import React from "react";
import { Card } from "ui-kit";
import ReadOnlyDataList, { DetentionDataType } from "./readOnlyDataList";
import { BehaviourView } from "areas/behaviour/types/behaviourResponses.types";
import moment from "moment";


interface IReadOnlyBehaviourDetailsProps {
  behaviour: BehaviourView;
}


const ReadOnlyBehaviourDetails: React.FC<IReadOnlyBehaviourDetailsProps> = ({ behaviour }) => {

  const data: DetentionDataType[] = [
    {
      dataType: "Behaviour Code",
      dataValue: behaviour.behaviourCodeName
    },
    {
      dataType: "Behaviour Category",
      dataValue: behaviour.categoryName
    },
    {
      dataType: "Points",
      dataValue: behaviour.points.toString()
    },
    {
      dataType: "Initial Action",
      dataValue: behaviour.initialActionName
    },
    { 
      dataType: "Outcome", 
      dataValue: behaviour.outcome 
    },
    {
      dataType: "Awarded By",
      dataValue: behaviour.awardedBy
    },
    {
      dataType: "Awarded Date",
      dataValue: moment(behaviour.awardedDate).format("DD/MM/YYYY HH:mm")
    }
  ];

  if (behaviour.publicNotes) {
    data.push({
      dataType: "Public Notes",
      dataValue: behaviour.publicNotes
    });
  }

  if (behaviour.behaviourDescription) {
    data.push({
      dataType: "Internal Notes",
      dataValue: behaviour.behaviourDescription
    });
  }

  return (
    <Card className="behaviour-details" title="Behaviour Details">
      <Card.Body>
        <ReadOnlyDataList data={data} />
      </Card.Body>
    </Card>
  );
};


export default ReadOnlyBehaviourDetails;
