const getElementBottomSpace = <T extends HTMLElement>(element: T) => {
  if (element) {
    const elRect = element.getBoundingClientRect();
    const space = window.innerHeight - elRect.bottom;

    return space;
  } else {
    return 0;
  }
};


const getElementTopSpace = <T extends HTMLElement>(element: T) => {
  if (element) {
    const elRect = element.getBoundingClientRect();

    return elRect.top;
  } else {
    return 0;
  }
};


export default {
  getElementBottomSpace,
  getElementTopSpace,
};
