import React from "react";
import { Swatches } from "ui-kit/common/colors";
import { Size, Spacing } from "ui-kit/common/size";
import Button from "ui-kit/forms/button";
import ButtonBar from "ui-kit/forms/buttonBar";
import AlertModal from "./alertModal";
import styled from "styled-components";


const ButtonBarWrapper = styled.div`
  .button-bar {
    justify-content: center;
    width: 100%;
  }
`;

const BodyWrapper = styled.div`
  padding: ${Spacing.ExtraLarge}px;
  text-align: center;
`;


interface IConfirmModalProps {
  open: boolean;
  body: React.ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  height?: number;
  cancelText?: string;
  confirmText?: string;
  working?: boolean;
}


const OkayModal: React.FC<IConfirmModalProps> = ({
  open,
  body,
  onCancel,
  onConfirm,
  height,
  cancelText,
  confirmText,
  working
}) => {

  const handleCancel = () => {
    onCancel?.();
  }

  const handleConfirm = () => {
    onConfirm?.();
  }

  return (
    <AlertModal
      open={open}
      onClose={handleCancel}
      height={`${height || 20}%`}
    >
      <AlertModal.Body>
        <BodyWrapper>{body}</BodyWrapper>
      </AlertModal.Body>

      <AlertModal.Footer>
        <ButtonBarWrapper>
          <ButtonBar>
            <Button
              text={cancelText || "Cancel"}
              color={Swatches.Low}
              size={Size.Small}
              onClick={handleCancel}
              working={working}
            />
            <Button
              text={confirmText || "Ok"}
              color={Swatches.Primary}
              size={Size.Small}
              onClick={handleConfirm}
              working={working}
            />
          </ButtonBar>
        </ButtonBarWrapper>
      </AlertModal.Footer>
    </AlertModal>
  )
}

export default OkayModal;