import React, { useEffect } from "react";
import GroupListWrapper from "./groupListWrapper";
import { Route, Routes } from "react-router";
import GroupTabMenuWrapper from "./groupTabMenuWrapper";
import styled from "styled-components";
import CustomGroupEditor from "./customGroups/customGroupEditor";
import NoGroup from "./noGroup";
import { useSelector } from "react-redux";
import { Loader, Size } from "ui-kit";
import { arrays } from "utils";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import groupsActions from "../../actions/groups/groupsActions";
import { groupRoutes as groupsRoutes } from "../../plannerRoutes";
import CustomRoute from "sharedComponents/layout/routes/customRoute";
import { Constants } from "configuration";
import { RootState } from "reducers/store";
import { useAppSelector } from "reducers/hooks";


const Wrapper = styled.div`
  height: 100%;

  .group-wrapper {
    flex-grow: 2;
    margin-left: 250px;
    height: 100%;
  }
`;


const Groups = () => {

  const { groups, favouriteGroups, loading, refresh, error } = useAppSelector(state => state.groups);
  const { user } = useAppSelector(state => state.currentUser);

  useEffect(() => {
    if (arrays.isEmpty(groups) && user) {
      groupsActions.getGroupsForStaff(user.id);
    }
  }, []);

  useEffect(() => {
    if (refresh) {
      groupsActions.getGroupsForStaff(user.id);
    }
  }, [refresh]);

  if (loading) {
    return (
      <Wrapper>
        <Loader size={Size.Large} cover />
      </Wrapper>
    );
  }

  if (error) {
    return (
      <Wrapper>
        <ApiExceptionMessage error={error} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <GroupListWrapper groups={groups} favouriteGroups={favouriteGroups} />

      <div className="group-wrapper">
        <Routes>
          <Route
            index
            element={
              <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
                <NoGroup />
              </CustomRoute>
            }
          />

          <Route
            path={`${groupsRoutes.group.default}/*`}
            element={
              <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
                <GroupTabMenuWrapper />
              </CustomRoute>
            }
          />

          <Route
            path={`/:groupId`}
            element={
              <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
                <GroupTabMenuWrapper />
              </CustomRoute>
            }
          />

          {/* <Route
            path={groupsRoutes.newGroup}
            element={
              <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
                <CustomGroupEditor />
              </CustomRoute>
            }
          />

          <Route
            path={groupsRoutes.editGroup}
            element={
              <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
                <CustomGroupEditor />
              </CustomRoute>
            }
          /> */}
        </Routes>
      </div>
    </Wrapper>
  );
};

export default Groups;
