
export enum LessonExitGroupBy {
  Teacher = 0,
  Pupil = 1,
  PupilGender = 2,
  YearGroup = 3,
  TutorGroup = 4,
  ClassGroup = 5,
  Subject = 6,
  Category = 7,
  PP = 8,
  Date = 9,
  SEN = 10,
  ProvisionGroup = 11
}

export enum FilterBy {
  Teacher = 0,
  Pupil = 1,
  YearGroup = 2,
  TutorGroup = 3,
  Subject = 4,
  BehaviourCategories = 5,
  BehaviourCode = 6,
  Outcome = 7,
  SEN = 8,
  DetentionTypes = 9,
  ProvisionGroup = 10
}