import { DatePicker, StructuredList } from "ui-kit";
import { ICateringReportProps } from "."
import { StudentPicker } from "sharedComponents/common";
import { useEffect, useState } from "react";
import { useAppSelector } from "reducers/hooks";


const SinglePupilChoicesReport: React.FC<ICateringReportProps> = ({ filter, setFilter }) => {

  const { user } = useAppSelector(state => state.currentUser);

  const [availableSchools, setAvailableSchools] = useState<number[]>([]);

  useEffect(() => {
    if (user) {
      setAvailableSchools([user.baseSchool, ...user.schools]
        .filter(school => school.enableLunchCredits)
        .map(school => school.id));
    }
  }, [user]);

  return (
    <>
      <StructuredList.Item name="Start Date">
        <DatePicker
          closeOnSelect
          selectedDate={filter?.startDate}
          onChange={value => setFilter({ ...filter, startDate: value })}
        />
      </StructuredList.Item>
      <StructuredList.Item name="End Date">
        <DatePicker
          closeOnSelect
          selectedDate={filter?.endDate}
          onChange={value => setFilter({ ...filter, endDate: value })}
        />
      </StructuredList.Item>
      <StructuredList.Item name="Student">
      <StudentPicker
        fluid
        schools={availableSchools}
        onChange={student => setFilter({...filter, studentId: student.id})}
      />
      </StructuredList.Item>
    </>
  )
}


export default SinglePupilChoicesReport;