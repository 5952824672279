import { IBeehiveError } from "types/common/errors.types";
import customGroupActions from "../../actions/group/customGroupActions";
import { IBeehiveAction } from "types/common/action.types";


interface ICustomGroupState {
  loading: boolean;
  error: IBeehiveError;
  loadingUpdateStudents: boolean;
  errorUpdatingStudents: IBeehiveError;
  loadingDeleteGroup: boolean;
  errorDeletingGroup: IBeehiveError;
  errorChangingStatus: IBeehiveError;
}

const INITIAL_STATE: ICustomGroupState = {
  loading: false,
  error: null,
  loadingUpdateStudents: false,
  errorUpdatingStudents: null,
  loadingDeleteGroup: false,
  errorDeletingGroup: null,
  errorChangingStatus: null,
};


const customGroupReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ICustomGroupState => {

  const {
    GETCUSTOMGROUP,
    UPDATECUSTOMGROUPSTUDENTS,
    DELETECUSTOMGROUP,
    CHANGECUSTOMGROUPSTATUS,
  } = customGroupActions.types;

  switch (action.type) {
    
    case GETCUSTOMGROUP.START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GETCUSTOMGROUP.SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case GETCUSTOMGROUP.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATECUSTOMGROUPSTUDENTS.START:
      return {
        ...state,
        loadingUpdateStudents: true,
        errorUpdatingStudents: null,
      };

    case UPDATECUSTOMGROUPSTUDENTS.SUCCESS:
      return {
        ...state,
        loadingUpdateStudents: false,
      };

    case UPDATECUSTOMGROUPSTUDENTS.FAILED:
      return {
        ...state,
        loadingUpdateStudents: false,
        errorUpdatingStudents: action.payload,
      };

    case DELETECUSTOMGROUP.START:
      return {
        ...state,
        loadingDeleteGroup: true,
        errorDeletingGroup: null,
      };

    case DELETECUSTOMGROUP.SUCCESS:
      return {
        ...state,
        loadingDeleteGroup: false,
      };

    case DELETECUSTOMGROUP.FAILED:
      return {
        ...state,
        loadingDeleteGroup: false,
        errorDeletingGroup: action.payload,
      };

    case CHANGECUSTOMGROUPSTATUS.START:
      return { ...state, errorChangingStatus: null };

    case CHANGECUSTOMGROUPSTATUS.FAILED:
      return { ...state, errorChangingStatus: action.payload };

    default:
      return state;
  }
};

export default customGroupReducer;
