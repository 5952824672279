import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Icon,
  neutral,
  honeygold,
  typescale,
  displayFont,
  Spacing,
  fontStyle,
  Speed,
  BorderRadius,
} from "../index";

const JumboPickerWrapper = styled.div<{disabled?: boolean, fluid?: boolean}>`
  display: flex;
  box-sizing: box-content;

  ${fontStyle(displayFont.bold, typescale.paragraph)}

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.75;
    cursor: not-allowed;`}
`;

const JumboPickerItemWrapper = styled.div<{iconColor?: string, hoverBackgroundColor?: string, hoverForegroundColor?: string, selected: boolean}>`
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-start;
  padding: ${Spacing.Medium}px;
  color: ${neutral[700]};
  border-radius: ${BorderRadius.Default}px;
  border-right: 1px solid ${neutral[400]};

  .jumbo-picker-item-icon {
    display: block;
    font-size: 35px;
    margin-bottom: ${Spacing.Default}px;
    ${({ iconColor }) =>
      iconColor ? `color: ${iconColor};` : `color: ${honeygold}`}
  }

  .jumbo-picker-item-label {
    display: block;
    text-align: center;
    ${fontStyle(displayFont.light, typescale.header2, neutral[700])}
    margin-bottom: ${Spacing.Default}px;
  }

  .jumbo-picker-item-sub {
    display: inline-block;
    text-align: center;
    ${fontStyle(displayFont.medium, typescale.paragraph, neutral[700])}
  }

  &:hover {
    cursor: pointer;
    transistion: all ease ${Speed.Default}ms;
    ${({ hoverBackgroundColor }) =>
      `background: ${hoverBackgroundColor ? hoverBackgroundColor : honeygold};`}

    i, span {
      ${({ hoverForegroundColor }) =>
        `color: ${hoverForegroundColor ? hoverForegroundColor : neutral[600]};`}
    }
  }

  ${({ selected, hoverBackgroundColor, hoverForegroundColor }) =>
    selected &&
    `background: ${
      hoverBackgroundColor ? hoverBackgroundColor : honeygold
    } !important;


    i, span {

      color: ${
        hoverForegroundColor ? hoverForegroundColor : neutral[600]
      } !important;
    
    }`}

  &:last-child {
    border-right: 0;
  }
`;

export interface IJumboPickerProps {
  value?: string | boolean | number | {};
  children?: React.ReactElement<IJumboPickerItemProps>[];
  items?: IJumboPickerItemProps[];
  onChange(value: any, index: number): void;
  fluid?: boolean;
  disabled?: boolean;
}

const JumboPicker: React.FC<IJumboPickerProps> & {
  Item: typeof JumboPickerItem;
} = ({ children, items, value, onChange, fluid, disabled }) => {
  const [selectedValue, setSelectedValue] = useState<any>(null);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleSelectedItemChanged = (value: any, index: number) => {
    setSelectedValue(value);
    onChange?.(value, index);
  };

  return (
    <JumboPickerWrapper
      className="jumbo-picker"
      fluid={fluid}
      disabled={disabled}
    >
      {children?.map((child, index) => {
        return React.cloneElement(
          child as React.ReactElement<IJumboPickerItemProps>,
          {
            key: index,
            selected: child.props.value === selectedValue,
            onClick: (value: any) => handleSelectedItemChanged(value, index),
          }
        );
      })}
      {items?.map((item, index) => (
        <JumboPickerItem
          key={children?.length ? index + children.length : index}
          value={item.value}
          icon={item.icon}
          label={item.label}
          selected={item.value === selectedValue}
          onClick={(value) => handleSelectedItemChanged(value, index)}
        />
      ))}
    </JumboPickerWrapper>
  );
};

export interface IJumboPickerItemProps {
  value: string | boolean | number | object;
  label: string;
  icon?: string;
  onClick?(value: any): void;
  selected?: boolean;
  sub?: string;
  iconColor?: string;
  hoverBackgroundColor?: string;
  hoverForegroundColor?: string;
}

const JumboPickerItem: React.FC<IJumboPickerItemProps> = ({
  value,
  label,
  icon,
  onClick,
  selected = false,
  sub,
  iconColor,
  hoverBackgroundColor,
  hoverForegroundColor,
}) => {
  const handleClick = () => {
    onClick?.(value);
  };
  return (
    <JumboPickerItemWrapper
      className="jumbo-picker-item"
      onClick={handleClick}
      selected={selected}
      iconColor={iconColor}
      hoverBackgroundColor={hoverBackgroundColor}
      hoverForegroundColor={hoverForegroundColor}
    >
      {icon && <Icon value={icon} className="jumbo-picker-item-icon" />}
      <span className="jumbo-picker-item-label">{label}</span>
      {sub != null && <span className="jumbo-picker-item-sub">{sub}</span>}
    </JumboPickerItemWrapper>
  );
};

JumboPicker.Item = JumboPickerItem;

export default JumboPicker;
