import React from "react";
import moment from "moment";
import { arrays, strings } from "utils";
import {
  EmptyMessage,
  Swatches,
  Table,
  SplitButton,
  Size,
  Button,
  DetailLabel, DateTime
} from "ui-kit";
import { WallPlannerEventView } from "areas/calendar/types/calendarResponses.types";
import WpEventChip from "./wallplannerEventChip";
import { PageInfo } from "types/common/paging.types";
import { IBeehiveError } from "types/common/errors.types";


export interface IWpEventsTableProps {
  events: WallPlannerEventView[];
  loading: boolean;
  error: IBeehiveError;
  paging?: PageInfo;
  handlePage?: () => void;
  onNewEventClick?: () => void;
  onEditEventClick?: (event: WallPlannerEventView) => void;
  onDeleteEventClick?: (event: WallPlannerEventView) => void;
}


const WpEventsTable: React.FC<IWpEventsTableProps> = ({
  loading, events, paging, handlePage, error, onNewEventClick, onEditEventClick, onDeleteEventClick,
}) => {

  const getDuration = (event: WallPlannerEventView) => {
    let start = moment(event.startDate);
    let end = moment(event.endDate);
    var days;

    if (start.isSame(end, "day")) {
      days = 1;
    } else {
      days = end.diff(start, "days") + 1;
    }

    return `${days} ${strings.pluralize("Day", "Days", days)}`;
  };

  const handleCreateEventClicked = () => {
    onNewEventClick?.();
  };

  const handleEditEventClicked = (event: WallPlannerEventView) => {
    onEditEventClick?.(event);
  };

  const handleDeleteEventClicked = (event: WallPlannerEventView) => {
    onDeleteEventClick?.(event);
  };

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was an error loading the events for this wall planner"
        icon="times-circle"
        cover />
    );
  }

  return (
    <>
      <Table
        zebra
        grow
        loading={loading}
        error={error != null}
        empty={arrays.isEmpty(events)}
        emptyMessage={<EmptyMessage icon="calendar" title="No Events Found" cover>
          <Button
            color={Swatches.Primary}
            text="Create New Event"
            size={Size.Small}
            onClick={handleCreateEventClicked} />
        </EmptyMessage>}
      >
        <Table.Header>
          <Table.HeaderCell width={0.75}>Type</Table.HeaderCell>
          <Table.HeaderCell width={2}>Name</Table.HeaderCell>
          <Table.HeaderCell width={1}>From</Table.HeaderCell>
          <Table.HeaderCell width={1}>To</Table.HeaderCell>
          <Table.HeaderCell width={1}>Duration</Table.HeaderCell>
          <Table.HeaderCell width={2} right />
        </Table.Header>
        <Table.Body onPage={handlePage} paging={paging}>
          {events?.map((event: WallPlannerEventView, index: number) => (
            <Table.Row key={index}>
              <Table.Cell width={0.75}>
                <WpEventChip categoryId={event.categoryId} />
              </Table.Cell>
              <Table.Cell width={2}>
                <DetailLabel label={event.name} bold />
              </Table.Cell>
              <Table.Cell width={1}>
                <DateTime
                  date={event.startDate}
                  hideTime
                  bold />
              </Table.Cell>
              <Table.Cell width={1}>
                <DateTime
                  date={event.endDate}
                  hideTime
                  bold />
              </Table.Cell>
              <Table.Cell width={1}>{getDuration(event)}</Table.Cell>
              <Table.Cell width={2} right>
                <SplitButton
                  size={Size.Small}
                  text="Edit"
                  color={Swatches.Default}
                  onDefaultClick={() => handleEditEventClicked(event)}
                >
                  <SplitButton.Option
                    onClick={() => handleEditEventClicked(event)}
                    text="Edit"
                    show={true} />
                  <SplitButton.Option
                    onClick={() => handleDeleteEventClicked(event)}
                    text="Delete"
                    show={true} />
                </SplitButton>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};


export default WpEventsTable;