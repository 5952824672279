import paymentActions from "areas/payments/actions/paymentsActions";
import defaults from "configuration/defaults";
import { IBeehiveAction } from "types/common/action.types";
import { PageInfo } from "types/common/paging.types";
import { SearchResultView } from "types/common/views.types";
import { UserListView } from "types/users/userListViews.types";


export interface ISearchUsersState {
  users: SearchResultView<UserListView>[] | null;
  error: string | null;
  loading: boolean;
  paging: PageInfo;
}

const INITIAL_STATE: ISearchUsersState = {
  users: [],
  error: null,
  loading: false,
  paging: defaults.DEFAULT_PAGING
};


const searchUsersReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISearchUsersState => {

  const { 
    PAYMENTS_SEARCHUSERS 
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_SEARCHUSERS.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_SEARCHUSERS.SUCCESS:
      return {
        ...state,
        users:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.users, ...action.payload.items],
        paging: action.payload.paging,
        loading: false
      };

    case PAYMENTS_SEARCHUSERS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default searchUsersReducer;
