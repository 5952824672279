import React from "react";
import BcsLogo from "./bcs";
import BeaLogo from "./bea";
import BroLogo from "./bro";
import CedLogo from "./ced";
import HalLogo from "./hal";
import HigLogo from "./hig";
import HumLogo from "./hum";
import JudLogo from "./jud";
import LhtLogo from "./lht";
import MarLogo from "./mar";
import RivLogo from "./riv";
import SjnLogo from "./sjn";
import CasLogo from "./cas";
import BlsLogo from "./bls";
import TnsLogo from "./tns";
import PioLogo from "./pio";
import MerLogo from "./mer";
import NhlLogo from "./nhl";
import styled from "styled-components";
import { Size } from "ui-kit";

export interface ISchoolIconProps {
  code: string;
  size?: number;
  className?: string;
  style?: object;
  width?: number;
  height?: number;
}

const getSize = (size: Size) => {
  switch (size) {
    case Size.ExtraSmall:
      return 18;
    case Size.Small:
      return 24;
    case Size.Medium:
      return 42;
    case Size.Large:
      return 56;
    case Size.ExtraLarge:
      return 72;
    case Size.Jumbo:
      return 100;
    default:
      return 100;
  }
};

const Wrapper = styled.span<{size: number}>`
  display: inline-flex;
  flex-grow: 0;
  align-content: center;
  flex-direction: column;
  svg {
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    min-width: ${({ size }) => size}px;
    min-height: ${({ size }) => size}px;
  }
`;

const SchoolIcon: React.FC<ISchoolIconProps> = (props) => {
  let _size = !props.size ? Size.Small : props.size;

  switch (props?.code?.toLowerCase()) {
    case "bcs":
      return (
        <Wrapper className="school-icon" size={getSize(_size)}>
          <BcsLogo {...props} />
        </Wrapper>
      );
    case "bea":
      return (
        <Wrapper className="school-icon" size={getSize(_size)}>
          <BeaLogo {...props} />
        </Wrapper>
      );
    case "bro":
      return (
        <Wrapper className="school-icon" size={getSize(_size)}>
          <BroLogo {...props} />
        </Wrapper>
      );
    case "ced":
      return (
        <Wrapper className="school-icon" size={getSize(_size)}>
          <CedLogo {...props} />
        </Wrapper>
      );
    case "hig":
      return (
        <Wrapper className="school-icon" size={getSize(_size)}>
          <HigLogo {...props} />
        </Wrapper>
      );
    case "hum":
      return (
        <Wrapper className="school-icon" size={getSize(_size)}>
          <HumLogo {...props} />
        </Wrapper>
      );
    case "hal":
      return (
        <Wrapper className="school-icon" size={getSize(_size)}>
          <HalLogo {...props} />
        </Wrapper>
      );
    case "jud":
      return (
        <Wrapper className="school-icon" size={getSize(_size)}>
          <JudLogo {...props} />
        </Wrapper>
      );
    case "lht":
      return (
        <Wrapper className="school-icon" size={getSize(_size)}>
          <LhtLogo {...props} />
        </Wrapper>
      );
    case "mar":
      return (
        <Wrapper className="school-icon" size={getSize(_size)}>
          <MarLogo {...props} />
        </Wrapper>
      );
    case "riv":
      return (
        <Wrapper className="school-icon" size={getSize(_size)}>
          <RivLogo {...props} />
        </Wrapper>
      );
    case "sjn":
      return (
        <Wrapper className="school-icon" size={getSize(_size)}>
          <SjnLogo {...props} />
        </Wrapper>
      );
    case "cas":
      return (
        <Wrapper className="school-icon" size={getSize(_size)}>
          <CasLogo {...props} />
        </Wrapper>
      );
    case "bls":
      return (
        <Wrapper className="school-icon" size={getSize(_size)}>
          <BlsLogo {...props} />
        </Wrapper>
      );
    case "tns":
      return (
        <Wrapper className="school-icon" size={getSize(_size)}>
          <TnsLogo {...props} />
        </Wrapper>
      );
    case "pio":
      return (
        <Wrapper className="school-icon" size={getSize(_size)}>
          <PioLogo {...props} />
        </Wrapper>
      );
    case "mer":
      return (
        <Wrapper className="school-icon" size={getSize(_size)}>
          <MerLogo {...props} />
        </Wrapper>
      );
    case "nhl":
      return (
        <Wrapper className="school-icon" size={getSize(_size)}>
          <NhlLogo {...props} />
        </Wrapper>
      );
    default:
      return null;
  }
};

export default SchoolIcon;
