import React, { useRef } from "react";
import PropTypes from "prop-types";
import Avatar from "sharedComponents/common/users/avatar";
import styled from "styled-components";
import { neutral, honeygold, danger, blue } from "ui-kit/common/colors";
import {
  getFontSize,
  fontStyle,
  displayFont,
  typescale,
  Icon,
  Tooltip,
  Size,
  Spacing,
  BorderRadius,
} from "ui-kit";
import Tippy from "@tippyjs/react";
import StudentSensitiveDataTiles from "areas/planner/components/studentRecord/studentSensitiveData/studentSensitiveDataTiles";

const Wrapper = styled.div`
  height: inherit;

  .student-background {
    background-color: ${neutral[400]};
    border-radius: ${BorderRadius.Default}px;
    position: relative;
    min-height: 100px;
  }

  .class-viewer-student {
    display: flex;
    justify-content: space-between;
    // background-color: ${neutral[400]};
    border-radius: ${BorderRadius.Default}px;
    transition: all ease 200ms;
    align-self: center;
    width: 100%;
    position: absolute;
    z-index: 1000;

    &:active {
      cursor: grabbing;
    }

    .avatar {
      margin-left: ${Spacing.Default}px;

      .profile-image {
        min-height: 48px;
        min-width: 48px;
      }

      .inner {
        padding: 0;
        margin-left: ${Spacing.Default}px;

        .label {
          ${fontStyle(displayFont.medium, typescale.paragraph, neutral[700])};
          font-size: 13px;
          line-height: 1rem !important
        }  
      }
    }
      .notes {
        position: absolute;
        top: 58px;
        left: 42px;
      }

    .remove-student-button {
      align-self: flex-end;
      font-size: ${getFontSize(Size.Large)}rem;
      background-color: unset;
      border: none;
      margin: 0;
      color: ${neutral[700]};
      cursor: pointer;
      visibility: hidden;
      line-height: 1rem;
      position: absolute;
      padding: 0;
      z-index: 1000;

      &:hover {
        color: ${danger};
        font-weight: bolder;
        // font-size: ${getFontSize(Size.ExtraLarge)}rem;
      }
    }
  }

  ${({ onGrid }) =>
    onGrid &&
    `
    .student-background {
      height: 6rem;
      min-height: 6rem;
      background-color: ${neutral[300]};
      border-radius: ${BorderRadius.Default}px;
      position: relative;
      top: 1.7rem;
    }

    .class-viewer-student {
      flex-direction: column;
      height: 100%;
      box-sizing: border-box;

      &:hover .remove-student-button {
        visibility: visible;
        transition: all ease 200ms;
        line-height: 1rem;
      }

      .avatar {
        margin-left: 0;
        width: auto;

        .profile-image {
          margin-bottom: -4px;
          background-size: cover;
          background-position: 50% 25%;
        }

        .inner {
          margin-top: 6px;
          margin-left: 0;
          
          .label {
            font-size: 0.65rem;
            line-height: 0.75rem !important;
          }
        } 
      }

      .sub {
        display: none;
      }

      .notes {
        top: 34px;
        left: 6px;
      }
    }

    &.hide-cross {
      .class-viewer-student .remove-student-button {
        display: none;
      }
    }
  `}  

  ${({ flags, isReadingAge }) =>
    flags > 5 && isReadingAge === true
      ? `
      .student-background {
        height: 6rem;
        min-height: 6rem;
      }`
      : (flags > 5 && isReadingAge === false) ||
        (flags > 0 && isReadingAge === true)
      ? `
      .student-background {
        height: 5.3rem;
        min-height: 5.3rem;
      }`
      : (flags === 0 || (flags > 0 && isReadingAge === false)) &&
        `
        .student-background {
          height: 4.3rem;
          min-height: 4.3rem;
        }`}

  ${({ filtered }) =>
    filtered &&
    `
    .student-background {
      background-color: ${honeygold};
    }

    .class-viewer-student {
      .avatar .inner .label {
        color: ${neutral[800]};
      }
    }
  `}
  
  ${({ detentionToday }) =>
    detentionToday &&
    `
    .student-background {
      background-color: ${neutral[800]};
    }
    .class-viewer-student {
      .avatar .inner .label {
        color: ${neutral[100]};
      }
    }
  `} 

  ${({ activeOnCall }) =>
    activeOnCall &&
    `
    .student-background {
      background-color: ${danger};
    }
    .class-viewer-student {
      .avatar .inner .label {
        color: ${neutral[100]};
      }
    }
  `} 

  ${({ onGrid, hideSensitive }) =>
    !onGrid &&
    hideSensitive &&
    `
    
    .student-background {
      height: 60px;
      min-height: 60px;
    }
    
    .class-viewer-student {
      // margin: ${Spacing.Medium}px 0;

      .avatar {
        margin-left: ${Spacing.Default}px;
        .inner {
          margin-left: ${Spacing.Medium}px;

          .detail-label {
            align-items: center;
          }
        }
      } 
      
      .notes {
        top: 40px;
        left: 46px;
      }    
    }
  `} 
  
  ${({ onGrid, hideSensitive }) =>
    onGrid &&
    hideSensitive &&
    `
    
      .student-background {
        height: 4.3rem;
        min-height: 4.3rem;
      }
      
      .class-viewer-student {
        margin: 0;
      }
    `}

  ${({ selected }) =>
    selected &&
    `
    .student-background {
      background-color: ${neutral[600]};
    }
    .class-viewer-student {
      .avatar .inner .label {
        color: ${neutral[100]};
      }

      .remove-student-button {
        color: ${neutral[100]};
      }
  `} 
  }
`;

const ClassLayoutStudent = ({
  student,
  onGrid,
  removeStudent,
  filtered,
  detentionToday,
  activeOnCall,
  selected,
  hideSensitive,
  hideProfileImage,
  maxFlagsAndReadingAge,
}) => {
  const notesRef = useRef();

  // console.log(activeOnCall);

  // ${({ hideProfileImage }) =>
  //   hideProfileImage &&
  //   `
  //     .class-viewer-student .avatar .inner {
  //       width: 100%;

  //       .detail-label {
  //         align-items: center;
  //       }

  //       .label {
  //         ${fontStyle(displayFont.medium, typescale.header4)}
  //         padding: ${Spacing.Small}px;
  //       }
  //     }
  //  `}

  return (
    <Wrapper
      className="class-layout-student-wrapper"
      onGrid={onGrid}
      filtered={filtered}
      detentionToday={detentionToday}
      activeOnCall={activeOnCall}
      selected={selected}
      hideSensitive={hideSensitive}
      hideProfileImage={hideProfileImage}
      flags={maxFlagsAndReadingAge?.maxFlags}
      isReadingAge={maxFlagsAndReadingAge?.isReadingAge}
    >
      <div
        className={`class-viewer-student ${onGrid ? "on-grid" : ""}`} // CHANGING THE CLASS NAME WILL BREAK SWAPING STUDENTS IN CLASS LAYOUT
        data-id={student.id}
      >
        {onGrid && (
          <button
            id={student.id}
            className="remove-student-button"
            onClick={event => {
              event.stopPropagation();
              removeStudent(student.id);
            }}
          >
            &#10799;
          </button>
        )}

        <Avatar
          user={student}
          size={Size.Medium}
          portrait={onGrid ? true : false}
          splitName={true}
        />

        {student.totalNotes > 0 && (
          <>
            <div ref={notesRef} className="notes">
              <Icon value="sticky-note" size={Size.Small} color={blue} />
            </div>
            <Tippy
              reference={notesRef}
              content={
                <Tooltip
                  title="Notes"
                  sub="Double click student to see notes for the student."
                />
              }
            />
          </>
        )}

        <StudentSensitiveDataTiles student={student} vertical={!onGrid} />
      </div>
      <div className="student-background" />
    </Wrapper>
  );
};

ClassLayoutStudent.propTypes = {
  className: PropTypes.string,
  student: PropTypes.object,
  onGrid: PropTypes.bool,
  removeStudent: PropTypes.func,
  displaySensitiveInfo: PropTypes.bool,
};

export default ClassLayoutStudent;
