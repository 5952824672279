import storeActions from "areas/payments/actions/storeActions";
import { OrderDetailView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface IStoreProductsState {
  order: OrderDetailView | null;
  error: string | null;
  loading: boolean;
}

const INITIAL_STATE: IStoreProductsState = {
  order: null,
  error: null,
  loading: false
};


const storeOrderReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IStoreProductsState => {

  const { 
    STORE_GETORDER 
  } = storeActions.storeTypes;

  switch (action.type) {
    case STORE_GETORDER.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case STORE_GETORDER.SUCCESS:
      return { 
        ...state, 
        order: action.payload, 
        loading: false
      };

    case STORE_GETORDER.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default storeOrderReducer;
