import { IBeehiveAction } from "types/common/action.types";
import schoolActions from "../../actions/school/schoolActions";
import { IBeehiveError } from "types/common/errors.types";
import { RoomListView } from "areas/planner/types/classLayoutResponse.types";


interface ISchoolRoomsState {
  rooms: RoomListView[],
  loadingRooms: boolean;
  getRoomsError: IBeehiveError;
}

const INITIAL_STATE: ISchoolRoomsState = {
  rooms: [],
  loadingRooms: false,
  getRoomsError: null,
};


const schoolRoomsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISchoolRoomsState => {

  const { GETSCHOOLROOMS } = schoolActions.types;

  switch (action.type) {
    case GETSCHOOLROOMS.START:
      return { ...state, loadingRooms: true, getRoomsError: null };

    case GETSCHOOLROOMS.SUCCESS:
      return { ...state, rooms: action.payload, loadingRooms: false };

    case GETSCHOOLROOMS.FAILED:
      return { ...state, loadingRooms: false, getRoomsError: action.payload };

    default:
      return state;
  }
};

export default schoolRoomsReducer;
