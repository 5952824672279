import { SpecialDayListView } from "../../../areas/planner/types/termDatesResponse.types";
import moment from "moment";
import { dateTime } from "../../../utils";
import { CoverArrangement, LeaveRequestType } from "../types/leaveShared.types";
import { SubmitLeaveRequestCommandExt } from "../components/leave/requestLeaveModal";
import { HolidayCardView } from "../types/leaveResponse.types";


type LeaveHoursData = {
  totalHours: number;
  startDate: string;
  endDate: string;
}


const DAYSTARTDEFAULT = 8;
const DAYENDDEFAULT = 16;


/**
 * @param {moment.MomentInput} time The time to check
 * @param {number} [dayStart = DAYSTARTDEFAULT] The starting hour of the work day
 * @param {number} [dayEnd = DAYENDDEFAULT] The end hour of the work day
 * @returns {boolean} The given time is within working hours
 */
const isInWorkingHours = (
  time: moment.MomentInput, 
  dayStart: number = DAYSTARTDEFAULT, 
  dayEnd: number = DAYENDDEFAULT
) : boolean => {
  const _time = moment(time);
  return _time.hour() >= dayStart && (_time.hour() < dayEnd || (_time.hour() === dayEnd && _time.minute() === 0));
}


/**
 * @param {moment.MomentInput} date The start dateTime of leave
 * @param {number} [dayStart = DAYSTARTDEFAULT] The starting hour of the work day
 * @param {number} [dayEnd = DAYENDDEFAULT] The end hour of the work day
 * @returns {number} The working hours used on the first day of leave
 */
const getWorkingHoursForStartDate = (
  date: moment.MomentInput, 
  dayStart: number = DAYSTARTDEFAULT, 
  dayEnd: number = DAYENDDEFAULT
) : number => {
  const workingHours = dayEnd - dayStart;
  var _date = moment(date);
  // Leave starts before or same as work start so it uses a full day
  if (_date.hour() < dayStart || (_date.hour() === dayStart && _date.minute() === 0)) {
    return workingHours;
  }
  // Leave starts after work end so no hours used
  else if (_date.hour() >= dayEnd) {
    return 0;
  }
  // Leave starts within working hours so get hours remaining until end of day
  else if (isInWorkingHours(date, dayStart, dayEnd)) {
    return dateTime.getTimeDiff(_date, moment().hour(dayEnd).minute(0).second(0), "minutes") / 60;
  }
  return 0;
}


/**
 * @param {moment.MomentInput} date The end dateTime of leave
 * @param {number} [dayStart = DAYSTARTDEFAULT] The starting hour of the work day
 * @param {number} [dayEnd = DAYENDDEFAULT] The end hour of the work day
 * @returns {number} The working hours used on the last day of leave
 */
const getWorkingHoursForEndDate = (
  date: moment.MomentInput, 
  dayStart: number = DAYSTARTDEFAULT, 
  dayEnd: number = DAYENDDEFAULT
) : number => {
  const workingHours = dayEnd - dayStart;
  var _date = moment(date);
  // Leave ends before or same time as day starts so no hours used
  if (_date.hour() < dayStart || (_date.hour() === dayStart && _date.minute() === 0)) {
    return 0;
  }
  // Leave ends after work end so it uses a full day
  else if (_date.hour() >= dayEnd) {
    return workingHours;
  }
  // Leave ends withing working hours so get hours used from day start to end
  else if (isInWorkingHours(_date, dayStart, dayEnd)) {
    return workingHours - (dateTime.getTimeDiff(_date, moment().hour(dayEnd).minute(0).second(0), "minutes") / 60);
  }
  return 0;
}


/**
 * @param {Date[]} range The daterange to check
 * @param {number} [dayStart = DAYSTARTDEFAULT] The starting hour of the work day
 * @param {number} [dayEnd = DAYENDDEFAULT] The end hour of the work day
 * @returns {number} The unsanitised working hours used for a period of leave
 */
const getHoursFromRange = (
  range: Date[], 
  dayStart: number = DAYSTARTDEFAULT, 
  dayEnd: number = DAYENDDEFAULT
) : number => {
  const workingHours = dayEnd - dayStart;
  var hours = 0;

  if (range.length === 0 || range.length === 1) {
    return 0;
  }

  if (range.length === 2 && dateTime.isSameDay(range[0], range[1])) {
    return dateTime.getTimeDiff(range[0], range[1], "minutes") / 60;
  }

  hours += getWorkingHoursForStartDate(range[0], dayStart, dayEnd);
  // For each day that isn't the first or last add a full day of working hours
  for (let i = 1; i < range.length - 1; i++) {
    hours += workingHours;
  }

  hours += getWorkingHoursForEndDate(range[range.length - 1], dayStart, dayEnd);

  return hours;
}


/**
 * @param {Date[]} dateRange The daterange to check
 * @param {number} [dayEnd = DAYENDDEFAULT] The starting hour of the work day
 * @returns {Date[]} The input daterange with weekends removed
 */
const subtractWeekends = (
  dateRange: Date[],
  dayEnd: number = DAYENDDEFAULT
) : Date[] => {
  var _dateRange = [...dateRange];
  var weekdays: Date[] = [];

  // If the leave ended on a weekend, remove those days and then set the leave time to the last hour on friday
  for (var i = _dateRange.length - 1; i >= 0; i--){
    if (dateTime.isWeekend(_dateRange[i])) {
      // Don't alter the start date
      if (i - 1 !== 0 && i - 1 !== -1) {
        _dateRange[i - 1].setHours(dayEnd);
      }
      _dateRange.pop();
    }
    else {
      break;
    }
  }

  _dateRange.forEach(date => {
    if (!dateTime.isWeekend(date)) {
      weekdays.push(date); 
    }
  });

  // Niche case caught by test cases :)
  // If there are no dates in the array then the user has selected nothing but weekend
  // But if there is one, they have selected a start date of Friday and ended on Sunday.
  // The weekend has been removed, leaving only Friday.
  // We always want two in the range. A start date and an end date.
  // Add an end date to the array for the same day (Friday), but an ending hour of work day end
  if (_dateRange.length === 1) {
    var startDate = moment(_dateRange[0]);
    weekdays.push(startDate.hour(dayEnd).minute(0).second(0).toDate())
  }

  return weekdays;
};


/**
 * @param {Date[]} dateRange The daterange to check
 * @param {SpecialDayListView[]} specialDays List of annual special days
 * @param {number} [dayEnd = DAYENDDEFAULT] The starting hour of the work day
 * @returns {Date[]} The input daterange with special days removed
 */
const subtractSpecialDays = (
  dateRange: Date[],
  specialDays: SpecialDayListView[],
  dayEnd: number = DAYENDDEFAULT
) : Date[] => {
  var _dateRange = [...dateRange];
  const nonHolidayDays: Date[] = [];

  if (_dateRange.length === 0) {
    return _dateRange;
  }
  
    // If the leave ended on a bank holiday, remove those days and then set the leave time to the last hour on last working day
    for (var i = _dateRange.length - 1; i >= 0; i--){
      if (dateTime.isBankHoliday(_dateRange[i], specialDays)) {
        // Don't alter the start date
        if (i - 1 !== 0 && i - 1 !== -1) {
          _dateRange[i - 1].setHours(dayEnd);
        }
        _dateRange.pop();
      }
      else {
        break;
      }
    }


  _dateRange.forEach(date => {
    if (!dateTime.isBankHoliday(date, specialDays)) {
      nonHolidayDays.push(date);
    }
  });

  // Same explanation as the one in subtractWeekends, but with bank holidays instead
  if (_dateRange.length === 1) {
    var startDate = moment(_dateRange[0]);
    nonHolidayDays.push(startDate.hour(dayEnd).minute(0).second(0).toDate())
  }
  
  return nonHolidayDays;
}


/**
 * @param {Date[]} range The daterange to check
 * @param {SpecialDayListView[]} specialDays List of annual special days
 * @returns {Date[]} The input daterange with weekends and special days removed
 */
const getWorkingDaysFromRange = (
  range: Date[],
  specialDays: SpecialDayListView[]
) : Date[] => {
  var _range = [...range];
  _range = subtractWeekends(_range);
  _range = subtractSpecialDays(_range, specialDays);
  return _range;
}


/**
 * @param {Date} startDate The leave start dateTime
 * @param {Date} endDate The leave end datetTime
 * @param {number} [dayStart = DAYSTARTDEFAULT] The starting hour of the work day
 * @returns {Date[]} The daterange including and between the start and end dates
 */
export const getLeaveRange = (
  startDate: moment.MomentInput, 
  endDate: moment.MomentInput,
  dayStart: number = DAYSTARTDEFAULT
) : Date[] => {
  const _startDate = moment(startDate);
  const _endDate = moment(endDate);
  const diff = _endDate.diff(_startDate, "days");
  // Add startDate manually to preserve time
  let range: Date[] = [ _startDate.toDate() ]
  // For each date between start and end, add day. Set hour to working day start in case start date is invalid and is removed.
  for (let i = 1; i < diff; i++) {
    range.push(_startDate.add(1, "days").hours(dayStart).toDate())
  }
  // Add endDate manually to preserve time
  range.push(_endDate.toDate());
  return range
}


/**
 * @param {number} goldenHours The number of requested Golden Hours
 * @param {moment.MomentInput} startDate The dateTime of leave start
 * @param {number} [dayStart = DAYSTARTDEFAULT] The starting hour of the work day
 * @param {number} [dayEnd = DAYENDDEFAULT] The end hour of the work day
 * @returns {LeaveHoursData} The amount of working hours used by a leave request
 */
const getGoldenHourDates = (
  goldenHours: number,
  startDate: moment.MomentInput, 
  dayStart: number = DAYSTARTDEFAULT, 
  dayEnd: number = DAYENDDEFAULT
): LeaveHoursData => {

  const workingHours = dayEnd - dayStart;
  const halfDay = workingHours / 2;
  const midDay = dayStart + halfDay;

  switch (goldenHours) {
    case -1:
      return {
        totalHours: halfDay,
        startDate: moment(startDate)
          .hours(dayStart)
          .minutes(0)
          .seconds(0)
          .utc()
          .format("YYYY-MM-DD HH:mm"),
        endDate: moment(startDate)
          .hours(midDay)
          .minutes(0)
          .seconds(0)
          .utc()
          .format("YYYY-MM-DD HH:mm")
      }
    case -2:
      return {
        totalHours: halfDay,
        startDate: moment(startDate)
          .hours(midDay)
          .minutes(0)
          .seconds(0)
          .utc()
          .format("YYYY-MM-DD HH:mm"),
        endDate: moment(startDate)
          .hours(dayEnd)
          .minutes(0)
          .seconds(0)
          .utc()
          .format("YYYY-MM-DD HH:mm")
      }
    case -3:
      return {
      totalHours: workingHours,
      startDate: moment(startDate)
        .hours(dayStart)
        .minutes(0)
        .seconds(0)
        .utc()
        .format("YYYY-MM-DD HH:mm"),
      endDate: moment(startDate)
        .hours(dayEnd)
        .minutes(0)
        .seconds(0)
        .utc()
        .format("YYYY-MM-DD HH:mm")
      }
    default:
      var _startDate = moment(startDate);
      return {
        totalHours: goldenHours,
        startDate: _startDate.hour() >= dayEnd 
          ? _startDate
            .hour(dayEnd - goldenHours - (_startDate.minutes() > 0 ? 1 : 0))
            .seconds(0)
            .utc()
            .format("YYYY-MM-DD HH:mm")
          : _startDate.hour() < dayStart 
            ? _startDate
              .hour(dayStart)
              .seconds(0)
              .utc()
              .format("YYYY-MM-DD HH:mm")
            : _startDate
              .seconds(0)
              .utc()
              .format("YYYY-MM-DD HH:mm"),
        endDate: _startDate
          .seconds(0)
          .add(goldenHours, "hours")
          .utc()
          .format("YYYY-MM-DD HH:mm")
      }
  }
}


/**
 * @param {moment.MomentInput} startDate The dateTime of leave start
 * @param {moment.MomentInput} endDate The dateTime of leave end
 * @param {SpecialDayListView[]} specialDays List of annual special days
 * @param {number} [dayStart = DAYSTARTDEFAULT] The starting hour of the work day
 * @param {number} [dayEnd = DAYENDDEFAULT] The end hour of the work day
 * @returns {number} The amount of working hours used by a leave request
 */
const calculateLeaveHours = (
  startDate: moment.MomentInput, 
  endDate: moment.MomentInput, 
  specialDays: SpecialDayListView[], 
  dayStart: number = DAYSTARTDEFAULT, 
  dayEnd: number = DAYENDDEFAULT
) : number => {
  var start = moment(startDate);
  var end = moment(endDate);
  // Invalid input
  if (start.isAfter(end)) {
    return 0;
  }
  // Get the unsanitised date range
  var range = getLeaveRange(start, end, dayStart);
  // Remove weekends and special days from the range
  var workingDays = getWorkingDaysFromRange(range, specialDays);
  // Get working hours from the filtered range
  var workingHours = getHoursFromRange(workingDays, dayStart, dayEnd); 
  return workingHours;
}


/**
 * @param {HolidayCardView} holidayCard The user holiday card
 * @param {LeaveRequestType} requestType The leave type
 * @param {SubmitLeaveRequestCommandExt} request The leave type
 * @param {number} [dayStart = DAYSTARTDEFAULT] The starting hour of the work day
 * @param {number} [dayEnd = DAYENDDEFAULT] The end hour of the work day
 * @returns {SubmitLeaveRequestCommandExt} The default leave hour data for the leave type
 */
const updateRequestType = (
  holidayCard: HolidayCardView,
  requestType: LeaveRequestType,
  request?: SubmitLeaveRequestCommandExt,
  dayStart: number = DAYSTARTDEFAULT, 
  dayEnd: number = DAYENDDEFAULT
) : SubmitLeaveRequestCommandExt => {
  const requestBase = request != null 
    ? request 
    : {
      leaveRequestType: requestType,
      staffMemberId: holidayCard.staffMember?.id,
      holidayCardId: holidayCard.id,
      toilHours: 0,
      unpaidHours: 0,
      coverRequirement: CoverArrangement.None,
      notes: "",
      userRef: "",
      unpaid: false,
    }
  switch (requestType) {
    case LeaveRequestType.GoldenTime:
      // Since there is no guarantee the user has any golden time remaining, just select the day with no hours
      return {
        ...requestBase,
        authoriser: holidayCard.goldenTimeAuthoriser,
        authoriserId: holidayCard.goldenTimeAuthoriser?.id,
        startDate: dateTime.getNextWorkingDay(),
        endDate: dateTime.getNextWorkingDay(),
        totalHours: 0,
        goldenHours: 0
      };
    case LeaveRequestType.AuthorisedAbsence:
    case LeaveRequestType.Compassionate:
    case LeaveRequestType.Cpd:
    case LeaveRequestType.Holiday:
    case LeaveRequestType.Maternity:
    case LeaveRequestType.Toil:
    default:
      return {
        ...requestBase,
        authoriser: holidayCard.lineManager,
        authoriserId: holidayCard.lineManager?.id,
        startDate: dateTime.getNextWorkingDay(),
        endDate: dateTime.getNextWorkingDay(Date.now(), 16),
        totalHours: dayEnd - dayStart,
        goldenHours: 0
      };
  }
}


/**
 * @param {SubmitLeaveRequestCommandExt} request The leave type
 * @param {number} [dayStart = DAYSTARTDEFAULT] The starting hour of the work day
 * @param {number} [dayEnd = DAYENDDEFAULT] The end hour of the work day
 * @returns {SubmitLeaveRequestCommandExt} The default leave hour data for the leave type
 */
const sanitiseDateTimes = (
  request: SubmitLeaveRequestCommandExt,
  dayStart: number = DAYSTARTDEFAULT, 
  dayEnd: number = DAYENDDEFAULT
) : SubmitLeaveRequestCommandExt => {
  var _request = { ...request };
  if (_request.startDate && _request.endDate) {
    // If the leave starts before work hours, set to first work hour
    if (moment(_request.startDate).hour() < dayStart) {
      _request = {
        ..._request,
        startDate: moment(request.startDate).hour(dayStart).format("YYYY-MM-DD HH:mm"),
      }
    }
    // If the leave ends same day after work hours, set to last work hour
    if (moment(_request.endDate).hour() > dayEnd) {
      _request = {
        ..._request,
        endDate: moment(_request.endDate).hour(dayEnd).format("YYYY-MM-DD HH:mm"),
      };
    }
  }
  return _request;
}


const sanitiseDateTimesAndCalculateHours = (
  request: SubmitLeaveRequestCommandExt,
  specialDays: SpecialDayListView[], 
  dayStart: number = DAYSTARTDEFAULT, 
  dayEnd: number = DAYENDDEFAULT
) : SubmitLeaveRequestCommandExt => {

  var _request = sanitiseDateTimes(request, dayStart, dayEnd);

  if (_request.leaveRequestType === LeaveRequestType.GoldenTime) {
    let goldenTime = getGoldenHourDates(_request.goldenHours, _request.startDate, dayStart, dayEnd);
    _request = {
      ..._request,
      startDate: goldenTime.startDate,
      endDate: goldenTime.endDate,
      totalHours: goldenTime.totalHours
    };
  }
  else {
    _request = { 
      ..._request, 
      totalHours: calculateLeaveHours(_request.startDate, _request.endDate, specialDays) 
    };
  }

  return _request;
}


const leaveHelpers = {
  isInWorkingHours,
  getWorkingHoursForStartDate,
  getWorkingHoursForEndDate,
  getHoursFromRange,
  subtractWeekends,
  subtractSpecialDays,
  getWorkingDaysFromRange,
  getLeaveRange,
  calculateLeaveHours,
  getGoldenHourDates,
  updateRequestType,
  sanitiseDateTimes,
  sanitiseDateTimesAndCalculateHours
}

export default leaveHelpers; 