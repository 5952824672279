import interventionActions from "areas/planner/actions/interventions/interventionActions";
import { InterventionProgressRating } from "areas/planner/types/interventions/interventionShared";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import {
  ActionBar,
  Button,
  Dropdown,
  Left,
  Right,
  Size,
  Swatches,
} from "ui-kit";
import InterventionProgressDropdown from "./interventionProgressDropdown";


interface InterventionProgressOption {
  key: number;
  label: string;
  value: number;
}

interface IInterventionsFilterProps {
  handleCreate: () => void;
  loading?: boolean;
}


const InterventionsFilter: React.FC<IInterventionsFilterProps> = ({ handleCreate, loading }) => {
  
  const { filter } = useSelector((state: RootState) => state.groupInterventionsFilter);
  const [progressOptions, setProgressOptions] = useState<InterventionProgressOption[]>(() => {
    var opts: InterventionProgressOption[] = [];

    opts.push({
      key: -1,
      label: "All",
      value: -1,
    });
    
    for (var ivProg in InterventionProgressRating) {
      if (!isNaN(Number(ivProg))) {
        opts.push({
          key: Number(ivProg),
          label: InterventionProgressRating[ivProg],
          value: Number(ivProg),
        });
      }
    }

    return opts;
  });

  const handleChange = (value: number) => {
    interventionActions.filterByProgress(value);
  };

  return (
    <ActionBar>
      <Left>
        <ActionBar.Filter>
          <ActionBar.FilterItem>
            <InterventionProgressDropdown 
              value={filter.progress}
              onChange={handleChange}
              showAllOption
            />
          </ActionBar.FilterItem>
        </ActionBar.Filter>
      </Left>
      <Right>
        <Button
          text={"Create New Record"}
          onClick={handleCreate}
          size={Size.Small}
          color={Swatches.Primary}
          working={loading}
        />
      </Right>
    </ActionBar>
  )
}

export default InterventionsFilter;