import React from "react";
import BehaviourPointFormWrapper from "./behaviourPointFormWrapper";
import StudentModal from "sharedComponents/common/users/studentModal";
import { BehaviourCategoryGroupByListView, SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";
import { StudentListView } from "types/users/userListViews.types";
import { GroupType } from "types/users/userGroups.types";


interface IAddBehaviourPointModalProps {
  open: boolean;
  handleCloseModal: () => void;
  selectedCategory: SchoolBehaviourCategoryListView;
  onSelectedCategoryChange: (category: SchoolBehaviourCategoryListView) => void;
  selectedBehaviourGroupKey: number;
  student: StudentListView,
  behaviourCategoryGroups: BehaviourCategoryGroupByListView[];
  groupId: number;
  groupType: GroupType;
}


const AddBehaviourPointModal: React.FC<IAddBehaviourPointModalProps> = ({
  open,
  handleCloseModal,
  selectedCategory,
  onSelectedCategoryChange,
  selectedBehaviourGroupKey,
  student,
  behaviourCategoryGroups,
  groupId,
  groupType,
}) => {

  const handleBehaviourCategoryChange = (behaviourCategoryId: number) => {
    const behaviourCategories = behaviourCategoryGroups.find(
      group => group.behaviourCodeId === selectedBehaviourGroupKey
    ).behaviourCategories;

    for (let i = 0; i < behaviourCategories.length; i++) {
      if (behaviourCategories[i].id === behaviourCategoryId) {
        onSelectedCategoryChange(behaviourCategories[i]);
        break;
      }
    }
  };

  return selectedCategory || selectedBehaviourGroupKey ? (
    <StudentModal
      title={
        selectedCategory
          ? `${selectedCategory.codeDisplayName}: ${selectedCategory.behaviourCategoryName}`
          : "Behaviour"
      }
      onClose={handleCloseModal}
      open={open}
      height="90%"
      width="75%"
      student={student}
    >
      <BehaviourPointFormWrapper
        student={student}
        selectedCategory={selectedCategory}
        handleBehaviourCategoryChange={handleBehaviourCategoryChange}
        selectedBehaviourGroupKey={selectedBehaviourGroupKey}
        behaviourCategories={
          behaviourCategoryGroups.find(
            group => group.behaviourCodeId === selectedBehaviourGroupKey
          ).behaviourCategories
        }
        handleCloseModal={handleCloseModal}
        groupId={groupId ? groupId : null}
        groupType={groupType !== null ? groupType : null}
      />
    </StudentModal>
  ) : null;
};

export default AddBehaviourPointModal;
