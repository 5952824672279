import { Card, Subtitle, Table, StructuredList } from "ui-kit";
import {
  formatTime,
  formatDate,
  getDurationInHoursAndMins,
} from "utils/dateTime";
import DetentionStatusChip from "areas/behaviour/components/detentions/detentionStatusChip";
import Avatar from "sharedComponents/common/users/avatar";
import { DetentionView } from "areas/behaviour/types/behaviourResponses.types";
import React from "react";


interface IReadOnlyCancelledDetentionDetailsProps {
  detentions: DetentionView[];
}


const ReadOnlyCancelledDetentionDetails: React.FC<IReadOnlyCancelledDetentionDetailsProps> = ({ detentions }) => {

  if (detentions.length === 0) {
    return (<></>);
  }

  return detentions?.length === 1 ? (
    <Card title="Cancelled Detention">
      <Card.Body>
        <StructuredList>
          <StructuredList.Item name={"Detention Status"}>
            <DetentionStatusChip
              detentionStatus={detentions[0].statusId}
              issuedText="Issued"
              cancelledText="Cancelled"
            />
          </StructuredList.Item>
          <StructuredList.Item name={"Type"}>
            <>{detentions[0].detentionTypeName}</>
          </StructuredList.Item>
          <StructuredList.Item name={"Date"}>
            <>{formatDate(detentions[0].detentionInformation.startDate)}</>
          </StructuredList.Item>
          <StructuredList.Item name={"Time"}>
            <>{formatTime(detentions[0].detentionInformation.startDate)}</>
          </StructuredList.Item>
          <StructuredList.Item name={"Cancelled By"}>
            <Avatar
              user={detentions[0].cancelledBy}
              sub={`${formatDate(detentions[0].cancelledDate)} ${formatTime(detentions[0].cancelledDate)}`}
            />
          </StructuredList.Item>
          <StructuredList.Item name={"Cancellation Notes"}>
            <>{detentions[0].cancelNote}</>
          </StructuredList.Item>
        </StructuredList>
      </Card.Body>
    </Card>
  ) : (
    <>
      <Subtitle text="Cancelled Detentions" />
      <Table>
        <Table.Header>
          <Table.HeaderCell width={0.5}>Status</Table.HeaderCell>
          <Table.HeaderCell width={0.5}>Type</Table.HeaderCell>
          <Table.HeaderCell width={0.5}>Date</Table.HeaderCell>
          <Table.HeaderCell width={0.5}>Time</Table.HeaderCell>
          <Table.HeaderCell width={1}>Cancelled By</Table.HeaderCell>
          <Table.HeaderCell width={1}>Cancellation Notes</Table.HeaderCell>
        </Table.Header>

        <Table.Body>
          {detentions.map(detention => (
            <Table.Row key={detention.detentionId}>
              <Table.Cell>
                <DetentionStatusChip
                  detentionStatus={detention.statusId}
                  issuedText="Issued"
                  cancelledText="Cancelled"
                />
              </Table.Cell>
              <Table.Cell>
                <>{detention.detentionTypeName}</>
              </Table.Cell>
              <Table.Cell>
                <>{formatDate(detention.detentionInformation.startDate)}</>
              </Table.Cell>
              <Table.Cell>
                <>{formatTime(detention.detentionInformation.startDate)}</>
              </Table.Cell>
              <Table.Cell>
                <Avatar
                  user={detention.cancelledBy}
                  sub={`${formatDate(detention.cancelledDate)} ${formatTime(detention.cancelledDate)}`}
                />
              </Table.Cell>
              <Table.Cell>
                <>{detention.cancelNote}</>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};


export default ReadOnlyCancelledDetentionDetails;
