import React, { useState } from "react";
import PropTypes from "prop-types";
import ActivityFeedItem from "./activityFeedItem";
import { Button, Loader } from "ui-kit";
import styled from "styled-components";
import { Size } from "ui-kit/common/size";
import studentNoteActions from "../../actions/student/studentNoteActions";
import studentQuizActions from "../../actions/student/studentQuizActions";
import studentAwardActions from "../../actions/student/studentAwardActions";
import studentLeavingLessonActions from "../../actions/student/studentLeavingLessonActions";
import { FEED_TYPE_KEY } from "../../constants/activityFeed";
import FeedItemDetailsModal from "./feedItemDetailsModal";
import FeedEditModal from "./feedEditModal";
import UpscaleBehaviourModal from "areas/behaviour/components/behaviours/upscale/upscaleBehaviourModal";

const LoadMoreButton = styled.div`
  display: flex;
  justify-content: center;
`;

const ActivityFeed = ({
  activities, //StudentFeedListView[] 
  displayStudentName,
  loading,
  pagesAvailable,
  handleLoadMore,
  schoolId,
}) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openUpscaleModal, setOpenUpscaleModal] = useState(false);
  const [openFeedItemModal, setOpenFeedItemModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);

  const handleDeleteActivity = (feedType, studentId, feedId, onSuccess) => {
    switch (feedType) {
      case FEED_TYPE_KEY.NOTES:
        studentNoteActions.deleteStudentNote(studentId, feedId, onSuccess);
        break;
      case FEED_TYPE_KEY.QNA:
        studentQuizActions.deleteStudentAnswer(studentId, feedId, onSuccess);
        break;
      case FEED_TYPE_KEY.AWARDS:
        studentAwardActions.deleteStudentAward(studentId, feedId, onSuccess);
        break;
      case FEED_TYPE_KEY.LEFT_LESSON:
        studentLeavingLessonActions.deleteLeavingLessonRecord(
          studentId,
          feedId,
          onSuccess
        );
        break;
      default:
        break;
    }
  };

  const handleEditActivity = activity => {
    setSelectedActivity({ ...activity, schoolId: schoolId });
    setOpenEditModal(true);
  };

  const handleUpscaleBehaviour = activity => {
    setSelectedActivity(activity);
    setOpenUpscaleModal(true);
  };

  const handleFeedClick = activity => {
    setSelectedActivity({ ...activity, schoolId: schoolId });
    setOpenFeedItemModal(true);
  };

  return (
    <>
      {activities.map((activity, index) => (
        <ActivityFeedItem
          key={index}
          activity={activity}
          handleDeleteActivity={handleDeleteActivity}
          handleEditActivity={handleEditActivity}
          handleUpscaleBehaviour={handleUpscaleBehaviour}
          handleFeedClick={handleFeedClick}
          displayStudentName={displayStudentName ? true : false}
        />
      ))}

      {loading && <Loader size={Size.Medium} fluid />}

      <LoadMoreButton>
        {!loading && pagesAvailable && (
          <Button text="Load More" onClick={handleLoadMore} />
        )}
      </LoadMoreButton>

      <br />

      <FeedEditModal
        activity={selectedActivity}
        open={openEditModal}
        handleCloseModal={() => setOpenEditModal(false)}
      />

      {openUpscaleModal && (
        <UpscaleBehaviourModal
          activity={selectedActivity}
          open={openUpscaleModal}
          onClose={() => setOpenUpscaleModal(false)}
        />
      )}

      {openFeedItemModal && (
        <FeedItemDetailsModal
          activity={selectedActivity}
          open={openFeedItemModal}
          handleCloseModal={() => setOpenFeedItemModal(false)}
        />
      )}
    </>
  );
};

ActivityFeed.propTypes = {
  activities: PropTypes.array,
  displayStudentInfo: PropTypes.bool,
  loading: PropTypes.bool,
  pagesAvailable: PropTypes.bool,
  handleLoadMore: PropTypes.func,
};

export default ActivityFeed;
