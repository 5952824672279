import { OrderListView, ProductListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Flyout,
  ActionBar,
  Button,
  Swatches,
  LoadingWrapper,
  DetailLabel,
  HeadlineStatistic,
  Currency,
  Card,
  Size,
  Table,
  StructuredList,
  Chip
} from "ui-kit";
import { Constants } from "configuration";
import flyoutActions from "actions/ui/flyouts";
import paymentActions from "areas/payments/actions/paymentsActions";
import { useNavigate } from "react-router";
import OrderStatus from "./orderStatus";
import Moment from "react-moment";
import { RoleView, SchoolIcon, UserName } from "sharedComponents/common";
import { RootState } from "reducers/store";
import { UserTinyView } from "types/users/userListViews.types";


interface IOrderFlyoutProps {
  order: OrderListView;
}


const OrderFlyout: React.FC<IOrderFlyoutProps> = ({ order }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { loading, error, order: orderDetail } = useSelector((state: RootState) => state.userOrder);
  const navigate = useNavigate();

  useEffect(() => {
    order && paymentActions.getOrder(order.user.id, order.id);
  }, [order]);

  const handleClose = () => {
    flyoutActions.closeFlyout();
  };

  const handleFullDetailsClicked = () => {
    flyoutActions.closeFlyout();
    navigate(`/main/shopkeeper/users/${order.user.id}/orders/${order.id}`);
  };

  const handleUserClicked = (user: UserTinyView) => {
    flyoutActions.closeFlyout();
    navigate(`/main/shopkeeper/users/${user.id}`);
  };

  const handleProductClicked = (product: ProductListView) => {
    flyoutActions.closeFlyout();
    navigate(`/main/shopkeeper/category/${product.category.id}/products/${product.id}`);
  };

  return (
    <Flyout
      title={`Order ${order && order.id}`}
      titleSub={`${order && order.user.firstName + " " + order.user.lastName}`}
      name={Constants.FLYOUTS.ORDER}
      wide
    >
      <Flyout.Body>
        <LoadingWrapper
          loading={loading}
          error={error != null}
          data={orderDetail}
          errorMessage="There was a problem loading the order"
          emptyMessage="The requested order was not found"
          emptyIcon="shopping-bag"
        >
          {orderDetail && (
            <>
              <HeadlineStatistic>
                <HeadlineStatistic.Item
                  icon="receipt"
                  label="Total"
                  value={<Currency value={orderDetail.total} />}
                />
                {orderDetail.outstanding > 0 && (
                  <>
                    <HeadlineStatistic.Item
                      icon="receipt"
                      label="Paid"
                      value={<Currency value={orderDetail.paid} />}
                    />
                    <HeadlineStatistic.Item
                      icon="receipt"
                      label="Remaning"
                      value={<Currency value={orderDetail.outstanding} />}
                    />
                  </>
                )}

                {orderDetail.refunded > 0 && (
                  <HeadlineStatistic.Item
                    icon="receipt"
                    value={<Currency value={orderDetail.refunded} />}
                    label="Refunded"
                  />
                )}
              </HeadlineStatistic>

              <Card>
                <Card.Body>
                  <StructuredList>
                    <StructuredList.Item name="Order Date">
                      <Moment
                        date={orderDetail.date}
                        format="DD/MM/YYYY HH:mma"
                      />
                    </StructuredList.Item>

                    <StructuredList.Item name="Order Status">
                      <OrderStatus status={orderDetail.status} />
                    </StructuredList.Item>
                    <StructuredList.Item name="Basket ID">
                      {orderDetail.initialBasket?.id}
                    </StructuredList.Item>
                    <StructuredList.Item name="Order Subsidies">
                      {orderDetail.orderType == 1 && (
                        <p>None</p>
                      )}
                      {orderDetail.orderType == 1 && (
                        <Chip text="Pupil Premium" colorSwatch={Swatches.Purple} />
                      )}
                      {orderDetail.orderType == 2 && (
                        <Chip text="Bursary" colorSwatch={Swatches.DeepPink} />
                      )}
                    </StructuredList.Item>
                  </StructuredList>
                </Card.Body>
              </Card>
              <Table zebra caption="Items Ordered">
                <Table.Header>
                  <Table.HeaderCell width={0.5} />
                  <Table.HeaderCell width={4}>Description</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Ordered For</Table.HeaderCell>
                  <Table.HeaderCell width={0.5} right>
                    Qty
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} right>
                    Unit Price
                  </Table.HeaderCell>
                  <Table.HeaderCell right width={1}>
                    Total
                  </Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                  {orderDetail.lines?.map((line, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>
                        <SchoolIcon
                          code={line?.product?.category?.school.code}
                          size={Size.Small}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <RoleView
                          inverse
                          roles={[Constants.ROLES.FINANCE_USER]}
                        >
                          <DetailLabel
                            label={line.description}
                            sub={line.product?.category?.name}
                            bold
                          />
                        </RoleView>
                        <RoleView roles={[Constants.ROLES.FINANCE_USER]}>
                          <DetailLabel
                            label={line.description}
                            sub={line.product?.category?.name}
                            onClick={
                              line.type === "Product"
                                ? () => handleProductClicked(line.product)
                                : null
                            }
                            bold
                          />
                        </RoleView>
                      </Table.Cell>
                      <Table.Cell>
                        <RoleView roles={[Constants.ROLES.FINANCE_USER]}>
                          <UserName
                            user={line.purchasedFor}
                            onClick={() => handleUserClicked(line.purchasedFor)}
                          />
                        </RoleView>
                        <RoleView
                          roles={[Constants.ROLES.FINANCE_USER]}
                          inverse
                        >
                          <UserName user={line.purchasedFor} />
                        </RoleView>
                      </Table.Cell>
                      <Table.Cell right>
                        <p>{line.quantity}</p>
                      </Table.Cell>
                      <Table.Cell right>
                        <Currency value={line.unitPrice} />
                      </Table.Cell>
                      <Table.Cell right>
                        <b>
                          <Currency value={line.total} />
                        </b>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          )}
        </LoadingWrapper>
      </Flyout.Body>
      <Flyout.Footer>
        <ActionBar low>
          <RoleView roles={[Constants.ROLES.FINANCE_USER]}>
            <Button
              text="View Full Details"
              onClick={handleFullDetailsClicked}
              color={Swatches.Success}
              fluid
            />
          </RoleView>
        </ActionBar>
      </Flyout.Footer>
    </Flyout>
  );
};

export default OrderFlyout;
