import React, { useState } from "react";
import { Card, Wizard } from "ui-kit";
import PasswordStrengthResult from "./passwordStrengthResult";
import PasswordGuidance from "./passwordGuidance";
import PasswordEditor, { PasswordObj } from "./passwordEditor";
import passwordActions from "areas/administration/actions/passwordActions";
import { useNavigate } from "react-router";
import { CheckPasswordResponse } from "types/users/userPassword.types";
import { CheckPasswordCommand } from "areas/administration/types/passwordRequest.types";

const PASSWORD_RESULT = {
  has8: false,
  has12: false,
  hasDigit: false,
  hasSpecial: false,
  hasUpper: false,
  hasLower: false,
};

interface IPasswordChangerProps {
  onPasswordChange: (password: string) => Promise<any>;
}

const PasswordChanger: React.FC<IPasswordChangerProps> = ({ onPasswordChange }) => {

  const [results, setResults] = useState<CheckPasswordResponse | null>(null);
  const [working, setWorking] = useState<boolean>(false);
  const [password, setPassword] = useState<PasswordObj>({
    password: "",
    check: "",
    result: PASSWORD_RESULT,
  });
  const navigate = useNavigate();

  const changePassword = () => {
    setWorking(true);

    return new Promise((resolve, reject) => {
      if (!password.password) {
        reject("Please enter a password.");
      }
      if (!password.check) {
        reject("Please re-enter your password.");
      }
      if (password.check != password.password) {
        reject("Your passwords do not match.");
      }
      if (!password.result.has8) {
        reject("Please enter a password with at least 8 characters");
      }
      if (!password.result.hasDigit) {
        reject("Please enter a password with at least one number");
      }
      if (!password.result.hasLower) {
        reject("Please enter a password with at least one lower case letter");
      }
      if (!password.result.hasUpper) {
        reject("Please enter a password with at least one upper case letter");
      }
      if (!password.result.hasSpecial) {
        reject(
          "Please enter a password with at least one punctuation/special character"
        );
      }

      passwordActions.check(password.password)
        .then((response: CheckPasswordResponse) => {
          setResults(response);

          if (response.isCompromised) {
            reject(
              "This password appears on a list of compromised passwords. Please choose another password."
            );
          }
          onPasswordChange(password.password)
            .then(() => {
              resolve(response);
            })
            .catch(() => {
              reject(
                "Your password could not be changed at this time. Please check try again later."
              );
            });
        })
        .catch(error => {
          reject(
            "Your password could not be analysed at this time. Please check try again later."
          );
        })
        .finally(() => {
          setWorking(false);
        });
    });
  };

  const handleFinish = () => {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  };

  const handleComplete = () => {
    navigate(-1);
  };

  return (
    <Wizard
      showHeaderSteps={false}
      onFinish={handleFinish}
      onComplete={handleComplete}
      finishButtonText="Change My Password"
      completionButtonText="Finish"
      showBackButtonOnCompletion={true}
      working={working}
    >
      <Wizard.Step>
        <Card>
          <Card.Body>
            <p>
              On this page you can change your password. You will need to choose
              a strong password that has not been compromised.
            </p>

            <PasswordGuidance />
            <p>
              To change your password, click on <b>'Next'</b> below.
            </p>
          </Card.Body>
        </Card>
      </Wizard.Step>
      <Wizard.Step onValidate={changePassword}>
        <Card>
          <Card.Body>
            <p>
              To check your password, simply enter and then re-enter your
              password below and click on the <b>'Next'</b> button.
            </p>
            <p>
              Your password will be analysed and if the password is suitable it
              will be changed. If the password is not strong enough or is
              compromised, details of why your password is not suitable will be
              displayed.
            </p>

            <PasswordEditor onChange={password => setPassword(password)} />
          </Card.Body>
        </Card>
      </Wizard.Step>
      <Wizard.Step completion>
        <Card>
          <Card.Body>
            <p>
              Your password has been changed and confirmation sent to you by
              email. Your password analysis results are shown below.
            </p>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            {results && <PasswordStrengthResult result={results} />}
          </Card.Body>
        </Card>
      </Wizard.Step>
    </Wizard>
  );
};

export default PasswordChanger;
