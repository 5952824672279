import React from "react";
import PropTypes from "prop-types";
import { danger, honeygold, neutral, success } from "ui-kit/common/colors";
import styled from "styled-components";
import { BorderRadius, Stats } from "ui-kit";
import { MEDIA_CONSENT } from "areas/planner/constants/student/consent";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";
import { MediaConsentChoiceListView } from "types/users/userConsent.types";

const ColumnStatsWrapper = styled.div`
  background: ${neutral[300]};
  border-radius: ${BorderRadius.Default}px;
`;

export const getConsentChoices = (mediaConsent: number, mediaConsentChoices: MediaConsentChoiceListView[]) => {
  return mediaConsent === MEDIA_CONSENT.PARTIAL
    ? mediaConsentChoices.map((consentChoice, index) => (
        <React.Fragment key={index}>
          <br />
          {consentChoice.consentGiven ? (
            <p key={index}>
              <span className="tick" style={{ color: success }}>
                <b>&#10004;</b>
              </span>{" "}
              {consentChoice.text}
            </p>
          ) : (
            <p key={index}>
              <span className="cross" style={{ color: danger }}>
                <b>&#10006;</b>
              </span>{" "}
              {consentChoice.text}
            </p>
          )}
        </React.Fragment>
      ))
    : null;
};

const getMediaConsentInfo = (mediaConsent: number, mediaConsentChoices: MediaConsentChoiceListView[]) => {
  switch (mediaConsent) {
    case MEDIA_CONSENT.REFUSED:
      return {
        icon: "camera",
        value: "Not Given",
        title: "Media Consent",
        tooltip: "Not Given",
        tooltipSub: getConsentChoices(mediaConsent, mediaConsentChoices),
        color: danger,
      };

    case MEDIA_CONSENT.PARTIAL:
      return {
        icon: "camera",
        value: "Partial",
        title: "Media Consent",
        tooltip: "Partial Media Consent Given",
        tooltipSub: getConsentChoices(mediaConsent, mediaConsentChoices),
        color: honeygold,
      };

    case MEDIA_CONSENT.GIVEN:
      return {
        icon: "camera",
        value: "Given",
        title: "Media Consent",
        tooltip: "Consent Given",
        tooltipSub: getConsentChoices(mediaConsent, mediaConsentChoices),
        color: success,
      };

    default:
      return {
        icon: "camera",
        value: "Error",
        title: "Media Consent",
        tooltip: "Could not load media consent",
        tooltipSub: getConsentChoices(mediaConsent, mediaConsentChoices),
        color: neutral[500],
      };
  }
};

const getStats = (student: PlannerStudentDetailView) => {
  let stats = [
    {
      icon: "clipboard-check",
      value: `${student.pastoral.attendance}%`,
      title: "Attendance",
    },
    {
      icon: "clipboard",
      value: `${student.pastoral.lates}`,
      title: "Lates",
    },
    {
      icon: "clipboard",
      value: `${student.pastoral.absences}`,
      title: "Absences",
    },
    {
      icon: "thumbs-up",
      value: `${student.pastoral.rewardPoints}`,
      title: "Rewards",
    },
    {
      icon: "thumbs-down",
      value: `${student.pastoral.behaviourPoints}`,
      title: "Behaviours",
    },
    {
      icon: "shield-alt",
      value: `${student.totalBehaviourCount}`,
      title: "Behaviour Count",
    },
    getMediaConsentInfo(student.mediaConsent, student.mediaConsentChoices),
  ];

  if (student.readingAge) {
    stats.push({
      icon: "book",
      value: student.readingAge,
      title: "Reading Age",
    });
  }

  return stats;
};


interface IStudentStatsProps {
  student: PlannerStudentDetailView;
  column?: boolean;
  background?: boolean;
}

const StudentStats: React.FC<IStudentStatsProps> = ({ student, column, background }) => {

  return column ? (
    <ColumnStatsWrapper>
      <Stats
        items={[
          {
            icon: "clipboard-check",
            value: `${student.pastoral.attendance}%`,
            title: "Attendance",
          },
          {
            icon: "clipboard",
            value: `${student.pastoral.lates}`,
            title: "Lates",
          },
          {
            icon: "clipboard",
            value: `${student.pastoral.absences}`,
            title: "Absences",
          },
        ]}
      />
      <Stats
        items={[
          {
            icon: "thumbs-up",
            value: `${student.pastoral.rewardPoints}`,
            title: "Rewards",
          },
          {
            icon: "thumbs-down",
            value: `${student.pastoral.behaviourPoints}`,
            title: "Behaviours",
          },
        ]}
      />
      <Stats
        items={[
          getMediaConsentInfo(
            student.mediaConsent,
            student.mediaConsentChoices
          ),
        ]}
      />
      {student.readingAge && (
        <Stats
          items={[
            {
              icon: "book",
              value: student.readingAge,
              title: "Reading Age",
            },
          ]}
        />
      )}
    </ColumnStatsWrapper>
  ) : (
    <Stats items={getStats(student)} background={background} />
  );
};


export default StudentStats;
