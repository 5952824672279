import React from "react";
import { ReactComponent as CatSvg } from "assets/primary/characters/cat.svg";
import { IAnimalIconProps } from "./primaryCharacter";


export const Cat: React.FC<IAnimalIconProps> = ({ className }) => {
  
  return (
    <CatSvg 
    //alt={alt} 
    className={className} 
    />
  )
};
export default Cat;
