import defaults from "reducers/defaults";
import detentionReportActions from "../actions/detentionReportActions";
import { IBeehiveAction } from "types/common/action.types";
import { PageInfo } from "types/common/paging.types";
import { IBeehiveError } from "types/common/errors.types";
import { DetentionsDetailReportListView } from "../types/detentionAnalyticsResponse.types";


interface IDetentionsDetailedReportState {
  report: DetentionsDetailReportListView[] | null;
  paging: PageInfo;
  refresh: boolean;
  loading: boolean;
  loadingExportReport: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IDetentionsDetailedReportState = {
  report: null,
  paging: defaults.DEFAULT_PAGING,
  refresh: false,
  loading: false,
  loadingExportReport: false,
  error: null,
};


const detentionsDetailedReportReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IDetentionsDetailedReportState => {

  const { 
    GETDETENTIONSDETAILEDREPORT, 
    EXPORTDETENTIONSDETAILEDREPORT 
  } = detentionReportActions.types;

  switch (action.type) {
    case GETDETENTIONSDETAILEDREPORT.START:
      return { ...state, loading: true, error: null };

    case GETDETENTIONSDETAILEDREPORT.SUCCESS:
      return {
        ...state,
        report:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.report, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };

    case GETDETENTIONSDETAILEDREPORT.FAILED:
      return { ...state, loading: false, error: action.payload };

    case EXPORTDETENTIONSDETAILEDREPORT.START:
      return { ...state, loadingExportReport: true, error: null };

    case EXPORTDETENTIONSDETAILEDREPORT.SUCCESS:
      return { ...state, loadingExportReport: false };

    case EXPORTDETENTIONSDETAILEDREPORT.FAILED:
      return { ...state, loadingExportReport: false, error: action.payload };

    default:
      return state;
  }
};

export default detentionsDetailedReportReducer;
