import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import loginService from "services/authentication/loginService";
import client from "services/client";
import store from "reducers/store";
import { decodedToken } from "services/authentication/tokenStorage";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { StartupView } from "types/startup/startup.types";

const builder = new actionTypeBuilder("startup");

export const types = {
  GETUSER: builder.build("getuser"),
  ACCEPTABLEUSE_SUBMIT: builder.build("ACCEPTABLEUSE_SUBMIT"),
  STAFF_ROLESCHANGED: "STAFF_ROLESCHANGED",
  STAFF_SCHOOLSCHANGED: "STAFF_SCHOOLSCHANGED",
  STAFF_BASESCHOOLCHANGED: "STAFF_BASESCHOOLCHANGED",
  SETUSERFORNEWWINDOW: "SETUSERFORNEWWINDOW",
};


const getUser = (callback?: callbackType<StartupView>) => {
  const { id }: decodedToken = loginService.getAuthenticatedUser();

  if (!id) {
    throw new Error("No authentication token was found.");
  }

  return action<StartupView>(
    () => client.get(`startup/${id}`), 
    types.GETUSER,
    callback
  );
};

const submitAcceptableUse = (userId: string, policyId: number, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`acceptableuse`, { userId: userId, policyId: policyId }),
    types.ACCEPTABLEUSE_SUBMIT,
    callback
  );

const setUserForNewWindow = () => {
  store.dispatch<IBeehiveAction<{ userType: string }>>({
    type: types.SETUSERFORNEWWINDOW,
    payload: {
      userType: "Staff",
    },
  });
};

const startupActions = {
  types,
  getUser,
  submitAcceptableUse,
  setUserForNewWindow,
};

export default startupActions;