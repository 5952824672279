/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { 
  TextInput, 
  EmptyMessage, 
  neutral, 
  Spacing 
} from "../index";
import styled from "styled-components";
import lodashDebounce from "lodash.debounce";
import { SearchResultViewBase } from "types/common/views.types";

const Wrapper = styled.div<{fluid?: boolean}>`
`;

const ResultsWrapper = styled.div<{open: boolean}>`
  ${({ open }) => (open ? `display: block;` : `display: none;`)}

  position: absolute;
  width: 100%;
  z-index: 999;
  top: 100%;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background: ${neutral[200]};
  color: ${neutral[600]};
  background: white;
  max-height: 10rem;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: ${Spacing.Large}px;
  }

  ::-webkit-scrollbar-track {
    background: ${neutral[200]};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${neutral[500]};
    border: 5px solid ${neutral[200]};
    min-height: 50px;
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: ${neutral[400]};
  }
`;
const ResultsList = styled.ul`
  margin: 0 !important;
`;
const ResultsListItem = styled.li`
  list-style-type: none;
  padding: none;
`;

interface ISearchBoxProps<TResult> {
  onSearch: (value: string) => void;
  error?: string;
  loading?: boolean;
  results: TResult[];
  placeholder?: string;
  fluid?: boolean
  minSearchLength?: number;
  resultTemplate?: (result: TResult) => React.ReactNode;
  onResultClick: (result: TResult, index?: number) => void;
}

const SearchBox = <TResult extends SearchResultViewBase = any>({
  onSearch,
  error,
  loading,
  placeholder,
  results,
  fluid,
  minSearchLength,
  resultTemplate,
  onResultClick,
}: ISearchBoxProps<TResult>) => {

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [_results, _setResults] = useState<TResult[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const wrapperRef = useRef() as React.RefObject<HTMLDivElement>;

  useEffect(() => {
    _setResults(results);
  }, [results]);

  const reset = () => {
    setSearchTerm("");
    _setResults([]);
    setOpen(false);
  };

  const debouncedSearchChange = useCallback(
    lodashDebounce((value: string, callback: () => void) => {
      onSearch?.(value);
      callback && callback();
    }, 500),
    []
  );

  const handleSearchTermChange = (value: string) => {
    setSearchTerm(value);

    if (value?.length >= (minSearchLength ?? 0)) {
      debouncedSearchChange(value, () => {
        setOpen(true);
      });
    } else {
      _setResults([]);
      setOpen(false);
    }
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (wrapperRef && !wrapperRef?.current?.contains(e.target as Node)) {
      reset();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick); // add when mounted
    return () => {
      document.removeEventListener("click", handleOutsideClick); // return function to be called when unmounted
    };
  }, []);

  const handleResultClick = (result: TResult, index: number) => {
    reset();
    onResultClick?.(result, index);
  };

  const Results = () => {
    if (searchTerm.length < (minSearchLength ?? 0)) {
      return null;
    }

    if (error) {
      return (
        <EmptyMessage
          icon="times-circle"
          title="An error occured"
          summary={`There was a problem while searching`}
        />
      );
    }

    return (
      <ResultsList>
        {_results?.map((result, index) => (
          <ResultsListItem
            key={index}
            onClick={() => handleResultClick(result, index)}
          >
            {resultTemplate ? resultTemplate(result) : result.title}
          </ResultsListItem>
        ))}
      </ResultsList>
    );
  };

  return (
    <Wrapper className="search-box" ref={wrapperRef} fluid={fluid}>
      <TextInput
        value={searchTerm}
        fluid={fluid}
        placeholder={placeholder || "Search groups..."}
        onChange={handleSearchTermChange}
        loading={loading}
      />
      <ResultsWrapper open={open}>
        <Results />
      </ResultsWrapper>
    </Wrapper>
  );
};

export default SearchBox;
