import { Size } from "./size";

interface Typescale {
  header1: Typesize;
  header2: Typesize;
  header3: Typesize;
  header4: Typesize;
  header5: Typesize;
  paragraph: Typesize;
  helper: Typesize;
  sub: Typesize;
  control1: Typesize;
  control2: Typesize;
  control3: Typesize;
}

interface Typesize {
  size: number;
  lineHeight: number;
}

export const typescale: Typescale = {
  header1: {
    size: 1.5, // 34px/16px
    lineHeight: 1.75, // 36px
  },
  header2: {
    size: 1.25, // 24px/16px,
    lineHeight: 1.5, // 23px
  },
  header3: {
    size: 1.125, // 18px/16px,
    lineHeight: 1.25, // 20px
  },
  header4: {
    size: 0.875, // 14px/16px
    lineHeight: 1, // 16px
  },
  header5: {
    size: 0.5,
    lineHeight: 1,
  },
  paragraph: {
    size: 0.75, // 14px/16px
    lineHeight: 1.25, // 16px
  },
  helper: {
    size: 0.5, // 12px/16px
    lineHeight: 1, // 16px
  },
  sub: {
    size: 0.625, // 10px/16px
    lineHeight: 0.875, // 12px
  },
  control1: {
    size: 1, // 16px/16px
    lineHeight: 1, // 20px
  },
  control2: {
    size: 0.875, // 16px/16px
    lineHeight: 1, // 20px
  },
  control3: {
    size: 0.75, // 16px/16px
    lineHeight: 1, // 20px
  },
};

export enum TitleSize {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
}

export interface Font {
  name: string;
  weight: {};
}

const displayFontName = "neue-haas-grotesk-display";

export const displayFont = {
  thin: {
    name: displayFontName,
    weight: 200,
  },
  extraLight: {
    name: displayFontName,
    weight: 300,
  },
  light: {
    name: displayFontName,
    weight: 400,
  },
  roman: {
    name: displayFontName,
    weight: 500,
  },
  medium: {
    name: displayFontName,
    weight: 600,
  },
  bold: {
    name: displayFontName,
    weight: 800,
  },
  black: {
    name: displayFontName,
    weight: 900,
  },
};

const textFontName = "neue-haas-grotesk-text";
export const textFont = {
  roman: {
    name: textFontName,
    weight: 400,
  },
  medium: {
    name: textFontName,
    weight: 500,
  },
  bold: {
    name: textFontName,
    weight: 600,
  },
};

export enum IconStyle {
  Light = "fal",
  Regular = "far",
  Solid = "fas",
  Brand = "fab",
  Default = "fa",
}

export const getFontSize = (size: Size): number => {
  switch (size) {
    case Size.ExtraLarge:
      return typescale.header1.size;
    case Size.Large:
      return typescale.header2.size;
    case Size.Medium:
      return typescale.header3.size;
    case Size.Small:
      return typescale.paragraph.size;
    case Size.ExtraSmall:
      return typescale.helper.size;
    default:
      return typescale.paragraph.size;
  }
};

export const fontStyle = (
  font: Font,
  size: Typesize,
  color?: string
): string => {
  var css = `
  font-family: ${font.name};
  font-weight: ${font.weight};
  ${fontSize(size)}`;

  if (color) {
    css = css + `color: ${color};`;
  }

  return css;
};

export const fontSize = (size: Typesize): string => {
  return `font-size: ${size.size}rem;
    line-height: ${size.lineHeight}rem;`;
};
