import React from "react";
import { ReactComponent as CatSvg } from "assets/primary/rockets/cat.svg";
import { IPrimaryRocketCharacterProps } from "./primaryRocket";


export const Cat: React.FC<IPrimaryRocketCharacterProps> = ({ className }) => {

  return <CatSvg className={className} />;
};

export default Cat;
