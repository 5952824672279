import moment, { unitOfTime } from "moment";
import { DateRange } from "types/common/data.types";
import strings from "./strings";
import specialDayActions from "areas/planner/actions/specialDayActions";
import config from "configuration";
import { SpecialDayType } from "areas/planner/types/termDatesShared.types";
import { SpecialDayListView } from "areas/planner/types/termDatesResponse.types";

/**
 * @returns {string} Todays date
 */
export const todayDate = (): string => {
  return moment().format("DD/MM/YYYY");
};

/**
 * @returns {string} Current time
 */
export const todayTime = (): string => {
  return moment().format("HH:mm");
};

/**
 * @returns {string} Todays date and current time
 */
export const todayDateTime = (): string => {
  return moment().format();
};

/**
 * @param {moment.MomentInput} date A string version of the date to format
 * @returns {string} The given date in day/month/year format
 */
export const formatDate = (date: moment.MomentInput): string => {
  return moment(date).format("DD/MM/YYYY");
};

/**
 * @param {moment.MomentInput} time A string version of the time to format
 * @returns {string} The given time in 24 hour format
 */
export const formatTime = (time: moment.MomentInput): string => {
  return moment(time).format("HH:mm");
};

/**
 * @param {moment.MomentInput} date A string version of the date to format
 * @param {string} time A string version of the time to format
 * @returns {string} The given date in day/month/year format and the given time in 24 hour format
 */
export const getDateTime = (date: moment.MomentInput, time: string): string => {
  return moment(date + " " + time, ["DD/MM/YYYY HH:mm"]).format();
};

export const getDurationInHoursAndMins = (duration: number): string => {
  const hours = Math.floor(duration / 60);
  let hoursText = "";
  if (hours) {
    hoursText = `${hours} ${hours > 1 ? "hours" : hours > 0 && "hour"}`;
  }

  const mins = duration % 60;
  let minsText = "";
  if (mins) {
    minsText = `${mins} ${mins > 1 ? "minutes" : mins > 0 && "minute"}`;
  }

  return hoursText + " " + minsText;
};


export const dateRangesOverlap = (dateRangeOne: DateRange, dateRangeTwo: DateRange): boolean => {

  var dr1Start = moment(dateRangeOne.startDate).toDate();
  var dr1End = moment(dateRangeOne.endDate).toDate();

  var dr2Start = moment(dateRangeTwo.startDate).toDate();
  var dr2End = moment(dateRangeTwo.endDate).toDate();

  return (dr2End <= dr1End && dr2End >= dr1Start) || (dr2Start >= dr1Start && dr2Start <= dr1End);
}


export const dateRangeIncludes = (dateRange: DateRange, date: Date): boolean => {

  var starts = moment(dateRange.startDate).toDate();
  var ends = moment(dateRange.endDate).toDate();
  var dateTime = moment(date).toDate();

  return (starts <= dateTime) && (dateTime <= ends);
}


export const stringToDate = (dateString: string): Date => {
  return moment(dateString).toDate();
}

/**
 * @param {Date} startDate The start date
 * @param {Date} endDate The end date
 */
export const getDateRange = (startDate: moment.MomentInput, endDate: moment.MomentInput): Date[] => {
  const _startDate = moment(startDate);
  const _endDate = moment(endDate);
  const diff = _endDate.diff(_startDate, "days");
  let range: Date[] = [ _startDate.toDate() ]
  for (let i = 1; i < diff; i++) {
    range.push(_startDate.add(i, "days").hours(8).toDate())
  }
  range.push(_endDate.toDate());
  return range
}


const decimalDaysToDaysAndHours = (decimalDay: number, workingHours: number = 8) => {
  const _days = Math.floor(decimalDay / 1);
  const _hours = (decimalDay % 1) * workingHours;
  const showS = (time: number) => (time == 1 ? "" : "s");
  const showUnit = (title: string, unit: number) => unit > 0 ? `${unit} ${title}${showS(unit)}` : "";
  return `${showUnit("Day", _days)} ${showUnit("Hour", _hours)}`;
}


const hoursToDaysAndHours = (hours: number, workingHours: number = 8) => {
  if (hours === 0) {
    return "0 Hours";
  }
  const _days = Math.floor(hours / workingHours);
  const { hours: _hours, minutes: _minutes } = decimalHoursToHoursAndMinutes(hours % workingHours);

  const showS = (time: number) => (time == 1 ? "" : "s");
  const showUnit = (title: string, unit: number) => unit > 0 ? `${unit} ${title}${showS(unit)}` : "";

  var returnString = "";
  returnString += showUnit("Day", _days);
  if (_days && _hours) {
    returnString += ", ";
  }
  returnString += showUnit("Hour", _hours);
  if (_hours && _minutes) {
    returnString += ", ";
  }
  returnString += showUnit("Minute", _minutes);
  
  return returnString;
}


const decimalHoursToHoursAndMinutes = (decimalHours: number) => {
  decimalHours = decimalHours * 60 * 60;
  var hours = Math.floor((decimalHours / (60 * 60)));
  decimalHours = decimalHours - (hours * 60 * 60);
  var minutes = Math.floor((decimalHours / 60));
  decimalHours = decimalHours - (minutes * 60);
  var seconds = Math.round(decimalHours);
  return {
    hours: hours,
    minutes: minutes,
    seconds: seconds
  }
}


const isSameDay = (dateOne: moment.MomentInput, dateTwo: moment.MomentInput) => {
  return moment(dateOne).isSame(dateTwo, 'day');
}


const isWeekend = (date: moment.MomentInput) => {
  var _date = moment(date);
  return _date.day() === 6 || _date.day() === 0;
}


const isBankHoliday = (date: moment.MomentInput, specialDays: SpecialDayListView[]) => {
  return specialDays.some(specialDay => isSameDay(specialDay.starts, date) && specialDay.specialDayTypeId === SpecialDayType.BankHoliday);
}


const getTimeDiff = (dateOne: moment.MomentInput, dateTwo: moment.MomentInput, unitOfTime: unitOfTime.Diff) => {
  var _dateOne = moment(dateOne);
  var _dateTwo = moment(dateTwo);

  var timeOne = moment()
    .hour(_dateOne.hour())
    .minute(_dateOne.minute())
    .second(_dateOne.second());

  var timeTwo = moment()
    .hour(_dateTwo.hour())
    .minute(_dateTwo.minute())
    .second(_dateTwo.second());
    
  return timeTwo.diff(timeOne, unitOfTime)
}

const getNextWorkingDay = (
  date: moment.MomentInput = Date.now(), 
  hour: number = 8, 
  format: string = "YYYY-MM-DD HH:mm",
) => {
  const _date = moment(date);
  // 0 corresponds to Sunday and 6 corresponds to Saturday
  switch (_date.day()) {
    case 5:
    case 6:
      return _date.weekday(8).hour(hour).minute(0).format(format);
    default:
      return _date.add(1, "day").hour(hour).minute(0).format(format)
  }
};


const methods = {
  todayDate,
  todayTime,
  todayDateTime,
  formatDate,
  formatTime,
  getDateTime,
  dateRangesOverlap,
  dateRangeIncludes,
  stringToDate,
  getDateRange,
  decimalDaysToDaysAndHours,
  hoursToDaysAndHours,
  isSameDay,
  decimalHoursToHoursAndMinutes,
  isWeekend,
  isBankHoliday,
  getTimeDiff,
  getNextWorkingDay
};

export default methods;
