import { EmptyMessage } from "ui-kit";
import { Loader } from "ui-kit";
import { Size } from "ui-kit/common/size";
import { useAppSelector } from "reducers/hooks";


const NoGroup: React.FC = () => {

  const { groups } = useAppSelector(state => state.groups);
  const {
    group,
    getGroupError,
    loading: loadingGroup,
  } = useAppSelector(state => state.group);


  return loadingGroup ? (
    <Loader size={Size.Large} cover />
  ) : group === null && !getGroupError && !loadingGroup ? (
    groups.length > 0 ? (
      <EmptyMessage icon="users-class" title="Please select a group" cover />
    ) : (
      <EmptyMessage
        icon="users-class"
        title="No Groups"
        summary="There are no groups for you"
        cover
      />
    )
  ) : (
    <></>
  );
  
};

export default NoGroup;
