import React, { useEffect, useState } from "react";
import moment from "moment";
import EditBehaviourStatusWrapper from "./editBehaviourStatusWrapper";
import IncidentFormWrapper from "../incidentFormWrapper";
import BehaviourOutcomeDropdown from "../behaviourOutcomeDropdown";
import { todayDateTime } from "utils/dateTime";
import InitialBehaviourActionWrapper from "../initialBehaviourActionWrapper";
import InternalNoteFormField from "../../behaviourCommon/internalNoteFormField";
import {
  StructuredList,
  Card,
  Spacing,
  ValidationMessage,
  ActionBar,
  Button,
  Swatches,
  Right,
  Size,
} from "ui-kit";
import styled from "styled-components";
import PublicNotesFormField from "../../behaviourCommon/publicNotesFormField";
import DetentionNotesFormField from "../../detentions/detentionNotesFormField";
import {
  DETENTION_STATUS_KEYS,
  DETENTION_CATEGORIES,
} from "areas/behaviour/constants/detentions";
import ReadOnlyDetentionDetails from "areas/behaviour/components/readOnly/readOnlyDetentionDetails";
import DetentionFormWrapper from "../../detentions/detentionFormWrapper";
import PointsDropdown from "../../behaviourCommon/pointsDropdown";
import ParentNotificationNote from "../../behaviourCommon/parentNotificationNote";
import ReadOnlyInvestigationNote from "areas/behaviour/components/readOnly/readOnlyInvestigationNote";
import ConfirmModal from "sharedComponents/common/confirmModal";
import { getIncidentDetails, readyToSubmit } from "../../../utils/behaviours";
import { IBehaviourDetails, IDetentionDetails, IIncidentDetails, getDetentionDetails } from "../../../utils/detentions";
import { StudentFeedListView } from "types/planner/feedResponse.types";
import { BehaviourView } from "areas/behaviour/types/behaviourResponses.types";


const Wrapper = styled.div`
  display: flex;

  .behaviour-form {
    flex-grow: 2;
    margin-right: ${Spacing.Medium}px;
    padding-bottom: ${Spacing.Large}px;
  }
`;


interface IEditBehaviourProps {
  studentId: string;
  handleCloseModal: () => void;
  activity: StudentFeedListView;
  behaviour: BehaviourView;
  handleUpdateBehaviour: (behaviourDetails: IBehaviourDetails) => void;
  isPrimary: boolean;
  checkValidations: (incidentDetails: IIncidentDetails, detentionDetails: IDetentionDetails) => string[];
  schoolId: number;
}


const EditBehaviour: React.FC<IEditBehaviourProps> = ({
  studentId,
  handleCloseModal,
  activity,
  behaviour,
  handleUpdateBehaviour,
  isPrimary,
  checkValidations,
  schoolId,
}) => {

  const [behaviourDetails, setBehaviourDetails] = useState<IBehaviourDetails>({
    points: null,
    outcome: null,
    initialAction: {
      initialActionId: null,
      initialActionName: "",
    },
    publicNotes: "",
    internalNotes: "",
    incidentDetails: null,
    detentionDetails: null,
    class: null
  });

  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    /* 
      if there are more than one detentions/sanctions or if the detention is an IE then don't let user amend 
      (there is an assumption that if there is more than one sanction then it will be IE/Reflection 
      as users can only created multiple sanctions for IE)
    */
    if (behaviour.detentions?.length === 1) {
      const detention = behaviour.detentions[0];
      setReadOnly(
        moment(
          behaviour.detentions[0].detentionInformation.startDate
        ).format() < todayDateTime() ||
          detention.statusId === DETENTION_STATUS_KEYS.CANCELLED ||
          detention.statusId === DETENTION_STATUS_KEYS.RESCHEDULED
      );
    } else if (behaviour.detentions?.length > 1) {
      setReadOnly(true);
    }

    setBehaviourDetails({
      ...behaviourDetails,
      incidentDetails: getIncidentDetails(behaviour),
      internalNotes: behaviour.behaviourDescription,
      publicNotes: behaviour.publicNotes,
      points: behaviour.points,
      initialAction: {
        initialActionId: behaviour.initialActionId,
        initialActionName: behaviour.initialActionName,
      },
      outcome: behaviour.outcomeId,
      detentionDetails:
        behaviour.detentions?.length === 1
          ? getDetentionDetails(behaviour.detentions[0])
          : null,
    });
    setValidationErrors([]);
  }, [behaviour]);

  const validateAndOpenConfirm = () => {
    let errors = checkValidations(
      behaviourDetails.incidentDetails,
      behaviourDetails.detentionDetails
    );
    if (errors.length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors([]);
      setOpenConfirm(true);
    }
  };

  return (
    <>
      <Wrapper>
        <div className="behaviour-form">
          {readOnly ? (
            <ReadOnlyDetentionDetails detentions={behaviour.detentions} />
          ) : (
            behaviourDetails.detentionDetails && (
              <>
                <DetentionFormWrapper
                  studentId={studentId}
                  schoolId={schoolId}
                  detentionDetails={behaviourDetails.detentionDetails}
                  setDetentionDetails={detentionDetails =>
                    setBehaviourDetails({
                      ...behaviourDetails,
                      detentionDetails: detentionDetails,
                    })
                  }
                />

                <DetentionNotesFormField
                  notes={
                    behaviourDetails.detentionDetails
                      ? behaviourDetails.detentionDetails.detentionNotes
                      : ""
                  }
                  handleNotesChange={detentionNotes =>
                    setBehaviourDetails({
                      ...behaviourDetails,
                      detentionDetails: {
                        ...behaviourDetails.detentionDetails,
                        detentionNotes: detentionNotes,
                      },
                    })
                  }
                  detentionStatus={activity.detentionStatusId}
                />
              </>
            )
          )}

          {behaviourDetails.incidentDetails && (
            <Card title="Incident Details">
              <Card.Body>
                <IncidentFormWrapper
                  incidentDetails={behaviourDetails.incidentDetails}
                  setIncidentDetails={incidentDetails =>
                    setBehaviourDetails({
                      ...behaviourDetails,
                      incidentDetails: incidentDetails,
                    })
                  }
                  detentionDetails={behaviourDetails.detentionDetails}
                  groupId={activity.classGroup ? activity.classGroup.id : null}
                  schoolId={schoolId}
                />
              </Card.Body>
            </Card>
          )}

          <Card title="Behaviour Details">
            <Card.Body>
              {isPrimary && behaviour.allowOverridePointsForPrimary && (
                <StructuredList.Item name="Points" required>
                  <PointsDropdown
                    points={behaviourDetails.points}
                    setPoints={points =>
                      setBehaviourDetails({
                        ...behaviourDetails,
                        points: points,
                      })
                    }
                    maxPoints={behaviour.maxPoints}
                  />
                </StructuredList.Item>
              )}

              <StructuredList.Item name="Outcome" required>
                <BehaviourOutcomeDropdown
                  outcome={behaviourDetails.outcome}
                  handleChangeOutcome={outcome =>
                    setBehaviourDetails({
                      ...behaviourDetails,
                      outcome: outcome,
                    })
                  }
                />
              </StructuredList.Item>

              <InitialBehaviourActionWrapper
                selectedInitialAction={behaviourDetails.initialAction}
                handleChangeInitialAction={initialAction =>
                  setBehaviourDetails({
                    ...behaviourDetails,
                    initialAction: initialAction,
                  })
                }
              />

              <StructuredList.Item
                name="Public Notes"
                description="Maximum 250 characters. (These notes will be sent/displayed to parents)"
                helpPopup
              >
                <PublicNotesFormField
                  notes={behaviourDetails.publicNotes}
                  handleNotesChange={notes =>
                    setBehaviourDetails({
                      ...behaviourDetails,
                      publicNotes: notes,
                    })
                  }
                />
              </StructuredList.Item>

              <StructuredList.Item
                name="Internal Notes"
                description="(These notes will not be sent/displayed to parents)"
                helpPopup
              >
                <InternalNoteFormField
                  notes={behaviourDetails.internalNotes}
                  handleNoteChange={notes =>
                    setBehaviourDetails({
                      ...behaviourDetails,
                      internalNotes: notes,
                    })
                  }
                />
              </StructuredList.Item>

              {behaviourDetails.detentionDetails &&
                activity.detentionStatusId !==
                  DETENTION_STATUS_KEYS.CANCELLED &&
                activity.detentionStatusId !==
                  DETENTION_STATUS_KEYS.RESCHEDULED && (
                  <ParentNotificationNote />
                )}
            </Card.Body>
          </Card>

          <ReadOnlyInvestigationNote
            investigationNote={behaviour.investigationNote}
          />

          <ValidationMessage errors={validationErrors} />

          <ActionBar low>
            <Right>
              <Button
                text="Save"
                color={Swatches.Success}
                size={Size.Small}
                disabled={
                  readyToSubmit(
                    behaviourDetails.initialAction.initialActionId,
                    behaviourDetails.detentionDetails
                  ) === false
                }
                onClick={validateAndOpenConfirm}
              />

              <Button
                text="Cancel"
                size={Size.Small}
                onClick={handleCloseModal}
              />
            </Right>
          </ActionBar>
        </div>

        <EditBehaviourStatusWrapper
          activity={activity}
          detentionDetails={behaviourDetails.detentionDetails}
          handleCloseModal={handleCloseModal}
        />
      </Wrapper>

      <ConfirmModal
        openModal={openConfirm}
        confirmMsg={
          behaviourDetails.detentionDetails
            ? "A message will be sent to parents with these changes. Are you sure you want to continue?"
            : "Are you sure you want to save the changes?"
        }
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          handleUpdateBehaviour(behaviourDetails);
          setOpenConfirm(false);
        }}
      />
    </>
  );
};

export default EditBehaviour;
