import React from "react";
import { FEED_TYPE_KEY } from "../../constants/activityFeed";
import { StaffView } from "sharedComponents/common/users/userTypeView";

// Some feed item types should only be visible to staff
// feedTypeId === FEED_TYPE_KEY.LEFT_LESSON || - Remove to allow student and parents to view 
const ShowInActivityFeed = ({ feedTypeId, children }) => {
  return feedTypeId === FEED_TYPE_KEY.NOTES ||
    feedTypeId === FEED_TYPE_KEY.ON_CALL ? (
    <StaffView>{children}</StaffView>
  ) : (
    children
  );
};

export default ShowInActivityFeed;
