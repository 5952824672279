import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  Modal,
  List,
  Tooltip,
  Card,
  Subtitle,
  Spacing,
  neutral,
  typescale,
  textFont,
  fontStyle,
  ActionBar,
  Right,
  Button,
  Swatches,
  Size,
  formatScrollbars,
  Chip,
  ToastService,
} from "ui-kit";
import { danger } from "ui-kit/common/colors";
import { StudentPicker, Avatar } from "sharedComponents/common";
import Tippy from "@tippyjs/react";
import provisionGroupsActions from "areas/send/actions/provisions/provisionGroupsActions";
import { ProvisionGroupDetailView, ProvisionRecordListView } from "areas/send/types/passportResponse.types";
import { RootState } from "reducers/store";
import { StudentListView } from "types/users/userListViews.types";
//these stupid long path names are the only way for intellisense to let you peak the definition

const HelpText = styled.div`
  ${fontStyle(textFont.roman, typescale.paragraph, neutral[700])};
  margin-bottom: ${Spacing.Default}px;
`;

const RemoveButton = styled.button`
  font-size: 1.5rem;
  background-color: unset;
  border: medium none;
  margin: 0px;
  padding: 0px;
  color: rgb(153, 153, 153);
  cursor: pointer;
  line-height: 1rem;
  margin-bottom: ${Spacing.Small}px;
  &:hover {
    color: ${danger};
  }
`;

const StudentListWrapper = styled.div`
  margin: 0 ${Spacing.Default}px;
  flex-grow: 1;
  min-width: 315px;
  min-height: 100%;
  max-height: 100%;
  overflow-y: auto;
  ${formatScrollbars()}
  transition: all ease 200ms;
  padding-right: ${Spacing.Default}px;

  .card,
  .list {
    transition: all ease 200ms;
  }

  .card {
    margin-bottom: ${Spacing.Default}px;
  }

  .existing-students-list {
    margin-bottom: ${Spacing.Default}px;
  }
`;


interface IProvisionRecordStudentBulkSelectorProps {
  provisionGroup: ProvisionGroupDetailView;
  records: ProvisionRecordListView[];
  onSave?: () => void;
  onCancel?: () => void;
  open: boolean;
}

interface provisionBulkPickerStudent extends StudentListView {
  alreadyInGroup: boolean;
}


const ProvisionRecordStudentBulkSelector: React.FC<IProvisionRecordStudentBulkSelectorProps> = ({
  provisionGroup,
  records,
  onSave,
  onCancel,
  open,
}) => {

  const ref = useRef();
  const { loadingRecords } = useSelector((state: RootState) => state.provisionGroup);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_open, _setOpen] = useState<boolean>(open);
  const [_provisionGroup, _setProvisionGroup] = useState<ProvisionGroupDetailView>(provisionGroup);
  const [_records, _setRecords] = useState<ProvisionRecordListView[]>(records);
  const [students, setStudents] = useState<provisionBulkPickerStudent[]>([]);

  useEffect(() => {
    // const students = records.map(record => {
    //   if (record.isCurrent === true) {
    //     return {...record.student, alreadyInGroup: true};
    //   }
    // });

    var students = records.reduce((filtered: provisionBulkPickerStudent[], record: ProvisionRecordListView) => {
      if (record.isCurrent === true) {
        filtered.push({ ...record.student, alreadyInGroup: true });
      }
      return filtered;
    }, []);

    setStudents(students);
  }, [_records]);

  useEffect(() => {
    setValidationErrors([]);
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    if (provisionGroup) {
      _setProvisionGroup(provisionGroup);
    }
  }, [provisionGroup]);

  useEffect(() => {
    records && _setRecords(records);
  }, [records]);

  const handleCancel = () => {
    onCancel?.();
  };

  const handleSave = () => {
    var newStudentIds = students
      .filter(student => student.alreadyInGroup === false)
      .map(student => student.id);
    var data = {
      providerId: _provisionGroup.provision.provider.id,
      provisionId: _provisionGroup.provision.id,
      groupId: _provisionGroup.id,
      newStudents: newStudentIds,
    };

    provisionGroupsActions.bulkCreateProvisionRecord(data, () => {
      ToastService.pop(
        "Provision Record Created",
        null,
        "hand-holding-seedling"
      );
      onSave?.();
      setStudents(null);
    });
  };

  const handleAddStudent = (newStudent: StudentListView) => {
    const existingActiveStudent = students.find(
      student => student.id === newStudent.id
    );
    const existingInactiveStudent = records.find(
      record => record.student.id === newStudent.id
    );

    if (existingActiveStudent == null && existingInactiveStudent == null) {
      setStudents([{ ...newStudent, alreadyInGroup: false }, ...students]);
    }
  };

  const handleRemoveStudent = (selectedStudentId: string) => {
    const foundStudent = students.find(
      student => student.id === selectedStudentId
    );
    if (foundStudent == null) {
      return;
    }

    if (foundStudent.alreadyInGroup === true) {
      const foundRecord = _records.find(
        record => record.student.id === selectedStudentId
      );
      provisionGroupsActions.updateProvisionRecordStatus({
        providerId: _provisionGroup.provision.provider.id,
        provisionId: _provisionGroup.provision.id,
        groupId: _provisionGroup.id,
        id: foundRecord.id,
        isCurrent: !foundRecord.isCurrent,
      });
    } else {
      var updatedStudents = students.filter(
        student => student.id !== selectedStudentId
      );
      setStudents(updatedStudents);
    }
  };

  return (
    <Modal
      title={"Select new Students"}
      open={_open}
      onClose={handleCancel}
      width="70%"
      height="80%"
    >
      <Modal.Body>
        <StudentListWrapper>
          <Subtitle text="Add students to the provision group by using the search box below." />

          <Card>
            <Card.Body>
              <StudentPicker
                fluid
                onChange={(value: StudentListView) => handleAddStudent(value)}
              />
            </Card.Body>
          </Card>

          {students?.length > 0 && (
            <List title={`Students`} className="existing-students-list">
              {students.map((student: provisionBulkPickerStudent, index: number) => (
                <List.Item
                  key={index}
                  left={<Avatar user={student} />}
                  right={
                    <>
                      {student?.alreadyInGroup !== true && (
                        <Chip
                          text="New"
                          colorSwatch={Swatches.Low}
                          style={{ marginRight: `${Spacing.Small}px` }}
                        />
                      )}
                      <RemoveButton
                        ref={ref}
                        onClick={event => {
                          event.stopPropagation();
                          handleRemoveStudent(student.id);
                        }}
                        disabled={loadingRecords}
                      >
                        &#10799;
                      </RemoveButton>
                      <Tippy
                        reference={ref}
                        content={<Tooltip title="Remove Student" />}
                      />
                    </>
                  }
                />
              ))}
            </List>
          )}
        </StudentListWrapper>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              text="Save"
              color={Swatches.Success}
              onClick={handleSave}
              size={Size.Small}
              working={loadingRecords}
              disabled={
                students?.filter(student => student.alreadyInGroup === false)
                  .length === 0
              }
            />
            <Button
              text="Cancel"
              color={Swatches.Low}
              onClick={handleCancel}
              size={Size.Small}
              working={loadingRecords}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default ProvisionRecordStudentBulkSelector;
