import storeActions from "areas/payments/actions/storeActions";
import { LunchCreditBalanceView } from "areas/payments/types/catering/cateringResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface LunchCreditsState {
  balance: LunchCreditBalanceView[];
  balanceError: string | null;
  loadingBalance: boolean;
}

const INITIAL_STATE: LunchCreditsState = {
  balance: [],
  balanceError: null,
  loadingBalance: false
};


const lunchCreditsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : LunchCreditsState => {

  const { 
    STORE_GETLUNCHCREDITBALANCE 
  } = storeActions.storeTypes;

  switch (action.type) {
    case STORE_GETLUNCHCREDITBALANCE.START:
      return { 
        ...state, 
        loadingBalance: true, 
        balanceError: null 
      };

    case STORE_GETLUNCHCREDITBALANCE.SUCCESS:
      return {
        ...state,
        balance: action.payload,
        loadingBalance: false,
      };

    case STORE_GETLUNCHCREDITBALANCE.FAILED:
      return {
        ...state,
        loadingBalance: false,
        balanceError: action.payload,
      };

    default:
      return state;
  }
};

export default lunchCreditsReducer;
