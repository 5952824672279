import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import flyoutActions from "actions/ui/flyouts";
import { Constants } from "configuration";
import GroupList from "./groupList";
import styled from "styled-components";
import { formatScrollbars, neutral, Spacing } from "ui-kit";
import { GROUP_LISTS } from "areas/planner/constants/groups/groups";
import studentActions from "../../actions/student/studentActions";
import { useNavigate } from "react-router";
import routes from "configuration/routes";
import groupsActions from "../../actions/groups/groupsActions";
import { groupRoutes } from "../../plannerRoutes";
import CustomGroupEditorModal from "./customGroups/customGroupEditorModal";
import { GroupListView } from "types/users/userGroups.types";
import { useAppSelector } from "reducers/hooks";
import { GroupType } from "types/planner/groups.types";


const Wrapper = styled.div`
  display: flex;
  padding-right: ${Spacing.Default}px;
  flex-direction: column;
  align-items: center;
  position: absolute;
  min-height: 100%;
  max-height: 100%;
  min-width: 200px;
  left: 0;
  overflow-y: scroll;
  ${formatScrollbars(neutral[200])};

  // .tutor-group, .custom-groups {
  //   .list li .body .detail-label .label {
  //     line-height: unset !important;
  //   }
  // }
`;


interface IGroupListWrapperProps {
  groups: GroupListView[];
  favouriteGroups: GroupListView[];
}


const GroupListWrapper: React.FC<IGroupListWrapperProps> = ({ groups, favouriteGroups }) => {

  const navigate = useNavigate();
  const { user } = useAppSelector(state => state.currentUser);
  const { selectedGroupId } = useAppSelector(state => state.group);
  const { staffExpandedGroupsIds } = useAppSelector(state => state.groupLists);

  const [classGroups, setClassGroups] = useState<GroupListView[]>([]);
  const [tutorGroups, setTutorGroups] = useState<GroupListView[]>([]);
  const [customGroups, setCustomGroups] = useState<GroupListView[]>([]);
  const [houses, setHouses] = useState<GroupListView[]>([]);
  // const [favourites, setFavourites] = useState([]);
  const [inactiveGroups, setInactiveGroups] = useState<GroupListView[]>([]);
  const [provisionGroups, setProvisionGroups] = useState<GroupListView[]>([]);
  const [openCustomGroupModal, setOpenCustomGroupModal] = useState<boolean>(false);

  useEffect(() => {
    if (groups !== null && groups.length > 0) {
      
      const tempClassGroups: GroupListView[] = [];
      const tempTutorGroups: GroupListView[] = [];
      const tempCustomGroups: GroupListView[] = [];
      const tempHouses: GroupListView[] = [];
      const tempInactiveGroups: GroupListView[] = [];
      const tempProvisionGroups: GroupListView[] = [];

      groups.forEach(group => {
        switch (group.type) {
          case GroupType.ClassGroup:
            group.isMyGroup && tempClassGroups.push(group);
            break;
          case GroupType.TutorGroup:
            group.isMyGroup && tempTutorGroups.push(group);
            break;
          case GroupType.Custom:
            group.isMyGroup && group.isActive
              ? tempCustomGroups.push(group)
              : group.isMyGroup &&
                !group.isActive &&
                tempInactiveGroups.push(group);

            break;
          case GroupType.Provision:
            group.isMyGroup && tempProvisionGroups.push(group);
            break;
          case GroupType.House:
            group.isMyGroup && tempHouses.push(group);
            break;
          default:
            break;
        }
      });

      setClassGroups(tempClassGroups);
      setTutorGroups(tempTutorGroups);
      setCustomGroups(tempCustomGroups);
      setHouses(tempHouses);
      setInactiveGroups(tempInactiveGroups);
      setProvisionGroups(tempProvisionGroups);
    }
  }, [groups]);

  // useEffect(() => {
  //   if (selectedGroupId) {
  //     handleGroupClick(selectedGroupId);
  //   }
  // }, [selectedGroupId]);

  const handleGroupClick = (groupId: number, groupType: GroupType) => {
    // dispatch(selectLayout(null));
    navigate(
      `${groupRoutes.getGroupPath(
        groupId,
        groupType !== null ? groupType : ""
      )}`
    );

    studentActions.selectStudent(null);
    flyoutActions.closeFlyout();
    window.scrollTo(0, 0);
  };

  const handleAddGroupClick = () => {
    // Go to editor page.
    // history.push("/main/groups/new");
    // dispatch(selectGroup(null));
    // window.scrollTo(0, 0);
    setOpenCustomGroupModal(true);
  };

  // const handleHouseClick = (house) => {
  //   navigate(`${routes.home}${routes.houses.getHousePath(house.id)}`);
  // };

  const onClickAccordionArrow = (groupTypeId: number) => {
    const tempExpandedGroupIds = staffExpandedGroupsIds.slice();
    const index = staffExpandedGroupsIds.findIndex(id => id === groupTypeId);
    if (index >= 0) {
      tempExpandedGroupIds.splice(index, 1);
    } else {
      tempExpandedGroupIds.push(groupTypeId);
    }
    groupsActions.saveStaffExpandedGroups(tempExpandedGroupIds);
  };

  return (
    <>
      <Wrapper>
        <GroupList
          groups={favouriteGroups}
          title="My Favourite Groups"
          selectedGroupId={selectedGroupId}
          handleGroupClick={handleGroupClick}
          isExpanded={staffExpandedGroupsIds.includes(
            GROUP_LISTS.FAVOURITE_GROUPS
          )}
          onClickArrow={() =>
            onClickAccordionArrow(GROUP_LISTS.FAVOURITE_GROUPS)
          }
          showNumberOfGroups
        />
        <GroupList
          className="tutor-groups"
          groups={tutorGroups}
          title="My Tutor Groups"
          selectedGroupId={selectedGroupId}
          handleGroupClick={handleGroupClick}
          isExpanded={staffExpandedGroupsIds.includes(GROUP_LISTS.TUTOR_GROUPS)}
          onClickArrow={() => onClickAccordionArrow(GROUP_LISTS.TUTOR_GROUPS)}
          showNumberOfGroups
        />
        <GroupList
          groups={classGroups}
          title="My Classes"
          selectedGroupId={selectedGroupId}
          handleGroupClick={handleGroupClick}
          isExpanded={staffExpandedGroupsIds.includes(GROUP_LISTS.CLASS_GROUPS)}
          onClickArrow={() => onClickAccordionArrow(GROUP_LISTS.CLASS_GROUPS)}
          showNumberOfGroups
        />
        {/* <GroupList
        groups={houses}
        title="My Houses"
        selectedGroupId={selectedGroupId}
        handleGroupClick={handleHouseClick}
      /> */}
        <GroupList
          className="custom-groups"
          groups={customGroups}
          title="My Custom Groups"
          selectedGroupId={selectedGroupId}
          handleGroupClick={handleGroupClick}
          showAddGroup
          addGroupText="New Custom Group"
          onAddGroupClick={handleAddGroupClick}
          isExpanded={staffExpandedGroupsIds.includes(
            GROUP_LISTS.CUSTOM_GROUPS
          )}
          onClickArrow={() => onClickAccordionArrow(GROUP_LISTS.CUSTOM_GROUPS)}
          showNumberOfGroups
        />
        {provisionGroups.length > 0 && (
          <GroupList
            groups={provisionGroups}
            title="My Provision Groups"
            selectedGroupId={selectedGroupId}
            handleGroupClick={handleGroupClick}
            isExpanded={staffExpandedGroupsIds.includes(
              GROUP_LISTS.PROVISION_GROUPS
            )}
            onClickArrow={() =>
              onClickAccordionArrow(GROUP_LISTS.PROVISION_GROUPS)
            }
            showNumberOfGroups
          />
        )}
        <GroupList
          className="custom-groups"
          groups={inactiveGroups}
          title="My Inactive Groups"
          selectedGroupId={selectedGroupId}
          handleGroupClick={handleGroupClick}
          isExpanded={staffExpandedGroupsIds.includes(
            GROUP_LISTS.INACTIVE_GROUPS
          )}
          onClickArrow={() =>
            onClickAccordionArrow(GROUP_LISTS.INACTIVE_GROUPS)
          }
          showNumberOfGroups
        />
      </Wrapper>

      {openCustomGroupModal && (
        <CustomGroupEditorModal
          open={openCustomGroupModal}
          onClose={() => setOpenCustomGroupModal(false)}
          schoolId={user?.baseSchool?.id}
          newGroup
        />
      )}
    </>
  );
};

export default GroupListWrapper;
