import React from "react";
import PropTypes from "prop-types";
import StudentAnswerType from "./studentAnswerType";
import styled from "styled-components";
import { ANSWER_TYPES } from "areas/planner/constants/groups/studentQuiz";
import { StudentAnswerListView } from "areas/planner/types/quiz/quizResponse.types";
import { AnswerType } from "areas/planner/types/quiz/quizShared.types";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;


interface IStudentAnswerTypesProps {
  selectedAnswerType: AnswerType;
  setSelectedAnswerType: (answerType: AnswerType) => void;
  studentAnswers: StudentAnswerListView[];
}


const StudentAnswerTypes: React.FC<IStudentAnswerTypesProps> = ({
  selectedAnswerType,
  setSelectedAnswerType,
  studentAnswers,
}) => {

  const getAnswerCount = (answerTypeKey: number) => {
    const answerTypeAndCount = studentAnswers.find(
      studentAnswer => answerTypeKey === studentAnswer.answerType
    );
    if (answerTypeAndCount) {
      return answerTypeAndCount.answerCount;
    }
    return 0;
  };

  return (
    <Wrapper>
      {ANSWER_TYPES.map(answerType => (
        <StudentAnswerType
          key={answerType.key}
          answerType={answerType}
          selectedAnswerType={selectedAnswerType}
          setSelectedAnswerType={setSelectedAnswerType}
          answerCount={getAnswerCount(answerType.key)}
        />
      ))}
    </Wrapper>
  );
};

export default StudentAnswerTypes;
