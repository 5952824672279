import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { urls } from "utils";
import client from "services/client";
import store from "reducers/store";
import { ApproveLeaveRequestCommand, ApproveOvertimeCommand, CancelLeaveRequestCommand, CancelOvertimeCommand, ConfirmLeaveRequestCommand, LeaveRequestQueryFilter, LeaveRequestReadCommand, RejectLeaveRequestCommand, RejectOvertimeCommand, ResubmitLeaveRequestCommand, SaveHolidayCardCommand, SaveOvertimeCommand, SubmitLeaveRequestCommand, UpdateLeaveRequestApproverCommand } from "../types/leaveRequest.types";
import { PagedQueryView } from "types/common/views.types";
import { HolidayCardView, LeaveRequestListView, OvertimeDetailView } from "../types/leaveResponse.types";
import { IBeehiveAction, callbackType } from "types/common/action.types";

const prefix = "staffMemberHolidayCard";
const builder = new actionTypeBuilder(prefix);

const types = {
  LEAVEREQUESTS_UPDATEFILTER: "LEAVEREQUESTS_UPDATEILTER",
  LEAVEREQUESTS_GETALL: builder.build("leaverequests_getall"),
  LEAVEREQUEST_GET: builder.build("leaverequest_get"),
  HOLIDAYCARD_CREATE: builder.build("holidaycard_create"),
  HOLIDAYCARD_SAVE: builder.build("holidaycard_save"),
  GETHOLIDAYCARDFORUSER: builder.build("getforuser"),
  GETHOLIDAYCARDFORSTAFFMEMBER: builder.build("getstaff"),
  SUBMIT_LEAVEREQUEST: builder.build("leave_submit"),
  APPROVE_LEAVEREQUEST: builder.build("leave_approve"),
  REJECT_LEAVEREQUEST: builder.build("leave_reject"),
  CONFIRM_LEAVEREQUEST: builder.build("leave_confirm"),
  RESUBMIT_LEAVEREQUEST: builder.build("leave_resubmit"),
  CANCEL_LEAVEREQUEST: builder.build("leave_cancel"),
  READ_LEAVEREQUEST: builder.build("leave_read"),
  UPDATEAPPROVER_LEAVEREQUEST: builder.build("updateapprover_leaverequest"),
  GET_OVERTIME: builder.build("overtime_get"),
  SUBMIT_OVERTIME: builder.build("overtime_submit"),
  APPROVE_OVERTIME: builder.build("overtime_approve"),
  REJECT_OVERTIME: builder.build("overtime_reject"),
  CANCEL_OVERTIME: builder.build("overtime_cancel"),
};


const getAllLeaveRequests = (filter: LeaveRequestQueryFilter, pageIndex: number, callback?: callbackType<PagedQueryView<LeaveRequestListView>>) => {
  let url = new urls.QueryString("pm/staff/leave");
  url.addParams({ ...filter, pageIndex });

  return action<PagedQueryView<LeaveRequestListView>>(
    () => client.get(url.toUrl()),
    types.LEAVEREQUESTS_GETALL,
    callback
  );
};

const getLeaveRequest = (userId: string, leaveRequestId: number, callback?: callbackType<LeaveRequestListView>) =>
  action<LeaveRequestListView>(
    () => client.get(`pm/staff/${userId}/leave/requests/${leaveRequestId}`),
    types.LEAVEREQUEST_GET,
    callback
  );

const updateLeaveRequestsFilter = (filter: LeaveRequestQueryFilter) => {
  store.dispatch<IBeehiveAction<LeaveRequestQueryFilter>>({
    type: types.LEAVEREQUESTS_UPDATEFILTER,
    payload: filter,
  });
};

const createHolidayCard = (holidayCard: SaveHolidayCardCommand, callback?: callbackType<HolidayCardView>) =>
  action<HolidayCardView>(
    () => client.post(`pm/staff/${holidayCard.staffMemberId}/holidaycards`, holidayCard),
    types.HOLIDAYCARD_CREATE,
    callback
  );

const saveHolidayCard = (holidayCard: SaveHolidayCardCommand, callback?: callbackType<HolidayCardView>) =>
  action<HolidayCardView>(
    () => client.post(`pm/staff/${holidayCard.staffMemberId}/holidaycards/${holidayCard.id}`, holidayCard),
    types.HOLIDAYCARD_SAVE,
    callback
  );

const getHolidayCardForUser = (staffMemberId: string, academicYearId: number, callback?: callbackType<HolidayCardView>) =>
  action<HolidayCardView>(
    () => client.get(`pm/staff/${staffMemberId}/leave/${academicYearId}`),
    types.GETHOLIDAYCARDFORUSER,
    callback
  );

const getHolidayCardForStaffMember = (staffMemberId: string, academicYearId: number, callback?: callbackType<HolidayCardView>) =>
  action<HolidayCardView>(
    () => client.get(`pm/staff/${staffMemberId}/leave/${academicYearId}`),
    types.GETHOLIDAYCARDFORSTAFFMEMBER,
    callback
  );

const submitLeaveRequest = (staffMemberId: string, leaveRequest: SubmitLeaveRequestCommand, callback?: callbackType<HolidayCardView>) =>
  action<HolidayCardView>(
    () => client.post(`pm/staff/${staffMemberId}/leave`, leaveRequest),
    types.SUBMIT_LEAVEREQUEST,
    callback
  );

const approveLeaveRequest = (staffMemberId: string, leaveRequest: ApproveLeaveRequestCommand, callback?: callbackType<HolidayCardView>) =>
  action<HolidayCardView>(
    () => client.post(`pm/staff/${staffMemberId}/leave/${leaveRequest.holidayRequestId}/approve`, leaveRequest),
    types.APPROVE_LEAVEREQUEST,
    callback
  );

const confirmLeaveRequest = (staffMemberId: string, leaveRequest: ConfirmLeaveRequestCommand, callback?: callbackType<HolidayCardView>) =>
  action<HolidayCardView>(
    () => client.post(`pm/staff/${staffMemberId}/leave/${leaveRequest.holidayRequestId}/confirm`, leaveRequest),
    types.CONFIRM_LEAVEREQUEST,
    callback
  );

const resubmitLeaveRequest = (staffMemberId: string, leaveRequest: ResubmitLeaveRequestCommand, callback?: callbackType<HolidayCardView>) =>
  action<HolidayCardView>(
    () => client.post(`pm/staff/${staffMemberId}/leave/${leaveRequest.holidayRequestId}/resubmit`, leaveRequest),
    types.RESUBMIT_LEAVEREQUEST,
    callback
  );

const rejectLeaveRequest = (staffMemberId: string, leaveRequest: RejectLeaveRequestCommand, callback?: callbackType<HolidayCardView>) =>
  action<HolidayCardView>(
    () => client.post(`pm/staff/${staffMemberId}/leave/${leaveRequest.holidayRequestId}/reject`, leaveRequest),
    types.REJECT_LEAVEREQUEST,
    callback
  );

const cancelLeaveRequest = (staffMemberId: string, leaveRequest: CancelLeaveRequestCommand, callback?: callbackType<HolidayCardView>) =>
  action<HolidayCardView>(
    () => client.post(`pm/staff/${staffMemberId}/leave/${leaveRequest.holidayRequestId}/cancel`, leaveRequest),
    types.REJECT_LEAVEREQUEST,
    callback
  );

const updateLeaveRequestApprover = (staffMemberId: string, leaveRequest: UpdateLeaveRequestApproverCommand, callback?: callbackType<HolidayCardView>) =>
  action<HolidayCardView>(
    () => client.post(`pm/staff/${staffMemberId}/leave/${leaveRequest.id}/approver`, leaveRequest),
    types.UPDATEAPPROVER_LEAVEREQUEST,
    callback
  );

const readLeaveRequest = (staffMemberId: string, leaveRequest: LeaveRequestReadCommand, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`pm/staff/${staffMemberId}/leave/${leaveRequest.id}/read`, leaveRequest),
    types.READ_LEAVEREQUEST,
    callback
  );

const getOvertime = (staffMemberId: string, overtimeId: number, callback?: callbackType<OvertimeDetailView>) =>
  action<OvertimeDetailView>(
    () => client.get(`pm/staff/${staffMemberId}/overtime/${overtimeId}`),
    types.GET_OVERTIME,
    callback
  );

const submitOvertime = (staffMemberId: string, overtime: SaveOvertimeCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`pm/staff/${staffMemberId}/overtime`, overtime),
    types.SUBMIT_OVERTIME,
    callback
  );

const approveOvertime = (staffMemberId: string, overtime: ApproveOvertimeCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`pm/staff/${staffMemberId}/overtime/${overtime.overtimeId}/approve`, overtime),
    types.APPROVE_OVERTIME,
    callback
  );

const rejectOvertime = (staffMemberId: string, overtime: RejectOvertimeCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`pm/staff/${staffMemberId}/overtime/${overtime.overtimeId}/reject`, overtime),
    types.REJECT_OVERTIME,
    callback
  );

const cancelOvertime = (staffMemberId: string, overtime: CancelOvertimeCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`pm/staff/${staffMemberId}/overtime/${overtime.overtimeId}/cancel`, overtime),
    types.REJECT_OVERTIME,
    callback
  );


const leaveActions = {
  types,
  getAllLeaveRequests,
  getLeaveRequest,
  updateLeaveRequestsFilter,
  createHolidayCard,
  saveHolidayCard,
  getHolidayCardForUser,
  getHolidayCardForStaffMember,
  submitLeaveRequest,
  approveLeaveRequest,
  confirmLeaveRequest,
  resubmitLeaveRequest,
  rejectLeaveRequest,
  cancelLeaveRequest,
  updateLeaveRequestApprover,
  readLeaveRequest,
  getOvertime,
  submitOvertime,
  approveOvertime,
  rejectOvertime,
  cancelOvertime,
};

export default leaveActions;
