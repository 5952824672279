import React from "react";
import { useSelector } from "react-redux";
import storeActions from "areas/payments/actions/storeActions";
import styled from "styled-components";
import {
  neutral,
  typescale,
  Swatches,
  Size,
  displayFont,
  Button,
  CircleIcon,
  Currency,
  BorderRadius,
  Spacing,
  fontStyle
} from "ui-kit";
import { RootState } from "reducers/store";
import { AddSmartcardTopupToBasketCommand } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // flex-grow: 1;
  // flex-basis: 0;
  // flex: 0 0 auto;
  background: ${neutral[100]};
  margin-right: ${Spacing.Large}px;
  margin-bottom: ${Spacing.Large}px;
  padding: ${Spacing.Large}px;
  border-radius: ${BorderRadius.Default}px;
  border: 1px solid ${neutral[300]};

  &:last-child {
    margin-right: 0;
  }

  .circle-icon {
    color: ${neutral[300]};
    margin-bottom: ${Spacing.Small}px;
  }
  .total {
    ${fontStyle(displayFont.extraLight, typescale.header2, neutral[700])}
  }
  .caption {
    ${fontStyle(displayFont.medium, typescale.paragraph, neutral[700])}
  }
`;


interface ITopupProps {
  onAddToBasket?: (value: number) => void;
  value: number;
  recipientId: string;
  schoolId: number;
}


const Topup: React.FC<ITopupProps> = ({
  onAddToBasket,
  value,
  recipientId,
  schoolId
}) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { working } = useSelector((state: RootState) => state.basket);

  const handleAddToBasket = () => {
    const data: AddSmartcardTopupToBasketCommand = { 
      userId: user.id, 
      recipientId: recipientId, 
      schoolId: schoolId, 
      amount: value 
    };
    storeActions.addTopupToBasket(user.id, data, () => {
      onAddToBasket?.(value);
    });
  };

  return (
    <Wrapper className="topup">
      <CircleIcon value="id-card-alt" outline color={Swatches.Low.swatch} />
      <Currency className="total" value={value} />
      <span className="caption">Topup</span>
      <Button
        text="Buy Topup"
        size={Size.Medium}
        onClick={handleAddToBasket}
        color={Swatches.Primary}
        working={working}
        tooltip="Add Topup to Basket"
        tooltipSub={`Add a £${value} smartcard topup to the basket`}
      />
    </Wrapper>
  );
};
export default Topup;
