import React from "react";
import { useSelector } from "react-redux";
import FeedItem from "./feedItem";
import { HtmlText, Button, Swatches, Size } from "ui-kit";
import Moment from "react-moment";
import { users } from "utils";
import feedActions from "../actions/feedActions";
import DateRange from "areas/calendar/components/dateRange";
import { RootState } from "reducers/store";
import { IFeedItem } from "../reducers/feedReducer";


interface IFeedCalendarEventProps {
  item: IFeedItem;
  onMultiEventSignup?: (item: IFeedItem) => void;
}


const FeedCalendarEvent: React.FC<IFeedCalendarEventProps> = ({ item, onMultiEventSignup }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);

  const handleDismiss = (article: IFeedItem) => {
    item.dismissed
      ? feedActions.undismissFeedArticle(user.id, item.id)
      : feedActions.dismissFeedArticle(user.id, item.id);
  };

  const handleLike = (article: IFeedItem) => {
    item.liked
      ? feedActions.unlikeFeedArticle(user.id, item.id)
      : feedActions.likeFeedArticle(user.id, item.id);
  };

  const handleFavourite = (article: IFeedItem) => {
    item.favourited
      ? feedActions.unfavouriteFeedArticle(user.id, item.id)
      : feedActions.favouriteFeedArticle(user.id, item.id);
  };

  const handleSignUp = () => {
    onMultiEventSignup?.(item);
  };

  const handleCancelSignUp = () => {
    onMultiEventSignup?.(item);
  };

  const showSignupButton = () => {
    if (!users.isStudent(user)) {
      return null;
    }

    if (!item.data.some((x: any) => x.SignedUp)) {
      return (
        <Button
          size={Size.Small}
          text="Sign Up"
          color={Swatches.Primary}
          onClick={handleSignUp}
        />
      );
    } else {
      return (
        <Button
          size={Size.Small}
          text="Signed Up"
          color={Swatches.Low}
          onClick={handleCancelSignUp}
        />
      );
    }
  };

  const formatDate = (dates: any) => {
    var date = dates?.[0];

    if (!date) {
      return null;
    }

    if (dates.length > 1) {
      return (
        <span>
          Starts{" "}
          <Moment
            date={dates[0].Starts}
            format="dddd, Do MMMM YYYY [at] HH:mma"
          />
        </span>
      );
    } else {
      return <DateRange starts={date.Starts} ends={date.Ends} />;
    }
  };

  return (
    <FeedItem
      item={item}
      headerIcon="calendar"
      headerPostMessage={
        <>
          added an event in{" "}
          <span className="feed-item-category">
            <b>{item.category.name}</b>
          </span>
        </>
      }
      onFavourite={handleFavourite}
      onLike={handleLike}
      onDismiss={handleDismiss}
      titleSub={formatDate(item.data)}
      // subheaderLeft={<PrettyDate date={item.data?.Starts} />}
      subheaderRight={showSignupButton()}
    >
      <HtmlText html={item.content} fluid />
    </FeedItem>
  );
};

export default FeedCalendarEvent;
