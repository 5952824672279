import React from "react";
import PropTypes from "prop-types";
import { List } from "ui-kit";
import SmartTaskStatusChip from "./smartTaskStatusChip";
import styled from "styled-components";
import { SchoolSmartTaskListView } from "areas/behaviour/types/smartTasks/smartTaskResponse.types";


const Wrapper = styled.div`
  max-width: 250px;
`;

interface ISmartTaskListProps {
  smartTasks: SchoolSmartTaskListView[];
  selectSmartTask: (smartTaskId: number) => void;
  selectedSmartTaskId: number;
  onAddSmartTaskClicked: () => void;
}


const SmartTaskList: React.FC<ISmartTaskListProps> = ({
  smartTasks,
  selectSmartTask,
  selectedSmartTaskId,
  onAddSmartTaskClicked,
}) => {

  const handleAddSmartTaskClicked = () => {
    onAddSmartTaskClicked?.();
  };

  return (
    <Wrapper>
      <List title="Smart Tasks">
        { smartTasks.map((task, index) => (
          <List.Item
            key={index}
            selected={selectedSmartTaskId === task.id}
            onClick={() => selectSmartTask(task.id)}
            text={task.name}
            right={<SmartTaskStatusChip active={task.isActive} />}
          />
        ))}
        <List.Item
          text="Add Smart Task"
          transparent
          icon="plus"
          onClick={handleAddSmartTaskClicked}
        />
      </List>
    </Wrapper>
  );
};

export default SmartTaskList;
