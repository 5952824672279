import React from "react";
import PropTypes from "prop-types";
import { Table, DetailLabel } from "ui-kit";
import { formatDate, formatTime } from "utils/dateTime";
import OnCallStatusChip from "./onCallStatusChip";
import { Size, SplitButton, Swatches } from "ui-kit";
import { arrays } from "utils";
import { OnCallRequestDetailView } from "areas/analytics/reducers/onCallAnalyticsResponse.types";
import { OnCallStatus } from "areas/analytics/reducers/onCallAnalyticsShared.types";


interface IOnCallRequestTableRowProps {
  onCallRequest: OnCallRequestDetailView;
  handleViewOnCallRequest: (onCallRequest: OnCallRequestDetailView, claimAndResolve?:boolean) => void;
  handleClaimOnCallRequest: (onCallRequest: OnCallRequestDetailView) => void;
  handleCancelRequestClick: (onCallRequest: OnCallRequestDetailView) => void;
  handleUpdateOnCallClick: (onCallRequest: OnCallRequestDetailView) => void;
  loadingAction: boolean;
}


const OnCallRequestTableRow: React.FC<IOnCallRequestTableRowProps> = ({
  onCallRequest,
  handleViewOnCallRequest,
  handleClaimOnCallRequest,
  handleCancelRequestClick,
  handleUpdateOnCallClick,
  loadingAction,
}) => {

  const getStudentNames = () => {
    let studentNames = "";

    for (let i = 0; i < onCallRequest.students.length; i++) {
      if (i > 2) {
        const moreStudents = onCallRequest.students.length - 3;
        studentNames += ` +${moreStudents}`;
        break;
      }

      const student = onCallRequest.students[i];
      if (studentNames.length > 0) {
        studentNames += ", ";
      }
      studentNames += `${student.firstName} ${student.lastName}`;
    }

    return studentNames;
  };

  return (
    <Table.Row>
      <Table.Cell width={0.5}>
        <OnCallStatusChip onCallStatus={onCallRequest.status} />
      </Table.Cell>

      <Table.Cell width={1}>
        <DetailLabel
          bold
          label={`${onCallRequest.requestedBy.firstName} ${onCallRequest.requestedBy.lastName}`}
          sub={
            <>
              <p>{onCallRequest.classGroup?.name}</p>
              <p>
                {`${formatDate(onCallRequest.createdDate)} ${formatTime(
                  onCallRequest.createdDate
                )}`}
              </p>
            </>
          }
        />
      </Table.Cell>

      <Table.Cell width={1}>
        {!arrays.isEmpty(onCallRequest.students) && getStudentNames()}
      </Table.Cell>

      <Table.Cell width={0.5}>{onCallRequest.roomName}</Table.Cell>

      <Table.Cell width={0.5}>
        {onCallRequest.category.categoryDetail.name}
      </Table.Cell>

      <Table.Cell width={1}>
        {onCallRequest.claimedBy && (
          <DetailLabel
            label={`${onCallRequest.claimedBy.firstName} ${onCallRequest.claimedBy.lastName}`}
            sub={`${formatDate(onCallRequest.claimedDate)} ${formatTime(
              onCallRequest.claimedDate
            )}`}
          />
        )}
      </Table.Cell>

      <Table.Cell width={1}>
        {onCallRequest.resolvedBy && (
          <DetailLabel
            label={`${onCallRequest.resolvedBy.firstName} ${onCallRequest.resolvedBy.lastName}`}
            sub={`${formatDate(onCallRequest.resolvedDate)} ${formatTime(
              onCallRequest.resolvedDate
            )}`}
          />
        )}
      </Table.Cell>

      <Table.Cell width={1}>
        {onCallRequest.cancelledBy && (
          <DetailLabel
            label={`${onCallRequest.cancelledBy.firstName} ${onCallRequest.cancelledBy.lastName}`}
            sub={`${formatDate(onCallRequest.cancelledDate)} ${formatTime(
              onCallRequest.cancelledDate
            )}`}
          />
        )}
      </Table.Cell>
      <Table.Cell width={1} right>
        <SplitButton
          size={Size.Small}
          text="View"
          color={Swatches.Default}
          working={loadingAction}
          onDefaultClick={() => handleViewOnCallRequest(onCallRequest)}
        >
          <SplitButton.Option
            onClick={() => handleViewOnCallRequest(onCallRequest)}
            text="View"
            show
          />
          <SplitButton.Option
            onClick={() => handleClaimOnCallRequest(onCallRequest)}
            text="Claim"
            show={onCallRequest.status === OnCallStatus.Open}
          />
          <SplitButton.Option
            onClick={() => handleUpdateOnCallClick(onCallRequest)}
            text="Update"
            show={
              onCallRequest.status === OnCallStatus.Open ||
              onCallRequest.status === OnCallStatus.Claimed
            }
          />
          <SplitButton.Option
            onClick={() => handleViewOnCallRequest(onCallRequest)}
            text="Resolve"
            show={onCallRequest.status === OnCallStatus.Claimed}
          />
          <SplitButton.Option
            onClick={() => handleCancelRequestClick(onCallRequest)}
            text="Cancel Request"
            show={
              onCallRequest.status === OnCallStatus.Open ||
              onCallRequest.status === OnCallStatus.Claimed
            }
          />
          <SplitButton.Option
            onClick={() => handleViewOnCallRequest(onCallRequest, true)}
            text="Claim & Resolve"
            show={onCallRequest.status === OnCallStatus.Open}
          />
        </SplitButton>
      </Table.Cell>
    </Table.Row>
  );
};


export default OnCallRequestTableRow;
