import smartcardActions from "areas/payments/actions/smartcardActions";
import { SmartcardTransactionListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import defaults from "configuration/defaults";
import { IBeehiveAction } from "types/common/action.types";
import { CashlessPageInfo } from "types/common/paging.types";


export interface ISmartcardTransactionsState {
  transactions: SmartcardTransactionListView[];
  paging: CashlessPageInfo;
  error: string | null;
  loading: boolean;
}

const INITIAL_STATE: ISmartcardTransactionsState = {
  transactions: [],
  paging: defaults.DEFAULT_ALT_PAGING,
  error: null,
  loading: false
};


const smartcardTransactionsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISmartcardTransactionsState => {

  const { 
    SMARTCARDS_GETTRANSACTIONS 
  } = smartcardActions.types;

  switch (action.type) {
    case SMARTCARDS_GETTRANSACTIONS.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case SMARTCARDS_GETTRANSACTIONS.SUCCESS:
      return { 
        ...state, 
        transactions: action.payload.paging.pageIndex === 0
          ? action.payload.items
          : [...state.transactions, ...action.payload.items],
        paging: action.payload.paging,
        loading: false 
      };

    case SMARTCARDS_GETTRANSACTIONS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default smartcardTransactionsReducer;
