import React from "react";


const Unavailable = () => {
  
  return (
    <>
      <p>
        Unfortunately, your child's school is not currently accepting Beehive
        registrations.
      </p>
      <p>
        You may have received an email from Beehive inviting you to register.
        This was sent in error and we apologise for any inconvenice.
      </p>
      <p>
        Beehive will be launched for your child's school later on in the term
        and a new registration email will be sent to you when it is available.
      </p>
    </>
  );
};

export default Unavailable;
