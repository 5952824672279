import React, { useState, useEffect } from "react";
import RoomLayoutGrid from "./roomLayoutGrid";
import RoomLayoutSizeFormField from "./roomLayoutSizeFormField";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { fontStyle, displayFont, typescale, Spacing } from "ui-kit";
import { neutral } from "ui-kit/common/colors";
import { getArrayOfCols, getArrayOfRows } from "areas/planner/utils/layouts";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .class-direction-label {
    ${fontStyle(displayFont.medium, typescale.sub, neutral[600])}
    text-align: center;
    &.front {
      margin-top: ${Spacing.Large}px;
    }
    &.back {
      margin-bottom: ${Spacing.Large}px;
    }
  }

  .room-layout-creator {
  }
`;

const RoomLayoutGridWrapper = ({
  rows,
  cols,
  changeRoomRowsCols,
  roomSlots,
  setRoomSlots,
}) => {
  const { actions } = useSelector(state => state.classViewerRoomLayout);

  const [rowsArray, setRowsArray] = useState([]);
  const [colsArray, setColsArray] = useState([]);

  useEffect(() => {
    const numberRowsArray = getArrayOfRows(rows);
    const numberColsArray = getArrayOfCols(cols);
    setRowsArray(numberRowsArray);
    setColsArray(numberColsArray);
  }, [rows, cols]);

  return (
    <Wrapper>
      <ApiExceptionMessage error={actions.createRoomLayout.error} />

      <div className="room-layout-creator">
        <RoomLayoutSizeFormField
          changeRoomRowsCols={changeRoomRowsCols}
          rows={rows}
          cols={cols}
        />

        <div className="class-direction-label back">BACK OF THE CLASS</div>
        <RoomLayoutGrid
          rowsArray={rowsArray}
          colsArray={colsArray}
          roomSlots={roomSlots}
          setRoomSlots={setRoomSlots}
        />
        <div className="class-direction-label front">FRONT OF THE CLASS</div>
      </div>
    </Wrapper>
  );
};

export default RoomLayoutGridWrapper;
