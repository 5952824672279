import classViewerActions from "../../actions/classViewer/classViewerActions";

const INITIAL_STATE = {
  filteredStudents: null,
  displayDetentionTodayStudents: false,
  loading: false,
  error: null,
};

const { FILTERSTUDENTS, RESETFILTERSTUDENTS, FILTERDETENTIONTODAY } =
  classViewerActions.types;

const classLayoutStudentFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FILTERSTUDENTS.START:
      return { ...state, loading: true, error: null };

    case FILTERSTUDENTS.SUCCESS:
      return { ...state, loading: false, filteredStudents: action.payload };

    case FILTERSTUDENTS.FAILED:
      return { ...state, loading: false, error: action.payload };

    case RESETFILTERSTUDENTS:
      return { ...state, filteredStudents: null };

    case FILTERDETENTIONTODAY:
      return { ...state, displayDetentionTodayStudents: action.payload };

    default:
      return state;
  }
};

export default classLayoutStudentFilterReducer;
