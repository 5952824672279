import React from "react";
import CurrencyFormat from "react-currency-format";
import styled from "styled-components";
import { 
  Swatches,
  IUiKitBaseProps
} from "../index";

const Wrapper = styled.span<{highlight?: boolean, value: number}>`
  ${({ highlight, value }) =>
    highlight &&
    `color: ${value > 0 ? Swatches.Success.swatch : Swatches.Danger.swatch};`}
`;

interface ICurrencyProps extends IUiKitBaseProps {
  value: number;
  highlight?: boolean;
}

const Currency: React.FC<ICurrencyProps> = ({ value, className, highlight }) => {
  return (
    <Wrapper className={className} highlight={highlight} value={value}>
      <CurrencyFormat
        prefix={"£"}
        decimalScale={2}
        thousandSeparator=","
        fixedDecimalScale={true}
        displayType={"text"}
        value={value}
      />
    </Wrapper>
  );
};

export default Currency;
