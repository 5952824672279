import behaviourReducers from "./behaviour";
import detentionsReducers from "./detentions";
import onCallReducers from "./onCall";
import rewardReducers from "./reward";
import smartTaskReducer from "./smartTasks/smartTaskReducer";
import smartTasksReducer from "./smartTasks/smartTasksReducer";

const behaviour = {
  ...behaviourReducers,
  ...detentionsReducers,
  ...onCallReducers,
  ...rewardReducers,
  smartTask: smartTaskReducer,
  smartTasks: smartTasksReducer,
};

export default behaviour;
