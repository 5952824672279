import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Subheader from "sharedComponents/layout/header/subheader";
import {
  StaffSearch,
  Avatar,
  GroupSelector,
  SchoolPicker,
  FilePicker,
  LinkPicker,
} from "sharedComponents/common";
import { arrays } from "utils";
import { useNavigate } from "react-router-dom";
import ArticleCategoryDropdown from "./articleCategoryDropdown";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  Loader,
  Size,
  EmptyMessage,
  Title,
  TitleSize,
  Card,
  StructuredList,
  TextInput,
  DatePicker,
  RichTextEditor,
  Checkbox,
  Swatches,
  ToastService,
  Button,
  Message,
  ActionBar,
  ValidationMessage,
} from "ui-kit";
import newsActions from "areas/news/actions/newsActions";
import { RootState } from "reducers/store";
import { SchoolStartupView } from "types/schools/schools.types";
import { ArticleDetailView } from "areas/news/types/newsResponse.types";
import { SchoolWithRead } from "sharedComponents/common/schools/schoolPicker";
import { GroupListView } from "types/users/userGroups.types";
import { StaffListView } from "types/users/userListViews.types";
import { SaveArticleCommand } from "areas/news/types/newsRequest.types";
import { AdmissionStatus } from "types/users/userAdmission.types";
import { FileListView } from "types/common/files.types";
import { useScroll } from "hooks/useScroll";

interface newArticle extends ArticleDetailView {
  categoryId: number;
  authorId: string;
}

const ArticleEditor = () => {

  const { scrollToTop } = useScroll();
  const { categoryId, articleId } = useParams();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { article, loading, error, saving, saveError } = useSelector(
    (state: RootState) => state.article
  );

  const [_article, _setArticle] = useState<newArticle | null>(null);
  const [_schools, _setSchools] = useState<SchoolStartupView[]>([
    ...user.schools,
    user.baseSchool,
  ]);
  const [changingAuthor, setChangingAuthor] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_body, _setBody] = useState<string>("");
  const [_summary, _setSummary] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    saveError && scrollToTop();
  }, [saveError]);

  useEffect(() => {
    if (articleId) {
      newsActions.getArticle(
        parseInt(categoryId),
        parseInt(articleId),
        (result: ArticleDetailView) => {
          _setArticle({
            ...result,
            categoryId: result.category.id,
            authorId: result.author.id,
          });
          _setBody(result.body);
          _setSummary(result.summary);
        }
      );
    } else {
      _setArticle({
        categoryId: null,
        title: "",
        author: {
          id: user.id,
          firstName: user.details.firstName,
          lastName: user.details.lastName,
          title: null,
          initials: null,
          admissionStatus: AdmissionStatus.Current,
        },
        authorId: user.id,
        groups: [],
        schools: [user.baseSchool],
        isSticky: false,
        files: [],
        links: [],
        assignedTo: [],
        userRestrictions: {
          allowParents: true,
          allowStaff: true,
          allowStudents: true,
        },
        body: "",
        relatedArticles: [],
        id: null,
        publishStatus: 0,
        summary: "",
        hasAttachments: false,
        visibleFrom: null,
        visibleUntil: null,
        publishedDate: null,
        publishedBy: null,
        createdDate: null,
        createdBy: null,
        category: null,
      });
    }
  }, [articleId]);

  const handleSetAuthor = (value: StaffListView) => {
    _setArticle({
      ..._article,
      author: value,
      authorId: value.id,
    });

    setChangingAuthor(false);
  };

  const handleSchoolsChange = (schools: SchoolWithRead[]) => {
    var newSchools = schools?.filter(s => s.selected);
    var newGroups = _article?.groups;

    // if there are any groups that don't have a selected school, remove them

    _article?.groups?.forEach(group => {
      if (newSchools?.every(school => school.id != group.school?.id)) {
        arrays.removeItem(newGroups, group);
      }
    });

    _setArticle({
      ..._article,
      schools: newSchools,
      groups: newGroups,
    });
  };

  const setGroups = (groups: GroupListView[]) => {
    // if there are any groups that don't have the school selected, then select it
    var newSchools = _article?.schools;

    groups?.forEach(group => {
      if (_article?.schools?.every(s => s.id != group.school?.id)) {
        newSchools = [...newSchools, group.school];
      }
    });

    _setArticle({ ..._article, groups, schools: newSchools });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSave = (saveAsDraft: boolean) => {
    var errors = [];

    if (!_article.title) {
      errors.push(`A title is required.`);
    }

    if (!_article.author) {
      errors.push(`An author is required.`);
    }

    if (!_article.body) {
      errors.push(`Please enter some content for your article.`);
    }

    if (_article.schools?.length === 0) {
      errors.push(`Please select a school.`);
    }

    var now = moment();

    if (_article.visibleUntil) {
      var expiry = moment(_article.visibleUntil, "DD/MM/YYYY");

      if (expiry.isBefore(now)) {
        errors.push(`The expiry date is in the past.`);
      }
    }

    if (_article.visibleFrom && _article.visibleUntil) {
      var expiry = moment(_article.visibleUntil, "DD/MM/YYYY");
      var from = moment(_article.visibleFrom, "DD/MM/YYYY");

      if (expiry.isBefore(from)) {
        errors.push(`The expiry date must be after the visible from date.`);
      }
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    var data: SaveArticleCommand = {
      id: _article.id,
      authorId: _article.authorId,
      categoryId: _article.categoryId,
      title: _article.title,
      body: _article.body,
      summary: _article.summary,
      visibleFrom: _article.visibleFrom,
      visibleUntil: _article.visibleUntil,
      isSticky: _article.isSticky,
      links: _article.links,
      files: _article.files,
      relatedArticles: _article.relatedArticles.map(x => x.id),
      assignedTo: _article.assignedTo.map(x => x.id),
      groups: _article.groups,
      userRestrictions: _article.userRestrictions,
      //newFiles: _article.newFiles?.map(x => x.id),
      //deletedFiles: _article.deletedFiles?.map(x => x.id),
      schools: _article.schools.map(school => school.id),
      //saveAsDraft,
    };

    if (_article.id) {
      newsActions.updateArticle(data, () => {
        ToastService.pop("Article Saved", null, "signal-stream");
        navigate(-1);
      });
    } else {
      newsActions.createArticle(data, () => {
        navigate(-1);
        ToastService.pop("Article Created", null, "signal-stream");
      });
    }
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem loading the article"
        cover
      />
    );
  }

  if (!_article) {
    return (
      <EmptyMessage
        icon="signal-stream"
        title="Not found"
        summary="The requested article was not found"
        cover
      />
    );
  }

  return (
    <>
      <Subheader>
        <Title
          size={TitleSize.H2}
          text="Newsdesk"
          sub={_article.id ? `Edit Article` : "New Article"}
        />
      </Subheader>
      <Message text={saveError} color={Swatches.Danger} />
      <ValidationMessage errors={validationErrors} />

      <Card title="General Details">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item
              required
              name="Title"
              description="Please enter the title of the article. This should be clear and concise. Please ensure your title is in title case."
              helpPopup
            >
              <TextInput
                value={_article.title}
                onChange={value => _setArticle({ ..._article, title: value })}
                characterCount={true}
                maxLength={250}
                fluid
                placeholder="e.g. School Play Tickets Now On Sale"
              />
            </StructuredList.Item>
            <StructuredList.Item
              required
              name="Author"
              rightContent={
                <>
                  {!changingAuthor && _article?.author ? (
                    <Button
                      text="Change Author"
                      size={Size.Small}
                      color={Swatches.Primary}
                      onClick={() => setChangingAuthor(true)}
                    />
                  ) : (
                    (_article.author || _article.authorId) && (
                      <Button
                        text="Cancel"
                        size={Size.Small}
                        color={Swatches.Low}
                        onClick={() => setChangingAuthor(false)}
                      />
                    )
                  )}
                </>
              }
            >
              {_article.author && !changingAuthor ? (
                <>
                  <Avatar user={_article.author} />
                </>
              ) : (
                <StaffSearch fluid onChange={handleSetAuthor} />
              )}
            </StructuredList.Item>
            <StructuredList.Item required name="Category">
              <ArticleCategoryDropdown
                onChange={value =>
                  _setArticle({ ..._article, categoryId: value })
                }
                value={_article.categoryId}
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      <Card title="Article Summary">
        <Card.Body>
          <TextInput
            value={_summary}
            onChange={value => _setArticle({ ..._article, summary: value })}
            characterCount={true}
            maxLength={250}
            fluid
          />
        </Card.Body>
      </Card>

      <Card title="Article Content">
        <Card.Body>
          <RichTextEditor
            initialValue={_body}
            onChange={value => _setArticle({ ..._article, body: value })}
          />
        </Card.Body>
      </Card>

      <Card title="Publishing &amp; Visibility">
        <Card.Body>
          {(_article?.schools?.length > 1 || _schools?.length > 1) && (
            <StructuredList.Item
              name="Schools"
              description="Please choose the schools this article is visible for. Your home school is selected automatically."
              required
              helpPopup
            >
              <SchoolPicker
                onChange={handleSchoolsChange}
                value={_article?.schools}
                selectedTooltip={school =>
                  `This article is set for ${school.name} `
                }
                deselectedTooltip={school =>
                  `This article is not set for ${school.name} `
                }
                readOnlyTooltip={school =>
                  `You do not have permission to set or unset articles for ${school.name} `
                }
              />
            </StructuredList.Item>
          )}
          <StructuredList.Item
            name="Available For"
            description="Please enter the groups this article is available for."
            helpPopup
          >
            <GroupSelector
              onChange={groups => setGroups(groups)}
              schools={_article.schools}
              groups={_article?.groups}
              showSchoolIcon
            />
          </StructuredList.Item>
          <StructuredList.Item
            name="Visible From"
            description="If selected, the article will only be visible after this date."
            helpPopup
          >
            <DatePicker
              dateFormat="DD/MM/YYYY"
              placeholder="Pick a visible from date..."
              closeOnSelect
              selectedDate={_article.visibleFrom}
              onChange={value => {
                _setArticle({ ..._article, visibleFrom: value });
              }}
            />
          </StructuredList.Item>
          <StructuredList.Item
            name="Visible Until"
            description="If selected, the article will not be visible after this date."
            helpPopup
          >
            <DatePicker
              dateFormat="DD/MM/YYYY"
              closeOnSelect
              placeholder="Pick an expiry date..."
              selectedDate={_article.visibleUntil}
              onChange={value =>
                _setArticle({ ..._article, visibleUntil: value })
              }
            />
          </StructuredList.Item>
          <StructuredList.Item
            name="Sticky"
            description="Articles that are set as sticky always appear at the top of users' news feeds. Sticky articles are intended for urgent or important notices."
            helpPopup
          >
            <Checkbox
              checked={_article.isSticky}
              text={`Article is sticky`}
              onChange={value => {
                _setArticle({ ..._article, isSticky: value.checked });
              }}
            />
          </StructuredList.Item>
        </Card.Body>
      </Card>

      <Card title="Attachments &amp; Links">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item
              name="Upload Files"
              description="You can upload .pdf, .docx, .xlsx, .csv, .jpg, .gif, .png or .pptx files. Please note that due to technical limitations with some mobile phones, attachments are only currently available on the web version of Beehive."
              helpPopup
            >
              <FilePicker
                files={_article.files}
                onFilesChanged={files =>
                  _setArticle({ ..._article, files: files as FileListView[] })
                }
              />
            </StructuredList.Item>
            <StructuredList.Item name="Links">
              <LinkPicker
                links={_article.links}
                onLinksChanged={links =>
                  _setArticle({ ..._article, links: links })
                }
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      <ActionBar>
        <Button
          text="Save Article"
          onClick={() => handleSave(false)}
          color={Swatches.Success}
          working={saving}
        />
        {/* <Button
          text="Save Draft"
          onClick={() => handleSave(true)}
          color={Swatches.Low}
          working={saving}
        /> */}
        <Button
          text="Cancel"
          onClick={handleCancel}
          color={Swatches.Low}
          working={saving}
        />
      </ActionBar>
    </>
  );
};

export default ArticleEditor;
