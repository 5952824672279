import React from "react";
import styled from "styled-components";
import {
  Icon,
  Body,
  Left,
  Right,
  neutral,
  Spacing,
  BorderRadius,
  DetailLabel,
  boxShadow,
  Subtitle,
  Swatches,
} from "../index";

export interface IListProps {
  children?:
    | React.ReactElement<IListItemProps>
    | React.ReactElement<IListItemProps>[]
    | React.ReactNode;
  className?: string;
  title?: string;
  isExpanded?: boolean;
  onClickAccordionArrow?: () => void;
  style?: object;
}

export interface IListItemProps {
  text?: string;
  sub?: string | JSX.Element;
  icon?: string;
  onClick?: Function;
  className?: string;
  selected?: boolean;
  transparent?: boolean;
  right?: React.ReactElement | null;
  left?: React.ReactElement | null;
  value?: any;
  label?: React.ReactElement | null;
  style?: React.CSSProperties;
}

const ListWrapper = styled.ul<{ isExpanded?: boolean }>`
  list-style: none;
  margin: 0;
  display: none;

  ${({ isExpanded }) =>
    isExpanded &&
    `
      display: block;
    `}

  li:last-child {
    margin-bottom: 0;
  }
`;

const ListItemWrapper = styled.li<{
  hasOnClick: boolean;
  selected?: boolean;
  transparent?: boolean;
}>`
  display: flex;

  margin-bottom: ${Spacing.Default}px;
  padding: ${Spacing.Default}px;
  border-radius: ${BorderRadius.Default}px;
  cursor: ${props => (props.hasOnClick ? "pointer" : "default")};
  // box-shadow: ${boxShadow};
  border: 1px solid ${neutral[300]};
  transition: all ease 150ms;

  .left {
    margin-right: ${Spacing.Default}px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    .icon {
      margin-top: 0.15rem;
    }
  }

  .right {
    margin-left: ${Spacing.Default}px;
    display: flex;
    align-items: center;
  }

  .body {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .text {
    display: block;
  }
  .sub {
    display: block;
  }

  .icon {
    color: ${neutral[600]};
  }

  ${({ transparent }) =>
    transparent
      ? `background: none; box-shadow: none;`
      : `background: ${neutral[100]};`}

  ${({ selected }) =>
    selected &&
    `
  background-color: ${Swatches.Blue.swatch};
  border-color: ${Swatches.Blue.swatch};
  .label, .sub, .icon {
    color: ${neutral[100]};
    }

    &:hover {
      border-color: ${neutral[600]};
    }
`}

  ${({ hasOnClick }) =>
    hasOnClick &&
    `
  &:hover {
    background: ${neutral[600]};

    .label, .icon, .sub {
    color: ${neutral[100]};
    }
    
    .chip {
      background: ${neutral[100]};
      color: ${neutral[600]};
      }
  }

  `}
`;

const List: React.FC<IListProps> & {
  Item: typeof ListItem;
} = ({ children, className, title, isExpanded, onClickAccordionArrow, style }) => {
  return (
    <>
      {title && (
        <Subtitle
          text={title}
          isExpanded={isExpanded}
          onClickAccordionArrow={onClickAccordionArrow}
        />
      )}
      <ListWrapper
        isExpanded={!onClickAccordionArrow || isExpanded}
        className={`list ${className ? className : ""}`}
        style={style}
      >
        {children}
      </ListWrapper>
    </>
  );
};

const ListItem: React.FC<IListItemProps> = ({
  text,
  sub,
  icon,
  onClick,
  className,
  selected,
  transparent,
  left,
  right,
  value,
  label,
  style
}) => {
  const handleClick = () => {
    onClick?.(value);
  };

  return (
    <ListItemWrapper
      className={`list-item ${className ? className : ""}`}
      onClick={handleClick}
      hasOnClick={onClick != null}
      selected={selected}
      transparent={transparent}
      style={style}
    >
      {icon && !left && (
        <Left>
          <Icon value={icon} />
        </Left>
      )}
      {left && <Left>{left}</Left>}
      {(text || sub) && (
        <Body>
          <DetailLabel label={text ?? ""} sub={sub ?? ""} bold />
        </Body>
      )}
      {label}
      {right && <Right>{right}</Right>}
    </ListItemWrapper>
  );
};

ListItem.defaultProps = {};
List.defaultProps = {};

List.Item = ListItem;

export default List;
