import { IBeehiveAction } from "types/common/action.types";
import observationActions from "../../actions/observationActions";
import { ObservationDetailView } from "areas/humanResources/types/managedStaffResponse.types";

interface IObservationState {
  observation: ObservationDetailView;
  loading: boolean;
  error: string | null;
}

const INITIAL_STATE: IObservationState = {
  observation: null,
  loading: false,
  error: null,
};

const observationReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IObservationState => {
  const { GETOBSERVATION } = observationActions.types;

  switch (action.type) {
    case GETOBSERVATION.START:
      return { ...INITIAL_STATE, loading: true };
    case GETOBSERVATION.SUCCESS:
      return { ...INITIAL_STATE, observation: action.payload };
    case GETOBSERVATION.FAILED:
      return { ...INITIAL_STATE, error: action.payload };
    default:
      return state;
  }
};

export default observationReducer;
