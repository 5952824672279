import { LeaveRequestType } from "areas/humanResources/types/leaveShared.types";
import {
  CovidTestresult,
  FeedItemTypes,
  Flyouts,
  GroupTypes,
  NavigationModes,
  Ordering,
  PrimaryAvatarTypes,
  ReviewPeriods,
  SpecialDays,
  UserType,
} from "./constants.enums";
import { IConstants } from "./constants.types";

var constants: IConstants = {
  USER_TYPES: UserType,
  STAFF_TYPES: {
    TEACHING: {
      id: 0,
      name: "Teaching",
    },
    ASSOCIATE: {
      id: 1,
      name: "Associate",
    },
    GOVERNOR: {
      id: 1,
      name: "Governor",
    },
  },
  ADMISSION_STATUS: {
    CURRENT: {
      value: 0,
      name: "Current",
    },
    FUTURE: {
      value: 1,
      name: "Future",
    },
  },
  ROLES: {
    DEVELOPMENT: {
      id: 1,
      roleTypeId: 0,
      name: "Development",
      roleType: "Development",
    },
    ADMINISTRATOR: {
      id: 2,
      roleTypeId: 1,
      name: "Administrator",
      roleType: "Administrator",
    },
    TRUST_LEADERSHIP: {
      id: 3,
      roleTypeId: 2,
      name: "Trust Leadership",
      roleType: "TrustLeadership",
    },
    LEADERSHIP: {
      id: 4,
      roleTypeId: 3,
      name: "Leadership",
      roleType: "Leadership",
    },
    NEWS_ADMINISTRATOR: {
      id: 5,
      roleTypeId: 4,
      name: "News Administrator",
      roleType: "NewsAdministrator",
    },
    PLANNER_ADMINISTRATOR: {
      id: 6,
      roleTypeId: 5,
      name: "Planner Administrator",
      roleType: "PlannerAdministrator",
    },
    PAYMENTS_ADMINISTRATOR: {
      id: 7,
      roleTypeId: 6,
      name: "Payments Administrator",
      roleType: "PaymentsAdministrator",
    },

    FINANCE_TEAMLEADER: {
      id: 11,
      roleTypeId: 7,
      name: "Finance Team Leader",
      roleType: "FinanceTeamLeader",
    },
    FINANCE_USER: {
      id: 12,
      roleTypeId: 8,
      name: "Finance User",
      roleType: "FinanceUser",
    },
    FINANCE_ADMIN: {
      id: 13,
      roleTypeId: 9,
      name: "Finance Administrator",
      roleType: "FinanceAdministrator",
    },
    HR_ADMINISTRATOR: {
      id: 14,
      roleTypeId: 10,
      name: "HR Administrator",
      roleType: "HrAdministrator",
    },
    COVID_USER: {
      id: 9,
      roleTypeId: 11,
      name: "Covid User",
      roleType: "Covid",
    },
    COVID_ADMINISTRATOR: {
      id: 10,
      roleTypeId: 12,
      name: "Covid Administrator",
      roleType: "CovidAdmin",
    },
    IT_USER: {
      id: 15,
      roleTypeId: 13,
      name: "IT User",
      roleType: "ITUser",
    },
    IT_ADMINISTRATOR: {
      id: 16,
      roleTypeId: 14,
      name: "IT Administrator",
      roleType: "ITAdministrator",
    },
    MAILOUT_ADMINISTRATOR: {
      id: 18,
      roleTypeId: 15,
      name: "Mailout Administrator",
      roleType: "MailoutAdministrator",
    },
    LESSON_OBSERVER: {
      id: 17,
      roleTypeId: 16,
      name: "Lesson Observer",
      roleType: "LessonObserver",
    },
    GOVERNOR: {
      id: 19,
      roleTypeId: 17,
      name: "Governor",
      roleType: "Governor",
    },
    TRUSTEE: {
      id: 20,
      roleTypeId: 18,
      name: "Trustee",
      roleType: "Trustee",
    },
    BEHAVIOUR_ADMINISTRATOR: {
      id: 21,
      roleTypeId: 19,
      name: "Behaviour Administrator",
      roleType: "BehaviourAdministrator",
    },
    BEHAVIOUR_MANAGER: {
      id: 22,
      roleTypeId: 20,
      name: "Behaviour Manager",
      roleType: "BehaviourManager",
    },
    ATTENDANCE_ADMINISTRATOR: {
      id: 23,
      roleTypeId: 21,
      name: "Attendance Administrator",
      roleType: "AttendanceAdministrator",
    },
    SEN_ADMINISTRATOR: {
      id: 24,
      roleTypeId: 22,
      name: "SEN Manager",
      roleType: "SenManager",
    },
    SEN_COORDINATOR: {
      id: 25,
      roleTypeId: 23,
      name: "SEN Administrator",
      roleType: "SenAdministrator",
    },
    CATERING_MANAGER: {
      id: 28,
      roleTypeId: 24,
      name: "Catering Manager",
      roleType: "CateringManager",
    },
    EXAMINATIONS_ADMINISTRATOR: {
      id: 26,
      roleTypeId: 25,
      name: "Examinations Administrator",
      roleType: "ExaminationsAdministrator",
    },
    MOVES_ADMINISTRATOR: {
      id: 27,
      roleTypeId: 26,
      name: "Moves Administrator",
      roleType: "MovesAdministrator",
    },
    SHOPKEEPER_USER: {
      id: 29,
      roleTypeId: 27,
      name: "Shopkeeper User",
      roleType: "ShopkeeperUser",
    },
    SHOPKEEPER_ADMINISTRATOR: {
      id: 30,
      roleTypeId: 28,
      name: "Shopkeeper Administrator",
      roleType: "ShopkeeperAdministrator",
    },
    MARKETING_ADMINISTRATOR: {
      id: 33,
      roleTypeId: 33,
      name: "Marketing Administrator",
      roleType: "MarketingAdministrator",
    },
    CONSENT_ADMINISTRATOR: {
      id: 31,
      roleTypeId: 35,
      name: "Consent Administrator",
      roleType: "ConsentAdministrator",
    },
    // ON_CALL_USER: {
    //   id: 36,
    //   roleTypeId: 29,
    //   name: "On Call User",
    //   roleType: "OnCallUser",
    // },
    ON_CALL_ADMINISTRATOR: {
      id: 37,
      roleTypeId: 29,
      name: "On Call Administrator",
      roleType: "OnCallAdministrator",
    },
    MATRICULATION_ADMINISTRATOR: {
      id: 39,
      roleTypeId: 40,
      name: "Matriculation Administrator",
      roleType: "MatriculationAdministrator",
    },
    MATRICULATION_USER: {
      id: 38,
      roleTypeId: 41,
      name: "Matriculation User",
      roleType: "MatriculationUser",
    },
    FINANCE_BUYER: {
      id: 40,
      roleTypeId: 45,
      name: "Finance Buyer",
      roleType: "FinanceBuyer",
    },
    PLANNER_ANALYTICS: {
      id: 41,
      roleTypeId: 46,
      name: "Planner Analytics",
      roleType: "PlannerAnalytics",
    },
    WALLPLANNER_USER: {
      id: 42,
      roleTypeId: 50,
      name: "Wallplanner User",
      roleType: "WallplannerUser",
    },
    WALLPLANNER_ADMINISTRATOR: {
      id: 43,
      roleTypeId: 51,
      name: "Wallplanner Administrator",
      roleType: "WallplannerAdministrator",
    },
    STUDENTPROFILE_ADMINISTRATOR: {
      id: 44,
      roleTypeId: 52,
      name: "Student Profile Administrator",
      roleType: "StudentProfileAdministrator"
    },
    DATAPROTECTION_ADMINISTRATOR: {
      id: 45,
      roleTypeId: 53,
      name: "Data Protection Administrator",
      roleType: "DataProtectionAdministrator"
    },

    SYNCDATA_ADMINISTRATOR: {
      id: 46,
      roleTypeId: 54,
      name: "Sync Data Administrator",
      roleType: "SyncDataAdministrator"
    },

    
  },
  FLYOUTS: Flyouts,
  NAVIGATION_MODES: NavigationModes,
  FEED_ITEM_TYPES: FeedItemTypes,
  TOPUP_AMOUNTS: [5, 10, 15, 20, 25, 50],
  LUNCH_AMOUNTS: [5, 10, 15, 20, 25, 50],
  DEFAULT_DATEFORMAT: "DD/MM/YYYY",

  SUBMISSION_STATUS: {
    DRAFT: {
      value: 0,
      name: "Draft",
    },
    SUBMITTED: {
      value: 1,
      name: "Submitted",
    },
    APPROVED: {
      value: 2,
      name: "Approved",
    },
    CONFIRMED: {
      value: 3,
      name: "Confirmed",
    },
    COMPLETE: {
      value: 4,
      name: "Complete",
    },
    CANCELLED: {
      value: 5,
      name: "Cancelled",
    },
  },

  CONSENTFORM_STATUS: {
    DRAFT: {
      value: 0,
      name: "Draft",
    },
    SUBMITTED: {
      value: 1,
      name: "Submitted",
    },
    APPROVED: {
      value: 2,
      name: "Live",
    },
    CONFIRMED: {
      value: 3,
      name: "Confirmed",
    },
    COMPLETE: {
      value: 4,
      name: "Finished",
    },
    CANCELLED: {
      value: 5,
      name: "Cancelled",
    },
  },

  DELIVERY_STATUS: {
    QUEUED: {
      value: 0,
      name: "Queued",
    },
    DELIVERED: {
      value: 1,
      name: "Delivered",
    },
    FAILED: {
      value: 2,
      name: "Failed",
    },
  },

  CONSENT_STATUS: {
    NOCONSENT: {
      value: 0,
      name: "No Consent",
    },
    CONSENT: {
      value: 1,
      name: "Consent",
    },
    PARTIAL: {
      value: 2,
      name: "Partial",
    },
  },

  CONSENTFORM_SECTIONTYPE: {
    STEP: {
      value: 0,
      name: "Step",
    },
    DECLARATION: {
      value: 1,
      name: "Declaration",
    },
  },

  REVIEW_STATUS: {
    DRAFT: {
      value: 0,
      name: "Draft",
    },
    INPROGRESS: {
      value: 1,
      name: "In Progress",
    },
    COMPLETE: {
      value: 2,
      name: "Complete",
    },
  },

  TARGET_STATUS: {
    NOTMET: {
      value: 0,
      name: "Not Met",
    },
    PARTIALLYMET: {
      value: 1,
      name: "Partially Met",
    },
    MET: {
      value: 2,
      name: "Met",
    },
  },

  PROGRESS_STATUS: {
    AHEAD: {
      value: 0,
      name: "Ahead of Schedule",
    },
    ONTRACK: {
      value: 1,
      name: "On Track",
    },
    ATRISK: {
      value: 2,
      name: "At Risk",
    },
  },

  PERFORMANCE_RATING: {
    EXCEEDS: {
      name: "Exceeds Expectations",
      value: 0,
    },
    MEETS: {
      name: "Successfully Meets Expectations",
      value: 1,
    },
    MEETSSOME: {
      name: "Meets Some Expectations",
      value: 2,
    },
    DOESNOTMEET: {
      name: "Does Not Meet Expectations",
      value: 3,
    },
  },

  ASPIRATION_OPTIONS: {
    PROMOTION: {
      name: "Explore Promotional Opportunities",
      value: 0,
    },
    TRUST: {
      name: "Explore Working Across The Trust",
      value: 1,
    },
    REMAIN: {
      name: "Remain in Current Role",
      value: 2,
    },
  },

  REVIEW_PERIODS: ReviewPeriods,

  OBSERVATION_GRADE: {
    NOTAPPLICABLE: {
      name: "Not Applicable",
      value: 0,
    },
    OUTSTANDING: {
      name: "Outstanding",
      value: 1,
    },
    GOOD: {
      name: "Good",
      value: 2,
    },
    SATISFACTORY: {
      name: "Satisfactory",
      value: 3,
    },
    POOR: {
      name: "Poor",
      value: 4,
    },
  },

  OBSERVATION_TYPE: {
    OBSERVATION: {
      name: "Observation",
      value: 0,
    },
    FEEDBACK: {
      name: "Feedback",
      value: 1,
    },
  },

  COVID_TESTRESULT: CovidTestresult,

  PUBLISH_STATUS: {
    DRAFT: {
      name: "Draft",
      value: 0,
    },
    PENDING: {
      name: "Pending",
      value: 1,
    },
    LIVE: {
      name: "Live",
      value: 2,
    },
    PAUSED: {
      name: "Paused",
      value: 3,
    },
    CANCELLED: {
      name: "Cancelled",
      value: 4,
    },
  },
  SUGGESTED_TIMESCALES: [
    { key: "0", text: "No Suggested Timescale", value: "0" },
    { key: "5", text: "5 Minutes", value: "5" },
    { key: "10", text: "10 Minutes", value: "10" },
    { key: "15", text: "15 Minutes", value: "15" },
    { key: "20", text: "20 Minutes", value: "20" },
    { key: "25", text: "25 Minutes", value: "25" },
    { key: "30", text: "30 Minutes", value: "30" },
    { key: "45", text: "45 Minutes", value: "45" },
    { key: "60", text: "1 Hour", value: "60" },
    { key: "120", text: "2 Hours", value: "120" },
    { key: "180", text: "3 Hours", value: "180" },
    { key: "240", text: "4 Hours", value: "240" },
    { key: "300", text: "5 Hours+", value: "300" },
  ],

  CREDIT_STATUS: {
    PENDING: {
      name: "Pending",
      value: 0,
    },
    CREDITED: {
      name: "Synced",
      value: 1,
    },
    FAILED: {
      name: "Failed",
      value: 2,
    },
    CANCELLED: {
      name: "Cancelled",
      value: 3,
    },
  },

  CREDIT_TYPE: {
    CREDIT: {
      name: "Credit",
      value: 0,
    },
    REVERSE: {
      name: "Reverse",
      value: 1,
    },
  },

  ORDER_STATUS: {
    OPEN: {
      name: "Open",
      value: 0,
    },
    COMPLETE: {
      name: "Complete",
      value: 1,
    },
    CANCELLED: {
      name: "Cancelled",
      value: 2,
    },
    VOID: {
      name: "Void",
      value: 3,
    },
  },
  ORDERLINE_STATUS: {
    ORDERED: {
      name: "Ordered",
      value: 0,
    },
    CANCELLED: {
      name: "Cancelled",
      value: 1,
    },
  },

  GROUP_TYPES: GroupTypes,
  PAYMENT_METHODS: {
    ONLINE: {
      name: "Opayo",
      value: 0,
      active: false,
    },
    EPOS: {
      name: "EPOS",
      value: 1,
      active: true,
    },
    CHEQUE: {
      name: "Cheque",
      value: 2,
      active: false,
    },
    CASH: {
      name: "Cash",
      value: 3,
      active: false,
    },
    SUBSIDY: {
      name: "Subsidy",
      value: 4,
      active: false,
    },
    BACS: {
      name: "BACS",
      value: 5,
      active: true,
    },
    TERMINAL: {
      name: "Terminal",
      value: 6,
      active: true,
    },
    CARDNET: {
      name: "Cardnet",
      value: 7,
      active: false,
    },
    STRIPE: {
      name: "Stripe",
      value: 8,
      active: true,
    },
    PUPILPREMIUM: {
      name: "Pupil Premium",
      value: 9,
      active: true,
    },
  },

  GRADE_ACTION: {
    NO_ACTION: {
      name: "No Action",
      value: 0,
    },
    REDO: {
      name: "Redo",
      value: 1,
    },
    EXTENSION: {
      name: "Extension",
      value: 2,
    },
  },
  GRADE_TYPE: {
    PASS_FAIL: {
      name: "Pass or Fail",
      value: 0,
    },
    GRADE_LETTER: {
      name: "Grade (Letter)",
      value: 1,
    },
    GRADE_NUMBER: {
      name: "Grade (Number)",
      value: 2,
    },
    SCORE: {
      name: "Score",
      value: 3,
    },
  },
  GRADE_STATUS: {
    COMPLETE: {
      name: "Complete",
      value: 0,
    },
    UNSATISTFACTORY: {
      name: "Unsatisfactory",
      value: 1,
    },
    NOT_SUBMITTED: {
      name: "Not Submitted",
      value: 2,
    },
    REASONABLE_EXCUSE: {
      name: "Reasonable Excuse",
      value: 3,
    },
  },
  SURVEY_DIFFICULTY: {
    TOO_EASY: {
      name: "Too Easy",
      value: 0,
    },
    EASY: {
      name: "Easy",
      value: 1,
    },
    OK: {
      name: "OK",
      value: 2,
    },
    HARD: {
      name: "Hard",
      value: 3,
    },
    TOO_HARD: {
      name: "Too Hard",
      value: 3,
    },
  },
  SURVEY_TIMESCALE: {
    TOO_SHORT: {
      name: "Too Short",
      value: 0,
    },
    OK: {
      name: "OK",
      value: 1,
    },
    TOO_LONG: {
      name: "Too Long",
      value: 2,
    },
  },
  PRIMARY_AVATAR_TYPES: PrimaryAvatarTypes,
  PRIMARY_AVATAR_TYPES_ARRAY: [
    { key: 1, label: "Fox", value: 1 },
    { key: 2, label: "Lion", value: 2 },
    { key: 3, label: "Frog", value: 3 },
    { key: 4, label: "Panda", value: 4 },
    { key: 5, label: "Dog", value: 5 },
    { key: 6, label: "Cat", value: 6 },
    { key: 7, label: "Bear", value: 7 },
    { key: 8, label: "Sheep", value: 8 },
  ],

  LUNCH_TYPES: {
    SCHOOL_MEAL: {
      name: "School Meal",
      value: 0,
    },
    PACKED_LUNCH: {
      name: "Packed Lunch",
      value: 1,
    },
    HOME: {
      name: "Home",
      value: 2,
    },
    FASTING: {
      name: "Fasting",
      value: 3,
    },
    ABSENT: {
      name: "Absent",
      value: 4,
    },
  },
  LUNCH_OPTIONS: {
    OPTION1: {
      name: "Option 1",
      altName: "Red",
      value: 1,
    },
    OPTION2: {
      name: "Option 2",
      altName: "Green",
      value: 2,
    },
    OPTION3: {
      name: "Option 3",
      altName: "Yellow",
      value: 3,
    },
    NOT_APPLICABLE: {
      name: "Not Applicable",
      altName: "Not Applicable",
      value: 0,
    },
  },

  SCHOOLS: [
    {
      id: 1,
      name: "Beauchamp College",
      code: "BEA",
      isPrimary: false,
      disallowedDates: [],
    },
    {
      id: 2,
      name: "Cedars Academy",
      code: "CED",
      isPrimary: false,
      disallowedDates: [],
    },
    {
      id: 3,
      name: "Judgemeadow",
      code: "JUD",
      isPrimary: false,
      disallowedDates: [],
    },
    {
      id: 4,
      name: "Martin High School",
      code: "MAR",
      isPrimary: false,
      disallowedDates: [],
    },
    {
      id: 5,
      name: "Humphrey Perkins",
      code: "HUM",
      isPrimary: false,
      disallowedDates: [],
    },
    {
      id: 6,
      name: "Sir Jonathan North",
      code: "SJN",
      isPrimary: false,
      disallowedDates: [],
    },
    {
      id: 7,
      name: "Riverside Academy",
      code: "RIV",
      isPrimary: true,
      disallowedDates: [],
    },
    {
      id: 8,
      name: "Highcliffe Academy",
      code: "HIG",
      isPrimary: true,
      disallowedDates: [],
    },
    {
      id: 9,
      name: "Hallam Fields Primary School",
      code: "HAL",
      isPrimary: true,
      disallowedDates: [],
    },
    {
      id: 10,
      name: "Brocks Hill Primary School",
      code: "BRO",
      isPrimary: true,
      disallowedDates: [],
    },
    {
      id: 11,
      name: "Beauchamp City Sixth Form",
      code: "BCS",
      isPrimary: false,
      disallowedDates: [],
    },
    {
      id: 12,
      name: "Castle Rock School",
      code: "CAS",
      isPrimary: false,
      disallowedDates: [],
    },
    {
      id: 13,
      name: "Broom Leys Primary School",
      code: "BLS",
      isPrimary: true,
      disallowedDates: [],
    },
    {
      id: 14,
      name: "Newbridge School",
      code: "TNS",
      isPrimary: false,
      disallowedDates: [],
    },
    {
      id: 15,
      name: "Pioneers Centre",
      code: "PIO",
      isPrimary: false,
      disallowedDates: [],
    },
    {
      id: 16,
      name: "Mercia Academy",
      code: "MER",
      isPrimary: false,
      disallowedDates: [],
    },
    {
      id: 17,
      name: "Newhall Junior School",
      code: "NHL",
      isPrimary: true,
      disallowedDates: [],
    },
  ],
  ALL_SCHOOL_DISALLOWED_DATES: [
    { date: "2022-09-19T00:00:00", isTermTime: false, isWeekend: false },
    { date: "2022-10-17T00:00:00", isTermTime: false, isWeekend: false },
    { date: "2022-10-18T00:00:00", isTermTime: false, isWeekend: false },
    { date: "2022-10-19T00:00:00", isTermTime: false, isWeekend: false },
    { date: "2022-10-20T00:00:00", isTermTime: false, isWeekend: false },
    { date: "2022-10-21T00:00:00", isTermTime: false, isWeekend: false },
  ],
  SPECIAL_DAYS: SpecialDays,
  ORDERING: Ordering,

  WALLPLANNER_CATEGORIES: [
    {
      id: 1,
      name: "Data Protection Team",
      swatch: "#ff6600",
    },
    {
      id: 2,
      name: "Governance",
      swatch: "#cc0066",
    },
    {
      id: 3,
      name: "Data Snapshot",
      swatch: "#fff176",
    },
    {
      id: 4,
      name: "Exams/Tests",
      swatch: "#808080",
    },
    {
      id: 5,
      name: "Safeguarding Review",
      swatch: "#0070c0",
    },
    {
      id: 6,
      name: "Trust Pastoral",
      swatch: "#ccccff",
    },
    {
      id: 7,
      name: "IT Maintenance Window",
      swatch: "#00b0f0",
    },
    {
      id: 8,
      name: "Pupil Premium Review",
      swatch: "#5885A2",
    },
    {
      id: 9,
      name: "Curriculum Writing Focus",
      swatch: "#7a90b0",
    },
    {
      id: 10,
      name: "Trust Self Evaluation",
      swatch: "#993366",
    },
    {
      id: 11,
      name: "CPD",
      swatch: "#ff0000",
    },
    {
      id: 12,
      name: "Lionheart Teach ITT",
      swatch: "#daa06d",
    },
    {
      id: 14,
      name: "Duke of Edinburgh",
      swatch: "#CC0066",
    },
    {
      id: 15,
      name: "Meetings",
      swatch: "#00B050",
    },
    {
      id: 18,
      name: "Parents Events",
      swatch: "#FFFF00",
    },
    {
      id: 19,
      name: "UCAS",
      swatch: "#8D63D4",
    },
    {
      id: 20,
      name: "School Pastoral QA",
      swatch: "#FF8F43",
    },
    {
      id: 23,
      name: "Trust Work Review",
      swatch: "#4BFCFF",
    },
    {
      id: 26,
      name: "School Events",
      swatch: "#FF75EE",
    },
    {
      id: 28,
      name: "Professional Learning",
      swatch: "#81C784",
    },
    {
      id: 29,
      name: "UNKNOWN",
      swatch: "#000000",
    },
    {
      id: 30,
      name: "Curriculum Intervention",
      swatch: "#4DB6AC",
    },
    {
      id: 31,
      name: "Annual Events",
      swatch: "#f5b7b1",
    },
    {
      id: 36,
      name: "Curriculum",
      swatch: "#4646eb",
    },
    // {
    //   id: 32,
    //   name: "Extra Curricular (PE)",
    //   swatch: null,
    // },
    // {
    //   id: 33,
    //   name: "Extra Curricular",
    //   swatch: null,
    // },
    // {
    //   id: 34,
    //   name: "Staff Events",
    //   swatch: null,
    // },
    // {
    //   id: 35,
    //   name: "Interventions",
    //   swatch: null,
    // }
  ],

  ACADEMIC_YEARS: [
    {
      id: 8,
      name: "2021/22",
    },
    {
      id: 9,
      name: "2022/23",
    },
    {
      id: 10,
      name: "2023/24",
    },
    {
      id: 11,
      name: "2024/25",
    },
  ],

  SEND_PROVISIONFREQUENCY: {
    SINGLE: {
      value: 0,
      name: "Single",
    },
    DAY: {
      value: 1,
      name: "Day",
    },
    WEEK: {
      value: 2,
      name: "Week",
    },
    MONTH: {
      value: 3,
      name: "Month",
    },
    HALFTERM: {
      value: 4,
      name: "Half-Term",
    },
  },
  SEND_PROVISIONGROUPSTATUS: {
    DRAFT: {
      value: 0,
      name: "Draft",
    },
    LIVE: {
      value: 1,
      name: "Live",
    },
    COMPLETE: {
      value: 2,
      name: "Complete",
    },
  },
  SEND_PROVISIONRECORDPROGRESS: {
    EXPECTED: {
      value: 0,
      name: "0 : Expected",
    },
    ABOVE: {
      value: 1,
      name: "+1 : Above",
    },
    BELOW: {
      value: 2,
      name: "-1 : Below",
    },
  },
  SEND_PROVISIONMAPSTATUS: {
    OPEN: {
      value: 0,
      name: "Open",
    },
    CLOSED: {
      value: 1,
      name: "Closed",
    },
  },
  SEND_PASSPORTSTATUS: {
    DRAFT: {
      value: 0,
      name: "Draft",
    },
    PUBLISHED: {
      value: 1,
      name: "Published",
    },
  },
  SEND_TILETYPE: {
    KEY_INFORMATION: {
      value: 0,
      primaryName: "Important Information",
      secondaryName: "Key Information",
    },
    STUDENT_INFORMATION: {
      value: 1,
      primaryName: "All about me",
      secondaryName: "Important to me",
    },
    SUPPORT_STRATEGIES: {
      value: 2,
      primaryName: "Important for me (strategies)",
      secondaryName: "Provision required and support strategies",
    },
    LEARNING_STRATEGIES: {
      value: 3,
      primaryName: "Things I do to support my own learning",
      secondaryName: "Strategies I use to support my own learning",
    },
    ADDITIONAL_INFORMATION: {
      value: 4,
      primaryName: "Additional Information",
      secondaryName: "Additional Information",
    },
    CUSTOM: {
      value: 5,
      primaryName: "Custom",
      secondaryName: "Custom",
    },
  },
  ACADEMIC_YEAR_FILTER_ARRAY: [
    { label: "2024/25", value: 11 },
    { label: "2023/24", value: 10 },
    { label: "2022/23", value: 9 },
    { label: "2021/22", value: 8 },
    { label: "2020/21", value: 7 },
  ],
};

export default constants;
