import React, { useEffect, useState } from "react";
import config from "configuration";
import styled from "styled-components";
import { Spacing, neutral, Icon, honeygold, Video } from "ui-kit";
import { useSelector } from "react-redux";
import LazyLoad from "react-lazyload";
import { files } from "utils";
import feedActions from "../actions/feedActions";
import { useEventListener } from "hooks";
import { RootState } from "reducers/store";


const FeedMediaModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.95);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  button.feed-image-modal-close {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.45);
    border: none;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    transition: all ease 150ms;
    z-index: 9999;

    &:hover {
      background: ${honeygold};
      cursor: pointer;
    }

    i,
    .icon {
      color: ${neutral[100]};
      font-size: 25px;
    }
  }
`;

const FeedMediaModalInner = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100vw;
  height: 100vh;
  align-items: stretch;
  justify-content: stretch;

  button.feed-image-move-button {
    border: 0;
    background: none;
    transition: all ease 150ms;
    padding-right: ${Spacing.Large}px;
    padding-left: ${Spacing.Large}px;
    flex-grow: 1;
    height: 100%;

    i,
    .icon {
      color: rgba(255, 255, 255, 0.6);
      font-size: 35px;
    }

    &:hover {
      i,
      .icon {
        color: ${honeygold};
      }
      cursor: pointer;
    }
  }

  .lazyload-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    max-width: 80%;
    margin: 15px;
  }
  .video {
    max-height: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  video {
    max-height: 100%;
    max-width: 100%;
    margin: 15px;
  }
`;

const FeedMediaModalNext = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 50px;
  width: 50px;

  button {
    justify-content: flex-start;
    text-align: left;
  }
`;

const FeedMediaModalPrevious = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 50px;
  width: 50px;

  button {
    justify-content: flex-end;
    text-align: right;
  }
`;

const FeedMediaModalImage = styled.img`
  display: block;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.25);
  max-height: 100%;
  max-width: 100%;
`;


const FeedMediaModal = () => {

  var { open, images, selectedIndex } = useSelector((state: RootState) => state.feedGallery);
  const [_selectedIndex, _setSelectedIndex] = useState<number | null>(null);

  useEffect(() => {
    _setSelectedIndex(selectedIndex);
  }, [selectedIndex]);

  const handleClose = () => {
    feedActions.closeGallery();
  };

  const handleNext = () => {
    if (_selectedIndex < images.length - 1) {
      _setSelectedIndex(_selectedIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (_selectedIndex > 0) {
      _setSelectedIndex(_selectedIndex - 1);
    }
  };

  const handleKeyDown = (kbEvent: KeyboardEvent) => {
    if (open) {
      switch (kbEvent.key) {
        case "ArrowUp":
        case "ArrowRight":
          handleNext();
          break;
        case "ArrowLeft":
        case "ArrowDown":
          handlePrevious();
          break;
        case "Escape":
          handleClose();
          break;
        default:
          break;
      }
    }
  };

  useEventListener("keydown", handleKeyDown);

  return images && open && _selectedIndex != null ? (
    <FeedMediaModalOverlay className="feed-image-modal">
      <button className="feed-image-modal-close" onClick={handleClose}>
        <Icon value="times" />
      </button>
      <FeedMediaModalInner>
        <FeedMediaModalPrevious>
          {_selectedIndex > 0 && (
            <button
              className="feed-image-move-button feed-image-previous-button"
              onClick={handlePrevious}
            >
              <Icon value="angle-left" />
            </button>
          )}
        </FeedMediaModalPrevious>
        {files.isVideoMimeType(images[_selectedIndex].mimeType) ? (
          <Video
            src={`${config.baseApiUrl(true)}files/stream/${
              images[_selectedIndex].id
            }`}
          />
        ) : (
          <LazyLoad>
            <FeedMediaModalImage
              src={`${config.baseApiUrl(true)}files/images/${
                images[_selectedIndex].id
              }`}
            />
          </LazyLoad>
        )}

        <FeedMediaModalNext>
          {_selectedIndex < images.length - 1 && (
            <button
              className="feed-image-move-button feed-image-next-button"
              onClick={handleNext}
            >
              <Icon value="angle-right" />
            </button>
          )}
        </FeedMediaModalNext>
      </FeedMediaModalInner>
    </FeedMediaModalOverlay>
  ) : null;
};

export default FeedMediaModal;
