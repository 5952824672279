import React from "react";
import { EmptyMessage, Spacing } from "ui-kit";
import styled from "styled-components";


const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  padding: ${Spacing.ExtraLarge}px;
`;


const NotAuthorised = () => {
  return (
    <Wrapper>
      <EmptyMessage
        title="Payment Failed"
        summary="Unfortunately, your payment failed.  This may be because your card was not authorised. Your basket will be stored. Please contact us before attempting to make payment again."
        cover
      />
    </Wrapper>
  );
};


export default NotAuthorised;
