import studentQuizActions from "../../actions/student/studentQuizActions";

const INITIAL_STATE = {
  studentAnswers: [],
  loading: false,
  refresh: false,
  error: null,
  saveAnswerError: null,
};

const { GETSTUDENTANSWERS, SAVESTUDENTANSWER, DELETESTUDENTANSWER } =
  studentQuizActions.types;

const studentQuizReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GETSTUDENTANSWERS.START:
      return { ...state, loading: true, error: null };

    case GETSTUDENTANSWERS.SUCCESS:
      return {
        ...state,
        refresh: false,
        loading: false,
        studentAnswers: action.payload,
      };

    case GETSTUDENTANSWERS.FAILED:
      return {
        ...state,
        refresh: false,
        loading: false,
        error: action.payload,
      };

    case SAVESTUDENTANSWER.START:
      return { ...state, loading: true, saveAnswerError: null };

    case SAVESTUDENTANSWER.SUCCESS:
      return { ...state, refresh: true, loading: false };

    case SAVESTUDENTANSWER.FAILED:
      return { ...state, loading: false, saveAnswerError: action.payload };

    case DELETESTUDENTANSWER.START:
      return { ...state, error: null };

    case DELETESTUDENTANSWER.SUCCESS:
      return { ...state, refresh: true };

    case DELETESTUDENTANSWER.FAILED:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

export default studentQuizReducer;
