/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import {
  neutral,
  typescale,
  Spacing,
  displayFont,
  fontStyle,
  BorderRadius,
  Tooltip,
  Speed,
  Swatches,
  Badge,
} from "../index";
import Tippy from "@tippyjs/react";

export interface ITabBarProps {
  children: React.ReactElement<ITabItemProps>[];
  className?: string;
  selected?: string;
  selectedIndex?: number;
  onItemClicked?: (name: string, index: number, data: any) => void;
}

export interface ITabItemProps {
  className?: string;
  text: string;
  selected?: boolean;
  name: string;
  tooltip?: string;
  tooltipSub?: string;
  onClick?: (name: string) => void;
  hide?: boolean;
  data?: any;
  onFirstClick?(): void;
  badge?: number;
  positionIndex?: number;
}

const TabBarWrapper = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${Spacing.Medium}px;
  border-bottom: 1px solid ${neutral[300]};
  padding-bottom: ${Spacing.Medium}px;
  flex-wrap: wrap;

  .tab-item {
    margin: ${Spacing.Default}px ${Spacing.Default}px 0 ${Spacing.Default}px;
  }
`;

const TabItemWrapper = styled.li<{ isSelected?: boolean; badge?: number }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${fontStyle(displayFont.medium, typescale.paragraph, neutral[700])}
  background: ${neutral[300]};
  border-radius: ${BorderRadius.ExtraLarge}px;
  padding: ${Spacing.ExtraSmall}px ${Spacing.Large}px;
  text-align: center;
  white-space: nowrap;

  .badge {
    position: absolute;
    top: -4px;
    right: -2px;
    padding: 0.2rem;
    span {
      line-height: 1rem !important;
    }
  }

  ${({ badge }) => (badge ? `position: relative;` : ``)}

  ${({ isSelected }) =>
    isSelected
      ? `background: ${Swatches.Blue.swatch}; color: ${neutral[100]}; cursor: default;`
      : `&:hover {
    transition: all ${Speed.Default}ms ease;
    background: ${neutral[600]};
    color: ${neutral[100]};
    cursor: pointer;
  }`}
`;

const TabBar: React.FC<ITabBarProps> & {
  Item: typeof TabItem;
} = ({ children, className, onItemClicked, selected, selectedIndex }) => {

  const [selectedItem, setSelectedItem] = useState(
    selected ? selected : children[0]?.props.name
  );

  useEffect(() => {
    if (selectedIndex != null) {
      setSelectedItem(children[selectedIndex]?.props.name);
    }
  }, [selectedIndex]);

  useEffect(() => {
    selected != null && setSelectedItem(selected);
  }, [selected]);

  const handleClick = (name: string, index: number, data: any) => {
    if (selectedItem !== name) {
      setSelectedItem(name);
      onItemClicked?.(name, index, data);
    }
  };

  return (
    <TabBarWrapper className={`tab-bar ${className ? className : ""}`}>
      {children
        .filter(x => !x.props.hide)
        .map((item, index) => {
          return React.cloneElement(item, {
            key: index,
            onClick: (name: string) => handleClick(name, item.props.positionIndex || index, item.props.data),
            selected: item.props.name === selectedItem,
          });
        })}
    </TabBarWrapper>
  );
};

const TabItem: React.FC<ITabItemProps> = ({
  className,
  text,
  selected,
  name,
  onClick,
  tooltip,
  tooltipSub,
  hide,
  data,
  onFirstClick,
  badge,
  positionIndex,
}) => {
  const [tabClicks, setTabClicks] = useState(0);

  // useEffect(() => {
  //   console.log(name + ": " + tabClicks);
  // }, [tabClicks]);

  useEffect(() => {
    if (selected) {
      tabClicks === 0 && onFirstClick?.();
      setTabClicks(tabClicks + 1);
    }
  }, [selected]);

  const handleClick = () => {
    tabClicks === 0 && onFirstClick?.();
    setTabClicks(tabClicks + 1);
    onClick?.(name);
  };

  const ref = useRef() as React.RefObject<HTMLLIElement>;

  return hide ? null : (
    <>
      <TabItemWrapper
        ref={ref}
        className={`tab-item ${selected ? "tab-item-selected" : ""} ${
          className ? className : ""
        }`}
        isSelected={selected}
        onClick={handleClick}
        badge={badge}
      >
        {text}
        {badge !== undefined && badge !== null && <Badge value={badge} />}
      </TabItemWrapper>
      {tooltip != null && (
        <Tippy
          placement="bottom"
          reference={ref}
          content={<Tooltip title={tooltip} sub={tooltipSub} />}
        />
      )}
    </>
  );
};

TabBar.Item = TabItem;

export default TabBar;
