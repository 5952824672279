import React from "react";
import { Chip, Swatches } from "ui-kit";
import { Constants } from "configuration";


interface IProvisionRecordProgressProps {
  status: number;
  fluid?: boolean;
}


const ProvisionRecordProgress: React.FC<IProvisionRecordProgressProps> = ({ status, fluid }) => {

  var swatch = null;
  var name = "";

  switch (status) {
    case Constants.SEND_PROVISIONRECORDPROGRESS.EXPECTED.value:
      swatch = Swatches.Blue;
      name = Constants.SEND_PROVISIONRECORDPROGRESS.EXPECTED.name;
      break;
    case Constants.SEND_PROVISIONRECORDPROGRESS.ABOVE.value:
      swatch = Swatches.Success;
      name = Constants.SEND_PROVISIONRECORDPROGRESS.ABOVE.name;
      break;
    case Constants.SEND_PROVISIONRECORDPROGRESS.BELOW.value:
      swatch = Swatches.Danger;
      name = Constants.SEND_PROVISIONRECORDPROGRESS.BELOW.name;
      break;
    default:
      swatch = Swatches.Danger;
      name = status.toString();
      break;
  }

  return <Chip text={name} colorSwatch={swatch} fluid={fluid} />;
};

export default ProvisionRecordProgress;
