import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  StructuredList,
  Label,
  Icon,
  TextInput,
  TextInputType,
  Swatches,
  neutral,
  Spacing
} from "ui-kit";

const PASSWORD_RESULT = {
  has8: false,
  has12: false,
  hasDigit: false,
  hasSpecial: false,
  hasUpper: false,
  hasLower: false
};

const ChecklistItem = styled.li<{checked?: boolean}>`
  .icon {
    margin-right: ${Spacing.Default}px;
    margin-top: ${Spacing.Default}px;
    marign-bottom: ${Spacing.Small}px;
  }
  ${({ checked }) =>
    checked
      ? `
        .icon {
            color: ${Swatches.Success.swatch};
        }
        
        .label {
            color: ${neutral[500]};
        }`
      : `
      .label {
        color: ${neutral[500]};
    }
      .icon  {
        color: ${Swatches.Low.swatch};
    }`}
`;


export interface PasswordObj {
  password: string;
  check: string;
  result: {
      has8: boolean;
      has12: boolean;
      hasDigit: boolean;
      hasSpecial: boolean;
      hasUpper: boolean;
      hasLower: boolean;
  };
}

interface IPasswordEditorProps {
  onChange?: (password: PasswordObj) => void;
}

const PasswordEditor: React.FC<IPasswordEditorProps> = ({ onChange }) => {

  const [password, setPassword] = useState<PasswordObj>({
    password: "",
    check: "",
    result: PASSWORD_RESULT
  });

  useEffect(() => {
    if (password) {
      var result = PASSWORD_RESULT;

      if (password.password) {
        result.has8 = password.password.length >= 8;
        result.has12 = password.password.length >= 12;
        result.hasDigit = /\d/.test(password.password);
        result.hasLower = /[a-z]/.test(password.password);
        result.hasUpper = /[A-Z]/.test(password.password);
        result.hasSpecial = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
          password.password
        );
      }
      setPassword({ ...password, result });
    }
  }, [password.password]);

  useEffect(() => {
    password && onChange?.(password);
  }, [password]);

  return (
    <StructuredList>
      <StructuredList.Item
        name="Your Password"
        description="Please
    choose a password that is at least 8 characters long and contains
    a combination of upper and lower case letters, numbers and
    punctuation."
        required
      >
        <TextInput
          value={password.password}
          onChange={value => setPassword({ ...password, password: value })}
          type={TextInputType.Password}
          fluid
          maxLength={50}
        />
        <ul className="checklist">
          <ChecklistItem checked={password.result.has8}>
            <Icon value="check-circle" />
            <Label bold>8 characters long</Label>
          </ChecklistItem>
          <ChecklistItem checked={password.result.has12}>
            <Icon value="check-circle" />
            <Label bold>12 characters long</Label>
          </ChecklistItem>
          <ChecklistItem checked={password.result.hasUpper}>
            <Icon value="check-circle" />
            <Label bold>Uppercase letters</Label>
          </ChecklistItem>
          <ChecklistItem checked={password.result.hasLower}>
            <Icon value="check-circle" />
            <Label bold>Lowercase letters</Label>
          </ChecklistItem>
          <ChecklistItem checked={password.result.hasDigit}>
            <Icon value="check-circle" />
            <Label bold>Numbers</Label>
          </ChecklistItem>
          <ChecklistItem checked={password.result.hasSpecial}>
            <Icon value="check-circle" />
            <Label bold>Special characters</Label>
          </ChecklistItem>
        </ul>
      </StructuredList.Item>
      <StructuredList.Item name="Re-Enter Your Password" required>
        <TextInput
          value={password.check}
          onChange={value => setPassword({ ...password, check: value })}
          type={TextInputType.Password}
          fluid
          maxLength={50}
        />
      </StructuredList.Item>
    </StructuredList>
  );
};

export default PasswordEditor;
