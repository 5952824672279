import React from "react";
import styled from "styled-components";
import Moment from "react-moment";
import { UserName } from "sharedComponents/common";
import {
  neutral,
  CircleIcon,
  Spacing,
  DetailLabel,
  Swatches,
  Chip,
} from "ui-kit";
import { arrays } from "utils";
import { CategoryListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { UserTinyView } from "types/users/userListViews.types";
import { GroupListView } from "types/users/userGroups.types";

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .chip {
    position: relative;
    margin-right: ${Spacing.Small}px;
    margin-left: 0 !important;
    font-size: 10px !important;
    padding: 3px !important;
    line-height: 11px !important;
    padding-top: 0;
    letter-spacing: 0.03rem;
    top: -1px;
  }

  .circle-icon {
    margin-right: ${Spacing.Default}px;
  }

  .feed-item-description {
    margin-left: ${Spacing.Default}px;
  }

  .feed-item-post-type {
    color: ${neutral[500]};
  }
  .feed-item-category {
    b {
      font-weight: bold;
      color: ${neutral[700]};
    }
  }
`;

interface IFeedItemHeaderProps {
  icon?: string;
  user?: UserTinyView;
  category?: CategoryListView;
  groups?: GroupListView[];
  date?: Date;
  postMessage?: JSX.Element | string;
  pinned?: boolean;
}

const FeedItemHeader: React.FC<IFeedItemHeaderProps> = ({
  icon,
  user,
  category,
  groups,
  date,
  postMessage,
  pinned,
}) => {
  const getFeedGroupItem = (
    group: GroupListView,
    index: number,
    count: number
  ) => {
    return (
      <React.Fragment key={index}>
        <b>{group.name}</b>
        {count > 1 && index < count - 1
          ? index == count - 2
            ? " and "
            : ", "
          : ""}
      </React.Fragment>
    );
  };

  return (
    <Wrapper>
      <CircleIcon value={icon} outline color={Swatches.Low.swatch} />
      <DetailLabel
        label={
          <>
            <b>
              <UserName user={user} />
            </b>{" "}
            {postMessage && (
              <span className="feed-item-post-type">{postMessage}</span>
            )}
            {!arrays.isEmpty(groups) && (
              <>
                {" "}
                for{" "}
                {groups.map((group: GroupListView, index: number) =>
                  getFeedGroupItem(group, index, groups.length)
                )}
              </>
            )}
          </>
        }
        sub={
          <>
            {pinned && <Chip text="Pinned" colorSwatch={Swatches.Blue} />}
            <Moment date={date} format="dddd, MMMM Do YYYY [at] h:mma" />{" "}
          </>
        }
      />
    </Wrapper>
  );
};

export default FeedItemHeader;
