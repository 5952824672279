import sensitiveData from "../constants/student/sensitiveData";
import { StudentSlotView } from "../types/classLayoutResponse.types";

// export const getSenCategories = (student) => {
//   return (
//     <ul>
//       {student.senCategories.map(category => (
//         <li key={category.id}> {category.name} </li>
//       ))}
//     </ul>
//   );
// };

export const maxNumberOfSensitiveFlagsAndReadingAge = (studentSlots: StudentSlotView[]) => {
  let maxFlags = 0;
  let isReadingAge = false;
  for (let i = 0; i < studentSlots.length; i++) {
    const student = studentSlots[i].student;
    if (student) {
      let flags = 0;

      if (student.eal) {
        flags++;
      }
      if (student.pupilPremium) {
        flags++;
      }
      // if (student.giftedTalented) {
      //   flags++;
      // }
      if (student.senStatus !== sensitiveData.STUDENT_SEN_KEYS.NOSEN) {
        flags++;
      }
      if (student.freeSchoolMeal) {
        flags++;
      }
      if (student.activePupilPassport) {
        flags++;
      }
      if (student.activeProvisionMap) {
        flags++;
      }
      if (student.lookedAfter) {
        flags++;
      }
      if (student.childProtection) {
        flags++;
      }

      if (flags > maxFlags) {
        maxFlags = flags;
      }

      if (student.readingAge && !isReadingAge) {
        isReadingAge = true;
      }
    }
  }

  return { maxFlags: maxFlags, isReadingAge: isReadingAge };
};
