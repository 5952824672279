import schoolActions from "../../actions/school/schoolActions";

const INITIAL_STATE = {
  schoolPeriods: [],
  loading: false,
  error: null,
  usedSchoolId: null, 
};

const { GETSCHOOLPERIODS, SETSCHOOLID } = schoolActions.types;

const schoolPeriodsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETSCHOOLID:
      return {
        ...state,
        usedSchoolId: action.payload,
      };

    case GETSCHOOLPERIODS.START:
      return { ...state, error: null };

    case GETSCHOOLPERIODS.SUCCESS:
      return { ...state, schoolPeriods: action.payload, loading: false };

    case GETSCHOOLPERIODS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default schoolPeriodsReducer;
