import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Spacing,
  neutral,
  honeygold,
  BorderRadius,
  fontStyle,
  displayFont,
  typescale,
  formatScrollbars,
} from "ui-kit";

export interface IModalProps {
  children:
    | React.ReactElement<IModalBodyProps>
    | React.ReactElement<IModalHeaderProps>
    | React.ReactElement<IModalFooterProps>;
  open?: boolean;
  title?: string;
  width?: string;
  height?: string;
  onOpen?(): void;
  onClose?(): void;
}

export interface IModalBodyProps {
  noPad?: boolean;
  children: React.ReactElement | React.ReactElement[];
}

export interface IModalFooterProps {
  children: React.ReactElement | React.ReactElement[];
}

export interface IModalHeaderProps {
  children: React.ReactElement | React.ReactElement[];
}

const Overlay = styled.div<{open: boolean}>`
  ${({ open }) => (open ? `display: flex;` : `display: none;`)}
  position: fixed;
  z-index: 9998;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  align-items: center;
  justify-content: center;
`;

const ModalWrapper = styled.div<{width?: string, height?: string}>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : "75%")};
  height: ${({ height }) => (height ? height : "75%")};
  border-radius: ${BorderRadius.Large}px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.9);
  border: 4px solid #999;
`;

const ModalClose = styled.div`
  position: absolute;
  z-index: 9998;
  right: -15px;
  top: -15px;
  button {
    padding: 0;
    border: 4px solid white;
    border-radius: 50%;
    display: flex;
    width: 48px;
    height: 48px;
    background: black;
    align-items: center;
    justify-content: center;
    transition: all ease 150ms;

    &:hover {
      background: ${honeygold};
    }

    i,
    .icon {
      margin: 0;
      font-size: 28px;
    }
  }
`;

const ModalTitleBar = styled.div`
  display: flex;
  border-top-right-radius: ${BorderRadius.Default}px;
  border-top-left-radius: ${BorderRadius.Default}px;

  align-items: center;
  .modal-title-bar-left {
    flex-grow: 1;
    ${fontStyle(displayFont.medium, typescale.header3, neutral[600])};
    padding-left: ${Spacing.Medium}px;
  }
  .modal-title-bar-right {
    .button {
      border-top-right-radius: ${BorderRadius.Default}px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      text-align: center;

      background: none;
      padding: ${Spacing.Medium}px;
      i {
        color: ${neutral[600]};
        margin: 0;
      }
      &:hover {
        background: ${honeygold};
      }
    }
  }
`;

const BodyWrapper = styled.div<{noPad?: boolean}>`
  flex-grow: 1;
  padding: ${Spacing.Medium}px;
  background: rgba(0, 0, 0, 0.65);
  overflow-y: auto !important;

  ${({ noPad }) => noPad && `padding: 0;`}
  ${formatScrollbars(neutral[100])};
  iframe {
    box-sizing: box-content;
    margin: 0;
    padding: 0;

    html {
      overflow-y: auto !important;
    }
  }
`;

const HeaderWrapper = styled.div`
  background: ${neutral[100]};
`;

const FooterWrapper = styled.div`
  padding: ${Spacing.Medium}px;
  background: ${neutral[100]};
  border-bottom-right-radius: ${BorderRadius.Default}px;
  border-bottom-left-radius: ${BorderRadius.Default}px;
`;

const PrimaryModal: React.FC<IModalProps> & {
  Body: typeof ModalBody;
  Header: typeof ModalHeader;
  Footer: typeof ModalFooter;
} = ({ children, open, onOpen, onClose, title, width, height }) => {
  const [isOpen, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  useEffect(() => {
    if (open) {
      setOpen(true);
      onOpen?.();
    } else {
      handleClose();
    }
  }, [open]);

  return (
    <Overlay open={isOpen}>
      <ModalWrapper width={width} height={height}>
        <ModalClose>
          <Button icon="times" onClick={handleClose} />
        </ModalClose>
        {children}
      </ModalWrapper>
    </Overlay>
  );
};

const ModalBody: React.FC<IModalBodyProps> = ({ children, noPad }) => {
  return (
    <BodyWrapper className="modal-body" noPad={noPad}>
      {children}
    </BodyWrapper>
  );
};

const ModalHeader: React.FC<IModalHeaderProps> = ({ children }) => {
  return <HeaderWrapper>{children}</HeaderWrapper>;
};

const ModalFooter: React.FC<IModalFooterProps> = ({ children }) => {
  return <FooterWrapper>{children}</FooterWrapper>;
};

PrimaryModal.Body = ModalBody;
PrimaryModal.Header = ModalHeader;
PrimaryModal.Footer = ModalFooter;

export default PrimaryModal;
