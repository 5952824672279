import { success, honeygold } from "ui-kit/common/colors";

export const LAYOUT_DIRECTION = {
  FRONTTOBACK: 0,
  BACKTOFRONT: 1
};

// export const onDragEnterGridColour = "#A7EFAA";
export const onDragEnterGridBorder = `2px ${honeygold} dashed`;

export const LAYOUT_KEYS = {
  ALPHABETICAL: 0,
  RANDOM: 1,
  BOYGIRL: 2,
  PP: 3,
  LAP: 4,
  EAL: 5,
  HAP: 6,
  TUTORGROUP: 7,
  BEHAVIOURPOINTS: 8,
  DETENTIONTODAY: 9,
  PUPILINTERACTION: 10,
  MAP: 11,
  NONE: 12
};

export const LAYOUT_TYPES = [
  {
    key: LAYOUT_KEYS.ALPHABETICAL,
    name: "Alphabetical",
    description:
      "Rearrange seating plan to alphabetical order according lastname",
    order: true,
    disperse: false
  },
  {
    key: LAYOUT_KEYS.RANDOM,
    name: "Random",
    description: "Rearrange seating plan to random order",
    order: false,
    disperse: false
  },
  {
    key: LAYOUT_KEYS.BOYGIRL,
    name: "Boy - Girl",
    description: "Rearrange seating plan according to gender",
    order: false,
    disperse: true
  },
  {
    key: LAYOUT_KEYS.PP,
    name: "Pupil Premium",
    description: "Rearrange seating plan according to Pupil Premium",
    order: false,
    disperse: true
  },
  // {
  //   key: LAYOUT_KEYS.LAP,
  //   name: "LAP",
  //   description: "Rearrange seating plan according to LAP",
  //   order: false,
  //   disperse: true
  // },
  {
    key: LAYOUT_KEYS.EAL,
    name: "EAL",
    description: "Rearrange seating plan according to EAL",
    order: false,
    disperse: true
  },
  // {
  //   key: LAYOUT_KEYS.HAP,
  //   name: "HAP",
  //   description: "Rearrange seating plan according to HAP",
  //   order: false,
  //   disperse: true
  // },
  {
    key: LAYOUT_KEYS.TUTORGROUP,
    name: "Tutor Group",
    description: "Rearrange seating plan according to Tutor Group",
    order: false,
    disperse: true
  },
  {
    key: LAYOUT_KEYS.BEHAVIOURPOINTS,
    name: "Behaviour Points",
    description: "Rearrange seating plan according to Behaviour Points",
    order: false,
    disperse: true
  }
  // {
  //   key: LAYOUT_KEYS.MAP,
  //   name: "MAP",
  //   description: "Rearrange seating plan according to MAP",
  //   order: false,
  //   disperse: true
  // }
];

export const CUSTOM_LAYOUT = {
  key: LAYOUT_KEYS.NONE,
  name: "Create Your Own Custom Seating Plan",
  description:
    "Select this if you don’t want to auto-generate a seating plan. You will then be able to choose where to place your students yourself",
  order: false,
  disperse: false
};

export const DISPERSE_LEVELS = [0, 1, 2, 3, 4];

export const ASC = {
  key: 1,
  name: "Ascending - A to Z order according to last name. "
};

export const DESC = {
  key: 0,
  name: "Descending - Z to A order according to last name"
};
