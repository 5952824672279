export type Swatch = {
  swatch: string;
  foreground: string;
};

export const honeygold = "#ffa000";
export const danger = "#990000";
export const warning = "#ffa000";
export const success = "#339900";
export const low = "#e1e1e1";

export const dangerLight = "#F4E5E5";
export const successLight = "#EAF4E5";
export const purple = "purple";
export const blue = "#0E83BC";
export const orange = "#EC5D00";
export const lightOrange = "#ff8e3f";
export const pink = "deeppink";
export const deepPink = "deeppink";
export const teal = "teal";

export const background = {
  orange: "#dd813e",
  pink: "#ce4273",
  purple: "#59458c",
  gradient: `linear-gradient(to top left, #59458c, #bb1717, #ffa000),
  linear-gradient(to top right, #dd813e, #bb1717, #ce4273)
  rgba(255, 153, 150, 1)`,
};

export const neutral = {
  100: "#ffffff",
  200: "#efefef",
  300: "#e1e1e1",
  400: "#cccccc",
  500: "#999999",
  600: "#737581",
  700: "#4a4b53",
  800: "#050505",
};

export const yellow = {
  100: "#ffcb70",
  200: "#ff3c5c",
  300: "#ffbc47",
  400: "#ffb433",
  500: "#FFAD1F",
  600: honeygold,
};

export interface ColorSwatch {
  swatch: string;
  foreground: string;
}

export const Swatches = {
  Primary: {
    swatch: honeygold,
    foreground: neutral[800],
  },
  Default: {
    swatch: neutral[500],
    foreground: neutral[100],
  },
  Low: {
    swatch: neutral[300],
    foreground: neutral[600],
  },
  Danger: {
    swatch: danger,
    foreground: neutral[100],
  },
  Warning: {
    swatch: warning,
    foreground: neutral[100],
  },
  Success: {
    swatch: success,
    foreground: neutral[100],
  },
  Complete: {
    swatch: "#6699ff",
    foreground: neutral[100],
  },
  Cancel: {
    swatch: neutral[700],
    foreground: neutral[100],
  },
  Inverse: {
    swatch: neutral[100],
    foreground: neutral[500],
  },
  Blue: {
    swatch: blue,
    foreground: neutral[100],
  },
  Purple: {
    swatch: purple,
    foreground: neutral[100],
  },
  Pink: {
    swatch: pink,
    foreground: neutral[100],
  },
  Teal: {
    swatch: teal,
    foreground: neutral[100],
  },
  Orange: {
    swatch: orange,
    foreground: neutral[100],
  },
  Black: {
    swatch: neutral[800],
    foreground: neutral[100],
  },
  Miami: {
    swatch: "#43C6DB",
    foreground: neutral[100],
  },
  DeepPink: {
    swatch: deepPink,
    foreground: neutral[100],
  },
  OrangeRed: {
    swatch: "orangered",
    foreground: neutral[100],
  },

  Primaries: {
    Red: {
      swatch: "#f45760",
      foreground: neutral[100],
    },
    Yellow: {
      swatch: "#f7cf53",
      foreground: neutral[100],
    },
    Blue: {
      swatch: "#38a7f8",
      foreground: neutral[100],
    },
    Green: {
      swatch: "#b8f679",
      foreground: neutral[100],
    },
    Pink: {
      swatch: "#f6c8b9",
      foreground: neutral[100],
    },
    Lilac: {
      swatch: "#d696f7",
      foreground: neutral[100],
    },
    LightBlue: {
      swatch: "#c8e9fa",
      foreground: neutral[100],
    },
  },
};
