import React, { useEffect, useState } from "react";
import { useAppSelector } from "reducers/hooks";
import { ChildTabs } from "sharedComponents/common";
import Subheader from "sharedComponents/layout/header/subheader";
import { StudentStartupView } from "types/users/userListViews.types";
import { EmptyMessage, Loader, Size, Title, TitleSize } from "ui-kit";
import EventsFeed from "./eventsFeed";
import calendarActions from "areas/calendar/actions/calendarActions";


interface IStudentEventsProps {

}


const StudentEvents: React.FC<IStudentEventsProps> = ({  }) => {

  const { user } = useAppSelector(state => state.currentUser);
  const { loading, error, events, paging } = useAppSelector(state => state.studentCalendarEvents);
  const [selectedChild, setSelectedChild] = useState<StudentStartupView>();

  useEffect(() => {
    user && setSelectedChild(user.children[0]);
  }, [user]);

  useEffect(() => {
    selectedChild && getEvents(selectedChild.id, 0);
  }, [selectedChild])

  const getEvents = (studentId: string, page: number) => {
    studentId && calendarActions.getStudentCalendarEvents(studentId, { }, page);
  };

  const handleOnPage = () => {
    if (!loading && selectedChild) {
      getEvents(selectedChild.id, paging.pageIndex + 1);
    }
  };

  const handleTabClicked = (name: string, index: number) => {
    setSelectedChild(user.children[index]);
  };
  
  return (
    <>
      <ChildTabs
        onChildClicked={handleTabClicked}
        singleChild={
          <Subheader>
            <Title
              text="Student Events"
              sub={`${selectedChild?.details.firstName} ${selectedChild?.details.lastName}`}
              size={TitleSize.H2}
            />
          </Subheader>
        }
      />

      {loading && <Loader size={Size.Large} cover />}
      {error && (
        <EmptyMessage
          icon="times-circle"
          title="A problem occurred"
          summary="There was a problem loading the timetable"
          cover
        />
      )}

      {!loading && !error && (
        <EventsFeed
          studentId={selectedChild?.id}
          events={events}
          error={error}
          loading={loading}
          paging={paging}
          onPage={handleOnPage}
          readonly
        />
      )}

    </>
  )
};

export default StudentEvents;