import React, { useEffect, useState } from "react";
import { Flyout, Swatches, Message, Size, Loader } from "ui-kit";
import { Constants } from "configuration";
import { useSelector } from "react-redux";
import { success, neutral, honeygold } from "ui-kit/common/colors";
import styled from "styled-components";
import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import flyoutActions from "actions/ui/flyouts";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import ConfirmModal from "sharedComponents/common/confirmModal";
import SelectionButton from "sharedComponents/common/largeSelectionButtons/selectionButton";
import SelectionButtons from "sharedComponents/common/largeSelectionButtons/selectionButtons";
import { useAppSelector } from "reducers/hooks";
import { StudentListView } from "types/users/userListViews.types";
import { ClassGroupStaffView } from "types/users/userGroups.types";
import { GroupType } from "types/planner/groups.types";
import { SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";
import { CreateRewardCommand, CreateRewardsCommand } from "areas/behaviour/types/behaviourRequest.types";


const Wrapper = styled.div`
  .selection-button {
    &.selected {
      background: ${honeygold};

      .item-label,
      .item-sub {
        color: ${neutral[800]};
      }

      .detail-label {
        .label,
        .sub {
          color: ${neutral[800]};
        }
      }

      .icon {
        color: ${neutral[100]};
      }
    }
  }
`;


interface IMultipleRewardFlyoutProps {
  students: StudentListView[];
  group: ClassGroupStaffView;
  resetSelectedStudents: () => void;
}


const MultipleRewardFlyout: React.FC<IMultipleRewardFlyoutProps> = ({ students, group, resetSelectedStudents }) => {

  const {
    schoolId,
    rewardCategoryGroups,
    loading: loadingRewards,
    getRewardsError,
  } = useAppSelector(state => state.rewardCategories);
  const { initialRewardActions } = useAppSelector(
    state => state.initialRewardActions
  );
  const { loading, error } = useAppSelector(state => state.rewardForMultiple);
  const { addRewardError } = useAppSelector(state => state.reward);
  const { activeFlyout } = useAppSelector(state => state.ui);

  const [reward, setReward] = useState<SchoolBehaviourCategoryListView>(null);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  useEffect(() => {
    if (!initialRewardActions || initialRewardActions.length === 0) {
      rewardActions.getInitialRewardActions();
    }
  }, []);

  useEffect(() => {
    if (
      activeFlyout === Constants.FLYOUTS.MULTIPLE_REWARDS &&
      (!rewardCategoryGroups ||
        rewardCategoryGroups.length === 0 ||
        schoolId !== group.school.id)
    ) {
      rewardActions.getRewardCategories(
        group.school.id,
        {
          isLegacy: null, 
          canBeAwarded: true
        },
        () => rewardActions.setRewardSchoolId(group.school.id)
      );
    }
  }, [activeFlyout]);

  const handleAddRewards = () => {
    const onSuccess = () => {
      flyoutActions.closeFlyout();
      resetSelectedStudents();
      setReward(null);
    };

    if (students.length > 1) {
      const rewards: CreateRewardsCommand = { 
        createRewardCommands: [] 
      };

      students.forEach(student => {
        rewards.createRewardCommands.push({
          studentId: student.id,
          categoryId: reward.id,
          behaviourCodeId: reward.behaviourCodeId,
          points: reward.points,
          classGroupId: group.type === GroupType.ClassGroup ? group.id : null,
          tutorGroupId: group.type === GroupType.TutorGroup ? group.id : null,
          customGroupId: group.type === GroupType.Custom ? group.id : null,
          provisionGroupId: group.type === GroupType.Provision ? group.id : null,
          initialActionId: initialRewardActions[0]?.initialActionId,
          description: "",
          publicNotes: ""
        });
      });

      rewardActions.addRewardForMultipleStudentsInGroup(
        group.id,
        group.type,
        rewards,
        onSuccess
      );
    } else {
      const student = students[0];
      const newPoint: CreateRewardCommand = {
        studentId: student.id,
        categoryId: reward.id,
        behaviourCodeId: reward.behaviourCodeId,
        points: reward.points,
        classGroupId: group.type === GroupType.ClassGroup ? group.id : null,
        tutorGroupId: group.type === GroupType.TutorGroup ? group.id : null,
        customGroupId: group.type === GroupType.Custom ? group.id : null,
        provisionGroupId: group.type === GroupType.Provision ? group.id : null,
        initialActionId: initialRewardActions[0]?.initialActionId,
        description: "",
        publicNotes: ""
      };

      rewardActions.addRewards(
        student.id,
        { createRewardCommands: [newPoint] },
        onSuccess
      );
    }
  };

  const handleCategoryClick = (category: SchoolBehaviourCategoryListView) => {
    setReward(category);
    setOpenConfirm(true);
  };

  return getRewardsError ? (
    <Message
      text="There was a problem loading the categories"
      color={Swatches.Danger}
    />
  ) : (
    <>
      <Flyout
        title="Rewards"
        name={Constants.FLYOUTS.MULTIPLE_REWARDS}
        onClose={() => setReward(null)}
      >
        {loading || loadingRewards ? (
          <Loader size={Size.Large} cover />
        ) : (
          <Flyout.Body>
            <Wrapper>
              <ApiExceptionMessage error={error} />
              <ApiExceptionMessage error={addRewardError} />

              <SelectionButtons listView>
                {rewardCategoryGroups.map(group =>
                  group.behaviourCategories.map(category => (
                    <SelectionButton
                      key={category.id}
                      // className={
                      //   reward && reward.id === category.id ? "selected" : null
                      // }
                      listView={true}
                      item={category}
                      itemLabel={category.codeDisplayName}
                      itemSub={category.behaviourCategoryName}
                      icon="thumbs-up"
                      iconColour={success}
                      handleItemClick={() => handleCategoryClick(category)}
                    />
                  ))
                )}
              </SelectionButtons>
            </Wrapper>
          </Flyout.Body>
        )}
      </Flyout>

      <ConfirmModal
        openModal={openConfirm}
        confirmMsg={`This will give a point to the students for ${
          reward && reward.behaviourCategoryName
        }.`}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          handleAddRewards();
          setOpenConfirm(false);
        }}
      />
    </>
  );
};

export default MultipleRewardFlyout;
