import React, { useState } from "react";
import {
  Wizard,
  HtmlText,
  Card,
  Title,
  TitleSize,
  Sub,
  Checkbox,
  StructuredList,
  TextInput,
} from "ui-kit";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { arrays } from "utils";
import { Constants } from "configuration";
import consentActions from "areas/administration/actions/consentActions";
import { ParentView } from "sharedComponents/common/users/userTypeView";
import UserName from "sharedComponents/common/users/userName";
import {
  ConsentFormItemListView,
  ConsentFormSectionView,
  ConsentFormUserDetailsView,
  ConsentFormUserView,
  ConsentResponseResult,
} from "areas/administration/types/consentResponses.types";
import { RootState } from "reducers/store";
import { SubmitConsentResponseCommand } from "areas/administration/types/consentRequest.types";
import routes from "../../../../configuration/routes";
import { consentRoutes } from "../../adminRoutes";

interface IConsentWizardProps {
  form: ConsentFormUserView;
}

const ConsentWizard: React.FC<IConsentWizardProps> = ({ form }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { working, error } = useSelector(
    (state: RootState) => state.consentSubmission
  );
  const [command, setCommand] = useState<SubmitConsentResponseCommand | null>(
    null
  );

  useEffect(() => {
    if (form) {
      setCommand({
        formId: form.id,
        declarationName: null,
        declarationRelationship: null,
        individualCommands: form.users.map(user => ({
          userId: user.user.id,
          items: form.sections
            .flatMap(s => s?.itemGroups.flatMap(u => u?.items))
            ?.map(item => ({
              itemId: item.id,
              consentGiven: arrays.sortByDate(user.history)?.[0]?.items?.find(x => x.itemId === item.id)?.consentGiven === ConsentResponseResult.ConsentGiven,
            })),
        })),
      });
    }
  }, [form]);

  if (!form) {
    return null;
  }

  const Begin = () => {
    return (
      <p>
        To begin, please click on <b>'Next'</b>.
      </p>
    );
  };

  const validateDeclaration = () => {
    return new Promise((resolve, reject) => {
      const errors = [];

      if (!command.declarationName) {
        errors.push("Please enter your full name.");
      }

      if (!command.declarationRelationship) {
        errors.push(
          "Please enter your relationship to the student(s) on this form."
        );
      }

      if (errors.length > 0) {
        reject(errors.join(" "));
      } else {
        resolve(true);
      }
    });
  };

  const setItemValue = (
    userIndex: number,
    item: ConsentFormItemListView,
    value: boolean
  ) => {
    if (!command) {
      return;
    }

    const newValue = {
      ...command,
      individualCommands: command.individualCommands.map((com, i) =>
        i === userIndex
          ? {
              ...com,
              items: command.individualCommands[i].items?.map(
                (commandItem, k) =>
                  commandItem.itemId === item.id
                    ? { ...commandItem, consentGiven: value }
                    : commandItem
              ),
            }
          : com
      ),
    };

    setCommand(newValue);
  };

  const isDeclaration = (section: ConsentFormSectionView) => {
    return (
      section.sectionType ===
      Constants.CONSENTFORM_SECTIONTYPE.DECLARATION.value
    );
  };

  const handleFinish = () => {
    return new Promise((resolve, reject) => {
      consentActions.submitConsentResponse(user.id, form.id, command, () => {
        resolve(true);
      });
    });
  };

  const handleComplete = () => {
    navigate(`${routes.home}${consentRoutes.default}`);
  };

  const getSteps = () => {
    const steps = [];

    steps.push(
      ...form.sections?.map(
        (section: ConsentFormSectionView, index: number) => (
          <Wizard.Step
            key={index}
            title={section.title}
            showTitle={false}
            onValidate={isDeclaration(section) ? validateDeclaration : null}
          >
            {section.description && (
              <Card
                title={isDeclaration(section) ? "Declaration of Consent" : null}
              >
                <Card.Body>
                  <HtmlText html={section.description} />
                </Card.Body>
              </Card>
            )}

            {index === 0 && (
              <ParentView>
                <Card title="Who Is This Form For?">
                  <Card.Body>
                    {form.users.length === 1 ? (
                      <p>
                        This form is for{" "}
                        <b>
                          <UserName user={form.users[0].user} />
                        </b>
                      </p>
                    ) : (
                      <>
                        <p>This form is for:</p>
                        <ul>
                          {form.users.map((u, i) => (
                            <li key={i}>
                              <b>
                                <UserName user={u.user} />
                              </b>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                    <Begin />
                  </Card.Body>
                </Card>
              </ParentView>
            )}

            <>
              {!arrays.isEmpty(section.itemGroups) &&
                form.users.map(
                  (
                    formUser: ConsentFormUserDetailsView,
                    formUserIndex: number
                  ) => (
                    <div key={formUserIndex}>
                      <Title
                        text={`${section.title} for ${formUser.user.firstName} ${formUser.user.lastName}`}
                        size={TitleSize.H3}
                      />

                      {section.itemGroups?.map((group, index) => (
                        <Card title={group.title} key={index}>
                          <Card.Body>
                            <HtmlText html={group.description} />

                            {group.items?.map(
                              (item: ConsentFormItemListView, j: number) => (
                                <div key={j}>
                                  <Checkbox
                                    checked={
                                      command?.individualCommands?.[
                                        formUserIndex
                                      ]?.items.find(x => x.itemId === item.id)
                                        ?.consentGiven
                                    }
                                    onChange={value =>
                                      setItemValue(
                                        formUserIndex,
                                        item,
                                        value.checked
                                      )
                                    }
                                    text={item.text}
                                  />
                                </div>
                              )
                            )}
                            <div style={{ marginTop: "20px" }}>
                              <Sub>
                                By ticking the tickbox you are providing your
                                consent. If you want to withhold your consent,
                                then please leave the tickbox unticked.
                              </Sub>
                            </div>
                          </Card.Body>
                        </Card>
                      ))}
                    </div>
                  )
                )}
            </>
            {isDeclaration(section) && (
              <Card title="Signature">
                <Card.Body>
                  <StructuredList>
                    <StructuredList.Item
                      name="Your Name"
                      description="Please enter your full name"
                    >
                      <TextInput
                        placeholder="e.g. John Smith"
                        value={command?.declarationName}
                        onChange={value =>
                          setCommand({ ...command, declarationName: value })
                        }
                      />
                    </StructuredList.Item>
                    <StructuredList.Item
                      name="Relationship to Student(s)"
                      description="Please enter your relationship to the students on this form."
                    >
                      <TextInput
                        placeholder="e.g. Father"
                        value={command?.declarationRelationship}
                        onChange={value =>
                          setCommand({
                            ...command,
                            declarationRelationship: value,
                          })
                        }
                      />
                    </StructuredList.Item>
                  </StructuredList>
                </Card.Body>
              </Card>
            )}
          </Wizard.Step>
        )
      )
    );

    steps.push(
      <Wizard.Step completion>
        <Card title="Consent Complete">
          <Card.Body>
            <p>Thank you for submitting your consent declaration.</p>
            {form.allowChange && (
              <p>
                You are able to change your consent at any time. To do this,
                simply click on the <b>Completed Forms</b> tab in the{" "}
                <b>Consent</b> section of Beehive and choose the{" "}
                <b>'{form.name}'</b> form.
              </p>
            )}
            <p>
              If you have any further questions about this consent form, please
              contact your school.
            </p>
          </Card.Body>
        </Card>
      </Wizard.Step>
    );

    return steps;
  };
  return (
    <>
      <Wizard
        onFinish={handleFinish}
        working={working}
        error={error}
        finishButtonText="Submit Consent Declaration"
        showHeaderSteps={true}
        completionButtonText="Finish"
        onComplete={handleComplete}
      >
        {getSteps()}
      </Wizard>
    </>
  );
};

export default ConsentWizard;
