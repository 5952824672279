import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  Flyout,
  ActionBar,
  Button,
  Swatches,
  HeadlineStatistic,
  Currency,
  Checkbox,
  Table,
  ToastService,
  Message,
  TextInput,
  TextInputType,
  ValidationMessage
} from "ui-kit";
import { Constants } from "configuration";
import flyoutActions from "actions/ui/flyouts";
import { useNavigate } from "react-router-dom";
import { arrays } from "utils";
import paymentActions from "areas/payments/actions/paymentsActions";
import { RootState } from "reducers/store";
import { OrderDetailView, OrderLineView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import paymentsActions from "areas/payments/actions/paymentsActions";
import { IssueRefundCommand, RefundLineDetail } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";


const RefundControlWrapper = styled.div`
  display: flex;
  .refund-cancel-line {
    width: auto !important;
    min-width: auto !important;
    input {
      max-width: 4rem !important;
      width: 4rem !important;
    }
  }
  .checkbox {
    margin-left: auto;
    label {
      margin-left: auto;
    }
  }
`;


interface OrderLine extends OrderLineView {
  refund?: boolean;
  refundAmount?: number;
  cancel?: boolean;
}

interface OrderDetail extends OrderDetailView {
  lines: OrderLine[]
}

interface ICreateRefundFlyoutProps {
  order: OrderDetailView;
  onRefundCreated?: () => void;
}


const CreateRefundFlyout: React.FC<ICreateRefundFlyoutProps> = ({ order, onRefundCreated }) => {

  const { refunding, refundError } = useSelector((state: RootState) => state.userOrder);
  const [_order, _setOrder] = useState<OrderDetail>(order);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    _setOrder(order);
  }, [order]);

  const handleClose = () => {
    flyoutActions.closeFlyout();
  };

  const calculateRefundTotal = () => {
    return _order.lines.reduce(function(prev, cur) {
      return prev + Number(cur.refundAmount ? cur.refundAmount : 0);
    }, 0);
  };

  const handleCreateRefund = () => {
    const errors = [];

    var noRefunds = _order.lines.every(function(line) {
      return !line.refund;
    });

    if (noRefunds) {
      errors.push(`No lines have been selected to refund.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    const lines: RefundLineDetail[] = _order.lines
      .filter(line => line.refund)
      .map(line => ({
        orderLineId: line.id,
        total: line.refundAmount,
        cancel: line.cancel
      }));

    const data: IssueRefundCommand = {
      userId: order.user.id,
      orderId: order.id,
      lines: lines
    };

    paymentsActions.issueRefund(order.user.id, data, () => {
      onRefundCreated?.();
      ToastService.pop("Refund created successfully", null, "receipt");
      flyoutActions.closeFlyout();
    });
  };

  return (
    <Flyout
      title={`Create Refund`}
      titleSub={`Order ${_order.id}`}
      name={Constants.FLYOUTS.CREATEREFUND}
      wide
    >
      <Flyout.Body>
        <HeadlineStatistic>
          <HeadlineStatistic.Item
            icon="receipt"
            label="Total"
            value={<Currency value={_order.total} />}
          />
          <HeadlineStatistic.Item
            icon="receipt"
            label="Paid"
            value={<Currency value={_order.paid} />}
          />
          <HeadlineStatistic.Item
            icon="receipt"
            label="Refunded"
            value={<Currency value={_order.refunded} />}
          />
        </HeadlineStatistic>
        <ValidationMessage errors={validationErrors} />
        <Message text={refundError} color={Swatches.Danger} />
        <Table>
          <Table.Header>
            <Table.HeaderCell width={0.5} />
            <Table.HeaderCell>Item</Table.HeaderCell>
            <Table.HeaderCell right width={1}>
              Total
            </Table.HeaderCell>
            <Table.HeaderCell right width={1}>
              Paid
            </Table.HeaderCell>
            <Table.HeaderCell right width={1}>
              Refunded
            </Table.HeaderCell>

            <Table.HeaderCell width={2}>Refund</Table.HeaderCell>
            <Table.HeaderCell width={0.5} right>
              Cancel
            </Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {_order.lines.map((line, index) => (
              <Table.Row>
                <Table.Cell>
                  {line.refundable > 0 && (
                    <Checkbox
                      checked={line.refunded > 0}
                      onChange={(value) => {
                        _setOrder({
                          ..._order,
                          lines: _order.lines.map((x, i) =>
                            line.id == x.id
                              ? {
                                  ...x,
                                  refund: !x.refund,
                                  refundAmount: x.refundable
                                }
                              : x
                          )
                        });
                      }}
                    />
                  )}
                </Table.Cell>
                <Table.Cell>{line.description}</Table.Cell>
                <Table.Cell right width={1}>
                  <b>
                    <Currency value={line.total} />
                  </b>
                </Table.Cell>
                <Table.Cell right width={1}>
                  <Currency value={line.paid} />
                </Table.Cell>
                <Table.Cell right width={1}>
                  <Currency value={line.refunded} />
                </Table.Cell>
                <Table.Cell width={2}>
                  {line.refundable > 0 && (
                    <RefundControlWrapper>
                      <TextInput
                        disabled={!line.refund}
                        className="refund-cancel-line"
                        type={TextInputType.Currency}
                        value={line.refundAmount}
                        min={0}
                        max={line.refundable}
                        onChange={value =>
                          _setOrder({
                            ..._order,
                            lines: _order.lines.map((x, i) =>
                              line.id == x.id
                                ? {
                                    ...x,
                                    refundAmount:
                                      value <= line.refundable
                                        ? value
                                        : line.refundable
                                  }
                                : x
                            )
                          })
                        }
                      />
                    </RefundControlWrapper>
                  )}
                </Table.Cell>
                <Table.Cell width={0.5} right>
                  <Checkbox
                    checked={line.cancel}
                    onChange={() => {
                      _setOrder({
                        ..._order,
                        lines: _order.lines.map((x, i) =>
                          line.id == x.id
                            ? {
                                ...x,
                                cancel: !x.cancel
                              }
                            : x
                        )
                      });
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Cell right colspan={7}>
              Refund Total{" "}
              <b>
                <Currency value={calculateRefundTotal()} />
              </b>
            </Table.Cell>
          </Table.Footer>
        </Table>
      </Flyout.Body>
      <Flyout.Footer>
        <ActionBar low>
          <Button
            text="Create Refund"
            onClick={handleCreateRefund}
            color={Swatches.Success}
            fluid
            working={refunding}
          />
        </ActionBar>
      </Flyout.Footer>
    </Flyout>
  );
};

export default CreateRefundFlyout;
