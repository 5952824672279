import startupActions from "actions/startup/startupActions";
import customGroupActions from "../../actions/group/customGroupActions";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";
import { GroupListView } from "types/users/userGroups.types";


interface ICustomGroupsState {
  groups: GroupListView[];
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: ICustomGroupsState = {
  groups: [],
  loading: false,
  error: null,
};


const customGroupsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ICustomGroupsState => {

  const { GETCUSTOMGROUPSFORUSER } = customGroupActions.types;
  const { GETUSER } = startupActions.types;

  switch (action.type) {
    case GETUSER.SUCCESS:
      return {
        ...state,
        groups: [],
      };

    case GETCUSTOMGROUPSFORUSER.START:
      return { ...state, loading: true, error: null };

    case GETCUSTOMGROUPSFORUSER.SUCCESS:
      return {
        ...state,
        groups: action.payload,
        loading: false,
      };

    case GETCUSTOMGROUPSFORUSER.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default customGroupsReducer;
