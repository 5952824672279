import React from "react";
import { Button, Size } from "ui-kit";
import { useNavigate } from "react-router-dom";


const LoginButton = () => {
  
  const navigate = useNavigate();

  return (
    <Button size={Size.Large} text="Login" onClick={() => navigate("/")} />
  );
};

export default LoginButton;
