import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Constants } from "configuration";
import { getDateTime, todayTime, formatDate, formatTime } from "utils/dateTime";
import { useSelector } from "react-redux";
import {
  Swatches,
  Loader,
  ToastService,
  Button,
  Modal,
  ActionBar,
  Spacing,
  neutral,
  Size,
  fontStyle,
  displayFont,
  typescale,
  ValidationMessage,
  Right,
} from "ui-kit";
import MultipleBehaviourModalContent from "./multipleBehaviourModalContent";
import moment from "moment";
import { DETENTION_OPTIONS_KEYS } from "areas/behaviour/constants/detentions";
import { IBehaviourDetails, IDetentionDetails, IMultipleBehaviourResponse, MultipleBehavioursSaveResultsViewExt, getDefaultDetentionInfo } from "areas/behaviour/utils/detentions";
import {
  BULK_BEHAVIOUR_STATUS,
  OUTCOMES,
  OUTCOME_KEYS,
} from "areas/behaviour/constants/behaviours";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import { bulkBehaviourValidation } from "areas/behaviour/utils/behaviours";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import ConfirmModal from "sharedComponents/common/confirmModal";
import { StudentListView } from "types/users/userListViews.types";
import { CreateDetention, MultipleBehavioursSaveResultsView, SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";
import { ClassGroupStaffView, GroupType } from "types/users/userGroups.types";
import { useAppSelector } from "reducers/hooks";
import { CreateBehaviourCommand } from "areas/behaviour/types/behaviourRequest.types";
import { objects } from "utils";
import { DetentionOption } from "areas/behaviour/types/behaviourShared.types";



const StudentNameError = styled.div`
  ${fontStyle(displayFont.bold, typescale.header4, neutral[700])};
  margin-left: ${Spacing.Small};
`;


interface IMultipleBehaviourModalProps {
  openModal: boolean;
  onClose: () => void;
  students: StudentListView[];
  selectedCategory: SchoolBehaviourCategoryListView;
  group: ClassGroupStaffView;
}


const MultipleBehaviourModal: React.FC<IMultipleBehaviourModalProps> = ({
  openModal,
  onClose,
  students,
  selectedCategory,
  group,
}) => {

  const { multipleBehaviourResponse, error, loading } = useAppSelector(
    state => state.behaviourForMultipleStudents
  );
  const { schoolInformation } = useAppSelector(state => state.school);

  const [behaviourDetails, setBehaviourDetails] = useState<IBehaviourDetails>({
    incidentDetails: {
      period: null,
      incidentLocation: null,
      otherIncidentLocation: null,
      incidentDate: moment().format(),
      incidentTime: todayTime(),
      initialActionId: null,
      initialActionName: null
    },
    detentionDetails: null,
    initialAction: {
      initialActionId: null,
      initialActionName: "",
    },
    points: null,
    outcome: OUTCOME_KEYS.RESOLVED,
    internalNotes: "",
    publicNotes: "",
    class: null
  });

  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const [bulkValidationErrors, setBulkValidationErrors] = useState<{ studentName: string, errors: string[] }[]>([]);
  const [openBulkBehaviourConfirm, setBulkBehaviourOpenConfirm] =
    useState<boolean>(false);

  useEffect(() => {
    let tempDetentionDetails = null;

    if (selectedCategory) {
      if (
        !selectedCategory.leadershipInterventionRequired &&
        selectedCategory.defaultDetentionType &&
        selectedCategory.detentionOptionId === DETENTION_OPTIONS_KEYS.MANDATORY
      ) {
        tempDetentionDetails = getDefaultDetentionInfo(
          selectedCategory.defaultDetentionType,
          schoolInformation.detentionCutOffTime,
          true,
          schoolInformation?.id
        );
      }

      setBehaviourDetails({
        incidentDetails: {
          period: null,
          incidentLocation: null,
          otherIncidentLocation: null,
          incidentDate: moment().format(),
          incidentTime: todayTime(),
          initialActionId: null,
          initialActionName: null
        },
        detentionDetails: tempDetentionDetails,
        initialAction: {
          initialActionId: null,
          initialActionName: "",
        },
        points: selectedCategory.points,
        outcome: OUTCOME_KEYS.RESOLVED,
        publicNotes: "", 
        internalNotes: "", 
        class: null
      });
    }
  }, [selectedCategory]);

  useEffect(() => {
    setValidationErrors([]);
    setBulkValidationErrors([]);

    const behaviourResponse: IMultipleBehaviourResponse = {
      bulkBehaviourStatus: BULK_BEHAVIOUR_STATUS.NOT_SENT,
      incidentDetails: null,
      detentionDetails: null,
      behaviours: students.map(student => ({
        errorMessage: null,
        isSuccessful: null,
        detentionDetails: null,
        student: {
          id: student.id,
          firstName: student.firstName,
          lastName: student.lastName,
          title: student.title,
          initials: student.initials,
          admissionStatus: student.admissionStatus,
        },
        detentions: null
      })),
    };

    behaviourActions.createMultipleBehaviourResponse(behaviourResponse);
  }, [students, selectedCategory]);

  const readyToSubmit = () => {
    console.log(behaviourDetails);

    if (behaviourDetails.initialAction.initialActionId === null) {
      console.log("NO INITIAL ACTION");
      return false;
    } 
    
    if ( behaviourDetails.detentionDetails && behaviourDetails.detentionDetails.detentionLocationId === null) {
      console.log("NO DETENTION LOCATION");
      return false;
    }

    console.log("READY");
    return true;
  };

  const callback = (response: MultipleBehavioursSaveResultsView[]) => {
    console.log(response);
    if (response) {
      const hasError = (behaviourItem: MultipleBehavioursSaveResultsView) => behaviourItem.errorMessage !== null;
      if (!response.some(hasError)) {
        ToastService.pop(
          "Successfully Added all Behaviours",
          null,
          "check-circle"
        );
        onClose();
      }
    }
  };

  const getDetentionPayload = (detentionDetails: IDetentionDetails) => {
    const detentionDateTime = getDateTime(
      formatDate(detentionDetails.date),
      detentionDetails.time
    );
    return {
      detentionTypeId: detentionDetails.detentionTypeId,
      roomId: detentionDetails.detentionLocationId,
      duration: detentionDetails.duration,
      detentionDate: detentionDateTime,
      detentionNote: detentionDetails.detentionNotes
    };
  };

  const handleAddBehaviourForStudents = () => {
    let detention: CreateDetention = null;
    if (behaviourDetails.detentionDetails) {
      detention = getDetentionPayload(behaviourDetails.detentionDetails);
    }

    const multipleBehaviours: CreateBehaviourCommand[] = [];
    const dummyMultipleBehaviourResponse: MultipleBehavioursSaveResultsViewExt[] = [];

    const incidentDetails = {
      description: behaviourDetails.internalNotes,
      publicNotes: behaviourDetails.publicNotes,
      categoryId: selectedCategory.id,
      behaviourCodeId: selectedCategory.behaviourCodeId,
      points: behaviourDetails.points,
      outcomeId: behaviourDetails.outcome ? behaviourDetails.outcome : null,
      initialActionId: behaviourDetails.initialAction.initialActionId,
      classGroupId:
        group.type === GroupType.ClassGroup ? group.id : null,
      tutorGroupId:
        group.type === GroupType.TutorGroup ? group.id : null,
      customGroupId:
        group.type === GroupType.Custom ? group.id : null,
      provisionGroupId:
        group.type === GroupType.Provision ? group.id : null,
      incidentDate: getDateTime(
        formatDate(behaviourDetails.incidentDetails.incidentDate),
        behaviourDetails.incidentDetails.incidentTime
      ),
      roomId: behaviourDetails.incidentDetails.incidentLocation,
      otherLocation: behaviourDetails.incidentDetails.otherIncidentLocation,
      periodId: behaviourDetails.incidentDetails.period,
    };

    students.forEach(student => {
      multipleBehaviours.push({
        ...incidentDetails,
        studentId: student.id,
        createDetention: detention,
      });

      dummyMultipleBehaviourResponse.push({
        student: {
          id: student.id,
          firstName: student.firstName,
          lastName: student.lastName,
          title: student.title,
          initials: student.initials,
          admissionStatus: student.admissionStatus,
        },
        detentionDetails: behaviourDetails.detentionDetails,
        isSuccessful: true,
        errorMessage: null,
        detentions: null
      });
    });

    let multipleBehaviourResponseCopy = objects.deepClone(multipleBehaviourResponse);

    multipleBehaviourResponseCopy = {
      ...multipleBehaviourResponseCopy,
      incidentDetails: {
        ...incidentDetails,
        behaviourCodeName: selectedCategory.behaviourCodeName,
        behaviourCategoryName: selectedCategory.behaviourCategoryName,
        leadershipInterventionRequired:
          selectedCategory.leadershipInterventionRequired,
      },
      behaviours: dummyMultipleBehaviourResponse,
    };

    behaviourActions.createMultipleBehaviourResponse(
      multipleBehaviourResponseCopy
    );

    behaviourActions.addBehaviourForMultipleStudentsInGroup(
      group.id,
      group.type,
      { createBehaviourCommands: multipleBehaviours },
      callback
    );
  };

  const handleAddBehavioursAfterFirstAttempt = () => {
    const multipleBehaviours:  CreateBehaviourCommand[] = [];

    const incidentDetails = multipleBehaviourResponse.incidentDetails;
    multipleBehaviourResponse.behaviours.forEach(behaviour => {
      multipleBehaviours.push({
        studentId: behaviour.student.id,
        description: incidentDetails.description,
        publicNotes: incidentDetails.publicNotes,
        categoryId: incidentDetails.categoryId,
        behaviourCodeId: incidentDetails.behaviourCodeId,
        points: incidentDetails.points,
        outcomeId: incidentDetails.outcome,
        initialActionId: incidentDetails.initialActionId,
        classGroupId:
          group.type === GroupType.ClassGroup ? group.id : null,
        tutorGroupId:
          group.type === GroupType.TutorGroup ? group.id : null,
        customGroupId:
          group.type === GroupType.Custom ? group.id : null,
        provisionGroupId:
          group.type === GroupType.Provision ? group.id : null,
        incidentDate: incidentDetails.incidentDateTime,
        roomId: incidentDetails.incidentLocation,
        otherLocation: incidentDetails.otherIncidentLocation,
        periodId: incidentDetails.period,
        createDetention: behaviour.detentionDetails
          ? getDetentionPayload(behaviour.detentionDetails)
          : null,
      });
    });

    behaviourActions.addBehaviourForMultipleStudentsInGroup(
      group.id,
      group.type,
      { createBehaviourCommands: multipleBehaviours },
      callback
    );
  };

  const handleAddBehavioursClick = () => {
    if (
      multipleBehaviourResponse.bulkBehaviourStatus ===
      BULK_BEHAVIOUR_STATUS.NOT_SENT
    ) {
      const errors = bulkBehaviourValidation(
        behaviourDetails.incidentDetails,
        behaviourDetails.detentionDetails,
        schoolInformation.detentionCutOffTime
      );

      if (errors.length > 0) {
        setValidationErrors(errors);
      } else {
        setValidationErrors([]);
        setOpenConfirm(true);
      }
    } else {
      const validationErrorsCopy = [...bulkValidationErrors];

      const incidentDateTime =
        multipleBehaviourResponse.incidentDetails.incidentDateTime;

      multipleBehaviourResponse.behaviours.forEach(behaviour => {
        const errors = bulkBehaviourValidation(
          {
            incidentDate: incidentDateTime,
            incidentTime: formatTime(incidentDateTime),
            period: null,
            incidentLocation: null,
            otherIncidentLocation: null,
            initialActionId: null,
            initialActionName: null
          },
          behaviour.detentionDetails,
          schoolInformation.detentionCutOffTime
        );

        if (errors.length > 0) {
          validationErrorsCopy.push({
            studentName: `${behaviour.student.firstName} ${behaviour.student.lastName}`,
            errors: errors,
          });
        }
      });

      if (validationErrorsCopy.length > 0) {
        setBulkValidationErrors(validationErrorsCopy);
      } else {
        setBulkValidationErrors([]);
        setBulkBehaviourOpenConfirm(true);
      }
    }
  };

  return (
    <>
      <Modal
        title="Add Multiple Behaviours"
        open={openModal}
        onClose={onClose}
        height="80%"
        width="75%"
      >
        {loading ? (
          <Loader size={Size.Large} cover />
        ) : (
          <>
            <Modal.Body>
              <MultipleBehaviourModalContent
                multipleBehaviourResponse={multipleBehaviourResponse}
                selectedCategory={selectedCategory}
                behaviourDetails={behaviourDetails}
                setBehaviourDetails={setBehaviourDetails}
                group={group}
              />
            </Modal.Body>

            <Modal.Footer>
              <ApiExceptionMessage error={error} />

              {validationErrors.length > 0 && (
                <ValidationMessage errors={validationErrors} />
              )}

              <>
              {bulkValidationErrors.length > 0 &&
                bulkValidationErrors.map((errorInfo, index) => (
                  <>
                    <StudentNameError>{errorInfo.studentName}</StudentNameError>
                    <ValidationMessage key={index} errors={errorInfo.errors} />
                  </>
                ))}
              </>
              <ActionBar low>
                <Right>
                  <Button
                    text="Add Behaviours"
                    color={Swatches.Success}
                    size={Size.Small}
                    onClick={handleAddBehavioursClick}
                    disabled={readyToSubmit() === false}
                  />
                  <Button text="Cancel" size={Size.Small} onClick={onClose} />
                </Right>
              </ActionBar>
            </Modal.Footer>
          </>
        )}
      </Modal>

      <ConfirmModal
        openModal={openConfirm}
        confirmMsg={
          selectedCategory &&
          selectedCategory.detentionOptionId === DetentionOption.Yes
            ? `Are you sure you want to add a sanction for ${
                selectedCategory && selectedCategory.behaviourCategoryName
              }? This will be sent to parents.`
            : `Are you sure you want to add a Behaviour point for ${
                selectedCategory && selectedCategory.behaviourCategoryName
              }?`
        }
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          handleAddBehaviourForStudents();
          setOpenConfirm(false);
        }}
      />

      <ConfirmModal
        openModal={openBulkBehaviourConfirm}
        confirmMsg={`Are you sure you want to add a behaviour point for ${
          selectedCategory && selectedCategory.behaviourCategoryName
        }? A notification will be sent to parents for any sanctions given with the behaviours.`}
        onClose={() => setBulkBehaviourOpenConfirm(false)}
        onConfirm={() => {
          handleAddBehavioursAfterFirstAttempt();
          setBulkBehaviourOpenConfirm(false);
        }}
      />
    </>
  );
};

export default MultipleBehaviourModal;
