import studentProfileActions from "../../actions/student/studentProfileActions";

const INITIAL_STATE = {
  loading: false,
  error: null,
};

const { UPDATEAVATAR } = studentProfileActions.types;

const studentProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATEAVATAR.START:
      return { ...state, loading: true, error: null };

    case UPDATEAVATAR.SUCCESS:
      return { ...state, loading: false };

    case UPDATEAVATAR.FAILED:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default studentProfileReducer;
