import types from "actions/ui/uiTypes";
import { Constants } from "configuration";
import { IBeehiveAction } from "types/common/action.types";


interface INavigationState {
  title: string | null;
  open: boolean;
  hover: boolean;
  mode: number;
}

const INITIAL_STATE: INavigationState = {
  title: null,
  open: false,
  hover: false,
  mode: Constants.NAVIGATION_MODES.FULL,
};


const navigationReducer = (state = INITIAL_STATE, action: IBeehiveAction) : INavigationState => {
  switch (action.type) {
    case types.SET_TITLE:
      return { ...state, title: action.payload };
    case types.TOGGLE_MENU:
      return { ...state, open: !state.open };
    case types.CLOSE_MENU:
      return { ...state, open: false };
    case types.OPEN_MENU:
      return { ...state, open: true };
    case types.TOGGLE_HOVER_MENU:
      return { ...state, hover: !state.hover };
    case types.MENU_SET_MODE:
      if (action.payload != state.mode) {
        return { ...state, mode: action.payload };
      }
      return state;
    default:
      return state;
  }
};

export default navigationReducer;
