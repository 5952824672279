import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ProvidersTable from "./providersTable";
import providerActions from "areas/send/actions/providers/providerActions";
import { RootState } from "reducers/store";


const Providers = () => {

  const { error, loading, providers } = useSelector((state: RootState) => state.providers);

  useEffect(() => {
    providerActions.getProviders();
  }, []);

  return (
    <ProvidersTable providers={providers} loading={loading} error={error} />
  );
};

export default Providers;
