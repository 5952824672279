import downloadReducer from "./downloadReducer";
import importStudentCsvReducer from "./importStudentCsvReducer";
import inTrayReducer from "./inTrayReducer";
import outTrayReducer from "./outTrayReducer";
import uploadReducer from "./uploadReducer";

const filing = {
  download: downloadReducer,
  importStudentCsv: importStudentCsvReducer,
  inTray: inTrayReducer,
  outTray: outTrayReducer,
  upload: uploadReducer,
};

export default filing;
