import declarationsOfInterestActions from "areas/forms/actions/declarations/declarationsOfInterestActions";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";
import { StaffListView } from "types/users/userListViews.types";


export interface ISubmissionFilter {
  schoolId: number,
  academicYearId: number;
  searchTerm: string;
  submitter: StaffListView;
}

interface IAdminDeclarationsFilterState {
  loading: boolean;
  error: IBeehiveError;
  filter: ISubmissionFilter;
}

const INITIAL_STATE: IAdminDeclarationsFilterState = {
  loading: false,
  error: null,
  filter: {
    schoolId: null,
    academicYearId: null,
    searchTerm: "",
    submitter: null
  },
};


const adminDeclarationsFilterReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAdminDeclarationsFilterState => {

  const {
    FILTERBYSUBMITTER,
    FILTERBYSCHOOL,
    FILTERBYSEARCHTERM,
    FILTERBYACADEMICYEAR
  } = declarationsOfInterestActions.types;

  switch (action.type) {

    case FILTERBYSUBMITTER:
      return {
        ...state,
        filter: { 
          ...state.filter, 
          submitter: action.payload 
        },
      };

    case FILTERBYSCHOOL:
      return {
        ...state,
        filter: { 
          ...state.filter, 
          schoolId: action.payload 
        },
      };

    case FILTERBYSEARCHTERM:
      return {
        ...state,
        filter: { 
          ...state.filter, 
          searchTerm: action.payload 
        },
      };

    case FILTERBYACADEMICYEAR:
      return {
        ...state,
        filter: { 
          ...state.filter, 
          academicYearId: action.payload 
        },
      };


    default:
      return state;
  }
};

export default adminDeclarationsFilterReducer;
