import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";
import { PageInfo } from "types/common/paging.types";
import syncActions from "areas/administration/actions/syncAction";
import { SyncFailureReportItems } from "areas/administration/types/syncResponses.types";
import defaults from "reducers/defaults";

interface ISyncFailureReportState {
  report: SyncFailureReportItems[] | null;
  paging: PageInfo;
  refresh: boolean;
  loading: boolean;
  loadingExportReport: boolean;
  error: IBeehiveError | null;
}

const INITIAL_STATE: ISyncFailureReportState = {
  report: null,
  paging: defaults.DEFAULT_PAGING,
  refresh: false,
  loading: false,
  loadingExportReport: false,
  error: null,
};

const syncFailureReportReducer = (
  state = INITIAL_STATE,
  action: IBeehiveAction
): ISyncFailureReportState => {
  const { GETSYNCFAILUREREPORT, EXPORTSYNCFAILUREREPORT } = syncActions.types;

  switch (action.type) {
    case GETSYNCFAILUREREPORT.START:
      return { ...state, loading: true, error: null };

    case GETSYNCFAILUREREPORT.SUCCESS:
      return {
        ...state,
        report:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.report, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };

    case GETSYNCFAILUREREPORT.FAILED:
      return { ...state, loading: false, error: action.payload };

    case EXPORTSYNCFAILUREREPORT.START:
      return { ...state, loadingExportReport: true, error: null };

    case EXPORTSYNCFAILUREREPORT.SUCCESS:
      return { ...state, loadingExportReport: false };

    case EXPORTSYNCFAILUREREPORT.FAILED:
      return { ...state, loadingExportReport: false, error: action.payload };

    default:
      return state;
  }
};

export default syncFailureReportReducer;
