import React, { useState, useEffect } from "react";
import {
  StructuredList,
  Checkbox,
  Dropdown,
  Spacing,
  Card,
  Text,
  TextInput,
  TextInputType,
  Message,
} from "ui-kit";
import styled from "styled-components";
import CheckboxSearchCondition from "./checkboxSearchCondition";
import { Constants } from "configuration";
import {
  ReadOnlyOtherConditions,
  ReadOnlyConditions,
} from "./readOnlySmartTask";
import { TIME_PERIOD_KEYS, TIME_PERIOD_NAME } from "../../constants/smartTasks";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { GroupType } from "types/planner/groups.types";
import { smartTaskData } from "./newSmartTaskFormWrapper";
import { EventName } from "areas/behaviour/types/smartTasks/smartTaskShared.types";


const RewardConditionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${Spacing.Medium}px;
  .text {
    white-space: nowrap;
  }
`;

const ConditionWrapper = styled.div`
  display: flex;
  align-items: center;

  .checkbox {
    margin-right: ${Spacing.Medium}px;
  }

  .group-search-wrapper {
    flex-grow: 2;
    margin-left: ${Spacing.Medium}px;
  }

  .dropdown {
    flex-grow: 2;
    margin-left: ${Spacing.Medium}px;
  }
`;


interface ISmartTaskConditionsProps {
  smartTask: smartTaskData;
  setSmartTask: (data: smartTaskData) => void;
  rewardCategories: IDropdownDataItemProps<string, number, null>[];
  calendarEventCategories: IDropdownDataItemProps<string, number, null>[];
  schoolId: number;
  handleTimePeriodChange: (value: number) => void;
  disabled?: boolean;
}


const SmartTaskConditions: React.FC<ISmartTaskConditionsProps> = ({
  smartTask,
  setSmartTask,
  rewardCategories,
  calendarEventCategories,
  schoolId,
  handleTimePeriodChange,
  disabled,
}) => {

  const getConditionSuffix = (smartTaskType: EventName) => {
    switch (smartTaskType) {
      case EventName.RewardPoints:
        return "Reward Points";
      case EventName.BehaviourPoints:
        return "Behaviour Points";
      case EventName.Event:
        return "Hours";
    }
  }

  const getConditionAdjective = (smartTaskType: EventName) => {
    switch (smartTaskType) {
      case EventName.RewardPoints:
        return "Received within";
      case EventName.BehaviourPoints:
        return "Received within";
      case EventName.Event:
        return "Attended within";
    }
  }

  return (
    <>
      {disabled && (
        <Message text="This smart has already been triggered for some students. Therefore, the conditions to trigger the smart task cannot be changed." />
      )}

      <Card title="Trigger Conditions">
        <Card.Body>
          {disabled ? (
            <ReadOnlyConditions smartTask={smartTask} />
          ) : (
            <StructuredList.Item
              required
              name="Condition"
              description="Condition that has to be met to trigger the smart task"
              helpPopup
            >
              <RewardConditionWrapper>
                <Text>Trigger if &nbsp; </Text>

                <TextInput
                  fluid
                  type={TextInputType.Number}
                  min={1}
                  value={smartTask.eventFrequency}
                  onChange={value =>
                    setSmartTask({
                      ...smartTask,
                      eventFrequency: Number(value),
                    })
                  }
                  suffix={getConditionSuffix(smartTask.eventNameId)}
                  disabled={disabled}
                />
              </RewardConditionWrapper>

              <RewardConditionWrapper>
                <Text>{getConditionAdjective(smartTask.eventNameId)} &nbsp;</Text>
                {smartTask.timePeriodNameId !==
                  TIME_PERIOD_KEYS.ACADEMIC_YEAR && (
                  <TextInput
                    fluid
                    type={TextInputType.Number}
                    min={1}
                    value={smartTask.timePeriodFrequency}
                    onChange={value =>
                      setSmartTask({
                        ...smartTask,
                        timePeriodFrequency: Number(value),
                      })
                    }
                    disabled={disabled}
                  />
                )}
                &nbsp;
                <Dropdown
                  fluid
                  placeholder="Time Period"
                  items={TIME_PERIOD_NAME}
                  value={smartTask.timePeriodNameId}
                  onChange={handleTimePeriodChange}
                  disabled={disabled}
                />
              </RewardConditionWrapper>
            </StructuredList.Item>
          )}

          {disabled ? (
            <ReadOnlyOtherConditions smartTask={smartTask} />
          ) : (
            <ExtraConditions 
              smartTask={smartTask} 
              setSmartTask={setSmartTask} 
              rewardCategories={rewardCategories} 
              calendarEventCategories={calendarEventCategories}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};


interface IExtraConditionsProps {
  smartTask: smartTaskData;
  setSmartTask: (smartTask: smartTaskData) => void;
  rewardCategories: IDropdownDataItemProps<string, number, null>[];
  calendarEventCategories: IDropdownDataItemProps<string, number, null>[];
}

const ExtraConditions: React.FC<IExtraConditionsProps> = ({ smartTask, setSmartTask, rewardCategories, calendarEventCategories }) => {

  switch (smartTask.eventNameId) {

    case EventName.RewardPoints:
      return (
        <StructuredList.Item
          name="Other Conditions"
          description="Other optional conditions that have to be met to trigger the smart task."
        >
          <BehaviourConditions 
            smartTask={smartTask}
            setSmartTask={setSmartTask}
            rewardCategories={rewardCategories}
          />
        </StructuredList.Item>
      );

    case EventName.BehaviourPoints:
      return (
        <StructuredList.Item
          name="Other Conditions"
          description="Other optional conditions that have to be met to trigger the smart task."
        >
          <BehaviourConditions 
            smartTask={smartTask}
            setSmartTask={setSmartTask}
            rewardCategories={rewardCategories}
          />
        </StructuredList.Item>
      );

    case EventName.Event:
      return (
        <StructuredList.Item
          name="Other Conditions"
          description="Other mandatory conditions that have to be met to trigger the smart task."
          required
        >
          <CalendarEventConditions 
            smartTask={smartTask}
            setSmartTask={setSmartTask}
            calendarEventCategories={calendarEventCategories}
          />
        </StructuredList.Item>
      );

  }
}


interface IBehaviourConditionsProps {
  smartTask: smartTaskData;
  setSmartTask: (smartTask: smartTaskData) => void;
  rewardCategories: IDropdownDataItemProps<string, number, null>[];
}

const BehaviourConditions: React.FC<IBehaviourConditionsProps> = ({ smartTask, setSmartTask, rewardCategories }) => {

  const [rewardCategoriesChecked, setRewardCategoriesChecked] = useState<boolean>(smartTask.schoolBehaviourCategoryId !== null);

  useEffect(() => {
    if (!rewardCategoriesChecked) {
      setSmartTask({ ...smartTask, schoolBehaviourCategoryId: null });
    }
  }, [rewardCategoriesChecked]);

  return (
    <>
      <ConditionWrapper>
        <Checkbox
          text="Reward Category"
          checked={rewardCategoriesChecked}
          onChange={() =>
            setRewardCategoriesChecked(!rewardCategoriesChecked)
          }
        />

        {rewardCategoriesChecked && (
          <Dropdown
            fluid
            placeholder="Reward Category"
            items={rewardCategories}
            value={smartTask.schoolBehaviourCategoryId}
            onChange={value =>
              setSmartTask({
                ...smartTask,
                schoolBehaviourCategoryId: value,
              })
            }
          />
        )}
      </ConditionWrapper>

      <ConditionWrapper>
        <CheckboxSearchCondition
          id={smartTask.subjectId}
          name={smartTask.subjectName}
          setId={value =>
            setSmartTask({ ...smartTask, subjectId: value })
          }
          checkboxText="Subject"
          searchGroupType={GroupType.Subject}
        />
      </ConditionWrapper>

      <ConditionWrapper>
        <CheckboxSearchCondition
          id={smartTask.classGroupId}
          name={smartTask.classGroupName}
          setId={value =>
            setSmartTask({ ...smartTask, classGroupId: value })
          }
          checkboxText="Class Group"
          searchGroupType={GroupType.ClassGroup}
        />
      </ConditionWrapper>

      <ConditionWrapper>
        <CheckboxSearchCondition
          id={smartTask.yearGroupId}
          name={smartTask.yearGroupName}
          setId={value =>
            setSmartTask({ ...smartTask, yearGroupId: value })
          }
          checkboxText="Year Group"
          searchGroupType={GroupType.YearGroup}
        />
      </ConditionWrapper>
    </>
  )
}


interface ICalendarEventConditionsProps {
  smartTask: smartTaskData;
  setSmartTask: (smartTask: smartTaskData) => void;
  calendarEventCategories: IDropdownDataItemProps<string, number, null>[];
}

const CalendarEventConditions: React.FC<ICalendarEventConditionsProps> = ({ smartTask, setSmartTask, calendarEventCategories }) => {

  const [calendarCategoriesChecked, setCalendarCategoriesChecked] = useState<boolean>(smartTask.calendarCategoryId !== null);

  useEffect(() => {
    if (!calendarCategoriesChecked) {
      setSmartTask({ ...smartTask, calendarCategoryId: null });
    }
  }, [calendarCategoriesChecked]);

  return (
    <>
      <ConditionWrapper>
        <Checkbox
          text="Calendar Event Category"
          checked={calendarCategoriesChecked}
          onChange={() =>
            setCalendarCategoriesChecked(!calendarCategoriesChecked)
          }
        />

        {calendarCategoriesChecked && (
          <Dropdown
            fluid
            placeholder="Calendar Event Category"
            items={calendarEventCategories}
            value={smartTask.calendarCategoryId}
            onChange={value =>
              setSmartTask({
                ...smartTask,
                calendarCategoryId: value,
              })
            }
          />
        )}
      </ConditionWrapper>
    </>
  )
}

export default SmartTaskConditions;