import { IBeehiveError } from "types/common/errors.types";
import managedMovesActions from "../../actions/managedMoves/managedMovesActions";
import { IBeehiveAction } from "types/common/action.types";
import { ManagedMoveListView } from "areas/planner/types/managedMoves/managedMovedResponse.types";


interface IManagedMovesState {
  moves: ManagedMoveListView[];
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE : IManagedMovesState = {
  moves: [],
  loading: false,
  error: null,
};

const managedMovesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IManagedMovesState => {

  const { 
    MANAGEDMOVES_GETALL, 
    MANAGEDMOVES_REFRESH 
  } = managedMovesActions.types;

  switch (action.type) {

    case MANAGEDMOVES_GETALL.START:
      return { 
        ...state, 
        loading: true,
        error: null
      };
    case MANAGEDMOVES_GETALL.SUCCESS:
      return { 
        ...state, 
        loading: false,
        moves: action.payload 
      };
    case MANAGEDMOVES_GETALL.FAILED:
      return { 
        ...state, 
        loading: false, 
        error: action.payload 
      };

    case MANAGEDMOVES_REFRESH:
      return {
        ...state,
        moves: state.moves.map(x =>
          x.id === action.payload.id ? action.payload : x
        ),
      };

    default:
      return state;
  }
};

export default managedMovesReducer;
