import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import store from "reducers/store";
import client from "services/client";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { StaffListView } from "types/users/userListViews.types";

const prefix = "managedstaff";
const builder = new actionTypeBuilder(prefix);

const types = {
  GETMANAGEDSTAFF: builder.build("getmanagedstaff"),
  SETWIDTH: "setwidth",
  SELECTSTAFFMEMBER: "selectstaffmember",
};


const setWidth = (width: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.SETWIDTH,
    payload: width,
  });
};

const getManagedStaff = (lineManagerId: string, callback?: callbackType<StaffListView[]>) =>
  action<StaffListView[]>(
    () => client.get(`pm/staff/${lineManagerId}/managedstaff`),
    types.GETMANAGEDSTAFF,
    callback
  );

const selectStaffMember = (staffMember: string) => {
  store.dispatch<IBeehiveAction<string>>({
    type: types.SELECTSTAFFMEMBER,
    payload: staffMember,
  });
};


const managedStaffActions = {
  types,
  setWidth,
  getManagedStaff,
  selectStaffMember,
};

export default managedStaffActions;
