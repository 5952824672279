import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Spacing, List, neutral } from "ui-kit";
import { arrays } from "utils";
import GroupListItem from "./groupListItem";
import { GroupListView } from "types/users/userGroups.types";
import { GroupType } from "types/planner/groups.types";


const Wrapper = styled.div`
  width: 12rem;
  min-width: 12rem;
  margin-bottom: ${Spacing.ExtraLarge}px;
  // border: 1px solid red;

  &:last-child {
    margin-bottom: ${Spacing.Small}px;
  }

  .list {
    .left {
      flex-grow: 0;
    }

    &.selected {
      .right {
        .private-icon {
          color: ${neutral[100]};
        }
      }
    }
  }
`;


interface IGroupListProps {
  className?: string;
  groups: GroupListView[];
  title: string;
  selectedGroupId: number;
  handleGroupClick?: (groupId: number, groupType: GroupType) => void;
  onAddGroupClick?: () => void;
  addGroupText?: string;
  showAddGroup?: boolean;
  isExpanded?: boolean;
  onClickArrow?: () => void;
  showNumberOfGroups?: boolean;
  style?: React.CSSProperties;
}


const GroupList: React.FC<IGroupListProps> = ({
  className,
  groups,
  title,
  selectedGroupId,
  handleGroupClick,
  onAddGroupClick,
  addGroupText,
  showAddGroup,
  isExpanded,
  onClickArrow,
  showNumberOfGroups,
  style,
}) => {

  const handleAddGroupClick = () => {
    onAddGroupClick?.();
  };

  const handleGroupClicked = (group: GroupListView) => {
    handleGroupClick?.(group.id, group.type);
  };

  return !arrays.isEmpty(groups) || showAddGroup ? (
    <Wrapper
      style={style}
      className={`${className ? className : ""} group-list`}
    >
      <List
        title={`${title}${showNumberOfGroups ? ` (${groups.length})` : ""}`}
        isExpanded={isExpanded}
        onClickAccordionArrow={onClickArrow}
      >
        {groups.map((group, index) => (
          <GroupListItem
            key={index}
            group={group}
            selectedGroupId={selectedGroupId}
            handleGroupClicked={handleGroupClicked}
          />
        ))}
        {showAddGroup && (
          <List.Item
            icon="plus"
            text={addGroupText}
            transparent
            onClick={handleAddGroupClick}
          />
        )}
      </List>
    </Wrapper>
  ) : null;
};


export default GroupList;