import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Title,
  TitleSize,
  Card,
  Table,
  Button,
  Size,
  DetailLabel,
  Swatches,
  Chip,
  SplitButton,
  ToastService,
  DateTime,
  EmptyMessage,
  Message,
} from "ui-kit";
import { arrays, files } from "utils";
import DocumentGroupFlyout from "./documentGroupFlyout";
import { Constants } from "configuration";
import { useNavigate } from "react-router";
import flyoutActions from "actions/ui/flyouts";
import Subheader from "sharedComponents/layout/header/subheader";
import { documentRoutes } from "areas/administration/adminRoutes";
import documentGroupActions from "areas/administration/actions/documentGroupActions";
import { RootState } from "reducers/store";
import { DocumentGroupDetailView } from "areas/administration/types/documentResponse.types";
import { SaveDocumentGroupCommand } from "areas/administration/types/documentRequest.types";
import { ApiExceptionMessage } from "sharedComponents/common";


const DocumentsAdmin = () => {

  const navigate = useNavigate();
  const { groups, loading, error, deleting, deleteError, runningReport, reportError } = useSelector((state: RootState) => state.documentGroups);
  const [selectedGroup, setSelectedGroup] = useState<DocumentGroupDetailView | null>(null);


  useEffect(() => {
    documentGroupActions.getDocumentGroups();
  }, []);

  const handleNewDocumentGroup = () => {
    setSelectedGroup(null);
    flyoutActions.openFlyout(Constants.FLYOUTS.DOCUMENTGROUP);
  };

  const handleEditDocumentGroup = (group: DocumentGroupDetailView) => {
    setSelectedGroup(group);
    flyoutActions.openFlyout(Constants.FLYOUTS.DOCUMENTGROUP);
  };

  const handleUploadToDocumentGroup = (group: DocumentGroupDetailView) => {
    navigate(documentRoutes.getUploadPath(group.id));
  };

  const handleViewDocuments = (group: DocumentGroupDetailView) => {
    navigate(documentRoutes.getViewerPath(group.id));
  };

  const handleReadDateReport = (group: DocumentGroupDetailView)  => {
    documentGroupActions.runReadRecieptReport(group.id, data => {
      files.openCsv(data, `${group.name}ReadReciepts`);
    });
  }

  const handleFlyoutCancel = () => {
    setSelectedGroup(null);
  };

  const handleDocumentGroupSaved = (data: SaveDocumentGroupCommand) => {
    documentGroupActions.getDocumentGroups();
  };

  const handleDelete = (group: DocumentGroupDetailView) => {
    if (
      window.confirm(
        `This will delete the '${group.name}' group and all documents in it. This cannot be undone. Are you sure?`
      )
    ) {
      documentGroupActions.deleteDocumentGroup(group.id, () => {
        documentGroupActions.getDocumentGroups();
        ToastService.pop("Document Group Deleted", null, "file-certificate");
      });
    }
  };

  return (
    <>
      <Subheader>
        <Title size={TitleSize.H2} text="Documents" sub="Administration" />
      </Subheader>
      <Message text={deleteError} color={Swatches.Danger} />
      <ApiExceptionMessage error={reportError} />
      <Card title="Document Groups" grow>
        <Card.Body noPad>
          <Table
            zebra
            loading={loading}
            error={error != null}
            empty={arrays.isEmpty(groups)}
            emptyMessage={
              <EmptyMessage
                icon="file-certificate"
                title="No Document Groups"
                summary="No Document Groups have been created yet."
                cover
              >
                <Button
                  size={Size.Small}
                  color={Swatches.Primary}
                  text="Add New Document Group"
                  onClick={handleNewDocumentGroup}
                />
              </EmptyMessage>
            }
            grow
          >
            <Table.Header>
              <Table.HeaderCell width={1} />
              <Table.HeaderCell width={5}>Name</Table.HeaderCell>
              <Table.HeaderCell width={1.5}>Date Created</Table.HeaderCell>
              <Table.HeaderCell width={0.5} right>
                Documents
              </Table.HeaderCell>
              <Table.HeaderCell width={2}></Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {groups.map((group: DocumentGroupDetailView, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell width={1}>
                    {group.isActive ? (
                      <Chip text="Active" colorSwatch={Swatches.Success} />
                    ) : (
                      <Chip text="Inactive" colorSwatch={Swatches.Low} />
                    )}
                  </Table.Cell>
                  <Table.Cell width={5}>
                    <DetailLabel
                      bold
                      label={group.name}
                      sub={group.documentTypeName}
                    />
                  </Table.Cell>
                  <Table.Cell width={1.5}>
                    <DateTime bold date={group.createdDate} />
                  </Table.Cell>
                  <Table.Cell width={0.5} right>
                    {group.documentCount}
                  </Table.Cell>
                  <Table.Cell width={2} right>
                    <SplitButton
                      size={Size.Small}
                      text="Actions"
                      color={Swatches.Default}
                      working={deleting || runningReport}
                    >
                      <SplitButton.Option
                        onClick={() => handleEditDocumentGroup(group)}
                        text="Edit"
                        show
                      />
                      <SplitButton.Option
                        onClick={() => handleUploadToDocumentGroup(group)}
                        text="Upload Documents"
                        show
                      />
                      <SplitButton.Option
                        onClick={() => handleViewDocuments(group)}
                        text="View Documents"
                        show
                      />
                      <SplitButton.Option
                        onClick={() => handleReadDateReport(group)}
                        text="Run Read Date Report"
                        show={group.recordReadReciepts}
                      />
                      <SplitButton.Option
                        onClick={() => handleDelete(group)}
                        text="Delete"
                        show
                      />
                    </SplitButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.Cell colspan={4} right>
                  <Button
                    size={Size.Small}
                    color={Swatches.Primary}
                    text="Add New Document Group"
                    onClick={handleNewDocumentGroup}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Card.Body>
      </Card>
      <DocumentGroupFlyout
        onCancel={handleFlyoutCancel}
        onSaved={handleDocumentGroupSaved}
        group={selectedGroup}
      />
    </>
  );
};

export default DocumentsAdmin;
