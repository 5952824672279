
export enum EventName {
  BehaviourPoints = 0,
  RewardPoints = 1,
  Event = 2
}

export enum TimePeriodName {
  CalendarDays = 0,
  CalendarWeeks = 1,
  CalendarMonths = 2,
  SchoolYear = 3
}