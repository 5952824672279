import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import {
  Swatches,
  Icon,
  Size,
  Spacing,
  BorderRadius,
  fontStyle,
  displayFont,
  typescale,
} from "ui-kit";

const DEFAULT_WIDTH = 1100;
const DEFAULT_HEIGHT = 0.56239015817 * DEFAULT_WIDTH;
//const DEFAULT_HEIGHT= 640;
const DEFAULT_FOCUS_WIDTH = 480;
const DEFAULT_MARGIN_WIDTH = (DEFAULT_WIDTH - DEFAULT_FOCUS_WIDTH!) / 2;

const CameraWrapper = styled.div<{
  wrapperWidth: number;
  wrapperHeight: number;
}>`
  width: ${props =>
    props.wrapperWidth ? `${props.wrapperWidth}px` : `${DEFAULT_WIDTH}px`};
  height: ${props =>
    props.wrapperHeight ? `${props.wrapperHeight}px` : `${DEFAULT_HEIGHT}px`};
  display: flex;
  position: relative;
  align-content: flex-start;
  margin: 0 auto;
`;

const ControlWrapper = styled.div`
  z-index: 200;
  position: absolute;
  box-sizing: border-box;
  cursor: pointer;
  background: ${Swatches.Low.swatch};
  border-radius: ${BorderRadius.Default}px;
  top: ${Spacing.Small}px;
  left: ${Spacing.Small}px;
  display: flex;
  align-items: center;

  .icon {
    padding: ${Spacing.Medium}px;
  }
`;

const CameraMargin = styled.div<{
  camMarginWidth: number;
  camMarginHeight: number;
}>`
  z-index: 100;
  position: absolute;
  top: 0;
  bottom: 0;
  width: ${props =>
    props.camMarginWidth
      ? `${props.camMarginWidth}px`
      : `${DEFAULT_MARGIN_WIDTH}px`};
  height: ${props =>
    props.camMarginHeight ? `${props.camMarginHeight}px` : DEFAULT_HEIGHT};
  background: rgba(0, 0, 0, 0.8);

  &:first-child {
    left: 0;
  }

  &:last-child {
    right: 0;
  }
`;

const CameraFocus = styled.div<{
  focusWidth: number;
  focusHeight: number;
  camMarginWidth: number;
}>`
  position: absolute;
  width: ${props =>
    props.focusWidth ? `${props.focusWidth}px` : DEFAULT_FOCUS_WIDTH};
  height: ${props =>
    props.focusHeight ? `${props.focusHeight}px` : DEFAULT_HEIGHT};
  color: ${Swatches.Primary.swatch};
  left: ${props =>
    props.camMarginWidth
      ? `${props.camMarginWidth}px`
      : `${DEFAULT_MARGIN_WIDTH}px`};
  right: ${props =>
    props.camMarginWidth
      ? `${props.camMarginWidth}px`
      : `${DEFAULT_MARGIN_WIDTH}px`};
`;

const handleGuidePostition = (position: string) => {
  switch (position) {
    case "eyes":
      return css`
        top: 42%;
      `;
    case "top":
      return css`
        top: 10%;
      `;
    case "bottom":
      return css`
        bottom: 10%;
      `;
    default:
      return css``;
  }
};

const Guide = styled.span<{ position: string }>`
  position: absolute;
  width: 95%;
  border-bottom: 2px dotted #900;
  padding-bottom: ${Spacing.Default}px;
  text-align: left;
  align-content: left;
  left: 2.5%;
  right: 2.5%;
  ${props => props.position && handleGuidePostition(props.position)}
`;

const GuideCenter = styled.span<{ split: number }>`
  position: absolute;
  left: ${props =>
    props.split ? `${props.split}px` : `${DEFAULT_FOCUS_WIDTH / 2}px`};
  border: 1px dotted #900;
  height: 90%;
  top: 5%;
`;

const GuideLabel = styled.span`
  display: inline-block;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: ${Spacing.ExtraSmall}px;
  z-index: 999;
  ${fontStyle(displayFont.medium, typescale.paragraph, "white")}
`;

const CameraPreview = styled.canvas`
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  border: 1px solid #ccc;
`;

const Canvas = styled.canvas`
  display: none;
`;

interface IPhotoboothProps {
  height?: number | null;
  width?: number | null;
  onCapture?: (base64Value: string) => void;
  autoPlay?: boolean | null;
  showPreview?: boolean | null;
  showControls?: boolean | null;
  showMargins?: boolean | null;
  showGuides?: boolean | null;
  focusZoneWidth?: number | null;
}

//0.56239015817 * preferred width
const defaultProps: IPhotoboothProps = {
  height: DEFAULT_HEIGHT,
  width: DEFAULT_WIDTH,
  autoPlay: false,
  showPreview: false,
  showControls: false,
  showMargins: false,
  showGuides: false,
  focusZoneWidth: DEFAULT_FOCUS_WIDTH,
};

const Photobooth: React.FC<IPhotoboothProps> = ({
  height,
  width,
  onCapture,
  autoPlay,
  showPreview,
  showControls,
  showMargins,
  showGuides,
  focusZoneWidth,
}) => {
  const [playing, setPlaying] = useState<boolean>(false);
  const cameraRef = useRef<HTMLVideoElement>(null);
  const previewRef = useRef<HTMLCanvasElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const cameraMarginWidth = (width! - focusZoneWidth!) / 2;
  const previewWidth = focusZoneWidth! / 1.75;
  const previewHeight = height! / 1.75;

  const navigator: any = window.navigator;
  navigator.getUserMedia =
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia;

  useEffect(() => {
    autoPlay && startVideo();
    // eslint-disable-next-line
  }, [autoPlay]);

  const startVideo = () => {
    setPlaying(true);
    var constraints = {
      audio: false,
      video: { width: width, height: height },
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((mediaStream: any) => {
        var video = cameraRef.current;
        if (video) {
          video.srcObject = mediaStream;
          video!.onloadedmetadata = () => video!.play();
        }
      })
      .catch((err: string) => console.log(err));
  };

  const stopVideo = () => {
    setPlaying(false);
    if (cameraRef.current) {
      (cameraRef.current.srcObject as MediaStream)
        .getTracks()
        .forEach(track => track.stop());
    }
  };

  const takePhoto = () => {
    if (!cameraRef.current) {
      return;
    }
    var leftMargin = (cameraRef.current.videoWidth! - focusZoneWidth!) / 2;
    if (showPreview && previewRef.current) {
      var preview = previewRef.current;
      preview
        .getContext("2d")!
        .drawImage(
          cameraRef.current,
          leftMargin,
          0,
          focusZoneWidth!,
          height!,
          0,
          0,
          previewWidth!,
          previewHeight!
        );
    }
    var hiddenCanvas = canvasRef.current;
    hiddenCanvas!
      .getContext("2d")!
      .drawImage(
        cameraRef.current,
        leftMargin,
        0,
        focusZoneWidth!,
        height!,
        0,
        0,
        focusZoneWidth!,
        height!
      );
    onCapture?.(hiddenCanvas!.toDataURL("image/jpeg"));
  };

  return (
    <>
      <CameraWrapper wrapperWidth={width!} wrapperHeight={height!}>
        <ControlWrapper>
          <Icon
            value="camera"
            color={Swatches.Low.foreground}
            size={Size.ExtraLarge}
            onClick={takePhoto}
          />
          {showControls &&
            (playing ? (
              <Icon
                value="ban"
                color={Swatches.Low.foreground}
                size={Size.ExtraLarge}
                onClick={stopVideo}
              />
            ) : (
              <Icon
                value="play"
                color={Swatches.Low.foreground}
                size={Size.ExtraLarge}
                onClick={startVideo}
              />
            ))}
        </ControlWrapper>

        <video ref={cameraRef} height={height!} width={"100%"} muted autoPlay />

        {showMargins && (
          <CameraMargin
            camMarginWidth={cameraMarginWidth}
            camMarginHeight={height!}
          />
        )}
        {showGuides && (
          <CameraFocus
            focusWidth={focusZoneWidth!}
            focusHeight={height!}
            camMarginWidth={cameraMarginWidth}
          >
            <Guide position={"top"}>
              <GuideLabel>Top of Head</GuideLabel>
            </Guide>
            <Guide position={"eyes"}>
              <GuideLabel>Eye Level</GuideLabel>
            </Guide>
            <Guide position={"bottom"}>
              <GuideLabel>Bottom of Chin</GuideLabel>
            </Guide>
            <GuideCenter split={focusZoneWidth! / 2} />
          </CameraFocus>
        )}
        {showPreview && (
          <CameraPreview
            ref={previewRef}
            width={previewWidth}
            height={previewHeight}
          />
        )}
        {showMargins && (
          <CameraMargin
            camMarginWidth={cameraMarginWidth}
            camMarginHeight={height!}
          />
        )}
      </CameraWrapper>

      <Canvas ref={canvasRef} width={focusZoneWidth!} height={height!} />
    </>
  );
};

Photobooth.defaultProps = defaultProps;
export default Photobooth;
