import paymentActions from "areas/payments/actions/paymentsActions";
import { IncomingReportListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface IIncomingReportsState {
  loading: boolean;
  error: string | null;
  reports: IncomingReportListView[],
  processing: boolean;
  processError: string | null;
}

const INITIAL_STATE: IIncomingReportsState = {
  loading: false,
  error: null,
  reports: [],
  processing: false,
  processError: null,
};

const incomingReportsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IIncomingReportsState => {

  const {
    PAYMENTS_GETINCOMINGREPORTS,
    PAYMENTS_PROCESSINCOMINGREPORT,
    PAYMENTS_RUNINCOMINGREPORT,
    PAYMENTS_GETINCOMINGREPORT,
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETINCOMINGREPORTS.START:
      return { 
        ...state, 
        loading: true, 
        error: null,
        processError: null 
      };

    case PAYMENTS_GETINCOMINGREPORTS.SUCCESS:
      return { 
        ...state, 
        reports: action.payload, 
        loading: false 
      };

    case PAYMENTS_GETINCOMINGREPORTS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PAYMENTS_PROCESSINCOMINGREPORT.START:
    case PAYMENTS_GETINCOMINGREPORT.START:
      return { 
        ...state, 
        processing: true, 
        error: null, 
        processError: null 
      };

    case PAYMENTS_PROCESSINCOMINGREPORT.SUCCESS:
      return {
        ...state,
        processing: false,
        reports: state.reports.map((x) => x.id === action.payload.id ? action.payload : x),
      };

    case PAYMENTS_PROCESSINCOMINGREPORT.FAILED:
      return {
        ...state,
        processing: false,
        processError: "There was a problem processing the report.",
      };

    case PAYMENTS_RUNINCOMINGREPORT.SUCCESS:
      return {
        ...state,
        reports: [action.payload, ...state.reports],
      };

    case PAYMENTS_GETINCOMINGREPORT.SUCCESS:
      return {
        ...state,
        processing: false,
        reports: state.reports.map((x) => x.id === action.payload.id ? action.payload : x),
      };

    case PAYMENTS_GETINCOMINGREPORT.FAILED:
      return {
        ...state,
        processing: false,
        processError: "There was a problem refreshing the report.",
      };

    default:
      return state;
  }
};

export default incomingReportsReducer;
