import favouritesReducer from "./favouritesReducer";
import feedGalleryReducer from "./feedGalleryReducer";
import feedReducer from "./feedReducer";
import feedbackMessageReducer from "./feedbackMessageReducer";
import feedbackMessagesReducer from "./feedbackMessagesReducer";

const feed = {
  favourites: favouritesReducer,
  feedGallery: feedGalleryReducer,
  feed: feedReducer,
  feedbackMessage: feedbackMessageReducer,
  feedbackMessages: feedbackMessagesReducer,
};

export default feed;
