import { IBeehiveAction } from "types/common/action.types";
import submissionFilterActions from "../actions/submissionFilterActions";
import { FormCategoryListView, FormListView } from "../types/formResponse.types";
import { StaffListView } from "types/users/userListViews.types";


export interface ISubmissionFilter {
  category: FormCategoryListView;
  formType: FormListView;
  formStatus: number;
  userId: string;
  schoolId: number;
  searchTerm: string;
  authoriser: StaffListView;
  submitter: StaffListView;
}

interface ISubmissionFilterState {
  loading: false;
  error: string | null;
  filter: ISubmissionFilter;
}

const INITIAL_STATE: ISubmissionFilterState = {
  loading: false,
  error: null,
  filter: {
    category: null,
    formType: null,
    formStatus: -1,
    userId: null,
    schoolId: -1,
    searchTerm: "",
    authoriser: null,
    submitter: null
  },
};


const submissionFilterReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISubmissionFilterState => {

  const {
    FILTERBYCATEGORY,
    FILTERBYFORMTYPE,
    FILTERBYFORMSTATUS,
    FILTERBYFORMUSER,
    FILTERBYFORMAUTHORISER,
    FILTERBYFORMSUBMITTER,
    FILTERBYSCHOOL,
    FILTERBYSEARCHTERM,
  } = submissionFilterActions.types;

  switch (action.type) {
    case FILTERBYCATEGORY:
      return {
        ...state,
        filter: { ...state.filter, category: action.payload, formType: null },
      };

    case FILTERBYFORMTYPE:
      return {
        ...state,
        filter: { ...state.filter, formType: action.payload },
      };

    case FILTERBYFORMSTATUS:
      return {
        ...state,
        filter: { ...state.filter, formStatus: action.payload },
      };

    case FILTERBYFORMUSER:
      return {
        ...state,
        filter: { ...state.filter, userId: action.payload },
      };

    case FILTERBYFORMAUTHORISER:
      return {
        ...state,
        filter: { ...state.filter, authoriser: action.payload },
      };

    case FILTERBYFORMSUBMITTER:
      return {
        ...state,
        filter: { ...state.filter, submitter: action.payload },
      };

    case FILTERBYSCHOOL:
      return {
        ...state,
        filter: { ...state.filter, schoolId: action.payload },
      };

    case FILTERBYSEARCHTERM:
      return {
        ...state,
        filter: { ...state.filter, searchTerm: action.payload },
      };

    default:
      return state;
  }
};

export default submissionFilterReducer;
