import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Size,
  fontStyle,
  displayFont,
  typescale,
  neutral,
  Spacing,
  BorderRadius,
} from "ui-kit";
import StudentSensitiveData from "areas/planner/components/studentRecord/studentSensitiveData/studentSensitiveData";
import { LAYOUT_DIRECTION } from "areas/planner/constants/classViewer/classViewerLayout";
import Avatar from "sharedComponents/common/users/avatar";
import { checkIsDropzone } from "areas/planner/utils/layouts";

const Wrapper = styled.div`
  .layout-grid {
    align-self: center;

    .row {
      display: flex;
      flex-direction: row;
      align-self: center;

      .col {
        width: 5rem;
        height: 2.5rem;
        border-radius: ${BorderRadius.Default}px;
        margin: ${Spacing.Small - 2}px;
        border: 1px solid ${neutral[100]};

        &.dropzone {
          height: 6.4rem;
          border: 1px solid ${neutral[500]};
        }

        .student {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          //   background-color: ${neutral[400]};
          padding: ${Spacing.Small}px 0;
          width: 100%;
          height: 100%;
          justify-content: space-between;

          .avatar {
            .inner .label .user-name {
              ${fontStyle(displayFont.medium, typescale.sub)}
            }

            .profile-image {
              height: 54px;
              width: 54px;
            }
          }

          &:has(.avatar):has(.profile-image):has(.icon) {
            height: 80%;
            justify-content: space-between;

            .profile-image {
              display: none;
            }

            .inner .detail-label .label .user-name {
              font-size: 0.875rem !important;
            }
          }
        }
      }
    }
  }

  .class-direction-label {
    align-self: center;
    ${fontStyle(displayFont.medium, typescale.paragraph, neutral[600])}
    text-align: center;
    &.front {
      margin-top: ${Spacing.Large}px;
    }
    &.back {
      margin-bottom: ${Spacing.Large}px;
    }
  }

  ${({ hideImage }) =>
    hideImage === true &&
    `
  .layout-grid .row .col.dropzone {
    height: 5rem;

    .student {
      height: 80%;
      justify-content: space-between;

      .avatar {
          .inner .detail-label .label .user-name {
            font-size: 0.875rem !important;
          }
        }
      }
  }
  `}
`;

const ClassLayout = ({
  room,
  layoutDirection,
  classLayout,
  noProfileImage,
  noSensitiveInfo,
}) => {
  const [numberOfRows, setNumberOfRows] = useState([]);
  const [numberOfCells, setNumberOfCells] = useState([]);

  useEffect(() => {
    if (room) {
      const roomLength = room.length;
      const roomWidth = room.width;

      const rows = [];
      if (layoutDirection == LAYOUT_DIRECTION.BACKTOFRONT) {
        for (let i = roomLength; i > 0; i--) {
          rows.push(i);
        }
      } else {
        for (let i = 1; i <= roomLength; i++) {
          rows.push(i);
        }
      }
      setNumberOfRows(rows);

      const cells = [];
      if (layoutDirection == LAYOUT_DIRECTION.BACKTOFRONT) {
        for (let i = 1; i <= roomWidth; i++) {
          cells.push(i);
        }
      } else {
        for (let i = roomWidth; i > 0; i--) {
          cells.push(i);
        }
      }
      setNumberOfCells(cells);
    }
  }, [room]);

  const displayStudentOnGrid = (row, col) => {
    const studentSlots = classLayout.studentSlots;
    for (let i = 0; i < studentSlots.length; i++) {
      let studentSlot = studentSlots[i];
      if (
        studentSlot.student != null &&
        row === studentSlot.coordinates.y &&
        col === studentSlot.coordinates.x
      ) {
        return (
          <div className="student" hideImage={noProfileImage}>
            <Avatar
              user={studentSlot.student}
              size={Size.Medium}
              portrait
              hidePhoto={noProfileImage}
            />
            {noSensitiveInfo === false && (
              <StudentSensitiveData
                student={studentSlot.student}
                compact
                fluid
              />
            )}
          </div>
        );
      }
    }
  };

  return (
    <Wrapper hideImage={noProfileImage}>
      <div className="class-direction-label back">
        {layoutDirection == LAYOUT_DIRECTION.BACKTOFRONT
          ? "BACK OF THE CLASS"
          : "FRONT OF THE CLASS"}
      </div>

      <div className="layout-grid">
        {numberOfRows.map(row => (
          <div key={row} className="row">
            {numberOfCells.map(col => (
              <div
                className={`col ${
                  checkIsDropzone(row, col, classLayout.studentSlots)
                    ? "dropzone"
                    : ""
                }`}
              >
                {displayStudentOnGrid(row, col)}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="class-direction-label front">
        {layoutDirection == LAYOUT_DIRECTION.BACKTOFRONT
          ? "FRONT OF THE CLASS"
          : "BACK OF THE CLASS"}
      </div>
    </Wrapper>
  );
};

export default ClassLayout;
