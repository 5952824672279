import { IBeehiveAction } from "types/common/action.types";
import rolesActions from "../../actions/rolesActions";
import { RoleListView } from "types/users/userRoles.types";


interface IRolesState {
  roles: RoleListView[],
  error: string | null;
  loading: boolean;
}

const INITIAL_STATE: IRolesState = {
  roles: [],
  error: null,
  loading: false,
};


const rolesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IRolesState => {

  const { ROLES_GETALL } = rolesActions.types;

  switch (action.type) {
    case ROLES_GETALL.START:
      return { ...state, loading: true, error: null };

    case ROLES_GETALL.SUCCESS:
      return { ...state, roles: action.payload, loading: false };

    case ROLES_GETALL.FAILED:
      return {
        ...state,
        loading: false,
        error: "There was a problem loading the roles.",
      };

    default:
      return state;
  }
};

export default rolesReducer;
