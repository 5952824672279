import React from "react";
import { Tabs } from "ui-kit";
import UserEvents from "./userEvents";
import ManagedEvents from "./managedEvents";
import AttendedEvents from "./attendedEvents";
import { users } from "utils";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import Reporting from "./reporting";
import WallPlanner from "../wallPlanner/wallPlanner";
import { ParentView, StaffView, StudentView } from "sharedComponents/common";
import { RootState } from "reducers/store";
import StudentEvents from "./studentEvents";


const Calendar = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);

  return (
    <Tabs>
      <Tabs.Pane
        label="Event Calendar"
        tooltip="Forthcoming Events"
        tooltipSub="View forthcoming events in the calendar"
      >
        <UserEvents />
      </Tabs.Pane>

      <Tabs.Pane
        label="Student Events"
        tooltip="Student Events"
        tooltipSub="View events for your children"
        hide={!users.isParent(user)}
      >
        <StudentEvents />
      </Tabs.Pane>

      <Tabs.Pane
        label="Your Events"
        tooltip="Your Events"
        tooltipSub="View events you have signed up for"
      >
        <StaffView>
          <AttendedEvents />
        </StaffView>
        <StudentView>
          <AttendedEvents />
        </StudentView>
        <ParentView>
          <AttendedEvents />
        </ParentView>
      </Tabs.Pane>

      <Tabs.Pane
        hide={!users.isStaff(user)}
        label="Event Management"
        tooltip="Event Management"
        tooltipSub="View and manage events you have created"
      >
        <ManagedEvents />
      </Tabs.Pane>

      <Tabs.Pane
        hide={!users.isStaff(user)}
        label="Reporting"
        tooltip="Reporting"
        tooltipSub="Run Reports on Event Data"
      >
        <Reporting />
      </Tabs.Pane>
      <Tabs.Pane
        hide={
          !users.isStaff(user) ||
          (!users.isInRole(user, Constants.ROLES.WALLPLANNER_USER) && !users.isInRole(user, Constants.ROLES.WALLPLANNER_ADMINISTRATOR))
        }
        label="Wall Planner"
        tooltip="Wall Planner"
        tooltipSub="Input events for wall planners"
      >
        <WallPlanner />
      </Tabs.Pane>
    </Tabs>
  );
};

export default Calendar;
