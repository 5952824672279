import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { fontStyle, displayFont, typescale, neutral, Spacing } from "ui-kit";
import WhiteboardClassLayoutStudent from "./whiteboardClassLayoutStudent";
import { LAYOUT_DIRECTION } from "areas/planner/constants/classViewer/classViewerLayout";
import { checkIsDropzone } from "areas/planner/utils/layouts";
import { ClassLayoutStudentView, ClassLayoutView, RoomLayoutView } from "areas/planner/types/classLayoutResponse.types";
import { UserTinyView } from "types/users/userListViews.types";


const Wrapper = styled.div`
  align-self: center;
  padding-bottom: ${Spacing.Medium}px;
  flex-grow: 1;

  .layout-grid {
    align-self: center;

    .row {
      display: flex;
      flex-direction: row;
      align-self: center;

      .col {
        width: 120px;
        min-height: 40px;
        margin: ${Spacing.Small - 2}px;
        display: flex;
        // &.dropzone {
        //   height: 6.4rem;
        // }
      }
    }
  }

  .class-direction-label {
    align-self: center;
    ${fontStyle(displayFont.medium, typescale.paragraph, neutral[600])}
    text-align: center;
    &.front {
      margin-top: ${Spacing.Large}px;
    }
    &.back {
      margin-bottom: ${Spacing.Large}px;
    }
  }
`;


interface IWhiteboardClassLayoutGridProps {
  room: RoomLayoutView;
  layoutDirection: number;
  classLayout: ClassLayoutView;
  handleOpenBehaviourModal: (students: ClassLayoutStudentView[]) => void;
  handleSelectStudent: (student: ClassLayoutStudentView) => void;
  selectedStudents: UserTinyView[];
}


const WhiteboardClassLayoutGrid: React.FC<IWhiteboardClassLayoutGridProps> = ({
  room,
  layoutDirection,
  classLayout,
  handleOpenBehaviourModal,
  handleSelectStudent,
  selectedStudents,
}) => {
  
  const [numberOfRows, setNumberOfRows] = useState<number[]>([]);
  const [numberOfCells, setNumberOfCells] = useState<number[]>([]);

  useEffect(() => {
    if (room) {
      const roomLength = room.length;
      const roomWidth = room.width;

      const rows: number[] = [];
      if (layoutDirection == LAYOUT_DIRECTION.BACKTOFRONT) {
        for (let i = roomLength; i > 0; i--) {
          rows.push(i);
        }
      } else {
        for (let i = 1; i <= roomLength; i++) {
          rows.push(i);
        }
      }
      setNumberOfRows(rows);

      const cells: number[] = [];
      if (layoutDirection == LAYOUT_DIRECTION.BACKTOFRONT) {
        for (let i = 1; i <= roomWidth; i++) {
          cells.push(i);
        }
      } else {
        for (let i = roomWidth; i > 0; i--) {
          cells.push(i);
        }
      }
      setNumberOfCells(cells);
    }
  }, [room]);

  const displayStudentOnGrid = (row: number, col: number) => {
    const studentSlots = classLayout.studentSlots;
    if (studentSlots) {
      for (let i = 0; i < studentSlots.length; i++) {
        let studentSlot = studentSlots[i];
        if (
          studentSlot.student != null &&
          row === studentSlot.coordinates.y &&
          col === studentSlot.coordinates.x
        ) {
          return (
            <WhiteboardClassLayoutStudent
              student={studentSlot.student}
              handleOpenBehaviourModal={handleOpenBehaviourModal}
              handleSelectStudent={handleSelectStudent}
              selectedStudents={selectedStudents}
            />
          );
        }
      }
    }
  };

  return (
    <Wrapper>
      <div className="layout-grid">
        {numberOfRows.map(row => (
          <div key={row} className="row">
            {numberOfCells.map(col => (
              <div
                key={col}
                className={`col ${
                  checkIsDropzone(row, col, classLayout.studentSlots)
                    ? "dropzone"
                    : ""
                }`}
              >
                {displayStudentOnGrid(row, col)}
              </div>
            ))}
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default WhiteboardClassLayoutGrid;
