import React, { useState, useEffect } from "react";
import {
  Flyout,
  StructuredList,
  TextInput,
  TextInputType,
  Dropdown,
  Loader,
  Size,
  ActionBar,
  Button,
  Card,
  Swatches,
  Message,
  ToastService,
  Checkbox,
} from "ui-kit";
import { arrays } from "utils";
import { Constants } from "configuration";
import { useSelector } from "react-redux";
import documentGroupActions from "areas/administration/actions/documentGroupActions";
import flyoutActions from "actions/ui/flyouts";
import { RootState } from "reducers/store";
import { DocumentGroupDetailView } from "areas/administration/types/documentResponse.types";
import { SaveDocumentGroupCommand } from "areas/administration/types/documentRequest.types";


interface IDocumentGroupFlyoutProps {
  group: DocumentGroupDetailView; 
  onSaved?: (data: SaveDocumentGroupCommand) => void;
  onCancel?: () => void;
}

const DocumentGroupFlyout: React.FC<IDocumentGroupFlyoutProps> = ({ group: initialGroup, onSaved, onCancel }) => {

  const { saving, error, loading } = useSelector((state: RootState) => state.documentGroup);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_group, _setGroup] = useState<DocumentGroupDetailView | null>(null);

  useEffect(() => {
    if (initialGroup?.id) {
      documentGroupActions.getDocumentGroup(initialGroup.id, (data: DocumentGroupDetailView) => //pointless? Takes a detail view and returns the same one. No list views are returned before this.
        _setGroup(data)
      );
    }
    else {
      _setGroup({
        id: null,
        name: "",
        summary: "",
        documentType: null,
        documentTypeName: null,
        createdDate: null,
        isActive: false,
        documentCount: 0,
        createdBy: null,
        updatedDate: null,
        updatedBy: null,
        recordReadReciepts: false
      });
    }
  }, [initialGroup]);

  useEffect(() => {
    error && setValidationErrors([...validationErrors, error.message]);
  }, [error]);

  const handleSave = () => {
    var errors = [];

    if (!_group?.name) {
      errors.push("Please enter a name.");
    }

    if (_group?.documentType == null) {
      errors.push("Please choose a document type.");
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    var data: SaveDocumentGroupCommand = {
      id: _group.id,
      name: _group.name,
      summary: _group.summary,
      documentType: _group.documentType,
      isActive: _group.isActive,
      recordReadReciepts: _group.recordReadReciepts
    };

    if (data.id) {
      documentGroupActions.updateDocumentGroup(data, () => {
        ToastService.pop("Document Group Updated", null, "file-certificate");
        flyoutActions.closeFlyout();
        onSaved?.(data);
      });
    } else {
      documentGroupActions.createDocumentGroup(data, () => {
        ToastService.pop("Document Group Created", null, "file-certificate");
        flyoutActions.closeFlyout();
        onSaved?.(data);
      });
    }
  };

  const handleCancel = () => {
    setValidationErrors([]);
    _setGroup(null);
    flyoutActions.closeFlyout();
    onCancel?.();
  };

  return (
    <Flyout title="Document Group" name={Constants.FLYOUTS.DOCUMENTGROUP} wide>
      <Flyout.Body scrollOnErrors={validationErrors}>
        {!arrays.isEmpty(validationErrors) && (
          <Message
            text={validationErrors.map((err: string, index: number) => (
              <React.Fragment key={index}>
                {err}
                <br />
              </React.Fragment>
            ))}
            color={Swatches.Danger}
          />
        )}
        {error && <Message text={error.message} color={Swatches.Danger} />}

        {loading ? (
          <Loader cover size={Size.Large} />
        ) : (
          <Card>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Document Type" required>
                  <Dropdown
                    placeholder="Please choose a document type..."
                    fluid
                    value={_group?.documentType}
                    onChange={value =>
                      _setGroup({ ..._group, documentType: value })
                    }
                  >
                    <Dropdown.Item label="Examination Timetable" value={0} />
                    <Dropdown.Item
                      label="Trial Examination Timetable"
                      value={1}
                    />
                    <Dropdown.Item label="Examination Results" value={2} />
                    <Dropdown.Item label="Snapshot" value={3} />
                    <Dropdown.Item
                      label="Examination Statement of Entries "
                      value={4}
                    />
                  </Dropdown>
                </StructuredList.Item>
                <StructuredList.Item
                  name="Name"
                  description="Please enter a descriptive name such as '2022 Examinations Timetable'. This name is visible to all users."
                  required
                >
                  <TextInput
                    value={_group?.name}
                    onChange={value => _setGroup({ ..._group, name: value })}
                    maxLength={150}
                    fluid
                    characterCount
                  />
                </StructuredList.Item>
                <StructuredList.Item
                  name="Summary"
                  description="You can optionally add a summary for this document. The summary is visible to all users."
                >
                  <TextInput
                    type={TextInputType.Textarea}
                    value={_group?.summary}
                    onChange={value => _setGroup({ ..._group, summary: value })}
                    maxLength={500}
                    fluid
                    characterCount
                  />
                </StructuredList.Item>
                <StructuredList.Item
                  name="Active"
                  description="Whether this documents in this can be downloaded by end users."
                  required
                >
                  <Checkbox
                    text="Is Active for Downloading"
                    checked={_group?.isActive}
                    onChange={value =>
                      _setGroup({ ..._group, isActive: value.checked })
                    }
                  />
                </StructuredList.Item>
                <StructuredList.Item
                  name="Record Read Reciepts"
                  description="Record the first time a user downloads a document in this group"
                  required
                >
                  <Checkbox
                    text="Record Read Reciepts"
                    checked={_group?.recordReadReciepts}
                    onChange={value => _setGroup({ ..._group, recordReadReciepts: value.checked })
                    }
                  />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
        )}
      </Flyout.Body>
      <Flyout.Footer>
        <ActionBar low>
          <Button
            text="Save"
            onClick={handleSave}
            color={Swatches.Success}
            fluid
            working={saving}
            disabled={loading}
          />
          <Button
            text="Cancel"
            onClick={handleCancel}
            color={Swatches.Low}
            fluid
            working={saving}
          />
        </ActionBar>
      </Flyout.Footer>
    </Flyout>
  );
};

export default DocumentGroupFlyout;
