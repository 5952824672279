
import { toast } from "react-toastify";
import { ToastItem } from "../index";

const toastService = {
  pop: (label: string, sub: string | undefined, icon: string) => {
    toast(<ToastItem label={label} sub={sub} icon={icon} />, {
      hideProgressBar: true
    });
  }
};

export default toastService;
