import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import { EmptyMessage, Size, Loader, Spacing, neutral } from "ui-kit";
import AssignmentList from "./assignmentList";
import useInfiniteScroll from "react-infinite-scroll-hook";
import AssignmentsFilter from "./assignmentFilter";
import AssignmentWrapper from "./assignmentWrapper";
import assignmentsActions from "areas/planner/actions/assignments/assignmentsActions";
import assignmentActions from "areas/planner/actions/assignments/assignmentActions";
import { useAppSelector } from "reducers/hooks";
import { callbackType } from "types/common/action.types";
import { PagedQueryView } from "types/common/views.types";
import { AssignmentTinyView } from "areas/planner/types/assignments/assignmentResponse.types";
import { UserStartupView } from "types/users/userStartup.types";
import { StudentStartupView } from "types/users/userListViews.types";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";

const listWidth = 400;

const OuterWrapper = styled.div<{ offset: number }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  right: ${Spacing.Medium}px;
  bottom: ${Spacing.Medium}px;
  ${({ offset }) => (offset ? `top: ${offset}px;` : `top: 0;`)}
  left: 0;
  padding: ${Spacing.Medium}px;
  overflow-y: hidden;
  flex-direction: column;
  flex-grow: 1;
  .left-panel {
    width: ${listWidth}px;
    z-index: 2;
  }

  .left-panel,
  .right-panel {
    position: absolute;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
      width: ${Spacing.Large}px;
    }

    ::-webkit-scrollbar-track {
      background: ${neutral[200]};
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: ${neutral[500]};
      border: 5px solid ${neutral[200]};
      min-height: 50px;
    }
    ::-webkit-scrollbar-thumb:window-inactive {
      background: ${neutral[400]};
    }
  }
`;
const InnerWrapper = styled.div`
  flex-grow: 1;
  display: flex;

  .right-panel {
    display: flex;
    padding-left: ${Spacing.Large}px;
    flex-grow: 1;
    right: 0;
    left: ${listWidth}px;
  }
`;


interface IAssignmentsProps {
  user: { id: string, userType: string };
  offset: number;
  hideNewAssignmentButton?: boolean;
  userType?: string;
}


const Assignments: React.FC<IAssignmentsProps> = ({ user, offset, hideNewAssignmentButton, userType }) => {

  const { assignments, paging, loading, filter, error, refresh } = useAppSelector(
    state => state.assignments
  );

  const loadAssignments = (pageIndex: number, callback?: callbackType<PagedQueryView<AssignmentTinyView>>) => {
    assignmentsActions.getAssignments(
      user.id,
      user.userType,
      filter.value,
      pageIndex,
      callback
    );
  };

  useEffect(() => {
    assignmentActions.selectAssignment(null);
    user && loadAssignments(0);
  }, [user]);

  useEffect(() => {
    if (user && arrays.isEmpty(assignments)) {
      loadAssignments(0);
    }
  }, [filter]);

  useEffect(() => {
    paging?.pageIndex == 0 &&
      assignmentActions.selectAssignment(assignments[0]?.id);
  }, [assignments]);

  useEffect(() => {
    if (user && refresh) {
      loadAssignments(0);
    }
  }, [refresh]);

  const onPage = () => {
    loadAssignments(paging.pageIndex + 1);
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage: paging?.pageNumber < paging?.totalPages,
    onLoadMore: onPage
  });

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem while loading the assignments"
        cover
      />
    );
  }

  return (
    <>
      <OuterWrapper
        className="assignments-wrapper"
        offset={offset ? offset : 0}
      >
        <AssignmentsFilter
          hideNewAssignmentButton={hideNewAssignmentButton}
          userType={userType}
        />
        <InnerWrapper>
          <div className="left-panel" ref={rootRef}>
            <AssignmentList
              pagesAvailable={paging.pageNumber < paging.totalPages}
              loading={loading}
              onPage={onPage}
              assignments={assignments}
            />
            {(loading || paging?.pageNumber < paging?.totalPages) && (
              <div ref={sentryRef}>
                <Loader size={Size.Medium} fluid />
              </div>
            )}
          </div>

          <div className="right-panel">
            <AssignmentWrapper />
          </div>
        </InnerWrapper>
      </OuterWrapper>
    </>
  );
};

export default Assignments;
