import React from "react";
import styled from "styled-components";
import { EmptyMessage, Loader, Size } from "ui-kit";
import StaffAssignmentView from "./staffAssignmentView";
import StudentAssignmentView from "./studentAssignmentView";
import NewAssignmentButton from "./newAssignmentButton";
import {
  NonStaffView,
  StaffView,
} from "sharedComponents/common/users/userTypeView";
import { IBeehiveError } from "types/common/errors.types";
import { AssignmentDetailView } from "types/planner/assignments.types";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;


interface IAssignmentViewProps {
  assignment: AssignmentDetailView;
  loading?: boolean;
  error?: IBeehiveError;
}


const AssignmentView: React.FC<IAssignmentViewProps> = ({ assignment, loading, error }) => {
  
  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <Wrapper>
        <EmptyMessage
          icon="times-circle"
          title="A problem occurred"
          summary="There was a problem while loading the assignment"
          cover
        />
      </Wrapper>
    );
  }

  if (!assignment) {
    return (
      <Wrapper>
        <EmptyMessage
          icon="briefcase"
          title="No assignment"
          summary="Please select an assignment"
          cover
        >
          <StaffView>
            <NewAssignmentButton />
          </StaffView>
        </EmptyMessage>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <StaffView>
        <StaffAssignmentView assignment={assignment} />
      </StaffView>
      <NonStaffView>
        <StudentAssignmentView assignment={assignment} />
      </NonStaffView>
    </Wrapper>
  );
};

export default AssignmentView;
