import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ActionBar, Button, Card, Dropdown, Flyout, Size, Swatches, TextInput, TextInputType, displayFont, fontStyle, honeygold, neutral, typescale } from "ui-kit";
import { useNavigate } from "react-router-dom";
import EventsCategoryDropdown from "./eventsCategoryDropdown";
import EventsOwnerDropdown from "./eventOwnerDropdown";
import calendarActions from "areas/calendar/actions/calendarActions";
import { PublishStatusFilter, SchoolDropdown } from "sharedComponents/common";
import { eventRoutes } from "../../calendarRoutes";
import { RootState } from "reducers/store";
import { CalendarCategoryListView } from "areas/calendar/types/calendarResponses.types";
import routes from "configuration/routes";
import styled from "styled-components";
import flyoutActions from "actions/ui/flyouts";
import { Constants } from "configuration";


const MoreFiltersButton = styled.div`
  .button {
    background: unset;
    padding-left: 0;
    padding-right: 0;

    .button-content {
      ${fontStyle(displayFont.medium, typescale.paragraph, neutral[500])}

      .icon {
        font-size: unset;

        svg {
          color: ${neutral[500]};
        }
      }
    }

    &:hover {
      .button-content {
        color: ${honeygold};

        .icon svg {
          color: ${honeygold};
        }
      }
    }
  }
`;


const EventsFilter = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { filter } = useSelector((state: RootState) => state.eventsFilter);
  const navigate = useNavigate();

  useEffect(() => {
    calendarActions.updateEventsFilter({
      ...filter,
      schoolId: user.baseSchool.id,
    });
  }, []);

  const handleSchoolChange = (schoolId: number) => {
    calendarActions.updateEventsFilter({ ...filter, schoolId: schoolId });
  };

  const handleCategoryChange = (category: CalendarCategoryListView) => {
    calendarActions.updateEventsFilter({ ...filter, categoryId: category.id });
  };

  const handleOwnerChange = (eventOwner: string) => {
    calendarActions.updateEventsFilter({ ...filter, eventOwner: eventOwner });
  };

  const handlePublishStatusChange = (publishStatus: number) => {
    calendarActions.updateEventsFilter({ ...filter, status: publishStatus });
  };

  const handleSearchTermChange = (searchTerm: string) => {
    calendarActions.updateEventsFilter({ ...filter, searchTerm: searchTerm });
  }

  const handleAcademicYearChange = (academicYearId: number) => {
    calendarActions.updateEventsFilter({ ...filter, academicYearId: academicYearId });
  }

  const handleCreateEventClicked = () => {
    navigate(`${routes.home}/calendar${eventRoutes.new}`);
  };

  return (
    <>
    <ActionBar>
      <ActionBar.Filter>

        <ActionBar.FilterItem>
          <SchoolDropdown
            fluid
            schools={[...user.schools]}
            includeAllOption={false}
            includeTrust={false}
            initialValue={filter?.schoolId}
            onChange={handleSchoolChange}
          />
        </ActionBar.FilterItem>

        <ActionBar.FilterItem>
          <EventsCategoryDropdown
            onChange={handleCategoryChange}
            initialValue={filter?.categoryId}
            includeAllOption
          />
        </ActionBar.FilterItem>

        <ActionBar.FilterItem>
          <TextInput
            icon="search"
            type={TextInputType.String}
            onChange={handleSearchTermChange}
            placeholder={"Search Events..."}
            value={filter?.searchTerm}
            debounce
            fluid
          />
        </ActionBar.FilterItem>

        <ActionBar.FilterItem>
          <MoreFiltersButton>
            <Button
              text="More Filters"
              onClick={() => flyoutActions.openFlyout(Constants.FLYOUTS.EVENT_FILTERING)}
              size={Size.Small}
              icon="filter"
            />
          </MoreFiltersButton>
        </ActionBar.FilterItem>

        <Button
          size={Size.Small}
          text="Add New Event"
          color={Swatches.Primary}
          onClick={handleCreateEventClicked}
          tooltip="Create a new event"
        />

      </ActionBar.Filter>
    </ActionBar>

    <Flyout
      title="Filters"
      name={Constants.FLYOUTS.EVENT_FILTERING}
    >
      <Flyout.Body>
        <Card >
          <Card.Body>

            <ActionBar.FilterItem title="Event Owner">
              <EventsOwnerDropdown
                onChange={handleOwnerChange}
                initialValue={filter?.eventOwner}
                includeAllOption
                fluid
              />
            </ActionBar.FilterItem>
            <br />
            <ActionBar.FilterItem title="Event Status">
              <PublishStatusFilter
                onChange={handlePublishStatusChange}
                initialValue={filter?.status}
                fluid
              />
            </ActionBar.FilterItem>
            <ActionBar.FilterItem title="Academic Year">
              <Dropdown
                items={Constants.ACADEMIC_YEAR_FILTER_ARRAY}
                value={filter?.academicYearId}
                placeholder={"All Years"}
                onChange={handleAcademicYearChange}
                fluid
                clearable
              />
            </ActionBar.FilterItem>
          </Card.Body>
        </Card>
      </Flyout.Body>
    </Flyout>
    </>
  );
};

export default EventsFilter;
