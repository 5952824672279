import React from "react";
import { Chip, Swatches } from "ui-kit";
import { Constants } from "configuration";
import { ApprovalStatus } from "areas/humanResources/types/leaveShared.types";


interface ISubmissionStatusProps {
  status: ApprovalStatus;
}


const SubmissionStatus: React.FC<ISubmissionStatusProps> = ({ status }) => {

  var swatch = null;
  var name = "";

  switch (status) {
    case Constants.SUBMISSION_STATUS.DRAFT.value:
      swatch = Swatches.Low;
      name = Constants.SUBMISSION_STATUS.DRAFT.name;
      break;
    case Constants.SUBMISSION_STATUS.SUBMITTED.value:
      swatch = Swatches.Low;
      name = Constants.SUBMISSION_STATUS.SUBMITTED.name;
      break;
    case Constants.SUBMISSION_STATUS.APPROVED.value:
      swatch = Swatches.Warning;
      name = Constants.SUBMISSION_STATUS.APPROVED.name;
      break;
    case Constants.SUBMISSION_STATUS.CONFIRMED.value:
      swatch = Swatches.Success;
      name = Constants.SUBMISSION_STATUS.CONFIRMED.name;
      break;
    case Constants.SUBMISSION_STATUS.COMPLETE.value:
      swatch = Swatches.Complete;
      name = Constants.SUBMISSION_STATUS.COMPLETE.name;
      break;
    case Constants.SUBMISSION_STATUS.CANCELLED.value:
      swatch = Swatches.Cancel;
      name = Constants.SUBMISSION_STATUS.CANCELLED.name;
      break;
    default:
      return null;
  }

  return <Chip text={name} colorSwatch={swatch} />;
};

export default SubmissionStatus;
