// Based off : https://kentcdodds.com/blog/how-to-use-react-context-effectively

import { SaveReviewCommand } from "areas/humanResources/types/managedStaffRequest.types";
import { ReviewDetailView } from "areas/humanResources/types/managedStaffResponse.types";
import React, { Fragment, useContext, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";


interface IReviewState {
  review: ReviewDetailView;
  isLineManager?: boolean;
  isReviewee?: boolean;
}

interface IReviewProviderProps {
  review: ReviewDetailView;
  children?: (JSX.Element | React.ReactNode)[];
}

type ReviewAction = {type: 'updateReview', payload: any} | {type: 'updateTarget', payload: any};
type ReviewDispatch = (action: ReviewAction) => void;


const ReviewContext = React.createContext<{state: IReviewState; dispatch: ReviewDispatch} | undefined>(undefined)


const reviewReducer = (state: IReviewState, action: ReviewAction) : IReviewState => {

  switch (action.type) {
    case "updateReview": {
      return { review: { ...state.review, ...action.payload } };
    }
    case "updateTarget": {
      return {
        review: {
          ...state.review,
          targets: state.review.targets.map((x) =>
            x.id === action.payload.id ? { ...x, ...action.payload } : x
          )
        }
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}


const ReviewProvider: React.FC<IReviewProviderProps> = ({ review, children }) => {

  const [state, dispatch] = useReducer(reviewReducer, { review });
  const { user } = useSelector((state: RootState) => state.currentUser);

  if (review) {
    state.isLineManager = review.lineManager && review.lineManager.id === user.id ? true : false;
    state.isReviewee = review.reviewee && review.reviewee.id === user.id ? true : false;
  }

  const value = {
    state,
    dispatch
  };

  return (
    <ReviewContext.Provider value={value}>{children}</ReviewContext.Provider>
  );
};


const useReview = () => {
  const context = useContext(ReviewContext);

  if (context === undefined) {
    throw new Error("useReview must be used within a ReviewProvider");
  }

  return context;
}


export { ReviewProvider, useReview };
