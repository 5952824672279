import searchEngineActions from "areas/search/actions/searchEngineActions";
import defaults from "configuration/defaults";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";
import { PageInfo } from "types/common/paging.types";
import { SearchResultViewBase } from "types/common/views.types";


interface ISearchCustomGroupsState {
  groups: SearchResultViewBase[];
  loading: boolean;
  error: IBeehiveError;
  paging: PageInfo;
}

const INITIAL_STATE: ISearchCustomGroupsState = {
  groups: null,
  loading: false,
  error: null,
  paging: defaults.DEFAULT_PAGING,
};


const searchCustomGroupsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISearchCustomGroupsState => {

  const { SEARCH_CUSTOMGROUPS, CLEAR_RESULTS } = searchEngineActions.types;

  switch (action.type) {
    
    case SEARCH_CUSTOMGROUPS.START:
      // We are not using ...INITIAL_STATE here to avoid a the menu opening and closing between key strokes when using an autocomplete control
      return { ...state, loading: true, error: null };
    case SEARCH_CUSTOMGROUPS.SUCCESS:
      return {
        ...state,
        loading: false,
        groups:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.groups, ...action.payload.items],
        paging: action.payload.paging,
      };
    case SEARCH_CUSTOMGROUPS.FAILED:
      return { ...state, loading: false, groups: null, error: action.payload };

    case CLEAR_RESULTS:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default searchCustomGroupsReducer;
