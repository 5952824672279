import React from "react";
import { Message } from "ui-kit";
import { Swatches } from "../../ui-kit";
import { IBeehiveError } from "types/common/errors.types";

interface IApiExceptionMessageProps {
  error: IBeehiveError;
}

const ApiExceptionMessage: React.FC<IApiExceptionMessageProps> = ({
  error,
}) => {
  return error ? (
    <Message
      text={`Error: ${
        error?.response?.data?.exceptionMessage ||
        error?.exceptionMessage ||
        (error && "An error has occurred.")
      }`}
      color={Swatches.Danger}
    />
  ) : null;
};

export default ApiExceptionMessage;
