import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ManagedStaffList } from "sharedComponents/common";
import { useSelector } from "react-redux";
import { EmptyMessage } from "ui-kit";
import HolidayCard from "./holidayCard";
import config from "configuration";
import { arrays } from "utils";
import leaveActions from "areas/humanResources/actions/leaveActions";
import { RootState } from "reducers/store";
import { StaffListView } from "types/users/userListViews.types";

const Wrapper = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const StaffHoliday = () => {
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { holidayCard, loading, error } = useSelector(
    (state: RootState) => state.staffMemberHolidayCard
  );
  const [selectedStaffMember, setSelectedStaffMember] =
    useState<StaffListView | null>(null);

  const staffLoaded = (staff: StaffListView[]) => {
    !arrays.isEmpty(staff) && setSelectedStaffMember(staff[0]);
  };

  const handleStaffMemberSelected = (staffMember: StaffListView) => {
    setSelectedStaffMember(staffMember);
  };

  const loadHolidayCard = (academicYearId: number) => {
    selectedStaffMember &&
      academicYearId &&
      leaveActions.getHolidayCardForStaffMember(
        selectedStaffMember.id,
        academicYearId
      );
  };

  useEffect(() => {
    loadHolidayCard(config.academicYear.current);
  }, [selectedStaffMember]);

  const handleHolidayCardChange = (academicYearId: number) => {
    loadHolidayCard(academicYearId);
  };

  const handleAcademicYearChanged = (academicYearId: number) => {
    loadHolidayCard(academicYearId);
  };

  return (
    <Wrapper>
      <ManagedStaffList
        onStaffMemberSelected={handleStaffMemberSelected}
        manager={user}
        onLoaded={staffLoaded}
      />

      {selectedStaffMember ? (
        <HolidayCard
          loading={loading}
          error={error}
          holidayCard={holidayCard}
          staffMember={selectedStaffMember}
          onAcademicYearChanged={handleAcademicYearChanged}
          onChange={handleHolidayCardChange}
        />
      ) : (
        <EmptyMessage
          icon="plane"
          title="Select a Staff Member"
          summary="Please select a staff member to view their holiday card"
          cover
        />
      )}
    </Wrapper>
  );
};

export default StaffHoliday;
