import React, { useState, useEffect } from "react";
import { Swatches, Size, EmptyMessage, Button, Loader, Message } from "ui-kit";
import CustomProvisionTable from "./customProvisionTable";
import ProvisionRecordTable from "../providers/provisions/provisionGroups/provisionRecordTable";
import { Constants } from "configuration";
import { ProvisionMapDetailView } from "areas/send/types/passportResponse.types";
import { UserType } from "configuration/constants.enums";
import { users } from "utils";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";


interface IProvisionMapProps {
  provisionMap: ProvisionMapDetailView; 
  onAdd?: () => void;
  loading?: boolean;
  error?: string;
  readonly?: boolean;
}


const ProvisionMap: React.FC<IProvisionMapProps> = ({ provisionMap, onAdd, loading, error, readonly }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const [_provisionMap, _setProvisionMap] = useState<ProvisionMapDetailView | null>(null);
  const [_loading, _setLoading] = useState<boolean>(false);
  const [_error, _setError] = useState<string | null>(null);

  useEffect(() => {
    _setError(error);
  }, [error]);

  useEffect(() => {
    _setLoading(loading);
  }, [loading]);

  useEffect(() => {
    _setProvisionMap(provisionMap);
  }, [provisionMap]);

  const handleAdd = () => {
    onAdd?.();
  }

  if (_loading) {
    return <Loader size={Size.Medium} cover />;
  }

  if (_error) {
    return (
      <EmptyMessage
        cover
        title="An error occurred"
        icon="hand-holding-seedling"
        summary="There was a problem loading the provision map"
      />
    );
  }

  if (!_provisionMap) {
    return (
      <EmptyMessage
        cover
        title="Provision Map Not Found"
        icon="hand-holding-seedling"
        summary="No provision maps have were found for this student"
      >
      { !readonly && (
        <Button
          text="Add New Provision Map"
          size={Size.Small}
          color={Swatches.Primary}
          onClick={handleAdd}
        />
      )}
      </EmptyMessage>
    );
  }

  return (
    <>
      {_provisionMap.status === Constants.SEND_PROVISIONMAPSTATUS.CLOSED.value &&
        users.isUserOfType(user, UserType.STAFF) && (
        <Message
          color={Swatches.Low}
          text={`This provision map now has a status of closed. No further changes can be made.`}
        />
      )}

      {_provisionMap?.reviewable &&
       users.isUserOfType(user, UserType.STAFF) && (
        <Message
          color={Swatches.Blue}
          text={`A Provision Review Window is open. Comments and other changes can be made.`}
        />
      )}
      <ProvisionRecordTable
        title="Provisions"
        records={_provisionMap.records}
        singleStudentMode
        editable={
          _provisionMap.status === Constants.SEND_PROVISIONMAPSTATUS.OPEN.value && !readonly
        }
      />
      <CustomProvisionTable 
        provisionMap={_provisionMap} 
        readonly={readonly} 
      />

      {!_provisionMap.reviewable &&
        _provisionMap.status === Constants.SEND_PROVISIONMAPSTATUS.OPEN.value && (
          <Message
            color={Swatches.Low}
            text={`There is no current Provision Review Window open right now. Comments and other changes can be made when one opens.`}
          />
        )}
    </>
  );
};

export default ProvisionMap;
