import React, { useEffect, useState } from "react";
import {
  Message,
  Card,
  StructuredList,
  ChipList,
  Swatches,
  Title,
  TitleSize,
  Label,
  Spacing,
  neutral,
  Chip,
  EmptyMessage,
  SplitButton,
  Button,
  Left,
  Right,
  Subtitle,
  Text,
  HtmlText,
  Size,
} from "ui-kit";
import { Constants } from "configuration";
import styled from "styled-components";
import moment from "moment";
import { arrays } from "utils";
import {
  DateOfBirth,
  SchoolAvatar,
  RoleView,
  Avatar,
  FileList,
} from "sharedComponents/common";
import PassportEditorModal from "./passportEditorModal";
import { useSelector } from "react-redux";
import { getTileName } from "../../utils";
import passportActions from "../../actions/passports/passportActions";
import { RootState } from "reducers/store";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";
import { PassportDetailView, PassportVersionDetailView } from "areas/send/types/passportResponse.types";

const Wrapper = styled.div<{readonly?: boolean}>`

  ${({ readonly }) => !readonly && `
    padding-top: ${Spacing.Medium}px;
    padding-bottom: ${Spacing.Medium}px;
    margin-top: ${Spacing.Medium}px;
    border-top: 1px solid ${neutral[300]};
  `}
`;

const PassportHeader = styled.div`
  margin-bottom: ${Spacing.Large}px;
  display: flex;
  align-items: top;
  justify-content: space-between;
`;


interface IPupilPassportViewerProps {
  passport: PassportDetailView;
  student: PlannerStudentDetailView;
  onDelete?: (deletedId: number) => void;
  onPublish?: (selectedVersion: PassportVersionDetailView) => void;
  readonly?: boolean;
}


const PupilPassportViewer: React.FC<IPupilPassportViewerProps> = ({
  passport,
  student,
  onDelete,
  onPublish,
  readonly,
}) => {

  const { deleting, deleteError, publishing, publishError } = useSelector(
    (state: RootState) => state.passportVersion
  );
  const [_passport, _setPassport] = useState<PassportDetailView | null>(null);
  const [_editModalOpen, _setEditModalOpen] = useState<boolean>(false);
  const [_downloading, _setDownloading] = useState<boolean>(false);

  useEffect(() => {
    _setPassport(passport);
  }, [passport]);

  const handleEditPassport = () => {
    _setEditModalOpen(true);
  };

  const handleModalCancel = () => {
    _setEditModalOpen(false);
  };

  const handleModalSaved = () => {
    _setEditModalOpen(false);
  };

  const handleDeletePassport = () => {
    if (
      window.confirm(
        "This will delete this version. This cannot be undone. Are you sure?"
      )
    ) {
      passportActions.deletePassportVersion(
        _passport.student.id,
        _passport.id,
        _passport.selectedVersion.id,
        (deletedId) => {
          onDelete?.(deletedId);
        }
      );
    }
  };

  const handlePublishPassport = () => {
    if (
      window.confirm(
        "This will publish this pupil passport and lock it for any further editing. It will also be visible to all staff as well as the student and their parents. This cannot be undone. Are you sure?"
      )
    ) {
      passportActions.publishPassportVersion(
        {
          studentId: _passport.student.id,
          passportId: _passport.id,
          id: _passport.selectedVersion.id,
        },
        () => {
          onPublish?.(_passport.selectedVersion);
        }
      );
    }
  };

  const handleDownloadPassport = () => {
    _setDownloading(true);

    passportActions
      .downloadPassportVersion(_passport.student.id, _passport.id, _passport.selectedVersion.id)
      .then(() => {
        _setDownloading(false);
      })
      .catch(() => {
        _setDownloading(false);
      });
    // downloadPassportVersionAction(
    //   {
    //     studentId: _passport.student.id,
    //     passportId: _passport.id,
    //     id: _passport.selectedVersion.id,
    //   },
    //   (data) => {
    //     var attachment = document.createElement("a");

    //     attachment.href = "data:attachment/pdf," + encodeURIComponent(data);
    //     attachment.download = "Pupil Passport.pdf";
    //     attachment.click();
    //   }
    // );
  };

  return _passport == null ? null : (
    <Wrapper readonly={readonly}>
      <PassportHeader>
        <Left>
          <>
            <Title
              size={TitleSize.H3}
              text={`Pupil Passport ${_passport.academicYear}`}
              sub={`Version ${_passport.selectedVersion.versionNumber} ${` 
                ${
                  _passport.selectedVersion.status ===
                  Constants.SEND_PASSPORTSTATUS.PUBLISHED.value
                    ? moment(_passport.selectedVersion.publishedDate).format(
                        " - Do MMM YYYY"
                      )
                    : ""
                }`}`}
            />
            <RoleView
              roles={[
                Constants.ROLES.SEN_ADMINISTRATOR,
                Constants.ROLES.SEN_COORDINATOR,
                Constants.ROLES.LEADERSHIP,
              ]}
            >
              {_passport.selectedVersion.status ===
                Constants.SEND_PASSPORTSTATUS.PUBLISHED.value && (
                <Chip
                  text={_passport.selectedVersion.statusName}
                  colorSwatch={
                    _passport.selectedVersion.status ===
                    Constants.SEND_PASSPORTSTATUS.DRAFT.value
                      ? Swatches.Blue
                      : Swatches.Success
                  }
                />
              )}
            </RoleView>
          </>
        </Left>
        <Right>
          {_passport.selectedVersion.status ===
          Constants.SEND_PASSPORTSTATUS.DRAFT.value ? (
            <RoleView
              roles={[
                Constants.ROLES.SEN_ADMINISTRATOR,
                Constants.ROLES.SEN_COORDINATOR,
                Constants.ROLES.LEADERSHIP,
              ]}
            >
              <SplitButton
                text="Edit Version"
                onDefaultClick={handleEditPassport}
                size={Size.Small}
                color={Swatches.Primary}
                working={deleting || publishing}
              >
                <SplitButton.Option
                  onClick={handleEditPassport}
                  text="Edit Version"
                  show
                />
                <SplitButton.Option
                  onClick={handlePublishPassport}
                  text="Publish Version"
                  show
                />
                <SplitButton.Option
                  onClick={handleDeletePassport}
                  text="Delete Version"
                  show={_passport.versions.length > 1}
                />
              </SplitButton>
            </RoleView>
          ) : (
            <Button
              onClick={handleDownloadPassport}
              text="Download Pupil Passport"
              size={Size.Small}
              color={Swatches.Primary}
              working={_downloading}
            />
          )}
        </Right>
      </PassportHeader>
      <Message text={deleteError} color={Swatches.Danger} />
      <Message text={publishError} color={Swatches.Danger} />
      {_passport.selectedVersion.status ===
        Constants.SEND_PASSPORTSTATUS.DRAFT.value && (
        <Message
          color={Swatches.Blue}
          text="This pupil passport version is a draft and is not available for non-SEND staff, parents or the student to view."
        />
      )}
      <>
        <Card title="Student Details">
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="School">
                <SchoolAvatar school={_passport.selectedVersion.school} />
              </StructuredList.Item>
              <StructuredList.Item name="Date of Birth">
                <Label>
                  <DateOfBirth
                    dateOfBirth={_passport.student.dateOfBirth}
                    bold
                  />
                </Label>
              </StructuredList.Item>
              <StructuredList.Item name="Year Group">
                {_passport.selectedVersion.yearGroup ? (
                  <Label>{_passport.selectedVersion.yearGroup.name}</Label>
                ) : (
                  <Label>No year group set</Label>
                )}
              </StructuredList.Item>
              <StructuredList.Item name="Tutor Group">
                {_passport.selectedVersion.tutorGroup ? (
                  <Label>{_passport.selectedVersion.tutorGroup.name}</Label>
                ) : (
                  <Label>No tutor group set</Label>
                )}
              </StructuredList.Item>
              <StructuredList.Item name="SEN Code">
                {_passport.selectedVersion.senStatus ? (
                  <Chip
                    text={_passport.selectedVersion.senStatusName}
                    colorSwatch={Swatches.Miami}
                  />
                ) : (
                  <Label>No Special Educational Need</Label>
                )}
              </StructuredList.Item>
              <StructuredList.Item name="NGRT Reading Age">
                {_passport.selectedVersion.readingAge ? (
                  <Label>{_passport.selectedVersion.readingAge}</Label>
                ) : (
                  <Label>No reading age available</Label>
                )}
              </StructuredList.Item>
              <StructuredList.Item name="Other Details">
                <ChipList>
                  {_passport.selectedVersion.pupilPremium && (
                    <Chip text="Pupil Premium" colorSwatch={Swatches.Purple} />
                  )}
                  {_passport.selectedVersion.eAL && (
                    <Chip text="EAL" colorSwatch={Swatches.DeepPink} />
                  )}
                  {_passport.selectedVersion.lookedAfter && (
                    <Chip
                      text="Looked After"
                      colorSwatch={{
                        swatch: "#ff8e3f",
                        foreground: neutral[100],
                      }}
                    />
                  )}
                  {_passport.selectedVersion.childProtection && (
                    <Chip
                      text="Child Protection"
                      colorSwatch={Swatches.OrangeRed}
                    />
                  )}
                  {_passport.selectedVersion.freeSchoolMeal && (
                    <Chip
                      text="Free School Meal"
                      colorSwatch={Swatches.Miami}
                    />
                  )}
                </ChipList>
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
        <Card title="Needs & Access">
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="Primary Area of Need">
                {_passport.selectedVersion.primaryAreaOfNeed != null ? (
                  <Chip
                    text={_passport.selectedVersion.primaryAreaOfNeedName}
                    colorSwatch={Swatches.Miami}
                  />
                ) : (
                  <Label>No Primary Area of Need</Label>
                )}
              </StructuredList.Item>
              <StructuredList.Item name="Secondary Area of Need">
                {_passport.selectedVersion.secondaryAreaOfNeed != null ? (
                  <Chip
                    text={_passport.selectedVersion.secondaryAreaOfNeedName}
                    colorSwatch={Swatches.Miami}
                  />
                ) : (
                  <Label>No Secondary Area of Need</Label>
                )}
              </StructuredList.Item>
              <StructuredList.Item name="Specific Area of Need">
                {_passport.selectedVersion.specificAreaOfNeed ? (
                  <Label>{_passport.selectedVersion.specificAreaOfNeed}</Label>
                ) : (
                  <Label>No Specific Area of Need</Label>
                )}
              </StructuredList.Item>
              <StructuredList.Item name="EHCP">
                {_passport.selectedVersion.ehcp ? (
                  <Label>Yes</Label>
                ) : (
                  <Label>No</Label>
                )}
              </StructuredList.Item>
              <StructuredList.Item name="IHCP">
                {_passport.selectedVersion.ihcp ? (
                  <Label>Yes</Label>
                ) : (
                  <Label>No</Label>
                )}
              </StructuredList.Item>
              <StructuredList.Item name="Pastoral Support Plan">
                {_passport.selectedVersion.pastoralSupportPlan ? (
                  <Label>Yes</Label>
                ) : (
                  <Label>No</Label>
                )}
              </StructuredList.Item>
              <StructuredList.Item name="Tags">
                {!arrays.isEmpty(_passport.selectedVersion.tags) ? (
                  <ChipList>
                    {_passport.selectedVersion.tags.map((tag, index) => (
                      <Chip
                        key={index}
                        text={tag.name}
                        tooltip={tag.name}
                        tooltipSub={tag.description}
                      />
                    ))}
                  </ChipList>
                ) : (
                  <Label>No tags have been added</Label>
                )}
              </StructuredList.Item>
              <StructuredList.Item name="Access Arrangements">
                {!arrays.isEmpty(
                  _passport.selectedVersion.accessArrangements
                ) ? (
                  <ChipList>
                    {_passport.selectedVersion.accessArrangements.map(
                      (access, index) => (
                        <Chip
                          key={index}
                          text={access.name}
                          tooltip={access.name}
                          tooltipSub={access.description}
                        />
                      )
                    )}
                  </ChipList>
                ) : (
                  <Label>No access arrangements have been made</Label>
                )}
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
        <Card title="Details" low={!_passport.selectedVersion.tiles?.length}>
          <Card.Body>
            {!arrays.isEmpty(_passport.selectedVersion.tiles) ? (
              <StructuredList>
                {_passport.selectedVersion.tiles.map((tile, index) => (
                  <StructuredList.Item
                    key={index}
                    name={getTileName(tile, student)}
                  >
                    <HtmlText html={tile.content} />
                  </StructuredList.Item>
                ))}
              </StructuredList>
            ) : (
              <EmptyMessage
                title="No Details"
                summary="No passport details have been added"
                icon="hand-holding-seedling"
              />
            )}
          </Card.Body>
        </Card>
        <Card
          title="Attachments"
          low={!_passport.selectedVersion.files?.length}
        >
          <Card.Body>
            {!arrays.isEmpty(_passport.selectedVersion.files) ? (
              <StructuredList>
                <StructuredList.Item name="Attachments">
                  <FileList>
                    {_passport.selectedVersion.files?.map((file, index) => (
                      <FileList.Item
                        key={index}
                        file={{ ...file, id: file.id }}
                        clickToDownload
                      />
                    ))}
                  </FileList>
                </StructuredList.Item>
              </StructuredList>
            ) : (
              <EmptyMessage
                title="No Attachments"
                summary="No files have been attached"
                icon="hand-holding-seedling"
              />
            )}
          </Card.Body>
        </Card>

        {_passport.selectedVersion.status ===
          Constants.SEND_PASSPORTSTATUS.PUBLISHED.value && (
          <>
            <Card title="Published">
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="Published">
                    <Avatar
                      user={_passport.selectedVersion.publishedBy}
                      sub={moment(
                        _passport.selectedVersion.publishedDate
                      ).format("dddd, Do MMMM YYYY hh:mm")}
                      size={Size.Small}
                    />
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
            <Subtitle>About this Pupil Passport</Subtitle>
            <Text>
              A student generally has one active pupil passport per academic
              year, although there could be older versions depending on a
              student's changing requirements. The information shown in the
              passport is fixed at the time of publishing and cannot be changed.
              If the information is out-of-date then a new version should be
              created. Only the most recent version is shown to staff, parents
              and the student. If you need to view a previous version of a
              passport then please contact your school's SEN Coordinator.
            </Text>
          </>
        )}

        <PassportEditorModal
          student={student}
          passport={_passport}
          open={_editModalOpen}
          onCancel={handleModalCancel}
          onSave={handleModalSaved}
        />
      </>
    </Wrapper>
  );
};
export default PupilPassportViewer;
