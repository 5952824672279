import React from "react";
import styled from "styled-components";
import {
  Icon,
  Size,
  Button,
  fontStyle,
  neutral,
  typescale,
  Subtitle,
  textFont,
  BorderRadius,
  Spacing,
  Swatches,
  honeygold,
  DetailLabel,
  Sub
} from "ui-kit";
import config from "configuration";
import { arrays } from "../../../utils";
import { LinkListView } from "types/common/links.types";

export interface ILinkListProps {
  className?: string;
  children?:
    | React.ReactElement<ILinkListProps>
    | React.ReactElement<ILinkListProps>[];
  title?: string;
}

const LinkListWrapper = styled.div`
  margin-bottom: ${Spacing.Large}px;
  .subtitle {
    margin-bottom: ${Spacing.Small}px;
  }
`;

const LinkList: React.FC<ILinkListProps> & {
  Item: typeof LinkListItem;
} = ({ children, className, title }) => {
  return arrays.isEmpty(children) ? null : (
    <LinkListWrapper className="links-list">
      {title && <Subtitle text={title} />}
      <ul>{children}</ul>
    </LinkListWrapper>
  );
};

export interface ILinkListItemProps {
  className?: string;
  onDelete?(link: LinkListView): void;
  onClick?(link: LinkListView): void;
  error?: string;
  link: LinkListView;
  clickToDownload?: boolean;
}

const LinkListItemWrapper = styled.li`
  background: ${neutral[200]};
  border-radius: ${BorderRadius.Default}px;
  padding: ${Spacing.Default}px;
  margin-bottom: ${Spacing.Default}px;
  display: flex;
  align-items: flex-start;
  cursor:pointer;

  &:last-child {
    margin-bottom: 0;
  }

  .link-item-details {
    flex-grow: 1;
  }
  .link-item-icon {
    color: ${honeygold}
  }
  .link-item-details {
    margin-left: ${Spacing.Default}px;
    align-items: center;
  }
  .link-item-name {
    ${fontStyle(textFont.bold, typescale.sub)}
    margin-bottom: ${Spacing.Small}px;
  }
  .link-item-size {
    margin-left: ${Spacing.Default}px;
    ${fontStyle(textFont.roman, typescale.sub)}
  }
  .link-item-error {
    ${fontStyle(textFont.roman, typescale.sub, Swatches.Danger.swatch)}  
    margin-left: ${Spacing.Default}px;
  }
  .link-item-button {
    
    background: none;
   i { color: ${neutral[500]}};
    padding: 0;

    &:hover {
      i { color: ${Swatches.Danger.swatch} !important;}
    }
  }`;

const LinkListItem: React.FC<ILinkListItemProps> = ({
  onDelete,
  onClick,
  link
}) => {
  const handleDelete = (e: React.MouseEvent) => {
    console.log(e);
    e.stopPropagation();
    onDelete?.(link);
  };

  const handleClick = () => {
    if (onClick) {
      onClick(link);
    } else {
      window.open(link.url, "_blank");
    }
  };

  return (
    <LinkListItemWrapper onClick={handleClick}>
      <div className="link-item-left">
        <Icon value="link" />
      </div>
      <div className="link-item-details">
        <DetailLabel label={link.title} bold sub={link.description} />
        <Sub className="link-item-url">{link.url}</Sub>
      </div>
      {onDelete && (
        <Button
          className="link-item-button"
          icon="times"
          onClick={e => handleDelete(e)}
        />
      )}
    </LinkListItemWrapper>
  );
};

LinkList.Item = LinkListItem;

export default LinkList;
