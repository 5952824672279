import routes from "../../configuration/routes";

export const sendRoutes = {
  default: "/send",
  provider: "/send/providers/:providerId",
  getProviderPath: (providerId: number) => `${routes.home}/send/providers/${providerId}`,
  provisionGroup: `/send/providers/:providerId/provisions/:provisionId/groups/:provisionGroupId`,
  getProvisionGroupPath: (providerId: number, provisionId: number, provisionGroupId: number) =>
    `${routes.home}/send/providers/${providerId}/provisions/${provisionId}/groups/${provisionGroupId}`,
  provision: "/main/send/providers/:providerId}/provisions/:provisionId",
  getProvisionPath: (providerId: number, provisionId: number) =>
    `/main/send/providers/${providerId}/provisions/${provisionId}`,
  studentCustomProvision: "students/:studentId/customprovisions/:provisionId",
  getStudentCustomProvisionPath: (studentId: string, provisionId: number) =>
    `/main/planner/students/${studentId}`,
};
