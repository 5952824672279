import React, { useEffect, useState } from "react";
import {
  Loader,
  Size,
  Title,
  TitleSize,
  HeadlineStatistic,
  Tabs,
} from "ui-kit";
import Subheader from "sharedComponents/layout/header/subheader";
import { ChildTabs } from "sharedComponents/common";
import { useSelector } from "react-redux";
import StudentActivityWrapper from "../../studentRecord/studentActivity/studentActivityWrapper";
import studentActions from "areas/planner/actions/student/studentActions";
import DetentionHistory from "areas/behaviour/components/detentions/detentionHistoryParentView/detentionHistory";
import StudentHeadlineStats from "./studentHeadlineStats";

const ParentActivityView = () => {
  const { user } = useSelector(state => state.currentUser);
  const { student, loading } = useSelector(state => state.student);
  const [selectedChild, setSelectedChild] = useState(user.children[0]);

  const handleTabClicked = (name, index) => {
    setSelectedChild(user.children[index]);
  };

  useEffect(() => {
    if (selectedChild) {
      studentActions.getStudent(selectedChild.id);
    }
  }, [selectedChild]);

  return (
    <>
      <ChildTabs
        onChildClicked={handleTabClicked}
        singleChild={
          <Subheader>
            <Title
              text={`${selectedChild?.details.firstName} ${selectedChild?.details.lastName}`}
              size={TitleSize.H2}
            />
          </Subheader>
        }
      />

      {loading ? (
        <Loader size={Size.Large} fluid />
      ) : (
        student && (
          <>
            <StudentHeadlineStats student={student} />

            <Tabs>
              <Tabs.Pane name="activity" label="Activity">
                <StudentActivityWrapper
                  studentId={selectedChild?.id}
                  schoolId={student.school?.id}
                />
              </Tabs.Pane>
              <Tabs.Pane name="detentions" label="Detentions">
                <DetentionHistory studentId={selectedChild?.id} />
              </Tabs.Pane>
            </Tabs>
          </>
        )
      )}
    </>
  );
};

export default ParentActivityView;
