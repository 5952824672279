import fileService from "services/files/fileService";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


export type CachedUserImage = {
  userId: string;
  imageUrl: string;
}

interface IProfileImageCacheReducerState {
  images: CachedUserImage[];
}

const INITIAL_STATE: IProfileImageCacheReducerState = {
  images: [],
};


const profileImageCacheReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IProfileImageCacheReducerState => {

  const { CACHEIMAGE } = fileService.types;

  switch (action.type) {

    case CACHEIMAGE: {
      let _payload = action.payload as CachedUserImage;
      return {
        ...state,
        images: !state.images.some(cachedImage => cachedImage.userId === _payload.userId )
          ? [_payload, ...state.images]
          : state.images
      }
    }

    default:
      return state;
  }
};


export default profileImageCacheReducer;