import React, { useState } from "react";
import { useReview } from "./reviewContext";
import {
  Wizard,
  Text,
  StructuredList,
  RadioList,
  RichTextEditor,
  HtmlText,
  Card,
} from "ui-kit";
import { Constants } from "configuration";
import RevieweeIntroduction from "./revieweeIntroduction";
import ReviewerIntroduction from "./reviewerIntroduction";
import ReviewSummary from "./reviewSummary";
import TargetEditor from "./targetEditor";
import { arrays } from "utils";
import { useNavigate } from "react-router-dom";
import { ReviewDetailView } from "areas/humanResources/types/managedStaffResponse.types";
import { AspirationType } from "areas/humanResources/types/managedStaffShared.types";


interface IReviewEditorProps {
  onSave?: (review: ReviewDetailView) => void;
  working?: boolean;
  error?: string;
}

export interface EditableReviewFields {
  aspirations?: AspirationType;
  trainingRequirements?: string;
  trainingActionRequired?: string;
  cpd?: string;
  revieweeSummary?: string;
  lineManagerSummary?: string;
  rating?: number;
}


const ReviewEditor: React.FC<IReviewEditorProps> = ({ onSave, working, error }) => {

  const { state, dispatch } = useReview();
  const [_review, _setReview] = useState(state.review);
  const navigate = useNavigate();

  const updateReview = (payload: EditableReviewFields) => {
    dispatch({ type: "updateReview", payload });
  };

  const getEditor = (value: string, onChange: (value: string) => void, editable: boolean, emptyMessage: string) => {
    if (editable) {
      return <RichTextEditor initialValue={value} onChange={onChange} />;
    } else {
      return value ? <HtmlText html={value} /> : <Text>{emptyMessage}</Text>;
    }
  };

  const handleFinish = () => new Promise((resolve, reject) => {
    resolve(onSave?.(state.review));
  });

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <Wizard
      error={error}
      onFinish={handleFinish}
      working={working}
      onCancel={handleCancel}
    >
      <Wizard.Step icon="rocket" title="Introduction" showTitle={false}>
        <Card title="Introduction">
          <Card.Body>
            {state.isReviewee ? (
              <RevieweeIntroduction />
            ) : (
              <ReviewerIntroduction />
            )}
          </Card.Body>
        </Card>
      </Wizard.Step>
      <Wizard.Step
        icon="bullseye-arrow"
        title={
          state.review.reviewType == "Teaching" ? "Professional Practice" : "Target 1"
        }
      >
        <TargetEditor target={state.review.targets[0]} />
      </Wizard.Step>
      <Wizard.Step
        icon="bullseye-arrow"
        title={
          state.review.reviewType == "Teaching" ? "Professional Outcomes" : "Target 2"
        }
      >
        <TargetEditor target={state.review.targets[1]} />
      </Wizard.Step>
      <Wizard.Step
        icon="bullseye-arrow"
        title={
          state.review.reviewType == "Teaching" ? "Professional Development" : "Target 3"
        }
      >
        <TargetEditor target={state.review.targets[2]} />
      </Wizard.Step>
      <Wizard.Step
        icon="rocket"
        title="Aspirations & Training"
        description="You can record any general training requirements not specified on the
          targets and action that needs to be taken by the Trust or School to
          facilitate this. This is optional but reviewees are encouraged to
          undertake training to support their job role and add additional
          skills. Please indicate any future aspirations over the next 12 months
          and add any necessary training for these aspirations. Reviewees can
          also log in separately add a record of any CPD (Continuing
          Professional Development) undertaken during the year."
      >
        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item
                name="Aspirations"
                description="Are there any aspirations over the next 12 months?"
              >
                <RadioList
                  disabled={state.isReviewee}
                  value={state.review.aspirations}
                  onChange={value => updateReview({ aspirations: (value as number) })}
                  items={arrays.configConstantsToArray(
                    Constants.ASPIRATION_OPTIONS
                  )}
                  fluid
                />
              </StructuredList.Item>
              <StructuredList.Item
                name="CPD &amp; Training Requirements"
                description="Please enter any CPD or training needed. This should not be linked to an individual target but reflect any general training needed or if training and support is needed for promotional aspirations."
              >
                {getEditor(
                  _review.trainingRequirements,
                  value => updateReview({ trainingRequirements: value }),
                  state.isLineManager,
                  "No CPD or training requirements have been entered yet"
                )}
              </StructuredList.Item>
              <StructuredList.Item
                name="CPD &amp; Training Action Required by School/Trust"
                description="Please enter any action that is required from the School or Trust in order to facilitate a successful CPD &amp; Training Plan."
              >
                {getEditor(
                  _review.trainingActionRequired,
                  value => updateReview({ trainingActionRequired: value }),
                  state.isLineManager,
                  "No training action has been entered yet"
                )}
              </StructuredList.Item>
              <StructuredList.Item
                name="CPD &amp; Training Record"
                description={`Any CPD (Continuing Professional Development) or training undertaken during the review period can be entered by ${state.review.reviewee.firstName}.`}
              >
                {getEditor(
                  _review.cpd,
                  value => updateReview({ cpd: value }),
                  state.isReviewee,
                  `No CPD has been entered yet by ${state.review.reviewee.firstName}`
                )}
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      </Wizard.Step>
      <Wizard.Step
        icon="flag-checkered"
        title="Year End"
        hide={() =>
          state.review.inCurrentAcademicYear &&
          ( state.review.currentReviewPeriod == null || 
            state.review.currentReviewPeriod.type.valueOf() !== Constants.REVIEW_PERIODS.YEAREND )
        }
      >
        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item
                name={`${state.review.reviewee.firstName}'s End of Year Summary`}
                description={`This is ${state.review.reviewee.firstName}'s summary of their performance during the academic year.`}
              >
                {getEditor(
                  _review.revieweeSummary,
                  value => updateReview({ revieweeSummary: value }),
                  state.isReviewee,
                  `No summary has been entered by ${state.review.reviewee.firstName} yet.`
                )}
              </StructuredList.Item>
            </StructuredList>
            <StructuredList>
              <StructuredList.Item
                name={`${state.review.lineManager.firstName}'s End of Year Summary`}
                description={`This is the ${state.review.lineManager.firstName}'s summary of ${state.review.reviewee.firstName}'s performance during the academic year.`}
              >
                {getEditor(
                  _review.lineManagerSummary,
                  value => updateReview({ lineManagerSummary: value }),
                  state.isLineManager,
                  `No summary has been entered by ${state.review.lineManager.firstName} yet.`
                )}
              </StructuredList.Item>
            </StructuredList>
            <StructuredList>
              <StructuredList.Item
                name="Overall Performance Rating"
                description={`How was ${state.review.reviewee.firstName}'s overall performance during the ${state.review.academicYear} academic year?`}
              >
                <RadioList
                  disabled={state.isReviewee}
                  value={state.review.rating}
                  onChange={value => updateReview({ rating: 1 })}
                  items={arrays.configConstantsToArray(
                    Constants.PERFORMANCE_RATING
                  )}
                  fluid
                />
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      </Wizard.Step>
      <Wizard.Step icon="save" title="Summary & Save">
        <Card>
          <Card.Body>
            <p>
              Please review the details added below. If you are satisfied,
              please click the 'Finish' button at the bottom of the page to save
              the review. Please note that the review has not been saved between
              steps.
            </p>
          </Card.Body>
        </Card>
        <ReviewSummary />
      </Wizard.Step>
    </Wizard>
  );
};

export default ReviewEditor;
