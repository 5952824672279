import { BeehiveReport, IBeehiveAction } from "types/common/action.types";
import consentActions from "../../actions/consentActions";


interface IConsentReportsState {
  loading: boolean;
  error: string | null;
  reportData: BeehiveReport | null,
}

const INITIAL_STATE: IConsentReportsState = {
  loading: false,
  error: null,
  reportData: null,
};


const consentReportsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IConsentReportsState => {

  const { CONSENT_RESPONSEREPORT } = consentActions.types;

  switch (action.type) {
    case CONSENT_RESPONSEREPORT.START:
      return { ...state, loading: true, error: null };

    case CONSENT_RESPONSEREPORT.SUCCESS:
      var newState = { ...state, reportData: action.payload, loading: false };
      return newState;

    case CONSENT_RESPONSEREPORT.FAILED:
      return {
        ...state,
        loading: false,
        error: "There was a problem running the report",
      };

    default:
      return state;
  }
};

export default consentReportsReducer;
