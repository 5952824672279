import React, { useEffect, useState } from "react";
import { DetailLabel, DateRange, Spacing, neutral, Left, Right, Size, Swatches, Button } from "ui-kit";
import styled from "styled-components";
import Moment from "react-moment";
import EventSignupButton from "./eventSignupButton";
import { CalendarEventAppListView, CalendarEventDateListView } from "areas/calendar/types/calendarResponses.types";
import { CalendarEvent } from "areas/calendar/reducers/calendarEventReducer";
import moment from "moment";


const Wrapper = styled.div<{ openOnClick?: boolean }>`
  display: flex;
  flex-grow: 1;
  align-items: top;
  padding: ${Spacing.Medium}px;
  background: ${neutral[200]};

  .pretty-date {
    margin-right: ${Spacing.Large}px;
  }

  .left {
    margin-right: ${Spacing.Default}px;
    flex-grow: 1;
  }

  .right {
    min-width: 140px;
    flex-shrink: 0;
    width: 140px;
  }

  ${props => props.openOnClick && `
    cursor: pointer;
  `}
`;


interface IEventHeadingProps {
  event: CalendarEventAppListView | CalendarEvent;
  onClick?: () => void;
  hideSignupButton?: boolean;
  showDetails?: boolean;
}


const EventHeading: React.FC<IEventHeadingProps> = ({ event, onClick, hideSignupButton, showDetails }) => {

  const [_event, _setEvent] = useState<CalendarEventAppListView | CalendarEvent>(event);

  const handleClick = () => {
    onClick?.();
  };

  useEffect(() => {
    _setEvent(event);
  }, [event]);

  const formatDate = (dates: CalendarEventDateListView[]) => {
    var date = dates?.[0];

    if (!date) {
      return null;
    }

    if (dates.length > 1) {
      return (
        <span>
          Starts{" "}
          <Moment date={date.starts} format="dddd, Do MMMM YYYY [at] HH:mma" />
        </span>
      );
    } else {
      return (
        <>
          <DateRange start={date.starts} end={date.ends} />
        </>
      );
    }
  };

  const signupStillOpen = () => {
    return (
      _event.allowSignupTo == null ||
      moment(_event.allowSignupTo).isAfter(moment())
    )
  }

  return (
    <Wrapper>
      <Left>
        <DetailLabel
          bold
          label={_event?.title}
          sub={formatDate(_event?.dates)}
        />
      </Left>
      <Right>
        {!hideSignupButton && signupStillOpen() && (
          <EventSignupButton event={_event} onClick={handleClick} />
        )}
        {(hideSignupButton || !signupStillOpen()) && showDetails && (
          <Button
            text={"Details"}
            size={Size.Small}
            onClick={onClick}
            color={Swatches.Primary}
            fluid
          />
        )}
      </Right>
    </Wrapper>
  );
};

export default EventHeading;
