import React from "react";
import { useNavigate } from "react-router-dom";


const Unauthorized: React.FC = () => {

  const navigate = useNavigate();

  return (
    <>
      <p>Unauthorized Page</p>
      <button onClick={() => navigate(-1)}>Back</button>
    </>
  );
};

export default Unauthorized;
