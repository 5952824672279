import {
  StructuredList,
  TextInput,
  TextInputType,
  Spacing,
  Card,
  Dropdown,
  Text
} from "ui-kit";
import styled from "styled-components";
import { smartTaskData } from "./newSmartTaskFormWrapper";
import { EventName } from "areas/behaviour/types/smartTasks/smartTaskShared.types";
import constants from "configuration/constants";


const ConditionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${Spacing.Medium}px;
  .text {
    white-space: nowrap;
  }
`;


interface ISmartTaskDetailsProps {
  smartTask: smartTaskData
  setSmartTask: (data: smartTaskData) => void;
  schoolId: number;
  disabled?: boolean;
}


const SmartTaskDetails: React.FC<ISmartTaskDetailsProps> = ({ smartTask, setSmartTask, schoolId, disabled }) => {


  const hideType = () => {
    const school = constants.SCHOOLS.find(school => school.id == schoolId);
    return school?.isPrimary;
  }

  const getTaskType = (smartTaskType: EventName) => {
    switch (smartTaskType) {
      case EventName.RewardPoints:
        return "Reward";
      case EventName.BehaviourPoints:
        return "Behaviour";
      case EventName.Event:
        return "Event";
    }
  }

  return (
    <>
      <Card title="Smart Task">
        <Card.Body>
          
          <StructuredList.Item
            required
            name="Name"
            description="Name of the smart task"
          >
            <TextInput
              placeholder="E.g. 10 rewards in a week"
              fluid
              type={TextInputType.String}
              value={smartTask.name}
              onChange={value => setSmartTask({ ...smartTask, name: value })}
              disabled={disabled}
            />
          </StructuredList.Item>

          <StructuredList.Item
            required
            name="Type"
            description="Type of the smart task (cannot be changed once triggered)"
            hide={hideType()}
          >
            {!smartTask.isTriggered ? (
            <Dropdown
              placeholder="Please choose a smart task type..."
              fluid
              value={smartTask?.eventNameId}
              onChange={value => setSmartTask({ ...smartTask, eventNameId: value })
              }
            >
              {/* <Dropdown.Item 
                label="Behaviour Points" 
                value={EventName.BehaviourPoints} 
              /> */}
              <Dropdown.Item 
                label="Reward Points" 
                value={EventName.RewardPoints} 
              />
              <Dropdown.Item
                label="Calendar Events"
                value={EventName.Event}
              />
            </Dropdown>
            ) : (
              <Text>{getTaskType(smartTask.eventNameId)}</Text>
            )}
          </StructuredList.Item>

        </Card.Body>
      </Card>
    </>
  );
};

export default SmartTaskDetails;
