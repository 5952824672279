import { StructuredList } from "ui-kit";
import { ICateringReportProps } from ".";
import { SchoolDropdown } from "sharedComponents/common";
import TutorGroupDropdown from "../tutorGroupDropdown";


const PupilBalanceReport: React.FC<ICateringReportProps> = ({ filter, setFilter }) => {

  return (
    <>
      <StructuredList.Item name="School">
        <SchoolDropdown
          initialValue={filter?.schoolId}
          onChange={value => setFilter({ ...filter, schoolId: value })}
          filter={(x) => x.enableLunchCredits}
          fluid
        />
      </StructuredList.Item>
      <StructuredList.Item name="Tutor Group">
        <TutorGroupDropdown
          schoolId={filter?.schoolId}
          onChange={tutorGroup => setFilter({ ...filter, tutorGroupId: tutorGroup?.id })}
          fluid
          includeAllOption
        />
      </StructuredList.Item>
    </>
  )
}


export default PupilBalanceReport;