import React from "react";
import styled from "styled-components";
import { Icon, Size, getFontSize } from "../index";

export interface ISpinnerProps {
  size?: Size;
  className?: string;
  color?: string;
  style?: React.CSSProperties;
}

const Wrapper = styled.span<{ size?: number }>`
  position: relative;
  margin: 0;
  padding: 0;
  font-size: ${props =>
    props.size ? getFontSize(props.size) + "rem" : "1rem"};

  .icon {
    animation: spin infinite 2s linear;
  }
`;

const Spinner: React.FC<ISpinnerProps> = ({ size, className, color, style }) => {
  return (
    <Wrapper className={`spinner ${className ? className : ""}`} size={size} style={style}>
      <Icon value="circle-notch" size={size} color={color} spin />
    </Wrapper>
  );
};

export default Spinner;
