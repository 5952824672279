import React from "react";
import { Routes, Route } from "react-router-dom";
import ConsentForm from "./consentForm";
import ConsentForms from "./consentForms";
import ConsentResponse from "./consentResponse";
import ConsentFormAdminView from "./admin/consentFormAdminView";
import CustomRoute from "sharedComponents/layout/routes/customRoute";
import { Constants } from "configuration";
import { consentRoutes } from "areas/administration/adminRoutes";


const Consent = () => {
  
  return (
    <Routes>
      <Route
        index
        element={
          <CustomRoute
            users={[Constants.USER_TYPES.STAFF, Constants.USER_TYPES.PARENT]}
          >
            <ConsentForms />
          </CustomRoute>
        }
      />

      <Route
        path={consentRoutes.form}
        element={
          <CustomRoute
            users={[Constants.USER_TYPES.STAFF, Constants.USER_TYPES.PARENT]}
          >
            <ConsentForm />
          </CustomRoute>
        }
      />

      <Route
        path={consentRoutes.response}
        element={
          <CustomRoute
            users={[Constants.USER_TYPES.STAFF, Constants.USER_TYPES.PARENT]}
          >
            <ConsentResponse />
          </CustomRoute>
        }
      />

      <Route
        path={consentRoutes.consentFormAdmin}
        element={
          <CustomRoute>
            <ConsentFormAdminView />
          </CustomRoute>
        }
      />
    </Routes>
  );
};

export default Consent;
