import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Swatches,
  StructuredList,
  TextInput,
  Checkbox,
  Button,
  Card,
  ActionBar,
  Right,
} from "ui-kit";
import LayoutGeneratorOptions from "../layoutGenerator/layoutGeneratorOptions";
import LayoutGeneratorOption from "../layoutGenerator/layoutGeneratorOption";
import { CUSTOM_LAYOUT } from "../../../../constants/classViewer/classViewerLayout";

const ClassLayoutCreatorForm = ({
  group,
  selectedRoom,
  selectedRoomLayout,
  handleCreateClassLayout,
  closeLayoutCreator,
  working,
}) => {
  const [layoutName, setLayoutName] = useState("");
  const [makeDefault, setMakeDefault] = useState(false);
  const [selectedLayoutType, setSelectedLayoutType] = useState(null);
  const [orderValue, setOrderValue] = useState(1);
  const [disperseValue, setDisperseValue] = useState(0);

  const handleOrderChange = value => {
    setOrderValue(value);
  };

  const handleDisperseChange = event => {
    setDisperseValue(Number(event.target.value));
  };

  const onMakeDefaultLayoutChange = ({ checked }) => {
    setMakeDefault(checked);
  };

  return (
    <>
      <Card title="New Seating Plan">
        <Card.Body>
          <StructuredList.Item
            name="Seating Plan Name"
            description="Give your seating plan a name"
            required
          >
            <TextInput
              placeholder="e.g. Friday Period 2"
              fluid
              value={layoutName}
              onChange={value => setLayoutName(value)}
            />
          </StructuredList.Item>

          {group.layouts && group.layouts.length >= 1 && (
            <StructuredList.Item
              name="Default Seating Plan"
              description="This will set the seating plan as the default plan for the class group. 
                A default seating plan will be the plan that will load first when you select a class 
                group and will appear at the top of your seating plan list."
            >
              <Checkbox
                text="Make this the default seating plan"
                checked={makeDefault}
                onChange={onMakeDefaultLayoutChange}
              />
            </StructuredList.Item>
          )}

          <StructuredList.Item
            name="Generate a Seating Plan"
            description="To generate a seating plan, choose a plan from the options or
              choose the Create Your Own Custom Seating Plan option to create a custom
              seating plan."
            required
          >
            <LayoutGeneratorOptions
              selectedLayoutType={selectedLayoutType}
              onSelectLayoutType={setSelectedLayoutType}
              orderValue={orderValue}
              handleOrderChange={handleOrderChange}
              disperseValue={disperseValue}
              handleDisperseChange={handleDisperseChange}
            >
              <LayoutGeneratorOption
                key={CUSTOM_LAYOUT.key}
                layout={CUSTOM_LAYOUT}
                selectedLayoutId={selectedLayoutType}
                onSelectLayoutType={setSelectedLayoutType}
                orderValue={orderValue}
                handleOrderChange={handleOrderChange}
                disperseValue={disperseValue}
                handleDisperseChange={handleDisperseChange}
              />
            </LayoutGeneratorOptions>
          </StructuredList.Item>
        </Card.Body>
      </Card>

      <ActionBar>
        <Right>
          <Button
            color={Swatches.Success}
            text="Create Seating Plan"
            disabled={
              selectedRoom === null ||
              selectedRoomLayout === null ||
              layoutName.trim() === "" ||
              selectedLayoutType === null
            }
            onClick={() =>
              handleCreateClassLayout(
                layoutName,
                selectedLayoutType,
                orderValue,
                disperseValue,
                makeDefault,
                selectedRoomLayout.id
              )
            }
            working={working}
          />

          {group.layouts && group.layouts.length > 0 && (
            <Button
              text="Cancel"
              color={Swatches.Low}
              onClick={closeLayoutCreator}
              working={working}
            />
          )}
        </Right>
      </ActionBar>
    </>
  );
};

ClassLayoutCreatorForm.propTypes = {
  classGroup: PropTypes.object,
  selectedRoom: PropTypes.object,
  handleCreateClassLayout: PropTypes.func,
  closeLayoutCreator: PropTypes.func,
};

export default ClassLayoutCreatorForm;
