import { IBeehiveAction } from "types/common/action.types";
import registrationActions from "../actions/registrationActions";
import { PreflightRegistrationResponse } from "../types/registrationResponse.types";

interface IRegistrationState {
  preflightData: PreflightRegistrationResponse | null;
  working: boolean;
  error: string | null;
  preflightError: string | null;
  preflighting: boolean;
}

const INITIAL_STATE: IRegistrationState = {
  preflightData: null,
  working: false,
  error: null,
  preflightError: null,
  preflighting: false,
};


const registrationReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IRegistrationState => {

  const {
    REGISTRATION_REQUEST,
    REGISTRATION_PREFLIGHT,
    REGISTRATION_VERIFY,
    REGISTRATION_ACTIVATE,
  } = registrationActions.types;

  switch (action.type) {
    case REGISTRATION_PREFLIGHT.START:
      return { ...INITIAL_STATE, preflighting: true };

    case REGISTRATION_PREFLIGHT.SUCCESS:
      return { ...INITIAL_STATE, preflightData: action.payload };

    case REGISTRATION_PREFLIGHT.FAILED:
      return {
        ...INITIAL_STATE,
        preflightError: action.payload,
      };

    case REGISTRATION_REQUEST.START:
    case REGISTRATION_PREFLIGHT.START:
    case REGISTRATION_VERIFY.START:
    case REGISTRATION_ACTIVATE.START:
      return { ...INITIAL_STATE, working: true };

    case REGISTRATION_REQUEST.SUCCESS:
    case REGISTRATION_VERIFY.SUCCESS:
    case REGISTRATION_ACTIVATE.SUCCESS:
      return { ...INITIAL_STATE, working: false };

    case REGISTRATION_REQUEST.FAILED:
      return {
        ...INITIAL_STATE,
        error:
          "There was a problem sending the registration email. This may be because your email was not recognised or you are already registered. Please check your details and try again.",
        working: false,
      };

    case REGISTRATION_VERIFY.FAILED:
      return {
        ...INITIAL_STATE,
        error: "There was a problem verifying your details.",
        working: false,
      };
    case REGISTRATION_ACTIVATE.FAILED:
      return {
        ...INITIAL_STATE,
        error: "There was a problem activating your registration",
        working: false,
      };

    default:
      return state;
  }
};

export default registrationReducer;
