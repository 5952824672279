import React, { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "ui-kit";
import tutorGroupService from "areas/payments/actions/tutorGroupActions";
import { GroupListView } from "types/users/userGroups.types";
import { GroupType } from "types/planner/groups.types";

export interface TutorGroupOption {
  label: string;
  sub: string;
  value: GroupListView;
}

const ALL_OPTION: TutorGroupOption = {
  label: "All Tutor Groups",
  sub: "",
  value: {
    id: -1,
    name: "",
    friendlyName: "",
    school: undefined,
    schoolId: 0,
    summary: "",
    code: "",
    type: GroupType.TutorGroup,
    isPrivate: false,
    allowSignup: false,
    isFavourite: false,
    isMyGroup: false,
    yearGroupName: "",
    subjectName: "",
    isActive: false,
  },
};

interface ITutorGroupDropdownProps {
  schoolId: number;
  onChange?: (tutorGroup: GroupListView) => void;
  fluid?: boolean;
  includeAllOption?: boolean;
  showYear?: boolean;
}

const TutorGroupDropdown: React.FC<ITutorGroupDropdownProps> = ({
  schoolId,
  onChange,
  fluid,
  includeAllOption,
  showYear,
}) => {
  const [items, setItems] = useState<TutorGroupOption[]>([ALL_OPTION]);
  const [selectedGroup, setSelectedGroup] = useState<GroupListView>(
    items[0].value
  );
  const [loadingGroups, setLoadingGroups] = useState<boolean>(false);

  const getTutorGroups = (schoolId: number) => {
    setLoadingGroups(true);
    tutorGroupService
      .getTutorGroupsBySchool(schoolId)
      .then((data: GroupListView[]) => {
        var _items = [
          ...data.map((d: GroupListView) => ({
            label: d.name,
            sub: showYear ? d.yearGroupName : null,
            value: d,
          })),
        ];

        if (includeAllOption) {
          _items = [ALL_OPTION, ..._items];
        }

        setItems(_items);

        if (_items.length) {
          setSelectedGroup(_items[0].value);
        }
        setLoadingGroups(false);
      })
      .catch(error => {
        setLoadingGroups(false);
      });
  };

  useEffect(() => {
    // console.log(schoolId);
    schoolId && getTutorGroups(schoolId);
  }, [schoolId]);

  useEffect(() => {
    onChange?.(selectedGroup);
  }, [selectedGroup]);

  const handleTutorGroupChange = (value: GroupListView) => {
    setSelectedGroup(value);
  };

  return (
    <Dropdown
      placeholder="Tutor Group"
      items={items}
      value={selectedGroup}
      onChange={handleTutorGroupChange}
      loading={loadingGroups}
      fluid={fluid}
    />
  );
};

export default TutorGroupDropdown;
