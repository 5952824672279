import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { TimetableAppView } from "../types/timetable/timetableResponse.types";
import { TermListView } from "areas/send/types/passportResponse.types";

const builder = new actionTypeBuilder("timetable");

const types = {
  TIMETABLE_GETTIMETABLE: builder.build("TIMETABLE_GETTIMETABLE"),
  TIMETABLE_GETTERMS: builder.build("TIMETABLE_GETTERMS"),
};


const getTimetable = (userId: string, callback?: callbackType<TimetableAppView>) =>
  action<TimetableAppView>(
    () => client.get(`planner/users/${userId}/timetable`),
    types.TIMETABLE_GETTIMETABLE,
    callback
  );


const getTerms = (academicYearId: number, callback?: callbackType<TermListView[]>) =>
  action<TermListView[]>(
    () => client.get(`planner/academicyears/${academicYearId}/terms`),
    types.TIMETABLE_GETTERMS,
    callback
  );


const timetableActions = {
  types,
  getTimetable,
  getTerms,
};


export default timetableActions;
