import React from "react";
import {
  StructuredList,
  fontStyle,
  typescale,
  neutral,
  textFont,
} from "ui-kit";
import styled from "styled-components";


const Wrapper = styled.p`
  ${fontStyle(textFont.roman, typescale.paragraph, neutral[700])}
`;


export type DetentionDataType = {
  dataType: string;
  dataValue: React.ReactNode;
};

interface IReadOnlyDataListProps {
  data: DetentionDataType[];
}


const ReadOnlyDataList: React.FC<IReadOnlyDataListProps> = ({ data }) => {

  return (
    <StructuredList>
      {data.map((dataItem, index) => (
        <StructuredList.Item key={index} name={dataItem.dataType}>
          <Wrapper>{dataItem.dataValue}</Wrapper>
        </StructuredList.Item>
      ))}
    </StructuredList>
  );
};

export default ReadOnlyDataList;
