import React from "react";
import { ReactComponent as LionSvg } from "assets/primary/rockets/lion.svg";
import { IPrimaryRocketCharacterProps } from "./primaryRocket";


export const Lion: React.FC<IPrimaryRocketCharacterProps> = ({ className }) => {

  return <LionSvg className={className} />;
};

export default Lion;
