import React, { useState } from "react";
import { arrays } from "utils";
import {
  Card,
  EmptyMessage,
  Table,
  Currency,
  Button,
  Swatches,
  Size,
  ActionBar,
  Label,
  Right,
} from "ui-kit";
import { Constants } from "configuration";
import flyoutActions from "actions/ui/flyouts";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { Avatar, StatusView, RoleView } from "sharedComponents/common";
import RefundFlyout from "./refundFlyout";
import { OrderDetailView, RefundView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { UserTinyView } from "types/users/userListViews.types";


interface IRefundsTableProps {
  order: OrderDetailView;
}


const RefundsTable: React.FC<IRefundsTableProps> = ({ order }) => {

  const [selectedRefund, setSelectedRefund] = useState<RefundView | null>(null);
  const navigate = useNavigate();

  const handleUserClicked = (user: UserTinyView) => {
    navigate(`/main/shopkeeper/users/${user.id}`);
  };

  const handleRowClicked = (refund: RefundView) => {
    setSelectedRefund(refund);
    flyoutActions.openFlyout(Constants.FLYOUTS.REFUND);
  };

  const handleCreateRefund = () => {
    flyoutActions.openFlyout(Constants.FLYOUTS.CREATEREFUND);
  };

  if (arrays.isEmpty(order?.refunds)) {
    return (
      <EmptyMessage
        icon="receipt"
        title="No Refunds Found"
        summary="There are no refunds for this order"
        cover
      >
        <RoleView roles={[Constants.ROLES.SHOPKEEPER_ADMINISTRATOR]}>
          <Button
            text="Create Refund"
            color={Swatches.Primary}
            onClick={handleCreateRefund}
          />
        </RoleView>
      </EmptyMessage>
    );
  }

  return (
    <>
      <Card>
        <Card.Body noPad>
          <Table>
            <Table.Header>
              <Table.HeaderCell width={1}>ID</Table.HeaderCell>
              <Table.HeaderCell width={1}>Date</Table.HeaderCell>
              <Table.HeaderCell width={1}>By</Table.HeaderCell>
              <Table.HeaderCell right width={1}>
                Total
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {order?.refunds.map((refund: RefundView, index: number) => (
                <Table.Row key={index} onClick={() => handleRowClicked(refund)}>
                  <Table.Cell>{refund.id}</Table.Cell>
                  <Table.Cell width={1}>
                    <Moment
                      date={refund.createdDate}
                      format="DD/MM/YYYY HH:mm"
                    />
                  </Table.Cell>

                  <Table.Cell>
                    <Avatar
                      user={refund.refundedBy}
                      size={Size.Small}
                      onClick={() => handleUserClicked(refund.refundedBy)}
                    />
                  </Table.Cell>
                  <Table.Cell right width={1}>
                    <Label bold>
                      <Currency value={refund.total} />
                    </Label>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card.Body>
        <StatusView status={1}>
          <p>p</p>
          <p>p</p>
        </StatusView>
        <Card.Footer>
          <RoleView roles={[Constants.ROLES.SHOPKEEPER_ADMINISTRATOR]}>
            {order.refundable > 0 && (
              <StatusView status={order.status}>
                <StatusView.Item
                  status={Constants.ORDER_STATUS.COMPLETE.value}
                >
                  <ActionBar low>
                    <Right>
                      <Button
                        text="Create Refund"
                        color={Swatches.Primary}
                        onClick={handleCreateRefund}
                      />
                    </Right>
                  </ActionBar>
                </StatusView.Item>
                <StatusView.Item></StatusView.Item>
              </StatusView>
            )}
          </RoleView>
        </Card.Footer>
      </Card>

      <RefundFlyout refund={selectedRefund} />
    </>
  );
};

export default RefundsTable;
