import React, { useState, useEffect, useRef } from "react";
import AddRewardForm from "./addRewardForm";
import { Loader, Size } from "ui-kit";
import {
  ActionBar,
  Button,
  Card,
  Dropdown,
  getFontSize,
  List,
  Message,
  Spacing,
  Sub,
  Subtitle,
  Swatches,
  Tooltip,
} from "ui-kit";
import styled from "styled-components";
import { todayDateTime } from "utils/dateTime";
import moment from "moment";
import { danger, neutral } from "ui-kit/common/colors";
import Tippy from "@tippyjs/react";
import schoolActions from "areas/planner/actions/school/schoolActions";
import { addGroupIdToPayload } from "areas/planner/utils/group";
import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import StudentModal from "sharedComponents/common/users/studentModal";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import HelpText from "sharedComponents/common/helpText";
import { useAppSelector } from "reducers/hooks";
import { GroupType } from "types/users/userGroups.types";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";
import { SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { CreateRewardCommand } from "areas/behaviour/types/behaviourRequest.types";


const RewardsWrapper = styled.div`
  display: flex;

  .help-text {
    border-bottom: 1px solid ${neutral[400]};
    padding-bottom: ${Spacing.Large}px;
  }

  .reward-form-wrapper {
    flex-grow: 2;
  }

  .multiple-reward-list {
    position: sticky;
    top: 0;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    max-width: 300px;
    margin-left: ${Spacing.ExtraLarge}px;

    .list .reward-list-item:hover {
      .remove-reward-button {
        color: ${neutral[100]};
      }
    }

    .list .reward-list-item .remove-reward-button {
      font-size: ${getFontSize(Size.ExtraLarge)}rem;
      background-color: unset;
      border: none;
      margin: 0;
      padding: 0;
      color: ${neutral[500]};
      cursor: pointer;
      line-height: 1rem;

      &:hover {
        color: ${danger};
      }
    }

    .action-bar {
      margin-top: ${Spacing.Medium}px;
      .button {
        padding-left: ${Spacing.Large}px;
        padding-right: ${Spacing.Large}px;
        flex: 1;
      }
    }
  }
`;


interface IAddRewardModalProps {
  open: boolean;
  handleCloseModal: () => void;
  student: PlannerStudentDetailView;
  selectedCategory: SchoolBehaviourCategoryListView;
  rewardCategories: SchoolBehaviourCategoryListView[];
  groupId?: number;
  groupType?: GroupType;
}

export interface RewardDetails {
  category: SchoolBehaviourCategoryListView;
  publicNotes: string;
  internalNotes: string;
  points: number;
  initialAction: RewardDetailsInitialAction;
  incidentDate: string;
  housePointsChecked: boolean;
  housePoints: number;
  class: RewardDetailsClass;
}

export interface RewardDetailsClass {
  id: number;
  type: GroupType;
}

export interface RewardDetailsInitialAction {
  initialActionId: number;
  initialActionName: string;
}


const AddRewardModal: React.FC<IAddRewardModalProps> = ({
  open,
  handleCloseModal,
  student,
  selectedCategory,
  rewardCategories,
  groupId,
  groupType,
}) => {

  const ref = useRef<HTMLDivElement>();
  const buttonRef = useRef<HTMLButtonElement>();

  const { addRewardError, loading } = useAppSelector(state => state.reward);
  const { schoolInformation } = useAppSelector(state => state.school);

  const [rewardCategoriesArray, setRewardCategoriesArray] = useState<IDropdownDataItemProps<string, number, null>[]>([]);

  const [rewardDetails, setRewardDetails] = useState<RewardDetails>({
    category: selectedCategory,
    publicNotes: "",
    internalNotes: "",
    points: selectedCategory.points,
    initialAction: {
      initialActionId: null,
      initialActionName: "",
    },
    incidentDate: todayDateTime(),
    housePointsChecked: false,
    housePoints: null,
    class: groupId ? { id: groupId, type: groupType } : null,
  });
  const [rewardsToAdd, setRewardsToAdd] = useState<RewardDetails[]>([]);
  const [rewardAlreadyGivenOnDate, setRewardAlreadyGivenOnDate] = useState<boolean>(false);
  const [updateRewardIndex, setUpdateRewardIndex] = useState<number | null>(null);

  useEffect(() => {
    if (updateRewardIndex === null) {
      const reward = rewardsToAdd.find(
        reward => rewardDetails.category?.id === reward.category?.id
      );

      if (reward && reward.class?.id === rewardDetails.class?.id) {
        const date1 = moment(reward.incidentDate).format("DD/MM/YYYY");
        const date2 = moment(rewardDetails.incidentDate).format("DD/MM/YYYY");

        if (moment(date1).isSame(date2)) {
          setRewardAlreadyGivenOnDate(true);
        } else {
          setRewardAlreadyGivenOnDate(false);
        }
      } else {
        setRewardAlreadyGivenOnDate(false);
      }
    } else {
      setRewardAlreadyGivenOnDate(false);
    }
  }, [rewardDetails]);

  useEffect(() => {
    if (student) {
      if (!schoolInformation || schoolInformation.id !== student.school.id) {
        schoolActions.getSchoolInformation(student.school.id);
      }
    }
  }, [student]);

  useEffect(() => {
    if (rewardCategories) {
      const categories = rewardCategories.map(category => ({
        key: category.id,
        label: `${category.codeDisplayName} - ${category.behaviourCategoryName}`,
        value: category.id,
      }));
      setRewardCategoriesArray(categories);
    } else {
      setRewardCategoriesArray([]);
    }
  }, [rewardCategories]);

  const callback = () => {
    handleCloseModal();
  };

  const resetRewardDetails = () => {
    setRewardDetails({
      category: null,
      publicNotes: "",
      internalNotes: "",
      points: 0,
      initialAction: rewardDetails.initialAction,
      incidentDate: todayDateTime(),
      housePointsChecked: false,
      housePoints: null,
      class: rewardDetails.class,
    });
  };

  const handleAddRewards = () => {
    const getRewardCommand = (reward: RewardDetails)=> {
      let classId = null;
      let classType = null;
      if (reward.class.id !== -1) {
        classId = reward.class.id;
        classType = reward.class.type;
      }

      let newReward: CreateRewardCommand = {
        studentId: student.id,
        description: reward.internalNotes,
        publicNotes: reward.publicNotes,
        categoryId: reward.category.id,
        behaviourCodeId: reward.category.behaviourCodeId,
        points: reward.points,
        initialActionId: reward.initialAction.initialActionId,
        housePoints: reward.housePoints,
        incidentDate: reward.incidentDate,
      };

      if (classId) {
        newReward = addGroupIdToPayload(newReward, classId, classType);
      }

      return newReward;
    };

    const rewards: CreateRewardCommand[] = [];
    if (
      rewardsToAdd.length > 0 &&
      window.confirm(
        `This will issue ${rewardsToAdd.length} rewards to ${student.firstName} ${student.lastName}. Are you sure?`
      )
    ) {
      rewardsToAdd.forEach(reward => {
        rewards.push(getRewardCommand(reward));
      });
      rewardActions.addRewards(
        student.id,
        { createRewardCommands: rewards },
        callback
      );
    } else {
      rewards.push(getRewardCommand(rewardDetails));
      rewardActions.addRewards(
        student.id,
        { createRewardCommands: rewards },
        callback
      );
    }
  };

  const handleDiscardAllRewards = () => {
    if (
      window.confirm(
        "Are you sure you want to discard all of the rewards to be issued?"
      )
    ) {
      setRewardsToAdd([]);
      handleCloseModal();
    }
  };

  const handleSaveAndAddAnother = () => {
    let tempRewards = rewardsToAdd.slice();
    const tempCurrentReward = JSON.parse(
      JSON.stringify({
        ...rewardDetails,
      })
    );
    tempRewards.push(tempCurrentReward);
    setRewardsToAdd(tempRewards);

    resetRewardDetails();

    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleRewardCategoryChange = (categoryId: number) => {
    const category = rewardCategories.find(
      category => categoryId === category.id
    );

    setRewardDetails({
      ...rewardDetails,
      points: category.points,
      category: category,
    });
  };

  const onClickRemoveReward = (rewardIndex: number) => {
    const tempRewardsToAdd = rewardsToAdd.slice();
    tempRewardsToAdd.splice(rewardIndex, 1);
    setRewardsToAdd(tempRewardsToAdd);
    if (updateRewardIndex === rewardIndex) {
      resetRewardDetails();
      setUpdateRewardIndex(null);
    }
  };

  const handleOnClickReward = (rewardIndex: number) => {
    setUpdateRewardIndex(rewardIndex);
    const reward = rewardsToAdd[rewardIndex];
    setRewardDetails({
      category: reward.category,
      publicNotes: reward.publicNotes,
      internalNotes: reward.internalNotes,
      points: reward.points,
      initialAction: reward.initialAction,
      incidentDate: reward.incidentDate,
      housePointsChecked: reward.housePointsChecked,
      housePoints: reward.housePoints,
      class: reward.class,
    });
  };

  const handleUpdateReward = () => {
    const tempRewardsToAdd = rewardsToAdd.slice();
    tempRewardsToAdd[updateRewardIndex] = rewardDetails;
    setRewardsToAdd(tempRewardsToAdd);
    setUpdateRewardIndex(null);
    resetRewardDetails();
  };

  const cancelUpdate = () => {
    setUpdateRewardIndex(null);
    resetRewardDetails();
  };

  return (
    <StudentModal
      title="Reward"
      onClose={handleCloseModal}
      open={open}
      height="90%"
      width="85%"
      student={student}
    >
      {loading ? (
        <Loader size={Size.Medium} fluid />
      ) : (
        <>
          <ApiExceptionMessage error={addRewardError} />

          <RewardsWrapper ref={ref}>
            <div className="reward-form-wrapper">
              <HelpText>
                You can add one or more rewards for {student.firstName}{" "}
                {student.lastName}. To add one reward, click on{" "}
                <b>Issue Reward</b>. To add multiple rewards, click on{" "}
                <b>Add and Create Another</b> and then click on{" "}
                <b>Issue All Rewards</b> on the right. Multiple rewards will not
                be issued until you click on <b>Issue All Rewards</b>.
              </HelpText>

              <Card title="Reward Category">
                <Card.Body>
                  {rewardAlreadyGivenOnDate && (
                    <Message
                      text="A reward of this type has already been added on the chosen date. Are you sure?"
                      color={Swatches.Cancel}
                    />
                  )}
                  <Dropdown
                    items={rewardCategoriesArray}
                    value={rewardDetails.category?.id}
                    onChange={value => handleRewardCategoryChange(value)}
                    placeholder="Reward Category"
                    fluid
                  />
                </Card.Body>
              </Card>

              <AddRewardForm
                rewardDetails={rewardDetails}
                handleSetRewardDetails={reward => setRewardDetails(reward)}
                isPrimary={schoolInformation?.isPrimary}
                belongsToHouse={student.house}
                group={groupId ? { id: groupId, type: groupType } : null}
                studentId={student.id}
                rewardsToAdd={rewardsToAdd}
                handleSaveReward={handleSaveAndAddAnother}
                handleIssueReward={handleAddRewards}
                updatingReward={updateRewardIndex !== null}
                handleUpdateReward={handleUpdateReward}
                handleCancelUpdate={cancelUpdate}
                handleCloseModal={handleCloseModal}
              />
            </div>

            {rewardsToAdd?.length > 0 && (
              <div className="multiple-reward-list">
                <Subtitle>Rewards Being Issued</Subtitle>
                <List>
                  {rewardsToAdd.map((reward, index) => (
                    <List.Item
                      key={index}
                      className="reward-list-item"
                      text={reward.category.codeDisplayName}
                      sub={reward.category.behaviourCategoryName}
                      right={
                        <>
                          <button
                            ref={buttonRef}
                            className="remove-reward-button"
                            onClick={event => {
                              event.stopPropagation();
                              onClickRemoveReward(index);
                            }}
                          >
                            &#10799;
                          </button>
                          <Tippy
                            reference={buttonRef}
                            content={<Tooltip title="Remove Reward" />}
                          />
                        </>
                      }
                      onClick={() => handleOnClickReward(index)}
                      selected={updateRewardIndex === index}
                    />
                  ))}
                </List>

                <ActionBar low>
                  <Button
                    className="issue-rewards-button"
                    text="Issue All Rewards"
                    color={Swatches.Success}
                    size={Size.Small}
                    disabled={
                      rewardDetails.initialAction.initialActionId === null ||
                      rewardDetails.class === null
                    }
                    onClick={handleAddRewards}
                  />

                  <Button
                    text="Discard All"
                    size={Size.Small}
                    onClick={handleDiscardAllRewards}
                  />
                </ActionBar>
                <br />
                <Sub>
                  Multiple rewards will not be issued until you click on{" "}
                  <b>Issue All Rewards</b>.
                </Sub>
              </div>
            )}
          </RewardsWrapper>
        </>
      )}
    </StudentModal>
  );
};


export default AddRewardModal;
