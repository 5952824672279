import { IBeehiveAction } from "types/common/action.types";
import feedbackActions from "../actions/feedbackActions";


export interface IFeedbackMessageState {
  feedbackMessage: {
    content: string | null;
    requiresReply: boolean;
  },
  actions: {
    load: { working: boolean, error: string | null },
    save: { working: boolean, error: string | null },
    delete: { working: boolean, error: string | null },
  },
}


const INITIAL_STATE: IFeedbackMessageState = {
  feedbackMessage: {
    content: null,
    requiresReply: false,
  },
  actions: {
    load: { working: false, error: null },
    save: { working: false, error: null },
    delete: { working: false, error: null },
  },
};

const feedbackMessageReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IFeedbackMessageState => {
  const {
    FEEDBACKMESSAGE_SAVE,
    FEEDBACKMESSAGE_CREATE,
    FEEDBACKMESSAGE_DELETE,
    FEEDBACKMESSAGE_SETCONTENT,
    FEEDBACKMESSAGE_SETREPLYREQUIREMENT,
  } = feedbackActions.types;

  switch (action.type) {
    case FEEDBACKMESSAGE_SETCONTENT:
      return {
        ...state,
        feedbackMessage: { 
          ...state.feedbackMessage, 
          content: action.payload 
        },
      };

    case FEEDBACKMESSAGE_SETREPLYREQUIREMENT:
      return {
        ...state,
        feedbackMessage: {
          ...state.feedbackMessage,
          requiresReply: action.payload,
        },
      };

    case FEEDBACKMESSAGE_SAVE.START:
    case FEEDBACKMESSAGE_CREATE.START:
      return {
        ...state,
        actions: { 
          ...state.actions, 
          save: { 
            working: true, 
            error: null 
          } 
        },
      };

    case FEEDBACKMESSAGE_SAVE.SUCCESS:
    case FEEDBACKMESSAGE_CREATE.SUCCESS:
      return {
        ...state,
        feedbackMessage: INITIAL_STATE.feedbackMessage,
        actions: { 
          ...state.actions, 
          save: { 
            working: false, 
            error: null 
          } 
        },
      };

    case FEEDBACKMESSAGE_SAVE.FAILED:
    case FEEDBACKMESSAGE_CREATE.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          save: {
            working: false,
            error: "There was a problem saving the message.",
          },
        },
      };

    case FEEDBACKMESSAGE_DELETE.START:
      return {
        ...state,
        actions: { 
          ...state.actions, 
          save: { 
            working: true, 
            error: null 
          } 
        },
      };

    case FEEDBACKMESSAGE_DELETE.SUCCESS:
      return {
        ...state,
        feedbackMessage: INITIAL_STATE.feedbackMessage,
        actions: { 
          ...state.actions, 
          save: { 
            working: false, 
            error: null 
          } 
        },
      };

    case FEEDBACKMESSAGE_DELETE.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          delete: {
            working: false,
            error: "There was a problem deleting the message.",
          },
        },
      };

    default:
      return state;
  }
};

export default feedbackMessageReducer;
