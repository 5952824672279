import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Subheader from "sharedComponents/layout/header/subheader";
import styled from "styled-components";
import {
  Title,
  TitleSize,
  Spacing,
  EmptyMessage,
  Size,
  neutral,
  Loader,
  BorderRadius,
  fontStyle,
  displayFont,
  typescale} from "ui-kit";
import SearchForm from "areas/search/searchForm";
import { Link } from "react-router-dom";
import { arrays } from "utils";
import { useSelector } from "react-redux";
import useInfiniteScroll from "react-infinite-scroll-hook";
import paymentActions from "areas/payments/actions/paymentsActions";
import { RootState } from "reducers/store";
import { ProductListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import ProductsTable from "../productsTable";


const ResultItem = styled.li`
  padding: ${Spacing.Medium}px;
  margin-top: ${Spacing.Small}px;
  margin-bottom: ${Spacing.Small}px !important;
  border-radius: ${BorderRadius.Default}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }

  &:hover {
    cursor: pointer;
    background: ${neutral[400]};
    transition: all ease 250ms;
  }
`;

const CountWrapper = styled.div`
  ${fontStyle(displayFont.roman, typescale.paragraph, neutral[600])}
`;


const ProductSearch = () => {

  const location = useLocation();
  const { loading, error, products, paging } = useSelector((state: RootState) => state.searchProducts);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const search = (pageIndex: number) => {
    if (searchTerm?.length > 0) {
      paymentActions.searchProducts(searchTerm, pageIndex);
    }
  };

  useEffect(() => {
    if (location) {
      setSearchTerm(location?.state?.searchTerm);
    }
  }, [location]);

  useEffect(() => {
    search(0);
  }, [searchTerm]);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const onPage = () => {
    search(paging?.pageIndex + 1);
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: paging?.pageNumber < paging?.totalPages,
    onLoadMore: onPage,
  });

  const handlePage = () => {
    search(paging.pageIndex + 1)
  };

  return (
    <>
      <Subheader>
        <Title size={TitleSize.H2} text="Shopkeeper" sub={`Product Search`} />
      </Subheader>
      <SearchForm
        initialValue={location?.state?.searchTerm}
        onSearch={handleSearch}
      />

      {error && (
        <EmptyMessage
          title="An error occured"
          summary="There was a problem searching the products"
          icon="times-circle"
        />
      )}

      {searchTerm && !loading && !error && arrays.isEmpty(products) && (
        <EmptyMessage
          title="No results"
          summary="No products were found"
          icon="shopping-bag"
        />
      )}

      {!arrays.isEmpty(products) && (
        <>
          <CountWrapper>
            <p>
              <b>{paging.totalRecords}</b> products found for '{searchTerm}'
            </p>
          </CountWrapper>

          <ProductsTable
            loading={loading}
            error={error != null}
            products={products}
            onPage={handlePage}
            paging={paging}
            showSchool
          />

          { paging?.pageNumber < paging?.totalPages && (
            <div ref={sentryRef}></div>
          )}
        </>
      )}
    </>
  );
};

export default ProductSearch;
