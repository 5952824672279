import customGroupReducer from "./customGroupReducer";
import groupActivityReducer from "./groupActivityReducer";
import groupPeriodsReducer from "./groupPeriodsReducer";
import groupReducer from "./groupReducer";
import groupTabMenuReducer from "./groupTabMenuReducer";

const groupReducers = {
  customGroup: customGroupReducer,
  groupActivity: groupActivityReducer,
  groupPeriods: groupPeriodsReducer,
  group: groupReducer,
  groupTabMenu: groupTabMenuReducer,
};

export default groupReducers;
