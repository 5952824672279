import defaults from "configuration/defaults";
import consentActions from "../../actions/consentActions";
import { IBeehiveAction } from "types/common/action.types";
import { PageInfo } from "types/common/paging.types";
import { ConsentResponseListView } from "areas/administration/types/consentResponses.types";


interface IAdminConsentResponsesState {
  loading: boolean;
  responses: ConsentResponseListView[],
  error: string | null;
  paging: PageInfo;
}

const CONSENT_STATE: IAdminConsentResponsesState = {
  loading: false,
  responses: [],
  error: null,
  paging: defaults.DEFAULT_PAGING,
};


const adminConsentResponsesReducer = (state = CONSENT_STATE, action: IBeehiveAction) : IAdminConsentResponsesState => {

  const { CONSENT_GETRESPONSESFORADMIN } = consentActions.types;

  switch (action.type) {
    case CONSENT_GETRESPONSESFORADMIN.START:
      return { ...state, loading: true, error: null };

    case CONSENT_GETRESPONSESFORADMIN.SUCCESS:
      return {
        ...state,
        responses:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.responses, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };

    case CONSENT_GETRESPONSESFORADMIN.FAILED:
      return {
        ...state,
        loading: false,
        responses: null,
        error: "There was a problem loading the consent responses",
      };

    default:
      return state;
  }
};

export default adminConsentResponsesReducer;
