import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { EmptyMessage } from "ui-kit";
import styled from "styled-components";
import Category from "./category";
import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { useAppSelector } from "reducers/hooks";
import { BehaviourCategoryGroupByListView, SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";
import { CategoryType } from "areas/analytics/types/analytics.shared";


const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;


interface ICategoriesProps {
  schoolId: number;
  handleAddPoint: (category: SchoolBehaviourCategoryListView) => void;
  categoryType: CategoryType;
}


const Categories: React.FC<ICategoriesProps> = ({ schoolId, handleAddPoint, categoryType }) => {

  const { rewardCategoryGroups, getRewardsError } = useAppSelector(
    state => state.rewardCategories
  );
  const { behaviourCategoryGroups, getBehavioursError } = useAppSelector(
    state => state.behaviourCategories
  );

  useEffect(() => {
    if (schoolId) {
      if (
        categoryType === CategoryType.Reward &&
        (!rewardCategoryGroups || rewardCategoryGroups.length === 0)
      ) {
        rewardActions.getRewardCategories(
          schoolId, 
          {
            isLegacy: null, 
            canBeAwarded: true
          }
        );
      } else if (
        categoryType === CategoryType.Behaviour &&
        (!behaviourCategoryGroups || behaviourCategoryGroups.length === 0)
      ) {
        behaviourActions.getBehaviourCategories(
          schoolId, 
          {
            isLegacy: null, 
            canBeAwarded: true,
            behaviourCodeId: null
          }
        );
      }
    }
  }, [schoolId]);

  if (getRewardsError) {
    return <ApiExceptionMessage error={getRewardsError} />;
  }

  if (getBehavioursError) {
    return <ApiExceptionMessage error={getBehavioursError} />;
  }

  return (
    <Wrapper>
      {categoryType === CategoryType.Reward ? (
        rewardCategoryGroups && rewardCategoryGroups.length > 0 ? (
          rewardCategoryGroups.map((categoryGroup, index) =>
            categoryGroup.behaviourCategories.map(category => (
              <Category
                key={category.id}
                category={category}
                handleAddPoint={handleAddPoint}
                categoryType={CategoryType.Reward}
              />
            ))
          )
        ) : (
          <EmptyMessage icon="list" title="No Categories" />
        )
      ) : behaviourCategoryGroups && behaviourCategoryGroups.length > 0 ? (
        behaviourCategoryGroups.map((categoryGroup: BehaviourCategoryGroupByListView, index: number) =>
          categoryGroup.behaviourCategories.map(category => (
            <Category
              key={category.id}
              category={category}
              handleAddPoint={handleAddPoint}
              categoryType={CategoryType.Behaviour}
            />
          ))
        )
      ) : (
        <EmptyMessage icon="list" title="No Categories" />
      )}
    </Wrapper>
  );
};

export default Categories;
