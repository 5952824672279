import React from "react";
import { Table } from "ui-kit";

const LeavingLessonHeaders: React.FC = () => {
  return (
    <Table.Header>
      <Table.HeaderCell>Student Name</Table.HeaderCell>
      <Table.HeaderCell>Class</Table.HeaderCell>
      <Table.HeaderCell>Reason</Table.HeaderCell>
      <Table.HeaderCell>Notes</Table.HeaderCell>
      <Table.HeaderCell>Created on</Table.HeaderCell>
      <Table.HeaderCell>Created By</Table.HeaderCell>
    </Table.Header>
  );
};

export default LeavingLessonHeaders;
