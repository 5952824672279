import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import AddRewardModal from "./addRewardModal";
import { success, neutral, danger, Swatches } from "ui-kit/common/colors";
import { Message, EmptyMessage, Size, Loader } from "ui-kit";
import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import Accordion from "sharedComponents/common/accordion/accordion";
import SelectionButtons from "sharedComponents/common/largeSelectionButtons/selectionButtons";
import SelectionButton from "sharedComponents/common/largeSelectionButtons/selectionButton";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";
import { ClassGroupStaffView } from "types/users/userGroups.types";
import { useAppSelector } from "reducers/hooks";
import { SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";


interface IRewardPointsAccordionProps {
  student: PlannerStudentDetailView;
  group: ClassGroupStaffView;
  tabIndex: number;
  currentTabIndex: number;
}


const RewardPointsAccordion: React.FC<IRewardPointsAccordionProps> = ({
  student,
  group,
  tabIndex,
  currentTabIndex,
}) => {

  const { schoolId, rewardCategoryGroups, getRewardsError, loading } =
    useAppSelector(state => state.rewardCategories);

  const [selectedCategory, setSelectedCategory] = useState<SchoolBehaviourCategoryListView | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [expandedRewardGroupKey, setExpandedRewardGroupKey] = useState<number>(null);
  const [allRewardCategories, setAllRewardCategories] = useState<SchoolBehaviourCategoryListView[]>([]);

  useEffect(() => {
    if (
      (tabIndex === undefined || tabIndex === currentTabIndex) &&
      (!rewardCategoryGroups ||
        rewardCategoryGroups.length === 0 ||
        schoolId !== student.school.id)
    ) {
      rewardActions.getRewardCategories(
        student.school.id, 
        {
          isLegacy: null, 
          canBeAwarded: true
        },
        () => rewardActions.setRewardSchoolId(student.school.id)
      );
    }
  }, [student, currentTabIndex]);

  useEffect(() => {
    let tempRewards: SchoolBehaviourCategoryListView[] = [];
    if (rewardCategoryGroups) {
      rewardCategoryGroups.forEach(group => {
        tempRewards = tempRewards.concat(group.behaviourCategories);
      });
    }
    setAllRewardCategories(tempRewards);
  }, [rewardCategoryGroups]);

  const handlePointClick = (selectedCategory: SchoolBehaviourCategoryListView) => {
    setSelectedCategory(selectedCategory);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedCategory(null);
  };

  const onClickAccordionItem = (accordionItemKey: number) => {
    if (expandedRewardGroupKey === accordionItemKey) {
      setExpandedRewardGroupKey(null);
    } else {
      setExpandedRewardGroupKey(accordionItemKey);
    }
  };

  if (getRewardsError) {
    return (
      <Message
        text="There was a problem loading the categories"
        color={Swatches.Danger}
      />
    );
  }

  if (loading) {
    return <Loader size={Size.Medium} fluid />;
  }

  return rewardCategoryGroups && rewardCategoryGroups.length > 0 ? (
    <>
      <Accordion>
        {rewardCategoryGroups.map((categoryGroup, index) => (
          <Accordion.Item
            key={index}
            accordionItemKey={index}
            title={categoryGroup.behaviourCodeName}
            sub={categoryGroup.description}
            expandedItemKey={expandedRewardGroupKey}
            onClickAccordionItemArrow={onClickAccordionItem}
            onClickItem={onClickAccordionItem}
          >
            <SelectionButtons listView={true}>
              {categoryGroup.behaviourCategories.map(category => (
                <SelectionButton
                  key={category.id}
                  listView
                  item={{ ...category, rewardGroupId: index }}
                  itemLabel={category.codeDisplayName}
                  itemSub={category.behaviourCategoryName}
                  icon="thumbs-up"
                  iconColour={success}
                  colour={neutral[200]}
                  handleItemClick={handlePointClick}
                />
              ))}
            </SelectionButtons>
          </Accordion.Item>
        ))}
      </Accordion>

      {open && selectedCategory && (
        <AddRewardModal
          open={open}
          handleCloseModal={handleCloseModal}
          student={student}
          selectedCategory={selectedCategory}
          rewardCategories={allRewardCategories}
          groupId={group ? group.id : null}
          groupType={group ? group.type : null}
        />
      )}
    </>
  ) : (
    <EmptyMessage icon="list" title="No Categories" />
  );
};

export default RewardPointsAccordion;
