import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, ActionBar } from "ui-kit";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import {
  ACTIVITY_FILTERS,
  DATE_FILTERS,
  FEED_TYPE_KEY,
} from "../../constants/activityFeed";

const FeedFilters = ({
  selectedActivityFilter,
  setSelectedActivityFilter,
  selectedDateFilter,
  setSelectedDateFilter,
}) => {
  const { user } = useSelector(state => state.currentUser);

  const [activityFiltersArray, setActivityFiltersArray] = useState([]);
  const [dateFiltersArray, setDateFiltersArray] = useState([]);

  useEffect(() => {
    let filters;
    if (user.userType === Constants.USER_TYPES.STAFF) {
      filters = ACTIVITY_FILTERS.map(filter => ({
        key: filter.key,
        label: filter.text,
        value: filter.key,
      }));
    } else {
      filters = [];
      for (let i = 0; i < ACTIVITY_FILTERS.length; i++) {
        if (
          ACTIVITY_FILTERS[i].key !== FEED_TYPE_KEY.NOTES &&
          // ACTIVITY_FILTERS[i].key !== FEED_TYPE_KEY.LEFT_LESSON &&
          ACTIVITY_FILTERS[i].key !== FEED_TYPE_KEY.ON_CALL
        ) {
          filters.push({
            key: ACTIVITY_FILTERS[i].key,
            label: ACTIVITY_FILTERS[i].text,
            value: ACTIVITY_FILTERS[i].key,
          });
        }
      }
    }
    setActivityFiltersArray(filters);

    const dateFilters = DATE_FILTERS.map(filter => ({
      key: filter.key,
      label: filter.text,
      value: filter.key,
    }));
    setDateFiltersArray(dateFilters);
  }, []);

  const onSelectActivityFilter = filterKey => {
    if (selectedActivityFilter !== filterKey) {
      const newFilter = filterKey;
      setSelectedActivityFilter(newFilter);
    }
  };

  const onSelectDateFilter = filterKey => {
    if (selectedDateFilter !== filterKey) {
      setSelectedDateFilter(filterKey);
    } else {
      setSelectedDateFilter(null);
    }
  };

  return (
    <ActionBar>
      <Dropdown
        items={activityFiltersArray}
        onChange={value => onSelectActivityFilter(value)}
        value={selectedActivityFilter}
      />
      <Dropdown
        items={dateFiltersArray}
        onChange={value => onSelectDateFilter(value)}
        value={selectedDateFilter}
      />
    </ActionBar>
  );
};

FeedFilters.propTypes = {
  activityFilters: PropTypes.array,
  selectedActivityFilter: PropTypes.number,
  setSelectedActivityFilter: PropTypes.func,
  dateFilters: PropTypes.array,
  selectedDateFilter: PropTypes.number,
  setSelectedDateFilter: PropTypes.func,
};

export default FeedFilters;
