import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import EventsFeed from "./eventsFeed";
import calendarActions from "../../actions/calendarActions";
import { RootState } from "reducers/store";


const ManagedEvents = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { events, loading, paging, error } = useSelector((state: RootState) => state.signedUpCalendarEvents);

  useEffect(() => {
    calendarActions.getSignedUpCalendarEvents(user.id, null, 0);
  }, []);

  const handleOnPage = () => {
    if (!loading) {
      calendarActions.getSignedUpCalendarEvents(
        user.id,
        null,
        paging.pageIndex + 1
      );
    }
  };

  return (
    <EventsFeed
      events={events}
      error={error}
      loading={loading}
      paging={paging}
      onPage={handleOnPage}
    />
  );
};

export default ManagedEvents;
