import { IBeehiveAction } from "types/common/action.types";
import provisionGroupsActions from "../../actions/provisions/provisionGroupsActions";
import { ProvisionRecordMapView } from "areas/send/types/passportResponse.types";

interface IProvisionRecordState {
  record: ProvisionRecordMapView | null;
  loading: boolean;
  error: string | null;
  saving: boolean;
  saveError: string | null;
  deleting: boolean;
  deleteError: string | null;
  updatingStatus: boolean;
  statusUpdateError: string | null;
  newId: number | null;
}

const INITIAL_STATE: IProvisionRecordState = {
  record: null,
  loading: false,
  saving: false,
  error: null,
  saveError: null,
  deleting: false,
  deleteError: null,
  updatingStatus: false,
  statusUpdateError: null,
  newId: null,
};


const provisionRecordReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IProvisionRecordState => {

  const {
    PROVISIONRECORDS_CREATE,
    PROVISIONRECORDS_SAVE,
    PROVISIONRECORDS_DELETE,
    PROVISIONRECORDS_UPDATESTATUS,
  } = provisionGroupsActions.types;

  switch (action.type) {
    case PROVISIONRECORDS_CREATE.START:
      return { ...state, saveError: null, saving: true };
    case PROVISIONRECORDS_CREATE.SUCCESS:
      return { ...state, saving: false, newId: action.payload };
    case PROVISIONRECORDS_CREATE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem creating the provision record",
      };
    case PROVISIONRECORDS_SAVE.START:
      return {
        ...state,
        saveError: null,
        deleteError: null,
        statusUpdateError: null,
        saving: true,
      };
    case PROVISIONRECORDS_SAVE.SUCCESS:
      return { ...state, saving: false, record: action.payload };
    case PROVISIONRECORDS_SAVE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the provision record",
      };
    case PROVISIONRECORDS_DELETE.START:
      return {
        ...state,
        saveError: null,
        deleteError: null,
        statusUpdateError: null,
        deleting: true,
      };
    case PROVISIONRECORDS_DELETE.SUCCESS:
      return { ...state, deleting: false };
    case PROVISIONRECORDS_DELETE.FAILED:
      return {
        ...state,
        deleting: false,
        deleteError:
          "There was a problem deleting the provision record. This may be because this provision record is in use.",
      };

    case PROVISIONRECORDS_UPDATESTATUS.START:
      return {
        ...state,
        saveError: null,
        deleteError: null,
        statusUpdateError: null,
        updatingStatus: true,
      };
    case PROVISIONRECORDS_UPDATESTATUS.SUCCESS:
      return { ...state, updatingStatus: false };
    case PROVISIONRECORDS_UPDATESTATUS.FAILED:
      return {
        ...state,
        updatingStatus: false,
        statusUpdateError:
          "There was a problem updating the provision record status. This may be because this provision record is in use.",
      };

    default:
      return state;
  }
};

export default provisionRecordReducer;
