import { IBeehiveAction } from "types/common/action.types";
import specialDayActions from "../actions/specialDayActions";
import { SpecialDayListView } from "../types/termDatesResponse.types";


interface ISpecialDaysState {
  specialDays: SpecialDayListView[];
  loading: boolean;
  error: string | null;
}

const INITIAL_STATE: ISpecialDaysState = {
  specialDays: [],
  loading: false,
  error: null,
};


const specialDaysReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISpecialDaysState => {

  const { SPECIALDAYS_GET } = specialDayActions.types;

  switch (action.type) {
    case SPECIALDAYS_GET.START:
      return { 
        ...state, 
        loading: true 
      };
    case SPECIALDAYS_GET.SUCCESS:
      return { 
        ...state, 
        loading: false, 
        specialDays: action.payload 
      };
    case SPECIALDAYS_GET.FAILED:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};

export default specialDaysReducer;
