import defaults, { IActionState } from "reducers/defaults";
import userManagementActions from "../../actions/userManagementActions";
import { BeehiveReport, IBeehiveAction } from "types/common/action.types";
import {
  ManagedParentDetailView,
  ManagedStudentDetailView,
  ManagedUserDetailView,
} from "types/users/userListViews.types";
import { UserSessionListView } from "areas/administration/types/userManagementResponse.types";
import { RoleListView } from "types/users/userRoles.types";
import { ManagedStaffSchoolView } from "types/users/userSchools.types";

interface IManagedUserActions {
  getSessions: IActionState;
  getUserRequestLog: IActionState;
  getAvailableRoles: IActionState;
  updateSchool: IActionState;
  updateBaseSchool: IActionState;
  removeSchool: IActionState;
  updateRoles: IActionState;
  updatePreferences: IActionState;
  updateJob: IActionState;
  updateDepartments: IActionState;
  unlock: IActionState;
  sendPasswordReset: IActionState;
  deregister: IActionState;
  sendRegistrationLink: IActionState;
  clearSessions: IActionState;
}

export interface managedUser extends ManagedUserDetailView {
  sessions?: UserSessionListView[];
  availableRoles?: RoleListView[];
  requestLog?: BeehiveReport;
}

export interface managedParent extends ManagedParentDetailView {
  sessions?: UserSessionListView[];
  availableRoles?: RoleListView[];
  requestLog?: BeehiveReport;
}

export interface managedStudent extends ManagedStudentDetailView {
  sessions?: UserSessionListView[];
  availableRoles?: RoleListView[];
  requestLog?: BeehiveReport;
}

export interface managedStaff extends ManagedUserDetailView {
  sessions?: UserSessionListView[];
  availableRoles?: RoleListView[];
  requestLog?: BeehiveReport;
  schools: ManagedStaffSchoolView[];
  school: ManagedStaffSchoolView;
}

export type managedUserAccount =
  | managedUser
  | managedParent
  | managedStudent
  | managedStaff;

interface IManagedUserState {
  user: managedUserAccount | null;
  status: {
    loading: boolean;
    error: string | null;
  };
  actions: IManagedUserActions;
}

const ACTIONS_DEFAULT: IManagedUserActions = {
  getSessions: defaults.INITIAL_ACTION_STATE,
  getUserRequestLog: defaults.INITIAL_ACTION_STATE,
  getAvailableRoles: defaults.INITIAL_ACTION_STATE,
  updateSchool: defaults.INITIAL_ACTION_STATE,
  updateBaseSchool: defaults.INITIAL_ACTION_STATE,
  removeSchool: defaults.INITIAL_ACTION_STATE,
  updateRoles: defaults.INITIAL_ACTION_STATE,
  updatePreferences: defaults.INITIAL_ACTION_STATE,
  updateJob: defaults.INITIAL_ACTION_STATE,
  updateDepartments: defaults.INITIAL_ACTION_STATE,
  unlock: defaults.INITIAL_ACTION_STATE,
  sendPasswordReset: defaults.INITIAL_ACTION_STATE,
  deregister: defaults.INITIAL_ACTION_STATE,
  sendRegistrationLink: defaults.INITIAL_ACTION_STATE,
  clearSessions: defaults.INITIAL_ACTION_STATE,
};

const INITIAL_STATE: IManagedUserState = {
  user: null,
  status: { loading: false, error: null },
  actions: ACTIONS_DEFAULT,
};

const managedUserReducer = (
  state = INITIAL_STATE,
  action: IBeehiveAction
): IManagedUserState => {
  const {
    USERMANAGEMENT_GETUSER,
    USERMANAGEMENT_GETSESSIONS,
    USERMANAGEMENT_GETAVAILABLEROLESFORUSER,
    USERMANAGEMENT_SETSTAFFSCHOOL,
    USERMANAGEMENT_SETSTAFFBASESCHOOL,
    USERMANAGEMENT_REMOVESTAFFSCHOOL,
    USERMANAGEMENT_SETSTAFFEMPLOYMENTDETAILS,
    USERMANAGEMENT_SETSTAFFDEPARTMENTS,
    USERMANAGEMENT_SETSTAFFROLES,
    USERMANAGEMENT_CLEARSESSIONS,
    USERMANAGEMENT_UNLOCKACCOUNT,
    USERMANAGEMENT_DEREGISTER,
    USERMANAGEMENT_SENDREGISTRATIONLINK,
    USERMANAGEMENT_SENDRESETPASSWORDRESETLINK,
    USERMANAGEMENT_GETUSERREQUESTLOG,
  } = userManagementActions.types;

  switch (action.type) {
    case USERMANAGEMENT_GETUSER.START:
      return {
        ...INITIAL_STATE,
        status: { loading: true, error: null },
      };

    case USERMANAGEMENT_GETUSER.SUCCESS:
      return {
        ...state,
        user: action.payload,
        status: { loading: false, error: null },
      };

    case USERMANAGEMENT_GETUSER.FAILED:
      return {
        ...state,
        status: { loading: false, error: action.payload },
      };

    case USERMANAGEMENT_GETSESSIONS.START:
      return {
        ...INITIAL_STATE,
        actions: {
          ...ACTIONS_DEFAULT,
          getSessions: { working: true, error: null },
        },
      };

    case USERMANAGEMENT_GETSESSIONS.SUCCESS:
      return {
        ...state,
        user: { ...state.user, sessions: action.payload },
        actions: {
          ...ACTIONS_DEFAULT,
          getSessions: { working: false, error: null },
        },
      };

    case USERMANAGEMENT_GETSESSIONS.FAILED:
      return {
        ...state,
        actions: {
          ...ACTIONS_DEFAULT,
          getSessions: {
            working: false,
            error: "There was a problem loading the user sessions.",
          },
        },
      };

    case USERMANAGEMENT_GETAVAILABLEROLESFORUSER.START:
      return {
        ...state,
        actions: {
          ...ACTIONS_DEFAULT,
          getAvailableRoles: { working: true, error: null },
        },
      };

    case USERMANAGEMENT_GETAVAILABLEROLESFORUSER.SUCCESS:
      return {
        ...state,
        user: { ...state.user, availableRoles: action.payload },
        actions: {
          ...ACTIONS_DEFAULT,
          getAvailableRoles: { working: false, error: null },
        },
      };

    case USERMANAGEMENT_GETAVAILABLEROLESFORUSER.FAILED:
      return {
        ...state,
        actions: {
          ...ACTIONS_DEFAULT,
          getAvailableRoles: {
            working: false,
            error: "There was a problem loading the available roles.",
          },
        },
      };

    case USERMANAGEMENT_GETUSERREQUESTLOG.START:
      return {
        ...state,
        actions: {
          ...ACTIONS_DEFAULT,
          getUserRequestLog: { working: true, error: null },
        },
      };

    case USERMANAGEMENT_GETUSERREQUESTLOG.SUCCESS:
      return {
        ...state,
        user: { ...state.user, requestLog: action.payload },
        actions: {
          ...ACTIONS_DEFAULT,
          getUserRequestLog: { working: false, error: null },
        },
      };

    case USERMANAGEMENT_GETUSERREQUESTLOG.FAILED:
      return {
        ...state,
        actions: {
          ...ACTIONS_DEFAULT,
          getUserRequestLog: {
            working: false,
            error: "There was a problem loading the user request log.",
          },
        },
      };

    case USERMANAGEMENT_SETSTAFFSCHOOL.START:
      return {
        ...state,
        actions: {
          ...ACTIONS_DEFAULT,
          updateSchool: { working: true, error: null },
        },
      };

    case USERMANAGEMENT_SETSTAFFSCHOOL.SUCCESS:
      let updatedUser = state.user;

      if (state.user.school.id === action.payload.id) {
        updatedUser = { ...state.user, school: action.payload };
      } else {
        const schoolIndex = state.user.schools.findIndex(
          s => s.id === action.payload.id
        );

        if (schoolIndex !== -1) {
          updatedUser = {
            ...state.user,
            schools: state.user.schools.map(s =>
              s.id === action.payload.id ? action.payload : s
            ),
          };
        } else {
          updatedUser = {
            ...state.user,
            schools: [...state.user.schools, action.payload],
          };
        }
      }

      return {
        ...state,
        user: updatedUser,
        actions: {
          ...state.actions,
          updateSchool: { working: false, error: null },
        },
      };

    case USERMANAGEMENT_SETSTAFFSCHOOL.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          updateSchool: {
            working: false,
            error: "There was a problem updating the school.",
          },
        },
      };

    case USERMANAGEMENT_SETSTAFFBASESCHOOL.START:
      return {
        ...state,
        actions: {
          ...ACTIONS_DEFAULT,
          updateBaseSchool: { working: true, error: null },
        },
      };

    case USERMANAGEMENT_SETSTAFFBASESCHOOL.SUCCESS:
      var newSchools = [
        ...state.user.schools,
        { ...state.user.school, personId: null, simsId: null },
      ];

      return {
        ...state,
        user: {
          ...state.user,
          schools: newSchools
            .filter(s => s.id !== action.payload.id)
            .sort((a, b) => {
              return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
            }),
          school: action.payload,
        },
        actions: {
          ...state.actions,
          updateBaseSchool: { working: false, error: null },
        },
      };

    case USERMANAGEMENT_SETSTAFFBASESCHOOL.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          updateBaseSchool: {
            working: false,
            error: "There was a problem updating the base school.",
          },
        },
      };

    case USERMANAGEMENT_REMOVESTAFFSCHOOL.START:
      return {
        ...state,
        actions: {
          ...ACTIONS_DEFAULT,
          removeSchool: { working: true, error: null },
        },
      };

    case USERMANAGEMENT_REMOVESTAFFSCHOOL.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          schools: state.user.schools.filter(s => s.id !== action.payload),
        },
        actions: {
          ...state.actions,
          removeSchool: { working: false, error: null },
        },
      };

    case USERMANAGEMENT_REMOVESTAFFSCHOOL.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          removeSchool: {
            working: false,
            error:
              "There was a problem removing the school. This may be because this is a base school.",
          },
        },
      };

    case USERMANAGEMENT_SETSTAFFEMPLOYMENTDETAILS.START:
      return {
        ...state,
        actions: {
          ...ACTIONS_DEFAULT,
          updateJob: { working: true, error: null },
        },
      };

    case USERMANAGEMENT_SETSTAFFEMPLOYMENTDETAILS.SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
        actions: {
          ...state.actions,
          updateJob: { working: false, error: null },
        },
      };

    case USERMANAGEMENT_SETSTAFFEMPLOYMENTDETAILS.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          updateJob: {
            working: false,
            error: "There was a problem updating the employment details.",
          },
        },
      };

    case USERMANAGEMENT_SETSTAFFDEPARTMENTS.START:
      return {
        ...state,
        actions: {
          ...ACTIONS_DEFAULT,
          updateDepartments: { working: true, error: null },
        },
      };

    case USERMANAGEMENT_SETSTAFFDEPARTMENTS.SUCCESS:
      return {
        ...state,
        user: { ...state.user, departments: action.payload },
        actions: {
          ...state.actions,
          updateDepartments: { working: false, error: null },
        },
      };

    case USERMANAGEMENT_SETSTAFFDEPARTMENTS.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          updateDepartments: {
            working: false,
            error: "There was a problem updating the departments.",
          },
        },
      };

    case USERMANAGEMENT_SETSTAFFROLES.START:
      return {
        ...state,
        actions: {
          ...ACTIONS_DEFAULT,
          updateRoles: { working: true, error: null },
        },
      };

    case USERMANAGEMENT_SETSTAFFROLES.SUCCESS:
      return {
        ...state,
        user: { ...state.user, roles: action.payload },
        actions: {
          ...state.actions,
          updateRoles: { working: false, error: null },
        },
      };

    case USERMANAGEMENT_SETSTAFFROLES.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          updateRoles: {
            working: false,
            error: "There was a problem updating the roles.",
          },
        },
      };

    case USERMANAGEMENT_CLEARSESSIONS.START:
      return {
        ...state,
        actions: {
          ...ACTIONS_DEFAULT,
          clearSessions: { working: true, error: null },
        },
      };

    case USERMANAGEMENT_CLEARSESSIONS.SUCCESS:
      return {
        ...state,
        user: { ...state.user, sessions: [] },
        actions: {
          ...state.actions,
          clearSessions: { working: false, error: null },
        },
      };

    case USERMANAGEMENT_CLEARSESSIONS.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          clearSessions: {
            working: false,
            error: "There was a problem clearing the sessions.",
          },
        },
      };

    case USERMANAGEMENT_UNLOCKACCOUNT.START:
      return {
        ...state,
        actions: {
          ...ACTIONS_DEFAULT,
          unlock: { working: true, error: null },
        },
      };

    case USERMANAGEMENT_UNLOCKACCOUNT.SUCCESS:
      return {
        ...state,
        user: { ...state.user, lockedDate: null },
        actions: {
          ...state.actions,
          unlock: { working: false, error: null },
        },
      };

    case USERMANAGEMENT_UNLOCKACCOUNT.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          unlock: {
            working: false,
            error: "There was a problem unlocking this account.",
          },
        },
      };

    case USERMANAGEMENT_DEREGISTER.START:
      return {
        ...state,
        actions: {
          ...ACTIONS_DEFAULT,
          deregister: { working: true, error: null },
        },
      };

    case USERMANAGEMENT_DEREGISTER.SUCCESS:
      return {
        ...state,
        user: { ...state.user, registeredDate: null },
        actions: {
          ...state.actions,
          deregister: { working: false, error: null },
        },
      };

    case USERMANAGEMENT_DEREGISTER.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          deregister: {
            working: false,
            error: "There was a problem deregistering this user.",
          },
        },
      };

    case USERMANAGEMENT_SENDREGISTRATIONLINK.START:
      return {
        ...state,
        actions: {
          ...ACTIONS_DEFAULT,
          sendRegistrationLink: { working: true, error: null },
        },
      };

    case USERMANAGEMENT_SENDREGISTRATIONLINK.SUCCESS:
      return {
        ...state,
        actions: {
          ...state.actions,
          sendRegistrationLink: { working: false, error: null },
        },
      };

    case USERMANAGEMENT_SENDREGISTRATIONLINK.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          sendRegistrationLink: {
            working: false,
            error: "There was a problem sending the registration link.",
          },
        },
      };

    case USERMANAGEMENT_SENDRESETPASSWORDRESETLINK.START:
      return {
        ...state,
        actions: {
          ...ACTIONS_DEFAULT,
          sendPasswordReset: { working: true, error: null },
        },
      };

    case USERMANAGEMENT_SENDRESETPASSWORDRESETLINK.SUCCESS:
      return {
        ...state,
        actions: {
          ...state.actions,
          sendPasswordReset: { working: false, error: null },
        },
      };

    case USERMANAGEMENT_SENDRESETPASSWORDRESETLINK.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          sendPasswordReset: {
            working: false,
            error: "There was a problem sending the password reset link.",
          },
        },
      };
    default:
      return state;
  }
};

export default managedUserReducer;
