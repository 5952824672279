import React, { useEffect, useState } from "react";
import {
  Modal,
  Card,
  ActionBar,
  Button,
  Right,
  Size,
  Swatches,
  Message,
  ToastService,
  RichTextEditor,
  ValidationMessage,
} from "ui-kit";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import provisionMapActions from "areas/send/actions/provisionMaps/provisionMapActions";
import { RootState } from "reducers/store";
import { ProvisionMapCommentView, ProvisionMapDetailView } from "areas/send/types/passportResponse.types";


interface ICommentModalProps {
  provisionMap: ProvisionMapDetailView;
  comment: ProvisionMapCommentView;
  open: boolean;
  onCancel: () => void;
  onSave?: (comment: ProvisionMapCommentView) => void;
}


const CommentModal: React.FC<ICommentModalProps> = ({ provisionMap, comment, open, onCancel, onSave }) => {

  const { saving, saveError } = useSelector((state: RootState) => state.provisionMapComment);
  const [_provisionMap, _setProvisionMap] = useState<ProvisionMapDetailView>(provisionMap);
  const [_content, _setContent] = useState<string>("");
  const [_open, _setOpen] = useState<boolean>(open);
  const [_comment, _setComment] = useState<ProvisionMapCommentView | null>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    setValidationErrors([]);
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    _setProvisionMap(provisionMap);
  }, [provisionMap]);

  useEffect(() => {
    _setComment(comment);
    _setContent(comment?.content);
  }, [comment]);

  const handleSave = () => {
    var errors = [];

    if (!_content?.length) {
      errors.push(`Please enter a comment.`);
    }
    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    var data = {
      ..._comment,
      content: _content,
      studentId: _provisionMap.student.id,
      provisionMapId: _provisionMap.id,
    };

    if (!_comment.id) {
      provisionMapActions.createProvisionMapComment(data, () => {
        ToastService.pop("Comment Created", null, "hand-holding-seedling");
        onSave?.(comment);
        _setComment(null);
        _setContent(null);
      });
    } else {
      provisionMapActions.saveProvisionMapComment(data, () => {
        ToastService.pop("Comment Saved", null, "hand-holding-seedling");
        onSave?.(comment);
        _setComment(null);
        _setContent(null);
      });
    }
  };

  return (
    <Modal
      title="New Provision Map Comment"
      open={_open}
      onClose={onCancel}
      width="80%"
      height="80%"
    >
      <Modal.Body>
        <ValidationMessage errors={validationErrors} />
        <Message text={saveError} color={Swatches.Danger} />
        <Card grow title="Comment">
          <Card.Body noPad>
            <RichTextEditor
              noPad
              initialValue={_comment?.content}
              onChange={value => _setContent(value)}
            />
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              onClick={onCancel}
              size={Size.Small}
              color={Swatches.Low}
              text="Cancel"
              working={saving}
            />
            <Button
              onClick={handleSave}
              size={Size.Small}
              color={Swatches.Success}
              text="Save"
              working={saving}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default CommentModal;
