import { Constants } from "configuration";
import { IWallplannerCategory } from "configuration/constants.types";
import { useEffect, useState } from "react";
import { Dropdown } from "ui-kit";

interface IWpCategoryDropdownProps {
  value: number;
  onChange?: (itemValue: number) => void;
}

const WpCategoryDropdown: React.FC<IWpCategoryDropdownProps> = ({ value, onChange }) => {

  const arrayToDropdown = (arr: IWallplannerCategory[]) => {
    return arr.map(item => ({
      label: item.name,
      value: item.id,
    }));
  };
  var categories = arrayToDropdown(Constants.WALLPLANNER_CATEGORIES);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredCategories, setFilteredCategories] = useState<{ label: string, value: number }[]>();

  useEffect(() => {
    if (categories) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      var filteredCategories =
        searchTerm.length > 2
          ? categories.filter(cat =>
              cat.label?.toLowerCase().includes(lowerCaseSearchTerm)
            )
          : categories;
      setFilteredCategories(filteredCategories);
    }
  }, [searchTerm]);

  const handleChange = (itemValue: number) => {
    onChange?.(itemValue);
  };

  return (
    <Dropdown
      items={filteredCategories}
      autocomplete
      placeholder={"Search categories"}
      searchPlaceholder={"Search categories"}
      onSearchChange={val => setSearchTerm(val)}
      onChange={handleChange}
      value={value}
      fluid
    />
  );
};


export default WpCategoryDropdown;