import { ProvisionType } from "areas/send/types/passportShared.types";
import React, { useState, useEffect } from "react";
import { Dropdown } from "ui-kit";


interface IProvisionTypeDropdownProps {
  value?: ProvisionType;
  onChange?: (value: number) => void;
  fluid?: boolean;
  placeholder?: string;
  includeAllOption?: boolean;
}

interface provisionTypeOption {
  label: string;
  value: number;
}


const ALL_OPTION = {
  key: -1,
  label: "All Provision Types",
  value: -1,
  sub: "ALL",
};

const OPTIONS: provisionTypeOption[] = [
  { label: "Standard Provisions", value: ProvisionType.Standard },
  { label: "Custom Provisions", value: ProvisionType.Custom },
];


const ProvisionTypeDropdown: React.FC<IProvisionTypeDropdownProps> = ({
  value,
  onChange,
  fluid,
  placeholder,
  includeAllOption,
}) => {

  const [_options, _setOptions] = useState<provisionTypeOption[]>(OPTIONS);

  useEffect(() => {
    includeAllOption && _setOptions([ALL_OPTION, ...OPTIONS]);
  }, [includeAllOption]);

  return (
    <Dropdown
      placeholder={
        placeholder ? placeholder : "Please choose a provision type..."
      }
      fluid={fluid}
      value={value}
      onChange={onChange}
    >
      {_options.map((opt: provisionTypeOption, index: number) => (
        <Dropdown.Item key={index} label={opt.label} value={opt.value} />
      ))}
    </Dropdown>
  );
};

export default ProvisionTypeDropdown;
