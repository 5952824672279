import React from "react";
import ArticleEditor from "./articleEditor";
import CustomRoute from "sharedComponents/layout/routes/customRoute";
import BulletinViewer from "./bulletins/bulletinViewer";
import BulletinEditor from "./bulletins/bulletinEditor";
import BulletinTemplateEditor from "./bulletins/bulletinTemplateEditor";
import NewsdeskHome from "./newsdeskHome";
import { Constants } from "configuration";
import { Routes, Route } from "react-router";
import constants from "configuration/constants";
import { articleRoutes, bulletinRoutes } from "../../newsRoutes";


const Newsdesk = () => {
  
  return (
    <Routes>
      <Route
        index
        element={
          <CustomRoute users={[constants.USER_TYPES.STAFF]}>
            <NewsdeskHome />
          </CustomRoute>
        }
      />

      <Route
        path={articleRoutes.new}
        element={
          <CustomRoute users={[constants.USER_TYPES.STAFF]}>
            <ArticleEditor />
          </CustomRoute>
        }
      />

      <Route
        path={articleRoutes.article}
        element={
          <CustomRoute users={[constants.USER_TYPES.STAFF]}>
            <ArticleEditor />
          </CustomRoute>
        }
      />

      <Route
        path={articleRoutes.new}
        element={
          <CustomRoute users={[constants.USER_TYPES.STAFF]}>
            <ArticleEditor />
          </CustomRoute>
        }
      />

      <Route
        path={bulletinRoutes.new}
        element={
          <CustomRoute
            users={[constants.USER_TYPES.STAFF]}
            roles={[
              Constants.ROLES.MARKETING_ADMINISTRATOR,
              Constants.ROLES.DEVELOPMENT,
            ]}
          >
            <BulletinEditor />
          </CustomRoute>
        }
      />

      <Route
        path={bulletinRoutes.edit}
        element={
          <CustomRoute
            users={[constants.USER_TYPES.STAFF]}
            roles={[
              Constants.ROLES.MARKETING_ADMINISTRATOR,
              Constants.ROLES.DEVELOPMENT,
            ]}
          >
            <BulletinEditor />
          </CustomRoute>
        }
      />

      <Route
        path={bulletinRoutes.bulletin}
        element={
          <CustomRoute
            users={[constants.USER_TYPES.STAFF]}
            roles={[
              Constants.ROLES.MARKETING_ADMINISTRATOR,
              Constants.ROLES.DEVELOPMENT,
            ]}
          >
            <BulletinViewer />
          </CustomRoute>
        }
      />

      <Route
        path={bulletinRoutes.newTemplate}
        element={
          <CustomRoute
            users={[constants.USER_TYPES.STAFF]}
            roles={[
              Constants.ROLES.MARKETING_ADMINISTRATOR,
              Constants.ROLES.DEVELOPMENT,
            ]}
          >
            <BulletinTemplateEditor />
          </CustomRoute>
        }
      />

      <Route
        path={bulletinRoutes.template}
        element={
          <CustomRoute
            users={[constants.USER_TYPES.STAFF]}
            roles={[
              Constants.ROLES.MARKETING_ADMINISTRATOR,
              Constants.ROLES.DEVELOPMENT,
            ]}
          >
            <BulletinTemplateEditor />
          </CustomRoute>
        }
      />
    </Routes>
  );
};

export default Newsdesk;
