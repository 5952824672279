import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Loader, Size } from "ui-kit";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import StudentModal from "sharedComponents/common/users/studentModal";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import detentionActions from "../../../actions/detentions/detentionActions";
import detentionRegisterActions from "../../../actions/detentions/detentionRegisterActions";
import RescheduleDetentionFormWrapper from "./rescheduleDetentionFormWrapper";
import { formatDate, getDateTime } from "utils/dateTime";
import { useAppSelector } from "reducers/hooks";
import { DetentionStudentView } from "areas/behaviour/types/detentionRegisterResponse.types";
import { RescheduleDetentionCommand } from "areas/behaviour/types/detentionRegisterRequest.types";
import { BehaviourView } from "areas/behaviour/types/behaviourResponses.types";
import { IDetentionDetails } from "areas/behaviour/utils/detentions";
import { getTime } from "areas/behaviour/utils/bulkDetentionReschedule";


interface IRescheduleDetentionModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  detention: DetentionStudentView;
}


const RescheduleDetentionModal: React.FC<IRescheduleDetentionModalProps> = ({ open, setOpen, detention }) => {

  const { behaviour, loading } = useAppSelector(state => state.behaviour);
  const { rescheduleDetentionError, loading: loadingRescheduleDetention } =
  useAppSelector(state => state.detention);
  const { detentionSummary } = useAppSelector(state => state.detentionSummary);
  const { schoolInformation } = useAppSelector(state => state.school);

  const [selectedBehaviour, setSelectedBehaviour] = useState<BehaviourView>(null);

  useEffect(() => {
    if (behaviour) {
      setSelectedBehaviour(behaviour.behaviour);
    }
  }, [behaviour]);

  useEffect(() => {
    if (detention) {
      behaviourActions.getBehaviour(
        detention.student.id,
        detention.behaviourRewardId,
        detention.detentionId
      );
    }
  }, [detention, open]);

  const onRescheduleDetention = (detentionDetails: IDetentionDetails) => {

    var { hour, minute } = getTime(detentionDetails.time);
    var _detentionDate = detentionDetails.date.clone().hour(hour).minute(minute);

    const rescheduleDetails: RescheduleDetentionCommand = {
      behaviourId: selectedBehaviour.behaviourId,
      studentId: detention.student.id,
      createDetention: {
        detentionTypeId: detentionDetails.detentionTypeId,
        detentionDate: _detentionDate.format("YYYY-MM-DD HH:mm"),
        roomId: detentionDetails.detentionLocationId,
        duration: detentionDetails.duration,
        detentionNote: detentionDetails.detentionNotes,
        parentDetentionId: detentionDetails.id,
      },
    };

    const callback = () => {
      setOpen(false);
      detentionRegisterActions.removeDetentionFromMissedDetentions(
        detention.detentionId
      );
    };

    detentionActions.rescheduleDetention(
      detention.student.id,
      selectedBehaviour.behaviourId,
      rescheduleDetails,
      callback
    );
  };

  return (
    <StudentModal
      onClose={() => setOpen(false)}
      open={open}
      width="75%"
      height="80%"
      title="Reschedule Detention"
      student={detention?.student}
    >
      <>
        {loading || loadingRescheduleDetention ? (
          <Loader size={Size.Medium} fluid />
        ) : (
          selectedBehaviour &&
          detention && (
            <>
              {rescheduleDetentionError && (
                <ApiExceptionMessage error={rescheduleDetentionError} />
              )}

              <RescheduleDetentionFormWrapper
                studentId={detention.student.id}
                behaviour={selectedBehaviour}
                handleCancel={() => setOpen(false)}
                onRescheduleDetention={onRescheduleDetention}
                detentionSummary={detentionSummary}
                schoolInformation={schoolInformation}
              />
            </>
          )
        )}
      </>
    </StudentModal>
  );
};


export default RescheduleDetentionModal;
