import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Constants } from "configuration";
import LayoutGeneratorWrapper from "../layoutGenerator/layoutGeneratorWrapper";
import {
  Flyout,
  Swatches,
  neutral,
  ActionBar,
  SplitButton,
  Size,
  Left,
  Modal,
  displayFont,
  fontStyle,
  typescale,
  Right,
  Dropdown,
  honeygold,
  Message,
  Spacing,
} from "ui-kit";
import { studentQuiz } from "utils";
import ClassLayoutStudentFilters from "./classLayoutStudentFilters";
import GroupActionButton from "../../groupActionButton";
import classViewerActions from "areas/planner/actions/classViewer/classViewerActions";
import groupActions from "areas/planner/actions/group/groupActions";
import { getUpdateClassLayoutPayload } from "areas/planner/utils/layouts";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import flyoutActions from "actions/ui/flyouts";
import ConfirmModal from "sharedComponents/common/confirmModal";
import CreateOnCallRequestWrapper from "areas/behaviour/components/onCall/createOnCallRequestWrapper";
import CustomGroupEditorModal from "areas/planner/components/groups/customGroups/customGroupEditorModal";

const ConfirmText = styled.div`
  ${fontStyle(displayFont.medium, typescale.header4, neutral[700])}
`;

const Wrapper = styled.div`
  .action-bar {
    .left {
      .dropdown {
        width: 10rem;
      }
    }

    .right .on-call-button {
      padding-left: ${Spacing.Medium}px;
      padding-right: ${Spacing.Medium}px;
      width: unset;
    }
  }

  .layout-generator {
    .class-layout-option {
      background: ${neutral[100]};

      &:hover {
        background: ${neutral[600]};
      }

      &.selected {
        background: ${honeygold};
      }
    }
  }
`;

const SplitButtonWrapper = styled.div`
  .split-button .split-button-inner .split-button-default {
    padding: 0 ${Spacing.ExtraLarge}px;
  }
`;

const ClassLayoutMenuWrapper = ({
  isDefault,
  handleSelectLayout,
  openLayoutCreator,
  selectedClassLayout,
  // handleDeleteRoomLayout,
  studentSlots,
  handleResetLayout,
  handleSelectAllStudents,
  selectedStudents,
  handlePickAStudent,
  handleUnselectAllStudents,
  loading,
}) => {
  const { group, selectedLayoutId } = useSelector(state => state.group);
  const { classLayout } = useSelector(state => state.classLayout);
  const { layoutDirection } = useSelector(state => state.classLayout);
  const { user } = useSelector(state => state.currentUser);
  const { isPrivate } = useSelector(state => state.privacy);
  const { errorDeletingGroup } = useSelector(state => state.customGroup);

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  // const [openDeleteRoomConfirm, setOpenDeleteRoomConfirm] = useState(false);
  // const [openDeleteRoomConfirmTwo, setOpenDeleteRoomConfirmTwo] =
  //   useState(false);

  const [openSetDefaultConfirm, setOpenSetDefaultConfirm] = useState(false);
  const [openResetConfirm, setOpenResetConfirm] = useState(false);
  const [existingLayouts, setExistingLayouts] = useState([]);
  const [openRequestOnCall, setOpenRequestOnCall] = useState(false);
  const [openCustomGroupModal, setOpenCustomGroupModal] = useState(false);
  const [editGroup, setEditGroup] = useState(false);
  const [userIsResponsibleStaff, setUserIsResponsibleStaff] = useState(false);

  useEffect(() => {
    const layouts = group.layouts.map(layout => ({
      key: layout.id,
      label: `${layout.name} - Room ${layout.roomName} ${
        layout.isDefault ? "(Default)" : ""
      }`,
      value: layout.id,
    }));

    setExistingLayouts(layouts);
    setUserIsResponsibleStaff(
      group.responsibleStaff.some(staff => user.id === staff.id)
    );
  }, [group]);

  const confirmDelete = () => {
    classViewerActions.deleteClassLayout(selectedClassLayout.classLayoutId);
    setOpenDeleteConfirm(false);
  };

  const onChangeLayoutDropdown = value => {
    handleSelectLayout(value);
  };

  // const confirmDeleteRoomLayout = () => {
  //   handleDeleteRoomLayout();
  //   setOpenDeleteRoomConfirm(false);
  // };

  const confirmSetDefaultLayout = () => {
    groupActions.setDefaultLayout(selectedLayoutId);
    setOpenSetDefaultConfirm(false);
  };

  const confirmReset = () => {
    handleResetLayout();
    setOpenResetConfirm(false);
  };

  const handleUpdateClassLayout = updatedLayout => {
    const newClassLayoutData = getUpdateClassLayoutPayload(updatedLayout);

    classViewerActions.updateClassLayout(
      updatedLayout.classLayoutId,
      newClassLayoutData
    );
  };

  const isLayoutEmpty = () => {
    const slots = studentSlots;
    for (let i = 0; i < slots.length; i++) {
      if (slots[i].student !== null) {
        return false;
      }
    }
    return true;
  };

  const onClickPrintLayout = () => {
    window.open(
      `/#/users/${user ? user.id : null}/groups/${
        group ? group.id : null
      }/grouptype/${group ? group.type : null}/rooms/${
        classLayout?.roomId
      }/roomLayouts/${classLayout?.roomLayoutId}/layouts/${
        classLayout?.classLayoutId
      }/layoutDirection/${layoutDirection}/isPrivate/${
        isPrivate.sensitiveInfo
      }/noProfileImage/${isPrivate.profileImage}/print`,
      "_blank"
    );
  };

  const pickRandomStudentFromClass = () => {
    handlePickAStudent(studentQuiz.pickRandomStudent(group.students));
  };

  const handleEditGroup = () => {
    setEditGroup(true);
    setOpenCustomGroupModal(true);
  };

  return (
    <Wrapper>
      <ApiExceptionMessage error={errorDeletingGroup} />
      <ActionBar>
        <Left>
          <Dropdown
            className="layout-dropdown"
            placeholder="Seating Plan"
            items={existingLayouts}
            onChange={onChangeLayoutDropdown}
            value={selectedLayoutId}
            loading={loading}
          />

          {(group.type !== Constants.GROUP_TYPES.CUSTOM ||
            (group.type === Constants.GROUP_TYPES.CUSTOM &&
              group.isActive)) && (
            <SplitButtonWrapper>
              <SplitButton
                size={Size.Small}
                text="Seating Plan Actions"
                color={Swatches.Primary}
                disabled={loading}
              >
                <SplitButton.Option
                  onClick={openLayoutCreator}
                  text="New Seating Plan"
                  tooltip="New Seating Plan"
                  tooltipSub="Create a new seating plan"
                  show={userIsResponsibleStaff}
                />
                <SplitButton.Option
                  onClick={() => setOpenSetDefaultConfirm(true)}
                  text="Set Default"
                  tooltip="Set as Default Seating Plan"
                  tooltipSub="Set this seating plan as the default plan for this class"
                  show={
                    userIsResponsibleStaff &&
                    selectedClassLayout.studentSlots.length > 0 &&
                    group.layouts &&
                    group.layouts.length > 1 &&
                    !isDefault
                  }
                />
                <SplitButton.Option
                  text="Rearrange Seating Plan"
                  onClick={() =>
                    flyoutActions.openFlyout(Constants.FLYOUTS.REARRANGE_LAYOUT)
                  }
                  tooltip="Rearrange Students"
                  tooltipSub="Rearrange students by choosing a layout option"
                  show={userIsResponsibleStaff}
                />
                <SplitButton.Option
                  className="edit-layout-button"
                  text="Clear Seating Plan"
                  onClick={() => setOpenResetConfirm(true)}
                  tooltip="Clear Seating Plan"
                  tooltipSub="Clear seating plan by unseating all students"
                  show={userIsResponsibleStaff && !isLayoutEmpty()}
                />
                <SplitButton.Option
                  onClick={() => handleUpdateClassLayout(selectedClassLayout)}
                  text="Save Seating Plan"
                  tooltip="Save Changes"
                  tooltipSub="Save any changes made to the seating plan"
                  show={
                    userIsResponsibleStaff &&
                    selectedClassLayout.studentSlots.length > 0
                  }
                />
                <SplitButton.Option
                  onClick={onClickPrintLayout}
                  text="Print Seating Plan"
                  tooltip="Print Seating Plan"
                  tooltipSub="Get a printed copy of your seating plan"
                  show={true}
                />
                <SplitButton.Option
                  onClick={() => setOpenDeleteConfirm(true)}
                  text="Delete Seating Plan"
                  tooltip="Delete Seating Plan"
                  tooltipSub="Delete the selected seating plan"
                  show={
                    userIsResponsibleStaff &&
                    selectedClassLayout.studentSlots.length > 0
                  }
                  color={Swatches.Danger}
                />
                {/* <SplitButton.Option
                  onClick={() => setOpenDeleteRoomConfirm(true)}
                  text="Delete Room Layout"
                  tooltip="Delete Room Layout"
                  tooltipSub="Delete table layout of the room"
                  show={
                    userIsResponsibleStaff &&
                    selectedClassLayout.studentSlots.length > 0
                  }
                  color={Swatches.Danger}
                /> */}
              </SplitButton>
            </SplitButtonWrapper>
          )}
        </Left>

        <Right>
          <ClassLayoutStudentFilters
            groupId={group.id}
            groupType={group.type}
          />

          <GroupActionButton
            selectedStudents={selectedStudents}
            handleSelectAllStudents={handleSelectAllStudents}
            handleUnselectAllStudents={handleUnselectAllStudents}
            handleRequestOnCall={() => setOpenRequestOnCall(true)}
            handleAddToCustomGroupClick={() => setOpenCustomGroupModal(true)}
            showAddToCustomGroup={group.type !== Constants.GROUP_TYPES.CUSTOM}
            group={group}
            handleEditGroup={handleEditGroup}
            showPickAStudent
            handlePickAStudent={pickRandomStudentFromClass}
            loading={loading}
          />
        </Right>
      </ActionBar>

      <ConfirmModal
        openModal={openResetConfirm}
        onOpen={() => setOpenResetConfirm(true)}
        onClose={() => setOpenResetConfirm(false)}
        onConfirm={confirmReset}
        confirmMsg="This will clear your seating plan. Are you sure you want to do this?
        (You can go back to your plan if you don't save it)"
      />

      <ConfirmModal
        openModal={openDeleteConfirm}
        onOpen={() => setOpenDeleteConfirm(true)}
        onClose={() => setOpenDeleteConfirm(false)}
        onConfirm={confirmDelete}
        confirmMsg="This will delete your seating plan permanently. Are you sure you want
        to do this?"
      />
      <ConfirmModal
        openModal={openSetDefaultConfirm}
        onOpen={() => setOpenSetDefaultConfirm(true)}
        onClose={() => setOpenSetDefaultConfirm(false)}
        onConfirm={confirmSetDefaultLayout}
        confirmMsg="This will set the seating plan as the default plan for the class group. 
        A default seating plan will be the plan that will load first when you select a class 
        group and will appear at the top of your seating plan list."
      />

      {/* <Modal
        onClose={() => setOpenDeleteRoomConfirm(false)}
        open={openDeleteRoomConfirm}
        title="Confirm Delete Room Layout"
        height="fit-content"
        width="50%"
      >
        <Modal.Body>
          <Message
            text="WARNING - This will delete seating plans for other staff members"
            color={Swatches.Danger}
          />
          <ConfirmText>
            Deleting the room layout is not the same as deleting a seating plan.
            This will delete the room table layout permanently and also delete
            class seating plans for other staff members that are using this
            room.
          </ConfirmText>

          <br />

          <ConfirmText>
            Are you sure you want to do this? This cannot be undone.
          </ConfirmText>
        </Modal.Body>

        <Modal.Footer>
          <ActionBar>
            <Button
              color={Swatches.Danger}
              text="Delete"
              onClick={() => {
                setOpenDeleteRoomConfirmTwo(true);
                setOpenDeleteRoomConfirm(false);
              }}
            />
            <Button
              color={Swatches.Low}
              text="Cancel"
              onClick={() => setOpenDeleteRoomConfirm(false)}
            />
          </ActionBar>
        </Modal.Footer>
      </Modal> */}

      {/* <Modal
        onClose={() => setOpenDeleteRoomConfirmTwo(false)}
        open={openDeleteRoomConfirmTwo}
        title="Confirm Delete Room Layout"
        height="fit-content"
        width="50%"
      >
        <Modal.Body>
          <Message
            text="WARNING - This will delete seating plans for other staff members"
            color={Swatches.Danger}
          />
          <ConfirmText>
            Are you sure you want to do this? This cannot be undone.
          </ConfirmText>
        </Modal.Body>

        <Modal.Footer>
          <ActionBar>
            <Button
              color={Swatches.Danger}
              text="Delete"
              onClick={confirmDeleteRoomLayout}
            />
            <Button
              color={Swatches.Low}
              text="Cancel"
              onClick={() => setOpenDeleteRoomConfirmTwo(false)}
            />
          </ActionBar>
        </Modal.Footer>
      </Modal> */}

      {openRequestOnCall && (
        <CreateOnCallRequestWrapper
          students={selectedStudents}
          handleUnselectAllStudents={handleUnselectAllStudents}
          groupType={group?.type}
          groupId={group?.id}
          schoolId={group?.school?.id}
          openModal={openRequestOnCall}
          handleCloseModal={() => setOpenRequestOnCall(false)}
        />
      )}

      {openCustomGroupModal && (
        <CustomGroupEditorModal
          open={openCustomGroupModal}
          onClose={() => {
            setOpenCustomGroupModal(false);
            setEditGroup(false);
          }}
          students={selectedStudents}
          handleUnselectAllStudents={handleUnselectAllStudents}
          schoolId={group?.school?.id}
          groupId={editGroup ? group.id : null}
        />
      )}

      <Flyout
        title="Rearrange Seating Plan"
        name={Constants.FLYOUTS.REARRANGE_LAYOUT}
        wide
      >
        <Flyout.Body>
          <LayoutGeneratorWrapper />
        </Flyout.Body>
      </Flyout>
    </Wrapper>
  );
};

ClassLayoutMenuWrapper.propTypes = {
  handleResetLayout: PropTypes.func,
  handleUpdateClassLayout: PropTypes.func,
  handleDeleteLayout: PropTypes.func,
  handleSetDefault: PropTypes.func,
  isDefault: PropTypes.bool,
  selectedLayoutId: PropTypes.number,
  handleSelectLayout: PropTypes.func,
  onDragStart: PropTypes.func,
  onDropStudentList: PropTypes.func,
  studentClassLayout: PropTypes.object,
  setDisplayLayoutCreator: PropTypes.func,
};

export default ClassLayoutMenuWrapper;
