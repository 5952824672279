import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ProvisionCostBandsTable from "./provisionCostBandsTable";
import provisionCostBandsActions from "areas/send/actions/provisions/provisionCostBandsActions";
import { RootState } from "reducers/store";


const ProvisionCostBands = () => {

  const { error, loading, bands } = useSelector(
    (state: RootState) => state.provisionCostBands
  );

  useEffect(() => {
    provisionCostBandsActions.getProvisionCostBands();
  }, []);

  return (
    <ProvisionCostBandsTable bands={bands} loading={loading} error={error} />
  );
};

export default ProvisionCostBands;
