import allRewardCategoriesReducer from "./allRewardCategoriesReducer";
import allRewardCodesReducer from "./allRewardCodesReducer";
import initialRewardActionsReducer from "./initialRewardActionsReducer";
import rewardCategoriesReducer from "./rewardCategoriesReducer";
import rewardCodesReducer from "./rewardCodesReducer";
import rewardForMultipleReducer from "./rewardForMultipleReducer";
import rewardReducer from "./rewardReducer";

const rewardReducers = {
  allRewardCategories: allRewardCategoriesReducer,
  allRewardCodes: allRewardCodesReducer,
  initialRewardActions: initialRewardActionsReducer,
  rewardCategories: rewardCategoriesReducer,
  rewardCodes: rewardCodesReducer,
  rewardForMultiple: rewardForMultipleReducer,
  reward: rewardReducer,
};

export default rewardReducers;
