import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import FileDownload from "js-file-download";
import { BeehiveReport, callbackType } from "types/common/action.types";
import { GenerateLessonExitReportCommand } from "../types/lessonExitAnalyticRequest.types";
import { GenerateLessonExitReportView } from "../types/lessonExitAnalyticsResponse.types";

const builder = new actionTypeBuilder("leavingLessonAnalytics");

const types = {
  GETLEAVINGLESSONREPORT: builder.build("getleavinglessonreport"),
  EXPORTLEAVINGLESSONREPORT: builder.build("exportleavinglessonreport"),
};

const getLeavingLessonReport = (payload: GenerateLessonExitReportCommand, onSuccess?: callbackType<GenerateLessonExitReportView>) => {
  return action<GenerateLessonExitReportView>(
    () => client.post(`planner/reporting/lessonExits`, payload),
    types.GETLEAVINGLESSONREPORT,
    onSuccess
  );
};

const exportLeavingLessonReport = (payload: GenerateLessonExitReportCommand, onSuccess?: callbackType<BeehiveReport>) => {
  return action<BeehiveReport>(
    () =>
      client.post(`planner/reporting/lessonExits/export`, payload, {
        responseType: "blob",
      }),
    types.EXPORTLEAVINGLESSONREPORT,
    response => {
      onSuccess && onSuccess(response);
      FileDownload(response, "Leaving Lesson Report.csv");
    }
  );
};

const leavingLessionReportActions = {
  types,
  getLeavingLessonReport,
  exportLeavingLessonReport,
};

export default leavingLessionReportActions;
