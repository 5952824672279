import interventionActions from "areas/planner/actions/interventions/interventionActions";
import { BeehiveReport, IBeehiveAction } from "types/common/action.types";

interface IInterventionReportingState {
  loading: boolean;
  error: string | null;
  report: BeehiveReport | null;
}

const INITIAL_STATE: IInterventionReportingState = {
  loading: false,
  error: null,
  report: null,
};

const interventionReportingReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IInterventionReportingState => {

  const {
    INTERVENTIONPROGRESS_STUDENTREPORT,
    INTERVENTIONPROGRESS_GROUPREPORT
  } = interventionActions.types;

  switch (action.type) {

    case INTERVENTIONPROGRESS_STUDENTREPORT.START:
    case INTERVENTIONPROGRESS_GROUPREPORT.START:
      return { 
        ...state, 
        loading: true,
        error: null,
        report: null
      };

    case INTERVENTIONPROGRESS_STUDENTREPORT.SUCCESS:
    case INTERVENTIONPROGRESS_GROUPREPORT.SUCCESS:
      return { 
        ...state, 
        loading: false,
        error: null,
        report: action.payload
      };

    case INTERVENTIONPROGRESS_STUDENTREPORT.FAILED:
      case INTERVENTIONPROGRESS_GROUPREPORT.FAILED:
        return { 
          ...state, 
          loading: false,
          error: action.payload
        };

    default:
      return state;
  }
};

export default interventionReportingReducer;
