import React from "react";
import { Message, Swatches } from "ui-kit";


interface ITriggeredDetentionMessageProps {
  ruleName: string;
}


const TriggeredDetentionMessage: React.FC<ITriggeredDetentionMessageProps> = ({ ruleName }) => {

  return (
    <Message
      text={`"${ruleName}" rule has been triggered by the student and therefore a sanction must be given.`}
      color={Swatches.Danger}
    />
  );
};

export default TriggeredDetentionMessage;
