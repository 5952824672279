export const getArrayOfRows = number => {
  let array = [];
  for (let i = number; i > 0; i--) {
    array.push(i);
  }
  return array;
};

export const getArrayOfCols = number => {
  let array = [];
  for (let i = 1; i <= number; i++) {
    array.push(i);
  }
  return array;
};

// Sort students by ascending lastname
export const sortByStudentName = (student1, student2) => {
  if (student1.lastName < student2.lastName) {
    return -1;
  }
  if (student1.lastName > student2.lastName) {
    return 1;
  }
  return 0;
};

// Find index of student already on the grid using student id
export const findCurrentPosition = (id, studentSlots) => {
  const index = studentSlots.findIndex(
    studentPosition =>
      studentPosition.student !== null && studentPosition.student.id === id
  );
  return index;
};

// Find index of position student is to be moved to using coordinates
export const findNewPosition = (x, y, studentSlots) => {
  return studentSlots.findIndex(
    studentPosition =>
      studentPosition.coordinates.x === x && studentPosition.coordinates.y === y
  );
};

// Change position of student already on the grid
export const changeStudentPositionOnGrid = (id, x, y, studentClassLayout) => {
  const tempstudentSlots = studentClassLayout.studentSlots.slice();
  let currentPosition = findCurrentPosition(id, tempstudentSlots);
  let newPosition = findNewPosition(x, y, tempstudentSlots);

  if (tempstudentSlots[newPosition].student === null) {
    moveStudent(tempstudentSlots, currentPosition, newPosition);
    return {
      ...studentClassLayout,
      studentSlots: tempstudentSlots,
    };
  }
};

// Move student on the grid
export const moveStudent = (studentSlots, currentPosition, newPosition) => {
  const studentToMove = studentSlots[currentPosition].student;
  studentSlots[currentPosition].student = null;
  studentSlots[newPosition].student = studentToMove;
};

// Swap students either on the grid or from unseated students
export const swapStudentPositionOnGrid = (
  id,
  studentToSwapWithId,
  studentClassLayout
) => {
  const tempstudentSlots = studentClassLayout.studentSlots.slice();
  let studentAPosition = findCurrentPosition(id, tempstudentSlots);
  let studentBPosition = findCurrentPosition(
    studentToSwapWithId,
    tempstudentSlots
  );
  if (studentAPosition !== -1) {
    swapStudents(tempstudentSlots, studentAPosition, studentBPosition);
    return {
      ...studentClassLayout,
      studentSlots: tempstudentSlots,
    };
  } else {
    let newLayout = removeStudentFromGrid(
      studentToSwapWithId,
      studentClassLayout
    );
    const coordinates = tempstudentSlots[studentBPosition].coordinates;
    newLayout = addStudentToGrid(id, coordinates.x, coordinates.y, newLayout);

    return newLayout;
  }
};

// Swap students using index
export const swapStudents = (studentSlots, currentPosition, newPosition) => {
  const studentA = studentSlots[newPosition].student;
  const studentB = studentSlots[currentPosition].student;
  studentSlots[currentPosition].student = studentA;
  studentSlots[newPosition].student = studentB;
};

// Add a student to the grid from unseated students list
export const addStudentToGrid = (id, x, y, studentClassLayout) => {
  const unseatedStudents = studentClassLayout.unseatedStudents.slice();
  const studentToAdd = unseatedStudents.find(student => student.id === id);
  const tempUnseatedStudents = unseatedStudents.filter(
    student => student.id !== id
  );

  const tempstudentSlots = studentClassLayout.studentSlots.slice();
  const position = findNewPosition(x, y, tempstudentSlots);

  if (tempstudentSlots[position].student === null) {
    tempstudentSlots[position].student = studentToAdd;
    tempUnseatedStudents.sort(sortByStudentName);

    return {
      ...studentClassLayout,
      studentSlots: tempstudentSlots,
      unseatedStudents: tempUnseatedStudents,
    };
  }
};

// Add student to an empty slot on the grid either by moving the from another slot or from unseated students
export const addStudentToEmptySlot = (id, x, y, studentClassLayout) => {
  const currentPosition = findCurrentPosition(
    id,
    studentClassLayout.studentSlots
  );
  if (currentPosition !== -1) {
    return changeStudentPositionOnGrid(id, x, y, studentClassLayout);
  } else {
    return addStudentToGrid(id, x, y, studentClassLayout);
  }
};

// Remove a student from a student slot to unseated students list
export const removeStudentFromGrid = (id, studentClassLayout) => {
  let position = findCurrentPosition(id, studentClassLayout.studentSlots);
  if (position !== -1) {
    const tempstudentSlots = studentClassLayout.studentSlots.slice();

    const studentToRemove = tempstudentSlots[position].student;
    tempstudentSlots[position].student = null;

    const tempUnseatedStudents = [
      ...studentClassLayout.unseatedStudents,
      studentToRemove,
    ];
    tempUnseatedStudents.sort(sortByStudentName);

    return {
      ...studentClassLayout,
      studentSlots: tempstudentSlots,
      unseatedStudents: tempUnseatedStudents,
    };
  }
};

// Reset all student slots to null
export const removeAllStudentsFromPositions = studentClassLayout => {
  const tempstudentSlots = studentClassLayout.studentSlots.slice();
  const tempUnseatedStudents = studentClassLayout.unseatedStudents.slice();
  tempstudentSlots.forEach(position => {
    if (position.student !== null) {
      tempUnseatedStudents.push(position.student);
      position.student = null;
    }
  });

  tempUnseatedStudents.sort(sortByStudentName);

  return {
    ...studentClassLayout,
    studentSlots: tempstudentSlots,
    unseatedStudents: tempUnseatedStudents,
  };
};

export const findEmptySlot = (dropzone, enter) => {
  while (
    dropzone.getAttribute("class") &&
    !dropzone.getAttribute("class").includes("empty-slot-wrapper")
  ) {
    dropzone = dropzone.parentNode;
  }

  if (
    dropzone.getAttribute("class") &&
    dropzone.getAttribute("class").includes("empty-slot-wrapper")
  ) {
    if (enter) {
      dropzone.classList.add("display-add");
    } else {
      dropzone.classList.remove("display-add");
    }
    return true;
  } else {
    return false;
  }
};

export const handleOnDropGrid = (
  eventTarget,
  studentBeingDraggedId,
  col,
  row,
  classLayout
) => {
  let dropzone = eventTarget;

  if (
    dropzone.getAttribute("class") &&
    (dropzone.getAttribute("class").includes("dropzone") ||
      findEmptySlot(dropzone) === true)
  ) {
    const newClassLayout = addStudentToEmptySlot(
      studentBeingDraggedId,
      col,
      row,
      classLayout
    );
    return newClassLayout;
  } else {
    while (
      dropzone.getAttribute("class") &&
      !dropzone.getAttribute("class").includes("student on-grid")
    ) {
      dropzone = dropzone.parentNode;
    }

    if (
      dropzone.getAttribute("class") &&
      dropzone.getAttribute("class").includes("student on-grid")
    ) {
      const newClassLayout = swapStudentPositionOnGrid(
        studentBeingDraggedId,
        dropzone.getAttribute("data-id"),
        classLayout
      );
      return newClassLayout;
    } else {
      return classLayout;
    }
  }
};

export const handleOnDropStudentList = (
  eventTarget,
  studentBeingDraggedId,
  classLayout,
  removeStudentFromGridLayout
) => {
  let dropzone = eventTarget;

  const currentPosition = findCurrentPosition(
    studentBeingDraggedId,
    classLayout.studentSlots
  );

  if (
    dropzone.getAttribute("class").includes("dropzone") &&
    currentPosition !== -1
  ) {
    removeStudentFromGridLayout(studentBeingDraggedId);
  } else {
    while (
      dropzone.getAttribute("class") &&
      !dropzone.getAttribute("class").includes("dropzone")
    ) {
      dropzone = dropzone.parentNode;
    }
    if (
      dropzone.getAttribute("class") &&
      dropzone.getAttribute("class").includes("dropzone") &&
      currentPosition !== -1
    ) {
      removeStudentFromGridLayout(studentBeingDraggedId);
    }
  }
};

export const getUpdateClassLayoutPayload = layout => {
  const studentSlots = layout.studentSlots;
  const nonEmptyStudentPositions = studentSlots.filter(
    studentPosition => studentPosition.student !== null
  );
  const populatedStudentPositions = nonEmptyStudentPositions.map(
    studentPosition => ({
      studentId: studentPosition.student.id,
      coordinates: studentPosition.coordinates,
    })
  );

  return {
    id: layout.classLayoutId,
    name: layout.classLayoutName,
    roomLayoutId: layout.roomLayoutId,
    classGroupId: layout.classGroupId,
    tutorGroupId: layout.tutorGroupId,
    customGroupId: layout.customGroupId,
    provisionGroupId: layout.provisionGroupId,
    saveStudentPositions: populatedStudentPositions,
  };
};

export const GRID_PROP = {
  ROWS: 0,
  COLS: 1,
};

export const GRID_CHANGE = {
  MINUS: 0,
  ADD: 1,
};

export const changeNumberOfRows = (
  change,
  cols,
  roomSlots,
  setRows,
  rows
  // setRowsArray,
  // rowsArray
) => {
  const tempRoomSlots = roomSlots.slice();

  if (change === GRID_CHANGE.ADD) {
    for (let i = 1; i <= cols; i++) {
      tempRoomSlots.push({
        x: i,
        y: rows + 1,
        isASeat: false,
      });
    }

    setRows(rows + 1);
  } else {
    for (let i = 1; i <= cols; i++) {
      const index = tempRoomSlots.findIndex(
        slot => slot.y === rows && slot.x === i
      );
      tempRoomSlots.splice(index, 1);
    }
    setRows(rows - 1);
  }

  return tempRoomSlots;
};

export const changeNumberOfCols = (change, cols, roomSlots, setCols, rows) => {
  const tempRoomSlots = roomSlots.slice();

  if (change === GRID_CHANGE.ADD) {
    for (let i = rows; i > 0; i--) {
      tempRoomSlots.push({
        x: cols + 1,
        y: i,
        isASeat: false,
      });
    }
    setCols(cols + 1);
  } else {
    for (let i = rows; i > 0; i--) {
      const index = tempRoomSlots.findIndex(
        slot => slot.y === i && slot.x === cols
      );
      tempRoomSlots.splice(index, 1);
    }
    setCols(cols - 1);
  }

  return tempRoomSlots;
};

export const checkIsDropzone = (row, col, studentSlots) => {
  for (let i = 0; i < studentSlots.length; i++) {
    const slot = studentSlots[i].coordinates;
    if (slot.y === row && slot.x === col) {
      return true;
    }
  }
  return false;
};
