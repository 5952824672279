import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FileUpload from "./fileUpload";
import FileImport from "./fileImport";
import FileList from "./fileList";
import { arrays } from "utils";
import { IAcceptedFileTypes, IBeehiveFile, IFileMetadata } from "types/common/files.types";


export const FilePickerWrapper = styled.div``;

interface IFilePickerProps {
  files: IBeehiveFile[];
  onFilesChanged: (filedata: (IFileMetadata | IBeehiveFile)[] | string | ArrayBuffer | null | undefined ) => void;
  single?: boolean;
  importFile?: boolean;
  fileTypes?: IAcceptedFileTypes[];
}

const FilePicker: React.FC<IFilePickerProps> = ({ files, onFilesChanged, single, importFile, fileTypes }) => {

  const [_files, _setFiles] = useState<(IFileMetadata | IBeehiveFile)[]>([]);

  useEffect(() => {
    files &&
      _setFiles(
        files.map((x) => ({ ...x, action: x.action == null ? 1 : x.action }))
      );
  }, [files]);

  const updateFiles = (newFiles : (IFileMetadata | IBeehiveFile)[]) => {
    _setFiles(newFiles);
    onFilesChanged?.(newFiles);
  };

  const handleUpload = (uploadedFiles: (IFileMetadata | IBeehiveFile)[]) => {
    updateFiles(
      single
        ? [{ ...uploadedFiles[0], action: 0 }]
        : [..._files, ...uploadedFiles.map((x) => ({ ...x, action: 0 }))]
    );
  };

  const handleImport = (filedata: string | ArrayBuffer | null | undefined, fileMetadata: IFileMetadata[]) => {
    _setFiles( single
        ? [{ ...fileMetadata[0], action: 0 }]
        : [..._files, ...fileMetadata.map((x) => ({ ...x, action: 0 }))]
    );
    onFilesChanged?.(filedata);
  }

  const handleRemoveFile = (file: IFileMetadata | IBeehiveFile, index: number) => {
    if (file.action === 0) {
      updateFiles([...arrays.removeItem(_files, file)]);
    } else {
      updateFiles([
        ..._files.map((x, i) =>
          x.id === file.id && x.filename === file.filename // This check is to ensure we don't delete an newly uploaded file with the same ID by mistake
            ? { ...x, action: 2 }
            : x
        ),
      ]);
    }
  };

  return (
    <FilePickerWrapper>
      { importFile ? (
        <FileImport onImport={handleImport} fileTypes={fileTypes} />
      ) : (
        <FileUpload onUpload={handleUpload} single />
      )}
      <FileList>
        {_files
          ?.filter((x) => x.action == null || x.action < 2)
          .map((file, index) => (
            <FileList.Item
              key={index}
              onDelete={() => handleRemoveFile(file, index)}
              file={file as IBeehiveFile}
              clickToDownload={false}
            />
          ))}
      </FileList>
    </FilePickerWrapper>
  );
};

export default FilePicker;
