import React from "react";
import { Chip, Swatches } from "ui-kit";
import { Constants } from "configuration";
import { ProvisionGroupStatus as PGS } from "../../../../types/passportShared.types"


interface IProvisionGroupStatusProps {
  status: PGS;
  fluid?: boolean;
}


const ProvisionGroupStatus: React.FC<IProvisionGroupStatusProps> = ({ status, fluid }) => {

  var swatch = null;
  var name = "";

  switch (status) {
    case Constants.SEND_PROVISIONGROUPSTATUS.DRAFT.value:
      swatch = Swatches.Low;
      name = Constants.SEND_PROVISIONGROUPSTATUS.DRAFT.name;
      break;
    case Constants.SEND_PROVISIONGROUPSTATUS.LIVE.value:
      swatch = Swatches.Blue;
      name = Constants.SEND_PROVISIONGROUPSTATUS.LIVE.name;
      break;
    case Constants.SEND_PROVISIONGROUPSTATUS.COMPLETE.value:
      swatch = Swatches.Success;
      name = Constants.SEND_PROVISIONGROUPSTATUS.COMPLETE.name;
      break;
    default:
      swatch = Swatches.Danger;
      name = status?.toString();
      break;
  }

  return <Chip text={name} colorSwatch={swatch} fluid={fluid} />;
};

export default ProvisionGroupStatus;
