import { StaffView, UserName } from "sharedComponents/common";
import ContextMenu from "ui-kit/composite/contextMenu";
import {
  getGroupName,
  FeedItemContent,
  DateAndStudentName,
  FeedItemDetails,
  FeedItemActionButtons,
} from "../activityFeedItemComponents";
import DetentionStatusChip from "areas/behaviour/components/detentions/detentionStatusChip";
import BehaviourStatusChip from "areas/behaviour/components/behaviours/behaviourStatusChip";

const BehaviourActivity = ({
  activity,
  displayStudentName,
  allowChange,
  allowUpscale,
  handleEditActivity,
  handleUpscaleBehaviour,
  handleFeedClick,
}) => {
  const editable = allowChange();
  const upscaleable = allowUpscale();

  return (
    <>
      <FeedItemContent onClick={handleFeedClick}>
        <DateAndStudentName
          displayStudentName={displayStudentName}
          activity={activity}
        >
          {activity.detentionStatusId !== null && (
            <DetentionStatusChip detentionStatus={activity.detentionStatusId} />
          )}

          {activity.behaviourStatusId !== null && (
            <BehaviourStatusChip
              behaviourStatusId={activity.behaviourStatusId}
            />
          )}
        </DateAndStudentName>

        <FeedItemDetails>
          <b>{activity.categoryName}</b> given by{" "}
          <b>
            <UserName user={activity.createdBy} />
          </b>
          {!displayStudentName && getGroupName(activity)}
          <div className="note-content">{activity.publicNotes}</div>
        </FeedItemDetails>
      </FeedItemContent>

      <StaffView>
        <FeedItemActionButtons>
          {editable && upscaleable ? (
            <ContextMenu>
              <ContextMenu.Item
                text="Edit"
                onClick={() => handleEditActivity(activity)}
              />
              <ContextMenu.Item
                text="Upscale"
                onClick={() => handleUpscaleBehaviour(activity)}
              />
            </ContextMenu>
          ) : editable ? (
            <ContextMenu>
              <ContextMenu.Item
                text="Edit"
                onClick={() => handleEditActivity(activity)}
              />
            </ContextMenu>
          ) : (
            upscaleable && (
              <ContextMenu>
                <ContextMenu.Item
                  text="Upscale"
                  onClick={() => handleUpscaleBehaviour(activity)}
                />
              </ContextMenu>
            )
          )}
        </FeedItemActionButtons>
      </StaffView>
    </>
  );
};

export default BehaviourActivity;
