import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import FileDownload from "js-file-download";
import { callbackType } from "types/common/action.types";
import { ProvisionMapCommentView, ProvisionMapDetailView, ProvisionMapListView } from "areas/send/types/passportResponse.types";
import { SaveProvisionMapCommand, SaveProvisionMapCommentCommand } from "areas/send/types/passportRequest.types";

const builder = new actionTypeBuilder("send");

const types = {
  PROVISIONMAPS_GETFORSTUDENT: builder.build("provisionmaps_getforstudent"),
  PROVISIONMAP_GET: builder.build("provisionmap_get"),
  PROVISIONMAP_CREATE: builder.build("provisionmap_create"),
  PROVISIONMAP_SAVE: builder.build("provisionmap_save"),
  PROVISIONMAP_DELETE: builder.build("provisionmap_delete"),
  PROVISIONMAP_DOWNLOAD: builder.build("provisionmap_download"),
  PROVISIONMAPCOMMENT_CREATE: builder.build("provisionmapcomment_create"),
  PROVISIONMAPCOMMENT_SAVE: builder.build("provisionmapcomment_save"),
  PROVISIONMAPCOMMENT_DELETE: builder.build("provisionmapcomment_delete"),
};


const getProvisionMapsForStudent = (studentId: string, callback?: callbackType<ProvisionMapListView[]>) =>
  action<ProvisionMapListView[]>(
    () => client.get(`planner/send/students/${studentId}/provisionMaps`),
    types.PROVISIONMAPS_GETFORSTUDENT,
    callback
  );

const getProvisionMap = (studentId: string, provisionMapId: number, callback?: callbackType<ProvisionMapDetailView>) =>
  action<ProvisionMapDetailView>(
    () => client.get(`planner/send/students/${studentId}/provisionMaps/${provisionMapId}`),
    types.PROVISIONMAP_GET,
    callback
  );

const createProvisionMap = (data: SaveProvisionMapCommand, callback?: callbackType<ProvisionMapDetailView>) =>
  action<ProvisionMapDetailView>(
    () => client.post(`planner/send/students/${data.studentId}/provisionMaps`, data),
    types.PROVISIONMAP_CREATE,
    callback
  );

const saveProvisionMap = (data: SaveProvisionMapCommand, callback?: callbackType<ProvisionMapDetailView>) =>
  action<ProvisionMapDetailView>(
    () => client.post(`planner/send/students/${data.studentId}/provisionMaps/${data.id}`, data),
    types.PROVISIONMAP_SAVE,
    callback
  );

const downloadProvisionMap = (studentId: string, provisionMapId: number, callback?: callbackType<Blob>) =>
  action<Blob>(
    () => client.get(`planner/send/students/${studentId}/provisionMaps/${provisionMapId}/download`, { responseType: "blob" }),
    types.PROVISIONMAP_DOWNLOAD,
    (responseData, responseHeaders) => {
      const contentDisposition = responseHeaders["content-disposition"];
      var filename = contentDisposition.split("=");
      FileDownload(responseData, filename[1]);
      callback && callback(responseData);
    }
  );

const deleteProvisionMap = (studentId: string, provisionMapId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.delete(`planner/send/students/${studentId}/provisionMaps/${provisionMapId}`),
    types.PROVISIONMAP_DELETE,
    callback
  );

const createProvisionMapComment = (data: SaveProvisionMapCommentCommand, callback?: callbackType<ProvisionMapCommentView>) =>
  action<ProvisionMapCommentView>(
    () => client.post(`planner/send/students/${data.studentId}/provisionMaps/${data.provisionMapId}/comments`, data),
    types.PROVISIONMAPCOMMENT_CREATE,
    callback
  );

const saveProvisionMapComment = (data: SaveProvisionMapCommentCommand, callback?: callbackType<ProvisionMapCommentView>) =>
  action<ProvisionMapCommentView>(
    () => client.post(`planner/send/students/${data.studentId}/provisionMaps/${data.provisionMapId}/comments/${data.id}`, data),
    types.PROVISIONMAPCOMMENT_SAVE,
    callback
  );

const deleteProvisionMapComment = (
  studentId: string,
  provisionMapId: number,
  commentId: number,
  callback?: callbackType<number>
) =>
  action<number>(
    () => client.delete(`planner/send/students/${studentId}/provisionMaps/${provisionMapId}/comments/${commentId}`),
    types.PROVISIONMAPCOMMENT_DELETE,
    callback
  );


const provisionMapActions = {
  types,
  getProvisionMapsForStudent,
  getProvisionMap,
  createProvisionMap,
  saveProvisionMap,
  downloadProvisionMap,
  deleteProvisionMap,
  createProvisionMapComment,
  saveProvisionMapComment,
  deleteProvisionMapComment,
};

export default provisionMapActions;
