import magazineCategoriesReducer from "./magazine/magazineCategoriesReducer";
import magazineCategoryLatestReducer from "./magazine/magazineCategoryLatestReducer";
import magazineCategoryReducer from "./magazine/magazineCategoryReducer";
import magazineFeatureReducer from "./magazine/magazineFeatureReducer";
import magazineHomeReducer from "./magazine/magazineHomeReducer";
import magazineLatestReducer from "./magazine/magazineLatestReducer";
import articleCategoriesReducer from "./news/articleCategoriesReducer";
import articleFilterReducer from "./news/articleFilterReducer";
import articleReducer from "./news/articleReducer";
import articlesReducer from "./news/articlesReducer";
import bulletinFullReducer from "./news/bulletinFullReducer";
import bulletinReducer from "./news/bulletinReducer";
import bulletinsReducer from "./news/bulletinsReducer";
import bulletinTemplateReducer from "./news/bulletinTemplateReducer";
import bulletinTemplatesReducer from "./news/bulletinTemplatesReducer";
import newsUiReducer from "./news/newsUiReducer";

const news = {
  magazineCategories: magazineCategoriesReducer,
  magazineCategoryLatest: magazineCategoryLatestReducer,
  magazineCategory: magazineCategoryReducer,
  magazineFeature: magazineFeatureReducer,
  magazineHome: magazineHomeReducer,
  magazineLatest: magazineLatestReducer,
  articleCategories: articleCategoriesReducer,
  articleFilter: articleFilterReducer,
  article: articleReducer,
  articles: articlesReducer,
  bulletinFull: bulletinFullReducer,
  bulletin: bulletinReducer,
  bulletins: bulletinsReducer,
  bulletinTemplate: bulletinTemplateReducer,
  bulletinTemplates: bulletinTemplatesReducer,
  newsUi: newsUiReducer,
};

export default news;
