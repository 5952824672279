import store from "reducers/store";
import client from "services/client";
import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { CateringDetailsFilter } from "../reducers/catering/cateringPricingReducer";
import { CateringDetailsView } from "../types/catering/cateringResponses.types";

const builder = new actionTypeBuilder("payments");

export const types = {
  DETAILS_SETFILTER: "DETAILS_SETFILTER",
  DETAILS_GET: builder.build("DETAILS_GET")
};


export const setFilter = (filter: CateringDetailsFilter) => {
  store.dispatch<IBeehiveAction<CateringDetailsFilter>>({
    type: types.DETAILS_SETFILTER,
    payload: filter,
  });
};

const getCateringDetails = (callback?: callbackType<CateringDetailsView[]>) =>
  action<CateringDetailsView[]>(
    () => client.get(`payments/lunch/cateringDetails`),
    types.DETAILS_GET,
    callback
  );


const cateringActions = {
  types,
  setFilter,
  getCateringDetails
};

export default cateringActions;