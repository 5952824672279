import {
  ActionBar,
  Button,
  DatePicker,
  Size,
  Spacing,
  TextInput,
} from "ui-kit";
import { MultiSchoolUserView, SchoolDropdown } from "sharedComponents/common";
import YearGroupDropdown from "areas/behaviour/components/behaviourCommon/yearGroupDropdown";
import styled from "styled-components";
import BehaviourCategoriesDropdown from "areas/behaviour/components/behaviours/behaviorCategoriesDropdown";
import DetentionTypesDropdown from "areas/behaviour/components/detentions/detentionTypesDropdown";
import BehaviourCodeDropdown from "areas/behaviour/components/behaviours/behaviourCodeDropdown";
import AcademicYearFilter from "../../../../../sharedComponents/common/academicYearFilter";
import SearchFilterItemAndButton from "../../searchButton";
import React from "react";
import { YearGroupFilter } from "areas/behaviour/types/detentionRegisterRequest.types";
import { GroupListView } from "types/users/userGroups.types";
import { YearGroupFilterExt } from "./missedDetentionsWrapper";


const ActionBarItemWrapper = styled.div`
  .dropdown {
    width: 100%;
  }

  .date-picker,
  .dropdown {
    margin-bottom: ${Spacing.Medium}px;
  }
`;


interface IMissedDetentionsActionBarProps {
  filters: YearGroupFilterExt;
  onChangeFilters: (filter: YearGroupFilterExt) => void;
  schoolId: number;
  onChangeSchool: (value: number) => void;
  searchTerm: string;
  onChangeSearchTerm: (searchTerm: string) => void;
  yearGroups: GroupListView[];
  loading: boolean;
  onClickSearch: () => void;
  loadingExport: boolean;
  handleExportMissedDetentions: () => void;
}


const MissedDetentionsActionBar: React.FC<IMissedDetentionsActionBarProps> = ({
  filters,
  onChangeFilters,
  schoolId,
  onChangeSchool,
  searchTerm,
  onChangeSearchTerm,
  yearGroups,
  loading,
  onClickSearch,
  loadingExport,
  handleExportMissedDetentions,
}) => {

  const onChangeDate = (date: Date) => {
    onChangeFilters({ ...filters, date: date });
  };

  const onChangeDetentionType = (value: number) => {
    onChangeFilters({ ...filters, detentionTypeId: value });
  };

  const onChangeBehaviourCode = (value: number) => {
    onChangeFilters({ ...filters, behaviourCodeId: value });
  };

  const onChangeBehaviourCategory = (value: number) => {
    onChangeFilters({ ...filters, schoolBehaviourCategoryId: value });
  };

  const onChangeAcademicYear = (value: number) => {
    onChangeFilters({ ...filters, academicYearId: value });
  };

  return (
    <ActionBar>
      <ActionBar.Filter>
        <MultiSchoolUserView>
          <ActionBar.FilterItem title="School">
            <SchoolDropdown
              onChange={onChangeSchool}
              initialValue={schoolId}
              fluid
            />
          </ActionBar.FilterItem>
        </MultiSchoolUserView>

        <ActionBar.FilterItem title="Academic Year">
          <ActionBarItemWrapper>
            <AcademicYearFilter
              value={filters.academicYearId}
              onChange={onChangeAcademicYear}
            />
          </ActionBarItemWrapper>
        </ActionBar.FilterItem>

        <ActionBar.FilterItem title="Detention">
          <ActionBarItemWrapper>
            <DatePicker
              placeholder="Detention Date"
              dateFormat="DD/MM/YYYY"
              selectedDate={filters.date}
              onChange={onChangeDate}
              closeOnSelect
              fluid
              clearable
            />
            <DetentionTypesDropdown
              schoolId={schoolId}
              selected={filters.detentionTypeId}
              onChange={onChangeDetentionType}
            />
          </ActionBarItemWrapper>
        </ActionBar.FilterItem>

        <ActionBar.FilterItem title="Behaviour">
          <ActionBarItemWrapper>
            <BehaviourCodeDropdown
              schoolId={schoolId}
              selected={filters.behaviourCodeId}
              onChange={onChangeBehaviourCode}
            />
            <BehaviourCategoriesDropdown
              schoolId={schoolId}
              behaviourCodeFilter={filters.behaviourCodeId}
              selected={filters.schoolBehaviourCategoryId}
              onChange={onChangeBehaviourCategory}
            />
          </ActionBarItemWrapper>
        </ActionBar.FilterItem>

        <ActionBar.FilterItem title="Year Group">
          <YearGroupDropdown
            yearGroups={yearGroups}
            selectedYearGroup={filters.yearGroupId}
            handleSelectYearGroup={value =>
              onChangeFilters({ ...filters, yearGroupId: value })
            }
          />
        </ActionBar.FilterItem>

        <ActionBar.FilterItem title="Search">
          {/* <SearchFilterItemAndButton onClickSearch={onClickSearch}> */}
          <TextInput
            value={searchTerm}
            fluid
            placeholder="Search Detentions... "
            onChange={onChangeSearchTerm}
            loading={loading}
            icon="search"
          />
          {/* </SearchFilterItemAndButton> */}
        </ActionBar.FilterItem>
      </ActionBar.Filter>
    </ActionBar>
  );
};

export default MissedDetentionsActionBar;
