import React from "react";
import PropTypes from "prop-types";
import StudentModal from "sharedComponents/common/users/studentModal";
import UpscaleBehaviourFormWrapper from "./upscaleBehaviourFormWrapper";
import UpscaleDetentionFormWrapper from "../../detentions/upscale/upscaleDetentionFormWrapper";
import { CODE_SEVERITY_INDEX } from "areas/behaviour/constants/behaviours";
import { StudentFeedListView } from "types/planner/feedResponse.types";


interface IUpscaleBehaviourModalProps {
  activity: StudentFeedListView;
  open: boolean;
  onClose: () => void;
}


const UpscaleBehaviourModal: React.FC<IUpscaleBehaviourModalProps> = ({ activity, open, onClose }) => {

  return (
    <StudentModal
      onClose={onClose}
      open={open}
      title="Upscale"
      width="75%"
      height="90%"
      student={activity && activity.students[0]}
    >
      {activity && open && (
        <>
          {activity.codeSeverityIndex === CODE_SEVERITY_INDEX.C1 ? (
            <UpscaleBehaviourFormWrapper
              activity={activity}
              handleCloseModal={onClose}
            />
          ) : (
            activity.codeSeverityIndex === CODE_SEVERITY_INDEX.C2 && (
              <UpscaleDetentionFormWrapper
                activity={activity}
                handleCloseModal={onClose}
              />
            )
          )}
        </>
      )}
    </StudentModal>
  );
};


export default UpscaleBehaviourModal;
