import styled from "styled-components";
import { ProfileImage, SchoolIcon } from "sharedComponents/common";
import {
  Icon,
  Size,
  Spacing,
  displayFont,
  fontStyle,
  neutral,
  typescale,
} from "../../../../ui-kit";
import { users } from "utils";
import StudentSensitiveData from "./studentSensitiveData/studentSensitiveData";

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    ${({ onClick }) => onClick && `cursor: pointer;`}
  }

  .profile-image {
    margin-right: ${Spacing.Default}px;
  }

  .title-wrapper {
    .title {
      display: flex;
      align-items: center;
      ${fontStyle(displayFont.medium, typescale.header2, neutral[600])}

      .school-icon {
        margin-left: ${Spacing.Default}px;
      }
    }

    .title-sub {
      display: flex;
      align-items: center;
      ${fontStyle(displayFont.light, typescale.header4, neutral[500])}
      font-size: 1rem;

      .dot-icon {
        font-size: 0.22rem;
        margin: 0 ${Spacing.Small + 2}px;
      }

      .student-sensitive-info {
        margin-left: ${Spacing.Default}px;
        .chip-list .chip {
          padding: 0 ${Spacing.Small + 2}px;
          font-size: 0.6rem;
        }
      }
    }
  }
`;

const StudentTitle = ({ student, onClick }) => {
  const dot = (
    <Icon
      className="dot-icon"
      value="circle"
      size={Size.ExtraSmall}
      color={neutral[500]}
    />
  );
  const tutorGroupName = student?.tutorGroup?.friendlyName;
  const houseName = student?.house?.friendlyName;

  const getSub = () => {
    let sub = null;
    if (tutorGroupName && houseName) {
      sub = (
        <>
          {tutorGroupName} {dot} {houseName} {dot} {student.simsId}
        </>
      );
    } else if (student.tutorGroup) {
      sub = (
        <>
          {tutorGroupName} {dot} {student.simsId}
        </>
      );
    } else if (student.house) {
      sub = (
        <>
          {houseName} {dot} {student.simsId}
        </>
      );
    }

    return (
      <>
        {sub}
        <StudentSensitiveData student={student} compact />
      </>
    );
  };

  return (
    <Wrapper onClick={onClick}>
      <ProfileImage user={student} size={Size.Large} />

      <div className="title-wrapper">
        <div className="title">
          {users.getFullName(student)}{" "}
          {student.school && (
            <SchoolIcon code={student.school.code} size={Size.Small} />
          )}
        </div>

        <div className="title-sub">{getSub()}</div>
      </div>
    </Wrapper>
  );
};

export default StudentTitle;
