import React from "react";
import styled from "styled-components";
import {
  Icon,
  Title,
  Sub,
  TitleSize,
  Size,
  honeygold,
  Spacing,
  neutral,
} from "../index";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export interface IEmptyMessageProps {
  title: string;
  summary?: string | JSX.Element;
  icon?: string | IconDefinition;
  iconColor?: string;
  cover?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  subStyle?: object;
}

const Wrapper = styled.div<{ cover: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
  flex-grow: ${props => (props.cover ? "1" : 0)};
  align-content: center !important;
  text-align: center !important;

  i.icon {
    display: inline-block;
    margin-bottom: ${Spacing.Default}px;
    text-align: center !important;
  }

  .emptymessage-body {
    margin-bottom: ${Spacing.Large}px;
    text-align: center !important;
    align-content: center !important;
    justify-content: center !important;

    h4 {
      width: auto;
      padding-left: 0;
      border-left: 0;
      margin: 0 auto 0 auto;
      text-align: center !important;
      justify-content: center !important;
      display: flex;
    }
    h3,
    span {
      color: ${neutral[600]};
      text-align: center !important;
    }
  }
`;

const EmptyMessage: React.FC<IEmptyMessageProps> = ({
  title,
  summary,
  icon,
  cover = false,
  children,
  className,
  iconColor,
  subStyle,
}) => {
  return (
    <Wrapper
      cover={cover}
      className={`empty-message ${className ? className : ""}`}
    >
      {icon && (
        <Icon
          value={icon}
          color={iconColor ? iconColor : honeygold}
          size={Size.ExtraLarge}
        />
      )}
      <div className="emptymessage-body">
        <Title text={title} size={TitleSize.H4} />
        {summary && <Sub style={subStyle}>{summary}</Sub>}
      </div>
      {children}
    </Wrapper>
  );
};

export default EmptyMessage;
