import { TagListView } from "areas/send/types/passportResponse.types";
import tagsActions from "../../actions/tags/tagsActions";
import { IBeehiveAction } from "types/common/action.types";

interface ITagState {
  tag: TagListView | null;
  loading: boolean;
  error: string | null;
  saving: boolean;
  saveError: string | null;
  deleting: boolean;
  deleteError: string | null;
  newId: number | null;
}

const INITIAL_STATE: ITagState = {
  tag: null,
  loading: false,
  saving: false,
  error: null,
  saveError: null,
  deleting: false,
  deleteError: null,
  newId: null,
};


const tagReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ITagState => {

  const { TAG_GET, TAG_CREATE, TAG_SAVE, TAG_DELETE } = tagsActions.types;

  switch (action.type) {
    case TAG_GET.START:
      return { ...state, loading: true };
    case TAG_GET.SUCCESS:
      return { ...state, loading: false, tag: action.payload };
    case TAG_GET.FAILED:
      return { ...state, error: action.payload };

    case TAG_CREATE.START:
      return { ...state, saving: true };
    case TAG_CREATE.SUCCESS:
      return { ...state, saving: false, newId: action.payload.id };
    case TAG_CREATE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem creating the tag",
      };

    case TAG_SAVE.START:
      return { ...state, saveError: null, deleteError: null, saving: true };
    case TAG_SAVE.SUCCESS:
      return { ...state, saving: false };
    case TAG_SAVE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the tag",
      };

    case TAG_DELETE.START:
      return { ...state, deleteError: null, deleting: true };
    case TAG_DELETE.SUCCESS:
      return { ...state, deleting: false };
    case TAG_DELETE.FAILED:
      return {
        ...state,
        deleting: false,
        deleteError:
          "There was a problem deleting the tag. This may be because this tag is in use.",
      };

    default:
      return state;
  }
};

export default tagReducer;
