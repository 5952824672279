import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Loader, Size, EmptyMessage, List } from "ui-kit";
import { arrays } from "utils";
import DetentionHistoryListItem from "./detentionHistoryListItem";
import studentDetentionHistoryActions from "areas/behaviour/actions/detentions/studentDetentionHistoryActions";
import { ApiExceptionMessage } from "sharedComponents/common";
import config from "configuration/config";
import { useAppSelector } from "reducers/hooks";


interface IDetentionHistoryProps {
  studentId: string;
}


const DetentionHistory: React.FC<IDetentionHistoryProps> = ({ studentId }) => {

  const { detentionHistory, loading, error } = useAppSelector(
    state => state.studentDetentionHistory
  );

  useEffect(() => {
    studentDetentionHistoryActions.getStudentDetentionHistory(
      studentId,
      {
        showCancelledDetentions: false,
        academicYearId : config.academicYear.current
      }
    );
  }, []);

  if (loading) {
    return <Loader size={Size.Large} fluid />;
  }

  if (error) {
    <ApiExceptionMessage error={error} />;
  }

  return arrays.isEmpty(detentionHistory) ? (
    <EmptyMessage
      icon="list"
      title="No detentions"
      summary="There are no detentions for the selected filters"
    />
  ) : (
    <List>
      {detentionHistory.map(detention => (
        <DetentionHistoryListItem detention={detention} />
      ))}
    </List>
  );
};

export default DetentionHistory;
