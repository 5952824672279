import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Icon,
  neutral,
  honeygold,
  Orientation,
  typescale,
  displayFont,
  Spacing,
  fontStyle,
  Speed,
  BorderRadius
} from "../index";

const SliderWrapper = styled.div<{disabled?: boolean, fluid?: boolean}>`
  display: inline-flex;
  box-sizing: box-content;

  ${fontStyle(displayFont.bold, typescale.paragraph)}

  ${({ disabled }) =>
    disabled
      ? `
    opacity: 0.75;
    cursor: not-allowed;
`
      : `
    .radio-list-item{
        &:hover {
        cursor: pointer;
        transistion: all ease ${Speed.Default}ms;
        background: ${honeygold};
      }
    }

  `}

  ${({ fluid }) =>
    fluid &&
    `
    display: flex;
    width: 100%;
  `}
`;

const SliderItemWrapper = styled.div<{selected: boolean, orientation: Orientation}>`
  flex-basis: 0;
  display: flex;
  align-items: center;
  margin-left: ${Spacing.ExtraSmall}px;
  flex-grow: 1;

  background: ${neutral[200]};
  padding: ${Spacing.Default}px;
  color: ${neutral[700]};

  i {
    color: ${neutral[700]};
  }

  ${({ orientation }) =>
    orientation === Orientation.Landscape
      ? `
    flex-direction: row;
    i {
      margin-right: ${Spacing.Default}px;
    }
  `
      : `
    flex-direction: column;
    justify-content: center;
    text-align: center;
    i {
      margin-bottom: ${Spacing.Default}px;
    }
  `}

  ${({ selected }) =>
    selected &&
    `
    background: ${neutral[600]};
    color: ${neutral[100]};
    i {
      color: ${neutral[100]};
    }
  `}

  &:first-child {
    border-top-left-radius: ${BorderRadius.Default}px;
    border-bottom-left-radius: ${BorderRadius.Default}px;
  }
  &:last-child {
    border-top-right-radius: ${BorderRadius.Default}px;
    border-bottom-right-radius: ${BorderRadius.Default}px;
    border-right: 0;
  }
`;

export interface ISliderProps {
  value: string | boolean | number | {};
  children?: React.ReactElement<ISliderItemProps>[];
  items?: ISliderItemProps[];
  onChange: (value: string | boolean | number | object) => void;
  orientation?: Orientation;
  fluid?: boolean;
  disabled?: boolean;
}

const Slider: React.FC<ISliderProps> & {
  Item: typeof SliderItem;
} = ({
  children,
  items,
  value,
  onChange,
  orientation = Orientation.Landscape,
  fluid,
  disabled
}) => {
  const [selectedValue, setSelectedValue] = useState(children?.[0].props.value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleSelectedItemChanged = (value: string | boolean | number | object) => {
    setSelectedValue(value);
    onChange?.(value);
  };

  return (
    <SliderWrapper fluid={fluid} disabled={disabled}>
      {children?.map((child, index) => {
        return React.cloneElement(
          child as React.ReactElement<ISliderItemProps>,
          {
            key: index,
            selected: child.props.value === selectedValue,
            onClick: handleSelectedItemChanged,
            orientation: orientation
          }
        );
      })}
      {items?.map((item, index) => (
        <SliderItem
          key={children?.length ? index + children.length : index}
          value={item.value}
          icon={item.icon}
          label={item.label}
          selected={item.value === selectedValue}
          onClick={handleSelectedItemChanged}
          orientation={orientation}
        />
      ))}
    </SliderWrapper>
  );
};

export interface ISliderItemProps {
  value: string | boolean | number | object;
  label: string;
  icon?: string;
  onClick?(value: string | boolean | number | object): void;
  selected: boolean;
  orientation: Orientation;
}

const SliderItem: React.FC<ISliderItemProps> = ({
  value,
  label,
  icon,
  onClick,
  selected = false,
  orientation = Orientation.Landscape
}) => {
  const handleClick = () => {
    onClick?.(value);
  };
  return (
    <SliderItemWrapper
      className="radio-list-item"
      onClick={handleClick}
      selected={selected}
      orientation={orientation}
    >
      {icon && <Icon value={icon} />}
      <span>{label}</span>
    </SliderItemWrapper>
  );
};

Slider.Item = SliderItem;

export default Slider;
