import routes from "configuration/routes";

export const matriculationRoutes = {
  default: "/matriculation",
  studentSession: "/schools/:schoolId/sessions/:sessionId/students",
  student: "/schools/:schoolId/sessions/:sessionId/students/:studentId",
  preadmissions: "/schools/:schoolId/sessions/:sessionId/preadmissions",
  camera: "/schools/:schoolId/sessions/:sessionId/preadmissions/camera/:studentId",
  getStudentSessionsPath: (schoolId: number, sessionId: number) =>
    `${routes.home}/matriculation/schools/${schoolId}/sessions/${sessionId}/students`,
  getPreadmissionsPath: (schoolId: number, sessionId: number) =>
    `${routes.home}/matriculation/schools/${schoolId}/sessions/${sessionId}/preadmissions`,
  getPreadmissionCameraPath: (schoolId: number, sessionId: number, preadmissionId: number) =>
    `${routes.home}/matriculation/schools/${schoolId}/sessions/${sessionId}/preadmissions/camera/${preadmissionId}`,
};
