import { OnCallRequestCategoryView } from "areas/analytics/reducers/onCallAnalyticsResponse.types";
import onCallActions from "areas/behaviour/actions/onCall/onCallActions";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


interface IOnCallCategoriesState {
  onCallCategories: OnCallRequestCategoryView[],
  loading: boolean;
  refresh: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE : IOnCallCategoriesState = {
  onCallCategories: [],
  loading: false,
  refresh: false,
  error: null,
};


const onCallCategoriesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IOnCallCategoriesState => {

  const { GETONCALLCATEGORIES } = onCallActions.types;

  switch (action.type) {
    case GETONCALLCATEGORIES.START:
      return { ...state, loading: true };

    case GETONCALLCATEGORIES.SUCCESS:
      return {
        ...state,
        loading: false,
        refresh: false,
        error: null,
        onCallCategories: action.payload,
      };

    case GETONCALLCATEGORIES.FAILED:
      return {
        ...state,
        loading: false,
        refresh: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default onCallCategoriesReducer;
