import assignmentsReducers from "./assignments";
import classViewerReducers from "./classViewer";
import classGroupCoursesReducer from "./courses/classGroupCoursesReducer";
import groupReducers from "./group";
import groupsReducers from "./groups";
import houseReducers from "./houses";
import managedMovesReducers from "./managedMoves";
import schoolReducers from "./school";
import schoolsReducer from "./schools/schoolsReducer";
import studentReducers from "./student";
import subjectsReducer from "./subjectsReducer";
import termsReducer from "./termsReducer";
import timetableReducer from "./timetableReducer";
import yearGroupsReducer from "./yearGroupsReducer";
import interventionReducers from "./interventions"
import specialDaysReducer from "./specialDaysReducer";

const planner = {
  ...assignmentsReducers,
  ...classViewerReducers,
  classGroupCourses: classGroupCoursesReducer,
  ...groupReducers,
  ...groupsReducers,
  ...houseReducers,
  ...managedMovesReducers,
  ...schoolReducers,
  schools: schoolsReducer,
  ...studentReducers,
  subjects: subjectsReducer,
  terms: termsReducer,
  specialDays: specialDaysReducer,
  timetable: timetableReducer,
  yearGroups: yearGroupsReducer,
  ...interventionReducers
};

export default planner;
