import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import moment from "moment";
import { urls } from "utils";
import paymentActions from "./paymentsActions";

import { BeehiveReport, IBeehiveAction, callbackType } from "types/common/action.types";
import { AddManualLunchCreditCommand, LunchNumbersFilter, LunchRecordQueryFilter, SaveRegisterRequest, StudentChoicesQueryFilter } from "../types/catering/cateringRequests.types";
import { LunchBalanceListView, LunchCreditAdjustmentView, LunchCreditBalanceView, LunchRecordListView, LunchRegisterDetailView, LunchTransactionListView } from "../types/catering/cateringResponses.types";
import { PagedQueryView } from "types/common/views.types";
import { ILunchBalancesFilter } from "../reducers/catering/lunchBalancesReducer";
import store from "reducers/store";
import { CateringReportFilter } from "../reducers/catering/lunchRecordsReducer";

const paymentsTypes = paymentActions.paymentsTypes;
const builder = new actionTypeBuilder("payments");

const lunchRegisterTypes = {
  PAYMENTS_GETLUNCHRECORDS: builder.build("PAYMENTS_GETLUNCHRECORDS"),
  PAYMENTS_GETLUNCHREGISTER: builder.build("PAYMENTS_GETLUNCHREGISTER"),
  PAYMENTS_SAVELUNCHREGISTER: builder.build("PAYMENTS_SAVELUNCHREGISTER"),
  CATERING_DAILYLUNCHREPORT: builder.build("CATERING_DAILYLUNCHREPORT"),
  CATERING_DAILYTUTORGROUPREPORT: builder.build("CATERING_DAILYTUTORGROUPREPORT"),
  CATERING_STUDENTBALANCEREPORT: builder.build("CATERING_STUDENTBALANCEREPORT"),
  CATERING_GETSTUDENTBALANCES: builder.build("CATERING_GETSTUDENTBALANCES"),
  CATERING_GETSTAFFBALANCES: builder.build("CATERING_GETSTAFFBALANCES"),
  CATERING_GETLUNCHTRANSACTIONS: builder.build("CATERING_GETLUNCHTRANSACTIONS"),
  CATERING_GETLUNCHHISTORY: builder.build("CATERING_GETLUNCHHISTORY"),
  CATERING_GETSTUDENTLUNCHCHOICES: builder.build("CATERING_GETSTUDENTLUNCHCHOICES"),
  CATERING_GETLUNCHNUMBERS: builder.build("CATERING_GETLUNCHNUMBERS"),
  PAYMENTS_SETRECORDSFILTER: "PAYMENTS_SETRECORDSFILTER",
  PAYMENTS_SETBALANCESFILTER: "PAYMENTS_SETBALANCESFILTER",
};


const getLunchHistory = (studentId: string, callback?: callbackType<LunchTransactionListView[]>) => 
  action<LunchTransactionListView[]>(
    () => client.get(`payments/lunch/students/${studentId}/history`),
    lunchRegisterTypes.CATERING_GETLUNCHHISTORY,
    callback
  );


const addManualLunchCredit = (userId: string, data: AddManualLunchCreditCommand, callback?: callbackType<LunchCreditAdjustmentView>) =>
  action<LunchCreditAdjustmentView>(
    () => client.post(`payments/users/${userId}/lunchcredits`, data),
    paymentsTypes.PAYMENTS_ADDMANUALLUNCHCREDIT,
    callback
  );


const getLunchRegister = (tutorGroupId: number, date: Date, callback?: callbackType<LunchRegisterDetailView>) =>
  action<LunchRegisterDetailView>(
    () => client.get(`payments/lunch/tutorGroups/${tutorGroupId}/register?year=${date.getFullYear()}&month=${date.getMonth() + 1}&day=${date.getDate()}`),
    lunchRegisterTypes.PAYMENTS_GETLUNCHREGISTER,
    callback
  );


const saveLunchRegister = (data: SaveRegisterRequest, callback?: callbackType<LunchRecordListView[]>) =>
  action<LunchRecordListView[]>(
    () => client.post(`payments/lunch/register`, data),
    lunchRegisterTypes.PAYMENTS_SAVELUNCHREGISTER,
    callback
  );


const getDailyLunchReport = (filter: LunchRecordQueryFilter, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.post(`payments/lunch/reporting/daily`, filter, { responseType: "text" }),
    lunchRegisterTypes.CATERING_DAILYLUNCHREPORT,
    callback
  );



const setRecordsFilter = (filter: CateringReportFilter) => {
  store.dispatch<IBeehiveAction<CateringReportFilter>>({
    type: lunchRegisterTypes.PAYMENTS_SETRECORDSFILTER,
    payload: filter,
  });
}


const getLunchRecords = (filter: LunchRecordQueryFilter, callback?: callbackType<LunchRecordListView[]>) => {

  var qs = `?schoolId=${filter.schoolId}&date=${moment(filter.date).format("YYYY-MM-DD")}`;
  if (filter.tutorGroupId) {
    qs = qs + `&tutorGroupId=${filter.tutorGroupId}`;
  }
  if (filter.lunchType != null) {
    qs = qs + `&lunchType=${filter.lunchType}`;
  }

  return action<LunchRecordListView[]>(
    () => client.get(`payments/lunch/register/records${qs}`),
    lunchRegisterTypes.PAYMENTS_GETLUNCHRECORDS,
    callback
  );
}


const getStudentBalanceReport = (filter: LunchRecordQueryFilter, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.post(`payments/lunch/reporting/balances`, filter, { responseType: "text" }),
    lunchRegisterTypes.CATERING_STUDENTBALANCEREPORT,
    callback
  );


const getDailyTutorGroupReport = (filter: LunchRecordQueryFilter, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.post(`payments/lunch/reporting/daily/tutorgroup`, filter, { responseType: "text" }),
    lunchRegisterTypes.CATERING_DAILYTUTORGROUPREPORT,
    callback
  );


const getSingleStudentLunchChoices = (filter: StudentChoicesQueryFilter, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.post(`payments/lunch/reporting/studentChoices`, filter, { responseType: "text" }),
    lunchRegisterTypes.CATERING_GETSTUDENTLUNCHCHOICES,
    callback
  );


const getLunchNumbers = (filter: LunchNumbersFilter, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.post(`payments/lunch/reporting/lunchNumbers`, filter, { responseType: "text" }),
    lunchRegisterTypes.CATERING_GETLUNCHNUMBERS,
    callback
  );


const setBalancesFilter = (filter: ILunchBalancesFilter) => {
  store.dispatch<IBeehiveAction<ILunchBalancesFilter>>({
    type: lunchRegisterTypes.PAYMENTS_SETBALANCESFILTER,
    payload: filter,
  });
}


const getStudentBalances = (filter: ILunchBalancesFilter, pageIndex: number, callback?: callbackType<PagedQueryView<LunchBalanceListView>>) => {
  var url = new urls.QueryString(`payments/lunch/students/balances`);
  url.addParams(filter);
  url.addParam("pageIndex", pageIndex);
  url.addParam("pageSize", 50);

  return action<PagedQueryView<LunchBalanceListView>>(
    () => client.get(url.toUrl()),
    lunchRegisterTypes.CATERING_GETSTUDENTBALANCES,
    callback
  );
}

const getStaffBalances = (schoolId: number, pageIndex: number, callback?: callbackType<PagedQueryView<LunchBalanceListView>>) => {
  var url = new urls.QueryString(`payments/lunch/staff/balances`);
  url.addParam("schoolId", schoolId);
  url.addParam("pageIndex", pageIndex);
  url.addParam("pageSize", 50);

  return action<PagedQueryView<LunchBalanceListView>>(
    () => client.get(url.toUrl()),
    lunchRegisterTypes.CATERING_GETSTAFFBALANCES,
    callback
  );
}


const getUserLunchTransactions = (userId: string, callback?: callbackType<LunchTransactionListView[]>) =>
  action<LunchTransactionListView[]>(
    () => client.get(`payments/users/${userId}/lunchcredits/transactions`),
    paymentsTypes.PAYMENTS_GETUSERLUNCHTRANSACTIONS,
    callback
  );


const getUserLunchCreditBalance = (userId: string, callback?: callbackType<LunchCreditBalanceView[]>) =>
  action<LunchCreditBalanceView[]>(
    () => client.get(`payments/users/${userId}/lunchcredits/balance`),
    paymentsTypes.PAYMENTS_GETUSERLUNCHCREDITBALANCE,
    callback
  );


const lunchRegisterActions = {
  lunchRegisterTypes,
  getLunchHistory,
  addManualLunchCredit,
  getLunchRegister,
  saveLunchRegister,
  getDailyLunchReport,
  setRecordsFilter,
  getLunchRecords,
  getStudentBalanceReport,
  getDailyTutorGroupReport,
  getSingleStudentLunchChoices,
  getLunchNumbers,
  setBalancesFilter,
  getStudentBalances,
  getStaffBalances,
  getUserLunchTransactions,
  getUserLunchCreditBalance
};

export default lunchRegisterActions;