import { IBeehiveError } from "types/common/errors.types";
import managedMovesActions from "../../actions/managedMoves/managedMovesActions";
import { IBeehiveAction } from "types/common/action.types";
import { ManagedMoveDetailView } from "areas/planner/types/managedMoves/managedMovedResponse.types";


interface IManagedMoveState {
  move: ManagedMoveDetailView;
  loading: boolean;
  saving: boolean;
  closing: boolean;
  error: IBeehiveError;
  saveError: IBeehiveError;
  closeError: IBeehiveError;
}

const INITIAL_STATE: IManagedMoveState = {
  move: null,
  loading: false,
  saving: false,
  closing: false,
  error: null,
  saveError: null,
  closeError: null,
};

const managedMoveReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IManagedMoveState => {

  const {
    MANAGEDMOVES_GETMOVE,
    MANAGEDMOVES_UPDATEMOVE,
    MANAGEDMOVES_CREATEMOVE,
    MANAGEDMOVES_CLOSEMOVE,
  } = managedMovesActions.types;

  switch (action.type) {
    case MANAGEDMOVES_GETMOVE.START:
      return { ...INITIAL_STATE, loading: true };

    case MANAGEDMOVES_GETMOVE.SUCCESS:
      return { ...INITIAL_STATE, move: action.payload };

    case MANAGEDMOVES_GETMOVE.FAILED:
      return { ...INITIAL_STATE, error: action.payload };

    case MANAGEDMOVES_CREATEMOVE.START:
    case MANAGEDMOVES_UPDATEMOVE.START:
      return { ...state, saving: true, saveError: null };

    case MANAGEDMOVES_CREATEMOVE.SUCCESS:
    case MANAGEDMOVES_UPDATEMOVE.SUCCESS:
      return { ...state, saving: false };

    case MANAGEDMOVES_CREATEMOVE.FAILED:
    case MANAGEDMOVES_UPDATEMOVE.FAILED:
      return { ...state, saving: false, saveError: action.payload };

    case MANAGEDMOVES_CLOSEMOVE.START:
      return { ...state, closing: true, closeError: null };

    case MANAGEDMOVES_CLOSEMOVE.SUCCESS:
      return { ...state, closing: false };

    case MANAGEDMOVES_CLOSEMOVE.FAILED:
      return { ...state, closing: false, closeError: action.payload };

    default:
      return state;
  }
};

export default managedMoveReducer;
