import { IBeehiveAction } from "types/common/action.types";
import matriculationActions from "../actions/matriculationActions";
import { MatriculationSessionListView, MatriculationStudentDetailView, MatriculationStudentListView, PreadmissionListView } from "../types/matriculationResponses.types";
import { PagedQueryView } from "types/common/views.types";


interface IMatriculationSessionsState {
  loading: boolean;
  error: string | null;
  sessions: MatriculationSessionListView[] | null;
  sessionStudents: PagedQueryView<MatriculationStudentListView> | null;
  sessionPreadmissions: PagedQueryView<PreadmissionListView> | null;
  reporting: {
    working: boolean;
    error: string | null;
  },
  selectedStudent: {
    loading: boolean;
    error: string | null;
    student: MatriculationStudentDetailView | null;
  },
  selectedPreadmission: {
    loading: boolean;
    error: string | null;
    preadmission: PreadmissionListView | null;
  },
  searchResults: {
    loading: boolean;
    error: string | null;
    results: PreadmissionListView[] | null;
  },
  sessionUpdated: boolean;
}

const INITIAL_STATE: IMatriculationSessionsState = {
  loading: false,
  error: null,
  sessions: null,
  sessionStudents: null,
  sessionPreadmissions: null,
  reporting: {
    working: false,
    error: null,
  },
  selectedStudent: {
    loading: false,
    error: null,
    student: null,
  },
  selectedPreadmission: {
    loading: false,
    error: null,
    preadmission: null,
  },
  searchResults: {
    loading: false,
    error: null,
    results: null,
  },
  sessionUpdated: false,
};

const matriculationSessionsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IMatriculationSessionsState => {
  const {
    MATRICULATION_GETSESSIONS,
    MATRICULATION_SESSION_GETSTUDENTS,
    MATRICULATION_SESSION_GETPREADMISSIONS,
    MATRICULATION_SESSION_RUNREPORT,
    MATRICULATION_SESSION_GETSTUDENT,
    MATRICULATION_SESSION_GETPREADMISSION,
    MATRICULATION_SEARCH_PREADMISSIONS,
    MATRICULATION_SESSION_CHANGESTATUS,
    MATRICULATION_CREATENEWSESSION,
    MATRICULATION_PROCESSSTUDENT,
  } = matriculationActions.types;

  switch (action.type) {
    case MATRICULATION_GETSESSIONS.START:
      return { ...INITIAL_STATE, loading: true };

    case MATRICULATION_GETSESSIONS.SUCCESS:
      return { ...INITIAL_STATE, sessions: action.payload };

    case MATRICULATION_GETSESSIONS.FAILED:
      return { ...INITIAL_STATE, error: action.payload };

    case MATRICULATION_SESSION_GETSTUDENTS.START:
      return { ...state, loading: true };

    case MATRICULATION_SESSION_GETSTUDENTS.SUCCESS:
      return { ...state, loading: false, sessionStudents: action.payload };

    case MATRICULATION_SESSION_GETSTUDENTS.FAILED:
      return { ...state, loading: false, error: action.payload };

    case MATRICULATION_SESSION_GETPREADMISSIONS.START:
      return { ...state, loading: true };

    case MATRICULATION_SESSION_GETPREADMISSIONS.SUCCESS:
      return { ...state, loading: false, sessionPreadmissions: action.payload };

    case MATRICULATION_SESSION_GETPREADMISSIONS.FAILED:
      return { ...state, loading: false, error: action.payload };

    case MATRICULATION_SESSION_RUNREPORT.START:
      return { ...state, reporting: { ...state.reporting, working: true } };

    case MATRICULATION_SESSION_RUNREPORT.SUCCESS:
      return { ...state, reporting: { ...state.reporting, working: false } };

    case MATRICULATION_SESSION_RUNREPORT.FAILED:
      return {
        ...state,
        reporting: {
          ...state.reporting,
          working: false,
          error: action.payload,
        },
      };

    case MATRICULATION_SESSION_GETSTUDENT.START:
      return {
        ...state,
        selectedStudent: { ...state.selectedStudent, loading: true },
      };

    case MATRICULATION_SESSION_GETSTUDENT.SUCCESS:
      return {
        ...state,
        selectedStudent: {
          ...state.selectedStudent,
          loading: false,
          student: action.payload,
        },
      };

    case MATRICULATION_SESSION_GETSTUDENT.FAILED:
      return {
        ...state,
        selectedStudent: {
          ...state.selectedStudent,
          loading: false,
          error: action.payload,
        },
      };

    case MATRICULATION_SESSION_GETPREADMISSION.START:
      return {
        ...state,
        selectedPreadmission: { ...state.selectedPreadmission, loading: true },
      };

    case MATRICULATION_SESSION_GETPREADMISSION.SUCCESS:
      return {
        ...state,
        selectedPreadmission: {
          ...state.selectedPreadmission,
          loading: false,
          preadmission: action.payload,
        },
      };

    case MATRICULATION_SESSION_GETPREADMISSION.FAILED:
      return {
        ...state,
        selectedPreadmission: {
          ...state.selectedPreadmission,
          loading: false,
          error: action.payload,
        },
      };

    case MATRICULATION_SEARCH_PREADMISSIONS.START:
      return {
        ...state,
        searchResults: { ...state.searchResults, loading: true },
      };

    case MATRICULATION_SEARCH_PREADMISSIONS.SUCCESS:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          loading: false,
          results: action.payload,
        },
      };

    case MATRICULATION_SEARCH_PREADMISSIONS.FAILED:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          loading: false,
          error: action.payload,
        },
      };

    case MATRICULATION_SESSION_CHANGESTATUS.START:
      return { ...state };

    case MATRICULATION_SESSION_CHANGESTATUS.SUCCESS:
      return { ...state, sessionUpdated: true };

    case MATRICULATION_SESSION_CHANGESTATUS.FAILED:
      return { ...state, error: action.payload };

    case MATRICULATION_CREATENEWSESSION.START:
      return { ...state };

    case MATRICULATION_CREATENEWSESSION.SUCCESS:
      return { ...state, sessionUpdated: true };

    case MATRICULATION_CREATENEWSESSION.FAILED:
      return { ...state, error: action.payload };

    case MATRICULATION_PROCESSSTUDENT.START:
      return {
        ...state,
        selectedStudent: { ...state.selectedStudent, loading: true },
      };

    case MATRICULATION_PROCESSSTUDENT.SUCCESS:
      return {
        ...state,
        selectedStudent: {
          ...state.selectedStudent,
          loading: false,
          student: { ...state.selectedStudent.student, processed: true },
        },
      };

    case MATRICULATION_PROCESSSTUDENT.FAILED:
      return {
        ...state,
        selectedStudent: {
          ...state.selectedStudent,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default matriculationSessionsReducer;
