import { stat } from "fs";
import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Card,
  HtmlText,
  ActionBar,
  neutral,
  Message,
} from "ui-kit";
import { useSelector } from "react-redux";
import { Size, Swatches } from "../../ui-kit";
import styled from "styled-components";
import { useAction } from "hooks";
import startupActions from "actions/startup/startupActions";
import { RootState } from "reducers/store";

const Wrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  b {
    color: ${neutral[600]};
  }
  h1 {
    font-size: 18px;
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
  }
  h4 {
    font-size: 12px;
    margin-bottom: 12px !important;
    padding-bottom: 0;
  }

  h4,
  h4 {
    display: inline-block;
  }

  ol {
    counter-reset: item;
  }
  li {
    display: block;
  }
  li:before {
    content: counters(item, ".") " ";
    counter-increment: item;
    margin-right: 0.25rem;
    font-weight: bold;
    color: ${neutral[600]};
  }

  p,
  ol,
  ul {
    font-size: 12px;
    line-height: 1.2rem;
    margin-left: 0.5rem;
    padding-left: 0;
  }

  li {
    margin-bottom: 0.5rem;
  }

  ol,
  ul {
    margin-bottom: 1rem !important;
  }
`;

interface IAcceptableUseModalProps {
  open: boolean;
  policy: any;
  onClose?: () => void;
}

const AcceptableUseModal : React.FC<IAcceptableUseModalProps> = ({ open, policy, onClose }) => {
  const [_open, _setOpen] = useState<boolean>(open);
  const { user, actions } = useSelector((state: RootState) => state.currentUser);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const handleAccept = () => {
    startupActions.submitAcceptableUse(user.id, policy.id, () => {
      onClose?.();
    });
  };

  return (
    <Modal open={_open} onClose={onClose} lock width="60%" height="60%">
      <Modal.Body>
        <Message
          text={actions.submitAcceptableUse.error}
          color={Swatches.Danger}
        />
        <Wrapper>
          <HtmlText html={policy.content} />
        </Wrapper>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Button
            fluid
            working={actions.submitAcceptableUse.isLoading}
            text="I agree to the terms of the Acceptable Use Policy"
            onClick={handleAccept}
            size={Size.Small}
            color={Swatches.Success}
          />
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default AcceptableUseModal;
