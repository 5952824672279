import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { 
  IUiKitBaseProps, 
  Spacing, 
  Icon, 
  Tooltip
} from "../index";
import Tippy from "@tippyjs/react";

const Wrapper = styled.span`
  margin-left: ${Spacing.Medium}px;
  &:hover {
    cursor: pointer;
  }
`;

export interface ICopyToClipboardProps extends IUiKitBaseProps {
  text: string;
  style?: object;
}

const CopyToClipboard: React.FC<ICopyToClipboardProps> = ({ text, style }) => {

  const ref = useRef<HTMLSpanElement>();
  const [copied, setCopied] = useState<boolean>(false);
  const [_text, _setText] = useState(text);

  useEffect(() => {
    _setText(text);
  }, [text]);

  const copy = () => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(_text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  };

  return (
    <>
      <Wrapper ref={ref} style={style} onClick={copy}>
        <Icon value="copy" tooltip="Copy to Clipboard" />
      </Wrapper>
      <Tippy
        reference={ref}
        content={<Tooltip title={"Copied Text"} />}
        visible={copied}
      />
    </>
  );
};

export default CopyToClipboard;
