import { Icon, List, Size, Spacing } from "ui-kit";
import { getGroupSub } from "areas/planner/utils/group";
import SchoolIcon from "sharedComponents/common/schools/icons/schoolIcon";
import styled from "styled-components";
import { danger } from "ui-kit/common/colors";
import { GroupListView } from "types/users/userGroups.types";


const IconsWrapper = styled.div`
  display: flex;
  .icon {
    margin-right: ${Spacing.Small}px;

    &:last-child {
      margin-right: 0;
    }
  }
  .favourite-icon {
    color: ${danger};
  }
`;


interface IGroupListItemProps {
  group: GroupListView;
  selectedGroupId: number;
  handleGroupClicked: (group: GroupListView) => void;
}


const GroupListItem: React.FC<IGroupListItemProps> = ({ group, selectedGroupId, handleGroupClicked }) => {

  return (
    <List.Item
      key={group.id}
      className={selectedGroupId == group.id ? "selected" : ""}
      text={group.friendlyName ? group.friendlyName : group.name}
      sub={getGroupSub(group)}
      selected={selectedGroupId == group.id}
      onClick={() => handleGroupClicked(group)}
      left={<SchoolIcon code={group.school.code} size={Size.Small} />}
      right={
        <IconsWrapper>
          {group.isPrivate && (
            <Icon
              className="private-icon"
              value="lock"
              tooltip="Private"
              tooltipSub="This group is only visible to the responsible staff members of the group."
            />
          )}
          {group.isFavourite && (
            <Icon
              className="favourite-icon"
              value="heart"
              color={danger}
              tooltip="Favourite"
            />
          )}
        </IconsWrapper>
      }
    />
  );
};

export default GroupListItem;
