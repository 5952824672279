import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IncidentForm from "./incidentForm";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import groupActions from "areas/planner/actions/group/groupActions";
import schoolActions from "areas/planner/actions/school/schoolActions";
import { useAppSelector } from "reducers/hooks";
import { IDetentionDetails, IIncidentDetails } from "areas/behaviour/utils/detentions";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { SchoolPeriodListView } from "types/planner/timetable.types";


interface IIncidentFormWrapperProps {
  incidentDetails: IIncidentDetails;
  setIncidentDetails: (details: IIncidentDetails) => void;
  detentionDetails: IDetentionDetails;
  groupId: number;
  schoolId: number;
}


const IncidentFormWrapper: React.FC<IIncidentFormWrapperProps> = ({
  incidentDetails,
  setIncidentDetails,
  detentionDetails,
  groupId,
  schoolId,
}) => {

  const { rooms } = useAppSelector(state => state.schoolRooms);
  const { selectedGroupId } = useAppSelector(state => state.group);
  const { groupPeriods } = useAppSelector(state => state.groupPeriods);
  const { schoolInformation } = useAppSelector(state => state.school);
  const { schoolPeriods, usedSchoolId } = useAppSelector(state => state.schoolPeriods);

  const [roomsArray, setRoomsArray] = useState<IDropdownDataItemProps<string, number, null>[]>([]);
  const [periodDropdownArray, setPeriodDropdownArray] = useState<IDropdownDataItemProps<string, number, null>[]>([]);

  useEffect(() => {
    if (groupId) {
      if (groupPeriods?.length === 0 || groupId !== selectedGroupId) {
        groupActions.getGroupPeriods(groupId);
      }
    } else if (arrays.isEmpty(schoolPeriods) || (schoolId !== usedSchoolId)) {
      schoolActions.setSchoolId(schoolId)
      schoolActions.getSchoolPeriods(schoolId).then((response) => updatePeriodDropdownArray(response));
    } else {
      updatePeriodDropdownArray(schoolPeriods);
    }
  }, []);

  const updatePeriodDropdownArray = (periodsArray: any) => {
    const schoolPeriodsArray = periodsArray.map((period: SchoolPeriodListView) => ({
        key: period.id,
        label: `${period.daysOfWeek} Period ${period.periodNumber}`,
        value: period.id,
      })
    );
    setPeriodDropdownArray(schoolPeriodsArray);
  };

  useEffect(() => {
    if (groupId) {
      const classGroupPeriodsArray = groupPeriods.map(period => ({
        key: period.id,
        label: `${period.daysOfWeek} Period ${period.periodNumber}`,
        value: period.id,
      }));

      setPeriodDropdownArray(classGroupPeriodsArray);
    }
  }, [groupPeriods]);

  // useEffect(() => {
  //   if (groupId === null && !arrays.isEmpty(schoolPeriods)) {
  //     const schoolPeriodsArray = schoolPeriods.map(period => ({
  //       key: period.id,
  //       label: `${period.daysOfWeek} Period ${period.periodNumber}`,
  //       value: period.id,
  //     }));

  //     setPeriodDropdownArray(schoolPeriodsArray);
  //   }
  // }, [schoolPeriods]);

  useEffect(() => {
    if (!schoolInformation || schoolInformation.id !== schoolId) {
      schoolActions.getSchoolInformation(schoolId);
      schoolActions.getSchoolRooms(schoolId);
    } else if (!rooms || rooms.length === 0 || rooms[0]?.schoolId !== schoolId) {
      schoolActions.getSchoolRooms(schoolId);
    }
  }, [schoolId]);

  useEffect(() => {
    const roomsArray = rooms.map(room => ({
      key: room.roomId,
      label: room.roomName,
      value: room.roomId,
    }));

    setRoomsArray(roomsArray);
  }, [rooms]);

  return (
    <IncidentForm
      periodDropdownArray={periodDropdownArray}
      roomsDropdownArray={roomsArray}
      incidentDetails={incidentDetails}
      setIncidentDetails={setIncidentDetails}
      schoolId={schoolId}
    />
  );
};


export default IncidentFormWrapper;
