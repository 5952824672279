import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "../../../services/client";
import { callbackType } from "types/common/action.types";
import { LinkCategoryView } from "types/common/links.types";

const builder = new actionTypeBuilder("links");

const types = {
  LINKS_GETFORUSER: builder.build("LINKS_GETFORUSER"),
};


const getLinksForUser = (callback?: callbackType<LinkCategoryView[]>) =>
  action<LinkCategoryView[]>(
    () => client.get(`planner/links`), 
    types.LINKS_GETFORUSER, 
    callback
  );


const linksActions = {
  types,
  getLinksForUser,
};

export default linksActions;
