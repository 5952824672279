import React, { useEffect, useState } from "react";
import {
  Left,
  Right,
  List,
  Spacing,
  Card,
  Message,
  StructuredList,
  Size,
  Button,
  Swatches,
  ActionBar,
  ValidationMessage,
} from "ui-kit";
import styled from "styled-components";
import { arrays, files, users } from "utils";
import { useScroll } from "hooks/useScroll";
import { Constants } from "configuration";
import DeclarationsOfInterestReport from "./declarationsOfInterestReport";
import { useAppSelector } from "reducers/hooks";
import { FormsReportFilter } from "areas/forms/types/formRequest.types";
import { ApiExceptionMessage } from "sharedComponents/common";
import formReportingActions from "areas/forms/actions/formReportingActions";
import { IRole } from "configuration/constants.types";


const Wrapper = styled.div`
  display: flex;

  > .left {
    flex-basis: 250px;
    flex-shrink: 0;
  }

  > .right {
    flex-grow: 1;
    margin-left: ${Spacing.Large}px;
  }
`;


export interface IFormsReportProps {
  filter: FormsReportFilter;
  setFilter: (value: FormsReportFilter) => void
}

interface IFormsReport {
  name: string;
  value: number;
  roles: IRole[];
}


const REPORTS: IFormsReport[] = [
  {
    name: "Declarations of Interest Report",
    value: 0,
    roles: [
      Constants.ROLES.DATAPROTECTION_ADMINISTRATOR,
      Constants.ROLES.IT_ADMINISTRATOR,
      Constants.ROLES.DEVELOPMENT,
    ],
  }
];

const FILTER_DEFAULT: FormsReportFilter = {
  schoolId: null,
  academicYearId: Constants.ACADEMIC_YEAR_FILTER_ARRAY[0].value
};


const Reporting: React.FC = () => {

  const { scrollToTop } = useScroll();
  const { user } = useAppSelector(state => state.currentUser);

  const { loading, error } = useAppSelector(state => state.formsReporting);

  const [selectedReport, setSelectedReport] = useState<IFormsReport>(REPORTS.filter(x => users.isInAnyRoles(user, x.roles))?.[0]);
  const [filter, setFilter] = useState<FormsReportFilter>(FILTER_DEFAULT);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    if (user) {
      setFilter({...filter, schoolId: user.baseSchool?.id});
    }
  }, [user]);

  const handleRunReport = () => {
    var errors = [];

    if (filter.schoolId == null) {
      errors.push(`Please select a school.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    switch (selectedReport.value) {
      case 0:
        formReportingActions.exportDeclarationsOfInterest(filter, reportData => {
          files.openCsv(reportData);
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
    <Wrapper>
      <Left>
        <List>
          { REPORTS
          .filter(x => users.isInAnyRoles(user, x.roles))
          .map((report, index) => (
            <List.Item
              key={index}
              text={report.name}
              onClick={() => setSelectedReport(report)}
              selected={report.value === selectedReport.value}
            />
          ))}
        </List>
      </Left>
      <Right>
        <ApiExceptionMessage error={error} />
        <ValidationMessage errors={validationErrors} />
        <Card title={selectedReport.name}>
          <Card.Body>
            <StructuredList>
              {selectedReport.value === 0 && (
                <DeclarationsOfInterestReport filter={filter} setFilter={setFilter} />
              )}
            </StructuredList>
          </Card.Body>
        </Card>
        <ActionBar low>
          <Button
            text="Run Report"
            onClick={handleRunReport}
            color={Swatches.Success}
            working={loading}
            size={Size.Medium}
          />
        </ActionBar>
      </Right>
    </Wrapper>
    </>
  );
};


export default Reporting;
