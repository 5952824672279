import { StudentProfileArea } from "areas/planner/utils/studentRecordAreas";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Badge,
  BorderRadius,
  Spacing,
  neutral,
  textFont,
  fontStyle,
  typescale,
  honeygold,
} from "ui-kit";

const Wrapper = styled.div<{ split?: string }>`
  display: flex;
  flex-grow: 1;
  height: fit-content;
`;

const MasterWrapper = styled.div<{ width?: number }>`
  ${({ width }) => `
        width: ${width}%;
        min-width:${width}%;
    `}
  padding-right: ${Spacing.Large}px;
  height: fit-content;
`;

const MasterListWrapper = styled.div<{ width?: number }>`
  ${({ width }) => `
        width: ${width}%;
        min-width:${width}%;
    `}

  height: fit-content;
  margin-right: ${Spacing.Large}px;
  border-radius: ${BorderRadius.Default}px;
  overflow: hidden;
`;

const MasterListItem = styled.div<{ selected?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${Spacing.Default}px;
  background: ${neutral[300]};
  margin-bottom: 1px;

  &:last-item {
    margin-bottom: 0;
  }

  .left {
    ${fontStyle(textFont.bold, typescale.paragraph, neutral[700])};
  }

  &:hover {
    cursor: pointer;
    background: ${neutral[600]};
    .left {
      color: ${neutral[100]};
    }
  }

  ${({ selected }) =>
    selected &&
    `
      background: ${honeygold};
      .left {
        color: ${neutral[100]};
      }
    `}
`;

const DetailWrapper = styled.div`
  flex-grow: 1;
`;

interface IMasterDetailProps {
  children:
    | (
        | React.ReactElement<IMasterProps>
        | React.ReactElement<IMasterListProps>
        | React.ReactElement<IDetailProps>
      )[]
    | React.ReactElement<IMasterProps>
    | React.ReactElement<IMasterListProps>
    | React.ReactElement<IDetailProps>;
}

const MasterDetail: React.FC<IMasterDetailProps> & {
  Master: typeof Master;
  MasterList: typeof MasterList;
  Detail: typeof Detail;
} = ({ children }) => {
  return (
    <Wrapper split="vertical" className="masterdetail">
      {children}
    </Wrapper>
  );
};

interface IMasterProps {
  width?: number;
  children?: any[];
}

const Master: React.FC<IMasterProps> = ({ width, children }) => {
  return (
    <MasterWrapper className="masterdetail-master" width={width}>
      {children}
    </MasterWrapper>
  );
};

interface IMasterListProps {
  width?: number;
  list?: StudentProfileArea[];
  selectedItem?: StudentProfileArea;
  onClick?: (item: StudentProfileArea) => void;
  autoSelectOnInitialLoad?: boolean;
}

const MasterList: React.FC<IMasterListProps> = ({
  width,
  list,
  selectedItem,
  onClick,
  autoSelectOnInitialLoad,
}) => {
  const [selected, setSelected] = useState<number | null>(null);

  useEffect(() => {
    selectedItem && setSelected(selectedItem.id);
  }, [selectedItem])

  useEffect(() => {
    if (list.length > 0 && autoSelectOnInitialLoad) {
      handleClick(list[0]);
    }
  }, [list]);

  const handleClick = (item: StudentProfileArea) => {
    setSelected(item.id);
    onClick?.(item);
  };

  return (
    <MasterListWrapper className="masterdetail-master" width={width}>
      {list.map((listItem, index) => (
        <MasterListItem
          key={index}
          className="master-list-item"
          selected={selected === listItem.id}
          onClick={() => handleClick(listItem)}
        >
          <div className="left">{listItem.text}</div>
          <div className="right">
            {listItem.badge ? <Badge value={listItem.badge} /> : null}
          </div>
        </MasterListItem>
      ))}
    </MasterListWrapper>
  );
};

interface IDetailProps {
  children: any[];
}

const Detail: React.FC<IDetailProps> = ({ children }) => {
  return (
    <DetailWrapper className="masterdetail-detail">{children}</DetailWrapper>
  );
};

MasterDetail.Master = Master;
MasterDetail.MasterList = MasterList;
MasterDetail.Detail = Detail;

export default MasterDetail;
