import navigationReducer from "./navigationReducer";
import privacyReducer from "./privacyReducer";
import uiReducer from "./uiReducer";

const uiReducers = {
  navigation: navigationReducer,
  privacy: privacyReducer,
  ui: uiReducer,
};

export default uiReducers;
