import { AnyAction, combineReducers } from "redux";
import currentUser from "./currentUserReducer";
import ui from "./ui";
import fileCache from "./fileCache";
import userManagement from "areas/administration/reducers";
import alerts from "./alerts";
import feed from "../areas/feed/reducers";
import administrationHome from "../areas/administration/reducers/administrationHomeReducer";
import analytics from "areas/analytics/reducers";
import calendar from "areas/calendar/reducers";
import filing from "areas/filing/reducers";
import declarations from "areas/forms/reducers/declarations";
import forms from "areas/forms/reducers";
import humanResources from "areas/humanResources/reducers";
import links from "../areas/links/reducers/linksReducer";
import registration from "../areas/login/reducers/registrationReducer";
import mailout from "../areas/mailout/reducers";
import matriculation from "../areas/matriculation/reducers";
import news from "../areas/news/reducers";
import planner from "../areas/planner/reducers";
import search from "../areas/search/reducers";
import catering from "areas/payments/reducers/catering";
import payments from "areas/payments/reducers/payments";
import store from "areas/payments/reducers/store";
import send from "../areas/send/reducers";
import behaviour from "../areas/behaviour/reducers";

const reducers = combineReducers({
  currentUser,
  ...fileCache,
  ...alerts,
  administrationHome,
  ...analytics,
  ...calendar,
  ...declarations,
  ...feed,
  ...filing,
  ...forms,
  ...humanResources,
  links,
  registration,
  ...mailout,
  ...matriculation,
  ...news,
  ...planner,
  ...userManagement,
  ...search,
  ...ui,
  ...catering,
  ...payments,
  ...store,
  ...send,
  ...behaviour,
});

const rootReducer = (state: ReturnType<typeof reducers>, action: AnyAction) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return reducers(state, action);
};

export default rootReducer;
