import adminCategoryReducer from "./adminCategoryReducer";
import adminProductOrdersReducer from "./adminProductOrdersReducer";
import adminProductReducer from "./adminProductReducer";
import adminProductsReducer from "./adminProductsReducer";
import adminSummaryReducer from "./adminSummaryReducer";
import basketsWithDuplicateOrdersReducer from "./basketsWithDuplicateOrdersReducer";
import incomingReportReducer from "./incomingReportReducer";
import incomingReportsReducer from "./incomingReportsReducer";
import incomingTransactionsReducer from "./incomingTransactionsReducer";
import inventoryFilterReducer from "./inventoryFilterReducer";
import managedProductReducer from "./managedProductReducer";
import managedProductOrdersReducer from "./managedProductOrdersReducer";
import managedProductsReducer from "./managedProductsReducer";
import paymentCategoriesReducer from "./paymentCategoriesReducer";
import paymentsHomeReducer from "./paymentsHomeReducer";
import paymentsProductReportsReducer from "./paymentsProductReportsReducer";
import paymentsReportingReducer from "./paymentsReportingReducer";
import paymentsUserReducer from "./paymentsUserReducer";
import printCreditFilterReducer from "./printCreditFilterReducer";
import printCreditsReducer from "./printCreditsReducer";
import reconciliationReducer from "./reconciliationReducer";
import reconciliationsReducer from "./reconciliationsReducer";
import searchUsersReducer from "./searchUsersReducer";
import smartcardCreditsReducer from "./smartcardCreditsReducer";
import smartcardFilterReducer from "./smartcardFilterReducer";
import transactionsReducer from "./transactionsReducer";
import userBasketReducer from "./userBasketReducer";
import userLunchCreditsReducer from "./userLunchCreditsReducer";
import userLunchTransactionsReducer from "./userLunchTransactionsReducer";
import userOrderReducer from "./userOrderReducer";
import userOrdersReducer from "./userOrdersReducer";
import userPrintCreditsReducer from "./userPrintCreditsReducer";
import userPrintCreditSyncRecordsReducer from "./userPrintCreditSyncRecordsReducer";
import userProductReducer from "./userProductReducer";
import userProductsReducer from "./userProductsReducer";
import userPurchasedItemsReducer from "./userPurchasedItemsReducer";
import userSmartcardsReducer from "./userSmartcardsReducer";
import userSmartcardSyncRecordsReducer from "./userSmartcardSyncRecordsReducer";
import userSmartcardTransactionsReducer from "./userSmartcardTransactionsReducer";
import searchProductsReducer from "./searchProductsReducer";
import userCashlessSaleReducer from "./userCashlessSaleReducer";

const payments = {
  adminCategory: adminCategoryReducer,
  adminProduct: adminProductReducer,
  adminProducts: adminProductsReducer,
  adminProductOrders: adminProductOrdersReducer,
  adminSummary: adminSummaryReducer,
  basketsWithDuplicateOrders: basketsWithDuplicateOrdersReducer,
  incomingReport: incomingReportReducer,
  incomingReports: incomingReportsReducer,
  incomingTransactions: incomingTransactionsReducer,
  inventoryFilter: inventoryFilterReducer,
  managedProduct: managedProductReducer,
  managedProductOrders: managedProductOrdersReducer,
  managedProducts: managedProductsReducer,
  paymentCategories: paymentCategoriesReducer,
  paymentsHome: paymentsHomeReducer,
  paymentsProductReports: paymentsProductReportsReducer,
  paymentsReporting: paymentsReportingReducer,
  paymentsUser: paymentsUserReducer,
  printCreditFilter: printCreditFilterReducer,
  printCredits: printCreditsReducer,
  reconciliation: reconciliationReducer,
  reconciliations: reconciliationsReducer,
  searchPaymentUsers: searchUsersReducer,
  searchProducts: searchProductsReducer,
  smartcardCreditFilter: smartcardFilterReducer,
  smartcardCredits: smartcardCreditsReducer,
  transactions: transactionsReducer,
  userBasket: userBasketReducer,
  userLunchCredits: userLunchCreditsReducer,
  userLunchTransactions: userLunchTransactionsReducer,
  userOrder: userOrderReducer,
  userOrders: userOrdersReducer,
  userPrintCredits: userPrintCreditsReducer,
  userPrintCreditSyncRecords: userPrintCreditSyncRecordsReducer,
  userProduct: userProductReducer,
  userProducts: userProductsReducer,
  userPurchasedItems: userPurchasedItemsReducer,
  userSmartcards: userSmartcardsReducer,
  userSmartcardSyncRecords: userSmartcardSyncRecordsReducer,
  userSmartcardTransactions: userSmartcardTransactionsReducer,
  userCashlessSale: userCashlessSaleReducer
};

export default payments;
