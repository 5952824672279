import React, { useEffect } from "react";
import { Size, Button, Swatches } from "ui-kit";
import styled from "styled-components";
import { useSelector } from "react-redux";
import constants from "configuration/constants";
import schoolActions from "areas/planner/actions/school/schoolActions";
import { useAppSelector } from "reducers/hooks";


const Wrapper = styled.div`
  .on-call-button {
    // width: 100%;
    .icon {
      font-size: 1rem;
    }
  }
`;


interface IRequestOnCallButtonProps {
  handleRequestOnCall: () => void;
  size?: Size;
  schoolId: number;
  fluid?: boolean;
}


const RequestOnCallButton: React.FC<IRequestOnCallButtonProps> = ({
  handleRequestOnCall,
  size,
  schoolId,
  fluid,
}) => {

  const { schoolInformation } = useAppSelector(state => state.school);

  useEffect(() => {
    const pioneersSchool = constants.SCHOOLS.find(school => school.id == 15);
    if (
      schoolId >= 0 && (!schoolInformation || (schoolInformation.id != pioneersSchool.id && schoolInformation.id !== schoolId))
    ) {
      // console.log("SCHOOL INFORMATION");
      // console.log(schoolInformation);
      // console.log("SCHOOL ID");
      // console.log(schoolId);
      schoolActions.getSchoolInformation(schoolId);
    }
  }, [schoolId]);

  return schoolInformation?.enableOnCall ? (
    <Wrapper className="on-call-button-wrapper">
      <Button
        className="on-call-button"
        text="On Call Assistance"
        color={Swatches.Danger}
        icon="bullhorn"
        onClick={handleRequestOnCall}
        size={size ? size : Size.Medium}
        fluid={fluid}
      />
    </Wrapper>
  ) : null;
};

export default RequestOnCallButton;
