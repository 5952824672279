import React from "react";
import { Chip, Swatches } from "ui-kit";
import { BEHAVIOUR_STATUS_KEYS } from "areas/behaviour/constants/behaviours";


interface IBehaviourStatusChipProps {
  behaviourStatusId: number;
}


const BehaviourStatusChip: React.FC<IBehaviourStatusChipProps> = ({ behaviourStatusId }) => {

  return (
    <>
      {behaviourStatusId === BEHAVIOUR_STATUS_KEYS.PENDING_ATTENTION && (
        <Chip
          text="Pending Attention"
          colorSwatch={Swatches.Black}
          tooltip="Pending Attention Behaviour"
          tooltipSub="The behaviour is pending attention"
        />
      )}
      {behaviourStatusId === BEHAVIOUR_STATUS_KEYS.UPSCALED && (
        <Chip
          text="Upscaled"
          colorSwatch={Swatches.Purple}
          tooltip="Upscaled Behaviour"
          tooltipSub="The behaviour has been upscaled"
        />
      )}
    </>
  );
};

export default BehaviourStatusChip;
