import { Constants } from "configuration";
import { IBeehiveConfig, INameValuePair } from "configuration/constants.types";
import moment from "moment";

export interface configArrType {
  label: string;
  value: number;
}


/**
 * Checks if an array is empty
 * @param {ArrayType[]} array  The array to check
 * @returns {boolean} True if the array is empty
 */
const isEmpty = <ArrayType>(array: ArrayType[] | ArrayType) : boolean => {
  if (!Array.isArray(array)) {
    return true;
  }
  return array == null || array.length === 0;
};


/**
 * Removes an item from the given array
 * @param {ArrayType[]} arr The source array to remove from
 * @param {number} index The index of the item to remove
 * @returns {ArrayType[]} The given array minus the item at the given index
 */
const remove = <ArrayType>(arr: ArrayType[], index: number) : ArrayType[] => {
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};


/**
 * Removes an item from the given array
 * @param {ArrayType[]} arr The source array to remove from
 * @param {ArrayType} value The value to remove from the array
 * @returns {ArrayType[]} The given array minus the given item
 */
const removeItem = <ArrayType>(arr: ArrayType[], value: ArrayType) : ArrayType[] => {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};


/**
 * Converts a config object to an array 
 * @param {[key:string]: INameValuePair} constant The source array to remove from
 * @returns {configArrType[]} An array of the constant for use in dropdowns and other Ui-Kit components
 */
const configConstantsToArray = (constant: IBeehiveConfig) : configArrType[] => {
  return Object.keys(constant).map(k => ({
    label: constant[k].name,
    value: constant[k].value
  }));
};


/**
 * Get the sum of all values for the given key in an array of objects
 * @param {ArrayItem} arr The source array
 * @param {string} key The key to lookup values by
 * @returns {configArrType[]} The sum of the values for the given key
 */
const sum = <ArrayItem>(arr: ArrayItem[], key: string) : number => {
  return arr.reduce((a, b) => a + (parseFloat(b[key as keyof object]) || 0), 0);
};


/**
 * Removes duplicates from the given array
 * @param {ArrayType[]} arr The source array to remove from
 * @returns {ArrayType[]} The given array minus with duplicates removed
 */
const removeDuplicates = <ArrayType>(arr: ArrayType[]) : ArrayType[] => {
  return arr.filter((value: ArrayType, index: number) => {
    return index === arr.findIndex(obj => JSON.stringify(obj) === JSON.stringify(value));
  });
}


const sortByDate = <ArrayType extends { createdDate: string | Date }>(arr: ArrayType[]) : ArrayType[] => {
  return arr.sort((a,b) => moment(b.createdDate).unix() - moment(a.createdDate).unix());
}


const arrays = {
  isEmpty,
  remove,
  removeItem,
  configConstantsToArray,
  sum,
  removeDuplicates,
  sortByDate
};

export default arrays;