import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EventsTable from "./eventsTable";
import calendarActions from "areas/calendar/actions/calendarActions";

const SignedUpEvents = ({ student }) => {
  const { events, error, loading, paging } = useSelector(
    state => state.signedUpCalendarEvents
  );

  const [_loading, _setLoading] = useState(false);

  useEffect(() => {
    _setLoading(true);
    const callback = () => _setLoading(false);
    calendarActions.getSignedUpCalendarEvents(
      student.id,
      null,
      0,
      callback,
      callback
    );
  }, []);

  const handlePage = () => {
    calendarActions.getSignedUpCalendarEvents(
      student.id,
      null,
      paging.pageIndex + 1
    );
  };

  return (
    <EventsTable
      student={student}
      events={events}
      loading={_loading}
      handlePage={handlePage}
      paging={paging}
      showAttendance
    />
  );
};

export default SignedUpEvents;
