import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


interface IRewardForMultipleState {
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IRewardForMultipleState = {
  loading: false,
  error: null,
};

const { ADDREWARDFORMULTIPLE } = rewardActions.types;


const rewardForMultipleReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IRewardForMultipleState => {

  switch (action.type) {
    case ADDREWARDFORMULTIPLE.START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ADDREWARDFORMULTIPLE.SUCCESS:
      return { ...state, loading: false };

    case ADDREWARDFORMULTIPLE.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default rewardForMultipleReducer;
