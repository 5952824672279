import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Dropdown } from "ui-kit";
import searchEngineActions from "areas/search/actions/searchEngineActions";
import { RootState } from "reducers/store";
import { SearchResultView } from "types/common/views.types";
import { StudentListView } from "types/users/userListViews.types";

const Wrapper = styled.div``;


interface IStudentPickerProps {
  onChange?: (value: StudentListView, label: string) => void;
  fluid?: boolean;
  excludeSelf?: boolean;
  schools?: number[];
  value?: string;
  style?: object;
}

const StudentPicker: React.FC<IStudentPickerProps> = ({ onChange, fluid, excludeSelf, schools, value, style }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { students, loading, error } = useSelector((state: RootState) => state.searchStudents);

  useEffect(() => {
    value && searchEngineActions.searchStudents(value, { schools: schools });
  }, [value]);
  
  const handleStudentSearch = (value: string) => {
    if (value && value.length > 2) {
      searchEngineActions.searchStudents(value, { schools: schools });
    }
  };

  return (
    <Wrapper className="student-picker" style={style}>
      <Dropdown
        items={students?.map((student: SearchResultView<StudentListView>, index: number) =>
          excludeSelf && student.id == user.id
            ? null
            : {
                key: index,
                label: `${student.data.firstName} ${student.data.lastName}`,
                value: student.data,
                sub: student.school.name,
              }
        )}
        autocomplete
        placeholder="Search students..."
        searchPlaceholder="Search students..."
        onSearchChange={handleStudentSearch}
        onChange={onChange}
        loading={loading}
        error={error && "An error occurred"}
        fluid={fluid}
        value={value && students[0]?.data}
      />
    </Wrapper>
  );
};

export default StudentPicker;
