import { IBeehiveAction } from "types/common/action.types";
import mailoutActions from "../actions/mailoutActions";
import { QueueMessageDetailView } from "../types/mailoutResponse.types";


interface IQueueMessageState {
  queueMessage: QueueMessageDetailView | null;
  loading: boolean;
  error: string | null;
  sending: boolean;
  sendError: string | null;
}

const INITIAL_STATE: IQueueMessageState = {
  queueMessage: null,
  loading: false,
  error: null,
  sending: false,
  sendError: null,
};


const queueMessageReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IQueueMessageState => {

  const { MAILOUT_QUEUEMESSAGE_GET, MAILOUT_QUEUEMESSAGE_SEND } =
    mailoutActions.types;

  switch (action.type) {
    case MAILOUT_QUEUEMESSAGE_GET.START:
      return { ...INITIAL_STATE, loading: true };
    case MAILOUT_QUEUEMESSAGE_GET.SUCCESS:
      return { ...state, loading: false, queueMessage: action.payload };
    case MAILOUT_QUEUEMESSAGE_GET.FAILED:
      return { ...state, loading: false, error: action.payload };

    case MAILOUT_QUEUEMESSAGE_SEND.START:
      return { ...state, sending: true };
    case MAILOUT_QUEUEMESSAGE_SEND.SUCCESS:
      return { ...state, sending: false };
    case MAILOUT_QUEUEMESSAGE_SEND.FAILED:
      return { ...state, sending: false, error: action.payload };

    default:
      return state;
  }
};

export default queueMessageReducer;
