import React from "react";
import {
  Flyout,
  HeadlineStatistic,
  Currency,
  Card,
  Title,
  TitleSize,
  Size,
  Table,
  StructuredList
} from "ui-kit";
import { Constants } from "configuration";
import flyoutActions from "actions/ui/flyouts";
import { useAction } from "hooks";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import { Avatar } from "sharedComponents/common";
import { RefundView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { UserTinyView } from "types/users/userListViews.types";


interface IRefundFlyoutProps {
  refund: RefundView;
}


const RefundFlyout: React.FC<IRefundFlyoutProps> = ({ refund }) => {

  const navigate = useNavigate();

  const handleClose = () => {
    flyoutActions.closeFlyout();
  };

  const handleUserClicked = (user: UserTinyView) => {
    flyoutActions.closeFlyout();
    navigate(`/main/shopkeeper/users/${user.id}`);
  };

  return (
    <Flyout
      title={`Refund ${refund && refund.id}`}
      name={Constants.FLYOUTS.REFUND}
      wide
    >
      <Flyout.Body>
        {refund && (
          <>
            <HeadlineStatistic>
              <HeadlineStatistic.Item
                icon="receipt"
                label="Total"
                value={<Currency value={refund.total} />}
              />
            </HeadlineStatistic>
            <Card>
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="ID">
                    {refund.id}
                  </StructuredList.Item>
                  <StructuredList.Item name="Refund Date">
                    <Moment
                      date={refund.createdDate}
                      format="DD/MM/YYYY HH:mma"
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="Refunded By">
                    <Avatar
                      user={refund.refundedBy}
                      size={Size.Small}
                      onClick={() => handleUserClicked(refund.refundedBy)}
                    />
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
            <Title size={TitleSize.H3} text="Allocations" />
            <Card>
              <Card.Body noPad>
                <Table>
                  <Table.Header>
                    <Table.HeaderCell>Item</Table.HeaderCell>
                    <Table.HeaderCell right>Total</Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    {refund.allocations.map((allocation, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>
                          {allocation.orderLine.description}
                        </Table.Cell>
                        <Table.Cell width={1} right>
                          <Currency value={allocation.total} />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Cell right colspan={2}>
                      <b>
                        <Currency value={refund.total} />
                      </b>
                    </Table.Cell>
                  </Table.Footer>
                </Table>
              </Card.Body>
            </Card>
          </>
        )}
      </Flyout.Body>
    </Flyout>
  );
};

export default RefundFlyout;
