import React, { useState, useEffect, useCallback } from "react";
import lodashDebounce from "lodash.debounce";
import SelectedItem from "./selectedItem";
import SearchResultList from "./searchResultList";
import {
  Swatches,
  Icon,
  Dropdown,
  Spacing,
  Size,
  neutral,
  BorderRadius,
  typescale,
  displayFont,
  fontStyle,
} from "ui-kit";
import { Constants } from "configuration";
import styled from "styled-components";
import searchEngineActions from "areas/search/actions/searchEngineActions";
import ApiExceptionMessage from "../apiExceptionMessage";
import { IStaffTypes } from "configuration/constants.types";
import { GroupTypes, UserType } from "configuration/constants.enums";
import { StaffType } from "../../../types/users/userStaff.types";
import { SearchResultViewBase } from "types/common/views.types";

const Wrapper = styled.div`
  display: flex;
  background: ${neutral[200]};
  border-radius: ${BorderRadius.Default}px;

  &:focus-within {
    background: ${neutral[600]};

    .search-text-box-wrapper .selected-items .selected-item {
      background-color: ${neutral[100]};
      ${fontStyle(displayFont.medium, typescale.paragraph, neutral[600])};
    }
  }

  .search-text-box-wrapper {
    display: flex;
    flex-grow: 1;
    align-items: center;
    position: relative;
    padding: ${Spacing.Small + 2}px;
    height: ${Spacing.ExtraLarge}px;

    .selected-items {
      align-self: center;
      display: flex;
    }

    input.search-text-box {
      flex-grow: 1;
      border: unset !important;
      background: unset;
      font-weight: bold;
      ${fontStyle(displayFont.medium, typescale.paragraph)};

      &:focus {
        color: ${neutral[100]};
      }
    }

    .clear-results {
      margin: 0 ${Spacing.Small}px;
      line-height: 0;

      .icon {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .ui.selection.dropdown.search-options {
    background: grey;
    border: 1px solid grey;
    color: white;
    font-weight: bold;
    border-radius: 0 0.5rem 0.5rem 0;

    &.visible > .text {
      color: white;
      font-weight: bold;
    }
  }
`;

export const SEARCH_GROUP_TYPE_KEYS = {
  CLASS_GROUP: 0,
  SUBJECT: 1,
  TUTOR_GROUP: 2,
  YEAR_GROUP: 3,
  HOUSE: 4,
  CUSTOM_GROUP: 5,
  STUDENT: 6,
  TEACHER: 7,
  ASSOCIATE: 8,
  STAFF: 9
};

export const SEARCH_GROUP_TYPES = [
  {
    key: SEARCH_GROUP_TYPE_KEYS.CLASS_GROUP,
    label: "Class Group",
    value: GroupTypes.CLASS,
  },
  {
    key: SEARCH_GROUP_TYPE_KEYS.SUBJECT,
    label: "Subject",
    value: GroupTypes.SUBJECT,
  },
  {
    key: SEARCH_GROUP_TYPE_KEYS.TUTOR_GROUP,
    label: "Tutor Group",
    value: GroupTypes.TUTOR,
  },
  {
    key: SEARCH_GROUP_TYPE_KEYS.YEAR_GROUP,
    label: "Year Group",
    value: GroupTypes.YEAR,
  },
  {
    key: SEARCH_GROUP_TYPE_KEYS.HOUSE,
    label: "House",
    value: GroupTypes.HOUSE,
  },
  {
    key: SEARCH_GROUP_TYPE_KEYS.CUSTOM_GROUP,
    label: "Custom Group",
    value: GroupTypes.CUSTOM,
  },
  {
    key: SEARCH_GROUP_TYPE_KEYS.STUDENT,
    label: "Student",
    value: 6,
  },
  {
    key: SEARCH_GROUP_TYPE_KEYS.TEACHER,
    label: "Teacher",
    value: 7,
  },
  {
    key: SEARCH_GROUP_TYPE_KEYS.ASSOCIATE,
    label: "Associate",
    value: 8,
  },
  {
    key: SEARCH_GROUP_TYPE_KEYS.STAFF,
    label: "Staff",
    value: 9
  }
];

interface ItempGroupType {
  key: number;
  label: string;
  value: GroupTypes | StaffType | UserType;
}

interface ISearchProps {
  groupTypes: (GroupTypes | StaffType | UserType)[];
  allowMultiple?: boolean;
  selected?: any[];
  handleSelectItem?: (filter: any) => void;
  handleDeleteItem?: (filter: any) => void;
  schoolId?: number;
  staff?: boolean;
  disabled?: boolean;
  academicYearId?: number;
}

const Search: React.FC<ISearchProps> = ({
  groupTypes,
  allowMultiple,
  selected,
  handleSelectItem,
  handleDeleteItem,
  schoolId,
  staff,
  disabled,
  academicYearId
}) => {

  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState<SearchResultViewBase[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [searchGroupTypes, setSearchGroupTypes] = useState<ItempGroupType[]>(
    []
  );
  const [selectedGroupType, setSelectedGroupType] = useState(groupTypes[0]);
  const [searchError, setSearchError] = useState(null);

  useEffect(() => {
    if (
      (searchText.length >= 2) || 
      selectedGroupType === Constants.GROUP_TYPES.YEAR
    ) {
      debouncedSearchChange(searchText, schoolId, academicYearId);
    }
  }, [searchText, schoolId, academicYearId]);

  const debouncedSearchChange = useCallback(
    lodashDebounce(
      (searchTerm: string, _schoolId: number, academicYearId: number) =>
      searchEngineActions
      .getSearchEndPoint(
        selectedGroupType,
        searchTerm,
        _schoolId ? [_schoolId] : null,
        academicYearId
      )
      .then((response) => {
        setSearchResults(response.items);
        setSearchError(null);
      })
      .catch((error: any) => {
        setSearchError(error);
      }),
      500
    ),
    []
  );

  useEffect(() => {
    if (!selected) {
      return;
    }
    const tempSelected: any[] = [];
    for (let i = 0; i < selected.length; i++) {
      const item = selected[i];
      tempSelected.push(item);
    }
    setSelectedItems(tempSelected);
  }, [selected]);

  useEffect(() => {
    const tempSearchGroupTypes: ItempGroupType[] = [];

    for (let i = 0; i < groupTypes.length; i++) {
      SEARCH_GROUP_TYPES.forEach(groupType => {
        if (groupType.key === groupTypes[i]) {
          const tempGroupType = groupType;
          tempSearchGroupTypes.push(tempGroupType);
        }
      });
    }
    setSearchGroupTypes(tempSearchGroupTypes);
  }, [groupTypes]);

  const clearResults = () => {
    setSearchText("");
    setSearchResults([]);
  };

  const getSelectedOptionName = () => {
    if (searchGroupTypes.length > 0) {
      const groupType = searchGroupTypes.find(
        groupType => groupType.key === selectedGroupType
      );

      return groupType?.label;
    } else {
      return "";
    }
  };

  const handleChangeSearchOption = (value: GroupTypes | StaffType | UserType, label: string) => {
    setSelectedGroupType(value);
  };

  const handleClickItem = (item: any) => {
    handleSelectItem?.(item);

    if (allowMultiple) {
      const tempSelectedItems = selectedItems.slice();
      tempSelectedItems.push(item);
      setSelectedItems(tempSelectedItems);
    } else {
      const tempSelectedItems = selectedItems.slice();
      tempSelectedItems[0] = item;
      setSelectedItems(tempSelectedItems);
    }
    clearResults();
  };

  const handleRemoveItem = (item: any) => {
    handleDeleteItem?.(item);

    const tempSelectedItems = selectedItems.slice();
    const selectedItemIndex = selectedItems.findIndex(
      selectedItem => item.id === selectedItem.id
    );

    tempSelectedItems.splice(selectedItemIndex, 1);

    setSelectedItems(tempSelectedItems);
  };

  return (
    <div className="group-search-wrapper">
      <ApiExceptionMessage error={searchError} />
      <Wrapper>
        <div className="search-text-box-wrapper">
          <div className="selected-items">
            {selectedItems
            .filter(x => x != null)
            .map((item, index) => (
              <SelectedItem
                key={index}
                item={item}
                handleDeleteItem={handleRemoveItem}
                disabled={disabled}
              />
            ))}
          </div>

          <input
            className="search-text-box"
            type="text"
            placeholder={`Search ${getSelectedOptionName()}`}
            value={searchText}
            onChange={event => setSearchText(event.target.value)}
            disabled={disabled}
          />

          {(searchResults.length > 0 || searchText.length > 0) && (
            <div className="clear-results" onClick={clearResults}>
              <Icon
                value="times"
                color={Swatches.Default.swatch}
                size={Size.Small}
              />
            </div>
          )}
        </div>

        {searchGroupTypes.length > 1 && (
          <Dropdown
            className="search-options"
            value={selectedGroupType}
            items={searchGroupTypes}
            onChange={handleChangeSearchOption}
          />
        )}
      </Wrapper>

      {searchResults.length > 0 && (
        <SearchResultList
          //   loading={loading}
          searchResults={searchResults}
          handleClickItem={handleClickItem}
        />
      )}
    </div>
  );
};

Search.defaultProps = {
  groupTypes: [Constants.GROUP_TYPES.GLOBAL],
};

export default Search;
