import { TagListView } from "areas/send/types/passportResponse.types";
import tagsActions from "../../actions/tags/tagsActions";
import { IBeehiveAction } from "types/common/action.types";

interface ITagsState {
  tags: TagListView[];
  loading: boolean;
  saving: boolean;
  error: string | null;
}

const INITIAL_STATE: ITagsState = {
  tags: [],
  loading: false,
  saving: false,
  error: null,
};


const tagsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ITagsState => {

  const { TAGS_GET, TAG_CREATE, TAG_SAVE, TAG_DELETE } = tagsActions.types;

  switch (action.type) {
    case TAGS_GET.START:
      return { ...state, loading: true };
    case TAGS_GET.SUCCESS:
      return { ...state, tags: action.payload, loading: false };
    case TAGS_GET.FAILED:
      return { ...state, error: action.payload, loading: false };

    case TAG_CREATE.SUCCESS:
      return {
        ...state,
        tags: [action.payload, ...state.tags],
      };

    case TAG_SAVE.SUCCESS:
      return {
        ...state,
        tags: state.tags.map(a =>
          a.id === action.payload.id ? action.payload : a
        ),
      };

    case TAG_DELETE.SUCCESS:
      return {
        ...state,
        tags: state.tags.filter(a => a.id !== action.payload),
      };

    default:
      return state;
  }
};

export default tagsReducer;
