import React from "react";
import { ReactComponent as BearSvg } from "assets/primary/characters/bear.svg";
import { IAnimalIconProps } from "./primaryCharacter";


export const Bear: React.FC<IAnimalIconProps> = ({ className }) => {
  
  return (
    <BearSvg 
      //alt={alt} 
      className={className} 
    />
  )
};
export default Bear;
