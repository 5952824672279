import { DatePicker, DetailLabel, Dropdown, EmptyMessage, HelpPopup, Label, Size, Spacing, Spinner, Sub, Table, TimePicker, Tooltip, getFontSize, neutral } from "ui-kit";
import { DetentionStudentViewExt, RescheduleStatus } from "./bulkRescheduleModal";
import { Avatar } from "sharedComponents/common";
import { BehaviourWrapper } from "./missedDetentions/missedDetentionsTableRow";
import Moment from "react-moment";
import moment from "moment";
import styled from "styled-components";
import { danger, warning } from "ui-kit/common/colors";
import Tippy from "@tippyjs/react";
import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "reducers/hooks";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import ValidDatePicker from "sharedComponents/common/validDatePicker";


const RemoveButton = styled.button<{disabled?: boolean}>`
  align-self: center;
  font-size: ${getFontSize(Size.ExtraLarge)}rem;
  background-color: unset;
  border: none;
  margin: 0;
  padding: 0;
  color: ${neutral[500]};
  cursor: pointer;
  line-height: 1rem;

  &:hover {
    ${({ disabled }) => !disabled && `color: ${danger};`}
  }
`;

const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;

  .date-picker {
    margin-right: ${Spacing.Medium}px;
  }
`;

const DateTimeWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const DateCheckMessageWrapper = styled.div`
  .error-sub {
    color: ${danger};
  }
  .warning-sub {
    color: ${warning};
  }
`;


export enum BulkRescheduleTableMode {
  Draft = 0,
  Error = 1
}


interface IBulkRescheduleTableProps {
  detentions: DetentionStudentViewExt[];
  handleDetentionDateChange: (detention: DetentionStudentViewExt, newDate: Date) => void;
  handleDetentionTimeChange: (detention: DetentionStudentViewExt, newTime: string) => void;
  handleDetentionTypeChange: (detention: DetentionStudentViewExt, detentionTypeId: number) => void;
  handleRemove: (detention: DetentionStudentViewExt) => void;
  mode: BulkRescheduleTableMode;
  schoolId: number;
  bodyStyle?: React.CSSProperties;
  rowStyle?: React.CSSProperties;
  loading?: boolean;
}


const BulkRescheduleTable: React.FC<IBulkRescheduleTableProps> = ({ 
  detentions,
  handleDetentionDateChange,
  handleDetentionTimeChange,
  handleDetentionTypeChange,
  handleRemove,
  mode,
  schoolId,
  bodyStyle,
  rowStyle,
  loading,
}) => {

  const ref = useRef<HTMLButtonElement>();
  const {
    detentionTypes,
    loading: loadingTypes,
    error: detentionTypesError,
  } = useAppSelector(state => state.detentionTypes);
  const {
    checkingDetentions,
    preflightResponse,
    preflightError
  } = useAppSelector(state => state.bulkRescheduleDetentions);
  const [detentionTypesArray, setDetentionTypesArray] = useState<IDropdownDataItemProps<string, number, null>[]>([]);

  useEffect(() => {
    setDetentionTypesArray(detentionTypes.map(detentionType => ({
      key: detentionType.detentionTypeId,
      label: detentionType.detentionTypeName,
      value: detentionType.detentionTypeId,
    })));
  }, [detentionTypes]);

  if (detentions.length < 1) {
    return (
      <></>
    )
  }
  
  
  return (
    <>
      <Table
        grow
        caption={mode === BulkRescheduleTableMode.Error && "Failed"}
        loading={loading}
        empty={detentions?.length === 0}
        emptyMessage={
          <EmptyMessage
            icon="list"
            title="No detentions"
            summary="No detentions were found"
            cover
          />
        }
      >
        <Table.Header>
          <Table.HeaderCell width={1.5} show={mode === BulkRescheduleTableMode.Error}>Error</Table.HeaderCell>
          <Table.HeaderCell width={1.5}>Student</Table.HeaderCell>
          <Table.HeaderCell width={1.25}>Details</Table.HeaderCell>
          <Table.HeaderCell width={3.25}>New Date</Table.HeaderCell>
          <Table.HeaderCell width={1.25}>Detention Type</Table.HeaderCell>
          <Table.HeaderCell width={0.5} center></Table.HeaderCell>
        </Table.Header>
        <Table.Body style={bodyStyle}>
          
          { detentions.map((detention, index) => (
            <Table.Row key={index} style={rowStyle}>
              <Table.Cell width={1.5} show={mode === BulkRescheduleTableMode.Error}>
                <Label>{detention.errorMessage}</Label>
              </Table.Cell>
              <Table.Cell width={1.5}>
                <Avatar
                  user={detention.student}
                  sub={
                    detention.tutorGroup?.friendlyName
                      ? `${detention.student.simsId} | ${detention.tutorGroup.friendlyName}`
                      : detention.student.simsId
                  }
                />
              </Table.Cell>
              <Table.Cell width={1.25}>
                <BehaviourWrapper>
                  <DetailLabel 
                    bold 
                    label={detention.codeDisplayName}
                    sub={moment(detention.originalDate).format("DD/MM/YYYY HH:mm")}
                  />
                  <HelpPopup title={detention.categoryName} />
                </BehaviourWrapper>
              </Table.Cell>
              <Table.Cell width={3.25}>
                <DateTimeWrapper>
                  <DatePickerWrapper>

                    <ValidDatePicker
                      dateFormat="DD/MM/YYYY"
                      closeOnSelect
                      selectedDate={moment(detention.startDate).toDate()}
                      onChange={date => handleDetentionDateChange(detention, moment(date).toDate())}
                      disabled={detention.rescheduleStatus === RescheduleStatus.Rescheduled}
                      fluid
                      validate
                      isForDetention
                      schoolId={schoolId}
                    />

                    {/* The reason for this random key is that we want a full rerender that clears the timepicker
                      usestates if the selected time does not pass validation checks
                      https://stackoverflow.com/questions/54895883/reset-to-initial-state-with-react-hooks
                    */}
                    <TimePicker
                      key={Math.floor(Math.random() * 1001)}
                      initialValue={moment(detention.startDate).format("HH:mm")}
                      onChange={time => handleDetentionTimeChange(detention, time)}
                      disabled={detention.rescheduleStatus === RescheduleStatus.Rescheduled}
                    />
                    {checkingDetentions && <Spinner size={Size.Small} style={{marginLeft: Spacing.Small}} />}
                    
                    
                  </DatePickerWrapper>
                  {/* {preflightError && (
                    <DateCheckMessageWrapper>
                      <Sub className="error-sub">
                        {preflightError?.response?.data?.exceptionMessage ||
                          preflightError?.exceptionMessage ||
                          (preflightError && "An error has occurred.")}
                      </Sub>
                    </DateCheckMessageWrapper>
                  )} */}
                  
                  { preflightResponse && 
                    preflightResponse.length < detentions.length &&
                    preflightResponse.some(x => 
                      x.detentionId === detention.detentionId &&
                      !x.isDateValid
                    ) && (
                    <DateCheckMessageWrapper>
                      <Sub className="warning-sub">
                        The date was not valid. A valid date has automatically been
                        selected.
                      </Sub>
                    </DateCheckMessageWrapper>
                  )}
                </DateTimeWrapper>
              </Table.Cell>
              <Table.Cell width={1.25}>
                <Dropdown
                  placeholder="Sanction Type"
                  items={detentionTypesArray}
                  onChange={value => handleDetentionTypeChange(detention, value)}
                  value={detention.detentionTypeId}
                  fluid
                  disabled={detention.rescheduleStatus === RescheduleStatus.Rescheduled}
                />
              </Table.Cell>
              <Table.Cell width={0.5} center>
                <>
                  <RemoveButton
                    ref={ref}
                    className="remove-student-button"
                    onClick={event => {
                      event.stopPropagation();
                      handleRemove(detention);
                    }}
                    disabled={detention.rescheduleStatus === RescheduleStatus.Rescheduled}
                  >
                    &#10799;
                  </RemoveButton>
                  <Tippy
                    reference={ref}
                    content={<Tooltip title="Remove Student" />}
                  />
                </>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  )
}

export default BulkRescheduleTable;