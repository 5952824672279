import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Spacing, neutral } from "ui-kit";
import MultipleBehaviourErrorForm from "./multipleBehaviourErrorForm";
import MultipleBehaviourStudents from "./multipleBehaviourStudents";
import MultipleBehaviourPointForm from "./multipleBehaviourPointForm";
import { useSelector } from "react-redux";
import { BULK_BEHAVIOUR_STATUS } from "areas/behaviour/constants/behaviours";
import { IBehaviourDetails, IMultipleBehaviourResponse, MultipleBehavioursSaveResultsViewExt } from "areas/behaviour/utils/detentions";
import { SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";
import { ClassGroupStaffView } from "types/users/userGroups.types";
import { useAppSelector } from "reducers/hooks";


const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  height: 100%;
`;

const FormWrapper = styled.div`
  flex-grow: 1;
  padding: ${Spacing.Large}px;
  border-left: 1px solid ${neutral[300]};
`;


interface IMultipleBehaviourModalContentProps {
  multipleBehaviourResponse: IMultipleBehaviourResponse;
  selectedCategory: SchoolBehaviourCategoryListView;
  behaviourDetails: IBehaviourDetails;
  setBehaviourDetails: (details: IBehaviourDetails) => void;
  group: ClassGroupStaffView;
}


const MultipleBehaviourModalContent: React.FC<IMultipleBehaviourModalContentProps> = ({
  multipleBehaviourResponse,
  selectedCategory,
  behaviourDetails,
  setBehaviourDetails,
  group,
}) => {

  const { schoolInformation } = useAppSelector(state => state.school);
  const [selectedStudentBehaviour, setSelectedStudentBehaviour] = useState<MultipleBehavioursSaveResultsViewExt>(null);

  useEffect(() => {
    setSelectedStudentBehaviour(null);
  }, [selectedCategory]);

  return (
    <Wrapper>
      {multipleBehaviourResponse && (
        <MultipleBehaviourStudents
          multipleBehaviourResponse={multipleBehaviourResponse}
          selectedStudentBehaviour={selectedStudentBehaviour}
          handleSelectStudentBehaviour={behaviour =>
            setSelectedStudentBehaviour(behaviour)
          }
        />
      )}

      {multipleBehaviourResponse && (
        <FormWrapper>
          {multipleBehaviourResponse.bulkBehaviourStatus ===
            BULK_BEHAVIOUR_STATUS.NOT_SENT && selectedCategory ? (
            <MultipleBehaviourPointForm
              selectedCategory={selectedCategory}
              groupId={group.id}
              schoolId={group.school.id}
              schoolInformation={schoolInformation}
              behaviourDetails={behaviourDetails}
              setBehaviourDetails={setBehaviourDetails}
            />
          ) : (
            <MultipleBehaviourErrorForm
              selectedStudentBehaviour={selectedStudentBehaviour}
              incidentDetails={multipleBehaviourResponse.incidentDetails}
              group={group}
            />
          )}
        </FormWrapper>
      )}
    </Wrapper>
  );
};

export default MultipleBehaviourModalContent;
