import React from "react";
import { TabBar } from "ui-kit";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { StudentStartupView } from "types/users/userListViews.types";


interface IChildTabsProps {
  onChildClicked?: (name: string, index: number) => void;
  singleChild?: React.ReactNode;
}

const ChildTabs: React.FC<IChildTabsProps> = ({ onChildClicked, singleChild }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);

  const handleChildClicked = (name: string, index: number) => {
    onChildClicked?.(name, index);
  };

  return user?.children?.length > 1 || !singleChild ? (
    <TabBar onItemClicked={handleChildClicked}>
      { user.children.map((children: StudentStartupView, index: number) => (
        <TabBar.Item
          key={index}
          name={children.id}
          text={`${children.details.firstName} ${children.details.lastName}`}
        />
      ))}
    </TabBar>
  ) : (
    singleChild
  );
};

export default ChildTabs;
