import { Constants } from "configuration";
import { CustomGroupCategory } from "../components/groups/customGroups/customGroupCategoryDropdown";
import { GroupListView } from "types/users/userGroups.types";
import { GroupType } from "types/planner/groups.types";
import { UserStartupView } from "types/users/userStartup.types";
import { UserTinyView } from "types/users/userListViews.types";


export const getGroupSub = (group: GroupListView) => {

  switch (group.type) {
    case GroupType.ClassGroup:
      return group.name;

    case GroupType.TutorGroup:
      return "Tutor Group";

    case GroupType.Custom:
      return `${group.yearGroupName ? group.yearGroupName !== "Year " ? group.yearGroupName : "" : ""} ${
        group.subjectName ? group.subjectName : ""
      } ${group.category ? CustomGroupCategory[group.category] : "Custom"} Group`;

    case GroupType.Provision:
      const getYearAndSubject = () => {
        if (group.yearGroupName && group.subjectName) {
          return `Provision - ${group.yearGroupName} ${group.subjectName}`;
        } else if (group.yearGroupName) {
          return `Provision - ${group.yearGroupName}`;
        } else if (group.subjectName) {
          return `Provision - ${group.subjectName}`;
        } else {
          return "Provision";
        }
      };

      return getYearAndSubject();

    default:
      return;
  }
};

export const isMyGroup = (responsibleStaff: UserTinyView[], user: UserStartupView) => {
  return responsibleStaff?.some(staff => staff.id === user.id);
};

export const addGroupIdToPayload = <T>(payload: T, groupId: number, groupType: GroupType) => {
  switch (groupType) {
    case GroupType.ClassGroup:
      return { ...payload, classGroupId: groupId };
    case GroupType.TutorGroup:
      return { ...payload, tutorGroupId: groupId };
    case GroupType.Custom:
      return { ...payload, customGroupId: groupId };
    case GroupType.Provision:
      return { ...payload, provisionGroupId: groupId };
    default:
      return payload;
  }
};
