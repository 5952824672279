import { useSelector } from "react-redux";
import {
  ActionBar,
  Button,
  Size,
  Swatches,
  Right,
  Left,
  DatePicker
} from "ui-kit";
import { useEffect } from "react";
import paymentActions from "areas/payments/actions/paymentsActions";
import ReconciliationFlyout from "./reconciliationFlyout";
import flyoutActions from "actions/ui/flyouts";
import { Constants } from "configuration";
import { RootState } from "reducers/store";


interface IReconciliationsFilterProps {
  onReconciliationSaved?: (reconciliationId: number) => void;
}


const ReconciliationsFilter: React.FC<IReconciliationsFilterProps> = ({ onReconciliationSaved }) => {

  const { filter } = useSelector((state: RootState) => state.reconciliations);

  useEffect(() => {}, []);

  const handleDateChange = (date: Date) => {
    date && paymentActions.filterReconciliationsByDate(date);
  };

  const handleReconciliationSaved = (reconciliationId: number) => {
    onReconciliationSaved?.(reconciliationId);
  };

  const handleNewReconciliation = () => {
    flyoutActions.openFlyout(Constants.FLYOUTS.CREATE_RECONCILIATION);
  };

  return (
    <>
      <ActionBar>
        <Left>
          <DatePicker
            dateFormat="DD/MM/YYYY"
            closeOnSelect
            selectedDate={filter.date}
            onChange={handleDateChange}
          />
        </Left>
        <Right>
          <Button
            text="New Reconciliation"
            onClick={handleNewReconciliation}
            color={Swatches.Primary}
            size={Size.Medium}
          />
        </Right>
      </ActionBar>
      <ReconciliationFlyout onReconciliationSaved={handleReconciliationSaved} />
    </>
  );
};

export default ReconciliationsFilter;
