import store from "reducers/store";
import {
  adminRoutes,
  consentRoutes,
  documentRoutes,
} from "../areas/administration/adminRoutes";
import { IconDefinition, faClipboardUser } from "@fortawesome/pro-solid-svg-icons";
import { analyticsRoutes } from "../areas/analytics/analyticsRoutes";
import { behaviourRoutes } from "../areas/behaviour/behaviourRoutes";
import { calendarRoutes } from "../areas/calendar/calendarRoutes";
import { formsRoutes } from "../areas/forms/formsRoutes";
import { leaveRoutes, pmRoutes } from "../areas/humanResources/hrRoutes";
import { matriculationRoutes } from "../areas/matriculation/matriculationRoutes";
import { newsdeskRoutes } from "../areas/news/newsRoutes";
import Constants from "./constants";
import { UserType } from "./constants.enums";
import { IRole } from "./constants.types";
import Routes from "./routes";
import {
  activityRoutes,
  assignmentRoutes,
  groupRoutes,
} from "areas/planner/plannerRoutes";
import { SenStatus } from "types/users/userSen.types";
import { UserStartupView } from "types/users/userStartup.types";
import { users } from "utils";


interface Navigation {
  name: string;
  icon: string | IconDefinition;
  route: string;
  tooltip: string;
  allowFuture?: boolean;
  users?: UserType[];
  disabled?: boolean;
  roles?: IRole[];
}

var urlPrefix: string = "/main";
var navigation = (user: UserStartupView): Navigation[] => [
  {
    name: "Home",
    icon: "home",
    route: Routes.home,
    tooltip: "Beehive Home and Newsfeed",
    allowFuture: true,
  },
  {
    name: "Assignments",
    route: `${urlPrefix}${assignmentRoutes.default}`,
    icon: "briefcase",
    tooltip: "View and manage homework assignments",
    allowFuture: false,
  },
  {
    name: "Timetable",
    route: `${urlPrefix}${Routes.timetable.default}`,
    icon: "clock",
    tooltip: "View your timetables",
    allowFuture: false,
  },
  {
    name: "Documents",
    route: `${urlPrefix}${documentRoutes.default}`,
    icon: "file-certificate",
    tooltip: "View your downloadable documents",
    users: [Constants.USER_TYPES.STUDENT],
    allowFuture: false,
  },
  {
    name: "Events",
    route: `${urlPrefix}${calendarRoutes.default}`,
    icon: "calendar",
    tooltip: "View forthcoming events",
    disabled: false,
    allowFuture: false,
  },
  {
    name: "Classes",
    route: `${urlPrefix}${groupRoutes.default}`,
    icon: "users-class",
    tooltip: "View your classes and other groups",
    users: [Constants.USER_TYPES.STAFF],
    allowFuture: false,
  },
  {
    name: "Classes",
    route: `${urlPrefix}${Routes.classes.default}`,
    icon: "users-class",
    tooltip: "View your classes",
    users: [Constants.USER_TYPES.STUDENT],
    allowFuture: false,
  },
  {
    name: "Behaviour",
    route: `${urlPrefix}${behaviourRoutes.default}`,
    icon: "angel",
    tooltip: "Manage detentions and smart tasks",
    users: [Constants.USER_TYPES.STAFF],
    allowFuture: false,
  },
  {
    name: "Rewards & Behaviours",
    route: `${urlPrefix}${activityRoutes.default}`,
    icon: "shield-alt",
    tooltip:
      "View activity in school, including awards, behaviour and reward points",
    users: [Constants.USER_TYPES.PARENT, Constants.USER_TYPES.STUDENT],
    allowFuture: false,
  },
  {
    name: "Houses",
    route: `${urlPrefix}${Routes.houses.default}`,
    icon: "shield-alt",
    tooltip: "House events and points",
    disabled: true,
    allowFuture: false,
  },
  {
    name: "Store",
    route: `${urlPrefix}${Routes.store.default}`,
    icon: "shopping-bag",
    tooltip: "Buy books, trips and other items online",
    allowFuture: true,
  },
  {
    name: "Smartcard",
    route: `${urlPrefix}${Routes.smartcard.default}`,
    icon: "id-card-alt",
    tooltip: "Check smartcard and print credit balances and buy topups",
    disabled: 
    (users.isParent(user) && !user.children.some(c => !c.school.isPrimary)) || 
    (users.isStudent(user) && user.baseSchool.isPrimary),
    allowFuture: true,
  },
  {
    name: "QLT",
    route: `${urlPrefix}${pmRoutes.default}`,
    icon: "rocket",
    tooltip:
      "View and manage your and your staff's performance management reviews",
    users: [Constants.USER_TYPES.STAFF],
    allowFuture: false,
  },
  {
    name: "Forms",
    route: `${urlPrefix}${formsRoutes.default}`,
    icon: "paste",
    tooltip: "Submit finance and HR forms",
    users: [Constants.USER_TYPES.STAFF],
    allowFuture: false,
  },
  // {
  //   name: "Declarations",
  //   route: `${urlPrefix}${formsRoutes.declarations}`,
  //   icon: faClipboardUser,
  //   tooltip: "Submit Declarations of Interest",
  //   users: [Constants.USER_TYPES.STAFF],
  //   allowFuture: false,
  // },
  {
    name: "Holiday & Leave",
    route: `${urlPrefix}${leaveRoutes.default}`,
    icon: "plane",
    tooltip: "Request and approve leave",
    users: [Constants.USER_TYPES.STAFF],
    allowFuture: false,
  },
  {
    name: "Shopkeeper",
    route: `${urlPrefix}${Routes.shopkeeper.default}`,
    icon: "bags-shopping",
    tooltip: "Manage store products and smartcards",
    users: [Constants.USER_TYPES.STAFF],
    roles: [
      Constants.ROLES.SHOPKEEPER_USER,
      Constants.ROLES.SHOPKEEPER_ADMINISTRATOR,
      Constants.ROLES.FINANCE_BUYER,
    ],
    allowFuture: false,
  },
  {
    name: "Newsdesk",
    route: `${urlPrefix}${newsdeskRoutes.default}`,
    icon: "signal-stream",
    tooltip: "Manage news articles",
    users: [Constants.USER_TYPES.STAFF],
    roles: [Constants.ROLES.NEWS_ADMINISTRATOR],
    allowFuture: false,
  },
  {
    name: "Catering",
    route: `${urlPrefix}${Routes.catering.default}`,
    icon: "utensils",
    tooltip: "Manage lunch registers and dinner money accounts",
    users: [Constants.USER_TYPES.STAFF],
    roles: [Constants.ROLES.CATERING_MANAGER, Constants.ROLES.DEVELOPMENT],
    allowFuture: false,
  },
  {
    name: "Analytics",
    route: `${urlPrefix}${analyticsRoutes.default}`,
    icon: "chart-line",
    tooltip: "Run reports on data stored in Beehive",
    users: [Constants.USER_TYPES.STAFF],
    allowFuture: false,
  },
  {
    name: "Consent",
    route: `${urlPrefix}${consentRoutes.default}`,
    icon: "clipboard-check",
    tooltip: "View your consent forms and update previously given consent",
  },
  {
    name: "Documents",
    route: `${urlPrefix}${documentRoutes.admin}`,
    icon: "file-certificate",
    tooltip: "Administration for downloadable documents",
    users: [Constants.USER_TYPES.STAFF],
    roles: [Constants.ROLES.EXAMINATIONS_ADMINISTRATOR],
    allowFuture: false,
  },

  // {
  //   name: "Coronavirus",
  //   route: `${urlPrefix}${Routes.covid.default}`,
  //   icon: "virus",
  //   tooltip: "Submit test results and update consent"
  // },
  {
    name: "Links",
    route: `${urlPrefix}${Routes.links.default}`,
    icon: "link",
    tooltip: "Useful internal and external links",
  },
  // {
  //   name: "Provisions",
  //   route: `${urlPrefix}${Routes.provisions.default}`,
  //   icon: "hand-holding-seedling",
  //   tooltip: "Update provision settings and reports",
  //   users: [Constants.USER_TYPES.STAFF],
  //   roles: [Constants.ROLES.SEN_ADMINISTRATOR, Constants.ROLES.SEN_COORDINATOR],
  // },
  {
    name: "SEND",
    route: `${urlPrefix}${Routes.send.default}`,
    icon: "hand-holding-seedling",
    tooltip: "Update SEND settings and reports",
    users: [Constants.USER_TYPES.STAFF],
    roles: [
      Constants.ROLES.SEN_ADMINISTRATOR,
      Constants.ROLES.SEN_COORDINATOR,
      Constants.ROLES.LEADERSHIP,
      Constants.ROLES.TRUST_LEADERSHIP,
    ],
  },
  {
    name: "SEND",
    route: `${urlPrefix}${Routes.send.parent}`,
    icon: "hand-holding-seedling",
    tooltip: "Update SEND settings and reports",
    disabled: !(user.children.some(c => c.senStatus != SenStatus.N)),
    users: [Constants.USER_TYPES.PARENT],
    roles: [],
  },
  {
    name: "Matriculation",
    route: `${urlPrefix}${matriculationRoutes.default}`,
    icon: "users",
    tooltip: "Matriculate submissions for potential new students",
    users: [Constants.USER_TYPES.STAFF],
    roles: [
      Constants.ROLES.MATRICULATION_ADMINISTRATOR,
      Constants.ROLES.MATRICULATION_USER,
      Constants.ROLES.IT_ADMINISTRATOR,
      Constants.ROLES.IT_USER,
    ],
  },
  {
    name: "Administration",
    route: `${urlPrefix}${adminRoutes.default}`,
    icon: "cogs",
    tooltip: "Set system and user preferences and other administration tasks",
    roles: [
      Constants.ROLES.DEVELOPMENT,
      Constants.ROLES.HR_ADMINISTRATOR,
      Constants.ROLES.IT_USER,
      Constants.ROLES.IT_ADMINISTRATOR,
      Constants.ROLES.STUDENTPROFILE_ADMINISTRATOR,
      Constants.ROLES.SYNCDATA_ADMINISTRATOR
    ],
  },
];

export default navigation;
