import React from "react";
import { Chip, Swatches } from "ui-kit";
import { Constants } from "configuration";
import { LeaveRequestType } from "areas/humanResources/types/leaveShared.types";


interface ILeaveRequestTypeChipProps {
  type: LeaveRequestType;
  fluid?: boolean;
}


const LeaveRequestTypeChip: React.FC<ILeaveRequestTypeChipProps> = ({ type, fluid }) => {

  var swatch = null;
  var name = "";

  switch (type) {
    case LeaveRequestType.Holiday:
      swatch = Swatches.Miami;
      name = "Holiday";
      break;
    case LeaveRequestType.Toil:
      swatch = Swatches.Teal;
      name = "TOIL";
      break;
    case LeaveRequestType.AuthorisedAbsence:
      swatch = Swatches.Purple;
      name = "Authorised Absence";
      break;
    case LeaveRequestType.Compassionate:
      swatch = Swatches.Purple;
      name = "Compassionate Leave";
      break;
    case LeaveRequestType.GoldenTime:
      swatch = Swatches.Warning;
      name = "Golden Time";
      break;
    case LeaveRequestType.Cpd:
      swatch = Swatches.Orange;
      name = "CPD";
      break;
    default:
      swatch = Swatches.Low;
      name = "N/A";
      break;
  }

  return <Chip text={name} colorSwatch={swatch} fluid={fluid} />;
};

export default LeaveRequestTypeChip;
