import React, { useState } from "react";
import styled from "styled-components";
import {
  neutral,
  BorderRadius,
  Spacing,
  fontStyle,
  textFont,
  typescale,
  Subtitle,
  honeygold,
  Video,
  Icon,
} from "../index";
import { files } from "../../utils";
import config from "../../configuration";
//import { useSize } from "hooks";

const CardWrapper = styled.section<{
  highlight?: boolean;
  transparent?: boolean;
  grow?: boolean;
  width?: number;
}>`
  display: flex;
  box-sizing: border-box;

  ${({ width }) =>
    width &&
    `max-width: ${width}px; min-width: ${width}px; flex-shrink: 0; flex-grow: 0;`}

  ${({ highlight }) => highlight && `border-left: 4px solid ${honeygold};`}
  ${({ transparent }) =>
    transparent &&
    `background: none; color: ${neutral[700]};
    
    
    
    `}

  margin-bottom: ${Spacing.Large}px;
  flex-direction: column;

  .card-title {
    // width: 150px;
    // max-width: 200px;
    flex-shrink: 0;
  }
  .card-children {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  ${({ grow }) => grow && `flex-grow: 1; `}
`;

const CardInner = styled.div<{ lowy?: boolean }>`
  ${({ lowy }) =>
    lowy
      ? `background: ${neutral[300]}; p, ol, ul { color: ${neutral[700]} !important;}`
      : `background: ${neutral[100]}; border: 1px solid ${neutral[300]};`}

  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border-radius: ${BorderRadius.Default}px;

  table {
    box-shadow: none;
  }
`;

const HeaderWrapper = styled.div<{ hasNoPad?: boolean }>`
  padding: ${Spacing.Large}px;
  border-bottom: 1px solid ${neutral[300]};
  display: flex;

  ${({ hasNoPad }) =>
    hasNoPad &&
    `
        padding: 0;
       
    `}

  .right {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
`;

const BodyWrapper = styled.div<{ hasNoPad?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${Spacing.Large}px;

  ${({ hasNoPad }) =>
    hasNoPad &&
    `
        padding: 0;

        .empty-message {
          padding: ${Spacing.Large}px;
        }

        .table {
          margin-bottom: 0;
          border: 0;
        }
    `}

  > p,
 > ol,
 > ul {
    ${fontStyle(textFont.roman, typescale.paragraph, neutral[600])}
    margin-top: 0 !important;
    margin-bottom: ${typescale.paragraph.lineHeight}rem !important;

    &:last-child {
      margin-bottom: 0 !important;
    }

    a {
      color: ${honeygold};
      font-weight: bold;

      &:hover {
        color: ${neutral[700]};
      }
    }
  }

  ul {
    list-style-type: square;
    margin-left: ${Spacing.Medium}px;
  }

  ol,
  ul {
  }
`;

const FooterWrapper = styled.div<{ hasNoPad?: boolean }>`
  padding: ${Spacing.Medium}px;
  border-top: 1px solid ${neutral[300]};

  ${({ hasNoPad }) =>
    hasNoPad &&
    `
        padding: 0;
    `}
`;

const MediaWrapper = styled.div<{ hasNoPad?: boolean; hasOnClick: boolean }>`
  padding: ${Spacing.Large}px;
  background: black;
  position: relative;

  ${({ hasNoPad }) =>
    hasNoPad &&
    `
        padding: 0;
    `}

  ${({ hasOnClick }) =>
    hasOnClick &&
    `&:hover {
        cursor: pointer;
      }
    `}


    .card-media-inner {
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img,
    video {
      position: relative;
      display: block;
      max-width: 100%;
      max-height: 100%;
      transition: all ease 250ms;
    }

    video {
      width: 100%;
    }
  }

  .card-media-move {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 500;
    display: flex;
    align-items: center;

    button {
      border: 0;
      background: rgba(0, 0, 0, 0.8);
      padding: ${Spacing.Small}px ${Spacing.Large}px;
      transition: all ease 200ms;
      i {
        font-size: 30px;
      }

      &:hover {
        cursor: pointer;
        i {
          color: ${honeygold};
        }
      }
    }
  }

  .card-media-previous {
    left: 0;
  }

  .card-media-next {
    right: 0;
  }
`;

export interface ICardProps {
  className?: string;
  children:
    | (
        | React.ReactElement<ICardHeaderProps>
        | React.ReactElement<ICardFooterProps>
        | React.ReactElement<ICardBodyProps>
        | React.ReactElement<ICardImageProps>
      )[]
    | React.ReactElement<ICardBodyProps>;
  onClick?(): void;
  title?: string | JSX.Element;
  grow?: boolean;
  highlight?: boolean;
  transparent?: boolean;
  width?: number;
  draggable?: boolean;
  onDragOver?: (ev: React.DragEvent<HTMLElement>) => void;
  onDragStart?: (ev: React.DragEvent<HTMLElement>) => void;
  onDrop?: (ev: React.DragEvent<HTMLElement>) => void;
  id?: string;
  low?: boolean;
  style?: object;
}

export interface ICardHeaderProps {
  children: any | React.ReactElement | React.ReactElement[];
  noPad?: boolean;
  className?: string;
}
export interface ICardFooterProps {
  children: React.ReactNode | React.ReactNode[];
  noPad?: boolean;
  className?: string;
}
export interface ICardBodyProps {
  children: React.ReactNode | React.ReactNode[];
  noPad?: boolean;
  className?: string;
}

export interface ICardImageProps {
  children?: React.ReactNode | React.ReactNode[];
  noPad?: boolean;
  onClick?(index?: number): void;
  media?: any;
  className?: string;
}

const Card: React.FC<ICardProps> & {
  Header: typeof CardHeader;
  Footer: typeof CardFooter;
  Body: typeof CardBody;
  Media: typeof CardMedia;
} = ({
  id,
  className,
  children,
  onClick,
  title,
  grow,
  highlight,
  transparent,
  width,
  draggable,
  onDragOver,
  onDragStart,
  onDrop,
  low,
  style,
}) => {
  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onClick?.();
  };

  return (
    <CardWrapper
      id={id}
      className={`card ${className ? className : ""}`}
      onClick={handleOnClick}
      highlight={highlight}
      transparent={transparent}
      grow={grow}
      width={width}
      draggable={draggable}
      onDragOver={onDragOver}
      onDragStart={onDragStart}
      onDrop={onDrop}
      style={style}
    >
      {title && <Subtitle text={title} />}
      <CardInner lowy={low}>
        <div className="card-children">{children}</div>
      </CardInner>
    </CardWrapper>
  );
};

export const CardHeader: React.FC<ICardHeaderProps> = ({
  children,
  className,
  noPad,
}) => {
  return (
    <HeaderWrapper
      className={`card-header ${className ? className : ""}`}
      hasNoPad={noPad}
    >
      {children}
    </HeaderWrapper>
  );
};

export const CardFooter: React.FC<ICardFooterProps> = ({
  children,
  className,
  noPad,
}) => {
  return (
    <FooterWrapper
      className={`card-footer ${className ? className : ""}`}
      hasNoPad={noPad}
    >
      {children}
    </FooterWrapper>
  );
};

export const CardBody: React.FC<ICardBodyProps> = ({
  children,
  className,
  noPad,
}) => {
  return (
    <BodyWrapper
      className={`card-body ${className ? className : ""}`}
      hasNoPad={noPad}
    >
      {children}
    </BodyWrapper>
  );
};

export const CardMedia: React.FC<ICardImageProps> = ({
  children,
  noPad,
  media,
  className,
  onClick,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const isVideo = (x: any) => {
    return files.isVideoMimeType(x.mimeType);
  };

  const handleClick = () => {
    onClick?.(selectedIndex);
  };

  const handleNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (selectedIndex < media.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const handlePrevious = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const target = React.useRef(null);
  //const size = useSize(target);

  return (
    <>
      <MediaWrapper
        className={`card-media ${className ? className : ""}`}
        hasNoPad={noPad}
        hasOnClick={onClick != null}
        ref={target}
      >
        {children ? (
          children
        ) : (
          <>
            {selectedIndex > 0 && (
              <div className="card-media-previous card-media-move">
                <button onClick={e => handlePrevious(e)}>
                  <Icon value="angle-left" />
                </button>
              </div>
            )}
            <div className="card-media-inner">
              {isVideo(media[selectedIndex]) ? (
                <Video
                  src={`${config.baseApiUrl(true)}files/stream/${
                    media[selectedIndex].id
                  }`}
                />
              ) : (
                // <LazyLoad>
                <img
                  alt=""
                  onClick={handleClick}
                  src={`${config.baseApiUrl(true)}files/images/${
                    media[selectedIndex].id
                  }`}
                />
                // </LazyLoad>
              )}
            </div>
            <div className="card-media-next card-media-move">
              {selectedIndex < media.length - 1 && (
                <button onClick={e => handleNext(e)}>
                  <Icon value="angle-right" />
                </button>
              )}
            </div>
          </>
        )}
      </MediaWrapper>
    </>
  );
};

Card.Header = CardHeader;
Card.Footer = CardFooter;
Card.Body = CardBody;
Card.Media = CardMedia;

export default Card;
