import React, { useEffect, useState } from "react";
import { Modal, Spacing, Left, Right, Size } from "ui-kit";
import { Avatar, SchoolIcon } from "sharedComponents/common";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  > .left {
    flex-direction: column;
    display: flex;
    width: 200px;
    position: fixed;
    align-items: center;
    justify-content: center;
  }

  > .right {
    flex-grow: 1;
    margin-left: 200px;
  }
`;

interface IStaffModalProps {
  staffMember: any;
  title?: string;
  onClose?: () => void;
  open?: boolean;
  children?: any;
  width?: string;
  height?: string;
  footer?: JSX.Element;
  loading?: boolean;
  empty?: boolean;
  emptyMessage?: React.ReactElement;
  error?: string;
  errorMessage?: React.ReactElement;
  scrollOnErrors?: string[];
}

const StaffModal: React.FC<IStaffModalProps> = ({
  staffMember,
  title,
  onClose,
  open,
  children,
  width,
  height,
  footer,
  loading,
  empty,
  emptyMessage,
  error,
  errorMessage,
  scrollOnErrors
}) => {
  const [_staffMember, _setStaffMember] = useState<any>(staffMember);

  useEffect(() => {
    staffMember && _setStaffMember(staffMember);
  }, [staffMember]);

  return (
    <Modal
      title={title}
      onClose={onClose}
      open={open}
      width={width}
      height={height}
      loading={loading}
      empty={empty}
      emptyMessage={emptyMessage}
      error={error}
      errorMessage={errorMessage}
    >
      <Modal.Header></Modal.Header>
      <Modal.Body scrollOnErrors={scrollOnErrors}>
        <Wrapper>
          <Left>
            <Avatar
              portrait
              user={_staffMember}
              sub={_staffMember?.initials}
              size={Size.ExtraLarge}
            />
            <SchoolIcon code={_staffMember?.school?.code} size={Size.Small} />
          </Left>
          <Right>{children}</Right>
        </Wrapper>
      </Modal.Body>

      {footer ? <Modal.Footer>{footer}</Modal.Footer> : <></>}
    </Modal>
  );
};

export default StaffModal;
