import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import config from "configuration";
import HolidayCard from "../holidayCard";
import { Size, Loader, Title, TitleSize } from "ui-kit";
import userManagementActions from "areas/administration/actions/userManagementActions";
import leaveActions from "areas/humanResources/actions/leaveActions";
import Subheader from "sharedComponents/layout/header/subheader";
import { RootState } from "reducers/store";
import { ManagedUserDetailView } from "types/users/userListViews.types";

const StaffMember = () => {
  const { staffId } = useParams();

  const { holidayCard, loading, error } = useSelector(
    (state: RootState) => state.staffMemberHolidayCard
  );
  const { user, status, actions } = useSelector(
    (state: RootState) => state.managedUser
  );

  useEffect(() => {
    userManagementActions.getUser(staffId, (data: ManagedUserDetailView) => {
      leaveActions.getHolidayCardForStaffMember(
        staffId,
        config.academicYear.current
      );
    });
  }, [staffId]);

  const handleAcademicYearChanged = (academicYearId: number) => {
    leaveActions.getHolidayCardForStaffMember(staffId, academicYearId);
  };

  return (
    <>
      {!user ? (
        <Loader size={Size.Medium} cover />
      ) : (
        <>
          <Subheader>
            <Title
              size={TitleSize.H2}
              text="Holiday & Leave"
              sub={user && `${user.firstName} ${user.lastName}`}
            />
          </Subheader>

          <HolidayCard
            loading={loading}
            error={error}
            holidayCard={holidayCard}
            staffMember={user}
            onAcademicYearChanged={handleAcademicYearChanged}
          />
        </>
      )}
    </>
  );
};

export default StaffMember;
