import React from "react";
import { Chip, Swatches } from "ui-kit";
import { Constants } from "configuration";
import { InterventionProgressRating } from "areas/planner/types/interventions/interventionShared";


interface IInterventionProgressProps {
  rating: InterventionProgressRating;
  fluid?: boolean;
}


const InterventionProgress: React.FC<IInterventionProgressProps> = ({ rating, fluid }) => {

  var swatch = null;
  var name = "";

  switch (rating) {
    case InterventionProgressRating.Above:
      swatch = Swatches.Blue;
      name = InterventionProgressRating[InterventionProgressRating.Above];
      break;
    case InterventionProgressRating.Expected:
      swatch = Swatches.Success;
      name = InterventionProgressRating[InterventionProgressRating.Expected];
      break;
    case InterventionProgressRating.Below:
      swatch = Swatches.Danger;
      name = InterventionProgressRating[InterventionProgressRating.Below];
      break;
    case InterventionProgressRating.NotSet:
      swatch = Swatches.Low;
      name = "Not Set";
      break;
    default:
      break;
  }

  return <Chip text={name} colorSwatch={swatch} fluid={fluid} />;
};

export default InterventionProgress;
