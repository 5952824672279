import paymentActions from "areas/payments/actions/paymentsActions";
import { UserProductOrderView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface IUserPurchasedItemsState {
  items: UserProductOrderView[],
  error: string | null;
  loading: boolean;
  collecting: boolean;
  collectError: string | null;
}

const INITIAL_STATE: IUserPurchasedItemsState = {
  items: [],
  error: null,
  loading: false,
  collecting: false,
  collectError: null
};


const userPurchasedItemsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUserPurchasedItemsState => {

  const {
    PAYMENTS_GETPURCHASEDITEMSFORUSER,
    PAYMENTS_MARKORDERLINECOLLECTED
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETPURCHASEDITEMSFORUSER.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETPURCHASEDITEMSFORUSER.SUCCESS:
      return { 
        ...state, 
        items: action.payload, 
        loading: false 
      };

    case PAYMENTS_GETPURCHASEDITEMSFORUSER.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PAYMENTS_MARKORDERLINECOLLECTED.START:
      return { 
        ...state, 
        collecting: true, 
        collectError: null 
      };

    case PAYMENTS_MARKORDERLINECOLLECTED.SUCCESS:
      return {
        ...state,
        collecting: false,
        collectError: null,
        items: state.items.map(item =>
          item.id === action.payload.id
            ? { ...item, ...action.payload, isCollectable: false }
            : item
        )
      };

    case PAYMENTS_MARKORDERLINECOLLECTED.FAILED:
      return { 
        ...state, 
        collecting: false, 
        collectError: action.payload 
      };

    default:
      return state;
  }
};

export default userPurchasedItemsReducer;
