
export enum DetentionOption {
  No = 0,
  Yes = 1,
  Optional = 2
}

export enum DetentionPeriod {
  MorningDetention = 0,
  LunchTimeDetention = 1,
  AfterSchoolDetention = 2,
  IE = 3
}

export enum BehaviourOutcome {
  Resolved = 0,
  Unresolved = 1,
  InterventionRequired = 2,
  Review1Week = 3,
  Review2Weeks = 4,
  Review3Weeks = 5,
  Review6Weeks = 6
}

export enum DetentionCancelNotification {
  Send = 0,
  NotSend = 1
}

export enum DetentionStatus {
  Issued = 0,
  Attended = 1,
  Missed = 2,
  Cancelled = 3,
  Rescheduled = 4
}