import paymentActions from "areas/payments/actions/paymentsActions";
import { GroupedTransactionListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface ITransactionsState {
  transactions: GroupedTransactionListView | null;
  error: string | null;
  loading: boolean;
}

const INITIAL_STATE: ITransactionsState = {
  transactions: null,
  error: null,
  loading: false
};


const transactionsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ITransactionsState => {

  const { 
    PAYMENTS_GETTRANSACTIONS 
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETTRANSACTIONS.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETTRANSACTIONS.SUCCESS:
      return {
        ...state,
        transactions: action.payload,
        loading: false
      };

    case PAYMENTS_GETTRANSACTIONS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default transactionsReducer;
