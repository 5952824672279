import React, { useRef } from "react";
import styled from "styled-components";
import Icon from "./icon";
import { BorderRadius, Size, Spacing } from "../common/size";
import { blue, neutral } from "../common/colors";
import { fontStyle, displayFont, typescale } from "../common/typography";
import Tippy from "@tippyjs/react";
import Tooltip from "./tooltip";

export interface IStatsProps {
  items?: IStatsItemProps[];
  className?: string;
  column?: boolean;
  background?: boolean;
}

export interface IStatsItemProps {
  title?: string;
  value?: string;
  icon: string;
  className?: string;
  tooltip?: string;
  tooltipSub?: any;
  color?: string;
}

const Wrapper = styled.div<{ column?: boolean; background: boolean }>`
  margin-bottom: ${Spacing.Large}px;
  padding: ${Spacing.Large}px ${Spacing.Medium}px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: ${BorderRadius.Default}px;

  ${({ background }) =>
    background &&
    `
    background: ${neutral[300]};
  `}

  ${({ column }) =>
    column &&
    `
    flex-direction: column;
    align-items: center;

    .stat-item {
      margin-bottom: ${Spacing.Large}px;
      .stat-value {
        margin-top: ${Spacing.Small}px;
      }
    }
  `}
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${Spacing.Small}px;
  margin-right: ${Spacing.Medium}px;

  &:last-child {
    margin-right: 0;
  }

  .stat-value {
    ${fontStyle(displayFont.light, typescale.header2, neutral[600])};
    margin-top: ${Spacing.Medium}px;
  }

  .stat-title {
    ${fontStyle(displayFont.medium, typescale.paragraph, blue)};
  }
`;

const Stats: React.FC<IStatsProps> & {
  Item: typeof StatsItem;
} = ({ className, items, column, background }) => {
  return (
    <Wrapper
      className={`stats ${className ? className : ""}`}
      column={column}
      background={background}
    >
      {items?.map((item, index) => (
        <Stats.Item
          key={index}
          icon={item.icon}
          title={item.title}
          value={item.value}
          tooltip={item.tooltip}
          tooltipSub={item.tooltipSub}
          color={item.color ? item.color : !background ? neutral[500] : null}
        />
      ))}
    </Wrapper>
  );
};

const StatsItem: React.FC<IStatsItemProps> = ({
  title,
  value,
  icon,
  tooltip,
  tooltipSub,
  color,
}) => {
  const ref = useRef() as React.RefObject<HTMLDivElement>;
  return (
    <>
      <ItemWrapper className="stat-item" ref={ref}>
        <Icon
          value={icon}
          size={Size.Large}
          color={color ? color : neutral[100]}
        />
        <div className="stat-value">{value}</div>
        <div className="stat-title">{title}</div>
      </ItemWrapper>
      {tooltip != null && (
        <Tippy
          reference={ref}
          content={<Tooltip title={tooltip} sub={tooltipSub} />}
          placement="bottom"
        />
      )}
    </>
  );
};

Stats.Item = StatsItem;

export default Stats;
