import React, { useState } from "react";
import {
  EmptyMessage,
  Table,
  Size,
  Swatches,
  Button,
  Chip,
  Icon,
  Spacing,
  SplitButton,
} from "ui-kit";
import { Avatar } from "sharedComponents/common";
import { arrays } from "utils";
import { Constants } from "configuration";
import styled from "styled-components";
import AccountModal from "./accountModal";
import AdjustBalanceModal from "./adjustBalanceModal";
import { LunchRecordListView } from "areas/payments/types/catering/cateringResponses.types";


const LunchOption = styled.div`
  font-weight: bold;
  margin-left: ${Spacing.Small}px;
  font-size: 16px;
`;


interface ILunchRecordsTableProps {
  records: LunchRecordListView[];
  schoolId: number;
  loading?: boolean;
  error?: boolean;
  tutorGroupId?: number;
  downloading?: boolean;
  onDownload?: () => void;
  onTakeRegister?: () => void;
  showTakeRegisterButton?: boolean;
}


const LunchRecordsTable: React.FC<ILunchRecordsTableProps> = ({
  records,
  schoolId,
  loading,
  error,
  tutorGroupId,
  downloading,
  onDownload,
  onTakeRegister,
  showTakeRegisterButton,
}) => {
  const [selectedRecord, setSelectedRecord] = useState<LunchRecordListView | null>(null);
  const [adjustmentModalOpen, setAdjustmentModalOpen] = useState<boolean>(false);
  const [accountModalOpen, setAccountModalOpen] = useState<boolean>(false);

  const handleTakeRegister = () => {
    onTakeRegister?.();
  };

  const getLunchTypeTotal = (lunchType: number) => {
    return records?.reduce(function (acc: number, obj: LunchRecordListView) {
      return acc + (obj.lunchType === lunchType ? 1 : 0);
    }, 0);
  };

  const getTick = (record: LunchRecordListView, lunchType: number) => {
    if (record.lunchType === lunchType) {
      return record.lunchType <= 1 ? (
        <Icon
          value="check-circle"
          size={Size.Large}
          color={Swatches.Success.swatch}
        />
      ) : (
        <Icon
          value="times-circle"
          size={Size.Large}
          color={Swatches.Default.swatch}
        />
      );
    }
    return null;
  };

  const Balance = ({ balance }: { balance: number }) => {
    return (
      <Chip
        fluid
        colorSwatch={
          balance === 0
            ? Swatches.Low
            : balance > 0
            ? Swatches.Success
            : Swatches.Danger
        }
        text={balance.toString()}
      />
    );
  };

  const handleAccountModalOpen = (record: LunchRecordListView) => {
    setAccountModalOpen(true);
    setSelectedRecord(record);
  };

  const handleAdjustBalanceModalOpen = (record: LunchRecordListView) => {
    setAdjustmentModalOpen(true);
    setSelectedRecord(record);
  };
  const handleAccountModalClose = () => {
    setAccountModalOpen(false);
    setSelectedRecord(null);
  };

  const handleAdjustmentModalClose = () => {
    setAdjustmentModalOpen(false);
    setSelectedRecord(null);
  };

  const handleAdjustmentModalSaved = (newBalance: number) => {
    setAdjustmentModalOpen(false);
    setSelectedRecord(null);
  };

  return (
    <>
      <Table
        grow
        zebra
        loading={loading}
        empty={arrays.isEmpty(records)}
        emptyMessage={
          <EmptyMessage
            icon="utensils"
            title="No Lunch Records"
            summary="No lunch records were found for this selection"
            cover
          >
            {tutorGroupId && (
              <Button
                text={`Take Lunch Register`}
                color={Swatches.Primary}
                size={Size.Small}
                onClick={handleTakeRegister}
              />
            )}
          </EmptyMessage>
        }
        error={error}
      >
        <Table.Header>
          <Table.HeaderCell width={3.6}>Pupil</Table.HeaderCell>
          <Table.HeaderCell width={0.4} center>
            DI
          </Table.HeaderCell>
          <Table.HeaderCell width={0.8} center>
            {Constants.LUNCH_TYPES.SCHOOL_MEAL.name}
          </Table.HeaderCell>
          <Table.HeaderCell width={0.8} center>
            {Constants.LUNCH_TYPES.PACKED_LUNCH.name}
          </Table.HeaderCell>
          <Table.HeaderCell width={0.8} center>
            {Constants.LUNCH_TYPES.HOME.name}
          </Table.HeaderCell>
          <Table.HeaderCell width={0.8} center>
            None
          </Table.HeaderCell>
          <Table.HeaderCell width={0.8} center>
            {Constants.LUNCH_TYPES.ABSENT.name}
          </Table.HeaderCell>
          <Table.HeaderCell width={0.8} center>
            Credit Balance
          </Table.HeaderCell>
          <Table.HeaderCell width={1.2}></Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {records?.map((record: LunchRecordListView, index: number) => (
            <Table.Row key={index}>
              <Table.Cell width={3.6}>
                <Avatar
                  user={record.user}
                  sub={`${record.yearGroup?.name} - ${record.tutorGroup?.name}`}
                  chip={
                    record.user.freeSchoolMeal ? (
                      <Chip
                        text="Free School Meal"
                        colorSwatch={Swatches.Miami}
                      />
                    ) : null
                  }
                />
              </Table.Cell>
              <Table.Cell width={0.4} center>
                {record.dietaryInformation && (
                  <Icon
                    value="exclamation-triangle"
                    size={Size.Large}
                    color={Swatches.Warning.swatch}
                    tooltip="Dietary Information"
                    tooltipSub={record.dietaryInformation}
                  />
                )}
              </Table.Cell>
              <Table.Cell width={0.8} center zebra>
                {getTick(record, Constants.LUNCH_TYPES.SCHOOL_MEAL.value)}
                {record.lunchType === 0 && (
                  <LunchOption> {record.lunchOption}</LunchOption>
                )}
              </Table.Cell>
              <Table.Cell width={0.8} center zebra>
                {getTick(record, Constants.LUNCH_TYPES.PACKED_LUNCH.value)}
              </Table.Cell>
              <Table.Cell width={0.8} center zebra>
                {getTick(record, Constants.LUNCH_TYPES.HOME.value)}
              </Table.Cell>
              <Table.Cell width={0.8} center zebra>
                {getTick(record, Constants.LUNCH_TYPES.FASTING.value)}
              </Table.Cell>
              <Table.Cell width={0.8} center zebra>
                {getTick(record, Constants.LUNCH_TYPES.ABSENT.value)}
              </Table.Cell>
              <Table.Cell width={0.8} right zebra>
                <Balance balance={record.balance} />
              </Table.Cell>
              <Table.Cell width={1.2} right>
                <SplitButton
                  size={Size.Small}
                  text="View"
                  color={Swatches.Default}
                  onDefaultClick={() => {
                    handleAccountModalOpen(record);
                  }}
                >
                  <SplitButton.Option
                    onClick={() => {
                      handleAccountModalOpen(record);
                    }}
                    text="View"
                    show={true}
                  />
                  <SplitButton.Option
                    onClick={() => handleAdjustBalanceModalOpen(record)}
                    text="Adjust Balance"
                    show={true}
                  />
                </SplitButton>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.Cell width={2}>
              <Button
                text="Download Register"
                working={downloading}
                onClick={onDownload}
                color={Swatches.Default}
                fluid
                size={Size.Small}
              />
            </Table.Cell>
            <Table.Cell width={2} center />

            <Table.Cell width={0.8} center>
              <b>
                {getLunchTypeTotal(Constants.LUNCH_TYPES.SCHOOL_MEAL.value)}
              </b>
            </Table.Cell>
            <Table.Cell width={0.8} center>
              <b>
                {getLunchTypeTotal(Constants.LUNCH_TYPES.PACKED_LUNCH.value)}
              </b>
            </Table.Cell>
            <Table.Cell width={0.8} center>
              <b>{getLunchTypeTotal(Constants.LUNCH_TYPES.HOME.value)}</b>
            </Table.Cell>
            <Table.Cell width={0.8} center>
              <b>{getLunchTypeTotal(Constants.LUNCH_TYPES.FASTING.value)}</b>
            </Table.Cell>
            <Table.Cell width={0.8} center>
              <b>{getLunchTypeTotal(Constants.LUNCH_TYPES.ABSENT.value)}</b>
            </Table.Cell>
            <Table.Cell width={2} colspan={2}>
              <Button
                text="Edit Register"
                onClick={handleTakeRegister}
                color={Swatches.Primary}
                fluid
                size={Size.Small}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <AdjustBalanceModal
        account={selectedRecord}
        schoolId={schoolId}
        open={adjustmentModalOpen && selectedRecord != null}
        onClose={handleAdjustmentModalClose}
        onSave={handleAdjustmentModalSaved}
      />
      <AccountModal
        account={selectedRecord}
        schoolId={schoolId}
        open={accountModalOpen && selectedRecord != null}
        onClose={handleAccountModalClose}
      />
    </>
  );
};

export default LunchRecordsTable;
