import allBehaviourCategoriesReducer from "./allBehaviourCategoriesReducer";
import allBehaviourCodesReducer from "./allBehaviourCodesReducer";
import behaviourCategoriesReducer from "./behaviourCategoriesReducer";
import behaviourCodesReducer from "./behaviourCodesReducer";
import behaviourForMultipleStudentsReducer from "./behaviourForMultipleStudentsReducer";
import behaviourReducer from "./behaviourReducer";
import behaviourUpscaleCategoriesReducer from "./behaviourUpscaleCategoriesReducer";
import defaultDetentionReducer from "./defaultDetentionReducer";
import initialBehaviourActionsReducer from "./initialBehaviourActionsReducer";
import pendingAttentionBehaviourReducer from "./pendingAttentionBehaviourReducer";
import pendingAttentionBehavioursReducer from "./pendingAttentionBehavioursReducer";
import upscaleBehaviourReducer from "./upscaleBehaviourReducer";

const behaviourReducers = {
  allBehaviourCategories: allBehaviourCategoriesReducer,
  allBehaviourCodes: allBehaviourCodesReducer,
  behaviourCategories: behaviourCategoriesReducer,
  behaviourCodes: behaviourCodesReducer,
  behaviourForMultipleStudents: behaviourForMultipleStudentsReducer,
  behaviour: behaviourReducer,
  behaviourUpscaleCategories: behaviourUpscaleCategoriesReducer,
  defaultDetention: defaultDetentionReducer,
  initialBehaviourActions: initialBehaviourActionsReducer,
  pendingAttentionBehaviour: pendingAttentionBehaviourReducer,
  pendingAttentionBehaviours: pendingAttentionBehavioursReducer,
  upscaleBehaviour: upscaleBehaviourReducer,
};

export default behaviourReducers;
