import schoolPeriodsReducer from "./schoolPeriodsReducer";
import schoolReducer from "./schoolReducer";
import schoolRoomsReducer from "./schoolRoomsReducer";

const schoolReducers = {
  school: schoolReducer,
  schoolRooms: schoolRoomsReducer,
  schoolPeriods: schoolPeriodsReducer,
};

export default schoolReducers;
