import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import SmartTaskConditions from "./smartTaskConditions";
import { TIME_PERIOD_KEYS } from "../../constants/smartTasks";
import { useAppSelector } from "reducers/hooks";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { smartTaskData } from "./newSmartTaskFormWrapper";
import calendarActions from "areas/calendar/actions/calendarActions";


interface ISmartTaskConditionsWrapperProps {
  smartTask: smartTaskData;
  setSmartTask: (data: smartTaskData) => void;
  schoolId: number;
  disabled?: boolean;
}


const SmartTaskConditionsWrapper: React.FC<ISmartTaskConditionsWrapperProps> = ({
  smartTask,
  setSmartTask,
  schoolId,
  disabled,
}) => {

  const { rewardCategoryGroups } = useAppSelector(state => state.rewardCategories);
  const { categories: eventCategories } = useAppSelector(state => state.calendarCategories);

  const [schoolRewardCategories, setSchoolRewardCategories] = useState<IDropdownDataItemProps<string, number, null>[]>([]);
  const [calendarEventCategories, setCalendarEventCategories] = useState<IDropdownDataItemProps<string, number, null>[]>([]);

  useEffect(() => {
    if (rewardCategoryGroups.length > 0) {
      var categories: IDropdownDataItemProps<string, number, null>[] = [];

      for (var i = 0; i < rewardCategoryGroups.length; i++) {
        var rewards = rewardCategoryGroups[i].behaviourCategories.map(
          category => ({
            key: category.id,
            label: `${category.codeDisplayName} - ${category.behaviourCategoryName}`,
            value: category.id,
          })
        );
        categories = categories.concat(rewards);
      }

      setSchoolRewardCategories(categories);
    }
  }, [rewardCategoryGroups]);

  useEffect(() => {
    if (eventCategories.length > 0) {
      var categories: IDropdownDataItemProps<string, number, null>[] = eventCategories.map(category => ({
        key: category.id,
        label: category.name,
        value: category.id,
      }));
      setCalendarEventCategories(categories);
    }
    else {
      calendarActions.getCalendarCategories();
    }
  }, [eventCategories])


  const handleTimePeriodChange = (value: number) => {
    if (value === TIME_PERIOD_KEYS.ACADEMIC_YEAR) {
      setSmartTask({
        ...smartTask,
        timePeriodNameId: value,
        timePeriodFrequency: 1,
      });
    } else {
      setSmartTask({
        ...smartTask,
        timePeriodNameId: value,
      });
    }
  };

  return (
    <SmartTaskConditions
      smartTask={smartTask}
      setSmartTask={setSmartTask}
      rewardCategories={schoolRewardCategories}
      calendarEventCategories={calendarEventCategories}
      schoolId={schoolId}
      handleTimePeriodChange={handleTimePeriodChange}
      disabled={disabled}
    />
  );
};


export default SmartTaskConditionsWrapper;
