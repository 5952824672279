import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { users } from "utils";
import { Constants } from "configuration";
import {
  EmptyMessage,
  Swatches,
  Size,
  Loader,
  Table,
  Chip,
  SplitButton,
  fontStyle,
  displayFont,
  typescale,
  Spacing,
  Button,
  ButtonBar,
  neutral,
  ToastService,
} from "ui-kit";
import { arrays } from "utils";
import { useState } from "react";
import { matriculationRoutes } from "../matriculationRoutes";
import matriculationActions from "../actions/matriculationActions";
import AlertModal from "ui-kit/modules/alertModal";
import { MatriculationSessionListView } from "../types/matriculationResponses.types";
import { RootState } from "reducers/store";
import { ChangeMatriculationSessionStatusCommand } from "../types/matriculationRequests.types";
import OkayModal from "ui-kit/modules/okayModal";


const StyledLink = styled.a`
  ${fontStyle(displayFont.roman, typescale.header4, Swatches.Primary.swatch)}
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  background: ${neutral[300]};
  padding: ${Spacing.Default}px 0;
  align-items: center;
  justify-content: center;

  .icon {
    align-self: center;
    margin-right: ${Spacing.Default}px;
  }

  .title {
    ${fontStyle(displayFont.medium, typescale.header2, neutral[100])}
    align-self: center;
    margin: 0;
  }
`;

const ButtonBarWrapper = styled.div`
  .button-bar {
    justify-content: center;
    width: 100%;
  }
`;


interface ISessionsTableProps {
  sessions: MatriculationSessionListView[];
  loading?: boolean;
  error?: string;
}


const SessionsTable: React.FC<ISessionsTableProps> = ({ sessions, loading, error }) => {

  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const [sessionToEdit, setSessionToEdit] = useState<MatriculationSessionListView | null>(null);

  const handleViewStudentsClicked = (session: MatriculationSessionListView) => {
    navigate(
      matriculationRoutes.getStudentSessionsPath(session.schoolId, session.id)
    );
  };

  const handleViewPreadmissionsClicked = (session: MatriculationSessionListView) => {
    navigate(
      matriculationRoutes.getPreadmissionsPath(session.schoolId, session.id)
    );
  };

  const handleEditSessionClicked = (session: MatriculationSessionListView) => {
    setSessionToEdit(session);
  };

  const handleStatusChange = () => {
    if (!sessionToEdit) {
      return;
    }
    var data: ChangeMatriculationSessionStatusCommand = {
      schoolId: sessionToEdit.schoolId,
      sessionId: sessionToEdit.id,
      isActive: !sessionToEdit.isActive
    }
    matriculationActions.changeSessionStatus(
      sessionToEdit.schoolId,
      sessionToEdit.id,
      data,
      () => ToastService.pop("Session status changed", null, "check-circle")
    );
    setSessionToEdit(null);
  };

  if (loading && arrays.isEmpty(sessions)) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem while loading the matriculation sessions"
        icon="times-circle"
        iconColor={Swatches.Danger.swatch}
        cover
      />
    );
  }

  return (
    <>
      <Table
        zebra
        grow
        loading={loading}
        error={error != null}
        empty={arrays.isEmpty(sessions)}
        emptyMessage={
          <EmptyMessage
            icon="calendar"
            title="No Matriculation Sessions Found"
            cover
          />
        }
      >
        <Table.Header>
          <Table.HeaderCell width={1}>Status</Table.HeaderCell>
          <Table.HeaderCell width={2}>Intake</Table.HeaderCell>
          <Table.HeaderCell width={2}>Students</Table.HeaderCell>
          {users.isInRole(user, Constants.ROLES.IT_ADMINISTRATOR) && (
            <Table.HeaderCell width={2}>Parent Form</Table.HeaderCell>
          )}
          <Table.HeaderCell width={2} right>
            Session Details
          </Table.HeaderCell>
        </Table.Header>

        <Table.Body>
          {sessions?.map((session: MatriculationSessionListView, index: number) => (
            <Table.Row key={index}>
              <Table.Cell width={1}>
                {session.isActive ? (
                  <Chip text="Active" colorSwatch={Swatches.Success} />
                ) : (
                  <Chip text="Inactive" colorSwatch={Swatches.Low} />
                )}
              </Table.Cell>
              <Table.Cell width={2}>{session.academicYear.year}</Table.Cell>
              <Table.Cell width={2}>{session.students}</Table.Cell>
              {users.isInRole(user, Constants.ROLES.IT_ADMINISTRATOR) && (
                <Table.Cell width={2}>
                  {session.isActive && (
                    <StyledLink
                      href={`https://matriculation.lionhearttrust.org.uk/#!/schools/${session.schoolId}/sessions/${session.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Parent Form
                    </StyledLink>
                  )}
                </Table.Cell>
              )}
              <Table.Cell width={2} right>
                <SplitButton
                  size={Size.Small}
                  text="Details"
                  color={Swatches.Default}
                >
                  <SplitButton.Option
                    onClick={() => handleViewStudentsClicked(session)}
                    text="View Students"
                    show
                  />
                  <SplitButton.Option
                    onClick={() => handleViewPreadmissionsClicked(session)}
                    text="View Preadmissions"
                    show={
                      users.isInRole(user, Constants.ROLES.IT_ADMINISTRATOR) ||
                      users.isInRole(user, Constants.ROLES.IT_USER) ||
                      users.isInRole(user, Constants.ROLES.MATRICULATION_ADMINISTRATOR) ||
                      users.isInRole(user, Constants.ROLES.MATRICULATION_USER)
                    }
                  />
                  <SplitButton.Option
                    onClick={() => handleEditSessionClicked(session)}
                    text={
                      session.isActive
                        ? "Make Session Inactive"
                        : "Make Session Active"
                    }
                    show={
                      users.isInRole(
                        user,
                        Constants.ROLES.MATRICULATION_ADMINISTRATOR
                      ) ||
                      users.isInRole(user, Constants.ROLES.IT_ADMINISTRATOR)
                    }
                  />
                </SplitButton>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <OkayModal 
        open={sessionToEdit != null}
        body={
          <p>
          Warning. You are about to set the matriculation session for
          academic year {sessionToEdit?.academicYear?.year} to "
          {sessionToEdit?.isActive ? "inactive" : "active"}".
        </p>
        }
        onCancel={() => setSessionToEdit(null)}
        onConfirm={() => handleStatusChange()}
      />

    </>
  );
};

export default SessionsTable;
