import paymentActions from "areas/payments/actions/paymentsActions";
import { AddProductToBasketCommand } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";
import { BasketDetailView, BasketItemView, OrderLineView, ProductInstalmentView, UserProductOrderView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { useAppSelector } from "reducers/hooks";
import { UserTinyView } from "types/users/userListViews.types";
import { Button, Size, Swatches, ToastService } from "ui-kit";


interface IInstalmentButtonProps {
  user: UserTinyView;
  item: UserProductOrderView | OrderLineView;
  recipient: UserTinyView;
  instalment: ProductInstalmentView;
}


const InstalmentButton: React.FC<IInstalmentButtonProps> = ({ user, item, recipient, instalment }) => {

  const { basket, working } = useAppSelector(state => state.userBasket);

  const addInstalmentToBasket = (recipient: UserTinyView, instalment: ProductInstalmentView) => {
    var data: AddProductToBasketCommand = {
      userId: user.id,
      recipientId: recipient.id,
      productId: item.product.id,
      quantity: 1,
      total: instalment.amount,
      virtualInstalmentId: instalment.virtualInstalmentId,
      orderType: instalment.installmentType
    };
    paymentActions.addProductToBasket(user.id, data, () => {
      ToastService.pop("Item Added to Basket", null, "shopping-bag");
    });
  };

  const removeFromBasket = (basketItem: BasketItemView) => {
    paymentActions.removeItemFromBasket(user.id, basketItem, () => {
      paymentActions.getBasket(user.id);
      ToastService.pop("Item Removed from Basket", null, "shopping-bag");
    });
  };
  
  var basketItem = basket?.items?.find(
    (item) => item.virtualInstalmentId === instalment.virtualInstalmentId
  );

  return !basketItem ? (
    <Button
      size={Size.Medium}
      color={Swatches.Success}
      text="Add"
      working={working}
      onClick={() => addInstalmentToBasket(recipient, instalment)}
    />
  ) : (
    <Button
      size={Size.Medium}
      color={Swatches.Danger}
      text="Remove"
      working={working}
      fluid
      onClick={() => removeFromBasket(basketItem)}
    />
  );
};


export default InstalmentButton;