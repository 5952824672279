import { IBeehiveAction } from "types/common/action.types";
import leaveActions from "../actions/leaveActions";
import { HolidayCardView } from "../types/leaveResponse.types";

interface IUserHolidayCardState {
  holidayCard: HolidayCardView | null,
  loading: boolean;
  error: string | null;
}

const INITIAL_STATE: IUserHolidayCardState = {
  holidayCard: null,
  loading: false,
  error: null,
};

const userHolidayCardReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUserHolidayCardState => {
  const {
    GETHOLIDAYCARDFORUSER,
    APPROVE_LEAVEREQUEST,
    REJECT_LEAVEREQUEST,
    CANCEL_LEAVEREQUEST,
    CONFIRM_LEAVEREQUEST,
    RESUBMIT_LEAVEREQUEST,
    SUBMIT_LEAVEREQUEST,
    HOLIDAYCARD_SAVE,
    HOLIDAYCARD_CREATE,
  } = leaveActions.types;

  switch (action.type) {
    case GETHOLIDAYCARDFORUSER.START:
      return { ...INITIAL_STATE, loading: true };

    case GETHOLIDAYCARDFORUSER.SUCCESS:
      return {
        ...state,
        holidayCard: action.payload,
        loading: false,
      };

    case GETHOLIDAYCARDFORUSER.FAILED:
      return {
        ...INITIAL_STATE,
        error: action.payload,
      };

    case HOLIDAYCARD_CREATE.SUCCESS:
    case HOLIDAYCARD_SAVE.SUCCESS:
      return {
        ...state,
        holidayCard:
          action.payload.id === state.holidayCard?.id
            ? action.payload
            : state.holidayCard,
      };

    case APPROVE_LEAVEREQUEST.SUCCESS:
    case REJECT_LEAVEREQUEST.SUCCESS:
    case CANCEL_LEAVEREQUEST.SUCCESS:
    case CONFIRM_LEAVEREQUEST.SUCCESS:
    case RESUBMIT_LEAVEREQUEST.SUCCESS:
    case SUBMIT_LEAVEREQUEST.SUCCESS:
      return {
        ...state,
        holidayCard:
          action.payload.id === state.holidayCard?.id
            ? action.payload
            : state.holidayCard,
      };
    default:
      return state;
  }
};

export default userHolidayCardReducer;
