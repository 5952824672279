import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "ui-kit";


interface IPointsDropdownProps {
  points: number;
  setPoints: (value: number) => void;
  maxPoints: number;
}


const PointsDropdown: React.FC<IPointsDropdownProps> = ({ points, setPoints, maxPoints }) => {

  const dropdownPointsOptions = [];
  for (let i = 1; i <= maxPoints; i++) {
    dropdownPointsOptions.push({
      label: `Stage ${i.toString()}`,
      value: i
    });
  }

  return (
    <Dropdown
      fluid
      placeholder="Severity"
      items={dropdownPointsOptions}
      onChange={value => setPoints(value)}
      value={points}
    />
  );
};


export default PointsDropdown;
