import React from "react";
import { ReactComponent as DogSvg } from "assets/primary/rockets/dog.svg";
import { IPrimaryRocketCharacterProps } from "./primaryRocket";


export const Dog: React.FC<IPrimaryRocketCharacterProps> = ({ className }) => {

  return <DogSvg className={className} />;
};

export default Dog;
