import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Size,
  Swatches,
  Button,
  EmptyMessage,
  Loader,
  SplitButton,
  ActionBar,
  Left,
  Right,
  Spacing,
  Title,
  TitleSize,
  ToastService,
  Tabs,
} from "ui-kit";
import { arrays, users } from "utils";
import { Constants } from "configuration";
import ProvisionMapDropdown from "./provisionMapDropdown";
import ProvisionMap from "./provisionMap";
import provisionMapActions from "areas/send/actions/provisionMaps/provisionMapActions";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";
import { RootState } from "reducers/store";
import config from "configuration/config";
import { UserTypeView } from "sharedComponents/common";
import styled from "styled-components";
import moment from "moment";
import { SaveProvisionMapCommand } from "areas/send/types/passportRequest.types";
import OkayModal from "ui-kit/modules/okayModal";
import { ProvisionMapStatus } from "areas/send/types/passportShared.types";
import ReviewComments from "./reviewComments";
import constants from "configuration/constants";
import { IAcademicYear } from "configuration/constants.types";


const MapHeader = styled.div`
  margin-bottom: ${Spacing.Large}px;
  display: flex;
  align-items: top;
  justify-content: space-between;
`;

const DownloadWrapper = styled.div`
  //margin-bottom: ${Spacing.Small}px;
  display: flex;
  flex-flow: row nowrap;
  align-items: top;
  justify-content: flex-end;
`;


interface IProvisionMapsProps {
  student: PlannerStudentDetailView;
  readonly?: boolean;
}


const ProvisionMaps: React.FC<IProvisionMapsProps> = ({ student, readonly }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { loading, error, provisionMaps } = useSelector(
    (state: RootState) => state.provisionMaps
  );
  const {
    provisionMap,
    loading: loadingProvisionMap,
    error: loadError,
    downloading
  } = useSelector((state: RootState) => state.provisionMap);

  const [_student, _setStudent] = useState<PlannerStudentDetailView>(student);
  const [addNewMapOpen, setAddNewMapOpen] = useState<boolean>(false);
  const [_showNewButton, _setShowNewButton] = useState<boolean>(false);
  const [_nextYearButton, _setNextYearButton] = useState<IAcademicYear>(null);
  const [_academicYear, _setAcademicYear] = useState<IAcademicYear>(null);

  const userCanEdit =
    users.isInRole(user, Constants.ROLES.SEN_ADMINISTRATOR) ||
    users.isInRole(user, Constants.ROLES.SEN_COORDINATOR) ||
    users.isInRole(user, Constants.ROLES.LEADERSHIP);

  const getMap = (id: number) => {
    provisionMapActions.getProvisionMap(_student.id, id);
  };

  useEffect(() => {
    _setStudent(student);
    if (student) {
      provisionMapActions.getProvisionMapsForStudent(student.id);
    }
  }, [student]);

  useEffect(() => {
    // Get the first provision map
    if (!arrays.isEmpty(provisionMaps) && provisionMaps?.[0].student.id == student.id) {
      getMap(provisionMaps?.[0].id);
    }
  }, [provisionMaps]);

  useEffect(() => {
    _setShowNewButton(false);
    
    if (!provisionMaps.some(map => map.academicYearId == config.academicYear.current)) {
      _setShowNewButton(true);
    }

    if (
      !provisionMaps.some(x => x.academicYearId == config.academicYear.current + 1) && 
      moment().isAfter(moment(config.academicYear.currentEndDate).add(-3, 'months')) && 
      constants.ACADEMIC_YEARS.some(x => x.id == config.academicYear.current + 1)
    ) {
      var nextYear = constants.ACADEMIC_YEARS.find(x => x.id == config.academicYear.current + 1);
      _setNextYearButton(nextYear);
    }

  }, [provisionMaps])

  const handleAddProvisionMap = (academicYear?: IAcademicYear) => {
    _setAcademicYear(
      academicYear != null 
      ? academicYear 
      : {
        id: config.academicYear.current,
        name: config.academicYear.currentName
      }
    );
    setAddNewMapOpen(true);
  };

  const handleNewProvisionMapCancel = () => {
    _setAcademicYear(null);
    setAddNewMapOpen(false);
  };

  const handleNewProvisionMapSaved = () => {
    var errors: string[] = [];

    if (!arrays.isEmpty(errors)) {
      return;
    }

    const saveCommand: SaveProvisionMapCommand = {
      studentId: _student.id,
      status: ProvisionMapStatus.Open,
      academicYearId: _academicYear.id
    }

    provisionMapActions.createProvisionMap(saveCommand, () => {
      ToastService.pop("Provision Map Created", null, "hand-holding-seedling");
    });

    _setAcademicYear(null);
    setAddNewMapOpen(false);
  };

  const handleProvisionMapChanged = (map: number) => {
    getMap(map);
  };

  const handleDownloadProvisionMap = () => {
    provisionMapActions
      .downloadProvisionMap(provisionMap?.student.id, provisionMap?.id);
  };


  if (loading) {
    return <Loader cover size={Size.Medium} />;
  }

  if (error) {
    return (
      <EmptyMessage
        cover
        title="An error occured"
        icon="times-circle"
        summary="There was a problem while loading the provision maps"
      />
    );
  }

  return (
    <>
      {arrays.isEmpty(provisionMaps) ? (
        <EmptyMessage
          cover
          title="No Provision Maps"
          icon="hand-holding-seedling"
          summary="No provision maps have been added for this student"
        >
          {userCanEdit && !readonly && (
            <Button
              text="Add New Provision Map"
              size={Size.Small}
              color={Swatches.Primary}
              onClick={() => handleAddProvisionMap()}
            />
          )}
        </EmptyMessage>
      ) : (
        <>
          <UserTypeView>
            <UserTypeView.Item userType={Constants.USER_TYPES.STAFF}>
              <ActionBar>
                <Left>
                  <ProvisionMapDropdown
                    value={provisionMap?.id}
                    onChange={handleProvisionMapChanged}
                  />
                </Left>
                <Right>
                  <SplitButton
                    text="Download Provision Map"
                    size={Size.Small}
                    color={Swatches.Primary}
                    onDefaultClick={handleDownloadProvisionMap}
                    working={downloading}
                  >
                    <SplitButton.Option
                      text="Download Provision Map"
                      onClick={handleDownloadProvisionMap}
                      show
                    />
                    <SplitButton.Option
                      text="Add New Provision Map"
                      onClick={handleAddProvisionMap}
                      show={_showNewButton && userCanEdit && !readonly}
                    />
                    <SplitButton.Option
                      text={`Add New Provision Map for ${_nextYearButton?.name}`}
                      onClick={() => handleAddProvisionMap(_nextYearButton)}
                      show={_nextYearButton != null && userCanEdit && !readonly}
                    />
                  </SplitButton>
                </Right>
              </ActionBar>
            </UserTypeView.Item>
            <UserTypeView.Item userType={Constants.USER_TYPES.PARENT}>
              <MapHeader>
                <Left>
                  <Title
                    size={TitleSize.H3}
                    text={`Provison Maps`}
                    sub={`${provisionMap ? provisionMap.academicYearName : ""}`}
                  />
                </Left>
                <Right>
                  <Button
                    text="Download Provision Map"
                    color={Swatches.Primary}
                    size={Size.Small}
                    onClick={handleDownloadProvisionMap}
                  />
                </Right>
              </MapHeader>
            </UserTypeView.Item>
          </UserTypeView>

          <Tabs>
            <Tabs.Pane name="provisionMap" label="Provision Map">
              <ProvisionMap
                provisionMap={provisionMap}
                onAdd={handleAddProvisionMap}
                loading={loadingProvisionMap}
                error={loadError}
                readonly={readonly}
              />
            </Tabs.Pane>
            <Tabs.Pane name="provisionComments" label="Comments">
              <ReviewComments 
                provisionMap={provisionMap} 
                loading={loadingProvisionMap}
                error={loadError}
              />
            </Tabs.Pane>
          </Tabs>

        </>
      )}

      <OkayModal 
        open={addNewMapOpen}
        body={<p>This will create a new provision map for the {_academicYear?.name} academic year</p>}
        onCancel={() => handleNewProvisionMapCancel()}
        onConfirm={() => handleNewProvisionMapSaved()}
      />
    </>
  );
};

export default ProvisionMaps;
