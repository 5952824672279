import { OvertimeStatus } from "areas/humanResources/types/leaveShared.types";
import React, { useState, useEffect } from "react";
import { Chip, Swatches } from "ui-kit";



interface IOvertimeStatusChipProps {
  status: OvertimeStatus;
  fluid?: boolean;
}


const OvertimeStatusChip: React.FC<IOvertimeStatusChipProps> = ({ status, fluid }) => {

  var swatch = null;
  var name = "";

  switch (status) {
    case OvertimeStatus.Submitted:
      swatch = Swatches.Low;
      name = "Submitted";
      break;
    case OvertimeStatus.Authorised:
      swatch = Swatches.Success;
      name = "Authorised";
      break;
    case OvertimeStatus.Rejected:
      swatch = Swatches.Warning;
      name = "Rejected";
      break;
    case OvertimeStatus.Cancelled:
      swatch = Swatches.Cancel;
      name = "Cancelled";
      break;
    default:
      return null;
  }

  return <Chip text={name} colorSwatch={swatch} fluid={fluid} />;
}


export default OvertimeStatusChip;