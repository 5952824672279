import React from "react";
import { Size, StructuredList, Label, Currency, Card } from "ui-kit";
import Moment from "react-moment";
import { SchoolAvatar, Avatar } from "sharedComponents/common";
import CreditStatus from "./creditStatus";
import { useNavigate } from "react-router-dom";
import { SmartcardSyncResultListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


interface ICreditDetailsProps {
  credit: SmartcardSyncResultListView;
}

const CreditDetails: React.FC<ICreditDetailsProps> = ({ credit }) => {

  const navigate = useNavigate();

  const handleOrderClicked = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`/main/shopkeeper/users/${credit.user.id}/orders/${credit.orderLine.orderId}`);
  };

  return (
    credit && (
      <Card>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="User">
              <Avatar user={credit.user} size={Size.Small} />
            </StructuredList.Item>

            <StructuredList.Item name="School">
              <SchoolAvatar school={credit.school} />
            </StructuredList.Item>

            <StructuredList.Item name="SIMS ID">
              <Label>{credit.user.simsId}</Label>
            </StructuredList.Item>

            <StructuredList.Item name="Status">
              <CreditStatus status={credit.status} />
            </StructuredList.Item>

            <StructuredList.Item name="Sync Date">
              <Label>
                <Moment
                  date={credit.syncProcessedDate}
                  format="DD/MM/YYYY HH:mm"
                />
              </Label>
            </StructuredList.Item>

            <StructuredList.Item name="Total">
              <Label>
                <Currency value={credit.total} />
              </Label>
            </StructuredList.Item>

            <StructuredList.Item name="Order">
              <Label onClick={handleOrderClicked}>
                {credit.orderLine.orderId}
              </Label>
            </StructuredList.Item>

            <StructuredList.Item name="Message">
              <Label>{credit.details}</Label>
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
    )
  );
};

export default CreditDetails;
