import defaults from "configuration/defaults";
import feedActions from "../actions/feedActions";
import calendarActions from "areas/calendar/actions/calendarActions";
import { PageInfo } from "types/common/paging.types";
import { IBeehiveAction } from "types/common/action.types";
import { FeedItemListView } from "../types/feedResponses.types";


export interface IFeedItem extends FeedItemListView {
  dismissed?: boolean;
}

interface IFeedState {
  feed: IFeedItem[],
  loading: boolean;
  error: string | null;
  paging: PageInfo;
}

const INITIAL_STATE: IFeedState = {
  feed: [],
  loading: false,
  error: null,
  paging: defaults.DEFAULT_PAGING,
};


const feedReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IFeedState => {

  const {
    GETFEED,
    FEED_ARTICLE_FAVOURITE,
    FEED_ARTICLE_UNFAVOURITE,
    FEED_ARTICLE_LIKE,
    FEED_ARTICLE_UNLIKE,
    FEED_ARTICLE_DISMISS,
    FEED_ARTICLE_UNDISMISS,
  } = feedActions.types;

  const { CALENDAR_EVENTSIGNUP, CALENDAR_EVENTUNSIGNUP } =
    calendarActions.types;

  switch (action.type) {
    case GETFEED.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case GETFEED.SUCCESS:
      var newState = {
        ...state,
        feed:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.feed, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };
      return newState;

    case GETFEED.FAILED:
      return { 
        ...state, 
        loading: false, 
        error: action.payload 
      };

    case FEED_ARTICLE_FAVOURITE.SUCCESS:
      return {
        ...state,
        feed: state.feed.map(x =>
          x.id == action.payload && x.type == 0 ? { ...x, favourited: true } : x
        ),
      };

    case FEED_ARTICLE_UNFAVOURITE.SUCCESS:
      return {
        ...state,
        feed: state.feed.map(x =>
          x.id == action.payload && x.type == 0
            ? { ...x, favourited: false }
            : x
        ),
      };

    case FEED_ARTICLE_LIKE.SUCCESS:
      return {
        ...state,
        feed: state.feed.map(x =>
          x.id == action.payload && x.type == 0 ? { ...x, liked: true } : x
        ),
      };

    case FEED_ARTICLE_UNLIKE.SUCCESS:
      return {
        ...state,
        feed: state.feed.map(x =>
          x.id == action.payload && x.type == 0 ? { ...x, liked: false } : x
        ),
      };

    case FEED_ARTICLE_DISMISS.SUCCESS:
      return {
        ...state,
        feed: state.feed.map(x =>
          x.id == action.payload && x.type == 0 ? { ...x, dismissed: true } : x
        ),
      };

    case FEED_ARTICLE_UNDISMISS.SUCCESS:
      return {
        ...state,
        feed: state.feed.map(x =>
          x.id == action.payload && x.type == 0 ? { ...x, dismissed: false } : x
        ),
      };

    case CALENDAR_EVENTSIGNUP.SUCCESS:
      return {
        ...state,
        feed: state.feed.map(x =>
          x.type === 2
            ? {
                ...x,
                data: x.data.map((d: any) =>
                  action.payload.some((a: any) => a === d.Id)
                    ? { ...d, SignedUp: true, Signups: d.Signups + 1 }
                    : d
                ),
              }
            : x
        ),
      };

    case CALENDAR_EVENTUNSIGNUP.SUCCESS:
      return {
        ...state,
        feed: state.feed.map(x =>
          x.type === 2
            ? {
                ...x,
                data: x.data.map((d: any) =>
                  action.payload.some((a: any) => a === d.Id)
                    ? { ...d, SignedUp: false, Signups: d.Signups - 1 }
                    : d
                ),
              }
            : x
        ),
      };

    default:
      return state;
  }
};

export default feedReducer;
