import React, { useEffect, useState } from "react";
import {
  Message,
  Swatches,
  EmptyMessage,
  Loader,
  Size,
  fontStyle,
  displayFont,
  typescale,
  neutral,
  Card,
} from "ui-kit";
import { useSelector, useDispatch } from "react-redux";
import { formatTime, formatDate } from "utils/dateTime";
import styled from "styled-components";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import schoolActions from "areas/planner/actions/school/schoolActions";
import CategoryChip from "areas/behaviour/components/behaviourCommon/categoryChip";
import { IDetentionDetails, IMultipleIncidentDetails, MultipleBehavioursSaveResultsViewExt, getDefaultDetentionInfo } from "areas/behaviour/utils/detentions";
import { DETENTION_OPTIONS_KEYS } from "areas/behaviour/constants/detentions";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import ReadOnlyDataList, { DetentionDataType } from "areas/behaviour/components/readOnly/readOnlyDataList";
import DetentionFormWrapper from "areas/behaviour/components/detentions/detentionFormWrapper";
import { ClassGroupStaffView } from "types/users/userGroups.types";
import { useAppSelector } from "reducers/hooks";
import { objects } from "utils";


const Wrapper = styled.div`
  flex-grow: 1;
`;

const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    ${fontStyle(displayFont.medium, typescale.header4, neutral[700])};
    text-align: center;
    width: 640px;
  }
`;


interface IMultipleBehaviourErrorFormProps {
  selectedStudentBehaviour: MultipleBehavioursSaveResultsViewExt;
  incidentDetails: IMultipleIncidentDetails;
  group: ClassGroupStaffView;
}


const MultipleBehaviourErrorForm: React.FC<IMultipleBehaviourErrorFormProps> = ({
  selectedStudentBehaviour,
  incidentDetails,
  group,
}) => {

  const {
    defaultDetention,
    error,
    loading: loadingDefaultDetention,
  } = useAppSelector(state => state.defaultDetention);
  const { schoolInformation } = useAppSelector(state => state.school);
  const { multipleBehaviourResponse } = useAppSelector(
    state => state.behaviourForMultipleStudents
  );

  const [behaviourError, setBehaviourError] = useState<string>("");
  const [detentionDetails, setDetentionDetails] = useState<IDetentionDetails>(null);
  const [readOnlyIncidentDetails, setReadOnlyIncidentDetails] = useState<DetentionDataType[]>([]);

  useEffect(() => {
    if (selectedStudentBehaviour) {
      // if (selectedStudentBehaviour.detentionDetails === null) {
      behaviourActions.getDefaultDetention(
        group.school.id,
        selectedStudentBehaviour.student.id,
        incidentDetails.categoryId
      );
      // }
      // else {
      //   setDetentionDetails(selectedStudentBehaviour.detentionDetails);
      // }

      setBehaviourError(selectedStudentBehaviour.errorMessage);
    }
  }, [selectedStudentBehaviour]);

  useEffect(() => {
    if (!schoolInformation || schoolInformation.id !== group.school.id) {
      schoolActions.getSchoolInformation(group.school.id);
    }
  }, [group]);

  useEffect(() => {
    if (incidentDetails) {
      const data = [
        {
          dataType: "Behaviour",
          dataValue: (
            <CategoryChip
              className="behaviour"
              categoryCode={incidentDetails.behaviourCodeName}
              categoryName={incidentDetails.behaviourCategoryName}
              colorSwatch={Swatches.Danger}
            />
          ),
        },
        {
          dataType: "Points",
          dataValue: incidentDetails.points,
        },
        {
          dataType: "Incident Date",
          dataValue: formatDate(incidentDetails.incidentDateTime),
        },
        {
          dataType: "Incident Time",
          dataValue: formatTime(incidentDetails.incidentDateTime),
        },
        {
          dataType: "Internal Notes",
          dataValue: incidentDetails.description,
        },
        {
          dataType: "Public Notes",
          dataValue: incidentDetails.publicNotes,
        },
      ];
      setReadOnlyIncidentDetails(data);
    }
  }, [incidentDetails]);

  useEffect(() => {
    if (
      defaultDetention &&
      defaultDetention.detentionTypeInformation !== null
    ) {
      const tempDetentionDetails = getDefaultDetentionInfo(
        defaultDetention.detentionTypeInformation,
        null,
        null,
        schoolInformation?.id
      );

      if (
        defaultDetention.isTriggeredDetention ||
        defaultDetention.detentionOptionId === DETENTION_OPTIONS_KEYS.MANDATORY
      ) {
        setDetentionDetails(tempDetentionDetails);
      } else {
        setDetentionDetails(null);
      }
    }
  }, [defaultDetention]);

  useEffect(() => {
    if (selectedStudentBehaviour) {
      let multipleBehaviourResponseCopy = objects.deepClone(multipleBehaviourResponse);

      const studentBehaviourIndex =
        multipleBehaviourResponseCopy.behaviours.findIndex(
          behaviour =>
            behaviour.student.id === selectedStudentBehaviour.student.id
        );

      const behaviour =
        multipleBehaviourResponseCopy.behaviours[studentBehaviourIndex];

      multipleBehaviourResponseCopy.behaviours[studentBehaviourIndex] = {
        ...behaviour,
        detentionDetails: detentionDetails,
      };

      behaviourActions.createMultipleBehaviourResponse(
        multipleBehaviourResponseCopy
      );
    }
  }, [detentionDetails]);

  if (loadingDefaultDetention) {
    return (
      <Wrapper>
        <Loader size={Size.Medium} cover />
      </Wrapper>
    );
  }

  if (error) {
    return (
      <Wrapper>
        <ApiExceptionMessage error={error} />
      </Wrapper>
    );
  }

  return (
    <div className="multiple-behaviour-error-form">
      {selectedStudentBehaviour ? (
        <>
          {selectedStudentBehaviour.isSuccessful ? (
            <Message
              text={`There are no problems in awarding the behaviour to ${selectedStudentBehaviour.student.firstName} ${selectedStudentBehaviour.student.lastName}`}
              color={Swatches.Success}
            />
          ) : (
            behaviourError && (
              <Message
                color={Swatches.Primary}
                text={
                  behaviourError?.trim() +
                  " - Please check below and amend if needed."
                }
              />
            )
          )}

          <Card title="Incident Details">
            <Card.Body>
              <ReadOnlyDataList data={readOnlyIncidentDetails} />
            </Card.Body>
          </Card>

          {!incidentDetails.leadershipInterventionRequired &&
            detentionDetails && (
              <DetentionFormWrapper
                studentId={selectedStudentBehaviour.student.id}
                schoolId={group.school.id}
                detentionDetails={detentionDetails}
                setDetentionDetails={setDetentionDetails}
              />
            )}
          <br />
        </>
      ) : (
        <>
          <EmptyMessage
            icon="list"
            title="There is an issue with awarding the behaviour points"
            cover
          />
          <InformationWrapper>
            <div>
              This may be because they need a detention adding or because they
              have a clashing detention. Please click on each student to view
              further details and make any changes required.
            </div>
          </InformationWrapper>
        </>
      )}
    </div>
  );
};

export default MultipleBehaviourErrorForm;
