import React, { useState, useEffect } from "react";
import { Dropdown } from "ui-kit";
import { Constants } from "configuration";
import { ILunchTypes } from "configuration/constants.types";


interface LunchType {
  key: number | string; 
  label: string;
  value: number; 
}


interface ILunchTypeDropdownProps {
  onChange?: (value: number) => void;
  initialValue?: number;
  fluid?: boolean;
}


const LunchTypeDropdown: React.FC<ILunchTypeDropdownProps> = ({ onChange, initialValue, fluid }) => {

  const ALL_OPTION: LunchType = { 
    key: -1, 
    label: "All Lunch Types", 
    value: -1 
  };

  const [selectedValue, setSelectedValue] = useState<number>(-1);
  const [options, setOptions] = useState<LunchType[]>(() => {
    var opts: LunchType[] = [];

    for (var key in Constants.LUNCH_TYPES) {
      opts.push({
        key: key,
        label: Constants.LUNCH_TYPES[key as keyof ILunchTypes].name,
        value: Constants.LUNCH_TYPES[key as keyof ILunchTypes].value,
      });
    }

    return [ALL_OPTION, ...opts];
  });

  const handleChange = (value: number) => {
    setSelectedValue(value);
    onChange?.(value);
  };

  useEffect(() => {
    const option = options.find((x) => x.value === initialValue);

    setSelectedValue(option ? option.value : -1);
  }, [initialValue]);

  return (
    <Dropdown
      placeholder="Lunch Type"
      items={options}
      onChange={handleChange}
      value={selectedValue}
      fluid={fluid}
    />
  );
};
export default LunchTypeDropdown;
