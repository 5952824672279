import { IBeehiveAction, StateAction } from "types/common/action.types";
import lunchRegisterActions from "../../actions/lunchRegisterActions"
import { LunchRegisterDetailView } from "areas/payments/types/catering/cateringResponses.types";


interface ILunchTegisterActions {
  saving: StateAction
}

export interface ILunchRegisterState {
  loading: boolean;
  register: LunchRegisterDetailView | null;
  error: null | string;
  actions: ILunchTegisterActions;
}


const INITIAL_STATE: ILunchRegisterState = {
  loading: false,
  register: null,
  error: null,
  actions: {
    saving: { 
      working: false, 
      error: null 
    },
  },
};

const lunchRegisterReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ILunchRegisterState => {
  const { 
    PAYMENTS_GETLUNCHREGISTER, 
    PAYMENTS_SAVELUNCHREGISTER 
  } = lunchRegisterActions.lunchRegisterTypes;

  switch (action.type) {

    case PAYMENTS_GETLUNCHREGISTER.START:
      return { 
        ...state, 
        register: null, 
        loading: true, 
        error: null 
      };
    case PAYMENTS_GETLUNCHREGISTER.SUCCESS:
      return {
        ...state,
        loading: false,
        register: action.payload,
        error: null,
      };
    case PAYMENTS_GETLUNCHREGISTER.FAILED:
      return {
        ...state,
        loading: false,
        register: null,
        error: "There was a problem loading the lunch register",
      };

    case PAYMENTS_SAVELUNCHREGISTER.START:
      return {
        ...state,
        actions: { 
          saving: { 
            working: true, 
            error: null 
          } 
        },
      };
    case PAYMENTS_SAVELUNCHREGISTER.SUCCESS:
      return {
        ...state,
        actions: { 
          saving: { 
            working: false, 
            error: null 
          } 
        },
      };
    case PAYMENTS_SAVELUNCHREGISTER.FAILED:
      return {
        ...state,
        actions: {
          saving: {
            working: false,
            error: "There was a problem saving the lunch register",
          },
        },
      };

    default:
      return state;
  }
};

export default lunchRegisterReducer;