/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Spacing,
  neutral,
  BorderRadius,
  formatScrollbars,
  Size,
  Loader,
  displayFont,
  fontStyle,
  typescale,
} from "../index"
import { useEventListener } from "../../hooks";

export interface IModalProps {
  children:
    ( React.ReactElement<IModalBodyProps>
    | React.ReactElement<IModalHeaderProps>
    | React.ReactElement<IModalFooterProps> )[]
    | React.ReactElement<IModalBodyProps>;
  open?: boolean;
  width?: string;
  height?: string;
  onOpen?(): void;
  onClose?(): void;
  loading?: boolean;
  empty?: boolean;
  emptyMessage?: React.ReactElement;
  error?: string;
  errorMessage?: React.ReactElement;
  lock?: boolean;
}

export interface IModalBodyProps {
  noPad?: boolean;
  children: React.ReactElement | React.ReactElement[];
  loading?: boolean;
}

export interface IModalFooterProps {
  children: React.ReactElement | React.ReactElement[];
}

export interface IModalHeaderProps {
  children: React.ReactElement | React.ReactElement[];
}

const Overlay = styled.div<{open: boolean}>`
  ${({ open }) => (open ? `display: flex;` : `display: none;`)}
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
`;

const ModalWrapper = styled.div<{width?: string, height?: string}>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  max-height: 95vh;
  max-width: 95vw;
  min-width: 50vw;
  border-radius: ${BorderRadius.Default}px;
  overflow-y: hidden;

  ${({ width }) =>
    width && `max-width: auto; min-width: auto; width: ${width};`}
  ${({ height }) =>
    height && `max-height: auto; min-height: auto; height: ${height};`}
`;

const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  background: ${neutral[200]};
  flex-grow: 1;
  height: inherit;
`;

const BodyWrapper = styled.div<{noPad?: boolean}>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  // height: 0px;
  overflow-y: auto !important;
  ${formatScrollbars(neutral[200])};

  > iframe {
    box-sizing: box-content;
    margin: 0;
    padding: 0;

    html {
      overflow-y: auto !important;
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-content: center;
  border-bottom: 1px solid ${neutral[100]};

  .left {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  .right {
    display: flex;
    align-items: center;
  }
`;

const FooterWrapper = styled.div`
  padding: ${Spacing.Medium}px;
  border-top: 1px solid ${neutral[300]};
  background: ${neutral[200]};
  border-bottom-right-radius: ${BorderRadius.Default}px;
  border-bottom-left-radius: ${BorderRadius.Default}px;

  .action-bar {
    justify-content: flex-end;
    margin-bottom: 0;
  }
  .left {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  .right {
    display: flex;
    align-items: center;
  }
`;

const AlertBody = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    ${fontStyle(displayFont.roman, typescale.control1, neutral[600])}
  }
`;


const AlertModal: React.FC<IModalProps> & {
  Body: typeof AlertModalBody;
  Header: typeof AlertModalHeader;
  Footer: typeof AlertModalFooter;
} = ({
  children,
  open,
  onOpen,
  onClose,
  width,
  height,
  loading,
  empty,
  emptyMessage,
  lock,
  error,
  errorMessage,
}) => {
  const [isOpen, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  useEffect(() => {
    if (open) {
      setOpen(true);
      onOpen?.();
    } else {
      handleClose();
    }
  }, [open]);

  const handleKeyDown = ( key: React.KeyboardEvent ) => {
    if (open && !lock) {
      switch (key.key) {
        case "Escape":
          handleClose();
          break;
      }
    }
  };

  useEventListener("keydown", handleKeyDown);

  return (
    <Overlay open={isOpen} className="modal-overlay">
      <ModalWrapper width={width} height={height} className="modal-wrapper">
        <ModalInner className="modal-inner">
          {" "}
          {loading && <Loader size={Size.Medium} cover />}
          {!loading && error && empty && errorMessage}
          {!loading && !error && empty && emptyMessage}
          {!loading && !error && !empty && children}
        </ModalInner>
      </ModalWrapper>
    </Overlay>
  );
};

const AlertModalBody: React.FC<IModalBodyProps> = ({ children, noPad }) => {
  return (
    <BodyWrapper className="modal-body" noPad={noPad}>
      <AlertBody>
        {children}
      </AlertBody>
    </BodyWrapper>
  );
};

const AlertModalHeader: React.FC<IModalHeaderProps> = ({ children }) => {
  return <HeaderWrapper className="modal-header">{children}</HeaderWrapper>;
};

const AlertModalFooter: React.FC<IModalFooterProps> = ({ children }) => {
  return <FooterWrapper className="modal-footer">{children}</FooterWrapper>;
};

AlertModal.Body = AlertModalBody;
AlertModal.Header = AlertModalHeader;
AlertModal.Footer = AlertModalFooter;

export default AlertModal;
