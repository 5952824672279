import React, { useEffect, useState } from "react";
import {
  Modal,
  HtmlText,
  Size,
  Left,
  Right,
  Button,
  Swatches,
  Spacing,
  ActionBar,
  DetailLabel,
  Table,
  TextInput,
  Label,
  Sub,
  Message,
  Dropdown,
  TextInputType,
  ValidationMessage,
  StructuredList,
  Card,
  ToastService,
  Chip,
} from "ui-kit";
import moment from "moment";
import Moment from "react-moment";
import EventHeading from "../eventHeading";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { strings, arrays } from "utils";
import calendarActions from "areas/calendar/actions/calendarActions";
import { Avatar, GroupList } from "sharedComponents/common";
import { RootState } from "reducers/store";
import { CalendarEventAppListView, CalendarEventDateListView, CalendarEventMenuItemListView, GroupedMenuView } from "areas/calendar/types/calendarResponses.types";
import { CalendarEventSignupCommand, CancelCalendarEventSignupCommand } from "areas/calendar/types/calendarRequests.types";
import { UserStartupView } from "types/users/userStartup.types";
import { CalendarEventMenuItemType } from "areas/calendar/types/calendarShared.types";
import { CalendarEvent } from "areas/calendar/reducers/calendarEventReducer";
import { IFeedItem } from "areas/feed/reducers/feedReducer";
import { read } from "fs";
import FormatDate from "./formatDate";
import DateSignupButton from "./dateSignupButton";
import RemainingPlaces from "./remainingPlaces";
import EventDetails from "./eventDetails";
import EventDates from "./eventDates";
import EventMenu, { MenuChoice } from "./eventMenu";
import EventRequirements from "./eventRequirements";


const RightWrapper = styled.div`
  padding-right: ${Spacing.ExtraLarge}px;
`;

const Wrapper = styled.div`
  .signup-all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button {
      margin-bottom: ${Spacing.Large}px;
    }
    > span {
    }
  }

  .cancel-all {
    margin-bottom: ${Spacing.Large}px;
  }
`;


interface IEventModalProps {
  userId: string;
  event: CalendarEventAppListView | IFeedItem;
  onClose?: () => void;
  onSave?: (calendarEvent: CalendarEvent) => void;
  onUnsignedUp?: (calendarEvent: CalendarEvent) => void;
  onCancel?: () => void;
  open: boolean;
  readonly?: boolean;
}

export interface SignupDetails {
  id?: number | null;
  user: UserStartupView | null;
  accessRequirements: string;
  dietaryRequirements: string;
  menu: MenuChoice[];
  dates?: number[];
}


const EventModal: React.FC<IEventModalProps> = ({
  userId,
  event,
  onClose,
  onSave,
  onUnsignedUp,
  onCancel,
  open,
  readonly
}) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { calendarEvent, loading, signingUp, signupError, error } = useSelector((state: RootState) => state.calendarEvent);

  const [_open, _setOpen] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_signup, _setSignup] = useState<SignupDetails>({
    user: null,
    accessRequirements: "",
    dietaryRequirements: "",
    menu: [],
    dates: []
  });

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    userId && event && calendarActions.getCalendarEvent(userId, event.id);
  }, [event]);

  useEffect(() => {
    if (calendarEvent) {
      var defaultMenu = [];
      var dates = [];

      if (calendarEvent?.menuChoices?.length) {
        defaultMenu = calendarEvent.menuChoices.map(x => ({
          id: x.id,
          type: x.type,
        }));
      } else {
        if (!_signup.menu?.length && calendarEvent?.menu?.length) {
          for (var i = 0; i < calendarEvent?.menu.length; i++) {
            defaultMenu.push({
              id: calendarEvent.menu[i].menu[0].id,
              type: calendarEvent.menu[i].type,
            });
          }
        }
      }

      if (calendarEvent.dates.length > 1) {
        dates = calendarEvent.dates.filter(x => x.signedUp).map(x => x.id);
      } else {
        dates = [calendarEvent.dates[0]?.id];
      }
      _setSignup({
        ..._signup,
        user: user,
        menu: defaultMenu,
        dates: dates,
        dietaryRequirements: calendarEvent.dietaryRequirements,
        accessRequirements: calendarEvent.accessRequirements,
      });
    }
  }, [calendarEvent]);


  const handleCancel = () => {
    if (
      window.confirm(
        "This will cancel your signup for all forthcoming dates for this event. Are you sure?"
      )
    ) {
      const data: CancelCalendarEventSignupCommand = {
        userId: user.id,
        eventId: calendarEvent.id,
        eventDates: calendarEvent.dates.map(x => x.id),
      };

      calendarActions.calendarEventUnsignup(data, () => {
        ToastService.pop("Signup cancelled", null, "calendar");
        calendarActions.removeEventFromSignedUpEvents(calendarEvent);
        onUnsignedUp?.(calendarEvent);

        onCancel?.();
      });
    }
  };

  const handleSave = () => {
    var errors = [];

    if (!_signup.user) {
      errors.push(`Please choose an attendee.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    if (
      window.confirm(
        `This will ${
          calendarEvent?.signedUp ? "update your details " : "sign you up "
        } for this event date. Are you sure?
        `
      )
    ) {
      const data: CalendarEventSignupCommand = {
        userId: user.id,
        eventId: calendarEvent.id,
        eventDates: _signup.dates,
        accessRequirements: _signup.accessRequirements,
        dietaryRequirements: _signup.dietaryRequirements,
        menu: _signup.menu?.map(x => x.id),
      };
      calendarActions.calendarEventSignup(data, id => {
        calendarActions.addEventToSignedUpEvents(calendarEvent);
        ToastService.pop("Signed up successfully", null, "calendar");
        onSave?.(calendarEvent);
      });
    }
  };

  const signupStillOpen = (cc: CalendarEvent) => {
    return (
      cc?.allowSignupTo == null ||
      moment(cc?.allowSignupTo).isAfter(moment())
    )
  }

  return (
    <Modal
      title={<DetailLabel bold label={`${readonly ? "Details" : "Signup"} for Event`} />}
      open={_open}
      onClose={onClose}
      loading={loading}
      width="80%"
      height="70%"
      empty={event == null}
    >
      <Modal.Header>
        {event ? (
          <>
            <Left>
              <EventHeading event={calendarEvent} hideSignupButton />
            </Left>
            <Right>
              <RightWrapper>
                <Avatar user={calendarEvent?.createdBy} sub="Organiser" />
              </RightWrapper>
            </Right>
          </>
        ) : null}
      </Modal.Header>
      <Modal.Body scrollOnErrors={validationErrors}>
        <EventDetails 
          calendarEvent={calendarEvent} 
          validationErrors={validationErrors}
          readonly={readonly}
        />
        <EventDates 
          calendarEvent={calendarEvent} 
          signup={_signup} 
          setSignup={_setSignup}
          readonly={readonly}
        />
        <EventMenu 
          calendarEvent={calendarEvent} 
          signup={_signup} 
          setSignup={_setSignup}
          readonly={readonly}
        />
        <EventRequirements 
          calendarEvent={calendarEvent} 
          signup={_signup} 
          setSignup={_setSignup}
          readonly={readonly}
        />
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          {calendarEvent?.signedUp && !calendarEvent?.mandatoryAttendance && !readonly && (
            <Button
              size={Size.Small}
              text={"Cancel Signup"}
              color={Swatches.Danger}
              style={{ width: 200 }}
              onClick={handleCancel}
            />
          )}
          {!calendarEvent?.mandatoryAttendance && !readonly && signupStillOpen(calendarEvent) &&(
            <Button
              size={Size.Small}
              text={
                calendarEvent?.signedUp ? "Save Changes" : "Signup for this Event"
              }
              color={Swatches.Success}
              fluid
              onClick={handleSave}
            />
          )}
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default EventModal;
