import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import styled from "styled-components";
import { SchoolStartupView } from "types/schools/schools.types";

const Wrapper = styled.div`
  display: flex;
  border: 1px solid green;

  .group-picker-column {
    border: 1px solid red;
  }
`;

interface SchoolItems {
  name: string;
  onClick: () => void;
}

const GroupViewer = () => {

  //TODO: Learn what this component is for. It isn't used on production and seems incomplete

  // const { user } = useSelector((state: RootState) => state.currentUser);
  // const [selectedSchool, setSelectedSchool] = useState(null);

  // const getSchools = () => {
  //   if (user) {
  //     const items : SchoolItems[] = [];

  //     user.schools.forEach((school, index) =>
  //       items.push({
  //         name: school.name,
  //         onClick: () => schoolSelected(school, index)
  //       })
  //     );

  //     return [{ items }];
  //   } else {
  //     return null;
  //   }
  // };

  // const [columns, setColumns] = useState<{items: SchoolItems[] | null}>(getSchools());

  // const schoolSelected = (school: SchoolStartupView, index: number) => {
  //   setColumns([
  //     columns[0],
  //     { items: groupTree.map(item => ({ ...item, id: school.id })) }
  //   ]);
  // };

  // const getSubjectsForSchool = (schoolId: string, itemOnClick: (num: number) => void) => {
  //   console.log("getSubjectsForSchool", schoolId);
  //   console.log(columns);
  //   var items: SchoolItems[] = [
  //     { name: "Maths", onClick: () => itemOnClick(122) },
  //     { name: "Science", onClick: () => itemOnClick(123) },
  //     { name: "Languages", onClick: () => itemOnClick(124) }
  //   ];
  //   setColumns([...columns, { items }]);
  // };

  // const getClassGroupsForSubject = (subjectId, itemOnClick) => {
  //   console.log("getClassGroupsForSubject", subjectId);
  //   return [{ name: "11X/123" }, { name: "11Y/123" }, { name: "11P/123" }];
  // };

  // const getClassGroupsForSchoolYearGroup = (yearGroupId, itemOnClick) => {
  //   console.log("Getting clas groups for school year grouo");
  // };

  // const getYearGroupsForSchool = (id, itemOnClick) => {
  //   console.log("Getting year groups for school");
  // };

  // const getTutorGroupsForSchool = (id, itemOnClick) => {
  //   console.log("Getting tutor for school");
  // };

  // const getHousesForSchool = (id, itemOnClick) => {
  //   console.log("Getting houses for school");
  // };
  // const getCustomGroupsForSchool = (id, itemOnClick) => {
  //   console.log("Getting custom groups for school");
  // };

  // const groupTree = [
  //   {
  //     name: "Year Groups",
  //     isLeaf: true,
  //     onClick: schoolId => getYearGroupsForSchool(schoolId)
  //   },
  //   {
  //     name: "Subjects",
  //     isLeaf: false,
  //     onClick: schoolId =>
  //       getSubjectsForSchool(schoolId, getClassGroupsForSubject)
  //   },
  //   {
  //     name: "Houses",
  //     isLeaf: true,
  //     onClick: schoolId => getHousesForSchool(schoolId)
  //   },
  //   {
  //     name: "Tutor Groups",
  //     isLeaf: false,
  //     onClick: schoolId =>
  //       getYearGroupsForSchool(schoolId, getTutorGroupsForSchool)
  //   },
  //   {
  //     name: "Class Groups",
  //     isLeaf: false,
  //     onClick: schoolId =>
  //       getYearGroupsForSchool(schoolId, getClassGroupsForSchoolYearGroup)
  //   },
  //   {
  //     name: "Custom Groups",
  //     isLeaf: true,
  //     onClick: (schoolId) => getCustomGroupsForSchool(schoolId)
  //   }
  // ];

  // return (
  //   <>
  //     <Wrapper>
  //       {columns != null &&
  //         columns.map((column, index) => (
  //           <div className="group-picker-column" key={index}>
  //             <ul>
  //               {column.items.map((item, index) => (
  //                 <li key={index} onClick={() => item.onClick(item.id)}>
  //                   {item.name}
  //                 </li>
  //               ))}
  //             </ul>
  //           </div>
  //         ))}
  //     </Wrapper>
  //     <pre>{JSON.stringify(columns, null, 2)}</pre>
  //   </>
  // );

  return (
    <></>
  )
};

export default GroupViewer;
