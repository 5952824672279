import React from "react";
import styled from "styled-components";
import DetailLabel from "./detailLabel";
import { neutral } from "../index";

const Wrapper = styled.div`
  .detail-label .label {
    color: ${neutral[100]} !important;
  }
  .detail-label .sub {
    color: ${neutral[400]} !important;
  }
`;


interface ITooltipProps {
  title?: string | JSX.Element | React.ReactNode;
  sub?: any;
}

const Tooltip: React.FC<ITooltipProps> = ({ title, sub }) => {
  return (
    <Wrapper>
      <DetailLabel label={title} sub={sub} bold />
    </Wrapper>
  );
};

export default Tooltip;
