import config from "configuration";
import store, { AppDispatch } from "reducers/store";
import { BuildLabels } from "./actionTypeBuilder";
import { AxiosError, AxiosResponseHeaders } from "axios";

/**
 * A function to call a service method and dispatch async actions accordingly
 *  (START, SUCCESS, FAILED and ERROR in the event of failure)
 * @param {*} service The service method to call
 * @param {*} actionType The action type with START, SUCCESS, FAILED and ERROR properties
 * @param {*} options An options object containing an errorMessage and a success callback
 * @returns {object} The data from the service call, or an error in the case of failure
 */
const action = <promisePayload = unknown>(
  service: () => Promise<any>,
  actionType: BuildLabels,
  onSuccess?: (data: promisePayload, headers: AxiosResponseHeaders) => void,
  onFailure?: (error: any) => void
) => {
  const dispatch: AppDispatch = store.dispatch;

  const dispatchErrorAction = (error: Error | AxiosError | any) => {
    dispatch({
      type: actionType.FAILED,
      payload: error,
    });
  };

  if (!service) {
    throw new Error("A service must be specified.");
  }

  if (!actionType) {
    throw new Error("An action must be specified");
  }

  return new Promise<promisePayload>((resolve, reject) => {
    try {
      // dispatch({ type: errorTypes.RESET_ERROR });
      dispatch({ type: actionType.START });

      service()
        .then(response => {
          setTimeout(() => {
            dispatch({
              type: actionType.SUCCESS,
              payload: response.data,
            });
          }, Number(config.apiTimeout));

          onSuccess?.(response.data, response.headers);
          resolve(response?.data);
        })
        .catch((error: AxiosError) => {
          dispatchErrorAction(error);
          onFailure && onFailure(error);
          reject(error);
        });
    } catch (error) {
      dispatchErrorAction(error);
      onFailure && onFailure(error);
      reject(error);
    }
  });
  // };
};

export default action;
