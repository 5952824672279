import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import lodashDebounce from "lodash.debounce";
import styled from "styled-components";
import {
  Modal,
  ActionBar,
  Right,
  Button,
  Size,
  Swatches,
  Sub,
  Subtitle,
  TextInput,
  TextInputType,
  Spacing,
  Checkbox,
  ToastService,
  neutral,
  ValidationMessage,
} from "ui-kit";
import feedbackActions from "../actions/feedbackActions";
import { RootState } from "reducers/store";
import { SaveFeedbackMessageCommand } from "../types/feedbackRequests.types";
import { arrays } from "utils";


const Wrapper = styled.div`
  height: 100%;
  padding: ${Spacing.Small}px;

  .subtitle-wrapper {
    margin-bottom: ${Spacing.Medium}px;
  }
  .subtitle {
    font-size: 0.8rem;
    line-height: ${Spacing.Large}px !important;
    color: ${neutral[600]};
  }
  textarea {
    background: white !important;
    color: ${neutral[600]};
  }
  .textInput-inner {
    :focus-within {
      background: white;
    }
`;


interface IFeedbackModalProps {
  open: boolean;
  onClose?: () => void;
}


const FeedbackModal: React.FC<IFeedbackModalProps> = ({ open, onClose }) => {

  const { feedbackMessage, actions } = useSelector((state: RootState) => state.feedbackMessage);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const handleClose = () => {
    setValidationErrors([]);
    onClose?.();
  };

  const handleSubmit = () => {

    setValidationErrors([]);
    var errors = [];
    if (!feedbackMessage.content) {
      errors.push(`Please add some feedback content`);
    }
    setValidationErrors(errors);
    if (!arrays.isEmpty(errors)) {
      return;
    }

    var data: SaveFeedbackMessageCommand = {
      content: feedbackMessage.content,
      personalReply: feedbackMessage.requiresReply,
    };
    feedbackActions.createFeedbackMessage(data, () => {
      ToastService.pop("Feedback successfully submitted", null, "comment");
      setValidationErrors([]);
      onClose?.();
    });
  };

  const feedbackContentChange = useCallback(
    lodashDebounce((value: string, index?: number, callback?: () => void) => {
      feedbackActions.setFeedbackContent(value);
    }, 500),
    []
  );

  const toggleReplyRequirement = (requiresReply: boolean) => {
    feedbackActions.setFeedbackReplyRequirement(requiresReply);
  };

  return (
    <Modal
      title="Share Feedback"
      onClose={handleClose}
      open={open}
      height="50%"
      width="50%"
    >
      <Modal.Body>
        <ValidationMessage errors={validationErrors} />
        <Wrapper>
          <Subtitle text="Questions, rumours, or feedback? Let us know!">
            <br /> <Sub> (Not for technical support)</Sub>
          </Subtitle>
          <Sub>
            Responses that will benefit others, will be published and shared in
            Heartbeat (Note: you will not be named).
          </Sub>
          <TextInput
            fluid
            type={TextInputType.Textarea}
            rows={5}
            value={feedbackMessage.content}
            onChange={value => feedbackContentChange(value)}
          />
          <Checkbox
            checked={feedbackMessage.requiresReply}
            text={`I would also like a direct response to my feedback`}
            onChange={value => toggleReplyRequirement(value.checked)}
          />
        </Wrapper>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              onClick={handleClose}
              size={Size.Small}
              color={Swatches.Low}
              text="Close"
              working={actions.save.working}
            />
            <Button
              onClick={handleSubmit}
              size={Size.Small}
              color={Swatches.Success}
              text="Submit"
              working={actions.save.working}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default FeedbackModal;
