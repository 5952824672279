import React, { useEffect, useState } from "react";
import {
  ActionBar,
  Button,
  fontStyle,
  Icon,
  Right,
  Size,
  Spacing,
  ChipList,
  Table,
  Text,
  textFont,
  typescale,
  EmptyMessage,
} from "ui-kit";
import { danger, neutral, success } from "ui-kit/common/colors";
import styled from "styled-components";
import { arrays } from "utils";
import calendarActions from "../../actions/calendarActions";
import { EVENT_ATTENDANCE, SIGN_UP_STATUS } from "../../constants/events";
import { ApiExceptionMessage, Avatar } from "sharedComponents/common";
import { formatDate } from "areas/calendar/utils/events";
import StudentSensitiveDataChip from "areas/planner/components/studentRecord/studentSensitiveData/studentSensitiveDataChip";
import { CalendarEvent } from "areas/calendar/reducers/calendarEventReducer";
import { IBeehiveError } from "types/common/errors.types";
import { EventRegisterForUsersView } from "areas/calendar/types/calendarResponses.types";
import { PagedQueryView } from "types/common/views.types";
import { PageInfo } from "types/common/paging.types";

const HelpText = styled.div`
  ${fontStyle(textFont.roman, typescale.paragraph, neutral[700])};
  margin-bottom: ${Spacing.Default}px;
`;


interface IEventAttendeesProps {
  event: CalendarEvent;
}


const EventAttendees: React.FC<IEventAttendeesProps> = ({ event }) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<IBeehiveError | null>(null);
  const [eventAttendanceReport, setEventAttendanceReport] = useState<PagedQueryView<EventRegisterForUsersView>>({
    items: [],
    paging: null,
  });
  const [_exportLoading, _setExportLoading] = useState<boolean>(false);

  const loadRegister = (pageIndex: number) => {
    const callback = (data: PagedQueryView<EventRegisterForUsersView>) => {
      setEventAttendanceReport({
        items: [...eventAttendanceReport.items, ...data.items],
        paging: data.paging,
      });
      setLoading(false);
    };
    const errCallback = (error: IBeehiveError) => {
      setError(error);
      setLoading(false);
    };
    setLoading(true);
    calendarActions.getEventAttendanceRegisterReport(
      event.id,
      pageIndex,
      callback,
      errCallback
    );
  };

  const handlePage = () => {
    loadRegister(eventAttendanceReport.paging.pageIndex + 1);
  };

  useEffect(() => {
    loadRegister(0);
  }, []);

  const handleExport = () => {
    _setExportLoading(true);
    const callback = () => {
      _setExportLoading(false);
    };
    calendarActions.exportEventAttendanceRegisterReport(
      event.id,
      event.title,
      callback,
      callback
    );
  };

  const getSub = (user: EventRegisterForUsersView) => {
    if (user.userType === "Student") {
      return `${user.tutorGroupName} | ${user.yearGroupName}`;
    }
    return "";
  };

  const getAttendance = (signUpStatusId: number, attendanceId: number, attendanceName: string) => {
    if (signUpStatusId === SIGN_UP_STATUS.CANCELLED) {
      return (
        <Icon
          value="ban"
          color={neutral[800]}
          size={Size.Medium}
          tooltip="Sign Up Cancelled"
        />
      );
    }

    switch (attendanceId) {
      case EVENT_ATTENDANCE.PENDING:
        return (
          <Icon value="clock" size={Size.Medium} tooltip={attendanceName} />
        );

      case EVENT_ATTENDANCE.ATTENDED:
        return (
          <Icon
            value="check"
            color={success}
            size={Size.Medium}
            tooltip={attendanceName}
          />
        );

      case EVENT_ATTENDANCE.MISSED:
        return (
          <Icon
            value="times"
            color={danger}
            size={Size.Medium}
            tooltip={attendanceName}
          />
        );

      default:
        break;
    }
  };

  // if (loading) {
  //   return <Loader size={Size.Medium} cover />;
  // }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return (
    <AttendeeTable
      attendees={eventAttendanceReport.items}
      onPage={handlePage}
      paging={eventAttendanceReport.paging}
      loading={loading}
      error={error}
      getSub={getSub}
      getAttendance={getAttendance}
      handleExport={handleExport}
      exportLoading={_exportLoading}
    />
  );
};


interface IAttendeeTableProps {
  attendees: EventRegisterForUsersView[];
  onPage?: () => void;
  paging?: PageInfo;
  loading?: boolean;
  error?: IBeehiveError;
  getSub?: (user: EventRegisterForUsersView) => string;
  getAttendance?: (signUpStatusId: number, attendanceId: number, attendanceName: string) => JSX.Element;
  handleExport?: () => void;
  exportLoading?: boolean;
}


const AttendeeTable: React.FC<IAttendeeTableProps> = ({
  attendees,
  onPage,
  paging,
  loading,
  error,
  getSub,
  getAttendance,
  handleExport,
  exportLoading,
}) => {
  return (
    <>
      <HelpText>
        These are the attendees for the event across all dates. To view
        attendees for each date, view the register for the date in Dates &amp;
        Registers.
      </HelpText>

      <Table
        grow
        zebra
        error={error != null}
        empty={arrays.isEmpty(attendees)}
        emptyMessage={
          <EmptyMessage
            icon="envelope"
            title="No Attendees"
            summary="No attendees for this event have been found."
            cover
          />
        }
      >
        <Table.Header>
          <Table.HeaderCell width={2}>Student</Table.HeaderCell>
          <Table.HeaderCell width={1}>Additional Info</Table.HeaderCell>
          <>
            {attendees[0]?.attendances.map(attendance => (
              <Table.HeaderCell width={1} center>
                {formatDate(attendance.starts, attendance.ends, true)}
              </Table.HeaderCell>
            ))}
          </>
          <Table.HeaderCell width={1} right>
            {"Attendance (%)"}
          </Table.HeaderCell>
        </Table.Header>

        <Table.Body onPage={onPage} paging={paging}>
          {attendees.map(student => (
            <Table.Row>
              <Table.Cell width={2}>
                <Avatar user={student} sub={getSub(student)} />
              </Table.Cell>
              <Table.Cell width={1}>
                {student.userType === "Student" && (
                  <ChipList>
                    <StudentSensitiveDataChip.SENChip student={student} compact={false} />
                    <StudentSensitiveDataChip.PPChip student={student} compact={false} />
                    <StudentSensitiveDataChip.EALChip student={student} compact={false} />
                  </ChipList>
                )}
              </Table.Cell>
              <>
              {student.attendances.map(attendance => (
                <Table.Cell width={1} center>
                  {getAttendance(
                    attendance.signUpStatusId,
                    attendance.attendanceId,
                    attendance.attendanceName
                  )}
                </Table.Cell>
              ))}
              </>
              <Table.Cell width={1} right>
                <Text>{student.attendedDatesPercentage.toFixed(2)}</Text>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Cell colspan={5 + attendees[0]?.attendances?.length} right>
            <ActionBar low>
              <Right>
                <Button
                  icon="file-download"
                  text="Export Register"
                  onClick={handleExport}
                  // color={Swatches.Success}
                  size={Size.Small}
                  working={loading || exportLoading}
                />
              </Right>
            </ActionBar>
          </Table.Cell>
        </Table.Footer>
      </Table>
    </>
  );
};

export default EventAttendees;
