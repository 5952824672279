import { BeehiveReport, IBeehiveAction } from "types/common/action.types";
import calendarActions from "../actions/calendarActions";
import { PagedQueryView } from "types/common/views.types";
import { EventTotalSignupsReportListView } from "../types/calendarResponses.types";


interface IEventReportingState {
  data: BeehiveReport | null;
  totalSignupsDisplay: PagedQueryView<EventTotalSignupsReportListView> | null;
  loading: boolean;
  error: string | null;
}

const INITIAL_STATE: IEventReportingState = {
  data: null,
  totalSignupsDisplay: null,
  loading: false,
  error: null,
};

const eventReportingReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IEventReportingState => {
  const {
    CALENDAR_EVENTATTENDANCEREPORT,
    CALENDAR_EXPORTEVENTDATEMENUREPORT,
    CALENDAR_EXPORTEVENTDATEMENUTOTALSREPORT,
    CALENDAR_EVENTPPSTATUSEREPORT,
    CALENDAR_TOTALSIGNUPSREPORT,
    CALENDAR_TOTALSIGNUPSREPORT_DISPLAY
  } = calendarActions.types;

  switch (action.type) {
    case CALENDAR_EVENTATTENDANCEREPORT.START:
    case CALENDAR_EXPORTEVENTDATEMENUREPORT.START:
    case CALENDAR_EXPORTEVENTDATEMENUTOTALSREPORT.START:
    case CALENDAR_EVENTPPSTATUSEREPORT.START:
    case CALENDAR_TOTALSIGNUPSREPORT.START:
    case CALENDAR_TOTALSIGNUPSREPORT_DISPLAY.START:
      return { 
        ...state, 
        loading: true 
      };

    case CALENDAR_EVENTATTENDANCEREPORT.SUCCESS:
    case CALENDAR_EXPORTEVENTDATEMENUREPORT.SUCCESS:
    case CALENDAR_EXPORTEVENTDATEMENUTOTALSREPORT.SUCCESS:
    case CALENDAR_EVENTPPSTATUSEREPORT.SUCCESS:
    case CALENDAR_TOTALSIGNUPSREPORT.SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case CALENDAR_TOTALSIGNUPSREPORT_DISPLAY.SUCCESS:
      return {
        ...state,
        totalSignupsDisplay: state.totalSignupsDisplay == null
          ? action.payload
          : {
            items: action.payload.paging.pageIndex === 0
              ? action.payload.items
              : [...state.totalSignupsDisplay.items, ...action.payload.items],
            paging: action.payload.paging,
            unread: action.payload.unread
          }
        ,
        loading: false
      }

    case CALENDAR_EVENTATTENDANCEREPORT.FAILED:
    case CALENDAR_EXPORTEVENTDATEMENUREPORT.FAILED:
    case CALENDAR_EXPORTEVENTDATEMENUTOTALSREPORT.FAILED:
    case CALENDAR_EVENTPPSTATUSEREPORT.FAILED:
    case CALENDAR_TOTALSIGNUPSREPORT.FAILED:
    case CALENDAR_TOTALSIGNUPSREPORT_DISPLAY.FAILED:
      return { 
        ...state, 
        loading: false, 
        error: action.payload 
      };

    default:
      return state;
  }
};

export default eventReportingReducer;
