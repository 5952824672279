import React, { useState } from "react";
import EditAnimalAvatarModal from "./editAnimalAvatarModal";
import { useSelector } from "react-redux";
import studentProfileActions from "areas/planner/actions/student/studentProfileActions";

const EditAnimalAvatarWrapper = ({ student, open, setOpen }) => {
  const [selectedAvatarId, setSelectedAvatarId] = useState(
    student.primaryAvatarId
  );

  const { loading, error } = useSelector(state => state.studentProfile);

  const onClickSaveAvatar = () => {
    const onSuccess = () => {
      setOpen(false);
    };

    studentProfileActions.updateAvatar(
      student.id,
      {
        studentId: student.id,
        primaryAvatarId: selectedAvatarId,
      },
      onSuccess
    );
  };

  return (
    <EditAnimalAvatarModal
      open={open}
      setOpen={setOpen}
      selectedAvatarId={selectedAvatarId}
      handleOnChangeAvatar={avatarId => setSelectedAvatarId(avatarId)}
      handleOnClickSaveAvatar={onClickSaveAvatar}
      loading={loading}
      error={error}
    />
  );
};

export default EditAnimalAvatarWrapper;
