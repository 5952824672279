import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { BeehiveReport, callbackType } from "types/common/action.types";
import { FormsReportFilter } from "../types/formRequest.types";
import { urls } from "utils";

const builder = new actionTypeBuilder("formreporting");

const types = {
  EXPORT_DECLARATIONSOFINTEREST: builder.build("EXPORT_DECLARATIONSOFINTEREST"),
};


const exportDeclarationsOfInterest = (filter: FormsReportFilter, callback?: callbackType<BeehiveReport>) => {
  var url = new urls.QueryString(`forms/reporting/declarationsOfInterest/export`);
  url.addParams(filter);
  return action<BeehiveReport>(
    () => client.get(url.toUrl()), 
    types.EXPORT_DECLARATIONSOFINTEREST, 
    callback
  );
}


const formReportingActions = {
  types,
  exportDeclarationsOfInterest,
};

export default formReportingActions;
