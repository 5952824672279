import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { PassportsOverviewView, SenAdminOverviewView } from "areas/send/types/senAdminResponse.types";
import { PublishStatus } from "types/common/status.types";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { BulkChangePassportStatusCommand } from "areas/send/types/senAdminRequest.types";

const builder = new actionTypeBuilder("send");

const types = {
  ADMIN_OVERVIEW_GET: builder.build("ADMIN_OVERVIEW_GET"),
  ADMIN_PASSPORTSTATUSCHANGE: builder.build("ADMIN_PASSPORTSTATUSCHANGE"),
};


const getAdminOverview = (schoolId: number, callback?: callbackType<SenAdminOverviewView>) =>
  action<SenAdminOverviewView>(
    () => client.get(`planner/send/adminOverview?schoolId=${schoolId}`), 
    types.ADMIN_OVERVIEW_GET, 
    callback
  );

const changeSchoolPassportsStatus = (data: BulkChangePassportStatusCommand, callback?: callbackType<PassportsOverviewView>) =>
  action<PassportsOverviewView>(
    () => client.post(`planner/send/bulkChangePassportStatus`, data), 
    types.ADMIN_PASSPORTSTATUSCHANGE, 
    callback
  );

  
const senAdminActions = {
  types,
  getAdminOverview,
  changeSchoolPassportsStatus
};

export default senAdminActions;
