import React, { useState } from "react";
import { arrays } from "utils";
import {
  Size,
  EmptyMessage,
  Table,
  DateTime,
  DetailLabel,
  Icon,
  Swatches,
  neutral,
} from "ui-kit";
import { SchoolIcon, Avatar, ApprovalStatusChip } from "sharedComponents/common";
import { Constants } from "configuration";
import LeaveRequestModal from "areas/humanResources/components/leave/leaveRequestModal";
import SubmissionModal from "areas/forms/components/submissions/submissionModal";
import FormEditorModal from "areas/forms/components/formEditorModal";
import { PageInfo } from "types/common/paging.types";
import { TrayListView } from "../types/fileResponse.types";
import { ApprovalStatus } from "areas/humanResources/types/leaveShared.types";


interface IFilingTableProps {
  items: TrayListView[];
  onRowClick?: () => void;
  error?: string;
  loading?: boolean;
  caption?: string;
  paging?: PageInfo;
  onPage?: () => void;
}

const FilingTable: React.FC<IFilingTableProps> = ({
  items,
  onRowClick,
  error,
  loading,
  caption,
  paging,
  onPage,
}) => {

  const [selectedRequest, setSelectedRequest] = useState<TrayListView | null>(null);
  const [selectedSubmission, setSelectedSubmission] = useState<TrayListView | null>(null);
  const [selectedDraft, setSelectedDraft] = useState<TrayListView | null>(null);

  const handleRowClick = (row: TrayListView) => {
    switch (row.type) {
      case 0:
        row.status === ApprovalStatus.Draft
          ? setSelectedDraft(row)
          : setSelectedSubmission(row);
        break;
      case 1:
        setSelectedRequest(row);
        break;
      default:
        break;
    }
    //onRowClick?.(row);
  };

  const handleLeaveRequestModalClose = () => {
    setSelectedRequest(null);
  };

  const handleSubmissionModalClose = () => {
    setSelectedSubmission(null);
  };

  const handleDraftModalClose = () => {
    setSelectedDraft(null);
  };

  const TrayIcon = ({ item } : { item: TrayListView }) => {
    var swatch =
      item.approverReadDate != null ? neutral[500] : Swatches.Blue.swatch;

    switch (item.type) {
      case 0:
        return <Icon value="clipboard" color={swatch} />;
      case 1:
        return <Icon value="plane" color={swatch} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Table
        zebra
        caption={caption}
        error={error != null}
        loading={loading}
        empty={arrays.isEmpty(items)}
        emptyMessage={
          <EmptyMessage icon="inbox" title="Your tray is empty" cover />
        }
        grow
      >
        <Table.Header>
          <Table.HeaderCell width={0.05}></Table.HeaderCell>
          <Table.HeaderCell width={0.1}></Table.HeaderCell>
          <Table.HeaderCell width={2}>Form</Table.HeaderCell>
          <Table.HeaderCell width={1.8}>From</Table.HeaderCell>
          <Table.HeaderCell width={1}>Date</Table.HeaderCell>
          <Table.HeaderCell width={0.5}>No.</Table.HeaderCell>
          <Table.HeaderCell width={1.5}>Ref</Table.HeaderCell>
          <Table.HeaderCell right width={1}>
            Status
          </Table.HeaderCell>
        </Table.Header>
        <Table.Body paging={paging} onPage={onPage}>
          {items.map((item: TrayListView, index: number) => (
            <Table.Row key={index} onClick={() => handleRowClick(item)}>
              <Table.Cell width={0.05}>
                <TrayIcon item={item} />
              </Table.Cell>
              <Table.Cell width={0.1}>
                <SchoolIcon code={item.school.code} />
              </Table.Cell>
              <Table.Cell width={2}>
                <DetailLabel
                  bold
                  label={item.formName}
                  sub={item.formCategoryName}
                />
              </Table.Cell>
              <Table.Cell width={1.8}>
                <Avatar user={item.staffMember} size={Size.Small} />
              </Table.Cell>
              <Table.Cell width={1}>
                <DateTime bold date={item.createdDate} />
              </Table.Cell>

              <Table.Cell width={0.5}>{item.id}</Table.Cell>
              <Table.Cell width={1.5}>
                <DetailLabel bold label={item.ref} sub={item.formName} />
              </Table.Cell>
              <Table.Cell right width={1}>
                <ApprovalStatusChip status={item.status} fluid />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <LeaveRequestModal
        leaveRequest={selectedRequest}
        onClose={handleLeaveRequestModalClose}
        open={selectedRequest != null}
      />
      <SubmissionModal
        submission={selectedSubmission}
        onClose={handleSubmissionModalClose}
        open={selectedSubmission != null}
      />
      <FormEditorModal
        form={null}
        submission={selectedDraft}
        open={selectedDraft != null}
        onClose={handleDraftModalClose}
      />
    </>
  );
};

export default FilingTable;
