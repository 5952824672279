import React from "react";
import { ReactComponent as BearSvg } from "assets/primary/rockets/bear.svg";
import { IPrimaryRocketCharacterProps } from "./primaryRocket";


export const Bear: React.FC<IPrimaryRocketCharacterProps> = ({ className }) => {

  return <BearSvg className={className} />;
};

export default Bear;
