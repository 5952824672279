import React from "react";
import { ActionBar, Left, Checkbox, Dropdown } from "ui-kit";
import AcademicYearFilter from "sharedComponents/common/academicYearFilter";


interface IDetentionListFiltersProps {
  showCancelledDetentions: boolean;
  handleShowCancelledDetentionsChange: () => void;
  academicYearId: number;
  handleAcademicYearChange: (value: number, label: string, data?: any) => void;
}


const DetentionListFilters: React.FC<IDetentionListFiltersProps> = ({
  showCancelledDetentions,
  handleShowCancelledDetentionsChange,
  academicYearId,
  handleAcademicYearChange,
}) => {

  return (
    <ActionBar>
      <Left>
        <ActionBar.Filter>
          <ActionBar.FilterItem>
            <AcademicYearFilter
              value={academicYearId}
              onChange={handleAcademicYearChange}
            />
          </ActionBar.FilterItem>
          <ActionBar.FilterItem>
            <Checkbox
              text="Show Cancelled Detentions"
              checked={showCancelledDetentions}
              onChange={handleShowCancelledDetentionsChange}
            />
          </ActionBar.FilterItem>
        </ActionBar.Filter>
      </Left>
    </ActionBar>
  );
};

export default DetentionListFilters;
