/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import lodashDebounce from "lodash.debounce";
import styled from "styled-components";
import {
  Icon,
  Size,
  displayFont,
  textFont,
  Swatches,
  neutral,
  Spacing,
  BorderRadius,
  typescale,
  honeygold,
  formatScrollbars,
  Speed,
  Spinner,
  fontStyle,
  DetailLabel,
} from "../index";
import { isEqual } from "lodash";
import uiKit from "../../utils/uiKit";

export type DropdownLabelTypes = string | number | JSX.Element;
export type DropdownValueTypes = number | boolean | string | {};

type returnLabel<T> = T extends null ? any : T;
type returnValue<T> = T extends null ? any : T;
type returnData<T> = T extends null ? any : T;

//TODO Remove the anys and get generics to work
export interface IDropdownProps<TLabel, TValue, TData> {
  children?: React.ReactElement<IDropdownItemProps>[] | React.ReactElement<IDropdownItemProps>;
  placeholder?: string;
  searchPlaceholder?: string;
  onChange?: (
    value: returnValue<TValue>,
    label: returnLabel<TLabel>,
    data?: returnData<TData>
  ) => void;
  fluid?: boolean;
  icon?: string;
  name?: string;
  value?: number | string | boolean | null | {};
  items?: IDropdownDataItemProps<TLabel, TValue, TData>[];
  disabled?: boolean;
  className?: string;
  error?: string;
  loading?: boolean;
  autocomplete?: boolean;
  invalid?: boolean;
  clearable?: boolean;
  onSearchChange?: (value: string) => void;
}

export interface IDropdownDataItemProps<
  TLabel = any,
  TValue = any,
  TData = any
> {
  label: TLabel;
  value: TValue;
  data?: TData;
  sub?: string;
  icon?: string;
  image?: React.ReactElement;
}

export interface IDropdownItemProps extends IDropdownDataItemProps {
  selected?: boolean;
  onClick?: (item: IDropdownDataItemProps) => void;
  children?: (JSX.Element | string)[] | JSX.Element | string;
}

const Wrapper = styled.div<{
  fluid?: boolean;
  disabled?: boolean;
  searchModeEnabled: boolean;
  invalid?: boolean;
  isOpen: boolean;
  name?: string;
  autocomplete?: boolean;
}>`
  position: relative;
  display: ${props => (props.fluid ? "flex" : "inline-flex")};
  // flex: ${props => (props.fluid ? "1 1" : "0 0")};
  flex-direction: column;
  user-select: none;

  ${fontStyle(displayFont.medium, typescale.paragraph, neutral[600])}

  cursor: pointer;
  min-width: 10rem;

  ${({ disabled }) => disabled && `opacity: 0.75; cursor: not-allowed;`}

  .dropdown-header {
    display: ${props => (props.fluid ? "flex" : "inline-flex")};
    align-items: center;
    background: ${neutral[200]};
    color: ${Swatches.Low.foreground};
    border-radius: ${BorderRadius.Default}px;
    padding: ${Spacing.Default}px;
    margin: 0;
    flex-basis: 0;
    flex-grow: 1;
    // margin-bottom: 1px;

    .icon {
      margin: 0;
    }

    .dropdown-loading {
      height: 1rem;
      padding: 0;
      display: inline-flex;
      align-items: center;

      .spinner {
        margin-top: 4px;
      }
    }

    .dropdown-header-title {
      margin-right: auto;

      ${({ searchModeEnabled }) =>
        searchModeEnabled ? `display: none;` : `display: block;`}
    }

    .dropdown-icon {
      margin-right: ${Spacing.Default}px;
      color: ${Swatches.Low.foreground};
    }

    .dropdown-clear {
      margin-left: ${Spacing.Medium}px;
      margin-right: ${Spacing.Small}px;
      color: ${Swatches.Low.foreground};

      border: 0;
      padding: 0;

      i {
        transition: transform ${Speed.Short}ms ease;
      }

      &:hover {
        cursor: pointer;
        i {
          color: ${Swatches.Danger.swatch} !important;
        }
      }
    }

    .dropdown-caret {
      transition: transform ${Speed.Short}ms ease;
      margin-left: ${Spacing.Default}px;
    }

    ${({ invalid }) =>
      invalid &&
      `
      background: ${Swatches.Danger.swatch};
      color: ${neutral[100]};
    `}

    ${({ isOpen }) =>
      isOpen &&
      `border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.2);
      background: ${neutral[600]};
      color: ${neutral[100]};

      i, icon, .icon:first-child {
        color: ${neutral[100]};
      }

      .dropdown-caret {
        transform: rotate(180deg);
      }
      
      .dropdown-clear {
        visibility: hidden;
      }
    `}
  }
`;

const SearchBoxWrapper = styled.div<{ enabled: boolean }>`
  ${({ enabled }) => (enabled ? `display: block;` : `display: none;`)}

  flex-grow: 1;

  input {
    flex-grow: 1;
    border: 0;
    background: none;
    ${fontStyle(textFont.roman, typescale.paragraph, neutral[100])}
  }
`;

const DropdownBodyWrapper = styled.div<{ isOpen: boolean; top: boolean }>`
  display: none;
  flex-basis: 0;
  background: ${neutral[100]};
  color: ${neutral[600]};
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  overflow-y: auto;

  ${formatScrollbars(neutral[100])}

  ${({ isOpen, top }) =>
    isOpen &&
    `
    display: block;
    position: absolute;
    width: 100%;
    z-index: 999;
    ${top ? "bottom: 100%;" : "top: 100%;"}
    max-height: 10rem;
    overflow: auto;
  `}

  .dropdown-error {
    display: flex;
    flex-direction: column;
    padding: ${Spacing.Large}px;
    text-align: center;
    align-items: center;

    span {
      ${fontStyle(displayFont.medium, typescale.paragraph, neutral[600])}
    }
    .icon {
      font-size: 24px;
      display: inline;
      color: ${Swatches.Danger.swatch};
      text-align: center;
      margin-bottom: ${Spacing.Default}px;
    }
  }
`;

type LabelTypes = string | number | JSX.Element;

const Dropdown = <
  TLabel extends LabelTypes = null,
  TValue = null,
  TData = null
>({
  children,
  placeholder = "Please choose an option...",
  onChange,
  fluid,
  icon,
  value,
  items,
  disabled,
  name,
  className,
  error,
  loading,
  onSearchChange,
  autocomplete,
  invalid,
  searchPlaceholder,
  clearable,
}: IDropdownProps<TLabel, TValue, TData>) => {
  const [isOpen, setOpen] = useState(false);
  const [title, setTitle] = useState(placeholder);
  const [searchModeEnabled, setSearchModeEnabled] = useState(false);

  const [selectedValue, setSelectedValue] = useState<
    string | boolean | number | {} | null
  >(null);

  const [selectedLabel, setSelectedLabel] = useState<
    string | number | JSX.Element | null
  >(null);

  const [bottomSpace, setBottomSpace] = useState(0);

  const inputRef = useRef<HTMLInputElement | null>(null);
  // const listAnimation = useSpring({
  //   from: { opacity: 0, transform: `translateY(-200%)` },
  //   to: { opacity: 1, transform: `translateY(0)` }
  // });

  const debouncedSearchChange = useCallback(
    lodashDebounce((value: string) => {
      onSearchChange?.(value);
    }, 500),
    []
  );

  useEffect(() => {
    setTitle(placeholder);
  }, [placeholder]);

  useEffect(() => {
    var selectedItem = items?.find(x => isEqual(x.value, value));
    var selectedChild = Array.isArray(children) 
      ? children?.find(x => isEqual(x.props.value, value))
      : children?.props.value == value && children;

    var label = selectedItem
      ? selectedItem.label
      : selectedChild
      ? selectedChild?.props.label
      : "";

    setSelectedValue(value as number | string | boolean | {} | null);
    setSelectedLabel(label as string | null);
  }, [value, items, children]);

  useEffect(() => {
    document.addEventListener("click", handleClick); // add when mounted
    return () => {
      document.removeEventListener("click", handleClick); // return function to be called when unmounted
    };
  }, []);

  // useEffect(() => {
  //   if (autocomplete && (items || error)) {
  //     // setOpen(true);
  //   }
  // }, [items, error]);

  const wrapperRef = useRef() as React.RefObject<HTMLDivElement>;

  const handleClick = (e: MouseEvent) => {
    if (wrapperRef && !wrapperRef?.current?.contains(e.target as Node)) {
      setOpen(false);
      setSearchModeEnabled(false);
    }
  };

  const mapItems = () => {
    return children
      ? Array.isArray(children) 
        ? children?.map((child, index) => {
          return React.cloneElement(
            child as React.ReactElement<IDropdownItemProps>,
            {
              key: index,
              selected: child.props.value === selectedValue,
              onClick: itemSelected,
            }
          );
        })
        : React.cloneElement(children as React.ReactElement<IDropdownItemProps>,
          {
            key: 1,
            selected: children.props.value === selectedValue,
            onClick: itemSelected,
          }
        )
      : items?.map((item, index) => (
          <Dropdown.Item
            key={index}
            icon={item.icon}
            onClick={itemSelected}
            label={item.label}
            sub={item.sub}
            value={item.value}
            data={item.data}
            selected={isEqual(selectedValue, item.value)}
          />
        ));
  };

  const itemSelected = (item: IDropdownDataItemProps) => {
    setOpen(false);
    setSearchModeEnabled(false);
    setSelectedValue(item.value);
    setSelectedLabel(item.label);
    onChange?.(item.value, item.label, item.data);
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    debouncedSearchChange(e.target.value);
  };

  const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(false);
    setSearchModeEnabled(false);
    setSelectedValue(null);
    setSelectedLabel(null);
    onChange?.(null, null, null);
  };

  const handleHeaderClick = () => {
    if (!disabled) {
      setOpen(!isOpen);
    }

    autocomplete && setSearchModeEnabled(true);
  };

  useEffect(() => {
    inputRef?.current?.focus();
  }, [searchModeEnabled]);

  return (
    <>
      <Wrapper
        className={`dropdown form-control ${className ? className : ""}`}
        ref={wrapperRef}
        fluid={fluid}
        isOpen={isOpen}
        name={name}
        disabled={disabled}
        autocomplete={autocomplete}
        invalid={invalid}
        searchModeEnabled={searchModeEnabled}
      >
        <div
          className={`dropdown-header`}
          onClick={handleHeaderClick}
          ref={el => {
            const space = uiKit.getElementBottomSpace(el);
            bottomSpace !== space && setBottomSpace(space);
          }}
        >
          {icon && (
            <Icon className="dropdown-icon" value={icon} size={Size.Small} />
          )}

          <span className={`dropdown-header-title`}>
            {selectedLabel ? selectedLabel : title}
          </span>

          {autocomplete && (
            <SearchBoxWrapper enabled={searchModeEnabled}>
              <input
                type={"text"}
                onChange={handleSearchChange}
                ref={inputRef}
                placeholder={searchPlaceholder}
              />
            </SearchBoxWrapper>
          )}

          {loading && (
            <span className="dropdown-loading">
              <Spinner />
            </span>
          )}

          {clearable && selectedValue !== null && (
            <button className="dropdown-clear" onClick={e => handleClear(e)}>
              <Icon value="times" size={Size.Small} />
            </button>
          )}

          <Icon
            className="dropdown-caret"
            value="caret-down"
            size={Size.Small}
          />
        </div>

        <DropdownBodyWrapper
          className="dropdown-body"
          isOpen={isOpen}
          top={bottomSpace < 160}
        >
          {error ? (
            <div className="dropdown-error">
              <Icon value="times-circle" />
              <span>{error}</span>
            </div>
          ) : (
            mapItems()
          )}
        </DropdownBodyWrapper>
      </Wrapper>
    </>
  );
};

const ItemWrapper = styled.div<{ selected?: boolean }>`
  padding: ${Spacing.Default}px;
  border-bottom: 1px solid ${neutral[200]};
  display: flex;
  align-items: center;

  ${fontStyle(textFont.roman, typescale.paragraph)}
  color: ${neutral[600]};
  i {
    color: ${neutral[600]};
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom-left-radius: ${BorderRadius.Default}px;
    border-bottom-right-radius: ${BorderRadius.Default}px;
  }

  &:hover {
    color: ${neutral[100]};
    i,
    span {
      color: ${neutral[100]};
    }
    background: ${neutral[600]};
  }

  ${({ selected }) =>
    selected &&
    `background: ${honeygold}; 
    color: ${neutral[100]}; 
    i, span { 
      color: ${neutral[100]};
    }`}

  i, svg {
    margin-right: ${Spacing.Default}px;
  }

  .dropdown-item-label span {
    display: block;
  }

  .dropdown-item-name {
    margin-bottom: ${Spacing.Small}px;
  }
  .dropdown-item-name {
    margin-bottom: ${Spacing.Small}px;
  }
`;

const DropdownItem: React.FC<IDropdownItemProps> = props => {
  var { selected, icon, label, sub, onClick, image } = props;

  const handleClick = () => {
    onClick?.(props);
  };

  return (
    <ItemWrapper selected={selected} onClick={handleClick}>
      {image ? image : icon && <Icon value={icon} />}
      <DetailLabel className="dropdown-item-label" label={label} sub={sub} />
    </ItemWrapper>
  );
};

Dropdown.Item = DropdownItem;

export default Dropdown;
