import { SubmitLeaveRequestCommand } from "areas/humanResources/types/leaveRequest.types";
import { HolidayCardView } from "areas/humanResources/types/leaveResponse.types";
import { CoverArrangement, LeaveRequestType } from "areas/humanResources/types/leaveShared.types";
import React, { useState, useEffect } from "react";
import { useAppSelector } from "reducers/hooks";
import { ActionBar, Button, Card, EmptyMessage, Left, Message, Modal, RichTextEditor, Right, Size, StructuredList, Swatches, TextInput, ToastService, ValidationMessage } from "ui-kit";
import LeaveRequestTypeDropdown from "../leaveRequestTypeDropdown";
import LeaveHours from "./leaveHours";
import UnpaidLeaveSelector from "./unpaidLeaveSelector";
import CoverRequirementDropdown from "../coverRequirementDropdown";
import { StaffSelector } from "sharedComponents/common";
import { UserListView } from "types/users/userListViews.types";
import { arrays, dateTime } from "utils";
import specialDayActions from "areas/planner/actions/specialDayActions";
import config from "configuration";
import moment from "moment";
import leaveHelpers from "areas/humanResources/utils/leaveHelpers";
import leaveActions from "areas/humanResources/actions/leaveActions";


export interface SubmitLeaveRequestCommandExt extends SubmitLeaveRequestCommand {
  authoriser: UserListView;
  unpaid: boolean;
  goldenHours: number;
}

interface IRequestLeaveModalProps {
  holidayCard: HolidayCardView;
  onRequestSubmitted?: (request: SubmitLeaveRequestCommandExt) => void;
  requestType: LeaveRequestType;
  open: boolean;
  onClose: () => void;
}


const RequestLeaveModal: React.FC<IRequestLeaveModalProps> = ({
  holidayCard,
  onRequestSubmitted,
  requestType,
  open,
  onClose
}) => {

  const { user } = useAppSelector(state => state.currentUser);
  const { working, error } = useAppSelector(state => state.leaveRequest);
  const { specialDays, loading: specialDaysLoading, error: specialDaysError } = useAppSelector(state => state.specialDays);

  const [_open, _setOpen] = useState<boolean>(false);
  const [request, setRequest] = useState<SubmitLeaveRequestCommandExt>();
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    if (arrays.isEmpty(specialDays)) {
      specialDayActions.getSpecialDaysForYear(config.academicYear.current);
    }
  }, [specialDays]);

  useEffect(() => {
    if (holidayCard) {
      setRequest(leaveHelpers.updateRequestType(holidayCard, requestType));
    }
  }, [holidayCard, requestType]);

  useEffect(() => {
    setRequest(leaveHelpers.updateRequestType(holidayCard, request?.leaveRequestType, request));
  }, [request?.leaveRequestType]);

  useEffect(() => {
    if (request?.startDate && request?.endDate) {
      setRequest(leaveHelpers.sanitiseDateTimesAndCalculateHours(request, specialDays));
    }
  }, [request?.startDate, request?.endDate, specialDays])

  const handleSave = () => {
    var errors: string[] = [];
    if (request.coverRequirement == null && !request.notes?.length) {
      errors.push("Please give details of the cover arranged or required.");
    }
    if (request.unpaid && request.unpaidHours === 0) {
      errors.push("Please enter the number of day(s) that should be unpaid.");
    }
    if (request.totalHours < request.unpaidHours) {
      errors.push("The amount of unpaid time must be less than the total time requested.");
    }
    if (request.leaveRequestType === LeaveRequestType.Holiday && request.totalHours < 4) {
      errors.push("Holiday leave must be at least 4 hours.");
    }
    setValidationErrors(errors);
    if (!arrays.isEmpty(errors)) {
      return;
    }
    leaveActions.submitLeaveRequest(user.id, request, () => {
      onClose?.();
      ToastService.pop("Leave Request Submitted", null, "plane-departure");
      onRequestSubmitted?.(request);
    });
  }

  return (
    <>
      <Modal
        title="Request Leave"
        open={_open}
        onClose={onClose}
        width="75%"
        height="95%"
      >
        <Modal.Body scrollOnErrors={validationErrors}>
          <ValidationMessage errors={validationErrors} />
          <Message text={error} color={Swatches.Danger} />
          <Card>
            <Card.Body>
              <StructuredList>

                <StructuredList.Item
                  required
                  name="Leave Type"
                  description="Please choose the type of leave you are requesting."
                >
                  <LeaveRequestTypeDropdown
                    value={request?.leaveRequestType}
                    onChange={val => setRequest({ ...request, leaveRequestType: val })}
                    fluid
                  />
                </StructuredList.Item>

                {(request?.leaveRequestType !== LeaveRequestType.GoldenTime || holidayCard.goldenTimeRemaining > 0) && (
                  <>
                    <LeaveHours
                      holidayCard={holidayCard}
                      request={request}
                      setRequest={setRequest}
                    />

                    <UnpaidLeaveSelector
                      request={request}
                      setRequest={setRequest}
                    />

                    <StructuredList.Item
                      name="Cover Required"
                      required
                      description="Please indicate if cover is required for this leave. If cover has already been arranged please enter it in the notes section below."
                    >
                      <CoverRequirementDropdown
                        value={request?.coverRequirement}
                        onChange={val => setRequest({ ...request, coverRequirement: val })}
                        fluid
                      />
                    </StructuredList.Item>
                    
                    <StructuredList.Item
                      name="Your Reference"
                      description="You can add an optional reference for your own records."
                    >
                      <TextInput
                        value={request?.userRef}
                        onChange={value => setRequest({ ...request, userRef: value })}
                        characterCount
                        maxLength={50}
                        fluid
                      />
                    </StructuredList.Item>

                    <StructuredList.Item
                      name="Authoriser"
                      required
                      description="This is the staff member that needs to approve this leave request. Normally this is your line manager."
                    >
                      <StaffSelector
                        user={request?.authoriser}
                        onChange={user => setRequest({ ...request, authoriser: user })}
                      />
                    </StructuredList.Item>

                    <StructuredList.Item
                      name="Notes"
                      description="Please add any additional information needed for this holiday request, including any cover arrangements."
                      required={request?.coverRequirement !== CoverArrangement.None}
                    >
                      <RichTextEditor
                        onChange={value => setRequest({ ...request, notes: value })}
                      />
                    </StructuredList.Item>
                  </>
                )}

              </StructuredList>

              {request?.leaveRequestType === LeaveRequestType.GoldenTime && holidayCard.goldenTimeRemaining === 0 && (
                <EmptyMessage
                  icon="plane"
                  title="No Golden Time Remaining"
                  summary="You do not have any golden time hours remaining to use"
                  cover
                />
              )}

            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <ActionBar low>
            <Left>
              <Button
                text="Cancel"
                onClick={onClose}
                color={Swatches.Low}
                working={working}
                size={Size.Small}
              />
            </Left>
            <Right>
              <Button
                text="Submit Leave Request"
                onClick={handleSave}
                color={Swatches.Success}
                working={working}
                size={Size.Small}
              />
            </Right>
          </ActionBar>
        </Modal.Footer>

      </Modal>
    </>
  );
}


export default RequestLeaveModal;