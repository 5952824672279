import defaults from "reducers/defaults";
import { FEED_TYPE_KEY } from "areas/planner/constants/activityFeed";
import groupActions from "../../actions/group/groupActions";
import studentNoteActions from "../../actions/student/studentNoteActions";
import studentQuizActions from "../../actions/student/studentQuizActions";
import studentAwardActions from "../../actions/student/studentAwardActions";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import studentLeavingLessonActions from "../../actions/student/studentLeavingLessonActions";
import onCallActions from "areas/behaviour/actions/onCall/onCallActions";
import { PageInfo } from "types/common/paging.types";
import { IBeehiveError } from "types/common/errors.types";
import { IBeehiveAction } from "types/common/action.types";
import { StudentFeedListView } from "types/planner/feedResponse.types";


interface IGroupActivityFeedState {
  groupActivity: StudentFeedListView[];
  feedTypeFilter: number;
  createdDateFilter: number;
  paging: PageInfo;
  refresh: boolean;
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IGroupActivityFeedState = {
  groupActivity: [],
  feedTypeFilter: FEED_TYPE_KEY.ALL,
  createdDateFilter: null,
  paging: defaults.DEFAULT_PAGING,
  refresh: false,
  loading: false,
  error: null,
};


const groupActivityFeedReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IGroupActivityFeedState => {

  const {
    GETGROUPACTIVITYFEED,
    SETGROUPFEEDTYPEFILTER,
    SETGROUPCREATEDDATEFILTER,
  } = groupActions.types;
  const { CREATESTUDENTNOTE, DELETESTUDENTNOTE } = studentNoteActions.types;
  const { SAVESTUDENTANSWER, DELETESTUDENTANSWER } = studentQuizActions.types;
  const { ADDSTUDENTAWARD, DELETESTUDENTAWARD } = studentAwardActions.types;
  const {
    ADDBEHAVIOUR,
    UPDATEBEHAVIOUR,
    REVOKEBEHAVIOUR,
    UPSCALEBEHAVIOUR,
    UPSCALEBEHAVIOURDETENTION,
    ADDBEHAVIOURFORMULTIPLE,
  } = behaviourActions.types;
  const { ADDREWARDS, UPDATEREWARD, REVOKEREWARD, ADDREWARDFORMULTIPLE } =
    rewardActions.types;
  const { CANCELDETENTION, MISSEDDETENTION, ATTENDEDDETENTION } =
    detentionActions.types;
  const { ADDLEAVINGLESSONRECORD, DELETELEAVINGLESSONRECORD } =
    studentLeavingLessonActions.types;
  const {
    CREATEONCALLREQUEST,
    RESOLVEONCALLREQUEST,
    CANCELONCALLREQUEST,
    UPDATEONCALLREQUEST,
  } = onCallActions.types;

  switch (action.type) {
    case GETGROUPACTIVITYFEED.START:
      return { ...state, loading: true, error: null };

    case GETGROUPACTIVITYFEED.SUCCESS:
      return {
        ...state,
        refresh: false,
        loading: false,
        groupActivity:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.groupActivity, ...action.payload.items],
        paging: action.payload.paging,
      };

    case GETGROUPACTIVITYFEED.FAILED:
      return {
        ...state,
        loading: false,
        refresh: false,
        error: action.payload,
      };

    case SETGROUPFEEDTYPEFILTER:
      return {
        ...state,
        feedTypeFilter: action.payload,
        groupActivity: [],
      };

    case SETGROUPCREATEDDATEFILTER:
      return {
        ...state,
        createdDateFilter: action.payload,
        groupActivity: [],
      };

    case CREATESTUDENTNOTE.SUCCESS:
    case DELETESTUDENTNOTE.SUCCESS:
    case SAVESTUDENTANSWER.SUCCESS:
    case DELETESTUDENTANSWER.SUCCESS:
    case ADDSTUDENTAWARD.SUCCESS:
    case DELETESTUDENTAWARD.SUCCESS:
    case ADDBEHAVIOUR.SUCCESS:
    case ADDREWARDS.SUCCESS:
    case REVOKEBEHAVIOUR.SUCCESS:
    case REVOKEREWARD.SUCCESS:
    case CANCELDETENTION.SUCCESS:
    case MISSEDDETENTION.SUCCESS:
    case ATTENDEDDETENTION.SUCCESS:
    case UPDATEBEHAVIOUR.SUCCESS:
    case UPDATEREWARD.SUCCESS:
    case ADDREWARDFORMULTIPLE.SUCCESS:
    case ADDBEHAVIOURFORMULTIPLE.SUCCESS:
    case UPSCALEBEHAVIOUR.SUCCESS:
    case UPSCALEBEHAVIOURDETENTION.SUCCESS:
    case ADDLEAVINGLESSONRECORD.SUCCESS:
    case DELETELEAVINGLESSONRECORD.SUCCESS:
    case CREATEONCALLREQUEST.SUCCESS:
    case CANCELONCALLREQUEST.SUCCESS:
    case RESOLVEONCALLREQUEST.SUCCESS:
    case UPDATEONCALLREQUEST.SUCCESS:
      return { ...state, refresh: true };

    default:
      return state;
  }
};

export default groupActivityFeedReducer;
