import React from "react";
import { DetailLabel, EmptyMessage, Loader, Size, Table } from "ui-kit";
import { formatDate, formatTime } from "utils/dateTime";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { useAppSelector } from "reducers/hooks";


interface IBehaviourDetailedReportTableProps {
  handlePage: () => void;
  loading?: boolean;
}

const BehaviourDetailedReportTable: React.FC<IBehaviourDetailedReportTableProps> = ({ handlePage, loading }) => {

  const { report, paging, error } = useAppSelector(
    state => state.behaviourDetailedReport
  );

  if (loading) {
    return <Loader size={Size.Large} fluid />;
  }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return !report ? null : report.length > 0 ? (
    <>
      <br />
      <Table grow loading={loading}>
        <Table.Header>
          <Table.HeaderCell width={1}>Student</Table.HeaderCell>
          <Table.HeaderCell width={0.4}>EAL</Table.HeaderCell>
          <Table.HeaderCell width={0.4}>PP</Table.HeaderCell>
          <Table.HeaderCell width={0.8}>SEN</Table.HeaderCell>
          <Table.HeaderCell width={2}>Behaviour</Table.HeaderCell>
          <Table.HeaderCell width={2}>Given By</Table.HeaderCell>
          <Table.HeaderCell width={1}>Subject</Table.HeaderCell>
          <Table.HeaderCell width={1}>Class/Group/Location</Table.HeaderCell>
        </Table.Header>

        <Table.Body onPage={handlePage} paging={paging}>
          {report.map(behaviour => (
            <Table.Row>
              <Table.Cell width={1}>
                <DetailLabel
                  label={`${behaviour.firstName} ${behaviour.lastName}`}
                  sub={`${behaviour.admissionNo} | ${behaviour.studentTutorGroup}`}
                  bold
                />
              </Table.Cell>
              <Table.Cell width={0.4}>{behaviour.eal ? "EAL" : ""}</Table.Cell>
              <Table.Cell width={0.4}>
                {behaviour.pupilPremium ? "PP" : ""}
              </Table.Cell>
              <Table.Cell width={0.8}>
                {behaviour.senStatus === "No Special Educational Need"
                  ? ""
                  : behaviour.senStatus}
              </Table.Cell>
              <Table.Cell width={2}>
                <DetailLabel
                  label={behaviour.behaviourCode}
                  sub={behaviour.categoryName}
                  bold
                />
              </Table.Cell>
              <Table.Cell width={2}>
                <DetailLabel
                  label={behaviour.awardedBy}
                  sub={`${formatDate(behaviour.awardedDate)} ${formatTime(
                    behaviour.awardedDate
                  )}`}
                  bold
                />
              </Table.Cell>
              <Table.Cell width={1}>{behaviour.subjectName}</Table.Cell>
              <Table.Cell width={1}>
                {behaviour.classGroupName ||
                  behaviour.cutomGroupName ||
                  behaviour.tutorGroupName ||
                  behaviour.otherLocation ||
                  null}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  ) : (
    <EmptyMessage title="No Behaviours Found" icon="list" />
  );
};

export default BehaviourDetailedReportTable;
