import { HolidayCardView } from "areas/humanResources/types/leaveResponse.types";
import { LeaveRequestType } from "areas/humanResources/types/leaveShared.types";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Checkbox, DatePicker, StructuredList, Sub, TextInput, TextInputType, TimePicker } from "ui-kit";
import GoldenTimeDropdown from "./goldenTimeDropdown";
import { dateTime, strings } from "utils";
import { SubmitLeaveRequestCommandExt } from ".";



interface ILeaveHoursProps {
  holidayCard: HolidayCardView;
  request: SubmitLeaveRequestCommandExt;
  setRequest: (value: React.SetStateAction<SubmitLeaveRequestCommandExt>) => void;
}


const LeaveHours: React.FC<ILeaveHoursProps> = ({ 
  holidayCard, 
  request, 
  setRequest
}) => {

  const [allDayStart, setAllDayStart] = useState<boolean>(true);
  const [allDayEnd, setAllDayEnd] = useState<boolean>(true);

  const handleAllDayStart = (ads: boolean) => {
    if (ads) {
      setRequest({
        ...request,
        startDate: moment(request.startDate)
          .hour(8)
          .minutes(0)
          .format("YYYY-MM-DD HH:mm")
      });
    }
    setAllDayStart(ads);
  }

  const handleAllDayEnd = (ade: boolean) => {
    if (ade) {
      setRequest({
        ...request,
        endDate: moment(request.endDate)
          .hour(16)
          .minutes(0)
          .format("YYYY-MM-DD HH:mm")
      });
    }
    setAllDayEnd(ade);
  }

  const setStartDate = (value: Date) => {
    setRequest({
      ...request,
      startDate: moment(value).hour(8).minutes(0).seconds(0).format("YYYY-MM-DD HH:mm"),
    });
  }

  const setEndDate = (value: Date) => {
    setRequest({
      ...request,
      endDate: moment(value).hour(16).minutes(0).seconds(0).format("YYYY-MM-DD HH:mm"),
    });
  }

  const handleGoldenHoursChanged = (value: number) => {
    setAllDayStart(value < 0);
    setRequest(prev => ({ ...prev, goldenHours: value }));
  }

  const handleStartTimeChanged = (value: string) => {
    var time = value.split(":");
    setRequest({
      ...request,
      startDate: moment(request.startDate)
        .hour(parseInt(time[0]))
        .minutes(parseInt(time[1]))
        .format("YYYY-MM-DD HH:mm")
    });
  }
  
  const handleEndTimeChanged = (value: string) => {
    var time = value.split(":");
    setRequest({
      ...request,
      endDate: moment(request.endDate)
        .hour(parseInt(time[0]))
        .minutes(parseInt(time[1]))
        .format("YYYY-MM-DD HH:mm")
    });
  }

  return (
    <>
      {request?.leaveRequestType === LeaveRequestType.GoldenTime && (
        <StructuredList.Item
          name="Golden Time"
          description={`Please choose the amount of golden time you would like. `}
          required
        >
          <>
            <GoldenTimeDropdown
              holidayCard={holidayCard}
              value={request.goldenHours}
              onChange={value => handleGoldenHoursChanged(value)}
              fluid
            />
            <br />
            <Sub>
              You have{" "}
              <b>
                {holidayCard.goldenTimeRemaining}{" "}
                {strings.pluralize(
                  "hour",
                  "hours",
                  holidayCard.goldenTimeRemaining
                )}
              </b>{" "}
              of Golden Time remaining.
            </Sub>
          </>
        </StructuredList.Item>
      )}

      {(request?.leaveRequestType !== LeaveRequestType.GoldenTime || holidayCard.goldenTimeRemaining > 0) && (
        <>
          <StructuredList.Item
            name="Starts"
            description={`This is the start of your leave. It must be within the ${holidayCard.academicYear} academic year.`}
            required
          >
            <DatePicker
              selectedDate={request?.startDate}
              onChange={setStartDate}
              closeOnSelect
            />
            {request?.leaveRequestType !== LeaveRequestType.GoldenTime && (
              <Checkbox
                text="All Day"
                checked={allDayStart}
                onChange={val => handleAllDayStart(val.checked)}
              />
            )}
            {!allDayStart && (
              <TimePicker
                onChange={handleStartTimeChanged}
                initialValue={moment(request?.startDate).format(
                  "HH:mm"
                )}
              />
            )}
          </StructuredList.Item>

          {request?.leaveRequestType !== LeaveRequestType.GoldenTime && (
            <StructuredList.Item
              name="Ends"
              description={`This is when your leave ends, not when you return to work.`}
              required
            >
              <DatePicker
                selectedDate={request?.endDate}
                onChange={setEndDate}
                closeOnSelect
              />
              <Checkbox
                text="All Day"
                checked={allDayEnd}
                onChange={() => handleAllDayEnd(!allDayEnd)}
              />
              {!allDayEnd && (
                <TimePicker
                  onChange={handleEndTimeChanged}
                  initialValue={moment(request?.endDate).format(
                    "HH:mm"
                  )}
                />
              )}
            </StructuredList.Item>
          )}

          {request?.leaveRequestType !== LeaveRequestType.GoldenTime && (
            <StructuredList.Item
              name="Number of Working Days / Hours"
              description={<>The number of working days / hours required off. <b>This field is automatically calculated from your start and end dates.</b></>}
            >
              <TextInput
                type={TextInputType.String}
                value={dateTime.hoursToDaysAndHours(request?.totalHours)}
                readonly
              />
              {request?.leaveRequestType === LeaveRequestType.Holiday && (
                <>
                  <br></br>
                  <Sub>
                    You have{" "}
                    <b>
                      {holidayCard.remaining}{" "}
                      {strings.pluralize("day", "days", holidayCard.remaining)}
                    </b>{" "}
                    of holiday remaining.{" "}
                  </Sub>
                </>
              )}
            </StructuredList.Item>
          )}

        </>
      )}
    </>
  );
}


export default LeaveHours;