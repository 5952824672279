import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Tabs,
  Card,
  HtmlText,
  RichTextEditor,
  StructuredList,
  Dropdown,
  TextInput,
  Checkbox,
  ActionBar,
  Right,
  Message,
  Swatches,
  Button,
  Size,
  Spacing,
  ValidationMessage,
} from "ui-kit";
import TableEditor, { selectedItemFields } from "./tableEditor";
import DynamicField from "./dynamicField";
import {
  SchoolDropdown,
  FilePicker,
  RoleView,
  StaffSelector,
} from "sharedComponents/common";
import { arrays, users } from "utils";
import SubmissionHistoryList from "./submissions/submissionHistoryList";
import SubmissionNoteList from "./submissions/submissionNoteList";
import SubmissionNoteEditor from "./submissions/submissionNoteEditor";
import { Constants } from "configuration";
import AdminSubmissionStateModal, {
  STATEMODAL_MODE,
} from "./submissions/submissionStateModal";
import searchEngineActions from "areas/search/actions/searchEngineActions";
import submissionActions from "areas/forms/actions/submissionActions";
import {
  FieldData,
  FieldGroupData,
  FieldGroupListView,
  FieldListView,
  SubmissionDetailView,
  SubmitFormCommand,
} from "../types/formResponse.types";
import { RootState } from "reducers/store";
import { FileListView } from "types/common/files.types";
import { useScroll } from "hooks/useScroll";
import { ApprovalStatus } from "areas/humanResources/types/leaveShared.types";

const FieldGroup = ({
  group,
  onChange,
}: {
  group: FieldGroupListView;
  onChange?: (
    group: FieldGroupListView,
    field: FieldListView,
    index: number,
    value: string
  ) => void;
}) => {
  return (
    <StructuredList>
      {group.fields
        .filter((x) => x.isActive)
        .map((field: FieldListView, index: number) => (
          <StructuredList.Item
            key={index}
            name={field.name}
            required={field.isRequired}
            description={field.description}
          >
            <DynamicField
              field={field}
              onChange={(value) =>
                onChange?.(group, field, index, value as string)
              }
              value={field.submittedValues[0]?.value}
            />
          </StructuredList.Item>
        ))}
    </StructuredList>
  );
};

const TableGroup = ({
  group,
  onSave,
  onDelete,
}: {
  group: FieldGroupListView;
  onSave: (
    selectedItem: selectedItemFields,
    group: FieldGroupListView,
    index: number
  ) => void;
  onDelete: (group: FieldGroupListView, index: number) => void;
}) => {
  return <TableEditor group={group} onSave={onSave} onDelete={onDelete} />;
};

interface IFormEditorProps {
  submission: SubmissionDetailView;
  onSave: (data: SubmitFormCommand) => void;
  onCancel?: () => void;
  error?: string;
  working?: boolean;
}

const FormEditor: React.FC<IFormEditorProps> = ({
  submission,
  onSave,
  onCancel,
  error,
  working,
}) => {
  const { scrollToTop } = useScroll();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const {
    departments,
    error: departmentsError,
    loading: loadingDepartments,
  } = useSelector((state: RootState) => state.searchDepartments);

  const [_submission, _setSubmission] =
    useState<SubmissionDetailView>(submission);
  const [accepted, setAccepted] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [changingAuthoriser, setChangingAuthoriser] = useState<boolean>(false);
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [_submitterNotes, _setSubmitterNotes] = useState<string>(
    submission?.submitterNotes
  );

  const handleFilesChanged = (files: FileListView[]) => {
    console.log(files);
    _setSubmission({
      ..._submission,
      files: files,
    });
  };

  useEffect(() => {
    searchEngineActions.searchDepartments(
      null,
      [submission?.schoolId],
      () => {}
    );
  }, []);

  useEffect(() => {
    _setSubmission(submission);
  }, [submission]);

  useEffect(() => {
    error && scrollToTop();
  }, [error]);

  const handleCancel = () => {
    onCancel?.();
  };

  const handleSchoolChange = (value: number) => {
    if (value) {
      _setSubmission({
        ..._submission,
        schoolId: value,
        departmentId: null,
        department: null,
      });
      searchEngineActions.searchDepartments(null, [value]);
    }
  };

  const handleSave = (saveAsDraft: boolean) => {
    //Validate
    var errors = [];

    if (!_submission.schoolId) {
      errors.push("Please choose a school.");
    }

    if (!_submission.departmentId) {
      errors.push("Please choose a department.");
    }

    if (!accepted && !saveAsDraft) {
      errors.push("Please accept the terms of submission.");
    }

    if (!_submission.authoriserId && _submission.form.requiresAuthorisation) {
      errors.push("Please choose the member of staff to authorise this form.");
    }

    if (
      _submission.authoriserId == user.id &&
      _submission.form.requiresAuthorisation
    ) {
      errors.push(
        "You are not permitted to self-authorise a form. Please choose a different member of staff to authorise this form."
      );
    }

    // Need to validate tabular data is present
    _submission.form.fieldGroups.forEach((group, index) => {
      if (group.minRowCount > 0) {
        if (group.fields.some((x) => arrays.isEmpty(x.submittedValues))) {
          errors.push(
            `You must add at least one item to the ${group.name} table`
          );
        }
      }
    });

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    if (
      !saveAsDraft &&
      !window.confirm(
        _submission.form.requiresAuthorisation
          ? "This will submit the form and send it to the authorising member for approval. Are you sure?"
          : "This will submit the form. Are you sure?"
      )
    ) {
      return;
    }

    // Build data object
    var fieldGroupData: FieldGroupData[] = [];

    _submission.form.fieldGroups.forEach((group: FieldGroupListView) => {
      var fieldData: FieldData[] = [];

      group.fields.forEach((field: FieldListView, index: number) => {
        fieldData.push({
          fieldId: field.id,
          values: field.submittedValues,
        });
      });

      fieldGroupData.push({
        id: group.id,
        fields: fieldData,
      });
    });

    var data: SubmitFormCommand = {
      ..._submission,
      saveAsDraft,
      fieldGroups: fieldGroupData,
      authoriserId: _submission.form.requiresAuthorisation
        ? _submission.authoriserId
        : user.id,
    };
    onSave?.(data);
  };

  const handleTableRowSave = (
    data: selectedItemFields,
    group: FieldGroupListView,
    index: number
  ) => {
    _submission.form.fieldGroups.forEach((fieldGroup, i) => {
      if (fieldGroup.alias === group.alias) {
        var groupToUpdate = fieldGroup;
        groupToUpdate.fields.forEach((field) => {
          if (index === null) {
            field.submittedValues.push({
              index: field.submittedValues.length,
              value: data[field.alias],
            });
          } else {
            field.submittedValues[index] = {
              index: field.submittedValues.length,
              value: data[field.alias],
            };
          }

          groupToUpdate.submittedRows = field.submittedValues.length;
        });

        var fieldGroups = _submission.form.fieldGroups;
        fieldGroups[i] = groupToUpdate;

        _setSubmission({
          ..._submission,
          form: { ..._submission.form, fieldGroups: fieldGroups },
        });
      }
    });
  };

  const handleFieldChange = (
    group: FieldGroupListView,
    field: FieldListView,
    index: number,
    value: string
  ) => {
    _setSubmission({
      ..._submission,
      form: {
        ..._submission.form,
        fieldGroups: _submission.form.fieldGroups.map((_group) =>
          _group.alias == group.alias
            ? {
                ..._group,
                fields: _group.fields.map((_field) =>
                  _field.id == field.id
                    ? {
                        ..._field,
                        submittedValues: [{ index: null, value }],
                      }
                    : _field
                ),
              }
            : _group
        ),
      },
    });
  };

  const handleTableRowDelete = (group: FieldGroupListView, index: number) => {
    var fieldGroups = _submission.form.fieldGroups;

    _submission.form.fieldGroups.forEach((fieldGroup, i) => {
      if (fieldGroup.alias === group.alias) {
        group.fields.forEach((field: FieldListView) => {
          arrays.remove(field.submittedValues, index);
        });

        fieldGroups[i] = {
          ...group,
          submittedRows: group.submittedRows - 1,
        };

        _setSubmission({
          ..._submission,
          form: { ..._submission.form, fieldGroups: fieldGroups },
        });
      }
    });
  };

  const userIsFinance = () => {
    return users.isInAnyRoles(user, [
      Constants.ROLES.FINANCE_USER,
      Constants.ROLES.FINANCE_ADMIN,
      Constants.ROLES.FINANCE_TEAMLEADER,
    ]);
  };

  const handleDeleteDraft = () => {
    if (
      window.confirm("This will permanently delete this draft. Are you sure?")
    ) {
      submissionActions.deleteDraft(_submission.id, () => {
        onCancel?.();
      });
    }
  };

  return _submission ? (
    <>
      <Tabs>
        <Tabs.Pane label="Form">
          <Message text={error} color={Swatches.Danger} />
          <ValidationMessage errors={validationErrors} />
          <Card title="Your School &amp; Department">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item
                  name="Your School"
                  required
                  description="This is the school that employs you. If you are a Trust member of staff, please choose 'Lionheart Educational Trust'."
                >
                  <SchoolDropdown
                    initialValue={_submission?.schoolId}
                    onChange={handleSchoolChange}
                    fluid
                    includeTrust
                  />
                </StructuredList.Item>
                <StructuredList.Item
                  name="Your Department"
                  required
                  description="This is the department that you are based in."
                >
                  <Dropdown
                    placeholder="Please select your department..."
                    fluid
                    error={departmentsError}
                    loading={loadingDepartments}
                    value={_submission?.departmentId}
                    onChange={(value) => {
                      _setSubmission({ ..._submission, departmentId: value });
                    }}
                  >
                    {departments?.items?.map((department, index) => (
                      <Dropdown.Item
                        key={index}
                        value={Number(department.id)}
                        label={department.title}
                      />
                    ))}
                  </Dropdown>
                </StructuredList.Item>
                <StructuredList.Item
                  name="Your Reference"
                  description="You can add an optional reference to help you identify this form submission."
                >
                  <TextInput
                    maxLength={50}
                    fluid
                    characterCount
                    value={_submission.userRef}
                    onChange={(value) =>
                      _setSubmission({ ..._submission, userRef: value })
                    }
                  />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
          <>
            {_submission.form.fieldGroups
              .filter((x) => x.fields.some((f) => f.isActive))
              .map((group: FieldGroupListView, index: number) => {
                const isTable =
                  (group.maxRowCount != null && group.maxRowCount != null) ||
                  (group.minRowCount != null && group.minRowCount > 0);
                return (
                  <React.Fragment key={index}>
                    <Card title={group.name}>
                      <Card.Body noPad={isTable}>
                        {isTable ? (
                          <TableGroup
                            group={group}
                            onSave={handleTableRowSave}
                            onDelete={handleTableRowDelete}
                          />
                        ) : (
                          <>
                            {group.description && <p>{group.description}</p>}
                            <FieldGroup
                              group={group}
                              onChange={handleFieldChange}
                            />
                          </>
                        )}
                      </Card.Body>
                    </Card>
                  </React.Fragment>
                );
              })}
          </>
          <Card title="Additional Information">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item
                  name="Upload"
                  description="Please upload any documents that are needed to support this form submission. You can upload .pdf, .docx, .xlsx, .csv, .jpg, .gif, .png or .pptx files."
                >
                  <FilePicker
                    files={_submission.files}
                    onFilesChanged={(x) =>
                      handleFilesChanged(x as FileListView[])
                    }
                  />
                </StructuredList.Item>
                <StructuredList.Item
                  name="Notes"
                  description="Please add any explanatory notes or additional information to support this form submission."
                >
                  <RichTextEditor
                    initialValue={_submitterNotes}
                    onChange={(value) =>
                      _setSubmission({ ..._submission, submitterNotes: value })
                    }
                  />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>

          <Card title="Authorisation">
            <Card.Body>
              {_submission.form.requiresAuthorisation ? (
                <StructuredList>
                  <StructuredList.Item name="Authoriser">
                    <StaffSelector
                      user={_submission?.authoriser}
                      onChange={(value) =>
                        _setSubmission({
                          ..._submission,
                          authoriser: value,
                          authoriserId: value.id,
                        })
                      }
                    />
                  </StructuredList.Item>
                </StructuredList>
              ) : (
                <p>This form does not need to be authorised.</p>
              )}
            </Card.Body>
          </Card>

          <Card title="Confirmation">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item
                  name="Acceptance"
                  description="Before submitting your form, you need to accept the terms of this form as described in the guidance. If you are saving a draft, you do not need to accept the terms until you submit the form."
                  required
                >
                  <Checkbox
                    checked={accepted}
                    onChange={(value) => setAccepted(!accepted)}
                    text={`Please confirm that you have read the ${_submission?.form.name} Form Guidance before submitting the form`}
                  />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>

          <ActionBar low style={{ marginBottom: Spacing.Medium + "px" }}>
            <Button
              onClick={handleCancel}
              text="Close"
              working={working}
              color={Swatches.Low}
              size={Size.Small}
            />
            <Right>
              {_submission?.id && userIsFinance() && (
                <Button
                  text="Cancel This Form"
                  onClick={() => setCancelModalOpen(true)}
                  color={Swatches.Cancel}
                  working={working}
                  size={Size.Small}
                />
              )}
              {_submission?.id && _submission.currentStatus === ApprovalStatus.Draft && (
                <Button
                  text="Delete Draft"
                  onClick={() => handleDeleteDraft()}
                  color={Swatches.Cancel}
                  working={working}
                  size={Size.Small}
                />
              )}
              <Button
                onClick={() => handleSave(true)}
                text="Save as Draft"
                working={working}
                color={Swatches.Primary}
                size={Size.Small}
              />
              <Button
                onClick={() => handleSave(false)}
                text="Save and Submit"
                working={working}
                color={Swatches.Success}
                size={Size.Small}
              />
            </Right>
          </ActionBar>
        </Tabs.Pane>
        <Tabs.Pane label="Guidance">
          <Card>
            <Card.Body>
              <HtmlText html={_submission?.form.description} />
            </Card.Body>
          </Card>
        </Tabs.Pane>
        <Tabs.Pane label="Notes" hide={submission.id == null}>
          {_submission?.id && (
            <RoleView roles={[Constants.ROLES.FINANCE_USER]}>
              <SubmissionNoteEditor submission={_submission} />
            </RoleView>
          )}
          <SubmissionNoteList notes={_submission?.notes} />
        </Tabs.Pane>
        <Tabs.Pane label="History" hide={submission.id == null}>
          <SubmissionHistoryList history={_submission?.history} />
        </Tabs.Pane>
      </Tabs>
      {_submission && _submission.id && (
        <AdminSubmissionStateModal
          open={cancelModalOpen}
          mode={STATEMODAL_MODE.CANCEL}
          onClose={() => setCancelModalOpen(false)}
          submission={_submission}
        />
      )}
    </>
  ) : null;
};

export default FormEditor;
