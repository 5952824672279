import React from "react";
import { Table } from "ui-kit";
import { formatDate } from "utils/dateTime";
import moment from "moment";
import LeavingLessonRow from "./leavingLessonRow";
import LeavingLessonHeaders from "./leavingLessonHeaders";
import {
  Title,
  TitleSize,
  fontStyle,
  displayFont,
  typescale,
  neutral,
} from "ui-kit";
import styled from "styled-components";
import { LessonExitGroupBy } from "areas/analytics/types/lessonExitAnalyticShared.types";
import { LessonExitGroupByView1 } from "areas/analytics/types/lessonExitAnalyticsResponse.types";



const SecondGroupByHeader = styled.div`
  ${fontStyle(displayFont.medium, typescale.header4, neutral[700])}
`;


interface ILeavingLessonTableProps {
  group: LessonExitGroupByView1;
  reportGroupType1: LessonExitGroupBy;
  reportGroupType2?: LessonExitGroupBy;
}


const LeavingLessonTable: React.FC<ILeavingLessonTableProps> = ({ group, reportGroupType1, reportGroupType2 }) => {

  const getGroupName = (groupTypeId: LessonExitGroupBy, groupName: string) => {
    switch (groupTypeId) {
      case LessonExitGroupBy.YearGroup:
        if (groupName) {
          return "Year " + groupName;
        } else {
          return "Not specified";
        }

      case LessonExitGroupBy.Date:
        return formatDate(moment(groupName, ["DD/MM/YYYY HH:mm:ss"]).format());

      default:
        if (groupName) {
          return groupName;
        } else {
          return "Not specified";
        }
    }
  };

  return (
    <>
      <br />
      <Title
        text={getGroupName(reportGroupType1, group.name)}
        size={TitleSize.H3}
      />
      <Table>
        {reportGroupType2 === null ? (
          <>
            <LeavingLessonHeaders />
            {group.lessonExits.map(lessonExit => (
              <LeavingLessonRow leavingLessonRecord={lessonExit} />
            ))}
          </>
        ) : (
          group.lessonExitGroupByView2s.map(group2 => (
            <>
              <Table.Header>
                <Table.Cell>
                  <SecondGroupByHeader>
                    {getGroupName(reportGroupType2, group2.name)}
                  </SecondGroupByHeader>
                </Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Header>

              <LeavingLessonHeaders />

              {group2.lessonExits.map(lessonExit => (
                <LeavingLessonRow leavingLessonRecord={lessonExit} />
              ))}
            </>
          ))
        )}
      </Table>
    </>
  );
};

export default LeavingLessonTable;
