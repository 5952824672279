import { IBeehiveAction } from "types/common/action.types";
import managedStaffActions from "../../actions/managedStaffActions";
import { StaffListView } from "types/users/userListViews.types";

interface IManagedStaffState {
  managedStaff: StaffListView[],
  selected: string | null;
  loading: boolean;
  error: string | null;
  masterWidth: number;
}

const INITIAL_STATE: IManagedStaffState = {
  managedStaff: [],
  selected: null,
  loading: false,
  error: null,
  masterWidth: 300,
};

const managedStaffReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IManagedStaffState => {

  const { GETMANAGEDSTAFF, SETWIDTH, SELECTSTAFFMEMBER } = managedStaffActions.types;

  switch (action.type) {
    case SETWIDTH:
      return { ...state, masterWidth: action.payload };

    case SELECTSTAFFMEMBER:
      return {
        ...state,
        selected: action.payload,
      };
    case GETMANAGEDSTAFF.START:
      return { ...INITIAL_STATE, loading: true };
    case GETMANAGEDSTAFF.SUCCESS:
      return { ...INITIAL_STATE, managedStaff: action.payload };
    case GETMANAGEDSTAFF.FAILED:
      return { ...INITIAL_STATE, error: action.payload };
    default:
      return state;
  }
};

export default managedStaffReducer;
