import React, { useEffect, useState } from "react";
import {
  Loader,
  Size,
  EmptyMessage,
  Card,
  HeadlineStatistic,
  Tabs,
  Button,
  neutral,
  Subtitle,
  Swatches,
  Dropdown,
  Spacing,
  honeygold,
  ActionBar,
  Left,
  Right,
  Icon,
  fontStyle,
  typescale,
  Message,
  displayFont,
  Sub,
} from "ui-kit";
import { useSelector } from "react-redux";
import { RoleView } from "sharedComponents/common";
import LeaveRequestTable from "./leaveRequestTable";
import RequestLeaveModal from "./requestLeaveModal";
import { Constants } from "configuration";
import AdditionalHoursTable from "./additionalHoursTable";
import RecordAdditionalHoursModal from "./recordAdditionalHoursModal";
import ViewAdditionalHoursModal from "./viewAdditionalHoursModal";
import { strings, users } from "utils";
import EditHolidayCardModal from "./editHolidayCardModal";
import styled from "styled-components";
import flyoutActions from "../../../../actions/ui/flyouts";
import {
  HolidayCardView,
  OvertimeListView,
} from "areas/humanResources/types/leaveResponse.types";
import { UserStartupView } from "types/users/userStartup.types";
import { RootState } from "reducers/store";
import {
  ManagedUserDetailView,
  StaffListView,
} from "types/users/userListViews.types";
import config from "configuration/config";
import { LeaveRequestType } from "areas/humanResources/types/leaveShared.types";

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .button {
    margin-bottom: ${Spacing.Medium}px;
  }
`;

const QuickLinkWrapper = styled.div`
  display: flex;

  .card-body {
    flex-direction: row;
    display: flex !important;
  }
`;

const QuickLink = styled.div`
  flex-grow: 1;
  border-right: 1px solid ${neutral[300]};
  padding: 0 ${Spacing.Large}px 0 0;
  margin-right: ${Spacing.Large}px;
  text-align: center;

  &:last-child {
    margin-right: 0;
    padding-right: 0;
    border: 0;
  }

  .icon {
    font-size: 24px;
    color: ${neutral[400]};
    margin-bottom: ${Spacing.Default}px;
    transition: all ease 500ms;
  }

  p {
    ${fontStyle(displayFont.medium, typescale.header4, neutral[500])}
  }

  &:hover {
    cursor: pointer;
    .icon {
      color: ${honeygold};
    }
  }
`;

interface IHolidayCardProps {
  holidayCard: HolidayCardView;
  staffMember: UserStartupView | StaffListView | ManagedUserDetailView;
  loading?: boolean;
  error?: string;
  onChange?: (value: number) => void;
  onAcademicYearChanged?: (value: number) => void;
}

const HolidayCard: React.FC<IHolidayCardProps> = ({
  holidayCard,
  staffMember,
  loading,
  error,
  onChange,
  onAcademicYearChanged,
}) => {
  const { user: currentUser } = useSelector(
    (state: RootState) => state.currentUser
  );

  const [selectedAcademicYear, setSelectedAcademicYear] = useState<
    number | null
  >(holidayCard?.academicYearId);

  const [selectedLeaveRequestType, setSelectedLeaveRequestType] = useState<LeaveRequestType>(null);
  const [_recordAdditionalHoursModalOpen, _setRecordAdditionalHoursModalOpen] =
    useState<boolean>(false);
  const [selectedOvertime, setSelectedOvertime] =
    useState<OvertimeListView | null>(null);
  const [_staffMember, _setStaffMember] = useState<
    UserStartupView | StaffListView | ManagedUserDetailView
  >(staffMember);
  const [editHolidayCard, setEditHolidayCard] = useState<boolean>(false);

  useEffect(() => {
    _setStaffMember(staffMember);
  }, [staffMember]);

  const handleRequestSubmitted = () => {
    onChange?.(selectedAcademicYear);
  };

  const handleSelectedAcademicYearChanged = (value: number) => {
    onAcademicYearChanged?.(value);
  };

  const handleOvertimeSelected = (
    overtime: OvertimeListView,
    index: number
  ) => {
    setSelectedOvertime(overtime);
    flyoutActions.openFlyout(Constants.FLYOUTS.VIEWOVERTIME);
  };

  const handleLeaveRequestUpdated = () => {
    onChange?.(selectedAcademicYear);
  };

  const handleOvertimeUpdated = () => {
    onChange?.(selectedAcademicYear);
  };

  const handleOvertimeAdded = () => {
    onChange?.(selectedAcademicYear);
  };

  const handleRecordOvertimeClicked = () => {
    _setRecordAdditionalHoursModalOpen(true);
  };

  const handleEditHolidayCard = () => {
    setEditHolidayCard(true);
  };

  const handleHolidayCardSaved = (card: HolidayCardView) => {
    setEditHolidayCard(false);
    console.log(card);
  };

  const openRequestLeaveFlyout = (requestType: LeaveRequestType) => {
    setSelectedLeaveRequestType(requestType);
  };

  const closeRequestLeaveFlyout = () => {
    setSelectedLeaveRequestType(null);
  };

  const openOvertimeFlyout = () => {
    flyoutActions.openFlyout(Constants.FLYOUTS.ADDOVERTIME);
  };

  return (
    <>
      <ActionBar>
        <Left>
          <Dropdown
            value={
              holidayCard?.academicYearId == null
                ? config.academicYear.current
                : holidayCard?.academicYearId
            }
            onChange={handleSelectedAcademicYearChanged}
          >
            <Dropdown.Item value={9} label="2022/23" />
            <Dropdown.Item value={10} label="2023/24" />
            <Dropdown.Item value={11} label="2024/25" />
          </Dropdown>
        </Left>
        <Right>
          {_staffMember?.id !== currentUser?.id &&
            holidayCard?.academicYearId === config.academicYear.current && (
              <RoleView
                roles={[
                  Constants.ROLES.HR_ADMINISTRATOR,
                  Constants.ROLES.DEVELOPMENT,
                ]}
              >
                <Button
                  size={Size.Small}
                  text="Edit Holiday Card"
                  onClick={() => setEditHolidayCard(true)}
                  color={Swatches.Primary}
                />
              </RoleView>
            )}
        </Right>
      </ActionBar>

      {loading ? (
        <Loader size={Size.Medium} cover />
      ) : error || !holidayCard ? (
        <EmptyMessage
          icon="plane"
          title="Holiday Card Not Available"
          summary="A holiday card for this year is not available. Please contact your HR department."
          cover
        >
          {!holidayCard && !error && _staffMember.id !== currentUser.id && (
            <RoleView
              roles={[
                Constants.ROLES.HR_ADMINISTRATOR,
                Constants.ROLES.DEVELOPMENT,
              ]}
            >
              <Button
                onClick={handleEditHolidayCard}
                color={Swatches.Primary}
                text="New Holiday Card"
                size={Size.Medium}
              />
            </RoleView>
          )}
        </EmptyMessage>
      ) : (
        <>
          <Message
            color={Swatches.Warning}
            text="The holiday cards for 2024/25 are provisional. Your carry over will be updated shortly and will include any outstanding allowance from 2023/24 up to a maximum of 5 days. If you believe your allowance or carry forward is incorrect, please do not contact HR or IT Support until the finalised holiday cards are released shortly."
          />

          <HeadlineStatistic>
            {holidayCard?.staffMember?.staffType === "Associate" && (
              <>
                <HeadlineStatistic.Item
                  icon="plane"
                  label="Total Holiday Allowance"
                  unit={`${strings.pluralize(
                    "day",
                    "days",
                    holidayCard.allowance
                  )} used`}
                  value={
                    <>
                      {holidayCard.used}
                      <span> of </span>
                      {holidayCard.totalAllowance}
                    </>
                  }
                />
                <HeadlineStatistic.Item
                  icon="plane"
                  label="Contractual Allowance"
                  unit={`${strings.pluralize(
                    "day",
                    "days",
                    holidayCard.allowance
                  )}`}
                  value={<>{holidayCard.allowance}</>}
                />
                <HeadlineStatistic.Item
                  icon="plane"
                  label="Carry Over"
                  unit={`${strings.pluralize(
                    "day",
                    "days",
                    holidayCard.carryOver
                  )}`}
                  value={<>{holidayCard.carryOver}</>}
                />
              </>
            )}

            <HeadlineStatistic.Item
              icon="stars"
              label="Golden Time"
              unit={`${strings.pluralize(
                "hour",
                "hours",
                holidayCard.goldenTimeUsed
              )} used`}
              value={
                <>
                  {holidayCard.goldenTimeUsed}
                  <span> of </span>
                  {holidayCard.goldenTimeAllowance}
                </>
              }
            />
            {/* {holidayCard.eligibleForOvertime && holidayCard.overtimeHours > 0 && (
              <HeadlineStatistic.Item
                icon="plane"
                label="Additional Hours"
                unit={`${strings.pluralize(
                  "hour",
                  "hours",
                  holidayCard.overtimeHours
                )} claimed`}
                value={
                  <>
                    {holidayCard.overtimeHoursUsed}
                    <span> of </span>
                    {holidayCard.overtimeHours}
                  </>
                }
              />
            )} */}
          </HeadlineStatistic>

          {_staffMember.id === currentUser.id &&
            holidayCard?.academicYearId === config.academicYear.current && (
              <QuickLinkWrapper>
                <Card grow>
                  <Card.Body>
                    {users.isAssociate(holidayCard.staffMember) && (
                      <QuickLink
                        onClick={() =>
                          openRequestLeaveFlyout(LeaveRequestType.Holiday)
                        }
                      >
                        <Icon value="plane-departure" />
                        <p>Request Holiday</p>
                      </QuickLink>
                    )}
                    <QuickLink
                      onClick={() =>
                        openRequestLeaveFlyout(LeaveRequestType.AuthorisedAbsence)
                      }
                    >
                      <Icon value="sign-out" />
                      <p>Request Absence</p>
                    </QuickLink>
                    {holidayCard.goldenTimeRemaining > 0 && (
                      <QuickLink
                        onClick={() =>
                          openRequestLeaveFlyout(LeaveRequestType.GoldenTime)
                        }
                      >
                        <Icon value="stars" />
                        <p>Request Golden Time</p>
                      </QuickLink>
                    )}
                    {/* {holidayCard.eligibleForOvertime && (
                  <QuickLink onClick={openOvertimeFlyout}>
                    <Icon value="house-night" />
                    <p>Record Additional Hours</p>
                  </QuickLink>
                )} */}
                  </Card.Body>
                </Card>
              </QuickLinkWrapper>
            )}
          <Tabs>
            <Tabs.Pane label="Leave" name="Leave">
              <Card title={`Leave Requests`}>
                <Card.Body noPad>
                  <LeaveRequestTable
                    requests={holidayCard.leaveRequests}
                    onUpdated={handleLeaveRequestUpdated}
                    showAddRequest={_staffMember.id === currentUser.id}
                    showStaff={false}
                    onRequestLeave={() =>
                      openRequestLeaveFlyout(LeaveRequestType.Holiday)
                    }
                    showRequestLeaveButton={
                      holidayCard?.academicYearId ===
                      config.academicYear.current
                    }
                  />
                </Card.Body>
              </Card>
              {_staffMember.id === currentUser.id && (
                <Card title="How is my Holiday Allowance calculated?">
                  <Card.Body>
                    <Sub>
                      Your total holiday allowance of{" "}
                      <b>{holidayCard.totalAllowance} days</b> is comprised of
                      your contractual allowance of{" "}
                      <b>{holidayCard.allowance} days</b>, plus any holiday
                      carried over from last academic year (
                      <b>
                        {holidayCard.carryOver}
                        {strings.pluralize(
                          " day",
                          " days",
                          holidayCard.carryOver
                        )}
                      </b>
                      ). If you have any questions about your holiday allowance,
                      please contact your school's HR department.
                    </Sub>
                  </Card.Body>
                </Card>
              )}
              <RequestLeaveModal
                holidayCard={holidayCard}
                onRequestSubmitted={handleRequestSubmitted}
                requestType={selectedLeaveRequestType}
                open={selectedLeaveRequestType != null}
                onClose={closeRequestLeaveFlyout}
              />
            </Tabs.Pane>
            <Tabs.Pane
              label="Additional Hours"
              name="Additional Hours"
              hide={true}
            >
              <Card title={`Overtime ${holidayCard.academicYear}`}>
                <Card.Body noPad>
                  <AdditionalHoursTable
                    overtime={holidayCard.overtime}
                    onRowSelected={handleOvertimeSelected}
                    onRecordClicked={handleRecordOvertimeClicked}
                  />
                </Card.Body>

                <ViewAdditionalHoursModal
                  holidayCard={holidayCard}
                  overtime={selectedOvertime}
                  onOvertimeUpdated={handleOvertimeUpdated}
                />
              </Card>
            </Tabs.Pane>
          </Tabs>
        </>
      )}
      <RecordAdditionalHoursModal
        holidayCard={holidayCard}
        onOvertimeAdded={handleOvertimeAdded}
        open={_recordAdditionalHoursModalOpen}
        onClose={() => _setRecordAdditionalHoursModalOpen(false)}
      />
      <EditHolidayCardModal
        open={editHolidayCard}
        onClose={() => setEditHolidayCard(false)}
        onSaved={handleHolidayCardSaved}
        holidayCard={holidayCard}
        staffMember={staffMember}
      />
    </>
  );
};

export default HolidayCard;
