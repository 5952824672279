import React, { useState, useEffect } from "react";
import PropTypes, { object } from "prop-types";
import DetentionNotesFormField from "../detentionNotesFormField";
import {
  behaviourValidation,
  getIncidentDetails,
  readyToSubmit,
} from "areas/behaviour/utils/behaviours";
import {
  ValidationMessage,
  ActionBar,
  Button,
  Swatches,
  Right,
  Size,
} from "ui-kit";
import { IDetentionDetails, IIncidentDetails, getDetentionDetails } from "areas/behaviour/utils/detentions";
import { DETENTION_STATUS_KEYS } from "areas/behaviour/constants/detentions";
import DetentionFormWrapper from "../detentionFormWrapper";
import ConfirmModal from "sharedComponents/common/confirmModal";
import { BehaviourView, DetentionStudentSummaryView } from "areas/behaviour/types/behaviourResponses.types";
import { PlannerSchoolView } from "areas/planner/types/plannerSchoolResponse.types";
import { DetentionStatus } from "areas/behaviour/types/behaviourShared.types";


interface IRescheduleDetentionFormWrapperProps {
  behaviour: BehaviourView;
  studentId: string;
  handleCancel: () => void;
  onRescheduleDetention: (detentionDetails: IDetentionDetails) => void;
  detentionSummary: DetentionStudentSummaryView[];
  schoolInformation: PlannerSchoolView;
}


const RescheduleDetentionFormWrapper: React.FC<IRescheduleDetentionFormWrapperProps> = ({
  behaviour,
  studentId,
  handleCancel,
  onRescheduleDetention,
  detentionSummary,
  schoolInformation,
}) => {

  const [incidentDetails, setIncidentDetails] = useState<IIncidentDetails>(null);
  const [detentionDetails, setDetentionDetails] = useState<IDetentionDetails>(null);
  const [detentionStatus, setDetentionStatus] = useState<DetentionStatus>(null);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    if (behaviour.detentions && behaviour.detentions.length > 0) {
      setDetentionDetails(getDetentionDetails(behaviour.detentions[0]));
      setDetentionStatus(behaviour.detentions[0]?.statusId);
    }
    setIncidentDetails(getIncidentDetails(behaviour));
  }, [behaviour]);

  const checkValidations = (incidentDetails: IIncidentDetails, detentionDetails: IDetentionDetails) => {
    const detentionStatusId = behaviour.detentions[0]?.statusId;
    let errors = behaviourValidation(
      incidentDetails,
      behaviour.detentions[0] &&
        (detentionStatusId === DetentionStatus.Issued || detentionStatusId === DetentionStatus.Missed)
          ? detentionDetails
          : null,
      detentionSummary,
      schoolInformation
    );

    if (errors.length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors([]);
      setOpenConfirm(true);
    }
  };

  return (
    <>
      <DetentionFormWrapper
        studentId={studentId}
        schoolId={schoolInformation.id}
        detentionDetails={detentionDetails}
        setDetentionDetails={setDetentionDetails}
        allowDetentionTypeChange
        allowDetentionDurationChange
      />

      <DetentionNotesFormField
        notes={detentionDetails ? detentionDetails.detentionNotes : ""}
        handleNotesChange={value =>
          setDetentionDetails({
            ...detentionDetails,
            detentionNotes: value,
          })
        }
        detentionStatus={detentionStatus}
      />

      {validationErrors.length > 0 && (
        <ValidationMessage errors={validationErrors} />
      )}

      <ActionBar low>
        <Right>
          <Button
            text="Save"
            color={Swatches.Success}
            size={Size.Small}
            disabled={
              readyToSubmit(behaviour.initialActionId, detentionDetails) ===
              false
            }
            onClick={() =>
              checkValidations(incidentDetails, detentionDetails)
            }
          />

          <Button text="Cancel" size={Size.Small} onClick={handleCancel} />
        </Right>
      </ActionBar>

      <br />

      <ConfirmModal
        openModal={openConfirm}
        confirmMsg={
          "A message will be sent to parents with these changes. Are you sure you want to continue?"
        }
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          onRescheduleDetention(detentionDetails);
          setOpenConfirm(false);
        }}
      />
    </>
  );
};


export default RescheduleDetentionFormWrapper;
