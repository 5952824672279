import React, { useEffect, useState } from "react";
import { Dropdown } from "ui-kit";
import calendarActions from "areas/calendar/actions/calendarActions";
import { CalendarCategoryListView } from "areas/calendar/types/calendarResponses.types";


interface IEventsCategoryDropdownProps {
  onChange?: (value: CalendarCategoryListView) => void;
  includeAllOption?: boolean;
  initialValue?: number;
}

interface EventCategoryDropdownOption {
  key: number;
  label: string;
  value: CalendarCategoryListView;
}


const ALL_OPTION: EventCategoryDropdownOption = {
  key: -1,
  label: "All Categories",
  value: {
    id: -1,
    name: "All"
  },
};


const EventsCategoryDropdown: React.FC<IEventsCategoryDropdownProps> = ({
  onChange,
  includeAllOption,
  initialValue,
}) => {

  const [selectedValue, setSelectedValue] = useState<number | CalendarCategoryListView>(includeAllOption ? ALL_OPTION.value : null);
  const [options, setOptions] = useState<EventCategoryDropdownOption[]>([]);

  useEffect(() => {
    calendarActions.getCalendarCategories((data: CalendarCategoryListView[]) => {
      var options: EventCategoryDropdownOption[] = data?.map((cat) => ({
        key: cat.id,
        label: cat.name,
        value: cat,
      }));
      includeAllOption && options.unshift(ALL_OPTION);
      setOptions(options);
    });
  }, []);

  useEffect(() => {
    if (!options) {
      return;
    }
    if (initialValue) {
      setFromOptions(initialValue);
    } else {
      setSelectedValue(options[0]?.value);
      options[0]?.value && onChange?.(options[0]?.value);
    }
  }, [options]);

  useEffect(() => {
    const option = options?.find(x => x?.value.id == initialValue);
    setSelectedValue(option?.value);
  }, [initialValue]);

  const setFromOptions = (value: number) => {
    const option = options?.find(x => x?.value.id == value);
    setSelectedValue(option?.value);
  };

  const handleChange = (value: CalendarCategoryListView) => {
    setSelectedValue(value);
    onChange?.(value);
  };

  return (
    <>
      <Dropdown
        placeholder="Choose a Category..."
        items={options}
        onChange={handleChange}
        value={selectedValue}
        fluid
      />
    </>
  );
};

export default EventsCategoryDropdown;
