import React from "react";
import { Title, TitleSize, Tabs } from "ui-kit";
import Subheader from "sharedComponents/layout/header/subheader";
import AccessArrangements from "./accessArrangements";
import Tags from "./tags";
import Providers from "./providers";
import Provisions from "./providers/provisions";
import CostBands from "./providers/provisions/provisionCostBands";
import SendReports from "./reporting/sendReports";
import { Constants } from "configuration";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { users } from "utils";
import SendAdmin from "./sendAdmin";


const SendAdministration = () => {
  
  const { user } = useSelector((state: RootState) => state.currentUser);

  return (
    <>
      <Subheader>
        <Title size={TitleSize.H2} text="SEND" sub="Administration" />
      </Subheader>
      <Tabs>
        <Tabs.Pane name="Provisions" label="Provisions">
          <Provisions />
        </Tabs.Pane>
        <Tabs.Pane name="Providers" label="Providers">
          <Providers />
        </Tabs.Pane>
        <Tabs.Pane name="Tags" label="Tags">
          <Tags />
        </Tabs.Pane>
        <Tabs.Pane name="Access Arrangements" label="Access Arrangements">
          <AccessArrangements />
        </Tabs.Pane>
        <Tabs.Pane name="Cost Bands" label="Cost Bands">
          <CostBands />
        </Tabs.Pane>
        <Tabs.Pane name="Reporting" label="Reporting">
          <SendReports />
        </Tabs.Pane>
        <Tabs.Pane name="Administration" label="Administration" hide={!users.isInRole(user, Constants.ROLES.SEN_ADMINISTRATOR)}>
          <SendAdmin />
        </Tabs.Pane>
      </Tabs>
    </>
  );
};

export default SendAdministration;
