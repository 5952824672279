/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  Title,
  TitleSize,
  FlyoutWidth,
  Spacing,
  neutral,
  formatScrollbars,
  Icon,
  Size,
  honeygold,
} from "../index";
import { useEventListener } from "../../hooks";
import flyoutActions from "../../actions/ui/flyouts";
import { RootState } from "reducers/store";

const OverlayWrapper = styled.div<{ isOpen: boolean; wide?: any }>`
  position: fixed;
  z-index: 9998;
  top: 0;
  bottom: 0;
  right: -100%;
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(2px);

  .flyout-wrapper {
    transition: all 250ms ease-out;

    ${({ wide }) =>
      wide
        ? `
      width: ${FlyoutWidth.Wide}px;
      right: -${FlyoutWidth.Wide}px;
    `
        : `
  width: ${FlyoutWidth.Default}px;
  right: -${FlyoutWidth.Default}px;`}
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    display: block;
    transition: all 25ms ease-out;
    opacity: 1;   
    right: 0;
    left: 0; 
    width: 100%;

    .flyout-wrapper {
      transition: all 250ms ease-out;
      right: 0;
    }
  `}
`;

const FlyoutWrapper = styled.div`
  background: ${neutral[200]};
  box-sizing: border-box;
  z-index: 9999;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  box-shadow: -4px 0px 10px -2px rgba(0, 0, 0, 0.15);
  padding: ${Spacing.Large}px ${Spacing.Default}px ${Spacing.ExtraLarge}px
    ${Spacing.ExtraLarge}px;
`;

const HeaderWrapper = styled.div`
  margin-bottom: ${Spacing.Large}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: ${Spacing.Medium}px;

  .close-icon {
    align-self: flex-start;
    &:hover {
      color: ${honeygold};
    }
  }
`;

const BodyWrapper = styled.div<{ scroll: boolean }>`
  flex-grow: 1;
  ${({ scroll }) =>
    scroll
      ? `overflow-y: auto; padding-right: ${Spacing.Medium}px`
      : "overflow-y: hidden;"}
  display: flex;
  flex-direction: column;
  padding-right: ${Spacing.Medium}px;

  ${formatScrollbars(neutral[200])}
`;

const FooterWrapper = styled.div`
  margin-top: ${Spacing.ExtraLarge}px;
  padding-right: ${Spacing.Medium}px;
  text-align: center;
`;

interface IFlyoutProps {
  title?: string;
  titleSub?: string;
  titleComponent?: React.ReactNode;
  children: any | any[];
  onClose?: () => void;
  name: string;
  wide?: boolean;
}

const Flyout: React.FC<IFlyoutProps> & {
  Body: typeof Body;
  Footer: typeof Footer;
} = ({ title, titleSub, titleComponent, children, onClose, name, wide }) => {
  const [open, setOpen] = useState(false);
  const { activeFlyout } = useSelector((state: RootState) => state.ui);

  const handleClose = () => {
    flyoutActions.closeFlyout();
    onClose?.();
  };

  //   const ref = useOnclickOutside(() => {
  //     handleClose();
  //   });

  const handleKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
    if (open) {
      switch (key) {
        case "Escape":
          handleClose();
          break;
        default:
          break;
      }
    }
  };

  useEventListener("keydown", handleKeyDown);

  useEffect(() => {
    // NB If we need to trigger onClose on auto-close as well, we need to check
    // if the flyout is open here, and if so call onClose.
    setOpen(activeFlyout === name);
  }, [activeFlyout]);

  const stopClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <OverlayWrapper isOpen={open} wide={wide} onClick={handleClose}>
      <FlyoutWrapper className="flyout-wrapper" onClick={stopClose}>
        <HeaderWrapper>
          {titleComponent
            ? titleComponent
            : title && (
                <Title text={title} size={TitleSize.H3} sub={titleSub} />
              )}
          <Icon
            className="close-icon"
            value="times"
            onClick={handleClose}
            size={Size.Large}
          />
        </HeaderWrapper>
        {children}
      </FlyoutWrapper>
    </OverlayWrapper>
  );
};


interface IFlyoutBodyProps {
  children: any | any[];
  scroll?: boolean;
  scrollOnErrors?: string[];
}
const Body: React.FC<IFlyoutBodyProps> = ({ children, scroll = true, scrollOnErrors }) => {

  const scrollRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (scrollOnErrors?.length > 0) {
      scrollRef.current?.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }
  }, [scrollOnErrors])
  
  return <BodyWrapper ref={scrollRef} scroll={scroll}>{children}</BodyWrapper>;
};


interface IFlyoutFooterProps {
  children: any | any[];
}
const Footer: React.FC<IFlyoutFooterProps> = ({ children }) => {
  return <FooterWrapper>{children}</FooterWrapper>;
};

Flyout.Body = Body;
Flyout.Footer = Footer;

export default Flyout;
