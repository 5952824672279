import React from "react";


const Mailout = () => {

  return (
    <div>
      <h1>Mailout</h1>
    </div>
  );
};

export default Mailout;
