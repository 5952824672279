import { useEffect, useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import {
  Card,
  Currency,
  EmptyMessage,
  Label,
  Loader,
  Modal, Size, StructuredList, Table,
} from "ui-kit";
import { arrays } from "utils";


interface ICashlessSaleModalProps {
  open: boolean;
  onClose?: () => void;
}


const CashlessSaleModal: React.FC<ICashlessSaleModalProps> = ({ open, onClose }) => {

  const { order, error, loading } = useSelector((state: RootState) => state.userCashlessSale);
  const [_open, _setOpen] = useState<boolean>(false);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  return (
    <Modal
      width="80%"
      height="60%"
      title="Order"
      open={_open}
      onClose={onClose}
    >
      <Modal.Body>
        { loading ? (
          <Loader size={Size.Medium} cover />
        ) : (
          order && (
            <>
              <Card>
                <Card.Body>
                  <StructuredList>
                    <StructuredList.Item name="Total">
                      <Currency value={order?.total} />
                    </StructuredList.Item>
                    <StructuredList.Item name="VAT">
                      <Currency value={(order?.total - order?.nettTotal)} />
                    </StructuredList.Item>
                    <StructuredList.Item name="Date">
                      <Moment format="DD/MM/YYYY, HH:mma" date={order?.createdAt} />
                    </StructuredList.Item>
                  </StructuredList>
                </Card.Body>
              </Card>

                  <Table
                    caption={"Items"}
                    empty={arrays.isEmpty(order.orderItems)}
                    error={error != null}
                    loading={loading}
                    emptyMessage={
                      <EmptyMessage
                        icon="id-card-alt"
                        title="No items found"
                        summary="There are no menu items for this order"
                        cover
                      />
                    }
                  >
                    <Table.Header>
                      <Table.HeaderCell width={3}>Item</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Price</Table.HeaderCell>
                      <Table.HeaderCell width={2}>VAT</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Quantity</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                      { order.orderItems.map((item, index) => (
                        <Table.Row key={index}>
                          <Table.Cell width={3}>
                            <Label>{item.menuItem.name}</Label>
                          </Table.Cell>
                          <Table.Cell width={2}>
                            <Currency value={item.price} />
                          </Table.Cell>
                          <Table.Cell width={2}>
                            <Currency value={item.vatPerItem} />
                          </Table.Cell>
                          <Table.Cell width={2}>
                            <Label>{item.quantity}</Label>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
     
            </>
          )
        )}
      </Modal.Body>
    </Modal>
  )
};
export default CashlessSaleModal;
