import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { formatDate, formatTime } from "utils/dateTime";
import { StructuredList, TextInput, TextInputType, Card } from "ui-kit";
import OnCallStatusChip from "./onCallStatusChip";
import OnCallModalContentWrapper from "./onCallModalContentWrapper";
import ReadOnlyDataList, { DetentionDataType } from "areas/behaviour/components/readOnly/readOnlyDataList";
import { OnCallRequestDetailView } from "areas/analytics/reducers/onCallAnalyticsResponse.types";
import { UserTinyView } from "types/users/userListViews.types";
import { OnCallStatus } from "areas/analytics/reducers/onCallAnalyticsShared.types";


interface IManageOnCallFormProps {
  onCallRequest: OnCallRequestDetailView;
  handleStudentListChange: (students: UserTinyView[]) => void;
  internalNotes: string;
  onChangeInternalNotes: (value: string) => void;
  readOnly?: boolean;
  claimAndResolve:boolean
}


const ManageOnCallForm: React.FC<IManageOnCallFormProps> = ({
  onCallRequest,
  handleStudentListChange,
  internalNotes,
  onChangeInternalNotes,
  readOnly,
  claimAndResolve,
}) => {

  const [onCallDetails, setOnCallDetails] = useState<DetentionDataType[]>([]);
  const [onCallClaimedDetails, setOnCallClaimedDetails] = useState<DetentionDataType[]>([]);
  const [onCallResolvedDetails, setOnCallResolvedDetails] = useState<DetentionDataType[]>([]);
  const [onCallCancelledDetails, setOnCallCancelledDetails] = useState<DetentionDataType[]>([]);
  const [students, setStudents] = useState<UserTinyView[]>([]);

  useEffect(() => {
    const details = [
      {
        dataType: "Status",
        dataValue: <OnCallStatusChip onCallStatus={onCallRequest.status} />,
      },
      {
        dataType: "Requested by",
        dataValue: `${onCallRequest.requestedBy.firstName} ${onCallRequest.requestedBy.lastName}`,
      },
      {
        dataType: "Requested Date",
        dataValue: formatDate(onCallRequest.createdDate),
      },
      {
        dataType: "Requested Time",
        dataValue: formatTime(onCallRequest.createdDate),
      },
      {
        dataType: "Requested in",
        dataValue: onCallRequest.classGroup
          ? onCallRequest.classGroup.friendlyName
          : onCallRequest.tutorGroup
          ? onCallRequest.tutorGroup.friendlyName
          : null,
      },
      {
        dataType: "Room",
        dataValue: onCallRequest.roomName,
      },
      {
        dataType: "Reason",
        dataValue: onCallRequest.category.categoryDetail.name,
      },
      {
        dataType: "Notes",
        dataValue: onCallRequest.notes,
      },
    ];

    setOnCallDetails(details);

    let claimedDetails;
    if (onCallRequest.claimedBy) {
      claimedDetails = [
        {
          dataType: "Claimed By",
          dataValue: `${onCallRequest.claimedBy.firstName} ${onCallRequest.claimedBy.lastName}`,
        },
        {
          dataType: "Claimed Date",
          dataValue: `${formatDate(onCallRequest.claimedDate)} ${formatTime(
            onCallRequest.claimedDate
          )}`,
        },
      ];
      setOnCallClaimedDetails(claimedDetails);
    }

    let resolvedDetails;
    if (onCallRequest.resolvedBy) {
      resolvedDetails = [
        {
          dataType: "Internal Notes",
          dataValue: onCallRequest.internalNotes,
        },
        {
          dataType: "Resolved By",
          dataValue: `${onCallRequest.resolvedBy.firstName} ${onCallRequest.resolvedBy.lastName}`,
        },
        {
          dataType: "Resolved Date",
          dataValue: `${formatDate(onCallRequest.resolvedDate)} ${formatTime(
            onCallRequest.resolvedDate
          )}`,
        },
      ];
      setOnCallResolvedDetails(resolvedDetails);
    }

    let cancelledDetails;
    if (onCallRequest.cancelledBy) {
      cancelledDetails = [
        {
          dataType: "Internal Notes",
          dataValue: onCallRequest.internalNotes,
        },
        {
          dataType: "Cancelled By",
          dataValue: `${onCallRequest.cancelledBy.firstName} ${onCallRequest.cancelledBy.lastName}`,
        },
        {
          dataType: "Cancelled Date",
          dataValue: `${formatDate(onCallRequest.cancelledDate)} ${formatTime(
            onCallRequest.cancelledDate
          )}`,
        },
      ];
      setOnCallCancelledDetails(cancelledDetails);
    }

    setStudents(onCallRequest.students);
  }, [onCallRequest]);

  return (
    <>
      <OnCallModalContentWrapper
        students={students}
        handleStudentListChange={handleStudentListChange}
      >
        <Card title="On Call Request Details">
          <Card.Body>
            <ReadOnlyDataList data={onCallDetails} />
          </Card.Body>
        </Card>

        {onCallRequest.claimedBy && (
          <Card title="Claim Details">
            <Card.Body>
              <ReadOnlyDataList data={onCallClaimedDetails} />
            </Card.Body>
          </Card>
        )}

        {(onCallRequest.status === OnCallStatus.Claimed || claimAndResolve) && !readOnly ? (
          <Card title="Manage Request">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Internal Notes">
                  <TextInput
                    fluid
                    type={TextInputType.Textarea}
                    placeholder="Notes"
                    value={internalNotes}
                    onChange={value => onChangeInternalNotes(value)}
                  />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
        ) : onCallRequest.status === OnCallStatus.Resolved ? (
          <Card title="Resolve Details">
            <Card.Body>
              <ReadOnlyDataList data={onCallResolvedDetails} />
            </Card.Body>
          </Card>
        ) : (
          onCallRequest.status === OnCallStatus.Cancelled && (
            <Card title="Cancel Details">
              <Card.Body>
                <ReadOnlyDataList data={onCallCancelledDetails} />
              </Card.Body>
            </Card>
          )
        )}
      </OnCallModalContentWrapper>
    </>
  );
};


export default ManageOnCallForm;
