import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { 
  EmptyMessage, 
  Size, 
  Loader, 
  TabBar
} from "ui-kit";
import { Constants } from "configuration";
import { arrays, users } from "utils";
import SmartcardWrapper from "./smartcardWrapper";
import { RootState } from "reducers/store";
import smartcardActions from "areas/payments/actions/smartcardActions";
import { SmartcardDetailView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


const Wrapper = styled.div`
  .tab-bar {
  }
`;


const Smartcard = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { smartcards, loading: loadingSmartcards, error: smartcardsError } = useSelector((state: RootState) => state.smartcards);
  const [unavailable, setUnavailable] = useState<boolean>(false);
  const [_smartcards, _setSmartcards] = useState<SmartcardDetailView[]>();
  const [activeCard, setActiveCard] = useState<SmartcardDetailView>();

  useEffect(() => {
    smartcards && _setSmartcards(
      smartcards.filter(smartcard => smartcard.canTopup && (!smartcard.school.isPrimary || user.userType === Constants.USER_TYPES.STAFF))
    );
  }, [smartcards])

  useEffect(() => {
    setActiveCard(!arrays.isEmpty(_smartcards) ? _smartcards[0] : null)
  }, [_smartcards])

  useEffect(() => {
    if (
      (user?.children.some(x => x.school.enableSmartcardTopup && !x.school.isPrimary)) ||
      (user?.baseSchool?.enableSmartcardTopup && (!user.baseSchool.isPrimary || users.isStaff(user)))
    ) {
      setUnavailable(false);
    } else {
      setUnavailable(true);
    }
  }, [user]);

  useEffect(() => {
    if (user && arrays.isEmpty(smartcards)) {
      smartcardActions.getSmartcards(user.id, cards => {
        if (!arrays.isEmpty(cards)) {
          setActiveCard(cards?.filter(smartcard => smartcard.canTopup && !smartcard.school.isPrimary)?.[0]);
        }
      });
    }
  }, [user, smartcards]);

  const handleTabClicked = (name: string, index: number) => {
    setActiveCard(_smartcards[index]);
  };

  if (loadingSmartcards || !user) {
    return <Loader size={Size.Large} cover />;
  }

  if (smartcardsError) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="An error occurred"
        summary="There was a problem getting the smartcard details"
        cover
      />
    );
  }

  if (unavailable) {
    return (
      <EmptyMessage
        icon="id-card-alt"
        title="Smartcard Topups Unavailable"
        summary="You do not have a child at a school that offers online smartcard topups."
        cover
      />
    );
  }

  if (arrays.isEmpty(_smartcards)) {
    <EmptyMessage
      icon="id-card-alt"
      title="Smartcard not found"
      summary="No smartcard details were found"
      cover
    />;
  }

  return (
    <Wrapper>
      {_smartcards && _smartcards.length > 1 && (user.userType.toLowerCase() != Constants.USER_TYPES.STAFF.toLowerCase()) && (
        <TabBar onItemClicked={handleTabClicked}>
          {_smartcards
            .filter((x) => x.canTopup && !x.school.isPrimary)
            .map((card, index) => (
              <TabBar.Item
                key={index}
                name={`${card.user.id}${card.school.code}`}
                text={`${card.user.firstName} ${card.user.lastName}`}
              />
            ))}
        </TabBar>
      )}

      <SmartcardWrapper smartcard={activeCard} />
    </Wrapper>
  );
};

export default Smartcard;
