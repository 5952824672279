import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import paymentActions from "areas/payments/actions/paymentsActions";
import { useParams, useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import {
  Loader,
  EmptyMessage,
  Size,
  Swatches,
  Title,
  TitleSize,
  Label,
  Message,
  DetailLabel,
  Card,
  Table,
  StructuredList,
  Spacing,
  Currency,
  TextInput,
  TextInputType,
  ActionBar,
  Button,
  ToastService,
  Icon,
  Checkbox,
  ValidationMessage
} from "ui-kit";
import Moment from "react-moment";
import { UserName, Avatar } from "sharedComponents/common";
import { arrays } from "utils";
import { RootState } from "reducers/store";
import { SaveReconciliationCommand, TransactionFilter } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";
import { callbackType } from "types/common/action.types";
import { GroupedTransactionListView, ReconciliationDetailView, TransactionGroupView, TransactionListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { useScroll } from "hooks/useScroll";


const Wrapper = styled.div`
  .action-bar {
    margin-top: ${Spacing.Large}px;
  }

  .external-icon {
    margin-left: ${Spacing.Small}px;
  }
`;


interface groupedTransactions extends GroupedTransactionListView {
  transactionMethods: transactionGroup[];
}

interface transactionGroup extends TransactionGroupView {
  transactions: transactionOverview[];
}

interface transactionOverview extends TransactionListView {
  selected?: boolean;
}


const Reconciliation = () => {

  const navigate = useNavigate();
  const { scrollToTop } = useScroll();

  const { reconciliation, loading, error, saving, saveError } = useSelector((state: RootState) => state.reconciliation);
  const { transactions, loading: transactionsLoading, error: transactionsError } = useSelector((state: RootState) => state.transactions);

  const { reconciliationId } = useParams();

  const [_selectedTransactions, _setSelectedTransactions] = useState<transactionOverview[]>([]);
  const [_transactions, _setTransactions] = useState<groupedTransactions | null>();
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_reconciliationTotal, _setReconciliationTotal] = useState<number>(0);
  const [_reconciledTotal, _setReconciledTotal] = useState<number>(0);


  const loadTransactions = (filter: TransactionFilter, callback: callbackType<GroupedTransactionListView>) => {
    paymentActions.getTransactions(filter, callback);
  };

  useEffect(() => {
    paymentActions.getReconciliation(parseInt(reconciliationId), (reconciliation: ReconciliationDetailView) => {
      _setReconciliationTotal(reconciliation?.total);
      _setSelectedTransactions(reconciliation.transactions);

      var _recTransactions = reconciliation.transactions;

      loadTransactions({
          startDate: new Date(reconciliation.startDate),
          endDate: new Date(reconciliation.endDate)
        },
        (value: GroupedTransactionListView) => {
          var _temp: groupedTransactions = value;
          var _tempSelectedTransactions: transactionOverview[] = [];
          var _total: number = 0;

          _temp.transactionMethods = _temp.transactionMethods?.map((method: transactionGroup)=> ({
            ...method,
            transactions: method.transactions.map((transaction: transactionOverview) => {
              transaction.selected = _recTransactions.some(t => t.id == transaction.id);
              _tempSelectedTransactions.push(transaction);
              if (transaction.selected) {
                _total += transaction.total;
              }
              return transaction;
            })
          }));

          _setTransactions(_temp);
          _setSelectedTransactions(_tempSelectedTransactions);
          _setReconciledTotal(_total);
        }
      );
    });
  }, []);

  useEffect(() => {
    saveError && scrollToTop();
  }, [saveError]);

  const handleRowCheckChange = (row: transactionOverview, checked: boolean) => {
    if (checked) {
      // Add to list
      _setSelectedTransactions([..._selectedTransactions, row]);
      _setReconciledTotal(_reconciledTotal + row.total);
    } else {
      // Remove from list
      _setSelectedTransactions(arrays.removeItem(_selectedTransactions, row));
      _setReconciledTotal(_reconciledTotal - row.total);
    }
  };

  const handleSaveReconciliation = () => {
    var difference = _reconciliationTotal - _reconciledTotal;

    if (
      difference == 0 ||
      window.confirm(
        `There is a difference of £${difference.toFixed(
          2
        )} between the closing balance (£${_reconciliationTotal.toFixed(
          2
        )}) and the reconciled transaction total (£${_reconciledTotal.toFixed(
          2
        )}) - do you want to proceed to save this reconciliation?`
      )
    ) {
      var data: SaveReconciliationCommand = {
        id: reconciliation.id,
        total: _reconciliationTotal,
        transactions: _selectedTransactions.map(t => t.id)
      };

      paymentActions.saveReconciliation(data, () => {
        navigate(-1);
        ToastService.pop("Reconciliation saved successfully", null, "receipt");
      });
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    console.log(error);
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem loading the reconciliation"
        cover
      />
    );
  }

  if (!reconciliation) {
    return (
      <EmptyMessage
        icon="receipt"
        title="Not found"
        summary="The requested reconciliation was not found"
        cover
      />
    );
  }

  return (
    <Wrapper>
      <Title
        size={TitleSize.H2}
        text="Reconciliations"
        sub={
          <>
            <Moment date={reconciliation.startDate} format="DD/MM/YYYY" />
            {" to "}
            <Moment date={reconciliation.endDate} format="DD/MM/YYYY" />
          </>
        }
      />
      {saveError && <Message text={saveError} color={Swatches.Danger} />}
      <ValidationMessage errors={validationErrors} />
      <Card>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Created By">
              <Avatar user={reconciliation.createdBy} />
            </StructuredList.Item>
            <StructuredList.Item name="Start Date">
              <Moment date={reconciliation.startDate} format="DD/MM/YYYY" />
            </StructuredList.Item>
            <StructuredList.Item name="End Date">
              <Moment date={reconciliation.endDate} format="DD/MM/YYYY" />
            </StructuredList.Item>
            <StructuredList.Item name="Closing Balance">
              <TextInput
                type={TextInputType.Currency}
                value={_reconciliationTotal}
                onChange={value => _setReconciliationTotal(value)}
                fluid
              />
            </StructuredList.Item>
            <StructuredList.Item name="Reconciled Transaction Total">
              <Currency value={_reconciledTotal} />
            </StructuredList.Item>
            <StructuredList.Item name="Difference">
              <Currency value={_reconciliationTotal - _reconciledTotal} />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      {transactionsLoading ? (
        <Loader size={Size.Large} cover />
      ) : (
        _transactions && (
          <>
            <Table>
              <Table.Header>
                <Table.HeaderCell width={0.5}>Rec</Table.HeaderCell>
                <Table.HeaderCell>Account</Table.HeaderCell>
                <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                <Table.HeaderCell>Transaction ID</Table.HeaderCell>
                <Table.HeaderCell width={0.75}>Basket</Table.HeaderCell>
                <Table.HeaderCell width={1}>Date</Table.HeaderCell>
                <Table.HeaderCell width={1}>Card Type</Table.HeaderCell>
                <Table.HeaderCell width={0.75} right>
                  Reference
                </Table.HeaderCell>
                <Table.HeaderCell width={0.75} right>
                  Total
                </Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {_transactions?.transactionMethods?.map((method: transactionGroup, index: number) => (
                  <>
                    <Table.GroupHeader
                      label={method.methodName}
                      colspan={9}
                      key={index}
                    />
                    {method.transactions.map((transaction, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>
                          <Checkbox
                            onChange={value => handleRowCheckChange(transaction, value.checked)}
                            checked={transaction.selected}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Label bold>
                            <Link
                              to={`/main/shopkeeper/users/${transaction.user.id}`}
                              target="_blank"
                            >
                              <UserName user={transaction.user} />{" "}
                              <Icon
                                value="external-link-alt"
                                className="external-icon"
                              />
                            </Link>
                          </Label>
                        </Table.Cell>
                        <Table.Cell>{transaction.status}</Table.Cell>
                        <Table.Cell>
                          <DetailLabel
                            label={transaction.id}
                            // sub={transaction.internalTransactionId}
                          />
                        </Table.Cell>
                        <Table.Cell>{transaction.basketId}</Table.Cell>
                        <Table.Cell>
                          <DetailLabel
                            label={
                              <Moment
                                date={transaction.date}
                                format="DD/MM/YYYY"
                              />
                            }
                            sub={
                              <Moment date={transaction.date} format="hh:mm" />
                            }
                          />
                        </Table.Cell>
                        <Table.Cell>{transaction.cardType}</Table.Cell>
                        <Table.Cell right>
                          <DetailLabel
                            label={transaction.reference}
                            sub={transaction.expiryDate}
                          />
                        </Table.Cell>
                        <Table.Cell right>
                          <Currency value={transaction.total} />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </>
                ))}
              </Table.Body>
              <Table.Footer>
                <Table.GroupHeader label="Reconciliation Totals" colspan={9} />
                <Table.Row>
                  <Table.Cell colspan={3}>
                    <Label bold>Closing Balance</Label>{" "}
                    <Currency value={_reconciliationTotal} />
                  </Table.Cell>
                  <Table.Cell colspan={3} right>
                    <Label bold>Difference</Label>{" "}
                    <Currency value={_reconciliationTotal - _reconciledTotal} />
                  </Table.Cell>
                  <Table.Cell colspan={3} right>
                    <Label bold>Reconciled</Label>{" "}
                    <Currency value={_reconciledTotal} />
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            </Table>

            <ActionBar>
              <Button
                text="Save Reconciliation"
                color={Swatches.Success}
                onClick={handleSaveReconciliation}
                working={saving}
              />
              <Button
                text="Cancel"
                color={Swatches.Low}
                onClick={handleCancel}
                working={saving}
              />
            </ActionBar>
          </>
        )
      )}
    </Wrapper>
  );
};

export default Reconciliation;
