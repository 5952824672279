export const EVENT_ATTENDANCE = {
  PENDING: 0,
  ATTENDED: 1,
  MISSED: 2,
};

export const SIGN_UP_STATUS = {
  SIGNED_UP: 0,
  CANCELLED: 2,
  NOT_ENROLLED: 100,
};
