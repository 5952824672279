import studentActions from "../../actions/student/studentActions";

const INITIAL_STATE = {
  consentForms: [],
  loading: false,
  error: null,
};

const { GETSTUDENTCOMPLETEDCONSENTFORMS } = studentActions.types;

const studentConsentFormsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GETSTUDENTCOMPLETEDCONSENTFORMS.START:
      return { ...state, loading: true, error: null };

    case GETSTUDENTCOMPLETEDCONSENTFORMS.SUCCESS:
      return { ...state, consentForms: action.payload, loading: false };

    case GETSTUDENTCOMPLETEDCONSENTFORMS.FAILED:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default studentConsentFormsReducer;
