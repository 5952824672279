import { IBeehiveError } from "types/common/errors.types";
import { Constants } from "../../../../configuration";
import assignmentActions from "../../actions/assignments/assignmentActions";
import assignmentCommentActions from "../../actions/assignments/assignmentCommentActions";
import assignmentEditorActions from "../../actions/assignments/assignmentEditorActions";
import assignmentPublishActions from "../../actions/assignments/assignmentPublishActions";
import { AssignmentDetailView } from "types/planner/assignments.types";
import { SubmissionListView } from "areas/planner/types/assignments/assignmentResponse.types";
import { CommentListView } from "areas/news/types/newsResponse.types";
import { IBeehiveAction } from "types/common/action.types";


interface IAssignmentState {
  selected: number;
  assignment: AssignmentDetailView;
  loading: boolean;
  error: IBeehiveError;
  submissions: {
    data: SubmissionListView[];
    loading: boolean;
    error: IBeehiveError;
    submitting: boolean;
    refresh: boolean;
  };
  comments: {
    data: CommentListView[];
    loading: boolean;
    error: IBeehiveError;
    submitting: boolean;
    refresh: boolean;
    working: boolean;
  };
}


const INITIAL_STATE: IAssignmentState = {
  selected: null,
  assignment: null,
  loading: false,
  error: null,
  submissions: {
    data: [],
    loading: false,
    error: null,
    submitting: false,
    refresh: false,
  },
  comments: {
    data: [],
    loading: false,
    error: null,
    submitting: false,
    refresh: false,
    working: false,
  },
};

const updateComments = (newComment: CommentListView, comments: CommentListView[]) : CommentListView[] => {
  return comments.map(c =>
    c.id === newComment.parentId
      ? { ...c, comments: [...c.comments, newComment] }
      : { ...c, comments: updateComments(newComment, c.comments) }
  );
};

const updateComment = (newComment: CommentListView, comments: CommentListView[]) : CommentListView[] => {
  return comments.map(c =>
    c.id === newComment.id
      ? newComment
      : { ...c, comments: updateComment(newComment, c.comments) }
  );
};

const assignmentReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAssignmentState => {

  const {
    GETASSIGNMENT,
    SELECTASSIGNMENT,
    MARKREAD,
    READ,
    GETSUBMISSIONS,
    GETCOMMENTS,
    SUBMITASSIGNMENT,
    SUBMITTED,
  } = assignmentActions.types;
  const { CREATE, UPDATE } = assignmentEditorActions.types;
  const { PUBLISHED, UNPUBLISHED, PUBLISH, UNPUBLISH } =
    assignmentPublishActions.types;
  const { POSTED, FLAG, APPROVE, REMOVE } = assignmentCommentActions.types;

  switch (action.type) {
    case SELECTASSIGNMENT:
      return { ...state, selected: action.payload, assignment: null };

    case GETASSIGNMENT.START:
      return { ...state, loading: true };

    case GETASSIGNMENT.SUCCESS:
      return {
        ...state,
        assignment: action.payload,
        loading: false,
      };

    case GETASSIGNMENT.FAILED:
      return { ...state, loading: false, error: action.payload };

    case CREATE.SUCCESS: // This needs to select the new assignment
      return {
        ...state,
        assignment: action.payload,
        selected: action.payload.id,
      };

    case UPDATE.SUCCESS: // This needs to update the selected assignment
      return {
        ...state,
        assignment: action.payload,
      };

    case PUBLISHED:
    case PUBLISH.SUCCESS:
      if (state.assignment && state.assignment.id === action.payload.id) {
        return {
          ...state,
          assignment: {
            ...state.assignment,
            publishStatus: Constants.PUBLISH_STATUS.LIVE.value,
          },
        };
      }
      return { ...state };

    case UNPUBLISHED:
    case UNPUBLISH.SUCCESS:
      if (state.assignment && state.assignment.id === action.payload.id) {
        return {
          ...state,
          assignment: {
            ...state.assignment,
            publishStatus: Constants.PUBLISH_STATUS.DRAFT.value,
          },
        };
      }
      return { ...state };

    case GETSUBMISSIONS.START:
      return {
        ...state,
        submissions: {
          ...state.submissions,
          data: [],
          refresh: false,
          loading: true,
        },
      };

    case GETSUBMISSIONS.SUCCESS:
      return {
        ...state,
        submissions: {
          ...state.submissions,
          data: action.payload,
          loading: false,
        },
        loading: false,
      };

    case GETSUBMISSIONS.FAILED:
      return {
        ...state,
        submissions: {
          ...state.submissions,
          loading: false,
          error: action.payload,
        },
      };

    case GETCOMMENTS.START:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: [],
          error: null,
          refresh: false,
          loading: true,
        },
      };

    case GETCOMMENTS.SUCCESS:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: action.payload,
          loading: false,
        },
        loading: false,
      };

    case GETCOMMENTS.FAILED:
      return {
        ...state,
        comments: {
          ...state.comments,
          loading: false,
          error: action.payload,
        },
      };

    case MARKREAD.SUCCESS:
      if (
        state.assignment &&
        state.assignment.id === action.payload.assignmentId
      ) {
        return {
          ...state,
          assignment: { ...state.assignment, isRead: true },
        };
      }
      return state;

    case READ:
      if (
        state.assignment &&
        state.assignment.id == action.payload.assignmentId
      ) {
        return {
          ...state,
          assignment: {
            ...state.assignment,
            isComplete: true,
            isOverdue: false,
          },
          submissions: {
            ...state.submissions,
            data: state.submissions.data
              ? state.submissions.data.map(sub =>
                  sub.student.id === action.payload.student.id
                    ? {
                        ...sub,
                        readOn: action.payload.readOn,
                      }
                    : sub
                )
              : [],
          },
        };
      }
      return state;

    case SUBMITASSIGNMENT.START:
      return {
        ...state,
        submissions: { ...state.submissions, submitting: true },
      };

    case SUBMITASSIGNMENT.SUCCESS:
      if (
        state.assignment &&
        state.assignment.id === action.payload.assignmentId
      ) {
        return {
          ...state,
          assignment: {
            ...state.assignment,
            isComplete: true,
            isOverdue: false,
          },
          submissions: { ...state.submissions, submitting: false },
        };
      }
      return {
        ...state,
        submissions: { ...state.submissions, submitting: false },
      };

    case SUBMITASSIGNMENT.FAILED:
      return {
        ...state,
        submissions: {
          ...state.submissions,
          submitting: false,
          error: action.payload,
        },
      };

    case SUBMITTED:
      if (
        state.assignment &&
        state.assignment.id == action.payload.assignmentId
      ) {
        return {
          ...state,
          assignment: {
            ...state.assignment,
            isComplete: true,
            isOverdue: false,
          },
          submissions: {
            ...state.submissions,
            data: state.submissions.data
              ? state.submissions.data.map(sub =>
                  sub.student.id === action.payload.student.id
                    ? {
                        ...sub,
                        completedOn: action.payload.completedOn,
                        readOn: action.payload.readOn,
                      }
                    : sub
                )
              : [],
          },
        };
      }
      return state;

    case POSTED:
      return {
        ...state,
        assignment:
          state.assignment != null
            ? {
                ...state.assignment,
                commentsCount: (state.assignment.commentsCount += 1),
              }
            : state.assignment,
        comments: {
          ...state.comments,
          data:
            action.payload.parentId == null
              ? [action.payload, ...state.comments.data]
              : updateComments(action.payload, state.comments.data),
        },
      };

    case FLAG.START:
    case APPROVE.START:
    case REMOVE.START:
      return { ...state, comments: { ...state.comments, error: null } };

    case FLAG.SUCCESS:
    case APPROVE.SUCCESS:
    case REMOVE.SUCCESS:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: updateComment(action.payload, state.comments.data),
        },
      };

    case FLAG.FAILED:
    case APPROVE.FAILED:
    case REMOVE.FAILED:
      return {
        ...state,
        comments: { ...state.comments, error: action.payload },
      };

    default:
      return state;
  }
};

export default assignmentReducer;
