import { LessonExitGroupBy } from "../types/lessonExitAnalyticShared.types";


export const GROUP_BY_ARRAY = [
  {
    label: "Teacher",
    value: LessonExitGroupBy.Teacher,
  },
  {
    label: "Student",
    value: LessonExitGroupBy.Pupil,
  },
  {
    label: "Student Gender",
    value: LessonExitGroupBy.PupilGender,
  },
  {
    label: "Year Group",
    value: LessonExitGroupBy.YearGroup,
  },
  {
    label: "Tutor Group",
    value: LessonExitGroupBy.TutorGroup,
  },
  {
    label: "Class Group",
    value: LessonExitGroupBy.ClassGroup,
  },
  {
    label: "Provision Group",
    value: LessonExitGroupBy.ProvisionGroup,
  },
  {
    label: "Subject",
    value: LessonExitGroupBy.Subject,
  },
  {
    label: "Reason for Leaving Class",
    value: LessonExitGroupBy.Category,
  },
  {
    label: "Date",
    value: LessonExitGroupBy.Date,
  },
  {
    label: "PP",
    value: LessonExitGroupBy.PP,
  },
  {
    label: "SEN",
    value: LessonExitGroupBy.SEN,
  },
];
