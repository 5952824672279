import { IBeehiveAction } from "types/common/action.types";
import behaviourAnalyticsActions from "../actions/behaviourAnalyticsActions";
import { GenerateBehaviourReportView } from "../types/behaviourAnalyticsResponse.types";
import { IBeehiveError } from "types/common/errors.types";


interface IBehaviourAnalyticsState {
  report: GenerateBehaviourReportView | null;
  refresh: boolean;
  loading: boolean;
  loadingExportReport: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IBehaviourAnalyticsState = {
  report: null,
  refresh: false,
  loading: false,
  loadingExportReport: false,
  error: null,
};


const behaviourAnalyticsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IBehaviourAnalyticsState => {

  const { 
    GETBEHAVIOURREPORT, 
    EXPORTBEHAVIOURREPORT 
  } = behaviourAnalyticsActions.types;

  switch (action.type) {
    case GETBEHAVIOURREPORT.START:
      return { ...state, loading: true, error: null };

    case GETBEHAVIOURREPORT.SUCCESS:
      return { ...state, report: action.payload, loading: false };

    case GETBEHAVIOURREPORT.FAILED:
      return { ...state, loading: false, error: action.payload };

    case EXPORTBEHAVIOURREPORT.START:
      return { ...state, loadingExportReport: true, error: null };

    case EXPORTBEHAVIOURREPORT.SUCCESS:
      return { ...state, loadingExportReport: false };

    case EXPORTBEHAVIOURREPORT.FAILED:
      return { ...state, loadingExportReport: false, error: action.payload };

    default:
      return state;
  }
};

export default behaviourAnalyticsReducer;
