import React, { useEffect, useState } from "react";
import {
  Title,
  TitleSize,
  Loader,
  EmptyMessage,
  Card,
  Size,
  ActionBar,
  Button,
  Swatches,
  Table,
  Checkbox,
  SplitButton,
  HeadlineStatistic,
} from "ui-kit";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { arrays, strings } from "utils";
import documentActions from "areas/administration/actions/documentActions";
import Subheader from "sharedComponents/layout/header/subheader";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import SchoolIcon from "sharedComponents/common/schools/icons/schoolIcon";
import Avatar from "sharedComponents/common/users/avatar";
import documentGroupActions from "areas/administration/actions/documentGroupActions";
import { documentRoutes } from "areas/administration/adminRoutes";
import { studentRoutes } from "areas/planner/plannerRoutes";
import { RootState } from "reducers/store";
import { DocumentGroupFilter } from "areas/administration/types/documentRequest.types";
import { documentList } from "areas/administration/reducers/userManagement/documentGroupReducer";
import moment from "moment";


const Viewer = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const {
    error,
    group,
    loading,
    documents,
    loadingDocuments,
    documentsError,
    paging,
  } = useSelector((state: RootState) => state.documentGroup);
  const [filter, setFilter] = useState<DocumentGroupFilter>({ schoolId: -1 });

  useEffect(() => {
    id &&
      documentGroupActions.getDocumentGroup(parseInt(id), () => {
        documentGroupActions.getDocumentGroupDocuments(parseInt(id), filter, 0);
      });
  }, [id]);

  const handleCancel = () => {
    navigate(-1);
  };

  const handlePage = () => {
    console.log(paging);
    documentGroupActions.getDocumentGroupDocuments(
      parseInt(id),
      filter,
      paging.pageIndex + 1
    );
  };

  const goToUpload = () => {
    navigate(documentRoutes.getUploadPath(group.id));
  };

  const handleFileDownload = (document: documentList) => {
    documentActions.downloadDocument(document.user.id, document.id);
  };

  const handleSchoolFilterChange = (value: number) => {
    var _filter = { ...filter, schoolId: value };
    setFilter(_filter);
    documentGroupActions.getDocumentGroupDocuments(parseInt(id), _filter, 0);
  };

  const handleDelete = () => {
    if (
      window.confirm(
        "This will delete the selected document(s). This cannot be undone. Are you sure?"
      )
    ) {
      const data = {
        documents: documents.filter(x => x.selected).map(x => x.id),
        documentGroupId: group.id,
      };
      documentGroupActions.deleteDocuments(data.documentGroupId, data, () => {
        documentGroupActions.getDocumentGroupDocuments(parseInt(id), filter, 0);
      });
    }
  };

  const selectDocument = (document: documentList) => {
    documentGroupActions.toggleDocumentSelection(document.id);
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="An error occurred"
        summary="There was an error while retrieving the document group."
        cover
      />
    );
  }

  if (!group) {
    return (
      <EmptyMessage
        icon="file-certificate"
        title="Not Found"
        summary="The requested document group was not found."
        cover
      />
    );
  }

  return (
    <>
      <Subheader>
        <Title size={TitleSize.H2} text="Documents" sub={group.name} />
      </Subheader>

      <HeadlineStatistic>
        <HeadlineStatistic.Item
          icon="file-certificate"
          label="Documents"
          value={group.documentCount}
        />
      </HeadlineStatistic>

      <ActionBar>
        <SchoolDropdown
          includeAllOption
          initialValue={filter?.schoolId}
          onChange={handleSchoolFilterChange}
        />
      </ActionBar>
      <Card grow>
        <Card.Body noPad>
          <Table
            zebra
            loading={loadingDocuments}
            error={documentsError != null}
            grow
            empty={arrays.isEmpty(documents)}
            emptyMessage={
              <EmptyMessage
                icon="file-certificate"
                title="Not Found"
                summary="No documents have been uploaded yet."
                cover
              >
                <Button
                  size={Size.Small}
                  color={Swatches.Primary}
                  text="Upload Documents"
                  onClick={goToUpload}
                />
              </EmptyMessage>
            }
          >
            <Table.Header>
              <Table.HeaderCell width={0.5} />
              <Table.HeaderCell width={0.5} center>
                School
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>Student</Table.HeaderCell>
              <Table.HeaderCell width={2.5}>Filename</Table.HeaderCell>
              <Table.HeaderCell width={2.5} show={group?.recordReadReciepts}>Read Date</Table.HeaderCell>
              <Table.HeaderCell width={1} />
            </Table.Header>
            <Table.Body onPage={handlePage} paging={paging}>
              {documents.map((document, index) => (
                <Table.Row key={index}>
                  <Table.Cell width={0.5}>
                    <Checkbox
                      checked={document.selected}
                      onChange={() => selectDocument(document)}
                    />
                  </Table.Cell>
                  <Table.Cell width={0.5} center>
                    <SchoolIcon code={document.user?.school?.code} />
                  </Table.Cell>
                  <Table.Cell width={3}>
                    <Avatar
                      user={document.user}
                      size={Size.Small}
                      sub={document.user.simsId}
                    />
                  </Table.Cell>
                  <Table.Cell width={2.5}>{document.file.filename}</Table.Cell>
                  <Table.Cell width={2.5} show={group?.recordReadReciepts}>
                    { document.readDate
                      ? moment(document.readDate).format("HH:mm DD/MM/YYYY")
                      : "Unread"
                    }
                  </Table.Cell>
                  <Table.Cell width={1} right>
                    <SplitButton
                      size={Size.Small}
                      text="Download"
                      color={Swatches.Default}
                      onDefaultClick={() => handleFileDownload(document)}
                    >
                      <SplitButton.Option
                        onClick={() => handleFileDownload(document)}
                        text="Download"
                        show={false}
                      />
                      <SplitButton.Option
                        onClick={() =>
                          navigate(
                            studentRoutes.getStudentPath(document.user.id)
                          )
                        }
                        text="View Student"
                        show
                      />
                    </SplitButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.Cell colspan={4} right>
                  <ActionBar low>
                    <Button
                      size={Size.Small}
                      color={Swatches.Danger}
                      text={`Delete ${
                        documents.filter(x => x.selected).length
                          ? documents.filter(x => x.selected).length
                          : ""
                      } ${strings.pluralize(
                        "Document",
                        "Documents",
                        documents.filter(x => x.selected).length
                      )}`}
                      onClick={handleDelete}
                      disabled={!documents.some(x => x.selected)}
                    />
                    <Button
                      size={Size.Small}
                      color={Swatches.Primary}
                      text="Upload Documents"
                      onClick={goToUpload}
                    />
                  </ActionBar>
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default Viewer;
