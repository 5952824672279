import React, { useState } from "react";
import { Card, StructuredList, Wizard, TextInput, TextInputType } from "ui-kit";
import PasswordStrengthResult from "./passwordStrengthResult";
import PasswordGuidance from "./passwordGuidance";
import { useNavigate } from "react-router-dom";
import passwordActions from "areas/administration/actions/passwordActions";
import { CheckPasswordResponse } from "types/users/userPassword.types";


const PasswordStrengthChecker = () => {

  const [results, setResults] = useState<CheckPasswordResponse | null>();
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const validatePassword = () => {
    return new Promise((resolve, reject) => {
      if (!password) {
        reject("Please enter a password.");
      } else {
        passwordActions
          .check(password)
          .then(response => {
            setResults(response);
            resolve(response);
          })
          .catch(error => {
            reject(
              "Your password could not be analysed at this time. Please check them and try again."
            );
          });
      }
    });
  };

  const handleFinish = () => {
    return new Promise((resolve, reject) => resolve(true));
  };

  const handleComplete = () => {
    navigate(0);
  };

  return (
    <Wizard
      showHeaderSteps={false}
      onFinish={handleFinish}
      onComplete={handleComplete}
      finishButtonText="Check My Password"
      completionButtonText="Check Another Password"
    >
      <Wizard.Step>
        <Card>
          <Card.Body>
            <p>
              This tool lets you check your the <b>strength of your password</b>{" "}
              and if it <b>has been compromised</b>. Simply enter any password
              and the tool will check it for you.
            </p>

            <PasswordGuidance />
            <p>
              To begin, click on <b>'Next'</b> below.
            </p>
          </Card.Body>
        </Card>
      </Wizard.Step>
      <Wizard.Step onValidate={validatePassword}>
        <Card>
          <Card.Body>
            <p>
              To check your password, simply enter your password below and click
              on <b>'Check My Password'</b> button. Your password will be
              analysed and the results displayed.
            </p>

            <StructuredList>
              <StructuredList.Item name="Your Password" required>
                <TextInput
                  value={password}
                  onChange={value => setPassword(value)}
                  type={TextInputType.Password}
                  fluid
                  maxLength={50}
                />
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      </Wizard.Step>
      <Wizard.Step completion>
        <Card>
          <Card.Body>
            {results && (
              <PasswordStrengthResult result={results} />
            )}
          </Card.Body>
        </Card>
      </Wizard.Step>
    </Wizard>
  );
};

export default PasswordStrengthChecker;
