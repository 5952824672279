import store from "reducers/store";
import { IBeehiveAction } from "types/common/action.types";

const types = {
  SMARTCARDS_FILTERBYSTATUS: "SMARTCARDS_FILTERBYSTATUS",
  SMARTCARDS_FILTERBYSCHOOL: "SMARTCARDS_FILTERBYSCHOOL",
  SMARTCARDS_FILTERBYSEARCHTERM: "SMARTCARDS_FILTERBYSEARCHTERM",
};

const filterBySchool = (schoolId: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.SMARTCARDS_FILTERBYSCHOOL,
    payload: schoolId,
  });
};

const filterByStatus = (status: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.SMARTCARDS_FILTERBYSTATUS,
    payload: status,
  });
};

const filterBySearchTerm = (searchTerm: string) => {
  store.dispatch<IBeehiveAction<string>>({
    type: types.SMARTCARDS_FILTERBYSEARCHTERM,
    payload: searchTerm,
  });
};

const smartcardFilterActions = {
  types,
  filterBySchool,
  filterByStatus,
  filterBySearchTerm,
};

export default smartcardFilterActions;
