import store from "reducers/store";
import tokenStorage from "services/authentication/tokenStorage";
import { IBeehiveAction } from "types/common/action.types";

const types = {
  LOGOUT: "LOGOUT",
  FORCE_LOGOUT: "FORCE_LOGOUT",
};

const logout = (callback?: () => void) => {
  tokenStorage.removeTokens();
  callback?.();

  store.dispatch<IBeehiveAction<null>>({
    type: types.LOGOUT,
    payload: null,
  });
};

const authActions = {
  types,
  logout,
};

export default authActions;