import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Subheader from "sharedComponents/layout/header/subheader";
import {
  Title,
  TitleSize,
  StructuredList,
  Card,
  EmptyMessage,
  Loader,
  Size,
  Tabs,
} from "ui-kit";
import Moment from "react-moment";
import { Address, SchoolAvatar } from "sharedComponents/common";
import { arrays, types, users } from "utils";
import profileActions from "areas/administration/actions/profileActions";
import { useNavigate } from "react-router";
import routes from "configuration/routes";
import { RootState } from "reducers/store";
import { managedParent } from "areas/administration/reducers/userManagement/managedUserReducer";


const Profile = () => {

  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { profile, loading, error } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    user && profileActions.getProfile(user.id);
  }, [user]);

  const updateDetails = (userId: string) => {
    navigate(routes.profile.getUpdateUserProfilePath(userId));
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }
  if (error) {
    return (
      <EmptyMessage
        cover
        icon="user"
        title="An error occurred"
        summary="Unfortunately, there was a problem while loading your profile information"
      />
    );
  }

  if (!profile) {
    return (
      <EmptyMessage
        icon="user"
        title="Profile Not Found"
        summary="Unfortunately, your profile information could not be loaded"
        cover
      />
    );
  }

  return (
    <>
      <Subheader>
        <Title size={TitleSize.H2} text="Your Account" />
      </Subheader>

      <Card title="Your Details">
        <Card.Body>
          <p>
            These are the details that currently we hold for you.
            {/* If you would like to
            change any of these details, you can update them on the {"  "}
            <Link to={`/main/profile/update`}>Data Update Request</Link> page. */}
          </p>

          <StructuredList>
            <StructuredList.Item name="First Name">
              {profile?.firstName}
            </StructuredList.Item>
            <StructuredList.Item name="Last Name">
              {profile?.lastName}
            </StructuredList.Item>
            {profile?.dateOfBirth && (
              <StructuredList.Item name="Date of Birth">
                <Moment date={profile?.dateOfBirth} format="Do MMMM YYYY" />
              </StructuredList.Item>
            )}
            <StructuredList.Item name="Email">
              {profile?.email}
            </StructuredList.Item>
            {profile?.address && (
              <StructuredList.Item name="Address">
                <Address address={profile?.address} />
              </StructuredList.Item>
            )}
            <>
            {!arrays.isEmpty(profile?.telephoneNumbers) &&
              profile.telephoneNumbers.map((number, index) => (
                <StructuredList.Item
                  name={`${number.location} Telephone`}
                  key={index}
                >
                  {number.number}
                </StructuredList.Item>
              ))}
            </>
          </StructuredList>
        </Card.Body>
        {/* <Card.Footer>
          <Button
            text="Update Your Details"
            onClick={() => updateDetails(profile.id)}
            color={Swatches.Primary}
          />
        </Card.Footer> */}
      </Card>

      {types.isType<managedParent>(profile, "children") && !arrays.isEmpty(profile.children) && (
        <>
          <Title text="Your Children" size={TitleSize.H3} />
          <Tabs>
            {profile.children.map((child, index) => (
              <Tabs.Pane
                key={index}
                label={users.getFullName(child)}
                name={index.toString()}
              >
                <Card title={users.getFullName(child)}>
                  <Card.Body>
                    <StructuredList>
                      <StructuredList.Item name="First Name">
                        {child.firstName}
                      </StructuredList.Item>
                      <StructuredList.Item name="Last Name">
                        {child.lastName}
                      </StructuredList.Item>
                      {child.dateOfBirth && (
                        <StructuredList.Item name="Date of Birth">
                          <Moment
                            date={child.dateOfBirth}
                            format="Do MMMM YYYY"
                          />
                        </StructuredList.Item>
                      )}
                      <StructuredList.Item name="School">
                        <SchoolAvatar school={child.school} />
                      </StructuredList.Item>
                      <StructuredList.Item name="Email">
                        {child.email}
                      </StructuredList.Item>
                      <StructuredList.Item name="Admission Number">
                        {child.school.code}
                        {child.simsId}
                      </StructuredList.Item>
                      {child.address && (
                        <StructuredList.Item name="Address">
                          <Address address={child.address} />
                        </StructuredList.Item>
                      )}
                    </StructuredList>
                  </Card.Body>
                  {/* <Card.Footer>
                    <Button
                      text={`Update ${users.getPossessive(child)} Details`}
                      onClick={() => updateDetails(child.id)}
                      color={Swatches.Primary}
                    />
                  </Card.Footer> */}
                </Card>
              </Tabs.Pane>
            ))}
          </Tabs>
        </>
      )}
    </>
  );
};
export default Profile;
