import React from "react";
import { ReactComponent as SheepSvg } from "assets/primary/characters/sheep.svg";
import { IAnimalIconProps } from "./primaryCharacter";


export const Sheep: React.FC<IAnimalIconProps> = ({ className }) => {

  return (
    <SheepSvg 
      //alt={alt} 
      className={className}
    />
  )
};
export default Sheep;
