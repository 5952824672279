import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Size } from "ui-kit/common/size";
import {
  ActionBar,
  Button,
  Right,
  Spacing,
  Sub,
  Swatches,
  Loader,
  EmptyMessage,
  Dropdown,
  Left,
  neutral,
} from "ui-kit";
import styled from "styled-components";
import StudentNotesList from "./studentNotesList";
import NewStudentNoteModal from "./newStudentNoteModal";
import {
  NOTE_TYPES,
  NOTE_TYPE_KEY,
} from "areas/planner/constants/student/notes";
import studentNoteActions from "areas/planner/actions/student/studentNoteActions";
import { addGroupIdToPayload } from "areas/planner/utils/group";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { arrays } from "../../../../../utils";

const MessageWrapper = styled.div`
  text-align: right;
  padding-bottom: ${Spacing.Small}px;
  .sub {
    color: ${neutral[600]};
  }
`;

const StudentNotesListWrapper = ({ student, group }) => {
  const {
    studentNotes,
    paging,
    refresh,
    loading: loadingNotes,
    error: errorGettingNotes,
  } = useSelector(state => state.studentNotes);
  const {
    studentNoteCategories,
    loading: loadingNoteCategories,
    error: noteCategoriesError,
  } = useSelector(state => state.studentNoteCategories);
  const { loading, createError, deleteError } = useSelector(
    state => state.studentNote
  );

  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [noteTypeFilters, setNoteTypeFilters] = useState([]);

  const [noteTypeFilter, setNoteTypeFilter] = useState(0);
  const [noteProps, setNoteProps] = useState({
    note: "",
    noteType: 0,
  });

  useEffect(() => {
    if (arrays.isEmpty(studentNoteCategories)) {
      studentNoteActions.getStudentNoteCategories();
    }
  }, []);

  useEffect(() => {
    const noteFilters = [
      {
        label: "All",
        value: 0,
      },
    ];

    if (!arrays.isEmpty(studentNoteCategories)) {
      studentNoteCategories.forEach(category => {
        noteFilters.push({
          label: category.name,
          value: category.id,
        });
      });
    }

    setNoteTypeFilters(noteFilters);
  }, [studentNoteCategories]);

  const handleGetNotes = () => {
    studentNoteActions.getStudentNotes(student.id, noteTypeFilter);
  };

  useEffect(() => {
    handleGetNotes();
  }, [student, noteTypeFilter]);

  useEffect(() => {
    if (refresh) {
      handleGetNotes();
    }
  }, [refresh]);

  const handleCreateNote = (noteCategoryId, content) => {
    let newNote = {
      id: null,
      studentNoteCategoryId: noteCategoryId,
      studentId: student.id,
      content: content,
    };
    if (group) {
      newNote = addGroupIdToPayload(newNote, group.id, group.type);
    }
    studentNoteActions.createStudentNote(newNote, () =>
      setOpenNoteModal(false)
    );
  };

  const handleOnClickAdd = () => {
    handleCreateNote(noteProps.noteType, noteProps.note);
    setNoteProps({ note: "", noteType: null });
  };

  const handleCancel = () => {
    setOpenNoteModal(false);
    setNoteProps({ note: "", noteType: null });
  };

  const handleDeleteNote = (noteId, onSuccess, onFailure) => {
    studentNoteActions
      .deleteStudentNote(student.id, noteId, onSuccess)
      .catch(() => onFailure());
  };

  return (
    <>
      <ActionBar>
        <Left>
          <Dropdown
            placeholder="Note Type"
            items={noteTypeFilters}
            onChange={value => setNoteTypeFilter(value)}
            value={noteTypeFilter}
            loading={loadingNoteCategories}
          />
        </Left>
        <Right>
          <Button
            text="Add New Note"
            onClick={() => setOpenNoteModal(true)}
            size={Size.Small}
            color={Swatches.Primary}
          />
        </Right>
      </ActionBar>

      {loadingNotes ? (
        <Loader size={Size.Medium} fluid />
      ) : studentNotes && studentNotes.length > 0 ? (
        <>
          <ApiExceptionMessage error={errorGettingNotes} />
          <ApiExceptionMessage error={deleteError} />

          {group && (
            <MessageWrapper>
              <Sub>Notes shown are for all classes not just {group.name}</Sub>
            </MessageWrapper>
          )}

          <StudentNotesList
            studentNotes={studentNotes}
            loading={loadingNotes}
            pagesAvailable={paging.pageNumber < paging.totalPages}
            handleDeleteNote={handleDeleteNote}
          />
        </>
      ) : (
        <EmptyMessage icon="list" title="No Notes" />
      )}

      <NewStudentNoteModal
        open={openNoteModal}
        onClose={() => setOpenNoteModal(false)}
        student={student}
        noteProps={noteProps}
        noteCategories={studentNoteCategories
          ?.filter(category => category.isActive)
          .map(category => ({
            label: category.name,
            value: category.id,
          }))}
        handleNotePropsChange={noteProps => setNoteProps(noteProps)}
        handleOnClickAdd={handleOnClickAdd}
        handleCancel={handleCancel}
        loading={loading}
        error={createError}
      />
    </>
  );
};

StudentNotesListWrapper.propTypes = {
  studentId: PropTypes.string,
};

export default StudentNotesListWrapper;
