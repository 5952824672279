import { IBeehiveAction } from "types/common/action.types";
import reviewActions from "../../actions/reviewActions";
import { ReviewDetailView } from "areas/humanResources/types/managedStaffResponse.types";

interface IReviewState {
  review: ReviewDetailView | null;
  loading: boolean;
  starting: boolean;
  error: string | null;
  startError: string | null;
  newId: number | null;
  saving: boolean;
  saveError: string | null;
  downloading: boolean;
}

const INITIAL_STATE: IReviewState = {
  review: null,
  loading: false,
  starting: false,
  error: null,
  startError: null,
  newId: null,
  saving: false,
  saveError: null,
  downloading: false
};

const reviewReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IReviewState => {

  const { GETREVIEW, STARTREVIEW, SAVEREVIEW, DOWNLOADREVIEW } = reviewActions.types;

  switch (action.type) {

    case GETREVIEW.START:
      return { ...INITIAL_STATE, loading: true };
    case GETREVIEW.SUCCESS:
      return { ...INITIAL_STATE, review: action.payload };
    case GETREVIEW.FAILED:
      return { ...INITIAL_STATE, error: action.payload };

    case STARTREVIEW.START:
      return { ...INITIAL_STATE, starting: true };
    case STARTREVIEW.SUCCESS:
      return { ...INITIAL_STATE, newId: action.payload };
    case STARTREVIEW.FAILED:
      return { ...INITIAL_STATE, startError: action.payload };

    case SAVEREVIEW.START:
      return { ...state, saveError: null, saving: true };
    case SAVEREVIEW.SUCCESS:
      return { ...state, saving: false };
    case SAVEREVIEW.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the review",
      };

    case DOWNLOADREVIEW.START:
      return { ...state, downloading: true };
    case DOWNLOADREVIEW.SUCCESS:
      return { ...state, downloading: false };
    case DOWNLOADREVIEW.FAILED:
      return { ...state, downloading: false, error: action.payload };

    default:
      return state;
  }
};

export default reviewReducer;
