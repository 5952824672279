import { IBeehiveAction } from "types/common/action.types";
import submissionApprovalFilterActions from "../actions/submissionApprovalFilterActions";
import { FormCategoryListView, FormListView } from "../types/formResponse.types";


interface ISubmissionApprovalFilterState {
  loading: boolean;
  error: string | null;
  filter: {
    category: FormCategoryListView | null;
    formType: FormListView | null;
    formStatus: number;
    schoolId: number;
  };
}

const INITIAL_STATE: ISubmissionApprovalFilterState = {
  loading: false,
  error: null,
  filter: {
    category: null,
    formType: null,
    formStatus: 1,
    schoolId: -1,
  },
};


const submissionApprovalFilterReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISubmissionApprovalFilterState => {

  const {
    APPROVALSFILTERBYCATEGORY,
    APPROVALSFILTERBYFORMTYPE,
    APPROVALSFILTERBYFORMSTATUS,
    APPROVALSFILTERBYSCHOOL,
  } = submissionApprovalFilterActions.types;

  switch (action.type) {
    case APPROVALSFILTERBYCATEGORY:
      return {
        ...state,
        filter: { ...state.filter, category: action.payload, formType: null },
      };

    case APPROVALSFILTERBYFORMTYPE:
      return {
        ...state,
        filter: { ...state.filter, formType: action.payload },
      };

    case APPROVALSFILTERBYFORMSTATUS:
      return {
        ...state,
        filter: { ...state.filter, formStatus: action.payload },
      };

    case APPROVALSFILTERBYSCHOOL:
      return {
        ...state,
        filter: { ...state.filter, schoolId: action.payload },
      };

    default:
      return state;
  }
};

export default submissionApprovalFilterReducer;
