import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Spacing, neutral, DetailLabel } from "ui-kit";
import styled from "styled-components";
import { SearchBox } from "ui-kit";
import searchEngineActions from "areas/search/actions/searchEngineActions";
import SchoolIcon from "../schools/icons/schoolIcon";
import { RootState } from "reducers/store";
import { GroupType } from "types/planner/groups.types";
import config from "configuration/config";
import { SchoolListView, SchoolStartupView } from "types/schools/schools.types";
import { SearchResultView } from "types/common/views.types";
import { GroupListView } from "types/users/userGroups.types";

const Wrapper = styled.div``;

const ResultItemWrapper = styled.div`
  padding: ${Spacing.Default}px;
  display: flex;
  border-bottom: 1px solid ${neutral[300]};
  svg {
    margin-right: ${Spacing.Default}px;
  }
  &:hover {
    background: ${neutral[300]};
  }
`;

interface IGroupSearchProps {
  onGroupSelected?: (result: SearchResultView<GroupListView>) => void;
  fluid?: boolean;
  placeholder?: string,
  groupType?: GroupType;
  schools?: SchoolListView[];
}

const GroupSearch: React.FC<IGroupSearchProps> = ({
  onGroupSelected,
  fluid,
  placeholder,
  groupType,
  schools,
}) => {

  const { groups, loading, error } = useSelector((state: RootState) => state.searchGroups);
  const [_schools, _setSchools] = useState<SchoolListView[]>([]);

  useEffect(() => {
    _setSchools(schools);
  }, [schools]);

  const handleGroupsSearch = (value: string, searchSchools?: SchoolListView[]) => {
    if (value && value.length > 2) {
      searchEngineActions.searchGroups(
        value,
        groupType,
        config.academicYear.current,
        searchSchools?.map(school => school.id)
      );
    }
  };

  const handleResultClick = (result: SearchResultView<GroupListView>) => {
    onGroupSelected?.(result);
  };

  return (
    <Wrapper>
      <SearchBox
        // force rerender on schools update because of lodash caching
        key={_schools.map(x => x.id).join()}
        onSearch={value => handleGroupsSearch(value, _schools)}
        results={groups?.items}
        loading={loading}
        error={error && "An error occurred"}
        minSearchLength={3}
        placeholder={placeholder}
        fluid
        resultTemplate={result => (
          <ResultItemWrapper>
            <SchoolIcon code={result.school?.code} />
            <DetailLabel label={result.title} sub={result.description} bold />
          </ResultItemWrapper>
        )}
        onResultClick={handleResultClick}
      />
    </Wrapper>
  );
};

export default GroupSearch;
