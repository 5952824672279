import React, { useEffect, useState } from "react";
import { Size } from "ui-kit/common/size";
import {
  Loader,
  Message,
  Swatches,
  Flyout,
  Spacing,
} from "ui-kit";
import { Constants } from "configuration";
import StudentInfoTabs from "./studentInfoTabs";
import styled from "styled-components";
import { useNavigate } from "react-router";
import studentActions from "areas/planner/actions/student/studentActions";
import { studentRoutes } from "areas/planner/plannerRoutes";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import CreateOnCallRequestWrapper from "areas/behaviour/components/onCall/createOnCallRequestWrapper";
import RequestOnCallButton from "areas/behaviour/components/onCall/requestOnCallButton";
import StudentTitle from "../studentTitle";
import StudentStats from "../studentStats";
import { useAppSelector } from "reducers/hooks";
import schoolActions from "areas/planner/actions/school/schoolActions";


const MessageWrapper = styled.div`
  margin-bottom: ${Spacing.Medium}px;
`;


const StudentRecordFlyout: React.FC = () => {

  const navigate = useNavigate();

  const { selected, student, refresh, loading, error } = useAppSelector(
    state => state.student
  );
  const { group } = useAppSelector(state => state.group);
  const { schoolInformation } = useAppSelector(state => state.school);

  const [openOnCallModal, setOpenOnCallModal] = useState<boolean>(false);

  useEffect(() => {
    if (selected) {
      studentActions.getStudent(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (student &&
      (!schoolInformation || (schoolInformation.id !== student.school.id))
    ) {
      schoolActions.getSchoolInformation(student.school.id);
    }
  }, [student]);

  useEffect(() => {
    if (refresh && student) {
      studentActions.getStudent(selected);
    }
  }, [refresh]);

  const handleClick = () => {
    navigate(`${studentRoutes.getStudentPath(student.id)}`);
  };

  return (
    <>
      <Flyout
        title={(!student || loading) && "Student Record"}
        titleComponent={
          student && !loading ? (
            <StudentTitle student={student} onClick={handleClick} />
          ) : null
        }
        name={Constants.FLYOUTS.STUDENT}
        wide
        onClose={() => studentActions.selectStudent(null)}
      >
        {<ApiExceptionMessage error={error} />}

        {loading ? (
          <Loader size={Size.Large} cover />
        ) : (
          selected &&
          student && (
            <>
              <Flyout.Body>
                <StudentStats student={student} background />

                {student && student.pastoral.hasOutstandingDetentions && (
                  <MessageWrapper>
                    <Message
                      text="This student has more than five unattended detentions"
                      color={Swatches.Warning}
                      icon="exclamation-triangle"
                    />
                  </MessageWrapper>
                )}
                <StudentInfoTabs student={student} group={group} />
              </Flyout.Body>


              { group.school.id != 99 && (
                <Flyout.Footer>
                  <RequestOnCallButton
                    handleRequestOnCall={() => setOpenOnCallModal(true)}
                    schoolId={student.school?.id}
                    fluid
                  />
                </Flyout.Footer>
              )}


              {openOnCallModal && (
                <CreateOnCallRequestWrapper
                  students={[student]}
                  groupType={group?.type}
                  groupId={group?.id}
                  schoolId={student.school.id}
                  openModal={openOnCallModal}
                  handleCloseModal={() => setOpenOnCallModal(false)}
                />
              )}
            </>
          )
        )}
      </Flyout>
    </>
  );
};

export default StudentRecordFlyout;
