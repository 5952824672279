import declarationsOfInterestActions from "areas/forms/actions/declarations/declarationsOfInterestActions";
import { DeclarationOfInterestAdminView } from "areas/forms/types/declarations/declarationsOfInterestResponse.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


interface IAdminDeclarationState {
  declaration: DeclarationOfInterestAdminView;
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IAdminDeclarationState = {
  declaration: null,
  loading: false,
  error: null,
};


const adminDeclarationReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAdminDeclarationState => {

  const { ADMIN_GET_DECLARATION } = declarationsOfInterestActions.types;

  switch (action.type) {

    case ADMIN_GET_DECLARATION.START:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case ADMIN_GET_DECLARATION.SUCCESS:
      return {
        ...state,
        loading: false,
        declaration: action.payload
      }
    case ADMIN_GET_DECLARATION.START:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state;
  }

}


export default adminDeclarationReducer;