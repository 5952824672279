import { useState } from "react";
import {
  Table,
  Currency,
  DateTime,
  Swatches,
  Sub,
  Button,
  Size,
  SplitButton,
  EmptyMessage,
} from "ui-kit";
import InstalmentFlyout from "./instalmentFlyout";
import flyoutActions from "actions/ui/flyouts";
import { Constants } from "configuration";
import { ProductDetails } from "./productEditor";
import { InstalmentRuleView } from "areas/payments/types/shopkeeper/shopkeeperShared.types";


export interface NewInstalment extends InstalmentRuleView {
  index?: number;
}

interface IInstalmentsTableProps {
  product: ProductDetails;
  editable?: boolean;
  onInstalmentDeleted?: (instalment: InstalmentRuleView, index: number) => void;
  onInstalmentSaved?: (instalment: NewInstalment, index?: number) => void;
  installmentType: number;
  emptyMessage?: string;
}


const InstalmentsTable: React.FC<IInstalmentsTableProps> = ({
  product,
  editable,
  onInstalmentDeleted,
  onInstalmentSaved,
  installmentType,
  emptyMessage
}) => {
  const [selectedInstalment, setSelectedInstalment] = useState<NewInstalment>(null);

  const handleNewInstalment = (filter: number) => {
    setSelectedInstalment({
      dueDate: new Date(), 
      installmentType: filter,
      id: null,
      total: 0, 
      optionalForPupilPremium: false
    });
    switch(filter) {
      case 0:
        flyoutActions.openFlyout(Constants.FLYOUTS.INSTALMENT);
        break;
      case 1:
        flyoutActions.openFlyout(Constants.FLYOUTS.PUPILPREMIUMINSTALMENT);
        break;
      case 2:
        flyoutActions.openFlyout(Constants.FLYOUTS.BURSARYINSTALMENT);
        break;
    }

  };

  const handleEditInstalment = (instalment: InstalmentRuleView, index: number) => {
    setSelectedInstalment({ ...instalment, index });
    switch(instalment.installmentType) {
      case 0:
        flyoutActions.openFlyout(Constants.FLYOUTS.INSTALMENT);
        break;
      case 1:
        flyoutActions.openFlyout(Constants.FLYOUTS.PUPILPREMIUMINSTALMENT);
        break;
      case 2:
        flyoutActions.openFlyout(Constants.FLYOUTS.BURSARYINSTALMENT);
        break;
    }
  };

  const handleDeleteInstalment = (instalment: InstalmentRuleView, index: number) => {
    if (window.confirm("This will delete this instalment. Are you sure?")) {
      onInstalmentDeleted?.(instalment, index);
    }
  };

  const handleInstalmentSaved = (instalment: NewInstalment) => {
    onInstalmentSaved?.(instalment);
  };

  const calculateInstalmentTotal = (filter: number) => {
    //https://stackoverflow.com/questions/39269701/typescript-trying-the-addition-of-two-variables-but-get-the-concatenation-of-t
    //IDK why the hell this happens

    return product.instalmentRules.reduce((totalOfType, instalment) => {
      return Number(totalOfType) + (instalment.installmentType == filter ? Number(instalment.total) : 0);
    }, 0);
  };

  const getMaxPrice = (filter: number) => {
    switch (filter) {
      case (0):
        return product.unitPrice;
      case (1):
        return product.unitPrice - product.pupilPremiumDiscount;
      case (2):
        return product.unitPrice - product.bursaryDiscount;
      default:
        return product.unitPrice;
    }
  }

  const InstalmentTableRow = ({ instalment, index } : { instalment: InstalmentRuleView, index: number }) => {
    return (
      <Table.Row key={index}>
        <Table.Cell>
          <DateTime date={instalment.dueDate} hideTime bold />
        </Table.Cell>

        <Table.Cell right>
          <Currency value={instalment.total} />
        </Table.Cell>
        <Table.Cell show={editable} right>
          <SplitButton
            text="Edit"
            color={Swatches.Low}
            onDefaultClick={() => handleEditInstalment?.(instalment, index)}
          >
            <SplitButton.Option
              text="Edit"
              onClick={() => handleEditInstalment?.(instalment, index)}
              show={true}
            />
            <SplitButton.Option
              text="Delete"
              onClick={() => handleDeleteInstalment?.(instalment, index)}
              color={Swatches.Danger}
              show={true}
            />
          </SplitButton>
        </Table.Cell>
      </Table.Row>
    );
  };

  const NoInstallments = ({ ofType } : { ofType: number }) => {
    return (
      <EmptyMessage icon="shopping-bag" title={emptyMessage ? emptyMessage : "No Instalments"} cover>
      {editable && (
        <Button
          text="Add Instalment"
          onClick={() => handleNewInstalment(ofType)}
          size={Size.Medium}
          color={Swatches.Primary}
        />
      )}
    </EmptyMessage>
    )
  }

  const InstalmentList = ({ filter } : { filter: number }) => {
    return (
      <Table>
        <Table.Header>
          <Table.HeaderCell width={editable ? 4 : 6}>
            Due Date
          </Table.HeaderCell>
          <Table.HeaderCell width={3} right>
            Total
          </Table.HeaderCell>
          <Table.HeaderCell width={3} show={editable} />
        </Table.Header>
        <Table.Body>
          { product.instalmentRules.map((instalment: InstalmentRuleView, index: number) => (
              instalment.installmentType == filter && (
                <InstalmentTableRow key={index} instalment={instalment} index={index} />
              )
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.Cell />
            <Table.Cell right>
              <b>
                <Currency value={calculateInstalmentTotal(filter)} />
              </b>
            </Table.Cell>
            {editable && (
              <Table.Cell right>
                { calculateInstalmentTotal(filter) < getMaxPrice(filter) && (
                  <Button
                    text="Add Instalment"
                    onClick={() => handleNewInstalment?.(filter)}
                    size={Size.Small}
                    color={Swatches.Primary}
                  />
                )}
              </Table.Cell>
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell colspan={editable ? 4 : 2}>
              <Sub>
                Instalments can only be set for products with a unit price
                greater than £50. The total of the instalments must be the
                same as the unit price.
              </Sub>
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
    )
  }

  return (
    <>
      { !product?.instalmentRules.some(x => x.installmentType === installmentType) ? (
        <NoInstallments ofType={installmentType}/>
      ) : (
        <InstalmentList filter={installmentType}/>
      )}
      <InstalmentFlyout
        instalment={selectedInstalment}
        onInstalmentSaved={handleInstalmentSaved}
        product={product}
        installmentType={installmentType}
      />
    </>
  )
};

export default InstalmentsTable;
