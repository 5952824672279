import studentNoteActions from "../../actions/student/studentNoteActions";

const INITIAL_STATE = {
  studentNotes: [],
  filteredStudentNotes: null,
  loading: false,
  createError: null,
  deleteError: null,
};

const { CREATESTUDENTNOTE, DELETESTUDENTNOTE } = studentNoteActions.types;

const studentNoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATESTUDENTNOTE.START:
      return { ...state, loading: true, createError: null };

    case CREATESTUDENTNOTE.SUCCESS:
      return { ...state, loading: false };

    case CREATESTUDENTNOTE.FAILED:
      return { ...state, loading: false, createError: action.payload };

    case DELETESTUDENTNOTE.START:
      return { ...state, loading: true, deleteError: null };

    case DELETESTUDENTNOTE.SUCCESS:
      return { ...state, loading: false };

    case DELETESTUDENTNOTE.FAILED:
      return { ...state, loading: false, deleteError: action.payload };

    default:
      return state;
  }
};

export default studentNoteReducer;
