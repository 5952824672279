import React from "react";
import {
  StructuredList,
  Card,
  Dropdown,
  ActionBar,
  Button,
  Swatches,
  Size,
} from "ui-kit";
import DateRange from "../dateRange";
import Search, {
  SEARCH_GROUP_TYPE_KEYS,
} from "sharedComponents/common/search/search";
import { DETENTION_STATUS_KEYS } from "areas/behaviour/constants/detentions";
import MultiSchoolUserView from "sharedComponents/common/users/multiSchoolUserView";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import AcademicYearFilter from "sharedComponents/common/academicYearFilter";
import { SchoolBehaviourCodeListView } from "areas/behaviour/types/behaviourResponses.types";
import moment from "moment";
import { DetentionReportFilterExt } from "./yearGroupDetentionReportWrapper";


interface IYearGroupDetentionReportFormProps {
  schoolId: number;
  onSelectSchool: (value: number) => void;
  behaviourCodes: SchoolBehaviourCodeListView[];
  loadingBehaviourCodes: boolean;
  reportCriteria: DetentionReportFilterExt;
  onChangeReportCriteria: (criteria: DetentionReportFilterExt) => void;
  handleGenerateReport: (exportReport: boolean) => void;
  loadingExportReport: boolean;
}


const YearGroupDetentionReportForm: React.FC<IYearGroupDetentionReportFormProps> = ({
  schoolId,
  onSelectSchool,
  behaviourCodes,
  loadingBehaviourCodes,
  reportCriteria,
  onChangeReportCriteria,
  handleGenerateReport,
  loadingExportReport,
}) => {
  return (
    <>
      <MultiSchoolUserView>
        <Card title="School">
          <Card.Body>
            <SchoolDropdown
              onChange={onSelectSchool}
              initialValue={schoolId}
              fluid
            />
          </Card.Body>
        </Card>
      </MultiSchoolUserView>

      <Card title="Date Range">
        <Card.Body>
          <StructuredList.Item name="Academic Year" required>
            <AcademicYearFilter
              value={reportCriteria.academicYearId}
              onChange={value =>
                onChangeReportCriteria({
                  ...reportCriteria,
                  academicYearId: value,
                })
              }
            />
          </StructuredList.Item>
          <DateRange
            startDate={reportCriteria.startDate}
            handleStartDateChange={value =>
              onChangeReportCriteria({
                ...reportCriteria,
                startDate: value,
              })
            }
            endDate={reportCriteria.endDate}
            handleEndDateChange={value =>
              onChangeReportCriteria({ ...reportCriteria, endDate: value })
            }
          />
        </Card.Body>
      </Card>

      <Card title="Filters">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item
              name="Year Group"
              description="Filter to generate a report for only selected year groups"
              helpPopup
              required
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.YEAR_GROUP]}
                selected={
                  reportCriteria.yearGroup ? [reportCriteria.yearGroup] : []
                }
                handleSelectItem={yearGroup =>
                  onChangeReportCriteria({
                    ...reportCriteria,
                    yearGroup: yearGroup,
                  })
                }
                handleDeleteItem={() =>
                  onChangeReportCriteria({
                    ...reportCriteria,
                    yearGroup: null,
                  })
                }
                schoolId={schoolId}
              />
            </StructuredList.Item>

            <StructuredList.Item name="Detention Status">
              <Dropdown
                items={[
                  {
                    label: "Issued",
                    value: DETENTION_STATUS_KEYS.ISSUED,
                  },
                  {
                    label: "Attended",
                    value: DETENTION_STATUS_KEYS.ATTENDED,
                  },
                  {
                    label: "Missed",
                    value: DETENTION_STATUS_KEYS.MISSED,
                  },
                ]}
                onChange={value =>
                  onChangeReportCriteria({
                    ...reportCriteria,
                    statusId: value,
                  })
                }
                value={reportCriteria.statusId}
                loading={loadingBehaviourCodes}
                fluid
                clearable
              />
            </StructuredList.Item>

            <StructuredList.Item name="Behaviour Code">
              <Dropdown
                items={behaviourCodes?.map((code, index) => ({
                  key: code.id,
                  label: code.codeName,
                  value: code.id,
                }))}
                onChange={value =>
                  onChangeReportCriteria({
                    ...reportCriteria,
                    codeId: value,
                  })
                }
                value={reportCriteria.codeId}
                loading={loadingBehaviourCodes}
                fluid
                clearable
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      <ActionBar>
        <Button
          text="Generate Report"
          color={Swatches.Success}
          size={Size.Small}
          disabled={reportCriteria.yearGroup === null}
          onClick={() => handleGenerateReport(false)}
        />
        <Button
          color={Swatches.Success}
          text="Export Report as CSV"
          icon="file-download"
          size={Size.Small}
          disabled={reportCriteria.yearGroup === null}
          onClick={() => handleGenerateReport(true)}
          working={loadingExportReport}
        />
      </ActionBar>
    </>
  );
};

export default YearGroupDetentionReportForm;
