import React from "react";
import styled from "styled-components";
import {
  IUiKitBaseProps,
  yellow,
  textFont,
  typescale,
  fontStyle,
} from "../index";

export interface IBadgeProps extends IUiKitBaseProps {
  value: number;
}

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${yellow[600]};
  color: black;
  border-radius: 50%;

  text-align: center;
  width: ${typescale.helper.size}rem;
  height: ${typescale.helper.size}rem;
  padding: ${typescale.helper.size / 4}rem;

  span {
    ${fontStyle(textFont.bold, typescale.sub)}
  }
`;

const Badge: React.FC<IBadgeProps> = ({ value, className, onClick }) => {
  return value ? (
    <Wrapper
      className={`badge ${className ? className : ""}`}
      onClick={() => onClick?.()}
    >
      <span>{value}</span>
    </Wrapper>
  ) : null;
};

export default Badge;
