import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  EmptyMessage,
  Currency,
  Swatches,
  Chip,
  DetailLabel,
  Icon,
  Size,
} from "ui-kit";
import Moment from "react-moment";
import { arrays } from "utils";
import { PageInfo } from "types/common/paging.types";
import { ProductListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { SchoolIcon } from "sharedComponents/common";


interface IProductsTableProps {
  loading?: boolean;
  error?: boolean;
  products: ProductListView[];
  onPage?: () => void;
  paging?: PageInfo;
  showSchool?: boolean;
}


const ProductsTable: React.FC<IProductsTableProps> = ({ loading, error, products, paging, onPage, showSchool }) => {

  const navigate = useNavigate();

  // Columns:
  // Name, Is Active (chip), is visible in shop (chip), stock, sold, remaining, sold out chip
  const handleRowClick = (product: ProductListView) => {
    navigate(`/main/shopkeeper/category/${product.category.id}/products/${product.id}`);
  };

  const handlePage = () => {
    onPage?.();
  };

  return (
    <Table
      zebra
      loading={loading}
      empty={arrays.isEmpty(products)}
      emptyMessage={
        <EmptyMessage
          icon="paste"
          title="No Products"
          summary="No products were found for this selection"
          cover
        />
      }
      error={error}
      grow
    >
      <Table.Header>
        <Table.HeaderCell hide={!showSchool} center width={0.25}></Table.HeaderCell>
        <Table.HeaderCell center width={0.75}></Table.HeaderCell>
        <Table.HeaderCell width={4.5}>Name</Table.HeaderCell>
        <Table.HeaderCell right width={1.5}>
          Expires
        </Table.HeaderCell>
        <Table.HeaderCell right width={1}>
          Price
        </Table.HeaderCell>
        <Table.HeaderCell right width={0.5}>
          Stock
        </Table.HeaderCell>
        <Table.HeaderCell right width={0.5}>
          Remaining
        </Table.HeaderCell>
        <Table.HeaderCell right width={0.5}>
          Discounts
        </Table.HeaderCell>
      </Table.Header>
      <Table.Body onPage={handlePage} paging={paging}>
        {products?.map((product, index) => (
          <Table.Row key={index} onClick={() => handleRowClick(product)}>
            <Table.Cell hide={!showSchool} center chip width={0.25}>
              <SchoolIcon
                code={product?.category?.school?.code}
              />
            </Table.Cell>
            <Table.Cell center chip width={0.75}>
              {product.isActive ? (
                <Chip
                  text="Active"
                  colorSwatch={Swatches.Success}
                  fluid
                  tooltip="Active"
                  tooltipSub="This product is currently active."
                />
              ) : (
                <Chip
                  text="Not Active"
                  colorSwatch={Swatches.Low}
                  fluid
                  tooltip="Inactive"
                  tooltipSub="This product is inactive and not available to purchase in the store."
                />
              )}
            </Table.Cell>
            <Table.Cell width={4.5}>
              <DetailLabel
                bold
                label={
                  <>
                    {product.name}{" "}
                    {product.allowFuture && (
                      <Icon
                        value="forward"
                        className="future-icon"
                        color="#1dcaff"
                      />
                    )}
                  </>
                }
                sub={product.category.name}
              />
            </Table.Cell>
            <Table.Cell right width={1.5}>
              {product.hasExpiryDate && (
                <Moment date={product.expiryDate} format="DD/MM/YYYY" />
              )}
            </Table.Cell>
            <Table.Cell right width={1}>
              <Currency value={product.unitPrice} />{" "}
            </Table.Cell>

            <Table.Cell right width={0.5}>
              {product.enforceStockControl ? product.stock : "-"}
            </Table.Cell>

            <Table.Cell right width={0.5}>
              {product.enforceStockControl ? (
                product.stockRemaining < 1 ? (
                  <Chip text="Sold Out" colorSwatch={Swatches.Danger} fluid />
                ) : (
                  product.stockRemaining
                )
              ) : (
                "-"
              )}
            </Table.Cell>
            <Table.Cell right width={0.25}>
              {(product.ppDiscountedPrice != null) && (
                <Chip
                  text="PP"
                  colorSwatch={Swatches.Purple}
                  tooltip="Pupil Premium"
                  tooltipSub="This product has a pupil premium discount."
                />
              )}
            </Table.Cell>
            <Table.Cell right width={0.25}>
              {(product.bursaryDiscountedPrice != null) && (
                <Chip
                  text="BUR"
                  colorSwatch={Swatches.DeepPink}
                  tooltip="Bursary"
                  tooltipSub="This product has a bursary discount."
                />
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

export default ProductsTable;