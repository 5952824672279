import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import paymentActions from "areas/payments/actions/paymentsActions";
import UserProductsTable from "./userProductsTable";
import { RootState } from "reducers/store";
import { PaymentUser } from "areas/payments/reducers/payments/paymentsUserReducer";


interface IUserStoreProps {
  user: PaymentUser;
}


const UserStore: React.FC<IUserStoreProps> = ({ user }) => {

  const { loading, error, products } = useSelector((state: RootState) => state.userProducts);

  useEffect(() => {
    user && paymentActions.getProductsForUser(user.id);
  }, [user]);

  return (
    <UserProductsTable
      user={user}
      products={products}
      error={error != null}
      loading={loading}
    />
  );
};

export default UserStore;
