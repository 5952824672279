import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import { users } from "utils";
import styled from "styled-components";
import { honeygold } from "ui-kit";
import { RootState } from "reducers/store";

const Wrapper = styled.span<{bold?: boolean, hasOnClick?: boolean}>`
  ${({ bold }) => bold && `font-weight: bold;`}
  ${({ hasOnClick }) =>
    hasOnClick &&
    `cursor: pointer; transition: all ease 150ms; &:hover { color: ${honeygold}; }`}
`;

interface IUsernameProps {
  user: any;
  self?: boolean;
  onClick?: () => void;
  bold?: boolean;
  splitName?: boolean; 
}

const UserName: React.FC<IUsernameProps> = ({ user, self, onClick, bold, splitName }) => {

  const { user: currentUser } = useSelector((state: RootState) => state.currentUser);

  if (!user || !currentUser) {
    return null;
  }

  const handleClick = () => {
    onClick?.();
  };

  // Default is the formal name
  var username : string | JSX.Element = users.getFormalName(user);

  // Show the full name if:
  // The user is a student
  // The current user is a staff member
  // It is self
  // Title is null

  if (
    user.userType == Constants.USER_TYPES.STUDENT ||
    currentUser.userType == Constants.USER_TYPES.STAFF ||
    currentUser.id == user.id ||
    !user.title ||
    self
  ) {
    username = (splitName ? (
      <>
        <div className="firstname">{users.getFirstName(user)}</div>
        <div className="lastname">{users.getLastName(user)}</div>
      </>
    ) : (
      users.getFullName(user)
    ));
  }

  return (
    <Wrapper
      className="user-name"
      onClick={handleClick}
      hasOnClick={onClick != null}
      bold={bold}
    >
      {username}
    </Wrapper>
  );
};

export default UserName;
