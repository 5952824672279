import passportActions from "areas/send/actions/passports/passportActions";
import { PassportDetailView } from "areas/send/types/passportResponse.types";
import { IBeehiveAction } from "types/common/action.types";

interface IPassportState {
  passport: PassportDetailView | null;
  loading: boolean;
  error: string | null;
  saving: boolean;
  cloning: boolean;
  saveError: string | null;
  cloneError: string | null;
  deleteError: string | null;
  newId: number | null;
}

const INITIAL_STATE: IPassportState = {
  passport: null,
  loading: false,
  error: null,
  saving: false,
  cloning: false,
  saveError: null,
  cloneError: null,
  deleteError: null,
  newId: null,
};


const passportReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IPassportState => {

  const {
    PASSPORT_GET,
    PASSPORT_CREATE,
    PASSPORT_SAVE,
    PASSPORT_CLONE,
    PASSPORTVERSION_SAVE,
    PASSPORTVERSION_DELETE,
    PASSPORTVERSION_PUBLISH,
    PASSPORTVERSION_CREATE,
    PASSPORTVERSION_CLONE,
  } = passportActions.types;

  switch (action.type) {
    case PASSPORT_GET.START:
      return { ...state, loading: true, error: null };
    case PASSPORT_GET.SUCCESS:
      return { ...state, loading: false, passport: action.payload };
    case PASSPORT_GET.FAILED:
      return { ...state, loading: false, error: action.payload };

    case PASSPORT_CREATE.START:
      return { ...state, saveError: null, saving: true };
    case PASSPORT_CREATE.SUCCESS:
      return { ...state, saving: false, newId: action.payload.id };
    case PASSPORT_CREATE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem creating the passport",
      };

    case PASSPORT_SAVE.START:
      return { ...state, saveError: null, cloneError: null, saving: true };
    case PASSPORT_SAVE.SUCCESS:
      return { ...state, saving: false };
    case PASSPORT_SAVE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the passport",
      };

    case PASSPORT_CLONE.START:
      return { ...state, cloneError: null, deleteError: null, cloning: true };
    case PASSPORT_CLONE.SUCCESS:
      return { ...state, cloning: false };
    case PASSPORT_CLONE.FAILED:
      return {
        ...state,
        cloning: false,
        cloneError: "There was a problem cloning the passport.",
      };

    case PASSPORTVERSION_SAVE.SUCCESS:
      return {
        ...state,
        passport: {
          ...state.passport,
          selectedVersion: action.payload,
        },
      };

    case PASSPORTVERSION_CREATE.SUCCESS:
    case PASSPORTVERSION_CLONE.SUCCESS:
      return {
        ...state,
        passport: {
          ...state.passport,
          versions: [...state.passport.versions, action.payload],
          selectedVersion: action.payload,
        },
      };

    case PASSPORTVERSION_DELETE.SUCCESS:
      return {
        ...state,
        passport: {
          ...state.passport,
          versions: state.passport.versions.filter(v => v.id !== action.payload)
        },
      };

    case PASSPORTVERSION_PUBLISH.SUCCESS:
      return {
        ...state,
        passport: {
          ...state.passport,
          versions: state.passport?.versions?.map(x =>
            x.id === action.payload.id ? { ...x, status: 1 } : x
          ),
          selectedVersion: action.payload,
        },
      };

    default:
      return state;
  }
};

export default passportReducer;
