import defaults from "configuration/defaults";
import { arrays } from "utils";
import mailoutActions from "../actions/mailoutActions";
import { PageInfo } from "types/common/paging.types";
import { QueueMessageUserListView } from "../types/mailoutResponse.types";
import { IBeehiveAction } from "types/common/action.types";


interface IUserSmsMessagesState {
  smsMessages: QueueMessageUserListView[];
  error: string | null;
  loading: boolean;
  paging: PageInfo;
}

const INITIAL_STATE: IUserSmsMessagesState = {
  smsMessages: [],
  error: null,
  loading: false,
  paging: defaults.DEFAULT_PAGING,
};

const userSmsMessagesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUserSmsMessagesState => {

  const { MAILOUT_USERSMS_GET } = mailoutActions.types;

  switch (action.type) {
    case MAILOUT_USERSMS_GET.START:
      return {
        ...state,
        loading: arrays.isEmpty(state.smsMessages),
        error: null,
      };

    case MAILOUT_USERSMS_GET.SUCCESS:
      return {
        ...state,
        smsMessages:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.smsMessages, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };

    case MAILOUT_USERSMS_GET.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default userSmsMessagesReducer;
