import React, { useState } from "react";
import styled from "styled-components";
import {
  EmptyMessage,
  neutral,
  BorderRadius,
  Spacing,
  Card,
  Speed,
  Swatches,
  Size,
  honeygold,
  boxShadow,
} from "ui-kit";
import { arrays } from "utils";
import Avatar from "./avatar";
import { StaffListView } from "types/users/userListViews.types";

const StaffListWrapper = styled.ul<{ wrap?: boolean }>`
  display: flex;
  border-bottom: 1px solid ${neutral[300]};
  margin-bottom: ${Spacing.Medium}px;
  padding-bottom: ${Spacing.Medium}px;
  ${({ wrap }) => wrap && `
    flex-flow: row wrap;
  `}
`;

const StaffListItemWrapper = styled.li<{highlight?: boolean}>`
  list-style: none;
  border-radius: ${BorderRadius.Default}px;
  padding: ${Spacing.Default}px;
  transition: all ease ${Speed.Default};
  // background: ${neutral[100]};
  // border: 1px solid ${neutral[300]};
  margin-right: ${Spacing.Default}px;

  ${({ highlight }) =>
    highlight &&
    `background: ${Swatches.Blue.swatch}; 
    // border-color: ${Swatches.Blue.swatch};
    span { color:${neutral[100]}; }
    &:hover {
    cursor: default;
  }`}
`;


interface IStaffListProps {
  staff: StaffListView[];
  onStaffMemberSelected?: (staffMember: StaffListView, index: number) => void;
  title?: string;
  wrap?: boolean;
}

const StaffList: React.FC<IStaffListProps> = ({ staff, onStaffMemberSelected, title, wrap }) => {

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleStaffMemberClicked = (staffMember: StaffListView, index: number) => {
    setSelectedIndex(index);
    onStaffMemberSelected?.(staffMember, index);
  };

  if (arrays.isEmpty(staff)) {
    return (
      <Card title={title}>
        <Card.Body>
          <StaffListWrapper>
            <EmptyMessage
              icon="users"
              title="No staff members"
              summary="No staff members were found"
              cover
            />
          </StaffListWrapper>
        </Card.Body>
      </Card>
    );
  }

  return (
    <StaffListWrapper wrap={wrap}>
      {staff.map((user: StaffListView, index: number) => (
        <StaffListItemWrapper key={index} highlight={index === selectedIndex}>
          <Avatar
            size={Size.Medium}
            user={user}
            onClick={() =>
              index !== selectedIndex && handleStaffMemberClicked(user, index)
            }
          />
        </StaffListItemWrapper>
      ))}
    </StaffListWrapper>
  );
};

export default StaffList;
