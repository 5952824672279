import React from "react";
import { Chip, Swatches } from "ui-kit";


const DetentionTodayChip: React.FC = () => {

  return (
    <Chip
      className="detention-today-chip"
      text="Sanction"
      colorSwatch={Swatches.Danger}
      tooltip="The student has a sanction Today"
    />
  );
};

export default DetentionTodayChip;
