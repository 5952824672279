import { NEW_SMART_TASK } from "areas/behaviour/constants/smartTasks";
import smartTaskActions from "areas/behaviour/actions/smartTasks/smartTaskActions";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";
import { SchoolSmartTaskView } from "areas/behaviour/types/smartTasks/smartTaskResponse.types";


interface ISmartTaskState {
  selectedSmartTaskId: number;
  smartTask: SchoolSmartTaskView | null;
  loading: boolean;
  refresh: boolean;
  getSmartTaskError: IBeehiveError;
  createSmartTaskError: IBeehiveError;
  updateSmartTaskError: IBeehiveError;
  deleteSmartTaskError: IBeehiveError
}

const INITIAL_STATE: ISmartTaskState = {
  selectedSmartTaskId: null,
  smartTask: null,
  loading: false,
  refresh: false,
  getSmartTaskError: null,
  createSmartTaskError: null,
  updateSmartTaskError: null,
  deleteSmartTaskError: null,
};


const smartTaskReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISmartTaskState => {

  const {
    GETSMARTTASK,
    SELECTSMARTTASK,
    RESETSELECTEDSMARTTASK,
    NEWSMARTTASK,
    CREATESMARTTASK,
    UPDATESMARTTASK,
    DELETESMARTTASK,
  } = smartTaskActions.types;

  switch (action.type) {
    case GETSMARTTASK.START:
      return {
        ...state,
        loading: true,
        getSmartTaskError: null,
        createSmartTaskError: null,
        updateSmartTaskError: null,
        deleteSmartTaskError: null,
      };

    case GETSMARTTASK.SUCCESS:
      return {
        ...state,
        loading: false,
        refresh: false,
        smartTask: action.payload,
      };

    case GETSMARTTASK.FAILED:
      return { ...state, loading: false, getSmartTaskError: action.payload };

    case SELECTSMARTTASK:
      return { ...state, selectedSmartTaskId: action.payload };

    case RESETSELECTEDSMARTTASK:
      return { ...state, selectedSmartTaskId: null, smartTask: null };

    case NEWSMARTTASK:
      return { ...state, smartTask: NEW_SMART_TASK };

    case CREATESMARTTASK.START:
      return { ...state, loading: true, createSmartTaskError: null };

    case CREATESMARTTASK.SUCCESS:
      return { ...state, loading: false };

    case CREATESMARTTASK.FAILED:
      return { ...state, loading: false, createSmartTaskError: action.payload };

    case UPDATESMARTTASK.START:
      return { ...state, loading: true, updateSmartTaskError: null };

    case UPDATESMARTTASK.SUCCESS:
      return { ...state, loading: false, refresh: true };

    case UPDATESMARTTASK.FAILED:
      return { ...state, loading: false, updateSmartTaskError: action.payload };

    case DELETESMARTTASK.START:
      return { ...state, loading: true, deleteSmartTaskError: null };

    case DELETESMARTTASK.SUCCESS:
      return { ...state, loading: false };

    case DELETESMARTTASK.FAILED:
      return { ...state, loading: false, deleteSmartTaskError: action.payload };

    default:
      return state;
  }
};

export default smartTaskReducer;
