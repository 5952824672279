import React, { useState, useEffect } from "react";
import { ActionBar, DatePicker, Dropdown } from "ui-kit";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import { ManagedMoveQueryFilter } from "areas/planner/types/managedMoves/managedMovesRequest.types";
import { ManagedMoveStatus } from "areas/planner/types/managedMoves/managedMovesShared.types";
import moment from "moment";


interface IManagedMovesFilterProps {
  onChange: (filter: ManagedMoveQueryFilter) => void;
  userBaseSchool?: number;
}


const ManagedMovesFilter: React.FC<IManagedMovesFilterProps> = ({ onChange, userBaseSchool }) => {

  const [_filter, _setFilter] = useState<ManagedMoveQueryFilter>({
    status: null,
    startDate: null,
    endDate: null,
    fromSchoolId: userBaseSchool ? userBaseSchool : -1,
    hostSchoolId: -1,
  });

  useEffect(() => {
    _filter && onChange?.(_filter);
  }, [_filter]);

  return (
    <ActionBar>
      <ActionBar.Filter>
        <ActionBar.FilterItem title="Move Outcome">
          <Dropdown
            fluid
            value={_filter?.status}
            onChange={value => _setFilter({ ..._filter, status: value })}
          >
            <Dropdown.Item value={null} label="All Outcomes" />
            <Dropdown.Item value={ManagedMoveStatus.Open} label="Open" />
            <Dropdown.Item value={ManagedMoveStatus.Hosting} label="Hosted" />
            <Dropdown.Item value={ManagedMoveStatus.ManagedMove} label="Managed Move" />
            <Dropdown.Item value={ManagedMoveStatus.PermanentMove} label="Permanent Move" />
          </Dropdown>
        </ActionBar.FilterItem>
        <ActionBar.FilterItem title="Home School">
          <SchoolDropdown
            fluid
            initialValue={_filter?.fromSchoolId}
            includeAllOption
            onChange={value => _setFilter({ ..._filter, fromSchoolId: value })}
            showAllSchools
          />
        </ActionBar.FilterItem>
        <ActionBar.FilterItem title="Host School">
          <SchoolDropdown
            fluid
            initialValue={_filter?.hostSchoolId}
            includeAllOption
            onChange={value => _setFilter({ ..._filter, hostSchoolId: value })}
            showAllSchools
          />
        </ActionBar.FilterItem>
        <ActionBar.FilterItem title="Start Date">
          <DatePicker
            selectedDate={_filter?.startDate}
            placeholder="Move Start"
            fluid
            closeOnSelect
            onChange={value => _setFilter({ ..._filter, startDate: moment(value).format("YYYY-DD-MM") })}
          />
        </ActionBar.FilterItem>
        <ActionBar.FilterItem title="End Date">
          <DatePicker
            selectedDate={_filter?.endDate}
            placeholder="Move End"
            fluid
            closeOnSelect
            onChange={value => _setFilter({ ..._filter, endDate: moment(value).format("YYYY-DD-MM") })}
          />
        </ActionBar.FilterItem>
      </ActionBar.Filter>
    </ActionBar>
  );
};

export default ManagedMovesFilter;
