import { IBeehiveAction } from "types/common/action.types";
import lunchRegisterActions from "../../actions/lunchRegisterActions"
import { LunchRecordListView } from "areas/payments/types/catering/cateringResponses.types";
import { LunchRecordQueryFilter, StudentChoicesQueryFilter } from "areas/payments/types/catering/cateringRequests.types";
import { LunchType } from "areas/payments/types/catering/cateringShared.types";


export interface CateringReportFilter extends 
  Partial<Omit<LunchRecordQueryFilter, "date" | "lunchType">>, 
  Partial<Omit<StudentChoicesQueryFilter, "startDate" | "endDate">> 
{
  lunchType?: LunchType | -1;
  date?: Date;
  startDate?: Date;
  endDate?: Date;
}

export interface ILunchRecordState {
  loading: boolean;
  records: LunchRecordListView[];
  error: null | string;
  filter: CateringReportFilter;
}

const INITIAL_STATE: ILunchRecordState = {
  loading: false,
  records: [],
  error: null,
  filter: {
    schoolId: null,
    tutorGroupId: null,
    date: new Date(),
    lunchType: -1,
  },
};


const lunchRecordsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ILunchRecordState => {

  const {
    PAYMENTS_GETLUNCHRECORDS,
    PAYMENTS_SETRECORDSFILTER,
    PAYMENTS_SAVELUNCHREGISTER,
  } = lunchRegisterActions.lunchRegisterTypes;
  //const { PAYMENTS_ADDMANUALLUNCHCREDIT } = paymentActions.types;

  switch (action.type) {
    
    case PAYMENTS_GETLUNCHRECORDS.START:
      return { 
        ...state, 
        records: [], 
        loading: true, 
        error: null 
      };
    case PAYMENTS_GETLUNCHRECORDS.SUCCESS:
      return { 
        ...state, 
        loading: false, 
        records: action.payload, 
        error: null 
      };
    case PAYMENTS_GETLUNCHRECORDS.FAILED:
      return {
        ...state,
        loading: false,
        records: [],
        error: "There was a problem loading the lunch records",
      };

    case PAYMENTS_SETRECORDSFILTER:
      return { 
        ...state, 
        filter: action.payload 
      };

    // case PAYMENTS_ADDMANUALLUNCHCREDIT.SUCCESS:
    //   return {
    //     ...state,

    //     records: state.records.map((s) =>
    //       s.user.id === action.payload.user.id
    //         ? { ...s, balance: action.payload.balance }
    //         : s
    //     ),
    //   };

    case PAYMENTS_SAVELUNCHREGISTER.SUCCESS:
      return { ...state, records: action.payload };

    default:
      return state;
  }
};

export default lunchRecordsReducer;