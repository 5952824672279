import React from "react";
import styled from "styled-components";
import { arrays } from "utils";
import { EmptyMessage, Spacing, Breakpoints } from "ui-kit";
import ProductListItem from "./productListItem";
import { ProductListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


const Wrapper = styled.div`
  position: relative;

  @media screen and (min-width: 640px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 ${Spacing.Large}px;
    grid-template-areas: ". .";

    .product-list-item {
    }
  }

  @media screen and (min-width: ${Breakpoints.s}px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: ". . .";
  }

  @media screen and (min-width: ${Breakpoints.l}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: ". . . .";
  }
`;


interface IProductListProps {
  products: ProductListView[];
}


const ProductList: React.FC<IProductListProps> = ({ products }) => {

  if (arrays.isEmpty(products)) {
    return (
      <EmptyMessage
        icon="shopping-bag"
        title="The store is empty"
        summary="There are no store items for you to purchase"
        cover
      />
    );
  }

  return (
    <Wrapper className="products-list">
      {products.map((product: ProductListView, index: number) => (
        <ProductListItem key={index} product={product} />
      ))}
    </Wrapper>
  );
};

export default ProductList;
