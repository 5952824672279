import currentPmUserReducer from "./pm/currentPmUserReducer";
import currentReviewPeriodReducer from "./pm/currentReviewPeriodReducer";
import managedStaffMemberReducer from "./pm/managedStaffMemberReducer";
import managedStaffReducer from "./pm/managedStaffReducer";
import observationReducer from "./pm/observationReducer";
import observationsReducer from "./pm/observationsReducer";
import reviewReducer from "./pm/reviewReducer";
import reviewReportReducer from "./pm/reviewReportReducer";
import holidayCardReportingReducer from "./holidayCardReportingReducer";
import leaveRequestReducer from "./leaveRequestReducer";
import leaveRequestsReducer from "./leaveRequestsReducer";
import overtimeReducer from "./overtimeReducer";
import staffMemberHolidayCardReducer from "./staffMemberHolidayCardReducer";
import staffReducer from "./staffReducer";
import userHolidayCardReducer from "./userHolidayCardReducer";

const humanResources = {
  currentPmUser: currentPmUserReducer,
  currentReviewPeriod: currentReviewPeriodReducer,
  managedStaffMember: managedStaffMemberReducer,
  managedStaff: managedStaffReducer,
  observation: observationReducer,
  observations: observationsReducer,
  review: reviewReducer,
  reviewReport: reviewReportReducer,
  holidayCardReporting: holidayCardReportingReducer,
  leaveRequest: leaveRequestReducer,
  leaveRequests: leaveRequestsReducer,
  overtime: overtimeReducer,
  staffMemberHolidayCard: staffMemberHolidayCardReducer,
  staff: staffReducer,
  userHolidayCard: userHolidayCardReducer,
};

export default humanResources;
