import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  DatePicker,
  ActionBar,
  Orientation,
  Checkbox,
  CheckboxList,
  Spacing,
  TextInput,
  Button,
  Swatches,
  Size,
  Flyout,
  Card,
  Right,
  Left,
  fontStyle,
  displayFont,
  typescale,
  neutral,
  honeygold,
} from "ui-kit";
import styled from "styled-components";
import { Constants } from "configuration";
import detentionRegisterActions from "areas/behaviour/actions/detentions/detentionRegisterActions";
import flyoutActions from "actions/ui/flyouts";
import MultiSchoolUserView from "sharedComponents/common/users/multiSchoolUserView";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import {
  DETENTION_GROUPBY,
  DETENTION_STATUS_FILTERS,
} from "areas/behaviour/constants/tableConstants";
import YearGroupDropdown from "../../behaviourCommon/yearGroupDropdown";
import BehaviourCodeDropdown from "../../behaviours/behaviourCodeDropdown";
import BehaviourCategoriesDropdown from "../../behaviours/behaviorCategoriesDropdown";
import { GroupListView } from "types/users/userGroups.types";
import { DetentionRegisterFilters } from "areas/behaviour/reducers/detentions/detentionRegisterReducer";


const ActionBarItemWrapper = styled.div`
  margin-top: ${Spacing.Medium}px;
  .dropdown {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  .button {
    white-space: nowrap;
  }
`;

const MoreFiltersButton = styled.div`
  .button {
    background: unset;
    padding-left: 0;
    padding-right: 0;

    .button-content {
      ${fontStyle(displayFont.medium, typescale.paragraph, neutral[500])}

      .icon {
        font-size: unset;

        svg {
          color: ${neutral[500]};
        }
      }
    }

    &:hover {
      .button-content {
        color: ${honeygold};

        .icon svg {
          color: ${honeygold};
        }
      }
    }
  }
`;


interface IDetentionRegisterMenuProps {
  date: Date;
  setDate: (date: Date) => void;
  onSelectDetentionStatusFilter: (filters: number[]) => void;
  yearGroups?: GroupListView[];
  onSelectYearGroup: (yearGroup: number) => void;
  onSelectGroupBy: (keys: number[]) => void;
  groupByProperties: number[];
  detentionFilters: DetentionRegisterFilters;
  behaviourCodeFilter: number;
  onChangeBehaviourCode: (behaviourCategory: number) => void;
  onChangeBehaviourCategory: (behaviourCategory: number) => void;
  handleSearchTermChange: (searchTerm: string) => void;
  loadingDetentions: boolean;
  onSelectSchool: (schoolId: number) => void;
  schoolId: number;
  handleGetDetentions: () => void;
  areDetentionsNull: boolean;
}


const DetentionRegisterMenu: React.FC<IDetentionRegisterMenuProps> = ({
  date,
  setDate,
  onSelectDetentionStatusFilter,
  yearGroups,
  onSelectYearGroup,
  onSelectGroupBy,
  groupByProperties,
  detentionFilters,
  behaviourCodeFilter,
  onChangeBehaviourCode,
  onChangeBehaviourCategory,
  handleSearchTermChange,
  loadingDetentions,
  onSelectSchool,
  schoolId,
  handleGetDetentions,
  areDetentionsNull,
}) => {

  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    handleSearchTermChange(searchTerm);
  }, [searchTerm]);

  const handleUnmarkedDetentionClick = () => {
    detentionRegisterActions.setViewUnmarkedDetentions(
      !detentionFilters.viewUnmarkedDetentions
    );
  };

  const handleGroupByChanged = (value: number[]) => {
    onSelectGroupBy(value);
  };

  const handleDetentionStatusFilterChanged = (value: number[]) => {
    onSelectDetentionStatusFilter(value);
  };

  const handleBehaviourCodeChange = (value: number) => {
    onChangeBehaviourCode(value);
  };

  const handleBehaviourCategoryChange = (value: number) => {
    onChangeBehaviourCategory(value);
  };

  const FilterButton = () => {
    return areDetentionsNull ? (
      <Button
        color={Swatches.Primary}
        text="Get Detentions"
        onClick={() => {
          handleGetDetentions();
          flyoutActions.closeFlyout();
        }}
        size={Size.Small}
        fluid
      />
    ) : (
      <Button
        color={Swatches.Success}
        text="Filter Detentions"
        onClick={() => {
          handleGetDetentions();
          flyoutActions.closeFlyout();
        }}
        size={Size.Small}
        fluid
      />
    );
  };

  return (
    <>
      <ActionBar>
        <Left>
          <ActionBar.Filter>
            <MultiSchoolUserView>
              <ActionBar.FilterItem>
                <SchoolDropdown
                  onChange={onSelectSchool}
                  initialValue={schoolId}
                  fluid
                />
              </ActionBar.FilterItem>
            </MultiSchoolUserView>

            <ActionBar.FilterItem>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                disabled={detentionFilters.viewUnmarkedDetentions}
                selectedDate={date}
                onChange={date => setDate(date)}
                closeOnSelect
                fluid
              />
            </ActionBar.FilterItem>

            <ActionBar.FilterItem>
              <TextInput
                value={searchTerm}
                fluid
                placeholder="Search Detentions... "
                onChange={value => setSearchTerm(value)}
                loading={loadingDetentions}
                icon="search"
              />
            </ActionBar.FilterItem>

            <ActionBar.FilterItem>
              <MoreFiltersButton>
                <Button
                  text="More Filters"
                  onClick={() =>
                    flyoutActions.openFlyout(
                      Constants.FLYOUTS.DETENTIONREGISTER
                    )
                  }
                  size={Size.Small}
                  icon="filter"
                />
              </MoreFiltersButton>
            </ActionBar.FilterItem>
          </ActionBar.Filter>
        </Left>

        {/* <Right>
          <ContextMenu>
            <ContextMenu.Item text="Item 1" />
            <ContextMenu.Item text="Item 2" />
          </ContextMenu>
        </Right> */}

        {!areDetentionsNull && (
          <Right>
            <ButtonWrapper>
              <FilterButton />
            </ButtonWrapper>
          </Right>
        )}
      </ActionBar>

      <Flyout
        title="Filters"
        name={Constants.FLYOUTS.DETENTIONREGISTER}
        // onClose={() => setBehaviour(null)}
      >
        <Flyout.Body>
          <Card>
            <Card.Body>
              <ActionBar.FilterItem title="Group By">
                <CheckboxList
                  orientation={Orientation.Portrait}
                  onChange={handleGroupByChanged}
                  value={groupByProperties}
                >
                  {DETENTION_GROUPBY.map((groupBy, index) => (
                    <CheckboxList.Item
                      key={index}
                      text={groupBy.name}
                      value={groupBy.key}
                    />
                  ))}
                </CheckboxList>
              </ActionBar.FilterItem>

              <br />

              <ActionBar.FilterItem title="Detention Status">
                <CheckboxList
                  orientation={Orientation.Portrait}
                  onChange={handleDetentionStatusFilterChanged}
                  value={detentionFilters.detentionStatusFilters}
                  disabled={detentionFilters.viewUnmarkedDetentions}
                >
                  {DETENTION_STATUS_FILTERS.map((filter, index) => (
                    <CheckboxList.Item
                      key={index}
                      text={filter.name}
                      value={filter.value}
                    />
                  ))}
                </CheckboxList>
              </ActionBar.FilterItem>

              <br />

              <ActionBar.FilterItem title="Year Group">
                <ActionBarItemWrapper>
                  <YearGroupDropdown
                    yearGroups={yearGroups}
                    selectedYearGroup={detentionFilters.yearGroupFilter}
                    handleSelectYearGroup={onSelectYearGroup}
                  />
                </ActionBarItemWrapper>
              </ActionBar.FilterItem>

              <br />

              <ActionBar.FilterItem title="Behaviour">
                <ActionBarItemWrapper>
                  <BehaviourCodeDropdown
                    schoolId={schoolId}
                    selected={detentionFilters.behaviourCodeFilter}
                    onChange={handleBehaviourCodeChange}
                  />
                </ActionBarItemWrapper>

                <ActionBarItemWrapper>
                  <BehaviourCategoriesDropdown
                    schoolId={schoolId}
                    behaviourCodeFilter={behaviourCodeFilter}
                    selected={detentionFilters.behaviourCategoryFilter}
                    onChange={handleBehaviourCategoryChange}
                  />
                </ActionBarItemWrapper>
              </ActionBar.FilterItem>

              <br />

              <ActionBar.FilterItem title="Past Detentions">
                <Checkbox
                  text="Show Unmarked"
                  checked={detentionFilters.viewUnmarkedDetentions}
                  onChange={handleUnmarkedDetentionClick}
                />
              </ActionBar.FilterItem>
            </Card.Body>
          </Card>
        </Flyout.Body>

        <Flyout.Footer>
          <FilterButton />
        </Flyout.Footer>
      </Flyout>
    </>
  );
};


export default DetentionRegisterMenu;
