import { FEED_TYPE_KEY } from "areas/planner/constants/activityFeed";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import onCallActions from "areas/behaviour/actions/onCall/onCallActions";
import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import studentActions from "../../actions/student/studentActions";
import studentAwardActions from "../../actions/student/studentAwardActions";
import studentLeavingLessonActions from "../../actions/student/studentLeavingLessonActions";
import studentNoteActions from "../../actions/student/studentNoteActions";
import studentQuizActions from "../../actions/student/studentQuizActions";
import defaults from "reducers/defaults";

const INITIAL_STATE = {
  studentActivityFeed: [], //StudentFeedListView[] 
  feedTypeFilter: FEED_TYPE_KEY.ALL,
  createdDateFilter: null,
  paging: defaults.DEFAULT_PAGING,
  refresh: false,
  loading: false,
  error: null,
};

const {
  GETSTUDENTACTIVITYFEED,
  SETSTUDENTFEEDTYPEFILTER,
  SETSTUDENTCREATEDDATEFILTER,
} = studentActions.types;

const { CREATESTUDENTNOTE, DELETESTUDENTNOTE } = studentNoteActions.types;
const { SAVESTUDENTANSWER, DELETESTUDENTANSWER } = studentQuizActions.types;
const { ADDSTUDENTAWARD, DELETESTUDENTAWARD } = studentAwardActions.types;

const {
  ADDBEHAVIOUR,
  UPDATEBEHAVIOUR,
  REVOKEBEHAVIOUR,
  UPSCALEBEHAVIOUR,
  UPSCALEBEHAVIOURDETENTION,
} = behaviourActions.types;

const { ADDREWARDS, UPDATEREWARD, REVOKEREWARD } = rewardActions.types;

const { CANCELDETENTION, MISSEDDETENTION, ATTENDEDDETENTION } =
  detentionActions.types;

const { ADDLEAVINGLESSONRECORD, DELETELEAVINGLESSONRECORD, ADDMULTISTUDENTLEAVINGLESSONRECORD } =
  studentLeavingLessonActions.types;

const {
  CREATEONCALLREQUEST,
  RESOLVEONCALLREQUEST,
  CANCELONCALLREQUEST,
  UPDATEONCALLREQUEST,
} = onCallActions.types;

const studentActivityFeedReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GETSTUDENTACTIVITYFEED.START:
      return { ...state, loading: true, error: null };

    case GETSTUDENTACTIVITYFEED.SUCCESS:
      return {
        ...state,
        studentActivityFeed:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.studentActivityFeed, ...action.payload.items],
        paging: action.payload.paging,
        refresh: false,
        loading: false,
      };

    case GETSTUDENTACTIVITYFEED.FAILED:
      return {
        ...state,
        loading: false,
        refresh: false,
        error: action.payload,
      };

    case SETSTUDENTFEEDTYPEFILTER:
      return {
        ...state,
        feedTypeFilter: action.payload,
        studentActivityFeed: [],
      };

    case SETSTUDENTCREATEDDATEFILTER:
      return {
        ...state,
        createdDateFilter: action.payload,
        studentActivityFeed: [],
      };

    case CREATESTUDENTNOTE.SUCCESS:
    case DELETESTUDENTNOTE.SUCCESS:
    case SAVESTUDENTANSWER.SUCCESS:
    case DELETESTUDENTANSWER.SUCCESS:
    case ADDSTUDENTAWARD.SUCCESS:
    case DELETESTUDENTAWARD.SUCCESS:
    case ADDBEHAVIOUR.SUCCESS:
    case ADDREWARDS.SUCCESS:
    case UPDATEREWARD.SUCCESS:
    case REVOKEBEHAVIOUR.SUCCESS:
    case REVOKEREWARD.SUCCESS:
    case CANCELDETENTION.SUCCESS:
    case MISSEDDETENTION.SUCCESS:
    case ATTENDEDDETENTION.SUCCESS:
    case UPDATEBEHAVIOUR.SUCCESS:
    case UPSCALEBEHAVIOUR.SUCCESS:
    case UPSCALEBEHAVIOURDETENTION.SUCCESS:
    case ADDLEAVINGLESSONRECORD.SUCCESS:
    case ADDMULTISTUDENTLEAVINGLESSONRECORD.SUCCESS: 
    case DELETELEAVINGLESSONRECORD.SUCCESS:
    case CREATEONCALLREQUEST.SUCCESS:
    case CANCELONCALLREQUEST.SUCCESS:
    case RESOLVEONCALLREQUEST.SUCCESS:
    case UPDATEONCALLREQUEST.SUCCESS:
      return { ...state, refresh: true };

    default:
      return state;
  }
};

export default studentActivityFeedReducer;
