import { useSelector } from "react-redux";
import { ActionBar, Button, Size, Swatches } from "ui-kit";
import { SchoolDropdown } from "sharedComponents/common";
import PublishStatusFilter from "sharedComponents/common/filtering/publishStatusFilter";
import ArticleCategoryDropdown from "./articleCategoryDropdown";
import newsActions from "areas/news/actions/newsActions";
import { RootState } from "reducers/store";

interface IArticlesFilterProps {
  onNewArticleClick?: (categoryId: number) => void
}

const ArticlesFilter: React.FC<IArticlesFilterProps> = ({ onNewArticleClick }) => {

  const { filter } = useSelector((state: RootState) => state.articleFilter);

  //   useEffect(() => {
  //     if (filter.schoolId == -1) {
  //       filterBySchoolAction(user?.baseSchool.id);
  //     }
  //   }, []);

  const handleSchoolChange = (schoolId: number) => {
    newsActions.filterBySchool(schoolId);
  };

  const handleCategoryChange = (categoryId: number) => {
    newsActions.filterByCategory(categoryId ? categoryId : -1);
  };

  const handlePublishStatusChange = (activeOption: number) => {
    newsActions.filterByPublishStatus(activeOption);
  };

  const handleNewArticleClick = () => {
    onNewArticleClick?.(filter.categoryId < 0 ? 0 : filter.categoryId); // The ternary is just to avoid having -1 in the url for the editor
  };

  return (
    <ActionBar>
      <SchoolDropdown
        onChange={handleSchoolChange}
        includeAllOption={true}
        includeTrust={false}
        initialValue={filter.schoolId}
      />
      <ArticleCategoryDropdown
        onChange={handleCategoryChange}
        value={filter.categoryId}
        includeAllOption
      />
      <PublishStatusFilter
        onChange={handlePublishStatusChange}
        initialValue={filter.publishStatus}
      />

      <Button
        size={Size.Small}
        text="New Article"
        color={Swatches.Primary}
        onClick={handleNewArticleClick}
        tooltip="Create a new article"
      />
    </ActionBar>
  );
};

export default ArticlesFilter;
