import styled from "styled-components";
import ActivityDate from "./activityDate";
import {
  DetailLabel,
  fontSize,
  textFont,
  neutral,
  Spacing,
  Card,
  Chip,
  fontStyle,
  displayFont,
  typescale,
} from "ui-kit";

export const FeedItemActionButtons = styled.div`
  display: flex;
  padding: ${Spacing.Small}px ${Spacing.Small}px ${Spacing.Small}px
    ${Spacing.Medium}px;

  .icon {
    margin-right: ${Spacing.Medium}px;
    // font-size: 0.9rem;

    &:hover {
      cursor: pointer;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const FeedItemContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;

  ${({ onClick }) =>
    onClick &&
    `
      cursor: pointer;
    `}

  .detail-label {
    .chip {
      margin-left: ${Spacing.Small}px;
      // margin-bottom: ${Spacing.Small - 2}px;
      ${fontSize(typescale.sub)}
    }
  }

  .user-name {
    ${fontStyle(displayFont.medium, typescale.paragraph)}
  }
`;

export const FeedItemDetails = styled.div`
  ${fontStyle(textFont.roman, typescale.paragraph)}

  .note-content {
    padding-top: ${Spacing.Small + 4}px;
  }
`;

export const FeedItemDate = styled.div`
  ${fontStyle(displayFont.roman, typescale.paragraph, neutral[500])}

  .chip {
    margin-left: ${Spacing.Small}px;
    ${fontSize(typescale.sub)}
    padding: ${Spacing.Small - 2}px ${Spacing.Small}px;
  }
`;

export const getGroupName = activity => {
  return activity.classGroup ? (
    <>
      {" "}
      in <b>{activity.classGroup.friendlyName}</b>{" "}
    </>
  ) : activity.tutorGroup ? (
    <>
      {" "}
      in <b>{activity.tutorGroup.friendlyName}</b>{" "}
    </>
  ) : activity.customGroup ? (
    <>
      {" "}
      in <b>{activity.customGroup.friendlyName}</b>{" "}
    </>
  ) : activity.provisionGroup ? (
    <>
      {" "}
      in <b>{activity.provisionGroup.friendlyName}</b>{" "}
    </>
  ) : null;
};

export const DateAndStudentName = ({
  displayStudentName,
  activity,
  children,
}) => {
  return displayStudentName && activity.students?.length === 1 ? (
    <DetailLabel
      label={
        <>
          {`${activity.students[0].firstName} ${activity.students[0].lastName}`}{" "}
          {children}
        </>
      }
      sub={<ActivityDate date={activity.createdDate} />}
      bold
    />
  ) : (
    <FeedItemDate>
      <ActivityDate date={activity.createdDate}>{children}</ActivityDate>
    </FeedItemDate>
  );
};
