import { Constants } from "configuration";
import StudentBehaviourWrapper from "areas/analytics/components/studentBehaviour/studentBehaviourWrapper";
import BehaviourPointsAccordion from "areas/behaviour/components/behaviours/behaviourPointsAccordion";
import RewardPointsAccordion from "areas/behaviour/components/rewards/rewardPointsAccordion";
import PupilPassport from "areas/send/components/passports/pupilPassport";
import ProvisionMaps from "areas/send/components/provisionMaps/provisionMaps";
import StudentManagedMoves from "areas/planner/components/managedMoves/studentManagedMoves";
import StudentAwardWrapper from "areas/planner/components/studentRecord/award/studentAwardWrapper";
import StudentClassListWrapper from "areas/planner/components/studentRecord/classList/studentClassListWrapper";
import StudentConsentForms from "areas/planner/components/studentRecord/consent/studentConsentForms";
import EventsWrapper from "areas/planner/components/studentRecord/events/eventsWrapper";
import StudentActivityWrapper from "areas/planner/components/studentRecord/studentActivity/studentActivityWrapper";
import Details from "areas/planner/components/studentRecord/studentDetails/details";
import StudentNotesListWrapper from "areas/planner/components/studentRecord/studentNote/studentNotesListWrapper";
import DetentionListWrapper from "areas/planner/components/studentRecord/detentions/detentionListWrapper";
import NonParentTimetableView from "areas/planner/components/timetable/nonParentTimetableView";
import Assignments from "areas/planner/components/assignments/assignments";
import StudentInterventions from "areas/planner/components/groups/interventions/studentInterventions";
import { PlannerStudentDetailView } from "../types/plannerStudentResponse.types";
import { IRole } from "configuration/constants.types";


export enum StudentProfileTabs {
  Details = 0,
  Activity = 1,
  Sanctions = 2,
  Timetable = 3,
  Classes = 4,
  Assignments = 5,
  Rewards = 6,
  Behaviours = 7,
  Awards = 8,
  Notes = 9,
  Events = 10,
  PupilPassport = 11,
  ProvisionMaps = 12,
  Reporting = 13,
  Consent = 14,
  Moves = 15,
  Interventions = 16,
}

export interface StudentProfileArea {
  id: StudentProfileTabs;
  text: string;
  path: string;
  component: JSX.Element;
  hide?: boolean;
  badge?: number;
  roles?: IRole[];
} 

export const getStudentProfileTab = (student: PlannerStudentDetailView, tabId: StudentProfileTabs) => {
  return getStudentRecordAreas(student, 0, 0).find(x => x.id === tabId);
}

export const getStudentRecordAreas = (student: PlannerStudentDetailView, currentIndex: number, totalNotes: number) : StudentProfileArea[] => [
  {
    id: StudentProfileTabs.Details,
    text: "Details",
    path: "/details",
    component: <Details student={student} />,
  },
  {
    id: StudentProfileTabs.Activity,
    text: "Activity",
    path: "/activity",
    component: (
      // @ts-ignore
      <StudentActivityWrapper
        studentId={student.id}
        schoolId={student.school?.id}
      />
    ),
  },
  {
    id: StudentProfileTabs.Sanctions,
    text: "Sanctions",
    path: "/sanctions",
    component: <DetentionListWrapper studentId={student.id} />,
    hide: student.school?.isPrimary,
  },
  {
    id: StudentProfileTabs.Timetable,
    text: "Timetable",
    path: "/timetable",
    component: <NonParentTimetableView user={student} />,
    hide: student.school?.isPrimary,
  },
  {
    id: StudentProfileTabs.Classes,
    text: "Classes/Groups",
    path: "/classes",
    component: <StudentClassListWrapper student={student} />,
  },
  {
    id: StudentProfileTabs.Assignments,
    text: "Assignments",
    path: "/assignments",
    component: (
      // @ts-ignore
      <Assignments
        user={student}
        hideNewAssignmentButton
        userType={Constants.USER_TYPES.STUDENT}
      />
    ),
    hide: student.school?.isPrimary,
  },
  {
    id: StudentProfileTabs.Rewards,
    text: "Rewards",
    path: "/rewards",
    // @ts-ignore
    component: <RewardPointsAccordion student={student} />,
  },
  {
    id: StudentProfileTabs.Behaviours,
    text: "Behaviours",
    path: "/behaviours",
    // @ts-ignore
    component: <BehaviourPointsAccordion student={student} />,
  },
  {
    id: StudentProfileTabs.Awards,
    text: "Awards",
    path: "/awards",
    // @ts-ignore
    component: <StudentAwardWrapper studentId={student.id} />,
  },
  {
    id: StudentProfileTabs.Notes,
    text: "Notes",
    path: "/notes",
    badge: totalNotes,
    // @ts-ignore
    component: <StudentNotesListWrapper student={student} />,
  },
  {
    id: StudentProfileTabs.Interventions,
    text: "Interventions",
    path: "/interventions",
    component: <StudentInterventions studentId={student.id} />,
  },
  {
    id: StudentProfileTabs.Events,
    text: "Events",
    path: "/events",
    component: <EventsWrapper student={student} />,
  },
  {
    id: StudentProfileTabs.PupilPassport,
    text: "Pupil Passport",
    path: "/pupilpassport",
    component: <PupilPassport student={student} />,
  },
  {
    id: StudentProfileTabs.ProvisionMaps,
    text: "Provision Maps",
    path: "/provisionmaps",
    component: <ProvisionMaps student={student} />,
  },
  {
    id: StudentProfileTabs.Reporting,
    text: "Reporting",
    path: "/reporting",
    component: <StudentBehaviourWrapper student={student} />,
  },
  {
    id: StudentProfileTabs.Consent,
    text: "Consent",
    path: "/consent",
    component: <StudentConsentForms student={student} />,
  },
  {
    id: StudentProfileTabs.Moves,
    text: "Moves",
    path: "/moves",
    roles: [Constants.ROLES.MOVES_ADMINISTRATOR],
    component: <StudentManagedMoves student={student} />,
  }
];
