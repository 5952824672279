import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import { Dropdown, Sub } from "ui-kit";
import providerActions from "areas/send/actions/providers/providerActions";
import { RootState } from "reducers/store";
import { ProviderListView } from "areas/send/types/passportResponse.types";

interface IProviderDropdownProps {
  value?: number;
  fluid?: boolean;
  onChange?: (value: number, label: string, data: ProviderListView) => void;
  placeholder?: string;
  includeAllOption?: boolean;
}

interface providerOption {
  value: number;
  label: string;
  data: ProviderListView;
}

const ProviderDropdown: React.FC<IProviderDropdownProps> = ({
  value,
  fluid,
  onChange,
  placeholder,
  includeAllOption,
}) => {

  const { error, loading, providers } = useSelector((state: RootState) => state.providers);
  const [_options, _setOptions] = useState<providerOption[]>([]);

  useEffect(() => {
    arrays.isEmpty(providers) && providerActions.getProviders(null);
  }, []);

  useEffect(() => {
    if (!arrays.isEmpty(providers)) {
      var opts: providerOption[] = providers.map(provider => ({
        value: provider.id,
        label: provider.name,
        data: provider
      }));

      if (includeAllOption) {
        opts = [{ label: "All Providers", value: -1, data: null }, ...opts];
      }

      _setOptions(opts);
    }
  }, [providers]);

  if (error) {
    return <Sub>There was a problem loading the providers</Sub>;
  }

  return (
    <>
      <Dropdown
        value={value}
        loading={loading}
        placeholder={placeholder ? placeholder : "Choose a Provider..."}
        fluid={fluid}
        onChange={onChange}
      >
        {_options?.map((option, index) => (
          <Dropdown.Item
            label={option.label}
            value={option.value}
            data={option.data}
            key={index}
          />
        ))}
      </Dropdown>
    </>
  );
};

export default ProviderDropdown;
