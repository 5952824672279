import React from "react";
import { ReactComponent as PandaSvg } from "assets/primary/characters/panda.svg";
import { IAnimalIconProps } from "./primaryCharacter";


export const Panda: React.FC<IAnimalIconProps> = ({ className }) => {
  
  return (
    <PandaSvg 
      //alt={alt} 
      className={className} 
    />
  )
};
export default Panda;
