import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { AddCustomGroupStudentCommand, RemoveCustomGroupStudentCommand, SaveCustomGroupCommand, UpdateCustomGroupStatusCommand, UpdateCustomGroupStudentsCommand } from "areas/planner/types/groups/customGroupRequest.types";
import client from "services/client";
import { callbackType, faulureCallback } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";
import { CustomGroupDetailView, GroupListView } from "types/users/userGroups.types";

const builder = new actionTypeBuilder("customGroup");

const types = {
  GETCUSTOMGROUP: builder.build("getcustomgroup"),
  SAVECUSTOMGROUP: builder.build("savecustomgroup"),
  CREATECUSTOMGROUP: builder.build("createcustomgroup"),
  DELETECUSTOMGROUP: builder.build("deletecustomgroup"),
  UPDATECUSTOMGROUPSTUDENTS: builder.build("updatecustomgroupstudents"),
  ADDSTUDENTTOCUSTOMGROUP: builder.build("addstudenttocustomgroup"),
  REMOVESTUDENTFROMCUSTOMGROUP: builder.build("removestudentfromcustomgroup"),
  CHANGECUSTOMGROUPSTATUS: builder.build("changecustomgroupstatus"),
  GETCUSTOMGROUPSFORUSER: builder.build("getcustomgroupsforuser"),
};

const getCustomGroupsForUser = (
  userId: string,
  academicYearId: number,
  schoolId: number,
  onSuccess?: callbackType<GroupListView[]>
) => {
  return action<GroupListView[]>(
    () =>
      client.get(
        `planner/staff/${userId}/customGroups?academicYearId=${academicYearId}&schoolId=${schoolId}`
      ),
    types.GETCUSTOMGROUPSFORUSER,
    onSuccess
  );
};

const getCustomGroup = (customGroupId: number, onSuccess?: callbackType<CustomGroupDetailView>) => {
  return action<CustomGroupDetailView>(
    () => client.get(`planner/customgroups/${customGroupId}`),
    types.GETCUSTOMGROUP,
    onSuccess
  );
};

const createCustomGroup = (data: SaveCustomGroupCommand, onSuccess?: callbackType<number>, onFailure?: faulureCallback) => {
  return action<number>(
    () => client.post(`planner/customgroups`, data),
    types.CREATECUSTOMGROUP,
    onSuccess,
    onFailure
  );
};

const saveCustomGroup = (data: SaveCustomGroupCommand, onSuccess?: callbackType<null>, onFailure?: faulureCallback) => {
  return action<null>(
    () => client.post(`planner/customgroups/${data.id}`, data),
    types.SAVECUSTOMGROUP,
    onSuccess,
    onFailure
  );
};

const deleteCustomGroup = (groupId: number, onSuccess?: callbackType<null>, onFailure?: faulureCallback) => {
  return action<null>(
    () => client.delete(`planner/customgroups/${groupId}`),
    types.DELETECUSTOMGROUP,
    onSuccess,
    onFailure
  );
};

const updateCustomGroupStudents = (customGroupId: number, payload: UpdateCustomGroupStudentsCommand, onSuccess?: callbackType<null>) => {
  return action<null>(
    () =>
      client.post(
        `planner/customgroups/${customGroupId}/updateStudents`,
        payload
      ),
    types.UPDATECUSTOMGROUPSTUDENTS,
    onSuccess
  );
};

const addStudentToCustomGroup = (customGroupId: number, payload: AddCustomGroupStudentCommand, onSuccess?: callbackType<null>) => {
  return action<null>(
    () =>
      client.post(`planner/customgroups/${customGroupId}/addStudent`, payload),
    types.ADDSTUDENTTOCUSTOMGROUP,
    onSuccess
  );
};

const removeStudentFromCustomGroup = (customGroupId: number, payload: RemoveCustomGroupStudentCommand, onSuccess?: callbackType<null>) => {
  return action<null>(
    () =>
      client.post(
        `planner/customgroups/${customGroupId}/removeStudent`,
        payload
      ),
    types.REMOVESTUDENTFROMCUSTOMGROUP,
    onSuccess
  );
};

const changeCustomGroupStatus = (customGroupId: number, payload: UpdateCustomGroupStatusCommand, onSuccess?: callbackType<null>) => {
  return action<null>(
    () =>
      client.post(
        `planner/customgroups/${customGroupId}/updateStatus`,
        payload
      ),
    types.CHANGECUSTOMGROUPSTATUS,
    onSuccess
  );
};


const customGroupActions = {
  types,
  getCustomGroupsForUser,
  getCustomGroup,
  createCustomGroup,
  saveCustomGroup,
  deleteCustomGroup,
  updateCustomGroupStudents,
  addStudentToCustomGroup,
  removeStudentFromCustomGroup,
  changeCustomGroupStatus
};

export default customGroupActions;
