import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Card,
  StructuredList,
  Left,
  Right,
  ValidationMessage,
  Size,
  Swatches,
  Spacing,
  Dropdown,
  honeygold,
  TextInput,
  neutral,
  RichTextEditor,
  ActionBar,
  DetailLabel,
} from "ui-kit";
import { FilePicker } from "sharedComponents/common";
import { arrays } from "utils";
import styled from "styled-components";
import { BulletinSectionView } from "areas/news/types/newsResponse.types";
import { FileListView } from "types/common/files.types";


const SectionWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  > .left {
    width: 50%;
    margin-right: ${Spacing.Large}px;
  }
  > .right {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
`;

const SwatchesWrapper = styled.div`
  display: flex;
`;

const SwatchWrapper = styled.div<{selected?: boolean}>`
  display: flex;
  flex-direction: column;
  margin-right: ${Spacing.Medium}px;
  border-color: ${neutral[300]};
  border-style: solid;
  flex-grow: 1;
  flex-basis: 25%;
  max-width: 70px;
  border-width: 1px 1px 1px 1px;
  &:hover {
    cursor: pointer;
  }

  &:last-child {
    margin-right: 0;
  }

  .swatch-color {
    ${({ color }) => `background: ${color};`}
    flex-grow: 1;
    width: 100%;
    height: 50px;
  }

  .swatch-details {
    padding: ${Spacing.Small}px ${Spacing.Default}px;
    border-top-style: solid;

    .detail-label .label {
      font-size: 9px;
      line-height: 16px !important;
      margin-bottom: 0;
    }

    .detail-label .sub {
      font-size: 9px;
      line-height: 12px !important;
      margin-bottom: 0;
    }

    ${({ selected }) =>
      selected &&
      `
      background: ${honeygold};   
      border-width: 1px;
      border-top-color: ${neutral[100]};
      .sub, .label { color: ${neutral[100]}; } `}
  }
`;

interface IBulletinSectionModalProps {
  section: BulletinSectionView;
  open: boolean;
  onClose?: () => void;
  onSave?: (section: BulletinSectionView) => void;
  primarySwatch: string;
  secondarySwatch: string;
  lightSwatch: string;
  darkSwatch: string;
}

interface newSection extends BulletinSectionView {
  files: FileListView[]
}

const BulletinSectionModal: React.FC<IBulletinSectionModalProps> = ({
  section,
  open,
  onClose,
  onSave,
  primarySwatch,
  secondarySwatch,
  lightSwatch,
  darkSwatch,
}) => {

  const [_open, _setOpen] = useState<boolean>(false);
  const [_section, _setSection] = useState<newSection | null>(null);
  const [_content, _setContent] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    _setSection({
      ...section,
      files: section?.file != null ? [section?.file] : [],
    });
    _setContent(section?.content);
  }, [section]);

  const handleSave = () => {
    // Validation
    var errors = [];

    if (!_section.title) {
      errors.push(`Please enter a section title.`);
    }
    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    onSave?.({
      ..._section,
      content: _content,
      file: _section.files.length ? _section.files[0] : null,
    });
  };

  const setSwatch = (label: string, color: number) => {
    _setSection({ ..._section, color: color, colorName: label });
  };

  const Swatch = ({ label, sub, value } : { label: string, sub: string, value: number }) => {
    return (
      <SwatchWrapper
        className="swatch"
        onClick={() => setSwatch(label, value)}
        color={sub}
        selected={_section?.color === value}
      >
        <div className="swatch-color"></div>
        <div className="swatch-details">
          <DetailLabel bold sub={sub} label={label} />
        </div>
      </SwatchWrapper>
    );
  };

  return (
    <Modal
      open={_open}
      onClose={onClose}
      title="Edit Section"
      width="90%"
      height="90%"
    >
      <Modal.Body>
        <ValidationMessage errors={validationErrors} />
        <SectionWrapper>
          <Left>
            <Card title="Title &amp; Styling">
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="Section Title" required>
                    <TextInput
                      value={_section?.title}
                      onChange={value =>
                        _setSection({
                          ..._section,
                          title: value,
                        })
                      }
                      characterCount={true}
                      maxLength={250}
                      fluid
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="Layout" required>
                    <Dropdown
                      fluid
                      value={_section?.layout}
                      onChange={(val, label) =>
                        _setSection({
                          ..._section,
                          layout: val,
                          layoutName: label,
                        })
                      }
                    >
                      <Dropdown.Item value={0} label="Introduction" />
                      <Dropdown.Item value={1} label="Center" />
                      <Dropdown.Item value={2} label="Left" />
                      <Dropdown.Item value={3} label="Right" />
                    </Dropdown>
                  </StructuredList.Item>
                  <StructuredList.Item name="Background" required>
                    <SwatchesWrapper>
                      <Swatch label="Primary" sub={primarySwatch} value={0} />
                      <Swatch
                        label="Secondary"
                        sub={secondarySwatch}
                        value={1}
                      />
                      <Swatch label="Light" sub={lightSwatch} value={2} />
                      <Swatch label="Dark" sub={darkSwatch} value={3} />
                    </SwatchesWrapper>
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
            <Card title="Call-to-Action Button">
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="Link URL">
                    <TextInput
                      value={_section?.linkUrl}
                      onChange={value =>
                        _setSection({
                          ..._section,
                          linkUrl: value,
                        })
                      }
                      characterCount={true}
                      maxLength={1024}
                      fluid
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="Link Text">
                    <TextInput
                      value={_section?.linkText}
                      onChange={value =>
                        _setSection({
                          ..._section,
                          linkText: value,
                        })
                      }
                      characterCount={true}
                      maxLength={50}
                      fluid
                    />
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
          </Left>
          <Right>
            <Card title="Content" grow>
              <Card.Body noPad>
                <RichTextEditor
                  noPad
                  initialValue={section?.content}
                  onChange={value => _setContent(value)}
                />
              </Card.Body>
            </Card>
            <Card title="Image">
              <Card.Body>
                <FilePicker
                  single={true}
                  files={_section?.files}
                  onFilesChanged={files =>
                    _setSection({
                      ..._section,
                      files: files as FileListView[],
                    })
                  }
                />
              </Card.Body>
            </Card>
          </Right>
        </SectionWrapper>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Button
            text="OK"
            onClick={handleSave}
            color={Swatches.Success}
            size={Size.Small}
          />
          <Button
            text="Cancel"
            onClick={onClose}
            color={Swatches.Low}
            size={Size.Small}
          />
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default BulletinSectionModal;
