import React from "react";
import { Card } from "ui-kit";
import ReadOnlyDataList, { DetentionDataType } from "./readOnlyDataList";
import { formatDate } from "utils/dateTime";
import { RewardView } from "areas/behaviour/types/reward/rewardResponse.types";


interface IReadOnlyRewardDetailsProps {
  reward: RewardView;
}


const ReadOnlyRewardDetails: React.FC<IReadOnlyRewardDetailsProps> = ({ reward }) => {

  const data: DetentionDataType[] = [
    {
      dataType: "Reward",
      dataValue: `${reward.rewardCodeName} - ${reward.categoryName}`,
    },
    {
      dataType: "Date",
      dataValue: formatDate(reward.incidentDate),
    },
    // {
    //   dataType: "Time",
    //   dataValue: formatTime(reward.incidentDate),
    // },
    {
      dataType: "Points",
      dataValue: reward.points,
    }
  ];

  if (reward.publicNotes) {
    data.push({
      dataType: "Public Notes",
      dataValue: reward.publicNotes,
    });
  }

  if (reward.description) {
    data.push({
      dataType: "Internal Notes",
      dataValue: reward.description,
    });
  }

  return (
    <Card className="behaviour-details" title="Reward Details">
      <Card.Body>
        <ReadOnlyDataList data={data} />
      </Card.Body>
    </Card>
  );
};


export default ReadOnlyRewardDetails;
