import { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  Subtitle,
  Spacing,
  HeadlineStatistic,
  Currency,
  neutral,
  formatScrollbars,
  Tabs,
  Breakpoints,
  Card,
  EmptyMessage,
} from "ui-kit";
import { Constants } from "configuration";
import Topup from "./topup";
import PrintCredit from "./printCredit";
import { useAction } from "hooks";
import Transactions from "./transactions";
import flyoutActions from "actions/ui/flyouts";
import smartcardActions from "areas/payments/actions/smartcardActions";
import { RootState } from "reducers/store";
import { SmartcardDetailView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { users } from "utils";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .balances {
    display: flex;
    flex-basis: 0;
    justify-content: center;
  }

  .topups {
    .topup {
      margin-right: 0;
      }

      @media screen and (min-width: ${Breakpoints.xxs}px) {
        display: grid;    gap: 0 ${Spacing.Large}px;

        grid-template-columns: 1fr 1fr;
        grid-template-areas: ". . ";
      }

      @media screen and (min-width: ${Breakpoints.xs}px) {
        display: grid;    gap: 0 ${Spacing.Large}px;

        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: ". . .";
      }
    
  }

    ${formatScrollbars(neutral[200])}
  }
`;

const TransactionsWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 720px;
`;


interface ISmartcardWrapperProps {
  smartcard: SmartcardDetailView;
  loading?: boolean;
}


const SmartcardWrapper: React.FC<ISmartcardWrapperProps> = ({ smartcard, loading }) => {

  const { user } = useSelector((state: RootState) => state.currentUser)
  const { transactions, loading: loadingTransactions, error: transactionsError, paging: transactionPaging } = useSelector((state: RootState) => state.smartcardTransactions);
  const { basket, working: basketWorking } = useSelector((state: RootState) => state.basket);

  const loadTransactions = (pageIndex: number) => {
    if (smartcard && smartcard.user && smartcard.school && smartcard.canTopup) {
      smartcardActions.getSmartcardTransactions(smartcard.user.id, pageIndex);
    }
  };

  useEffect(() => {
    loadTransactions(0);
  }, [smartcard]);

  const handlePage = () => {
    loadTransactions(transactionPaging.pageIndex + 1);
  };

  const handleItemAdded = (value: number) => {
    flyoutActions.openFlyout(Constants.FLYOUTS.BASKET);
  };

  return smartcard ? (
    <Wrapper>
      <Tabs>
        <Tabs.Pane label="Smartcard">
          {smartcard.canTopup ? (
            <>
              <HeadlineStatistic>
                <HeadlineStatistic.Item
                  icon="id-card-alt"
                  value={<Currency value={smartcard.balance} />}
                  label="Smartcard Balance"
                />
              </HeadlineStatistic>
              <Subtitle text="Topup Smartcard" />

              {basket?.school != null &&
              basket?.school?.id !== smartcard.school.id ? (
                <Card>
                  <Card.Body>
                    <p>
                      Smartcard credits for {smartcard.user.firstName} cannot be
                      added to your basket as you have items from{" "}
                      {basket.school?.name} in your basket. Unfortunately only
                      purchases for a one school at a time can be made in an
                      order.
                    </p>
                    <p>
                      To order smartcard credits, either remove the other items
                      from your basket or checkout your basket first, and then
                      place another order for the smartcard credits.
                    </p>
                  </Card.Body>
                </Card>
              ) : (
                <div className="topups">
                  {Constants.TOPUP_AMOUNTS.map((value) => (
                    <Topup
                      key={value}
                      value={value}
                      onAddToBasket={handleItemAdded}
                      recipientId={smartcard.user.id}
                      schoolId={smartcard.school.id}
                    />
                  ))}
                </div>
              )}
            </>
          ) : (
            <EmptyMessage
              icon="utensils"
              title="Smartcards Not Available"
              summary={`Smartcard topups are not available at ${smartcard.school.name}`}
              cover
            />
          )}
        </Tabs.Pane>
        <Tabs.Pane label="Print Credit" hide={users.isStaff(user)}>
          {smartcard.canPrint ? (
            <>
              <HeadlineStatistic>
                <HeadlineStatistic.Item
                  icon="print"
                  value={<Currency value={smartcard.printCreditBalance} />}
                  label="Print Credits"
                />
              </HeadlineStatistic>
              <Subtitle text="Topup Print Credits" />

              {basket?.school != null &&
              basket?.school?.id !== smartcard.school.id ? (
                <Card>
                  <Card.Body>
                    <p>
                      Print credits for {smartcard.user.firstName} cannot be
                      added to your basket as you have items from{" "}
                      {basket.school?.name} in your basket. Unfortunately only
                      purchases for a one school at a time can be made in an
                      order.
                    </p>
                    <p>
                      To order print credits, either remove the other items from
                      your basket or checkout your basket first, and then place
                      another order for the print credits.
                    </p>
                  </Card.Body>
                </Card>
              ) : (
                <div className="topups">
                  {Constants.TOPUP_AMOUNTS.map((value) => (
                    <PrintCredit
                      key={value}
                      value={value}
                      onAddToBasket={handleItemAdded}
                      recipientId={smartcard.user.id}
                      schoolId={smartcard.school.id}
                    />
                  ))}
                </div>
              )}
            </>
          ) : (
            <EmptyMessage
              icon="print"
              title="Print Credits Not Available"
              summary={`Print Credits are not available at ${smartcard.school.name}`}
              cover
            />
          )}
        </Tabs.Pane>
        <Tabs.Pane label="Transactions">
          {smartcard.canTopup ? (
            <TransactionsWrapper>
              <Transactions
                user={smartcard.user}
                onPage={handlePage}
                paging={transactionPaging}
                loading={loadingTransactions}
                transactions={transactions}
                error={transactionsError}
              />
            </TransactionsWrapper>
          ) : (
            <EmptyMessage
              icon="utensils"
              title="Transactions Not Available"
              summary={`Smartcard transactions are not available at ${smartcard.school.name}`}
              cover
            />
          )}
        </Tabs.Pane>
      </Tabs>
    </Wrapper>
  ) : null;
};
export default SmartcardWrapper;
