import filters, { AssignmentFilterType } from "areas/planner/constants/assignments/assignmentsFilters";
import { Defaults } from "configuration";
import { arrays } from "utils";
import startupActions from "../../../../actions/startup/startupActions";
import assignmentActions from "../../actions/assignments/assignmentActions";
import assignmentsActions from "../../actions/assignments/assignmentsActions";
import assignmentPublishActions from "../../actions/assignments/assignmentPublishActions";
import assignmentEditorActions from "../../actions/assignments/assignmentEditorActions";
import assignmentCommentActions from "../../actions/assignments/assignmentCommentActions";
import { PageInfo } from "types/common/paging.types";
import { IBeehiveAction } from "types/common/action.types";
import { AssignmentTinyView } from "areas/planner/types/assignments/assignmentResponse.types";
import { IBeehiveError } from "types/common/errors.types";
import { AssignmentQueryFilterType } from "areas/planner/types/assignments/assignmentShared.types";


export enum AssignmentGrouping {
  Owned = 0,
  Shared = 1,
  Department = 2,
  ManagedStaff = 3
}

export interface AssingmentGroupingFilter {
  value: AssignmentGrouping;
  dropdownValue: number;
  subjectId?: number;
  staffId?: string;
}

interface IAssignmentsState {
  assignments: AssignmentTinyView[];
  paging: PageInfo;
  loading: boolean;
  error: IBeehiveError;
  filter: AssignmentFilterType;
  grouping: AssingmentGroupingFilter;
  refresh: boolean;
  unread: number;
  width: number;
}

const INITIAL_STATE: IAssignmentsState = {
  assignments: [],
  paging: Defaults.DEFAULT_PAGING,
  loading: false,
  error: null,
  filter: filters.all[0],
  grouping: {
    value: AssignmentGrouping.Owned,
    dropdownValue: -1,
    subjectId: null,
    staffId: null
  },
  refresh: false,
  unread: 0,
  width: 350,
};

const assignmentsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAssignmentsState => {

  const { GETUSER } = startupActions.types;
  const { GETASSIGNMENTS, GETSUBJECTASSIGNMENTS, FILTERASSIGNMENTS, GROUPASSIGNMENTS, REFRESH, SET_WIDTH } =
    assignmentsActions.types;
  const { PUBLISHED, UNPUBLISHED, PUBLISH, UNPUBLISH } =
    assignmentPublishActions.types;
  const { CREATE, UPDATE, CLONE } = assignmentEditorActions.types;
  const { MARKREAD, SUBMITASSIGNMENT, READ, SUBMITTED } =
    assignmentActions.types;
  const { POSTED } = assignmentCommentActions.types;

  switch (action.type) {

    case SET_WIDTH:
      return { ...state, width: action.payload };

    case GETUSER.SUCCESS:
      return { ...state, unread: action.payload.user.unreadAssignmentsCount };

    case REFRESH:
      return { ...state, refresh: true };

    case GETSUBJECTASSIGNMENTS.START:
    case GETASSIGNMENTS.START:
      return { ...state, loading: arrays.isEmpty(state.assignments) };

    case GETSUBJECTASSIGNMENTS.SUCCESS:
    case GETASSIGNMENTS.SUCCESS:
      // If the page index is zero, then replace array. NB A re-filter will always be page zero.
      // Otherwise append.
      return {
        ...state,
        assignments:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.assignments, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
        refresh: false,
      };

    case GETSUBJECTASSIGNMENTS.FAILED:
    case GETASSIGNMENTS.FAILED:
      return {
        ...state,
        loading: false,
        refresh: false,
        error: action.payload,
      };

    case FILTERASSIGNMENTS:
      return { ...state, assignments: [], filter: action.payload };

    case GROUPASSIGNMENTS:
      return {
        ...state,
        assignments: [],
        grouping: action.payload,
        filter: {
          name: "All",
          value: AssignmentQueryFilterType.All,
          tooltip: "All assignments",
          tooltipSub: "View all assignments"
        },
      }

    case CREATE.SUCCESS:
    case UPDATE.SUCCESS:
    case CLONE.SUCCESS:
      return { ...state, refresh: true };

    case PUBLISHED:
    case UNPUBLISHED:
    case PUBLISH.SUCCESS:
    case UNPUBLISH.SUCCESS:
      return { ...state, refresh: true };

    case MARKREAD.SUCCESS:
    case READ:
      return {
        ...state,
        unread: state.unread - 1,
        assignments: state.assignments.map(x =>
          x.id === action.payload.assignmentId ? { ...x, isRead: true } : x
        ),
      };

    case SUBMITASSIGNMENT.SUCCESS:
    case SUBMITTED:
      return {
        ...state,
        unread: state.unread - 1,
        assignments: state.assignments.map(x =>
          x.id === action.payload.assignmentId
            ? { ...x, isComplete: true, isOverdue: false }
            : x
        ),
      };

    case POSTED:
      return {
        ...state,
        assignments:
          action.payload.parentId == null
            ? state.assignments.map(x =>
                x.id === action.payload.assignmentId
                  ? { 
                    ...x, 
                    //commentsCount: (x.commentsCount += 1) 
                  }
                  : x
              )
            : state.assignments,
      };

    default:
      return state;
  }
};

export default assignmentsReducer;
