import React from "react";
import { Table, Swatches } from "ui-kit";
import BehaviourRatio from "./behaviourRatio";
import BehaviourCountChip from "./behaviourCountChip";
import { getGroupName } from "./utils";
import { GenerateBehaviourReportView } from "areas/analytics/types/behaviourAnalyticsResponse.types";
import { GroupBy } from "areas/analytics/types/behaviourAnalyticsShared.types";


interface IBehaviourAndRewardReportTableProps {
  report: GenerateBehaviourReportView;
  groupType1: GroupBy;
  groupType2: GroupBy;
}


const BehaviourAndRewardReportTable: React.FC<IBehaviourAndRewardReportTableProps> = ({ report, groupType1, groupType2 }) => {

  return (
    <>
      <Table>
        <Table.Header>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell center width={1}>
            Reward Points
          </Table.HeaderCell>
          <Table.HeaderCell center width={1}>
            Behaviour Points
          </Table.HeaderCell>
          <Table.HeaderCell center width={1}>
            Behaviour Counts
          </Table.HeaderCell>
          <Table.HeaderCell center width={3}>
            Ratio
          </Table.HeaderCell>
        </Table.Header>

        <Table.Row>
          <Table.Cell>
            <b>{report.name}</b>
          </Table.Cell>
          <Table.Cell center>
            <BehaviourCountChip
              count={report.totalRewardPoints}
              color={Swatches.Success}
            />
          </Table.Cell>
          <Table.Cell center>
            <BehaviourCountChip
              count={report.totalBehaviourPoints}
              color={Swatches.Danger}
            />
          </Table.Cell>
          <Table.Cell center>
            <BehaviourCountChip
              count={report.totalBehaviourCount}
              color={Swatches.Danger}
            />
          </Table.Cell>
          <Table.Cell center>
            <BehaviourRatio rewardRatio={report.ratio} />
          </Table.Cell>
        </Table.Row>
      </Table>

      {report.behaviourGroupByView1s.map((group, index) => (
        <Table>
          {groupType2 === null ? (
            <Table.Row>
              <Table.Cell>
                <b>{getGroupName(groupType1, group.name)}</b>
              </Table.Cell>
              <Table.Cell center width={1}>
                <BehaviourCountChip
                  count={group.rewardPoints}
                  color={Swatches.Success}
                />
              </Table.Cell>
              <Table.Cell center width={1}>
                <BehaviourCountChip
                  count={group.behaviourPoints}
                  color={Swatches.Danger}
                />
              </Table.Cell>
              <Table.Cell center width={1}>
                <BehaviourCountChip
                  count={group.behaviourCount}
                  color={Swatches.Danger}
                />
              </Table.Cell>
              <Table.Cell center width={3}>
                <BehaviourRatio rewardRatio={group.ratio} />
              </Table.Cell>
            </Table.Row>
          ) : (
            <Table.Header>
              <Table.HeaderCell>
                <b>{getGroupName(groupType1, group.name)}</b>
              </Table.HeaderCell>
              <Table.HeaderCell center width={1}>
                <BehaviourCountChip
                  count={group.rewardPoints}
                  color={Swatches.Success}
                />
              </Table.HeaderCell>
              <Table.HeaderCell center width={1}>
                <BehaviourCountChip
                  count={group.behaviourPoints}
                  color={Swatches.Danger}
                />
              </Table.HeaderCell>
              <Table.Cell center width={1}>
                <BehaviourCountChip
                  count={group.behaviourCount}
                  color={Swatches.Danger}
                />
              </Table.Cell>
              <Table.HeaderCell center width={3}>
                <BehaviourRatio rewardRatio={group.ratio} />
              </Table.HeaderCell>
            </Table.Header>
          )}

          <>
          {group.behaviourGroupByView2s.map(group2 => (
            <Table.Row>
              <Table.Cell>
                <b>{getGroupName(groupType2, group2.name)}</b>
              </Table.Cell>
              <Table.Cell center>
                <BehaviourCountChip
                  count={group2.rewardPoints}
                  color={Swatches.Success}
                />
              </Table.Cell>
              <Table.Cell center>
                <BehaviourCountChip
                  count={group2.behaviourPoints}
                  color={Swatches.Danger}
                />
              </Table.Cell>
              <Table.Cell center width={1}>
                <BehaviourCountChip
                  count={group2.behaviourCount}
                  color={Swatches.Danger}
                />
              </Table.Cell>
              <Table.Cell center>
                <BehaviourRatio rewardRatio={group2.ratio} />
              </Table.Cell>
            </Table.Row>
          ))}
          </>
        </Table>
      ))}
    </>
  );
};

export default BehaviourAndRewardReportTable;
