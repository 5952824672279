import React from "react";
import { ReactComponent as FoxSvg } from "assets/primary/rockets/fox.svg";
import { IPrimaryRocketCharacterProps } from "./primaryRocket";


export const Fox: React.FC<IPrimaryRocketCharacterProps> = ({ className }) => {

  return <FoxSvg className={className} />;
};

export default Fox;
