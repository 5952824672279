import actionTypeBuilder from "actions/actionTypeBuilder";
import action from "actions/action";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { AccessArrangementListView } from "areas/send/types/passportResponse.types";
import { SaveAccessArrangementCommand } from "areas/send/types/passportRequest.types";

const builder = new actionTypeBuilder("send");

const types = {
  ACCESSARRANGEMENTS_GET: builder.build("accessarrangements_get"),
  ACCESSARRANGEMENT_GET: builder.build("accessarrangement_get"),
  ACCESSARRANGEMENT_CREATE: builder.build("accessarrangement_create"),
  ACCESSARRANGEMENT_SAVE: builder.build("accessarrangement_save"),
  ACCESSARRANGEMENT_DELETE: builder.build("accessarrangement_delete"),
};


const getAccessArrangements = (callback?: callbackType<AccessArrangementListView[]>) =>
  action<AccessArrangementListView[]>(
    () => client.get(`planner/send/accessArrangements`),
    types.ACCESSARRANGEMENTS_GET,
    callback
  );

const getAccessArrangement = (id: number, callback?: callbackType<AccessArrangementListView>) =>
  action<AccessArrangementListView>(
    () => client.get(`planner/send/accessArrangements/${id}`),
    types.ACCESSARRANGEMENT_GET,
    callback
  );

const createAccessArrangement = (data: SaveAccessArrangementCommand, callback?: callbackType<AccessArrangementListView>) =>
  action<AccessArrangementListView>(
    () => client.post(`planner/send/accessArrangements`, data),
    types.ACCESSARRANGEMENT_CREATE,
    callback
  );

const saveAccessArrangement = (data: SaveAccessArrangementCommand, callback?: callbackType<AccessArrangementListView>) =>
  action<AccessArrangementListView>(
    () => client.post(`planner/send/accessArrangements/${data.id}`, data),
    types.ACCESSARRANGEMENT_SAVE,
    callback
  );

const deleteAccessArrangement = (accessArrangementId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.delete(`planner/send/accessArrangements/${accessArrangementId}`),
    types.ACCESSARRANGEMENT_DELETE,
    callback
  );


const accessArrangementActions = {
  types,
  getAccessArrangements,
  getAccessArrangement,
  createAccessArrangement,
  saveAccessArrangement,
  deleteAccessArrangement,
};

export default accessArrangementActions;
