import React, { useState, useEffect } from "react";
import {
  Table,
  EmptyMessage,
  Message,
  Button,
  SplitButton,
  Size,
  Swatches,
  Chip,
  Sub,
  ToastService,
  Loader,
  DetailLabel,
  Card,
} from "ui-kit";
import { Avatar } from "sharedComponents/common";
import { arrays } from "utils";
import ProvisionRecordProgress from "./provisionRecordProgress";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ProvisionRecordModal from "./provisionRecordModal";
import { studentRoutes } from "areas/planner/plannerRoutes";
import provisionGroupsActions from "areas/send/actions/provisions/provisionGroupsActions";
import { ProvisionGroupDetailView, ProvisionRecordListView, ProvisionRecordMapView } from "areas/send/types/passportResponse.types";
import { RootState } from "reducers/store";
import moment from "moment";
import { ProvisionStatus } from "areas/send/types/passportShared.types";


interface IProvisionRecordTableProps {
  title?: string;
  records: ProvisionRecordMapView[];
  provisionGroup?: ProvisionGroupDetailView;
  singleStudentMode?: boolean;
  loading?: boolean;
  error?: string;
  editable?: boolean;
}


const ProvisionRecordTable: React.FC<IProvisionRecordTableProps> = ({
  title,
  records,
  provisionGroup,
  singleStudentMode,
  loading,
  error,
  editable,
}) => {

  const navigate = useNavigate();
  const { deleting, deleteError } = useSelector((state: RootState) => state.provisionRecord);
  const [_records, _setRecords] = useState<ProvisionRecordMapView[]>([]);
  const [_selectedRecord, _setSelectedRecord] = useState<ProvisionRecordMapView | null>(null);
  const [_provisionGroup, _setProvisionGroup] = useState<ProvisionGroupDetailView | null>(null);

  useEffect(() => {
    // Optional parameter depending on whether component is used as part of a provision or a provision map
    _setProvisionGroup(provisionGroup);
  }, [provisionGroup]);

  useEffect(() => {
    _setRecords(records);
  }, [records]);

  const goToStudentProfile = (record: ProvisionRecordListView) => {
    navigate(studentRoutes.getStudentPath(record.student.id));
  };

  const handleUpdateStatus = (record: ProvisionRecordListView, isCurrent: boolean) => {
    if (
      window.confirm(
        !isCurrent
          ? `This will change ${record.student.firstName} ${record.student.lastName} to being inactive in this group but will keep the progress data. Are you sure?`
          : `This will update ${record.student.firstName} ${record.student.lastName} as being actively part of this group again. Are you sure?`
      )
    ) {
        provisionGroupsActions.updateProvisionRecordStatus(
        {
          providerId: _provisionGroup.provision.provider.id,
          provisionId: _provisionGroup.provision.id,
          groupId: _provisionGroup.id,
          id: record.id,
          isCurrent: isCurrent ? true : false,
        },
        () => {
          ToastService.pop(
            "Provision Record Updated",
            null,
            "hand-holding-seedling"
          );
        }
      );
    }
  };

  const handleDeleteRecord = (record: ProvisionRecordListView) => {
    if (
      window.confirm(
        "This will delete the selected student record. This cannot be undone. Are you sure?"
      )
    ) {
      provisionGroupsActions.deleteProvisionRecord(
        _provisionGroup.provision.provider.id,
        _provisionGroup.provision.id,
        _provisionGroup.id,
        record.id,
        () => {
          ToastService.pop(
            "Provision Record Deleted",
            null,
            "hand-holding-seedling"
          );
        }
      );
    }
  };

  const handleAddRecord = () => {
    var newRecord: ProvisionRecordMapView = {
      group: null,
      id: null,
      student: null,
      progress: {
        entryData: null,
        exitData: null,
        target: null,
        strategies: null,
        notes: null,
        ratingName: null,
        rating: 0,
      },
      isCurrent: false,
      startDate: null,
      endDate: null
    };
    _setSelectedRecord(newRecord);
  };

  const handleSaved = () => {
    _setSelectedRecord(null);
  };

  const handleCancel = () => {
    _setSelectedRecord(null);
  };

  const handleEdit = (record: ProvisionRecordMapView) => {
    _setSelectedRecord(record);
  };

  return (
    <>
      <Card title={title}>
        <Card.Body noPad={!arrays.isEmpty(records)}>
          {loading ? (
            <Loader size={Size.Medium} />
          ) : (
            <>
              {error ? (
                <EmptyMessage
                  title="An error occurred"
                  icon="times-circle"
                  summary="There was a problem loading the student records"
                />
              ) : arrays.isEmpty(_records) ? (
                singleStudentMode ? (
                  <EmptyMessage
                    title="No Provisions"
                    icon="users"
                    summary="No provisions were found for this student"
                  />
                ) : (
                  <EmptyMessage
                    title="No Student Records"
                    icon="users"
                    summary="No student records were found for this group"
                  >
                    {!singleStudentMode && editable && (
                      <Button
                        text="Add a Student"
                        onClick={handleAddRecord}
                        size={Size.Small}
                        color={Swatches.Primary}
                      />
                    )}
                  </EmptyMessage>
                )
              ) : (
                <>
                  <Message text={deleteError} color={Swatches.Danger} />
                  <Table>
                    <Table.Header>
                      <Table.HeaderCell width={0.8} />
                      <Table.HeaderCell width={1.3}>{singleStudentMode ? "Provision Group" : "Student"}</Table.HeaderCell>
                      <Table.HeaderCell width={1.3}>Target</Table.HeaderCell>
                      <Table.HeaderCell width={1.3}>Entry Data</Table.HeaderCell>
                      <Table.HeaderCell width={1.8}>Exit Data</Table.HeaderCell>
                      <Table.HeaderCell width={1.1}>Progress</Table.HeaderCell>
                      <Table.HeaderCell width={1.5} hide={!editable} right></Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                      {_records?.map((record: ProvisionRecordMapView, index: number) => (
                        <Table.Row key={index}>
                          <Table.Cell width={0.8}>
                            <>
                            {record.isCurrent && record.group.provision.status !== ProvisionStatus.Archived ? (
                              <Chip
                                text="Active"
                                tooltip={`${moment(record.startDate).format("DD/MM/YY")} - ${moment(record.endDate).format("DD/MM/YY")}`}
                                fluid
                                colorSwatch={Swatches.Blue}
                              />
                            ) : (
                              <Chip
                                text="Inactive"
                                tooltip={`${moment(record.startDate).format("DD/MM/YY")} - ${moment(record.endDate).format("DD/MM/YY")}`}
                                fluid
                                colorSwatch={Swatches.Low}
                              />
                            )}
                            </>
                          </Table.Cell>
                          <Table.Cell width={2}>
                            {!singleStudentMode ? (
                              <Avatar
                                user={record.student}
                                sub={`${record.student.simsId} | ${record.student.tutorGroupName}`}
                              />
                            ) : (
                              <DetailLabel
                                bold
                                label={record.group.provision.name}
                                sub={record.group.name}
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell width={1.3}>
                            <Sub>{record.progress.target}</Sub>
                          </Table.Cell>
                          <Table.Cell width={1.3}>
                            <Sub>{record.progress.entryData}</Sub>
                          </Table.Cell>
                          <Table.Cell width={1.8}>
                            <Sub>{record.progress.exitData}</Sub>
                          </Table.Cell>
                          <Table.Cell width={1.1}>
                            <ProvisionRecordProgress
                              status={record.progress.rating}
                              fluid
                            />
                          </Table.Cell>
                          <Table.Cell width={1.5} hide={!editable} right>
                            {!editable && !singleStudentMode && (
                              <Button
                                color={Swatches.Low}
                                size={Size.Small}
                                text="Student Profile"
                                onClick={() => goToStudentProfile(record)}
                                working={deleting}
                              />
                            )}
                            {editable && (
                              <SplitButton
                                text="Edit"
                                onDefaultClick={() => handleEdit?.(record)}
                                color={Swatches.Low}
                                working={deleting}
                              >
                                <SplitButton.Option
                                  text="Edit"
                                  onClick={() => handleEdit?.(record)}
                                  show
                                />
                                <SplitButton.Option
                                  text="Student Profile"
                                  onClick={() => goToStudentProfile(record)}
                                  show={!singleStudentMode}
                                />
                                <SplitButton.Option
                                  text={
                                    record.isCurrent
                                      ? "Set Inactive"
                                      : "Set Active"
                                  }
                                  onClick={() =>
                                    handleUpdateStatus(
                                      record,
                                      !record.isCurrent
                                    )
                                  }
                                  show
                                />
                                <SplitButton.Option
                                  text="Delete"
                                  onClick={() => handleDeleteRecord(record)}
                                  color={Swatches.Danger}
                                  show
                                />
                              </SplitButton>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                    {!singleStudentMode && editable && (
                      <Table.Footer>
                        <Table.Cell right colspan={7}>
                          <Button
                            text="Add a Student"
                            onClick={handleAddRecord}
                            size={Size.Small}
                            color={Swatches.Primary}
                          />
                        </Table.Cell>
                      </Table.Footer>
                    )}
                  </Table>
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>
      <ProvisionRecordModal
        record={_selectedRecord}
        provisionGroup={_provisionGroup}
        open={_selectedRecord != null}
        onSave={handleSaved}
        onCancel={handleCancel}
      />
    </>
  );
};

export default ProvisionRecordTable;
