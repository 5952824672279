import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import FilingTable from "./filingTable";
import FilingFilter from "./filingFilter";
import filingActions from "../actions/filingActions";
import { RootState } from "reducers/store";
import { ApprovalStatus, LeaveRequestType } from "areas/humanResources/types/leaveShared.types";
import { TrayListItemType } from "../types/fileRequest.types";
import { FormCategoryListView, FormListView } from "areas/forms/types/formResponse.types";
import { FilingFilter as requestFilter } from "../types/fileRequest.types";


const Outtray = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { items, paging, loading, error, filter } = useSelector((state: RootState) => state.outTray);

  const loadTray = (pageIndex: number) => {
    var requestFilter: requestFilter = {
      schoolId: filter.schoolId,
      type: filter.type,
      status: filter.status,
      formType: filter.formType?.id,
      category: filter.category?.id,
      leaveRequestType: filter.leaveRequestType
    }
    filingActions.getOutTray(
      user.id,
      requestFilter,
      pageIndex,
      () => {}
    );
  };

  useEffect(() => {
    if (user && arrays.isEmpty(items)) {
      loadTray(0);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      loadTray(0);
    }
  }, [filter]);

  const handlePage = () => {
    loadTray(paging.pageIndex + 1);
  };

  const handleSchoolChange = (schoolId: number) => {
    filingActions.filterOutTray({ ...filter, schoolId: schoolId });
  };

  const handleStatusChange = (status: ApprovalStatus) => {
    filingActions.filterOutTray({ ...filter, status: status });
  };

  const handleTypeChange = (type: TrayListItemType) => {
    filingActions.filterOutTray({ ...filter, type: type });
  };

  const handleLeaveRequestTypeChange = (type: LeaveRequestType) => {
    filingActions.filterOutTray({ ...filter, leaveRequestType: type });
  };

  const handleFormTypeChange = (type: FormListView) => {
    filingActions.filterOutTray({ ...filter, formType: type });
  };

  const handleFormCategoryChange = (category: FormCategoryListView) => {
    filingActions.filterOutTray({
      ...filter,
      category: category,
      formType: null,
    });
  };

  return (
    <>
      <FilingFilter
        filter={filter}
        onSchoolChange={handleSchoolChange}
        onTypeChange={handleTypeChange}
        onLeaveRequestTypeChange={handleLeaveRequestTypeChange}
        onFormTypeChange={handleFormTypeChange}
        onFormCategoryChange={handleFormCategoryChange}
        onStatusChange={handleStatusChange}
      />
      <FilingTable
        caption="Outgoing Forms"
        items={items}
        paging={paging}
        loading={loading}
        error={error}
        onPage={handlePage}
      />
    </>
  );
};

export default Outtray;
