import { ProvisionCostBandListView } from "areas/send/types/passportResponse.types";
import provisionCostBandsActions from "../../actions/provisions/provisionCostBandsActions";
import { IBeehiveAction } from "types/common/action.types";

interface IProvisionCostBandsState {
  bands: ProvisionCostBandListView[];
  loading: boolean;
  saving: boolean;
  error: string | null;
}

const INITIAL_STATE: IProvisionCostBandsState = {
  bands: [],
  loading: false,
  saving: false,
  error: null,
};


const provisionCostBandsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IProvisionCostBandsState => {
  const {
    PROVISIONCOSTBANDS_GET,
    PROVISIONCOSTBANDS_CREATE,
    PROVISIONCOSTBANDS_SAVE,
    PROVISIONCOSTBANDS_DELETE,
  } = provisionCostBandsActions.types;

  switch (action.type) {
    case PROVISIONCOSTBANDS_GET.START:
      return { ...state, loading: true };
    case PROVISIONCOSTBANDS_GET.SUCCESS:
      return { ...state, bands: action.payload, loading: false };
    case PROVISIONCOSTBANDS_GET.FAILED:
      return { ...state, error: action.payload, loading: false };

    case PROVISIONCOSTBANDS_CREATE.SUCCESS:
      return {
        ...state,
        bands: [action.payload, ...state.bands],
      };

    case PROVISIONCOSTBANDS_SAVE.SUCCESS:
      return {
        ...state,
        bands: state.bands.map(a =>
          a.id === action.payload.id ? action.payload : a
        ),
      };

    case PROVISIONCOSTBANDS_DELETE.SUCCESS:
      return {
        ...state,
        bands: state.bands.filter(a => a.id !== action.payload),
      };

    default:
      return state;
  }
};

export default provisionCostBandsReducer;
