import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import leaderboardReportActions from "../actions/leaderboardReportActions";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";
import { LeaderBoardBehaviourView } from "areas/planner/types/whiteboard/whiteboardResponse.types";


interface IGroupLeaderboardState {
  leaderboardReport: LeaderBoardBehaviourView[];
  loading: boolean;
  error: IBeehiveError;
  refresh: boolean;
}

const INITIAL_STATE: IGroupLeaderboardState = {
  leaderboardReport: [],
  loading: false,
  error: null,
  refresh: false,
};


const groupLeaderboardReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IGroupLeaderboardState => {

  const { GETGROUPLEADERBOARD } = leaderboardReportActions.types;
  const { ADDREWARDS, ADDREWARDFORMULTIPLE } = rewardActions.types;
  const { ADDBEHAVIOUR, ADDBEHAVIOURFORMULTIPLE } = behaviourActions.types;

  switch (action.type) {
    case GETGROUPLEADERBOARD.START:
      return { ...state, loading: true, error: null };

    case GETGROUPLEADERBOARD.SUCCESS:
      return {
        ...state,
        leaderboardReport: action.payload,
        loading: false,
        refresh: false,
      };

    case GETGROUPLEADERBOARD.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        refresh: false,
      };

    case ADDBEHAVIOUR.SUCCESS:
    case ADDREWARDS.SUCCESS:
    case ADDREWARDFORMULTIPLE.SUCCESS:
    case ADDBEHAVIOURFORMULTIPLE.SUCCESS:
      return { ...state, refresh: true };

    default:
      return state;
  }
};

export default groupLeaderboardReducer;
