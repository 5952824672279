import React from "react";
import PropTypes from "prop-types";
import { getDateTime, formatDate, todayDateTime } from "utils/dateTime";
import { Button, Size } from "ui-kit";
import { Swatches } from "ui-kit/common/colors";
import ButtonPanel from "../../behaviourCommon/buttonPanel";
import {
  CHANGE_STATUS_KEYS,
  DETENTION_STATUS_KEYS,
} from "areas/behaviour/constants/detentions";
import { StudentFeedListView } from "types/planner/feedResponse.types";
import { IDetentionDetails } from "areas/behaviour/utils/detentions";


interface IEditBehaviourStatusProps {
  activity: StudentFeedListView;
  detentionDetails: IDetentionDetails;
  changeStatus: (futureStatus: number) => void;
  setOpenConfirmStatusCancelled: (value: boolean) => void;
  setOpenConfirmRevoke: (value: boolean) => void;
}


const EditBehaviourStatus: React.FC<IEditBehaviourStatusProps> = ({
  activity,
  detentionDetails,
  changeStatus,
  setOpenConfirmStatusCancelled,
  setOpenConfirmRevoke,
}) => {

  return (
    <ButtonPanel>
      {detentionDetails ? (
        <>
          {activity.detentionStatusId === DETENTION_STATUS_KEYS.ISSUED &&
            getDateTime(
              formatDate(detentionDetails.date),
              detentionDetails.time
            ) < todayDateTime() && (
              <>
                <Button
                  color={Swatches.Success}
                  text="Mark as Attended"
                  size={Size.Small}
                  onClick={() => changeStatus(CHANGE_STATUS_KEYS.MARKATTENDED)}
                />
                <Button
                  color={Swatches.Danger}
                  text="Mark as Missed"
                  size={Size.Small}
                  onClick={() => changeStatus(CHANGE_STATUS_KEYS.MARKMISSED)}
                />
              </>
            )}
          {activity.detentionStatusId !== DETENTION_STATUS_KEYS.CANCELLED ? (
            <>
              <Button
                color={Swatches.Danger}
                text="Cancel Sanction"
                size={Size.Small}
                onClick={() => setOpenConfirmStatusCancelled(true)}
              />
              <Button
                color={Swatches.Danger}
                text="Revoke Behaviour &amp; Cancel Sanction"
                size={Size.Small}
                onClick={() => setOpenConfirmRevoke(true)}
              />
            </>
          ) : (
            <Button
              color={Swatches.Danger}
              text="Revoke Behaviour"
              size={Size.Small}
              onClick={() => setOpenConfirmRevoke(true)}
            />
          )}
        </>
      ) : (
        <Button
          color={Swatches.Danger}
          text="Revoke Behaviour"
          size={Size.Small}
          onClick={() => setOpenConfirmRevoke(true)}
        />
      )}
    </ButtonPanel>
  );
};


export default EditBehaviourStatus;
