import React from "react";
import styled from "styled-components";
import {
  neutral,
  Spacing,
  BorderRadius,
  Icon,
  honeygold,
  textFont,
  typescale,
  Size,
  fontStyle,
  displayFont
} from "ui-kit";

const OuterWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.95);
`;

const InnerWrapper = styled.div`
  background: ${neutral[100]};
  position: fixed;
  border-radius: ${BorderRadius.Default}px;
  padding: ${Spacing.Jumbo}px ${Spacing.Large}px;
  margin: ${Spacing.Jumbo}px;
  text-align: center;
  display flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  box-sizing: border-box;
  max-width: 80%;

  i, .icon {
      color: ${honeygold};
      font-size: 64px;
      margin-bottom: ${Spacing.Jumbo}px;

  }

  h1 {
    ${fontStyle(displayFont.medium, typescale.header2, neutral[600])}
    margin-bottom: ${Spacing.Large}px;
  }

  p {
    ${fontStyle(textFont.roman, typescale.paragraph, neutral[500])}
    margin-bottom: ${Spacing.Large}px;
  }
`;

const UpgradeClientVersion = () => {
  return (
    <OuterWrapper>
      <InnerWrapper>
        <Icon value="redo" size={Size.Jumbo} />
        <h1>Please Refresh Your Browser</h1>
        <p>
          Your browser is using an older version of Beehive, so to keep
          up-to-date please refresh your browser.
        </p>
        <p>
          If you see this message after refreshing, you may need to clear your
          browser history as well.
        </p>
      </InnerWrapper>
    </OuterWrapper>
  );
};

export default UpgradeClientVersion;
