import { SyncFailureReportItems } from "areas/administration/types/syncResponses.types";
import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Right,
  ActionBar,
  Swatches,
  Size,
  Message,
} from "ui-kit";

interface IDetailedDataViewModalProps {
  Data: SyncFailureReportItems;
  open: boolean;
  onCancel?: () => void;
}

const DetailedDataViewModal: React.FC<IDetailedDataViewModalProps> = ({
  Data,
  open,
  onCancel,
}) => {
  const [_open, _setOpen] = useState<boolean>(false);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const parseDataObject = (data: any) => {
    try {
      if (typeof data === "object") {
        return JSON.stringify(data, null, 2);
      }
      if (typeof data === "string") {
        return JSON.stringify(JSON.parse(data), null, 2);
      }
    } catch (error) {
      return data;
    }
  };

  return (
    <Modal
      width="80%"
      height="80%"
      title="Data"
      open={_open}
      onClose={onCancel}
    >
      <Modal.Body>
        <Message
          text="The following data has been received from Wonde during the last sync."
          color={Swatches.Warning}
        />
        <pre>{parseDataObject(Data?.objectData)}</pre>
      </Modal.Body>

      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              onClick={onCancel}
              size={Size.Small}
              color={Swatches.Low}
              text="Close"
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default DetailedDataViewModal;
