import fileActions from "areas/filing/actions/fileActions";
import { IBeehiveAction } from "types/common/action.types";


interface IDownloadState {
  file: Blob;
  downloading: boolean;
  error: string | null;
}

const INITIAL_STATE: IDownloadState = {
  file: null,
  downloading: false,
  error: null,
};


const downloadReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IDownloadState => {

  const { DOWNLOAD_FILE } = fileActions.types;

  switch (action.type) {
    case DOWNLOAD_FILE.START:
      return { ...INITIAL_STATE, downloading: true };
    case DOWNLOAD_FILE.SUCCESS:
      return { ...INITIAL_STATE, file: action.payload };
    case DOWNLOAD_FILE.FAILED:
      return { ...INITIAL_STATE, error: action.payload, downloading: false };
    default:
      return state;
  }
};

export default downloadReducer;
