import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";

interface IUpscaleBehaviourState {
  loading: boolean;
  error: IBeehiveError;
}
const INITIAL_STATE: IUpscaleBehaviourState = {
  loading: false,
  error: null,
};

const { UPSCALEBEHAVIOUR, UPSCALEBEHAVIOURDETENTION, GETBEHAVIOUR } =
  behaviourActions.types;

const upscaleBehaviourReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUpscaleBehaviourState => {

  switch (action.type) {
    case UPSCALEBEHAVIOUR.START:
    case UPSCALEBEHAVIOURDETENTION.START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case UPSCALEBEHAVIOUR.SUCCESS:
    case UPSCALEBEHAVIOURDETENTION.SUCCESS:
      return { ...state, loading: false };

    case UPSCALEBEHAVIOUR.FAILED:
    case UPSCALEBEHAVIOURDETENTION.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GETBEHAVIOUR.START:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default upscaleBehaviourReducer;
