import accessArrangementActions from "areas/send/actions/accessArrangements/accessArrangementsActions";
import { AccessArrangementListView } from "areas/send/types/passportResponse.types";
import { IBeehiveAction } from "types/common/action.types";

interface IAccessArrangementState {
  accessArrangement: AccessArrangementListView | null;
  loading: boolean;
  saving: boolean;
  error: string | null;
  saveError: string | null;
  deleting: boolean;
  deleteError: string | null;
  newId: number | null;
}

const INITIAL_STATE: IAccessArrangementState = {
  accessArrangement: null,
  loading: false,
  saving: false,
  error: null,
  saveError: null,
  deleting: false,
  deleteError: null,
  newId: null,
};

const accessArrangementReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAccessArrangementState => {
  const {
    ACCESSARRANGEMENT_GET,
    ACCESSARRANGEMENT_CREATE,
    ACCESSARRANGEMENT_SAVE,
    ACCESSARRANGEMENT_DELETE,
  } = accessArrangementActions.types;

  switch (action.type) {
    case ACCESSARRANGEMENT_GET.START:
      return { ...state, loading: true };
    case ACCESSARRANGEMENT_GET.SUCCESS:
      return { ...state, loading: false, accessArrangement: action.payload };
    case ACCESSARRANGEMENT_GET.FAILED:
      return { ...state, error: action.payload };

    case ACCESSARRANGEMENT_CREATE.START:
      return { ...state, saving: true };
    case ACCESSARRANGEMENT_CREATE.SUCCESS:
      return { ...state, saving: false, newId: action.payload.id };
    case ACCESSARRANGEMENT_CREATE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem creating the access arrangement",
      };

    case ACCESSARRANGEMENT_SAVE.START:
      return { ...state, saveError: null, deleteError: null, saving: true };
    case ACCESSARRANGEMENT_SAVE.SUCCESS:
      return { ...state, saving: false };
    case ACCESSARRANGEMENT_SAVE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the access arrangement",
      };

    case ACCESSARRANGEMENT_DELETE.START:
      return { ...state, deleteError: null, deleting: true };
    case ACCESSARRANGEMENT_DELETE.SUCCESS:
      return { ...state, deleting: false };
    case ACCESSARRANGEMENT_DELETE.FAILED:
      return {
        ...state,
        deleting: false,
        deleteError:
          "There was a problem deleting the access arrangement. This may be because this access arrangement is in use.",
      };

    default:
      return state;
  }
};

export default accessArrangementReducer;
