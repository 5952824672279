import React from "react";
import { Route, Routes } from "react-router-dom";
import Search from "./search";
import Administration from "./administration";
import User from "./userManagement/user";
import { adminRoutes } from "../adminRoutes";
import CustomRoute from "sharedComponents/layout/routes/customRoute";
import { Constants } from "configuration";


const Index = () => {
  
  return (
    <Routes>
      <Route
        index
        element={
          <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
            <Administration />
          </CustomRoute>
        }
      />
      <Route
        path={adminRoutes.search}
        element={
          <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
            <Search />
          </CustomRoute>
        }
      />
      <Route
        path={adminRoutes.user}
        element={
          <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
            <User />
          </CustomRoute>
        }
      />
    </Routes>
  );
};

export default Index;
