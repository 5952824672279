import { BeehiveReport, IBeehiveAction } from "types/common/action.types";
import reportingActions from "../../actions/reportingActions";

interface IReportingState {
  report: BeehiveReport | null;
  working: boolean;
  error: string | null;
}

const INITIAL_STATE: IReportingState = {
  report: null,
  working: false,
  error: null,
};


const reportingReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IReportingState => {
  const {
    USERMANAGEMENT_USERSREPORT,
    USERMANAGEMENT_STAFFREPORT,
    USERMANAGEMENT_STAFFROLESREPORT,
    USERMANAGEMENT_STUDENTSWITHUNREGISTEREDPARENTREPORT,
    USERMANAGEMENT_INCOMPLETEACCEPTABLEUSEREPORT,
  } = reportingActions.types;

  switch (action.type) {
    case USERMANAGEMENT_USERSREPORT.START:
    case USERMANAGEMENT_STAFFREPORT.START:
    case USERMANAGEMENT_STAFFROLESREPORT.START:
    case USERMANAGEMENT_STUDENTSWITHUNREGISTEREDPARENTREPORT.START:
    case USERMANAGEMENT_INCOMPLETEACCEPTABLEUSEREPORT.START:
      return {
        ...INITIAL_STATE,
        working: true,
        error: null,
      };

    case USERMANAGEMENT_USERSREPORT.SUCCESS:
    case USERMANAGEMENT_STAFFREPORT.SUCCESS:
    case USERMANAGEMENT_STAFFROLESREPORT.SUCCESS:
    case USERMANAGEMENT_STUDENTSWITHUNREGISTEREDPARENTREPORT.SUCCESS:
    case USERMANAGEMENT_INCOMPLETEACCEPTABLEUSEREPORT.SUCCESS:
      return {
        ...state,
        report: action.payload,
        working: false,
        error: null,
      };

    case USERMANAGEMENT_USERSREPORT.FAILED:
    case USERMANAGEMENT_STAFFREPORT.FAILED:
    case USERMANAGEMENT_STAFFROLESREPORT.FAILED:
    case USERMANAGEMENT_STUDENTSWITHUNREGISTEREDPARENTREPORT.FAILED:
    case USERMANAGEMENT_INCOMPLETEACCEPTABLEUSEREPORT.FAILED:
      return {
        ...state,
        working: false,
        error: "There was a problem running the report.",
      };

    default:
      return state;
  }
};

export default reportingReducer;
