import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Size } from "ui-kit/common/size";
import {
  Loader,
  EmptyMessage,
  Swatches,
  neutral,
  Title,
  TitleSize,
  Button,
  Icon,
  CircleIcon,
  Message,
  Spacing,
  SplitButton,
  ToastService,
} from "ui-kit";
import flyoutActions from "actions/ui/flyouts";
import PrimaryGroupTabs from "./primaryGroupTabs";
import SecondaryGroupTabs from "./secondaryGroupTabs";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { danger } from "ui-kit/common/colors";
import { getGroupSub, isMyGroup } from "areas/planner/utils/group";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import groupActions from "../../actions/group/groupActions";
import routes from "configuration/routes";
import customGroupActions from "../../actions/group/customGroupActions";
import StudentRecordFlyout from "../studentRecord/studentInformationFlyout/studentRecordFlyout";
import CustomGroupEditorModal from "./customGroups/customGroupEditorModal";
import { groupRoutes } from "../../plannerRoutes";
import { useAppSelector } from "reducers/hooks";
import { GroupType } from "types/planner/groups.types";
import LeavingLessonFlyout from "../studentRecord/leavingLesson/LeavingLessonFlyout";


const Wrapper = styled.div`
  .title-buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-wrapper {
      display: flex;
      align-items: center;

      .title {
        margin-right: ${Spacing.Small + 4}px;
        margin-bottom: 0;
      }

      .favourite-icon:hover {
        color: ${danger};
      }
    }

    .circle-icon:hover {
      cursor: pointer;

      .icon {
        color: ${neutral[100]};
      }
    }
  }
`;

const TitleMainWrapper = styled.div`
  display: flex;
  align-items: center;

  .icon {
    margin-left: ${Spacing.Small}px;
  }
`;


const GroupTabMenuWrapper: React.FC = () => {

  const { groupId, groupType } = useParams();
  const navigate = useNavigate();

  const { user } = useAppSelector(state => state.currentUser);
  const { group, refresh, loading, getGroupError } = useAppSelector(state => state.group);
  const { classLayout, layoutDirection } = useAppSelector(state => state.classLayout);
  const { isPrivate } = useAppSelector(state => state.privacy);

  const [openCustomGroupModal, setOpenCustomGroupModal] = useState<boolean>(false);

  useEffect(() => {
    flyoutActions.closeFlyout();
    var groupTypeId;
    if (groupType) {
      groupTypeId = parseInt(groupType);
    }

    if (groupId && ((group && parseInt(groupId) != group.id) || !group)) {
      groupActions.getGroup(user.id, parseInt(groupId), groupTypeId);
    }
  }, [groupId]);

  useEffect(() => {
    if (refresh) {
      const groupTypeId = parseInt(groupType);
      groupActions.getGroup(user.id, parseInt(groupId), groupTypeId);
    }
  }, [refresh]);

  const handleFavouriteGroup = () => {
    groupActions.favouriteGroup(group.id, {
      id: group.id,
      groupTypeId: group.type,
      isFavourite: false,
      group: undefined
    });
  };

  const handleUnfavouriteGroup = () => {
    groupActions.unfavouriteGroup(group.id, {
      id: group.id,
      groupTypeId: group.type,
      isFavourite: false,
      group: undefined
    });
  };

  const handleDeleteCustomGroup = () => {
    if (
      window.confirm(
        "This will delete this custom group. This cannot be undone. Are you sure?"
      )
    ) {
      customGroupActions.deleteCustomGroup(group.id, () => {
        navigate(`${routes.home}${groupRoutes.default}`);
        ToastService.pop("Group Deleted", null, "users");
      });
    }
  };

  const hasBehavioursOrRewards = () => {
    if (group.totalRewardPoints > 0 || group.totalBehaviourPoints > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleDeactivateCustomGroup = () => {
    if (
      window.confirm(
        "Are you sure you want to make this group Inactive? An inactive group will only be visible to the group's responsible staff members and will have limited functionality."
      )
    ) {
      customGroupActions.changeCustomGroupStatus(group.id, {
        id: group.id,
        isActive: false,
      });
    }
  };

  const handleActivateCustomGroup = () => {
    if (window.confirm("Are you sure you want to make this group active?")) {
      customGroupActions.changeCustomGroupStatus(group.id, {
        id: group.id,
        isActive: true,
      });
    }
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (getGroupError) {
    return (
      <ApiExceptionMessage error={getGroupError} />
    );
  }

  return group ? (
    <>
      <Wrapper>
        {group.isActive === false && (
          <Message
            text="This is an inactive group and functionality is limited. 
          You can make the group active again by clicking on Activate Group."
            color={Swatches.Primary}
          >
            <Button
              text="Activate Group"
              color={{
                swatch: neutral[100],
                foreground: neutral[700],
              }}
              size={Size.Small}
              onClick={handleActivateCustomGroup}
            />
          </Message>
        )}

        <div className="title-buttons-wrapper">
          <div className="title-wrapper">
            <Title
              size={TitleSize.H2}
              text={
                <TitleMainWrapper>
                  {group.type === GroupType.Custom 
                    ? group.name
                    : group.friendlyName}{" "}
                  {group.isPrivate && (
                    <Icon
                      value="lock"
                      size={Size.Medium}
                      tooltip="Private"
                      tooltipSub="This group is only visible to the responsible staff members of the group."
                    />
                  )}
                  {group.isPrivate &&
                  !isMyGroup(
                    group.responsibleStaff,
                    user
                  ) ? null : group?.isFavourite ? (
                    <Icon
                      className="favourite-icon"
                      value="heart"
                      size={Size.Medium}
                      color={danger}
                      onClick={handleUnfavouriteGroup}
                      tooltip="Unfavourite"
                      tooltipSub="Unfavourite this class."
                    />
                  ) : (
                    <Icon
                      className="favourite-icon"
                      value="heart"
                      regular
                      size={Size.Medium}
                      color={neutral[600]}
                      onClick={handleFavouriteGroup}
                      tooltip="Favourite"
                      tooltipSub="Make this class a favourite."
                    />
                  )}
                </TitleMainWrapper>
              }
              sub={getGroupSub(group)}
            />
          </div>

          <div>
            {parseInt(groupType) == GroupType.Custom  && (
              <SplitButton
                size={Size.Small}
                text="Edit Group"
                onDefaultClick={() => setOpenCustomGroupModal(true)}
                color={Swatches.Default}
              >
                <SplitButton.Option
                  text="Edit group"
                  onClick={() => setOpenCustomGroupModal(true)}
                  show={
                    group?.type === GroupType.Custom  &&
                    isMyGroup(group?.responsibleStaff, user)
                  }
                />

                <SplitButton.Option
                  text="Make Group Inactive"
                  onClick={handleDeactivateCustomGroup}
                  show={
                    group?.type === GroupType.Custom  &&
                    isMyGroup(group?.responsibleStaff, user) &&
                    group.isActive
                  }
                />

                <SplitButton.Option
                  text="Make Group Active"
                  onClick={handleActivateCustomGroup}
                  show={
                    group?.type === GroupType.Custom  &&
                    isMyGroup(group?.responsibleStaff, user) &&
                    !group.isActive
                  }
                />

                <SplitButton.Option
                  text="Delete group"
                  onClick={handleDeleteCustomGroup}
                  color={Swatches.Danger}
                  show={
                    group?.type === GroupType.Custom &&
                    isMyGroup(group?.responsibleStaff, user) &&
                    !hasBehavioursOrRewards()
                  }
                />
              </SplitButton>
            )}
            {group?.school?.isPrimary && user && (
              <Link
                to={`/users/${user ? user.id : null}/groups/${
                  group ? group.id : null
                }/grouptype/${group ? group.type : null}/rooms/${
                  classLayout?.roomId
                }/roomLayouts/${classLayout?.roomLayoutId}/layouts/${
                  classLayout?.classLayoutId
                }/layoutDirection/${layoutDirection}/isPrivate/${
                  isPrivate.sensitiveInfo
                }/noProfileImage/${isPrivate.profileImage}/whiteboard`}
                target="_blank"
              >
                <CircleIcon
                  className="whiteboard-view-icon"
                  value="chalkboard"
                  size={Size.Large}
                  tooltip="Whiteboard View"
                  tooltipSub="Save the seating plan to see your latest changes"
                  hover
                />
              </Link>
            )}
          </div>
        </div>

        {group.school.isPrimary ? (
          <PrimaryGroupTabs group={group} />
        ) : (
          <SecondaryGroupTabs group={group} />
        )}
      </Wrapper>

      <StudentRecordFlyout />
      <LeavingLessonFlyout />
      {openCustomGroupModal && (
        <CustomGroupEditorModal
          open={openCustomGroupModal}
          onClose={() => setOpenCustomGroupModal(false)}
          schoolId={group?.school?.id}
          groupId={group.id}
        />
      )}
    </>
  ) : (
    <EmptyMessage icon="list" title="Please select a class group" />
  );
};

export default GroupTabMenuWrapper;
