import React, { useEffect, useCallback } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  Size,
  Spacing,
  Button,
  Swatches,
  Title,
  TitleSize,
  Loader,
} from "ui-kit";
import ClassLayout from "./classLayout";
import privacyActions from "actions/ui/privacy";
import classViewerActions from "areas/planner/actions/classViewer/classViewerActions";
import groupActions from "areas/planner/actions/group/groupActions";
import classRoomActions from "areas/planner/actions/classViewer/classRoomActions";
import startupActions from "actions/startup/startupActions";
import { ApiExceptionMessage } from "sharedComponents/common";
import { useParams } from "react-router";

const Wrapper = styled.div`
  padding: ${Spacing.Medium}px 0;
  display: flex;
  flex-direction: column;
  // height: 100vh;
  // overflow-y: scroll;

  .button {
    align-self: center;
    margin-bottom: ${Spacing.Large}px;
  }

  .class-layout-wrapper {
    width: fit-content;
    align-self: center;
  }

  @media print and (color) {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    color-adjust: exact;

    .button {
      display: none;
    }

    // @page {
    //   size: landscape;
    // }
  }
`;

const ClassLayoutPrintView = () => {
  const {
    layoutId,
    groupId,
    groupType,
    roomId,
    roomLayoutId,
    layoutDirection,
    userId,
    isPrivate: noSensitiveInfo,
    noProfileImage,
  } = useParams();

  const {
    group,
    loading: loadingGroup,
    error: getGroupError,
  } = useSelector(state => state.group);
  const {
    classLayout,
    loadingClassLayout,
    error: getLayoutError,
  } = useSelector(state => state.classLayout);
  const { roomLayout, actions } = useSelector(
    state => state.classViewerRoomLayout
  );
  const { isPrivate } = useSelector(state => state.privacy);

  useEffect(() => {
    startupActions.setUserForNewWindow();
    privacyActions.setPrivacy({
      ...isPrivate,
      sensitiveInfo: noSensitiveInfo === "true" ? true : false,
      profileImage: noProfileImage === "true" ? true : false,
    });
    classViewerActions.getClassLayout(layoutId);
    groupActions.getGroup(userId, groupId, parseInt(groupType));
  }, []);

  useEffect(() => {
    if (group) {
      classRoomActions.getRoomLayout(group.school.id, roomId, roomLayoutId);
    }
  }, [group]);

  const wrapperRef = useCallback(node => {
    if (node) {
      let bodyTag = node.parentNode.closest("body");
      if (bodyTag) {
        bodyTag.style.position = "static";
      }

      let htmlTag = node.parentNode.closest("html");
      if (htmlTag) {
        htmlTag.style.position = "static";
        htmlTag.style.overflow = "auto";
        htmlTag.style.overscrollBehaviour = "auto";
        htmlTag.style.overflowY = "auto";
      }
    }
  }, []);

  const handlePrint = () => {
    window.print();
  };

  if (loadingGroup || loadingClassLayout || actions.getRoomLayout.isLoading) {
    return <Loader size={Size.Large} cover />;
  }

  return (
    <Wrapper className="class-print-view" ref={wrapperRef}>
      <ApiExceptionMessage error={getGroupError} />
      <ApiExceptionMessage error={getLayoutError} />
      <ApiExceptionMessage error={actions.getRoomLayout.error} />

      <Button
        text="Print"
        color={Swatches.Success}
        onClick={event => handlePrint(event)}
      />

      <div className="class-layout-wrapper">
        {group && (
          <Title
            text={group.friendlyName}
            sub={group.name}
            size={TitleSize.H3}
          />
        )}
        <ClassLayout
          room={roomLayout}
          layoutDirection={layoutDirection}
          classLayout={classLayout}
          noProfileImage={isPrivate?.profileImage}
          noSensitiveInfo={isPrivate?.sensitiveInfo}
        />
      </div>
    </Wrapper>
  );
};

export default ClassLayoutPrintView;
