import action from "actions/action";
import store from "reducers/store";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { InterventionRecordView } from "areas/planner/types/interventions/interventionResponses";
import { InterventionProgressRating } from "areas/planner/types/interventions/interventionShared";
import client from "services/client";
import { BeehiveReport, IBeehiveAction, callbackType } from "types/common/action.types";
import { SaveInterventionRecordCommand } from "areas/planner/types/interventions/interventionRequests";

const builder = new actionTypeBuilder("links");

const types = {
  STUDENTINTERVENTIONS_GET: builder.build("STUDENTINTERVENTIONS_GET"),
  GROUPINTERVENTIONS_GET: builder.build("GROUPINTERVENTIONS_GET"),
  GROUPINTERVENTIONS_CREATE: builder.build("GROUPINTERVENTIONS_CREATE"),
  GROUPINTERVENTIONS_SAVE: builder.build("GROUPINTERVENTIONS_SAVE"),
  GROUPINTERVENTIONS_DELETE: builder.build("GROUPINTERVENTIONS_DELETE"),
  INTERVENTIONS_FILTERBYPROGRESS: "INTERVENTIONS_FILTERBYPROGRESS",
  GROUPINTERVENTIONS_REPORT: builder.build("GROUPINTERVENTIONS_REPORT"),
  STUDENTINTERVENTIONS_REPORT: builder.build("STUDENTINTERVENTIONS_REPORT"),
  INTERVENTIONPROGRESS_STUDENTREPORT: builder.build("INTERVENTIONPROGRESS_STUDENTREPORT"),
  INTERVENTIONPROGRESS_GROUPREPORT: builder.build("INTERVENTIONPROGRESS_GROUPREPORT")
};


const getGroupInterventions = (groupId: number, callback?: callbackType<InterventionRecordView[]>) =>
  action<InterventionRecordView[]>(
    () => client.get(`planner/customgroups/${groupId}/interventionRecords`), 
    types.GROUPINTERVENTIONS_GET, 
    callback
  );

const getStudentInterventions = (studentId: string, callback?: callbackType<InterventionRecordView[]>) =>
  action<InterventionRecordView[]>(
    () => client.get(`planner/students/${studentId}/interventionRecords`), 
    types.STUDENTINTERVENTIONS_GET, 
    callback
  );

const createInterventionRecord = (data: SaveInterventionRecordCommand, callback?: callbackType<InterventionRecordView>) =>
  action<InterventionRecordView>(
    () => client.post(`planner/students/${data.studentId}/interventionRecords`, data), 
    types.GROUPINTERVENTIONS_CREATE, 
    callback
  );

const saveInterventionRecord = (data: SaveInterventionRecordCommand, callback?: callbackType<InterventionRecordView>) =>
  action<InterventionRecordView>(
    () => client.post(`planner/students/${data.studentId}/interventionRecords/${data.id}`, data), 
    types.GROUPINTERVENTIONS_SAVE, 
    callback
  );

const deleteInterventionRecord = (studentId: string, interventionId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.delete(`planner/students/${studentId}/interventionRecords/${interventionId}`), 
    types.GROUPINTERVENTIONS_DELETE, 
    callback
  );

const filterByProgress = (progressRating: InterventionProgressRating | -1) => {
  store.dispatch<IBeehiveAction<InterventionProgressRating | -1>>({
    type: types.INTERVENTIONS_FILTERBYPROGRESS,
    payload: progressRating,
  });
};

const exportGroupInterventionReport = (groupId: number, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.post(`planner/customgroups/${groupId}/interventionReport`), 
    types.GROUPINTERVENTIONS_REPORT, 
    callback
  );

const exportStudentInterventionReport = (studentId: string, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.post(`planner/students/${studentId}/interventionReport`), 
    types.STUDENTINTERVENTIONS_REPORT, 
    callback
  );

const studentInterventionProgressReport = (studentId: string, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.post(`planner/students/${studentId}/interventionsProgressReport`), 
    types.INTERVENTIONPROGRESS_STUDENTREPORT, 
    callback
  );

const interventionGroupProgressReport = (groupId: number, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.post(`planner/customgroups/${groupId}/interventionProgressReport`), 
    types.INTERVENTIONPROGRESS_GROUPREPORT, 
    callback
  );

const interventionActions = {
  types,
  getGroupInterventions,
  getStudentInterventions,
  createInterventionRecord,
  saveInterventionRecord,
  deleteInterventionRecord,
  filterByProgress,
  exportGroupInterventionReport,
  exportStudentInterventionReport,
  studentInterventionProgressReport,
  interventionGroupProgressReport,
};

export default interventionActions;
