import { IBeehiveAction } from "types/common/action.types";
import schoolsActions from "../../actions/schools/schoolsActions";


interface ISchoolsState {
  schoolId: number;
}

const INITIAL_STATE: ISchoolsState = {
  schoolId: null,
};


const schoolsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISchoolsState => {

  const { SELECTSCHOOL } = schoolsActions.types;

  switch (action.type) {
    
    case SELECTSCHOOL: {
      return { ...state, schoolId: action.payload };
    }

    default:
      return state;
  }
};

export default schoolsReducer;
