import React from "react";
import styled from "styled-components";

export interface IScrollerProps {
  breakpoint: number;
  children: React.ReactElement | React.ReactElement[];
  width: number;
  parentRef?: any;
}

const Wrapper = styled.div<{breakpoint: number, width: number}>`
  display: flex;
  flex-wrap: nowrap;

  ${({ breakpoint, width }) =>
    breakpoint &&
    `
  @media screen and (max-width: ${breakpoint}px) {
    background: red;
    overflow-x: auto;
    // -webkit-overflow-scrolling: touch;
    // -ms-overflow-style: -ms-autohiding-scrollbar;
  }


  `}
`;

const Scroller: React.FC<IScrollerProps> = ({
  breakpoint,
  children,
  width,
  parentRef,
}) => {
  //   const size = useSize(parentRef);

  //   const size = useWindowSize();

  //   useEffect(() => {
  //     console.log(parentRef?.current?.getBoundingClientRect());
  //     _setWidth(parentRef?.current?.getBoundingClientRect());
  //   }, [size]);

  return (
    <>
      <Wrapper className="scroller" breakpoint={breakpoint} width={width}>
        {children}
      </Wrapper>
    </>
  );
};
export default Scroller;
