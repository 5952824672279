import { IBeehiveAction } from "types/common/action.types";
import profileActions from "../actions/profileActions";
import { ManagedParentDetailView, ManagedStudentDetailView, ManagedUserDetailView } from "types/users/userListViews.types";


interface IProfileState {
  profile: ManagedUserDetailView | ManagedParentDetailView | ManagedStudentDetailView | null;
  loading: boolean;
  error: string | null;
  working: boolean;
  submitting: boolean;
  settingNotificationPreferences: boolean;
  notificationPreferencesError: string | null;
}

const INITIAL_STATE: IProfileState = {
  profile: null,
  loading: false,
  error: null,
  working: false,
  submitting: false,
  settingNotificationPreferences: false,
  notificationPreferencesError: null,
};


const profileReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IProfileState => {

  const {
    PROFILE_GETPROFILE,
    PROFILE_SUBMITDATAUPDATEREQUEST,
    PROFILE_SETNOTIFICATIONPREFERENCES,
  } = profileActions.types;

  switch (action.type) {
    case PROFILE_GETPROFILE.START:
      return { ...INITIAL_STATE, loading: true };
    case PROFILE_GETPROFILE.SUCCESS:
      return { ...INITIAL_STATE, profile: action.payload };
    case PROFILE_GETPROFILE.FAILED:
      return { ...INITIAL_STATE, error: action.payload };

    case PROFILE_SUBMITDATAUPDATEREQUEST.START:
      return { ...state, submitting: true };
    case PROFILE_SUBMITDATAUPDATEREQUEST.SUCCESS:
      return { ...state, submitting: false };
    case PROFILE_SUBMITDATAUPDATEREQUEST.FAILED:
      return { ...state, error: action.payload, submitting: false };

    case PROFILE_SETNOTIFICATIONPREFERENCES.START:
      return {
        ...state,
        notificationPreferencesError: null,
        settingNotificationPreferences: true,
      };
    case PROFILE_SETNOTIFICATIONPREFERENCES.SUCCESS:
      return {
        ...state,
        notificationPreferencesError: null,
        settingNotificationPreferences: false,
      };
    case PROFILE_SETNOTIFICATIONPREFERENCES.FAILED:
      return {
        ...state,
        notificationPreferencesError: action.payload,
        settingNotificationPreferences: false,
      };
    default:
      return state;
  }
};

export default profileReducer;
