import action from "actions/action";
import { ToastService } from "ui-kit";
import actionTypeBuilder from "actions/actionTypeBuilder";
import store from "reducers/store";
import client from "services/client";
import { Constants } from "configuration";

const builder = new actionTypeBuilder("classLayout");

const types = {
  SELECTLAYOUT: "selectlayout",
  GETCLASSLAYOUT: builder.build("getclasslayout"),
  DELETECLASSLAYOUT: builder.build("deleteclasslayout"),
  CREATECLASSLAYOUT: builder.build("createclasslayout"),
  UPDATECLASSLAYOUT: builder.build("updateclasslayout"),
  OPENGENERATELAYOUT: "opengeneratelayout",
  GENERATELAYOUT: builder.build("generatelayout"),
  CHANGELAYOUTDIRECTION: "changelayoutdirection",
  FILTERSTUDENTS: builder.build("filterstudents"),
  RESETFILTERSTUDENTS: "resetfilterstudents",
  // DISPLAYSENSITIVEINFO: "displaysensitiveinfo"
  FILTERDETENTIONTODAY: "filterdetentiontoday",
};

const selectLayout = layoutId =>
  store.dispatch({
    type: types.SELECTLAYOUT,
    payload: layoutId,
  });

const getClassLayout = (layoutId, onSuccess) => { //ClassLayoutView
  return action(
    () => client.get(`planner/classLayouts/${layoutId}`),
    types.GETCLASSLAYOUT,
    onSuccess
  );
};

const createClassLayout = (layout, onSuccess) => {
  return action(
    () => client.post(`planner/classLayouts`, layout),
    types.CREATECLASSLAYOUT,
    () => {
      ToastService.pop("Layout successfully created", null, "check-circle");
      if (onSuccess) {
        onSuccess();
      }
    }
  );
};

const updateClassLayout = (layoutId, layout, onSuccess) => {
  return action(
    () => client.post(`planner/classLayouts/${layoutId}`, layout),
    types.UPDATECLASSLAYOUT,
    () => {
      ToastService.pop("Layout successfully saved", null, "check-circle");
      if (onSuccess) {
        onSuccess();
      }
    }
  );
};

const deleteClassLayout = (layoutId, onSuccess) => {
  return action(
    () => client.delete(`planner/classLayouts/${layoutId}`),
    types.DELETECLASSLAYOUT,
    () => {
      ToastService.pop("Layout successfully deleted", null, "check-circle");
      if (onSuccess) {
        onSuccess();
      }
    }
  );
};

const openLayoutGenerator = open =>
  store.dispatch({
    type: types.OPENGENERATELAYOUT,
    payload: open,
  });

const generateClassLayout = (
  layoutId,
  layoutType,
  order,
  disperse,
  onSuccess
) => {
  return action(
    () =>
      client.get(
        `planner/classLayouts/${layoutId}/generatelayout/?layouttype=${layoutType}&order=${order}&disperse=${disperse}`
      ),
    types.GENERATELAYOUT,
    onSuccess
  );
};

const changeLayoutDirection = direction =>
  store.dispatch({
    type: types.CHANGELAYOUTDIRECTION,
    payload: direction,
  });

const filterStudents = (
  groupId,
  groupTypeId,
  pp,
  eal,
  sen,
  fsm,
  abilityStatusId,
  onSuccess
) => {
  const groupType = () => {
    switch (groupTypeId) {
      case Constants.GROUP_TYPES.CLASS:
        return "classGroups";

      case Constants.GROUP_TYPES.CUSTOM:
        return "customGroups";

      case Constants.GROUP_TYPES.TUTOR:
        return "tutorGroups";

      case Constants.GROUP_TYPES.PROVISION:
        return "provisionGroups";

      default:
        return "classGroups";
    }
  };

  return action(
    () =>
      client.get(
        `planner/${groupType()}/${groupId}/studentFilter/?PP=${pp}&EAL=${eal}&AbilityStatusId=${abilityStatusId}&SEN=${sen}&FSM=${fsm}`
      ),
    types.FILTERSTUDENTS,
    onSuccess
  );
};

const resetFilterStudents = () =>
  store.dispatch({
    type: types.RESETFILTERSTUDENTS,
  });

const displayDetentionToday = displayDetentionTodayStudents =>
  store.dispatch({
    type: types.FILTERDETENTIONTODAY,
    payload: displayDetentionTodayStudents,
  });

// export const displaySensitiveInfo = displayInfo => ({
//   type: classViewerActionTypes.DISPLAYSENSITIVEINFO,
//   payload: displayInfo
// });

const classViewerActions = {
  types,
  selectLayout,
  getClassLayout,
  createClassLayout,
  updateClassLayout,
  deleteClassLayout,
  openLayoutGenerator,
  generateClassLayout,
  changeLayoutDirection,
  filterStudents,
  resetFilterStudents,
  displayDetentionToday,
};

export default classViewerActions;
