import React, { useState, useEffect } from "react";
import { Dropdown } from "ui-kit";


interface IFilterOption {
  key: string;
  value: number;
  name: string;
}

const FILTER_OPTIONS: IFilterOption[] = [
  {
    key: "ALL",
    value: -1,
    name: "All Products"
  },
  {
    key: "ACTIVE",
    value: 1,
    name: "Active Products"
  },
  {
    key: "INACTIVE",
    value: 0,
    name: "Inactive Products"
  }
];

interface productOption {
  key: string;
  label: string;
  value: number;
}

interface IProductActiveDropdownProps {
  onChange?: (value: number) => void;
  initialValue?: number;
}


const ProductActiveDropdown: React.FC<IProductActiveDropdownProps> = ({ onChange, initialValue }) => {
  const [selectedValue, setSelectedValue] = useState<number>(-1);
  const [options, setOptions] = useState<productOption[]>(() => [
    ...FILTER_OPTIONS.map(opt => ({
      key: opt.key,
      label: opt.name,
      value: opt.value
    }))
  ]);

  useEffect(() => {
    setSelectedValue(options[0].value);
  }, options);

  const handleChange = (value: number) => {
    if (value != selectedValue) {
      setSelectedValue(value);

      if (onChange) {
        onChange?.(value);
      }
    }
  };

  useEffect(() => {
    const option = options.find(x => x.value === initialValue);
    setSelectedValue(option ? option.value : -1);
  }, [initialValue]);

  return (
    <Dropdown
      placeholder="Active"
      items={options}
      onChange={handleChange}
      value={selectedValue}
    />
  );
};
export default ProductActiveDropdown;
