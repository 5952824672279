import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { ObservationDetailView, ObservationListView } from "../types/managedStaffResponse.types";
import { callbackType } from "types/common/action.types";

const prefix = "observation";
const builder = new actionTypeBuilder(prefix);

const types = {
  GETOBSERVATIONS: builder.build("getobservations"),
  GETOBSERVATION: builder.build("getobservation"),
};

const getObservations = (staffId: string, callback?: callbackType<ObservationListView[]>) =>
  action<ObservationListView[]>(
    () => client.get(`pm/staff/${staffId}/observations`),
    types.GETOBSERVATIONS,
    callback
  );

const getObservation = (staffId: string, observationId: number, callback?: callbackType<ObservationDetailView>) =>
  action<ObservationDetailView>(
    () => client.get(`pm/staff/${staffId}/observations/${observationId}`),
    types.GETOBSERVATION,
    callback
  );


const observationActions = {
  types,
  getObservations,
  getObservation,
};

export default observationActions;
