import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { Card, EmptyMessage, StructuredList } from "ui-kit";
import Table, { TableHeaderCell } from "ui-kit/composite/table";
import { formatDate } from "utils/dateTime";

interface IUserSyncViewProps {}

const UserSyncView: React.FC<IUserSyncViewProps> = () => {
  const { user } = useSelector((state: RootState) => state.managedUser);

  const [warnings, setWarnings] = useState<string[]>([]);

  const parseWarnings = (data: string) => {
    if (typeof data !== "string" || data.trim() === "") {
      console.error("Invalid input: Data should be a non-empty string.");
      return [];
    }
    try {
      const warningsArray = data.split("~").map((warning) => warning.trim());
      const warnings = warningsArray.filter((warning) => warning.length > 0);
      return warnings;
    } catch (error) {
      console.error("An error occurred while parsing warnings:", error);
      return [];
    }
  };

  useEffect(() => {
    if ((user.notes && user.notes !== "") || user.syncStatusId === 1) {
      const parsedWarnings = parseWarnings(user.notes);
      setWarnings(parsedWarnings);
    } else {
      setWarnings([]);
    }
  }, [user.notes, user.syncStatusId]);

  return (
    <>
      <Card title="Sync Details">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Total Warnings">
              {warnings.length > 0 ? warnings.length : "0"}
            </StructuredList.Item>
            <StructuredList.Item name="Created On">
              {user.createdDate
                ? formatDate(user.createdDate)
                : "Not Available"}
            </StructuredList.Item>
            <StructuredList.Item name="Last Synced">
              {user.syncedOn ? formatDate(user.syncedOn) : "Not Available"}
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      {warnings.length > 0 && (
        <Table>
          <TableHeaderCell>Warnings</TableHeaderCell>
          <Table.Body>
            {warnings.map((warning: string, index: number) => (
              <Table.Row key={index}>
                <Table.Cell>{warning}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
};

export default UserSyncView;
