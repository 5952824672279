import React from "react";
import { Chip } from "ui-kit";
import { ColorSwatch } from "ui-kit/common/colors";


interface ICategoryChipProps {
  className: string;
  categoryCode: string;
  categoryName: string;
  colorSwatch: ColorSwatch;
}


const CategoryChip: React.FC<ICategoryChipProps> = ({
  className,
  categoryCode,
  categoryName,
  colorSwatch
}) => {

  return (
    <Chip
      className={`category-chip ${className ? className : ""}`}
      text={`${categoryCode} - ${categoryName}`}
      colorSwatch={colorSwatch}
    />
  );
};


export default CategoryChip;
