interface INameValuePair {
  name: string;
  value: any;
}
interface IQueryStringReturnMethods {
  addParam: (name: string, value: any) => void;
  addParams: (obj: object) => void;
  addPaging: (pageIndex: number) => void;
  toUrl: () => string;
}

/**
 * Class to create a formatted query string
 * @param {string} baseUrl Base Url constructor
 * @returns {string} Formatted Url with querystring parameters appended
 */
class QueryString {
  baseUrl: string = "";
  params: INameValuePair[] = [];

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  addParam = (name: string, value: any): void => {
    if (value === null || value === -1 || value === undefined || value === "") {
      return;
    }

    if (Object.prototype.toString.call(value) === "[object Date]") {
      this.params.push({
        name,
        value: `${value.getFullYear()}-${
          value.getMonth() + 1
        }-${value.getDate()}`,
      });
    } else {
      this.params.push({ name, value });
    }
  };

  addParams = (obj: object): void => {
    if (obj) {
      var entries = Object.entries(obj);

      for (let i = 0; i < entries.length; i++) {
        this.addParam(entries[i][0], entries[i][1]);
      }
    }
  };

  addPaging = (pageIndex: number): void => {
    this.addParam("pageIndex", pageIndex);
  };

  toUrl = (): string => {
    if (!this.params.length) {
      return this.baseUrl;
    }

    var mappedParams = this.params.map(x => `${x.name}=${x.value}`);
    var qs = mappedParams.join("&");

    return `${this.baseUrl}?${qs}`;
  };
}

/**
 * Function to create a formatted query string
 * @param {string} baseUrl Base Url constructor
 * @returns {string} Formatted Url with querystring parameters appended
 */
const CreateQueryString = (baseUrl: string): IQueryStringReturnMethods => {
  var params: INameValuePair[] = [];

  const returnMethods: IQueryStringReturnMethods = {
    addParam: (name: string, value: any): void => {
      if (value === null || value === -1) {
        return;
      }
      if (Object.prototype.toString.call(value) === "[object Date]") {
        var date = value as Date;
        params.push({
          name,
          value: `${date.getFullYear()}-${
            date.getMonth() + 1
          }-${date.getDate()}`,
        });
      } else {
        params.push({ name, value });
      }
    },

    addParams: (obj: object): void => {
      if (obj) {
        var entries = Object.entries(obj);
        for (let i = 0; i < entries.length; i++) {
          returnMethods.addParam(entries[i][0], entries[i][1]);
        }
      }
    },

    addPaging: (pageIndex: number): void => {
      returnMethods.addParam("pageIndex", pageIndex);
    },

    toUrl: (): string => {
      if (!params.length) {
        return baseUrl;
      }
      var mappedParams = params.map(param => `${param.name}=${param.value}`);
      var qs = mappedParams.join("&");
      return `${baseUrl}?${qs}`;
    },
  };

  return returnMethods;
};

const urlHelpers = {
  QueryString,
  CreateQueryString,
};

export default urlHelpers;
