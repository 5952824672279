import { ApprovalStatus } from "areas/humanResources/types/leaveShared.types";
import React, { useState, useEffect } from "react";
import Dropdown, { IDropdownDataItemProps } from "ui-kit/forms/dropdown";



interface IApprovalStatusDropdownProps {
  value?: ApprovalStatus;
  onChange: (value: ApprovalStatus, label: string) => void;
  includeAll?: boolean;
  fluid?: boolean;
}


const ApprovalStatusDropdown: React.FC<IApprovalStatusDropdownProps> = ({ value, onChange, includeAll, fluid }) => {

  const [approvalStatuses] = useState<IDropdownDataItemProps<string, ApprovalStatus, null>[]>(() => {
    var opts = (Object.keys(ApprovalStatus) as Array<keyof typeof ApprovalStatus>)
      .filter(status => isNaN(Number(status)))
      .map(status => ({
        label: status,
        value: ApprovalStatus[status]
      }))
    return includeAll ? [ { label: "All", value: null }, ...opts ] : opts;
  });

  return (
    <>
      <Dropdown
        value={value}
        onChange={onChange}
        items={approvalStatuses}
        fluid={fluid}
      />
    </>
  );
}


export default ApprovalStatusDropdown;