import React, { useState } from "react";
import styled from "styled-components";
import Icon from "../basic/icon";
import { ColorSwatch, honeygold, neutral } from "../common/colors";
import { Size } from "../common/size";
import {
  BorderRadius,
  typescale,
  Spacing,
  fontStyle,
  textFont,
} from "../index";

const Wrapper = styled.div`
  button {
    background-color: unset;
    border: none;
    padding: 0 ${Spacing.Default}px;

    &:hover {
      .icon {
        color: ${honeygold};
      }
      cursor: pointer;
    }
  }
`;

const Items = styled.div<{ expanded: boolean; right?: boolean }>`
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);

  ${({ expanded }) =>
    expanded
      ? `
  display: flex; 
  position: absolute;
  width: 100px; 
  z-index: 9999; 
  background: white;
  right: 0;
  border-bottom-left-radius:${BorderRadius.Default}px;
  border-bottom-right-radius:${BorderRadius.Default}px;
  
  button {
    padding: ${Spacing.Default}px;
    border-bottom: 1px solid ${neutral[200]};
    color: ${neutral[600]};
    ${fontStyle(textFont.medium, typescale.paragraph)}

    &:disabled {
      cursor: not-allowed;
      color: ${neutral[400]};
    }

    &:last-child{
      border-bottom: 0;
    }

    &:hover {
      background: ${neutral[200]};
      cursor: pointer;
    }
  }
  
  `
      : `display: none;`}

  ${({ right }) =>
    right &&
    `
  
  left: 0;
  `}

  flex-direction: column;
  button {
    display: block;
  }
`;

export interface IContextMenuProps {
  icon?: string;
  children?:
    | React.ReactElement<IContextMenuItemProps>[]
    | React.ReactElement<IContextMenuItemProps>;
  className?: string;
  right?: boolean;
}

export interface IContextMenuItemProps {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  show?: boolean;
  color?: ColorSwatch;
}

const ContextMenu: React.FC<IContextMenuProps> & {
  Item: typeof ContextMenuItem;
} = ({ icon = "ellipsis-v", children, className, right }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = (event: React.MouseEvent) => {
    setExpanded(!expanded);
    event.stopPropagation();
  };

  return (
    <Wrapper className={`context-menu ${className ? className : ""}`}>
      {icon && (
        <button className="context-menu-button" onClick={handleExpand}>
          <Icon
            value={icon}
            color={expanded ? neutral[500] : neutral[600]}
            size={Size.Medium}
            regular
          />
        </button>
      )}

      <Items className="context-menu-items" expanded={expanded} right={right}>
        {Array.isArray(children)
          ? children
              ?.filter(x => !x?.props.show || x?.props.show === true)
              .map(
                (child, index) =>
                  child &&
                  React.cloneElement(
                    child as React.ReactElement<IContextMenuItemProps>,
                    {
                      key: index,
                      onClick: () => {
                        child.props.onClick?.();
                        setExpanded(false);
                      },
                    }
                  )
              )
          : children &&
            React.cloneElement(
              children as React.ReactElement<IContextMenuItemProps>,
              {
                onClick: () => {
                  children.props.onClick?.();
                  setExpanded(false);
                },
              }
            )}
      </Items>
    </Wrapper>
  );
};

const ContextMenuItem: React.FC<IContextMenuItemProps> = ({
  text,
  onClick,
  disabled,
  color,
}) => {
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClick?.();
  };

  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      style={{ color: color?.swatch }}
    >
      {text}
    </button>
  );
};

ContextMenu.Item = ContextMenuItem;

export default ContextMenu;
