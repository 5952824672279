import { Spacing, neutral } from "../index";

export const formatScrollbars = (background?: string) => {
  return `
  
  overscroll-behavior: none;

  ::-webkit-scrollbar {
    width: ${Spacing.Large}px;
  }

  ::-webkit-scrollbar-track {
    background: ${background ? background : neutral[200]};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${neutral[500]};
    border: 5px solid ${background ? background : neutral[200]};
    min-height: 50px;
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: ${neutral[400]};
  }

  ::-webkit-scrollbar-corner {
    background: ${background};
  }
  
  `;
};
