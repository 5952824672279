import React from "react";
import styled from "styled-components";

export interface IFooterProps {
  children: React.ReactElement | React.ReactElement[];
}

const Wrapper = styled.div``;

const Footer: React.FC<IFooterProps> = ({ children }) => {
  return <Wrapper className="footer">{children}</Wrapper>;
};

export default Footer;
