import { ProviderContactDetailView } from "areas/send/types/passportResponse.types";
import providerActions from "../../actions/providers/providerActions";
import { IBeehiveAction } from "types/common/action.types";

interface IProviderContactState {
  providerContact: ProviderContactDetailView | null;
  loading: boolean;
  error: string | null;
  saving: boolean;
  saveError: string | null;
  deleting: boolean;
  deleteError: string | null;
  newId: number | null;
}

const INITIAL_STATE: IProviderContactState = {
  providerContact: null,
  loading: false,
  error: null,
  saving: false,
  saveError: null,
  deleting: false,
  deleteError: null,
  newId: null
};


const providerContactReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IProviderContactState => {
  
  const {
    PROVIDERCONTACT_GET,
    PROVIDERCONTACT_CREATE,
    PROVIDERCONTACT_SAVE,
    PROVIDERCONTACT_DELETE,
  } = providerActions.types;

  switch (action.type) {
    case PROVIDERCONTACT_GET.START:
      return { ...state, loading: true };
    case PROVIDERCONTACT_GET.SUCCESS:
      return { ...state, loading: false, providerContact: action.payload };
    case PROVIDERCONTACT_GET.FAILED:
      return { ...state, loading: false, error: action.payload };

    case PROVIDERCONTACT_CREATE.START:
      return { ...state, saving: true };
    case PROVIDERCONTACT_CREATE.SUCCESS:
      return { ...state, saving: false, newId: action.payload.id };
    case PROVIDERCONTACT_CREATE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem creating the contact.",
      };

    case PROVIDERCONTACT_SAVE.START:
      return { ...state, saveError: null, deleteError: null, saving: true };
    case PROVIDERCONTACT_SAVE.SUCCESS:
      return { ...state, saving: false };
    case PROVIDERCONTACT_SAVE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the contact.",
      };

    case PROVIDERCONTACT_DELETE.START:
      return { ...state, deleteError: null, saving: true };
    case PROVIDERCONTACT_DELETE.SUCCESS:
      return { ...state, deleting: false };
    case PROVIDERCONTACT_DELETE.FAILED:
      return {
        ...state,
        deleting: false,
        deleteError:
          "There was a problem deleting the contact. This may be because thhe contact is in use.",
      };

    default:
      return state;
  }
};

export default providerContactReducer;
