import client from "../client";
import configuration from "configuration";
import tokenStorage, { decodedToken } from "./tokenStorage";
import Axios, { AxiosRequestConfig } from "axios";

const AUTH_GRANT_TYPE = "password";
const REFRESH_GRANT_TYPE = "refresh_token";
const AUTH_CONTENT_TYPE = "application/x-www-form-urlencoded";
const TIMEOUT_ERROR_MESSAGE = "The login request timed out. Please try again later.";
const SERVER_ERROR_MESSAGE = "There was a problem logging in. Please try again later.";
const AUTHENTICATION_ERROR_MESSAGE = "Please check your username and password.";
const UNHANDLED_ERROR_MESSAGE = "An error occured while logging in. Please try again later.";

const API_BASEURI = configuration.baseApiUrl(false);

/**
 * Service function to call the authentication endpoint and
 * set refresh tokens for the user with supplied username and password.
 * @param {string} username The username of the user
 * @param {string} password The password of the user
 * @returns {Promise} The response contains the ID of the logged in user
 */
const login = (username: string, password: string) : Promise<string> => {

  if (!username) {
    throw new Error("A username must be supplied.");
  }
  if (!password) {
    throw new Error("A password must be supplied.");
  }

  const config : AxiosRequestConfig = {
    headers: {
      "Content-Type": AUTH_CONTENT_TYPE
    },
    timeout: Number(configuration.apiTimeout),
    timeoutErrorMessage: TIMEOUT_ERROR_MESSAGE
  };

  const encodedPassword = encodeURIComponent(password);
  const body = encodeURI(
    `grant_type=${AUTH_GRANT_TYPE}&username=${username}&password=${encodedPassword}&client_id=${configuration.clientId}`
  );

  return new Promise((resolve, reject) => {
    if (!username) {
      reject("A username must be supplied.");
    }
    if (!password) {
      reject("A password must be supplied.");
    }

    Axios.post(`${API_BASEURI}${configuration.tokens.endpoint}`, body, config)
      .then(response => {
        tokenStorage.setTokens(
          response.data.access_token,
          response.data.refresh_token
        );
        const { id } : decodedToken = tokenStorage.getDecodedAccessToken()!;

        resolve(id);
      })
      .catch(error => {
        if (error.code === 408 || error.code === "ECONNABORTED") {
          reject(TIMEOUT_ERROR_MESSAGE);
        }

        if (error.response) {
          switch (error.response.status) {
            case 408:
            case "ECONNABORTED":
              reject(TIMEOUT_ERROR_MESSAGE);
              break;
            case 400:
            case 401:
            case 402:
              reject(AUTHENTICATION_ERROR_MESSAGE);
              break;
            case 500:
              reject(SERVER_ERROR_MESSAGE);
              break;
            default:
              reject(error.message);
              break;
          }
        }
        reject(UNHANDLED_ERROR_MESSAGE);
      });
  });
};


/**
 * Function to refresh the access token from the API using the refresh token
 * and add the new authentication tokens to local storage.
 * @returns {Promise}
 */
const refreshAccessToken = () : Promise<{ access_token: string, refresh_token: string }> => {
  return new Promise((resolve, reject) => {
    const refreshToken = tokenStorage.getRefreshToken();
    if (!refreshToken) {
      reject("A refresh token was not found.");
    }

    client
      .post(`${API_BASEURI}${configuration.tokens.endpoint}`, {
        refreshToken: refreshToken,
        grantType: REFRESH_GRANT_TYPE,
        clientId: configuration.clientId
      })
      .then(response => {
        const { access_token, refresh_token } = response.data;
        tokenStorage.setTokens(access_token, refresh_token);
        resolve({ access_token, refresh_token });
      })
      .catch(error => {
        reject(error.message);
      });
  });
};

// /**
//  * Gets the authentication user object from the decoded access token
//  * and checks whether the token has expired. If expired it will reauthenticate.
//  * @returns {bool} True if authenticated, false if expired or no authenticated user.
//  */
// const checkAuthenticatedUser = () => {
//   const decodedToken = tokenStorage.getDecodedAccessToken();
//   if (decodedToken) {
//     const expiryDate = new Date(decodedToken.exp * 1000);
//     const now = new Date();

//     if (expiryDate <= now) {
//       refreshAccessToken()
//         .then(() => {
//           return true;
//         })
//         .catch(() => {
//           return false;
//         });
//     }
//     return true;
//   }

//   return false;
// };


/**
 * Service function to get the authenticated user object from the access token.
 * @returns {object} The authenticated user object or null if not authenticated
 */
const getAuthenticatedUser = () : decodedToken | null => {
  return tokenStorage.getDecodedAccessToken();
};


const methods = { 
  login, 
  refreshAccessToken, 
  getAuthenticatedUser 
};

export default methods;
