import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  EmptyMessage,
  Loader,
  Size,
  Swatches,
  Title,
  TitleSize,
  Tabs,
  Card,
  StructuredList,
  HtmlText,
} from "ui-kit";
import { arrays } from "utils";
import ConsentFormStatus from "../consentFormStatus";
import Moment from "react-moment";
import ConsentResponseList from "./consentResponseList";
import ConsentReports from "./consentReports";
import consentActions from "areas/administration/actions/consentActions";
import Subheader from "sharedComponents/layout/header/subheader";
import Avatar from "sharedComponents/common/users/avatar";
import { RootState } from "reducers/store";

const ConsentFormAdminView = () => {
  const { formId } = useParams();
  const { loading, error, form } = useSelector(
    (state: RootState) => state.adminConsentForm
  );

  useEffect(() => {
    consentActions.getConsentFormForAdmin(parseInt(formId));
  }, []);

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (!form) {
    return (
      <EmptyMessage
        title="Form Not Found"
        summary="The requested consent form was not found"
        icon="clipboard-check"
        cover
      />
    );
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem while loading the consent form"
        icon="times-circle"
        iconColor={Swatches.Danger.swatch}
        cover
      />
    );
  }

  return (
    <>
      <Subheader>
        <Title size={TitleSize.H2} text="Consent Forms" sub={form.name} />
      </Subheader>
      <Tabs>
        <Tabs.Pane name="Details" label="Details">
          <>
            <Card title="Details">
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="Name">
                    <HtmlText html={form.name} />
                  </StructuredList.Item>
                  <StructuredList.Item name="Created By">
                    <Avatar user={form.createdBy} />
                  </StructuredList.Item>
                  <StructuredList.Item name="Status">
                    <ConsentFormStatus status={form.statusId} />
                  </StructuredList.Item>
                  <StructuredList.Item
                    name="Start Date"
                    description="The date the form should is available from."
                  >
                    {form.startDate ? <Moment date={form.startDate} /> : "N/A"}
                  </StructuredList.Item>
                  <StructuredList.Item
                    name="End Date"
                    description="The deadline by which the form should be completed by."
                  >
                    {form.endDate ? <Moment date={form.endDate} /> : "N/A"}
                  </StructuredList.Item>
                  <StructuredList.Item
                    name="Expiry Date"
                    description="The date that any consent set expires by."
                  >
                    {form.expiryDate ? (
                      <Moment date={form.expiryDate} />
                    ) : (
                      "N/A"
                    )}
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
          </>
        </Tabs.Pane>
        <Tabs.Pane name="Responses" label="Responses">
          <ConsentResponseList form={form} />
        </Tabs.Pane>
        <Tabs.Pane name="Reporting" label="Reporting">
          <ConsentReports form={form} />
        </Tabs.Pane>
      </Tabs>
    </>
  );
};

export default ConsentFormAdminView;
