import { Tabs } from "ui-kit";
import Registers from "./registers";
import Reporting from "./reporting";
import Students from "./students";
import Staff from "./staff";
import CateringDetails from "./cateringDetails";


const Catering = () => {
  
  return (
    <Tabs>
      <Tabs.Pane name="registers" label="Registers">
        <Registers />
      </Tabs.Pane>
      <Tabs.Pane name="students" label="Pupils">
        <Students />
      </Tabs.Pane>
      {/* <Tabs.Pane name="staff" label="Staff">
        <Staff />
      </Tabs.Pane> */}
      <Tabs.Pane name="reporting" label="Reporting">
        <Reporting />
      </Tabs.Pane>
      <Tabs.Pane name="details" label="Details">
        <CateringDetails />
      </Tabs.Pane>
    </Tabs>
  );
};

export default Catering;
