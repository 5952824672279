import styled from "styled-components";
import { CheckPasswordResponse } from "types/users/userPassword.types";
import { Swatches, EmptyMessage, Icon, Label, Spacing } from "ui-kit";

const Wrapper = styled.div`
  .checklist {
    text-align: center;
    i {
      font-size: 18px;
      margin-right: ${Spacing.Default}px;
    }
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: ${Spacing.Medium}px;
    }
  }
`;


interface IPasswordStrengthResultProps {
  result: CheckPasswordResponse;
}

const PasswordStrengthResult: React.FC<IPasswordStrengthResultProps> = ({ result }) => {

  const renderChecklistIcon = (check: boolean) => {
    return check ? (
      <Icon color={Swatches.Success.swatch} value="check-circle" />
    ) : (
      <Icon color={Swatches.Danger.swatch} value="times-circle" />
    );
  };

  return (
    <Wrapper>
      {result.isCompromised ? (
        <EmptyMessage
          title="This password is compromised"
          summary="This password appears on the list of compromised passwords and is
                dangerous. If you are using this password you should change it immediately"
          iconColor={Swatches.Danger.swatch}
          icon="times-circle"
        />
      ) : (
        <EmptyMessage
          title="This password is not compromised"
          summary="This password does not appear on the list of compromised passwords"
          iconColor={Swatches.Success.swatch}
          icon="check-circle"
        />
      )}

      {result.strength.score <= 1 && (
        <EmptyMessage
          title="The password strength is VERY WEAK"
          summary="The strength of this password is very weak. If you are using this password you should change it immediately."
          iconColor={Swatches.Danger.swatch}
          icon="times-circle"
        />
      )}

      {result.strength.score === 2 && (
        <EmptyMessage
          title="The password strength is WEAK"
          summary="The strength of this password is weak. If you are using this password you should change it immediately."
          iconColor={Swatches.Danger.swatch}
          icon="times-circle"
        />
      )}

      {result.strength.score === 3 && (
        <EmptyMessage
          title="The password strength is MEDIUM"
          summary="The strength of this password is medium. You should consider
         using a password that is stronger."
          iconColor={Swatches.Warning.swatch}
          icon="check-circle"
        />
      )}

      {result.strength.score === 4 && (
        <EmptyMessage
          title="The password strength is STRONG"
          summary="The strength of this password is strong. It is strong enough to use but could be stronger."
          iconColor={Swatches.Success.swatch}
          icon="check-circle"
        />
      )}

      {result.strength.score === 5 && (
        <EmptyMessage
          title="The password strength is VERY STRONG"
          summary="Congratulations - the strength of this password is very strong. You
           do not need to change this password unless it is compromised."
          iconColor={Swatches.Success.swatch}
          icon="check-circle"
        />
      )}

      <ul className="checklist">
        <li>
          {renderChecklistIcon(result.strength.has8Characters)}{" "}
          <Label bold>
            {result.strength.has8Characters ? "Is" : "Is not"} over 8 characters
            long
          </Label>
        </li>
        <li>
          {renderChecklistIcon(result.strength.has12Characters)}{" "}
          <Label bold>
            {result.strength.has12Characters ? "Is" : "Is not"} over 12
            characters long
          </Label>
        </li>
        <li>
          {renderChecklistIcon(result.strength.hasUppercase)}{" "}
          <Label bold>
            {result.strength.hasUppercase ? "Has" : "Does not have"} uppercase
            letters
          </Label>
        </li>
        <li>
          {renderChecklistIcon(result.strength.hasLowercase)}{" "}
          <Label bold>
            {result.strength.hasLowercase ? "Has" : "Does not have"} lowercase
            letters
          </Label>
        </li>
        <li>
          {renderChecklistIcon(result.strength.hasDigit)}{" "}
          <Label bold>
            {result.strength.hasDigit ? "Has" : "Does not have"} numbers
          </Label>
        </li>
        <li>
          {renderChecklistIcon(result.strength.hasNonAlphanumeric)}{" "}
          <Label bold>
            {result.strength.hasNonAlphanumeric ? "Has" : "Does not have"}{" "}
            special characters
          </Label>
        </li>
      </ul>
    </Wrapper>
  );
};

export default PasswordStrengthResult;
