import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  EmptyMessage,
  Left,
  ActionBar,
  ToastService,
  Swatches,
  Size,
} from "ui-kit";
import { useSelector } from "react-redux";
import FormEditor from "./formEditor";
import adminSubmissionActions from "../actions/adminSubmissionActions";
import formActions from "../actions/formActions";
import formSubmissionActions from "../actions/formSubmissionActions";
import { RootState } from "reducers/store";
import { FormDetailView, FormListView, SubmissionDetailView, SubmitFormCommand } from "../types/formResponse.types";
import { TrayListView } from "areas/filing/types/fileResponse.types";
import { ApprovalStatus } from "areas/humanResources/types/leaveShared.types";


interface IFormEditorModalProps {
  form?: FormListView;
  submission?: TrayListView;
  open: boolean;
  onClose?: () => void;
}


const FormEditorModal: React.FC<IFormEditorModalProps> = ({
  form: _form,
  submission: _submission,
  open,
  onClose,
}) => {

  const [_open, _setOpen] = useState<boolean>(false);
  const [_emptySubmission, _setEmptySubmission] = useState<SubmissionDetailView | null>(null);

  const { submitting, error: submitError } = useSelector((state: RootState) => state.formSubmission);
  const {
    submission,
    loading: loadingSubmission,
    error: submissionError,
  } = useSelector((state: RootState) => state.adminSubmission);
  const {
    form,
    loading: loadingForm,
    error: formError,
  } = useSelector((state: RootState) => state.form);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    if (_submission) {
      adminSubmissionActions.getSubmissionForAdmin(_submission.id);
    }

    if (!_submission && _form) {
      formActions.getForm(_form.categoryId, _form.id, (data: FormDetailView) => {
        _setEmptySubmission({
          description: "",
          history: [],
          notes: [],
          files: [],
          submitterNotes: "",
          id: null,
          formId: _form.id,
          name: "",
          categoryName: "",
          categoryId: _form.categoryId,
          latestHistory: undefined,
          submittedBy: undefined,
          createdDate: new Date(),
          authorisedDate: null,
          form: data,
          assignedTo: undefined,
          authoriserId: "",
          authoriser: undefined,
          schoolId: 0,
          school: undefined,
          departmentId: 0,
          department: undefined,
          ref: undefined,
          userRef: "",
          additionalFields: [],
          currentStatus: ApprovalStatus.Draft
        });
      });
    }
  }, [_submission, _form]);

  const handleSave = (data: SubmitFormCommand) => {
    formSubmissionActions.submitForm(data, () => {
      onClose?.();
      ToastService.pop("Form submitted successfully", null, "paste");
    });
  };

  return (
    <Modal
      open={_open}
      onClose={onClose}
      width="95%"
      height="95%"
      loading={loadingForm || loadingSubmission}
      // title={(_form ? _form?.name : _submission?.formName) + " Form"}
      empty={!_submission && !_emptySubmission}
      emptyMessage={
        <EmptyMessage
          icon="paste"
          title="Form Not Found"
          summary="The requested form was not found"
          cover
        />
      }
      error={formError || submissionError}
      errorMessage={
        <EmptyMessage
          icon="times-circle"
          title="A problem occurred"
          summary="There was a problem loading the requested form"
          cover
        />
      }
    >
      <Modal.Body>
        <FormEditor
          onSave={handleSave}
          onCancel={() => onClose?.()}
          submission={submission && _submission ? submission : _emptySubmission}
          error={submitError}
          working={submitting}
        />
      </Modal.Body>
    </Modal>
  );
};

export default FormEditorModal;
