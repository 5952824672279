import React from "react";
import { Dropdown } from "../../ui-kit";
import { Constants } from "configuration";

interface IAcademicYearFilterProps {
  value: number;
  onChange: (value: number, label: string, data?: any) => void;
  fluid?: boolean;
}

const AcademicYearFilter: React.FC<IAcademicYearFilterProps> = ({
  value,
  onChange,
  fluid,
}) => {
  return (
    <Dropdown
      items={Constants.ACADEMIC_YEAR_FILTER_ARRAY}
      value={value}
      placeholder="Academic Year"
      onChange={onChange}
      fluid={fluid}
    />
  );
};

export default AcademicYearFilter;
