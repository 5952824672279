import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  Spacing,
  fontStyle,
  displayFont,
  typescale,
  neutral,
  Button,
  Size,
  Icon,
  honeygold,
} from "ui-kit";
import ClassLayoutGridCell from "./classLayoutGridCell";
import { maxNumberOfSensitiveFlagsAndReadingAge } from "areas/planner/utils/sensitiveData";
import { LAYOUT_DIRECTION } from "areas/planner/constants/classViewer/classViewerLayout";

const CellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
`;

const ClassDirectionAndZoomWrapper = styled.div`
  align-self: stretch;
  ${fontStyle(displayFont.medium, typescale.paragraph, neutral[600])}
  text-align: center;
  margin-bottom: ${Spacing.Large}px;

  .class-direction-and-zoom-wrapper {
    display: flex;

    .class-direction-wrapper,
    .zoom-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .class-direction-wrapper {
      flex-grow: 3;
      line-height: 1rem;

      .flip-class-direction-icon {
        margin-left: ${Spacing.Small + 4}px;
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;

          .icon {
            color: ${honeygold};
          }
        }
      }
    }

    .zoom-buttons {
      flex-grow: 2;
      .percentage-zoom {
        ${fontStyle(displayFont.bold, typescale.paragraph, neutral[600])}
        margin-right: ${Spacing.Medium}px
      }

      .button {
        margin-right: ${Spacing.Small + 4}px;
        padding-right: ${Spacing.Medium}px;
        padding-left: ${Spacing.Medium}px;

        .button-content .icon {
          margin: 0;
          color: ${neutral[100]};
        }
      }

      .button:last-child {
        margin-right: 0;
      }
    }
  }
`;

const ClassLayoutGrid = ({
  numberOfRows,
  numberOfCells,
  scale,
  setScale,
  handleFlipLayoutDirection,
  onDragStart,
  onDropGrid,
  studentSlots,
  removeStudent,
  selectedStudents,
  handleSelectStudent,
}) => {
  const { layoutDirection } = useSelector(state => state.classLayout);
  const [maxFlagsAndReadingAge, setMaxFlagsAndReadingAge] = useState({
    maxFlags: 0,
    isReadingAge: false,
  });

  useEffect(() => {
    setMaxFlagsAndReadingAge(
      maxNumberOfSensitiveFlagsAndReadingAge(studentSlots)
    );
  }, []);

  const zoomOut = () => {
    if (scale > 0.8) {
      const newScale = scale - 0.1;
      setScale(newScale);
    }
  };

  const zoomIn = () => {
    if (scale < 1) {
      const newScale = scale + 0.1;
      setScale(newScale);
    }
  };

  return (
    <>
      {/* className="class-direction-label" */}
      <ClassDirectionAndZoomWrapper>
        <div className="class-direction-and-zoom-wrapper">
          <div className="class-direction-wrapper">
            <span>
              {layoutDirection === LAYOUT_DIRECTION.BACKTOFRONT
                ? "BACK OF THE CLASS"
                : "FRONT OF THE CLASS"}
            </span>
            <div
              onClick={handleFlipLayoutDirection}
              className="flip-class-direction-icon"
            >
              <Icon
                value="sync"
                tooltip="Flip Seating Plan"
                tooltipSub="Flip the orientation of the seating plan"
                size={Size.Small}
              />
            </div>
          </div>

          <div className="zoom-buttons">
            <span className="percentage-zoom">{(scale * 100).toFixed(0)}%</span>
            <Button
              icon="search-plus"
              disabled={scale < 0.8 || scale >= 1}
              onClick={zoomIn}
              tooltip="Zoom In"
              size={Size.Small}
            />
            <Button
              icon="search-minus"
              disabled={scale > 1 || scale <= 0.8}
              onClick={zoomOut}
              tooltip="Zoom Out"
              size={Size.Small}
            />
          </div>
        </div>
      </ClassDirectionAndZoomWrapper>

      <div className="layout-grid" style={{ transform: `scale(${scale})` }}>
        {numberOfRows.map((row, index) => (
          <CellWrapper key={index}>
            {numberOfCells.map((col, index) => (
              <ClassLayoutGridCell
                key={index}
                row={row}
                col={col}
                onDragStart={onDragStart}
                onDropGrid={onDropGrid}
                studentSlots={studentSlots ? studentSlots : null}
                removeStudent={removeStudent}
                selectedStudents={selectedStudents}
                handleSelectStudent={handleSelectStudent}
                maxFlagsAndReadingAge={maxFlagsAndReadingAge}
              />
            ))}
          </CellWrapper>
        ))}
      </div>
      <div className="class-direction-label front">
        {layoutDirection === LAYOUT_DIRECTION.BACKTOFRONT
          ? "FRONT OF THE CLASS"
          : "BACK OF THE CLASS"}
      </div>
    </>
  );
};

ClassLayoutGrid.propTypes = {
  onDragStart: PropTypes.func,
  onDropGrid: PropTypes.func,
  studentSlots: PropTypes.array,
  removeStudent: PropTypes.func,
  showGenerateLayout: PropTypes.bool,
  handleCLoseGenerateLayout: PropTypes.func,
  generateLayout: PropTypes.func,
};

export default ClassLayoutGrid;
