import action from "actions/action";
import { Constants } from "configuration";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { StaffListView } from "types/users/userListViews.types";
import { PagedQueryView, SearchResultView } from "types/common/views.types";

const builder = new actionTypeBuilder("formstafftypes");

const types = {
  GETFINANCEUSERS: builder.build("getfinanceusers"),
};


const getFinanceUsers = (callback?: callbackType<PagedQueryView<SearchResultView<StaffListView>>>) => {
  return action<PagedQueryView<SearchResultView<StaffListView>>>(
    () => client.get(`search/users/staff?searchTerm=&roles=${Constants.ROLES.FINANCE_USER.roleTypeId}&pageSize=100`),
    types.GETFINANCEUSERS,
    callback
  );
};


const formStaffActions = {
  types,
  getFinanceUsers,
};

export default formStaffActions;
