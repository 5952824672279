import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { files, users } from "utils";
import { Constants } from "configuration";
import {
  Left,
  Right,
  Card,
  List,
  formatScrollbars,
  neutral,
  Spacing,
  Swatches,
  Message,
  ActionBar,
  Button,
  Size,
} from "ui-kit";
import { TermsDropdown, StudentPicker } from "sharedComponents/common";
import provisionReportingActions from "areas/send/actions/reporting/provisionReportingActions";
import { RootState } from "reducers/store";
import { IRole } from "configuration/constants.types";
import { TermListView } from "areas/send/types/passportResponse.types";
import AcademicYearFilter from "sharedComponents/common/academicYearFilter";


const ReportWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;

  .left {
    padding-right: ${Spacing.Default}px;
    flex-direction: column;
    width: 0px;
    flex-grow: 1;
    left: 0;
    overflow-y: auto !important;
    width: 13rem;
    max-width: 13rem;
    margin-right: ${Spacing.Default}px;
  }

  .right {
    flex-grow: 1;
  }

  .dropdown {
    margin-bottom: ${Spacing.Default}px;
  }

  ${formatScrollbars(neutral[200])}
`;


interface sendReportParameters {
  academicYearId?: number;
  studentId?: string;
}

interface sendReport {
  id: number;
  name: string;
  description: string;
  body: (reportParams: sendReportParameters) => JSX.Element;
  action: (params: any) => Promise<string>;
  roles: IRole[];
}


const SendReports = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { loading, error } = useSelector(
    (state: RootState) => state.provisionReporting
  );
  const REPORTS: sendReport[] = [
    {
      id: 0,
      name: "Get Student Costs",
      description:
        "Export the total provisions costs for each individual student",
      body: reportParams => (
        <AcademicYearFilter
          value={reportParams?.academicYearId}
          onChange={value => setReportParams({ academicYearId: value })}
        />
      ),
      action: (params: sendReportParameters) =>
        provisionReportingActions.provisionStudentsCostReport(
          params.academicYearId,
          reportData => files.openCsv(reportData)
        ),
      roles: [
        Constants.ROLES.SEN_ADMINISTRATOR,
        Constants.ROLES.SEN_COORDINATOR,
        Constants.ROLES.LEADERSHIP,
      ],
    },
    {
      id: 1,
      name: "Get Group Costs",
      description: "Export the total costs for each provision group",
      body: reportParams => (
        <AcademicYearFilter
          value={reportParams?.academicYearId}
          onChange={value => setReportParams({ academicYearId: value })}
        />
      ),
      action: (params: sendReportParameters) =>
        provisionReportingActions.provisionGroupCostReport(params?.academicYearId, reportData =>
          files.openCsv(reportData)
        ),
      roles: [
        Constants.ROLES.SEN_ADMINISTRATOR,
        Constants.ROLES.SEN_COORDINATOR,
        Constants.ROLES.LEADERSHIP,
      ],
    },
    {
      id: 2,
      name: "Get Single Student Costs",
      description: "Export the total costs for an individual student",
      body: reportParams => (
        <>
          <StudentPicker
            fluid
            value={reportParams?.studentId}
            onChange={student =>
              setReportParams({ ...reportParams, studentId: student.id })
            }
          />
          <AcademicYearFilter
            value={reportParams?.academicYearId}
            onChange={value => setReportParams({ ...reportParams, academicYearId: value })}
          />
        </>
      ),
      action: (params: sendReportParameters) =>
        provisionReportingActions.provisionStudentCostReport(
          params.studentId,
          params.academicYearId,
          reportData => files.openCsv(reportData)
        ),
      roles: [
        Constants.ROLES.SEN_ADMINISTRATOR,
        Constants.ROLES.SEN_COORDINATOR,
        Constants.ROLES.LEADERSHIP,
      ],
    },
  ];

  const [reportParams, setReportParams] = useState<sendReportParameters>({
    academicYearId: null,
    studentId: null
  });
  const [selectedReport, setSelectedReport] = useState<sendReport>(REPORTS[0]);

  const handleRunReport = () => {
    selectedReport.action(reportParams);
  };

  const handleChangeReport = (report: sendReport) => {
    setReportParams(null);
    setSelectedReport(report);
  };

  return (
    <ReportWrapper>
      <Left>
        <List title="Available Reports">
          {REPORTS.filter(x => users.isInAnyRoles(user, x.roles)).map(
            (report: sendReport, index: number) => (
              <List.Item
                text={report.name}
                key={index}
                selected={selectedReport.id === report.id}
                onClick={() => handleChangeReport(report)}
              />
            )
          )}
        </List>
      </Left>
      <Right>
        {error && <Message text={error} color={Swatches.Danger} />}
        <Card>
          <Card.Body>
            <p>{selectedReport.description}</p>
            {selectedReport.body(reportParams)}
          </Card.Body>
          <Card.Footer>
            <ActionBar low>
              <Button
                text="Run Report"
                color={Swatches.Success}
                size={Size.Small}
                onClick={handleRunReport}
                working={loading}
                disabled={reportParams == null}
              />
            </ActionBar>
          </Card.Footer>
        </Card>
      </Right>
    </ReportWrapper>
  );
};

export default SendReports;
