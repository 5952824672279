import React from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "ui-kit";
import searchEngineActions from "areas/search/actions/searchEngineActions";
import { RootState } from "reducers/store";

interface IUsersSearchProps {
  onChange?: (user: any) => void;
  fluid?: boolean;
  schools?: number[];
}

const UsersSearch: React.FC<IUsersSearchProps> = ({
  onChange,
  fluid,
  schools,
}) => {
  const { users, loading, error } = useSelector((state: RootState) => state.searchUsers);

  const handleUsersSearch = (value: any) => {
    if (value && value.length > 2) {
      searchEngineActions.searchUsers(value, { schools: schools });
    }
  };

  return (
    <>
      <Dropdown
        className="user-search"
        items={users?.map((user: any, index: number) => ({
          key: index,
          label: `${user.data.firstName} ${user.data.lastName}`,
          value: user.data,
          sub: user.description,
        }))}
        autocomplete
        placeholder="Search users..."
        searchPlaceholder="Search users..."
        onSearchChange={handleUsersSearch}
        onChange={onChange}
        loading={loading}
        error={error && "An error occurred"}
        fluid={fluid}
      />
    </>
  );
};

export default UsersSearch;
