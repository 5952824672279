// Baseline is 4px

export enum Size {
  ExtraSmall,
  Small,
  Medium,
  Large,
  ExtraLarge,
  Jumbo
}

export enum Spacing {
  ExtraSmall = 2,
  Small = 4,
  Default = 8,
  Medium = 12,
  Large = 16,
  ExtraLarge = 24,
  Jumbo = 36
}

export enum BorderRadius {
  Default = 4,
  Large = 8,
  ExtraLarge = 16
}

export enum Orientation {
  Portrait,
  Landscape
}

export enum AlignmentX {
  Left = "flex-start",
  Center = "center",
  Right = "flex-end"
}

export enum AlignmentY {
  Top = "flex-start",
  Center = "center",
  Bottom = "flex-end"
}

export enum FlyoutWidth {
  Default = 400,
  Wide = 800
}

export const MinFieldWidth = 15;
