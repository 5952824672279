import { UserTinyView } from "types/users/userListViews.types";
import { UserStartupView } from "types/users/userStartup.types";
import { users } from "utils";

/**
 * Checks if parameter is of type
 * @param {any} data Object to check
 * @returns {boolean} True if the given data is of type
 */
const isType = <Type>(data: any, discriminator: keyof Type): data is Type => {
  if (!discriminator || !data) {
    return false;
  }

  return discriminator in data;
}

/**
 * Converts UserStartupView to userTinyView
 * @param {UserStartupView} user User to convert
 * @returns {UserTinyView} Converted user
 */
const currentUserToTiny = (user: UserStartupView): UserTinyView => {
  if (!user) {
    return null;
  }

  var utv: UserTinyView = {
    id: user.id,
    title: null,
    firstName: user.details.firstName,
    initials: users.getInitials(`${user.details.firstName} ${user.details.lastName}`),
    lastName: user.details.lastName,
    admissionStatus: user.admissionStatus
  }
  
  return utv;
}


const methods = {
  isType,
  currentUserToTiny
};

export default methods;
