import action from "actions/action";
import store from "reducers/store";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import {
  PagedQueryView,
  SearchResultView,
  SearchResultViewBase,
} from "types/common/views.types";
import {
  StaffListView,
  StudentListView,
  UserListView,
} from "types/users/userListViews.types";
import { StaffType } from "types/users/userStaff.types";
import { TelephoneSearchView } from "../types/searchResponse.types";
import { GroupTypes, UserType } from "configuration/constants.enums";
import { SEARCH_GROUP_TYPE_KEYS } from "sharedComponents/common/search/search";
import { StudentQueryFilter, UserQueryFilter } from "../types/searchRequest.types";
import { objects, urls } from "utils";
import { GroupType } from "types/planner/groups.types";
import { GroupListView } from "types/users/userGroups.types";

const builder = new actionTypeBuilder("search");

const types = {
  SEARCH_DEPARTMENTS: builder.build("SEARCH_DEPARTMENTS"),
  SEARCH_STAFF: builder.build("SEARCH_STAFF"),
  SEARCH_GROUPS: builder.build("SEARCH_GROUPS"),
  SEARCH_CLASSGROUPS: builder.build("SEARCH_CLASSGROUPS"),
  SEARCH_TUTORGROUPS: builder.build("SEARCH_TUTORGROUPS"),
  SEARCH_CUSTOMGROUPS: builder.build("SEARCH_CUSTOMGROUPS"),
  SEARCH_STUDENTS: builder.build("SEARCH_STUDENTS"),
  SEARCH_USERS: builder.build("SEARCH_USERS"),
  SEARCH_PHONEBOOK: builder.build("SEARCH_PHONEBOOK"),
  SEARCH_SUBJECTS: builder.build("SEARCH_SUBJECTS"),
  SEARCH_YEARGROUP: builder.build("SEARCH_YEARGROUP"),
  CLEAR_RESULTS: "CLEAR_RESULTS",
};

const searchUsers = (
  searchTerm: string,
  filter?: UserQueryFilter,
  pageIndex?: number,
  callback?: callbackType<PagedQueryView<SearchResultView<UserListView>>>
) => {
  let qs = `searchTerm=${searchTerm ? searchTerm : ""}`;

  if (filter.schools && filter.schools.length) {
    qs = `${qs}&schools=${filter.schools.join("&schools=")}`;
  }

  if (filter.userTypes && filter.userTypes.length) {
    qs = `${qs}&userTypes=${filter.userTypes.join("&userTypes=")}`;
  }

  if (pageIndex != null) {
    qs = `${qs}&pageIndex=${pageIndex}`;
  }

  return action<PagedQueryView<SearchResultView<UserListView>>>(
    () => client.get(`search/users?${qs}&pageSize=100`),
    types.SEARCH_USERS,
    callback
  );
};

const searchDepartments = (
  searchTerm: string,
  schools?: number[],
  callback?: callbackType
) => {
  let qs = `searchTerm=${searchTerm ? searchTerm : ""}`;

  if (schools && schools.length) {
    qs = `${qs}&schools=${schools.join("&schools=")}`;
  }

  return action<PagedQueryView<SearchResultViewBase>>(
    () => client.get(`search/departments?${qs}&pageSize=100`),
    types.SEARCH_DEPARTMENTS,
    callback
  );
};

const searchStaff = (
  searchTerm: string,
  staffType?: StaffType,
  schools?: number[],
  roles?: number[],
  callback?: callbackType<PagedQueryView<SearchResultView<StaffListView>>>
) => {
  let qs = `searchTerm=${searchTerm ? searchTerm : ""}`;

  if (staffType != null) {
    qs = `${qs}&staffType=${staffType != null ? staffType : ""}`;
  }

  if (schools && schools.length) {
    qs = `${qs}&schools=${schools.join("&schools=")}`;
  }

  if (roles && roles.length) {
    qs = `${qs}&${roles.map(s => `roles=${s}`)}`;
  }

  return action<PagedQueryView<SearchResultView<StaffListView>>>(
    () => client.get(`search/users/staff?${qs}&pageSize=100`),
    types.SEARCH_STAFF,
    callback
  );
};

const searchStudents = (
  searchTerm: string,
  filter?: StudentQueryFilter,
  pageIndex?: number,
  callback?: callbackType<PagedQueryView<SearchResultView<StudentListView>>>
) => {

  var url = new urls.QueryString(`search/users/students`);
  if (filter?.schools && filter?.schools?.filter(x => x !== 99).length) {
    url.addParam("schools", filter.schools.filter(x => x !== 99).join("&schools="));
  }
  url.addParam("searchTerm", searchTerm);
  url.addParam("pageSize", 100);
  url.addParams(objects.omit(filter, "schools"));
  url.addPaging(pageIndex);

  return action<PagedQueryView<SearchResultView<StudentListView>>>(
    () => client.get(url.toUrl()),
    types.SEARCH_STUDENTS,
    callback
  );
};

const searchGroups = (
  searchTerm: string,
  groupType?: GroupType,
  academicYearId?: number,
  schools?: number[],
  callback?: callbackType<PagedQueryView<SearchResultView<GroupListView>>>
) => {
  let qs = `searchTerm=${searchTerm ? searchTerm : ""}`;

  if (groupType != null) {
    qs = `${qs}&groupType=${groupType != null ? groupType : ""}`;
  }

  if (academicYearId) {
    qs = `${qs}&academicYearId=${academicYearId != null ? academicYearId : ""}`;
  }

  if (schools && schools.length) {
    qs = `${qs}&schools=${schools.join("&schools=")}`;
  }

  return action<PagedQueryView<SearchResultView<GroupListView>>>(
    () => client.get(`search/groups?${qs}&pageSize=100`),
    types.SEARCH_GROUPS,
    callback
  );
};

const searchTutorGroups = (
  searchTerm: string,
  academicYearId?: number,
  schools?: number[],
  callback?: callbackType<PagedQueryView<SearchResultViewBase>>
) => {
  let qs = `searchTerm=${searchTerm ? searchTerm : ""}`;

  if (academicYearId) {
    qs = `${qs}&academicYearId=${academicYearId != null ? academicYearId : ""}`;
  }

  if (schools && schools.length) {
    qs = `${qs}&schools=${schools.join("&schools=")}`;
  }

  return action<PagedQueryView<SearchResultViewBase>>(
    () => client.get(`search/groups/tutorgroups?${qs}&pageSize=100`),
    types.SEARCH_TUTORGROUPS,
    callback
  );
};

const searchClassGroups = (
  searchTerm: string,
  subjectId?: number,
  yearGroupId?: number,
  academicYearId?: number,
  schools?: number[],
  pageIndex?: number,
  callback?: callbackType<PagedQueryView<SearchResultViewBase>>
) => {
  let qs = `searchTerm=${searchTerm ? searchTerm : ""}`;

  if (subjectId) {
    qs = `${qs}&subjectId=${subjectId != null ? subjectId : ""}`;
  }

  if (yearGroupId) {
    qs = `${qs}&yearGroupId=${yearGroupId != null ? yearGroupId : ""}`;
  }

  if (academicYearId) {
    qs = `${qs}&academicYearId=${academicYearId != null ? academicYearId : ""}`;
  }

  if (schools && schools.length) {
    qs = `${qs}&schools=${schools.join("&schools=")}`;
  }

  if (pageIndex != null) {
    qs = `${qs}&pageIndex=${pageIndex}`;
  }

  return action<PagedQueryView<SearchResultViewBase>>(
    () => client.get(`search/groups/classgroups?${qs}&pageSize=25`),
    types.SEARCH_CLASSGROUPS,
    callback
  );
};

const searchCustomGroups = (
  searchTerm: string,
  subjectId?: number,
  yearGroupId?: number,
  academicYearId?: number,
  schools?: number[],
  pageIndex?: number,
  callback?: callbackType<PagedQueryView<SearchResultViewBase>>
) => {
  let qs = `searchTerm=${searchTerm ? searchTerm : ""}`;

  if (subjectId) {
    qs = `${qs}&subjectId=${subjectId != null ? subjectId : ""}`;
  }

  if (yearGroupId) {
    qs = `${qs}&yearGroupId=${yearGroupId != null ? yearGroupId : ""}`;
  }

  if (academicYearId) {
    qs = `${qs}&academicYearId=${academicYearId != null ? academicYearId : ""}`;
  }

  if (schools && schools.length) {
    qs = `${qs}&schools=${schools.join("&schools=")}`;
  }

  if (pageIndex != null) {
    qs = `${qs}&pageIndex=${pageIndex}`;
  }

  return action<PagedQueryView<SearchResultViewBase>>(
    () => client.get(`search/groups/customgroups?${qs}&pageSize=25`),
    types.SEARCH_CUSTOMGROUPS,
    callback
  );
};

const searchPhonebook = (
  searchTerm: string,
  schools?: number[],
  pageIndex?: number,
  callback?: callbackType<PagedQueryView<TelephoneSearchView>>
) => {
  let qs = `searchTerm=${searchTerm ? searchTerm : ""}`;

  if (schools && schools.length) {
    qs = `${qs}&schools=${schools.join("&schools=")}`;
  }

  if (pageIndex != null) {
    qs = `${qs}&pageIndex=${pageIndex}`;
  }

  return action<PagedQueryView<TelephoneSearchView>>(
    () => client.get(`search/telephony/numbers?${qs}&pageSize=200`),
    types.SEARCH_PHONEBOOK,
    callback
  );
};

const searchSubjects = (
  searchTerm: string,
  yearGroupId?: number,
  schools?: number[],
  pageIndex?: number,
  callback?: callbackType<PagedQueryView<SearchResultViewBase>>
) => {
  var qs = `searchTerm=${searchTerm ? searchTerm : ""}`;
  if (yearGroupId) {
    qs = `${qs}&yearGroupId=${yearGroupId != null ? yearGroupId : ""}`;
  }
  if (schools && schools.length) {
    qs = `${qs}&schools=${schools.join("&schools=")}`;
  }
  if (pageIndex != null) {
    qs = `${qs}&pageIndex=${pageIndex}`;
  }

  return action<PagedQueryView<SearchResultViewBase>>(
    () => client.get(`search/groups/subjects?${qs}&pageSize=25`),
    types.SEARCH_SUBJECTS,
    callback
  );
};

const searchYearGroup = (
  searchTerm: string,
  schools?: number[],
  callback?: callbackType<PagedQueryView<SearchResultViewBase>>
) => {
  var qs = `searchTerm=${searchTerm ? searchTerm : ""}`;

  if (schools && schools.length) {
    qs = `${qs}&schools=${schools.join("&schools=")}`;
  }

  return action<PagedQueryView<SearchResultViewBase>>(
    () => client.get(`search/groups/yeargroups?${qs}`),
    types.SEARCH_YEARGROUP,
    callback
  );
};

const getSearchEndPoint = (
  selectedGroupType: GroupTypes | StaffType | UserType,
  searchTerm: string,
  schoolIds?: number[],
  academicYearId?: number
) => {
  
  switch (selectedGroupType) {

    case SEARCH_GROUP_TYPE_KEYS.STAFF:
      return searchStaff(searchTerm, null, schoolIds);

    case SEARCH_GROUP_TYPE_KEYS.ASSOCIATE:
      return searchStaff(searchTerm, StaffType.Associate, schoolIds);

    case SEARCH_GROUP_TYPE_KEYS.TEACHER:
      return searchStaff(searchTerm, StaffType.Teaching, schoolIds);

    case SEARCH_GROUP_TYPE_KEYS.ASSOCIATE:
      return searchStaff(searchTerm, StaffType.Associate, schoolIds);

    case SEARCH_GROUP_TYPE_KEYS.SUBJECT:
      return searchSubjects(searchTerm, null, schoolIds);

    case SEARCH_GROUP_TYPE_KEYS.CLASS_GROUP:
      return searchClassGroups(searchTerm, null, null, academicYearId, schoolIds);

    case SEARCH_GROUP_TYPE_KEYS.STUDENT:
      return searchStudents(searchTerm, { schools: schoolIds });

    case SEARCH_GROUP_TYPE_KEYS.YEAR_GROUP:
      return searchYearGroup(searchTerm, schoolIds);

    case SEARCH_GROUP_TYPE_KEYS.TUTOR_GROUP:
      return searchTutorGroups(searchTerm, academicYearId, schoolIds);

    case SEARCH_GROUP_TYPE_KEYS.CUSTOM_GROUP:
      return searchCustomGroups(searchTerm, null, null, academicYearId, schoolIds);

    default:
      return searchGroups(searchTerm, selectedGroupType as number, academicYearId, schoolIds);
  }
};

const clearAllResults = () : void => {
  store.dispatch<IBeehiveAction<null>>({
    type: types.CLEAR_RESULTS,
    payload: null,
  });
};

const searchEngineActions = {
  types,
  searchUsers,
  searchDepartments,
  searchStaff,
  searchStudents,
  searchGroups,
  searchTutorGroups,
  searchClassGroups,
  searchCustomGroups,
  searchPhonebook,
  searchSubjects,
  getSearchEndPoint,
  clearAllResults
};

export default searchEngineActions;
