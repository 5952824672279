import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import { BehaviourCategoryGroupByListView } from "areas/behaviour/types/behaviourResponses.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";

interface IBehaviourUpscaleCategoriesState {
  behaviourUpscaleCategoryGroups: BehaviourCategoryGroupByListView[];
  loading: boolean;
  error: IBeehiveError;
}
const INITIAL_STATE: IBehaviourUpscaleCategoriesState = {
  behaviourUpscaleCategoryGroups: [],
  loading: false,
  error: null,
};

const { GETUPSCALEBEHAVIOURCATEGORIES } = behaviourActions.types;

const behaviourUpscaleCategoriesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IBehaviourUpscaleCategoriesState => {
  
  switch (action.type) {
    case GETUPSCALEBEHAVIOURCATEGORIES.START:
      return { ...state, loading: true, error: null };

    case GETUPSCALEBEHAVIOURCATEGORIES.SUCCESS:
      return {
        ...state,
        loading: false,
        behaviourUpscaleCategoryGroups: action.payload,
      };

    case GETUPSCALEBEHAVIOURCATEGORIES.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default behaviourUpscaleCategoriesReducer;
