import React, { useState, useEffect } from "react";
import { Dropdown } from "ui-kit";
import { Constants } from "configuration";

interface IPublishStatusDropdownProps {
  onChange?: (value: number) => void;
  initialValue?: number;
  fluid?: boolean;
  clearable?: boolean;
}

interface publishStatusOption {
  key: number | string;
  label: string;
  value: number;
}

const PublishStatusDropdown: React.FC<IPublishStatusDropdownProps> = ({
  onChange,
  initialValue,
  fluid,
  clearable
}) => {
  const ALL_OPTION: publishStatusOption = {
    key: -1,
    label: "All Statuses",
    value: -1,
  };

  const [selectedValue, setSelectedValue] = useState<number>(-1);
  const [options, setOptions] = useState<publishStatusOption[]>(() => {
    var opts: publishStatusOption[] = [];

    for (var key in Constants.PUBLISH_STATUS) {
      opts.push({
        key: key,
        label: Constants.PUBLISH_STATUS[key].name,
        value: Constants.PUBLISH_STATUS[key].value,
      });
    }

    return [ALL_OPTION, ...opts];
  });

  useEffect(() => {
    const option = options.find(x => x.value === initialValue);

    if (option) {
      setSelectedValue(option.value);
    }
  }, [initialValue]);

  const handleChange = (value: number) => {
    setSelectedValue(value);

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Dropdown
      placeholder="Status"
      items={options}
      onChange={handleChange}
      value={selectedValue}
      fluid={fluid}
      clearable={clearable}
    />
  );
};
export default PublishStatusDropdown;
