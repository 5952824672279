import { SchoolListView } from "types/schools/schools.types";

export enum GroupType {
  ClassGroup = 0,
  Subject = 1,
  TutorGroup = 2,
  YearGroup = 3,
  House = 4,
  Custom = 5,
  Provision = 6
}