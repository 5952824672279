import reportingActions from "areas/payments/actions/reportingActions";
import { BeehiveReport, IBeehiveAction } from "types/common/action.types";


export interface IPaymentsProductReportsState {
  loading: boolean;
  error: string | null;
  reportData: BeehiveReport | null;
}

const INITIAL_STATE: IPaymentsProductReportsState = {
  loading: false,
  error: null,
  reportData: null
};


const paymentsProductReportsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IPaymentsProductReportsState => {

  const {
    PAYMENTS_SINGLEPRODUCTORDERSREPORT,
    PAYMENTS_SINGLEPRODUCTRECIPIENTSREPORT,
    PAYMENTS_SINGLEPRODUCTEXPORT
  } = reportingActions.paymentReportingTypes;

  switch (action.type) {
    case PAYMENTS_SINGLEPRODUCTORDERSREPORT.START:
    case PAYMENTS_SINGLEPRODUCTRECIPIENTSREPORT.START:
    case PAYMENTS_SINGLEPRODUCTEXPORT.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_SINGLEPRODUCTORDERSREPORT.SUCCESS:
    case PAYMENTS_SINGLEPRODUCTRECIPIENTSREPORT.SUCCESS:
    case PAYMENTS_SINGLEPRODUCTEXPORT.SUCCESS:
      return { 
        ...state, 
        reportData: action.payload, 
        loading: false 
      };

    case PAYMENTS_SINGLEPRODUCTORDERSREPORT.FAILED:
    case PAYMENTS_SINGLEPRODUCTRECIPIENTSREPORT.FAILED:
    case PAYMENTS_SINGLEPRODUCTEXPORT.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default paymentsProductReportsReducer;
