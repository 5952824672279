import React from "react";
import { List, EmptyMessage, CircleIcon, Size } from "ui-kit";
import UserName from "./userName";
import { arrays } from "utils";
import Avatar from "./avatar";


interface IUserListProps {
  users: any[];
  title?: string;
  editable?: boolean;
  onUserRemoved?: (user: any, index: number) => void;
  transparrent?: boolean;
  style?: React.CSSProperties;
}

const UserList: React.FC<IUserListProps> = ({ users, title, editable, onUserRemoved, transparrent, style }) => {

  const handleUserRemoved = (user: any, index: number) => {
    onUserRemoved?.(user, index);
  };

  return arrays.isEmpty(users) ? (
    <EmptyMessage icon="users" title="No users found" cover />
  ) : (
    <List title={title}>
      {users.map((user, index) => (
        <List.Item
          key={index}
          left={<Avatar user={user} size={Size.Small} />}
          // label={<UserName user={user} />}
          right={
            editable ? (
              <CircleIcon
                value="times"
                onClick={() => handleUserRemoved(user, index)}
                size={Size.Small}
                tooltip={`Remove ${user.firstName} ${user.lastName}`}
              />
            ) : null
          }
          transparent={transparrent}
          style={style}
        />
      ))}
    </List>
  );
};

export default UserList;
