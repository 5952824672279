import axios from "axios";
import configuration from "configuration";
import tokenStorage from "./authentication/tokenStorage";
import loginService from "./authentication/loginService";
import signalrLocalStorage from "signalr/signalrLocalStorage";
import store from "reducers/store";
import { upgradeUi } from "../actions/ui/upgrade";
import authenticationActions from "../actions/authentication/authenticationActions";

const API_BASEURI = configuration.baseApiUrl(true);

if (!API_BASEURI) throw new Error("API Base URI has not been supplied.");

/**
 * An instance of an AxiosStatic object to call the Server API
 */
const client = axios.create({
  baseURL: API_BASEURI,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 * Axios interceptor to add an authorisation header with the access token
 */
client.interceptors.request.use(config => {
  if (tokenStorage.hasAccessToken()) {
    config.headers.Authorization = `Bearer ${tokenStorage.getAccessToken()}`;
    config.headers["x-signalr"] = signalrLocalStorage.getConnectionId();
    config.headers["x-client-version-desktop"] = configuration.clientVersion;
  }
  return config;
});

/**
 * Axios interceptor for reauthorising expired access tokens
 * From https://medium.com/swlh/handling-access-and-refresh-tokens-using-axios-interceptors-3970b601a5da
 */
client.interceptors.response.use(
  response => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (error.response?.status === 403) {
      const { dispatch } = store;

      console.error(
        "The current UI version is out-of-date. Please refresh the browser."
      );
      upgradeUi();

      return Promise.reject(error);
    }

    if (
      error.response?.status === 401 &&
      originalRequest.url === `${API_BASEURI}${configuration.tokens.endpoint}`
    ) {
      authenticationActions.logout();
      return Promise.reject(error);
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      loginService
        .refreshAccessToken()
        .then(response => {
          originalRequest.config.headers.Authorization = `Bearer ${response.access_token}`; // Or axios.defaults.headers.common['Authorization']?
        })
        .catch(error => {
          authenticationActions.logout();
          return Promise.reject(error);
        });

      return axios(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default client;
