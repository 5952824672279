import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { formatTime, todayDateTime, formatDate } from "utils/dateTime";
import {
  Icon,
  Size,
  Button,
  Table,
  Spacing,
  DetailLabel,
  HelpPopup,
  Checkbox,
  Text,
} from "ui-kit";
import {
  success,
  danger,
  Swatches,
  warning,
  neutral,
} from "ui-kit/common/colors";
import styled from "styled-components";
import { DETENTION_STATUS_KEYS } from "areas/behaviour/constants/detentions";
import Avatar from "sharedComponents/common/users/avatar";
import { DETENTION_GROUPBY_KEYS } from "areas/behaviour/constants/tableConstants";
import { getBehaviourGivenGroup } from "areas/behaviour/utils/detentions";
import { DetentionStudentView } from "areas/behaviour/types/detentionRegisterResponse.types";
import { useAppSelector } from "reducers/hooks";


const AttendanceButtons = styled.div`
  .button {
    padding: ${Spacing.Small + 2}px ${Spacing.Medium}px;
    width: 120px;
    margin-right: ${Spacing.Small}px;
  }

  .mark-not-present-button {
    margin-top: ${Spacing.Small}px;
  }
`;

const ButtonWrapper = styled.div`
  .button {
    padding: ${Spacing.Small + 2}px ${Spacing.Medium}px;
  }

.form-control * {
  display: flex;
  justify-content: center;
  align-items: center;
}

`;

const AttendanceWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .button {
    margin-left: ${Spacing.Medium}px;
  }
`;

const BehaviourWrapper = styled.div`
  display: flex;

  .detail-label {
    margin-right: ${Spacing.ExtraSmall + 2}px;
  }
`;

const AvatarWrapper = styled.div`
  .avatar {
    .detail-label {
      text-align: left;
    }
  }
`;

const DetentionName = styled.div`
  width: 100%;
`;


interface IDetentionRegisterTableRowProps {
  detention: DetentionStudentView;
  markPresent: (studentId: string, behaviourRewardId: number, detentionId: number) => void;
  markNotPresent: (studentId: string, behaviourRewardId: number, detentionId: number) => void;
  onClickDetention: (detention: DetentionStudentView) => void;
  selectedGroupByOptions: number[];
  rescheduleDetention: (detention: DetentionStudentView) => void;
  detentionRegisterStarts: number;
  handleBulkSelection: (checked: boolean, detention: DetentionStudentView) => void;
  handleLocalMarking:  (detention: DetentionStudentView, markingType?:number ) => void;
  selectedDetentions: DetentionStudentView[]
}


const DetentionRegisterTableRow: React.FC<IDetentionRegisterTableRowProps> = ({
  detention,
  markPresent,
  markNotPresent,
  onClickDetention,
  selectedGroupByOptions,
  rescheduleDetention,
  detentionRegisterStarts,
  handleBulkSelection,
  handleLocalMarking,
  selectedDetentions,
}) => {

  const [isPresent, setIsPresent] = useState(null);
  const [changeAttendanceLoading, setChangeAttendanceLoading] = useState(false);
  const [initialMarkLoading, setInitialMarkLoading] = useState(false);

  const {loading } = useAppSelector(
    state => state.detention
  );

  useEffect(() => {
    if (changeAttendanceLoading && !loading) {
      setIsPresent(!isPresent);
      setChangeAttendanceLoading(false);
    }
    if (initialMarkLoading && !loading) {
      setInitialMarkLoading(false)
    }

  }, [loading, changeAttendanceLoading, isPresent]);

  useEffect(() => {
    switch (detention.statusId) {
      case DETENTION_STATUS_KEYS.ISSUED:
        setIsPresent(null);
        break;

      case DETENTION_STATUS_KEYS.ATTENDED:
        setIsPresent(true);
        break;

      case DETENTION_STATUS_KEYS.MISSED:
        setIsPresent(false);
        break;

      default:
        setIsPresent(false);
    }
  }, []);

  const onMarkPresent = (buttonloading?:boolean) => {
    markPresent(
      detention.student.id,
      detention.behaviourRewardId,
      detention.detentionId
    );
    if (!buttonloading) setIsPresent(true);
    handleLocalMarking(detention, 1)
  };

  const onMarkNotPresent = (buttonloading?:boolean) => {
    markNotPresent(
      detention.student.id,
      detention.behaviourRewardId,
      detention.detentionId
    );
    if (!buttonloading) setIsPresent(false);
    handleLocalMarking(detention, 0)
  
  };

  const changeAttendance = () => {
    if (
      window.confirm(
        `Are you sure you want to change the attendance to ${
          isPresent ? "Not Present" : "Present"
        }? A notification will be sent to the student's parents.`
      )
    ) {
      setChangeAttendanceLoading(true);
      isPresent? onMarkNotPresent(true) : onMarkPresent(true);
    }
  };

  const detentionAttendance = () => {
    if (detention.statusId === DETENTION_STATUS_KEYS.CANCELLED) {
      return;
    } else {
      switch (isPresent) {
        case null:
          return (
            <AttendanceButtons>
              <Button
                text="Present"
                color={Swatches.Success}
                onClick={e => {
                  onMarkPresent();
                  setInitialMarkLoading(true)
                  e.stopPropagation();
                }}
                size={Size.Small}
              />
              <Button
                className="mark-not-present-button"
                text="Not Present"
                color={Swatches.Danger}
                onClick={e => {
                  onMarkNotPresent();
                  setInitialMarkLoading(true)
                  e.stopPropagation();
                }}
                size={Size.Small}
              />
            </AttendanceButtons>
          );

        case true:
          return (
            <Icon
              value="check-circle"
              color={success}
              size={Size.ExtraLarge}
              tooltip="Attended"
              tooltipSub="The detention was attended"
            />
          );

        case false:
          return (
            <Icon
              value="times-circle"
              color={danger}
              size={Size.ExtraLarge}
              tooltip="Missed"
              tooltipSub="The detention was missed"
            />
          );

        default:
          return (
            <Icon
              value="question-circle"
              color={neutral[400]}
              size={Size.ExtraLarge}
            />
          );
      }
    }
  };

  return (
    <>
      <Table.Row onClick={() => onClickDetention(detention)}>
        <Table.Cell width={0.3} center>
          <Text>
            {(detention.statusId === 0 && !isPresent || detention.statusId === 2 && !isPresent) || (!isPresent && detention.statusId !== 3 &&  detention.statusId !== 4)   ?
            <Checkbox
              disabled={changeAttendanceLoading}
              checked={selectedDetentions.some((Selected) => Selected.detentionId === detention.detentionId)}
              onChange={checked => handleBulkSelection(checked.checked, detention)}
            /> : null}
          </Text>
        </Table.Cell>
        <Table.Cell center width={1}>
          <AttendanceWrapper>
            {detention.statusId === DETENTION_STATUS_KEYS.CANCELLED && (
              <Icon
                value="ban"
                color={neutral[700]}
                size={Size.ExtraLarge}
                tooltip="Cancelled"
                tooltipSub="The detention has been cancelled"
              />
            )}
            {todayDateTime() >=
            moment(detention.startDate)
              .subtract(detentionRegisterStarts, "minutes")
              .format()
              ? detentionAttendance()
              : ""}
          </AttendanceWrapper>
        </Table.Cell>

        <Table.Cell width={1.5}>
          <AvatarWrapper>
            <Avatar
              user={detention.student}
              sub={
                detention.tutorGroup?.friendlyName
                  ? `${detention.student.simsId} | ${detention.tutorGroup.friendlyName}`
                  : detention.student.simsId
              }
            />
          </AvatarWrapper>
        </Table.Cell>

        <Table.Cell width={0.5}>
          <BehaviourWrapper>
            <DetailLabel bold label={detention.codeDisplayName} />
            <HelpPopup title={detention.categoryName} />
          </BehaviourWrapper>
        </Table.Cell>

        {!selectedGroupByOptions.includes(
          DETENTION_GROUPBY_KEYS.DETENTION_TYPE
        ) && (
          <Table.Cell width={1}>
            <DetentionName>{detention.detentionTypeName}</DetentionName>
          </Table.Cell>
        )}

        <Table.Cell width={1}>
          <DetailLabel
            bold
            label={detention.awardedBy}
            sub={
              <>
                {getBehaviourGivenGroup(detention)}
                <p>
                  {`${formatDate(detention.awardedDate)} ${formatTime(
                    detention.awardedDate
                  )}`}
                </p>
              </>
            }
          />
        </Table.Cell>

        {!selectedGroupByOptions.includes(DETENTION_GROUPBY_KEYS.ROOM) && (
          <Table.Cell width={0.75}>{detention.roomName}</Table.Cell>
        )}

        {!selectedGroupByOptions.includes(
          DETENTION_GROUPBY_KEYS.START_TIME
        ) && (
          <Table.Cell width={0.5}>{formatTime(detention.startDate)}</Table.Cell>
        )}

        {!selectedGroupByOptions.includes(DETENTION_GROUPBY_KEYS.END_TIME) && (
          <Table.Cell width={0.5}>{formatTime(detention.endDate)}</Table.Cell>
        )}

        <Table.Cell center width={0.5}>
          {detention.student.hasOutstandingDetentions && (
            <Icon
              value="exclamation-triangle"
              color={warning}
              size={Size.Medium}
              tooltip="Unattended Detentions"
              tooltipSub="This student has more than five unattended detentions"
            />
          )}
        </Table.Cell>

        <Table.Cell center width={1}>
          <ButtonWrapper>
            {detention.statusId ===
            DETENTION_STATUS_KEYS.CANCELLED || detention.statusId ===
            DETENTION_STATUS_KEYS.RESCHEDULED ? null : isPresent === null ? (
              <Button
                text="Reschedule"
                onClick={e => {
                  rescheduleDetention(detention);
                  e.stopPropagation();
                }}
                size={Size.Small}
              />
            ) : (
              <Button
                working={initialMarkLoading || changeAttendanceLoading? loading : false}
                text="Change Attendance"
                onClick={e => {
                  changeAttendance();
                  e.stopPropagation();
                }}
                size={Size.Small}
              />
            )}
          </ButtonWrapper>
        </Table.Cell>
      </Table.Row>
      {/* <ChangeAttendanceModal
        open={openAttendanceModal}
        setOpen={setOpenAttendanceModal}
        detention={detention}
        isPresent={isPresent}
        setIsPresent={setIsPresent}
        markPresent={markPresent}
        markNotPresent={markNotPresent}
      /> */}
    </>
  );
};



export default DetentionRegisterTableRow;
