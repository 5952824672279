import { IBeehiveAction } from "types/common/action.types";
import consentActions from "../../actions/consentActions";
import { ConsentFormListView } from "areas/administration/types/consentResponses.types";

interface ICreatedConsentFormsState {
  loading: boolean;
  forms: ConsentFormListView[] | null;
  error: string | null;
}

const CONSENT_STATE: ICreatedConsentFormsState = {
  loading: false,
  forms: null,
  error: null,
};


const createdConsentFormsReducer = (state = CONSENT_STATE, action: IBeehiveAction) : ICreatedConsentFormsState => {

  const { CONSENT_GETCONSENTFORMSFORRESPONSIBLESTAFF } = consentActions.types;

  switch (action.type) {
    case CONSENT_GETCONSENTFORMSFORRESPONSIBLESTAFF.START:
      return { ...state, loading: true, error: null };
    case CONSENT_GETCONSENTFORMSFORRESPONSIBLESTAFF.SUCCESS:
      return { ...state, loading: false, forms: action.payload, error: null };
    case CONSENT_GETCONSENTFORMSFORRESPONSIBLESTAFF.FAILED:
      return {
        ...state,
        loading: false,
        forms: null,
        error: "There was a problem loading the consent forms",
      };

    default:
      return state;
  }
};

export default createdConsentFormsReducer;
