import React, { useEffect } from "react";
import AssignmentView from "./assignmentView";
import assignmentActions from "areas/planner/actions/assignments/assignmentActions";
import { useAppSelector } from "reducers/hooks";


const AssignmentWrapper: React.FC = () => {

  const { selected, assignment, loading, error } = useAppSelector(
    state => state.assignment
  );
  const { user } = useAppSelector(state => state.currentUser);

  useEffect(() => {
    user &&
      selected &&
      assignmentActions.getAssignment(user.id, selected);
  }, [selected]);

  return (
    <AssignmentView error={error} loading={loading} assignment={assignment} />
  );
};

export default AssignmentWrapper;
