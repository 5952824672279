import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import paymentActions from "areas/payments/actions/paymentsActions";
import {
  EmptyMessage,
  Currency,
  Table,
  Chip,
  DetailLabel,
  Swatches
} from "ui-kit";
import { arrays } from "utils";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { RootState } from "reducers/store";
import { ProductListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


const ManagedProducts = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { products, loading, error } = useSelector((state: RootState) => state.managedProducts);
  const navigate = useNavigate();

  useEffect(() => {
    user && paymentActions.getManagedProducts(user.id);
  }, []);

  const handleProductClick = (product: ProductListView) => {
    navigate(`/main/store/managedProducts/categories/${product.category.id}/products/${product.id}`);
  };

  return (
    <Table
      zebra
      caption="Your Managed Products"
      error={error != null}
      loading={loading}
      empty={arrays.isEmpty(products)}
      emptyMessage={
        <EmptyMessage
          icon="shopping-bag"
          title="No products found"
          summary="You do not have any managed products"
          cover
        />
      }
      grow
    >
      <Table.Header>
        <Table.HeaderCell width={0.75} />
        <Table.HeaderCell width={5}>Product</Table.HeaderCell>
        <Table.HeaderCell width={1} right>
          Price
        </Table.HeaderCell>
        <Table.HeaderCell width={1}>Expiry Date</Table.HeaderCell>
        <Table.HeaderCell width={0.5} right>
          Stock
        </Table.HeaderCell>
        <Table.HeaderCell width={0.5} right>
          Sold
        </Table.HeaderCell>
        <Table.HeaderCell width={0.5} right>
          Remain
        </Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {products.map((product: ProductListView, index: number) => (
          <Table.Row key={index} onClick={() => handleProductClick(product)}>
            <Table.Cell width={0.75}>
              {product.isVisibleInStore ? (
                <Chip colorSwatch={Swatches.Success} text="On Sale" />
              ) : (
                <Chip colorSwatch={Swatches.Low} text="Inactive" />
              )}
            </Table.Cell>
            <Table.Cell width={5}>
              <DetailLabel
                label={product.name}
                sub={product.category.name}
                bold
              />
            </Table.Cell>
            <Table.Cell right width={1}>
              <Currency value={product.unitPrice} />
            </Table.Cell>
            <Table.Cell width={1}>
              {product.expiryDate && (
                <Moment date={product.expiryDate} format="DD/MM/YYYY" />
              )}
            </Table.Cell>
            <Table.Cell right width={0.5}>
              {product.enforceStockControl ? product.stock : "Unlimited"}
            </Table.Cell>
            <Table.Cell right width={0.5}>
              {product.sold}
            </Table.Cell>
            <Table.Cell right width={0.5}>
              {product.enforceStockControl ? (
                product.soldOut ? (
                  <Chip text="Sold Out" colorSwatch={Swatches.Danger} />
                ) : (
                  product.stockRemaining
                )
              ) : (
                "N/A"
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default ManagedProducts;
