import React from "react";
import PropTypes from "prop-types";
import { Loader, Size } from "ui-kit";
import styled from "styled-components";

const Wrapper = styled.div`
z-index: 500;
max-height: 175px;
overflow-y: scroll;
position: absolute;
width: 100%;
background: white;
box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);


.search-result-list {
list-style-type: none;
margin: 0;
padding: 0 1rem 0.5rem 1rem;

.search-result-list-item {
  padding: 1rem;
  border-bottom: 1px solid $divider-color;
  transition: all ease 150ms;

  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.05);
  }

  .search-result-name,
  .search-result-school {
    text-decoration: none;
  }

  .search-result-name {
    font-weight: bold;
  }

  .search-result-school {
    font-size: 0.8rem;
    color: $dark-grey;
  }
}
`;


interface ISearchResultListProps {
  loading?: boolean; 
  searchResults: any[];
  handleClickItem: (result: any) => void;
}

const SearchResultList: React.FC<ISearchResultListProps> = ({ loading, searchResults, handleClickItem }) => {
  
  return (
    <Wrapper>
      {loading ? (
        <Loader size={Size.Small} cover />
      ) : (
        <ul className="search-result-list form">
          {searchResults.map(result => (
            <li
              key={result.id}
              onClick={() => handleClickItem(result)}
              className="search-result-list-item form"
            >
              <div className="search-result-name">{`${result.title} | ${result.school.name}`}</div>
            </li>
          ))}
        </ul>
      )}
    </Wrapper>
  );
};


export default SearchResultList;
