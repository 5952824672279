import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { ProvisionQueryFilter, SaveCustomProvisionCommand, SaveStandardProvisionCommand } from "areas/send/types/passportRequest.types";
import { CustomProvisionDetailView, ProvisionListView, StandardProvisionDetailView } from "areas/send/types/passportResponse.types";
import store from "reducers/store";
import client from "services/client";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { PagedQueryView } from "types/common/views.types";
import { urls } from "utils";

const builder = new actionTypeBuilder("send");

const types = {
  PROVISIONS_GET: builder.build("provisions_get"),
  PROVISIONS_GETSTANDARD: builder.build("provisions_getstandard"),
  PROVISIONS_GETCUSTOM: builder.build("provisions_getcustom"),

  PROVISIONS_DELETE: builder.build("provisions_delete"),

  PROVISIONS_CREATESTANDARD: builder.build("provisions_createstandard"),
  PROVISIONS_SAVESTANDARD: builder.build("provisions_savestandard"),

  PROVISIONS_CREATECUSTOM: builder.build("provisions_createcustom"),
  PROVISIONS_SAVECUSTOM: builder.build("provisions_savecustom"),
  PROVISIONS_FILTER: "PROVISIONS_FILTER",
};


const getProvisions = (filter: ProvisionQueryFilter, pageIndex: number, callback?: callbackType<PagedQueryView<ProvisionListView>>) => {
  var url = new urls.QueryString(`planner/send/provisions`);

  if (filter) {
    if (filter.schoolId === -1) {
      filter = { ...filter, schoolId: null };
    }
    if (filter.providerId === -1) {
      filter = { ...filter, providerId: null };
    }
    if (filter.areaOfNeed === null) {
      filter = { ...filter, areaOfNeed: null };
    }
    if (filter.provisionType === null) {
      filter = { ...filter, provisionType: null };
    }

    url.addParams(filter);
  }
  url.addParam("pageIndex", pageIndex);
  url.addParam("pageSize", 50);

  return action<PagedQueryView<ProvisionListView>>(
    () => client.get(url.toUrl()), 
    types.PROVISIONS_GET, 
    callback
  );
};

const filterProvisions = (filter: ProvisionQueryFilter) => {
  store.dispatch<IBeehiveAction<ProvisionQueryFilter>>({
    type: types.PROVISIONS_FILTER,
    payload: filter,
  });
};

const getStandardProvision = (providerId: number, provisionId: number, callback?: callbackType<StandardProvisionDetailView>) =>
  action<StandardProvisionDetailView>(
    () => client.get(`planner/send/providers/${providerId}/provisions/${provisionId}`),
    types.PROVISIONS_GETSTANDARD,
    callback
  );

const getCustomProvision = (studentId: string, provisionId: number, callback?: callbackType<CustomProvisionDetailView>) =>
  action<CustomProvisionDetailView>(
    () => client.get(`planner/send/students/${studentId}/customprovisions/${provisionId}`),
    types.PROVISIONS_GETCUSTOM,
    callback
  );

const createStandardProvision = (data: SaveStandardProvisionCommand, callback?: callbackType<StandardProvisionDetailView>) =>
  action<StandardProvisionDetailView>(
    () => client.post(`planner/send/providers/${data.providerId}/provisions`, data),
    types.PROVISIONS_CREATESTANDARD,
    callback
  );

const saveStandardProvision = (data: SaveStandardProvisionCommand, callback?: callbackType<StandardProvisionDetailView>) =>
  action<StandardProvisionDetailView>(
    () => client.post(`planner/send/providers/${data.providerId}/provisions/${data.id}`, data),
    types.PROVISIONS_SAVESTANDARD,
    callback
  );

const createCustomProvision = (data: SaveCustomProvisionCommand, callback?: callbackType<CustomProvisionDetailView>) =>
  action<CustomProvisionDetailView>(
    () => client.post(`planner/send/students/${data.studentId}/customprovisions`, data),
    types.PROVISIONS_CREATECUSTOM,
    callback
  );

const saveCustomProvision = (data: SaveCustomProvisionCommand, callback?: callbackType<CustomProvisionDetailView>) =>
  action<CustomProvisionDetailView>(
    () => client.post(`planner/send/students/${data.studentId}/customprovisions/${data.id}`, data),
    types.PROVISIONS_SAVECUSTOM,
    callback
  );

const deleteProvision = (providerId: number, provisionId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.delete(`planner/send/providers/${providerId}/provisions/${provisionId}`),
    types.PROVISIONS_DELETE,
    callback
  );


const provisionsActions = {
  types,
  getProvisions,
  filterProvisions,
  getStandardProvision,
  getCustomProvision,
  createStandardProvision,
  saveStandardProvision,
  createCustomProvision,
  saveCustomProvision,
  deleteProvision,
};

export default provisionsActions;
