import React from "react";
import { PasswordStrengthChecker } from "sharedComponents/common";
import { TitleSize, Title } from "ui-kit";
import Subheader from "sharedComponents/layout/header/subheader";

const CheckPassword = () => {
  return (
    <>
      <Subheader>
        <Title
          size={TitleSize.H2}
          text="Password & Security"
          sub="Check Your Password"
        />
      </Subheader>
      <PasswordStrengthChecker />
    </>
  );
};

export default CheckPassword;
