import styled from "styled-components";
import { PageInfo } from "types/common/paging.types";
import {
  Swatches,
  Spacing,
  BorderRadius,
  fontStyle,
  displayFont,
  typescale,
} from "ui-kit";


const PaginationWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  margin-bottom: ${Spacing.Small}px;
`;

const PaginationTab = styled.a<{isActive?: boolean}>`
  display: flex;
  justify-content: center;
  padding: ${Spacing.Default}px;
  margin: ${Spacing.Small}px;
  height: 18px;
  width: 18px;
  border: none;
  border-radius: ${BorderRadius.Default}px;
  background: ${Swatches.Low.swatch};
  background: ${props => props.isActive ? Swatches.Primary.swatch : Swatches.Low.swatch};
  ${fontStyle(displayFont.roman, typescale.header4, Swatches.Low.foreground)}
  cursor: pointer;
`;


interface IPaginationProps {
  paging: PageInfo;
  onPageClick: (pageIndex: number) => void;
}


const Pagination: React.FC<IPaginationProps> = ({ paging, onPageClick }) => {

  const handlePageClick = (pageIndex: number) => {
    onPageClick?.(pageIndex);
  };

  return (
    <PaginationWrapper>
      {paging &&
        [...Array(paging.totalPages)].map((x, index) => (
          <PaginationTab
            key={index}
            isActive={index === paging.pageIndex}
            onClick={() => handlePageClick(index)}
          >
            {index + 1}
          </PaginationTab>
        ))}
    </PaginationWrapper>
  );
};

export default Pagination;
