import searchEngineActions from "areas/search/actions/searchEngineActions";
import defaults from "configuration/defaults";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";
import { PageInfo } from "types/common/paging.types";
import { SearchResultView } from "types/common/views.types";
import { StudentListView } from "types/users/userListViews.types";


interface ISearchStudentsState {
  students: SearchResultView<StudentListView>[] | null;
  loading: boolean;
  error: IBeehiveError;
  paging: PageInfo;
}

const INITIAL_STATE: ISearchStudentsState = {
  students: null,
  loading: false,
  error: null,
  paging: defaults.DEFAULT_PAGING,
};


const searchStudentsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISearchStudentsState => {

  const { SEARCH_STUDENTS, CLEAR_RESULTS } = searchEngineActions.types;

  switch (action.type) {

    case SEARCH_STUDENTS.START:
      // We are not using ...INITIAL_STATE here to avoid a the menu opening and closing between key strokes when using an autocomplete control
      return { ...state, error: null, loading: true };

    case SEARCH_STUDENTS.SUCCESS:
      // console.log(action.payload);
      return {
        ...state,
        loading: false,
        students:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : state.students
              ? [...state.students, ...action.payload.items]
              : [...action.payload.items],
        paging: action.payload.paging,
      };
    case SEARCH_STUDENTS.FAILED:
      return {
        ...state,
        loading: false,
        students: null,
        paging: defaults.DEFAULT_PAGING,
        error: action.payload,
      };

    case CLEAR_RESULTS:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default searchStudentsReducer;
