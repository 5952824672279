import { CustomProvisionDetailView } from "areas/send/types/passportResponse.types";
import provisionsActions from "../../actions/provisions/provisionsActions";
import { IBeehiveAction } from "types/common/action.types";

interface ICustomProvisionState {
  provision: CustomProvisionDetailView | null;
  loading: boolean;
  error: string | null;
  saving: boolean;
  saveError: string | null;
  deleting: boolean;
  deleteError: string | null;
  newId: number | null;
}

const INITIAL_STATE: ICustomProvisionState = {
  provision: null,
  loading: false,
  error: null,
  saving: false,
  saveError: null,
  deleting: false,
  deleteError: null,
  newId: null,
};

const customProvisionReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ICustomProvisionState => {
  const {
    PROVISIONS_GETCUSTOM,
    PROVISIONS_CREATECUSTOM,
    PROVISIONS_SAVECUSTOM,
  } = provisionsActions.types;

  switch (action.type) {
    case PROVISIONS_GETCUSTOM.START:
      return { ...state, loading: true };
    case PROVISIONS_GETCUSTOM.SUCCESS:
      return { ...state, loading: false, provision: action.payload };
    case PROVISIONS_GETCUSTOM.FAILED:
      return { ...state, loading: false, error: action.payload };

    case PROVISIONS_CREATECUSTOM.START:
      return { ...state, saveError: null, saving: true };
    case PROVISIONS_CREATECUSTOM.SUCCESS:
      return { ...state, saving: false, newId: action.payload.id };
    case PROVISIONS_CREATECUSTOM.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem creating the provision",
      };

    case PROVISIONS_SAVECUSTOM.START:
      return { ...state, saveError: null, deleteError: null, saving: true };
    case PROVISIONS_SAVECUSTOM.SUCCESS:
      return { ...state, saving: false };
    case PROVISIONS_SAVECUSTOM.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the provision",
      };
    default:
      return state;
  }
};

export default customProvisionReducer;
