import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import { arrays } from "utils";
import { LoadingWrapper, TabBar } from "ui-kit";
import UserPrintCredit from "./userPrintCredit";
import { RootState } from "reducers/store";
import { PaymentUser } from "areas/payments/reducers/payments/paymentsUserReducer";
import { SmartcardDetailView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import paymentActions from "areas/payments/actions/paymentsActions";


interface IUserPrintCreditsProps {
  user: PaymentUser;
}


const UserPrintCredits: React.FC<IUserPrintCreditsProps> = ({ user }) => {

  const { smartcards, loading: loadingSmartcards, error: smartcardsError } = useSelector((state: RootState) => state.userSmartcards);
  const [activeCard, setActiveCard] = useState<SmartcardDetailView | null>(!arrays.isEmpty(smartcards) ? smartcards[0] : null);

  useEffect(() => {
    if (user) {
      paymentActions.getSmartcardsForUser(user.id, (cards: SmartcardDetailView[]) => {
        if (!arrays.isEmpty(cards)) {
          setActiveCard(cards?.[0]);
        }
      });
    }
  }, []);

  const handleTabClicked = (name: string, index: number) => {
    setActiveCard(smartcards[index]);
  };

  return (
    <LoadingWrapper
      loading={loadingSmartcards}
      error={smartcardsError != null}
      data={user}
      errorMessage="There was a problem loading the smartcards"
      emptyMessage="The requested smartcard was not found"
      emptyIcon="users"
    >
      {smartcards.length > 1 && (
        <TabBar onItemClicked={handleTabClicked}>
          {smartcards.map((card, index) => (
            <TabBar.Item
              key={index}
              name={`${card.user.id}${card.school.code}`}
              text={
                user.userType == Constants.USER_TYPES.STAFF
                  ? card.school.code
                  : `${card.user.firstName} ${card.user.lastName}`
              }
            />
          ))}
        </TabBar>
      )}

      <UserPrintCredit smartcard={activeCard} />
    </LoadingWrapper>
  );
};

export default UserPrintCredits;
