import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  StructuredList,
  Button,
  Swatches,
  Modal,
  RichTextEditor,
  ActionBar,
  Size,
  Message,
  Left,
  Right,
  ToastService,
} from "ui-kit";
import { Constants } from "configuration";
import { users } from "utils";
import submissionActions from "areas/forms/actions/submissionActions";
import { RootState } from "reducers/store";

export enum STATEMODAL_MODE {
  ACCEPT = 0,
  REJECT = 1,
  CANCEL,
}

interface ISubmissionStateModal {
  open: boolean;
  onClose?: () => void;
  onSave?: () => void;
  submission: any;
  mode: STATEMODAL_MODE;
}

const SubmissionStateModal: React.FC<ISubmissionStateModal> = ({
  open,
  onClose,
  onSave,
  submission,
  mode,
}) => {
  const [notes, setNotes] = useState("");
  const [title, setTitle] = useState("");
  const [validationError, setValidationError] = useState("");
  const [notesRequired, setNotesRequired] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [buttonColor, setButtonColor] = useState(Swatches.Success);

  const { updatingStatus, statusError } = useSelector(
    (state: RootState) => state.adminSubmission
  );

  useEffect(() => {
    switch (submission.latestHistory.status) {
      case Constants.SUBMISSION_STATUS.SUBMITTED.value:
        setTitle("Authorise This Form");
        setConfirmMessage("This will authorise the form. Are you sure?");
        break;
      case Constants.SUBMISSION_STATUS.APPROVED.value:
        setTitle("Confirm This Form");
        setConfirmMessage("This will confirm the form. Are you sure?");
        break;
      case Constants.SUBMISSION_STATUS.CONFIRMED.value:
        setTitle("Complete This Form");
        setConfirmMessage(
          "This will mark this form as complete. Are you sure?"
        );
        break;
    }

    switch (mode) {
      case STATEMODAL_MODE.ACCEPT:
        setButtonColor(Swatches.Success);
        setNotesRequired(false);
        break;
      case STATEMODAL_MODE.REJECT:
        setButtonColor(Swatches.Danger);
        setTitle("Reject This Form");
        setNotesRequired(true);
        setConfirmMessage(
          `This will reject this form and return it to ${users.getFullName(
            submission.submittedBy
          )} for the necessary changes to be made. ${
            submission.submittedBy.firstName
          } will also receive an email notification. Are you sure?`
        );
        break;
      case STATEMODAL_MODE.CANCEL:
        setButtonColor(Swatches.Danger);
        setTitle("Cancel This Form");
        setNotesRequired(true);
        setConfirmMessage(`This will cancel this form. Are you sure?`);
        break;

      default:
        break;
    }
  }, [submission, mode]);

  useEffect(() => {
    if (notes !== "" && notesRequired) {
      setValidationError("");
    }
  }, [notes]);

  const handleSave = () => {
    if (notes === "" && notesRequired) {
      setValidationError("You must enter covering notes.");
      return;
    } else {
      setValidationError("");
    }

    if (window.confirm(confirmMessage)) {
      let action = null;
      let toastMessage = "";
      switch (mode) {
        case STATEMODAL_MODE.ACCEPT:
          toastMessage = "Form Updated";
          action = submissionActions.authoriseSubmission;
          break;
        case STATEMODAL_MODE.REJECT:
          toastMessage = "Form Rejected";
          action = submissionActions.rejectSubmission;
          break;
        case STATEMODAL_MODE.CANCEL:
          action = submissionActions.cancelSubmission;
          toastMessage = "Form Cancelled";
          break;
        default:
          break;
      }

      const data = {
        formId: submission.formId,
        categoryId: submission.form.categoryId,
        submissionId: submission.id,
        notes: notes,
      };

      action(data, () => {
        ToastService.pop(toastMessage, null, "paste");

        onSave?.();
        onClose?.();
      });
    }
  };

  return (
    <Modal open={open} onClose={onClose} title={title}>
      <Modal.Body>
        <Message
          text={validationError}
          color={Swatches.Danger}
          icon="times-circle"
        />
        <Message
          text={statusError}
          color={Swatches.Danger}
          icon="times-circle"
        />
        <StructuredList>
          <StructuredList.Item
            name="Notes"
            description={`You ${
              notesRequired ? "must add covering" : "can add optional"
            }  notes. These notes will be seen in the Form History.`}
          >
            <RichTextEditor onChange={value => setNotes(value)} />
          </StructuredList.Item>
        </StructuredList>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Left>
            <Button
              text="Close"
              disabled={updatingStatus}
              color={Swatches.Low}
              onClick={onClose}
              size={Size.Small}
            />
          </Left>
          <Right>
            {" "}
            <Button
              working={updatingStatus}
              text={title}
              color={buttonColor}
              onClick={handleSave}
              size={Size.Small}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default SubmissionStateModal;
