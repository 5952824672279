import React from "react";
import { List, Icon } from "ui-kit";
import { useNavigate } from "react-router-dom";
import { arrays } from "utils";
import { honeygold, Spacing } from "ui-kit";
import styled from "styled-components";
import { getGroupSub } from "areas/planner/utils/group";
import { groupRoutes } from "areas/planner/plannerRoutes";

const ListWrapper = styled.div`
  padding-bottom: ${Spacing.Default}px;
`;

const IconWrapper = styled.div`
  .icon:hover {
    color: ${honeygold};
  }
`;

const StudentClassList = ({
  studentClasses,
  title,
  onClickRemoveGroup,
  showAddGroup,
  addGroupText,
  handleAddGroupClick,
  isExpanded,
  onClickArrow,
  showNumberOfGroups,
}) => {
  const navigate = useNavigate();

  const handleGroupClicked = group => {
    navigate(groupRoutes.getGroupPath(group.id, group.type));
  };

  const onClickRemove = (event, groupId, groupName) => {
    event.stopPropagation();
    onClickRemoveGroup?.(groupId, groupName);
  };

  return !arrays.isEmpty(studentClasses) || showAddGroup ? (
    <ListWrapper>
      <List
        className="student-class-list"
        title={`${title}${
          showNumberOfGroups ? ` (${studentClasses.length})` : ""
        }`}
        isExpanded={isExpanded}
        onClickAccordionArrow={onClickArrow}
      >
        {studentClasses.map((group, index) => (
          <List.Item
            onClick={() => handleGroupClicked(group)}
            key={index}
            text={group.friendlyName}
            sub={getGroupSub(group)}
            right={
              onClickRemoveGroup && (
                <IconWrapper>
                  <Icon
                    value="times"
                    onClick={event =>
                      onClickRemove(event, group.id, group.name)
                    }
                  />
                </IconWrapper>
              )
            }
          />
        ))}
        {showAddGroup && (
          <List.Item
            icon="plus"
            text={addGroupText}
            transparent
            onClick={handleAddGroupClick}
          />
        )}
      </List>
    </ListWrapper>
  ) : null;
};

export default StudentClassList;
