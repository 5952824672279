import React from "react";
import styled from "styled-components";
import WhiteboardClassLayoutGrid from "./whiteboardClassLayoutGrid";
import {
  Button,
  ActionBar,
  Right,
  neutral,
  BorderRadius,
  fontSize,
  Label,
  Spacing,
  typescale,
} from "ui-kit";
import { ClassLayoutStudentView, ClassLayoutView, RoomLayoutView } from "areas/planner/types/classLayoutResponse.types";
import { StudentListView, UserTinyView } from "types/users/userListViews.types";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .button {
    background: ${neutral[700]};
    color: white;
  }

  .action-bar {
    background: unset;
    border: unset;
  }
`;

const SeatingPlanMsgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  .label {
    border-radius: ${BorderRadius.Default}px;
    padding: ${Spacing.Default}px ${Spacing.ExtraLarge}px;
    margin-bottom: ${Spacing.Large}px;
    background: rgba(255, 255, 255, 0.5);
    ${fontSize(typescale.header3)}
  }
`;


interface IWhiteboardClassLayoutWrapperProps {
  studentsInClass: StudentListView[];
  room: RoomLayoutView;
  layoutDirection: number,
  classLayout: ClassLayoutView;
  handleOpenBehaviourModal: (students: UserTinyView[]) => void;
  selectedStudents: UserTinyView[];
  setSelectedStudents: (students: UserTinyView[]) => void;
}


const WhiteboardClassLayoutWrapper: React.FC<IWhiteboardClassLayoutWrapperProps> = ({
  studentsInClass,
  room,
  layoutDirection,
  classLayout,
  handleOpenBehaviourModal,
  selectedStudents,
  setSelectedStudents,
}) => {

  const handleSelectStudent = (student: ClassLayoutStudentView) => {
    const tempMultipleStudents = selectedStudents.slice();
    const index = tempMultipleStudents.findIndex(
      selectedStudent => selectedStudent.id === student.id
    );

    if (index === -1) {
      tempMultipleStudents.push(student);
    } else if (index > -1) {
      tempMultipleStudents.splice(index, 1);
    }
    setSelectedStudents(tempMultipleStudents);
  };

  const handleSelectAllStudents = () => {
    const tempSelectedStudents: UserTinyView[] = [];
    studentsInClass.forEach(student => {
      tempSelectedStudents.push(student);
    });
    setSelectedStudents(tempSelectedStudents);
  };

  const isLayoutEmpty = () => {
    if (
      classLayout.studentSlots.every(
        studentSlot => studentSlot.student === null
      )
    ) {
      return true;
    }
    return false;
  };

  return classLayout ? (
    isLayoutEmpty() ? (
      <SeatingPlanMsgWrapper>
        <Label bold>
          There are no students in the seating plan. Have you saved your seating
          plan?
        </Label>
      </SeatingPlanMsgWrapper>
    ) : (
      <Wrapper>
        <WhiteboardClassLayoutGrid
          room={room}
          layoutDirection={layoutDirection}
          classLayout={classLayout}
          handleOpenBehaviourModal={handleOpenBehaviourModal}
          handleSelectStudent={handleSelectStudent}
          selectedStudents={selectedStudents}
        />
        <ActionBar>
          <Right>
            {selectedStudents.length === 0 ? (
              <>
                <Button
                  text="Select All Students"
                  onClick={handleSelectAllStudents}
                />
              </>
            ) : (
              selectedStudents.length > 0 && (
                <>
                  <Button
                    text="Deselect All Students"
                    onClick={() => setSelectedStudents([])}
                  />

                  <Button
                    text="Add Point"
                    onClick={() => handleOpenBehaviourModal(selectedStudents)}
                  />
                </>
              )
            )}
          </Right>
        </ActionBar>
      </Wrapper>
    )
  ) : (
    <SeatingPlanMsgWrapper>
      <Label bold>Create a seating plan to see your class here</Label>
    </SeatingPlanMsgWrapper>
  );
};

export default WhiteboardClassLayoutWrapper;
