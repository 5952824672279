import React from "react";
import PropTypes from "prop-types";
import RoomLayoutGridCell from "./roomLayoutGridCell";

const RoomLayoutGridRow = ({
  row,
  colsArray,
  roomSlots,
  setRoomSlots,
  preview
}) => {
  return (
    <div className={`room-row-${row}`}>
      {colsArray.map(col => (
        <RoomLayoutGridCell
          key={col}
          row={row}
          col={col}
          roomSlots={roomSlots}
          setRoomSlots={setRoomSlots}
          preview={preview}
        />
      ))}
    </div>
  );
};

RoomLayoutGridRow.propTypes = {
  row: PropTypes.number,
  colsArray: PropTypes.array,
  roomSlots: PropTypes.array,
  setRoomSlots: PropTypes.func,
  preview: PropTypes.bool
};

export default RoomLayoutGridRow;
