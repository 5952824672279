import defaults from "reducers/defaults";
import studentNoteActions from "../../actions/student/studentNoteActions";

const INITIAL_STATE = {
  studentNotes: [],
  paging: defaults.DEFAULT_PAGING,
  refresh: false,
  loading: false,
  error: null,
};

const { CREATESTUDENTNOTE, DELETESTUDENTNOTE, GETSTUDENTNOTES } =
  studentNoteActions.types;

const studentNotesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GETSTUDENTNOTES.START:
      return { ...state, loading: true, error: null };

    case GETSTUDENTNOTES.SUCCESS:
      return {
        ...state,
        studentNotes: action.payload,
        refresh: false,
        loading: false,
      };

    case GETSTUDENTNOTES.FAILED:
      return {
        ...state,
        loading: false,
        refresh: false,
        error: action.payload,
      };

    case CREATESTUDENTNOTE.SUCCESS:
    case DELETESTUDENTNOTE.SUCCESS:
      return { ...state, refresh: true };

    default:
      return state;
  }
};

export default studentNotesReducer;
