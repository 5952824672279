import customGroupsReducer from "./customGroupsReducer";
import groupListsReducer from "./groupListsReducer";
import groupsReducer from "./groupsReducer";

const groupsReducers = {
  customGroups: customGroupsReducer,
  groupLists: groupListsReducer,
  groups: groupsReducer,
};

export default groupsReducers;
