import { IBeehiveAction } from "types/common/action.types";
import timetableActions from "../actions/timetableActions";
import { IBeehiveError } from "types/common/errors.types";
import { TermListView } from "areas/send/types/passportResponse.types";


interface ITermsState {
  terms: TermListView[];
  academicYearId: number;
  error: IBeehiveError;
  loading: boolean;
}

const INITIAL_STATE : ITermsState = {
  terms: [],
  academicYearId: null,
  error: null,
  loading: false,
};


const termsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ITermsState => {

  const { TIMETABLE_GETTERMS } = timetableActions.types;

  switch (action.type) {
    case TIMETABLE_GETTERMS.START:
      return {
        ...state,
        terms: [],
        academicYearId: action.payload,
        loading: true,
        error: null,
      };

    case TIMETABLE_GETTERMS.SUCCESS:
      return { ...state, terms: action.payload, loading: false };

    case TIMETABLE_GETTERMS.FAILED:
      return {
        ...state,
        academicYearId: null,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default termsReducer;
