import React, { useEffect, useState } from "react";
import { arrays } from "utils";
import { useSelector } from "react-redux";
import { Loader, Size, EmptyMessage, Subtitle, Card, CircleIcon, Swatches, Sub, Button } from "ui-kit";
import FormCategoryList, { FormCategoryWrapper } from "./formCategoryList";
import FormEditorModal from "./formEditorModal";
import formCategoriesActions from "areas/forms/actions/formCategoriesActions";
import { RootState } from "reducers/store";
import { FormCategoryListView, FormListView } from "../types/formResponse.types";
import { AssociateChip, FormListWrapper } from "./formListItem";
import { faClipboardUser } from "@fortawesome/pro-solid-svg-icons";
import { FormStaffType } from "../types/formShared.types";
import { useNavigate } from "react-router";
import routes from "configuration/routes";
import { formsRoutes } from "../formsRoutes";


const FormCategories = () => {

  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { categories, loading, error } = useSelector((state: RootState) => state.formCategories);

  const [selectedForm, setSelectedForm] = useState<FormListView | null>(null);

  const loadCategories = () => {
    formCategoriesActions.getCategories();
  };

  useEffect(() => {
    if (arrays.isEmpty(categories)) {
      loadCategories();
    }
  }, []);

  const handleGoToForm = (form: FormListView) => {
    setSelectedForm(form);
  };

  const handleFormEditorModalClosed = () => {
    setSelectedForm(null);
  };


  const Content = ({ categories } : { categories: FormCategoryListView[] }) => {

    if (loading) {
      return <Loader size={Size.Large} cover />;
    }

    if (error) {
      return (
        <EmptyMessage
          icon="times-circle"
          title="A problem occurred"
          summary="There was a problem while loading the forms"
          cover
        />
      );
    }

    if (arrays.isEmpty(categories)) {
      return (
        <EmptyMessage
          icon="paste"
          title="No Forms"
          summary="You do not currently have any forms available"
          cover
        />
      );
    }

    return (
      <>
        {categories.map((category: FormCategoryListView, index: number) => (
          <FormCategoryList
            key={index}
            category={category}
            onGoToForm={handleGoToForm}
          />
        ))}

      <>
        <Subtitle text={"Declarations"} />
        <FormCategoryWrapper>
          <Card
            className="form-list-item"
            onClick={() => navigate(`${routes.home}${formsRoutes.default}${formsRoutes.declarations}`)}
          >
            <Card.Body>
              <FormListWrapper>
                <CircleIcon
                  value={faClipboardUser}
                  size={Size.Large}
                  outline
                  color={Swatches.Low.swatch}
                />
                <span className="form-item-name">Declarations of Interest</span>
                <AssociateChip staffType={FormStaffType.All} />
                <Sub className="form-item-summary">This form is for declaring any interests outside of the Trust</Sub>
                <Button
                  text={"Complete Form"}
                  color={Swatches.Primary}
                  onClick={e => navigate(`${routes.home}${formsRoutes.default}${formsRoutes.declarations}`)}
                  fluid
                  size={Size.Small}
                />
              </FormListWrapper>
            </Card.Body>
          </Card>
        </FormCategoryWrapper>
      </>

      </>
    );
  };


  return (
    <>
      <Content categories={categories} />
      <FormEditorModal
        form={selectedForm}
        open={selectedForm != null}
        onClose={handleFormEditorModalClosed}
      />
    </>
  );
};

export default FormCategories;
