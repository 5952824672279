import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ClassLayoutStudent from "./classLayoutStudent";
import { Constants } from "configuration";
import flyoutActions from "actions/ui/flyouts";
import studentActions from "areas/planner/actions/student/studentActions";
import Draggable from "sharedComponents/common/draggable/draggable";

const ClassLayoutStudentWrapper = ({
  student,
  onGrid,
  onDragStart,
  removeStudent,
  selectedStudents,
  handleSelectStudent,
  maxFlagsAndReadingAge,
}) => {
  const { filteredStudents } = useSelector(
    state => state.classLayoutStudentFilter
  );
  const { isPrivate } = useSelector(state => state.privacy);
  const { selected: selectedStudent } = useSelector(state => state.student);
  const { displayDetentionTodayStudents } = useSelector(
    state => state.classLayoutStudentFilter
  );

  const [filtered, setFiltered] = useState(false);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (selectedStudents) {
      const studentInArray = selectedStudents.find(
        stu => stu.id === student.id
      );

      if (studentInArray !== undefined) {
        setSelected(true);
      } else {
        setSelected(false);
      }
    }
  }, [student, selectedStudents]);

  useEffect(() => {
    setFiltered(false);
    if (filteredStudents) {
      const studentfound = filteredStudents.find(
        filteredStudent => filteredStudent.studentId === student.id
      );
      if (studentfound) {
        setFiltered(true);
      }
    } else {
      setFiltered(false);
    }
  }, [filteredStudents, student]);

  const handleDoubleClick = () => {
    if (selectedStudent !== student.id) {
      flyoutActions.openFlyout(Constants.FLYOUTS.STUDENT);
      studentActions.selectStudent(student.id);
    }
  };

  return (
    <Draggable
      id={student.id}
      onDragStart={onDragStart}
      onDragEnd={event =>
        event.target.firstChild.classList.remove("hide-cross")
      }
      handleDoubleClick={handleDoubleClick}
      handleClick={() => handleSelectStudent(student)}
    >
      <ClassLayoutStudent
        student={student}
        onGrid={onGrid}
        onDragStart={onDragStart}
        removeStudent={removeStudent}
        filtered={filtered}
        detentionToday={displayDetentionTodayStudents && student.todayDetention}
        activeOnCall={student.activeOnCall}
        selected={selected}
        hideSensitive={isPrivate.sensitiveInfo}
        hideProfileImage={isPrivate.profileImage}
        maxFlagsAndReadingAge={maxFlagsAndReadingAge}
      />
    </Draggable>
  );
};

ClassLayoutStudentWrapper.propTypes = {
  student: PropTypes.object,
  onGrid: PropTypes.bool,
  onDragStart: PropTypes.func,
  removeStudent: PropTypes.func,
};

export default ClassLayoutStudentWrapper;
