import React from "react";
import PropTypes from "prop-types";
import LayoutGeneratorDisperseSlider from "./layoutGeneratorDisperseSlider";
import { Size, Spacing, BorderRadius } from "ui-kit/common/size";
import styled from "styled-components";
import { neutral, honeygold } from "ui-kit/common/colors";
import { DetailLabel, RadioList, getFontSize } from "ui-kit";
import {
  ASC,
  DESC,
} from "areas/planner/constants/classViewer/classViewerLayout";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${Spacing.Medium}px;
  margin-bottom: ${Spacing.Medium}px;
  background: ${neutral[200]};
  border-radius: ${BorderRadius.Default}px;

  .class-layout-option-details {
    width: 100%;

    .radio-list {
      margin-top: ${Spacing.Medium}px;

      .radio-list-item {
        .icon {
          font-size: ${getFontSize(Size.Medium)}rem;
        }

        &:hover {
          background: ${neutral[600]};
          color: ${neutral[100]};

          .icon {
            color: ${neutral[100]};
          }
        }
      }
    }
  }

  .choose-layout-button {
    min-width: max-content;
    align-self: center;
  }

  &:hover {
    cursor: pointer;
    background: ${neutral[600]};

    .class-layout-option-details {
      .label,
      .sub {
        color: ${neutral[100]};
      }
    }
  }

  &.selected {
    cursor: pointer;
    background: ${honeygold};

    .class-layout-option-details {
      .label,
      .sub {
        color: ${neutral[800]};
      }

      .radio-list .radio-list-item-selected {
        background: ${neutral[600]};
      }
    }

    .auto-layout-slider {
      .disperse-slider {
        .slider-levels,
        .slider-labels {
          color: ${neutral[800]};
        }

        .slider {
          background: ${neutral[200]};
          &::-webkit-slider-thumb {
            background: ${neutral[200]};
          }
        }
      }
    }
  }
`;

const LayoutGeneratorOption = ({
  layout,
  selectedLayoutId,
  onSelectLayoutType,
  orderValue,
  handleOrderChange,
  disperseValue,
  handleDisperseChange,
}) => {
  return (
    // (selectedLayoutId === null || selectedLayoutId === layout.key) && (
    <Wrapper
      className={`class-layout-option ${
        selectedLayoutId === layout.key ? "selected" : ""
      }`}
      onClick={() => onSelectLayoutType(layout.key)}
    >
      <div className="class-layout-option-details">
        <DetailLabel label={layout.name} sub={layout.description} bold />

        {selectedLayoutId === layout.key && layout.order && (
          <RadioList
            value={orderValue}
            onChange={value => handleOrderChange(value)}
          >
            <RadioList.Item
              icon="sort-alpha-down"
              name="ascDesc"
              value={ASC.key}
            />
            <RadioList.Item
              icon="sort-alpha-down-alt"
              name="ascDesc"
              value={DESC.key}
            />
          </RadioList>
        )}

        {selectedLayoutId === layout.key && layout.disperse && (
          <LayoutGeneratorDisperseSlider
            value={disperseValue}
            handleDisperseChange={handleDisperseChange}
          />
        )}
      </div>
    </Wrapper>
  );
};

LayoutGeneratorOption.propTypes = {
  layout: PropTypes.object,
  selectedLayoutId: PropTypes.number,
  onSelectLayoutType: PropTypes.func,
  orderValue: PropTypes.number,
  disperseValue: PropTypes.number,
  setOrderValue: PropTypes.func,
  setDisperseValue: PropTypes.func,
};

export default LayoutGeneratorOption;
