import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  EmptyMessage,
  DetailLabel,
  BorderRadius,
  Spacing,
  honeygold,
  Speed,
  neutral,
  Chip,
  Sub,
  Swatches,
  Icon,
} from "ui-kit";
import Moment from "react-moment";
import { users, arrays } from "utils";
import assignmentActions from "areas/planner/actions/assignments/assignmentActions";
import UserName from "sharedComponents/common/users/userName";
import { AssignmentTinyView } from "areas/planner/types/assignments/assignmentResponse.types";
import { useAppSelector } from "reducers/hooks";

const ListWrapper = styled.div``;

const ListItemWrapper = styled.li<{ isRead?: boolean, selected?: boolean }>`
  display: flex;
  padding: ${Spacing.Large}px;
  border-radius: ${BorderRadius.Default}px;
  background: ${neutral[100]};
  margin-bottom: ${Spacing.Default}px;
  border-left: 4px solid ${neutral[300]};
  align-items: center;
  justify-content: space-between;

  &:hover {
    transition: all ease ${Speed.Default};
    cursor: pointer;
    background: ${neutral[300]};
  }
  ${({ isRead }) =>
    isRead
      ? `  border-left: 4px solid ${neutral[300]};
  `
      : `  border-left: 4px solid ${honeygold};
  `}

  ${({ selected }) =>
    selected &&
    `
    border-left-color: ${honeygold};
    background: ${neutral[300]};
    color: ${neutral[100]} !important;
    &:hover {
        cursor: default;
    }
    `}

  .assignment-item-description {
    flex-grow: 1;
    max-width: 220px;

    .chip-list {
      margin-top: ${Spacing.Default}px;
    }

    span {
      display: block;
    }
  }
  .assignment-item-deadline {
    i {
      margin-right: ${Spacing.Small}px;
    }
  }

  .assignment-item-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .late-chip {
    margin-left: auto;
    margin-bottom: ${Spacing.Default}px;
  }
`;

const Deadline = styled.div`
  display: flex;
  align-items: center;

  .icon {
    margin-right: ${Spacing.Small}px;
    color: ${neutral[600]};
  }
  .sub {
    color: ${neutral[600]};
  }
`;


interface IAssignmentListProps {
  assignments: AssignmentTinyView[];
  loading?: boolean;
  onPage?: () => void;
  pagesAvailable?: boolean;
}


const AssignmentList: React.FC<IAssignmentListProps> = ({ assignments, loading, onPage, pagesAvailable }) => {

  const { selected } = useAppSelector(state => state.assignment);

  const onItemClicked = ({ id, isRead } : AssignmentTinyView) => {
    if (selected != id) {
      assignmentActions.selectAssignment(id);
    }

    // Disabled because it was saturating the network. If reenabled, fix issue where this resets the selected assignment
    // if (!isRead && users.isStudent(user)) {
    //   assignmentActions.markAssignmentRead(id, user.id);
    // }
  };

  if (!loading && arrays.isEmpty(assignments)) {
    return <EmptyMessage icon="briefcase" title="No assignments found" cover />;
  }

  return (
    <ListWrapper className="assignment-list">
      {assignments?.map(assignment => (
        <AssignmentListItem
          assignment={assignment}
          selected={selected === assignment.id}
          key={assignment.id}
          onClick={onItemClicked}
        />
      ))}
    </ListWrapper>
  );
};


interface IAssignmentListItemProps {
  assignment: AssignmentTinyView;
  onClick: (assignment: AssignmentTinyView) => void;
  selected: boolean;
}

const AssignmentListItem: React.FC<IAssignmentListItemProps> = ({ assignment, onClick, selected }) => {

  const handleClick = () => {
    onClick?.(assignment);
  };

  return (
    <ListItemWrapper
      onClick={handleClick}
      selected={selected}
      isRead={assignment.isRead}
    >
      <div className="assignment-item-description">
        {/* <StaffView>
          <DetailLabel
            label={assignment.title}
            sub={
              assignment.deadline != null &&
              `Due ${moment(assignment.deadline).format("dddd, MMMM Do YYYY")}`
            }
            bold
          />
          <GroupList groups={assignment.groups} />
        </StaffView> */}

        {/* <NonStaffView> */}
        <DetailLabel
          label={assignment.title}
          sub={<UserName user={assignment.setBy} />}
          bold
        />
        {/* </NonStaffView> */}
      </div>

      <div className="assignment-item-right">
        {/* <StaffView>
          {assignment.isExpired ? (
            <Chip
              text="Expired"
              colorSwatch={Swatches.Low}
              tooltip="Assignment Expired"
              tooltipSub={
                assignment.deadline != null &&
                `The deadline for this assignment passed on ${moment(
                  assignment.deadline
                ).format("DD/MM/YYYY")}`
              }
            />
          ) : assignment.isScheduled ? (
            <Chip
              text="Scheduled"
              colorSwatch={Swatches.Warning}
              tooltip="Scheduled Assignment"
              tooltipSub={`This assignment is published but not visible to students until ${moment(
                assignment.visibleFrom
              ).format("DD/MM/YYYY")}`}
            />
          ) : (
            <PublishStatusChip status={assignment.publishStatus} />
          )}
        </StaffView> */}

        {/* <NonStaffView> */}
        {assignment.isOverdue && (
          <Chip
            text="Late"
            className="late-chip"
            colorSwatch={Swatches.Danger}
          />
        )}
        {assignment.isComplete ? (
          <Chip text="Completed" colorSwatch={Swatches.Success} />
        ) : (
          assignment.deadline !== null && (
            <Deadline>
              <Icon value="clock" />
              <Sub>
                <Moment date={assignment.deadline} format="DD/MM/YYYY" />
              </Sub>
            </Deadline>
          )
        )}
        {/* </NonStaffView> */}
      </div>
    </ListItemWrapper>
  );
};

export default AssignmentList;
