import React, { useEffect } from "react";
import { useAction } from "hooks";
import { useSelector } from "react-redux";
import paymentActions from "areas/payments/actions/paymentsActions";
import OrdersTable from "../orders/ordersTable";
import { RootState } from "reducers/store";
import { PaymentUser } from "areas/payments/reducers/payments/paymentsUserReducer";


interface IUserOrdersProps {
  user: PaymentUser;
}


const UserOrders: React.FC<IUserOrdersProps> = ({ user }) => {

  const { loading, error, orders } = useSelector((state: RootState) => state.userOrders);

  useEffect(() => {
    user && paymentActions.getOrdersForUser(user.id);
  }, [user]);

  return <OrdersTable orders={orders} loading={loading} error={error != null} />;
};

export default UserOrders;
