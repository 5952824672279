import { IBeehiveAction } from "types/common/action.types";
import provisionsActions from "../../actions/provisions/provisionsActions";

interface IProvisionState {
  deleting: boolean;
  deleteError: string | null;
}

const INITIAL_STATE: IProvisionState = {
  deleting: false,
  deleteError: null,
};


const provisionReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IProvisionState => {

  const { PROVISIONS_DELETE } = provisionsActions.types;

  switch (action.type) {
    case PROVISIONS_DELETE.START:
      return { ...state, deleteError: null, deleting: true };
    case PROVISIONS_DELETE.SUCCESS:
      return { ...state, deleting: false };
    case PROVISIONS_DELETE.FAILED:
      return {
        ...state,
        deleting: false,
        deleteError:
          "There was a problem deleting the provision. This may be because this provision is in use.",
      };
    default:
      return state;
  }
};

export default provisionReducer;
