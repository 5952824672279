import action from "actions/action";
import { ToastService } from "ui-kit";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { urls } from "../../../../utils";

const builder = new actionTypeBuilder("classRoom");

const types = {
  GETROOM: builder.build("getroom"),
  CREATEROOMLAYOUT: builder.build("createroomlayout"),
  DELETEROOMLAYOUT: builder.build("deleteroomlayout"),
  GETROOMLAYOUTS: builder.build("getroomlayouts"),
  GETROOMLAYOUT: builder.build("getroomlayout"),
};

const getRoom = (schoolId, roomId, onSuccess) => {
  return action(
    () => client.get(`planner/schools/${schoolId}/rooms/${roomId}`),
    types.GETROOM,
    onSuccess
  );
};

const createRoomLayout = (schoolId, roomLayout, onSuccess) => {
  return action(
    () =>
      client.post(`planner/schools/${schoolId}/createroomlayout`, roomLayout),
    types.CREATEROOMLAYOUT,
    response => {
      ToastService.pop(
        "Room layout created successfully",
        null,
        "check-circle"
      );
      if (onSuccess) {
        onSuccess(response);
      }
    }
  );
};

const deleteRoomLayout = (schoolId, roomId, onSuccess) => {
  return action(
    () =>
      client.delete(`planner/schools/${schoolId}/rooms/${roomId}/roomlayout`),
    types.DELETEROOMLAYOUT,
    response => {
      ToastService.pop(
        "Room layout successfully deleted",
        null,
        "check-circle"
      );
      if (onSuccess) {
        onSuccess(response);
      }
    }
  );
};

const getRoomLayouts = (schoolId, roomId, onSuccess) => {
  const url = new urls.QueryString(`planner/schools/${schoolId}/roomLayouts`);

  url.addParam("roomId", roomId);

  return action(() => client.get(url.toUrl()), types.GETROOMLAYOUTS, onSuccess);
};

const getRoomLayout = (schoolId, roomId, roomLayoutId, onSuccess) => { //RoomLayoutView[]
  const url = new urls.QueryString(`planner/schools/${schoolId}/roomLayouts`);

  url.addParam("roomId", roomId);
  url.addParam("roomLayoutId", roomLayoutId);

  return action(() => client.get(url.toUrl()), types.GETROOMLAYOUT, onSuccess);
};

const classRoomActions = {
  types,
  getRoom,
  createRoomLayout,
  deleteRoomLayout,
  getRoomLayouts,
  getRoomLayout,
};

export default classRoomActions;
