import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import FormCategories from "./formCategories";
import AdminDashboard from "./admin/adminDashboard";
import { TabBar } from "ui-kit";
import { RoleView } from "sharedComponents/common";
import { Constants } from "configuration";
import { formsRoutes } from "../formsRoutes";
import CustomRoute from "sharedComponents/layout/routes/customRoute";
import routes from "../../../configuration/routes";
import UserDeclarations from "./declarations/userDeclarations";
import { useAppSelector } from "reducers/hooks";
import { users } from "utils";
import Reporting from "./reporting";
import DeclarationsAdministration from "./declarations/declarationsAdministration";


const Index = () => {

  const navigate = useNavigate();
  const { user } = useAppSelector(state => state.currentUser);

  useEffect(() => {
    navigate(`${routes.home}${formsRoutes.default}${formsRoutes.categories}`);
  }, []);

  const handleTabClicked = (name: string) => {
    navigate(`${routes.home}${formsRoutes.default}/${name}`);
  };

  return (
    <>
      <TabBar onItemClicked={handleTabClicked}>

        <TabBar.Item 
          key={0} 
          name="categories" 
          text="Forms" 
        />
        
        <TabBar.Item 
          key={1} 
          name="admin" 
          text="Administration" 
          hide={!users.isInAnyRoles(user, [
            Constants.ROLES.FINANCE_USER,
            Constants.ROLES.IT_ADMINISTRATOR,
            Constants.ROLES.DEVELOPMENT
          ])}
        />

        <TabBar.Item 
          key={2} 
          name="declarationsAdmin" 
          text="Declarations Administration" 
          hide={!users.isInAnyRoles(user, [
            Constants.ROLES.DATAPROTECTION_ADMINISTRATOR,
            Constants.ROLES.IT_ADMINISTRATOR,
            Constants.ROLES.DEVELOPMENT
          ])}
        />

        <TabBar.Item 
          key={3} 
          name="reporting" 
          text="Reporting" 
          hide={!users.isInAnyRoles(user, [
            Constants.ROLES.DATAPROTECTION_ADMINISTRATOR,
            Constants.ROLES.FINANCE_USER,
            Constants.ROLES.IT_ADMINISTRATOR,
            Constants.ROLES.DEVELOPMENT
          ])}
        />

      </TabBar>

      <Routes>
        <Route
          path={formsRoutes.categories}
          element={
            <CustomRoute>
              <FormCategories />
            </CustomRoute>
          }
        />

        <Route
          path={formsRoutes.declarations}
          element={
            <CustomRoute>
              <UserDeclarations />
            </CustomRoute>
          }
        />

        <Route
          path={formsRoutes.declarationsAdmin}
          element={
            <CustomRoute roles={[
              Constants.ROLES.DATAPROTECTION_ADMINISTRATOR, 
              Constants.ROLES.IT_ADMINISTRATOR, 
              Constants.ROLES.DEVELOPMENT
            ]}>
              <DeclarationsAdministration />
            </CustomRoute>
          }
        />

        <Route
          path={formsRoutes.admin}
          element={
            <CustomRoute roles={[Constants.ROLES.FINANCE_USER]}>
              <AdminDashboard />
            </CustomRoute>
          }
        />

        <Route
          path={formsRoutes.reporting}
          element={
            <CustomRoute roles={[
              Constants.ROLES.FINANCE_USER, 
              Constants.ROLES.DATAPROTECTION_ADMINISTRATOR, 
              Constants.ROLES.IT_ADMINISTRATOR, 
              Constants.ROLES.DEVELOPMENT
            ]}>
              <Reporting />
            </CustomRoute>
          }
        />

      </Routes>
    </>
  );
};

export default Index;
