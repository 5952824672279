import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Size,
  EmptyMessage,
  SplitButton,
  Swatches,
  Table,
  Loader,
  DetailLabel,
} from "ui-kit";
import { arrays } from "utils";
import { ApprovalStatusChip, SchoolIcon } from "sharedComponents/common";
import bulletinActions from "areas/news/actions/bulletinActions";
import { bulletinRoutes } from "areas/news/newsRoutes";
import { RootState } from "reducers/store";
import { BulletinListView } from "areas/news/types/newsResponse.types";


const Bulletins = () => {

  const navigate = useNavigate();
  const { bulletins, loading, error } = useSelector((state: RootState) => state.bulletins);

  useEffect(() => {
    bulletinActions.getBulletins();
  }, []);

  const handleCreateBulletin = () => {
    navigate(bulletinRoutes.getNewPath());
  };

  const handleEditBulletin = (bulletin: BulletinListView) => {
    navigate(bulletinRoutes.getEditPath(bulletin.id));
  };

  const handleViewBulletin = (bulletin: BulletinListView) => {
    navigate(bulletinRoutes.getBulletinPath(bulletin.id));
  };

  if (loading) {
    return <Loader cover size={Size.Medium} />;
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem loading the bulletins"
        cover
      />
    );
  }

  if (arrays.isEmpty(bulletins)) {
    return (
      <EmptyMessage
        icon="megaphone"
        title="No Bulletins Found"
        summary="No bulletins have been created yet"
        cover
      >
        <Button
          size={Size.Small}
          text="Create Bulletin"
          color={Swatches.Primary}
          onClick={handleCreateBulletin}
        />
      </EmptyMessage>
    );
  }

  return (
    <Table>
      <Table.Header>
        <Table.HeaderCell width={1.25}>Status</Table.HeaderCell>
        <Table.HeaderCell width={0.5} />
        <Table.HeaderCell width={5.5}>Name</Table.HeaderCell>
        <Table.HeaderCell width={1} right>
          Sends
        </Table.HeaderCell>
        <Table.HeaderCell width={1.75} />
      </Table.Header>
      <Table.Body>
        {bulletins.map((bulletin: BulletinListView, index: number) => (
          <Table.Row key={index}>
            <Table.Cell>
              <ApprovalStatusChip status={bulletin.status} fluid />
            </Table.Cell>
            <Table.Cell icon>
              <SchoolIcon code={bulletin.sender.code} />
            </Table.Cell>
            <Table.Cell>
              <DetailLabel
                label={bulletin.name}
                sub={bulletin.sender.name}
                bold
              />
            </Table.Cell>
            <Table.Cell right>{bulletin.sendCount}</Table.Cell>
            <Table.Cell right>
              <SplitButton
                size={Size.Small}
                text="View"
                color={Swatches.Default}
                onDefaultClick={() => handleViewBulletin(bulletin)}
              >
                <SplitButton.Option
                  onClick={() => handleViewBulletin(bulletin)}
                  text="View"
                  show={true}
                />
                <SplitButton.Option
                  onClick={() => handleEditBulletin(bulletin)}
                  text="Edit"
                  show={true}
                />
              </SplitButton>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.Cell colspan={5} right>
            <Button
              size={Size.Small}
              text="Create Bulletin"
              color={Swatches.Primary}
              onClick={handleCreateBulletin}
            />
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default Bulletins;
