import React, { useState, useEffect } from "react";
import { StructuredList, TextInput, TextInputType, Card, Label } from "ui-kit";

const LeavingLessonForm = ({ selectedReason, leavingLessonData, onChange }) => {
  const [_leavingLessonData, _setLeavingLessonData] = useState({
    notes: "",
  });

  useEffect(() => {
    _setLeavingLessonData({
      ...leavingLessonData,
      notes: leavingLessonData.notes,
    });
  }, [leavingLessonData]);

  const handleChange = (field, notes) => {
    const tempLeavingLessonData = _leavingLessonData;
    tempLeavingLessonData[field] = notes;
    _setLeavingLessonData(tempLeavingLessonData);
    onChange?.(tempLeavingLessonData);
  };

  return (
    <Card>
      <Card.Body>
        <StructuredList>
          <StructuredList.Item name="Reason for Leaving Lesson">
            <Label>{selectedReason.name}</Label>
          </StructuredList.Item>
          <StructuredList.Item name="Notes">
            <TextInput
              type={TextInputType.Textarea}
              value={_leavingLessonData.notes}
              onChange={value => handleChange("notes", value)}
              fluid
            />
          </StructuredList.Item>
        </StructuredList>
      </Card.Body>
    </Card>
  );
};

export default LeavingLessonForm;
