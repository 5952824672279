import { AreaOfNeed } from "areas/send/types/passportShared.types";
import React, { useState, useEffect } from "react";
import { Dropdown } from "ui-kit";

interface IAreaOfNeedDropdownProps {
  value?: AreaOfNeed;
  onChange?: (value: number) => void;
  fluid?: boolean;
  placeholder?: string;
  includeAllOption?: boolean;
}

interface areaOfNeedOption {
  key?: number;
  label: string;
  value: AreaOfNeed | -1;
  sub?: string;
}


const ALL_OPTION: areaOfNeedOption = {
  key: -1,
  label: "All Areas of Need",
  value: -1,
  sub: "LHT",
};

const OPTIONS: areaOfNeedOption[] = [
  { label: "Cognition & Learning", value: AreaOfNeed.CognitionAndLearning },
  { label: "Communication & Interaction", value: AreaOfNeed.CommunicationAndInteraction },
  { label: "Sensory &/or Physical", value: AreaOfNeed.Sensory },
  { label: "SEMH", value: AreaOfNeed.SEMH }
];


const AreaOfNeedDropdown: React.FC<IAreaOfNeedDropdownProps> = ({
  value,
  onChange,
  fluid,
  placeholder,
  includeAllOption,
}) => {

  const [_options, _setOptions] = useState<areaOfNeedOption[]>(OPTIONS);

  useEffect(() => {
    includeAllOption && _setOptions([ALL_OPTION, ...OPTIONS]);
  }, [includeAllOption]);

  return (
    <Dropdown
      placeholder={
        placeholder ? placeholder : "Please choose an area of need..."
      }
      fluid={fluid}
      value={value}
      onChange={onChange}
    >
      {_options.map((opt: areaOfNeedOption, index: number) => (
        <Dropdown.Item key={index} label={opt.label} value={opt.value} />
      ))}
    </Dropdown>
  );
};

export default AreaOfNeedDropdown;
