import { CalendarEventDateListView } from "areas/calendar/types/calendarResponses.types";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { strings } from "utils";



interface IRemainingPlacesProps {
  date: CalendarEventDateListView;
}


const RemainingPlaces: React.FC<IRemainingPlacesProps> = ({ date }) => {

  return date.signupLimit > 0 && moment().isBefore(date.starts) ? (
    <>
      <b>{date.signupLimit - date.signups}</b>{" "}
      {strings.pluralize("place", "places", date.signupLimit - date.signups)}{" "}
      left
    </>
  ) 
  : (
    <></>
  );
}


export default RemainingPlaces;