import { IBeehiveAction } from "types/common/action.types";
import calendarActions from "../actions/calendarActions";
import { PublishStatus } from "types/common/status.types";


export interface CalendarEventsFilter {
  status?: PublishStatus | null;
  startDate?: Date | null;
  endDate?: Date | null;
  schoolId?: number | null;
  categoryId?: number | null;
  signedUp?: boolean | null;
  eventOwner?: string | null;
  searchTerm?: string | null;
  academicYearId?: number | null;
}

interface ICalendarEventFilterState {
  loading: boolean;
  error: string | null;
  filter: CalendarEventsFilter,
}

const INITIAL_STATE: ICalendarEventFilterState = {
  loading: false,
  error: null,
  filter: {
    schoolId: -1,
    categoryId: -1,
    eventOwner: null,
    status: 2,
  },
};

const calendarEventsFilterReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ICalendarEventFilterState => {
  
  const { CALENDAR_EVENTS_UPDATEFILTER } = calendarActions.types;

  switch (action.type) {
    case CALENDAR_EVENTS_UPDATEFILTER:
      return {
        ...state,
        filter: action.payload,
      };

    default:
      return state;
  }
};

export default calendarEventsFilterReducer;
