import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  typescale,
  displayFont,
  neutral,
  honeygold,
  CircleIcon,
  Spacing,
  Size,
  fontStyle,
  Speed
} from "ui-kit";
import { Link, Path } from "react-router-dom";
import { RootState } from "reducers/store";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

const Wrapper = styled.li<{selected: boolean}>`
  margin-bottom: ${Spacing.Default}px;

  a {
    display: flex;
    flex-grow: 1;
    align-items: center;
    color: ${neutral[500]};

    .circle-icon {
      background: ${neutral[400]};
      i {
        color: ${neutral[100]};
      }
    }

    &:hover {
      .circle-icon {
        transition: all ${Speed.Short}ms ease;
        background: ${neutral[700]};
      }
    }

    ${({ selected }) =>
      selected &&
      `
      .circle-icon {
        transition: all ${Speed.Short}ms ease;
        background: ${honeygold};

        i { color: ${neutral[100]};}
      }
    `}

    .navigation-item-label {
      padding-right: ${Spacing.Medium}px;
      margin-left: ${Spacing.Default}px;
      ${fontStyle(displayFont.medium, typescale.paragraph, neutral[600])}
    }
  }
`;

interface INavigationItemProps {
  name: string;
  route: string | Partial<Path>;
  icon: string | IconDefinition;
  onClick?: () => void;
}


const NavigationItem = React.forwardRef(({ name, route, icon, onClick }: INavigationItemProps, ref) => {

    const { title } = useSelector((state: RootState) => state.navigation);

    const handleClick = () => {
      onClick?.();
    };

    return (
      <Wrapper selected={title === name} ref={ref as React.RefObject<HTMLLIElement>} onClick={handleClick}>
        <Link to={route}>
          <CircleIcon value={icon} size={Size.Medium} />
          <span className="navigation-item-label">{name}</span>
        </Link>
      </Wrapper>
    );
  }
);

export default NavigationItem;
