import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import { BehaviourCategoryGroupByListView } from "areas/behaviour/types/behaviourResponses.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


interface IRewardCategoriesState {
  rewardCategoryGroups: BehaviourCategoryGroupByListView[];
  schoolId: number;
  loading: boolean;
  getRewardsError: IBeehiveError;
}

const INITIAL_STATE: IRewardCategoriesState = {
  rewardCategoryGroups: [],
  schoolId: null,
  loading: false,
  getRewardsError: null,
};

const rewardCategoriesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IRewardCategoriesState => {

  const { GETREWARDCATEGORIES, SETREWARDSCHOOLID } = rewardActions.types;
  
  switch (action.type) {
    case GETREWARDCATEGORIES.START:
      return { ...state, loading: true, getRewardsError: null };

    case GETREWARDCATEGORIES.SUCCESS:
      return { ...state, loading: false, rewardCategoryGroups: action.payload };

    case GETREWARDCATEGORIES.FAILED:
      return { ...state, loading: false, getRewardsError: action.payload };

    case SETREWARDSCHOOLID:
      return { ...state, schoolId: action.payload };

    default:
      return state;
  }
};

export default rewardCategoriesReducer;
