import calendarActions from "../actions/calendarActions";
import { IBeehiveAction } from "types/common/action.types";
import { WallPlannerEventView, WallplannerListView } from "../types/calendarResponses.types";
import { IBeehiveError } from "types/common/errors.types";
import moment from "moment";


export interface WallPlannerSelectedSessionFilter {
  category: number,
  month: number
}

export interface WallPlannerSessionsFilter {
  academicYear: number;
}

interface IWallPlannerSessionsState {
  loading: boolean;
  error: IBeehiveError;
  exporting: boolean;
  exportError: IBeehiveError;
  sessions: WallplannerListView[];
  selectedSession: {
    filter: WallPlannerSelectedSessionFilter,
    events: WallPlannerEventView[];
  },
  filter: WallPlannerSessionsFilter,
  selectedEvent: {
    working: boolean;
    error: string | null;
  }
}

const INITIAL_STATE: IWallPlannerSessionsState = {
  loading: false,
  error: null,
  exporting: false,
  exportError: null,
  sessions: [],
  selectedSession: {
    filter: {
      category: null,
      month: null
    },
    events: []
  },
  filter: {
    academicYear: 11
  },
  selectedEvent: {
    working: false,
    error: null
  }
};

const wallPlannerSessionsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IWallPlannerSessionsState => {
  const {
    WALLPLANNER_UPDATESESSIONFILTER,
    WALLPLANNER_GETSESSIONS,
    WALLPLANNER_GETEVENTS,
    WALLPLANNER_UPDATEEVENTSFILTER,
    WALLPLANNER_CREATEEVENT,
    WALLPLANNER_UPDATEEVENT,
    WALLPLANNER_DELETEEVENT,
    WALLPLANNER_GETPREVIEW
  } = calendarActions.types;

  switch (action.type) {

    case WALLPLANNER_UPDATESESSIONFILTER:
      return { ...state, filter: action.payload };


    case WALLPLANNER_GETSESSIONS.START:
      return { ...state, loading: true };

    case WALLPLANNER_GETSESSIONS.SUCCESS:
      return { ...state, loading: false, sessions: action.payload };

    case WALLPLANNER_GETSESSIONS.FAILED:
      return { ...state, loading: false, error: action.payload };


    case WALLPLANNER_GETEVENTS.START:
      return { ...state, loading: true };

    case WALLPLANNER_GETEVENTS.SUCCESS:
      return { ...state, loading: false, selectedSession: { ...state.selectedSession, events: action.payload } };
      
    case WALLPLANNER_GETEVENTS.FAILED:
      return { ...state, loading: false, error: action.payload };


    case WALLPLANNER_UPDATEEVENTSFILTER:
      return { ...state, selectedSession: { ...state.selectedSession, filter: action.payload } };


    case WALLPLANNER_CREATEEVENT.START:
    case WALLPLANNER_UPDATEEVENT.START:
      return { ...state, selectedEvent: { ...state.selectedEvent, working: true } };

    case WALLPLANNER_CREATEEVENT.SUCCESS:
      return { 
        ...state,
        selectedSession: { 
          ...state.selectedSession, 
          events: ([...state.selectedSession.events, action.payload] as WallPlannerEventView[])
            .sort((a, b) => moment(a.startDate).unix() - moment(b.startDate).unix())
        },
        selectedEvent: { 
          ...state.selectedEvent, 
          working: false 
        } 
      };

    case WALLPLANNER_UPDATEEVENT.SUCCESS:
      return { 
        ...state,
        selectedSession: { 
          ...state.selectedSession, 
          events: state.selectedSession.events.map(wpevent =>
            wpevent.id === action.payload.id
            ? action.payload
            : wpevent
          )
          .sort((a, b) => moment(a.startDate).unix() - moment(b.startDate).unix())
        },
        selectedEvent: { 
          ...state.selectedEvent, 
          working: false 
        } 
      };

    case WALLPLANNER_CREATEEVENT.FAILED:
    case WALLPLANNER_UPDATEEVENT.FAILED:
      return { ...state, selectedEvent: { ...state.selectedEvent, working: false, error: action.payload } };

    
    case WALLPLANNER_DELETEEVENT.START:
      return { ...state, selectedEvent: { ...state.selectedEvent, working: true } };

    case WALLPLANNER_DELETEEVENT.SUCCESS:
      return { 
        ...state, 
        selectedSession: { ...state.selectedSession, events: state.selectedSession.events.filter(x => x.id != action.payload) },
        selectedEvent: { ...state.selectedEvent, working: false }
      };

    case WALLPLANNER_DELETEEVENT.FAILED:
      return { ...state, selectedEvent: { ...state.selectedEvent, working: false, error: action.payload } };

    case WALLPLANNER_GETPREVIEW.START:
      return {
        ...state,
        exporting: true,
        exportError: null
      }
    case WALLPLANNER_GETPREVIEW.SUCCESS:
      return {
        ...state,
        exporting: false
      }
    case WALLPLANNER_GETPREVIEW.FAILED:
      return {
        ...state,
        exporting: false,
        exportError: action.payload
      }


    default:
      return state;
  }
};

export default wallPlannerSessionsReducer;
