import defaults from "reducers/defaults";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import detentionRegisterActions from "../../actions/detentions/detentionRegisterActions";
import { IBeehiveAction } from "types/common/action.types";
import { PageInfo } from "types/common/paging.types";
import { IBeehiveError } from "types/common/errors.types";
import { DetentionStudentView } from "areas/behaviour/types/detentionRegisterResponse.types";


interface IMissedDetentionsState {
  missedDetentions: DetentionStudentView[],
  paging: PageInfo;
  loading: boolean;
  loadingExport: boolean;
  refresh: boolean;
  error: IBeehiveError;
  exportError: boolean;
}

const INITIAL_STATE: IMissedDetentionsState = {
  missedDetentions: [],
  paging: defaults.DEFAULT_PAGING,
  loading: false,
  loadingExport: false,
  refresh: false,
  error: null,
  exportError: null,
};

const {
  GETMISSEDDETENTIONS,
  REMOVEDETENTIONFROMMISSEDLIST,
  EXPORTMISSEDDETENTIONS,
} = detentionRegisterActions.types;

const { MISSEDDETENTION, CANCELDETENTION } = detentionActions.types;

const { REVOKEBEHAVIOUR } = behaviourActions.types;

const missedDetentionsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IMissedDetentionsState => {
  switch (action.type) {
    case GETMISSEDDETENTIONS.START:
      return { ...state, loading: true, error: null };

    case GETMISSEDDETENTIONS.SUCCESS:
      return {
        ...state,
        loading: false,
        refresh: false,
        missedDetentions:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.missedDetentions, ...action.payload.items],
        paging: action.payload.paging,
      };

    case GETMISSEDDETENTIONS.FAILED:
      return {
        ...state,
        loading: false,
        refresh: false,
        error: action.payload,
      };

    case MISSEDDETENTION.SUCCESS:
    case CANCELDETENTION.SUCCESS:
    case REVOKEBEHAVIOUR.SUCCESS:
      return { ...state, refresh: true };

    case REMOVEDETENTIONFROMMISSEDLIST:
      return {
        ...state,
        missedDetentions: state.missedDetentions.filter(
          detention => detention.detentionId !== action.payload
        ),
      };

    case EXPORTMISSEDDETENTIONS.START:
      return { ...state, loadingExport: true };

    case EXPORTMISSEDDETENTIONS.SUCCESS:
      return { ...state, loadingExport: false };

    case EXPORTMISSEDDETENTIONS.FAILED:
      return { ...state, loadingExport: false, exportError: action.payload };

    default:
      return state;
  }
};

export default missedDetentionsReducer;
