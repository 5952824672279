import React from "react";
import PropTypes from "prop-types";
import {
  Icon,
  Size,
  Swatches,
  Spacing,
  BorderRadius,
  fontStyle,
  displayFont,
  typescale,
} from "ui-kit";
import styled from "styled-components";
import { neutral, honeygold } from "ui-kit/common/colors";

const Wrapper = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  width: max-content;
  background-color: ${neutral[600]};
  border-radius: ${BorderRadius.Default}px;
  line-height: 100%;
  padding: ${Spacing.Small}px ${Spacing.Small}px;
  margin: 0 ${Spacing.Small}px;
  align-items: center;
  ${fontStyle(displayFont.medium, typescale.paragraph, neutral[100])};

  ${({ disabled }) =>
    disabled &&
    `
    .delete-selected-item {
      display: none;
    }
  `}

  &:hover {
    cursor: pointer;
    background-color: ${honeygold};
    color: ${neutral[800]};
  }

  .delete-selected-item {
    line-height: 0;
    .icon {
      margin-top: 0;
      margin-left: ${Spacing.Small + 4}px;
    }
  }
`;

interface ISelectedItemProps {
  item: any;
  handleDeleteItem: (item: any) => void;
  disabled?: boolean;
}

const SelectedItem: React.FC<ISelectedItemProps> = ({
  item,
  handleDeleteItem,
  disabled,
}) => {
  return (
    <Wrapper
      className="selected-item"
      onClick={disabled ? null : () => handleDeleteItem(item)}
      disabled={disabled}
    >
      {item.title}

      <div className="delete-selected-item">
        <Icon value="times" color={Swatches.Default.swatch} size={Size.Small} />
      </div>
    </Wrapper>
  );
};

export default SelectedItem;
