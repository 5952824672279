import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Size,
  Swatches,
  Button,
  Loader,
  typescale,
  displayFont,
  EmptyMessage,
  Message,
  Currency,
  Spacing,
  neutral,
  List,
  fontStyle,
} from "ui-kit";
import flyoutActions from "actions/ui/flyouts";
import storeActions from "areas/payments/actions/storeActions";
import { useAction } from "hooks";
import { arrays } from "utils";
import BasketItem from "./basketItem";
import { RootState } from "reducers/store";
import { BasketItemView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


const Wrapper = styled.div`
  .basket-error {
    margin-bottom: ${Spacing.Default}px;
  }
`;

const Total = styled.div`
  text-align: right;
  padding: ${Spacing.Medium}px ${Spacing.Small}px 0 0;

  span:first-child {
    ${fontStyle(displayFont.roman, typescale.header2, neutral[500])};
  }
  span:last-child {
    ${fontStyle(displayFont.medium, typescale.header2, neutral[600])};
  }
`;


interface IBasketProps {
  hideErrors?: boolean;
  title?: string;
}


const Basket: React.FC<IBasketProps> = ({ hideErrors, title }) => {

  const navigate = useNavigate();
  var { basket, working, errors, refresh } = useSelector((state: RootState) => state.basket);
  var { user } = useSelector((state: RootState) => state.currentUser);

  useEffect(() => {
    user && storeActions.getBasket(user.id);
  }, [user]);

  useEffect(() => {
    refresh && storeActions.getBasket(user.id);
  }, [refresh]);

  const handleGoToStore = () => {
    flyoutActions.closeFlyout();
    navigate(`/main/store/products`);
  };

  const handleItemDelete = (item: BasketItemView) => {
    storeActions.getBasket(user.id);
  };

  if (errors.load) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem loading your basket"
        cover
      />
    );
  }

  if (working) {
    return <Loader cover={true} size={Size.Medium} />;
  }

  if (!basket || arrays.isEmpty(basket.items)) {
    return (
      <>
        <EmptyMessage
          icon="shopping-bag"
          title="Basket Empty"
          summary="There are no items in your basket"
          cover
        >
          <Button
            text="Go to Store"
            onClick={handleGoToStore}
            size={Size.Small}
          />
        </EmptyMessage>
      </>
    );
  }

  return (
    <Wrapper>
      {!hideErrors && errors.item && (
        <Message
          className="basket-error"
          text={errors.item}
          icon="times-circle"
          color={Swatches.Danger}
        />
      )}
      <List title={title}>
        {basket.items.map((item: BasketItemView, index: number) => {
          return (
            <BasketItem
              key={index}
              userId={user.id}
              item={item}
              onDelete={() => handleItemDelete(item)}
              working={working}
            />
          );
        })}
      </List>
      <Total>
        <span>Total</span> <Currency value={basket.total} />
      </Total>
    </Wrapper>
  );
};

export default Basket;
