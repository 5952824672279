import React from "react";
import FormCategoryDropdown from "../filters/formCategoryDropdown";
import FormTypeDropdown from "../filters/formTypeDropdown";
import FormStatusDropdown from "../filters/formStatusDropdown";
import FinanceUserDropdown from "../filters/financeUserDropdown";
import { SchoolDropdown, StaffPicker, StaffSearch, StaffSelector, YearGroupDropdown } from "sharedComponents/common";
import { useSelector } from "react-redux";
import { TextInput, ActionBar, TextInputType, Button, Size, displayFont, fontStyle, honeygold, neutral, typescale, Flyout, Card, Checkbox, CheckboxList, FilterButton, Orientation, Swatches } from "ui-kit";
import submissionFilterActions from "areas/forms/actions/submissionFilterActions";
import { RootState } from "reducers/store";
import { FormCategoryListView, FormListView } from "areas/forms/types/formResponse.types";
import flyoutActions from "actions/ui/flyouts";
import { Constants } from "configuration";
import styled from "styled-components";
import { StaffListView } from "types/users/userListViews.types";


const MoreFiltersButton = styled.div`
  .button {
    background: unset;
    padding-left: 0;
    padding-right: 0;

    .button-content {
      ${fontStyle(displayFont.medium, typescale.paragraph, neutral[500])}

      .icon {
        font-size: unset;

        svg {
          color: ${neutral[500]};
        }
      }
    }

    &:hover {
      .button-content {
        color: ${honeygold};

        .icon svg {
          color: ${honeygold};
        }
      }
    }
  }
`;


const AdminSubmissionFilter = () => {
  
  const { filter } = useSelector((state: RootState) => state.submissionFilter);

  const handleSchoolChange = (schoolId: number) => {
    submissionFilterActions.filterBySchool(schoolId);
  };

  const handleUserChange = (userId: string) => {
    submissionFilterActions.filterByFormUser(userId);
  };

  const handlerAuthoriserChange = (authoriser: StaffListView) => {
    submissionFilterActions.filterByAuthoriser(authoriser);
  };

  const handlerSubmitterChange = (submitter: StaffListView) => {
    submissionFilterActions.filterBySubmitter(submitter);
  };

  const handleCategoryChange = (cat: FormCategoryListView) => {
    submissionFilterActions.filterByCategory(cat ? cat : null);
  };

  const handleStatusChange = (status: number) => {
    submissionFilterActions.filterByFormStatus(status);
  };

  const handleTypeChange = (formType: FormListView) => {
    submissionFilterActions.filterByFormType(formType ? formType : null);
  };

  const handleSearchTermChange = (searchTerm: string) => {
    submissionFilterActions.filterBySearchTerm(searchTerm);
  };

  return (
    <>
      <ActionBar>
        <ActionBar.Filter>
        
          <ActionBar.FilterItem>
            <SchoolDropdown
              onChange={handleSchoolChange}
              initialValue={filter.schoolId}
              includeAllOption
              includeTrust
              fluid
            />
          </ActionBar.FilterItem>

          <ActionBar.FilterItem>
            <FormCategoryDropdown
              filter={filter}
              onChange={handleCategoryChange}
              initialValue={filter.category}
              fluid
            />
          </ActionBar.FilterItem>

          <ActionBar.FilterItem>
            <FormStatusDropdown
              onChange={handleStatusChange}
              initialValue={filter.formStatus}
              fluid
            />
          </ActionBar.FilterItem>

          <ActionBar.FilterItem>
            <FormTypeDropdown
              onChange={handleTypeChange}
              initialValue={filter.formType}
              selectedCategory={filter.category}
              fluid
            />
          </ActionBar.FilterItem>

          <ActionBar.FilterItem>
            <TextInput
              icon="search"
              type={TextInputType.String}
              onChange={handleSearchTermChange}
              placeholder="Search Submissions..."
              value={filter.searchTerm}
              debounce
              fluid
            />
          </ActionBar.FilterItem>
          
          <ActionBar.FilterItem>
            <MoreFiltersButton>
              <Button
                text="More Filters"
                onClick={() => flyoutActions.openFlyout(Constants.FLYOUTS.FORM_FILTERING)}
                size={Size.Small}
                icon="filter"
              />
            </MoreFiltersButton>
          </ActionBar.FilterItem>

        </ActionBar.Filter>
      </ActionBar>

      <Flyout
        title="Filters"
        name={Constants.FLYOUTS.FORM_FILTERING}
        // onClose={() => setBehaviour(null)}
      >
        <Flyout.Body>

          <Card title={"Staff"} >
            <Card.Body>

              <ActionBar.FilterItem title="Finance User">
                <FinanceUserDropdown
                  onChange={handleUserChange}
                  initialValue={filter.userId}
                  includeAllOption
                  fluid
                />
              </ActionBar.FilterItem>

              <br />

              <ActionBar.FilterItem title="Authoriser">
                <StaffSelector
                  user={filter.authoriser}
                  onChange={handlerAuthoriserChange}
                  onCancel={() => submissionFilterActions.filterByAuthoriser(null)}
                />
              </ActionBar.FilterItem>

              <br />

              <ActionBar.FilterItem title="Submitter">
                <StaffSelector
                  user={filter.submitter}
                  onChange={handlerSubmitterChange}
                  onCancel={() => submissionFilterActions.filterBySubmitter(null)}
                />
              </ActionBar.FilterItem>


            </Card.Body>
          </Card>

        </Flyout.Body>

        <Flyout.Footer>
          <Button
            color={Swatches.Success}
            text="Filter Submissions"
            onClick={() => {}}
            size={Size.Small}
            fluid
          />
        </Flyout.Footer>
      </Flyout>
    </>
  );
};

export default AdminSubmissionFilter;
