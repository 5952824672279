import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import pendingAttentionBehaviourActions from "../../actions/behaviour/pendingAttentionActions";
import { IBeehiveError } from "types/common/errors.types";
import { IBeehiveAction } from "types/common/action.types";
import { BehaviourSummaryListView } from "areas/behaviour/types/behaviourResponses.types";


interface IPendingAttentionBehavioursState {
  selectedBehaviour: BehaviourSummaryListView;
  pendingAttentionBehaviours: BehaviourSummaryListView[];
  loading: boolean;
  error: IBeehiveError;
  refresh: boolean;
  claimBehaviourError: IBeehiveError;
}

const INITIAL_STATE: IPendingAttentionBehavioursState = {
  selectedBehaviour: null,
  pendingAttentionBehaviours: [],
  loading: false,
  error: null,
  refresh: false,
  claimBehaviourError: null,
};

const {
  GETPENDINGATTENTIONBEHAVIOURS,
  SELECTBEHAVIOUR,
  CLAIMPENDINGATTENTIONBEHAVIOUR,
  AUTHORISEPENDINGBEHAVIOUR,
} = pendingAttentionBehaviourActions.types;

const { REVOKEBEHAVIOUR } = behaviourActions.types;

const pendingAttentionBehavioursReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IPendingAttentionBehavioursState => {

  switch (action.type) {
    case GETPENDINGATTENTIONBEHAVIOURS.START:
      return { ...state, loading: true, error: null };

    case GETPENDINGATTENTIONBEHAVIOURS.SUCCESS:
      return {
        ...state,
        loading: false,
        refresh: false,
        pendingAttentionBehaviours: action.payload,
      };

    case GETPENDINGATTENTIONBEHAVIOURS.FAILED:
      return {
        ...state,
        loading: false,
        refresh: false,
        error: action.payload,
      };

    case CLAIMPENDINGATTENTIONBEHAVIOUR.START:
      return { ...state, loading: true, claimBehaviourError: null };

    case CLAIMPENDINGATTENTIONBEHAVIOUR.SUCCESS:
      return {
        ...state,
        loading: false,
        refresh: true,
      };

    case CLAIMPENDINGATTENTIONBEHAVIOUR.FAILED:
      return { ...state, loading: false, claimBehaviourError: action.payload };

    case SELECTBEHAVIOUR:
      return { ...state, selectedBehaviour: action.payload };

    case AUTHORISEPENDINGBEHAVIOUR.SUCCESS:
    case REVOKEBEHAVIOUR.SUCCESS:
      return {
        ...state,
        loading: false,
        refresh: true,
      };

    default:
      return state;
  }
};

export default pendingAttentionBehavioursReducer;
