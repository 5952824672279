import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Card, Icon, Size, Spacing } from "ui-kit";

const DietaryKeyWrapper = styled.ul`
  list-style-type: none;
  display: flex;
  margin: 0 !important;
  padding: 0;

  >li {
    display: flex; 
    align-items: center;
    margin-right: ${Spacing.Default}px;

    .icon {
      margin-right: ${Spacing.Small}px;
    }
  }
`

interface IDietartIconLegendProps {
}


const DietartIconLegend: React.FC<IDietartIconLegendProps> = ({}) => {

  return (
    <>
      <Card title="Menu Items Dietary Key">
        <Card.Body>
          <DietaryKeyWrapper>
            <li>
              <Icon
                className="menu-icon"
                value="leaf"
                size={Size.Small}
                tooltip="Vegetarian"
                tooltipSub="This dish is suitable for vegetarians. It does not contain meat or fish but may contain dairy products."
              />
              <p>Vegetarian</p>
            </li>
            <li>
              <Icon
                className="menu-icon"
                value="seedling"
                size={Size.Small}
                tooltip="Vegan"
                tooltipSub="This dish is suitable for vegans and does not contain meat, fish or dairy products."
              />
              <p>Vegan</p>
            </li>
            <li>
              <Icon
                className="menu-icon"
                value="star-and-crescent"
                size={Size.Small}
                tooltip="Halal"
                tooltipSub="This dish contains Halal meat."
              />
              <p>Halal</p>
            </li>
            <li>
              <Icon
                className="menu-icon"
                value="exclamation-triangle"
                size={Size.Small}
                tooltip="Allergy"
                tooltipSub="This dish is intended for people with allergies."
              />
              <p>Allergy</p>
            </li>
          </DietaryKeyWrapper>
        </Card.Body>
      </Card>
    </>
  );
}


export default DietartIconLegend;