import { createContext, createRef, useContext } from "react";
import styled from "styled-components";
import { formatScrollbars, neutral } from "ui-kit";


const MainWrapper = styled.div<{
  verticalOffset?: number;
  navigationOffset?: number;
}>`
  display: flex;
  position: absolute;
  flex-direction: column;
  flex-grow: 1;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${neutral[200]};
  overflow-y: auto;
  overscroll-behavior: none;
  content-sizing: box-sizing;

  ${({ verticalOffset }) => verticalOffset && `top: ${verticalOffset}px;`}

  ${({ navigationOffset }) =>
    navigationOffset && `left: ${navigationOffset}px;`}
    ${formatScrollbars(neutral[200])}
`;


interface IScrollContext {
  scrollToTop: () => void;
}
const ScrollContext = createContext<IScrollContext>(null);
const scrollRef = createRef<HTMLDivElement>();

export const MainWrapperProvider = ({ verticalOffset, navigationOffset, children } : { verticalOffset: number, navigationOffset: number, children: any }) => {
  return (
    <ScrollContext.Provider value={{
      scrollToTop: () => scrollRef.current?.scrollTo({top: 0, left: 0, behavior: 'smooth' })
    }}>
      <MainWrapper verticalOffset={verticalOffset} navigationOffset={navigationOffset} ref={scrollRef}>
        { children }
      </MainWrapper>
    </ScrollContext.Provider>
  );
};

export const useScroll = () => useContext(ScrollContext);