import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ManagePendingAttentionFormWrapper from "./managePendingAttentionFormWrapper";
import { useSelector } from "react-redux";
import { Loader, Size } from "ui-kit";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import StudentModal from "sharedComponents/common/users/studentModal";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { useAppSelector } from "reducers/hooks";
import { BehaviourSummaryListView, BehaviourView } from "areas/behaviour/types/behaviourResponses.types";


interface IManagePendingAttentionModalProps {
  open: boolean;
  closeModal: () => void;
  behaviourDetails: BehaviourSummaryListView;
  handleRevokeBehaviour: (behaviour: BehaviourSummaryListView) => void;
  schoolId: number;
}


const ManagePendingAttentionModal: React.FC<IManagePendingAttentionModalProps> = ({
  open,
  closeModal,
  behaviourDetails,
  handleRevokeBehaviour,
  schoolId,
}) => {

  const { behaviour, loading, updateBehaviourError } = useAppSelector(state => state.behaviour);

  const [selectedBehaviour, setSelectedBehaviour] = useState<BehaviourView>(null);

  useEffect(() => {
    if (behaviour) {
      setSelectedBehaviour(behaviour.behaviour);
    }
  }, [behaviour]);

  useEffect(() => {
    if (behaviourDetails && open) {
      behaviourActions.getBehaviour(
        behaviourDetails.student.id,
        behaviourDetails.behaviourId
      );
    }
  }, [behaviourDetails, open]);

  const handleCloseModal = () => {
    setSelectedBehaviour(null);
    closeModal?.();
  };

  return (
    <StudentModal
      title="Manage Behaviour"
      onClose={handleCloseModal}
      open={open}
      height="90%"
      width="75%"
      student={behaviourDetails && behaviourDetails.student}
    >
      <>
        {loading ? (
          <Loader size={Size.Medium} fluid />
        ) : updateBehaviourError ? (
          <ApiExceptionMessage error={updateBehaviourError} />
        ) : (
          behaviourDetails &&
          selectedBehaviour && (
            <ManagePendingAttentionFormWrapper
              student={behaviourDetails.student}
              schoolId={schoolId}
              behaviour={selectedBehaviour}
              closeModal={handleCloseModal}
              handleRevokeBehaviour={() =>
                handleRevokeBehaviour(behaviourDetails)
              }
            />
          )
        )}
      </>
    </StudentModal>
  );
};


export default ManagePendingAttentionModal;
