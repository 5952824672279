import { LeaveRequestType } from "areas/humanResources/types/leaveShared.types";
import React, { useState, useEffect } from "react";
import Dropdown, { IDropdownDataItemProps } from "ui-kit/forms/dropdown";



interface ILeaveRequestTypeDropdownProps {
  value?: LeaveRequestType;
  onChange: (value: LeaveRequestType, label: string) => void;
  includeAll?: boolean;
  fluid?: boolean;
}


const LeaveRequestTypeDropdown: React.FC<ILeaveRequestTypeDropdownProps> = ({ value, onChange, includeAll, fluid }) => {

  const [leaveTypes] = useState<IDropdownDataItemProps<string, LeaveRequestType, null>[]>(() => {
    var opts = (Object.keys(LeaveRequestType) as Array<keyof typeof LeaveRequestType>)
      .filter(leaveType => isNaN(Number(leaveType)))
      .map(leaveType => ({
        label: leaveType,
        value: LeaveRequestType[leaveType]
      }))
    return includeAll ? [ { label: "All", value: null }, ...opts ] : opts;
  });

  return (
    <>
      <Dropdown
        value={value}
        onChange={onChange}
        items={leaveTypes}
        fluid={fluid}
      />
    </>
  );
}


export default LeaveRequestTypeDropdown;