import React, { useState } from "react";
import {
  StructuredList,
  Card,
  Dropdown,
  ActionBar,
  Button,
  Swatches,
  Size,
} from "ui-kit";
import DateRange from "../dateRange";
import {
  GROUP_BY_ARRAY,
} from "areas/analytics/constants/assignmentReportConstants";
import Search, {
  SEARCH_GROUP_TYPE_KEYS,
} from "sharedComponents/common/search/search";
import { DateRange as DRT } from "types/common/data.types";
import { SearchResultViewBase } from "types/common/views.types";
import { AssignmentGroupBy } from "areas/analytics/types/assignmentAnalyticsShared.types";


interface IAssignmentAnalyticsFormProps {
  handleGenerateReport: (
    setDateRange: DRT,
    deadlineDateRange: DRT,
    filterStaff: SearchResultViewBase[],
    filterClassGroups: SearchResultViewBase[],
    filterTutorGroups: SearchResultViewBase[],
    filterCustomGroups: SearchResultViewBase[],
    filterYearGroups: SearchResultViewBase[],
    filterSubjects: SearchResultViewBase[],
    groupById: AssignmentGroupBy,
    exportReport?: boolean
  ) => void,
  loadingExportReport?: boolean;
}


const AssignmentAnalyticsForm: React.FC<IAssignmentAnalyticsFormProps> = ({
  handleGenerateReport,
  loadingExportReport,
}) => {

  const [setDateRange, setSetDateRange] = useState<DRT>({
    startDate: null,
    endDate: null,
  });
  const [deadlineDateRange, setDeadlineDateRange] = useState<DRT>({
    startDate: null,
    endDate: null,
  });
  const [filterStaff, setFilterStaff] = useState<SearchResultViewBase[]>([]);
  const [filterClassGroups, setFilterClassGroups] = useState<SearchResultViewBase[]>([]);
  const [filterTutorGroups, setFilterTutorGroups] = useState<SearchResultViewBase[]>([]);
  const [filterCustomGroups, setFilterCustomGroups] = useState<SearchResultViewBase[]>([]);
  const [filterYearGroups, setFilterYearGroups] = useState<SearchResultViewBase[]>([]);
  const [filterSubjects, setFilterSubjects] = useState<SearchResultViewBase[]>([]);
  const [groupById, setGroupById] = useState<AssignmentGroupBy>(AssignmentGroupBy.None);

  const selectFilter = (filterArray: SearchResultViewBase[], filter: SearchResultViewBase) => {
    const tempSelectedFilters = filterArray.slice();
    tempSelectedFilters.push(filter);
    return tempSelectedFilters;
  };

  const removeFilter = (filterArray: SearchResultViewBase[], filter: SearchResultViewBase) => {
    const tempSelectedFilters = filterArray.slice();
    const index = tempSelectedFilters.findIndex(
      selectedFilter => selectedFilter.id === filter.id
    );
    tempSelectedFilters.splice(index, 1);

    return tempSelectedFilters;
  };

  return (
    <>
      <StructuredList>
        <Card title="Assignment Set Date Range">
          <Card.Body>
            <DateRange
              startDate={setDateRange.startDate}
              handleStartDateChange={value =>
                setSetDateRange({
                  ...setDateRange,
                  startDate: value,
                })
              }
              endDate={setDateRange.endDate}
              handleEndDateChange={value =>
                setSetDateRange({
                  ...setDateRange,
                  endDate: value,
                })
              }
              required
            />
          </Card.Body>
        </Card>

        <Card title="Deadline Date Range">
          <Card.Body>
            <DateRange
              startDate={deadlineDateRange.startDate}
              handleStartDateChange={value =>
                setDeadlineDateRange({
                  ...deadlineDateRange,
                  startDate: value ? value : null,
                })
              }
              endDate={deadlineDateRange.endDate}
              handleEndDateChange={value =>
                setDeadlineDateRange({
                  ...deadlineDateRange,
                  endDate: value ? value : null,
                })
              }
            />
          </Card.Body>
        </Card>

        <Card title="Filters">
          <Card.Body>
            <StructuredList.Item
              name="Staff"
              description="Filter to generate a report for only selected staff"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.TEACHER]}
                allowMultiple
                selected={filterStaff}
                handleSelectItem={filter =>
                  setFilterStaff(selectFilter(filterStaff, filter))
                }
                handleDeleteItem={filter =>
                  setFilterStaff(removeFilter(filterStaff, filter))
                }
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Class Group"
              description="Filter to generate a report for only selected class groups"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.CLASS_GROUP]}
                allowMultiple
                selected={filterClassGroups}
                handleSelectItem={filter =>
                  setFilterClassGroups(selectFilter(filterClassGroups, filter))
                }
                handleDeleteItem={filter =>
                  setFilterClassGroups(removeFilter(filterClassGroups, filter))
                }
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Tutor Group"
              description="Filter to generate a report for only selected tutor groups"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.TUTOR_GROUP]}
                allowMultiple
                selected={filterTutorGroups}
                handleSelectItem={filter =>
                  setFilterTutorGroups(selectFilter(filterTutorGroups, filter))
                }
                handleDeleteItem={filter =>
                  setFilterTutorGroups(removeFilter(filterTutorGroups, filter))
                }
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Custom Group"
              description="Filter to generate a report for only selected custom groups"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.CUSTOM_GROUP]}
                allowMultiple
                selected={filterCustomGroups}
                handleSelectItem={filter =>
                  setFilterCustomGroups(
                    selectFilter(filterCustomGroups, filter)
                  )
                }
                handleDeleteItem={filter =>
                  setFilterCustomGroups(
                    removeFilter(filterCustomGroups, filter)
                  )
                }
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Year Group"
              description="Filter to generate a report for only selected year groups"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.YEAR_GROUP]}
                allowMultiple
                selected={filterYearGroups}
                handleSelectItem={filter =>
                  setFilterYearGroups(selectFilter(filterYearGroups, filter))
                }
                handleDeleteItem={filter =>
                  setFilterYearGroups(removeFilter(filterYearGroups, filter))
                }
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Subject"
              description="Filter to generate a report for only selected subjects"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.SUBJECT]}
                allowMultiple
                selected={filterSubjects}
                handleSelectItem={filter =>
                  setFilterSubjects(selectFilter(filterSubjects, filter))
                }
                handleDeleteItem={filter =>
                  setFilterSubjects(removeFilter(filterSubjects, filter))
                }
              />
            </StructuredList.Item>
          </Card.Body>
        </Card>

        <Card title="Report Breakdown">
          <Card.Body>
            <StructuredList.Item
              name="Breakdown Category"
              description="Choose a category that you want your report to be broken down by"
              helpPopup
            >
              <Dropdown
                placeholder="Category"
                fluid
                items={GROUP_BY_ARRAY}
                onChange={value => setGroupById(value)}
                value={groupById}
              />
            </StructuredList.Item>
          </Card.Body>
        </Card>
      </StructuredList>

      <ActionBar>
        <Button
          text="Generate Report"
          color={Swatches.Success}
          size={Size.Small}
          disabled={
            setDateRange.startDate === null ||
            setDateRange.endDate === null
          }
          onClick={() =>
            handleGenerateReport(
              setDateRange,
              deadlineDateRange,
              filterStaff,
              filterClassGroups,
              filterTutorGroups,
              filterCustomGroups,
              filterYearGroups,
              filterSubjects,
              groupById
            )
          }
        />
        <Button
          color={Swatches.Success}
          text="Export Report as CSV"
          icon="file-download"
          size={Size.Small}
          disabled={
            setDateRange.startDate === null ||
            setDateRange.endDate === null
          }
          onClick={() =>
            handleGenerateReport(
              setDateRange,
              deadlineDateRange,
              filterStaff,
              filterClassGroups,
              filterTutorGroups,
              filterCustomGroups,
              filterYearGroups,
              filterSubjects,
              groupById,
              true
            )
          }
          working={loadingExportReport}
        />
      </ActionBar>
    </>
  );
};

export default AssignmentAnalyticsForm;
