import routes from "configuration/routes";

export const newsdeskRoutes = {
  default: "/newsdesk",
};

export const magazineRoutes = {
  default: "/magazine",
  category: "/magazine/categories/:categoryId",
  article: "/magazine/articles/:articleId",
  getCategoryPath: (id: number) => `${routes.home}/magazine/categories/${id}`,
  getArticlePath: (id: number) => `${routes.home}/magazine/articles/${id}`,
};

export const bulletinRoutes = {
  default: "/bulletins",
  new: "/bulletins/new",
  bulletin: "/bulletins/:id",
  edit: "/bulletins/:id/edit",
  newTemplate: "/bulletins/templates/new",
  template: "/bulletins/templates/:id",
  getNewPath: () => `${routes.home}/newsdesk/bulletins/new`,
  getBulletinPath: (id: number) => `${routes.home}/newsdesk/bulletins/${id}`,
  getEditPath: (id: number) => `${routes.home}/newsdesk/bulletins/${id}/edit`,
};

export const articleRoutes = {
  new: `/categories/:categoryId/articles/new`,
  article: `/categories/:categoryId/articles/:articleId`,
  getArticlePath: (categoryId: number, id: number) =>
    `/main/newsdesk/categories/${categoryId}/articles/${id}`,
  getNewPath: (id: number) => `${routes.home}/newsdesk/categories/${id}/articles/new`,
};
