import React from "react";
import { Checkbox } from "ui-kit";
import SenTypeCheckbox from "./senTypeCheckbox";
import { SEN_TYPES, SenType } from "areas/analytics/constants/behaviourReportConstants";


interface ISenFiltersProps {
  filterBySen: boolean;
  handleFilterbySenClick: () => void;
  selectedSenTypes: SenType[];
  handleSenTypeClick: (senType: SenType, isChecked: boolean) => void;
}

const SenFilters: React.FC<ISenFiltersProps> = ({
  filterBySen,
  handleFilterbySenClick,
  selectedSenTypes,
  handleSenTypeClick,
}) => {
  
  return (
    <>
      <Checkbox
        text={`Filter by SEN`}
        checked={filterBySen}
        onChange={handleFilterbySenClick}
      />
      {filterBySen && (
        <>
          <br />
          {SEN_TYPES.map(type => (
            <SenTypeCheckbox
              senType={type}
              selectedSenTypes={selectedSenTypes}
              handleSenTypeClick={handleSenTypeClick}
            />
          ))}
        </>
      )}
    </>
  );
};

export default SenFilters;
