import React, { useEffect, useState } from "react";
import { StudentModal, Avatar } from "sharedComponents/common";
import {
  Card,
  Chip,
  DetailLabel,
  StructuredList,
  Swatches,
  Table,
} from "ui-kit";
import Moment from "react-moment";
import moment from "moment";
import ConsentStatus from "areas/administration/components/consent/consentStatus";

const StudentConsentResponseModal = ({ form, student, open, onClose }) => {
  return (
    <StudentModal
      student={student}
      title="Consent Response"
      open={open}
      onClose={onClose}
      width="80%"
      height="80%"
    >
      <>
        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="Form">{form.name}</StructuredList.Item>
              <StructuredList.Item name="Consent Given">
                <ConsentStatus status={form.consentGiven} />
              </StructuredList.Item>
              <StructuredList.Item name="Completed By">
                <Avatar
                  user={form.createdBy}
                  sub={
                    <Moment format="DD/MM/YYYY hh:mmA">
                      {form.createdDate}
                    </Moment>
                  }
                />
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>

        <Card title="Responses">
          <Card.Body noPad>
            <Table>
              <Table.Body noHover>
                {form.items.map((item, index) => (
                  <Table.Row key={index}>
                    <Table.Cell width={7}>
                      <DetailLabel bold label={item.title} sub={item.text} />
                    </Table.Cell>
                    <Table.Cell width={1}>{item.code}</Table.Cell>
                    <Table.Cell width={2} right>
                      {item.consentGiven ? (
                        <Chip text={"Yes"} colorSwatch={Swatches.Success} />
                      ) : (
                        <Chip text={"No"} colorSwatch={Swatches.Danger} />
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Card.Body>
        </Card>
      </>
    </StudentModal>
  );
};

export default StudentConsentResponseModal;
