import { useEffect, useState } from "react";
import { DeclarationOfInterestDetailView, DeclarationOfInterestVersionDetailView, InterestView } from "../../types/declarations/declarationsOfInterestResponse.types";
import { ActionBar, Button, Card, Checkbox, DatePicker, Dropdown, EmptyMessage, Flyout, Modal, RichTextEditor, Right, Size, Spacing, StructuredList, Swatches, Table, Tabs, TextInput, ValidationMessage, displayFont, fontStyle, neutral, typescale } from "ui-kit";
import { DeclarationType } from "../../types/declarations/declarationsOfInterestShared.types";
import { FilePicker } from "sharedComponents/common";
import { FileListView } from "types/common/files.types";
import flyoutActions from "actions/ui/flyouts";
import { Constants } from "configuration";
import moment from "moment";
import { arrays } from "utils";
import styled from "styled-components";


const InfoHeader = styled.h4`
  ${fontStyle(displayFont.medium, typescale.header4, neutral[600])}
  margin-bottom: ${Spacing.Large}px;
`


interface IDeclarationEditorModalProps {
  version: DeclarationOfInterestVersionDetailView;
  open: boolean;
  onCancel: () => void;
  onSave?: (declarationVersion: DeclarationOfInterestVersionDetailView, saveAsDraft: boolean) => void;
}

interface InterestViewExt extends InterestView {
  tempId?: number;
}

interface IDeclarationOfInterestVersionDetailViewExt extends DeclarationOfInterestVersionDetailView {
  interests: InterestViewExt[];
}


const DeclarationEditorModal: React.FC<IDeclarationEditorModalProps> = ({ version, open, onCancel, onSave }) => {

  const [_open, _setOpen] = useState<boolean>(false);
  const [declarationVersion, setDeclarationVersion] = useState<IDeclarationOfInterestVersionDetailViewExt>(null);
  const [newInterest, setNewInterest] = useState<InterestViewExt>(null);

  const [interestValidationErrors, setInterestValidationErrors] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    version && setDeclarationVersion(version);
  }, [version]);

  const handleCancel = () => {
    setValidationErrors([]);
    setInterestValidationErrors([]);
    onCancel();
  }

  const handleSave = (saveAsDraft: boolean) => {
    setValidationErrors([]);

    var errors: string[] = [];
    if (declarationVersion.acceptance === false && saveAsDraft === false) {
      errors.push("Cannot submit a declaration that has not been accepted");
    }
    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    setInterestValidationErrors([]);
    onSave(declarationVersion, saveAsDraft);
  }

  const addInterest = () => {
    setNewInterest({
      id: null,
      declarationVersionId: declarationVersion.id,
      declarationType: DeclarationType.Employment,
      organisationName: "",
      organisationNature: "",
      natureOfInterest: "",
      startDate: "",
      endDate: "",
    })
    flyoutActions.openFlyout(Constants.FLYOUTS.DECLARATION_INTERESTS);
  }

  const handleFlyoutSave = () => {
    setInterestValidationErrors([]);
    var errors: string[] = [];

    if (newInterest.declarationType == null) {
      errors.push("Please enter a declaration type");
    }
    if (newInterest.organisationName == null) {
      errors.push("Please enter an organisation name");
    }
    if (newInterest.organisationNature == null) {
      errors.push("Please enter an organisation nature");
    }
    if (newInterest.natureOfInterest == null) {
      errors.push("Please enter a nature of interest");
    }
    if (newInterest.startDate == null) {
      errors.push("Please enter a start date of interest");
    }

    setInterestValidationErrors(errors);
    if (!arrays.isEmpty(errors)) {
      return;
    }

    if (newInterest.id !== null || newInterest.tempId != null) {
      setDeclarationVersion({ 
        ...declarationVersion, 
        interests: declarationVersion.interests.map(x => 
          (x.id === newInterest.id || x.tempId === newInterest.tempId)
            ? newInterest
            : x
        )
      });
    }
    else {
      setDeclarationVersion({ 
        ...declarationVersion, 
        interests: [
          ...declarationVersion.interests, 
          {
            ...newInterest, 
            tempId: Math.floor(Math.random() * 1001) 
          }
        ] 
      });
    }
    setNewInterest(null);
    flyoutActions.closeFlyout();
  }

  const handleFlyoutCancel = () => {
    setNewInterest(null);
    setInterestValidationErrors([]);
    flyoutActions.closeFlyout();
  };

  const getDeclarationTypeFriendlyName = (declarationType: DeclarationType) => {
    switch (declarationType) {
      case DeclarationType.Employment:
        return "Employment";
      case DeclarationType.AppointmentsAndDirectorships:
        return "Appointments and Directorships";
      case DeclarationType.NonprofitMembership:
        return "Nonprofit Membership";
      case DeclarationType.ShareholdingAndInvestment:
        return "Shareholding and Investment";
      case DeclarationType.TrustConnection:
        return "Trust Connection";
      case DeclarationType.Contractual:
        return "Contractual";
      case DeclarationType.Other:
        return "Other";
    }
  }

  const handleEdit = (interest: InterestViewExt, index: number) => {
    setNewInterest(interest);
    flyoutActions.openFlyout(Constants.FLYOUTS.DECLARATION_INTERESTS);
  };

  const handleDelete = (interest: InterestViewExt, index: number) => {
    if (window.confirm("Are you sure you want to delete this interest? This cannot be undone.")) {
      var filtered = declarationVersion.interests.filter(x => 
        JSON.stringify(x) !== JSON.stringify(interest)
      );
      setDeclarationVersion({ ...declarationVersion, interests: filtered });
    }
  };

  if (declarationVersion == null) {
    return (
      <></>
    )
  }

  return (
    <>
     <Modal
      title="Edit Declaration of Interest"
      open={_open}
      onClose={handleCancel}
      width="90%"
      height="90%"
    >
      <Modal.Body>

      <Tabs>
        <Tabs.Pane label="Form">
          <ValidationMessage errors={validationErrors} />

          <Card>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item
                  name="Your Reference"
                  description="You can add an optional reference."
                >
                  <TextInput
                    maxLength={50}
                    fluid
                    characterCount
                    value={declarationVersion.reference}
                    onChange={value => setDeclarationVersion({ ...declarationVersion, reference: value })}
                  />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>

          <Card>
              {declarationVersion.interests.length > 0 ? (
              <Card.Body noPad>
                <Table zebra>
                  <Table.Header>
                    <Table.HeaderCell>Declaration Type</Table.HeaderCell>
                    <Table.HeaderCell>Organisation Name</Table.HeaderCell>
                    <Table.HeaderCell>Organisation Nature</Table.HeaderCell>
                    <Table.HeaderCell>Nature of Interest</Table.HeaderCell>
                    <Table.HeaderCell>Start Date</Table.HeaderCell>
                    <Table.HeaderCell>End Date</Table.HeaderCell>
                    <Table.HeaderCell right></Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    { declarationVersion.interests.map((interest, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>{getDeclarationTypeFriendlyName(interest.declarationType)}</Table.Cell>
                        <Table.Cell>{interest.organisationName}</Table.Cell>
                        <Table.Cell>{interest.organisationNature}</Table.Cell>
                        <Table.Cell>{interest.natureOfInterest}</Table.Cell>
                        <Table.Cell>{interest.startDate}</Table.Cell>
                        <Table.Cell>{interest.endDate}</Table.Cell>
                        <Table.Cell right>
                          <ActionBar low>
                            <Right>
                              <Button
                                icon="pencil"
                                onClick={() => handleEdit(interest, index)}
                                color={Swatches.Low}
                                size={Size.Small}
                              />
                              <Button
                                icon="trash"
                                color={Swatches.Low}
                                onClick={() => handleDelete(interest, index)}
                                size={Size.Small}
                              />
                            </Right>
                          </ActionBar>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.Cell
                        colspan={7}
                        right
                      >
                        <Button
                          text="Add"
                          size={Size.Small}
                          onClick={addInterest}
                          color={Swatches.Primary}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
              </Card.Body>
              )
            : (
              <Card.Body>
                <EmptyMessage
                  icon="paste"
                  title="No interests added"
                  summary={"If you have nothing to declare, please leave this section blank and enter 'NIL' in the notes box below."}
                  cover
                >
                  <Button
                    text="Click to Add"
                    onClick={addInterest}
                    color={Swatches.Primary}
                    size={Size.Small}
                  />
                </EmptyMessage>
              </Card.Body>
            )}
          </Card>

          <Card title="Additional Information">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item
                  name="Upload"
                  description="Please upload any documents that are needed to support this form submission. You can upload .pdf, .docx, .xlsx, .csv, .jpg, .gif, .png or .pptx files."
                >
                  <FilePicker
                    files={declarationVersion.files}
                    onFilesChanged={files => setDeclarationVersion({ ...declarationVersion, files: files as FileListView[] })}
                  />
                </StructuredList.Item>
                <StructuredList.Item
                  name="Notes"
                  description="Please add any explanatory notes or additional information to support this form submission."
                >
                  <RichTextEditor
                    initialValue={declarationVersion.notes}
                    onChange={value => setDeclarationVersion({ ...declarationVersion, notes: value })}
                  />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>

          <Card title="Confirmation">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item
                  name="Acceptance"
                  description="Before submitting your form, you need to accept the terms of this form as described in the guidance. If you are saving a draft, you do not need to accept the terms until you submit the form."
                  required
                >
                  <Checkbox
                    checked={declarationVersion.acceptance}
                    onChange={value => setDeclarationVersion({ ...declarationVersion, acceptance: value.checked })}
                    text={`Please confirm that you have read the Declarations of Interest Form Guidance before submitting the form`}
                  />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>

        </Tabs.Pane>
        <Tabs.Pane label={"Guidance"}>
          <Card>
            <Card.Body>
              <p>By submitting this form, you certify that the information supplied is accurate.</p>
              <InfoHeader>Information about Interest Categories</InfoHeader>
              <p>
                <b>Employment:</b> Current employment, remunerated trade, profession, public office or consultancy and any previous employment in which you continue to have financial interest.<br/>
                <b>Appointments and Directorships of Companies:</b> Directorships of companies, both public and private, both remunerated and unremunerated, appointments e.g trusteeships, local authority membership, tribunals etc.<br/>  
                <b>Membership of Non-Profit Organisations:</b> Membership of any professional bodies, special interest groups, mutual support organisations, clubs or associations, especially those that have significant dealings with the Trust or whose activities could relate to the organisations work.<br/>  
                <b>Shareholding and investment:</b> Names of companies and organisations in which the employee or his/her family or associate controlling interest or shares or securities, and could be involved in the supply of business dealings with the Trust.<br/>  
                <b>Connections within the Trust:</b> This includes close family relationships, commercial interests with other employees, the organisation's lawyers and auditors (if known)<br/>  
                <b>Contractual Interests:</b> Any contractual relationship with the Trust<br/>  
                <b>Other Relevant interests:</b> Any other conflicts that are not covered by the above and which a member of the public might reasonably think significant and relevant.<br/>  
              </p>    
            </Card.Body>
          </Card>
        </Tabs.Pane>
      </Tabs>
      </Modal.Body>

      <Modal.Footer>
        <ActionBar low >
          <Button
            onClick={handleCancel}
            text="Close"
            color={Swatches.Low}
            size={Size.Small}
          />
          <Right>
            <Button
              onClick={() => handleSave(true)}
              text="Save as Draft"
              color={Swatches.Primary}
              size={Size.Small}
            />
            <Button
              onClick={() => handleSave(false)}
              text="Save and Submit"
              color={Swatches.Success}
              size={Size.Small}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>

      <Flyout title="Add Interest" name={Constants.FLYOUTS.DECLARATION_INTERESTS} wide>
        <Flyout.Body>
          <ValidationMessage errors={interestValidationErrors} />
          <Card>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Type of Declaration" required>
                  <Dropdown
                    items={
                      (Object.keys(DeclarationType))
                      .filter((key) => !Number.isNaN(+key))
                      .map((key) => ({
                        label: getDeclarationTypeFriendlyName(+key as DeclarationType),
                        value: +key as DeclarationType
                      }))
                    }
                    value={newInterest?.declarationType}
                    onChange={value => setNewInterest({ ...newInterest, declarationType: +value })}
                    fluid
                  />
                </StructuredList.Item>
                <StructuredList.Item name="Name of Organisation" required>
                  <TextInput
                    maxLength={250}
                    value={newInterest?.organisationName}
                    onChange={value => setNewInterest({ ...newInterest, organisationName: value })}
                    placeholder="Name of Organisation"
                    fluid
                  />
                </StructuredList.Item>
                <StructuredList.Item name="Nature of Organisation" required>
                  <TextInput
                    maxLength={250}
                    value={newInterest?.organisationNature}
                    onChange={value => setNewInterest({ ...newInterest, organisationNature: value })}
                    placeholder="Nature of Organisation"
                    fluid
                  />
                </StructuredList.Item>
                <StructuredList.Item name="Nature of Interest" required>
                  <TextInput
                    maxLength={250}
                    value={newInterest?.natureOfInterest}
                    onChange={value => setNewInterest({ ...newInterest, natureOfInterest: value })}
                    placeholder="Nature of Interest"
                    fluid
                  />
                </StructuredList.Item>
                <StructuredList.Item name="Start Date" required>
                  <DatePicker
                    dateFormat="DD/MM/YYYY"
                    closeOnSelect
                    selectedDate={newInterest?.endDate ? moment(newInterest?.startDate).toDate() : null}
                    onChange={value => setNewInterest({ ...newInterest, startDate: moment(value).format("YYYY-MM-DD") })}
                  />
                </StructuredList.Item>
                <StructuredList.Item name="End Date">
                  <DatePicker
                    dateFormat="DD/MM/YYYY"
                    closeOnSelect
                    selectedDate={newInterest?.endDate ? moment(newInterest?.endDate).toDate() : null}
                    onChange={value => setNewInterest({ ...newInterest, endDate: moment(value).format("YYYY-MM-DD") })}
                  />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
        </Flyout.Body>
        <Flyout.Footer>
          <ActionBar low>
            <Button
              text="Save"
              onClick={handleFlyoutSave}
              color={Swatches.Success}
              fluid
            />
            <Button
              text="Cancel"
              onClick={handleFlyoutCancel}
              color={Swatches.Low}
              fluid
            />
          </ActionBar>
      </Flyout.Footer>
    </Flyout>

    </Modal>
    </>
  )
}


export default DeclarationEditorModal;