import React, { useState, useEffect } from "react";
import {
  StructuredList,
  StructuredListItem,
  Card,
  Dropdown,
  ActionBar,
  Message,
  Right,
  Button,
  Swatches,
  Size,
} from "ui-kit";
import { useSelector } from "react-redux";
import { useScroll } from "hooks/useScroll";
import { arrays } from "utils";
import reviewActions from "areas/humanResources/actions/reviewActions";
import MultiSchoolUserView from "sharedComponents/common/users/multiSchoolUserView";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import { useAppSelector } from "reducers/hooks";
import { BeehiveReport } from "types/common/action.types";


const PmAnalyticsWrapper = () => {

  const { scrollToTop } = useScroll();
  const { user } = useAppSelector(state => state.currentUser);
  const { report, loading, error } = useAppSelector(state => state.reviewReport);

  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [schoolId, setSchoolId] = useState<number>(user.baseSchool?.id);
  const [staffType, setStaffType] = useState<number>(null);
  const [academicYearId, setAcademicYearId] = useState<number>(null);

  const openCsv = (data: BeehiveReport) => {
    var attachment = document.createElement("a");

    attachment.href = "data:attachment/csv," + encodeURIComponent(data);
    attachment.download = "reviews.csv";
    attachment.click();
  };

  const handleGenerateReport = () => {
    var errors = [];

    if (!schoolId) {
      errors.push("Please choose a school.");
    }

    if (staffType == null) {
      errors.push("Please choose a staff type.");
    }

    if (!academicYearId) {
      errors.push("Please choose an academic year.");
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    reviewActions.getReviewReport(schoolId, academicYearId, staffType, data => {
      openCsv(data);
    });
  };

  useEffect(() => {
    error && scrollToTop();
  }, [error]);

  return (
    <>
      {!arrays.isEmpty(validationErrors) && (
        <Message
          text={validationErrors.map(err => (
            <>
              {err}
              <br />
            </>
          ))}
          color={Swatches.Danger}
        />
      )}

      {error && <Message text={error} color={Swatches.Danger} />}

      <MultiSchoolUserView>
        <Card title="School">
          <Card.Body>
            <StructuredList>
              <StructuredListItem name="School">
                <SchoolDropdown
                  onChange={value => setSchoolId(value)}
                  initialValue={schoolId}
                  fluid
                />
              </StructuredListItem>
            </StructuredList>
          </Card.Body>
        </Card>
      </MultiSchoolUserView>

      <Card title="Report Options">
        <Card.Body>
          <StructuredList>
            <StructuredListItem name="Staff Type">
              <Dropdown
                fluid
                value={staffType}
                onChange={value => setStaffType(value)}
              >
                <Dropdown.Item value={0} label="Teaching Staff" />
                <Dropdown.Item value={1} label="Associate Staff" />
              </Dropdown>
            </StructuredListItem>
            <StructuredListItem name="Academic Year">
              <Dropdown
                fluid
                value={academicYearId}
                onChange={value => setAcademicYearId(value)}
              >
                <Dropdown.Item value={11} label="2024/25" />
                <Dropdown.Item value={10} label="2023/24" />
                <Dropdown.Item value={9} label="2022/23" />
                <Dropdown.Item value={8} label="2021/22" />
                <Dropdown.Item value={7} label="2020/21" />
                <Dropdown.Item value={6} label="2019/20" />
              </Dropdown>
            </StructuredListItem>
          </StructuredList>
        </Card.Body>
      </Card>

      <ActionBar>
        <Right>
          <Button
            text="Generate Report"
            color={Swatches.Success}
            size={Size.Small}
            onClick={() => handleGenerateReport()}
            working={loading}
          />
        </Right>
      </ActionBar>
    </>
  );
};

export default PmAnalyticsWrapper;
