import React from "react";
import { Table, Swatches, EmptyMessage } from "ui-kit";
import BehaviourCountChip from "./behaviourCountChip";
import { arrays } from "utils";
import { getGroupName } from "./utils";
import { GenerateBehaviourReportView } from "areas/analytics/types/behaviourAnalyticsResponse.types";
import { GroupBy } from "areas/analytics/types/behaviourAnalyticsShared.types";


interface IBehaviourReportTableProps {
  report: GenerateBehaviourReportView;
  groupType1: GroupBy;
  groupType2: GroupBy;
}

const BehaviourReportTable: React.FC<IBehaviourReportTableProps> = ({ report, groupType1, groupType2 }) => {
  return (
    report && (
      <>
        {arrays.isEmpty(report.behaviourGroupByView1s) ? (
          <EmptyMessage
            title="No rewards or behaviours in the selected time period"
            icon="list"
          />
        ) : (
          <Table>
            <Table.Header>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell right>Behaviour Points</Table.HeaderCell>
              <Table.HeaderCell center>Behaviour Counts</Table.HeaderCell>
            </Table.Header>

            <Table.Row>
              <Table.Cell>
                <b>{report.name}</b>
              </Table.Cell>
              <Table.Cell right>
                <BehaviourCountChip
                  count={report.totalBehaviourPoints}
                  color={Swatches.Danger}
                />
              </Table.Cell>
              <Table.Cell center>
                <BehaviourCountChip
                  count={report.totalBehaviourCount}
                  color={Swatches.Danger}
                />
              </Table.Cell>
            </Table.Row>
          </Table>
        )}

        {report.behaviourGroupByView1s.map((group, index) => (
          <Table>
            {groupType2 === null ? (
              <Table.Row>
                <Table.Cell>
                  <b>{getGroupName(groupType1, group.name)}</b>
                </Table.Cell>
                <Table.Cell right>
                  <BehaviourCountChip
                    count={group.behaviourPoints}
                    color={Swatches.Danger}
                  />
                </Table.Cell>
                <Table.Cell center>
                  <BehaviourCountChip
                    count={group.behaviourCount}
                    color={Swatches.Danger}
                  />
                </Table.Cell>
              </Table.Row>
            ) : (
              <Table.Header>
                <Table.HeaderCell>
                  <b>{getGroupName(groupType1, group.name)}</b>
                </Table.HeaderCell>
                <Table.HeaderCell right>
                  <BehaviourCountChip
                    count={group.behaviourPoints}
                    color={Swatches.Danger}
                  />
                </Table.HeaderCell>
                <Table.Cell center>
                  <BehaviourCountChip
                    count={group.behaviourCount}
                    color={Swatches.Danger}
                  />
                </Table.Cell>
              </Table.Header>
            )}

            <>
            {group.behaviourGroupByView2s.map(group2 => (
              <Table.Row>
                <Table.Cell>
                  <b>{getGroupName(groupType2, group2.name)}</b>
                </Table.Cell>
                <Table.Cell right>
                  <BehaviourCountChip
                    count={group2.behaviourPoints}
                    color={Swatches.Danger}
                  />
                </Table.Cell>
                <Table.Cell center>
                  <BehaviourCountChip
                    count={group2.behaviourCount}
                    color={Swatches.Danger}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
            </>
          </Table>
        ))}
      </>
    )
  );
};

export default BehaviourReportTable;
