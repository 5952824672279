import React, { useEffect, useRef, useState } from "react";
import DetentionDetailedReportForm from "./detentionDetailedReportForm";
import DetentionDetailedReportTable from "./detentionDetailedReportTable";
import ReportDescription from "../reportDescription";
import { Title, TitleSize } from "ui-kit";
import detentionReportActions from "areas/analytics/actions/detentionReportActions";
import { Constants } from "configuration";
import { useAppSelector } from "reducers/hooks";
import { DetentionReportFilter } from "areas/analytics/types/detentionAnalyticsRequest.types";
import moment from "moment";


export interface DetentionReportFilterExt extends Omit<DetentionReportFilter, "startDate" | "endDate"> {
  startDate: Date;
  endDate: Date;
}


const DetentionDetailedReportWrapper: React.FC = () => {

  const reportRef = useRef<HTMLDivElement>();
  const { user } = useAppSelector(state => state.currentUser);
  const { report, paging, loading, loadingExportReport } = useAppSelector(
    state => state.detentionsDetailedReport
  );

  const [_loading, _setLoading] = useState<boolean>(false);
  const [schoolId, setSchoolId] = useState<number>(null);
  const [reportCriteria, setReportCriteria] = useState<DetentionReportFilterExt>({
    startDate: null,
    endDate: null,
    statusId: null,
    academicYearId: Constants.ACADEMIC_YEAR_FILTER_ARRAY[0].value,
    schoolId: null,
    yearGroupId: null
  });

  useEffect(() => {
    if (user?.baseSchool) {
      setSchoolId(user.baseSchool.id);
    }
  }, []);

  useEffect(() => {
    if (loading || report) {
      reportRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading, report]);

  const handleReportCriteriaChange = (criteria: DetentionReportFilterExt) => {
    setReportCriteria(criteria);
  };

  const handleGenerateReport = (exportReport: boolean) => {
    if (!exportReport) {
      _setLoading(true);
    }
    detentionReportActions
      .getDetentionDetailedReport(
        {
          schoolId: schoolId,
          startDate: moment(reportCriteria.startDate).format("YYYY-MM-DD"),
          endDate: moment(reportCriteria.endDate).format("YYYY-MM-DD"),
          statusId: reportCriteria.statusId,
          academicYearId: reportCriteria.academicYearId,
          yearGroupId: null
        },
        0,
        exportReport,
        () => _setLoading(false)
      )
      .catch(() => _setLoading(false));
  };

  const handlePage = () => {
    detentionReportActions.getDetentionDetailedReport(
      {
        schoolId: schoolId,
        startDate: moment(reportCriteria.startDate).format("YYYY-MM-DD"),
        endDate: moment(reportCriteria.endDate).format("YYYY-MM-DD"),
        statusId: reportCriteria.statusId,
        academicYearId: reportCriteria.academicYearId,
        yearGroupId: null
      },
      paging.pageIndex + 1,
      false
    );
  };

  return (
    <>
      <Title text="Detentions Report" size={TitleSize.H3} />
      <ReportDescription text="Use this report to view a list of detailed information for detentions." />
      <DetentionDetailedReportForm
        schoolId={schoolId}
        onSelectSchool={value => setSchoolId(value)}
        reportCriteria={reportCriteria}
        onChangeReportCriteria={handleReportCriteriaChange}
        handleGenerateReport={handleGenerateReport}
        loadingExportReport={loadingExportReport}
      />
      <div ref={reportRef}>
        <DetentionDetailedReportTable
          handlePage={handlePage}
          loading={_loading}
        />
      </div>
    </>
  );
};

export default DetentionDetailedReportWrapper;
