import React, { useEffect, useState } from "react";
import {
  StructuredList,
  Card,
  ActionBar,
  Button,
  Swatches,
  Size,
} from "ui-kit";
import MultiSchoolUserView from "sharedComponents/common/users/multiSchoolUserView";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import AcademicYearFilter from "sharedComponents/common/academicYearFilter";
import { Constants } from "configuration";
import { useAppSelector } from "reducers/hooks";
import { CoCurricularReportFilter } from "areas/analytics/types/coCurricularAnalyticsRequest.types";


interface ICoCurricularAnalyticsFormProps {
  onGenerateReport: (filter: CoCurricularReportFilter) => void;
  exporting: boolean;
}


const CoCurricularAnalyticsForm: React.FC<ICoCurricularAnalyticsFormProps> = ({
  onGenerateReport,
  exporting,
}) => {

  const { user } = useAppSelector(state => state.currentUser);
  
  const [filter, setFitler] = useState<CoCurricularReportFilter>({
    schoolId: null,
    academicYearId: Constants.ACADEMIC_YEAR_FILTER_ARRAY[0].value
  });

  useEffect(() => {
    user && setFitler({
      schoolId: user.baseSchool.id,
      academicYearId: Constants.ACADEMIC_YEAR_FILTER_ARRAY[0].value
    })
  }, [user])

  return (
    <>
      <StructuredList>
        <MultiSchoolUserView>
          <Card title="School">
            <Card.Body>
              <SchoolDropdown
                onChange={value => setFitler({ ...filter, schoolId: value })}
                initialValue={filter.schoolId}
                fluid
              />
            </Card.Body>
          </Card>
        </MultiSchoolUserView>

        <Card title="Date Range">
          <Card.Body>
            <StructuredList.Item name="Academic Year" required>
              <AcademicYearFilter
                value={filter.academicYearId}
                onChange={value => setFitler({ ...filter, academicYearId: value })}
              />
            </StructuredList.Item>
          </Card.Body>
        </Card>
      </StructuredList>

      <ActionBar>
        <Button
          color={Swatches.Success}
          text="Export Report"
          icon="file-download"
          size={Size.Small}
          disabled={filter.schoolId == null || filter.academicYearId === null}
          onClick={() => onGenerateReport(filter)}
          working={exporting}
        />
      </ActionBar>
    </>
  );
};

export default CoCurricularAnalyticsForm;
