import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Subheader from "sharedComponents/layout/header/subheader";
import styled from "styled-components";
import {
  Title,
  TitleSize,
  Spacing,
  EmptyMessage,
  Size,
  neutral,
  Loader,
  BorderRadius,
  fontStyle,
  displayFont,
  typescale,
  Chip
} from "ui-kit";
import SearchForm from "areas/search/searchForm";
import { Link } from "react-router-dom";
import { Avatar } from "sharedComponents/common";
import { arrays } from "utils";
import { useSelector } from "react-redux";
import useInfiniteScroll from "react-infinite-scroll-hook";
import paymentActions from "areas/payments/actions/paymentsActions";
import { RootState } from "reducers/store";
import { UserListView } from "types/users/userListViews.types";
import { SearchResultView } from "types/common/views.types";


const ResultsWrapper = styled.ul``;

const ResultItem = styled.li`
  padding: ${Spacing.Medium}px;
  margin-top: ${Spacing.Small}px;
  margin-bottom: ${Spacing.Small}px !important;
  border-radius: ${BorderRadius.Default}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }

  &:hover {
    cursor: pointer;
    background: ${neutral[400]};
    transition: all ease 250ms;
  }
`;

const CountWrapper = styled.div`
  ${fontStyle(displayFont.roman, typescale.paragraph, neutral[600])}
`;


const Search = () => {

  const location = useLocation();
  const { loading, error, users, paging } = useSelector((state: RootState) => state.searchPaymentUsers);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const search = (pageIndex: number) => {
    if (searchTerm?.length > 0) {
      paymentActions.searchUsers(searchTerm, pageIndex);
    }
  };

  useEffect(() => {
    if (location) {
      setSearchTerm(location?.state?.searchTerm);
    }
  }, [location]);

  useEffect(() => {
    search(0);
  }, [searchTerm]);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const onPage = () => {
    search(paging?.pageIndex + 1);
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: paging?.pageNumber < paging?.totalPages,
    onLoadMore: onPage,
  });

  return (
    <>
      <Subheader>
        <Title size={TitleSize.H2} text="Shopkeeper" sub={`User Search`} />
      </Subheader>
      <SearchForm
        initialValue={location?.state?.searchTerm}
        onSearch={handleSearch}
      />

      {error && (
        <EmptyMessage
          title="An error occured"
          summary="There was a problem searching the users"
          icon="times-circle"
        />
      )}

      {searchTerm && !loading && !error && arrays.isEmpty(users) && (
        <EmptyMessage
          title="No results"
          summary="No users were found"
          icon="users"
        />
      )}

      {!arrays.isEmpty(users) && (
        <>
          <CountWrapper>
            <p>
              <b>{paging.totalRecords}</b> users found for '{searchTerm}'
            </p>
          </CountWrapper>
          { users.map((user: SearchResultView<UserListView>, index: number) => (
            <Link to={`/main/shopkeeper/users/${user.id}`} key={index}>
              <ResultItem key={index}>
                <Avatar
                  size={Size.Medium}
                  user={user.data}
                  sub={`${user?.school?.name}`}
                />
                <Chip text={user.data.userType} />
              </ResultItem>
            </Link>
          ))}

          {paging?.pageNumber < paging?.totalPages && (
            <div ref={sentryRef}></div>
          )}
        </>
      )}
      {loading && <Loader size={Size.Medium} fluid />}
    </>
  );
};

export default Search;
