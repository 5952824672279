import { GROUP_LISTS } from "areas/planner/constants/groups/groups";
import groupsActions from "../../actions/groups/groupsActions";
import { IBeehiveAction } from "types/common/action.types";


interface IGroupListsState {
  staffExpandedGroupsIds: number[];
  studentExpandedGroupsIds: number[];
}

const INITIAL_STATE: IGroupListsState = {
  staffExpandedGroupsIds: [],
  studentExpandedGroupsIds: [
    GROUP_LISTS.TUTOR_GROUPS,
    GROUP_LISTS.CLASS_GROUPS,
    GROUP_LISTS.CUSTOM_GROUPS,
  ],
};


const groupListsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IGroupListsState => {

  const { STAFFEXPANDEDGROUPS, STUDENTEXPANDEDGROUPS } = groupsActions.types;

  switch (action.type) {
    case STAFFEXPANDEDGROUPS:
      return { ...state, staffExpandedGroupsIds: action.payload };

    case STUDENTEXPANDEDGROUPS:
      return { ...state, studentExpandedGroupsIds: action.payload };

    default:
      return state;
  }
};

export default groupListsReducer;
