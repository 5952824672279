import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card } from "ui-kit";
import ReadOnlyDataList, { DetentionDataType } from "./readOnlyDataList";


interface IReadOnlyInvestigationNoteProps {
  investigationNote: string;
}


const ReadOnlyInvestigationNote: React.FC<IReadOnlyInvestigationNoteProps> = ({ investigationNote }) => {

  const [data, setData] = useState<DetentionDataType[]>([]);

  useEffect(() => {
    if (investigationNote) {
      setData([
        {
          dataType: "Investigation Notes",
          dataValue: investigationNote,
        },
      ]);
    }
  }, [investigationNote]);

  return investigationNote ? (
    <Card title="Investigation Details">
      <Card.Body>
        <ReadOnlyDataList data={data} />
      </Card.Body>
    </Card>
  ) : null;
};

ReadOnlyInvestigationNote.propTypes = {
  investigationNote: PropTypes.string,
};

export default ReadOnlyInvestigationNote;
