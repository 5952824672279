import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Subheader from "sharedComponents/layout/header/subheader";
import {
  Title,
  Sub,
  Loader,
  Size,
  EmptyMessage,
  Table,
  ActionBar,
  Chip,
  Swatches,
  Dropdown,
  Button,
  Modal,
  RadioButton,
  Left,
  Right,
  Spacing,
  Message,
  DetailLabel,
  ToastService,
  Icon,
} from "ui-kit";
import lunchRegisterActions from "areas/payments/actions/lunchRegisterActions";
import { arrays } from "utils";
import { UserName } from "sharedComponents/common";
import { Constants } from "configuration";
import Moment from "react-moment";
import LunchRegisterStatistics from "./lunchRegisterStatistics";
import styled from "styled-components";
import { SaveRegisterRequest } from "areas/payments/types/catering/cateringRequests.types";
import { RootState } from "reducers/store";
import {
  LunchRecordListView,
  LunchRegisterDetailView,
} from "areas/payments/types/catering/cateringResponses.types";
import { LunchOption } from "areas/payments/types/catering/cateringShared.types";

const LunchOptionWrapper = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const SchoolLunchOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  .table .radio-button {
    justify-content: center;
    display: flex;
  }
`;

interface NewLunchRecords extends LunchRecordListView {
  initialBalance?: number;
  initialLunchType?: number;
}

interface NewLunchRegister extends LunchRegisterDetailView {
  records: NewLunchRecords[];
}

interface IRegisterModalProps {
  tutorGroupId: number;
  date: Date;
  open?: boolean;
  onClose?: () => void;
}

const RegisterModal: React.FC<IRegisterModalProps> = ({
  tutorGroupId,
  date,
  open,
  onClose,
}) => {
  const { loading, error, register, actions } = useSelector(
    (state: RootState) => state.lunchRegister
  );
  const [_register, _setRegister] = useState<NewLunchRegister | null>(null);
  const [_open, _setOpen] = useState<boolean>(false);
  const [_tutorGroupId, _setTutorGroupId] = useState<number | null>(null);

  useEffect(() => {
    _setTutorGroupId(tutorGroupId);
  }, [tutorGroupId]);

  useEffect(() => {
    _setOpen(open);
    _tutorGroupId && lunchRegisterActions.getLunchRegister(tutorGroupId, date);
  }, [open]);

  useEffect(() => {
    register &&
      _setRegister({
        ...register,
        records: register?.records
          ?.map((x: LunchRecordListView) =>
            x.lunchType == null
              ? { ...x, lunchType: Constants.LUNCH_TYPES.FASTING.value }
              : x
          )
          .map(x => ({
            ...x,
            initialBalance: x.balance,
            initialLunchType: x.lunchType,
          })),
      });
  }, [register]);

  const handleUseDefault = () => {
    if (
      window.confirm(
        "This will update the register with pupils' default lunch choices. Are you sure?"
      )
    ) {
      _setRegister({
        ...register,
        records: register?.records?.map((x: LunchRecordListView) =>
          x.defaultLunchType == null
            ? { ...x, lunchType: Constants.LUNCH_TYPES.FASTING.value }
            : {
                ...x,
                lunchType: x.defaultLunchType,
                lunchOption: x.defaultLunchOption,
              }
        ),
      });
    }
  };

  const handleSaveRegister = (updateDefaultChoice: boolean) => {
    if (
      !updateDefaultChoice ||
      window.confirm(
        "This will save the lunch register and update the default pupil choices with the options chosen (non-absent choices only). This cannot be undone. Are you sure?"
      )
    ) {
      const data: SaveRegisterRequest = {
        id: _register.id,
        date: _register.date,
        tutorGroupId: _register.tutorGroup.id,
        updateDefaultChoice,
        records: _register.records.map((x: LunchRecordListView) => ({
          userId: x.user.id,
          lunchType: x.lunchType,
          lunchOption: x.lunchOption,
        })),
      };

      lunchRegisterActions.saveLunchRegister(data, newRegister => {
        ToastService.pop("Lunch Register Saved", null, "utensils");
        onClose?.();
      });
    }
  };

  const getDynamicLunchBalance = (
    newLunchType: number,
    initialLunchType: number,
    balance: number,
    initialBalance: number,
    userFreeSchoolMeal: boolean
  ) => {
    console.log(
      newLunchType,
      initialLunchType,
      initialBalance,
      userFreeSchoolMeal
    );

    if (newLunchType === 0 && initialLunchType > 0 && !userFreeSchoolMeal) {
      // Reduce the initial balance
      return initialBalance - 1;
    }

    if (newLunchType > 0 && initialLunchType === 0 && !userFreeSchoolMeal) {
      // Lunch was unpaid, and original choice was paid, so increase the balance
      return initialBalance + 1;
    }

    return initialBalance;
  };

  const handleLunchTypeChanged = (index: number, lunchType: number) => {
    _setRegister({
      ..._register,
      records: _register.records.map((x: NewLunchRecords, i: number) =>
        i === index
          ? {
              ...x,
              lunchType: lunchType,
              lunchOption: null, // Reset lunch option if not a school meal
              balance: getDynamicLunchBalance(
                lunchType,
                x.initialLunchType,
                x.balance,
                x.initialBalance,
                x.user.freeSchoolMeal
              ),
            }
          : x
      ),
    });
  };

  const setLunchOption = (index: number, option: LunchOption) => {
    _setRegister({
      ..._register,
      records: _register.records.map((x: NewLunchRecords, i: number) =>
        i === index
          ? {
              ...x,
              lunchType: 0,
              lunchOption: option,
            }
          : x
      ),
    });
  };

  const getLunchTypeTotal = (lunchType: number) => {
    return _register.records.reduce(function (
      acc: number,
      obj: LunchRecordListView
    ) {
      return acc + (obj.lunchType === lunchType ? 1 : 0);
    },
    0);
  };

  const Balance = ({ balance }: { balance: number }) => {
    return (
      <Chip
        fluid
        colorSwatch={
          balance === 0
            ? Swatches.Low
            : balance > 0
            ? Swatches.Success
            : Swatches.Danger
        }
        text={balance.toString()}
      />
    );
  };

  const Register = () => {
    return _register && !arrays.isEmpty(_register?.records) ? (
      <Wrapper>
        <Subheader>
          <Title
            text={_register.tutorGroup.friendlyName}
            sub={<Moment date={_register.date} format="dddd Do MMMM" />}
          />
        </Subheader>
        <LunchRegisterStatistics records={_register?.records} />
        <Message text={actions.saving.error} color={Swatches.Danger} />
        {_register?.enableDefaultLunch && (
          <Sub style={{ display: "block", paddingBottom: Spacing.Default }}>
            New registers can be populated with pupils' default meal choices by
            clicking on <b>'Use Default Lunches'</b>. To save the register and
            update the default choices click on{" "}
            <b>'Save Register & Update Default Choices'</b>. To save the
            register without updating the default choices, click on{" "}
            <b>'Save Register'</b>.
          </Sub>
        )}
        <Table zebra>
          <Table.Header>
            <Table.HeaderCell width={6}>Pupil</Table.HeaderCell>
            <Table.HeaderCell width={0.4} center>
              DI
            </Table.HeaderCell>
            <Table.HeaderCell width={0.8} center>
              {Constants.LUNCH_TYPES.SCHOOL_MEAL.name}
            </Table.HeaderCell>
            <Table.HeaderCell width={0.8} center>
              {Constants.LUNCH_TYPES.PACKED_LUNCH.name}
            </Table.HeaderCell>
            <Table.HeaderCell width={0.8} center>
              {Constants.LUNCH_TYPES.HOME.name}
            </Table.HeaderCell>
            <Table.HeaderCell width={0.8} center>
              None
            </Table.HeaderCell>
            <Table.HeaderCell width={0.8} center>
              {Constants.LUNCH_TYPES.ABSENT.name}
            </Table.HeaderCell>
            {/* <Table.HeaderCell right width={1} center>
              New Balance
            </Table.HeaderCell> */}
          </Table.Header>
          <Table.Body>
            {_register?.records?.map(
              (record: LunchRecordListView, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell width={6}>
                    <DetailLabel
                      label={<UserName user={record.user} />}
                      sub={record.user.simsId}
                      bold
                      chip={
                        record.user.freeSchoolMeal ? (
                          <Chip
                            text="Free School Meal"
                            colorSwatch={Swatches.Miami}
                          />
                        ) : null
                      }
                    />
                  </Table.Cell>
                  <Table.Cell width={0.4} center>
                    {record.dietaryInformation && (
                      <Icon
                        value="exclamation-triangle"
                        size={Size.Large}
                        color={Swatches.Warning.swatch}
                        tooltip="Dietary Information"
                        tooltipSub={record.dietaryInformation}
                      />
                    )}
                  </Table.Cell>

                  {record.lunchType === 0 && !record.lunchOption ? (
                    <Table.Cell width={4} colspan={5} zebra>
                      <Dropdown
                        fluid
                        placeholder="Please choose a meal option..."
                        value={record.lunchOption}
                        onChange={value => setLunchOption(index, value)}
                        items={Object.values(Constants.LUNCH_OPTIONS)
                          .map(option => ({
                            label: option.altName,
                            value: option.value,
                          }))
                          .filter(x => x.value > 0)}
                      />
                    </Table.Cell>
                  ) : (
                    <>
                      <Table.Cell width={0.8} center zebra>
                        <SchoolLunchOptionWrapper>
                          <RadioButton
                            value={0}
                            checked={record.lunchType === 0}
                            onChange={() =>
                              handleLunchTypeChanged(
                                index,
                                Constants.LUNCH_TYPES.SCHOOL_MEAL.value
                              )
                            }
                            disabled={
                              record.lunchType === 0 && !record.lunchOption
                            }
                          />
                          {record.lunchType === 0 && (
                            <LunchOptionWrapper>
                              {" "}
                              {record.lunchOption}
                            </LunchOptionWrapper>
                          )}
                        </SchoolLunchOptionWrapper>
                      </Table.Cell>
                      <Table.Cell width={0.8} center zebra>
                        <RadioButton
                          value={1}
                          checked={record.lunchType === 1}
                          onChange={() =>
                            handleLunchTypeChanged(
                              index,
                              Constants.LUNCH_TYPES.PACKED_LUNCH.value
                            )
                          }
                        />
                      </Table.Cell>
                      <Table.Cell width={0.8} center zebra>
                        <RadioButton
                          value={2}
                          checked={record.lunchType === 2}
                          onChange={() =>
                            handleLunchTypeChanged(
                              index,
                              Constants.LUNCH_TYPES.HOME.value
                            )
                          }
                        />
                      </Table.Cell>
                      <Table.Cell width={0.8} center zebra>
                        <RadioButton
                          value={3}
                          checked={record.lunchType === 3}
                          onChange={() =>
                            handleLunchTypeChanged(
                              index,
                              Constants.LUNCH_TYPES.FASTING.value
                            )
                          }
                        />
                      </Table.Cell>
                      <Table.Cell width={0.8} center zebra>
                        <RadioButton
                          value={4}
                          checked={record.lunchType === 4}
                          onChange={() =>
                            handleLunchTypeChanged(
                              index,
                              Constants.LUNCH_TYPES.ABSENT.value
                            )
                          }
                        />
                      </Table.Cell>
                    </>
                  )}
                </Table.Row>
              )
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell width={4}>
                <b>{_register.records.length + " "}Pupils</b>
              </Table.Cell>
              <Table.Cell width={0.8} center zebra>
                <b>
                  {getLunchTypeTotal(Constants.LUNCH_TYPES.SCHOOL_MEAL.value)}
                </b>
              </Table.Cell>
              <Table.Cell width={0.8} center zebra>
                <b>
                  {" "}
                  {getLunchTypeTotal(Constants.LUNCH_TYPES.PACKED_LUNCH.value)}
                </b>
              </Table.Cell>
              <Table.Cell width={0.8} center zebra>
                <b>{getLunchTypeTotal(Constants.LUNCH_TYPES.HOME.value)}</b>
              </Table.Cell>
              <Table.Cell width={0.8} center zebra>
                <b>{getLunchTypeTotal(Constants.LUNCH_TYPES.FASTING.value)}</b>
              </Table.Cell>
              <Table.Cell width={0.8} center zebra>
                <b>{getLunchTypeTotal(Constants.LUNCH_TYPES.ABSENT.value)}</b>
              </Table.Cell>
              <Table.Cell colspan={2} width={2} />
            </Table.Row>
          </Table.Footer>
        </Table>
      </Wrapper>
    ) : (
      <EmptyMessage
        icon="utensils"
        title="No Register"
        summary="No register was found"
        cover
      />
    );
  };

  return (
    <Modal
      title="Lunch Register"
      open={_open}
      onClose={onClose}
      width="90%"
      height="90%"
    >
      <Modal.Body>
        {loading && <Loader size={Size.Large} cover />}
        {error && (
          <EmptyMessage
            icon="times-circle"
            title="An error occured"
            summary="An error occured while loading the register"
            cover
          />
        )}
        {!loading && !error && <Register />}
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Left>
            {_register?.enableDefaultLunch && (
              <Button
                text="Use Default Lunches"
                onClick={() => handleUseDefault()}
                color={Swatches.Primary}
                working={actions.saving.working}
                size={Size.Small}
              />
            )}
            <Button
              text="Cancel"
              onClick={onClose}
              color={Swatches.Low}
              working={actions.saving.working}
              size={Size.Small}
            />
          </Left>
          <Right>
            <Button
              text="Save Register"
              onClick={() => handleSaveRegister(false)}
              color={Swatches.Success}
              working={actions.saving.working}
              size={Size.Small}
            />
            {_register?.enableDefaultLunch && (
              <Button
                text="Save Register & Update Default Choices"
                onClick={() => handleSaveRegister(true)}
                color={Swatches.Success}
                working={actions.saving.working}
                size={Size.Small}
              />
            )}
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default RegisterModal;
