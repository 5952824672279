import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import store from "reducers/store";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { GroupListView } from "types/users/userGroups.types";

const builder = new actionTypeBuilder("classGroups");

const types = {
  GETGROUPS: builder.build("getgroups"),
  STAFFEXPANDEDGROUPS: "staffexpandedgroups",
  STUDENTEXPANDEDGROUPS: "studentexpandedgroups",
};


const getGroupsForStaff = (userId: string, onSuccess?: callbackType<GroupListView[]>) => {
  return action<GroupListView[]>(
    () => client.get(`planner/staff/${userId}/classes`),
    types.GETGROUPS,
    onSuccess
  );
};

const saveStaffExpandedGroups = (groupListIds: number[]) => {
  store.dispatch<IBeehiveAction<number[]>>({
    type: types.STAFFEXPANDEDGROUPS,
    payload: groupListIds,
  });
};

const saveStudentExpandedGroups = (groupListIds: number[]) => {
  store.dispatch<IBeehiveAction<number[]>>({
    type: types.STUDENTEXPANDEDGROUPS,
    payload: groupListIds,
  });
};


const groupsActions = {
  types,
  getGroupsForStaff,
  saveStaffExpandedGroups,
  saveStudentExpandedGroups,
};

export default groupsActions;
