import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import store from "reducers/store";
import { BeehiveReport, IBeehiveAction, callbackType } from "types/common/action.types";
import { DocumentGroupDetailView, DocumentListView, UploadDocumentsResult } from "../types/documentResponse.types";
import { DeleteDocumentsCommand, DocumentGroupFilter, SaveDocumentGroupCommand, UploadDocumentsCommand } from "../types/documentRequest.types";
import { PagedQueryView } from "types/common/views.types";

const builder = new actionTypeBuilder("documentgroups");

const types = {
  DOCUMENTGROUPS_GETALL: builder.build("DOCUMENTGROUPS_GETALL"),
  DOCUMENTGROUPS_GET: builder.build("DOCUMENTGROUPS_GET"),
  DOCUMENTGROUPS_CREATE: builder.build("DOCUMENTGROUPS_CREATE"),
  DOCUMENTGROUPS_UPDATE: builder.build("DOCUMENTGROUPS_UPDATE"),
  DOCUMENTGROUPS_DELETE: builder.build("DOCUMENTGROUPS_DELETE"),
  DOCUMENTGROUPS_UPLOAD: builder.build("DOCUMENTGROUPS_UPLOAD"),
  DOCUMENTGROUPS_DELETEDOCUMENTS: builder.build(
    "DOCUMENTGROUPS_DELETEDOCUMENTS"
  ),
  DOCUMENTGROUPS_GETDOCUMENTS: builder.build("DOCUMENTGROUPS_GETDOCUMENTS"),
  DOCUMENTGROUPS_RUNREADDATEREPORT: builder.build("DOCUMENTGROUPS_RUNREADDATEREPORT"),
  DOCUMENTGROUPS_TOGGLEDOCUMENTSELECTION:
    "DOCUMENTGROUPS_TOGGLEDOCUMENTSELECTION",
};


const getDocumentGroups = (callback?: callbackType<DocumentGroupDetailView[]>) =>
  action<DocumentGroupDetailView[]>(
    () => client.get(`users/documentgroups`),
    types.DOCUMENTGROUPS_GETALL,
    callback
  );

const getDocumentGroup = (id: number, callback?: callbackType<DocumentGroupDetailView>) =>
  action<DocumentGroupDetailView>(
    () => client.get(`users/documentgroups/${id}`),
    types.DOCUMENTGROUPS_GET,
    callback
  );

const getDocumentGroupDocuments = (id: number, filter: DocumentGroupFilter, pageIndex: number, callback?: callbackType<PagedQueryView<DocumentListView>>) => {
  var url = `users/documentgroups/${id}/documents?pageIndex=${pageIndex}`;

  if (filter && filter.schoolId && filter.schoolId > 0) {
    url = url + `&schoolId=${filter.schoolId}`;
  }

  return action<PagedQueryView<DocumentListView>>(
    () => client.get(url),
    types.DOCUMENTGROUPS_GETDOCUMENTS,
    callback
  );
};

const createDocumentGroup = (data: SaveDocumentGroupCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`users/documentgroups`, data),
    types.DOCUMENTGROUPS_CREATE,
    callback
  );

const updateDocumentGroup = (data: SaveDocumentGroupCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`users/documentgroups/${data.id}`, data),
    types.DOCUMENTGROUPS_UPDATE,
    callback
  );

const deleteDocumentGroup = (id: number, callback?: callbackType<null>) =>
  action<null>(
    () => client.delete(`users/documentgroups/${id}`),
    types.DOCUMENTGROUPS_DELETE,
    callback
  );

const uploadToDocumentGroup = (data: UploadDocumentsCommand, callback?: callbackType<UploadDocumentsResult>) =>
  action<UploadDocumentsResult>(
    () => client.post(`users/documentgroups/${data.documentGroupId}/upload`, data),
    types.DOCUMENTGROUPS_UPLOAD,
    callback
  );

const deleteDocuments = (documentGroupId: number, data: DeleteDocumentsCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`users/documentgroups/${documentGroupId}/delete`, data),
    types.DOCUMENTGROUPS_DELETEDOCUMENTS,
    callback
  );

const runReadRecieptReport = (documentGroupId: number, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.get(`users/documentgroups/${documentGroupId}/runReadRecieptReport`, { responseType: "text" }),
    types.DOCUMENTGROUPS_RUNREADDATEREPORT,
    callback
  );

const toggleDocumentSelection = (id: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.DOCUMENTGROUPS_TOGGLEDOCUMENTSELECTION,
    payload: id,
  });
};


const documentGroupActions = {
  types,
  getDocumentGroups,
  getDocumentGroup,
  getDocumentGroupDocuments,
  createDocumentGroup,
  updateDocumentGroup,
  deleteDocumentGroup,
  uploadToDocumentGroup,
  deleteDocuments,
  runReadRecieptReport,
  toggleDocumentSelection,
};

export default documentGroupActions;
