import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import paymentActions from "areas/payments/actions/paymentsActions";
import flyoutActions from "actions/ui/flyouts";
import ProductOrdersTable from "../products/productOrdersTable";
import { Constants } from "configuration";
import ItemPaymentFlyout from "../orders/itemPaymentFlyout";
import { RootState } from "reducers/store";
import { PaymentUser } from "areas/payments/reducers/payments/paymentsUserReducer";
import { UserProductOrderView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { useAppSelector } from "reducers/hooks";


interface IUserPurchasedItemsProps {
  user: PaymentUser;
}


const UserPurchasedItems: React.FC<IUserPurchasedItemsProps> = ({ user }) => {

  const { loading, error, items } = useAppSelector(state => state.userPurchasedItems);
  const [selectedItem, setSelectedItem] = useState<UserProductOrderView | null>(null);

  useEffect(() => {
    user && paymentActions.getPurchasedItemsForUser(user.id);
  }, [user]);

  const handlePayClicked = (item: UserProductOrderView) => {
    setSelectedItem(item);
    flyoutActions.openFlyout(Constants.FLYOUTS.ITEMPAYMENT);
  };

  return (
    <>
      <ProductOrdersTable
        loading={loading}
        error={error != null}
        orders={items}
        showCollection
        showPurchasedFor={false}
        showProduct
        onPayClicked={handlePayClicked}
      />
      <ItemPaymentFlyout item={selectedItem} user={user} />
    </>
  );
};

export default UserPurchasedItems;
