import types from "actions/ui/uiTypes";
import { IBeehiveAction } from "types/common/action.types";


export interface privacySettings {
  sensitiveInfo: boolean;
  profileImage: boolean;
}

interface IPrivacyState {
  isPrivate: privacySettings
}

const INITIAL_STATE: IPrivacyState = {
  isPrivate: {
    sensitiveInfo: false,
    profileImage: false
  }
};


const privacyReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IPrivacyState => {
  switch (action.type) {
    case types.SET_PRIVACY:
      return { ...state, isPrivate: action.payload };
    default:
      return state;
  }
};

export default privacyReducer;
