import React, { useState, useEffect, useRef, RefObject } from 'react';
import styled from 'styled-components';
import {
  neutral,
  typescale,
  textFont,
  fontStyle,
  BorderRadius,
  Spacing,
  Loader,
  formatScrollbars,
  Size,
  EmptyMessage,
  Icon,
  ClassNames,
  Swatches
} from "../../ui-kit";
import { IEmptyMessageProps } from "../../ui-kit/composite/emptyMessage";


export interface spreadsheetCellValue {
  cellValue: string,
  rowIndex?: number,
  cellIndex?: number,
  rowIdentifier?: string,
  cellIdentifier?: string
}

export interface ISpeadsheetCellProps {
  children: any;
  width?: number;
  right?: boolean;
  center?: boolean;
  show?: boolean;
  rowIndex?: number;
  cellIndex?: number;
  rowIdentifier?: string;
  cellIdentifier?: string;
  onCellChange?: (cell: spreadsheetCellValue) => void;
  readonly?: boolean;
  noCloseOnEnter?: boolean;
  colspan?: number;
  maxLength?: number;
  characterCount?: boolean;
}
export interface ISpeadsheetHeaderCellProps extends ISpeadsheetCellProps {
}
export interface ISpreadsheetHeaderProps {
  children: ( React.ReactElement<ISpeadsheetHeaderCellProps> | React.ReactElement<ISpeadsheetHeaderCellProps>[]);
}
export interface ISpreadsheetFooterProps {
  children: ( React.ReactElement<ISpeadsheetCellProps> | React.ReactElement<ISpeadsheetCellProps>[]);
}
export interface ISpreadsheetRowProps {
  children: (React.ReactElement<ISpeadsheetCellProps> | React.ReactElement<ISpeadsheetCellProps>[]);
  rowIndex?: number;
  rowIdentifier?: string;
  onClick?: () => void;
  onCellChange?: (cell: spreadsheetCellValue) => void;
}
export interface ISpreadsheetBodyProps {
  children: (React.ReactElement<ISpreadsheetRowProps> | React.ReactElement<ISpreadsheetRowProps>[]);
  onCellChange?: (cell: spreadsheetCellValue) => void;
}
export interface ISpreadsheetProps {
  children: (React.ReactElement<ISpreadsheetHeaderProps> | React.ReactElement<ISpreadsheetBodyProps>)[];
  loading?: boolean | number;
  empty?: boolean;
  emptyMessage?: React.ReactElement<IEmptyMessageProps>;
  error?: boolean;
  grow?: boolean;
}


const GenericWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .loader {
    z-index: 9998;
  }
  .empty-message {
    margin: ${Spacing.Large}px;
  }
`;

const SpreadsheetWrapper = styled.table<{grow?: boolean }>`
  position: relative;
  width: 100%;
  table-layout: fixed;
  box-sizing: box-content;
  // margin-bottom: ${Spacing.Large}px;
  border: 1px solid ${neutral[300]};
  border-radius: ${BorderRadius.Default}px;
  background: ${neutral[100]};

  ${fontStyle(textFont.roman, typescale.paragraph, neutral[700])}

  ${({ grow }) =>
    grow
      ? // Growing
        `
      display: flex;
      flex-flow: column;
      flex-grow: 1;
      justify-content: stretch;

      thead {
        display: flex;
        flex-flow: row;
        justify-content: stretch;
        padding-right: 16px;
        border-bottom: 1px solid ${neutral[300]};

        th {
          flex: 1;
        }
      }

      tbody {
        display: block;
        flex-grow: 1;
        flex-basis: 0;
        flex-shrink: 1;
        min-height: 350px;
        overflow-y: auto;
        width: 100%;
        ${formatScrollbars(neutral[100])}
      }

      tr {
        display: flex;
        flex-flow: row;
        flex: 1;
      }

      td {
        display: flex;
        flex-flow: row;
        flex: 1;
        align-items: center;
        flex-shrink: 0;
      }
    `
      : // Not growing
        `      
          th {
            border-bottom: 1px solid ${neutral[300]};
          }
    `}
`;

const HeaderWrapper = styled.thead`  
  color: ${neutral[600]};  
`;

const RowWrapper = styled.tr`
  width: 100%;

  &:last-child td {
    border-bottom: 0 !important;
  }
`;

const HeaderCellWrapper = styled.th<{width?: number, right?: boolean, center?: boolean}>`
  position: relative;
  padding: ${Spacing.Default}px;
  vertical-align: middle;
  box-sizing: border-box;
  transition: all ease 350ms;
  ${fontStyle(textFont.medium, typescale.sub, neutral[500])}

  ${({ width }) =>
    width && 
    `
    width: ${width * 10}%;
    minWidth: ${width * 10}%;
    flexBasis: ${width * 10}%;
  `}
  ${({ right }) => right && `text-align: right; justify-content: flex-end;`}
  ${({ center }) => center && `text-align: center;  justify-content: center;`}
`;

const BodyWrapper = styled.tbody`
  width: 100%;

  tr.table-loader {
    td div {
      text-align: center;
      width: 100%;
      padding: ${Spacing.Default}px 0 ${Spacing.Small}px 0;
    }
  }
`;

const CellWrapper = styled.td<{width?: number, right?: boolean, center?: boolean}>`
  overflow-wrap: break-word;
  padding: ${Spacing.Default}px;
  vertical-align: middle;
  word-wrap: break-word;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 1px solid ${neutral[200]};
  ${fontStyle(textFont.roman, typescale.paragraph, neutral[600])}

  ${({ width }) =>
  width && 
    `
    width: ${width * 10}%;
    minWidth: ${width * 10}%;
    flexBasis: ${width * 10}%;
  `}
  ${({ right }) => right && `text-align: right; justify-content: flex-end;`}
  ${({ center }) => center && `text-align: center;  justify-content: center;`}

  > .icon, > i { 
    margin-left: ${Spacing.Medium}px;
  }
`;

const CellEditor = styled.textarea`
  resize: none;
  padding: 0;
  width: 100%;
  ${fontStyle(textFont.roman, typescale.paragraph, neutral[600])}
`;

const FooterWrapper = styled.tfoot`
  td {
    border-top: 1px solid ${neutral[300]};
  }
`;

const CharCount = styled.span<{ invalid?: boolean }>`
  float: right;
  ${({ invalid }) => invalid && `
    color: ${Swatches.Danger.swatch}
  `}
`;


const Spreadsheet: React.FC<ISpreadsheetProps> & {
  Header: typeof SpreadsheetHeader;
  HeaderCell: typeof SpreadsheetHeaderCell;
  Body: typeof SpreadsheetBody;
  Row: typeof SpreadsheetRow;
  Cell: typeof SpreadsheetCell;
  Footer: typeof SpreadsheetFooter;
} = ({
  children,
  loading,
  empty,
  emptyMessage,
  error,
  grow
}) => {

  if (error) {
    return (
      <GenericWrapper>
        <EmptyMessage icon="times-circle" title="A problem occurred" cover />
      </GenericWrapper>
    );
  }

  if (loading) {
    return (
      <GenericWrapper>
        <Loader size={Size.Medium} cover />
      </GenericWrapper>
    );
  }

  if (!loading && empty) {
    return <GenericWrapper>{emptyMessage}</GenericWrapper>;
  }

  return (
    <SpreadsheetWrapper grow={grow}>
      { children }
    </SpreadsheetWrapper>
  );
}


const SpreadsheetRow: React.FC<ISpreadsheetRowProps> = ({ children, rowIndex, onClick, onCellChange, rowIdentifier }) => {

  const handleClick = () => {
    onClick?.();
  };

  if (children == null) {
    return (
      <RowWrapper onClick={handleClick}>
      </RowWrapper>
    )
  }

  return (
    <RowWrapper onClick={handleClick}>
      { Array.isArray(children) 
        ? children.map((child, index) => React.cloneElement(child, { 
            key: index, 
            rowIndex: rowIndex, 
            rowIdentifier: rowIdentifier,
            onCellChange: onCellChange, 
            cellIndex: index 
          }))
        : React.cloneElement(children, { 
            rowIndex: rowIndex, 
            rowIdentifier: rowIdentifier, 
            cellIndex: 1 
          })
      }
    </RowWrapper>
  )
}


const SpreadsheetHeader: React.FC<ISpreadsheetHeaderProps> = ({ children }) => {

  return (
    <HeaderWrapper>
      <SpreadsheetRow>
        { children }
      </SpreadsheetRow>
    </HeaderWrapper>
  )
}


const SpreadsheetHeaderCell: React.FC<ISpeadsheetHeaderCellProps> = ({ children, width, show, right, center }) => {

  return (
    <HeaderCellWrapper width={width} right={right} center={center}>
      { children }
    </HeaderCellWrapper>
  )
}


const SpreadsheetBody: React.FC<ISpreadsheetBodyProps> = ({ children, onCellChange }) => {

  return (
    <BodyWrapper>
      { Array.isArray(children) 
        ? children.map((child, index) => React.cloneElement(child, { 
            key: index, 
            rowIndex: index, 
            onCellChange: onCellChange 
          }))
        : React.cloneElement(children, { rowIndex: 1 })
      }
    </BodyWrapper>
  )
}

declare const InstallTrigger: any;

const SpreadsheetCell: React.FC<ISpeadsheetCellProps> = ({ 
  children, 
  width, 
  show, 
  rowIndex, 
  cellIndex, 
  onCellChange, 
  cellIdentifier, 
  rowIdentifier, 
  readonly, 
  center, 
  right, 
  noCloseOnEnter, 
  colspan,
  maxLength,
  characterCount
}) => {

  const [cellValue, setCellValue] = useState<string | undefined>();
  const [inEdit, setInEdit] = useState<boolean>(false);
  const ref = useRef() as RefObject<HTMLTextAreaElement>;

  const handleBlur = (event: MouseEvent) => {
    if (ref.current) {
      // Workaround for Chrome because Chrome is a bad browser 
      // https://stackoverflow.com/questions/16951635/javascript-onclick-does-not-work-when-highlighting-text-in-p
      const isFirefox = typeof InstallTrigger !== 'undefined';
      if (isFirefox) {
        if (!ref.current.contains(event.target as Element)) {
          setInEdit(false);
        }
      } else {
        var isHighlighted = (ref.current.selectionStart !== ref.current.selectionEnd);
        if (!ref.current.contains(event.target as Element) && (isHighlighted == false)) {
          setInEdit(false);
        } else if ((isHighlighted == true) && !ref.current.contains(event.target as Element)) {
          ref.current.selectionStart = 0;
          ref.current.selectionEnd = 0;
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', (e) => handleBlur(e), true);
    return () => document.removeEventListener('click', (e) => handleBlur(e));
  }, []);

  useEffect(() => {
    if (children && typeof children == "string") {
      setCellValue(children);
    } else if (children == null) {
      setCellValue("");
    }
  }, [children])

  useEffect(() => {
    if (cellValue && !inEdit) {
      onCellChange?.({cellValue, rowIndex, cellIndex, rowIdentifier, cellIdentifier});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inEdit])

  useEffect(() => {
    if (ref.current && inEdit) {
      ref.current.style.height = "0px";
      const scrollHeight = ref.current.scrollHeight;
      ref.current.style.height = scrollHeight + "px";
    }
  }, [cellValue, inEdit])

  const handleClick = () => {
    if (!readonly && (typeof children == "string" || children == null)) {
      setInEdit(true);
    }
  }

  const handleChange = (e: any) => {
    setCellValue(e.target.value);
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && !noCloseOnEnter) {
      setInEdit(false);
    }
  }

  const CharacterCount = () => {
    return (
      maxLength && characterCount ? (
        <CharCount invalid={cellValue && maxLength && cellValue.length > maxLength}>
          {cellValue ? cellValue.length : 0}/{maxLength}
        </CharCount>
      ) : (
        <></>
      )
    );
  };

  return (
    <CellWrapper onDoubleClick={handleClick} width={width} right={right} center={center} {...(colspan && { colSpan: colspan })}>
      { inEdit
        ? <>
            <CellEditor 
              ref={ref} 
              value={cellValue} 
              onChange={handleChange} 
              onKeyDown={handleKeyPress}
              autoFocus
            />
            <CharacterCount />
          </>
        : (cellValue !== null && cellValue !== undefined )
          ? <>
              { cellValue }
              { !readonly && (
                <Icon value="pencil" color={neutral[500]} />
              )}
            </>
          : children
      }
    </CellWrapper>
  )
}

const SpreadsheetFooter: React.FC<ISpreadsheetFooterProps> = ({ children }) => {

  const immutableChildren = Array.isArray(children) 
    ? children.map((child, index: number) => React.cloneElement<ISpeadsheetCellProps>(child, { 
        key: index, 
        readonly: true
      }))
    : React.cloneElement<ISpeadsheetCellProps>(children, { rowIndex: 1, readonly: true });

  return (
    <FooterWrapper>
      <SpreadsheetRow>
        { immutableChildren }
      </SpreadsheetRow>
    </FooterWrapper>
  )
};


Spreadsheet.Header = SpreadsheetHeader;
Spreadsheet.HeaderCell = SpreadsheetHeaderCell;
Spreadsheet.Body = SpreadsheetBody;
Spreadsheet.Row = SpreadsheetRow;
Spreadsheet.Cell = SpreadsheetCell;
Spreadsheet.Footer = SpreadsheetFooter;
export default Spreadsheet;
