import React from "react";
import { ReactComponent as StarSvg } from "assets/primary/behaviour.svg";
import { IPrimaryRocketCharacterProps } from "./rockets/primaryRocket";


export const BehaviourStar: React.FC<IPrimaryRocketCharacterProps> = ({ className }) => {

  return <StarSvg className={className} />;
};

export default BehaviourStar;
