import { SchoolBehaviourCodeListView } from "areas/behaviour/types/behaviourResponses.types";
import React, { useEffect, useState } from "react";
import { Checkbox } from "ui-kit";


interface IBehaviourCodeCheckboxProps {
  code: SchoolBehaviourCodeListView;
  selectedBehaviourCodes: SchoolBehaviourCodeListView[];
  handleCodeClick: (code: SchoolBehaviourCodeListView, isChecked: boolean) => void;
}

const BehaviourCodeCheckbox: React.FC<IBehaviourCodeCheckboxProps> = ({
  code,
  selectedBehaviourCodes,
  handleCodeClick
}) => {

  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    const tempCode = selectedBehaviourCodes.find(
      behaviourCode => behaviourCode.id === code.id
    );

    if (tempCode !== undefined) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [selectedBehaviourCodes]);

  const handleCheckboxChange = () => {
    const newIsChecked = !isChecked;
    handleCodeClick(code, newIsChecked);
    setIsChecked(newIsChecked);
  };

  return (
    <Checkbox
      text={code.codeName}
      checked={isChecked}
      onChange={handleCheckboxChange}
    />
  );
};

export default BehaviourCodeCheckbox;
