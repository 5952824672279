import React, { useEffect, useState } from "react";
import {
  Title,
  TitleSize,
  Loader,
  EmptyMessage,
  Card,
  Size,
  Subtitle,
  ActionBar,
  Button,
  Swatches,
  Message,
  Sub,
  Chip,
  Table,
} from "ui-kit";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { arrays, strings } from "utils";
import documentGroupActions from "areas/administration/actions/documentGroupActions";
import Subheader from "sharedComponents/layout/header/subheader";
import FileUpload from "sharedComponents/common/files/fileUpload";
import Avatar from "sharedComponents/common/users/avatar";
import { RootState } from "reducers/store";
import { UploadDocumentsCommand } from "areas/administration/types/documentRequest.types";
import { UploadDocumentsFileResult, UploadDocumentsResult } from "areas/administration/types/documentResponse.types";
import { IFileMetadata } from "types/common/files.types";


interface uploadFiles extends IFileMetadata, Omit<UploadDocumentsFileResult, "id"> {
  status: number;
}


const Upload = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const { error, group, loading, uploading, uploadResults, uploadError } = useSelector((state: RootState) => state.documentGroup);
  const [_files, _setFiles] = useState<uploadFiles[]>([]);
  const [_result, _setResult] = useState<UploadDocumentsResult | null>(null);

  useEffect(() => {
    id && documentGroupActions.getDocumentGroup(parseInt(id));
  }, [id]);

  const handleUpload = () => {
    const data: UploadDocumentsCommand = {
      documentGroupId: parseInt(id),
      files: _files.filter(x => x.status === -1).map(x => Number(x.id)),
    };

    documentGroupActions.uploadToDocumentGroup(data, response => {
      _setResult(response);
      _setFiles(
        _files
          .filter(x => x.status === -1)
          .map((file, index) => ({ ...file, ...response.files[index] }))
      );
    });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleFilesUploaded = (uploadedFiles: IFileMetadata[]) => {
    var data = uploadedFiles as uploadFiles[];
    _setFiles([...data.map(x => ({ ...x, status: -1 }))]);
  };

  const removeFile = (file: uploadFiles) => {
    _setFiles([...arrays.removeItem(_files, file)]);
  };

  const getStatusChip = (file: uploadFiles) => {
    switch (file.status) {
      case 0:
        return <Chip text="OK" colorSwatch={Swatches.Success} />;
      case 1:
        return <Chip text="Failed" colorSwatch={Swatches.Danger} />;
      default:
        return <Chip text="Pending" colorSwatch={Swatches.Low} />;
    }
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="An error occurred"
        summary="There was an error while retrieving the document group."
        cover
      />
    );
  }

  if (!group) {
    return (
      <EmptyMessage
        icon="file-certificate"
        title="Not Found"
        summary="The requested document group was not found."
        cover
      />
    );
  }

  return (
    <>
      <Subheader>
        <Title size={TitleSize.H2} text="Documents" sub="Upload" />
      </Subheader>
      <Subtitle text={`Upload to ${group?.name}`} />
      <div>
        <FileUpload onUpload={handleFilesUploaded} />
      </div>

      {uploadError && <Message text={uploadError} color={Swatches.Danger} />}

      {_result?.status === 0 && (
        <Message
          text={`${_result.successCount} ${strings.pluralize(
            "file",
            "files",
            _result.successCount
          )} uploaded successfully.`}
          color={Swatches.Success}
        />
      )}
      {_result?.status === 1 && (
        <Message
          text={`The upload failed. ${_result.failureCount} ${strings.pluralize(
            "file",
            "files",
            _result.failureCount
          )} failed and ${_result.successCount} ${strings.pluralize(
            "file",
            "files",
            _result.successCount
          )} uploaded successfully. Please see the Selected Files table for further details.`}
          color={Swatches.Danger}
        />
      )}

      {!arrays.isEmpty(_files) && (
        <Card title="Selected Files">
          <Card.Body noPad>
            <Table>
              <Table.Header>
                <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                <Table.HeaderCell width={2}>Filename</Table.HeaderCell>
                <Table.HeaderCell width={3}>Matched User</Table.HeaderCell>
                <Table.HeaderCell width={3}>Status Detail</Table.HeaderCell>
                <Table.HeaderCell width={2}></Table.HeaderCell>
              </Table.Header>
              <>
              {_files.map((file: uploadFiles, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell width={1}>{getStatusChip(file)}</Table.Cell>
                  <Table.Cell width={2}>{file.filename}</Table.Cell>
                  <Table.Cell width={3}>
                    {file.user && (
                      <Avatar user={file.user} sub={file.user.school.name} />
                    )}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    <Sub>{file.statusDetail}</Sub>
                  </Table.Cell>
                  <Table.Cell right width={2}>
                    {!(file.status >= 0) && (
                      <Button
                        size={Size.Small}
                        color={Swatches.Danger}
                        text="Remove"
                        onClick={() => removeFile(file)}
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
              </>
            </Table>
          </Card.Body>
        </Card>
      )}

      <ActionBar>
        <Button
          text="Upload"
          onClick={handleUpload}
          color={Swatches.Success}
          fluid
          disabled={!_files.length || _files.every(x => x.status >= 0)}
          working={uploading}
        />
        <Button
          text="Back"
          onClick={handleCancel}
          color={Swatches.Low}
          fluid
          working={uploading}
        />
      </ActionBar>
    </>
  );
};

export default Upload;
