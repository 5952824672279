import React from "react";
import { Table, Swatches } from "ui-kit";
import BehaviourCountChip from "../behaviour/behaviourCountChip";
import styled from "styled-components";
import { StudentBehaviourReportView } from "areas/planner/types/plannerReportResponse.types";
import { CategoryType } from "areas/analytics/types/analytics.shared";
import { BehaviourIncludeType } from "areas/analytics/types/behaviourAnalyticsShared.types";


const Wrapper = styled.div`
  .table {
    width: unset;
  }
`;


interface IStudentBehaviourTableProps {
  report: StudentBehaviourReportView;
  categoryType: BehaviourIncludeType;
}

const StudentBehaviourTable: React.FC<IStudentBehaviourTableProps> = ({ report, categoryType }) => {
  const rewardsTable = (
    <Table>
      <Table.Header>
        <Table.HeaderCell>Rewards</Table.HeaderCell>
        <Table.HeaderCell center>
          <BehaviourCountChip
            count={report.totalRewardPoints}
            color={Swatches.Success}
          />
        </Table.HeaderCell>
      </Table.Header>

      <>
      {report.rewards.map(reward => (
        <Table.Row>
          <Table.Cell>
            <b>{`${reward.codeName} - ${reward.categoryName}`}</b>
          </Table.Cell>
          <Table.Cell center>
            <BehaviourCountChip count={reward.total} color={Swatches.Success} />
          </Table.Cell>
        </Table.Row>
      ))}
      </>
    </Table>
  );

  const behavioursTable = (
    <Table>
      <Table.Header>
      <Table.HeaderCell>Behaviours</Table.HeaderCell>
      <Table.HeaderCell center>Points</Table.HeaderCell>
      <Table.HeaderCell center>Counts</Table.HeaderCell>
      </Table.Header>
      <Table.Header>
        <Table.HeaderCell>Total</Table.HeaderCell>
        <Table.HeaderCell center>
          <BehaviourCountChip
            count={report.totalBehaviourPoints}
            color={Swatches.Danger}
          />
        </Table.HeaderCell>
        <Table.HeaderCell center>
          <BehaviourCountChip
            count={report.totalBehaviourCount}
            color={Swatches.Danger}
          />
        </Table.HeaderCell>
      </Table.Header>

      <>
      {report.behaviours.map(behaviour => (
        <Table.Row>
          <Table.Cell>
            <b>{`${behaviour.codeName} - ${behaviour.categoryName}`}</b>
          </Table.Cell>
          <Table.Cell center>
            <BehaviourCountChip
              count={behaviour.total}
              color={Swatches.Danger}
            />
          </Table.Cell>
          <Table.Cell center>
            <BehaviourCountChip
              count={behaviour.totalCount}
              color={Swatches.Danger}
            />
          </Table.Cell>
        </Table.Row>
      ))}
      </>
    </Table>
  );

  return (
    <Wrapper>
      {categoryType === BehaviourIncludeType.Reward ? (
        rewardsTable
      ) : categoryType === BehaviourIncludeType.Behaviour ? (
        behavioursTable
      ) : (
        <>
          {report.totalRewardPoints > 0 && rewardsTable}
          <br />
          {report.totalBehaviourPoints > 0 && behavioursTable}
        </>
      )}
    </Wrapper>
  );
};

export default StudentBehaviourTable;
