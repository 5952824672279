import React from "react";
import {
  Flyout,
  HeadlineStatistic,
  Currency,
  Card,
  Title,
  TitleSize,
  Size,
  Table,
  StructuredList
} from "ui-kit";
import { Constants } from "configuration";
import flyoutActions from "actions/ui/flyouts";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import { Avatar, CardLogo } from "sharedComponents/common";
import { PaymentView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { UserTinyView } from "types/users/userListViews.types";


interface IPaymentFlyoutProps {
  payment: PaymentView;
}


const PaymentFlyout: React.FC<IPaymentFlyoutProps> = ({ payment }) => {

  const navigate = useNavigate();

  const handleClose = () => {
    flyoutActions.closeFlyout();
  };

  const handleUserClicked = (user: UserTinyView) => {
    flyoutActions.closeFlyout();
    navigate(`/main/shopkeeper/users/${user.id}`);
  };

  return (
    <Flyout
      title={`Payment ${payment && payment.id}`}
      titleSub={`${payment &&
        payment.createdBy.firstName + " " + payment.createdBy.lastName}`}
      name={Constants.FLYOUTS.PAYMENT}
      wide
    >
      <Flyout.Body>
        {payment && (
          <>
            <HeadlineStatistic>
              <HeadlineStatistic.Item
                icon="receipt"
                label="Total"
                value={<Currency value={payment.total} />}
              />
            </HeadlineStatistic>
            <Card>
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="ID">
                    {payment.id}
                  </StructuredList.Item>
                  <StructuredList.Item name="Basket ID">
                    {payment.transaction.basketId}
                  </StructuredList.Item>
                  <StructuredList.Item name="Date">
                    <Moment
                      date={payment.createdDate}
                      format="DD/MM/YYYY HH:mma"
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="By">
                    <Avatar
                      user={payment.createdBy}
                      size={Size.Small}
                      onClick={() => handleUserClicked(payment.createdBy)}
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="Transaction ID">
                    {payment.transaction.id}
                  </StructuredList.Item>
                  <StructuredList.Item name="Method">
                    {payment.transaction.method}
                  </StructuredList.Item>
                  <StructuredList.Item name="Card Type">
                    <CardLogo
                      type={payment.transaction.cardType}
                      className="card-logo"
                    />{" "}
                    {payment.transaction.cardTypeName}
                  </StructuredList.Item>
                  <StructuredList.Item name="Reference">
                    {payment.transaction.reference}
                  </StructuredList.Item>
                  <StructuredList.Item name="Expiry">
                    {payment.transaction.expiryDate}
                  </StructuredList.Item>
                  <StructuredList.Item name="Vendor Name">
                    {payment.transaction.vendorName}
                  </StructuredList.Item>
                  <StructuredList.Item name="Bank Auth Code">
                    {payment.transaction.bankAuthorisationCode}
                  </StructuredList.Item>
                  <StructuredList.Item name="Provider ID">
                    {payment.transaction.providerTransactionId}
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
            <Title size={TitleSize.H3} text="Allocations" />
            <Card>
              <Card.Body noPad>
                <Table>
                  <Table.Header>
                    <Table.HeaderCell>Item</Table.HeaderCell>
                    <Table.HeaderCell right>Total</Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    {payment.allocations.map((allocation, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>
                          {allocation.orderLine.description}
                        </Table.Cell>
                        <Table.Cell width={1} right>
                          <Currency value={allocation.total} />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Cell right colspan={2}>
                      <b>
                        <Currency value={payment.total} />
                      </b>
                    </Table.Cell>
                  </Table.Footer>
                </Table>
              </Card.Body>
            </Card>
          </>
        )}
      </Flyout.Body>
    </Flyout>
  );
};

export default PaymentFlyout;
