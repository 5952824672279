import React from "react";
import styled from "styled-components";
import {
  BorderRadius,
  Spacing,
  fontStyle,
  typescale,
  neutral,
  displayFont,
  Swatches,
  boxShadow,
  IUiKitBaseProps,
  Icon,
} from "../index";

const Wrapper = styled.div`
  display: flex;
  margin-bottom: ${Spacing.Large}px;
  background: ${neutral[300]};
  border-radius: ${BorderRadius.Default}px;
`;

const ItemWrapper = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 0;
  flex-grow: 1;

  margin-right: ${Spacing.Large}px;
  padding: ${Spacing.Medium}px;
  text-align: center;
  // box-shadow: ${boxShadow};
  // border: 1px solid ${neutral[300]};

  &:last-child {
    margin-right: 0;
  }

  .headline-statistic-icon {
    display: block;
    // color: ${neutral[100]};
    font-size: 24px;
    text-align: center;
    margin-bottom: ${Spacing.Default}px;
  }

  ${({ color }) =>
    color
      ? `.headline-statistic-icon {
    color: ${color};
  }`
      : `.headline-statistic-icon {
    color: ${neutral[100]};
  }`}

  .headline-statistic-value {
    display: block;
    ${fontStyle(displayFont.light, typescale.header2, neutral[600])};
  }

  .headline-statistic-unit {
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    margin-bottom: ${Spacing.Small}px;
    ${fontStyle(displayFont.roman, typescale.helper, neutral[500])};
  }

  .headline-statistic-label {
    display: block;
    ${fontStyle(displayFont.medium, typescale.paragraph, Swatches.Blue.swatch)};
  }
`;

interface IHeadlineStatisticProps extends IUiKitBaseProps {
  children?: any | any[];
}

const HeadlineStatistic: React.FC<IHeadlineStatisticProps> & {
  Item: typeof HeadlineStatisticItem;
} = ({ children, className }) => {
  return (
    <>
      <Wrapper className={`headline-statistic ${className ? className : ""}`}>
        {children}
      </Wrapper>
    </>
  );
};

interface IHeadlineStatisticItemProps extends IUiKitBaseProps {
  children?: any | any[];
  icon?: string;
  label?: string;
  value?: string | number | JSX.Element;
  unit?: string;
  color?: string;
}

const HeadlineStatisticItem: React.FC<IHeadlineStatisticItemProps> = ({
  icon,
  label,
  value,
  unit,
  className,
  color,
}) => {
  return (
    <ItemWrapper
      className={`headline-statistic-item ${className ? className : ""}`}
      color={color}
    >
      {icon && <Icon className="headline-statistic-icon" value={icon} />}
      <span className="headline-statistic-value">{value}</span>
      {unit && <span className="headline-statistic-unit">{unit}</span>}
      <span className="headline-statistic-label">{label}</span>
    </ItemWrapper>
  );
};

HeadlineStatistic.Item = HeadlineStatisticItem;

export default HeadlineStatistic;
