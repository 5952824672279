import React, { useState, useEffect } from "react";
import { IHolidayAndLeaveReportProps } from ".";
import { Dropdown, StructuredList } from "ui-kit";
import { SchoolDropdown } from "sharedComponents/common";
import { useAppSelector } from "reducers/hooks";


const HolidayCardReport: React.FC<IHolidayAndLeaveReportProps> = ({ filter, setFilter }) => {

  const { user } = useAppSelector(state => state.currentUser);

  const userHasMultipleSchools = () => user.schools.length > 0;

  return (
    <>
      <StructuredList.Item name="Academic Year">
        <Dropdown
          value={filter.academicYearId}
          fluid
          onChange={val =>
            setFilter({ ...filter, academicYearId: val })
          }
        >
          <Dropdown.Item label="All Academic Years" value={null} />
          <Dropdown.Item label="2023/24" value={10} />
          <Dropdown.Item label="2022/23" value={9} />
          <Dropdown.Item label="2021/22" value={8} />
        </Dropdown>
      </StructuredList.Item>

      <StructuredList.Item name="School">
        <SchoolDropdown
          initialValue={filter.schoolId}
          includeAllOption={userHasMultipleSchools()}
          fluid
          schools={user.schools}
          onChange={val => setFilter({ ...filter, schoolId: val })}
        />
      </StructuredList.Item>

      <StructuredList.Item name="Staff Type">
        <Dropdown
          value={filter.staffType}
          fluid
          onChange={val => setFilter({ ...filter, staffType: val })}
        >
          <Dropdown.Item label="All Staff Types" value={null} />
          <Dropdown.Item label="Teaching" value={0} />
          <Dropdown.Item label="Associate" value={1} />
        </Dropdown>
      </StructuredList.Item>
    </>
  );
}


export default HolidayCardReport;