import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Moment from "react-moment";
import { arrays } from "utils";
import styled from "styled-components";
import { Constants } from "configuration";
import {
  Title,
  TitleSize,
  Tabs,
  Card,
  StructuredList,
  Loader,
  Size,
  EmptyMessage,
  Swatches,
  Button,
  Right,
  Spacing,
  neutral,
  ToastService,
} from "ui-kit";
import matriculationActions from "../actions/matriculationActions";
import { RootState } from "reducers/store";
import { MatriculationStudentDetailView } from "../types/matriculationResponses.types";


const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${Spacing.Default}px;
  .title {
    color: ${neutral[500]};
  }
`;


const MatriculationStudent = () => {

  const { schoolId, sessionId, studentId } = useParams();
  const { student, loading, error } = useSelector((state: RootState) => state.matriculationSessions.selectedStudent);

  useEffect(() => {
    matriculationActions.getMatriculationStudent(
      parseInt(schoolId),
      parseInt(sessionId),
      studentId
    );
  }, []);

  const HandleProcess = () => {
    if (
      window.confirm(
        "This will mark this student as having been processed. Are you sure?"
      )
    ) {
      matriculationActions.processStudent(parseInt(schoolId), parseInt(sessionId), studentId, () =>
        ToastService.pop("Student processed", null, "user")
      );
    }
  };

  const isPrimary = (schoolId: number) => {
    if (!schoolId) {
      return;
    }
    const school = Constants.SCHOOLS.find(school => school.id == schoolId);
    return school.isPrimary;
  };

  return (
    <>
      <Wrapper>
        <Title
          size={TitleSize.H2}
          text="Matriculation Submission"
          sub={!loading && `${student?.firstName} ${student?.lastName}`}
        />
        {!student?.processed && (
          <Right>
            <Button
              text="Mark as processed"
              onClick={HandleProcess}
              color={Swatches.Success}
              size={Size.Medium}
            />
          </Right>
        )}
      </Wrapper>
      <Tabs>
        <Tabs.Pane name="general" label="General">
          <StudentGeneral student={student} error={error} />
        </Tabs.Pane>
        <Tabs.Pane name="previousSchool" label="Previous School">
          <StudentPreviousSchool student={student} />
        </Tabs.Pane>
        <Tabs.Pane name="medical" label="Medical">
          <StudentMedical student={student} error={error} />
        </Tabs.Pane>
        <Tabs.Pane name="cultural" label="Cultural">
          <StudentCulture student={student} error={error} />
        </Tabs.Pane>
        <Tabs.Pane name="dietaryAndTravel" label="Dietary & Travel">
          <StudentDietary 
            student={student} 
            error={error} 
            isPrimary={isPrimary(parseInt(schoolId))}
          />
        </Tabs.Pane>
        <Tabs.Pane name="parents" label="Parents">
          <StudentParents student={student} error={error} />
        </Tabs.Pane>
        <Tabs.Pane name="consent" label="Consent">
          <StudentConsent
            student={student}
            error={error}
            isPrimary={isPrimary(parseInt(schoolId))}
          />
        </Tabs.Pane>
      </Tabs>
    </>
  );
};


interface IMatriculationStudentSectionProps {
  student: MatriculationStudentDetailView;
  error?: string;
}


const StudentGeneral: React.FC<IMatriculationStudentSectionProps> = ({ student, error }) => {

  if (!student) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem loading the matriculation submission details"
        icon="times-circle"
        iconColor={Swatches.Danger.swatch}
        cover
      />
    );
  }

  return (
    <>
      <Card title="Details">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name={"First Name"}>
              {student.firstName}
            </StructuredList.Item>
            <StructuredList.Item name={"Last Name"}>
              {student.lastName}
            </StructuredList.Item>
            <StructuredList.Item name={"Middle Name"}>
              {student.middleName}
            </StructuredList.Item>
            <StructuredList.Item name={"Preferred First Name"}>
              {student.preferredFirstName}
            </StructuredList.Item>
            <StructuredList.Item name={"Preferred Last Name"}>
              {student.preferredLastName}
            </StructuredList.Item>
            <StructuredList.Item name={"Date Of Birth"}>
              <Moment format="DD/MM/YYYY" date={student.dateOfBirth} />
            </StructuredList.Item>
            <StructuredList.Item name={"Gender"}>
              {student.gender}
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      <Card title="Siblings">
        <Card.Body>
          {student.siblings ? (
            student.siblings.split("\n").map((sibling: string, index: number) => (
              <StructuredList.Item key={index} name={`Sibling ${index + 1}`}>
                {sibling}
              </StructuredList.Item>
            ))
          ) : (
            <p>No Recorded Siblings</p>
          )}
        </Card.Body>
      </Card>

      <Card title="Contact">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name={"Home Telephone"}>
              {student.homeTelephone}
            </StructuredList.Item>
            <StructuredList.Item name={"Mobile Telephone"}>
              {student.mobileTelephone}
            </StructuredList.Item>
            <StructuredList.Item name={"Address"}>
              {student.address1} {student.address2} {student.address3}{" "}
              {student.town} {student.county} {student.postcode}
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      <Card title="Submission">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name={"Signed"}>
              {student.signatory}
            </StructuredList.Item>
            <StructuredList.Item name={"Signatory Relationship"}>
              {student.signatoryRelationship}
            </StructuredList.Item>
            <StructuredList.Item name={"Processed"}>
              {student.processed ? (
                <Moment format="DD/MM/YYYY" date={student.processedDate} />
              ) : (
                "Not Processed"
              )}
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
    </>
  );
};


const StudentPreviousSchool: React.FC<IMatriculationStudentSectionProps> = ({ student, error }) => {

  if (!student) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem loading the matriculation submission details"
        icon="times-circle"
        iconColor={Swatches.Danger.swatch}
        cover
      />
    );
  }

  return (
    <>
      <Card>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name={"Previous School"}>
              {student.previousSchool}
            </StructuredList.Item>
            <StructuredList.Item name={"Date Attended"}>
              <Moment format="DD/MM/YYYY" date={student.dateAttendedFrom} /> -{" "}
              <Moment format="DD/MM/YYYY" date={student.dateAttendedTo} />
            </StructuredList.Item>
            <StructuredList.Item name={"Reason for Leaving"}>
              {student.leavingReason}
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
    </>
  );
};


const StudentMedical: React.FC<IMatriculationStudentSectionProps> = ({ student, error }) => {

  if (!student) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem loading the matriculation submission details"
        icon="times-circle"
        iconColor={Swatches.Danger.swatch}
        cover
      />
    );
  }

  return (
    <>
      <Card>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name={"Doctor's Name"}>
              {student.doctorName}
            </StructuredList.Item>
            <StructuredList.Item name={"Practice Name"}>
              {student.doctorPractice}
            </StructuredList.Item>
            <StructuredList.Item name={"Address"}>
              {student.doctorAddress1} {student.doctorAddress2}{" "}
              {student.doctorAddress3} {student.doctorTown}{" "}
              {student.doctorCounty} {student.doctorPostcode}
            </StructuredList.Item>
            <StructuredList.Item name={"Medical Conditions"}>
              {student.hasMedicalConditions &&
                student.medicalConditions
                  .split("\n")
                  .map((condition, index) => <p key={index}>{condition}</p>)}
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
    </>
  );
};


const StudentCulture: React.FC<IMatriculationStudentSectionProps> = ({ student, error }) => {

  if (!student) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem loading the matriculation submission details"
        icon="times-circle"
        iconColor={Swatches.Danger.swatch}
        cover
      />
    );
  }

  return (
    <>
      <Card>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name={"Ethnicity"}>
              {student.ethnicity?.name}
            </StructuredList.Item>
            <StructuredList.Item name={"Nationality"}>
              {student.countryOfBirth?.nationality}
            </StructuredList.Item>
            <StructuredList.Item name={"Country of Birth"}>
              {student.countryOfBirth?.name}
            </StructuredList.Item>
            <StructuredList.Item name={"Home Languages"}>
              {!arrays.isEmpty(student.homeLanguages) &&
                student.homeLanguages.map((language, index) => (
                  <p key={index}>{language.name}</p>
                ))}
            </StructuredList.Item>
            <StructuredList.Item name={"First Language"}>
              {student.firstLanguage?.name}
            </StructuredList.Item>
            <StructuredList.Item name={"Religion"}>
              {!arrays.isEmpty(student.religions) &&
                student.religions.map((religion, index) => (
                  <p key={index}>{religion.name}</p>
                ))}
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
    </>
  );
};


interface IStudentDietaryProps extends IMatriculationStudentSectionProps {
  isPrimary: boolean;
}

const StudentDietary: React.FC<IStudentDietaryProps> = ({ student, error, isPrimary }) => {

  if (!student) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem loading the matriculation submission details"
        icon="times-circle"
        iconColor={Swatches.Danger.swatch}
        cover
      />
    );
  }

  return (
    <>
      <Card>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name={"Dietary Requirements"}>
              {!arrays.isEmpty(student.dietaryRequirements) &&
                student.dietaryRequirements.map((dietaryRequirement, index) => (
                  <p key={index}>{dietaryRequirement.name}</p>
                ))}
            </StructuredList.Item>
            <StructuredList.Item name={"Dietary Details"}>
              {student.dietaryDetails}
            </StructuredList.Item>
            <StructuredList.Item name={"Lunch Arrangements"}>
              {student.lunchArrangement}
            </StructuredList.Item>
            <StructuredList.Item name={"Travel Arrangements"}>
              {student.travelArrangement}
            </StructuredList.Item>
            {isPrimary && (
              <>
                <StructuredList.Item name={"Free School Meal Voucher"}>
                  {student.hasFreeSchoolMealVoucher ? "Yes" : "No"}
                </StructuredList.Item>
                <StructuredList.Item name={"Food Tasting"}>
                  {student.foodTasting ? "Yes" : "No"}
                </StructuredList.Item>
                <StructuredList.Item name={"Milk In School Scheme"}>
                  {student.milkScheme ? "Yes" : "No"}
                </StructuredList.Item>
                <StructuredList.Item name={"Fruit In School Scheme"}>
                  {student.fruitScheme ? "Yes" : "No"}
                </StructuredList.Item>
              </>
            )}
          </StructuredList>
        </Card.Body>
      </Card>
    </>
  );
};


const StudentParents: React.FC<IMatriculationStudentSectionProps> = ({ student, error }) => {

  if (!student) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem loading the matriculation submission details"
        icon="times-circle"
        iconColor={Swatches.Danger.swatch}
        cover
      />
    );
  }

  return (
    <Tabs>
      {student.parents.map((parent, index) => (
        <Tabs.Pane
          key={index}
          name={parent.relationshipToStudent}
          label={parent.relationshipToStudent}
        >
          <Card>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name={"Title"}>
                  {parent.titleString}
                </StructuredList.Item>
                <StructuredList.Item name={"First Name"}>
                  {parent.firstName}
                </StructuredList.Item>
                <StructuredList.Item name={"Last Name"}>
                  {parent.lastName}
                </StructuredList.Item>
                <StructuredList.Item name={"Relationship to Student"}>
                  {parent.relationshipToStudent}
                </StructuredList.Item>
                <StructuredList.Item name={"Contact Priority"}>
                  {parent.contactPriority}
                </StructuredList.Item>
                <StructuredList.Item name={"Parental Responsibility"}>
                  {parent.parentalResponsibility ? "Yes" : "No"}
                </StructuredList.Item>
                <StructuredList.Item name={"Receive Correspondence"}>
                  {parent.receiveCorrespondence ? "Yes" : "No"}
                </StructuredList.Item>
                <StructuredList.Item name={"Court Order"}>
                  {parent.courtOrder ? "Yes" : "No"}
                </StructuredList.Item>
                <StructuredList.Item name={"Armed Forces"}>
                  {parent.armedForces ? "Yes" : "No"}
                </StructuredList.Item>
                <StructuredList.Item name={"Home Telephone"}>
                  {parent.homeTelephone}
                </StructuredList.Item>
                <StructuredList.Item name={"Mobile Telephone"}>
                  {parent.mobileTelephone}
                </StructuredList.Item>
                <StructuredList.Item name={"Work Telephone"}>
                  {parent.workTelephone}
                </StructuredList.Item>
                <StructuredList.Item name={"Email"}>
                  {parent.email}
                </StructuredList.Item>
                <StructuredList.Item name={"Address"}>
                  {parent.address1} {parent.address2} {parent.address3}{" "}
                  {parent.town} {parent.county} {parent.postcode}
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
        </Tabs.Pane>
      ))}
    </Tabs>
  );
};


interface IStudentConsentProps extends IMatriculationStudentSectionProps {
  isPrimary: boolean;
}


const StudentConsent: React.FC<IStudentConsentProps> = ({ student, error, isPrimary }) => {

  if (!student) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem loading the matriculation submission details"
        icon="times-circle"
        iconColor={Swatches.Danger.swatch}
        cover
      />
    );
  }

  console.log(student);

  return (
    <>
      <Card>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name={"Use of Photographs"}>
              {student.photographPermission ? "Yes" : "No"}
            </StructuredList.Item>
            <StructuredList.Item
              name={"Agreement to inform school of data changes"}
            >
              {student.declarationChangeAgreement ? "Yes" : "No"}
            </StructuredList.Item>
            <StructuredList.Item
              name={"Agreement to inform school of medical changes"}
            >
              {student.medicalChangeAgreement ? "Yes" : "No"}
            </StructuredList.Item>
            <StructuredList.Item name={"Medical Treatment Consent"}>
              {student.medicalTreatmentAgreement ? "Yes" : "No"}
            </StructuredList.Item>
            {isPrimary && (
              <>
                <StructuredList.Item name={"Tapestry Online"}>
                  {student.tapestryPermission ? "Yes" : "No"}
                </StructuredList.Item>
                <StructuredList.Item name={"Walking Trips"}>
                  {student.walkingPermission ? "Yes" : "No"}
                </StructuredList.Item>
              </>
            )}
          </StructuredList>
        </Card.Body>
      </Card>
    </>
  );
};

export default MatriculationStudent;
