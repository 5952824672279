import { Dispatch } from "redux";

const types = {
  GLOBALERROR_DISMISS: "GLOBALERROR_DISMISS",
};

const Dismiss = (dispatch: Dispatch) : void => {
  dispatch({
    type: types.GLOBALERROR_DISMISS,
  });
};

const errorActions = {
  types,
  Dismiss
}

export default errorActions;
