import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Loader, EmptyMessage, Size, Subtitle } from "ui-kit";
import { arrays, users } from "utils";
import ReviewList from "./reviews/reviewList";
import { useNavigate } from "react-router-dom";
import reviewActions from "areas/humanResources/actions/reviewActions";
import { pmRoutes } from "areas/humanResources/hrRoutes";
import { PmStaffDetailView } from "areas/humanResources/types/managedStaffResponse.types";
import { RootState } from "reducers/store";
import config from "configuration/config";


const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;


interface IManagedStaffMemberProps {
  staffMember: PmStaffDetailView;
  loading?: boolean;
  error?: string;
}


const ManagedStaffMember: React.FC<IManagedStaffMemberProps> = ({ staffMember, loading, error }) => {
  
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { starting } = useSelector((state: RootState) => state.review);

  const navigate = useNavigate();

  const handleStartReview = () => {
    reviewActions.startReview(staffMember.id, newId => {
      navigate(pmRoutes.getStaffReviewPath(staffMember.id, newId));
    });
  };

  if (loading) {
    return (
      <Wrapper>
        <Loader size={Size.Large} cover />
      </Wrapper>
    );
  }

  if (error) {
    return (
      <Wrapper>
        <EmptyMessage
          icon="times-circle"
          title="A problem occurred"
          summary="There was a problem loading the staff member"
          cover
        />
      </Wrapper>
    );
  }

  if (!staffMember) {
    return (
      <Wrapper>
        <EmptyMessage
          icon="users"
          title="Not found"
          summary="The requested staff member was not found"
          cover
        />
      </Wrapper>
    );
  }

  const hasCurrentReview = () => {
    return staffMember.reviews.some(x => x.academicYear == config.academicYear.currentName);
  }

  return (
    <>
      <Subtitle text={`Reviews for ${users.getFullName(staffMember)}`} />
      <ReviewList
        reviews={staffMember.reviews}
        allowStartReview={(user.id != staffMember.id) && !hasCurrentReview()}
        onStartReview={handleStartReview}
        starting={starting}
      />
    </>
  );
};

export default ManagedStaffMember;
