import React from "react";
import { Constants } from "configuration";
import {
  Chip,
  neutral
} from "ui-kit";


interface IWpEventChipProps {
  categoryId: number;
}


const WpEventChip: React.FC<IWpEventChipProps> = ({ categoryId }) => {

  const getCategory = (categoryId: number) => {
    if (!categoryId) {
      return;
    }
    return Constants.WALLPLANNER_CATEGORIES.find(
      category => category.id == categoryId
    );
  };

  var category = getCategory(categoryId);
  const swatch = {
    swatch: category?.swatch,
    foreground: neutral[700],
  };

  return <Chip text={category?.name} colorSwatch={swatch} fluid />;
};


export default WpEventChip;