import React, { useEffect, useState } from "react";
import { Dropdown } from "ui-kit";
import { arrays } from "utils";
import detentionTypesActions from "../../actions/detentions/detentionTypesActions";
import { useAppSelector } from "reducers/hooks";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";


interface IDetentionTypesDropdownProps {
  schoolId: number;
  selected?: number;
  onChange: (value: number) => void;
}


const DetentionTypesDropdown: React.FC<IDetentionTypesDropdownProps> = ({ schoolId, selected, onChange }) => {

  const { detentionTypes, loading, error } = useAppSelector(
    state => state.detentionTypes
  );
  const [detentionTypesArray, setDetentionTypesArray] = useState<IDropdownDataItemProps<string, number, null>[]>([]);

  useEffect(() => {
    if (schoolId) {
      detentionTypesActions.getDetentionTypes(schoolId);
    }
  }, [schoolId]);

  useEffect(() => {
    if (!arrays.isEmpty(detentionTypes)) {
      const tempDetentionTypes: IDropdownDataItemProps<string, number, null>[] = detentionTypes.map(detentionType => ({
        key: detentionType.detentionTypeId,
        label: detentionType.detentionTypeName,
        value: detentionType.detentionTypeId,
      }));
      setDetentionTypesArray(tempDetentionTypes);
    }
  }, [detentionTypes]);

  return (
    <Dropdown
      items={detentionTypesArray}
      placeholder="Detention Type"
      value={selected}
      onChange={onChange}
      clearable
      fluid
      loading={loading}
      error={
        error ? "An error occurred when getting the detention types." : null
      }
    />
  );
};

export default DetentionTypesDropdown;
