import defaults from "configuration/defaults";
import { arrays } from "utils";
import newsActions from "../../actions/newsActions";
import { PageInfo } from "types/common/paging.types";
import { ArticleListView } from "areas/news/types/newsResponse.types";
import { IBeehiveAction } from "types/common/action.types";

interface IArticlesState {
  articles: ArticleListView[];
  error: string | null;
  loading: boolean;
  paging: PageInfo;
}

const INITIAL_STATE: IArticlesState = {
  articles: [],
  error: null,
  loading: false,
  paging: defaults.DEFAULT_PAGING,
};


const articlesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IArticlesState => {

  const { GET_ARTICLES } = newsActions.types;

  switch (action.type) {
    case GET_ARTICLES.START:
      return {
        ...state,
        loading: arrays.isEmpty(state.articles),
        error: null,
      };

    case GET_ARTICLES.SUCCESS:
      return {
        ...state,
        articles:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.articles, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };

    case GET_ARTICLES.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default articlesReducer;
