import React, { useEffect, useState } from "react";
import MissedDetentionsTable from "./missedDetentionsTable";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import { ApiExceptionMessage } from "sharedComponents/common";
import MissedDetentionsActionBar from "./missedDetentionsActionBar";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import detentionRegisterActions from "areas/behaviour/actions/detentions/detentionRegisterActions";
import schoolActions from "areas/planner/actions/school/schoolActions";
import flyoutActions from "actions/ui/flyouts";
import RescheduleDetentionModal from "../reschedule/rescheduleDetentionModal";
import { useAppSelector } from "reducers/hooks";
import { DetentionStudentView } from "areas/behaviour/types/detentionRegisterResponse.types";
import { YearGroupFilter } from "areas/behaviour/types/detentionRegisterRequest.types";
import moment from "moment";


export interface YearGroupFilterExt extends Omit<YearGroupFilter, "date"> {
  date: Date;
}


const MissedDetentionsWrapper: React.FC = () => {

  const { schoolInformation } = useAppSelector(state => state.school);
  const { missedDetentions, paging, loading, loadingExport, refresh, error } =
  useAppSelector(state => state.missedDetentions);
  const { user } = useAppSelector(state => state.currentUser);

  const [schoolId, setSchoolId] = useState<number>(user?.baseSchool?.id);
  const [openRescheduleModal, setOpenRescheduleModal] = useState<boolean>(false);
  const [detention, setDetention] = useState<DetentionStudentView>(null);
  const [filters, setFilters] = useState<YearGroupFilterExt>({
    academicYearId: Constants.ACADEMIC_YEAR_FILTER_ARRAY[0].value,
    yearGroupId: null,
    date: null,
    detentionTypeId: null,
    behaviourCodeId: null,
    schoolBehaviourCategoryId: null,
    searchTerm: null
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [_loading, _setLoading] = useState<boolean>(false);

  const getMissedDetentionsFirstPage = () => {
    if (!_loading) {
      _setLoading(true);
      detentionRegisterActions
        .getMissedDetentions(
          schoolId,
          {
            academicYearId: filters.academicYearId,
            yearGroupId: filters.yearGroupId,
            date: filters.date ? moment(filters.date).format("YYYY-MM-DD") : null,
            detentionTypeId: filters.detentionTypeId,
            behaviourCodeId: filters.behaviourCodeId,
            schoolBehaviourCategoryId: filters.schoolBehaviourCategoryId,
            searchTerm: searchTerm
          },
          0,
          () => _setLoading(false)
        )
        .catch(() => _setLoading(false));
    }
  };

  const exportMissedDetentions = () => {
    detentionRegisterActions.exportMissedDetentions(
      schoolId,
      {
        academicYearId: filters.academicYearId,
        yearGroupId: filters.yearGroupId,
        date: moment(filters.date).format("YYYY-MM-DD"),
        detentionTypeId: filters.detentionTypeId,
        behaviourCodeId: filters.behaviourCodeId,
        schoolBehaviourCategoryId: filters.schoolBehaviourCategoryId,
        searchTerm: searchTerm
      }
    );
  };

  useEffect(() => {
    if (schoolId) {
      getMissedDetentionsFirstPage();

      if (schoolId !== schoolInformation?.id) {
        schoolActions.getSchoolInformation(schoolId);
      }
    }
  }, [schoolId, filters]);

  useEffect(() => {
    if (schoolId && refresh) {
      getMissedDetentionsFirstPage();
    }
  }, [refresh]);

  useEffect(() => {
    if (searchTerm.length === 0) {
      getMissedDetentionsFirstPage();
    }
    const timeoutId = setTimeout(() => {
      if (searchTerm.length >= 2) {
        getMissedDetentionsFirstPage();
      }
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  const handleSearch = () => {
    getMissedDetentionsFirstPage();
  };

  const onClickDetention = (detention: DetentionStudentView) => {
    flyoutActions.openFlyout(Constants.FLYOUTS.DETENTIONDETAILS);
    detentionActions.selectDetention(detention);
  };

  const rescheduleDetention = (detention: DetentionStudentView) => {
    setDetention(detention);
    setOpenRescheduleModal(true);
  };

  const onChangeSchool = (value: number) => {
    setSchoolId(value);
    setFilters({
      ...filters,
      yearGroupId: null,
      schoolBehaviourCategoryId: null,
      behaviourCodeId: null,
      detentionTypeId: null,
    });
    setSearchTerm("");
  };

  const handlePage = () => {
    detentionRegisterActions.getMissedDetentions(
      schoolId,
      {
        academicYearId: filters.academicYearId,
        yearGroupId: filters.yearGroupId,
        date: filters.date ? moment(filters.date).format("YYYY-MM-DD") : null,
        detentionTypeId: filters.detentionTypeId,
        behaviourCodeId: filters.behaviourCodeId,
        schoolBehaviourCategoryId: filters.schoolBehaviourCategoryId,
        searchTerm: searchTerm
      },
      paging.pageIndex + 1
    );
  };

  const handleBulkUpdate = (refresh: boolean) => {
    refresh && getMissedDetentionsFirstPage();
  }

  return (
    <>
      {/* <HelpMessage
        icon="info-circle"
        title="Need help taking the managing missed detentions or rescheduling a detention?"
        sub="Watch our VLE training video to find out more"
        link="https://vle.lionhearttrust.org.uk/mod/page/view.php?id=70120"
      /> */}

      <MissedDetentionsActionBar
        filters={filters}
        onChangeFilters={newfilters => setFilters(newfilters)}
        schoolId={schoolId}
        onChangeSchool={onChangeSchool}
        yearGroups={
          schoolInformation?.yearGroups ? schoolInformation.yearGroups : []
        }
        searchTerm={searchTerm}
        onChangeSearchTerm={value => setSearchTerm(value)}
        onClickSearch={handleSearch}
        loading={loading}
        loadingExport={loadingExport}
        handleExportMissedDetentions={exportMissedDetentions}
      />

      <ApiExceptionMessage error={error} />

      <MissedDetentionsTable
        filters={filters}
        schoolId={schoolId}
        missedDetentions={missedDetentions}
        onClickDetention={onClickDetention}
        rescheduleDetention={rescheduleDetention}
        handlePage={handlePage}
        paging={paging}
        loading={_loading}
        handleExportDetentions={exportMissedDetentions}
        loadingExport={loadingExport}
        error={error ? true : false}
        onBulkUpdate={handleBulkUpdate}
      />

      <RescheduleDetentionModal
        open={openRescheduleModal}
        setOpen={setOpenRescheduleModal}
        detention={detention}
      />
    </>
  );
};

export default MissedDetentionsWrapper;
