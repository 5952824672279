import React, { useEffect, useState, useRef, useImperativeHandle } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  EmptyMessage,
  Loader,
  Size,
  Title,
  TitleSize,
  Message,
  Swatches,
  StructuredList,
  TextInput,
  DatePicker,
  RichTextEditor,
  Card,
  ActionBar,
  Button,
  Dropdown,
  ToastService,
  ValidationMessage,
  List,
  Tooltip,
  Spacing
} from "ui-kit";
import { danger } from "ui-kit/common/colors";
import Tippy from "@tippyjs/react";
import { arrays } from "utils";
import { Constants } from "configuration";
import assignmentActions from "areas/planner/actions/assignments/assignmentActions";
import assignmentEditorActions from "areas/planner/actions/assignments/assignmentEditorActions";
import Subheader from "sharedComponents/layout/header/subheader";
import GroupSelector from "sharedComponents/common/groups/groupSelector";
import FilePicker from "sharedComponents/common/files/filePicker";
import LinkPicker from "sharedComponents/common/links/linkPicker";
import StatusView from "sharedComponents/common/status/statusView";
import { StudentPicker, Avatar, SubjectDropdown } from "sharedComponents/common";
import { useScroll } from "hooks/useScroll";
import { StudentListView } from "types/users/userListViews.types";
import { useAppSelector } from "reducers/hooks";
import { AssignmentDetailView } from "types/planner/assignments.types";
import moment from "moment";
import { GroupListView, GroupType } from "types/users/userGroups.types";
import { FileListView } from "types/common/files.types";
import { PublishStatus } from "types/common/status.types";
import { SaveAssignmentCommand } from "areas/planner/types/assignments/assignmentRequest.types";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const RemoveButton = styled.button`
  font-size: 1.5rem;
  background-color: unset;
  border: medium none;
  margin: 0px;
  padding: 0px;
  color: rgb(153, 153, 153);
  cursor: pointer;
  line-height: 1rem;
  margin-bottom: ${Spacing.Small}px;
  &:hover {
    color: ${danger};
  }
`;


const defaultAssignment: AssignmentDetailView = {
  details: "",
  suggestedTimescale: 15,
  links: [],
  files: [],
  surveyEnabled: false,
  id: null,
  title: "",
  summary: "",
  deadline: moment().format("YYYY-MM-DD"),
  setBy: undefined,
  setOn: moment().format("YYYY-MM-DD"),
  groups: [],
  students: [],
  publishStatus: PublishStatus.Draft,
  isRead: false,
  isComplete: false,
  commentsCount: 0,
  updatedDate: "",
  visibleFrom: "",
  completedOn: "",
  readOn: "",
  isUpdated: false,
  isExpired: false,
  isOverdue: false,
  isScheduled: false
}


export interface IAssignmentEditorRef {
  saveAsDraft: () => void;
  saveAndPublish: () => void;
}

interface IAssignmentEditorProps {
  contentOnly?: boolean;
  students?: StudentListView[];
}


const AssignmentEditor = React.forwardRef<IAssignmentEditorRef, IAssignmentEditorProps>(({ contentOnly, students }, ref) => {

  let { id } = useParams();
  const { scrollToTop } = useScroll();
  const navigate = useNavigate();
  const tippyRef = useRef();

  const { assignment, loading, error } = useAppSelector(state => state.assignment);
  const { user } = useAppSelector(state => state.currentUser);
  const { error: saveError, saving } = useAppSelector(
    state => state.assignmentEditor
  );

  const [_assignment, _setAssignment] = useState<AssignmentDetailView>(defaultAssignment);
  const [_details, _setDetails] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    saveError && scrollToTop();
  }, [saveError]);

  useEffect(() => {
    if (id) {
      user &&
        assignmentActions.getAssignment(user.id, parseInt(id), () => {
          _setDetails(assignment?.details);
          _setAssignment(
            assignment
              ? assignment
              : defaultAssignment
          );
        });
    } else {
      _setAssignment(defaultAssignment);
    }
  }, [id, user]);

  useEffect(() => {
    _setAssignment({ ..._assignment, students: students })
  }, [students])

  useImperativeHandle(ref, () => ({
    saveAsDraft() {
      handleSave(true);
    },
    saveAndPublish() {
      handleSave(false);
    }
  }));

  const handleSave = (saveAsDraft?: boolean) => {
    var errors = [];

    if (!_assignment.title) {
      errors.push(`A title is required.`);
    }

    if (!_assignment.details) {
      errors.push(`Details of the assignment are required.`);
    }

    if (!_assignment.deadline) {
      errors.push(`A deadline is required.`);
    }

    if (!_assignment.groups?.length && !_assignment.students?.length) {
      errors.push(
        `Please select one or more groups and/or students to set the assignment for.`
      );
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    var data: SaveAssignmentCommand = {
      ..._assignment,
      saveAsDraft,
      students: _assignment?.students?.map(s => s.id),
      visibleUntil: null,
      isSticky: false,
      schools: [],
      assignedTo: [],
      userRestrictions: {
        allowParents: true,
        allowStudents: true,
        allowStaff: true
      }
    };

    if (_assignment.id) {
      assignmentEditorActions.updateAssignment(data, () => {
        ToastService.pop("Assignment Saved", null, "briefcase");
        navigate(-1);
      });
    } else {
      assignmentEditorActions.createAssignment({ ...data, saveAsDraft }, () => {
        navigate(-1);

        ToastService.pop(
          saveAsDraft
            ? "Assignment Saved as Draft"
            : "Assignment Saved and Published",
          null,
          "briefcase"
        );
      });
    }

    // After save, go back and pop toast etc
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleGroupsChange = (groups: GroupListView[]) => {
    _setAssignment({ 
      ..._assignment, 
      groups: groups,
    });
  };

  const handleAddStudent = (newStudent: StudentListView) => {
    if (_assignment.students?.some(student => student.id == newStudent.id)) {
      return;
    }
    _setAssignment({ ..._assignment, students: [..._assignment.students || [], newStudent] });
  }

  const handleRemoveStudent = (studentId: string) => {
    _setAssignment({ 
      ..._assignment, 
      students: _assignment.students.filter(x => x.id !== studentId)
    })
  }

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <Wrapper>
        <EmptyMessage
          icon="times-circle"
          title="A problem occurred"
          summary="There was a problem while loading the assignment"
          cover
        />
      </Wrapper>
    );
  }

  return (
    <>
      {!contentOnly && (
        <Subheader>
          <Title
            size={TitleSize.H2}
            text="Assignments"
            sub={id ? "Edit Assignment" : "New Assignment"}
          />
        </Subheader>
      )}

      <Message text={saveError} color={Swatches.Danger} />
      <ValidationMessage errors={validationErrors} />
      <Card>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item
              name="Title"
              required
              description="Please set a title that describes the assignment being set. This will be visible to parents and students."
              helpPopup
            >
              <TextInput
                value={_assignment.title}
                onChange={value =>
                  _setAssignment({ ..._assignment, title: value })
                }
                fluid
              />
            </StructuredList.Item>
            <StructuredList.Item
              name="Set For"
              required
              description="The groups that the assignment is set for. Students in any of the groups listed will be able to see the assignment."
              helpPopup
            >
              {!contentOnly && (
                <GroupSelector
                  onChange={handleGroupsChange}
                  groups={_assignment?.groups}
                  showSchoolIcon={user.schools?.length > 0}
                />
              )}
              <StudentPicker
                fluid
                onChange={handleAddStudent}
                style={!contentOnly ? { marginTop: 12, marginBottom: 8 } : { marginBottom: 8 }}
              />
              { _assignment?.students?.length > 0 && (
                <List>
                  { _assignment?.students.map((student, index) => (
                    <List.Item
                      key={index}
                      left={<Avatar user={student} />}
                      right={
                        <>
                          <RemoveButton
                            ref={tippyRef}
                            onClick={event => {
                              event.stopPropagation();
                              handleRemoveStudent(student.id);
                            }}
                          >
                            &#10799;
                          </RemoveButton>
                          <Tippy
                            reference={tippyRef}
                            content={<Tooltip title="Remove Student" />}
                          />
                        </>
                      }
                    />
                  ))}
                </List>
              )}
            </StructuredList.Item>
            <StructuredList.Item name="Deadline" required>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                closeOnSelect
                selectedDate={_assignment.deadline}
                onChange={value =>
                  _setAssignment({ ..._assignment, deadline: moment(value).format("YYYY-MM-DD") })
                }
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Visible From"
              description={
                <span>
                  You can optionally specify when the assignment should be
                  available to students from.{" "}
                  <b>
                    If you choose a visible from date, you still need to publish
                    the assignment.
                  </b>
                </span>
              }
              helpPopup
            >
              <DatePicker
                dateFormat="DD/MM/YYYY"
                closeOnSelect
                selectedDate={_assignment.visibleFrom}
                onChange={value =>
                  _setAssignment({ ..._assignment, visibleFrom: moment(value).format("YYYY-MM-DD") })
                }
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Time to Complete"
              description="This is optional but informs students as to approximately how long the assignment should take."
              helpPopup
            >
              <Dropdown
                value={_assignment.suggestedTimescale}
                onChange={value =>
                  _setAssignment({ ..._assignment, suggestedTimescale: value })
                }
                clearable
              >
                {Constants.SUGGESTED_TIMESCALES.map((timescale, index) => (
                  <Dropdown.Item
                    key={index}
                    label={timescale.text}
                    value={timescale.value}
                  />
                ))}
              </Dropdown>
            </StructuredList.Item>
            <StructuredList.Item
              required
              name="Details"
              description="Please note that this will be seen by students and parents."
              helpPopup
            >
              <RichTextEditor
                initialValue={_details}
                onChange={value =>
                  _setAssignment({ ..._assignment, details: value })
                }
              />
            </StructuredList.Item>
            <StructuredList.Item
              name="Upload Files"
              description="You can upload .pdf, .docx, .xlsx, .csv, .jpg, .gif, or .png files.  After uploading, you still need to save the form to save the documents. Please note that due to technical limitations with some mobile phones, attachments are only currently available on the web version of Beehive."
              helpPopup
            >
              <FilePicker
                files={_assignment.files}
                onFilesChanged={files =>
                  _setAssignment({ ..._assignment, files: files as FileListView[] })
                }
              />
            </StructuredList.Item>
            <StructuredList.Item name="Links">
              <LinkPicker
                links={_assignment.links}
                onLinksChanged={links =>
                  _setAssignment({ ..._assignment, links: links })
                }
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
      {!contentOnly && (
        <ActionBar>
          <StatusView status={_assignment.publishStatus}>
            <StatusView.Item status={Constants.PUBLISH_STATUS.DRAFT.value}>
              <Button
                text="Save as Draft"
                color={Swatches.Primary}
                onClick={() => handleSave(true)}
                working={saving}
              />
            </StatusView.Item>
            <StatusView.Item></StatusView.Item>
          </StatusView>
          <Button
            text={
              _assignment.publishStatus == Constants.PUBLISH_STATUS.DRAFT.value
                ? `Save and Publish`
                : `Save`
            }
            color={Swatches.Success}
            onClick={() => handleSave(false)}
            working={saving}
          />
          <Button
            text="Cancel"
            color={Swatches.Low}
            onClick={handleCancel}
            working={saving}
          />
        </ActionBar>
      )}
    </>
  );
});

export default AssignmentEditor;
