import React, { useState } from "react";
import {
  StructuredList,
  Card,
  Dropdown,
  ActionBar,
  Button,
  Swatches,
  Size,
} from "ui-kit";
import DateRange from "../dateRange";
import MultiSchoolUserView from "sharedComponents/common/users/multiSchoolUserView";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import AcademicYearFilter from "sharedComponents/common/academicYearFilter";
import { Constants } from "configuration";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";


interface IAwardAnalyticsFormProps {
  schoolId: number;
  onSelectSchool: (value: number) => void;
  awardCategories: IDropdownDataItemProps<string, number, null>[];
  handleGenerateReport: (
    startDate: string, 
    endDate: string, 
    awardCategory: number, 
    academicYearId: number, 
    exportReport?: boolean
  ) => void;
  loadingExportReport: boolean;
}


const AwardAnalyticsForm: React.FC<IAwardAnalyticsFormProps> = ({
  schoolId,
  onSelectSchool,
  awardCategories,
  handleGenerateReport,
  loadingExportReport,
}) => {

  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [awardCategory, setAwardCategory] = useState<number>(null);
  const [academicYearId, setAcademicYearId] = useState<number>(
    Constants.ACADEMIC_YEAR_FILTER_ARRAY[0].value
  );

  return (
    <>
      <StructuredList>
        <MultiSchoolUserView>
          <Card title="School">
            <Card.Body>
              <SchoolDropdown
                onChange={onSelectSchool}
                initialValue={schoolId}
                fluid
              />
            </Card.Body>
          </Card>
        </MultiSchoolUserView>

        <Card title="Date Range">
          <Card.Body>
            <StructuredList.Item name="Academic Year" required>
              <AcademicYearFilter
                value={academicYearId}
                onChange={value => setAcademicYearId(value)}
              />
            </StructuredList.Item>

            <DateRange
              startDate={startDate}
              handleStartDateChange={value => setStartDate(value)}
              endDate={endDate}
              handleEndDateChange={value => setEndDate(value)}
              required
            />
          </Card.Body>
        </Card>

        <Card title="Filters">
          <Card.Body>
            <StructuredList.Item name="Award Category" required>
              <Dropdown
                placeholder="Category"
                items={awardCategories}
                onChange={value => setAwardCategory(value)}
                value={awardCategory}
                fluid
              />
            </StructuredList.Item>
          </Card.Body>
        </Card>
      </StructuredList>

      <ActionBar>
        <Button
          text="Generate Report"
          color={Swatches.Success}
          size={Size.Small}
          disabled={
            startDate === null || endDate === null || awardCategory === null
          }
          onClick={() =>
            handleGenerateReport(
              startDate.toString(),
              endDate.toString(),
              awardCategory,
              academicYearId
            )
          }
        />
        <Button
          color={Swatches.Success}
          text="Export Report as CSV"
          icon="file-download"
          size={Size.Small}
          disabled={
            startDate === null || endDate === null || awardCategory === null
          }
          onClick={() =>
            handleGenerateReport(
              startDate.toString(),
              endDate.toString(),
              awardCategory,
              academicYearId,
              true
            )
          }
          working={loadingExportReport}
        />
      </ActionBar>
    </>
  );
};

export default AwardAnalyticsForm;
