import action from "actions/action";
import client from "services/client";
import FileDownload from "js-file-download";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { urls } from "../../../utils";
import { BeehiveReport, callbackType } from "types/common/action.types";
import { AssignmentReportCriteria } from "../types/assignmentAnalyticsRequests.types";
import { AssignmentReportView } from "../types/assignmentAnalyticsResponse.types";

const builder = new actionTypeBuilder("assignmentAnalytics");

const types = {
  GETASSIGNMENTREPORT: builder.build("getassignmentreport"),
  EXPORTASSIGNMENTREPORT: builder.build("exportassignmentreport"),
};

const getAssignmentReport = (
  filter: AssignmentReportCriteria,
  onSuccess?: callbackType<AssignmentReportView[]>
) => {

  let url = new urls.QueryString("planner/reporting/assignmentReport");

  url.addParams({
    deadlineStartDate: filter.deadlineStartDate,
    deadlineEndDate: filter.deadlineEndDate,
    setStartDate: filter.setStartDate,
    setEndDate: filter.setEndDate,
    classGroupIds: filter.classGroupIds,
    tutorGroupIds: filter.tutorGroupIds,
    customGroupIds: filter.customGroupIds,
    yearGroupIds: filter.yearGroupIds,
    subjectIds: filter.subjectIds,
    assignmentGroupById: filter.assignmentGroupById,
    staffIds: filter.staffIds,
  });

  return action<AssignmentReportView[]>(
    () => client.get(url.toUrl()),
    types.GETASSIGNMENTREPORT,
    onSuccess
  );
};


const exportAssignmentReport = (
  filter: AssignmentReportCriteria,
  onSuccess?: callbackType<BeehiveReport>
) => {

  let url = new urls.QueryString("planner/reporting/assignmentReport/export");

  url.addParams({
    deadlineStartDate: filter.deadlineStartDate,
    deadlineEndDate: filter.deadlineEndDate,
    setStartDate: filter.setStartDate,
    setEndDate: filter.setEndDate,
    classGroupIds: filter.classGroupIds,
    tutorGroupIds: filter.tutorGroupIds,
    customGroupIds: filter.customGroupIds,
    yearGroupIds: filter.yearGroupIds,
    subjectIds: filter.subjectIds,
    assignmentGroupById: filter.assignmentGroupById,
    staffIds: filter.staffIds,
  });

  return action<BeehiveReport>(
    () => client.get(url.toUrl(), { responseType: "blob" }),
    types.EXPORTASSIGNMENTREPORT,
    response => {
      onSuccess && onSuccess(response);
      FileDownload(response, "Assignment Report.csv");
    }
  );
};


const assignmentAnalyticsActions = {
  types,
  getAssignmentReport,
  exportAssignmentReport,
};

export default assignmentAnalyticsActions;
