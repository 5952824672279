import actionTypeBuilder from "actions/actionTypeBuilder";
import { DateView } from "areas/planner/types/termDatesResponse.types";
import store from "reducers/store";
import { IBeehiveAction } from "types/common/action.types";

const builder = new actionTypeBuilder("termDates");

const types = {
  SAVECHECKEDDATES: "savecheckeddates",
  CHECKTERMDATES: builder.build("checktermdates"),
};

// const checkTermDates = (
//   startDate,
//   endDate,
//   sendNextAvailableDate,
//   isForDetention,
//   callback
// ) => {
//   let url = `planner/checkDates?&startDate=${moment(startDate).format(
//     "YYYY-MM-DD"
//   )}&schoolId=${schoolId}`;

//   if (endDate) {
//     url = url + `&endDate=${moment(endDate).format("YYYY-MM-DD")}`;
//   }

//   if (sendNextAvailableDate) {
//     url = url + `&SendNextAvailableDate=${sendNextAvailableDate}`;
//   }

//   if (isForDetention) {
//     url = url + `&isForDetention=${isForDetention}`;
//   }

//   return action(() => client.get(url), types.CHECKTERMDATES, callback);
// };

const saveCheckedDates = (dates: DateView[]) => {
  store.dispatch<IBeehiveAction<DateView[]>>({
    type: types.SAVECHECKEDDATES,
    payload: dates,
  });
};

const termDateActions = {
  types,
  saveCheckedDates,
};

export default termDateActions;
