import leaveActions from "areas/humanResources/actions/leaveActions";
import { leaveRoutes } from "areas/humanResources/hrRoutes";
import { ApproveLeaveRequestCommand, CancelLeaveRequestCommand, ConfirmLeaveRequestCommand, RejectLeaveRequestCommand, ResubmitLeaveRequestCommand } from "areas/humanResources/types/leaveRequest.types";
import { ApprovalStatus } from "areas/humanResources/types/leaveShared.types";
import { Constants } from "configuration";
import React, {  } from "react";
import { useNavigate } from "react-router";
import { useAppSelector } from "reducers/hooks";
import { StatusView, RoleView } from "sharedComponents/common";
import { ActionBar, Left, Button, Swatches, Size, Right, ToastService } from "ui-kit";
import { arrays, users } from "utils";


enum StatusChange {
  Cancel,
  Resubmit,
  Approve,
  Reject,
  Confirm
}

interface ILeaveRequestFooterProps {
  notes: string;
  onClose: () => void;
  setValidationErrors: (value: React.SetStateAction<string[]>) => void;
}


const LeaveRequestFooter: React.FC<ILeaveRequestFooterProps> = ({ notes, onClose, setValidationErrors }) => {

  const navigate = useNavigate();
  
  const { user } = useAppSelector(state => state.currentUser);
  const { request, working } = useAppSelector(state => state.leaveRequest);


  const viewHolidayCard = () => {
    onClose?.();
    navigate(leaveRoutes.getStaffLeavePath(request.staffMember.id));
  };


  const validate = (condition: boolean, errorMessage: string) => {
    var errors: string[] = [];
    if (condition) {
      errors.push(errorMessage);
    }
    setValidationErrors(errors);
    return !arrays.isEmpty(errors);
  }


  const handleProcess = (action: StatusChange) => {

    var confirmMessage: string = "";
    switch (action) {
      case StatusChange.Approve:
        confirmMessage = "This will approve this leave request. Are you sure?";
        break;
      case StatusChange.Confirm:
        confirmMessage = "This will confirm this leave request. You should ensure that any external holiday/leave tracking systems are updated (e.g. SIMS).  Are you sure?";
        break;
      case StatusChange.Cancel:
        confirmMessage = "This will cancel this leave request. This cannot be undone. Are you sure?";
        break;
      case StatusChange.Resubmit:
        confirmMessage = "This will resubmit this leave request. Are you sure?";
        break;
      case StatusChange.Reject:
        confirmMessage = "This will reject this leave request. This cannot be undone. Are you sure?";
        break;
    }

    if (!window.confirm(confirmMessage)) {
      return;
    }

    const callback = (message: string) => {
      ToastService.pop(message, null, "plane-departure");
      onClose?.();
    }

    var baseData = {
      staffMemberId: request.staffMember.id,
      holidayRequestId: request.id,
      holidayCardId: request.holidayCardId,
    }

    if (action === StatusChange.Approve) {
      let data: ApproveLeaveRequestCommand = { ...baseData, approverId: user.id }
      leaveActions.approveLeaveRequest(request.staffMember.id, data, () => callback("Leave Request Approved"))
    }
    else if (action === StatusChange.Confirm) {
      let data: ConfirmLeaveRequestCommand = { ...baseData, confirmerId: user.id }
      leaveActions.confirmLeaveRequest(request.staffMember.id, data, () => callback("Leave Request Confirmed"));
    }
    else if (action === StatusChange.Cancel) {
      let data: CancelLeaveRequestCommand = { ...baseData, cancellerId: user.id, notes: notes };
      leaveActions.cancelLeaveRequest(request.staffMember.id, data, () => callback("Leave Request Cancelled"));
    }
    else if (action === StatusChange.Resubmit) {
      let data: ResubmitLeaveRequestCommand = { ...baseData, resubmitterId: user.id };
      leaveActions.resubmitLeaveRequest(request.staffMember.id, data, () => callback("Leave Request Resubmitted"));
    }
    else if (action === StatusChange.Reject) {
      if (validate(!notes.length, "You must supply covering notes when rejecting a leave request.")) {
        return;
      }
      let data: RejectLeaveRequestCommand = { ...baseData, rejectorId: user.id, notes: notes };
      leaveActions.rejectLeaveRequest(request.staffMember.id, data, () => callback("Leave Request Rejected"));
    }
  }


  return (
    <>
      <ActionBar low>
        <Left>
          <Button
            text="Close"
            onClick={onClose}
            color={Swatches.Low}
            working={working}
            size={Size.Small}
          />
        </Left>
        <Right>
          {users.isInAnyRoles(user, [Constants.ROLES.HR_ADMINISTRATOR]) && (
            <Button
              onClick={viewHolidayCard}
              text="View Holiday Card"
              size={Size.Small}
            />
          )}
          {(user.id === request?.staffMember.id &&
            request?.currentStatus?.status == ApprovalStatus.Submitted) ||
            (user.id !== request?.staffMember.id &&
            request?.currentStatus?.status != ApprovalStatus.Cancelled && (
              <Button
                text="Cancel Leave Request"
                onClick={() => handleProcess(StatusChange.Cancel)}
                color={Swatches.Danger}
                working={working}
                size={Size.Small}
              />
            ))}
          <StatusView status={request?.currentStatus?.status}>
            <StatusView.Item status={ApprovalStatus.Cancelled}>
              <Button
                text="Resubmit Leave Request"
                onClick={() => handleProcess(StatusChange.Resubmit)}
                color={Swatches.Success}
                working={working}
                size={Size.Small}
              />
            </StatusView.Item>
            <StatusView.Item></StatusView.Item>
          </StatusView>
          <StatusView status={request?.currentStatus?.status}>
            <StatusView.Item status={1}>
              {user.id !== request?.staffMember.id &&
                (user.id === request?.approver.id ||
                  users.isInAnyRoles(user, [
                    Constants.ROLES.HR_ADMINISTRATOR,
                    Constants.ROLES.DEVELOPMENT,
                  ])) && (
                  <>
                    <Button
                      text="Approve Leave Request"
                      onClick={() => handleProcess(StatusChange.Approve)}
                      color={Swatches.Success}
                      working={working}
                      size={Size.Small}
                    />
                    <Button
                      text="Reject Leave Request"
                      onClick={() => handleProcess(StatusChange.Reject)}
                      color={Swatches.Danger}
                      working={working}
                      size={Size.Small}
                    />
                  </>
                )}
            </StatusView.Item>
            <StatusView.Item></StatusView.Item>
          </StatusView>
          <StatusView status={request?.currentStatus?.status}>
            <StatusView.Item status={2}>
              <RoleView
                roles={[
                  Constants.ROLES.HR_ADMINISTRATOR,
                  Constants.ROLES.DEVELOPMENT,
                ]}
              >
                <Button
                  text="Confirm Leave Request"
                  onClick={() => handleProcess(StatusChange.Confirm)}
                  color={Swatches.Success}
                  working={working}
                  size={Size.Small}
                />
              </RoleView>
            </StatusView.Item>
            <StatusView.Item></StatusView.Item>
          </StatusView>
        </Right>
      </ActionBar>
    </>
  );
}


export default LeaveRequestFooter;