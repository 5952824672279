import React from "react";
import StudentActivityView from "./studentActivityView";
import ParentActivityView from "./parentActivityView";
import { ParentView, StudentView } from "sharedComponents/common";
const Activity = () => {
  return (
    <>
      <ParentView>
        <ParentActivityView />
      </ParentView>
      <StudentView>
        <StudentActivityView />
      </StudentView>
    </>
  );
};

export default Activity;
