import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import { Dropdown, Sub } from "ui-kit";
import yearGroupActions from "../../../areas/planner/actions/yearGroupActions";
import { RootState } from "reducers/store";
import { GroupListView } from "types/users/userGroups.types";


interface IYearGroupDropdownProps {
  value?: number;
  fluid?: boolean;
  onChange?: (value: number, label: string, data: GroupListView) => void;
  school?: number;
  clearable?: boolean;
}


const YearGroupDropdown: React.FC<IYearGroupDropdownProps> = ({ value, fluid, onChange, school, clearable }) => {
  
  const { error, loading, yearGroups, schoolId } = useSelector(
    (state: RootState) => state.yearGroups
  );
  const [_school, _setSchool] = useState<number>(null);

  useEffect(() => {
    _setSchool(school);
  }, [school]);

  useEffect(() => {
    if ((arrays.isEmpty(yearGroups) && _school != null) || (_school != null && schoolId != _school)) {
      yearGroupActions.getYearGroupsBySchool(_school);
    }
  }, [_school]);

  if (error) {
    return <Sub>There was a problem loading the year groups</Sub>;
  }

  if (!school) {
    return <Sub>A school must be specified</Sub>;
  }

  return (
    <Dropdown
      value={value}
      loading={loading}
      placeholder="Choose a Year Group"
      fluid={fluid}
      onChange={onChange}
      clearable={clearable}
    >
      {yearGroups.map((yearGroup: GroupListView, index: number) => (
        <Dropdown.Item
          label={yearGroup.name}
          value={yearGroup.id}
          data={yearGroup}
          key={index}
        />
      ))}
    </Dropdown>
  );
};

export default YearGroupDropdown;
