import React from "react";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import {
  Swatches,
  Size,
  Button, ActionBar,
  Dropdown
} from "ui-kit";
import calendarActions from "areas/calendar/actions/calendarActions";
import { RootState } from "reducers/store";
import { IWallplannerCategory } from "configuration/constants.types";


interface IWpEventsFilterProps {
  onNewEventClick?: () => void;
}


const WpEventsFilter: React.FC<IWpEventsFilterProps> = ({ onNewEventClick }) => {

  const { loading } = useSelector((state: RootState) => state.wallPlannerSessions);
  const { filter } = useSelector((state: RootState) => state.wallPlannerSessions.selectedSession);

  const arrayToDropdown = (arr: IWallplannerCategory[]) => {
    return arr.map(item => ({
      label: item.name,
      value: item.id,
    }));
  };

  var eventCategories = arrayToDropdown(Constants.WALLPLANNER_CATEGORIES);
  eventCategories = [
    { label: "All Categories", value: null },
    ...eventCategories,
  ];

  const handleNewEventClick = () => {
    onNewEventClick?.();
  };

  return (
    <>
      <ActionBar>
        <Dropdown
          fluid
          value={filter?.category}
          onChange={val => calendarActions.updateWallplannerEventsFilter({
            ...filter,
            category: val,
          })}
          items={eventCategories} />
        <Dropdown
          value={filter?.month}
          onChange={val => calendarActions.updateWallplannerEventsFilter({
            ...filter,
            month: val,
          })}
        >
          <Dropdown.Item value={null} label="All Months" />
          <Dropdown.Item value={1} label="January" />
          <Dropdown.Item value={2} label="February" />
          <Dropdown.Item value={3} label="March" />
          <Dropdown.Item value={4} label="April" />
          <Dropdown.Item value={5} label="May" />
          <Dropdown.Item value={6} label="June" />
          <Dropdown.Item value={7} label="July" />
          <Dropdown.Item value={8} label="August" />
          <Dropdown.Item value={9} label="Septermber" />
          <Dropdown.Item value={10} label="October" />
          <Dropdown.Item value={11} label="November" />
          <Dropdown.Item value={12} label="December" />
        </Dropdown>
        <Button
          size={Size.Small}
          text="New Event"
          color={Swatches.Primary}
          onClick={handleNewEventClick}
          tooltip="Add a new event to this wall planner"
          disabled={loading} />
      </ActionBar>
    </>
  );
};


export default WpEventsFilter;