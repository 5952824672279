import React, { useEffect, useState } from "react";
import { arrays } from "utils";
import { useSelector } from "react-redux";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  Loader,
  Size,
  ActionBar,
  Dropdown,
  EmptyMessage,
  Swatches,
  Button,
  TextInput,
  Table,
  HeadlineStatistic,
} from "ui-kit";
import Moment from "react-moment";
import ConsentStatus from "../consentStatus";
import ConsentResponseModal from "./consentResponseModal";
import consentActions from "areas/administration/actions/consentActions";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import Avatar from "sharedComponents/common/users/avatar";
import { RootState } from "reducers/store";
import { ConsentFormDetailView, ConsentResponseListView } from "areas/administration/types/consentResponses.types";
import { ConsentReportFilter } from "areas/administration/types/consentRequest.types";


interface IConsentResponseListProps {
  form: ConsentFormDetailView;
}


const ConsentResponseList: React.FC<IConsentResponseListProps> = ({ form }) => {

  const { loading, error, paging, responses } = useSelector((state: RootState) => state.adminConsentResponses);
  const [selectedResponse, setSelectedResponse] = useState<ConsentResponseListView | null>(null);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [filter, setFilter] = useState<ConsentReportFilter>({
    schoolId: -1,
    consentGiven: null,
  });

  const loadResponses = (pageIndex: number) => {
    var data: ConsentReportFilter = {
      ...filter,
      searchTerm: searchTerm,
      schoolId: filter.schoolId === -1 ? null : filter.schoolId,
    };
    consentActions.getResponsesForAdmin(form.id, data, pageIndex);
  };

  const onPage = () => {
    loadResponses(paging.pageIndex + 1);
  };

  useEffect(() => {
    form && loadResponses(0);
  }, [form]);

  useEffect(() => {
    filter && loadResponses(0);
  }, [filter]);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: paging?.pageNumber < paging?.totalPages,
    onLoadMore: onPage,
  });

  const handleResponseClick = (response: ConsentResponseListView) => {
    setSelectedResponse(response);
  };

  const handleResponseModalClose = () => {
    setSelectedResponse(null);
  };

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem while loading the responses"
        icon="times-circle"
        iconColor={Swatches.Danger.swatch}
        cover
      />
    );
  }

  return (
    <>
      <HeadlineStatistic>
        <HeadlineStatistic.Item
          icon="clipboard-check"
          label="Responses"
          value={paging?.totalRecords}
        />
      </HeadlineStatistic>
      <ActionBar>
        <SchoolDropdown
          onChange={value => setFilter({ ...filter, schoolId: value })}
          includeAllOption={true}
          includeTrust={false}
          initialValue={filter.schoolId}
        />
        <Dropdown
          value={filter.consentGiven}
          onChange={value => setFilter({ ...filter, consentGiven: value })}
        >
          <Dropdown.Item value={null} label="All Consent Types">
            All Consent
          </Dropdown.Item>
          <Dropdown.Item value={1} label="Consent Given">
            Consent Given
          </Dropdown.Item>
          <Dropdown.Item value={2} label="Partial Consent">
            Partial Consent
          </Dropdown.Item>
          <Dropdown.Item value={0} label="No Consent">
            No Consent
          </Dropdown.Item>
        </Dropdown>
        <TextInput
          value={searchTerm}
          placeholder="Search Responses..."
          onChange={value => setSearchTerm(value)}
        />
        <Button
          size={Size.Small}
          text="Search"
          onClick={() => setFilter({ ...filter, searchTerm: searchTerm })}
        />
      </ActionBar>
      <Table>
        <Table.Header>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell right>Consent</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {responses?.map((response: ConsentResponseListView, index: number) => (
            <Table.Row
              key={index}
              onClick={() => handleResponseClick(response)}
            >
              <Table.Cell>
                <Avatar user={response.user} />
              </Table.Cell>
              <Table.Cell>
                <Moment date={response.createdDate} format="DD/MM/YYYY" />
              </Table.Cell>
              <Table.Cell right>
                <ConsentStatus status={response.consentGiven} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {!loading && arrays.isEmpty(responses) && (
        <EmptyMessage title="No Responses" icon="clipboard-check" cover />
      )}
      {loading && <Loader size={Size.Medium} />}

      {paging?.pageNumber < paging?.totalPages && <div ref={sentryRef}></div>}

      <ConsentResponseModal
        response={selectedResponse}
        form={form}
        onClose={handleResponseModalClose}
      />
    </>
  );
};

export default ConsentResponseList;
