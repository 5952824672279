import { SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";
import React, { useEffect, useState } from "react";
import { Checkbox } from "ui-kit";


interface IBehaviourCategoryCheckboxProps {
  category: SchoolBehaviourCategoryListView;
  selectedBehaviourCategories: SchoolBehaviourCategoryListView[];
  handleCategoryClick: (category: SchoolBehaviourCategoryListView, isChecked: boolean) => void;
}

const BehaviourCategoryCheckbox: React.FC<IBehaviourCategoryCheckboxProps> = ({
  category,
  selectedBehaviourCategories,
  handleCategoryClick
}) => {

  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    const tempCategory = selectedBehaviourCategories.find(
      cat => cat.id === category.id
    );

    if (tempCategory !== undefined) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [selectedBehaviourCategories]);

  const handleCheckboxChange = () => {
    const newIsChecked = !isChecked;
    handleCategoryClick(category, newIsChecked);
    setIsChecked(newIsChecked);
  };

  return (
    <Checkbox
      text={`${category.codeDisplayName}: ${category.behaviourCategoryName}`}
      checked={isChecked}
      onChange={handleCheckboxChange}
    />
  );
};

export default BehaviourCategoryCheckbox;
