import React, { useEffect, useState } from "react";
import {
  Size,
  Button,
  Swatches,
  Spacing,
  ActionBar,
  ToastService,
  Title,
  TitleSize,
  DateRange,
  Loader,
  EmptyMessage,
} from "ui-kit";
import moment from "moment";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { arrays, users } from "utils";
import calendarActions from "areas/calendar/actions/calendarActions";
import { Avatar } from "sharedComponents/common";
import { RootState } from "reducers/store";
import { CalendarEventDateListView } from "areas/calendar/types/calendarResponses.types";
import { CalendarEventSignupCommand, CancelCalendarEventSignupCommand } from "areas/calendar/types/calendarRequests.types";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "reducers/hooks";
import Subheader from "sharedComponents/layout/header/subheader";
import { CalendarEvent } from "areas/calendar/reducers/calendarEventReducer";
import EventDetails from "./eventModal/eventDetails";
import EventDates from "./eventModal/eventDates";
import EventMenu from "./eventModal/eventMenu";
import EventRequirements from "./eventModal/eventRequirements";
import { SignupDetails } from "./eventModal";


const UserEventViewer: React.FC = () => {

  const navigate = useNavigate();
  const { eventId } = useParams();
  const { user } = useAppSelector(state => state.currentUser);
  const { calendarEvent, loading, signingUp, signupError, error } = useSelector((state: RootState) => state.calendarEvent);
  const [_signup, _setSignup] = useState<SignupDetails>({
    user: null,
    accessRequirements: "",
    dietaryRequirements: "",
    menu: [],
    dates: []
  });
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [readonly, setReadonly] = useState<boolean>(false);
  const [noAccess, setNoAccess] = useState<boolean>(false);

  useEffect(() => {
    user && eventId && calendarActions.getCalendarEvent(user.id, parseInt(eventId));
  }, [eventId, user]);

  useEffect(() => {
    if (calendarEvent) {

      if (
        !users.groupableIsAccessibleToUserType(calendarEvent, user)
        // !users.userIsCreator(calendarEvent, user) &&
        // !users.isInAnyRoles(user, [
        //   Constants.ROLES.DEVELOPMENT,
        //   Constants.ROLES.IT_ADMINISTRATOR,
        //   Constants.ROLES.IT_USER,
        //   Constants.ROLES.MARKETING_ADMINISTRATOR
        // ])
      )
      {
        if (users.parentCanView(calendarEvent, user) || users.isStaff(user)) {
          setReadonly(true);
        }
        else {        
          setNoAccess(true);
          return;
        }
      }

      var defaultMenu = [];
      var dates = [];

      if (calendarEvent?.menuChoices?.length) {
        defaultMenu = calendarEvent.menuChoices.map(x => ({
          id: x.id,
          type: x.type,
        }));
      } else {
        if (!_signup.menu?.length && calendarEvent?.menu?.length) {
          for (var i = 0; i < calendarEvent?.menu.length; i++) {
            defaultMenu.push({
              id: calendarEvent.menu[i].menu[0].id,
              type: calendarEvent.menu[i].type,
            });
          }
        }
      }

      if (calendarEvent.dates.length > 1) {
        dates = calendarEvent.dates.filter(x => x.signedUp).map(x => x.id);
      } else {
        dates = [calendarEvent.dates[0]?.id];
      }
      _setSignup({
        ..._signup,
        user: user,
        menu: defaultMenu,
        dates: dates,
        dietaryRequirements: calendarEvent.dietaryRequirements,
        accessRequirements: calendarEvent.accessRequirements,
      });
    }
  }, [calendarEvent]);


  const handleCancel = () => {
    if (
      window.confirm(
        "This will cancel your signup for all forthcoming dates for this event. Are you sure?"
      )
    ) {
      const data: CancelCalendarEventSignupCommand = {
        userId: user.id,
        eventId: calendarEvent.id,
        eventDates: calendarEvent.dates.map(x => x.id),
      };

      calendarActions.calendarEventUnsignup(data, () => {
        ToastService.pop("Signup cancelled", null, "calendar");
        calendarActions.removeEventFromSignedUpEvents(calendarEvent);
      });
    }
  };


  const handleSave = () => {
    var errors = [];

    if (!_signup.user) {
      errors.push(`Please choose an attendee.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    if (
      window.confirm(
        `This will ${
          calendarEvent?.signedUp ? "update your details " : "sign you up "
        } for this event date. Are you sure?
        `
      )
    ) {
      const data: CalendarEventSignupCommand = {
        userId: user.id,
        eventId: calendarEvent.id,
        eventDates: _signup.dates,
        accessRequirements: _signup.accessRequirements,
        dietaryRequirements: _signup.dietaryRequirements,
        menu: _signup.menu?.map(x => x.id),
      };
      calendarActions.calendarEventSignup(data, id => {
        calendarActions.addEventToSignedUpEvents(calendarEvent);
        ToastService.pop("Signed up successfully", null, "calendar");
      });
    }
  };


  const formatTitleDate = (dates: CalendarEventDateListView[]) => {
    var date = dates?.[0];

    if (!date) {
      return null;
    }

    if (dates.length > 1) {
      return (
        <span>
          Starts{" "}
          <Moment date={date.starts} format="dddd, Do MMMM YYYY [at] HH:mma" />
        </span>
      );
    } else {
      return (
        <>
          <DateRange start={date.starts} end={date.ends} />
        </>
      );
    }
  }


  const signupStillOpen = (cc: CalendarEvent) => {
    return (
      cc?.allowSignupTo == null ||
      moment(cc?.allowSignupTo).isAfter(moment())
    )
  }


  if (loading) {
    return <Loader size={Size.Large} cover />;
  }


  if ((!calendarEvent || error) && !loading) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem while loading this event"
        icon="times-circle"
        iconColor={Swatches.Danger.swatch}
        cover
      />
    )
  }


  if (noAccess) {
    return (
      <EmptyMessage
        icon="ban"
        title="Not Authorised"
        summary="Sorry, this event has not been made available to you"
        cover
      >
        <Button text="Go Back" onClick={() => navigate("../")} size={Size.Small} />
      </EmptyMessage>
    );
  }


  return (
    <>
      <Subheader>
        <Title
          size={TitleSize.H2}
          text={calendarEvent?.title}
          sub={formatTitleDate(calendarEvent?.dates)}
          right={<Avatar user={calendarEvent?.createdBy} sub="Organiser" style={{marginRight: Spacing.Large}} />}
        />
      </Subheader>
      
      <EventDetails 
        calendarEvent={calendarEvent} 
        validationErrors={validationErrors}
      />
      <EventDates 
        calendarEvent={calendarEvent} 
        signup={_signup} 
        setSignup={_setSignup}
        readonly={readonly}
      />
      <EventMenu 
        calendarEvent={calendarEvent} 
        signup={_signup} 
        setSignup={_setSignup}
        readonly={readonly}
      />
      <EventRequirements 
        calendarEvent={calendarEvent} 
        signup={_signup} 
        setSignup={_setSignup}
        readonly={readonly}
      />

      { !readonly && (
        <ActionBar low>
          { calendarEvent?.signedUp && !calendarEvent?.mandatoryAttendance && (
            <Button
              size={Size.Small}
              text={"Cancel Signup"}
              color={Swatches.Danger}
              style={{ width: 200 }}
              onClick={handleCancel}
            />
          )}
          { !calendarEvent?.mandatoryAttendance && signupStillOpen(calendarEvent) && (
            <Button
              size={Size.Small}
              text={
                calendarEvent?.signedUp ? "Save Changes" : "Signup for this Event"
              }
              color={Swatches.Success}
              fluid
              onClick={handleSave}
            />
          )}
        </ActionBar>
      )}
    </>
  );
};

export default UserEventViewer;
