import React, { useEffect, useState } from "react";
import { Flyout, Swatches, Message, Size, Loader } from "ui-kit";
import { Constants } from "configuration";
import { useSelector } from "react-redux";
import { neutral, honeygold } from "ui-kit/common/colors";
import styled from "styled-components";
import { danger } from "ui-kit/common/colors";
import flyoutActions from "actions/ui/flyouts";
import SelectionButtons from "sharedComponents/common/largeSelectionButtons/selectionButtons";
import SelectionButton from "sharedComponents/common/largeSelectionButtons/selectionButton";
import MultipleBehaviourModal from "./multipleBehaviourModal";
import AddBehaviourPointModal from "areas/behaviour/components/behaviours/addBehaviourPointModal";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import { StudentListView } from "types/users/userListViews.types";
import { ClassGroupStaffView } from "types/users/userGroups.types";
import { useAppSelector } from "reducers/hooks";
import { SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";


const Wrapper = styled.div`
  .selection-button {
    &.selected {
      background: ${honeygold};

      .item-label,
      .item-sub {
        color: ${neutral[800]};
      }

      .detail-label {
        .label,
        .sub {
          color: ${neutral[800]};
        }
      }

      .icon {
        color: ${neutral[100]};
      }
    }
  }
`;


interface IMultipleBehaviourFlyoutProps {
  students: StudentListView[];
  group: ClassGroupStaffView
  resetSelectedStudents?: () => void;
}


const MultipleBehaviourFlyout: React.FC<IMultipleBehaviourFlyoutProps> = ({
  students,
  group,
  resetSelectedStudents,
}) => {

  const { schoolId, behaviourCategoryGroups, loading, getBehavioursError } =
    useAppSelector(state => state.behaviourCategories);
  const { activeFlyout } = useAppSelector(state => state.ui);

  const [openMultipleBehaviourModal, setOpenMultipleBehaviourModal] =
    useState<boolean>(false);
  const [selectedBehaviourCategory, setSelectedBehaviourCategory] =
    useState<SchoolBehaviourCategoryListView>(null);
  const [openSingleBehaviourModal, setOpenSingleBehaviourModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      activeFlyout === Constants.FLYOUTS.MULTIPLE_BEHAVIOURS &&
      (!behaviourCategoryGroups ||
        behaviourCategoryGroups.length === 0 ||
        schoolId !== group.school.id)
    ) {
      behaviourActions.getBehaviourCategories(
        group.school.id,
        {
          isLegacy: null,
          canBeAwarded: true,
          behaviourCodeId: null
        },
        () => behaviourActions.setBehaviourSchoolId(group.school.id)
      );
    }
  }, [activeFlyout]);

  const handleBehaviourCategoryClick = (category: SchoolBehaviourCategoryListView) => {
    setSelectedBehaviourCategory(category);
    // console.log(category);
    if (students.length > 1) {
      setOpenMultipleBehaviourModal(true);
    } else {
      setOpenSingleBehaviourModal(true);
    }
  };

  const handleCloseModal = () => {
    setSelectedBehaviourCategory(null);
    setOpenMultipleBehaviourModal(false);
    setOpenSingleBehaviourModal(false);
    flyoutActions.closeFlyout();
    resetSelectedStudents();
  };

  return getBehavioursError ? (
    <Message
      text="There was a problem loading the categories"
      color={Swatches.Danger}
    />
  ) : (
    <>
      <Flyout
        title="Behaviours"
        name={Constants.FLYOUTS.MULTIPLE_BEHAVIOURS}
        onClose={() => setSelectedBehaviourCategory(null)}
      >
        {loading ? (
          <Loader size={Size.Large} cover />
        ) : (
          <>
            <Flyout.Body>
              <Wrapper>
                <SelectionButtons listView>
                  {behaviourCategoryGroups.map(group =>
                    group.behaviourCategories.map(category => (
                      <SelectionButton
                        key={category.id}
                        className={
                          selectedBehaviourCategory &&
                          selectedBehaviourCategory.id === category.id
                            ? "selected"
                            : null
                        }
                        listView={true}
                        item={category}
                        itemLabel={category.codeDisplayName}
                        itemSub={category.behaviourCategoryName}
                        icon="thumbs-down"
                        iconColour={danger}
                        handleItemClick={() =>
                          handleBehaviourCategoryClick(category)
                        }
                      />
                    ))
                  )}
                </SelectionButtons>
              </Wrapper>
            </Flyout.Body>
          </>
        )}
      </Flyout>
      {selectedBehaviourCategory && openSingleBehaviourModal && (
        <AddBehaviourPointModal
          open={openSingleBehaviourModal}
          handleCloseModal={handleCloseModal}
          selectedCategory={selectedBehaviourCategory}
          onSelectedCategoryChange={category =>
            setSelectedBehaviourCategory(category)
          }
          selectedBehaviourGroupKey={selectedBehaviourCategory.behaviourCodeId}
          student={{ ...students[0], school: group?.school }}
          behaviourCategoryGroups={behaviourCategoryGroups}
          groupId={group?.id}
          groupType={group?.type}
        />
      )}

      <MultipleBehaviourModal
        openModal={openMultipleBehaviourModal}
        onClose={handleCloseModal}
        students={students}
        selectedCategory={selectedBehaviourCategory}
        group={group}
      />
    </>
  );
};

export default MultipleBehaviourFlyout;
