import { SearchResultView } from "types/common/views.types";
import { StaffListView } from "types/users/userListViews.types";
import formStaffActions from "../actions/formStaffActions";
import { IBeehiveAction } from "types/common/action.types";


interface IFormStaffState {
  staff: SearchResultView<StaffListView>[];
  loading: boolean;
  error: string | null;
}

const INITIAL_STATE: IFormStaffState = {
  staff: [],
  loading: false,
  error: null,
};


const formStaffReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IFormStaffState => {

  const { GETFINANCEUSERS } = formStaffActions.types;

  switch (action.type) {
    case GETFINANCEUSERS.START:
      return { ...state, loading: true };

    case GETFINANCEUSERS.SUCCESS:
      return {
        ...state,
        staff: action.payload?.items,
        loading: false,
      };

    case GETFINANCEUSERS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default formStaffReducer;
