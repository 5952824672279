import store from "reducers/store";
import { IBeehiveAction } from "types/common/action.types";

const types = {
  SETTAB: "forms_settab",
};


const setTab = (tabIndex: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.SETTAB,
    payload: tabIndex,
  });
};


const formsActions = {
  types,
  setTab,
};

export default formsActions;
