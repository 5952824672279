import defaults from "configuration/defaults";
import { arrays } from "utils";
import filingActions from "areas/filing/actions/filingActions";
import submissionActions from "areas/forms/actions/submissionActions";
import leaveActions from "../../humanResources/actions/leaveActions";
import { PageInfo } from "types/common/paging.types";
import { FilingFilter } from "../types/fileRequest.types";
import { TrayListView } from "../types/fileResponse.types";
import { IBeehiveAction } from "types/common/action.types";
import { LeaveRequestListView } from "areas/humanResources/types/leaveResponse.types";
import { FormCategoryListView, FormListView } from "areas/forms/types/formResponse.types";


export interface IInTrayFilter extends Omit<FilingFilter, "category" | "formType"> {
  category?: FormCategoryListView | null;
  formType?: FormListView | null;
}

interface IInTrayState {
  items: TrayListView[];
  loading: boolean;
  error: string | null;
  paging: PageInfo;
  filter: IInTrayFilter;
}

const INITIAL_STATE: IInTrayState = {
  items: [],
  loading: false,
  error: null,
  paging: defaults.DEFAULT_PAGING,
  filter: {
    schoolId: -1,
    type: null,
    leaveRequestType: null,
    formType: null,
    category: null,
    status: 1,
  },
};


const inTrayReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IInTrayState => {

  const { INTRAY_GET, INTRAY_FILTER } = filingActions.types;
  const { AUTHORISE, REJECT, CANCEL, READ } = submissionActions.types;

  const {
    APPROVE_LEAVEREQUEST,
    REJECT_LEAVEREQUEST,
    CANCEL_LEAVEREQUEST,
    CONFIRM_LEAVEREQUEST,
    READ_LEAVEREQUEST,
  } = leaveActions.types;

  switch (action.type) {
    case INTRAY_FILTER:
      return { ...state, filter: action.payload };

    case INTRAY_GET.START:
      return { ...state, loading: arrays.isEmpty(state.items), error: null };

    case INTRAY_GET.SUCCESS:
      var newState = {
        ...state,
        items:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.items, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };
      return newState;

    case INTRAY_GET.FAILED:
      return { ...state, loading: false, error: action.payload };

    case READ.SUCCESS:
      return {
        ...state,
        items: state.items.map((i: TrayListView) =>
          i.id === action.payload && i.type === 0
            ? { ...i, approverReadDate: new Date() }
            : i
        ),
      };

    case READ_LEAVEREQUEST.SUCCESS:
      return {
        ...state,
        items: state.items.map((i: TrayListView) =>
          i.id === action.payload && i.type === 1
            ? { ...i, approverReadDate: new Date() }
            : i
        ),
      };

    case APPROVE_LEAVEREQUEST.SUCCESS:
    case REJECT_LEAVEREQUEST.SUCCESS:
    case CANCEL_LEAVEREQUEST.SUCCESS:
    case CONFIRM_LEAVEREQUEST.SUCCESS:
      return {
        ...state,
        items: state.items.map((i: TrayListView) =>
          i.type === 1 && action.payload.leaveRequests.some((s: LeaveRequestListView) => s.id === i.id)
            ? {
                ...i,
                status: action.payload.leaveRequests.filter(
                  (x: LeaveRequestListView) => x.id === i.id
                )?.[0]?.currentStatus.status,
              }
            : i
        ),
      };

    case AUTHORISE.SUCCESS:
    case REJECT.SUCCESS:
    case CANCEL.SUCCESS:
    case REJECT.SUCCESS:
      return {
        ...state,
        items: state.items.map((i: TrayListView) =>
          i.type === 0 && i.id === action.payload.submissionId
            ? { ...i, status: action.payload.status }
            : i
        ),
      };

    default:
      return state;
  }
};

export default inTrayReducer;
