import { PageInfo } from "types/common/paging.types";

export enum ObjectType {
  Room = 0,
  Subject = 1,
  Student = 2,
  Parent = 3,
  Employee = 4,
  House = 5,
  HouseStaff = 6,
  TutorGroup = 9,
  TutorGroupStaff = 10,
  ClassGroup = 12,
  ClassGroupStaff = 13,
  ClassGroupStudent = 14,
  Period = 15,
  Lesson = 16,
  LessonStaff = 17,
}

export const objectTypeDescriptions: Record<ObjectType, string> = {
  [ObjectType.Room]: "Room",
  [ObjectType.Subject]: "Subject",
  [ObjectType.Student]: "Student",
  [ObjectType.Parent]: "Parent",
  [ObjectType.Employee]: "Staff",
  [ObjectType.House]: "House",
  [ObjectType.HouseStaff]: "House Staff",
  [ObjectType.TutorGroup]: "Tutor Group",
  [ObjectType.TutorGroupStaff]: "Tutor Group Staff",
  [ObjectType.ClassGroup]: "Class Group",
  [ObjectType.ClassGroupStaff]: "Class Group Staff",
  [ObjectType.ClassGroupStudent]: "Class Group Student",
  [ObjectType.Period]: "Period",
  [ObjectType.Lesson]: "Lesson",
  [ObjectType.LessonStaff]: "Lesson Staff",
};

export interface SyncFailureReportItems {
  objectTypeId: number;
  objectName: string | null;
  name: string | null;
  providerId: string;
  simsId: string;
  createdDate: string;
  updatedDate: string;
  notes: string;
  objectData: any;
  schoolCode: string;
}

export interface SyncFailureReportResponse {
  items: SyncFailureReportItems[];
  paging: PageInfo;
}

export interface SyncLogReportResponse {
  lines: string[];
  // paging: PageInfo;
}

export interface UserWarningItem {
  id: string;
  title: string;
  firstName: string;
  initials: string;
  lastName: string;
  simsId: string;
  national_Insurance_Number: string | null;
  isCurrent: boolean;
  createdDate: string;
  updatedDate: string | null;
  providerId: string;
  misId: string;
  syncedOn: string;
  notes: string;
  userType: string;
  schoolCode: string;
}

export interface SyncUserWarningsResponse {
  items: UserWarningItem[];
  paging: PageInfo;
}
