import action from "actions/action";
import { ToastService } from "ui-kit";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";

const builder = new actionTypeBuilder("studentLeavingLesson");

const types = {
  GETLEAVINGLESSONCATEGORIES: builder.build("getleavinglessoncategories"),
  ADDLEAVINGLESSONRECORD: builder.build("addleavinglessonrecord"),
  DELETELEAVINGLESSONRECORD: builder.build("deleteleavinglessonrecord"),
  ADDMULTISTUDENTLEAVINGLESSONRECORD: builder.build("addmultistudentleavinglessonrecord"),
};

const getLeavingLessonCategories = onSuccess => {
  return action(
    () => client.get(`planner/lessonExitCategories`),
    types.GETLEAVINGLESSONCATEGORIES,
    onSuccess
  );
};

const createLeavingLessonRecord = (
  studentId,
  leavingLessonRecord,
  onSuccess
) => {
  return action(
    () =>
      client.post(
        `planner/students/${studentId}/lessonExit`,
        leavingLessonRecord
      ),
    types.ADDLEAVINGLESSONRECORD,
    response => {
      ToastService.pop("Successfully recorded", null, "check-circle");
      onSuccess?.(response);
    }
  );
};

const createMultiStudentLeavingLessonRecord = (
  payload,
  onSuccess
) => {
  return action(
    () =>
      client.post(
        `planner/SaveLessonExits`,
        payload
      ),
    types.ADDMULTISTUDENTLEAVINGLESSONRECORD,
    response => {
      ToastService.pop("Successfully recorded", null, "check-circle");
      onSuccess?.(response);
    }
  );
};

const deleteLeavingLessonRecord = (studentId, recordId, onSuccess) => {
  return action(
    () =>
      client.delete(`planner/students/${studentId}/lessonExits/${recordId}`),
    types.DELETELEAVINGLESSONRECORD,
    response => {
      ToastService.pop("Successfully deleted", null, "check-circle");
      onSuccess?.(response);
    }
  );
};

const studentLeavingLessonActions = {
  types,
  getLeavingLessonCategories,
  createLeavingLessonRecord,
  deleteLeavingLessonRecord,
  createMultiStudentLeavingLessonRecord,
};

export default studentLeavingLessonActions;
