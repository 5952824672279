import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Size,
  Swatches,
  ActionBar,
  Card,
  Message,
  Sub,
  ValidationMessage,
  ToastService,
} from "ui-kit";
import { StaffPicker } from "sharedComponents/common";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import bulletinActions from "areas/news/actions/bulletinActions";
import { RootState } from "reducers/store";
import { BulletinDetailView, BulletinFullView, BulletinNotificationView } from "areas/news/types/newsResponse.types";
import { TestSendBulletinCommand } from "areas/news/types/newsRequest.types";
import { StaffListView } from "types/users/userListViews.types";

interface IBulletinTestSendModalProps {
  bulletin: BulletinDetailView | BulletinFullView;
  open?: boolean;
  onClose?: () => void;
  onSent?: () => void;
}

const BulletinTestSendModal: React.FC<IBulletinTestSendModalProps> = ({ bulletin, open, onClose, onSent }) => {

  const { actions, testRecipients } = useSelector((state: RootState) => state.bulletinFull);

  const [_open, _setOpen] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const handleSend = () => {
    var errors = [];

    if (!testRecipients?.length) {
      errors.push(`Please choose one or more recipients.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    var command: TestSendBulletinCommand = {
      bulletin: bulletin as BulletinNotificationView, 
      recipients: testRecipients.map(r => r.id)
    }

    bulletinActions.testSendBulletin(command,
      () => {
        onSent?.();
        ToastService.pop("Test Bulletin Sent", null, "megaphone");
      }
    );
  };

  const handleRecipientsChange = (staff: StaffListView[]) => {
    bulletinActions.updateTestRecipients([...staff]);
  };

  return (
    <Modal
      open={_open}
      onClose={onClose}
      title="Test Send Bulletin"
      width="80%"
      height="60%"
    >
      <Modal.Body>
        <Message text={actions.testSend.error} color={Swatches.Danger} />
        <ValidationMessage errors={validationErrors} />

        <Card>
          <Card.Body>
            <Sub>
              Please note that test recipients will receive a copy of the
              bulletin in its current un-saved version.
            </Sub>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <StaffPicker
              users={testRecipients}
              onChange={handleRecipientsChange}
            />
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Button
            text="Send"
            onClick={handleSend}
            color={Swatches.Success}
            size={Size.Small}
            working={actions.testSend.working}
          />
          <Button
            text="Close"
            onClick={onClose}
            color={Swatches.Low}
            size={Size.Small}
            working={actions.testSend.working}
          />
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default BulletinTestSendModal;
