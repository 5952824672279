import React from "react";
import { useReview } from "./reviewContext";
import { Constants } from "configuration";
import { StatusView, UserName } from "sharedComponents/common";

const ReviewerIntroduction = () => {
  const { state } = useReview();
  return (
    <>
      { state.review.currentReviewPeriod && (
        <StatusView status={state.review.status}>
          <StatusView.Item status={Constants.REVIEW_STATUS.DRAFT.value}>
            <p>
              You have started a new review for{" "}
              <UserName user={state.review.reviewee} /> for the{" "}
              {state.review.academicYear} Academic Year. This page wil take
              you through step-by-step to set the targets and training
              requirements for {state.review.reviewee.firstName}.
            </p>
          </StatusView.Item>

          <StatusView.Item
            status={Constants.REVIEW_STATUS.DRAFT.value}
            inverse
          >
            <StatusView status={state.review.currentReviewPeriod.type}>
              <StatusView.Item status={Constants.REVIEW_PERIODS.MIDYEAR}>
                <p>
                  You are now about to start the mid-year review for{" "}
                  <UserName user={state.review.reviewee} /> for the{" "}
                  {state.review.academicYear} Academic Year.
                </p>
                <p>
                  You will be able to add progress notes for the targets
                  already set and indicate whether the targets are on track or
                  not.
                </p>
                <p>
                  {state.review.reviewee.firstName} is able to log in
                  separately and add evidence that targets have been completed
                  and add any CPD and training undertaken since the review was
                  started.
                </p>
              </StatusView.Item>

              <StatusView.Item status={Constants.REVIEW_PERIODS.YEAREND}>
                <p>
                  You are now about to start the end-of-year review for{" "}
                  <UserName user={state.review.reviewee} /> for the{" "}
                  {state.review.academicYear} Academic Year.
                </p>
                <p>
                  You will be able to add progress notes for the targets
                  already set and indicate whether the targets have been met
                  or not. You will also be able to add a summary for the
                  academic year and give an overall performance rating for{" "}
                  {state.review.reviewee.firstName}.
                </p>
                <p>
                  {state.review.reviewee.firstName} is able to log in
                  separately and add supporting evidence that targets have
                  been completed. {state.review.reviewee.firstName} can also
                  add any CPD and training undertaken since the review was
                  started include a personal summary of the year.
                </p>
                <>{" "}</>
              </StatusView.Item>
            </StatusView>
          </StatusView.Item>
        </StatusView>
      )}
      <p>
        Once you have entered all of the necessary details, you will be able to
        save the review. Please note that details are not saved after each step
        and you will need to finish entering all details before saving.
      </p>
      <p>
        Please click on the <b>'Next'</b> button to continue.
      </p>
    </>
  );
};

export default ReviewerIntroduction;
