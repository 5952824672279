import React, { useEffect } from "react";
import { Constants } from "configuration";
import { useSelector } from "react-redux";
import BehaviourModal from "./behaviourModal";
import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import { addGroupIdToPayload } from "areas/planner/utils/group";
import { useAppSelector } from "reducers/hooks";
import { UserTinyView } from "types/users/userListViews.types";
import { GroupType } from "types/planner/groups.types";
import { MultipleBehavioursSaveResultsView, SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";
import { CreateBehaviourCommand, CreateBehavioursCommand, CreateRewardCommand, CreateRewardsCommand } from "areas/behaviour/types/behaviourRequest.types";
import { IMultipleBehaviourResponse, MultipleBehavioursSaveResultsViewExt } from "areas/behaviour/utils/detentions";


interface IBehaviourModalWrapperProps {
  openBehaviourModal: boolean;
  handleCloseBehaviourModal: () => void;
  students: UserTinyView[];
  schoolId?: number;
  groupId: number;
  groupType: GroupType;
  clearSelectedStudents: () => void;
}


const BehaviourModalWrapper: React.FC<IBehaviourModalWrapperProps> = ({
  openBehaviourModal,
  handleCloseBehaviourModal,
  students,
  schoolId,
  groupId,
  groupType,
  clearSelectedStudents,
}) => {

  const { initialRewardActions } = useAppSelector(
    state => state.initialRewardActions
  );
  const { initialBehaviourActions } = useAppSelector(
    state => state.initialBehaviourActions
  );

  useEffect(() => {
    if (!initialRewardActions || initialRewardActions.length === 0) {
      rewardActions.getInitialRewardActions();
    }

    if (!initialBehaviourActions || initialBehaviourActions.length === 0) {
      behaviourActions.getInitialBehaviourActions();
    }
  }, []);

  const callback = () => {
    handleCloseBehaviourModal();
    clearSelectedStudents();
  };

  const handleAddReward = (category: SchoolBehaviourCategoryListView) => {
    const getRewardPayload = (student: UserTinyView) : CreateRewardCommand => {
      const payload: CreateRewardCommand = {
        studentId: student.id,
        categoryId: category.id,
        behaviourCodeId: category.behaviourCodeId,
        points: category.points,
        initialActionId:
          initialRewardActions.length > 0 &&
          initialRewardActions[0].initialActionId,
        description: null,
        publicNotes: null,
      };

      return addGroupIdToPayload(
        payload,
        groupId,
        groupType
      );
    };

    if (students.length === 1) {
      const newReward = getRewardPayload(students[0]);
      rewardActions.addRewards(
        students[0].id,
        { createRewardCommands: [newReward] },
        callback
      );
    } else {
      const rewards: CreateRewardsCommand = { createRewardCommands: [] };
      students.forEach(student => {
        rewards.createRewardCommands.push(getRewardPayload(student));
      });

      rewardActions.addRewardForMultipleStudentsInGroup(
        groupId,
        groupType,
        rewards,
        callback
      );
    }
  };

  const handleAddBehaviour = (category: SchoolBehaviourCategoryListView) => {
    const getBehaviourPayload = (student: UserTinyView) : CreateBehaviourCommand => {
      return {
        studentId: student.id,
        categoryId: category.id,
        behaviourCodeId: category.behaviourCodeId,
        points: category.points,
        initialActionId:
          initialBehaviourActions.length > 0 &&
          initialBehaviourActions[0].initialActionId,
        classGroupId: groupType == GroupType.ClassGroup ? groupId : null,
        tutorGroupId: groupType == GroupType.TutorGroup ? groupId : null,
        customGroupId: groupType == GroupType.Custom ? groupId : null,
        provisionGroupId: groupType == GroupType.Provision ? groupId : null,
        description: null,
        publicNotes: null,
        otherLocation: null,
        outcomeId: null,
        createDetention: null,
      };
    };

    if (students.length === 1) {
      const newBehaviour = getBehaviourPayload(students[0]);
      behaviourActions.addBehaviour(students[0].id, newBehaviour, callback);
    } else {

      const behaviours: CreateBehavioursCommand = { createBehaviourCommands: [] };
      const dummyMultipleBehaviourResponse: MultipleBehavioursSaveResultsViewExt[] = [];

      students.forEach(student => {
        behaviours.createBehaviourCommands.push(getBehaviourPayload(student));
        dummyMultipleBehaviourResponse.push({
          student: {
            id: student.id,
            title: null,
            initials: null,
            firstName: student.firstName,
            lastName: student.lastName,
            admissionStatus: null
          },
          isSuccessful: true,
          errorMessage: null,
          detentions: [],
          detentionDetails: null
        });
      });

      const multipleBehaviourResponseCopy: IMultipleBehaviourResponse = {
        behaviours: dummyMultipleBehaviourResponse,
        bulkBehaviourStatus: null,
        detentionDetails: null,
        incidentDetails: null
      };

      behaviourActions.createMultipleBehaviourResponse(
        multipleBehaviourResponseCopy
      );

      behaviourActions.addBehaviourForMultipleStudentsInGroup(
        groupId,
        groupType,
        behaviours,
        callback
      );
    }
  };

  return (
    <BehaviourModal
      openBehaviourModal={openBehaviourModal}
      handleCloseBehaviourModal={handleCloseBehaviourModal}
      students={students}
      schoolId={schoolId}
      handleAddReward={handleAddReward}
      handleAddBehaviour={handleAddBehaviour}
    />
  );
};

export default BehaviourModalWrapper;
