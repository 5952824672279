import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  Card,
  StructuredList,
  Message,
  ValidationMessage,
  TextInput,
  TextInputType,
  Button,
  Size,
  Swatches,
  ToastService,
  ActionBar,
} from "ui-kit";
import config from "configuration";
import { arrays } from "utils";
import leaveActions from "areas/humanResources/actions/leaveActions";
import { RootState } from "reducers/store";
import { HolidayCardView } from "areas/humanResources/types/leaveResponse.types";
import { UserStartupView } from "types/users/userStartup.types";
import { SaveHolidayCardCommand } from "areas/humanResources/types/leaveRequest.types";
import {
  ManagedUserDetailView,
  StaffListView,
  UserListView,
} from "types/users/userListViews.types";

interface IEditHolidayCardModalProps {
  open?: boolean;
  holidayCard: HolidayCardView;
  staffMember: UserStartupView | UserListView;
  onSaved?: (holidayCard: HolidayCardView) => void;
  onClose?: () => void;
}

const EditHolidayCardModal: React.FC<IEditHolidayCardModalProps> = ({
  open,
  holidayCard,
  staffMember,
  onSaved,
  onClose,
}) => {

  const [_open, _setOpen] = useState<boolean>(open);
  const [_holidayCard, _setHolidayCard] =
    useState<HolidayCardView>(holidayCard);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const { saving, saveError: error } = useSelector(
    (state: RootState) => state.staffMemberHolidayCard
  );

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    _setHolidayCard(
      holidayCard // This is because the supplied card has a different schema for the staff member
        ? holidayCard
        : {
            id: null,
            staffMember: staffMember as StaffListView,
            lineManager: null,
            carryOver: 0,
            allowance: 0,
            totalAllowance: 0,
            remaining: 0,
            used: 0,
            goldenTimeAllowance: 0,
            goldenTimeRemaining: 0,
            goldenTimeUsed: 0,
            overtimeHours: 0,
            overtimeHoursUsed: 0,
            leaveRequests: [],
            overtime: [],
            academicYear: config.academicYear.current,
            eligibleForOvertime: false,
            goldenTimeAuthoriser: undefined,
            academicYearId: config.academicYear.current,
          }
    );
  }, [holidayCard]);

  const handleCancel = () => {
    _setOpen(false);
    onClose?.();
  };

  const handleSave = () => {
    var errors = [];

    if (_holidayCard.allowance < 0) {
      errors.push("The number of allowance days is not valid.");
    }

    if (_holidayCard.carryOver < 0) {
      errors.push("The number of carry over days is not valid.");
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    var request: SaveHolidayCardCommand = {
      id: _holidayCard.id,
      academicYearId: _holidayCard.academicYearId,
      staffMemberId: _holidayCard.staffMember.id,
      allowance: _holidayCard.allowance,
      carryOver: _holidayCard.carryOver,
      eligibleForOvertime: _holidayCard.eligibleForOvertime,
    };

    if (_holidayCard.id) {
      leaveActions.saveHolidayCard(request, (data: HolidayCardView) => {
        ToastService.pop("Holiday Card Saved", null, "plane");
        _setOpen(false);
        onSaved?.(data);
      });
    } else {
      leaveActions.createHolidayCard(request, (data: HolidayCardView) => {
        ToastService.pop("Holiday Card Saved", null, "plane");
        _setOpen(false);
        onSaved?.(data);
      });
    }
  };

  return (
    <Modal open={_open} title="Holiday Card" onClose={onClose} width="50%">
      <Modal.Body scrollOnErrors={validationErrors}>
        <ValidationMessage errors={validationErrors} />
        <Message text={error} color={Swatches.Danger} />
        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item
                name="Allowance"
                required
                description="This is the normal holiday allowance as per this staff member's contract."
              >
                <TextInput
                  type={TextInputType.Number}
                  value={_holidayCard?.allowance}
                  min={0}
                  onChange={(value) =>
                    _setHolidayCard({ ..._holidayCard, allowance: value })
                  }
                  suffix="Days"
                  fluid
                />
              </StructuredList.Item>
              <StructuredList.Item
                name="Carry Over"
                description="This is the number of unused holiday days carried over from last year."
                required
              >
                <TextInput
                  type={TextInputType.Number}
                  value={_holidayCard?.carryOver}
                  min={0}
                  onChange={(value) =>
                    _setHolidayCard({ ..._holidayCard, carryOver: value })
                  }
                  suffix="Days"
                  fluid
                />
              </StructuredList.Item>
              {/* <StructuredList.Item
                name="Overtime"
                description="Whether this staff member is eligible for overtime based on their contract."
                required
              >
                <Checkbox
                  text="Eligible For Overtime"
                  checked={_holidayCard?.eligibleForOvertime}
                  onChange={(value) =>
                    _setHolidayCard({
                      ..._holidayCard,
                      eligibleForOvertime: value.checked,
                    })
                  }
                />
              </StructuredList.Item> */}
            </StructuredList>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Button
            text="Save"
            onClick={handleSave}
            color={Swatches.Success}
            size={Size.Medium}
            working={saving}
          />
          <Button
            text="Cancel"
            onClick={handleCancel}
            color={Swatches.Low}
            size={Size.Medium}
            working={saving}
          />
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default EditHolidayCardModal;
