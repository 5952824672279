import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "ui-kit";
import { arrays } from "utils";
import formCategoriesActions from "areas/forms/actions/formCategoriesActions";
import { RootState } from "reducers/store";
import { ISubmissionFilter } from "areas/forms/reducers/submissionFilterReducer";
import { FormCategoryListView } from "areas/forms/types/formResponse.types";


interface IFormCategoryDropDownProps {
  onChange?: (cat: FormCategoryListView) => void;
  filter?: ISubmissionFilter;
  initialValue?: FormCategoryListView;
  fluid?: boolean;
}

interface IFormCategoryOption {
  key: number;
  label: string;
  value: number;
}


const ALL_OPTION: IFormCategoryOption = { key: -1, label: "All Categories", value: -1 };

const FormCategoryDropDown: React.FC<IFormCategoryDropDownProps> = ({ onChange, filter, initialValue, fluid }) => {

  const { categories, loading: loadingCategories } = useSelector((state: RootState) => state.formCategories);
  // const { loading, filter } = useSelector((state: RootState) => state.submissionFilter);
  const { loading } = useSelector((state: RootState) => state.submissionFilter);
  const [selectedValue, setSelectedValue] = useState<number | null>(null);
  const [_filter, _setFilter] = useState<ISubmissionFilter>(filter);

  useEffect(() => {
    _setFilter(filter);
  }, [filter]);

  const handleChange = (value: number) => {
    setSelectedValue(value);
    if (onChange) {
      const cat = categories.find(x => x.id === value);
      onChange(cat ? cat : { id: ALL_OPTION.key, name: ALL_OPTION.label, forms: [] });
    }
  };

  const getOptions = () : IFormCategoryOption[] => {
    var opts: IFormCategoryOption[] = categories.map(({ id, name }, index) => ({
      key: index,
      label: name,
      value: id,
    }));
    return [ALL_OPTION, ...opts];
  };

  useEffect(() => {
    formCategoriesActions.getCategories(null);
  }, []);

  useEffect(() => {
    //The internal selected state is needed as the selected cateogry
    //from the reducer is not in the correct object format, so needs
    //to be mapped to a format the dropdown accepts.
    setSelectedValue(_filter.category ? _filter.category.id : -1);
  }, [_filter.category]);

  useEffect(() => {
    //const id = initialValue ? initialValue.id : initialValue; What?
    setSelectedValue(-1);
  }, [categories]);

  useEffect(() => {
    const id = initialValue ? initialValue.id : initialValue;
    const category = categories.find(x => x.id === id || x === initialValue);
    setSelectedValue(category ? category.id : -1);
  }, [initialValue]);

  return !loading && !arrays.isEmpty(categories) ? (
    <Dropdown
      placeholder="Category"
      items={getOptions()}
      onChange={handleChange}
      value={selectedValue}
      loading={loadingCategories}
      fluid={fluid}
    />
  ) : null;
};

export default FormCategoryDropDown;
