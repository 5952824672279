/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Spacing,
  neutral,
  honeygold,
  BorderRadius,
  fontStyle,
  displayFont,
  typescale,
  formatScrollbars,
  Subtitle,
  Size,
  Loader,
} from "../index";
import { useEventListener } from "../../hooks";

export interface IModalProps {
  children:
    ( React.ReactElement<IModalBodyProps>
    | React.ReactElement<IModalHeaderProps>
    | React.ReactElement<IModalFooterProps> )[]
    | React.ReactElement<IModalBodyProps>;
  open?: boolean;
  title?: string | JSX.Element;
  width?: string;
  height?: string;
  onOpen?(): void;
  onClose?(): void;
  loading?: boolean;
  empty?: boolean;
  emptyMessage?: React.ReactElement;
  error?: string;
  errorMessage?: React.ReactElement;
  lock?: boolean;
}

export interface IModalBodyProps {
  noPad?: boolean;
  children: React.ReactElement | React.ReactElement[];
  loading?: boolean;
  scrollOnErrors?: string[];
}

export interface IModalFooterProps {
  children: React.ReactElement | React.ReactElement[];
}

export interface IModalHeaderProps {
  children?: React.ReactElement | React.ReactElement[];
}

const Overlay = styled.div<{open: boolean}>`
  ${({ open }) => (open ? `display: flex;` : `display: none;`)}
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
`;

const ModalWrapper = styled.div<{width?: string, height?: string}>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  max-height: 95vh;
  max-width: 95vw;
  min-width: 50vw;
  border-radius: ${BorderRadius.Default}px;

  ${({ width }) =>
    width && `max-width: auto; min-width: auto; width: ${width};`}
  ${({ height }) =>
    height && `max-height: auto; min-height: auto; height: ${height};`}
`;

const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  background: ${neutral[200]};
  flex-grow: 1;
  height: inherit;
`;

const ModalTitleBar = styled.div`
  display: flex;
  flex-grow: 0;
  border-top-right-radius: ${BorderRadius.Default}px;
  border-top-left-radius: ${BorderRadius.Default}px;
  padding: ${Spacing.Medium}px ${Spacing.Medium}px ${Spacing.Default}px
    ${Spacing.Medium}px;
  background: ${neutral[200]};
  border-bottom: 1px solid ${neutral[400]};

  align-items: center;
  .modal-title-bar-left {
    flex-grow: 1;

    .subtitle {
      ${fontStyle(displayFont.medium, typescale.header4, neutral[600])};

      text-align: center;
    }
  }

  .modal-title-bar-right {
    position: absolute;
    right: ${Spacing.Medium}px;
    .button {
      text-align: center;
      padding: 0;

      background: none;

      i {
        color: ${neutral[500]};
        margin: 0;
        transition: all ease 150ms;
      }
      &:hover {
        i {
          color: ${honeygold};
        }
      }
    }
  }
`;

const BodyWrapper = styled.div<{noPad?: boolean}>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  // height: 0px;
  padding: ${Spacing.Medium}px ${Spacing.Medium}px 0 ${Spacing.Medium}px;
  overflow-y: auto !important;
  ${formatScrollbars(neutral[200])};

  ${({ noPad }) => noPad && `padding: 0;`}

  > iframe {
    box-sizing: box-content;
    margin: 0;
    padding: 0;

    html {
      overflow-y: auto !important;
    }
  }
`;

const HeaderWrapper = styled.div`
  background: ${neutral[200]};
  display: flex;
  align-content: center;
  border-bottom: 1px solid ${neutral[300]};

  .left {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  .right {
    display: flex;
    align-items: center;
  }
`;

const FooterWrapper = styled.div`
  padding: ${Spacing.Medium}px;
  border-top: 1px solid ${neutral[300]};
  background: ${neutral[200]};
  border-bottom-right-radius: ${BorderRadius.Default}px;
  border-bottom-left-radius: ${BorderRadius.Default}px;

  .action-bar {
    justify-content: flex-end;
    margin-bottom: 0;
  }
  .left {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  .right {
    display: flex;
    align-items: center;
  }
`;

const Modal: React.FC<IModalProps> & {
  Body: typeof ModalBody;
  Header: typeof ModalHeader;
  Footer: typeof ModalFooter;
} = ({
  children,
  open,
  onOpen,
  onClose,
  title,
  width,
  height,
  loading,
  empty,
  emptyMessage,
  lock,
  error,
  errorMessage,
}) => {
  const [isOpen, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  useEffect(() => {
    if (open) {
      setOpen(true);
      onOpen?.();
    } else {
      handleClose();
    }
  }, [open]);

  const handleKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
    if (open && !lock) {
      switch (key) {
        case "Escape":
          handleClose();
          break;
      }
    }
  };

  useEventListener("keydown", handleKeyDown);

  return (
    <Overlay open={isOpen} className="modal-overlay">
      <ModalWrapper width={width} height={height} className="modal-wrapper">
        <ModalTitleBar className="modal-title-bar">
          <div className="modal-title-bar-left">
            {title && <Subtitle text={title} />}
          </div>
          {!lock && (
            <div className="modal-title-bar-right">
              <Button icon="times" onClick={handleClose} />
            </div>
          )}
        </ModalTitleBar>
        <ModalInner className="modal-inner">
          {" "}
          {loading && <Loader size={Size.Medium} cover />}
          {!loading && error && empty && errorMessage}
          {!loading && !error && empty && emptyMessage}
          {!loading && !error && !empty && children}
        </ModalInner>
      </ModalWrapper>
    </Overlay>
  );
};

const ModalBody: React.FC<IModalBodyProps> = ({ children, noPad, scrollOnErrors }) => {

  const scrollRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (scrollOnErrors?.length > 0) {
      scrollRef.current?.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }
  }, [scrollOnErrors])

  return (
    <BodyWrapper ref={scrollRef} className="modal-body" noPad={noPad}>
      {children}
    </BodyWrapper>
  );
};

// const ModalBody = React.forwardRef<HTMLDivElement, IModalBodyProps>(({ noPad, children, loading }: IModalBodyProps, ref) => {
//   return (
//     <BodyWrapper className="modal-body" noPad={noPad} ref={ref}>
//       {children}
//     </BodyWrapper>
//   );
// });

const ModalHeader: React.FC<IModalHeaderProps> = ({ children }) => {
  return <HeaderWrapper className="modal-header">{children}</HeaderWrapper>;
};

const ModalFooter: React.FC<IModalFooterProps> = ({ children }) => {
  return <FooterWrapper className="modal-footer">{children}</FooterWrapper>;
};

Modal.Body = ModalBody;
Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;

export default Modal;
