import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StudentModal } from "sharedComponents/common";
import {
  Size,
  EmptyMessage,
  HeadlineStatistic,
  Label,
  Table,
  StructuredList,
  DateTime,
  DetailLabel,
  ActionBar,
  Chip,
  Button,
  Card,
  Spacing,
  Swatches,
} from "ui-kit";
import lunchRegisterActions from "areas/payments/actions/lunchRegisterActions";
import { arrays, types } from "utils";
import AdjustBalanceModal from "./adjustBalanceModal";
import { RootState } from "reducers/store";
import { LunchBalanceListView, LunchRecordListView, LunchTransactionListView } from "areas/payments/types/catering/cateringResponses.types";


interface IAccountModalProps {
  account: LunchBalanceListView | LunchRecordListView;
  schoolId: number;
  open?: boolean;
  onClose?: () => void;
}


const AccountModal: React.FC<IAccountModalProps> = ({ account, schoolId, open, onClose }) => {

  const [_open, _setOpen] = useState<boolean>(false);
  const [_account, _setAccount] = useState<LunchBalanceListView | LunchRecordListView | null>(null);
  const [adjustmentModalOpen, setAdjustmentModalOpen] = useState<boolean>(false);

  const { history, loading, error } = useSelector((state: RootState) => state.lunchHistory);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    _setAccount(account);
    if (account) {
      lunchRegisterActions.getLunchHistory(account.user.id);
    }
  }, [account]);

  const handleClose = () => {
    onClose?.();
  };

  const handleCreateAdjustment = () => {
    setAdjustmentModalOpen(true);
  };

  const handleAdjustmentModalClose = () => {
    setAdjustmentModalOpen(false);
  };

  const handleAdjustmentModalSaved = (newBalance: number) => {
    setAdjustmentModalOpen(false);
    _setAccount({ ..._account, balance: newBalance });
  };

  return (
    <>
      <StudentModal
        title="Lunch Account Details"
        open={_open}
        onClose={handleClose}
        width="80%"
        height="80%"
        student={_account?.user}
        profile={
          <>
            {_account?.yearGroup ? (
              <Chip
                text={_account?.yearGroup.name}
                style={{ marginBottom: Spacing.Default + "px" }}
                colorSwatch={Swatches.Default}
              />
            ) : null}

            {_account?.tutorGroup ? (
              <Chip
                text={_account?.tutorGroup.name}
                style={{ marginBottom: Spacing.Default + "px" }}
                colorSwatch={Swatches.Default}
              />
            ) : null}

            { types.isType<LunchRecordListView>(_account, "dietaryInformation") && _account?.user?.freeSchoolMeal ? (
              <Chip text="Free School Meal" colorSwatch={Swatches.Miami} />
            ) : null}
          </>
        }
        footer={
          <ActionBar low>
            <Button
              text="Create Adjustment"
              color={Swatches.Primary}
              onClick={handleCreateAdjustment}
              size={Size.Small}
            />
          </ActionBar>
        }
      >
        <HeadlineStatistic>
          <HeadlineStatistic.Item
            icon="utensils"
            label="Lunch Credit Balance"
            value={_account?.balance.toString()}
          />
        </HeadlineStatistic>

        <Card>
          <Card.Body>
            <StructuredList>
            { types.isType<LunchRecordListView>(_account, "dietaryInformation") && (
              <StructuredList.Item name="Dietary Requirements">
                <Label>
                  {_account?.dietaryInformation
                    ? _account?.dietaryInformation
                    : "No dietary requirements specified"}
                </Label>
              </StructuredList.Item>
            )}
              <StructuredList.Item name="Default Lunch Type">
                <Label>
                  {_account?.defaultLunchTypeName
                    ? _account?.defaultLunchTypeName
                    : "No default lunch option"}
                </Label>
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>

        <Card grow>
          <Card.Body noPad>
            <Table
              grow
              zebra
              loading={loading}
              empty={arrays.isEmpty(history)}
              emptyMessage={
                <EmptyMessage
                  icon="utensils"
                  title="No Lunch Records"
                  summary="No lunch records were found for this selection"
                  cover
                />
              }
              error={error != null}
            >
              <Table.Header>
                <Table.HeaderCell width={2}>Date</Table.HeaderCell>
                <Table.HeaderCell width={5}>Description</Table.HeaderCell>
                <Table.HeaderCell width={2}></Table.HeaderCell>
                <Table.HeaderCell right width={1}>
                  Credits
                </Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {history?.map((hist: LunchTransactionListView, index: number) => (
                  <Table.Row key={index}>
                    <Table.Cell width={2}>
                      <DateTime date={hist.date} bold />
                    </Table.Cell>
                    <Table.Cell width={5}>
                      {hist.recordDetails != null && (
                        <DetailLabel
                          bold
                          label={hist.recordDetails.description}
                          sub={
                            hist.recordDetails.lunchType === 0
                              ? `Option ${hist.recordDetails.lunchOption}`
                              : null
                          }
                        />
                      )}

                      {hist.adjustmentDetails != null && (
                        <DetailLabel
                          bold
                          label={hist.adjustmentDetails.adjustmentReasonName}
                          sub={hist.adjustmentDetails.methodName}
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell width={2}>
                      {hist.recordDetails?.freeSchoolMeal && (
                        <Chip
                          text="Free School Meal"
                          colorSwatch={Swatches.Miami}
                        />
                      )}
                      {hist.recordDetails?.universalFreeSchoolMeal && (
                        <Chip text="Universal" colorSwatch={Swatches.Miami} />
                      )}
                    </Table.Cell>
                    <Table.Cell right width={1}>
                      {hist.total !== 0 && (
                        <Chip
                          fluid
                          colorSwatch={
                            hist.total > 0 ? Swatches.Success : Swatches.Danger
                          }
                          text={hist.total.toString()}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Card.Body>
        </Card>
      </StudentModal>
      <AdjustBalanceModal
        account={_account}
        schoolId={schoolId}
        open={adjustmentModalOpen}
        onClose={handleAdjustmentModalClose}
        onSave={handleAdjustmentModalSaved}
      />
    </>
  );
};

export default AccountModal;
