import { IBeehiveError } from "types/common/errors.types";
import timetableActions from "../actions/timetableActions";
import { IBeehiveAction } from "types/common/action.types";
import { TimetableAppView } from "../types/timetable/timetableResponse.types";


interface ITimetableState {
  timetable: TimetableAppView;
  error: IBeehiveError;
  loading: boolean;
}

const INITIAL_STATE : ITimetableState = {
  timetable: null,
  error: null,
  loading: false,
};


const timetableReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ITimetableState => {

  const { TIMETABLE_GETTIMETABLE } = timetableActions.types;

  switch (action.type) {
    case TIMETABLE_GETTIMETABLE.START:
      return { ...state, loading: true, error: null };

    case TIMETABLE_GETTIMETABLE.SUCCESS:
      return { ...state, timetable: action.payload, loading: false };

    case TIMETABLE_GETTIMETABLE.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default timetableReducer;
