import React, { useState } from "react";
import {
  DetailLabel,
  EmptyMessage,
  Loader,
  Size,
  Table,
  Chip,
  Swatches,
  SplitButton,
} from "ui-kit";
import { formatDate } from "utils/dateTime";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import ViewWarningModal from "./ViewWarningModal";
import { Link, useNavigate } from "react-router-dom";
import routes from "configuration/routes";
import { UserWarningItem } from "areas/administration/types/syncResponses.types";
import { SchoolIcon } from "sharedComponents/common";

interface SyncUserWarningTableProps {
  handlePage: () => void;
  loading?: boolean;
}

const SyncUserWarningTable: React.FC<SyncUserWarningTableProps> = ({
  handlePage,
  loading,
}) => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<UserWarningItem | null>(
    null
  );

  const { warnings, error, paging } = useSelector(
    (state: RootState) => state.userWarning
  );

  if (loading) {
    return <Loader size={Size.Large} fluid />;
  }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  const parseWarnings = (data: string) => {
    if (typeof data !== "string" || data.trim() === "") {
      console.error("Invalid input: Data should be a non-empty string.");
      return [];
    }
    try {
      const warningsArray = data.split("~").map((warning) => warning.trim());
      const warnings = warningsArray.filter((warning) => warning.length > 0);
      return warnings;
    } catch (error) {
      console.error("An error occurred while parsing warnings:", error);
      return [];
    }
  };
  return !warnings ? null : warnings.length > 0 ? (
    <>
      <br />
      <Table grow loading={loading}>
        <Table.Header>
          <Table.HeaderCell width={0.5} />
          <Table.HeaderCell width={1.5}>User</Table.HeaderCell>
          <Table.HeaderCell width={1}>User Type</Table.HeaderCell>
          <Table.HeaderCell width={0.8}>Sims ID</Table.HeaderCell>
          <Table.HeaderCell width={1}>Wonde ID</Table.HeaderCell>
          <Table.HeaderCell width={1}>Created Date</Table.HeaderCell>
          <Table.HeaderCell width={1}>Last Synced</Table.HeaderCell>
          <Table.HeaderCell width={1}>Total Warnings</Table.HeaderCell>
          <Table.HeaderCell width={1.8} right />
        </Table.Header>

        <Table.Body onPage={handlePage} paging={paging}>
          {warnings.map((item: UserWarningItem, index) => (
            <Table.Row key={index}>
              <Table.Cell width={0.5}>
                <SchoolIcon code={item.schoolCode} />
              </Table.Cell>
              <Table.Cell width={1.5}>
                <Link to={`/main/administration/users/${item.id}`} key={index}>
                  <DetailLabel
                    label={`${item.firstName} ${item.lastName}`}
                    bold
                  />
                </Link>
              </Table.Cell>
              <Table.Cell width={1}>{<Chip text={item.userType} />}</Table.Cell>
              <Table.Cell width={0.8}>{item.simsId}</Table.Cell>
              <Table.Cell width={1}>{item.providerId}</Table.Cell>
              <Table.Cell width={1}>{formatDate(item.createdDate)}</Table.Cell>
              <Table.Cell width={1}>{formatDate(item.syncedOn)}</Table.Cell>
              <Table.Cell width={1}>
                {parseWarnings(item.notes).length}
              </Table.Cell>
              <Table.Cell width={1.8} right>
                <SplitButton
                  size={Size.Small}
                  text="Actions"
                  color={Swatches.Default}
                >
                  <SplitButton.Option
                    onClick={() => setSelectedItem(item)}
                    text="View Warnings"
                    show
                  />
                  <SplitButton.Option
                    onClick={() =>
                      navigate(`${routes.home}/administration/users/${item.id}`)
                    }
                    text="User Profile"
                    show
                  />
                </SplitButton>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <ViewWarningModal
        open={!!selectedItem}
        notes={selectedItem && parseWarnings(selectedItem.notes)}
        onCancel={() => setSelectedItem(null)}
      ></ViewWarningModal>
    </>
  ) : (
    <EmptyMessage title="No Warnings Found" icon="list" />
  );
};

export default SyncUserWarningTable;
