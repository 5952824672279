import React from "react";
import PropTypes from "prop-types";
import { Checkbox, StructuredList, Card } from "ui-kit";
import { smartTaskData } from "./newSmartTaskFormWrapper";



interface ISmartTaskOptionsProps {
  smartTask: smartTaskData;
  setSmartTask: (data: smartTaskData) => void;
}


const SmartTaskOptions: React.FC<ISmartTaskOptionsProps> = ({ smartTask, setSmartTask }) => {

  const setActive = () => {
    setSmartTask({ ...smartTask, isActive: !smartTask.isActive });
  };

  return (
    <Card title="Smart Task Options">
      <Card.Body>
        <StructuredList.Item
          name="Status"
          description="Activating a smart task will trigger the actions when the smart task conditions are met"
        >
          <Checkbox
            text="Active"
            checked={smartTask.isActive}
            onChange={setActive}
          />
        </StructuredList.Item>

        <StructuredList.Item
          name="Multiple Triggers"
          description="Allowing multiple triggers will trigger the smart task every time the trigger conditions are met by a student. 
          Not allowing multiple triggers will mean that the smart task will only be triggered once for a student."
        >
          <Checkbox
            text="Allow"
            checked={smartTask.allowMultiple}
            onChange={() =>
              setSmartTask({
                ...smartTask,
                allowMultiple: !smartTask.allowMultiple,
              })
            }
          />
        </StructuredList.Item>
      </Card.Body>
    </Card>
  );
};


export default SmartTaskOptions;
