import React from "react";
import { StructuredList, HtmlText, Swatches, Card, Chip } from "ui-kit";
import moment from "moment";
import Moment from "react-moment";
import { arrays } from "utils";
import Avatar from "sharedComponents/common/users/avatar";
import FileList from "sharedComponents/common/files/fileList";
import LinkList from "sharedComponents/common/links/linkList";
import { AssignmentDetailView } from "types/planner/assignments.types";


interface IAssignmentDetailsProps {
  assignment: AssignmentDetailView;
}


const AssignmentDetails: React.FC<IAssignmentDetailsProps> = ({ assignment }) => {

  const getRecommendedTime = (timescale: number) => {
    const hours = Math.floor(timescale / 60);
    const minutes = timescale % 60;
    const showS = (time: number) => (time == 1 ? "" : "s");
    const showUnit = (title: string, unit: number) =>
      unit > 0 ? `${unit} ${title}${showS(unit)}` : "";

    return `${showUnit("Hour", hours)} ${showUnit("Minute", minutes)}`;
  };

  return (
    <>
      <Card>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Deadline">
              {assignment.deadline !== null && (
                <>
                  <Moment
                    date={assignment.deadline}
                    format="dddd, Do MMMM YYYY"
                  />{" "}
                </>
              )}
              {assignment.isComplete && (
                <Chip text="Completed" colorSwatch={Swatches.Success} />
              )}
              {assignment.isOverdue && (
                <Chip text="Late" colorSwatch={Swatches.Danger} />
              )}
            </StructuredList.Item>
            <StructuredList.Item name="Set By">
              <Avatar
                user={assignment.setBy}
                sub={moment(assignment.setOn).format("dddd, Do MMMM YYYY")}
              />
            </StructuredList.Item>
            {assignment.suggestedTimescale !== 0 && (
              <StructuredList.Item name="Suggested Timescale">
                {getRecommendedTime(assignment.suggestedTimescale)}
              </StructuredList.Item>
            )}
          </StructuredList>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Details">
              <HtmlText html={assignment.details} />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      {!arrays.isEmpty(assignment.files) && (
        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="Attachments">
                <FileList>
                  {assignment.files?.map((file, index) => (
                    <FileList.Item
                      key={index}
                      file={{ ...file, id: file.id }}
                      clickToDownload
                    />
                  ))}
                </FileList>
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      )}

      {!arrays.isEmpty(assignment.links) && (
        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="Links">
                <LinkList>
                  {assignment.links.map((link, index) => (
                    <LinkList.Item key={index} link={link} />
                  ))}
                </LinkList>
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default AssignmentDetails;
