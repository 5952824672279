import React from "react";
import { ReactComponent as FoxSvg } from "assets/primary/characters/fox.svg";
import { IAnimalIconProps } from "./primaryCharacter";


export const Fox: React.FC<IAnimalIconProps> = ({ className }) => {

  return (
    <FoxSvg 
      //alt={alt} 
      className={className} 
    />
  )
};

export default Fox;
