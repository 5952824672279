import { SchoolListView } from "types/schools/schools.types";
import { StudentListView, UserTinyView } from "./userListViews.types";
import { CalendarEventListView } from "areas/calendar/types/calendarResponses.types";
import { ClassLayoutView, LayoutListView, RoomListView } from "areas/planner/types/classLayoutResponse.types";
import { CustomGroupCategory } from "areas/planner/types/groups/customGroupShared.types";


export interface GroupListView {
  id: number;
  name: string;
  friendlyName: string;
  school: SchoolListView;
  schoolId: number;
  summary?: string | null;
  code?: string | null;
  type: GroupType;
  isPrivate?: boolean | null;
  allowSignup?: boolean | null;
  isFavourite?: boolean | null;
  isMyGroup?: boolean | null;
  yearGroupId?: number | null;
  yearGroupName?: string | null;
  subjectId?: number | null;
  subjectName?: string | null;
  isActive?: boolean | null;
  category?: CustomGroupCategory | null;
}

export interface CustomGroupDetailView {
  id: number;
  schoolId: number;
  academicYearId: number;
  subject: GroupListView;
  yearGroup: GroupListView;
  name: string;
  isPrivate: boolean;
  allowSignup?: boolean | null;
  isActive: boolean;
  category?: CustomGroupCategory | null;
  responsibleStaff: UserTinyView[];
  students: CustomGroupStudentView[];
  createdDate: string;
  createdBy: UserTinyView;
  linkedEvents: CalendarEventListView[];
  description: string;
}

export interface CustomGroupStudentView extends UserTinyView {
  canBeDeleted: boolean;
}

export interface ClassGroupStaffView extends GroupListView {
  students: StudentListView[];
  layouts: LayoutListView[];
  defaultLayout: ClassLayoutView;
  rooms: RoomListView[];
  responsibleStaff: UserTinyView[];
  createdBy: UserTinyView;
  createdDate: string | null;
  totalIssuedBehaviourPoints: number;
  totalBehaviourPoints: number;
  totalIssuedRewardPoints: number;
  totalRewardPoints: number;
  linkedEvents: CalendarEventListView[];
}

export enum GroupType {
  ClassGroup = 0,
  Subject = 1,
  TutorGroup = 2,
  YearGroup = 3,
  House = 4,
  Custom = 5,
  Provision = 6
}