import interventionActions from "areas/planner/actions/interventions/interventionActions";
import { IBeehiveAction } from "types/common/action.types";

interface IAccessArrangementState {
  saving: boolean;
  saveError: string | null;
  deleting: boolean;
  deleteError: string | null;
  newId: number | null;
}

const INITIAL_STATE: IAccessArrangementState = {
  saving: false,
  saveError: null,
  deleting: false,
  deleteError: null,
  newId: null,
};

const interventionReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAccessArrangementState => {

  const {
    GROUPINTERVENTIONS_GET,
    GROUPINTERVENTIONS_CREATE,
    GROUPINTERVENTIONS_SAVE,
    GROUPINTERVENTIONS_DELETE
  } = interventionActions.types;

  switch (action.type) {

    case GROUPINTERVENTIONS_GET.START:
      return { 
        ...state, 
        saving: false,
        saveError: null,
        deleting: false,
        deleteError: null
      };

    case GROUPINTERVENTIONS_SAVE.START:
    case GROUPINTERVENTIONS_CREATE.START:
      return { 
        ...state, 
        saveError: null, 
        saving: true 
      };
    case GROUPINTERVENTIONS_SAVE.SUCCESS:
    case GROUPINTERVENTIONS_CREATE.SUCCESS:
      return { 
        ...state, 
        saving: false, 
        newId: action.payload.id
      };
    case GROUPINTERVENTIONS_CREATE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem creating the intervention record",
      };

    case GROUPINTERVENTIONS_SAVE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the intervention record",
      };

    case GROUPINTERVENTIONS_DELETE.START:
      return { 
        ...state, 
        deleteError: null, 
        deleting: true 
      };
    case GROUPINTERVENTIONS_DELETE.SUCCESS:
      return { 
        ...state, 
        deleting: false 
      };
    case GROUPINTERVENTIONS_DELETE.FAILED:
      return {
        ...state,
        deleting: false,
        deleteError: "There was a problem deleting the intervention record. This may be because this intervention record is in use.",
      };

    default:
      return state;
  }
};

export default interventionReducer;
