import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import { Dropdown, Sub } from "ui-kit";
import accessArrangementActions from "../../actions/accessArrangements/accessArrangementsActions";
import { RootState } from "reducers/store";
import { AccessArrangementListView } from "areas/send/types/passportResponse.types";


interface IAccessArrangementDropdownProps {
  value?: AccessArrangementListView;
  fluid?: boolean; 
  onChange: (value: AccessArrangementListView, label: string) => void; 
  filter?: AccessArrangementListView[];
}


const AccessArrangementDropdown: React.FC<IAccessArrangementDropdownProps> = ({ value, fluid, onChange, filter }) => {

  const { error, loading, accessArrangements } = useSelector(
    (state: RootState) => state.accessArrangements
  );

  useEffect(() => {
    arrays.isEmpty(accessArrangements) &&
      accessArrangementActions.getAccessArrangements();
  }, []);

  if (error) {
    return <Sub>There was a problem loading the access arrangements</Sub>;
  }

  return (
    <Dropdown
      value={value}
      loading={loading}
      placeholder="Choose an Access Arrangement"
      fluid={fluid}
      onChange={onChange}
    >
      {accessArrangements
        .filter(x => !filter || !filter.some(t => t.id === x.id))
        .map((accessArrangements, index) => (
          <Dropdown.Item
            label={accessArrangements.name}
            value={accessArrangements}
            key={index}
          />
        ))}
    </Dropdown>
  );
};

export default AccessArrangementDropdown;
