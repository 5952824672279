import { formatDate } from "utils/dateTime";
import moment from "moment";
import { DetailLabel } from "ui-kit";
import { GroupBy } from "areas/analytics/types/behaviourAnalyticsShared.types";


export const getGroupName = (groupTypeId: GroupBy, groupName: string) => {

  switch (groupTypeId) {
    case GroupBy.YearGroup:
      return groupName ? `Year ${groupName}` : "Not Specified";

    case GroupBy.AwardedDate:
      return formatDate(moment(groupName, ["DD/MM/YYYY HH:mm:ss"]).format());

    case GroupBy.Pupil:
      const studentInfoArray = groupName.split("|");
      const studentOtherInfo =
        (studentInfoArray.length > 1 ? `${studentInfoArray[1]} ` : "") +
        (studentInfoArray.length > 2 ? `| ${studentInfoArray[2]}` : "");
      return (
        <DetailLabel label={studentInfoArray[0]} sub={studentOtherInfo} bold />
      );

    case GroupBy.Teacher:
      const teacherInfoArray = groupName.split("|");
      const teacherOtherInfo =
        (teacherInfoArray.length > 1 ? `${teacherInfoArray[1]} ` : "") +
        (teacherInfoArray.length > 2 ? `| ${teacherInfoArray[2]}` : "");
      return (
        <DetailLabel label={teacherInfoArray[0]} sub={teacherOtherInfo} bold />
      );

    case GroupBy.DetentionType:
      return groupName ? groupName : "No Detention";

    default:
      return groupName ? groupName : "Not Specified";
  }
};
