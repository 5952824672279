import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { users } from "utils";
import { 
    Left,
    Right,
    Card,
    List,
    formatScrollbars,
    neutral,
    Spacing,
    Swatches,
    Message,
    ActionBar,
    Button,
    Size
} from "ui-kit";
import { matriculationReport } from "./matriculationSessionStudents";
import { RootState } from "reducers/store";


const ReportWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;

  .left {
    padding-right: ${Spacing.Default}px;
    flex-direction: column;
    width: 0px;
    flex-grow: 1;
    left: 0;
    overflow-y: auto !important;
    width: 13rem;
    max-width: 13rem;
    margin-right: ${Spacing.Default}px;
  }

  .right {
    flex-grow: 1;
  }

  ${formatScrollbars(neutral[200])}
`;


interface IMatriculationReportsProps {
    reports: matriculationReport[];
}


const MatriculationReports: React.FC<IMatriculationReportsProps> = ({ reports }) => {

    const { user } = useSelector((state: RootState) => state.currentUser);
    const { working, error } = useSelector((state: RootState) => state.matriculationSessions.reporting);
    const [selectedReport, setSelectedReport] = useState<matriculationReport>(reports[0]);

    const handleRunReport = () => {
        selectedReport.action();
    }

    return (
        <ReportWrapper>
            <Left>
                <List title="Available Reports">
                { reports.filter((x: matriculationReport) => users.isInAnyRoles(user, x.roles))
                    .map((report: matriculationReport, index: number) => (
                        <List.Item
                            text={report.name}
                            key={index}
                            selected={selectedReport.id === report.id}
                            onClick={() => setSelectedReport(report)}
                        />
                    )
                )}
                </List>
            </Left>
            <Right>
                {error && <Message text={error} color={Swatches.Danger} />}
                <Card>
                    <Card.Body>
                        <p>{selectedReport.description}</p>
                    </Card.Body>
                    <Card.Footer>
                    <ActionBar low>
                        <Button
                            text="Run Report"
                            color={Swatches.Success}
                            size={Size.Small}
                            onClick={handleRunReport}
                            working={working}
                        />
                    </ActionBar>
                </Card.Footer>
                </Card>
            </Right>
        </ReportWrapper>
    )
}


export default MatriculationReports;