import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import FileDownload from "js-file-download";
import { urls } from "../../../../utils";
import { callbackType } from "types/common/action.types";
import { StudentBehaviourReportByTimeView, StudentBehaviourReportView } from "areas/planner/types/plannerReportResponse.types";

const builder = new actionTypeBuilder("studentbehaviours");

const types = {
  GETSTUDENTBEHAVIOURS: builder.build("getstudentbehaviours"),
  GETSTUDENTBEHAVIOURSBYTIME: builder.build("getstudentbehavioursbytime"),
  DOWNLOADSTUDENTBEHAVIOURS: builder.build("downloadstudentbehaviours"),
};


const getStudentBehaviourReport = (
  studentId: string,
  startDate?: string,
  endDate?: string,
  categoryType?: number,
  academicYearId?: number,
  codeId?: number,
  tutorGroupId?: number,
  classGroupId?: number,
  onSuccess?: callbackType<StudentBehaviourReportView>
) => {
  const url = new urls.QueryString(
    `planner/reporting/students/${studentId}/behaviourReport`
  );

  url.addParams({
    startDate: startDate,
    endDate: endDate,
    categoryType: categoryType,
    codeId: codeId,
    tutorGroupId: tutorGroupId,
    classGroupId: classGroupId,
    academicYearId: academicYearId,
  });

  return action<StudentBehaviourReportView>(
    () => client.get(url.toUrl()),
    types.GETSTUDENTBEHAVIOURS,
    onSuccess
  );
};


const getStudentBehaviourReportByTime = (
  studentId: string,
  startDate?: string,
  endDate?: string,
  categoryType?: number,
  codeId?: number,
  tutorGroupId?: number,
  classGroupId?: number,
  timeInterval?: number,
  onSuccess?: callbackType<StudentBehaviourReportByTimeView>
) => {

  return action<StudentBehaviourReportByTimeView>(
    () =>
      client.get(
        `planner/reporting/students/${studentId}/studentBehaviourReportByTime?StartDate=${startDate}&EndDate=${endDate}&categoryType=${categoryType}&codeId=${codeId}&tutorGroupId=${tutorGroupId}&classGroupId=${classGroupId}&timeInterval=${timeInterval}`
      ),
    types.GETSTUDENTBEHAVIOURSBYTIME,
    onSuccess
  );
};


const downloadStudentBehaviourReport = ( 
  studentId: string,
  startDate?: string,
  endDate?: string,
  categoryType?: number,
  academicYearId?: number,
  codeId?: number,
  tutorGroupId?: number,
  classGroupId?: number,
  onSuccess?: callbackType<Blob>
) => {
  const url = new urls.QueryString(
    `planner/reporting/students/${studentId}/behaviourReport/download`
  );

  url.addParams({
    startDate: startDate,
    endDate: endDate,
    categoryType: categoryType,
    codeId: codeId,
    tutorGroupId: tutorGroupId,
    classGroupId: classGroupId,
    academicYearId: academicYearId,
  });

  return action<Blob>(
    () => client.get(url.toUrl(), { responseType: "blob" }),
    types.DOWNLOADSTUDENTBEHAVIOURS,
    (responseData, responseHeaders) => {
      const contentDisposition = responseHeaders["content-disposition"];
      var filename = contentDisposition.split("=");
      FileDownload(responseData, filename[1]);
      onSuccess && onSuccess(responseData);
    }
  );
};


const studentBehaviourReportActions = {
  types,
  getStudentBehaviourReport,
  getStudentBehaviourReportByTime,
  downloadStudentBehaviourReport
};

export default studentBehaviourReportActions;
