import { CSSObject, FlattenSimpleInterpolation, SimpleInterpolation, css } from "styled-components";

interface BreakpointDefs {
  [key: string]: number;
}

export const Breakpoints: BreakpointDefs = {
  max: 1900,
  xl: 1400,
  l: 1200,
  m: 1024,
  s: 960,
  xs: 768,
  xxs: 480,
  min: 375,
};

interface respondToDef {
  [key: string]: (first: TemplateStringsArray | CSSObject, ...interpolations: SimpleInterpolation[]) => FlattenSimpleInterpolation;
}

export const respondTo = Object.keys(Breakpoints).reduce(
  (accumulator: respondToDef, label: string) => {
    accumulator[label] = (first, ...interpolations) => css`
      @media (min-width: ${Breakpoints[label]}px) {
        ${css(first, ...interpolations)};
      }
    `;
    return accumulator;
  },
  {}
)

var methods = { Breakpoints, respondTo };
export default methods;