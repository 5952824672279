import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Dropdown } from "ui-kit";
import searchEngineActions from "areas/search/actions/searchEngineActions";
import { RootState } from "reducers/store";
import { SearchResultView, SearchResultViewBase } from "types/common/views.types";
import { UserType } from "configuration/constants.enums";
import { arrays } from "utils";
import { GroupType } from "types/planner/groups.types";
import { UserListView } from "types/users/userListViews.types";

const Wrapper = styled.div``;


interface IUserAndGroupsSearchProps {
  userTypes?: UserType[];
  groupTypes?: GroupType[];
  onChange?: (value: SearchResultView<UserListView | null>, label: string) => void;
  fluid?: boolean;
  excludeSelf?: boolean;
  schools?: number[];
  style?: object;
}

const UserAndGroupsSearch: React.FC<IUserAndGroupsSearchProps> = ({ userTypes, groupTypes, onChange, fluid, excludeSelf, schools, style }) => {

  const { user: currentUser } = useSelector((state: RootState) => state.currentUser);

  const { users, loading: loadingUsers, error: usersError } = useSelector((state: RootState) => state.searchUsers);
  const { staff, loading: loadingStaff, error: staffError } = useSelector((state: RootState) => state.searchStaff);
  const { students, loading: loadingStudents, error: studentsError } = useSelector((state: RootState) => state.searchStudents);

  const { groups, loading: loadingGroups, error: groupsError } = useSelector((state: RootState) => state.searchGroups);
  const { groups: classGroups, loading: loadingClassGroups, error: classGroupsError } = useSelector((state: RootState) => state.searchClassGroups);
  const { groups: tutorGroups, loading: loadingTutorGroups, error: tutorGroupsError } = useSelector((state: RootState) => state.searchTutorGroups);
  const { groups: customGroups, loading: loadingCustomGroups, error: customGroupsError } = useSelector((state: RootState) => state.searchCustomGroups);

  useEffect(() => {
    searchEngineActions.clearAllResults();
  }, [])

  const handleUsersSearch = (searchTerm: string) => {
    if (userTypes.some(t => t === UserType.STAFF)) {
      searchEngineActions.searchStaff(searchTerm, null, schools);
    }
    if (userTypes.some(t => t === UserType.STUDENT)) {
      searchEngineActions.searchStudents(searchTerm, { schools: schools });
    }
    if (userTypes.some(t => t === UserType.PARENT)) {
      //No existing action
    }
    // if (arrays.isEmpty(userTypes)) {
    //   searchEngineActions.searchUsers(searchTerm, schools);
    // }
  }

  const handleGroupsSearch = (searchTerm: string) => {
    if (groupTypes.some(t => t === GroupType.ClassGroup)) {
      searchEngineActions.searchClassGroups(searchTerm, null, null, null, schools);
    }
    if (groupTypes.some(t => t === GroupType.Subject)) {
      //No existing reducer
    }
    if (groupTypes.some(t => t === GroupType.TutorGroup)) {
      searchEngineActions.searchTutorGroups(searchTerm, null, schools);
    }
    if (groupTypes.some(t => t === GroupType.YearGroup)) {
      //No existing action
    }
    if (groupTypes.some(t => t === GroupType.House)) {
      //No existing action
    }
    if (groupTypes.some(t => t === GroupType.Custom)) {
      searchEngineActions.searchCustomGroups(searchTerm, null, null, null, schools);
    }
    // if (arrays.isEmpty(groupTypes)) {
    //   searchEngineActions.searchGroups(searchTerm, null, schools);
    // }
  }

  const handleSearch = (searchTerm: string) => {
    if (searchTerm && searchTerm.length > 2) {
      handleUsersSearch(searchTerm);
      handleGroupsSearch(searchTerm);
    }
  };

  const getResults = () : SearchResultView<UserListView | null>[] => {
    return [
      ...users || [],
      ...staff?.items || [],
      ...students || [],
      ...groups?.items || [],
      ...classGroups || [],
      ...tutorGroups || [],
      ...customGroups || []
    ];
  }

  const isLoading = () => {
    return loadingUsers
    || loadingStaff
    || loadingStudents
    || loadingGroups
    || loadingClassGroups
    || loadingTutorGroups
    || loadingCustomGroups
  }

  const getErrors = () => {
    return usersError
    || staffError
    || studentsError
    || groupsError
    || classGroupsError
    || tutorGroupsError
    || customGroupsError
  }

  const handleResultChange = (value: SearchResultView<UserListView | null>, label: string) => {
    onChange?.(value, label);
  }

  return (
    <Wrapper className="student-picker" style={style}>
      <Dropdown
        items={getResults()?.map((result: SearchResultView<UserListView | null>, index: number) =>
          excludeSelf && result.id == currentUser.id
            ? null
            : {
                key: index,
                label: `${result.title}`,
                value: result,
                sub: result.school?.name,
              }
        )}
        autocomplete
        placeholder="Search users and groups..."
        searchPlaceholder="Search users and groups..."
        onSearchChange={handleSearch}
        onChange={handleResultChange}
        loading={isLoading()}
        error={getErrors() && "An error occurred"}
        fluid={fluid}
      />
    </Wrapper>
  );
};

export default UserAndGroupsSearch;
