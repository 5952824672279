import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { files, users } from "utils";
import Moment from "react-moment";
import styled from "styled-components";
import {
  Tabs,
  Table,
  Chip,
  Button,
  Size,
  EmptyMessage,
  Swatches,
  Card,
  StructuredList,
  Icon,
  neutral,
  Spacing,
  fontStyle,
  displayFont,
  typescale,
} from "ui-kit";
import { FilePicker } from "sharedComponents/common";
import { Constants } from "configuration";
import { arrays } from "utils";
import MatriculationReports from "./matriculationReports";
import Pagination from "./pagination";
import matriculationActions from "../actions/matriculationActions";
import { RootState } from "reducers/store";
import { PagedQueryView } from "types/common/views.types";
import { PreadmissionListView } from "../types/matriculationResponses.types";
import { matriculationReport } from "./matriculationSessionStudents";
import { ImportResultView } from "types/common/actionResult.types";
import { ImportPreadmissionStudentsCommand } from "../types/matriculationRequests.types";
import { FileListView, IAcceptedFileTypes, IFileMetadata } from "types/common/files.types";


const ResultsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: ${Spacing.Large}px;
`;

const Result = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-right: 100px;
`;

const ResultIcon = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const ResultText = styled.p`
  ${fontStyle(displayFont.medium, typescale.header1, neutral[500])}
  padding: 0px ${Spacing.Small}px;
`;


const MatriculationSessionPreadmissions = () => {

  const { schoolId, sessionId } = useParams();
  const { sessionPreadmissions, loading, error } = useSelector((state: RootState) => state.matriculationSessions);
  const { user } = useSelector((state: RootState) => state.currentUser);

  const loadPreadmissions = (pageIndex: number) => {
    matriculationActions.getSessionPreadmissions(
      parseInt(schoolId),
      parseInt(sessionId),
      pageIndex
    );
  };

  useEffect(() => {
    loadPreadmissions(0);
  }, []);

  const onPage = (pageIndex: number) => {
    loadPreadmissions(pageIndex);
  };

  const reports: matriculationReport[] = [
    {
      id: 0,
      name: "Download MIDAS Data",
      description: "Export the MIDAS data for preadmitted students for this matriculation session",
      action: () =>
        matriculationActions.runMidasReport(parseInt(schoolId), parseInt(sessionId), reportData =>
          files.openCsv(reportData)
        ),
      roles: [
        Constants.ROLES.MATRICULATION_ADMINISTRATOR,
        Constants.ROLES.MATRICULATION_USER,
        Constants.ROLES.IT_ADMINISTRATOR,
        Constants.ROLES.IT_USER,
      ],
    },
  ];

  return (
    <Tabs>
      <Tabs.Pane 
        name="preadmissions" 
        label="Preadmissions"
      >
        <PreadmissionsTable
          preadmissions={sessionPreadmissions}
          loading={loading}
          error={error}
        />
        <Pagination
          paging={sessionPreadmissions?.paging}
          onPageClick={onPage}
        />
      </Tabs.Pane>
      <Tabs.Pane 
        name="reporting" 
        label="Reporting"
        hide={!users.isInRole(user, Constants.ROLES.IT_ADMINISTRATOR)}
      >
        <MatriculationReports reports={reports} />
      </Tabs.Pane>
      <Tabs.Pane
        name="upload"
        label="Upload XML File"
        hide={!users.isInRole(user, Constants.ROLES.IT_ADMINISTRATOR)}
      >
        <PreadmissionUpload schoolId={parseInt(schoolId)} sessionId={parseInt(sessionId)} />
      </Tabs.Pane>
    </Tabs>
  );
};


interface IPreadmissionsTableProps {
  preadmissions: PagedQueryView<PreadmissionListView>;
  loading?: boolean;
  error?: string;
}


const PreadmissionsTable: React.FC<IPreadmissionsTableProps> = ({ preadmissions, loading, error }) => {

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const openCameraApp = (preadmission: PreadmissionListView) => {
    navigate(`${pathname}/camera/${preadmission.id}`);
  };

  return (
    <>
      <Table
        zebra
        grow
        loading={loading}
        error={error != null}
        empty={arrays.isEmpty(preadmissions?.items)}
        emptyMessage={
          <EmptyMessage
            icon="calendar"
            title="No preadmissions for this session found"
            cover
          />
        }
      >
        <Table.Header>
          <Table.HeaderCell width={1}>Photographed</Table.HeaderCell>
          <Table.HeaderCell width={1}>Admission Number</Table.HeaderCell>
          <Table.HeaderCell width={1}>First Name</Table.HeaderCell>
          <Table.HeaderCell width={1}>Last Name</Table.HeaderCell>
          <Table.HeaderCell width={1}>Date of Birth</Table.HeaderCell>
          <Table.HeaderCell width={1} right />
        </Table.Header>

        <Table.Body>
          {preadmissions?.items?.map((preadmission: PreadmissionListView, index: number) => (
            <Table.Row key={index}>
              <Table.Cell width={1}>
                {preadmission.photographedDate ? (
                  <Chip text="Processed" colorSwatch={Swatches.Success} />
                ) : (
                  <Chip text="Unprocessed" colorSwatch={Swatches.Low} />
                )}
              </Table.Cell>
              <Table.Cell width={1}>{preadmission.admissionNumber}</Table.Cell>
              <Table.Cell width={1}>{preadmission.firstName}</Table.Cell>
              <Table.Cell width={1}>{preadmission.lastName}</Table.Cell>
              <Table.Cell width={1}>
                <Moment format="DD/MM/YYYY" date={preadmission.dateOfBirth} />
              </Table.Cell>
              <Table.Cell width={1} right>
                <Button
                  onClick={() => openCameraApp(preadmission)}
                  size={Size.Small}
                  color={Swatches.Low}
                  text="Open Camera"
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};


interface IPreadmissionUploadProps {
  schoolId: number;
  sessionId: number;
}


const PreadmissionUpload: React.FC<IPreadmissionUploadProps> = ({ schoolId, sessionId }) => {

  const [fileReset, setFileReset] = useState<FileListView[] | null>(null);
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [importResult, setImportResult] = useState<ImportResultView | null>(null);

  const uploadFile = () => {
    setImportResult(null);
    var data: ImportPreadmissionStudentsCommand = {
      schoolId: schoolId,
      sessionId: sessionId,
      importData: fileContent,
    };
    fileContent &&
      matriculationActions.uploadPreadmissionXmlFile(
        schoolId,
        sessionId,
        data,
        (importResult: ImportResultView) => {
          setFileReset([]);
          setImportResult(importResult);
        }
      );
  };

  const handleFileChange = (fileContent: string | (FileListView | IFileMetadata)[] | ArrayBuffer) => {
    setFileContent(fileContent as string);
  };

  const acceptedTypes: IAcceptedFileTypes[] = [
    { mimeType: "application/xml", fileExtension: ".xml" },
    { mimeType: "text/xml", fileExtension: ".xml" },
  ];

  return (
    <Card>
      <Card.Body>
        <StructuredList>
          <StructuredList.Item name="Upload XML File">
            <FilePicker
              files={fileReset}
              onFilesChanged={handleFileChange}
              importFile
              single
              fileTypes={acceptedTypes}
            />
            <Button
              onClick={uploadFile}
              size={Size.Small}
              color={Swatches.Primary}
              text="Upload File"
            />
            {importResult && (
              <ResultsWrapper>
                <Result>
                  <ResultIcon>
                    <Icon
                      value={"users"}
                      color={neutral[500]}
                      size={Size.ExtraLarge}
                    />
                    <ResultText>
                      {importResult.imported + importResult.failed}
                    </ResultText>
                  </ResultIcon>
                  <p>Total Students</p>
                </Result>
                <Result>
                  <ResultIcon>
                    <Icon
                      value={"users"}
                      color={neutral[500]}
                      size={Size.ExtraLarge}
                    />
                    <ResultText>{importResult.imported}</ResultText>
                  </ResultIcon>
                  <p>Imported</p>
                </Result>
                <Result>
                  <ResultIcon>
                    <Icon
                      value={"users"}
                      color={neutral[500]}
                      size={Size.ExtraLarge}
                    />
                    <ResultText>{importResult.failed}</ResultText>
                  </ResultIcon>
                  <p>Failed</p>
                </Result>
              </ResultsWrapper>
            )}
          </StructuredList.Item>
        </StructuredList>
      </Card.Body>
    </Card>
  );
};

export default MatriculationSessionPreadmissions;
