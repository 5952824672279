
export enum GroupBy {
  Teacher = 0,
  Pupil = 1,
  PupilGender = 2,
  YearGroup = 3,
  TutorGroup = 4,
  Subject = 5,
  EAL = 6,
  PP = 7,
  Room = 8,
  BehaviourType = 9,
  BehaviourCode = 10,
  Outcome = 11,
  AwardedDate = 12,
  SEN = 13,
  DetentionType = 14,
  ProvisionGroup = 15
}

export enum OrderBy {
  Name = 0,
  RewardPoints = 1,
  BehaviourPoints = 2,
  BehaviourCounts = 3,

}

export enum OrderByOption {
  Ascending = 0,
  Descending = 1
}

export enum BehaviourIncludeType {
  Behaviour = 0,
  Reward = 1,
  BehaviourReward = 2
}