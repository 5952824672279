import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Subtitle } from "ui-kit";
import schoolActions from "areas/planner/actions/school/schoolActions";
import { LAYOUT_KEYS } from "areas/planner/constants/classViewer/classViewerLayout";
import classViewerActions from "areas/planner/actions/classViewer/classViewerActions";
import { addGroupIdToPayload } from "areas/planner/utils/group";
import ClassLayoutCreator from "./classLayoutCreator";

const ClassLayoutCreatorWrapper = ({ group, closeLayoutCreator, children }) => {
  const { rooms } = useSelector(state => state.schoolRooms);

  useEffect(() => {
    if (
      !rooms ||
      rooms.length === 0 ||
      rooms[0]?.schoolId !== group.school.id
    ) {
      schoolActions.getSchoolRooms(group.school.id);
    }
  }, [group]);

  const handleCreateClassLayout = (
    layoutName,
    selectedLayoutType,
    orderValue,
    disperseValue,
    makeDefault,
    selectedRoomLayoutId
  ) => {
    const newLayoutData = {
      id: null,
      name: layoutName,
      roomLayoutId: selectedRoomLayoutId,
      layoutFilter:
        selectedLayoutType !== LAYOUT_KEYS.NONE
          ? {
              layouttype: selectedLayoutType,
              order: orderValue,
              disperse: disperseValue,
            }
          : null,
      isDefault: makeDefault,
      saveStudentPositions: [],
    };

    classViewerActions.createClassLayout(
      addGroupIdToPayload(newLayoutData, group.id, group.type)
    );
  };

  return (
    <>
      <Subtitle text="Create a New Seating Plan" />

      <ClassLayoutCreator
        group={group}
        schoolRooms={rooms}
        closeLayoutCreator={closeLayoutCreator}
        handleCreateClassLayout={handleCreateClassLayout}
      >
        {children}
      </ClassLayoutCreator>
    </>
  );
};

ClassLayoutCreatorWrapper.propTypes = {
  classGroup: PropTypes.object,
  closeLayoutCreator: PropTypes.func,
  children: PropTypes.node,
};

export default ClassLayoutCreatorWrapper;
