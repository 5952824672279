import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import BehaviourDetailedReportForm from "./behaviourDetailedReportForm";
import BehaviourDetailedReportTable from "./behaviourDetailedReportTable";
import ReportDescription from "../../reportDescription";
import { Title, TitleSize } from "ui-kit";
import behaviourAnalyticsActions from "areas/analytics/actions/behaviourAnalyticsActions";
import { Constants } from "configuration";
import { useAppSelector } from "reducers/hooks";
import { BehaviourRewardReportFilter } from "areas/analytics/types/behaviourAnalyticsRequest.types";
import moment from "moment";


export interface BehaviourRewardReportFilterExt extends Omit<BehaviourRewardReportFilter, "startDate" | "endDate"> {
  startDate: Date;
  endDate: Date;
}


const BehaviourDetailedReportWrapper: React.FC = () => {

  const reportRef = useRef<HTMLDivElement>();

  const { user } = useAppSelector(state => state.currentUser);
  const { report, paging, loading, loadingExportReport } = useAppSelector(
    state => state.behaviourDetailedReport
  );

  const [_loading, _setLoading] = useState<boolean>(false);
  const [reportCriteria, setReportCriteria] = useState<BehaviourRewardReportFilterExt>({
    schoolId: 0,
    startDate: null,
    endDate: null,
    categoryTypeId: 0,
    academicYearId: Constants.ACADEMIC_YEAR_FILTER_ARRAY[0].value,
  });

  useEffect(() => {
    if (user?.baseSchool) {
      setReportCriteria({ ...reportCriteria, schoolId: user.baseSchool.id });
    }
  }, []);

  useEffect(() => {
    if (loading || report) {
      reportRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading, report]);

  const handleReportCriteriaChange = (criteria: BehaviourRewardReportFilterExt) => {
    setReportCriteria(criteria);
  };

  const handleGenerateReport = (exportReport: boolean) => {
    if (!exportReport) {
      _setLoading(true);
    }
    behaviourAnalyticsActions
      .getBehaviourDetailedReport(
        {
          ...reportCriteria,
          startDate: moment(reportCriteria.startDate).format("YYYY-MM-DD"),
          endDate: moment(reportCriteria.endDate).format("YYYY-MM-DD"),
        },
        0,
        exportReport,
        () => _setLoading(false)
      )
      .catch(() => _setLoading(false));
  };

  const handlePage = () => {
    behaviourAnalyticsActions.getBehaviourDetailedReport(
      {
        ...reportCriteria,
        startDate: moment(reportCriteria.startDate).format("YYYY-MM-DD"),
        endDate: moment(reportCriteria.endDate).format("YYYY-MM-DD"),
      },
      paging.pageIndex + 1,
      false
    );
  };

  return (
    <>
      <Title text="Behaviours and Rewards Report" size={TitleSize.H3} />
      <ReportDescription text="Use this report to view detailed behaviour or reward information." />
      <BehaviourDetailedReportForm
        schoolId={reportCriteria.schoolId}
        onSelectSchool={value => setReportCriteria({ ...reportCriteria, schoolId: value })}
        reportCriteria={reportCriteria}
        onChangeReportCriteria={handleReportCriteriaChange}
        handleGenerateReport={handleGenerateReport}
        loadingExportReport={loadingExportReport}
      />
      <div ref={reportRef}>
        <BehaviourDetailedReportTable
          handlePage={handlePage}
          loading={_loading}
        />
      </div>
    </>
  );
};

export default BehaviourDetailedReportWrapper;
