import React, { useState, useEffect } from "react";
import { useReview } from "./reviewContext";
import { Constants } from "configuration";
import {
  StructuredList,
  RichTextEditor,
  HtmlText,
  Text,
  RadioList,
  Card
} from "ui-kit";
import { arrays } from "utils";
import { TargetView } from "areas/humanResources/types/managedStaffResponse.types";


interface ITargetEditorProps {
  target: TargetView;
}


const TargetEditor: React.FC<ITargetEditorProps> = ({ target }) => {

  const [_target, _setTarget] = useState<TargetView| null>(null);
  const { state, dispatch } = useReview();

  useEffect(() => {
    target && _setTarget(target);
  }, []);

  useEffect(() => {
    _target && dispatch({ type: "updateTarget", payload: _target });
  }, [_target]);

  const getEditor = (value: string, onChange: (value: string) => void, editable: boolean, emptyMessage?: string) => {
    if (editable) {
      return <RichTextEditor initialValue={value} onChange={onChange} />;
    } else {
      return value ? <HtmlText html={value} /> : <Text>{emptyMessage}</Text>;
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <StructuredList.Item
            name="Details"
            description="Please enter details of the target. The target should be clear, concise and focused and give specific objectives. It should also be realistically achievable before the end of the academic year. Remember that targets should be aligned to the School Development Plan, improve the performance of the department and provide the opportunity for personal development."
          >
            {getEditor(
              _target?.description,
              value => _setTarget({ ..._target, description: value }),
              state.isLineManager,
              "No target description has been added"
            )}
          </StructuredList.Item>
          <StructuredList.Item
            name="Success Criteria"
            description={`Please enter the criteria the target will be judged against. These should be clear, quantifiable and not unnecessarily subjective.  This section helps staff understand how the objective will be achieved - for example, what tasks will need to be completed to achieve the objective?`}
          >
            {getEditor(
              _target?.successCriteria,
              value => _setTarget({ ..._target, successCriteria: value }),
              state.isLineManager,
              "No success criteria have been added"
            )}
          </StructuredList.Item>
          <StructuredList.Item
            name="Training Requirements"
            description="If any training or help is needed for the target to be achieved, please include it here. Training should be specific to the target; general training requirements can be added during the CPD & Training step later on. Remember that training can be learning from a colleague, sourcing free courses or materials through to more formal training including apprenticeship training."
          >
            {getEditor(
              _target?.trainingRequirements,
              value => _setTarget({ ..._target, trainingRequirements: value }),
              state.isLineManager,
              "No training requirements have been added"
            )}
          </StructuredList.Item>

          {state.isReviewee ? (
            <StructuredList.Item
              name="Evidence"
              description="Please include evidence that the target has been met. If success criteria have been specified then the evidence should relate to these."
            >
              {getEditor(
                _target?.evidence,
                value => _setTarget({ ..._target, evidence: value }),
                state.isReviewee
              )}
            </StructuredList.Item>
          ) : (
            <>
              <StructuredList.Item
                name="Evidence"
                description={`This is the reviewee's evidence that the target has been met.`}
              >
                {_target?.evidence == null ? (
                  <Text>
                    {`${state.review.reviewee.firstName} has not added any evidence
              yet.`}
                  </Text>
                ) : (
                  <HtmlText html={_target?.evidence} />
                )}
              </StructuredList.Item>
            </>
          )}

          <StructuredList.Item
            name="Progress"
            description={`This is the current status of the progress made towards achieving the target.`}
          >
            <RadioList
              fluid
              disabled={state.isReviewee}
              value={_target?.progressStatus}
              onChange={value =>
                _setTarget({ ..._target, progressStatus: (value as number) })
              }
              items={arrays.configConstantsToArray(Constants.PROGRESS_STATUS)}
            />
          </StructuredList.Item>
          <StructuredList.Item
            name="Progress Notes"
            description={`These are interim notes about the progress made towards achieving the target.`}
          >
            {getEditor(
              _target?.progressNotes,
              value => _setTarget({ ..._target, progressNotes: value }),
              state.isLineManager,
              "No progress notes have been added"
            )}
          </StructuredList.Item>
          <StructuredList.Item
            name="Target Completion"
            description={`This indicates whether the target has been met.`}
          >
            <RadioList
              fluid
              disabled={state.isReviewee}
              value={_target?.status}
              onChange={value => _setTarget({ ..._target, status: (value as number) })}
              items={arrays.configConstantsToArray(Constants.TARGET_STATUS)}
            />
          </StructuredList.Item>
        </Card.Body>
      </Card>
    </>
  );
};

export default TargetEditor;
