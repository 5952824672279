import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import { SchoolBehaviourCodeListView } from "areas/behaviour/types/behaviourResponses.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";

// Includes legacy codes for reporting - seperate state as we dont want to display legacy codes when giving new behaviours
interface IAllBehaviourCodesState {
  allBehaviourCodes: SchoolBehaviourCodeListView[];
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IAllBehaviourCodesState = {
  allBehaviourCodes: [],
  loading: false,
  error: null,
};

const { GETALLBEHAVIOURCODES } = behaviourActions.types;


const allBehaviourCodesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAllBehaviourCodesState => {

  switch (action.type) {
    case GETALLBEHAVIOURCODES.START:
      return { ...state, loading: true, error: null };

    case GETALLBEHAVIOURCODES.SUCCESS:
      return { ...state, loading: false, allBehaviourCodes: action.payload };

    case GETALLBEHAVIOURCODES.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default allBehaviourCodesReducer;
