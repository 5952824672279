import React from "react";
import styled from "styled-components";
import { typescale, textFont, neutral, fontStyle } from "../index";

export interface ITextProps {
  children: React.ReactNode;
  className?: string;
}

const Wrapper = styled.span`
  ${fontStyle(textFont.roman, typescale.paragraph, neutral[500])}

  b,
  em,
  strong {
    font-weight: ${textFont.bold.weight};
  }
`;

const Text: React.FC<ITextProps> = ({ children, className }) => {
  return (
    <Wrapper className={`text ${className ? className : ""}`}>
      {children}
    </Wrapper>
  );
};

export default Text;
