import declarationsOfInterestActions from "areas/forms/actions/declarations/declarationsOfInterestActions";
import { DeclarationOfInterestListView, DeclarationOfInterestVersionDetailView } from "areas/forms/types/declarations/declarationsOfInterestResponse.types";
import { DeclarationType, DeclarationVersionStatus } from "areas/forms/types/declarations/declarationsOfInterestShared.types";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAppSelector } from "reducers/hooks";
import styled from "styled-components";
import { BorderRadius, Card, Chip, DetailLabel, EmptyMessage, HtmlText, Label, Modal, Spacing, Speed, StructuredList, Swatches, Table, honeygold, neutral } from "ui-kit";
import FileList from "sharedComponents/common/files/fileList";


const OuterWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: ${Spacing.Medium}px;

  
  .left-panel {
    flex-basis: 20%;
  }

  .right-panel {
    display: flex;
    flex-basis: 80%;
    padding-left: ${Spacing.Large}px;
  }
`;

const ListItemWrapper = styled.li<{ selected?: boolean }>`
  display: flex;
  padding: ${Spacing.Large}px;
  border-radius: ${BorderRadius.Default}px;
  background: ${neutral[100]};
  margin-bottom: ${Spacing.Default}px;
  border-left: 4px solid ${neutral[300]};
  align-items: center;
  justify-content: space-between;

  &:hover {
    transition: all ease ${Speed.Default};
    cursor: pointer;
    background: ${neutral[300]};
  }

  ${({ selected }) => selected && `
    border-left-color: ${honeygold};
    background: ${neutral[300]};
    color: ${neutral[100]} !important;
    &:hover {
        cursor: default;
    }
  `}
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
`;


interface IDeclarationsAdminModalProps {
  declaration: DeclarationOfInterestListView;
  open: boolean;
  onClose?: () => void;
}


const DeclarationsAdminModal: React.FC<IDeclarationsAdminModalProps> = ({ declaration, open, onClose }) => {

  const { user } = useAppSelector(state => state.currentUser);
  const { declaration: declarationDetails, loading, error } = useAppSelector(state => state.adminDeclaration);

  const [_open, _setOpen] = useState<boolean>(false);
  const [selectedVersion, setSelectedVersion] = useState<DeclarationOfInterestVersionDetailView>();
  
  useEffect(() => {
    _setOpen(open);
  }, [open]);
  
  useEffect(() => {
    declaration && declarationsOfInterestActions.getDeclarationOfInterestForAdmin(declaration.staffMember.id, declaration.id);
  }, [declaration]);

  useEffect(() => {
    if (declarationDetails && declarationDetails.staffMember.id === declaration?.staffMember?.id) {
      setSelectedVersion(declarationDetails.versions[0]);
    }
  }, [declarationDetails]);

  const getDeclarationTypeFriendlyName = (declarationType: DeclarationType) => {
    switch (declarationType) {
      case DeclarationType.Employment:
        return "Employment";
      case DeclarationType.AppointmentsAndDirectorships:
        return "Appointments and Directorships";
      case DeclarationType.NonprofitMembership:
        return "Nonprofit Membership";
      case DeclarationType.ShareholdingAndInvestment:
        return "Shareholding and Investment";
      case DeclarationType.TrustConnection:
        return "Trust Connection";
      case DeclarationType.Contractual:
        return "Contractual";
      case DeclarationType.Other:
        return "Other";
    }
  }

  return (
    <>
     <Modal
      title={`${declarationDetails?.staffMember?.firstName} ${declarationDetails?.staffMember?.lastName} Declaration of Interest`}
      open={_open}
      onClose={onClose}
      width="95%"
      height="95%"
      loading={loading}
      empty={declarationDetails == null}
      emptyMessage={
        <EmptyMessage
          icon="paste"
          title="Declaration Not Found"
          summary="The requested Declaration of Interest was not found"
          cover
        />
      }
      error={error?.message}
      errorMessage={
        <EmptyMessage
          icon="times-circle"
          title="A problem occurred"
          summary="There was a problem loading the requested Declaration of Interest"
          cover
        />
      }
    >
      <Modal.Body>

        <OuterWrapper>

          <div className="left-panel">
          { declarationDetails?.versions?.sort((a, b) => b.versionNumber - a.versionNumber)?.map(version => (
            <ListItemWrapper
              onClick={() => setSelectedVersion(version)}
              selected={version.id === selectedVersion?.id}
            >
              <DetailLabel
                label={`Version ${version.versionNumber}`}
                sub={moment(version.updatedDate).format("DD/MM/YYYY")}
                bold
              />
              <Chip
                text={version.statusName}
                colorSwatch={
                  version.status === DeclarationVersionStatus.Draft
                    ? Swatches.Low
                    : Swatches.Success
                }
              />
            </ListItemWrapper>
          ))}
          </div>

          <div className="right-panel">

            {selectedVersion && (
              <ContentWrapper>
                <Card title="Dates">
                  <Card.Body>
                    <StructuredList>
                      <StructuredList.Item name="Created">
                        <Label>{moment(selectedVersion.createdDate).format("DD/MM/YYYY")}</Label>
                      </StructuredList.Item>
                      <StructuredList.Item name="Last Updated ">
                        <Label>{moment(selectedVersion.updatedDate).format("DD/MM/YYYY")}</Label>
                      </StructuredList.Item>
                    </StructuredList>
                  </Card.Body>
                </Card>

                <Card title="Details">
                  <Card.Body>
                    <StructuredList>
                      <StructuredList.Item name="Reference">
                        {selectedVersion.reference}
                      </StructuredList.Item>
                      <StructuredList.Item name="Notes">
                        <HtmlText html={selectedVersion.notes} />
                      </StructuredList.Item>
                      <StructuredList.Item name="Accepted">
                        {selectedVersion.acceptance ? "Yes" : "No"}
                      </StructuredList.Item>
                    </StructuredList>
                  </Card.Body>
                </Card>

                  <Card title="Interests">
                    {selectedVersion.interests.length > 0 ? (
                      <Card.Body noPad>
                        <Table>
                          <Table.Header>
                            <Table.HeaderCell>Declaration Type</Table.HeaderCell>
                            <Table.HeaderCell>Organisation Name</Table.HeaderCell>
                            <Table.HeaderCell>Organisation Nature</Table.HeaderCell>
                            <Table.HeaderCell>Nature of Interest</Table.HeaderCell>
                            <Table.HeaderCell>Start Date</Table.HeaderCell>
                            <Table.HeaderCell>End Date</Table.HeaderCell>
                          </Table.Header>
                          <Table.Body>
                            {selectedVersion.interests.map((interest, index) => (
                              <Table.Row key={index}>
                                <Table.Cell>{getDeclarationTypeFriendlyName(interest.declarationType)}</Table.Cell>
                                <Table.Cell>{interest.organisationName}</Table.Cell>
                                <Table.Cell>{interest.organisationNature}</Table.Cell>
                                <Table.Cell>{interest.natureOfInterest}</Table.Cell>
                                <Table.Cell>{interest.startDate && moment(interest.startDate).format("DD/MM/YYYY")}</Table.Cell>
                                <Table.Cell>{interest.endDate && moment(interest.endDate).format("DD/MM/YYYY")}</Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                      </Card.Body>
                    )
                      : (
                        <Card.Body>
                          <EmptyMessage
                            icon="paste"
                            title="No Interests Added"
                            summary={"You have added no interests on this declaration"}
                            cover />
                        </Card.Body>
                      )}
                  </Card>

                  <Card>
                    <Card.Body>
                      <StructuredList>
                        <StructuredList.Item name="Attachments">
                          <FileList>
                            {selectedVersion.files?.map((file, index) => (
                              <FileList.Item
                                key={index}
                                file={{ ...file, id: file.id }}
                                clickToDownload />
                            ))}
                          </FileList>
                        </StructuredList.Item>
                      </StructuredList>
                    </Card.Body>
                  </Card>

                </ContentWrapper>
            )}

          </div>

        </OuterWrapper>

      </Modal.Body>
    </Modal>
    </>
  )
}


export default DeclarationsAdminModal;