import React, { useState, useEffect } from "react";
import {
  Size,
  Spacing,
  SplitButton,
  Swatches,
  ValidationMessage,
} from "ui-kit";
import { Constants } from "configuration";
import styled from "styled-components";
import { useSelector } from "react-redux";
import flyoutActions from "actions/ui/flyouts";
import groupActions from "../../actions/group/groupActions";
import SensitiveInfoExportButton from "../../../../sharedComponents/common/sensitiveInfoExportButton";
import ConfirmModal from "../../../../sharedComponents/common/confirmModal";
import AssignmentModal from "./assignmentModal";
import { StudentListView } from "types/users/userListViews.types";
import { useAppSelector } from "reducers/hooks";
import { ClassGroupStaffView, GroupType } from "types/users/userGroups.types";
import studentActions from "areas/planner/actions/student/studentActions";
import studentLeavingLessonActions from "areas/planner/actions/student/studentLeavingLessonActions";


const Wrapper = styled.div`
  .split-button .split-button-inner .split-button-default {
    padding: 0 ${Spacing.ExtraLarge}px;
  }
`;


interface IGroupActionButtonProps {
  selectedStudents: StudentListView[];
  handleSelectAllStudents: () => void;
  handleUnselectAllStudents: () => void;
  handleRequestOnCall: () => void;
  handleAddToCustomGroupClick: () => void;
  showAddToCustomGroup?: boolean;
  group: ClassGroupStaffView;
  showPickAStudent?: boolean;
  handlePickAStudent?: () => void;
  loading?: boolean;
}


const GroupActionButton: React.FC<IGroupActionButtonProps> = ({
  selectedStudents,
  handleSelectAllStudents,
  handleUnselectAllStudents,
  handleRequestOnCall,
  handleAddToCustomGroupClick,
  showAddToCustomGroup,
  group,
  showPickAStudent,
  handlePickAStudent,
  loading,
}) => {

  const { schoolInformation } = useAppSelector(state => state.school);

  const [numberOfStudents, setNumberOfStudents] = useState<number>(0);
  const [loadingExportStudents, setLoadingExportStudents] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [assignmentModalOpen, setAssignmentModalOpen] = useState<boolean>(false);
  const [exportStudentsError, setExportStudentsError] = useState(null);

  useEffect(() => {
    setNumberOfStudents(selectedStudents.length);
  }, [selectedStudents]);

  const handleOnClickExport = () => {
    setOpen(true);
  };

  const onSuccess = () => {
    setLoadingExportStudents(false);
    setOpen(false);
  };

  const handleExportStudents = () => {
    setLoadingExportStudents(true);
    let studentIds = null;
    if (selectedStudents.length > 0) {
      studentIds = selectedStudents.map(student => student.id);
    }

    const data = {
      groupId: group.id,
      groupTypeId: group.type,
      studentIds: studentIds,
    };
    groupActions
      .exportGroupStudents(group.id, group.name, data)
      .then(onSuccess)
      .catch(error => setExportStudentsError(error.message));
  };

  return (
    <Wrapper>
      {/* <SensitiveInfoExportButton
        text={
          !numberOfStudents
            ? "Export All Students as CSV"
            : "Export Selected Students as CSV"
        }
        onConfirm={handleExportStudents}
      /> */}

      <SplitButton
        size={Size.Small}
        text="Actions"
        color={Swatches.Primary}
        disabled={loading || loadingExportStudents}
        working={loading || loadingExportStudents}
      >
        <SplitButton.Option
          onClick={handleUnselectAllStudents}
          text="Unselect All Students"
          show={numberOfStudents > 0}
        />

        <SplitButton.Option
          text="Add Reward"
          onClick={() =>
            flyoutActions.openFlyout(Constants.FLYOUTS.MULTIPLE_REWARDS)
          }
          show={
            numberOfStudents > 0 &&
            (group.type !== GroupType.Custom ||
              (group.type === GroupType.Custom && group.isActive))
          }
        />

        <SplitButton.Option
          text="Add Behaviour"
          onClick={() =>
            flyoutActions.openFlyout(Constants.FLYOUTS.MULTIPLE_BEHAVIOURS)
          }
          show={
            numberOfStudents > 0 &&
            (group.type !== GroupType.Custom ||
              (group.type === GroupType.Custom && group.isActive))
              && group?.school?.id !== 99
          }
        />

        <SplitButton.Option
          text="Pick a Student"
          onClick={handlePickAStudent}
          show={
            showPickAStudent &&
            !numberOfStudents &&
            ( group?.type === GroupType.ClassGroup ||
              group?.type === GroupType.Provision ||
              group?.type === GroupType.Custom
            )
          }
        />

        <SplitButton.Option
          text="Create Assignment"
          onClick={() => setAssignmentModalOpen(true)}
          show={
            numberOfStudents > 0 &&
            (group.type !== GroupType.Custom ||
            (group.type === GroupType.Custom && group.isActive))
          }
        />

        <SplitButton.Option
          text="Add to Custom Group"
          onClick={handleAddToCustomGroupClick}
          show={numberOfStudents > 0 && showAddToCustomGroup}
        />

        <SplitButton.Option
          text="Select All Students"
          onClick={handleSelectAllStudents}
          show={!numberOfStudents && group.isActive}
        />

        <SplitButton.Option
          onClick={() =>  {
            studentActions.selectMultiStudent(selectedStudents)
            studentLeavingLessonActions.getLeavingLessonCategories()
            flyoutActions.openFlyout(Constants.FLYOUTS.MULTI_STUDENT_LEAVING_LESSON)
           
          }}
          text="Leaving Lesson"
          show={numberOfStudents > 0 && group.type !== GroupType.Custom && group.isActive }
        />

        <SplitButton.Option
          text={
            !numberOfStudents
              ? "Export Student List"
              : "Export Selected Students List"
          }
          onClick={handleOnClickExport}
          show
        />

        <SplitButton.Option
          text="On Call Assistance"
          onClick={handleRequestOnCall}
          color={Swatches.Danger}
          show={schoolInformation?.enableOnCall && group?.school?.id !== 99}
        />
      </SplitButton>

      <ConfirmModal
        onClose={() => setOpen(false)}
        onConfirm={handleExportStudents}
        openModal={open}
        confirmMsg="You are downloading sensitive information. Please ensure you have taken the appropriate data protection steps."
        loading={loadingExportStudents}
      >
        {exportStudentsError && (
          <ValidationMessage errors={[exportStudentsError]} />
        )}
      </ConfirmModal>
      <AssignmentModal
        open={assignmentModalOpen}
        students={selectedStudents}
        onClose={() => setAssignmentModalOpen(false)}
      />
    </Wrapper>
  );
};

export default GroupActionButton;
