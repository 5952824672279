import React from "react";
import { 
  EmptyMessage, 
  IUiKitBaseProps, 
  Loader, 
  Size 
} from "../index";

interface ILoadingWrapperProps extends IUiKitBaseProps {
  children: any | any[];
  loading: boolean; 
  data?: any; 
  error?: boolean;
  errorMessage?: string;
  emptyMessage?: string;
  emptyTitle?: string;
  emptyIcon?: string;
}

const LoadingWrapper: React.FC<ILoadingWrapperProps> = ({
  children,
  loading,
  data,
  error,
  errorMessage,
  emptyMessage,
  emptyTitle,
  emptyIcon
}) => {
  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary={
          errorMessage
            ? errorMessage
            : "There was a problem while loading the condition"
        }
        cover
      />
    );
  }

  if (!data) {
    return (
      <EmptyMessage
        icon={emptyIcon}
        title={emptyTitle ? emptyTitle : "Not Found"}
        summary={
          emptyMessage ? emptyMessage : "The requested item was not found"
        }
        cover
      />
    );
  }

  return data && children;
};

export default LoadingWrapper;
