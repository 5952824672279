import React from "react";
import styled from "styled-components";
import {
  Label,
  Text,
  Body,
  Left,
  Right,
  neutral,
  textFont,
  Spacing,
  Title,
  TitleSize,
  HelpPopup,
  Sub,
  Breakpoints,
  Swatches,
  fontStyle,
  typescale,
} from "../index";

export interface IStructuredListProps {
  children:
    | React.ReactElement<IStructuredListItemProps>
    | React.ReactElement<IStructuredListItemProps>[];
  values?: IStructuredListItemProps[];
  title?: string;
  orientation?: StructuredListOrientation;
  className?: string;
}

export interface IStructuredListItemProps {
  children?:
    | React.ReactElement
    | React.ReactElement[]
    | string
    | number
    | (React.ReactElement | React.ReactElement[] | string | number)[];
  name?: string | JSX.Element;
  value?: string;
  icon?: string;
  description?: string | React.ReactElement;
  orientation?: StructuredListOrientation;
  rightContent?: React.ReactElement | React.ReactElement[] | string | number;
  required?: boolean;
  helpPopup?: boolean;
  className?: string;
  hide?: boolean;
}

export enum StructuredListOrientation {
  Columns = 0,
  Rows = 1,
}

const ListContainer = styled.div`
  display: flex;
  margin: 0 0 ${Spacing.ExtraLarge}px 0;

  &:last-child {
    margin-bottom: 0;
  }

  .title {
    margin-right: ${Spacing.Default * 6}px;
    width: 5rem;
    min-width: 5rem;
    color: ${neutral[600]};
  }
`;

const ListWrapper = styled.ul<{ orientation?: StructuredListOrientation }>`
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  li:last-child {
    margin-bottom: 0;
  }
`;

const ListItemWrapper = styled.li<{
  orientation?: StructuredListOrientation;
  helpPopup?: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 0;
  padding: ${Spacing.Medium}px 0 ${Spacing.Medium}px 0;
  cursor: ${props => (props.onClick ? "pointer" : "default")};
  ${fontStyle(textFont.roman, typescale.paragraph, neutral[500])};
  border-bottom: 1px solid ${neutral[300]};

  > .left {
    label {
      margin-bottom: ${Spacing.Medium}px;
    }
    .required {
      color: ${Swatches.Danger.swatch};
    }

    .sub {
      display: block;
      line-height: 1rem;
      margin-bottom: ${Spacing.Medium}px;
    }

    .help-popup {
      display: none;
    }
  }

  .body {
    width: 100%;
  }

  > .right {
    margin-left: ${Spacing.Medium}px;
  }

  @media screen and (min-width: ${Breakpoints.xs}px) {
    flex-direction: row;
    > .left {
      margin-right: ${Spacing.Default * 6}px;
      width: 8rem;
      min-width: 8rem;

      .sub {
        margin-top: ${Spacing.Medium}px;
      }

      .help-popup {
        display: inline-block;
        margin: 0 0 0 ${Spacing.ExtraSmall}px;
      }

      ${({ helpPopup }) =>
        helpPopup
          ? `.sub { display: none; }`
          : `.help-popup { display: none; }`}
    }
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .body {
    flex-grow: 1;
  }

  .text {
    display: block;
  }

  .icon {
    margin-top: 0.15rem;
  }
`;

//   ${({ orientation }) =>
//     orientation === StructuredListOrientation.Rows &&
//     `
//  /*flex-direction: column;

//   .body {
//     width: 100%;
//   }*/
//  `}
// `;

const StructuredList: React.FC<IStructuredListProps> & {
  Item: typeof StructuredListItem;
} = ({ children, values, title, orientation, className }) => {
  const renderFromChildren = () => {
    return <ListWrapper orientation={orientation}>{children}</ListWrapper>;
  };

  const renderFromProps = () => {
    return (
      <ListWrapper>
        {values &&
          values.map(({ name, value, orientation }, index) => (
            <StructuredListItem
              name={name}
              value={value}
              orientation={orientation}
            />
          ))}
      </ListWrapper>
    );
  };

  return (
    <ListContainer className={`structured-list ${className ? className : ""}`}>
      {title && <Title size={TitleSize.H4} text={title} />}
      {children ? renderFromChildren() : renderFromProps()}
    </ListContainer>
  );
};

export const StructuredListItem: React.FC<IStructuredListItemProps> = ({
  children,
  name,
  description,
  value,
  orientation,
  rightContent,
  helpPopup,
  required,
  className,
  hide
}) => {
  const renderFromChildren = () => {
    return (
      <ListItemWrapper
        className={`structured-list-item ${className ? className : ""}`}
        orientation={orientation}
        helpPopup={helpPopup}
      >
        <Left>
          <>
            <Label bold>
              <>
                {name} {required && <span className="required">*</span>}
              </>
            </Label>
            {description != null && (
              <>
                <HelpPopup text={description} title={name} />
                <Sub>{description}</Sub>
              </>
            )}
          </>
          {/* {orientation == StructuredListOrientation.Columns &&
              description != null && <Sub>{description}</Sub>} */}
        </Left>
        <Body>{children}</Body>
        {rightContent && <Right>{rightContent}</Right>}
      </ListItemWrapper>
    );
  };

  const renderFromProps = () => {
    return (
      <>
        <ListItemWrapper
          className={`structured-list-item ${className ? className : ""}`}
          orientation={orientation}
        >
          <Left>
            <Label bold>{name}</Label>
            {orientation === StructuredListOrientation.Columns &&
              description != null && <Sub>{description}</Sub>}
          </Left>
          <Body>{value != null && <Text>{value}</Text>}</Body>
        </ListItemWrapper>
      </>
    );
  };

  if (hide) {
    return (
      <></>
    )
  }

  return children ? renderFromChildren() : renderFromProps();
};

StructuredList.Item = StructuredListItem;

export default StructuredList;
