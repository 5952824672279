import { IBeehiveError } from "types/common/errors.types";
import smartTaskActions from "../../actions/smartTasks/smartTaskActions";
import { IBeehiveAction } from "types/common/action.types";
import { SchoolSmartTaskListView } from "areas/behaviour/types/smartTasks/smartTaskResponse.types";


interface ISmartTasksState {
  smartTasks: SchoolSmartTaskListView[];
  loading: boolean;
  refresh: boolean;
  error: IBeehiveError;
  getSmartTasksError: IBeehiveError;
}

const INITIAL_STATE: ISmartTasksState = {
  smartTasks: [],
  loading: false,
  refresh: false,
  error: null,
  getSmartTasksError: null,
};


const smartTasksReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISmartTasksState => {

  const { 
    GETSMARTTASKS, 
    CREATESMARTTASK, 
    UPDATESMARTTASK, 
    DELETESMARTTASK 
  } = smartTaskActions.types;

  switch (action.type) {

    case GETSMARTTASKS.START:
      return { ...state, loading: true, error: null };

    case GETSMARTTASKS.SUCCESS:
      return {
        ...state,
        loading: false,
        refresh: false,
        smartTasks: action.payload,
      };

    case GETSMARTTASKS.FAILED:
      return { ...state, loading: false, error: action.payload };

    case CREATESMARTTASK.SUCCESS:
    case UPDATESMARTTASK.SUCCESS:
    case DELETESMARTTASK.SUCCESS:
      return { ...state, refresh: true };

    default:
      return state;
  }
};

export default smartTasksReducer;
