import { DatePicker, StructuredList } from "ui-kit";
import { ICateringReportProps } from "."
import { SchoolDropdown } from "sharedComponents/common";


const GroupDailyReport: React.FC<ICateringReportProps> = ({ filter, setFilter }) => {

  return (
    <>
      <StructuredList.Item name="Date">
        <DatePicker
          closeOnSelect
          selectedDate={filter?.date}
          onChange={value => setFilter({ ...filter, date: value })}
        />
      </StructuredList.Item>
      <StructuredList.Item name="School">
        <SchoolDropdown
          initialValue={filter?.schoolId}
          onChange={value => setFilter({ ...filter, schoolId: value })}
          filter={x => x.enableLunchCredits}
          fluid
        />
      </StructuredList.Item>      
    </>
  )
}


export default GroupDailyReport;