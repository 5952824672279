import { IBeehiveAction } from "types/common/action.types";
import assignmentEditorActions from "../../actions/assignments/assignmentEditorActions";
import { AssignmentDetailView } from "types/planner/assignments.types";


interface IAssignmentEditorState {
  assignment: AssignmentDetailView;
  loading: boolean;
  editing: boolean;
  error: string;
  saving: boolean;
  cloning: boolean;
}

const INITIAL_STATE: IAssignmentEditorState = {
  assignment: null,
  loading: false,
  editing: false,
  error: null,
  saving: false,
  cloning: false,
};


const assignmentEditorReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAssignmentEditorState => {

  const { NEW, CANCEL, EDIT, CREATE, UPDATE, CLONE } = assignmentEditorActions.types;

  switch (action.type) {
    case NEW:
      return { ...state, editing: true, error: null, assignment: null };
    case CANCEL:
      return { ...state, editing: false, error: null, assignment: null };
    case EDIT.START:
      return { ...state, editing: true, error: null, loading: true };

    case EDIT.SUCCESS:
      return {
        ...state,
        assignment: null,
        loading: false,
      };
    case EDIT.FAILED:
      return {
        ...state,
        editing: false,
        loading: false,
        error: action.payload,
      };

    case CREATE.START:
      return { ...state, saving: true, error: null };

    case CREATE.SUCCESS:
      return {
        ...state,
        saving: false,
        assignment: null,
        editing: false,
      };

    case CREATE.FAILED:
      return {
        ...state,
        saving: false,
        error: "An error occurred while creating the assignment",
      };

    case UPDATE.START:
      return { ...state, saving: true, error: null };

    case UPDATE.SUCCESS:
      return { ...state, saving: false, editing: false, assignment: null };

    case UPDATE.FAILED:
      return {
        ...state,
        saving: false,
        error: "An error occured while saving the assignment",
      };

    case CLONE.START:
      return { 
        ...state, 
        cloning: true,
        error: null 
      };

    case CLONE.SUCCESS:
      return { 
        ...state, 
        cloning: false
      };

    case CLONE.FAILED:
      return { 
        ...state, 
        cloning: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default assignmentEditorReducer;
