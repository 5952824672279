import { useState, useEffect } from "react";
import React from "react";

export interface IStatusItemProps {
  children?: React.ReactElement | React.ReactElement[];
  inverse?: boolean;
  status?: any | any[];
}

export interface IStatusViewProps {
  children?: React.ReactElement<IStatusItemProps> | React.ReactElement<IStatusItemProps>[];
  status: any;
}

const StatusView: React.FC<IStatusViewProps> & {
  Item: typeof StatusViewItem;
} = ({ children, status }) => {

  const [_children, _setChildren] = useState<React.ReactElement<IStatusItemProps>[]>([]);

  useEffect(() => {
    if (!children) return;

    Array.isArray(children) ? _setChildren(children) : _setChildren([children]);
  }, [children]);

  return (
    <>
      {_children
        .filter(child => {
          const { inverse, status: childStatus } = child.props;
          return Array.isArray(childStatus)
            ? (!inverse && childStatus.includes(status)) ||
                (inverse && !childStatus.includes(status))
            : (!inverse && childStatus == status) ||
                (inverse && childStatus != status);
        })
        .map((child, index) => {
          return React.cloneElement(child, {
            key: index,
          });
        })}
    </>
  );
};

const StatusViewItem: React.FC<IStatusItemProps> = ({ children }) => {
  if (children) {
    return (
      <>{children}</>
    )
  } else {
    return (
      <></>
    )
  };
};

StatusView.Item = StatusViewItem;

export default StatusView;
