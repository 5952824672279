import React from "react";
import styled from "styled-components";
import { Text } from "../../../ui-kit";
import { AddressView } from "types/users/userContact.types";

const AddressWrapper = styled.address`
  span {
    display: block;
  }
`;


interface IAddressProps {
  address: AddressView;
}

const Address: React.FC<IAddressProps> = ({ address }) => {

  const formatLine = (line: string) => {
    if (line != null && line != "") {
      return <span>{line}</span>;
    }
    return null;
  };

  return (
    <AddressWrapper>
      {formatLine(address.address1)}
      {formatLine(address.address2)}
      {formatLine(address.address3)}
      {formatLine(address.town)}
      {formatLine(address.county)}
      {formatLine(address.postcode?.toUpperCase())}
    </AddressWrapper>
  );
};

export default Address;
