import onCallActions from "areas/behaviour/actions/onCall/onCallActions";
import { IBeehiveAction } from "types/common/action.types";

interface IOnCallNotificationState {
  newOnCallNotification: null;
}

const INITIAL_STATE: IOnCallNotificationState = {
  newOnCallNotification: null,
};

const { 
  //NEWONCALLNOTIFICATION, 
  CLEARNEWONCALLNOTIFICATION 
} = onCallActions.types;


const onCallNotificationReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IOnCallNotificationState => {

  switch (action.type) {
    // case NEWONCALLNOTIFICATION:
    //   return { ...state, newOnCallNotification: action.payload };

    case CLEARNEWONCALLNOTIFICATION:
      return {
        ...state,
        newOnCallNotification: null,
      };

    default:
      return state;
  }
};

export default onCallNotificationReducer;
