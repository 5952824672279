import React from "react";
import { ReactComponent as StarSvg } from "assets/primary/reward.svg";
import { IPrimaryRocketCharacterProps } from "./rockets/primaryRocket";


export const RewardStar: React.FC<IPrimaryRocketCharacterProps> = ({ className }) => {

  return <StarSvg className={className} />;
};

export default RewardStar;
