import React, { useState } from "react";
import { useSelector } from "react-redux";
import paymentActions from "areas/payments/actions/paymentsActions";
import flyoutActions from "actions/ui/flyouts";
import {
  Table,
  Currency,
  DetailLabel,
  Chip,
  Label,
  Swatches,
  Size,
  ToastService,
  Message,
  SplitButton,
  Icon,
  DateTime,
  EmptyMessage,
} from "ui-kit";
import { arrays } from "utils";
import { Constants } from "configuration";
import { UserName, SchoolIcon, RoleView } from "sharedComponents/common";
import ItemPaymentFlyout from "../orders/itemPaymentFlyout";
import OrderFlyout from "../orders/orderFlyout";
import { useNavigate } from "react-router";
import { users } from "utils";
import { RootState } from "reducers/store";
import { OrderListView, ProductListView, UserProductOrderView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { UserTinyView } from "types/users/userListViews.types";
import moment from "moment";


interface IProductOrdersTableProps {
  orders: UserProductOrderView[];
  showProduct?: boolean;
  showCollection?: boolean;
  showPurchasedFor?: boolean;
  onPayClicked?: (order: UserProductOrderView) => void;
  error?: boolean;
  loading?: boolean;
  footer?: JSX.Element;
}

const ProductOrdersTable: React.FC<IProductOrdersTableProps> = ({
  orders,
  showProduct,
  showCollection,
  showPurchasedFor,
  onPayClicked,
  error,
  loading,
  footer,
}) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const [selectedOrder, setSelectedOrder] = useState<OrderListView | null>(null);
  const [selectedItemToPay, setSelectedItemToPay] = useState<UserProductOrderView | null>(null);
  const { collecting, collectError } = useSelector((state: RootState) => state.userPurchasedItems);
  const navigate = useNavigate();

  const handleProductClicked = (product: ProductListView) => {
    navigate(`/main/shopkeeper/category/${product.category.id}/products/${product.id}`);
  };

  const handleUserClicked = (user: UserTinyView) => {
    navigate(`/main/shopkeeper/users/${user.id}`);
  };

  const handleOrderClicked = (order: OrderListView) => {
    setSelectedOrder(order);
    flyoutActions.openFlyout(Constants.FLYOUTS.ORDER);
  };

  const handlePayClicked = (order: UserProductOrderView) => {
    onPayClicked?.(order);
    setSelectedItemToPay(order);
    flyoutActions.openFlyout(Constants.FLYOUTS.ITEMPAYMENT);
  };

  const handleCollectClicked = (order: UserProductOrderView) => {
    if (
      window.confirm(
        "This will mark this order as having been collected. Are you sure?"
      )
    ) {
      paymentActions.markOrderLineCollected(
        order.order.user.id,
        order.order.id,
        order.id,
        () => {
          ToastService.pop("Item marked as collected", null, "shopping-bag");
        }
      );
    }
  };

  return (
    <>
      <Message text={collectError} color={Swatches.Danger} />
      <>
        <Table
          zebra
          error={error}
          loading={loading}
          empty={arrays.isEmpty(orders)}
          emptyMessage={
            <EmptyMessage
              icon="shopping-bag"
              title="No Orders"
              summary="No orders were found"
              cover
            />
          }
          grow
        >
          <Table.Header>
            <Table.HeaderCell show={showProduct} width={0.25} />
            <Table.HeaderCell show={showPurchasedFor} width={1.25}>
              Purchased For/By
            </Table.HeaderCell>
            <Table.HeaderCell width={0.25} />
            <Table.HeaderCell show={showProduct} width={1.5}>
              Item
            </Table.HeaderCell>
            <Table.HeaderCell width={1.25}>Order Date</Table.HeaderCell>
            <Table.HeaderCell width={0.5}>Order ID</Table.HeaderCell>
            <Table.HeaderCell width={0.25} right>
              Qty
            </Table.HeaderCell>
            <Table.HeaderCell width={0.5} right>
              Total
            </Table.HeaderCell>
            <Table.HeaderCell width={0.5} right>
              Paid
            </Table.HeaderCell>
            <Table.HeaderCell width={0.5} right>
              O/S
            </Table.HeaderCell>
            <Table.HeaderCell width={0.5} right>
              Refunded
            </Table.HeaderCell>
            <Table.HeaderCell width={0.5} center>
              Collected
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              Collected From
            </Table.HeaderCell>
            <Table.HeaderCell width={1} right></Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {orders?.map((order, index) => (
              <Table.Row key={index}>
                <Table.Cell width={0.25} show={showProduct}>
                  <SchoolIcon
                    code={order?.product?.category?.school.code}
                    size={Size.Small}
                  />
                </Table.Cell>
                <Table.Cell show={showPurchasedFor} width={1.25}>
                  <RoleView inverse roles={[Constants.ROLES.FINANCE_USER]}>
                    <DetailLabel
                      bold
                      label={
                        <>
                          <UserName user={order.purchasedFor} />
                          {/* {order.purchasedFor.pupilPremium && (
                            <Chip text="PP" colorSwatch={Swatches.Purple} />
                          )} */}
                        </>
                      }
                      sub={
                        <>
                          by <UserName user={order.orderedBy} />
                        </>
                      }
                    />
                  </RoleView>
                  <RoleView roles={[Constants.ROLES.FINANCE_USER]}>
                    <DetailLabel
                      bold
                      label={
                        <>
                          <UserName user={order.purchasedFor} />{" "}
                          {/* {order.purchasedFor.pupilPremium && (
                            <Icon
                              value="circle"
                              color={Swatches.Purple.swatch}
                              size={Size.ExtraSmall}
                            />
                          )} */}
                        </>
                      }
                      onClick={() => handleUserClicked(order.purchasedFor)}
                      sub={
                        <>
                          by <UserName user={order.orderedBy} />
                        </>
                      }
                    />
                  </RoleView>
                </Table.Cell>
                <Table.Cell width={0.25}>
                  {order.orderType == 1 && (
                    <Chip text="PP" colorSwatch={Swatches.Purple} />
                  )}
                  {order.orderType == 2 && (
                    <Chip text="BUR" colorSwatch={Swatches.DeepPink} />
                  )}
                </Table.Cell>
                <Table.Cell width={1.5} show={showProduct}>
                  <DetailLabel
                    onClick={() => handleProductClicked(order.product)}
                    bold
                    label={order.product.name}
                    sub={order.product.category.name}
                  />
                </Table.Cell>
                <Table.Cell width={1.25}>
                  <DateTime date={order.orderDate} bold />
                </Table.Cell>
                <Table.Cell width={0.5}>
                  <Label onClick={() => handleOrderClicked(order.order)}>
                    {order.order.id}
                  </Label>
                </Table.Cell>
                <Table.Cell width={0.25} right>
                  <p>{order.quantity}</p>
                </Table.Cell>
                <Table.Cell width={0.5} right>
                  <b>
                    <Currency value={order.total} />
                  </b>
                </Table.Cell>
                <Table.Cell width={0.5} right>
                  <Currency value={order.paid} />
                </Table.Cell>
                <Table.Cell width={0.5} right>
                  <Currency value={order.outstanding} />
                </Table.Cell>
                <Table.Cell width={0.5} right>
                  <Currency value={order.refunded} />
                </Table.Cell>
                <Table.Cell center width={0.5}>
                  {order.product?.requiresCollection
                    ? order.collectedDate != null && (
                        <Icon
                          value="check-circle"
                          color={Swatches.Success.swatch}
                        />
                      )
                    : "N/A"}
                </Table.Cell>
                <Table.Cell width={1}>
                  { order.collectedDate != null && (
                    <DetailLabel
                      bold
                      label={`${order.collectedFrom?.firstName} ${order.collectedFrom?.lastName}`}
                      sub={moment(order.collectedDate).format("DD/MM/YYYY HH:mm")}
                    />
                  )}
                </Table.Cell>
                <Table.Cell right width={1}>
                  <SplitButton
                    size={Size.Small}
                    text="Actions"
                    color={Swatches.Default}
                    working={collecting}
                  >
                    <SplitButton.Option
                      onClick={() => handlePayClicked(order)}
                      text="Pay"
                      show={
                        order.outstanding > 0 &&
                        users.isInAnyRoles(user, [Constants.ROLES.FINANCE_USER])
                      }
                    />
                    <SplitButton.Option
                      onClick={() => handleCollectClicked(order)}
                      text="Mark Collected"
                      show={order.isCollectable}
                    />
                  </SplitButton>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          {footer && <Table.Footer>{footer}</Table.Footer>}
        </Table>
      </>

      <OrderFlyout order={selectedOrder} />
      <ItemPaymentFlyout
        item={selectedItemToPay}
        user={selectedItemToPay?.orderedBy}
      />
    </>
  );
};

export default ProductOrdersTable;
