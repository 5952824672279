import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { displayFont, Tooltip } from "ui-kit";
import Tippy from "@tippyjs/react";
import { useNavigate } from "react-router";

const Wrapper = styled.div`
  font-size: 9px;
  line-height: 1rem;
  font-family: ${displayFont.roman.name};
  flex-grow: 1;
  margin-bottom: 0;
  background: black;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0.9rem;
  letter-spacing: 1px;
`;

const StudentSensitiveDataTile = ({ data, style, student }) => {
  const ref = useRef();
  const navigate = useNavigate();
  return (
    <>
      <Wrapper
        ref={ref}
        className="sensitive-info-list-item"
        style={
          style
            ? {
                ...style,
                background: data.color.swatch,
                color: data.color.foreground,
              }
            : { background: data.color.swatch, color: data.color.foreground }
        }
        onClick={() => data.onClick && data.onClick(student)}
      >
        {data.text}
      </Wrapper>

      <Tippy
        reference={ref}
        content={<Tooltip title={data.tooltip} sub={data.tooltipSub} />}
      />
    </>
  );
};

StudentSensitiveDataTile.propTypes = {
  student: PropTypes.object,
};

export default StudentSensitiveDataTile;
