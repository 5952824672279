import paymentActions from "areas/payments/actions/paymentsActions";
import { LunchCreditBalanceView } from "areas/payments/types/catering/cateringResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface IUserLunchCreditsState {
  accounts: LunchCreditBalanceView[],
  error: string | null;
  loading: boolean;
}

const INITIAL_STATE: IUserLunchCreditsState = {
  accounts: [],
  error: null,
  loading: false,
};


const userLunchCreditsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUserLunchCreditsState => {

  const { 
    PAYMENTS_GETUSERLUNCHCREDITBALANCE, 
    PAYMENTS_ADDMANUALLUNCHCREDIT 
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETUSERLUNCHCREDITBALANCE.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETUSERLUNCHCREDITBALANCE.SUCCESS:
      return { 
        ...state, 
        accounts: action.payload, 
        loading: false 
      };

    case PAYMENTS_GETUSERLUNCHCREDITBALANCE.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PAYMENTS_ADDMANUALLUNCHCREDIT.SUCCESS:
      return {
        ...state,
        accounts: state.accounts.map((x) =>
          x.school.id === action.payload.adjustment.school.id
            ? { ...x, balance: action.payload.balance }
            : x
        ),
      };

    default:
      return state;
  }
};

export default userLunchCreditsReducer;
