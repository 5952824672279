import { DeclarationOfInterestListView } from "areas/forms/types/declarations/declarationsOfInterestResponse.types";
import { Constants } from "configuration";
import { useState } from "react";
import Moment from "react-moment";
import { Avatar } from "sharedComponents/common";
import { IBeehiveError } from "types/common/errors.types";
import { PageInfo } from "types/common/paging.types";
import { EmptyMessage, Table } from "ui-kit";
import { arrays } from "utils";
import DeclarationsAdminModal from "./declarationsAdminModal";

interface IAdminDeclarationsTableProps {
  items: DeclarationOfInterestListView[];
  onRowClick?: () => void;
  loading?: boolean;
  error?: IBeehiveError;
  onPage?: () => void;
  paging?: PageInfo;
}


const AdminDeclarationsTable: React.FC<IAdminDeclarationsTableProps> = ({ 
  items, 
  onRowClick, 
  loading, 
  error, 
  onPage, 
  paging 
}) => {

  const [selectedDeclaration, setSelectedDeclaration] = useState<DeclarationOfInterestListView>(null);

  const handleRowClick = (row: DeclarationOfInterestListView) => {
    setSelectedDeclaration(row);
  };

  const handlePage = () => {
    onPage?.();
  };

  const handleSubmissionModalClose = () => {
    setSelectedDeclaration(null);
  };

  const getYearName = (yearId: number) => {
    if (!yearId) {
      return;
    }
    const year = Constants.ACADEMIC_YEARS.find(year => year.id == yearId);
    return year?.name;
  };

  return (
    <>
      <Table
        zebra
        empty={arrays.isEmpty(items)}
        emptyMessage={
          <EmptyMessage
            icon="paste"
            title="No Declarations"
            summary="No Declations of Interest were found"
            cover
          />
        }
        error={error != null}
        grow
      >

        <Table.Header>
          <Table.HeaderCell>Staff</Table.HeaderCell>
          <Table.HeaderCell>Academic Year</Table.HeaderCell>
          <Table.HeaderCell>Revisions</Table.HeaderCell>
          <Table.HeaderCell>Last Edited</Table.HeaderCell>
          <Table.HeaderCell>Created Date</Table.HeaderCell>
        </Table.Header>
        <Table.Body onPage={handlePage} paging={paging}>
          { items?.map((item, index) => (
            <Table.Row key={index} onClick={() => handleRowClick(item)}>
              <Table.Cell>
                <Avatar 
                  user={item.staffMember} 
                  sub={item.staffMember.school?.name}
                />
              </Table.Cell>
              <Table.Cell>
                {getYearName(item.academicYearId)}
              </Table.Cell>
              <Table.Cell>
                {item.versions.length}
              </Table.Cell>
              <Table.Cell>
                <Moment date={item.lastUpdatedDate} format="DD/MM/YYYY" />
              </Table.Cell>
              <Table.Cell>
                <Moment date={item.createdDate} format="DD/MM/YYYY" />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

      </Table>

      <DeclarationsAdminModal
        declaration={selectedDeclaration}
        onClose={handleSubmissionModalClose}
        open={selectedDeclaration != null}
      />

    </>
  )
}


export default AdminDeclarationsTable;