import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Size,
  Swatches,
  Button,
  EmptyMessage,
  Loader,
  Message,
  ToastService,
  Dropdown,
  Left,
  ActionBar,
  SplitButton,
} from "ui-kit";
import { arrays, users } from "utils";
import PupilPassportViewer from "./pupilPassportViewer";
import { Constants } from "configuration";
import { RoleView } from "sharedComponents/common";
import passportActions from "../../actions/passports/passportActions";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";
import { RootState } from "reducers/store";
import { PassportDetailView, PassportListView, PassportVersionDetailView, PassportVersionTinyView } from "areas/send/types/passportResponse.types";
import { SavePassportVersionCommand } from "areas/send/types/passportRequest.types";
import { PassportVersionStatus } from "areas/send/types/passportShared.types";
import config from "configuration/config";
import moment from "moment";
import constants from "configuration/constants";


interface IPupilPassportProps {
  student: PlannerStudentDetailView;
  readonly?: boolean;
}


const PupilPassport: React.FC<IPupilPassportProps> = ({ student, readonly }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { 
    loading, 
    error, 
    passports,  
  } = useSelector((state: RootState)  => state.passports);
  const {
    passport,
    loading: loadingPassport,
    error: passportError,
    saving,
    saveError,
  } = useSelector((state: RootState)  => state.passport);
  const {
    saving: savingVersion,
    saveError: saveVersionError,
    cloning,
    cloneError,
  } = useSelector((state: RootState)  => state.passportVersion);

  const [_student, _setStudent] = useState<PlannerStudentDetailView>(student);
  const [_selectedPassport, _setSelectedPassport] = useState<PassportListView | null>(null);
  const [_passport, _setPassport] = useState<PassportDetailView | null>(null);

  const userCanEdit =
    users.isInRole(user, Constants.ROLES.SEN_ADMINISTRATOR) ||
    users.isInRole(user, Constants.ROLES.SEN_COORDINATOR) ||
    users.isInRole(user, Constants.ROLES.LEADERSHIP);

  const getPassportVersion = (passportId: number, versionId?: number) => {
    // Leaving versionId null gets the most recent version of the passport
    passportActions.getPassport(student.id, passportId, versionId);
  };

  useEffect(() => {
    _setStudent(student);

    if (student) {
      passportActions.getPassportsForStudent(student.id);
    }
  }, [student]);

  useEffect(() => {
    if (_student && !arrays.isEmpty(passports) && (passports[0].student.id == _student.id)) {
      userCanEdit
        ? _setSelectedPassport(passports[0])
        : _setSelectedPassport(passports.find((p: PassportListView) => p.versions.some(v => v.status === PassportVersionStatus.Published)));
    } else {
      _setSelectedPassport(null);
    }
  }, [passports, _student]);

  useEffect(() => {
    if (passport && _student && passport.student.id == _student.id) {
      _setPassport(passport);
    }
    else {
      _setPassport(null);
    }
  }, [passport, _student])



  useEffect(() => {
    if (_selectedPassport && !arrays.isEmpty(_selectedPassport.versions)) {
      getPassportVersion(_selectedPassport.id); // This gets the most recent version of the selected passport
    }
  }, [_selectedPassport]);

  const getLatestVersionOfLatestPassport = () => {
    var latestPassport = passports.sort((a,b) => b.academicYearId - a.academicYearId)[0];
    var latestVersion = latestPassport.versions.sort((a,b) => b.versionNumber - a.versionNumber)[0];
    return latestVersion.versionNumber;
  }

  const handleAddPassport = (academicYearId: number, clone?: boolean) => {

    const messageText = clone 
      ? `This will create a new pupil passport for ${student.firstName} ${student.lastName} with the default version cloned from the latest version (${getLatestVersionOfLatestPassport()}) of last academic years passport. Are you sure?`
      : `This will create a new blank pupil passport for ${student.firstName} ${student.lastName}. Are you sure?`;
    
    if (window.confirm(messageText)) {
      passportActions.createPassport(
        { 
          studentId: student.id, 
          cloneFromLatestVersion: clone, 
          academicYearId: academicYearId
        }, 
        () => ToastService.pop("Pupil Passport Created", null, "hand-holding-seedling")
      );
    }
  };

  const handleNewVersion = () => {
    if (
      window.confirm(
        `This will create a new draft blank version of this passport. Are you sure?`
      )
    ) {
      var data: SavePassportVersionCommand = {
        passportId: _passport.id,
        studentId: _student.id,
        status: PassportVersionStatus.Draft,
        primaryNeed: null,
        secondaryNeed: null,
        specificNeed: "",
        tags: [],
        accessArrangements: [],
        files: [],
        tiles: [],
        ehcp: false,
        ihcp: false,
        pastoralSupportPlan: false
      };
      passportActions.createPassportVersion(data,
        (newVersion) => {
          ToastService.pop(
            "Pupil Passport Version Created",
            null,
            "hand-holding-seedling"
          );
          _selectedPassport.versions.push(newVersion as PassportVersionTinyView);
        }
      );
    }
  };

  const handleCloneVersion = () => {
    if (
      window.confirm(
        `This will clone Version ${_passport.selectedVersion.versionNumber} of this passport into a new draft version for editing.  Are you sure?`
      )
    ) {
      passportActions.clonePassportVersion(
        {
          versionId: _passport.selectedVersion.id,
          studentId: _student.id,
          passportId: _passport.id,
        },
        (data: PassportVersionDetailView) => {
          _setSelectedPassport({ ..._selectedPassport, versions: [..._selectedPassport.versions, data as PassportVersionTinyView]});
          ToastService.pop(
            "Pupil Passport Version Cloned",
            null,
            "hand-holding-seedling"
          );
        }
      );
    }
  };

  const handlePassportChange = (passport: PassportListView, passportId: number) => {
    if (passport == null && passportId == -1) {
      handleAddPassport(config.academicYear.current);
    } 
    else if (passport == null && passportId == -2) {
      handleAddPassport(config.academicYear.current, true);
    }
    else if (passport == null && passportId == -3) {
      handleAddPassport(config.academicYear.current + 1);
    }
    else if (passport == null && passportId == -4) {
      handleAddPassport(config.academicYear.current + 1, true);
    }
    else {
      _setSelectedPassport(passport);
    }
  };

  const handlePassportVersionChange = (versionId: number) => {
    getPassportVersion(_passport.id, versionId);
  };

  const handleVersionPublish = (publishedVersion: PassportVersionDetailView) => {
    _setSelectedPassport({ 
      ..._selectedPassport, 
      versions: _selectedPassport.versions.map(version => 
        version.id == publishedVersion.id
          ? { ...version, status: 1, statusName: "Published" }
          : version
      )
    });
  }

  const handleVersionDelete = (deletedId: number) => {
    _setSelectedPassport({ 
      ..._selectedPassport, 
      versions: _selectedPassport.versions.filter(version => version.id !== deletedId)
    });
  }

  if (loading) {
    return <Loader cover size={Size.Medium} />;
  }

  if (error) {
    return (
      <EmptyMessage
        cover
        title="An error occured"
        icon="times-circle"
        summary="There was a problem while loading the passports"
      />
    );
  }

  const getMapList = () => {
    var provisionMaps: JSX.Element[] = passports?.map((pass, index) => (
      <Dropdown.Item
        key={index}
        value={pass.id}
        label={pass.academicYear}
        data={pass}
      />
    ))

    if (!passports.some(x => x.academicYearId === config.academicYear.current) && userCanEdit) {
      provisionMaps.push(
        <Dropdown.Item
          key={-1}
          value={-1}
          label={`Create new blank passport for ${config.academicYear.currentName}`}
          data={null}
        />
      );
      if (passports.some(x => x.academicYearId < config.academicYear.current) && userCanEdit) {
        provisionMaps.push(
          <Dropdown.Item
            key={-2}
            value={-2}
            label={`Create new passport for ${config.academicYear.currentName} from latest passport version`}
            data={null}
          />
        );
      }
    }
    if (
      !passports.some(x => x.academicYearId == config.academicYear.current + 1) && 
      moment().isAfter(moment(config.academicYear.currentEndDate).add(-3, 'months')) && 
      constants.ACADEMIC_YEARS.some(x => x.id == config.academicYear.current + 1) &&
      userCanEdit
    ) {
      var nextYear = constants.ACADEMIC_YEARS.find(x => x.id == config.academicYear.current + 1);
      provisionMaps.push(
        <Dropdown.Item
          key={-3}
          value={-3}
          label={`Create new blank passport for ${nextYear.name}`}
          data={null}
        />
      );

      if (passports.some(x => x.academicYearId == config.academicYear.current)) {
        provisionMaps.push(
          <Dropdown.Item
            key={-4}
            value={-4}
            label={`Create new passport for ${nextYear.name} from latest passport version`}
            data={null}
          />
        );
      }
    }

    return provisionMaps;
  }

  return arrays.isEmpty(passports) ? (
    <>
      <Message text={saveError} color={Swatches.Danger} />
      <Message text={saveVersionError} color={Swatches.Danger} />
      <Message text={cloneError} color={Swatches.Danger} />

      <EmptyMessage
        cover
        title="No Pupil Passports"
        icon="hand-holding-seedling"
        summary="No pupil passports have been added for this student"
      >
        { userCanEdit && (
          <Button
            text="Add Pupil Passport"
            size={Size.Small}
            color={Swatches.Primary}
            onClick={() => handleAddPassport(config.academicYear.current)}
            working={saving}
          />
        )}
      </EmptyMessage>
    </>
  ) : (
    <>
      <RoleView
        roles={[
          Constants.ROLES.SEN_ADMINISTRATOR,
          Constants.ROLES.SEN_COORDINATOR,
          Constants.ROLES.LEADERSHIP,
        ]}
      >
        <ActionBar>
          <Left>
            <Dropdown
              placeholder="Please choose a passport"
              value={_selectedPassport?.id}
              onChange={(value: number, label: string, data: PassportListView) => handlePassportChange(data, value)}
            >
              { getMapList() }
            </Dropdown>
            <Dropdown
              placeholder="Please choose a passport version"
              value={_passport?.selectedVersion.id}
              onChange={(value: number, label: string) => handlePassportVersionChange(value)}
            >
              {_selectedPassport?.versions?.map((version, index) => (
                <Dropdown.Item
                  key={index}
                  value={version.id}
                  label={`Version ${version.versionNumber}`}
                />
              ))}
            </Dropdown>
            {_selectedPassport?.versions?.every(x => x.status === 1) && (
              <SplitButton
                size={Size.Small}
                text="New Version"
                color={Swatches.Primary}
                onDefaultClick={handleNewVersion}
                working={savingVersion || cloning}
              >
                <SplitButton.Option
                  text="New Version"
                  onClick={handleNewVersion}
                  show
                />
                <SplitButton.Option
                  text={`Clone Version ${_passport?.selectedVersion.versionNumber}`}
                  onClick={handleCloneVersion}
                  show
                />
              </SplitButton>
            )}
          </Left>
        </ActionBar>
      </RoleView>

      {loadingPassport ? (
        <Loader cover size={Size.Medium} />
      ) : passportError ? (
        <EmptyMessage
          cover
          title="An error occured"
          icon="times-circle"
          summary="There was a problem loading the selected passport"
        />
      ) : _passport == null ? (
        <EmptyMessage
          cover
          title="No passport found"
          icon="hand-holding-seedling"
          summary="There are no live passports for this user"
        />
      ) : (
        <PupilPassportViewer
          passport={_passport}
          student={student}
          readonly={userCanEdit || readonly}
          onPublish={handleVersionPublish}
          onDelete={handleVersionDelete}
        />
      )}
    </>
  );
};

export default PupilPassport;
