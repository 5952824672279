import React, { useEffect, useState } from "react";
import {
  Modal,
  Card,
  StructuredList,
  ActionBar,
  Button,
  Right,
  Size,
  Swatches,
  Message,
  TextInput,
  TextInputType,
  ToastService,
  RichTextEditor,
  ValidationMessage,
  Dropdown,
  Currency,
  DatePicker,
  Loader,
} from "ui-kit";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import { arrays, strings } from "utils";
import ProviderDropdown from "../providers/providerDropdown";
import AreaOfNeedDropdown from "../providers/provisions/areaOfNeedDropdown";
import ProvisionCostBandForm from "../providers/provisions/provisionCostBands/provisionCostBandForm";
import provisionsActions from "../../actions/provisions/provisionsActions";
import { RootState } from "reducers/store";
import { CustomProvisionDetailView, ProviderListView, ProvisionCostListView, ProvisionMapDetailView } from "areas/send/types/passportResponse.types";
import { SaveCustomProvisionCommand } from "areas/send/types/passportRequest.types";
import { ProvisionFrequency, ProvisionStatus, ProvisionTimescale } from "areas/send/types/passportShared.types";
import TimescaleDropdown from "../providers/provisions/timescaleDropdown";
import { DateRange } from "types/common/data.types";


interface ICustomProvisionModalProps {
  provision: CustomProvisionDetailView;
  provisionMap: ProvisionMapDetailView;
  open: boolean;
  onCancel: () => void;
  onSave?: (data: SaveCustomProvisionCommand) => void;
}

interface customProvision extends CustomProvisionDetailView {
  providerId: number;
  newProviderId?: number;
}


const CustomProvisionModal: React.FC<ICustomProvisionModalProps> = ({
  provision,
  provisionMap,
  open,
  onCancel,
  onSave,
}) => {

  const { saving, saveError } = useSelector((state: RootState) => state.customProvision);
  const [_notes, _setNotes] = useState<string>("");
  const [_open, _setOpen] = useState<boolean>(open);
  const [_provision, _setProvision] = useState<customProvision | null>(null);
  const [_provisionMap, _setProvisionMap] = useState<ProvisionMapDetailView | null>(null);
  const [_total, _setTotal] = useState<number>(0);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  useEffect(() => {
    setValidationErrors([]);
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    provisionMap && _setProvisionMap(provisionMap);
  }, [provisionMap]);

  useEffect(() => {
    _setProvision({ ...provision, providerId: provision?.provider?.id });
    _setNotes(provision?.progress?.notes);
  }, [provision]);

  useEffect(() => {
    _setTotal(0);

    if (_provision) {
      var hours =
        _provision?.frequencyLength *
        _provision?.sessionsPerFrequency *
        _provision?.sessionLength;

      if (_provision?.costs?.length) {
        var costTotal = _provision?.costs
          ?.map(x => x.defaultCost * x.weighting)
          .reduce((prev, next) => prev + next);

        _setTotal(hours * costTotal);
      }
    }
  }, [_provision]);

  const handleCancel = () => {
    _setNotes(null);
    onCancel?.();
  }

  const handleSave = () => {
    // Validation
    if (!_provision || !_provisionMap) {
      return;
    }

    var errors = [];

    if (!_provision.name) {
      errors.push(`Please enter a name.`);
    }

    if (!_provision.providerId) {
      errors.push(`Please choose a provider.`);
    }

    if (_provision.areaOfNeed == null) {
      errors.push(`Please select an area of need.`);
    }

    if (!_provision.progress.target) {
      errors.push(`Please enter a target.`);
    }

    if (_provision.progress.rating == null) {
      errors.push(`Please enter a progress rating.`);
    }

    // if (_provision.frequency == null) {
    //   errors.push(`Please select a frequency.`);
    // }

    // if (_provision.frequencyLength == null) {
    //   errors.push(`Please enter a frequency length`);
    // }

    // if (_provision.sessionsPerFrequency == null) {
    //   errors.push(`Please enter the number of sessions per frequency`);
    // }

    // if (!_provision.sessionLength) {
    //   errors.push(`Please enter the length of a session`);
    // }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    var data: SaveCustomProvisionCommand = {
      id: _provision.id,
      studentId: _provisionMap.student.id,
      startDate: _provision.startDate,
      endDate: _provision.endDate,
      timescale: _provision.timescale,
      entryData: _provision.progress.entryData,
      exitData: _provision.progress.exitData,
      target: _provision.progress.target,
      strategies: _provision.progress.strategies,
      notes: _notes,
      rating: _provision.progress.rating,
      costs: _provision.costs?.map(cost => ({
        id: cost.id ? cost.id : null,
        bandId: cost.bandId,
        cost: cost.defaultCost,
        weighting: cost.weighting,
      })),
      frequency: _provision.frequency,
      frequencyLength: _provision.frequencyLength,
      sessionsPerFrequency: _provision.sessionsPerFrequency,
      sessionLength: _provision.sessionLength,
      providerId: _provision.providerId,
      schoolId: _provision.school.id,
      areaOfNeed: _provision.areaOfNeed,
      name: _provision.name,
      description: _provision.description,
      tags: _provision.tags.map(x => x.id),
      newProviderId: _provision.newProviderId,
      status: ProvisionStatus.Live
    };

    console.log(data);

    if (!data.id) {
      provisionsActions.createCustomProvision(data, () => {
        ToastService.pop(
          "Custom Provision Created",
          null,
          "hand-holding-seedling"
        );
        _setNotes(null);
        onSave?.(data);
      });
    } else {
      provisionsActions.saveCustomProvision(data, () => {
        ToastService.pop(
          "Custom Provision Saved",
          null,
          "hand-holding-seedling"
        );
        _setNotes(null);
        onSave?.(data);
      });
    }
  };

  const getFrequencyName = (value: ProvisionFrequency) => {
    var vals = Object.values(Constants.SEND_PROVISIONFREQUENCY);
    var freq = vals.find(x => x.value === value);
    return freq?.name;
  };

  const getTotalSessions = () => {
    const sessions =
      _provision?.frequencyLength * _provision?.sessionsPerFrequency;
    const hours = sessions * _provision?.sessionLength;

    return `${sessions} ${strings.pluralize(
      "Session",
      "Sessions",
      sessions
    )} / ${hours} ${strings.pluralize("Hour", "Hours", hours)}`;
  };

  const handleFrequencyChanged = (value: number) => {
    if (value === 0) {
      _setProvision({
        ..._provision,
        frequency: value,
        frequencyLength: 1,
        sessionsPerFrequency: 1,
      });
    } else {
      _setProvision({ ..._provision, frequency: value });
    }
  };

  const handleCostBandsChange = (costBands: ProvisionCostListView[]) => {
    _setProvision({ ..._provision, costs: costBands });
  };

  const handleTimescaleChange = (value: ProvisionTimescale, data?: DateRange) => {
    if (value != ProvisionTimescale.Custom) {
      setShowDatePicker(false);
      _setProvision({ 
        ..._provision, 
        startDate: data.startDate, 
        endDate: data.endDate,
        timescale: value
      })
    }
    else {
      setShowDatePicker(true);
      _setProvision({ 
        ..._provision, 
        timescale: value
      })
    }
  }

  if (_provision == null) {
    return <Loader size={Size.Medium} cover />
  }

  return (
    <Modal
      title={_provision?.id ? "Edit Custom Provision" : "New Custom Provision"}
      open={_open}
      onClose={handleCancel}
      width="80%"
      height="80%"
    >
      <Modal.Body>
        <ValidationMessage errors={validationErrors} />
        <Message text={saveError} color={Swatches.Danger} />

        <Card title="General">
          <Card.Body>
            <StructuredList>
              <StructuredList.Item
                name="Name"
                description="The name of the provision"
                required
              >
                <TextInput
                  value={_provision?.name}
                  onChange={value =>
                    _setProvision({ ..._provision, name: value })
                  }
                  maxLength={100}
                  fluid
                />
              </StructuredList.Item>
              <StructuredList.Item name="Provider" required>
                <ProviderDropdown
                  fluid
                  value={ _provision?.newProviderId 
                    ? _provision?.newProviderId 
                    : _provision?.providerId
                  }
                  onChange={(value: number, label: string, data: ProviderListView) => 
                    _provision.id 
                    ? _setProvision({ ..._provision, newProviderId: value, provider: data })
                    : _setProvision({ ..._provision, providerId: value, provider: data })
                  }
                />
              </StructuredList.Item>
              <StructuredList.Item name="Area of Need" required>
                <AreaOfNeedDropdown
                  fluid
                  value={_provision?.areaOfNeed}
                  onChange={value =>
                    _setProvision({ ..._provision, areaOfNeed: value })
                  }
                />
              </StructuredList.Item>
              <StructuredList.Item 
                name="Date Range" 
                description={"All predefined date ranges start from the current date"} 
                required
              >
                <TimescaleDropdown
                  fluid
                  onChange={handleTimescaleChange}
                  academicYear={_provisionMap?.academicYear}
                  value={{ 
                    timescale: _provision.timescale, 
                    dates: { startDate: _provision.startDate, endDate: _provision.endDate } 
                  }}
                />
              </StructuredList.Item>
              <StructuredList.Item 
                name={"Start Date"} 
                hide={!showDatePicker} 
                required={true}
              >
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  closeOnSelect
                  selectedDate={_provision?.startDate}
                  onChange={value => _setProvision({ ..._provision, startDate: value })}
                />
              </StructuredList.Item>
              <StructuredList.Item 
                name={"End Date"} 
                hide={!showDatePicker} 
                required={true}
              >
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  closeOnSelect
                  selectedDate={_provision?.endDate}
                  onChange={value => _setProvision({ ..._provision, endDate: value })}
                />  
              </StructuredList.Item>
              <StructuredList.Item
                name="Description"
                description="An optional description with more details about this provision"
              >
                <TextInput
                  type={TextInputType.Textarea}
                  value={_provision?.description}
                  onChange={value =>
                    _setProvision({ ..._provision, description: value })
                  }
                  fluid
                  rows={5}
                />
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>

        <Card title="Target & Progress">
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="Target" required>
                <TextInput
                  onChange={value =>
                    _setProvision({
                      ..._provision,
                      progress: { ..._provision?.progress, target: value },
                    })
                  }
                  value={_provision?.progress?.target}
                  fluid
                />
              </StructuredList.Item>
              <StructuredList.Item name="Progress Rating" required>
                <Dropdown
                  onChange={value =>
                    _setProvision({
                      ..._provision,
                      progress: { ..._provision?.progress, rating: value },
                    })
                  }
                  value={_provision?.progress?.rating}
                  fluid
                >
                  <Dropdown.Item value={0} label="0 : Expected Progress" />
                  <Dropdown.Item
                    value={1}
                    label="+1 : Above Expected Progress"
                  />
                  <Dropdown.Item
                    value={2}
                    label="-1 : Below Expected Progress"
                  />
                </Dropdown>
              </StructuredList.Item>
              <StructuredList.Item name="Entry Data">
                <TextInput
                  type={TextInputType.Textarea}
                  onChange={value =>
                    _setProvision({
                      ..._provision,
                      progress: { ..._provision?.progress, entryData: value },
                    })
                  }
                  value={_provision?.progress?.entryData}
                  maxLength={100}
                  characterCount
                  rows={3}
                  fluid
                />
              </StructuredList.Item>
              <StructuredList.Item name="Exit Data">
                <TextInput
                  type={TextInputType.Textarea}
                  onChange={value =>
                    _setProvision({
                      ..._provision,
                      progress: { ..._provision?.progress, exitData: value },
                    })
                  }
                  value={_provision?.progress?.exitData}
                  maxLength={100}
                  characterCount
                  rows={3}
                  fluid
                />
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>

        <Card title="Frequency">
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="Frequency">
                <Dropdown
                  fluid
                  placeholder="Choose a Frequency"
                  value={_provision?.frequency ?? null}
                  onChange={handleFrequencyChanged}
                  items={arrays.configConstantsToArray(
                    Constants.SEND_PROVISIONFREQUENCY
                  )}
                />
              </StructuredList.Item>
              {_provision?.frequency !== 0 && (
                <>
                  <StructuredList.Item
                    name={`Number of ${getFrequencyName(
                      _provision?.frequency
                    )}s`}
                  >
                    <TextInput
                      type={TextInputType.Number}
                      value={_provision?.frequencyLength}
                      min={1}
                      onChange={value =>
                        _setProvision({ ..._provision, frequencyLength: value })
                      }
                      suffix={`${getFrequencyName(_provision?.frequency)}s`}
                    />
                  </StructuredList.Item>

                  <StructuredList.Item
                    name={`Sessions per ${getFrequencyName(
                      _provision?.frequency
                    )}${_provision?.frequencyLength > 1 ? "" : "s"}`}
                  >
                    <TextInput
                      type={TextInputType.Number}
                      value={_provision?.sessionsPerFrequency}
                      min={1}
                      onChange={value =>
                        _setProvision({
                          ..._provision,
                          sessionsPerFrequency: value,
                        })
                      }
                      suffix="Sessions"
                    />
                  </StructuredList.Item>
                </>
              )}

              {_provision?.frequency != null && (
                <StructuredList.Item name="Session Length">
                  <TextInput
                    type={TextInputType.Number}
                    value={_provision?.sessionLength}
                    onChange={value =>
                      _setProvision({ ..._provision, sessionLength: value })
                    }
                    suffix="Hours"
                  />
                </StructuredList.Item>
              )}
              <StructuredList.Item name="Total Sessions">
                {getTotalSessions()}
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>

        <Card title="Cost">
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="Cost Bands">
                <ProvisionCostBandForm
                  costBands={_provision?.costs}
                  onChange={handleCostBandsChange}
                />
              </StructuredList.Item>
              <StructuredList.Item name="Total Cost">
                <Currency value={_total} />
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>

        <Card grow title="Strategies">
          <Card.Body noPad>
            <RichTextEditor
              initialValue={_provision?.progress?.strategies}
              noPad
              onChange={value => _setProvision({
                ..._provision,
                progress: { ..._provision?.progress, strategies: value },
              })}
            />
          </Card.Body>
        </Card>

        <Card grow title="Notes">
          <Card.Body noPad>
            <RichTextEditor
              initialValue={_provision?.progress?.notes}
              noPad
              onChange={value => _setNotes(value)}
            />
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              onClick={handleCancel}
              size={Size.Small}
              color={Swatches.Low}
              text="Cancel"
              working={saving}
            />
            <Button
              onClick={handleSave}
              size={Size.Small}
              color={Swatches.Success}
              text="Save"
              working={saving}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomProvisionModal;
