import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import StudentBehaviourCharts from "./studentBehaviourCharts";
import StudentBehaviourReportForm from "./studentBehaviourReportForm";
import { Loader, Size, EmptyMessage } from "ui-kit";
import moment from "moment";
import StudentBehaviourTable from "./studentBehaviourTable";
import { arrays } from "utils";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import schoolsActions from "areas/planner/actions/schools/schoolsActions";
import studentBehaviourReportActions from "areas/planner/actions/reports/studentBehaviourReportActions";
import { ApiExceptionMessage } from "sharedComponents/common";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";
import { useAppSelector } from "reducers/hooks";
import { BehaviourIncludeType } from "areas/analytics/types/behaviourAnalyticsShared.types";
import { CategoryType } from "areas/analytics/types/analytics.shared";


interface IStudentBehaviourWrapperProps {
  student: PlannerStudentDetailView;
}

const StudentBehaviourWrapper: React.FC<IStudentBehaviourWrapperProps> = ({ student }) => {

  const reportRef = useRef<HTMLDivElement>();

  const { report, loading, error } = useAppSelector(
    state => state.studentBehaviourReport
  );
  const { behaviourCodes } = useAppSelector(state => state.behaviourCodes);
  const { rewardCodes } = useAppSelector(state => state.rewardCodes);

  const [categoryType, setCategoryType] = useState<BehaviourIncludeType>(null);

  useEffect(() => {
    if (student) {
      if (arrays.isEmpty(behaviourCodes)) {
        behaviourActions.getBehaviourCodes(student.school.id, { isLegacy: null, canBeAwarded: null });
      }
      if (arrays.isEmpty(rewardCodes)) {
        rewardActions.getRewardCodes(student.school.id);
      }

      schoolsActions.selectSchool(student.school.id);
    }
  }, [student]);

  useEffect(() => {
    if (loading || report) {
      reportRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading, report]);

  const handleGenerateReport = (
    startDate: Date,
    endDate: Date,
    categoryType: BehaviourIncludeType,
    academicYearId: number,
    codeId: number,
    tutorGroupId: number,
    classGroupId: number,
    exportToPdf: boolean
    // timeInterval
  ) => {
    // if (timeInterval) {
    //   getStudentBehaviourReportByTimeAction(
    //     student.id,
    //     moment(startDate, ["DD/MM/YYYY"]).format("YYYY-MM-DD"),
    //     moment(endDate, ["DD/MM/YYYY"]).format("YYYY-MM-DD"),
    //     categoryType,
    //     codeId,
    //     tutorGroupId,
    //     classGroupId,
    //     timeInterval
    //   );
    // } else {

    if (exportToPdf) {
      studentBehaviourReportActions.downloadStudentBehaviourReport(
        student.id,
        moment(startDate, ["DD/MM/YYYY"]).format("YYYY-MM-DD"),
        moment(endDate, ["DD/MM/YYYY"]).format("YYYY-MM-DD"),
        categoryType,
        academicYearId,
        codeId,
        tutorGroupId,
        classGroupId
      );
    }
    else {
      studentBehaviourReportActions.getStudentBehaviourReport(
        student.id,
        moment(startDate, ["DD/MM/YYYY"]).format("YYYY-MM-DD"),
        moment(endDate, ["DD/MM/YYYY"]).format("YYYY-MM-DD"),
        categoryType,
        academicYearId,
        codeId,
        tutorGroupId,
        classGroupId
      );
    }
    // }

    setCategoryType(categoryType);
  };

  return (
    <>
      <StudentBehaviourReportForm
        handleGenerateReport={handleGenerateReport}
        studentTutorGroup={student.tutorGroup}
        schoolId={student.school?.id}
      />
      <div ref={reportRef}>
        {loading ? (
          <Loader size={Size.Large} cover />
        ) : error ? (
          <ApiExceptionMessage error={error} />
        ) : (
          report &&
          (report.totalBehaviourPoints + report.totalRewardPoints === 0 ? (
            <EmptyMessage
              title="No rewards or behaviours in the selected time period"
              icon="list"
            />
          ) : (
            <>
              <StudentBehaviourCharts studentBehaviourData={report} />
              <StudentBehaviourTable
                report={report}
                categoryType={categoryType}
              />
            </>
          ))
        )}
      </div>
    </>
  );
};

export default StudentBehaviourWrapper;
