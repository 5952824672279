import { DetentionTypeListView } from "areas/behaviour/types/behaviourResponses.types";
import detentionTypesActions from "../../actions/detentions/detentionTypesActions";
import { IBeehiveError } from "types/common/errors.types";
import { IBeehiveAction } from "types/common/action.types";


interface IDetentionTypesState {
  detentionTypes: DetentionTypeListView[];
  loading: boolean;
  error: IBeehiveError
}

const INITIAL_STATE: IDetentionTypesState = {
  detentionTypes: [],
  loading: false,
  error: null,
};

const { GETDETENTIONTYPES } = detentionTypesActions.types;

const detentionTypesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IDetentionTypesState => {
  switch (action.type) {
    case GETDETENTIONTYPES.START:
      return { ...state, loading: true, error: null };

    case GETDETENTIONTYPES.SUCCESS:
      return {
        ...state,
        loading: false,
        detentionTypes: action.payload,
      };

    case GETDETENTIONTYPES.FAILED:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default detentionTypesReducer;
