import { BehaviourIntialActionView } from "areas/behaviour/types/behaviourResponses.types";
import React, { useEffect, useState } from "react"
import { Dropdown, StructuredList } from "ui-kit";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { IInitialAction } from "../rewards/initialRewardActionWrapper";


interface IInitialActionDropdownProps {
  selectedInitialAction: IInitialAction;
  handleChangeInitialAction: (action: IInitialAction) => void;
  initialActions: BehaviourIntialActionView[];
}


const InitialActionDropdown: React.FC<IInitialActionDropdownProps> = ({
  selectedInitialAction,
  handleChangeInitialAction,
  initialActions
}) => {

  const [initialActionsArray, setInitialActionsArray] = useState<IDropdownDataItemProps<string, number, null>[]>([]);
  const [actionDisabled, setActionDisabled] = useState<boolean>(false);

  useEffect(() => {
    const actions: IDropdownDataItemProps<string, number, null>[] = initialActions.map(action => ({
      key: action.initialActionId,
      label: action.name,
      value: action.initialActionId
    }));

    setInitialActionsArray(actions);

    if (
      selectedInitialAction.initialActionId &&
      initialActions &&
      initialActions.length > 0
    ) {
      const actionSet = initialActions.find(
        action =>
          action.initialActionId === selectedInitialAction.initialActionId
      );
      if (!actionSet) {
        actions.push({
          label: selectedInitialAction.initialActionName,
          value: selectedInitialAction.initialActionId
        });
        setActionDisabled(true);
      }
    }
  }, [initialActions]);

  const handleActionChange = (value: number, label: string)=> {
    const selectedAction = initialActions.find(
      action => action.initialActionId === value
    );
    handleChangeInitialAction({
      initialActionId: value,
      initialActionName: selectedAction.name
    });
  };

  return (
    <StructuredList.Item
      name="Initial Action"
      description={`The initial action taken when the point was given. ${
        actionDisabled
          ? "Please note: The Initial Action that was set for this record is now inactive."
          : ""
      }`}
      required
      helpPopup
    >
      <Dropdown
        placeholder="Initial Action"
        fluid
        items={initialActionsArray}
        onChange={handleActionChange}
        value={selectedInitialAction.initialActionId}
      />
    </StructuredList.Item>
  );
};


export default InitialActionDropdown;
