import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import FileDownload from "js-file-download";
import { PassportDetailView, PassportListView, PassportVersionDetailView } from "areas/send/types/passportResponse.types";
import { callbackType } from "types/common/action.types";
import { ClonePassportCommand, ClonePassportVersionCommand, PublishPassportVersionCommand, SavePassportCommand, SavePassportVersionCommand } from "areas/send/types/passportRequest.types";

const builder = new actionTypeBuilder("send");

const types = {
  PASSPORTS_GET: builder.build("passports_get"),
  PASSPORT_GET: builder.build("passport_get"),
  PASSPORT_CREATE: builder.build("passport_create"),
  PASSPORT_SAVE: builder.build("passport_save"),
  PASSPORT_CLONE: builder.build("passport_clone"),
  PASSPORTVERSION_SAVE: builder.build("passportversion_save"),
  PASSPORTVERSION_CREATE: builder.build("passportversion_create"),
  PASSPORTVERSION_PUBLISH: builder.build("passportversion_publish"),
  PASSPORTVERSION_DELETE: builder.build("passportversion_delete"),
  PASSPORTVERSION_CLONE: builder.build("passportversion_clone"),
  PASSPORTVERSION_DOWNLOAD: builder.build("passportversion_download"),
};


const getPassportsForStudent = (studentId: string, callback?: callbackType<PassportListView[]>) =>
  action<PassportListView[]>(
    () => client.get(`planner/send/students/${studentId}/passports`),
    types.PASSPORTS_GET,
    callback
  );

const getPassport = (studentId: string, passportId: number, versionId?: number, callback?: callbackType<PassportDetailView>) => {
  var urlString = `planner/send/students/${studentId}/passports/${passportId}`;

  if (versionId != null) {
    urlString = urlString + "/versions/" + versionId;
  }

  return action<PassportDetailView>(
    () => client.get(urlString), 
    types.PASSPORT_GET, 
    callback
  );
};

const createPassport = (data: SavePassportCommand, callback?: callbackType<PassportDetailView>) =>
  action<PassportDetailView>(
    () => client.post(`planner/send/students/${data.studentId}/passports`, data),
    types.PASSPORT_CREATE,
    callback
  );

const savePassport = (data: SavePassportCommand, callback?: callbackType<PassportDetailView>) =>
  action<PassportDetailView>(
    () => client.post(`planner/send/students/${data.studentId}/passports/${data.id}`, data),
    types.PASSPORT_SAVE,
    callback
  );

const clonePassport = (data: ClonePassportCommand, callback?: callbackType<PassportDetailView>) =>
  action<PassportDetailView>(
    () => client.post(`planner/send/students/${data.studentId}/passports/${data.passportId}/clone`, data),
    types.PASSPORT_CLONE,
    callback
  );

const createPassportVersion = (data: SavePassportVersionCommand, callback?: callbackType<PassportVersionDetailView>) =>
  action<PassportVersionDetailView>(
    () => client.post(`planner/send/students/${data.studentId}/passports/${data.passportId}/versions`, data),
    types.PASSPORTVERSION_CREATE,
    callback
  );

const savePassportVersion = (data: SavePassportVersionCommand, callback?: callbackType<PassportVersionDetailView>) =>
  action<PassportVersionDetailView>(
    () => client.post(`planner/send/students/${data.studentId}/passports/${data.passportId}/versions/${data.id}`, data),
    types.PASSPORTVERSION_SAVE,
    callback
  );

const clonePassportVersion = (data: ClonePassportVersionCommand, callback?: callbackType<PassportVersionDetailView>) =>
  action<PassportVersionDetailView>(
    () => client.post(`planner/send/students/${data.studentId}/passports/${data.passportId}/versions/${data.versionId}/clone`, data),
    types.PASSPORTVERSION_CLONE,
    callback
  );

const publishPassportVersion = (data: PublishPassportVersionCommand, callback?: callbackType<PassportVersionDetailView>) =>
  action<PassportVersionDetailView>(
    () => client.post(`planner/send/students/${data.studentId}/passports/${data.passportId}/versions/${data.id}/publish`, data),
    types.PASSPORTVERSION_PUBLISH,
    callback
  );

const downloadPassportVersion = (studentId: string, passportId: number, versionId: number, callback?: callbackType<Blob>) =>
  action<Blob>(
    () => client.get(`planner/send/students/${studentId}/passports/${passportId}/versions/${versionId}/download`, { responseType: "blob" }),
    types.PASSPORTVERSION_DOWNLOAD,
    (responseData, responseHeaders) => {
      const contentDisposition = responseHeaders["content-disposition"];
      var filename = contentDisposition.split("=");
      FileDownload(responseData, filename[1]);

      callback && callback(responseData);
    }
  );

const deletePassportVersion = (studentId: string, passportId: number, versionId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.delete(`planner/send/students/${studentId}/passports/${passportId}/versions/${versionId}`),
    types.PASSPORTVERSION_DELETE,
    callback
  );


const passportActions = {
  types,
  getPassportsForStudent,
  getPassport,
  createPassport,
  savePassport,
  clonePassport,
  createPassportVersion,
  savePassportVersion,
  clonePassportVersion,
  publishPassportVersion,
  downloadPassportVersion,
  deletePassportVersion,
};

export default passportActions;
