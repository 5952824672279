import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import moment from "moment";
import store from "reducers/store";
import client from "services/client";
import FileDownload from "js-file-download";
import { urls } from "utils";
import { BeehiveReport, callbackType } from "types/common/action.types";
import { CalendarEventFilter, CalendarEventSignupCommand, CancelCalendarEventSignupCommand, EventReportFilter, IssueRewardsForCalendarEventDateCommand, SaveCalendarEventCommand, SaveCalendarEventDateRegisterCommand, SaveWallplannerEventCommand } from "../types/calendarRequests.types";
import { PagedQueryView } from "types/common/views.types";
import { CalendarCategoryListView, CalendarEventAppListView, CalendarEventAttendanceListView, CalendarEventDateRegisterView, CalendarEventDetailView, CalendarEventListView, EventAttendanceStudentListView, EventRegisterForUsersView, EventTotalSignupsReportListView, WallPlannerEventView, WallplannerListView } from "../types/calendarResponses.types";
import { WallPlannerSelectedSessionFilter, WallPlannerSessionsFilter } from "../reducers/wallPlannerSessionsReducer";
import { CalendarEvent } from "../reducers/calendarEventReducer";
import { CalendarEventsFilter } from "../reducers/calendarEventsFilterReducer";
import { IBeehiveError } from "types/common/errors.types";

const builder = new actionTypeBuilder("calendar");

const types = {
  CALENDAR_GETCALENDARCATEGORIES: builder.build(
    "CALENDAR_GETCALENDARCATEGORIES"
  ),
  CALENDAR_GETUSERCALENDAREVENTS: builder.build(
    "CALENDAR_GETUSERCALENDAREVENTS"
  ),
  CALENDAR_GETSTUDENTCALENDAREVENTS: builder.build(
    "CALENDAR_GETSTUDENTCALENDAREVENTS"
  ),
  CALENDAR_GETMANAGEDCALENDAREVENTS: builder.build(
    "CALENDAR_GETMANAGEDCALENDAREVENTS"
  ),
  CALENDAR_GETSIGNEDUPCALENDAREVENTS: builder.build(
    "CALENDAR_GETSIGNEDUPCALENDAREVENTS"
  ),
  CALENDAR_GETCALENDAREVENT: builder.build("CALENDAR_GETCALENDAREVENT"),
  CALENDAR_CREATECALENDAREVENT: builder.build("CALENDAR_CREATECALENDAREVENT"),
  CALENDAR_UPDATECALENDAREVENT: builder.build("CALENDAR_UPDATECALENDAREVENT"),
  CALENDAR_EVENTSIGNUP: builder.build("CALENDAR_EVENTSIGNUP"),
  CALENDAR_EVENTUNSIGNUP: builder.build("CALENDAR_EVENTUNSIGNUP"),
  CALENDAR_EVENTDATE_REGISTER: builder.build("CALENDAR_EVENTDATE_REGISTER"),
  CALENDAR_EVENTDATE_REGISTER_SAVE: builder.build(
    "CALENDAR_EVENTDATE_REGISTER_SAVE"
  ),
  CALENDAR_EVENTDATE_REGISTER_ISSUEREWARDS: builder.build(
    "CALENDAR_EVENTDATE_REGISTER_ISSUEREWARDS"
  ),
  CALENDAR_ADDEVENTTOSIGNEDUPEVENTS: "CALENDAR_ADDEVENTTOSIGNEDUPEVENTS",
  CALENDAR_REMOVEEVENTFROMSIGNEDUPEVENTS:
    "CALENDAR_REMOVEEVENTFROMSIGNEDUPEVENTS",
  CALENDAR_UNSIGNEDUPTOALLDATES: "CALENDAR_UNSIGNEDUPTOALLDATES",
  CALENDAR_SIGNEDUPTOALLDATES: "CALENDAR_SIGNEDUPTOALLDATES",
  CALENDAR_EVENTATTENDANCEREPORT: builder.build(
    "CALENDAR_EVENTATTENDANCEREPORT"
  ),
  CALENDAR_USEREVENTATTENDANCE: builder.build("CALENDAR_USEREVENTATTENDANCE"),
  CALENDAR_EXPORTEVENTATTENDANCEREPORT: builder.build(
    "CALENDAR_EXPORTEVENTATTENDANCEREPORT"
  ),
  CALENDAR_EVENTDATEATTENDANCEREPORT: builder.build(
    "CALENDAR_EVENTDATEATTENDANCEREPORT"
  ),
  CALENDAR_EXPORTEVENTDATEATTENDANCEREPORT: builder.build(
    "CALENDAR_EXPORTEVENTDATEATTENDANCEREPORT"
  ),
  CALENDAR_EXPORTEVENTDATEMENUREPORT: builder.build(
    "CALENDAR_EXPORTEVENTDATEMENUREPORT"
  ),

  CALENDAR_EXPORTEVENTDATEMENUTOTALSREPORT: builder.build(
    "CALENDAR_EXPORTEVENTDATEMENUTOTALSREPORT"
  ),
  CALENDAR_DELETECALENDAREVENT: builder.build("CALENDAR_DELETECALENDAREVENT"),
  CALENDAR_EVENTS_UPDATEFILTER: "CALENDAR_EVENTS_UPDATEFILTER",
  WALLPLANNER_UPDATESESSIONFILTER: "WALLPLANNER_UPDATESESSIONFILTER",
  WALLPLANNER_GETSESSIONS: builder.build("WALLPLANNER_GETSESSIONS"),
  WALLPLANNER_GETEVENTS: builder.build("WALLPLANNER_GETEVENTS"),
  WALLPLANNER_UPDATEEVENTSFILTER: "WALLPLANNER_UPDATEEVENTSFILTER",
  WALLPLANNER_CREATEEVENT: builder.build("WALLPLANNER_CREATEEVENT"),
  WALLPLANNER_UPDATEEVENT: builder.build("WALLPLANNER_UPDATEEVENT"),
  WALLPLANNER_DELETEEVENT: builder.build("WALLPLANNER_DELETEEVENT"),
  WALLPLANNER_GETPREVIEW: builder.build("WALLPLANNER_GETPREVIEW"),
  CALENDAR_EVENTPPSTATUSEREPORT: builder.build("CALENDAR_EVENTPPSTATUSEREPORT"),
  CALENDAR_TOTALSIGNUPSREPORT: builder.build("CALENDAR_TOTALSIGNUPSREPORT"),
  CALENDAR_TOTALSIGNUPSREPORT_DISPLAY: builder.build("CALENDAR_TOTALSIGNUPSREPORT_DISPLAY"),
};


const addEventToSignedUpEvents = (event: CalendarEvent) => {
  return store.dispatch({
    type: types.CALENDAR_ADDEVENTTOSIGNEDUPEVENTS,
    payload: event,
  });
};

const removeEventFromSignedUpEvents = (event: CalendarEvent) => {
  return store.dispatch({
    type: types.CALENDAR_REMOVEEVENTFROMSIGNEDUPEVENTS,
    payload: event,
  });
};

const signedUpToAllDates = (event: CalendarEvent) => {
  return store.dispatch({
    type: types.CALENDAR_SIGNEDUPTOALLDATES,
    payload: event,
  });
};

const unsignedUpToAllDates = (event: CalendarEvent) => {
  return store.dispatch({
    type: types.CALENDAR_UNSIGNEDUPTOALLDATES,
    payload: event,
  });
};

const getUserCalendarEvents = (
  userId: string,
  filter: CalendarEventFilter,
  pageIndex: number,
  callback?: callbackType<PagedQueryView<CalendarEventAppListView>>,
  errCallback?: callbackType<IBeehiveError>,
) => {
  let url = new urls.QueryString(`planner/calendar/users/${userId}/events`);
  url.addPaging(pageIndex);
  url.addParams(filter);

  return action<PagedQueryView<CalendarEventAppListView>>(
    () => client.get(url.toUrl()),
    types.CALENDAR_GETUSERCALENDAREVENTS,
    callback,
    errCallback
  );
};


const getStudentCalendarEvents = (
  userId: string,
  filter: CalendarEventFilter,
  pageIndex: number,
  callback?: callbackType<PagedQueryView<CalendarEventAppListView>>,
  errCallback?: callbackType<IBeehiveError>,
) => {
  let url = new urls.QueryString(`planner/calendar/users/${userId}/events`);
  url.addPaging(pageIndex);
  url.addParams(filter);

  return action<PagedQueryView<CalendarEventAppListView>>(
    () => client.get(url.toUrl()),
    types.CALENDAR_GETSTUDENTCALENDAREVENTS,
    callback,
    errCallback
  );
};


const getCalendarCategories = (callback?: callbackType<CalendarCategoryListView[]>) =>
  action<CalendarCategoryListView[]>(
    () => client.get(`planner/calendar/categories`),
    types.CALENDAR_GETCALENDARCATEGORIES,
    callback
  );

const getManagedCalendarEvents = (
  userId: string, 
  filter: CalendarEventFilter, 
  pageIndex: number, 
  callback?: callbackType<PagedQueryView<CalendarEventListView>>
) => {
  let url = new urls.QueryString(`planner/calendar/users/${userId}/events/managed`);
  url.addPaging(pageIndex);
  url.addParams(filter);

  return action<PagedQueryView<CalendarEventListView>>(
    () => client.get(url.toUrl()),
    types.CALENDAR_GETMANAGEDCALENDAREVENTS,
    callback
  );
};

const getSignedUpCalendarEvents = (
  userId: string, 
  filter: CalendarEventFilter, 
  pageIndex: number, 
  callback?: callbackType<PagedQueryView<CalendarEventAppListView>>
) => {
  let url = new urls.QueryString(`planner/calendar/users/${userId}/events/signedup`);
  url.addPaging(pageIndex);
  url.addParams(filter);

  return action<PagedQueryView<CalendarEventAppListView>>(
    () => client.get(url.toUrl()),
    types.CALENDAR_GETSIGNEDUPCALENDAREVENTS,
    callback
  );
};

const getCalendarEvent = (userId: string, eventId: number, showPastDates: boolean = false, callback?: callbackType<CalendarEventDetailView>) =>
  action<CalendarEventDetailView>(
    () => client.get(`planner/calendar/users/${userId}/events/${eventId}?showPastDates=${showPastDates}`),
    types.CALENDAR_GETCALENDAREVENT,
    callback
  );

const createCalendarEvent = (data: SaveCalendarEventCommand, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`planner/calendar/events`, data),
    types.CALENDAR_CREATECALENDAREVENT,
    callback
  );

const updateCalendarEvent = (data: SaveCalendarEventCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`planner/calendar/events/${data.id}`, data),
    types.CALENDAR_UPDATECALENDAREVENT,
    callback
  );

const deleteCalendarEvent = (eventId: number, callback?: callbackType<number>) => 
  action<number>(
    () => client.delete(`planner/calendar/events/${eventId}`),
    types.CALENDAR_EVENTPPSTATUSEREPORT,
    callback
  );

const calendarEventSignup = (data: CalendarEventSignupCommand, callback?: callbackType<Number[]>, failureCallback?: callbackType) =>
  action<Number[]>(
    () => client.post(`planner/calendar/events/${data.eventId}/signups`, data),
    types.CALENDAR_EVENTSIGNUP,
    callback,
    failureCallback
  );

const calendarEventUnsignup = (data: CancelCalendarEventSignupCommand, callback?: callbackType<Number[]>, failureCallback?: callbackType) =>
  action<Number[]>(
    () => client.post(`planner/calendar/events/${data.eventId}/signups/cancel`, data),
    types.CALENDAR_EVENTUNSIGNUP,
    callback,
    failureCallback
  );

const getCalendarEventDateRegister = (
  eventId: number,
  eventDateId: number,
  pageIndex: number,
  callback?: callbackType<CalendarEventDateRegisterView>
) =>
  action<CalendarEventDateRegisterView>(
    () => client.get(`planner/calendar/events/${eventId}/dates/${eventDateId}/signups?pageIndex=${pageIndex}&pageSize=125`),
    types.CALENDAR_EVENTDATE_REGISTER,
    callback
  );

const saveCalendarEventDateRegister = (data: SaveCalendarEventDateRegisterCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`planner/calendar/events/${data.eventId}/dates/${data.eventDateId}/register`, data),
    types.CALENDAR_EVENTDATE_REGISTER_SAVE,
    callback
  );

const issueRewardsForCalendarEventDate = (data: IssueRewardsForCalendarEventDateCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`planner/calendar/events/${data.eventId}/dates/${data.eventDateId}/rewards`, data),
    types.CALENDAR_EVENTDATE_REGISTER_ISSUEREWARDS,
    callback
  );

const getEventAttendanceReport = (filter: EventReportFilter, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.post(`planner/calendar/events/reporting/attendance?schoolId=${filter.schoolId}&academicYearId=${filter.academicYearId}`, filter, { responseType: "text" }),
    types.CALENDAR_EVENTATTENDANCEREPORT,
    callback
  );

const getUserEventAttendance = (
  eventId: number, 
  userId: string, 
  callback?: callbackType<CalendarEventAttendanceListView[]>, 
  errCallback?: callbackType<IBeehiveError>
) =>
  action<CalendarEventAttendanceListView[]>(
    () => client.get(`planner/calendar/events/${eventId}/users/${userId}/attendance`),
    types.CALENDAR_USEREVENTATTENDANCE,
    callback,
    errCallback
  );

const getEventAttendanceRegisterReport = (
  eventId: number,
  pageIndex: number,
  callback?: callbackType<PagedQueryView<EventRegisterForUsersView>>,
  errCallback?: callbackType<IBeehiveError>
) =>
  action<PagedQueryView<EventRegisterForUsersView>>(
    () => client.get(`planner/calendar/events/${eventId}/reporting/eventRegister?pageIndex=${pageIndex}&pageSize=50`),
    types.CALENDAR_EVENTATTENDANCEREPORT,
    callback,
    errCallback
  );

const exportEventAttendanceRegisterReport = (
  eventId: number,
  eventName: string,
  callback?: callbackType<BeehiveReport>,
  errCallback?: callbackType<IBeehiveError>
) =>
  action<BeehiveReport>(
    () => client.get(`planner/calendar/events/${eventId}/reporting/eventRegisterExport`, { responseType: "blob" }),
    types.CALENDAR_EXPORTEVENTATTENDANCEREPORT,
    response => {
      FileDownload(response, `${eventName} Attendance.csv`);
      callback?.(response);
    },
    errCallback
  );

const getEventDateAttendanceReport = (
  eventId: number, 
  date: Date, 
  callback?: callbackType<EventAttendanceStudentListView[]>, 
  errCallback?: callbackType<IBeehiveError>
) =>
  action<EventAttendanceStudentListView[]>(
    () => client.get(`planner/calendar/events/${eventId}/dates/${moment(date).format("YYYY-MM-DD")}/reporting/userAttendances`),
    types.CALENDAR_EVENTDATEATTENDANCEREPORT,
    callback,
    errCallback
  );

const exportEventDateAttendanceReport = (
  eventId: number,
  date: Date,
  eventName: string,
  callback?: callbackType<BeehiveReport>,
  errCallback?: callbackType<IBeehiveError>
) =>
  action<BeehiveReport>(
    () => client.get(`planner/calendar/events/${eventId}/dates/${moment(date).format("YYYY-MM-DD")}/reporting/userAttendancesExport`, { responseType: "blob" }),
    types.CALENDAR_EXPORTEVENTDATEATTENDANCEREPORT,
    response => {
      FileDownload(response, `${eventName} Attendance - ${moment(date, "YYYY-MM-DD").format("DD-MM-YYYY")}.csv`);
      callback?.(response);
    },
    errCallback
  );

const exportEventDateMenuReport = (
  eventId: number,
  eventDateId: number,
  callback?: callbackType<BeehiveReport>,
  errCallback?: callbackType<IBeehiveError>
) =>
  action<BeehiveReport>(
    () => client.get(`planner/calendar/events/${eventId}/dates/${eventDateId}/exportMenu`, { responseType: "blob" }),
    types.CALENDAR_EXPORTEVENTDATEMENUREPORT,
    response => {
      FileDownload(response, `Menu Choices.csv`);
      callback?.(response);
    },
    errCallback
  );

const exportEventDateMenuTotalsReport = (
  eventId: number,
  eventDateId: number,
  callback?: callbackType<BeehiveReport>,
  errCallback?: callbackType<IBeehiveError>
) =>
  action<BeehiveReport>(
    () => client.get(`planner/calendar/events/${eventId}/dates/${eventDateId}/exportMenuTotals`, { responseType: "blob" }),
    types.CALENDAR_EXPORTEVENTDATEMENUTOTALSREPORT,
    response => {
      FileDownload(response, `Menu Totals.csv`);
      callback?.(response);
    },
    errCallback
  );

const updateEventsFilter = (filter: CalendarEventsFilter) => {
  store.dispatch({
    type: types.CALENDAR_EVENTS_UPDATEFILTER,
    payload: filter,
  });
};

const updateWallplannerSessionFilter = (filter: WallPlannerSessionsFilter) => {
  store.dispatch({
    type: types.WALLPLANNER_UPDATESESSIONFILTER,
    payload: filter,
  });
};

const getWallplannerSessions = (filter: WallPlannerSessionsFilter, callback?: callbackType<WallplannerListView[]>) =>
  action<WallplannerListView[]>(
    () => client.get(`planner/calendar/wallplanners/academicyear/${filter.academicYear}`),
    types.WALLPLANNER_GETSESSIONS,
    callback
  );

const getWallplannerEvents = (schoolId: number, academicYear: number, callback?: callbackType<WallPlannerEventView[]>) =>
  action<WallPlannerEventView[]>(
    () => client.get(`planner/calendar/wallplanners/schools/${schoolId}/academicyears/${academicYear}/events`),
    types.WALLPLANNER_GETEVENTS,
    callback
  );

const updateWallplannerEventsFilter = (filter: WallPlannerSelectedSessionFilter) => {
  store.dispatch({
    type: types.WALLPLANNER_UPDATEEVENTSFILTER,
    payload: filter,
  });
};

const createWallplannerEvent = (event: SaveWallplannerEventCommand, callback?: callbackType<WallPlannerEventView>) =>
  action<WallPlannerEventView>(
    () => client.post(`planner/calendar/wallplanners/saveEvent`, event),
    types.WALLPLANNER_CREATEEVENT,
    callback
  );

const updateWallplannerEvent = (event: SaveWallplannerEventCommand, callback?: callbackType<WallPlannerEventView>) =>
  action<WallPlannerEventView>(
    () => client.post(`planner/calendar/wallplanners/saveEvent`, event),
    types.WALLPLANNER_UPDATEEVENT,
    callback
  );

const deleteWallplannerEvent = (event: WallPlannerEventView, callback?: callbackType<number>) =>
  action<number>(
    () => client.delete(`planner/calendar/wallplanners/deleteEvent/category/${event.categoryId}/event/${event.id}`),
    types.WALLPLANNER_DELETEEVENT,
    callback
  );

const getPlannerPreview = (schoolId: number, yearId: number, callback?: callbackType<Blob>) =>
  action<Blob>(
    () => client.get(`planner/calendar/schools/${schoolId}/academicyears/${yearId}/print`, { responseType: "blob" }),
    types.WALLPLANNER_GETPREVIEW,
    (data) => {
      FileDownload(data, "wallplanner.pdf")
      callback?.(data);
    }
  );

const getAttendanceStatusReport = (filter: EventReportFilter, callback?: callbackType<BeehiveReport>) => {
  let url = new urls.QueryString(`planner/calendar/events/reporting/studentsEventAttendance`);
  url.addParams(filter);

  return action<BeehiveReport>(
    () => client.get(url.toUrl(), { responseType: "text" }),
    types.CALENDAR_EVENTPPSTATUSEREPORT,
    callback
  );
}

const getTotalSignupsReport = (filter: EventReportFilter, callback?: callbackType<BeehiveReport>) => {
  let url = new urls.QueryString(`planner/reporting/calendarEventTotalSignupsReportExport`);
  url.addParams(filter);

  return action<BeehiveReport>(
    () => client.get(url.toUrl(), { responseType: "text" }),
    types.CALENDAR_TOTALSIGNUPSREPORT,
    callback
  );
}

const getTotalSignupsReportDisplay = (filter: EventReportFilter, pageIndex: number, callback?: callbackType<PagedQueryView<EventTotalSignupsReportListView>>) => {
  let url = new urls.QueryString(`planner/reporting/calendarEventTotalSignupsReport`);
  url.addParams(filter);
  url.addPaging(pageIndex);

  return action<PagedQueryView<EventTotalSignupsReportListView>>(
    () => client.get(url.toUrl()),
    types.CALENDAR_TOTALSIGNUPSREPORT_DISPLAY,
    callback
  );
}

const calendarActions = {
  types,
  addEventToSignedUpEvents,
  removeEventFromSignedUpEvents,
  signedUpToAllDates,
  unsignedUpToAllDates,
  getUserCalendarEvents,
  getStudentCalendarEvents,
  getCalendarCategories,
  getManagedCalendarEvents,
  getSignedUpCalendarEvents,
  getCalendarEvent,
  createCalendarEvent,
  updateCalendarEvent,
  deleteCalendarEvent,
  calendarEventSignup,
  calendarEventUnsignup,
  getCalendarEventDateRegister,
  saveCalendarEventDateRegister,
  issueRewardsForCalendarEventDate,
  getEventAttendanceReport,
  getUserEventAttendance,
  getEventAttendanceRegisterReport,
  exportEventAttendanceRegisterReport,
  getEventDateAttendanceReport,
  exportEventDateAttendanceReport,
  exportEventDateMenuReport,
  exportEventDateMenuTotalsReport,
  updateEventsFilter,
  updateWallplannerSessionFilter,
  getWallplannerSessions,
  getWallplannerEvents,
  updateWallplannerEventsFilter,
  createWallplannerEvent,
  updateWallplannerEvent,
  deleteWallplannerEvent,
  getPlannerPreview,
  getAttendanceStatusReport,
  getTotalSignupsReport,
  getTotalSignupsReportDisplay
};

export default calendarActions;
