import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  EmptyMessage,
  Left,
  Right,
  ActionBar,
  Swatches,
  Size,
  ToastService,
} from "ui-kit";
import { StatusView, UserView, RoleView } from "sharedComponents/common";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import Submission from "./submission";
import AdminSubmissionStateModal, {
  STATEMODAL_MODE,
} from "./submissionStateModal";
import adminSubmissionActions from "areas/forms/actions/adminSubmissionActions";
import formActions from "../../actions/formActions";
import { SubmissionListView } from "areas/forms/types/formResponse.types";
import { RootState } from "reducers/store";
import { TrayListView } from "areas/filing/types/fileResponse.types";
import { AddUserNoteCommand } from "areas/forms/types/formRequest.types";


interface ISubmissionModalProps {
  submission: SubmissionListView | TrayListView;
  open?: boolean;
  onClose?: () => void;
}


const SubmissionModal: React.FC<ISubmissionModalProps> = ({ submission, open, onClose }) => {
  
  const { user } = useSelector((state: RootState) => state.currentUser);
  const {
    submission: _submission,
    loading,
    error,
  } = useSelector((state: RootState) => state.adminSubmission);

  const [_notes, _setNotes] = useState<string | null>(null);
  const [_open, _setOpen] = useState<boolean>(false);
  const [stateModalOpen, setStateModalOpen] = useState<boolean>(false);
  const [stateModalMode, setStateModalMode] = useState<STATEMODAL_MODE>(STATEMODAL_MODE.ACCEPT);

  const noteChange = (noteValue: string) => {
    _setNotes(noteValue);
  };

  useEffect(() => {
    submission && adminSubmissionActions.getSubmissionForAdmin(submission.id);
  }, [submission]);

  useEffect(() => {
    _setOpen(open);
    open && _submission && _setNotes(_submission.submitterNotes);
    !open && _setNotes(null);
  }, [open]);

  const openStateModal = (mode: STATEMODAL_MODE) => {
    setStateModalMode(mode);
    setStateModalOpen(true);
  };

  const handleNoteUpdates = () => {
    const noteUpdate: AddUserNoteCommand = {
      userId: user.id,
      submissionId: _submission.id,
      notes: _notes,
    };

    formActions.updateNotes(_submission.id, noteUpdate, () => {
      onClose?.();
      ToastService.pop("Form submitted succesfully", null, "paste");
    });
  };

  return _submission ? (
    <Modal
      // title={submission?.formName + " Form"}
      open={_open}
      onClose={onClose}
      width="95%"
      height="95%"
      loading={loading}
      empty={_submission == null}
      emptyMessage={
        <EmptyMessage
          icon="paste"
          title="Submission Not Found"
          summary="The requested form submission was not found"
          cover
        />
      }
      error={error}
      errorMessage={
        <EmptyMessage
          icon="times-circle"
          title="A problem occurred"
          summary="There was a problem loading the requested form submission"
          cover
        />
      }
    >
      <Modal.Body>
        <StatusView status={_submission.latestHistory.status}>
          <StatusView.Item status={Constants.SUBMISSION_STATUS.DRAFT.value}>
            <UserView user={_submission.submittedBy} adminView>
              <Submission submission={_submission} onNoteChange={noteChange} />
            </UserView>
          </StatusView.Item>
          <StatusView.Item
            status={Constants.SUBMISSION_STATUS.DRAFT.value}
            inverse
          >
            <Submission submission={_submission} onNoteChange={noteChange} />
          </StatusView.Item>
        </StatusView>

        <AdminSubmissionStateModal
          open={stateModalOpen}
          mode={stateModalMode}
          onClose={() => setStateModalOpen(false)}
          submission={_submission}
        />
      </Modal.Body>
      {_submission.latestHistory.status !==
        Constants.SUBMISSION_STATUS.DRAFT.value && (
        <Modal.Footer>
          <ActionBar low>
            <Left>
              <Button
                text="Close"
                color={Swatches.Low}
                size={Size.Small}
                onClick={onClose}
              />
            </Left>
            <Right>
              {_submission && _notes !== _submission.submitterNotes && _notes !== null && (
                <Button
                  text="Update Notes"
                  color={Swatches.Primary}
                  size={Size.Small}
                  onClick={handleNoteUpdates}
                />
              )}
              <StatusView status={_submission.latestHistory.status}>
                <StatusView.Item
                  status={Constants.SUBMISSION_STATUS.SUBMITTED.value}
                >
                  {user && user.id === _submission.authoriser?.id && (
                    <>
                      <Button
                        text="Authorise This Form"
                        onClick={() => openStateModal(STATEMODAL_MODE.ACCEPT)}
                        color={Swatches.Success}
                        size={Size.Small}
                      />
                      <Button
                        text="Reject This Form"
                        onClick={() => openStateModal(STATEMODAL_MODE.REJECT)}
                        color={Swatches.Danger}
                        size={Size.Small}
                      />
                    </>
                  )}
                </StatusView.Item>

                <StatusView.Item
                  status={Constants.SUBMISSION_STATUS.APPROVED.value}
                >
                  <RoleView roles={[Constants.ROLES.FINANCE_USER]}>
                    <Button
                      text="Confirm This Form"
                      onClick={() => openStateModal(STATEMODAL_MODE.ACCEPT)}
                      size={Size.Small}
                      color={Swatches.Success}
                    />
                    <Button
                      text="Reject This Form"
                      onClick={() => openStateModal(STATEMODAL_MODE.REJECT)}
                      size={Size.Small}
                      color={Swatches.Danger}
                    />
                  </RoleView>
                </StatusView.Item>
                <StatusView.Item
                  status={Constants.SUBMISSION_STATUS.CONFIRMED.value}
                >
                  <RoleView roles={[Constants.ROLES.FINANCE_USER]}>
                    <Button
                      text="Complete this Form"
                      onClick={() => openStateModal(STATEMODAL_MODE.ACCEPT)}
                      size={Size.Small}
                      color={Swatches.Success}
                    />
                  </RoleView>
                </StatusView.Item>
                <StatusView.Item
                  inverse
                  status={Constants.SUBMISSION_STATUS.CANCELLED.value}
                >
                  <RoleView roles={[Constants.ROLES.FINANCE_USER]}>
                    <Button
                      text="Cancel This Form"
                      color={Swatches.Cancel}
                      size={Size.Small}
                      onClick={() => openStateModal(STATEMODAL_MODE.CANCEL)}
                    />
                  </RoleView>
                </StatusView.Item>
              </StatusView>
            </Right>
          </ActionBar>
        </Modal.Footer>
      )}
    </Modal>
  ) : null;
};

export default SubmissionModal;
