
export enum SenStatus {
  N = 0,
  E = 1,
  K = 2,
  KH = 3
}

export enum AbilityStatus {
  Lap = 0,
  Map = 1,
  Hap = 2
}