import adminConsentFormReducer from "./admin/adminConsentFormReducer";
import adminConsentFormsReducer from "./admin/adminConsentFormsReducer";
import adminConsentResponseReducer from "./admin/adminConsentResponseReducer";
import adminConsentResponsesReducer from "./admin/adminConsentResponsesReducer";
import completedConsentFormsReducer from "./consent/completedConsentFormsReducer";
import consentSubmissionReducer from "./consent/consentSubmissionReducer";
import createdConsentFormsReducer from "./consent/createdConsentFormsReducer";
import newConsentFormsReducer from "./consent/newConsentFormsReducer";
import userConsentFormReducer from "./consent/userConsentFormReducer";
import consentReportsReducer from "./reports/consentReportsReducer";
import documentGroupReducer from "./userManagement/documentGroupReducer";
import documentGroupsReducer from "./userManagement/documentGroupsReducer";
import managedUserReducer from "./userManagement/managedUserReducer";
import passwordReducer from "./userManagement/passwordReducer";
import reportingReducer from "./userManagement/reportingReducer";
import rolesReducer from "./userManagement/rolesReducer";
import roleStaffReducer from "./userManagement/roleStaffReducer";
import userDocumentsReducer from "./userManagement/userDocumentsReducer";
import profileReducer from "./profileReducer";
import administrationHomeReducer from "./administrationHomeReducer";
import syncFailureReportReducer from "./sync/syncFailureReportReducer";
import syncLogReducer from "./sync/SyncLogReducer";
import syncUserWarningReducer from "./sync/syncUserWarningReducer";

const userManagement = {
  adminConsentForm: adminConsentFormReducer,
  adminConsentForms: adminConsentFormsReducer,
  adminConsentResponse: adminConsentResponseReducer,
  adminConsentResponses: adminConsentResponsesReducer,
  completedConsentForms: completedConsentFormsReducer,
  consentSubmission: consentSubmissionReducer,
  createdConsentForms: createdConsentFormsReducer,
  newConsentForms: newConsentFormsReducer,
  userConsentForm: userConsentFormReducer,
  consentReports: consentReportsReducer,
  documentGroup: documentGroupReducer,
  documentGroups: documentGroupsReducer,
  managedUser: managedUserReducer,
  password: passwordReducer,
  managedUserReporting: reportingReducer,
  roles: rolesReducer,
  roleStaff: roleStaffReducer,
  userDocuments: userDocumentsReducer,
  profile: profileReducer,
  administrationHome: administrationHomeReducer,
  syncFailureReport: syncFailureReportReducer,
  syncLog: syncLogReducer,
  userWarning: syncUserWarningReducer,
};

export default userManagement;
