import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import AccessArrangementsTable from "./accessArrangementsTable";
import accessArrangementActions from "../../actions/accessArrangements/accessArrangementsActions";
import { RootState } from "reducers/store";


const AccessArrangements = () => {

  const { error, loading, accessArrangements } = useSelector(
    (state: RootState) => state.accessArrangements
  );

  useEffect(() => {
    accessArrangementActions.getAccessArrangements();
  }, []);

  return (
    <AccessArrangementsTable
      arrangements={accessArrangements}
      loading={loading}
      error={error}
    />
  );
};

export default AccessArrangements;
