import React from "react";
import { useSelector } from "react-redux";
import { ActionBar, Dropdown } from "ui-kit";
import calendarActions from "../../actions/calendarActions";
import { RootState } from "reducers/store";


const WallPlannerFilter = () => {
  
  const { filter } = useSelector((state: RootState) => state.wallPlannerSessions);

  const handleYearChange = (YearId: number) => {
    calendarActions.updateWallplannerSessionFilter({
      ...filter,
      academicYear: YearId,
    });
  };

  return (
    <>
      <ActionBar>
        <Dropdown
          value={filter?.academicYear}
          onChange={handleYearChange}
          fluid
        >
          <Dropdown.Item label="2022/23" value={9} />
          <Dropdown.Item label="2023/24" value={10} />
          <Dropdown.Item label="2024/25" value={11} />
        </Dropdown>
      </ActionBar>
    </>
  );
};

export default WallPlannerFilter;
