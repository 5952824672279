import React from "react";
import PropTypes from "prop-types";
import RoomLayoutGridRow from "./roomLayoutGridRow";
import styled from "styled-components";
import { neutral } from "ui-kit/common/colors";
import { Spacing } from "ui-kit";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  [class*="room-row-"] {
    display: flex;

    [class*="room-col-"] {
      width: 2rem;
      height: 2rem;
      border: 1px dashed ${neutral[500]};
      border-radius: ${Spacing.Small}px;
      margin: 0.05rem;

      &:not(.preview):hover {
        background-color: ${neutral[600]};
        cursor: pointer;
      }

      &.seat {
        border: 1px solid ${neutral[600]};
        background-color: ${neutral[600]};
      }

      &.preview {
        width: 0.6rem;
        height: 0.6rem;
        border: 1px solid lightgrey;
      }
    }
  }
`;

const RoomLayoutGrid = ({
  rowsArray,
  colsArray,
  roomSlots,
  setRoomSlots,
  preview,
}) => {
  return (
    <Wrapper className="room-layout-grid">
      {rowsArray.map(row => (
        <RoomLayoutGridRow
          key={row}
          row={row}
          colsArray={colsArray}
          roomSlots={roomSlots}
          setRoomSlots={setRoomSlots}
          preview={preview}
        />
      ))}
      {/* <pre>{JSON.stringify(roomSlots, null, 2)}</pre> */}
    </Wrapper>
  );
};

RoomLayoutGrid.propTypes = {
  rowsArray: PropTypes.array,
  colsArray: PropTypes.array,
  roomSlots: PropTypes.array,
  setRoomSlots: PropTypes.func,
  preview: PropTypes.bool,
};

export default RoomLayoutGrid;
