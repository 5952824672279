import React from "react";
import { SchoolDropdown, StaffSearch, StaffSelector } from "sharedComponents/common";
import { TextInput, ActionBar, TextInputType, Spacing } from "ui-kit";
import { useAppSelector } from "reducers/hooks";
import declarationsOfInterestActions from "areas/forms/actions/declarations/declarationsOfInterestActions";
import AcademicYearFilter from "sharedComponents/common/academicYearFilter";
import { StaffListView } from "types/users/userListViews.types";
import { danger } from "ui-kit/common/colors";
import styled, { css } from "styled-components";


const StaffWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const removeButtonStylecss = css`
  font-size: 1.5rem;
  background-color: unset;
  border: medium none;
  margin: 0px;
  padding: 0px;
  color: rgb(153, 153, 153);
  cursor: pointer;
  line-height: 1rem;
  margin-bottom: ${Spacing.Small}px;
  &:hover {
    color: ${danger};
  }
`;


const AdminDeclarationFilter: React.FC = () => {
  
  const { filter } = useAppSelector(state => state.adminDeclarationsFilter);

  const handleSchoolChange = (schoolId: number) => {
    declarationsOfInterestActions.filterBySchool(schoolId);
  };

  const handleAcademicYearChange = (academicYearId: number) => {
    declarationsOfInterestActions.filterByAcadmicYear(academicYearId);
  };

  const handleSearchTermChange = (searchTerm: string) => {
    declarationsOfInterestActions.filterBySearchTerm(searchTerm);
  };

  const handlerSubmitterChange = (submitter: StaffListView) => {
    declarationsOfInterestActions.filterBySubmitter(submitter);
  };

  return (
    <>
      <ActionBar>
        <ActionBar.Filter>
        
          <ActionBar.FilterItem>
            <SchoolDropdown
              onChange={handleSchoolChange}
              initialValue={filter.schoolId}
              includeAllOption
              includeTrust
              fluid
            />
          </ActionBar.FilterItem>

          <ActionBar.FilterItem>
            <AcademicYearFilter
              value={filter.academicYearId}
              onChange={value => handleAcademicYearChange(value)}
              fluid
            />
          </ActionBar.FilterItem>

          <ActionBar.FilterItem>
            <StaffWrapper>
              <StaffSelector
                user={filter.submitter}
                onChange={handlerSubmitterChange}
                onCancel={() => declarationsOfInterestActions.filterBySubmitter(null)}
                buttonText={"⨯"}
                buttonCss={removeButtonStylecss}
              />
            </StaffWrapper>
          </ActionBar.FilterItem>

          <ActionBar.FilterItem>
            <TextInput
              icon="search"
              type={TextInputType.String}
              onChange={handleSearchTermChange}
              placeholder="Search Submissions..."
              value={filter.searchTerm}
              debounce
              fluid
            />
          </ActionBar.FilterItem>

        </ActionBar.Filter>
      </ActionBar>
    </>
  );
};

export default AdminDeclarationFilter;
