import queueMessageReducer from "./queueMessageReducer";
import userEmailsReducer from "./userEmailsReducer";
import userSmsMessagesReducer from "./userSmsMessagesReducer";

const mailout = {
  queueMessage: queueMessageReducer,
  userEmails: userEmailsReducer,
  userSmsMessages: userSmsMessagesReducer,
};

export default mailout;
