import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import { DetentionStudentView } from "areas/behaviour/types/detentionRegisterResponse.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


interface IDetentionState {
  selectedDetention: DetentionStudentView;
  loading: boolean;
  error: IBeehiveError;
  attendedDetentionError: IBeehiveError;
  missedDetentionError: IBeehiveError;
  cancelDetentionError: IBeehiveError;
  rescheduleDetentionError: IBeehiveError;
}

const INITIAL_STATE: IDetentionState = {
  selectedDetention: null,
  loading: false,
  error: null,
  attendedDetentionError: null,
  missedDetentionError: null,
  cancelDetentionError: null,
  rescheduleDetentionError: null,
};

const {
  ATTENDEDDETENTION,
  MISSEDDETENTION,
  CANCELDETENTION,
  RESCHEDULEDETENTION,
  SELECTDETENTION,
} = detentionActions.types;

const { GETBEHAVIOUR } = behaviourActions.types;

const { VALIDATEDETENTIONDATE } = detentionActions.types;


const detentionReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IDetentionState => {

  switch (action.type) {
    case ATTENDEDDETENTION.START:
      return { ...state, loading: true, attendedDetentionError: null };

    case ATTENDEDDETENTION.SUCCESS:
      return { ...state, loading: false };

    case ATTENDEDDETENTION.FAILED:
      return {
        ...state,
        loading: false,
        attendedDetentionError: action.payload,
      };

    case MISSEDDETENTION.START:
      return { ...state, loading: true, missedDetentionError: null };

    case MISSEDDETENTION.SUCCESS:
      return { ...state, loading: false };

    case MISSEDDETENTION.FAILED:
      return {
        ...state,
        loading: false,
        missedDetentionError: action.payload,
      };

    case CANCELDETENTION.START:
      return { ...state, loading: true, cancelDetentionError: null };

    case CANCELDETENTION.SUCCESS:
      return { ...state, loading: false };

    case CANCELDETENTION.FAILED:
      return {
        ...state,
        loading: false,
        cancelDetentionError: action.payload,
      };

    case RESCHEDULEDETENTION.START:
      return { ...state, rescheduleDetentionError: null, loading: true };

    case RESCHEDULEDETENTION.SUCCESS:
      return { ...state, loading: false };

    case RESCHEDULEDETENTION.FAILED:
      return {
        ...state,
        rescheduleDetentionError: action.payload,
        loading: false,
      };

    case SELECTDETENTION:
      return { ...state, selectedDetention: action.payload, error: null };

    case GETBEHAVIOUR.START:
      return {
        ...state,
        error: null,
        attendedDetentionError: null,
        missedDetentionError: null,
        cancelDetentionError: null,
        rescheduleDetentionError: null,
      };

    case VALIDATEDETENTIONDATE.SUCCESS:
      return { ...state, rescheduleDetentionError: null };

    default:
      return state;
  }
};

export default detentionReducer;
