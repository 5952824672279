import houseActions from "../../actions/houses/houseActions";

const INITIAL_STATE = {
  loading: false,
  error: null,
  house: null,
};

const houseSummaryReducer = (state = INITIAL_STATE, action) => {
  const { HOUSES_GETSUMMARY } = houseActions.types;

  switch (action.type) {
    case HOUSES_GETSUMMARY.START:
      return { ...state, loading: true, error: null };

    case HOUSES_GETSUMMARY.SUCCESS:
      var newState = { ...state, house: action.payload, loading: false };
      return newState;

    case HOUSES_GETSUMMARY.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default houseSummaryReducer;
