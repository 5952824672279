import React, { useEffect, useState } from "react";
import DetentionList from "./detentionList";
import { useSelector } from "react-redux";
import { Loader, Size } from "ui-kit";
import DetentionListFilters from "./detentionListFilters";
import { Constants } from "configuration";
import config from "configuration/config";
import studentDetentionHistoryActions from "areas/behaviour/actions/detentions/studentDetentionHistoryActions";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import flyoutActions from "actions/ui/flyouts";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import RescheduleDetentionModal from "areas/behaviour/components/detentions/reschedule/rescheduleDetentionModal";
import DetentionDetailsFlyout from "areas/behaviour/components/detentions/detentionDetailsFlyout";
import { useAppSelector } from "reducers/hooks";
import { DetentionDetailView, DetentionStudentView } from "areas/behaviour/types/detentionRegisterResponse.types";


interface IDetentionListWrapperProps {
  studentId: string;
}


const DetentionListWrapper: React.FC<IDetentionListWrapperProps> = ({ studentId }) => {

  const { detentionHistory, loading, error, refresh } = useAppSelector(
    state => state.studentDetentionHistory
  );

  const [showCancelledDetentions, setShowCancelledDetentions] = useState<boolean>(false);
  const [academicYearId, setAcademicYearId] = useState<number>(
    config.academicYear.current
  );
  const [openRescheduleModal, setOpenRescheduleModal] = useState<boolean>(false);
  const [detention, setDetention] = useState<DetentionStudentView>(null);

  useEffect(() => {
    studentDetentionHistoryActions.getStudentDetentionHistory(
      studentId,
      {
        showCancelledDetentions,
        academicYearId
      }
    );
  }, [showCancelledDetentions, academicYearId]);

  useEffect(() => {
    if (refresh) {
      studentDetentionHistoryActions.getStudentDetentionHistory(
        studentId,
        {
          showCancelledDetentions,
          academicYearId
        }
      );
    }
  }, [refresh]);

  const markPresent = (behaviourRewardId: number, detentionId: number) => {
    detentionActions.attendedDetention(
      studentId,
      behaviourRewardId,
      detentionId,
      {
        studentId: studentId,
        behaviourId: behaviourRewardId,
        detentionId: detentionId,
        updateNote: null,
        cancelNote: null,
      }
    );
  };

  const markNotPresent = (behaviourRewardId: number, detentionId: number) => {
    detentionActions.missedDetention(
      studentId,
      behaviourRewardId,
      detentionId,
      {
        studentId: studentId,
        behaviourId: behaviourRewardId,
        detentionId: detentionId,
        updateNote: null,
        cancelNote: null,
      }
    );
  };

  const onClickRescheduleDetention = (detention: DetentionDetailView) => {
    setDetention({ 
      ...detention, 
      student: {
        id: studentId,
        title: null,
        firstName: null,
        initials: null,
        lastName: null,
        admissionStatus: null,
        simsId: null,
        hasOutstandingDetentions: null
      },
      tutorGroup: null,
      yearGroup: null
    });
    setOpenRescheduleModal(true);
  };

  const onClickDetention = (detention: DetentionDetailView) => {
    flyoutActions.openFlyout(Constants.FLYOUTS.DETENTIONDETAILS);
    detentionActions.selectDetention(detention as DetentionStudentView);
  };

  return (
    <>
      <DetentionListFilters
        showCancelledDetentions={showCancelledDetentions}
        handleShowCancelledDetentionsChange={() =>
          setShowCancelledDetentions(!showCancelledDetentions)
        }
        academicYearId={academicYearId}
        handleAcademicYearChange={value => setAcademicYearId(value)}
      />

      {loading ? (
        <Loader size={Size.Medium} fluid />
      ) : error ? (
        <ApiExceptionMessage error={error} />
      ) : (
        <DetentionList
          detentionHistory={detentionHistory}
          markPresent={markPresent}
          markNotPresent={markNotPresent}
          handleRescheduleDetention={onClickRescheduleDetention}
          handleClickDetention={onClickDetention}
        />
      )}

      <RescheduleDetentionModal
        open={openRescheduleModal}
        setOpen={setOpenRescheduleModal}
        detention={detention}
      />

      <DetentionDetailsFlyout studentId={studentId} />
    </>
  );
};

export default DetentionListWrapper;
