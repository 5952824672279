import { StaffView, UserName } from "sharedComponents/common";
import ContextMenu from "ui-kit/composite/contextMenu";
import {
  getGroupName,
  FeedItemContent,
  DateAndStudentName,
  FeedItemDetails,
  FeedItemActionButtons,
} from "../activityFeedItemComponents";

const LeftLessonActivity = ({
  activity,
  userIsAuthorised,
  handleOnClickDelete,
  displayStudentName,
}) => {
  return (
    <>
      <FeedItemContent>
        <DateAndStudentName
          displayStudentName={displayStudentName}
          activity={activity}
        />

        <FeedItemDetails>
          Left lesson for <b>{activity.categoryName}</b> recorded by{" "}
          <b>
            <UserName user={activity.createdBy} />
          </b>
          {!displayStudentName && getGroupName(activity)}
          <div className="note-content">{activity.content}</div>
        </FeedItemDetails>
      </FeedItemContent>

      <StaffView>
        {userIsAuthorised() && (
          <FeedItemActionButtons>
            <ContextMenu>
              <ContextMenu.Item
                text="Delete"
                onClick={() => handleOnClickDelete(activity)}
              />
            </ContextMenu>
          </FeedItemActionButtons>
        )}
      </StaffView>
    </>
  );
};

export default LeftLessonActivity;
