import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import { Dropdown } from "ui-kit";
import { UserName } from "sharedComponents/common";
import formStaffActions from "areas/forms/actions/formStaffActions";
import { RootState } from "reducers/store";


interface IFinanceUserDropdownProps {
  initialValue?: string;
  onChange?: (userId: string) => void;
  includeAllOption?: boolean;
  disabled?: boolean;
  fluid?: boolean;
}

interface financeUserOption {
  key: string | number;
  label: JSX.Element | string;
  value: string;
}

const FinanceUserDropdown: React.FC<IFinanceUserDropdownProps> = ({
  initialValue,
  onChange,
  includeAllOption,
  disabled,
  fluid
}) => {
  
  const { staff, loading, error } = useSelector((state: RootState) => state.formStaff);

  const allOption: financeUserOption = { 
    key: -1, 
    value: "-1", 
    label: "All Finance Staff" 
  };
  const [options, setOptions] = useState<financeUserOption[]>(includeAllOption ? [allOption] : []);
  const [selectedValue, setSelectedValue] = useState<string | null>(includeAllOption ? allOption.value : null);

  useEffect(() => {
    if (arrays.isEmpty(staff)) {
      formStaffActions.getFinanceUsers();
    }
  }, []);

  useEffect(() => {
    if (!arrays.isEmpty(staff)) {
      var mappedStaff: financeUserOption[] = staff.map(s => ({
        key: s.id,
        label: <UserName user={s.data} />,
        value: s.id,
      }));

      setOptions(includeAllOption ? [allOption, ...mappedStaff] : mappedStaff);
    }
  }, [staff]);

  useEffect(() => {
    if (initialValue) {
      setSelectedValue(initialValue);
    }
  }, [initialValue]);

  const handleChange = (value: string) => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <>
    <Dropdown
      placeholder="Finance Staff Member"
      items={options}
      loading={loading}
      error={error}
      onChange={handleChange}
      value={selectedValue}
      disabled={disabled}
      fluid={fluid}
    />
    </>
  );
};
export default FinanceUserDropdown;
