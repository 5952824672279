import Activity from "areas/planner/components/activityFeed/nonStaffView";
import Administration from "areas/administration/components";
import Analytics from "areas/analytics/components";
import Calendar from "areas/calendar/components";
import Catering from "areas/payments/components/catering";
import Consent from "areas/administration/components/consent";
import Classes from "areas/planner/components/classes";
import Feed from "areas/feed/components";
import Forms from "areas/forms/components";
import Groups from "areas/planner/components/groups";
import Leave from "areas/humanResources/components/leave";
import StaffLeave from "areas/humanResources/components/leave/administration/staff";
import Houses from "areas/planner/components/houses";
import Filing from "areas/filing/components";
import Links from "areas/links";
import Mailout from "areas/mailout/components";
import Newsdesk from "areas/news/components/newsdesk";
import PerformanceManagement from "areas/humanResources/components/pm";
import Store from "areas/payments/components/store";
import Shopkeeper from "areas/payments/components/shopkeeper";
import Smartcard from "areas/payments/components/smartcard";
import Timetable from "areas/planner/components/timetable";
import Behaviour from "areas/behaviour/components";
import Checkout from "areas/payments/components/store/checkout/checkout";
import CheckoutSuccess from "areas/payments/components/store/checkout/success";
import Profile from "areas/administration/components/profile";
import NotificationPreferences from "areas/administration/components/profile/notificationPreferences";
import Security from "areas/administration/components/profile/security";
import ProfileDataUpdate from "areas/administration/components/profile/profileDataUpdate";
import CheckPassword from "areas/administration/components/profile/checkPassword";
import ChangePassword from "areas/administration/components/profile/changePassword";
// import LoadingScreen from "./loadingScreen";
import StudentRecord from "areas/planner/components/studentRecord";
import Magazine from "areas/news/components/blog/magazine";
// import MagazineCategory from "../areas/blog/magazineCategory";
import MagazineArticle from "areas/news/components/blog/article/article";
import Documents from "areas/administration/components/documents";
import DocumentsAdmin from "areas/administration/components/documents/admin";
import DocumentsUpload from "areas/administration/components/documents/admin/upload";
import DocumentsViewer from "areas/administration/components/documents/admin/viewer";
import SendAdmin from "areas/send/components";
import SendProvider from "areas/send/components/providers/provider";
import SendProvision from "areas/send/components/providers/provisions/provision";
import SendProvisionGroup from "areas/send/components/providers/provisions/provisionGroups/provisionGroup";
import SenParentView from "areas/send/components/parentView";
import Matriculation from "areas/matriculation/components";
import routes from "configuration/routes";
import { Constants } from "configuration";
import {
  groupRoutes,
  assignmentRoutes,
  activityRoutes,
} from "areas/planner/plannerRoutes";
import Assignments from "areas/planner/components/assignments";
import { analyticsRoutes } from "areas/analytics/analyticsRoutes";
import {
  adminRoutes,
  consentRoutes,
  documentRoutes,
} from "areas/administration/adminRoutes";
import { magazineRoutes, newsdeskRoutes } from "areas/news/newsRoutes";
import { calendarRoutes } from "areas/calendar/calendarRoutes";
import { IRole } from "configuration/constants.types";
import { UserType } from "configuration/constants.enums";
import { behaviourRoutes } from "areas/behaviour/behaviourRoutes";
import { filingRoutes } from "areas/filing/filingRoutes";
import { formsRoutes } from "areas/forms/formsRoutes";
import { leaveRoutes, pmRoutes } from "areas/humanResources/hrRoutes";
import { matriculationRoutes } from "areas/matriculation/matriculationRoutes";
import { UserStartupView } from "types/users/userStartup.types";


type mainRoute = {
  path: string;
  title: string;
  Component: (() => JSX.Element) | React.FC;
  roles: IRole[];
  users: UserType[];
};

const MAIN_ROUTES = (user: UserStartupView): mainRoute[] => [
  {
    path: "/",
    title: "Home",
    Component: Feed,
    roles: [],
    users: [],
  },
  {
    path: activityRoutes.default,
    title: "Rewards and Behaviours",
    Component: Activity,
    roles: [],
    users: [Constants.USER_TYPES.STUDENT, Constants.USER_TYPES.PARENT],
  },
  {
    path: analyticsRoutes.default,
    title: "Analytics",
    Component: Analytics,
    roles: [],
    users: [Constants.USER_TYPES.STAFF],
  },
  {
    path: `${assignmentRoutes.default}/*`,
    title: "Assignments",
    Component: Assignments,
    roles: [],
    users: [],
  },
  {
    path: `${behaviourRoutes.default}/*`,
    title: "Behaviour",
    Component: Behaviour,
    roles: [],
    users: [Constants.USER_TYPES.STAFF],
  },
  {
    path: `${calendarRoutes.default}/*`,
    title: "Events",
    Component: Calendar,
    roles: [],
    users: [],
  },
  {
    path: `${consentRoutes.default}/*`,
    title: "Consent",
    Component: Consent,
    roles: [],
    users: [Constants.USER_TYPES.STAFF, Constants.USER_TYPES.PARENT],
  },
  {
    path: routes.classes.default,
    title: "Classes",
    Component: Classes,
    roles: [],
    users: [Constants.USER_TYPES.STUDENT],
  },
  {
    path: `${formsRoutes.default}/*`,
    title: "Forms",
    Component: Forms,
    roles: [],
    users: [Constants.USER_TYPES.STAFF],
  },
  {
    path: `${groupRoutes.default}/*`,
    title: "Classes",
    Component: Groups,
    roles: [],
    users: [Constants.USER_TYPES.STAFF],
  },
  {
    path: leaveRoutes.default,
    title: "Holiday & Leave",
    Component: Leave,
    roles: [],
    users: [Constants.USER_TYPES.STAFF],
  },
  {
    path: leaveRoutes.staffLeave,
    title: "Holiday & Leave",
    Component: StaffLeave,
    roles: [Constants.ROLES.HR_ADMINISTRATOR],
    users: [Constants.USER_TYPES.STAFF],
  },
  {
    path: routes.houses.default,
    title: "Houses",
    Component: Houses,
    roles: [],
    users: [],
  },
  {
    path: routes.links.default,
    title: "Links",
    Component: Links,
    roles: [],
    users: [],
  },
  {
    path: filingRoutes.default,
    title: "Filing",
    Component: Filing,
    roles: [],
    users: [Constants.USER_TYPES.STAFF],
  },
  {
    path: routes.mailout.default,
    title: "Mailout",
    Component: Mailout,
    roles: [],
    users: [Constants.USER_TYPES.STAFF],
  },
  {
    path: `${pmRoutes.default}/*`,
    title:
      user?.staffType == Constants.STAFF_TYPES.ASSOCIATE.name
        ? "Appraisals"
        : "QLT",
    Component: PerformanceManagement,
    roles: [],
    users: [Constants.USER_TYPES.STAFF],
  },
  {
    path: `${routes.shopkeeper.default}/*`,
    title: "Shopkeeper",
    Component: Shopkeeper,
    roles: [
      Constants.ROLES.FINANCE_ADMIN,
      Constants.ROLES.FINANCE_TEAMLEADER,
      Constants.ROLES.FINANCE_USER,
      Constants.ROLES.DEVELOPMENT,
      Constants.ROLES.FINANCE_BUYER,
      Constants.ROLES.SHOPKEEPER_ADMINISTRATOR,
      Constants.ROLES.SHOPKEEPER_USER
    ],
    users: [Constants.USER_TYPES.STAFF],
  },
  {
    path: `${newsdeskRoutes.default}/*`,
    title: "Newsdesk",
    Component: Newsdesk,
    roles: [Constants.ROLES.NEWS_ADMINISTRATOR, Constants.ROLES.DEVELOPMENT],
    users: [],
  },
  {
    path: routes.smartcard.default,
    title: "Smartcard",
    Component: Smartcard,
    roles: [],
    users: [],
  },
  {
    path: `${routes.store.default}/*`,
    title: "Store",
    Component: Store,
    roles: [],
    users: [],
  },
  {
    path: routes.timetable.default,
    title: "Timetable",
    Component: Timetable,
    roles: [],
    users: [],
  },
  {
    path: routes.catering.default,
    title: "Catering",
    Component: Catering,
    roles: [Constants.ROLES.CATERING_MANAGER, Constants.ROLES.DEVELOPMENT],
    users: [Constants.USER_TYPES.STAFF],
  },
  // catering register is commented out in original Beehive UI
  //   {
  //     path: routes.catering.register,
  //     title: "Catering",
  //     Component: Register,
  //     roles: [],
  //     users: [Constants.USER_TYPES.STAFF],
  //   },
  {
    path: routes.checkout.default,
    title: "Store",
    Component: Checkout,
    roles: [],
    users: [],
  },
  {
    path: routes.checkout.success,
    title: "",
    Component: CheckoutSuccess,
    roles: [],
    users: [],
  },
  {
    path: `${adminRoutes.default}/*`,
    title: "Administration",
    Component: Administration,
    roles: [
      Constants.ROLES.DEVELOPMENT,
      Constants.ROLES.HR_ADMINISTRATOR,
      Constants.ROLES.IT_USER,
      Constants.ROLES.IT_ADMINISTRATOR,
      Constants.ROLES.STUDENTPROFILE_ADMINISTRATOR,
      Constants.ROLES.SYNCDATA_ADMINISTRATOR

    ],
    users: [],
  },
  {
    path: documentRoutes.default,
    title: "Documents",
    Component: Documents,
    roles: [],
    users: [Constants.USER_TYPES.STUDENT],
  },
  {
    path: `${documentRoutes.admin}/*`,
    title: "Documents",
    Component: DocumentsAdmin,
    roles: [Constants.ROLES.EXAMINATIONS_ADMINISTRATOR],
    users: [Constants.USER_TYPES.STAFF],
  },
  {
    path: documentRoutes.upload,
    title: "Documents",
    Component: DocumentsUpload,
    roles: [Constants.ROLES.EXAMINATIONS_ADMINISTRATOR],
    users: [Constants.USER_TYPES.STAFF],
  },
  {
    path: documentRoutes.viewer,
    title: "Documents",
    Component: DocumentsViewer,
    roles: [Constants.ROLES.EXAMINATIONS_ADMINISTRATOR],
    users: [Constants.USER_TYPES.STAFF],
  },
  {
    path: routes.profile.details,
    title: "Your Account",
    Component: Profile,
    roles: [],
    users: [],
  },
  {
    path: routes.profile.security,
    title: "Your Account",
    Component: Security,
    roles: [],
    users: [],
  },
  {
    path: routes.passwords.private.changePassword,
    title: "Your Account",
    Component: ChangePassword,
    roles: [],
    users: [Constants.USER_TYPES.PARENT],
  },
  {
    path: routes.profile.notifications,
    title: "Your Account",
    Component: NotificationPreferences,
    roles: [],
    users: [],
  },
  {
    path: routes.passwords.private.checkPassword,
    title: "Your Account",
    Component: CheckPassword,
    roles: [],
    users: [],
  },
  {
    path: routes.profile.update,
    title: "Your Account",
    Component: ProfileDataUpdate,
    roles: [],
    users: [],
  },
  {
    path: routes.profile.updateUserProfile,
    title: "Your Account",
    Component: ProfileDataUpdate,
    roles: [],
    users: [],
  },
  {
    path: routes.planner.student,
    title: "",
    Component: StudentRecord,
    roles: [],
    users: [Constants.USER_TYPES.STAFF],
  },
  {
    path: `${magazineRoutes.default}/*`,
    title: "",
    Component: Magazine,
    roles: [],
    users: [],
  },
  //   {
  //     path: routes.magazine.category,
  //     title: "",
  //     Component: MagazineCategory,
  //     roles: [],
  //     users: [],
  //   },
  {
    path: magazineRoutes.article,
    title: "",
    Component: MagazineArticle,
    roles: [],
    users: [],
  },
  {
    path: routes.send.default,
    title: "SEND",
    Component: SendAdmin,
    roles: [
      Constants.ROLES.SEN_ADMINISTRATOR,
      Constants.ROLES.SEN_COORDINATOR,
      Constants.ROLES.LEADERSHIP,
      Constants.ROLES.TRUST_LEADERSHIP,
    ],
    users: [Constants.USER_TYPES.STAFF],
  },
  {
    path: routes.send.provider,
    title: "SEND",
    Component: SendProvider,
    roles: [
      Constants.ROLES.SEN_ADMINISTRATOR,
      Constants.ROLES.SEN_COORDINATOR,
      Constants.ROLES.LEADERSHIP,
      Constants.ROLES.TRUST_LEADERSHIP,
    ],
    users: [Constants.USER_TYPES.STAFF],
  },
  {
    path: routes.send.provision,
    title: "SEND",
    Component: SendProvision,
    roles: [
      Constants.ROLES.SEN_ADMINISTRATOR,
      Constants.ROLES.SEN_COORDINATOR,
      Constants.ROLES.LEADERSHIP,
      Constants.ROLES.TRUST_LEADERSHIP,
    ],
    users: [Constants.USER_TYPES.STAFF],
  },
  {
    path: routes.send.provisionGroup,
    title: "SEND",
    Component: SendProvisionGroup,
    roles: [
      Constants.ROLES.SEN_ADMINISTRATOR,
      Constants.ROLES.SEN_COORDINATOR,
      Constants.ROLES.LEADERSHIP,
      Constants.ROLES.TRUST_LEADERSHIP,
    ],
    users: [Constants.USER_TYPES.STAFF],
  },
  {
    path: routes.send.parent,
    title: "SEND",
    Component: SenParentView,
    roles: [],
    users: [Constants.USER_TYPES.PARENT],
  },
  {
    path: `${matriculationRoutes.default}/*`,
    title: "Matriculation",
    Component: Matriculation,
    roles: [
      Constants.ROLES.MATRICULATION_ADMINISTRATOR,
      Constants.ROLES.MATRICULATION_USER,
      Constants.ROLES.IT_ADMINISTRATOR,
      Constants.ROLES.IT_USER,
    ],
    users: [Constants.USER_TYPES.STAFF],
  },
];

export default MAIN_ROUTES;
