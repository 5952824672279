import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { CloneAssignmentCommand } from "areas/planner/types/assignmentRequests.types";
import { SaveAssignmentCommand } from "areas/planner/types/assignments/assignmentRequest.types";
import store from "reducers/store";
import client from "services/client";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { AssignmentDetailView } from "types/planner/assignments.types";

const builder = new actionTypeBuilder("assignmenteditor");

const types = {
  NEW: "new",
  EDIT: builder.build("edit"),
  CANCEL: "cancel",
  CREATE: builder.build("create"),
  UPDATE: builder.build("update"),
  CLONE: builder.build("clone"),
};

const newAssignment = () => {
  store.dispatch<IBeehiveAction<null>>({
    type: types.NEW,
  });
};

const editAssignment = (userId: string, assignmentId: number, callback?: callbackType<AssignmentDetailView>) =>
  action<AssignmentDetailView>(
    () => client.get(`planner/users/${userId}/assignments/${assignmentId}`),
    types.EDIT,
    callback
  );

const cancelEditing = () => {
  store.dispatch<IBeehiveAction<null>>({
    type: types.CANCEL,
  });
};

const createAssignment = (data: SaveAssignmentCommand, callback?: callbackType<AssignmentDetailView>) =>
  action<AssignmentDetailView>(
    () => client.post(`planner/assignments`, data),
    types.CREATE,
    callback
  );

const updateAssignment = (data: SaveAssignmentCommand, callback?: callbackType<AssignmentDetailView>) =>
  action<AssignmentDetailView>(
    () => client.post(`planner/assignments/${data.id}`, data),
    types.UPDATE,
    callback
  );
  
const cloneAssignment = (data: CloneAssignmentCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`planner/assignments/${data.assignmentId}/clone`, data),
    types.CLONE,
    callback
  );

const assignmentEditorActions = {
  types,
  newAssignment,
  editAssignment,
  cancelEditing,
  createAssignment,
  updateAssignment,
  cloneAssignment
};

export default assignmentEditorActions;
