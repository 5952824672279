import managedMoveReducer from "./managedMoveReducer";
import managedMovesReducer from "./managedMovesReducer";
import managedMovesSummaryReducer from "./managedMovesSummaryReducer";
import studentManagedMovesReducer from "./studentManagedMovesReducer";

const managedMovesReducers = {
  managedMove: managedMoveReducer,
  managedMoves: managedMovesReducer,
  managedMovesSummary: managedMovesSummaryReducer,
  studentManagedMoves: studentManagedMovesReducer,
};

export default managedMovesReducers;
