import React, { useState } from "react";
import {
  StructuredList,
  Button,
  HtmlText,
  Swatches,
  Card,
  Title,
  TitleSize,
  ActionBar,
  Chip,
  Size
} from "ui-kit";
import { useSelector } from "react-redux";
import moment from "moment";
import Moment from "react-moment";
import { arrays } from "utils";
import { useNavigate } from "react-router-dom";
import { Constants } from "configuration";
import styled from "styled-components";
import { assignmentRoutes, groupRoutes } from "areas/planner/plannerRoutes";
import assignmentPublishActions from "areas/planner/actions/assignments/assignmentPublishActions";
import Subheader from "sharedComponents/layout/header/subheader";
import {
  FileList,
  GroupList,
  LinkList,
  PublishStatusChip,
  StatusView,
  Avatar,
} from "sharedComponents/common";
import { Link } from "react-router-dom";
import { studentRoutes } from "../../plannerRoutes";
import { RootState } from "reducers/store";
import { AssignmentDetailView } from "types/planner/assignments.types";
import CloneAssignmentModal from "./cloneAssignmentModal";
import { GroupType } from "types/users/userGroups.types";


const Wrapper = styled.div`
  .card .title {
    margin-bottom: 0;
  }
`;


interface IStaffAssignmentViewProps {
  assignment: AssignmentDetailView;
  publishing?: boolean;
}


const StaffAssignmentView: React.FC<IStaffAssignmentViewProps> = ({ assignment, publishing }) => {
  
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const [cloneModalOpen, setCloneModalOpen] = useState<boolean>(false);

  const handleEdit = () => {
    navigate(assignmentRoutes.editAssignmentPath(assignment.id));
  };

  const handlePublish = () => {
    assignmentPublishActions.publishAssignment(assignment.id);
  };

  const handleUnpublish = () => {
    assignmentPublishActions.unpublishAssignment(assignment.id);
  };

  const handleDelete = () => {};

  const handleClone = () => {
    setCloneModalOpen(true);
  }

  return (
    <>

    <Wrapper>
      <Subheader>
        <Title
          size={TitleSize.H2}
          text="Assignments"
          sub={assignment?.title}
          right={
            <Button
              color={Swatches.Primary}
              text="Clone Assignment"
              onClick={handleClone}
              size={Size.Small}
            />
          }
        />
      </Subheader>

      {/* <Tabs> */}
      {/* <Tabs.Pane name="Details" label="Details"> */}
      <Card>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Set For">
              <GroupList
                groups={assignment.groups}
                limit={3}
                onGroupClick={group =>
                  navigate(groupRoutes.getGroupPath(group.id, group.type))
                }
                showSchoolIcon={user.schools?.length > 0}
              />
              { assignment?.students?.map(student => 
                <Link to={`${studentRoutes.getStudentPath(student.id)}`}>
                  {`${student.firstName} ${student.lastName}`}<br/>
                </Link>
              )}
            </StructuredList.Item>
            <StructuredList.Item name="Deadline">
              {assignment.deadline !== null && (
                <>
                  <Moment
                    date={assignment.deadline}
                    format="dddd, Do MMMM YYYY"
                  />{" "}
                </>
              )}
              {assignment.isExpired && (
                <Chip text="Expired" colorSwatch={Swatches.Low} />
              )}
            </StructuredList.Item>
            <StructuredList.Item name="Set On">
              <Moment
                date={assignment.setOn}
                format="dddd, Do MMMM YYYY"
              ></Moment>
            </StructuredList.Item>
            <StructuredList.Item name="Set By">
              <Avatar user={assignment.setBy} />
            </StructuredList.Item>
            {assignment.visibleFrom && (
              <StructuredList.Item name="Visible From">
                <Moment
                  date={assignment.visibleFrom}
                  format="dddd, Do MMMM YYYY"
                ></Moment>
              </StructuredList.Item>
            )}

            <StructuredList.Item name="Publish Status">
              {assignment.isScheduled ? (
                <Chip
                  text="Scheduled"
                  colorSwatch={Swatches.Warning}
                  tooltip="Scheduled Assignment"
                  tooltipSub={`This assignment is published but not visible to students until ${moment(
                    assignment.visibleFrom
                  ).format("DD/MM/YYYY")}`}
                />
              ) : (
                <PublishStatusChip status={assignment.publishStatus} />
              )}
            </StructuredList.Item>
            <StructuredList.Item name="Suggested Timescale">
              {
                Constants.SUGGESTED_TIMESCALES.find(
                  x => x.value == assignment.suggestedTimescale?.toString()
                )?.text
              }
            </StructuredList.Item>
            {/* <StructuredList.Item name="Details">
              <HtmlText html={assignment.details} />
            </StructuredList.Item> */}
          </StructuredList>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Details">
              <HtmlText html={assignment.details} />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      {!arrays.isEmpty(assignment.files) && (
        <>
          <Card>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Attachments">
                  <FileList>
                    {assignment.files?.map((file, index) => (
                      <FileList.Item key={index} file={file} clickToDownload />
                    ))}
                  </FileList>
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
        </>
      )}

      {!arrays.isEmpty(assignment.links) && (
        <>
          <Card>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Links">
                  <LinkList>
                    {assignment.links.map((link, index) => (
                      <LinkList.Item key={index} link={link} />
                    ))}
                  </LinkList>
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
        </>
      )}
      {user.id === assignment.setBy.id && (
        <ActionBar>
          <Button text="Edit" onClick={handleEdit} color={Swatches.Success} />
          <StatusView status={assignment.publishStatus}>
            <StatusView.Item status={Constants.PUBLISH_STATUS.LIVE.value}>
              <Button
                text="Unpublish"
                onClick={handleUnpublish}
                color={Swatches.Low}
                working={publishing}
              />
            </StatusView.Item>
            <StatusView.Item
              status={Constants.PUBLISH_STATUS.LIVE.value}
              inverse
            >
              <Button
                text="Publish"
                onClick={handlePublish}
                color={Swatches.Primary}
                working={publishing}
              />
            </StatusView.Item>
          </StatusView>
          {/* <Right>
          <Button
            text="Delete"
            onClick={handleDelete}
            color={Swatches.Danger}
          />
        </Right> */}
        </ActionBar>
      )}
      {/* </Tabs.Pane> */}
      {/* <Tabs.Pane name="Submissions" label="Submissions">
          <AssignmentSubmissionsWrapper assignment={assignment} />
        </Tabs.Pane> */}
      {/* <Tabs.Pane name="Statistics" label="Statistics">
          <Card>
            <Card.Body></Card.Body>
          </Card>
        </Tabs.Pane> */}
      {/* </Tabs> */}
    </Wrapper>

    <CloneAssignmentModal 
      assignment={assignment}
      open={cloneModalOpen}
      onSaved={() => setCloneModalOpen(false)}
      onCancel={() => setCloneModalOpen(false)}
    />

    </>
  );
};

export default StaffAssignmentView;
