import styled from "styled-components";
import { 
  Icon, 
  Label, 
  Size, 
  Currency, 
  Spacing, 
  List, 
  Swatches 
} from "ui-kit";
import storeActions from "areas/payments/actions/storeActions";
import { BasketItemView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


const BasketItemPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  .label {
    margin-right: ${Spacing.Large}px;
  }
  .icon {
    margin-bottom: 5px;
  }
  .icon:hover {
    display: block;
    color: ${Swatches.Danger.swatch};

    i {
      color: white;
    }
  }
`;


interface IBasketItemProps {
  userId: string;
  item: BasketItemView;
  onDelete?: (item: BasketItemView) => void;
  working?: boolean;
}


const BasketItem: React.FC<IBasketItemProps> = ({ userId, item, onDelete, working }) => {

  const handleItemDelete = () => {
    if (!working) {
      storeActions.removeItemFromBasket(userId, item.id, () => {
        onDelete?.(item);
      });
    }
  };

  return (
    <List.Item
      className={`basket-item`}
      text={`${item.name} ${item.quantity > 1 ? ` x ${item.quantity}` : ``}`}
      sub={`for ${item.recipient.firstName} ${item.recipient.lastName}`}
      right={
        <BasketItemPriceWrapper>
          <Label bold className="basket-item-price">
            <Currency value={item.total} />
          </Label>
          <Icon
            value="times"
            onClick={handleItemDelete}
            size={Size.Small}
            tooltip="Remove this item from your basket"
          />
        </BasketItemPriceWrapper>
      }
    ></List.Item>
  );
};

export default BasketItem;
