import action from "actions/action";
import { ToastService } from "ui-kit";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { Constants } from "configuration";
import store from "reducers/store";
import { CategoriesFilter, CreateRewardsCommand, RevokeRewardCommand, UpdateRewardCommand } from "areas/behaviour/types/behaviourRequest.types";
import { callbackType } from "types/common/action.types";
import { BehaviourCategoryGroupByListView, BehaviourIntialActionView, SchoolBehaviourCodeListView } from "areas/behaviour/types/behaviourResponses.types";
import { RewardView } from "areas/behaviour/types/reward/rewardResponse.types";
import { GroupType } from "types/planner/groups.types";


const builder = new actionTypeBuilder("rewards");

const types = {
  GETREWARDCATEGORIES: builder.build("getrewardcategories"),
  GETREWARDCODES: builder.build("getrewardcodes"),
  GETALLREWARDCATEGORIES: builder.build("getallrewardcategories"),
  GETALLREWARDCODES: builder.build("getallrewardcodes"),
  ADDREWARDS: builder.build("addrewards"),
  GETREWARD: builder.build("getreward"),
  UPDATEREWARD: builder.build("updatereward"),
  REVOKEREWARD: builder.build("revokereward"),
  GETINITIALREWARDACTIONS: builder.build("getinitialrewardactions"),
  ADDREWARDFORMULTIPLE: builder.build("addrewardformultiple"),
  ADDMULTIPPLEREWARDSFORSTUDENT: builder.build("addmultiplerewardsforstudent"),
  SETREWARDSCHOOLID: "setrewardschoolid",
};


const getRewardCategories = (
  schoolId: number, 
  {
    isLegacy, 
    canBeAwarded
  }: CategoriesFilter, 
  onSuccess?: callbackType<BehaviourCategoryGroupByListView[]>
) => {
  return action<BehaviourCategoryGroupByListView[]>(
    () =>
      client.get(
        `planner/schools/${schoolId}/rewardCategories?isLegacy=${isLegacy}&canBeAwarded=${canBeAwarded}`
      ),
    types.GETREWARDCATEGORIES,
    onSuccess
  );
};


const getAllRewardCategories = (
  schoolId: number, 
  onSuccess?: callbackType<BehaviourCategoryGroupByListView[]>
) => {
  return action<BehaviourCategoryGroupByListView[]>(
    () => client.get(`planner/schools/${schoolId}/rewardCategories`),
    types.GETALLREWARDCATEGORIES,
    onSuccess
  );
};


const getRewardCodes = (
  schoolId: number, 
  onSuccess?: callbackType<SchoolBehaviourCodeListView[]>
) => {
  return action<SchoolBehaviourCodeListView[]>(
    () =>
      client.get(
        `planner/schools/${schoolId}/behaviourRewardCodes?CategoryTypeId=1&isLegacy=${false}`
      ),
    types.GETREWARDCODES,
    onSuccess
  );
};


// For reporting to get all codes including legacy codes
// Saved in a seperate reducer so it doesn't affect planner
const getAllRewardCodes = (
  schoolId: number, 
  onSuccess?: callbackType<SchoolBehaviourCodeListView[]>
) => {
  return action(
    () =>
      client.get(
        `planner/schools/${schoolId}/behaviourRewardCodes?CategoryTypeId=1`
      ),
    types.GETALLREWARDCODES,
    onSuccess
  );
};


const addRewards = (
  studentId: string, 
  rewards: CreateRewardsCommand, 
  onSuccess?: callbackType<null>
) => {
  return action<null>(
    () => client.post(`planner/students/${studentId}/rewards`, rewards),
    types.ADDREWARDS,
    response => {
      ToastService.pop("Successfully added", null, "check-circle");
      onSuccess?.(response);
    }
  );
};


const getReward = (
  studentId: string, 
  rewardId: number, 
  onSuccess?: callbackType<RewardView>
) => {
  return action<RewardView>(
    () => client.get(`planner/students/${studentId}/rewards/${rewardId}`),
    types.GETREWARD,
    onSuccess
  );
};


const updateReward = (
  updateDetails: UpdateRewardCommand, 
  onSuccess?: callbackType<number>
) => {
  return action<number>(
    () =>
      client.post(
        `planner/students/${updateDetails.studentId}/rewards/${updateDetails.id}/update`,
        updateDetails
      ),
    types.UPDATEREWARD,
    response => {
      ToastService.pop("Successfully updated", null, "check-circle");
      onSuccess?.(response);
    }
  );
};


const revokeReward = (
  command: RevokeRewardCommand, 
  onSuccess?: callbackType<number>
) => {
  return action<number>(
    () =>
      client.put(
        `planner/students/${command.studentId}/rewards/${command.rewardId}/revoke`,
        command
      ),
    types.REVOKEREWARD,
    response => {
      ToastService.pop("Successfully revoked", null, "check-circle");
      onSuccess?.(response);
    }
  );
};


const getInitialRewardActions = (onSuccess?: callbackType<BehaviourIntialActionView[]>) => {
  return action<BehaviourIntialActionView[]>(
    () => client.get(`planner/behaviourRewardInitialActions?CategoryTypeId=1`),
    types.GETINITIALREWARDACTIONS,
    onSuccess
  );
};


const addRewardForMultipleStudentsInGroup = (
  groupId: number,
  groupType: GroupType,
  rewards: CreateRewardsCommand,
  onSuccess?: callbackType
) => {
  let url: string;
  switch (groupType) {
    case GroupType.ClassGroup:
      url = `planner/classGroups/${groupId}/rewards`;
      break;

    case GroupType.Custom:
      url = `planner/customGroups/${groupId}/rewards`;
      break;

    case GroupType.TutorGroup:
      url = `planner/tutorGroups/${groupId}/rewards`;
      break;

    case GroupType.Provision:
      url = `planner/provisiongroups/${groupId}/rewards`;
      break;

    default:
      break;
  }

  return action(
    () => client.post(url, rewards),
    types.ADDREWARDFORMULTIPLE,
    response => {
      ToastService.pop("Successfully added", null, "check-circle");
      if (onSuccess) {
        onSuccess(response);
      }
    }
  );
};


const setRewardSchoolId = (schoolId: number) =>
  store.dispatch({
    type: types.SETREWARDSCHOOLID,
    payload: schoolId,
  });


const rewardActions = {
  types,
  getRewardCategories,
  getAllRewardCategories,
  getRewardCodes,
  getAllRewardCodes,
  addRewards,
  getReward,
  updateReward,
  revokeReward,
  getInitialRewardActions,
  addRewardForMultipleStudentsInGroup,
  setRewardSchoolId,
};

export default rewardActions;
