import React from "react";
import styled from "styled-components";
import { 
  Spacing, 
  neutral, 
  BorderRadius, 
  Button, 
  Size, 
  Subtitle 
} from "../index";

const Wrapper = styled.div<{fluid?: boolean, low?: number, fluidControls?: boolean}>`
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: stretch;
  border-radius: ${BorderRadius.Default}px;

  ${({ low }) =>
    low
      ? `
    background: none;
    padding: 0;
  `
      : `background: ${neutral[100]};
      margin-bottom: ${Spacing.Default}px;
      padding: ${Spacing.Default}px;
      border: 1px solid ${neutral[300]};`}

  .dropdown {
    flex-grow: 1;
    flex-basis: 0;
  }

  ${({ fluid }) => fluid && `flex-grow: 1;`}

  .left {
    margin-right: ${Spacing.Default}px;
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .right {
    margin-left: ${Spacing.Default}px;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;
  }

  .form-control {
    margin-right: ${Spacing.Default}px;

    ${({ fluidControls }) => fluidControls && `flex-grow: 1;`}

    &:last-child {
      margin-right: 0;
    }
  }
`;

export interface IActionBarProps {
  fluid?: boolean;
  fluidControls?: boolean;
  low?: boolean;
  children?: React.ReactElement[] | React.ReactElement;
  style?: {};
}

const ActionBar: React.FC<IActionBarProps> & {
  Filter: typeof ActionBarFilter;
  FilterItem: typeof ActionBarFilterItem;
  FilterToggle: typeof ActionBarFilterToggle;
} = ({ children, fluid, fluidControls, low, style }) => {
  return (
    <Wrapper
      style={style}
      className="action-bar"
      fluid={fluid}
      fluidControls={fluidControls}
      low={low ? 1 : 0}
    >
      {children}
    </Wrapper>
  );
};

const ActionBarFilterWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  // width: 100%;

  .subtitle {
    white-space: nowrap;
  }

  .action-bar-filter-item {
    flex-basis: 100%;

    margin-right: ${Spacing.Large}px;
    border-right: 1px solid ${neutral[300]};
    padding: 0 ${Spacing.Large}px 0 0;

    &:last-child {
      border-right: 0;
      margin-right: 0;
      padding-right: ${Spacing.Small}px;
    }
  }
`;

// const ActionBarFilterWrapper = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   margin: -${Spacing.Medium}px;
//   width: 100%;

//   .subtitle {
//     white-space: nowrap;
//   }

//   .action-bar-filter-item {
//     margin: ${Spacing.Medium}px;
//     flex: 1 1 15%;

//     // margin-right: ${Spacing.Large}px;
//     // border-right: 1px solid ${neutral[300]};
//     // padding: 0 ${Spacing.Large}px 0 0;

//     // &:last-child {
//     //   border-right: 0;
//     //   margin-right: 0;
//     //   padding-right: ${Spacing.Small}px;
//     // }
//   }
// `;

export interface IActionBarFilterProps {
  children?:
    | React.ReactElement<IActionBarFilterItemProps>[]
    | React.ReactElement<IActionBarFilterItemProps>;
}

const ActionBarFilter: React.FC<IActionBarFilterProps> = ({ children }) => {
  return (
    <ActionBarFilterWrapper className="action-bar-filter">
      {children}
    </ActionBarFilterWrapper>
  );
};

const ActionBarFilterItemWrapper = styled.div``;

export interface IActionBarFilterItemProps {
  children?: React.ReactElement[] | React.ReactElement;
  title?: string;
}
const ActionBarFilterItem: React.FC<IActionBarFilterItemProps> = ({
  title,
  children,
}) => {
  return (
    <ActionBarFilterItemWrapper className="action-bar-filter-item">
      {title && <Subtitle text={title} />}
      <div className="action-bar-filter-item-inner">{children}</div>
    </ActionBarFilterItemWrapper>
  );
};

const ActionBarFilterToggleWrapper = styled.div`
  margin-right: ${Spacing.Medium}px;
`;

export interface IActionBarFilterToggleProps {
  onClick?(): void;
}
const ActionBarFilterToggle: React.FC<IActionBarFilterToggleProps> = ({
  onClick,
}) => {
  const handleClick = () => {
    onClick?.();
  };

  return (
    <ActionBarFilterToggleWrapper className="action-bar-filter-toggle">
      <Button icon="filter" onClick={handleClick} size={Size.Small} />
    </ActionBarFilterToggleWrapper>
  );
};

ActionBar.Filter = ActionBarFilter;
ActionBar.FilterItem = ActionBarFilterItem;
ActionBar.FilterToggle = ActionBarFilterToggle;

export default ActionBar;
