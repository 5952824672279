import { IBeehiveAction } from "types/common/action.types";
import provisionMapActions from "../../actions/provisionMaps/provisionMapActions";

interface IProvisionMapCommentState {
  loading: boolean;
  error: string | null;
  saving: boolean;
  saveError: string | null;
  deleting: boolean;
  deleteError: string | null;
}

const INITIAL_STATE: IProvisionMapCommentState = {
  loading: false,
  error: null,
  saving: false,
  saveError: null,
  deleting: false,
  deleteError: null,
};


const provisionMapCommentReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IProvisionMapCommentState => {

  const {
    PROVISIONMAPCOMMENT_SAVE,
    PROVISIONMAPCOMMENT_CREATE,
    PROVISIONMAPCOMMENT_DELETE,
  } = provisionMapActions.types;

  switch (action.type) {
    case PROVISIONMAPCOMMENT_CREATE.START:
      return { ...state, saveError: null, saving: true };
    case PROVISIONMAPCOMMENT_CREATE.SUCCESS:
      return { ...state, saving: false };
    case PROVISIONMAPCOMMENT_CREATE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the comment.",
      };

    case PROVISIONMAPCOMMENT_SAVE.START:
      return { ...state, saveError: null, deleteError: null, saving: true };
    case PROVISIONMAPCOMMENT_SAVE.SUCCESS:
      return { ...state, saving: false };
    case PROVISIONMAPCOMMENT_SAVE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the comment.",
      };
    case PROVISIONMAPCOMMENT_DELETE.START:
      return { ...state, saveError: null, deleteError: null, deleting: true };
    case PROVISIONMAPCOMMENT_DELETE.SUCCESS:
      return { ...state, deleting: false };
    case PROVISIONMAPCOMMENT_DELETE.FAILED:
      return {
        ...state,
        deleting: false,
        deleteError: "There was a problem deleting the comment.",
      };

    default:
      return state;
  }
};

export default provisionMapCommentReducer;
