import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { GroupListView } from "types/users/userGroups.types";

const builder = new actionTypeBuilder("yeargroups");

const types = {
  YEARGROUPS_GETBYSCHOOL: builder.build("YEARGROUPS_GETBYSCHOOL"),
};

const getYearGroupsBySchool = (schoolId: number, callback?: callbackType<GroupListView[]>) =>
  action<GroupListView[]>(
    () => client.get(`planner/schools/${schoolId}/yearGroups`),
    types.YEARGROUPS_GETBYSCHOOL,
    callback
  );

const yearGroupActions = {
  types,
  getYearGroupsBySchool,
};

export default yearGroupActions;
