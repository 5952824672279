import studentLeavingLessonActions from "../../actions/student/studentLeavingLessonActions";

const INITIAL_STATE = {
  leavingLessonCategories: [],
  loadingCategories: false,
  loadingCreateRecord: false,
  loadingDeleteRecord: false,
  getCategoriesError: null,
  createRecordError: null,
  deleteRecordError: null,
};

const {
  GETLEAVINGLESSONCATEGORIES,
  ADDLEAVINGLESSONRECORD,
  ADDMULTISTUDENTLEAVINGLESSONRECORD,
  DELETELEAVINGLESSONRECORD,
} = studentLeavingLessonActions.types;

const studentLeavingLessonReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GETLEAVINGLESSONCATEGORIES.START:
      return { ...state, loadingCategories: true, getCategoriesError: null };

    case GETLEAVINGLESSONCATEGORIES.SUCCESS:
      return {
        ...state,
        loadingCategories: false,
        leavingLessonCategories: action.payload,
      };

    case GETLEAVINGLESSONCATEGORIES.FAILED:
      return {
        ...state,
        loadingCategories: false,
        getCategoriesError: action.payload,
      };

    case ADDLEAVINGLESSONRECORD.START:
      return { ...state, loadingCreateRecord: true, createRecordError: null };

    case ADDLEAVINGLESSONRECORD.SUCCESS:
      return { ...state, refresh: true, loadingCreateRecord: false };

    case ADDLEAVINGLESSONRECORD.FAILED:
      return {
        ...state,
        loadingCreateRecord: false,
        createRecordError: action.payload,
      };

    case ADDMULTISTUDENTLEAVINGLESSONRECORD.START:
      return { ...state, loadingCreateRecord: true, createRecordError: null };

    case ADDMULTISTUDENTLEAVINGLESSONRECORD.SUCCESS:
      return { ...state, refresh: true, loadingCreateRecord: false };

    case ADDMULTISTUDENTLEAVINGLESSONRECORD.FAILED:
      return {
        ...state,
        loadingCreateRecord: false,
        createRecordError: action.payload,
      };

    case DELETELEAVINGLESSONRECORD.START:
      return { ...state, loadingDeleteRecord: true, deleteRecordError: null };

    case DELETELEAVINGLESSONRECORD.SUCCESS:
      return { ...state, loadingDeleteRecord: false };

    case DELETELEAVINGLESSONRECORD.FAILED:
      return {
        ...state,
        loadingDeleteRecord: false,
        deleteRecordError: action.payload,
      };

    default:
      return state;
  }
};

export default studentLeavingLessonReducer;
