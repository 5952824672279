import React from "react";
import { EmptyMessage, Spacing } from "ui-kit";
import styled from "styled-components";


const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  padding: ${Spacing.ExtraLarge}px;
`;


const Cancelled = () => {
  return (
    <Wrapper>
      <EmptyMessage
        title="Checkout Cancelled"
        summary="The checkout process has been cancelled. Your basket will be stored and you can checkout at a later date."
        cover
      />
    </Wrapper>
  );
};


export default Cancelled;
