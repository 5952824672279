import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { SpecialDayListView } from "../types/termDatesResponse.types";

const builder = new actionTypeBuilder("specialDays");

const types = {
  SPECIALDAYS_GET: builder.build("SPECIALDAYS_GET"),
};


const getSpecialDaysForYear = (academicYearId: number, callback?: callbackType<SpecialDayListView[]>) =>
  action<SpecialDayListView[]>(
    () => client.get(`planner/academicyears/${academicYearId}/specialDays`),
    types.SPECIALDAYS_GET,
    callback
  );


const specialDayActions = {
  types,
  getSpecialDaysForYear,
};

export default specialDayActions;
