import React, { useEffect, useState } from "react";
import {
  Card,
  StructuredList,
  Swatches,
  Chip,
  Table,
  DetailLabel,
  Loader,
  Size,
} from "ui-kit";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import ConsentStatus from "../consentStatus";
import consentActions from "areas/administration/actions/consentActions";
import StudentModal from "sharedComponents/common/users/studentModal";
import Avatar from "sharedComponents/common/users/avatar";
import { RootState } from "reducers/store";
import { ConsentFormDetailView, ConsentResponseListView } from "areas/administration/types/consentResponses.types";


interface IConsentResponseModalProps {
  form: ConsentFormDetailView;
  response: ConsentResponseListView;
  onClose?: () => void;
}


const ConsentResponseModal: React.FC<IConsentResponseModalProps> = ({ form, response: initialResponse, onClose }) => {

  const { response, loading, error } = useSelector((state: RootState) => state.adminConsentResponse);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(initialResponse != null);

    if (initialResponse) {
      consentActions.getResponseForAdmin(form.id, initialResponse.id);
    }
  }, [initialResponse]);

  return (
    <StudentModal
      student={response?.user}
      title="Consent Response"
      open={open}
      onClose={onClose}
      width="80%"
      height="80%"
    >
      {loading ? (
        <Loader size={Size.Medium} cover />
      ) : (
        <>
          <Card>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Form">
                  {response?.form.name}
                </StructuredList.Item>
                <StructuredList.Item name="Consent Given">
                  <ConsentStatus status={response?.consentGiven} />
                </StructuredList.Item>
                <StructuredList.Item name="Completed By">
                  <Avatar
                    user={response?.createdBy}
                    sub={
                      <Moment format="DD/MM/YYYY hh:mmA">
                        {response?.createdDate}
                      </Moment>
                    }
                  />
                </StructuredList.Item>
                <StructuredList.Item name="Declared Name">
                  {response?.declarationName}
                </StructuredList.Item>
                <StructuredList.Item name="Declared Relationship">
                  {response?.declarationRelationship}
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
          <Card title="Responses">
            <Card.Body noPad>
              <Table>
                <Table.Body noHover>
                  {response?.items.map((item, index) => (
                    <Table.Row key={index}>
                      <Table.Cell width={7}>
                        <DetailLabel bold label={item.title} sub={item.text} />
                      </Table.Cell>
                      <Table.Cell width={1}>{item.code}</Table.Cell>
                      <Table.Cell width={2} right>
                        {item.consentGiven ? (
                          <Chip text={"Yes"} colorSwatch={Swatches.Success} />
                        ) : (
                          <Chip text={"No"} colorSwatch={Swatches.Danger} />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card.Body>
          </Card>
        </>
      )}
    </StudentModal>
  );
};

export default ConsentResponseModal;
