import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Button,
  Right,
  ActionBar,
  Swatches,
  Size,
  Message,
  ToastService,
  ValidationMessage,
  Card,
  StructuredList,
  DatePicker,
  List,
  Spacing,
  Tooltip,
} from "ui-kit";
import { CloneAssignmentCommand } from "areas/planner/types/assignmentRequests.types";
import { AssignmentDetailView } from "types/planner/assignments.types";
import assignmentEditorActions from "areas/planner/actions/assignments/assignmentEditorActions";
import { useAppSelector } from "reducers/hooks";
import { arrays } from "utils";
import { useScroll } from "hooks/useScroll";
import { Avatar, GroupSelector, StudentPicker } from "sharedComponents/common";
import Tippy from "@tippyjs/react";
import styled from "styled-components";
import { danger } from "ui-kit/common/colors";
import { StudentListView } from "types/users/userListViews.types";
import { GroupListView } from "types/users/userGroups.types";
import { GroupCommand } from "types/common/command.typed";


const RemoveButton = styled.button`
  font-size: 1.5rem;
  background-color: unset;
  border: medium none;
  margin: 0px;
  padding: 0px;
  color: rgb(153, 153, 153);
  cursor: pointer;
  line-height: 1rem;
  margin-bottom: ${Spacing.Small}px;
  &:hover {
    color: ${danger};
  }
`;


interface ICloneAssignmentModalProps {
  assignment: AssignmentDetailView;
  open: boolean;
  onCancel?: () => void;
  onSaved?: (command: CloneAssignmentCommand) => void;
}

interface CloneView {
  deadline: Date;
  saveAsDraft: boolean;
  students: StudentListView[];
  schools: number[];
  groups: GroupListView[];
}


const CloneAssignmentModal: React.FC<ICloneAssignmentModalProps> = ({ assignment, open, onCancel, onSaved }) => {

  const { scrollToTop } = useScroll();
  const tippyRef = useRef<HTMLButtonElement>();
  const { error, cloning } = useAppSelector(state => state.assignmentEditor);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_open, _setOpen] = useState<boolean>(false);
  const [_cloneView, _setCloneView] = useState<CloneView>({
    deadline: new Date(),
    saveAsDraft: false,
    students: [],
    schools: [],
    groups: [],
  });

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const handleSave = (saveAsDraft: boolean) => {

    if (!assignment) {
      return;
    }

    var errors = [];

    if (!_cloneView.deadline) {
      errors.push(`A deadline is required.`);
    }

    if (!_cloneView.groups?.length && !_cloneView.students?.length) {
      errors.push(`Please select one or more groups and/or students to set the assignment for.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    var data: CloneAssignmentCommand = {
      assignmentId: assignment.id,
      deadline: _cloneView.deadline,
      saveAsDraft: saveAsDraft,
      students: _cloneView.students.map(s => s.id),
      schools: [],
      groups: _cloneView.groups,
      assignedTo: [],
      userRestrictions: {
        allowParents: false,
        allowStaff: false,
        allowStudents: true,
      }
    };

    assignmentEditorActions.cloneAssignment(data, () => {
      onSaved?.(data);
      ToastService.pop("Assignment cloned", null, "briefcase");
    });
  };

  const handleCancel = () => {
    onCancel?.();
  }

  const handleAddStudent = (studentId: StudentListView) => {
    if (_cloneView.students?.some(student => student == studentId)) {
      return;
    }
    _setCloneView({ ..._cloneView, students: [..._cloneView.students || [], studentId] });
  }

  const handleRemoveStudent = (studentId: string) => {
    _setCloneView({ 
      ..._cloneView, 
      students: _cloneView.students.filter(x => x.id !== studentId)
    })
  }

  const handleGroupChange = (groups: GroupListView[]) => {
    _setCloneView({ ..._cloneView, groups });
  };

  return (
    <Modal
      width="80%"
      height="60%"
      title={`Clone ${assignment?.title}`}
      open={_open}
      onClose={handleCancel}
    >
      <Modal.Body>
        <Message text={error} color={Swatches.Danger} />
        <ValidationMessage errors={validationErrors} />
        {_cloneView && (
          <Card>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Deadline" required>
                  <DatePicker
                    dateFormat="DD/MM/YYYY"
                    selectedDate={_cloneView.deadline}
                    onChange={value => _setCloneView({ ..._cloneView, deadline: value })}
                    closeOnSelect
                  />
                </StructuredList.Item>
              </StructuredList>
              <StructuredList.Item name="Set For" required>
                <GroupSelector
                  onChange={handleGroupChange}
                  groups={_cloneView.groups}
                />
                <StudentPicker
                  fluid
                  onChange={handleAddStudent}
                  style={{ marginTop: 12, marginBottom: 8 }}
                />
                { _cloneView?.students?.length > 0 && (
                  <List>
                    { _cloneView?.students.map((student: StudentListView, index: number) => (
                      <List.Item
                        key={index}
                        left={<Avatar user={student} />}
                        right={
                          <>
                            <RemoveButton
                              ref={tippyRef}
                              onClick={event => {
                                event.stopPropagation();
                                handleRemoveStudent(student.id);
                              }}
                            >
                              &#10799;
                            </RemoveButton>
                            <Tippy
                              reference={tippyRef}
                              content={<Tooltip title="Remove Student" />}
                            />
                          </>
                        }
                      />
                    ))}
                  </List>
                )}
              </StructuredList.Item>
            </Card.Body>
          </Card>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              onClick={handleCancel}
              color={Swatches.Low}
              text="Cancel"
              size={Size.Small}
            />
            <Button
              text="Save as Draft"
              color={Swatches.Primary}
              onClick={() => handleSave(true)}
              working={cloning}
              size={Size.Small}
            />
            <Button
              onClick={() => handleSave(false)}
              color={Swatches.Success}
              text="Save"
              working={cloning}
              size={Size.Small}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default CloneAssignmentModal;
