import React, { useEffect, useState } from "react";
import {
  Table,
  Card,
  Button,
  EmptyMessage,
  Size,
  DetailLabel,
  Sub,
  SplitButton,
  Left,
  Message,
  Right,
  Subtitle,
  Swatches,
  ToastService,
  Spacing,
  HtmlText,
  textFont,
  typescale,
  neutral,
  fontStyle,
  Chip,
} from "ui-kit";
import { arrays } from "utils";
import CustomProvisionModal from "./customProvisionModal";
import ProvisionRecordProgress from "../providers/provisions/provisionGroups/provisionRecordProgress";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import provisionsActions from "areas/send/actions/provisions/provisionsActions";
import { RootState } from "reducers/store";
import { CustomProvisionDetailView, ProvisionMapDetailView } from "areas/send/types/passportResponse.types";
import { ProvisionMapStatus, ProvisionStatus, ProvisionType } from "areas/send/types/passportShared.types";
import { SaveCustomProvisionCommand } from "areas/send/types/passportRequest.types";
import moment from "moment";


const CustomProvisionActions = styled.div`
  .subtitle {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${Spacing.Default}px;
`;


interface ICustomProvisionTableProps {
  provisionMap: ProvisionMapDetailView;
  readonly?: boolean;
}


const CustomProvisionTable: React.FC<ICustomProvisionTableProps> = ({ provisionMap, readonly }) => {

  const { deleting, deleteError } = useSelector((state: RootState) => state.provision);
  const [_provisionMap, _setProvisionMap] = useState<ProvisionMapDetailView | null>(null);
  const [_selectedProvision, _setSelectedProvision] = useState<CustomProvisionDetailView | null>(null);

  useEffect(() => {
    _setProvisionMap(provisionMap);
  }, [provisionMap]);

  const handleAddCustomProvision = () => {
    var newProvision: CustomProvisionDetailView = {
      student: _provisionMap.student,
      progress: {
        entryData: null,
        exitData: null,
        target: null,
        strategies: null,
        notes: null,
        ratingName: null,
        rating: null,
      },
      description: null,
      costs: [],
      frequency: null,
      frequencyLength: null,
      sessionsPerFrequency: null,
      sessionLength: null,
      totalSessions: null,
      frequencyName: null,
      sessionCost: null,
      totalCost: null,
      tags: [],
      id: null,
      provider: null,
      school: _provisionMap.student.school,
      name: null,
      areaOfNeedName: null,
      areaOfNeed: null,
      provisionType: ProvisionType.Custom,
      startDate: null,
      endDate: null,
      isActive: null,
      status: ProvisionStatus.Live
    };
    _setSelectedProvision(newProvision);
  };

  const handleProvisionSaved = (data: SaveCustomProvisionCommand) => {
    _setSelectedProvision(null);
  };

  const handleProvisionModalCancel = () => {
    _setSelectedProvision(null);
  };

  const handleEdit = (provision: CustomProvisionDetailView) => {
    _setSelectedProvision(provision);
  };

  const handleDelete = (provision: CustomProvisionDetailView) => {
    if (
      window.confirm(
        "This will delete the selected custom provision. This cannot be undone. Are you sure?"
      )
    ) {
      provisionsActions.deleteProvision(
        provision.provider.id,
        provision.id,
        () => {
          ToastService.pop("Custom Provision Deleted", null, "hand-holding-seedling");
        }
      );
    }
  };

  return (
    <>
      <CustomProvisionActions>
        <Left>
          <Subtitle text="Custom Provisions" />
        </Left>
        <Right>
          {_provisionMap?.status === 0 && 
            !arrays.isEmpty(_provisionMap?.customProvisions) &&
            !readonly && (
            <Button
              text="Add Custom Provision"
              size={Size.Small}
              color={Swatches.Primary}
              onClick={handleAddCustomProvision}
            />
          )}
        </Right>
      </CustomProvisionActions>
      <Message text={deleteError} color={Swatches.Danger} />

      <Card>
        <Card.Body noPad={_provisionMap?.customProvisions?.length > 0}>
          {arrays.isEmpty(_provisionMap?.customProvisions) ? (
            <>
              <EmptyMessage
                cover
                title="No Custom Provisions"
                icon="hand-holding-seedling"
                summary="No custom provisions have been added for this student"
              >
                {_provisionMap?.status === Constants.SEND_PROVISIONMAPSTATUS.OPEN.value &&
                  !readonly && (
                  <Button
                    text="Add Custom Provision"
                    size={Size.Small}
                    color={Swatches.Primary}
                    onClick={handleAddCustomProvision}
                  />
                )}
              </EmptyMessage>
            </>
          ) : (
            <Table>
              <Table.Header>
                <Table.HeaderCell width={0.9} />
                <Table.HeaderCell width={1.8}>Area of Concern</Table.HeaderCell>
                <Table.HeaderCell width={1.3}>Target</Table.HeaderCell>
                <Table.HeaderCell width={1.3}>Entry Data</Table.HeaderCell>
                <Table.HeaderCell width={1.3}>Strategies</Table.HeaderCell>
                <Table.HeaderCell width={1.3}>Exit Data</Table.HeaderCell>
                <Table.HeaderCell width={1.3}>Progress</Table.HeaderCell>
                <Table.HeaderCell width={1.3} hide={readonly || _provisionMap?.status === ProvisionMapStatus.Closed} right></Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {_provisionMap?.customProvisions.map((provision: CustomProvisionDetailView, index: number) => (
                  <Table.Row key={index}>
                     <Table.Cell width={0.9}>
                      <>
                      { provision.isActive ? (
                        <Chip
                          text="Active"
                          tooltip={`${moment(provision.startDate).format("DD/MM/YY")} - ${moment(provision.endDate).format("DD/MM/YY")}`}
                          fluid
                          colorSwatch={Swatches.Blue}
                        />
                      ) : (
                        <Chip
                          text="Inactive"
                          tooltip={`${moment(provision.startDate).format("DD/MM/YY")} - ${moment(provision.endDate).format("DD/MM/YY")}`}
                          fluid
                          colorSwatch={Swatches.Low}
                        />
                      )}
                      </>
                    </Table.Cell>
                    <Table.Cell>
                      <DetailLabel
                        bold
                        label={provision.name}
                        sub={
                          <>
                            {provision.areaOfNeedName}
                            <br />
                            {provision.provider.name}
                          </>
                        }
                      />
                    </Table.Cell>
                    <Table.Cell width={1.3}>
                      <Sub>{provision.progress.target}</Sub>
                    </Table.Cell>
                    <Table.Cell width={1.3}>
                      <Sub>{provision.progress.entryData}</Sub>
                    </Table.Cell>
                    <Table.Cell width={1.3}>
                      <HtmlText 
                        html={provision.progress.strategies} 
                        fontStyle={fontStyle(textFont.roman, typescale.sub, neutral[500])}
                      />
                    </Table.Cell>
                    <Table.Cell width={1.8}>
                      <Sub>{provision.progress.exitData}</Sub>
                    </Table.Cell>
                    <Table.Cell width={1.3}>
                      <ProvisionRecordProgress
                        status={provision.progress.rating}
                        fluid
                      />
                    </Table.Cell>
                    <Table.Cell width={1.3} hide={readonly || _provisionMap?.status === ProvisionMapStatus.Closed} right>
                      {_provisionMap?.status ===
                        Constants.SEND_PROVISIONMAPSTATUS.OPEN.value && (
                        <SplitButton
                          text="Edit"
                          onDefaultClick={() => handleEdit?.(provision)}
                          color={Swatches.Low}
                          working={deleting}
                        >
                          <SplitButton.Option
                            text="Edit"
                            onClick={() => handleEdit?.(provision)}
                            show
                          />
                          <SplitButton.Option
                            text="Delete"
                            onClick={() => handleDelete(provision)}
                            color={Swatches.Danger}
                            show
                          />
                        </SplitButton>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </Card.Body>
      </Card>
      <CustomProvisionModal
        open={_selectedProvision != null}
        provisionMap={_provisionMap}
        provision={_selectedProvision}
        onCancel={handleProvisionModalCancel}
        onSave={handleProvisionSaved}
      />
    </>
  );
};

export default CustomProvisionTable;
