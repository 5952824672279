import studentInformationTabsActions from "../../actions/student/studentInformationTabsActions";

const INITIAL_STATE = {
  activeTab: 0,
};

const { SETACTIVETAB } = studentInformationTabsActions.types;

const studentInformationTabsReducer = (state = INITIAL_STATE, action) => {
  if (action.type === SETACTIVETAB) {
    return { ...state, activeTab: action.payload };
  }
  return state;
};

export default studentInformationTabsReducer;
