import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Spacing } from "ui-kit";
import RequestOnCallButton from "areas/behaviour/components/onCall/requestOnCallButton";
import CreateOnCallRequestWrapper from "areas/behaviour/components/onCall/createOnCallRequestWrapper";
import StudentTitle from "./studentTitle";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${Spacing.Default}px;
  margin-bottom: ${Spacing.Default}px;

  .stats {
    flex-grow: 1;
    margin-bottom: 0;
  }
  .on-call-button-wrapper .on-call-button {
    padding: ${Spacing.Default}px ${Spacing.Medium}px;
  }
`;

const StudentHeader = ({ student }) => {
  const [openOnCallModal, setOpenOnCallModal] = useState(false);

  return (
    student && (
      <>
        <Wrapper>
          <StudentTitle student={student} />

          <RequestOnCallButton
            handleRequestOnCall={() => setOpenOnCallModal(true)}
            schoolId={student.school?.id}
          />
        </Wrapper>

        {openOnCallModal && (
          <CreateOnCallRequestWrapper
            students={[student]}
            schoolId={student?.school?.id}
            openModal={openOnCallModal}
            handleCloseModal={() => setOpenOnCallModal(false)}
          />
        )}
      </>
    )
  );
};

StudentHeader.propTypes = {
  student: PropTypes.object,
  loading: PropTypes.bool,
};

export default StudentHeader;
