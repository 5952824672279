import interventionActions from "areas/planner/actions/interventions/interventionActions";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import InterventionsTable from "./interventionsTable";
import { arrays, files } from "utils";
import { Card, Subtitle } from "ui-kit";
import { group } from "console";
import { BeehiveReport } from "types/common/action.types";


interface IStudentInterventionsProps {
  studentId: string;
}

const StudentInterventions: React.FC<IStudentInterventionsProps> = ({ studentId }) => {

  const { interventions, error, loading } = useSelector((state: RootState) => state.studentInterventions);
  const { loading: loadingReport } = useSelector((state: RootState) => state.interventionReporting);

  useEffect(() => {
    studentId && interventionActions.getStudentInterventions(studentId);
  }, []);

  const handleExport = () => {
    studentId && interventionActions.exportStudentInterventionReport(studentId,
      (data: BeehiveReport) => files.openCsv(data, "Student Interventions")
    );
  }

  const handleProgressReport = () => {
    studentId && interventionActions.studentInterventionProgressReport(studentId,
      (data: BeehiveReport) => files.openCsv(data, "Student Interventions")
    );
  }

  return (
    <>
      <Subtitle>Student Intervention Records</Subtitle>
      <Card>
        <Card.Body noPad={!arrays.isEmpty(interventions)}>
          <InterventionsTable
            interventions={interventions}
            handleExport={handleExport}
            handleProgressReport={handleProgressReport}
            showAttendance={true}
            loading={loading}
            loadingReport={loadingReport}
            error={error}
            readonly
            studentMode
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default StudentInterventions;
