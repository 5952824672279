import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import {
  Flyout,
  ActionBar,
  Button,
  Swatches,
  Sub,
  Card,
  EmptyMessage,
  TextInput,
  TextInputType,
  Message,
  ValidationMessage,
  StructuredList,
} from "ui-kit";
import { arrays } from "utils";
import flyoutActions from "actions/ui/flyouts";
import provisionCostBandsActions from "../../../../actions/provisions/provisionCostBandsActions";
import { ProvisionCostBandListView } from "areas/send/types/passportResponse.types";
import { RootState } from "reducers/store";


interface IProvisionCostBandFlyoutProps {
  band: ProvisionCostBandListView;
  onClose?: (band: ProvisionCostBandListView) => void;
  onSave?: (band: ProvisionCostBandListView) => void;
}


const ProvisionCostBandFlyout: React.FC<IProvisionCostBandFlyoutProps> = ({ band, onClose, onSave }) => {

  const { saveError, saving } = useSelector((state: RootState) => state.provisionCostBand);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_band, _setCostBand] = useState<ProvisionCostBandListView>(band);

  useEffect(() => {
    _setCostBand(band);
    setValidationErrors([]);
  }, [band]);

  const handleClose = () => {
    flyoutActions.closeFlyout();
    onClose?.(band);
  };

  const handlePostSave = () => {
    flyoutActions.closeFlyout();
    onSave?.(_band);
  };

  const handleSave = () => {
    // Validation
    var errors = [];

    if (!_band.name.length) {
      errors.push(`Please enter a name.`);
    }
    if (!_band.defaultCost == null) {
      errors.push(`Please enter a default cost.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    if (_band.id) {
      provisionCostBandsActions.saveProvisionCostBand(_band, () => {
        handlePostSave();
      });
    } else {
      provisionCostBandsActions.createProvisionCostBand(_band, () => {
        handlePostSave();
      });
    }
  };

  return (
    <Flyout
      title={`${band?.id ? "Edit" : "New"} Cost Band`}
      name={Constants.FLYOUTS.SEND_PROVISIONCOSTBAND}
      wide
    >
      <Flyout.Body>
        {_band ? (
          <>
            <Message text={saveError} color={Swatches.Danger} />
            <ValidationMessage errors={validationErrors} />
            <Card>
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item
                    name="Name"
                    description="The name of the cost band"
                    required
                  >
                    <TextInput
                      value={_band.name}
                      onChange={value =>
                        _setCostBand({ ..._band, name: value })
                      }
                      maxLength={15}
                      fluid
                      characterCount
                    />
                  </StructuredList.Item>
                  <StructuredList.Item
                    name="Default Cost"
                    description="The default cost for this band"
                    required
                  >
                    <TextInput
                      type={TextInputType.Number}
                      value={_band.defaultCost}
                      onChange={value =>
                        _setCostBand({ ..._band, defaultCost: value })
                      }
                      fluid
                      prefix="£"
                    />
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>

            <Sub>
              <b>Important:</b> Editing the details for this cost band will not
              update the cost band settings, including default costs, for any
              existing provisions using this cost band. Updated details will
              only be reflected in any provisions created after any changes made
              to this cost band.
            </Sub>
          </>
        ) : (
          <EmptyMessage
            icon="hand-holding-seedling"
            title="No Cost Band"
            summary="No cost band has been selected"
            cover
          />
        )}
      </Flyout.Body>
      <Flyout.Footer>
        <ActionBar low>
          <Button
            text="Save"
            onClick={handleSave}
            color={Swatches.Success}
            fluid
            working={saving}
          />
        </ActionBar>
      </Flyout.Footer>
    </Flyout>
  );
};

export default ProvisionCostBandFlyout;
