import { useState, useEffect } from "react";
import { HeadlineStatistic } from "ui-kit";
import { Constants } from "configuration";
import { LunchRecordListView } from "areas/payments/types/catering/cateringResponses.types";


interface ILunchRegisterStatisticsProps {
  records: LunchRecordListView[];
}


const LunchRegisterStatistics: React.FC<ILunchRegisterStatisticsProps> = ({ records }) => {

  const [_records, _setRegister] = useState<LunchRecordListView[]>([]);

  useEffect(() => {
    _setRegister(records);
  }, [records]);

  const getLunchTypeTotal = (lunchType: number) => {
    return _records?.reduce(function (acc, obj) {
      return acc + (obj.lunchType === lunchType ? 1 : 0);
    }, 0);
  };

  return (
    <HeadlineStatistic>
      <HeadlineStatistic.Item
        icon="utensils"
        label="School Meals"
        value={getLunchTypeTotal(Constants.LUNCH_TYPES.SCHOOL_MEAL.value)}
      />
      <HeadlineStatistic.Item
        icon="sandwich"
        label="Packed Lunches"
        value={getLunchTypeTotal(Constants.LUNCH_TYPES.PACKED_LUNCH.value)}
      />
      <HeadlineStatistic.Item
        icon="home"
        label="Home"
        value={getLunchTypeTotal(Constants.LUNCH_TYPES.HOME.value)}
      />
      <HeadlineStatistic.Item
        icon="empty-set"
        label="None"
        value={getLunchTypeTotal(Constants.LUNCH_TYPES.FASTING.value)}
      />
      <HeadlineStatistic.Item
        icon="sign-out"
        label="Absent"
        value={getLunchTypeTotal(Constants.LUNCH_TYPES.ABSENT.value)}
      />
    </HeadlineStatistic>
  );
};

export default LunchRegisterStatistics;
