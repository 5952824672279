import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Icon } from "ui-kit";
import styled from "styled-components";
import { fontStyle, displayFont, typescale, Size, Spacing } from "ui-kit";
import { neutral } from "ui-kit/common/colors";
import { QuizAnswerType } from "areas/planner/constants/groups/studentQuiz";
import { AnswerType } from "areas/planner/types/quiz/quizShared.types";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .student-answer-type {
    padding: ${Spacing.Medium}px ${Spacing.Medium}px;
    margin: ${Spacing.Large}px ${Spacing.ExtraLarge}px;
    height: 3rem;
    width: 3rem;
    display: flex;
    border-radius: ${Spacing.Small}px;
    flex-direction: column;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    .icon {
      margin: 0;
      font-size: 3rem;
    }
  }

  .student-answer-count {
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: ${Spacing.Medium}px;
    bottom: ${Spacing.Jumbo}px;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    background-color: ${neutral[700]};
    ${fontStyle(displayFont.roman, typescale.header4, neutral[100])}
    line-height: 1rem;
  }
`;


interface IStudentAnswerTypeProps {
  answerType: QuizAnswerType;
  selectedAnswerType: AnswerType;
  setSelectedAnswerType: (answerType: AnswerType) => void;
  answerCount: number;
}


const StudentAnswerType: React.FC<IStudentAnswerTypeProps> = ({
  answerType,
  selectedAnswerType,
  setSelectedAnswerType,
  answerCount,
}) => {

  const [style, setStyle] = useState<React.CSSProperties>({});

  useEffect(() => {
    selectedAnswerType === answerType.key
      ? setStyle({
          backgroundColor: answerType.colour,
          border: "0px",
          boxShadow: `0px 0px ${Spacing.ExtraSmall}px 0px ${answerType.colour}`,
        })
      : setStyle({
          backgroundColor: "white",
          border: `1px ${answerType.colour} solid`,
          boxShadow: `0px 0px ${Spacing.ExtraSmall}px 0px ${answerType.colour}`,
        });
  }, [selectedAnswerType]);

  return (
    <Wrapper>
      <div
        className="student-answer-type"
        style={style}
        onClick={() => setSelectedAnswerType(answerType.key)}
      >
        <Icon
          value={answerType.icon}
          size={Size.ExtraLarge}
          color={
            selectedAnswerType === answerType.key ? neutral[100] : neutral[500]
          }
        />
      </div>
      <div className="student-answer-count">{answerCount}</div>
    </Wrapper>
  );
};

export default StudentAnswerType;
