import { ProvisionGroupDetailView, ProvisionRecordListView } from "areas/send/types/passportResponse.types";
import provisionGroupsActions from "../../actions/provisions/provisionGroupsActions";
import { IBeehiveAction } from "types/common/action.types";

interface IProvisionGroupState {
  group: ProvisionGroupDetailView | null;
  loading: boolean;
  error: string | null;
  saving: boolean;
  saveError: string | null;
  deleting: boolean;
  deleteError: string | null;
  records: ProvisionRecordListView[];
  loadingRecords: boolean;
  recordsError: string | null;
  newId: number | null;
}

const INITIAL_STATE: IProvisionGroupState = {
  group: null,
  loading: false,
  saving: false,
  error: null,
  saveError: null,
  deleting: false,
  deleteError: null,
  records: [],
  loadingRecords: false,
  recordsError: null,
  newId: null,
};


const provisionGroupReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IProvisionGroupState => {
  const {
    PROVISIONGROUPS_GET,
    PROVISIONGROUPS_CREATE,
    PROVISIONGROUPS_SAVE,
    PROVISIONGROUPS_DELETE,
    PROVISIONGROUPS_STATUSCHANGE,
    PROVISIONRECORDS_CREATE,
    PROVISIONRECORDS_SAVE,
    PROVISIONRECORDS_DELETE,
    PROVISIONRECORDS_UPDATESTATUS,
    PROVISIONRECORDS_GET,
    PROVISIONRECORDS_BULKCREATE,
    PROVISIONRECORDS_BULKUPDATE,
  } = provisionGroupsActions.types;

  switch (action.type) {
    case PROVISIONGROUPS_GET.START:
      return {
        ...state,
        error: null,
        saveError: null,
        loading: true,
        records: [],
        loadingRecords: false,
        recordsError: null,
      };
    case PROVISIONGROUPS_GET.SUCCESS:
      return { ...state, loading: false, group: action.payload };
    case PROVISIONGROUPS_GET.FAILED:
      return { ...state, loading: false, error: action.payload };

    case PROVISIONRECORDS_GET.START:
      return {
        ...state,
        records: [],
        loadingRecords: true,
        recordsError: null,
      };
    case PROVISIONRECORDS_GET.SUCCESS:
      return { ...state, loadingRecords: false, records: action.payload };
    case PROVISIONRECORDS_GET.FAILED:
      return { ...state, loadingRecords: false, recordsError: action.payload };

    case PROVISIONGROUPS_CREATE.START:
      return { ...state, saveError: null, saving: true };
    case PROVISIONGROUPS_CREATE.SUCCESS:
      return { ...state, saving: false, newId: action.payload.id };
    case PROVISIONGROUPS_CREATE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem creating the provision group",
      };

    case PROVISIONGROUPS_SAVE.START:
      return { ...state, saveError: null, deleteError: null, saving: true };
    case PROVISIONGROUPS_SAVE.SUCCESS:
      return { ...state, saving: false, group: action.payload };
    case PROVISIONGROUPS_SAVE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the provision group",
      };

    case PROVISIONGROUPS_DELETE.START:
      return { ...state, saveError: null, deleteError: null, deleting: true };
    case PROVISIONGROUPS_DELETE.SUCCESS:
      return { ...state, deleting: false };
    case PROVISIONGROUPS_DELETE.FAILED:
      return {
        ...state,
        deleting: false,
        deleteError:
          "There was a problem deleting the provision group. This may be because this provision group is in use.",
      };

    case PROVISIONRECORDS_BULKCREATE.START:
      return {
        ...state,
        loadingRecords: true,
      };
    case PROVISIONRECORDS_BULKCREATE.SUCCESS:
      return {
        ...state,
        records: [...action.payload, ...state.records],
        loadingRecords: false,
      };
    case PROVISIONRECORDS_BULKCREATE.FAILED:
      return {
        ...state,
        loadingRecords: false,
      };

    case PROVISIONRECORDS_BULKUPDATE.START:
      return {
        ...state,
        loadingRecords: true,
      };
    case PROVISIONRECORDS_BULKUPDATE.SUCCESS:
      return {
        ...state,
        records: action.payload,
        loadingRecords: false,
      };
    case PROVISIONRECORDS_BULKUPDATE.FAILED:
      return {
        ...state,
        loadingRecords: false,
      };

    case PROVISIONRECORDS_CREATE.SUCCESS:
      return {
        ...state,
        records: [action.payload, ...state.records],
      };

    case PROVISIONRECORDS_SAVE.SUCCESS:
    case PROVISIONRECORDS_UPDATESTATUS.SUCCESS:
      return {
        ...state,
        records: state.records.map(a =>
          a.id === action.payload.id ? action.payload : a
        ),
      };

    case PROVISIONRECORDS_DELETE.SUCCESS:
      return {
        ...state,
        records: state.records.filter(a => a.id !== action.payload),
      };

    case PROVISIONGROUPS_STATUSCHANGE.START:
      return { ...state, saving: true };
    case PROVISIONGROUPS_STATUSCHANGE.SUCCESS:
      return { ...state, saving: false, group: action.payload };
    case PROVISIONGROUPS_STATUSCHANGE.FAILED:
      return { ...state, saving: false, error: action.payload };

    default:
      return state;
  }
};

export default provisionGroupReducer;
