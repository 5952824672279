import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import { TabBar, EmptyMessage, Loader, Size } from "ui-kit";
import TimetableGrid from "./timetableGrid";
import timetableActions from "areas/planner/actions/timetableActions";
import { useNavigate } from "react-router";
import { groupRoutes } from "../../plannerRoutes";
import { Constants } from "configuration";
import routes from "configuration/routes";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const NonParentTimetableView = ({ user }) => {
  // const { user } = useSelector(state => state.currentUser);
  const { timetable, loading, error } = useSelector(state => state.timetable);

  const [activeSchool, setActiveSchool] = useState(
    !arrays.isEmpty(timetable?.schools) ? timetable.schools[0] : null
  );

  const navigate = useNavigate();

  useEffect(() => {
    user &&
      timetableActions.getTimetable(user.id, response => {
        setActiveSchool(response?.schools[0]);
      });
  }, []);

  // useEffect(() => {
  //   setActiveSchool(timetable?.schools[0]);
  // }, [timetable]);

  const handleSchoolClicked = (name, index) => {
    setActiveSchool(timetable.schools[index]);
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem loading the timetable"
        cover
      />
    );
  }

  if (arrays.isEmpty(timetable?.schools)) {
    return (
      <EmptyMessage
        icon="clock"
        title="No schools found"
        summary="No schools were found for this timetable"
        cover
      />
    );
  }

  return (
    <Wrapper>
      {timetable?.schools.length > 1 && (
        <TabBar onItemClicked={handleSchoolClicked}>
          {timetable.schools.map((school, index) => (
            <TabBar.Item
              key={index}
              name={school.school.code}
              label={school.school.code}
              text={school.school.code}
            />
          ))}
        </TabBar>
      )}
      <TimetableGrid
        timetable={activeSchool}
        onPeriodClick={period =>
          period.groupId > 0 &&
          navigate(
            `${groupRoutes.getGroupPath(
              period.groupId,
              Constants.GROUP_TYPES.CLASS
            )}`
          )
        }
      />
    </Wrapper>
  );
};

export default NonParentTimetableView;
