import storeActions from "areas/payments/actions/storeActions";
import { OrderListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface IStoreOrdersState {
  orders: OrderListView[];
  error: string | null;
  loading: boolean;
}

const INITIAL_STATE: IStoreOrdersState = {
  orders: [],
  error: null,
  loading: false
};


const storeOrdersReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IStoreOrdersState => {

  const { 
    STORE_GETORDERS 
  } = storeActions.storeTypes;

  switch (action.type) {
    case STORE_GETORDERS.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case STORE_GETORDERS.SUCCESS:
      return { 
        ...state, 
        orders: action.payload, 
        loading: false 
      };

    case STORE_GETORDERS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default storeOrdersReducer;
