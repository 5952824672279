import React from "react";
import styled from "styled-components";
import {
  Swatches,
  neutral,
  honeygold,
  Button,
  Spacing,
  ActionBar
} from "ui-kit";
import { IFeedItem } from "../reducers/feedReducer";


const Wrapper = styled.div<{favourited: boolean, liked: boolean}>`
  .feed-item-favourite {
    .icon {
      color: ${({ favourited }) =>
        favourited ? Swatches.Danger.swatch : neutral[400]} !important;
    }

    &:hover {
      .icon {
        color: ${Swatches.Danger.swatch};
      }
      span {
        /* animation: heartbeat 3s; */
      }
    }
  }

  .feed-item-like {
    .icon {
      color: ${({ liked }) => (liked ? honeygold : neutral[400])} !important;
    }

    &:hover {
      .icon {
        color: ${honeygold} !important;
      }

      span {
        /* animation: shake 3s; */
      }
    }
  }

  .feed-item-dismiss {
    .icon {
      color: ${neutral[400]};
    }
    &:hover {
      background: ${Swatches.Danger.swatch};
      .icon {
        color: white;
      }
    }
  }

  @keyframes heartbeat {
    0% {
      transform: scale(0.75);
    }
    15% {
      transform: scale(1);
    }
    40% {
      transform: scale(0.75);
    }
    55% {
      transform: scale(1);
    }
    80% {
      transform: scale(0.75);
    }
    100% {
      transform: scale(0.75);
    }
  }

  @keyframes shake {
    0% {
      transform: rotate(-10deg) scale(1.25);
    }
    50% {
      transform: rotate(-10deg) scale(1.25);
    }
    55% {
      transform: rotate(0);
    }
  }
`;

const FeedItemGroups = styled.div`
  padding-bottom: ${Spacing.Default}px;
  flex-grow: 1;
  display: flex;
  justify-content space-between;
  align-items: flex-start;
  border-bottom: 1px solid ${neutral[300]};
  padding-top: ${Spacing.Default}px;

  .chip-list {
    padding-left: ${Spacing.Default}px;
  }
`;

const FeedItemActions = styled.div`
  .action-bar {
    padding: ${Spacing.Small}px;
    margin-bottom: 0;
  }
`;


interface IFeedItemFooterProps {
  item: IFeedItem;
  onFavourite?: (article: IFeedItem) => void;
  onLike?: (article: IFeedItem) => void;
  onDismiss?: (article: IFeedItem) => void;
}


const FeedItemFooter: React.FC<IFeedItemFooterProps> = ({ item, onFavourite, onLike, onDismiss }) => {

  const handleFavourite = (article: IFeedItem) => {
    onFavourite?.(article);
  };

  const handleLike = (article: IFeedItem) => {
    onLike?.(article);
  };

  const handleDismiss = (article: IFeedItem) => {
    onDismiss?.(article);
  };

  return (
    <Wrapper
      className="feed-item-footer"
      favourited={item.favourited}
      liked={item.liked}
    >
      <FeedItemActions>
        <ActionBar low>
          <Button
            tooltip="Favourite this article"
            className="feed-item-favourite"
            icon="heart"
            fluid
            transparent
            onClick={() => handleFavourite(item)}
            color={Swatches.Low}
          />
          <Button
            tooltip="Like this article"
            className="feed-item-like"
            icon="thumbs-up"
            fluid
            transparent
            onClick={() => handleLike(item)}
            color={Swatches.Low}
            data-tippy="Tooltip"
          />
          <Button
            tooltip="Dismiss this article"
            className="feed-item-dismiss"
            icon="trash"
            fluid
            transparent
            onClick={() => handleDismiss(item)}
            color={Swatches.Low}
          />
        </ActionBar>
      </FeedItemActions>
    </Wrapper>
  );
};

export default FeedItemFooter;
