import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { PreflightRegistrationResponse } from "../types/registrationResponse.types";
import { ActivateAccountCommand, StudentRegistrationDetails, VerifyRegistrationCommand } from "../types/registrationRequest.types";
import { IBeehiveError } from "types/common/errors.types";

const builder = new actionTypeBuilder("request");

const types = {
  REGISTRATION_REQUEST: builder.build("REGISTRATION_REQUEST"),
  REGISTRATION_VERIFY: builder.build("REGISTRATION_VERIFY"),
  REGISTRATION_ACTIVATE: builder.build("REGISTRATION_ACTVATE"),
  REGISTRATION_PREFLIGHT: builder.build("REGISTRATION_PREFLIGHT"),
};


const request = (email: string, callback?: callbackType<null>, errCallback?: callbackType<IBeehiveError>) =>
  action<null>(
    () => client.post(`users/registration/request`, { email }),
    types.REGISTRATION_REQUEST,
    callback,
    errCallback
  );

const preflight = (authorisationCode: string, callback?: callbackType<PreflightRegistrationResponse>) =>
  action<PreflightRegistrationResponse>(
    () => client.get(`users/registration/${authorisationCode}/preflight`),
    types.REGISTRATION_PREFLIGHT,
    callback
  );

const verify = (authorisationCode: string, data: VerifyRegistrationCommand, callback?: callbackType<StudentRegistrationDetails[]>) =>
  action<StudentRegistrationDetails[]>(
    () => client.post(`users/registration/${authorisationCode}/verify`, data),
    types.REGISTRATION_VERIFY,
    callback
  );

const activate = (authorisationCode: string, data: ActivateAccountCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`users/registration/${authorisationCode}/activate`, data),
    types.REGISTRATION_ACTIVATE,
    callback
  );


const registrationActions = {
  types,
  request,
  preflight,
  verify,
  activate,
};

export default registrationActions;
