import paymentActions from "areas/payments/actions/paymentsActions";
import { ReconciliationFilter } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";
import { ReconciliationListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import defaults from "configuration/defaults";
import { IBeehiveAction } from "types/common/action.types";
import { PageInfo } from "types/common/paging.types";


export interface IReconciliationsState {
  reconciliations: ReconciliationListView[],
  error: string | null;
  loading: boolean;
  paging: PageInfo,
  filter: ReconciliationFilter;
}

const INITIAL_STATE: IReconciliationsState = {
  reconciliations: [],
  error: null,
  loading: false,
  paging: defaults.DEFAULT_PAGING,
  filter: {
    date: null
  }
};

const reconciliationsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IReconciliationsState => {

  const {
    PAYMENTS_GETRECONCILIATIONS,
    PAYMENTS_RECONCILIATIONS_FILTERBYDATE
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETRECONCILIATIONS.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETRECONCILIATIONS.SUCCESS:
      return {
        ...state,
        reconciliations:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.reconciliations, ...action.payload.items],
        paging: action.payload.paging,
        loading: false
      };

    case PAYMENTS_GETRECONCILIATIONS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PAYMENTS_RECONCILIATIONS_FILTERBYDATE:
      return {
        ...state,
        filter: { 
          ...state.filter, 
          date: action.payload 
        }
      };
    default:
      return state;
  }
};

export default reconciliationsReducer;
