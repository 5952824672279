import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { StaffType } from "types/users/userStaff.types";
import { ReviewPeriodView } from "../types/managedStaffResponse.types";

const prefix = "reviewperiods";
const builder = new actionTypeBuilder(prefix);

const types = {
  GETCURRENT: builder.build("getcurrent"),
};


const getCurrentReviewPeriod = (staffType: StaffType, callback?: callbackType<ReviewPeriodView>) =>
  action<ReviewPeriodView>(
    () => client.get(`pm/reviewperiods/current?staffType=${staffType}`),
    types.GETCURRENT,
    callback
  );


const reviewPeriodActions = {
  types,
  getCurrentReviewPeriod,
};

export default reviewPeriodActions;
