import schoolsActions from "areas/planner/actions/schools/schoolsActions";
import React, { useEffect } from "react";
import { useAppSelector } from "reducers/hooks";
import { Title, TitleSize } from "ui-kit";
import ReportDescription from "../reportDescription";
import coCurricularAnalyticsActions from "areas/analytics/actions/coCurricularAnalyticsActions";
import { CoCurricularReportFilter } from "areas/analytics/types/coCurricularAnalyticsRequest.types";
import CoCurricularAnalyticsForm from "./coCurricularAnalyticsForm";
import { ApiExceptionMessage } from "sharedComponents/common";


const CoCurricularAnalytics: React.FC = () => {

  const { exporting, exportError } = useAppSelector(state => state.coCurricularAnalytics);

  const handleGenerateReport = (filter: CoCurricularReportFilter) => {  
    coCurricularAnalyticsActions.exportCoCurricularReport(filter);
  };

  return (
    <>
      <Title text="CoCurricular Report" size={TitleSize.H3} />
      <ReportDescription text="" />
      { exportError && (
        <ApiExceptionMessage error={exportError} />
      )}
      <CoCurricularAnalyticsForm
        onGenerateReport={handleGenerateReport}
        exporting={exporting}
      />
    </>
  );
};

export default CoCurricularAnalytics;
