export const FEED_TYPE_KEY = {
  ALL: null,
  NOTES: 0,
  QNA: 1,
  AWARDS: 2,
  BEHAVIOURS: 3,
  REWARDS: 4,
  SMART_TASK: 5,
  LEFT_LESSON: 6,
  ON_CALL: 7,
};

export const ACTIVITY_FILTERS = [
  {
    key: FEED_TYPE_KEY.ALL,
    text: "All Types",
    tooltip: "All Activity Types",
    tooltipSub: "View all student activity",
  },
  {
    key: FEED_TYPE_KEY.NOTES,
    text: "Notes",
    tooltip: "Notes",
    tooltipSub: "Filter notes",
  },
  {
    key: FEED_TYPE_KEY.QNA,
    text: "Quiz",
    tooltip: "Quiz",
    tooltipSub: "Filter to view all student answers to questions",
  },
  {
    key: FEED_TYPE_KEY.AWARDS,
    text: "Awards",
    tooltip: "Awards",
    tooltipSub: "Filter to view all awards",
  },
  {
    key: FEED_TYPE_KEY.BEHAVIOURS,
    text: "Behaviours",
    tooltip: "Behaviours",
    tooltipSub: "Filter to view all behaviours",
  },
  {
    key: FEED_TYPE_KEY.REWARDS,
    text: "Rewards",
    tooltip: "Rewards",
    tooltipSub: "Filter to view all rewards",
  },
  {
    key: FEED_TYPE_KEY.SMART_TASK,
    text: "Smart Tasks",
    tooltip: "Smart Tasks",
    tooltipSub: "Filter to view all smart tasks",
  },
  {
    key: FEED_TYPE_KEY.LEFT_LESSON,
    text: "Leaving Lesson",
    tooltip: "Leaving Lesson",
    tooltipSub: "Filter to view all leaving lesson records",
  },
  {
    key: FEED_TYPE_KEY.ON_CALL,
    text: "On Call Requests",
    tooltip: "On Call Requests",
    tooltipSub: "Filter to view all on call requests",
  },
];

export const DATE_FILTER_KEYS = {
  ALL: null,
  TODAY: 0,
  YESTERDAY: 1,
  THIS_WEEK: 2,
};

export const DATE_FILTERS = [
  {
    key: DATE_FILTER_KEYS.ALL,
    text: "All Timescales",
    setFilter: null,
    tooltip: "All Timescales",
    tooltipSub: "View all activity",
  },
  {
    key: DATE_FILTER_KEYS.TODAY,
    text: "Today",
    setFilter: null,
    tooltip: "Today",
    tooltipSub: "View activity for Today",
  },
  {
    key: DATE_FILTER_KEYS.YESTERDAY,
    text: "Yesterday",
    setFilter: null,
    tooltip: "All",
    tooltipSub: "View activity for yesterday",
  },
  {
    key: DATE_FILTER_KEYS.THIS_WEEK,
    text: "This Week",
    setFilter: null,
    tooltip: "All",
    tooltipSub: "View activity this week",
  },
];
