import defaults from "configuration/defaults";
import consentActions from "../../actions/consentActions";
import { PageInfo } from "types/common/paging.types";
import { IBeehiveAction } from "types/common/action.types";
import { ConsentFormListView } from "areas/administration/types/consentResponses.types";

interface IAdminConsentFormsState {
  loading: boolean;
  forms: ConsentFormListView[],
  error:  string | null;
  paging: PageInfo;
  filter: AdminConsentFormsFilter;
}

export interface AdminConsentFormsFilter {
  status: number;
}

const CONSENT_STATE: IAdminConsentFormsState = {
  loading: false,
  forms: [],
  error: null,
  paging: defaults.DEFAULT_PAGING,
  filter: {
    status: 2,
  },
};

const adminConsentFormsReducer = (state = CONSENT_STATE, action: IBeehiveAction) : IAdminConsentFormsState => {

  const { 
    CONSENT_GETFORMS, 
    CONSENT_UPDATEFILTER 
  } = consentActions.types;

  switch (action.type) {
    case CONSENT_GETFORMS.START:
      return { ...state, loading: true, error: null };

    case CONSENT_GETFORMS.SUCCESS:
      return {
        ...state,
        forms:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.forms, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };

    case CONSENT_GETFORMS.FAILED:
      return {
        ...state,
        loading: false,
        forms: null,
        error: "There was a problem loading the consent forms",
      };

    case CONSENT_UPDATEFILTER:
      return {
        ...state,
        filter: action.payload,
      };

    default:
      return state;
  }
};

export default adminConsentFormsReducer;
