import { useEffect, useState } from "react";
import {
  Title,
  TitleSize,
  EmptyMessage,
  Loader,
  Swatches,
  Text,
  Button,
  Size,
  Card,
} from "ui-kit";
import Subheader from "sharedComponents/layout/header/subheader";
import { Constants } from "configuration";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ProviderFlyout from "./providerFlyout";
import ProviderContactsTable from "./providerContacts/providerContactsTable";
import ProvisionsTable from "./provisions/provisionsTable";
import ProvisionFlyout from "./provisions/provisionFlyout";
import providerActions from "areas/send/actions/providers/providerActions";
import flyoutActions from "actions/ui/flyouts";
import { RootState } from "reducers/store";
import { ProvisionListView } from "areas/send/types/passportResponse.types";


export type provisionInEdit = Partial<ProvisionListView> & { providerId?: number; schoolId?: number; }; //& Pick<ProvisionListView, "id"> 


const Provider = () => {

  const { providerId } = useParams();
  const { loading, error, provider } = useSelector((state: RootState) => state.provider);
  const [_selectedProvision, _setSelectedProvision] = useState<provisionInEdit>(null);

  useEffect(() => {
    providerActions.getProvider(parseInt(providerId));
  }, []);

  const editProvider = () => {
    flyoutActions.openFlyout(Constants.FLYOUTS.SEND_PROVIDER);
  };

  const handleNewProvision = () => {
    _setSelectedProvision({
      id: null,
      providerId: parseInt(providerId),
    });
    flyoutActions.openFlyout(Constants.FLYOUTS.SEND_PROVISION);
  };

  const handleEditProvision = (provision: ProvisionListView) => {
    _setSelectedProvision(provision);
    flyoutActions.openFlyout(Constants.FLYOUTS.SEND_PROVISION);
  };

  if (loading) {
    return <Loader cover size={Size.Medium} />;
  }

  if (error) {
    return <EmptyMessage icon="times-circle" title="An error occurred" cover />;
  }

  if (!loading && !error && !provider) {
    <EmptyMessage
      icon="hand-holding-seedling"
      title="Provider Not Found"
      cover
    />;
  }

  return (
    <>
      <Subheader>
        <Title
          size={TitleSize.H2}
          text="SEND"
          sub={provider?.name}
          right={
            <Button
              size={Size.Small}
              onClick={editProvider}
              text="Edit Provider"
              color={Swatches.Primary}
            />
          }
        />
      </Subheader>
      {provider?.description.length ? (
        <Card title="Description">
          <Card.Body>
            <Text>{provider?.description}</Text>
          </Card.Body>
        </Card>
      ) : null}

      <Card title="Provisions">
        <Card.Body noPad={provider?.provisions.length > 0}>
          <ProvisionsTable
            provisions={provider?.provisions}
            onNew={handleNewProvision}
            onEdit={handleEditProvision}
          />
        </Card.Body>
      </Card>
      <ProvisionFlyout
        onClose={() => _setSelectedProvision(null)}
        provision={_selectedProvision}
        provider={provider}
      />

      <Card title="Contacts">
        <Card.Body noPad={provider?.contacts.length> 0}>
          <ProviderContactsTable provider={provider} />
        </Card.Body>
      </Card>

      <ProviderFlyout provider={provider} />
    </>
  );
};

export default Provider;
