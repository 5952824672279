import React, { useState } from "react";
import styled from "styled-components";
import {
  Card,
  CircleIcon,
  neutral,
  typescale,
  Size,
  displayFont,
  Chip,
  Spacing,
  Button,
  Sub,
  fontStyle,
  Swatches,
  honeygold,
  Speed,
} from "ui-kit";
import fileActions from "areas/filing/actions/fileActions";
import { FormListView } from "../types/formResponse.types";
import { FormStaffType } from "../types/formShared.types";


export const FormListWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;

  &:hover {
    cursor: pointer;

    .circle-icon {
      transition: all ease ${Speed.Default}ms;
      i {
        color: ${honeygold};
      }
      border-color: ${honeygold};
    }
  }

  .circle-icon {
    margin-bottom: ${Spacing.Large}px;
  }

  .form-item-name {
    display: block;
    margin-bottom: ${Spacing.Large}px;
    ${fontStyle(displayFont.medium, typescale.header4, neutral[600])}
  }

  .form-item-summary {
    display: block;
    color: ${neutral[600]};
    margin: ${Spacing.Large}px;
    flex-grow: 1;
  }
`;

interface IFormListItemProps {
  form: FormListView;
  onGoToForm: (form: FormListView) => void;
}


const FormListItem: React.FC<IFormListItemProps> = ({ form, onGoToForm }) => {

  const handleFormClick = (form: FormListView, e?: React.MouseEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    form.fileId ? downloadForm(form) : onGoToForm?.(form);
  };

  const downloadForm = (form: FormListView) => {
    fileActions.downloadFile(form.fileId);
  };

  return (
    <Card
      className="form-list-item"
      onClick={() => handleFormClick(form)}
    >
      <Card.Body>
        <FormListWrapper>
          <CircleIcon
            value={form.icon ? form.icon : "paste"}
            size={Size.Large}
            outline
            color={Swatches.Low.swatch}
          />
          <span className="form-item-name">{form.name}</span>
          <AssociateChip staffType={form.staffType} />
          <Sub className="form-item-summary">{form.summary}</Sub>
          <Button
            text={form.fileId ? "Download Form" : "Complete Form"}
            color={Swatches.Primary}
            onClick={e => handleFormClick(form, e)}
            fluid
            size={Size.Small}
          />
        </FormListWrapper>
      </Card.Body>
    </Card>
  );
};


interface IAssociateChipProps {
  staffType: FormStaffType;
}

export const AssociateChip: React.FC<IAssociateChipProps> = ({ staffType }) => {
  var chipText = "";

  switch (staffType) {
    case 0:
      chipText = "All Staff";
      break;
    case 1:
      chipText = "Teaching";
      break;
    case 2:
      chipText = "Associate";
      break;

    default:
      break;
  }

  return <Chip text={chipText} />;
};

export default FormListItem;
