import { IBeehiveAction } from "types/common/action.types";
import store from "../../../../reducers/store";

const types = {
  SELECTSCHOOL: "selectschool",
};


const selectSchool = (schoolId: number) =>
  store.dispatch<IBeehiveAction<number>>({
    type: types.SELECTSCHOOL,
    payload: schoolId,
  });


const schoolsActions = {
  types,
  selectSchool,
};

export default schoolsActions;
