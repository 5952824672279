import routes from "../../configuration/routes";

const groupPath = (groupId, groupType) =>
  `${routes.home}/groups/${groupId}/grouptype/${groupType}`;

export const groupRoutes = {
  default: "/groups",
  group: {
    default: "/:groupId/grouptype/:groupType",
    activity: "/activity",
    getActivityPath: (groupId, groupType) =>
      `${groupPath(groupId, groupType)}/activity`,

    students: "/students",
    getStudentsPath: (groupId, groupType) =>
      `${groupPath(groupId, groupType)}/students`,

    seatingPlan: "/seatingplan",
    getSeatingPlanPath: (groupId, groupType) =>
      `${groupPath(groupId, groupType)}/seatingplan`,

    leaderboard: "/leaderboard",
    getLeaderboardPath: (groupId, groupType) =>
      `${groupPath(groupId, groupType)}/leaderboard`,

    reporting: "/reporting",
    getReportingPath: (groupId, groupType) =>
      `${groupPath(groupId, groupType)}/reporting`,

    interventions: "/interventions",
    getInterventionsPath: (groupId, groupType) =>
      `${groupPath(groupId, groupType)}/interventions`,

    details: "/details",
    getDetailsPath: (groupId, groupType) =>
      `${groupPath(groupId, groupType)}/details`,

    lunch: "/lunch",
    getLunchPath: (groupId, groupType) =>
      `${groupPath(groupId, groupType)}/lunch`,
  },
  printClassLayout:
    "/users/:userId/groups/:groupId/grouptype/:groupType/rooms/:roomId/roomLayouts/:roomLayoutId/layouts/:layoutId/layoutDirection/:layoutDirection/isPrivate/:isPrivate/noProfileImage/:noProfileImage/print",
  whiteboardView:
    "/users/:userId/groups/:groupId/grouptype/:groupType/rooms/:roomId/roomLayouts/:roomLayoutId/layouts/:layoutId/layoutDirection/:layoutDirection/isPrivate/:isPrivate/noProfileImage/:noProfileImage/whiteboard",
  getGroupPath: (groupId, groupType) => groupPath(groupId, groupType),
  newGroup: "/new",
  editGroup: "/:groupId/edit",
};

export const studentRoutes = {
  //student: `/planner/students/:studentId/:pathParam1?`, I've commented this out to avoid confusion. The route used to map student is in routes planner.student
  getStudentPath: (studentId) => `${routes.home}/planner/students/${studentId}`,
  getStudentTabPath: (studentId, tabPath) => `${studentRoutes.getStudentPath(studentId)}${tabPath}`
};

export const assignmentRoutes = {
  default: `/assignments`,
  assignment: `/:id`,
  getAssignmentPath: id => `${routes.home}/assignments/${id}`,
  newAssignment: `/new`,
  editAssignment: `/:id/edit`,
  editAssignmentPath: id => `${routes.home}/assignments/${id}/edit`,
};

export const activityRoutes = {
  default: "/activity",
};
