import React, { useEffect } from "react";
import {
  Flyout,
  ActionBar,
  Button,
  Swatches,
  Message,
  ToastService,
} from "ui-kit";
import { Constants } from "configuration";
import flyoutActions from "actions/ui/flyouts";
import CreditDetails from "./creditDetails";
import paymentActions from "areas/payments/actions/paymentsActions";
import { RoleView } from "sharedComponents/common";
import { SmartcardSyncResultListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


interface IPrintCreditFlyoutProps {
  credit: SmartcardSyncResultListView;
  onCancelled?: (credit: SmartcardSyncResultListView) => void;
  onRequeued?: (credit: SmartcardSyncResultListView) => void;
  working?: boolean;
  error?: string;
}


const PrintCreditFlyout: React.FC<IPrintCreditFlyoutProps> = ({
  credit,
  onCancelled,
  onRequeued,
  working,
  error,
}) => {

  const handleClose = () => {
    flyoutActions.closeFlyout();
  };

  const handleCancel = () => {
    if (window.confirm("This will cancel this sync record. This means that the credit will not be synced to Papercut. Are you sure?")) {
      paymentActions.cancelPrintCredit(credit.user.id, credit.id, () => {
        flyoutActions.closeFlyout();
        ToastService.pop("Sync Record Cancelled", null, "id-card-alt");
        onCancelled?.(credit);
      });
    }
  }

  const handleRequeue = () => {
    if (window.confirm("This will requeue the credit for syncing to Papercut. Are you sure?")) {
      paymentActions.requeuePrintCredit(credit.user.id, credit.id, () => {
        flyoutActions.closeFlyout();
        ToastService.pop("Sync Record Requeued", null, "id-card-alt");
        onRequeued?.(credit);
      });
    }
  }

  return (
    <Flyout title="Credit Sync Record" name={Constants.FLYOUTS.CREDIT} wide>
      <Flyout.Body scrollOnErrors={[ error ]}>
        <Message text={error} color={Swatches.Danger} />
        <CreditDetails credit={credit} />
      </Flyout.Body>
      <Flyout.Footer>
        <RoleView roles={[Constants.ROLES.SHOPKEEPER_ADMINISTRATOR]}>
          <ActionBar low>
            {(credit?.status === 0 || credit?.status === 2) && (
              <Button
                text="Cancel Sync"
                onClick={handleCancel}
                color={Swatches.Danger}
                fluid
                working={working}
              />
            )}

            {credit?.status === 3 && (
              <Button
                text="Requeue Sync"
                onClick={handleRequeue}
                color={Swatches.Success}
                fluid
                working={working}
              />
            )}
          </ActionBar>
        </RoleView>
      </Flyout.Footer>
    </Flyout>
  );
};

export default PrintCreditFlyout;
