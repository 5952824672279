import React from "react";
import { useAction } from "hooks";
import { useSelector } from "react-redux";
import { Dropdown } from "ui-kit";
import searchEngineActions from "areas/search/actions/searchEngineActions";
import { RootState } from "reducers/store";
import { StaffListView } from "types/users/userListViews.types";
import { SearchResultView } from "types/common/views.types";


interface IStaffSearchProps {
  onChange?: (value: StaffListView, label: any) => void;
  fluid?: boolean;
  excludeSelf?: boolean; 
  schools?: number[];
}

const StaffSearch: React.FC<IStaffSearchProps> = ({ onChange, fluid, excludeSelf, schools }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { staff, loading, error } = useSelector((state: RootState) => state.searchStaff);

  const handleStaffSearch = (value: any) => {
    if (value && value.length > 2) {
      searchEngineActions.searchStaff(value, null, schools);
    }
  };

  return (
    <Dropdown
      className="staff-search"
      items={staff?.items?.map((staff: SearchResultView<StaffListView>, index: number) =>
        excludeSelf && staff.id == user.id
          ? null
          : {
              key: index,
              label: `${staff.data.firstName} ${staff.data.lastName}`,
              value: staff.data,
              sub: staff.description,
            }
      )}
      autocomplete
      placeholder="Search staff members..."
      searchPlaceholder="Search staff members..."
      onSearchChange={handleStaffSearch}
      onChange={onChange}
      loading={loading}
      error={error && "An error occurred"}
      fluid={fluid}
    />
  );
};

export default StaffSearch;
