import { IBeehiveError } from "types/common/errors.types";
import schoolActions from "../../actions/school/schoolActions";
import { PlannerSchoolView } from "areas/planner/types/plannerSchoolResponse.types";
import { IBeehiveAction } from "types/common/action.types";


interface ISchoolState {
  schoolInformation: PlannerSchoolView | null;
  loading: boolean;
  getSchoolInfoError: IBeehiveError;
}

const INITIAL_STATE: ISchoolState = {
  schoolInformation: null,
  loading: false,
  getSchoolInfoError: null,
};


const schoolReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISchoolState => {

  const { GETSCHOOLINFORMATION } = schoolActions.types;

  switch (action.type) {
    case GETSCHOOLINFORMATION.START:
      return { ...state, loading: true, getSchoolInfoError: null };

    case GETSCHOOLINFORMATION.SUCCESS:
      return { ...state, loading: false, schoolInformation: action.payload };

    case GETSCHOOLINFORMATION.FAILED:
      return { ...state, loading: false, getSchoolInfoError: action.payload };

    default:
      return state;
  }
};

export default schoolReducer;
