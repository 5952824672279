import store from "reducers/store";
import { IBeehiveAction } from "types/common/action.types";

const types = {
  PRINTCREDITS_FILTERBYSTATUS: "PRINTCREDITS_FILTERBYSTATUS",
  PRINTCREDITS_FILTERBYSCHOOL: "PRINTCREDITS_FILTERBYSCHOOL",
  PRINTCREDITS_FILTERBYSEARCHTERM: "PRINTCREDITS_FILTERBYSEARCHTERM",
};

const filterBySchool = (schoolId: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.PRINTCREDITS_FILTERBYSCHOOL,
    payload: schoolId,
  });
};

const filterByStatus = (status: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.PRINTCREDITS_FILTERBYSTATUS,
    payload: status,
  });
};

const filterBySearchTerm = (searchTerm: string) => {
  store.dispatch<IBeehiveAction<string>>({
    type: types.PRINTCREDITS_FILTERBYSEARCHTERM,
    payload: searchTerm,
  });
};

const printCreditFilterActions = {
  types,
  filterBySchool,
  filterByStatus,
  filterBySearchTerm,
};

export default printCreditFilterActions;
