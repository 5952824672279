import React from "react";
import styled from "styled-components";
import { useReview } from "./reviewContext";
import { Constants } from "configuration";
import { StructuredList, Chip, Card, HtmlText, Text } from "ui-kit";

const Wrapper = styled.div``;

const ReviewSummary = () => {

  const { state } = useReview();

  const displayProseContent = (prose: string, emptyMessage: string) => {
    return prose ? <HtmlText html={prose} /> : <Text>{emptyMessage}</Text>;
  };

  return state.review ? (
    <Wrapper>
      {state.review.targets.map((target, index) => (
        <Card key={index} title={`Target ${index + 1}`}>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="Target Details">
                {displayProseContent(
                  target.description,
                  "No target description added"
                )}
              </StructuredList.Item>
              <StructuredList.Item name="Success Criteria">
                {displayProseContent(
                  target.successCriteria,
                  "No success criteria added"
                )}
              </StructuredList.Item>
              <StructuredList.Item name="Training Requirements">
                {displayProseContent(
                  target.trainingRequirements,
                  "No target training requirements added"
                )}
              </StructuredList.Item>

              <>
              {!state.review.currentReviewPeriod ||
                (state.review.currentReviewPeriod.type.valueOf() !==
                  Constants.REVIEW_PERIODS.YEARSTART && (
                  <>
                    <StructuredList.Item name="Progress">
                      {target.progressStatus && (
                        <Chip text={target.progressStatus.toString()} />
                      )}
                    </StructuredList.Item>
                    <StructuredList.Item name="Progress Notes">
                      {displayProseContent(
                        target.progressNotes,
                        "No progress notes added"
                      )}
                    </StructuredList.Item>
                    <StructuredList.Item name="Evidence">
                      {displayProseContent(
                        target.evidence,
                        "No evidence added"
                      )}
                    </StructuredList.Item>
                  </>
                ))}
                </>
            </StructuredList>
          </Card.Body>
        </Card>
      ))}
      <Card title="CPD & Training">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Training Requirements">
              {displayProseContent(
                state.review.trainingRequirements,
                "No training requirements added"
              )}
            </StructuredList.Item>
            <StructuredList.Item name="Action Required by School or Trust">
              {displayProseContent(
                state.review.trainingActionRequired,
                "No training action required"
              )}
            </StructuredList.Item>
            <StructuredList.Item name="CPD Undertaken">
              {displayProseContent(state.review.cpd, "No CPD undertaken")}
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      {!state.review.inCurrentAcademicYear ||
        (state.review.currentReviewPeriod &&
          state.review.currentReviewPeriod.type.valueOf() === Constants.REVIEW_PERIODS.YEAREND && (
            <Card title="Year End">
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="Reviewee Summary">
                    {displayProseContent(
                      state.review.revieweeSummary,
                      "No reviewee summary added"
                    )}
                  </StructuredList.Item>
                  <StructuredList.Item name="Line Manager Summary">
                    {displayProseContent(
                      state.review.lineManagerSummary,
                      "No line manager summary added"
                    )}
                  </StructuredList.Item>
                  <StructuredList.Item name="Overall Performance">
                    <Chip text={state.review.rating.toString()} />
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
          ))}
    </Wrapper>
  ) : null;
};

export default ReviewSummary;
