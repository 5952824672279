import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Flyout,
  ActionBar,
  Button,
  Swatches,
  Message,
  StructuredList,
  Card,
  TextInput,
  TextInputType,
  ValidationMessage,
} from "ui-kit";
import { Constants } from "configuration";
import flyoutActions from "actions/ui/flyouts";
import { arrays } from "utils";


const Wrapper = styled.div`
  .topupValue {
    min-width: auto;
  }
  .topupValue input {
    min-width: 4rem;
    width: 4rem;
    max-width: 4rem;
  }
`;


interface ICustomTopupFlyoutProps {
  working?: boolean;
  error?: string;
  onTopupAdded?: (topupValue: number) => void;
}


const CustomTopupFlyout: React.FC<ICustomTopupFlyoutProps> = ({ working, error, onTopupAdded }) => {

  const [topupValue, setTopupValue] = useState<number>(5);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const handleAdd = () => {
    // Validation
    var errors = [];

    if (topupValue <= 3.5) {
      errors.push(`Please enter a valid topup amount over £3.50.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    onTopupAdded?.(topupValue);
    flyoutActions.closeFlyout();
  };

  const handleClose = () => {
    flyoutActions.closeFlyout();
  };

  return (
    <Flyout title="Add Custom Topup" name={Constants.FLYOUTS.CUSTOM_TOPUP}>
      <Flyout.Body scrollOnErrors={[ error, ...validationErrors ]}>
        <Message text={error} color={Swatches.Danger} />
        <ValidationMessage errors={validationErrors} />
        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item
                name="Topup Amount"
                required
                description="Please enter an amount over £3.50 to credit the smartcard with."
              >
                <Wrapper>
                  <TextInput
                    className="topupValue"
                    type={TextInputType.Currency}
                    value={topupValue}
                    onChange={(value) => setTopupValue(value)}
                    min={3.5}
                    fluid
                  />
                </Wrapper>
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      </Flyout.Body>
      <Flyout.Footer>
        <ActionBar low>
          <Button
            text="Add Topup"
            onClick={handleAdd}
            color={Swatches.Success}
            fluid
            working={working}
          />
          <Button
            text="Close"
            onClick={handleClose}
            color={Swatches.Low}
            fluid
            working={working}
          />
        </ActionBar>
      </Flyout.Footer>
    </Flyout>
  );
};

export default CustomTopupFlyout;
