import { IBeehiveAction } from "types/common/action.types";
import fileActions from "../actions/fileActions";


interface IUploadState {
  results: number[];
  uploading: boolean;
  error: string | null;
}

const INITIAL_STATE: IUploadState = {
  results: [],
  uploading: false,
  error: null,
};


const uploadReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUploadState => {

  const { UPLOAD_FILES } = fileActions.types;

  switch (action.type) {
    case UPLOAD_FILES.START:
      return { ...INITIAL_STATE, uploading: true };
    case UPLOAD_FILES.SUCCESS:
      return { ...INITIAL_STATE, results: action.payload };
    case UPLOAD_FILES.FAILED:
      return { ...INITIAL_STATE, error: action.payload, uploading: false };
    default:
      return state;
  }
};

export default uploadReducer;
