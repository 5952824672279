import React, { useRef } from "react";
import styled from "styled-components";
import {
  BorderRadius,
  typescale,
  textFont,
  Speed,
  neutral,
  Spacing,
  fontStyle,
  honeygold,
  Tooltip,
  IUiKitBaseProps,
} from "../index";
import Tippy from "@tippyjs/react";

const Wrapper = styled.div<{ active?: boolean }>`
  display: inline-flex;
  flex-grow: 0;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: ${BorderRadius.Default}px;
  padding: ${Spacing.ExtraSmall}px ${Spacing.Default}px;

  background: ${neutral[400]};
  color: ${neutral[100]};

  ${fontStyle(textFont.bold, typescale.paragraph, neutral[100])}

  &:hover {
    background: ${neutral[600]};
    cursor: pointer;
    transition: all ${Speed.Default}ms ease;
  }

  ${({ active }) =>
    active &&
    `
    background ${honeygold};

  `}
`;

interface IFilterButtonProps extends IUiKitBaseProps {
  text: string;
  active?: boolean;
  tooltip: string;
  tooltipSub?: any;
}

const FilterButton: React.FC<IFilterButtonProps> = ({
  text,
  active,
  className,
  onClick,
  tooltip,
  tooltipSub,
}) => {
  const handleClick = () => {
    onClick?.();
  };
  const ref = useRef() as React.RefObject<HTMLDivElement>;

  return (
    <>
      <Wrapper
        ref={ref}
        className={`filter-button form-control ${className ? className : ""}`}
        active={active}
        onClick={handleClick}
      >
        {text}
      </Wrapper>
      {tooltip != null && (
        <Tippy
          reference={ref}
          content={<Tooltip title={tooltip} sub={tooltipSub} />}
        />
      )}
    </>
  );
};

export default FilterButton;
