import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { urls } from "utils";
import store from "reducers/store";
import { BeehiveReport, IBeehiveAction, callbackType } from "types/common/action.types";
import { ConsentFormsFilter, ConsentReportFilter, SaveConsentFormCommand, SubmitConsentResponseCommand } from "../types/consentRequest.types";
import { PagedQueryView } from "types/common/views.types";
import { ConsentFormCheckView, ConsentFormDetailView, ConsentFormListView, ConsentFormUserView, ConsentResponseDetailView, ConsentResponseListView } from "../types/consentResponses.types";
import { UserListView } from "types/users/userListViews.types";
import { AdminConsentFormsFilter } from "../reducers/admin/adminConsentFormsReducer";

const builder = new actionTypeBuilder("consent");

const types = {
  CONSENT_CHECKNEWFORMSFORUSER: builder.build("CONSENT_CHECKNEWFORMSFORUSER"),
  CONSENT_GETFORMS: builder.build("CONSENT_GETFORMS"),
  CONSENT_GETFORMFORUSER: builder.build("CONSENT_GETFORMFORUSER"),
  CONSENT_GETFORMFORPARENT: builder.build("CONSENT_GETFORMFORPARENT"),
  CONSENT_GETNEWFORMSFORUSER: builder.build("CONSENT_GETNEWFORMSFORUSER"),
  CONSENT_GETNEWFORMSFORPARENT: builder.build("CONSENT_GETNEWFORMSFORPARENT"),
  CONSENT_GETCOMPLETEDFORMSFORUSER: builder.build(
    "CONSENT_GETCOMPLETEDFORMSFORUSER"
  ),
  CONSENT_GETCOMPLETEDFORMSFORPARENT: builder.build(
    "CONSENT_GETCOMPLETEDFORMSFORPARENT"
  ),
  CONSENT_SUBMITCONSENT: builder.build("CONSENT_SUBMITCONSENT"),
  CONSENT_GETCHILDRENWITHCONSENTFORPARENT: builder.build(
    "CONSENT_GETCHILDRENWITHCONSENTFORPARENT"
  ),
  CONSENT_GETCONSENTFORMSFORRESPONSIBLESTAFF: builder.build(
    "CONSENT_GETCONSENTFORMSFORRESPONSIBLESTAFF"
  ),
  CONSENT_CREATECONSENTFORM: builder.build("CONSENT_CREATECONSENTFORM"),
  CONSENT_SAVECONSENTFORM: builder.build("CONSENT_SAVECONSENTFORM"),

  CONSENT_GETCONSENTFORMFORADMIN: builder.build(
    "CONSENT_GETCONSENTFORMFORADMIN"
  ),

  CONSENT_GETRESPONSESFORADMIN: builder.build("CONSENT_GETRESPONSESFORADMIN"),
  CONSENT_GETNORESPONSESFORADMIN: builder.build(
    "CONSENT_GETNORESPONSESFORADMIN"
  ),
  CONSENT_GETRESPONSEFORADMIN: builder.build("CONSENT_GETRESPONSEFORADMIN"),
  CONSENT_RESPONSEREPORT: builder.build("CONSENT_RESPONSEREPORT"),
  CONSENT_UPDATEFILTER: "CONSENT_UPDATEFILTER",
};


const getConsentForms = (filter: ConsentFormsFilter, pageIndex: number, callback?: callbackType<PagedQueryView<ConsentFormListView>>) => {
  var url = `users/consent/forms?pageIndex=${pageIndex}`;
  if (filter) {
    if (filter.status !== -1) {
      url = url + `&status=${filter.status}`;
    }
  }

  return action<PagedQueryView<ConsentFormListView>>(
    () => client.get(url), 
    types.CONSENT_GETFORMS, 
    callback
  );
};

const checkNewConsentFormsForUser = (userId: string, callback?: callbackType<ConsentFormCheckView>) =>
  action<ConsentFormCheckView>(
    () => client.get(`users/consent/users/${userId}/forms/new/check`),
    types.CONSENT_CHECKNEWFORMSFORUSER,
    callback
  );

const getConsentFormForUser = (userId: string, formId: number, callback?: callbackType<ConsentFormUserView>) =>
  action<ConsentFormUserView>(
    () => client.get(`users/consent/users/${userId}/forms/${formId}`),
    types.CONSENT_GETFORMFORUSER,
    callback
  );

const getConsentFormForParent = (parentId: string, formId: number, callback?: callbackType<ConsentFormUserView>) =>
  action<ConsentFormUserView>(
    () => client.get(`users/consent/parents/${parentId}/forms/${formId}`),
    types.CONSENT_GETFORMFORPARENT,
    callback
  );

const getNewConsentFormsForUser = (userId: string, callback?: callbackType<ConsentFormUserView[]>) =>
  action<ConsentFormUserView[]>(
    () => client.get(`users/consent/users/${userId}/forms/new`),
    types.CONSENT_GETNEWFORMSFORUSER,
    callback
  );

const getNewConsentFormsForParent = (parentId: string, callback?: callbackType<ConsentFormUserView[]>) =>
  action<ConsentFormUserView[]>(
    () => client.get(`users/consent/parents/${parentId}/forms/new`),
    types.CONSENT_GETNEWFORMSFORPARENT,
    callback
  );

const getCompletedConsentFormsForUser = (userId: string, callback?: callbackType<ConsentFormUserView[]>) =>
  action<ConsentFormUserView[]>(
    () => client.get(`users/consent/users/${userId}/forms/completed`),
    types.CONSENT_GETCOMPLETEDFORMSFORUSER,
    callback
  );

const getCompletedConsentFormsForParent = (parentId: string, callback?: callbackType<ConsentFormUserView[]>) =>
  action<ConsentFormUserView[]>(
    () => client.get(`users/consent/parents/${parentId}/forms/completed`),
    types.CONSENT_GETCOMPLETEDFORMSFORPARENT,
    callback
  );

const getConsentFormsForResponsibleStaff = (staffId: string, callback?: callbackType<ConsentFormListView[]>) =>
  action<ConsentFormListView[]>(
    () => client.get(`users/consent/staff/${staffId}/forms`),
    types.CONSENT_GETCONSENTFORMSFORRESPONSIBLESTAFF,
    callback
  );

const submitConsentResponse = (userId: string, formId: number, command: SubmitConsentResponseCommand, onSuccess?: callbackType<null>, onError?: callbackType<null>) =>
  action<null>(
    () => client.post(`users/consent/forms/${formId}/submit`, command),
    types.CONSENT_SUBMITCONSENT,
    onSuccess,
    onError
  );

// export const getChildrenWithConsentForParent = (userId, parentId, callback) =>
//   action(
//     () =>
//     client
//       .get(`users/consent/parents/${parentId}/forms/${formId}/children`),
//     types.CONSENT_GETCHILDRENWITHCONSENTFORPARENT,
//     callback
//   );

const saveConsentForm = (formId: number, data: SaveConsentFormCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`users/consent/forms/${formId}`, data),
    types.CONSENT_SAVECONSENTFORM,
    callback
  );

const createConsentForm = (data: SaveConsentFormCommand, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`users/consent/forms`, data),
    types.CONSENT_CREATECONSENTFORM,
    callback
  );

const getConsentFormForAdmin = (formId: number, callback?: callbackType<ConsentFormDetailView>) =>
  action<ConsentFormDetailView>(
    () => client.get(`users/consent/forms/${formId}`),
    types.CONSENT_GETCONSENTFORMFORADMIN,
    callback
  );

const getResponsesForAdmin = (formId: number, filter: ConsentReportFilter, pageIndex: number, callback?: callbackType<PagedQueryView<ConsentResponseListView>>) => {
  var url = new urls.QueryString(`users/consent/forms/${formId}/responses`);
  url.addParams(filter);
  url.addPaging(pageIndex);

  return action<PagedQueryView<ConsentResponseListView>>(
    () => client.get(url.toUrl()),
    types.CONSENT_GETRESPONSESFORADMIN,
    callback
  );
}

const getNoResponsesForAdmin = (formId: number, filter: ConsentReportFilter, pageIndex: number, callback?: callbackType<PagedQueryView<UserListView>>) => {
  var url = new urls.QueryString(`users/consent/admin/forms/${formId}/noresponse`);
  url.addParams(filter);
  url.addPaging(pageIndex);

  action<PagedQueryView<UserListView>>(
    () => client.get(url.toUrl()),
    types.CONSENT_GETNORESPONSESFORADMIN,
    callback
  );
}

const getResponseForAdmin = (formId: number, responseId: number, callback?: callbackType<ConsentResponseDetailView>) =>
  action<ConsentResponseDetailView>(
    () => client.get(`users/consent/forms/${formId}/responses/${responseId}`),
    types.CONSENT_GETRESPONSEFORADMIN,
    callback
  );

const consentFormResponseReport = (formId: number, filter: ConsentReportFilter, callback?: callbackType<BeehiveReport>) => {
  var url = new urls.QueryString(
    `users/consent/forms/${formId}/responses/report`
  );
  url.addParams(filter);

  return action<BeehiveReport>(
    () => client.post(url.toUrl(), null, { responseType: "text" }),
    types.CONSENT_RESPONSEREPORT,
    callback
  );
};

const updateConsentFormFilter = (filter: AdminConsentFormsFilter) => {
  store.dispatch<IBeehiveAction<AdminConsentFormsFilter>>({
    type: types.CONSENT_UPDATEFILTER,
    payload: filter,
  });
};


const consentActions = {
  types,
  getConsentForms,
  checkNewConsentFormsForUser,
  getConsentFormForUser,
  getConsentFormForParent,
  getNewConsentFormsForUser,
  getNewConsentFormsForParent,
  getCompletedConsentFormsForUser,
  getCompletedConsentFormsForParent,
  getConsentFormsForResponsibleStaff,
  submitConsentResponse,
  saveConsentForm,
  createConsentForm,
  getConsentFormForAdmin,
  getResponsesForAdmin,
  getNoResponsesForAdmin,
  getResponseForAdmin,
  consentFormResponseReport,
  updateConsentFormFilter,
};

export default consentActions;
