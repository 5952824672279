import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  ToastService,
  Sub,
  Loader,
  Size,
  Button,
  EmptyMessage,
  Card,
  StructuredList,
  Swatches,
  ChipList,
  Message,
  Title,
  TitleSize,
  Text,
  DateTime,
  Chip,
  formatScrollbars,
  neutral,
} from "ui-kit";
import DeliveryStatus from "./deliveryStatus";
import styled from "styled-components";
import mailoutActions from "../actions/mailoutActions";
import SchoolAvatar from "sharedComponents/common/schools/schoolAvatar";
import { RootState } from "reducers/store";
import { managedUserAccount } from "areas/administration/reducers/userManagement/managedUserReducer";
import { QueueMessageUserListView } from "../types/mailoutResponse.types";


const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  iframe {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto !important;
    ${formatScrollbars(neutral[200])};
  }
`;

interface IQueueMessageModalProps {
  message: QueueMessageUserListView;
  user: managedUserAccount;
  open: boolean;
  onClose: () => void;
}

const QueueMessageModal: React.FC<IQueueMessageModalProps> = ({ message, user, open, onClose }) => {

  const { queueMessage, loading, error, sending, sendError } = useSelector(
    (state: RootState) => state.queueMessage
  );
  const [_open, _setOpen] = useState<boolean>(false);

  useEffect(() => {
    // Get the full queueMessage
    message && mailoutActions.getQueueMessageForUser(message.id, user.id);
  }, [message]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const handleSend = () => {
    mailoutActions.sendQueueMessage(message.id, () => {
      ToastService.pop("Message Sent", null, "envelope");
    });
  };

  return (
    <Modal
      width="80%"
      height="90%"
      title={message?.messageType === 0 ? "Email Message" : "SMS Message"}
      open={_open}
      onClose={onClose}
    >
      <Modal.Body>
        {loading ? (
          <Loader size={Size.Medium} cover />
        ) : queueMessage == null ? (
          <EmptyMessage
            icon="envelope"
            title="No Message"
            summary="The requested message was not found"
            cover
          />
        ) : (
          <Wrapper>
            <Title text={queueMessage.subject} size={TitleSize.H4} />
            <Message text={sendError} color={Swatches.Danger} />
            <Card>
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="Sender">
                    <SchoolAvatar school={queueMessage.school} />
                  </StructuredList.Item>
                  <StructuredList.Item name="To">
                    <ChipList>
                      {queueMessage.recipients.map((recipient, index) => (
                        <Chip
                          text={
                            <>
                              <b>{recipient.displayName}</b> (
                              {recipient.address})
                            </>
                          }
                          colorSwatch={Swatches.Blue}
                        />
                      ))}
                    </ChipList>
                  </StructuredList.Item>
                  <StructuredList.Item name="Queued">
                    <DateTime bold date={queueMessage.queuedOn} />
                  </StructuredList.Item>
                  <StructuredList.Item
                    name="Delivery"
                    rightContent={
                      queueMessage.deliveryRecord.status > 0 && (
                        <Button
                          text="Resend"
                          size={Size.Small}
                          onClick={handleSend}
                          color={Swatches.Primary}
                          working={sending}
                        />
                      )
                    }
                  >
                    {queueMessage.deliveryRecord?.deliveredOn ? (
                      <DateTime
                        bold
                        date={queueMessage.deliveryRecord?.deliveredOn}
                      />
                    ) : (
                      <DeliveryStatus
                        status={queueMessage.deliveryRecord?.status}
                      />
                    )}
                  </StructuredList.Item>
                  {queueMessage.messageType === 0 && (
                    <StructuredList.Item name="Read">
                      {queueMessage.readOn ? (
                        <DateTime date={queueMessage.readOn} />
                      ) : (
                        "Not Read"
                      )}
                    </StructuredList.Item>
                  )}
                </StructuredList>
              </Card.Body>
            </Card>
            {queueMessage.messageType === 0 ? (
              <iframe title="Email Body" srcDoc={queueMessage.body} />
            ) : (
              <Card title="Message Content">
                <Card.Body>
                  <Text>{queueMessage.body}</Text>
                </Card.Body>
                <Card.Footer>
                  <Sub>{queueMessage.body.length.toString()} Characters</Sub>
                </Card.Footer>
              </Card>
            )}
          </Wrapper>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default QueueMessageModal;
