import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  StructuredList,
  Button,
  Swatches,
  RichTextEditor,
  Message,
  ToastService,
  Size,
} from "ui-kit";
import adminSubmissionActions from "areas/forms/actions/adminSubmissionActions";
import { SubmissionDetailView } from "areas/forms/types/formResponse.types";
import { RootState } from "reducers/store";
import { AddSubmissionNoteCommand } from "areas/forms/types/formRequest.types";


interface ISubmissionNoteEditorProps {
  submission: SubmissionDetailView;
}


const SubmissionNoteEditor: React.FC<ISubmissionNoteEditorProps> = ({ submission }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { savingNote, noteError } = useSelector((state: RootState) => state.adminSubmission);

  const [noteText, setNoteText] = useState<string>("");
  const [noteValidationError, setNoteValidationError] = useState<string>("");

  const handleAddNote = () => {
    setNoteValidationError("");
    if (!noteText) {
      setNoteValidationError("Please enter a note.");
      return;
    }

    const data: AddSubmissionNoteCommand = {
      userId: user.id,
      submissionId: submission.id,
      formId: submission.formId,
      categoryId: submission.form.categoryId,
      content: noteText,
    };

    adminSubmissionActions.addNote(data, () => {
      ToastService.pop("Note added successfully", null, "comment-lines");
    });
  };

  return (
    <Card>
      <Card.Body>
        <Message
          text={noteValidationError || noteError}
          color={Swatches.Danger}
          icon="times-circle"
        />
        <StructuredList>
          <StructuredList.Item name="Add a Note">
            <RichTextEditor onChange={value => setNoteText(value)} />
          </StructuredList.Item>
        </StructuredList>
      </Card.Body>
      <Card.Footer>
        <Button
          text="Add Note"
          color={Swatches.Success}
          onClick={handleAddNote}
          working={savingNote}
          size={Size.Small}
        />
      </Card.Footer>
    </Card>
  );
};

export default SubmissionNoteEditor;
