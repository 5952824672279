import { ArticleDetailView, CommentListView } from "areas/news/types/newsResponse.types";
import magazineActions from "../../actions/magazineActions";
import { IBeehiveAction } from "types/common/action.types";

interface IMagazineFeatureState {
  loading: boolean;
  error: string | null;
  feature: ArticleDetailView | null;
  comments: CommentListView[];
  loadingComments: boolean;
  commentsError: string | null;
  posting: boolean;
}

const INITIAL_STATE: IMagazineFeatureState = {
  loading: false,
  error: null,
  feature: null,
  comments: [],
  loadingComments: false,
  commentsError: null,
  posting: false,
};

const findComment = (comments: CommentListView[], id: number) : CommentListView | null => {
  if (comments) {
    for (var i = 0; i < comments.length; i++) {
      if (comments[i].id === id) {
        return comments[i];
      }
      var found = findComment(comments[i].comments, id);
      if (found) return found;
    }
  }
};

const magazineFeatureReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IMagazineFeatureState => {

  const {
    MAGAZINE_GETFEATURE,
    MAGAZINE_GETFEATURECOMMENTS,
    MAGAZINE_POSTCOMMENT,
    MAGAZINE_APPROVECOMMENT,
    MAGAZINE_REMOVECOMMENT,
    MAGAZINE_FLAGCOMMENT,
  } = magazineActions.types;

  switch (action.type) {
    case MAGAZINE_GETFEATURE.START:
      return { ...INITIAL_STATE, loading: true, error: null };

    case MAGAZINE_GETFEATURE.SUCCESS:
      var newState = {
        ...state,
        feature: action.payload,
        loading: false,
      };
      return newState;

    case MAGAZINE_GETFEATURE.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case MAGAZINE_GETFEATURECOMMENTS.START:
      return {
        ...state,
        loadingComments: true,
        commentsError: null,
      };

    case MAGAZINE_GETFEATURECOMMENTS.SUCCESS:
      return {
        ...state,
        loadingComments: false,
        comments: action.payload,
      };

    case MAGAZINE_GETFEATURECOMMENTS.FAILED:
      return {
        ...state,
        loadingComments: false,
        comments: [],
        commentsError: action.payload,
      };

    case MAGAZINE_POSTCOMMENT.START:
      return {
        ...state,
        posting: true,
        commentsError: null,
      };

    case MAGAZINE_POSTCOMMENT.SUCCESS:
      var newComments = state.comments;

      if (action.payload.parentId) {
        var parent = findComment(newComments, action.payload.parentId);
        parent.comments = [action.payload, ...parent.comments];
      } else {
        newComments = [action.payload, ...state.comments];
      }

      return {
        ...state,
        posting: false,
        comments: newComments,
      };

    case MAGAZINE_POSTCOMMENT.FAILED:
      return {
        ...state,
        posting: false,
        commentsError: action.payload,
      };

    case MAGAZINE_FLAGCOMMENT.START:
    case MAGAZINE_APPROVECOMMENT.START:
    case MAGAZINE_REMOVECOMMENT.START:
      return {
        ...state,
        comments: state.comments.map(x =>
          x.id === action.payload.id ? { ...x, working: true, error: null } : x
        ),
      };

    case MAGAZINE_FLAGCOMMENT.SUCCESS:
    case MAGAZINE_APPROVECOMMENT.SUCCESS:
    case MAGAZINE_REMOVECOMMENT.SUCCESS:
      return {
        ...state,
        comments: state.comments.map(x =>
          x.id === action.payload.id ? { ...action.payload, working: false } : x
        ),
      };

    case MAGAZINE_FLAGCOMMENT.FAILED:
    case MAGAZINE_APPROVECOMMENT.FAILED:
    case MAGAZINE_REMOVECOMMENT.FAILED:
      return {
        ...state,
        comments: state.comments.map(x =>
          x.id === action.payload.id
            ? { ...x, working: true, error: "A problem occurred" }
            : x
        ),
      };

    default:
      return state;
  }
};

export default magazineFeatureReducer;
