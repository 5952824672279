import { OrderLineView, ProductAvailibilityView, UserProductOrderView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import React, {  } from "react";
import Moment from "react-moment";
import { Chip, Currency, Table } from "ui-kit";
import InstalmentButton from "./instalmentButton";
import { InstallmentType } from "areas/payments/types/shopkeeper/shopkeeperShared.types";
import { UserTinyView } from "types/users/userListViews.types";



interface IRelevantInstallmentsProps {
  user: UserTinyView;
  item: UserProductOrderView | OrderLineView;
  recipient: ProductAvailibilityView;
}

const RelevantInstallments: React.FC<IRelevantInstallmentsProps> = ({ user, item, recipient }) => {

  if (recipient.bursary && recipient.instalments.some(x => x.installmentType === InstallmentType.Bursary)) {
    return (
      <InstalmentsList 
        title={"Bursary Instalments"} 
        user={user}
        item={item}
        filter={InstallmentType.Bursary} 
        recipient={recipient}
      />
    )
  }
  if (recipient.pupilPremium && recipient.instalments.some(x => x.installmentType === InstallmentType.PupilPremium)) {
    return (
      <InstalmentsList 
        title={"Pupil Premium Instalments"} 
        user={user}
        item={item}
        filter={InstallmentType.PupilPremium} 
        recipient={recipient}
      />
    )
  }
  return (
    <InstalmentsList 
      title={"Instalments"} 
      user={user}
      item={item}
      filter={InstallmentType.Default} 
      recipient={recipient}
    />
  )
}


interface IInstalmentsListProps {
  title: string;
  user: UserTinyView;
  item: UserProductOrderView | OrderLineView;
  filter: InstallmentType;
  recipient: ProductAvailibilityView;
}

const InstalmentsList: React.FC<IInstalmentsListProps> = ({ title, user, item, filter, recipient }) => {
  return (
    <Table>
      <Table.Body noHover>
        { recipient?.instalments
          .filter((x) => x.installmentType == filter)
          .map((instalment, index) => (
          <Table.Row key={index}>
            <Table.Cell width={6}>
              <Moment
                date={instalment.dueDate}
                format="dddd, MMMM Do YYYY"
              />{" "}
              {instalment.optionalForPupilPremium && (
                <Chip text="PP Optional" />
              )}
            </Table.Cell>
            <Table.Cell right width={2}>
              <b>
                <Currency value={instalment.amount} />
              </b>
            </Table.Cell>
            <Table.Cell right width={2}>
              <InstalmentButton
                user={user}
                item={item}
                recipient={recipient.student}
                instalment={instalment}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}


export default RelevantInstallments;