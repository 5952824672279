import defaults from "configuration/defaults";
import { arrays } from "utils";
import calendarActions from "../actions/calendarActions";
import { PageInfo } from "types/common/paging.types";
import { IBeehiveAction } from "types/common/action.types";
import { CalendarEventAppListView } from "../types/calendarResponses.types";


interface IUserCalendarEventsState {
  events: CalendarEventAppListView[],
  error: string | null;
  loading: boolean;
  paging: PageInfo;
  signingUp: boolean;
  signupError: string | null;
}

const INITIAL_STATE: IUserCalendarEventsState = {
  events: [],
  error: null,
  loading: false,
  paging: defaults.DEFAULT_PAGING,
  signingUp: false,
  signupError: null,
};

const userCalendarEventsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUserCalendarEventsState => {
  const {
    CALENDAR_GETUSERCALENDAREVENTS,
    CALENDAR_EVENTSIGNUP,
    CALENDAR_EVENTUNSIGNUP,
  } = calendarActions.types;

  switch (action.type) {
    case CALENDAR_GETUSERCALENDAREVENTS.START:
      return {
        ...state,
        loading: arrays.isEmpty(state.events),
        error: null,
      };

    case CALENDAR_GETUSERCALENDAREVENTS.SUCCESS:
      return {
        ...state,
        events:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.events, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };

    case CALENDAR_GETUSERCALENDAREVENTS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CALENDAR_EVENTSIGNUP.START:
      return { ...state, signingUp: true, signupError: null };

    case CALENDAR_EVENTSIGNUP.SUCCESS:
      console.log("HERE", action.payload);
      return {
        ...state,
        events: state.events.map(x => ({
          ...x,
          signedUp: true,
          dates: x.dates.map(d =>
            action.payload.some((a: number) => a === d.id)
              ? { ...d, signedUp: true, signups: d.signups + 1 }
              : d
          ),
        })),
      };

    case CALENDAR_EVENTSIGNUP.FAILED:
      return {
        ...state,
        signingUp: false,
        signupError:
          "An error occured while unregistering from the calendar event",
      };

    case CALENDAR_EVENTUNSIGNUP.START:
      return { ...state, signingUp: true, signupError: null };

    case CALENDAR_EVENTUNSIGNUP.SUCCESS:
      return {
        ...state,
        events: state.events.map(x => ({
          ...x,
          dates: x.dates.map(d =>
            action.payload.some((a: number) => a === d.id)
              ? { ...d, signedUp: true, signups: d.signups - 1 }
              : d
          ),
        })),
      };

    case CALENDAR_EVENTUNSIGNUP.FAILED:
      return {
        ...state,
        signingUp: false,
        signupError:
          "An error occured while unregistering from the calendar event",
      };

    default:
      return state;
  }
};

export default userCalendarEventsReducer;
