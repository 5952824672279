import React from "react";
import styled from "styled-components";
import { Loader, EmptyMessage, Title, TitleSize, Size, Button, Swatches, Spacing } from "ui-kit";
import ReviewEditor from "./reviewEditor";
import { ReviewProvider } from "./reviewContext";
import ReviewViewer from "./reviewViewer";
import { ReviewDetailView } from "areas/humanResources/types/managedStaffResponse.types";
import Subheader from "sharedComponents/layout/header/subheader";
import reviewActions from "areas/humanResources/actions/reviewActions";
import { useAppSelector } from "reducers/hooks";


const Wrapper = styled.div`
  flex-grow: 1;
`;


interface IReviewProps {
  review: ReviewDetailView;
  onSave?: (review: ReviewDetailView) => void;
  onDownload?: (review: ReviewDetailView) => void;
  loading?: boolean;
  error?: string;
  working?: boolean;
  saveError?: string;
}


const Review: React.FC<IReviewProps> = ({ review, onSave, onDownload, loading, error, working, saveError }) => {

  const { downloading } = useAppSelector(state => state.review)

  const handleSave = (review: ReviewDetailView) => {
    onSave?.(review);
  };

  const handleDownload = () => {
    onDownload?.(review);
  };

  if (loading) {
    return (
      <Wrapper>
        <Loader size={Size.Large} cover />
      </Wrapper>
    );
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem loading the review"
        cover
      />
    );
  }

  if (!review) {
    return (
      <EmptyMessage
        icon="rocket"
        title="Review not found"
        summary="The requested review was not found"
        cover
      />
    );
  }

  return (
    <Wrapper>
      <ReviewProvider review={review}>
      <Subheader style={{ marginBottom: Spacing.Large }}>
        <Title
          text={`Performance Review ${review.academicYear}`}
          size={TitleSize.H2}
          sub={`${review.reviewee.firstName} ${review.reviewee.lastName}`}
          right={
            <Button
              color={Swatches.Primary}
              text={"Download Review"}
              onClick={handleDownload}
              size={Size.Small}
              working={downloading}
            />
          }
        />
      </Subheader>
        {review.readOnly ? (
          <ReviewViewer />
        ) : (
          <ReviewEditor
            onSave={handleSave}
            working={working}
            error={saveError}
          />
        )}
      </ReviewProvider>
    </Wrapper>
  );
};

export default Review;
