import React, { useState } from "react";
import {
  Card,
  fontStyle,
  displayFont,
  honeygold,
  typescale,
  neutral,
  JumboPicker,
  Spacing,
  ActionBar,
  Button,
  Swatches,
  ValidationMessage,
} from "ui-kit";
import styled from "styled-components";
import { arrays } from "utils";
import UserName from "sharedComponents/common/users/userName";
import { useScroll } from "hooks/useScroll";
import { AssignmentDetailView } from "types/planner/assignments.types";
import { SubmitAssignmentCommand } from "areas/planner/types/assignments/assignmentRequest.types";

const SurveyWrapper = styled.div`
  p {
    text-align: center;
  }
  h2 {
    text-align: center;
    ${fontStyle(displayFont.medium, typescale.header3, neutral[600])}
  }
  .jumbo-picker {
    margin: ${Spacing.Large}px 0 56px 0;
  }
`;

export type SurveyType = Omit<SubmitAssignmentCommand, "studentId" | "assignmentId">

interface IAssignmentSubmissionProps {
  assignment: AssignmentDetailView;
  onSubmit: (survey: SurveyType) => void;
  onCancel: () => void;
  working?: boolean;
}


const AssignmentSubmission: React.FC<IAssignmentSubmissionProps> = ({ assignment, onSubmit, onCancel, working }) => {

  const { scrollToTop } = useScroll();
  const [survey, setSurvey] = useState<SurveyType>({
    difficulty: null,
    timescale: null,
    comments: null,
    requireAssistance: null,
    understoodRequirements: null,
  });
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const handleSubmit = () => {
    var errors = [];

    if (
      survey.timescale == null ||
      survey.difficulty == null ||
      survey.understoodRequirements == null ||
      survey.requireAssistance == null
    ) {
      errors.push(
        `Please answer all of the survey questions before submitting your assignment.`
      );
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    onSubmit?.(survey);
  };

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <>
      <Card>
        <Card.Body>
          <SurveyWrapper>
            <p>
              Before submitting the assignment, please let{" "}
              <UserName user={assignment.setBy} /> know how you got on by
              answering a few questions. Simply select an option for each
              question.
            </p>

            <h2>Did you understand what you had to do?</h2>

            <JumboPicker
              onChange={value =>
                setSurvey({ ...survey, understoodRequirements: value })
              }
            >
              <JumboPicker.Item
                value={1}
                label="Yes"
                icon="smile"
                iconColor="green"
                hoverBackgroundColor="green"
                hoverForegroundColor="white"
                sub="I understood what to do"
              />
              <JumboPicker.Item
                value={0}
                label="No"
                icon="frown"
                iconColor="red"
                hoverBackgroundColor="red"
                hoverForegroundColor="white"
                sub="I did not understand what to do"
              />
            </JumboPicker>

            <h2>How difficult was the assignment?</h2>
            <JumboPicker
              onChange={value => setSurvey({ ...survey, difficulty: value })}
            >
              <JumboPicker.Item
                value={0}
                label="Too Easy"
                icon="laugh"
                iconColor="green"
                hoverBackgroundColor="green"
                hoverForegroundColor="white"
                sub="It was too easy for me"
              />
              <JumboPicker.Item
                value={1}
                label="Easy"
                icon="smile"
                iconColor="green"
                hoverBackgroundColor="green"
                hoverForegroundColor="white"
                sub="It was easy for me"
              />
              <JumboPicker.Item
                value={2}
                label="OK"
                icon="meh-blank"
                iconColor={honeygold}
                hoverBackgroundColor={honeygold}
                hoverForegroundColor="white"
                sub="It was OK for me"
              />
              <JumboPicker.Item
                value={3}
                label="Hard"
                icon="meh"
                iconColor="red"
                hoverBackgroundColor="red"
                hoverForegroundColor="white"
                sub="It was hard but I managed"
              />
              <JumboPicker.Item
                value={4}
                label="Too Hard"
                icon="frown"
                iconColor="red"
                hoverBackgroundColor="red"
                hoverForegroundColor="white"
                sub="It was too hard to do"
              />
            </JumboPicker>

            <h2>
              Was the time suggested for doing the assignment long enough?
            </h2>
            <JumboPicker
              onChange={value => setSurvey({ ...survey, timescale: value })}
            >
              <JumboPicker.Item
                value={2}
                label="Too Long"
                icon="clock"
                iconColor="green"
                hoverBackgroundColor="green"
                hoverForegroundColor="white"
                sub="It took me less time than suggested"
              />

              <JumboPicker.Item
                value={1}
                label="OK"
                icon="clock"
                iconColor={honeygold}
                hoverBackgroundColor={honeygold}
                hoverForegroundColor="white"
                sub="It took about the time that was suggested"
              />
              <JumboPicker.Item
                value={0}
                label="Too Short"
                icon="clock"
                iconColor="red"
                hoverBackgroundColor="red"
                hoverForegroundColor="white"
                sub="It took me longer than suggested"
              />
            </JumboPicker>

            <h2>
              Would you like further help with anything in the assignment?
            </h2>

            <JumboPicker
              onChange={value =>
                setSurvey({ ...survey, requireAssistance: value })
              }
            >
              <JumboPicker.Item
                value={1}
                label="Yes Please"
                icon="check"
                iconColor="green"
                hoverBackgroundColor="green"
                hoverForegroundColor="white"
                sub="I'd like some help"
              />
              <JumboPicker.Item
                value={0}
                label="No Thanks"
                icon="times"
                iconColor="red"
                hoverBackgroundColor="red"
                hoverForegroundColor="white"
                sub="I don't need help"
              />
            </JumboPicker>
            <p>
              When you have answered the questions, click on{" "}
              <b>'Submit Assignment' </b> below to mark this assignment as
              complete.
            </p>
          </SurveyWrapper>
        </Card.Body>
      </Card>{" "}
      <ValidationMessage errors={validationErrors} />
      <ActionBar>
        <Button
          text="Submit Assignment"
          onClick={handleSubmit}
          color={Swatches.Primary}
          working={working}
        />
        <Button text="Cancel" onClick={handleCancel} />
      </ActionBar>
    </>
  );
};

export default AssignmentSubmission;
