
export enum DeclarationVersionStatus {
  Draft = 0,
  Submitted = 1
}

export enum DeclarationType {
  Employment = 0,
  AppointmentsAndDirectorships = 1,
  NonprofitMembership = 2,
  ShareholdingAndInvestment = 3,
  TrustConnection = 4,
  Contractual = 5,
  Other = 6
}