import React from "react";
import styled from "styled-components";
import {
  Button,
  fontStyle,
  neutral,
  typescale,
  textFont,
  BorderRadius,
  Spacing,
  Swatches,
  Subtitle,
  honeygold,
} from "ui-kit";
import FileIcon from "./fileIcon";
import { arrays } from "utils";
import fileActions from "areas/filing/actions/fileActions";
import { IBeehiveFile } from "types/common/files.types";

export interface IFileListProps {
  className?: string;
  blockDownload?: boolean;
  children?:
    | React.ReactElement<IFileListProps>
    | React.ReactElement<IFileListProps>[];
  title?: string;
}

const FileListWrapper = styled.div`
  margin-bottom: ${Spacing.Large}px;

  .subtitle {
    margin-bottom: ${Spacing.Small}px;
  }
`;

const FileList: React.FC<IFileListProps> & {
  Item: typeof FileListItem;
} = ({ children, className, title }) => {
  return arrays.isEmpty(children) ? null : (
    <FileListWrapper className="file-list">
      {title && <Subtitle text={title} />}
      <ul>{children}</ul>
    </FileListWrapper>
  );
};

export interface IFileListItemProps {
  className?: string;
  onDelete?(file: IBeehiveFile): void;
  onClick?(file: IBeehiveFile): void;
  error?: string;
  file: IBeehiveFile;
  clickToDownload?: boolean;
}

const FileListItemWrapper = styled.li`
  background: ${neutral[200]};
  border-radius: ${BorderRadius.Default}px;
  padding: ${Spacing.Default}px;
  margin-bottom: ${Spacing.Default}px;
  display: flex;
  align-items: center;

  ${({ onClick }) => onClick && `cursor:pointer;`}

  &:last-child {
    margin-bottom: 0;
  }

  .file-item-details {
    flex-grow: 1;
  }
  .file-item-icon {
    color: ${honeygold};
  }
  .file-item-details {
    margin-left: ${Spacing.Default}px;
    align-items: center;
  }
  .file-item-name {
    ${fontStyle(textFont.bold, typescale.sub)}
    margin-bottom: ${Spacing.Small}px;
  }
  .file-item-size {
    margin-left: ${Spacing.Default}px;
    ${fontStyle(textFont.roman, typescale.sub)}
  }
  .file-item-error {
    ${fontStyle(textFont.roman, typescale.sub, Swatches.Danger.swatch)}
    margin-left: ${Spacing.Default}px;
  }
  .file-item-button {
    background: none;
    padding: 0;

    i {
      color: ${neutral[500]};
    }

    &:hover {
      i {
        color: ${Swatches.Danger.swatch} !important;
      }
    }
  }
`;

const FileListItem: React.FC<IFileListItemProps> = ({
  onDelete,
  onClick,
  file,
  error,
  clickToDownload,
}) => {
  const fileSize = (size: number) => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const fileType = (fileName: string) => {
    return fileName
      ?.substring(fileName.lastIndexOf(".") + 1, fileName.length)
      ?.toLowerCase();
  };

  const handleDelete = (e: React.MouseEvent)=> {
    e.stopPropagation();
    onDelete?.(file);
  };

  const handleClick = () => {
    if (clickToDownload) {
      fileActions.downloadFile(file.id);
      onClick?.(file);
    }
  };

  return (
    <FileListItemWrapper onClick={handleClick}>
      <FileIcon className="file-item-icon" type={fileType(file.extension)} />
      <div className="file-item-details">
        <span className="file-item-name">{file.filename}</span>
        {/* <span className="file-item-size">{fileSize(file.size)}</span> */}
        {file.invalid && <span className="file-item-error">{error}</span>}
      </div>
      {onDelete && (
        <Button
          className="file-item-button"
          icon="times"
          onClick={e => handleDelete(e)}
        />
      )}
    </FileListItemWrapper>
  );
};

FileList.Item = FileListItem;

export default FileList;
