import React from "react";
import { Table, DetailLabel, Chip, Swatches, EmptyMessage } from "ui-kit";
import Moment from "react-moment";
import { arrays } from "utils";
import moment from "moment";
import NewAssignmentButton from "./newAssignmentButton";
import {
  StaffView,
  StudentView,
} from "sharedComponents/common/users/userTypeView";
import PublishStatusChip from "sharedComponents/common/status/publishStatusChip";
import GroupList from "sharedComponents/common/groups/groupList";
import { IBeehiveError } from "types/common/errors.types";
import { AssignmentTinyView } from "areas/planner/types/assignments/assignmentResponse.types";
import { PageInfo } from "types/common/paging.types";
import { useAppSelector } from "reducers/hooks";
import { AssignmentGrouping } from "areas/planner/reducers/assignments/assignmentsReducer";
import { Avatar } from "sharedComponents/common";


interface IAssignmentsTableProps {
  loading?: boolean;
  onPage?: () => void;
  assignments: AssignmentTinyView[];
  error?: IBeehiveError;
  paging?: PageInfo;
  onRowClick?: (assignment: AssignmentTinyView) => void;
}


const AssignmentsTable: React.FC<IAssignmentsTableProps> = ({
  loading,
  onPage,
  assignments,
  error,
  paging,
  onRowClick,
}) => {

  const { grouping } = useAppSelector(state => state.assignments);

  const handleRowClick = (assignment: AssignmentTinyView) => {
    onRowClick?.(assignment);
  };

  const handlePage = () => {
    onPage?.();
  };

  const showStaff = () => {
    return (
      grouping.value !== AssignmentGrouping.Owned && 
      grouping.value !== AssignmentGrouping.ManagedStaff
    );
  }

  //console.log(assignments.flatMap(a => a.students.map(s => s.id)));

  return (
    <Table
      zebra
      loading={loading}
      error={error != null}
      empty={arrays.isEmpty(assignments)}
      grow
      emptyMessage={
        <EmptyMessage
          icon="briefcase"
          title="No assignments"
          summary={<StudentView>Please select an assignment</StudentView>}
          cover
        >
          <StaffView>
            <NewAssignmentButton />
          </StaffView>
        </EmptyMessage>
      }
    >
      <Table.Header>
        <Table.HeaderCell width={0.5} />
        <Table.HeaderCell width={1.25} show={showStaff()}>Staff</Table.HeaderCell>
        <Table.HeaderCell width={4}>Assignment</Table.HeaderCell>
        <Table.HeaderCell width={2}>Set For</Table.HeaderCell>
        <Table.HeaderCell right width={2}>
          Deadline
        </Table.HeaderCell>
      </Table.Header>
      <Table.Body onPage={handlePage} paging={paging}>
        {assignments.map((assignment, index) => (
          <Table.Row key={index} onClick={() => handleRowClick(assignment)}>
            <Table.Cell width={0.5}>
              {assignment.isExpired ? (
                <Chip
                  text="Expired"
                  colorSwatch={Swatches.Low}
                  tooltip="Assignment Expired"
                  tooltipSub={
                    assignment.deadline != null &&
                    `The deadline for this assignment passed on ${moment(
                      assignment.deadline
                    ).format("DD/MM/YYYY")}`
                  }
                />
              ) : assignment.isScheduled ? (
                <Chip
                  text="Scheduled"
                  colorSwatch={Swatches.Warning}
                  tooltip="Scheduled Assignment"
                  tooltipSub={`This assignment is published but not visible to students until ${moment(
                    assignment.visibleFrom
                  ).format("DD/MM/YYYY")}`}
                />
              ) : (
                <PublishStatusChip status={assignment.publishStatus} />
              )}
            </Table.Cell>
            <Table.Cell width={1.25} show={showStaff()}>
              <Avatar
                user={assignment.setBy}
              />
            </Table.Cell>
            <Table.Cell width={4}>
              <DetailLabel
                bold
                label={assignment.title}
                sub={
                  <>
                    <Moment
                      date={assignment.setOn}
                      format="\S\e\t DD/MM/YYYY"
                    />{" "}
                    by
                    {` ${assignment.setBy.firstName} ${assignment.setBy.lastName}`}
                  </>
                }
              />
            </Table.Cell>
            <Table.Cell width={2}>
              <GroupList groups={assignment?.groups} />
              { assignment?.students?.length > 1 
                  ? (
                    <Chip
                      text={`${assignment?.students?.length} Students`}
                      colorSwatch={Swatches.Default}
                      tooltip={assignment?.students?.map(s => <>{`${s.firstName} ${s.lastName}`}<br/></>)}
                    />
                  )
                  : assignment?.students?.length === 1 && (
                    <>{`${assignment?.students[0]?.firstName} ${assignment?.students[0]?.lastName}`}<br/></>
                  )
              }
            </Table.Cell>
            <Table.Cell right width={2}>
              {assignment?.deadline !== null && (
                <Moment
                  date={assignment?.deadline}
                  format="dddd, Do MMMM YYYY"
                />
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default AssignmentsTable;
