import interventionActions from "areas/planner/actions/interventions/interventionActions";
import { InterventionRecordView } from "areas/planner/types/interventions/interventionResponses";
import { IBeehiveAction } from "types/common/action.types";

interface IStudentInterventionsState {
  interventions: InterventionRecordView[];
  loading: boolean;
  saving: boolean;
  error: string | null;
}

const INITIAL_STATE: IStudentInterventionsState = {
  interventions: [],
  loading: false,
  saving: false,
  error: null,
};


const studentInterventionsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IStudentInterventionsState => {

  const {
    STUDENTINTERVENTIONS_GET,
  } = interventionActions.types;

  switch (action.type) {

    case STUDENTINTERVENTIONS_GET.START:
      return { 
        ...state, 
        loading: true
      };
    case STUDENTINTERVENTIONS_GET.SUCCESS:
      return { 
        ...state, 
        loading: false, 
        interventions: action.payload 
      };
    case STUDENTINTERVENTIONS_GET.FAILED:
      return { 
        ...state, 
        loading: false, 
        error: action.payload 
      };

    default:
      return state;
  }
};

export default studentInterventionsReducer;
