import React from "react";
import {
  ActionBar,
  Left,
  DatePicker,
  Spacing,
  fontStyle,
  displayFont,
  typescale,
  neutral,
  Right,
  RadioList,
} from "ui-kit";
import styled from "styled-components";
import { CategoryType } from "areas/analytics/types/analytics.shared";
import moment from "moment";


const ActionBarItem = styled.div`
  margin-right: ${Spacing.Medium}px;
  ${fontStyle(displayFont.medium, typescale.paragraph, neutral[500])}
`;


interface ILeaderboardActionBarProps {
  startDate: moment.Moment;
  handleSelectStartDate: (value: moment.Moment) => void;
  endDate: moment.Moment;
  handleSelectEndDate: (value: moment.Moment) => void;
  categoryTypeId: CategoryType;
  handleSelectCategoryType: (value: CategoryType) => void;
  whiteboardView?: boolean;
}


const LeaderboardActionBar: React.FC<ILeaderboardActionBarProps> = ({
  startDate,
  handleSelectStartDate,
  endDate,
  handleSelectEndDate,
  categoryTypeId,
  handleSelectCategoryType,
  whiteboardView
}) => {

  return (
    <ActionBar>
      {!whiteboardView && (
        <Left>
          <ActionBarItem>
            Start Date{" "}
            <DatePicker
              dateFormat="DD/MM/YYYY"
              selectedDate={startDate.toDate()}
              onChange={value => handleSelectStartDate(moment(value))}
              closeOnSelect
            />
          </ActionBarItem>

          <ActionBarItem>
            End Date{" "}
            <DatePicker
              dateFormat="DD/MM/YYYY"
              selectedDate={endDate.toDate()}
              onChange={value => handleSelectEndDate(moment(value))}
              closeOnSelect
            />
          </ActionBarItem>
        </Left>
      )}

      <Right>
        <RadioList
          value={categoryTypeId}
          onChange={value => handleSelectCategoryType(value)}
          fluid
        >
          <RadioList.Item
            label="Rewards"
            value={CategoryType.Reward}
          />
          <RadioList.Item
            label="Behaviours"
            value={CategoryType.Behaviour}
          />
        </RadioList>
      </Right>
    </ActionBar>
  );
};

export default LeaderboardActionBar;
