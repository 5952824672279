import React from "react";
import styled from "styled-components";
import { Subtitle, EmptyMessage, Spacing } from "ui-kit";
import { arrays } from "utils";
import FormListItem from "./formListItem";
import { FormCategoryListView, FormListView } from "../types/formResponse.types";


export const FormCategoryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-bottom: ${Spacing.Large}px;

  &:last-child {
    margin-bottom: 0;
  }

  .card {
    box-sizing: box-content;
    flex-basis: 0;
    flex-shrink: 0;
    margin-right: ${Spacing.Large}px;
  }
`;


interface IFormsListProps {
  category: FormCategoryListView;
  onGoToForm: (form: FormListView) => void;
}


const FormsList: React.FC<IFormsListProps> = ({ category, onGoToForm }) => {

  return (
    <>
      <Subtitle text={category.name} />

      <FormCategoryWrapper>
        {arrays.isEmpty(category.forms) ? (
          <EmptyMessage
            icon="paste"
            title={`No ${category.name} Forms`}
            cover
          />
        ) : (
          category.forms.map((form: FormListView, index: number) => (
            <FormListItem key={index} form={form} onGoToForm={onGoToForm} />
          ))
        )}
      </FormCategoryWrapper>
    </>
  );
};

export default FormsList;
