import React, { useState, useEffect } from "react";
import DetentionFormWrapper from "areas/behaviour/components/detentions/detentionFormWrapper";
import {
  todayTime,
  formatDate,
  formatTime,
  todayDateTime,
} from "utils/dateTime";
import IncidentFormWrapper from "./incidentFormWrapper";
import BehaviourOutcomeDropdown from "./behaviourOutcomeDropdown";
import InternalNoteFormField from "../behaviourCommon/internalNoteFormField";
import InitialBehaviourActionWrapper from "./initialBehaviourActionWrapper";
import ParentNotificationNote from "areas/behaviour/components/behaviourCommon/parentNotificationNote";
import {
  StructuredList,
  Message,
  Swatches,
  Checkbox,
  Card,
  ValidationMessage,
  ActionBar,
  Button,
  Right,
  Size,
} from "ui-kit";
import PointsDropdown from "areas/behaviour/components/behaviourCommon/pointsDropdown";
import PublicNotesFormField from "../behaviourCommon/publicNotesFormField";
import styled from "styled-components";
import { readyToSubmit } from "areas/behaviour/utils/behaviours";
import TriggeredDetentionMessage from "../detentions/triggeredDetentionMessage";
import {
  DETENTION_OPTIONS_KEYS,
} from "areas/behaviour/constants/detentions";
import { IBehaviourDetails, IDetentionDetails, IIncidentDetails, TinyGroup, getDefaultDetentionInfo } from "areas/behaviour/utils/detentions";
import StudentClassesDropdown from "areas/planner/components/studentRecord/classList/studentClassesDropdown";
import ConfirmModal from "sharedComponents/common/confirmModal";
import { OUTCOME_KEYS } from "../../constants/behaviours";
import { DetentionTypeView, SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";
import { PlannerSchoolView } from "areas/planner/types/plannerSchoolResponse.types";
import { DetentionOption } from "areas/behaviour/types/behaviourShared.types";
import { PreflightDetentionDateView } from "areas/behaviour/types/detentionRegisterResponse.types";


const MessageWrapper = styled.div`
  .message {
    margin-bottom: 0;
  }
`;


interface IBehaviourPointFormProps {
  studentId: string;
  selectedCategory: SchoolBehaviourCategoryListView;
  defaultDetention: DetentionTypeView;
  handleAddBehaviour: (detentionOptionId: DetentionOption, behaviourDetails: IBehaviourDetails) => void;
  handleCloseModal: () => void;
  group?: TinyGroup;
  schoolId: number;
  schoolInformation: PlannerSchoolView;
  checkValidations: (incidentDetails: IIncidentDetails, detentionDetails: IDetentionDetails) => string[];
  dateCheckResponse?: PreflightDetentionDateView;
  loading?: boolean;
  upscale?: boolean;
}


const BehaviourPointForm: React.FC<IBehaviourPointFormProps> = ({
  studentId,
  selectedCategory,
  defaultDetention,
  handleAddBehaviour,
  handleCloseModal,
  group,
  schoolId,
  schoolInformation,
  checkValidations,
  dateCheckResponse,
  loading,
  upscale,
}) => {
  // Add behaviour confirm
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  // Behaviour information
  const [behaviourDetails, setBehaviourDetails] = useState<IBehaviourDetails>({
    points: selectedCategory.points,
    outcome: OUTCOME_KEYS.RESOLVED,
    initialAction: {
      initialActionId: null,
      initialActionName: "",
    },
    publicNotes: "",
    internalNotes: "",
    incidentDetails: {
      period: null,
      incidentLocation: null,
      otherIncidentLocation: null,
      incidentDate: todayDateTime(),
      incidentTime: todayTime(),
      initialActionId: null,
      initialActionName: null
    },
    detentionDetails: null,
    class: group,
  });

  // determines if detention is mandatory, optional or not required
  const [detentionOptionId, setDetentionOptionId] = useState<DetentionOption>(null);
  // incident details are optional
  const [addIncidentDetails, setAddIncidentDetails] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_defaultDetention, _setDefaultDetentions] = useState<DetentionTypeView>(null);

  useEffect(() => {
    if (selectedCategory) {
      setBehaviourDetails({
        ...behaviourDetails,
        points: selectedCategory.points,
      });
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (defaultDetention) {
      _setDefaultDetentions(defaultDetention);
      setDetentionOptionId(defaultDetention.detentionOptionId);
      setValidationErrors([]);
    }
  }, [defaultDetention]);

  useEffect(() => {
    if (_defaultDetention) {
      // add detention details if conditions are met
      if (
        !selectedCategory.leadershipInterventionRequired &&
        _defaultDetention.detentionTypeInformation !== null &&
        (_defaultDetention.isTriggeredDetention ||
          _defaultDetention.detentionOptionId ===
            DETENTION_OPTIONS_KEYS.MANDATORY)
      ) {
        const tempDetentionDetails = getDefaultDetentionInfo(
          _defaultDetention.detentionTypeInformation,
          null,
          null,
          schoolId
        );
        setBehaviourDetails({
          ...behaviourDetails,
          detentionDetails: tempDetentionDetails,
        });
      } else {
        setBehaviourDetails({ ...behaviourDetails, detentionDetails: null });
      }
    }
  }, [_defaultDetention]);

  useEffect(() => {
    // reset incident details if checkbox unchecked
    if (!addIncidentDetails) {
      setBehaviourDetails({
        ...behaviourDetails,
        incidentDetails: {
          ...behaviourDetails.incidentDetails,
          period: null,
          incidentLocation: null,
          otherIncidentLocation: null,
        },
      });
    }
  }, [addIncidentDetails]);

  // useEffect(() => {
  //   if (dateCheckResponse && !dateCheckResponse.isDateValid) {
  //     setBehaviourDetails({
  //       ...behaviourDetails,
  //       detentionDetails: {
  //         ...behaviourDetails.detentionDetails,
  //         date: dateCheckResponse.validDetentionDate,
  //       },
  //     });
  //   }
  // }, [dateCheckResponse]);

  const validateAndOpenConfirm = () => {
    let errors = checkValidations(
      behaviourDetails.incidentDetails,
      behaviourDetails.detentionDetails
    );
    if (errors.length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors([]);
      setOpenConfirm(true);
    }
  };

  return (
    <>
      {_defaultDetention?.detentionRule?.ruleName && (
        <TriggeredDetentionMessage
          ruleName={_defaultDetention.detentionRule.ruleName}
        />
      )}
      <StructuredList>
        {(_defaultDetention?.isTriggeredDetention ||
          detentionOptionId === DETENTION_OPTIONS_KEYS.MANDATORY) &&
          !selectedCategory.leadershipInterventionRequired &&
          behaviourDetails.detentionDetails && (
            <DetentionFormWrapper
              studentId={studentId}
              schoolId={schoolId}
              detentionDetails={behaviourDetails.detentionDetails}
              setDetentionDetails={detentionDetails =>
                setBehaviourDetails({
                  ...behaviourDetails,
                  detentionDetails: detentionDetails,
                })
              }
            />
          )}

        <Card title="Incident Details">
          <Card.Body>
            {!upscale && (
              <StructuredList.Item
                name="Class or Group"
                description="You can optionally choose a class group, tutor group or custom group for this point."
                required
              >
                <StudentClassesDropdown
                  studentId={studentId}
                  selectedClass={behaviourDetails.class}
                  onClassChange={(newClass: TinyGroup) =>
                    setBehaviourDetails({
                      ...behaviourDetails,
                      class: newClass,
                    })
                  }
                  showNoGroupMessage={!group}
                />
              </StructuredList.Item>
            )}

            <StructuredList.Item name="Add Details of the Incident">
              <Checkbox
                text="Enter incident details"
                checked={addIncidentDetails}
                onChange={() => setAddIncidentDetails(!addIncidentDetails)}
              />
            </StructuredList.Item>

            {addIncidentDetails && (
              <IncidentFormWrapper
                incidentDetails={behaviourDetails.incidentDetails}
                setIncidentDetails={incidentDetails =>
                  setBehaviourDetails({
                    ...behaviourDetails,
                    incidentDetails: incidentDetails,
                  })
                }
                detentionDetails={behaviourDetails.detentionDetails}
                groupId={group?.id}
                schoolId={schoolId}
              />
            )}
          </Card.Body>
        </Card>

        <Card title="Behaviour Details">
          <Card.Body>
            {schoolInformation.isPrimary &&
              selectedCategory.allowOverridePointsForPrimary && (
                <StructuredList.Item name="Points" required>
                  <PointsDropdown
                    points={behaviourDetails.points}
                    setPoints={points =>
                      setBehaviourDetails({
                        ...behaviourDetails,
                        points: points,
                      })
                    }
                    maxPoints={selectedCategory.maxPoints}
                  />
                </StructuredList.Item>
              )}

            <InitialBehaviourActionWrapper
              selectedInitialAction={behaviourDetails.initialAction}
              handleChangeInitialAction={initialAction =>
                setBehaviourDetails({
                  ...behaviourDetails,
                  initialAction: initialAction,
                })
              }
            />

            <StructuredList.Item name="Outcome" required>
              <BehaviourOutcomeDropdown
                outcome={behaviourDetails.outcome}
                handleChangeOutcome={outcome =>
                  setBehaviourDetails({ ...behaviourDetails, outcome: outcome })
                }
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Public Notes"
              description="Maximum 250 characters. (These notes will be sent/displayed to parents)"
              helpPopup
            >
              <PublicNotesFormField
                notes={behaviourDetails.publicNotes}
                handleNotesChange={notes =>
                  setBehaviourDetails({
                    ...behaviourDetails,
                    publicNotes: notes,
                  })
                }
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Internal Notes"
              description="(These notes will not be sent/displayed to parents)"
              helpPopup
            >
              <InternalNoteFormField
                notes={behaviourDetails.internalNotes}
                handleNoteChange={notes =>
                  setBehaviourDetails({
                    ...behaviourDetails,
                    internalNotes: notes,
                  })
                }
              />
            </StructuredList.Item>

            {behaviourDetails.detentionDetails && (
              <MessageWrapper>
                <ParentNotificationNote />
              </MessageWrapper>
            )}
          </Card.Body>
        </Card>

        {validationErrors.length > 0 && (
          <ValidationMessage errors={validationErrors} />
        )}

        {dateCheckResponse && !dateCheckResponse.isDateValid && (
          <MessageWrapper>
            <Message
              text={`Sanction date and time has been amended to ${formatDate(
                dateCheckResponse.validDetentionDate
              )} ${formatTime(
                dateCheckResponse.validDetentionDate
              )}. Please check and click Add to add behaviour.`}
              color={Swatches.Cancel}
            />
          </MessageWrapper>
        )}
      </StructuredList>

      <ActionBar low>
        <Right>
          <Button
            text="Add"
            color={Swatches.Success}
            size={Size.Small}
            disabled={
              readyToSubmit(
                behaviourDetails.initialAction.initialActionId,
                behaviourDetails.detentionDetails,
                behaviourDetails.class
              ) === false
            }
            onClick={validateAndOpenConfirm}
            working={loading}
          />

          <Button
            text="Cancel"
            size={Size.Small}
            onClick={handleCloseModal}
            working={loading}
          />
        </Right>
      </ActionBar>

      <br />

      <ConfirmModal
        openModal={openConfirm}
        confirmMsg={
          detentionOptionId === DETENTION_OPTIONS_KEYS.MANDATORY
            ? `Are you sure you want to add a sanction for ${selectedCategory.behaviourCategoryName}? This will be sent to parents.`
            : `Are you sure you want to add a Behaviour point for ${selectedCategory.behaviourCategoryName}?`
        }
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          handleAddBehaviour(detentionOptionId, behaviourDetails);
          setOpenConfirm(false);
        }}
      />
    </>
  );
};

export default BehaviourPointForm;
