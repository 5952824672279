import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import { RewardView } from "areas/behaviour/types/reward/rewardResponse.types";
import studentActions from "areas/planner/actions/student/studentActions";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


interface IRewardState {
  reward: RewardView;
  refresh: boolean;
  loading: boolean;
  loadingRevokeReward: boolean;
  addRewardError: IBeehiveError;
  getRewardError: IBeehiveError;
  updateRewardError: IBeehiveError;
  revokeRewardError: IBeehiveError;
}

const INITIAL_STATE: IRewardState = {
  reward: null,
  refresh: false,
  loading: false,
  loadingRevokeReward: false,
  addRewardError: null,
  getRewardError: null,
  updateRewardError: null,
  revokeRewardError: null,
};

const { 
  ADDREWARDS, 
  GETREWARD, 
  UPDATEREWARD, 
  REVOKEREWARD 
} = rewardActions.types;
const { GETSTUDENT } = studentActions.types;


const rewardReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IRewardState => {

  switch (action.type) {
    case ADDREWARDS.START:
      return {
        ...state,
        loading: true,
        addRewardError: null,
      };

    case ADDREWARDS.SUCCESS:
      return { ...state, loading: false };

    case ADDREWARDS.FAILED:
      return {
        ...state,
        loading: false,
        addRewardError: action.payload,
      };

    case GETREWARD.START: {
      return {
        ...state,
        loading: true,
        getRewardError: null,
        addRewardError: null,
        updateRewardError: null,
      };
    }

    case GETREWARD.SUCCESS: {
      return {
        ...state,
        refresh: false,
        loading: false,
        reward: action.payload,
      };
    }

    case GETREWARD.FAILED:
      return {
        ...state,
        loading: false,
        getRewardError: action.payload,
      };

    case UPDATEREWARD.START:
      return { ...state, updateRewardError: null, loading: true };

    case UPDATEREWARD.SUCCESS:
      return { ...state, refresh: true, loading: false };

    case UPDATEREWARD.FAILED:
      return {
        ...state,
        updateRewardError: action.payload,
        loading: false,
      };

    case REVOKEREWARD.START:
      return {
        ...state,
        revokeRewardError: null,
        loadingRevokeReward: true,
      };

    case REVOKEREWARD.SUCCESS:
      return {
        ...state,
        loadingRevokeReward: false,
      };

    case REVOKEREWARD.FAILED:
      return {
        ...state,
        revokeRewardError: action.payload,
        loadingRevokeReward: false,
      };

    case GETSTUDENT.SUCCESS:
      return {
        ...state,
        addRewardError: null,
        getRewardError: null,
        updateRewardError: null,
        revokeRewardError: null,
      };

    default:
      return state;
  }
};

export default rewardReducer;
