import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { 
  Title, 
  TitleSize, 
  EmptyMessage, 
  Size, 
  Swatches, 
  Button 
} from "ui-kit";
import Subheader from "sharedComponents/layout/header/subheader";


const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .icon {
    font-size: 60px;
  }
`;


const Success = () => {

  const navigate = useNavigate();

  return (
    <>
      <Subheader>
        <Title size={TitleSize.H2} text="Store" sub="Checkout" />
      </Subheader>
      <Wrapper>
        <EmptyMessage
          icon="check-circle"
          iconColor={Swatches.Success.swatch}
          title="Order Complete"
          summary={
            <p>
              Your order has been received and is now being processed. You will
              receive a receipt to your email shortly.
              <br />
              If you have ordered smartcard credit topups these will be synced
              and will be available to spend shortly.
            </p>
          }
        >
          <Button
            text="Go to Home"
            color={Swatches.Primary}
            onClick={() => navigate("/main")}
            size={Size.Small}
          />
        </EmptyMessage>
      </Wrapper>
    </>
  );
};

export default Success;
