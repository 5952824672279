import { ProvisionMapDetailView } from "areas/send/types/passportResponse.types";
import React, { useState, useEffect } from "react";
import { useAppSelector } from "reducers/hooks";
import styled from "styled-components";
import { Button, EmptyMessage, Left, Right, Size, Spacing, Swatches, Tabs, Title, TitleSize } from "ui-kit";
import { arrays } from "utils";
import ProvisionMap from "../provisionMaps/provisionMap";
import provisionMapActions from "areas/send/actions/provisionMaps/provisionMapActions";
import ReviewComments from "../provisionMaps/reviewComments";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";


const MapHeader = styled.div`
  margin-bottom: ${Spacing.Large}px;
  display: flex;
  align-items: top;
  justify-content: space-between;
`;


interface IParentProvisionMapViewProps {
  student: PlannerStudentDetailView;
}


const ParentProvisionMapView: React.FC<IParentProvisionMapViewProps> = ({ student }) => {

  const { 
    provisionMaps,
    activeStudent
  } = useAppSelector(state => state.provisionMaps);
  const {
    provisionMap,
    loading: loadingProvisionMap,
    error: provisionMapError,
    downloading
  } = useAppSelector(state => state.provisionMap);

  const [_provisionMap, _setProvisionMap] = useState<ProvisionMapDetailView | null>(null);

  const validStudent = () => student && (student.id == activeStudent || activeStudent == null);

  useEffect(() => {
    if (validStudent()) {
      provisionMapActions.getProvisionMapsForStudent(student.id);
    }
  }, [activeStudent, student]);

  useEffect(() => {
    if (validStudent() && 
      !arrays.isEmpty(provisionMaps) && 
      (provisionMaps[0].student.id == student.id)
    ) {
      provisionMapActions.getProvisionMap(student.id, provisionMaps?.[0].id);
    }
  }, [provisionMaps])

  useEffect(() => {
    if (provisionMap && validStudent()) {
      _setProvisionMap(provisionMap);
    }
    else {
      _setProvisionMap(null);
    }
  }, [provisionMap])


  const handleDownloadProvisionMap = () => {
    _provisionMap && provisionMapActions.downloadProvisionMap(_provisionMap.student.id, _provisionMap.id);
  };

  return arrays.isEmpty(provisionMaps) ? (
    <EmptyMessage
      cover
      title="No Provision Maps"
      icon="hand-holding-seedling"
      summary="No provision maps have been added for this student"
    />
  ) : (
    <>
      <MapHeader>
        <Left>
          <Title
            size={TitleSize.H3}
            text={`Provison Maps`}
            sub={`${_provisionMap ? _provisionMap.academicYearName : ""}`}
          />
        </Left>
        <Right>
          <Button
            text="Download Provision Map"
            color={Swatches.Primary}
            size={Size.Small}
            onClick={handleDownloadProvisionMap}
            working={downloading}
          />
        </Right>
      </MapHeader>

      <Tabs>
        <Tabs.Pane name="provisionMap" label="Provision Map">
          <ProvisionMap
            provisionMap={_provisionMap}
            loading={loadingProvisionMap}
            error={provisionMapError}
            readonly
          />
        </Tabs.Pane>
        <Tabs.Pane name="provisionComments" label="Comments">
          <ReviewComments 
            provisionMap={_provisionMap} 
            loading={loadingProvisionMap}
            error={provisionMapError}
          />
        </Tabs.Pane>
      </Tabs>
    </>
  );
}


export default ParentProvisionMapView;