import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAction } from "hooks";
import styled from "styled-components";
import {
  Size,
  TabBar,
  Loader,
  HeadlineStatistic,
  EmptyMessage,
  Subtitle,
  Breakpoints,
  Spacing,
  Text,
} from "ui-kit";
import DinnerMoneyOption from "./dinnerMoneyOption";
import { Constants } from "configuration";
import storeActions from "areas/payments/actions/storeActions";
import { arrays } from "utils";
import { RootState } from "reducers/store";
import { StudentStartupView } from "types/users/userListViews.types";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .topups {
    .topup {
      margin-right: 0;
    }

    @media screen and (min-width: ${Breakpoints.xxs}px) {
      display: grid;
      gap: 0 ${Spacing.Large}px;

      grid-template-columns: 1fr 1fr;
      grid-template-areas: ". . ";
    }

    @media screen and (min-width: ${Breakpoints.xs}px) {
      display: grid;
      gap: 0 ${Spacing.Large}px;

      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas: ". . .";
    }
  }
`;


const DinnerMoney = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const filteredChildren = user.children.filter((x) => x.school.enableLunchCredits);
  const [selectedChild, setSelectedChild] = useState<StudentStartupView>(filteredChildren[0]);

  const { balance, loadingBalance, balanceError } = useSelector((state: RootState) => state.lunchCredits);

  const handleTabClicked = (tab: string, index: number) => {
    setSelectedChild(filteredChildren[index]);
  };

  const handleLunchAdded = () => {};

  useEffect(() => {
    selectedChild && storeActions.getLunchCreditBalanceForUser(selectedChild.id);
  }, [selectedChild]);

  return (
    <Wrapper>
      {arrays.isEmpty(filteredChildren) ? (
        <EmptyMessage
          icon="utensils"
          title="Dinner Money Topups Unavailable"
          summary="You do not have any children at a primary school offering online dinner money topups"
          cover
        />
      ) : (
        <>
          {filteredChildren?.length > 1 && (
            <TabBar onItemClicked={handleTabClicked}>
              {filteredChildren.map((child, index) => (
                <TabBar.Item
                  key={index}
                  name={`${child.details.firstName} ${child.details.lastName}`}
                  text={`${child.details.firstName} ${child.details.lastName}`}
                />
              ))}
            </TabBar>
          )}

          {loadingBalance ? (
            <Loader size={Size.Medium} cover />
          ) : balance?.[0]?.freeSchoolMeal ? (
            <EmptyMessage
              icon="utensils"
              title={`${selectedChild.details.firstName} gets free school meals`}
              summary={`As ${selectedChild.details.firstName} is automatically entitled to free school meals, you do not need to buy lunch credits.`}
              cover
            />
          ) : (
            <>
              <HeadlineStatistic>
                <HeadlineStatistic.Item
                  icon="utensils"
                  value={balance?.[0]?.balance}
                  label="School Lunch Balance"
                />
              </HeadlineStatistic>
              <Subtitle text="Buy School Lunches" />
              <div className="topups">
                {Constants.LUNCH_AMOUNTS.map((value) => (
                  <DinnerMoneyOption
                    key={value}
                    value={value}
                    onAddToBasket={handleLunchAdded}
                    recipientId={selectedChild.id}
                  />
                ))}
              </div>

              {balance?.[0]?.enableSingleMealPurchase && (
                <>
                  <Subtitle text="Celebratory Lunches" />
                  <Text>
                    If your child does not normally have school meals, you are
                    able to purchase a single meal for special occasions instead
                    of buying a minimum of five lunches. If you normally
                    purchase school lunches, you do not have to buy a
                    celebratory lunch separately and normal lunch credits can be
                    used.
                  </Text>
                  <div className="topups">
                    <DinnerMoneyOption
                      value={1}
                      onAddToBasket={handleLunchAdded}
                      recipientId={selectedChild.id}
                      caption={balance?.[0]?.singleMealPurchaseName}
                      buttonText={"Buy " + balance?.[0]?.singleMealPurchaseName}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default DinnerMoney;
