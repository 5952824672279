import { useAppSelector } from "reducers/hooks";
import AdminDeclarationFilter from "./adminDeclarationFilter";
import { useEffect } from "react";
import declarationsOfInterestActions from "areas/forms/actions/declarations/declarationsOfInterestActions";
import AdminDeclarationsTable from "./adminDeclarationsTable";


const DeclarationsAdministration: React.FC = () => {

  const { declarations, paging, loading, error } = useAppSelector(state => state.adminDeclarations);
  const { filter } = useAppSelector(state => state.adminDeclarationsFilter);
  const { user } = useAppSelector(state => state.currentUser);

  useEffect(() => {
    if (user) {
      loadSubmissions(0);
    }
  }, [filter]);

  const handlePage = () => {
    loadSubmissions(paging.pageIndex + 1);
  };

  const loadSubmissions = (pageIndex: number) => {
    declarationsOfInterestActions.getDeclarationsForAdmin(
      {
        schoolId: filter.schoolId,
        academicYearId: filter.academicYearId,
        searchTerm: filter.searchTerm,
        staffId: filter.submitter?.id
      }, 
      pageIndex
    );
  };

  return (
    <>
      <AdminDeclarationFilter />
      <AdminDeclarationsTable
        items={declarations}
        error={error}
        loading={loading}
        onPage={handlePage}
        paging={paging}
      />
    </>
  )
}


export default DeclarationsAdministration;