import React from "react";
import NonParentTimetableView from "./nonParentTimetableView";
import ParentTimetableView from "./parentTimetableView";
import { useSelector } from "react-redux";
import {
  NonParentView,
  ParentView,
} from "sharedComponents/common/users/userTypeView";

const Timetable = () => {
  const { user } = useSelector(state => state.currentUser);
  return (
    <>
      <ParentView>
        <ParentTimetableView user={user} />
      </ParentView>
      <NonParentView>
        <NonParentTimetableView user={user} />
      </NonParentView>
    </>
  );
};

export default Timetable;
