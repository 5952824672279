import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DetentionRegisterTableRow from "./detentionRegisterTableRow";
import { formatTime } from "utils/dateTime";
import moment from "moment";
import { Table } from "ui-kit";
import { DETENTION_TABLE_HEADERS_ARRAY } from "areas/behaviour/constants/tableConstants";
import { DetentionStudentView, SchoolStudentDetentionListView } from "areas/behaviour/types/detentionRegisterResponse.types";


interface IDetentionRegisterTableProps {
  session: SchoolStudentDetentionListView;
  markPresent: (studentId: string, behaviourRewardId: number, detentionId: number) => void;
  markNotPresent: (studentId: string, behaviourRewardId: number, detentionId: number) => void;
  onClickDetention: (detention: DetentionStudentView) => void;
  selectedGroupByOptions: number[];
  detentionRegisterStarts?: number;
  handleRescheduleDetention: (detention: DetentionStudentView) => void;
  handleBulkSelection: (checked: boolean, detention: DetentionStudentView) => void;
  handleLocalMarking: (detention: DetentionStudentView, markingType?:number) => void;
  selectedDetentions: DetentionStudentView[];
}


const DetentionRegisterTable: React.FC<IDetentionRegisterTableProps> = ({
  session,
  markPresent,
  markNotPresent,
  onClickDetention,
  selectedGroupByOptions,
  detentionRegisterStarts,
  handleRescheduleDetention,
  handleBulkSelection,
  handleLocalMarking,
  selectedDetentions,
}) => {

  const [minutesToDetention, setMinutesToDetention] = useState<number>();
  const [columns, setColumns] = useState<number>(null);
  const [groupByOptions, setGroupByOptions] = useState<number>(null);

  useEffect(() => {
    if (session.startDate) {
      const timeToDetention = moment(session.startDate).diff(
        moment(),
        "minutes"
      );
      const minsToDetention = timeToDetention < 0 ? 0 : timeToDetention;
      setMinutesToDetention(minsToDetention);
    } else {
      setMinutesToDetention(null);
    }
  }, [session]);

  useEffect(() => {
    if (minutesToDetention !== null) {
      const detentionCountdown = setInterval(function () {
        setMinutesToDetention(
          moment(session.startDate).diff(moment(), "minutes")
        );
      }, 1000 * 3);
      if (minutesToDetention <= 0) {
        clearInterval(detentionCountdown);
      }
    }
  }, [minutesToDetention]);

  useEffect(() => {
    const numberOfGroupByOptions = selectedGroupByOptions.length;
    const numberOfColumns =
      DETENTION_TABLE_HEADERS_ARRAY.length - numberOfGroupByOptions;
    setGroupByOptions(numberOfGroupByOptions);
    setColumns(numberOfColumns);
  }, [selectedGroupByOptions]);

  if (session.startDate) {
    var startDate = session.startDate;
    var startTime = formatTime(startDate);
  }

  if (session.endDate) {
    var endDate = session.endDate;
    var endTime = formatTime(endDate);
  }

  if (session.startDate && session.endDate) {
    var duration = `(${moment(endDate).diff(
      moment(startDate),
      "minutes"
    )} mins)`;

    const days = moment(session.startDate).diff(moment(), "days");
    const hours = moment(session.startDate).diff(moment(), "hours");
    const minutes = moment(session.startDate).diff(moment(), "minutes") + 1;

    var timeToDetentionText = "";
    if (minutesToDetention > 0) {
      if (days > 0) {
        timeToDetentionText += `${days} ${days === 1 ? "day" : "days"} `;
      }
      if (hours > 0) {
        const remainingHours = hours % (days * 24);
        timeToDetentionText += `${remainingHours} ${
          remainingHours === 1 ? "hour" : "hours"
        } `;
      }
      if (minutes > 0) {
        const remainingMins = minutes % (hours * 60);
        timeToDetentionText += `${remainingMins} ${
          remainingMins === 1 ? "min" : "mins"
        } `;
      }
      timeToDetentionText += "to detention";
    } else if (moment().format() < moment(endDate).format()) {
      timeToDetentionText = "Detention has started";
    } else {
      timeToDetentionText = "Detention finished";
    }
  }

  if (session.location) {
    var location = session.location;
  }

  const addSpaces = (header: string) => (
    <>{header} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
  );

  return (
    <>
      {groupByOptions > 0 && (
        <Table.Header>
          <Table.HeaderCell colspan={columns - 1}>
            {location ? addSpaces(location) : ""}
            {startTime
              ? endTime
                ? addSpaces(`${startTime} - ${endTime} ${duration}`)
                : addSpaces(startTime)
              : endTime
              ? addSpaces("End Time: " + endTime)
              : ""}
            {session.name}
          </Table.HeaderCell>
          <Table.HeaderCell right>
            {timeToDetentionText}
          </Table.HeaderCell>
        </Table.Header>
      )}

      <Table.Body>
        {session.students.map((detention, index) => (
          <DetentionRegisterTableRow
            key={index}
            detention={detention}
            markPresent={markPresent}
            markNotPresent={markNotPresent}
            onClickDetention={onClickDetention}
            selectedGroupByOptions={selectedGroupByOptions}
            rescheduleDetention={handleRescheduleDetention}
            detentionRegisterStarts={detentionRegisterStarts}
            handleBulkSelection={handleBulkSelection}
            handleLocalMarking={handleLocalMarking}
            selectedDetentions={selectedDetentions}
          />
        ))}
      </Table.Body>

      {/* <ChangeAttendanceModal
        open={openAttendanceModal}
        setOpen={setOpenAttendanceModal}
        detention={detention}
        isPresent={studentMarkedPresent}
        markPresent={markPresent}
        markNotPresent={markNotPresent}
      /> */}
    </>
  );
};


export default DetentionRegisterTable;
