import React, { useEffect, useState, useRef } from "react";
import AwardAnalyticsForm from "./awardAnalyticsForm";
import AwardReportTable from "./awardReportTable";
import { arrays } from "utils";
import { Title, TitleSize } from "ui-kit";
import ReportDescription from "../reportDescription";
import awardAnalyticsActions from "areas/analytics/actions/awardAnalyticsActions";
import studentAwardActions from "areas/planner/actions/student/studentAwardActions";
import schoolsActions from "areas/planner/actions/schools/schoolsActions";
import { useAppSelector } from "reducers/hooks";
import { CategoryListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { GenerateAwardReportCommand } from "areas/analytics/types/awardAnalyticsRequests.types";
import moment from "moment";



const AwardAnalyticsWrapper: React.FC = () => {

  const reportRef = useRef<HTMLDivElement>();
  const { schoolId } = useAppSelector(state => state.schools);
  const { user } = useAppSelector(state => state.currentUser);
  const { studentAwardCategories } = useAppSelector(state => state.studentAward);
  const { report, loading, loadingExportReport } = useAppSelector(state => state.awardAnalytics);
  const [awardCategories, setAwardCategories] = useState<IDropdownDataItemProps<string, number, null>[]>([]);

  useEffect(() => {
    schoolsActions.selectSchool(user.baseSchool.id);
    if (arrays.isEmpty(studentAwardCategories)) {
      studentAwardActions.getStudentAwardCategories();
    }
  }, []);

  useEffect(() => {
    const awards = studentAwardCategories.map((awardCategory: CategoryListView) => ({
      key: awardCategory.id,
      label: awardCategory.name,
      value: awardCategory.id,
    }));

    setAwardCategories(awards);
  }, [studentAwardCategories]);

  useEffect(() => {
    if (loading || report) {
      reportRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading, report]);

  const onSelectSchool = (value: number) => {
    schoolsActions.selectSchool(value);
  };

  const handleGenerateReport = (
    startDate: string,
    endDate: string,
    awardCategory: number,
    academicYearId: number,
    exportReport?: boolean
  ) => {

    const payload: GenerateAwardReportCommand = {
      schoolId: schoolId,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      awardCategoryId: awardCategory,
      academicYearId: academicYearId,
    };

    if (exportReport) {
      awardAnalyticsActions.exportAwardReport(payload);
    } else {
      awardAnalyticsActions.getAwardReport(payload);
    }
  };

  return (
    <>
      <Title text="Awards Report" size={TitleSize.H3} />
      <ReportDescription text="Use this report to view awards given between a selected date range." />
      <AwardAnalyticsForm
        schoolId={schoolId}
        onSelectSchool={onSelectSchool}
        awardCategories={awardCategories}
        handleGenerateReport={handleGenerateReport}
        loadingExportReport={loadingExportReport}
      />
      <br />
      <div ref={reportRef}>
        <AwardReportTable />
      </div>
    </>
  );
};

export default AwardAnalyticsWrapper;
