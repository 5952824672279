import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import authenticationActions from "actions/authentication/authenticationActions";
import { Routes as routes } from "configuration";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import startupActions from "actions/startup/startupActions";
import Header from "./header/header";
import PrimaryContent from "./primaryContent";
import PrimaryRoute from "./primaryRoute";
import Navigation from "./navigation/navigation";
import CustomRoute from "./routes/customRoute";
import { Constants } from "configuration";
import { ToastContainer } from "ui-kit";
import "tippy.js/dist/tippy.css";
import { createGlobalStyle } from "styled-components";
import {
  textFont,
  typescale,
  neutral,
  fontStyle,
  Spacing,
  formatScrollbars,
} from "ui-kit";
import { tippy } from "@tippyjs/react";
import MAIN_ROUTES from "./mainRoutes";
import LoadingScreen from "./loadingScreen";
import UpgradeClientVersion from "./upgradeClientVersion";
import AcceptableUseModal from "./acceptableUseModal";
import { RootState } from "reducers/store";
import { MainWrapperProvider } from "hooks/useScroll";
import navigationService from "services/navigationService";
// import OnCallAlertModal from "./onCallAlertModal";

tippy.setDefaultProps({ delay: 400, arrow: true });

const GlobalStyle = createGlobalStyle`

  .tippy-box {
    ${fontStyle(textFont.medium, typescale.paragraph, neutral[100])}
    padding: ${Spacing.Medium}px;
    background
  }
`;


const Main = () => {
  // Notes on refs - The refs are used to get the offsetHeight/Width of the header and navigation to correctly space the main content panel.
  // Changes to refs do not trigger a re-render so cannot be tracked using useEffect
  // As such, the headerHeight/navigationWidth internal state properties are set directly on the ref callback

  navigationService.navigation = useNavigate();
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [navigationWidth, setNavigationWidth] = useState<number>(0);
  const [_acceptableUseModalOpen, _setAcceptableUseModalOpen] = useState<boolean>(false);

  // const history = useHistory();
  const { user, forceLogout, actions } = useSelector(
    (state: RootState) => state.currentUser
  );

  // This is needed as normal cSS hover does not update the width and causes issues when the links are clicked in navigation
  const { hover, mode } = useSelector((state: RootState) => state.navigation);

  const { upgradeUi } = useSelector((state: RootState) => state.ui);

  useEffect(() => {
    setTimeout(() => {
      startupActions.getUser();
    }, 1000);
  }, []);

  useEffect(() => {
    user && _setAcceptableUseModalOpen(user.policy != null);
  }, [user]);

  useEffect(() => {
    actions.getUser.error && performLogout();
  }, [actions]);

  useEffect(() => {
    forceLogout && performLogout();
  }, [forceLogout]);

  useEffect(() => {}, [hover]);

  useEffect(() => {
    // Menu mode has changed, so recalculate navigation offset
    var nav = document.getElementById("navigation");
    if (nav && mode < Constants.NAVIGATION_MODES.HAMBURGER) {
      setNavigationWidth(nav.offsetWidth);
    } else {
      setNavigationWidth(0);
    }
  }, [mode]);

  const performLogout = () => {
    authenticationActions.logout(() => {
      <Navigate to={routes.authentication.login + "?startupError=1"} />;
    });
  };

  if (!user) {
    return <LoadingScreen />;
  }

  return (
    <>
      <GlobalStyle />
      <Header
        ref={(el: any) => {
          setHeaderHeight(el?.offsetHeight); // See ref notes above
        }}
      />
      <Navigation verticalOffset={headerHeight} />
      <MainWrapperProvider 
        verticalOffset={headerHeight}
        navigationOffset={navigationWidth}
      >
          <PrimaryRoute>
            <PrimaryContent>
              <Routes>
                {user &&
                  MAIN_ROUTES(user).map((ROUTE, index) => (
                    <Route
                      key={index}
                      path={ROUTE.path}
                      element={
                        <CustomRoute
                          title={ROUTE.title}
                          roles={ROUTE.roles}
                          users={ROUTE.users}
                        >
                          <ROUTE.Component />
                        </CustomRoute>
                      }
                    />
                  ))}
              </Routes>
            </PrimaryContent>
          </PrimaryRoute>
      </MainWrapperProvider>

      <ToastContainer />

      {upgradeUi && <UpgradeClientVersion />}

      {user.policy && (
        <AcceptableUseModal
          policy={user?.policy}
          open={_acceptableUseModalOpen}
          onClose={() => _setAcceptableUseModalOpen(false)}
        />
      )}

      {/* <OnCallAlertModal /> */}
    </>
  );
};

export default Main;
