import React from "react";
import { Table, Size, EmptyMessage, Icon, DetailLabel, DateTime } from "ui-kit";
import { arrays } from "utils";
import { Avatar, PublishStatus } from "sharedComponents/common";
import { useNavigate } from "react-router-dom";
import { articleRoutes } from "areas/news/newsRoutes";
import { ArticleListView } from "areas/news/types/newsResponse.types";
import { PageInfo } from "types/common/paging.types";

interface IArticlesTableProps {
  articles: ArticleListView[];
  loading?: boolean;
  error?: string;
  onPage?: () => void;
  paging?: PageInfo;
}

const ArticlesTable: React.FC<IArticlesTableProps> = ({ articles, loading, error, onPage, paging }) => {

  const navigate = useNavigate();

  const handleRowClick = (article: ArticleListView) => {
    navigate(articleRoutes.getArticlePath(article.category.id, article.id));
  };

  const handlePage = () => {
    onPage?.();
  };

  return (
    <Table
      zebra
      loading={loading}
      empty={arrays.isEmpty(articles)}
      emptyMessage={
        <EmptyMessage
          icon="signal-stream"
          title="No Articles"
          summary="No articles were found for this selection"
          cover
        />
      }
      error={error != null}
      grow
    >
      <Table.Header>
        <Table.HeaderCell width={0.5} />
        <Table.HeaderCell width={0.25} />
        <Table.HeaderCell width={0.25} />
        <Table.HeaderCell width={4}>Article</Table.HeaderCell>
        <Table.HeaderCell width={2}>Author</Table.HeaderCell>
        <Table.HeaderCell width={1}>Created</Table.HeaderCell>
        <Table.HeaderCell width={1}>Published</Table.HeaderCell>
      </Table.Header>
      <Table.Body onPage={handlePage} paging={paging}>
        {articles?.map((article: ArticleListView, index: number) => (
          <Table.Row key={index} onClick={() => handleRowClick(article)}>
            <Table.Cell width={0.5}>
              <PublishStatus status={article.publishStatus} />
            </Table.Cell>

            <Table.Cell width={0.25}>
              {article.isSticky && <Icon value="thumbtack" />}
            </Table.Cell>
            <Table.Cell width={0.25}>
              {article.hasAttachments && <Icon value="paperclip" />}
            </Table.Cell>
            <Table.Cell width={4}>
              <DetailLabel
                bold
                label={article.title}
                sub={article.category.name}
              />
            </Table.Cell>
            <Table.Cell width={2}>
              <Avatar user={article.author} size={Size.Small} />
            </Table.Cell>
            <Table.Cell width={1}>
              <DateTime bold date={article.createdDate} />
            </Table.Cell>
            <Table.Cell width={1}>
              <DateTime bold date={article.publishedDate} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default ArticlesTable;
