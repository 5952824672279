import { ArticleCategoryListView } from "areas/news/types/newsResponse.types";
import magazineActions from "../../actions/magazineActions";
import { IBeehiveAction } from "types/common/action.types";


interface IMagazineCategoriesState {
  loading: boolean;
  error: string | null;
  categories: ArticleCategoryListView[];
}

const INITIAL_STATE: IMagazineCategoriesState = {
  loading: false,
  error: null,
  categories: [],
};


const magazineCategoriesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IMagazineCategoriesState => {

  const { MAGAZINE_GETCATEGORIES } = magazineActions.types;

  switch (action.type) {
    case MAGAZINE_GETCATEGORIES.START:
      return { ...INITIAL_STATE, loading: true, error: null };

    case MAGAZINE_GETCATEGORIES.SUCCESS:
      var newState = { ...state, categories: action.payload, loading: false };
      return newState;

    case MAGAZINE_GETCATEGORIES.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default magazineCategoriesReducer;
