import detentionRegisterActions from "areas/behaviour/actions/detentions/detentionRegisterActions";
import { DetentionsPreflightListView } from "areas/behaviour/types/detentionRegisterResponse.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


interface IDetentionState {
  loading: boolean;
  preflightError: IBeehiveError;
  preflightResponse: DetentionsPreflightListView[];
  bulkRescheduleDetentionError: IBeehiveError;
  checkingDetentions: boolean;
}

const INITIAL_STATE: IDetentionState = {
  loading: false,
  preflightError: null,
  preflightResponse: null,
  bulkRescheduleDetentionError: null,
  checkingDetentions: false,
};

const { 
  GETPREFLIGHTDETENTIONDATES, 
  RESCHEDULEDETENTIONS 
} = detentionRegisterActions.types;


const bulkRescheduleDetentionsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IDetentionState => {

  switch (action.type) {

    case GETPREFLIGHTDETENTIONDATES.START:
      return {
        ...state,
        preflightError: null,
        checkingDetentions: true
      }
    case GETPREFLIGHTDETENTIONDATES.SUCCESS:
      return {
        ...state,
        checkingDetentions: false,
        preflightResponse: action.payload
      }
    case GETPREFLIGHTDETENTIONDATES.FAILED:
      return {
        ...state,
        checkingDetentions: false,
        preflightError: action.payload
      }

    case RESCHEDULEDETENTIONS.START:
      return {
        ...state,
        bulkRescheduleDetentionError: null,
        loading: true,
      }
    case RESCHEDULEDETENTIONS.SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case RESCHEDULEDETENTIONS.FAILED:
      return {
        ...state,
        loading: false,
        bulkRescheduleDetentionError: action.payload
      }

    default:
      return state;
  }
};

export default bulkRescheduleDetentionsReducer;
