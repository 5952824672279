/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Dropdown,
  neutral,
  fontStyle,
  typescale,
  displayFont,
  BorderRadius,
  Spacing,
} from "../index";
import moment from "moment";

const Wrapper = styled.div`
  display: inline-flex;
  align-items-center;

  .date-picker ~ & {
    margin-left: ${Spacing.Medium}px;
  }

  .dropdown {
    display: inline-flex;
    min-width: 3rem !important;

    .dropdown-header {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  span.am-pm {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: ${Spacing.Small}px ${Spacing.Default}px;
    background: ${neutral[500]};

    ${fontStyle(displayFont.medium, typescale.paragraph, neutral[100])}

    border-top-right-radius: ${BorderRadius.Default}px;
    border-bottom-right-radius: ${BorderRadius.Default}px;
  }
`;

export interface ITimePickerProps {
  minuteStep?: number;
  disabled?: boolean;
  onChange?(time: string): void;
  initialValue?: string;
  className?: string;
  style?: React.CSSProperties;
}
export interface ITimeProps {
  hour: number;
  minute: number;
}

const TimePicker: React.FC<ITimePickerProps> = ({
  onChange,
  disabled,
  className,
  initialValue,
  style
}) => {
  var hoursArray: number[] = [];
  for (var i = 0; i <= 23; i++) {
    hoursArray.push(i);
  }

  var minutesArray: number[] = [];
  for (var j = 0; j <= 59; j++) {
    minutesArray.push(j);
  }

  const [hours, setHours] = useState<number[]>([]);
  const [minutes, setMinutes] = useState<number[]>([]);
  const [selectedHour, setSelectedHour] = useState<number | null>(null);
  const [selectedMinute, setSelectedMinute] = useState<number | null>(null);

  useEffect(() => {
    setHours(hoursArray);
    setMinutes(minutesArray);
    setSelectedHour(12);
    setSelectedMinute(0);
  }, []);

  useEffect(() => {
    if (initialValue) {
      var time = moment(initialValue, "HH:mm");
      setSelectedHour(time.hours());
      setSelectedMinute(time.minutes());
      //onChange?.(time.format("HH mm"))
    }
  }, [initialValue]);

  // useEffect(() => {
  //   if (selectedHour != null && selectedMinute != null) {
  //     onChange?.(
  //       `${moment(selectedHour, "HH").format("HH")}:${moment(
  //         selectedMinute,
  //         "mm"
  //       ).format("mm")}`
  //     );
  //   }
  // }, [selectedMinute, selectedHour]);

  const handleHourChange = (hour: number) => {
    setSelectedHour(hour);
    onChange?.(
      `${moment(hour, "HH").format("HH")}:${moment(selectedMinute, "mm").format(
        "mm"
      )}`
    );
  };

  const handleMinuteChange = (minute: number) => {
    setSelectedMinute(minute);
    onChange?.(
      `${moment(selectedHour, "HH").format("HH")}:${moment(minute, "mm").format(
        "mm"
      )}`
    );
  };

  return (
    <Wrapper className={`time-picker ${className ? className : ""}`} style={style}>
      <Dropdown
        value={selectedHour}
        onChange={handleHourChange}
        placeholder="HH"
        disabled={disabled}
      >
        {hours.map((hour, index) => (
          <Dropdown.Item
            key={index}
            label={(hour < 10 ? "0" : "") + hour.toString()}
            value={hour}
          />
        ))}
      </Dropdown>

      <Dropdown
        value={selectedMinute}
        onChange={handleMinuteChange}
        placeholder="MM"
        disabled={disabled}
      >
        {minutes.map((minute, index) => (
          <Dropdown.Item
            key={index}
            label={(minute < 10 ? "0" : "") + minute.toString()}
            value={minute}
          />
        ))}
      </Dropdown>
      <span className="am-pm">
        {selectedHour && selectedHour < 12 ? "AM" : "PM"}
      </span>
    </Wrapper>
  );
};

export default TimePicker;
