import React from "react";
import styled from "styled-components";
import {
  neutral,
  typescale,
  Swatches,
  Size,
  displayFont,
  Label,
  Button,
  CircleIcon,
  Currency,
  BorderRadius,
  Spacing,
  fontStyle
} from "ui-kit";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
  background: ${neutral[100]};
  margin-right: ${Spacing.Large}px;
  margin-bottom: ${Spacing.Large}px;
  padding: ${Spacing.Large}px;
  border-radius: ${BorderRadius.Default}px;
  border: 1px solid ${neutral[300]};

  &:last-child {
    margin-right: 0;
  }

  .circle-icon {
    color: ${neutral[300]};
    margin-bottom: ${Spacing.Small}px;
  }
  .total {
    ${fontStyle(displayFont.extraLight, typescale.header2, neutral[700])}
    margin-bottom: ${Spacing.Medium}px;
  }
`;


interface ITopupButtonProps {
  onAddToBasket?: (value: number) => void;
  value?: number;
  working?: boolean;
  caption?: string;
  tooltip?: string;
  tooltipSub?: string;
  buttonText?: string;
  custom?: boolean;
  onAddCustom?: () => void;
}


const TopupButton: React.FC<ITopupButtonProps> = ({
  onAddToBasket,
  value,
  working,
  caption,
  tooltip,
  tooltipSub,
  buttonText,
  custom,
  onAddCustom
}) => {

  const handleAddToBasket = () => {
    onAddToBasket?.(value);
  };

  const handleAddCustom = () => {
    onAddCustom?.();
  };

  return (
    <Wrapper>
      <CircleIcon value="id-card-alt" outline color={Swatches.Low.swatch} />
      {custom ? (
        <Label className="total">Custom</Label>
      ) : (
        <Currency className="total" value={value} />
      )}
      <Button
        text={buttonText}
        size={Size.Medium}
        onClick={() => {
          if (custom) {
            handleAddCustom();
          } else {
            handleAddToBasket();
          }
        }}
        color={Swatches.Primary}
        working={working}
        tooltip={tooltip}
        tooltipSub={tooltipSub}
      />
    </Wrapper>
  );
};
export default TopupButton;
