import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LeavingLessonForm from "./leavingLessonForm";
import { Swatches, Button, ActionBar, Loader, Size } from "ui-kit";
import { Constants } from "configuration";
import { useSelector } from "react-redux";
import studentLeavingLessonActions from "areas/planner/actions/student/studentLeavingLessonActions";
import StudentModal from "sharedComponents/common/users/studentModal";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";

const LeavingLessonModal = ({
  selectedReason,
  student,
  group,
  openModal,
  handleCloseModal,
}) => {
  const { loadingCreateRecord, createRecordError } = useSelector(
    state => state.studentLeavingLesson
  );

  const [leavingLessonData, setLeavingLessonData] = useState({
    notes: "",
  });

  useEffect(() => {
    setLeavingLessonData({ ...leavingLessonData, notes: "" });
  }, [selectedReason]);

  const handleAddLeavingLesson = () => {
    const callback = () => {
      handleCloseModal();
    };

    const payload = {
      studentId: student.id,
      classGroupId:
        group.type === Constants.GROUP_TYPES.CLASS ? group.id : null,
      tutorGroupId:
        group.type === Constants.GROUP_TYPES.TUTOR ? group.id : null,
      provisionGroupId:
        group.type === Constants.GROUP_TYPES.PROVISION ? group.id : null,
      categoryId: selectedReason.id,
      notes: leavingLessonData.notes,
    };

    studentLeavingLessonActions.createLeavingLessonRecord(
      student.id,
      payload,
      callback
    );
  };

  const handleChange = data => {
    setLeavingLessonData({ ...leavingLessonData, ...data });
  };

  return (
    <StudentModal
      title="Leaving Lesson"
      onClose={handleCloseModal}
      open={openModal}
      width="75%"
      height="70%"
      student={student}
      footer={
        <ActionBar low>
          <Button
            text="Record Leaving Lessson"
            color={Swatches.Success}
            onClick={handleAddLeavingLesson}
            size={Size.Small}
          />
          <Button
            text="Cancel"
            color={Swatches.Low}
            onClick={handleCloseModal}
            size={Size.Small}
          />
        </ActionBar>
      }
    >
      {selectedReason &&
        (loadingCreateRecord ? (
          <Loader size={Size.Medium} fluid />
        ) : (
          <>
            <ApiExceptionMessage error={createRecordError} />
            <LeavingLessonForm
              selectedReason={selectedReason}
              leavingLessonData={leavingLessonData}
              onChange={handleChange}
            />
          </>
        ))}
    </StudentModal>
  );
};

LeavingLessonModal.propTypes = {
  className: PropTypes.string,
  category: PropTypes.object,
  selectedCategory: PropTypes.object,
  setSelectedCategory: PropTypes.func,
  student: PropTypes.object,
  classGroupId: PropTypes.number,
  handlePointClick: PropTypes.func,
  pointFormWrapper: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default LeavingLessonModal;
