import React from "react";
import { Table, neutral } from "ui-kit";
import PrimaryLeaderboardPointsBar from "./primaryLeaderboardPointsBar";
import styled from "styled-components";
import PrimaryCharacter from "sharedComponents/common/primary/characters/primaryCharacter";
import { Interface } from "readline";
import { ClassLayoutStudentView } from "areas/planner/types/classLayoutResponse.types";
import { LeaderBoardBehaviourView } from "areas/planner/types/whiteboard/whiteboardResponse.types";
import { UserTinyView } from "types/users/userListViews.types";


const Wrapper = styled.div`
  .table {
    border-radius: 12px;
    padding-top: 20px;
    padding-left: 12px;
    background: rgba(0, 0, 0, 0.75);

    thead {
      background: none;
      border-bottom: 0;
    }

    td {
      border-bottom: 0;
      padding: 0 12px;
    }
  }
`;

const LeaderboardName = styled.div`
  color: white;
  font-size: 22px;
`;

const LeaderboardPointsCharacter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  svg {
    width: 50px;
  }

  .primary-student-name {
    color: ${neutral[700]};
    font-family: "neue-haas-grotesk-display";
    font-weight: bold;
    font-size: 24px;
    position: absolute;
    bottom: 0px;
    height: 46px;
    max-width: 80px;
    text-align: center;
    right: 0;
    display: block;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;


interface IPrimaryLeaderboardTableProps {
  leaderboardReport: LeaderBoardBehaviourView[];
  categoryTypeId: number;
  handleOpenBehaviourModal: (students: UserTinyView[]) => void;
}


const PrimaryLeaderboardTable: React.FC<IPrimaryLeaderboardTableProps> = ({
  leaderboardReport,
  categoryTypeId,
  handleOpenBehaviourModal,
}) => {

  return (
    <Wrapper>
      <Table>
        {leaderboardReport?.map((leaderboardReportItem, index) => (
          <Table.Row key={index}>
            <Table.Cell colspan={1}>
              <LeaderboardName
                onClick={
                  handleOpenBehaviourModal
                    ? () =>
                        handleOpenBehaviourModal([
                          leaderboardReportItem.student,
                        ])
                    : null
                }
              >
                {leaderboardReportItem.student.firstName}
              </LeaderboardName>
            </Table.Cell>
            <Table.Cell colspan={8} center>
              <PrimaryLeaderboardPointsBar
                highestPoints={leaderboardReport[0].totalPoints}
                currentPointsValue={leaderboardReportItem.totalPoints}
                primaryAvatarId={leaderboardReportItem.primaryAvatarId}
              />
            </Table.Cell>
            <Table.Cell right>
              <LeaderboardPointsCharacter>
                <PrimaryCharacter
                  code={leaderboardReportItem.primaryAvatarId}
                />
                <div className="primary-student-name">
                  <span>{leaderboardReportItem.totalPoints.toString()}</span>
                </div>
              </LeaderboardPointsCharacter>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table>
    </Wrapper>
  );
};

export default PrimaryLeaderboardTable;
