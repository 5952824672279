import { IBeehiveAction } from "types/common/action.types";
import leaveActions from "../actions/leaveActions";
import { HolidayCardView } from "../types/leaveResponse.types";

interface IStaffMemberHolidayCardState {
  holidayCard: HolidayCardView | null;
  loading: boolean;
  error: string | null;
  saving: boolean;
  saveError: string | null;
}

const INITIAL_STATE: IStaffMemberHolidayCardState = {
  holidayCard: null,
  loading: false,
  error: null,
  saving: false,
  saveError: null,
};

const staffMemberHolidayCardReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IStaffMemberHolidayCardState => {
  const {
    GETHOLIDAYCARDFORSTAFFMEMBER,
    HOLIDAYCARD_SAVE,
    HOLIDAYCARD_CREATE,
    APPROVE_LEAVEREQUEST,
    REJECT_LEAVEREQUEST,
    CANCEL_LEAVEREQUEST,
    CONFIRM_LEAVEREQUEST,
    RESUBMIT_LEAVEREQUEST,
    SUBMIT_LEAVEREQUEST
  } = leaveActions.types;

  switch (action.type) {
    case GETHOLIDAYCARDFORSTAFFMEMBER.START:
      return { 
        ...state, 
        loading: true,
        error: null
      };

    case GETHOLIDAYCARDFORSTAFFMEMBER.SUCCESS:
      return {
        ...state,
        holidayCard: action.payload,
        loading: false,
      };

    case GETHOLIDAYCARDFORSTAFFMEMBER.FAILED:
      return {
        ...state,
        error: action.payload,
      };

    case HOLIDAYCARD_CREATE.START:
    case HOLIDAYCARD_SAVE.START:
      return { ...state, saving: true };

    case HOLIDAYCARD_CREATE.SUCCESS:
    case HOLIDAYCARD_SAVE.SUCCESS:
      return {
        ...state,
        saving: false,
        holidayCard:
          action.payload?.id === state.holidayCard?.id ||
          state.holidayCard?.id == null
            ? action.payload
            : state.holidayCard,
      };

    case HOLIDAYCARD_CREATE.FAILED:
    case HOLIDAYCARD_SAVE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the holiday card.",
      };

    case APPROVE_LEAVEREQUEST.SUCCESS:
    case REJECT_LEAVEREQUEST.SUCCESS:
    case CANCEL_LEAVEREQUEST.SUCCESS:
    case CONFIRM_LEAVEREQUEST.SUCCESS:
    case RESUBMIT_LEAVEREQUEST.SUCCESS:
    case SUBMIT_LEAVEREQUEST.SUCCESS:
      return {
        ...state,
        holidayCard:
          action.payload.id === state.holidayCard?.id
            ? action.payload
            : state.holidayCard,
      };

    default:
      return state;
  }
};

export default staffMemberHolidayCardReducer;
