import searchEngineActions from "areas/search/actions/searchEngineActions";
import defaults from "configuration/defaults";
import { IBeehiveAction } from "types/common/action.types";
import { PageInfo } from "types/common/paging.types";
import { TelephoneSearchView } from "../types/searchResponse.types";
import { IBeehiveError } from "types/common/errors.types";


interface ISearchPhonebookState {
  phonebook: TelephoneSearchView[] | null;
  loading: boolean;
  error: IBeehiveError;
  paging: PageInfo;
}

const INITIAL_STATE: ISearchPhonebookState = {
  phonebook: null,
  loading: false,
  error: null,
  paging: defaults.DEFAULT_PAGING,
};


const searchPhonebookReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISearchPhonebookState => {

  const { SEARCH_PHONEBOOK, CLEAR_RESULTS } = searchEngineActions.types;

  switch (action.type) {

    case SEARCH_PHONEBOOK.START:
      // We are not using ...INITIAL_STATE here to avoid a the menu opening and closing between key strokes when using an autocomplete control
      return { ...state, error: null, loading: true };
    case SEARCH_PHONEBOOK.SUCCESS:
      return {
        ...state,
        loading: false,
        phonebook:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.phonebook, ...action.payload.items],
        paging: action.payload.paging,
      };
    case SEARCH_PHONEBOOK.FAILED:
      return {
        ...state,
        loading: false,
        phonebook: null,
        error: action.payload,
      };

    case CLEAR_RESULTS:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default searchPhonebookReducer;
