import React, { useState } from "react";
import {
  StructuredList,
  Card,
  ActionBar,
  Button,
  Size,
  Swatches,
  Dropdown,
} from "ui-kit";
import DateRange from "../dateRange";
import MultiSchoolUserView from "sharedComponents/common/users/multiSchoolUserView";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import { GROUP_BY_ARRAY } from "areas/analytics/constants/leavingLessonReportConstants";
import AcademicYearFilter from "sharedComponents/common/academicYearFilter";
import { Constants } from "configuration";
import { useAppSelector } from "reducers/hooks";
import Search, { SEARCH_GROUP_TYPE_KEYS } from "sharedComponents/common/search/search";
import { SearchResultViewBase } from "types/common/views.types";
import { FilterBy, LessonExitGroupBy } from "areas/analytics/types/lessonExitAnalyticShared.types";
import { ReportFilterBy } from "areas/analytics/types/lessonExitAnalyticRequest.types";


interface ILeavingLessonAnalyticsFormProps {
  onSelectSchool: (value: number) => void;
  handleGenerateReport: (
    startDate: Date, 
    endDate: Date, 
    groupBy1: LessonExitGroupBy, 
    groupBy2: LessonExitGroupBy | null, 
    academicYearId: number,
    filters: ReportFilterBy[],
    exportReport?: boolean
  ) => void;
  loadingExportReport: boolean;
} 

enum filterAction {
  add,
  remove
}

const LeavingLessonAnalyticsForm: React.FC<ILeavingLessonAnalyticsFormProps> = ({
  onSelectSchool,
  handleGenerateReport,
  loadingExportReport,
}) => {

  const { schoolId } = useAppSelector(state => state.schools);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [breakdownCategoryOne, setBreakdownCategoryOne] = useState<LessonExitGroupBy | null>(null);
  const [breakdownCategoryTwo, setBreakdownCategoryTwo] = useState<LessonExitGroupBy | null>(null);
  const [academicYearId, setAcademicYearId] = useState<number>(Constants.ACADEMIC_YEAR_FILTER_ARRAY[0].value);
  //we want SearchResultViewBase rather than just string for the sake of rendering the item. We'll map this result later to just the id.
  const [filters, setFilters] = useState<{filterById: FilterBy, names: SearchResultViewBase[]}[]>([
    { filterById: FilterBy.YearGroup, names: [] },
    { filterById: FilterBy.TutorGroup, names: [] }
  ]);

  const handleFilterChange = (item: SearchResultViewBase, filterType: FilterBy, action: filterAction) => {
    var _filters = filters.map(filter => 
      filter.filterById === filterType
      ? {
        ...filter,
        names: action === filterAction.add
          ? [...filter.names, item]
          : filter.names.filter(x => x.id !== item.id)
      }
      : filter
    )
    setFilters(_filters);
  }

  return (
    <>
      <MultiSchoolUserView>
        <Card title="School">
          <Card.Body>
            <SchoolDropdown
              onChange={onSelectSchool}
              initialValue={schoolId}
              fluid
            />
          </Card.Body>
        </Card>
      </MultiSchoolUserView>

      <Card title="Date Range">
        <Card.Body>
          <StructuredList.Item name="Academic Year" required>
            <AcademicYearFilter
              value={academicYearId}
              onChange={value => setAcademicYearId(value)}
            />
          </StructuredList.Item>

          <DateRange
            startDate={startDate}
            handleStartDateChange={value => setStartDate(value)}
            endDate={endDate}
            handleEndDateChange={value => setEndDate(value)}
            required
          />
        </Card.Body>
      </Card>

      <Card title="Report Breakdown">
        <Card.Body>
          <StructuredList.Item
            name="Breakdown Category One"
            description="Choose a category that you want your report to be broken down by"
            helpPopup
            required
          >
            <Dropdown
              placeholder="Category"
              fluid
              items={GROUP_BY_ARRAY}
              onChange={value => setBreakdownCategoryOne(value)}
              value={breakdownCategoryOne}
              clearable
            />
          </StructuredList.Item>

          {breakdownCategoryOne !== null && (
            <StructuredList.Item
              name="Breakdown Category Two"
              description="Choose a second category that you want your report to be broken down by"
              helpPopup
            >
              <Dropdown
                placeholder="Category"
                fluid
                items={GROUP_BY_ARRAY}
                onChange={value => setBreakdownCategoryTwo(value)}
                value={breakdownCategoryTwo}
                clearable
              />
            </StructuredList.Item>
          )}
        </Card.Body>
      </Card>

      <Card title="Filters">
          <Card.Body>
            <StructuredList.Item
              name="Year Group"
              description="Filter by selected year groups"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.YEAR_GROUP]}
                allowMultiple
                selected={filters.find(x => x.filterById === FilterBy.YearGroup)?.names}
                handleSelectItem={item =>
                  handleFilterChange(item, FilterBy.YearGroup, filterAction.add)
                }
                handleDeleteItem={item =>
                  handleFilterChange(item, FilterBy.YearGroup, filterAction.remove)
                }
                schoolId={schoolId}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Tutor Group"
              description="Filter by selected tutor groups"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.TUTOR_GROUP]}
                allowMultiple
                selected={filters.find(x => x.filterById === FilterBy.TutorGroup)?.names}
                handleSelectItem={filter =>
                  handleFilterChange(filter, FilterBy.TutorGroup, filterAction.add)
                }
                handleDeleteItem={filter =>
                  handleFilterChange(filter, FilterBy.TutorGroup, filterAction.remove)
                }
                schoolId={schoolId}
                academicYearId={academicYearId}
              />
            </StructuredList.Item>
          </Card.Body>
        </Card>

      <ActionBar>
        <Button
          text="Generate Report"
          color={Swatches.Success}
          size={Size.Small}
          disabled={
            startDate === null ||
            endDate === null ||
            breakdownCategoryOne === null
          }
          onClick={() =>
            handleGenerateReport(
              startDate,
              endDate,
              breakdownCategoryOne,
              breakdownCategoryTwo,
              academicYearId,
              filters
                .filter(filter => filter.names.length > 0)
                .map(filter => ({...filter, names: filter.names.map(name => name.id)}))
            )
          }
        />
        <Button
          color={Swatches.Success}
          text="Export Report as CSV"
          icon="file-download"
          size={Size.Small}
          disabled={
            startDate === null ||
            endDate === null ||
            breakdownCategoryOne === null
          }
          onClick={() =>
            handleGenerateReport(
              startDate,
              endDate,
              breakdownCategoryOne,
              breakdownCategoryTwo,
              academicYearId,
              filters
                .filter(filter => filter.names.length > 0)
                .map(filter => ({...filter, names: filter.names.map(name => name.id)})),
              true
            )
          }
          working={loadingExportReport}
        />
      </ActionBar>
    </>
  );
};

export default LeavingLessonAnalyticsForm;
