import React from "react";
import { StructuredList, DatePicker } from "ui-kit";


interface IDateRangeProps {
  startDate: Date,
  handleStartDateChange?: (startDate: Date) => void,
  endDate: Date,
  handleEndDateChange?: (endDate: Date) => void,
  required?: boolean,
}


const DateRange: React.FC<IDateRangeProps> = ({
  startDate,
  handleStartDateChange,
  endDate,
  handleEndDateChange,
  required,
}) => {
  
  return (
    <>
      <StructuredList.Item
        name="Start Date"
        description="Choose a start date for your report"
        required={required}
        helpPopup
      >
        <DatePicker
          dateFormat="DD/MM/YYYY"
          selectedDate={startDate}
          onChange={value => handleStartDateChange(value)}
          closeOnSelect
          clearable={!required}
        />
      </StructuredList.Item>

      <StructuredList.Item
        name="End Date"
        description="Choose an end date for your report"
        required={required}
        helpPopup
      >
        <DatePicker
          dateFormat="DD/MM/YYYY"
          selectedDate={endDate}
          onChange={value => handleEndDateChange(value)}
          closeOnSelect
          clearable={!required}
        />
      </StructuredList.Item>
    </>
  );
};

export default DateRange;
