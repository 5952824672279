import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { BulkCreateProvisionRecordCommand, BulkUpdateProvisionRecordCommand, ProvisionRecordFilter, SaveProvisionGroupCommand, SaveProvisionRecordCommand, UpdateProvisionRecordStatusCommand } from "areas/send/types/passportRequest.types";
import { ProvisionGroupDetailView, ProvisionRecordListView, ProvisionRecordMapView } from "areas/send/types/passportResponse.types";
import { ProvisionGroupStatus } from "areas/send/types/passportShared.types";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { urls } from "utils";

const builder = new actionTypeBuilder("send");

const types = {
  PROVISIONGROUPS_GET: builder.build("provisiongroups_get"),
  PROVISIONGROUPS_CREATE: builder.build("provisiongroups_create"),
  PROVISIONGROUPS_SAVE: builder.build("provisiongroups_save"),
  PROVISIONGROUPS_DELETE: builder.build("provisiongroups_delete"),
  PROVISIONGROUPS_STATUSCHANGE: builder.build("provisiongroups_statuschange"),

  PROVISIONRECORDS_GET: builder.build("provisionrecords_get"),
  PROVISIONRECORDS_CREATE: builder.build("provisionrecords_create"),
  PROVISIONRECORDS_SAVE: builder.build("provisionrecords_save"),
  PROVISIONRECORDS_DELETE: builder.build("provisionrecords_delete"),
  PROVISIONRECORDS_UPDATESTATUS: builder.build("provisionrecords_updatestatus"),
  PROVISIONRECORDS_BULKCREATE: builder.build("provisionrecords_bulkcreate"),
  PROVISIONRECORDS_BULKUPDATE: builder.build("provisionrecords_bulkupdate"),
};


const getProvisionGroup = (providerId: number, provisionId: number, groupId: number, callback?: callbackType<ProvisionGroupDetailView>) =>
  action<ProvisionGroupDetailView>(
    () => client.get(`planner/send/providers/${providerId}/provisions/${provisionId}/groups/${groupId}`),
    types.PROVISIONGROUPS_GET,
    callback
  );

const getProvisionRecords = (
  providerId: number,
  provisionId: number,
  groupId: number,
  filter: ProvisionRecordFilter,
  callback?: callbackType<ProvisionRecordListView[]>
) => {
  var url = new urls.QueryString(
    `planner/send/providers/${providerId}/provisions/${provisionId}/groups/${groupId}/records`
  );

  url.addParams(filter);

  return action<ProvisionRecordListView[]>(
    () => client.get(url.toUrl()),
    types.PROVISIONRECORDS_GET,
    callback
  );
};

const createProvisionGroup = (data: SaveProvisionGroupCommand, callback?: callbackType<ProvisionGroupDetailView>) =>
  action<ProvisionGroupDetailView>(
    () => client.post(`planner/send/providers/${data.providerId}/provisions/${data.provisionId}/groups`, data),
    types.PROVISIONGROUPS_CREATE,
    callback
  );

const saveProvisionGroup = (data: SaveProvisionGroupCommand, callback?: callbackType<ProvisionGroupDetailView>) =>
  action<ProvisionGroupDetailView>(
    () => client.post(`planner/send/providers/${data.providerId}/provisions/${data.provisionId}/groups/${data.id}`, data),
    types.PROVISIONGROUPS_SAVE,
    callback
  );

const deleteProvisionGroup = (providerId: number, provisionId: number, groupId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.delete(`planner/send/providers/${providerId}/provisions/${provisionId}/groups/${groupId}`),
    types.PROVISIONGROUPS_DELETE,
    callback
  );

const createProvisionRecord = (data: SaveProvisionRecordCommand, callback?: callbackType<ProvisionRecordMapView>) =>
  action<ProvisionRecordMapView>(
    () => client.post(`planner/send/providers/${data.providerId}/provisions/${data.provisionId}/groups/${data.groupId}/records`, data),
    types.PROVISIONRECORDS_CREATE,
    callback
  );

const saveProvisionRecord = (data: SaveProvisionRecordCommand, callback?: callbackType<ProvisionRecordMapView>) =>
  action<ProvisionRecordMapView>(
    () => client.post(`planner/send/providers/${data.providerId}/provisions/${data.provisionId}/groups/${data.groupId}/records/${data.id}`, data),
    types.PROVISIONRECORDS_SAVE,
    callback
  );

const updateProvisionRecordStatus = (data: UpdateProvisionRecordStatusCommand, callback?: callbackType<ProvisionRecordMapView>) =>
  action<ProvisionRecordMapView>(
    () => client.post(`planner/send/providers/${data.providerId}/provisions/${data.provisionId}/groups/${data.groupId}/records/${data.id}/status`, data),
    types.PROVISIONRECORDS_UPDATESTATUS,
    callback
  );

const deleteProvisionRecord = (
  providerId: number,
  provisionId: number,
  groupId: number,
  recordId: number,
  callback?: callbackType<number>
) =>
  action<number>(
    () => client.delete(`planner/send/providers/${providerId}/provisions/${provisionId}/groups/${groupId}/records/${recordId}`),
    types.PROVISIONRECORDS_DELETE,
    callback
  );

const bulkCreateProvisionRecord = (data: BulkCreateProvisionRecordCommand, callback?: callbackType<ProvisionRecordMapView[]>) =>
  action<ProvisionRecordMapView[]>(
    () => client.post(`planner/send/providers/${data.providerId}/provisions/${data.provisionId}/groups/${data.groupId}/addRecords`, data),
    types.PROVISIONRECORDS_BULKCREATE,
    callback
  );

const bulkUpdateProvisionRecords = (data: BulkUpdateProvisionRecordCommand, callback?: callbackType<ProvisionRecordMapView[]>) =>
  action<ProvisionRecordMapView[]>(
    () => client.post(`planner/send/providers/${data.providerId}/provisions/${data.provisionId}/groups/${data.groupId}/updateRecords`, data),
    types.PROVISIONRECORDS_BULKUPDATE,
    callback
  );

const changeProvisionGroupStatus = (
  providerId: number,
  provisionId: number,
  groupId: number,
  status: ProvisionGroupStatus, 
  callback?: callbackType<ProvisionGroupDetailView>
) =>
  action<ProvisionGroupDetailView>(
    () => client.post(`planner/send/providers/${providerId}/provisions/${provisionId}/groups/${groupId}/status`, { status: status }),
    types.PROVISIONGROUPS_STATUSCHANGE,
    callback
  );


const provisionGroupsActions = {
  types,
  getProvisionGroup,
  getProvisionRecords,
  createProvisionGroup,
  saveProvisionGroup,
  deleteProvisionGroup,
  createProvisionRecord,
  saveProvisionRecord,
  updateProvisionRecordStatus,
  deleteProvisionRecord,
  bulkCreateProvisionRecord,
  bulkUpdateProvisionRecords,
  changeProvisionGroupStatus
};

export default provisionGroupsActions;
