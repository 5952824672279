import React, { useEffect, useState, useCallback } from "react";
import lodashDebounce from "lodash.debounce";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  StructuredList,
  Dropdown,
  TimePicker,
  TextInput,
  TextInputType,
  Text,
  Card,
  Size,
  Spacing,
  Spinner,
  Sub,
} from "ui-kit";
import { formatDate } from "utils/dateTime";
import styled from "styled-components";
import { danger, warning } from "ui-kit/common/colors";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import ValidDatePicker from "sharedComponents/common/validDatePicker";
import { useAppSelector } from "reducers/hooks";
import { IDetentionDetails } from "areas/behaviour/utils/detentions";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { IBeehiveError } from "types/common/errors.types";
import { PreflightDetentionDateView } from "areas/behaviour/types/detentionRegisterResponse.types";
import moment from "moment";


const DateCheckMessageWrapper = styled.div`
  .error-sub {
    color: ${danger};
  }
  .warning-sub {
    color: ${warning};
  }
`;

const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;

  .date-picker {
    margin-right: ${Spacing.Medium}px;
  }
`;


interface IDetentionFormProps {
  detentionDetails: IDetentionDetails;
  onChangeDetentionDetails: (details: IDetentionDetails) => void;
  detentionTypesDropdownArray: IDropdownDataItemProps<string, number, null>[];
  onChangeDetentionType?: (value: number) => void;
  allowDetentionTypeChange?: boolean;
  allowDetentionDurationChange?: boolean;
  detentionRooms: IDropdownDataItemProps<string, number, null>[];
  validateDetentionDate?: (detentionTypeId: number, date: Date, time: string, duration: number) => void;
  dateCheckResponse?: PreflightDetentionDateView;
  dateCheckError: IBeehiveError;
  loadingDateCheck: boolean;
  schoolId: number;
}


const DetentionForm: React.FC<IDetentionFormProps> = ({
  detentionDetails,
  onChangeDetentionDetails,
  detentionTypesDropdownArray,
  onChangeDetentionType,
  allowDetentionTypeChange,
  allowDetentionDurationChange,
  detentionRooms,
  validateDetentionDate,
  dateCheckResponse,
  dateCheckError,
  loadingDateCheck,
  schoolId,
}) => {

  const { error } = useAppSelector(state => state.detention);
  const [detentionRoomsArray, setDetentionRoomsArray] = useState<IDropdownDataItemProps<string, number, null>[]>([]);

  useEffect(() => {
    if (detentionRooms.length > 0) {
      const tempDetentionRooms = detentionRooms.slice();
      if (detentionDetails.detentionLocationId !== null) {
        const detentionRoom = detentionRooms.find(
          room => room.value === detentionDetails.detentionLocationId
        );

        if (detentionRoom === undefined) {
          tempDetentionRooms.unshift({
            label: detentionDetails.detentionLocation,
            value: detentionDetails.detentionLocationId,
          });
        }
      }
      setDetentionRoomsArray(tempDetentionRooms);
    }
  }, [detentionRooms]);

  useEffect(() => {
    if (
      detentionRoomsArray.length > 0 &&
      detentionDetails.detentionLocationId === null
    ) {
      onChangeDetentionDetails?.({
        ...detentionDetails,
        detentionLocationId: detentionRoomsArray[0].value,
      });
    }
  }, [detentionRoomsArray, detentionDetails.detentionLocationId]);



  const onChangeDetentionDate = (date: Date | moment.Moment) => {
    onChangeDetentionDetails({
      ...detentionDetails,
      date: moment(date),
    });

    validateDetentionDate?.(
      detentionDetails.detentionTypeId,
      moment(date).toDate(),
      detentionDetails.time,
      detentionDetails.duration
    );
  };

  const onChangeDetentionTime = (time: string) => {
    onChangeDetentionDetails({
      ...detentionDetails,
      time: time,
    });

    validateDetentionDate?.(
      detentionDetails.detentionTypeId,
      detentionDetails.date.toDate(),
      time,
      detentionDetails.duration
    );
  };

  const onChangeDetentionDuration = (duration: number) => {
    onChangeDetentionDetails({
      ...detentionDetails,
      duration: duration,
    });

    debouncedChange(detentionDetails, duration);
  };

  const debouncedChange = useCallback(
    lodashDebounce((_detentionDetails, duration) => {
      validateDetentionDate?.(
        _detentionDetails.detentionTypeId,
        _detentionDetails.date.toDate(),
        _detentionDetails.time,
        duration
      );
    }, 500),
    []
  );

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return (
    <>
      <Card title="Sanction Details">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Sanction Type" required>
              {allowDetentionTypeChange ? (
                <Dropdown
                  placeholder="Sanction Type"
                  fluid
                  items={detentionTypesDropdownArray}
                  onChange={value => onChangeDetentionType?.(value)}
                  value={detentionDetails.detentionTypeId}
                />
              ) : (
                <Text>{detentionDetails.detentionTypeName}</Text>
              )}
            </StructuredList.Item>

            <StructuredList.Item
              name="Sanction Location"
              required
              description="This is the location that the sanction will take place at, not the incident location."
              helpPopup
            >
              <Dropdown
                placeholder="Sanction Location"
                fluid
                items={detentionRoomsArray}
                onChange={value => {
                  onChangeDetentionDetails?.({
                    ...detentionDetails,
                    detentionLocationId: value,
                  });
                }}
                value={detentionDetails.detentionLocationId}
              />
            </StructuredList.Item>

            <StructuredList.Item name="Date" required>
              <DatePickerWrapper>
                <ValidDatePicker
                  dateFormat="DD/MM/YYYY"
                  selectedDate={detentionDetails.date}
                  onChange={onChangeDetentionDate}
                  disabled={loadingDateCheck}
                  closeOnSelect
                  validate
                  isForDetention
                  detentionPeriodId={detentionDetails.detentionPeriodId}
                  schoolId={schoolId}
                />
                {loadingDateCheck && <Spinner size={Size.Small} />}
              </DatePickerWrapper>

              {dateCheckError && (
                <DateCheckMessageWrapper>
                  <Sub className="error-sub">
                    {dateCheckError?.response?.data?.exceptionMessage ||
                      dateCheckError?.exceptionMessage ||
                      (dateCheckError && "An error has occurred.")}
                  </Sub>
                </DateCheckMessageWrapper>
              )}
              {dateCheckResponse && !dateCheckResponse.isDateValid && (
                <DateCheckMessageWrapper>
                  <Sub className="warning-sub">
                    The date was not valid. A valid date has automatically been
                    selected.
                  </Sub>
                </DateCheckMessageWrapper>
              )}
            </StructuredList.Item>

            <StructuredList.Item name="Time" required>
              {allowDetentionDurationChange ? (
                <TimePicker
                  initialValue={detentionDetails.time}
                  onChange={onChangeDetentionTime}
                />
              ) : (
                <Text>{detentionDetails.time}</Text>
              )}
            </StructuredList.Item>

            <StructuredList.Item name="Duration" required>
              {allowDetentionDurationChange ? (
                <TextInput
                  type={TextInputType.Number}
                  value={detentionDetails.duration}
                  onChange={onChangeDetentionDuration}
                  suffix="minutes"
                  min={15}
                />
              ) : (
                <Text>{`${detentionDetails.duration} minutes`}</Text>
              )}
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
    </>
  );
};


export default DetentionForm;
