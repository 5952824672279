import defaults, { IActionState } from "reducers/defaults";
import onCallActions from "areas/behaviour/actions/onCall/onCallActions";
import { IBeehiveAction } from "types/common/action.types";
import { OnCallRequestDetailView } from "areas/analytics/reducers/onCallAnalyticsResponse.types";


interface IOnCallRequestState {
  onCallRequest: OnCallRequestDetailView;
  refresh: boolean;
  actions: {
      createOnCallRequest: IActionState;
      getOnCallRequest: IActionState;
      updateOnCallRequest: IActionState;
      claimOnCallRequest: IActionState;
      cancelOnCallRequest: IActionState;
      resolveOnCallRequest: IActionState;
  };
}

const INITIAL_STATE: IOnCallRequestState = {
  onCallRequest: null,
  refresh: false,
  actions: {
    createOnCallRequest: defaults.INITIAL_ACTION_STATE,
    getOnCallRequest: defaults.INITIAL_ACTION_STATE,
    updateOnCallRequest: defaults.INITIAL_ACTION_STATE,
    claimOnCallRequest: defaults.INITIAL_ACTION_STATE,
    cancelOnCallRequest: defaults.INITIAL_ACTION_STATE,
    resolveOnCallRequest: defaults.INITIAL_ACTION_STATE,
  },
};

const {
  GETONCALLREQUEST,
  CREATEONCALLREQUEST,
  CLAIMONCALLREQUEST,
  RESOLVEONCALLREQUEST,
  CANCELONCALLREQUEST,
  UPDATEONCALLREQUEST,
} = onCallActions.types;


const onCallRequestReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IOnCallRequestState => {
  
  switch (action.type) {
    case GETONCALLREQUEST.START:
      return {
        ...state,
        actions: {
          ...state.actions,
          getOnCallRequest: defaults.LOADING_ACTION_STATE,
          updateOnCallRequest: defaults.INITIAL_ACTION_STATE,
          claimOnCallRequest: defaults.INITIAL_ACTION_STATE,
          cancelOnCallRequest: defaults.INITIAL_ACTION_STATE,
          resolveOnCallRequest: defaults.INITIAL_ACTION_STATE,
        },
      };

    case GETONCALLREQUEST.SUCCESS:
      return {
        ...state,
        onCallRequest: action.payload,
        refresh: false,
        actions: {
          ...state.actions,
          getOnCallRequest: defaults.INITIAL_ACTION_STATE,
        },
      };

    case GETONCALLREQUEST.FAILED:
      return {
        ...state,
        refresh: false,
        actions: {
          ...state.actions,
          getOnCallRequest: defaults.ERROR_ACTION_STATE(action.payload),
        },
      };

    case CREATEONCALLREQUEST.START:
      return {
        ...state,
        actions: {
          ...state.actions,
          createOnCallRequest: defaults.LOADING_ACTION_STATE,
        },
      };

    case CREATEONCALLREQUEST.SUCCESS:
      return {
        ...state,
        actions: {
          ...state.actions,
          createOnCallRequest: defaults.INITIAL_ACTION_STATE,
        },
      };

    case CREATEONCALLREQUEST.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          createOnCallRequest: defaults.ERROR_ACTION_STATE(action.payload),
        },
      };

    case CLAIMONCALLREQUEST.START:
      return {
        ...state,
        actions: {
          ...state.actions,
          claimOnCallRequest: defaults.LOADING_ACTION_STATE,
        },
      };

    case CLAIMONCALLREQUEST.SUCCESS:
      return {
        ...state,
        refresh: true,
        actions: {
          ...state.actions,
          claimOnCallRequest: defaults.INITIAL_ACTION_STATE,
        },
      };

    case CLAIMONCALLREQUEST.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          claimOnCallRequest: defaults.ERROR_ACTION_STATE(action.payload),
        },
      };

    case RESOLVEONCALLREQUEST.START:
      return {
        ...state,
        actions: {
          ...state.actions,
          resolveOnCallRequest: defaults.LOADING_ACTION_STATE,
        },
      };

    case RESOLVEONCALLREQUEST.SUCCESS:
      return {
        ...state,
        refresh: true,
        actions: {
          ...state.actions,
          resolveOnCallRequest: defaults.INITIAL_ACTION_STATE,
        },
      };

    case RESOLVEONCALLREQUEST.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          resolveOnCallRequest: defaults.ERROR_ACTION_STATE(action.payload),
        },
      };

    case CANCELONCALLREQUEST.START:
      return {
        ...state,
        actions: {
          ...state.actions,
          cancelOnCallRequest: defaults.LOADING_ACTION_STATE,
        },
      };

    case CANCELONCALLREQUEST.SUCCESS:
      return {
        ...state,
        refresh: true,
        actions: {
          ...state.actions,
          cancelOnCallRequest: defaults.INITIAL_ACTION_STATE,
        },
      };

    case CANCELONCALLREQUEST.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          cancelOnCallRequest: defaults.ERROR_ACTION_STATE(action.payload),
        },
      };

    case UPDATEONCALLREQUEST.START:
      return {
        ...state,
        actions: {
          ...state.actions,
          updateOnCallRequest: defaults.LOADING_ACTION_STATE,
        },
      };

    case UPDATEONCALLREQUEST.SUCCESS:
      return {
        ...state,
        refresh: true,
        actions: {
          ...state.actions,
          updateOnCallRequest: defaults.INITIAL_ACTION_STATE,
        },
      };

    case UPDATEONCALLREQUEST.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          updateOnCallRequest: defaults.ERROR_ACTION_STATE(action.payload),
        },
      };

    default:
      return state;
  }
};

export default onCallRequestReducer;
