/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Dropdown, IUiKitBaseProps, Spacing } from "../index";
import moment from "moment";

const Wrapper = styled.div<{ fluid?: boolean }>`
  display: ${({ fluid }) => (fluid ? "flex" : "inline-flex")};
  .dropdown {
    margin-right: ${Spacing.Default}px;
    min-width: auto;
    flex-grow: 1;
  }
`;

interface IDateSelectorProps extends IUiKitBaseProps {
  onChange?: (value: string) => void;
  dateFormat?: string;
  fluid?: boolean;
}

const DateSelector: React.FC<IDateSelectorProps> = ({
  className,
  onChange,
  dateFormat,
  fluid,
}) => {
  const [day, setDay] = useState<any>(null);
  const [month, setMonth] = useState<any>(null);
  const [year, setYear] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [date, setDate] = useState<any>(null);
  const [valid, setValid] = useState(true);

  function* range(start: number, end: number, step: number) {
    while (start < end) {
      yield start;
      start += step;
    }
  }

  let years = Array.from(range(2002, new Date().getFullYear(), 1)).map(x => ({
    value: x,
    label: x,
  }));

  let months = Array.from(range(1, 13, 1)).map(x => ({
    value: x,
    label: moment(x, "M").format("MMMM"),
  }));

  let days = Array.from(range(1, 32, 1)).map(x => ({ value: x, label: x }));

  useEffect(() => {
    if (day && month && year) {
      var _date = moment.utc(`${day}/${month}/${year}`, "DD/MM/YYYY");

      if (_date.isValid()) {
        setValid(true);
        setDate(_date);
        onChange?.(_date.format(dateFormat ? dateFormat : "DD/MM/YYYY"));
      } else {
        setValid(false);
      }
    }
  }, [day, month, year]);

  return (
    <Wrapper
      className={`date-selector ${className ? className : ""}`}
      fluid={fluid}
    >
      <Dropdown
        invalid={!valid}
        value={day}
        placeholder="Day"
        items={days}
        onChange={(value: number) => setDay(value)}
      />
      <Dropdown
        invalid={!valid}
        value={month}
        placeholder="Month"
        items={months}
        onChange={(value: number) => setMonth(value)}
      />
      <Dropdown
        invalid={!valid}
        value={year}
        placeholder="Year"
        items={years}
        onChange={(value: number) => setYear(value)}
      />
    </Wrapper>
  );
};

export default DateSelector;
