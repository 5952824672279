import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import OnCallReportTable from "./onCallReportTable";
import OnCallReportForm from "./onCallReportForm";
import { arrays } from "utils";
import { Title, TitleSize } from "ui-kit";
import ReportDescription from "../reportDescription";
import onCallActions from "areas/behaviour/actions/onCall/onCallActions";
import schoolActions from "areas/planner/actions/school/schoolActions";
import onCallReportActions from "areas/analytics/actions/onCallReportActions";
import { Constants } from "configuration";
import { OnCallRequestsFilter } from "areas/analytics/reducers/onCallAnalyticsRequest.types";
import { useAppSelector } from "reducers/hooks";
import moment from "moment";
import { SearchResultViewBase } from "types/common/views.types";


export interface OnCallRequestsFilterExt extends Omit<OnCallRequestsFilter, "classGroupId" | "tutorGroupId" | "studentId" | "startDate" | "endDate"> {
  student?: SearchResultViewBase;
  classGroup?: SearchResultViewBase;
  tutorGroup?: SearchResultViewBase;
  startDate?: Date;
  endDate?: Date;
}


const OnCallReportWrapper = () => {

  const reportRef = useRef<HTMLDivElement>();

  const { onCallCategories, loading: loadingCategories } = useAppSelector(
    state => state.onCallCategories
  );
  const { rooms, loadingRooms } = useAppSelector(state => state.schoolRooms);
  const { user } = useAppSelector(state => state.currentUser);
  const { report, loading, loadingExportReport } = useAppSelector(
    state => state.onCallReport
  );

  const [schoolId, setSchoolId] = useState<number>(null);
  const [reportCriteria, setReportCriteria] = useState<OnCallRequestsFilterExt>({
    startDate: null,
    endDate: null,
    categoryId: null,
    statusId: null,
    student: null,
    classGroup: null,
    tutorGroup: null,
    roomId: null,
    pupilPremium: null,
    sen: null,
    gender: null,
    academicYearId: Constants.ACADEMIC_YEAR_FILTER_ARRAY[0].value,
  });

  useEffect(() => {
    if (arrays.isEmpty(onCallCategories)) {
      onCallActions.getOnCallCategories();
    }
    if (user?.baseSchool) {
      setSchoolId(user.baseSchool.id);
    }
  }, []);

  useEffect(() => {
    if (schoolId) {
      schoolActions.getSchoolRooms(schoolId);
    }

    setReportCriteria({
      ...reportCriteria,
      categoryId: null,
      statusId: null,
      student: null,
      classGroup: null,
      tutorGroup: null,
      roomId: null,
      pupilPremium: null,
      sen: null,
      gender: null,
    });
  }, [schoolId]);

  useEffect(() => {
    if (loading || report) {
      reportRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading, report]);

  const handleReportCriteriaChange = (criteria: OnCallRequestsFilterExt) => {
    setReportCriteria(criteria);
  };

  const handleGenerateReport = (exportReport: boolean) => {
    onCallReportActions.getOnCallReport(
      schoolId,
      {
        startDate: moment(reportCriteria.startDate).format('YYYY-MM-DD'),
        endDate: moment(reportCriteria.endDate).format('YYYY-MM-DD'),
        categoryId: reportCriteria.categoryId,
        statusId: reportCriteria.statusId,
        roomId: reportCriteria.roomId,
        pupilPremium: reportCriteria.pupilPremium,
        sen: reportCriteria.sen,
        gender: reportCriteria.gender,
        classGroupId: reportCriteria.classGroup? parseInt(reportCriteria.classGroup.id) : null,
        tutorGroupId: reportCriteria.tutorGroup? parseInt(reportCriteria.tutorGroup.id) : null,
        studentId: reportCriteria.student?.id,
        academicYearId: reportCriteria.academicYearId,
      },
      exportReport
    );
  };

  return (
    <>
      <Title text="On Call Report" size={TitleSize.H3} />
      <ReportDescription text="Use this report to view details of on call requests between a selected date range." />
      <OnCallReportForm
        schoolId={schoolId}
        handleSchoolChange={id => setSchoolId(id)}
        reportCriteria={reportCriteria}
        handleReportCriteriaChange={handleReportCriteriaChange}
        onCallCategories={onCallCategories?.map(category => ({
          key: category.categoryDetail.id,
          label: category.categoryDetail.name,
          value: category.categoryDetail.id,
        }))}
        rooms={rooms.map(room => ({
          key: room.roomId,
          label: room.roomName,
          value: room.roomId,
        }))}
        handleGenerateReport={handleGenerateReport}
        loadingExportReport={loadingExportReport}
      />
      <br />
      <div ref={reportRef}>
        <OnCallReportTable />
      </div>
    </>
  );
};

export default OnCallReportWrapper;
