import React from "react";
import styled from "styled-components";
import { fontStyle, neutral, Spacing, textFont, typescale } from "../../ui-kit";

const Wrapper = styled.div`
  ${fontStyle(textFont.roman, typescale.paragraph, neutral[700])};
  margin-bottom: ${Spacing.Large}px;
`;


export interface IHelpTextProps {
  children: any | any[];
  style?: React.CSSProperties;
}

const HelpText: React.FC<IHelpTextProps> = ({ children, style }) => {
  return <Wrapper className="help-text" style={style}>{children}</Wrapper>;
};

export default HelpText;
