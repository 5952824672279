import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import FileDownload from "js-file-download";
import { callbackType } from "types/common/action.types";
import { DocumentListView } from "../types/documentResponse.types";

const builder = new actionTypeBuilder("documents");

const types = {
  DOCUMENTS_GETFORUSER: builder.build("DOCUMENTS_GETFORUSER"),
  DOCUMENTS_DOWNLOAD: builder.build("DOCUMENTS_DOWNLOAD"),
};


const getDocumentsForUser = (userId: string, callback?: callbackType<DocumentListView[]>) =>
  action<DocumentListView[]>(
    () => client.get(`users/${userId}/documents`),
    types.DOCUMENTS_GETFORUSER,
    callback
  );

const downloadDocument = (userId: string, id: number, callback?: callbackType<Blob>) =>
  action<Blob>(
    () => client.get(`users/${userId}/documents/${id}`, { responseType: "blob" }),
    types.DOCUMENTS_DOWNLOAD,
    (response, headers) => {
      const contentDisposition = headers["content-disposition"];
      var filename = contentDisposition.split("=");
      FileDownload(response, filename);
      callback?.(response)
    }
  );


const documentActions = {
  types,
  getDocumentsForUser,
  downloadDocument,
};

export default documentActions;
