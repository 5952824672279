import React from "react";
import PropTypes from "prop-types";
import { Spacing, ChipList } from "ui-kit";
import styled from "styled-components";
import { useSelector } from "react-redux";
import StudentSensitiveDataChip from "./studentSensitiveDataChip";

const Wrapper = styled.div`
  .chip-list {
    margin-bottom: 0;
    .chip {
      margin-right: ${Spacing.Small}px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  ${({ hide }) =>
    hide &&
    `
    display: none;
  `}

  ${({ fluid }) =>
    fluid &&
    `
    .chip-list {
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      gap: ${Spacing.Small}px;

      .student-sensitive-data-chip-wrapper {
        // flex: 1;

        .chip {
          width: 100%;
          margin: 0;
        }
      }
    }
  `}
`;

const StudentSensitiveData = ({ student, compact, fluid }) => {

  const { isPrivate } = useSelector(state => state.privacy);

  return (
    <Wrapper
      className="student-sensitive-info"
      hide={isPrivate.sensitiveInfo}
      fluid={fluid}
    >
      <ChipList>
        <StudentSensitiveDataChip.EALChip 
          student={student} 
          compact={compact} 
        />

        <StudentSensitiveDataChip.GiftedChip
          student={student}
          compact={compact}
        />

        <StudentSensitiveDataChip.PPChip 
          student={student} 
          compact={compact} 
        />

        <StudentSensitiveDataChip.SENChip 
          student={student} 
          compact={compact} 
        />

        <StudentSensitiveDataChip.PupilPassportChip
          student={student}
          compact={compact}
        />

        <StudentSensitiveDataChip.ProvisionMapsChip
          student={student}
          compact={compact}
        />

        <StudentSensitiveDataChip.AssignedSSCSupportChip
          student={student}
          compact={compact}
        />

        <StudentSensitiveDataChip.LookedAfterChip
          student={student}
          compact={compact}
        />

        <StudentSensitiveDataChip.ChildProtectionChip
          student={student}
          compact={compact}
        />

        <StudentSensitiveDataChip.BursaryChip
          student={student}
          compact={compact}
        />

      </ChipList>
    </Wrapper>
  );
};

StudentSensitiveData.propTypes = {
  student: PropTypes.object,
};

export default StudentSensitiveData;
