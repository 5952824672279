import paymentActions from "areas/payments/actions/paymentsActions";
import { ProductCategoryDetailView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface IAdminCategoryState {
  loading: boolean;
  error: string | null;
  saving: boolean;
  category: ProductCategoryDetailView | null;
}

const INITIAL_STATE: IAdminCategoryState = {
  loading: false,
  error: null,
  saving: false,
  category: null
};

const adminCategoryReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAdminCategoryState => {
  const {
    PAYMENTS_SAVECATEGORY,
    PAYMENTS_CREATECATEGORY,
    PAYMENTS_GETCATEGORY
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETCATEGORY.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETCATEGORY.SUCCESS:
      return { 
        ...state, 
        category: action.payload, 
        loading: false 
      };

    case PAYMENTS_GETCATEGORY.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PAYMENTS_SAVECATEGORY.START:
    case PAYMENTS_CREATECATEGORY.START:
      return { 
        ...state, 
        saving: true, 
        error: null 
      };

    case PAYMENTS_SAVECATEGORY.SUCCESS:
      return {
        ...state,
        saving: false
      };

    case PAYMENTS_CREATECATEGORY.SUCCESS:
      return {
        ...state,
        saving: false,
        category: action.payload
      };

    case PAYMENTS_SAVECATEGORY.FAILED:
    case PAYMENTS_CREATECATEGORY.FAILED:
      return {
        ...state,
        saving: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default adminCategoryReducer;
