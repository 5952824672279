import action from "actions/action";
import { ToastService } from "ui-kit";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";

const builder = new actionTypeBuilder("studentProfile");

const types = {
  UPDATEAVATAR: builder.build("updateAvatar"),
};

const updateAvatar = (studentId, newAvatar, onSuccess) => {
  return action(
    () => client.post(`planner/students/${studentId}/avatar`, newAvatar),
    types.UPDATEAVATAR,
    response => {
      ToastService.pop("Avatar successfully updated", null, "check-circle");
      onSuccess?.(response);
    }
  );
};

const studentProfileActions = {
  types,
  updateAvatar,
};

export default studentProfileActions;
