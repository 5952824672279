import action from "actions/action";
import { ToastService } from "ui-kit";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";

const builder = new actionTypeBuilder("studentNotes");

const types = {
  GETSTUDENTNOTECATEGORIES: builder.build("getstudentnotecategories"),
  CREATESTUDENTNOTE: builder.build("addstudentnote"),
  DELETESTUDENTNOTE: builder.build("deletestudentnote"),
  GETSTUDENTNOTES: builder.build("getstudentnotes"),
};

const getStudentNoteCategories = isActive => {
  let url = "planner/studentNotesCategories";
  if (isActive !== undefined) {
    url += `?isActive=${isActive}`;
  }

  return action(() => client.get(url), types.GETSTUDENTNOTECATEGORIES);
};

const getStudentNotes = (studentId, categoryId, onSuccess) => {
  let url = `planner/students/${studentId}/notes`;
  if (categoryId) {
    url = url + `?CategoryId=${categoryId}`;
  }

  return action(() => client.get(url), types.GETSTUDENTNOTES, onSuccess);
};

const createStudentNote = (newNote, onSuccess) => {
  return action(
    () => client.post(`planner/studentNote`, newNote),
    types.CREATESTUDENTNOTE,
    response => {
      ToastService.pop("Note successfully added", null, "check-circle");
      onSuccess?.(response);
    }
  );
};

const deleteStudentNote = (studentId, noteId, onSuccess) => {
  return action(
    () => client.delete(`planner/students/${studentId}/notes/${noteId}`),
    types.DELETESTUDENTNOTE,
    response => {
      ToastService.pop("Note successfully deleted", null, "check-circle");
      onSuccess?.(response);
    }
  );
};

const studentNoteActions = {
  types,
  getStudentNoteCategories,
  getStudentNotes,
  createStudentNote,
  deleteStudentNote,
};

export default studentNoteActions;
