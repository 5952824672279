import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import InitialRewardActionWrapper, { IInitialAction } from "./initialRewardActionWrapper";
import {
  StructuredList,
  TextInput,
  TextInputType,
  Button,
  Card,
  Spacing,
  Message,
  Swatches,
  ActionBar,
  DatePicker,
  Right,
  Size,
} from "ui-kit";
import styled from "styled-components";
import HousePointsDropdown from "./housePointsDropdown";
import PublicNotesFormField from "areas/behaviour/components/behaviourCommon/publicNotesFormField";
import InternalNoteFormField from "areas/behaviour/components/behaviourCommon/internalNoteFormField";
import ButtonPanel from "../behaviourCommon/buttonPanel";
import ConfirmModal from "sharedComponents/common/confirmModal";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { StudentFeedListView } from "types/planner/feedResponse.types";
import { RewardView } from "areas/behaviour/types/reward/rewardResponse.types";
import { IBeehiveError } from "types/common/errors.types";
import moment from "moment";


const Wrapper = styled.div`
  display: flex;

  .edit-reward-form {
    flex-grow: 2;
    padding-right: ${Spacing.Medium}px;
    padding-bottom: ${Spacing.Large}px;
  }
`;

export interface IRewardDetails {
  internalNotes: string;
  publicNotes: string;
  points: number;
  housePoints: number;
  incidentDate: Date;
  initialAction: IInitialAction;

}

interface IEditRewardProps {
  activity: StudentFeedListView;
  reward: RewardView;
  handleCloseModal: () => void;
  updateReward: (details: IRewardDetails) => void,
  revokeReward: (revokeNote: string) => void;
  updateError: IBeehiveError;
  isPrimary?: boolean;
}

const EditReward: React.FC<IEditRewardProps> = ({
  activity,
  reward,
  handleCloseModal,
  updateReward,
  revokeReward,
  updateError,
  isPrimary
}) => {

  const [rewardDetails, setRewardDetails] = useState<Omit<IRewardDetails, "housePoints">>({
    publicNotes: "",
    internalNotes: "",
    points: null,
    initialAction: {
      initialActionId: null,
      initialActionName: "",
    },
    incidentDate: null
  });

  const [housePointsChecked, setHousePointsChecked] = useState<boolean>(false);
  const [housePoints, setHousePoints] = useState<number>(null);

  const [openSaveConfirm, setOpenSaveConfirm] = useState<boolean>(false);
  const [revokeNote, setRevokeNote] = useState<string>("");

  const [openRevokeConfirm, setOpenRevokeConfirm] = useState<boolean>(false);
  const [emptyRevokeNoteError, setEmptyRevokeNoteError] = useState<boolean>(false);

  useEffect(() => {
    if (reward) {
      setRewardDetails({
        publicNotes: reward.publicNotes,
        internalNotes: reward.description,
        points: reward.points,
        initialAction: {
          initialActionId: reward.initialActionId,
          initialActionName: reward.initialActionName,
        },
        incidentDate: moment(reward.incidentDate).toDate(),
      });
      setHousePointsChecked(activity.houseName && reward.housePoints > 0);
      setHousePoints(reward.housePoints);
    }
  }, [reward]);

  const handleRevokeReward = () => {
    setEmptyRevokeNoteError(false);
    if (revokeNote.trim() !== "") {
      setEmptyRevokeNoteError(false);
      revokeReward(revokeNote);
      setOpenRevokeConfirm(false);
    } else {
      setEmptyRevokeNoteError(true);
    }
  };

  const handleHousePointCheckbox = () => {
    if (housePointsChecked) {
      setHousePoints(null);
    }
    setHousePointsChecked(!housePointsChecked);
  };

  const confirmContent = (
    <>
      <StructuredList>
        <StructuredList.Item required name="Reason for revoking">
          <TextInput
            fluid
            type={TextInputType.Textarea}
            placeholder="Reason for revoking"
            value={revokeNote}
            onChange={value => {
              setRevokeNote(value);
              setEmptyRevokeNoteError(false);
            }}
          />
        </StructuredList.Item>
      </StructuredList>

      {emptyRevokeNoteError && (
        <Message
          text="The reason for revoking this reward must be entered"
          color={Swatches.Danger}
        />
      )}
    </>
  );

  return (
    <>
      <Wrapper>
        <div className="edit-reward-form">
          <Card>
            <Card.Body>
              <StructuredList>
                {/* {isPrimary && reward.allowOverridePointsForPrimary && (
                  <StructuredList.Item name="Points" required>
                    <PointsDropdown
                      points={points}
                      setPoints={setPoints}
                      maxPoints={reward.maxPoints}
                    />
                  </StructuredList.Item>
                )} */}

                <InitialRewardActionWrapper
                  selectedInitialAction={rewardDetails.initialAction}
                  handleInitialActionChange={initialAction =>
                    setRewardDetails({
                      ...rewardDetails,
                      initialAction: initialAction,
                    })
                  }
                />

                <StructuredList.Item
                  name="Date"
                  description="The date that the reward is being issued for."
                  required
                >
                  <DatePicker
                    dateFormat="DD/MM/YYYY"
                    selectedDate={rewardDetails.incidentDate}
                    onChange={date =>
                      setRewardDetails({
                        ...rewardDetails,
                        incidentDate: date,
                      })
                    }
                    closeOnSelect
                  />
                </StructuredList.Item>

                {/* {activity.houseName && (
                  <StructuredList.Item
                    name="House Points"
                    description="Select the number of house points to give with this reward"
                    helpPopup
                  >
                    <HousePointsDropdown
                      housePointsChecked={housePointsChecked}
                      onChangeHousePointsCheckbox={handleHousePointCheckbox}
                      housePoints={housePoints}
                      onChangeHousePoints={value => setHousePoints(value)}
                    />
                  </StructuredList.Item>
                )} */}

                <StructuredList.Item
                  name="Public Notes"
                  description="Maximum 250 characters. (These notes will be sent/displayed to parents)"
                  helpPopup
                >
                  <PublicNotesFormField
                    notes={rewardDetails.publicNotes}
                    handleNotesChange={value =>
                      setRewardDetails({ ...rewardDetails, publicNotes: value })
                    }
                  />
                </StructuredList.Item>

                <StructuredList.Item
                  name="Internal Notes"
                  description="(These notes will not be sent/displayed to parents)"
                  helpPopup
                >
                  <InternalNoteFormField
                    notes={rewardDetails.internalNotes}
                    handleNoteChange={value =>
                      setRewardDetails({
                        ...rewardDetails,
                        internalNotes: value,
                      })
                    }
                  />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>

          <ApiExceptionMessage error={updateError} />

          <ActionBar low>
            <Right>
              <Button
                text="Save"
                color={Swatches.Success}
                size={Size.Small}
                disabled={rewardDetails.initialAction.initialActionId === null}
                onClick={() => setOpenSaveConfirm(true)}
              />

              <Button
                text="Cancel"
                size={Size.Small}
                onClick={handleCloseModal}
              />
            </Right>
          </ActionBar>
        </div>

        <ButtonPanel>
          <Button
            text="Revoke Reward"
            color={Swatches.Danger}
            onClick={() => setOpenRevokeConfirm(true)}
          />
        </ButtonPanel>
      </Wrapper>

      <ConfirmModal
        openModal={openSaveConfirm}
        confirmMsg={"Are you sure you want to make changes to this reward?"}
        onClose={() => setOpenSaveConfirm(false)}
        onConfirm={() => {
          updateReward({
            internalNotes: rewardDetails.internalNotes,
            publicNotes: rewardDetails.publicNotes,
            points: rewardDetails.points,
            initialAction: rewardDetails.initialAction,
            housePoints: housePoints,
            incidentDate: rewardDetails.incidentDate
        });
          setOpenSaveConfirm(false);
        }}
      />

      <ConfirmModal
        openModal={openRevokeConfirm}
        confirmMsg="Are you sure you want to revoke this Reward point?"
        onClose={() => setOpenRevokeConfirm(false)}
        onConfirm={handleRevokeReward}
      >
        {confirmContent}
      </ConfirmModal>
    </>
  );
};


export default EditReward;
