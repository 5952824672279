import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import { Dropdown, Sub } from "ui-kit";
import provisionCostBandsActions from "areas/send/actions/provisions/provisionCostBandsActions";
import { RootState } from "reducers/store";
import { ProvisionCostBandListView } from "areas/send/types/passportResponse.types";


interface IProvisionCostBandDropdownProps {
  value?: number;
  fluid?: boolean;
  onChange: (value: number, label: string, data: ProvisionCostBandListView) => void;
}


const ProvisionCostBandDropdown: React.FC<IProvisionCostBandDropdownProps> = ({ value, fluid, onChange }) => {

  const { error, loading, bands } = useSelector(
    (state: RootState) => state.provisionCostBands
  );

  useEffect(() => {
    arrays.isEmpty(bands) && provisionCostBandsActions.getProvisionCostBands();
  }, []);

  if (error) {
    return <Sub>There was a problem loading the cost bands</Sub>;
  }

  return (
    <Dropdown
      value={value}
      loading={loading}
      placeholder="Choose a Cost Band"
      fluid={fluid}
      onChange={onChange}
    >
      {bands
        .map((band, index) => (
          <Dropdown.Item
            label={band.name}
            value={band.id}
            key={index}
            data={band}
          />
        ))}
    </Dropdown>
  );
};

export default ProvisionCostBandDropdown;
