import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import FileDownload from "js-file-download";
import client from "services/client";
import { BeehiveReport, callbackType } from "types/common/action.types";
import { GetStudentAssignedStaffReportCommand } from "../types/assignedStaffAnalyticRequests.types";
import { StudentListView } from "types/users/userListViews.types";
import { StudentAssignedStaffReportView } from "../types/assignedStaffAnalyticResponse.types";

const builder = new actionTypeBuilder("behaviourAnalytics");
const types = {
  GET_ASSIGNEDSTAFFREPORT: builder.build("GET_ASSIGNEDSTAFFREPORT"),
  EXPORT_ASSIGNEDSTAFFREPORT: builder.build("EXPORT_ASSIGNEDSTAFFREPORT"),
};


const getStudentAssignedStaff = <T extends boolean = false>(
  command: GetStudentAssignedStaffReportCommand,
  exportReport?: T,
  onSuccess?: callbackType<T extends true ? BeehiveReport : StudentAssignedStaffReportView[]>
) => {
  return exportReport
    ? action<BeehiveReport>(
        () => client.post(`planner/reporting/assignedStaff/export`, command, { responseType: "blob" }),
        types.EXPORT_ASSIGNEDSTAFFREPORT,
        response => {
          onSuccess?.(response as any);
          FileDownload(response, "AssignedStaffReport.csv");
        }
      )
    : action<StudentAssignedStaffReportView[]>(
        () => client.post(`planner/reporting/assignedStaff`, command),
        types.GET_ASSIGNEDSTAFFREPORT,
        response => onSuccess?.(response as any)
      );
};


const assignedStaffAnalyticsActions = {
  types,
  getStudentAssignedStaff,
};

export default assignedStaffAnalyticsActions;
