import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Moment from "react-moment";
import {
  Tabs,
  Table,
  EmptyMessage,
  Chip,
  Swatches,
  Button,
  Size,
} from "ui-kit";
import { arrays, files } from "utils";
import { Constants } from "configuration";
import MatriculationReports from "./matriculationReports";
import Pagination from "./pagination";
import matriculationActions from "../actions/matriculationActions";
import { RootState } from "reducers/store";
import { MatriculationStudentListView } from "../types/matriculationResponses.types";
import { PagedQueryView } from "types/common/views.types";
import { IRole } from "configuration/constants.types";


interface IMatriculationStudentsTableProps {
  students: PagedQueryView<MatriculationStudentListView>;
  loading?: boolean;
  error?: string;
}

export interface matriculationReport {
  id: number;
  name: string;
  description: string;
  action: () => Promise<any>;
  roles: IRole[];
}


const MatriculationSessionStudents = () => {

  const { schoolId, sessionId } = useParams();
  const { sessionStudents, loading, error } = useSelector((state: RootState) => state.matriculationSessions);

  const loadStudents = (pageIndex: number) => {
    matriculationActions.getSessionStudents(parseInt(schoolId), parseInt(sessionId), pageIndex);
  };

  useEffect(() => {
    loadStudents(0);
  }, []);

  const onPage = (pageIndex: number) => {
    loadStudents(pageIndex);
  };

  const reports: matriculationReport[] = [
    {
      id: 0,
      name: "Download CSV Report",
      description:
        "Export the students for this matriculation session to a CSV file and download it",
      action: () =>
        matriculationActions.runSessionReport(parseInt(schoolId), parseInt(sessionId), reportData =>
          files.openCsv(reportData, "MatriculationStudents")
        ),
      roles: [
        Constants.ROLES.MATRICULATION_ADMINISTRATOR,
        Constants.ROLES.MATRICULATION_USER,
        Constants.ROLES.IT_ADMINISTRATOR,
        Constants.ROLES.IT_USER,
      ],
    },
  ];

  return (
    <Tabs>
      <Tabs.Pane name="students" label="Students">
        <MatriculationStudentsTable
          students={sessionStudents}
          loading={loading}
          error={error}
        />
        <Pagination paging={sessionStudents?.paging} onPageClick={onPage} />
      </Tabs.Pane>
      <Tabs.Pane name="reporting" label="Reporting">
        <MatriculationReports reports={reports} />
      </Tabs.Pane>
    </Tabs>
  );
};


const MatriculationStudentsTable: React.FC<IMatriculationStudentsTableProps> = ({ students, loading, error }) => {

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onDetailsClick = (student: MatriculationStudentListView) => {
    navigate(`${pathname}/${student.id}`);
  };

  return (
    <>
      <Table
        zebra
        grow
        loading={loading}
        error={error != null}
        empty={arrays.isEmpty(students?.items)}
        emptyMessage={
          <EmptyMessage
            icon="calendar"
            title="No matriculation students for this session found"
            cover
          />
        }
      >
        <Table.Header>
          <Table.HeaderCell width={1}>Status</Table.HeaderCell>
          <Table.HeaderCell width={1}>First Name</Table.HeaderCell>
          <Table.HeaderCell width={1}>Last Name</Table.HeaderCell>
          <Table.HeaderCell width={2}>Previous School</Table.HeaderCell>
          <Table.HeaderCell width={1}>Date of Birth</Table.HeaderCell>
          <Table.HeaderCell width={1} right />
        </Table.Header>

        <Table.Body>
          {students?.items?.map((student: MatriculationStudentListView, index: number) => (
            <Table.Row key={index}>
              <Table.Cell width={1}>
                {student.processed ? (
                  <Chip text="Processed" colorSwatch={Swatches.Success} />
                ) : (
                  <Chip text="Unprocessed" colorSwatch={Swatches.Low} />
                )}
              </Table.Cell>
              <Table.Cell width={1}>{student.firstName}</Table.Cell>
              <Table.Cell width={1}>{student.lastName}</Table.Cell>
              <Table.Cell width={2}>{student.previousSchool}</Table.Cell>
              <Table.Cell width={1}>
                <Moment format="DD/MM/YYYY" date={student.dateOfBirth} />
              </Table.Cell>
              <Table.Cell width={1} right>
                <Button
                  onClick={() => onDetailsClick(student)}
                  size={Size.Small}
                  color={Swatches.Low}
                  text="View Details"
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default MatriculationSessionStudents;
