import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Swatches,
  Spacing,
  fontStyle,
  displayFont,
  typescale,
  neutral,
  BorderRadius,
} from "ui-kit";
import { StatusView, RoleView, UserName } from "sharedComponents/common";
import { Constants } from "configuration";
import AdminSubmissionStateModal, {
  STATEMODAL_MODE,
} from "./submissionStateModal";
import styled from "styled-components";
import { SubmissionDetailView } from "areas/forms/types/formResponse.types";
import { RootState } from "reducers/store";


const Wrapper = styled.div`
  padding: ${Spacing.Medium}px;
  margin-bottom: ${Spacing.Medium}px;
  p {
    ${fontStyle(displayFont.roman, typescale.paragraph, neutral[100])}
    letter-spacing: 0.5px;
  }
  background: ${Swatches.Blue.swatch};
  border-radius: ${BorderRadius.Default}px;
`;


interface ISubmissionStatusControlProps {
  submission: SubmissionDetailView;
}


const SubmissionStatusControl: React.FC<ISubmissionStatusControlProps> = ({ submission }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const [stateModalOpen, setStateModalOpen] = useState<boolean>(false);
  const [stateModalMode, setStateModalMode] = useState<STATEMODAL_MODE>(STATEMODAL_MODE.ACCEPT);

  const openStateModal = (mode: STATEMODAL_MODE) => {
    setStateModalMode(mode);
    setStateModalOpen(true);
  };

  return (
    <>
      <Wrapper>
        <StatusView status={submission.latestHistory.status}>
          <StatusView.Item status={Constants.SUBMISSION_STATUS.DRAFT.value}>
            <p>
              This form is currently a <b>Draft</b> and has not yet been
              submitted for approval by{" "}
              <b>
                <UserName user={submission.submittedBy} />
              </b>
            </p>
          </StatusView.Item>
          <StatusView.Item status={Constants.SUBMISSION_STATUS.SUBMITTED.value}>
            {user && user.id === submission.authoriser?.id ? (
              <p>
                This form has been submitted by{" "}
                <b>
                  <UserName user={submission.submittedBy} />
                </b>{" "}
                and is waiting for your authorisation.
              </p>
            ) : (
              <p>
                This form has been submitted by{" "}
                <b>
                  <UserName user={submission.submittedBy} />
                </b>{" "}
                and is waiting for authorisation from{" "}
                <b>
                  <UserName user={submission.authoriser} />
                </b>
                .
              </p>
            )}
          </StatusView.Item>
          <StatusView.Item status={Constants.SUBMISSION_STATUS.APPROVED.value}>
            <p>
              This form has been authorised by{" "}
              <b>
                <UserName user={submission.authoriser} />
              </b>{" "}
              and is currently in the{" "}
              <b>{submission.latestHistory.statusName}</b> state.{" "}
              <RoleView roles={[Constants.ROLES.FINANCE_USER]}>
                You need to either <b>Confirm</b> or <b>Reject</b> this form
                submission.
              </RoleView>
            </p>
          </StatusView.Item>
          <StatusView.Item status={Constants.SUBMISSION_STATUS.CONFIRMED.value}>
            <p>
              This form is currently in the{" "}
              <b>{submission.latestHistory.statusName}</b> state.{" "}
              <RoleView roles={[Constants.ROLES.FINANCE_USER]}>
                If all actions have been finished for this form, you can mark it
                as <b>Complete</b>.
              </RoleView>
            </p>
          </StatusView.Item>
          <StatusView.Item status={Constants.SUBMISSION_STATUS.COMPLETE.value}>
            <p>
              This form is <b>Complete</b>. No further action is required.
            </p>
          </StatusView.Item>
          <StatusView.Item status={Constants.SUBMISSION_STATUS.CANCELLED.value}>
            <p>
              This form has been <b>Cancelled</b>. No further changes can be
              made.
            </p>
          </StatusView.Item>
        </StatusView>
      </Wrapper>
      <AdminSubmissionStateModal
        open={stateModalOpen}
        mode={stateModalMode}
        onClose={() => setStateModalOpen(false)}
        submission={submission}
      />
    </>
  );
};

export default SubmissionStatusControl;
