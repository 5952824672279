import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { SaveTagCommand } from "areas/send/types/passportRequest.types";
import { TagListView } from "areas/send/types/passportResponse.types";
import client from "services/client";
import { callbackType } from "types/common/action.types";

const builder = new actionTypeBuilder("send");

const types = {
  TAGS_GET: builder.build("tags_get"),
  TAG_GET: builder.build("tag_get"),
  TAG_CREATE: builder.build("tag_create"),
  TAG_SAVE: builder.build("tag_save"),
  TAG_DELETE: builder.build("tag_delete"),
};


const getTags = (callback?: callbackType<TagListView[]>) =>
  action<TagListView[]>(
    () => client.get(`planner/send/tags`), 
    types.TAGS_GET, 
    callback
  );

const getTag = (id: number, callback?: callbackType<TagListView>) =>
  action<TagListView>(
    () => client.get(`planner/send/tags/${id}`), 
    types.TAG_GET, 
    callback
  );

const createTag = (data: SaveTagCommand, callback?: callbackType<TagListView>) =>
  action<TagListView>(
    () => client.post(`planner/send/tags`, data),
    types.TAG_CREATE,
    callback
  );

const saveTag = (data: SaveTagCommand, callback?: callbackType<TagListView>) =>
  action<TagListView>(
    () => client.post(`planner/send/tags/${data.id}`, data),
    types.TAG_SAVE,
    callback
  );

const deleteTag = (id: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.delete(`planner/send/tags/${id}`),
    types.TAG_DELETE,
    callback
  );

  
const tagsActions = {
  types,
  getTags,
  getTag,
  createTag,
  saveTag,
  deleteTag,
};

export default tagsActions;
