import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import { Dropdown, Sub } from "ui-kit";
import config from "configuration";
import timetableActions from "areas/planner/actions/timetableActions";
import { RootState } from "reducers/store";
import { TermListView } from "areas/send/types/passportResponse.types";


interface ITermsDropdownProps {
  value?: number;
  fluid?: boolean;
  onChange?: (value: number, label: string, data?: TermListView) => void;
  includeAllOption?: boolean;
}

const ALL_OPTION: TermListView = {
  id: -1,
  name: "All Terms",
  academicYear: "",
  startDate: null,
  endDate: null,
};


const TermsDropdown: React.FC<ITermsDropdownProps> = ({ value, fluid, onChange, includeAllOption }) => {

  const { error, loading, terms } = useSelector((state: RootState) => state.terms);
  const [_terms, _setTerms] = useState([]);

  useEffect(() => {
    arrays.isEmpty(terms) &&
      timetableActions.getTerms(config.academicYear.current);
  }, []);

  useEffect(() => {
    if (!arrays.isEmpty(terms)) {
      includeAllOption ? _setTerms([ALL_OPTION, ...terms]) : _setTerms(terms);
    }
  }, [terms]);

  if (error) {
    return <Sub>There was a problem loading the terms</Sub>;
  }

  return (
    <Dropdown
      value={value}
      loading={loading}
      placeholder="Choose a Term"
      fluid={fluid}
      onChange={onChange}
    >
      {_terms.map((term: TermListView, index: number) => (
        <Dropdown.Item
          label={`${term.name} ${term.academicYear}`}
          value={term.id}
          key={index}
          data={term}
        />
      ))}
    </Dropdown>
  );
};

export default TermsDropdown;
