import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import {
  Size,
  Spacing,
  Loader,
  Tabs,
  ToastContainer,
  neutral,
  fontStyle,
  typescale,
  displayFont,
  honeygold,
} from "ui-kit";
import PrimaryLeaderboardWrapper from "./primaryLeaderboardWrapper";
import WhiteboardClassLayoutWrapper from "./whiteboardClassLayoutWrapper";
import BehaviourModalWrapper from "./behaviourModalWrapper";
import { ReactComponent as BalloonSvg } from "assets/primary/balloon.svg";
import startupActions from "actions/startup/startupActions";
import privacyActions from "actions/ui/privacy";
import classViewerActions from "areas/planner/actions/classViewer/classViewerActions";
import groupActions from "areas/planner/actions/group/groupActions";
import classRoomActions from "../../../actions/classViewer/classRoomActions";
import { useAppSelector } from "reducers/hooks";
import { ClassLayoutStudentView } from "areas/planner/types/classLayoutResponse.types";
import { GroupType } from "types/planner/groups.types";
import { UserDetailListView, UserTinyView } from "types/users/userListViews.types";

const getBackground = () => {
  return "spring"; // Math.floor(Math.random() * 20);
};

const Wrapper = styled.div`
  background: white url("/images/primary/bg/${getBackground()}.svg") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  // flex-grow: 1;
  padding: ${Spacing.Medium}px ${Spacing.ExtraLarge}px ${Spacing.Jumbo}px
    ${Spacing.ExtraLarge}px;
  display: flex;
  flex-direction: column;
  // align-items: stretch;
  overflow-y: auto;
  height: 100vh;
  box-sizing: border-box;

  h1 {
    ${fontStyle(displayFont.bold, typescale.paragraph, neutral[100])}
    align-self: center;
    font-size: 40px;
    margin: ${Spacing.Medium * 2}px 0 ${Spacing.ExtraLarge * 2}px 0;
  }

  .tabs {
    .tab-bar {
      border-bottom: none;
    }

    .tab-item {
      background: rgba(0, 0, 0, 0.6);
      color: white;
      font-size: 18px;
      padding: 12px 24px;
    }

    .tab-item-selected {
      background: ${honeygold};
    }
  }

  .tabs-pane {
    padding-top: ${Spacing.Medium * 2}px;
  }
`;

const Balloon = styled.div`
  width: 120px;
  position: absolute;
  top: 40px;
  right: 40px;
  transform-origin: 50% 10%;
  animation: moveIt 12s ease-in-out infinite;

  @keyframes moveIt {
    0%,
    100% {
      transform: rotate(3.5deg);
    }
    50% {
      transform: rotate(-3.5deg);
    }
  }
`;


const WhiteboardViewWrapper: React.FC = () => {

  const {
    layoutId,
    groupId,
    groupType,
    roomId,
    roomLayoutId,
    layoutDirection,
    userId,
    isPrivate: noSensitiveInfo,
    noProfileImage,
  } = useParams();

  const { group, loading: loadingGroup } = useAppSelector(state => state.group);
  const { classLayout, loadingClassLayout } = useAppSelector(
    state => state.classLayout
  );
  const { roomLayout, actions } = useAppSelector(
    state => state.classViewerRoomLayout
  );
  const { isPrivate } = useAppSelector(state => state.privacy);

  const [selectedStudents, setSelectedStudents] = useState<UserTinyView[]>([]);//UserDetailListView
  const [openBehaviourModal, setOpenBehaviourModal] = useState<boolean>(false);

  useEffect(() => {
    startupActions.setUserForNewWindow();
    privacyActions.setPrivacy({
      ...isPrivate,
      sensitiveInfo: noSensitiveInfo === "true" ? true : false,
      profileImage: noProfileImage === "true" ? true : false,
    });
    classViewerActions.getClassLayout(layoutId);
    groupActions.getGroup(userId, parseInt(groupId), parseInt(groupType));
  }, []);

  useEffect(() => {
    if (group && roomId && roomLayoutId) {
      classRoomActions.getRoomLayout(group.school.id, roomId, roomLayoutId);
    }
  }, [group]);

  const handleOpenBehaviourModal = (students: UserTinyView[]) => {
    setSelectedStudents(students);
    setOpenBehaviourModal(true);
  };

  const handleCloseBehaviourModal = () => {
    setOpenBehaviourModal(false);
    setSelectedStudents([]);
  };

  if (loadingGroup || loadingClassLayout || actions.getRoomLayout.isLoading) {
    return <Loader size={Size.Large} cover />;
  }

  return (
    <Wrapper>
      <h1>{group && group.name}</h1>
      <Balloon>
        <BalloonSvg />
      </Balloon>
      <Tabs>
        <Tabs.Pane label="Class" tooltip="Class">
          <WhiteboardClassLayoutWrapper
            studentsInClass={group && group.students}
            room={roomLayout}
            layoutDirection={parseInt(layoutDirection)}
            classLayout={classLayout}
            handleOpenBehaviourModal={handleOpenBehaviourModal}
            selectedStudents={selectedStudents}
            setSelectedStudents={students => setSelectedStudents(students)}
          />
        </Tabs.Pane>
        <Tabs.Pane label="Rocket Race" tooltip="Rocket Race">
          <PrimaryLeaderboardWrapper
            groupId={parseInt(groupId)}
            groupType={parseInt(groupType) as GroupType}
            handleOpenBehaviourModal={handleOpenBehaviourModal}
          />
        </Tabs.Pane>
      </Tabs>

      <BehaviourModalWrapper
        openBehaviourModal={openBehaviourModal}
        handleCloseBehaviourModal={handleCloseBehaviourModal}
        students={selectedStudents}
        schoolId={group && group.school.id}
        groupId={parseInt(groupId)}
        groupType={parseInt(groupType) as GroupType}
        clearSelectedStudents={() => setSelectedStudents([])}
      />

      <ToastContainer />
    </Wrapper>
  );
};

export default WhiteboardViewWrapper;
