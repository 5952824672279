import React from "react";
import LoginButton from "../loginButton";
import { Link } from "react-router-dom";


const Registered = () => {
  
  return (
    <>
      <p>
        It appears that your details are already confirmed with us and you can{" "}
        <Link to="/">login</Link> with your existing Beehive account.
      </p>
      <LoginButton />
    </>
  );
};

export default Registered;
