import React from "react";
import styled from "styled-components";
import { Size, Spacing, Swatches, displayFont, fontStyle, typescale } from "ui-kit";
import { ColorSwatch, neutral } from "../common/colors";

export interface ILoaderProps {
  loadingMessage?: string;
  size?: Size;
  cover?: boolean;
  colorSwatch?: ColorSwatch;
  fluid?: boolean;
  className?: string;
}

const getSize = (size: Size): number => {
  switch (size) {
    case Size.ExtraSmall:
      return 1;
    case Size.Small:
      return 1.25;
    case Size.Medium:
      return 2;
    case Size.Large:
      return 3;
    case Size.ExtraLarge:
      return 5;
    default:
      return 2;
  }
};

const Wrapper = styled.div<{ cover?: boolean; size?: Size; isFluid?: boolean }>`
  ${({ cover, isFluid, size }) =>
    cover
      ? `
        position: absolute;
        display: flex;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      `
      : `
        position: relative;
        display: block;
        background: none;
        width: ${isFluid ? "100%" : size ? getSize(size) : 1}rem;
        height: ${size ? getSize(size) : 1}rem;
      `}

  text-align: center;
  align-items: center;
  justify-content: center;

  h3 {
    ${fontStyle(displayFont.medium, typescale.header3, neutral[600])}
    margin-left: ${Spacing.Large}px;
  }

  svg {
    width: ${({ size }) => (size ? getSize(size) : 2)}rem;
    height: ${({ size }) => (size ? getSize(size) : 2)}rem;
    overflow: visible;
  }

  .spinner-cell {
    animation: spin 2000ms ease-in-out infinite;
    transform-origin: 56% 50%;
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const Loader: React.FC<ILoaderProps> = ({
  loadingMessage,
  size,
  colorSwatch,
  cover,
  fluid,
  className,
}) => {
  const renderSvg = () => {
    return (
      <svg
        className={`spinner ${className ? className : ""}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 150 150"
      >
        <g>
          <path
            className="spinner-cell one"
            fill={colorSwatch?.swatch}
            d="M124.374 68.244H92.667c-2.381 0-4.581-1.271-5.771-3.332L71.043 37.454c-1.19-2.063-1.19-4.603 0-6.664L86.896 3.332C88.086 1.271 90.287 0 92.667 0h31.706c2.381 0 4.581 1.27 5.771 3.332l15.854 27.458c1.19 2.062 1.19 4.602 0 6.664l-15.854 27.458c-1.19 2.062-3.391 3.332-5.77 3.332zM96.515 54.916h24.012l12.006-20.794-12.006-20.793H96.515L84.509 34.122l12.006 20.794z"
          />
        </g>
        <g>
          <path
            className="spinner-cell two"
            fill={colorSwatch?.swatch}
            d="M54.223 108.859H22.516c-2.381 0-4.581-1.271-5.771-3.332L.893 78.068c-1.19-2.062-1.19-4.602 0-6.664l15.853-27.458c1.19-2.062 3.39-3.332 5.771-3.332h31.707c2.381 0 4.581 1.271 5.771 3.332l15.854 27.458c1.189 2.063 1.189 4.603 0 6.664l-15.854 27.459c-1.192 2.063-3.391 3.332-5.772 3.332zm-27.86-13.328h24.012l12.006-20.794-12.006-20.795H26.363L14.357 74.737l12.006 20.794z"
          />
        </g>
        <g>
          <path
            className="spinner-cell three"
            fill={colorSwatch?.swatch}
            d="M124.374 147.99H92.667c-2.381 0-4.581-1.271-5.771-3.331L71.043 117.2c-1.19-2.063-1.19-4.603 0-6.664l15.853-27.458c1.19-2.063 3.391-3.332 5.771-3.332h31.706c2.381 0 4.581 1.271 5.771 3.332l15.854 27.458c1.19 2.063 1.19 4.603 0 6.664l-15.854 27.459c-1.19 2.062-3.391 3.331-5.77 3.331zm-27.859-13.327h24.012l12.006-20.795-12.006-20.795H96.515l-12.006 20.795 12.006 20.795z"
          />
        </g>
      </svg>
    );
  };

  return (
    <Wrapper
      className={`loader ${className ? className : ""}`}
      size={size}
      cover={cover}
      isFluid={fluid}
    >
      {renderSvg()}
      {loadingMessage && (
        <h3>{loadingMessage}</h3>
      )}
    </Wrapper>
  );
};

Loader.defaultProps = {
  cover: false,
  colorSwatch: Swatches.Primary,
  size: Size.ExtraSmall,
};

export default Loader;
