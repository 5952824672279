import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import FileDownload from "js-file-download";
import { BeehiveReport, callbackType } from "types/common/action.types";
import { ReviewDetailView } from "../types/managedStaffResponse.types";
import { StaffType } from "types/users/userStaff.types";
import { SaveReviewCommand } from "../types/managedStaffRequest.types";

const prefix = "review";
const builder = new actionTypeBuilder(prefix);

const types = {
  GETREVIEW: builder.build("getreview"),
  GETREVIEWREPORT: builder.build("getreviewreport"),
  STARTREVIEW: builder.build("startreview"),
  SAVEREVIEW: builder.build("savereview"),
  DOWNLOADREVIEW: builder.build("downloadreview"),
};


const getReview = (staffId: string, reviewId: number, callback?: callbackType<ReviewDetailView>) =>
  action<ReviewDetailView>(
    () => client.get(`pm/staff/${staffId}/reviews/${reviewId}`),
    types.GETREVIEW,
    callback
  );

const getReviewReport = (schoolId: number, academicYearId: number, staffType: StaffType, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.get(`pm/staff/reviews/report?schoolId=${schoolId}&academicYearId=${academicYearId}&staffType=${staffType}`),
    types.GETREVIEWREPORT,
    callback
  );

const startReview = (staffId: string, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`pm/staff/${staffId}/reviews`),
    types.STARTREVIEW,
    callback
  );

const saveReview = (review: SaveReviewCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`pm/staff/${review.reviewee.id}/reviews/${review.id}`, review),
    types.SAVEREVIEW,
    callback
  );

const downloadReview = (staffId: string, reviewId: number, callback?: callbackType<Blob>) =>
  action<Blob>(
    () => client.get(`pm/staff/${staffId}/reviews/${reviewId}/download`, { responseType: "blob" }),
    types.DOWNLOADREVIEW,
    (responseData, responseHeaders) => {
      const contentDisposition = responseHeaders["content-disposition"];
      var filename = contentDisposition.split("=");
      FileDownload(responseData, filename[1]);
      callback && callback(responseData);
    }
  );


const reviewActions = {
  types,
  getReview,
  getReviewReport,
  startReview,
  saveReview,
  downloadReview
};

export default reviewActions;
