import React, { useState, useEffect } from "react";
import { IHolidayAndLeaveReportProps } from ".";
import { useAppSelector } from "reducers/hooks";
import { DatePicker, Dropdown, StructuredList } from "ui-kit";
import { ApprovalStatusDropdown, SchoolDropdown, StaffSelector } from "sharedComponents/common";
import { arrays } from "utils";
import { Constants } from "configuration";
import { configArrType } from "utils/arrays";
import CoverRequirementDropdown from "../../coverRequirementDropdown";
import LeaveRequestTypeDropdown from "../../leaveRequestTypeDropdown";


const LeaveRequestsReport: React.FC<IHolidayAndLeaveReportProps> = ({ filter, setFilter }) => {

  const { user } = useAppSelector(state => state.currentUser);
  
  const userHasMultipleSchools = () => user.schools.length > 0;
  
  return (
    <>
      <StructuredList.Item name="School" hide={filter.staffId != null}>
        <SchoolDropdown
          initialValue={filter.schoolId}
          includeAllOption={userHasMultipleSchools()}
          fluid
          schools={user.schools}
          onChange={val => setFilter({ ...filter, schoolId: val })}
        />
      </StructuredList.Item>

      <StructuredList.Item name="Staff Member">
        <StaffSelector
          user={filter.staffId}
          onChange={user => setFilter({ ...filter, staffId: user })}
          onCancel={() => setFilter({ ...filter, staffId: null })}
          schools={(filter.schoolId != null && filter.schoolId != -1) ? [filter.schoolId] : undefined}
        />
      </StructuredList.Item>

      <StructuredList.Item name="Staff Type">
        <Dropdown
          value={filter.staffType}
          fluid
          onChange={val => setFilter({ ...filter, staffType: val })}
        >
          <Dropdown.Item label="All Staff Types" value={null} />
          <Dropdown.Item label="Teaching" value={0} />
          <Dropdown.Item label="Associate" value={1} />
        </Dropdown>
      </StructuredList.Item>

      <StructuredList.Item name="Starts">
        <DatePicker
          dateFormat="DD/MM/YYYY"
          closeOnSelect
          selectedDate={filter.startDate}
          onChange={value =>
            setFilter({ ...filter, startDate: value })
          }
        />
      </StructuredList.Item>

      <StructuredList.Item name="Ends">
        <DatePicker
          dateFormat="DD/MM/YYYY"
          closeOnSelect
          selectedDate={filter.endDate}
          onChange={value =>
            setFilter({ ...filter, endDate: value })
          }
        />
      </StructuredList.Item>

      <StructuredList.Item name="Leave Type">
        <LeaveRequestTypeDropdown
          value={filter.leaveRequestType}
          onChange={val => setFilter({ ...filter, leaveRequestType: val })}
          includeAll
          fluid
        />
      </StructuredList.Item>

      <StructuredList.Item name="Status">
        <ApprovalStatusDropdown
          value={filter.status}
          onChange={val => setFilter({ ...filter, status: val })}
          includeAll
          fluid
        />
      </StructuredList.Item>
      
      <StructuredList.Item name="Cover Requirement">
        <CoverRequirementDropdown
          value={filter.coverRequirement}
          onChange={val => setFilter({ ...filter, coverRequirement: val })}
          includeAll
          fluid
        />
      </StructuredList.Item>
    </>
  );
}


export default LeaveRequestsReport;