import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import HelpMessage from "sharedComponents/common/helpMessage";
import EventsTable from "./eventsTable";
import EventsFilter from "./eventsFilter";
import { Spacing } from "ui-kit";
import calendarActions from "areas/calendar/actions/calendarActions";
import { RootState } from "reducers/store";


const ManagedEvents = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { events, loading, paging, error } = useSelector((state: RootState) => state.managedCalendarEvents);
  const { filter } = useSelector((state: RootState) => state.eventsFilter);

  const loadEvents = (pageIndex: number) => {
    filter &&
      calendarActions.getManagedCalendarEvents(user.id, filter, pageIndex);
  };

  useEffect(() => {
    loadEvents(0);
  }, [filter]);

  const onPage = () => {
    loadEvents(paging.pageIndex + 1);
  };

  return (
    <>
      <EventsFilter />
      {filter?.status == 3 && (
        <HelpMessage
          icon="info-circle"
          sub="Events with no future dates will automatically have their status changed to Paused"
          marginTop={Spacing.ExtraSmall}
          marginBottom={Spacing.Small}
          padding={Spacing.Medium}
        />
      )}
      <EventsTable
        events={events}
        loading={loading}
        error={error}
        paging={paging}
        handlePage={onPage}
      />
    </>
  );
};

export default ManagedEvents;
