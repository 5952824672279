import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Spacing,
  neutral,
  honeygold,
  BorderRadius,
  fontStyle,
  textFont,
  typescale,
  Tooltip,
} from "ui-kit";
import styled from "styled-components";
import { SchoolAvatar } from "sharedComponents/common";
import { arrays, types } from "utils";
import Tippy from "@tippyjs/react";
import { RootState } from "reducers/store";
import { SchoolStartupView } from "types/schools/schools.types";
import { ISchool } from "configuration/constants.types";

const ALL_OPTION = {
  key: -1,
  label: "All Schools",
  value: -1,
  sub: "LHT",
};

const Wrapper = styled.div``;

const SchoolItemsWrapper = styled.div`
  // display: flex;
  // flex-wrap: wrap;
  // column-count: 3;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const ButtonWrapper = styled.div`
  span {
    ${fontStyle(textFont.roman, typescale.sub, honeygold)}
    margin-right: ${Spacing.Medium}px;

    &:hover {
      cursor: pointer;
      color: ${neutral[600]};
    }
  }
`;

const SchoolWrapper = styled.div<{ readOnly?: boolean; selected?: boolean }>`
  padding: ${Spacing.Default}px;
  background: ${neutral[200]};
  margin-bottom: ${Spacing.Small}px;
  margin-right: ${Spacing.Small}px;
  border-radius: ${BorderRadius.Default}px;
  transition: 100ms ease all;
  box-content: border-box;

  &:hover {
    cursor: pointer;
    background: ${honeygold};
    span {
      color: white;
    }
  }

  ${({ readOnly }) =>
    readOnly &&
    `
  
    background: ${neutral[700]};
  pointer-events: none;
    &:hover {
        cursor: not-allowed;
    }
  `}

  ${({ selected }) =>
    selected &&
    `
    background: ${honeygold};
    span {
        color: white !important;
    }
  `}
`;

export interface SchoolWithRead extends SchoolStartupView {
  readOnly?: boolean;
  selected?: boolean;
}

interface ISchoolPickerProps {
  onChange?: (newSchools: SchoolWithRead[]) => void;
  value?: SchoolWithRead[] | ({ id: number }[]);
  selectedTooltip?: (school: SchoolWithRead) => string;
  deselectedTooltip?: (school: SchoolWithRead) => string;
  readOnlyTooltip?: (school: SchoolWithRead) => string;
}

const SchoolPicker: React.FC<ISchoolPickerProps> = ({
  onChange,
  value,
  selectedTooltip,
  deselectedTooltip,
  readOnlyTooltip,
}) => {
  // console.log(value);
  const { user } = useSelector((state: RootState) => state.currentUser);
  const [schools, setSchools] = useState<SchoolWithRead[]>([]);

  const bindSchools = () => {
    var _schools: SchoolWithRead[] = [user.baseSchool, ...user.schools];

    if (!arrays.isEmpty(_schools)) {
      setSchools(
        _schools.map(school =>
          value?.some(val => val.id == school.id)
            ? { ...school, selected: true }
            : school
        )
      );

      // if school is not present then user does not have permission to change. Push to _schools array but set to read-only
      value?.forEach(val => {
        if (_schools.every(school => school.id != val.id) && types.isType<SchoolWithRead>(val, "domain")) {
          setSchools([..._schools, { ...val, readOnly: true }]);
        }
      });
    }
  };

  useEffect(() => {
    bindSchools();
  }, [value]);

  const handleClick = (school: SchoolWithRead) => {
    var newSchools = schools.map(s =>
      s.id === school.id && !s.readOnly
        ? { ...s, selected: school.selected ? false : true }
        : s
    );

    setSchools(newSchools);
    onChange?.(newSchools);

    console.log("NEWSCHOOLS", newSchools);
  };

  const handleBulkSelection = (select: boolean) => {
    // Select all non-readonly schools
    var newSchools = schools.map(s =>
      !s.readOnly ? { ...s, selected: select } : s
    );

    setSchools(newSchools);
    onChange?.(newSchools);
  };

  return schools ? (
    <Wrapper>
      <SchoolItemsWrapper>
        {schools.map((school, index) => (
          <Tippy
            key={index}
            content={
              <Tooltip
                sub={
                  school.readOnly
                    ? readOnlyTooltip?.(school)
                    : school.selected
                    ? selectedTooltip?.(school)
                    : deselectedTooltip?.(school)
                }
              />
            }
          >
            <SchoolWrapper
              onClick={() => handleClick(school)}
              selected={school.selected}
            >
              <SchoolAvatar school={school} />
            </SchoolWrapper>
          </Tippy>
        ))}
      </SchoolItemsWrapper>
      <ButtonWrapper>
        <Tippy content={<Tooltip sub={"Select all schools"} />}>
          <span onClick={() => handleBulkSelection(true)}>Select All</span>
        </Tippy>
        {schools?.some(x => x.selected) && (
          <Tippy content={<Tooltip sub={"Deselect all schools"} />}>
            <span onClick={() => handleBulkSelection(false)}>Deselect All</span>
          </Tippy>
        )}
      </ButtonWrapper>
    </Wrapper>
  ) : null;
};

export default SchoolPicker;
