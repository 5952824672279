import React from "react";
import { Chip, Swatches } from "ui-kit";
import { CoverArrangement } from "areas/humanResources/types/leaveShared.types";


interface ICoverRequirementChipProps {
  coverRequirement: CoverArrangement;
}


const CoverRequirementChip: React.FC<ICoverRequirementChipProps> = ({ coverRequirement }) => {

  if (coverRequirement === null || coverRequirement == undefined) {
    return null;
  }

  var swatch = null;
  var name = "";

  switch (coverRequirement) {
    case CoverArrangement.None:
      swatch = Swatches.Low;
      name = "Not Required";
      break;
    case CoverArrangement.Required:
      swatch = Swatches.Warning;
      name = "Cover Required";
      break;
    case CoverArrangement.Arranged:
      swatch = Swatches.Purple;
      name = "Cover Arranged";
      break;
    default:
      swatch = Swatches.Low;
      name = "N/A";
      break;
  }

  return <Chip text={name} colorSwatch={swatch} />;
};

export default CoverRequirementChip;
