import React from "react";
import LayoutGenerator from "./layoutGenerator";
import { useSelector } from "react-redux";
import flyoutActions from "actions/ui/flyouts";
import classViewerActions from "areas/planner/actions/classViewer/classViewerActions";

const LayoutGeneratorWrapper = () => {
  const { classLayout, generateLayoutError } = useSelector(
    state => state.classLayout
  );

  const handleGenerateLayout = (layoutType, order, disperse) => {
    const callBack = () => {
      flyoutActions.closeFlyout();
    };

    classViewerActions.generateClassLayout(
      classLayout.classLayoutId,
      layoutType,
      order,
      disperse,
      callBack
    );
  };

  return (
    <LayoutGenerator
      generateLayout={handleGenerateLayout}
      error={generateLayoutError}
    />
  );
};

export default LayoutGeneratorWrapper;
