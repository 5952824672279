import React from "react";
import styled from "styled-components";
import {
  typescale,
  displayFont,
  neutral,
  fontStyle,
  Spacing,
  Icon,
  Size,
  honeygold,
} from "../index";

export interface ISubtitleProps {
  children?: React.ReactNode;
  className?: string;
  text?: string | JSX.Element;
  icon?: string;
  isExpanded?: boolean;
  onClickAccordionArrow?(): void;
}

const Wrapper = styled.h5`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    margin-bottom: ${Spacing.Default}px;
    font-size: 1rem;

    &:hover {
      cursor: pointer;
      color: ${honeygold};
    }
  }

  .subtitle {
    ${fontStyle(displayFont.medium, typescale.paragraph, neutral[500])}
    //text-transform: uppercase;
  letter-spacing: -0-05rem;
    b,
    em,
    strong {
      font-weight: ${displayFont.bold.weight};
    }
    margin-bottom: ${Spacing.Small}px;

    i,
    .icon {
      top: 2px;
      margin-right: ${Spacing.Small}px;
    }
  }
`;

const Subtitle: React.FC<ISubtitleProps> = ({
  children,
  className,
  text,
  icon,
  isExpanded,
  onClickAccordionArrow,
}) => {
  return (
    <Wrapper className={`subtitle-wrapper ${className ? className : ""}`}>
      <div className="subtitle">
        {icon ? <Icon value={icon} /> : null}
        {text}
        {children}
      </div>
      {onClickAccordionArrow && (
        <Icon
          value={`${isExpanded ? "angle-up" : "angle-down"}`}
          size={Size.Medium}
          onClick={onClickAccordionArrow}
        />
      )}
    </Wrapper>
  );
};

export default Subtitle;
