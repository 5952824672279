import React from "react";
import QuizFormWrapper from "./quizFormWrapper";
import StudentModal from "sharedComponents/common/users/studentModal";
import { ClassGroupStaffView } from "types/users/userGroups.types";
import { StudentListView } from "types/users/userListViews.types";


interface IQuizModalProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  student: StudentListView;
  setStudent: (student: StudentListView) => void;
  group: ClassGroupStaffView;
}


const QuizModal: React.FC<IQuizModalProps> = ({ openModal, setOpenModal, student, setStudent, group }) => {

  return (
    <StudentModal
      onClose={() => {
        setOpenModal(false);
      }}
      open={openModal}
      student={student}
      title="Quiz"
      width="65%"
      height="65%"
    >
      <QuizFormWrapper
        studentId={student.id}
        group={group}
        allowSaveAndPickNext
        setStudent={setStudent}
        handleCloseModal={() => setOpenModal(false)}
      />
    </StudentModal>
  );
};

export default QuizModal;
