import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { BeehiveReport, callbackType } from "types/common/action.types";
import { ManagedParentDetailView, ManagedStaffDetailView, ManagedStudentDetailView, ManagedUserDetailView } from "types/users/userListViews.types";
import { UserSessionListView } from "../types/userManagementResponse.types";
import { RoleListView } from "types/users/userRoles.types";
import { SetStaffBaseSchoolCommand, SetStaffRolesCommand, SetStaffSchoolCommand, UpdateSscStaffForStudentCommand, UpdateStaffDepartmentsCommand, UpdateStaffDetailsCommand, UpdateStudentDetailsCommand } from "../types/userManagementRequest.types";
import { ManagedStaffSchoolView } from "types/users/userSchools.types";
import { DepartmentListView } from "types/users/userDepartments.types";

const builder = new actionTypeBuilder("usermanagment");

const types = {
  USERMANAGEMENT_GETUSER: builder.build("USERMANAGEMENT_GETUSER"),
  USERMANAGEMENT_GETSESSIONS: builder.build("USERMANAGEMENT_GETSESSIONS"),
  USERMANAGEMENT_GETAVAILABLEROLESFORUSER: builder.build(
    "USERMANAGEMENT_GETAVAILABLEROLESFORUSER"
  ),
  USERMANAGEMENT_SETSTAFFSCHOOL: builder.build("USERMANAGEMENT_SETSTAFFSCHOOL"),
  USERMANAGEMENT_SETSTAFFBASESCHOOL: builder.build(
    "USERMANAGEMENT_SETSTAFFBASESCHOOL"
  ),
  USERMANAGEMENT_REMOVESTAFFSCHOOL: builder.build(
    "USERMANAGEMENT_REMOVESTAFFSCHOOL"
  ),
  USERMANAGEMENT_SETSTAFFEMPLOYMENTDETAILS: builder.build(
    "USERMANAGEMENT_SETSTAFFEMPLOYMENTDETAILS"
  ),
  USERMANAGEMENT_SETSTAFFDEPARTMENTS: builder.build(
    "USERMANAGEMENT_SETSTAFFDEPARTMENTS"
  ),
  USERMANAGEMENT_SETSTAFFROLES: builder.build("USERMANAGEMENT_SETSTAFFROLES"),
  USERMANAGEMENT_CLEARSESSIONS: builder.build("USERMANAGEMENT_CLEARSESSIONS"),
  USERMANAGEMENT_UNLOCKACCOUNT: builder.build("USERMANAGEMENT_UNLOCKACCOUNT"),
  USERMANAGEMENT_DEREGISTER: builder.build("USERMANAGEMENT_DEREGISTER"),
  USERMANAGEMENT_SENDREGISTRATIONLINK: builder.build(
    "USERMANAGEMENT_SENDREGISTRATIONLINK"
  ),
  USERMANAGEMENT_SENDRESETPASSWORDRESETLINK: builder.build(
    "USERMANAGEMENT_SENDRESETPASSWORDRESETLINK"
  ),
  USERMANAGEMENT_GETUSERREQUESTLOG: builder.build(
    "USERMANAGEMENT_GETUSERREQUESTLOG"
  ),
  USERMANAGEMENT_UPDATESTUDENTDETAILS: builder.build(
    "USERMANAGEMENT_UPDATESTUDENTDETAILS"
  ),
  USERMANAGEMENT_UPDATEASSIGNEDSTAFF: builder.build(
    "USERMANAGEMENT_UPDATEASSIGNEDSTAFF"
  ),
};


const getUser = (userId: string, callback?: callbackType<ManagedUserDetailView>) =>
  action<ManagedUserDetailView | ManagedParentDetailView | ManagedStudentDetailView>(
    () => client.get(`users/${userId}`),
    types.USERMANAGEMENT_GETUSER,
    callback
  );

const getSessions = (userId: string, callback?: callbackType<UserSessionListView[]>) =>
  action<UserSessionListView[]>(
    () => client.get(`authentication/users/${userId}/sessions`),
    types.USERMANAGEMENT_GETSESSIONS,
    callback
  );

const getUserRequestLog = (userId: string, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.get(`users/${userId}/requestlog`, { responseType: "text" }),
    types.USERMANAGEMENT_GETUSERREQUESTLOG,
    callback
  );

const getAvailableRolesForUser = (userId: string, callback?: callbackType<RoleListView[]>) =>
  action<RoleListView[]>(
    () => client.get(`users/${userId}/roles/available`),
    types.USERMANAGEMENT_GETAVAILABLEROLESFORUSER,
    callback
  );

const setStaffSchool = (userId: string, data: SetStaffSchoolCommand, callback?: callbackType<ManagedStaffSchoolView>) =>
  action<ManagedStaffSchoolView>(
    () => client.post(`users/${userId}/schools`, data),
    types.USERMANAGEMENT_SETSTAFFSCHOOL,
    callback
  );

const setStaffBaseSchool = (userId: string, data: SetStaffBaseSchoolCommand, callback?: callbackType<ManagedStaffSchoolView>) =>
  action<ManagedStaffSchoolView>(
    () => client.post(`users/${userId}/schools/base`, data),
    types.USERMANAGEMENT_SETSTAFFBASESCHOOL,
    callback
  );

const removeStaffSchool = (userId: string, schoolId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.delete(`users/${userId}/schools/${schoolId}`),
    types.USERMANAGEMENT_REMOVESTAFFSCHOOL,
    callback
  );

const setStaffEmploymentDetails = (userId: string, data: UpdateStaffDetailsCommand, callback?: callbackType<ManagedStaffDetailView>) =>
  action<ManagedStaffDetailView>(
    () => client.post(`users/staff/${userId}/details`, data),
    types.USERMANAGEMENT_SETSTAFFEMPLOYMENTDETAILS,
    callback
  );

const setStaffDepartments = (userId: string, data: UpdateStaffDepartmentsCommand, callback?: callbackType<DepartmentListView[]>) =>
  action<DepartmentListView[]>(
    () => client.post(`users/staff/${userId}/departments`, data),
    types.USERMANAGEMENT_SETSTAFFDEPARTMENTS,
    callback
  );

const setStaffRoles = (userId: string, data: SetStaffRolesCommand, callback?: callbackType<RoleListView[]>) =>
  action<RoleListView[]>(
    () => client.post(`users/${userId}/roles`, data),
    types.USERMANAGEMENT_SETSTAFFROLES,
    callback
  );

const clearSessions = (userId: string, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`authentication/users/${userId}/sessions/kill`),
    types.USERMANAGEMENT_CLEARSESSIONS,
    callback
  );

const unlockAccount = (userId: string, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`authentication/users/${userId}/unlock`),
    types.USERMANAGEMENT_UNLOCKACCOUNT,
    callback
  );

const deregister = (userId: string, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`users/registration/${userId}/deregister`),
    types.USERMANAGEMENT_DEREGISTER,
    callback
  );

const sendRegistrationLink = (userId: string, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`users/registration/${userId}/invite`),
    types.USERMANAGEMENT_SENDREGISTRATIONLINK,
    callback
  );

const sendResetPasswordLink = (userId: string, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`users/${userId}/password/reset`),
    types.USERMANAGEMENT_SENDRESETPASSWORDRESETLINK,
    callback
  );

const updateStudentDetails = (userId: string, data: UpdateStudentDetailsCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`users/${userId}/updateStudentDetails`, data),
    types.USERMANAGEMENT_UPDATESTUDENTDETAILS,
    callback
  );

const updateAssignedStaff = (userId: string, data: UpdateSscStaffForStudentCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`users/${userId}/updateSscStaff`, data),
    types.USERMANAGEMENT_UPDATEASSIGNEDSTAFF,
    callback
  );


const userManagementActions = {
  types,
  getUser,
  getSessions,
  getUserRequestLog,
  getAvailableRolesForUser,
  setStaffSchool,
  setStaffBaseSchool,
  removeStaffSchool,
  setStaffEmploymentDetails,
  setStaffDepartments,
  setStaffRoles,
  clearSessions,
  unlockAccount,
  deregister,
  sendRegistrationLink,
  sendResetPasswordLink,
  updateStudentDetails,
  updateAssignedStaff,
};

export default userManagementActions;
