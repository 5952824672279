import React from "react";
import { EmptyMessage, List } from "ui-kit";
import DetentionListItem from "./detentionListItem";
import { DetentionDetailView, DetentionStudentView } from "areas/behaviour/types/detentionRegisterResponse.types";


interface IDetentionListProps {
  detentionHistory: DetentionDetailView[];
  markPresent: (behaviourRewardId: number, detentionId: number) => void;
  markNotPresent: (behaviourRewardId: number, detentionId: number) => void;
  handleRescheduleDetention: (detention: DetentionDetailView) => void;
  handleClickDetention: (detention: DetentionDetailView) => void;
}


const DetentionList: React.FC<IDetentionListProps> = ({
  detentionHistory,
  markPresent,
  markNotPresent,
  handleRescheduleDetention,
  handleClickDetention
}) => {

  if (detentionHistory.length === 0) {
    return (
      <EmptyMessage
        icon="list"
        title="No detentions"
        summary="There are no detentions for the selected filters"
      />
    );
  }

  return (
    <List>
      {detentionHistory?.map(detention => (
        <DetentionListItem
          detention={detention}
          markPresent={markPresent}
          markNotPresent={markNotPresent}
          handleRescheduleDetention={handleRescheduleDetention}
          handleClickDetention={handleClickDetention}
        />
      ))}
    </List>
  );
};

export default DetentionList;
