import React from "react";
import { RadioList } from "ui-kit";
import { BehaviourIncludeType, OrderBy } from "areas/analytics/types/behaviourAnalyticsShared.types";


interface ISortByRadioListProps {
  behaviourTypesToInclude: BehaviourIncludeType;
  sortByValue: OrderBy;
  handleSortByChange: (value: OrderBy) => void;
}

const SortByRadioList: React.FC<ISortByRadioListProps> = ({
  behaviourTypesToInclude,
  sortByValue,
  handleSortByChange,
}) => {

  return behaviourTypesToInclude === BehaviourIncludeType.Reward ? (
    <RadioList
      value={sortByValue}
      onChange={value => handleSortByChange(value)}
    >
      <RadioList.Item
        label="Category Name"
        value={OrderBy.Name}
      />
      <RadioList.Item
        label="Reward Points"
        value={OrderBy.RewardPoints}
      />
    </RadioList>
  ) : behaviourTypesToInclude === BehaviourIncludeType.Behaviour ? (
    <RadioList
      value={sortByValue}
      onChange={value => handleSortByChange(value)}
    >
      <RadioList.Item
        label="Category Name"
        value={OrderBy.Name}
      />
      <RadioList.Item
        label="Behaviour Points"
        value={OrderBy.BehaviourPoints}
      />
       <RadioList.Item
        label="Behaviour Counts"
        value={OrderBy.BehaviourCounts}
      />
    </RadioList>
  ) : (
    behaviourTypesToInclude === BehaviourIncludeType.BehaviourReward && (
      <RadioList
        value={sortByValue}
        onChange={value => handleSortByChange(value)}
      >
        <RadioList.Item
          label="Category Name"
          value={OrderBy.Name}
        />
        <RadioList.Item
          label="Reward Points"
          value={OrderBy.RewardPoints}
        />
        <RadioList.Item
          label="Behaviour Points"
          value={OrderBy.BehaviourPoints}
        />
           <RadioList.Item
        label="Behaviour Counts"
        value={OrderBy.BehaviourCounts}
      />
      </RadioList>
    )
  );
};

export default SortByRadioList;
