import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { Button, Flyout, Modal, Right, Swatches } from "ui-kit";
import { Constants } from "configuration";
import {
  Tabs,
  TextInput,
  Spacing,
  EmptyMessage,
  Size,
  neutral,
  BorderRadius,
  fontStyle,
  displayFont,
  ActionBar,
  List,
  boxShadow,
  typescale,
} from "ui-kit";
import lodashDebounce from "lodash.debounce";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { arrays } from "utils";
import { Link } from "react-router-dom";
import flyoutActions from "actions/ui/flyouts";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Routes } from "configuration";
import searchEngineActions from "./actions/searchEngineActions";
import Avatar from "sharedComponents/common/users/avatar";
import SchoolAvatar from "sharedComponents/common/schools/schoolAvatar";
import { groupRoutes, studentRoutes } from "../planner/plannerRoutes";
import routes from "../../configuration/routes";
import { RootState } from "reducers/store";
import {
  PagedQueryView,
  SearchResultView,
  SearchResultViewBase,
} from "types/common/views.types";
import {
  StaffListView,
  StudentListView,
} from "types/users/userListViews.types";
import { PageInfo } from "types/common/paging.types";
import { TelephoneSearchView } from "./types/searchResponse.types";
import StudentSearchFiltersModal from "./studentSearchFiltersModal";
import { StudentQueryFilter } from "./types/searchRequest.types";

const SearchWrapper = styled.div`
  .tab-bar {
    margin-top: ${Spacing.ExtraLarge}px;
  }
`;

const SearchResultsWrapper = styled.div``;


const SearchFlyout = () => {

  const {
    loading: loadingGroups,
    error: groupsError,
    groups,
    paging: groupsPaging,
  } = useSelector((state: RootState) => state.searchClassGroups);
  const {
    loading: loadingTutorGroups,
    error: tutorGroupsError,
    groups: tutorGroups,
    paging: tutorGroupsPaging,
  } = useSelector((state: RootState) => state.searchTutorGroups);
  const {
    loading: loadingCustomGroups,
    error: customGroupsError,
    groups: customGroups,
    paging: customGroupsPaging,
  } = useSelector((state: RootState) => state.searchCustomGroups);
  const {
    loading: loadingStudents,
    error: studentsError,
    students,
    paging: studentsPaging,
  } = useSelector((state: RootState) => state.searchStudents);
  const {
    loading: loadingPhonebook,
    error: phonebookError,
    phonebook,
    paging: phonebookPaging,
  } = useSelector((state: RootState) => state.searchPhonebook);

  const filterDefaults: StudentQueryFilter = {
    schools: [],
    eal: false,
    pupilPremium: false,
    bursary: false,
    freeSchoolMeals: false,
    sen: false,
    abilityStatus: null,
    leaver: false
  }

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<StudentQueryFilter>(filterDefaults);

  const getStudents = (searchTerm: string, pageIndex: number, filter: StudentQueryFilter) => {
    searchEngineActions.searchStudents(searchTerm, filter, pageIndex);
  };

  const getGroups = (searchTerm: string, pageIndex: number) => {
    searchEngineActions.searchClassGroups(
      searchTerm,
      null,
      null,
      null,
      null,
      pageIndex
    );
  };
  const getTutorGroups = (searchTerm: string, pageIndex: number) => {
    searchEngineActions.searchTutorGroups(searchTerm, null);
  };
  const getCustomGroups = (searchTerm: string, pageIndex: number) => {
    searchEngineActions.searchCustomGroups(
      searchTerm,
      null,
      null,
      null,
      null,
      pageIndex
    );
  };
  const getPhonebook = (searchTerm: string, pageIndex: number) => {
    searchEngineActions.searchPhonebook(searchTerm, null, pageIndex);
  };

  const debouncedSearchChange = useCallback(
    lodashDebounce((value: string, index: number, queryFilter: StudentQueryFilter, callback?: () => void) => {
      switch (index) {
        case 0:
          getStudents(value, 0, queryFilter);
          break;
        case 1:
          searchEngineActions.searchClassGroups(value, null, null, null, null, 0);
          break;
        case 2:
          searchEngineActions.searchTutorGroups(value);
          break;
        case 3:
          searchEngineActions.searchCustomGroups(value, null, null, null, null, 0);
          break;
        case 4:
          searchEngineActions.searchPhonebook(value, null, 0);
          break;
        default:
          break;
      }
    }, 500),
    []
  );

  const handleTabSelected = (name: string, index: number) => {
    setSelectedIndex(index);
  };

  const search = () => {
    if (searchTerm.length >= 2) {
      debouncedSearchChange(searchTerm, selectedIndex, filter);
    }
  };

  useEffect(() => {
    search();
  }, [searchTerm]);

  useEffect(() => {
    search();
  }, [selectedIndex]);

  const handleSearchTermChange = (value: string) => {
    setSearchTerm(value);
  };

  const getPlaceholder = () => {
    switch (selectedIndex) {
      case 0:
        return "Search Students...";
      case 1:
        return "Search Class Groups...";
      case 2:
        return "Search Tutor Groups...";
      case 3:
        return "Search Custom Groups...";
      case 4:
        return "Search Staff Phonebook...";
      default:
        return "";
    }
  };

  const handleStudentsPage = () => {
    getStudents(searchTerm, studentsPaging?.pageIndex + 1, filter);
  };

  const handleGroupsPage = () => {
    getGroups(searchTerm, groupsPaging?.pageIndex + 1);
  };

  const handleTutorGroupsPage = () => {
    getTutorGroups(searchTerm, tutorGroupsPaging?.pageIndex + 1);
  };

  const handleCustomGroupsPage = () => {
    getCustomGroups(searchTerm, customGroupsPaging?.pageIndex + 1);
  };

  const handlePhonebookPage = () => {
    getPhonebook(searchTerm, phonebookPaging?.pageIndex + 1);
  };

  const handleResultClick = () => {
    flyoutActions.closeFlyout();
  };

  const handleFilterModalClear = () => {
    setFilter(filterDefaults);
    setFilterModalOpen(false);
  }

  const handleFilterModalClose = () => {
    setFilterModalOpen(false);
  }

  const handleFilterModalApply = (newFilter: StudentQueryFilter) => {
    if (searchTerm.length > 2) {
      searchEngineActions.searchStudents(searchTerm, newFilter, 0);
    }
    setFilter(newFilter);
    setFilterModalOpen(false);
  }

  return (
    <>
    <Flyout title="Search" name={Constants.FLYOUTS.SEARCH} wide>
      <Flyout.Body>
        <SearchWrapper>
          <ActionBar>
            <TextInput
              value={searchTerm}
              fluid
              placeholder={getPlaceholder()}
              onChange={handleSearchTermChange}
              loading={loadingGroups || loadingStudents || loadingPhonebook}
            />
            { selectedIndex === 0 && (
              <Button
                icon={"filter"}
                text="Filter"
                onClick={() => setFilterModalOpen(true)}
                color={Swatches.Primary}
                size={Size.Medium}
              />
            )}
          </ActionBar>
          <Tabs selectedIndex={selectedIndex} onTabSelected={handleTabSelected}>
            <Tabs.Pane
              name="students"
              label="Students"
              tooltip="Search for a student"
            >
              <StudentResults
                loading={loadingStudents}
                results={students}
                paging={studentsPaging}
                error={studentsError != null}
                onClick={handleResultClick}
                onPage={handleStudentsPage}
              />
            </Tabs.Pane>
            <Tabs.Pane
              name="classes"
              label="Class Groups"
              tooltip="Search for a class group"
            >
              <ClassGroupResults
                loading={loadingGroups}
                results={groups}
                error={groupsError != null}
                paging={groupsPaging}
                onClick={handleResultClick}
                onPage={handleGroupsPage}
              />
            </Tabs.Pane>
            <Tabs.Pane
              name="classes"
              label="Tutor Groups"
              tooltip="Search for a tutor group"
            >
              <TutorGroupResults
                loading={loadingTutorGroups}
                results={tutorGroups}
                error={tutorGroupsError != null}
                paging={tutorGroupsPaging}
                onClick={handleResultClick}
                onPage={handleTutorGroupsPage}
              />
            </Tabs.Pane>
            <Tabs.Pane
              name="customgroups"
              label="Custom Groups"
              tooltip="Search for a custom group"
            >
              <CustomGroupResults
                loading={loadingCustomGroups}
                results={customGroups}
                error={customGroupsError != null}
                paging={customGroupsPaging}
                onClick={handleResultClick}
                onPage={handleCustomGroupsPage}
              />
            </Tabs.Pane>
            <Tabs.Pane
              name="phonebook"
              label="Staff Phonebook"
              tooltip="Search for an internal phone number"
            >
              <PhonebookResults
                loading={loadingPhonebook}
                results={phonebook}
                paging={phonebookPaging}
                error={phonebookError != null}
                onPage={handlePhonebookPage}
              />
            </Tabs.Pane>
          </Tabs>
        </SearchWrapper>
      </Flyout.Body>
    </Flyout>
    <StudentSearchFiltersModal
      open={filterModalOpen}
      onClear={handleFilterModalClear}
      onClose={handleFilterModalClose}
      onApply={handleFilterModalApply}
    />
    </>
  );
};

const ResultsWrapper = styled.ul``;

const ResultItem = styled.li`
  padding: ${Spacing.Medium}px;
  margin-top: ${Spacing.Small}px;
  margin-bottom: ${Spacing.Default}px !important;
  border-radius: ${BorderRadius.Default}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${neutral[100]};
  box-shadow: ${boxShadow};

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }

  ${({ onClick }) =>
    onClick &&
    `
    &:hover { cursor: pointer; 
      background: ${neutral[600]}; 
      .label, .sub {
        color: ${neutral[100]};
      }
    
    }`}

  .phone-number-result {
    ${fontStyle(displayFont.medium, typescale.header4, neutral[600])}
  }
`;

interface IStudentResultsProps {
  loading?: boolean;
  error?: boolean;
  results: SearchResultView<StudentListView>[];
  paging?: PageInfo;
  onClick?: () => void;
  onPage?: () => void;
}

const StudentResults: React.FC<IStudentResultsProps> = ({
  loading,
  error,
  results,
  paging,
  onClick,
  onPage,
}) => {
  const handleClick = () => {
    onClick?.();
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: paging?.pageNumber < paging?.totalPages,
    onLoadMore: onPage,
  });

  if (loading) {
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem searching the students"
        icon="times-circle"
      />
    );
  }

  if (!results) {
    return null;
  }

  if (arrays.isEmpty(results)) {
    return (
      <>
        <EmptyMessage
          title="No results"
          summary="No students were found"
          icon="users"
        />
      </>
    );
  }

  return (
    <>
      <ResultsWrapper>
        <List>
          {results.map(
            (result: SearchResultView<StudentListView>, index: number) => (
              <Link to={`${studentRoutes.getStudentPath(result.id)}`}>
                <ResultItem key={index} onClick={handleClick}>
                  <Avatar
                    size={Size.Medium}
                    user={result.data}
                    sub={`${result.school.name} | ${result.data.simsId} | ${result.data.tutorGroupName}`}
                  />
                </ResultItem>
              </Link>
            )
          )}
        </List>
      </ResultsWrapper>
      {paging?.pageNumber < paging?.totalPages && <div ref={sentryRef}></div>}
    </>
  );
};

interface IClassGroupResultsProps {
  loading?: boolean;
  error?: boolean;
  results: SearchResultViewBase[];
  paging?: PageInfo;
  onClick?: () => void;
  onPage?: () => void;
}

const ClassGroupResults: React.FC<IClassGroupResultsProps> = ({
  loading,
  error,
  results,
  paging,
  onClick,
  onPage,
}) => {
  const handleClick = () => {
    // history.push(); <---- HENA
    onClick?.();
  };
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: paging?.pageNumber < paging?.totalPages,
    onLoadMore: onPage,
  });

  if (loading) {
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem searching the class groups"
        icon="times-circle"
      />
    );
  }

  if (!results) {
    return null;
  }

  if (arrays.isEmpty(results)) {
    return (
      <EmptyMessage
        title="No results"
        summary="No class groups were found"
        icon="users"
      />
    );
  }

  return (
    <>
      <ResultsWrapper>
        {results.map((result: SearchResultViewBase, index: number) => (
          <Link
            to={`${groupRoutes.getGroupPath(
              result.id,
              Constants.GROUP_TYPES.CLASS
            )}`}
          >
            <ResultItem key={index} onClick={handleClick}>
              <SchoolAvatar
                size={Size.Medium}
                school={result.school}
                label={result.title}
                sub={`${result.description}`}
              />
            </ResultItem>
          </Link>
        ))}
      </ResultsWrapper>
      {paging?.pageNumber < paging?.totalPages && <div ref={sentryRef}></div>}
    </>
  );
};

interface ITutorGroupResultsProps {
  loading?: boolean;
  error?: boolean;
  results: SearchResultViewBase[];
  paging?: PageInfo;
  onClick?: () => void;
  onPage?: () => void;
}

const TutorGroupResults: React.FC<ITutorGroupResultsProps> = ({
  loading,
  error,
  results,
  paging,
  onClick,
  onPage,
}) => {
  // const history = useHistory();

  const handleClick = () => {
    // history.push(); <---- HENA
    onClick?.();
  };
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: paging?.pageNumber < paging?.totalPages,
    onLoadMore: onPage,
  });

  if (loading) {
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem searching the tutor groups"
        icon="times-circle"
      />
    );
  }

  if (!results) {
    return null;
  }

  if (arrays.isEmpty(results)) {
    return (
      <EmptyMessage
        title="No results"
        summary="No tutor groups were found"
        icon="users"
      />
    );
  }

  return (
    <>
      <ResultsWrapper>
        {results.map((result: SearchResultViewBase, index: number) => (
          <Link
            to={`${groupRoutes.getGroupPath(
              result.id,
              Constants.GROUP_TYPES.TUTOR
            )}`}
          >
            <ResultItem key={index} onClick={handleClick}>
              <SchoolAvatar
                size={Size.Medium}
                school={result.school}
                label={result.title}
                sub={`${result.description}`}
              />
            </ResultItem>
          </Link>
        ))}
      </ResultsWrapper>
      {paging?.pageNumber < paging?.totalPages && <div ref={sentryRef}></div>}
    </>
  );
};

interface ICustomGroupResultsProps {
  loading?: boolean;
  error?: boolean;
  results: SearchResultViewBase[];
  paging?: PageInfo;
  onClick: () => void;
  onPage: () => void;
}

const CustomGroupResults: React.FC<ICustomGroupResultsProps> = ({
  loading,
  error,
  results,
  paging,
  onClick,
  onPage,
}) => {
  // const history = useHistory();

  const handleClick = () => {
    // history.push(); <---- HENA
    onClick?.();
  };
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: paging?.pageNumber < paging?.totalPages,
    onLoadMore: onPage,
  });

  if (loading) {
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem searching the tutor groups"
        icon="times-circle"
      />
    );
  }

  if (!results) {
    return null;
  }

  if (arrays.isEmpty(results)) {
    return (
      <>
        <EmptyMessage
          title="No results"
          summary="No custom groups were found"
          icon="users"
        />
      </>
    );
  }

  return (
    <>
      <ResultsWrapper>
        {results.map((result: SearchResultViewBase, index: number) => (
          <Link
            to={`${groupRoutes.getGroupPath(
              result.id,
              Constants.GROUP_TYPES.CUSTOM
            )}`}
          >
            <ResultItem key={index} onClick={handleClick}>
              <SchoolAvatar
                size={Size.Medium}
                school={result.school}
                label={result.title}
                sub={`${result.description}`}
              />
            </ResultItem>
          </Link>
        ))}
      </ResultsWrapper>
      {paging?.pageNumber < paging?.totalPages && <div ref={sentryRef}></div>}
    </>
  );
};

interface IPhonebookResultsProps {
  loading?: boolean;
  error?: boolean;
  results: TelephoneSearchView[];
  paging?: PageInfo;
  onPage?: () => void;
}

const PhonebookResults: React.FC<IPhonebookResultsProps> = ({
  loading,
  error,
  results,
  paging,
  onPage,
}) => {
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: paging?.pageNumber < paging?.totalPages,
    onLoadMore: onPage,
  });

  if (loading) {
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem searching the class groups"
        icon="times-circle"
      />
    );
  }

  if (!results) {
    return null;
  }

  if (arrays.isEmpty(results)) {
    return (
      <>
        <EmptyMessage
          title="No results"
          summary="No staff were found"
          icon="users"
        />
      </>
    );
  }

  const getSub = (result: TelephoneSearchView) => {
    if (result.user == null) {
      return result.extensionDetail;
    }

    return `${result.extensionDetail} ${
      result.user.email ? " | " + result.user.email.toLowerCase() : ""
    } ${result.user.jobTitle ? " | " + result.user.jobTitle : ""}`;
  };

  return (
    <>
      <ResultsWrapper>
        {results.map((result: TelephoneSearchView, index: number) => (
          <ResultItem key={index}>
            <SchoolAvatar
              size={Size.Medium}
              school={result.school}
              label={result.extensionName}
              sub={`${getSub(result)}`}
            />
            <span className="phone-number-result">{result.number}</span>
          </ResultItem>
        ))}
      </ResultsWrapper>
      {paging?.pageNumber < paging?.totalPages && <div ref={sentryRef}></div>}{" "}
    </>
  );
};

interface IStaffResultsProps {
  loading?: boolean;
  error?: string;
  results: PagedQueryView<SearchResultView<StaffListView>>;
  paging?: PageInfo;
  onPage?: () => void;
}

const StaffResults: React.FC<IStaffResultsProps> = ({
  loading,
  error,
  results,
  paging,
  onPage,
}) => {
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: paging?.pageNumber < paging?.totalPages,
    onLoadMore: onPage,
  });

  if (loading) {
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem searching staff"
        icon="times-circle"
      />
    );
  }

  if (!results) {
    return null;
  }

  if (arrays.isEmpty(results)) {
    return (
      <>
        <EmptyMessage
          title="No results"
          summary="No staff were found"
          icon="users"
        />
      </>
    );
  }

  const getSub = (result: SearchResultView<StaffListView>) => {
    return `${result.data.email} ${
      result.data.jobTitle ? " | " + result.data.jobTitle : ""
    }`;
  };

  const handleClick = () => {};

  return (
    <>
      <ResultsWrapper>
        {results?.items?.map(
          (result: SearchResultView<StaffListView>, index: number) => (
            <a href={`mailto:${result.data.email}`} key={index}>
              <ResultItem>
                <SchoolAvatar
                  size={Size.Medium}
                  school={result.school}
                  label={result.title}
                  sub={`${getSub(result)}`}
                />
                {/* <span className="phone-number-result">{result.data.simsId}</span> */}
              </ResultItem>
            </a>
          )
        )}
      </ResultsWrapper>
      {paging?.pageNumber < paging?.totalPages && <div ref={sentryRef}></div>}{" "}
    </>
  );
};

export default SearchFlyout;
