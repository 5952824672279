import React, { useState, useEffect } from "react";
import {
  Message,
  Swatches,
  StructuredList,
  TextInput,
  TextInputType,
  Flyout,
  Card,
  ActionBar,
  Button,
} from "ui-kit";
import { arrays } from "utils";
import { Constants } from "configuration";
import flyoutActions from "../../../actions/ui/flyouts";
import { LinkListView } from "types/common/links.types";


interface ILinkFlyoutProps {
  link: LinkListView;
  onSaved: (link: LinkListView | null) => void; 
  onCancel?: () => void;
}

const defaultLink: LinkListView = {
  id: null,
  url: "",
  title: "",
  description: ""
}

const LinkFlyout: React.FC<ILinkFlyoutProps> = ({ link, onSaved, onCancel }) => {

  const [linkValidationErrors, setLinkValidationErrors] = useState<string[]>([]);
  const [_link, _setLink] = useState<LinkListView>(defaultLink);

  useEffect(() => {
    link ? _setLink(link) : _setLink(defaultLink);
  }, [link]);

  const handleLinkSave = () => {
    var errors = [];

    if (!link || !_link?.url) {
      errors.push(`A URL is required.`);
    }

    setLinkValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    onSaved?.(_link);

    // if (selectedLinkIndex === null) {
    //   _setAssignment({
    //     ..._assignment,
    //     links: _assignment.links
    //       ? [..._assignment.links, selectedLink]
    //       : [selectedLink]
    //   });
    // } else {
    //   _setAssignment({
    //     ..._assignment,
    //     links: [
    //       ..._assignment.links.map((x, index) =>
    //         index == selectedLinkIndex ? selectedLink : x
    //       )
    //     ]
    //   });
    // }

    flyoutActions.closeFlyout();
  };

  const handleLinkCancel = () => {
    onCancel?.();
    flyoutActions.closeFlyout();
  };

  return (
    <Flyout title="Links" name={Constants.FLYOUTS.LINK_EDITOR} wide>
      <Flyout.Body>
        {!arrays.isEmpty(linkValidationErrors) && (
          <Message
            text={linkValidationErrors.map(err => (
              <>
                {err}
                <br />
              </>
            ))}
            color={Swatches.Danger}
          />
        )}
        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="URL">
                <TextInput
                  fluid
                  value={_link?.url}
                  onChange={value => _setLink({ ..._link, url: value })}
                />
              </StructuredList.Item>
              <StructuredList.Item name="Title">
                <TextInput
                  fluid
                  value={_link?.title}
                  onChange={value => _setLink({ ..._link, title: value })}
                />
              </StructuredList.Item>
              <StructuredList.Item name="Description">
                <TextInput
                  fluid
                  type={TextInputType.Textarea}
                  value={_link?.description}
                  onChange={value => _setLink({ ..._link, description: value })}
                />
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      </Flyout.Body>
      <Flyout.Footer>
        <ActionBar low>
          <Button
            text="Save"
            onClick={handleLinkSave}
            color={Swatches.Success}
            fluid
          />
          <Button
            text="Cancel"
            onClick={handleLinkCancel}
            color={Swatches.Low}
            fluid
          />
        </ActionBar>
      </Flyout.Footer>
    </Flyout>
  );
};

export default LinkFlyout;
