import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Spacing,
  formatScrollbars,
  neutral,
  Left,
  Right,
  Card,
  ActionBar,
  Button,
  Swatches,
  Size,
  TextInput,
  Loader,
  Subtitle,
  ToastService,
} from "ui-kit";
import PreadmissionSearch from "./preadmissionSearch";
import matriculationActions from "../actions/matriculationActions";
import { matriculationRoutes } from "../matriculationRoutes";
import Photobooth from "sharedComponents/common/camera/photobooth";
import { RootState } from "reducers/store";
import { PreadmissionListView } from "../types/matriculationResponses.types";
import { UploadPreadmissionPhotographCommand } from "../types/matriculationRequests.types";


const CameraWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;

  .left {
    padding-right: ${Spacing.Default}px;
    flex-direction: column;
    width: 0px;
    flex-grow: 1;
    left: 0;
    overflow-y: clip;
    width: 200px;
    max-width: 200px;
    margin-right: ${Spacing.Default}px;

    .card-body {
      padding: ${Spacing.Small}px ${Spacing.Medium}px ${Spacing.Small}px
        ${Spacing.Small}px;
    }

    .textInput {
      margin: ${Spacing.Small}px ${Spacing.Small}px;
    }

    .action-bar {
      flex-direction: column;

      .button {
        margin: ${Spacing.Small}px ${Spacing.Default}px;
      }
    }

    .subtitle {
      margin: ${Spacing.Default}px 0 0 ${Spacing.Small}px;
    }
  }

  .right {
    flex-grow: 1;
  }

  ${formatScrollbars(neutral[200])}
`;


const MatriculationCamera = () => {

  const navigate = useNavigate();
  const { schoolId, sessionId, studentId } = useParams();
  const { preadmission, loading, error } = useSelector((state: RootState) => state.matriculationSessions.selectedPreadmission);
  const { user } = useSelector((state: RootState) => state.currentUser);
  const [_preadmissionDetails, _setPreadmissionDetails] = useState<PreadmissionListView | null>(null);
  const [photo, _setPhoto] = useState<string | null>(null);

  useEffect(() => {
    matriculationActions.getPreadmissionStudent(parseInt(schoolId), parseInt(sessionId), studentId);
  }, [schoolId, sessionId, studentId]);

  useEffect(() => {
    preadmission && _setPreadmissionDetails(preadmission);
  }, [preadmission]);

  const handleUploadPhoto = () => {
    if (!user || !_preadmissionDetails || !photo) {
      return;
    }
    const data: UploadPreadmissionPhotographCommand = {
      admissionNumber: _preadmissionDetails.admissionNumber,
      firstName: _preadmissionDetails.firstName,
      imageData: photo,
      lastName: _preadmissionDetails.lastName,
      photographedById: user.id,
      preadmissionId: _preadmissionDetails.id,
      schoolId: parseInt(schoolId),
      sessionId: parseInt(sessionId),
    };
    matriculationActions.uploadPhotograph(parseInt(schoolId), parseInt(sessionId), data, () => {
      ToastService.pop("Uploaded photo", null, "check-circle");
      var link = document.createElement("a");
      link.download = _preadmissionDetails.admissionNumber;
      link.href = photo;
      link.click();
    });
  };

  const handleTakePhoto = (b64Image: string) => {
    _setPhoto(b64Image);
  };

  const onStudentChange = (preadmission: PreadmissionListView) => {
    _setPreadmissionDetails(preadmission);
    navigate(
      matriculationRoutes.getPreadmissionCameraPath(
        parseInt(schoolId),
        parseInt(sessionId),
        preadmission.id
      )
    );
  };

  const handleReset = () => {
    preadmission && _setPreadmissionDetails(preadmission);
  }

  return (
    <>
      <CameraWrapper>
        <Left>
          <PreadmissionSearch
            schoolId={parseInt(schoolId)}
            sessionId={parseInt(sessionId)}
            onChange={onStudentChange}
          />
          <PreadmissionsDetails
            details={_preadmissionDetails}
            onReset={handleReset}
            setDetails={_setPreadmissionDetails}
            onUpload={handleUploadPhoto}
          />
        </Left>
        <Right>
          <Photobooth
            onCapture={handleTakePhoto}
            autoPlay
            showPreview
            showMargins
            showGuides
          />
        </Right>
      </CameraWrapper>
    </>
  );
};


interface IPreadmissionsDetailsProps {
  details: PreadmissionListView;
  setDetails: React.Dispatch<React.SetStateAction<PreadmissionListView>>;
  onReset: () => void;
  onUpload: () => void;
}

const PreadmissionsDetails: React.FC<IPreadmissionsDetailsProps> = ({ 
  details, 
  setDetails, 
  onReset,
  onUpload 
}) => {

  if (!details) {
    return <Loader size={Size.Large} cover />;
  }

  return (
    <Card>
      <Card.Body>
        <Subtitle text={"First Name"} />
        <TextInput
          value={details.firstName}
          onChange={value => setDetails({ ...details, firstName: value })}
          fluid
        />
        <Subtitle text={"Last Name"} />
        <TextInput
          value={details.lastName}
          onChange={value => setDetails({ ...details, lastName: value })}
          fluid
        />
        <Subtitle text={"Admission Number"} />
        <TextInput
          value={details.admissionNumber}
          onChange={value =>
            setDetails({ ...details, admissionNumber: value })
          }
          fluid
        />
      </Card.Body>
      <Card.Footer>
        <ActionBar low>
          <Button
            text="Reset Form"
            color={Swatches.Low}
            size={Size.Small}
            onClick={onReset}
          />
          <Button
            text="Upload Photo"
            color={Swatches.Success}
            size={Size.Small}
            onClick={onUpload}
          />
        </ActionBar>
      </Card.Footer>
    </Card>
  );
};

export default MatriculationCamera;
