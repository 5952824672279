import React, { useEffect, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Title,
  TitleSize,
  StructuredList,
  EmptyMessage,
  Loader,
  Size,
  ActionBar,
  Checkbox,
  ToastService,
  Card,
  Button,
  Swatches,
  Message,
} from "ui-kit";
import Subheader from "sharedComponents/layout/header/subheader";
import { StaffView } from "sharedComponents/common";
import { users } from "utils";
import profileActions from "areas/administration/actions/profileActions";
import { RootState } from "reducers/store";
import {
  ManagedUserDetailView,
  ManagedParentDetailView,
  ManagedStudentDetailView,
} from "types/users/userListViews.types";
import { UpdateUserNotificationPreferencesCommand } from "areas/administration/types/profileRequest.types";
import { UserNotificationPreferences } from "areas/administration/types/profileResponses.types";

const NotificationPreferences = () => {
  const { userId } = useParams();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const {
    profile,
    loading,
    error,
    settingNotificationPreferences,
    notificationPreferencesError,
  } = useSelector((state: RootState) => state.profile);
  const [_profile, _setProfile] = useState<UserNotificationPreferences>();

  useEffect(() => {
    if (userId) {
      profileActions.getProfile(userId);
    } else {
      user && profileActions.getProfile(user.id);
    }
  }, [user, userId]);

  useEffect(() => {
    profile &&
      _setProfile({
        ...profile.notificationPreferences,
      });
  }, [profile]);

  const handleSave = () => {
    var data: UpdateUserNotificationPreferencesCommand = {
      userId: user.id,
      notificationPreferences: _profile,
    };
    profileActions.setNotificationPreferences(user.id, data, () => {
      ToastService.pop("Notification Preferences Saved", null, "paper-plane");
    });
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        cover
        icon="user"
        title="An error occurred"
        summary="Unfortunately, there was a problem while loading your profile information"
      />
    );
  }

  if (!profile) {
    return (
      <EmptyMessage
        icon="user"
        title="Profile Not Found"
        summary="Unfortunately, your profile information could not be loaded"
        cover
      />
    );
  }

  return (
    <>
      <Subheader>
        <Title
          size={TitleSize.H2}
          text="Your Account"
          sub="Notification Preferences"
        />
      </Subheader>

      {notificationPreferencesError && (
        <Message
          text={"There was a problem setting your notification preferences."}
          color={Swatches.Danger}
        />
      )}

      <Card>
        <Card.Body>
          <p>
            On this page, you can choose which optional notifications you
            receive by email. To update which notifications you receive, simply
            tick the notifications you want to receive and click{" "}
            <b>'Set Notification Preferences'</b> below.
          </p>
          <StructuredList>
            <StructuredList.Item
              name="Low Balance Notifications"
              description="This is sent when a smartcard balance drops below the amount needed to buy a hot meal."
            >
              <Checkbox
                checked={_profile?.lowBalanceNotifications}
                text={`Send Low Balance Notifications`}
                onChange={value => {
                  _setProfile({
                    ..._profile,
                    lowBalanceNotifications: value.checked,
                  });
                }}
              />
            </StructuredList.Item>
            <StaffView>
              <StructuredList.Item
                name="Managed Product Notifications"
                description="This is sent when a student purchases a product that is in your 'Managed Products' list in your store."
              >
                <Checkbox
                  checked={_profile?.productNotifications}
                  text={`Send Managed Product Notifications`}
                  onChange={value => {
                    _setProfile({
                      ..._profile,
                      productNotifications: value.checked,
                    });
                  }}
                />
              </StructuredList.Item>
            </StaffView>
            {user && users.isPrimaryParent(user) && (
              <StructuredList.Item
                name="Low Lunch Credit Notifications (Primary Schools only)"
                description="This is sent when your child's school lunch credit balance drops below 3. Please note that if your balance is negative then you will still receive notifications."
              >
                <Checkbox
                  checked={_profile?.lowLunchCreditNotifications}
                  text={`Send Low Lunch Credit Notifications`}
                  onChange={value => {
                    _setProfile({
                      ..._profile,
                      lowLunchCreditNotifications: value.checked,
                    });
                  }}
                />
              </StructuredList.Item>
            )}
          </StructuredList>
        </Card.Body>
        <Card.Footer>
          <ActionBar low>
            <Button
              text="Set Notification Preferences"
              onClick={handleSave}
              color={Swatches.Primary}
              working={settingNotificationPreferences}
              size={Size.Small}
            />
          </ActionBar>
        </Card.Footer>
      </Card>
    </>
  );
};

export default NotificationPreferences;
