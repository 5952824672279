import React from "react";
import styled from "styled-components";
import { formatScrollbars, neutral, Spacing, List } from "ui-kit";
import { AnalyticReport } from ".";


const Wrapper = styled.div`
  display: flex;
  padding-right: ${Spacing.Default}px;
  flex-direction: column;
  position: absolute;
  min-height: 100%;
  max-height: 100%;
  left: 0;
  overflow-y: scroll;
  width: 13rem;
  min-width: 13rerem;

  ${formatScrollbars(neutral[200])}
`;


interface IReportListProps {
  reports: AnalyticReport[], 
  selectedReport?: AnalyticReport, 
  handleSelectReport?: (report: AnalyticReport) => void
}


const ReportList: React.FC<IReportListProps> = ({ reports, selectedReport, handleSelectReport }) => {
  return (
    <Wrapper>
      <List title="Available Reports">
        {reports
          .filter(x => !x.hide)
          .sort((a, b) => a.text.localeCompare(b.text))
          .map((report, index) => (
            <List.Item
              text={report.text}
              //sub={report.name}
              key={index}
              selected={selectedReport?.key === report.key}
              onClick={() => handleSelectReport(report)}
            />
          ))}
      </List>
    </Wrapper>
  );
};

export default ReportList;
