import React from "react";
import { Button, EmptyMessage, Loader, Size, Swatches, Table } from "ui-kit";
import { PageInfo } from "types/common/paging.types";
import { EventTotalSignupsReportListView } from "areas/calendar/types/calendarResponses.types";
import { SchoolIcon } from "sharedComponents/common";
import constants from "configuration/constants";
import { arrays } from "utils";
import { useNavigate } from "react-router";
import { studentRoutes } from "areas/planner/plannerRoutes";


interface ITotalSignupsReportDisplayProps {
  records: EventTotalSignupsReportListView[];
  onPage?: () => void;
  paging?: PageInfo;
}


const TotalSignupsReportDisplay: React.FC<ITotalSignupsReportDisplayProps> = ({ records, onPage, paging }) => {

  const navigate = useNavigate();
  
  const handlePage = () => {
    onPage?.();
  }

  const getSchoolCode = (schoolId: number) => {
    var school = constants.SCHOOLS.find(x => x.id === schoolId);
    return school?.code;
  }

  const goToStudentProfile = (studentId: string) => {
    navigate(studentRoutes.getStudentPath(studentId));
  };

  if (arrays.isEmpty(records)) {
    return (
      <>
        <EmptyMessage
          title="No Records"
          summary="No Records With This Criteria Have Been Found"
          icon="clipboard-check"
          cover
        ></EmptyMessage>
      </>
    );
  }

  return (
    <Table
    grow
    zebra
    >
      <Table.Header>
        <Table.HeaderCell width={0.75}></Table.HeaderCell>
        <Table.HeaderCell>Student</Table.HeaderCell>
        <Table.HeaderCell>SIMS Id</Table.HeaderCell>
        <Table.HeaderCell>Tutor Group</Table.HeaderCell>
        <Table.HeaderCell>Total Hours</Table.HeaderCell>
        <Table.HeaderCell right></Table.HeaderCell>
      </Table.Header>
      <Table.Body onPage={handlePage} paging={paging}>
        {records?.map((record: EventTotalSignupsReportListView, index: number) => (
          <Table.Row key={index}>
            <Table.Cell width={0.75}>
              <SchoolIcon 
                code={getSchoolCode(record.schoolId)} 
                size={Size.Small} 
              />
            </Table.Cell>
            <Table.Cell>
              {`${record.firstName} ${record.lastName}`}
            </Table.Cell>
            <Table.Cell>
              {record.simsId}
            </Table.Cell>
            <Table.Cell>
              {record.tutorGroup}
            </Table.Cell>
            <Table.Cell>
              {record.totalHours}
            </Table.Cell>
            <Table.Cell right>
              <Button
                text="Student Profile"
                onClick={() => goToStudentProfile(record.studentId)}
                color={Swatches.Primary}
                size={Size.Small}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default TotalSignupsReportDisplay;
