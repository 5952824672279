import React, { useRef } from "react";
import styled from "styled-components";
import {
  Size,
  Icon,
  neutral,
  honeygold,
  Spacing,
  Tooltip,
  Speed,
} from "../index";
import { IIconProps } from "./icon";
import Badge from "./badge";
import Tippy from "@tippyjs/react";
import { Swatch } from "../common/colors";

const Wrapper = styled.span<{
  background?: string;
  size: number;
  color?: any;
  hasOnClick?: boolean;
  hover?: boolean;
  isOutline?: boolean;
}>`
  position: relative;
  background: ${({ background }) => (background ? background : neutral[300])};
  border-radius: 50%;
  box-sizing: border-box;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  font-size: ${({ size }) => size / 2}px;
  display: flex;
  align-items: center;
  justify-content: center;

  i,
  .icon {
    color: ${({ color }) => (color ? color : neutral[400])};
  }

  ${({ hasOnClick, hover }) =>
    (hasOnClick || hover) &&
    `
    cursor: pointer;
    &:hover {
        background: ${honeygold};
        transition: all ${Speed.Short}ms ease;
        color: ${neutral[100]};
    }`}

  i {
    font-size: ${({ size }) => size / 2.2}px;
  }

  ${({ isOutline, color }) =>
    isOutline &&
    `
    background: none;
    border-color: ${color ? color : neutral[400]};
    border-width: ${Spacing.Small}px;
    border-style: solid;
    `}

  .badge {
    position: absolute;
    right: -10%;
    top: -10%;
    font-size: 40%;
    border: 0.2rem solid white;
  }
`;

export interface ICircleIconProps extends IIconProps {
  hover?: boolean;
  outline?: boolean;
  tooltip?: string;
  tooltipSub?: string;
  background?: string;
  preventDefault?: boolean;
}

const CircleIcon: React.FC<ICircleIconProps> = props => {
  const ref = useRef() as React.RefObject<HTMLSpanElement>;

  const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    props.preventDefault && e.preventDefault();
    props.onClick?.();
  };

  const getSize = () => {
    switch (props.size) {
      case Size.ExtraSmall:
        return 18;
      case Size.Small:
        return 24;
      case Size.Medium:
        return 42;
      case Size.Large:
        return 56;
      case Size.ExtraLarge:
        return 72;
      case Size.Jumbo:
        return 100;
      default:
        return 100;
    }
  };

  return (
    <>
      {props.tooltip != null && (
        <Tippy
          reference={ref}
          content={<Tooltip title={props.tooltip} sub={props.tooltipSub} />}
        />
      )}
      <Wrapper
        ref={ref}
        style={props.style}
        className={`circle-icon ${props.className ? props.className : ""}`}
        size={getSize()}
        hasOnClick={props.onClick != null}
        isOutline={props.outline}
        onClick={handleClick}
        hover={props.hover}
        color={props.color}
        background={props.background}
      >
        <Icon {...props} badge={0} stopPropagation />
        {props.badge != null && props.badge > 0 && (
          <Badge value={props.badge} />
        )}
      </Wrapper>
    </>
  );
};

CircleIcon.defaultProps = {
  size: Size.Medium,
  spin: false,
  hover: false,
  color: neutral[600],
  preventDefault: false,
};

export default CircleIcon;
