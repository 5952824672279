import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import { Dropdown, Sub } from "ui-kit";
import tagsActions from "areas/send/actions/tags/tagsActions";
import { RootState } from "reducers/store";
import { TagListView } from "areas/send/types/passportResponse.types";

interface ITagDropdownProps {
  value?: TagListView;
  fluid?: boolean;
  onChange: (tag: TagListView) => void;
  filter: TagListView[];
}

const TagDropdown: React.FC<ITagDropdownProps> = ({ value, fluid, onChange, filter }) => {

  const { error, loading, tags } = useSelector((state: RootState) => state.tags);

  useEffect(() => {
    arrays.isEmpty(tags) && tagsActions.getTags();
  }, []);

  if (error) {
    return <Sub>There was a problem loading the tags</Sub>;
  }

  return (
    <Dropdown
      value={value}
      loading={loading}
      placeholder="Choose a Tag"
      fluid={fluid}
      onChange={onChange}
    >
      {tags
        .filter(x => !filter || !filter.some(t => t.id === x.id))
        .map((tag: TagListView, index: number) => (
          <Dropdown.Item label={tag.name} value={tag} key={index} />
        ))}
    </Dropdown>
  );
};

export default TagDropdown;
