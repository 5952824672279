import { IBeehiveAction } from "types/common/action.types";
import consentActions from "../../actions/consentActions";

interface ICompletedSubmissionState {
  working: boolean;
  consent: null; //This action has no payload?
  error: string | null;
  saving: boolean;
}

const CONSENT_STATE: ICompletedSubmissionState = {
  working: false,
  consent: null,
  error: null,
  saving: false,
};


const completedSubmissionReducer = (state = CONSENT_STATE, action: IBeehiveAction) : ICompletedSubmissionState => {

  const { CONSENT_SUBMITCONSENT } = consentActions.types;

  switch (action.type) {
    case CONSENT_SUBMITCONSENT.START:
      return { ...CONSENT_STATE, working: true, error: null };
    case CONSENT_SUBMITCONSENT.SUCCESS:
      return { ...state, working: false, consent: action.payload, error: null };
    case CONSENT_SUBMITCONSENT.FAILED:
      return {
        ...state,
        working: false,
        consent: null,
        error: "There was a problem submitting the consent response",
      };

    default:
      return state;
  }
};

export default completedSubmissionReducer;
