import { DatePicker, StructuredList } from "ui-kit";
import { ICateringReportProps } from ".";
import { SchoolDropdown } from "sharedComponents/common";
import TutorGroupDropdown from "../tutorGroupDropdown";
import LunchTypeDropdown from "../lunchTypeDropdown";


const PupilDailyReport: React.FC<ICateringReportProps> = ({ filter, setFilter }) => {

  return (
    <>
      <StructuredList.Item name="Date">
        <DatePicker
          closeOnSelect
          selectedDate={filter?.date}
          onChange={value => setFilter({ ...filter, date: value })}
        />
      </StructuredList.Item>
      <StructuredList.Item name="School">
        <SchoolDropdown
          initialValue={filter?.schoolId}
          onChange={value => setFilter({ ...filter, schoolId: value })}
          filter={x => x.enableLunchCredits}
          fluid
        />
      </StructuredList.Item>
      <StructuredList.Item name="Tutor Group">
        <TutorGroupDropdown
          schoolId={filter?.schoolId}
          onChange={tutorGroup => setFilter({ ...filter, tutorGroupId: tutorGroup?.id })}
          fluid
          includeAllOption
        />
      </StructuredList.Item>
      <StructuredList.Item name="Lunch Type">
        <LunchTypeDropdown
          initialValue={filter?.lunchType}
          onChange={value => setFilter({ ...filter, lunchType: value })}
          fluid
        />
      </StructuredList.Item>
    </>
  )
}

export default PupilDailyReport;