import React from "react";
import styled from "styled-components";
import {
  DetailLabel,
  EmptyMessage,
  Loader,
  Size,
  Spacing,
  Table,
} from "ui-kit";
import { formatDate, formatTime } from "utils/dateTime";
import DetentionStatusChip from "areas/behaviour/components/detentions/detentionStatusChip";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { useAppSelector } from "reducers/hooks";


const StatusChipWrapper = styled.div`
  .chip {
    margin-left: 0;
    margin-top: ${Spacing.Small}px;
    font-size: 12px;
  }
`;


interface IDetentionDetailedReportTableProps {
  handlePage: () => void;
  loading: boolean;
}


const DetentionDetailedReportTable: React.FC<IDetentionDetailedReportTableProps> = ({ handlePage, loading }) => {

  const { report, paging, error } = useAppSelector(
    state => state.detentionsDetailedReport
  );

  if (loading) {
    return <Loader size={Size.Large} fluid />;
  }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return !report ? null : report.length > 0 ? (
    <>
      <br />
      <Table grow loading={loading}>
        <Table.Header>
          <Table.HeaderCell width={1}>Student</Table.HeaderCell>
          <Table.HeaderCell width={1.4}>Detention</Table.HeaderCell>
          <Table.HeaderCell width={1}>Behaviour</Table.HeaderCell>
          <Table.HeaderCell width={1}>Given By</Table.HeaderCell>
          <Table.HeaderCell width={1}>Updated By</Table.HeaderCell>
          <Table.HeaderCell width={1}>Registered By</Table.HeaderCell>
          <Table.HeaderCell width={1}>Cancelled By</Table.HeaderCell>
        </Table.Header>

        <Table.Body onPage={handlePage} paging={paging}>
          {report.map(detention => (
            <Table.Row>
              <Table.Cell width={1}>
                <DetailLabel
                  label={detention.studentName}
                  sub={detention.admissionNo}
                  bold
                />
              </Table.Cell>
              <Table.Cell width={1.4}>
                <DetailLabel
                  label={detention.detentionType}
                  sub={
                    <>
                      {`${formatDate(detention.awardedDate)} ${formatTime(
                        detention.awardedDate
                      )}`}
                      <StatusChipWrapper>
                        <DetentionStatusChip
                          detentionStatus={detention.statusId}
                          issuedText="Issued"
                        />
                      </StatusChipWrapper>
                    </>
                  }
                  bold
                />
              </Table.Cell>
              <Table.Cell width={1}>
                <DetailLabel
                  label={detention.behaviourCode}
                  sub={detention.categoryName}
                  bold
                />
              </Table.Cell>
              <Table.Cell width={1}>
                <DetailLabel
                  label={detention.awardedBy}
                  sub={`${formatDate(detention.awardedDate)} ${formatTime(
                    detention.awardedDate
                  )}`}
                  bold
                />
              </Table.Cell>
              <Table.Cell width={1}>
                {detention.updatedBy && (
                  <DetailLabel
                    label={detention.updatedBy}
                    sub={`${formatDate(detention.updatedDate)} ${formatTime(
                      detention.awardedDate
                    )}`}
                    bold
                  />
                )}
              </Table.Cell>
              <Table.Cell width={1}>
                {detention.registerdBy && (
                  <DetailLabel
                    label={detention.registerdBy}
                    sub={`${formatDate(detention.registeredDate)} ${formatTime(
                      detention.awardedDate
                    )}`}
                    bold
                  />
                )}
              </Table.Cell>
              <Table.Cell width={1}>
                {detention.cancelledBy && (
                  <DetailLabel
                    label={detention.cancelledBy}
                    sub={`${formatDate(detention.cancelledDate)} ${formatTime(
                      detention.awardedDate
                    )}`}
                    bold
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  ) : (
    <EmptyMessage title="No Detentions Found" icon="list" />
  );
};

export default DetentionDetailedReportTable;
