import { StaffView, UserName } from "sharedComponents/common";
import ContextMenu from "ui-kit/composite/contextMenu";
import {
  getGroupName,
  FeedItemContent,
  DateAndStudentName,
  FeedItemDetails,
  FeedItemActionButtons,
} from "../activityFeedItemComponents";
import { Chip } from "ui-kit";

const RewardActivity = ({
  activity,
  displayStudentName,
  allowChange,
  handleEditActivity,
  handleFeedClick,
}) => {
  return (
    <>
      <FeedItemContent onClick={handleFeedClick}>
        <DateAndStudentName
          displayStudentName={displayStudentName}
          activity={activity}
        />

        <FeedItemDetails>
          <b>{activity.categoryName}</b> given by{" "}
          <b>
            <UserName user={activity.createdBy} />
          </b>
          {!displayStudentName && getGroupName(activity)}
          <p>
            <b>
              {activity.houseName && activity.housePoints && (
                <Chip
                  className="house-points-chip"
                  text={`${activity.houseName} +${activity.housePoints}`}
                />
              )}
            </b>
          </p>
          <div className="note-content">{activity.publicNotes}</div>
        </FeedItemDetails>
      </FeedItemContent>

      <StaffView>
        {allowChange() && (
          <FeedItemActionButtons>
            <ContextMenu>
              <ContextMenu.Item
                text="Edit"
                onClick={() => handleEditActivity(activity)}
              />
            </ContextMenu>
          </FeedItemActionButtons>
        )}
      </StaffView>
    </>
  );
};

export default RewardActivity;
