import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  HtmlText,
  honeygold,
  typescale,
  textFont,
  neutral,
  Spacing,
  fontStyle,
  Speed,
  Left,
  Right,
  Chip,
  displayFont,
  Card,
} from "ui-kit";
import FeedItemFooter from "./feedItemFooter";
import FeedItemHeader from "./feedItemHeader";
import { files } from "utils";
import feedActions from "../actions/feedActions";
import {
  FileList,
  LinkList,
  SchoolIcon,
  StaffView,
} from "sharedComponents/common";
import { IFeedItem } from "../reducers/feedReducer";
import { RootState } from "reducers/store";
import { FileListView } from "types/common/files.types";


const FeedItemBody = styled.div`
  h5 {
    margin-bottom: ${Spacing.ExtraLarge}px;
  }
  .chip {
    margin-right: ${Spacing.Default}px;
  }
  .links-list {
    margin-bottom: 0;
  }
`;

const ReadMore = styled.div`
  display: block;
  ${fontStyle(textFont.roman, typescale.sub, honeygold)}

  &:hover {
    transistion: all ${Speed.Default}ms ease;
    color: ${neutral[800]};
    cursor: pointer;
  }
`;

const FeedItemContent = styled.div`
  .file-list {
    padding-top: ${Spacing.Large}px;
  }
`;

const FeedItemSchools = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  span {
    margin-right: ${Spacing.Small}px;
    &:last-child {
      margin-right: 0;
    }
  }

  .feed-items-schools-overflow {
    ${fontStyle(displayFont.medium, typescale.sub, neutral[600])}
  }
`;


interface IFeedArticleProps {
  item: IFeedItem;
}


const FeedArticle: React.FC<IFeedArticleProps> = ({ item }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);

  const isMedia = (file: FileListView) => {
    return files.isMediaMimeType(file.mimeType);
  };

  const truncate = (str: string, max: number, suffix?: string) => {
    return str
      ? str.length < max
        ? str
        : `${str.substr(
            0,
            str.substr(0, max - suffix.length).lastIndexOf(" ")
          )}${suffix}`
      : null;
  };

  const [content, setContent] = useState<string>(item?.content);
  const [media, setMedia] = useState<FileListView[]>(item?.files?.filter(x => isMedia(x)));

  //  const [content, setContent] = useState(truncate(item?.content, 350, "..."));

  const [truncated, setTruncated] = useState<boolean>(false);

  // useEffect(() => {
  //   // setContent(truncate(item?.content, 150, "..."));
  // }, [item]);

  useEffect(() => {
    if (!truncated) {
      setContent(item?.content);
    }
  }, [truncated]);

  const handleDismiss = (article: IFeedItem) => {
    item.dismissed
      ? feedActions.undismissFeedArticle(user.id, item.id)
      : feedActions.dismissFeedArticle(user.id, item.id);
  };

  const handleLike = (article: IFeedItem) => {
    item.liked
      ? feedActions.unlikeFeedArticle(user.id, item.id)
      : feedActions.likeFeedArticle(user.id, item.id);
  };

  const handleFavourite = (article: IFeedItem) => {
    item.favourited
      ? feedActions.unfavouriteFeedArticle(user.id, item.id)
      : feedActions.favouriteFeedArticle(user.id, item.id);
  };

  const handleMediaClick = (index: number) => {
    feedActions.openGallery(media, index);
  };

  return (
    <Card>
      <Card.Header>
        <Left>
          <FeedItemHeader
            icon="newspaper"
            postMessage={`posted an ${
              item.type === 0 ? "notice" : "feature"
            } in `}
          />
        </Left>
        <Right>
          {user?.schools.length > 0 && (
            <FeedItemSchools>
              {item.schools.map(
                (school, index) =>
                  index < 3 && <SchoolIcon key={index} code={school.code} />
              )}
              {item.schools.length >= 3 && (
                <span className="feed-items-schools-overflow">
                  +{item.schools.length}
                </span>
              )}
            </FeedItemSchools>
          )}
        </Right>
      </Card.Header>
      {media?.length > 0 && (
        <Card.Media noPad media={media} onClick={handleMediaClick} />
      )}
      <Card.Body>
        <FeedItemBody>
          <p>
            {item.isSticky && (
              <>
                <Chip text="Pinned" />
              </>
            )}
            <b>{item.title}</b>
            <br />
          </p>

          <FeedItemContent>
            {item.type === 0 && (
              <>
                <HtmlText html={content} fluid />
                {truncated && (
                  <ReadMore onClick={() => setTruncated(false)}>
                    Read More...
                  </ReadMore>
                )}
              </>
            )}

            <FileList title="Attachments">
              {item.files
                ?.filter(file => !isMedia(file))
                .map((file, index) => (
                  <FileList.Item key={index} file={file} clickToDownload />
                ))}
            </FileList>

            <LinkList title="Links">
              {item.links?.map((link, index) => (
                <LinkList.Item key={index} link={link} />
              ))}
            </LinkList>
          </FeedItemContent>
        </FeedItemBody>
      </Card.Body>
      <Card.Footer noPad>
        <StaffView>
          <FeedItemFooter
            item={item}
            onFavourite={handleFavourite}
            onDismiss={handleDismiss}
            onLike={handleLike}
          />
        </StaffView>
      </Card.Footer>
    </Card>
  );
};

export default FeedArticle;
