import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ActionBar } from "ui-kit";
import styled from "styled-components";
import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import smartTaskActions from "areas/behaviour/actions/smartTasks/smartTaskActions";
import schoolsActions from "areas/planner/actions/schools/schoolsActions";
import MultiSchoolUserView from "sharedComponents/common/users/multiSchoolUserView";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import { useAppSelector } from "reducers/hooks";


const Wrapper = styled.div`
  .card .card-children .card-body .action-bar .dropdown {
    width: 100%;
  }
`;


const SmartTasksHeader: React.FC = () => {
  
  const { schoolId } = useAppSelector(state => state.schools);

  useEffect(() => {
    if (schoolId) {
      rewardActions.getRewardCodes(schoolId);
      rewardActions.getRewardCategories(schoolId, {});
    }
  }, [schoolId]);

  const onSelectSchool = (value: number) => {
    smartTaskActions.resetSelectedSmartTask();
    schoolsActions.selectSchool(value);
    rewardActions.getRewardCodes(value);
    rewardActions.getRewardCategories(value, {});
  };

  return (
    <Wrapper>
      <MultiSchoolUserView>
        <ActionBar>
          <SchoolDropdown
            onChange={onSelectSchool}
            initialValue={schoolId}
            fluid
          />
        </ActionBar>
      </MultiSchoolUserView>
    </Wrapper>
  );
};

export default SmartTasksHeader;
