import { IBeehiveAction } from "types/common/action.types";
import formSubmissionActions from "../actions/formSubmissionActions";


interface IFormSubmissionState {
  error: string | null;
  submitting: boolean;
}

const INITIAL_STATE: IFormSubmissionState = {
  error: null,
  submitting: false,
};


const formSubmissionReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IFormSubmissionState => {

  const { SUBMIT } = formSubmissionActions.types;

  switch (action.type) {
    case SUBMIT.START:
      return { ...state, submitting: true, error: null };

    case SUBMIT.SUCCESS:
      return {
        ...state,
        submitting: false,
      };

    case SUBMIT.FAILED:
      return {
        ...state,
        submitting: false,
        error: "An error occurred while submitting the form",
      };

    default:
      return state;
  }
};

export default formSubmissionReducer;
