import { BulletinTemplateDetailView } from "areas/news/types/newsResponse.types";
import bulletinActions from "../../actions/bulletinActions";
import { IBeehiveAction } from "types/common/action.types";


interface IBulletinState {
  template: BulletinTemplateDetailView | null;
  bulletin: BulletinTemplateDetailView | null;
  actions: {
    load: { working: boolean; error: string | null; };
    save: { working: boolean; error: string | null; };
  };
}

const INITIAL_STATE: IBulletinState = {
  template: null,
  bulletin: null,
  actions: {
    load: { working: false, error: null },
    save: { working: false, error: null },
  },
};


const bulletinReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IBulletinState => {

  const {
    BULLETINTEMPLATE_GET,
    BULLETINTEMPLATE_SAVE,
    BULLETINTEMPLATE_CREATE,
  } = bulletinActions.types;

  switch (action.type) {
    case BULLETINTEMPLATE_GET.START:
      return {
        ...INITIAL_STATE,
        actions: { ...state.actions, load: { working: true, error: null } },
      };

    case BULLETINTEMPLATE_GET.SUCCESS:
      return {
        ...state,
        template: action.payload,
        actions: { ...state.actions, load: { working: false, error: null } },
      };

    case BULLETINTEMPLATE_GET.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          load: {
            working: false,
            error: "There was a problem loading the bulletin.",
          },
        },
      };

    case BULLETINTEMPLATE_SAVE.START:
    case BULLETINTEMPLATE_CREATE.START:
      return {
        ...INITIAL_STATE,
        actions: { ...state.actions, save: { working: true, error: null } },
      };

    case BULLETINTEMPLATE_SAVE.SUCCESS:
    case BULLETINTEMPLATE_CREATE.SUCCESS:
      return {
        ...state,
        bulletin: action.payload,
        actions: { ...state.actions, save: { working: false, error: null } },
      };

    case BULLETINTEMPLATE_SAVE.FAILED:
    case BULLETINTEMPLATE_CREATE.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          save: {
            working: false,
            error: "There was a problem saving the bulletin.",
          },
        },
      };

    default:
      return state;
  }
};

export default bulletinReducer;
