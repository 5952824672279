import { ChipList, Chip, Swatches, Size } from "ui-kit";
import { Constants } from "configuration";
import { arrays } from "utils";
import SchoolIcon from "../schools/icons/schoolIcon";
import UserTypeView from "../users/userTypeView";
import { GroupListView } from "types/users/userGroups.types";


interface IGroupListProps {
  groups: GroupListView[];
  limit?: number;
  expandable?: boolean;
  onGroupClick?: (group: GroupListView, index: number) => void;
  chipHoverBackground?: string;
  chipIcon?: string;
  showSchoolIcon?: boolean;
  style?: object;
}

const GroupList: React.FC<IGroupListProps> = ({
  groups,
  limit = 3,
  expandable,
  onGroupClick,
  chipHoverBackground,
  chipIcon,
  showSchoolIcon,
  style,
}) => {

  const nonStaffGroups: GroupListView[] = [...new Set(groups?.map((group: GroupListView) => group))];

  const handleGroupClick = (group: GroupListView, index: number) => {
    onGroupClick?.(group, index);
  };

  if (arrays.isEmpty(groups)) {
    return null;
  }

  return (
    <UserTypeView>
      <UserTypeView.Item userType={Constants.USER_TYPES.STAFF}>
        <ChipList limit={3} expandable={expandable} style={style}>
          {groups?.map((group, index) => (
            <Chip
              key={index}
              text={group.name}
              colorSwatch={Swatches.Default}
              onClick={() => handleGroupClick(group, index)}
              hoverBackground={chipHoverBackground}
              rightIcon={chipIcon}
              schoolIcon={
                showSchoolIcon ? (
                  <SchoolIcon code={group.school?.code} size={Size.Small} />
                ) :
                (<></>)
              }
            />
          ))}
        </ChipList>
      </UserTypeView.Item>
      <UserTypeView.Item userType={Constants.USER_TYPES.STAFF} inverse>
        <ChipList limit={3} expandable={expandable} style={style}>
          {nonStaffGroups?.map((group, index) => (
            <Chip
              key={index}
              text={group.friendlyName}
              colorSwatch={Swatches.Default}
              onClick={() => handleGroupClick(group, index)}
              hoverBackground={chipHoverBackground}
              rightIcon={chipIcon}
            />
          ))}
        </ChipList>
      </UserTypeView.Item>
    </UserTypeView>
  );
};

export default GroupList;
