import React, { useState, useEffect } from "react";
import { Loader, Size, EmptyMessage, Label } from "ui-kit";
import { arrays } from "utils";
import RewardReportTable from "./rewardReportTable";
import BehaviourReportTable from "./behaviourReportTable";
import BehaviourAndRewardReportTable from "./behaviourAndRewardReportTable";
import { ApiExceptionMessage } from "../../../../sharedComponents/common";
import { GenerateBehaviourReportCommand } from "areas/analytics/types/behaviourAnalyticsRequest.types";
import { useAppSelector } from "reducers/hooks";
import { BehaviourIncludeType, GroupBy } from "areas/analytics/types/behaviourAnalyticsShared.types";

interface IReportTableProps {
  reportParameters: GenerateBehaviourReportCommand;
}

const ReportTable: React.FC<IReportTableProps> = ({ reportParameters }) => {

  const { report, loading, error } = useAppSelector(state => state.behaviourAnalytics);

  const [reportBehaviourType, setReportBehaviourType] = useState<BehaviourIncludeType>(null);
  const [groupType1, setGroupType1] = useState<GroupBy>(null);
  const [groupType2, setGroupType2] = useState<GroupBy>(null);

  useEffect(() => {
    if (reportParameters) {
      setReportBehaviourType(reportParameters.behaviourIncludeTypeId);
      setGroupType1(reportParameters.groupBy1);
      setGroupType2(reportParameters.groupBy2);
    }
  }, [reportParameters]);

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return (
    report && (
      <>
        <Label bold>
          All values in the report below are the number of behaviour points or
          the number of reward points for the chosen categories.
        </Label>

        <br />

        {arrays.isEmpty(report.behaviourGroupByView1s) ? (
          <EmptyMessage
            title="No rewards or behaviours in the selected time period"
            icon="list"
          />
        ) : reportBehaviourType === BehaviourIncludeType.Reward ? (
          <RewardReportTable
            report={report}
            groupType1={groupType1}
            groupType2={groupType2}
          />
        ) : reportBehaviourType === BehaviourIncludeType.Behaviour ? (
          <BehaviourReportTable
            report={report}
            groupType1={groupType1}
            groupType2={groupType2}
          />
        ) : (
          <BehaviourAndRewardReportTable
            report={report}
            groupType1={groupType1}
            groupType2={groupType2}
          />
        )}
      </>
    )
  );
};

export default ReportTable;
