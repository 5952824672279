import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import userManagementActions from "areas/administration/actions/userManagementActions";
import mailoutActions from "areas/mailout/actions/mailoutActions";
import { useNavigate, useParams } from "react-router-dom";
import {
  Loader,
  Size,
  EmptyMessage,
  Title,
  TitleSize,
  Tabs,
  StructuredList,
  Card,
  Chip,
  DetailLabel,
  Swatches,
  Label,
  Button,
  ActionBar,
  DateTime,
  Right,
  ActionPanel,
  Message,
  Subtitle,
  Checkbox,
  ChipList,
  ToastService,
  Table,
  Sub,
  List,
  SplitButton,
} from "ui-kit";
import Moment from "react-moment";
import { arrays, files, types, users } from "utils";
import { Constants } from "configuration";
import moment from "moment";
import profileActions from "areas/administration/actions/profileActions";
import leaveActions from "areas/humanResources/actions/leaveActions";
import StaffEmploymentModal from "./staffEmploymentModal";
import StaffDepartmentsModal from "./staffDepartmentsModal";
import StaffRolesModal from "./staffRolesModal";
import StaffSchoolsModal from "./staffSchoolsModal";
import QueueMessageTable from "areas/mailout/components/queueMessageTable";
import config from "configuration";
import EditHolidayCardModal from "areas/humanResources/components/leave/editHolidayCardModal";
import StudentDetailsModal, { studentDetailsData } from "./studentDetailsModal";
import Subheader from "sharedComponents/layout/header/subheader";
import SchoolAvatar from "sharedComponents/common/schools/schoolAvatar";
import UserList from "sharedComponents/common/users/userList";
import Address from "sharedComponents/common/formatting/address";
import TelephoneNumberList from "sharedComponents/common/formatting/telephoneNumberList";
import CarRegistrationList from "sharedComponents/common/formatting/carRegistrationList";
import UserName from "sharedComponents/common/users/userName";
import Email from "sharedComponents/common/formatting/email";
import Avatar from "sharedComponents/common/users/avatar";
import SchoolIcon from "sharedComponents/common/schools/icons/schoolIcon";
import RoleView from "sharedComponents/common/users/roleView";
import { adminRoutes } from "areas/administration/adminRoutes";
import { leaveRoutes } from "areas/humanResources/hrRoutes";
import StudentSensitiveData from "areas/planner/components/studentRecord/studentSensitiveData/studentSensitiveData";
import { RootState } from "reducers/store";
import { UserNotificationPreferencesView } from "types/users/userNotification.types";
import { SchoolListView } from "types/schools/schools.types";
import {
  ManagedParentDetailView,
  ManagedUserDetailView,
  StaffListView,
} from "types/users/userListViews.types";
import {
  managedParent,
  managedStaff,
  managedStudent,
  managedUser,
} from "areas/administration/reducers/userManagement/managedUserReducer";
import { ManagedStaffSchoolView } from "types/users/userSchools.types";
import {
  SetStaffRolesCommand,
  UpdateStaffDepartmentsCommand,
} from "areas/administration/types/userManagementRequest.types";
import { HolidayCardView } from "areas/humanResources/types/leaveResponse.types";
import UserSyncView from "../sync/UserSyncView";

const RolesWrapper = styled.ul`
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  li {
    box-sizing: border-box;
  }
`;

interface userChips {
  bursary: boolean;
  childProtection: boolean;
  lookedAfter: boolean;
}

const User = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useSelector(
    (state: RootState) => state.currentUser
  );
  const { user, status, actions } = useSelector(
    (state: RootState) => state.managedUser
  );
  const {
    emails,
    loading: emailsLoading,
    error: emailsError,
    paging: emailsPaging,
  } = useSelector((state: RootState) => state.userEmails);
  const {
    smsMessages,
    loading: smsMessagesLoading,
    error: smsMessagesError,
    paging: smsMessagesPaging,
  } = useSelector((state: RootState) => state.userSmsMessages);
  const { settingNotificationPreferences, notificationPreferencesError } =
    useSelector((state: RootState) => state.profile);
  const {
    holidayCard,
    loading: loadingHolidayCard,
    error: holidayCardError,
  } = useSelector((state: RootState) => state.staffMemberHolidayCard);

  const [_notificationPreferences, _setNotificationPreferences] =
    useState<UserNotificationPreferencesView | null>(null);
  const [_employmentModalOpen, _setEmploymentModalOpen] =
    useState<boolean>(false);
  const [_departmentsModalOpen, _setDepartmentsModalOpen] =
    useState<boolean>(false);
  const [_rolesModalOpen, _setRolesModalOpen] = useState<boolean>(false);
  const [_schoolsModalOpen, _setSchoolsModalOpen] = useState<boolean>(false);
  const [_selectedSchool, _setSelectedSchool] = useState<
    SchoolListView | ManagedStaffSchoolView | null
  >(null);
  const [_holidayCardModalOpen, _setHolidayCardModalOpen] =
    useState<boolean>(false);
  const [_detailsModalOpen, _setDetailsModalOpen] = useState<boolean>(false);
  const [_assignedStaffModalOpen, _setAssignedStaffModalOpen] =
    useState<boolean>(false);
  const [_chips, _setChips] = useState<userChips>({
    bursary: null,
    childProtection: null,
    lookedAfter: null,
  });
  const [_assignedStaff, _setAssignedStaff] = useState<StaffListView[]>([]);

  const isItAdmin = () => {
    return users.isInAnyRoles(currentUser, [
      Constants.ROLES.DEVELOPMENT,
      Constants.ROLES.IT_ADMINISTRATOR,
      Constants.ROLES.IT_USER,
    ]);
  };

  const isSyncAdmin = () => {
    return users.isInAnyRoles(currentUser, [Constants.ROLES.SYNCDATA_ADMINISTRATOR]);
  };

  const isHrAdmin = () => {
    return users.isInAnyRoles(currentUser, [Constants.ROLES.HR_ADMINISTRATOR]);
  };

  const getEmails = (pageIndex: number) => {
    mailoutActions.getEmailsForUser(userId, pageIndex);
  };

  const getSmsMessages = (pageIndex: number) => {
    mailoutActions.getSmsMessagesForUser(userId, pageIndex);
  };

  useEffect(() => {
    userId &&
      userManagementActions.getUser(userId, (data: ManagedUserDetailView) => {
        _setNotificationPreferences(data.notificationPreferences);
        userManagementActions.getSessions(userId);
        getEmails(0);
        getSmsMessages(0);
        leaveActions.getHolidayCardForStaffMember(
          userId,
          config.academicYear.current
        );
      });
  }, [userId]);

  useEffect(() => {
    user &&
      _setChips({
        bursary: user.bursary,
        childProtection: user.childProtection,
        lookedAfter: user.lookedAfter,
      });
    user && _setAssignedStaff(user.assignedStaff);
  }, [user]);

  const handleAccountClick = (
    user: ManagedParentDetailView | ManagedUserDetailView
  ) => {
    navigate(adminRoutes.getUserPath(user.id));
  };

  const handleChangeSchools = () => {
    ToastService.pop("Schools Updated", null, "user");
  };

  const handleChangeRoles = () => {
    var data: SetStaffRolesCommand = {
      userId: userId,
      roles: [],
    };
    userManagementActions.setStaffRoles(userId, data, () => {
      ToastService.pop("Roles Updated", null, "user");
    });
  };

  const handleChangeDepartments = () => {
    var data: UpdateStaffDepartmentsCommand = {
      userId: userId,
      departments: [],
    };
    userManagementActions.setStaffDepartments(userId, data, () => {
      ToastService.pop("Departments Updated", null, "user");
    });
  };

  // Account-related actions
  const handleClearSessions = () => {
    if (
      window.confirm(
        "This will clear all sessions for this user and log them out from all devices. Are you sure?"
      )
    ) {
      userManagementActions.clearSessions(userId, () => {
        ToastService.pop("User Sessions Cleared", null, "user");
      });
    }
  };

  const handleSendRegistrationLink = () => {
    if (
      window.confirm(
        "This will send a new registration link to this user. Are you sure?"
      )
    ) {
      userManagementActions.sendRegistrationLink(userId, () => {
        ToastService.pop("Registration Link Sent", null, "user");
      });
    }
  };

  const handleSendResetPasswordLink = () => {
    if (
      window.confirm(
        "This will send a reset password link to this user. Are you sure?"
      )
    ) {
      userManagementActions.sendResetPasswordLink(userId, () => {
        ToastService.pop("Password Reset Link Sent", null, "user");
      });
    }
  };

  const handleDeregisterUser = () => {
    if (
      window.confirm(
        "This will deregister this user and log them out from all devices. Are you sure?"
      )
    ) {
      userManagementActions.deregister(userId, () => {
        ToastService.pop("User Deregistered", null, "user");
      });
    }
  };

  const handleUnlockAccount = () => {
    if (window.confirm("This will unlock this account. Are you sure?")) {
      userManagementActions.unlockAccount(userId, () => {
        ToastService.pop("User Account Unlocked", null, "user");
      });
    }
  };

  const handleDownloadLog = () => {
    userManagementActions.getUserRequestLog(userId, data => {
      files.openCsv(data);
    });
  };

  const handleUpdateNotifications = () => {
    profileActions.setNotificationPreferences(
      userId,
      { userId, notificationPreferences: _notificationPreferences },
      () => {
        ToastService.pop("Notification Preferences Saved", null, "user");
      }
    );
  };

  const handleEmploymentDetailsChanged = (newUser: managedStaff) => {
    _setEmploymentModalOpen(false);
  };

  const handleDepartmentsChanged = (departments: number[]) => {
    _setDepartmentsModalOpen(false);
  };

  const handleStudentDetailsChanged = (studentDetails: studentDetailsData) => {
    _setChips(studentDetails); //adds assigned staff also to the chips object but... meh
    _setAssignedStaff(studentDetails.assignedStaff);
    _setDetailsModalOpen(false);
  };

  const handleRolesChanged = (roles: number[]) => {
    _setRolesModalOpen(false);
  };

  const handleSchoolEditClicked = (
    school: SchoolListView | ManagedStaffSchoolView
  ) => {
    _setSelectedSchool(school);
    _setSchoolsModalOpen(true);
  };

  const handleSchoolDeleteClicked = (
    school: SchoolListView | ManagedStaffSchoolView
  ) => {
    if (
      window.confirm("Are you sure you want to remove the selected school?")
    ) {
      userManagementActions.removeStaffSchool(user.id, school.id, () => {
        ToastService.pop("School Removed", null, "user");
      });
    }
  };

  const handleAddSchoolClicked = () => {
    _setSelectedSchool(null);
    _setSchoolsModalOpen(true);
  };

  const handleSetBaseSchoolClicked = (school: ManagedStaffSchoolView) => {
    userManagementActions.setStaffBaseSchool(
      user.id,
      { schoolId: school.id, userId: user.id },
      () => {
        ToastService.pop("Base School Updated", null, "user");
      }
    );
  };

  const handleSchoolsChanged = (staff: managedStaff) => {
    _setSelectedSchool(null);
    _setSchoolsModalOpen(false);
  };

  const handleSchoolsModalClosed = () => {
    _setSchoolsModalOpen(false);
    _setSelectedSchool(null);
  };

  const onEmailsPage = () => {
    getEmails(emailsPaging.pageIndex + 1);
  };

  const onSmsMessagesPage = () => {
    getSmsMessages(emailsPaging.pageIndex + 1);
  };

  const viewHolidayCard = () => {
    navigate(leaveRoutes.getStaffLeavePath(userId));
  };

  const handleHolidayCardSaved = (card: HolidayCardView) => {};

  if (status?.loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (status?.error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem while loading the user"
        cover
      />
    );
  }

  return user ? (
    <>
      <Subheader>
        <Title
          size={TitleSize.H2}
          text="User Management"
          sub={user && `${user.firstName} ${user.lastName}`}
        />
      </Subheader>
      <Tabs>
        <Tabs.Pane label="Personal Details">
          {user.syncStatusId === 1 && (
              <Message text={"There are sync warnings for this user. Please review the details."} color={Swatches.Warning} />
          )}
          <Card title="General Details">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Name">
                  <DetailLabel
                    bold
                    label={`${user.firstName} ${user.lastName}`}
                    sub={user.initials}
                  />
                </StructuredList.Item>
                <StructuredList.Item name="User Type">
                  <Chip text={user.userType} />
                </StructuredList.Item>

                <StructuredList.Item
                  name={users.isStaff(user) ? "Base School" : "School"}
                >
                  <SchoolAvatar school={user.school} />
                </StructuredList.Item>

                <StructuredList.Item
                  name={users.isStaff(user) ? "Base SIMS ID" : "SIMS ID"}
                >
                  <Label>
                    {user.isTrust
                      ? "LATS"
                      : user.school
                      ? user.school.code + "S"
                      : ""}
                    {user.simsId}
                  </Label>
                </StructuredList.Item>

                {!users.isStaff(user) && (
                  <StructuredList.Item name="Admission Number">
                    {user.simsId}
                  </StructuredList.Item>
                )}
                {(users.isStaff(user) || users.isStudent(user)) && (
                  <StructuredList.Item name="External ID">
                    {user.externalId}
                  </StructuredList.Item>
                )}
                { user.dateOfBirth && (
                  <StructuredList.Item name="Date of Birth">
                    <Moment format="DD/MM/YYYY" date={user.dateOfBirth} />
                  </StructuredList.Item>
                )}
              </StructuredList>
            </Card.Body>
          </Card>

          {users.isStudent(user) && (
            <>
              <Card title="Pastoral Details">
                <Card.Body>
                  <StructuredList>
                    <StructuredList.Item name="Year Group">
                      {user.yearGroup?.name}
                    </StructuredList.Item>

                    <StructuredList.Item name="Tutor Group">
                      {user.tutorGroup?.name}
                    </StructuredList.Item>
                    <StructuredList.Item name="House">
                      {user.house?.name}
                    </StructuredList.Item>

                    <StructuredList.Item name="Additional Info">
                      <StudentSensitiveData
                        student={user}
                        compact={false}
                        fluid={false}
                      />
                    </StructuredList.Item>
                    <StructuredList.Item name="Assigned Staff">
                      <UserList users={_assignedStaff} />
                    </StructuredList.Item>
                  </StructuredList>
                </Card.Body>
                <Card.Footer>
                  <ActionBar low>
                    <Right>
                      <Button
                        onClick={() => _setDetailsModalOpen(true)}
                        size={Size.Small}
                        color={Swatches.Primary}
                        text="Edit Student Details"
                      />
                    </Right>
                  </ActionBar>
                </Card.Footer>
              </Card>
              <StudentDetailsModal
                student={user as managedStudent}
                open={_detailsModalOpen}
                onSaved={val => handleStudentDetailsChanged(val)}
                onCancel={() => _setDetailsModalOpen(false)}
              />
            </>
          )}
          <Card title="Contact Details">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Email">
                  <a href={`mailto:${user.email}`}>
                    {user.email.toLowerCase()}
                  </a>
                </StructuredList.Item>
                <StructuredList.Item name="Address">
                  <Address address={user.address} />
                </StructuredList.Item>
                <StructuredList.Item name="Telephone Numbers">
                  <TelephoneNumberList numbers={user.telephoneNumbers} />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>

          {users.isStaff(user) && (
            <Card title="Car Registrations">
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="Car Registrations">
                    {arrays.isEmpty(user.carRegistrations) ? (
                      "No Car Registrations"
                    ) : (
                      <CarRegistrationList
                        registrations={user.carRegistrations}
                      />
                    )}
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
          )}

          {users.isParent(user) && (
            <>
              <Card title="Registration">
                <Card.Body>
                  <StructuredList>
                    <StructuredList.Item name="Registration Status">
                      {user.registeredDate != null ? (
                        <Chip
                          colorSwatch={Swatches.Success}
                          text="Registered"
                        />
                      ) : (
                        <Chip
                          colorSwatch={Swatches.Low}
                          text="Not Registered"
                        />
                      )}
                    </StructuredList.Item>
                    {user.registeredDate != null ? (
                      <StructuredList.Item name="Registration Date">
                        <DateTime date={user.registeredDate} bold />
                      </StructuredList.Item>
                    ) : null}
                  </StructuredList>
                </Card.Body>
              </Card>
            </>
          )}
        </Tabs.Pane>
        <Tabs.Pane label="Schools" hide={!users.isStaff(user)}>
          <Message
            text={
              actions.removeSchool?.error || actions.updateBaseSchool?.error
            }
            color={Swatches.Danger}
          />
          <Card>
            <Card.Body noPad>
              <Table>
                <Table.Header>
                  <Table.HeaderCell width={1} />
                  <Table.HeaderCell width={4}>School</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Staff Number</Table.HeaderCell>
                  <Table.HeaderCell width={2} />
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Chip text="Base School" colorSwatch={Swatches.Blue} />
                    </Table.Cell>
                    <Table.Cell>
                      <SchoolAvatar
                        school={user?.school}
                        sub={user.school?.code}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {types.isType<managedStaff>(user, "staffType") &&
                      user.school?.simsId
                        ? user.school?.simsId
                        : "Not Set"}
                    </Table.Cell>

                    <Table.Cell right>
                      <RoleView roles={[Constants.ROLES.DEVELOPMENT]}>
                        <SplitButton
                          size={Size.Small}
                          text="Edit"
                          color={Swatches.Default}
                          onDefaultClick={() =>
                            handleSchoolEditClicked({
                              ...user.school,
                            })
                          }
                          working={
                            actions?.updateBaseSchool?.working ||
                            actions?.removeSchool?.working
                          }
                        >
                          <SplitButton.Option
                            onClick={() => handleSchoolEditClicked(user.school)}
                            text="Edit"
                            show={true}
                          />
                          <SplitButton.Option
                            onClick={() =>
                              handleSchoolDeleteClicked(user.school)
                            }
                            text="Delete"
                            show={false}
                          />
                        </SplitButton>
                      </RoleView>
                    </Table.Cell>
                  </Table.Row>
                  <>
                    {!arrays.isEmpty(user.schools) &&
                      user.schools?.map((school, index) => (
                        <Table.Row key={index}>
                          <Table.Cell />
                          <Table.Cell>
                            <SchoolAvatar school={school} sub={school.code} />
                          </Table.Cell>
                          <Table.Cell>
                            {school.simsId ? `${school.simsId}` : "Not Set"}
                          </Table.Cell>
                          <Table.Cell right>
                            <RoleView roles={[Constants.ROLES.DEVELOPMENT]}>
                              <SplitButton
                                size={Size.Small}
                                text="Edit"
                                color={Swatches.Default}
                                working={
                                  actions?.updateBaseSchool?.working ||
                                  actions?.removeSchool?.working
                                }
                                onDefaultClick={() =>
                                  handleSchoolEditClicked({
                                    ...school,
                                  })
                                }
                              >
                                <SplitButton.Option
                                  onClick={() =>
                                    handleSchoolEditClicked(school)
                                  }
                                  text="Edit"
                                  show={true}
                                />
                                <SplitButton.Option
                                  onClick={() =>
                                    handleSetBaseSchoolClicked(school)
                                  }
                                  text="Set Base School"
                                  show={true}
                                />
                                <SplitButton.Option
                                  onClick={() =>
                                    handleSchoolDeleteClicked(school)
                                  }
                                  text="Delete"
                                  show={true}
                                  color={Swatches.Danger}
                                />
                              </SplitButton>
                            </RoleView>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </>
                </Table.Body>
              </Table>
            </Card.Body>
            <RoleView roles={[Constants.ROLES.DEVELOPMENT]}>
              <Card.Footer>
                <ActionBar low>
                  <Right>
                    <Button
                      onClick={handleAddSchoolClicked}
                      size={Size.Small}
                      color={Swatches.Primary}
                      text="Add School"
                      working={actions?.updateSchool?.working}
                    />
                  </Right>
                </ActionBar>
              </Card.Footer>
            </RoleView>
          </Card>
          <StaffSchoolsModal
            open={_schoolsModalOpen}
            onSaved={handleSchoolsChanged}
            onCancel={handleSchoolsModalClosed}
            user={user as managedStaff}
            school={_selectedSchool as ManagedStaffSchoolView}
          />
        </Tabs.Pane>
        <Tabs.Pane label="Parents" hide={!users.isStudent(user)}>
          {types.isType<managedStudent>(user, "parents") &&
            !arrays.isEmpty(user.parents) &&
            user.parents.map((parent, index) => (
              <Card key={index} title={<UserName user={parent} />}>
                <Card.Body>
                  <StructuredList>
                    <StructuredList.Item name="SIMS ID">
                      <Label>{parent.simsId}</Label>
                    </StructuredList.Item>
                    <StructuredList.Item name="Email">
                      <Email email={parent.email} />
                    </StructuredList.Item>
                    <StructuredList.Item name="Telephone">
                      <TelephoneNumberList numbers={parent.telephoneNumbers} />
                    </StructuredList.Item>
                    <StructuredList.Item name="Address">
                      <Address address={parent.address} />
                    </StructuredList.Item>
                    <StructuredList.Item>
                      <Button
                        size={Size.Small}
                        text="View Account"
                        color={Swatches.Primary}
                        onClick={() => handleAccountClick(parent)}
                      />
                    </StructuredList.Item>
                  </StructuredList>
                </Card.Body>
              </Card>
            ))}
        </Tabs.Pane>
        <Tabs.Pane label="Children" hide={!users.isParent(user)}>
          {types.isType<managedParent>(user, "children") &&
            !arrays.isEmpty(user.children) &&
            user.children.map((child, index) => (
              <Card key={index} title={<UserName user={child} />}>
                <Card.Body>
                  <StructuredList>
                    <StructuredList.Item name="Name">
                      <Avatar user={child} />
                    </StructuredList.Item>
                    <StructuredList.Item name="School">
                      <SchoolAvatar school={child.school} />
                    </StructuredList.Item>
                    <StructuredList.Item name="Admission Number">
                      <Label>{child.simsId}</Label>
                    </StructuredList.Item>
                    {child.dateOfBirth && (
                      <StructuredList.Item name="Date of Birth">
                        <Moment format="DD/MM/YYYY" date={child.dateOfBirth} />
                      </StructuredList.Item>
                    )}
                    <StructuredList.Item name="Email">
                      <Email email={child.email} />
                    </StructuredList.Item>

                    <StructuredList.Item name="Telephone">
                      <TelephoneNumberList numbers={child.telephoneNumbers} />
                    </StructuredList.Item>
                    <StructuredList.Item name="Address">
                      <Address address={child.address} />
                    </StructuredList.Item>
                    <StructuredList.Item>
                      <Button
                        size={Size.Small}
                        text="View Account"
                        color={Swatches.Primary}
                        onClick={() => handleAccountClick(child)}
                      />
                    </StructuredList.Item>
                  </StructuredList>
                </Card.Body>
              </Card>
            ))}
        </Tabs.Pane>
        <Tabs.Pane label="HR &amp; Employment" hide={!users.isStaff(user)}>
          <Card title="Employment Details">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Staff Type">
                  <ChipList>
                    <Chip text={user.staffType} />
                    {user.isTrust && (
                      <Chip text="Trust" colorSwatch={Swatches.Blue} />
                    )}
                  </ChipList>
                </StructuredList.Item>
                <StructuredList.Item name="Job Title">
                  {user.jobTitle}
                </StructuredList.Item>
                <StructuredList.Item name="Line Manager">
                  {user.lineManager && (
                    <Avatar
                      user={user.lineManager}
                      sub={user.lineManager.jobTitle}
                    />
                  )}
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
            <Card.Footer>
              <ActionBar low>
                <Right>
                  <Button
                    onClick={() => _setEmploymentModalOpen(true)}
                    size={Size.Small}
                    color={Swatches.Primary}
                    text="Change"
                  />
                </Right>
              </ActionBar>
            </Card.Footer>
          </Card>
          <Card title={`Holiday Card ${config.academicYear.currentName}`}>
            {holidayCard ? (
              <>
                <Card.Body>
                  <StructuredList>
                    <StructuredList.Item name="Holiday Entitlement">
                      {holidayCard.allowance} days
                    </StructuredList.Item>
                    <StructuredList.Item name="Carry Over">
                      {holidayCard.carryOver} days
                    </StructuredList.Item>
                  </StructuredList>
                </Card.Body>
                <Card.Footer>
                  <ActionBar low>
                    <Right>
                      <SplitButton
                        size={Size.Small}
                        color={Swatches.Primary}
                        text="Change"
                      >
                        <SplitButton.Option
                          onClick={() => _setHolidayCardModalOpen(true)}
                          text="Edit Holiday Card"
                          show={true}
                        />
                        <SplitButton.Option
                          onClick={() => viewHolidayCard()}
                          text="View Leave Record"
                          show={true}
                        />
                      </SplitButton>
                    </Right>
                  </ActionBar>
                </Card.Footer>
              </>
            ) : (
              <Card.Body>
                <EmptyMessage
                  icon="plane"
                  title="No Holiday Card"
                  summary={`This staff member does not have a holiday card for ${config.academicYear.currentName}`}
                  cover
                >
                  <Button
                    onClick={() => _setHolidayCardModalOpen(true)}
                    size={Size.Small}
                    color={Swatches.Primary}
                    text="New Holiday Card"
                  />
                </EmptyMessage>
              </Card.Body>
            )}
          </Card>
          <Card title="Managed Staff">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Managed Staff">
                  <List>
                    {user.staffManaged?.map((staff, index) => (
                      <List.Item
                        key={index}
                        left={<Avatar user={staff} sub={staff.jobTitle} />}
                      />
                    ))}
                  </List>
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
          <Card title="Departments">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item
                  name="Departments"
                  rightContent={
                    <Button
                      onClick={() => _setDepartmentsModalOpen(true)}
                      size={Size.Small}
                      color={Swatches.Primary}
                      text="Change"
                    />
                  }
                >
                  {arrays.isEmpty(user.departments) ? (
                    <p>No departments set</p>
                  ) : (
                    <List>
                      {user.departments.map((dept, index) => (
                        <List.Item
                          text={dept.name}
                          sub={dept.school.name}
                          left={
                            <SchoolIcon
                              code={dept.school.code}
                              size={Size.Small}
                            />
                          }
                        />
                      ))}
                    </List>
                  )}
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
          <StaffEmploymentModal
            user={user as managedStaff}
            open={_employmentModalOpen}
            onSaved={val => handleEmploymentDetailsChanged(val)}
            onCancel={() => _setEmploymentModalOpen(false)}
          />
          <StaffDepartmentsModal
            user={user as managedStaff}
            open={_departmentsModalOpen}
            onSaved={val => handleDepartmentsChanged(val)}
            onCancel={() => _setDepartmentsModalOpen(false)}
          />
        </Tabs.Pane>
        <Tabs.Pane label="Security &amp; Registration" hide={!isItAdmin()}>
          {user.lockedDate && (
            <Message
              text={`This account was locked on ${moment(
                user.lockedDate
              ).format("DD/MM/YYYY hh:mmA")}`}
              color={Swatches.Warning}
            />
          )}

          {user.securityFlag > 0 && (
            <Message
              text="This account has a compromised password."
              color={Swatches.Danger}
            />
          )}

          <Card title="Registration">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Registration Status">
                  {user.registeredDate != null ? (
                    <Chip colorSwatch={Swatches.Success} text="Registered" />
                  ) : (
                    <Chip colorSwatch={Swatches.Low} text="Not Registered" />
                  )}
                </StructuredList.Item>
                {user.registeredDate != null ? (
                  <StructuredList.Item name="Registration Date">
                    <DateTime date={user.registeredDate} bold />
                  </StructuredList.Item>
                ) : null}
              </StructuredList>
            </Card.Body>
          </Card>

          {users.isStaff(user) && (
            <>
              <Card title="Roles">
                <Card.Body>
                  <StructuredList>
                    <StructuredList.Item
                      name="Assigned Roles"
                      rightContent={
                        <Button
                          onClick={() => _setRolesModalOpen(true)}
                          size={Size.Small}
                          color={Swatches.Primary}
                          text="Change"
                        />
                      }
                    >
                      <RolesWrapper>
                        {user.roles.map((role, index) => (
                          <li key={index}>{role.name}</li>
                        ))}
                      </RolesWrapper>
                    </StructuredList.Item>
                  </StructuredList>
                </Card.Body>
              </Card>
              <StaffRolesModal
                open={_rolesModalOpen}
                onSaved={handleRolesChanged}
                onCancel={() => _setRolesModalOpen(false)}
              />
            </>
          )}

          <Subtitle text="Actions" />

          {user.lockedDate && (
            <ActionPanel
              label="Unlock Account"
              sub="This will unlock the user account"
              buttonText="Unlock Account"
              buttonColor={Swatches.Primary}
              onClick={handleUnlockAccount}
              status={actions.unlock}
            />
          )}

          {users.isParent(user) && (
            <>
              {user.registeredDate ? (
                <>
                  <ActionPanel
                    label="Send Reset Password Link"
                    sub="This will send the user a link to reset their password"
                    buttonText="Send Reset Password Link"
                    buttonColor={Swatches.Primary}
                    onClick={handleSendResetPasswordLink}
                    status={actions.sendPasswordReset}
                  />
                  <ActionPanel
                    label="Deregister User"
                    sub="This will deregister this user from Beehive and clear all user sessions across all devices"
                    buttonText="Deregister User"
                    buttonColor={Swatches.Primary}
                    onClick={handleDeregisterUser}
                    status={actions.deregister}
                  />
                </>
              ) : (
                <ActionPanel
                  label="Send Registration Link"
                  sub="This will send the user a link to activate their Beehive account"
                  buttonText="Send Registration Link"
                  buttonColor={Swatches.Primary}
                  onClick={handleSendRegistrationLink}
                  status={actions.sendRegistrationLink}
                />
              )}{" "}
            </>
          )}

          {user.registeredDate && (
            <ActionPanel
              label="Clear Sessions"
              sub="This will remove all user sessions on all devices for this user"
              buttonText="Clear Sessions"
              buttonColor={Swatches.Primary}
              onClick={handleClearSessions}
              status={actions.clearSessions}
            />
          )}

          {!arrays.isEmpty(user.sessions) && (
            <Card title="User Tokens">
              <Card.Body noPad>
                <Table>
                  <Table.Header>
                    <Table.HeaderCell width={8}>Token ID</Table.HeaderCell>
                    <Table.HeaderCell right width={1}>
                      Issued Date
                    </Table.HeaderCell>
                    <Table.HeaderCell right width={1}>
                      Expiry Date
                    </Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    {user.sessions.map((session, index) => (
                      <Table.Row key={index}>
                        <Table.Cell width={8}>
                          <Sub>{session.refreshTokenId}</Sub>
                        </Table.Cell>
                        <Table.Cell right width={1}>
                          <DateTime bold date={session.issuedDate} />
                        </Table.Cell>
                        <Table.Cell right width={1}>
                          <DateTime bold date={session.expiryDate} />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Card.Body>
            </Card>
          )}
        </Tabs.Pane>
        <Tabs.Pane label="Preferences" hide={!isItAdmin()}>
          <Message
            text={notificationPreferencesError}
            color={Swatches.Danger}
          />
          <Card title="Notification Preferences">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Low Balance Notifications">
                  <Checkbox
                    checked={_notificationPreferences?.lowBalanceNotifications}
                    text="Receive smartcard low balance notifications"
                    onChange={val =>
                      _setNotificationPreferences({
                        ..._notificationPreferences,
                        lowBalanceNotifications: val.checked,
                      })
                    }
                  />
                </StructuredList.Item>
                {users.isStaff(user) && (
                  <StructuredList.Item name="Managed Product Notifications">
                    <Checkbox
                      checked={_notificationPreferences?.productNotifications}
                      text="Receive managed product notifications"
                      onChange={val =>
                        _setNotificationPreferences({
                          ..._notificationPreferences,
                          productNotifications: val.checked,
                        })
                      }
                    />
                  </StructuredList.Item>
                )}
              </StructuredList>
            </Card.Body>
            <Card.Footer>
              <ActionBar low>
                <Right>
                  <Button
                    onClick={handleUpdateNotifications}
                    size={Size.Small}
                    color={Swatches.Success}
                    text="Save Notification Preferences"
                    working={settingNotificationPreferences}
                  />
                </Right>
              </ActionBar>
            </Card.Footer>
          </Card>
        </Tabs.Pane>
        <Tabs.Pane label="Log" hide={true}>
          <ActionPanel
            label="Download User Request Log"
            sub="This will download the user request log"
            buttonText="Download"
            buttonColor={Swatches.Primary}
            onClick={handleDownloadLog}
            status={actions.getUserRequestLog}
          />
        </Tabs.Pane>
        <Tabs.Pane label="Emails" hide={!isItAdmin()}>
          <QueueMessageTable
            messages={emails}
            user={user}
            loading={emailsLoading}
            error={emailsError}
            onPage={onEmailsPage}
            paging={emailsPaging}
            onRowClick={null}
          />
        </Tabs.Pane>
        <Tabs.Pane label="SMS" hide={!isItAdmin()}>
          <QueueMessageTable
            messages={smsMessages}
            user={user}
            loading={smsMessagesLoading}
            error={smsMessagesError}
            onPage={onSmsMessagesPage}
            paging={smsMessagesPaging}
            onRowClick={null}
          />
        </Tabs.Pane>
        <Tabs.Pane label="Sync" hide={!(isItAdmin() || isSyncAdmin())}>
          <UserSyncView/>        
        </Tabs.Pane>
      </Tabs>
      <EditHolidayCardModal
        open={_holidayCardModalOpen}
        onClose={() => _setHolidayCardModalOpen(false)}
        onSaved={card => handleHolidayCardSaved(card)}
        holidayCard={holidayCard}
        staffMember={user}
      />
    </>
  ) : null;
};

export default User;
