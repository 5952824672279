import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  StructuredList,
  Button,
  Dropdown,
  Size,
  Spacing,
  EmptyMessage,
  Swatches,
  Loader,
  Label,
  Chip,
  Title,
  DateTime,
  TitleSize,
  HeadlineStatistic,
  Table,
  Left,
  Sub,
  ActionBar,
  Right,
} from "ui-kit";
import { arrays } from "utils";
import { SchoolDropdown, SchoolIcon } from "sharedComponents/common";
import Subheader from "sharedComponents/layout/header/subheader";
import styled from "styled-components";
import BulletinPreviewModal from "./bulletinPreviewModal";
import BulletinSendModal from "./bulletinSendModal";
import BulletinTestSendModal from "./bulletinTestSendModal";
import bulletinActions from "areas/news/actions/bulletinActions";
import { bulletinRoutes } from "areas/news/newsRoutes";
import { RootState } from "reducers/store";
import { BulletinLinkAnalytic, BulletinSendHistoryStatisticsDetail, BulletinSendHistoryStatisticsSummary } from "areas/news/types/newsResponse.types";
import { SchoolListView } from "types/schools/schools.types";


const MainWrapper = styled.div`
  display: flex;
  flex-grow: 1;

  > .left {
    flex-grow: 1;
    margin-right: ${Spacing.Large}px;
  }

  > .right {
    width: 300px;

    .button {
      margin-bottom: ${Spacing.Medium}px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .table .sub a {
    color: ${Swatches.Blue.swatch};
  }
`;


const BulletinViewer = () => {

  const navigate = useNavigate();
  const { id: bulletinId } = useParams();
  const { bulletin, actions, filter } = useSelector(
    (state: RootState) => state.bulletinFull
  );

  const [selectedSendHistory, setSelectedSendHistory] = useState<BulletinSendHistoryStatisticsDetail | null>(null);
  const [_previewOpen, _setPreviewOpen] = useState<boolean>(false);
  const [_testSendOpen, _setTestSendOpen] = useState<boolean>(false);
  const [_sendOpen, _setSendOpen] = useState<boolean>(false);

  const getStatistics = (sendHistory: BulletinSendHistoryStatisticsSummary) => {
    bulletinActions.getBulletinSendHistoryStatistics(
      parseInt(bulletinId),
      sendHistory.id,
      filter,
      (data: BulletinSendHistoryStatisticsDetail) => {
        setSelectedSendHistory(data);
      }
    );
  };
  useEffect(() => {
    if (bulletinId != null) {
      bulletinActions.getBulletinFull(parseInt(bulletinId));
    }
  }, [bulletinId]);

  useEffect(() => {
    bulletin && selectedSendHistory && getStatistics(selectedSendHistory);
  }, [filter]);

  const handleFilterSchoolChanged = (value: number) => {
    bulletinActions.setBulletinFilter({
      ...filter,
      schoolId: value,
    });
  };

  const handleFilterUserTypeChanged = (value: number) => {
    bulletinActions.setBulletinFilter({ ...filter, userType: value });
  };

  const handleFilterStaffTypeChanged = (value: number | null) => {
    bulletinActions.setBulletinFilter({ ...filter, staffType: value });
  };

  const handleSendHistoryClick = (sendHistory: BulletinSendHistoryStatisticsSummary) => {
    getStatistics(sendHistory);
  };

  const percentageWithValue = (value: number, total: number) => {
    return value ? `${value} / ${percentage(value, total)}` : value;
  };
  const percentage = (value: number, total: number) => {
    return `${Math.round((value / total) * 100)}%`;
  };
  const handleSend = () => {};

  if (actions.load.working) {
    return <Loader cover size={Size.Medium} />;
  }

  if (actions.load.error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem loading the bulletin"
        cover
      />
    );
  }

  if (!bulletin) {
    return (
      <EmptyMessage
        icon="megaphone"
        title="Bulletin Not Found"
        summary="The requested bulletin was not found"
        cover
      />
    );
  }

  return (
    <>
      <Subheader>
        <Title size={TitleSize.H2} text="Bulletins" sub={bulletin?.name} />
      </Subheader>
      <MainWrapper>
        <Left>
          <Card>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Schools">
                  {bulletin.schools.map((school: SchoolListView, index: number) => (
                    <SchoolIcon code={school.code} key={index} />
                  ))}
                </StructuredList.Item>
                <StructuredList.Item name="Sends">
                  <Label>{bulletin.sendCount}</Label>
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
          {!arrays.isEmpty(bulletin.sendHistory) && (
            <Card title="Send History">
              <Card.Body noPad>
                <Table zebra>
                  <Table.Header>
                    <Table.HeaderCell width={1}>Type</Table.HeaderCell>
                    <Table.HeaderCell width={2.5}>Queued</Table.HeaderCell>
                    <Table.HeaderCell width={2.5}>Send After</Table.HeaderCell>
                    <Table.HeaderCell width={1} right>
                      Recipients
                    </Table.HeaderCell>
                    <Table.HeaderCell width={1} right>
                      Sent
                    </Table.HeaderCell>
                    <Table.HeaderCell width={2} />
                  </Table.Header>
                  <>
                  {bulletin.sendHistory.map((hist: BulletinSendHistoryStatisticsSummary, index: number) => (
                    <Table.Row
                      key={index}
                      highlight={selectedSendHistory?.id === hist.id}
                    >
                      <Table.Cell>
                        <Chip
                          text={!hist.sendType ? "Test" : "Live"}
                          colorSwatch={
                            !hist.sendType ? Swatches.Low : Swatches.Success
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <DateTime bold date={hist.queuedDate} />
                      </Table.Cell>
                      <Table.Cell>
                        <DateTime bold date={hist.sendAfter} />
                      </Table.Cell>

                      <Table.Cell right>
                        <Label>{hist.totalRecipients}</Label>
                      </Table.Cell>
                      <Table.Cell right>
                        <Label>
                          {percentageWithValue(
                            hist.totalSent,
                            hist.totalRecipients
                          )}
                        </Label>
                      </Table.Cell>
                      <Table.Cell right>
                        {selectedSendHistory?.id !== hist.id && (
                          <Button
                            size={Size.Small}
                            text="Data"
                            color={Swatches.Low}
                            onClick={() => handleSendHistoryClick(hist)}
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                  </>
                </Table>
              </Card.Body>
            </Card>
          )}

          {selectedSendHistory && (
            <>
              <ActionBar>
                <SchoolDropdown
                  initialValue={filter?.schoolId}
                  includeAllOption
                  onChange={handleFilterSchoolChanged}
                />
                <Dropdown
                  value={filter?.userType}
                  onChange={handleFilterUserTypeChanged}
                >
                  <Dropdown.Item value={null} label="All User Types" />
                  <Dropdown.Item value={1} label="Staff" />
                  <Dropdown.Item value={0} label="Parent" />
                  <Dropdown.Item value={2} label="Student" />
                </Dropdown>
                <Dropdown
                  value={filter?.staffType}
                  onChange={handleFilterStaffTypeChanged}
                >
                  <Dropdown.Item value={null} label="All Staff Types" />
                  <Dropdown.Item value={1} label="Associate" />
                  <Dropdown.Item value={0} label="Teaching" />
                </Dropdown>
              </ActionBar>
              <HeadlineStatistic>
                <HeadlineStatistic.Item
                  icon="users"
                  label="Recipients"
                  value={selectedSendHistory.totalRecipients}
                />
                <HeadlineStatistic.Item
                  icon="envelope"
                  label="Sent"
                  value={percentageWithValue(
                    selectedSendHistory.totalSent,
                    selectedSendHistory.totalRecipients
                  )}
                />
                <HeadlineStatistic.Item
                  icon="times-circle"
                  label="Failed"
                  value={percentageWithValue(
                    selectedSendHistory.totalFailed,
                    selectedSendHistory.totalRecipients
                  )}
                />
                <HeadlineStatistic.Item
                  icon="envelope-open"
                  label="Read"
                  value={percentageWithValue(
                    selectedSendHistory.totalRead,
                    selectedSendHistory.totalRecipients
                  )}
                />
                <HeadlineStatistic.Item
                  icon="bullseye-pointer"
                  label="Interacted"
                  value={percentageWithValue(
                    selectedSendHistory.totalInteracted,
                    selectedSendHistory.totalRecipients
                  )}
                />
              </HeadlineStatistic>

              <Card title="Interactions">
                {!selectedSendHistory.totalClicks ? (
                  <Card.Body>
                    <EmptyMessage
                      icon="megaphone"
                      title="No interactions recorded"
                      summary="No interactions have been recorded yet"
                      cover
                    />
                  </Card.Body>
                ) : (
                  <Card.Body noPad>
                    <Table>
                      <Table.Header>
                        <Table.HeaderCell width={8}>Link</Table.HeaderCell>
                        <Table.HeaderCell right width={1}>
                          Clicks
                        </Table.HeaderCell>
                        <Table.HeaderCell right width={1}>
                          %
                        </Table.HeaderCell>
                      </Table.Header>
                      <Table.Body>
                        {selectedSendHistory.linkAnalytics
                          .filter(x => x.clicks > 0)
                          .map((link: BulletinLinkAnalytic, index: number) => (
                            <Table.Row key={index}>
                              <Table.Cell>
                                <Sub>
                                  <a
                                    href={link.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {link.url}
                                  </a>
                                </Sub>
                              </Table.Cell>
                              <Table.Cell right>{link.clicks}</Table.Cell>
                              <Table.Cell right>
                                {percentage(
                                  link.clicks,
                                  selectedSendHistory.totalClicks
                                )}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                      </Table.Body>
                      <Table.Footer>
                        <Table.Row>
                          <Table.Cell>
                            <b>Total</b>
                          </Table.Cell>
                          <Table.Cell right>
                            <b>{selectedSendHistory.totalClicks}</b>
                          </Table.Cell>
                          <Table.Cell />
                        </Table.Row>
                      </Table.Footer>
                    </Table>
                  </Card.Body>
                )}
              </Card>

              <Card title="Information" low>
                <Card.Body>
                  <Sub>
                    <ul>
                      <li>
                        <b>Recipients:</b> The total number of unique users
                        receiving this send.
                      </li>
                      <li>
                        <b>Sent:</b> The total number of messages that have been
                        sent. This does not mean the message has been received.
                        This number will increase as messages are sent from the
                        message queue.
                      </li>
                      <li>
                        <b>Failed:</b> The number of messages that could not be
                        sent. This may for a number of reasons, such as a user
                        email was incorrect or there was a temporary issue with
                        Office 365.
                      </li>
                      <li>
                        <b>Read:</b> The number of messages that have been read.
                        This uses image tracking to determine if an email has
                        been opened, and relies on the recipient enabling image
                        downloads in their email client.
                      </li>
                      <li>
                        <b>Interacted:</b> The number of users that have clicked
                        on a link in the email.
                      </li>
                    </ul>
                  </Sub>
                </Card.Body>
              </Card>
            </>
          )}
        </Left>
        <Right>
          <Card>
            <Card.Body>
              <Button
                text="Edit"
                onClick={() =>
                  navigate(bulletinRoutes.getEditPath(bulletin.id))
                }
                color={Swatches.Blue}
                size={Size.Small}
              />
              <Button
                text="Preview"
                onClick={() => _setPreviewOpen(true)}
                color={Swatches.Low}
                working={actions.preview.working}
                size={Size.Small}
              />
              <Button
                text="Test Send"
                onClick={() => _setTestSendOpen(true)}
                color={Swatches.Low}
                working={actions.testSend.working}
                size={Size.Small}
              />
              <Button
                text="Send"
                onClick={() => _setSendOpen(true)}
                color={Swatches.Success}
                size={Size.Small}
              />
            </Card.Body>
          </Card>
        </Right>
      </MainWrapper>
      <BulletinPreviewModal
        bulletin={bulletin}
        open={_previewOpen}
        onClose={() => _setPreviewOpen(false)}
      />
      <BulletinTestSendModal
        bulletin={bulletin}
        open={_testSendOpen}
        onClose={() => _setTestSendOpen(false)}
        onSent={() => _setTestSendOpen(false)}
      />
      <BulletinSendModal
        bulletin={bulletin}
        open={_sendOpen}
        onClose={() => _setSendOpen(false)}
        onSent={() => _setSendOpen(false)}
      />
    </>
  );
};

export default BulletinViewer;
