import React from "react";
import styled from "styled-components";
import {
  typescale,
  displayFont,
  TitleSize,
  Spacing,
  neutral,
  honeygold,
} from "../index";

export interface ITitleProps {
  text: string | React.ReactElement;
  size?: TitleSize;
  sub?: string | React.ReactElement;
  color?: string;
  className?: string;
  children?: React.ReactElement;
  highlight?: boolean;
  right?: React.ReactElement | React.ReactElement[];
}

const getTypescaleSize = (titleSize: TitleSize) => {
  switch (titleSize) {
    case TitleSize.H1:
      return typescale.header1.size;
    case TitleSize.H2:
      return typescale.header2.size;
    case TitleSize.H3:
      return typescale.header3.size;
    case TitleSize.H4:
      return typescale.header4.size;
    case TitleSize.H5:
      return typescale.header5.size;
  }
};

export const getTypescaleLineHeight = (titleLineHeight: TitleSize) => {
  switch (titleLineHeight) {
    case TitleSize.H1:
      return typescale.header1.lineHeight;
    case TitleSize.H2:
      return typescale.header2.lineHeight;
    case TitleSize.H3:
      return typescale.header3.lineHeight;
    case TitleSize.H4:
      return typescale.header4.lineHeight;
    case TitleSize.H5:
      return typescale.header5.lineHeight;
  }
};

const Wrapper = styled.span<{
  as?: TitleSize;
  size?: TitleSize;
  color?: string;
  highlight?: boolean;
}>`
  ${props =>
    props.highlight &&
    `border-left: 4px solid ${honeygold}; 
    padding: 0 0 0 8px;`}

  ${({ color }) =>
    color === null ? `color: ${neutral[600]}` : `color: ${color};`}

  display: flex;
  justify-content: space-between;
  margin: 0 0
    ${props =>
      props.size === TitleSize.H1
        ? `${Spacing.Default}px`
        : `${Spacing.Default}px`}
    0;

  > div {
    width: 100%;
  }

  .title-main {
    font-size: ${({ size }) => (size ? getTypescaleSize(size) : 1)}rem;
    line-height: ${({ size }) => (size ? getTypescaleSize(size) : 1)}rem;
    font-family: "${displayFont.medium.name}";
    font-weight: ${displayFont.medium.weight};
    color: ${neutral[600]};
  }

  .title-right {
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-start;
  }
  .title-sub {
    display: flex;
    flex-grow: 1;
    font-size: ${({ size }) => (size ? getTypescaleSize(size) : 1)}rem;
    line-height: ${({ size }) => (size ? getTypescaleSize(size) : 1)}rem;
    font-family: "${displayFont.light.name}";
    font-weight: ${displayFont.light.weight};
    color: ${neutral[500]};

    .right {
    }
    .left {
    }
  }
  .title-additional {
    display: block;
    margin-top: ${Spacing.Small}px;
  }

  .title-right {
  }
`;

const Title: React.FC<ITitleProps> = ({
  text,
  sub,
  size,
  color,
  className,
  children,
  highlight,
  right,
}) => {
  return (
    <Wrapper
      className={`title ${className ? className : ""}`}
      as={size}
      size={size}
      color={color}
      highlight={highlight}
    >
      <>
        <div>
          <span className="title-main">{text}</span>
          {sub && <div className="title-sub">{sub}</div>}
          {children != null && (
            <span className="title-additional">{children}</span>
          )}
        </div>
        {right && <div className="title-right">{right}</div>}
      </>
    </Wrapper>
  );
};

Title.defaultProps = {
  size: TitleSize.H1,
};

export default Title;
