import React, { useState } from "react";
import { arrays } from "utils";
import LinkedTransactionsModal from "./linkedTransactionsModal";
import {
  Swatches,
  Currency,
  EmptyMessage,
  DateTime,
  Table,
  Sub,
  CopyToClipboard,
  Chip,
} from "ui-kit";
import { IncomingTransactionListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


interface IIncomingTransactionsTableProps {
  transactions: IncomingTransactionListView[];
}

const IncomingTransactionsTable: React.FC<IIncomingTransactionsTableProps> = ({ transactions }) => {
  
  const [_selectedTransaction, _setSelectedTransaction] = useState<IncomingTransactionListView | null>(null);

  return !arrays.isEmpty(transactions) ? (
    <>
      <Table zebra>
        <Table.Header>
          <Table.HeaderCell width={0.5}>ID</Table.HeaderCell>
          <Table.HeaderCell width={1}>Date</Table.HeaderCell>
          <Table.HeaderCell width={2.35}>
            Stripe Transaction ID
          </Table.HeaderCell>
          <Table.HeaderCell width={2.35}>
            Stripe Payment Intent ID
          </Table.HeaderCell>
          <Table.HeaderCell width={1.5}>Email</Table.HeaderCell>
          <Table.HeaderCell width={0.8} right>
            Total
          </Table.HeaderCell>
          <Table.HeaderCell width={0.5} center>
            Matched
          </Table.HeaderCell>
          <Table.HeaderCell width={1}></Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {transactions.map((transaction, index) => (
            <Table.Row
              key={index}
              onClick={() => _setSelectedTransaction(transaction)}
            >
              <Table.Cell>{transaction.id}</Table.Cell>
              <Table.Cell>
                <DateTime date={transaction.date} bold />
              </Table.Cell>
              <Table.Cell>
                <Sub>{transaction.transactionId}</Sub>
                <CopyToClipboard text={transaction.transactionId} />
              </Table.Cell>
              <Table.Cell>
                <Sub>{transaction.paymentIntent}</Sub>
                <CopyToClipboard text={transaction.paymentIntent} />
              </Table.Cell>

              <Table.Cell>
                <Sub>{transaction.email}</Sub>
              </Table.Cell>
              <Table.Cell right>
                <b>
                  <Currency value={transaction.total} />
                </b>
              </Table.Cell>
              <Table.Cell center>
                {transaction.linkedTransactions.length}
              </Table.Cell>
              <Table.Cell right>
                {transaction.linkedTransactions.length === 0 && (
                  <Chip fluid colorSwatch={Swatches.Danger} text="No Match" />
                )}
                {transaction.linkedTransactions.length === 1 && (
                  <Chip fluid colorSwatch={Swatches.Success} text="Matched" />
                )}
                {transaction.linkedTransactions.length > 1 && (
                  <Chip fluid colorSwatch={Swatches.Warning} text="Multiple" />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <LinkedTransactionsModal
        transaction={_selectedTransaction}
        onClose={() => _setSelectedTransaction(null)}
        open={_selectedTransaction != null}
      />
    </>
  ) : (
    <EmptyMessage
      icon="clipboard"
      title="No Transactions"
      summary="No new transactions were imported for this report"
      cover
    />
  );
};

export default IncomingTransactionsTable;
