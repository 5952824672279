import React, { useState, useEffect } from "react";
import { useSelector, useStore } from "react-redux";
import { Dropdown } from "ui-kit";
import SchoolIcon from "./icons/schoolIcon";
import { Constants } from "configuration";
import { RootState } from "reducers/store";
import { SchoolStartupView } from "types/schools/schools.types";
import { ISchool } from "configuration/constants.types";
import { ManagedStaffSchoolView } from "types/users/userSchools.types";

const ALL_OPTION = {
  key: -1,
  label: "All Schools",
  value: -1,
  sub: "LHT",
};

interface SchoolItem {
  label: string;
  value: number;
  sub: string;
}

type SchoolType<T> = T extends true ? ISchool : SchoolStartupView;

interface ISchoolDropdownProps<T extends boolean = false> {
  onChange?: (value: number) => void;
  fluid?: boolean;
  includeTrust?: boolean;
  placeholder?: string;
  includeAllOption?: boolean;
  filter?: (school: SchoolType<T>) => boolean;
  initialValue?: number;
  schools?: SchoolStartupView[] | ManagedStaffSchoolView[] | ISchool[];
  showAllSchools?: T;
}

const SchoolDropdown = <T extends boolean = false>({
  onChange,
  fluid,
  includeTrust,
  placeholder,
  includeAllOption,
  filter,
  initialValue,
  schools: initialSchools,
  showAllSchools,
}: ISchoolDropdownProps<T>) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const [schools, setSchools] = useState<SchoolItem[]>([]);
  const [selectedValue, setSelectedValue] = useState<number | null>(null);

  const getItemFromSchool = (school: ISchool | SchoolStartupView) : SchoolItem => {
    return {
      label: school.name,
      value: school.id,
      sub: school.code,
    };
  };


  const processSchools = (values: (ISchool | SchoolStartupView)[]) : SchoolItem[] => {

    var schoolValues: (ISchool | SchoolStartupView)[] = values != null ? values : [];
    var tempValues: SchoolItem[] = [];

    if (filter) {
      schoolValues = schoolValues.filter((value) => filter(value as SchoolType<T>));
    }

    tempValues = schoolValues
      // ?.sort((a, b) => a.name.localeCompare(b.name))
      .map(school => getItemFromSchool(school));

    if (includeTrust) {
      tempValues = [
        ...tempValues,
        {
          label: "Lionheart Educational Trust",
          value: 99,
          sub: "LHT",
        },
      ];
    }

    if (includeAllOption) {
      tempValues = [ALL_OPTION, ...tempValues];
    }

    return tempValues;
  };

  useEffect(() => {
    initialValue && setSelectedValue(initialValue);
  }, [initialValue]);

  // useEffect(() => {
  //   if (initialSchools) {
  //     setSchools(processSchools(initialSchools));
  //   }
  // }, [initialSchools]);

  useEffect(() => {
    if (user) {
      if (showAllSchools) {
        setSchools(processSchools(Constants.SCHOOLS));
      } else {
        var _schools = [];
        _schools = [...user.schools, user.baseSchool];
        setSchools(processSchools(_schools));
      }
    }
  }, []);

  const handleChange = (value: number, label: string) => {
    onChange?.(value);
  };

  return schools ? (
    <Dropdown
      placeholder={placeholder ? placeholder : "Please choose a school..."}
      value={selectedValue}
      onChange={handleChange}
      fluid={fluid}
    >
      {schools.map((school, index) => (
        <Dropdown.Item
          key={index}
          label={school.label}
          sub={school.sub}
          value={school.value}
          image={<SchoolIcon code={school.sub} />}
        />
      ))}
    </Dropdown>
  ) : (
    <p>No schools</p>
  );
};

export default SchoolDropdown;
