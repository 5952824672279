
import declarationsOfInterestActions from "areas/forms/actions/declarations/declarationsOfInterestActions";
import { DeclarationOfInterestListView, DeclarationOfInterestVersionDetailView, DeclarationOfInterestVersionListView } from "areas/forms/types/declarations/declarationsOfInterestResponse.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";
import { DeclarationVersionStatus } from "../../types/declarations/declarationsOfInterestShared.types";

interface IDeclarationsOfInterestState {
  declarations: DeclarationOfInterestListView[];
  loading: boolean;
  error: IBeehiveError;
  deleting: boolean;
  deleteError: IBeehiveError;
  creating: boolean;
  createError: IBeehiveError;
}

const INITIAL_STATE: IDeclarationsOfInterestState = {
  declarations: [],
  loading: false,
  error: null,
  deleting: false,
  deleteError: null,
  creating: false,
  createError: null
};


const declarationsOfInterestReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IDeclarationsOfInterestState => {

  const { 
    GETFORSTAFF, 
    CREATE_DECLARATION, 
    UPDATE_DECLARATION, 
    DELETE_DECLARATION, 
    SUBMIT_DECLARATION_VERSION, 
    DELETE_DECLARATION_VERSION,
    UPDATE_DECLARATION_VERSION,
    CLONE_DECLARATION_VERSION 
  } = declarationsOfInterestActions.types;

  switch (action.type) {

    case GETFORSTAFF.START:
      return { 
        ...state, 
        loading: true,
        error: null
      };
    case GETFORSTAFF.SUCCESS:
      return { 
        ...state, 
        loading: false, 
        declarations: action.payload
      };
    case GETFORSTAFF.FAILED:
      return { 
        ...state, 
        loading: false, 
        error: action.payload 
      };

    case DELETE_DECLARATION.START:
      return { 
        ...state, 
        deleting: true, 
        error: null
      };
    case DELETE_DECLARATION.SUCCESS:
      return { 
        ...state, 
        deleting: false,
        declarations: state.declarations.filter(x => x.id !== action.payload)
      };
    case DELETE_DECLARATION.START:
      return { 
        ...state, 
        deleting: false, 
        deleteError: action.payload
      };

    case CREATE_DECLARATION.START:
      return {
        ...state,
        creating: true,
      };
    case CREATE_DECLARATION.SUCCESS:
      return {
        ...state,
        creating: false,
        declarations: [action.payload, ...state.declarations],
      };
    case CREATE_DECLARATION.FAILED:
      return {
        ...state,
        creating: false,
        createError: action.payload,
      };

    case UPDATE_DECLARATION.SUCCESS:
      return {
        ...state,
        declarations: state.declarations?.map(x =>
          x.id === action.payload.id ? action.payload : x
        ),
      };

    case SUBMIT_DECLARATION_VERSION.SUCCESS:
      return { 
        ...state, 
        declarations: state.declarations?.map(x => x.id === action.payload 
          ? {
            ...x,
            versions: x.versions.map((version, index) => index === 0
              ? {
                ...version,
                status: DeclarationVersionStatus.Submitted,
                statusName: "Submitted"
              }
              : version
            )
          }
          : x
        )
      };

    case DELETE_DECLARATION_VERSION.START:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_DECLARATION_VERSION.SUCCESS:
      return {
        ...state,
        deleting: false,
        declarations: state.declarations?.map(declaration =>
          declaration.versions.some(v => v.id === action.payload)
          ? {
            ...declaration,
            versions: declaration.versions.filter(version => version.id !== action.payload)
          }
          : declaration
        )
      }
    case DELETE_DECLARATION_VERSION.FAILED:
      return {
        ...state,
        deleting: false,
        deleteError: action.payload
      }

    case UPDATE_DECLARATION_VERSION.SUCCESS:
      return {
        ...state,
        declarations: state.declarations.map(declaration => 
          action.payload.declarationId === declaration.id
          ? {
            ...declaration,
            versions: declaration.versions.map(version => 
              version.id === action.payload.id
              ? action.payload as DeclarationOfInterestVersionListView
              : version  
            )
          }
          : declaration
        )
      }

      case CLONE_DECLARATION_VERSION.SUCCESS:
        return {
          ...state,
          declarations: state.declarations.map(declaration => 
            action.payload.declarationId === declaration.id
            ? {
              ...declaration,
              versions: [action.payload as DeclarationOfInterestVersionListView, ...declaration.versions]
            }
            : declaration
          )
        }

    default:
      return state;
  }
};

export default declarationsOfInterestReducer;
