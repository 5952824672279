import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import paymentActions from "areas/payments/actions/paymentsActions";
import reportingActions from "areas/payments/actions/reportingActions";
import { useParams, Link } from "react-router-dom";
import {
  Loader,
  Size,
  EmptyMessage,
  Title,
  TitleSize,
  Table,
  Currency,
  Card,
  HeadlineStatistic,
  Button,
  Swatches
} from "ui-kit";
import { arrays, files } from "utils";
import CurrencyFormat from "react-currency-format";
import ProductOrdersTable from "../shopkeeper/products/productOrdersTable";
import Moment from "react-moment";
import { RootState } from "reducers/store";
import { BeehiveReport } from "types/common/action.types";


const ManagedProduct = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { product, loading, error } = useSelector((state: RootState) => state.managedProduct);
  const { orders, loading: loadingOrders, error: ordersError } = useSelector((state: RootState) => state.managedProductOrders);
  const { loading: reporting, error: reportError, reportData } = useSelector((state: RootState) => state.paymentsProductReports);
  const { productId, categoryId } = useParams();

  useEffect(() => {
    user &&
      paymentActions.getManagedProduct(user.id, parseInt(categoryId), parseInt(productId), () => {
        paymentActions.getManagedProductOrders(parseInt(categoryId), parseInt(productId));
      });
  }, []);

  const handleExportClicked = () => {
    reportingActions.runSingleProductExport(parseInt(categoryId), parseInt(productId), (reportData: BeehiveReport) => {
      files.openCsv(reportData);
    });
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem loading the product"
        cover
      />
    );
  }

  if (!product) {
    return (
      <EmptyMessage
        icon="shopping-bag"
        title="Not found"
        summary="The requested product was not found"
        cover
      />
    );
  }

  return (
    <>
      <Title
        size={TitleSize.H2}
        text={product.name}
        sub={product.category.name}
      />
      <HeadlineStatistic>
        <HeadlineStatistic.Item
          icon="tag"
          label="Price"
          value={
            <CurrencyFormat
              prefix={"£"}
              decimalScale={2}
              thousandSeparator=","
              fixedDecimalScale={true}
              displayType={"text"}
              value={product.unitPrice}
            />
          }
        />
        <HeadlineStatistic.Item icon="gift" label="Sold" value={product.sold} />
        {product.enforceStockControl && (
          <>
            <HeadlineStatistic.Item
              icon="boxes"
              label="Stock Limit"
              value={product.stock}
            />
            <HeadlineStatistic.Item
              icon="box-open"
              label="Remaining"
              value={product.stockRemaining}
            />
          </>
        )}
      </HeadlineStatistic>

      <ProductOrdersTable
        orders={orders}
        showPurchasedFor
        showCollection
        footer={
          <Table.Row>
            <Table.Cell />
            <Table.Cell right>
              <Button
                size={Size.Small}
                text="Export Orders"
                color={Swatches.Primary}
                onClick={handleExportClicked}
                working={reporting}
              />
            </Table.Cell>
          </Table.Row>
        }
      />

      {!arrays.isEmpty(product.instalmentRules) && (
        <>
          <Title size={TitleSize.H3} text="Instalments" />

          <Card>
            <Card.Body noPad>
              <Table>
                <Table.Header>
                  <Table.HeaderCell>Due Date</Table.HeaderCell>
                  <Table.HeaderCell right>Total</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                  {product.instalmentRules.map((instalment, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>
                        <Moment
                          date={instalment.dueDate}
                          format="dddd, MMMM Do YYYY"
                        />
                      </Table.Cell>
                      <Table.Cell right>
                        <b>
                          <Currency value={instalment.total} />
                        </b>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card.Body>
          </Card>
        </>
      )}

      <Card>
        <Card.Body>
          <p>
            If you require any changes to this product or for further
            information about orders, please contact{" "}
            <a href="mailto:beehiveproductrequests@lionhearttrust.org.uk">
              beehiveproductrequests@lionhearttrust.org.uk
            </a>
            . If you want to turn off email notifications for your managed
            products, please update your{" "}
            <Link to="/main/profile/notifications">Notification Settings</Link>.
          </p>
        </Card.Body>
      </Card>
    </>
  );
};

export default ManagedProduct;
