
export enum AssignmentQueryFilterType {
  All = 0,
  Current = 1,
  Expired = 2,
  Draft = 3,
  Complete = 4,
  DueToday = 5,
  DueTomorrow = 6,
  Overdue = 7,
  Unread = 8
}

export enum SurveyDifficulty {
  TooEasy = 0,
  Easy = 1,
  Ok = 2,
  Hard = 3,
  TooHard = 4
}

export enum SurveyTimescale {
  TooShort = 0,
  Ok = 1,
  TooLong = 2
}

export enum AssignmentCreator {
  All = 0,
  Self = 1,
  Others = 2
}