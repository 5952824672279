import { HeadlineStatistic } from "ui-kit";

const StudentHeadlineStats = ({ student }) => {
  return (
    <HeadlineStatistic>
      <HeadlineStatistic.Item
        icon="clipboard-check"
        value={`${student.pastoral.attendance}%`}
        label="Attendance"
      />
      <HeadlineStatistic.Item
        icon="clipboard"
        label="Lates"
        value={student.pastoral.lates}
      />
      <HeadlineStatistic.Item
        icon="clipboard"
        label="Absences"
        value={student.pastoral.absences}
      />
      <HeadlineStatistic.Item
        icon="thumbs-up"
        label="Rewards"
        value={student.pastoral.rewardPoints}
      />
      <HeadlineStatistic.Item
        icon="thumbs-down"
        label="Behaviours"
        value={student.pastoral.behaviourPoints}
      />
       <HeadlineStatistic.Item
        icon="shield-alt"
        label="Behaviour Count"
        value={student.totalBehaviourCount}
      />
    </HeadlineStatistic>
  );
};

export default StudentHeadlineStats;
