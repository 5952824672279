import React, { useState } from "react";
import { useSelector } from "react-redux";
import registrationActions from "areas/login/actions/registrationActions";
import {
  Wizard,
  TextInput,
  Message,
  Swatches,
  Size,
  Button,
  StructuredList,
  Card,
} from "ui-kit";
import { arrays } from "utils";
import { useNavigate } from "react-router";
import { RootState } from "reducers/store";

const RegistrationRequest = () => {
  const navigate = useNavigate();
  const { working, error } = useSelector(
    (state: RootState) => state.registration
  );
  const [email, setEmail] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const handleFinish = () => {
    return new Promise((resolve, reject) => {

      if (email == "" || !email) {
        reject("Please enter your email");
        return;
      }

      registrationActions.request(
        email,
        () => {
          resolve(true);
        },
        error => {
          reject(error.exceptionMessage);
        }
      );
    });
  };

  const goToLogin = () => {
    navigate("/");
  };

  return (
    <Wizard
      showHeaderSteps={false}
      onFinish={handleFinish}
      error={error}
      working={working}
      onCancel={goToLogin}
      finishButtonText="Send Activation Email"
    >
      <Wizard.Step>
        <Card>
          <Card.Body>
            <p>
              To start the activation process for Beehive, please enter your
              email address below. This must be the email that you have already
              given to your child's school.
            </p>
            <p>
              You will then receive an activation email. Please follow the
              instructions in the email to complete your Beehive account activation.
            </p>
          </Card.Body>
        </Card>

        {!arrays.isEmpty(validationErrors) && (
          <Message
            text={validationErrors.map((err, index) => (
              <React.Fragment key={index}>
                {err}
                <br />
              </React.Fragment>
            ))}
            color={Swatches.Danger}
          />
        )}

        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="Email" required>
                <TextInput
                  fluid
                  value={email}
                  placeholder="e.g. john@johnsmith.com"
                  onChange={value => setEmail(value)}
                  maxLength={256}
                />
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      </Wizard.Step>
      <Wizard.Step completion>
        <Card>
          <Card.Body>
            <p>
              Instructions on completing the registration process have now been
              sent to your email. Please allow a few minutes for the email to
              arrive
            </p>
            <p>
              If you do not receive this email within the next few minutes,
              please check your Junk Mail, Clutter or Spam folder.
            </p>
            <p>
              Once you have received the email, please follow the instructions
              in the email to complete your Beehive registration.
            </p>
            <Button
              text="Back to Login"
              onClick={goToLogin}
              size={Size.Large}
              color={Swatches.Primary}
            />
          </Card.Body>
        </Card>
      </Wizard.Step>
    </Wizard>
  );
};

export default RegistrationRequest;
