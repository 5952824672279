import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  Button,
  Right,
  ActionBar,
  Swatches,
  Size,
  StructuredList,
  Sub,
  Card,
  EmptyMessage,
  TextInput,
  Checkbox,
  Dropdown,
  Message,
  ToastService,
} from "ui-kit";
import userManagementActions from "areas/administration/actions/userManagementActions";
import StaffSelector from "sharedComponents/common/users/staffSelector";
import { managedStaff } from "areas/administration/reducers/userManagement/managedUserReducer";
import { RootState } from "reducers/store";
import { UpdateStaffDetailsCommand } from "areas/administration/types/userManagementRequest.types";
import { StaffListView } from "types/users/userListViews.types";


interface IStaffEmploymentModalProps {
  user: managedStaff;
  open: boolean;
  onCancel?: () => void;
  onSaved?: (user: managedStaff) => void;
}


const StaffEmploymentModal: React.FC<IStaffEmploymentModalProps> = ({ user, open, onCancel, onSaved }) => {

  const { actions } = useSelector((state: RootState) => state.managedUser);

  const [_user, _setUser] = useState<managedStaff | null>(null);
  const [_open, _setOpen] = useState<boolean>(false);

  useEffect(() => {
    _setUser(user);
  }, [user]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const handleSave = () => {
    const data: UpdateStaffDetailsCommand = {
      userId: _user.id,
      jobTitle: _user.jobTitle,
      lineManagerId: _user.lineManager?.id,
      staffType: _user.staffTypeId,
      isTrust: _user.isTrust,
      departments: []
    };

    userManagementActions.setStaffEmploymentDetails(_user.id, data, () => {
      onSaved?.(_user);
      ToastService.pop("Employment Details Updated", null, "user");
    });
  };

  const handleSetLineManager = (lineManager: StaffListView) => {
    _setUser({ ..._user, lineManager: lineManager });
  };

  return (
    <Modal title="Employment Details" open={_open} onClose={onCancel}>
      <Modal.Body>
        <Message text={actions.updateJob?.error} color={Swatches.Danger} />
        {_user ? (
          <Card>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Staff Type" required>
                  <Dropdown
                    value={_user.staffTypeId}
                    fluid
                    onChange={(val, label) =>
                      _setUser({ ..._user, staffTypeId: val, staffType: label })
                    }
                  >
                    <Dropdown.Item value={0} label="Teaching" />
                    <Dropdown.Item value={1} label="Associate" />
                  </Dropdown>
                </StructuredList.Item>
                <StructuredList.Item name="Trust Status" required>
                  <Checkbox
                    checked={_user.isTrust}
                    onChange={val =>
                      _setUser({ ..._user, isTrust: val.checked })
                    }
                    text="Is Trust Member of Staff"
                  />
                  <Sub>
                    If Trust status is updated then any associated accounts such
                    as Smartcard accounts should also be updated.
                  </Sub>
                </StructuredList.Item>
                <StructuredList.Item name="Job Title" required>
                  <TextInput
                    fluid
                    maxLength={50}
                    characterCount
                    value={_user.jobTitle}
                    onChange={val => _setUser({ ..._user, jobTitle: val })}
                  />
                </StructuredList.Item>
                <StructuredList.Item name="Line Manager">
                  <StaffSelector
                    user={_user.lineManager}
                    onChange={val => handleSetLineManager(val)}
                  />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
        ) : (
          <EmptyMessage
            icon="user"
            title="No Staff Member"
            summary="No staff member was selected"
            cover
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              onClick={onCancel}
              size={Size.Small}
              color={Swatches.Low}
              text="Cancel"
            />
            <Button
              onClick={handleSave}
              size={Size.Small}
              color={Swatches.Success}
              text="Save"
              working={actions.updateJob?.working}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default StaffEmploymentModal;
