import { GroupBy } from "../types/behaviourAnalyticsShared.types";
import { SenStatus } from "types/users/userSen.types";

export const GROUP_BY_ARRAY = [
  {
    label: "Teacher",
    value: GroupBy.Teacher,
  },
  {
    label: "Student",
    value: GroupBy.Pupil,
  },
  {
    label: "Student Gender",
    value: GroupBy.PupilGender,
  },
  {
    label: "Year Group",
    value: GroupBy.YearGroup,
  },
  {
    label: "Tutor Group",
    value: GroupBy.TutorGroup,
  },
  {
    label: "Subject",
    value: GroupBy.Subject,
  },
  {
    label: "EAL",
    value: GroupBy.EAL,
  },
  {
    label: "PP",
    value: GroupBy.PP,
  },
  {
    label: "Room",
    value: GroupBy.Room,
  },
  {
    label: "Behaviour Type",
    value: GroupBy.BehaviourType,
  },
  {
    label: "Behaviour Code",
    value: GroupBy.BehaviourCode,
  },
  {
    label: "Outcome",
    value: GroupBy.Outcome,
  },
  {
    label: "Awarded Date",
    value: GroupBy.AwardedDate,
  },
  {
    label: "SEN",
    value: GroupBy.SEN,
  },
  {
    label: "Detention Type",
    value: GroupBy.DetentionType,
  },
  {
    label: "Provision Group",
    value: GroupBy.ProvisionGroup,
  },
];

export interface SenType {
  id: SenStatus;
  text: string;
}

export const SEN_TYPES: SenType[] = [
  { id: SenStatus.N, text: "NO SEN" },
  { id: SenStatus.E, text: "EHCP" },
  { id: SenStatus.K, text: "SEN" },
  { id: SenStatus.KH, text: "SEN+" },
];