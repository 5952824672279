export const HALF_TERM = [
  {
    id: 0,
    value: "1",
  },
  {
    id: 1,
    value: "2",
  },
];

export const PROVISION_TYPE = {
  STANDARD: 0,
  CUSTOM: 1,
};

export enum PROVISION_GROUP_ACTION {
  CREATE_NEW = 0,
  CLONE = 1,
};