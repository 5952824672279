import { IBeehiveAction } from "types/common/action.types";
import sendStudentsActions from "../../actions/students/sendStudentsActions";

interface ISendStudentState {
  saving: boolean;
  error: string | null;
}

const INITIAL_STATE: ISendStudentState = {
  saving: false,
  error: null,
};


const sendStudentReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISendStudentState => {

  const { STUDENTS_UPDATESTAFF } = sendStudentsActions.types;

  switch (action.type) {
    case STUDENTS_UPDATESTAFF.START:
      return { ...state, error: null, saving: true };
    case STUDENTS_UPDATESTAFF.SUCCESS:
      return { ...state, saving: false };
    case STUDENTS_UPDATESTAFF.FAILED:
      return {
        ...state,
        saving: false,
        error: "There was a problem saving the student details.",
      };

    default:
      return state;
  }
};

export default sendStudentReducer;
