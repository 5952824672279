import {
  StructuredList,
  Card,
  ActionBar,
  Button,
  Swatches,
  Dropdown,
  RadioList,
  Size,
} from "ui-kit";
import DateRange from "../dateRange";
import Search, {
  SEARCH_GROUP_TYPE_KEYS,
} from "sharedComponents/common/search/search";
import MultiSchoolUserView from "sharedComponents/common/users/multiSchoolUserView";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import { ON_CALL_FILTERS } from "areas/behaviour/constants/onCall";
import AcademicYearFilter from "sharedComponents/common/academicYearFilter";
import { OnCallRequestsFilterExt } from "./onCallReportWrapper";
import moment from "moment";


interface IOnCallReportFormProps {
  schoolId: number;
  handleSchoolChange: (value: number) => void;
  reportCriteria: OnCallRequestsFilterExt;
  handleReportCriteriaChange: (criteria: OnCallRequestsFilterExt) => void;
  onCallCategories: {
    key: number;
    label: string;
    value: number;
  }[],
  rooms: {
    key: number;
    label: string;
    value: number;
  }[],
  handleGenerateReport: (exportReport: boolean) => void;
  loadingExportReport: boolean;
}


const OnCallReportForm: React.FC<IOnCallReportFormProps> = ({
  schoolId,
  handleSchoolChange,
  reportCriteria,
  handleReportCriteriaChange,
  onCallCategories,
  rooms,
  handleGenerateReport,
  loadingExportReport,
}) => {

  return (
    <>
      <MultiSchoolUserView>
        <Card title="School">
          <Card.Body>
            <SchoolDropdown
              onChange={value => handleSchoolChange(value)}
              initialValue={schoolId}
              fluid
            />
          </Card.Body>
        </Card>
      </MultiSchoolUserView>

      <Card title="Date Range">
        <Card.Body>
          <StructuredList.Item name="Academic Year" required>
            <AcademicYearFilter
              value={reportCriteria.academicYearId}
              onChange={value =>
                handleReportCriteriaChange({
                  ...reportCriteria,
                  academicYearId: value,
                })
              }
            />
          </StructuredList.Item>
          <DateRange
            startDate={reportCriteria.startDate}
            handleStartDateChange={value =>
              handleReportCriteriaChange({
                ...reportCriteria,
                startDate: value,
              })
            }
            endDate={reportCriteria.endDate}
            handleEndDateChange={value =>
              handleReportCriteriaChange({ ...reportCriteria, endDate: value })
            }
            required
          />
        </Card.Body>
      </Card>

      <Card title="Filters">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="On Call Category">
              <Dropdown
                placeholder="Category"
                fluid
                items={onCallCategories}
                onChange={value =>
                  handleReportCriteriaChange({
                    ...reportCriteria,
                    categoryId: value,
                  })
                }
                value={reportCriteria.categoryId}
                clearable
              />
            </StructuredList.Item>

            <StructuredList.Item name="On Call Status">
              <Dropdown
                placeholder="Status"
                fluid
                items={ON_CALL_FILTERS}
                onChange={value =>
                  handleReportCriteriaChange({
                    ...reportCriteria,
                    statusId: value,
                  })
                }
                value={reportCriteria.statusId}
                clearable
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Student"
              description="Filter to generate a report for only selected students"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.STUDENT]}
                selected={
                  reportCriteria.student ? [reportCriteria.student] : []
                }
                handleSelectItem={student =>
                  handleReportCriteriaChange({
                    ...reportCriteria,
                    student: student,
                  })
                }
                handleDeleteItem={() =>
                  handleReportCriteriaChange({
                    ...reportCriteria,
                    student: null,
                  })
                }
                schoolId={schoolId}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Class Group"
              description="Filter to generate a report for only selected tutor groups"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.CLASS_GROUP]}
                selected={
                  reportCriteria.classGroup ? [reportCriteria.classGroup] : []
                }
                handleSelectItem={student =>
                  handleReportCriteriaChange({
                    ...reportCriteria,
                    classGroup: student,
                  })
                }
                handleDeleteItem={() =>
                  handleReportCriteriaChange({
                    ...reportCriteria,
                    classGroup: null,
                  })
                }
                schoolId={schoolId}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Tutor Group"
              description="Filter to generate a report for only selected tutor groups"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.TUTOR_GROUP]}
                selected={
                  reportCriteria.tutorGroup ? [reportCriteria.tutorGroup] : []
                }
                handleSelectItem={student =>
                  handleReportCriteriaChange({
                    ...reportCriteria,
                    tutorGroup: student,
                  })
                }
                handleDeleteItem={() =>
                  handleReportCriteriaChange({
                    ...reportCriteria,
                    tutorGroup: null,
                  })
                }
                schoolId={schoolId}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Room"
              description="Filter to generate a report for on call requests made form only selected room."
              helpPopup
            >
              <Dropdown
                placeholder="Room"
                fluid
                items={rooms}
                onChange={value =>
                  handleReportCriteriaChange({
                    ...reportCriteria,
                    roomId: value,
                  })
                }
                value={reportCriteria.roomId}
                clearable
              />
            </StructuredList.Item>

            <StructuredList.Item name="Pupil Premium">
              <RadioList
                value={reportCriteria.pupilPremium}
                onChange={value =>
                  handleReportCriteriaChange({ ...reportCriteria, pupilPremium: value })
                }
              >
                <RadioList.Item
                  label="All Students"
                  value={null}
                />
                <RadioList.Item label="Pupil Premium" value={true} />
                <RadioList.Item
                  label="Not Pupil Premium"
                  value={false}
                />
              </RadioList>
            </StructuredList.Item>

            <StructuredList.Item name="SEN">
              <RadioList
                value={reportCriteria.sen}
                onChange={value =>
                  handleReportCriteriaChange({ ...reportCriteria, sen: value })
                }
              >
                <RadioList.Item
                  label="All Students"
                  value={null}
                />
                <RadioList.Item label="SEN" value={true} />
                <RadioList.Item label="Not SEN" value={false} />
              </RadioList>
            </StructuredList.Item>

            <StructuredList.Item name="Gender">
              <RadioList
                value={reportCriteria.gender}
                onChange={value =>
                  handleReportCriteriaChange({
                    ...reportCriteria,
                    gender: value,
                  })
                }
              >
                <RadioList.Item label="Both" value={null} />
                <RadioList.Item label="Female" value={1} />
                <RadioList.Item label="Male" value={0} />
              </RadioList>
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      <ActionBar>
        <Button
          text="Generate Report"
          color={Swatches.Success}
          size={Size.Small}
          disabled={
            reportCriteria.startDate === null || reportCriteria.endDate === null
          }
          onClick={() => handleGenerateReport(false)}
        />
        <Button
          color={Swatches.Success}
          text="Export Report as CSV"
          icon="file-download"
          size={Size.Small}
          disabled={
            reportCriteria.startDate === null || reportCriteria.endDate === null
          }
          onClick={() => handleGenerateReport(true)}
          working={loadingExportReport}
        />
      </ActionBar>
    </>
  );
};

export default OnCallReportForm;
