import React from "react";
import styled from "styled-components";
import {
  Title,
  TitleSize,
  Loader,
  Size,
  Card,
  EmptyMessage,
  Swatches,
  Tabs,
  DetailLabel,
  HtmlText,
  Left,
  Body,
  fontStyle,
  CircleIcon,
  textFont,
  typescale,
  neutral,
  honeygold,
  Spacing,
} from "ui-kit";
import Subheader from "sharedComponents/layout/header/subheader";

const Wrapper = styled.div`
  .reference .card-body {
    display: flex;
    flex-direction: row;
    .left {
      margin-right: ${Spacing.Medium}px;
    }
    .body {
      flxt-grow: 1;
    }
  }

  .reference-details {
    span span:first-child {
      margin-right: ${Spacing.Medium}px;
    }
  }

  .reference-description {
    margin-top: ${Spacing.Medium}px;
    max-width: auto;
    p,
    ul,
    ol {
      ${fontStyle(textFont.roman, typescale.sub, neutral[600])}
    }
  }

  .reference-link {
    display: block;
    margin-top: ${Spacing.Medium}px;

    ${fontStyle(textFont.roman, typescale.sub, honeygold)}

    &:hover {
      color: ${neutral[600]};
    }
  }
`;

const CourseContent = ({ course, loading, error, showTitle }) => {
  const getIcon = course => {
    switch (course.referenceType) {
      case 0:
        return "book";
      case 1:
        return "magazine";
      case 2:
        return "books"; // journal
      case 3:
        return "newspaper";
      case 4:
        return "tv-retro";
      case 5:
        return "radio";
      case 6:
        return "podcast";
      case 7:
        return "film";
      case 8:
        return "video";
      case 9:
        return "globe";
      case 10:
        return "bus";
      default:
        return null;
    }
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem loading the class information"
        icon="times-circle"
        color={Swatches.Danger}
        cover
      />
    );
  }

  if (course == null) {
    return (
      <EmptyMessage
        icon="users-class"
        title="No Course Resources"
        summary="No resources are available for this course"
        cover
      />
    );
  }

  return (
    <Wrapper>
      {showTitle && (
        <Subheader>
          <Title size={TitleSize.H2} text={course?.title} sub="Resources" />
        </Subheader>
      )}
      <Tabs>
        {course?.contentGroups.map((group, index) => (
          <Tabs.Pane key={index} label={group.category.name} value={index}>
            {group.references.map((ref, index) => (
              <Card className="reference" key={index}>
                <Card.Body>
                  <Left>
                    <CircleIcon value={getIcon(ref)} />
                  </Left>
                  <Body>
                    {(ref.author || ref.publisher || ref.title) && (
                      <DetailLabel
                        bold
                        className="reference-details"
                        label={ref.title}
                        sub={
                          <>
                            {ref.publisher && (
                              <span className="reference-publisher">
                                {ref.publisher}
                              </span>
                            )}
                            {ref.author && (
                              <span className="reference-author">
                                {ref.author}
                              </span>
                            )}
                          </>
                        }
                      />
                    )}
                    <HtmlText
                      html={ref.description}
                      fluid
                      className="reference-description"
                    />
                    {ref.url && (
                      <a
                        href={ref.url}
                        target="_blank"
                        rel="noreferrer"
                        className="reference-link"
                      >
                        {ref.url}
                      </a>
                    )}
                  </Body>
                </Card.Body>
              </Card>
            ))}
          </Tabs.Pane>
        ))}
      </Tabs>
    </Wrapper>
  );
};

export default CourseContent;
