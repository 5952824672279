import React, { useState } from "react";
import PropTypes from "prop-types";
import { Table } from "ui-kit";
import OnCallRequestsTableRow from "./onCallRequestsTableRow";
import { arrays } from "utils";
import { EmptyMessage } from "ui-kit";
import { OnCallRequestDetailView } from "areas/analytics/reducers/onCallAnalyticsResponse.types";
import { PageInfo } from "types/common/paging.types";


interface IOnCallRequestsTableProps {
  onCallRequests: OnCallRequestDetailView[];
  handleViewOnCallRequest: (onCallRequest: OnCallRequestDetailView, claimAndResolve?:boolean) => void;
  handleClaimOnCallRequest: (onCallRequest: OnCallRequestDetailView) => void;
  handleCancelRequestClick: (onCallRequest: OnCallRequestDetailView) => void;
  handleUpdateOnCallClick: (onCallRequest: OnCallRequestDetailView) => void;
  handlePage: () => void;
  paging: PageInfo;
  loading?: boolean;
  loadingAction?: boolean;
}


const OnCallRequestsTable: React.FC<IOnCallRequestsTableProps> = ({
  onCallRequests,
  handleViewOnCallRequest,
  handleClaimOnCallRequest,
  handleCancelRequestClick,
  handleUpdateOnCallClick,
  handlePage,
  paging,
  loading,
  loadingAction,
}) => {

  return (
    <Table
      grow
      loading={loading}
      empty={arrays.isEmpty(onCallRequests)}
      emptyMessage={
        <EmptyMessage
          icon="list"
          title="No On Call Requests"
          summary="There are no On Call requests for the selected school"
        />
      }
    >
      <Table.Header>
        <Table.HeaderCell width={0.5}>Status</Table.HeaderCell>
        {/* <Table.HeaderCell center>Student</Table.HeaderCell> */}
        <Table.HeaderCell width={1}>Requested By</Table.HeaderCell>
        <Table.HeaderCell width={1}>{"Student(s)"}</Table.HeaderCell>
        <Table.HeaderCell width={0.5}>Room</Table.HeaderCell>
        <Table.HeaderCell width={0.5}>Reason</Table.HeaderCell>
        <Table.HeaderCell width={1}>Claimed By</Table.HeaderCell>
        <Table.HeaderCell width={1}>Resolved By</Table.HeaderCell>
        <Table.HeaderCell width={1}>Cancelled By</Table.HeaderCell>
        <Table.HeaderCell width={1}></Table.HeaderCell>
      </Table.Header>

      <Table.Body onPage={handlePage} paging={paging}>
        {onCallRequests.map(request => (
          <OnCallRequestsTableRow
            key={request.id}
            onCallRequest={request}
            handleViewOnCallRequest={handleViewOnCallRequest}
            handleClaimOnCallRequest={handleClaimOnCallRequest}
            handleUpdateOnCallClick={handleUpdateOnCallClick}
            handleCancelRequestClick={handleCancelRequestClick}
            loadingAction={loadingAction}
          />
        ))}
      </Table.Body>
    </Table>
  );
};


export default OnCallRequestsTable;
