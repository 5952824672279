import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import store from "reducers/store";
import { BeehiveReport, IBeehiveAction, callbackType } from "types/common/action.types";
import { MatriculationSessionListView, MatriculationStudentDetailView, MatriculationStudentListView, PreadmissionListView } from "../types/matriculationResponses.types";
import { PagedQueryView } from "types/common/views.types";
import { matriculationFilter } from "../reducers/matriculationFilterReducer";
import { ChangeMatriculationSessionStatusCommand, CreateMatriculationSessionStatusCommand, ImportPreadmissionStudentsCommand, UploadPreadmissionPhotographCommand } from "../types/matriculationRequests.types";
import { ImportResultView } from "types/common/actionResult.types";

const prefix = "matriculation";
const builder = new actionTypeBuilder(prefix);

const types = {
  MATRICULATION_GETSESSIONS: builder.build("MATRICULATION_GETSESSIONS"),
  MATRICULATION_SESSION_GETSTUDENTS: builder.build(
    "MATRICULATION_SESSION_GETSTUDENTS"
  ),
  MATRICULATION_SESSION_GETPREADMISSIONS: builder.build(
    "MATRICULATION_SESSION_GETPREADMISSIONS"
  ),
  MATRICULATION_SESSION_RUNREPORT: builder.build(
    "MATRICULATION_SESSION_RUNREPORT"
  ),
  MATRICULATION_SESSION_GETSTUDENT: builder.build(
    "MATRICULATION_SESSION_GETSTUDENT"
  ),
  MATRICULATION_SESSION_RUNMIDASREPORT: builder.build(
    "MATRICULATION_SESSION_RUNMIDASREPORT"
  ),
  MATRICULATION_SESSION_UPLOADXML: builder.build(
    "MATRICULATION_SESSION_UPLOADXML"
  ),
  MATRICULATION_SESSION_GETPREADMISSION: builder.build(
    "MATRICULATION_SESSION_GETPREADMISSION"
  ),
  MATRICULATION_SEARCH_PREADMISSIONS: builder.build(
    "MATRICULATION_SEARCH_PREADMISSIONS"
  ),
  MATRICULATION_SESSION_UPLOADPHOTOGRAPH: builder.build(
    "MATRICULATION_SESSION_UPLOADPHOTOGRAPH"
  ),
  MATRICULATION_SESSION_CHANGESTATUS: builder.build(
    "MATRICULATION_SESSION_CHANGESTATUS"
  ),
  MATRICULATION_CREATENEWSESSION: builder.build(
    "MATRICULATION_CREATENEWSESSION"
  ),
  MATRICULATION_PROCESSSTUDENT: builder.build("MATRICULATION_PROCESSSTUDENT"),
  MATRICULATION_UPDATEFILTER: "MATRICULATION_UPDATEFILTER",
};


const getMatriculationSessions = (schoolId: number, callback?: callbackType<MatriculationSessionListView[]>) =>
  action<MatriculationSessionListView[]>(
    () => client.get(`/matriculation/schools/${schoolId}/sessions`),
    types.MATRICULATION_GETSESSIONS,
    callback
  );

const getSessionStudents = (schoolId: number, sessionId: number, pageIndex: number, callback?: callbackType<PagedQueryView<MatriculationStudentListView>>) =>
  action<PagedQueryView<MatriculationStudentListView>>(
    () => client.get(`/matriculation/schools/${schoolId}/sessions/${sessionId}/students?pageIndex=${pageIndex}&pageSize=50`),
    types.MATRICULATION_SESSION_GETSTUDENTS,
    callback
  );

const getSessionPreadmissions = (schoolId: number, sessionId: number, pageIndex: number, callback?: callbackType<PagedQueryView<PreadmissionListView>>) =>
  action<PagedQueryView<PreadmissionListView>>(
    () => client.get(`/matriculation/schools/${schoolId}/sessions/${sessionId}/preadmissions?pageIndex=${pageIndex}&pageSize=50`),
    types.MATRICULATION_SESSION_GETPREADMISSIONS,
    callback
  );

const updateMatriculationFilter = (filter: matriculationFilter) => {
  store.dispatch<IBeehiveAction<matriculationFilter>>({
    type: types.MATRICULATION_UPDATEFILTER,
    payload: filter,
  });
};

const runSessionReport = (schoolId: number, sessionId: number, callback?: callbackType<BeehiveReport>) => {
  return action<BeehiveReport>(
    () => client.get(`/matriculation/schools/${schoolId}/sessions/${sessionId}/report`, { responseType: "text" }),
    types.MATRICULATION_SESSION_RUNREPORT,
    callback
  );
};

const getMatriculationStudent = (schoolId: number, sessionId: number, studentId: string, callback?: callbackType<MatriculationStudentDetailView>) =>
  action<MatriculationStudentDetailView>(
    () => client.get(`/matriculation/schools/${schoolId}/sessions/${sessionId}/students/${studentId}`),
    types.MATRICULATION_SESSION_GETSTUDENT,
    callback
  );

const runMidasReport = (schoolId: number, sessionId: number, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.get(`/matriculation/schools/${schoolId}/sessions/${sessionId}/preadmissions/midas`, { responseType: "text" }),
    types.MATRICULATION_SESSION_RUNMIDASREPORT,
    callback
  );

const uploadPreadmissionXmlFile = (schoolId: number, sessionId: number, data: ImportPreadmissionStudentsCommand, callback?: callbackType<ImportResultView>) =>
  action<ImportResultView>(
    () => client.post(`/matriculation/schools/${schoolId}/sessions/${sessionId}/preadmissions/import`, data),
    types.MATRICULATION_SESSION_UPLOADXML,
    callback
  );

const getPreadmissionStudent = (schoolId: number, sessionId: number, preadmissionId: string, callback?: callbackType<PreadmissionListView>) =>
  action<PreadmissionListView>(
    () => client.get(`/matriculation/schools/${schoolId}/sessions/${sessionId}/preadmissions/${preadmissionId}`),
    types.MATRICULATION_SESSION_GETPREADMISSION,
    callback
  );

const searchPreadmissionStudents = (schoolId: number, sessionId: number, searchTerm: string, callback?: callbackType<PreadmissionListView[]>) =>
  action<PreadmissionListView[]>(
    () => client.get(`matriculation/schools/${schoolId}/sessions/${sessionId}/preadmissions/search?searchTerm=${searchTerm}`),
    types.MATRICULATION_SEARCH_PREADMISSIONS,
    callback
  );

const uploadPhotograph = (schoolId: number, sessionId: number, data: UploadPreadmissionPhotographCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`matriculation/schools/${schoolId}/sessions/${sessionId}/preadmissions/photograph`, data),
    types.MATRICULATION_SESSION_UPLOADPHOTOGRAPH,
    callback
  );

const changeSessionStatus = (schoolId: number, sessionId: number, data: ChangeMatriculationSessionStatusCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`matriculation/schools/${schoolId}/sessions/${sessionId}/status`, data),
    types.MATRICULATION_SESSION_CHANGESTATUS,
    callback
  );

const createNewSession = (schoolId: number, data: CreateMatriculationSessionStatusCommand, callback?: callbackType) =>
  action(
    () => client.post(`matriculation/schools/${schoolId}/sessions`, data),
    types.MATRICULATION_CREATENEWSESSION,
    callback
  );

const processStudent = (schoolId: number, sessionId: number, studentId: string, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`matriculation/schools/${schoolId}/sessions/${sessionId}/students/${studentId}/process`),
    types.MATRICULATION_PROCESSSTUDENT,
    callback
  );


const matriculationActions = {
  types,
  getMatriculationSessions,
  getSessionStudents,
  getSessionPreadmissions,
  updateMatriculationFilter,
  runSessionReport,
  getMatriculationStudent,
  runMidasReport,
  uploadPreadmissionXmlFile,
  getPreadmissionStudent,
  searchPreadmissionStudents,
  uploadPhotograph,
  changeSessionStatus,
  createNewSession,
  processStudent,
};

export default matriculationActions;
