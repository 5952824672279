import React, { useEffect, useState } from "react";
import {
  Modal,
  ActionBar,
  Button,
  Size,
  Swatches,
  Card,
  StructuredList,
  Left,
  Right,
  Message,
  ValidationMessage,
  ToastService,
  Sub,
  Dropdown,
} from "ui-kit";
import { arrays } from "utils";
import { useSelector } from "react-redux";
import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import calendarActions from "areas/calendar/actions/calendarActions";
import { EventRegister } from "./eventDateRegisterModal";
import { CalendarEventDateListView } from "areas/calendar/types/calendarResponses.types";
import { RootState } from "reducers/store";
import { BehaviourCategoryGroupByListView, SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";


interface IIssueRewardsModalProps {
  eventDate: CalendarEventDateListView;
  register: EventRegister;
  open: boolean;
  onClose?: () => void;
  onSave: (students: string[], selectedReward: SchoolBehaviourCategoryListView) => void; 
}


const IssueRewardsModal: React.FC<IIssueRewardsModalProps> = ({ eventDate, register, open, onClose, onSave }) => {

  const [_open, _setOpen] = useState<boolean>(open);
  const [_eventDate, _setEventDate] = useState<CalendarEventDateListView>(eventDate);
  const [_register, _setRegister] = useState<EventRegister>(register);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_selectedReward, _setSelectedReward] = useState<SchoolBehaviourCategoryListView | null>(null);
  const [_flattenedRewards, _setFlattenedRewards] = useState<SchoolBehaviourCategoryListView[]>([]);

  const { calendarEvent, rewarding, rewardError } = useSelector((state: RootState) => state.calendarEvent);
  const { rewardCategoryGroups, getRewardsError } = useSelector((state: RootState) => state.rewardCategories);

  useEffect(() => {
    _setOpen(open);

    // This is set to Beauchamp as a fudge
    rewardActions.getRewardCategories(
      1, 
      {
        isLegacy: null, 
        canBeAwarded: true
      }, 
      (data: BehaviourCategoryGroupByListView[]) => {
      var array = [];

      for (var i = 0; i < data.length; i++) {
        for (var j = 0; j < data[i].behaviourCategories.length; j++) {
          array.push(data[i].behaviourCategories[j]);
        }
      }

      _setFlattenedRewards(array);
    });
  }, [open]);

  useEffect(() => {
    _setRegister(register);
  }, [register]);

  useEffect(() => {
    _setEventDate(eventDate);

    setValidationErrors([]);
  }, [eventDate]);

  const handleSave = () => {
    var errors = [];

    if (!_selectedReward) {
      errors.push(`Please choose a reward to issue.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    if (
      window.confirm(
        `This will issue rewards to all students who have not yet had one issued for this event date. Are you sure?`
      )
    ) {
      const data = {
        eventId: calendarEvent.id,
        eventDateId: _eventDate.id,
        schoolBehaviourCategoryId: _selectedReward.id,
        students: _register.attendees
          .filter(x => 
            x.attendance == 1 && 
            x.rewardId == null && 
            x.user.userType.toLowerCase() === "student"
          )
          .map(x => x.user.id),
      };

      calendarActions.issueRewardsForCalendarEventDate(data, id => {
        ToastService.pop("Rewards issued successfully", null, "calendar");
        onSave(data.students, _selectedReward);
      });
    }
  };

  return _eventDate ? (
    <Modal
      title="Issue Rewards"
      open={_open}
      onClose={onClose}
      width="50%"
      height="40%"
    >
      <Modal.Body scrollOnErrors={validationErrors}>
        <ValidationMessage errors={validationErrors} />
        <Message text={rewardError} color={Swatches.Danger} />
        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item
                name="Reward"
                description="Please choose a reward to issue."
                required
              >
                <Dropdown
                  value={_selectedReward}
                  onChange={(value: SchoolBehaviourCategoryListView)=> _setSelectedReward(value)}
                  fluid
                >
                  {_flattenedRewards?.map((reward: SchoolBehaviourCategoryListView, i: number) => (
                    <Dropdown.Item
                      key={i}
                      value={reward}
                      label={`${reward.codeDisplayName} - ${reward.behaviourCategoryName}`}
                    />
                  ))}
                </Dropdown>
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Sub>
              This will issue the selected reward to all students who attended
              the event date but who have not yet had a reward issued for
              attendance. The reward is just for this date and not for any other
              dates set for this event.
            </Sub>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Left>
            <Button
              text="Close"
              onClick={onClose}
              color={Swatches.Low}
              size={Size.Small}
              working={rewarding}
            />
          </Left>
          <Right>
            <Button
              text="Issue Rewards"
              onClick={handleSave}
              color={Swatches.Success}
              size={Size.Small}
              working={rewarding}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  ) : null;
};

export default IssueRewardsModal;
