import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import store from "reducers/store";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { BasketDetailView, OrderDetailView, OrderListView, ProductListView, UserProductDetailLegacyView } from "../types/shopkeeper/shopkeeperResponses.types";
import { LunchCreditBalanceView } from "../types/catering/cateringResponses.types";
import { AddLunchCreditToBasketCommand, AddPrintCreditToBasketCommand, AddProductToBasketCommand, AddSmartcardTopupToBasketCommand, TransferToPaymentProviderCommand } from "../types/shopkeeper/shopkeeperRequests.types";

const builder = new actionTypeBuilder("store");

const storeTypes = {
  STORE_GETPRODUCTS: builder.build("STORE_GETPRODUCTS"),
  STORE_GETPRODUCT: builder.build("STORE_GETPRODUCT"),
  STORE_GETORDERS: builder.build("STORE_GETORDERS"),
  STORE_GETORDER: builder.build("STORE_GETORDER"),
  STORE_GETLUNCHCREDITBALANCE: builder.build("STORE_GETLUNCHCREDITBALANCE"),
  BASKET_GET: builder.build("BASKET_GET"),
  BASKET_REMOVEITEM: builder.build("BASKET_REMOVEITEM"),
  BASKET_ADDPRODUCT: builder.build("BASKET_ADDPRODUCT"),
  BASKET_ADDTOPUP: builder.build("BASKET_ADDTOPUP"),
  BASKET_ADDLUNCHCREDIT: builder.build("BASKET_ADDLUNCHCREDIT"),
  BASKET_ADDPRINTCREDIT: builder.build("BASKET_ADDPRINTCREDIT"),
  CHECKOUT_START: builder.build("CHECKOUT_START"),
  CHECKOUT_CLOSE: "CHECKOUT_CLOSE",
  CHECKOUT_CANCEL: "CHECKOUT_CANCEL",
  CHECKOUT_RESET: "CHECKOUT_RESET",
  CHECKOUT_DATA: builder.build("CHECKOUT_DATA"),
};


const getProductsForUser = (userId: string, callback?: callbackType<ProductListView[]>) =>
  action<ProductListView[]>(
    () => client.get(`payments/users/${userId}/products`),
    storeTypes.STORE_GETPRODUCTS,
    callback
  );


const getProductForUser = (userId: string, productId: number, excludeDiscount: boolean = false, callback?: callbackType<UserProductDetailLegacyView>) =>
  action<UserProductDetailLegacyView>(
    () => client.get(`payments/users/${userId}/products/${productId}?excludeDiscount=${excludeDiscount}`),
    storeTypes.STORE_GETPRODUCT,
    callback
  );


const getOrdersForUser = (userId: string, callback?: callbackType<OrderListView[]>) =>
  action<OrderListView[]>(
    () => client.get(`payments/users/${userId}/orders`),
    storeTypes.STORE_GETORDERS,
    callback
  );


const getOrderForUser = (userId: string, orderId: number, callback?: callbackType<OrderDetailView>) =>
  action<OrderDetailView>(
    () => client.get(`payments/users/${userId}/orders/${orderId}`),
    storeTypes.STORE_GETORDER,
    callback
  );


const getLunchCreditBalanceForUser = (userId: string, callback?: callbackType<LunchCreditBalanceView[]>) =>
  action<LunchCreditBalanceView[]>(
    () => client.get(`payments/users/${userId}/lunchcredits/balance`),
    storeTypes.STORE_GETLUNCHCREDITBALANCE,
    callback
  );


const getBasket = (userId: string, callback?: callbackType<BasketDetailView>) =>
  action<BasketDetailView>(
    () => client.get(`payments/users/${userId}/basket`), 
    storeTypes.BASKET_GET, 
    callback
  );


const removeItemFromBasket = (userId: string, basketItemId: number, callback?: callbackType<null>) =>
  action<null>(
    () => client.delete(`payments/users/${userId}/basket/items/${basketItemId}`),
    storeTypes.BASKET_REMOVEITEM,
    callback
  );


const addProductToBasket = (userId: string, data: AddProductToBasketCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`payments/users/${userId}/basket/products`, data),
    storeTypes.BASKET_ADDPRODUCT,
    callback
  );


const addTopupToBasket = (userId: string, data: AddSmartcardTopupToBasketCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`payments/users/${userId}/basket/smartcards`, data),
    storeTypes.BASKET_ADDTOPUP,
    callback
  );


const addPrintCreditToBasket = (userId: string, data: AddPrintCreditToBasketCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`payments/users/${userId}/basket/printcredits`, data),
    storeTypes.BASKET_ADDPRINTCREDIT,
    callback
  );

  
const addLunchCreditToBasket = (userId: string, data: AddLunchCreditToBasketCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`payments/users/${userId}/basket/lunchcredits`, data),
    storeTypes.BASKET_ADDLUNCHCREDIT,
    callback
  );


const startCheckout = (userId: string, data: TransferToPaymentProviderCommand, callback?: callbackType<string>) =>
  action<string>(
    () => client.post(`payments/users/${userId}/basket/checkout`, data),
    storeTypes.CHECKOUT_START,
    callback
  );


const cancelCheckout = () => {
  store.dispatch<IBeehiveAction<null>>({
    type: storeTypes.CHECKOUT_CANCEL,
  });
};


const closeCheckout = () => {
  store.dispatch<IBeehiveAction<null>>({
    type: storeTypes.CHECKOUT_CLOSE,
  });
};


const resetCheckout = () => {
  store.dispatch<IBeehiveAction<null>>({
    type: storeTypes.CHECKOUT_RESET,
  });
};


const storeActions = {
  storeTypes,
  getProductsForUser,
  getProductForUser,
  getOrdersForUser,
  getOrderForUser,
  getLunchCreditBalanceForUser,
  getBasket,
  removeItemFromBasket,
  addProductToBasket,
  addTopupToBasket,
  addPrintCreditToBasket,
  addLunchCreditToBasket,
  startCheckout,
  cancelCheckout,
  closeCheckout,
  resetCheckout
}

export default storeActions;