import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";

export interface ISchoolViewProps {
  children?: React.ReactElement | React.ReactElement[];
}

const MultiSchoolUserView: React.FC<ISchoolViewProps> = ({ children }) => {
  const { user } = useSelector((state: RootState) => state.currentUser);
  
  if (user && user.schools?.length > 0) {
    return (
      <>{children}</>
    )
   } else {
    return (
      <></>
    )
  }
};

export default MultiSchoolUserView;
