import { DocumentGroupDetailView } from "areas/administration/types/documentResponse.types";
import documentGroupActions from "../../actions/documentGroupActions";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


interface IDocumentGroupsState {
  groups: DocumentGroupDetailView[],
  error: string | null;
  loading: boolean;
  deleting: boolean;
  deleteError: string | null;
  runningReport: boolean;
  reportError: IBeehiveError;
}

const INITIAL_STATE: IDocumentGroupsState = {
  groups: [],
  error: null,
  loading: false,
  deleting: false,
  deleteError: null,
  runningReport: false,
  reportError: null
};


const documentGroupsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IDocumentGroupsState => {

  const { DOCUMENTGROUPS_GETALL, DOCUMENTGROUPS_DELETE, DOCUMENTGROUPS_RUNREADDATEREPORT } =
    documentGroupActions.types;

  switch (action.type) {
    case DOCUMENTGROUPS_GETALL.START:
      return { ...state, loading: true, error: null };

    case DOCUMENTGROUPS_GETALL.SUCCESS:
      return { ...state, groups: action.payload, loading: false };

    case DOCUMENTGROUPS_GETALL.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DOCUMENTGROUPS_DELETE.START:
      return { ...state, deleting: true, deleteError: null };

    case DOCUMENTGROUPS_DELETE.SUCCESS:
      return { ...state, deleting: false };

    case DOCUMENTGROUPS_DELETE.FAILED:
      return {
        ...state,
        deleting: false,
        deleteError: "There was a problem deleting the document group.",
      };

    case DOCUMENTGROUPS_RUNREADDATEREPORT.START:
      return {
        ...state,
        runningReport: true,
        reportError: null
      }
    case DOCUMENTGROUPS_RUNREADDATEREPORT.SUCCESS:
      return {
        ...state,
        runningReport: false
      }
    case DOCUMENTGROUPS_RUNREADDATEREPORT.FAILED:
      return {
        ...state,
        runningReport: false,
        reportError: action.payload
      }

    default:
      return state;
  }
};

export default documentGroupsReducer;
