import React from "react";
import styled from "styled-components";
import { 
  Size, 
  neutral, 
  Spacing, 
  DetailLabel 
} from "ui-kit";
import SchoolIcon from "./icons/schoolIcon";
import { IAvatarProps } from "../users/avatar";

const Wrapper = styled.div<{isPortrait?: boolean}>`
  display: flex;
  align-items: center;

  .label {
    color: ${neutral[700]};
  }

  ${({ isPortrait }) =>
    isPortrait
      ? `
    flex-direction: column;
    justify-content: center;

    .circle-icon {
      margin-bottom: ${Spacing.Default}px;
    }

    .inner {
      text-align: left;
    }
  `
      : `
  .circle-icon {
  }
  `}
  .inner {
    margin-left: ${Spacing.Default}px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    text-align: left;

    .detail-label span {
      white-space: pre !important;
    }
  }

  ${({ onClick }) =>
    onClick &&
    `
    &:hover {
      cursor: pointer;
    }
  `}
`;

export interface ISchoolProps extends IAvatarProps {
  school: SchoolObj;
  label?: string;
  short?: boolean;
  sub?: string;
}

type SchoolObj = {
  code: string,
  name: string
};

const School: React.FC<ISchoolProps> = ({
  school,
  portrait,
  user,
  sub,
  size = Size.Small,
  onClick,
  label,
  short,
}) => {

  // const handleClick = () => {
  //   onClick?.();
  // };

  return school ? (
    <Wrapper isPortrait={portrait} onClick={onClick}>
      {school && <SchoolIcon code={school?.code} size={size} />}
      <div className="inner">
        <DetailLabel
          label={label ? label : short ? school?.code : school?.name}
          sub={!sub && !short ? school?.code : sub}
          bold
        />
      </div>
    </Wrapper>
  ) : null;
};

School.defaultProps = {
  portrait: false,
};

export default School;
