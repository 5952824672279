import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Icon, honeygold, Spacing } from "../index";

export interface IVideoProps {
  controls?: boolean;
  src: string;
  className?: string;
}

const Wrapper = styled.div`
  position: relative;

  .video-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 100;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255,255,255,0.3);
        border-radius: 10px;
        padding: ${Spacing.Large}px;
        transition: all ease 150ms;
        i { color white; font-size: 35px;}
        width: 80px;
    }

    &:hover {
        cursor: pointer;
        span {
            background: ${honeygold};
        }
    }
  }
`;

const Video: React.FC<IVideoProps> = ({ src, controls, className }) => {
  const [paused, setPaused] = useState(true);

  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlay = () => {
    setPaused(false);
  };

  const handlePause = (e: SyntheticEvent<HTMLVideoElement>) => {
    setPaused(true);
  };

  useEffect(() => {
    !paused && videoRef.current && videoRef.current.play();
  }, [paused]);

  return (
    <Wrapper className={`video ${className ? className : ""}`}>
      {paused && (
        <div className="video-overlay" onClick={handlePlay}>
          <span>
            <Icon value="play" />
          </span>
        </div>
      )}
      <video
        ref={videoRef}
        src={src}
        controls={!paused}
        onPause={handlePause}
      />
    </Wrapper>
  );
};

export default Video;
