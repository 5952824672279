import { useEffect, useState } from "react";
import SmartcardCreditFilter from "./smartcardCreditFilter";
import CreditsTable from "./creditsTable";
import { useSelector } from "react-redux";
import paymentActions from "areas/payments/actions/paymentsActions";
import flyoutActions from "actions/ui/flyouts";
import SmartcardCreditFlyout from "./smartcardCreditFlyout";
import { Constants } from "configuration";
import { RootState } from "reducers/store";
import { SmartcardSyncResultListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


const Smartcards = () => {

  const { credits, paging, loading, error, working, workingError } = useSelector((state: RootState) => state.smartcardCredits);
  const { filter } = useSelector((state: RootState) => state.smartcardCreditFilter);
  const [selectedCredit, setSelectedCredit] = useState<SmartcardSyncResultListView | null>(null);


  const loadCredits = (pageIndex: number) => {
    paymentActions.getSmartcardCredits(filter, pageIndex);
  };

  useEffect(() => {
    loadCredits(0);
  }, [filter]);

  const handlePage = () => {
    loadCredits(paging.pageIndex + 1);
  };

  const handleRowClicked = (credit: SmartcardSyncResultListView) => {
    setSelectedCredit(credit);
    flyoutActions.openFlyout(Constants.FLYOUTS.CREDIT);
  };

  return (
    <>
      <SmartcardCreditFilter />
      <CreditsTable
        loading={loading}
        error={error != null}
        credits={credits}
        onRowClicked={handleRowClicked}
        showUser={true}
        paging={paging}
        onPage={handlePage}
        grow
      />

      <SmartcardCreditFlyout
        credit={selectedCredit}
        working={working}
        error={workingError}
      />
    </>
  );
};

export default Smartcards;
