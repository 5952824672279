import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  Spacing,
  Breakpoints,
  Loader,
  Size,
  EmptyMessage,
  neutral,
} from "ui-kit";
import { FileList, LinkList } from "sharedComponents/common";
import ArticleHeader from "./articleHeader";
import ArticleBody from "./articleBody";
import { useParams } from "react-router-dom";
import { files, arrays } from "utils";
import magazineActions from "areas/news/actions/magazineActions";
import { RootState } from "reducers/store";
import { LinkListView } from "types/common/links.types";
import { FileListView } from "types/common/files.types";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${Breakpoints.m}px) {
    .main {
      max-width: 70%;
      padding: ${Spacing.Medium}px;
    }

    flex-direction: row;
    > .right {
      margin-left: ${Spacing.Jumbo}px;
    }

    .article-comments {
      padding-bottom: ${Spacing.Large}px;
      margin-bottom: ${Spacing.Jumbo}px;
    }

    .magazine-articles-list {
      padding-bottom: ${Spacing.Large}px;
      border-bottom: 1px solid ${neutral[400]};
      margin-bottom: ${Spacing.Jumbo}px;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
    .magazine-articles-list-inner {
      flex-direction: column;
      .article-box {
        margin-right: ${Spacing.Medium}px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;
const FeedLinks = styled.div`
  padding: 0 ${Spacing.Medium}px;
`;

const FeedFiles = styled.div`
  padding: 0 ${Spacing.Medium}px;
`;


const Article = () => {

  const { articleId } = useParams();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { loading, feature, error, loadingComments, commentsError, comments } =
    useSelector((state: RootState) => state.magazineFeature);

  const isMedia = (x: FileListView) => {
    return files.isMediaMimeType(x.mimeType);
  };

  useEffect(() => {
    articleId && magazineActions.getFeature(parseInt(articleId));
  }, [articleId]);

  if (loading) {
    return <Loader cover size={Size.Medium} />;
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem loading the article"
        cover
      />
    );
  }

  return (
    <Wrapper>
      <div className="main">
        <ArticleHeader article={feature} />
        <ArticleBody article={feature} />
        {/* <ArticleComments article={feature} />
        <ArticleFooter article={feature} /> */}

        {!arrays.isEmpty(feature?.files) && (
          <FeedFiles className="feed-item-files">
            <FileList title="Attachments">
              {feature?.files
                ?.filter(file => !isMedia(file))
                .map((file, index) => (
                  <FileList.Item key={index} file={file} clickToDownload />
                ))}
            </FileList>
          </FeedFiles>
        )}

        {!arrays.isEmpty(feature?.links) && (
          <FeedLinks className="feed-item-links">
            <LinkList title="Links">
              {feature?.links?.map((link: LinkListView, index: number) => (
                <LinkList.Item key={index} link={link} />
              ))}
            </LinkList>
          </FeedLinks>
        )}
      </div>
      {/* <div class="right">
        <LatestCategoryArticles category={feature?.category} />
        <LatestArticles />
      </div> */}
    </Wrapper>
  );
};

export default Article;
