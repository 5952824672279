import store from "reducers/store";
import actionTypeBuilder from "actions/actionTypeBuilder";
import action from "actions/action";
import client from "services/client";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { PagedQueryView } from "types/common/views.types";
import { FeedbackMessageListView } from "../types/feedbackResponses.types";
import { SaveFeedbackMessageCommand } from "../types/feedbackRequests.types";


const builder = new actionTypeBuilder("feedback");

const types = {
  FEEDBACKMESSAGES_GET: builder.build("FEEDBACKMESSAGES_GET"),
  FEEDBACKMESSAGE_CREATE: builder.build("FEEDBACKMESSAGE_CREATE"),
  FEEDBACKMESSAGE_SAVE: builder.build("FEEDBACKMESSAGE_SAVE"),
  FEEDBACKMESSAGE_DELETE: builder.build("FEEDBACKMESSAGE_DELETE"),
  FEEDBACKMESSAGE_SETCONTENT: "FEEDBACKMESSAGE_SETCONTENT",
  FEEDBACKMESSAGE_SETREPLYREQUIREMENT: "FEEDBACKMESSAGE_SETREPLYREQUIREMENT",
};


const setFeedbackContent = (feedbackContent: string) =>
  store.dispatch<IBeehiveAction<string>>({
    type: types.FEEDBACKMESSAGE_SETCONTENT,
    payload: feedbackContent,
  });


const setFeedbackReplyRequirement = (requiresReply: boolean) =>
  store.dispatch<IBeehiveAction<boolean>>({
    type: types.FEEDBACKMESSAGE_SETREPLYREQUIREMENT,
    payload: requiresReply,
  });


const getFeedbackMessages = (pageIndex: number, callback?: callbackType<PagedQueryView<FeedbackMessageListView>>) =>
  action<PagedQueryView<FeedbackMessageListView>>(
    () => client.get(`feedback?pageIndex=${pageIndex}`),
    types.FEEDBACKMESSAGES_GET,
    callback
  );


const createFeedbackMessage = (command: SaveFeedbackMessageCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`feedback`, command),
    types.FEEDBACKMESSAGE_CREATE,
    callback
  );


const saveFeedbackMessage = (command: SaveFeedbackMessageCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`feedback/${command.id}`, command),
    types.FEEDBACKMESSAGE_SAVE,
    callback
  );


const deleteFeedbackMessage = (feedbackId: number, callback?: callbackType<number>) =>
  action(
    () => client.delete(`feedback/${feedbackId}`),
    types.FEEDBACKMESSAGE_SAVE,
    callback
  );


const feedbackActions = {
  types,
  setFeedbackContent,
  setFeedbackReplyRequirement,
  getFeedbackMessages,
  createFeedbackMessage,
  saveFeedbackMessage,
  deleteFeedbackMessage,
};

export default feedbackActions;
