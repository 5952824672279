import React from "react";
import styled from "styled-components";
import {
  Icon,
  Body,
  Left,
  Right,
  neutral,
  Spacing,
  BorderRadius,
  DetailLabel,
  boxShadow,
  Subtitle,
  Swatches,
} from "../index";

export interface IGridProps {
  children?:
    | React.ReactElement<IGridItemProps>
    | React.ReactElement<IGridItemProps>[];
  className?: string;
  title?: string;
  isExpanded?: boolean;
  onClickAccordionArrow?(): void;
}

export interface IGridItemProps {
  text?: string;
  sub?: string | JSX.Element;
  icon?: string;
  onClick?: Function;
  className?: string;
  selected?: boolean;
  transparent?: boolean;
  bottom?: React.ReactElement | null;
  body?: React.ReactElement | null;
  value?: any;
  label?: React.ReactElement | null;
}

const GridWrapper = styled.div<{ isExpanded?: boolean }>`
  margin: 0;
  display: none;

  ${({ isExpanded }) =>
    isExpanded &&
    `
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: ${Spacing.Medium}px;
    `}
`;

const GridItemWrapper = styled.div<{
  hasOnClick: boolean;
  selected?: boolean;
  transparent?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.Large}px;
  border-radius: ${BorderRadius.Default}px;
  cursor: ${props => (props.hasOnClick ? "pointer" : "default")};
  // box-shadow: ${boxShadow};
  border: 1px solid ${neutral[300]};
  transition: all ease 150ms;

  .top {
    margin-bottom: ${Spacing.Default}px;
    display: flex;
    align-items: center;
    .icon {
      margin-top: 0.15rem;
    }
  }

  .bottom {
    margin-top: ${Spacing.Default}px;
    display: flex;
    align-items: center;
  }

  .body {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text {
    display: block;
  }
  .sub {
    display: block;
  }

  .icon {
    color: ${neutral[600]};
  }

  ${({ transparent }) =>
    transparent
      ? `background: none; box-shadow: none;`
      : `background: ${neutral[100]};`}

  ${({ selected }) =>
    selected &&
    `
  background-color: ${Swatches.Primary.swatch};
  border-color: ${Swatches.Primary.swatch};
  .label, .sub, .icon {
    color: ${neutral[100]};
    }

    &:hover {
      border-color: ${neutral[600]};
    }
`}

  ${({ hasOnClick }) =>
    hasOnClick &&
    `
  &:hover {
    background: ${neutral[600]};

    .label, .icon, .sub {
    color: ${neutral[100]};
    }
    
    .chip {
      background: ${neutral[100]};
      color: ${neutral[600]};
      }
  }

  `}
`;

const Grid: React.FC<IGridProps> & {
  Item: typeof GridItem;
} = ({ children, className, title, isExpanded, onClickAccordionArrow }) => {
  return (
    <>
      {title && (
        <Subtitle
          text={title}
          isExpanded={isExpanded}
          onClickAccordionArrow={onClickAccordionArrow}
        />
      )}
      <GridWrapper
        isExpanded={!onClickAccordionArrow || isExpanded}
        className={`grid ${className}`}
      >
        {children}
      </GridWrapper>
    </>
  );
};

const GridItem: React.FC<IGridItemProps> = ({
  text,
  sub,
  icon,
  onClick,
  className,
  selected,
  transparent,
  body,
  bottom,
  value,
  label,
}) => {
  const handleClick = () => {
    onClick?.(value);
  };

  return (
    <GridItemWrapper
      className={`className ${className}`}
      onClick={handleClick}
      hasOnClick={onClick != null}
      selected={selected}
      transparent={transparent}
    >
      {icon && !body && (
        <Left>
          <Icon value={icon} />
        </Left>
      )}
      {
        <div className="top">
          {(text || sub) && (
            <DetailLabel label={text ?? ""} sub={sub ?? ""} bold />
          )}
        </div>
      }
      {body && <Body>{body}</Body>}
      {label}
      {bottom}
    </GridItemWrapper>
  );
};

GridItem.defaultProps = {};
Grid.defaultProps = {};

Grid.Item = GridItem;

export default Grid;
