import { StaffView, UserName } from "sharedComponents/common";
import ContextMenu from "ui-kit/composite/contextMenu";
import OnCallStatusChip from "areas/behaviour/components/onCall/onCallStatusChip";
import { OnCallStatus } from "areas/analytics/reducers/onCallAnalyticsShared.types";
import {
  getGroupName,
  FeedItemContent,
  DateAndStudentName,
  FeedItemDetails,
  FeedItemActionButtons,
} from "../activityFeedItemComponents";

const OnCallActivity = ({
  activity,
  userIsAuthorised,
  displayStudentName,
  handleEditActivity,
  handleFeedClick,
}) => {
  return (
    <>
      <FeedItemContent onClick={handleFeedClick}>
        <DateAndStudentName
          displayStudentName={displayStudentName}
          activity={activity}
        >
          {activity.onCallStatusId !== null && (
            <OnCallStatusChip onCallStatus={activity.onCallStatusId} />
          )}
        </DateAndStudentName>

        <FeedItemDetails>
          <b>{activity.categoryName} On Call</b> requested by{" "}
          <b>
            <UserName user={activity.createdBy} />
          </b>
          {!displayStudentName && getGroupName(activity)}
          <StaffView>
            <div className="note-content">{activity.content}</div>
          </StaffView>
        </FeedItemDetails>
      </FeedItemContent>

      <StaffView>
        {userIsAuthorised() &&
          (activity.onCallStatusId === OnCallStatus.Open ||
            activity.onCallStatusId === OnCallStatus.Claimed) && (
            <FeedItemActionButtons>
              <ContextMenu>
                <ContextMenu.Item
                  text="Edit"
                  onClick={() => handleEditActivity(activity)}
                />
              </ContextMenu>
            </FeedItemActionButtons>
          )}
      </StaffView>
    </>
  );
};

export default OnCallActivity;
