import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { SendStudentQueryFilter, UpdateSenStaffForStudentCommand } from "areas/send/types/passportRequest.types";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { UserTinyView } from "types/users/userListViews.types";
import { urls } from "utils";

const builder = new actionTypeBuilder("send");

const types = {
  STUDENTS_GET: builder.build("students_get"),
  STUDENTS_UPDATESTAFF: builder.build("students_updatestaff"),
};


const getSendStudents = (filter: SendStudentQueryFilter, pageIndex: number, callback?: callbackType<UserTinyView[]>) => {
  var url = new urls.QueryString(`planner/send/students`);
  url.addParams(filter);
  url.addParam("pageIndex", pageIndex);
  url.addParam("pageSize", 50);

  return action<UserTinyView[]>(
    () => client.get(url.toUrl()), 
    types.STUDENTS_GET, 
    callback
  );
};

const updateSendStaffForStudent = (data: UpdateSenStaffForStudentCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`planner/send/students/${data.studentId}/staff`, data),
    types.STUDENTS_UPDATESTAFF,
    callback
  );


const sendStudentsActions = {
  types,
  getSendStudents,
  updateSendStaffForStudent,
};

export default sendStudentsActions;
