import { IBeehiveAction, StateAction } from "types/common/action.types";
import lunchRegisterActions from "../../actions/lunchRegisterActions"
import { LunchTransactionListView } from "areas/payments/types/catering/cateringResponses.types";
import paymentActions from "areas/payments/actions/paymentsActions";


interface ILunchHistoryActions {
  adjust: StateAction
}

export interface ILunchHistoryState {
  loading: boolean;
  history: LunchTransactionListView[] | null;
  error: null | string;
  actions: ILunchHistoryActions;
}

const INITIAL_STATE: ILunchHistoryState = {
  loading: false,
  history: null,
  error: null,
  actions: {
    adjust: {
      working: false,
      error: null
    },
  },
};

const lunchHistoryReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ILunchHistoryState => {

  const { CATERING_GETLUNCHHISTORY } = lunchRegisterActions.lunchRegisterTypes;
  const { PAYMENTS_ADDMANUALLUNCHCREDIT } = paymentActions.paymentsTypes;
  //const { PAYMENTS_ADDMANUALLUNCHCREDIT } = paymentActions.types;

  switch (action.type) {

    case CATERING_GETLUNCHHISTORY.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };
    case CATERING_GETLUNCHHISTORY.SUCCESS:
      return {
        ...state,
        loading: false,
        history: action.payload,
        error: null,
      };
    case CATERING_GETLUNCHHISTORY.FAILED:
      return {
        ...state,
        loading: false,
        error: "There was a problem loading the lunch history",
      };

    case PAYMENTS_ADDMANUALLUNCHCREDIT.START:
      return {
        ...state,
        actions: {
          ...state.actions,
          adjust: { ...state.actions.adjust, working: true, error: null },
        },
      };
    case PAYMENTS_ADDMANUALLUNCHCREDIT.SUCCESS:
      return {
        ...state,
        history: [action.payload.adjustment, ...(state.history || [])],
        actions: {
          ...state.actions,
          adjust: { ...state.actions.adjust, working: false, error: null },
        },
      };
    case PAYMENTS_ADDMANUALLUNCHCREDIT.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          adjust: {
            ...state.actions.adjust,
            working: false,
            error: "There was a problem adjusting the balance.",
          },
        },
      };

    default:
      return state;
  }
};

export default lunchHistoryReducer;
