const types = {
  FLYOUT_OPEN: "FLYOUT_OPEN",
  FLYOUT_CLOSE: "FLYOUT_CLOSE",
  SET_TITLE: "SET_TITLE",
  SET_PRIVACY: "SET_PRIVACY",
  TOGGLE_MENU: "TOGGLE_MENU",
  CLOSE_MENU: "CLOSE_MENU",
  OPEN_MENU: "OPEN_MENU",
  TOGGLE_HOVER_MENU: "TOGGLE_HOVER_MENU",
  MENU_SET_MODE: "MENU_SET_MODE",
  UPGRADE_UI: "UPGRADE_UI",
};

export default types;
