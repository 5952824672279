import React from "react";
import styled from "styled-components";
import { 
  IUiKitBaseProps, 
  Spacing, 
  neutral 
} from "../index";

const Wrapper = styled.div`
  margin-bottom: ${Spacing.Default}px;
  margin-top: ${Spacing.Default}px;

  hr {
    border-top: 1px solid ${neutral[300]};
  }
`;

export interface IDividerProps extends IUiKitBaseProps {
  style?: object;
}

const Divider: React.FC<IDividerProps> = ({ style }) => {
  return (
    <Wrapper style={style}>
      <hr />
    </Wrapper>
  );
};

export default Divider;
