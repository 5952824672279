import React, { useState } from "react";
import {
  Button,
  Swatches,
  Card,
  Title,
  TitleSize,
  ActionBar,
  ToastService,
} from "ui-kit";
import styled from "styled-components";
import { useSelector } from "react-redux";
import AssignmentDetails from "./assignmentDetails";
import AssignmentSubmission, { SurveyType } from "./assignmentSubmission";
import assignmentActions from "areas/planner/actions/assignments/assignmentActions";
import GroupList from "sharedComponents/common/groups/groupList";
import { StudentView } from "sharedComponents/common/users/userTypeView";
import { useAppSelector } from "reducers/hooks";
import { AssignmentDetailView } from "types/planner/assignments.types";


const Wrapper = styled.div`
  .card .title {
    margin-bottom: 0;
  }
`;


interface IStudentAssignmentViewProps {
  assignment: AssignmentDetailView;
}


const StudentAssignmentView: React.FC<IStudentAssignmentViewProps> = ({ assignment }) => {

  const { user } = useAppSelector(state => state.currentUser);
  const { submissions } = useAppSelector(state => state.assignment);
  const [showSubmitPanel, setShowSubmitPanel] = useState<boolean>(false);

  const sendSubmission = (survey: SurveyType) => {
    //console.log(survey);
    if (
      window.confirm("This will mark the assignment as complete. Are you sure?")
    ) {
      assignmentActions.submitAssignment(assignment.id, user.id, survey, () => {
        ToastService.pop("Assignment submitted successfully", null, "briefcase");
        setShowSubmitPanel(false);
      });
    }
  };

  const handleSubmitSubmission = (survey: SurveyType) => {
    sendSubmission(survey);
  };

  const handleCancelSubmission = () => {
    setShowSubmitPanel(false);
  };

  const handleSubmit = () => {
    if (assignment.surveyEnabled) {
      setShowSubmitPanel(true);
    } else {
      sendSubmission({
        difficulty: null,
        timescale: null,
        comments: null,
        requireAssistance: null,
        understoodRequirements: null,
      });
    }
  };

  return (
    <Wrapper>
      <Card>
        <Card.Body>
          <Title
            text={assignment.title}
            size={TitleSize.H3}
            sub={<GroupList groups={assignment.groups} />}
          />
        </Card.Body>
      </Card>

      {showSubmitPanel ? (
        <AssignmentSubmission
          assignment={assignment}
          onCancel={handleCancelSubmission}
          onSubmit={handleSubmitSubmission}
          working={submissions.submitting}
        />
      ) : (
        <AssignmentDetails assignment={assignment} />
      )}

      {!showSubmitPanel && (
        <StudentView>
          {!assignment.isComplete && (
            <ActionBar>
              <Button
                text="Submit Assignment"
                onClick={handleSubmit}
                color={Swatches.Primary}
                working={submissions.submitting}
              />
            </ActionBar>
          )}
        </StudentView>
      )}
    </Wrapper>
  );
};

export default StudentAssignmentView;
