const imageMimeTypes : string[] = ["image/jpeg", "image/jpg", "image/png", "image/gif"];
const videoMimeTypes : string[] = ["video/mp4", "application/mp4"];


/**
 * Checks if a mimetype is an image
 * @param {string} mimeType The mimetype to check
 * @returns {boolean} True if the given mimetype is an image
 */
const isImageMimeType = (mimeType: string) : boolean => {
  return imageMimeTypes.some((type) => type === mimeType);
};


/**
 * Checks if a mimetype is a video
 * @param {string} mimeType The mimetype to check
 * @returns {boolean} True if the given mimetype is a video
 */
const isVideoMimeType = (mimeType: string) : boolean => {
  return videoMimeTypes.some((type) => type === mimeType);
};


/**
 * Checks if a mimetype is a media type
 * @param {string} mimeType The mimetype to check
 * @returns {boolean} True if the given mimetype is a media type
 */
const isMediaMimeType = (mimeType: string) : boolean => {
  const mimeTypes = [...imageMimeTypes, ...videoMimeTypes];
  return mimeTypes.some((type) => type === mimeType);
};


/**
 * Composes and opens a CSV returned by the API
 * @param {string} reportData The returned API CSV data to open
 * @param {string} reportName The download name of the file (without extension)
 */
const openCsv = (reportData: string, reportName?: string) => {
  var attachment = document.createElement("a");
  attachment.href = "data:attachment/csv," + encodeURIComponent(reportData);
  attachment.download = reportName ? `${reportName}.csv` : "report.csv";
  attachment.click();
};


/**
 * Composes and opens a PDF returned by the API
 * @param {string} reportData The returned API PDF data to open
 * @param {string} reportName The download name of the file (without extension)
 */
const openPdf = (reportData: string, reportName?: string) => {
  var attachment = document.createElement("a");
  attachment.href = 'application/octet-binary,' + encodeURIComponent(reportData);
  attachment.download = reportName ? `${reportName}.pdf` : "report.csv";
  attachment.click();
};


const files = {
  isImageMimeType,
  imageMimeTypes,
  videoMimeTypes,
  isVideoMimeType,
  isMediaMimeType,
  openCsv,
  openPdf
};

export default files;
