import React from "react";
import styled from "styled-components";
import { Spacing, Title, TitleSize, neutral } from "ui-kit";

const Wrapper = styled.div`
  margin-bottom: ${Spacing.Default}px;
  .title {
    color: ${neutral[500]};
  }
`;

interface ISubheaderProps {
  children?: any | any[];
  className?: string;
  title?: string;
  style?: React.CSSProperties;
}

const Subheader: React.FC<ISubheaderProps> = ({
  children,
  className,
  title,
  style
}) => {
  return (
    <Wrapper className={`subheader ${className ? className : ""}`} style={style}>
      {title && <Title size={TitleSize.H2} text={title} />}
      {children}
    </Wrapper>
  );
};

export default Subheader;
