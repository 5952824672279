import calendarActions from "areas/calendar/actions/calendarActions";
import { CalendarEventsFilter } from "areas/calendar/reducers/calendarEventsFilterReducer";
import { CalendarCategoryListView, CalendarEventListView } from "areas/calendar/types/calendarResponses.types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import styled from "styled-components";
import { PublishStatus } from "types/common/status.types";
import { PagedQueryView } from "types/common/views.types";
import { ActionBar, Breakpoints, Button, Card, Chip, CircleIcon, DetailLabel, EmptyMessage, Left, List, Modal, Right, Size, Spacing, Swatches } from "ui-kit";
import { arrays } from "utils";


const FeedWrapper = styled.div`
  position: relative;

  @media screen and (min-width: 640px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0 ${Spacing.Large}px;
    grid-template-areas: ". ";
  }

  @media screen and (min-width: ${Breakpoints.s}px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ". .";
  }

  @media screen and (min-width: ${Breakpoints.l}px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: ". . .";
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: top;
  padding: ${Spacing.Medium}px;

  .left {
    margin-right: ${Spacing.Default}px;
    flex-grow: 1;
  }
`;


interface ICalendarEventPickerProps {
  eventCategoryName: string;
  buttonText?: string;
  selectedEvents?: CalendarEventListView[];
  onSelect?: (events: CalendarEventListView[]) => void;
  selectMultiple?: boolean;
  editable?: boolean;
}

const CalendarEventPicker: React.FC<ICalendarEventPickerProps> = ({
  eventCategoryName,
  buttonText,
  selectedEvents,
  onSelect,
  selectMultiple,
  editable
}) => {

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [availableCategories, setAvailableCategories] = useState<CalendarCategoryListView[]>([]);
  const [foundCategory, setFoundCategory] = useState<CalendarCategoryListView>(null);

  useEffect(() => {
    calendarActions.getCalendarCategories(
      (data: CalendarCategoryListView[]) => {
        setAvailableCategories(data);
      }
    );
  }, []);

  useEffect(() => {
    if (!arrays.isEmpty(availableCategories)) {
      const found = availableCategories.find(cat => cat.name.toLowerCase() === eventCategoryName.toLowerCase());
      setFoundCategory(found);
    }
  }, [availableCategories, eventCategoryName]);

  const handleEventLookup = () => {
    setModalOpen(true);
  }

  const handleCancel = () => {
    setModalOpen(false);
  }

  const handleSelect = (events: CalendarEventListView[]) => {
    setModalOpen(false);
    onSelect?.(events)
  }

  const handleRemoveEvent = (calEvent: CalendarEventListView) => {
    onSelect([...arrays.removeItem(selectedEvents, calEvent)]);
  }

  return (
    <>
      { !arrays.isEmpty(selectedEvents) && (
        <List>
          { selectedEvents.map((calEvent: CalendarEventListView, index: number) => (
            <>
              <List.Item
                key={index}
                value={calEvent}
                left={
                  <DetailLabel
                    bold
                    label={calEvent.title}
                    sub={calEvent.mandatoryAttendance
                      ? "Mandatory Attendance"
                      : "Optional Attendance"
                    }
                  />
                }
                right={
                  <>
                  { editable && (
                    <CircleIcon
                      value="times"
                      onClick={() => handleRemoveEvent(calEvent)}
                      size={Size.Small}
                      tooltip={`Remove ${calEvent.title}`}
                    />
                  )}
                  </>
                }
              />
            </>
          ))}
        </List>
      )}
      { (arrays.isEmpty(selectedEvents) || selectMultiple) && (
        <Button
          text={buttonText || "Add Events"}
          onClick={handleEventLookup}
          color={Swatches.Primary}
          size={Size.Small}
          disabled={!foundCategory}
          disabledTooltip={"No calendar category with this name was found"}
          style={(selectMultiple && !arrays.isEmpty(selectedEvents)) ? { marginTop: Spacing.Default, float: "right" } : {}}
        />
      )}
      <CalendarEventPickerModal
        open={modalOpen}
        eventCategory={foundCategory}
        preSelected={selectedEvents}
        onSelect={handleSelect}
        onCancel={handleCancel}
        selectMultiple={selectMultiple}
      />
    </>
  )
}


interface ICalendarEventPickerModalProps {
  eventCategory: CalendarCategoryListView;
  onSelect?: (events: CalendarEventListView[]) => void;
  preSelected?: CalendarEventListView[];
  open: boolean;
  onCancel?: () => void;
  selectMultiple?: boolean;
}

const CalendarEventPickerModal: React.FC<ICalendarEventPickerModalProps> = ({
  eventCategory,
  onSelect,
  preSelected,
  open,
  onCancel,
  selectMultiple
}) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const [_open, _setOpen] = useState<boolean>(false);
  const [events, setEvents] = useState<PagedQueryView<CalendarEventListView>>(null);
  const [selectedEvents, setSelectedEvents] = useState<CalendarEventListView[]>([]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    if (!arrays.isEmpty(preSelected)) {
      setSelectedEvents([...selectedEvents, ...preSelected]);
    }
  }, [preSelected]);

  useEffect(() => {
    if (user && eventCategory) {
      var filter: CalendarEventsFilter = {
        categoryId: eventCategory.id,
        status: PublishStatus.Live
      }
      calendarActions.getManagedCalendarEvents(
        user.id, 
        filter, 
        0,
        (data) => {
          setEvents(data);
        }
      );
    }
  }, [eventCategory, user])

  const handleClick = (ev: CalendarEventListView) => {
    if (selectedEvents.findIndex(se => se.id == ev.id) !== -1) {
      setSelectedEvents([...arrays.removeItem(selectedEvents, ev)]);
    }
    else if (selectMultiple) {
      setSelectedEvents([...selectedEvents, ev]);
    } 
    else {
      setSelectedEvents([ev]);
    }
  }

  const handleCancel = () => {
    setSelectedEvents([]);
    onCancel?.();
  }

  const handleSelect = () => {
    onSelect?.(selectedEvents)
  }

  return (
    <>
    <Modal
      width={"80%"}
      height={"60%"}
      title={`${eventCategory?.name} Events`}
      open={_open}
      onClose={handleCancel}
    >
      <Modal.Body>
        { arrays.isEmpty(events?.items) ? (
          <EmptyMessage
            icon="calendar"
            title="Events Not Found"
            summary="No calendar events for the provided category were found."
          />
        ) : (
          <FeedWrapper>
            { events?.items?.map((calEvent: CalendarEventListView, index: number) => (
              <Card 
                key={index}
                onClick={() => handleClick(calEvent)}
                id={calEvent.id.toString()}
                highlight={selectedEvents?.some(se => se.id?.toString() === calEvent.id.toString())}
                style={{ cursor: "pointer" }}
              >
                <Card.Body noPad>
                  <HeaderWrapper>
                    <Left>
                      <DetailLabel
                        bold
                        label={calEvent.title}
                        sub={`${calEvent.dateCount} Dates`}
                      />
                    </Left>
                    <Right>
                      { calEvent.mandatoryAttendance && (
                        <Chip
                          text={"Mandatory Attendance"}
                          colorSwatch={Swatches.Low}
                        />
                      )}
                    </Right>
                  </HeaderWrapper>
                </Card.Body>
              </Card>
            ))}
          </FeedWrapper>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              onClick={handleCancel}
              size={Size.Small}
              color={Swatches.Low}
              text="Cancel"
            />
            <Button
              text={"Confirm"}
              onClick={handleSelect}
              size={Size.Small}
              color={Swatches.Success}
              disabled={arrays.isEmpty(selectedEvents)}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
    </>
  )
};

export default CalendarEventPicker;
