import cateringActions from "areas/payments/actions/cateringActions";
import { CateringDetailsView } from "areas/payments/types/catering/cateringResponses.types";
import { IBeehiveAction } from "types/common/action.types";

export interface CateringDetailsFilter {
  schoolId: number;
}

export interface ICateringPricingReducerState {
  details: CateringDetailsView[];
  filter: CateringDetailsFilter;
  loading: boolean;
  error: string | null;
}

const INITIAL_STATE: ICateringPricingReducerState = {
  details: [],
  filter: {
    schoolId: null
  },
  loading: false,
  error: null,
};

const cateringPricingReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ICateringPricingReducerState => {

  const {
    DETAILS_SETFILTER,
    DETAILS_GET
  } = cateringActions.types;

  switch (action.type) {

    case DETAILS_SETFILTER:
      return {
        ...state,
        filter: action.payload
      };

    case DETAILS_GET.START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case DETAILS_GET.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        details: action.payload
      };
    case DETAILS_GET.START:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
      
    default:
      return state;
  }
};

export default cateringPricingReducer;
