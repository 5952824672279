import { IBeehiveAction } from "types/common/action.types";
import groupActions from "../../actions/group/groupActions";


interface IGroupTabMenuState {
  activeTab: number;
}

const INITIAL_STATE: IGroupTabMenuState = {
  activeTab: 2,
};


const groupTabMenuReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IGroupTabMenuState => {

  const { SETGROUPACTIVETAB } = groupActions.types;

  if (action.type === SETGROUPACTIVETAB) {
    return { ...state, activeTab: action.payload };
  }

  return state;
  
};

export default groupTabMenuReducer;
