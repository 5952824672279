import React from "react";
import { Chip, Swatches } from "ui-kit";
import { Constants } from "configuration";


interface IConsentStatusProps {
  status: number;
}


const ConsentStatus: React.FC<IConsentStatusProps> = ({ status }) => {

  if (status === Constants.CONSENT_STATUS.NOCONSENT.value) {
    return (
      <Chip
        text={Constants.CONSENT_STATUS.NOCONSENT.name}
        colorSwatch={Swatches.Danger}
      />
    );
  }

  if (status === Constants.CONSENT_STATUS.CONSENT.value) {
    return (
      <Chip
        text={Constants.CONSENT_STATUS.CONSENT.name}
        colorSwatch={Swatches.Success}
      />
    );
  }

  return (
    <Chip
      text={Constants.CONSENT_STATUS.PARTIAL.name}
      colorSwatch={Swatches.Warning}
    />
  );
};

export default ConsentStatus;
