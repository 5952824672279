import React from "react";
import {
  Card,
  Dropdown,
  StructuredList,
  TextInput,
  TextInputType,
  ActionBar,
  Button,
  Right,
  Swatches,
} from "ui-kit";
import { Size } from "ui-kit/common/size";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { NOTE_TYPES } from "areas/planner/constants/student/notes";
import StudentModal from "sharedComponents/common/users/studentModal";

const NewStudentNoteModal = ({
  open,
  onClose,
  student,
  noteProps,
  noteCategories,
  handleNotePropsChange,
  handleOnClickAdd,
  handleCancel,
  loading,
  error,
}) => {
  return (
    <StudentModal
      title="Note"
      onClose={onClose}
      open={open}
      width="60%"
      student={student}
      footer={
        <ActionBar low>
          <Right>
            <Button
              text="Add Note"
              color={Swatches.Success}
              size={Size.Small}
              disabled={
                noteProps.noteType === null || noteProps.note.trim() === ""
              }
              onClick={handleOnClickAdd}
              working={loading}
            />
            <Button
              text="Cancel"
              size={Size.Small}
              onClick={handleCancel}
              working={loading}
            />
          </Right>
        </ActionBar>
      }
    >
      <ApiExceptionMessage error={error} />

      <Card>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Note Type" required>
              <Dropdown
                placeholder="Note Type"
                fluid
                items={noteCategories}
                onChange={value =>
                  handleNotePropsChange({ ...noteProps, noteType: value })
                }
                value={noteProps.noteType}
              />
            </StructuredList.Item>

            <StructuredList.Item name="Note" required>
              <TextInput
                type={TextInputType.Textarea}
                fluid
                placeholder="Add note text here..."
                value={noteProps.note}
                onChange={value =>
                  handleNotePropsChange({ ...noteProps, note: value })
                }
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
    </StudentModal>
  );
};

export default NewStudentNoteModal;
