import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Left,
  Right,
  List,
  Spacing,
  Card,
  Message,
  StructuredList,
  Size,
  Button,
  Swatches,
  ActionBar,
  ValidationMessage,
} from "ui-kit";
import styled from "styled-components";
import { arrays, files } from "utils";
import calendarActions from "areas/calendar/actions/calendarActions";
import { RootState } from "reducers/store";
import { SchoolStartupView } from "types/schools/schools.types";
import { useScroll } from "hooks/useScroll";
import { Constants } from "configuration";
import { EventReportFilter } from "areas/calendar/types/calendarRequests.types";
import TotalSignupsReport from "./totalSignupsReport";
import AttendanceStatusReport from "./attendanceStatusReport";
import AttendanceSummaryReport from "./attendanceSummaryReport";
import TotalSignupsReportDisplay from "./totalSignupsReportDisplay";


const Wrapper = styled.div`
  display: flex;

  > .left {
    flex-basis: 250px;
    flex-shrink: 0;
  }

  > .right {
    flex-grow: 1;
    margin-left: ${Spacing.Large}px;
  }
`;

const DisplayWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: ${Spacing.ExtraLarge}px;
`


export interface ICalendarReportProps {
  filter: EventReportFilter;
  setFilter: (value: EventReportFilter) => void
}

interface ICalendarReport {
  name: string;
  value: number;
  hasDisplay?: boolean;
}


const REPORTS: ICalendarReport[] = [
  {
    name: "Attendance Summary Report",
    value: 0
  },
  {
    name: "Attendance Status Report",
    value: 1
  },
  {
    name: "Total Signups Report",
    value: 2,
    hasDisplay: true
  }
];

const FILTER_DEFAULT: EventReportFilter = {
  schoolId: null,
  eventCategoryId: null,
  topStudentsCount: 20,
  academicYearId: Constants.ACADEMIC_YEAR_FILTER_ARRAY[0].value,
  isPp: null,
  isSen: null,
  isEal: null,
  gender: null,
  starts: null,
  ends: null,
  yearGroupId: null,
};


const Reporting = () => {

  const { scrollToTop } = useScroll();
  const resultsRef = useRef<HTMLDivElement>();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { data, totalSignupsDisplay, loading, error } = useSelector((state: RootState) => state.eventReporting);
  const [selectedReport, setSelectedReport] = useState<ICalendarReport>(REPORTS[0]);
  const [schools, setSchools] = useState<SchoolStartupView[]>([]);
  const [filter, setFilter] = useState<EventReportFilter>(FILTER_DEFAULT);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    if (user) {
      setSchools([user.baseSchool, ...user.schools].filter(x => x.enableLunchCredits));
      setFilter({...filter, schoolId: user.baseSchool?.id});
    }
  }, [user]);

  useEffect(() => {
    totalSignupsDisplay && totalSignupsDisplay?.paging?.pageIndex == 0 && resultsRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [totalSignupsDisplay]);

  // useEffect(() => {
  //   !arrays.isEmpty(schools) &&
  //     setFilter({ ...filter, schoolId: schools[0]?.id });
  // }, [schools]);

  const handleDisplayReport = () => {
    var errors = [];

    if (filter.schoolId == null) {
      errors.push(`Please select a school.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    switch (selectedReport.value) {
      case 2:
        calendarActions.getTotalSignupsReportDisplay(filter, 0, () => {
        });
        break;
      default:
        break;
    }
  }

  const handleRunReport = () => {
    var errors = [];

    if (filter.schoolId == null) {
      errors.push(`Please select a school.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    switch (selectedReport.value) {
      case 0:
        calendarActions.getEventAttendanceReport(filter, reportData => {
          files.openCsv(reportData);
        });
        break;
      case 1:
        calendarActions.getAttendanceStatusReport(filter, reportData => {
          files.openCsv(reportData);
        });
        break;
      case 2:
        calendarActions.getTotalSignupsReport(filter, reportData => {
          files.openCsv(reportData);
        });
        break;
      default:
        break;
    }
  };

  const handleTotalSignupsPage = () => {
    calendarActions.getTotalSignupsReportDisplay(filter, totalSignupsDisplay.paging.pageIndex + 1);
  }

  return (
    <>
    <Wrapper>
      <Left>
        <List>
          {REPORTS.map((report, index) => (
            <List.Item
              key={index}
              text={report.name}
              onClick={() => setSelectedReport(report)}
              selected={report.value === selectedReport.value}
            />
          ))}
        </List>
      </Left>
      <Right>
        <Message text={error} color={Swatches.Danger} />
        <ValidationMessage errors={validationErrors} />
        <Card title={selectedReport.name}>
          <Card.Body>
            <StructuredList>
              {selectedReport.value === 0 && (
                <AttendanceSummaryReport filter={filter} setFilter={setFilter} />
              )}
              {selectedReport.value === 1 && (
                <AttendanceStatusReport filter={filter} setFilter={setFilter} />
              )}
              {selectedReport.value === 2 && (
                <TotalSignupsReport filter={filter} setFilter={setFilter} />
              )}
            </StructuredList>
          </Card.Body>
        </Card>
        <ActionBar low>
          <Button
            text="Run Report"
            onClick={handleRunReport}
            color={Swatches.Success}
            working={loading}
            size={Size.Medium}
          />
          {selectedReport.hasDisplay && (
            <Button
              text="Display Report"
              onClick={handleDisplayReport}
              color={Swatches.Primary}
              working={loading}
              size={Size.Medium}
            />
          )}
        </ActionBar>

        <DisplayWrapper ref={resultsRef}>
          { selectedReport.value === 2 && totalSignupsDisplay && (
            <TotalSignupsReportDisplay 
              records={totalSignupsDisplay.items}
              onPage={handleTotalSignupsPage}
              paging={totalSignupsDisplay.paging}
            />
          )}
        </DisplayWrapper>
      </Right>
    </Wrapper>
    </>
  );
};


export default Reporting;
