import searchEngineActions from "areas/search/actions/searchEngineActions";
import { IBeehiveAction } from "types/common/action.types";
import { PagedQueryView, SearchResultViewBase } from "types/common/views.types";


interface ISearchDepartmentsState {
  departments: PagedQueryView<SearchResultViewBase> | null;
  loading: boolean;
  error: string | null;
}

const INITIAL_STATE: ISearchDepartmentsState = {
  departments: null,
  loading: false,
  error: null,
};


const searchDepartmentsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISearchDepartmentsState => {

  const { SEARCH_DEPARTMENTS, CLEAR_RESULTS } = searchEngineActions.types;

  switch (action.type) {

    case SEARCH_DEPARTMENTS.START:
      return { ...INITIAL_STATE, loading: true };
    case SEARCH_DEPARTMENTS.SUCCESS:
      return { ...INITIAL_STATE, departments: action.payload };
    case SEARCH_DEPARTMENTS.FAILED:
      return { ...INITIAL_STATE, error: action.payload };

    case CLEAR_RESULTS:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default searchDepartmentsReducer;
