import React from "react";
import styled from "styled-components";
import { IUiKitBaseProps, Label, Sub, Spacing } from "../index";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 0;

  .chip {
    position: relative;
    top: -2px;
    margin-left: ${Spacing.Small}px;
    font-size: 8px;
    padding: 4px !important;
    letter-spacing: 0.02rem;
    span {
      line-height: 12px;
    }
  }
`;

export interface IDetailLabelProps extends IUiKitBaseProps {
  label?: string | number | React.ReactNode | JSX.Element;
  sub?: any;
  bold?: boolean;
  chip?: React.ReactNode;
}

const DetailLabel: React.FC<IDetailLabelProps> = ({
  label,
  sub,
  bold,
  className,
  style,
  onClick,
  chip,
}) => {
  return (
    <Wrapper
      style={style}
      className={`detail-label ${className ? className : ""}`}
    >
      <div className="label-wrapper">
        <Label bold={bold} onClick={onClick}>
          {label}
        </Label>
        {chip}
      </div>
      <Sub>{sub}</Sub>
    </Wrapper>
  );
};
export default DetailLabel;
