import paymentActions from "areas/payments/actions/paymentsActions";
import { SmartcardUserCreditQueryFilter } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";
import { SmartcardSyncResultListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface IUserSmartcardSyncRecordsState {
  syncRecords: SmartcardSyncResultListView[],
  error: string | null;
  loading: boolean;
  filter: SmartcardUserCreditQueryFilter;
}

const INITIAL_STATE: IUserSmartcardSyncRecordsState = {
  syncRecords: [],
  error: null,
  loading: false,
  filter: {
    schoolId: -1,
    statusId: -1,
    providerId: -1
  }
};

const userSmartcardSyncRecordsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUserSmartcardSyncRecordsState => {

  const { 
    PAYMENTS_GETUSERSMARTCARDSYNCRECORDS,
    PAYMENTS_USERSYNC_UPDATEFILTER
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETUSERSMARTCARDSYNCRECORDS.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETUSERSMARTCARDSYNCRECORDS.SUCCESS:
      return { 
        ...state, 
        syncRecords: action.payload, 
        loading: false 
      };

    case PAYMENTS_GETUSERSMARTCARDSYNCRECORDS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PAYMENTS_USERSYNC_UPDATEFILTER:
      return {
        ...state,
        filter: action.payload
      }

    default:
      return state;
  }
};

export default userSmartcardSyncRecordsReducer;
