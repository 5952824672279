import paymentActions from "areas/payments/actions/paymentsActions";
import { ProductCategoryListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface IPaymentCategoriesState {
  categories: ProductCategoryListView[];
  error: string | null;
  loading: boolean;
  saving: boolean;
}

const INITIAL_STATE: IPaymentCategoriesState = {
  categories: [],
  error: null,
  loading: false,
  saving: false
};

const paymentCategoriesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IPaymentCategoriesState => {
  const {
    PAYMENTS_GETCATEGORIESFORSCHOOL,
    PAYMENTS_SAVECATEGORY,
    PAYMENTS_CREATECATEGORY
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETCATEGORIESFORSCHOOL.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETCATEGORIESFORSCHOOL.SUCCESS:
      return { 
        ...state, 
        categories: action.payload, 
        loading: false 
      };

    case PAYMENTS_GETCATEGORIESFORSCHOOL.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PAYMENTS_SAVECATEGORY.SUCCESS:
      return {
        ...state,
        saving: false,
        categories: state.categories.map(cat =>
          cat.id === action.payload.id
            ? { ...cat, name: action.payload.name }
            : cat
        )
      };

    case PAYMENTS_CREATECATEGORY.SUCCESS:
      return {
        ...state,
        saving: false,
        categories: [...state.categories, action.payload]
      };

    default:
      return state;
  }
};

export default paymentCategoriesReducer;
