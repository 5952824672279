import React from "react";
import PropTypes from "prop-types";
import { formatTime, formatDate } from "utils/dateTime";
import { Card } from "ui-kit";
import ReadOnlyDataList, { DetentionDataType } from "./readOnlyDataList";
import { BehaviourView } from "areas/behaviour/types/behaviourResponses.types";


interface IReadOnlyIncidentDetailsProps {
  behaviour: BehaviourView;
}


const ReadOnlyIncidentDetails: React.FC<IReadOnlyIncidentDetailsProps> = ({ behaviour }) => {

  const data: DetentionDataType[] = [
    {
      dataType: "Incident Date",
      dataValue: formatDate(behaviour.incidentDate),
    },
    {
      dataType: "Incident Time",
      dataValue: formatTime(behaviour.incidentDate),
    },
  ];

  if (behaviour.roomName || behaviour.otherLocation) {
    data.push({
      dataType: "Incident Location",
      dataValue: behaviour.roomName
        ? behaviour.roomName
        : behaviour.otherLocation,
    });
  }

  if (behaviour.periodName) {
    data.push({
      dataType: "Period",
      dataValue: behaviour.periodName,
    });
  }

  return (
    <Card className="incident-details" title="Incident Details">
      <Card.Body>
        <ReadOnlyDataList data={data} />
      </Card.Body>
    </Card>
  );
};


export default ReadOnlyIncidentDetails;
