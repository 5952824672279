import { IBeehiveAction } from "types/common/action.types";
import passportActions from "../../actions/passports/passportActions";

interface IPassportVersionState {
  saving: boolean;
  saveError: string | null;
  publishing: boolean;
  publishError: string | null;
  deleting: boolean;
  deleteError: string | null;
  cloning: boolean;
  cloneError: string | null;
  downloading: boolean;
  downloadError: string | null;
}

const INITIAL_STATE: IPassportVersionState = {
  saving: false,
  saveError: null,
  publishing: false,
  publishError: null,
  deleting: false,
  deleteError: null,
  cloning: false,
  cloneError: null,
  downloading: false,
  downloadError: null
};


const passportVersionReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IPassportVersionState => {

  const {
    PASSPORTVERSION_SAVE,
    PASSPORTVERSION_PUBLISH,
    PASSPORTVERSION_DELETE,
    PASSPORTVERSION_CREATE,
    PASSPORTVERSION_CLONE,
    PASSPORTVERSION_DOWNLOAD,
  } = passportActions.types;

  switch (action.type) {
    case PASSPORTVERSION_CREATE.START:
      return { ...state, saveError: null, cloneError: null, saving: true };
    case PASSPORTVERSION_CREATE.SUCCESS:
      return { ...state, saving: false };
    case PASSPORTVERSION_CREATE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem creating the new passport version.",
      };

    case PASSPORTVERSION_SAVE.START:
      return { ...state, saveError: null, cloneError: null, saving: true };
    case PASSPORTVERSION_SAVE.SUCCESS:
      return { ...state, saving: false };
    case PASSPORTVERSION_SAVE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the passport version.",
      };

    case PASSPORTVERSION_CLONE.START:
      return { ...state, cloneError: null, saveError: null, cloning: true };
    case PASSPORTVERSION_CLONE.SUCCESS:
      return { ...state, cloning: false };
    case PASSPORTVERSION_CLONE.FAILED:
      return {
        ...state,
        cloning: false,
        cloneError: "There was a problem cloning the passport version.",
      };

    case PASSPORTVERSION_PUBLISH.START:
      return {
        ...state,
        publishError: null,
        deleteError: null,
        publishing: true,
      };
    case PASSPORTVERSION_PUBLISH.SUCCESS:
      return { ...state, publishing: false };
    case PASSPORTVERSION_PUBLISH.FAILED:
      return {
        ...state,
        publishing: false,
        publishError: "There was a problem publishing the passport version.",
      };

    case PASSPORTVERSION_DOWNLOAD.START:
      return {
        ...state,
        downloadError: null,
        saveError: null,
        publishError: null,
        cloneError: null,
        downloading: true,
      };
    case PASSPORTVERSION_DOWNLOAD.SUCCESS:
      return { ...state, downloading: false };
    case PASSPORTVERSION_DOWNLOAD.FAILED:
      return {
        ...state,
        downloading: false,
        downloadError: "There was a problem downloading the passport.",
      };

    case PASSPORTVERSION_DELETE.START:
      return {
        ...state,
        publishError: null,
        deleteError: null,
        deleting: true,
      };
    case PASSPORTVERSION_DELETE.SUCCESS:
      return { 
        ...state, 
        deleting: false
      };
    case PASSPORTVERSION_DELETE.FAILED:
      return {
        ...state,
        deleting: false,
        deleteError:
          "There was a problem deleting the passport version. This may because it is the only version for this passport.",
      };

    default:
      return state;
  }
};

export default passportVersionReducer;
