import React from "react";
import { ReactComponent as FrogSvg } from "assets/primary/characters/frog.svg";
import { IAnimalIconProps } from "./primaryCharacter";


export const Frog: React.FC<IAnimalIconProps> = ({ className }) => {

  return (
    <FrogSvg 
      //alt={alt} 
      className={className} 
    />
  )
};

export default Frog;
