import React, { useState } from "react";
import { arrays } from "utils";
import { Table, EmptyMessage, Swatches, Button, Size, DateTime } from "ui-kit";
import { Avatar, ApprovalStatusChip } from "sharedComponents/common";
import LeaveRequestTypeChip from "./leaveRequestTypeChip";
import LeaveRequestModal from "./leaveRequestModal";
import { PageInfo } from "types/common/paging.types";
import { LeaveRequestListView } from "areas/humanResources/types/leaveResponse.types";

interface ILeaveRequestTableProps {
  requests: LeaveRequestListView[];
  paging?: PageInfo;
  handlePage?: () => void;
  onRowSelected?: (request: LeaveRequestListView, index: number) => void;
  showAddRequest?: boolean;
  showStaff?: boolean;
  onUpdated?: () => void;
  onRequestLeave?: () => void;
  loading?: boolean;
  error?: string;
  showRequestLeaveButton?: boolean;
}

const LeaveRequestTable: React.FC<ILeaveRequestTableProps> = ({
  requests,
  paging,
  handlePage,
  onRowSelected,
  showAddRequest,
  showStaff,
  onUpdated,
  onRequestLeave,
  loading,
  error,
  showRequestLeaveButton,
}) => {
  const [selectedLeaveRequest, setSelectedLeaveRequest] =
    useState<LeaveRequestListView | null>(null);

  const handleRequestLeave = () => {
    onRequestLeave?.();
  };

  const handleLeaveRequestSelected = (
    request: LeaveRequestListView,
    index: number
  ) => {
    setSelectedLeaveRequest(request);
    onRowSelected?.(request, index);
  };

  const handleLeaveRequestModalClose = () => {
    setSelectedLeaveRequest(null);
  };

  if (arrays.isEmpty(requests)) {
    return (
      <EmptyMessage
        icon="plane"
        title="No Leave Requests"
        summary="No leave requests have been made yet"
        cover
      >
        {showAddRequest && (
          <Button
            text="Request Leave"
            onClick={handleRequestLeave}
            color={Swatches.Primary}
            size={Size.Small}
          />
        )}
      </EmptyMessage>
    );
  }

  return (
    <>
      <Table zebra grow loading={loading} error={error != null}>
        <Table.Header>
          <Table.HeaderCell width={1}>Type</Table.HeaderCell>
          <Table.HeaderCell width={1.5} show={showStaff}>
            Staff
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>From</Table.HeaderCell>
          <Table.HeaderCell width={1}>To</Table.HeaderCell>
          <Table.HeaderCell width={1}>Duration</Table.HeaderCell>
          <Table.HeaderCell width={showStaff ? 2.75 : 3.75}>
            Ref
          </Table.HeaderCell>
          <Table.HeaderCell right width={1} />
        </Table.Header>
        <Table.Body onPage={handlePage} paging={paging}>
          {requests.map((request: LeaveRequestListView, index: number) => (
            <Table.Row
              key={index}
              onClick={() => handleLeaveRequestSelected(request, index)}
            >
              <Table.Cell width={1}>
                <LeaveRequestTypeChip type={request?.leaveRequestType} fluid />
              </Table.Cell>
              <Table.Cell show={showStaff} width={1.5}>
                <Avatar user={request.staffMember} />
              </Table.Cell>
              <Table.Cell width={1}>
                <DateTime date={request.startDate} bold />
              </Table.Cell>
              <Table.Cell width={1}>
                <DateTime date={request.endDate} bold />
              </Table.Cell>
              <Table.Cell width={1}>{request.duration}</Table.Cell>
              <Table.Cell width={showStaff ? 2.75 : 3.75}>
                {request.userRef}
              </Table.Cell>
              <Table.Cell width={1} right>
                <ApprovalStatusChip status={request.currentStatus?.status} fluid />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        {showAddRequest && (
          <Table.Footer>
            <Table.Row>
              <Table.Cell colspan={5} />
              <Table.Cell right>
                {showRequestLeaveButton && (
                  <Button
                    fluid
                    text="Request Leave"
                    onClick={handleRequestLeave}
                    color={Swatches.Primary}
                    size={Size.Small}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
      {/* <ViewLeaveRequestFlyout
        request={selectedLeaveRequest}
        onRequestUpdated={onUpdated}
      /> */}
      <LeaveRequestModal
        leaveRequest={selectedLeaveRequest}
        onClose={handleLeaveRequestModalClose}
        open={selectedLeaveRequest != null}
      />
    </>
  );
};

export default LeaveRequestTable;
