import React, { useEffect, useRef, useState } from "react";
import SmartTaskReportForm from "./smartTaskReportForm";
import SmartTaskReportTable from "./smartTaskReportTable";
import ReportDescription from "../reportDescription";
import { Title, TitleSize } from "ui-kit";
import smartTaskActions from "areas/behaviour/actions/smartTasks/smartTaskActions";
import smartTaskReportActions from "areas/analytics/actions/smartTaskReportActions";
import { useAppSelector } from "reducers/hooks";
import { SmartTaskFilter } from "areas/analytics/types/smartTaskAnalyticsRequests.types";
import moment from "moment";


export interface SmartTaskFilterExt extends Omit<SmartTaskFilter, "schoolId" | "startDate" | "endDate"> {
  startDate: Date;
  endDate: Date;
}


const SmartTaskReportWrapper = () => {

  const reportRef = useRef<HTMLDivElement>();

  const { user } = useAppSelector(state => state.currentUser);
  const { report, loading, loadingExportReport } = useAppSelector(
    state => state.smartTaskReport
  );
  const { smartTasks, loading: loadingSmartTasks } = useAppSelector(
    state => state.smartTasks
  );

  const [schoolId, setSchoolId] = useState<number>(null);
  const [reportCriteria, setReportCriteria] = useState<SmartTaskFilterExt>({
    startDate: null,
    endDate: null,
    smartTaskId: null,
  });

  useEffect(() => {
    if (user?.baseSchool) {
      setSchoolId(user.baseSchool.id);
    }
  }, [user]);

  useEffect(() => {
    if (schoolId !== null) {
      smartTaskActions.getSmartTasks(schoolId);
    }

    setReportCriteria({
      ...reportCriteria,
      smartTaskId: null,
    });
  }, [schoolId]);

  useEffect(() => {
    if (loading || report) {
      reportRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading, report]);

  const handleReportCriteriaChange = (criteria: SmartTaskFilterExt) => {
    setReportCriteria(criteria);
  };

  const handleGenerateReport = (exportReport: boolean) => {
    smartTaskReportActions.getSmartTaskReport(
      {
        startDate: moment(reportCriteria.startDate).format("YYYY-MM-DD"),
        endDate: moment(reportCriteria.endDate).format("YYYY-MM-DD"),
        schoolId: schoolId,
        smartTaskId: reportCriteria.smartTaskId
      },
      exportReport
    );
  };

  return (
    <>
      <Title text="Smart Tasks Report" size={TitleSize.H3} />
      <ReportDescription text="Use this report to view smart task triggers between a given date range." />
      <SmartTaskReportForm
        schoolId={schoolId}
        onSelectSchool={id => setSchoolId(id)}
        smartTasks={smartTasks}
        loadingSmartTasks={loadingSmartTasks}
        reportCriteria={reportCriteria}
        onChangeReportCriteria={handleReportCriteriaChange}
        handleGenerateReport={handleGenerateReport}
        loadingExportReport={loadingExportReport}
      />
      <div ref={reportRef}>
        <SmartTaskReportTable />
      </div>
    </>
  );
};

export default SmartTaskReportWrapper;
