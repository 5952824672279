import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import { BehaviourIntialActionView } from "areas/behaviour/types/behaviourResponses.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


interface IInitialRewardActionsState {
  initialRewardActions: BehaviourIntialActionView[];
  loadingRewardActions: boolean;
  getRewardActionsError: IBeehiveError;
}

const INITIAL_STATE: IInitialRewardActionsState = {
  initialRewardActions: [],
  loadingRewardActions: false,
  getRewardActionsError: null,
};

const { GETINITIALREWARDACTIONS } = rewardActions.types;


const initialRewardActionsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IInitialRewardActionsState => {

  switch (action.type) {
    case GETINITIALREWARDACTIONS.START:
      return {
        ...state,
        loadingRewardActions: true,
        getRewardActionsError: null,
      };

    case GETINITIALREWARDACTIONS.SUCCESS:
      return {
        ...state,
        loadingRewardActions: false,
        initialRewardActions: action.payload,
      };

    case GETINITIALREWARDACTIONS.FAILED:
      return {
        ...state,
        loadingRewardActions: false,
        getRewardActionsError: action.payload,
      };

    default:
      return state;
  }
};

export default initialRewardActionsReducer;
