import React, { useEffect, useState } from "react";
import {
  Loader,
  Size,
  Flyout,
  Button,
  ActionBar,
  Swatches,
  StructuredList,
  TextInput,
  TextInputType,
  ValidationMessage,
} from "ui-kit";
import { Constants } from "configuration";
import RescheduledDetentions from "./reschedule/rescheduledDetentions";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import flyoutActions from "actions/ui/flyouts";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import StudentProfileHeader from "sharedComponents/common/users/studentProfileHeader";
import { DETENTION_STATUS_KEYS } from "areas/behaviour/constants/detentions";
import ConfirmModal from "sharedComponents/common/confirmModal";
import studentActions from "areas/planner/actions/student/studentActions";
import ReadOnlyDetentionDetails from "areas/behaviour/components/readOnly/readOnlyDetentionDetails";
import ReadOnlyBehaviourDetails from "areas/behaviour/components/readOnly/readOnlyBehaviourDetails";
import ReadOnlyIncidentDetails from "areas/behaviour/components/readOnly/readOnlyIncidentDetails";
import ReadOnlyInvestigationNote from "areas/behaviour/components/readOnly/readOnlyInvestigationNote";
import StudentStats from "areas/planner/components/studentRecord/studentStats";
import { useAppSelector } from "reducers/hooks";
import { BehaviourView } from "areas/behaviour/types/behaviourResponses.types";
import ReadOnlyCancelledDetentionDetails from "../readOnly/readOnlyCancelledDetentionDetails";


interface IDetentionDetailsWrapperProps {
  studentId?: string;
}


const DetentionDetailsWrapper: React.FC<IDetentionDetailsWrapperProps> = ({ studentId }) => {

  const {
    behaviour,
    loading,
    loadingRevokeBehaviour,
    getBehaviourError,
    revokeBehaviourError,
  } = useAppSelector(state => state.behaviour);
  const {
    selectedDetention,
    loading: loadingDetention,
    cancelDetentionError,
  } = useAppSelector(state => state.detention);
  const { student, loading: loadingStudent } = useAppSelector(
    state => state.student
  );

  const [selectedBehaviour, setSelectedBehaviour] = useState<BehaviourView>();
  const [note, setNote] = useState<string>("");
  const [openConfirmStatusCancelled, setOpenConfirmStatusCancelled] =
    useState<boolean>(false);
  const [openConfirmRevoke, setOpenConfirmRevoke] = useState<boolean>(false);
  const [emptyNoteError, setEmptyNoteError] = useState<boolean>(false);

  useEffect(() => {
    if (selectedDetention) {
      behaviourActions.getBehaviour(
        studentId ? studentId : selectedDetention.student?.id,
        selectedDetention.behaviourRewardId,
        selectedDetention.detentionId
      );
      if (!student || (student && student.id !== studentId)) {
        studentActions.getStudent(
          studentId ? studentId : selectedDetention.student?.id
        );
      }
    }
  }, [selectedDetention]);

  useEffect(() => {
    if (behaviour) {
      setSelectedBehaviour(behaviour.behaviour);
    }
  }, [behaviour]);

  const handleCancelDetention = () => {
    setEmptyNoteError(false);

    const callback = () => {
      setNote("");
      flyoutActions.closeFlyout();
    };

    detentionActions.cancelDetention(
      studentId ? studentId : selectedDetention.student.id,
      selectedDetention.behaviourRewardId,
      selectedDetention.detentionId,
      {
        studentId: studentId ? studentId : selectedDetention.student.id,
        behaviourId: selectedDetention.behaviourRewardId,
        detentionId: selectedDetention.detentionId,
        updateNote: null,
        cancelNote: note,
      },
      callback
    );

    setOpenConfirmStatusCancelled(false);
  };

  const handleRevokeDetention = () => {
    setEmptyNoteError(false);

    const callback = () => {
      setNote("");
      flyoutActions.closeFlyout();
    };

    behaviourActions.revokeBehaviour(
      {
        studentId: studentId || selectedDetention.student.id,
        updateNote: note,
        behaviourId: selectedDetention.behaviourRewardId
      },
      callback
    );

    setOpenConfirmRevoke(false);
  };

  const confirmContent = (noteFieldName: string) => {
    return (
      <>
        <StructuredList>
          <StructuredList.Item required name={noteFieldName}>
            <TextInput
              fluid
              type={TextInputType.Textarea}
              placeholder="Reason"
              value={note}
              onChange={value => {
                setNote(value);
                setEmptyNoteError(false);
              }}
            />
          </StructuredList.Item>
        </StructuredList>

        {emptyNoteError && (
          <ValidationMessage
            errors={["The reason for changing this behaviour must be entered"]}
          />
        )}
      </>
    );
  };

  if (loading || loadingStudent || loadingRevokeBehaviour || loadingDetention) {
    return <Loader size={Size.Large} cover />;
  }

  if (getBehaviourError) {
    return <ApiExceptionMessage error={getBehaviourError} />;
  }

  return (
    (selectedDetention &&
    student &&
    selectedBehaviour &&
    selectedBehaviour.behaviourId === selectedDetention.behaviourRewardId) ? (
      <>
        <Flyout.Body>
          <StudentProfileHeader student={student} allowNavigation />
          <StudentStats student={student} />

          {selectedBehaviour.detentions && (
            <ReadOnlyDetentionDetails
              detentions={selectedBehaviour.detentions}
            />
          )}

          {/* <ReadOnlyDetails behaviour={selectedBehaviour} /> */}

          {selectedDetention.parentDetentionId && (
            <RescheduledDetentions
              studentId={student.id}
              behaviourId={selectedBehaviour.behaviourId}
              detentionId={selectedDetention.detentionId}
            />
          )}

          <ReadOnlyBehaviourDetails behaviour={selectedBehaviour} />

          <ReadOnlyIncidentDetails behaviour={selectedBehaviour} />

          {selectedBehaviour.investigationNote && (
            <ReadOnlyInvestigationNote
              investigationNote={selectedBehaviour.investigationNote}
            />
          )}

          {cancelDetentionError && (
            <ApiExceptionMessage error={cancelDetentionError} />
          )}

          {revokeBehaviourError && (
            <ApiExceptionMessage error={revokeBehaviourError} />
          )}
        </Flyout.Body>

        {selectedBehaviour.detentions[0]?.statusId !==
          DETENTION_STATUS_KEYS.CANCELLED &&
          selectedBehaviour.detentions[0]?.statusId !==
            DETENTION_STATUS_KEYS.RESCHEDULED && (
            <Flyout.Footer>
              <ActionBar low>
                <Button
                  fluid
                  color={Swatches.Danger}
                  text="Cancel Sanction"
                  onClick={() => setOpenConfirmStatusCancelled(true)}
                />
                <Button
                  fluid
                  color={Swatches.Danger}
                  text="Revoke Behaviour &amp; Cancel Sanction"
                  onClick={() => setOpenConfirmRevoke(true)}
                />
              </ActionBar>
            </Flyout.Footer>
          )}

        <ConfirmModal
          openModal={openConfirmStatusCancelled}
          confirmMsg="A message will be sent to parents to inform them of the sanction cancellation. Are you sure you want to continue?"
          onClose={() => setOpenConfirmStatusCancelled(false)}
          onConfirm={handleCancelDetention}
        >
          {confirmContent("Reason for Cancelling")}
        </ConfirmModal>

        <ConfirmModal
          openModal={openConfirmRevoke}
          confirmMsg="A message will be sent to parents to inform them of the sanction cancellation. Are you sure you want to continue?"
          onClose={() => setOpenConfirmRevoke(false)}
          onConfirm={handleRevokeDetention}
        >
          {confirmContent("Reason for Revoking")}
        </ConfirmModal>
      </>
    ) : (
      <>
      </>
    )
  );
};

export default DetentionDetailsWrapper;
