import React, { useState, useEffect } from "react";
import {
  Card,
  Dropdown,
  StructuredList,
  Message,
  ValidationMessage,
  Button,
  Swatches,
  List,
  formatScrollbars,
  Spacing,
  ActionBar,
  Size,
  neutral,
  Left,
  Right,
} from "ui-kit";
import { arrays, files } from "utils";
import { Constants } from "configuration";
import { useSelector } from "react-redux";
import reportingActions from "areas/administration/actions/reportingActions";
import rolesActions from "areas/administration/actions/rolesActions";
import styled from "styled-components";
import { users } from "utils";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import { RootState } from "reducers/store";
import { UsersReportFilter } from "areas/administration/types/reportingRequest.types";
import { IRole } from "configuration/constants.types";
import { useScroll } from "hooks/useScroll";


const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;

  .left {
    padding-right: ${Spacing.Default}px;
    flex-direction: column;
    width: 0px;
    flex-grow: 1;
    left: 0;
    overflow-y: auto !important;
    width: 13rem;
    max-width: 13rem;
    margin-right: ${Spacing.Default}px;
  }

  .right {
    flex-grow: 1;
  }

  ${formatScrollbars(neutral[200])}
`;


interface adminReport {
  name: string;
  value: number;
  roles: IRole[];
}

interface adminReportsFilter extends UsersReportFilter {
  staffType: number;
  role: number;
}

interface rolesOption {
  label: string;
  sub: string;
  value: number;
}


const REPORTS: adminReport[] = [
  {
    name: "Users Report",
    value: 0,
    roles: [
      Constants.ROLES.DEVELOPMENT,
      Constants.ROLES.IT_ADMINISTRATOR,
      Constants.ROLES.IT_USER,
    ],
  },
  {
    name: "Staff Report",
    value: 1,
    roles: [
      Constants.ROLES.DEVELOPMENT,
      Constants.ROLES.HR_ADMINISTRATOR,
      Constants.ROLES.IT_ADMINISTRATOR,
      Constants.ROLES.IT_USER,
    ],
  },
  {
    name: "Staff Roles Report",
    value: 2,
    roles: [
      Constants.ROLES.DEVELOPMENT,
      Constants.ROLES.IT_ADMINISTRATOR,
      Constants.ROLES.IT_USER,
    ],
  },
  {
    name: "Students with Unregistered Parents Report",
    value: 3,
    roles: [
      Constants.ROLES.DEVELOPMENT,
      Constants.ROLES.IT_ADMINISTRATOR,
      Constants.ROLES.IT_USER,
    ],
  },
  {
    name: "Incomplete Acceptable Use Report",
    value: 4,
    roles: [
      Constants.ROLES.DEVELOPMENT,
      Constants.ROLES.IT_ADMINISTRATOR,
      Constants.ROLES.IT_USER,
    ],
  },
];


const Reporting = () => {

  const { scrollToTop } = useScroll();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { working, error } = useSelector((state: RootState) => state.managedUserReporting);
  const [filter, setFilter] = useState<adminReportsFilter>({
    schoolId: -1,
    staffType: null,
    role: null,
    userType: null,
    registered: null,
    locked: null,
    securityFlag: null,
  });
  const [selectedReport, setSelectedReport] = useState<adminReport>(REPORTS[0]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_roles, _setRoles] = useState<rolesOption[]>([]);

  useEffect(() => {
    rolesActions.getRoles(data => {
      _setRoles(
        data.map(role => ({
          label: role.name,
          sub: role.description,
          value: role.id,
        }))
      );
    });
  }, []);
  //   useEffect(() => {
  //     user
  //   }, [user]);

  const handleRunReport = () => {
    switch (selectedReport.value) {
      case 0: // Users
        reportingActions.usersReport(filter, data => {
          files.openCsv(data);
        });
        break;
      case 1: // Staff
        reportingActions.staffReport(filter, data => {
          files.openCsv(data);
        });
        break;
      case 2: // Staff Roles
        var errors = [];

        if (filter.role === null) {
          errors.push(`Please choose a role.`);
        }
        setValidationErrors(errors);
        if (!arrays.isEmpty(errors)) {
          scrollToTop();
          return;
        }

        reportingActions.staffRolesReport(filter, data => {
          files.openCsv(data);
        });
        break;
      case 3: // Students with no registered parents
        reportingActions.studentsWithUnregisteredParentReport(filter.schoolId, data => {
          files.openCsv(data);
        });
        break;
      case 4: // Students with no registered parents
        reportingActions.incompleteAcceptableUseReport(filter, data => {
          files.openCsv(data);
        });
        break;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <Left>
        <List title="Available Reports">
          {REPORTS.filter(x => users.isInAnyRoles(user, x.roles)).map(
            (report, index) => (
              <List.Item
                text={report.name}
                key={index}
                selected={selectedReport.value === report.value}
                onClick={() => setSelectedReport(report)}
              />
            )
          )}
        </List>
      </Left>
      <Right>
        <Card title={selectedReport.name}>
          <Card.Body>
            <ValidationMessage errors={validationErrors} />
            {error && <Message text={error} color={Swatches.Danger} />}
            <StructuredList>
              <StructuredList.Item name="School">
                <SchoolDropdown
                  initialValue={filter.schoolId}
                  includeAllOption={true}
                  fluid
                  schools={user.schools}
                  onChange={val => setFilter({ ...filter, schoolId: val })}
                />
              </StructuredList.Item>
              <>
              {selectedReport.value === 0 ||
                (selectedReport.value === 4 && (
                  <StructuredList.Item name="User Type">
                    <Dropdown
                      value={filter.userType}
                      fluid
                      onChange={val => setFilter({ ...filter, userType: val })}
                    >
                      <Dropdown.Item label="All User Types" value={null} />
                      <Dropdown.Item label="Staff" value={1} />
                      <Dropdown.Item label="Student" value={2} />
                      <Dropdown.Item label="Parent" value={0} />
                    </Dropdown>
                  </StructuredList.Item>
                ))}
              </>
              {selectedReport.value === 1 && (
                <StructuredList.Item name="Staff Type">
                  <Dropdown
                    value={filter.staffType}
                    fluid
                    onChange={val => setFilter({ ...filter, staffType: val })}
                  >
                    <Dropdown.Item label="All Staff Types" value={null} />
                    <Dropdown.Item label="Teaching" value={0} />
                    <Dropdown.Item label="Associate" value={1} />
                  </Dropdown>
                </StructuredList.Item>
              )}
              {selectedReport.value === 2 && (
                <StructuredList.Item name="Role" required>
                  <Dropdown
                    value={filter.role}
                    fluid
                    onChange={val => setFilter({ ...filter, role: val })}
                  >
                    {_roles?.map((role, index) => (
                      <Dropdown.Item
                        key={index}
                        label={role.label}
                        sub={role.sub}
                        value={role.value}
                      />
                    ))}
                  </Dropdown>
                </StructuredList.Item>
              )}
              {selectedReport.value === 0 && (
                <>
                  <StructuredList.Item name="Registered">
                    <Dropdown
                      value={filter.registered}
                      fluid
                      onChange={val =>
                        setFilter({ ...filter, registered: val })
                      }
                    >
                      <Dropdown.Item
                        label="All Registration Statuses"
                        value={null}
                      />
                      <Dropdown.Item label="Registered" value={true} />
                      <Dropdown.Item label="Unregistered" value={false} />
                    </Dropdown>
                  </StructuredList.Item>
                  <StructuredList.Item name="Locked">
                    <Dropdown
                      value={filter.locked}
                      fluid
                      onChange={val => setFilter({ ...filter, locked: val })}
                    >
                      <Dropdown.Item label="Locked or Unlocked" value={null} />
                      <Dropdown.Item label="Locked" value={true} />
                      <Dropdown.Item label="Unlocked" value={false} />
                    </Dropdown>
                  </StructuredList.Item>
                  <StructuredList.Item name="Security Flag">
                    <Dropdown
                      value={filter.securityFlag}
                      fluid
                      onChange={val =>
                        setFilter({ ...filter, securityFlag: val })
                      }
                    >
                      <Dropdown.Item
                        label="All Security Statuses"
                        value={null}
                      />
                      <Dropdown.Item label="Flagged" value={1} />
                      <Dropdown.Item label="Not Flagged" value={0} />
                    </Dropdown>
                  </StructuredList.Item>
                </>
              )}
            </StructuredList>
          </Card.Body>
          <Card.Footer>
            <ActionBar low>
              <Button
                text="Run Report"
                color={Swatches.Success}
                size={Size.Small}
                onClick={handleRunReport}
                working={working}
              />
            </ActionBar>
          </Card.Footer>
        </Card>
      </Right>
    </Wrapper>
  );
};

export default Reporting;
