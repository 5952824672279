import React from "react";
import { StructuredList, TextInput, TextInputType } from "ui-kit";
import { Card } from "ui-kit";
import { DETENTION_STATUS_KEYS } from "areas/behaviour/constants/detentions";
import { DetentionStatus } from "areas/behaviour/types/behaviourShared.types";


interface IDetentionNotesFormFieldProps {
  notes: string;
  handleNotesChange: (value: string) => void;
  detentionStatus: DetentionStatus;
}


const DetentionNotesFormField: React.FC<IDetentionNotesFormFieldProps> = ({
  notes,
  handleNotesChange,
  detentionStatus,
}) => {

  return (
    <Card title="Notes">
      <Card.Body>
        <StructuredList>
          <StructuredList.Item
            name="Detention Notes"
            description={`${
              detentionStatus !== DETENTION_STATUS_KEYS.CANCELLED &&
              detentionStatus !== DETENTION_STATUS_KEYS.RESCHEDULED
                ? "(These notes will not be sent to parents)"
                : ""
            }`}
            helpPopup
          >
            <TextInput
              type={TextInputType.Textarea}
              rows={3}
              fluid
              placeholder="Notes about the detention"
              value={notes}
              onChange={handleNotesChange}
            />
          </StructuredList.Item>
        </StructuredList>
      </Card.Body>
    </Card>
  );
};

export default DetentionNotesFormField;
