import React from "react";
import styled from "styled-components";

export interface IColumnProps {
  children: React.ReactElement | React.ReactElement[];
  width?: number;
}

const Wrapper = styled.div`
  flex-grow: 1;
`;

const Column: React.FC<IColumnProps> = ({ children }) => {
  return <Wrapper className="column">{children}</Wrapper>;
};

Column.defaultProps = {
  width: 1
};

export default Column;
