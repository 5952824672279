import { IBeehiveAction } from "types/common/action.types";
import submissionActions from "../actions/submissionActions";
import { SubmissionDetailView } from "../types/formResponse.types";


interface ISubmissionState {
  submission: SubmissionDetailView | null;
  loading: boolean;
  error: string | null;
  saving: boolean; 
}

const INITIAL_STATE: ISubmissionState = {
  submission: null,
  loading: false,
  error: null,
  saving: false,
};


const submissionReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISubmissionState => {

  const { GETSUBMISSION, AUTHORISE, REJECT, CANCEL, READ } = submissionActions.types;

  switch (action.type) {
    case GETSUBMISSION.START:
      return { ...state, loading: true };

    case GETSUBMISSION.SUCCESS:
      return {
        ...state,
        submission: action.payload,
        loading: false,
      };

    case GETSUBMISSION.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case AUTHORISE.START:
    case REJECT.START:
    case CANCEL.START:
      return { ...state, saving: true };

    case AUTHORISE.SUCCESS:
    case REJECT.SUCCESS:
    case CANCEL.SUCCESS:
      var submission =
        state.submission && state.submission.id === action.payload.submissionId
          ? {
              ...state.submission,
              currentStatus: action.payload,
              history: [action.payload, ...state.submission.history],
            }
          : state.submission;

      return {
        ...state,
        saving: false,
        submission: submission,
      };

    case AUTHORISE.FAILED:
    case REJECT.FAILED:
    case CANCEL.FAILED:
      return {
        ...state,
        saving: false,
        error: action.payload,
      };

    case READ.START:
      return { ...state, saving: true };

    case READ.SUCCESS:
      var sub =
        state.submission && state.submission.id === action.payload
          ? {
              ...state.submission,
              approverRead: new Date(),
            }
          : state.submission;

      return {
        ...state,
        saving: false,
        submission: sub,
      };

    case READ.FAILED:
      return {
        ...state,
        saving: false,
        error: "There was a problem marking the submission as read",
      };

    default:
      return state;
  }
};

export default submissionReducer;
