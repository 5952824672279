import { IBeehiveAction } from "types/common/action.types";
import awardAnalyticsActions from "../actions/awardAnalyticsActions";
import { IBeehiveError } from "types/common/errors.types";
import coCurricularAnalyticsActions from "../actions/coCurricularAnalyticsActions";


interface ICoCurricularAnalyticsState {
  exporting: boolean;
  exportError: IBeehiveError;
}

const INITIAL_STATE: ICoCurricularAnalyticsState = {
  exporting: false,
  exportError: null,
}


const coCurricularAnalyticsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ICoCurricularAnalyticsState => {

  const { 
    EXPORTCOCURRICULARREPORT
  } = coCurricularAnalyticsActions.types;

  switch (action.type) {

    case EXPORTCOCURRICULARREPORT.START:
      return { 
        ...state, 
        exporting: true, 
        exportError: null 
      };
    case EXPORTCOCURRICULARREPORT.SUCCESS:
      return { 
        ...state, 
        exporting: false 
      };
    case EXPORTCOCURRICULARREPORT.FAILED:
      return { 
        ...state, 
        exporting: false, 
        exportError: action.payload
      };

    default:
      return state;
  }
};

export default coCurricularAnalyticsReducer;
