import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import paymentActions from "areas/payments/actions/paymentsActions";
import {
  Message,
  Swatches,
  ToastService,
  Table,
  Loader,
  Size,
  DateTime,
  Card,
  Button,
  DatePicker,
  EmptyMessage,
  Chip,
  SplitButton,
  ActionBar,
  Left,
  Right,
} from "ui-kit";
import TransactionsModal from "./transactionsModal";
import ImportedTransactionsModal from "./importedTransactionsModal";
import { RootState } from "reducers/store";
import { IncomingReportListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { useScroll } from "hooks/useScroll";


const IncomingReports = () => {

  const { scrollToTop } = useScroll();
  const { working, error: runError } = useSelector((state: RootState) => state.incomingReport);
  const { loading, error, reports, processing, processError } = useSelector((state: RootState) => state.incomingReports);

  const [_startDate, _setStartDate] = useState<Date>(new Date());
  const [_endDate, _setEndDate] = useState<Date>(new Date());
  const [_selectedReport, _setSelectedReport] = useState<IncomingReportListView | null>(null);
  const [_importedTransactionsModalOpen, _setImportedTransactionsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    paymentActions.getIncomingReports();
  }, []);

  useEffect(() => {
    processError && scrollToTop();
  }, [processError]);

  const handleRunReport = () => {
    if (window.confirm("This will request a transaction report from Stripe for the dates selected. The report will run in the background and will be available shortly. Are you sure you want to proceed?")) {
      paymentActions.runIncomingReport(
        { 
          startDate: _startDate, 
          endDate: _endDate 
        },
        () => ToastService.pop("Report Request Submitted", null, "clipboard")
      );
    }
  };

  const handleProcess = (report: IncomingReportListView) => {
    if (window.confirm("This will process the Stripe report and import transactions for reconciliation. Are you sure you want to proceed?")) {
      paymentActions.processIncomingReport(report.id, () => ToastService.pop("Report Processed", null, "clipboard"));
    }
  };

  const handleViewImportedTransactions = () => {
    _setImportedTransactionsModalOpen(true);
  };

  const handleRefresh = (report: IncomingReportListView) => {
    paymentActions.getIncomingReport(report.id);
  };

  const handleShowTransactions = (report: IncomingReportListView) => {
    _setSelectedReport(report);
  };

  return (
    <>
      <Message color={Swatches.Danger} text={runError} />
      <ActionBar>
        <Left>
          <ActionBar.Filter>
            <ActionBar.FilterItem>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                selectedDate={_startDate}
                onChange={(date) => _setStartDate(date)}
                closeOnSelect
                fluid
              />
            </ActionBar.FilterItem>
            <ActionBar.FilterItem>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                selectedDate={_endDate}
                onChange={(date) => _setEndDate(date)}
                closeOnSelect
                fluid
              />
            </ActionBar.FilterItem>
          </ActionBar.Filter>
        </Left>
        <Right>
          <SplitButton
            onDefaultClick={handleRunReport}
            text="Request Stripe Report"
            working={working}
            color={Swatches.Primary}
            size={Size.Small}
          >
            <SplitButton.Option
              onClick={handleRunReport}
              text="Request Stripe Report"
              show={true}
            />
            <SplitButton.Option
              onClick={handleViewImportedTransactions}
              text="View Imported Transactions"
              show={true}
            />
          </SplitButton>
        </Right>
      </ActionBar>
      {loading ? (
        <Loader cover size={Size.Medium} />
      ) : (
        <>
          <Message color={Swatches.Danger} text={processError} />

          <Card title="Reports">
            <Card.Body noPad>
              <Table
                zebra
                emptyMessage={
                  <EmptyMessage
                    icon="clipboard"
                    title="No reports found"
                    cover
                  />
                }
              >
                <Table.Header>
                  <Table.HeaderCell width={0.5}>ID</Table.HeaderCell>
                  <Table.HeaderCell width={1.25}>Start</Table.HeaderCell>
                  <Table.HeaderCell width={2}>End</Table.HeaderCell>
                  <Table.HeaderCell width={1} center>
                    Status
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} center>
                    Transactions
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} center>
                    Imported
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} center>
                    Duplicates
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1.5} />
                </Table.Header>
                <Table.Body>
                  {reports?.map((report, index) => (
                    <Table.Row key={index}>
                      <Table.Cell width={0.25}>{report.id}</Table.Cell>
                      <Table.Cell width={1}>
                        <DateTime bold date={report.startDate} />
                      </Table.Cell>
                      <Table.Cell width={2}>
                        <DateTime bold date={report.endDate} />
                      </Table.Cell>
                      <Table.Cell width={1}>
                        {report.status === 0 && (
                          <Chip
                            fluid
                            size={Size.Small}
                            colorSwatch={Swatches.Low}
                            text={report.statusName}
                          />
                        )}
                        {report.status === 1 && (
                          <Chip
                            fluid
                            size={Size.Small}
                            colorSwatch={Swatches.Blue}
                            text={report.statusName}
                          />
                        )}
                        {report.status === 2 && (
                          <Chip
                            fluid
                            size={Size.Small}
                            colorSwatch={Swatches.Danger}
                            text={report.statusName}
                          />
                        )}
                        {report.status === 3 && (
                          <Chip
                            fluid
                            size={Size.Small}
                            colorSwatch={Swatches.Success}
                            text={report.statusName}
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell width={1} center>
                        {report.transactionCount}
                      </Table.Cell>
                      <Table.Cell width={1} center>
                        <b>{report.importedCount}</b>
                      </Table.Cell>
                      <Table.Cell width={1} center>
                        {report.duplicateCount}
                      </Table.Cell>

                      <Table.Cell width={1.5} right>
                        {report.status === 3 && (
                          <Button
                            text="Transactions"
                            onClick={() => handleShowTransactions(report)}
                            size={Size.Small}
                            working={processing}
                            fluid
                          />
                        )}
                        {report.status === 1 && (
                          <Button
                            text="Process"
                            size={Size.Small}
                            working={processing}
                            onClick={() => handleProcess(report)}
                            fluid
                            color={Swatches.Primary}
                          />
                        )}
                        {report.status === 0 && (
                          <Button
                            text="Refresh"
                            size={Size.Small}
                            working={processing}
                            onClick={() => handleRefresh(report)}
                            fluid
                            color={Swatches.Low}
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card.Body>
          </Card>
          <TransactionsModal
            report={_selectedReport}
            open={_selectedReport != null}
            onClose={() => _setSelectedReport(null)}
          />
          <ImportedTransactionsModal
            dateRange={{ startDate: _startDate, endDate: _endDate }}
            open={_importedTransactionsModalOpen}
            onClose={() => _setImportedTransactionsModalOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default IncomingReports;
