import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import { RoomListView } from "areas/planner/types/classLayoutResponse.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


interface IDetentionRoomsState {
  detentionRooms: RoomListView[];
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IDetentionRoomsState = {
  detentionRooms: [],
  loading: false,
  error: null,
};

const { GETDETENTIONROOMS } = detentionActions.types;

const detentionRoomsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IDetentionRoomsState => {

  switch (action.type) {
    case GETDETENTIONROOMS.START:
      return { ...state, loading: true, error: null };

    case GETDETENTIONROOMS.SUCCESS:
      return { ...state, detentionRooms: action.payload, loading: false };

    case GETDETENTIONROOMS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default detentionRoomsReducer;
