import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import { arrays } from "utils";
import {
  Table,
  SplitButton,
  Loader,
  honeygold,
  Size,
  EmptyMessage,
  Button,
  Swatches,
  ToastService,
  Message,
} from "ui-kit";
import ProviderContactFlyout from "./providerContactFlyout";
import flyoutActions from "actions/ui/flyouts";
import providerActions from "areas/send/actions/providers/providerActions";
import { RootState } from "reducers/store";
import { ProviderContactDetailView, ProviderDetailView } from "areas/send/types/passportResponse.types";


interface IProviderContactsTableProps {
  provider: ProviderDetailView;
  loading?: boolean;
  error?: string;
}


const ProviderContactsTable: React.FC<IProviderContactsTableProps> = ({ provider, loading, error }) => {

  const { deleteError, deleting } = useSelector((state: RootState) => state.providerContact);
  const [_provider, _setProvider] = useState<ProviderDetailView>(provider);
  const [defaultContact, setDefaultContact] = useState<ProviderContactDetailView | null>(null);
  const [_selectedContact, _setSelectedContact] = useState<ProviderContactDetailView | null>(null);

  useEffect(() => {
    _setProvider(provider);
  }, [provider]);

  useEffect(() => {
    _provider &&
      setDefaultContact({
        id: null,
        firstName: "",
        lastName: "",
        providerId: _provider.id,
        displayName: "",
        sms: null,
        email: "",
        mobile: null,
        telephone: null
      });
  }, [_provider]);

  const handleAdd = () => {
    _setSelectedContact(defaultContact);
    flyoutActions.openFlyout(Constants.FLYOUTS.SEND_PROVIDERCONTACT);
  };

  const handleEdit = (contact: ProviderContactDetailView) => {
    _setSelectedContact(contact);
    flyoutActions.openFlyout(Constants.FLYOUTS.SEND_PROVIDERCONTACT);
  };

  const handleDelete = (contact: ProviderContactDetailView) => {
    if (
      window.confirm(
        "This will delete the selected contact. This cannot be undone. Are you sure?"
      )
    ) {
      providerActions.deleteProviderContact(
        contact.providerId,
        contact.id,
        () => {
          ToastService.pop("Contact Deleted", null, "hand-holding-seedling");
        }
      );
    }
  };

  const handleSaved = (contact: ProviderContactDetailView) => {
    _setSelectedContact(null);
    ToastService.pop("Contact Saved", null, "hand-holding-seedling");
  };

  if (loading) {
    return <Loader size={Size.Medium} />;
  }

  if (error) {
    return <EmptyMessage icon="times-circle" title="An error occurred" />;
  }

  return (
    <>
      {arrays.isEmpty(provider?.contacts) ? (
        <EmptyMessage
          icon="hand-holding-seedling"
          title="No Contacts"
          summary="No contacts have been set up yet"
        >
          <Button
            size={Size.Small}
            text="Add New Contact"
            onClick={handleAdd}
            color={Swatches.Primary}
          />
        </EmptyMessage>
      ) : (
        <>
          <Message text={deleteError} color={Swatches.Danger} />
          <Table>
            <Table.Header>
              <Table.HeaderCell width={2.5}>Name</Table.HeaderCell>
              <Table.HeaderCell width={3}>Email</Table.HeaderCell>
              <Table.HeaderCell width={1.5}>Mobile</Table.HeaderCell>
              <Table.HeaderCell width={1.5}>Telephone</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Header>
            <Table.Body>
              {provider.contacts.map((contact: ProviderContactDetailView, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell width={2.5}>
                    {contact.firstName} {contact.lastName}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {contact.email && (
                      <a
                        style={{ color: honeygold }}
                        href={`mailto:${contact.email}`}
                      >
                        {contact.email}
                      </a>
                    )}
                  </Table.Cell>
                  <Table.Cell width={1.5}>{contact.mobile}</Table.Cell>
                  <Table.Cell width={1.5}>{contact.telephone}</Table.Cell>
                  <Table.Cell width={1.5} right>
                    <SplitButton
                      color={Swatches.Low}
                      text="Edit"
                      onDefaultClick={() => handleEdit(contact)}
                      working={deleting}
                    >
                      <SplitButton.Option
                        text="Edit"
                        onClick={() => handleEdit(contact)}
                        show
                      />
                      <SplitButton.Option
                        text="Delete"
                        onClick={() => handleDelete(contact)}
                        show
                        color={Swatches.Danger}
                      />
                    </SplitButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.Cell colspan={5} right>
                  <Button
                    size={Size.Small}
                    text="Add New Contact"
                    onClick={handleAdd}
                    color={Swatches.Primary}
                    working={deleting}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </>
      )}
      <ProviderContactFlyout
        onClose={() => _setSelectedContact(null)}
        contact={_selectedContact}
        onSave={handleSaved}
      />
    </>
  );
};

export default ProviderContactsTable;
