import React from "react";
import { useSelector } from "react-redux";
import { Table, Loader, Size, EmptyMessage } from "ui-kit";
import { formatDate } from "utils/dateTime";
import { arrays } from "utils";
import { ApiExceptionMessage } from "sharedComponents/common";
import { useAppSelector } from "reducers/hooks";


const AwardReportTable: React.FC = () => {

  const { report, loading, error } = useAppSelector(state => state.awardAnalytics);

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return arrays.isEmpty(report) ? (
    <EmptyMessage title="No awards in the selected time period" icon="list" />
  ) : (
    <>
      <Table>
        <Table.Header>
          <Table.HeaderCell>Award Date</Table.HeaderCell>
          <Table.HeaderCell>Student Name</Table.HeaderCell>
          <Table.HeaderCell>Tutor Group</Table.HeaderCell>
          <Table.HeaderCell>Awarded By</Table.HeaderCell>
        </Table.Header>

        <>
        { report.map(reportRow => (
          <Table.Row>
            <Table.Cell>{formatDate(reportRow.awardDate)}</Table.Cell>
            <Table.Cell>
              <b>{reportRow.studentName}</b>
            </Table.Cell>
            <Table.Cell>{reportRow.tutorGroup}</Table.Cell>
            <Table.Cell>{reportRow.awardedBy}</Table.Cell>
          </Table.Row>
        ))}
        </>
      </Table>
    </>
  );
};

export default AwardReportTable;
