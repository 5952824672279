import { IBeehiveError } from "types/common/errors.types";
import assignmentActions from "../../actions/assignments/assignmentActions";
import assignmentPublishActions from "../../actions/assignments/assignmentPublishActions";
import { IBeehiveAction } from "types/common/action.types";


interface IAssignmentPublishState {
  publishing: boolean;
  error: string;
}

const INITIAL_STATE: IAssignmentPublishState = {
  publishing: false,
  error: null,
};

const assignmentPublishReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAssignmentPublishState => {

  const { PUBLISH, UNPUBLISH } = assignmentPublishActions.types;
  const { SELECTASSIGNMENT } = assignmentActions.types;

  switch (action.type) {
    case SELECTASSIGNMENT: // Reset when a new assignment is selected.
      return { ...state, ...INITIAL_STATE };

    case PUBLISH.START:
      return { ...state, publishing: true, error: null };

    case PUBLISH.SUCCESS:
      return { ...state, publishing: false };

    case PUBLISH.FAILED:
      return {
        ...state,
        publishing: false,
        error: "An error occured while publishing the assignment",
      };

    case UNPUBLISH.START:
      return { ...state, publishing: true, error: null };

    case UNPUBLISH.SUCCESS:
      return { ...state, publishing: false };

    case UNPUBLISH.FAILED:
      return {
        ...state,
        publishing: false,
        error: "An error occured while unpublishing the assignment",
      };

    default:
      return state;
  }
};

export default assignmentPublishReducer;
