import React from "react";
import {
  Icon,
  BorderRadius,
  formatScrollbars,
  Spacing,
  neutral,
  Size,
} from "ui-kit";
import {
  success,
  danger,
  successLight,
  dangerLight,
} from "ui-kit/common/colors";
import styled from "styled-components";
import { BULK_BEHAVIOUR_STATUS } from "areas/behaviour/constants/behaviours";
import Avatar from "sharedComponents/common/users/avatar";
import { IMultipleBehaviourResponse, MultipleBehavioursSaveResultsViewExt } from "areas/behaviour/utils/detentions";


const StudentsWrapper = styled.div`
  padding: ${Spacing.Large}px ${Spacing.Large}px ${Spacing.Large}px
    ${Spacing.Large}px;
  min-width: 250px;
  width: 250px;
  overflow-y: auto;
  ${formatScrollbars()}
`;

const AvatarWrapper = styled.div<{ behavioursSent?: boolean; isSuccessful?: boolean;  }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.Small + 4}px ${Spacing.Large}px;
  margin-bottom: ${Spacing.Medium}px;
  border-radius: ${BorderRadius.Default}px;
  background: ${neutral[100]};

  .avatar {
    padding-right: ${Spacing.Medium}px;
  }

  .icon {
    display: none;
  }

  ${({ behavioursSent, isSuccessful }) =>
    behavioursSent &&
    `
        .icon {
            display: block;
        }

          &.selected {
                background: ${neutral[600]};
          
                .avatar .inner .label {
                color: ${neutral[100]};
                }
          
                .icon {
                color: ${neutral[100]};
                }
            }

        &:hover {
            cursor: pointer;
            background: ${neutral[600]};

            .avatar .inner .label {
                color: ${neutral[100]};
            }

            .icon {
                color: ${neutral[100]}
            }
        }

          ${
            isSuccessful
              ? `
        background: ${successLight}
        `
              : `
        background: ${dangerLight}
        `
          }
  `}
`;


interface IMultipleBehaviourStudentsProps {
  multipleBehaviourResponse: IMultipleBehaviourResponse;
  selectedStudentBehaviour: MultipleBehavioursSaveResultsViewExt;
  handleSelectStudentBehaviour: (behaviour: MultipleBehavioursSaveResultsViewExt) => void;
}


const MultipleBehaviourStudents: React.FC<IMultipleBehaviourStudentsProps> = ({
  multipleBehaviourResponse,
  selectedStudentBehaviour,
  handleSelectStudentBehaviour,
}) => {
  return (
    <>
      <StudentsWrapper>
        {multipleBehaviourResponse.behaviours.map(behaviour => (
          <AvatarWrapper
            key={behaviour.student.id}
            behavioursSent={
              multipleBehaviourResponse.bulkBehaviourStatus ===
              BULK_BEHAVIOUR_STATUS.SENT
            }
            isSuccessful={behaviour.isSuccessful || behaviour.hasBeenSaved}
            className={`avatar-wrapper ${
              selectedStudentBehaviour &&
              selectedStudentBehaviour.student.id === behaviour.student.id
                ? "selected"
                : ""
            }`}
            onClick={() => handleSelectStudentBehaviour(behaviour)}
          >
            <Avatar user={behaviour.student} size={Size.Medium} />
            <Icon
              value={behaviour.isSuccessful ? "check-circle" : "times-circle"}
              size={Size.Medium}
              color={behaviour.isSuccessful ? success : danger}
            />
          </AvatarWrapper>
        ))}
      </StudentsWrapper>
    </>
  );
};

export default MultipleBehaviourStudents;
