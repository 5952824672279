import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CircleIcon,
  DetailLabel,
  EmptyMessage,
  HtmlText,
  Left,
  List, Loader, Message, Right, Size, Spacing, SplitButton, Subtitle, Swatches, ToastService, neutral,
} from "ui-kit";
import moment from "moment";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { ProvisionMapCommentView, ProvisionMapDetailView, ReviewCommentView } from "areas/send/types/passportResponse.types";
import { RootState } from "reducers/store";
import { arrays } from "utils";
import { Avatar } from "sharedComponents/common";
import ReviewChip from "./reviewChip";
import CommentModal from "./commentModal";
import provisionMapActions from "areas/send/actions/provisionMaps/provisionMapActions";
import { ProvisionMapStatus } from "areas/send/types/passportShared.types";
import Grid from "ui-kit/basic/grid";


const Wrapper = styled.div`
  display: flex;
`;

const ListWrapper = styled.div`
  width: 250px;
  margin-right: ${Spacing.ExtraLarge}px;
`;

const CommentWrapper = styled.div`
  flex-grow: 2;
  margin-top: ${Spacing.Small}px;
`;

const CommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${neutral[300]};
  padding-bottom: ${Spacing.Default}px;
  margin-bottom: ${Spacing.Default}px;
`;


interface IReviewCommentsProps {
  provisionMap: ProvisionMapDetailView;
  loading?: boolean;
  error?: string;
}


const ReviewComments: React.FC<IReviewCommentsProps> = ({ provisionMap, loading, error }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { deleting, deleteError } = useSelector((state: RootState) => state.provisionMapComment);
  const [_provisionMap, _setProvisionMap] = useState<ProvisionMapDetailView | null>(null);
  const [_loading, _setLoading] = useState<boolean>(false);
  const [_error, _setError] = useState<string | null>(null);
  const [selectedReviewWindow, setSelectedReviewWindow] = useState<ReviewCommentView | null>(null);
  const [selectedComment, setSelectedComment] = useState<ProvisionMapCommentView | null>(null);

  useEffect(() => {
    _setProvisionMap(provisionMap);
    if (selectedReviewWindow != null) {
      var updatedReview = provisionMap.reviewComments.find(review => review.term.id === selectedReviewWindow.term.id)
      setSelectedReviewWindow(updatedReview ? updatedReview : null);
    }
  }, [provisionMap]);

  useEffect(() => {
    _setLoading(loading);
  }, [loading]);

  useEffect(() => {
    _setError(error);
  }, [error]);

  useEffect(() => {
    if (_provisionMap && !arrays.isEmpty(_provisionMap.reviewComments)) {
      setSelectedReviewWindow(_provisionMap.reviewComments[0]);
    }
  }, [_provisionMap])

  const getReviewWindow = (senReviewStart: string) => {
    const start = moment(senReviewStart);
    return `${start.format("DD/MM/YY")} - ${start.add(7, "days").format("DD/MM/YY")}`
  }

  const handleModalCancel = () => {
    setSelectedComment(null);
  };

  const handleCommentSaved = (comment: ProvisionMapCommentView) => {
    setSelectedComment(null);
  };

  const handleEditComment = (comment: ProvisionMapCommentView) => {
    setSelectedComment(comment);
  };

  const handleDeleteComment = (comment: ProvisionMapCommentView) => {
    if (window.confirm("This will delete the selected comment. This cannot be undone. Are you sure?")) {
      provisionMapActions.deleteProvisionMapComment(
        _provisionMap.student.id,
        _provisionMap.id,
        comment.id,
        () => {
          ToastService.pop("Comment Deleted", null, "hand-holding-seedling");
        }
      );
    }
  };

  const handleAddComment = () => {
    setSelectedComment({
      id: null,
      content: null,
      term: null,
      createdBy: null,
      createdDate: null,
    });
  };

  if (_error) {
    return (
      <EmptyMessage
        cover
        title="An error occurred"
        icon="hand-holding-seedling"
        summary="There was a problem loading the provision map"
      />
    );
  }

  if (_loading) {
    return <Loader size={Size.Medium} cover />;
  }

  if (arrays.isEmpty(_provisionMap?.reviewComments) && !_provisionMap?.reviewable) {
    return (
      <EmptyMessage
        cover
        title="There are no comments for this provision map"
        icon="hand-holding-seedling"
        summary={
          _provisionMap?.status === ProvisionMapStatus.Closed
          ? "This provision map is now closed and no further comments can be added"
          : "Comments can be added next time a review window is open"
        }
      />
    );
  }

  return (
    <>
    
    <Wrapper>

      <ListWrapper>
        <List>
          {_provisionMap?.reviewComments.map((review, index) => (
            <List.Item
              key={index}
              text={review.term.name}
              sub={getReviewWindow(review.term.senReviewStart)} 
              selected={selectedReviewWindow === review}
              onClick={() => setSelectedReviewWindow(review)}
              right={<ReviewChip active={_provisionMap?.reviewable} />}
            />
          ))}
          {(_provisionMap?.reviewable && !_provisionMap.reviewComments.some(x => x.term.id == _provisionMap.nextReviewTerm?.id)) && (
            <List.Item
              text="Add Review Comments"
              transparent
              icon="plus"
              onClick={handleAddComment}
            />
          )}
        </List>
      </ListWrapper>

      <CommentWrapper>
        { selectedReviewWindow != null && (
          <>
            { arrays.isEmpty(selectedReviewWindow?.comments) ? (
              <Card>
                <Card.Body noPad={false}>
                  <EmptyMessage
                    cover
                    title="No Comments"
                    icon="hand-holding-seedling"
                    subStyle={{ 
                      whiteSpace: "pre-line", 
                      display: "block",
                      lineHeight: `${Spacing.Default}px`
                    }}
                    summary={`
                      No comments have been added for this student. \n
                      ${_provisionMap?.reviewable 
                        ? `Comments can be added`
                        : "The comments window is now closed"
                      }
                    `}
                  >
                    {_provisionMap?.reviewable && (
                      <Button
                        text="Add Comment"
                        size={Size.Small}
                        color={Swatches.Primary}
                        onClick={handleAddComment}
                      />
                    )}
                  </EmptyMessage>
                </Card.Body>
              </Card>
            ) : (
              <>
              { selectedReviewWindow?.comments.map((comment: ProvisionMapCommentView, index: number) => (
                <React.Fragment key={index}>
                  <Message text={deleteError} color={Swatches.Danger} />
                  <Card>
                    <Card.Body>
                      <CommentHeader>
                        <Left>
                          <Avatar
                            user={comment.createdBy}
                            size={Size.Small}
                            sub={moment(comment.createdDate).format("DD/MM/YYYY hh:mm")}
                          />
                        </Left>
                        <Right>
                          {_provisionMap?.reviewable &&
                            user.id === comment.createdBy.id && (
                              <SplitButton
                                size={Size.Medium}
                                text="Edit"
                                color={Swatches.Low}
                                onDefaultClick={() => handleEditComment(comment)}
                                working={deleting}
                              >
                                <SplitButton.Option
                                  text="Edit"
                                  onClick={() => handleEditComment(comment)}
                                  show
                                />
                                <SplitButton.Option
                                  text="Delete"
                                  color={Swatches.Danger}
                                  onClick={() => handleDeleteComment(comment)}
                                  show
                                />
                              </SplitButton>
                            )}
                        </Right>
                      </CommentHeader>
                      <HtmlText html={comment.content} />
                    </Card.Body>
                  </Card>
                </React.Fragment>
              ))}
              
              {_provisionMap?.reviewable && (
                <Card>
                  <Card.Body noPad>
                    <Button
                      text="Add Comment"
                      size={Size.Small}
                      color={Swatches.Primary}
                      onClick={handleAddComment}
                    /> 
                  </Card.Body>
                </Card>
              )}
              </>
            )}
          </>
        )}
      </CommentWrapper>

    </Wrapper>

    <CommentModal
      provisionMap={_provisionMap}
      open={selectedComment != null}
      comment={selectedComment}
      onCancel={handleModalCancel}
      onSave={handleCommentSaved}
    />

    </>
  );
};

export default ReviewComments;
