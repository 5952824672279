import { DetailLabel, HelpPopup, Table } from "ui-kit";
import { DetentionStudentViewExt } from "./bulkRescheduleModal";
import Moment from "react-moment";
import { BehaviourWrapper } from "./missedDetentions/missedDetentionsTableRow";
import { Avatar } from "sharedComponents/common";


interface IBulkRescheduleSuccessTableProps {
  detentions: DetentionStudentViewExt[];
  loading?: boolean;
}


const BulkRescheduleSuccessTable: React.FC<IBulkRescheduleSuccessTableProps> = ({ 
  detentions,
  loading 
}) => {

  if (detentions.length < 1) {
    return (
      <></>
    )
  }

  return (
    <>
      <Table
        grow
        loading={loading}
        caption={"Valid Detentions"}
      >
        <Table.Header>
          <Table.HeaderCell width={2}>Student</Table.HeaderCell>
          <Table.HeaderCell width={1}>Behaviour</Table.HeaderCell>
          <Table.HeaderCell width={1}>New Date</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {detentions.map((detention, index) => (
            <Table.Row key={index} style={{backgroundColor: "rgba(51, 153, 0, 0.25)"}} >
              <Table.Cell width={2}>
                <Avatar
                  user={detention.student}
                  sub={
                    detention.tutorGroup?.friendlyName
                      ? `${detention.student.simsId} | ${detention.tutorGroup.friendlyName}`
                      : detention.student.simsId
                  }
                />
              </Table.Cell>
              <Table.Cell width={1}>
                <BehaviourWrapper>
                  <DetailLabel bold label={detention.codeDisplayName} />
                  <HelpPopup title={detention.categoryName} />
                </BehaviourWrapper>
              </Table.Cell>
              <Table.Cell width={1}>
                <Moment date={detention.startDate} format="DD/MM/YYYY HH:mm" />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  )
}

export default BulkRescheduleSuccessTable;