import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import pendingAttentionBehaviourActions from "../../actions/behaviour/pendingAttentionActions";
import { IBeehiveError } from "types/common/errors.types";
import { IBeehiveAction } from "types/common/action.types";


interface IPendingAttentionBehaviourState {
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IPendingAttentionBehaviourState = {
  loading: false,
  error: null,
};

const { AUTHORISEPENDINGBEHAVIOUR } = pendingAttentionBehaviourActions.types;
const { GETBEHAVIOUR } = behaviourActions.types;

const pendingAttentionBehaviourReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IPendingAttentionBehaviourState => {

  switch (action.type) {
    case AUTHORISEPENDINGBEHAVIOUR.START:
      return { ...state, loading: true, error: null };

    case AUTHORISEPENDINGBEHAVIOUR.SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case AUTHORISEPENDINGBEHAVIOUR.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GETBEHAVIOUR.START:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default pendingAttentionBehaviourReducer;
