import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DetentionForm from "../../detentions/detentionForm";
import { useSelector } from "react-redux";
import { todayDate, formatDate } from "utils/dateTime";
import { Size } from "ui-kit/common/size";
import moment from "moment";
import schoolActions from "areas/planner/actions/school/schoolActions";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import detentionTypesActions from "areas/behaviour/actions/detentions/detentionTypesActions";
import { Loader } from "ui-kit";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import termDatesService from "services/termDatesService";
import { IDetentionDetails } from "areas/behaviour/utils/detentions";
import { useAppSelector } from "reducers/hooks";
import { IBeehiveError } from "types/common/errors.types";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { objects } from "utils";
import { DateListView } from "areas/planner/types/termDatesResponse.types";


interface IMultipleDetentionFormWrapperProps {
  schoolId: number;
  detentionDetails: IDetentionDetails;
  setDetentionDetails: (detentionDetails: IDetentionDetails) => void
}


const MultipleDetentionFormWrapper: React.FC<IMultipleDetentionFormWrapperProps> = ({
  schoolId,
  detentionDetails,
  setDetentionDetails,
}) => {

  const {
    detentionTypes,
    loading,
    error: detentionTypesError,
  } = useAppSelector(state => state.detentionTypes);
  const { schoolInformation } = useAppSelector(state => state.school);
  const { detentionRooms, error: detentionRoomsError } = useAppSelector(
    state => state.detentionRooms
  );

  const [detentionTypesArray, setDetentionTypesArray] = useState<IDropdownDataItemProps<string, number, null>[]>([]);
  const [roomsArray, setRoomsArray] = useState<IDropdownDataItemProps<string, number, null>[]>([]);
  //   const [dateCheckResponse, setDateCheckResponse] = useState(null);
  const [checkedDatesError, setCheckedDatesError] = useState<IBeehiveError>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //   const validateDetentionDateAction = useAction(validateDetentionDate);

  useEffect(() => {
    // console.log(detentionDetails);
    if (!schoolInformation || schoolInformation.id !== schoolId) {
      schoolActions.getSchoolInformation(schoolId);
      detentionActions.getDetentionRooms(schoolId);
      detentionTypesActions.getDetentionTypes(schoolId);
    } else {
      if (!detentionTypes || detentionTypes.length === 0) {
        detentionTypesActions.getDetentionTypes(schoolId);
      }
      if (!detentionRooms || detentionRooms.length === 0) {
        detentionActions.getDetentionRooms(schoolId);
      }
    }

    const detentionDateTime = detentionDetails
      ? moment(
          formatDate(detentionDetails.date) + " " + detentionDetails.time,
          "DD/MM/YYYY HH:mm"
        ).format()
      : moment(
          todayDate() + " " + detentionDetails.time,
          "DD/MM/YYYY HH:mm"
        ).format();

    // console.log(detentionDateTime);

    checkTermDates(detentionDateTime);
  }, []);

  useEffect(() => {
    if (detentionRooms.length > 0) {
      const roomsArray = detentionRooms.map(room => ({
        key: room.roomId,
        label: room.roomName,
        value: room.roomId,
      }));

      setRoomsArray(roomsArray);
    }
  }, [detentionRooms]);

  useEffect(() => {
    const detentions = detentionTypes.map(detentionType => ({
      key: detentionType.detentionTypeId,
      label: detentionType.detentionTypeName,
      value: detentionType.detentionTypeId,
    }));

    if (detentionDetails && detentionDetails.detentionTypeId) {
      const selectedDetentionTypeId = detentionTypes.find(
        detentionType =>
          detentionType.detentionTypeId === detentionDetails.detentionTypeId
      );

      if (selectedDetentionTypeId === undefined) {
        detentions.push({
          key: detentionDetails.detentionTypeId,
          label: detentionDetails.detentionTypeName,
          value: detentionDetails.detentionTypeId,
        });
      }
    }

    setDetentionTypesArray(detentions);
  }, [detentionTypes, detentionDetails]);

  const onDateCheckSuccess = (response: DateListView) => {
    const tempDetentionDetails = objects.deepClone(detentionDetails);
    // console.log(detentionDetails.time);
    if (response.nextAvailableDate) {
      const validDate = moment(response.nextAvailableDate);

      setDetentionDetails({
        ...tempDetentionDetails,
        id:
          detentionDetails && detentionDetails.id ? detentionDetails.id : null,
        date: validDate,
        // time: detentionDetails.time,
      });
    } else {
      setDetentionDetails({
        ...tempDetentionDetails,
        date: moment(response?.dates?.[0]?.date),
      });
    }
  };

  //CALL TERM DATES CHECK

  const checkTermDates = async (startDate: string) => {
    setIsLoading(true);
    termDatesService
      .checkTermDates(
        moment(startDate, "YYYY-MM-DD").toDate(),
        null,
        schoolId,
        true,
        true,
        detentionDetails.detentionPeriodId
      )
      .then(response => {
        onDateCheckSuccess(response);
        setCheckedDatesError(null);
        setIsLoading(false);
      })
      .catch(error => {
        setCheckedDatesError(error);
        setIsLoading(false);
      });
  };

  if (loading) {
    return <Loader size={Size.Medium} fluid />;
  }

  return detentionDetails ? (
    <>
      {detentionRoomsError && (
        <ApiExceptionMessage error={detentionRoomsError} />
      )}

      <DetentionForm
        detentionDetails={detentionDetails}
        onChangeDetentionDetails={setDetentionDetails}
        detentionTypesDropdownArray={detentionTypesArray}
        detentionRooms={roomsArray}
        dateCheckError={checkedDatesError}
        loadingDateCheck={isLoading}
        schoolId={schoolId}
      />
    </>
  ) : null;
};


export default MultipleDetentionFormWrapper;
