import React, { useState } from "react";
import { ActionBar, Button, Icon, Right, Size, Table } from "ui-kit";
import { danger, success, Swatches } from "ui-kit/common/colors";
import EventAttendance from "./eventAttendance";
import StudentModal from "sharedComponents/common/users/studentModal";

const EventsTable = ({
  student,
  events,
  loading,
  handlePage,
  paging,
  showAttendance,
}) => {
  const [openEventModal, setOpenEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventClick = event => {
    setSelectedEvent(event);
    setOpenEventModal(true);
  };

  const handleCloseEventModal = () => {
    setSelectedEvent(null);
    setOpenEventModal(false);
  };

  return (
    <>
      <Table grow loading={loading}>
        <Table.Header>
          <Table.HeaderCell width={3}>Event</Table.HeaderCell>
          <Table.HeaderCell width={3}>Event Category</Table.HeaderCell>
          <Table.HeaderCell width={0.2} right>
            Signed Up
          </Table.HeaderCell>
          <Table.HeaderCell
            width={0.2}
            right
            show={showAttendance ? true : false}
          >
            {"Attendance (%)"}
          </Table.HeaderCell>
        </Table.Header>

        <Table.Body onPage={handlePage} paging={paging}>
          {events?.map(event => (
            <Table.Row onClick={() => handleEventClick(event)}>
              <Table.Cell width={3}>
                <b>{event.title}</b>
              </Table.Cell>
              <Table.Cell width={3}>{event.categoryName}</Table.Cell>
              <Table.Cell width={0.3} right>
                {
                  <Icon
                    value={event.signedUp ? "check" : "times"}
                    color={event.signedUp ? success : danger}
                    size={Size.Default}
                  />
                }
              </Table.Cell>
              <Table.Cell
                width={0.3}
                right
                show={showAttendance ? true : false}
              >
                {event.attendancePercentage.toFixed(2)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {openEventModal && selectedEvent && (
        <StudentModal
          width="80%"
          height="75%"
          open={openEventModal}
          onClose={handleCloseEventModal}
          title={`${selectedEvent.title}`}
          student={student}
          footer={
            <ActionBar low>
              <Right>
                <Button
                  text="Close"
                  onClick={handleCloseEventModal}
                  size={Size.Small}
                  color={Swatches.Low}
                />
              </Right>
            </ActionBar>
          }
        >
          <EventAttendance studentId={student.id} event={selectedEvent} />
        </StudentModal>
      )}
    </>
  );
};

export default EventsTable;
