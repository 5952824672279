import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  fontStyle,
  displayFont,
  typescale,
  honeygold,
  neutral,
  BorderRadius,
} from "ui-kit";

const Wrapper = styled.div`
  height: inherit;
  background-color: ${neutral[100]};

  .slot-background {
    border-radius: ${BorderRadius.Default}px;
    position: relative;
    top: 1.7rem;
    min-height: 60px;
    border: ${neutral[300]} 2px dashed;
    height: 6rem;
    box-sizing: border-box;
  }

  ${({ flags, isReadingAge }) =>
    flags > 5 && isReadingAge === true
      ? `
  .slot-background {
    height: 6rem;
    min-height: 6rem;
  }`
      : (flags > 5 && isReadingAge === false) ||
        (flags > 0 && isReadingAge === true)
      ? `
  .slot-background {
    height: 5.3rem;
    min-height: 5.3rem;
  }`
      : (flags === 0 || (flags > 0 && isReadingAge === false)) &&
        `
    .slot-background {
      height: 4.3rem;
      min-height: 4.3rem;
    }`}

  ${({ hideSensitive }) =>
    hideSensitive &&
    `
      .slot-background {
        height: 4.3rem;
      }
    `}

  .empty-slot {
    display: flex;
    position: absolute;
    justify-content: center;
    width: 100%;

    .empty-profile-image {
      height: 48px;
      width: 48px;
      min-height: 48px;
      min-width: 48px;
      border: ${neutral[300]} 2px dashed;
      border-radius: 50%;
      background: ${neutral[100]};
      z-index: 100;
      display: flex;

      .add-sign {
        display: none;
        align-self: center;
        width: 100%;
        text-align: center;
        ${fontStyle(displayFont.medium, typescale.header1, honeygold)}
      }
    }
  }

  &.display-add {
    .empty-slot .empty-profile-image .add-sign {
      display: block;
    }

    .slot-background,
    .empty-slot .empty-profile-image {
      border: ${honeygold} 2px dashed;
    }
  }
`;

const ClassLayoutStudentSlot = ({ maxFlagsAndReadingAge }) => {
  const { isPrivate } = useSelector(state => state.privacy);
  return (
    <Wrapper
      className="empty-slot-wrapper"
      hideSensitive={isPrivate.sensitiveInfo}
      flags={maxFlagsAndReadingAge?.maxFlags}
      isReadingAge={maxFlagsAndReadingAge?.isReadingAge}
    >
      <div className="empty-slot">
        <div className="empty-profile-image">
          <div className="add-sign">+</div>
        </div>
      </div>
      <div className="slot-background" />
    </Wrapper>
  );
};

export default ClassLayoutStudentSlot;
