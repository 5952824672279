import React, { useState, useEffect } from "react";
import {
  DetailLabel,
  fontStyle,
  displayFont,
  typescale,
  Spacing,
  Size,
  List,
  Icon,
  neutral,
} from "ui-kit";
import styled from "styled-components";
import { success, danger, blue } from "ui-kit/common/colors";
import { DETENTION_STATUS_KEYS } from "areas/behaviour/constants/detentions";
import ActivityDate from "areas/planner/components/activityFeed/activityDate";
import { DetentionDetailView } from "areas/behaviour/types/detentionRegisterResponse.types";


const DetentionItem = styled.div`
  display: flex;
  align-items: center;

  .icon {
    margin-top: 0;
    color: ${({ color }) => color};
  }

  .detail-label {
    margin-bottom: ${Spacing.Small}px;
  }

  .detention-details {
    ${fontStyle(displayFont.roman, typescale.paragraph, neutral[700])}
    margin-left: ${Spacing.Medium}px;
  }
`;


interface IDetentionHistoryListItemProps {
  detention: DetentionDetailView;
}


const DetentionHistoryListItem: React.FC<IDetentionHistoryListItemProps> = ({ detention }) => {

  const [icon, setIcon] = useState({
    value: "clock",
    color: neutral[800],
    tooltip: "Issued",
    tooltipSub: "The detention has been issued",
  });

  useEffect(() => {
    switch (detention.statusId) {
      case DETENTION_STATUS_KEYS.ISSUED:
        setIcon({
          value: "clock",
          color: blue,
          tooltip: "Issued",
          tooltipSub: "The detention has been issued",
        });
        break;

      case DETENTION_STATUS_KEYS.ATTENDED:
        setIcon({
          value: "check-circle",
          color: success,
          tooltip: "Attended",
          tooltipSub: "The detention was attended",
        });
        break;

      case DETENTION_STATUS_KEYS.MISSED:
        setIcon({
          value: "times-circle",
          color: danger,
          tooltip: "Missed",
          tooltipSub: "The detention was missed",
        });
        break;

      case DETENTION_STATUS_KEYS.RESCHEDULED:
        setIcon({
          value: "history",
          color: neutral[500],
          tooltip: "Rescheduled",
          tooltipSub: "The detention was rescheduled",
        });
        break;

      default:
        setIcon({
          value: "clock",
          color: neutral[500],
          tooltip: "Issued",
          tooltipSub: "The detention has been issued",
        });
    }
  }, []);

  return (
    <List.Item
      left={
        <DetentionItem color={icon.color}>
          <Icon
            value={icon.value}
            color={icon.color}
            size={Size.ExtraLarge}
            tooltip={icon.tooltip}
            tooltipSub={icon.tooltipSub}
          />
          <div className="detention-details">
            <DetailLabel
              label={detention.detentionTypeName}
              sub={<ActivityDate date={detention.startDate} />}
              bold
            />
            <DetailLabel
              label={
                <>
                  <b>{`${detention.codeDisplayName} - ${detention.categoryName}`}</b>{" "}
                  <span className="given-by-text">given by</span>{" "}
                  <b>{`${detention.awardedBy}`}</b>
                </>
              }
            />
          </div>
        </DetentionItem>
      }
    />
  );
};

export default DetentionHistoryListItem;
