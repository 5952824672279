import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import storeActions from "areas/payments/actions/storeActions";
import { 
  Size, 
  Loader, 
  EmptyMessage 
} from "ui-kit";
import { arrays } from "utils";
import ProductList from "./productList";
import { RootState } from "reducers/store";


const Products = () => {

  const { products, loading, error } = useSelector((state: RootState) => state.storeProducts);
  const { user } = useSelector((state: RootState) => state.currentUser);

  useEffect(() => {
    if (user && arrays.isEmpty(products)) {
      storeActions.getProductsForUser(user.id);
    }
  }, []);

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem loading your products"
        cover
      />
    );
  }

  return <ProductList products={products} />;
};

export default Products;
