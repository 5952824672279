import { useDispatch } from "react-redux";

const useAction = (passedAction: any) => {
  const dispatch = useDispatch();

  const dispatchAction = async function (...args: any[]) {
    return new Promise((resolve, reject) => {
      dispatch(
        passedAction(...args)
        // .then((data) => resolve(data))
        // .error((data) => resolve(data))
      );
    });
  };

  return dispatchAction;
};

export default useAction;
