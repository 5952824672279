import React from "react";
import PropTypes from "prop-types";
import {
  Swatches,
  Button,
  Left,
  ActionBar,
  Right,
  Text,
  StructuredList,
  Card,
  HtmlText,
} from "ui-kit";
import SmartTaskStatusChip from "./smartTaskStatusChip";
import { arrays, users } from "utils";
import {
  EVENT_TYPE_KEYS,
  TIME_PERIOD_KEYS,
  TIME_PERIOD_NAME,
} from "areas/behaviour/constants/smartTasks";
import { SchoolSmartTaskView } from "areas/behaviour/types/smartTasks/smartTaskResponse.types";
import { EventName, TimePeriodName } from "areas/behaviour/types/smartTasks/smartTaskShared.types";


const timePeriod = (timePeriodNameId: TimePeriodName) => {
  const smartTaskTimePeriod = TIME_PERIOD_NAME.find(
    ({ key }) => key === timePeriodNameId
  );
  return smartTaskTimePeriod.label;
};


interface IReadOnlyConditionsProps {
  smartTask: SchoolSmartTaskView;
}
export const ReadOnlyConditions: React.FC<IReadOnlyConditionsProps> = ({ smartTask }) => {

  const getConditionUnit = (smartTaskType: EventName) => {
    switch (smartTaskType) {
      case EventName.RewardPoints:
        return "Reward Points";
      case EventName.BehaviourPoints:
        return "Behaviour Points";
      case EventName.Event:
        return "Hours attended";
    }
  }

  return (
    <StructuredList.Item
      name="Condition"
      description="Conditions that have to be met to trigger the smart task"
    >
      <Text>
        {`${smartTask.eventFrequency} ${getConditionUnit(smartTask.eventNameId)} in ${
          smartTask.timePeriodNameId !== TIME_PERIOD_KEYS.ACADEMIC_YEAR
            ? smartTask.timePeriodFrequency
            : ""
        } ${timePeriod(smartTask.timePeriodNameId)}`}

        {smartTask.behaviourCodeId !== null && (
          <span>
            {` for Reward Code `}
            &quot;{smartTask.behaviourCodeName}&quot;
          </span>
        )}

        {smartTask.schoolBehaviourCategoryId !== null && (
          <span>
            {` for Reward Category `}
            &quot;{smartTask.schoolBehaviourCategoryName}&quot;
          </span>
        )}

        {smartTask.calendarCategoryId !== null && (
          <span>
            {` for Event Category `}
            &quot;{smartTask.calendarCategoryName}&quot;
          </span>
        )}
      </Text>
    </StructuredList.Item>
  );
};


interface IReadOnlyOtherConditionsProps {
  smartTask: SchoolSmartTaskView
}
export const ReadOnlyOtherConditions: React.FC<IReadOnlyOtherConditionsProps> = ({ smartTask }) => {
  return (
    (smartTask.subjectId !== null ||
      smartTask.classGroupId !== null ||
      smartTask.yearGroupId !== null) && (
      <>
        {smartTask.subjectId !== null && (
          <StructuredList.Item
            name="Subject"
            description="The subject that the smart task is set for"
          >
            <Text>{smartTask.subjectName}</Text>
          </StructuredList.Item>
        )}

        {smartTask.classGroupId !== null && (
          <StructuredList.Item
            name="Class"
            description="The class that the smart task is set for"
          >
            <Text>{smartTask.classGroupName}</Text>
          </StructuredList.Item>
        )}

        {smartTask.yearGroupId !== null && (
          <StructuredList.Item
            name="Year Group"
            description="The year group that the smart task is set for"
          >
            <Text>{`Year ${smartTask.yearGroupName}`}</Text>
          </StructuredList.Item>
        )}
      </>
    )
  );
};


interface IReadOnlySmartTaskProps {
  smartTask: SchoolSmartTaskView;
  openEditMode: () => void;
  openConfirmDelete: () => void;
}

const ReadOnlySmartTask: React.FC<IReadOnlySmartTaskProps> = ({ smartTask, openEditMode, openConfirmDelete }) => {

  const staffEmailRecipients = smartTask.staffEmailRecipients;

  const getTaskType = (smartTaskType: EventName) => {
    switch (smartTaskType) {
      case EventName.RewardPoints:
        return "Reward";
      case EventName.BehaviourPoints:
        return "Behaviour";
      case EventName.Event:
        return "Event";
    }
  }

  return (
    <>
      <StructuredList>
        <Card title="Smart Task Details">
          <Card.Body>
            <StructuredList.Item name="Name">
              <Text>{smartTask.name}</Text>
            </StructuredList.Item>
            <StructuredList.Item name="Type">
              <Text>{getTaskType(smartTask.eventNameId)}</Text>
            </StructuredList.Item>
            <StructuredList.Item name="Status">
              <SmartTaskStatusChip active={smartTask.isActive} />
            </StructuredList.Item>
            <StructuredList.Item
              name="Multiple Triggers"
              description="Allowed - The smart task will trigger every time the trigger conditions are met by a student. &#13; 
                Not Allowed - The smart task will only be triggered once for every student."
            >
              {smartTask.allowMultiple ? "Allowed" : "Not Allowed"}
            </StructuredList.Item>

            <ReadOnlyConditions smartTask={smartTask} />
            <ReadOnlyOtherConditions smartTask={smartTask} />

          </Card.Body>
        </Card>

        <Card title="Actions">
          <Card.Body>
            <StructuredList.Item name="Email Student" />

            {smartTask.sendNotificationToParents && (
              <StructuredList.Item name="Email Parents">
                <HtmlText html={smartTask.actionNote} />
              </StructuredList.Item>
            )}

            {!arrays.isEmpty(staffEmailRecipients) && (
              <StructuredList.Item name="Email Teachers">
                <Text>
                  {staffEmailRecipients.map(
                    (teacher, index) =>
                      users.getFullName(teacher) +
                      `${index === staffEmailRecipients.length - 1 ? "" : ", "}`
                  )}
                </Text>
              </StructuredList.Item>
            )}

            {smartTask.sendNotificationToPastoral && (
              <StructuredList.Item name="Email SSC" />
            )}

            {smartTask.sendNotificationToLeadership && (
              <StructuredList.Item name="Email Leadership" />
            )}
          </Card.Body>
        </Card>
      </StructuredList>

      <ActionBar>
        <Left>
          <Button text="Edit" color={Swatches.Primary} onClick={openEditMode} />
        </Left>
        <Right>
          <Button
            text="Delete"
            color={Swatches.Danger}
            onClick={openConfirmDelete}
            disabled={smartTask.isTriggered}
            disabledTooltip={"Cannot delete a triggered smarttask"}
          />
        </Right>
      </ActionBar>
    </>
  );
};


export default ReadOnlySmartTask;
