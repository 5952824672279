import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { ArticleCategoryFeatureView, ArticleCategoryListView, ArticleDetailView, CommentListView } from "../types/newsResponse.types";
import { PostCommentCommand } from "../types/newsRequest.types";

const prefix = "magazine";
const builder = new actionTypeBuilder(prefix);

const types = {
  MAGAZINE_GETCATEGORIES: builder.build("getcategories"),
  MAGAZINE_GETHOME: builder.build("gethome"),
  MAGAZINE_GETLATEST: builder.build("getlatest"),
  MAGAZINE_GETCATEGORY: builder.build("getcategory"),
  MAGAZINE_GETCATEGORYLATEST: builder.build("getcategorylatest"),
  MAGAZINE_GETFEATURE: builder.build("getfeature"),
  MAGAZINE_GETFEATURECOMMENTS: builder.build("getfeaturecomments"),
  MAGAZINE_POSTCOMMENT: builder.build("postcomment"),
  MAGAZINE_APPROVECOMMENT: builder.build("approvecomment"),
  MAGAZINE_REMOVECOMMENT: builder.build("removecomment"),
  MAGAZINE_FLAGCOMMENT: builder.build("flagcomment"),
};


const getFeatureCategories = (callback?: callbackType<ArticleCategoryListView[]>) =>
  action<ArticleCategoryListView[]>(
    () => client.get(`news/features/categories`),
    types.MAGAZINE_GETCATEGORIES,
    callback
  );

const getHomeFeatures = (pageIndex: number, callback?: callbackType<ArticleCategoryFeatureView>) =>
  action<ArticleCategoryFeatureView>(
    () => client.get(`news/features?pageIndex=${pageIndex}`),
    types.MAGAZINE_GETHOME,
    callback
  );

const getLatestFeatures = (callback?: callbackType<ArticleCategoryFeatureView>) =>
  action<ArticleCategoryFeatureView>(
    () => client.get(`news/features/latest`),
    types.MAGAZINE_GETLATEST,
    callback
  );

const getCategoryFeatures = (categoryId: number, pageIndex: number, callback?: callbackType<ArticleCategoryFeatureView>) =>
  action<ArticleCategoryFeatureView>(
    () => client.get(`news/features/categories/${categoryId}?pageIndex=${pageIndex}`),
    types.MAGAZINE_GETCATEGORY,
    callback
  );

const getCategoryLatestFeatures = (categoryId: number, callback?: callbackType<ArticleCategoryFeatureView>) =>
  action<ArticleCategoryFeatureView>(
    () => client.get(`news/features/categories/${categoryId}/latest`),
    types.MAGAZINE_GETCATEGORYLATEST,
    callback
  );

const getFeature = (featureId: number, callback?: callbackType<ArticleDetailView>) =>
  action<ArticleDetailView>(
    () => client.get(`news/articles/${featureId}`),
    types.MAGAZINE_GETFEATURE,
    callback
  );

const getFeatureComments = (categoryId: number, featureId: number, callback?: callbackType<CommentListView[]>) =>
  action<CommentListView[]>(
    () => client.get(`news/features/categories/${categoryId}/articles/${featureId}/comments`),
    types.MAGAZINE_GETFEATURECOMMENTS,
    callback
  );

const postComment = (data: PostCommentCommand, callback?: callbackType<CommentListView>) =>
  action<CommentListView>(
    () => client.post(`news/features/categories/${data.categoryId}/articles/${data.articleId}/comments`, data),
    types.MAGAZINE_POSTCOMMENT,
    callback
  );

const approveComment = (categoryId: number, featureId: number, commentId: number, callback?: callbackType<CommentListView>) =>
  action<CommentListView>(
    () => client.post(`news/features/categories/${categoryId}/articles/${featureId}/comments/${commentId}/approve`),
    types.MAGAZINE_APPROVECOMMENT,
    callback
  );

const flagComment = (categoryId: number, featureId: number, commentId: number, callback?: callbackType<CommentListView>) =>
  action(
    () => client.post(`news/features/categories/${categoryId}/articles/${featureId}/comments/${commentId}/flag`),
    types.MAGAZINE_FLAGCOMMENT,
    callback
  );

const removeComment = (categoryId: number, featureId: number, commentId: number, callback?: callbackType<null>) =>
  action<null>(
    () => client.delete(`news/features/categories/${categoryId}/articles/${featureId}/comments/${commentId}/remove`),
    types.MAGAZINE_REMOVECOMMENT,
    callback
  );


const magazineActions = {
  types,
  getFeatureCategories,
  getHomeFeatures,
  getLatestFeatures,
  getCategoryFeatures,
  getCategoryLatestFeatures,
  getFeature,
  getFeatureComments,
  postComment,
  approveComment,
  flagComment,
  removeComment,
};

export default magazineActions;
