import React, { useEffect, useState } from "react";
import ProvisionCostBandDropdown from "./provisionCostBandDropdown";
import {
  TextInput,
  TextInputType,
  Button,
  Swatches,
  Size,
  SplitButton,
  Spacing,
  Table,
  ValidationMessage,
  Label,
  Sub,
  Currency,
} from "ui-kit";
import styled from "styled-components";
import { arrays } from "utils";
import { ProvisionCostBandListView, ProvisionCostListView } from "areas/send/types/passportResponse.types";

const Wrapper = styled.div`
  display: flex;
  margin-bottom: ${Spacing.Medium}px;
  .dropdown {
    margin-right: ${Spacing.Medium}px;
    flex-grow: 1;
  }

  .button {
    margin-left: ${Spacing.Medium}px;
  }

  .textInput {
    min-width: 60px !important;
  }
`;

const EmptyWrapper = styled.div`
  padding: ${Spacing.Large}px;
  text-align: center;
`;


const DEFAULT_COSTBAND: ProvisionCostListView = {
  id: null,
  weighting: null,
  bandId: null,
  defaultCost: 0,
  name: "",
  cost: 0,
  totalCost: 0
};

interface ProvisionCostBandForm {
  costBands: ProvisionCostListView[];
  onChange?: (bands: ProvisionCostListView[]) => void;
}


const ProvisionCostBandForm: React.FC<ProvisionCostBandForm> = ({ onChange, costBands }) => {

  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_costBand, _setCostBand] = useState<ProvisionCostListView>(DEFAULT_COSTBAND);
  const [_costBands, _setCostBands] = useState<ProvisionCostListView[]>([]);
  const [_total, _setTotal] = useState<number>(0);
  const [_selectedIndex, _setSelectedIndex] = useState(null);

  useEffect(() => {
    costBands && _setCostBands(costBands);
  }, [costBands]);

  useEffect(() => {
    if (_costBands) {
      _costBands.length &&
        _setTotal(
          _costBands
            .map(x => x.defaultCost * x.weighting)
            .reduce((prev, next) => prev + next)
        );
    }
  }, [_costBands]);

  const handleSave = () => {
    var bands: ProvisionCostListView[] = [];

    // Validation
    var errors: string[] = [];

    if (!_costBand.bandId) {
      errors.push(`Please enter a cost band.`);
    }

    if (!_costBand.weighting || _costBand.weighting === 0) {
      errors.push(`Please enter a weighting.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    if (_costBand.id === null) {
      bands = [..._costBands, { ..._costBand, id: 0 }];
    } else {
      bands = _costBands.map((band, index) =>
        index === _selectedIndex ? _costBand : band
      );
    }

    _setCostBands(bands);
    _setCostBand(DEFAULT_COSTBAND);
    _setSelectedIndex(null);

    onChange?.(bands);
  };

  const handleEdit = (index: number) => {
    setValidationErrors([]);
    _setCostBand(_costBands[index]);
    _setSelectedIndex(index);
  };

  const handleDelete = (index: number) => {
    setValidationErrors([]);
    if (
      window.confirm(
        "This will remove this cost band from the provision group. Are you sure?"
      )
    ) {
      var bands = arrays.remove(_costBands, index);
      _setCostBands([...bands]);
    }
  };

  return (
    <>
      <ValidationMessage errors={validationErrors} />
      <Wrapper>
        <ProvisionCostBandDropdown
          value={_costBand.bandId}
          onChange={(value: number, label: string, data: ProvisionCostBandListView) =>
            _setCostBand({
              ..._costBand,
              bandId: data.id,
              defaultCost: data.defaultCost,
              name: data.name,
            })
          }
          fluid
        />
        <TextInput
          type={TextInputType.Number}
          value={_costBand?.weighting}
          onChange={value => _setCostBand({ ..._costBand, weighting: value })}
          placeholder="Weighting"
        />
        <Button
          size={Size.Small}
          text={_selectedIndex === null ? "Add" : "Save"}
          color={_selectedIndex === null ? Swatches.Primary : Swatches.Success}
          onClick={handleSave}
        />
      </Wrapper>
      {arrays.isEmpty(_costBands) ? (
        <EmptyWrapper>
          <Sub>No Cost Bands Added</Sub>
        </EmptyWrapper>
      ) : (
        <>
          <Table>
            <Table.Header>
              <Table.HeaderCell>Cost Band</Table.HeaderCell>
              <Table.HeaderCell>Weighting</Table.HeaderCell>
              <Table.HeaderCell right>Band Cost</Table.HeaderCell>
              <Table.HeaderCell right>Total Cost</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Header>
            <Table.Body>
              {_costBands.map((costBand: ProvisionCostListView, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <b>{costBand.name}</b>
                  </Table.Cell>
                  <Table.Cell>{costBand.weighting}</Table.Cell>
                  <Table.Cell right>
                    <Currency value={costBand.defaultCost} />
                  </Table.Cell>
                  <Table.Cell right>
                    <Currency
                      value={costBand.defaultCost * costBand.weighting}
                    />
                  </Table.Cell>
                  <Table.Cell right>
                    <SplitButton
                      color={Swatches.Low}
                      size={Size.Small}
                      text="Edit"
                      onDefaultClick={() => handleEdit(index)}
                    >
                      <SplitButton.Option
                        text="Edit"
                        onClick={() => handleEdit(index)}
                        show
                      />
                      <SplitButton.Option
                        text="Delete"
                        color={Swatches.Danger}
                        onClick={() => handleDelete(index)}
                        show
                      />
                    </SplitButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.Cell colspan={2}>
                  <Label bold>Hourly Cost</Label>
                </Table.Cell>
                <Table.Cell right>
                  <b>
                    <Currency value={_total} />
                  </b>
                </Table.Cell>
                <Table.Cell />
              </Table.Row>
            </Table.Footer>
          </Table>
        </>
      )}
    </>
  );
};

export default ProvisionCostBandForm;
