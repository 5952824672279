import paymentActions from "areas/payments/actions/paymentsActions";
import { ProductDetailView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


export interface IAdminProductState {
  product: ProductDetailView;
  error: IBeehiveError | null;
  loading: boolean;
  saving: boolean;
  saveError: IBeehiveError | null;
}

const INITIAL_STATE: IAdminProductState = {
  product: null,
  error: null,
  loading: false,
  saving: false,
  saveError: null
};

const adminProductReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAdminProductState => {
  const {
    PAYMENTS_GETPRODUCT,
    PAYMENTS_CREATEPRODUCT,
    PAYMENTS_SAVEPRODUCT
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETPRODUCT.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETPRODUCT.SUCCESS:
      return {
        ...state,
        product: action.payload,
        loading: false
      };

    case PAYMENTS_GETPRODUCT.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PAYMENTS_SAVEPRODUCT.START:
    case PAYMENTS_CREATEPRODUCT.START:
      return { 
        ...state, 
        saving: true, 
        saveError: null 
      };

    case PAYMENTS_SAVEPRODUCT.SUCCESS:
    case PAYMENTS_CREATEPRODUCT.SUCCESS:
      return {
        ...state,
        saving: false
      };

    case PAYMENTS_SAVEPRODUCT.FAILED:
    case PAYMENTS_CREATEPRODUCT.FAILED:
      return {
        ...state,
        saving: false,
        saveError: action.payload
      };

    default:
      return state;
  }
};

export default adminProductReducer;
