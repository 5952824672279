import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ActionBar, Button, Size, Swatches } from "ui-kit";
import { SchoolDropdown } from "sharedComponents/common";
import ProductActiveFilter from "./filters/productActiveFilter";
import inventoryFilterActions from "areas/payments/actions/inventoryFilterActions";
import ProductCategoryDropDown from "./productCategoryDropdown";
import { RootState } from "reducers/store";
import { ProductCategoryListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


interface IInventoryFilterProps {
  onEditClick?: () => void;
  onNewClick?: () => void;
  onNewProductClick?: () => void;
}


const InventoryFilter: React.FC<IInventoryFilterProps> = ({ onEditClick, onNewClick, onNewProductClick }) => {

  const { filter } = useSelector((state: RootState) => state.inventoryFilter);
  const { user } = useSelector((state: RootState) => state.currentUser);

  useEffect(() => {
    if (filter.schoolId == -1) {
      inventoryFilterActions.filterBySchool(user?.baseSchool.id);
    }
  }, [])

  const handleSchoolChange = (schoolId: number) => {
    inventoryFilterActions.filterBySchool(schoolId);
  }

  const handleCategoryChange = (category: ProductCategoryListView) => {
    inventoryFilterActions.filterByCategory(category ? category : null);
  }

  const handleActiveChange = (activeOption: number) => {
    inventoryFilterActions.filterByActive(activeOption);
  }

  const handleEditClick = () => {
    onEditClick?.();
  };

  const handleNewClick = () => {
    onNewClick?.();
  };

  const handleNewProductClick = () => {
    onNewProductClick?.();
  };


  return (
    <ActionBar>
      <SchoolDropdown
        onChange={handleSchoolChange}
        includeAllOption={false}
        includeTrust={false}
        initialValue={filter.schoolId}
      />
      <ProductCategoryDropDown
        onChange={handleCategoryChange}
        schoolId={filter.schoolId}
      />
      <ProductActiveFilter
        onChange={handleActiveChange}
        initialValue={filter.active}
      />
      <Button
        size={Size.Small}
        text="Edit"
        onClick={handleEditClick}
        disabled={filter.category?.id == -1}
        tooltip={
          filter.category?.id == -1
            ? null
            : `Edit the '${filter.category.name}' category`
        }
      />
      <Button
        size={Size.Small}
        text="New"
        onClick={handleNewClick}
        tooltip="Create a new category"
      />
      <Button
        size={Size.Small}
        text="New Product"
        color={Swatches.Primary}
        onClick={handleNewProductClick}
        tooltip="Add a a new item in this category"
        disabled={filter.category?.id == -1}
      />
    </ActionBar>
  )
}

export default InventoryFilter;