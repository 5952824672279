import { neutral, Swatches } from "../../../../ui-kit";
import { ColorSwatch, lightOrange } from "ui-kit/common/colors";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";
import { NavigateFunction } from "react-router";
import navigationService from "services/navigationService";
import { studentRoutes } from "areas/planner/plannerRoutes";
import { StudentProfileTabs, getStudentProfileTab } from "areas/planner/utils/studentRecordAreas";


interface SensitiveStatus {
  key?: number;
  text: string;
  description: string;
  tooltip: string;
  tooltipSub?: string;
  color: ColorSwatch;
  onClick?: (student: PlannerStudentDetailView, navigate?: NavigateFunction) => void;
}

const STUDENT_ABILITY_KEYS = {
  LAP: 0,
  MAP: 1,
  HAP: 2,
};

const STUDENT_SEN_KEYS = {
  NOSEN: 0,
  EHCP: 1,
  SEN: 2,
  SENH: 3,
};

const PP: SensitiveStatus = {
  key: 0,
  text: "PP",
  description: "Pupil Premium",
  tooltip: "Pupil Premium",
  color: Swatches.Purple,
};

const EAL: SensitiveStatus = {
  key: 1,
  text: "EAL",
  description: "English additional language",
  tooltip: "English as an additional language",
  color: Swatches.DeepPink,
};

const SEN = {
  key: 2,
  text: "SEN",
  description: "Special Educational Needs",
  tooltip: "Special Educational Needs",
  color: { swatch: "#006344", foreground: neutral[100] },
};

const FSM: SensitiveStatus = {
  key: 3,
  text: "FSM",
  description: "Free School Meal",
  tooltip: "Free School Meal",
  color: Swatches.Miami,
};

const GIFTED: SensitiveStatus = {
  text: "GT",
  description: "Gifted and Talented",
  tooltip: "Gifted and Talented",
  color: Swatches.Blue,
};

const PUPIL_PASSPORT: SensitiveStatus = {
  text: "Pas",
  description: "Pupil Passport",
  tooltip: "Current Pupil Passport",
  tooltipSub: "This student has a current pupil passport.",
  color: { swatch: "#009164", foreground: neutral[100] },
  onClick: (student) => {
    const passportTab = getStudentProfileTab(student, StudentProfileTabs.PupilPassport);
    navigationService.navigation(studentRoutes.getStudentTabPath(student.id, passportTab.path));
  },
};

const PROVISION_MAP: SensitiveStatus = {
  text: "Map",
  description: "Provision Map",
  tooltip: "Current Provision Map",
  tooltipSub: "This student has a current provision map.",
  color: { swatch: "#00b57d", foreground: neutral[100] },
  onClick: (student) => {
    const provisionTab = getStudentProfileTab(student, StudentProfileTabs.ProvisionMaps);
    navigationService.navigation(studentRoutes.getStudentTabPath(student.id, provisionTab.path));
  }
};

const ASSIGNED_STAFF: SensitiveStatus = {
  text: "SUP",
  description: "Assigned Staff",
  tooltip: "Assigned Staff Support",
  tooltipSub: "This student has assigned staff for support.",
  color: Swatches.Miami,
};

const LOOKED_AFTER: SensitiveStatus = {
  text: "LAC",
  description: "Looked After",
  tooltip: "Looked After",
  // tooltipSub: "This student is a looked after child.",
  color: { swatch: lightOrange, foreground: neutral[100] },
};

const CHILD_PROTECTION: SensitiveStatus = {
  text: "CP",
  description: "Child Protection",
  tooltip: "Child Protection",
  tooltipSub: "This student is subject to child protection.",
  color: Swatches.OrangeRed,
};

const BURSARY: SensitiveStatus = {
  text: "BUR",
  description: "Bursary",
  tooltip: "This student has a bursary",
  color: Swatches.Teal,
};

const sensitiveData = {
  STUDENT_ABILITY_KEYS,
  STUDENT_SEN_KEYS,
  PP,
  EAL,
  SEN,
  FSM,
  GIFTED,
  PUPIL_PASSPORT,
  PROVISION_MAP,
  ASSIGNED_STAFF,
  LOOKED_AFTER,
  CHILD_PROTECTION,
  BURSARY,
};

export default sensitiveData;