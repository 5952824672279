import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { StructuredList, Size } from "ui-kit";
import SchoolAvatar from "sharedComponents/common/schools/schoolAvatar";
import { Avatar } from "sharedComponents/common";
import Address from "sharedComponents/common/formatting/address";

const StudentDetails = ({ student }) => {
  const studentDetails = [
    {
      data: "School",
      value: student.school ? (
        <SchoolAvatar
          size={Size.Medium}
          school={student.school}
          label={student.school.name}
        />
      ) : null,
    },
    {
      data: "Admission No.",
      value: student.simsId,
    },
    {
      data: "Email",
      value: <a href={`mailto:${student.email}`}>{student.email}</a>,
    },

    {
      data: "Year Group",
      value: student.yearGroup ? student.yearGroup.friendlyName : null,
    },
    {
      data: "Tutor Group",
      value: student.tutorGroup ? student.tutorGroup.friendlyName : null,
    },
    {
      data: "Tutor",
      value: <Avatar user={student.tutor} />
    },
    {
      data: "Date of Birth",
      value: moment(student.dateOfBirth).format("DD/MM/YYYY"),
    },
  ];

  return (
    <StructuredList>
      {studentDetails.map((dataItem, index) => (
        <StructuredList.Item key={index} name={dataItem.data}>
          {dataItem.value}
        </StructuredList.Item>
      ))}

      {student.address && (
        <StructuredList.Item name="Address">
          <Address address={student.address} />
        </StructuredList.Item>
      )}
    </StructuredList>
  );
};

StudentDetails.propTypes = {
  student: PropTypes.object,
};

export default StudentDetails;
