import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ManagePendingAttentionForm from "./managePendingAttentionForm";
import { useSelector } from "react-redux";
import { getDateTime, formatDate } from "utils/dateTime";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import schoolActions from "areas/planner/actions/school/schoolActions";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import detentionTypesActions from "areas/behaviour/actions/detentions/detentionTypesActions";
import pendingAttentionBehaviourActions from "areas/behaviour/actions/behaviour/pendingAttentionActions";
import { ApiExceptionMessage } from "sharedComponents/common";
import { useAppSelector } from "reducers/hooks";
import { UserDetailListView } from "types/users/userListViews.types";
import { AuthorisedPendingBehaviourCommand, BehaviourView, CreateDetention } from "areas/behaviour/types/behaviourResponses.types";
import { IDetentionDetails } from "areas/behaviour/utils/detentions";


interface IManagePendingAttentionFormWrapperProps {
  student: UserDetailListView;
  schoolId: number;
  behaviour: BehaviourView;
  closeModal: () => void;
  handleRevokeBehaviour: () => void;
}

export interface IBehaviourCategory {
  behaviourCodeId: number;
  behaviourCategoryId: number;
  points: number;
  severityIndex: number;
}


const ManagePendingAttentionFormWrapper: React.FC<IManagePendingAttentionFormWrapperProps> = ({
  student,
  schoolId,
  behaviour,
  closeModal,
  handleRevokeBehaviour,
}) => {

  const { defaultDetention, loading, error } = useAppSelector(
    state => state.defaultDetention
  );
  const { schoolInformation } = useAppSelector(state => state.school);
  const { detentionSummary } = useAppSelector(state => state.detentionSummary);

  const [behaviourCategory, setBehaviourCategory] = useState<IBehaviourCategory>({
    behaviourCodeId: null,
    behaviourCategoryId: null,
    points: null,
    severityIndex: null
  });

  useEffect(() => {
    if (behaviour) {
      setBehaviourCategory({
        behaviourCodeId: behaviour.behaviourCodeId,
        behaviourCategoryId: behaviour.categoryId,
        points: behaviour.points,
        severityIndex: behaviour.behaviourCodeSeverityIndex,
      });
    }
  }, [behaviour]);

  useEffect(() => {
    if (behaviourCategory.behaviourCategoryId) {
      behaviourActions.getDefaultDetention(
        schoolId,
        student.id,
        behaviourCategory.behaviourCategoryId
      );
    }
  }, [behaviourCategory]);

  useEffect(() => {
    if (schoolId && (!schoolInformation || schoolInformation.id !== schoolId)) {
      schoolActions.getSchoolInformation(schoolId);
    }
    detentionActions.getDetentionRooms(schoolId);
    detentionTypesActions.getDetentionTypes(schoolId);
  }, [schoolId]);

  const handleAuthorisePendingBehaviour = (detentions: IDetentionDetails[], investigationNotes: string) => {
    const newDetentions: CreateDetention[] = [];

    detentions.forEach(detention => {
      let detentionDateTime = getDateTime(
        formatDate(detention.date),
        detention.time
      );

      newDetentions.push({
        detentionTypeId: detention.detentionTypeId,
        detentionDate: detentionDateTime,
        roomId: detention.detentionLocationId,
        duration: detention.duration,
        detentionNote: behaviour.investigationNote,
      });
    });

    let behaviourDetails: AuthorisedPendingBehaviourCommand = {
      behaviourId: behaviour.behaviourId,
      studentId: student.id,
      categoryId: behaviourCategory.behaviourCategoryId,
      investigationNote: investigationNotes,
      createDetentions: detentions.length > 0 ? newDetentions : null,
    };

    const onSuccess = () => {
      closeModal();
    };
    pendingAttentionBehaviourActions.authorisePendingBehaviour(
      student.id,
      behaviour.behaviourId,
      behaviourDetails,
      onSuccess
    );
  };

  return (
    <>
      <ApiExceptionMessage error={error} />
      <ManagePendingAttentionForm
        studentId={student.id}
        behaviour={behaviour}
        defaultDetention={defaultDetention}
        schoolInformation={schoolInformation}
        detentionSummary={detentionSummary}
        behaviourCategory={behaviourCategory}
        setBehaviourCategory={setBehaviourCategory}
        onClickAuthoriseBehaviour={handleAuthorisePendingBehaviour}
        closeModal={closeModal}
        handleRevokeBehaviour={handleRevokeBehaviour}
        loadingDefaultDetention={loading}
      />
    </>
  );
};


export default ManagePendingAttentionFormWrapper;
