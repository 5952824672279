import React, { useState } from "react";
import {
  StructuredList,
  Card,
  Size,
  ActionBar,
  Right,
  Button,
  Swatches,
} from "ui-kit";
import {
  Checkbox,
  RadioList,
  DatePicker,
  Spacing,
  displayFont,
  fontStyle,
  typescale,
  neutral,
} from "ui-kit";
import styled from "styled-components";
import DateRange from "../dateRange";
import { Gender } from "types/users/userDetail.types";
import moment from "moment";


const DatePickerWrapper = styled.span`
  margin-right: ${Spacing.Medium}px;
  ${fontStyle(displayFont.medium, typescale.paragraph, neutral[500])}
`;


interface IClassBehaviourReportFormProps {
  handleGenerateReport: (
    startDate?: string, 
    endDate?: string, 
    gender?: Gender, 
    SEN?: boolean, 
    EAL?: boolean, 
    PP?: boolean
  ) => void
}


const ClassBehaviourReportForm: React.FC<IClassBehaviourReportFormProps> = ({ handleGenerateReport }) => {

  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);

  const [SENFilter, setSENFilter] = useState<boolean>(false);
  const [EALFilter, setEALFilter] = useState<boolean>(false);
  const [PPFilter, setPPFilter] = useState<boolean>(false);
  const [genderFilter, setGenderFilter] = useState<Gender>(null);

  return (
    <>
      <Card title="Report Options">
        <Card.Body>
          <StructuredList>
            {/* <StructuredList.Item
              name="Date Range"
              description="Choose a start date for your report"
              helpPopup
            >
              <DatePickerWrapper>
                Start Date{" "}
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  selectedDate={startDate}
                  onChange={value => setStartDate(value)}
                  closeOnSelect
                />
              </DatePickerWrapper>

              <DatePickerWrapper>
                End Date{" "}
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  selectedDate={endDate}
                  onChange={value => setEndDate(value)}
                  closeOnSelect
                />
              </DatePickerWrapper>
            </StructuredList.Item> */}

            <DateRange
              startDate={startDate}
              handleStartDateChange={value => setStartDate(value)}
              endDate={endDate}
              handleEndDateChange={value => setEndDate(value)}
            />

            <StructuredList.Item
              name="Filters"
              description="Filter by Special Educational Needs"
              helpPopup
            >
              <Checkbox
                text="Special Educational Needs"
                checked={SENFilter}
                onChange={() => setSENFilter(!SENFilter)}
              />

              <Checkbox
                text="English as an Additional Language"
                checked={EALFilter}
                onChange={() => setEALFilter(!EALFilter)}
              />

              <Checkbox
                text="Pupil Premium"
                checked={PPFilter}
                onChange={() => setPPFilter(!PPFilter)}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Gender"
              description="Filter by Gender"
              helpPopup
            >
              <RadioList
                value={genderFilter}
                onChange={value => setGenderFilter(value)}
                fluid
              >
                <RadioList.Item label="All" value={null} />
                <RadioList.Item label="Male" value={Gender.Male} />
                <RadioList.Item label="Female" value={Gender.Female} />
              </RadioList>
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      <ActionBar>
        <Right>
          <Button
            text="Generate Report"
            color={Swatches.Success}
            size={Size.Small}
            onClick={() =>
              handleGenerateReport(
                moment(startDate).format("YYYY-MM-DD"),
                moment(endDate).format("YYYY-MM-DD"),
                genderFilter,
                SENFilter ? SENFilter : null,
                EALFilter ? EALFilter : null,
                PPFilter ? PPFilter : null
              )
            }
          />
        </Right>
      </ActionBar>
    </>
  );
};

export default ClassBehaviourReportForm;
