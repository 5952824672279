import { IBeehiveAction } from "types/common/action.types";
import awardAnalyticsActions from "../actions/awardAnalyticsActions";
import { IBeehiveError } from "types/common/errors.types";
import { GenerateAwardReportView } from "../types/awardAnalyticsShared.types";


interface IAwardAnalyticsState {
  report: GenerateAwardReportView[] | null;
  refresh: boolean;
  loading: boolean;
  loadingExportReport: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IAwardAnalyticsState = {
  report: null,
  refresh: false,
  loading: false,
  loadingExportReport: false,
  error: null,
};


const awardAnalyticsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAwardAnalyticsState => {

  const { 
    GETAWARDREPORT, 
    EXPORTAWARDREPORT 
  } = awardAnalyticsActions.types;

  switch (action.type) {

    case GETAWARDREPORT.START:
      return { ...state, loading: true, error: null };

    case GETAWARDREPORT.SUCCESS:
      return { ...state, report: action.payload, loading: false };

    case GETAWARDREPORT.FAILED:
      return { ...state, loading: false, error: action.payload };

    case EXPORTAWARDREPORT.START:
      return { ...state, loadingExportReport: true, error: null };

    case EXPORTAWARDREPORT.SUCCESS:
      return { ...state, loadingExportReport: false };

    case EXPORTAWARDREPORT.FAILED:
      return { ...state, loadingExportReport: false, error: action.payload };

    default:
      return state;
  }
};

export default awardAnalyticsReducer;
