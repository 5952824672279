import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Avatar,
  SchoolAvatar,
  FileList,
  RoleView,
  StaffSelector,
} from "sharedComponents/common";
import ReadOnlyField from "../readOnlyField";
import {
  Card,
  EmptyMessage,
  Chip,
  StructuredList,
  Swatches,
  Tabs,
  Message,
  HtmlText,
  DateTime,
  Label,
  RichTextEditor,
  ToastService,
} from "ui-kit";
import SubmissionStatus from "../submissionStatus";
import SubmissionTable from "./submissionTable";
import SubmissionHistoryList from "./submissionHistoryList";
import SubmissionNoteList from "./submissionNoteList";
import FinanceUserDropdown from "../filters/financeUserDropdown";
import { arrays, users } from "utils";
import { Constants } from "configuration";
import SubmissionNoteEditor from "./submissionNoteEditor";
import SubmissionStatusControl from "./submissionStatusControl";
import userManagementActions from "areas/administration/actions/userManagementActions";
import adminSubmissionActions from "areas/forms/actions/adminSubmissionActions";
import { RootState } from "reducers/store";
import { FieldGroupListView, FieldListView, SubmissionDetailView } from "areas/forms/types/formResponse.types";
import { StaffListView } from "types/users/userListViews.types";
import { RoleStartupView } from "types/users/userRoles.types";


interface ISubmissionProps {
  submission: SubmissionDetailView;
  onNoteChange?: (noteValue: string) => void;
}


const Submission: React.FC<ISubmissionProps> = ({ submission, onNoteChange }) => {

  const { assignmentError, settingAuthoriser } = useSelector((state: RootState) => state.adminSubmission);
  const { user: currentUser } = useSelector((state: RootState) => state.currentUser);
  const { user: submitterDetails } = useSelector((state: RootState) => state.managedUser);

  useEffect(() => {
    submission && userManagementActions.getUser(submission.submittedBy.id);
  }, [submission]);

  const handleAssignedToChange = (assignedUser: string) => {
    adminSubmissionActions.assignSubmission(
      submission,
      assignedUser,
      null,
      () => {
        ToastService.pop("Form Assigned", null, "paste");
      }
    );
  };

  const handleSetAuthoriser = (authoriser: StaffListView) => {
    if (
      window.confirm(
        `This will set the authoriser to ${users.getFullName(authoriser)}. ${
          authoriser.firstName
        } will also receive a notification email to authorise this form. Are you sure?`
      )
    ) {
      adminSubmissionActions.setAuthoriser(submission, authoriser.id, () => {
        ToastService.pop(
          "Authoriser Updated",
          `Authoriser set to ${users.getFullName(authoriser)}`,
          "paste"
        );
      });
    }
  };

  const TableGroup = ({ group } : { group: FieldGroupListView }) => {
    return <SubmissionTable group={group} rows={group.fields} />;
  };

  const FieldGroup = ({ group } : { group: FieldGroupListView }) => {
    return (
      <StructuredList>
        {group.fields.map((field: FieldListView, index: number) => (
          <StructuredList.Item
            key={index}
            name={
              <>
                {field.name}{" "}
                {!field.isActive && (
                  <Chip text="Legacy" colorSwatch={Swatches.Low} />
                )}
              </>
            }
          >
            <ReadOnlyField
              field={field}
              value={field.submittedValues[0]?.value}
            />
          </StructuredList.Item>
        ))}
      </StructuredList>
    );
  };

  const FormGroup = ({ group } : { group: FieldGroupListView }) => {

    const isTable = (group.maxRowCount != null && group.maxRowCount != null) || (group.minRowCount != null && group.minRowCount > 0);

    return (
      <>
        <Card title={group.name}>
          <Card.Body noPad={isTable}>
            {isTable ? (
              <TableGroup group={group} />
            ) : (
              <FieldGroup group={group} />
            )}
          </Card.Body>
        </Card>
      </>
    );
  };

  return (
    <>
      <Tabs>
        <Tabs.Pane label="Details">
          <SubmissionStatusControl submission={submission} />

          <Card>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Form">
                  <Label>{submission.form?.name}</Label>
                </StructuredList.Item>
                <StructuredList.Item name="ID">
                  <Label>{submission.id}</Label>
                </StructuredList.Item>
                <StructuredList.Item name="Submitted By">
                  <Avatar user={submission.submittedBy} />
                </StructuredList.Item>
                <StructuredList.Item name="Date">
                  <DateTime date={submission.createdDate} bold />
                </StructuredList.Item>
                <StructuredList.Item name="Reference">
                  <Label>{submission.userRef}</Label>
                </StructuredList.Item>

                <StructuredList.Item name="Authoriser">
                  {submission.latestHistory.status <=
                  Constants.SUBMISSION_STATUS.SUBMITTED.value ? (
                    <StaffSelector
                      user={submission?.authoriser}
                      onChange={value => handleSetAuthoriser(value)}
                      working={settingAuthoriser}
                    />
                  ) : (
                    <Avatar user={submission?.authoriser} />
                  )}
                </StructuredList.Item>
                <StructuredList.Item name="Status">
                  <SubmissionStatus status={submission.latestHistory.status} />
                </StructuredList.Item>
                <StructuredList.Item name="Assigned To">
                  <RoleView roles={[Constants.ROLES.FINANCE_USER]}>
                    <Message
                      text={assignmentError}
                      color={Swatches.Danger}
                      icon="times-circle"
                    />
                    <div>
                      <FinanceUserDropdown
                        onChange={handleAssignedToChange}
                        initialValue={submission.assignedTo?.id}
                        includeAllOption
                      />
                    </div>
                  </RoleView>
                  <RoleView roles={[Constants.ROLES.FINANCE_USER]} inverse>
                    {submission.assignedTo && (
                      <Avatar user={submission.assignedTo} />
                    )}
                  </RoleView>
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>

          <Card title="School & Department">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="School">
                  <SchoolAvatar school={submission.school} />
                </StructuredList.Item>
                <StructuredList.Item name="Department">
                  <Label>{submission.department?.name}</Label>
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>

          <>
            { submission.form.fieldGroups.map((group: FieldGroupListView, index: number) => (
              <FormGroup key={index} group={group} />
            ))}
          </>

          <Card title="Additional Information">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Documents">
                  {arrays.isEmpty(submission.files) ? (
                    <EmptyMessage
                      icon="file"
                      title="No files found"
                      summary="No files have been uploaded for this submission yet"
                      cover
                    />
                  ) : (
                    <FileList>
                      {submission.files?.map((file, index) => (
                        <FileList.Item
                          key={index}
                          file={{ ...file, id: file.id }}
                          clickToDownload
                        />
                      ))}
                    </FileList>
                  )}
                </StructuredList.Item>
                <StructuredList.Item name="Additional Notes">
                  {submitterDetails &&
                  (currentUser.id === submission.submittedBy.id ||
                    currentUser.id === submitterDetails.lineManager?.id ||
                    currentUser.roles.some(role => role.roleType === "FinanceAdministrator")
                  ) ? (
                    <RichTextEditor
                      initialValue={submission.submitterNotes}
                      onChange={value => onNoteChange?.(value)}
                    />
                  ) : (
                    <HtmlText html={submission.submitterNotes} />
                  )}
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
        </Tabs.Pane>
        <Tabs.Pane label="Notes">
          <RoleView roles={[Constants.ROLES.FINANCE_USER]}>
            <SubmissionNoteEditor submission={submission} />
          </RoleView>
          <SubmissionNoteList notes={submission.notes} />
        </Tabs.Pane>
        <Tabs.Pane label="History">
          <SubmissionHistoryList history={submission.history} />
        </Tabs.Pane>
      </Tabs>
    </>
  );
};

export default Submission;
