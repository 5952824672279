import { useEffect, useState, useRef } from "react";

const useOnScreen = (ref: React.MutableRefObject<any>, rootRef: React.MutableRefObject<any>) => {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerRef = useRef<any>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => setIsOnScreen(entry.isIntersecting),
      {
        root: rootRef.current,
        rootMargin: "50px",
        threshold: 1.0
      }
    );
  }, []);

  useEffect(() => {
    observerRef.current.observe(ref.current);

    return () => {
      observerRef.current.disconnect();
    };
  }, [ref]);

  return isOnScreen;
}

export default useOnScreen;