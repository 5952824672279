import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { PagedQueryView } from "types/common/views.types";
import { QueueMessageDetailView, QueueMessageUserListView } from "../types/mailoutResponse.types";

const prefix = "MAILOUT";
const builder = new actionTypeBuilder(prefix);

const types = {
  MAILOUT_QUEUEMESSAGE_GET: builder.build("queuemessage_get"),
  MAILOUT_QUEUEMESSAGE_SEND: builder.build("queuemessage_send"),
  MAILOUT_USEREMAILS_GET: builder.build("useremails_get"),
  MAILOUT_USERSMS_GET: builder.build("usersms_get"),
};


const getEmailsForUser = (userId: string, pageIndex: number, callback?: callbackType<PagedQueryView<QueueMessageUserListView>>) => {
  let url = `mailout/queue/users/${userId}/messages?pageIndex=${pageIndex}&messageType=${0}`;

  return action<PagedQueryView<QueueMessageUserListView>>(
    () => client.get(url), 
    types.MAILOUT_USEREMAILS_GET, 
    callback
  );
};

const getSmsMessagesForUser = (userId: string, pageIndex: number, callback?: callbackType<PagedQueryView<QueueMessageUserListView>>) => {
  let url = `mailout/queue/users/${userId}/messages?pageIndex=${pageIndex}&messageType=${1}`;

  return action<PagedQueryView<QueueMessageUserListView>>(
    () => client.get(url), 
    types.MAILOUT_USERSMS_GET, 
    callback
  );
};

const getQueueMessageForUser = (messageId: number, userId: string, callback?: callbackType<QueueMessageDetailView>) =>
  action<QueueMessageDetailView>(
    () => client.get(`mailout/queue/users/${userId}/messages/${messageId}`),
    types.MAILOUT_QUEUEMESSAGE_GET,
    callback
  );

const sendQueueMessage = (messageId: number, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`mailout/queue/messages/${messageId}/send`),
    types.MAILOUT_QUEUEMESSAGE_SEND,
    callback
  );


const mailoutActions = {
  types,
  getEmailsForUser,
  getSmsMessagesForUser,
  getQueueMessageForUser,
  sendQueueMessage,
};

export default mailoutActions;
