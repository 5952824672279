import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import store from "reducers/store";
import client from "services/client";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { ArticleFilter, SaveArticleCommand } from "../types/newsRequest.types";
import { PagedQueryView } from "types/common/views.types";
import { ArticleCategoryListView, ArticleDetailView, ArticleListView } from "../types/newsResponse.types";

const prefix = "news";
const builder = new actionTypeBuilder(prefix);

const types = {
  SET_TAB: "news_set_tab",
  GET_ARTICLECATEGORIES: builder.build("get_categories"),
  GET_ARTICLES: builder.build("get_articles"),
  GET_ARTICLE: builder.build("get_article"),
  CREATE_ARTICLE: builder.build("create_article"),
  SAVE_ARTICLE: builder.build("save_article"),
  DELETE_ARTICLE: builder.build("delete_article"),

  ARTICLES_FILTERBYPUBLISHSTATUS: "ARTICLES_FILTERBYPUBLISHSTATUS",
  ARTICLES_FILTERBYCATEGORY: "ARTICLES_FILTERBYCATEGORY",
  ARTICLES_FILTERBYSCHOOL: "ARTICLES_FILTERBYSCHOOL",
};


const setTab = (tabIndex: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.SET_TAB,
    payload: tabIndex,
  });
};

const getArticles = (filter: ArticleFilter, pageIndex: number, callback?: callbackType<PagedQueryView<ArticleListView>>) => {

  let url = `news/articles?pageIndex=${pageIndex}`;

  url =
    filter?.categoryId && filter?.categoryId > 0
      ? url + `&categoryId=${filter.categoryId}`
      : url;
  url =
    filter?.publishStatus >= 0 && filter?.publishStatus > -1
      ? url + `&publishStatus=${filter.publishStatus}`
      : url;
  url =
    filter?.schoolId && filter?.schoolId > -1
      ? url + `&schoolId=${filter.schoolId}`
      : url;

  action<PagedQueryView<ArticleListView>>(
    () => client.get(url), 
    types.GET_ARTICLES, 
    callback
  );
};

const getCategories = (callback?: callbackType<ArticleCategoryListView[]>) =>
  action<ArticleCategoryListView[]>(
    () => client.get(`news/categories`),
    types.GET_ARTICLECATEGORIES,
    callback
  );

const getArticle = (categoryId: number, articleId: number, callback?: callbackType<ArticleDetailView>) =>
  action<ArticleDetailView>(
    () => client.get(`news/categories/${categoryId}/articles/${articleId}`),
    types.GET_ARTICLE,
    callback
  );

const createArticle = (command: SaveArticleCommand, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`news/categories/${command.categoryId}/articles`, command),
    types.CREATE_ARTICLE,
    callback
  );

const updateArticle = (command: SaveArticleCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`news/categories/${command.categoryId}/articles/${command.id}`, command),
    types.SAVE_ARTICLE,
    callback
  );

const deleteArticle = (categoryId: number, articleId: number, callback?: callbackType<null>) =>
  action<null>(
    () => client.delete(`news/categories/${categoryId}/articles/${articleId}`),
    types.DELETE_ARTICLE,
    callback
  );

const filterBySchool = (schoolId: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.ARTICLES_FILTERBYSCHOOL,
    payload: schoolId,
  });
};

const filterByCategory = (categoryId: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.ARTICLES_FILTERBYCATEGORY,
    payload: categoryId,
  });
};

const filterByPublishStatus = (publishStatus: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.ARTICLES_FILTERBYPUBLISHSTATUS,
    payload: publishStatus,
  });
};


const newsActions = {
  types,
  setTab,
  getArticles,
  getCategories,
  getArticle,
  createArticle,
  updateArticle,
  deleteArticle,
  filterBySchool,
  filterByCategory,
  filterByPublishStatus,
};

export default newsActions;
