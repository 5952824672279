import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  Button,
  Size,
  Swatches,
  ActionBar,
  Table,
  DetailLabel,
  DateTime,
  Checkbox,
  EmptyMessage,
} from "ui-kit";
import { arrays } from "utils";
import bulletinActions from "areas/news/actions/bulletinActions";
import { RootState } from "reducers/store";
import { ArticleListView, BulletinDetailView } from "areas/news/types/newsResponse.types";

interface IBulletinArticlesModalProps {
  bulletin: BulletinDetailView;
  open: boolean;
  onSave?: (articles: ArticleListView[]) => void;
  onClose?: () => void;
}

const BulletinArticlesModal: React.FC<IBulletinArticlesModalProps> = ({ bulletin, open, onSave, onClose }) => {

  const { actions, articles, paging } = useSelector((state: RootState) => state.bulletin);
  const [_open, _setOpen] = useState<boolean>(false);
  const [_selectedArticles, _setSelectedArticles] = useState<ArticleListView[] | null>(null);

  const getArticles = (pageIndex: number) => {
    bulletinActions.getArticlesForBulletin(null, pageIndex);
  };

  useEffect(() => {
    _setOpen(open);

    if (open) {
      arrays.isEmpty(articles) && getArticles(0);
    }
  }, [open]);

  useEffect(() => {
    if (bulletin) {
      _setSelectedArticles(bulletin.articles);
    }
  }, [bulletin]);

  const handlePage = () => {
    getArticles(paging.pageIndex + 1);
  };

  const handleOk = () => {
    onSave?.(_selectedArticles);
  };

  const handleCancel = () => {
    _setSelectedArticles(bulletin.articles);
    onClose?.();
  };

  const handleSelectArticle = (article: ArticleListView, selected: boolean) => {
    if (selected) {
      _setSelectedArticles([..._selectedArticles, article]);
    } else {
      _setSelectedArticles([...arrays.removeItem(_selectedArticles, article)]);
    }
  };

  return (
    <Modal
      open={_open}
      onClose={handleCancel}
      title="Bulletin Articles"
      width="90%"
      height="90%"
    >
      <Modal.Body>
        <Table
          zebra
          loading={actions.getArticles.working}
          empty={arrays.isEmpty(articles)}
          emptyMessage={
            <EmptyMessage
              icon="signal-stream"
              title="No Articles"
              summary="No articles were found for this selection"
              cover
            />
          }
          grow
          error={actions.getArticles.error != null}
        >
          <Table.Header>
            <Table.HeaderCell width={0.25} />
            <Table.HeaderCell width={7.25}>Article</Table.HeaderCell>
            <Table.HeaderCell width={2}>Published Date</Table.HeaderCell>
          </Table.Header>
          <Table.Body onPage={handlePage} paging={paging}>
            {articles?.map((article: ArticleListView, index: number) => (
              <Table.Row
                key={index}
                highlight={_selectedArticles?.some(x => x.id == article.id)}
              >
                <Table.Cell width={0.25}>
                  <Checkbox
                    checked={_selectedArticles?.some(x => x.id == article.id)}
                    onChange={value =>
                      handleSelectArticle(article, value.checked)
                    }
                  />
                </Table.Cell>
                <Table.Cell width={7.25}>
                  <DetailLabel
                    bold
                    label={article.title}
                    sub={article.category.name}
                  />
                </Table.Cell>
                <Table.Cell width={2}>
                  <DateTime date={article.publishedDate} bold />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Button
            text="OK"
            onClick={handleOk}
            color={Swatches.Success}
            size={Size.Small}
          />
          <Button
            text="Cancel"
            onClick={handleCancel}
            color={Swatches.Low}
            size={Size.Small}
          />
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default BulletinArticlesModal;
