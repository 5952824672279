import { Constants } from "configuration";
import { UserType } from "configuration/constants.enums";
import { IRole } from "configuration/constants.types";
import { UserTypePermissionsView } from "types/common/views.types";
import { ManagedUserDetailView, UserTinyView } from "types/users/userListViews.types";
import { RoleListView, RoleStartupView } from "types/users/userRoles.types";
import { UserStartupView } from "types/users/userStartup.types";
import { arrays, types } from "utils";

//TODO: Create user object
type IUser = any;

/**
 * Check if a user is a usertype
 * @param {IUser} user The user object to check (usually the logged-in user/startup user object)
 * @param {UserType} userType The user type to check for
 * @returns {boolean} True if the user is of the given usertype
 */
const isUserOfType = (user: IUser, userType: UserType): boolean => {
  return user && user.userType === userType;
};

/**
 * Check if a user is one of the supplied user types
 * @param {IUser} user The user object to check (usually the logged-in user/startup user object)
 * @param {UserType} userTypes An array of user types to check for
 * @returns {boolean} True if the user is of any of the given usertype
 */
const isUserOfAnyType = (user: IUser, userTypes: UserType[]): boolean => {
  return (
    user &&
    userTypes.some(
      userType => user.userType.toLowerCase() === userType.toLowerCase()
    )
  );
};

/**
 * Check if a user is of staff type
 * @param {IUser} user The user object to check (usually the logged-in user/startup user object)
 * @returns {boolean} True if the user is a staff member
 */
const isStaff = (user: IUser): boolean => {
  return isUserOfType(user, Constants.USER_TYPES.STAFF);
};

/**
 * Check if a user is a teacher
 * @param {IUser} user The user object to check (usually the logged-in user/startup user object)
 * @returns {boolean} True if the user is a teacher
 */
const isTeacher = (user: IUser): boolean => {
  return (
    isUserOfType(user, Constants.USER_TYPES.STAFF) &&
    user.staffType === Constants.STAFF_TYPES.TEACHING.name
  );
};

/**
 * Check if a user is associate staff
 * @param {IUser} user The user object to check (usually the logged-in user/startup user object)
 * @returns {boolean} True if the user is associate staff
 */
const isAssociate = (user: IUser): boolean => {
  return (
    isUserOfType(user, Constants.USER_TYPES.STAFF) &&
    user.staffType === Constants.STAFF_TYPES.ASSOCIATE.name
  );
};

/**
 * Check if a user is a student
 * @param {IUser} user The user object to check (usually the logged-in user/startup user object)
 * @returns {boolean} True if the user is a student
 */
const isStudent = (user: IUser): boolean => {
  return isUserOfType(user, Constants.USER_TYPES.STUDENT);
};

/**
 * Check if a user is a parent
 * @param {IUser} user The user object to check (usually the logged-in user/startup user object)
 * @returns {boolean} True if the user is a parent
 */
const isParent = (user: IUser): boolean => {
  return isUserOfType(user, Constants.USER_TYPES.PARENT);
};

/**
 * Check if a user is a primary school parent
 * @param {IUser} user The user object to check (usually the logged-in user/startup user object)
 * @returns {boolean} True if the user is a primary school parent
 */
const isPrimaryParent = (user: IUser): boolean => {
  return (
    isUserOfType(user, Constants.USER_TYPES.PARENT) &&
    user.children.some((x: any) => x.school.isPrimary)
  );
};

/**
 * Check if a user is a future student
 * @param {IUser} user The user object to check (usually the logged-in user/startup user object)
 * @returns {boolean} True if the user is a future student
 */
const isFuture = (user: IUser): boolean => {
  return user.admissionStatus === Constants.ADMISSION_STATUS.FUTURE.value;
};

/**
 * Check if a user has a primary school as a base school
 * @param {IUser} user The user object to check (usually the logged-in user/startup user object)
 * @returns {boolean} True if the user has a primary school as a base school
 */
const isPrimary = (user: IUser): boolean => {
  return (
    user.school.isPrimary &&
    (arrays.isEmpty(user.schools) ||
      user.schools?.every((x: any) => x.isPrimary))
  );
};

/**
 * Check if a user is in a role
 * @param {UserStartupView | ManagedUserDetailView} user  The user object to check (usually the logged-in user/startup user object)
 * @param {IRole} role The role name to check (usually from ROLES constant property)
 * @returns {boolean} True if the user is in the supplied role, otherwise false.
 */
const isInRole = (user: UserStartupView | ManagedUserDetailView, role: IRole): boolean => {
  if (types.isType<UserStartupView>(user, "enablePayments")) {
    return (
      user && user.roles?.some((uRole: RoleStartupView) => uRole.roleTypeId === role.roleTypeId)
    );
  }
  else if (types.isType<ManagedUserDetailView>(user, "address")) {
    return (
      user && user.roles?.some((uRole: RoleListView) => uRole.roleType === role.roleTypeId)
    );
  }
};

/**
 * Check if a user is in any of the supplied roles
 * @param {UserStartupView | ManagedUserDetailView} user  The user object to check (usually the logged-in user/startup user object)
 * @param {IRole[]} roles An array of role objects to check (usually from ROLES constant property)
 * @returns {boolean} True if the user is in any of the supplied roles, otherwise false.
 */
const isInAnyRoles = (user: UserStartupView | ManagedUserDetailView, roles: IRole[]): boolean => {
  if (arrays.isEmpty(roles)) {
    return false;
  }

  if (types.isType<UserStartupView>(user, "enablePayments")) {
    return (
      user && user?.roles?.some((role: RoleStartupView) =>
        roles?.some(x => x.roleTypeId === role.roleTypeId)
      )
    );
  }
  else if (types.isType<ManagedUserDetailView>(user, "address")) {
    return (
      user &&
      user?.roles?.some((role: RoleListView) =>
        roles?.some(x => x.roleTypeId === role.roleType)
      )
    );
  }
};

/**
 * Convert a string to title case
 * @param {string} name  The string to convert
 * @returns {string} The supplied string in title case
 */
const toTitleCase = (name: string): string => {
  return name.charAt(0).toUpperCase() + name.substr(1).toLowerCase();
};

/**
 * Removes the delimiter from the string and returns it in title case
 * @param {string} name  The string to convert
 * @param {string} delimiter The delimiter to remove from the string
 * @returns {string} The supplied string in title case with the delimiter removed
 */
const toDelimitedTitleCase = (name: string, delimiter: string): string => {
  if (!name) {
    return "";
  }
  if (!delimiter || !name.includes(delimiter)) {
    return name; //
  }

  var sliced = name.split(delimiter);

  sliced = sliced.map(sub => toTitleCase(sub));

  return sliced.join(delimiter);
};

/**
 * Convert a name to title case
 * @param {string} name  The name to convert
 * @returns {string} The supplied name in title case
 */
const nameToTitleCase = (name: string): string => {
  if (!name) {
    return "";
  }
  var formatted = toTitleCase(name);
  formatted = toDelimitedTitleCase(formatted, "-");
  formatted = toDelimitedTitleCase(formatted, " ");
  formatted = toDelimitedTitleCase(formatted, "'");

  return formatted;
};

/**
 * Gets the name from a user and converts it to title case
 * @param {IUser} user  The user to get the name from
 * @returns {string} The supplied users name in title case
 */
const getFullName = (user: IUser): string => {
  return (
    user &&
    `${nameToTitleCase(user.firstName)} ${nameToTitleCase(user.lastName)}`
  );
};

/**
 * Gets the first name from a user and converts it to title case
 * @param {IUser} user  The user to get the first name from
 * @returns {string} The supplied users first name in title case
 */
const getFirstName = (user: IUser): string => {
  return user && `${nameToTitleCase(user.firstName)}`;
};

/**
 * Gets the last name from a user and converts it to title case
 * @param {IUser} user  The user to get the last name from
 * @returns {string} The supplied users last name in title case
 */
const getLastName = (user: IUser): string => {
  return user && `${nameToTitleCase(user.lastName)}`;
};

/**
 * Gets the name from a user, converts it to title case, and prefixed it with their title
 * @param {IUser} user  The user to get the name and title from
 * @returns {string} The supplied users name in title case with their title prefixed
 */
const getFormalName = (user: IUser): string => {
  return user && `${user.title} ${nameToTitleCase(user.lastName)}`;
};

/**
 * Gets the name from a user and returns the possessive form
 * @param {IUser} user  The user to get the name from
 * @returns {string} The supplied users name as a posessive noun
 */
const getPossessive = (user: IUser): string => {
  if (!user) {
    return "";
  }
  const name = user.firstName;
  const last = name[name.length - 1];
  return last === "s" ? `${name}'` : `${name}'s`;
};


/**
 * Gets the initials from a users name
 * @param {string} usersName  The user to get the name from
 * @returns {string} The supplied users name initials
 */
const getInitials = (usersName: string) => {
  if (!usersName) {
    return "";
  }

  var names = usersName.split(' ');
  var initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  
  return initials;
}


const groupableIsAccessibleToUserType = <T extends { userRestrictions: UserTypePermissionsView }>(groupable: T, user: IUser) => {
  return (
    (groupable.userRestrictions.allowParents && isUserOfType(user, Constants.USER_TYPES.PARENT)) ||
    (groupable.userRestrictions.allowStaff && isUserOfType(user, Constants.USER_TYPES.STAFF)) ||
    (groupable.userRestrictions.allowStudents && isUserOfType(user, Constants.USER_TYPES.STUDENT))
  );
}

const parentCanView = <T extends { userRestrictions: UserTypePermissionsView }>(groupable: T, user: IUser) => {
  return (groupable.userRestrictions.allowStudents && isUserOfType(user, Constants.USER_TYPES.PARENT));
}

const userIsCreator = <T extends { createdBy: UserTinyView }>(item: T, user: IUser) => {
  return item.createdBy.id == user.id;
}


const methods = {
  isStaff,
  isStudent,
  isParent,
  isTeacher,
  isAssociate,
  isInRole,
  isPrimary,
  isInAnyRoles,
  isUserOfType,
  isUserOfAnyType,
  isFuture,
  isPrimaryParent,
  getFullName,
  getFirstName,
  getLastName,
  getFormalName,
  getPossessive,
  getInitials,
  groupableIsAccessibleToUserType,
  parentCanView,
  userIsCreator
};

export default methods;
