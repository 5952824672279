import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, ActionBar } from "ui-kit";
import { Spacing } from "ui-kit/common/size";
import { fontStyle, displayFont, typescale } from "ui-kit/common/typography";
import { neutral, Swatches } from "ui-kit/common/colors";
import styled from "styled-components";
import { Card, Size } from "../../ui-kit";

const Wrapper = styled.div`
  .modal-body {
    margin-left: 0;
  }
`;

const ConfirmMsg = styled.div`
  ${fontStyle(displayFont.medium, typescale.header4, neutral[700])}
  margin-bottom: ${Spacing.Large}px;
`;

interface IConfirmModalProps {
  openModal?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmMsg: string;
  loading?: boolean;
  children?: any | any[];
}

const ConfirmModal: React.FC<IConfirmModalProps> = ({
  openModal,
  onClose,
  onConfirm,
  confirmMsg,
  loading,
  children,
}) => {
  return (
    <Modal
      onClose={onClose}
      open={openModal}
      title="Confirm"
      height="fit-content"
      width="50%"
    >
      <Wrapper>
        <Modal.Body>
          <Card>
            <Card.Body>
              <ConfirmMsg>{confirmMsg}</ConfirmMsg>
              {children}
            </Card.Body>
          </Card>
        </Modal.Body>
      </Wrapper>

      <Modal.Footer>
        <ActionBar low>
          <Button
            color={Swatches.Success}
            text="Confirm"
            size={Size.Small}
            onClick={e => {
              onConfirm();
              e.stopPropagation();
            }}
            working={loading}
          />
          <Button
            color={Swatches.Low}
            text="Close"
            size={Size.Small}
            onClick={e => {
              onClose();
              e.stopPropagation();
            }}
            working={loading}
          />
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
