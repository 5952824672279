import React from "react";
import { Chip, Swatches } from "ui-kit";
import { Constants } from "configuration";


interface IOrderLineStatusProps {
  status: number;
}


const OrderLineStatus: React.FC<IOrderLineStatusProps> = ({ status }) => {
  var swatch = null;
  var name = "";

  switch (status) {
    case Constants.ORDERLINE_STATUS.ORDERED.value:
      swatch = Swatches.Success;
      name = Constants.ORDERLINE_STATUS.ORDERED.name;
      break;
    case Constants.ORDERLINE_STATUS.CANCELLED.value:
      swatch = Swatches.Cancel;
      name = Constants.ORDERLINE_STATUS.CANCELLED.name;
      break;
    default:
      swatch = Swatches.Danger;
      name = status.toString();
      break;
  }

  return <Chip text={name} colorSwatch={swatch} />;
};

export default OrderLineStatus;
