import React from "react";
import { strings } from "utils";
import {
  HeadlineStatistic,
} from "ui-kit";
import { InterventionRecordView } from "areas/planner/types/interventions/interventionResponses";
import { group } from "console";
import { InterventionProgressRating } from "areas/planner/types/interventions/interventionShared";


interface IInterventionsOverviewProps {
  interventions: InterventionRecordView[];
  loading?: boolean;
  error?: string;
}


const InterventionsOverview: React.FC<IInterventionsOverviewProps> = ({ interventions, loading, error }) => {

  return (
    <HeadlineStatistic>
      <HeadlineStatistic.Item
        icon="hand-holding-seedling"
        label={`Intervention ${strings.pluralize(
          "Record",
          "Records",
          interventions?.length
        )}`}
        value={interventions?.length}
      />
      <HeadlineStatistic.Item
        icon="hand-holding-seedling"
        label="Above Target"
        value={interventions?.filter((intervention) => intervention.rating === InterventionProgressRating.Above).length}
      />
      <HeadlineStatistic.Item
        icon="hand-holding-seedling"
        label="On Target"
        value={interventions?.filter((intervention) => intervention.rating === InterventionProgressRating.Expected).length}
      />
      <HeadlineStatistic.Item
        icon="hand-holding-seedling"
        label="Below Target"
        value={interventions?.filter((intervention) => intervention.rating === InterventionProgressRating.Below).length}
      />
    </HeadlineStatistic>
  )
}

export default InterventionsOverview;