import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { 
  Spacing, 
  neutral, 
  Swatches, 
  IUiKitBaseProps
} from "../index";

const Wrapper = styled.div`
  display: flex;
  padding: 8px;

  span {
    margin-right: ${Spacing.Medium}px;
    background: ${neutral[200]};

    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    padding: 4px;
    width: 24px;
    transition: all ease 250ms;

    &:hover {
      background: ${neutral[600]};
      color: white;
    }

    &.selected,
    &.selected:hover {
      background: ${Swatches.Blue.swatch};
      cursor: default;
      color: white;
    }
  }
`;

interface IPagerProps extends IUiKitBaseProps {
  pageCount: number;
  onPage?: (index: number) => void; 
  selectedPage?: number;
}

const Pager: React.FC<IPagerProps> = ({ pageCount, onPage, selectedPage, style }) => {
  const [_count, _setCount] = useState<number>(pageCount);
  const [_currentPage, _setCurrentPage] = useState<number>(selectedPage ?? 0);

  const getButtons = () => {
    const buttons: number[] = [];

    for (let i = 0; i < _count; i++) {
      buttons.push(i);
    }

    return buttons;
  };

  useEffect(() => {
    _setCount(pageCount);
  }, [pageCount]);

  useEffect(() => {
    selectedPage && _setCurrentPage(selectedPage);
  }, [selectedPage]);

  const handlePage = (index: number) => {
    if (index !== _currentPage - 1) {
      onPage?.(index);
    }
  };

  return (
    <Wrapper style={style}>
      {getButtons().map((x, i) => (
        <span
          key={i}
          onClick={() => handlePage(i)}
          className={i === _currentPage - 1 ? "selected" : ""}
        >
          {x + 1}
        </span>
      ))}
    </Wrapper>
  );
};

export default Pager;
