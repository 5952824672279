import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import {
  Flyout,
  Swatches,
  ActionBar,
  Button,
  StructuredList,
  TextInput,
  TextInputType,
  Message,
  Checkbox,
  neutral,
  Loader,
  Size
} from "ui-kit";
import { arrays } from "utils";
import { ProductCategoryDetailView, ProductCategoryListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import paymentActions from "areas/payments/actions/paymentsActions";
import { SaveProductCategoryCommand } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";
import flyoutActions from "actions/ui/flyouts";
import { RootState } from "reducers/store";
import { NewCategory } from "./inventory";


interface TempCategory extends ProductCategoryDetailView {
  displayIndex: number;
  schoolId?: number | null;
}

interface ICategoryEditorFlyoutProps {
  category: NewCategory;
  onSaved?: (category: SaveProductCategoryCommand) => void;
  onCreated?: (category: ProductCategoryListView) => void;
}


const CategoryEditorFlyout: React.FC<ICategoryEditorFlyoutProps> = ({ category, onSaved, onCreated }) => {

  const { error, saving } = useSelector((state: RootState) => state.adminCategory);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_category, _setCategory] = useState<TempCategory | null>(null);

  useEffect(() => {
    if (category?.id) {
      paymentActions.getCategory(category?.id, categoryDetailView => {
        _setCategory({
          ...categoryDetailView,
          displayIndex: 0,
          schoolId: categoryDetailView.school?.id,
        });
      });
    } else {
      _setCategory({
        id: null,
        name: category?.name,
        isActive: true,
        icon: null,
        school: undefined,
        description: "",
        allowParents: false,
        allowStudents: false,
        allowStaff: false,
        displayIndex: 0,
        schoolId: category?.schoolId,
      });
    }
  }, [category]);

  const handleSave = () => {
    var errors = [];
    if (!_category.name) {
      errors.push("Please enter a category name.");
    }
    if (!_category.allowParents && !_category.allowStudents && !_category.allowStaff) {
      errors.push("Please enter at least one user type who can purchase items in this category");
    }
    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    const data: SaveProductCategoryCommand = {
      id: _category.id,
      name: _category.name,
      isActive: _category.isActive,
      displayIndex: _category.displayIndex,
      description: _category.description,
      allowParents: _category.allowParents,
      allowStudents: _category.allowStudents,
      allowStaff: _category.allowStaff,
      schoolId: _category.schoolId
    }

    if (_category.id) {
      paymentActions.saveCategory(data, () => {
        onSaved?.(data);
        flyoutActions.closeFlyout();
      });
    } else {
      paymentActions.createCategory(data, value => {
        onCreated?.(value);
        flyoutActions.closeFlyout();
      });
    }
  };

  const handleCancel = () => {
    flyoutActions.closeFlyout();
  };

  if (!category) {
    return (
      <Loader size={Size.Medium} fluid />
    )
  }

  return (
    <Flyout
      title={`${category?.id ? "Edit" : "New"} Category`}
      name={Constants.FLYOUTS.CATEGORY_EDITOR}
      wide
    >
      <Flyout.Body scrollOnErrors={validationErrors}>
        {!arrays.isEmpty(validationErrors) && (
          <Message
            text={validationErrors.map(err => (
              <>
                {err}
                <br />
              </>
            ))}
            color={Swatches.Danger}
          />
        )}

        {error && <Message text={error} color={Swatches.Danger} />}

        <StructuredList>
          <StructuredList.Item
            name="Name"
            description="The name of the category. This name is publicly visible. 30 characters max."
            required
          >
            <TextInput
              value={_category?.name}
              onChange={value => _setCategory({ ..._category, name: value })}
              max={30}
              maxLength={30}
              characterCount
              fluid
              color={neutral[300]}
            />
          </StructuredList.Item>
          <StructuredList.Item
            name="Description"
            required
            description="Optional private description for the category. This is not visible in the store."
          >
            <TextInput
              value={_category?.description}
              type={TextInputType.Textarea}
              onChange={value =>
                _setCategory({ ..._category, description: value })
              }
              fluid
              color={neutral[300]}
            />
          </StructuredList.Item>
          <StructuredList.Item
            name="Is Active"
            required
            description="Whether the category is active. If the category is inactive, all products will automatically be unavailable regardless of the products' individual visibility settings."
          >
            <Checkbox
              checked={_category?.isActive}
              onChange={value =>
                _setCategory({ ..._category, isActive: value.checked })
              }
              text={`Category is active`}
            />
          </StructuredList.Item>
          <StructuredList.Item
            name="User Permissions"
            required
            description={
              <p>
                Choose which user types can buy items from this category{" "}
                <b>for themselves</b>. This will normally only be students. If
                students are selected, then their parents can also purchase
                products for their children.
              </p>
            }
          >
            <Checkbox
              checked={_category.allowParents}
              onChange={checkbox => _setCategory({ ..._category, allowParents: checkbox.checked })}
              text={`Allow parents to purchase for themselves`}
            />
            <Checkbox
              checked={_category.allowStudents}
              onChange={checkbox => _setCategory({ ..._category, allowStudents: checkbox.checked })}
              text={`Allow students to purchase for themselves`}
            />
            <Checkbox
              checked={_category.allowStaff}
              onChange={checkbox => _setCategory({ ..._category, allowStaff: checkbox.checked })}
              text={`Allow staff to purchase for themselves`}
            />
          </StructuredList.Item>
        </StructuredList>
      </Flyout.Body>
      <Flyout.Footer>
        <ActionBar low>
          <Button
            text="Save"
            onClick={handleSave}
            color={Swatches.Success}
            fluid
            working={saving}
          />
        </ActionBar>
      </Flyout.Footer>
    </Flyout>
  )
}

export default CategoryEditorFlyout;