import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table, Loader, Size, EmptyMessage, DetailLabel } from "ui-kit";
import { arrays, dateTime } from "utils";
import { ApiExceptionMessage } from "sharedComponents/common";
import { useAppSelector } from "reducers/hooks";
import { AssignmentAnalyticsReportParameters } from "./assignmentAnalyticsWrapper";
import { AssignmentGroupBy } from "areas/analytics/types/assignmentAnalyticsShared.types";


interface IAssignmentReportTableProps {
  reportParameters: AssignmentAnalyticsReportParameters;
}


const AssignmentReportTable: React.FC<IAssignmentReportTableProps> = ({ reportParameters }) => {

  const { report, loading, error } = useAppSelector(
    state => state.assignmentAnalytics
  );
  const [groupById, setGroupById] = useState<AssignmentGroupBy | null>(null);

  useEffect(() => {
    if (reportParameters) {
      setGroupById(reportParameters.groupById);
    }
  }, [reportParameters]);

  if (loading) {
    return <Loader size={Size.Large} fluid />;
  }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return (
    report && (
      <>
        { arrays.isEmpty(report[0].assignments) ? (
          <EmptyMessage
            title="No rewards or behaviours in the selected time period"
            icon="list"
          />
        ) : groupById === AssignmentGroupBy.None ? (
          <Table>
            <Table.Header>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Set By</Table.HeaderCell>
              <Table.HeaderCell>Deadline Date</Table.HeaderCell>
              <Table.HeaderCell>Set For</Table.HeaderCell>
            </Table.Header>

            <Table.Body>
              <>
              { report.map(group =>
                group.assignments.map((assignment, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      <b>{assignment.title}</b>
                    </Table.Cell>
                    <Table.Cell>
                      <DetailLabel
                        label={assignment.setBy}
                        sub={`Set ${dateTime.formatDate(assignment.setDate.toString())}`}
                        bold
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {dateTime.formatDate(assignment.deadlineDate.toString())}
                    </Table.Cell>
                    <Table.Cell>{assignment.setFor}</Table.Cell>
                  </Table.Row>
                ))
              )}
              </>
            </Table.Body>
            
          </Table>
        ) : (
          report.map((group, index) => (
            <Table key={index}>
              <Table.Header>
                <Table.HeaderCell colspan={5}>
                  <b>
                    {groupById === AssignmentGroupBy.YearGroup
                      ? `Year ${group.name}`
                      : group.name}
                  </b>
                </Table.HeaderCell>
                <Table.HeaderCell colspan={0}></Table.HeaderCell>
              </Table.Header>

              <Table.Header>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell>Set Date</Table.HeaderCell>
                <Table.HeaderCell>Deadline Date</Table.HeaderCell>
                <Table.HeaderCell>Timescale</Table.HeaderCell>
                <Table.HeaderCell>Set By</Table.HeaderCell>
                <Table.HeaderCell>Set For</Table.HeaderCell>
              </Table.Header>

              <Table.Body>
                {group.assignments.map((assignment, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      <b>{assignment.title}</b>
                    </Table.Cell>
                    <Table.Cell>
                      {dateTime.formatDate(assignment.setDate.toString())}
                    </Table.Cell>
                    <Table.Cell>
                      {dateTime.formatDate(assignment.deadlineDate.toString())}
                    </Table.Cell>
                    <Table.Cell>{assignment.timeScale}</Table.Cell>
                    <Table.Cell>{assignment.setBy}</Table.Cell>
                    <Table.Cell>{assignment.setFor}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ))
        )}
      </>
    )
  );
};

export default AssignmentReportTable;
