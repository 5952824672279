import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";


const BulletinTemplateEditor = () => {

  const { template, actions } = useSelector((state: RootState) => state.bulletinTemplate);

  useEffect(() => {
    
  }, []);

  return <p>Template Editor</p>;
};

export default BulletinTemplateEditor;
