import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { TrayListView } from "areas/filing/types/fileResponse.types";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { SubmitFormCommand } from "../types/formResponse.types";

const builder = new actionTypeBuilder("form");

const types = {
  SUBMIT: builder.build("submit"),
};


const submitForm = (data: SubmitFormCommand, callback?: callbackType<TrayListView>) =>
  action<TrayListView>(
    () => client.post(`forms/submissions`, data), 
    types.SUBMIT, 
    callback
  );


const formSubmissionActions = {
  types,
  submitForm,
};

export default formSubmissionActions;
