import defaults from "reducers/defaults";
import onCallActions from "areas/behaviour/actions/onCall/onCallActions";
import { PageInfo } from "types/common/paging.types";
import { IBeehiveError } from "types/common/errors.types";
import { IBeehiveAction } from "types/common/action.types";
import { OnCallRequestDetailView } from "areas/analytics/reducers/onCallAnalyticsResponse.types";


interface IOnCallRequestsState {
  onCallRequests: OnCallRequestDetailView[];
  paging: PageInfo;
  loading: boolean;
  refresh: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IOnCallRequestsState = {
  onCallRequests: [],
  paging: defaults.DEFAULT_PAGING,
  loading: false,
  refresh: false,
  error: null,
};

const {
  GETONCALLREQUESTS,
  CLAIMONCALLREQUEST,
  RESOLVEONCALLREQUEST,
  CANCELONCALLREQUEST,
  UPDATEONCALLREQUEST,
} = onCallActions.types;


const onCallRequestsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IOnCallRequestsState => {

  switch (action.type) {
    case GETONCALLREQUESTS.START:
      return { ...state, loading: true };

    case GETONCALLREQUESTS.SUCCESS:
      return {
        ...state,
        loading: false,
        refresh: false,
        error: null,
        onCallRequests:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.onCallRequests, ...action.payload.items],
        paging: action.payload.paging,
      };

    case GETONCALLREQUESTS.FAILED:
      return {
        ...state,
        loading: false,
        refresh: false,
        error: action.payload,
      };

    case CLAIMONCALLREQUEST.SUCCESS:
    case RESOLVEONCALLREQUEST.SUCCESS:
    case CANCELONCALLREQUEST.SUCCESS:
    case UPDATEONCALLREQUEST.SUCCESS:
      return { ...state, refresh: true };

    default:
      return state;
  }
};

export default onCallRequestsReducer;
