import React from "react";
import moment from "moment";

interface IDateRangeProps {
  start: Date;
  end: Date;
}

const DateRange: React.FC<IDateRangeProps> = ({ start, end }) => {
  
  if (!start || !end) {
    return null;
  }

  const _start = moment(start);
  const _end = moment(end);
  const format = "ddd, Do MMM YYYY h:mm A";

  const getEndDate = () => {
    return _start.isSame(_end, "day")
      ? _end.format("h:mm A")
      : _end.format(format);
  };

  return (
    <span>
      {_start.format(format)}
      {" to "}
      {getEndDate()}
    </span>
  );
};

export default DateRange;
