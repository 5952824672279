import React, { useEffect, useState, useRef } from "react";
import ReportDescription from "../reportDescription";
import { Title, TitleSize } from "ui-kit";
import { useAppSelector } from "reducers/hooks";
import assignedStaffAnalyticsActions from "areas/analytics/actions/assignedStaffAnalyticsActions";
import { GetStudentAssignedStaffReportCommand } from "areas/analytics/types/assignedStaffAnalyticRequests.types";
import AssignedStaffAnalyticsForm from "./assignedStaffAnalyticsForm";
import AssignedStaffReportTable from "./assignedStaffReportTable";


const AssignedStaffAnalyticsWrapper: React.FC = () => {

  const reportRef = useRef<HTMLDivElement>();
  const { report, loading, loadingExportReport, error } = useAppSelector(state => state.assignedStaffAnalytics);

  useEffect(() => {
    if (loading || report) {
      reportRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading, report]);

  const handleGenerateReport = (command: GetStudentAssignedStaffReportCommand, exportReport?: Boolean) => {
    if (exportReport) {
      assignedStaffAnalyticsActions.getStudentAssignedStaff(command, true);
    } else {
      assignedStaffAnalyticsActions.getStudentAssignedStaff(command, false);
    }
  };

  return (
    <>
      <Title text="Student Assigned Staff Report" size={TitleSize.H3} />
      <ReportDescription text="Use this report to view students with assigned members of staff." />
      <AssignedStaffAnalyticsForm
        onGenerateReport={handleGenerateReport}
        loading={loadingExportReport || loading}
      />
      <div ref={reportRef}>
        <AssignedStaffReportTable reportData={report} loading={loading} error={error} />
      </div>
    </>
  );
};

export default AssignedStaffAnalyticsWrapper;
