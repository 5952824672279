import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router";
import { TabBar } from "ui-kit";
import { groupRoutes } from "../../plannerRoutes";
import { Constants } from "configuration";
import groupActions from "../../actions/group/groupActions";
import CustomRoute from "sharedComponents/layout/routes/customRoute";
import { ClassGroupStaffView } from "types/users/userGroups.types";
import { useAppSelector } from "reducers/hooks";
import ClassViewer from "./classViewer/classViewer";
import GroupActivityWrapper from "./groupActivity/groupActivityWrapper";
import LunchWrapper from "./lunch/lunchWrapper";
import GroupStudentsWrapper from "./groupStudentlist/groupStudentsWrapper";
import LeaderboardWrapper from "areas/analytics/components/leaderboard/leaderboardWrapper";


const TABS = {
  activity: { key: 0, name: "activity", text: "Activity" },
  students: { key: 1, name: "students", text: "Students" },
  seatingPlan: { key: 2, name: "seatingplan", text: "Seating Plan" },
  leaderboard: { key: 3, name: "leaderboard", text: "Leaderboard" },
  lunch: { key: 4, name: "lunch", text: "Lunch" },
};


interface IPrimaryGroupTabsProps {
  group: ClassGroupStaffView;
}


const PrimaryGroupTabs: React.FC<IPrimaryGroupTabsProps> = ({ group }) => {

  const navigate = useNavigate();
  const { activeTab } = useAppSelector(state => state.groupTabMenu);

  useEffect(() => {
    if (group) {
      groupActions.selectGroup(group.id);
      if (!group.layouts || group.layouts.length === 0) {
        groupActions.setGroupActiveTab(TABS.students.key);
      } else {
        groupActions.setGroupActiveTab(TABS.seatingPlan.key);
      }
    }
  }, [group]);

  useEffect(() => {
    const groupId = group.id;
    const groupType = group.type;
    switch (activeTab) {
      case TABS.activity.key:
        navigate(`${groupRoutes.group.getActivityPath(groupId, groupType)}`);
        break;

      case TABS.students.key:
        navigate(`${groupRoutes.group.getStudentsPath(groupId, groupType)}`);
        break;

      case TABS.seatingPlan.key:
        navigate(`${groupRoutes.group.getSeatingPlanPath(groupId, groupType)}`);
        break;

      case TABS.leaderboard.key:
        navigate(`${groupRoutes.group.getLeaderboardPath(groupId, groupType)}`);
        break;

      case TABS.lunch.key:
        navigate(`${groupRoutes.group.getLunchPath(groupId, groupType)}`);
        break;

      default:
        break;
    }
  }, [activeTab]);

  const handleTabClick = (name: string, index: number) => {
    if (activeTab !== index) {
      groupActions.setGroupActiveTab(index);
    }
  };

  return (
    <>
      <TabBar selectedIndex={activeTab} onItemClicked={handleTabClick}>
        <TabBar.Item
          key={TABS.activity.key}
          name={TABS.activity.name}
          text={TABS.activity.text}
        />

        <TabBar.Item
          key={TABS.students.key}
          name={TABS.students.name}
          text={TABS.students.text}
        />

        <TabBar.Item
          key={TABS.seatingPlan.key}
          name={TABS.seatingPlan.name}
          text={TABS.seatingPlan.text}
        />

        <TabBar.Item
          key={TABS.leaderboard.key}
          name={TABS.leaderboard.name}
          text={TABS.leaderboard.text}
        />

        <TabBar.Item
          key={TABS.lunch.key}
          name={TABS.lunch.name}
          text={TABS.lunch.text}
        />
      </TabBar>

      <Routes>
        <Route
          path={groupRoutes.group.activity}
          element={
            <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
              <GroupActivityWrapper />
            </CustomRoute>
          }
        />

        <Route
          path={groupRoutes.group.students}
          element={
            <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
              <GroupStudentsWrapper />
            </CustomRoute>
          }
        />

        <Route
          path={groupRoutes.group.seatingPlan}
          element={
            <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
              <ClassViewer />
            </CustomRoute>
          }
        />

        <Route
          path={groupRoutes.group.leaderboard}
          element={
            <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
              <LeaderboardWrapper />
            </CustomRoute>
          }
        />

        <Route
          path={groupRoutes.group.lunch}
          element={
            <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
              <LunchWrapper />
            </CustomRoute>
          }
        />
      </Routes>
    </>
  );
};

export default PrimaryGroupTabs;
