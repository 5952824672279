import React from "react";
import PropTypes from "prop-types";
import { Icon, Size, Table, honeygold } from "ui-kit";
import {
  DETENTION_GROUPBY_KEYS,
  DETENTION_TABLE_HEADERS,
} from "areas/behaviour/constants/tableConstants";
import { faSquareCheck } from "@fortawesome/pro-solid-svg-icons";
import { low } from "ui-kit/common/colors";
import { DetentionStudentView } from "areas/behaviour/types/detentionRegisterResponse.types";


interface IDetentionRegisterTableHeadersProps {
  selectedGroupByOptions: number[];
  selectAll?: () => void;
  selectedDetentions: DetentionStudentView[];
}


const DetentionRegisterTableHeaders: React.FC<IDetentionRegisterTableHeadersProps> = ({ selectedGroupByOptions, selectAll, selectedDetentions }) => {

  return (
    <Table.Header>

        <Table.HeaderCell width={0.25} onSort={selectAll} center>
          <Icon
            value={faSquareCheck}
            color={(selectedDetentions.length < 1) ? low : honeygold}
            size={Size.Large}
            tooltip={(selectedDetentions.length < 1) ? "Select All" : "Deselect All"}
          />
      </Table.HeaderCell>

      <Table.HeaderCell center width={1}>
        {DETENTION_TABLE_HEADERS.attendanceButtons}
      </Table.HeaderCell>

      <Table.HeaderCell width={1.5}>
        {DETENTION_TABLE_HEADERS.student}
      </Table.HeaderCell>

      <Table.HeaderCell width={0.5}>
        {DETENTION_TABLE_HEADERS.behaviour}
      </Table.HeaderCell>

      {!selectedGroupByOptions.includes(
        DETENTION_GROUPBY_KEYS.DETENTION_TYPE
      ) && (
        <Table.HeaderCell width={1}>
          {DETENTION_TABLE_HEADERS.detentionType}
        </Table.HeaderCell>
      )}

      <Table.HeaderCell width={1}>
        {DETENTION_TABLE_HEADERS.issuedBy}
      </Table.HeaderCell>

      {!selectedGroupByOptions.includes(DETENTION_GROUPBY_KEYS.ROOM) && (
        <Table.HeaderCell width={0.75}>
          {DETENTION_TABLE_HEADERS.room}
        </Table.HeaderCell>
      )}

      {!selectedGroupByOptions.includes(DETENTION_GROUPBY_KEYS.START_TIME) && (
        <Table.HeaderCell width={0.5}>
          {DETENTION_TABLE_HEADERS.start}
        </Table.HeaderCell>
      )}

      {!selectedGroupByOptions.includes(DETENTION_GROUPBY_KEYS.END_TIME) && (
        <Table.HeaderCell width={0.5}>
          {DETENTION_TABLE_HEADERS.end}
        </Table.HeaderCell>
      )}

      <Table.HeaderCell width={0.5}>
        {DETENTION_TABLE_HEADERS.warning}
      </Table.HeaderCell>

      <Table.HeaderCell width={1}>
        {DETENTION_TABLE_HEADERS.actionButton}
      </Table.HeaderCell>
    </Table.Header>
  );
};


export default DetentionRegisterTableHeaders;
