import store from "reducers/store";

const types = {
  SETACTIVETAB: "setactivetab",
};

const setActiveTab = activeTab =>
  store.dispatch({
    type: types.SETACTIVETAB,
    payload: activeTab,
  });

const studentInformationTabsActions = {
  types,
  setActiveTab,
};

export default studentInformationTabsActions;
