import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StudentSearch, Avatar } from "sharedComponents/common";
import { Button, Size, Swatches, Spacing } from "ui-kit";

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  .staff-selector-left {
    flex-grow: 1;
    margin-right: ${Spacing.Small}px;
  }

  .staff-selector-right {
    .button:last-child {
      margin-left: ${Spacing.Small}px;
    }
  }
`;

interface IStudentSelector {
  user: any;
  onChange?: (user: any) => void;
  onCancel?: () => void;
  working?: boolean;
}

const StudentSelector: React.FC<IStudentSelector> = ({
  user,
  onChange,
  onCancel,
  working,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [_user, _setUser] = useState(null);

  useEffect(() => {
    _setUser(user);
  }, [user]);

  const handleCancel = () => {
    setEditMode(false);
    onCancel?.();
  };

  const handleChange = (user: any) => {
    setEditMode(false);
    onChange?.(user);
  };

  const Editor = () => {
    return (
      <>
        <div className="staff-selector-left">
          <StudentSearch fluid onChange={handleChange} />
        </div>
        <div className="staff-selector-right">
          <Button
            onClick={handleCancel}
            size={Size.Small}
            color={Swatches.Low}
            text="Cancel"
            working={working}
          />
        </div>
      </>
    );
  };

  const Static = () => {
    return (
      <>
        <div className="staff-selector-left">
          <Avatar user={_user} />
        </div>
        <div className="staff-selector-right">
          <Button
            onClick={() => setEditMode(true)}
            size={Size.Small}
            color={Swatches.Low}
            text="Change"
            working={working}
          />
        </div>
      </>
    );
  };

  return <Wrapper>{editMode || !_user ? <Editor /> : <Static />}</Wrapper>;
};

export default StudentSelector;
