import React from "react";
import { arrays } from "utils";
import {
  EmptyMessage,
  Table,
  Label,
  DetailLabel,
  DateTime,
  Currency,
} from "ui-kit";
import { SchoolAvatar, UserName } from "sharedComponents/common";
import CreditStatus from "./creditStatus";
import CreditType from "./creditType";
import { useNavigate } from "react-router-dom";
import { PageInfo } from "types/common/paging.types";
import { SmartcardSyncResultListView, SyncView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


interface ICreditsTableProps {
  credits: SmartcardSyncResultListView[];
  loading?: boolean;
  error?: boolean;
  onRowClicked?: (credit: SmartcardSyncResultListView) => void;
  showUser?: boolean;
  caption?: string;
  paging?: PageInfo;
  onPage?: () => void;
  grow?: boolean;
}


const CreditsTable: React.FC<ICreditsTableProps> = ({
  credits,
  loading,
  error,
  onRowClicked,
  showUser,
  caption,
  paging,
  onPage,
  grow,
}) => {

  const navigate = useNavigate();

  const handleRowClicked = (credit: SmartcardSyncResultListView) => {
    onRowClicked?.(credit);
  };

  const handleOrderClicked = (e: React.MouseEvent, credit: SmartcardSyncResultListView) => {
    e.stopPropagation();
    navigate(`/main/shopkeeper/users/${credit.user.id}/orders/${credit.orderLine.orderId}`);
  };

  return (
    <Table
      zebra
      caption={caption}
      error={error}
      loading={loading}
      empty={arrays.isEmpty(credits)}
      emptyMessage={
        <EmptyMessage
          icon="id-card-alt"
          title="No Credit Sync Records"
          summary="No credits sync records were found"
          cover
        />
      }
      grow={grow}
    >
      <Table.Header>
        <Table.HeaderCell width={1}>School</Table.HeaderCell>
        <Table.HeaderCell width={2} show={showUser}>
          User
        </Table.HeaderCell>
        <Table.HeaderCell width={0.5}>Order</Table.HeaderCell>
        <Table.HeaderCell width={1}>Provider</Table.HeaderCell>
        <Table.HeaderCell width={0.5}>Status</Table.HeaderCell>
        <Table.HeaderCell width={0.5} right>
          Total
        </Table.HeaderCell>
        <Table.HeaderCell width={1} right>
          Processed
        </Table.HeaderCell>
      </Table.Header>
      <Table.Body onPage={onPage} paging={paging}>
        {credits?.map((credit, index) => (
          <Table.Row key={index} onClick={() => handleRowClicked(credit)}>
            <Table.Cell width={1}>
              <SchoolAvatar school={credit.school} />
            </Table.Cell>
            <Table.Cell width={2} show={showUser}>
              <DetailLabel
                bold
                label={<UserName user={credit.user} />}
                sub={credit.user.simsId}
              />
            </Table.Cell>
            <Table.Cell width={0.5}>
              <Label onClick={(e) => handleOrderClicked(e, credit)}>
                {credit.orderLine?.orderId}
              </Label>
            </Table.Cell>
            <Table.Cell width={1}>
              <Label>{credit.smartcardProviderName}</Label>
            </Table.Cell>
            <Table.Cell width={0.5} chip>
              <CreditStatus status={credit.status} />
            </Table.Cell>
            <Table.Cell width={0.5} right>
              <Currency value={credit.total} />
            </Table.Cell>
            <Table.Cell width={1} right>
              <DateTime date={credit.syncProcessedDate} bold />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
export default CreditsTable;
