import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  EmptyMessage,
  Title,
  TitleSize,
  Text,
  Card,
  Divider,
  Table,
  Loader,
  ActionBar,
  Button,
  Swatches,
  Size,
  Message,
  SplitButton,
  Left,
  Right,
  ToastService,
} from "ui-kit";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import rolesActions from "areas/administration/actions/rolesActions";
import reportingActions from "areas/administration/actions/reportingActions";
import StaffSearch from "sharedComponents/common/users/staffSearch";
import Avatar from "sharedComponents/common/users/avatar";
import { RootState } from "reducers/store";
import { RoleListView } from "types/users/userRoles.types";
import { files } from "utils";
import { UserListView } from "types/users/userListViews.types";
import { adminRoutes } from "areas/administration/adminRoutes";
import { useScroll } from "hooks/useScroll";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;


interface IRoleStaffProps {
  role: RoleListView;
}


const RoleStaff: React.FC<IRoleStaffProps> = ({ role }) => {

  const { scrollToTop } = useScroll();
  const navigate = useNavigate();
  const { working: reporting, error: reportError } = useSelector((state: RootState) => state.managedUserReporting);
  const { staff, loading, error, working, staffError } = useSelector((state: RootState) => state.roleStaff);
  const [_role, _setRole] = useState(null);
  const [_selectedStaffMember, _setSelectedStaffMember] = useState(null);

  useEffect(() => {
    _setRole(role);

    if (role) {
      rolesActions.getStaffByRole(role.id);
    }
  }, [role]);

  useEffect(() => {
    staffError && scrollToTop();
  }, [staffError]);

  const handleRemoveStaff = (staffMember: UserListView) => {
    if (
      window.confirm(
        `This will remove ${staffMember.firstName} ${staffMember.lastName} from the ${_role.name} role. Are you sure?`
      )
    ) {
      rolesActions.removeStaffFromRole(_role.id, staffMember.id, () => {
        ToastService.pop(
          `${staffMember.firstName} ${staffMember.lastName} removed from ${_role.name} role`,
          null,
          "user"
        );
      });
    }
  };

  const handleAddStaff = () => {
    rolesActions.addStaffToRole(_role.id, _selectedStaffMember.id, () => {
      ToastService.pop(
        `${_selectedStaffMember.firstName} ${_selectedStaffMember.lastName} added to ${_role.name} role`,
        null,
        "user"
      );
      _setSelectedStaffMember(null);
    });
  };

  const handleExport = () => {
    reportingActions.staffRolesReport(
      { schoolId: -1, role: _role.id },
      data => {
        files.openCsv(data);
      }
    );
  };

  const handleGoToProfile = (staffMember: UserListView) => {
    navigate(
      adminRoutes.getUserPath(staffMember.id)
    )
  };

  if (!_role && !loading) {
    return (
      <Wrapper>
        <EmptyMessage icon="users" title="Please select a role" cover />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Title text={_role.name} size={TitleSize.H3} />
      <Text>{_role.description}</Text>
      <Divider />
      <Message text={reportError} color={Swatches.Danger} />
      <Card>
        <Card.Body>
          <ActionBar low>
            <Left>
              <StaffSearch onChange={user => _setSelectedStaffMember(user)} />
              <Button
                color={Swatches.Primary}
                text="Add to Role"
                size={Size.Small}
                onClick={handleAddStaff}
                working={working}
              />
            </Left>
            <Right>
              <Button
                color={Swatches.Default}
                text="Export Staff List to CSV"
                size={Size.Small}
                onClick={handleExport}
                working={reporting}
              />
            </Right>
          </ActionBar>
        </Card.Body>
      </Card>

      {loading ? (
        <Loader size={Size.Medium} />
      ) : (
        <>
          <Message text={error} color={Swatches.Danger} />
          <Message text={staffError} color={Swatches.Danger} />
          <Card title="Staff in Role">
            <Card.Body noPad>
              <Table
                emptyMessage={
                  <EmptyMessage
                    icon="briefcase"
                    title="No staff in role"
                    cover
                  />
                }
              >
                {staff?.map((staffMember, index) => (
                  <Table.Row key={index}>
                    <Table.Cell width={7}>
                      <Link
                        to={`/main/administration/users/${staffMember.id}`}
                        key={index}
                      >
                        <Avatar
                          user={staffMember}
                          size={Size.Small}
                          sub={staffMember.school?.name}
                        />
                      </Link>
                    </Table.Cell>
                    <Table.Cell width={3} right>
                      <SplitButton
                        text="Remove"
                        onDefaultClick={() => handleRemoveStaff(staffMember)}
                        size={Size.Small}
                        color={Swatches.Low}
                        working={working}
                      >
                        <SplitButton.Option
                          text="Remove"
                          onClick={() => handleRemoveStaff(staffMember)}
                          show={true}
                        />
                        <SplitButton.Option
                          text="Go to Staff Profile"
                          onClick={() => handleGoToProfile(staffMember)}
                          show={true}
                        />
                      </SplitButton>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table>
            </Card.Body>
          </Card>
        </>
      )}
    </Wrapper>
  );
};

export default RoleStaff;
