import React from "react";
import {
  textFont,
  fontStyle,
  typescale,
  neutral,
  Spacing,
  HtmlText,
} from "ui-kit";
import styled from "styled-components";
import { ArticleDetailView } from "areas/news/types/newsResponse.types";


const Wrapper = styled.div`
  p {
    ${fontStyle(textFont.roman, typescale.paragraph, neutral[700])}
    margin-bottom: 16px;
  }
  margin-bottom: ${Spacing.Default}px;
  padding-bottom: ${Spacing.Jumbo}px;
  border-bottom: 1px solid ${neutral[400]};
`;

interface IArticleBodyProps {
  article: ArticleDetailView;
}

const ArticleBody: React.FC<IArticleBodyProps> = ({ article }) => {
  return article ? (
    <Wrapper>
      <HtmlText html={article.body} />
    </Wrapper>
  ) : null;
};

export default ArticleBody;
