import paymentActions from "areas/payments/actions/paymentsActions";
import { UserProductOrderView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


interface IManagedProductOrdersState {
  orders: UserProductOrderView[],
  error: string | null;
  loading: boolean;
  collecting: boolean;
  collectError: string | null;
}

const INITIAL_STATE: IManagedProductOrdersState = {
  orders: [],
  error: null,
  loading: false,
  collecting: false,
  collectError: null,
};


const managedProductOrdersReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IManagedProductOrdersState => {
  
  const {
    PAYMENTS_GETMANAGEDPRODUCTORDERS,
    PAYMENTS_MARKORDERLINECOLLECTED
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETMANAGEDPRODUCTORDERS.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETMANAGEDPRODUCTORDERS.SUCCESS:
      return { 
        ...state, 
        orders: action.payload, 
        loading: false 
      };

    case PAYMENTS_GETMANAGEDPRODUCTORDERS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PAYMENTS_MARKORDERLINECOLLECTED.SUCCESS:
      return {
        ...state,
        collecting: false,
        collectError: null,
        orders: state.orders.map(item =>
          item.id === action.payload.id
            ? { ...item, ...action.payload, isCollectable: false }
            : item
        )
      };

    default:
      return state;
  }
};

export default managedProductOrdersReducer;
