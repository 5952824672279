import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import paymentActions from "areas/payments/actions/paymentsActions";
import {
  Subtitle,
  HeadlineStatistic,
  Spacing,
  Currency,
  Message,
  ToastService,
  Swatches
} from "ui-kit";
import CreditsTable from "../smartcards/creditsTable";
import flyoutActions from "actions/ui/flyouts";
import PrintCreditFlyout from "../smartcards/printCreditFlyout";
import { Constants } from "configuration";
import TopupButton from "../smartcards/topupButton";
import { RootState } from "reducers/store";
import { SmartcardDetailView, SmartcardSyncResultListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { AddPrintCreditToBasketCommand } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";


const TopupWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  .card {
    margin-right: ${Spacing.Large}px;
    &:last-child {
      margin-right: 0;
    }
  }
`;


interface IUserPrintCreditsProps {
  smartcard: SmartcardDetailView;
}


const UserPrintCredit: React.FC<IUserPrintCreditsProps> = ({ smartcard }) => {

  const { syncRecords, loading: loadingSyncRecords, error: syncRecordsError } = useSelector((state: RootState) => state.userPrintCreditSyncRecords);
  const { working: basketWorking, errors: basketError } = useSelector((state: RootState) => state.userBasket);
  const [selectedCredit, setSelectedCredit] = useState<SmartcardSyncResultListView | null>(null);

  useEffect(() => {
    if (smartcard && smartcard.user && smartcard.school) {
      paymentActions.getPrintCreditSyncRecordsForUser(smartcard.user.id);
    }
  }, [smartcard]);

  const handleCreditClick = (credit: SmartcardSyncResultListView) => {
    setSelectedCredit(credit);
    flyoutActions.openFlyout(Constants.FLYOUTS.CREDIT);
  };

  const handleTopupAdded = (value: number) => {
    const data: AddPrintCreditToBasketCommand = {
      userId: smartcard.user.id,
      recipientId: smartcard.user.id,
      amount: value
    };
    paymentActions.addPrintCreditToBasket(smartcard.user.id, data, () => {
      ToastService.pop("Print Credit added to basket", null, "shopping-bag");
    });
  };

  return (
    <>
      <HeadlineStatistic>
        <HeadlineStatistic.Item
          icon="id-card-alt"
          value={<Currency value={smartcard?.printCreditBalance} />}
          label="Balance"
        />
      </HeadlineStatistic>

      <Subtitle text="Print Credit Topups" />
      <TopupWrapper>
        {basketError?.item && (
          <Message
            text="There was a problem adding the print credit to the basket"
            color={Swatches.Danger}
          />
        )}
        {Constants.TOPUP_AMOUNTS.map(value => (
          <TopupButton
            key={value}
            value={value}
            buttonText="Add Print Credit"
            onAddToBasket={handleTopupAdded}
            working={basketWorking}
            tooltip="Add Print Credit to Basket"
            tooltipSub={`Add a £${value} print credit to the basket`}
          />
        ))}
      </TopupWrapper>
      <CreditsTable
        caption="Sync Records"
        credits={syncRecords}
        loading={loadingSyncRecords}
        error={syncRecordsError != null}
        onRowClicked={handleCreditClick}
      />
      <PrintCreditFlyout credit={selectedCredit} />
    </>
  );
};

export default UserPrintCredit;
