import { IBeehiveAction } from "types/common/action.types";
import smartTaskReportActions from "../actions/smartTaskReportActions";
import { IBeehiveError } from "types/common/errors.types";
import { SmartTaskReportView } from "../types/smartTaskAnalyticsResponse.types";


interface ISmartTaskReportState {
  report: SmartTaskReportView[] | null;
  refresh: boolean;
  loading: boolean;
  loadingExportReport: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: ISmartTaskReportState = {
  report: null,
  refresh: false,
  loading: false,
  loadingExportReport: false,
  error: null,
};


const smartTaskReportReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISmartTaskReportState => {

  const { 
    GETSMARTTASKREPORT, 
    EXPORTSMARTTASKREPORT 
  } = smartTaskReportActions.types;


  switch (action.type) {
    case GETSMARTTASKREPORT.START:
      return { ...state, loading: true, error: null };

    case GETSMARTTASKREPORT.SUCCESS:
      return { ...state, report: action.payload, loading: false };

    case GETSMARTTASKREPORT.FAILED:
      return { ...state, loading: false, error: action.payload };

    case EXPORTSMARTTASKREPORT.START:
      return { ...state, loadingExportReport: true, error: null };

    case EXPORTSMARTTASKREPORT.SUCCESS:
      return { ...state, loadingExportReport: false };

    case EXPORTSMARTTASKREPORT.FAILED:
      return { ...state, loadingExportReport: false, error: action.payload };

    default:
      return state;
  }
};

export default smartTaskReportReducer;
