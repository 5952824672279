import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { CheckPasswordResponse } from "types/users/userPassword.types";
import { ChangePasswordCommand, ChangeUserPasswordCommand, CheckPasswordCommand, SendResetPasswordByEmailCommand } from "../types/passwordRequest.types";
import { VerifyPasswordResetCodeView } from "../types/passwordResponse.types";

const builder = new actionTypeBuilder("usermanagment");

const types = {
  PASSWORD_FORGOT: builder.build("PASSWORD_FORGOT"),
  PASSWORD_RESET: builder.build("PASSWORD_RESET"),
  PASSWORD_VERIFY: builder.build("PASSWORD_VERIFY"),
  PASSWORD_MANUALRESET: builder.build("PASSWORD_MANUALRESET"),
  PASSWORD_CHECK: builder.build("PASSWORD_CHECK"),
  PASSWORD_CHANGE: builder.build("PASSWORD_CHANGE"),
  PASSWORD_UPDATE: builder.build("PASSWORD_UPDATE"),
};


const forgot = (command: SendResetPasswordByEmailCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`users/password/forgot`, command),
    types.PASSWORD_FORGOT,
    callback
  );

const reset = (userId: string, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`users/${userId}/password/reset`),
    types.PASSWORD_RESET,
    callback
  );

const manualReset = (userId: string, callback?: callbackType<string>) =>
  action<string>(
    () => client.post(`users/${userId}/password/reset/manual`),
    types.PASSWORD_MANUALRESET,
    callback
  );

const verify = (passwordResetCode: string, callback?: callbackType<VerifyPasswordResetCodeView>) =>
  action<VerifyPasswordResetCodeView>(
    () => client.get(`users/password/verify/${passwordResetCode}`),
    types.PASSWORD_VERIFY,
    callback
  );

const check = (password: string, callback?: callbackType<CheckPasswordResponse>) =>
  action<CheckPasswordResponse>(
    () => client.post(`users/password/check`, { password }),
    types.PASSWORD_CHECK,
    callback
  );

const change = (data: ChangePasswordCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`users/password/change`, data),
    types.PASSWORD_CHANGE,
    callback
  );

// This is for logged in users changing
const update = (userId: string, data: ChangeUserPasswordCommand, callback?: callbackType<null>) =>
  action(
    () => client.post(`users/${userId}/password/change`, data),
    types.PASSWORD_UPDATE,
    callback
  );

const passwordActions = {
  types,
  forgot,
  reset,
  manualReset,
  verify,
  check,
  change,
  update,
};

export default passwordActions;
