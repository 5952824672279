import paymentActions from "areas/payments/actions/paymentsActions";
import { OrderDetailView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface IUserOrderState {
  order: OrderDetailView | null;
  error: string | null;
  loading: boolean;
  refunding: boolean;
  changing: boolean;
  cancelling: boolean;
  voiding: boolean;
  sendingReceipt: boolean;
  refundError: string | null;
  receiptError: string | null;
  cancelError: string | null;
  changeError: string | null;
  voidError: string | null;
  addingNote: boolean;
  noteError: string | null;
  collecting: boolean;
  collectError: string | null;
}

const INITIAL_STATE: IUserOrderState = {
  order: null,
  error: null,
  loading: false,
  refunding: false,
  changing: false,
  cancelling: false,
  voiding: false,
  sendingReceipt: false,
  refundError: null,
  receiptError: null,
  cancelError: null,
  changeError: null,
  voidError: null,
  addingNote: false,
  noteError: null,
  collecting: false,
  collectError: null
};

const userOrderReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUserOrderState => {
  const {
    PAYMENTS_GETORDER,
    PAYMENTS_MARKORDERLINECOLLECTED,
    PAYMENTS_ISSUEREFUND,
    PAYMENTS_SENDRECEIPT,
    PAYMENTS_CANCELORDER,
    PAYMENTS_CHANGEORDER,
    PAYMENTS_VOIDORDER,
    PAYMENTS_ADDORDERNOTE,
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETORDER.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETORDER.SUCCESS:
      return { 
        ...state, 
        order: action.payload, 
        loading: false 
      };

    case PAYMENTS_GETORDER.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PAYMENTS_MARKORDERLINECOLLECTED.SUCCESS:
      return {
        ...state,
        collecting: false,
        collectError: null,
        order: {
          ...state.order,
          lines: state.order?.lines?.map((item) =>
            item.id === action.payload.id
              ? { ...item, ...action.payload, isCollectable: false }
              : item
          ),
        },
      };

    case PAYMENTS_ISSUEREFUND.START:
      return { 
        ...state, 
        refunding: true, 
        refundError: null 
      };

    case PAYMENTS_ISSUEREFUND.SUCCESS:
      return { 
        ...state, 
        order: action.payload, 
        refunding: false 
      };

    case PAYMENTS_ISSUEREFUND.FAILED:
      return {
        ...state,
        refunding: false,
        refundError: action.payload,
      };

    case PAYMENTS_SENDRECEIPT.START:
      return { 
        ...state, 
        sendingReceipt: true, 
        receiptError: null
      };

    case PAYMENTS_SENDRECEIPT.SUCCESS:
      return { 
        ...state, 
        sendingReceipt: false
      };

    case PAYMENTS_SENDRECEIPT.FAILED:
      return {
        ...state,
        sendingReceipt: false,
        receiptError: action.payload,
      };

    case PAYMENTS_CANCELORDER.START:
      return { 
        ...state, 
        cancelling: true, 
        cancelError: null
      };

    case PAYMENTS_CANCELORDER.SUCCESS:
      return { 
        ...state, 
        cancelling: false
      };

    case PAYMENTS_CANCELORDER.FAILED:
      return {
        ...state,
        cancelling: false,
        cancelError: action.payload,
      };

    case PAYMENTS_VOIDORDER.START:
      return { 
        ...state, 
        voiding: true, 
        voidError: null
      };

    case PAYMENTS_VOIDORDER.SUCCESS:
      return { 
        ...state, 
        voiding: false
      };

    case PAYMENTS_VOIDORDER.FAILED:
      return {
        ...state,
        voiding: false,
        voidError: action.payload,
      };

    case PAYMENTS_ADDORDERNOTE.START:
      return { 
        ...state, 
        addingNote: true, 
        noteError: null
      };

    case PAYMENTS_ADDORDERNOTE.SUCCESS:
      return {
        ...state,
        addingNote: false,
        order: {
          ...state.order,
          notes: [action.payload, ...state.order.notes],
        },
      };

    case PAYMENTS_ADDORDERNOTE.FAILED:
      return {
        ...state,
        addingNote: false,
        noteError: action.payload,
      };

    case PAYMENTS_CHANGEORDER.START:
      return { 
        ...state, 
        changing: true, 
        changeError: null
      };

    case PAYMENTS_CHANGEORDER.SUCCESS:
      return { 
        ...state, 
        changing: false
      };

    case PAYMENTS_CHANGEORDER.FAILED:
      return {
        ...state,
        changing: false,
        changeError: action.payload,
      };

    default:
      return state;
  }
};

export default userOrderReducer;
