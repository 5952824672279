import classRoomActions from "../../actions/classViewer/classRoomActions";
import groupActions from "../../actions/group/groupActions";

const INITIAL_STATE = {
  roomLayouts: [],
  loading: false,
  error: null,
  refresh: false,
};

const { GETROOMLAYOUTS, CREATEROOMLAYOUT } = classRoomActions.types;

const classViewerRoomLayoutsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GETROOMLAYOUTS.START:
      return { ...state, loading: true, error: false, refresh: false };

    case GETROOMLAYOUTS.SUCCESS:
      return {
        ...state,
        roomLayouts: action.payload,
        loading: false,
      };

    case GETROOMLAYOUTS.FAILED:
      return { ...state, loading: false, error: action.payload };

    case CREATEROOMLAYOUT.SUCCESS:
      return { ...state, refresh: true };

    default:
      return state;
  }
};

export default classViewerRoomLayoutsReducer;
