import React from "react";
import { RadioList, Spacing, honeygold } from "ui-kit";
import styled from "styled-components";
import { TIME_SCALE } from "areas/planner/constants/classViewer/primarySchool";


const Wrapper = styled.div`
  margin-bottom: ${Spacing.Large}px;
  width: 400px;

  .button {
    background: rgba(0, 0, 0, 0.8);
    color: white;
  }

  .radio-list {
    width: auto;
  }

  .radio-list-item {
    background: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 8px;
  }

  .radio-list-item-selected {
    background: ${honeygold};
  }
`;


interface IPrimaryLeaderboardActionBarProps {
  categoryTypeId?: number;
  handleSelectCategoryType?: (categoryType: number) => void;
  timeScale: number;
  handleTimeScaleClick: (timeScale: number) => void;
}


const PrimaryLeaderboardActionBar: React.FC<IPrimaryLeaderboardActionBarProps> = ({
  categoryTypeId,
  handleSelectCategoryType,
  timeScale,
  handleTimeScaleClick,
}) => {

  return (
    <Wrapper>
      <RadioList
        value={timeScale}
        onChange={value => handleTimeScaleClick(value)}
        fluid
      >
        <RadioList.Item label="Today" value={TIME_SCALE.DAY} />
        <RadioList.Item
          label="This Week"
          value={TIME_SCALE.WEEK}
        />
      </RadioList>
    </Wrapper>
  );
};

export default PrimaryLeaderboardActionBar;
