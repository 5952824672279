import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAction } from "hooks";
import {
  Modal,
  Button,
  Right,
  ActionBar,
  Swatches,
  Size,
  StructuredList,
  Card,
  EmptyMessage,
  DateTime,
  Message,
  Loader,
} from "ui-kit";
import paymentActions from "areas/payments/actions/paymentsActions";
import reportingActions from "areas/payments/actions/reportingActions";
import IncomingTransactionsTable from "./incomingTransactionsTable";
import { RootState } from "reducers/store";
import { IncomingReportListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { BeehiveReport } from "types/common/action.types";
import { files } from "utils";


interface ITransactionsModal {
  report: IncomingReportListView;
  open: boolean;
  onClose?: () => void;
}


const TransactionsModal: React.FC<ITransactionsModal> = ({ report, open, onClose }) => {

  const { loading, error, transactions } = useSelector((state: RootState) => state.incomingTransactions);
  const { loading: reporting, error: reportError, reportData } = useSelector((state: RootState) => state.paymentsReporting);
  const [_report, _setReport] = useState<IncomingReportListView | null>(null);
  const [_open, _setOpen] = useState<boolean>(false);

  useEffect(() => {
    _setReport(report);

    if (report) {
      paymentActions.getIncomingTransactions(report.id);
    }
  }, [report]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const handleExport = () => {
    reportingActions.runIncomingTransactionsReport(
      { 
        startDate: _report.startDate, 
        endDate: _report.endDate 
      },
      (reportData: BeehiveReport) => {
        files.openCsv(reportData);
      }
    );
  };

  const handleClose = () => {
    onClose?.();
  };

  return (
    <Modal
      title="Report Transactions"
      open={_open}
      onClose={handleClose}
      width="95%"
      height="95%"
    >
      <Modal.Body>
        {_report ? (
          <>
            <Message text={error} color={Swatches.Danger} />
            <Message text={reportError} color={Swatches.Danger} />
            <Card>
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="Start Date">
                    <DateTime bold date={_report.startDate} />
                  </StructuredList.Item>
                  <StructuredList.Item name="End Date">
                    <DateTime bold date={_report.endDate} />
                  </StructuredList.Item>
                  <StructuredList.Item name="Imported / Duplicates">
                    <b>{_report.importedCount}</b> / {_report.duplicateCount}
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>

            {loading ? (
              <Loader cover size={Size.Medium} />
            ) : (
              <Card title="Imported Transactions">
                <Card.Body noPad>
                  <IncomingTransactionsTable transactions={transactions} />
                </Card.Body>
              </Card>
            )}
          </>
        ) : (
          <EmptyMessage
            icon="file-spreadsheet"
            title="No Report"
            summary="No report was selected"
            cover
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              onClick={handleExport}
              size={Size.Small}
              color={Swatches.Primary}
              text="Export Transactions"
              working={reporting}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default TransactionsModal;
