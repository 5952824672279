import { IBeehiveAction } from "types/common/action.types";
import senAdminActions from "areas/send/actions/senAdministration/senAdministrationActions";
import { SenAdminOverviewView } from "areas/send/types/senAdminResponse.types";

interface ISendAdminState {
  schoolSenOverview: SenAdminOverviewView | null,
  overviewLoading: boolean;
  overviewError: string | null;
  passportActionLoading: boolean,
  passportActionError: string | null
}

const INITIAL_STATE: ISendAdminState = {
  schoolSenOverview: null,
  overviewLoading: false,
  overviewError: null,
  passportActionLoading: false,
  passportActionError: null
};


const sendAdminReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISendAdminState => {

  const { ADMIN_OVERVIEW_GET, ADMIN_PASSPORTSTATUSCHANGE } = senAdminActions.types;

  switch (action.type) {
    case ADMIN_OVERVIEW_GET.START:
      return { 
        ...state, 
        overviewError: null, 
        overviewLoading: true 
      };
    case ADMIN_OVERVIEW_GET.SUCCESS:
      return { 
        ...state, 
        overviewLoading: false,
        schoolSenOverview: action.payload
      };
    case ADMIN_OVERVIEW_GET.FAILED:
      return {
        ...state,
        overviewLoading: false,
        overviewError: "There was a problem loading the SEN admin overview.",
      };

    case ADMIN_PASSPORTSTATUSCHANGE.START:
      return {
        ...state,
        passportActionError: null,
        passportActionLoading: true
      }
    case ADMIN_PASSPORTSTATUSCHANGE.SUCCESS:
      return {
        ...state,
        passportActionLoading: false,
        schoolSenOverview: {
          ...(state.schoolSenOverview || {}),
          passportsOverview: action.payload
        }
      }
    case ADMIN_PASSPORTSTATUSCHANGE.FAILED:
      return {
        ...state,
        passportActionLoading: false,
        passportActionError: "There was a problem updating the passport statuses for this school"
      }

    default:
      return state;
  }
};

export default sendAdminReducer;
