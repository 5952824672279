import React from "react";
import { useSelector } from "react-redux";
import Subheader from "sharedComponents/layout/header/subheader";
import {
  Title,
  TitleSize,
  StructuredList,
  Card,
  Button,
  Size,
  Swatches,
} from "ui-kit";
import { Link, useNavigate } from "react-router-dom";
import { ParentView, NonParentView } from "sharedComponents/common";
import routes from "configuration/routes";
import { RootState } from "reducers/store";

const Security = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.currentUser);

  const updateDetails = (userId: string) => {
    navigate(routes.profile.getUpdateUserProfilePath(userId));
  };

  return (
    <>
      <Subheader>
        <Title size={TitleSize.H2} text="Password & Security" />
      </Subheader>

      <NonParentView>
        <Card title="Your Password">
          <Card.Body>
            <p>
              Your system password should be strong and different from all your
              other passwords.
            </p>
            <p>
              To create a strong and unique password use three random words and
              numbers and/or symbols. Combining three random words that each
              mean something to you is a great way to create a password that is
              easy to remember but hard to crack.
            </p>
            <p>
              Some passwords that are well-known by hackers are not allowed. Do
              not use words that can be guessed (like your pet's name). Include
              numbers and symbols to make the password even stronger. For
              example, <i>Hippo!PizzaRocket1</i>
            </p>
            <p>
              You can change your password from any Trust PC or laptop by
              pressing <b>Ctrl+Alt+Delete</b> and then choosing{" "}
              <b>Change a Password</b>.
            </p>
            <p>
              Before you change your password, we recommend that you check the
              strength of your password using our{" "}
              <Link to={"security/checkpassword"}>Password Checker</Link>.
            </p>
          </Card.Body>
        </Card>
      </NonParentView>

      <ParentView>
        <Card title="Your Password">
          <Card.Body>
            <p>We recommend that you change your password regularly. </p>
            <StructuredList>
              <StructuredList.Item name="Your Password">
                *******{" "}
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
          <Card.Footer>
            <Button
              size={Size.Small}
              fluid={false}
              text="Change Your Password"
              onClick={() =>
                navigate(
                  `${routes.home}${routes.passwords.private.changePassword}`
                )
              }
              color={Swatches.Primary}
            />
          </Card.Footer>
        </Card>
      </ParentView>

      <Card title="Password Checker">
        <Card.Body>
          <p>
            You can check the strength of your password and whether it has been
            compromised using our <b>Password Checker Tool.</b>
          </p>
        </Card.Body>
        <Card.Footer>
          <Button
            fluid={false}
            text="Check Your Password"
            onClick={() =>
              navigate(
                `${routes.home}${routes.passwords.private.checkPassword}`
              )
            }
            color={Swatches.Primary}
          />
        </Card.Footer>
      </Card>
    </>
  );
};
export default Security;
