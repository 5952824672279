import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Loader, Size } from "ui-kit";
import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import InitialActionDropdown from "../behaviourCommon/initialActionDropdown";
import { useAppSelector } from "reducers/hooks";


export interface IInitialAction {
  initialActionId: number;
  initialActionName: string;
}


interface IInitialRewardActionWrapperProps {
  selectedInitialAction: IInitialAction;
  handleInitialActionChange: (action: IInitialAction) => void;
}


const InitialRewardActionWrapper: React.FC<IInitialRewardActionWrapperProps> = ({
  selectedInitialAction,
  handleInitialActionChange,
}) => {

  const { initialRewardActions, loadingRewardActions } = useAppSelector(
    state => state.initialRewardActions
  );

  useEffect(() => {
    if (!initialRewardActions || initialRewardActions.length === 0) {
      rewardActions.getInitialRewardActions();
    }
  }, []);

  useEffect(() => {
    if (initialRewardActions.length > 0) {
      handleInitialActionChange({
        initialActionId: initialRewardActions[0].initialActionId,
        initialActionName: initialRewardActions[0].name,
      });
    }
  }, [initialRewardActions]);

  if (loadingRewardActions) {
    return <Loader size={Size.Large} cover />;
  }

  return (
    <InitialActionDropdown
      selectedInitialAction={selectedInitialAction}
      handleChangeInitialAction={handleInitialActionChange}
      initialActions={initialRewardActions}
    />
  );
};

export default InitialRewardActionWrapper;
