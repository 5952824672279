import config from "configuration";


const { key } = config.pushNotifications;


/**
 * Function to set the SignalR Client Connection ID in local storage
 * @param {string} connectionId The signalR Client Connection ID
 * @returns {void}
 */
const setConnectionId = (connectionId: string) : void => {
  if (!connectionId) {
    throw new Error("An connection ID must be supplied.");
  }

  localStorage.setItem(key, connectionId);
};


/**
 * Function to get the SignalR Client Connection ID from local storage
 * @returns {string} The SignalR Client Connection ID
 */
const getConnectionId = () : string | null => {
  return localStorage.getItem(key);
};



const signalR = {
  setConnectionId,
  getConnectionId
};

export default signalR;
