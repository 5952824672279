import React, { useRef } from "react";
import styled from "styled-components";
import {
  Size,
  Swatches,
  neutral,
  Spacing,
  typescale,
  Icon,
  fontSize,
  Tooltip,
} from "../index";
import { ColorSwatch } from "../common/colors";
import Tippy from "@tippyjs/react";

export interface ITextButtonProps {
  text?: string;
  color?: ColorSwatch;
  icon?: string;
  working?: boolean;
  disabled?: boolean;
  className?: string;
  size?: Size;
  tooltip?: string;
  tooltipSub?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const getFontSize = (size: Size) => {
  switch (size) {
    case Size.Small:
      return fontSize(typescale.sub);
    case Size.Medium:
      return fontSize(typescale.helper);
    case Size.Large:
      return fontSize(typescale.paragraph);
    default:
      return fontSize(typescale.sub);
  }
};

const Wrapper = styled.button<{
  colorSwatch?: ColorSwatch;
  disabled?: boolean;
  working?: boolean;
  size?: Size;
}>`
  display: inline-flex;
  flex-grow: 0;
  align-items: center;
  box-sizing: border-box;
  border: 0;
  align-items: center;
  position: relative;
  background: none;
  text-align: left;
  margin-bottom: ${Spacing.Small}px;

  ${({ colorSwatch }) =>
    colorSwatch
      ? `color: ${colorSwatch.swatch}; .icon {color: ${colorSwatch.swatch};

    }`
      : `color: ${neutral[400]}; .icon {color: ${neutral[400]};}`}

  ${({ size }) => size && `${getFontSize(size)};`}

  margin: 0;
  padding: 0;
  cursor: ${props => (props.working ? "wait" : "pointer")};

  &:hover {
    color: ${neutral[600]};
    .icon {
      color: ${neutral[600]};
    }
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  .icon {
    margin-right: ${Spacing.Small}px;
    margin-left: 0;
  }
`;

const TextButton: React.FC<ITextButtonProps> = ({
  text,
  color,
  icon,
  working,
  disabled,
  size,
  className,
  tooltip,
  tooltipSub,
  onClick,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    !disabled && !working && onClick?.(e);
  };

  const ref = useRef() as React.RefObject<HTMLButtonElement>;

  return (
    <>
      <Wrapper
        ref={ref}
        className={`text-button ${className ? className : ""}`}
        colorSwatch={color}
        disabled={disabled}
        working={working}
        onClick={handleClick}
        size={size}
      >
        {icon && <Icon value={icon} size={size} />}
        {working ? text : text}
      </Wrapper>
      {tooltip != null && (
        <Tippy
          reference={ref}
          content={<Tooltip title={tooltip} sub={tooltipSub} />}
        />
      )}
    </>
  );
};

TextButton.defaultProps = {
  color: Swatches.Default,
  working: false,
  disabled: false,
};

export default TextButton;
