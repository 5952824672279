import React, { useState, useEffect } from "react";
import { Loader, Size, EmptyMessage } from "ui-kit";
import LeavingLessonTable from "./leavingLessonTable";
import { arrays } from "utils";
import { ApiExceptionMessage } from "../../../../sharedComponents/common";
import { useAppSelector } from "reducers/hooks";
import { GenerateLessonExitReportCommand } from "areas/analytics/types/lessonExitAnalyticRequest.types";
import { LessonExitGroupBy } from "areas/analytics/types/lessonExitAnalyticShared.types";



interface ILeavingLessonTableWrapperProps {
  reportParameters: GenerateLessonExitReportCommand;
}


const LeavingLessonTableWrapper: React.FC<ILeavingLessonTableWrapperProps> = ({ reportParameters }) => {

  const { report, loading, error } = useAppSelector(
    state => state.leavingLessonAnalytics
  );

  const [reportGroupType1, setReportGroupType1] = useState<LessonExitGroupBy | null>(null);
  const [reportGroupType2, setReportGroupType2] = useState<LessonExitGroupBy | null>(null);

  useEffect(() => {
    if (reportParameters) {
      setReportGroupType1(reportParameters.groupBy1);
      setReportGroupType2(reportParameters.groupBy2);
    }
  }, [reportParameters]);

  if (loading) {
    return <Loader size={Size.Large} fluid />;
  }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return (
    report && (
      <>
        {arrays.isEmpty(report.lessonExitGroupByView1s) ? (
          <EmptyMessage
            title="No leaving lesson records in the selected time period"
            icon="list"
          />
        ) : (
          report.lessonExitGroupByView1s.map(group => (
            <LeavingLessonTable
              group={group}
              reportGroupType1={reportGroupType1}
              reportGroupType2={reportGroupType2}
            />
          ))
        )}
      </>
    )
  );
};

export default LeavingLessonTableWrapper;
