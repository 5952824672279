import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { StudentReportFilter } from "../types/classGroupBehaviourAnalyticsRequests.types";
import { BehaviourReportView } from "../types/classGroupBehaviourAnalyticsResponse.types";

const builder = new actionTypeBuilder("classbehaviours");

const types = {
  GETCLASSBEHAVIOURS: builder.build("getclassbehaviours"),
};


const getClassBehaviourReport = (
  classGroupId: number,
  {
    startDate,
    endDate,
    gender,
    sen,
    eal,
    pp,
    academicYearId
  }: StudentReportFilter,
  onSuccess?: callbackType<BehaviourReportView>
) => {

  return action<BehaviourReportView>(
    () =>
      client.get(
        `planner/reporting/classGroups/${classGroupId}/classGroupBehaviourReport?StartDate=${startDate}&EndDate=${endDate}&gender=${gender}&sen=${sen}&eal=${eal}&pp=${pp}&academicYearId=${academicYearId}`
      ),
    types.GETCLASSBEHAVIOURS,
    onSuccess
  );
};


const classBehaviourReportActions = { 
  types, 
  getClassBehaviourReport 
};

export default classBehaviourReportActions;
