import { IBeehiveAction } from "types/common/action.types";
import passwordActions from "../../actions/passwordActions";


interface IPasswordState {
  verifying: boolean;
  error: string | null;
}

const INITIAL_STATE: IPasswordState = {
  verifying: false,
  error: null,
};


const passwordReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IPasswordState => {
  
  const { PASSWORD_VERIFY } = passwordActions.types;

  switch (action.type) {
    case PASSWORD_VERIFY.START:
      return { ...INITIAL_STATE, verifying: true };
    case PASSWORD_VERIFY.SUCCESS:
      return { ...INITIAL_STATE };
    case PASSWORD_VERIFY.FAILED:
      return { ...INITIAL_STATE, error: action.payload };
    default:
      return state;
  }
};

export default passwordReducer;
