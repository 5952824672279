import React, { useState, useEffect } from "react";
import { Dropdown } from "ui-kit";
import { Constants } from "configuration";


interface CreditStatusOption {
  key: number | string;
  label: string;
  value: number;
}

interface ICreditStatusDropdownProps {
  onChange?: (value: number) => void;
  initialValue?: number;
}


const CreditStatusDropdown: React.FC<ICreditStatusDropdownProps> = ({ onChange, initialValue }) => {

  const ALL_OPTION: CreditStatusOption = { 
    key: -1, 
    label: "All Statuses", 
    value: -1 
  };

  const [selectedValue, setSelectedValue] = useState<number>(-1);
  const [options, setOptions] = useState<CreditStatusOption[]>(() => {
    var opts = [];

    for (var key in Constants.CREDIT_STATUS) {
      opts.push({
        key: key,
        label: Constants.CREDIT_STATUS[key].name,
        value: Constants.CREDIT_STATUS[key].value
      });
    }

    return [ALL_OPTION, ...opts];
  });

  const handleChange = (value: number, label: string) => {
    setSelectedValue(value);

    if (onChange) {
      onChange(value);
    }
  };

  useEffect(() => {
    const option = options.find(x => x.value === initialValue);

    setSelectedValue(option ? option.value : -1);
  }, [initialValue]);

  return (
    <Dropdown
      placeholder="Status"
      items={options}
      onChange={handleChange}
      value={selectedValue}
    />
  );
};
export default CreditStatusDropdown;
