import React from "react";
import { DetailLabel } from "../index";
import Moment from "react-moment";
import moment from "moment";

interface IDateTimeProps {
  date: moment.MomentInput;
  bold?: boolean;
  hideTime?: boolean;
}

const DateTime: React.FC<IDateTimeProps> = ({ date, bold, hideTime }) => {
  if (!date || !moment(date).isValid()) {
    return null;
  }

  return (
    <DetailLabel
      bold={bold}
      label={<Moment date={date} format="DD/MM/YYYY" />}
      sub={hideTime ? null : <Moment date={date} format="HH:mm A" />}
    />
  );
};

export default DateTime;
