import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SmartTaskDetails from "./smartTaskDetails";
import SmartTaskConditionsWrapper from "./smartTaskConditionsWrapper";
import SmartTaskActions from "./smartTaskActions";
import SmartTaskOptions from "./smartTaskOptions";
import { useDispatch, useSelector } from "react-redux";
import {
  Message,
  StructuredList,
  Swatches,
  Button,
  EmptyMessage,
  ActionBar,
  ValidationMessage,
} from "ui-kit";
import { arrays } from "utils";
import smartTaskActions from "areas/behaviour/actions/smartTasks/smartTaskActions";
import { useAppSelector } from "reducers/hooks";
import { SchoolSmartTaskView } from "areas/behaviour/types/smartTasks/smartTaskResponse.types";
import { SaveSmartTaskCommand } from "areas/behaviour/types/smartTasks/smartTaskRequest.types";
import { EventName } from "areas/behaviour/types/smartTasks/smartTaskShared.types";
import { useScroll } from "hooks/useScroll";


interface INewSmartTaskFormWrapperProps {
  schoolId: number;
}

export type smartTaskData = Required<Omit<SchoolSmartTaskView & Omit<SaveSmartTaskCommand, "staffEmailRecipients">, "id">>;


const NewSmartTaskFormWrapper: React.FC<INewSmartTaskFormWrapperProps> = ({ schoolId }) => {

  const { scrollToTop } = useScroll();
  const { smartTask, createSmartTaskError } = useAppSelector(
    state => state.smartTask
  );
  const [selectedSmartTask, setSelectedSmartTask] = useState<smartTaskData | null>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    smartTask && setSelectedSmartTask({
      ...smartTask,
      schoolId: schoolId,
      sendNotificationToParents: smartTask.sendNotificationToParents || false,
      sendNotificationToPastoral: smartTask.sendNotificationToPastoral || false,
      sendNotificationToLeadership: smartTask.sendNotificationToLeadership || false,
      behaviourCodeId: smartTask.behaviourCodeId,
      yearGroupId: smartTask.yearGroupId,
      classGroupId: smartTask.classGroupId,
      subjectId: smartTask.subjectId,
      schoolBehaviourCategoryId: smartTask.schoolBehaviourCategoryId,
      calendarCategoryId: smartTask.calendarCategoryId
    });
  }, [smartTask]);

  const addSmartTask = () => {
    const callback = () => {
      smartTaskActions.resetSelectedSmartTask();
    };

    setValidationErrors([]);
    var errors: string[] = [];
    if (selectedSmartTask.eventNameId === EventName.Event && selectedSmartTask.calendarCategoryId == null) {
      errors.push("Event smarttasks must have an event category");
    }
    setValidationErrors(errors);
    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    const newSmartTask: SaveSmartTaskCommand = {
      ...selectedSmartTask, 
      schoolId: schoolId,
      staffEmailRecipients: selectedSmartTask.staffEmailRecipients.map(x => x.id)
    };

    smartTaskActions.createSmartTask(schoolId, newSmartTask, callback);
  };

  return selectedSmartTask ? (
    <>
      <StructuredList>
        {createSmartTaskError && (
          <Message
            text={createSmartTaskError.message}
            color={Swatches.Danger}
          />
        )}
        <ValidationMessage errors={validationErrors} />

        <SmartTaskDetails
          smartTask={selectedSmartTask}
          setSmartTask={setSelectedSmartTask}
          schoolId={schoolId}
        />

        <SmartTaskConditionsWrapper
          smartTask={selectedSmartTask}
          setSmartTask={setSelectedSmartTask}
          schoolId={schoolId}
        />

        <SmartTaskActions
          smartTask={selectedSmartTask}
          setSmartTask={setSelectedSmartTask}
          schoolId={schoolId}
        />

        <SmartTaskOptions
          smartTask={selectedSmartTask}
          setSmartTask={setSelectedSmartTask}
        />
      </StructuredList>

      <ActionBar>
        <Button
          color={Swatches.Success}
          disabled={
            (selectedSmartTask.sendNotificationToParents && selectedSmartTask.actionNote.trim() === "") ||
            //(selectedSmartTask.sendNotificationToStaff && arrays.isEmpty(selectedSmartTask.staffEmailRecipients)) ||
            selectedSmartTask.name.trim() === ""
          }
          text="Add Smart Task"
          onClick={addSmartTask}
        />

        <Button
          color={Swatches.Low}
          text="Cancel"
          onClick={() => smartTaskActions.resetSelectedSmartTask()}
        />
      </ActionBar>
    </>
  ) : (
    <EmptyMessage title="No Smart Task Selected" icon="tasks" cover />
  );
};

export default NewSmartTaskFormWrapper;
