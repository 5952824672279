import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import StudentClassList from "./studentClassList";
import { Loader, Size } from "ui-kit";
import { arrays } from "utils";
import { Constants } from "configuration";
import studentActions from "areas/planner/actions/student/studentActions";
import customGroupActions from "areas/planner/actions/group/customGroupActions";
import groupsActions from "areas/planner/actions/groups/groupsActions";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { GROUP_LISTS } from "areas/planner/constants/groups/groups";
import CustomGroupEditorModal from "areas/planner/components/groups/customGroups/customGroupEditorModal";

const StudentClassListWrapper = ({ student }) => {
  const { studentClasses, loading, refresh, error } = useSelector(
    state => state.studentClasses
  );
  const { studentExpandedGroupsIds } = useSelector(state => state.groupLists);

  const [classGroups, setClassGroups] = useState([]);
  const [tutorGroups, setTutorGroups] = useState([]);
  const [customGroups, setCustomGroups] = useState([]);
  const [loadingRemoveGroup, setLoadingRemoveGroup] = useState(false);
  const [openCustomGroupModal, setOpenCustomGroupModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    studentActions.getStudentClasses(student.id);
  }, []);

  useEffect(() => {
    if (refresh) {
      studentActions.getStudentClasses(student.id);
    }
  }, [refresh]);

  useEffect(() => {
    if (!arrays.isEmpty(studentClasses)) {
      const tempClassGroups = [];
      const tempTutorGroups = [];
      const tempCustomGroups = [];

      studentClasses.forEach(studentClass => {
        if (studentClass.type === Constants.GROUP_TYPES.CLASS) {
          tempClassGroups.push(studentClass);
        } else if (studentClass.type === Constants.GROUP_TYPES.TUTOR) {
          tempTutorGroups.push(studentClass);
        } else if (studentClass.type === Constants.GROUP_TYPES.CUSTOM) {
          tempCustomGroups.push(studentClass);
        }
      });

      setClassGroups(tempClassGroups);
      setTutorGroups(tempTutorGroups);
      setCustomGroups(tempCustomGroups);
    }
  }, [studentClasses]);

  const handleRemoveGroup = (customGroupId, customGroupName) => {
    if (
      window.confirm(
        `Are you sure you want to remove ${student.firstName} ${student.lastName} from ${customGroupName}?`
      )
    ) {
      setLoadingRemoveGroup(true);
      customGroupActions.removeStudentFromCustomGroup(
        customGroupId,
        {
          id: customGroupId,
          studentId: student.id,
        },
        () => setLoadingRemoveGroup(false)
      );
    }
  };

  const handleAddGroupClick = () => {
    setOpenCustomGroupModal(true);
  };

  const onClickAccordionArrow = groupTypeId => {
    const tempExpandedGroupIds = studentExpandedGroupsIds.slice();
    const index = studentExpandedGroupsIds.findIndex(id => id === groupTypeId);
    if (index >= 0) {
      tempExpandedGroupIds.splice(index, 1);
    } else {
      tempExpandedGroupIds.push(groupTypeId);
    }
    groupsActions.saveStudentExpandedGroups(tempExpandedGroupIds);
  };

  if (loading || loadingRemoveGroup) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return (
    <>
      <StudentClassList
        studentClasses={tutorGroups}
        title="Tutor Group"
        isExpanded={studentExpandedGroupsIds.includes(GROUP_LISTS.TUTOR_GROUPS)}
        onClickArrow={() => onClickAccordionArrow(GROUP_LISTS.TUTOR_GROUPS)}
        showNumberOfGroups
      />
      <br />
      <StudentClassList
        studentClasses={classGroups}
        title="Classes"
        isExpanded={studentExpandedGroupsIds.includes(GROUP_LISTS.CLASS_GROUPS)}
        onClickArrow={() => onClickAccordionArrow(GROUP_LISTS.CLASS_GROUPS)}
        showNumberOfGroups
      />
      <br />
      <StudentClassList
        studentClasses={customGroups}
        title="Custom Groups"
        onClickRemoveGroup={handleRemoveGroup}
        showAddGroup
        addGroupText={`Add ${student.firstName} ${student.lastName} to a Custom Group`}
        handleAddGroupClick={handleAddGroupClick}
        isExpanded={studentExpandedGroupsIds.includes(
          GROUP_LISTS.CUSTOM_GROUPS
        )}
        onClickArrow={() => onClickAccordionArrow(GROUP_LISTS.CUSTOM_GROUPS)}
        showNumberOfGroups
      />
      <br />
      <CustomGroupEditorModal
        open={openCustomGroupModal}
        onClose={() => setOpenCustomGroupModal(false)}
        schoolId={student.school?.id}
        students={[student]}
      />
    </>
  );
};

StudentClassListWrapper.propTypes = {
  studentId: PropTypes.string,
};

export default StudentClassListWrapper;
