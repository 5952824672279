import React, { useState } from "react";
import Avatar from "sharedComponents/common/users/avatar";
import { List, Spacing } from "ui-kit";
import { StudentListView } from "types/users/userListViews.types";


interface IStudentListPickerProps {
  students: StudentListView[];
  listTitle?: string;
  onSelect: (student: StudentListView) => void;
}


const StudentListPicker: React.FC<IStudentListPickerProps> = ({
  students,
  listTitle,
  onSelect
}) => {

  const [selectedStudent, setSelectedStudent] = useState<StudentListView>();

  const handleClick = (student: StudentListView) => {
    setSelectedStudent(student);
    onSelect?.(student);
  }

  return (
    <List 
      title={listTitle}
      style={{ marginBottom: Spacing.Large }}
    >
      { students.map((student, index) => (
        <>
          <List.Item
            key={index}
            value={student}
            left={<Avatar user={student} />}
            onClick={handleClick}
            selected={student.id === selectedStudent?.id}
          />
        </>
      ))}
    </List>
  );
};

export default StudentListPicker;
