import React from "react";
import { ReactComponent as DogSvg } from "assets/primary/characters/dog.svg";
import { IAnimalIconProps } from "./primaryCharacter";


export const Dog: React.FC<IAnimalIconProps> = ({ className }) => {

  return (
    <DogSvg 
      //alt={alt} 
      className={className} 
    />
  )
};

export default Dog;
