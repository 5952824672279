import action from "actions/action";
import { ToastService } from "ui-kit";
import actionTypeBuilder from "actions/actionTypeBuilder";
import store from "reducers/store";
import client from "services/client";
import { Constants } from "configuration";
import FileDownload from "js-file-download";
import { GroupType } from "types/planner/groups.types";
import { BeehiveReport, IBeehiveAction, callbackType } from "types/common/action.types";
import { ClassGroupStaffView, GroupListView } from "types/users/userGroups.types";
import { SchoolPeriodListView } from "types/planner/timetable.types";
import { StudentFeedListView } from "types/planner/feedResponse.types";
import { PagedQueryView } from "types/common/views.types";
import { FeedFilter } from "types/planner/feedRequest.types";
import { ExportStudentsInfoForGroupCommand, ImportStudentCommand } from "areas/planner/types/groups/groupStudentRequest.types";
import { StudentSchemaSearchView, StudentSimsSearchView } from "areas/planner/types/groups/groupStudentResponses.types";
import { SaveFavouriteGroupCommand } from "areas/planner/types/groups/groupRequest.types";

const builder = new actionTypeBuilder("group");

const types = {
  SELECTGROUP: "selectgroup",
  GETDEFAULTLAYOUT: "getdefaultlayout",

  GETGROUP: builder.build("getgroup"),
  GETGROUPPERIODS: builder.build("getgroupperiods"),
  GETGROUPACTIVITYFEED: builder.build("getgroupactivityfeed"),

  EXPORTGROUPSTUDENTS: builder.build("exportgroupstudents"),

  SETDEFAULTLAYOUT: builder.build("setdefaultlayout"),
  SETGROUPFEEDTYPEFILTER: "setgroupfeedtypefilter",
  SETGROUPCREATEDDATEFILTER: "setgroupcreateddatefilter",
  SETGROUPACTIVETAB: "setgroupactivetab",

  FAVOURITEGROUP: builder.build("favouritegroup"),
  UNFAVOURITEGROUP: builder.build("unfavouritegroup"),

  GETBYSIMSIDS: builder.build("getbysimsids"),
  GETBYIMPORT: builder.build("getbyimport"),
};


const selectGroup = (classId: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.SELECTGROUP,
    payload: classId,
  });
};

const getDefaultLayout = (defaultLayout: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.GETDEFAULTLAYOUT,
    payload: defaultLayout,
  });
};

const getGroup = (userId: string, groupId: number, groupType: GroupType, onSuccess?: callbackType<ClassGroupStaffView>) => {
  var url: string;
  switch (groupType) {
    case GroupType.ClassGroup:
    case GroupType.Custom:
      url = `planner/staff/${userId}/classes/${groupId}`;
      break;

    case GroupType.TutorGroup:
      url = `planner/staff/${userId}/tutorGroups/${groupId}`;
      break;

    case GroupType.Provision:
      url = `planner/send/staff/${userId}/provisionGroups/${groupId}`;
      break;

    default:
      break;
  }

  return action<ClassGroupStaffView>(
    () => client.get(url), 
    types.GETGROUP, 
    onSuccess
  );
};

const getGroupPeriods = (classId: number, onSuccess?: callbackType<SchoolPeriodListView[]>) => {
  return action<SchoolPeriodListView[]>(
    () => client.get(`planner/classes/${classId}/periods`),
    types.GETGROUPPERIODS,
    onSuccess
  );
};

const getGroupActivityFeed = (
  groupId: number,
  groupType: GroupType,
  pageIndex: number,
  filter: FeedFilter,
  onSuccess?: callbackType<PagedQueryView<StudentFeedListView>>
) => {
  var url: string;

  switch (groupType) {
    case GroupType.ClassGroup:
      url = `planner/classGroups/${groupId}/feed/?PageIndex=${pageIndex}&FeedType=${filter.feedType}&CreatedDate=${filter.createdDate}`;
      break;

    case GroupType.TutorGroup:
      url = `planner/tutorGroups/${groupId}/feed/?PageIndex=${pageIndex}&FeedType=${filter.feedType}&CreatedDate=${filter.createdDate}`;
      break;

    case GroupType.Custom:
      url = `planner/customGroups/${groupId}/feed/?PageIndex=${pageIndex}&FeedType=${filter.feedType}&CreatedDate=${filter.createdDate}`;
      break;

    case GroupType.Provision:
      url = `planner/send/provisionGroups/${groupId}/feed/?PageIndex=${pageIndex}&FeedType=${filter.feedType}&CreatedDate=${filter.createdDate}`;
      break;

    default:
      break;
  }

  return action<PagedQueryView<StudentFeedListView>>(
    () => client.get(url), 
    types.GETGROUPACTIVITYFEED, 
    onSuccess
  );
};

const exportGroupStudents = (groupId: number, groupName: string, data: ExportStudentsInfoForGroupCommand, onSuccess?: callbackType<BeehiveReport>) => {
  return action<BeehiveReport>(
    () => client.post(`planner/groups/${groupId}/exportStudentsInfo`, data),
    types.EXPORTGROUPSTUDENTS,
    response => {
      onSuccess && onSuccess(response);
      FileDownload(response, `${groupName} Students.csv`);
    }
  );
};

const setDefaultLayout = (layoutId: number, onSuccess?: callbackType<number>) => {
  return action<number>(
    () => client.post(`planner/classLayouts/${layoutId}/setDefaultLayout`),
    types.SETDEFAULTLAYOUT,
    response => {
      ToastService.pop("Default layout set successfully", null, "check-circle");
      onSuccess?.(response);
    }
  );
};

const setGroupFeedTypeFilter = (filter: number) =>
  store.dispatch<IBeehiveAction<number>>({
    type: types.SETGROUPFEEDTYPEFILTER,
    payload: filter,
  });

const setGroupCreatedDateFilter = (filter: number) =>
  store.dispatch<IBeehiveAction<number>>({
    type: types.SETGROUPCREATEDDATEFILTER,
    payload: filter,
  });

const setGroupActiveTab = (activeTab: number) =>
  store.dispatch<IBeehiveAction<number>>({
    type: types.SETGROUPACTIVETAB,
    payload: activeTab,
  });

const favouriteGroup = (groupId: number, payload: SaveFavouriteGroupCommand, onSuccess?: callbackType<GroupListView>) => {
  return action<GroupListView>(
    () => client.post(`planner/groups/${groupId}/favourite`, payload),
    types.FAVOURITEGROUP,
    onSuccess
  );
};

const unfavouriteGroup = (groupId: number, payload: SaveFavouriteGroupCommand, onSuccess?: callbackType<GroupListView>) => {
  return action<GroupListView>(
    () => client.post(`planner/groups/${groupId}/unfavourite`, payload),
    types.UNFAVOURITEGROUP,
    onSuccess
  );
};

const getBySimsIds = (schoolId: number, payload: string[], onSuccess?: callbackType<StudentSimsSearchView>) => {
  return action<StudentSimsSearchView>(
    () => client.post(`planner/school/${schoolId}/students/getBySims`, payload),
    types.GETBYSIMSIDS,
    onSuccess
  );
};

const getByImport = (schoolId: number, payload: ImportStudentCommand[], onSuccess?: callbackType<StudentSchemaSearchView>) => {
  return action<StudentSchemaSearchView>(
    () => client.post(`planner/school/${schoolId}/students/getByImport`, payload),
    types.GETBYIMPORT,
    onSuccess
  );
};


const groupActions = {
  types,
  selectGroup,
  getDefaultLayout,
  getGroup,
  getGroupPeriods,
  getGroupActivityFeed,
  exportGroupStudents,
  setDefaultLayout,
  setGroupFeedTypeFilter,
  setGroupCreatedDateFilter,
  setGroupActiveTab,
  favouriteGroup,
  unfavouriteGroup,
  getBySimsIds,
  getByImport
};

export default groupActions;
