import { IBeehiveAction } from "types/common/action.types";
import newsActions from "../../actions/newsActions";
import { ArticleCategoryListView } from "areas/news/types/newsResponse.types";

interface IArticleCategoriesState {
  loading: boolean;
  error: string | null;
  categories: ArticleCategoryListView[];
}

const INITIAL_STATE: IArticleCategoriesState = {
  loading: false,
  error: null,
  categories: [],
};

const articleCategoriesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IArticleCategoriesState => {

  const { GET_ARTICLECATEGORIES } = newsActions.types;

  switch (action.type) {
    case GET_ARTICLECATEGORIES.START:
      return { ...state, loading: true, error: null };

    case GET_ARTICLECATEGORIES.SUCCESS:
      var newState = { ...state, categories: action.payload, loading: false };
      return newState;

    case GET_ARTICLECATEGORIES.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default articleCategoriesReducer;
