import React, { useState, useEffect } from "react";
import StudentDetails from "./studentDetails";
import ParentDetails from "./parentDetails";
import { Card, StructuredList, Button, Size, Spacing, Text } from "ui-kit";
import styled from "styled-components";
import EditAnimalAvatarWrapper from "./editAnimalAvatarWrapper";
import StudentStats from "../studentStats";

const ParentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ButtonWrapper = styled.span`
  .button {
    padding: ${Spacing.Small + 4}px ${Spacing.Medium}px;
    margin-left: ${Spacing.Medium}px;
  }
`;

const Details = ({ student }) => {
  // const { timetable } = useSelector(state => state.timetable);

  const [open, setOpen] = useState(false);
  const [primaryAvatarName, setPrimaryAvatarName] = useState(
    student.primaryAvatarName
  );

  useEffect(() => {
    if (primaryAvatarName !== student.primaryAvatarName) {
      setPrimaryAvatarName(student.primaryAvatarName);
    }
  }, [student]);

  return (
    <>
      <StudentStats student={student} background />

      <Card title="Student Details">
        <Card.Body>
          <StudentDetails student={student} />
        </Card.Body>
      </Card>

      <Card title="Reading Age">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="NGRT Reading Age">
              <Text>
                {student.readingAge
                  ? student.readingAge
                  : "Reading age has not been recorded"}
              </Text>
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      {student.school?.isPrimary ? (
        <Card title="Profile">
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="Animal Avatar">
                <Text>
                  {student.primaryAvatarName}
                  <ButtonWrapper>
                    <Button
                      text="Change"
                      size={Size.Small}
                      onClick={() => setOpen(true)}
                    />
                  </ButtonWrapper>
                </Text>
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      ) : null}

      {student.parents.length > 0 && (
        <Card title="Parents Details">
          <Card.Body>
            <ParentWrapper>
              {student.parents.map((parent, index) => (
                <ParentDetails key={index} parent={parent} />
              ))}
            </ParentWrapper>
          </Card.Body>
        </Card>
      )}

      <EditAnimalAvatarWrapper
        student={student}
        open={open}
        setOpen={open => setOpen(open)}
      />
    </>
  );
};

export default Details;
