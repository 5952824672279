import printCreditFilterActions from "areas/payments/actions/printCreditFilterActions";
import { IBeehiveAction } from "types/common/action.types";


interface IPrintCreditFilterState {
  loading: boolean;
  error: string | null,
  filter: {
    status: number;
    schoolId: number;
    searchTerm: string;
  }
}

const DEFAULT_STATUS: number = 2;

const INITIAL_STATE: IPrintCreditFilterState = {
  loading: false,
  error: null,
  filter: {
    status: DEFAULT_STATUS,
    schoolId: -1,
    searchTerm: ""
  }
};

const printCreditFilterReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IPrintCreditFilterState => {

  const {
    PRINTCREDITS_FILTERBYSTATUS,
    PRINTCREDITS_FILTERBYSCHOOL,
    PRINTCREDITS_FILTERBYSEARCHTERM
  } = printCreditFilterActions.types;

  switch (action.type) {
    case PRINTCREDITS_FILTERBYSTATUS:
      return {
        ...state,
        filter: { 
          ...state.filter, 
          status: action.payload 
        }
      };

    case PRINTCREDITS_FILTERBYSCHOOL:
      return {
        ...state,
        filter: {
          ...state.filter,
          schoolId: action.payload,
          status: DEFAULT_STATUS
        }
      };

    case PRINTCREDITS_FILTERBYSEARCHTERM:
      return {
        ...state,
        filter: { 
          ...state.filter, 
          searchTerm: action.payload 
        }
      };

    default:
      return state;
  }
};

export default printCreditFilterReducer;
