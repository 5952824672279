import { IBeehiveError } from "types/common/errors.types";
import assignmentAnalyticsActions from "../actions/assignmentAnalyticsActions";
import { IBeehiveAction } from "types/common/action.types";
import { AssignmentReportView } from "../types/assignmentAnalyticsResponse.types";


interface IAssignmentAnalyticsState {
  report: AssignmentReportView[];
  refresh: boolean;
  loading: boolean;
  loadingExportReport: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IAssignmentAnalyticsState = {
  report: null,
  refresh: false,
  loading: false,
  loadingExportReport: false,
  error: null,
};


const assignmentAnalyticsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAssignmentAnalyticsState => {

  const { GETASSIGNMENTREPORT, EXPORTASSIGNMENTREPORT } =
  assignmentAnalyticsActions.types;

  switch (action.type) {
    case GETASSIGNMENTREPORT.START:
      return { ...state, loading: true, error: null };

    case GETASSIGNMENTREPORT.SUCCESS:
      return { ...state, report: action.payload, loading: false };

    case GETASSIGNMENTREPORT.FAILED:
      return { ...state, loading: false, error: action.payload };

    case EXPORTASSIGNMENTREPORT.START:
      return { ...state, loadingExportReport: true, error: null };

    case EXPORTASSIGNMENTREPORT.SUCCESS:
      return { ...state, loadingExportReport: false };

    case EXPORTASSIGNMENTREPORT.FAILED:
      return { ...state, loadingExportReport: false, error: action.payload };

    default:
      return state;
  }
};

export default assignmentAnalyticsReducer;
