import React from "react";
import {
  StructuredList,
  Card,
  Dropdown,
  ActionBar,
  Button,
  Swatches,
  Size,
} from "ui-kit";
import DateRange from "../dateRange";
import MultiSchoolUserView from "sharedComponents/common/users/multiSchoolUserView";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import { SchoolSmartTaskListView } from "areas/behaviour/types/smartTasks/smartTaskResponse.types";
import moment from "moment";
import { SmartTaskFilterExt } from "./smartTaskReportWrapper";


interface ISmartTaskReportFormProps {
  schoolId: number;
  onSelectSchool: (value: number) => void;
  smartTasks: SchoolSmartTaskListView[];
  loadingSmartTasks: boolean;
  reportCriteria: SmartTaskFilterExt;
  onChangeReportCriteria: (criteria: SmartTaskFilterExt) => void
  handleGenerateReport: (exportReport: boolean) => void;
  loadingExportReport: boolean;
}


const SmartTaskReportForm: React.FC<ISmartTaskReportFormProps> = ({
  schoolId,
  onSelectSchool,
  smartTasks,
  loadingSmartTasks,
  reportCriteria,
  onChangeReportCriteria,
  handleGenerateReport,
  loadingExportReport,
}) => {

  return (
    <>
      <MultiSchoolUserView>
        <Card title="School">
          <Card.Body>
            <SchoolDropdown
              onChange={onSelectSchool}
              initialValue={schoolId}
              fluid
            />
          </Card.Body>
        </Card>
      </MultiSchoolUserView>

      <Card title="Date Range">
        <Card.Body>
          <DateRange
            startDate={reportCriteria.startDate}
            handleStartDateChange={value =>
              onChangeReportCriteria({
                ...reportCriteria,
                startDate: value,
              })
            }
            endDate={reportCriteria.endDate}
            handleEndDateChange={value =>
              onChangeReportCriteria({ ...reportCriteria, endDate: value })
            }
            required
          />
        </Card.Body>
      </Card>

      <Card title="Filters">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Smart Task">
              <Dropdown
                items={smartTasks?.map((smartTask, index) => ({
                  key: smartTask.id,
                  label: smartTask.name,
                  value: smartTask.id,
                }))}
                onChange={value =>
                  onChangeReportCriteria({
                    ...reportCriteria,
                    smartTaskId: value,
                  })
                }
                value={reportCriteria.smartTaskId}
                loading={loadingSmartTasks}
                fluid
                clearable
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      <ActionBar>
        <Button
          text="Generate Report"
          color={Swatches.Success}
          size={Size.Small}
          disabled={
            reportCriteria.startDate === null ||
            reportCriteria.endDate === null
          }
          onClick={() => handleGenerateReport(false)}
        />
        <Button
          color={Swatches.Success}
          text="Export Report as CSV"
          icon="file-download"
          size={Size.Small}
          disabled={
            reportCriteria.startDate === null ||
            reportCriteria.endDate === null
          }
          onClick={() => handleGenerateReport(true)}
          working={loadingExportReport}
        />
      </ActionBar>
    </>
  );
};

export default SmartTaskReportForm;
