import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Size,
  Swatches,
  ActionBar,
  Card,
  StructuredList,
  Message,
  DatePicker,
  ValidationMessage,
  ToastService,
} from "ui-kit";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import bulletinActions from "areas/news/actions/bulletinActions";
import { RootState } from "reducers/store";
import { BulletinFullView } from "areas/news/types/newsResponse.types";

interface IBulletinSendModalProps {
  bulletin: BulletinFullView;
  open: boolean;
  onClose?: () => void;
  onSent?: () => void;
}

const BulletinSendModal: React.FC<IBulletinSendModalProps> = ({ bulletin, open, onClose, onSent }) => {

  const { actions, testRecipients } = useSelector((state: RootState) => state.bulletin);

  const [_open, _setOpen] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [sendAfter, setSendAfter] = useState<Date>(new Date());

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const handleSend = () => {
    var errors = [];

    if (!sendAfter) {
      errors.push(`Please choose a date to send this bulletin.`);
    }

    if (
      !window.confirm(
        `This will send the bulletin to live recipients. This cannot be undone. Are you sure?`
      )
    ) {
      return;
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    bulletinActions.sendBulletin(
      { id: bulletin.id, sendAfter: sendAfter },
      () => {
        onSent?.();
        ToastService.pop("Bulletin Sent", null, "megaphone");
      }
    );
  };

  return (
    <Modal
      open={_open}
      onClose={onClose}
      title="Send Bulletin"
      width="80%"
      height="40%"
    >
      <Modal.Body>
        <Message text={actions.send.error} color={Swatches.Danger} />
        <ValidationMessage errors={validationErrors} />

        <Card grow>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item
                name="Send After"
                required
                description="This the earliest date that the bulletin will be sent after. Please note that to avoid heavy server load, bulletin sending is sent as low priority."
              >
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  placeholder="Pick a send date..."
                  closeOnSelect
                  selectedDate={sendAfter}
                  onChange={value => {
                    setSendAfter(value);
                  }}
                />
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Button
            text="Send"
            onClick={handleSend}
            color={Swatches.Success}
            size={Size.Small}
            working={actions.send.working}
          />
          <Button
            text="Close"
            onClick={onClose}
            color={Swatches.Low}
            size={Size.Small}
            working={actions.send.working}
          />
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default BulletinSendModal;
