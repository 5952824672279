import defaults from "configuration/defaults";
import { arrays } from "utils";
import filingActions from "../actions/filingActions";
import formSubmissionActions from "../../forms/actions/formSubmissionActions";
import { IBeehiveAction } from "types/common/action.types";
import { PageInfo } from "types/common/paging.types";
import { FilingFilter } from "../types/fileRequest.types";
import { TrayListView } from "../types/fileResponse.types";
import { FormCategoryListView, FormListView } from "areas/forms/types/formResponse.types";
import submissionActions from "areas/forms/actions/submissionActions";

export interface IOutTrayFilter extends Omit<FilingFilter, "category" | "formType"> {
  category?: FormCategoryListView | null;
  formType?: FormListView | null;
}

interface IOutTrayState {
  items: TrayListView[];
  loading: boolean;
  error: string | null;
  paging: PageInfo;
  filter: IOutTrayFilter;
}

const INITIAL_STATE: IOutTrayState = {
  items: [],
  loading: false,
  error: null,
  paging: defaults.DEFAULT_PAGING,
  filter: {
    schoolId: -1,
    type: null,
    leaveRequestType: null,
    formType: null,
    category: null,
    status: 1,
  },
};


const outTrayReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IOutTrayState => {

  const { OUTTRAY_GET, OUTTRAY_FILTER } = filingActions.types;
  const { SUBMIT } = formSubmissionActions.types;
  const { DELETE } = submissionActions.types

  switch (action.type) {
    case OUTTRAY_FILTER:
      return { ...state, filter: action.payload };

    case OUTTRAY_GET.START:
      return { ...state, loading: arrays.isEmpty(state.items), error: null };

    case OUTTRAY_GET.SUCCESS:
      var newState = {
        ...state,
        items:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.items, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };
      return newState;

    case OUTTRAY_GET.FAILED:
      return { ...state, loading: false, error: action.payload };

    case SUBMIT.SUCCESS:
      var existing = state.items.some(
        x => x.id === action.payload.id && x.type === action.payload.type
      );

      return {
        ...state,
        items: existing
          ? state.items.map(x =>
              x.id === action.payload.id && x.type === action.payload.type
                ? action.payload
                : x
            )
          : [action.payload, ...state.items],
      };

    case DELETE.START:
      return { ...state, loading: true };
    case DELETE.SUCCESS:
      return { 
        ...state, 
        loading: false,
        items: state.items.filter(item => item.id !== action.payload)
      };
    case DELETE.FAILED:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default outTrayReducer;
