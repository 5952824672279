import paymentActions from "areas/payments/actions/paymentsActions";
import { IncomingTransactionListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface IIncomingTransactionsState {
  loading: boolean;
  error: string | null;
  transactions: IncomingTransactionListView[];
}

const INITIAL_STATE: IIncomingTransactionsState = {
  loading: false,
  error: null,
  transactions: [],
};

const incomingTransactionsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IIncomingTransactionsState => {

  const {
    PAYMENTS_GETINCOMINGTRANSACTIONS,
    PAYMENTS_GETINCOMINGTRANSACTIONSBYDATE,
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETINCOMINGTRANSACTIONS.START:
    case PAYMENTS_GETINCOMINGTRANSACTIONSBYDATE.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETINCOMINGTRANSACTIONS.SUCCESS:
    case PAYMENTS_GETINCOMINGTRANSACTIONSBYDATE.SUCCESS:
      return { 
        ...state, 
        transactions: action.payload, 
        loading: false 
      };

    case PAYMENTS_GETINCOMINGTRANSACTIONS.FAILED:
    case PAYMENTS_GETINCOMINGTRANSACTIONSBYDATE.FAILED:
      return {
        ...state,
        loading: false,
        error: "There was a problem loading the transactions.",
      };

    default:
      return state;
  }
};

export default incomingTransactionsReducer;
