import React, { useEffect, useState } from "react";
import {
  Title,
  TitleSize,
  EmptyMessage,
  Loader,
  Swatches,
  Chip,
  SplitButton,
  Size,
  Table,
  Dropdown,
  HeadlineStatistic,
  Currency,
  ActionBar,
  ChipList,
  Subtitle,
  neutral,
  Label,
  Spacing,
  Left,
  Right,
} from "ui-kit";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Subheader from "sharedComponents/layout/header/subheader";
import { UserList } from "sharedComponents/common";
import { arrays, strings } from "utils";
import ProvisionGroupStatus from "./provisionGroupStatus";
import ProvisionGroupEditModal from "./provisionGroupEditModal";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ProvisionRecordSpreadsheet from "./provisionRecordSpreadsheet";
import { getHalfTerm } from "areas/send/utils";
import provisionGroupsActions from "areas/send/actions/provisions/provisionGroupsActions";
import { groupRoutes } from "../../../../../planner/plannerRoutes";
import { GroupType } from "../../../../../../types/planner/groups.types";
import { sendRoutes } from "../../../../sendRoutes";
import { RootState } from "reducers/store";
import { ProvisionRecordFilter } from "areas/send/types/passportRequest.types";
import { ProvisionGroupDetailView } from "areas/send/types/passportResponse.types";
import Moment from "react-moment";

const Wrapper = styled.div`
  padding-bottom: ${Spacing.Medium}px;
  .subheader {
    padding-bottom: ${Spacing.Medium}px;
    margin-bottom: ${Spacing.Medium}px;
    border-bottom: 1px solid ${neutral[300]};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  #sen-group-title {
    margin-right: ${Spacing.Medium}px;
    font-size: 20px;
    display: flex;
    flex-flow: column nowrap;
  }
`;


const ProvisionGroup = () => {

  const navigate = useNavigate();
  const { providerId, provisionId, provisionGroupId } = useParams();
  const { loading, error, group, records, recordsError, loadingRecords } = useSelector((state: RootState) => state.provisionGroup);
  const [_filter, _setFilter] = useState<ProvisionRecordFilter>({ isCurrent: -1, rating: null });
  const [_group, _setGroup] = useState<ProvisionGroupDetailView | null>(null); // This is needed to avoid infinite loop on the edit modal.

  const getRecords = (filter: ProvisionRecordFilter) => {
    provisionGroupsActions.getProvisionRecords(
      parseInt(providerId),
      parseInt(provisionId),
      parseInt(provisionGroupId),
      filter
    );
  };

  useEffect(() => {
    provisionGroupsActions.getProvisionGroup(
      parseInt(providerId),
      parseInt(provisionId),
      parseInt(provisionGroupId),
      () => {
        getRecords(_filter);
      }
    );
  }, []);

  const filterRecords = (filter: ProvisionRecordFilter) => {
    _setFilter(filter);
    getRecords({
      ...filter,
      rating: filter.rating === null ? null : filter.rating,
    });
  };

  const editProvisionGroup = () => {
    _setGroup(group);
  };

  const goToPlannerPage = () => {
    navigate(`${groupRoutes.getGroupPath(group.id, GroupType.Provision)}`);
  };

  const goToProviderPage = () => {
    navigate(sendRoutes.getProviderPath(group.provision.provider.id));
  };

  const handleDelete = () => {
    if (
      window.confirm(
        "This will delete this provision group. This cannot be undone. Are you sure?"
      )
    ) {
      provisionGroupsActions.deleteProvisionGroup(
        parseInt(providerId),
        parseInt(provisionId),
        parseInt(provisionGroupId),
        () => {
          navigate(
            sendRoutes.getProvisionGroupPath(
              parseInt(providerId),
              parseInt(provisionId),
              parseInt(provisionGroupId)
            )
          );
        }
      );
    }
  };

  if (loading && !group) {
    // The && !group clause is to stop the loader showing when the modal re-loads the group in.
    return <Loader cover size={Size.Medium} />;
  }

  if (error) {
    return <EmptyMessage icon="times-circle" title="An error occurred" cover />;
  }

  if (!loading && !error && !group) {
    <EmptyMessage
      icon="hand-holding-seedling"
      title="Provision Group Not Found"
      cover
    />;
  }

  const getCostPerPupil = () => {
    return group && records.length > 0 ? group.totalCost / records.length : 0.0;
  };

  return (
    <Wrapper>
      <Subheader>
        <Title
          size={TitleSize.H2}
          text="SEND"
          sub={
            <TitleWrapper>
              <div id="sen-group-title">
                {group?.name}
                <div>
                  <Moment format="DD/MM/YY" date={group?.startDate} /> - <Moment format="DD/MM/YY" date={group?.endDate} />
                </div>
              </div>
              <ProvisionGroupStatus status={group?.status} />
            </TitleWrapper>
          }
          right={
            <SplitButton
              size={Size.Small}
              onDefaultClick={editProvisionGroup}
              text="Edit Provision Group"
              color={Swatches.Primary}
            >
              <SplitButton.Option
                onClick={editProvisionGroup}
                text="Edit Provision Group"
                show
              />
              <SplitButton.Option
                onClick={goToPlannerPage}
                text="Go To Planner View"
                show
              />
              <SplitButton.Option
                onClick={handleDelete}
                text="Delete"
                show
                color={Swatches.Danger}
              />
            </SplitButton>
          }
        />

        <ChipList>
          <Chip
            text={group?.provision?.provider?.name}
            colorSwatch={Swatches.Miami}
            onClick={() => goToProviderPage()}
          />
          <Chip text={group?.code} />
          {group?.yearGroup && <Chip text={group?.yearGroup?.name} />}
          {group?.subject && <Chip text={group?.subject?.name} />}
        </ChipList>
      </Subheader>

      <Subtitle>Student Progress</Subtitle>
      <ActionBar>
        <Left>
          <ActionBar.Filter>
            <ActionBar.FilterItem>
              <Dropdown
                value={_filter.isCurrent}
                onChange={value =>
                  filterRecords({ ..._filter, isCurrent: value })
                }
              >
                <Dropdown.Item value={-1} label="All Students" />
                <Dropdown.Item value={1} label="Active Students" />
                <Dropdown.Item value={0} label="Inactive Students" />
              </Dropdown>
            </ActionBar.FilterItem>
            <ActionBar.FilterItem>
              <Dropdown
                value={_filter.rating}
                onChange={value => filterRecords({ ..._filter, rating: value })}
              >
                <Dropdown.Item value={-1} label="All Progress" />
                <Dropdown.Item value={0} label="0 : Expected" />
                <Dropdown.Item value={1} label="+1 : Above" />
                <Dropdown.Item value={2} label="-1 : Below" />
              </Dropdown>
            </ActionBar.FilterItem>
          </ActionBar.Filter>
        </Left>
        <Right>
          {/* <Button
            text="Add a Student"
            onClick={handleAddRecord}
            size={Size.Small}
            color={Swatches.Primary}
          /> */}
        </Right>
      </ActionBar>

      {/* <ProvisionRecordTable
        records={records}
        provisionGroup={group}
        loading={loadingRecords}
        error={recordsError}
        editable
      /> */}

      <ProvisionRecordSpreadsheet
        records={records}
        provisionGroup={group}
        loading={loadingRecords}
        error={recordsError}
        editable
      />

      {/* <ProvisionRecordsWrapper
        title="Records"
        provisionGroup={group}
        records={records}
        loading={loadingRecords}
        error={recordsError}
        onEdit={handleEditRecord}
        onAdd={handleAddRecord}
      /> */}

      {!arrays.isEmpty(group?.costs) && (
        <>
          <Subtitle>Sessions & Costs</Subtitle>
          <HeadlineStatistic>
            <HeadlineStatistic.Item
              icon="hand-holding-seedling"
              label="Session Length"
              value={`${group.sessionLength} ${strings.pluralize(
                "Hour",
                "Hours",
                group.sessionLength
              )}`}
            />
            <HeadlineStatistic.Item
              icon="hand-holding-seedling"
              label="Total Sessions"
              value={group.totalSessions}
            />
            <HeadlineStatistic.Item
              icon="hand-holding-seedling"
              label="Frequency"
              value={`${group.sessionsPerFrequency} / ${group.frequencyName}`}
            />
            <HeadlineStatistic.Item
              icon="hand-holding-seedling"
              label="Cost per Student"
              value={<Currency value={getCostPerPupil()} />}
            />
            <HeadlineStatistic.Item
              icon="hand-holding-seedling"
              label="Session Cost"
              value={<Currency value={group.sessionCost} />}
            />
            <HeadlineStatistic.Item
              icon="hand-holding-seedling"
              label="Total Cost"
              value={<Currency value={group.totalCost} />}
            />
          </HeadlineStatistic>
          <Table>
            <Table.Header>
              <Table.HeaderCell width={7}>Band</Table.HeaderCell>
              <Table.HeaderCell width={1} right>
                Band Cost
              </Table.HeaderCell>
              <Table.HeaderCell width={1} right>
                Weighting
              </Table.HeaderCell>
              <Table.HeaderCell width={1} right>
                Total
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {group.costs.map((cost, index) => (
                <Table.Row key={index}>
                  <Table.Cell width={7}>
                    <b>{cost.name}</b>
                  </Table.Cell>
                  <Table.Cell width={1} right>
                    <Currency value={cost.cost} />
                  </Table.Cell>
                  <Table.Cell width={1} right>
                    <Label>{cost.weighting}</Label>
                  </Table.Cell>
                  <Table.Cell width={1} right>
                    <b>
                      <Currency value={cost.totalCost} />
                    </b>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      )}

      {!arrays.isEmpty(group?.costs) && (
        <>
          <Subtitle>Responsible Staff</Subtitle>
          <UserList users={group?.responsibleStaff} />
        </>
      )}

      <ProvisionGroupEditModal
        group={_group}
        provision={_group?.provision}
        open={_group != null}
        onSave={() => _setGroup(null)}
        onCancel={() => _setGroup(null)}
      />
    </Wrapper>
  );
};

export default ProvisionGroup;
