import React, { useState } from "react";
import flyoutActions from "actions/ui/flyouts";
import styled from "styled-components";
import {
  Flyout,
  ActionBar,
  Button,
  Swatches,
  Spacing,
  Message,
  TextInputType,
  ToastService,
  Card,
  Sub,
  RadioList,
  StructuredList,
  HeadlineStatistic,
  DatePicker,
  Checkbox,
  Currency,
  TextInput,
  Orientation,
  TimePicker,
  ValidationMessage,
} from "ui-kit";
import { useAction } from "hooks";
import { Constants } from "configuration";
import { arrays } from "utils";
import paymentsActions from "areas/payments/actions/paymentsActions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "reducers/store";
import { PaymentUser } from "areas/payments/reducers/payments/paymentsUserReducer";
import { BasketDetailView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


const DateWrapper = styled.div`
  .time-picker {
    margin-left: ${Spacing.Medium}px;
  }
`;


interface IUserOrderFlyoutProps {
  user: PaymentUser;
  basket: BasketDetailView;
  onOrderPlaced?: () => void;
}


const UserOrderFlyout: React.FC<IUserOrderFlyoutProps> = ({ user, basket, onOrderPlaced }) => {

  const { errors, working } = useSelector((state: RootState) => state.userBasket);
  const navigate = useNavigate();

  const [method, setMethod] = useState<number>(Constants.PAYMENT_METHODS.TERMINAL.value);
  const [reference, setReference] = useState<string>("");
  const [providerTransactionId, setProviderTransactionId] = useState<string>("");
  const [date, setDate] = useState<Date>(new Date());
  const [time, setTime] = useState<string>("12:00");
  const [_notes, _setNotes] = useState<string>(null);
  const [useCustomDate, setUseCustomDate] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);


  const handlePlaceOrder = () => {
    const errors = [];

    if (
      method === Constants.PAYMENT_METHODS.STRIPE.value &&
      !providerTransactionId.length
    ) {
      errors.push(`Please enter the transaction ID.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    if (
      window.confirm(
        "This will place an order for this basket using the selected payment method. Are you sure?"
      )
    ) {
      // Set the time
      let [hours, minutes] = time.split(":");

      date.setMinutes(parseInt(minutes));
      date.setHours(parseInt(hours));

      paymentsActions.placeOrder(
        user.id,
        method,
        reference,
        providerTransactionId,
        useCustomDate ? date : new Date(),
        _notes,
        () => {
          flyoutActions.closeFlyout();
          ToastService.pop("Order Placed", null, "shopping-bag");
          onOrderPlaced?.();
          navigate(`/main/shopkeeper/users/${user.id}?tab=3&action=${basket?.id}`);
        }
      );
    }
  };

  const handleClose = () => {
    flyoutActions.closeFlyout();
  };

  return (
    <Flyout title="Place Order" name={Constants.FLYOUTS.BASKETORDER} wide>
      <Flyout.Body>
        <HeadlineStatistic>
          <HeadlineStatistic.Item
            icon="shopping-bag"
            value={<Currency value={basket?.total} />}
            label="Order Total"
          />
        </HeadlineStatistic>
        <ValidationMessage errors={validationErrors} />
        <Message text={errors?.placeOrder} color={Swatches.Danger} />
        <Card title="Payment Details">
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="Payment Method">
                <RadioList
                  fluid
                  value={method}
                  onChange={(value) => setMethod(value as number)}
                  items={arrays
                    .configConstantsToArray(Constants.PAYMENT_METHODS)
                    .filter(
                      (x) =>
                        x.value !== Constants.PAYMENT_METHODS.CASH.value &&
                        x.value !== Constants.PAYMENT_METHODS.CARDNET.value &&
                        x.value !== Constants.PAYMENT_METHODS.CHEQUE.value &&
                        x.value !== Constants.PAYMENT_METHODS.ONLINE.value
                    )}
                  orientation={Orientation.Portrait}
                />
                <br />
                {(method === Constants.PAYMENT_METHODS.PUPILPREMIUM.value ||
                  method === Constants.PAYMENT_METHODS.SUBSIDY.value) && (
                  <Sub>
                    <b>Important: </b>
                    Only Pupil Premium payments should be recorded as the 'Pupil
                    Premium' payment type. Subsidies from other funding streams
                    should be recorded as 'Subsidy'.
                  </Sub>
                )}
              </StructuredList.Item>
              <StructuredList.Item
                name="Reference"
                description={
                  <>
                    An optional payment reference, e.g. cheque number or last
                    four card digits.{" "}
                    <b>
                      DO NOT enter notes such as 'Manual Payment' or 'Added by
                      XYZ'
                    </b>
                    .
                  </>
                }
              >
                <TextInput
                  value={reference}
                  onChange={(value) => setReference(value)}
                  max={50}
                  maxLength={50}
                  fluid
                  placeholder="e.g. cheque number or last 4 card digits"
                />
              </StructuredList.Item>
              {method === Constants.PAYMENT_METHODS.STRIPE.value && (
                <StructuredList.Item
                  name="Transaction ID"
                  description={
                    <>
                      Please enter the payment provider transaction ID. For
                      Stripe payments, this is the payment intent number, e.g.{" "}
                      <i>pi_3Mp8jfFoBbJ5QWVx1mYrJazR</i>
                    </>
                  }
                  required
                >
                  <TextInput
                    value={providerTransactionId}
                    onChange={(value) => setProviderTransactionId(value)}
                    max={50}
                    maxLength={50}
                    fluid
                    placeholder="e.g. pi_3Mp8jfFoBbJ5QWVx1mYrJazR"
                  />
                </StructuredList.Item>
              )}
              <StructuredList.Item
                name="Date"
                description="You can optionally set the date and time of the payment if this is not the current date or time"
              >
                <Checkbox
                  checked={useCustomDate}
                  text={`Set Payment Date Manually`}
                  onChange={(value) => {
                    setUseCustomDate(value.checked);
                  }}
                />
                {useCustomDate && (
                  <DateWrapper>
                    <DatePicker
                      dateFormat="DD/MM/YYYY"
                      closeOnSelect
                      selectedDate={date}
                      onChange={(value) => setDate(value)}
                    />
                    <TimePicker onChange={(value) => setTime(value)} />
                  </DateWrapper>
                )}
              </StructuredList.Item>
              <StructuredList.Item
                name="Notes"
                description="You can optionally add notes for this order"
              >
                <TextInput
                  type={TextInputType.Textarea}
                  value={_notes}
                  onChange={(value) => _setNotes(value)}
                  fluid
                  rows={3}
                />
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      </Flyout.Body>
      <Flyout.Footer>
        <ActionBar low>
          <Button
            text="Place Order"
            onClick={handlePlaceOrder}
            color={Swatches.Success}
            fluid
            working={working}
          />
        </ActionBar>
      </Flyout.Footer>
    </Flyout>
  );
};

export default UserOrderFlyout;
