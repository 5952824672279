import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Loader, Size, Message, Swatches } from "ui-kit";
import { getDateTime, formatDate } from "utils/dateTime";
import { DETENTION_STATUS_KEYS } from "areas/behaviour/constants/detentions";
import { behaviourValidation } from "areas/behaviour/utils/behaviours";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import RelatedBehaviourDetails from "../relatedBehaviourDetails";
import EditBehaviour from "./editBehaviour";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { StudentFeedListView } from "types/planner/feedResponse.types";
import { useAppSelector } from "reducers/hooks";
import { BehaviourView } from "areas/behaviour/types/behaviourResponses.types";
import { UpdateBehaviourCommand, UpdateDetention } from "areas/behaviour/types/behaviourRequest.types";
import { IBehaviourDetails, IDetentionDetails, IIncidentDetails } from "areas/behaviour/utils/detentions";


interface IEditBehaviourWrapperProps {
  activity: StudentFeedListView;
  handleCloseModal: () => void;
}


const EditBehaviourWrapper: React.FC<IEditBehaviourWrapperProps> = ({ activity, handleCloseModal }) => {

  const {
    behaviour,
    refresh,
    loading,
    loadingRevokeBehaviour,
    getBehaviourError,
    updateBehaviourError,
  } = useAppSelector(state => state.behaviour);
  const { schoolInformation } = useAppSelector(state => state.school);
  const { detentionSummary } = useAppSelector(state => state.detentionSummary);
  const {
    attendedDetentionError,
    missedDetentionError,
    cancelDetentionError,
    rescheduleDetentionError,
    loading: loadingDetentionChange,
  } = useAppSelector(state => state.detention);

  const [selectedBehaviour, setSelectedBehaviour] = useState<BehaviourView>(null);

  useEffect(() => {
    behaviourActions.getBehaviour(
      activity.students[0].id,
      activity.itemId,
      null
    );
  }, [activity]);

  useEffect(() => {
    if (behaviour) {
      setSelectedBehaviour(behaviour.behaviour);
    }
  }, [behaviour]);

  useEffect(() => {
    if (refresh) {
      behaviourActions.getBehaviour(
        activity.students[0].id,
        activity.itemId,
        null
      );
    }
  }, [refresh]);

  const checkValidations = (incidentDetails: IIncidentDetails, detentionDetails: IDetentionDetails) : string[] => {
    // only validating if detention is issued because attended/missed/cancelled detentions can't be amended
    let errors = behaviourValidation(
      incidentDetails,
      selectedBehaviour.detentions &&
        selectedBehaviour.detentions.find(x => x.detentionId == detentionDetails.id)?.statusId === DETENTION_STATUS_KEYS.ISSUED
        ? detentionDetails
        : null,
      detentionSummary,
      schoolInformation
    );
    return errors;
  };

  const handleUpdateBehaviour = (behaviourDetails: IBehaviourDetails) => {
    const incidentDetails = behaviourDetails.incidentDetails;
    const incidentDateTime = getDateTime(
      formatDate(incidentDetails.incidentDate),
      incidentDetails.incidentTime
    );

    let detention: UpdateDetention = null;
    if (behaviourDetails.detentionDetails) {
      const detentionDetails = behaviourDetails.detentionDetails;
      detention = {
        id: detentionDetails.id,
        detentionDate: getDateTime(
          formatDate(detentionDetails.date),
          detentionDetails.time
        ),
        roomId: detentionDetails.detentionLocationId,
        detentionNote: detentionDetails.detentionNotes,
      };
    }

    const behaviourUpdateDetails: UpdateBehaviourCommand = {
      id: activity.itemId,
      description: behaviourDetails.internalNotes,
      publicNotes: behaviourDetails.publicNotes,
      points: behaviourDetails.points,
      incidentDate: incidentDateTime,
      outcomeId: behaviourDetails.outcome,
      initialActionId: behaviourDetails.initialAction.initialActionId,
      roomId: incidentDetails.incidentLocation,
      otherLocation: incidentDetails.otherIncidentLocation,
      periodId: incidentDetails.period,
      updateDetention: detention,
      studentId: activity.students[0].id,
    };

    behaviourActions.updateBehaviour(
      activity.students[0].id,
      activity.itemId,
      behaviourUpdateDetails,
      handleCloseModal
    );
  };

  if (loading || loadingRevokeBehaviour || loadingDetentionChange) {
    return <Loader size={Size.Medium} fluid />;
  }

  if (getBehaviourError) {
    return (
      <Message
        text={"There was a problem loading the behaviour details."}
        color={Swatches.Danger}
      />
    );
  }

  return (
    selectedBehaviour && (
      <div className="behaviour-modal">
        <>
          <ApiExceptionMessage error={updateBehaviourError} />
          <ApiExceptionMessage error={attendedDetentionError} />
          <ApiExceptionMessage error={missedDetentionError} />
          <ApiExceptionMessage error={cancelDetentionError} />
          <ApiExceptionMessage error={rescheduleDetentionError} />

          <RelatedBehaviourDetails behaviour={behaviour} />

          <EditBehaviour
            studentId={activity.students[0].id}
            handleCloseModal={handleCloseModal}
            activity={activity}
            behaviour={selectedBehaviour}
            handleUpdateBehaviour={handleUpdateBehaviour}
            isPrimary={schoolInformation?.isPrimary}
            checkValidations={checkValidations}
            schoolId={schoolInformation?.id}
          />
        </>
      </div>
    )
  );
};


export default EditBehaviourWrapper;
