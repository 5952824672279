export const CODE_SEVERITY_INDEX = {
  C1: 0,
  C2: 1,
  C3: 2
};

export const BEHAVIOUR_STATUS_KEYS = {
  ISSUED: 0,
  REVOKED: 1,
  PENDING_ATTENTION: 2,
  UPSCALED: 3
};

export const OUTCOME_KEYS = {
  RESOLVED: 0,
  UNRESOLVED: 1,
  INTERVENTION: 2,
  REVIEW_ONE_WEEK: 3,
  REVIEW_TWO_WEEK: 4,
  REVIEW_THREE_WEEK: 5,
  REVIEW_SIX_WEEK: 6
};

export interface OutcomeType {
  id: number;
  description: string;
}

export const OUTCOMES: OutcomeType[] = [
  { id: OUTCOME_KEYS.RESOLVED, description: "Resolved" },
  { id: OUTCOME_KEYS.UNRESOLVED, description: "Unresolved" },
  {
    id: OUTCOME_KEYS.INTERVENTION,
    description: "Further Intervention Required"
  },
  { id: OUTCOME_KEYS.REVIEW_ONE_WEEK, description: "Review in 1 Week" },
  { id: OUTCOME_KEYS.REVIEW_TWO_WEEK, description: "Review in 2 Weeks" },
  { id: OUTCOME_KEYS.REVIEW_THREE_WEEK, description: "Review in 3 Weeks" },
  { id: OUTCOME_KEYS.REVIEW_SIX_WEEK, description: "Review in 6 Weeks" }
];

export const BULK_BEHAVIOUR_STATUS = {
  NOT_SENT: 0,
  SENT: 1
};
