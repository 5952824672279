import paymentActions from "areas/payments/actions/paymentsActions";
import { LunchTransactionListView } from "areas/payments/types/catering/cateringResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface IUserLunchTransactionsState {
  transactions: LunchTransactionListView[],
  error: string | null;
  loading: boolean;
}

const INITIAL_STATE: IUserLunchTransactionsState = {
  transactions: [],
  error: null,
  loading: false,
};

const userLunchTransactionsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUserLunchTransactionsState => {

  const { 
    PAYMENTS_GETUSERLUNCHTRANSACTIONS
  } = paymentActions.paymentsTypes;

  const { PAYMENTS_ADDMANUALLUNCHCREDIT } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETUSERLUNCHTRANSACTIONS.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETUSERLUNCHTRANSACTIONS.SUCCESS:
      return {
        ...state,
        transactions: action.payload,
        loading: false,
      };

    case PAYMENTS_GETUSERLUNCHTRANSACTIONS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PAYMENTS_ADDMANUALLUNCHCREDIT.SUCCESS:
      return {
        ...state,
        transactions: [action.payload.adjustment, ...(state.transactions || [])]
      }

    default:
      return state;
  }
};

export default userLunchTransactionsReducer;
