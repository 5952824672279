
import declarationsOfInterestActions from "areas/forms/actions/declarations/declarationsOfInterestActions";
import { DeclarationOfInterestDetailView, DeclarationOfInterestVersionListView } from "areas/forms/types/declarations/declarationsOfInterestResponse.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";
import { DeclarationVersionStatus } from "../../types/declarations/declarationsOfInterestShared.types";

interface IDeclarationOfInterestState {
  declaration: DeclarationOfInterestDetailView;
  loading: boolean;
  error: IBeehiveError;
  saving: boolean;
  saveError: IBeehiveError;
  deleting: boolean;
  deleteError: IBeehiveError;
  submitting: boolean;
  submitError: IBeehiveError;
  cloning: boolean;
  cloneError: IBeehiveError;
}

const INITIAL_STATE: IDeclarationOfInterestState = {
  declaration: null,
  loading: false,
  error: null,
  saving: false,
  saveError: null,
  deleting: false,
  deleteError: null,
  submitting: false,
  submitError: null,
  cloning: false,
  cloneError: null
};


const declarationOfInterestReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IDeclarationOfInterestState => {

  const { 
    GET_DECLARATION, 
    SUBMIT_DECLARATION_VERSION, 
    CLONE_DECLARATION_VERSION,
    UPDATE_DECLARATION_VERSION,
    DELETE_DECLARATION_VERSION
  } = declarationsOfInterestActions.types;

  switch (action.type) {

    case GET_DECLARATION.START:
      return { 
        ...state, 
        loading: true,
        error: null
      };
    case GET_DECLARATION.SUCCESS:
      return { 
        ...state, 
        loading: false, 
        declaration: action.payload
      };
    case GET_DECLARATION.FAILED:
      return { 
        ...state, 
        loading: false, 
        error: action.payload 
      };

    case SUBMIT_DECLARATION_VERSION.START:
      return { 
        ...state, 
        submitting: true, 
        submitError: null
      };
    case SUBMIT_DECLARATION_VERSION.SUCCESS:
      return { 
        ...state, 
        submitting: false, 
        declaration: {
          ...state.declaration,
          latestVersion: {
            ...state.declaration.latestVersion,
            status: DeclarationVersionStatus.Submitted,
            statusName: "Submitted"
          }
        }
      };
    case SUBMIT_DECLARATION_VERSION.FAILED:
      return { 
        ...state, 
        submitting: false, 
        submitError: action.payload
      };

    case CLONE_DECLARATION_VERSION.START:
      return {
        ...state,
        cloning: true,
        cloneError: null
      }
    case CLONE_DECLARATION_VERSION.SUCCESS:
      return {
        ...state,
        cloning: false,
        declaration: {
          ...state.declaration,
          latestVersion: action.payload,
          versions: [
            state.declaration.latestVersion as DeclarationOfInterestVersionListView,
            ...state.declaration.versions
          ]
        }
      }
    case CLONE_DECLARATION_VERSION.FAILED:
      return {
        ...state,
        cloning: false,
        cloneError: action.payload
      }

    case UPDATE_DECLARATION_VERSION.START:
      return {
        ...state,
        saving: true,
        saveError: null
      }
    case UPDATE_DECLARATION_VERSION.SUCCESS:
      return {
        ...state,
        saving: false,
        declaration: {
          ...state.declaration,
          latestVersion: action.payload
        }
      }
    case UPDATE_DECLARATION_VERSION.FAILED:
      return{
        ...state,
        saving: false,
        saveError: action.payload
      }

      case DELETE_DECLARATION_VERSION.START:
        return {
          ...state,
          deleting: true,
        }
      case DELETE_DECLARATION_VERSION.SUCCESS:
        return {
          ...state,
          deleting: false,
          declaration: {
            ...state.declaration,
            versions: state.declaration.versions.filter(version => version.id !== action.payload)
          }
        }
      case DELETE_DECLARATION_VERSION.FAILED:
        return {
          ...state,
          deleting: false,
          deleteError: action.payload
        }
    
    default:
      return state;
  }
};

export default declarationOfInterestReducer;
