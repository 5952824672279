import React from "react";
import { Sub, Chip } from "ui-kit";
import moment from "moment";
import { CalendarEventAppListView } from "areas/calendar/types/calendarResponses.types";


interface IEventSignupInfoProps {
  event: CalendarEventAppListView
  readonly?: boolean;
}


const EventSignupInfo: React.FC<IEventSignupInfoProps> = ({ event, readonly }) => {

  if (event == null) {
    return null;
  }

  const getSignupInfo = (event: CalendarEventAppListView) => {
    if (event.signedUp && !readonly) {
      return "You have signed up";
    }
    if (event.signedUp && readonly) {
      return "Your child has signed up to this event";
    }

    if (
      event.allowSignupFrom != null &&
      moment().isBefore(event.allowSignupFrom, "day")
    ) {
      return `Sign up opens on ${moment(event.allowSignupFrom).format(
        "DD/MM/YYYY"
      )}`;
    }

    if (
      event.allowSignupTo != null &&
      moment().isAfter(event.allowSignupTo, "day")
    ) {
      return `Sign up has closed on ${moment(event.allowSignupTo).format(
        "DD/MM/YYYY"
      )}`;
    }

    // if (event.signupCount >= event.signupLimit) {
    //   return `This event is full`;
    // } else {
    //   return `${event.signupLimit - event.signupCount} of ${
    //     event.signupLimit
    //   } spaces available`;
    // }

    return "Sign up open";
  };

  return ( 
    <Sub>{getSignupInfo(event)}</Sub>
  );
};

export default EventSignupInfo;
