import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import storeActions from "areas/payments/actions/storeActions";
import flyoutActions from "actions/ui/flyouts";
import { arrays } from "utils";
import Moment from "react-moment";
import {
  Size,
  TitleSize,
  Swatches,
  Loader,
  EmptyMessage,
  Title,
  Table,
  HtmlText,
  ChipList,
  Dropdown,
  Chip,
  Spacing,
  Button,
  Label,
  Currency,
  fontStyle,
  displayFont,
  HeadlineStatistic,
  StructuredList,
  Sub,
  typescale,
  Card,
  Text,
  Subtitle,
  ValidationMessage,
  TextInput,
  TextInputType,
} from "ui-kit";
import { Constants } from "configuration";
import { RootState } from "reducers/store";
import { AddProductToBasketCommand } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";
import { BasketItemView, ProductAvailibilityView, ProductInstalmentView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { UserTinyView } from "types/users/userListViews.types";
import { GroupListView } from "types/users/userGroups.types";
import { useScroll } from "hooks/useScroll";
import moment from "moment";


const Wrapper = styled.div`
  .chip-list {
    margin-bottom: ${Spacing.Default}px;
  }

  .price {
    display: block;
    ${fontStyle(displayFont.light, typescale.header2)}
  }

  .availability {
    margin-top: -${Spacing.Large}px;
    margin-bottom: ${Spacing.Large}px;
  }
`;

const AvailableForWrapper = styled.div`
  margin-bottom: ${Spacing.Large}px;
`;

type PartPayUser = {
  recipientId: string,
  partPayValue: number
}

const Product = () => {

  let { id } = useParams();
  const { scrollToTop } = useScroll();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { product, error, loading } = useSelector((state: RootState) => state.storeProduct);
  const { basket, working: basketWorking, errors: basketErrors } = useSelector((state: RootState) => state.basket);

  const [quantity, setQuantity] = useState<number>(1);
  const [addToBasketErrors, setAddToBasketErrors] = useState<string[]>([]);
  const [partPayments, setPartPayments] = useState<PartPayUser[]>([]);

  useEffect(() => {
    basketErrors?.item
      ? setAddToBasketErrors([basketErrors?.item])
      : setAddToBasketErrors([]);
  }, [basketErrors]);

  useEffect(() => {
    user && storeActions.getProductForUser(user.id, parseInt(id));
  }, []);

  useEffect(() => {
    if (product) {
      var partPayments = product.availableFor.map(recipient => (
        {
          recipientId: recipient.student.id,
          partPayValue: recipient.outstanding || product.unitPrice
        }
      ))
      setPartPayments(partPayments);
    }
  }, [product]);

  const openBasket = () => {
    flyoutActions.openFlyout(Constants.FLYOUTS.BASKET);
  };

  const addInstalmentToBasket = (student: UserTinyView, instalment: ProductInstalmentView) => {
    var data: AddProductToBasketCommand = {
      userId: user.id,
      recipientId: student.id,
      productId: parseInt(id),
      quantity: 1,
      total: instalment.amount,
      virtualInstalmentId: instalment.virtualInstalmentId,
      orderType: instalment.installmentType,
    };

    storeActions.addProductToBasket(user.id, data, openBasket);
  };

  const getOrderType = (recipient: ProductAvailibilityView) => {
    if (!recipient.pupilPremium && !recipient.bursary) {
      return 0;
    }
    if (
      recipient.pupilPremium &&
      product.ppDiscountedPrice != null &&
      product.ppDiscountedPrice != product.unitPrice
    ) {
      return 1;
    }
    if (
      recipient.bursary &&
      product.bursaryDiscountedPrice != null &&
      product.bursaryDiscountedPrice != product.unitPrice
    ) {
      return 2;
    }
  };

  const getProductPaymentTotal = (availableFor: ProductAvailibilityView, isPartPaid: boolean, partPayUser: PartPayUser) : number => {
    if (
      product.allowPartPayment && 
      !isPartPaid && 
      partPayUser?.partPayValue >= 25 && 
      partPayUser?.partPayValue != product.unitPrice
    ) {
      return partPayUser?.partPayValue;
    }
    if (
      product.allowPartPayment && 
      isPartPaid && 
      partPayUser?.partPayValue != product.unitPrice
    ) {
      return partPayUser?.partPayValue;
    }
    if (isPartPaid) {
      return availableFor.outstanding;
    }
    if (availableFor.bursary && product.bursaryDiscountedPrice != null && product.bursaryDiscountedPrice != product.unitPrice) {
      return product.bursaryDiscountedPrice;
    }
    if (availableFor.pupilPremium && product.ppDiscountedPrice != null && product.ppDiscountedPrice != product.unitPrice) {
      return product.ppDiscountedPrice;
    }
    return product.unitPrice;
  }

  const addToBasket = (availableFor: ProductAvailibilityView) => {
    const isPartPaid = availableFor.paid > 0 && availableFor.outstanding > 0;
    const partPayUser = partPayments.find(partPayment => partPayment.recipientId === availableFor.student.id);

    var errors: string[] = [];
    if (
      product.allowPartPayment && 
      !isPartPaid && 
      partPayUser?.partPayValue < 25
     ) {
      errors.push("Cannot part pay an amount below £25");
    }
    setAddToBasketErrors(errors);
    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    var data: AddProductToBasketCommand = {
      userId: user.id,
      recipientId: availableFor.student.id,
      productId: parseInt(id),
      quantity: quantity,
      total: getProductPaymentTotal(availableFor, isPartPaid, partPayUser),
      virtualInstalmentId: null,
      orderType: getOrderType(availableFor),
    };

    storeActions.addProductToBasket(user.id, data, openBasket);
  };

  const removeFromBasket = (basketItem: BasketItemView) => {
    storeActions.removeItemFromBasket(user.id, basketItem.id, openBasket);
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem loading your products"
        cover
      />
    );
  }

  if (!product) {
    return (
      <EmptyMessage
        icon="shopping-bag"
        title="Product not found"
        summary="The requested product was not found in the store"
        cover
      />
    );
  }

  const hasPartPaid = () => {
    // if (user.userType === "Parent") {
    //   const parentChildren = user.children
    // }
    const userAvailability = product.availableFor.find(
      o => o.paid > 0 && o.outstanding > 0
    );
    return userAvailability == null;
  };

  const RelevantInstallments = ({ availableFor } : { availableFor: ProductAvailibilityView }) => {
    if (
      availableFor.bursary &&
      availableFor.instalments.some(x => x.installmentType === 2)
    ) {
      return (
        <InstalmentsList
          title={"Bursary Instalments"}
          filter={2}
          availableFor={availableFor}
        />
      );
    }
    if (
      availableFor.pupilPremium &&
      availableFor.instalments.some(x => x.installmentType === 1)
    ) {
      return (
        <InstalmentsList
          title={"Pupil Premium Instalments"}
          filter={1}
          availableFor={availableFor}
        />
      );
    }
    return (
      <InstalmentsList
        title={"Instalments"}
        filter={0}
        availableFor={availableFor}
      />
    );
  };

  const renderInstalmentButton = (student: UserTinyView, instalment: ProductInstalmentView) => {
    var basketItem = basket?.items?.find(
      item => item.virtualInstalmentId === instalment.virtualInstalmentId
    );

    return !basketItem ? (
      <Button
        size={Size.Small}
        color={Swatches.Success}
        text="Add to Basket"
        working={basketWorking}
        onClick={() => addInstalmentToBasket(student, instalment)}
      />
    ) : (
      <Button
        size={Size.Small}
        color={Swatches.Danger}
        text="Remove from Basket"
        working={basketWorking}
        onClick={() => removeFromBasket(basketItem)}
      />
    );
  };

  const InstalmentsList = ({ title, filter, availableFor } : { title: string, filter: number, availableFor: ProductAvailibilityView }) => {
    if (availableFor.instalments.some(x => x.installmentType === filter)) {
      return (
        <Card title={title}>
          <Card.Body noPad>
            <Table>
              <Table.Header>
                <Table.HeaderCell>Due Date</Table.HeaderCell>
                <Table.HeaderCell right>Total</Table.HeaderCell>
                <Table.HeaderCell right></Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {availableFor.instalments
                  .filter(x => x.installmentType == filter)
                  .sort((a, b) => moment(a.dueDate).unix() - moment(b.dueDate).unix())
                  .map((instalment: ProductInstalmentView, index: number) => (
                    <Table.Row key={index}>
                      <Table.Cell>
                        <Moment
                          date={instalment.dueDate}
                          format="dddd, MMMM Do YYYY"
                        />{" "}
                      </Table.Cell>
                      <Table.Cell right>
                        <b>
                          <Currency value={instalment.amount} />
                        </b>
                      </Table.Cell>
                      <Table.Cell right>
                        {renderInstalmentButton(
                          availableFor.student,
                          instalment
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Card.Body>
        </Card>
      );
    } else {
      return null;
    }
  };

  const renderAddButton = (availableFor: ProductAvailibilityView) => {
    var basketItem = basket?.items?.find(
      item =>
        item.productId === product.id &&
        item.recipient.id === availableFor.student.id
    );

    const isPartPaid = availableFor.paid > 0 && availableFor.outstanding > 0;

    const getQuantityOptions = (maxQuantity: number) => {
      const options = [];
      for (var i = 1; i <= maxQuantity; i++) {
        options.push(<Dropdown.Item value={i} label={i} />);
      }
      return options;
    };

    return !basketItem ? (
      <>
      { isPartPaid && (
        <Sub>Product has been partially paid. {<Currency value={availableFor.outstanding} />} Remaining.</Sub>
      )}
      <Card>
        <Card.Body>
          {product.allowMultiple == true && (
            <StructuredList>
              <StructuredList.Item name="Quantity">
                <Dropdown
                  value={quantity}
                  onChange={value => setQuantity(value)}
                >
                  {getQuantityOptions(product.maximumOrderQuantity)}
                </Dropdown>
              </StructuredList.Item>
            </StructuredList>
          )}

          <Button
            size={Size.Medium}
            color={Swatches.Success}
            text="Add to Basket"
            onClick={() => addToBasket(availableFor)}
          />
        </Card.Body>
      </Card>
      </>
    ) : (
      <Button
        size={Size.Small}
        color={Swatches.Danger}
        text="Remove from Basket"
        working={basketWorking}
        onClick={() => removeFromBasket(basketItem)}
      />
    );
  };

  const PartPayButton = ({ availableFor } : { availableFor: ProductAvailibilityView }) => {
    var basketItem = basket?.items?.find(
      item =>
        item.productId === product.id &&
        item.recipient.id === availableFor.student.id
    );
    return !basketItem ? (
      <Button
        size={Size.Medium}
        color={Swatches.Success}
        text="Add to Basket"
        onClick={() => addToBasket(availableFor)}
      />
    ) : (
      <Button
        size={Size.Small}
        color={Swatches.Danger}
        text="Remove from Basket"
        working={basketWorking}
        onClick={() => removeFromBasket(basketItem)}
      />
    )
  }

  return (
    <Wrapper>
      <ValidationMessage errors={addToBasketErrors} />
      <Title
        size={TitleSize.H2}
        text={product.name}
        sub={product.category.name}
      />

      {!arrays.isEmpty(product.groups) && (
        <ChipList>
          {product.groups.map((group: GroupListView, index: number) => (
            <Chip key={index} text={group.name} />
          ))}
        </ChipList>
      )}
      <Card>
        <Card.Body>
          <p className="price">
            <Currency value={product.unitPrice} />
          </p>

          {!product.soldOut && product.enforceStockControl && (
            <Label className="availability" bold>
              {product.stockRemaining} Available{" "}
              {product.expiryDate && `until` && (
                <Moment
                  date={product.expiryDate}
                  format="[until] dddd, MMMM Do YYYY"
                />
              )}
            </Label>
          )}
          <HtmlText html={product.description} />
        </Card.Body>
      </Card>

      {product.soldOut && hasPartPaid() ? (
        <>
          <EmptyMessage
            icon="shopping-bag"
            title="Sold Out"
            summary="Unfortunately, this item has now sold out"
            cover
          />
        </>
      ) : basket?.school != null &&
        product?.category?.school?.id !== basket?.school?.id ? (
        <Card>
          <Card.Body>
            <p>
              This product cannot be added to your basket as you have items from{" "}
              {basket.school?.name} in your basket. Unfortunately only purchases
              for a one school at a time can be made in an order.
            </p>
            <p>
              To order this item, either remove the other items from your basket
              or checkout your basket first, and then place another order for
              this item.
            </p>
          </Card.Body>
        </Card>
      ) : (
        product.availableFor.map((availableFor: ProductAvailibilityView, index: number) => (
          <AvailableForWrapper key={index}>
            {product.availableFor.length > 0 && (
              <>
                <Title
                  size={TitleSize.H3}
                  text={`${availableFor.student.firstName} ${availableFor.student.lastName}`}
                />
                {availableFor.bursary &&
                product.bursaryDiscountedPrice &&
                product.bursaryDiscountedPrice != product.unitPrice ? (
                  <Subtitle>
                    Price after bursary discount:{" "}
                    {<Currency value={product.bursaryDiscountedPrice} />}
                  </Subtitle>
                ) : (
                  availableFor.pupilPremium &&
                  product.ppDiscountedPrice &&
                  product.ppDiscountedPrice != product.unitPrice && (
                    <Subtitle>
                      Price after pupil premium discount:{" "}
                      {<Currency value={product.ppDiscountedPrice} />}
                    </Subtitle>
                  )
                )}
              </>
            )}
            {product.allowMultiple == false && (
              <HeadlineStatistic>
                <HeadlineStatistic.Item
                  label="Paid"
                  value={<Currency value={availableFor.paid} />}
                />
                <HeadlineStatistic.Item
                  label="Remaining"
                  value={<Currency value={availableFor.outstanding} />}
                />
              </HeadlineStatistic>
            )}
            {availableFor.instalments.some(x => x.installmentType === 0) 
              || (availableFor.instalments.some(x => x.installmentType === 1) && availableFor.pupilPremium && !availableFor.bursary) 
              || (availableFor.instalments.some(x => x.installmentType === 2) && availableFor.bursary) 
            ? (
              <>
              <RelevantInstallments availableFor={availableFor} />
              {product.allowPartPayment && availableFor.instalments.length > 0 && (
                <Card>
                  <Card.Body>
                    <StructuredList>
                      <StructuredList.Item 
                        name="Part Payment"
                        description={`Part payments have been enabled for this product.
                          You can pay a minimum of £25 and pay the outstanding amount at a later date.`}
                      >
                        <TextInput
                          type={TextInputType.Number}
                          value={partPayments.find(partPayment => partPayment.recipientId === availableFor.student.id)?.partPayValue || 0}
                          onChange={(value) => setPartPayments(partPayments.map(partPayment =>
                            partPayment.recipientId === availableFor.student.id
                            ? {...partPayment, partPayValue: value}
                            : partPayment
                          ))}
                          min={25}
                          max={availableFor.outstanding || product.unitPrice}
                        />
                      </StructuredList.Item>
                    </StructuredList>
                    <PartPayButton availableFor={availableFor} />
                  </Card.Body>
                </Card>
              )}
              </>
            ) 
            : product.allowMultiple == true || arrays.isEmpty(availableFor.orders) || availableFor.outstanding > 0
              ? (
                <>{renderAddButton(availableFor)}</>
              ) 
              : (
              <Card>
                <Card.Body>
                  <p>
                    This item cannot be ordered for{" "}
                    {
                      `${availableFor.student.firstName} ${availableFor.student.lastName} 
                      ${ availableFor.orders.length > 0 ? `as it has already been purchased.` : `` }`
                    }
                    .{" "}
                  </p>
                </Card.Body>
              </Card>
            )}
          </AvailableForWrapper>
        ))
      )}
    </Wrapper>
  );
};

export default Product;
