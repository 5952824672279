import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StaffSearch from "./staffSearch";
import UserList from "./userList";
import { arrays } from "utils";
import { Spacing } from "ui-kit";
import { StaffListView, UserListView, UserTinyView } from "types/users/userListViews.types";

const StaffPickerWrapper = styled.div`
  .staff-search {
    margin-bottom: ${Spacing.Default}px;
  }
`;


interface IStaffPickerProps {
  users: (StaffListView | UserListView | UserTinyView)[]; 
  onChange?: (newUsers: StaffListView[]) => void;
}

const StaffPicker: React.FC<IStaffPickerProps> = ({ users, onChange }) => {
  
  const [_users, _setUsers] = useState<StaffListView[]>([]);

  useEffect(() => {
    users && _setUsers(users as StaffListView[]);
  }, [users]);

  const handleUserSelected = (user: StaffListView) => {
    if (!_users.some(x => x.id === user.id)) {
      var newUsers: StaffListView[] = [..._users, user];
      _setUsers(newUsers);
      onChange?.(newUsers);
    }
  };

  const handleUserRemoved = (user: StaffListView, index: number) => {
    if (window.confirm("This will remove this user. Are you sure?")) {
      var newUsers = arrays.remove(_users, index);
      _setUsers(newUsers);
      onChange?.(newUsers);
    }
  };

  return (
    <StaffPickerWrapper className="staff-picker">
      <StaffSearch fluid onChange={handleUserSelected} />
      <UserList users={_users} editable onUserRemoved={handleUserRemoved} />
    </StaffPickerWrapper>
  );
};

export default StaffPicker;
