import React from "react";
import { TitleSize, Title } from "ui-kit";


const PasswordGuidance = () => {

  return (
    <>
      <Title size={TitleSize.H4} text="What is a strong password?" />
      <p>
        A strong password is one that is not easily guessable and is hard to
        crack. Using a combination of{" "}
        <b>
          upper and lowercase letters, numbers and punctuation or special
          characters
        </b>{" "}
        works best. Do not use words that can be guessed, like your pet’s name,
        your name, date of birth or any other information which can be easily
        obtained. You should also avoid using the same password on for multiple
        accounts.
      </p>
      <p>
        An example of a strong password is{" "}
        <i>
          <b>Disco21RocketParty?</b>
        </i>
        .
      </p>

      <p>
        You can change your password from any Trust PC or laptop by pressing{" "}
        <b>Ctrl+Alt+Delete</b> and then choosing <b>Change a Password</b>.
      </p>
      <Title
        size={TitleSize.H4}
        text="How do we check whether a password has been compromised?"
      />
      <p>
        Lists of passwords that are dangerous are regularly released by security
        firms. A password that appears on this list is easily guessable (e.g.{" "}
        <i>'password123'</i>) or is known to have been stolen via phishing, a
        data breach or other large-scale hacks.
      </p>

      <p>
        We simply compare your password against the list of known compromised or
        insecure passwords. The lists do not give us any information which can
        identify the user and we do not record the passwords being checked.
      </p>

      <p>
        You can read more about good password practices at{" "}
        <a href="https://www.ncsc.gov.uk/cyberaware/home" target="_blank">
          NCSC CyberAware
        </a>
        .
      </p>
    </>
  );
};

export default PasswordGuidance;
