import { AnswerType } from "areas/planner/types/quiz/quizShared.types";
import { success, warning, danger } from "ui-kit/common/colors";

export interface QuizAnswerType {
  key: AnswerType;
  answerType: string;
  icon: string;
  colour: string;
}

export const ANSWER_TYPES: QuizAnswerType[] = [
  {
    key: AnswerType.Correct,
    answerType: "Correct",
    icon: "check",
    colour: success
  },
  {
    key: AnswerType.Guess,
    answerType: "Guess",
    icon: "question",
    colour: warning
  },
  {
    key: AnswerType.Incorrect,
    answerType: "Incorrect",
    icon: "times",
    colour: danger
  }
];
