import React from "react";
import { Currency, HtmlText, Label, DateTime } from "ui-kit";
import moment from "moment";
import { FieldListView } from "../types/formResponse.types";

interface IReadOnlyFieldProps {
  field: FieldListView;
  value: number | string | Date;
}

const ReadOnlyField: React.FC<IReadOnlyFieldProps> = ({ field, value }) => {
  if (!field || !value) {
    return null;
  }

  switch (field.fieldType) {
    case 0: // String
      return <Label>{value as string}</Label>;
    case 1: // Integer
      return <Label>{value as string}</Label>;
    case 2: // Currency
      return (
        <Label>
          <Currency value={value as number} />
        </Label>
      );
    case 3:
      return <DateTime date={value as Date} hideTime />;
    case 4: // Textarea
      return <HtmlText html={value as string} />;
    case 5: // Decimal
      return <Label>{value as string}</Label>;
    case 6: // Time
      return <Label>{moment(value, "hh:mm").format("hh:mma")}</Label>;
    case 7: // Hours
      return <Label>{`${value} hours`}</Label>;
    case 8: // Minutes
      return <Label>{`${value} minutes`}</Label>;
    case 9: // Month
      return <Label>{moment(value, "M").format("MMMM")}</Label>;
    case 12: // Boolean
      return <Label>{value ? "Yes" : "No"}</Label>;
    case 13:
      return (
        <Label>
          {field?.options?.find((x) => x.id.toString() === value)?.displayName}
        </Label>
      );
    default:
      return null;
  }
};

export default ReadOnlyField;
