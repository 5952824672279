import { IBeehiveAction } from "types/common/action.types";
import formsActions from "../actions/formsActions";


interface IFormsState {
  activeTab: number;
}

const INITIAL_STATE = {
  activeTab: 0,
};


const formsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IFormsState => {
  
  const { SETTAB } = formsActions.types;

  switch (action.type) {
    case SETTAB:
      return { ...state, activeTab: action.payload };

    default:
      return state;
  }
};

export default formsReducer;
