import { IBeehiveAction } from "types/common/action.types";
import documentActions from "../../actions/documentActions";
import { DocumentListView } from "areas/administration/types/documentResponse.types";


interface IUserDocumentsState {
  documents: DocumentListView[],
  error: string | null;
  loading: boolean;
}

const INITIAL_STATE: IUserDocumentsState = {
  documents: [],
  error: null,
  loading: false,
};


const userDocumentsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUserDocumentsState => {

  const { DOCUMENTS_GETFORUSER } = documentActions.types;

  switch (action.type) {
    case DOCUMENTS_GETFORUSER.START:
      return { ...state, loading: true, error: null };

    case DOCUMENTS_GETFORUSER.SUCCESS:
      return { ...state, documents: action.payload, loading: false };

    case DOCUMENTS_GETFORUSER.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default userDocumentsReducer;
