import React from "react";
import { useReview } from "./reviewContext";
import { Constants } from "configuration";
import { StatusView } from "sharedComponents/common";


const RevieweeIntroduction = () => {

  const { state } = useReview();

  return (
    <>
      {state.review.currentReviewPeriod && (
        <StatusView status={state.review.currentReviewPeriod?.type}>
          <StatusView.Item status={Constants.REVIEW_PERIODS.YEARSTART}>
            <p>
              This is your new performance review for the{" "}
              {state.review.academicYear} Academic Year. Your line manager has
              set your three performance targets for you for the year.
            </p>
            <p>
              You are now able to add evidence supporting your target
              completion and include any training or CPD (Continuing
              Professional Development) that you have undertaken.
            </p>
          </StatusView.Item>

          <StatusView.Item status={Constants.REVIEW_PERIODS.MIDYEAR}>
            <p>
              This is your performance review for the{" "}
              {state.review.academicYear} Academic Year. The mid-year review
              period is now open and you are now able to add evidence
              supporting your target completion. You can also include any
              training or CPD (Continuing Professional Development) that you
              have undertaken.
            </p>
            <p>
              Your line manager is able to log in separately and add add
              updates about your progress towards meeting your targets.
            </p>
          </StatusView.Item>

          <StatusView.Item status={Constants.REVIEW_PERIODS.YEAREND}>
            <p>
              This is your performance review for the{" "}
              {state.review.academicYear} Academic Year. The end-of-year
              review period is now open and you are now able to add evidence
              supporting your target completion. You can also include any
              training or CPD (Continuing Professional Development) that you
              have undertaken. You will also be able to add a summary for the
              academic year.
            </p>

            <p>
              Your line manager is able to log in separately and add add
              updates about your progress towards meeting your targets as well
              as give you an overall performance rating for the year.
            </p>
          </StatusView.Item>
        </StatusView>
      )}
      <p>
        Once you have entered all of the necessary details, you will be able to
        save the review. Please note that details are not saved after each step
        and you will need to finish entering all details before saving.
      </p>
      <p>
        Please click on the <b>'Next'</b> button to continue.
      </p>
    </>
  );
};
export default RevieweeIntroduction;
