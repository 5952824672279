import store from "reducers/store";
import { IBeehiveAction } from "types/common/action.types";
import { FormCategoryListView, FormListView } from "../types/formResponse.types";
import { StaffListView } from "types/users/userListViews.types";

const types = {
  FILTERBYCATEGORY: "filterbycategory",
  FILTERBYFORMTYPE: "filterbyformtype",
  FILTERBYFORMSTATUS: "filterbyformstatus",
  FILTERBYFORMUSER: "filterbyformusers",
  FILTERBYSCHOOL: "filterbyschool",
  FILTERBYSEARCHTERM: "filterbysearchterm",
  FILTERBYFORMAUTHORISER: "filterbyformauthoriser",
  FILTERBYFORMSUBMITTER: "filterbyformsubmitter"
};


const filterByCategory = (category: FormCategoryListView) => {
  store.dispatch<IBeehiveAction<FormCategoryListView>>({
    type: types.FILTERBYCATEGORY,
    payload: category,
  });
};

const filterByFormType = (formType: FormListView) => {
  store.dispatch<IBeehiveAction<FormListView>>({
    type: types.FILTERBYFORMTYPE,
    payload: formType,
  });
};

const filterByFormStatus = (formStatus: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.FILTERBYFORMSTATUS,
    payload: formStatus,
  });
};

const filterByFormUser = (userId: string) => {
  store.dispatch<IBeehiveAction<string>>({
    type: types.FILTERBYFORMUSER,
    payload: userId,
  });
};

const filterByAuthoriser = (user: StaffListView) => {
  store.dispatch<IBeehiveAction<StaffListView>>({
    type: types.FILTERBYFORMAUTHORISER,
    payload: user,
  });
}

const filterBySubmitter = (user: StaffListView) => {
  store.dispatch<IBeehiveAction<StaffListView>>({
    type: types.FILTERBYFORMSUBMITTER,
    payload: user,
  });
}

const filterBySchool = (schoolId: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.FILTERBYSCHOOL,
    payload: schoolId,
  });
};

const filterBySearchTerm = (searchTerm: string) => {
  store.dispatch<IBeehiveAction<string>>({
    type: types.FILTERBYSEARCHTERM,
    payload: searchTerm,
  });
};


const submissionFilterActions = {
  types,
  filterByCategory,
  filterByFormType,
  filterByFormStatus,
  filterByFormUser,
  filterByAuthoriser,
  filterBySubmitter,
  filterBySchool,
  filterBySearchTerm,
};

export default submissionFilterActions;
