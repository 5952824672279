import { IBeehiveAction } from "types/common/action.types";
import staffMemberActions from "../../actions/staffMemberActions";
import { PmStaffDetailView } from "areas/humanResources/types/managedStaffResponse.types";

interface ICurrentPmUserState {
  currentPmUser: PmStaffDetailView | null;
  loading: boolean;
  error: string | null;
  selectedTabIndex: number;
}

const INITIAL_STATE: ICurrentPmUserState = {
  currentPmUser: null,
  loading: false,
  error: null,
  selectedTabIndex: 0,
};

const currentPmUserReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ICurrentPmUserState => {

  const { GETCURRENTPMUSER, SELECTCURRENTPMUSERTAB } = staffMemberActions.types;

  switch (action.type) {
    case SELECTCURRENTPMUSERTAB:
      return { ...state, selectedTabIndex: action.payload };
    case GETCURRENTPMUSER.START:
      return { ...INITIAL_STATE, loading: true };
    case GETCURRENTPMUSER.SUCCESS:
      return { ...INITIAL_STATE, currentPmUser: action.payload };
    case GETCURRENTPMUSER.FAILED:
      return { ...INITIAL_STATE, error: action.payload };
    default:
      return state;
  }
};

export default currentPmUserReducer;
