import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Constants } from "configuration";
import ActivityIcon from "./activityIcon";
import ShowInActivityFeed from "./showInActivityFeed";
import { Card } from "ui-kit";
import ContextMenu from "ui-kit/composite/contextMenu";
import { StaffView } from "sharedComponents/common/users/userTypeView";
import { FEED_TYPE_KEY } from "../../constants/activityFeed";
import { BEHAVIOUR_STATUS_KEYS } from "areas/behaviour/constants/behaviours";
import { DETENTION_STATUS_KEYS } from "areas/behaviour/constants/detentions";
import UserName from "sharedComponents/common/users/userName";
import ConfirmModal from "sharedComponents/common/confirmModal";
import OnCallActivity from "./activityItems/onCallActivity";
import LeftLessonActivity from "./activityItems/leftLessonActivity";
import BehaviourActivity from "./activityItems/behaviourActivity";
import RewardActivity from "./activityItems/rewardActivity";
import SmartTaskActivity from "./activityItems/smartTaskActivity";
import {
  getGroupName,
  FeedItemContent,
  DateAndStudentName,
  FeedItemDetails,
  FeedItemActionButtons,
} from "./activityFeedItemComponents";

const FeedItem = styled.div`
  display: flex;
`;

const ActivityFeedItem = ({
  activity,
  handleDeleteActivity,
  handleEditActivity,
  handleUpscaleBehaviour,
  handleFeedClick,
  displayStudentName,
}) => {
  const { user } = useSelector(state => state.currentUser);

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const userIsAuthorised = () => {
    if (
      user.roles?.some(
        role => role.roleType === Constants.ROLES.LEADERSHIP.roleType
      ) ||
      (activity.createdBy && activity.createdBy.id === user.id)
    ) {
      return true;
    } else {
      switch (activity.feedTypeId) {
        case FEED_TYPE_KEY.BEHAVIOURS:
        case FEED_TYPE_KEY.REWARDS:
          return user.roles?.some(
            role =>
              role.roleType ===
                Constants.ROLES.BEHAVIOUR_ADMINISTRATOR.roleType ||
              role.roleType === Constants.ROLES.BEHAVIOUR_MANAGER.roleType
          );

        case FEED_TYPE_KEY.ON_CALL:
          return user.roles?.some(
            role =>
              role.roleType === Constants.ROLES.ON_CALL_ADMINISTRATOR.roleType
          );

        default:
          return false;
      }
    }
  };

  const allowBehaviourChange = () => {
    if (activity.behaviourStatusId === BEHAVIOUR_STATUS_KEYS.ISSUED) {
      return userIsAuthorised();
    }
  };

  const allowUpscale = () => {
    if (
      activity.behaviourStatusId === BEHAVIOUR_STATUS_KEYS.ISSUED &&
      activity.detentionStatusId !== DETENTION_STATUS_KEYS.ATTENDED &&
      activity.isBehaviourCodeUpscalable &&
      (!activity.detentionCount || activity.detentionCount < 2) // if it is upscalable and detentions is null or if there isn't multiple detentions
    ) {
      if (activity.customGroup === null || activity.customGroup?.isActive) {
        return true;
      }
    }
  };

  const onFeedItemClick =
    user.userType == Constants.USER_TYPES.STAFF && userIsAuthorised()
      ? () => handleFeedClick(activity)
      : null;

  const getActivityDetails = () => {
    switch (activity.feedTypeId) {
      case FEED_TYPE_KEY.SMART_TASK:
        return (
          <SmartTaskActivity
            activity={activity}
            displayStudentName={displayStudentName}
          />
        );

      case FEED_TYPE_KEY.REWARDS:
        return (
          <RewardActivity
            activity={activity}
            displayStudentName={displayStudentName}
            allowChange={allowBehaviourChange}
            handleEditActivity={handleEditActivity}
            handleFeedClick={onFeedItemClick}
          />
        );

      case FEED_TYPE_KEY.BEHAVIOURS:
        return (
          <BehaviourActivity
            activity={activity}
            displayStudentName={displayStudentName}
            allowChange={allowBehaviourChange}
            allowUpscale={allowUpscale}
            handleEditActivity={handleEditActivity}
            handleUpscaleBehaviour={handleUpscaleBehaviour}
            handleFeedClick={onFeedItemClick}
          />
        );

      case FEED_TYPE_KEY.LEFT_LESSON:
        return (
          <LeftLessonActivity
            activity={activity}
            displayStudentName={displayStudentName}
            userIsAuthorised={userIsAuthorised}
            handleOnClickDelete={() => setOpenConfirmDelete(true)}
          />
        );

      case FEED_TYPE_KEY.ON_CALL:
        return (
          <OnCallActivity
            activity={activity}
            displayStudentName={displayStudentName}
            userIsAuthorised={userIsAuthorised}
            handleEditActivity={handleEditActivity}
            handleFeedClick={onFeedItemClick}
          />
        );

      default:
        return (
          <>
            <FeedItemContent>
              <DateAndStudentName
                displayStudentName={displayStudentName}
                activity={activity}
              />

              <FeedItemDetails>
                <b>{activity.categoryName}</b>{" "}
                {activity.feedTypeId === FEED_TYPE_KEY.NOTES && <b>Note </b>}
                {`${
                  activity.feedTypeId === FEED_TYPE_KEY.AWARDS
                    ? "given by "
                    : "recorded by "
                }`}
                <b>
                  <UserName user={activity.createdBy} />
                </b>
                {!displayStudentName && getGroupName(activity)}
                <div className="note-content">{activity.content}</div>
              </FeedItemDetails>
            </FeedItemContent>

            <StaffView>
              {userIsAuthorised() && (
                <FeedItemActionButtons>
                  <ContextMenu>
                    <ContextMenu.Item
                      text="Delete"
                      onClick={() => setOpenConfirmDelete(true)}
                    />
                  </ContextMenu>
                </FeedItemActionButtons>
              )}
            </StaffView>
          </>
        );
    }
  };

  return (
    <>
      <ShowInActivityFeed feedTypeId={activity.feedTypeId}>
        <Card>
          <Card.Body>
            <FeedItem>
              <ActivityIcon
                activityTypeId={activity.feedTypeId}
                codeDisplayName={activity.codeDisplayName}
              />

              {getActivityDetails()}
            </FeedItem>
          </Card.Body>
        </Card>
      </ShowInActivityFeed>

      <ConfirmModal
        openModal={openConfirmDelete}
        confirmMsg={`Are you sure you want to permanently delete this ${activity.feedTypeName} record?`}
        onOpen={() => setOpenConfirmDelete(true)}
        onClose={() => setOpenConfirmDelete(false)}
        onConfirm={() =>
          handleDeleteActivity(
            activity.feedTypeId,
            activity.students[0].id,
            activity.itemId,
            () => setOpenConfirmDelete(false)
          )
        }
      />
    </>
  );
};

ActivityFeedItem.propTypes = {
  activity: PropTypes.object,
  handleDeleteActivity: PropTypes.func,
  displayStudentName: PropTypes.bool,
};

export default ActivityFeedItem;
