/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import {
  Calendar,
  Icon,
  displayFont,
  neutral,
  typescale,
  Spacing,
  BorderRadius,
  fontStyle,
  Spinner,
  Size,
  IUiKitBaseProps,
} from "../index";
import moment from "moment";
import { Constants } from "../../configuration";
import { danger } from "../common/colors";
import { DateObject } from "ui-kit/composite/calendar";
import uiKit from "../../utils/uiKit";

const Wrapper = styled.div<{
  fluid?: boolean;
  pickerOpen: boolean;
  disabled?: boolean;
  invalid: boolean;
  // top?: boolean;
}>`
  box-sizing: box-content;
  width: 20rem;
  ${({ fluid }) =>
    fluid
      ? `
        display: flex;
        width: 100%;
        .calendar-picker {
          width: auto !important;

          .calendar .calendar-body table th, .calendar .calendar-body table td {
            width: 28px;
          }
        }
        `
      : `display: inline-flex;`}

  .date-picker-input {
    flex-grow: 1;
    display: flex;
    border-bottom: 1px solid transparent;

    input {
      ${fontStyle(displayFont.medium, typescale.paragraph, neutral[600])}
      padding: ${Spacing.Default}px;
      border: 0;
      background: ${neutral[200]};
      flex-grow: 1;
      border-top-left-radius: ${BorderRadius.Default}px;
      border-bottom-left-radius: ${BorderRadius.Default}px;
    }

    .date-clear {
      border: unset;

      &:hover {
        cursor: pointer;
        .icon {
          color: ${danger};
        }
      }
    }

    .open-picker-button {
      background: ${neutral[500]};
      width: 2rem;
      border-top-right-radius: ${BorderRadius.Default}px;
      border-bottom-right-radius: ${BorderRadius.Default}px;
      border-left: 1px solid ${neutral[100]};

      i {
        color: ${neutral[100]};
      }

      &:hover {
        background: ${neutral[600]};
        cursor: pointer;
      }

      border: 0;
    }
  }

  ${({ pickerOpen }) =>
    pickerOpen
      ? `
      .date-picker-input {
          border-bottom: 1px solid ${neutral[100]};
          input {
              border-bottom-left-radius: 0;
            
          }
          .open-picker-button {
            border-bottom-right-radius: 0;
        }
      }
        .calendar-picker {
            display: block;  
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
            position: absolute;
            width: 100%;
            z-index: 999;
            top: 100%;

            .calendar-header {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                        }
        
        }
    `
      : `
        .calendar-picker {
            display: none;
        }
    `}

  ${({ disabled }) => disabled && `opacity: 0.55`}
`;

export interface IDatePickerProps extends IUiKitBaseProps {
  selectedDate?: moment.MomentInput;
  dateFormat?: string;
  inputDateFormat?: string;
  onChange?: (date: Date | null) => void;
  closeOnSelect?: boolean;
  disabled?: boolean;
  fluid?: boolean;
  placeholder?: string;
  disallowed?: Date[];
  onDateRangeChange?: (startDate: DateObject, endDate: DateObject) => void;
  loading?: boolean;
  handleOnTextChangeDate?: (date: moment.Moment) => void;
  clearable?: boolean;
  value?: Date;
}

const DatePicker: React.FC<IDatePickerProps> = ({
  selectedDate: initialDate,
  dateFormat,
  inputDateFormat,
  onChange,
  closeOnSelect,
  disabled,
  fluid,
  placeholder,
  className,
  disallowed,
  onDateRangeChange,
  loading,
  handleOnTextChangeDate,
  clearable,
}) => {
  const getDateFormat = () => {
    return dateFormat ? dateFormat : Constants.DEFAULT_DATEFORMAT;
  };

  const [textValue, setTextValue] = useState<string | null>(null);
  const [invalid, setInvalid] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [pickerOpen, setPickerOpen] = useState(false);
  // const [bottomSpace, setBottomSpace] = useState(0);
  // const [topSpace, setTopSpace] = useState(0);

  const wrapperRef = useRef() as React.RefObject<HTMLDivElement>;

  const handleClick = (e: MouseEvent) => {
    if (wrapperRef && !wrapperRef?.current?.contains(e.target as Node)) {
      setPickerOpen(false);
    }
  };

  const analyseDate = (dateString: string) => {
    var momentDate = moment(dateString, getDateFormat(), true);
    return momentDate.isValid();
    // if (momentDate.isValid()) {
    //   return true;
    // } else {
    //   momentDate = moment(dateString);
    //   return momentDate.isValid();
    // }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick, true); // add when mounted
    return () => {
      document.removeEventListener("click", handleClick); // return function to be called when unmounted
    };
  }, []);

  // useEffect(() => {
  //   if (initialDate) {
  //     if (analyseDate(initialDate)) {
  //       setSelectedDate(initialDate);
  //       setTextValue(
  //         moment(initialDate, getDateFormat()).format(getDateFormat())
  //       );
  //     }
  //   }
  // }, [initialDate]);

  useEffect(() => {
    if (initialDate) {
      setSelectedDate(moment(initialDate));
      setTextValue(moment(initialDate).format(getDateFormat()));
    }
  }, [initialDate]);

  const handleCalendarChange = (date: Date) => {
    onChange?.(date);
    setTextValue(moment(date).format(getDateFormat()));

    closeOnSelect && setPickerOpen(false);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = e.target.value;
    setTextValue(newDate);

    if (analyseDate(newDate)) {
      // set date
      setSelectedDate(newDate);
      handleOnTextChangeDate?.(moment(newDate, getDateFormat()));
      setInvalid(false);
    } else {
      setInvalid(true);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !(
        (event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105) ||
        event.keyCode === 191 ||
        event.keyCode === 46 ||
        event.keyCode === 8 ||
        event.keyCode === 39 ||
        event.keyCode === 37
      )
    ) {
      event.preventDefault();
    }
  };

  const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setPickerOpen(false);
    setSelectedDate(null);
    setTextValue(null);
    onChange?.(null);
  };

  return (
    <Wrapper
      pickerOpen={pickerOpen}
      className={`date-picker form-control ${className ? className : ""}`}
      ref={wrapperRef}
      disabled={disabled}
      fluid={fluid}
      invalid={invalid}
      // top={bottomSpace < 350 && topSpace > 440} // only open above if there is enough space above otherwise open below.
    >
      <div
        className="date-picker-input"
        // ref={el => {
        //   const botSpace = uiKit.getElementBottomSpace(el);
        //   bottomSpace !== botSpace && setBottomSpace(botSpace);

        //   const tSpace = uiKit.getElementTopSpace(el);
        //   topSpace !== tSpace && setTopSpace(tSpace);
        // }}
      >
        <input
          type="text"
          disabled={disabled}
          value={textValue ? textValue : ""}
          placeholder={placeholder ? placeholder : "Pick a date..."}
          onKeyDown={handleKeyDown}
          onChange={handleTextChange}
        />

        {clearable && selectedDate !== null && textValue !== null && (
          <button className="date-clear" onClick={e => handleClear(e)}>
            <Icon value="times" size={Size.Small} />
          </button>
        )}
        <button
          className="open-picker-button"
          disabled={disabled}
          onClick={() => setPickerOpen(!disabled && !pickerOpen)}
        >
          {loading ? (
            <Spinner size={Size.Medium} />
          ) : (
            <Icon value={pickerOpen ? "calendar-times" : "calendar"} />
          )}
        </button>
      </div>
      <div className="calendar-picker">
        <Calendar
          selectedDate={selectedDate}
          onChange={handleCalendarChange}
          disallowed={disallowed}
          onDateRangeChange={onDateRangeChange}
          loading={loading}
        />
      </div>
    </Wrapper>
  );
};

export default DatePicker;
