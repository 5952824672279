import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faCopy,
  faEmptySet,
  faSandwich,
  faBullseyePointer,
  faMegaphone,
  faStars,
  faForward,
  faExclamation,
  faRedo,
  faBook,
  faBooks,
  faTvRetro,
  faRadio,
  faPodcast,
  faFilm,
  faVideo,
  faGlobe,
  faBus,
  faAbacus,
  faHandHolding,
  faLeaf,
  faFileCertificate,
  faReply,
  faComment,
  faCircle,
  faPlay,
  faBars,
  faFilter,
  faCircleNotch,
  faCoffee,
  faUser,
  faUsers,
  faShoppingBag,
  faBell,
  faSearch,
  faTimesCircle,
  faHome,
  faUsersClass,
  faCalendar,
  faPlane,
  faIdCardAlt,
  faEnvelope,
  faEnvelopeOpen,
  faPaperclip,
  faPaste,
  faClock,
  faSeedling,
  faBriefcase,
  faTimes,
  faPaperPlane,
  faLockAlt,
  faSignOut,
  faHeart,
  faThumbsUp,
  faTrash,
  faNewspaper,
  faUndoAlt,
  faPrint,
  faArrowCircleRight,
  faArrowCircleLeft,
  faTags,
  faReceipt,
  faMapMarkerAlt,
  faCaretUp,
  faCaretDown,
  faCheck,
  faAngleRight,
  faAngleLeft,
  faCalendarAlt,
  faCalendarTimes,
  faPlus,
  faBullseyeArrow,
  faFlagCheckered,
  faSave,
  faRocket,
  faVirus,
  faSmile,
  faQuestion,
  faFrown,
  faQuestionCircle,
  faCheckCircle,
  faVial,
  faTrafficLight,
  faInbox,
  faCar,
  faUserClock,
  faUniversity,
  faGraduationCap,
  faCoins,
  faStopwatch,
  faTable,
  faPencil,
  faCloudUpload,
  faFileImage,
  faFile,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileCsv,
  faSkullCrossbones,
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSearchMinus,
  faSearchPlus,
  faSync,
  faExclamationTriangle,
  faMinus,
  faClipboardCheck,
  faClipboard,
  faThumbsDown,
  faStar,
  faStickyNote,
  faList,
  faTh,
  faHistory,
  faCommentLines,
  faLink,
  faPlaneDeparture,
  faPlaneArrival,
  faLock,
  faThumbtack,
  faShieldAlt,
  faBan,
  faEye,
  faEyeSlash,
  faAngel,
  faCalendarDay,
  faAlarmClock,
  faAlarmExclamation,
  faAlarmPlus,
  faInfoCircle,
  faTasks,
  faFileDownload,
  faBoxOpen,
  faBoxes,
  faGift,
  faTag,
  faMedal,
  faTrophyAlt,
  faBagsShopping,
  faPortrait,
  faChartLine,
  faBullhorn,
  faSignalStream,
  faArrowUp,
  faArrowDown,
  faIndustry,
  faMobileAlt,
  faPhone,
  faDoorOpen,
  faLaugh,
  faMeh,
  faMehBlank,
  faExternalLinkAlt,
  faCog,
  faCogs,
  faExclamationCircle,
  faChalkboard,
  faPlusCircle,
  faSortUp,
  faSortDown,
  faCaretCircleDown,
  faCaretCircleUp,
  faWalking,
  faDiploma,
  faUtensils,
  faStarAndCrescent,
  faHouseNight,
  faExchangeAlt,
  faAngleUp,
  faAngleDown,
  faHandHoldingSeedling,
  faCamera,
  faEllipsisV,
  faMale,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faHeart as farHeart,
  faEllipsisV as farEllipsisV,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faHome as falHome,
  faBriefcase as falBriefcase,
  faCalendar as falCalendar,
  faShoppingBag as falShoppingBag,
  faIdCardAlt as falIdCardAlt,
  faExclamationCircle as falExclamationCircle,
  faEllipsisV as falEllipsisV,
} from "@fortawesome/pro-light-svg-icons";

import { faStripe } from "@fortawesome/free-brands-svg-icons";

const BuildIcons = () => {
  library.add(
    faCopy,
    faEmptySet,
    faSandwich,
    faBullseyePointer,
    faMegaphone,
    faStars,
    faHouseNight,
    faForward,
    faExclamation,
    faRedo,
    faBook,
    faLeaf,
    faStarAndCrescent,
    faBooks,
    faTvRetro,
    faRadio,
    faPodcast,
    faFilm,
    faVideo,
    faGlobe,
    faBus,
    faAbacus,
    faHandHoldingSeedling,
    faFileCertificate,
    faReply,
    faComment,
    faDiploma,
    faStripe,
    faCircle,
    faWalking,
    faPlay,
    faBars,
    faFilter,
    falHome,
    falBriefcase,
    falCalendar,
    falShoppingBag,
    falIdCardAlt,
    faCircleNotch,
    faCoffee,
    faUser,
    faUsers,
    faShoppingBag,
    faSearch,
    faBell,
    faTimesCircle,
    faHome,
    faPaperclip,
    faCalendar,
    faPlane,
    faUsersClass,
    faIdCardAlt,
    faStarAndCrescent,
    faEnvelope,
    faEnvelopeOpen,
    faPaste,
    faClock,
    faSeedling,
    faBriefcase,
    faTimes,
    faPaperPlane,
    faTimesCircle,
    faLockAlt,
    faSignOut,
    faHeart,
    faThumbsUp,
    faTrash,
    faNewspaper,
    faUndoAlt,
    faPrint,
    faArrowCircleRight,
    faArrowCircleLeft,
    faTags,
    faReceipt,
    faMapMarkerAlt,
    faCaretUp,
    faCaretDown,
    faCheck,
    faAngleRight,
    faAngleLeft,
    faCalendarAlt,
    faCalendarTimes,
    faPlus,
    faBullseyeArrow,
    faFlagCheckered,
    faSave,
    faRocket,
    faVirus,
    faSmile,
    faFrown,
    faQuestion,
    faQuestionCircle,
    faCheckCircle,
    faVial,
    faTrafficLight,
    faInbox,
    faCar,
    faExclamationCircle,
    faUserClock,
    faUniversity,
    faGraduationCap,
    faCoins,
    faReceipt,
    faStopwatch,
    faTable,
    faPencil,
    faCloudUpload,
    faFile,
    faFileImage,
    faFilePdf,
    faFileWord,
    faFileExcel,
    faFilePowerpoint,
    faFileCsv,
    faSkullCrossbones,
    faSortAlphaDown,
    faSortAlphaDownAlt,
    faSearchMinus,
    faSearchPlus,
    faSync,
    faExclamationTriangle,
    faMinus,
    faClipboardCheck,
    faClipboard,
    faThumbsDown,
    faStar,
    faStickyNote,
    faList,
    faTh,
    faHistory,
    faCommentLines,
    faLink,
    faPlaneDeparture,
    faPlaneArrival,
    faLock,
    faThumbtack,
    faShieldAlt,
    faBan,
    faEye,
    faEyeSlash,
    faAngel,
    faCalendarDay,
    faAlarmClock,
    faAlarmExclamation,
    faAlarmPlus,
    faInfoCircle,
    faTasks,
    faFileDownload,
    faBoxOpen,
    faBoxes,
    faGift,
    faTag,
    faMedal,
    faTrophyAlt,
    faBagsShopping,
    faPortrait,
    faChartLine,
    faBullhorn,
    faSignalStream,
    faArrowUp,
    faArrowDown,
    faExternalLinkAlt,
    faIndustry,
    faMobileAlt,
    faPhone,
    faDoorOpen,
    faLaugh,
    faMeh,
    faMehBlank,
    faCog,
    faCogs,
    faChalkboard,
    faPlusCircle,
    faSortUp,
    faSortDown,
    faCaretUp,
    faCaretDown,
    faCaretCircleDown,
    faCaretCircleUp,
    faUtensils,
    faAngleUp,
    faAngleDown,
    faCamera,
    faExclamationCircle,
    falExclamationCircle,
    farHeart,
    faEllipsisV,
    farEllipsisV,
    falEllipsisV,
    faMale
  );
};

export default BuildIcons;
