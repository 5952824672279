import defaults from "configuration/defaults";
import magazineActions from "../../actions/magazineActions";
import { PageInfo } from "types/common/paging.types";
import { CategoryListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { ArticleListView } from "areas/news/types/newsResponse.types";
import { IBeehiveAction } from "types/common/action.types";


interface IMagazineCategoryState {
  loading: boolean;
  error: string | null;
  category: CategoryListView | null;
  features: ArticleListView[];
  paging: PageInfo;
}

const INITIAL_STATE: IMagazineCategoryState = {
  loading: false,
  error: null,
  category: null,
  features: [],
  paging: defaults.DEFAULT_PAGING,
};


const magazineCategoryReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IMagazineCategoryState => {

  const { MAGAZINE_GETCATEGORY } = magazineActions.types;

  switch (action.type) {
    case MAGAZINE_GETCATEGORY.START:
      return { ...INITIAL_STATE, loading: true, error: null };

    case MAGAZINE_GETCATEGORY.SUCCESS:
      var newState = {
        ...state,
        category: action.payload.category,

        features:
          action.payload.articles.paging.pageIndex === 0
            ? action.payload.articles.items
            : [...state.features, ...action.payload.articles.items],

        paging: action.payload.articles.paging,

        loading: false,
      };
      return newState;

    case MAGAZINE_GETCATEGORY.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default magazineCategoryReducer;
