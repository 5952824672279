import storeActions from "areas/payments/actions/storeActions";
import { BasketDetailView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


interface IBasketErrors {
  load: string | null;
  item: string | null;
}

export interface IBasketState {
  basket: BasketDetailView | null;
  working: boolean;
  redirecting: boolean;
  checkoutUrl: string | null;
  errors: IBasketErrors;
  refresh: boolean;
  checkoutActive: boolean;
}

const INITIAL_ERRORS: IBasketErrors = {
  load: null,
  item: null
};

const INITIAL_STATE: IBasketState = {
  basket: null,
  working: false,
  redirecting: false,
  checkoutUrl: null,
  errors: INITIAL_ERRORS,
  refresh: false,
  checkoutActive: false
};


const basketReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IBasketState => {

  const {
    BASKET_GET,
    BASKET_REMOVEITEM,
    BASKET_ADDPRODUCT,
    BASKET_ADDTOPUP,
    BASKET_ADDPRINTCREDIT,
    BASKET_ADDLUNCHCREDIT,
    CHECKOUT_START,
    CHECKOUT_CLOSE,
    CHECKOUT_CANCEL,
    CHECKOUT_RESET
  } = storeActions.storeTypes;

  switch (action.type) {
    case BASKET_GET.START:
      return {
        ...state,
        working: true,
        refresh: false,
        errors: INITIAL_ERRORS
      };

    case BASKET_GET.SUCCESS:
      return {
        ...state,
        basket: action.payload,
        working: false,
        refresh: false
      };

    case BASKET_GET.FAILED:
      return {
        ...state,
        working: false,
        refresh: false,
        errors: { 
          ...state.errors, 
          load: action.payload 
        }
      };

    case BASKET_REMOVEITEM.START:
      return { 
        ...state, 
        working: true, 
        errors: INITIAL_ERRORS 
      };

    case BASKET_REMOVEITEM.SUCCESS:
      return { 
        ...state, 
        working: false, 
        refresh: true 
      };

    case BASKET_REMOVEITEM.FAILED:
      return {
        ...state,
        working: false,
        errors: {
          ...state.errors,
          item: "There was a problem removing the item"
        }
      };

    case BASKET_ADDPRODUCT.START:
      return { 
        ...state, 
        working: true, 
        errors: INITIAL_ERRORS 
      };

    case BASKET_ADDPRODUCT.SUCCESS:
      return { 
        ...state, 
        working: false, 
        refresh: true 
      };

    case BASKET_ADDPRODUCT.FAILED:
      return {
        ...state,
        working: false,
        errors: {
          ...state.errors,
          item: "There was a problem adding the product to basket"
        }
      };

    case BASKET_ADDPRINTCREDIT.START:
      return { 
        ...state, 
        working: true, 
        errors: INITIAL_ERRORS 
      };

    case BASKET_ADDPRINTCREDIT.SUCCESS:
      return { 
        ...state, 
        working: false, 
        refresh: true 
      };

    case BASKET_ADDPRINTCREDIT.FAILED:
      return {
        ...state,
        working: false,
        errors: {
          ...state.errors,
          item: "There was a problem adding the print credit"
        }
      };

    case BASKET_ADDLUNCHCREDIT.START:
      return { 
        ...state, 
        working: true, 
        errors: INITIAL_ERRORS 
      };

    case BASKET_ADDLUNCHCREDIT.SUCCESS:
      return { 
        ...state, 
        working: false, 
        refresh: true 
      };

    case BASKET_ADDLUNCHCREDIT.FAILED:
      return {
        ...state,
        working: false,
        errors: {
          ...state.errors,
          item: "There was a problem adding the lunch credit"
        }
      };

    case BASKET_ADDTOPUP.START:
      return { 
        ...state, 
        working: true, 
        errors: INITIAL_ERRORS 
      };

    case BASKET_ADDTOPUP.SUCCESS:
      return { 
        ...state, 
        working: false,
        refresh: true 
      };

    case BASKET_ADDTOPUP.FAILED:
      return {
        ...state,
        working: false,
        errors: {
          ...state.errors,
          item: "There was a problem adding the topup"
        }
      };

    case CHECKOUT_START.START:
      return {
        ...state,
        redirecting: true,
        checkoutActive: false,
        errors: INITIAL_ERRORS
      };

    case CHECKOUT_START.SUCCESS:
      return {
        ...state,
        checkoutUrl: action.payload,
        checkoutActive: true,
        redirecting: false
      };

    case CHECKOUT_START.FAILED:
      return {
        ...state,
        redirecting: false,
        checkoutActive: false,
        errors: {
          ...state.errors,
          item: action.payload ?? "There was a problem redirecting to our payment provider"
        }
      };

    case CHECKOUT_CLOSE:
    case CHECKOUT_CANCEL:
    case CHECKOUT_RESET:
      return {
        ...state,
        errors: INITIAL_ERRORS,
        redirecting: false,
        working: false,
        checkoutUrl: null,
        checkoutActive: false,
        refresh: true
      };

    default:
      return state;
  }
};

export default basketReducer;
