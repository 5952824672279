import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DetentionDetailsFlyout from "./detentions/detentionDetailsFlyout";
import { TabBar } from "ui-kit";
import { Route, useLocation, Routes, useNavigate } from "react-router";
import SmartTasksWrapper from "./smartTasks/smartTasksWrapper";
import { Constants } from "configuration";
import { users } from "utils";
import CustomRoute from "sharedComponents/layout/routes/customRoute";
import { behaviourRoutes } from "../behaviourRoutes";
import schoolActions from "areas/planner/actions/school/schoolActions";
import PendingAttentionWrapper from "./behaviours/pendingAttentionBehaviours/pendingAttentionWrapper";
import OnCallRequestsWrapper from "./onCall/onCallRequestsWrapper";
import PendingAttentionBehaviourFlyout from "./behaviours/pendingAttentionBehaviours/pendingAttentionBehaviourFlyout";
import DetentionRegister from "./detentions/detentionRegister/detentionRegister";
import MissedDetentionsWrapper from "./detentions/missedDetentions/missedDetentionsWrapper";
import ManagedMoves from "areas/planner/components/managedMoves";
import routes from "../../../configuration/routes";
import { useAppSelector } from "reducers/hooks";

const TABS = {
  detentions: {
    key: 0,
    name: "detentions",
    text: "Detention Register",
  },
  missedDetentions: {
    key: 1,
    name: "misseddetentions",
    text: "Missed Detentions",
  },
  pendingAttention: {
    key: 2,
    name: "pendingattention",
    text: "Pending Attention Behaviours",
  },
  smartTasks: {
    key: 3,
    name: "smarttasks",
    text: "Smart Tasks",
  },
  onCall: {
    key: 4,
    name: "oncalls",
    text: "On Call Requests",
  },
  managedMoves: {
    key: 5,
    name: "managedmoves",
    text: "Moves",
  },
};

const Behaviour = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useAppSelector(state => state.currentUser);
  const { schoolInformation } = useAppSelector(state => state.school);

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (!schoolInformation && user?.baseSchool) {
      schoolActions.getSchoolInformation(user?.baseSchool?.id);
    }

    switch (location.pathname) {
      case `${routes.home}${behaviourRoutes.default}`:
        setActiveTab(TABS.detentions.key);
        navigate(
          `${routes.home}${behaviourRoutes.default}/${TABS.detentions.name}`
        );
        break;
      case `${routes.home}${behaviourRoutes.default}/${TABS.detentions.name}`:
        setActiveTab(TABS.detentions.key);
        break;

      case `${routes.home}${behaviourRoutes.default}/${TABS.missedDetentions.name}`:
        setActiveTab(TABS.missedDetentions.key);
        break;

      case `${routes.home}${behaviourRoutes.default}/${TABS.pendingAttention.name}`:
        setActiveTab(TABS.pendingAttention.key);
        break;

      case `${routes.home}${behaviourRoutes.default}/${TABS.smartTasks.name}`:
        setActiveTab(TABS.smartTasks.key);
        break;

      case `${routes.home}${behaviourRoutes.default}/${TABS.onCall.name}`:
        setActiveTab(TABS.onCall.key);
        break;

      default:
        setActiveTab(TABS.detentions.key);
    }
  }, []);

  const handleTabClick = (name: string, index: number) => {
    navigate(`${routes.home}${behaviourRoutes.default}/${name}`);
    setActiveTab(index);
  };

  return (
    <>
      <TabBar onItemClicked={handleTabClick} selectedIndex={activeTab}>
        <TabBar.Item
          key={TABS.detentions.key}
          name={TABS.detentions.name}
          text={TABS.detentions.text}
        />
        <TabBar.Item
          key={TABS.missedDetentions.key}
          name={TABS.missedDetentions.name}
          text={TABS.missedDetentions.text}
          hide={
            !users.isInAnyRoles(user, [
              Constants.ROLES.LEADERSHIP,
              Constants.ROLES.BEHAVIOUR_ADMINISTRATOR,
              Constants.ROLES.BEHAVIOUR_MANAGER,
            ])
          }
        />
        <TabBar.Item
          key={TABS.pendingAttention.text}
          name={TABS.pendingAttention.name}
          text={TABS.pendingAttention.text}
          hide={
            !users.isInAnyRoles(user, [
              Constants.ROLES.LEADERSHIP,
              Constants.ROLES.BEHAVIOUR_MANAGER,
            ])
          }
        />
        <TabBar.Item
          key={TABS.smartTasks.key}
          name={TABS.smartTasks.name}
          text={TABS.smartTasks.text}
          hide={
            !users.isInAnyRoles(user, [
              Constants.ROLES.LEADERSHIP,
              Constants.ROLES.BEHAVIOUR_MANAGER,
            ])
          }
        />
        <TabBar.Item
          key={TABS.onCall.key}
          name={TABS.onCall.name}
          text={TABS.onCall.text}
          hide={
            !schoolInformation?.enableOnCall ||
            !users.isInAnyRoles(user, [
              Constants.ROLES.LEADERSHIP,
              Constants.ROLES.TRUST_LEADERSHIP,
              Constants.ROLES.ON_CALL_ADMINISTRATOR,
            ])
          }
        />
        <TabBar.Item
          key={5}
          name="managedMoves"
          text="Moves"
          hide={
            !users.isInAnyRoles(user, [
              Constants.ROLES.LEADERSHIP,
              Constants.ROLES.TRUST_LEADERSHIP,
              Constants.ROLES.MOVES_ADMINISTRATOR,
            ])
          }
        />
      </TabBar>

      <Routes>
        <Route
          path={behaviourRoutes.detentions}
          element={
            <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
              <DetentionRegister />
            </CustomRoute>
          }
        />

        <Route
          path={behaviourRoutes.misseddetentions}
          element={
            <CustomRoute
              users={[Constants.USER_TYPES.STAFF]}
              roles={[
                Constants.ROLES.LEADERSHIP,
                Constants.ROLES.BEHAVIOUR_ADMINISTRATOR,
                Constants.ROLES.BEHAVIOUR_MANAGER,
              ]}
            >
              <MissedDetentionsWrapper />
            </CustomRoute>
          }
        />

        <Route
          path={behaviourRoutes.pendingattention}
          element={
            <CustomRoute
              users={[Constants.USER_TYPES.STAFF]}
              roles={[
                Constants.ROLES.LEADERSHIP,
                Constants.ROLES.BEHAVIOUR_MANAGER,
              ]}
            >
              <PendingAttentionWrapper />
            </CustomRoute>
          }
        />

        <Route
          path={behaviourRoutes.smarttasks}
          element={
            <CustomRoute
              users={[Constants.USER_TYPES.STAFF]}
              roles={[
                Constants.ROLES.LEADERSHIP,
                Constants.ROLES.BEHAVIOUR_MANAGER,
              ]}
            >
              <SmartTasksWrapper />
            </CustomRoute>
          }
        />

        <Route
          path={behaviourRoutes.managedmoves}
          element={
            <CustomRoute
              users={[Constants.USER_TYPES.STAFF]}
              roles={[
                Constants.ROLES.LEADERSHIP,
                Constants.ROLES.TRUST_LEADERSHIP,
                Constants.ROLES.MOVES_ADMINISTRATOR,
              ]}
            >
              <ManagedMoves />
            </CustomRoute>
          }
        />

        <Route
          path={behaviourRoutes.oncalls}
          element={
            <CustomRoute
              users={[Constants.USER_TYPES.STAFF]}
              roles={[
                Constants.ROLES.LEADERSHIP,
                Constants.ROLES.TRUST_LEADERSHIP,
                Constants.ROLES.ON_CALL_ADMINISTRATOR,
              ]}
            >
              <OnCallRequestsWrapper />
            </CustomRoute>
          }
        />
      </Routes>

      <DetentionDetailsFlyout />
      <PendingAttentionBehaviourFlyout />
    </>
  );
};

export default Behaviour;
