import { UserTypePermissionsView } from "types/common/views.types";
import { StaffListView, UserListView, UserTinyView } from "types/users/userListViews.types";

export interface ConsentFormListView {
  id: number;
  name: string;
  summary: string;
  formType: string;
  formTypeId: number;
  status: string;
  statusId: number;
  isActive: boolean;
}

export interface ConsentFormCheckView {
  count: number;
}

export interface ConsentFormUserView {
  id: number;
  name: string;
  summary: string;
  description: string;
  allowChange: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
  expiryDate?: Date | null;
  sections: ConsentFormSectionView[];
  users: ConsentFormUserDetailsView[];
}

export interface ConsentFormUserDetailsView {
  user: UserTinyView;
  history: ConsentResponseHistoryView[];
}

export interface ConsentResponseHistoryView {
  form: ConsentFormListView;
  formId: number;
  createdDate: Date;
  createdBy: UserTinyView;
  isWritten: boolean;
  items: ConsentResponseItemView[];
}

export interface ConsentResponseItemView {
  id?: number | null;
  itemId: number;
  text: string;
  title: string;
  code: string;
  consentGiven: ConsentResponseResult;
}

export enum ConsentResponseResult {
  ConsentRefused = 0,
  ConsentGiven = 1,
  PartialConsent = 2
}

export interface ConsentFormSectionView {
  id: number;
  title: string;
  description: string;
  sectionType: ConsentFormSectionType;
  itemGroups: ConsentFormItemGroupView[];
}

export interface ConsentFormItemGroupView {
  id: number;
  title: string;
  description: string;
  items: ConsentFormItemListView[];
}

export interface ConsentFormItemListView {
  id: number;
  text: string;
  code: string;
}

export enum ConsentFormSectionType {
  Step = 0,
  Declaration = 1
}

export interface ConsentFormDetailView extends ConsentFormListView {
  description: string;
  allowSelfConsent: boolean;
  allowChange: boolean;
  selfConsentAgeThreshold?: number | null;
  userPermissions: UserTypePermissionsView;
  startDate?: Date | null;
  endDate?: Date | null;
  expiryDate?: Date | null;
  createdBy: StaffListView;
  updatedBy: StaffListView;
  createdDate: Date;
  updatedDate: Date;
  responsibleStaff: StaffListView[];
  sections: ConsentFormSectionView[];
  invitations: ConsentInvitationListView[];
}

export interface ConsentInvitationListView {
  id: number;
  createdDate: string;
}

export interface ConsentResponseListView {
  id: number;
  user: UserListView;
  createdBy: UserTinyView;
  createdDate: string;
  form: ConsentFormListView;
  formId: number;
  isWritten: boolean;
  consentGiven: ConsentResponseResult;
}

export interface ConsentResponseDetailView extends ConsentResponseListView {
  tutorGroup: string;
  yearGroup: string;
  declarationName: string;
  declarationRelationship: string;
  items: ConsentResponseItemView[];
}