import { TrayListView } from "areas/filing/types/fileResponse.types";
import { LeaveRequestListView } from "areas/humanResources/types/leaveResponse.types";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useAppSelector } from "reducers/hooks";
import { ApprovalStatusChip, Avatar, HelpMessage, StaffModal, StaffSelector, StatusView, UserView } from "sharedComponents/common";
import LeaveRequestFooter from "./leaveRequestFooter";
import leaveActions from "areas/humanResources/actions/leaveActions";
import { Card, Chip, DateTime, EmptyMessage, HtmlText, Label, Message, RichTextEditor, Spacing, StructuredList, Swatches, ToastService, ValidationMessage } from "ui-kit";
import { ApprovalStatus, LeaveRequestType } from "areas/humanResources/types/leaveShared.types";
import LeaveRequestTypeChip from "../leaveRequestTypeChip";
import { strings } from "utils";
import CoverRequirementChip from "../coverRequirementChip";
import { StaffListView } from "types/users/userListViews.types";
import { UpdateLeaveRequestApproverCommand } from "areas/humanResources/types/leaveRequest.types";


interface ILeaveRequestModalProps {
  leaveRequest: LeaveRequestListView | TrayListView;
  open?: boolean;
  onClose?: () => void;
}


const LeaveRequestModal: React.FC<ILeaveRequestModalProps> = ({ leaveRequest, open, onClose }) => {

  const { user } = useAppSelector(state => state.currentUser);
  const { request, loading, working, error } = useAppSelector(state => state.leaveRequest);

  const [_open, _setOpen] = useState<boolean>(false);
  const [_notes, _setNotes] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  
  useEffect(() => {
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    leaveRequest &&
      leaveActions.getLeaveRequest(
        leaveRequest.staffMember.id,
        leaveRequest.id,
        data => {
          _setNotes(data.notes);

          if (user.id === data.approver.id && data.approverReadDate == null) {
            leaveActions.readLeaveRequest(data.staffMember.id, {
              ...data,
              staffMemberId: data.staffMember.id,
            });
          }
        }
      );
  }, [leaveRequest]);

  const updateApprover = (user: StaffListView) => {
    if (window.confirm("This will update the member of staff that needs to authorise this leave request. Are you sure?")) {
      var data: UpdateLeaveRequestApproverCommand = {
        staffMemberId: request.staffMember.id,
        holidayCardId: request.holidayCardId,
        id: request.id,
        approverId: user.id,
      }
      leaveActions.updateLeaveRequestApprover(request.staffMember.id, data, () => {
        onClose?.();
        ToastService.pop("Leave Request Updated", null, "plane-arrival");
      });
    }
  };

  return (
    <>
      <StaffModal
        open={_open}
        onClose={onClose}
        width="95%"
        height="95%"
        title="Leave Request"
        staffMember={request?.staffMember}
        footer={
          <LeaveRequestFooter 
            notes={_notes} 
            onClose={onClose} 
            setValidationErrors={setValidationErrors} 
          />
        }
        loading={loading}
        scrollOnErrors={validationErrors}
      >
        <>
        <Message color={Swatches.Danger} text={error} />
        <ValidationMessage errors={validationErrors} />
        {[ ApprovalStatus.Approved, ApprovalStatus.Confirmed, ApprovalStatus.Completed]
          .includes(request?.currentStatus?.status) &&
          user.id === request?.staffMember.id && (
            <HelpMessage
              icon="info-circle"
              sub="Approved leave can only be cancelled by your line manager or a member of HR"
              marginTop={Spacing.ExtraSmall}
              marginBottom={Spacing.ExtraSmall}
              padding={Spacing.Medium}
            />
          )}
        {!request ? (
          <EmptyMessage
            icon="plane"
            title="Not Found"
            summary="The requested leave request could not be found"
            cover
          />
        ) : (
          <Card title="Details">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Leave Type">
                  <LeaveRequestTypeChip type={request?.leaveRequestType} />
                </StructuredList.Item>
                <StructuredList.Item name="From">
                  <DateTime
                    date={request?.startDate}
                    bold
                  />
                </StructuredList.Item>
                <StructuredList.Item name="To">
                  <DateTime
                    date={request?.endDate}
                    bold
                  />
                </StructuredList.Item>
                <StructuredList.Item name="Duration">
                  <Label bold>{request?.duration}</Label>
                </StructuredList.Item>
                <StructuredList.Item name="Allowance">
                  {request?.leaveRequestType === LeaveRequestType.GoldenTime ? (
                    <Label>
                      <b>{request?.goldenTimeRemaining}</b>{" "}
                      {strings.pluralize(
                        "hour",
                        "hours",
                        request?.goldenTimeRemaining
                      )}{" "}
                      remaining
                    </Label>
                  ) : (
                    <Label>
                      <b>{request?.remaining}</b>{" "}
                      {strings.pluralize("day", "days", request?.remaining)}{" "}
                      remaining
                    </Label>
                  )}
                </StructuredList.Item>
                <StructuredList.Item name="Status">
                  <ApprovalStatusChip status={request.currentStatus?.status} />
                </StructuredList.Item>
                <StructuredList.Item name="Staff Reference">
                  <Label>{request.userRef}</Label>
                </StructuredList.Item>
                <StructuredList.Item
                  name="Cover"
                  description="Whether cover is required for this leave request."
                >
                  <CoverRequirementChip
                    coverRequirement={request.coverRequirement}
                  />
                </StructuredList.Item>

                {request.leaveRequestType !== LeaveRequestType.GoldenTime && (
                  <StructuredList.Item
                    name="Payment"
                    description="Whether unpaid leave has been requested."
                  >
                    {request.unpaidDays === 0 ? (
                      <Chip colorSwatch={Swatches.Success} text="Paid Leave" />
                    ) : (
                      <Chip colorSwatch={Swatches.Danger} text="Unpaid Leave" />
                    )}
                  </StructuredList.Item>
                )}
                {request.unpaidDays > 0 && (
                  <StructuredList.Item
                    name="Unpaid Leave"
                    description="The amount of unpaid leave requested."
                  >
                    <Label>
                      {request?.unpaidDays} day
                      {request?.unpaidDays > 1 && "s"}
                    </Label>
                  </StructuredList.Item>
                )}

                {request?.userRef && (
                  <StructuredList.Item name="Staff Reference">
                    <Label>{request?.userRef}</Label>
                  </StructuredList.Item>
                )}

                {request?.notes && (
                  <StructuredList.Item name="Notes">
                    <HtmlText html={request?.notes} />
                  </StructuredList.Item>
                )}

                <StructuredList.Item
                  name="Line Manager / Authoriser"
                  description="This is the staff member that the leave request has been sent to to approve."
                >
                  {request?.currentStatus?.status < 2 ||
                  request?.currentStatus?.status === 6 ? (
                    <StaffSelector
                      user={request?.approver}
                      onChange={user => updateApprover(user)}
                    />
                  ) : (
                    <Avatar user={request?.approver} />
                  )}
                </StructuredList.Item>

                <StatusView status={request?.currentStatus?.status}>
                  <StatusView.Item
                    status={ApprovalStatus.Cancelled}
                    inverse
                  >
                    <UserView user={request?.approver}>
                      <StructuredList.Item
                        name="Line Manager Notes"
                        description="These notes are for use when approving or rejecting a request.  They must be used to give a reason when rejecting a request."
                      >
                        <RichTextEditor onChange={value => _setNotes(value)} />
                      </StructuredList.Item>
                    </UserView>
                  </StatusView.Item>
                  <StatusView.Item></StatusView.Item>
                </StatusView>
              </StructuredList>
            </Card.Body>
          </Card>
        )}
      </>
      </StaffModal>
    </>
  );
}


export default LeaveRequestModal;