import React, { useState } from "react";
import {
  EmptyMessage,
  Swatches,
  Size,
  Loader,
  Spacing,
  Breakpoints,
} from "ui-kit";
import { arrays } from "utils";
import styled from "styled-components";
import { useSelector } from "react-redux";
import EventModal from "./eventModal";
import EventCard from "./eventCard";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { RootState } from "reducers/store";
import { PageInfo } from "types/common/paging.types";
import { CalendarEventAppListView } from "areas/calendar/types/calendarResponses.types";


const FeedWrapper = styled.div`
  // display: flex;
  // flex-wrap: wrap;
  // .card {
  //   width: 350px;
  //   margin: 0 ${Spacing.Small}px ${Spacing.Medium}px ${Spacing.Small}px;
  // }

  position: relative;

  @media screen and (min-width: 640px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0 ${Spacing.Large}px;
    grid-template-areas: ". ";

    .product-list-item {
    }
  }

  @media screen and (min-width: ${Breakpoints.s}px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ". .";
  }

  @media screen and (min-width: ${Breakpoints.l}px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: ". . .";
  }
`;


interface IEventsFeedProps {
  studentId?: string;
  loading?: boolean; 
  events: CalendarEventAppListView[];
  paging?: PageInfo;
  error?: string;
  onPage?: () => void;
  readonly?: boolean;
}


const EventsFeed: React.FC<IEventsFeedProps> = ({ studentId, loading, events, paging, error, onPage, readonly }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);

  const [selectedEvent, setSelectedEvent] = useState<CalendarEventAppListView | null>(null);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: paging?.pageNumber < paging?.totalPages,
    onLoadMore: onPage,
  });

  const handleEventClick = (event: CalendarEventAppListView) => {
    setSelectedEvent(event);
  };

  const handleModalClose = () => {
    setSelectedEvent(null);
  };

  // if (loading) {
  //   return <Loader size={Size.Large} cover />;
  // }

  if (arrays.isEmpty(events)) {
    return <EmptyMessage icon="calendar" title="No Events Found" cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem while loading your events"
        icon="times-circle"
        iconColor={Swatches.Danger.swatch}
        cover
      />
    );
  }

  return (
    <>
      <FeedWrapper>
        {events.map((event: CalendarEventAppListView, index: number) => (
          <EventCard
            event={event}
            key={index}
            onClick={(ev) => handleEventClick(ev)}
            readonly={readonly}
          />
        ))}
      </FeedWrapper>

      {(loading || (paging && paging.pageNumber < paging.totalPages)) && (
        <div ref={sentryRef}>
          <Loader size={Size.Medium} />
        </div>
      )}

      <EventModal
        userId={studentId ? studentId : user?.id}
        event={selectedEvent}
        onClose={handleModalClose}
        onCancel={handleModalClose}
        onSave={handleModalClose}
        open={selectedEvent != null}
        readonly={readonly}
      />
    </>
  );
};

export default EventsFeed;
