import React from "react";
import { Table } from "ui-kit";
import { Swatches } from "ui-kit/common/colors";
import LeaderboardPointsBar from "./leaderboardPointsBar";
import Avatar from "sharedComponents/common/users/avatar";
import BehaviourCountChip from "../behaviour/behaviourCountChip";
import { LeaderBoardBehaviourView } from "areas/planner/types/whiteboard/whiteboardResponse.types";
import { CategoryType } from "areas/analytics/types/analytics.shared";


interface ILeaderboardTableProps {
  leaderboardReport: LeaderBoardBehaviourView[];
  categoryTypeId: CategoryType;
}


const LeaderboardTable: React.FC<ILeaderboardTableProps> = ({ leaderboardReport, categoryTypeId }) => {

  return (
    <>
      {leaderboardReport && (
        <Table>
          <Table.Header>
            <Table.HeaderCell colspan={2}></Table.HeaderCell>
            <Table.HeaderCell colspan={7}></Table.HeaderCell>
            <Table.HeaderCell right>Total Points</Table.HeaderCell>
            {categoryTypeId === CategoryType.Behaviour && <Table.HeaderCell center>Behaviour Count</Table.HeaderCell>}
          </Table.Header>

          <>
          {leaderboardReport.map((leaderboardReportItem, index) => (
            <Table.Row key={index}>
              <Table.Cell colspan={2}>
                <Avatar
                  user={leaderboardReportItem.student}
                  sub={leaderboardReportItem.student.simsId}
                />
              </Table.Cell>
              <Table.Cell colspan={7} center>
                <LeaderboardPointsBar
                  highestPoints={leaderboardReport[0].totalPoints}
                  currentPointsValue={leaderboardReportItem.totalPoints}
                  color={
                    categoryTypeId === CategoryType.Behaviour
                      ? Swatches.Danger
                      : Swatches.Success
                  }
                />
              </Table.Cell>
              <Table.Cell right>
                <BehaviourCountChip
                  count={leaderboardReportItem.totalPoints}
                  color={
                    categoryTypeId === CategoryType.Behaviour
                      ? Swatches.Danger
                      : Swatches.Success
                  }
                />
              </Table.Cell>
              {categoryTypeId === CategoryType.Behaviour && <Table.Cell center>
                <BehaviourCountChip
                  count={leaderboardReportItem.totalBehaviourCount}
                  color={Swatches.Danger}
                />
              </Table.Cell>}
            </Table.Row>
          ))}
          </>
        </Table>
      )}
    </>
  );
};

export default LeaderboardTable;
