import moment from "moment";
import React from "react";
import styled from "styled-components";
import { Table, Loader, Size, EmptyMessage, DetailLabel, Label } from "ui-kit";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { useAppSelector } from "reducers/hooks";
import { StudentFeedListView } from "types/planner/feedResponse.types";


const Wrapper = styled.div`
  .table .table-body {
    min-height: 500px;
  }
`;


interface IYearGroupReportTableProps {
  handlePage: () => void;
  loading: boolean;
}


const YearGroupReportTable: React.FC<IYearGroupReportTableProps> = ({ handlePage, loading }) => {

  const { report, paging, error } = useAppSelector(state => state.yearGroupReport);

  const getGroupName = (activity: StudentFeedListView) => {
    if (activity.classGroup) {
      return activity.classGroup.name;
    } else if (activity.tutorGroup) {
      return activity.tutorGroup.name;
    } else if (activity.customGroup) {
      return activity.customGroup.name;
    } else if (activity.provisionGroup) {
      return activity.provisionGroup.name;
    } else {
      return "";
    }
  };

  if (loading) {
    return <Loader size={Size.Large} fluid />;
  }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return !report ? null : report?.length > 0 ? (
    <Wrapper>
      <Table grow>
        <Table.Header>
          <Table.HeaderCell width={0.9}>Created Date</Table.HeaderCell>
          <Table.HeaderCell width={1}>Created By</Table.HeaderCell>
          <Table.HeaderCell width={1}>Activity Type</Table.HeaderCell>
          <Table.HeaderCell width={1}>Category</Table.HeaderCell>
          <Table.HeaderCell width={0.9}>Class / Group</Table.HeaderCell>
          <Table.HeaderCell width={1}>{"Student(s)"}</Table.HeaderCell>
        </Table.Header>

        <Table.Body onPage={handlePage} paging={paging}>
          {report.map((activity, index) => (
            <Table.Row key={index}>
              <Table.Cell width={0.9}>
                <DetailLabel
                  label={moment(activity.createdDate).format("DD/MM/YYYY")}
                  bold
                />
              </Table.Cell>
              <Table.Cell width={1}>
                {activity.createdBy && (
                  <DetailLabel
                    label={`${activity.createdBy.firstName} ${activity.createdBy.lastName}`}
                    // sub={moment(activity.createdDate).format("DD/MM/YYYY")}
                    bold
                  />
                )}
              </Table.Cell>
              <Table.Cell width={1}>{activity.feedTypeName}</Table.Cell>
              <Table.Cell width={1}>{activity.categoryName}</Table.Cell>
              <Table.Cell width={0.9}>{getGroupName(activity)}</Table.Cell>
              <Table.Cell width={1}>
                <div>
                  {activity.students.map(student => (
                    <Label
                      bold
                    >{`${student.firstName} ${student.lastName}`}</Label>
                  ))}
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Wrapper>
  ) : (
    <EmptyMessage title="No Activities Found" icon="list" />
  );
};

export default YearGroupReportTable;
