import React from "react";
import styled from "styled-components";
import {
  Spacing,
  Icon,
  Size,
  fontStyle,
  displayFont,
  typescale,
  BorderRadius
} from "../../ui-kit";
import { blue, neutral, Swatches } from "../../ui-kit/common/colors";

const Wrapper = styled.a<{padding?: number, marginTop?: number, marginBottom?: number}>`
  display: flex;
  align-items: center;
  padding: ${props => props.padding ? `${props.padding}px` : `${Spacing.Large}px`};
  margin: ${props => props.marginTop ? `${props.marginTop}px` : `${Spacing.Large}px`} 0 ${props => props.marginBottom ? `${props.marginBottom}px` : `${Spacing.ExtraLarge}px`} 0;
  background: ${blue};
  border-radius: ${BorderRadius.Default}px;

  .title-sub {
    margin-left: ${Spacing.Medium}px;

    .title {
      ${fontStyle(displayFont.medium, typescale.header3, neutral[100])}
    }

    .sub {
      ${fontStyle(displayFont.roman, typescale.header4, neutral[200])}
    }
  }
`;

export interface IHelpMessageProps {
  icon?: string;
  title?: string;
  sub?: string;
  link?: string;
  padding?: number;
  marginTop?: number;
  marginBottom?: number;
}

const HelpMessage: React.FC<IHelpMessageProps> = ({ icon, title, sub, link, padding, marginTop, marginBottom }) => {
  return (
    <Wrapper href={link} target="_blank" padding={padding} marginTop={marginTop} marginBottom={marginBottom}>
      {icon && <Icon value={icon} size={Size.Large} color={neutral[100]} /> }
      <div className="title-sub">
        <div className="title">{title}</div>
        <div className="sub">{sub}</div>
      </div>
    </Wrapper>
  );
};

export default HelpMessage;
