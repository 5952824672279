import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router";
import ClassViewer from "./classViewer/classViewer";
import { Constants } from "configuration";
import GroupActivityWrapper from "./groupActivity/groupActivityWrapper";
import { TabBar } from "ui-kit";
import LeaderboardWrapper from "areas/analytics/components/leaderboard/leaderboardWrapper";
import ReportingWrapper from "./reporting/reportingWrapper";
import GroupStudentsWrapper from "./groupStudentlist/groupStudentsWrapper";
import { groupRoutes } from "../../plannerRoutes";
import groupActions from "../../actions/group/groupActions";
import CustomRoute from "sharedComponents/layout/routes/customRoute";
import Interventions from "./interventions";
import { CustomGroupCategory } from "./customGroups/customGroupCategoryDropdown";
import GroupDetails from "./groupDetails";
import { ClassGroupStaffView } from "types/users/userGroups.types";
import { useAppSelector } from "reducers/hooks";
import { GroupType } from "types/planner/groups.types";


const TABS = {
  activity: { key: 0, name: "activity", text: "Activity" },
  students: { key: 1, name: "students", text: "Students" },
  seatingPlan: { key: 2, name: "seatingplan", text: "Seating Plan" },
  leaderboard: { key: 3, name: "leaderboard", text: "Leaderboard" },
  reporting: { key: 4, name: "reporting", text: "Reporting" },
  interventions: { key: 5, name: "interventions", text: "Interventions" },
  details: { key: 6, name: "details", text: "Details" },
};


interface ISecondaryGroupTabsProps {
  group: ClassGroupStaffView;
}


const SecondaryGroupTabs: React.FC<ISecondaryGroupTabsProps> = ({ group }) => {

  const navigate = useNavigate();
  const { activeTab } = useAppSelector(state => state.groupTabMenu);

  useEffect(() => {
    if (group) {
      groupActions.selectGroup(group.id);
      if (!group.layouts || group.layouts.length === 0) {
        groupActions.setGroupActiveTab(TABS.students.key);
      } else {
        groupActions.setGroupActiveTab(TABS.seatingPlan.key);
      }
    }
  }, [group]);

  useEffect(() => {
    const groupId = group.id;
    const groupType = group.type;

    switch (activeTab) {
      case TABS.activity.key:
        navigate(`${groupRoutes.group.getActivityPath(groupId, groupType)}`);
        break;

      case TABS.students.key:
        navigate(`${groupRoutes.group.getStudentsPath(groupId, groupType)}`);
        break;

      case TABS.seatingPlan.key:
        navigate(`${groupRoutes.group.getSeatingPlanPath(groupId, groupType)}`);
        break;

      case TABS.leaderboard.key:
        navigate(`${groupRoutes.group.getLeaderboardPath(groupId, groupType)}`);
        break;

      case TABS.reporting.key:
        navigate(`${groupRoutes.group.getReportingPath(groupId, groupType)}`);
        break;

      case TABS.interventions.key:
        navigate(`${groupRoutes.group.getInterventionsPath(groupId, groupType)}`);
        break;

      case TABS.details.key:
        navigate(`${groupRoutes.group.getDetailsPath(groupId, groupType)}`);
        break;

      default:
        break;
    }
  }, [activeTab]);

  const handleTabClick = (name: string, index: number) => {
    if (activeTab !== index) {
      groupActions.setGroupActiveTab(index);
    }
  };

  return (
    <>
      <TabBar selectedIndex={activeTab} onItemClicked={handleTabClick}>
        <TabBar.Item
          positionIndex={TABS.activity.key}
          name={TABS.activity.name}
          text={TABS.activity.text}
        />

        <TabBar.Item
          positionIndex={TABS.students.key}
          name={TABS.students.name}
          text={TABS.students.text}
        />

        <TabBar.Item
          positionIndex={TABS.seatingPlan.key}
          name={TABS.seatingPlan.name}
          text={TABS.seatingPlan.text}
          hide={group?.school?.id === 99}
          // onFirstClick={seatingPlanTabClick}
        />

        <TabBar.Item
          positionIndex={TABS.leaderboard.key}
          name={TABS.leaderboard.name}
          text={TABS.leaderboard.text}
          hide={group.type === GroupType.Custom}
        />

        <TabBar.Item
          positionIndex={TABS.reporting.key}
          name={TABS.reporting.name}
          text={TABS.reporting.text}
          hide={group.type !== GroupType.ClassGroup}
        />

        <TabBar.Item
          positionIndex={TABS.interventions.key}
          name={TABS.interventions.name}
          text={TABS.interventions.text}
          hide={group.type !== GroupType.Custom || group.category !== CustomGroupCategory.Intervention}
        />

        <TabBar.Item
          positionIndex={TABS.details.key}
          name={TABS.details.name}
          text={TABS.details.text}
          hide={group.type !== GroupType.Custom}
        />
      </TabBar>

      <Routes>
        <Route
          path={groupRoutes.group.activity}
          element={
            <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
              <GroupActivityWrapper />
            </CustomRoute>
          }
        />

        <Route
          path={groupRoutes.group.students}
          element={
            <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
              <GroupStudentsWrapper />
            </CustomRoute>
          }
        />

        <Route
          path={groupRoutes.group.seatingPlan}
          element={
            <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
              <ClassViewer />
            </CustomRoute>
          }
        />

        <Route
          path={groupRoutes.group.leaderboard}
          element={
            <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
              <LeaderboardWrapper />
            </CustomRoute>
          }
        />

        <Route
          path={groupRoutes.group.interventions}
          element={
            <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
              <Interventions />
            </CustomRoute>
          }
        />

        <Route
          path={groupRoutes.group.details}
          element={
            <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
              <GroupDetails />
            </CustomRoute>
          }
        />

        <Route
          path={groupRoutes.group.reporting}
          element={
            <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
              <ReportingWrapper />
            </CustomRoute>
          }
        />
      </Routes>
    </>
  );
};

export default SecondaryGroupTabs;
