import { BulletinListView } from "areas/news/types/newsResponse.types";
import bulletinActions from "../../actions/bulletinActions";
import { IBeehiveAction } from "types/common/action.types";


interface IBulletinsState {
  loading: boolean;
  error: string | null;
  bulletins: BulletinListView[];
}

const INITIAL_STATE: IBulletinsState = {
  loading: false,
  error: null,
  bulletins: [],
};


const bulletinsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IBulletinsState => {

  const { BULLETINS_GET } = bulletinActions.types;

  switch (action.type) {
    case BULLETINS_GET.START:
      return { ...INITIAL_STATE, loading: true, error: null };

    case BULLETINS_GET.SUCCESS:
      var newState = { ...state, bulletins: action.payload, loading: false };
      return newState;

    case BULLETINS_GET.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default bulletinsReducer;
