import React from "react";
import styled from "styled-components";
import { IButtonProps } from "./button";
import { Spacing } from "../index";
import { AlignmentX } from "ui-kit/common/size";

const Wrapper = styled.div<{buttonAlignment?: AlignmentX}>`
  display: flex;

  ${({ buttonAlignment }) => buttonAlignment && `justify-content: ${buttonAlignment};`}

  .button {
    margin-right: ${Spacing.Default}px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export interface IButtonBarProps {
  children?: React.ReactElement<IButtonProps>[];
  className?: string;
  buttonAlignment?: AlignmentX;
}

const ButtonBar: React.FC<IButtonBarProps> = ({ children, className, buttonAlignment }) => {
  return (
    <Wrapper className={`button-bar ${className ? className : ""}`} buttonAlignment={buttonAlignment}>
      {children}
    </Wrapper>
  );
};

export default ButtonBar;
