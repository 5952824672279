import { Chip } from "ui-kit";
import styled from "styled-components";
import { success } from "ui-kit/common/colors";
import React from "react";


const Wrapper = styled.div`
  .active {
    background: ${success};
  }
`;


interface ISmartTaskStatusChipProps {
  active: boolean;
}


const SmartTaskStatusChip: React.FC<ISmartTaskStatusChipProps> = ({ active }) => {
  return (
    <Wrapper>
      <Chip
        className={`smart-task-chip ${active ? "active" : "inactive"}`}
        text={active ? "Active" : "Inactive"}
      />
    </Wrapper>
  );
};


export default SmartTaskStatusChip;
