import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import { Tabs } from "ui-kit";
import NewConsentFormList from "./newConsentFormList";
import CompletedConsentFormList from "./completedConsentFormList";
import CreatedConsentForms from "./createdConsentForms";
import AdminConsentForms from "./admin/consentForms";
import { users } from "utils";
import consentActions from "areas/administration/actions/consentActions";
import { RootState } from "reducers/store";

const ConsentForms = () => {
  const { user } = useSelector((state: RootState) => state.currentUser);
  const {
    forms: newForms,
    error: newFormsError,
    loading: newFormsLoading,
  } = useSelector((state: RootState) => state.newConsentForms);
  const {
    forms: completedForms,
    error: completedFormsError,
    loading: completedFormsLoading,
  } = useSelector((state: RootState) => state.completedConsentForms);
  const {
    forms: createdForms,
    error: createdFormsError,
    loading: createdFormsLoading,
  } = useSelector((state: RootState) => state.createdConsentForms);

  useEffect(() => {
    if (user) {
      if (user.userType == Constants.USER_TYPES.PARENT) {
        consentActions.getNewConsentFormsForParent(user.id);
        consentActions.getCompletedConsentFormsForParent(user.id);
      } else {
        consentActions.getNewConsentFormsForUser(user.id);
        consentActions.getCompletedConsentFormsForUser(user.id);
      }
      if (user.userType == Constants.USER_TYPES.STAFF) {
        consentActions.getConsentFormsForResponsibleStaff(user.id);
      }
    }
  }, [user]);

  return (
    <>
      <Tabs>
        <Tabs.Pane
          name="new"
          label="New Consent Forms"
          key={0}
          tooltip="New Consent Forms"
          tooltipSub="New consent forms that are waiting for you to complete"
        >
          <NewConsentFormList
            forms={newForms}
            loading={newFormsLoading}
            error={newFormsError}
          />
        </Tabs.Pane>
        <Tabs.Pane
          name="Completed Consent Forms"
          label="Completed Consent Forms"
          key={1}
          tooltip="Completed Consent Forms"
          tooltipSub="Previously completed consent forms that you can view or update"
        >
          <CompletedConsentFormList
            forms={completedForms}
            loading={completedFormsLoading}
            error={completedFormsError}
          />
        </Tabs.Pane>
        <Tabs.Pane
          name="Managed Consent Forms"
          label="Managed Consent Forms"
          key={2}
          hide={
            !users.isStaff(user) ||
            users.isInRole(user, Constants.ROLES.CONSENT_ADMINISTRATOR)
          }
          tooltip="Managed Forms"
          tooltipSub="Manage consent forms that you have created"
        >
          <CreatedConsentForms
            forms={createdForms}
            loading={createdFormsLoading}
            error={createdFormsError}
          />
        </Tabs.Pane>

        <Tabs.Pane
          name="Administration"
          label="Administration"
          key={2}
          hide={!users.isInRole(user, Constants.ROLES.CONSENT_ADMINISTRATOR)}
          tooltip="Administration"
          tooltipSub="Administration of consent forms"
        >
          <AdminConsentForms />
        </Tabs.Pane>
      </Tabs>
    </>
  );
};

export default ConsentForms;
