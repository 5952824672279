import React from "react";
import { TextInput, TextInputType } from "ui-kit";


interface IInternalNoteFormFieldProps {
  notes?: string;
  handleNoteChange: (value: string) => void;
}


const InternalNoteFormField: React.FC<IInternalNoteFormFieldProps> = ({ notes, handleNoteChange }) => {
  return (
    <TextInput
      type={TextInputType.Textarea}
      fluid
      placeholder="Internal Notes"
      rows={3}
      value={notes}
      onChange={handleNoteChange}
    />
  );
};

export default InternalNoteFormField;
