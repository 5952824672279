import storeActions from "areas/payments/actions/storeActions";
import { UserProductDetailLegacyView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


interface IStoreProductsState {
  product: UserProductDetailLegacyView;
  error: string | null;
  loading: boolean;
}

const INITIAL_STATE: IStoreProductsState = {
  product: null,
  error: null,
  loading: false
};


const storeProductReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IStoreProductsState => {

  const { 
    STORE_GETPRODUCT 
  } = storeActions.storeTypes;

  switch (action.type) {
    case STORE_GETPRODUCT.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case STORE_GETPRODUCT.SUCCESS:
      return { 
        ...state, 
        product: action.payload, 
        loading: false 
      };

    case STORE_GETPRODUCT.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default storeProductReducer;
