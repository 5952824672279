import { callbackType } from "types/common/action.types";
import client from "../../../services/client";
import action from "actions/action";
import { GroupListView } from "types/users/userGroups.types";
import actionTypeBuilder from "actions/actionTypeBuilder";


const builder = new actionTypeBuilder("tutorgroups");

const tutorGroupTypes = {
  TUTORGROUPS_GETBYSCHOOL: builder.build("TUTORGROUPS_GETBYSCHOOL"),
};


const getTutorGroupsBySchool = (schoolId: number, callback?: callbackType<GroupListView[]>) => 
  action<GroupListView[]>(
    () => client.get(`planner/schools/${schoolId}/tutorGroups`),
    tutorGroupTypes.TUTORGROUPS_GETBYSCHOOL,
    callback
  );


const tutorgroupService = {
  tutorGroupTypes,
  getTutorGroupsBySchool,
};

export default tutorgroupService;