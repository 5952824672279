import React from "react";
import { Checkbox, Icon, Size, Table, Text } from "ui-kit";
import styled from "styled-components";
import { blue, danger, honeygold, success } from "ui-kit/common/colors";
import { MEDIA_CONSENT } from "areas/planner/constants/student/consent";
import Avatar from "sharedComponents/common/users/avatar";
import DetentionTodayChip from "areas/behaviour/components/detentions/detentionTodayChip";
import ActiveOnCallChip from "areas/behaviour/components/onCall/activeOnCallChip";
import StudentSensitiveData from "areas/planner/components/studentRecord/studentSensitiveData/studentSensitiveData";
import { StudentListView } from "types/users/userListViews.types";
import { MediaConsentChoiceListView } from "types/users/userConsent.types";
import { ICheckboxClickEvent } from "ui-kit/forms/checkbox";


const CameraIconWrapper = styled.div`
  .icon {
    font-size: 1rem;
  }
`;


interface IGroupStudentsTableProps {
  handleStudentClick: (studentId: string) => void;
  selectedStudents: StudentListView[];
  handleStudentCheckBoxChange: (checked: ICheckboxClickEvent<null>, student: StudentListView) => void;
  groupIsPrimary?: boolean;
  students: StudentListView[];
}


const GroupStudentsTable: React.FC<IGroupStudentsTableProps> = ({
  handleStudentClick,
  selectedStudents,
  handleStudentCheckBoxChange,
  groupIsPrimary,
  students,
}) => {
  
  const getConsentIcon = (mediaConsent: number, mediaConsentChoices: MediaConsentChoiceListView[]) => {
    return (
      <CameraIconWrapper>
        {mediaConsent === MEDIA_CONSENT.REFUSED ? (
          <Icon
            value="camera"
            color={danger}
            size={Size.Medium}
            tooltip={"Media Consent Not Given"}
          />
        ) : mediaConsent === MEDIA_CONSENT.GIVEN ? (
          <Icon
            value="camera"
            color={success}
            size={Size.Medium}
            tooltip={"Media Consent Given"}
          />
        ) : mediaConsent === MEDIA_CONSENT.PARTIAL ? (
          <Icon
            value="camera"
            color={honeygold}
            size={Size.Medium}
            tooltip={"Partial Media Consent Given"}
            tooltipSub={
              mediaConsent === 2 &&
              mediaConsentChoices.map((consentChoice, index) => (
                <div key={index}>
                  <br />
                  {consentChoice.consentGiven ? (
                    <p>
                      <span className="tick" style={{ color: success }}>
                        <b>&#10004;</b>
                      </span>{" "}
                      {consentChoice.text}
                    </p>
                  ) : (
                    <p key={index}>
                      <span className="cross" style={{ color: danger }}>
                        <b>&#10006;</b>
                      </span>{" "}
                      {consentChoice.text}
                    </p>
                  )}
                </div>
              ))
            }
          />
        ) : null}
      </CameraIconWrapper>
    );
  };

  const isStudentSelected = (student: StudentListView) => {
    return selectedStudents.some(
      selectedStudent => selectedStudent.id === student.id
    );
  };

  return (
    <Table>
      <Table.Header>
        <Table.HeaderCell width={0.3}></Table.HeaderCell>
        <Table.HeaderCell width={1.2}>Student</Table.HeaderCell>
        <Table.HeaderCell width={0.2}></Table.HeaderCell>
        <Table.HeaderCell right width={0.8}>
          Rewards <br /> {`(Class / All)`}
        </Table.HeaderCell>
        <Table.HeaderCell right width={0.8}>
          Behaviours <br /> {`(Class / All)`}
        </Table.HeaderCell>
        <Table.HeaderCell
          right
          width={0.8}
        >{`Attendance (%)`}</Table.HeaderCell>
        <Table.HeaderCell right width={0.5}>
          Lates
        </Table.HeaderCell>
        <Table.HeaderCell right width={0.5}>
          Absences
        </Table.HeaderCell>
        <Table.HeaderCell right width={0.5}>
          Reading Age
        </Table.HeaderCell>
        {!groupIsPrimary && (
          <Table.HeaderCell center width={0.5}>
            Sanction Today
          </Table.HeaderCell>
        )}
        <Table.HeaderCell width={0.5}></Table.HeaderCell>
        <Table.HeaderCell width={0.3}></Table.HeaderCell>
        <Table.HeaderCell center width={1.2}></Table.HeaderCell>
      </Table.Header>

      <Table.Body>
        {students.map(student => (
          <Table.Row
            key={student.id}
            onClick={() => handleStudentClick(student.id)}
          >
            <Table.Cell right>
              <Text>
                <Checkbox
                  checked={isStudentSelected(student)}
                  onChange={checked =>
                    handleStudentCheckBoxChange(checked, student)
                  }
                />
              </Text>
            </Table.Cell>
            <Table.Cell>
              <Avatar
                user={student}
                sub={
                  student.tutorGroupName
                    ? `${student.simsId} | ${student.tutorGroupName}`
                    : student.simsId
                }
              />
            </Table.Cell>
            <Table.Cell right>
              <Text>
                {student.totalNotes > 0 && (
                  <Icon
                    value="sticky-note"
                    color={blue}
                    size={Size.Small}
                    tooltip="Notes"
                    tooltipSub="Click on this row to see notes for the student."
                  />
                )}
              </Text>
            </Table.Cell>
            <Table.Cell right>
              <Text>{`${student.rewardPoints} / ${student.totalRewardPoints}`}</Text>
            </Table.Cell>
            <Table.Cell right>
              <Text>{`${student.behaviourPoints} / ${student.totalBehaviourPoints}`}</Text>
            </Table.Cell>
            <Table.Cell right>
              <Text>{student.attendance}</Text>
            </Table.Cell>
            <Table.Cell right>
              <Text>{student.lates}</Text>
            </Table.Cell>
            <Table.Cell right>
              <Text>{student.absences}</Text>
            </Table.Cell>
            <Table.Cell right>
              <Text>{student.readingAge ? student.readingAge : null}</Text>
            </Table.Cell>
            {!groupIsPrimary && (
              <Table.Cell center>
                {student.todayDetention && <DetentionTodayChip />}
              </Table.Cell>
            )}
            <Table.Cell>
              {student.activeOnCall && <ActiveOnCallChip compact />}
            </Table.Cell>
            <Table.Cell>
              {getConsentIcon(
                student.mediaConsent,
                student.mediaConsentChoices
              )}
            </Table.Cell>
            <Table.Cell center>
              <StudentSensitiveData student={student} compact fluid />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default GroupStudentsTable;
