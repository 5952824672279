import { AssignmentQueryFilterType } from "areas/planner/types/assignments/assignmentShared.types";

export type AssignmentFilterType = {
  name: string;
  value: AssignmentQueryFilterType;
  tooltip: string;
  tooltipSub: string;
}

export type AssignmentFilterTypes = {
  all: AssignmentFilterType[],
  staffSelf: AssignmentFilterType[],
  staffAll: AssignmentFilterType[],
  students: AssignmentFilterType[]
}

const ASSIGNMENT_FILTER_TYPES: AssignmentFilterTypes = {
  all: [
    {
      name: "All",
      value: AssignmentQueryFilterType.All,
      tooltip: "All assignments",
      tooltipSub: "View all assignments"
    },
    {
      name: "Current",
      value: AssignmentQueryFilterType.Current,
      tooltip: "Current assignments",
      tooltipSub: "View assignments with a deadline in the future"
    }
  ],
  staffSelf: [
    {
      name: "Expired",
      value: AssignmentQueryFilterType.Expired,
      tooltip: "Expired Assignments",
      tooltipSub: "View assignments with a passed deadline"
    },
    {
      name: "Drafts",
      value: AssignmentQueryFilterType.Draft,
      tooltip: "Draft Assignments",
      tooltipSub: "View assignments that have not been published"
    }
  ],
  staffAll: [
    {
      name: "Expired",
      value: AssignmentQueryFilterType.Expired,
      tooltip: "Expired Assignments",
      tooltipSub: "View assignments with a passed deadline"
    }
  ],
  students: [
    {
      name: "Unread",
      value: AssignmentQueryFilterType.Unread,
      tooltip: "Unread Assignments",
      tooltipSub: "View new assignments you have not yet viewed"
    },
    {
      name: "Complete",
      value: AssignmentQueryFilterType.Complete,
      tooltip: "Completed Assignments",
      tooltipSub: "View assignments you have marked as complete"
    },
    {
      name: "Due Today",
      value: AssignmentQueryFilterType.DueToday,
      tooltip: "Assignments Due Today",
      tooltipSub: "View assignments that have a deadline of today"
    },
    {
      name: "Due Tomorrow",
      value: AssignmentQueryFilterType.DueTomorrow,
      tooltip: "Assignments Due Tomorrow",
      tooltipSub: "View assignments that have a deadline of tomorrow"
    },
    {
      name: "Late",
      value: AssignmentQueryFilterType.Overdue,
      tooltip: "Late Assignments",
      tooltipSub: "View assignments that you have missed the deadline for"
    }
  ]
};

export default ASSIGNMENT_FILTER_TYPES;
