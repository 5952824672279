import paymentActions from "areas/payments/actions/paymentsActions";
import { ProductListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface IManagedProductsState {
  products: ProductListView[],
  error: string | null;
  loading: boolean;
}

const INITIAL_STATE: IManagedProductsState = {
  products: [],
  error: null,
  loading: false
};


const managedProductsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IManagedProductsState => {

  const { 
    PAYMENTS_GETMANAGEDPRODUCTS 
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETMANAGEDPRODUCTS.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETMANAGEDPRODUCTS.SUCCESS:
      return { 
        ...state, 
        products: action.payload, 
        loading: false 
      };

    case PAYMENTS_GETMANAGEDPRODUCTS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default managedProductsReducer;
