import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ActionBar, Dropdown } from "ui-kit";
import { Constants } from "configuration";
import consentActions from "areas/administration/actions/consentActions";
import { RootState } from "reducers/store";


const ConsentFormFilter = () => {

  const { filter } = useSelector((state: RootState) => state.adminConsentForms);

  const handlePublishStatusChange = (formStatus: number) => {
    consentActions.updateConsentFormFilter({ ...filter, status: formStatus });
  };

  return (
    <ActionBar>
      <ConsentStatusFilter
        onChange={handlePublishStatusChange}
        initialValue={filter?.status}
      />
    </ActionBar>
  );
};


interface IConsentStatusFilterProps {
  onChange: (status: number) => void;
  initialValue?: number;
}

interface consentFormStatus {
  key: number | string;
  label: string;
  value: number;
}


const ConsentStatusFilter: React.FC<IConsentStatusFilterProps> = ({ onChange, initialValue }) => {

  const ALL_OPTION: consentFormStatus = { key: -1, label: "All Statuses", value: -1 };

  const [selectedValue, setSelectedValue] = useState<number>(-1);
  const [options, setOptions] = useState<consentFormStatus[]>(() => {
    var opts = [];

    for (var key in Constants.CONSENTFORM_STATUS) {
      opts.push({
        key: key,
        label: Constants.CONSENTFORM_STATUS[key].name,
        value: Constants.CONSENTFORM_STATUS[key].value,
      });
    }

    return [ALL_OPTION, ...opts];
  });

  const handleChange = (value: number) => {
    setSelectedValue(value);
    onChange?.(value);
  };

  useEffect(() => {
    const option = options.find(x => x.value === initialValue);

    setSelectedValue(option ? option.value : -1);
  }, [initialValue]);

  return (
    <Dropdown
      placeholder="Status"
      items={options}
      onChange={handleChange}
      value={selectedValue}
    />
  );
};

export default ConsentFormFilter;
