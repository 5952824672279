import React from "react";
import styled from "styled-components";
import { LandscapeWhiteLogo } from "sharedComponents/common/logos/beehive";
import { Breakpoints } from "ui-kit";
import LoginForm from "./loginForm";
import LoginIntro from "./loginIntro";

const getBackground = () => {
  return Math.floor(Math.random() * 20);
};


const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  background: #111 url("/images/bg/bg-${getBackground()}.jpg");
  background-position: center;
  background-size: cover;
  svg.logo {
    position: absolute;
    top: 4rem;
    left: 6rem;
    display: block;
    margin-bottom: 2rem;
    z-index: 999;
  }

  @media screen and (max-width: ${Breakpoints.xs}px) {
    svg.logo {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 2rem 2rem 2rem 6rem;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.75);

  @media screen and (max-width: ${Breakpoints.xs}px) {
    display: none;
  }
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  background: rgba(0, 0, 0, 0.65);
  padding: 2rem 4rem;

  @media screen and (max-width: ${Breakpoints.xs}px) {
    width: 100%;
    padding: 6rem 2rem 2rem 2rem;
  }
`;


const Login = () => {
  
  const handleClick = () => {};

  return (
    <Wrapper>
      <LandscapeWhiteLogo className="logo" width={200} />
      <LeftWrapper>
        <LoginIntro />
      </LeftWrapper>
      <RightWrapper>
        <LoginForm />
      </RightWrapper>
    </Wrapper>
  );
};

export default Login;
