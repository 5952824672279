import { IBeehiveAction } from "types/common/action.types";
import formActions from "../actions/formActions";
import { FormDetailView } from "../types/formResponse.types";


interface IFormState {
  form: FormDetailView | null;
  loading: boolean;
  error: string | null;
  saving: boolean;
}

const INITIAL_STATE: IFormState = {
  form: null,
  loading: false,
  error: null,
  saving: false,
};


const formReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IFormState => {

  const { GETFORM } = formActions.types;

  switch (action.type) {
    case GETFORM.START:
      return { ...state, loading: true };

    case GETFORM.SUCCESS:
      return {
        ...state,
        form: action.payload,
        loading: false,
      };

    case GETFORM.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default formReducer;
