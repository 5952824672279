import React, { useState } from "react";
import { ActionPanel, Swatches } from "ui-kit";
import { useParams } from "react-router-dom";
import RegisterModal from "areas/payments/components/catering/registerModal";


const LunchWrapper: React.FC = () => {

  const { groupId } = useParams();
  const [registerModalOpen, setRegisterModalOpen] = useState<boolean>(false);

  return (
    <>
      <ActionPanel
        label="Take Today's Lunch Register"
        sub="This allow you to take or edit today's lunch register for this class"
        buttonText="Take Lunch Register"
        buttonColor={Swatches.Primary}
        onClick={() => setRegisterModalOpen(true)}
      />
      <RegisterModal
        tutorGroupId={parseInt(groupId)}
        open={registerModalOpen}
        onClose={() => setRegisterModalOpen(false)}
        date={new Date()}
      />
    </>
  );
};

export default LunchWrapper;
