import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import FileDownload from "js-file-download";
import moment from "moment";
import { BeehiveReport, callbackType } from "types/common/action.types";
import { PagedQueryView } from "types/common/views.types";
import { StudentFeedListView } from "types/planner/feedResponse.types";
import { FeedType } from "types/planner/feedShared.types";
import { FeedFilter } from "types/planner/feedRequest.types";

const builder = new actionTypeBuilder("analytics");

const types = {
  GETYEARGROUPREPORT: builder.build("getyeargroupreport"),
  EXPORTYEARGROUPREPORT: builder.build("exportyeargroupreport"),
};


const getYearGroupReport = <T extends boolean = false>(
  yearGroupId: number,
  {
    startDate,
    endDate,
    createdDate: timeScale,
    feedType: activityType
  }: FeedFilter,
  pageIndex: number,
  exportReport?: T,
  onSuccess?: callbackType<T extends true ? BeehiveReport : PagedQueryView<StudentFeedListView>>
) => {

  var queryEndPoint: string;

  if (exportReport) {
    queryEndPoint = `planner/yearGroups/${yearGroupId}/feedExport?pageIndex=${0}&pageSize=10000&startDate=${moment(
      startDate
    ).format("YYYY-MM-DD")}`;
  } else {
    queryEndPoint = `planner/yearGroups/${yearGroupId}/feed?pageIndex=${pageIndex}&pageSize=50&startDate=${moment(
      startDate
    ).format("YYYY-MM-DD")}`;
  }

  if (endDate) {
    queryEndPoint =
      queryEndPoint + `&endDate=${moment(endDate).format("YYYY-MM-DD")}`;
  }

  if (timeScale !== null) {
    queryEndPoint = queryEndPoint + `&createdDate=${timeScale}`;
  }

  if (activityType !== null) {
    queryEndPoint = queryEndPoint + `&feedType=${activityType}`;
  }

  return exportReport
    ? action<BeehiveReport>(
        () => client.get(queryEndPoint, { responseType: "blob" }),
        types.EXPORTYEARGROUPREPORT,
        response => {
          onSuccess?.(response as any);
          FileDownload(response, "Year Group Report.csv");
        }
      )
    : action<PagedQueryView<StudentFeedListView>>(
        () => client.get(queryEndPoint),
        types.GETYEARGROUPREPORT,
        response => onSuccess?.(response as any)
      );
};


const yearGroupReportActions = { 
  types, 
  getYearGroupReport 
};

export default yearGroupReportActions;
