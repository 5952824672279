import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { DetentionHistoryFilter } from "areas/behaviour/types/detentionRegisterRequest.types";
import { DetentionDetailView } from "areas/behaviour/types/detentionRegisterResponse.types";
import client from "services/client";
import { callbackType } from "types/common/action.types";

const builder = new actionTypeBuilder("detention");

const types = {
  GETSTUDENTDETENTIONHISTORY: builder.build("getstudentdetentionhistory"),
};

const getStudentDetentionHistory = (
  studentId: string,
  {
    showCancelledDetentions,
    academicYearId
  }: DetentionHistoryFilter,
  onSuccess?: callbackType<DetentionDetailView[]>
) => {
  return action<DetentionDetailView[]>(
    () =>
      client.get(
        `planner/students/${studentId}/detentionHistory?showCancelledDetentions=${showCancelledDetentions}&academicYearId=${academicYearId}`
      ),
    types.GETSTUDENTDETENTIONHISTORY,
    onSuccess
  );
};

const studentDetentionHistoryActions = {
  types,
  getStudentDetentionHistory,
};

export default studentDetentionHistoryActions;
