import React, { useEffect, useState } from "react";
import { DetailLabel } from "ui-kit";
import Moment from "react-moment";
import moment from "moment";


interface IDateOfBirthProps {
  dateOfBirth: Date;
  bold?: boolean;
}

const DateOfBirth: React.FC<IDateOfBirthProps> = ({ dateOfBirth, bold }) => {

  const [_dateOfBirth, _setDateOfBirth] = useState<Date>(dateOfBirth);

  useEffect(() => {
    _setDateOfBirth(dateOfBirth);
  }, [dateOfBirth]);

  const getAge = () => {
    return moment().diff(_dateOfBirth, 'years');
  };

  if (!_dateOfBirth || !moment(_dateOfBirth).isValid()) {
    return null;
  }

  return (
    <DetailLabel
      bold={bold}
      label={<Moment date={_dateOfBirth} format="DD/MM/YYYY" />}
      sub={`${getAge()} Years old`}
    />
  );
};

export default DateOfBirth;
