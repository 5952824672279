
const omit = <T>(obj: T, key: keyof T) => {
  if (!obj) {
    return {};
  }
  const { [key]: omitted, ...rest } = obj;
  return rest;
}

const deepClone = <T>(obj: T) => {
  return JSON.parse(JSON.stringify(obj)) as T;
}

const methods = {
  omit,
  deepClone
}

export default methods;