import { forwardRef } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  neutral,
  Spacing,
  Tooltip,
  formatScrollbars,
  Breakpoints,
} from "ui-kit";
import Tippy from "@tippyjs/react";
import { arrays, users } from "utils";
import { navigation } from "configuration";
import NavigationItem from "./navigationItem";
import navigationActions from "actions/ui/navigation";
import { useWindowSize, useEventListener } from "hooks";
import { useEffect } from "react";
import { Constants } from "configuration";
import { RootState } from "reducers/store";

const Wrapper = styled.ul<{
  verticalOffset: number;
  mode: any;
  open: boolean;
  hovering: boolean;
}>`
  position: fixed;
  ${({ verticalOffset }) => verticalOffset && `top: ${verticalOffset}px;`}
  bottom: 0;
  background: ${neutral[200]};
  padding: ${Spacing.Medium}px;
  overflow: hidden;
  margin: 0;
  z-index: 600;
  border-right: 1px solid ${neutral[300]};
  transition: all ease 150ms;

  ${({ mode, open }) => {
    switch (mode) {
      case Constants.NAVIGATION_MODES.FULL:
        return `box-shadow: none; `;

      case Constants.NAVIGATION_MODES.ICONS:
        return `              
        .navigation-item-label {
          display: none;
        }
        
        &:hover {          
          box-shadow: 2px 0px 5px 2px rgba(0, 0, 0, 0.1); 
          .navigation-item-label {
            display: block;
          }          
        }        
        `;
      case Constants.NAVIGATION_MODES.HAMBURGER:
        return open
          ? `
          left: 0; 
          right: 200px; 
          background: white;
          box-shadow: 2px 0px 5px 2px rgba(0, 0, 0, 0.1);`
          : `left: -1000px;  `;
      default:
        return;
    }
  }}

  ${({ hovering }) =>
    hovering &&
    `
    overflow-y: auto;
    overflow-x: auto;  
  `}

  ${formatScrollbars(neutral[200])}
`;

interface INavigationItemProps {
  verticalOffset: number;
}

const Navigation = forwardRef(
  ({ verticalOffset }: INavigationItemProps, ref) => {
    const { user } = useSelector((state: RootState) => state.currentUser);
    const { hover, mode, open } = useSelector(
      (state: RootState) => state.navigation
    );
    const [windowWidth, windowHeight] = useWindowSize();

    useEffect(() => {
      if (open) {
        navigationActions.closeMenu();
      }
      if (windowWidth >= Breakpoints.l) {
        navigationActions.setMenuMode(Constants.NAVIGATION_MODES.FULL);
      } else if (windowWidth < Breakpoints.l && windowWidth > Breakpoints.xs) {
        navigationActions.setMenuMode(Constants.NAVIGATION_MODES.ICONS);
      } else {
        navigationActions.setMenuMode(Constants.NAVIGATION_MODES.HAMBURGER);
      }
    }, [windowWidth]);

    const handleKeyDown = (key: KeyboardEvent) => {
      if (open && key.key === "Escape") {
        navigationActions.toggleMenu();
      }
    };

    useEventListener("keydown", handleKeyDown);

    const handleItemClick = () => {
      navigationActions.closeMenu();
    };

    // console.log("CURRETY");
    // console.log(user?.admissionStatus === Constants.ADMISSION_STATUS.CURRENT.value);

    return (
      <Wrapper
        id="navigation"
        ref={ref as React.RefObject<HTMLUListElement>}
        verticalOffset={verticalOffset}
        onMouseEnter={navigationActions.toggleHoverMenu}
        onMouseLeave={navigationActions.toggleHoverMenu}
        hovering={hover}
        mode={mode}
        open={open}
      >
        { user && navigation(user)
          .filter(nav =>
            (nav.allowFuture === true || user?.admissionStatus === Constants.ADMISSION_STATUS.CURRENT.value) &&
            !nav.disabled &&
            (nav.users == null || arrays.isEmpty(nav.users) || (nav.users != null && users.isUserOfAnyType(user, nav.users))) &&
            (nav.roles == null || arrays.isEmpty(nav.roles) || (nav.roles != null && users.isInAnyRoles(user, nav.roles)))
          )
          .map((nav, index) => (
            <Tippy
              key={index}
              content={<Tooltip title={nav.name} sub={nav.tooltip} />}
            >
              <>
                <NavigationItem
                  name={nav.name}
                  route={nav.route}
                  icon={nav.icon}
                  onClick={handleItemClick}
                />
              </>
            </Tippy>
          ))}
      </Wrapper>
    );
  }
);

export default Navigation;
