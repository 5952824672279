import paymentActions from "areas/payments/actions/paymentsActions";
import { PaymentsSummaryReport } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface IAdminSummaryState {
  loading: boolean;
  error: null | string;
  summary: null | PaymentsSummaryReport;
}

const INITIAL_STATE: IAdminSummaryState = {
  loading: false,
  error: null,
  summary: null
};

const adminSummaryReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAdminSummaryState => {

  const { 
    PAYMENTS_GETSUMMARY 
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETSUMMARY.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETSUMMARY.SUCCESS:
      return { 
        ...state, 
        summary: action.payload, 
        loading: false 
      };

    case PAYMENTS_GETSUMMARY.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default adminSummaryReducer;
