import fileActions from "areas/filing/actions/fileActions";
import { IBeehiveAction } from "types/common/action.types";
import { ProcessStudentCsvResult } from "../types/fileResponse.types";


interface IIimportStudentCsvState {
  results: ProcessStudentCsvResult | null;
  uploading: boolean;
  error: string | null;
}

const INITIAL_STATE: IIimportStudentCsvState = {
  results: null,
  uploading: false,
  error: null,
};


const importStudentCsvReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IIimportStudentCsvState => {

  const { IMPORT_STUDENTCSV } = fileActions.types;

  switch (action.type) {
    case IMPORT_STUDENTCSV.START:
      return { ...INITIAL_STATE, uploading: true };
    case IMPORT_STUDENTCSV.SUCCESS:
      return { ...INITIAL_STATE, results: action.payload };
    case IMPORT_STUDENTCSV.FAILED:
      return { ...INITIAL_STATE, error: action.payload, uploading: false };
    default:
      return state;
  }
};

export default importStudentCsvReducer;
