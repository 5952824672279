import searchEngineActions from "areas/search/actions/searchEngineActions";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";
import { PagedQueryView, SearchResultView } from "types/common/views.types";
import { StaffListView } from "types/users/userListViews.types";

interface ISearchStaffState {
  staff: PagedQueryView<SearchResultView<StaffListView>> | null;
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: ISearchStaffState = {
  staff: null,
  loading: false,
  error: null,
};

const searchStaffReducer = (state = INITIAL_STATE, action: IBeehiveAction): ISearchStaffState => {

  const { SEARCH_STAFF, CLEAR_RESULTS } = searchEngineActions.types;

  switch (action.type) {

    case SEARCH_STAFF.START:
      // We are not using ...INITIAL_STATE here to avoid a the menu opening and closing between key strokes when using an autocomplete control
      return { ...state, error: null, loading: true };
    case SEARCH_STAFF.SUCCESS:
      return { ...state, loading: false, staff: action.payload };
    case SEARCH_STAFF.FAILED:
      return { ...state, loading: false, staff: null, error: action.payload };

    case CLEAR_RESULTS:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default searchStaffReducer;
