import React, { useEffect, useState } from "react";
import moment from "moment";
import LeaderboardTable from "./leaderboardTable";
import LeaderboardActionBar from "./leaderboardActionBar";
import { EmptyMessage, Loader, Size } from "ui-kit";
import { useParams } from "react-router";
import leaderboardReportActions from "areas/analytics/actions/leaderboardReportActions";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { useAppSelector } from "reducers/hooks";
import { CategoryType } from "areas/analytics/types/analytics.shared";


const LeaderboardWrapper = () => {

  const { groupId, groupType } = useParams();
  
  const { leaderboardReport, loading, error, refresh } = useAppSelector(
    state => state.groupLeaderboard
  );
  const { group } = useAppSelector(state => state.group);

  const [startDate, setStartDate] = useState<moment.Moment>(moment().day(1));
  const [endDate, setEndDate] = useState<moment.Moment>(moment().day(5));
  const [categoryTypeId, setCategoryTypeId] = useState<CategoryType>(CategoryType.Reward);

  useEffect(() => {
    if (group?.school?.isPrimary) {
      const today = moment();
      setStartDate(today);
      setEndDate(today);
    }
  }, [group]);

  const getReport = () => {
    leaderboardReportActions.getGroupLeaderboardReport(
      parseInt(groupId),
      {
        groupTypeId: parseInt(groupType),
        categoryTypeId: categoryTypeId,
        startDate: moment(startDate, ["DD/MM/YYYY"]).format("YYYY-MM-DD"),
        endDate: moment(endDate, ["DD/MM/YYYY"]).format("YYYY-MM-DD")
      }
    );
  };

  useEffect(() => {
    getReport();
  }, [categoryTypeId, endDate, groupId, groupType, startDate]);

  useEffect(() => {
    if (refresh) {
      getReport();
    }
  }, [refresh]);

  return (
    <>
      <LeaderboardActionBar
        startDate={startDate}
        handleSelectStartDate={date => setStartDate(date)}
        endDate={endDate}
        handleSelectEndDate={date => setEndDate(date)}
        categoryTypeId={categoryTypeId}
        handleSelectCategoryType={categoryType =>
          setCategoryTypeId(categoryType)
        }
      />
      {loading ? (
        <Loader size={Size.Medium} fluid />
      ) : error ? (
        <ApiExceptionMessage error={error} />
      ) : leaderboardReport?.length > 0 ? (
        <LeaderboardTable
          leaderboardReport={leaderboardReport}
          categoryTypeId={categoryTypeId}
        />
      ) : (
        <EmptyMessage title="No Students" icon="list" />
      )}
    </>
  );
};

export default LeaderboardWrapper;
