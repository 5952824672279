import React from "react";
import styled from "styled-components";
import renderHTML from "react-render-html";
import {
  typescale,
  textFont,
  neutral,
  Swatches,
  Spacing,
  fontStyle,
  fontSize,
} from "../index";

export interface IHtmlTextProps {
  html: string;
  fluid?: boolean;
  children?: any;
  className?: string;
  containerStyle?: React.CSSProperties;
  fontStyle?: string;
}

const Wrapper = styled.div<{ isFluid?: boolean, font?: string }>`

  ${({ font }) => font
    ? font
    : fontStyle(textFont.roman, typescale.paragraph, neutral[500])
  }

  ${({ isFluid }) => (isFluid ? `width: auto;` : `max-width: 40rem;`)}

  b,
  em,
  strong {
    font-weight: ${textFont.bold.weight};
  }

  a {
    color: ${Swatches.Primary.swatch};

    &:hover {
      color: ${Swatches.Primary.foreground};
      transition: color 500ms ease;
    }
  }

  p,
  ol,
  ul {
    ${({ font }) => font
      ? font
      : fontStyle(textFont.roman, typescale.paragraph, neutral[500])
    }
    margin-top: 0 !important;
    margin-bottom: ${typescale.paragraph.lineHeight}rem !important;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  ul {
    list-style-type: square;
  }

  ol,
  ul {
    margin-left: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: ${textFont.bold.weight};
    margin-top: 0;
  }

  h2,
  h3,
  h4 {
    margin-bottom: ${Spacing.Default}px;
  }

  h1 {
    ${fontSize(typescale.header1)};
    margin-bottom: ${Spacing.Large}px;
  }

  h2 {
    ${fontSize(typescale.header2)};
  }

  h3 {
    ${fontSize(typescale.header3)};
  }

  h4 {
    ${fontSize(typescale.header4)};
  }
`;

const HtmlText: React.FC<IHtmlTextProps> = ({
  html,
  children,
  fluid,
  className,
  containerStyle,
  fontStyle
}) => {
  return (
    <Wrapper
      className={`htmlText ${className ? className : ""}`}
      isFluid={fluid}
      style={containerStyle}
      font={fontStyle}
    >
      {html != null ? renderHTML(html) : null}
    </Wrapper>
  );
};

HtmlText.defaultProps = {
  fluid: false,
};

export default HtmlText;
