import groupInterventionsFilterReducer from "./groupInterventionsFilterReducer";
import groupInterventionsReducer from "./groupInterventionsReducer";
import interventionReducer from "./interventionReducer";
import interventionReportingReducer from "./interventionReportingReducer";
import studentInterventionsReducer from "./studentInterventionsReducer";


const interventions = {
  groupInterventions: groupInterventionsReducer,
  groupInterventionsFilter: groupInterventionsFilterReducer,
  intervention: interventionReducer,
  studentInterventions: studentInterventionsReducer,
  interventionReporting: interventionReportingReducer
};


export default interventions;
