import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router";
import styled from "styled-components";
import ReportList from "./reportList";
import AssignmentAnalyticsWrapper from "./assignment/assignmentAnalyticsWrapper";
import { users } from "utils";
import { Constants } from "configuration";
import BehaviourAnalyticsWrapper from "./behaviour/behaviourAnalyticsWrapper";
import AwardAnalyticsWrapper from "./award/awardAnalyticsWrapper";
// import PmAnalyticsWrapper from "./pm/pmAnalyticsWrapper";
import LeavingLessonAnalyticsWrapper from "./leavingLesson/leavingLessonAnalyticsWrapper";
import ManagedMovesReport from "./managedMoves/managedMovesReport";
import OnCallReportWrapper from "./onCall/onCallReportWrapper";
import YearGroupReportWrapper from "./yearGroup/yearGroupReportWrapper";
import SmartTaskReportWrapper from "./smartTasks/smartTaskReportWrapper";
import YearGroupDetentionReportWrapper from "./detentions/yearGroupDetentionReportWrapper";
import DetentionDetailedReportWrapper from "./detentions/detentionDetailedReportWrapper";
import BehaviourDetailedReportWrapper from "./behaviour/behaviourDetailed/behaviourDetailedReportWrapper";
import { analyticsRoutes } from "../analyticsRoutes";
import CustomRoute from "sharedComponents/layout/routes/customRoute";
import { useAppSelector } from "reducers/hooks";
import AssignedStaffAnalyticsWrapper from "./assignedStaff/assignedStaffAnalyticsWrapper";
import CoCurricularAnalytics from "./coCurricular/coCurricularAnalytics";


const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const ReportWrapper = styled.div`
  flex-grow: 2;
  margin-left: 250px;
  height: 100%;
`;


export interface AnalyticReport {
  key: number;
  text: string;
  name: string;
  hide?: Boolean;
}



const Analytics: React.FC = () => {

  const { user } = useAppSelector(state => state.currentUser);

  const availableReports: AnalyticReport[] = [
    { key: 0, text: "Assignments", name: "assignments" },
    { key: 1, text: "Awards", name: "awards" },
    {
      key: 2,
      text: "Behaviours and Rewards",
      name: "behaviour",
      hide: !users.isInAnyRoles(user, [Constants.ROLES.PLANNER_ANALYTICS]),
    },
    {
      key: 3,
      text: "Behaviour and Reward Points",
      name: "behaviourpoints",
    },
    {
      key: 4,
      text: "Detentions",
      name: "detentions",
      hide: !users.isInAnyRoles(user, [Constants.ROLES.PLANNER_ANALYTICS]),
    },
    { key: 5, text: "Leaving Lesson", name: "leavinglesson" },
    {
      key: 6,
      text: "Moves",
      name: "managedMoves",
      hide: !users.isInAnyRoles(user, [
        Constants.ROLES.LEADERSHIP,
        Constants.ROLES.TRUST_LEADERSHIP,
      ]),
    },
    { key: 7, text: "On Call", name: "oncall" },
    { key: 8, text: "Smart Tasks", name: "smarttasks" },
    {
      key: 9,
      text: "Year Group Detentions",
      name: "yeargroupdetentions",
    },
    { key: 10, text: "Year Group Activity", name: "yeargroupactivityfeed" },
    { key: 11, text: "Assigned Staff", name: "assignedstaff" },
    { key: 12, text: "CoCurricular Activity", name: "cocurricular" }
  ];

  const navigate = useNavigate();
  const [reports] = useState<AnalyticReport[]>(availableReports);
  const [selectedReport, setSelectedReport] = useState<AnalyticReport>(reports.sort((a, b) => a.text.localeCompare(b.text))[0]);

  useEffect(() => {
    navigate(`${analyticsRoutes.getReportPath(selectedReport.name)}`);
  }, []);

  const handleSelectReport = (report: AnalyticReport) => {
    navigate(`${analyticsRoutes.getReportPath(report.name)}`);
    setSelectedReport(report);
  };

  useEffect(() => {
    setSelectedReport(reports[0]);
  }, [reports]);

  return (
    <Wrapper>
      <ReportList
        reports={reports}
        handleSelectReport={handleSelectReport}
        selectedReport={selectedReport}
      />

      <ReportWrapper>
        <Routes>
          <Route
            path="/assignedStaff"
            element={
              <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
                <AssignedStaffAnalyticsWrapper />
              </CustomRoute>
            }
          />
          <Route
            path="/behaviourpoints"
            element={
              <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
                <BehaviourAnalyticsWrapper />
              </CustomRoute>
            }
          />
          <Route
            path="/behaviour"
            element={
              <CustomRoute
                users={[Constants.USER_TYPES.STAFF]}
                roles={[Constants.ROLES.PLANNER_ANALYTICS]}
              >
                <BehaviourDetailedReportWrapper />
              </CustomRoute>
            }
          />
          <Route
            path="/yeargroupdetentions"
            element={
              <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
                <YearGroupDetentionReportWrapper />
              </CustomRoute>
            }
          />
          <Route
            path="/detentions"
            element={
              <CustomRoute
                users={[Constants.USER_TYPES.STAFF]}
                roles={[Constants.ROLES.PLANNER_ANALYTICS]}
              >
                <DetentionDetailedReportWrapper />
              </CustomRoute>
            }
          />
          <Route
            path="/awards"
            element={
              <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
                <AwardAnalyticsWrapper />
              </CustomRoute>
            }
          />
          <Route
            path="/leavinglesson"
            element={
              <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
                <LeavingLessonAnalyticsWrapper />
              </CustomRoute>
            }
          />
          <Route
            path="/assignments"
            element={
              <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
                <AssignmentAnalyticsWrapper />
              </CustomRoute>
            }
          />
          <Route
            path="/onCall"
            element={
              <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
                <OnCallReportWrapper />
              </CustomRoute>
            }
          />
          <Route
            path="/yeargroupactivityfeed"
            element={
              <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
                <YearGroupReportWrapper />
              </CustomRoute>
            }
          />
          <Route
            path="/smartTasks"
            element={
              <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
                <SmartTaskReportWrapper />
              </CustomRoute>
            }
          />
          <Route
            path="/managedMoves"
            element={
              <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
                <ManagedMovesReport />
              </CustomRoute>
            }
          />
          <Route
            path="/cocurricular"
            element={
              <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
                <CoCurricularAnalytics />
              </CustomRoute>
            }
          />
        </Routes>
      </ReportWrapper>
    </Wrapper>
  );
};

export default Analytics;
