import { IBeehiveAction } from "types/common/action.types";
import leavingLessionReportActions from "../actions/leavingLessonReportActions";
import { IBeehiveError } from "types/common/errors.types";
import { GenerateLessonExitReportView } from "../types/lessonExitAnalyticsResponse.types";


interface ILeavingLessonAnalyticsState {
  report: GenerateLessonExitReportView | null;
  refresh: boolean;
  loading: boolean;
  loadingExportReport: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: ILeavingLessonAnalyticsState = {
  report: null,
  refresh: false,
  loading: false,
  loadingExportReport: false,
  error: null,
};


const leavingLessonAnalyticsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ILeavingLessonAnalyticsState => {

  const { 
    GETLEAVINGLESSONREPORT, 
    EXPORTLEAVINGLESSONREPORT 
  } = leavingLessionReportActions.types;

  switch (action.type) {
    case GETLEAVINGLESSONREPORT.START:
      return { ...state, loading: true, error: null };

    case GETLEAVINGLESSONREPORT.SUCCESS:
      return { ...state, report: action.payload, loading: false };

    case GETLEAVINGLESSONREPORT.FAILED:
      return { ...state, loading: false, error: action.payload };

    case EXPORTLEAVINGLESSONREPORT.START:
      return { ...state, loadingExportReport: true, error: null };

    case EXPORTLEAVINGLESSONREPORT.SUCCESS:
      return { ...state, loadingExportReport: false };

    case EXPORTLEAVINGLESSONREPORT.FAILED:
      return { ...state, loadingExportReport: false, error: action.payload };

    default:
      return state;
  }
};

export default leavingLessonAnalyticsReducer;
