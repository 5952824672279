export interface Route {
  [key: string]:
    | string
    | { [key: string]: string | ((houseId: number) => string) }
    | { private: { [key: string]: string }; public: { [key: string]: string } };
}

const routes = {
  home: "/main",
  authentication: {
    login: "/login",
  },
  passwords: {
    public: {
      forgotPassword: "/password/forgot",
      resetPassword: "/password/reset/:authenticationCode",
      checkPassword: "/password/check",
    },
    private: {
      changePassword: "/profile/security/changepassword",
      checkPassword: "/profile/security/checkpassword",
    },
  },
  registration: {
    default: "/register",
    registrationWizard: "/:authenticationCode",
    preflightError: "/error",
    preflightRegistered: "/registered",
    preflightUnavailable: "/unavailable",
  },
  fallback: {
    unauthorized: "/unauthorized",
  },
  catering: {
    default: "/catering",
    register: "/catering/register/:tutorGroupId",
  },
  checkout: {
    default: "/checkout",
    success: "/checkout/success",
  },
  classes: {
    default: "/classes",
  },
  covid: {
    default: "/covid",
  },
  forms: {
    default: "/forms",
  },
  houses: {
    default: "/houses",
    house: "/:houseId",
    getHousePath: (houseId: number) => `/${houseId}`,
  },
  links: {
    default: "/links",
  },
  mailout: {
    default: "/mailout",
  },
  payment: {
    cardnet: "/cardnet/payment/:userId",
  },
  planner: {
    default: "/planner/students",
    student: "/planner/students/:studentId/*",
  },
  profile: {
    details: "/profile/details",
    security: "/profile/security",
    notifications: "/profile/notifications",
    update: "/profile/update",
    updateUserProfile: "/profile/update/:userId",
    getUpdateUserProfilePath: (userId: string) =>
      `${routes.home}/profile/update/${userId}`,
  },
  provisions: {
    default: "/provisions",
  },
  send: {
    default: "/send",
    provider: "/send/providers/:providerId",
    provision: "/send/providers/:providerId/provisions/:provisionId",
    provisionGroup:
      "/send/providers/:providerId/provisions/:provisionId/groups/:provisionGroupId",
    parent: "/send/parents",
  },
  shopkeeper: {
    default: "/shopkeeper",
  },
  smartcard: {
    default: "/smartcard",
  },
  store: {
    default: "/store",
  },
  timetable: {
    default: "/timetable",
  },
};

export default routes;
