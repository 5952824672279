import searchEngineActions from "areas/search/actions/searchEngineActions";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";
import { PagedQueryView, SearchResultView, SearchResultViewBase } from "types/common/views.types";
import { GroupListView } from "types/users/userGroups.types";


interface ISearchGroupsState {
  groups: PagedQueryView<SearchResultView<GroupListView>> | null;
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: ISearchGroupsState = {
  groups: null,
  loading: false,
  error: null,
};


const searchGroupsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISearchGroupsState => {

  const { SEARCH_GROUPS, CLEAR_RESULTS } = searchEngineActions.types;

  switch (action.type) {

    case SEARCH_GROUPS.START:
      // We are not using ...INITIAL_STATE here to avoid a the menu opening and closing between key strokes when using an autocomplete control
      return { ...state, loading: true, error: null };
    case SEARCH_GROUPS.SUCCESS:
      return { ...state, loading: false, groups: action.payload };
    case SEARCH_GROUPS.FAILED:
      return { ...state, loading: false, groups: null, error: action.payload };

    case CLEAR_RESULTS:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default searchGroupsReducer;
