import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ClassLayoutWrapper from "./classLayout/classLayoutWrapper";
import ClassLayoutCreatorWrapper from "./classLayout/classLayoutCreatorWrapper";
import { Message, Swatches } from "ui-kit";

const ClassViewer = () => {
  const { group } = useSelector(state => state.group);
  const { user } = useSelector(state => state.currentUser);

  const [isLayoutCreatorOpen, setIsLayoutCreatorOpen] = useState(false);

  const handleOpenLayoutCreator = () => {
    setIsLayoutCreatorOpen(true);
  };

  if (!group.layouts || group.layouts.length === 0) {
    if (group.responsibleStaff.some(staff => staff.id === user.id)) {
      return group.isActive === false ? (
        <Message
          text="No seating plans have been created for the selected class. Seating plans can only be created for active groups."
          color={Swatches.Warning}
        />
      ) : (
        <>
          <Message
            text="No seating plans have been created for the selected class. You
                    can create a seating plan below."
            color={Swatches.Warning}
          />
          <ClassLayoutCreatorWrapper
            group={group}
            closeLayoutCreator={() => setIsLayoutCreatorOpen(false)}
          />
        </>
      );
    } else {
      return (
        <Message
          text="No seating plans have been created for the selected class. Seating plans can only be created by responsible staff members of the class."
          color={Swatches.Warning}
        />
      );
    }
  } else {
    return isLayoutCreatorOpen ? (
      <ClassLayoutCreatorWrapper
        group={group}
        closeLayoutCreator={() => setIsLayoutCreatorOpen(false)}
      />
    ) : (
      <ClassLayoutWrapper
        openLayoutCreator={roomId => handleOpenLayoutCreator(roomId)}
      />
    );
  }
};

export default ClassViewer;
