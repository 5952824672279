import defaults from "configuration/defaults";
import { arrays } from "utils";
import calendarActions from "../actions/calendarActions";
import { PageInfo } from "types/common/paging.types";
import { IBeehiveAction } from "types/common/action.types";
import { CalendarEventAppListView } from "../types/calendarResponses.types";


interface IStudentCalendarEventsState {
  events: CalendarEventAppListView[],
  error: string | null;
  loading: boolean;
  paging: PageInfo;
  signingUp: boolean;
  signupError: string | null;
}

const INITIAL_STATE: IStudentCalendarEventsState = {
  events: [],
  error: null,
  loading: false,
  paging: defaults.DEFAULT_PAGING,
  signingUp: false,
  signupError: null,
};

const studentCalendarEventsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IStudentCalendarEventsState => {
  const {
    CALENDAR_GETSTUDENTCALENDAREVENTS
  } = calendarActions.types;

  switch (action.type) {

    case CALENDAR_GETSTUDENTCALENDAREVENTS.START:
      return {
        ...state,
        loading: arrays.isEmpty(state.events),
        error: null,
      };

    case CALENDAR_GETSTUDENTCALENDAREVENTS.SUCCESS:
      return {
        ...state,
        events:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.events, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };

    case CALENDAR_GETSTUDENTCALENDAREVENTS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default studentCalendarEventsReducer;
