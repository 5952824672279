import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { arrays, dateTime, users } from "utils";
import {
  EmptyMessage,
  Loader,
  Size,
  Title,
  TitleSize,
  DateTime,
  Chip,
  Swatches,
  StructuredList,
  Card,
  Table,
  Label,
  Button,
  Tabs,
  Spacing,
  ChipList,
  SplitButton,
  ToastService,
  ActionBar,
  Dropdown,
  CopyToClipboard,
} from "ui-kit";
import {
  PublishStatus,
  Avatar,
  GroupList,
  SchoolIcon,
  ApiExceptionMessage,
} from "sharedComponents/common";
import { Constants } from "configuration";
import EventDateRegisterModal from "./eventDateRegisterModal";
import EventAttendees from "./eventAttendees";
import MenuList from "./menuList";
import calendarActions from "areas/calendar/actions/calendarActions";
import { eventRoutes } from "areas/calendar/calendarRoutes";
import Subheader from "sharedComponents/layout/header/subheader";
import { groupRoutes } from "areas/planner/plannerRoutes";
import { RootState } from "reducers/store";
import { CalendarEventDateListView } from "areas/calendar/types/calendarResponses.types";
import { SchoolListView } from "types/schools/schools.types";
import { GroupListView } from "types/users/userGroups.types";
import OkayModal from "ui-kit/modules/okayModal";
import moment from "moment";


const StaffWrapper = styled.div`
  margin-bottom: ${Spacing.Large}px;

  &:last-child {
    margin-bottom: 0;
  }
`;


enum RegisterDates {
  Past = 0,
  Future = 1,
}


const EventViewer = () => {

  let { eventId } = useParams();
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { calendarEvent, loading, error, deleting, deleteError } = useSelector((state: RootState) => state.calendarEvent);

  const [_selectedDate, _setSelectedDate] = useState<CalendarEventDateListView | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [registerFilter, setRegisterFilter] = useState<RegisterDates | null>(RegisterDates.Future);
  const [registers, setRegisters] = useState<CalendarEventDateListView[]>(calendarEvent?.dates);

  useEffect(() => {
    user && eventId && calendarActions.getCalendarEvent(user.id, parseInt(eventId), true);
  }, [eventId]);

  useEffect(() => {
    if (!calendarEvent) {
      return;
    }

    switch (registerFilter) {
      case RegisterDates.Future:
        setRegisters(calendarEvent?.dates?.filter(x => moment(x.ends).isAfter(moment())));
        break;
      case RegisterDates.Past:
        setRegisters(calendarEvent?.dates?.filter(x => moment(x.ends).isBefore(moment())));
        break;
      default:
        setRegisters(calendarEvent?.dates);
        break;
    }
  }, [registerFilter, calendarEvent]);

  const handleDelete = () => {
    if (!calendarEvent) {
      return;
    }
    calendarActions.deleteCalendarEvent(calendarEvent.id, () => {
      setDeleteModalOpen(false);
      ToastService.pop("Event Deleted", null, "calendar");
      navigate("../");
    });
  }

  const handleEdit = () => {
    navigate(eventRoutes.getEditPath(calendarEvent.id));
  };

  const handleViewRegister = (date: CalendarEventDateListView) => {
    _setSelectedDate(date);
  };

  const handleEventDateRegisterModalClosed = () => {
    _setSelectedDate(null);
  };

  const userCanEdit = () => {
    return (
      calendarEvent.createdBy.id == user.id ||
      calendarEvent.responsibleStaff.some(staff => staff.id == user.id) ||
      users.isInAnyRoles(user, [Constants.ROLES.DEVELOPMENT])
    );
  };

  const userCanDelete = () => {
    return (
      (calendarEvent.createdBy.id == user.id || users.isInAnyRoles(user, [Constants.ROLES.DEVELOPMENT])) &&
      !calendarEvent.dates.some(date => moment(date.ends).isBefore(moment()) && date.signups > 0)
    );
  }

  const filterRegister = (dateFilter: RegisterDates | null) => {
    setRegisterFilter(dateFilter);
  }

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem while loading the calendar event"
        cover
      />
    );
  }

  if (!calendarEvent) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="Not Found"
        summary="The requested calendar event was not found"
        cover
      />
    );
  }

  return (
    <>
      <Subheader>
        <Title
          size={TitleSize.H2}
          text="Events"
          sub={calendarEvent?.title}
          right={
            userCanEdit() && (
              <SplitButton
                color={Swatches.Primary}
                text="Edit Event"
                onDefaultClick={handleEdit}
                size={Size.Small}
              >
                <SplitButton.Option
                  onClick={handleEdit}
                  text="Edit Event"
                  show
                />
                <SplitButton.Option
                  onClick={() => setDeleteModalOpen(true)}
                  text="Delete Event"
                  color={Swatches.Danger}
                  show={userCanDelete()}
                />
                <></>
              </SplitButton>
            )
          }
        />
      </Subheader>

      {deleteError && (
        <ApiExceptionMessage error={deleteError} />
      )}

      <Tabs>
        <Tabs.Pane label="Details" name="Details">
          <Card title="Details">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Event Title">
                  <Label>{calendarEvent.title}</Label>
                </StructuredList.Item>
                <StructuredList.Item name="Category">
                  <Label>{calendarEvent.category.name}</Label>
                </StructuredList.Item>
                <StructuredList.Item name="Status">
                  <PublishStatus status={calendarEvent?.status} />
                </StructuredList.Item>
                <StructuredList.Item name="Dates">
                  <Label>{calendarEvent.dateCount}</Label>
                </StructuredList.Item>
                {/* <StructuredList.Item name="Next Date">
                  <DateTime date={calendarEvent.nextDate?.starts} bold />
                </StructuredList.Item> */}
                {/* <StructuredList.Item name="Ends">
                  <DateTime bold date={calendarEvent.endDate} />
                </StructuredList.Item> */}
                <StructuredList.Item name="Schools">
                  {calendarEvent.schools?.map((school: SchoolListView, index: number) => (
                    <>
                      <SchoolIcon key={index} code={school.code} />
                      <> </>
                    </>
                  ))}
                </StructuredList.Item>
                <StructuredList.Item name="For Users">
                  <ChipList>
                    {calendarEvent?.userRestrictions?.allowStaff && (
                      <Chip text="Staff" />
                    )}
                    {calendarEvent?.userRestrictions?.allowStudents && (
                      <Chip text="Students" />
                    )}
                    {calendarEvent?.userRestrictions?.allowParents && (
                      <Chip text="Parents" />
                    )}
                  </ChipList>
                </StructuredList.Item>
                <StructuredList.Item name="Open to Groups">
                  <GroupList
                    groups={calendarEvent.groups}
                    onGroupClick={group =>
                      navigate(groupRoutes.getGroupPath(group.id))
                    }
                  />
                </StructuredList.Item>
                {calendarEvent.isSticky && (
                  <StructuredList.Item name="Is Sticky">
                    <Chip text="Sticky" />
                  </StructuredList.Item>
                )}
                <StructuredList.Item name="Signup URL">
                  <>
                  {`https://beehive.lionhearttrust.org.uk/#/main/calendar/events/${calendarEvent.id}/signup`}
                  <CopyToClipboard text={`https://beehive.lionhearttrust.org.uk/#/main/calendar/events/${calendarEvent.id}/signup`} />
                  </>
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
          <Card title="Signup &amp; Consent">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Require Signup">
                  <Label>{calendarEvent.requireSignup ? "Yes" : "No"}</Label>
                </StructuredList.Item>
                {calendarEvent.requireSignup && (
                  <>
                    <StructuredList.Item name="Attendees Limit">
                      <Label> {calendarEvent.signupLimit}</Label>
                    </StructuredList.Item>
                    {calendarEvent.allowSignupFrom ? (
                      <StructuredList.Item name="Allow Signup From">
                        <DateTime date={calendarEvent.allowSignupFrom} />
                      </StructuredList.Item>
                    ) : null}
                    {/* <StructuredList.Item name="Reserve List">
                      <Label>
                        {calendarEvent.enableReserveList
                          ? "Reserve List Enabled"
                          : "No Reserve List"}
                      </Label>
                    </StructuredList.Item> */}
                  </>
                )}
                <StructuredList.Item name="Mandatory Attendance">
                  <Label>{calendarEvent.mandatoryAttendance ? "Yes" : "No"}</Label>
                </StructuredList.Item>
                {/* <StructuredList.Item name="Require Parental Consent">
                  <Label>
                    {calendarEvent.requireParentalConsent ? "Yes" : "No"}
                  </Label>
                </StructuredList.Item> */}
              </StructuredList>
            </Card.Body>
          </Card>

          {!arrays.isEmpty(calendarEvent.menu) && (
            <Card title="Menu">
              <Card.Body noPad>
                <MenuList menu={calendarEvent.menu} />
              </Card.Body>
            </Card>
          )}

          {!arrays.isEmpty(calendarEvent.responsibleStaff) && (
            <Card title="Responsible Staff">
              <Card.Body>
                {calendarEvent.responsibleStaff.map((staff, index) => (
                  <StaffWrapper key={index}>
                    <Avatar user={staff} />
                  </StaffWrapper>
                ))}
              </Card.Body>
            </Card>
          )}
        </Tabs.Pane>
        <Tabs.Pane label="Dates &amp; Registers" name="Dates">
          <ActionBar>
          <Dropdown
            value={registerFilter}
            onChange={value => filterRegister(value)}
          >
            <Dropdown.Item value={RegisterDates.Future} label="Upcoming" />
            <Dropdown.Item value={RegisterDates.Past} label="Past" />
            <Dropdown.Item value={null} label="All" />
          </Dropdown>
          </ActionBar>
          <Card>
            <Card.Body noPad>
              <Table zebra>
                <Table.Header>
                  <Table.HeaderCell width={2}>Starts</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Ends</Table.HeaderCell>
                  {(calendarEvent.requireSignup || calendarEvent.mandatoryAttendance) ? (
                    <Table.HeaderCell right></Table.HeaderCell>
                  ) : (
                    <Table.HeaderCell width={6} />
                  )}
                </Table.Header>
                <Table.Body>
                  {registers?.map((date, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>
                        <DateTime date={date.starts} bold />
                      </Table.Cell>
                      <Table.Cell>
                        <DateTime date={date.ends} bold />
                      </Table.Cell>
                      {(calendarEvent.requireSignup || calendarEvent.mandatoryAttendance) ? (
                        <Table.Cell right>
                          <Button
                            size={Size.Small}
                            text="Register"
                            color={Swatches.Low}
                            onClick={() => {
                              handleViewRegister(date);
                            }}
                          />
                        </Table.Cell>
                      ) : (
                        <Table.HeaderCell width={6} />
                      )}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card.Body>
          </Card>
          <EventDateRegisterModal
            eventDate={_selectedDate}
            open={_selectedDate != null}
            mandatoryAttendance={calendarEvent.mandatoryAttendance}
            onSave={handleEventDateRegisterModalClosed}
            onClose={handleEventDateRegisterModalClosed}
          />
        </Tabs.Pane>

        <Tabs.Pane label="Attendees" name="Attendees">
          <EventAttendees event={calendarEvent} />
        </Tabs.Pane>

        {/* <Tabs.Pane label="Reserve List" name="Reserve List">
          <AttendeeList
            event={calendarEvent}
            signups={calendarEvent.reserveList}
          />
        </Tabs.Pane> */}
      </Tabs>

      <OkayModal 
        open={deleteModalOpen}
        body={
          <>
          <p>Warning. You are about to permamently delete the "{calendarEvent.title}" event. </p>
          <p>This cannot be undone. Are you sure?"</p>
          </>
        }
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={() => handleDelete()}
        working={deleting}
      />
    </>
  );
};

export default EventViewer;
