import React, { useEffect, useMemo, useState } from "react";
import styled, { FlattenSimpleInterpolation } from "styled-components";
import { Avatar } from "sharedComponents/common";
import { Button, Size, Swatches, Spacing } from "ui-kit";
import StaffSearch from "./staffSearch";
import { StaffListView } from "types/users/userListViews.types";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  .staff-selector-left {
    flex-grow: 1;
    margin-right: ${Spacing.Small}px;
  }

  .staff-selector-right {
    .button:last-child {
      margin-left: ${Spacing.Small}px;
    }
  }
`;

interface IStaffSelectorProps {
  user?: any;
  onChange?: (user: StaffListView) => void;
  onCancel?: () => void;
  working?: boolean;
  schools?: number[];
  buttonText?: string;
  buttonStyle?: React.CSSProperties;
  buttonCss?: FlattenSimpleInterpolation;
}

const StaffSelector: React.FC<IStaffSelectorProps> = ({
  user,
  onChange,
  onCancel,
  working,
  schools,
  buttonText,
  buttonStyle,
  buttonCss
}) => {
  const [editMode, setEditMode] = useState(false);
  const [_user, _setUser] = useState(null);

  useEffect(() => {
    _setUser(user);
  }, [user]);

  const handleCancel = () => {
    setEditMode(false);
    onCancel?.();
  };

  const handleChange = (user: StaffListView) => {
    setEditMode(false);
    onChange?.(user);
  };

  const Editor = () => {
    return (
      <>
        <div className="staff-selector-left">
          <StaffSearch fluid onChange={handleChange} schools={schools}/>
        </div>
        <div className="staff-selector-right">
          <Button
            onClick={handleCancel}
            size={Size.Small}
            color={Swatches.Low}
            text={buttonText || "Cancel"}
            working={working}
            style={buttonStyle}
            css={buttonCss}
          />
        </div>
      </>
    );
  };

  const Static = () => {
    return (
      <>
        <div className="staff-selector-left">
          <Avatar user={_user} />
        </div>
        <div className="staff-selector-right">
          <Button
            onClick={() => {
              onCancel?.();
              setEditMode(true);
            }}
            size={Size.Small}
            color={Swatches.Low}
            text="Change"
            working={working}
          />
        </div>
      </>
    );
  };

  const selectorComponent = useMemo(() => {
    return (editMode || !_user) 
      ? <Editor />  
      : <Static />;
  }, [editMode, _user]);
  
  return (
    <Wrapper>
      {(editMode || !_user) 
        ? <Editor />  
        : <Static />
      }
    </Wrapper>
  )
};

export default StaffSelector;
