import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import { DetentionTypeView } from "areas/behaviour/types/behaviourResponses.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


interface IDefaultDetentionState {
  defaultDetention: DetentionTypeView;
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IDefaultDetentionState = {
  defaultDetention: null,
  loading: false,
  error: null,
};

const { GETDEFAULTDETENTION } = behaviourActions.types;

const defaultDetentionReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IDefaultDetentionState => {

  switch (action.type) {
    case GETDEFAULTDETENTION.START:
      return { ...state, loading: true, error: null };

    case GETDEFAULTDETENTION.SUCCESS:
      return {
        ...state,
        loading: false,
        defaultDetention: action.payload,
      };

    case GETDEFAULTDETENTION.FAILED:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default defaultDetentionReducer;
