import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Icon,
  displayFont,
  Spacing,
  typescale,
  ClassNames,
  neutral,
  honeygold,
  BorderRadius,
  fontStyle,
  Speed,
} from "../index";

export interface ICheckboxProps<TValue> {
  name?: string;
  text?: string;
  value?: TValue;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (value: ICheckboxClickEvent<TValue>) => void;
}

export interface ICheckboxClickEvent<TValue> {
  value?: TValue;
  checked: boolean;
}

const Wrapper = styled.div`
  display: flex;
  ${fontStyle(displayFont.medium, typescale.paragraph, neutral[600])}
  padding: ${Spacing.Default}px 0;
  align-items: center;

  label {
    display: flex;
    align-items: flex-start;

    input {
      margin-right: ${Spacing.Default}px;
      display: none;
    }
  }

  &.disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }
`;

const CheckboxIconWrapper = styled.span<{ ticked?: boolean }>`
  background: ${neutral[400]};
  padding: ${Spacing.Small}px;
  border-radius: ${BorderRadius.Default}px;
  margin-right: ${Spacing.Default}px;

  .icon {
    display: flex;
    align-items: center;
    opacity: 0;
  }

  ${({ ticked }) =>
    ticked &&
    `
    background: ${honeygold};
    .icon {
      opacity: 1;
      color: ${neutral[700]};
      transition: all ease ${Speed.Short}ms;
    }`}
`;

const Checkbox = <TValue = null>({
  text,
  value,
  checked,
  name,
  disabled,
  onChange,
  className,
}: ICheckboxProps<TValue>) => {
  const [ticked, setTicked] = useState<boolean>(checked);

  useEffect(() => {
    setTicked(checked);
  }, [checked]);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!disabled) {
      const newValue = !ticked;

      setTicked(newValue);
      onChange && onChange({ value, checked: newValue });
    }
  };

  const getProps = () => {
    var props = {};

    props = { ...props, ...{ name } };
    props = { ...props, ...{ text } };
    props = { ...props, ...(value ? { value } : {}) };
    props = { ...props, ...(disabled ? { disabled } : {}) };

    return props;
  };

  const getCssClassName = () => {
    var css = [className];

    if (disabled) {
      css.push(ClassNames.disabled);
    }

    return css.join(" ");
  };

  return (
    <Wrapper
      className={`${getCssClassName()} ${className ? className : ""}`}
      onClick={e => handleClick(e)}
    >
      <label htmlFor={name}>
        <CheckboxIconWrapper ticked={ticked}>
          <Icon value="check" />
        </CheckboxIconWrapper>
        <div>{text}</div>
        <input type="checkbox" {...getProps()} checked={ticked} readOnly />
      </label>
    </Wrapper>
  );
};

Checkbox.defaultProps = {
  checked: false,
};

export default Checkbox;
