import React from "react";
import { Tabs } from "ui-kit";
import Intray from "./intray";
import Outtray from "./outtray";


const Filing = () => {
  
  return (
    <Tabs>
      <Tabs.Pane
        label="In Tray"
        tooltip="In Tray"
        tooltipSub="Your in tray contains items waiting for your attention"
      >
        <Intray />
      </Tabs.Pane>
      <Tabs.Pane
        label="Out Tray"
        tooltip="Out Tray"
        tooltipSub="Your out tray contains items you have submitted"
      >
        <Outtray />
      </Tabs.Pane>
    </Tabs>
  );
};

export default Filing;
