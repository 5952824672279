import React, { useEffect, useState } from "react";
import { Dropdown, Message, Swatches } from "ui-kit";
import { useSelector } from "react-redux";
import studentActions from "areas/planner/actions/student/studentActions";
import { getGroupSub } from "areas/planner/utils/group";

const noGroup = {
  id: -1,
  label: "No Group",
  sub: "Is being given outside of class",
  value: -1,
};

const StudentClassesDropdown = ({
  studentId,
  selectedClass,
  onClassChange,
  showNoGroupMessage,
}) => {
  const { studentClasses, loading, error } = useSelector(
    state => state.studentClasses
  );

  const [studentClassesArray, setStudentClassesArray] = useState([]);

  useEffect(() => {
    if (studentId) {
      studentActions.getStudentClasses(studentId);
    }
  }, [studentId]);

  useEffect(() => {
    let classes = [];
    if (studentClasses && studentClasses.length > 0) {
      classes = studentClasses?.map(studentClass => ({
        key: studentClass.id,
        label: studentClass.friendlyName,
        sub: getGroupSub(studentClass),
        value: studentClass.id,
      }));
    }
    classes.unshift(noGroup);
    setStudentClassesArray(classes);
  }, [studentClasses]);

  const handleClassChange = value => {
    if (value === -1) {
      onClassChange?.(noGroup);
    } else {
      const selected = studentClasses.find(c => c.id === value);
      onClassChange?.(selected);
    }
  };

  return (
    <>
      {showNoGroupMessage && !selectedClass && (
        <Message
          text="No class or group has been chosen. You can choose a class or group below, or to explicitly award the point without a group, choose 'No Group'."
          color={Swatches.Primary}
        />
      )}
      <Dropdown
        items={studentClassesArray}
        fluid
        value={selectedClass?.id}
        onChange={handleClassChange}
        error={error}
        loading={loading}
      />
    </>
  );
};

export default StudentClassesDropdown;
