import { ProviderListView } from "areas/send/types/passportResponse.types";
import providerActions from "../../actions/providers/providerActions";
import { IBeehiveAction } from "types/common/action.types";

interface IProviders {
  providers: ProviderListView[];
  loading: boolean;
  saving: boolean;
  error: string | null;
}

const INITIAL_STATE: IProviders = {
  providers: [],
  loading: false,
  saving: false,
  error: null,
};


const providersReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IProviders => {

  const { PROVIDERS_GET, PROVIDER_CREATE, PROVIDER_SAVE, PROVIDER_DELETE } = providerActions.types;

  switch (action.type) {
    case PROVIDERS_GET.START:
      return { ...state, loading: true };
    case PROVIDERS_GET.SUCCESS:
      return { ...state, loading: false, providers: action.payload };
    case PROVIDERS_GET.FAILED:
      return { ...state, loading: false, error: action.payload };

    case PROVIDER_CREATE.SUCCESS:
      return {
        ...state,
        providers: [action.payload, ...state.providers],
      };

    case PROVIDER_SAVE.SUCCESS:
      return {
        ...state,
        providers: state.providers.map(a =>
          a.id === action.payload.id ? action.payload : a
        ),
      };

    case PROVIDER_DELETE.SUCCESS:
      return {
        ...state,
        providers: state.providers.filter(a => a.id !== action.payload),
      };
    default:
      return state;
  }
};

export default providersReducer;
