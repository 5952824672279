import React, { useEffect } from "react";
import { Tabs } from "ui-kit";
import { useSelector } from "react-redux";
import ManagedStaff from "./managedStaff";
import ReviewList from "./reviews/reviewList";
import ReviewPeriodMessage from "./reviewPeriodMessage";
import { Constants } from "configuration";
import { users } from "utils";
import staffMemberActions from "areas/humanResources/actions/staffMemberActions";
import PmAnalyticsWrapper from "areas/analytics/components/pm/pmAnalyticsWrapper";
import { RootState } from "reducers/store";


const Dashboard = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { currentPmUser, error, loading } = useSelector((state: RootState) => state.currentPmUser);

  useEffect(() => {
    user && !currentPmUser && staffMemberActions.getCurrentPmUser(user.id);
  }, []);

  return (
    <>
      <ReviewPeriodMessage />
      <Tabs>
        <Tabs.Pane label="My Reviews">
          <ReviewList
            loading={loading}
            error={error}
            reviews={currentPmUser?.reviews}
          />
        </Tabs.Pane>
        <Tabs.Pane label="My Staff">
          <ManagedStaff />
        </Tabs.Pane>
        <Tabs.Pane
          label="Reporting"
          hide={!users.isInRole(user, Constants.ROLES.HR_ADMINISTRATOR)}
        >
          <PmAnalyticsWrapper />
        </Tabs.Pane>
      </Tabs>
    </>
  );
};

export default Dashboard;
