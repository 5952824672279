import action from "actions/action";
import { ToastService } from "ui-kit";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import store from "reducers/store";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { SchoolSmartTaskListView, SchoolSmartTaskView } from "areas/behaviour/types/smartTasks/smartTaskResponse.types";
import { SaveSmartTaskCommand } from "areas/behaviour/types/smartTasks/smartTaskRequest.types";

const builder = new actionTypeBuilder("smarttask");

const types = {
  GETSMARTTASKS: builder.build("getsmarttasks"),
  SELECTSMARTTASK: "selectsmarttask",
  RESETSELECTEDSMARTTASK: "cancelsmarttaskupdatecreate",
  GETSMARTTASK: builder.build("getsmarttask"),
  NEWSMARTTASK: "newsmarttask",
  CREATESMARTTASK: builder.build("createsmarttask"),
  UPDATESMARTTASK: builder.build("updatesmarttask"),
  DELETESMARTTASK: builder.build("deletesmarttask"),
};


const getSmartTasks = (schoolId?: number, onSuccess?: callbackType<SchoolSmartTaskListView[]>) => {
  return action<SchoolSmartTaskListView[]>(
    () => client.get(`planner/schools/${schoolId}/smartTasks`),
    types.GETSMARTTASKS,
    onSuccess
  );
};

const selectSmartTask = (smartTaskId: number) =>
  store.dispatch<IBeehiveAction<number>>({
    type: types.SELECTSMARTTASK,
    payload: smartTaskId,
  });

const resetSelectedSmartTask = () =>
  store.dispatch<IBeehiveAction<null>>({
    type: types.RESETSELECTEDSMARTTASK,
  });

const getSmartTask = (schoolId: number, smartTaskId: number, onSuccess?: callbackType<SchoolSmartTaskView>) => {
  return action<SchoolSmartTaskView>(
    () => client.get(`planner/schools/${schoolId}/smartTasks/${smartTaskId}/smartTask`),
    types.GETSMARTTASK,
    onSuccess
  );
};

const newSmartTask = () => {
  store.dispatch<IBeehiveAction<null>>({
    type: types.NEWSMARTTASK,
  });
};

const createSmartTask = (schoolId: number, smartTask: SaveSmartTaskCommand, onSuccess?: callbackType<null>) => {
  return action<null>(
    () => client.post(`planner/schools/${schoolId}/smartTask`, smartTask),
    types.CREATESMARTTASK,
    response => {
      ToastService.pop("Smart task successfully claimed", null, "check-circle");
      onSuccess?.(response);
    }
  );
};

const updateSmartTask = (schoolId: number, smartTaskId: number, smartTask: SaveSmartTaskCommand, onSuccess?: callbackType<number>) => {
  return action<number>(
    () =>
      client.post(
        `planner/schools/${schoolId}/smartTasks/${smartTaskId}/update`,
        smartTask
      ),
    types.UPDATESMARTTASK,
    response => {
      ToastService.pop("Smart task successfully updated", null, "check-circle");
      onSuccess?.(response);
    }
  );
};

const deleteSmartTask = (schoolId: number, smartTaskId: number, onSuccess?: callbackType<null>) => {
  return action<null>(
    () =>
      client.delete(`planner/schools/${schoolId}/smartTasks/${smartTaskId}`),
    types.DELETESMARTTASK,
    response => {
      ToastService.pop("Smart task successfully deleted", null, "check-circle");
      onSuccess?.(response);
    }
  );
};


const smartTaskActions = {
  types,
  getSmartTasks,
  selectSmartTask,
  resetSelectedSmartTask,
  getSmartTask,
  newSmartTask,
  createSmartTask,
  updateSmartTask,
  deleteSmartTask,
};

export default smartTaskActions;
