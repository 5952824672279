import { IBeehiveAction } from "types/common/action.types";
import consentActions from "../../actions/consentActions";
import { ConsentFormUserView } from "areas/administration/types/consentResponses.types";

interface INewConsentFormsState {
  loading: boolean;
  forms: ConsentFormUserView[],
  error: string | null;
  count: number;
}

const CONSENT_STATE: INewConsentFormsState = {
  loading: false,
  forms: [],
  error: null,
  count: 0,
};


const newConsentFormsReducer = (state = CONSENT_STATE, action: IBeehiveAction) : INewConsentFormsState => {

  const {
    CONSENT_GETNEWFORMSFORUSER,
    CONSENT_GETNEWFORMSFORPARENT,
    CONSENT_CHECKNEWFORMSFORUSER,
  } = consentActions.types;

  switch (action.type) {
    case CONSENT_CHECKNEWFORMSFORUSER.SUCCESS:
      return { ...state, count: action.payload.count };
    case CONSENT_GETNEWFORMSFORUSER.START:
    case CONSENT_GETNEWFORMSFORPARENT.START:
      return { ...state, loading: true, error: null };
    case CONSENT_GETNEWFORMSFORUSER.SUCCESS:
    case CONSENT_GETNEWFORMSFORPARENT.SUCCESS:
      return { ...state, loading: false, forms: action.payload, error: null };
    case CONSENT_GETNEWFORMSFORUSER.FAILED:
    case CONSENT_GETNEWFORMSFORPARENT.FAILED:
      return {
        ...state,
        loading: false,
        forms: [],
        error: "There was a problem loading the consent forms",
      };

    default:
      return state;
  }
};

export default newConsentFormsReducer;
