import React, { useState } from "react";
import FileDropArea from "./fileDropArea";
import fileActions from "../../../areas/filing/actions/fileActions";
import { IFileMetadata } from "types/common/files.types";
import { AxiosProgressEvent } from "axios";


interface IFileUploadProps {
  onUpload: (files: IFileMetadata[]) => void;
  single?: boolean;
}

const FileUpload: React.FC<IFileUploadProps> = ({ onUpload, single }) => {

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [validFiles, setValidFiles] = useState<File[]>([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState<File[]>([]);
  const [uploadPercent, setUploadPercent] = useState<string>("");

  const validTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "text/csv",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "video/mp4",
    "application/mp4",
  ];

  const validateFile = (file: File) => {
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const getExtension = (filename: string) => {
    return filename
      .substring(filename.lastIndexOf("."), filename.length)
      ?.toLowerCase();
  };

  const processUpload = (files: FileList) => {
    let selectedFiles: File[] = [];
    let filteredFiles: File[] = [];

    if (single && files.length > 1) {
      alert("Only one file can be uploaded.");
      return;
    }

    // Check validity of files
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        selectedFiles.push(files[i]);
      } else {
        alert("Unsupported file type");
        return;
      }
    }

    // Check if file is duplicate
    // if (single) {
    //   var duplicate = selectedFiles.find((item) => item.name === files[0].name);
    //   if (!duplicate?.length) {
    //     filteredFiles = [...selectedFiles];
    //   }
    // } else {
    filteredFiles = selectedFiles.reduce((file: File[], current: File) => {
      const x = file.find(item => item.name === current.name);
      if (!x) {
        return file.concat([current]);
      } else {
        return file;
      }
    }, []);

    // Upload
    const formData = new FormData();

    for (let i = 0; i < filteredFiles.length; i++) {
      formData.append("file", filteredFiles[i]);
    }

    fileActions.uploadFiles(formData, (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
          const uploadPercentage = Math.floor(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setUploadPercent(`${uploadPercentage}%`);
        }
      },
      (data: number[]) => {
        var uploadedFiles: IFileMetadata[] = [];

        for (var i = 0; i < data.length; i++) {
          uploadedFiles.push({
            id: data[i],
            name: filteredFiles[i].name,
            size: filteredFiles[i].size,
            mimeType: filteredFiles[i].type,
            extension: getExtension(filteredFiles[i].name),
            filename: filteredFiles[i].name,
          });
        }

        onUpload(uploadedFiles);

        filteredFiles.length = 0;

        // reset the width of the progress bar to avoid a jump on re-uploading
        setUploadPercent("0%");

        setValidFiles([...filteredFiles]);
        setSelectedFiles([...filteredFiles]);
        setUnsupportedFiles([...filteredFiles]);
      }
    );
  };

  return (
    <FileDropArea onDrop={processUpload} uploadPercentage={uploadPercent} />
  );
};

export default FileUpload;
