import React from "react";
import { Chip, Swatches } from "ui-kit";
import { Constants } from "configuration";


interface IPublishStatusChipProps {
  status: number;
}

const PublishStatusChip: React.FC<IPublishStatusChipProps> = ({ status }) => {

  var swatch = null;
  var name = "";

  switch (status) {
    case Constants.PUBLISH_STATUS.DRAFT.value:
      swatch = Swatches.Low;
      name = Constants.PUBLISH_STATUS.DRAFT.name;
      break;
    case Constants.PUBLISH_STATUS.PENDING.value:
      swatch = Swatches.Warning;
      name = Constants.PUBLISH_STATUS.PENDING.name;
      break;
    case Constants.PUBLISH_STATUS.LIVE.value:
      swatch = Swatches.Success;
      name = Constants.PUBLISH_STATUS.LIVE.name;
      break;
    case Constants.PUBLISH_STATUS.PAUSED.value:
      swatch = Swatches.Low;
      name = Constants.PUBLISH_STATUS.PAUSED.name;
      break;
    case Constants.PUBLISH_STATUS.CANCELLED.value:
      swatch = Swatches.Cancel;
      name = Constants.PUBLISH_STATUS.CANCELLED.name;
      break;
    default:
      return null;
  }

  return <Chip text={name} colorSwatch={swatch} />;
};

export default PublishStatusChip;
