import React from "react";
import { Avatar } from "sharedComponents/common";
import { Card, HtmlText, EmptyMessage } from "ui-kit";
import moment from "moment";
import { arrays } from "utils";
import { SubmissionNoteView } from "areas/forms/types/formResponse.types";


interface ISubmissionNoteListProps {
  notes: SubmissionNoteView[];
}


const SubmissionNoteList: React.FC<ISubmissionNoteListProps> = ({ notes }) => {

  if (arrays.isEmpty(notes)) {
    return (
      <EmptyMessage
        icon="comment-lines"
        title="No notes found"
        summary="No notes have been added for this submission yet"
        cover
      />
    );
  }

  return (
    <ul>
      {notes.map((note: SubmissionNoteView, index: number) => (
        <SubmissionNoteListItem key={index} note={note} />
      ))}
    </ul>
  );
};

const SubmissionNoteListItem = ({ note } : { note: SubmissionNoteView }) => {
  return (
    <li>
      <Card>
        <Card.Header>
          <Avatar
            user={note.createdBy}
            sub={moment(note.createdDate).format("DD/MM/YYYY h:mm a")}
          />
        </Card.Header>
        <Card.Body>
          <HtmlText html={note.content} />
        </Card.Body>
      </Card>
    </li>
  );
};

export default SubmissionNoteList;
