import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import FileDownload from "js-file-download";
import moment from "moment";
import client from "services/client";
import { BeehiveReport, callbackType } from "types/common/action.types";
import { PagedQueryView } from "types/common/views.types";
import { BehaviourRewardReportFilter, GenerateBehaviourReportCommand } from "../types/behaviourAnalyticsRequest.types";
import { GenerateBehaviourReportView, BehaviourRewardsDetailReportListView } from "../types/behaviourAnalyticsResponse.types";


const builder = new actionTypeBuilder("behaviourAnalytics");

const types = {
  GETBEHAVIOURREPORT: builder.build("getbehaviourreport"),
  EXPORTBEHAVIOURREPORT: builder.build("exportbehaviourreport"),
  GETBEHAVIOURDETAILEDREPORT: builder.build("getbehaviourdetailedreport"),
  EXPORTBEHAVIOURDETAILEDREPORT: builder.build("exportbehaviourdetailedreport"),
};


const getBehaviourReport = (payload: GenerateBehaviourReportCommand, onSuccess?: callbackType<GenerateBehaviourReportView>) => {
  return action<GenerateBehaviourReportView>(
    () => client.post(`planner/reporting/behaviours`, payload),
    types.GETBEHAVIOURREPORT,
    onSuccess
  );
};

const exportBehaviourReport = (payload: GenerateBehaviourReportCommand, onSuccess?: callbackType<BeehiveReport>) => {
  return action<BeehiveReport>(
    () =>
      client.post(`planner/reporting/behaviours/export`, payload, {
        responseType: "blob",
      }),
    types.EXPORTBEHAVIOURREPORT,
    response => {
      onSuccess && onSuccess(response);
      FileDownload(response, "Behaviour Report.csv");
    }
  );
};

const getBehaviourDetailedReport = <T extends boolean = false>(
  {
    schoolId,
    startDate,
    endDate,
    categoryTypeId,
    academicYearId,
  }: BehaviourRewardReportFilter,
  pageIndex: number,
  exportReport?: T,
  onSuccess?: callbackType<T extends true ? BeehiveReport : PagedQueryView<BehaviourRewardsDetailReportListView>>
) => {

  var url: string;

  if (exportReport) {
    url = `planner/reporting/behaviourRewardsDetailReportExport?SchoolId=${schoolId}&academicYearId=${academicYearId}&categoryTypeId=${categoryTypeId}`;
  } else {
    url = `planner/reporting/behaviourRewardsDetailReport?SchoolId=${schoolId}&academicYearId=${academicYearId}&categoryTypeId=${categoryTypeId}&pageIndex=${pageIndex}&pageSize=50`;
  }

  if (startDate) {
    url = url + `&startDate=${moment(startDate).format("YYYY-MM-DD")}`;
  }

  if (endDate) {
    url = url + `&endDate=${moment(endDate).format("YYYY-MM-DD")}`;
  }

  return exportReport
    ? action<BeehiveReport>(
        () => client.get(url, { responseType: "blob" }),
        types.EXPORTBEHAVIOURDETAILEDREPORT,
        response => {
          onSuccess?.(response as any);
          FileDownload(response, "Behaviour Detailed Report.csv");
        }
      )
    : action<PagedQueryView<BehaviourRewardsDetailReportListView>>(
        () => client.get(url),
        types.GETBEHAVIOURDETAILEDREPORT,
        response => onSuccess?.(response as any)
      );
};

const behaviourAnalyticsActions = {
  types,
  getBehaviourReport,
  exportBehaviourReport,
  getBehaviourDetailedReport,
};

export default behaviourAnalyticsActions;
