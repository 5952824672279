import React, { useEffect } from "react";
import { Loader, Size, EmptyMessage } from "ui-kit";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import StaffAssignmentView from "./staffAssignmentView";
import assignmentActions from "areas/planner/actions/assignments/assignmentActions";
import { useAppSelector } from "reducers/hooks";


const StaffAssignment: React.FC = () => {

  let { id } = useParams();
  const { user } = useAppSelector(state => state.currentUser);
  const { publishing } = useAppSelector(state => state.assignmentPublish);
  const { assignment, loading, error } = useAppSelector(state => state.assignment);

  useEffect(() => {
    if (id) {
      assignmentActions.getAssignment(user.id, parseInt(id));
    }
  }, [id, user]);

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem while loading the assignment"
        cover
      />
    );
  }

  if (!assignment) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="Not Found"
        summary="The requested assignment was not found"
        cover
      />
    );
  }

  return (
    <StaffAssignmentView assignment={assignment} publishing={publishing} />
  );
};

export default StaffAssignment;
