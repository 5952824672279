import React from "react";
import { Size, Spacing, Loader } from "ui-kit";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Categories from "./categories";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import PrimaryModal from "sharedComponents/common/primary/primaryModal";
import { useAppSelector } from "reducers/hooks";
import { UserTinyView } from "types/users/userListViews.types";
import { SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";
import { BehaviourIncludeType } from "areas/analytics/types/behaviourAnalyticsShared.types";
import { CategoryType } from "areas/analytics/types/analytics.shared";


const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${Spacing.Medium}px 0;
`;


interface IBehaviourModalProps {
  openBehaviourModal: boolean;
  handleCloseBehaviourModal: () => void;
  students: UserTinyView[];
  schoolId: number;
  handleAddReward: (category: SchoolBehaviourCategoryListView) => void;
  handleAddBehaviour: (category: SchoolBehaviourCategoryListView) => void;
}


const BehaviourModal: React.FC<IBehaviourModalProps> = ({
  openBehaviourModal,
  handleCloseBehaviourModal,
  students,
  schoolId,
  handleAddReward,
  handleAddBehaviour,
}) => {

  const { addRewardError, loading: loadingAddReward } = useAppSelector(
    state => state.reward
  );
  const { addBehaviourError, loading: loadingAddBehaviour } = useAppSelector(
    state => state.behaviour
  );
  const { loading: loadingAddMultipleRewards, error: addMultipleRewardsError } =
  useAppSelector(state => state.rewardForMultiple);
  const {
    error: addMultipleBehavioursError,
    loading: loadingAddMultipleBehaviours,
  } = useAppSelector(state => state.behaviourForMultipleStudents);

  const errorMessages = () => {
    return (
      <>
        <ApiExceptionMessage error={addRewardError} />
        <ApiExceptionMessage error={addBehaviourError} />
        <ApiExceptionMessage error={addMultipleRewardsError} />
        <ApiExceptionMessage error={addMultipleBehavioursError} />
      </>
    );
  };

  return (
    <PrimaryModal open={openBehaviourModal} onClose={handleCloseBehaviourModal}>
      {/* <PrimaryModal.Header>
        <AvatarWrapper>
          <PrimaryAvatar user={student} size={Size.Large} portrait />
        </AvatarWrapper>
      </PrimaryModal.Header> */}

      <PrimaryModal.Body>
        {loadingAddReward ||
        loadingAddBehaviour ||
        loadingAddMultipleRewards ||
        loadingAddMultipleBehaviours ? (
          <Loader size={Size.Large} cover />
        ) : (
          <>
            {errorMessages()}
            {/* <Tabs>
              <Tabs.Pane label="Rewards" tooltip="Rewards"> */}
            <Categories
              schoolId={schoolId}
              handleAddPoint={handleAddReward}
              categoryType={CategoryType.Reward}
            />
            {/* </Tabs.Pane>

              <Tabs.Pane label="Behaviours" tooltip="Behaviours">
                <Categories
                  schoolId={schoolId}
                  handleAddPoint={handleAddBehaviour}
                  categoryType={BehaviourIncludeType.Behaviour}
                />
              </Tabs.Pane>
            </Tabs> */}
          </>
        )}
      </PrimaryModal.Body>
    </PrimaryModal>
  );
};

export default BehaviourModal;
