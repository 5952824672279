import { useEffect, useRef, useState } from "react";
import { Modal, Button, Right, ActionBar, Swatches, Size, Table, Icon, ValidationMessage, ButtonBar, displayFont, fontStyle, neutral, typescale, Tooltip, getFontSize, Spacing } from "ui-kit";
import FileDropArea from "../fileDropArea";
import { arrays, files } from "utils";
import groupActions from "areas/planner/actions/group/groupActions";
import { UserTinyView } from "types/users/userListViews.types";
import CustomGroupStudentList from "areas/planner/components/groups/customGroups/customGroupStudentList";
import { StudentNotFoundView, StudentSchemaSearchView, StudentSimsSearchView } from "areas/planner/types/groups/groupStudentResponses.types";
import AlertModal from "ui-kit/modules/alertModal";
import styled from "styled-components";
import HelpText from "sharedComponents/common/helpText";
import Avatar from "sharedComponents/common/users/avatar";
import Tippy from "@tippyjs/react";
import { danger } from "ui-kit/common/colors";
import React from "react";
import OkayModal from "ui-kit/modules/okayModal";
import { ImportStudentCommand } from "areas/planner/types/groups/groupStudentRequest.types";


const ButtonBarWrapper = styled.div`
  .button-bar {
    justify-content: center;
    width: 100%;
  }
`;

const RemoveButton = styled.button`
  align-self: flex-start;
  font-size: ${getFontSize(Size.ExtraLarge)}rem;
  background-color: unset;
  border: none;
  margin: 0;
  padding: 0;
  color: ${neutral[500]};
  cursor: pointer;
  line-height: 1rem;

  &:hover {
    //   font-weight: bolder;
    color: ${danger};
  }
`;

const NotFound = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const CheckWrapper = styled.div`
  .icon:first-child {
    margin-right: ${Spacing.Default}px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-bottom: ${Spacing.Large}px;
`;


const DefaultStudentImportObj: ImportStudentCommand = {
  simsId: null,
  firstName: null,
  lastName: null,
}
type StudentImportSchemaKeys = Array<keyof ImportStudentCommand>;
const schemaHeaders: StudentImportSchemaKeys = Object.keys(DefaultStudentImportObj) as StudentImportSchemaKeys;

interface IImportStudentsModalProps {
  open: boolean;
  schoolId: number;
  onCancel?: () => void;
  onImported?: (students: UserTinyView[]) => void;
}


const ImportStudentsModal: React.FC<IImportStudentsModalProps> = ({
  open,
  schoolId,
  onCancel,
  onImported,
}) => {

  const ref = useRef<HTMLButtonElement>();
  const [_open, _setOpen] = useState<boolean>(false);
  const [students, setStudents] = useState<UserTinyView[]>([]);
  const [notFound, setNotFound] = useState<StudentNotFoundView[]>([]);
  const [schemaError, setSchemaError] = useState<boolean>(false);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

 
  const validateSchema = (data: string, delim: string) => {
    const _headers = data
      .slice(0,data.indexOf('\n'))
      .replace(/(\r\n|\n|\r)/gm,"")
      .toLowerCase()
      .split(delim);
    return (JSON.stringify(_headers) == JSON.stringify(schemaHeaders.map(s => s.toLowerCase())));
  }

  const processCSV = (data: string) => {
    const delim: string = ',';
    if (!validateSchema(data, delim)) {
     //alert("The provided CSV does not conform to the required schema");
     setSchemaError(true);
     return;
    }
    const rows = data
      .slice(data.indexOf('\n')+1)
      .split('\n')
      .filter(r => r); //filter empty rows
    const importCommands = rows.map(row => {
      const values = row.split(delim);
      const eachObject = schemaHeaders.reduce((student: ImportStudentCommand, header: keyof ImportStudentCommand, i: number) => {
        student[header] = values[i].replace(/(\r\n|\n|\r)/gm, "");
        return student;
      }, {
        simsId: null,
        firstName: null,
        lastName: null
      })
      return eachObject;
    });
    groupActions.getByImport(
      schoolId, 
      importCommands,
      (data: StudentSchemaSearchView) => {
        if (data.notFound.length > 0) {
          setNotFound(arrays.removeDuplicates([...notFound, ...data.notFound]));
        }
        setStudents(arrays.removeDuplicates([...students, ...data.foundUsers]))
      }
    )
  }

  const addFilesToProcessList = (files: FileList) => {
    for (var i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        processCSV(data as string);
      }
      reader.readAsText(files[i]);
    }
  };

  const handleRemove = (studentId: string) => {
    const temp = [...students].filter(x => x.id !== studentId);
    setStudents(temp);
  }

  const handleCancel = () => {
    setStudents([]);
    setNotFound([]);
    onCancel?.();
  }

  const handleSave = () => {
    onImported?.(students);
    setStudents([]);
    setNotFound([]);
  }

  const downloadExampleSchema = () => {
    const headers = `${schemaHeaders},\n`;
    const exampleRow = "0001, John, Doe";
    files.openCsv(headers + exampleRow, "ExampleFormat");
  }

  const handleDeclineSugestion = (notFoundStudent: StudentNotFoundView, declinedMatch: UserTinyView) => {
    const temp = [...notFound].map(x =>
      x === notFoundStudent 
        ? { ...x, possibleMatches: x.possibleMatches.filter(m => m !== declinedMatch) } 
        : x
    );
    setNotFound(temp);
  }

  const handleAcceptSuggestion = (notFoundStudent: StudentNotFoundView, acceptedMatch: UserTinyView) => {
    const temp = [...notFound].filter(x => x !== notFoundStudent);
    setNotFound(temp);
    setStudents(arrays.removeDuplicates([...students, acceptedMatch]))
  }

  return (
    <>
    <Modal
      title="Import Students"
      open={_open}
      onClose={handleCancel}
      width="85%"
      height="80%"
    >
      <Modal.Body>
        <HelpText style={{ marginBottom: Spacing.Small }}>
          Here you can upload a CSV of students to add to the group.
          Students that cannot be found will have their SIMS IDs listed.
        </HelpText>
        <ButtonWrapper>
          <Button
            text="Download Example"
            color={Swatches.Low}
            size={Size.Small}
            onClick={downloadExampleSchema}
          />
        </ButtonWrapper>
        <FileDropArea 
          onDrop={addFilesToProcessList} 
          fileExtensions={[".csv"]}
        />
        { notFound?.length > 0 && (
          <>
          <Table>
            <Table.Header>
              <Table.HeaderCell width={2}>Provided Details</Table.HeaderCell>
              <Table.HeaderCell>Possible Matches</Table.HeaderCell>
              <Table.HeaderCell width={1} right></Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              { notFound.map((notFoundStudent: StudentNotFoundView, index: number) => (
                <React.Fragment key={index}>
                  <Table.Row key={index}>
                    <Table.Cell width={2} rowspan={notFoundStudent.possibleMatches.length}>
                      <NotFound>
                        <p><b>SIMS: </b>{notFoundStudent.simsId}</p> 
                        <p><b>Name: </b>{`${notFoundStudent.firstName} ${notFoundStudent.lastName}`}</p>
                      </NotFound>
                    </Table.Cell>
                    <Table.Cell>
                      { !arrays.isEmpty(notFoundStudent.possibleMatches) && (
                        <Avatar
                          size={Size.Medium}
                          user={notFoundStudent.possibleMatches[0]}
                          sub={``}
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell width={1} right>
                      { !arrays.isEmpty(notFoundStudent.possibleMatches) && (
                        <CheckWrapper>
                          <Icon
                            value="check-circle"
                            size={Size.ExtraLarge}
                            hoverColor={Swatches.Success.swatch}
                            onClick={() => handleAcceptSuggestion(notFoundStudent, notFoundStudent.possibleMatches[0])}
                          />
                          <Icon
                            value="times-circle"
                            size={Size.ExtraLarge}
                            hoverColor={Swatches.Danger.swatch}
                            onClick={() => handleDeclineSugestion(notFoundStudent, notFoundStudent.possibleMatches[0])}
                          />
                        </CheckWrapper>
                      )}
                    </Table.Cell>
                  </Table.Row>
                  { (notFoundStudent.possibleMatches.length > 1) && 
                     notFoundStudent.possibleMatches.slice(1).map((possibleMatch: UserTinyView, matchIndex: number) => (
                    <Table.Row key={matchIndex}>
                      <Table.Cell>
                        <Avatar
                          size={Size.Medium}
                          user={possibleMatch}
                          sub={``}
                        />
                      </Table.Cell>
                      <Table.Cell width={1} right>
                      <CheckWrapper>
                        <Icon
                          value="check-circle"
                          size={Size.ExtraLarge}
                          hoverColor={Swatches.Success.swatch}
                          onClick={() => handleAcceptSuggestion(notFoundStudent, possibleMatch)}
                        />
                        <Icon
                          value="times-circle"
                          size={Size.ExtraLarge}
                          hoverColor={Swatches.Danger.swatch}
                          onClick={() => handleDeclineSugestion(notFoundStudent, possibleMatch)}
                        />
                      </CheckWrapper>
                    </Table.Cell>
                    </Table.Row>
                  ))}
                </React.Fragment>
              ))}
            </Table.Body>
          </Table>
          </>
        )}
        { students?.length > 0 && (
          <>
          {/* <CustomGroupStudentList
             students={students}
             listTitle={`Students to import`}
             handleRemoveStudent={handleRemove}
           /> */}
          <Table>
            <Table.Header>
              <Table.HeaderCell>Student</Table.HeaderCell>
              <Table.HeaderCell right></Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              { students.map((student: UserTinyView, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <Avatar
                      size={Size.Medium}
                      user={student}
                      sub={``}
                    />
                  </Table.Cell>
                  <Table.Cell right>
                    <>
                      <RemoveButton
                        ref={ref}
                        className="remove-student-button"
                        onClick={event => {
                          event.stopPropagation();
                          handleRemove(student.id);
                        }}
                      >
                        &#10799;
                      </RemoveButton>
                      <Tippy
                        reference={ref}
                        content={<Tooltip title="Remove Student" />}
                      />
                    </>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              text="Upload"
              color={Swatches.Success}
              onClick={handleSave}
              size={Size.Small}
              disabled={ !students || arrays.isEmpty(students) }
            />
            <Button
              onClick={handleCancel}
              size={Size.Small}
              color={Swatches.Low}
              text="Cancel"
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>

    <OkayModal 
      open={schemaError}
      body={<p>The provided CSV does not conform to the required format</p>}
      onCancel={() => setSchemaError(false)}
      onConfirm={() => downloadExampleSchema()}
      cancelText={"Download Example"}
    />
    </>
  );
};

export default ImportStudentsModal;
