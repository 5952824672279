import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Path, useLocation } from "react-router-dom";
import loginService from "services/authentication/loginService";
import { Routes } from "configuration";
import arrays from "utils/arrays";
import userUtils from "utils/users";
import Unauthorized from "../messages/unauthorized";
import navigationActions from "../../../actions/ui/navigation";
import { RootState } from "reducers/store";
import { IRole } from "configuration/constants.types";
import { UserType } from "configuration/constants.enums";
// import { setTitle } from "actions/ui/navigation";
// import { useAction } from "hooks";

interface ICustomRouteProps {
  path?: string;
  noAuth?: boolean;
  redirectTo?: string | Partial<Path>;
  roles?: IRole[];
  users?: UserType[];
  title?: string;
  redirectIfAuthenticated?: boolean;
  children?: any | any[];
}

const CustomRoute : React.FC<ICustomRouteProps> = ({
  path,
  noAuth,
  redirectTo,
  roles,
  users,
  title,
  redirectIfAuthenticated,
  children,
}) => {

  const location = useLocation();
  var authenticated = loginService.getAuthenticatedUser();
  // const authenticated = null;
  const currentUser = useSelector((state: RootState) => state.currentUser);
  // const setTitleAction = useAction(setTitle);

  useEffect(() => {
    if (title?.length) {
      navigationActions.setTitle(title);
    }
  }, [title]);

  if (redirectIfAuthenticated && authenticated) {
    return <Navigate to={Routes.home} />;
  }

  if (!authenticated && !noAuth) {
    return <Navigate to={`${Routes.authentication.login}`} state={{ preAuthLocation: location }} />;
  }

  // PERMISSION CHECKS
  // Roles take precedence over user types
  if (!arrays.isEmpty(roles)) {
    if (currentUser.user && !userUtils.isInAnyRoles(currentUser.user, roles!)) {
      return <Unauthorized />;
    }
  }

  if (!arrays.isEmpty(users)) {
    if (
      currentUser.user &&
      !userUtils.isUserOfAnyType(currentUser.user, users!)
    ) {
      return <Unauthorized />;
    }
  }

  return redirectTo ? <Navigate to={redirectTo} /> : children;
};


export default CustomRoute;
