export const ROOM_LAYOUT_TEMPLATES = [
  {
    id: 1,
    name: "Rows of 4 Wide",
    description: "Common wide class room layout with 3 rows of 4 tables",
    rows: 6,
    cols: 14,
    slots: [
      {
        x: 1,
        y: 6,
        isASeat: true,
      },
      {
        x: 2,
        y: 6,
        isASeat: true,
      },
      {
        x: 3,
        y: 6,
        isASeat: true,
      },
      {
        x: 4,
        y: 6,
        isASeat: true,
      },
      {
        x: 5,
        y: 6,
        isASeat: false,
      },
      {
        x: 6,
        y: 6,
        isASeat: true,
      },
      {
        x: 7,
        y: 6,
        isASeat: true,
      },
      {
        x: 8,
        y: 6,
        isASeat: true,
      },
      {
        x: 9,
        y: 6,
        isASeat: true,
      },
      {
        x: 10,
        y: 6,
        isASeat: false,
      },
      {
        x: 1,
        y: 5,
        isASeat: false,
      },
      {
        x: 2,
        y: 5,
        isASeat: false,
      },
      {
        x: 3,
        y: 5,
        isASeat: false,
      },
      {
        x: 4,
        y: 5,
        isASeat: false,
      },
      {
        x: 5,
        y: 5,
        isASeat: false,
      },
      {
        x: 6,
        y: 5,
        isASeat: false,
      },
      {
        x: 7,
        y: 5,
        isASeat: false,
      },
      {
        x: 8,
        y: 5,
        isASeat: false,
      },
      {
        x: 9,
        y: 5,
        isASeat: false,
      },
      {
        x: 10,
        y: 5,
        isASeat: false,
      },
      {
        x: 1,
        y: 4,
        isASeat: true,
      },
      {
        x: 2,
        y: 4,
        isASeat: true,
      },
      {
        x: 3,
        y: 4,
        isASeat: true,
      },
      {
        x: 4,
        y: 4,
        isASeat: true,
      },
      {
        x: 5,
        y: 4,
        isASeat: false,
      },
      {
        x: 6,
        y: 4,
        isASeat: true,
      },
      {
        x: 7,
        y: 4,
        isASeat: true,
      },
      {
        x: 8,
        y: 4,
        isASeat: true,
      },
      {
        x: 9,
        y: 4,
        isASeat: true,
      },
      {
        x: 10,
        y: 4,
        isASeat: false,
      },
      {
        x: 1,
        y: 3,
        isASeat: false,
      },
      {
        x: 2,
        y: 3,
        isASeat: false,
      },
      {
        x: 3,
        y: 3,
        isASeat: false,
      },
      {
        x: 4,
        y: 3,
        isASeat: false,
      },
      {
        x: 5,
        y: 3,
        isASeat: false,
      },
      {
        x: 6,
        y: 3,
        isASeat: false,
      },
      {
        x: 7,
        y: 3,
        isASeat: false,
      },
      {
        x: 8,
        y: 3,
        isASeat: false,
      },
      {
        x: 9,
        y: 3,
        isASeat: false,
      },
      {
        x: 10,
        y: 3,
        isASeat: false,
      },
      {
        x: 1,
        y: 2,
        isASeat: true,
      },
      {
        x: 2,
        y: 2,
        isASeat: true,
      },
      {
        x: 3,
        y: 2,
        isASeat: true,
      },
      {
        x: 4,
        y: 2,
        isASeat: true,
      },
      {
        x: 5,
        y: 2,
        isASeat: false,
      },
      {
        x: 6,
        y: 2,
        isASeat: true,
      },
      {
        x: 7,
        y: 2,
        isASeat: true,
      },
      {
        x: 8,
        y: 2,
        isASeat: true,
      },
      {
        x: 9,
        y: 2,
        isASeat: true,
      },
      {
        x: 10,
        y: 2,
        isASeat: false,
      },
      {
        x: 1,
        y: 1,
        isASeat: false,
      },
      {
        x: 2,
        y: 1,
        isASeat: false,
      },
      {
        x: 3,
        y: 1,
        isASeat: false,
      },
      {
        x: 4,
        y: 1,
        isASeat: false,
      },
      {
        x: 5,
        y: 1,
        isASeat: false,
      },
      {
        x: 6,
        y: 1,
        isASeat: false,
      },
      {
        x: 7,
        y: 1,
        isASeat: false,
      },
      {
        x: 8,
        y: 1,
        isASeat: false,
      },
      {
        x: 9,
        y: 1,
        isASeat: false,
      },
      {
        x: 10,
        y: 1,
        isASeat: false,
      },
      {
        x: 11,
        y: 6,
        isASeat: true,
      },
      {
        x: 11,
        y: 5,
        isASeat: false,
      },
      {
        x: 11,
        y: 4,
        isASeat: true,
      },
      {
        x: 11,
        y: 3,
        isASeat: false,
      },
      {
        x: 11,
        y: 2,
        isASeat: true,
      },
      {
        x: 11,
        y: 1,
        isASeat: false,
      },
      {
        x: 12,
        y: 6,
        isASeat: true,
      },
      {
        x: 12,
        y: 5,
        isASeat: false,
      },
      {
        x: 12,
        y: 4,
        isASeat: true,
      },
      {
        x: 12,
        y: 3,
        isASeat: false,
      },
      {
        x: 12,
        y: 2,
        isASeat: true,
      },
      {
        x: 12,
        y: 1,
        isASeat: false,
      },
      {
        x: 13,
        y: 6,
        isASeat: true,
      },
      {
        x: 13,
        y: 5,
        isASeat: false,
      },
      {
        x: 13,
        y: 4,
        isASeat: true,
      },
      {
        x: 13,
        y: 3,
        isASeat: false,
      },
      {
        x: 13,
        y: 2,
        isASeat: true,
      },
      {
        x: 13,
        y: 1,
        isASeat: false,
      },
      {
        x: 14,
        y: 6,
        isASeat: true,
      },
      {
        x: 14,
        y: 5,
        isASeat: false,
      },
      {
        x: 14,
        y: 4,
        isASeat: true,
      },
      {
        x: 14,
        y: 3,
        isASeat: false,
      },
      {
        x: 14,
        y: 2,
        isASeat: true,
      },
      {
        x: 14,
        y: 1,
        isASeat: false,
      },
    ],
  },
  {
    id: 2,
    name: "Rows of 4",
    description: "Common class room layout",

    rows: 8,
    cols: 9,
    slots: [
      {
        x: 1,
        y: 5,
        isASeat: false,
      },
      {
        x: 2,
        y: 5,
        isASeat: false,
      },
      {
        x: 3,
        y: 5,
        isASeat: false,
      },
      {
        x: 4,
        y: 5,
        isASeat: false,
      },
      {
        x: 5,
        y: 5,
        isASeat: false,
      },
      {
        x: 6,
        y: 5,
        isASeat: false,
      },
      {
        x: 7,
        y: 5,
        isASeat: false,
      },
      {
        x: 8,
        y: 5,
        isASeat: false,
      },
      {
        x: 9,
        y: 5,
        isASeat: false,
      },
      {
        x: 1,
        y: 4,
        isASeat: true,
      },
      {
        x: 2,
        y: 4,
        isASeat: true,
      },
      {
        x: 3,
        y: 4,
        isASeat: true,
      },
      {
        x: 4,
        y: 4,
        isASeat: true,
      },
      {
        x: 5,
        y: 4,
        isASeat: false,
      },
      {
        x: 6,
        y: 4,
        isASeat: true,
      },
      {
        x: 7,
        y: 4,
        isASeat: true,
      },
      {
        x: 8,
        y: 4,
        isASeat: true,
      },
      {
        x: 9,
        y: 4,
        isASeat: true,
      },
      {
        x: 1,
        y: 3,
        isASeat: false,
      },
      {
        x: 2,
        y: 3,
        isASeat: false,
      },
      {
        x: 3,
        y: 3,
        isASeat: false,
      },
      {
        x: 4,
        y: 3,
        isASeat: false,
      },
      {
        x: 5,
        y: 3,
        isASeat: false,
      },
      {
        x: 6,
        y: 3,
        isASeat: false,
      },
      {
        x: 7,
        y: 3,
        isASeat: false,
      },
      {
        x: 8,
        y: 3,
        isASeat: false,
      },
      {
        x: 9,
        y: 3,
        isASeat: false,
      },
      {
        x: 1,
        y: 2,
        isASeat: true,
      },
      {
        x: 2,
        y: 2,
        isASeat: true,
      },
      {
        x: 3,
        y: 2,
        isASeat: true,
      },
      {
        x: 4,
        y: 2,
        isASeat: true,
      },
      {
        x: 5,
        y: 2,
        isASeat: false,
      },
      {
        x: 6,
        y: 2,
        isASeat: true,
      },
      {
        x: 7,
        y: 2,
        isASeat: true,
      },
      {
        x: 8,
        y: 2,
        isASeat: true,
      },
      {
        x: 9,
        y: 2,
        isASeat: true,
      },
      {
        x: 1,
        y: 1,
        isASeat: false,
      },
      {
        x: 2,
        y: 1,
        isASeat: false,
      },
      {
        x: 3,
        y: 1,
        isASeat: false,
      },
      {
        x: 4,
        y: 1,
        isASeat: false,
      },
      {
        x: 5,
        y: 1,
        isASeat: false,
      },
      {
        x: 6,
        y: 1,
        isASeat: false,
      },
      {
        x: 7,
        y: 1,
        isASeat: false,
      },
      {
        x: 8,
        y: 1,
        isASeat: false,
      },
      {
        x: 9,
        y: 1,
        isASeat: false,
      },
      {
        x: 1,
        y: 6,
        isASeat: true,
      },
      {
        x: 2,
        y: 6,
        isASeat: true,
      },
      {
        x: 3,
        y: 6,
        isASeat: true,
      },
      {
        x: 4,
        y: 6,
        isASeat: true,
      },
      {
        x: 5,
        y: 6,
        isASeat: false,
      },
      {
        x: 6,
        y: 6,
        isASeat: true,
      },
      {
        x: 7,
        y: 6,
        isASeat: true,
      },
      {
        x: 8,
        y: 6,
        isASeat: true,
      },
      {
        x: 9,
        y: 6,
        isASeat: true,
      },
      {
        x: 1,
        y: 7,
        isASeat: false,
      },
      {
        x: 2,
        y: 7,
        isASeat: false,
      },
      {
        x: 3,
        y: 7,
        isASeat: false,
      },
      {
        x: 4,
        y: 7,
        isASeat: false,
      },
      {
        x: 5,
        y: 7,
        isASeat: false,
      },
      {
        x: 6,
        y: 7,
        isASeat: false,
      },
      {
        x: 7,
        y: 7,
        isASeat: false,
      },
      {
        x: 8,
        y: 7,
        isASeat: false,
      },
      {
        x: 9,
        y: 7,
        isASeat: false,
      },
      {
        x: 1,
        y: 8,
        isASeat: true,
      },
      {
        x: 2,
        y: 8,
        isASeat: true,
      },
      {
        x: 3,
        y: 8,
        isASeat: true,
      },
      {
        x: 4,
        y: 8,
        isASeat: true,
      },
      {
        x: 5,
        y: 8,
        isASeat: false,
      },
      {
        x: 6,
        y: 8,
        isASeat: true,
      },
      {
        x: 7,
        y: 8,
        isASeat: true,
      },
      {
        x: 8,
        y: 8,
        isASeat: true,
      },
      {
        x: 9,
        y: 8,
        isASeat: true,
      },
    ],
  },
  {
    id: 3,
    name: "Rows of 6",
    description: "Common wide class room layout with 3 rows of 6 tables",

    rows: 6,
    cols: 13,
    slots: [
      {
        x: 1,
        y: 5,
        isASeat: false,
      },
      {
        x: 2,
        y: 5,
        isASeat: false,
      },
      {
        x: 3,
        y: 5,
        isASeat: false,
      },
      {
        x: 4,
        y: 5,
        isASeat: false,
      },
      {
        x: 5,
        y: 5,
        isASeat: false,
      },
      {
        x: 6,
        y: 5,
        isASeat: false,
      },
      {
        x: 7,
        y: 5,
        isASeat: false,
      },
      {
        x: 8,
        y: 5,
        isASeat: false,
      },
      {
        x: 9,
        y: 5,
        isASeat: false,
      },
      {
        x: 1,
        y: 4,
        isASeat: true,
      },
      {
        x: 2,
        y: 4,
        isASeat: true,
      },
      {
        x: 3,
        y: 4,
        isASeat: true,
      },
      {
        x: 4,
        y: 4,
        isASeat: true,
      },
      {
        x: 5,
        y: 4,
        isASeat: true,
      },
      {
        x: 6,
        y: 4,
        isASeat: true,
      },
      {
        x: 7,
        y: 4,
        isASeat: false,
      },
      {
        x: 8,
        y: 4,
        isASeat: true,
      },
      {
        x: 9,
        y: 4,
        isASeat: true,
      },
      {
        x: 1,
        y: 3,
        isASeat: false,
      },
      {
        x: 2,
        y: 3,
        isASeat: false,
      },
      {
        x: 3,
        y: 3,
        isASeat: false,
      },
      {
        x: 4,
        y: 3,
        isASeat: false,
      },
      {
        x: 5,
        y: 3,
        isASeat: false,
      },
      {
        x: 6,
        y: 3,
        isASeat: false,
      },
      {
        x: 7,
        y: 3,
        isASeat: false,
      },
      {
        x: 8,
        y: 3,
        isASeat: false,
      },
      {
        x: 9,
        y: 3,
        isASeat: false,
      },
      {
        x: 1,
        y: 2,
        isASeat: true,
      },
      {
        x: 2,
        y: 2,
        isASeat: true,
      },
      {
        x: 3,
        y: 2,
        isASeat: true,
      },
      {
        x: 4,
        y: 2,
        isASeat: true,
      },
      {
        x: 5,
        y: 2,
        isASeat: true,
      },
      {
        x: 6,
        y: 2,
        isASeat: true,
      },
      {
        x: 7,
        y: 2,
        isASeat: false,
      },
      {
        x: 8,
        y: 2,
        isASeat: true,
      },
      {
        x: 9,
        y: 2,
        isASeat: true,
      },
      {
        x: 1,
        y: 1,
        isASeat: false,
      },
      {
        x: 2,
        y: 1,
        isASeat: false,
      },
      {
        x: 3,
        y: 1,
        isASeat: false,
      },
      {
        x: 4,
        y: 1,
        isASeat: false,
      },
      {
        x: 5,
        y: 1,
        isASeat: false,
      },
      {
        x: 6,
        y: 1,
        isASeat: false,
      },
      {
        x: 7,
        y: 1,
        isASeat: false,
      },
      {
        x: 8,
        y: 1,
        isASeat: false,
      },
      {
        x: 9,
        y: 1,
        isASeat: false,
      },
      {
        x: 1,
        y: 6,
        isASeat: true,
      },
      {
        x: 2,
        y: 6,
        isASeat: true,
      },
      {
        x: 3,
        y: 6,
        isASeat: true,
      },
      {
        x: 4,
        y: 6,
        isASeat: true,
      },
      {
        x: 5,
        y: 6,
        isASeat: true,
      },
      {
        x: 6,
        y: 6,
        isASeat: true,
      },
      {
        x: 7,
        y: 6,
        isASeat: false,
      },
      {
        x: 8,
        y: 6,
        isASeat: true,
      },
      {
        x: 9,
        y: 6,
        isASeat: true,
      },
      {
        x: 10,
        y: 6,
        isASeat: true,
      },
      {
        x: 10,
        y: 5,
        isASeat: false,
      },
      {
        x: 10,
        y: 4,
        isASeat: true,
      },
      {
        x: 10,
        y: 3,
        isASeat: false,
      },
      {
        x: 10,
        y: 2,
        isASeat: true,
      },
      {
        x: 10,
        y: 1,
        isASeat: false,
      },
      {
        x: 11,
        y: 6,
        isASeat: true,
      },
      {
        x: 11,
        y: 5,
        isASeat: false,
      },
      {
        x: 11,
        y: 4,
        isASeat: true,
      },
      {
        x: 11,
        y: 3,
        isASeat: false,
      },
      {
        x: 11,
        y: 2,
        isASeat: true,
      },
      {
        x: 11,
        y: 1,
        isASeat: false,
      },
      {
        x: 12,
        y: 6,
        isASeat: true,
      },
      {
        x: 12,
        y: 5,
        isASeat: false,
      },
      {
        x: 12,
        y: 4,
        isASeat: true,
      },
      {
        x: 12,
        y: 3,
        isASeat: false,
      },
      {
        x: 12,
        y: 2,
        isASeat: true,
      },
      {
        x: 12,
        y: 1,
        isASeat: false,
      },
      {
        x: 13,
        y: 6,
        isASeat: true,
      },
      {
        x: 13,
        y: 5,
        isASeat: false,
      },
      {
        x: 13,
        y: 4,
        isASeat: true,
      },
      {
        x: 13,
        y: 3,
        isASeat: false,
      },
      {
        x: 13,
        y: 2,
        isASeat: true,
      },
      {
        x: 13,
        y: 1,
        isASeat: false,
      },
    ],
  },
  {
    id: 4,
    name: "Computer Room",
    description: "Common computer room layout",

    rows: 12,
    cols: 8,
    slots: [
      {
        x: 1,
        y: 10,
        isASeat: true,
      },
      {
        x: 2,
        y: 10,
        isASeat: false,
      },
      {
        x: 3,
        y: 10,
        isASeat: true,
      },
      {
        x: 4,
        y: 10,
        isASeat: true,
      },
      {
        x: 5,
        y: 10,
        isASeat: true,
      },
      {
        x: 6,
        y: 10,
        isASeat: true,
      },
      {
        x: 7,
        y: 10,
        isASeat: false,
      },
      {
        x: 8,
        y: 10,
        isASeat: true,
      },
      {
        x: 1,
        y: 9,
        isASeat: true,
      },
      {
        x: 2,
        y: 9,
        isASeat: false,
      },
      {
        x: 3,
        y: 9,
        isASeat: false,
      },
      {
        x: 4,
        y: 9,
        isASeat: false,
      },
      {
        x: 5,
        y: 9,
        isASeat: false,
      },
      {
        x: 6,
        y: 9,
        isASeat: false,
      },
      {
        x: 7,
        y: 9,
        isASeat: false,
      },
      {
        x: 8,
        y: 9,
        isASeat: true,
      },
      {
        x: 1,
        y: 8,
        isASeat: true,
      },
      {
        x: 2,
        y: 8,
        isASeat: false,
      },
      {
        x: 3,
        y: 8,
        isASeat: true,
      },
      {
        x: 4,
        y: 8,
        isASeat: true,
      },
      {
        x: 5,
        y: 8,
        isASeat: true,
      },
      {
        x: 6,
        y: 8,
        isASeat: true,
      },
      {
        x: 7,
        y: 8,
        isASeat: false,
      },
      {
        x: 8,
        y: 8,
        isASeat: true,
      },
      {
        x: 1,
        y: 7,
        isASeat: true,
      },
      {
        x: 2,
        y: 7,
        isASeat: false,
      },
      {
        x: 3,
        y: 7,
        isASeat: false,
      },
      {
        x: 4,
        y: 7,
        isASeat: false,
      },
      {
        x: 5,
        y: 7,
        isASeat: false,
      },
      {
        x: 6,
        y: 7,
        isASeat: false,
      },
      {
        x: 7,
        y: 7,
        isASeat: false,
      },
      {
        x: 8,
        y: 7,
        isASeat: true,
      },
      {
        x: 1,
        y: 6,
        isASeat: true,
      },
      {
        x: 2,
        y: 6,
        isASeat: false,
      },
      {
        x: 3,
        y: 6,
        isASeat: true,
      },
      {
        x: 4,
        y: 6,
        isASeat: true,
      },
      {
        x: 5,
        y: 6,
        isASeat: true,
      },
      {
        x: 6,
        y: 6,
        isASeat: true,
      },
      {
        x: 7,
        y: 6,
        isASeat: false,
      },
      {
        x: 8,
        y: 6,
        isASeat: true,
      },
      {
        x: 1,
        y: 5,
        isASeat: true,
      },
      {
        x: 2,
        y: 5,
        isASeat: false,
      },
      {
        x: 3,
        y: 5,
        isASeat: false,
      },
      {
        x: 4,
        y: 5,
        isASeat: false,
      },
      {
        x: 5,
        y: 5,
        isASeat: false,
      },
      {
        x: 6,
        y: 5,
        isASeat: false,
      },
      {
        x: 7,
        y: 5,
        isASeat: false,
      },
      {
        x: 8,
        y: 5,
        isASeat: true,
      },
      {
        x: 1,
        y: 4,
        isASeat: true,
      },
      {
        x: 2,
        y: 4,
        isASeat: false,
      },
      {
        x: 3,
        y: 4,
        isASeat: true,
      },
      {
        x: 4,
        y: 4,
        isASeat: true,
      },
      {
        x: 5,
        y: 4,
        isASeat: true,
      },
      {
        x: 6,
        y: 4,
        isASeat: true,
      },
      {
        x: 7,
        y: 4,
        isASeat: false,
      },
      {
        x: 8,
        y: 4,
        isASeat: true,
      },
      {
        x: 1,
        y: 3,
        isASeat: true,
      },
      {
        x: 2,
        y: 3,
        isASeat: false,
      },
      {
        x: 3,
        y: 3,
        isASeat: false,
      },
      {
        x: 4,
        y: 3,
        isASeat: false,
      },
      {
        x: 5,
        y: 3,
        isASeat: false,
      },
      {
        x: 6,
        y: 3,
        isASeat: false,
      },
      {
        x: 7,
        y: 3,
        isASeat: false,
      },
      {
        x: 8,
        y: 3,
        isASeat: true,
      },
      {
        x: 1,
        y: 2,
        isASeat: true,
      },
      {
        x: 2,
        y: 2,
        isASeat: false,
      },
      {
        x: 3,
        y: 2,
        isASeat: true,
      },
      {
        x: 4,
        y: 2,
        isASeat: true,
      },
      {
        x: 5,
        y: 2,
        isASeat: true,
      },
      {
        x: 6,
        y: 2,
        isASeat: true,
      },
      {
        x: 7,
        y: 2,
        isASeat: false,
      },
      {
        x: 8,
        y: 2,
        isASeat: true,
      },
      {
        x: 1,
        y: 1,
        isASeat: false,
      },
      {
        x: 2,
        y: 1,
        isASeat: false,
      },
      {
        x: 3,
        y: 1,
        isASeat: false,
      },
      {
        x: 4,
        y: 1,
        isASeat: false,
      },
      {
        x: 5,
        y: 1,
        isASeat: false,
      },
      {
        x: 6,
        y: 1,
        isASeat: false,
      },
      {
        x: 7,
        y: 1,
        isASeat: false,
      },
      {
        x: 8,
        y: 1,
        isASeat: false,
      },
      {
        x: 1,
        y: 11,
        isASeat: true,
      },
      {
        x: 2,
        y: 11,
        isASeat: false,
      },
      {
        x: 3,
        y: 11,
        isASeat: false,
      },
      {
        x: 4,
        y: 11,
        isASeat: false,
      },
      {
        x: 5,
        y: 11,
        isASeat: false,
      },
      {
        x: 6,
        y: 11,
        isASeat: false,
      },
      {
        x: 7,
        y: 11,
        isASeat: false,
      },
      {
        x: 8,
        y: 11,
        isASeat: true,
      },
      {
        x: 1,
        y: 12,
        isASeat: true,
      },
      {
        x: 2,
        y: 12,
        isASeat: true,
      },
      {
        x: 3,
        y: 12,
        isASeat: true,
      },
      {
        x: 4,
        y: 12,
        isASeat: true,
      },
      {
        x: 5,
        y: 12,
        isASeat: true,
      },
      {
        x: 6,
        y: 12,
        isASeat: true,
      },
      {
        x: 7,
        y: 12,
        isASeat: true,
      },
      {
        x: 8,
        y: 12,
        isASeat: true,
      },
    ],
  },
  {
    id: 5,
    name: "Custom Template",
    description:
      "If your room layout does not match any of the above, you can create a custom layout. In the next step you can choose where to put table positions.",
    buttonText: "Create a Custom Template",
    rows: 10,
    cols: 10,
    slots: [
      {
        x: 1,
        y: 10,
        isASeat: false,
      },
      {
        x: 2,
        y: 10,
        isASeat: false,
      },
      {
        x: 3,
        y: 10,
        isASeat: false,
      },
      {
        x: 4,
        y: 10,
        isASeat: false,
      },
      {
        x: 5,
        y: 10,
        isASeat: false,
      },
      {
        x: 6,
        y: 10,
        isASeat: false,
      },
      {
        x: 7,
        y: 10,
        isASeat: false,
      },
      {
        x: 8,
        y: 10,
        isASeat: false,
      },
      {
        x: 9,
        y: 10,
        isASeat: false,
      },
      {
        x: 10,
        y: 10,
        isASeat: false,
      },
      {
        x: 1,
        y: 9,
        isASeat: false,
      },
      {
        x: 2,
        y: 9,
        isASeat: false,
      },
      {
        x: 3,
        y: 9,
        isASeat: false,
      },
      {
        x: 4,
        y: 9,
        isASeat: false,
      },
      {
        x: 5,
        y: 9,
        isASeat: false,
      },
      {
        x: 6,
        y: 9,
        isASeat: false,
      },
      {
        x: 7,
        y: 9,
        isASeat: false,
      },
      {
        x: 8,
        y: 9,
        isASeat: false,
      },
      {
        x: 9,
        y: 9,
        isASeat: false,
      },
      {
        x: 10,
        y: 9,
        isASeat: false,
      },
      {
        x: 1,
        y: 8,
        isASeat: false,
      },
      {
        x: 2,
        y: 8,
        isASeat: false,
      },
      {
        x: 3,
        y: 8,
        isASeat: false,
      },
      {
        x: 4,
        y: 8,
        isASeat: false,
      },
      {
        x: 5,
        y: 8,
        isASeat: false,
      },
      {
        x: 6,
        y: 8,
        isASeat: false,
      },
      {
        x: 7,
        y: 8,
        isASeat: false,
      },
      {
        x: 8,
        y: 8,
        isASeat: false,
      },
      {
        x: 9,
        y: 8,
        isASeat: false,
      },
      {
        x: 10,
        y: 8,
        isASeat: false,
      },
      {
        x: 1,
        y: 7,
        isASeat: false,
      },
      {
        x: 2,
        y: 7,
        isASeat: false,
      },
      {
        x: 3,
        y: 7,
        isASeat: false,
      },
      {
        x: 4,
        y: 7,
        isASeat: false,
      },
      {
        x: 5,
        y: 7,
        isASeat: false,
      },
      {
        x: 6,
        y: 7,
        isASeat: false,
      },
      {
        x: 7,
        y: 7,
        isASeat: false,
      },
      {
        x: 8,
        y: 7,
        isASeat: false,
      },
      {
        x: 9,
        y: 7,
        isASeat: false,
      },
      {
        x: 10,
        y: 7,
        isASeat: false,
      },
      {
        x: 1,
        y: 6,
        isASeat: false,
      },
      {
        x: 2,
        y: 6,
        isASeat: false,
      },
      {
        x: 3,
        y: 6,
        isASeat: false,
      },
      {
        x: 4,
        y: 6,
        isASeat: false,
      },
      {
        x: 5,
        y: 6,
        isASeat: false,
      },
      {
        x: 6,
        y: 6,
        isASeat: false,
      },
      {
        x: 7,
        y: 6,
        isASeat: false,
      },
      {
        x: 8,
        y: 6,
        isASeat: false,
      },
      {
        x: 9,
        y: 6,
        isASeat: false,
      },
      {
        x: 10,
        y: 6,
        isASeat: false,
      },
      {
        x: 1,
        y: 5,
        isASeat: false,
      },
      {
        x: 2,
        y: 5,
        isASeat: false,
      },
      {
        x: 3,
        y: 5,
        isASeat: false,
      },
      {
        x: 4,
        y: 5,
        isASeat: false,
      },
      {
        x: 5,
        y: 5,
        isASeat: false,
      },
      {
        x: 6,
        y: 5,
        isASeat: false,
      },
      {
        x: 7,
        y: 5,
        isASeat: false,
      },
      {
        x: 8,
        y: 5,
        isASeat: false,
      },
      {
        x: 9,
        y: 5,
        isASeat: false,
      },
      {
        x: 10,
        y: 5,
        isASeat: false,
      },
      {
        x: 1,
        y: 4,
        isASeat: false,
      },
      {
        x: 2,
        y: 4,
        isASeat: false,
      },
      {
        x: 3,
        y: 4,
        isASeat: false,
      },
      {
        x: 4,
        y: 4,
        isASeat: false,
      },
      {
        x: 5,
        y: 4,
        isASeat: false,
      },
      {
        x: 6,
        y: 4,
        isASeat: false,
      },
      {
        x: 7,
        y: 4,
        isASeat: false,
      },
      {
        x: 8,
        y: 4,
        isASeat: false,
      },
      {
        x: 9,
        y: 4,
        isASeat: false,
      },
      {
        x: 10,
        y: 4,
        isASeat: false,
      },
      {
        x: 1,
        y: 3,
        isASeat: false,
      },
      {
        x: 2,
        y: 3,
        isASeat: false,
      },
      {
        x: 3,
        y: 3,
        isASeat: false,
      },
      {
        x: 4,
        y: 3,
        isASeat: false,
      },
      {
        x: 5,
        y: 3,
        isASeat: false,
      },
      {
        x: 6,
        y: 3,
        isASeat: false,
      },
      {
        x: 7,
        y: 3,
        isASeat: false,
      },
      {
        x: 8,
        y: 3,
        isASeat: false,
      },
      {
        x: 9,
        y: 3,
        isASeat: false,
      },
      {
        x: 10,
        y: 3,
        isASeat: false,
      },
      {
        x: 1,
        y: 2,
        isASeat: false,
      },
      {
        x: 2,
        y: 2,
        isASeat: false,
      },
      {
        x: 3,
        y: 2,
        isASeat: false,
      },
      {
        x: 4,
        y: 2,
        isASeat: false,
      },
      {
        x: 5,
        y: 2,
        isASeat: false,
      },
      {
        x: 6,
        y: 2,
        isASeat: false,
      },
      {
        x: 7,
        y: 2,
        isASeat: false,
      },
      {
        x: 8,
        y: 2,
        isASeat: false,
      },
      {
        x: 9,
        y: 2,
        isASeat: false,
      },
      {
        x: 10,
        y: 2,
        isASeat: false,
      },
      {
        x: 1,
        y: 1,
        isASeat: false,
      },
      {
        x: 2,
        y: 1,
        isASeat: false,
      },
      {
        x: 3,
        y: 1,
        isASeat: false,
      },
      {
        x: 4,
        y: 1,
        isASeat: false,
      },
      {
        x: 5,
        y: 1,
        isASeat: false,
      },
      {
        x: 6,
        y: 1,
        isASeat: false,
      },
      {
        x: 7,
        y: 1,
        isASeat: false,
      },
      {
        x: 8,
        y: 1,
        isASeat: false,
      },
      {
        x: 9,
        y: 1,
        isASeat: false,
      },
      {
        x: 10,
        y: 1,
        isASeat: false,
      },
    ],
  },
];
