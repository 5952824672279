import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import store from "reducers/store";
import client from "services/client";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { PagedQueryView } from "types/common/views.types";
import {
  AddSubmissionNoteCommand,
  AssignSubmissionCommand,
  SetAuthoriserCommand,
  SubmissionQueryFilter,
} from "../types/formRequest.types";
import {
  SubmissionAssignView,
  SubmissionDetailView,
  SubmissionListView,
  SubmissionNoteView,
} from "../types/formResponse.types";
import { UserTinyView } from "types/users/userListViews.types";
import { ISubmissionFilter } from "../reducers/submissionFilterReducer";

const builder = new actionTypeBuilder("adminsubmision");

const types = {
  SETWIDTH: "setwidth",
  NEWSUBMISSION: "newsubmission",
  GETFORADMIN: builder.build("getforadmin"),
  GETSUBMISSION: builder.build("getsubmission"),
  SELECTSUBMISSION: "selectsubmission",
  ASSIGN: builder.build("assign"),
  SETPREAUTHORISER: builder.build("setpreauthoriser"),
  ADDNOTE: builder.build("addnote"),
};

const buildSubmissionFilterQueryString = (filter: ISubmissionFilter) => {
  var filterString = `${
    filter.category && filter.category.id > -1
      ? "&categoryId=" + filter.category.id
      : ""
  }${
    filter.formType && filter.formType.id > -1
      ? "&formId=" + filter.formType.id
      : ""
  }${filter.formStatus > -1 ? "&status=" + filter.formStatus : ""}${
    filter.userId !== "-1" ? "&assignedToId=" + filter.userId : ""
  }${filter.schoolId !== -1 ? "&schoolId=" + filter.schoolId : ""}
  ${filter.searchTerm ? "&searchTerm=" + filter.searchTerm : ""}
  ${filter.authoriser ? "&authoriserId=" + filter.authoriser.id : ""}
  ${filter.submitter ? "&submittedById=" + filter.submitter.id : ""}`;

  return filterString;
};

const setWidth = (width: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.SETWIDTH,
    payload: width,
  });
};

const newSubmission = (categoryId: number, formId: number) => {
  store.dispatch<IBeehiveAction<{ categoryId: number; formId: number }>>({
    type: types.NEWSUBMISSION,
    payload: { categoryId, formId },
  });
};

const getSubmissionsForAdmin = (
  userId: string,
  pageIndex: number,
  filter: ISubmissionFilter,
  callback?: callbackType<PagedQueryView<SubmissionListView>>
) =>
  action<PagedQueryView<SubmissionListView>>(
    () =>
      client.get(
        `forms/users/${userId}/submissions/admin?pageIndex=${pageIndex}${buildSubmissionFilterQueryString(
          filter
        )}`
      ),
    types.GETFORADMIN,
    callback
  );

const getSubmissionForAdmin = (
  submissionId: number,
  callback?: callbackType<SubmissionDetailView>
) =>
  action<SubmissionDetailView>(
    () => client.get(`forms/submissions/${submissionId}`),
    types.GETSUBMISSION,
    callback
  );

const selectSubmission = (submission: SubmissionDetailView) => {
  store.dispatch<IBeehiveAction<SubmissionDetailView>>({
    type: types.SELECTSUBMISSION,
    payload: submission,
  });
};

const assignSubmission = (
  submission: SubmissionDetailView,
  userId: string,
  filter: ISubmissionFilter,
  callback?: callbackType<{
    submission: SubmissionDetailView;
    user: UserTinyView;
    filter: SubmissionQueryFilter;
  }>
) => {
  // Note that the filter from the submissionFilter reducer is needed as it needs to be passed in the submissionTypes.ASSIGN.SUCCESS action.
  // This is because one reducer (i.e. the adminSubmissions reducer) cannot access another directly.
  var command: AssignSubmissionCommand = {
    submissionId: submission.id,
    formId: submission.formId,
    categoryId: submission.categoryId,
    userId: (userId != "-1") ? userId : null,
  };
  
  return action<SubmissionAssignView>(
    () => client.post(`forms/submissions/${submission.id}/assign`, command),
    types.ASSIGN,
    data => {
      callback?.({ submission: data.submission, user: data.user, filter });
    }
  );
};

const setAuthoriser = (
  submission: SubmissionDetailView,
  authoriserId: string,
  callback?: callbackType<{
    submission: SubmissionDetailView;
    authoriser: UserTinyView;
  }>
) => {
  var command: SetAuthoriserCommand = {
    submissionId: submission.id,
    formId: submission.formId,
    categoryId: submission.categoryId,
    authoriserId,
  };
  return action<UserTinyView>(
    () => client.post(`forms/submissions/${submission.id}/authoriser`, command),
    types.SETPREAUTHORISER,
    data => {
      callback?.({ submission, authoriser: data });
    }
  );
};

const addNote = (
  note: AddSubmissionNoteCommand,
  callback?: callbackType<SubmissionNoteView>
) => {
  return action<SubmissionNoteView>(
    () => client.post(`forms/submissions/${note.submissionId}/notes`, note),
    types.ADDNOTE,
    callback
  );
};

const adminSubmissionActions = {
  types,
  setWidth,
  newSubmission,
  getSubmissionsForAdmin,
  getSubmissionForAdmin,
  selectSubmission,
  assignSubmission,
  setAuthoriser,
  addNote,
};

export default adminSubmissionActions;
