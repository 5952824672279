import React from "react";
import { Tabs } from "ui-kit";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import HolidayCard from "./holidayCard";
import StaffHoliday from "./staffHoliday";
import config, { Constants } from "configuration";
import LeaveAdministration from "./administration";
import { users } from "utils";
import Reporting from "./administration/reporting";
import leaveActions from "areas/humanResources/actions/leaveActions";
import { RootState } from "reducers/store";

const Holiday = () => {
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { holidayCard, loading, error } = useSelector(
    (state: RootState) => state.userHolidayCard
  );

  const getUserHolidayCard = (academicYearId: number) => {
    user &&
      academicYearId &&
      leaveActions.getHolidayCardForUser(user.id, academicYearId);
  };
  useEffect(() => {
    getUserHolidayCard(config.academicYear.current);
  }, []);

  const handleUserHolidayCardChange = (academicYearId: number) => {
    getUserHolidayCard(academicYearId);
  };

  const handleAcademicYearChanged = (academicYearId: number) => {
    getUserHolidayCard(academicYearId);
  };

  return (
    <Tabs>
      <Tabs.Pane name="myCard" label="My Leave">
        <HolidayCard
          holidayCard={holidayCard}
          loading={loading}
          error={error}
          onChange={handleUserHolidayCardChange}
          staffMember={user}
          onAcademicYearChanged={handleAcademicYearChanged}
        />
      </Tabs.Pane>
      <Tabs.Pane name="myStaff" label="My Staff Leave">
        <StaffHoliday />
      </Tabs.Pane>
      <Tabs.Pane
        name="administration"
        label="Administration"
        hide={
          !users.isInAnyRoles(user, [
            Constants.ROLES.HR_ADMINISTRATOR,
            Constants.ROLES.DEVELOPMENT,
          ])
        }
      >
        <LeaveAdministration />
      </Tabs.Pane>
      <Tabs.Pane
        name="reporting"
        label="Reporting"
        hide={
          !users.isInAnyRoles(user, [
            Constants.ROLES.HR_ADMINISTRATOR,
            Constants.ROLES.DEVELOPMENT,
          ])
        }
      >
        <Reporting />
      </Tabs.Pane>
    </Tabs>
  );
};

export default Holiday;
