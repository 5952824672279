import moment from "moment";
import { DETENTION_STATUS_KEYS } from "areas/behaviour/constants/detentions";
import detentionRegisterActions from "areas/behaviour/actions/detentions/detentionRegisterActions";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";
import { SchoolStudentDetentionListView } from "areas/behaviour/types/detentionRegisterResponse.types";


export interface DetentionRegisterFilters {
  detentionStatusFilters: number[];
  yearGroupFilter: number;
  behaviourCodeFilter: number,
  behaviourCategoryFilter: number,
  viewUnmarkedDetentions: boolean;
}
interface IDetentionRegisterState {
  detentions: SchoolStudentDetentionListView[];
  detentionDate: Date;
  detentionFilters: DetentionRegisterFilters;
  groupBy: number[];
  searchTerm: string;
  loading: boolean;
  loadingExportReport: boolean;
  refresh: boolean;
  error: IBeehiveError;
}


const INITIAL_STATE: IDetentionRegisterState = {
  detentions: null,
  detentionDate: moment().toDate(),
  detentionFilters: {
    detentionStatusFilters: [
      DETENTION_STATUS_KEYS.ISSUED,
      DETENTION_STATUS_KEYS.ATTENDED,
      DETENTION_STATUS_KEYS.MISSED,
    ],
    yearGroupFilter: null,
    behaviourCodeFilter: null,
    behaviourCategoryFilter: null,
    viewUnmarkedDetentions: false,
  },
  groupBy: [],
  searchTerm: "",
  loading: false,
  loadingExportReport: false,
  refresh: false,
  error: null,
};

const {
  GETDETENTIONS,
  EXPORTDETENTIONS,
  SETDETENTIONREGISTERDATE,
  SETDETENTIONREGISTERFILTER,
  SETYEARGROUPFILTER,
  SETDETENTIONGROUPBY,
  SETBEHAVIOURCODE,
  SETBEHAVIOURCATEGORY,
  SETVIEWUNMARKEDDETENTIONS,
  SETSEARCHTERM,
} = detentionRegisterActions.types;

const { RESCHEDULEDETENTION, CANCELDETENTION } = detentionActions.types;

const { REVOKEBEHAVIOUR, UPDATEBEHAVIOUR } = behaviourActions.types;

const detentionRegisterReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IDetentionRegisterState => {

  switch (action.type) {
    case GETDETENTIONS.START:
      return { ...state, loading: true, error: null };

    case GETDETENTIONS.SUCCESS:
      return {
        ...state,
        loading: false,
        refresh: false,
        detentions: action.payload,
      };

    case GETDETENTIONS.FAILED:
      return {
        ...state,
        loading: false,
        refresh: false,
        error: action.payload,
      };

    case EXPORTDETENTIONS.START:
      return { ...state, loadingExportReport: true, error: null };

    case EXPORTDETENTIONS.SUCCESS:
      return {
        ...state,
        loadingExportReport: false,
      };

    case EXPORTDETENTIONS.FAILED:
      return {
        ...state,
        loadingExportReport: false,
        error: action.payload,
      };

    case SETDETENTIONREGISTERDATE:
      return { ...state, detentionDate: action.payload };

    case SETDETENTIONREGISTERFILTER:
      return {
        ...state,
        detentionFilters: {
          ...state.detentionFilters,
          detentionStatusFilters: action.payload,
        },
      };

    case SETYEARGROUPFILTER:
      return {
        ...state,
        detentionFilters: {
          ...state.detentionFilters,
          yearGroupFilter: action.payload,
        },
      };

    case SETDETENTIONGROUPBY:
      return { ...state, groupBy: action.payload };

    case SETBEHAVIOURCODE:
      return {
        ...state,
        detentionFilters: {
          ...state.detentionFilters,
          behaviourCodeFilter: action.payload,
        },
      };

    case SETBEHAVIOURCATEGORY:
      return {
        ...state,
        detentionFilters: {
          ...state.detentionFilters,
          behaviourCategoryFilter: action.payload,
        },
      };

    case RESCHEDULEDETENTION.SUCCESS:
    case CANCELDETENTION.SUCCESS:
    case REVOKEBEHAVIOUR.SUCCESS:
    case UPDATEBEHAVIOUR.SUCCESS:
      return { ...state, refresh: true };

    case SETVIEWUNMARKEDDETENTIONS:
      return {
        ...state,
        detentionFilters: {
          ...state.detentionFilters,
          viewUnmarkedDetentions: action.payload,
        },
      };

    case SETSEARCHTERM:
      return { ...state, searchTerm: action.payload };

    // case SELECTDETENTION:
    //   return { ...state, selectedDetention: action.payload };

    default:
      return state;
  }
};

export default detentionRegisterReducer;
