import declarationsOfInterestActions from "areas/forms/actions/declarations/declarationsOfInterestActions";
import { DeclarationOfInterestListView } from "areas/forms/types/declarations/declarationsOfInterestResponse.types";
import config from "configuration/config";
import { useEffect, useState } from "react";
import { useAppSelector } from "reducers/hooks";
import { ActionBar, Button, Dropdown, EmptyMessage, Loader, Size, Swatches } from "ui-kit";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { arrays } from "utils";
import { faClipboardUser } from "@fortawesome/pro-solid-svg-icons";
import { Constants } from "configuration";
import UserDeclaration from "./userDeclaration";


const UserDeclarations: React.FC = () => {

  const { user } = useAppSelector(state => state.currentUser);
  const { loading: loadingList, error: listError, declarations, creating, createError } = useAppSelector(state  => state.declarationsOfInterest);
  const { loading: loadingDeclaration, error: declarationError, declaration } = useAppSelector(state  => state.declarationOfInterest);

  const [selectedDeclaration, setSelectedDeclaration] = useState<DeclarationOfInterestListView>(null);

  useEffect(() => {
    if (user) {
      declarationsOfInterestActions.getDeclarationsOfInterestForStaff(user.id);
    }
  }, [user]);

  useEffect(() => {
    if (!arrays.isEmpty(declarations)) {
      setSelectedDeclaration(declarations[0])
    }
  }, [declarations]);

  useEffect(() => {
    if (selectedDeclaration && !arrays.isEmpty(selectedDeclaration.versions)) {
      declarationsOfInterestActions.getDeclarationOfInterest(user.id, selectedDeclaration.id);
    }
  }, [selectedDeclaration]);

  const getYearName = (yearId: number) => {
    if (!yearId) {
      return;
    }
    const year = Constants.ACADEMIC_YEARS.find(year => year.id == yearId);
    return year?.name;
  };

  const getDeclarationList = () => {
    var declarationList: IDropdownDataItemProps<string, number, DeclarationOfInterestListView>[] = declarations?.map((declaration, index) => ({
      key: index,
      label: getYearName(declaration.academicYearId),
      value: declaration.id,
      data: declaration
    }));


    if (!declarations.some(x => x.academicYearId === config.academicYear.current)) {
      declarationList.push({
        label: `Create a new Declaration of Interest for ${config.academicYear.currentName}`,
        value: -1,
        data: null
      });
    }

    return declarationList;
  }

  const handleAddDeclaration = () => {
    declarationsOfInterestActions.createDeclarationOfInterest(user.id, { staffId: user.id });
  }

  const handleDeclarationChange = (declarationId: number, label: string, declaration?: DeclarationOfInterestListView) => {
    if (declaration == null && declarationId == -1) {
      handleAddDeclaration();
    } 
    else {
      setSelectedDeclaration(declaration);
    }
  }

  if (loadingList) {
    return <Loader cover size={Size.Medium} />;
  }

  if (listError) {
    return (
      <EmptyMessage
        cover
        title="An error occured"
        icon="times-circle"
        summary="There was a problem while loading the declarations of interest"
      />
    );
  }

  return arrays.isEmpty(declarations) ? (
    <>
      <EmptyMessage
        cover
        title="No Declarations"
        icon={faClipboardUser}
        summary="No declarations of interest have been found"
      >
        <Button
          text="Add Declaration of Interest"
          size={Size.Small}
          color={Swatches.Primary}
          onClick={handleAddDeclaration}
          working={creating}
        />
      </EmptyMessage>
    </>
  )
  : (
    <>
      <ActionBar>
        <Dropdown
          placeholder="Please choose a declaration"
          items={getDeclarationList()}
          value={selectedDeclaration?.id}
          onChange={handleDeclarationChange}
        />
      </ActionBar>

      { loadingDeclaration 
        ? ( <Loader cover size={Size.Medium} /> ) 
        : declarationError 
          ? (
            <EmptyMessage
              cover
              title="An error occured"
              icon="times-circle"
              summary="There was a problem loading the selected declaration of interest"
            />
          ) 
          : (
            <>
              <UserDeclaration 
                declaration={declaration} 
              />
            </>
          )
      }
    </>
  )
}


export default UserDeclarations;