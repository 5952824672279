import { IBeehiveAction } from "types/common/action.types";
import userSubmissionsFilterActions from "../actions/userSubmissionsFilterActions";
import { FormCategoryListView, FormListView } from "../types/formResponse.types";


interface IUserSubmissionsFilterState {
  loading: boolean;
  error: string | null;
  filter: {
    category: FormCategoryListView | null;
    formType: FormListView | null;
    formStatus: number;
    schoolId: number;
  };
}

const INITIAL_STATE: IUserSubmissionsFilterState = {
  loading: false,
  error: null,
  filter: {
    category: null,
    formType: null,
    formStatus: -1,
    schoolId: -1,
  },
};


const userSubmissionsFilterReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUserSubmissionsFilterState => {

  const {
    USERSUBMISSIONSFILTERBYCATEGORY,
    USERSUBMISSIONSFILTERBYFORMTYPE,
    USERSUBMISSIONSFILTERBYFORMSTATUS,
    USERSUBMISSIONSFILTERBYSCHOOL,
  } = userSubmissionsFilterActions.types;

  switch (action.type) {
    case USERSUBMISSIONSFILTERBYCATEGORY:
      return {
        ...state,
        filter: { ...state.filter, category: action.payload, formType: null },
      };

    case USERSUBMISSIONSFILTERBYFORMTYPE:
      return {
        ...state,
        filter: { ...state.filter, formType: action.payload },
      };

    case USERSUBMISSIONSFILTERBYFORMSTATUS:
      return {
        ...state,
        filter: { ...state.filter, formStatus: action.payload },
      };

    case USERSUBMISSIONSFILTERBYSCHOOL:
      return {
        ...state,
        filter: { ...state.filter, schoolId: action.payload },
      };

    default:
      return state;
  }
};

export default userSubmissionsFilterReducer;
