import React, { useState, useEffect } from "react";
import FileDropArea from "./fileDropArea";
import { IAcceptedFileTypes, IFileMetadata } from "types/common/files.types";


//Unlike the upload component, this will read the contents of an uploaded file and return it

interface IFileImportProps {
  onImport?: (fileContent: string | ArrayBuffer | null | undefined, fileMetadata: IFileMetadata[]) => void;
  fileTypes?: IAcceptedFileTypes[];
}

const FileImport: React.FC<IFileImportProps> = ({ onImport, fileTypes }) => {

  const acceptedMimeTypes = fileTypes?.map(fileType => fileType.mimeType);
  const acceptedFileExtensions = fileTypes?.map(fileType => fileType.fileExtension);

  const validateFile = (file: File) => {
    if (acceptedMimeTypes?.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const getExtension = (filename: string) : string => {
    return filename
      .substring(filename.lastIndexOf("."), filename.length)
      ?.toLowerCase();
  };

  const processImport = (files: FileList) => {

    var fileMetadata : IFileMetadata[] = [];

    for (var i = 0; i < files.length; i++) {
      if (!validateFile(files[i])) {
        alert("Unsupported file type");
        return;
      }
      fileMetadata.push({
        id: new Date().getTime().toString().slice(-5),
        name: files[i].name,
        size: files[i].size,
        mimeType: files[i].type,
        extension: getExtension(files[i].name),
        filename: files[i].name,
      });
    };

    const reader = new FileReader();
    reader.onload = async (e: ProgressEvent<FileReader>) => { 
      const fileContent = (e.target?.result);
      onImport?.(fileContent, fileMetadata);
    };
    reader.readAsText(files[0]);
  }

    return (
      <>
        <FileDropArea onDrop={processImport} fileExtensions={acceptedFileExtensions}/>
      </>
    )
}

export default FileImport;