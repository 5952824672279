import React from "react";
import PropTypes from "prop-types";
import { FEED_TYPE_KEY } from "../../constants/activityFeed";
import StudentModal from "sharedComponents/common/users/studentModal";
import EditBehaviourWrapper from "areas/behaviour/components/behaviours/editBehaviour/editBehaviourWrapper";
import EditRewardWrapper from "areas/behaviour/components/rewards/editRewardWrapper";
import EditOnCallRequestWrapper from "areas/behaviour/components/onCall/editOnCallRequestWrapper";

const FeedEditModal = ({ activity, open, handleCloseModal }) => {
  const EditModal = () => {
    switch (activity.feedTypeId) {
      case FEED_TYPE_KEY.BEHAVIOURS:
        return (
          <StudentModal
            title="Edit Behaviour"
            onClose={handleCloseModal}
            open={open}
            height="90%"
            width="75%"
            student={activity?.students?.[0]}
          >
            <EditBehaviourWrapper
              activity={activity}
              handleCloseModal={handleCloseModal}
            />
          </StudentModal>
        );

      case FEED_TYPE_KEY.REWARDS:
        return (
          <StudentModal
            title="Edit Reward"
            onClose={handleCloseModal}
            open={open}
            height="65%"
            width="75%"
            student={activity?.students?.[0]}
          >
            <EditRewardWrapper
              activity={activity}
              handleCloseModal={handleCloseModal}
            />
          </StudentModal>
        );

      case FEED_TYPE_KEY.ON_CALL:
        return (
          <EditOnCallRequestWrapper
            onCallId={activity.itemId}
            schoolId={activity.schoolId}
            openModal={open}
            handleCloseModal={handleCloseModal}
          />
        );

      default:
        return null;
    }
  };
  return activity && open && <EditModal />;
};

FeedEditModal.propTypes = {
  activity: PropTypes.object,
};

export default FeedEditModal;
