export enum LeaveRequestType {
  Holiday = 0,
  Toil = 1,
  AuthorisedAbsence = 2,
  Compassionate = 3,
  Maternity = 4,
  GoldenTime = 5,
  Cpd = 6,
}

export enum CoverArrangement {
  None = 0,
  Required = 1,
  Arranged = 2,
}

export enum ApprovalStatus {
  Draft = 0,
  Submitted = 1,
  Approved = 2,
  Confirmed = 3,
  Completed = 4,
  Cancelled = 5,
  Rejected = 6,
}

export enum OvertimeStatus {
  Submitted = 0,
  Authorised = 1,
  Rejected = 2,
  Cancelled = 3,
}
