import lunchRegisterActions from "../../actions/lunchRegisterActions"
import { BeehiveReport, IBeehiveAction } from "types/common/action.types";


export interface ICateringReportState {
  loading: boolean;
  data: BeehiveReport | null;
  error: string | null;
}

const INITIAL_STATE: ICateringReportState = {
  loading: false,
  data: null,
  error: null,
};

const cateringReportReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ICateringReportState => {
  const {
    CATERING_DAILYLUNCHREPORT,
    CATERING_STUDENTBALANCEREPORT,
    CATERING_DAILYTUTORGROUPREPORT,
    CATERING_GETSTUDENTLUNCHCHOICES,
    CATERING_GETLUNCHNUMBERS
  } = lunchRegisterActions.lunchRegisterTypes;

  switch (action.type) {
    
    case CATERING_DAILYLUNCHREPORT.START:
    case CATERING_DAILYTUTORGROUPREPORT.START:
    case CATERING_STUDENTBALANCEREPORT.START:
    case CATERING_GETSTUDENTLUNCHCHOICES.START:
    case CATERING_GETLUNCHNUMBERS.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case CATERING_DAILYLUNCHREPORT.SUCCESS:
    case CATERING_DAILYTUTORGROUPREPORT.SUCCESS:
    case CATERING_STUDENTBALANCEREPORT.SUCCESS:
    case CATERING_GETSTUDENTLUNCHCHOICES.SUCCESS:
    case CATERING_GETLUNCHNUMBERS.SUCCESS:
      return { 
        ...state, 
        loading: false, 
        data: action.payload, 
        error: null 
      };

    case CATERING_DAILYLUNCHREPORT.FAILED:
    case CATERING_DAILYTUTORGROUPREPORT.FAILED:
    case CATERING_STUDENTBALANCEREPORT.FAILED:
    case CATERING_GETSTUDENTLUNCHCHOICES.FAILED:
    case CATERING_GETLUNCHNUMBERS.FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: "There was a problem running the report.",
      };
      
    default:
      return state;
  }
};

export default cateringReportReducer;
