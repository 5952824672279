import { Constants } from "configuration";
import termDateActions from "../actions/termDatesActions";
import { IBeehiveAction } from "types/common/action.types";
import { DateView } from "areas/planner/types/termDatesResponse.types";


interface ITermDatesState {
  allCheckedDates: DateView[],
  loading: boolean;
  error: string | null;
}

const INITIAL_STATE: ITermDatesState = {
  allCheckedDates: [],
  //   checkedDates: null,
  loading: false,
  error: null,
};


const termDatesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ITermDatesState => {

  const { SAVECHECKEDDATES } = termDateActions.types;
  
  switch (action.type) {
    // case CHECKTERMDATES.START:
    //   return { ...state, loading: true };

    // case CHECKTERMDATES.SUCCESS:
    //   const tempAllDates = state.allCheckedDates.slice();
    //   action.payload.dates.forEach(date => {
    //     tempAllDates.push(date);
    //   });
    //   return {
    //     ...state,
    //     checkedDates: action.payload,
    //     allCheckedDates: tempAllDates,
    //     loading: false
    //   };

    // case CHECKTERMDATES.FAILED:
    //   return { ...state, loading: false, error: action.payload };

    case SAVECHECKEDDATES:
      return { ...state, allCheckedDates: action.payload };

    default:
      return state;
  }
};

export default termDatesReducer;
