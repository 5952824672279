import defaults from "configuration/defaults";
import { arrays } from "utils";
import documentGroupActions from "../../actions/documentGroupActions";
import { PageInfo } from "types/common/paging.types";
import { DocumentGroupDetailView, DocumentListView, UploadDocumentsResult } from "areas/administration/types/documentResponse.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


export interface documentList extends DocumentListView {
  selected?: boolean;
}

interface IDocumentGroupState {
  group: DocumentGroupDetailView | null,
  error: IBeehiveError | null;
  loading: boolean;
  saving: boolean;
  saveError: string | null;
  uploadResults: UploadDocumentsResult | null,
  uploadError: string | null;
  uploading: boolean;
  documents: documentList[],
  documentsError: string | null;
  loadingDocuments: boolean;
  paging: PageInfo;
  runningReport: boolean;
  reportError: IBeehiveError;
}

const INITIAL_STATE: IDocumentGroupState = {
  group: null,
  error: null,
  loading: false,
  saving: false,
  saveError: null,
  uploadResults: null,
  uploadError: null,
  uploading: false,
  documents: [],
  documentsError: null,
  loadingDocuments: false,
  paging: defaults.DEFAULT_PAGING,
  runningReport: false,
  reportError: null
};


const documentGroupReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IDocumentGroupState => {
  const {
    DOCUMENTGROUPS_GET,
    DOCUMENTGROUPS_CREATE,
    DOCUMENTGROUPS_UPDATE,
    DOCUMENTGROUPS_UPLOAD,
    DOCUMENTGROUPS_GETDOCUMENTS,
    DOCUMENTGROUPS_TOGGLEDOCUMENTSELECTION,
    DOCUMENTGROUPS_RUNREADDATEREPORT
  } = documentGroupActions.types;

  switch (action.type) {
    case DOCUMENTGROUPS_GET.START:
      return { ...state, loading: true, error: null };

    case DOCUMENTGROUPS_GET.SUCCESS:
      return { ...state, group: action.payload, loading: false };

    case DOCUMENTGROUPS_GET.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DOCUMENTGROUPS_CREATE.START:
    case DOCUMENTGROUPS_UPDATE.START:
      return { ...state, saving: true, saveError: null };

    case DOCUMENTGROUPS_CREATE.SUCCESS:
    case DOCUMENTGROUPS_UPDATE.SUCCESS:
      return { ...state, saving: false };

    case DOCUMENTGROUPS_CREATE.FAILED:
    case DOCUMENTGROUPS_UPDATE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: action.payload,
      };

    case DOCUMENTGROUPS_UPLOAD.START:
      return { ...state, uploading: true, uploadError: null };

    case DOCUMENTGROUPS_UPLOAD.SUCCESS:
      return { ...state, uploadResults: action.payload, uploading: false };

    case DOCUMENTGROUPS_UPLOAD.FAILED:
      return {
        ...state,
        uploading: false,
        saveError: action.payload,
      };

    case DOCUMENTGROUPS_GETDOCUMENTS.START:
      return {
        ...state,
        loadingDocuments: arrays.isEmpty(state.documents),
        documentsError: null,
      };

    case DOCUMENTGROUPS_GETDOCUMENTS.SUCCESS:
      return {
        ...state,
        documents:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.documents, ...action.payload.items],
        paging: action.payload.paging,
        loadingDocuments: false,
      };

    case DOCUMENTGROUPS_GETDOCUMENTS.FAILED:
      return {
        ...state,
        loadingDocuments: false,
        documentsError: action.payload,
      };

    case DOCUMENTGROUPS_TOGGLEDOCUMENTSELECTION:
      console.log(action.payload);
      return {
        ...state,
        documents: state.documents.map(x =>
          x.id === action.payload ? { ...x, selected: !x.selected } : x
        ),
      };

    case DOCUMENTGROUPS_RUNREADDATEREPORT.START:
      return {
        ...state,
        runningReport: true,
        reportError: null
      }
    case DOCUMENTGROUPS_RUNREADDATEREPORT.SUCCESS:
      return {
        ...state,
        runningReport: false
      }
    case DOCUMENTGROUPS_RUNREADDATEREPORT.FAILED:
      return {
        ...state,
        runningReport: false,
        reportError: action.payload
      }

    default:
      return state;
  }
};

export default documentGroupReducer;
