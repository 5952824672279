import paymentActions from "areas/payments/actions/paymentsActions";
import { IncomingReportListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface IIncomingReportState {
  working: boolean;
  error: string | null;
  data: IncomingReportListView | null;
}

const INITIAL_STATE: IIncomingReportState = {
  working: false,
  error: null,
  data: null,
};

const incomingReportReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IIncomingReportState => {

  const { 
    PAYMENTS_RUNINCOMINGREPORT 
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_RUNINCOMINGREPORT.START:
      return { 
        ...state, 
        working: true, 
        error: null 
      };

    case PAYMENTS_RUNINCOMINGREPORT.SUCCESS:
      return { 
        ...state, 
        data: action.payload, 
        working: false 
      };

    case PAYMENTS_RUNINCOMINGREPORT.FAILED:
      return {
        ...state,
        working: false,
        error: "There was a problem running the report - please note that Stripe data is only available for download up to the end of the previous day.",
      };

    default:
      return state;
  }
};

export default incomingReportReducer;
