import React, { useState, forwardRef } from "react";
import styled from "styled-components";
import Tippy from "@tippyjs/react";
import { useSelector } from "react-redux";
import {
  respondTo,
  Icon,
  CircleIcon,
  typescale,
  displayFont,
  neutral,
  honeygold,
  Speed,
  Spacing,
  BorderRadius,
  Swatches,
  Tooltip,
  fontStyle,
  Respond,
  Breakpoints,
} from "ui-kit";
import { IconColorLogo } from "sharedComponents/common/logos/beehive";
import { Link, Path, useNavigate } from "react-router-dom";
import routes from "configuration/routes";
import ProfileFlyout from "areas/administration/components/profile/profileFlyout";
import { StaffView } from "sharedComponents/common/users/userTypeView";
import navigationActions from "actions/ui/navigation";
import privacyActions from "actions/ui/privacy";
import { Constants } from "configuration";
import flyoutActions from "actions/ui/flyouts";
import CreateOnCallRequestWrapper from "areas/behaviour/components/onCall/createOnCallRequestWrapper";
import { RootState } from "reducers/store";
import { filingRoutes } from "../../../areas/filing/filingRoutes";
// import { users } from "utils";
import SearchFlyout from "areas/search/searchFlyout";
import BasketFlyout from "../../../areas/payments/components/store/basketFlyout";

const Wrapper = styled.div`
  opacity: 0.85;
  position: sticky;
  overflow-y: auto;
  top: 0;
  width: 100%;
  align-self: flex-start;
  z-index: 999;
  background: white;
  box-shadow: 0px 5px 6px -4px rgba(0, 0, 0, 0.35);
`;

const Alert = styled.div`
  background: orange;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${fontStyle(displayFont.medium, typescale.paragraph)}
`;

const EnvironmentMessage = styled.div`
  background: ${neutral[600]};
  color: ${neutral[200]};
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${fontStyle(displayFont.medium, typescale.paragraph)}
`;

const Inner = styled.div`
  height: 56px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${Spacing.Large}px;

  .header-logo {
    height: 36px;
  }
`;
const HeaderCenter = styled.div`
  display: none;
  align-items: stretch;
  justify-content: center;

  ${respondTo.l`
    display: flex;
    position: absolute;
    right: 50%;
    left: 50%;
    top: 0;
    bottom: 0;
  `}
`;

const HeaderRight = styled.div<{
  hideSensitiveInfo?: boolean;
  hideProfileImage?: boolean;
}>`
  display: flex;
  align-items: center;
  padding-right: ${Spacing.Medium}px;
  .circle-icon {
    margin-left: ${Spacing.Default}px;
  }

  .on-call-icon:hover { 
    background: ${Swatches.Danger.swatch}; 
    .icon {
      color: ${neutral[100]};
    }
  }
  
    ${({ hideSensitiveInfo }) =>
      hideSensitiveInfo &&
      `
        .sensitive-info-icon {        
          background: ${Swatches.Danger.swatch}; 
          color: ${neutral[100]};
      }`}

    ${({ hideProfileImage }) =>
      hideProfileImage &&
      `
      .profile-image-icon {        
        background: ${Swatches.Danger.swatch}; 
        color: ${neutral[100]};
      }
    `}
  }
`;

const HeaderNav = styled.ul`
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  justify-content: center;
`;

const HeaderNavItemWrapper = styled.li<{ isSelected?: boolean }>`
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: ${Spacing.Small}px 0 ${Spacing.ExtraSmall}px 0;
  border-bottom: 3px solid transparent;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${BorderRadius.Default}px;
    padding: ${Spacing.Default}px;
    width: 60px;
    margin-right: ${Spacing.Small}px;
    margin-left: ${Spacing.Small}px;
    color: ${neutral[500]};

    i {
      font-size: 24px;
    }

    &:hover {
      cursor: pointer;
      transition: all ${Speed.Short}ms ease;
      background: ${neutral[200]};
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    border-bottom: 3px solid ${honeygold};

    a:hover {
      cursor: default;
      background: none;
    }
  `}
`;

interface IHeaderNavItemProps {
  icon: string;
  route: string | Partial<Path>;
  badge?: number;
  name: string;
}

const HeaderNavItem = React.forwardRef(
  ({ icon, route, badge, name }: IHeaderNavItemProps, ref) => {
    const { title } = useSelector((state: RootState) => state.navigation);

    return (
      <HeaderNavItemWrapper
        isSelected={name === title}
        ref={ref as React.RefObject<HTMLLIElement>}
      >
        <Link to={route}>
          <Icon value={icon} badge={badge} />
        </Link>
      </HeaderNavItemWrapper>
    );
  }
);

const HeaderMenuToggleIconWrapper = styled.button`
  border: 0;
  background: 0;
  padding: 0;

  i,
  .icon {
    font-size: 24px;
    transition: all ease 150ms;
  }

  &:hover {
    cursor: pointer;
    i {
      color: ${neutral[600]};
    }
  }
`;

const HeaderMenuToggle = () => {
  const handleMenuToggleClick = () => {
    navigationActions.toggleMenu();
  };
  return (
    <HeaderMenuToggleIconWrapper onClick={handleMenuToggleClick}>
      <Icon value="bars" />
    </HeaderMenuToggleIconWrapper>
  );
};

const Header = forwardRef((props, ref) => {
  const { message: alertMessage } = useSelector(
    (state: RootState) => state.alert
  );
  const { user, database } = useSelector(
    (state: RootState) => state.currentUser
  );
  const { basket } = useSelector((state: RootState) => state.basket);
  const { isPrivate } = useSelector((state: RootState) => state.privacy);

  const navigate = useNavigate();

  const [openOnCallModal, setOpenOnCallModal] = useState(false);

  const switchSensitiveInformation = () => {
    const hideSensitiveInfo = isPrivate.sensitiveInfo;
    privacyActions.setPrivacy({
      ...isPrivate,
      sensitiveInfo: !hideSensitiveInfo,
    });
  };

  const switchProfileImage = () => {
    const hideProfileImage = isPrivate.profileImage;
    privacyActions.setPrivacy({
      ...isPrivate,
      profileImage: !hideProfileImage,
    });
  };

  const userHasOnCallEnabledSchool = (): boolean => {
    if (user.baseSchool?.enableOnCall) {
      return true;
    } else if (user.schools?.some((school: any) => school.enableOnCall)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Wrapper id="header" ref={ref as React.RefObject<HTMLDivElement>}>
        {process.env.REACT_APP_ENVIRONMENT_NAME != "Production" && (
          <EnvironmentMessage>
            {database} Database / {process.env.REACT_APP_ENVIRONMENT_NAME}{" "}
            Environment
          </EnvironmentMessage>
        )}
        {alertMessage && <Alert>{alertMessage}</Alert>}
        <Inner>
          <HeaderLeft>
            <Respond upper={Breakpoints.xs}>
              <HeaderMenuToggle />
            </Respond>
            <Respond lower={Breakpoints.xs}>
              <Link to={routes.home}>
                <IconColorLogo className="header-logo" />
              </Link>
            </Respond>
          </HeaderLeft>
          {/* <HeaderCenter>
            {!users.isFuture(user) && (
              <HeaderNav>
                <Tippy content="Home">
                  <HeaderNavItem
                    icon="home"
                    route={`${Routes.home}`}
                    light
                    name="Home"
                  />
                </Tippy>
                <Tippy
                  content={
                    <Tooltip
                      title="Assignments"
                      sub="View and manage your homework assignments"
                    />
                  }
                >
                  <HeaderNavItem
                    icon="briefcase"
                    route={`${url}${Routes.assignments.default}`}
                    light
                    name="Assignments"
                  />
                </Tippy>
                <Tippy
                  content={
                    <Tooltip title="Timetable" sub="View your timetables" />
                  }
                >
                  <HeaderNavItem
                    icon="clock"
                    route={`${url}${Routes.timetable.default}`}
                    light
                    name="Timetable"
                  />
                </Tippy>
                <Tippy
                  content={
                    <Tooltip
                      title="Store"
                      sub="Buy books, trips and other items online"
                    />
                  }
                >
                  <HeaderNavItem
                    icon="shopping-bag"
                    route={`${url}${Routes.store.default}`}
                    light
                    name="Store"
                  />
                </Tippy>
                <Tippy
                  content={
                    <Tooltip
                      title="Smartcard"
                      sub="Check smartcard and print credit balances and buy topups"
                    />
                  }
                >
                  <HeaderNavItem
                    icon="id-card-alt"
                    route={`${url}${Routes.smartcard.default}`}
                    light
                    name="Smartcard"
                  />
                </Tippy>
              </HeaderNav>
            )}
          </HeaderCenter> */}
          <HeaderRight
            hideSensitiveInfo={isPrivate.sensitiveInfo}
            hideProfileImage={isPrivate.profileImage}
          >
            <StaffView>
              {userHasOnCallEnabledSchool() ? (
                <CircleIcon
                  className="on-call-icon"
                  value="bullhorn"
                  onClick={() => setOpenOnCallModal(true)}
                  tooltip="On Call Assistance"
                  tooltipSub="Request on call assistance"
                />
              ) : (
                <></>
              )}
              <CircleIcon
                className="profile-image-icon"
                value="portrait"
                onClick={switchProfileImage}
                tooltip="Profile Image"
                tooltipSub="Enable or disable profile images on screen"
              />
              <CircleIcon
                className="sensitive-info-icon"
                value={isPrivate.sensitiveInfo ? "eye-slash" : "eye"}
                onClick={switchSensitiveInformation}
                tooltip="Sensitive Information"
                tooltipSub="Enable or disable sensitive information on screen"
              />
              <CircleIcon
                value="inbox"
                onClick={() =>
                  navigate(`${routes.home}${filingRoutes.default}`)
                }
                tooltip="In Tray"
                tooltipSub="View your incoming and outgoing forms and holiday requests"
              />
              <CircleIcon
                value="search"
                onClick={() =>
                  flyoutActions.openFlyout(Constants.FLYOUTS.SEARCH)
                }
                tooltip="Search"
                tooltipSub="Search students, class groups and the internal phonebook"
              />
            </StaffView>

            <CircleIcon
              value="shopping-bag"
              onClick={() => flyoutActions.openFlyout(Constants.FLYOUTS.BASKET)}
              badge={basket?.items?.length}
              tooltip="Your Basket"
              tooltipSub="View the contents of your basket and checkout securely"
            />
            {/* <CircleIcon
              value="bell"
              onClick={() => openFlyoutAction(Constants.FLYOUTS.NOTIFICATIONS)}
              /> */}

            <CircleIcon
              value="user"
              onClick={() =>
                flyoutActions.openFlyout(Constants.FLYOUTS.ACCOUNT)
              }
              tooltip="Your Account"
              tooltipSub="View your account details"
            />
          </HeaderRight>
        </Inner>
      </Wrapper>

      <CreateOnCallRequestWrapper
        openModal={openOnCallModal}
        handleCloseModal={() => setOpenOnCallModal(false)}
        students={null}
        handleUnselectAllStudents={null}
        groupType={null}
        groupId={null}
        schoolId={null}
      />

      <ProfileFlyout />

      <BasketFlyout />
      <SearchFlyout />
    </>
  );
});

export default Header;
