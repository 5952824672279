import React from "react";
import styled from "styled-components";
import { Avatar } from "sharedComponents/common";
import SubmissionStatus from "../submissionStatus";
import { Card, HtmlText, Label, EmptyMessage } from "ui-kit";
import moment from "moment";
import { arrays } from "utils";
import { SubmissionHistoryView } from "areas/forms/types/formResponse.types";


const ListWrapper = styled.div``;


interface ISubmissionHistoryListProps {
  history: SubmissionHistoryView[];
}


const SubmissionHistoryList: React.FC<ISubmissionHistoryListProps> = ({ history }) => {

  if (arrays.isEmpty(history)) {
    return (
      <EmptyMessage
        icon="history"
        title="No history found"
        summary="No history has been added for this submission yet"
        cover
      />
    );
  }

  return (
    <ListWrapper>
      {history.map((history: SubmissionHistoryView, index: number) => (
        <SubmissionHistoryListItem key={index} history={history} />
      ))}
    </ListWrapper>
  );
};

const SubmissionHistoryListItem = ({ history } : { history: SubmissionHistoryView }) => {
  return (
    <Card>
      <Card.Header>
        <Avatar
          user={history.createdBy}
          sub={moment(history.createdDate).format("DD/MM/YYYY h:mm a")}
        />
      </Card.Header>
      <Card.Body>
        <Label bold>
          Status changed to {<SubmissionStatus status={history.status} />}
        </Label>

        <HtmlText html={history.notes} />
      </Card.Body>
    </Card>
  );
};

export default SubmissionHistoryList;
