import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SearchableDropdown from "sharedComponents/common/searchableDropdown";

const ClassLayoutCreatorRoomDropdown = ({
  classGroupRooms,
  schoolRooms,
  selectedRoom,
  handleRoomChange,
}) => {
  const [allSchoolRooms, setAllSchoolRooms] = useState([]);
  const [allRooms, setAllRooms] = useState([]);

  useEffect(() => {
    setAllSchoolRooms(schoolRooms);
  }, [schoolRooms]);

  useEffect(() => {
    let allRoomsArray = [];
    if (
      classGroupRooms &&
      classGroupRooms.length > 0 &&
      classGroupRooms[0] !== null
    ) {
      const classGroupRoomsArray = classGroupRooms.map(room => ({
        key: room.roomId,
        label: room.roomName,
        value: room.roomId,
      }));

      allRoomsArray = allRoomsArray.concat(classGroupRoomsArray);

      if (allSchoolRooms.length > 0) {
        classGroupRooms.forEach(room => {
          const index = allSchoolRooms.findIndex(
            schoolRoom => schoolRoom.roomId === room.roomId
          );
          allSchoolRooms.splice(index, 1);
        });

        const schoolRoomsArray = allSchoolRooms.map(room => ({
          key: room.roomId,
          label: room.roomName,
          value: room.roomId,
        }));

        allRoomsArray = allRoomsArray.concat(schoolRoomsArray);
      }
    } else if (allSchoolRooms.length > 0) {
      const schoolRoomsArray = allSchoolRooms.map(room => ({
        key: room.roomId,
        label: room.roomName,
        value: room.roomId,
      }));

      allRoomsArray = allRoomsArray.concat(schoolRoomsArray);
    }
    setAllRooms(allRoomsArray);
  }, [allSchoolRooms, classGroupRooms]);

  return (
    <SearchableDropdown
      placeholder="Search/Choose a Room"
      items={allRooms}
      onChange={handleRoomChange}
      value={selectedRoom}
      fluid
    />
  );
};

ClassLayoutCreatorRoomDropdown.propTypes = {
  classGroupRooms: PropTypes.array,
  schoolRooms: PropTypes.array,
  selectedRoom: PropTypes.number,
  handleRoomChange: PropTypes.func,
};

export default ClassLayoutCreatorRoomDropdown;
