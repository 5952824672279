export enum FieldType {
  String = 0,
  Integer = 1,
  Currency = 2,
  Date = 3,
  TextArea = 4,
  Decimal = 5,
  Time = 6,
  Hours = 7,
  Minutes = 8,
  Month = 9,
  Email = 10,
  Telephone = 11,
  Boolean = 12,
  Option = 13,
}

export enum FormStaffType {
  All = 0,
  Teaching = 1,
  Associate = 2,
}
