import React from "react";
import { Chip, Swatches } from "ui-kit";
import { Constants } from "configuration";
import { DeliveryStatus as DStatus } from "../types/mailoutShared.types"

interface IDeliveryStatusProps {
  status: DStatus;
}

const DeliveryStatus: React.FC<IDeliveryStatusProps> = ({ status }) => {

  var swatch = null;
  var name = "";

  switch (status) {
    case Constants.DELIVERY_STATUS.QUEUED.value:
      swatch = Swatches.Low;
      name = Constants.DELIVERY_STATUS.QUEUED.name;
      break;
    case Constants.DELIVERY_STATUS.DELIVERED.value:
      swatch = Swatches.Success;
      name = Constants.DELIVERY_STATUS.DELIVERED.name;
      break;
    case Constants.DELIVERY_STATUS.FAILED.value:
      swatch = Swatches.Danger;
      name = Constants.DELIVERY_STATUS.FAILED.name;
      break;
    default:
      return null;
  }

  return <Chip text={name} colorSwatch={swatch} />;
};

export default DeliveryStatus;
