export interface SyncReportsFilter {
  schoolId: number;
  type?: number | null;
  simsId: string | null;
}

export interface SyncUserWarningFilter {
  schoolId: number;
  searchTerm: string | null;
  userType: number | null;
}

export enum UserType {
  parent = 0,
  staff = 1,
  student = 2,
}
