import { useWindowSize } from "../../hooks/index";

interface IRespondProps {
  upper?: number;
  lower?: number;
  children?: any | any[];
}

const Respond: React.FC<IRespondProps> = ({ upper, lower, children }) => {
  const [windowWidth] = useWindowSize();

  if (
    (upper == null || upper > windowWidth) &&
    (lower == null || lower <= windowWidth)
  ) {
    return children;
  }

  return null;
};

export default Respond;
