import React from "react";
import styled from "styled-components";
import { Swatches, BorderRadius, Spacing, Icon, Label, Sub } from "../index";
import { ColorSwatch } from "../common/colors";

export interface IMessageProps {
  text: string | React.ReactNode;
  subtext?: string;
  color?: ColorSwatch;
  badge?: number | null;
  icon?: string;
  fluid?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  onClick?: () => void;
  jumpTo?: void;
}

//Can't use param name "color" as there is no way to override the styledcomponent base type parameter
const Wrapper = styled.div<{
  colorSwatch?: ColorSwatch;
  fluid?: boolean;
  text: string | React.ReactNode;
  subtext?: string;
}>`
  display: ${props => (props.fluid ? "flex" : "inline-flex")};
  width: 100%;
  box-sizing: border-box;
  position: relative;
  align-items: ${props =>
    props.text && props.subtext ? "flex-start" : "center"};
  background: ${({ colorSwatch }) => (colorSwatch ? colorSwatch.swatch : "")};
  color: ${({ colorSwatch }) => (colorSwatch ? colorSwatch.foreground : "")};
  border-radius: ${BorderRadius.Default}px;
  padding: ${Spacing.Default}px ${Spacing.Large}px;
  margin-bottom: ${Spacing.Large}px;

  .icon {
    padding-top: ${props =>
      props.text && props.subtext ? Spacing.ExtraSmall + "px" : "0"};
    margin-right: ${Spacing.Default}px;
    color: ${({ colorSwatch }) => (colorSwatch ? colorSwatch.foreground : "")};
  }

  .label {
    display: block;
    color: ${({ colorSwatch }) => (colorSwatch ? colorSwatch.foreground : "")};
  }
  .sub {
    display: block;
    color: ${({ colorSwatch }) => (colorSwatch ? colorSwatch.foreground : "")};
  }

  ul {
    color: ${({ colorSwatch }) => (colorSwatch ? colorSwatch.foreground : "")};
    padding-left: ${Spacing.Default}px;
  }
  .message-right {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  a {
    color: ${({ colorSwatch }) => (colorSwatch ? colorSwatch.foreground : "")};
    text-decoration: underline;
  }

  ${props =>
    props.onClick &&
    `&:hover {
    cursor: pointer;
  }`}
`;

const Message: React.FC<IMessageProps> = ({
  text,
  subtext,
  color,
  icon,
  fluid,
  children,
  className,
  onClick,
}) => {
  return text ? (
    <Wrapper
      className={`message ${className ? className : ""}`}
      colorSwatch={color}
      fluid={fluid}
      text={text}
      subtext={subtext}
      onClick={onClick}
    >
      {icon && <Icon value={icon} />}
      <div className="message-body">
        <Label bold>{text}</Label>
        {subtext && <Sub>{subtext}</Sub>}
      </div>

      {children && <div className="message-right">{children}</div>}
    </Wrapper>
  ) : null;
};

Message.defaultProps = {
  color: Swatches.Default,
  fluid: false,
};

export default Message;
