import store from "reducers/store";
import { IBeehiveAction } from "types/common/action.types";
import { FormCategoryListView, FormListView } from "../types/formResponse.types";

const types = {
  APPROVALSFILTERBYCATEGORY: "submissionapprovalfilterbycategory",
  APPROVALSFILTERBYFORMTYPE: "submissionapprovalfilterbyformtype",
  APPROVALSFILTERBYFORMSTATUS: "submissionapprovalfilterbyformstatus",
  APPROVALSFILTERBYFORMUSER: "submissionapprovalfilterbyformuser",
  APPROVALSFILTERBYSCHOOL: "submissionapprovalfilterbyschool",
};


const filterByCategory = (category: FormCategoryListView) => {
  store.dispatch<IBeehiveAction<FormCategoryListView>>({
    type: types.APPROVALSFILTERBYCATEGORY,
    payload: category,
  });
};

const filterByFormType = (formType: FormListView) => {
  store.dispatch<IBeehiveAction<FormListView>>({
    type: types.APPROVALSFILTERBYFORMTYPE,
    payload: formType,
  });
};

const filterByFormStatus = (formStatus: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.APPROVALSFILTERBYFORMSTATUS,
    payload: formStatus,
  });
};

const filterByFormUser = (userId: string) => {
  store.dispatch<IBeehiveAction<string>>({
    type: types.APPROVALSFILTERBYFORMUSER,
    payload: userId,
  });
};

const filterBySchool = (schoolId: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.APPROVALSFILTERBYSCHOOL,
    payload: schoolId,
  });
};


const submissionApprovalFilterActions = {
  types,
  filterByCategory,
  filterByFormType,
  filterByFormStatus,
  filterByFormUser,
  filterBySchool,
};

export default submissionApprovalFilterActions;
