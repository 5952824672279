import defaults from "configuration/defaults";
import { arrays } from "utils";
import bulletinActions from "../../actions/bulletinActions";
import { IBeehiveAction } from "types/common/action.types";
import { PageInfo } from "types/common/paging.types";
import { ArticleListView, BulletinDetailView } from "areas/news/types/newsResponse.types";

interface IBulletinState {
  bulletin: BulletinDetailView | null;
  articles: ArticleListView[];
  testRecipients: string[];
  paging: PageInfo;
  preview: string | null;
  actions: {
    load: { working: boolean; error: string | null; };
    save: { working: boolean; error: string | null; };
    send: { working: boolean; error: string | null; };
    testSend: { working: boolean; error: string | null; };
    approve: { working: boolean; error: string | null; };
    preview: { working: boolean; error: string | null; };
    getArticles: { working: boolean; error: string | null; };
  };
}

const INITIAL_STATE: IBulletinState = {
  bulletin: null,
  articles: [],
  testRecipients: [],
  paging: defaults.DEFAULT_PAGING,
  preview: null,
  actions: {
    load: { working: false, error: null },
    save: { working: false, error: null },
    send: { working: false, error: null },
    testSend: { working: false, error: null },
    approve: { working: false, error: null },
    preview: { working: false, error: null },
    getArticles: { working: false, error: null },
  },
};

const bulletinReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IBulletinState => {

  const {
    BULLETIN_GET,
    BULLETIN_SAVE,
    BULLETIN_CREATE,
    BULLETIN_SEND,
    BULLETIN_TESTSEND,
    BULLETIN_APPROVE,
    BULLETIN_GETARTICLES,
    BULLETIN_PREVIEW,
    BULLETIN_UPDATETESTRECIPIENTS,
  } = bulletinActions.types;

  switch (action.type) {
    case BULLETIN_GET.START:
      return {
        ...INITIAL_STATE,
        actions: { ...state.actions, load: { working: true, error: null } },
      };

    case BULLETIN_GET.SUCCESS:
      return {
        ...state,
        bulletin: action.payload,
        actions: { ...state.actions, load: { working: false, error: null } },
      };

    case BULLETIN_GET.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          load: {
            working: false,
            error: "There was a problem loading the bulletin.",
          },
        },
      };

    case BULLETIN_SAVE.START:
    case BULLETIN_CREATE.START:
      return {
        ...state,
        actions: { ...state.actions, save: { working: true, error: null } },
      };

    case BULLETIN_SAVE.SUCCESS:
    case BULLETIN_CREATE.SUCCESS:
      return {
        ...state,
        bulletin: action.payload,
        actions: { ...state.actions, save: { working: false, error: null } },
      };

    case BULLETIN_SAVE.FAILED:
    case BULLETIN_CREATE.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          save: {
            working: false,
            error: "There was a problem saving the bulletin.",
          },
        },
      };

    case BULLETIN_SEND.START:
      return {
        ...state,
        actions: { ...state.actions, send: { working: true, error: null } },
      };

    case BULLETIN_SEND.SUCCESS:
      if (!state.bulletin) {
        return state;
      }
      return {
        ...state,
        bulletin: {
          ...state.bulletin,
          sendCount: state.bulletin?.sendCount + 1,
          //sendHistory: [action.payload, ...state.bulletin?.sendHistory],
        },
        actions: { ...state.actions, send: { working: false, error: null } },
      };

    case BULLETIN_SEND.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          send: {
            working: false,
            error: "There was a problem sending the bulletin.",
          },
        },
      };

    case BULLETIN_UPDATETESTRECIPIENTS:
      return {
        ...state,
        testRecipients: action.payload,
      };

    case BULLETIN_TESTSEND.START:
      return {
        ...state,
        actions: { ...state.actions, testSend: { working: true, error: null } },
      };

    case BULLETIN_TESTSEND.SUCCESS:
      if (!state.bulletin) {
        return state;
      }
      return {
        ...state,
        bulletin: {
          ...state.bulletin,
          sendCount: state.bulletin?.sendCount + 1,
          //sendHistory: [action.payload, ...state.bulletin?.sendHistory],
        },
        actions: {
          ...state.actions,
          testSend: { working: false, error: null },
        },
      };

    case BULLETIN_TESTSEND.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          testSend: {
            working: false,
            error: "There was a problem sending the test bulletin.",
          },
        },
      };

    case BULLETIN_APPROVE.START:
      return {
        ...state,
        actions: { ...state.actions, approve: { working: true, error: null } },
      };

    case BULLETIN_APPROVE.SUCCESS:
      return {
        ...state,
        bulletin: action.payload,
        actions: {
          ...state.actions,
          approve: { working: false, error: null },
        },
      };

    case BULLETIN_APPROVE.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          approve: {
            working: false,
            error: "There was a problem sending the test bulletin.",
          },
        },
      };

    case BULLETIN_PREVIEW.START:
      return {
        ...state,
        actions: { ...state.actions, preview: { working: true, error: null } },
      };

    case BULLETIN_PREVIEW.SUCCESS:
      return {
        ...state,
        preview: action.payload,
        actions: {
          ...state.actions,
          preview: { working: false, error: null },
        },
      };

    case BULLETIN_PREVIEW.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          preview: {
            working: false,
            error: "There was a problem previewing the bulletin.",
          },
        },
      };

    case BULLETIN_GETARTICLES.START:
      return {
        ...state,
        actions: {
          ...state.actions,
          getArticles: {
            working: arrays.isEmpty(state.articles),
            error: null,
          }
        }
      };

    case BULLETIN_GETARTICLES.SUCCESS:
      return {
        ...state,
        articles:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.articles, ...action.payload.items],
        paging: action.payload.paging,
        actions: {
          ...state.actions,
          getArticles: {
            working: false,
            error: null,
          }
        }
      };

    case BULLETIN_GETARTICLES.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          getArticles: {
            working: false,
            error: action.payload,
          }
        }
      };

    default:
      return state;
  }
};

export default bulletinReducer;
