import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import FeedFilters from "areas/planner/components/activityFeed/feedFilters";
import groupActions from "areas/planner/actions/group/groupActions";
import { useAppSelector } from "reducers/hooks";


const GroupFeedFilters: React.FC = () => {

  const { feedTypeFilter, createdDateFilter } = useAppSelector(
    state => state.groupActivity
  );

  const [_feedTypeFilter, _setFeedTypeFilter] = useState(feedTypeFilter);
  const [_createdDateFilter, _setCreatedDateFilter] =
    useState(createdDateFilter);

  useEffect(() => {
    if (feedTypeFilter !== _feedTypeFilter) {
      groupActions.setGroupFeedTypeFilter(_feedTypeFilter);
    }
  }, [_feedTypeFilter]);

  useEffect(() => {
    if (createdDateFilter !== _createdDateFilter) {
      groupActions.setGroupCreatedDateFilter(_createdDateFilter);
    }
  }, [_createdDateFilter]);

  return (
    <FeedFilters
      selectedActivityFilter={_feedTypeFilter}
      setSelectedActivityFilter={_setFeedTypeFilter}
      selectedDateFilter={_createdDateFilter}
      setSelectedDateFilter={_setCreatedDateFilter}
    />
  );
};

export default GroupFeedFilters;
