import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Loader, Size, Flyout } from "ui-kit";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import studentActions from "areas/planner/actions/student/studentActions";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import StudentProfileHeader from "sharedComponents/common/users/studentProfileHeader";
import StudentStats from "areas/planner/components/studentRecord/studentStats";
import ReadOnlyDetails from "areas/behaviour/components/readOnly/readOnlyDetails";
import { useAppSelector } from "reducers/hooks";


const PendingAttentionBehaviourDetailsWrapper: React.FC = () => {

  const { behaviour, loading, updateBehaviourError } = useAppSelector(state => state.behaviour);
  const { selectedBehaviour: pendingAttentionBehaviour } = useAppSelector(
    state => state.pendingAttentionBehaviours
  );
  const { student, loading: loadingStudent } = useAppSelector(
    state => state.student
  );

  useEffect(() => {
    if (pendingAttentionBehaviour) {
      behaviourActions.getBehaviour(
        pendingAttentionBehaviour.student.id,
        pendingAttentionBehaviour.behaviourId,
        null
      );
      studentActions.getStudent(pendingAttentionBehaviour.student.id);
    }
  }, [pendingAttentionBehaviour]);

  if (loading || loadingStudent) {
    return <Loader size={Size.Large} cover />;
  }

  if (updateBehaviourError) {
    return <ApiExceptionMessage error={updateBehaviourError} />;
  }

  return (
    pendingAttentionBehaviour &&
    student &&
    behaviour?.behaviour?.behaviourId ===
      pendingAttentionBehaviour.behaviourId && (
      <Flyout.Body>
        <StudentProfileHeader student={student} allowNavigation />
        <StudentStats student={student} />
        <ReadOnlyDetails behaviour={behaviour?.behaviour} />
      </Flyout.Body>
    )
  );
};


export default PendingAttentionBehaviourDetailsWrapper;
