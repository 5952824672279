import React from "react";
import { Modal, Spacing } from "ui-kit";
import styled from "styled-components";
import StudentProfileHeader from "./studentProfileHeader";

const Wrapper = styled.div`
  display: flex;

  .content-wrapper {
    margin-left: 200px;
    flex-grow: 1;
  }
`;
const StudentDetailsWrapper = styled.div`
  width: 200px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${Spacing.Default}px;
`;


interface IStudentModalProps {
  student: any;
  title?: string;
  onClose?: () => void;
  open?: boolean;
  children?: any | any[];
  width?: string;
  height?: string;
  footer?: JSX.Element;
  loading?: boolean;
  profile?: JSX.Element | JSX.Element[];
}

const StudentModal: React.FC<IStudentModalProps> = ({
  student,
  title,
  onClose,
  open,
  children,
  width,
  height,
  footer,
  loading,
  profile,
}) => {
  return (
    <Modal
      title={title}
      onClose={onClose}
      open={open}
      width={width}
      height={height}
      loading={loading}
    >
      <Modal.Body>
        <Wrapper>
          <StudentDetailsWrapper>
            <StudentProfileHeader student={student} />
            {profile}
          </StudentDetailsWrapper>
          <div className="content-wrapper">{children}</div>
        </Wrapper>
      </Modal.Body>
      {footer ? <Modal.Footer>{footer}</Modal.Footer> : <></>}
    </Modal>
  );
};

export default StudentModal;
