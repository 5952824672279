import { AssignmentGroupBy } from "../types/assignmentAnalyticsShared.types";


export const GROUP_BY_ARRAY = [
  {
    label: "None",
    value: AssignmentGroupBy.None
  },
  {
    label: "Teacher",
    value: AssignmentGroupBy.Staff
  },
  {
    label: "Subject",
    value: AssignmentGroupBy.Subject
  },
  {
    label: "Class Group",
    value: AssignmentGroupBy.ClassGroup
  },
  {
    label: "Tutor Group",
    value: AssignmentGroupBy.TutorGroup
  },
  {
    label: "Custom Group",
    value: AssignmentGroupBy.CustomGroup
  },
  {
    label: "Year Group",
    value: AssignmentGroupBy.YearGroup
  }
];
