import React, { useState, useEffect } from "react";
import { useAppSelector } from "reducers/hooks";
import { EmptyMessage, Loader, Size } from "ui-kit";
import PupilPassportViewer from "../passports/pupilPassportViewer";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";
import { PassportDetailView } from "areas/send/types/passportResponse.types";
import { arrays } from "utils";
import { PassportVersionStatus } from "areas/send/types/passportShared.types";
import passportActions from "areas/send/actions/passports/passportActions";



interface IParentPassportViewProps {
  student: PlannerStudentDetailView;
}


const ParentPassportView: React.FC<IParentPassportViewProps> = ({ student }) => {

  const { 
    passports, 
    activeStudent 
  } = useAppSelector(state  => state.passports);
  const {
    passport,
    loading: loadingPassport,
    error: passportError,
  } = useAppSelector(state  => state.passport);

  const [_passport, _setPassport] = useState<PassportDetailView | null>(null);

  const validStudent = () => student && (student.id == activeStudent || activeStudent == null);

  useEffect(() => {
    if (validStudent()) {
      passportActions.getPassportsForStudent(student.id)
    }
  }, [activeStudent, student]);

  useEffect(() => {
    if (validStudent() && 
      !arrays.isEmpty(passports) && 
      (passports[0].student.id == student.id)
    ) {
      var _selectedPassport = passports.find((p => p.versions.some(v => v.status === PassportVersionStatus.Published)));
      if (_selectedPassport && !arrays.isEmpty(_selectedPassport.versions)) {
        passportActions.getPassport(student.id, _selectedPassport.id);
      }
    }
  }, [passports])

  useEffect(() => {
    if (passport && validStudent()) {
      _setPassport(passport);
    }
    else {
      _setPassport(null);
    }
  }, [passport])

  return (
    <>
    {loadingPassport ? (
        <Loader cover size={Size.Medium} />
      ) : passportError ? (
        <EmptyMessage
          cover
          title="An error occured"
          icon="times-circle"
          summary="There was a problem loading the selected passport"
        />
      ) : _passport == null ? (
        <EmptyMessage
          cover
          title="No passport found"
          icon="hand-holding-seedling"
          summary="There are no live passports for this user"
        />
      ) : (
        <PupilPassportViewer
          passport={_passport}
          student={student}
          readonly
        />
      )}
    </>
  );
}


export default ParentPassportView;