import React, { useEffect, useState } from "react";
import onCallActions from "areas/behaviour/actions/onCall/onCallActions";
import { addGroupIdToPayload } from "areas/planner/utils/group";
import OnCallModal from "./onCallModal";
import { useAppSelector } from "reducers/hooks";
import { StudentListView, UserTinyView } from "types/users/userListViews.types";
import { GroupType } from "types/planner/groups.types";
import { OnCallRequestListView } from "areas/analytics/reducers/onCallAnalyticsResponse.types";


interface ICreateOnCallRequestWrapperProps {
  students: StudentListView[];
  handleUnselectAllStudents?: () => void;
  groupType?: GroupType;
  groupId?: number;
  schoolId?: number;
  openModal: boolean;
  handleCloseModal: () => void;
}


const CreateOnCallRequestWrapper: React.FC<ICreateOnCallRequestWrapperProps> = ({
  students,
  handleUnselectAllStudents,
  groupType,
  groupId,
  schoolId: initialSchoolId,
  openModal,
  handleCloseModal,
}) => {

  const { actions } = useAppSelector(state => state.onCallRequest);
  const { user } = useAppSelector(state => state.currentUser);

  const [schoolId, setSchoolId] = useState<number | null>();

  useEffect(() => {
    initialSchoolId
      ? setSchoolId(initialSchoolId)
      : setSchoolId(user.baseSchool?.id);
  }, []);

  const onChangeSchool = (value: number) => {
    setSchoolId(value);
  };

  const handleRequestOnCall = (onCallRequest: any, closeConfirmModal?: () => void) => {
    const studentIds = onCallRequest.students.map((student: UserTinyView) => student.id);

    let newOnCall = {
      studentIds: studentIds,
      schoolId: schoolId,
      roomId: onCallRequest.roomId,
      categoryId: onCallRequest.category.categoryDetail.id,
      notes: onCallRequest.notes,
    };

    if (groupId) {
      newOnCall = addGroupIdToPayload(newOnCall, groupId, groupType);
    }

    const callback = () => {
      handleCloseModal();
      handleUnselectAllStudents?.();
    };

    onCallActions.createOnCallRequest(schoolId, newOnCall, callback);
    closeConfirmModal?.();
  };


  return (
    <OnCallModal
      onCallRequest={{
        students: students ? students : [],
        roomId: null,
        category: null,
        notes: "",
        id: null,
        requestedBy: null,
        createdDate: null,
        claimedBy: null,
        claimedDate: null,
        classGroup: null,
        status: null,
        tutorGroup: null,
        provisionGroup: null,
        roomName: null
      }}
      schoolId={schoolId}
      handleSchoolChange={onChangeSchool}
      openModal={openModal}
      handleCloseModal={handleCloseModal}
      handleSaveOnCall={handleRequestOnCall}
      loadingAction={actions.createOnCallRequest.isLoading}
      error={actions.createOnCallRequest.error}
    />
  );
};


export default CreateOnCallRequestWrapper;
