import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { neutral, BorderRadius, honeygold } from "ui-kit";
import PrimaryCharacter from "sharedComponents/common/primary/characters/primaryCharacter";
import { ClassLayoutStudentView } from "areas/planner/types/classLayoutResponse.types";
import { UserTinyView } from "types/users/userListViews.types";


const Wrapper = styled.div<{selected?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  //   background-color: ${neutral[400]};
  border-radius: ${BorderRadius.Default}px;
  transition: all ease 200ms;
  flex-grow: 1;

  .primary-student-name {
    color: ${neutral[700]};
    font-family: "neue-haas-grotesk-display";
    font-weight: bold;
    font-size: 14px;
    position: absolute;
    bottom: 3px;
    height: 60px;
    max-width: 80px;
    text-align: center;
    right: 0;
    display: block;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &:hover {
    cursor: pointer;

    svg {
    }

    .primary-avatar .inner .label {
      color: ${neutral[100]};
    }
  }

  ${({ selected }) =>
    selected &&
    `
    background: top url("/images/primary/star.svg") no-repeat;

      .primary-avatar .inner .label {
        color: ${neutral[100]};
      }

      .primary-student-name {
        color: ${honeygold};
      }
  `}
`;


interface IWhiteboardClassLayoutStudentProps {
  student: ClassLayoutStudentView;
  handleOpenBehaviourModal: (students: ClassLayoutStudentView[]) => void;
  handleSelectStudent: (student: ClassLayoutStudentView) => void;
  selectedStudents: UserTinyView[];
}


const WhiteboardClassLayoutStudent: React.FC<IWhiteboardClassLayoutStudentProps> = ({
  student,
  handleOpenBehaviourModal,
  handleSelectStudent,
  selectedStudents,
}) => {

  const [selected, setSelected] = useState<boolean>(false);

  useEffect(() => {
    if (selectedStudents) {
      const studentInArray = selectedStudents.find(
        stu => stu.id === student.id
      );

      if (studentInArray !== undefined) {
        setSelected(true);
      } else {
        setSelected(false);
      }
    }
  }, [student, selectedStudents]);

  return (
    <Wrapper
      className="student"
      onClick={() => handleSelectStudent(student)}
      onDoubleClick={() => handleOpenBehaviourModal([student])}
      selected={selected}
    >
      <PrimaryCharacter code={student.primaryAvatarId} />
      <div className="primary-student-name">
        <span>{student.firstName}</span>
      </div>
    </Wrapper>
  );
};

export default WhiteboardClassLayoutStudent;
