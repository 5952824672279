import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ActionBar, Dropdown, FilterButton, Left, Right } from "ui-kit";
import NewAssignmentButton from "./newAssignmentButton";
import { Constants } from "configuration";
import {
  NonStaffView,
  StaffView,
} from "sharedComponents/common/users/userTypeView";
import assignmentsActions from "areas/planner/actions/assignments/assignmentsActions";
import filters, { AssignmentFilterType } from "areas/planner/constants/assignments/assignmentsFilters";
import { useAppSelector } from "reducers/hooks";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import managedStaffActions from "areas/humanResources/actions/managedStaffActions";
import { arrays } from "utils";
import { AssignmentGrouping, AssingmentGroupingFilter } from "areas/planner/reducers/assignments/assignmentsReducer";
import subjectActions from "areas/planner/actions/subjectActions";
import ManagedStaff from "areas/humanResources/components/pm/managedStaff";
import { ManagedStaffList } from "sharedComponents/common";
import { StaffListView } from "types/users/userListViews.types";
import staffMemberActions from "areas/humanResources/actions/staffMemberActions";
import { GroupListView } from "types/users/userGroups.types";


interface IAssignmentsFilterProps {
  hideNewAssignmentButton?: boolean;
  userType?: string;
}


const AssignmentsFilter: React.FC<IAssignmentsFilterProps> = ({ hideNewAssignmentButton, userType }) => {

  const { user } = useAppSelector(state => state.currentUser);
  const { filter: currentFilter, grouping: currentGrouping } = useAppSelector(state => state.assignments);

  const [options, setOptions] = useState<IDropdownDataItemProps<string, number, AssingmentGroupingFilter>[]>([
    { label: "My Assignments", value: -1, data: { value: AssignmentGrouping.Owned, dropdownValue: -1 } },
    { label: "Shared Groups", value: -2, data: { value: AssignmentGrouping.Shared, dropdownValue:  -2 } }
  ]);

  const setSubjects = (subjects: GroupListView[]) => {
    if (!arrays.isEmpty(subjects)) {
      setOptions(prev => [
        ...prev, 
        ...subjects
          .filter(s => !prev.some(p => p.value === s.id))
          .map(s => ({ 
            label: s.name, 
            value: s.id, 
            data: { value: AssignmentGrouping.Department, dropdownValue: s.id, subjectId: s.id }
          }))
      ]);
    }
  }

  useEffect(() => {
    if (user) {
      user.isManager && setOptions(prev => [...prev, { label: "My Staff", value: -3, data: { value: AssignmentGrouping.ManagedStaff, dropdownValue: -3 } }]);
      subjectActions.getSubjectsAvailableToStaff(user.id, setSubjects);
    }
  }, [user]);

  const onGroup = (newGrouping: number, label: string, data?: AssingmentGroupingFilter) => {
    if (
      data.value !== currentGrouping.value ||
      data.subjectId !== currentGrouping.subjectId
    ) {
      assignmentsActions.groupAssignments(data);
    }
  }

  const onFilter = (newFilter: AssignmentFilterType) => {
    if (newFilter.value != currentFilter.value) {
      assignmentsActions.filterAssignments(newFilter);
    }
  }

  const handleOnStaffLoaded = (managedStaff: StaffListView[]) => {
    if (!arrays.isEmpty(managedStaff)) {
      assignmentsActions.groupAssignments({
        value: AssignmentGrouping.ManagedStaff,
        dropdownValue: -3,
        subjectId: null,
        staffId: managedStaff[0].id
      });
    }
  };

  const handleStaffMemberSelected = (staffMember: StaffListView) => {
    assignmentsActions.groupAssignments({
      value: AssignmentGrouping.ManagedStaff,
      dropdownValue: -3,
      subjectId: null,
      staffId: staffMember.id
    });
  };

  const FilterButtons = ({ filterArray } : {filterArray: AssignmentFilterType[]}) => {
    return filterArray.map((filter, index) => (
      <FilterButton
        key={index}
        text={filter.name}
        active={filter.value === currentFilter.value}
        onClick={() => onFilter(filter)}
        tooltip={filter.tooltip}
        tooltipSub={filter.tooltipSub}
      />
    ));
  };


  return (
    <>
    <ActionBar>

      <Left>
        <StaffView>
          <Dropdown
            placeholder="Assignments"
            items={options}
            onChange={onGroup}
            value={currentGrouping.dropdownValue}
          />
        </StaffView>

        <FilterButtons filterArray={filters.all} />

        <StaffView>
          {currentGrouping.value === AssignmentGrouping.Owned ? (
            <FilterButtons filterArray={filters.staffSelf} />
          )
          : (
            <FilterButtons filterArray={filters.staffAll} />
          )}
        </StaffView>

        <NonStaffView>
          <FilterButtons filterArray={filters.students} />
        </NonStaffView>

      </Left>

      <Right>
        <StaffView>
          {!hideNewAssignmentButton && <NewAssignmentButton />}
        </StaffView>
      </Right>
      
    </ActionBar>

    {currentGrouping.value === AssignmentGrouping.ManagedStaff && (
      <StaffView>
        <ManagedStaffList
          manager={user}
          onLoaded={handleOnStaffLoaded}
          onStaffMemberSelected={handleStaffMemberSelected}
        />
      </StaffView>
    )}
    
    </>
  );
};

export default AssignmentsFilter;
