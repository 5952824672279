import React from "react";
import { TextInput, TextInputType } from "ui-kit";


interface IPublicNotesFormFieldProps {
  notes?: string;
  handleNotesChange: (value: string) => void;
}


const PublicNotesFormField: React.FC<IPublicNotesFormFieldProps> = ({ notes, handleNotesChange }) => {
  return (
    <TextInput
      type={TextInputType.Textarea}
      rows={3}
      fluid
      placeholder="Public Notes"
      maxLength={250}
      value={notes}
      onChange={handleNotesChange}
    />
  );
};

export default PublicNotesFormField;
