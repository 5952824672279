import PropTypes from "prop-types";
import { Spacing } from "ui-kit";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { BorderRadius, neutral, Swatches } from "ui-kit";
import StudentSensitiveDataTile from "./studentSensitiveDataTile";
import sensitiveData from "areas/planner/constants/student/sensitiveData";
import UserName from "sharedComponents/common/users/userName";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  ${({ hide }) =>
    hide &&
    `
    display: none;
  `}

  ${({ vertical }) =>
    vertical &&
    `
    flex-direction: column;
    align-content: flex-start;
    // column-count: auto;
    // column-gap: 0;
    border-radius: 0 ${BorderRadius.Default}px ${BorderRadius.Default}px 0;

    height: 100%;
    width: 26px;

    &:has(div:nth-child(7)) {
      width: 54px;
    }
    
    &:last-child {
      border-right: unset;
    }

    .sensitive-info-list-item {
      min-width: 26px;
      border-left: 1px solid ${neutral[100]};
    }

    &:has(.sensitive-info-list-item){
      border-left: 1px solid ${neutral[100]};
    }
  `}

${({ vertical }) =>
    vertical === false &&
    `
    margin-bottom: 0;
    border-radius: 0 0 ${BorderRadius.Default}px ${BorderRadius.Default}px;
    width: 100%;
    bottom: 0;
    
    &:last-child {
      border-top: unset;
    }
  
    &:has(.sensitive-info-list-item){
      border-top: 1px solid ${neutral[100]};
    }

  .sensitive-info-list-item {
    padding: 0 ${Spacing.Small - 2}px;
    border-top: 1px solid ${neutral[100]};
  }
  `}
`;

const StudentSensitiveDataTiles = ({ student, vertical }) => {
  const { isPrivate } = useSelector(state => state.privacy);

  return (
    <Wrapper
      className="sensitive-info-list"
      hide={isPrivate.sensitiveInfo}
      vertical={vertical}
    >
      {student.eal && (
        <StudentSensitiveDataTile data={sensitiveData.EAL} student={student} />
      )}
      {student.giftedTalented && (
        <StudentSensitiveDataTile
          data={sensitiveData.GIFTED}
          student={student}
        />
      )}
      {student.pupilPremium && (
        <StudentSensitiveDataTile data={sensitiveData.PP} student={student} />
      )}
      {student.senStatus !== sensitiveData.STUDENT_SEN_KEYS.NOSEN && (
        <StudentSensitiveDataTile
          data={{
            ...sensitiveData.SEN,
            tooltipSub: student.senStatusName,
          }}
          student={student}
        />
      )}

      {student.freeSchoolMeal && (
        <StudentSensitiveDataTile data={sensitiveData.FSM} student={student} />
      )}
      {student.activePupilPassport && (
        <StudentSensitiveDataTile
          data={sensitiveData.PUPIL_PASSPORT}
          student={student}
        />
      )}
      {student.activeProvisionMap && (
        <StudentSensitiveDataTile
          data={sensitiveData.PROVISION_MAP}
          student={student}
        />
      )}
      {student.assignedStaff?.length > 0 && (
        <StudentSensitiveDataTile
          data={{
            ...sensitiveData.ASSIGNED_STAFF,
            tooltipSub: student.assignedStaff.map((staff, index) => (
              <>
                <UserName key={index} user={staff} /> <br />
              </>
            )),
          }}
          student={student}
        />
      )}
      {student.lookedAfter && (
        <StudentSensitiveDataTile
          data={sensitiveData.LOOKED_AFTER}
          student={student}
        />
      )}
      {student.bursary && (
        <StudentSensitiveDataTile
          data={sensitiveData.BURSARY}
          student={student}
        />
      )}
      {student.childProtection && (
        <StudentSensitiveDataTile
          data={sensitiveData.CHILD_PROTECTION}
          student={student}
        />
      )}
      {student.readingAge && (
        <StudentSensitiveDataTile
          style={{ width: "100%" }}
          data={{
            text: student.readingAge,
            color: Swatches.Default,
            tooltip: `Reading Age - ${student.readingAge}`,
          }}
          student={student}
        />
      )}
    </Wrapper>
  );
};

StudentSensitiveDataTiles.propTypes = {
  student: PropTypes.object,
};

export default StudentSensitiveDataTiles;
