import React from "react";
import { Route, Routes } from "react-router";
import { matriculationRoutes } from "../matriculationRoutes";
import CustomRoute from "sharedComponents/layout/routes/customRoute";
import MatriculationSessionStudents from "./matriculationSessionStudents";
import { Constants } from "configuration";
import MatriculationStudent from "./matriculationStudent";
import MatriculationSessionPreadmissions from "./matriculationSessionPreadmissions";
import MatriculationCamera from "./matriculationCamera";
import MatriculationAdmin from "./matriculationAdmin";


const Matriculation = () => {

  return (
    <Routes>

      <Route
        index
        element={
          <CustomRoute
            roles={[
              Constants.ROLES.MATRICULATION_ADMINISTRATOR,
              Constants.ROLES.MATRICULATION_USER,
              Constants.ROLES.IT_ADMINISTRATOR,
              Constants.ROLES.IT_USER,
            ]}
            users={[Constants.USER_TYPES.STAFF]}
          >
            <MatriculationAdmin />
          </CustomRoute>
        }
      />

      <Route
        path={matriculationRoutes.studentSession}
        element={
          <CustomRoute
            roles={[
              Constants.ROLES.MATRICULATION_ADMINISTRATOR,
              Constants.ROLES.MATRICULATION_USER,
              Constants.ROLES.IT_ADMINISTRATOR,
              Constants.ROLES.IT_USER,
            ]}
            users={[Constants.USER_TYPES.STAFF]}
          >
            <MatriculationSessionStudents />
          </CustomRoute>
        }
      />

      <Route
        path={matriculationRoutes.student}
        element={
          <CustomRoute
            roles={[
              Constants.ROLES.MATRICULATION_ADMINISTRATOR,
              Constants.ROLES.MATRICULATION_USER,
              Constants.ROLES.IT_ADMINISTRATOR,
              Constants.ROLES.IT_USER,
            ]}
            users={[Constants.USER_TYPES.STAFF]}
          >
            <MatriculationStudent />
          </CustomRoute>
        }
      />

      <Route
        path={matriculationRoutes.preadmissions}
        element={
          <CustomRoute
            roles={[
              Constants.ROLES.MATRICULATION_ADMINISTRATOR,
              Constants.ROLES.MATRICULATION_USER,
              Constants.ROLES.IT_ADMINISTRATOR,
              Constants.ROLES.IT_USER,
            ]}
            users={[Constants.USER_TYPES.STAFF]}
          >
            <MatriculationSessionPreadmissions />
          </CustomRoute>
        }
      />

      <Route
        path={matriculationRoutes.camera}
        element={
          <CustomRoute
            roles={[
              Constants.ROLES.MATRICULATION_ADMINISTRATOR,
              Constants.ROLES.MATRICULATION_USER,
              Constants.ROLES.IT_ADMINISTRATOR,
              Constants.ROLES.IT_USER,
            ]}
            users={[Constants.USER_TYPES.STAFF]}
          >
            <MatriculationCamera />
          </CustomRoute>
        }
      />
      
    </Routes>
  );
};

export default Matriculation;
