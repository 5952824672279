import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  Button,
  Right,
  ActionBar,
  Swatches,
  Size,
  StructuredList,
  Currency,
  Card,
  EmptyMessage,
  SplitButton,
  TextInput,
  TextInputType,
  Dropdown,
  Message,
  ToastService,
  ValidationMessage,
  Checkbox,
  DatePicker,
} from "ui-kit";
import {
  SubjectDropdown,
  YearGroupDropdown,
  TermsDropdown,
  StaffPicker,
} from "sharedComponents/common";
import { Constants } from "configuration";
import { arrays, strings } from "utils";
import ProvisionCostBandForm from "../provisionCostBands/provisionCostBandForm";
import ProvisionGroupStatus from "./provisionGroupStatus";
import { PROVISION_GROUP_ACTION } from "areas/send/constants";
import provisionGroupsActions from "areas/send/actions/provisions/provisionGroupsActions";
import { RootState } from "reducers/store";
import { ProvisionCostListView, ProvisionGroupDetailView, ProvisionListView, TermListView } from "areas/send/types/passportResponse.types";
import { provisionGroup } from "./provisionGroupsTable";
import { ProvisionFrequency, ProvisionGroupStatus as PGS, ProvisionTimescale } from "areas/send/types/passportShared.types";
import { SaveProvisionGroupCommand } from "areas/send/types/passportRequest.types";
import { GroupListView } from "types/users/userGroups.types";
import { IModalBodyProps } from "ui-kit/modules/modal";
import moment from "moment";
import TimescaleDropdown from "../timescaleDropdown";
import { DateRange } from "types/common/data.types";
import config from "configuration/config";


interface IProvisionGroupEditModalProps {
  group: provisionGroup;
  provision: ProvisionListView;
  open: boolean;
  onCancel?: () => void;
  onSave?: (group: ProvisionGroupDetailView) => void;
}

interface cloneableProvisionGroup extends ProvisionGroupDetailView {
  cloneStudents?: boolean;
  keepStudentProgress?: boolean;
  sourceGroupId?: number;
}


const ProvisionGroupEditModal: React.FC<IProvisionGroupEditModalProps> = ({
  group,
  provision,
  open,
  onCancel,
  onSave,
}) => {

  const { saveError, saving } = useSelector((state: RootState) => state.provisionGroup);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_group, _setGroup] = useState<cloneableProvisionGroup | null>(null);
  const [_error, _setError] = useState<string | null>(null);
  const [_loading, _setLoading] = useState<boolean>(false);
  const [_total, _setTotal] = useState<number>(0);
  const [_provision, _setProvision] = useState<ProvisionListView>(provision);
  const [_open, _setOpen] = useState<boolean>(false);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  useEffect(() => {
    _setProvision(provision);
  }, [provision]);

  useEffect(() => {
    setValidationErrors([]);

    if (
      group &&
      group.id &&
      provision &&
      group.action !== PROVISION_GROUP_ACTION.CLONE
    ) {
      _setGroup(null);
      _setError(null);
      _setLoading(true);

      provisionGroupsActions
        .getProvisionGroup(provision.provider.id, provision.id, group.id)
        .then(data => {
          _setGroup(data);
          _setLoading(false);
        })
        .catch(data => {
          _setError("There was a problem loading the provision group.");
          _setLoading(false);
        });
    } else if (
      group &&
      group.action === PROVISION_GROUP_ACTION.CLONE &&
      provision
    ) {
      _setGroup(null);
      _setError(null);
      _setLoading(true);

      provisionGroupsActions
        .getProvisionGroup(provision.provider.id, provision.id, group.id)
        .then(data => {
          _setGroup({
            ...data,
            id: null,
            code: null,
            name: data.name + " (copy)",
            responsibleStaff: [],
            status: 0,
            statusName: "Draft",
            costs: data.costs.map(cost => ({ ...cost, id: null })),
            sourceGroupId: data.id,
            cloneStudents: false
          });
          _setLoading(false);
        })
        .catch(data => {
          _setError("There was a problem loading the provision group.");
          _setLoading(false);
        });
    } else {
      if (provision) {
        var defaultGroup: ProvisionGroupDetailView = {
          costs: [],
          responsibleStaff: [],
          frequencyName: "",
          frequency: ProvisionFrequency.Single,
          frequencyLength: 1,
          sessionsPerFrequency: 1,
          sessionLength: 1,
          totalSessions: 0,
          hourlyCost: 0,
          sessionCost: 0,
          frequencyCost: 0,
          totalCost: 0,
          id: null,
          name: "",
          code: "",
          provision: provision,
          startDate: null,
          endDate: null,
          yearGroup: undefined,
          subject: undefined,
          status: PGS.Draft,
          statusName: "",
          academicYear: {
            id: config.academicYear.current,
            year: config.academicYear.currentName,
            startDate: config.academicYear.currentStartDate,
            endDate: config.academicYear.currentEndDate
          }
        }
        _setGroup(defaultGroup);
      }
    }
  }, [group]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    _setTotal(0);

    if (_group) {
      var hours =
        _group?.frequencyLength *
        _group?.sessionsPerFrequency *
        _group?.sessionLength;

      if (_group?.costs?.length) {
        var costTotal = _group?.costs
          ?.map(x => x.defaultCost * x.weighting)
          .reduce((prev, next) => prev + next);

        _setTotal(hours * costTotal);
      }
    }
  }, [_group]);

  const handleCancel = () => {
    _setGroup(null);
    onCancel?.();
  }

  const handleSave = (setGroupLive: boolean) => {
    // Validation
    var errors: string[] = [];

    if (!_group.name) {
      errors.push(`Please enter a name.`);
    }

    if (!_group.code) {
      errors.push(`Please enter a code.`);
    }

    if (!_group.startDate) {
      errors.push(`Please enter a start date.`);
    }

    if (!_group.startDate) {
      errors.push(`Please enter an end date.`);
    }

    // if (_group.term == null || !_group.term?.id || _group.term?.id == null) {
    //   errors.push(`Please select a term.`);
    // }

    // if (_group.halfTerm == null) {
    //   errors.push(`Please select a half term.`);
    // }

    if (_group.frequency == null) {
      errors.push(`Please select a frequency.`);
    }

    if (_group.frequencyLength == null) {
      errors.push(`Please enter a frequency length`);
    }

    if (!_group.sessionsPerFrequency) {
      errors.push(`Please enter the number of sessions per frequency`);
    }

    if (!_group.sessionLength) {
      errors.push(`Please enter the length of a session`);
    }

    var start = moment(_group.startDate);
    var end = moment(_group.endDate);
    switch(_group.frequency) {
      case ProvisionFrequency.Week:
        if (end.diff(start, 'weeks') < _group.frequencyLength) {
          errors.push(`The frequency cannot exceed the runtime of the group date span`);
        }
        break;
      case ProvisionFrequency.Month:
        if (end.diff(start, 'months') < _group.frequencyLength) {
          errors.push(`The frequency cannot exceed the runtime of the group date span`);
        }
        break;
      case ProvisionFrequency.HalfTerm:
        if (end.diff(start, 'weeks') < (_group.frequencyLength * 7)) {
          errors.push(`The frequency cannot exceed the runtime of the group date span`);
        }
        break;
      default:
        // eh
    } 

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    var data: SaveProvisionGroupCommand = {
      id: _group.id,
      status: _group.status,
      providerId: provision.provider.id,
      provisionId: provision.id,
      startDate: _group.startDate,
      endDate: _group.endDate,
      timescale: _group.timescale,
      yearGroupId: _group.yearGroup?.id,
      subjectId: _group.subject?.id,
      name: _group.name,
      code: _group.code,
      frequency: _group.frequency,
      frequencyLength: _group.frequencyLength,
      sessionsPerFrequency: _group.sessionsPerFrequency,
      sessionLength: _group.sessionLength,
      setLive: setGroupLive ? true : false,
      costs: _group.costs?.map(cost => ({
        id: cost.id ? cost.id : null,
        bandId: cost.bandId,
        cost: cost.defaultCost,
        weighting: cost.weighting,
      })),
      responsibleStaff: _group.responsibleStaff?.map(staff => staff.id),
      cloneStudents: _group.cloneStudents,
      keepStudentProgress: _group.keepStudentProgress,
      sourceGroupId: _group.sourceGroupId
    };

    if (_group.id) {
      provisionGroupsActions.saveProvisionGroup(data, () => {
        ToastService.pop(
          "Provision Group Saved",
          null,
          "hand-holding-seedling"
        );
        _setGroup(null);
        onSave?.(_group);
      });
    } else {
      provisionGroupsActions.createProvisionGroup(data, () => {
        ToastService.pop(
          "Provision Group Created",
          null,
          "hand-holding-seedling"
        );
        _setGroup(null);
        onSave?.(_group);
      });
    }
  };

  const handleCostBandsChange = (costBands: ProvisionCostListView[]) => {
    console.log(costBands);
    _setGroup({ ..._group, costs: costBands });
  };

  const getFrequencyName = (value: ProvisionFrequency) => {
    var vals = Object.values(Constants.SEND_PROVISIONFREQUENCY);
    var freq = vals.find(x => x.value === value);
    return freq?.name;
  };

  const getTotalSessions = () => {
    const sessions = _group.frequencyLength * _group.sessionsPerFrequency;
    const hours = sessions * _group.sessionLength;

    return `${sessions} ${strings.pluralize(
      "Session",
      "Sessions",
      sessions
    )} / ${hours} ${strings.pluralize("Hour", "Hours", hours)}`;
  };

  const handleFrequencyChanged = (value: number) => {
    if (value === 0) {
      _setGroup({
        ..._group,
        frequency: value,
        frequencyLength: 1,
        sessionsPerFrequency: 1,
      });
    } else {
      _setGroup({ ..._group, frequency: value });
    }
  };

  const handleTimescaleChange = (value: ProvisionTimescale, data?: DateRange) => {
    console.log(data);
    if (value != ProvisionTimescale.Custom) {
      setShowDatePicker(false);
      _setGroup({ 
        ..._group, 
        startDate: data.startDate, 
        endDate: data.endDate,
        timescale: value
      })
    }
    else {
      setShowDatePicker(true);
      _setGroup({ 
        ..._group, 
        timescale: value
      })
    }
  }

  return (
    <Modal
      title={
        <>
          {group?.id ? group?.name : "New Provision Group"}{" "}
          <ProvisionGroupStatus status={group?.status} />
        </>
      }
      open={_open}
      onClose={handleCancel}
      width="90%"
      height="85%"
      loading={_loading}
    >
      <Modal.Body scrollOnErrors={validationErrors}>
        {_group ? (
          <>
            <ValidationMessage errors={validationErrors} />
            <Message text={saveError} color={Swatches.Danger} />
            <Card title="General">
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="Name" required>
                    <TextInput
                      value={_group.name}
                      onChange={value => _setGroup({ ..._group, name: value })}
                      maxLength={150}
                      fluid
                      characterCount
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="Code" required>
                    <TextInput
                      value={_group.code}
                      onChange={value => _setGroup({ ..._group, code: value })}
                      maxLength={5}
                      fluid
                      characterCount
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="Year Group">
                    <YearGroupDropdown
                      value={_group?.yearGroup?.id}
                      school={_group?.provision?.school?.id}
                      fluid
                      onChange={(value: number, label: string, data: GroupListView) =>
                        _setGroup({ ..._group, yearGroup: data })
                      }
                      clearable
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="Subject">
                    <SubjectDropdown
                      value={_group?.subject?.id}
                      school={_group?.provision?.school}
                      fluid
                      onChange={subject =>
                        _setGroup({ ..._group, subject: subject })
                      }
                      clearable
                    />
                  </StructuredList.Item>
                  { group?.action === PROVISION_GROUP_ACTION.CLONE && (
                    <StructuredList.Item name={"Copy Students"} >
                      <Checkbox
                        checked={false}
                        text={`Copy students from source group`}
                        onChange={(value) => {
                          _setGroup({ ..._group, cloneStudents: value.checked, keepStudentProgress: true });
                        }}
                      />
                      { _group.cloneStudents && (
                        <Checkbox
                          checked={true}
                          text={`Copy students existing progress from source group`}
                          onChange={(value) => {
                            _setGroup({..._group, keepStudentProgress: value.checked});
                          }}
                        />
                      )}
                    </StructuredList.Item>
                  )}
                </StructuredList>
              </Card.Body>
            </Card>
            
            <Card title={"Dates"}>
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item 
                    name="Date Range" 
                    description={"All predefined date ranges start from the current date"} 
                    required
                  >
                    <TimescaleDropdown
                      fluid
                      onChange={handleTimescaleChange}
                      academicYear={_group?.academicYear}
                      value={{ 
                        timescale: _group.timescale, 
                        dates: { startDate: _group.startDate, endDate: _group.endDate } 
                      }}
                    />
                  </StructuredList.Item>
                  <StructuredList.Item 
                    name={"Start Date"} 
                    hide={!showDatePicker} 
                    required={true}
                  >
                    <DatePicker
                      dateFormat="DD/MM/YYYY"
                      closeOnSelect
                      selectedDate={_group.startDate}
                      onChange={value => _setGroup({ ..._group, startDate: value })}
                    />
                  </StructuredList.Item>
                  <StructuredList.Item 
                    name={"End Date"}
                    hide={!showDatePicker} 
                    required={true}
                  >
                    <DatePicker
                      dateFormat="DD/MM/YYYY"
                      closeOnSelect
                      selectedDate={_group.endDate}
                      onChange={value => _setGroup({ ..._group, endDate: value })}
                    />  
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
            <Card title="Frequency">
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="Frequency" required>
                    <Dropdown
                      fluid
                      placeholder="Choose a Frequency"
                      value={_group.frequency}
                      onChange={handleFrequencyChanged}
                      items={arrays.configConstantsToArray(
                        Constants.SEND_PROVISIONFREQUENCY
                      )}
                    />
                  </StructuredList.Item>
                  {_group.frequency !== 0 && (
                    <>
                      <StructuredList.Item
                        name={`Number of ${getFrequencyName(
                          _group.frequency
                        )}s`}
                        required
                      >
                        <TextInput
                          type={TextInputType.Number}
                          value={_group.frequencyLength}
                          min={1}
                          onChange={value =>
                            _setGroup({ ..._group, frequencyLength: value })
                          }
                          suffix={`${getFrequencyName(_group.frequency)}s`}
                        />
                      </StructuredList.Item>

                      <StructuredList.Item
                        name={`Sessions per ${getFrequencyName(
                          _group.frequency
                        )}${_group.frequencyLength > 1 ? "" : "s"}`}
                        required
                      >
                        <TextInput
                          type={TextInputType.Number}
                          value={_group.sessionsPerFrequency}
                          min={1}
                          onChange={value =>
                            _setGroup({
                              ..._group,
                              sessionsPerFrequency: value,
                            })
                          }
                          suffix="Sessions"
                        />
                      </StructuredList.Item>
                    </>
                  )}

                  {_group.frequency != null && (
                    <StructuredList.Item name="Session Length" required>
                      <TextInput
                        type={TextInputType.Number}
                        value={_group.sessionLength}
                        onChange={value =>
                          _setGroup({ ..._group, sessionLength: value })
                        }
                        suffix="Hours"
                      />
                    </StructuredList.Item>
                  )}
                  <StructuredList.Item name="Total Sessions">
                    {getTotalSessions()}
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
            <Card title="Cost">
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="Cost Bands">
                    <ProvisionCostBandForm
                      costBands={_group.costs}
                      onChange={handleCostBandsChange}
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="Total Cost">
                    <Currency value={_total} />
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
            <Card title="Responsible Staff">
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="Responsible Staff" required>
                    <StaffPicker
                      users={_group?.responsibleStaff}
                      onChange={users =>
                        _setGroup({ ..._group, responsibleStaff: users })
                      }
                    />
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
          </>
        ) : (
          <EmptyMessage
            icon="hand-holding-seedling"
            title="No Provision Group"
            summary="No provision group was selected"
            cover
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              onClick={handleCancel}
              size={Size.Small}
              color={Swatches.Low}
              text="Cancel"
              working={saving}
            />
            {_group?.status > 0 ? (
              <Button
                onClick={() => handleSave(false)}
                size={Size.Small}
                color={Swatches.Success}
                text="Save"
                working={saving}
              />
            ) : (
              <SplitButton
                color={Swatches.Success}
                size={Size.Small}
                text={"Save as Draft"}
                working={saving}
                onDefaultClick={() => handleSave(false)}
              >
                <SplitButton.Option
                  text="Save as Draft"
                  onClick={() => handleSave(false)}
                  show
                />
                <SplitButton.Option
                  text="Save and Set Live"
                  color={Swatches.Danger}
                  onClick={() => handleSave(true)}
                  show
                />
              </SplitButton>
            )}
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default ProvisionGroupEditModal;
