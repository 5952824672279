import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import { Dropdown, Sub } from "ui-kit";
import subjectActions from "areas/planner/actions/subjectActions";
import { RootState } from "reducers/store";
import { SchoolListView } from "types/schools/schools.types";
import { GroupListView } from "types/users/userGroups.types";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { useAppSelector } from "reducers/hooks";


interface ISubjectDropdownProps {
  value?: number;
  fluid?: boolean;
  onChange?: (subject: GroupListView) => void;
  school?: SchoolListView;
  clearable?: boolean;
}


const SubjectDropdown: React.FC<ISubjectDropdownProps> = ({ value, fluid, onChange, school, clearable }) => {

  const { error, loading, subjects, schoolId } = useAppSelector(state => state.subjects);
  const [_school, _setSchool] = useState<SchoolListView>(null);
  const [_subjects, _setSubjects] = useState<IDropdownDataItemProps<string, number, GroupListView>[]>([]);

  useEffect(() => {
    _setSchool(school);
  }, [school]);

  useEffect(() => {
    arrays.isEmpty(subjects) &&
      _school &&
      schoolId !== _school.id &&
      subjectActions.getSubjectsBySchool(_school.id);
  }, [_school]);

  useEffect(() => {
    if (!arrays.isEmpty(subjects)) {
      _setSubjects(subjects.map(subject => ({
        label: subject.name,
        value: subject.id,
        data: subject
      })));
    }
  }, [subjects]);

  const handleChange = (value: number, label: string, data: GroupListView) => {
    onChange?.(data);
  }

  if (!school) {
    return <Sub>A school must be specified</Sub>;
  }

  if (error) {
    return <Sub>There was a problem loading the subjects</Sub>;
  }

  return (
    <Dropdown
      value={value}
      loading={loading}
      placeholder="Choose a Subject"
      fluid={fluid}
      onChange={handleChange}
      clearable={clearable}
      items={_subjects}
    />
  );
};

export default SubjectDropdown;
