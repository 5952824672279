import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Constants } from "configuration";
import {
  TitleSize,
  Title,
  ToastService,
} from "ui-kit";
import calendarActions from "areas/calendar/actions/calendarActions";
import Subheader from "sharedComponents/layout/header/subheader";
import { RootState } from "reducers/store";
import { WallPlannerEventView } from "areas/calendar/types/calendarResponses.types";
import WpEditModal from "./wallplannerEditModal";
import WpEventsTable from "./wallplannerEventsTable";
import WpEventsFilter from "./wallplannerEventsFilter";
import { SaveWallplannerEventCommand } from "areas/calendar/types/calendarRequests.types";


const WallPlannerEditor = () => {
  
  const { schoolId, yearId } = useParams();
  const { loading, error } = useSelector((state: RootState) => state.wallPlannerSessions);
  const { events, filter } = useSelector((state: RootState) => state.wallPlannerSessions.selectedSession);

  const [_events, _setEvents] = useState<WallPlannerEventView[] | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<WallPlannerEventView | null>();
  const [rememberedDates, setRememberedDates] = useState<{ startDate: Date, endDate: Date }>({
    startDate: null,
    endDate: null
  });

  const EVENT_DEFAULTS: WallPlannerEventView = {
    name: null,
    categoryId: null,
    schools: [parseInt(schoolId)],
    startDate: null,
    endDate: null,
  };

  const loadEvents = (pageIndex: number) => {
    calendarActions.getWallplannerEvents(parseInt(schoolId), parseInt(yearId));
  };

  useEffect(() => {
    loadEvents(0);
  }, []);

  useEffect(() => {
    if (events) {
      var filteredEvents = events;
      if (filter) {
        filteredEvents = filter.category
          ? filteredEvents.filter(evnt => evnt.categoryId == filter.category)
          : filteredEvents;
        filteredEvents = filter.month
          ? filteredEvents.filter(
              evnt => moment(evnt.startDate).month() + 1 == filter.month
            )
          : filteredEvents;
      }
      _setEvents(filteredEvents);
    }
  }, [filter, events]);

  const onPage = (pageIndex: number) => {
    //loadEvents(paging.pageIndex + 1);
  };

  const getSchoolName = (schoolId: number) => {
    if (!schoolId) {
      return;
    }
    if (schoolId == 99) {
      return "Lionheart Educational Trust";
    }
    const school = Constants.SCHOOLS.find(school => school.id == schoolId);
    return school?.name;
  };

  const getYearName = (yearId: number) => {
    if (!yearId) {
      return;
    }
    const year = Constants.ACADEMIC_YEARS.find(year => year.id == yearId);
    return year?.name;
  };

  const createNewEvent = () => {
    setSelectedEvent({
      ...EVENT_DEFAULTS,
      startDate: rememberedDates.startDate,
      endDate: rememberedDates.endDate
    });
  };

  const editEvent = (event: WallPlannerEventView) => {
    setSelectedEvent(event);
  };

  const deleteEvent = (event: WallPlannerEventView) => {
    calendarActions.deleteWallplannerEvent(event, () => {
      ToastService.pop("Successfully deleted Event", null, "check-circle");
    });
  };

  const handleModalClose = () => {
    setSelectedEvent(null);
  };

  const handleModalSave = (eventDetails: SaveWallplannerEventCommand) => {
    if (eventDetails.id != null) {
      calendarActions.updateWallplannerEvent(
        eventDetails, 
        () => ToastService.pop("Successfully updated event", null, "check-circle")
      );
    }
    else {
      calendarActions.createWallplannerEvent(
        eventDetails, 
        () => ToastService.pop("Successfully created event", null, "check-circle")
      );
    }
    setSelectedEvent(null);
  };

  return (
    <>
      <Subheader>
        <Title
          size={TitleSize.H2}
          text="Wallplanner"
          sub={`${getSchoolName(parseInt(schoolId))} - ${getYearName(parseInt(yearId))}`}
        />
      </Subheader>
      <WpEventsFilter onNewEventClick={createNewEvent} />
      <WpEventsTable
        events={_events}
        loading={loading}
        error={error}
        //paging={paging}
        //handlePage={onPage}
        onNewEventClick={createNewEvent}
        onEditEventClick={editEvent}
        onDeleteEventClick={deleteEvent}
      />
      <WpEditModal
        event={selectedEvent}
        open={selectedEvent != null}
        setRememberedDates={setRememberedDates}
        onClose={handleModalClose}
        onSave={handleModalSave}
      />
    </>
  );
};

export default WallPlannerEditor;
