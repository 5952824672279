import React from "react";
import { arrays } from "utils";
import TelephoneNumber from "./telephoneNumber";
import styled from "styled-components";
import { TelephoneNumberListView } from "types/users/userContact.types";

const Wrapper = styled.ul`
  li {
    list-style: none;
  }
`;

interface ITelephoneNumberListProps {
  numbers: TelephoneNumberListView[];
}

const TelephoneNumberList: React.FC<ITelephoneNumberListProps> = ({ numbers }) => {

  return !arrays.isEmpty(numbers) ? (
    <Wrapper>
      {numbers.map((number: TelephoneNumberListView, index: number) => (
        <li key={index}>
          <TelephoneNumber number={number} />
        </li>
      ))}
    </Wrapper>
  ) : null;
};

export default TelephoneNumberList;
