import { IBeehiveAction } from "types/common/action.types";
import linksActions from "../actions/linksActions";
import { LinkCategoryView } from "types/common/links.types";


interface ILinksState {
  links: LinkCategoryView[];
  error: string | null;
  loading: boolean;
}

const INITIAL_STATE: ILinksState = {
  links: [],
  error: null,
  loading: false,
};


const linksReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ILinksState => {

  const { LINKS_GETFORUSER } = linksActions.types;

  switch (action.type) {
    case LINKS_GETFORUSER.START:
      return { ...state, loading: true, error: null };

    case LINKS_GETFORUSER.SUCCESS:
      return { ...state, links: action.payload, loading: false };

    case LINKS_GETFORUSER.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default linksReducer;
