import { CoverArrangement } from "areas/humanResources/types/leaveShared.types";
import React, { useState, useEffect } from "react";
import { Dropdown } from "ui-kit";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";



interface ICoverRequirementDropdownProps {
  value?: CoverArrangement;
  onChange: (value: CoverArrangement, label: string) => void;
  includeAll?: boolean;
  fluid?: boolean;
}


const CoverRequirementDropdown: React.FC<ICoverRequirementDropdownProps> = ({ value, onChange, includeAll, fluid }) => {

  const [coverRequirements] = useState<IDropdownDataItemProps<string, CoverArrangement, null>[]>(() => {
    var opts = (Object.keys(CoverArrangement) as Array<keyof typeof CoverArrangement>)
      .filter(arrangment => isNaN(Number(arrangment)))
      .map(arrangment => ({
        label: arrangment,
        value: CoverArrangement[arrangment]
      }))
    return includeAll ? [ { label: "All", value: null }, ...opts ] : opts;
  });

  return (
    <>
      <Dropdown
        value={value}
        onChange={onChange}
        items={coverRequirements}
        fluid={fluid}
      />
    </>
  );
}


export default CoverRequirementDropdown;