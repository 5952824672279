import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
`;


interface IPrimaryRouteProps {
  children: any | any[];
}

const PrimaryRoute : React.FC<IPrimaryRouteProps> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default PrimaryRoute;
