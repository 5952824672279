import React, { useEffect } from "react";
import { useAppSelector } from "reducers/hooks";
import { SchoolDropdown } from "sharedComponents/common";
import { ActionBar, Card, Currency, StructuredList } from "ui-kit";
import cateringActions from "areas/payments/actions/cateringActions";
import constants from "configuration/constants";


const CateringDetails : React.FC = () => {

  const { user } = useAppSelector(state => state.currentUser);
  const { details, filter } = useAppSelector(state => state.cateringPricing);

  useEffect(() => {
    cateringActions.getCateringDetails();
  }, []);

  useEffect(() => {
    if (user && !filter?.schoolId) {
      var schoolId = [user.baseSchool, ...user.schools].filter(
        (x) => x.enableLunchCredits
      )[0]?.id;

      cateringActions.setFilter({ ...filter, schoolId: schoolId});
    }
  }, [user]);

  const handleSchoolChange = (value: number) => {
    cateringActions.setFilter({ ...filter, schoolId: value});
  };

  const getSchoolName = () => {
    var school = constants.SCHOOLS.filter(x => x.id === filter.schoolId)?.[0];
    return school?.name;
  }

  return (
    <>
      <ActionBar>
        <SchoolDropdown
          initialValue={filter.schoolId}
          onChange={handleSchoolChange}
          filter={(x) => x.enableLunchCredits}
        />
      </ActionBar>

      { details
        .filter(schoolCateringDetails => schoolCateringDetails.schoolId === filter.schoolId)
        .map(schoolCateringDetails => (
          <Card title={`Lunch Credit Pricing`}>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Student Price">
                  <Currency value={schoolCateringDetails.pricing.studentPrice} />
                </StructuredList.Item>
                <StructuredList.Item name="Staff Price">
                  <Currency value={schoolCateringDetails.pricing.staffPrice} />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
        ))}
    </>
  )
};

export default CateringDetails;
