import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Avatar } from "sharedComponents/common";
import {
  displayFont,
  fontStyle,
  typescale,
  Card,
  neutral,
  Spacing,
  Breakpoints,
} from "ui-kit";
import moment from "moment";
import MagazineCategoryChip from "../magazineCategoryChip";
import { files } from "utils";
import { useWindowSize } from "hooks";
import { ArticleDetailView } from "areas/news/types/newsResponse.types";
import { RootState } from "reducers/store";
import { FileListView } from "types/common/files.types";


const Wrapper = styled.div`
  text-align: center;
  border-bottom: 1px solid ${neutral[400]};
  margin-bottom: ${Spacing.Large}px;
  padding-bottom: ${Spacing.Large}px;

  @media screen and (min-width: ${Breakpoints.m}px) {
    text-align: left;
  }

  img {
    margin-bottom: ${Spacing.Large}px;
    width: 100%;
  }
  h1 {
    ${fontStyle(displayFont.medium, typescale.header1, neutral[700])}
    font-size: 1.75rem;
    margin-bottom: ${Spacing.Medium}px;
  }

  .article-header-summary {
    ${fontStyle(displayFont.roman, typescale.header3, neutral[600])}
    margin-bottom: ${Spacing.ExtraLarge}px;
  }

  .article-category-chip {
    margin-bottom: ${Spacing.Medium}px;
  }
`;


interface IArticleHeaderProps {
  article: ArticleDetailView;
}


const ArticleHeader: React.FC<IArticleHeaderProps> = ({ article }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const [width, height] = useWindowSize();

  const isMedia = (x: FileListView) => {
    return files.isMediaMimeType(x.mimeType);
  };
  const [media, setMedia] = useState(article?.files?.filter(x => isMedia(x)));

  return article ? (
    <Wrapper>
      {/* <img src="https://picsum.photos/800/400" /> */}

      {media?.length > 0 && (
        <Card.Media className="feed-item-media" noPad media={media} />
      )}

      <MagazineCategoryChip category={article?.category} />
      <h1>{article.title}</h1>
      {article.summary && (
        <p className="article-header-summary">{article.summary}</p>
      )}

      <Avatar
        user={article.createdBy}
        sub={moment(article.createdDate).format("Do MMMM YYYY")}
        portrait={width < Breakpoints.m}
      />
    </Wrapper>
  ) : null;
};

export default ArticleHeader;
