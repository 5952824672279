import React from "react";
import styled from "styled-components";
import {
  Card,
  CircleIcon,
  neutral,
  typescale,
  Size,
  displayFont,
  Chip,
  Currency,
  Spacing,
  fontStyle,
  Swatches
} from "ui-kit";
import { useLocation, useNavigate } from "react-router-dom";
import { ProductListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


const Wrapper = styled.div`
  ${fontStyle(displayFont.bold, typescale.paragraph, neutral[700])}
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  .circle-icon {
    margin-bottom: ${Spacing.Large}px;
  }

  .product-item-title {
    display: block;
    margin-bottom: ${Spacing.Default}px;
    ${fontStyle(displayFont.bold, typescale.header4)}
    word-break: break-word;
  }

  .product-item-category {
    display: block;
    color: ${neutral[500]};
    margin-bottom: 12px;
  }

  .product-item-price {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;

    span.price {
      display: block;
      ${fontStyle(displayFont.light, typescale.header2)}
    }

    .chip {
      margin-top: ${Spacing.Default}px;
    }
  }
`;


interface IProductListItemProps {
  product: ProductListView;
  onGoToProduct?: () => void;
}


const ProductListItem: React.FC<IProductListItemProps> = ({ product, onGoToProduct }) => {

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const goToProduct = () => {
    navigate(`${pathname}/${product.id}`);
  };

  return (
    <Card className="product-list-item" onClick={goToProduct}>
      <Card.Body>
        <Wrapper>
          <CircleIcon
            value={product?.category?.icon ?? "shopping-bag"}
            size={Size.Large}
            color={Swatches.Low.swatch}
            outline
          />
          <span className="product-item-title">{product.name}</span>
          <span className="product-item-category">{product.category.name}</span>

          <div className="product-item-price">
            <Currency className="price" value={product.unitPrice} />

            {product.soldOut ? (
              <Chip text="Sold Out" />
            ) : (
              product.hasInstalments && <Chip text="Instalments" />
            )}
          </div>
        </Wrapper>
      </Card.Body>
    </Card>
  );
};

export default ProductListItem;
