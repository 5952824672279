import React, { useState } from "react";
import styled from "styled-components";
import {
  Card,
  fontStyle,
  Icon,
  Loader,
  neutral,
  Size,
  Spacing,
  textFont,
  typescale,
} from "ui-kit";
import { Constants } from "configuration";
import { useSelector } from "react-redux";
import ActivityIcon from "areas/planner/components/activityFeed/activityIcon";
import { FEED_TYPE_KEY } from "areas/planner/constants/activityFeed";
import ActivityDate from "areas/planner/components/activityFeed/activityDate";
import UserName from "sharedComponents/common/users/userName";
import { StaffView } from "sharedComponents/common/users/userTypeView";
import ConfirmModal from "sharedComponents/common/confirmModal";

const FeedItem = styled.div`
  display: flex;

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 2;

    .user-name {
      ${fontStyle(textFont.bold, typescale.paragraph)}
    }
  }

  .activity-date {
    ${fontStyle(textFont.roman, typescale.paragraph, neutral[500])}
  }

  .note-details {
    ${fontStyle(textFont.roman, typescale.paragraph)}

    .note-content {
      padding-top: ${Spacing.Small + 4}px;
    }
  }
`;

const FeedItemActionButton = styled.div`
  padding: ${Spacing.Small}px ${Spacing.Small}px ${Spacing.Small}px
    ${Spacing.Medium}px;

  &:hover {
    cursor: pointer;
  }

  .icon {
    font-size: 1rem;
  }
`;

const StudentNotesList = ({
  studentNotes,
  loading,
  // pagesAvailable,
  // handleLoadMore,
  handleDeleteNote,
}) => {
  const { user } = useSelector(state => state.currentUser);

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);

  const userIsAuthorised = note => {
    if (
      (user.roles &&
        user.roles.some(
          role =>
            role.roleType ===
              Constants.ROLES.BEHAVIOUR_ADMINISTRATOR.roleType ||
            role.roleType === Constants.ROLES.BEHAVIOUR_MANAGER.roleType ||
            role.roleType === Constants.ROLES.LEADERSHIP.roleType ||
            role.roleType === Constants.ROLES.TRUST_LEADERSHIP.roleType
        )) ||
      (note.createdBy && note.createdBy.id === user.id)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getGroupName = note => {
    return note.classGroup ? (
      <>
        {" "}
        in <b>{note.classGroup.friendlyName}</b>
      </>
    ) : note.provisionGroup ? (
      <>
        {" "}
        in <b>{note.provisionGroup.friendlyName}</b>
      </>
    ) : null;
  };

  const onClickDelete = note => {
    setNoteToDelete(note);
    setOpenConfirmDelete(true);
  };

  return (
    <>
      {studentNotes.map((note, index) => (
        // <ShowInActivityFeed feedTypeId={note.feedTypeId}>
        <Card>
          <Card.Body>
            <FeedItem>
              <ActivityIcon activityTypeId={FEED_TYPE_KEY.NOTES} />

              <div className="content">
                <ActivityDate date={note.createdDate} />

                <div className="note-details">
                  <b>{note.categoryName} Note</b> added by{" "}
                  <UserName user={note.createdBy} />
                  {getGroupName(note)}
                  <div className="note-content">{note.content}</div>
                </div>
              </div>

              <StaffView>
                {userIsAuthorised(note) && (
                  <FeedItemActionButton onClick={() => onClickDelete(note)}>
                    <Icon value="trash" color={neutral[500]} />
                  </FeedItemActionButton>
                )}
              </StaffView>
            </FeedItem>
          </Card.Body>
        </Card>
        // </ShowInActivityFeed>
      ))}

      {loading && <Loader size={Size.Large} fluid />}

      {/* <LoadMoreButton>
        {!loading && pagesAvailable && (
          <Button text="Load More" onClick={handleLoadMore} />
        )}
      </LoadMoreButton> */}

      <ConfirmModal
        openModal={openConfirmDelete}
        confirmMsg={`Are you sure you want to permanently delete this note?`}
        onOpen={() => setOpenConfirmDelete(true)}
        onClose={() => setOpenConfirmDelete(false)}
        onConfirm={() =>
          handleDeleteNote(
            noteToDelete.id,
            () => setOpenConfirmDelete(false),
            () => setOpenConfirmDelete(false)
          )
        }
      />
    </>
  );
};

export default StudentNotesList;
