import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  EmptyMessage,
  Loader,
  Size,
  Chip,
  Label,
  CircleIcon,
  BorderRadius,
  Spacing,
  neutral,
  honeygold,
  Swatches,
  Button,
  Speed,
  boxShadow,
} from "ui-kit";
import { arrays } from "utils";
import { pmRoutes } from "../../../hrRoutes";
import { ReviewListView } from "areas/humanResources/types/managedStaffResponse.types";


const ReviewWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const ReviewListItemWrapper = styled.div<{start?: boolean}>`
  display: flex;
  padding: ${Spacing.Large}px;
  border-radius: ${BorderRadius.Default}px;
  background: ${neutral[100]};
  margin-bottom: ${Spacing.Default}px;
  align-items: center;
  box-shadow: ${boxShadow};

  &:last-child {
    margin-bottom: 0;
  }

  .circle-icon {
    margin-right: ${Spacing.Default}px;
  }
  & > i {
    color: #999;
    display: block;
    font-size: 18px;
  }

  .label {
    display: block;
  }

  &:hover {
    cursor: pointer;
    background: ${honeygold};
    color: ${neutral[100]};
    label {
      color: ${neutral[100]};
    }

    .chip {
      background: ${neutral[700]};
    }
    .circle-icon {
      border-color: ${neutral[700]};
    }

    i {
      color: ${neutral[700]};
    }
  }

  ${({ start }) =>
    start &&
    `
  background: ${honeygold};
  i {
    color: ${neutral[700]};
  }
  .circle-icon {
    border-color: ${neutral[700]};
  }

  &:hover {
    transform: all ease ${Speed.Default};
    background: ${neutral[700]};
    .circle-icon {
    border-color: ${neutral[100]};
    }
    i, .label {
      color: ${neutral[100]};
    }
  }

  `}
`;


interface IReviewListProps {
  reviews: ReviewListView[];
  loading?: boolean;
  error?: string;
  onReviewClicked?: (review: ReviewListView, index: number) => void;
  onStartReview?: () => void;
  allowStartReview?: boolean;
  starting?: boolean;
}


const ReviewList: React.FC<IReviewListProps> = ({
  reviews,
  loading,
  error,
  onReviewClicked,
  onStartReview,
  allowStartReview,
  starting,
}) => {

  const navigate = useNavigate();

  const handleReviewClicked = (review: ReviewListView, index: number) => {
    navigate(pmRoutes.getStaffReviewPath(review.reviewee.id, review.id));
    onReviewClicked?.(review, index);
  };

  const handleStartNewReview = () => {
    onStartReview?.();
  };

  if (loading) {
    return (
      <ReviewWrapper>
        <Loader size={Size.Large} cover />
      </ReviewWrapper>
    );
  }

  if (error) {
    return (
      <ReviewWrapper>
        <EmptyMessage
          icon="times-circle"
          title="A problem occurred"
          summary="There was a problem loading the reviews"
          cover
        />
      </ReviewWrapper>
    );
  }

  if (arrays.isEmpty(reviews)) {
    return (
      <ReviewWrapper>
        <EmptyMessage
          icon="rocket"
          title="No reviews"
          summary="No performance management reviews were found"
          cover
        >
          {allowStartReview && (
            <Button
              text={`Start New Review`}
              onClick={handleStartNewReview}
              color={Swatches.Primary}
              working={starting}
            />
          )}
        </EmptyMessage>
      </ReviewWrapper>
    );
  }

  return (
    <ReviewWrapper>
      {allowStartReview && <StartReviewItem onClick={handleStartNewReview} />}

      {reviews?.map((review, index) => (
        <ReviewListItem
          key={index}
          review={review}
          onClick={() => handleReviewClicked(review, index)}
        />
      ))}
    </ReviewWrapper>
  );
};


const StartReviewItem = ({ onClick } : { onClick: () => void }) => {

  const handleClick = () => {
    onClick?.();
  };

  return (
    <ReviewListItemWrapper onClick={handleClick} start={true}>
      <CircleIcon value="plus" outline />
      <div className="review-list-item-title">
        <Label bold>Start New Review</Label>
      </div>
    </ReviewListItemWrapper>
  );
};


const ReviewListItem = ({ review, onClick } : { review: ReviewListView, onClick: (review: ReviewListView) => void }) => {
  const handleClick = () => {
    onClick?.(review);
  };

  return (
    <ReviewListItemWrapper onClick={handleClick}>
      <CircleIcon value="rocket" outline color={Swatches.Low.swatch} />
      <div className="review-list-item-title">
        <Label bold>{review.academicYear}</Label>
        <Chip text={review.statusName} />
      </div>
    </ReviewListItemWrapper>
  );
};


export default ReviewList;
