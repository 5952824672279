import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "../../../../services/client";
import { callbackType } from "types/common/action.types";
import { RoomListView } from "areas/planner/types/classLayoutResponse.types";
import { SchoolPeriodListView } from "types/planner/timetable.types";
import { PlannerSchoolView } from "areas/planner/types/plannerSchoolResponse.types";
import store from "reducers/store";

const builder = new actionTypeBuilder("school");

export const types = {
  GETSCHOOLINFORMATION: builder.build("getschoolinformation"),
  GETSCHOOLROOMS: builder.build("getrooms"),
  GETSCHOOLPERIODS: builder.build("getschoolperiods"),
  SETSCHOOLID: builder.build("setschoolid"),
};


export const getSchoolInformation = (schoolId: number, onSuccess?: callbackType<PlannerSchoolView>) => {
  return action<PlannerSchoolView>(
    () => client.get(`planner/schools/${schoolId}/information`),
    types.GETSCHOOLINFORMATION,
    onSuccess
  );
};

export const getSchoolRooms = (schoolId: number, onSuccess?: callbackType<RoomListView[]>) => {
  return action<RoomListView[]>(
    () => client.get(`planner/schools/${schoolId}/allrooms`),
    types.GETSCHOOLROOMS,
    onSuccess
  );
};

export const getSchoolPeriods = (schoolId: number, onSuccess?: callbackType<SchoolPeriodListView[]>) => {
  return action<SchoolPeriodListView[]>(
    () => client.get(`planner/schools/${schoolId}/periods`),
    types.GETSCHOOLPERIODS,
    onSuccess
  );
};

export const setSchoolId = (schoolId:number) => {
  store.dispatch({ 
    type: types.SETSCHOOLID,
    payload: schoolId,
  });
};


const schoolActions = {
  types,
  getSchoolInformation,
  getSchoolRooms,
  getSchoolPeriods,
  setSchoolId
};

export default schoolActions;
