import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { EmptyMessage, Message, Swatches, Loader, Size } from "ui-kit";
import { blue } from "ui-kit/common/colors";
import { useSelector } from "react-redux";
import studentLeavingLessonActions from "areas/planner/actions/student/studentLeavingLessonActions";
import SelectionButtons from "sharedComponents/common/largeSelectionButtons/selectionButtons";
import LeavingLessonModal from "./leavingLessonModal";
import SelectionButton from "sharedComponents/common/largeSelectionButtons/selectionButton";

const LeavingLessonReasons = ({
  student,
  group,
  tabIndex,
  currentTabIndex,
}) => {
  const { leavingLessonCategories, getCategoriesError, loadingCategories } =
    useSelector(state => state.studentLeavingLesson);

  const [selectedReason, setSelectedReason] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedReason(null);
  };

  const handleReasonClick = reason => {
    setSelectedReason(reason);
    setOpenModal(true);
  };

  useEffect(() => {
    if (
      (tabIndex === undefined || tabIndex === currentTabIndex) &&
      (!leavingLessonCategories || leavingLessonCategories.length === 0)
    ) {
      studentLeavingLessonActions.getLeavingLessonCategories();
    }
  }, [student, currentTabIndex]);

  if (getCategoriesError) {
    return (
      <Message
        text="There was a problem loading the categories"
        color={Swatches.Danger}
      />
    );
  }

  if (loadingCategories) {
    return <Loader size={Size.Medium} fluid />;
  }

  return leavingLessonCategories.length > 0 ? (
    <>
      <SelectionButtons>
        {leavingLessonCategories.map(category => (
          <SelectionButton
            key={category.id}
            item={category}
            itemSub={category.name}
            icon="door-open"
            iconColour={blue}
            handleItemClick={handleReasonClick}
          />
        ))}
      </SelectionButtons>

      <LeavingLessonModal
        selectedReason={selectedReason}
        student={student}
        group={group}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      />
    </>
  ) : (
    <EmptyMessage icon="list" title="No Reasons" />
  );
};

LeavingLessonReasons.propTypes = {
  student: PropTypes.object,
  classGroup: PropTypes.object,
};

export default LeavingLessonReasons;
