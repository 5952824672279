import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import studentDetentionHistoryActions from "areas/behaviour/actions/detentions/studentDetentionHistoryActions";
import { DetentionDetailView } from "areas/behaviour/types/detentionRegisterResponse.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


interface IStudentDetentionHistoryState {
  detentionHistory: DetentionDetailView[];
  loading: boolean;
  error: IBeehiveError;
  refresh: boolean;
}

const INITIAL_STATE: IStudentDetentionHistoryState = {
  detentionHistory: [],
  loading: false,
  error: null,
  refresh: false,
};


const { GETSTUDENTDETENTIONHISTORY } = studentDetentionHistoryActions.types;
const { RESCHEDULEDETENTION, CANCELDETENTION } = detentionActions.types;
const { REVOKEBEHAVIOUR } = behaviourActions.types;


const studentDetentionHistoryReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IStudentDetentionHistoryState => {
  switch (action.type) {
    case GETSTUDENTDETENTIONHISTORY.START:
      return { ...state, loading: true, error: null };

    case GETSTUDENTDETENTIONHISTORY.SUCCESS:
      return {
        ...state,
        detentionHistory: action.payload,
        loading: false,
        refresh: false,
      };

    case GETSTUDENTDETENTIONHISTORY.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        refresh: false,
      };

    case RESCHEDULEDETENTION.SUCCESS:
    case CANCELDETENTION.SUCCESS:
    case REVOKEBEHAVIOUR.SUCCESS:
      return {
        ...state,
        refresh: true,
      };

    default:
      return state;
  }
};

export default studentDetentionHistoryReducer;
