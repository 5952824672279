import React from "react";
import { Chip, Swatches } from "ui-kit";
import { OnCallStatus } from "areas/analytics/reducers/onCallAnalyticsShared.types";


interface IOnCallStatusChipProps {
  onCallStatus: OnCallStatus;
}


const OnCallStatusChip: React.FC<IOnCallStatusChipProps> = ({ onCallStatus }) => {

  switch (onCallStatus) {
    case OnCallStatus.Open:
      return (
        <Chip
          text="Open"
          colorSwatch={Swatches.Danger}
          tooltip="Open Request"
          tooltipSub="The on call request is waiting to be claimed"
        />
      );
    case OnCallStatus.Claimed:
      return (
        <Chip
          text="Claimed"
          colorSwatch={Swatches.Primary}
          tooltip="Claimed Request"
          tooltipSub="The on call request has been claimed"
        />
      );
    case OnCallStatus.Resolved:
      return (
        <Chip
          text="Resolved"
          colorSwatch={Swatches.Success}
          tooltip="Resolved Request"
          tooltipSub="The on call request has been resolved"
        />
      );
    case OnCallStatus.Cancelled:
      return (
        <Chip
          text="Cancelled"
          colorSwatch={Swatches.Default}
          tooltip="Cancelled Request"
          tooltipSub="The on call request has been cancelled"
        />
      );
    default:
      return null;
  }
};


export default OnCallStatusChip;
