import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Loader, Size } from "ui-kit";
import StudentHeader from "./studentHeader";
import flyoutActions from "actions/ui/flyouts";
import studentActions from "areas/planner/actions/student/studentActions";
import schoolActions from "areas/planner/actions/school/schoolActions";
import { ApiExceptionMessage } from "sharedComponents/common";
import MasterDetail from "../../../../sharedComponents/common/masterDetail";
import { getStudentRecordAreas } from "../../utils/studentRecordAreas";
import userUtils from "utils/users";
import { Route, Routes, useNavigate, Navigate, useLocation } from "react-router";
import { studentRoutes } from "areas/planner/plannerRoutes";


const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;


const StudentRecord = () => {

  const { studentId } = useParams();
  let location = useLocation();
  const navigate = useNavigate();

  const { student, loading, refresh, error } = useSelector(
    state => state.student
  );
  const { schoolInformation } = useSelector(state => state.school);
  const { user } = useSelector(state => state.currentUser);

  const [menuItems, setMenuItems] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null); //StudentProfileArea

  const getLastUrlSegment = (path) => {
    var parts = path.split('/');
    var lastSegment = parts.pop() || parts.pop();
    return lastSegment;
  }

  useEffect(() => {
    if (location.pathname && menuItems.length > 0) {
      var path = getLastUrlSegment(location.pathname);
      const found = menuItems.find((menuItem) => menuItem.path == `/${path}`);
      found && setSelectedMenuItem(found);
    }
  }, [location, menuItems]);

  useEffect(() => {
    flyoutActions.closeFlyout();
  }, []);

  useEffect(() => {
    if (!student || student.id !== studentId) {
      studentActions.getStudent(studentId);
    }
  }, [studentId]);

  useEffect(() => {
    if (student) {
      setMenuItems(
        getStudentRecordAreas(
          student,
          selectedMenuItem?.id,
          student.totalNotes
        ).filter(
          listItem =>
            (!listItem.roles || userUtils.isInAnyRoles(user, listItem.roles)) &&
            !listItem.hide
        )
      );
    }

    if (
      student?.school &&
      (!schoolInformation || schoolInformation.id !== student.school.id)
    ) {
      schoolActions.getSchoolInformation(student.school.id);
    }
  }, [student]);

  useEffect(() => {
    if (refresh) {
      studentActions.getStudent(studentId);
    }
  }, [refresh]);

  const onMenuItemClicked = (menuItem) => {
    navigate(studentRoutes.getStudentTabPath(student.id, menuItem.path));
    setSelectedMenuItem(menuItem);
  }

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return (
    student && (
      <>
        <StudentHeader student={student} horizontal />
        <MasterDetail>
          <MasterDetail.MasterList
            width={15}
            list={menuItems}
            selectedItem={selectedMenuItem}
            onClick={onMenuItemClicked}
          />
          <MasterDetail.Detail>
            <Routes>
              <Route
                index
                element={<Navigate to={studentRoutes.getStudentTabPath(student.id, "/details")} replace />}
              />
              {menuItems.map(menuItem => (
                <Route
                  path={menuItem.path}
                  element={menuItem.component}
                />
              ))}
            </Routes>
          </MasterDetail.Detail>
        </MasterDetail>
      </>
    )
  );
};

export default StudentRecord;
