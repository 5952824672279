import React from "react";
import { useSelector } from "react-redux";
import {
  EmptyMessage,
  Swatches,
  Size,
  Table,
  Loader,
  SplitButton,
} from "ui-kit";
import { SchoolAvatar } from "sharedComponents/common";
import { Constants } from "configuration";
import { arrays, users } from "utils";
import calendarActions from "../../actions/calendarActions";
import { useNavigate } from "react-router";
import { wallplannerRoutes } from "../../calendarRoutes";
import { WallplannerListView } from "areas/calendar/types/calendarResponses.types";
import { RootState } from "reducers/store";
import { useAppSelector } from "reducers/hooks";
import { IBeehiveError } from "types/common/errors.types";


interface IWallPlannerTableProps {
  sessions: WallplannerListView[];
  academicYear: number;
  loading: boolean;
  error?: IBeehiveError;
}


const WallPlannerTable: React.FC<IWallPlannerTableProps> = ({ sessions, academicYear, loading, error }) => {

  const { user } = useAppSelector(state => state.currentUser);
  const { exporting, exportError } = useAppSelector(state => state.wallPlannerSessions);
  const navigate = useNavigate();

  const getSchool = (schoolId: number) => {
    if (schoolId === 99) {
      return {
        id: 99,
        name: "Lionheart Educational Trust",
        code: "LHT",
        isPrimary: false,
        disallowedDates: [],
      };
    }
    return Constants.SCHOOLS.find(school => school.id == schoolId);
  };

  const handleEditClick = (session: WallplannerListView) => {
    navigate(wallplannerRoutes.getEditPath(session.schoolId, academicYear));
  };

  const handlePreviewClick = (session: WallplannerListView) => {
    calendarActions.getPlannerPreview(session.schoolId, academicYear);
  };

  if (loading && arrays.isEmpty(sessions)) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem while loading the wall planner sessions"
        icon="times-circle"
        cover
      />
    );
  }

  return (
    <Table
      zebra
      grow
      loading={loading}
      error={error != null}
      empty={arrays.isEmpty(sessions)}
      emptyMessage={
        <EmptyMessage
          icon="calendar"
          title="No Wall Planner Sessions Found"
          cover
        />
      }
    >
      <Table.Header>
        <Table.HeaderCell width={2}>School</Table.HeaderCell>
        <Table.HeaderCell width={2}>Event Count</Table.HeaderCell>
        <Table.HeaderCell width={2} right>
          Session Details
        </Table.HeaderCell>
      </Table.Header>

      <Table.Body>
        {sessions?.map((session: WallplannerListView, index: number) => (
          <Table.Row key={index}>
            <Table.Cell width={2}>
              <SchoolAvatar school={getSchool(session?.schoolId)} />
            </Table.Cell>
            <Table.Cell width={2}>{session?.eventCount}</Table.Cell>
            <Table.Cell width={2} right>
              <SplitButton
                size={Size.Small}
                text="Details"
                color={Swatches.Default}
                onDefaultClick={() => handleEditClick(session)}
                working={exporting}
              >
                <SplitButton.Option
                  onClick={() => handleEditClick(session)}
                  text="Edit Events"
                  show
                />
                <SplitButton.Option
                  onClick={() => handlePreviewClick(session)}
                  text="Preview"
                  show={users.isInAnyRoles(
                    user,
                    [
                      Constants.ROLES.WALLPLANNER_ADMINISTRATOR,
                      Constants.ROLES.WALLPLANNER_USER
                    ]
                  )}
                />
              </SplitButton>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default WallPlannerTable;
