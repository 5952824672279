import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Tabs, Message, CircleIcon } from "ui-kit";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { strings, users } from "utils";
import { Constants } from "configuration";
import { consentRoutes } from "areas/administration/adminRoutes";
import feedActions from "../actions/feedActions";
import consentActions from "areas/administration/actions/consentActions";
import Subheader from "sharedComponents/layout/header/subheader";
import FeedList from "./feedList";
import FavouritesList from "./favouritesList";
import FeedMediaModal from "./feedMediaModal";
import FeedbackModal from "./feedbackModal";
import { RootState } from "reducers/store";

const FeedWrapper = styled.div`
  max-width: 45rem;
  width: 100%;
  margin: 0 auto 0 auto;

  .circle-icon.feedback-form {
    position: absolute;
    top: 0;
    right: -48px; //temp fix while favourited articles are hidden
    transform: scaleX(-1);
  }
`;

const Feed = () => {
  var { count } = useSelector((state: RootState) => state.newConsentForms);
  var { user } = useSelector((state: RootState) => state.currentUser);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [feedbackFormOpen, setFeedbackFormOpen] = useState(false);

  useEffect(() => {
    user &&
      user.userType != Constants.USER_TYPES.STUDENT &&
      consentActions.checkNewConsentFormsForUser(user.id);
  }, [user]);

  const ConsentAlert = () => {
    return (
      <Message
        onClick={() => navigate(`${pathname}${consentRoutes.default}`)}
        icon="clipboard-check"
        text={`New Consent ${strings.pluralize("Form", "Forms", count)}`}
        subtext={`You have ${ 
          count === 1 ? "a" : ""
        } new consent ${strings.pluralize(
          "form",
          "forms",
          count
        )} available to complete. Click for more information.`}
      />
    );
  };

  const handleFeedbackClose = () => {
    setFeedbackFormOpen(false);
  };

  return (
    <FeedWrapper>
      {count > 0 && <ConsentAlert />}
      <Subheader>
        <Tabs>
          <Tabs.Pane
            key={0}
            label="All"
            name="all"
            tooltip="All Articles"
            tooltipSub="View all of your new articles"
          >
            <FeedList />
          </Tabs.Pane>
          {/* <Tabs.Pane
            key={1}
            label="Favourites"
            name="favourites"
            tooltip="Favourited Articles"
            tooltipSub="View your favourited articles"
          >
            <FavouritesList />
          </Tabs.Pane> */}
        </Tabs>
        {users.isStaff(user) && (
          <CircleIcon
            className="feedback-form"
            value="comment"
            onClick={() => setFeedbackFormOpen(!feedbackFormOpen)}
            tooltip="Feedback"
            tooltipSub="Give your feedback"
          />
        )}
      </Subheader>
      <FeedMediaModal />
      {users.isStaff(user) && (
        <FeedbackModal open={feedbackFormOpen} onClose={handleFeedbackClose} />
      )}
    </FeedWrapper>
  );
};

export default Feed;
