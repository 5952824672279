import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import { SchoolBehaviourCodeListView } from "areas/behaviour/types/behaviourResponses.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


interface IRewardCodesState {
  rewardCodes: SchoolBehaviourCodeListView[];
  loading: boolean;
  getRewardCodesError: IBeehiveError;
}

const INITIAL_STATE: IRewardCodesState = {
  rewardCodes: [],
  loading: false,
  getRewardCodesError: null,
};

const { GETREWARDCODES } = rewardActions.types;


const rewardCodesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IRewardCodesState => {

  switch (action.type) {
    case GETREWARDCODES.START:
      return { ...state, loading: true, getRewardCodesError: null };

    case GETREWARDCODES.SUCCESS:
      return { ...state, loading: false, rewardCodes: action.payload };

    case GETREWARDCODES.FAILED:
      return { ...state, loading: false, getRewardCodesError: action.payload };

    default:
      return state;
  }
};

export default rewardCodesReducer;
