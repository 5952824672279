import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Spacing } from "ui-kit";
import studentActions from "areas/planner/actions/student/studentActions";
import FeedFilters from "areas/planner/components/activityFeed/feedFilters";

const Wrapper = styled.div`
  margin-top: ${Spacing.Small}px;
`;

const StudentFeedFilters = () => {
  const { feedTypeFilter, createdDateFilter } = useSelector(
    state => state.studentActivityFeed
  );

  const [selectedActivityFilter, setSelectedActivityFilter] =
    useState(feedTypeFilter);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);

  useEffect(() => {
    if (feedTypeFilter !== selectedActivityFilter) {
      studentActions.setStudentFeedTypeFilter(selectedActivityFilter);
    }
  }, [selectedActivityFilter]);

  useEffect(() => {
    if (createdDateFilter !== selectedDateFilter) {
      studentActions.setStudentFeedCreatedDateFilter(selectedDateFilter);
    }
  }, [selectedDateFilter]);

  return (
    <Wrapper>
      <FeedFilters
        selectedActivityFilter={selectedActivityFilter}
        setSelectedActivityFilter={setSelectedActivityFilter}
        selectedDateFilter={selectedDateFilter}
        setSelectedDateFilter={setSelectedDateFilter}
      />
    </Wrapper>
  );
};

export default StudentFeedFilters;
