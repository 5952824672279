import React from "react";
import { Constants } from "configuration";
import {
  Modal,
  StructuredList,
  Dropdown,
  Card,
  ActionBar,
  Button,
  Swatches,
  Loader,
  Size,
} from "ui-kit";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";

const EditAnimalAvatarModal = ({
  open,
  setOpen,
  selectedAvatarId,
  handleOnChangeAvatar,
  handleOnClickSaveAvatar,
  loading,
  error,
}) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)} height="300px">
      <Modal.Body>
        {loading ? (
          <Loader size={Size.Large} cover />
        ) : (
          <>
            <ApiExceptionMessage error={error} />
            <Card>
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="Animal Avatar">
                    <Dropdown
                      items={Constants.PRIMARY_AVATAR_TYPES_ARRAY}
                      fluid
                      onChange={value => handleOnChangeAvatar(value)}
                      value={selectedAvatarId}
                    />
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
            <ActionBar>
              <Button
                text="Save"
                color={Swatches.Success}
                onClick={() => handleOnClickSaveAvatar()}
              />
            </ActionBar>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EditAnimalAvatarModal;
