import {
  FormListView,
  FormCategoryListView,
} from "areas/forms/types/formResponse.types";
import { FormStaffType } from "areas/forms/types/formShared.types";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { Dropdown } from "ui-kit";

interface IFormTypeDropdownProps {
  onChange: (formType: FormListView) => void;
  selectedCategory: FormCategoryListView;
  initialValue?: FormListView;
  fluid?: boolean;
}

interface formTypeOption {
  key: number;
  label: string;
  value: number;
}

const FormTypeDropdown: React.FC<IFormTypeDropdownProps> = ({
  onChange,
  selectedCategory,
  initialValue,
  fluid
}) => {

  const { formTypes, loading } = useSelector(
    (state: RootState) => state.formCategories
  );
  const ALL_OPTION: formTypeOption = { key: -1, label: "All Forms", value: -1 };

  const getOptions = (selectedCategory: FormCategoryListView) => {
    var options = selectedCategory
      ? formTypes.filter(
          (form) =>
            form.categoryId === selectedCategory.id ||
            selectedCategory.id === -1
        )
      : formTypes;

    var mapped = options.map(({ id, name }) => ({
      key: id,
      label: name,
      value: id,
    }));

    return [ALL_OPTION, ...mapped];
  };

  const [options, setOptions] = useState<formTypeOption[]>(
    getOptions(selectedCategory)
  );
  const [selectedValue, setSelectedValue] = useState<number>(-1);

  const handleChange = (value: number) => {
    setSelectedValue(value);
    if (onChange) {
      const formType = formTypes.find((x) => x.id === value);
      var defaultForm: FormListView = {
        id: ALL_OPTION.key,
        categoryId: null,
        staffType: FormStaffType.All,
        name: ALL_OPTION.label,
        summary: null,
        icon: null,
        requiresAuthorisation: true,
      };
      onChange(formType ? formType : defaultForm);
    }
  };

  useEffect(() => {
    setOptions(getOptions(selectedCategory));
  }, [selectedCategory]);

  useEffect(() => {
    if (initialValue == null) {
      return;
    }
    const formType = options.find((x) => x.value === initialValue.id);
    setSelectedValue(formType ? formType.value : -1);
  }, [initialValue]);

  return (
    <Dropdown
      placeholder="Form Type"
      items={options}
      onChange={handleChange}
      value={selectedValue}
      loading={loading}
      fluid={fluid}
    />
  );
};

export default FormTypeDropdown;
