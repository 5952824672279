import store from "reducers/store";

const types = {
  SET_ACTIVE_CHILD: "SET_ACTIVE_CHILD",
};


const setActiveChild = (childId: string) => 
  store.dispatch({
    type: types.SET_ACTIVE_CHILD,
    payload: childId,
  });



const sendParentsActions = {
  types,
  setActiveChild,
};

export default sendParentsActions;
