import React from "react";
import PropTypes from "prop-types";
import RoomLayoutOption from "./roomLayoutOption";
import styled from "styled-components";
import {
  List,
  neutral,
  honeygold,
  Label,
  Icon,
  Size,
  CircleIcon,
} from "ui-kit";
import { ROOM_LAYOUT_TEMPLATES } from "../../../../constants/classViewer/roomLayout";
import Grid from "ui-kit/basic/grid";

const Wrapper = styled.div`
  .grid {
    margin-left: 0;

    .room-layout-option {
      background: ${neutral[200]};
      border: unset;

      &:hover {
        background: ${neutral[600]};
      }

      &:hover,
      &.selected {
        .room-layout-option-preview .room-layout-grid [class*="room-row-"] {
          [class*="room-col-"] {
            &:not(.seat) {
              border: 1px solid ${neutral[200]};
            }
            &.seat {
              background-color: ${neutral[100]};
              border: 1px solid ${neutral[100]};
            }
          }
        }
      }

      &.selected {
        background: ${honeygold};
      }
    }
  }
`;

const RoomLayoutOptions = ({
  roomlayouts,
  selectedRoomTemplate,
  onSelectTemplate,
  showCreateNewLayout,
}) => {
  return (
    <Wrapper>
      <Grid>
        {showCreateNewLayout && (
          <Grid.Item
            className={`room-layout-option ${
              selectedRoomTemplate && selectedRoomTemplate.id === -1
                ? "selected"
                : ""
            }`}
            onClick={() =>
              onSelectTemplate({ id: -1, name: "Create New Room Layout" })
            }
            text="Create New Room Layout"
            body={<CircleIcon value="plus" size={Size.ExtraLarge} />}
            selected={selectedRoomTemplate && selectedRoomTemplate.id === -1}
          />
        )}
        {roomlayouts.map(template => (
          <RoomLayoutOption
            key={template.id}
            template={template}
            selectedRoomTemplate={selectedRoomTemplate}
            onSelectTemplate={onSelectTemplate}
          />
        ))}
      </Grid>
    </Wrapper>
  );
};

RoomLayoutOptions.propTypes = {
  selectedRoomTemplate: PropTypes.object,
  onSelectTemplate: PropTypes.func,
};

export default RoomLayoutOptions;
