import accessArrangementActions from "areas/send/actions/accessArrangements/accessArrangementsActions";
import { AccessArrangementListView } from "areas/send/types/passportResponse.types";
import { IBeehiveAction } from "types/common/action.types";

interface IAccessArrangementsState {
  accessArrangements: AccessArrangementListView[];
  loading: boolean;
  saving: boolean;
  error: string | null;
}

const INITIAL_STATE: IAccessArrangementsState = {
  accessArrangements: [],
  loading: false,
  saving: false,
  error: null,
};


const accessArrangementsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAccessArrangementsState => {

  const {
    ACCESSARRANGEMENTS_GET,
    ACCESSARRANGEMENT_CREATE,
    ACCESSARRANGEMENT_SAVE,
    ACCESSARRANGEMENT_DELETE,
  } = accessArrangementActions.types;

  switch (action.type) {
    case ACCESSARRANGEMENTS_GET.START:
      return { ...state, loading: true };
    case ACCESSARRANGEMENTS_GET.SUCCESS:
      return { ...state, loading: false, accessArrangements: action.payload };
    case ACCESSARRANGEMENTS_GET.FAILED:
      return { ...state, loading: false, error: action.payload };

    case ACCESSARRANGEMENT_CREATE.SUCCESS:
      return {
        ...state,
        accessArrangements: [action.payload, ...state.accessArrangements],
      };

    case ACCESSARRANGEMENT_SAVE.SUCCESS:
      return {
        ...state,
        accessArrangements: state.accessArrangements.map(a =>
          a.id === action.payload.id ? action.payload : a
        ),
      };

    case ACCESSARRANGEMENT_DELETE.SUCCESS:
      return {
        ...state,
        accessArrangements: state.accessArrangements.filter(
          a => a.id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default accessArrangementsReducer;
