import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import { BehaviourUpscaleView } from "areas/behaviour/types/behaviourResponses.types";
import studentActions from "areas/planner/actions/student/studentActions";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";

interface IBehaviourState {
  behaviour: BehaviourUpscaleView;
  refresh: boolean;
  loading: boolean;
  loadingRevokeBehaviour: boolean;
  addBehaviourError: IBeehiveError;
  getBehaviourError: IBeehiveError;
  updateBehaviourError: IBeehiveError;
  revokeBehaviourError: IBeehiveError;
}

const INITIAL_STATE: IBehaviourState = {
  behaviour: null,
  refresh: false,
  loading: false,
  loadingRevokeBehaviour: false,
  addBehaviourError: null,
  getBehaviourError: null,
  updateBehaviourError: null,
  revokeBehaviourError: null,
};

const {
  ADDBEHAVIOUR,
  GETBEHAVIOUR,
  UPDATEBEHAVIOUR,
  REVOKEBEHAVIOUR,
  GETDEFAULTDETENTION,
} = behaviourActions.types;

const {
  ATTENDEDDETENTION,
  MISSEDDETENTION,
  CANCELDETENTION,
  RESCHEDULEDETENTION,
} = detentionActions.types;

const { GETSTUDENT } = studentActions.types;


const behaviourReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IBehaviourState => {

  switch (action.type) {
    case GETDEFAULTDETENTION.SUCCESS:
      return {
        ...state,
        addBehaviourError: null,
      };

    case ADDBEHAVIOUR.START:
      return {
        ...state,
        loading: true,
        addBehaviourError: null,
      };

    case ADDBEHAVIOUR.SUCCESS:
      return { ...state, loading: false };

    case ADDBEHAVIOUR.FAILED:
      return {
        ...state,
        loading: false,
        addBehaviourError: action.payload,
      };

    case GETBEHAVIOUR.START:
      return {
        ...state,
        loading: true,
        getBehaviourError: null,
        addBehaviourError: null,
        updateBehaviourError: null,
        revokeBehaviourError: null,
      };

    case GETBEHAVIOUR.SUCCESS:
      return {
        ...state,
        refresh: false,
        loading: false,
        behaviour: action.payload,
      };

    case GETBEHAVIOUR.FAILED:
      return {
        ...state,
        loading: false,
        getBehaviourError: action.payload,
      };

    case UPDATEBEHAVIOUR.START:
      return { ...state, updateBehaviourError: null, loading: true };

    case ATTENDEDDETENTION.SUCCESS:
    case MISSEDDETENTION.SUCCESS:
    case CANCELDETENTION.SUCCESS:
    case RESCHEDULEDETENTION.SUCCESS:
      return { ...state, refresh: true, loading: false };

    case CANCELDETENTION.FAILED:
    case RESCHEDULEDETENTION.FAILED:
      return { ...state, loading: false };

    case UPDATEBEHAVIOUR.FAILED:
      return {
        ...state,
        updateBehaviourError: action.payload,
        loading: false,
      };

    case REVOKEBEHAVIOUR.START:
      return {
        ...state,
        revokeBehaviourError: null,
        loadingRevokeBehaviour: true,
      };

    case REVOKEBEHAVIOUR.SUCCESS:
      return {
        ...state,
        loadingRevokeBehaviour: false,
      };

    case REVOKEBEHAVIOUR.FAILED:
      return {
        ...state,
        revokeBehaviourError: action.payload,
        loadingRevokeBehaviour: false,
      };

    case GETSTUDENT.SUCCESS:
      return {
        ...state,
        addBehaviourError: null,
        getBehaviourError: null,
        updateBehaviourError: null,
        revokeBehaviourError: null,
      };

    default:
      return state;
  }
};

export default behaviourReducer;
