import interventionActions from "areas/planner/actions/interventions/interventionActions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import InterventionsTable from "./interventionsTable";
import { arrays, files, types } from "utils";
import { Card, Subtitle } from "ui-kit";
import InterventionsOverview from "./interventionsOverview";
import InterventionsFilter from "./interventionsFilter";
import { InterventionRecordView } from "areas/planner/types/interventions/interventionResponses";
import InterventionRecordModal from "./InterventionRecordModal";
import { InterventionProgressRating } from "areas/planner/types/interventions/interventionShared";
import { StudentListView } from "types/users/userListViews.types";
import config from "configuration/config";
import { GroupListView } from "types/users/userGroups.types";
import { BeehiveReport } from "types/common/action.types";


const Interventions = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { interventions, error, loading } = useSelector((state: RootState) => state.groupInterventions);
  const { loading: loadingReport } = useSelector((state: RootState) => state.interventionReporting);
  const { group } = useSelector((state: RootState) => state.group); //ClassGroupStaffView
  const [_selectedRecord, _setSelectedRecord] = useState<InterventionRecordView | null>(null);
  const [studentsWithoutRecord, setStudentsWithoutRecord] = useState<StudentListView[]>([]);

  useEffect(() => {
    group && interventionActions.getGroupInterventions(group.id);
  }, []);

  useEffect(() => {
    if (!arrays.isEmpty(interventions) && group) { 
      const _studentsWithoutRecords: StudentListView[] = group.students.filter((gs: any) => !interventions.some(itv => itv.student.id === gs.id));
      setStudentsWithoutRecord(_studentsWithoutRecords);
    }
  }, [interventions, group]);

  const handleCreateNewRecord = () => {
    _setSelectedRecord({
      id: null,
      group: group as GroupListView, //Can do this as ClassGroupStaffView inherits from GroupListView
      student: null,
      academicYearId: config.academicYear.current,
      target: "",
      notes: "",
      rating: InterventionProgressRating.Expected,
      createdBy: types.currentUserToTiny(user),
      updatedBy: types.currentUserToTiny(user),
      createdDate: new Date(),
      updatedDate: new Date(),
      attendances: null
    });
  }

  const handleEditRecord = (record: InterventionRecordView) => {
    _setSelectedRecord(record);
  }
  
  const handleExport = () => {
    group && interventionActions.exportGroupInterventionReport(group.id,
      (data: BeehiveReport) => files.openCsv(data, group.name)
    );
  }

  const handleProgressReport = () => {
    group && interventionActions.interventionGroupProgressReport(group.id,
      (data: BeehiveReport) => files.openCsv(data, group.name)
    );
  }

  const handleSaved = () => {
    _setSelectedRecord(null);
  }
  const handleCancel = () => {
    _setSelectedRecord(null);
  }

  return (
    <>
      <InterventionsOverview
        interventions={interventions}
        loading={loading}
        error={error}
      />

      <Subtitle>Student Intervention Records</Subtitle>
      <InterventionsFilter
        handleCreate={handleCreateNewRecord}
        loading={loading}
      />

      <Card>
        <Card.Body noPad={!arrays.isEmpty(interventions)}>
          <InterventionsTable
            interventions={interventions}
            handleCreate={handleCreateNewRecord}
            handleEdit={handleEditRecord}
            handleExport={handleExport}
            handleProgressReport={handleProgressReport}
            showAttendance={!arrays.isEmpty(group?.linkedEvents)}
            loading={loading}
            loadingReport={loadingReport}
            error={error}
          />
        </Card.Body>
      </Card>

      <InterventionRecordModal
        record={_selectedRecord}
        interventionGroup={group}
        availableNewStudents={studentsWithoutRecord}
        open={_selectedRecord != null}
        onSave={handleSaved}
        onCancel={handleCancel}
      />
    </>
  );
};

export default Interventions;
