import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import {
  neutral,
  Size,
  BorderRadius,
  displayFont,
  typescale,
  Spacing,
  Spinner,
  Icon,
  fontStyle,
} from "../index";
import { ColorSwatch } from "../common/colors";
import uiKit from "utils/uiKit";

const Wrapper = styled.div<{
  colorSwatch?: ColorSwatch;
  disabled?: boolean;
  working?: boolean;
  expanded: boolean;
  size?: Size;
  top?: boolean;
}>`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  background: ${neutral[400]};
  border-radius: ${BorderRadius.Default}px;
  box-sizing: border-box;
  padding: ${Spacing.Default}px 0 ${Spacing.Default}px 0;
  margin: 0;
  flex-grow: 0;
  height: 36px;
  width: auto;

  background: ${props => props.colorSwatch && props.colorSwatch.swatch};
  color: ${props => props.colorSwatch && props.colorSwatch.foreground};
  transition: all ease 100ms;
  justify-content: center;

  .icon,
  i {
    color: ${props => props.colorSwatch && props.colorSwatch.foreground};
  }

  button {
    ${({ colorSwatch }) =>
      fontStyle(
        displayFont.medium,
        typescale.control3,
        colorSwatch && colorSwatch.foreground
      )}

    background: none;
    border: 0;

    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    cursor: ${props => (props.working ? "wait" : "pointer")};
  }

  ${({ expanded }) =>
    expanded &&
    `
      border-bottom-left-radius:0; 
      border-bottom-right-radius:0;
      background: ${neutral[600]};

      .split-button-default, i, .icon {
        color: ${neutral[100]};
      }
    `}

  ${({ expanded, top }) =>
    expanded &&
    top &&
    `
      border-bottom-left-radius:${BorderRadius.Default}px; 
      border-bottom-right-radius:${BorderRadius.Default}px;
      border-top-left-radius:0; 
      border-top-right-radius:0;
    `}

  ${({ disabled }) =>
    disabled &&
    `cursor: not-allowed;
    opacity: 0.4;
    
    button {
      cursor: not-allowed !important;
    }
    `}

  .spinner {
    position: absolute;
    right: 0;
    left: 0;
    color: ${props => props.colorSwatch && props.colorSwatch.foreground};
  }
`;

const TextWrapper = styled.span<{
  working?: boolean;
  colorSwatch?: ColorSwatch;
}>`
  display: flex;
  position: relative;
  width: auto;
  align-items: center;
  justify-content: center;
  visibility: ${props => (props.working ? "hidden" : "visible")};
`;

const Inner = styled.div<{ working?: boolean }>`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;

  .split-button-default {
    flex-grow: 1;
    padding: 0 ${Spacing.Jumbo}px;
  }

  .split-button-expand {
    border-left: 1px solid rgba(255, 255, 255, 0.25) !important;
    padding: 5px ${Spacing.Medium}px 0 ${Spacing.Default}px;

    visibility: ${props => (props.working ? "hidden" : "visible")};
  }
`;

const Options = styled.div<{ expanded: boolean }>`
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  ${({ expanded }) =>
    expanded
      ? `
  display: flex; 
  position: absolute;
  width: 100%; 
  z-index: 9999; 
  background: white;
  top: 100%;
  border-bottom-left-radius:${BorderRadius.Default}px;
  border-bottom-right-radius:${BorderRadius.Default}px;
  
  button {
    padding: ${Spacing.Default}px;
    border-bottom: 1px solid ${neutral[200]};
    color: ${neutral[600]};

    &:disabled {
      cursor: not-allowed;
      color: ${neutral[400]};
    }

    &:last-child{
      border-bottom: 0;
    }

    &:hover {
      background: ${neutral[200]};
    }
  }
  
  `
      : `display: none;`}

  flex-direction: column;
  button {
    display: block;
  }
`;

export interface ISplitButtonProps {
  children?: React.ReactElement<ISplitButtonOptionProps>[];
  text: string;
  onDefaultClick?: () => void;
  color?: ColorSwatch;
  working?: boolean;
  disabled?: boolean;
  size?: Size;
}

export interface ISplitButtonOptionProps {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  show?: boolean;
  confirm?: string;
  color?: ColorSwatch;
}

const SplitButton: React.FC<ISplitButtonProps> & {
  Option: typeof SplitButtonOption;
} = ({ text, children, onDefaultClick, color, working, disabled, size }) => {
  const [expanded, _setExpanded] = useState(false);
  const [bottomSpace, setBottomSpace] = useState(0);

  const handleExpand = (event: React.MouseEvent<HTMLElement>) => {
    !disabled && !working && _setExpanded(!expanded);
    event.stopPropagation();
  };

  const handleDefaultClick = () => {
    !working && onDefaultClick?.();
  };

  useEffect(() => {
    document.addEventListener("click", handleClick); // add when mounted
    return () => {
      document.removeEventListener("click", handleClick); // return function to be called when unmounted
    };
  }, []);

  const wrapperRef = useRef() as React.RefObject<HTMLDivElement>;

  const handleClick = (e: MouseEvent) => {
    if (wrapperRef && !wrapperRef?.current?.contains(e.target as Node)) {
      _setExpanded(false);
    }
  };

  useEffect(() => {
    if (working) {
      _setExpanded(false);
    }
  }, [working]);

  return children?.filter(x => x.props.show === true || x.props.show === null)
    .length || onDefaultClick ? (
    <Wrapper
      ref={wrapperRef}
      className="split-button form-control"
      colorSwatch={color}
      disabled={disabled}
      working={working}
      size={size}
      expanded={expanded}
      top={
        bottomSpace <
        (children ? children.filter(c => c.props.show).length * 36 : 0)
      }
    >
      <Inner
        className="split-button-inner"
        working={working}
        ref={el => {
          if (el) {
            const space = uiKit.getElementBottomSpace(el);
            bottomSpace !== space && setBottomSpace(space);
          }
        }}
      >
        <button
          className="split-button-default"
          onClick={onDefaultClick ? handleDefaultClick : handleExpand}
        >
          {working && <Spinner size={Size.Medium} color={color?.foreground} />}

          <TextWrapper working={working} colorSwatch={color}>
            <span>{text}</span>
          </TextWrapper>
        </button>

        <button className="split-button-expand" onClick={handleExpand}>
          <Icon value="caret-down" />
        </button>
      </Inner>
      <Options className="split-button-options" expanded={expanded}>
        {children
          ?.filter(x => x.props.show === null || x.props.show === true)
          .map((child, index) => {
            return React.cloneElement(
              child as React.ReactElement<ISplitButtonOptionProps>,
              {
                key: index,
                onClick: () => {
                  child.props.onClick?.();
                  _setExpanded(false);
                },
              }
            );
          })}
      </Options>
    </Wrapper>
  ) : null;
};

const SplitButtonOption: React.FC<ISplitButtonOptionProps> = ({
  text,
  onClick,
  disabled,
  confirm,
  color,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (confirm) {
      if (window.confirm(confirm)) {
        onClick?.();
      }
    } else {
      onClick?.();
    }
  };

  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      style={{ color: color?.swatch }}
    >
      {text}
    </button>
  );
};

SplitButton.Option = SplitButtonOption;

export default SplitButton;
