import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  Message,
  Swatches,
  ToastService,
  Table,
  Size,
  DateTime,
  DatePicker,
  Chip,
  SplitButton,
  ActionBar,
  Left,
  Checkbox,
  Pager,
  DetailLabel,
  Right,
  Currency,
  Button,
} from "ui-kit";
import paymentActions from "areas/payments/actions/paymentsActions";
import flyoutActions from "actions/ui/flyouts";
import { arrays } from "utils";
import OrderStatus from "../orders/orderStatus";
import OrderFlyout from "../orders/orderFlyout";
import { Constants } from "configuration";
import { RootState } from "reducers/store";
import { OrderListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { useScroll } from "hooks/useScroll";


const OrderLink = styled.span`
  &:hover {
    cursor: pointer;
  }
`;


const BasketTools = () => {

  const { scrollToTop } = useScroll();
  const { loading, error, baskets, paging, working, voidError } = useSelector((state: RootState) => state.basketsWithDuplicateOrders);

  const [_startDate, _setStartDate] = useState<Date>(new Date());
  const [_endDate, _setEndDate] = useState<Date>(new Date());
  const [_includeVoid, _setIncludeVoid] = useState<boolean>(true);
  const [selectedOrder, setSelectedOrder] = useState<OrderListView | null>(null);

  const handleRowClicked = (order: OrderListView) => {
    setSelectedOrder(order);
    flyoutActions.openFlyout(Constants.FLYOUTS.ORDER);
  };

  const getBaskets = (pageIndex: number) => {
    paymentActions.getBasketsWithDuplicateOrders(
      {
        startDate: _startDate,
        endDate: _endDate,
        includeVoid: _includeVoid,
      },
      pageIndex,
      () => scrollToTop()
    );
  };

  const handleGetBaskets = () => {
    getBaskets(0);
  };

  const handlePage = (pageIndex: number) => {
    getBaskets(pageIndex);
  };

  const handleVoid = (order: OrderListView, issueRefund: boolean) => {
    if (window.confirm(`This will void order ${order.id} for ${order.user.firstName} ${order.user.lastName}${issueRefund ? " and issue a refund" : ""}. This cannot be undone. Are you sure?`)) {
      paymentActions.voidOrder(
        { 
          userId: order.user.id, 
          orderId: order.id, 
          issueRefund: issueRefund 
        },
        () => ToastService.pop(`Order ${order.id} voided`, null, "invoice")
      );
    }
  };

  return (
    <>
      <Message color={Swatches.Danger} text={error} />
      <ActionBar>
        <Left>
          <ActionBar.Filter>
            <ActionBar.FilterItem>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                selectedDate={_startDate}
                onChange={(date) => _setStartDate(date)}
                closeOnSelect
                fluid
              />
            </ActionBar.FilterItem>
            <ActionBar.FilterItem>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                selectedDate={_endDate}
                onChange={(date) => _setEndDate(date)}
                closeOnSelect
                fluid
              />
            </ActionBar.FilterItem>
            <ActionBar.FilterItem>
              <Checkbox
                checked={_includeVoid}
                onChange={(value) => _setIncludeVoid(value.checked)}
                text={`Include Baskets with Void Orders`}
              />
            </ActionBar.FilterItem>
          </ActionBar.Filter>
        </Left>
        <Right>
          <Button
            onClick={handleGetBaskets}
            text="Get Baskets"
            working={loading}
            color={Swatches.Primary}
            size={Size.Small}
          />
        </Right>
      </ActionBar>

      {!arrays.isEmpty(baskets) && (
        <>
          <Message color={Swatches.Danger} text={voidError} />
          <Table>
            <Table.Header>
              <Table.HeaderCell width={1}>Status</Table.HeaderCell>
              <Table.HeaderCell width={1}>Order ID</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell right></Table.HeaderCell>
              <Table.HeaderCell right>Total</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Header>
            <Table.Body>
              {baskets?.map((basket, i) => (
                <>
                  <Table.GroupHeader
                    label={
                      <DetailLabel
                        label={
                          <>
                            {basket.user.firstName} {basket.user.lastName}
                            {!basket.orders.every((x) => x.status < 2) && (
                              <Chip
                                text="Safe"
                                colorSwatch={Swatches.Success}
                              />
                            )}
                          </>
                        }
                        sub={`Basket ${basket.id}`}
                        bold
                      />
                    }
                    colspan={6}
                  />
                  {basket.orders.map((order, j) => (
                    <Table.Row key={j}>
                      <Table.Cell width={1}>
                        <OrderStatus status={order.status} />
                      </Table.Cell>
                      <Table.Cell width={1}>
                        <OrderLink onClick={() => handleRowClicked(order)}>
                          {order.id}
                        </OrderLink>
                      </Table.Cell>
                      <Table.Cell>
                        <DateTime date={order.date} bold />
                      </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell right>
                        <Currency value={order.total} />
                      </Table.Cell>
                      <Table.Cell right>
                        {order.status < 2 && (
                          <SplitButton
                            text="Void Order"
                            onDefaultClick={() => handleVoid(order, false)}
                            color={Swatches.Danger}
                            size={Size.Small}
                            working={working}
                          >
                            <SplitButton.Option
                              text="Void Order"
                              onClick={() => handleVoid(order, false)}
                              color={Swatches.Danger}
                              show={true}
                            />
                            <SplitButton.Option
                              text="Void Order & Refund"
                              onClick={() => handleVoid(order, true)}
                              color={Swatches.Danger}
                              show={true}
                            />
                          </SplitButton>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Cell colspan={6} center>
                <Pager
                  pageCount={paging?.totalPages}
                  onPage={handlePage}
                  selectedPage={paging?.pageNumber}
                />
              </Table.Cell>
            </Table.Footer>
          </Table>
          <OrderFlyout order={selectedOrder} />
        </>
      )}
    </>
  );
};

export default BasketTools;
