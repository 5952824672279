import { IBeehiveAction } from "types/common/action.types";
import matriculationActions from "../actions/matriculationActions";

export interface matriculationFilter {
  schoolId: number;
}

interface IMatriculationFilterState {
  loading: boolean;
  error: string | null;
  filter: matriculationFilter,
}

const INITIAL_STATE: IMatriculationFilterState = {
  loading: false,
  error: null,
  filter: {
    schoolId: -1,
  },
};

const matriculationFilterReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IMatriculationFilterState => {

  const { MATRICULATION_UPDATEFILTER } = matriculationActions.types;

  switch (action.type) {
    case MATRICULATION_UPDATEFILTER:
      return {
        ...state,
        filter: action.payload,
      };

    default:
      return state;
  }
};

export default matriculationFilterReducer;
