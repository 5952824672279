import paymentActions from "areas/payments/actions/paymentsActions";
import { ProductListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import defaults from "configuration/defaults";
import { IBeehiveAction } from "types/common/action.types";
import { PageInfo } from "types/common/paging.types";
import { SearchResultView } from "types/common/views.types";


export interface ISearchProductsState {
  products: ProductListView[];
  error: string | null;
  loading: boolean;
  paging: PageInfo;
}

const INITIAL_STATE: ISearchProductsState = {
  products: [],
  error: null,
  loading: false,
  paging: defaults.DEFAULT_PAGING
};


const searchProductsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISearchProductsState => {

  const { 
    PAYMENTS_SEARCHPRODUCTS 
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_SEARCHPRODUCTS.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_SEARCHPRODUCTS.SUCCESS:
      return {
        ...state,
        products:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.products, ...action.payload.items],
        paging: action.payload.paging,
        loading: false
      };

    case PAYMENTS_SEARCHPRODUCTS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default searchProductsReducer;
