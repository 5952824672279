import React from "react";
import { EmptyMessage, Spacing } from "ui-kit";
import styled from "styled-components";


const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  padding: ${Spacing.ExtraLarge}px;
`;


const Error = () => {
  return (
    <Wrapper>
      <EmptyMessage
        title="Sorry, there seems to be a problem"
        summary="Unfortunately there seems to have been a problem with the payment process. Your card has not been charged. Your basket will be stored and you can checkout at a later date."
        cover
      />
    </Wrapper>
  );
};


export default Error;
