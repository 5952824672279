import React, { useEffect, useState } from "react";
import { GroupListView } from "types/users/userGroups.types";
import { Dropdown } from "ui-kit";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";


interface IYearGroupDropdownProps {
  yearGroups: GroupListView[];
  selectedYearGroup?: number;
  handleSelectYearGroup: (value: number) => void;
}


const YearGroupDropdown: React.FC<IYearGroupDropdownProps> = ({
  yearGroups,
  selectedYearGroup,
  handleSelectYearGroup
}) => {
  
  const [schoolYearGroups, setSchoolYearGroups] = useState<IDropdownDataItemProps<string, number, null>[]>([]);

  useEffect(() => {
    if (yearGroups) {
      const tempYearGroups: IDropdownDataItemProps<string, number, null>[] = yearGroups.map(yearGroup => ({
        key: yearGroup.id,
        label: yearGroup.name,
        value: yearGroup.id
      }));

      setSchoolYearGroups(tempYearGroups);
    }
  }, [yearGroups]);

  return (
    <Dropdown
      className="year-group-dropdown"
      placeholder="Year Group"
      items={schoolYearGroups}
      onChange={value => handleSelectYearGroup(value)}
      value={selectedYearGroup}
      clearable
      fluid
    />
  );
};

export default YearGroupDropdown;
