import React, { useEffect, useState } from "react";
import MissedDetentionsTableRow from "./missedDetentionsTableRow";
import { Table, EmptyMessage, Button, Swatches, Size, Spacing, Icon, honeygold, ToastService } from "ui-kit";
import { DetentionStudentView } from "areas/behaviour/types/detentionRegisterResponse.types";
import { PageInfo } from "types/common/paging.types";
import BulkRescheduleModal from "../bulkRescheduleModal";
import { faSquareCheck } from "@fortawesome/pro-solid-svg-icons";
import { low } from "ui-kit/common/colors";
import { YearGroupFilterExt } from "./missedDetentionsWrapper";
import config from "configuration/config";


interface IMissedDetentionsTableProps {
  filters: YearGroupFilterExt;
  schoolId: number;
  missedDetentions: DetentionStudentView[];
  onClickDetention: (detention: DetentionStudentView) => void;
  rescheduleDetention: (detention: DetentionStudentView) => void;
  handlePage: () => void;
  paging: PageInfo;
  loading: boolean;
  handleExportDetentions: () => void;
  onBulkUpdate: (refresh: boolean) => void;
  loadingExport: boolean;
  error: boolean;
}


const MissedDetentionsTable: React.FC<IMissedDetentionsTableProps> = ({
  filters,
  schoolId,
  missedDetentions,
  onClickDetention,
  rescheduleDetention,
  handlePage,
  paging,
  loading,
  handleExportDetentions,
  onBulkUpdate,
  loadingExport,
  error,
}) => {

  const [selectedDetentions, setSelectedDetentions] = useState<DetentionStudentView[]>([]);
  const [rescheduleModalOpen, setRescheduleModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setSelectedDetentions([]);
  }, [filters])

  const isDetentionSelected = (missedDetention: DetentionStudentView) => {
    return selectedDetentions.some(
      selectedDetention => selectedDetention.detentionId === missedDetention.detentionId
    );
  };

  const handleDetentionCheckBoxChange = (checked: boolean, detention: DetentionStudentView) => {
    if (checked) {
      if (!selectedDetentions.some(x => x.detentionId === detention.detentionId)) {
        setSelectedDetentions([...selectedDetentions, detention]);
      }
    } else {
      var temp = selectedDetentions.filter(x => x.detentionId !== detention.detentionId);
      setSelectedDetentions(temp);
    }
  };

  const handleBulkReschedule = (refresh: boolean) => {
    setSelectedDetentions([]);
    setRescheduleModalOpen(false);
    ToastService.pop("All detentions successfully rescheduled", null, "check-circle");
    onBulkUpdate(refresh);
  }

  const handleBulkRescheduleCancel = (refresh: boolean) => {
    setRescheduleModalOpen(false)
    onBulkUpdate(refresh);
  }

  const selectAll = () => {
    if (selectedDetentions.length < 1) {
      setSelectedDetentions(missedDetentions);
    }
    else {
      setSelectedDetentions([]);
    }
  }

  return (
    <>
    <Table
      grow
      loading={loading}
      error={error}
      empty={missedDetentions?.length === 0}
      emptyMessage={
        <EmptyMessage
          icon="list"
          title="No missed detentions"
          summary="No missed detentions were found for the selected filters"
          cover
        />
      }
    >
      <Table.Header>
        <Table.HeaderCell width={0.25} onSort={selectAll} center>
          <Icon
            value={faSquareCheck}
            color={(selectedDetentions.length < 1) ? low : honeygold}
            size={Size.Large}
            tooltip={(selectedDetentions.length < 1) ? "Select All" : "Deselect All"}
          />
        </Table.HeaderCell>
        <Table.HeaderCell width={2}>Student</Table.HeaderCell>
        <Table.HeaderCell width={1}>Date</Table.HeaderCell>
        <Table.HeaderCell width={1}>Time</Table.HeaderCell>
        <Table.HeaderCell width={1}>Detention Type</Table.HeaderCell>
        <Table.HeaderCell width={1}>Behaviour</Table.HeaderCell>
        <Table.HeaderCell width={2}>Issued By</Table.HeaderCell>
        <Table.HeaderCell width={1} show={filters.academicYearId === config.academicYear.current}></Table.HeaderCell>
      </Table.Header>

      <Table.Body onPage={handlePage} paging={paging}>
        {missedDetentions.map(missedDetention => (
          <MissedDetentionsTableRow
            key={missedDetention.detentionId}
            filters={filters}
            detention={missedDetention}
            onClickDetention={onClickDetention}
            rescheduleDetention={rescheduleDetention}
            selected={isDetentionSelected(missedDetention)}
            handleDetentionCheckBoxChange={handleDetentionCheckBoxChange}
          />
        ))}
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.Cell right>
            {filters.academicYearId === config.academicYear.current && (
              <Button
                color={Swatches.Primary}
                text="Bulk Reschedule"
                icon="calendar"
                onClick={() => setRescheduleModalOpen(true)}
                working={loadingExport}
                size={Size.Small}
                style={{ marginRight: Spacing.Default }}
                disabled={selectedDetentions.length < 1}
              />
            )}
            <Button
              color={Swatches.Success}
              text="Export Missed Detentions"
              icon="file-download"
              onClick={handleExportDetentions}
              working={loadingExport}
              size={Size.Small}
            />
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table>

    <BulkRescheduleModal
      schoolId={schoolId}
      detentions={selectedDetentions}
      open={rescheduleModalOpen}
      onCancel={handleBulkRescheduleCancel}
      onSaved={handleBulkReschedule}
    />

    </>
  );
};


export default MissedDetentionsTable;
