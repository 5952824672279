import {
  Size,
  Loader,
  EmptyMessage,
  Swatches,
  Card,
  Chip,
  ActionPanel,
  ChipList,
} from "ui-kit";
import { useNavigate } from "react-router-dom";
import { arrays, users } from "utils";
import { consentRoutes } from "areas/administration/adminRoutes";
import { ParentView } from "sharedComponents/common/users/userTypeView";
import { ConsentFormUserView } from "areas/administration/types/consentResponses.types";

interface ICompletedConsentFormListProps {
  forms: ConsentFormUserView[];
  loading?: boolean;
  error?: string;
}

const CompletedConsentFormList: React.FC<ICompletedConsentFormListProps> = ({
  forms,
  loading,
  error,
}) => {
  const navigate = useNavigate();

  const getIsActive = (form: ConsentFormUserView) => {
    if (form.users) {
      for (let i = 0; i < form.users.length; i++) {
        const history = form.users[i].history;
        if (history?.length > 0 && history[0].form?.isActive) {
          return true;
        }
      }
      return false;
    }
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (arrays.isEmpty(forms)) {
    return (
      <EmptyMessage
        title="No forms available"
        summary="There are no consent forms for you to complete"
        icon="clipboard-check"
        cover
      />
    );
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem while loading the forms"
        icon="times-circle"
        iconColor={Swatches.Danger.swatch}
        cover
      />
    );
  }

  return (
    <>
      <Card transparent>
        <Card.Body>
          <p>
            The consent forms below have been completed. Depending on the form,
            you may be able to update your consent - to do this click on the{" "}
            <b>Update Consent</b> button and you will be able to amend your
            consent. For any questions about these forms, please contact your
            school.
          </p>
        </Card.Body>
      </Card>

      {forms.map((form, index) => (
        <ActionPanel
          key={index}
          label={form.name}
          sub={form.summary}
          buttonText="Update Consent"
          buttonColor={Swatches.Primary}
          onClick={() => navigate(consentRoutes.getConsentFormPath(form.id))}
          hideButton={!form.allowChange || !getIsActive(form)}
        >
          <ParentView>
            {!arrays.isEmpty(form.users) && (
              <ChipList>
                {form.users.map((u, i) => (
                  <Chip key={i} text={users.getFullName(u.user)} />
                ))}
              </ChipList>
            )}
          </ParentView>
        </ActionPanel>
      ))}
    </>
  );
};

export default CompletedConsentFormList;
