import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Constants } from "configuration";
import ConsentWizard from "./consentWizard";
import { Size, Loader, Title, TitleSize, EmptyMessage, Swatches } from "ui-kit";
import consentActions from "areas/administration/actions/consentActions";
import Subheader from "sharedComponents/layout/header/subheader";
import { RootState } from "reducers/store";


const ConsentForm = () => {

  const { formId } = useParams();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { form, error, loading } = useSelector((state: RootState) => state.userConsentForm);

  useEffect(() => {
    if (formId && user) {
      if (user.userType == Constants.USER_TYPES.PARENT) {
        consentActions.getConsentFormForParent(user.id, parseInt(formId));
      } else {
        consentActions.getConsentFormForUser(user.id, parseInt(formId));
      }
    }
  }, [user, formId]);

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem while loading the form"
        icon="times-circle"
        iconColor={Swatches.Danger.swatch}
      />
    );
  }

  if (!form) {
    return (
      <EmptyMessage
        title="Form Not Found"
        summary="The requested form could not be found or is not available for you to completed"
        icon="clipboard-check"
      />
    );
  }

  return (
    <>
      <Subheader>
        <Title size={TitleSize.H2} text="Consent Forms" sub={form.name} />
      </Subheader>
      <ConsentWizard form={form} />
    </>
  );
};

export default ConsentForm;
