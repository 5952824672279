import paymentActions from "areas/payments/actions/paymentsActions";
import { SmartcardSyncStatus } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { SmartcardProvider } from "areas/payments/types/shopkeeper/shopkeeperShared.types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { SchoolDropdown } from "sharedComponents/common"
import { 
  ActionBar, Dropdown 
} from "ui-kit"


const SmartcardSyncFilter = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { filter } = useSelector((state: RootState) => state.userSmartcardSyncRecords);

  useEffect(() => {
    paymentActions.updateUserSyncRecordsFilter({ ...filter, schoolId: user.baseSchool.id });
  }, []);

  const handleSchoolChange = (schoolId: number) => {
    paymentActions.updateUserSyncRecordsFilter({ ...filter, schoolId: schoolId });
  };

  const handleProviderChange = (providerId: number) => {
    paymentActions.updateUserSyncRecordsFilter({ ...filter, providerId: providerId });
  };
  
  const handleStatusChange = (statusId: number) => {
    paymentActions.updateUserSyncRecordsFilter({ ...filter, statusId: statusId });
  };

  
  return (
    <ActionBar>
      <SchoolDropdown
        fluid
        schools={[...user.schools]}
        includeAllOption={true}
        includeTrust={false}
        initialValue={filter?.schoolId}
        onChange={handleSchoolChange}
      />
      <SmartcardProviderDropdown
        onChange={handleProviderChange}
        initialValue={filter?.providerId}
        includeAllOption
      />
      <SyncStatusDropdown
        onChange={handleStatusChange}
        initialValue={filter?.statusId}
        includeAllOption
      />
    </ActionBar>
  )
}


interface ISmartcardProviderDropdownProps {
  onChange: (providerId: number) => void;
  includeAllOption?: boolean;
  initialValue?: number;
}
interface smartcardProviderOption {
  label: string;
  value: number;
}

const SmartcardProviderDropdown: React.FC<ISmartcardProviderDropdownProps> = ({ onChange, includeAllOption, initialValue }) => {

  const ALL_OPTION: smartcardProviderOption = {
    label: "All Providers",
    value: -1,
  };
  const [selectedValue, setSelectedValue] = useState<number | null>(includeAllOption ? ALL_OPTION.value : null);
  const [options, setOptions] = useState<smartcardProviderOption[]>([]);

  const { user } = useSelector((state: RootState) => state.currentUser);

  useEffect(() => {
    var opts: smartcardProviderOption[] = [];
    for (var provider in SmartcardProvider) {
      if (Number(provider) >= 0) {
        opts.push({
          label: SmartcardProvider[provider], //I hate enums in this language so much SmartcardProvider
          value: Number(provider)
        })
     }
    }
    includeAllOption && opts.unshift(ALL_OPTION);
    setOptions(opts);
  },[user])

  useEffect(() => {
    if (initialValue) {
      setFromOptions(initialValue);
    }
  }, [options]);

  useEffect(() => {
    const option = options?.find(x => x.value === initialValue);
    setSelectedValue(option ? option.value : -1);
  }, [initialValue]);


  const setFromOptions = (value: number) => {
    const option = options?.find((x) => x?.value == value);
    setSelectedValue(option?.value);
  };

  const handleChange = (value: number) => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Dropdown
      placeholder="Event Owner"
      items={options}
      onChange={handleChange}
      value={selectedValue}
    />
  );
};


interface ISyncStatusDropdownProps {
  onChange: (statusId: number) => void;
  includeAllOption?: boolean;
  initialValue?: number;
}
interface syncStatusOption {
  label: string;
  value: number;
}

const SyncStatusDropdown: React.FC<ISyncStatusDropdownProps> = ({ onChange, includeAllOption, initialValue }) => {

  const ALL_OPTION: syncStatusOption = {
    label: "All Statuses",
    value: -1,
  };
  const [selectedValue, setSelectedValue] = useState<number | null>(includeAllOption ? ALL_OPTION.value : null);
  const [options, setOptions] = useState<syncStatusOption[]>([]);

  const { user } = useSelector((state: RootState) => state.currentUser);

  useEffect(() => {
    var opts: syncStatusOption[] = [];
    for (var status in SmartcardSyncStatus) {
      if (Number(status) >= 0) {
        opts.push({
          label: SmartcardSyncStatus[status], //I hate enums in this language so much
          value: Number(status)
        })
     }
    }
    includeAllOption && opts.unshift(ALL_OPTION);
    setOptions(opts);
  },[user])

  useEffect(() => {
    if (initialValue) {
      setFromOptions(initialValue);
    }
  }, [options]);

  useEffect(() => {
    const option = options?.find(x => x.value === initialValue);
    setSelectedValue(option ? option.value : -1);
  }, [initialValue]);


  const setFromOptions = (value: number) => {
    const option = options?.find((x) => x?.value == value);
    setSelectedValue(option?.value);
  };

  const handleChange = (value: number) => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Dropdown
      placeholder="Event Owner"
      items={options}
      onChange={handleChange}
      value={selectedValue}
    />
  );
};


export default SmartcardSyncFilter;