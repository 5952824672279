import { CashlessOrderDetailView, SmartcardTransactionListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import React, { useState } from "react";
import {
  Swatches,
  Table,
  EmptyMessage,
  Icon,
  Currency,
  DateTime,
  Label
} from "ui-kit";
import { arrays } from "utils";
import paymentActions from "areas/payments/actions/paymentsActions";
import { UserTinyView } from "types/users/userListViews.types";
import CashlessSaleModal from "./cashlessSaleModal";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { CashlessPageInfo } from "types/common/paging.types";


interface ITransactionsProps {
  user: UserTinyView;
  transactions: SmartcardTransactionListView[];
  onPage?: () => void;
  paging?: CashlessPageInfo;
  loading?: boolean;
  error?: string;
}


const Transactions: React.FC<ITransactionsProps> = ({ user, transactions, onPage, paging, loading, error }) => {

  const [saleModalOpen, setSaleModalOpen] = useState<boolean>(false);

  const handleClick = (transaction: SmartcardTransactionListView) => {
    if ((transaction.total < 0) && (transaction.orderId)) {
      setSaleModalOpen(true);
      paymentActions.getCashlessOrderDetails(user.id, transaction.orderId);
    }
  }

  return (
    <>
      <Table
        grow
        caption="Transactions"
        empty={arrays.isEmpty(transactions)}
        error={error != null}
        //loading={loading}
        emptyMessage={
          <EmptyMessage
            icon="id-card-alt"
            title="No transactions found"
            summary="There are no transactions for this smartcard"
            cover
          />
        }
      >
        <Table.Header>
          <Table.HeaderCell width={1} />
          <Table.HeaderCell width={3}>Date</Table.HeaderCell>
          <Table.HeaderCell width={4}>Description</Table.HeaderCell>
          <Table.HeaderCell width={2} right>
            Total
          </Table.HeaderCell>
        </Table.Header>
        <Table.Body 
          altPaging={paging}
          onPage={onPage}
        >
          {transactions.map((tran, index) => (
            <Table.Row key={index} onClick={() => handleClick(tran)}>
              <Table.Cell width={1}>
                {tran.total > 0 ? (
                  <Icon
                    value="arrow-circle-right"
                    color={Swatches.Success.swatch}
                  />
                ) : (
                  <Icon
                    value="arrow-circle-left"
                    color={Swatches.Danger.swatch}
                  />
                )}
              </Table.Cell>
              <Table.Cell width={3}>
                <DateTime bold date={tran.date} />
              </Table.Cell>
              <Table.Cell width={4}>
                <Label bold>{tran.description}</Label>
              </Table.Cell>
              <Table.Cell width={2} right>
                { tran.total && (
                  <Currency value={tran.total} highlight />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <CashlessSaleModal 
        open={saleModalOpen}
        onClose={() => setSaleModalOpen(false)}
      />
    </>
  );
};
export default Transactions;
