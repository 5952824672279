import { LeaveRequestType } from "areas/humanResources/types/leaveShared.types";
import React, { useState, useEffect } from "react";
import { Checkbox, Dropdown, StructuredList, Sub } from "ui-kit";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { SubmitLeaveRequestCommandExt } from ".";


interface IUnpaidLeaveSelectorProps {
  request: SubmitLeaveRequestCommandExt;
  setRequest: React.Dispatch<React.SetStateAction<SubmitLeaveRequestCommandExt>>;
}


const UnpaidLeaveSelector: React.FC<IUnpaidLeaveSelectorProps> = ({ request, setRequest }) => {

  const [unpaidOpts, setUnpaidOpts] = useState<IDropdownDataItemProps<number, number, null>[]>([]);

  useEffect(() => {
    if (request) {
      var opts: IDropdownDataItemProps<number, number, null>[] = [];
      for (var i = 0.5; i <= (request.totalHours / 8); i += 0.5) {
        opts.push({ label: i, value: i*8 });
      }
      setUnpaidOpts(opts);
    }
  }, [request])

  const handlePaidChange = (checked: boolean) => {
    setRequest({
      ...request,
      unpaid: checked,
      unpaidHours: checked ? 0.5 : 0,
    })
  }

  return (
    <>
      {request?.leaveRequestType !== LeaveRequestType.GoldenTime && (
        <StructuredList.Item
          name="Request  Unpaid Leave"
          description="You can request some or all of this leave as unpaid."
          required={request?.unpaid}
        >
          <Checkbox
            checked={request?.unpaid}
            onChange={val => handlePaidChange(val.checked)}
            text="Request Unpaid Leave"
          />
          {request?.unpaid && (
            <>
              <Dropdown
                value={request?.unpaidHours}
                onChange={value => setRequest({ ...request, unpaidHours: value })}
                items={unpaidOpts}
                fluid
              />
              <br />
              <Sub>
                Please choose how many days should be unpaid. For
                half days, please enter as .5 (e.g 4.5)
              </Sub>
            </>
          )}
        </StructuredList.Item>
      )}
    </>
  );
}


export default UnpaidLeaveSelector;