import paymentActions from "areas/payments/actions/paymentsActions";
import { ReconciliationDetailView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


interface IReconciliationState {
  loading: boolean;
  error: string | null;
  saving: boolean;
  reconciliation: ReconciliationDetailView;
  saveError: string | null;
}

const INITIAL_STATE: IReconciliationState = {
  loading: false,
  error: null,
  saving: false,
  reconciliation: null,
  saveError: null
};

const reconciliationReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IReconciliationState => {

  const {
    PAYMENTS_GETRECONCILIATION,
    PAYMENTS_CREATERECONCILIATION,
    PAYMENTS_SAVERECONCILIATION
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETRECONCILIATION.START:
      return { 
        ...state, 
        loading: true, 
        saveError: null, 
        error: null 
      };

    case PAYMENTS_GETRECONCILIATION.SUCCESS:
      return { 
        ...state, 
        reconciliation: action.payload, 
        loading: false 
      };

    case PAYMENTS_GETRECONCILIATION.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PAYMENTS_CREATERECONCILIATION.START:
    case PAYMENTS_SAVERECONCILIATION.START:
      return { 
        ...state, 
        saving: true, 
        saveError: null, 
        error: null 
      };

    case PAYMENTS_CREATERECONCILIATION.SUCCESS:
      return {
        ...state,
        saving: false,
        reconciliation: action.payload
      };

    case PAYMENTS_SAVERECONCILIATION.SUCCESS:
      return {
        ...state,
        saving: false
      };

    case PAYMENTS_CREATERECONCILIATION.FAILED:
      return {
        ...state,
        saving: false,
        error: "There was a problem creating the reconciliation"
      };

    case PAYMENTS_SAVERECONCILIATION.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the reconciliation"
      };

    default:
      return state;
  }
};

export default reconciliationReducer;
