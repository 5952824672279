import React, { useEffect, useState } from "react";
import { Dropdown } from "ui-kit";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import { useAppSelector } from "reducers/hooks";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";


interface IBehaviourCodeDropdownProps {
  schoolId: number;
  selected?: number;
  onChange: (value: number) => void;
}


const BehaviourCodeDropdown: React.FC<IBehaviourCodeDropdownProps> = ({ schoolId, selected, onChange }) => {

  const { behaviourCodes } = useAppSelector(state => state.behaviourCodes);
  const [behaviourCodesArray, setBehaviourCodesArray] = useState<IDropdownDataItemProps<string, number, null>[]>([]);

  useEffect(() => {
    if (schoolId) {
      behaviourActions.getBehaviourCodes(schoolId, { isLegacy: false, canBeAwarded: null });
    }
  }, [schoolId]);

  useEffect(() => {
    if (!arrays.isEmpty(behaviourCodes)) {
      const tempBehaviourCodes: IDropdownDataItemProps<string, number, null>[] = behaviourCodes.map(behaviourCode => ({
        key: behaviourCode.id,
        label: behaviourCode.codeName,
        value: behaviourCode.id,
      }));
      setBehaviourCodesArray(tempBehaviourCodes);
    }
  }, [behaviourCodes]);

  return (
    <Dropdown
      items={behaviourCodesArray}
      placeholder="Behaviour Code"
      value={selected}
      onChange={onChange}
      clearable
      fluid
    />
  );
};

export default BehaviourCodeDropdown;
