import React, { useEffect, useState } from "react";
import { Table, DateTime, Icon, Swatches, EmptyMessage, Sub } from "ui-kit";
import { arrays } from "utils";
import QueueMessageModal from "./queueMessageModal";
import DeliveryStatus from "./deliveryStatus";
import { managedUserAccount } from "areas/administration/reducers/userManagement/managedUserReducer";
import { QueueMessageUserListView } from "../types/mailoutResponse.types";
import { PageInfo } from "types/common/paging.types";
import { NotificationPriority } from "../types/mailoutShared.types";

interface IQueueMessageTableProps {
  user: managedUserAccount;
  messages: QueueMessageUserListView[];
  loading: boolean;
  error: string;
  paging: PageInfo;
  onPage: () => void;
  onRowClick: (message: QueueMessageUserListView) => void;
}

const QueueMessageTable: React.FC<IQueueMessageTableProps> = ({
  user,
  messages,
  loading,
  error,
  paging,
  onPage,
  onRowClick,
}) => {

  const [selectedMessage, setSelectedMessage] = useState<QueueMessageUserListView | null>(null);

  const getPriorityIcon = (priority: NotificationPriority) => {
    switch (priority) {
      case 2:
        return <Icon value="exclamation" color={Swatches.Danger.swatch} />;
      case 1:
        return <Icon value="arrow-down" color={Swatches.Blue.swatch} />;
      default:
        return null;
    }
  };

  const handlePage = () => {
    onPage?.();
  };

  const handleRowClick = (message: QueueMessageUserListView) => {
    onRowClick?.(message);
    setSelectedMessage(message);
  };

  const handleQueueMessageModalClosed = () => {
    setSelectedMessage(null);
  };

  useEffect(() => {}, [selectedMessage]);

  return (
    <>
      <Table
        grow
        zebra
        loading={loading}
        error={error != null}
        empty={arrays.isEmpty(messages)}
        emptyMessage={
          <EmptyMessage
            icon="envelope"
            title="No Messages"
            summary="No messages have been sent to this user yet."
            cover
          />
        }
      >
        <Table.Header>
          <Table.HeaderCell width={0.3}></Table.HeaderCell>
          <Table.HeaderCell width={0.3}></Table.HeaderCell>
          <Table.HeaderCell width={1}>Date</Table.HeaderCell>
          <Table.HeaderCell width={6.4}>Subject</Table.HeaderCell>
          <Table.HeaderCell width={1}>Status</Table.HeaderCell>
          <Table.HeaderCell width={1}>Sent On</Table.HeaderCell>
        </Table.Header>
        <Table.Body onPage={handlePage} paging={paging}>
          {messages?.map((message: QueueMessageUserListView, index: number) => (
            <Table.Row key={index} onClick={() => handleRowClick(message)}>
              <Table.Cell width={0.3}>
                {message.readOn !== null ? (
                  <Icon value="envelope-open" color={Swatches.Low.swatch} />
                ) : (
                  <Icon value="envelope" color={Swatches.Blue.swatch} />
                )}
              </Table.Cell>
              <Table.Cell width={0.3}>
                {getPriorityIcon(message.delivery.priority)}
              </Table.Cell>
              <Table.Cell width={1}>
                <DateTime date={message.queuedOn} bold />
              </Table.Cell>
              <Table.Cell width={6.4}>
                {message.messageType === 0 ? (
                  <span
                    style={{
                      fontWeight: message.readOn ? "normal" : "bold",
                    }}
                  >
                    {message.subject}
                  </span>
                ) : (
                  <Sub>{message.subject}</Sub>
                )}
              </Table.Cell>
              <Table.Cell width={1}>
                <DeliveryStatus status={message.deliveryRecord?.status} />
              </Table.Cell>
              <Table.Cell width={1}>
                {message.deliveryRecord?.deliveredOn && (
                  <DateTime date={message.deliveryRecord?.deliveredOn} bold />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <QueueMessageModal
        user={user}
        message={selectedMessage}
        onClose={handleQueueMessageModalClosed}
        open={selectedMessage != null}
      />
    </>
  );
};

export default QueueMessageTable;
