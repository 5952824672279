import React, { useEffect, useRef } from "react";
import { Loader, Size, EmptyMessage } from "ui-kit";
import moment from "moment";
import ClassBehaviourReportForm from "./classBehaviourReportForm";
import ClassBehaviourTable from "./classBehaviourTable";
import ClassBehaviourCharts from "./classBehaviourCharts";
import { useParams } from "react-router";
import classBehaviourReportActions from "areas/analytics/actions/classBehaviourReportActions";
import { ApiExceptionMessage } from "../../../../sharedComponents/common";
import Config from "configuration";
import { useAppSelector } from "reducers/hooks";
import { Gender } from "types/users/userDetail.types";


const ClassBehaviourWrapper: React.FC = () => {

  const reportRef = useRef<HTMLDivElement>();
  const { groupId } = useParams();

  const { report, loading, error } = useAppSelector(
    state => state.classBehaviourReport
  );

  useEffect(() => {
    if (loading || report) {
      reportRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading, report]);

  const handleGenerateReport = (startDate?: string, endDate?: string, gender?: Gender, SEN?: boolean, EAL?: boolean, PP?: boolean) => {
    classBehaviourReportActions.getClassBehaviourReport(
      parseInt(groupId),
      {
        startDate: moment(startDate, ["DD/MM/YYYY"]).format("YYYY-MM-DD"),
        endDate: moment(endDate, ["DD/MM/YYYY"]).format("YYYY-MM-DD"),
        gender: gender,
        sen: SEN,
        eal: EAL,
        pp: PP,
        academicYearId: Config.academicYear.current
      }
    );
  };

  return (
    <>
      <ClassBehaviourReportForm handleGenerateReport={handleGenerateReport} />
      <div ref={reportRef}>
        {loading ? (
          <Loader size={Size.Large} fluid />
        ) : error ? (
          <ApiExceptionMessage error={error} />
        ) : (
          report &&
          (report.totalBehaviourPoints + report.totalRewardPoints === 0 ? (
            <EmptyMessage
              title="No rewards or behaviours in the selected time period"
              icon="list"
            />
          ) : (
            <>
              <ClassBehaviourCharts classBehaviourData={report} />
              <ClassBehaviourTable report={report} />
            </>
          ))
        )}
      </div>
    </>
  );
};

export default ClassBehaviourWrapper;
