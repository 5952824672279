import { IBeehiveAction } from "types/common/action.types";
import globalErrorActions from "../../actions/globalErrorActions";


interface IGlobalErrorState {
  message: string | null;
}

const INITIAL_STATE: IGlobalErrorState = {
  message: null, //"An error occured."
};


const globalErrorReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IGlobalErrorState => {

  const { GLOBALERROR_DISMISS } = globalErrorActions.types;

  switch (action.type) {
    case GLOBALERROR_DISMISS:
      return { ...state, message: null };
    default:
      return state;
  }
};

export default globalErrorReducer;
