import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, Subtitle, Table } from "ui-kit";
import {
  formatTime,
  formatDate,
  getDurationInHoursAndMins,
} from "utils/dateTime";
import ReadOnlyDataList, { DetentionDataType } from "./readOnlyDataList";
import DetentionStatusChip from "areas/behaviour/components/detentions/detentionStatusChip";
import Avatar from "sharedComponents/common/users/avatar";
import { DetentionView } from "areas/behaviour/types/behaviourResponses.types";


interface IReadOnlyDetentionDetailsProps {
  detentions: DetentionView[];
}


const ReadOnlyDetentionDetails: React.FC<IReadOnlyDetentionDetailsProps> = ({ detentions }) => {

  const [data, setData] = useState<DetentionDataType[]>([]);

  useEffect(() => {
    if (detentions?.length === 1) {
      const detention = detentions[0];

      let detentionData = [
        {
          dataType: "Detention Status",
          dataValue: (
            <DetentionStatusChip
              detentionStatus={detention.statusId}
              issuedText="Issued"
              cancelledText="Cancelled"
            />
          ),
        },
        {
          dataType: "Type",
          dataValue: detention.detentionTypeName,
        },
        {
          dataType: "Location",
          dataValue: detention.roomName,
        },
        {
          dataType: "Date",
          dataValue: formatDate(detention.detentionInformation.startDate),
        },
        {
          dataType: "Time",
          dataValue: formatTime(detention.detentionInformation.startDate),
        },
        {
          dataType: "Duration",
          dataValue: getDurationInHoursAndMins(
            detention.detentionInformation.duration
          ),
        },
      ];

      if (detention.detentionNote) {
        detentionData.push({
          dataType: "Detention Notes",
          dataValue: detention.detentionNote,
        });
      }

      if (detention.cancelledBy) {
        detentionData.push({
          dataType: "Cancelled By",
          dataValue: (
            <Avatar
              user={detention.cancelledBy}
              sub={`${formatDate(detention.cancelledDate)} ${formatTime(
                detention.cancelledDate
              )}`}
            />
          ),
        });

        detentionData.push({
          dataType: "Cancellation Notes",
          dataValue: detention.cancelNote,
        });
      }

      setData(detentionData);
    }
  }, [detentions]);

  return detentions?.length === 1 ? (
    <Card title="Sanction Details">
      <Card.Body>
        <ReadOnlyDataList data={data} />
      </Card.Body>
    </Card>
  ) : (
    <>
      <Subtitle text="Sanctions" />
      <Table>
        <Table.Header>
          <Table.HeaderCell width={0.5}>Status</Table.HeaderCell>
          <Table.HeaderCell width={0.5}>Date</Table.HeaderCell>
          <Table.HeaderCell width={0.5}>Time</Table.HeaderCell>
          <Table.HeaderCell width={1}>Duration</Table.HeaderCell>
          <Table.HeaderCell width={1}>Sanction</Table.HeaderCell>
          <Table.HeaderCell width={1}>Location</Table.HeaderCell>
        </Table.Header>

        <Table.Body>
          {detentions.map(detention => (
            <Table.Row key={detention.detentionId}>
              <Table.Cell>
                <DetentionStatusChip
                  detentionStatus={detention.statusId}
                  issuedText="Issued"
                  cancelledText="Cancelled"
                />
              </Table.Cell>
              <Table.Cell>
                <b>{formatDate(detention.detentionInformation.startDate)}</b>
              </Table.Cell>
              <Table.Cell>
                {formatTime(detention.detentionInformation.startDate)}
              </Table.Cell>
              <Table.Cell>
                {getDurationInHoursAndMins(
                  detention.detentionInformation.duration
                )}
              </Table.Cell>
              <Table.Cell>{detention.detentionTypeName}</Table.Cell>
              <Table.Cell>{detention.roomName}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};


export default ReadOnlyDetentionDetails;
