import React, { useState } from "react";
import { RichTextEditor, Card } from "ui-kit";
import { getTileName } from "../../utils";
import { PassportTileView } from "areas/send/types/passportResponse.types";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";


interface ITileEditorProps {
  tile: PassportTileView;
  student: PlannerStudentDetailView;
  onChange?: (value: string) => void;
}


const TileEditor: React.FC<ITileEditorProps> = ({ tile, student, onChange }) => {

  const [_content, _setContent] = useState<string | null>(null);

  const handleChange = (value: string) => {
    _setContent(value);
    onChange?.(value);
  };

  return (
    <Card title={getTileName(tile, student)}>
      <Card.Body noPad>
        <RichTextEditor
          noPad
          initialValue={tile.content}
          onChange={handleChange}
        />
      </Card.Body>
    </Card>
  );
};

export default TileEditor;
