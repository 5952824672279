import React, { useState } from "react";
import { arrays } from "utils";
import { Size, EmptyMessage, Table, DateTime, DetailLabel } from "ui-kit";
import { SchoolIcon, UserName } from "sharedComponents/common";
import SubmissionStatus from "../submissionStatus";
import SubmissionModal from "../submissions/submissionModal";
import { Constants } from "configuration";
import { SubmissionListView } from "areas/forms/types/formResponse.types";
import { PageInfo } from "types/common/paging.types";
import { ApprovalStatus } from "areas/humanResources/types/leaveShared.types";


interface ISubmissionsTableProps {
  items: SubmissionListView[];
  onRowClick?: () => void;
  loading?: boolean;
  error?: string;
  onPage?: () => void;
  paging?: PageInfo;
}


const SubmissionsTable: React.FC<ISubmissionsTableProps> = ({
  items,
  onRowClick,
  loading,
  error,
  onPage,
  paging,
}) => {

  const [selectedSubmission, setSelectedSubmission] = useState<SubmissionListView | null>(null);

  const handleRowClick = (row: SubmissionListView) => {
    setSelectedSubmission(row);
  };

  const handlePage = () => {
    onPage?.();
  };

  const handleSubmissionModalClose = () => {
    setSelectedSubmission(null);
  };

  return (
    <>
      <Table
        zebra
        loading={loading}
        empty={arrays.isEmpty(items)}
        emptyMessage={
          <EmptyMessage
            icon="paste"
            title="No Submissions"
            summary="No form submissions were found"
            cover
          />
        }
        error={error != null}
        grow
      >
        <Table.Header>
          <Table.HeaderCell width={0.3}></Table.HeaderCell>
          <Table.HeaderCell width={1.5}>Submitted By</Table.HeaderCell>
          <Table.HeaderCell width={1.2}>Form</Table.HeaderCell>
          <Table.HeaderCell width={0.8}>Date</Table.HeaderCell>
          <Table.HeaderCell width={0.5}>No.</Table.HeaderCell>
          <Table.HeaderCell width={1.5}>Ref.</Table.HeaderCell>
          <Table.HeaderCell width={1}>Authoriser</Table.HeaderCell>
          <Table.HeaderCell width={0.8}>Auth. Date</Table.HeaderCell>
          <Table.HeaderCell width={0.5} center right>
            Status
          </Table.HeaderCell>
          <Table.HeaderCell width={0.3} right />
        </Table.Header>
        <Table.Body onPage={handlePage} paging={paging}>
          {items?.map((item: SubmissionListView, index: number) => (
            <Table.Row key={index} onClick={() => handleRowClick(item)}>
              <Table.Cell width={0.3} center>
                <SchoolIcon code={item.school.code} />
              </Table.Cell>

              <Table.Cell width={1.5}>
                <DetailLabel
                  bold
                  label={
                    item.submittedBy.firstName + " " + item.submittedBy.lastName
                  }
                  sub={item.department?.name}
                />
              </Table.Cell>

              <Table.Cell width={1.2}>
                <DetailLabel label={item.name} sub={item.categoryName} bold />
              </Table.Cell>

              <Table.Cell width={0.8}>
                <DateTime date={item.createdDate} bold />
              </Table.Cell>
              <Table.Cell width={0.5}>{item.id}</Table.Cell>
              <Table.Cell width={1.5} >
                <DetailLabel
                  label={item.ref?.value?.slice(0, 15)}
                  sub={item.additionalFields.map(field => field.value)}
                  bold
                  style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
                />
              </Table.Cell>
              <Table.Cell width={1}>
                {item.authoriser && (
                  <DetailLabel label={<UserName user={item.authoriser} />} />
                )}
              </Table.Cell>
              <Table.Cell width={0.8}>
                <DateTime date={item.authorisedDate} bold />
              </Table.Cell>
              <Table.Cell center width={0.5}>
                <SubmissionStatus status={item.latestHistory.status} />
              </Table.Cell>
              <Table.Cell right width={0.3}>
                {item.assignedTo?.initials}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <SubmissionModal
        submission={selectedSubmission}
        onClose={handleSubmissionModalClose}
        open={
          selectedSubmission != null &&
          selectedSubmission.currentStatus !== ApprovalStatus.Draft
        }
      />
    </>
  );
};

export default SubmissionsTable;
