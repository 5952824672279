import { useSelector } from "react-redux";
import { useAction } from "hooks";
import styled from "styled-components";
import {
  neutral,
  typescale,
  Swatches,
  Size,
  displayFont,
  Button,
  CircleIcon,
  Spacing,
  BorderRadius,
  fontStyle,
} from "ui-kit";
import flyoutActions from "actions/ui/flyouts";
import storeActions from "areas/payments/actions/storeActions";
import { Constants } from "configuration";
import { RootState } from "reducers/store";
import { AddLunchCreditToBasketCommand } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
  background: ${neutral[100]};
  margin-right: ${Spacing.Large}px;
  margin-bottom: ${Spacing.Large}px;
  padding: ${Spacing.Large}px;
  border-radius: ${BorderRadius.Default}px;

  &:last-child {
    margin-right: 0;
  }

  .circle-icon {
    margin-bottom: 4px;
    color: ${neutral[300]};
  }
  .total {
    ${fontStyle(displayFont.extraLight, typescale.header2, neutral[700])}
  }
  .caption {
    ${fontStyle(displayFont.bold, typescale.paragraph, neutral[700])}
  }
`;


interface IDinnerMoneyOptionProps {
  onAddToBasket?: (value: number) => void; 
  value: number;
  recipientId: string;
  buttonText?: string;
  caption?: string;
}


const DinnerMoneyOption: React.FC<IDinnerMoneyOptionProps> = ({ onAddToBasket, value, recipientId, buttonText, caption }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { working } = useSelector((state: RootState)  => state.basket);

  const openBasket = () => {
    flyoutActions.openFlyout(Constants.FLYOUTS.BASKET);
  };

  const handleAddToBasket = () => {
    const data: AddLunchCreditToBasketCommand = { 
      userId: user.id, 
      recipientId: recipientId, 
      quantity: value 
    };
    storeActions.addLunchCreditToBasket(user.id, data, () => {
      onAddToBasket?.(value);
      openBasket();
    });
  };
  return (
    <Wrapper className="topup">
      <CircleIcon value="utensils" outline color={Swatches.Low.swatch} />
      <span className="total">{value}</span>
      <span className="caption">{caption ? caption : "School Lunches"}</span>
      <Button
        text={buttonText ? buttonText : `Buy ${value} School Lunches`}
        size={Size.Small}
        onClick={handleAddToBasket}
        color={Swatches.Primary}
        working={working}
      />
    </Wrapper>
  );
};
export default DinnerMoneyOption;
