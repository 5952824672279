import React, { useState } from "react";
import StudentAnswerTypes from "./studentAnswerTypes";
import {
  Swatches,
  StructuredList,
  Button,
  TextInput,
  TextInputType,
  ActionBar,
  Card,
  Right,
  Size,
} from "ui-kit";
import { StudentAnswerListView } from "areas/planner/types/quiz/quizResponse.types";
import { AnswerType } from "areas/planner/types/quiz/quizShared.types";


interface IQuizFormProps {
  studentAnswers: StudentAnswerListView[];
  handleSaveAnswer: (answerType: AnswerType, comment: string, question: string, pickAnotherStudent?: boolean) => void;
  handleSaveAndPickNext?: (answerType: AnswerType, comment: string, question: string) => void;
}


const QuizForm: React.FC<IQuizFormProps> = ({
  studentAnswers,
  handleSaveAnswer,
  handleSaveAndPickNext,
}) => {

  const [comment, setComment] = useState<string>("");
  const [question, setQuestion] = useState<string>("");
  const [selectedAnswerType, setSelectedAnswerType] = useState<AnswerType | null>(null);

  return (
    <>
      <Card>
        <Card.Body>
          <StudentAnswerTypes
            selectedAnswerType={selectedAnswerType}
            setSelectedAnswerType={answerType => setSelectedAnswerType(answerType)}
            studentAnswers={studentAnswers}
          />
          <StructuredList>
            <StructuredList.Item name="Quiz Question">
              <TextInput
                placeholder="Quiz Question"
                type={TextInputType.Textarea}
                rows={3}
                fluid
                value={question}
                onChange={value => setQuestion(value)}
              />
            </StructuredList.Item>

            <StructuredList.Item name="Comments">
              <TextInput
                placeholder="Comments"
                type={TextInputType.Textarea}
                rows={3}
                fluid
                value={comment}
                onChange={value => setComment(value)}
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      <ActionBar low>
        <Right>
          <Button
            className="save-student-answer-button"
            disabled={selectedAnswerType === null}
            color={Swatches.Success}
            text="Save"
            size={Size.Small}
            onClick={() =>
              handleSaveAnswer(selectedAnswerType, comment, question)
            }
          />

          {handleSaveAndPickNext && (
            <Button
              className="save-and-ask-next-button"
              disabled={selectedAnswerType === null}
              color={Swatches.Success}
              text="Save &amp; Ask Next"
              size={Size.Small}
              onClick={() =>
                handleSaveAndPickNext(selectedAnswerType, comment, question)
              }
            />
          )}
        </Right>
      </ActionBar>
    </>
  );
};

export default QuizForm;
