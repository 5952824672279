import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { FormCategoryListView } from "../types/formResponse.types";

const builder = new actionTypeBuilder("formcategories");

const types = {
  GETCATEGORIES: builder.build("getcategories"),
};


const getCategories = (callback?: callbackType<FormCategoryListView[]>) =>
  action<FormCategoryListView[]>(
    () => client.get(`forms/categories`), 
    types.GETCATEGORIES, 
    callback
  );


const formCategoriesActions = {
  types,
  getCategories,
};

export default formCategoriesActions;
