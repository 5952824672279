import React from "react";
import { 
  Chip, 
  Swatches 
} from "ui-kit";
import { Constants } from "configuration";

interface IOrderStatusProps {
  status: number;
}

const OrderStatus: React.FC<IOrderStatusProps> = ({ status }) => {
  var swatch = null;
  var name = "";

  switch (status) {
    case Constants.ORDER_STATUS.OPEN.value:
      swatch = Swatches.Blue;
      name = Constants.ORDER_STATUS.OPEN.name;
      break;
    case Constants.ORDER_STATUS.COMPLETE.value:
      swatch = Swatches.Success;
      name = Constants.ORDER_STATUS.COMPLETE.name;
      break;
    case Constants.ORDER_STATUS.CANCELLED.value:
      swatch = Swatches.Cancel;
      name = Constants.ORDER_STATUS.CANCELLED.name;
      break;
    case Constants.ORDER_STATUS.VOID.value:
      swatch = Swatches.Low;
      name = Constants.ORDER_STATUS.VOID.name;
      break;
    default:
      return null;
  }

  return <Chip text={name} colorSwatch={swatch} />;
};

export default OrderStatus;
