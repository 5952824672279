import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { AssignmentFilterType } from "areas/planner/constants/assignments/assignmentsFilters";
import { AssignmentGrouping, AssingmentGroupingFilter } from "areas/planner/reducers/assignments/assignmentsReducer";
import { StaffAssignmentsFilter } from "areas/planner/types/assignments/assignmentRequest.types";
import { AssignmentTinyView } from "areas/planner/types/assignments/assignmentResponse.types";
import { AssignmentCreator, AssignmentQueryFilterType } from "areas/planner/types/assignments/assignmentShared.types";
import store from "reducers/store";
import client from "services/client";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { PagedQueryView } from "types/common/views.types";
import { urls } from "utils";

const builder = new actionTypeBuilder("assignments");

const types = {
  GETASSIGNMENTS: builder.build("getassignments"),
  GETSUBJECTASSIGNMENTS: builder.build("getsubjectassignments"),
  FILTERASSIGNMENTS: "filterassignments",
  GROUPASSIGNMENTS: "groupassignments",
  REFRESH: "refreshassignments",
  SET_WIDTH: "set_width",
};


const getAssignments = (
  userId: string, 
  userType: string, 
  filter: AssignmentQueryFilterType, 
  pageIndex: number, 
  callback?: callbackType<PagedQueryView<AssignmentTinyView>>
) => {

  switch (userType) {
    case "Staff":
      return getAssignmentsForStaff(
        userId, 
        { assignmentStatus: filter, setBy: AssignmentCreator.All }, 
        pageIndex, 
        callback
      );
    default:
      return getAssignmentsForStudents(
        userId,
        filter,
        pageIndex
      );
  }
};


const getAssignmentsForStudents = (
  userId: string, 
  filter: AssignmentQueryFilterType, 
  pageIndex: number, 
  callback?: callbackType<PagedQueryView<AssignmentTinyView>>
) => {
  var url = new urls.QueryString(`planner/students/${userId}/assignmentstiny`);
  url.addParam("filter", filter);
  url.addParam("pageSize", 11);
  url.addPaging(pageIndex);

  return action<PagedQueryView<AssignmentTinyView>>(
    () => client.get(url.toUrl()), 
    types.GETASSIGNMENTS, 
    callback
  );
}


const getAssignmentsForStaff = (
  userId: string, 
  filter: StaffAssignmentsFilter, 
  pageIndex: number, 
  callback?: callbackType<PagedQueryView<AssignmentTinyView>>
) => {
  console.log(filter);
  var url = new urls.QueryString(`planner/staff/${userId}/assignmentstiny`);
  url.addParams(filter);
  url.addParam("pageSize", 15);
  url.addPaging(pageIndex);
  
  return action<PagedQueryView<AssignmentTinyView>>(
    () => client.get(url.toUrl()), 
    types.GETASSIGNMENTS, 
    callback
  );
}


const getAssignmentsForSubject = (
  subjectId: number, 
  filter: StaffAssignmentsFilter, 
  pageIndex: number, 
  callback?: callbackType<PagedQueryView<AssignmentTinyView>>
) => {
  var url = new urls.QueryString(`planner/subject/${subjectId}/assignmentstiny`);
  url.addParams(filter);
  url.addParam("pageSize", 11);
  url.addPaging(pageIndex);
  
  return action<PagedQueryView<AssignmentTinyView>>(
    () => client.get(url.toUrl()), 
    types.GETSUBJECTASSIGNMENTS, 
    callback
  );
}


const filterAssignments = (filter: AssignmentFilterType) => {
  store.dispatch({
    type: types.FILTERASSIGNMENTS,
    payload: filter,
  });
};


const groupAssignments = (grouping: AssingmentGroupingFilter) => {
  store.dispatch({
    type: types.GROUPASSIGNMENTS,
    payload: grouping,
  });
};


const setWidth = (width: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.SET_WIDTH,
    payload: width,
  });
};


const assignmentsActions = {
  types,
  getAssignments,
  getAssignmentsForStaff,
  getAssignmentsForStudents,
  getAssignmentsForSubject,
  filterAssignments,
  groupAssignments,
  setWidth,
};


export default assignmentsActions;
