import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { SaveProvisionCostBandCommand } from "areas/send/types/passportRequest.types";
import { ProvisionCostBandListView } from "areas/send/types/passportResponse.types";
import client from "services/client";
import { callbackType } from "types/common/action.types";

const builder = new actionTypeBuilder("send");

const types = {
  PROVISIONCOSTBANDS_GET: builder.build("provisioncostbands_get"),
  PROVISIONCOSTBANDS_CREATE: builder.build("provisioncostbands_create"),
  PROVISIONCOSTBANDS_SAVE: builder.build("provisioncostbands_save"),
  PROVISIONCOSTBANDS_DELETE: builder.build("provisioncostbands_delete"),
};


const getProvisionCostBands = (callback?: callbackType<ProvisionCostBandListView[]>) =>
  action<ProvisionCostBandListView[]>(
    () => client.get(`planner/send/bands`),
    types.PROVISIONCOSTBANDS_GET,
    callback
  );

const createProvisionCostBand = (data: SaveProvisionCostBandCommand, callback?: callbackType<ProvisionCostBandListView>) =>
  action<ProvisionCostBandListView>(
    () => client.post(`planner/send/bands`, data),
    types.PROVISIONCOSTBANDS_CREATE,
    callback
  );

const saveProvisionCostBand = (data: SaveProvisionCostBandCommand, callback?: callbackType<ProvisionCostBandListView>) =>
  action<ProvisionCostBandListView>(
    () => client.post(`planner/send/bands/${data.id}`, data),
    types.PROVISIONCOSTBANDS_SAVE,
    callback
  );

const deleteProvisionCostBand = (id: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.delete(`planner/send/bands/${id}`),
    types.PROVISIONCOSTBANDS_DELETE,
    callback
  );

  
const provisionCostBandsActions = {
  types,
  getProvisionCostBands,
  createProvisionCostBand,
  saveProvisionCostBand,
  deleteProvisionCostBand,
};

export default provisionCostBandsActions;
