import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import { arrays } from "utils";
import {
  Table,
  SplitButton,
  Loader,
  Size,
  EmptyMessage,
  Button,
  Swatches,
  DetailLabel,
  Message,
  ToastService,
} from "ui-kit";
import ProvisionGroupEditModal from "./provisionGroupEditModal";
import ProvisionGroupStatus from "./provisionGroupStatus";
import { useNavigate } from "react-router";
import { getHalfTerm } from "areas/send/utils";
import { PROVISION_GROUP_ACTION } from "areas/send/constants";
import flyoutActions from "actions/ui/flyouts";
import provisionGroupsActions from "areas/send/actions/provisions/provisionGroupsActions";
import { sendRoutes } from "areas/send/sendRoutes";
import { ProvisionGroupDetailView, ProvisionGroupListView, StandardProvisionDetailView } from "areas/send/types/passportResponse.types";
import { RootState } from "reducers/store";
import { ProvisionGroupStatus as PGS } from "../../../../types/passportShared.types"
import Moment from "react-moment";


interface IProvisionGroupsTableProps {
  provision: StandardProvisionDetailView; 
  loading?: boolean;
  error?: string;
}

export interface provisionGroup extends ProvisionGroupListView {
  action?: PROVISION_GROUP_ACTION;
}

const DEFAULT_ARRANGEMENT: ProvisionGroupListView = {
  id: null,
  name: "",
  code: "",
  provision: null,
  startDate: null,
  endDate: null,
  yearGroup: null,
  subject: null,
  status: null,
  statusName: "",
  academicYear: null
};


const ProvisionGroupsTable: React.FC<IProvisionGroupsTableProps> = ({ provision, loading, error }) => {

  const navigate = useNavigate();
  const { deleteError, saving, deleting } = useSelector((state: RootState) => state.provisionGroup);
  const [_selectedProvisionGroup, _setSelectedProvisionGroup] = useState<provisionGroup | null>(null);
  const [_provision, _setProvision] = useState<StandardProvisionDetailView | null>(null);

  useEffect(() => {
    // console.log("SETTING PROVISION");
    provision && _setProvision(provision);
  }, [provision]);

  const handleAdd = () => {
    _setSelectedProvisionGroup(DEFAULT_ARRANGEMENT);
    flyoutActions.openFlyout(Constants.FLYOUTS.SEND_ACCESSARRANGEMENT);
  };

  const handleEdit = (group: ProvisionGroupListView) => {
    _setSelectedProvisionGroup(group);
    flyoutActions.openFlyout(Constants.FLYOUTS.SEND_ACCESSARRANGEMENT);
  };

  const changeStatus = (group: ProvisionGroupListView) => {
    provisionGroupsActions.changeProvisionGroupStatus(
      provision.provider.id,
      provision.id,
      group.id,
      group.status == PGS.Draft ? PGS.Live : PGS.Draft,
      () => {
      ToastService.pop(
        "Status Updated",
        "Provision Group Status Changed",
        "hand-holding-seedling"
      );
    });
  }

  const handleClone = (group: ProvisionGroupListView) => {
    _setSelectedProvisionGroup({
      ...group,
      code: null,
      name: group.name + " (copy)",
      action: PROVISION_GROUP_ACTION.CLONE,
      status: 0,
      statusName: "Draft",
    });
  };

  const handleDelete = (group: ProvisionGroupListView) => {
    // console.log(group.id);
    if (
      window.confirm(
        "This will delete the selected group. This cannot be undone. Are you sure?"
      )
    ) {
      provisionGroupsActions.deleteProvisionGroup(
        provision.provider.id,
        provision.id,
        group.id,
        () => {
          _setProvision({
            ..._provision,
            groups: _provision.groups.filter(g => g.id !== group.id),
          });
        }
      );
    }
  };

  const handleSaved = (group: ProvisionGroupDetailView) => {
    _setSelectedProvisionGroup(null);
  };

  const handleViewDetails = (group: ProvisionGroupListView) => {
    navigate(
      sendRoutes.getProvisionGroupPath(
        provision.provider.id,
        provision.id,
        group.id
      )
    );
  };

  if (loading) {
    return <Loader cover size={Size.Medium} />;
  }

  if (error) {
    return <EmptyMessage icon="times-circle" title="An error occurred" cover />;
  }

  return (
    <>
      {arrays.isEmpty(_provision?.groups) ? (
        <EmptyMessage
          icon="hand-holding-seedling"
          title="No Provision Groups"
          summary="No groups have been set up yet"
          cover
        >
          <Button
            size={Size.Small}
            text="Add New Provision Group"
            onClick={handleAdd}
            color={Swatches.Primary}
          />
        </EmptyMessage>
      ) : (
        <>
          <Message text={deleteError} color={Swatches.Danger} />

          <Table>
            <Table.Header>
              <Table.HeaderCell width={0.8}>Status</Table.HeaderCell>
              <Table.HeaderCell width={2.2}>Group</Table.HeaderCell>
              <Table.HeaderCell width={2}>Dates</Table.HeaderCell>
              <Table.HeaderCell width={3}>Year Group/Subject</Table.HeaderCell>
              <Table.HeaderCell width={2} />
            </Table.Header>
            <Table.Body>
              {_provision?.groups.map((group: ProvisionGroupListView, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell width={0.8}>
                    <ProvisionGroupStatus status={group.status} fluid />
                  </Table.Cell>
                  <Table.Cell width={2.2}>
                    <DetailLabel bold label={group.name} sub={group.code} />
                  </Table.Cell>
                  <Table.Cell width={2}>
                    <Moment format="DD/MM/YY" date={group.startDate} /> - <Moment format="DD/MM/YY" date={group.endDate} />
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {group.yearGroup?.name} {group.subject?.name}
                  </Table.Cell>
                  <Table.Cell width={2} right>
                    <SplitButton
                      color={Swatches.Low}
                      text="Details"
                      onDefaultClick={() => handleViewDetails(group)}
                      working={deleting || saving}
                    >
                      <SplitButton.Option
                        text="Details"
                        onClick={() => handleViewDetails(group)}
                        show
                      />
                      <SplitButton.Option
                        text="Edit"
                        onClick={() => handleEdit(group)}
                        show
                      />
                      <SplitButton.Option
                        text={ group?.status == PGS.Draft
                          ? "Set Live"
                          : "Set as Draft"
                        }
                        onClick={() => changeStatus(group)}
                        show={group?.status !== PGS.Complete}
                      />
                      <SplitButton.Option
                        text="Clone Group"
                        onClick={() => handleClone(group)}
                        show
                      />
                      <SplitButton.Option
                        text="Delete"
                        onClick={() => handleDelete(group)}
                        show
                        color={Swatches.Danger}
                      />
                    </SplitButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.Cell colspan={5} right>
                  <Button
                    size={Size.Small}
                    text="Add New Provision Group"
                    onClick={handleAdd}
                    color={Swatches.Primary}
                    working={deleting}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </>
      )}
      <ProvisionGroupEditModal
        group={_selectedProvisionGroup}
        provision={provision}
        onSave={handleSaved}
        open={_selectedProvisionGroup != null}
        onCancel={() => _setSelectedProvisionGroup(null)}
      />
    </>
  );
};

export default ProvisionGroupsTable;
