import paymentActions from "areas/payments/actions/paymentsActions";
import { AddProductToBasketCommand } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";
import { BasketDetailView, BasketItemView, OrderLineView, UserProductDetailLegacyView, UserProductOrderView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { InstallmentType } from "areas/payments/types/shopkeeper/shopkeeperShared.types";
import React, { useState, useEffect } from "react";
import { useAppSelector } from "reducers/hooks";
import { UserTinyView } from "types/users/userListViews.types";
import { Button, Size, Swatches, ToastService } from "ui-kit";



interface ISingleAddButtonProps {
  user: UserTinyView;
  product: UserProductDetailLegacyView;
  item: UserProductOrderView | OrderLineView;
  total: number;
}


const SingleAddButton: React.FC<ISingleAddButtonProps> = ({ 
  user, 
  product, 
  item, 
  total
}) => {

  const { basket, working } = useAppSelector(state => state.userBasket);

  var basketItem = basket?.items?.find(
    (bi) =>
      bi?.productId === product.id &&
      bi.recipient?.id === item?.purchasedFor?.id
  );

  const getOrderType = (recipient: UserTinyView) => {
    return InstallmentType.Default;
    // if (!recipient.pupilPremium && !recipient.bursary) {
    //   return 0;
    // }
    // if (recipient.pupilPremium && (product.ppDiscountedPrice != null) && (product.ppDiscountedPrice != product.unitPrice)) {
    //   return 1;
    // }
    // if (recipient.bursary && (product.bursaryDiscountedPrice != null) && (product.bursaryDiscountedPrice != product.unitPrice)) {
    //   return 2;
    // }
  }

  const addToBasket = (purchasedFor: UserTinyView) => {
    var data: AddProductToBasketCommand = {
      userId: user.id,
      recipientId: item.purchasedFor.id,
      productId: item.product.id,
      quantity: 1,
      total: total,
      virtualInstalmentId: null,
      orderType: getOrderType(purchasedFor)
    };
    paymentActions.addProductToBasket(user.id, data, () => {
      paymentActions.getBasket(user.id);
      ToastService.pop("Item Added to Basket", null, "shopping-bag");
    });
  };

  const removeFromBasket = (basketItem: BasketItemView) => {
    paymentActions.removeItemFromBasket(user.id, basketItem, () => {
      paymentActions.getBasket(user.id);
      ToastService.pop("Item Removed from Basket", null, "shopping-bag");
    });
  };

 
  return !basketItem ? (
    <Button
      size={Size.Medium}
      color={Swatches.Success}
      text="Add"
      working={working}
      fluid
      onClick={() => addToBasket(item.purchasedFor)}
    />
  ) : (
    <Button
      size={Size.Medium}
      color={Swatches.Danger}
      text="Remove"
      working={working}
      fluid
      onClick={() => removeFromBasket(basketItem)}
    />
  );
}


export default SingleAddButton;