import interventionActions from "areas/planner/actions/interventions/interventionActions";
import { InterventionProgressRating } from "areas/planner/types/interventions/interventionShared";
import { IBeehiveAction } from "types/common/action.types";


export interface IGroupInterventionsFilterState {
  filter: {
    progress: InterventionProgressRating | -1;
  }
}

const INITIAL_STATE: IGroupInterventionsFilterState = {
  filter: {
    progress: -1,
  }
};


const groupInterventionsFilterReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IGroupInterventionsFilterState => {

  const {
    INTERVENTIONS_FILTERBYPROGRESS
  } = interventionActions.types;

  switch (action.type) {

    case INTERVENTIONS_FILTERBYPROGRESS:
      return {
        ...state,
        filter: {
          ...state.filter, 
          progress: action.payload
        }
      };

    default:
      return state;
  }
};

export default groupInterventionsFilterReducer;
