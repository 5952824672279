import { useEffect, useState } from "react";
import InventoryFilter from "./inventoryFilter";
import ProductsTable from "./productsTable";
import { useSelector } from "react-redux";
import { Message, Swatches } from "ui-kit";
import CategoryEditorFlyout from "./categoryEditorFlyout";
import flyoutActions from "actions/ui/flyouts";
import { Constants } from "configuration";
import { RootState } from "reducers/store";
import { useNavigate } from "react-router-dom";
import paymentActions from "areas/payments/actions/paymentsActions";
import inventoryFilterActions from "areas/payments/actions/inventoryFilterActions";
import { ProductCategoryListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { SaveProductCategoryCommand } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";


export interface NewCategory extends ProductCategoryListView {
  schoolId?: number;
}

export interface ShopkeeperCategory {
  id: number;
  name: string;
  schoolId: string;
  allowParents: boolean;
  allowStudents: boolean;
  allowStaff:boolean;
  description: string;
  isActive: boolean;
  displayIndex: number;
  icon: string;
}


const Inventory = () => {

  const [selectedCategory, setSelectedCategory] = useState<NewCategory | null>(null);
  const { products, paging, loading, error } = useSelector((state: RootState) => state.adminProducts);
  const { filter } = useSelector((state: RootState) => state.inventoryFilter);
  const navigate = useNavigate();

  const loadProducts = (pageIndex: number) => {
    filter && paymentActions.getProductsForAdmin(filter, pageIndex);
  };

  useEffect(() => {
    loadProducts(0);
  }, [filter]);

  const handlePage = () => {
    loadProducts(paging.pageIndex + 1);
  };

  const handleEditClick = () => {
    setSelectedCategory(filter.category);
    flyoutActions.openFlyout(Constants.FLYOUTS.CATEGORY_EDITOR);
  };

  const handleNewClick = () => {
    setSelectedCategory({ ...selectedCategory, schoolId: filter.schoolId });
    flyoutActions.openFlyout(Constants.FLYOUTS.CATEGORY_EDITOR);
  };

  const handleNewProductClick = () => {
    navigate(`/main/shopkeeper/category/${filter.category.id}/products/new`);
  };

  const handleCategorySaved = (category: SaveProductCategoryCommand) => {
    paymentActions.getCategoriesForSchool(filter.schoolId, () => inventoryFilterActions.filterByCategory(category));
  };

  const handleCategoryCreated = (category: ProductCategoryListView) => {
    inventoryFilterActions.filterByCategory(category);
  };

  return (
    <>
      <InventoryFilter
        onEditClick={handleEditClick}
        onNewClick={handleNewClick}
        onNewProductClick={handleNewProductClick}
      />

      { filter.category.id !== -1 && !filter.category.isActive && (
        <Message
          text="This category is not active. All products in this category are automatically unavailable for purchase."
          color={Swatches.Warning}
        />
      )}

      <ProductsTable
        loading={loading}
        error={error != null}
        products={products}
        onPage={handlePage}
        paging={paging}
      />

      <CategoryEditorFlyout
        category={selectedCategory}
        onSaved={handleCategorySaved}
        onCreated={handleCategoryCreated}
      />
    </>
  );
};

export default Inventory;
