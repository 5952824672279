import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { CommentListView } from "areas/news/types/newsResponse.types";
import { PostCommentCommand } from "areas/planner/types/assignments/assignmentRequest.types";
import client from "services/client";
import { callbackType } from "types/common/action.types";

const builder = new actionTypeBuilder("assignment");

const types = {
  /**
   * Posts a new comment
   */
  POST: builder.build("postcomment"),
  /**
   * Flags a comment for attention
   */
  FLAG: builder.build("flagcomment"),
  /**
   * Approves a comment
   */
  APPROVE: builder.build("approvecomment"),
  /**
   * Removes a comment
   */
  REMOVE: builder.build("removecomment"),
  /**
   * Notify that a new comment was posted
   */
  POSTED: "posted",
};

const postComment = (comment: PostCommentCommand, callback?: callbackType<CommentListView>) =>
  action<CommentListView>(
    () =>
      client.post(
        `planner/assignments/${comment.assignmentId}/comments`,
        comment
      ),
    types.POST,
    callback
  );

const flagComment = (assignmentId: number, commentId: number, callback?: callbackType<CommentListView>) =>
  action<CommentListView>(
    () =>
      client.post(
        `planner/assignments/${assignmentId}/comments/${commentId}/flag`
      ),
    types.FLAG,
    callback
  );

const approveComment = (assignmentId: number, commentId: number, callback?: callbackType<CommentListView>) =>
  action<CommentListView>(
    () =>
      client.post(
        `planner/assignments/${assignmentId}/comments/${commentId}/approve`
      ),
    types.APPROVE,
    callback
  );

const removeComment = (assignmentId: number, commentId: number, callback?: callbackType<CommentListView>) =>
  action<CommentListView>(
    () =>
      client.post(
        `planner/assignments/${assignmentId}/comments/${commentId}/remove`
      ),
    types.REMOVE,
    callback
  );

  
const assignmentCommentActions = {
  types,
  postComment,
  flagComment,
  approveComment,
  removeComment,
};

export default assignmentCommentActions;
