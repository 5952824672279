import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import { BehaviourCategoryGroupByListView } from "areas/behaviour/types/behaviourResponses.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";

// Does not include legacy behaviour categories
interface IBehaviourCategoriesState {
  behaviourCategoryGroups: BehaviourCategoryGroupByListView[];
  schoolId: number;
  loading: boolean;
  getBehavioursError: IBeehiveError;
}

const INITIAL_STATE: IBehaviourCategoriesState = {
  behaviourCategoryGroups: [],
  schoolId: null,
  loading: false,
  getBehavioursError: null,
};

const { GETBEHAVIOURCATEGORIES, SETBEHAVIOURSCHOOLID } = behaviourActions.types;

const behaviourCategoriesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IBehaviourCategoriesState => {

  switch (action.type) {
    case GETBEHAVIOURCATEGORIES.START:
      return { ...state, loading: true, getBehavioursError: null };

    case GETBEHAVIOURCATEGORIES.SUCCESS:
      return {
        ...state,
        loading: false,
        behaviourCategoryGroups: action.payload,
      };

    case GETBEHAVIOURCATEGORIES.FAILED:
      return { ...state, loading: false, getBehavioursError: action.payload };

    case SETBEHAVIOURSCHOOLID:
      return { ...state, schoolId: action.payload };

    default:
      return state;
  }
};

export default behaviourCategoriesReducer;
