import React from "react";
import EventHeading from "./eventHeading";
import EventSignupInfo from "./eventSignupInfo";
import styled from "styled-components";
import { Card, Left, Right, Spacing } from "ui-kit";
import { CalendarEventAppListView } from "areas/calendar/types/calendarResponses.types";


const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${Spacing.Default}px;

  .left {
    flex-shrink: 0;
    margin-right: ${Spacing.Large}px;
  }
`;


interface IEventCardProps {
  event: CalendarEventAppListView;
  onClick?: (event: CalendarEventAppListView) => void;
  readonly?: boolean;
}


const EventCard: React.FC<IEventCardProps> = ({ event, onClick, readonly }) => {

  const handleEventClick = () => {
    onClick?.(event);
  };

  if (!event) {
    return null;
  }

  return (
    <Card>
      {/* <Card.Image noPad onClick={handleEventClick}>
        <img src={`https://picsum.photos/seed/picsum/1920/1024`} />
      </Card.Image> */}
      <Card.Body noPad>
        <EventHeading 
          event={event} 
          onClick={handleEventClick}
          hideSignupButton={readonly}
          showDetails
        />
      </Card.Body>
      <Card.Footer noPad>
        <FooterWrapper>
          <Left>
            <EventSignupInfo 
              event={event} 
              readonly={readonly} 
            />
          </Left>
        </FooterWrapper>
      </Card.Footer>
    </Card>
  );
};

export default EventCard;
