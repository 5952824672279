import React from "react";
import DetentionRegisterMenuWrapper from "./detentionRegisterMenuWrapper";
import DetentionRegisterTableWrapper from "./detentionRegisterTableWrapper";


const DetentionRegister: React.FC = () => {
  return (
    <>
      {/* <HelpMessage
        icon="info-circle"
        title="Need help taking the detention register or rescheduling a detention?"
        sub="Watch our VLE training video to find out more"
        link="https://vle.lionhearttrust.org.uk/mod/page/view.php?id=70120"
      /> */}
      <DetentionRegisterMenuWrapper />
      <DetentionRegisterTableWrapper />
    </>
  );
};

export default DetentionRegister;
