import React, { useState, useEffect } from "react";
import { Dropdown } from "ui-kit";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";


interface IEventsOwnerDropdownProps {
  onChange: (eventOwner: string) => void;
  includeAllOption?: boolean;
  initialValue?: string;
  fluid?: boolean;
  clearable?: boolean;
}

interface EventOwnerDropdownOption {
  key: number;
  label: string;
  value: string;
}


const ALL_OPTION: EventOwnerDropdownOption = {
  key: -1,
  label: "All Events",
  value: "",
};


const EventsOwnerDropdown: React.FC<IEventsOwnerDropdownProps> = ({ onChange, includeAllOption, initialValue, fluid, clearable }) => {

  const [selectedValue, setSelectedValue] = useState<string | null>(includeAllOption ? ALL_OPTION.value : null);
  const [options, setOptions] = useState<EventOwnerDropdownOption[]>([]);

  const { user } = useSelector((state: RootState) => state.currentUser);

  useEffect(() => {
    var opt: EventOwnerDropdownOption[] = [
      { 
        key: 0, 
        label: "My Events", 
        value: user?.id 
      }
    ];
    includeAllOption && opt.unshift(ALL_OPTION);
    setOptions(opt);
  },[user])

  useEffect(() => {
    if (initialValue) {
      setFromOptions(initialValue);
    }
  }, [options]);

  useEffect(() => {
    const option = options?.find(x => x.value === initialValue);
    setSelectedValue(option ? option.value : "");
  }, [initialValue]);


  const setFromOptions = (value: string) => {
    const option = options?.find((x) => x?.value == value);
    setSelectedValue(option?.value);
  };

  const handleChange = (value: string) => {
    setSelectedValue(value);

    if (onChange) {
      onChange(value);
    }
  };


  return (
    <Dropdown
      placeholder="Event Owner"
      items={options}
      onChange={handleChange}
      value={selectedValue}
      fluid={fluid}
      clearable={clearable}
    />
  );
};

export default EventsOwnerDropdown;
