import React, { useEffect, useState } from "react";
import {
  Modal,
  ActionBar,
  Right,
  Button,
  Size,
  Swatches,
  Card,
  StructuredList,
  Message,
  Chip,
  ChipList,
  Tabs,
  ValidationMessage,
  TextInput,
  TextInputType,
  SplitButton,
  Checkbox,
  Sub,
  Spacing,
  ToastService,
  neutral,
} from "ui-kit";
import AreaOfNeedDropdown from "../providers/provisions/areaOfNeedDropdown";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import styled from "styled-components";
import TagsDropdown from "../tags/tagsDropdown";
import AccessArrangementDropdown from "../accessArrangements/accessArrangementDropdown";
import TileEditor from "./tileEditor";
import { Constants } from "configuration";
import passportActions from "../../actions/passports/passportActions";
import { FilePicker } from "sharedComponents/common";
import { RootState } from "reducers/store";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";
import { AccessArrangementListView, PassportDetailView, PassportFileView, PassportTileView, PassportVersionDetailView, TagListView } from "areas/send/types/passportResponse.types";
import { FileListView } from "types/common/files.types";

const TagsWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${neutral[300]};
  margin-bottom: ${Spacing.Medium}px;
  padding-bottom: ${Spacing.Medium}px;
  .dropdown {
    flex-grow: 1;
  }
  .button {
    margin-left: ${Spacing.Small}px;
  }
`;


interface IPassportEditorModalProps {
  student: PlannerStudentDetailView;
  passport: PassportDetailView;
  open: boolean;
  onCancel: () => void;
  onSave?: () => void;
}


const PassportEditorModal: React.FC<IPassportEditorModalProps> = ({ student, passport, open, onCancel, onSave }) => {

  const { saveError, saving } = useSelector((state: RootState) => state.passportVersion);
  const [_passportVersion, _setPassportVersion] = useState<PassportVersionDetailView | null>(null);
  const [_passport, _setPassport] = useState<PassportDetailView | null>(null);
  const [_selectedTag, _setSelectedTag] = useState<TagListView | null>(null);
  const [_selectedAccessArrangement, _setSelectedAccessArrangement] = useState<AccessArrangementListView | null>(null);
  const [_open, _setOpen] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    _setPassportVersion(passport?.selectedVersion);
    _setPassport(passport);
  }, [passport]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const handleSave = (saveAsDraft: boolean) => {
    if (
      !saveAsDraft &&
      !window.confirm(
        "This will save the pupil passport, publish it and lock it for any further editing. It will also be visible to all staff as well as the student and their parents. This cannot be undone. Are you sure?"
      )
    ) {
      return;
    }

    var errors = [];

    if (_passportVersion.primaryAreaOfNeed == null) {
      errors.push(`Please enter a primary area of need.`);
    }

    if (
      _passportVersion.primaryAreaOfNeed != null &&
      _passportVersion.primaryAreaOfNeed ===
        _passportVersion.secondaryAreaOfNeed
    ) {
      errors.push(
        `The primary and secondary areas of need cannot be the same.`
      );
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    const data = {
      id: _passportVersion.id,
      studentId: _passport.student.id,
      passportId: _passport.id,
      status: saveAsDraft
        ? Constants.SEND_PASSPORTSTATUS.DRAFT.value
        : Constants.SEND_PASSPORTSTATUS.PUBLISHED.value,
      tags: _passportVersion.tags?.length
        ? _passportVersion.tags.map(x => x.id)
        : [],
      accessArrangements: _passportVersion.accessArrangements?.length
        ? _passportVersion.accessArrangements.map(x => x.id)
        : [],
      primaryNeed: _passportVersion.primaryAreaOfNeed,
      secondaryNeed: _passportVersion.secondaryAreaOfNeed,
      specificNeed: _passportVersion.specificAreaOfNeed,
      ehcp: _passportVersion.ehcp,
      ihcp: _passportVersion.ihcp,
      pastoralSupportPlan: _passportVersion.pastoralSupportPlan,
      tiles: _passportVersion.tiles.map(tile => ({
        id: tile.id,
        type: tile.type,
        content: tile.content,
        customTitle: tile.customTitle,
      })),
      files: _passportVersion.files,
    };

    passportActions.savePassportVersion(data, () => {
      onSave?.();
      ToastService.pop(
        "Passport saved successfully.",
        null,
        "hand-holding-seedling"
      );
    });
  };

  const onTagDropdownChange = (value: TagListView) => {
    _setSelectedTag(value);
  };

  const handleRemoveTag = (tag: TagListView) => {
    _setPassportVersion({
      ..._passportVersion,
      tags: arrays.removeItem(_passportVersion.tags, tag),
    });
  };

  const handleAddTag = () => {
    if (_passportVersion?.tags?.every(x => x.id !== _selectedTag.id)) {
      _setPassportVersion({
        ..._passportVersion,
        tags: [..._passportVersion.tags, _selectedTag],
      });
      _setSelectedTag(null);
    }
  };

  const onAccessArrangementDropdownChange = (value: AccessArrangementListView) => {
    _setSelectedAccessArrangement(value);
  };

  const handleRemoveAccessArrangement = (accessArrangement: AccessArrangementListView) => {
    _setPassportVersion({
      ..._passportVersion,
      accessArrangements: arrays.removeItem(
        _passportVersion.accessArrangements,
        accessArrangement
      ),
    });
  };

  const handleAddAccessArrangement = () => {
    if (
      _passportVersion?.accessArrangements?.every(
        x => x.id !== _selectedAccessArrangement.id
      )
    ) {
      _setPassportVersion({
        ..._passportVersion,
        accessArrangements: [
          ..._passportVersion.accessArrangements,
          _selectedAccessArrangement,
        ],
      });
      _setSelectedAccessArrangement(null);
    }
  };

  const handleTileChange = (index: number, value: string) => {
    var tiles = _passportVersion.tiles;
    tiles[index] = { ...tiles[index], content: value };

    _setPassportVersion({ ..._passportVersion, tiles: tiles });
  };

  return (
    <Modal
      title="Edit Passport Version"
      open={_open}
      onClose={onCancel}
      width="90%"
      height="90%"
    >
      <Modal.Body>
        {_passportVersion && (
          <>
            <Message text={saveError} color={Swatches.Danger} />
            <ValidationMessage errors={validationErrors} />
            <Tabs>
              <Tabs.Pane name="Needs" label="Needs, Access & Plans">
                <Card title="Areas of Need">
                  <Card.Body>
                    <StructuredList>
                      <StructuredList.Item name="Primary Area of Need" required>
                        <AreaOfNeedDropdown
                          value={_passportVersion.primaryAreaOfNeed}
                          placeholder={
                            "Please choose a primary area of need..."
                          }
                          fluid
                          onChange={value => {
                            _setPassportVersion({
                              ..._passportVersion,
                              primaryAreaOfNeed: value,
                            });
                          }}
                        />
                      </StructuredList.Item>
                      <StructuredList.Item name="Secondary Area of Need">
                        <AreaOfNeedDropdown
                          value={_passportVersion.secondaryAreaOfNeed}
                          fluid
                          placeholder={
                            "Please choose an optional secondary area of need..."
                          }
                          onChange={value => {
                            _setPassportVersion({
                              ..._passportVersion,
                              secondaryAreaOfNeed: value,
                            });
                          }}
                        />
                      </StructuredList.Item>
                      <StructuredList.Item name="Specific Area of Need">
                        <TextInput
                          type={TextInputType.Textarea}
                          value={_passportVersion.specificAreaOfNeed}
                          rows={3}
                          fluid
                          placeholder="Please enter an optional specific area of need..."
                          onChange={value => {
                            _setPassportVersion({
                              ..._passportVersion,
                              specificAreaOfNeed: value,
                            });
                          }}
                          characterCount
                          maxLength={50}
                        />
                      </StructuredList.Item>
                      <StructuredList.Item
                        name="EHCP"
                        description="If the student has an EHCP, please ensure this is uploaded as an attachment"
                      >
                        <Checkbox
                          checked={_passportVersion.ehcp}
                          onChange={val =>
                            _setPassportVersion({
                              ..._passportVersion,
                              ehcp: val.checked,
                            })
                          }
                          text="EHCP"
                        />
                      </StructuredList.Item>
                      <StructuredList.Item
                        name="IHCP"
                        description="If the student has an IHCP, please ensure this is uploaded as an attachment"
                      >
                        <Checkbox
                          checked={_passportVersion.ihcp}
                          onChange={val =>
                            _setPassportVersion({
                              ..._passportVersion,
                              ihcp: val.checked,
                            })
                          }
                          text="IHCP"
                        />
                      </StructuredList.Item>
                      <StructuredList.Item
                        name="Pastoral Support Plan"
                        description="If the student has a Pastoral Support Plan, please ensure this is uploaded as an attachment"
                      >
                        <Checkbox
                          checked={_passportVersion.pastoralSupportPlan}
                          onChange={val =>
                            _setPassportVersion({
                              ..._passportVersion,
                              pastoralSupportPlan: val.checked,
                            })
                          }
                          text="Pastoral Support Plan"
                        />
                      </StructuredList.Item>
                    </StructuredList>
                  </Card.Body>
                </Card>
                <Card title="Tags">
                  <Card.Body>
                    <StructuredList>
                      <StructuredList.Item name="Tags">
                        <TagsWrapper>
                          <TagsDropdown
                            fluid
                            value={_selectedTag}
                            onChange={value => onTagDropdownChange(value)}
                            filter={_passportVersion.tags}
                          />
                          <Button
                            size={Size.Small}
                            text="Add Tag"
                            color={Swatches.Primary}
                            onClick={handleAddTag}
                            disabled={!_selectedTag}
                          />
                        </TagsWrapper>
                        {arrays.isEmpty(_passportVersion.tags) ? (
                          <Sub>No tags selected</Sub>
                        ) : (
                          <ChipList>
                            {_passportVersion?.tags.map((tag: TagListView, index: number) => (
                              <Chip
                                text={tag.name}
                                key={index}
                                rightIcon="times"
                                onClick={() => handleRemoveTag(tag)}
                              />
                            ))}
                          </ChipList>
                        )}
                      </StructuredList.Item>
                    </StructuredList>
                  </Card.Body>
                </Card>
                <Card title="Access Arrangements">
                  <Card.Body>
                    <StructuredList>
                      <StructuredList.Item name="Access Arrangements">
                        <TagsWrapper>
                          <AccessArrangementDropdown
                            fluid
                            value={_selectedAccessArrangement}
                            onChange={(value: AccessArrangementListView) =>
                              onAccessArrangementDropdownChange(value)
                            }
                            filter={_passportVersion.accessArrangements}
                          />
                          <Button
                            size={Size.Small}
                            text="Add Access Arrangement"
                            color={Swatches.Primary}
                            onClick={handleAddAccessArrangement}
                            disabled={!_selectedAccessArrangement}
                          />
                        </TagsWrapper>
                        {arrays.isEmpty(_passportVersion.accessArrangements) ? (
                          <Sub>No access arrangements selected</Sub>
                        ) : (
                          <ChipList>
                            {_passportVersion?.accessArrangements.map(
                              (accessArrangement: AccessArrangementListView, index: number) => (
                                <Chip
                                  text={accessArrangement.name}
                                  key={index}
                                  rightIcon="times"
                                  onClick={() =>
                                    handleRemoveAccessArrangement(
                                      accessArrangement
                                    )
                                  }
                                />
                              )
                            )}
                          </ChipList>
                        )}
                      </StructuredList.Item>
                    </StructuredList>
                  </Card.Body>
                </Card>
                <Card title="Attachments">
                  <Card.Body>
                    <StructuredList>
                      <StructuredList.Item
                        name="Upload Files"
                        description="You can upload .pdf, .docx, .xlsx, .csv, .jpg, .gif, or .png files.  After uploading, you still need to save the form to save the documents. Please note that due to technical limitations with some mobile phones, attachments are only currently available on the web version of Beehive."
                        helpPopup
                      >
                        <FilePicker
                          files={_passportVersion.files}
                          onFilesChanged={files =>
                            _setPassportVersion({
                              ..._passportVersion,
                              files: files as PassportFileView[],
                            })
                          }
                        />
                      </StructuredList.Item>
                    </StructuredList>
                  </Card.Body>
                </Card>
              </Tabs.Pane>
              <Tabs.Pane name="Details" label="Details">
                {_passportVersion?.tiles.map((tile: PassportTileView, index: number) => (
                  <TileEditor
                    key={index}
                    tile={tile}
                    student={student}
                    onChange={(value: string) => handleTileChange(index, value)}
                  />
                ))}
              </Tabs.Pane>
            </Tabs>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              onClick={onCancel}
              size={Size.Small}
              color={Swatches.Low}
              text="Cancel"
              working={saving}
            />
            <SplitButton
              onDefaultClick={() => handleSave(true)}
              text="Save as Draft"
              size={Size.Small}
              color={Swatches.Success}
              working={saving}
            >
              <SplitButton.Option
                onClick={() => handleSave(true)}
                color={Swatches.Success}
                text="Save as Draft"
                show
              />
              <SplitButton.Option
                onClick={() => handleSave(false)}
                color={Swatches.Success}
                text="Save & Publish"
                show
              />
            </SplitButton>
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default PassportEditorModal;
