import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StructuredList, TextInput, TextInputType } from "ui-kit";
import ConfirmModal from "sharedComponents/common/confirmModal";
import onCallActions from "areas/behaviour/actions/onCall/onCallActions";
import OnCallModal from "./onCallModal";
import { useAppSelector } from "reducers/hooks";
import { SaveOnCallRequestCommand } from "areas/behaviour/types/onCall/onCallRequest.types";
import { OnCallRequestDetailView, OnCallRequestListView } from "areas/analytics/reducers/onCallAnalyticsResponse.types";


interface IEditOnCallRequestWrapperProps {
  onCallId: number;
  schoolId: number;
  openModal: boolean;
  handleCloseModal: () => void;
}


const EditOnCallRequestWrapper: React.FC<IEditOnCallRequestWrapperProps> = ({
  onCallId,
  schoolId,
  openModal,
  handleCloseModal,
}) => {

  const { actions, onCallRequest } = useAppSelector(state => state.onCallRequest);

  const [openConfirmCancel, setOpenConfirmCancel] = useState<boolean>(false);
  const [cancelOnCallInternalNotes, setCancelOnCallInternalNotes] = useState<string>("");

  useEffect(() => {
    if (onCallId && !actions.getOnCallRequest.isLoading) {
      onCallActions.getOnCallRequest(schoolId, onCallId);
    }
  }, []);

  const callback = () => {
    handleCloseModal();
  };

  const handleUpdateOnCall = (onCallRequest: OnCallRequestListView, closeConfirmModal?: () => void) => {
    const studentIds = onCallRequest.students.map(student => student.id);

    const onCallRequestPayload: SaveOnCallRequestCommand = {
      id: onCallRequest.id,
      studentIds: studentIds,
      schoolId: schoolId,
      roomId: onCallRequest.roomId,
      categoryId: onCallRequest.category.categoryDetail.id,
      notes: onCallRequest.notes,
      classGroupId: onCallRequest.classGroup
        ? onCallRequest.classGroup.id
        : null,
      tutorGroupId: onCallRequest.tutorGroup
        ? onCallRequest.tutorGroup.id
        : null,
      provisionGroupId: onCallRequest.provisionGroup
        ? onCallRequest.provisionGroup.id
        : null,
    };

    onCallActions.updateOnCallRequest(
      schoolId,
      onCallRequest.id,
      onCallRequestPayload,
      callback
    );

    closeConfirmModal?.();
  };

  const handleCancelOnCall = () => {
    const payload = {
      id: onCallId,
      schoolId: schoolId,
      internalNotes: cancelOnCallInternalNotes,
    };

    onCallActions.cancelOnCallRequest(schoolId, onCallId, payload, callback);
    setOpenConfirmCancel(false);
  };

  return (
    <>
      <OnCallModal
        onCallRequest={onCallRequest}
        schoolId={schoolId}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        handleSaveOnCall={handleUpdateOnCall}
        handleCancelOnCall={() => setOpenConfirmCancel(true)}
        loading={actions.getOnCallRequest.isLoading}
        loadingAction={
          actions.updateOnCallRequest.isLoading ||
          actions.cancelOnCallRequest.isLoading
        }
        error={
          actions.getOnCallRequest.error || actions.updateOnCallRequest.error
        }
      />

      <ConfirmModal
        openModal={openConfirmCancel}
        confirmMsg={`Are you sure you want to cancel this request?`}
        onClose={() => setOpenConfirmCancel(false)}
        onConfirm={handleCancelOnCall}
        loading={actions.cancelOnCallRequest.isLoading}
      >
        <StructuredList>
          <StructuredList.Item name="Internal Notes">
            <TextInput
              fluid
              type={TextInputType.Textarea}
              placeholder="Internal Notes"
              value={cancelOnCallInternalNotes}
              onChange={value => setCancelOnCallInternalNotes(value)}
            />
          </StructuredList.Item>
        </StructuredList>
      </ConfirmModal>
    </>
  );
};

export default EditOnCallRequestWrapper;
