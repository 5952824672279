import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import AdminSubmissionFilter from "./adminSubmissionFilter";
import AdminSubmissionsTable from "./adminSubmissionsTable";
import adminSubmissionActions from "areas/forms/actions/adminSubmissionActions";
import { RootState } from "reducers/store";


const AdminDashboard = () => {

  const { submissions, paging, loading, error } = useSelector((state: RootState) => state.adminSubmissions);
  const { filter } = useSelector((state: RootState) => state.submissionFilter);
  const { user } = useSelector((state: RootState) => state.currentUser);

  useEffect(() => {
    if (user) {
      loadSubmissions(0);
    }
  }, [filter]);

  const handlePage = () => {
    loadSubmissions(paging.pageIndex + 1);
  };

  const loadSubmissions = (pageIndex: number) => {
    adminSubmissionActions.getSubmissionsForAdmin(user.id, pageIndex, filter);
  };

  return (
    <>
      <AdminSubmissionFilter />
      <AdminSubmissionsTable
        items={submissions}
        error={error}
        loading={loading}
        onPage={handlePage}
        paging={paging}
      />
    </>
  );
};

export default AdminDashboard;
