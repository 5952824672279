import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { urls } from "utils";
import client from "services/client";
import moment from "moment";
import FileDownload from "js-file-download";
import store from "reducers/store";
import { DetentionFilter, PreflightDetentionDatesFilter, RescheduleDetentionsCommand, YearGroupFilter } from "areas/behaviour/types/detentionRegisterRequest.types";
import { BeehiveReport, callbackType, faulureCallback } from "types/common/action.types";
import { DetentionStudentView, DetentionsPreflightListView, RescheduleDetentionsSaveView, SchoolStudentDetentionListView } from "areas/behaviour/types/detentionRegisterResponse.types";
import { PagedQueryView } from "types/common/views.types";

const builder = new actionTypeBuilder("detentionregister");

const types = {
  GETDETENTIONS: builder.build("getdetentions"),
  EXPORTDETENTIONS: builder.build("exportdetentions"),
  GETMISSEDDETENTIONS: builder.build("getmisseddetentions"),
  EXPORTMISSEDDETENTIONS: builder.build("exportmisseddetentions"),
  SETDETENTIONREGISTERDATE: "setdetentionregisterdate",
  SETDETENTIONREGISTERFILTER: "setdetentionregisterfilter",
  SETYEARGROUPFILTER: "setregisteryeargroup",
  SETDETENTIONGROUPBY: "setdetentiongroupby",
  SETBEHAVIOURCODE: "setbehaviourcode",
  SETBEHAVIOURCATEGORY: "setbehaviourcategory",
  SETVIEWUNMARKEDDETENTIONS: "setviewunmarkeddetentions",
  SETSEARCHTERM: "setsearchterm",
  REMOVEDETENTIONFROMMISSEDLIST: "removedetentionfrommissedlist",
  GETPREFLIGHTDETENTIONDATES: builder.build("getpreflightdetentiondates"),
  RESCHEDULEDETENTIONS: builder.build("rescheduleDetentions")
};


const getDetentions = (
  schoolId: number,
  {
    year,
    month,
    day,
    statusIds,
    yearGroupId,
    groupByDetentionType,
    groupByRoom,
    groupByStartDate,
    groupByEndDate,
    unMarkedOldDetentions,
    behaviourCodeId,
    schoolBehaviourCategoryId,
    searchTerm
  }: DetentionFilter,
  onSuccess?: callbackType<SchoolStudentDetentionListView[]>
) => {
  let url = new urls.QueryString(
    `planner/schools/${schoolId}/behaviourDetentions/sessions`
  );
  url.addParam("year", year);
  url.addParam("month", month);
  url.addParam("day", day);
  url.addParam("YearGroupId", yearGroupId);
  url.addParam("GroupByDetentionType", groupByDetentionType);
  url.addParam("GroupByRoom", groupByRoom);
  url.addParam("GroupByStartDate", groupByStartDate);
  url.addParam("GroupByEndDate", groupByEndDate);
  url.addParam("UnMarkedOldDetentions", unMarkedOldDetentions);
  url.addParam("BehaviourCodeId", behaviourCodeId);
  url.addParam("SchoolBehaviourCategoryId", schoolBehaviourCategoryId);
  url.addParam("SearchTerm", searchTerm);

  statusIds.forEach(id => {
    url.addParam("statusIds", id);
  });

  return action<SchoolStudentDetentionListView[]>(
    () => client.get(url.toUrl()), 
    types.GETDETENTIONS, 
    onSuccess
  );
};


const getMissedDetentions = (
  schoolId: number,
  {
    academicYearId,
    yearGroupId,
    date,
    detentionTypeId,
    behaviourCodeId,
    schoolBehaviourCategoryId,
    searchTerm
  }: YearGroupFilter,
  pageIndex: number,
  onSuccess?: callbackType<PagedQueryView<DetentionStudentView>> 
) => {
  let url = new urls.QueryString(
    `planner/schools/${schoolId}/missedDetentions`
  );
  url.addParam("pageIndex", pageIndex);
  url.addParam("academicYearId", academicYearId);
  url.addParam("yearGroupId", yearGroupId);
  url.addParam("date", date ? moment(date).format("YYYY-MM-DD") : null);
  url.addParam("detentionTypeId", detentionTypeId);
  url.addParam("behaviourCodeId", behaviourCodeId);
  url.addParam("SchoolBehaviourCategoryId", schoolBehaviourCategoryId);
  url.addParam("SearchTerm", searchTerm.length > 0 ? searchTerm : null);

  return action<PagedQueryView<DetentionStudentView>> (
    () => client.get(url.toUrl()),
    types.GETMISSEDDETENTIONS,
    onSuccess
  );
};


const exportMissedDetentions = (
  schoolId: number,
  {
    academicYearId,
    yearGroupId,
    date,
    detentionTypeId,
    behaviourCodeId,
    schoolBehaviourCategoryId,
    searchTerm
  }: YearGroupFilter,
  onSuccess?: callbackType<BeehiveReport>
) => {
  let url = new urls.QueryString(
    `planner/schools/${schoolId}/missedDetentions/export`
  );
  url.addParams({
    academicYearId: academicYearId,
    yearGroupId: yearGroupId,
    date: date ? moment(date, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
    detentionTypeId: detentionTypeId,
    behaviourCodeId: behaviourCodeId,
    schoolBehaviourCategoryId: schoolBehaviourCategoryId,
    searchTerm: searchTerm.length > 0 ? searchTerm : null,
  });

  return action<BeehiveReport>(
    () => client.get(url.toUrl()),
    types.EXPORTMISSEDDETENTIONS,
    response => {
      FileDownload(response, "Missed Detentions Report.csv");
      onSuccess?.(response);
    }
  );
};


const exportDetentions = (
  schoolId: number,
  {
    year,
    month,
    day,
    statusIds,
    yearGroupId,
    groupByDetentionType,
    groupByRoom,
    groupByStartDate,
    groupByEndDate,
    unMarkedOldDetentions,
    behaviourCodeId,
    schoolBehaviourCategoryId,
    searchTerm
  }: DetentionFilter,
  onSuccess?: callbackType<BeehiveReport>
) => {
  let url = new urls.QueryString(
    `planner/reporting/schools/${schoolId}/behaviourDetentions/sessions/export`
  );
  url.addParam("year", year);
  url.addParam("month", month);
  url.addParam("day", day);
  url.addParam("YearGroupId", yearGroupId);
  url.addParam("GroupByDetentionType", groupByDetentionType);
  url.addParam("GroupByRoom", groupByRoom);
  url.addParam("GroupByStartDate", groupByStartDate);
  url.addParam("GroupByEndDate", groupByEndDate);
  url.addParam("UnMarkedOldDetentions", unMarkedOldDetentions);
  url.addParam("BehaviourCodeId", behaviourCodeId);
  url.addParam("SchoolBehaviourCategoryId", schoolBehaviourCategoryId);
  url.addParam("SearchTerm", searchTerm);

  statusIds.forEach(id => {
    url.addParam("statusIds", id);
  });

  return action<BeehiveReport>(
    () => client.get(url.toUrl(), { responseType: "blob" }),
    types.EXPORTDETENTIONS,
    response => {
      FileDownload(response, "Detention Report.csv");
      onSuccess?.(response);
    }
  );
};


const getPreflightDetentionDates = (
  filter: PreflightDetentionDatesFilter,
  onSuccess?: callbackType<DetentionsPreflightListView[]>,
  onFailure?: faulureCallback
) => 
  action<DetentionsPreflightListView[]>(
    () => client.post(`planner/preflightCheckDetentionDates`, filter),
    types.GETPREFLIGHTDETENTIONDATES,
    onSuccess,
    onFailure
  );


const rescheduleDetentions = (
    command: RescheduleDetentionsCommand,
    onSuccess?: callbackType<RescheduleDetentionsSaveView[]>
) => 
  action<RescheduleDetentionsSaveView[]>(
    () => client.post(`planner/rescheduleDetentions`, command),
    types.RESCHEDULEDETENTIONS,
    onSuccess
  );


const setDetentionRegisterDate = (date: Date) => {
  store.dispatch({
    type: types.SETDETENTIONREGISTERDATE,
    payload: date,
  });
};

const setDetentionStatusFilter = (filter: number[]) => {
  store.dispatch({
    type: types.SETDETENTIONREGISTERFILTER,
    payload: filter,
  });
};

const setRegisterYearGroupFilter = (yearGroup: number) => {
  store.dispatch({
    type: types.SETYEARGROUPFILTER,
    payload: yearGroup,
  });
};

const setDetentionRegisterGroupBy = (groupByAttributes: number[]) => {
  store.dispatch({
    type: types.SETDETENTIONGROUPBY,
    payload: groupByAttributes,
  });
};

const setBehaviourCodeFilter = (behaviourCode: number) => {
  store.dispatch({
    type: types.SETBEHAVIOURCODE,
    payload: behaviourCode,
  });
};

const setBehaviourCategoryFilter = (behaviourCategory: number) => {
  store.dispatch({
    type: types.SETBEHAVIOURCATEGORY,
    payload: behaviourCategory,
  });
};

const setViewUnmarkedDetentions = (viewUnmarkedDetentions: boolean) => {
  store.dispatch({
    type: types.SETVIEWUNMARKEDDETENTIONS,
    payload: viewUnmarkedDetentions,
  });
};

const setSearchTerm = (searchTerm: string) => {
  store.dispatch({
    type: types.SETSEARCHTERM,
    payload: searchTerm,
  });
};

// export const selectDetention = (detention) => ({
//   type: detentionRegisterTypes.SELECTDETENTION,
//   payload: detention,
// });

const removeDetentionFromMissedDetentions = (detentionId: number) => {
  store.dispatch({
    type: types.REMOVEDETENTIONFROMMISSEDLIST,
    payload: detentionId,
  });
};

const detentionRegisterActions = {
  types,
  getDetentions,
  getMissedDetentions,
  exportMissedDetentions,
  exportDetentions,
  setDetentionRegisterDate,
  setDetentionStatusFilter,
  setRegisterYearGroupFilter,
  setDetentionRegisterGroupBy,
  setBehaviourCodeFilter,
  setBehaviourCategoryFilter,
  setViewUnmarkedDetentions,
  setSearchTerm,
  removeDetentionFromMissedDetentions,
  getPreflightDetentionDates,
  rescheduleDetentions
};

export default detentionRegisterActions;
