import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Review from "./review";
import { useNavigate, useParams } from "react-router-dom";
import { ToastService } from "ui-kit";
import reviewActions from "areas/humanResources/actions/reviewActions";
import routes from "configuration/routes";
import { pmRoutes } from "areas/humanResources/hrRoutes";
import { RootState } from "reducers/store";
import { ReviewDetailView } from "areas/humanResources/types/managedStaffResponse.types";
import { SaveReviewCommand } from "areas/humanResources/types/managedStaffRequest.types";


const ReviewWrapper = () => {

  let { revieweeId, reviewId } = useParams();
  const { review, loading, saving, error, saveError } = useSelector((state: RootState) => state.review);

  const navigate = useNavigate();

  useEffect(() => {
    reviewActions.getReview(revieweeId, parseInt(reviewId));
  }, []);

  const handleSave = (review: ReviewDetailView) => {
    reviewActions.saveReview(review as SaveReviewCommand, () => {
      navigate(`${routes.home}${pmRoutes.default}`);
      ToastService.pop("Review saved successfully", null, "rocket");
    });
  };

  const handleDownload = (review: ReviewDetailView) => {
    reviewActions.downloadReview(revieweeId, review.id);
  };

  return (
    <Review
      review={review}
      onSave={handleSave}
      onDownload={handleDownload}
      loading={loading}
      error={error}
      saveError={saveError}
      working={saving}
    />
  );
};

export default ReviewWrapper;
