import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { BeehiveReport, callbackType } from "types/common/action.types";
import { GetIncomingTransactionsByDateQuery, PaymentReportFilter, TransactionsReportFilter } from "../types/shopkeeper/shopkeeperRequests.types";

const builder = new actionTypeBuilder("payments");

const paymentReportingTypes = {
  PAYMENTS_INCOMEREPORT: builder.build("PAYMENTS_INCOMEREPORT"),
  PAYMENTS_PRODUCTSREPORT: builder.build("PAYMENTS_PRODUCTSREPORT"),
  PAYMENTS_PURCHASESREPORT: builder.build("PAYMENTS_PURCHASESREPORT"),
  PAYMENTS_SINGLEPRODUCTORDERSREPORT: builder.build("PAYMENTS_SINGLEPRODUCTORDERSREPORT"),
  PAYMENTS_SINGLEPRODUCTRECIPIENTSREPORT: builder.build("PAYMENTS_SINGLEPRODUCTRECIPIENTSREPORT"),
  PAYMENTS_SINGLEPRODUCTEXPORT: builder.build("PAYMENTS_SINGLEPRODUCTEXPORT"),
  PAYMENTS_TRANSACTIONSREPORT: builder.build("PAYMENTS_TRANSACTIONSREPORT"),
  PAYMENTS_INCOMINGTRANSACTIONSREPORT: builder.build("PAYMENTS_INCOMINGTRANSACTIONSREPORT"),
};

const runPurchaseReport = (data: PaymentReportFilter, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.post(`payments/reporting/purchase`, data, { responseType: "text" }),
    paymentReportingTypes.PAYMENTS_PURCHASESREPORT,
    callback
  );

const runProductReport = (data: PaymentReportFilter, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.post(`payments/reporting/product`, data, { responseType: "text" }),
    paymentReportingTypes.PAYMENTS_PRODUCTSREPORT,
    callback
  );

const runIncomeReport = (data: PaymentReportFilter, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.post(`payments/reporting/income`, data, { responseType: "text" }),
    paymentReportingTypes.PAYMENTS_INCOMEREPORT,
    callback
  );

const runSingleProductOrdersReport = (categoryId: number, productId: number, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.post(`payments/inventory/categories/${categoryId}/products/${productId}/reports/orders`, null, { responseType: "text" }),
    paymentReportingTypes.PAYMENTS_SINGLEPRODUCTORDERSREPORT,
    callback
  );

const runSingleProductRecipientsReport = (categoryId: number, productId: number, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.post(`payments/inventory/categories/${categoryId}/products/${productId}/reports/recipients`, null, { responseType: "text" }),
    paymentReportingTypes.PAYMENTS_SINGLEPRODUCTRECIPIENTSREPORT,
    callback
  );

const runSingleProductExport = (categoryId: number, productId: number, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.post(`payments/inventory/categories/${categoryId}/products/${productId}/export`, null, { responseType: "text" }),
    paymentReportingTypes.PAYMENTS_SINGLEPRODUCTEXPORT,
    callback
  );

const runTransactionsReport = (data: TransactionsReportFilter, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.post(`payments/reporting/transactions`, data, { responseType: "text" }),
    paymentReportingTypes.PAYMENTS_TRANSACTIONSREPORT,
    callback
  );

const runIncomingTransactionsReport = (data: GetIncomingTransactionsByDateQuery, callback?: callbackType<BeehiveReport>) =>
  action<BeehiveReport>(
    () => client.post(`payments/incomingtransactions/report`, data, { responseType: "text" }),
    paymentReportingTypes.PAYMENTS_INCOMINGTRANSACTIONSREPORT,
    callback
  );

const reportingActions = {
  paymentReportingTypes,
  runPurchaseReport,
  runProductReport,
  runIncomeReport,
  runSingleProductOrdersReport,
  runSingleProductRecipientsReport,
  runSingleProductExport,
  runTransactionsReport,
  runIncomingTransactionsReport,
};

export default reportingActions;
