import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import QuizForm from "./quizForm";
import { Message, Loader, Size } from "ui-kit";
import { Swatches } from "ui-kit/common/colors";
import { studentQuiz } from "utils";
import studentQuizActions from "areas/planner/actions/student/studentQuizActions";
import { addGroupIdToPayload } from "areas/planner/utils/group";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { useAppSelector } from "reducers/hooks";
import { ClassGroupStaffView } from "types/users/userGroups.types";
import { StudentListView } from "types/users/userListViews.types";
import { AnswerType } from "areas/planner/types/quiz/quizShared.types";
import { SaveStudentAnswerCommand } from "areas/planner/types/quiz/quizRequest.types";


interface IQuizFormWrapperProps {
  studentId: string;
  group: ClassGroupStaffView;
  tabIndex?: number;
  currentTabIndex?: number;
  allowSaveAndPickNext?: boolean;
  setStudent?: (student: StudentListView) => void;
  handleCloseModal?: () => void;
}


const QuizFormWrapper: React.FC<IQuizFormWrapperProps> = ({
  studentId,
  group,
  tabIndex,
  currentTabIndex,
  allowSaveAndPickNext,
  setStudent,
  handleCloseModal,
}) => {

  const { studentAnswers, refresh, loading, error, saveAnswerError } =
    useAppSelector(state => state.studentQuiz);
  const { user } = useAppSelector(state => state.currentUser);

  useEffect(() => {
    if (tabIndex === undefined || tabIndex === currentTabIndex) {
      studentQuizActions.getStudentAnswers(
        studentId,
        group.id,
        group.type,
        user.id
      );
    }
  }, [currentTabIndex]);

  useEffect(() => {
    if (refresh) {
      studentQuizActions.getStudentAnswers(
        studentId,
        group.id,
        group.type,
        user.id
      );
    }
  }, [refresh]);

  useEffect(() => {
    if (studentId) {
      studentQuizActions.getStudentAnswers(
        studentId,
        group.id,
        group.type,
        user.id
      );
    }
  }, [studentId]);

  const handleSaveAnswer = (answerType: AnswerType, comment: string, question: string, pickAnotherStudent?: boolean) => {
    let newAnswer: SaveStudentAnswerCommand = {
      id: null,
      studentId: studentId,
      answerType: answerType,
      question: question,
      comment: comment,
    };

    newAnswer = addGroupIdToPayload(newAnswer, group.id, group.type);

    studentQuizActions.saveStudentAnswer(
      newAnswer,
      !pickAnotherStudent && handleCloseModal ? handleCloseModal : null
    );
  };

  const handleSaveAndPickNext = (answerType: AnswerType, comment: string, question: string) => {
    handleSaveAnswer(answerType, comment, question, true);
    setStudent(studentQuiz.pickRandomStudent(group.students));
  };

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  if (loading) {
    return <Loader size={Size.Medium} fluid />;
  }

  return (
    <>
      {saveAnswerError && (
        <Message text={saveAnswerError.message} color={Swatches.Danger} />
      )}
      <QuizForm
        studentAnswers={studentAnswers}
        handleSaveAnswer={handleSaveAnswer}
        handleSaveAndPickNext={
          allowSaveAndPickNext ? handleSaveAndPickNext : null
        }
      />
    </>
  );
};

export default QuizFormWrapper;
