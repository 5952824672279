import React from "react";
import styled from "styled-components";
import {
  Left,
  Right,
  DetailLabel,
  Button,
  Card,
  Message,
  Swatches,
  Spacing,
  Breakpoints,
  IUiKitBaseProps,
} from "../index";
import { Size } from "../common/size";
import { ColorSwatch } from "../common/colors";
import { IActionState } from "configuration/defaults";

const Wrapper = styled.div`
  .right {
    margin-top: ${Spacing.Medium}px;
  }

  @media screen and (min-width: ${Breakpoints.xs}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      flex-grow: 1;
    }
    .right {
      width: 25%;
    }
  }
`;

const ChildrenWrapper = styled.div`
  margin-top: ${Spacing.Medium}px;
`;

interface IActionPanelProps extends IUiKitBaseProps {
  label: string | React.ReactElement;
  sub: any;
  buttonText?: string;
  buttonColor?: ColorSwatch;
  working?: boolean;
  status?: IActionState;
  children?: any | any[];
  hideButton?: boolean;
}

const ActionPanel: React.FC<IActionPanelProps> = ({
  label,
  sub,
  buttonText,
  buttonColor,
  onClick,
  working,
  className,
  status, // This has props of working (bool) and error (string)
  children,
  hideButton,
}) => {
  return (
    <Card className={`action-panel ${className ? className : ""}`}>
      <Card.Body>
        {status?.error && (
          <Message text={status?.error} color={Swatches.Danger} />
        )}
        <Wrapper className="action-panel-inner">
          <Left>
            <DetailLabel bold label={label} sub={sub} />
            {children != null && <ChildrenWrapper>{children}</ChildrenWrapper>}
          </Left>
          <Right>
            {!hideButton && (
              <Button
                fluid
                size={Size.Small}
                text={buttonText}
                color={buttonColor}
                onClick={onClick}
                working={working}
              />
            )}
          </Right>
        </Wrapper>
      </Card.Body>
    </Card>
  );
};

export default ActionPanel;
