import paymentActions from "areas/payments/actions/paymentsActions";
import { ProductListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import defaults from "configuration/defaults";
import { IBeehiveAction } from "types/common/action.types";
import { PageInfo } from "types/common/paging.types";
import { arrays } from "utils";


export interface IAdminProductsState {
  products: ProductListView[];
  error: null | string;
  loading: boolean;
  paging: PageInfo;
}

const INITIAL_STATE: IAdminProductsState = {
  products: [],
  error: null,
  loading: false,
  paging: defaults.DEFAULT_PAGING
};

const adminProductsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAdminProductsState => {
  const { 
    PAYMENTS_GETPRODUCTSFORADMIN 
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETPRODUCTSFORADMIN.START:
      return {
        ...state,
        loading: arrays.isEmpty(state.products),
        error: null
      };

    case PAYMENTS_GETPRODUCTSFORADMIN.SUCCESS:
      return {
        ...state,
        products:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.products, ...action.payload.items],
        paging: action.payload.paging,
        loading: false
      };

    case PAYMENTS_GETPRODUCTSFORADMIN.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default adminProductsReducer;
