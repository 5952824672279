import action from "actions/action";
import client from "services/client";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { callbackType } from "types/common/action.types";
import { PrintCreditBalanceView, SmartcardBalanceView, SmartcardDetailView, SmartcardTransactionListView } from "../types/shopkeeper/shopkeeperResponses.types";
import { PagedCashlessQueryView } from "types/common/views.types";

const builder = new actionTypeBuilder("store");

const types = {
  SMARTCARDS_GETSMARTCARDS: builder.build("SMARTCARD_GET"),
  SMARTCARDS_GETTRANSACTIONS: builder.build("SMARTCARD_GETTRANSACTIONS"),
  SMARTCARDS_GETBALANCE: builder.build("SMARTCARD_GETBALANCE"),
  SMARTCARDS_GETPRINTCREDITS: builder.build("SMARTCARD_GETPRINTCREDITS"),
};


const getSmartcards = (userId: string, callback?: callbackType<SmartcardDetailView[]>) =>
  action<SmartcardDetailView[]>(
    () => client.get(`payments/users/${userId}/smartcards/all`),
    types.SMARTCARDS_GETSMARTCARDS,
    callback
  );


const getSmartcardTransactions = (userId: string, pageIndex?: number, callback?: callbackType<PagedCashlessQueryView<SmartcardTransactionListView>>) =>
  action<PagedCashlessQueryView<SmartcardTransactionListView>>(
    () => client.get(`payments/users/${userId}/smartcards/transactions?pageIndex=${pageIndex}&pageSize=25`),
    types.SMARTCARDS_GETTRANSACTIONS,
    callback
  );


const getSmartcardBalance = (userId: string, schoolId?: number, callback?: callbackType<SmartcardBalanceView>) =>
  action<SmartcardBalanceView>(
    () => client.get(`payments/users/${userId}/smartcards/balance${schoolId ? `/${schoolId}` : ""}`),
    types.SMARTCARDS_GETBALANCE,
    callback
  );


const getPrintCreditsForUser = (userId: string, callback?: callbackType<PrintCreditBalanceView>) =>
  action<PrintCreditBalanceView>(
    () => client.get(`payments/users/${userId}/smartcards/printcredits`),
    types.SMARTCARDS_GETPRINTCREDITS,
    callback
  );


const smartcardActions = {
  types,
  getSmartcards,
  getSmartcardTransactions,
  getSmartcardBalance,
  getPrintCreditsForUser,
};

export default smartcardActions;
  