import React from "react";
import FeedItem from "./feedItem";
import { HtmlText } from "ui-kit";
import { useSelector } from "react-redux";
import feedActions from "../actions/feedActions";
import { RootState } from "reducers/store";
import { IFeedItem } from "../reducers/feedReducer";


interface IFeedNoticeProps {
  item: IFeedItem;
}


const FeedNotice: React.FC<IFeedNoticeProps> = ({ item }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);

  const HandleFavourite = (article: IFeedItem) => {
    article &&
      (!article.favourited
        ? feedActions.favouriteFeedArticle(user.id, article.id)
        : feedActions.unfavouriteFeedArticle(user.id, article.id));
  };

  const HandleLike = (article: IFeedItem) => {
    article &&
      (!article.liked
        ? feedActions.likeFeedArticle(user.id, article.id)
        : feedActions.unlikeFeedArticle(user.id, article.id));
  };

  const HandleDismiss = (article: IFeedItem) => {
    article && feedActions.dismissFeedArticle(user.id, article.id);
  };

  return (
    <FeedItem
      item={item}
      headerIcon="newspaper"
      headerPostMessage={
        <>
          posted a notice in{" "}
          <span className="feed-item-category">
            <b>{item.category.name}</b>
          </span>
        </>
      }
      onFavourite={HandleFavourite}
      onLike={HandleLike}
      onDismiss={HandleDismiss}
    >
      <HtmlText html={item.content} fluid />
    </FeedItem>
  );
};

export default FeedNotice;
