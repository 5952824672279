import styled from "styled-components";
import {
  Modal,
  Swatches,
  Size,
  Button,
  Card,
  StructuredList,
  HtmlText,
  DateTime,
  Message,
} from "ui-kit";
import { Avatar } from "sharedComponents/common";
import { FeedbackMessageListView } from "areas/feed/types/feedbackResponses.types";

const HtmlWrapper = styled.div`
  .feedback-text {
    overflow-y: auto !important;
    overscroll-behavior: none;
  }
`;

interface IFeedbackViewModalProps {
  open?: boolean;
  message: FeedbackMessageListView;
  onClose?: () => void;
}

const FeedbackViewModal: React.FC<IFeedbackViewModalProps> = ({ open, message, onClose }) => {

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Feedback Message"
      width="70%"
      height="70%"
    >
      <Modal.Body>
        {message && (
          <>
            {message.personalReply && (
              <Message
                text={`${message.sender.firstName} would like a personal reply to this message at ${message.sender.email}`}
                color={Swatches.Blue}
              />
            )}
            <Card title="Details">
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="Sender">
                    {" "}
                    <Avatar
                      user={message.sender}
                      size={Size.Small}
                      sub={message.sender.school.name}
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="Date">
                    <DateTime date={message.createdDate} bold />
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
            <Card title="Message">
              <Card.Body>
                <HtmlWrapper>
                  <HtmlText
                    className="feedback-text"
                    html={message.content}
                    fluid
                  />
                </HtmlWrapper>
              </Card.Body>
            </Card>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          text="Close"
          onClick={onClose}
          color={Swatches.Low}
          size={Size.Small}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default FeedbackViewModal;
