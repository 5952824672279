import React, { useState } from "react";
import { useAction } from "hooks";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import { arrays } from "utils";
import {
  Table,
  SplitButton,
  Loader,
  Size,
  EmptyMessage,
  Button,
  Swatches,
  ToastService,
  Message,
} from "ui-kit";
import AccessArrangementFlyout from "./accessArrangementFlyout";
import flyoutActions from "actions/ui/flyouts";
import accessArrangementActions from "../../actions/accessArrangements/accessArrangementsActions";
import { RootState } from "reducers/store";
import { AccessArrangementListView } from "areas/send/types/passportResponse.types";


interface IAccessArrangementsTableProps {
  arrangements: AccessArrangementListView[];
  loading?: boolean;
  error?: string;
}

const DEFAULT_ARRANGEMENT: AccessArrangementListView = {
  id: null,
  name: "",
  code: "",
  description: "",
};


const AccessArrangementsTable: React.FC<IAccessArrangementsTableProps> = ({ arrangements, loading, error }) => {

  const { deleteError, deleting } = useSelector(
    (state: RootState) => state.accessArrangement
  );
  const [_selectedArrangement, _setSelectedArrangement] = useState<AccessArrangementListView | null>(null);

  const handleAdd = () => {
    _setSelectedArrangement(DEFAULT_ARRANGEMENT);
    flyoutActions.openFlyout(Constants.FLYOUTS.SEND_ACCESSARRANGEMENT);
  };

  const handleEdit = (arrangement: AccessArrangementListView) => {
    _setSelectedArrangement(arrangement);
    flyoutActions.openFlyout(Constants.FLYOUTS.SEND_ACCESSARRANGEMENT);
  };

  const handleDelete = (arrangement: AccessArrangementListView) => {
    if (
      window.confirm(
        "This will delete the selected arrangement. This cannot be undone. Are you sure?"
      )
    ) {
      accessArrangementActions.deleteAccessArrangement(arrangement.id, () => {
        ToastService.pop(
          "Access Arrangement Deleted",
          null,
          "hand-holding-seedling"
        );
      });
    }
  };

  const handleSaved = (arrangement: AccessArrangementListView) => {
    _setSelectedArrangement(null);
    ToastService.pop("Access Arrangement Saved", null, "hand-holding-seedling");
  };

  if (loading) {
    return <Loader cover size={Size.Medium} />;
  }

  if (error) {
    return <EmptyMessage icon="times-circle" title="An error occurred" cover />;
  }

  return (
    <>
      {arrays.isEmpty(arrangements) ? (
        <EmptyMessage
          icon="hand-holding-seedling"
          title="No Access Arrangements"
          summary="No access arrangements have been set up yet"
          cover
        >
          <Button
            size={Size.Small}
            text="Add New Access Arrangement"
            onClick={handleAdd}
            color={Swatches.Primary}
          />
        </EmptyMessage>
      ) : (
        <>
          <Message text={deleteError} color={Swatches.Danger} />

          <Table>
            <Table.Header>
              <Table.HeaderCell width={1}>Code</Table.HeaderCell>
              <Table.HeaderCell>Access Arrangement</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Header>
            <Table.Body>
              {arrangements.map((arrangement: AccessArrangementListView, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell width={1}>
                    <b>{arrangement.code}</b>
                  </Table.Cell>
                  <Table.Cell width={7.5}>{arrangement.name}</Table.Cell>
                  <Table.Cell width={1.5} right>
                    <SplitButton
                      color={Swatches.Low}
                      text="Edit"
                      onDefaultClick={() => handleEdit(arrangement)}
                      working={deleting}
                    >
                      <SplitButton.Option
                        text="Edit"
                        onClick={() => handleEdit(arrangement)}
                        show
                      />
                      <SplitButton.Option
                        text="Delete"
                        onClick={() => handleDelete(arrangement)}
                        show
                        color={Swatches.Danger}
                      />
                    </SplitButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.Cell colspan={3} right>
                  <Button
                    size={Size.Small}
                    text="Add New Access Arrangement"
                    onClick={handleAdd}
                    color={Swatches.Primary}
                    working={deleting}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </>
      )}
      <AccessArrangementFlyout
        onClose={() => _setSelectedArrangement(null)}
        arrangement={_selectedArrangement}
        onSave={handleSaved}
      />
    </>
  );
};

export default AccessArrangementsTable;
