import { CalendarEvent } from "areas/calendar/reducers/calendarEventReducer";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Card, Dropdown, StructuredList, TextInput, TextInputType } from "ui-kit";
import { SignupDetails } from ".";
import { arrays } from "utils";
import { CalendarEventMenuItemType } from "areas/calendar/types/calendarShared.types";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { GroupedMenuView } from "areas/calendar/types/calendarResponses.types";
import ItemDietaryIcons from "../itemDietaryIcons";
import DietartIconLegend from "./dietartIconLegend";


export interface MenuChoice {
  id: number;
  type: CalendarEventMenuItemType;
}

interface IEventMenuProps {
  calendarEvent: CalendarEvent;
  signup: SignupDetails;
  setSignup: React.Dispatch<React.SetStateAction<SignupDetails>>;
  readonly?: boolean;
}


const EventMenu: React.FC<IEventMenuProps> = ({ calendarEvent, signup, setSignup, readonly }) => {

  const getCourseMenu = (course: GroupedMenuView) : IDropdownDataItemProps<string, MenuChoice, null>[] => {
    return course?.menu.map(dish => ({
      label: dish.name,
      value: { id: dish.id, type: dish.type }
    }));
  }

  const signupStillOpen = (cc: CalendarEvent) => {
    return (
      cc?.allowSignupTo == null ||
      moment(cc?.allowSignupTo).isAfter(moment())
    )
  }

  const setMenuChoice = (menuItem: MenuChoice) => {
    const index = signup.menu?.map(e => e.type).indexOf(menuItem.type);
    var newChoices = signup.menu;

    if (index != null) {
      newChoices = arrays.remove(signup.menu, index);
    }

    newChoices.push(menuItem);
    setSignup({ ...signup, menu: newChoices });
  };

  if (!calendarEvent || calendarEvent?.menu?.length === 0 || calendarEvent?.dates?.length !== 1) {
    return (
      <></>
    )
  }

  if (!signupStillOpen(calendarEvent) || readonly) {
    return (
      <>
        <ReadonlyEventMenu 
          calendarEvent={calendarEvent}
          signup={signup} 
        />
        <DietartIconLegend />
      </>
    )
  }
  
  return (
    <>
      <Card title="Menu">
        <Card.Body>
          <StructuredList>
            {calendarEvent.menu.map((course, index) => (
              <StructuredList.Item
                key={index}
                name={course.typeName}
                required={course.menu.length > 1}
              >
                {course.menu.length === 1 ? (
                  <>{course.menu[0].name}</>
                ) : (
                  <Dropdown
                    fluid
                    onChange={val => setMenuChoice(val)}
                    items={getCourseMenu(course)}
                    value={signup?.menu?.find(x => x.type === course.type)}
                  />
                )}
              </StructuredList.Item>
            ))}
          </StructuredList>
        </Card.Body>
      </Card>
      <DietartIconLegend />
    </>
  );
}


interface IReadonlyEventMenuProps {
  calendarEvent: CalendarEvent;
  signup: SignupDetails;
}

const ReadonlyEventMenu: React.FC<IReadonlyEventMenuProps> = ({ calendarEvent, signup }) => {

  if (!calendarEvent || !calendarEvent.signedUp || calendarEvent?.menu?.length === 0 || calendarEvent?.dates?.length !== 1) {
    return (
      <></>
    )
  }

  const getmenuChoice = (course: GroupedMenuView) => {
    var found = calendarEvent.menuChoices?.find(x => x.type === course.type);
    return found ?(
      <>
        <h3>{found.name}</h3>
        <p>{found.description}</p>
        <ItemDietaryIcons menuItem={found} />
      </>
    )
    : (
      <></>
    )
  }

  return (
    <>
      <Card title="Menu">
        <Card.Body>
          <StructuredList>
            {calendarEvent.menu.map((course, index) => (
              <StructuredList.Item
                key={index}
                name={course.typeName}
              >
                {getmenuChoice(course)}
              </StructuredList.Item>
            ))}
          </StructuredList>
        </Card.Body>
      </Card>
    </>
  )
}

export default EventMenu;