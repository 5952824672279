import React from "react";
import { Title, TitleSize } from "ui-kit";
import Subheader from "sharedComponents/layout/header/subheader";
import { PasswordChanger } from "sharedComponents/common";
import { useSelector } from "react-redux";
import passwordActions from "areas/administration/actions/passwordActions";
import { RootState } from "reducers/store";


const ChangePassword = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);

  const changePassword = (password: string) => {
    return passwordActions.update(user.id, {
      password,
      confirmPassword: password,
      userId: user.id,
    });
  };

  return (
    <>
      <Subheader>
        <Title
          size={TitleSize.H2}
          text="Password & Security"
          sub="Change Your Password"
        />
      </Subheader>
      <PasswordChanger onPasswordChange={changePassword} />
    </>
  );
};

export default ChangePassword;
