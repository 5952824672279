import React from "react";
import { Chip, Swatches } from "ui-kit";
import { ApprovalStatus } from "areas/humanResources/types/leaveShared.types";

interface IApprovalStatusChipProps {
  status: ApprovalStatus;
  fluid?: boolean;
}

const ApprovalStatusChip: React.FC<IApprovalStatusChipProps> = ({ status, fluid }) => {

  var swatch = null;
  var name = "";

  switch (status) {
    case ApprovalStatus.Draft:
      swatch = Swatches.Low;
      name = "Draft";
      break;
    case ApprovalStatus.Submitted:
      swatch = Swatches.Low;
      name = "Submitted";
      break;
    case ApprovalStatus.Approved:
      swatch = Swatches.Warning;
      name = "Approved";
      break;
    case ApprovalStatus.Confirmed:
      swatch = Swatches.Success;
      name = "Confirmed";
      break;
    case ApprovalStatus.Completed:
      swatch = Swatches.Complete;
      name = "Complete";
      break;
    case ApprovalStatus.Cancelled:
      swatch = Swatches.Cancel;
      name = "Cancelled";
      break;
    case ApprovalStatus.Rejected:
      swatch = Swatches.Danger;
      name = "Rejected";
      break;
    default:
      return null;
  }

  return <Chip text={name} colorSwatch={swatch} fluid={fluid} />;
};

export default ApprovalStatusChip;
