import React from "react";
import styled from "styled-components";

export interface IContainerProps {
  children: React.ReactElement | React.ReactElement[];
}

const Wrapper = styled.div`
  display: flex;
`;

const Container: React.FC<IContainerProps> = ({ children }) => {
  return <Wrapper className="container">{children}</Wrapper>;
};

export default Container;
