import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import store from "reducers/store";
import client from "services/client";

const builder = new actionTypeBuilder("student");

const types = {
  SELECTSTUDENT: builder.build("selectstudent"),
  SELECTMULTISTUDENT: builder.build("selectmultistudent"),
  GETSTUDENT: builder.build("getstudent"),
  GETSTUDENTCLASSES: builder.build("getstudentclasses"),
  GETSTUDENTACTIVITYFEED: builder.build("getstudentactivityfeed"),
  SETSTUDENTFEEDTYPEFILTER: "setstudentfeedtypefilter",
  SETSTUDENTCREATEDDATEFILTER: "setstudentcreateddatefilter",
  GETSTUDENTCOMPLETEDCONSENTFORMS: builder.build(
    "getstudentcompletedconsentforms"
  )
};

const selectStudent = studentId =>
  store.dispatch({
    type: types.SELECTSTUDENT,
    payload: studentId,
  });

const selectMultiStudent = students =>
  store.dispatch({
    type: types.SELECTMULTISTUDENT,
    payload: students,
  });

const getStudent = (studentId, onSuccess) => { //PlannerStudentDetailView
  return action(
    () => client.get(`planner/students/${studentId}`),
    types.GETSTUDENT,
    onSuccess
  );
};

const getStudentClasses = (studentId, onSuccess) => {
  return action(
    () => client.get(`planner/students/${studentId}/classes`),
    types.GETSTUDENTCLASSES,
    onSuccess
  );
};

const getStudentActivityFeed = ( //StudentFeedListView[] 
  studentId,
  pageIndex,
  feedType,
  createdDate,
  onSuccess
) => {
  return action(
    () =>
      client.get(
        `planner/students/${studentId}/feed?PageIndex=${pageIndex}&FeedType=${feedType}&CreatedDate=${createdDate}`
      ),
    types.GETSTUDENTACTIVITYFEED,
    onSuccess
  );
};

const setStudentFeedTypeFilter = filter =>
  store.dispatch({
    type: types.SETSTUDENTFEEDTYPEFILTER,
    payload: filter,
  });

const setStudentFeedCreatedDateFilter = filter =>
  store.dispatch({
    type: types.SETSTUDENTCREATEDDATEFILTER,
    payload: filter,
  });

const getStudentCompletedConsentForms = studentId => {
  return action(
    () => client.get(`users/consent/students/${studentId}/forms/completed`),
    types.GETSTUDENTCOMPLETEDCONSENTFORMS
  );
};


const studentActions = {
  types,
  selectStudent,
  selectMultiStudent,
  getStudent,
  getStudentClasses,
  getStudentActivityFeed,
  setStudentFeedTypeFilter,
  setStudentFeedCreatedDateFilter,
  getStudentCompletedConsentForms,
};

export default studentActions;
