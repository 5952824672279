import React from "react";
import styled from "styled-components";
import {
  typescale,
  textFont,
  neutral,
  fontStyle,
  honeygold,
  Spacing,
} from "../index";

export interface ILabelProps {
  children: string | React.ReactElement | React.ReactNode;
  bold?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
}

const Wrapper = styled.div<{ bold?: boolean }>`
  margin-bottom: ${Spacing.Small}px;
  margin-top: ${Spacing.Small - 2}px;

  ${({ bold }) =>
    fontStyle(
      bold ? textFont.bold : textFont.roman,
      typescale.paragraph,
      neutral[700]
    )};

  line-height: 1rem !important;

  ${({ onClick }) =>
    onClick &&
    ` 
    ${fontStyle(textFont.bold, typescale.paragraph, honeygold)}

    line-height: 1rem !important;

    &:hover {
      cursor: pointer;
      color: ${neutral[700]};
      transition: all ease 150ms;
    }
  `}

  a {
    color: ${honeygold};

    &:hover {
      color: ${neutral[700]};
      transition: all ease 150ms;
    }
  }
`;

const Label: React.FC<ILabelProps> = ({
  children,
  bold,
  className,
  onClick,
}) => {
  return (
    <Wrapper
      className={`label ${className ? className : ""}`}
      bold={bold}
      onClick={onClick}
    >
      {children}
    </Wrapper>
  );
};

Label.defaultProps = {
  bold: false,
};

export default Label;
