import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import store from "reducers/store";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { AssignmentDetailView } from "types/planner/assignments.types";
import { SubmissionListView } from "areas/planner/types/assignments/assignmentResponse.types";
import { GroupType } from "types/users/userGroups.types";
import { CommentListView } from "areas/news/types/newsResponse.types";
import { SurveyType } from "areas/planner/components/assignments/assignmentSubmission";

const builder = new actionTypeBuilder("assignment");

const types = {
  /**
   * Retrieves an assignment
   */
  GETASSIGNMENT: builder.build("getassignment"),
  /**
   * Retrieves submissions for an assignment
   */
  GETASSIGNMENTSUBMISSIONS: builder.build("getassignmentsubmissions"),
  /**
   * Selects an assignment
   */
  SELECTASSIGNMENT: "selectassignment",
  /**
   * Sends an assignment read request
   */
  MARKREAD: builder.build("markread"),
  /**
   * Retrieves submissions for an assignment
   */
  GETSUBMISSIONS: builder.build("getsubmissions"),
  /**
   * Retrieves comments for an assignment
   */
  GETCOMMENTS: builder.build("getcomments"),
  /**
   * Sends a submission request for an assignment
   */
  SUBMITASSIGNMENT: builder.build("submitassignment"),
  /**
   * Marks an assignment as submitted (usually after a push notification is received)
   */
  SUBMITTED: "submitted",
  /**
   * Marks an assignment as read (usually after a push notification is received)
   */
  READ: "read",

  SUBMISSIONS_FILTERBYGROUP: "SUBMISSIONS_FILTERBYGROUP",
  SUBMISSIONS_FILTERBYSTATUS: "SUBMISSIONS_FILTERBYSTATUS",
};


const getAssignment = (userId: string, assignmentId: number, callback?: callbackType<AssignmentDetailView>) =>
  action<AssignmentDetailView>(
    () => client.get(`planner/users/${userId}/assignments/${assignmentId}`),
    types.GETASSIGNMENT,
    callback
  );

const selectAssignment = (assignmentId: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.SELECTASSIGNMENT,
    payload: assignmentId,
  });
};

const markAssignmentRead = (assignmentId: number, studentId: string, callback?: callbackType<null>) =>
  action<null>(
    () =>
      client.post(
        `planner/students/${studentId}/assignments/${assignmentId}/read`
      ),
    types.MARKREAD,
    callback
  );

const getAssignmentSubmissions = (
  assignmentId: number, 
  groupType?: GroupType, 
  groupId?: number, 
  callback?: callbackType<SubmissionListView[]>
) =>
  action<SubmissionListView[]>(
    () =>
      client.get(
        `planner/assignments/${assignmentId}/submissions?groupType=${groupType}&groupId=${groupId}`
      ),
    types.GETSUBMISSIONS,
    callback
  );

const getAssignmentComments = (assignmentId: number, callback?: callbackType<CommentListView[]>) =>
  action<CommentListView[]>(
    () => client.get(`planner/assignments/${assignmentId}/comments`),
    types.GETCOMMENTS,
    callback
  );

const submitAssignment = (assignmentId: number, studentId: string, survey: SurveyType, callback?: callbackType<null>) =>
  action(
    () =>
      client.post<null>(
        `planner/students/${studentId}/assignments/${assignmentId}/submit`,
        { ...survey, studentId, assignmentId }
      ),
    types.SUBMITASSIGNMENT,
    callback
  );


const assignmentActions = {
  types,
  getAssignment,
  selectAssignment,
  markAssignmentRead,
  getAssignmentSubmissions,
  getAssignmentComments,
  submitAssignment,
};

export default assignmentActions;
