import { IBeehiveAction } from "types/common/action.types";
import consentActions from "../../actions/consentActions";
import { ConsentFormUserView } from "areas/administration/types/consentResponses.types";

interface IUserConsentFormState {
  loading: boolean;
  form: ConsentFormUserView | null,
  error: string | null;
}

const CONSENT_STATE: IUserConsentFormState = {
  loading: false,
  form: null,
  error: null,
};


const userConsentFormReducer = (state = CONSENT_STATE, action: IBeehiveAction) : IUserConsentFormState => {

  const { CONSENT_GETFORMFORUSER, CONSENT_GETFORMFORPARENT } =
    consentActions.types;

  switch (action.type) {
    case CONSENT_GETFORMFORUSER.START:
    case CONSENT_GETFORMFORPARENT.START:
      return { ...state, loading: true, error: null };
    case CONSENT_GETFORMFORUSER.SUCCESS:
    case CONSENT_GETFORMFORPARENT.SUCCESS:
      return { ...state, loading: false, form: action.payload, error: null };
    case CONSENT_GETFORMFORUSER.FAILED:
    case CONSENT_GETFORMFORPARENT.FAILED:
      return {
        ...state,
        loading: false,
        form: null,
        error: "There was a problem loading the consent form",
      };

    default:
      return state;
  }
};

export default userConsentFormReducer;
