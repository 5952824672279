import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import { BehaviourCategoryGroupByListView } from "areas/behaviour/types/behaviourResponses.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


interface IAllRewardCategoriesState {
  allRewardCategoryGroups: BehaviourCategoryGroupByListView[];
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IAllRewardCategoriesState = {
  allRewardCategoryGroups: [],
  loading: false,
  error: null,
};

const { GETALLREWARDCATEGORIES } = rewardActions.types;


const allRewardCategoriesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAllRewardCategoriesState => {

  switch (action.type) {
    case GETALLREWARDCATEGORIES.START:
      return { ...state, loading: true, error: null };

    case GETALLREWARDCATEGORIES.SUCCESS:
      return {
        ...state,
        loading: false,
        allRewardCategoryGroups: action.payload,
      };

    case GETALLREWARDCATEGORIES.FAILED:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default allRewardCategoriesReducer;
