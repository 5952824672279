import { DetentionStatus } from "../types/behaviourShared.types";


export const DETENTION_STATUS_FILTERS = [
  { name: "Issued", value: DetentionStatus.Issued },
  { name: "Attended", value: DetentionStatus.Attended },
  { name: "Missed", value: DetentionStatus.Missed },
  { name: "Cancelled", value: DetentionStatus.Cancelled },
  { name: "Rescheduled", value: DetentionStatus.Rescheduled },
];

export const DETENTION_GROUPBY_KEYS = {
  DETENTION_TYPE: 0,
  ROOM: 1,
  START_TIME: 2,
  END_TIME: 3,
};

export const DETENTION_GROUPBY = [
  {
    key: DETENTION_GROUPBY_KEYS.DETENTION_TYPE,
    name: "Detention Type",
  },
  { key: DETENTION_GROUPBY_KEYS.ROOM, name: "Room", setAttribute: false },
  {
    key: DETENTION_GROUPBY_KEYS.START_TIME,
    name: "Start Time",
  },
  {
    key: DETENTION_GROUPBY_KEYS.END_TIME,
    name: "End Time",
  },
];

export const DETENTION_TABLE_HEADERS = {
  attendanceButtons: "Attendance",
  student: "Student",
  tutorGroup: "Tutor Group",
  behaviour: "Behaviour",
  detentionType: "Detention Type",
  issuedBy: "Issued By",
  room: "Detention Room",
  start: "Start",
  end: "End",
  warning: "",
  actionButton: "",
};

// Created an array as number of columns change depending on group by options selected in Detention Register
export const DETENTION_TABLE_HEADERS_ARRAY = [
  DETENTION_TABLE_HEADERS.attendanceButtons,
  DETENTION_TABLE_HEADERS.student,
  DETENTION_TABLE_HEADERS.tutorGroup,
  DETENTION_TABLE_HEADERS.behaviour,
  DETENTION_TABLE_HEADERS.detentionType,
  DETENTION_TABLE_HEADERS.issuedBy,
  DETENTION_TABLE_HEADERS.room,
  DETENTION_TABLE_HEADERS.start,
  DETENTION_TABLE_HEADERS.end,
  DETENTION_TABLE_HEADERS.warning,
  DETENTION_TABLE_HEADERS.actionButton,
];
