import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import {
  Flyout,
  ActionBar,
  Button,
  Swatches,
  Card,
  EmptyMessage,
  Size,
  ChipList,
  Sub,
  TextInput,
  TextInputType,
  Message,
  ValidationMessage,
  StructuredList,
  neutral,
  Chip,
  Spacing,
  Checkbox,
} from "ui-kit";
import { SchoolDropdown } from "sharedComponents/common";
import { arrays } from "utils";
import TagsDropdown from "../../tags/tagsDropdown";
import styled from "styled-components";
import ProviderDropdown from "../providerDropdown";
import AreaOfNeedDropdown from "./areaOfNeedDropdown";
import flyoutActions from "actions/ui/flyouts";
import provisionsActions from "areas/send/actions/provisions/provisionsActions";
import { RootState } from "reducers/store";
import { CustomProvisionDetailView, ProviderListView, ProvisionListView, StandardProvisionDetailView, TagListView } from "areas/send/types/passportResponse.types";
import { provisionInEdit } from "../provider";
import { SaveStandardProvisionCommand } from "areas/send/types/passportRequest.types";
import { ProvisionStatus, ProvisionType } from "areas/send/types/passportShared.types";


const TagsWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${neutral[300]};
  margin-bottom: ${Spacing.Medium}px;
  padding-bottom: ${Spacing.Medium}px;
  .dropdown {
    flex-grow: 1;
  }
  .button {
    margin-left: ${Spacing.Small}px;
  }
`;



interface IProvisionFlyoutProps {
  provision: provisionInEdit;
  provider?: ProviderListView;
  onClose?: (provision: provisionInEdit) => void;
  onSave?: (provision: standardProvisionEdit | customProvisionEdit) => void;
}

export interface standardProvisionEdit extends Partial<StandardProvisionDetailView> {
  providerId?: number;
  schoolId?: number;
  newProviderId?: number;
}

export interface customProvisionEdit extends Partial<CustomProvisionDetailView> {
  providerId?: number;
  schoolId?: number;
  newProviderId?: number;
}


const ProvisionFlyout: React.FC<IProvisionFlyoutProps> = ({ provision, provider, onClose, onSave }) => {

  const { saveError, saving } = useSelector((state: RootState) => state.standardProvision);

  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_selectedTag, _setSelectedTag] = useState<TagListView | null>(null);
  const [_provision, _setProvision] = useState<standardProvisionEdit | customProvisionEdit>(null);
  const [_provider, _setProvider] = useState<ProviderListView>(provider);

  useEffect(() => {
    if (provision) {
      if (provision.id && provider?.id && provision.provisionType == ProvisionType.Standard) {
        provisionsActions.getStandardProvision(
          provider.id, 
          provision.id, 
          (provisionDetail: StandardProvisionDetailView) => _setProvision(provisionDetail as standardProvisionEdit)
        );
      }
      else if (provision.id && provider?.id && provision.provisionType == ProvisionType.Custom) {
        provisionsActions.getCustomProvision(
          provision.studentId, 
          provision.id,
          (provisionDetail: CustomProvisionDetailView) => _setProvision(provisionDetail as customProvisionEdit)
        );
      }
      else {
        _setProvision({
          ...provision,
          schoolId: provision.id ? provision.school?.id : provision.schoolId,
          providerId: provision.id
            ? provision.provider?.id
            : provision.providerId,
          tags: [],
        });
      }
    }
    setValidationErrors([]);
  }, [provision]);

  const handleClose = () => {
    flyoutActions.closeFlyout();
    onClose?.(provision);
  };

  const handlePostSave = () => {
    flyoutActions.closeFlyout();
    onSave?.(_provision);
  };

  const handleSave = () => {
    // Validation
    var errors = [];

    if (!_provision.name.length) {
      errors.push(`Please enter a first name.`);
    }

    if (!_provision.schoolId) {
      errors.push(`Please choose a school.`);
    }

    if (_provision.areaOfNeed == null) {
      errors.push(`Please choose an area of need.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    var data: SaveStandardProvisionCommand = {
      providerId: _provision.providerId,
      schoolId: _provision.schoolId,
      id: _provision.id,
      description: _provision.description,
      areaOfNeed: _provision.areaOfNeed,
      name: _provision.name,
      tags: _provision.tags.map(x => x.id),
      newProviderId: _provision.newProviderId,
      status: _provision.status
    };

    if (data.id) {
      provisionsActions.saveStandardProvision(data, () => {
        handlePostSave();
      });
    } else {
      provisionsActions.createStandardProvision(data, () => {
        handlePostSave();
      });
    }
  };

  const onTagDropdownChange = (value: TagListView) => {
    _setSelectedTag(value);
  };

  const handleRemoveTag = (tag: TagListView) => {
    _setProvision({
      ..._provision,
      tags: arrays.removeItem(_provision.tags, tag),
    });
  };

  const handleAddTag = () => {
    if (_provision?.tags?.every(x => x.id !== _selectedTag.id)) {
      _setProvision({
        ..._provision,
        tags: [..._provision.tags, _selectedTag],
      });
      _setSelectedTag(null);
    }
  };

  return (
    <Flyout
      title={`${provision?.id ? "Edit" : "New"} Provision`}
      name={Constants.FLYOUTS.SEND_PROVISION}
      wide
    >
      <Flyout.Body>
        {_provision ? (
          <>
            <Message text={saveError} color={Swatches.Danger} />
            <ValidationMessage errors={validationErrors} />
            <Card>
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="Name" required>
                    <TextInput
                      value={_provision.name}
                      onChange={value =>
                        _setProvision({ ..._provision, name: value })
                      }
                      maxLength={150}
                      fluid
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="Provider" required>
                    {_provider ? (
                      <Sub>{_provider.name}</Sub>
                    ) : (
                      <ProviderDropdown
                        value={ _provision?.newProviderId 
                          ? _provision?.newProviderId 
                          : _provision?.providerId
                        }
                        onChange={value =>
                          _provision.id 
                          ? _setProvision({ ..._provision, newProviderId: value })
                          : _setProvision({ ..._provision, providerId: value })
                        }
                        fluid
                      />
                    )}
                  </StructuredList.Item>
                  <StructuredList.Item name="School" required>
                    <SchoolDropdown
                      fluid
                      initialValue={_provision?.schoolId}
                      onChange={value =>
                        _setProvision({ ..._provision, schoolId: value })
                      }
                      showAllSchools
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="Area of Need" required>
                    <AreaOfNeedDropdown
                      fluid
                      value={_provision?.areaOfNeed}
                      onChange={value =>
                        _setProvision({ ..._provision, areaOfNeed: value })
                      }
                    />
                  </StructuredList.Item>
                  <StructuredList.Item
                    name="Description"
                    description="An optional description with more details about this provision"
                  >
                    <TextInput
                      type={TextInputType.Textarea}
                      value={_provision.description}
                      onChange={value =>
                        _setProvision({ ..._provision, description: value })
                      }
                      fluid
                      rows={5}
                    />
                  </StructuredList.Item>
                  <StructuredList.Item
                    name="Tags"
                    description="You can optionally add tags appropriate for this provision"
                  >
                    <TagsWrapper>
                      <TagsDropdown
                        fluid
                        value={_selectedTag}
                        onChange={value => onTagDropdownChange(value)}
                        filter={_provision.tags}
                      />
                      <Button
                        size={Size.Small}
                        text="Add Tag"
                        color={Swatches.Primary}
                        onClick={handleAddTag}
                        disabled={!_selectedTag}
                      />
                    </TagsWrapper>
                    {arrays.isEmpty(_provision.tags) ? (
                      <Sub>No tags selected</Sub>
                    ) : (
                      <ChipList>
                        {_provision?.tags.map((tag: TagListView, index: number) => (
                          <Chip
                            text={tag.name}
                            key={index}
                            rightIcon="times"
                            onClick={() => handleRemoveTag(tag)}
                          />
                        ))}
                      </ChipList>
                    )}
                  </StructuredList.Item>
                  {_provision.id && (
                    <StructuredList.Item
                      name="Archive"
                      description="Archive option for provisions no longer in use"
                    >
                      <Checkbox
                        checked={_provision.status === ProvisionStatus.Archived}
                        text={`Archived`}
                        onChange={(value) => _setProvision({ 
                          ..._provision, 
                          status: value.checked ? ProvisionStatus.Archived : ProvisionStatus.Live
                        })}
                      />
                    </StructuredList.Item>
                  )}
                </StructuredList>
              </Card.Body>
            </Card>
          </>
        ) : (
          <EmptyMessage
            icon="hand-holding-seedling"
            title="No Provision"
            summary="No provision has been selected"
            cover
          />
        )}
      </Flyout.Body>
      <Flyout.Footer>
        <ActionBar low>
          <Button
            text="Save"
            onClick={handleSave}
            color={Swatches.Success}
            fluid
            working={saving}
          />
        </ActionBar>
      </Flyout.Footer>
    </Flyout>
  );
};

export default ProvisionFlyout;
