import React from "react";
import PropTypes from "prop-types";
import ClassLayoutStudentWrapper from "./classLayoutStudentWrapper";
import styled from "styled-components";
import { formatScrollbars, neutral, Subtitle, Spacing } from "ui-kit";
import { onDragEnterGridBorder } from "areas/planner/constants/classViewer/classViewerLayout";

const Wrapper = styled.div`
  width: 220px;
  min-width: 220px;
  flex-direction: column;
  overflow: auto;
  min-height: 100%;
  max-height: 100%;
  position: absolute;
  right: 0;
  ${formatScrollbars(neutral[200])}
  box-sizing: border-box;

  ${({ noUnseatedStudents }) =>
    noUnseatedStudents &&
    `
  margin : 0;
  display: none;
  `}

  .unseated-student-list {
    padding: ${Spacing.Small}px 0 ${Spacing.Small}px ${Spacing.Medium}px;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 95%;
    box-sizing: border-box;

    .draggable {
      height: auto;
      transition: all ease 200ms;
      margin-bottom: ${Spacing.Medium}px;

      .class-layout-student-wrapper .class-viewer-student {
        // padding: ${Spacing.Small}px 0 ${Spacing.Small}px ${Spacing.Small}px;
        height: 100%;

        .notes {
          background: white;
          width: 16px;
          height: 16px;
          text-align: center;
          border-radius: 2px;
        }
      }
    }
  }

  .no-unseated-students {
    text-align: center;
  }
`;

const ClassLayoutUnseatedStudentList = ({
  unseatedStudents,
  onDragStart,
  onDropStudentList,
  selectedStudents,
  handleSelectStudent,
}) => {
  const onDragEnter = event => {
    event.preventDefault();
    let target = event.target;
    if (
      target.getAttribute("class") &&
      target.getAttribute("class").includes("dropzone")
    ) {
      target.style.border = onDragEnterGridBorder;
    }
  };

  const onDragLeave = event => {
    event.preventDefault();
    let target = event.target;
    if (
      target.getAttribute("class") &&
      target.getAttribute("class").includes("dropzone")
    ) {
      target.style = {};
    }
  };

  return (
    <Wrapper noUnseatedStudents={unseatedStudents.length === 0}>
      {unseatedStudents && unseatedStudents.length > 0 && (
        <>
          <Subtitle
            className="unseated-student-list-header"
            text="Unseated Students"
          />
          <div
            className="unseated-student-list dropzone" // CHANGING THE CLASS NAME WILL BREAK DRAGGING STUDENTS INTO UNSEATED LIST
            onDragOver={event => event.preventDefault()}
            onDrop={onDropStudentList}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
          >
            {unseatedStudents.map(student => (
              <ClassLayoutStudentWrapper
                key={student.id}
                student={student}
                onDragStart={onDragStart}
                selectedStudents={selectedStudents}
                handleSelectStudent={handleSelectStudent}
              />
            ))}
          </div>
        </>
      )}
    </Wrapper>
  );
};

ClassLayoutUnseatedStudentList.propTypes = {
  unseatedStudents: PropTypes.array,
  onDragStart: PropTypes.func,
  onDropStudentList: PropTypes.func,
};

export default ClassLayoutUnseatedStudentList;
