import { ManagedMoveListView } from "areas/planner/types/managedMoves/managedMovedResponse.types";
import managedMovesActions from "../../actions/managedMoves/managedMovesActions";
import { IBeehiveError } from "types/common/errors.types";
import { IBeehiveAction } from "types/common/action.types";


interface IStudentManagedMovesState {
  moves: ManagedMoveListView[];
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IStudentManagedMovesState = {
  moves: [],
  loading: false,
  error: null,
};


const studentManagedMovesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IStudentManagedMovesState => {

  const { 
    MANAGEDMOVES_GETFORSTUDENT, 
    MANAGEDMOVES_REFRESH 
  } = managedMovesActions.types;

  switch (action.type) {

    case MANAGEDMOVES_GETFORSTUDENT.START:
      return { 
        ...INITIAL_STATE, 
        loading: true 
      };
    case MANAGEDMOVES_GETFORSTUDENT.SUCCESS:
      return { 
        ...INITIAL_STATE, 
        moves: action.payload 
      };
    case MANAGEDMOVES_GETFORSTUDENT.FAILED:
      return { 
        ...INITIAL_STATE, 
        error: action.payload 
      };
      
    case MANAGEDMOVES_REFRESH:
      if (action.payload.id) {
        return {
          ...INITIAL_STATE,
          moves: state.moves.map(x =>
            x.id === action.payload.id ? action.payload : x
          ),
        };
      } else {
        return {
          ...INITIAL_STATE,
          moves: [...state.moves, action.payload],
        };
      }

    default:
      return state;
  }
};

export default studentManagedMovesReducer;
