import React from "react";
import styled from "styled-components";
import {
  Text,
  Spacing,
  fontStyle,
  displayFont,
  typescale,
  neutral,
} from "ui-kit";
import RewardStar from "sharedComponents/common/primary/rewardStar";
import BehaviourStar from "sharedComponents/common/primary/behaviourStar";
import { SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";
import { CategoryType } from "areas/analytics/types/analytics.shared";


const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 20%;

  &:hover {
    cursor: pointer;
  }

  svg {
    display: block;
    width: 150px;
    margin-bottom: ${Spacing.Large}px;
    transition: all ease 150ms;
  }

  .text {
    display: block;
    ${fontStyle(displayFont.medium, typescale.header3, neutral[100])}
  }

  &:hover {
    svg {
      width: 175px;
    }
  }
`;


interface ICategoryProps {
  category: SchoolBehaviourCategoryListView;
  categoryType: CategoryType;
  handleAddPoint: (category: SchoolBehaviourCategoryListView) => void;
}


const Category: React.FC<ICategoryProps> = ({ category, categoryType, handleAddPoint }) => {

  return (
    <Wrapper key={category.id} onClick={() => handleAddPoint(category)}>
      {categoryType === CategoryType.Reward ? (
        <RewardStar />
      ) : (
        <BehaviourStar />
      )}
      <Text>{category.behaviourCategoryName} </Text>
    </Wrapper>
  );
};

export default Category;
