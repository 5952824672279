import React, { useState, useEffect } from "react";
import {
  Modal,
  StructuredList,
  TextInput,
  TextInputType,
  ActionBar,
  Button,
  Swatches,
  Message,
  ToastService,
  DatePicker,
  ValidationMessage,
  RichTextEditor,
  Card,
  TimePicker,
} from "ui-kit";
import { arrays } from "utils";
import { useSelector } from "react-redux";
import moment from "moment";
import leaveActions from "areas/humanResources/actions/leaveActions";
import { HolidayCardView } from "areas/humanResources/types/leaveResponse.types";
import { RootState } from "reducers/store";
import { SaveOvertimeCommand } from "areas/humanResources/types/leaveRequest.types";


interface IRecordAdditionalHoursModalProps {
  holidayCard: HolidayCardView;
  onOvertimeAdded?: (overtime: SaveOvertimeCommand) => void;
  open?: boolean;
  onClose?: () => void;
}


const RecordAdditionalHoursModal: React.FC<IRecordAdditionalHoursModalProps> = ({
  holidayCard,
  onOvertimeAdded,
  open,
  onClose,
}) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { working, error } = useSelector((state: RootState) => state.overtime);
  const [_open, _setOpen] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [overtime, setOvertime] = useState<SaveOvertimeCommand | null>({
    id: null,
    staffMemberId: null,
    holidayCardId: null,
    startDate: null,
    hours: null,
    notes: null,
    returnValue: null,
    userRef: null
  });

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    holidayCard &&
      setOvertime({
        staffMemberId: user.id,
        holidayCardId: holidayCard.id,
        startDate: new Date(),
        hours: 1,
        notes: "",
        userRef: ""
      });
  }, [holidayCard]);

  const handleSave = () => {
    var errors = [];

    if (!overtime.hours || overtime.hours === 0) {
      errors.push("The number of hours is not valid.");
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    leaveActions.submitOvertime(user.id, overtime, () => {
      onClose?.();
      ToastService.pop("Overtime Added", null, "house-night");
      onOvertimeAdded?.(overtime);
    });
  };

  const handleCancel = () => {
    onClose?.();
  };

  const setStartDate = (value: Date) => {
    setOvertime({ ...overtime, startDate: value });
  };

  const handleStartTimeChanged = (value: string) => {
    var time = value.split(":");

    setOvertime({
      ...overtime,
      startDate: moment(overtime.startDate)
        .hour(parseInt(time[0]))
        .minutes(parseInt(time[1]))
        .toDate(),
    });
  };

  return (
    <Modal
      title="Record Additional Hours"
      open={open}
      onClose={onClose}
      width="90%"
      height="90%"
    >
      <Modal.Body scrollOnErrors={validationErrors}>
        <ValidationMessage errors={validationErrors} />
        <Message text={error} color={Swatches.Danger} />
        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item
                name="Start Date"
                description={`This is the date and time that your additional hours started.`}
                required
              >
                <DatePicker
                  selectedDate={overtime?.startDate}
                  onChange={setStartDate}
                  closeOnSelect
                />
                <TimePicker
                  onChange={handleStartTimeChanged}
                  initialValue={moment(overtime?.startDate).format("HH:mm")}
                />
              </StructuredList.Item>
              <StructuredList.Item
                name="Number of Hours"
                description="Please enter the number of additional hours worked. For half hours, please enter as .5 (e.g 4.5)"
                required
              >
                <TextInput
                  type={TextInputType.Number}
                  onChange={value => setOvertime({ ...overtime, hours: value })}
                  value={overtime?.hours}
                  suffix="Hours"
                  fluid
                />
              </StructuredList.Item>

              <StructuredList.Item
                name="Your Reference"
                description="You can add an optional reference for your own records."
              >
                <TextInput
                  value={overtime?.userRef}
                  onChange={value =>
                    setOvertime({ ...overtime, userRef: value })
                  }
                  characterCount
                  maxLength={50}
                  fluid
                />
              </StructuredList.Item>

              <StructuredList.Item
                name="Notes"
                description="Please add any additional information needed."
              >
                <RichTextEditor
                  onChange={value => setOvertime({ ...overtime, notes: value })}
                />
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Button
            text="Save"
            onClick={handleSave}
            color={Swatches.Success}
            fluid
            working={working}
          />
          <Button
            text="Cancel"
            onClick={handleCancel}
            color={Swatches.Low}
            fluid
            working={working}
          />
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default RecordAdditionalHoursModal;
