import React from "react";
import {
  EmptyMessage,
  Card,
  StructuredList,
  Currency,
  DetailLabel,
  Table,
  DateTime
} from "ui-kit";
import { useNavigate } from "react-router-dom";
import { UserName } from "sharedComponents/common";
import { BasketItemView, BasketView as BhBasketView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { UserTinyView } from "types/users/userListViews.types";


interface IBasketViewProps {
  basket: BhBasketView;
}


const BasketView: React.FC<IBasketViewProps> = ({ basket }) => {

  const navigate = useNavigate();

  const handleUserClicked = (user: UserTinyView) => {
    navigate(`/main/shopkeeper/users/${user.id}`);
  };

  if (!basket) {
    return (
      <EmptyMessage
        icon="shopping-bag"
        title="No Basket Found"
        summary="No basket was found for this order"
        cover
      />
    );
  }

  return (
    <>
      <Card>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Basket ID">
              {basket.id}
            </StructuredList.Item>
            <StructuredList.Item
              name="Created Date"
              description="Depending on when a user completed the checkout process, the basket created date could be significantly earlier that the order date."
            >
              <DateTime date={basket.createdDate} />
            </StructuredList.Item>
            <StructuredList.Item name="Total">
              <Currency value={basket.total} />
            </StructuredList.Item>
            <StructuredList.Item name="Checkout ID">
              {basket.paymentProviderCheckoutId}
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
      <Table zebra caption="Basket Items">
        <Table.Header>
          <Table.HeaderCell width={1}>Description</Table.HeaderCell>
          <Table.HeaderCell width={1}>Ordered For</Table.HeaderCell>
          <Table.HeaderCell width={0.5} right>
            Unit Price
          </Table.HeaderCell>
          <Table.HeaderCell width={0.5} right>
            Quantity
          </Table.HeaderCell>
          <Table.HeaderCell width={0.5} right>
            Total
          </Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {basket.items.map((item: BasketItemView, index: number) => (
            <Table.Row key={index}>
              <Table.Cell>
                <DetailLabel label={item.name} bold />
              </Table.Cell>
              <Table.Cell>
                <UserName
                  user={item.recipient}
                  onClick={() => handleUserClicked(item.recipient)}
                />
              </Table.Cell>
              <Table.Cell right>
                <Currency value={item.unitPrice} />
              </Table.Cell>
              <Table.Cell right>
                <p>{item.quantity}</p>
              </Table.Cell>
              <Table.Cell right>
                <Currency value={item.total} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default BasketView;
