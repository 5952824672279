import { Defaults } from "configuration";
import staffActions from "../actions/staffActions";
import { PageInfo } from "types/common/paging.types";
import { IBeehiveAction } from "types/common/action.types";
import { StaffType } from "types/users/userStaff.types";

interface IStaffState {
  //staff: [],
  loading: boolean;
  error: string | null;
  paging: PageInfo;
  filter: {
    schoolId: number | null;
    staffType: StaffType | null;
  },
}

const INITIAL_STATE: IStaffState = {
  //staff: [],
  loading: false,
  error: null,
  paging: Defaults.DEFAULT_PAGING,
  filter: {
    schoolId: null,
    staffType: null,
  },
};

const staffReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IStaffState => {

  const { 
    FILTERBYSCHOOL, 
    FILTERBYSTAFFTYPE 
  } = staffActions.types;

  switch (action.type) {
    // case GETSTAFF.START:
    //   return { ...state, loading: true };

    // case GETSTAFF.SUCCESS:
    //   return {
    //     ...state,
    //     staff:
    //       action.payload.paging.pageIndex === 0
    //         ? action.payload.items
    //         : [...state.staff, ...action.payload.items],
    //     paging: action.payload.paging,
    //     loading: false
    //   };

    // case GETSTAFF.FAILED:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload
    //   };

    case FILTERBYSCHOOL:
      return {
        ...state,
        filter: { ...state.filter, schoolId: action.payload },
      };

    case FILTERBYSTAFFTYPE:
      return {
        ...state,
        filter: { ...state.filter, staffType: action.payload },
      };

    default:
      return state;
  }
};

export default staffReducer;
