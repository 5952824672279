import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TimetableGrid from "./timetableGrid";
import { Loader, Size, EmptyMessage, Title, TitleSize } from "ui-kit";
import timetableActions from "areas/planner/actions/timetableActions";
import ChildTabs from "sharedComponents/common/users/childTabs";
import Subheader from "sharedComponents/layout/header/subheader";

const ParentTimetableView = ({ user }) => {
  const [selectedChild, setSelectedChild] = useState(user.children[0]);
  const { timetable, loading, error } = useSelector(state => state.timetable);

  const handleTabClicked = (name, index) => {
    setSelectedChild(user.children[index]);
  };

  useEffect(() => {
    selectedChild && timetableActions.getTimetable(selectedChild.id);
  }, [selectedChild]);

  return (
    <>
      <ChildTabs
        onChildClicked={handleTabClicked}
        singleChild={
          <Subheader>
            <Title
              text="Timetable"
              sub={`${selectedChild?.details.firstName} ${selectedChild?.details.lastName}`}
              size={TitleSize.H2}
            />
          </Subheader>
        }
      />

      {loading && <Loader size={Size.Large} cover />}
      {error && (
        <EmptyMessage
          icon="times-circle"
          title="A problem occurred"
          summary="There was a problem loading the timetable"
          cover
        />
      )}

      {!loading && !error && (
        <TimetableGrid timetable={timetable?.schools[0]} />
      )}
    </>
  );
};

export default ParentTimetableView;
