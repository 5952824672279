import React, { useState, useEffect } from "react";
import { ActionBar, TextInput, Button, Size, Swatches, Spacing } from "ui-kit";
import styled from "styled-components";


const SearchFormWrapper = styled.div`
  display: flex;
  flex-grow: 1;

  .textInput {
    margin-right: ${Spacing.Default}px;
  }
`;


interface ISearchFormProps {
  onSearch: (searchTerm: string) => void;
  placeHolder?: string;
  initialValue?: string;
}


const SearchForm: React.FC<ISearchFormProps> = ({ onSearch, placeHolder, initialValue }) => {

  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSearch = () => {
    if (searchTerm?.length > 0) {
      onSearch?.(searchTerm);
    }
  };

  useEffect(() => {
    setSearchTerm(initialValue);
  }, [initialValue]);

  return (
    <ActionBar>
      <SearchFormWrapper>
        <TextInput
          value={searchTerm}
          onChange={value => setSearchTerm(value)}
          placeholder={placeHolder ?? "Search users..."}
          fluid
          onKeyPress={event => (event.key === 'Enter') && handleSearch()}
        />
        <Button
          text="Search"
          color={Swatches.Primary}
          type="submit"
          size={Size.Medium}
          onClick={handleSearch}
        />
      </SearchFormWrapper>
    </ActionBar>
  );
};

export default SearchForm;
