import React from "react";
import { Chip, displayFont, Spacing, Swatches } from "ui-kit";
import styled from "styled-components";
import { useSelector } from "react-redux";
import sensitiveData from "areas/planner/constants/student/sensitiveData";
import UserName from "sharedComponents/common/users/userName";
import methods from "../../../../../utils/validations";

const Wrapper = styled.div`
  .chip {
    height: fit-content;
    width: fit-content;
    padding: 0 ${Spacing.Medium}px;
    margin: ${Spacing.Small}px;
    font-size: 12px;
    font-family: ${displayFont.roman.name};
    letter-spacing: 1px;
    font-weight: unset;
  }
  ${({ compact }) =>
    compact &&
    `
    .chip { 
      margin-left: 0;
      padding-left: ${Spacing.Small}px;
      padding-right: ${Spacing.Small}px;
   }
  `}
`;

const StudentSensitiveDataChip = ({ data, compact }) => {
  const { isPrivate } = useSelector(state => state.privacy);

  return (
    <Wrapper
      className="student-sensitive-data-chip-wrapper"
      compact={compact}
      style={{ display: `${isPrivate.sensitiveInfo ? "none" : "block"}` }}
    >
      <Chip
        text={data.text}
        colorSwatch={data.color}
        tooltip={data.tooltip}
        tooltipSub={data.tooltipSub}
      />
    </Wrapper>
  );
};

const SENChip = ({ student, compact }) => {
  return (methods.notNullOrUndefined(student.sen) &&
    student.sen === true) ||
    (methods.notNullOrUndefined(student.senStatus) &&
      student.senStatus !== sensitiveData.STUDENT_SEN_KEYS.NOSEN) ? (
    <StudentSensitiveDataChip
      data={{
        ...sensitiveData.SEN,
        tooltipSub: student.senStatusName,
      }}
      compact={compact}
    />
  ) : null;
};

const PPChip = ({ student, compact }) => {
  return student.pupilPremium ? (
    <StudentSensitiveDataChip data={sensitiveData.PP} compact={compact} />
  ) : null;
};

const EALChip = ({ student, compact }) => {
  return student.eal ? (
    <StudentSensitiveDataChip data={sensitiveData.EAL} compact={compact} />
  ) : null;
};

const GiftedChip = ({ student, compact }) => {
  return student.giftedTalented ? (
    <StudentSensitiveDataChip data={sensitiveData.GIFTED} compact={compact} />
  ) : null;
};

const FSMChip = ({ student, compact }) => {
  return student.freeSchoolMeal ? (
    <StudentSensitiveDataChip data={sensitiveData.FSM} compact={compact} />
  ) : null;
};

const ReadingAgeChip = ({ student, compact }) => {
  return student.readingAge ? (
    <StudentSensitiveDataChip
      data={{
        text: student.readingAge,
        color: Swatches.Default,
        tooltip: `Reading Age - ${student.readingAge}`,
      }}
      compact={compact}
    />
  ) : null;
};

const PupilPassportChip = ({ student, compact }) => {
  return student.activePupilPassport ? (
    <StudentSensitiveDataChip
      data={sensitiveData.PUPIL_PASSPORT}
      compact={compact}
    />
  ) : null;
};

const ProvisionMapsChip = ({ student, compact }) => {
  return student.activeProvisionMap ? (
    <StudentSensitiveDataChip
      data={sensitiveData.PROVISION_MAP}
      compact={compact}
    />
  ) : null;
};

const AssignedStaffSupportChip = ({ student, compact }) => {
  return student.assignedStaff?.length > 0 ? (
    <StudentSensitiveDataChip
      data={{
        ...sensitiveData.ASSIGNED_STAFF,
        tooltipSub: student.assignedStaff.map((staff, index) => (
          <React.Fragment key={index}>
            <UserName key={index} user={staff} /> <br />
          </React.Fragment>
        )),
      }}
      compact={compact}
    />
  ) : null;
};

const LookedAfterChip = ({ student, compact }) => {
  return student.lookedAfter ? (
    <StudentSensitiveDataChip
      data={sensitiveData.LOOKED_AFTER}
      compact={compact}
    />
  ) : null;
};

const ChildProtectionChip = ({ student, compact }) => {
  return student.childProtection ? (
    <StudentSensitiveDataChip
      data={sensitiveData.CHILD_PROTECTION}
      compact={compact}
    />
  ) : null;
};

const BursaryChip = ({ student, compact }) => {
  return student.bursary ? (
    <StudentSensitiveDataChip
      data={sensitiveData.BURSARY}
      compact={compact}
    />
  ) : null;
};

const studentDataChips = {
  SENChip,
  PPChip,
  EALChip,
  GiftedChip,
  FSMChip,
  ReadingAgeChip,
  PupilPassportChip,
  ProvisionMapsChip,
  AssignedSSCSupportChip: AssignedStaffSupportChip,
  LookedAfterChip,
  ChildProtectionChip,
  BursaryChip
};

export default studentDataChips;
