import { CarRegistrationListView } from "./userCars.types";
import { AddressView, TelephoneNumberListView } from "./userContact.types";
import { DepartmentListView } from "./userDepartments.types";
import { GroupListView } from "./userGroups.types";
import { StaffListView } from "./userListViews.types";
import { UserNotificationPreferencesView } from "./userNotification.types";
import { RoleListView } from "./userRoles.types";
import { ManagedStaffSchoolView } from "./userSchools.types";
import { SecurityFlag } from "./userSecurity.types";
import { SenStatus } from "./userSen.types";

export interface IManagedUserDetailView {
  address: AddressView;
  telephoneNumbers: TelephoneNumberListView[];
  schools: ManagedStaffSchoolView[];
  roles: RoleListView[];
  notificationPreferences: UserNotificationPreferencesView;
  departments: DepartmentListView[];
  staffManaged: StaffListView[];
  carRegistrations: CarRegistrationListView[];
  jobTitle: string;
  lineManager: StaffListView;
  staffTypeId: number;
  staffType: string;
  isTrust: boolean;
  pupilPremium: boolean;
  bursary: boolean;
  eal: boolean;
  sen: SenStatus;
  registeredDate: Date | null;
  yearGroup: GroupListView;
  tutorGroup: GroupListView;
  house: GroupListView;
  lockedDate: Date | null;
  securityFlag: SecurityFlag;
  securityFlagWarningDate: Date | null;
}

export enum Gender {
  Male = 0,
  Female = 1
}

export enum Title {
  Mr = 0,
  Mrs = 1,
  Miss = 2,
  Ms = 3,
  Mx = 4,
  Dr = 5,
  Prof = 6,
  Rev = 7,
  Sir = 8,
  Lord = 9
}

export interface CountryListView {
  id: number;
  name: string;
  nationality: string;
  iso3: string;
}

export enum UserType {
  Parent = 0,
  Staff = 1,
  Student = 2
}