import React from "react";
import DetentionDetailsWrapper from "./detentionDetailsWrapper";
import { Flyout } from "ui-kit";
import { Constants } from "configuration";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";


interface IDetentionDetailsFlyoutProps {
  studentId?: string;
}


const DetentionDetailsFlyout: React.FC<IDetentionDetailsFlyoutProps> = ({ studentId }) => {

  const handleCloseDetentionDetails = () => {
    detentionActions.selectDetention(null);
  };

  return (
    <Flyout
      title="Sanction Details"
      name={Constants.FLYOUTS.DETENTIONDETAILS}
      onClose={handleCloseDetentionDetails}
      wide
    >
      <DetentionDetailsWrapper studentId={studentId} />
    </Flyout>
  );
};

export default DetentionDetailsFlyout;
