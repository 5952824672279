import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ActionBar, Dropdown, DatePicker } from "ui-kit";
import { SchoolDropdown } from "sharedComponents/common";
import { arrays } from "utils";
import { Constants } from "configuration";
import leaveActions from "areas/humanResources/actions/leaveActions";
import { RootState } from "reducers/store";
import LeaveRequestTypeDropdown from "../leaveRequestTypeDropdown";


const LeaveRequestFilter = () => {

  const { filter } = useSelector((state: RootState) => state.leaveRequests);
  const { user } = useSelector((state: RootState) => state.currentUser);

  const userHasMultipleSchools = () => user.schools.length > 0;

  useEffect(() => {
    if (user && !userHasMultipleSchools()) {
      leaveActions.updateLeaveRequestsFilter({
        ...filter,
        schoolId: user.baseSchool.id,
      });
    }
  }, [user]);

  return (
    <>
      <ActionBar>
        <ActionBar.Filter>
          <ActionBar.FilterItem title="Academic Year">
            <Dropdown
              value={filter?.academicYearId}
              onChange={val =>
                leaveActions.updateLeaveRequestsFilter({
                  ...filter,
                  academicYearId: val,
                })
              }
            >
              <Dropdown.Item value={8} label="2021/22" />
              <Dropdown.Item value={9} label="2022/23" />
              <Dropdown.Item value={10} label="2023/24" />
              <Dropdown.Item value={11} label="2024/25" />
            </Dropdown>
          </ActionBar.FilterItem>
          <ActionBar.FilterItem title="School">
            <SchoolDropdown
              includeAllOption={userHasMultipleSchools()}
              initialValue={filter?.schoolId}
              onChange={val =>
                leaveActions.updateLeaveRequestsFilter({
                  ...filter,
                  schoolId: val,
                })
              }
            />
          </ActionBar.FilterItem>
          <ActionBar.FilterItem title="Status">
            <Dropdown
              value={filter?.status}
              onChange={val =>
                leaveActions.updateLeaveRequestsFilter({
                  ...filter,
                  status: val,
                })
              }
            >
              <Dropdown.Item value={null} label="All Statuses" />
              <Dropdown.Item value={1} label="Submitted" />
              <Dropdown.Item value={2} label="Approved" />
              <Dropdown.Item value={6} label="Rejected" />
              <Dropdown.Item value={3} label="Confirmed" />
              <Dropdown.Item value={5} label="Cancelled" />
            </Dropdown>
          </ActionBar.FilterItem>
          <ActionBar.FilterItem title="Leave Type">
            <LeaveRequestTypeDropdown
              value={filter?.leaveRequestType}
              onChange={val => leaveActions.updateLeaveRequestsFilter({ ...filter, leaveRequestType: val })}
              includeAll
              fluid
            />
          </ActionBar.FilterItem>

          <ActionBar.FilterItem title="Start Date">
            <DatePicker
              selectedDate={filter?.startDate}
              onChange={val =>
                leaveActions.updateLeaveRequestsFilter({
                  ...filter,
                  startDate: val,
                  order: Constants.ORDERING.ASCENDING,
                })
              }
            />
          </ActionBar.FilterItem>
        </ActionBar.Filter>
      </ActionBar>
    </>
  );
};

export default LeaveRequestFilter;
