import React from "react";
import styled from "styled-components";
import { Spacing } from "ui-kit/common/size";

const Wrapper = styled.div`
  &.grid {
    display: flex;
    flex-wrap: wrap;

    .selection-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 160px;
      max-width: 160px;

      .item-label {
        margin-bottom: ${Spacing.Large}px;
        text-align: center;
      }

      .item-sub {
        padding-top: ${Spacing.Large}px;
      }
    }
  }

  &.list {
    display: flex;
    flex-direction: column;

    .selection-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding:${Spacing.Medium}px;

        .icon {
          margin-right: ${Spacing.Medium}px;
        }
  }
`;


interface ISelectionButtonsProps {
  listView?: boolean;
  children?: any | any[];
}

const SelectionButtons: React.FC<ISelectionButtonsProps> = ({ listView, children }) => {
  return (
    <Wrapper className={`selection-buttons ${listView ? "list" : "grid"}`}>
      {children}
    </Wrapper>
  );
};

export default SelectionButtons;
