import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  fontStyle,
  displayFont,
  typescale,
  Spacing,
  ChipList,
  Chip,
  neutral,
} from "ui-kit";
import { STUDENT_FILTERS } from "areas/planner/constants/student/studentFilter";
import classViewerActions from "areas/planner/actions/classViewer/classViewerActions";

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .filter-label {
    margin-right: ${Spacing.Small}px;
    ${fontStyle(displayFont.medium, typescale.header4, neutral[600])}
  }
`;

const ChipWrapper = styled.div`
  margin-right: ${Spacing.Small + 4}px;

  &.selected,
  &:hover {
    ${({ color }) =>
      `
      .chip {
        background: ${color};
      }
    `}
  }
`;

const ClassLayoutStudentFilters = ({ groupId, groupType }) => {
  const { displayDetentionTodayStudents } = useSelector(
    state => state.classLayoutStudentFilter
  );
  const { schoolInformation } = useSelector(state => state.school);
  const [studentFilters, setStudentFilters] = useState(STUDENT_FILTERS);
  const [studentAbilityFilter, setStudentAbilityFilter] = useState(null);

  const isNull = value => value.setFilter === null;

  useEffect(() => {
    if (studentFilters.every(isNull) && studentAbilityFilter === null) {
      classViewerActions.resetFilterStudents();
    } else {
      classViewerActions.filterStudents(
        groupId,
        groupType,
        studentFilters[0].setFilter,
        studentFilters[1].setFilter,
        studentFilters[2].setFilter,
        studentFilters[3].setFilter,
        studentAbilityFilter
      );
    }
  }, [studentFilters, studentAbilityFilter]);

  const onSelect = filterKey => {
    const tempSelectedFilters = studentFilters.slice();
    tempSelectedFilters.forEach(selectedFilter => {
      if (selectedFilter.key === filterKey) {
        const filter = selectedFilter.setFilter;
        selectedFilter.setFilter = filter === true ? null : true;
      }
    });
    setStudentFilters(tempSelectedFilters);
  };

  // const onSelectAbility = filterKey => {
  //   if (studentAbilityFilter !== filterKey) {
  //     setStudentAbilityFilter(filterKey);
  //   } else {
  //     setStudentAbilityFilter(null);
  //   }
  // };

  const handleDetentionTodayFilter = () => {
    classViewerActions.displayDetentionToday(!displayDetentionTodayStudents);
  };

  return (
    <Wrapper>
      <ChipList>
        {studentFilters.map(filter => (
          <ChipWrapper
            key={filter.key}
            className={filter.setFilter ? "selected" : ""}
            color={filter.color.swatch}
          >
            <Chip
              text={filter.text}
              onClick={() => onSelect(filter.key)}
              tooltip={filter.tooltip}
            />
          </ChipWrapper>
        ))}

        {schoolInformation && !schoolInformation.isPrimary && (
          <ChipWrapper
            className={displayDetentionTodayStudents ? "selected" : ""}
            color={neutral[800]}
          >
            <Chip
              className={displayDetentionTodayStudents ? "selected" : ""}
              text="Detention Today"
              onClick={() => handleDetentionTodayFilter()}
              tooltip="Filter Detention Today"
              tooltipSub="Highlight students with detention today"
            />
          </ChipWrapper>
        )}
      </ChipList>
      {/* <div className="filter-label">Ability Filter:</div>
      <ChipList>
        {STUDENT_ABILITY_FILTERS.map(filter => (
          <Chip
            key={filter.key}
            className={filter.key === studentAbilityFilter ? "selected" : ""}
            text={filter.text}
            onClick={() => onSelectAbility(filter.key)}
          />
        ))}
      </ChipList> */}
    </Wrapper>
  );
};

ClassLayoutStudentFilters.propTypes = {
  classGroupId: PropTypes.number,
};

export default ClassLayoutStudentFilters;
