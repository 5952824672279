import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LinkList from "./linkList";
import { Constants } from "configuration";
import { arrays } from "utils";
import { Button, Swatches, Size } from "ui-kit";
import LinkFlyout from "./linkFlyout";
import flyoutActions from "../../../actions/ui/flyouts";
import { LinkListView } from "types/common/links.types";

const Wrapper = styled.div``;


interface ILinkPickerProps {
  links?: LinkListView[];
  onLinksChanged?: (links: LinkListView[]) => void;
}

const defaultLink: LinkListView = {
  id: null,
  url: "",
  title: "",
  description: ""
}

const LinkPicker: React.FC<ILinkPickerProps> = ({ links, onLinksChanged }) => {

  const [_links, _setLinks] = useState<LinkListView[]>([]);
  const [selectedLink, setSelectedLink] = useState<LinkListView>(defaultLink);
  const [selectedLinkIndex, setSelectedLinkIndex] = useState<number | null>(null);

  useEffect(() => {
    !arrays.isEmpty(links) && _setLinks(links);
  }, []);

  useEffect(() => {
    !arrays.isEmpty(links) && _setLinks(links);
  }, [links]);

  const updateLinks = (links: LinkListView[]) => {
    _setLinks(links);
    onLinksChanged?.(links);
  };

  const handleLinkDelete = (link: LinkListView) => {
    updateLinks([...arrays.removeItem(_links, link)]);
    setSelectedLinkIndex(null);
  };

  const handleLinkEdit = (link: LinkListView, index?: number) => {
    setSelectedLink(link);
    setSelectedLinkIndex(index ?? null);
    flyoutActions.openFlyout(Constants.FLYOUTS.LINK_EDITOR);
  };

  const handleNewLink = () => {
    setSelectedLink(defaultLink);
    setSelectedLinkIndex(null);
    flyoutActions.openFlyout(Constants.FLYOUTS.LINK_EDITOR);
  };

  const handleCancelFlyout = () => {
    setSelectedLink(defaultLink);
    setSelectedLinkIndex(null);
  };

  const handleSave = (link: LinkListView | null) => {
    if (link == null) {
      return;
    }
    if (selectedLinkIndex != null) {
      updateLinks(_links.map((x, i) => (i === selectedLinkIndex ? link : x)));
    } else {
      updateLinks([...(_links || []), link]);
    }
    setSelectedLinkIndex(null);
  };

  return (
    <>
      <Wrapper>
        {!arrays.isEmpty(_links) && (
          <LinkList>
            {_links.map((link, index) => (
              <LinkList.Item
                key={index}
                link={link}
                onClick={val => handleLinkEdit(val)}
                onDelete={val => handleLinkDelete(val)}
              />
            ))}
          </LinkList>
        )}
        <Button
          text="Add Link"
          onClick={handleNewLink}
          color={Swatches.Primary}
          size={Size.Small}
        />
      </Wrapper>
      <LinkFlyout
        link={selectedLink}
        onCancel={handleCancelFlyout}
        onSaved={handleSave}
      />
    </>
  );
};

export default LinkPicker;
