import React from "react";
import {
  Size,
  Loader,
  EmptyMessage,
  Swatches,
  Card,
  Chip,
  ActionPanel,
  ChipList,
} from "ui-kit";
import { useNavigate } from "react-router-dom";
import { arrays, users } from "utils";
import { consentRoutes } from "areas/administration/adminRoutes";
import { ParentView } from "sharedComponents/common/users/userTypeView";
import { ConsentFormUserView } from "areas/administration/types/consentResponses.types";


interface INewConsentFormListProps {
  forms: ConsentFormUserView[];
  loading?: boolean;
  error?: string;
}


const NewConsentFormList: React.FC<INewConsentFormListProps> = ({ forms, loading, error }) => {

  const navigate = useNavigate();

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (arrays.isEmpty(forms)) {
    return (
      <EmptyMessage
        title="No forms available"
        summary="There are no consent forms for you to complete"
        icon="clipboard-check"
        cover
      />
    );
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem while loading the forms"
        icon="times-circle"
        iconColor={Swatches.Danger.swatch}
        cover
      />
    );
  }

  return (
    <>
      <Card transparent>
        <Card.Body>
          <p>
            The consent forms below are available for you to complete. For any
            questions about these forms or how to complete them, please contact
            your school.
          </p>
        </Card.Body>
      </Card>

      {forms.map((form, index) => (
        <ActionPanel
          key={index}
          label={form.name}
          sub={form.summary}
          buttonText="Complete Form"
          buttonColor={Swatches.Primary}
          onClick={() => navigate(consentRoutes.getConsentFormPath(form.id))}
        >
          <ParentView>
            {!arrays.isEmpty(form.users) && (
              <ChipList>
                {form.users.map((u, i) => (
                  <Chip key={i} text={users.getFullName(u.user)} />
                ))}
              </ChipList>
            )}
          </ParentView>
        </ActionPanel>
      ))}
    </>
  );
};

export default NewConsentFormList;
