import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  ActionBar,
  Swatches,
  Size,
  StructuredList,
  TextInput,
  TextInputType,
  DatePicker,
  Card,
  Loader,
  Message,
  ValidationMessage,
} from "ui-kit";
import { arrays } from "utils";
import managedMovesActions from "areas/planner/actions/managedMoves/managedMovesActions";
import StudentPicker from "sharedComponents/common/users/studentPicker";
import Avatar from "sharedComponents/common/users/avatar";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import { ManagedMoveDetailView, ManagedMoveListView } from "areas/planner/types/managedMoves/managedMovedResponse.types";
import { UserListView } from "types/users/userListViews.types";
import { useAppSelector } from "reducers/hooks";
import moment from "moment";
import { ApiExceptionMessage } from "sharedComponents/common";


interface IManagedMoveEditorModalProps {
  open: boolean
  move: ManagedMoveListView;
  student: UserListView;
  onClose: (move: ManagedMoveListView) => void;
}


const ManagedMoveEditorModal: React.FC<IManagedMoveEditorModalProps> = ({
  open,
  move: initialMove,
  student,
  onClose,
}) => {

  const { move, loading, error, saving, saveError } = useAppSelector(
    state => state.managedMove
  );

  const [_move, _setMove] = useState<ManagedMoveDetailView>(null);
  const [_editStudent, _setEditStudent] = useState<boolean>(true);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    if (initialMove?.id) {
      managedMovesActions.getManagedMove(
        initialMove.student.id,
        initialMove.id,
        data => _setMove(data)
      );
    } else {
      _setMove({
        ...initialMove,
        notes: null,
        createdBy: null,
        updatedBy: null
      });
    }
  }, [initialMove]);

  const handleStudentChange = (value: UserListView) => {
    _setMove({ ..._move, newStudent: value });
    _setEditStudent(false);
  };

  const handleClose = () => {
    onClose?.(_move);
  };

  const handleSaveManagedMove = () => {
    // Validate
    var errors = [];

    if (_move.hostSchool?.id == null) {
      errors.push("Please choose a host school.");
    }

    // if (
    //   _move.newStudent != null &&
    //   _move.newStudent.school.id != _move.hostSchoolId
    // ) {
    //   errors.push(
    //     "The host school does not match the school for the linked student account."
    //   );
    // }

    if (_move.startDate == null) {
      errors.push("Please choose a start date.");
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    // Save...
    var data = {
      id: _move.id,
      hostSchoolId: _move.hostSchool?.id,
      studentId: _move.student.id,
      newStudentId: _move.newStudent?.id,
      startDate: _move.startDate,
      notes: _move.notes,
    };

    if (_move.id) {
      managedMovesActions.updateManagedMove(data, () => {
        onClose?.(_move);
      });
    } else {
      managedMovesActions.createManagedMove(data, () => {
        console.log(_move);
        onClose?.(_move);
      });
    }
  };

  return (
    <Modal open={open} onClose={handleClose} title="Move" height="90%">
      <Modal.Body>
        <ApiExceptionMessage error={saveError} />
        <ApiExceptionMessage error={error} />
        <ValidationMessage errors={validationErrors} />

        {loading ? (
          <Loader cover size={Size.Large} fluid />
        ) : (
          <Card>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Student">
                  <Avatar
                    user={_move?.student}
                    sub={_move?.student.school.name}
                  />
                </StructuredList.Item>
                <StructuredList.Item name="Host School" required>
                  <SchoolDropdown
                    fluid
                    initialValue={_move?.hostSchool?.id}
                    onChange={value =>
                      _setMove({ ..._move, hostSchool: {
                        id: value,
                        name: "",
                        code: "",
                        isPrimary: false
                      } })
                    }
                    showAllSchools
                    filter={school => school.id !== student.school.id}
                  />
                </StructuredList.Item>
                <StructuredList.Item
                  name="Linked Student"
                  description="If the student has a Beehive account at the host school, you can select it here."
                  rightContent={
                    _move?.newStudent && (
                      <Button
                        text="Change"
                        onClick={() => _setMove({ ..._move, newStudent: null })}
                        size={Size.Small}
                        color={Swatches.Primary}
                      />
                    )
                  }
                >
                  {!_move?.newStudent ? (
                    <StudentPicker onChange={handleStudentChange} fluid />
                  ) : (
                    <Avatar
                      user={_move?.newStudent}
                      sub={_move?.newStudent?.school?.name}
                    />
                  )}
                </StructuredList.Item>
                <StructuredList.Item name="Start Date" required>
                  <DatePicker
                    fluid
                    selectedDate={_move?.startDate}
                    onChange={value => _setMove({ ..._move, startDate: moment(value).format("YYYY-MM-DD") })}
                    closeOnSelect
                  />
                </StructuredList.Item>
                <StructuredList.Item name="Notes">
                  <TextInput
                    fluid
                    type={TextInputType.Textarea}
                    value={_move?.notes}
                    onChange={value => _setMove({ ..._move, notes: value })}
                  />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Button
            text="Save"
            onClick={handleSaveManagedMove}
            color={Swatches.Success}
            size={Size.Small}
            working={saving}
          />
          <Button
            text="Cancel"
            onClick={handleClose}
            color={Swatches.Low}
            size={Size.Small}
            working={saving}
          />
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default ManagedMoveEditorModal;
