//@ts-nocheck
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";

export interface IUserViewProps {
  children?: React.ReactElement | React.ReactElement[];
  user: IUser;
  inverse?: boolean;
  adminView?: boolean;
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  title: string;
}

const UserView: React.FC<IUserViewProps> = ({
  children,
  user,
  inverse,
  adminView,
}) => {
  const { user: currentUser } = useSelector(
    (state: RootState) => state.currentUser
  );

  const canShow =
    (user && user.id === currentUser.id && !inverse) ||
    (user && user.id !== currentUser.id && inverse);
  return canShow || adminView ? children : null;
};

export default UserView;
