import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import { arrays } from "utils";
import {
  Table,
  SplitButton,
  Loader,
  Size,
  EmptyMessage,
  Button,
  Swatches,
  ToastService,
  Message,
} from "ui-kit";
import ProviderFlyout from "./providerFlyout";
import { useNavigate } from "react-router";
import flyoutActions from "actions/ui/flyouts";
import providerActions from "areas/send/actions/providers/providerActions";
import { sendRoutes } from "areas/send/sendRoutes";
import { ProviderListView } from "areas/send/types/passportResponse.types";
import { RootState } from "reducers/store";


interface IProvidersTableProps {
  providers: ProviderListView[];
  loading?: boolean;
  error?: string;
}


const DEFAULT_PROVIDER: ProviderListView = {
  id: null,
  name: "",
  description: "",
  hasProvisions: false
};

const ProvidersTable: React.FC<IProvidersTableProps> = ({ providers, loading, error }) => {

  const navigate = useNavigate();
  const { deleteError, deleting } = useSelector((state: RootState) => state.provider);
  const [_selectedProvider, _setSelectedProvider] = useState<ProviderListView | null>(null);

  const handleAdd = () => {
    _setSelectedProvider(DEFAULT_PROVIDER);
    flyoutActions.openFlyout(Constants.FLYOUTS.SEND_PROVIDER);
  };

  const handleEdit = (provider: ProviderListView) => {
    _setSelectedProvider(provider);
    flyoutActions.openFlyout(Constants.FLYOUTS.SEND_PROVIDER);
  };

  const handleDelete = (provider: ProviderListView) => {
    if (
      window.confirm(
        "This will delete the selected provider. This cannot be undone. Are you sure?"
      )
    ) {
      providerActions.deleteProvider(provider.id, () => {
        ToastService.pop("Provider Deleted", null, "hand-holding-seedling");
      });
    }
  };

  const handleViewDetails = (provider: ProviderListView) => {
    navigate(sendRoutes.getProviderPath(provider.id));
  };

  const handleSaved = (provider: ProviderListView) => {
    _setSelectedProvider(null);
  };

  if (loading) {
    return <Loader cover size={Size.Medium} />;
  }

  if (error) {
    return <EmptyMessage icon="times-circle" title="An error occurred" cover />;
  }

  return (
    <>
      {arrays.isEmpty(providers) ? (
        <EmptyMessage
          icon="hand-holding-seedling"
          title="No Providers"
          summary="No providers have been set up yet"
          cover
        >
          <Button
            size={Size.Small}
            text="Add New Provider"
            onClick={handleAdd}
            color={Swatches.Primary}
          />
        </EmptyMessage>
      ) : (
        <>
          <Message text={deleteError} color={Swatches.Danger} />

          <Table>
            <Table.Header>
              <Table.HeaderCell>Provider</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Header>
            <Table.Body>
              {providers.map((provider: ProviderListView, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell width={8.5}>{provider.name}</Table.Cell>
                  <Table.Cell width={1.5} right>
                    <SplitButton
                      color={Swatches.Low}
                      text="View Details"
                      onDefaultClick={() => handleViewDetails(provider)}
                      working={deleting}
                    >
                      <SplitButton.Option
                        text="View Details"
                        onClick={() => handleViewDetails(provider)}
                        show
                      />
                      <SplitButton.Option
                        text="Edit"
                        onClick={() => handleEdit(provider)}
                        show
                      />
                      <SplitButton.Option
                        text="Delete"
                        onClick={() => handleDelete(provider)}
                        show={!provider?.hasProvisions}
                        color={Swatches.Danger}
                      />
                    </SplitButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.Cell colspan={2} right>
                  <Button
                    size={Size.Small}
                    text="Add New Provider"
                    onClick={handleAdd}
                    color={Swatches.Primary}
                    working={deleting}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </>
      )}
      <ProviderFlyout
        onClose={() => _setSelectedProvider(null)}
        provider={_selectedProvider}
        onSave={handleSaved}
      />
    </>
  );
};

export default ProvidersTable;
