import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import store from "reducers/store";
import { urls } from "utils";
import moment from "moment";
import client from "services/client";
import { ManagedMoveDetailView, ManagedMoveListView, ManagedMoveSummaryView } from "areas/planner/types/managedMoves/managedMovedResponse.types";
import { callbackType, IBeehiveAction } from "types/common/action.types";
import { CloseManagedMoveCommand, ManagedMoveQueryFilter, ManagedMoveSummaryFilter, SaveManagedMoveCommand } from "areas/planner/types/managedMoves/managedMovesRequest.types";


const builder = new actionTypeBuilder("managedmoves");

const types = {
  MANAGEDMOVES_REFRESH: "MANAGEDMOVES_REFRESH",
  MANAGEDMOVES_GETALL: builder.build("ALL_GETALL"),
  MANAGEDMOVES_GETSUMMARY: builder.build("MANAGEDMOVES_GETSUMMARY"),
  MANAGEDMOVES_GETMOVE: builder.build("MANAGEDMOVES_GETMOVE"),
  MANAGEDMOVES_GETFORSTUDENT: builder.build("MANAGEDMOVES_GETFORSTUDENT"),
  MANAGEDMOVES_CREATEMOVE: builder.build("MANAGEDMOVES_CREATEMOVE"),
  MANAGEDMOVES_UPDATEMOVE: builder.build("MANAGEDMOVES_UPDATEMOVE"),
  MANAGEDMOVES_CLOSEMOVE: builder.build("MANAGEDMOVES_CLOSEMOVE"),
  MANAGEDMOVES_DELETEMOVE: builder.build("MANAGEDMOVES_DELETEMOVE"),
};


const refreshManagedMoves = (move: ManagedMoveListView) => {
  store.dispatch<IBeehiveAction<ManagedMoveListView>>({
    type: types.MANAGEDMOVES_REFRESH,
    payload: move,
  });
};


const getManagedMoves = (
  filter: ManagedMoveQueryFilter, 
  callback?: callbackType<ManagedMoveListView[]>
) => {
  let url = new urls.QueryString("planner/managedmoves");
  url.addParams(filter);

  return action<ManagedMoveListView[]>(
    () => client.get(url.toUrl()),
    types.MANAGEDMOVES_GETALL,
    callback
  );
};


const getManagedMovesSummary = (
  filter: ManagedMoveSummaryFilter, 
  callback?: callbackType<ManagedMoveSummaryView>
) => {
  let url = new urls.QueryString("planner/managedmoves/summary");
  url.addParams(filter);

  return action<ManagedMoveSummaryView>(
    () => client.get(url.toUrl()),
    types.MANAGEDMOVES_GETSUMMARY,
    callback
  );
};


const getManagedMovesForStudent = (
  studentId: string, 
  callback?: callbackType<ManagedMoveListView[]>
) =>
  action<ManagedMoveListView[]>(
    () => client.get(`planner/students/${studentId}/managedmoves`),
    types.MANAGEDMOVES_GETFORSTUDENT,
    callback
  );


const getManagedMove = (
  studentId: string, 
  id: number, 
  callback?: callbackType<ManagedMoveDetailView>
) =>
  action<ManagedMoveDetailView>(
    () => client.get(`planner/students/${studentId}/managedmoves/${id}`),
    types.MANAGEDMOVES_GETMOVE,
    callback
  );


const createManagedMove = (
  data: SaveManagedMoveCommand, 
  callback?: callbackType<null>
) =>
  action<null>(
    () => client.post(`planner/students/${data.studentId}/managedmoves`, data),
    types.MANAGEDMOVES_CREATEMOVE,
    callback
  );


const updateManagedMove = (
  data: SaveManagedMoveCommand, 
  callback?: callbackType<null>
) =>
  action<null>(
    () =>
      client.post(
        `planner/students/${data.studentId}/managedmoves/${data.id}`,
        data
      ),
    types.MANAGEDMOVES_UPDATEMOVE,
    callback
  );


const closeManagedMove = (
  data: CloseManagedMoveCommand, 
  callback?: callbackType<ManagedMoveDetailView>
) =>
  action<ManagedMoveDetailView>(
    () =>
      client.post(
        `planner/students/${data.studentId}/managedmoves/${data.id}/close`,
        data
      ),
    types.MANAGEDMOVES_CLOSEMOVE,
    callback
  );


const deleteManagedMove = (
  studentId: string, 
  id: number, 
  callback?: callbackType<null>
) =>
  action<null>(
    () => client.delete(`planner/students/${studentId}/managedmoves/${id}`),
    types.MANAGEDMOVES_DELETEMOVE,
    callback
  );


const managedMovesActions = {
  types,
  refreshManagedMoves,
  getManagedMoves,
  getManagedMovesSummary,
  getManagedMovesForStudent,
  getManagedMove,
  createManagedMove,
  updateManagedMove,
  closeManagedMove,
  deleteManagedMove,
};

export default managedMovesActions;
