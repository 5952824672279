import React, { useState, useEffect } from "react";
import {
  StructuredList,
  Card,
  RadioList,
  ActionBar,
  Button,
  Swatches,
  Checkbox,
  Size,
} from "ui-kit";
import DateRange from "../dateRange";
import BehaviourCodeFilters from "../behaviour/behaviourCodeFilters";
import Search, {
  SEARCH_GROUP_TYPE_KEYS,
} from "sharedComponents/common/search/search";
import { Constants } from "configuration";
import { arrays } from "utils";
import AcademicYearFilter from "sharedComponents/common/academicYearFilter";
import { GroupListView } from "types/users/userGroups.types";
import { useAppSelector } from "reducers/hooks";
import { CategoryType } from "areas/analytics/types/analytics.shared";
import { BehaviourIncludeType } from "areas/analytics/types/behaviourAnalyticsShared.types";
import { SchoolBehaviourCodeListView } from "areas/behaviour/types/behaviourResponses.types";
import { SearchResultViewBase } from "types/common/views.types";

enum FILTERS {
  BEHAVIOUR_CODE = 0,
  TUTOR_GROUP = 1,
  CLASS_GROUP = 2
};

const TIME_INTERVALS = [
  {
    key: 0,
    label: "15 minutes",
    value: 15,
  },
  {
    key: 1,
    label: "30 minutes",
    value: 30,
  },
  {
    key: 2,
    label: "45 minutes",
    value: 45,
  },
  {
    key: 3,
    label: "60 minutes",
    value: 60,
  },
  {
    key: 4,
    label: "75 minutes",
    value: 75,
  },
  {
    key: 5,
    label: "90 minutes",
    value: 90,
  },
];


interface IStudentBehaviourReportFormProps {
  handleGenerateReport: (
    startDate: Date, 
    endDate: Date, 
    categoryType: BehaviourIncludeType, 
    academicYearId: number, 
    codeId: number, 
    tutorGroupId: number, 
    classGroupId: number, 
    exportToPdf: boolean
  ) => void;
  studentTutorGroup: GroupListView;
  schoolId: number;
}


const StudentBehaviourReportForm: React.FC<IStudentBehaviourReportFormProps> = ({
  handleGenerateReport,
  studentTutorGroup,
  schoolId,
}) => {

  const { behaviourCodes } = useAppSelector(state => state.behaviourCodes);
  const { rewardCodes } = useAppSelector(state => state.rewardCodes);
  const { loading: loadingReport, exporting: exportingReport } = useAppSelector(state => state.studentBehaviourReport);

  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [categoryTypeId, setCategoryTypeId] = useState<BehaviourIncludeType>(null);
  const [categoryTypeName, setCategoryTypeName] = useState<string>(null);
  const [academicYearId, setAcademicYearId] = useState<number>(
    Constants.ACADEMIC_YEAR_FILTER_ARRAY[0].value
  );
  const [filterByCodeChecked, setFilterByCodeChecked] = useState<boolean>(false);
  const [filterByTutorGroupChecked, setFilterByTutorGroupChecked] = useState<boolean>(false);

  const [filters, setFilters] = useState<{ classGroups: SearchResultViewBase[], codes: SchoolBehaviourCodeListView[]}>({
    //tutorGroups: [],
    classGroups: [],
    codes: [],
  });

  const [timeInterval, setTimeInterval] = useState(null);

  useEffect(() => {
    setCategoryTypeName(
      categoryTypeId === BehaviourIncludeType.Reward
        ? "Reward"
        : categoryTypeId === BehaviourIncludeType.Behaviour
        ? "Behaviour"
        : "Reward & Behaviour"
    );
  }, [categoryTypeId]);

  const handleCodeClick = (code: SchoolBehaviourCodeListView, isChecked: boolean) => {
    handleFilterChange(code, FILTERS.BEHAVIOUR_CODE, isChecked);
  };

  const handleFilterByCodeClick = () => {
    if (filterByCodeChecked === true) {
      setFilters({ ...filters, codes: [] });
    }
    setFilterByCodeChecked(!filterByCodeChecked);
  };

  const handleFilterChange = (
    filter: SchoolBehaviourCodeListView | SearchResultViewBase, 
    filterType: FILTERS, 
    isSelected: boolean
  ) => {
    switch (filterType) {
      case FILTERS.BEHAVIOUR_CODE:
        setFilters({
          ...filters,
          codes: isSelected ? [filter as SchoolBehaviourCodeListView] : [],
        });
        break;

      // case FILTERS.TUTOR_GROUP:
      //   setFilters({
      //     ...filters,
      //     tutorGroups: isSelected ? [filter] : [],
      //   });
      //   break;

      case FILTERS.CLASS_GROUP:
        setFilters({
          ...filters,
          classGroups: isSelected ? [filter as SearchResultViewBase] : [],
        });
        break;

      default:
        break;
    }
  };

  const generateReport = (exportToPfd: boolean) => {
    handleGenerateReport?.(
      startDate,
      endDate,
      categoryTypeId,
      academicYearId,
      !arrays.isEmpty(filters.codes) ? filters.codes[0].id : null,
      filterByTutorGroupChecked ? studentTutorGroup.id : null,
      !arrays.isEmpty(filters.classGroups)
        ? Number(filters.classGroups[0].id)
        : null,
      exportToPfd
    )
  }

  return (
    <>
      <StructuredList>
        <Card title="Date Range">
          <Card.Body>
            <StructuredList.Item name="Academic Year" required>
              <AcademicYearFilter
                value={academicYearId}
                onChange={value => setAcademicYearId(value)}
              />
            </StructuredList.Item>
            <DateRange
              startDate={startDate}
              handleStartDateChange={value => setStartDate(value)}
              endDate={endDate}
              handleEndDateChange={value => setEndDate(value)}
            />
          </Card.Body>
        </Card>

        <Card title="Behaviour Type">
          <Card.Body>
            <StructuredList.Item
              name="Behaviours to Include in Report"
              description="Choose the points that you want to include in your report"
              helpPopup
              required
            >
              <RadioList
                value={categoryTypeId}
                onChange={value => setCategoryTypeId(value)}
                fluid
              >
                <RadioList.Item
                  label="Rewards &amp; Behaviours"
                  value={null}
                />
                <RadioList.Item
                  label="Rewards"
                  value={CategoryType.Reward}
                />
                <RadioList.Item
                  label="Behaviours"
                  value={CategoryType.Behaviour}
                />
              </RadioList>
            </StructuredList.Item>
          </Card.Body>
        </Card>

        <Card title="Filters">
          <Card.Body>
            <StructuredList.Item
              name={`${categoryTypeName} Codes`}
              description="Filter to generate a report for only selected behaviour or reward codes"
              helpPopup
            >
              <BehaviourCodeFilters
                behaviourTypesToInclude={categoryTypeId}
                behaviourTypesToIncludeName={categoryTypeName}
                filterByPointCode={filterByCodeChecked}
                handleFilterbyPointCodeClick={handleFilterByCodeClick}
                rewardCodes={rewardCodes}
                behaviourCodes={behaviourCodes}
                selectedCodes={filters.codes}
                handleCodeClick={handleCodeClick}
              />
            </StructuredList.Item>
            {studentTutorGroup && (
              <StructuredList.Item
                name="Tutor Group"
                description="Filter to view points given in tutor group"
                helpPopup
              >
                {/* <Search
                groupTypes={[Constants.GROUP_TYPES.TUTOR]}
                selected={filters.tutorGroups}
                handleSelectItem={filter =>
                  handleFilterChange(filter, FILTERS.TUTOR_GROUP, true)
                }
                handleDeleteItem={filter =>
                  handleFilterChange(filter, FILTERS.TUTOR_GROUP, false)
                }
              /> */}

                <Checkbox
                  text={`${studentTutorGroup.name}`}
                  checked={filterByTutorGroupChecked}
                  onChange={() =>
                    setFilterByTutorGroupChecked(!filterByTutorGroupChecked)
                  }
                />
              </StructuredList.Item>
            )}

            <StructuredList.Item
              name="Class Group"
              description="Filter to view points given in a class group"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.CLASS_GROUP]}
                selected={filters.classGroups}
                handleSelectItem={filter =>
                  handleFilterChange(filter, FILTERS.CLASS_GROUP, true)
                }
                handleDeleteItem={filter =>
                  handleFilterChange(filter, FILTERS.CLASS_GROUP, false)
                }
                schoolId={schoolId}
              />
            </StructuredList.Item>
          </Card.Body>
        </Card>

        {/* <Card title="Time Interval">
          <Card.Body>
            <StructuredList.Item name="Time Interval">
                <Dropdown
                  placeholder="Minutes"
                  fluid
                  items={TIME_INTERVALS}
                  onChange={value => setTimeInterval(value)}
                  value={timeInterval}
                  clearable
                />
            </StructuredList.Item>
          </Card.Body>
        </Card> */}

        <ActionBar>
          <Button
            text="Generate Report"
            color={Swatches.Success}
            size={Size.Small}
            onClick={() => generateReport(false)}
            working={loadingReport}
          />
          <Button
            text="Export to PDF"
            color={Swatches.Primary}
            size={Size.Small}
            onClick={() => generateReport(true)}
            working={exportingReport}
          />
        </ActionBar>
      </StructuredList>
    </>
  );
};

export default StudentBehaviourReportForm;
