import senAdminActions from "areas/send/actions/senAdministration/senAdministrationActions";
import { Constants } from "configuration";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { MultiSchoolUserView, SchoolDropdown } from "sharedComponents/common";
import Unauthorized from "sharedComponents/layout/fallback/unauthorized";
import { PublishStatus } from "types/common/status.types";
import { ButtonBar, Button, Swatches, Size, ActionBar, Card, StructuredList, HeadlineStatistic, Loader, Message, ToastService } from "ui-kit";
import { AlignmentX } from "ui-kit/common/size";
import AlertModal from "ui-kit/modules/alertModal";
import OkayModal from "ui-kit/modules/okayModal";
import { schools, users } from "utils";


interface AlertContent {
  body: React.ReactNode | React.ReactNode[];
  action: () => void;
}

const SendAdmin: React.FC = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { schoolSenOverview, overviewLoading, overviewError, passportActionLoading, passportActionError } = useSelector((state: RootState) => state.sendAdmin);
  const [alertContent, setAlertContent] = useState<AlertContent | null>(null);
  const [selectedSchool, setSelectedSchool] = useState<number>(-1);

  useEffect(() => {
    if (selectedSchool > 0) {
      senAdminActions.getAdminOverview(selectedSchool);
    }
  }, [selectedSchool])

  const changeLatestPassportStatusForSchool = (status: PublishStatus) => {
    closeAlert();
    senAdminActions.changeSchoolPassportsStatus(
      { 
        schoolId: selectedSchool, 
        status: status 
      }, 
      () => {
        ToastService.pop(
          `Latest passport versions for ${schools.getSchoolById(selectedSchool)?.name} have been set to ${PublishStatus[status]}`, 
          null, 
          "hand-holding-seedling"
        );
      }
    )
  }

  const openPublishAlert = () => {
    setAlertContent({
      body: (
        <>
          <p><b>Warning:</b> This will publish the latest version of all pupil passports for {schools.getSchoolById(selectedSchool)?.name}. </p>
          <p>Please confirm with your staff if all pupil passports are in a state to proceed.</p>
        </>
      ),
      action: () => changeLatestPassportStatusForSchool(PublishStatus.Live)
    });
  }

  const openUnpublishAlert = () => {
    setAlertContent({
      body: (
        <>
          <p><b>Warning:</b> This will unpublish the latest version of all pupil passports for {schools.getSchoolById(selectedSchool)?.name}. </p>
        </>
      ),
      action: () => changeLatestPassportStatusForSchool(PublishStatus.Draft)
    });
  }

  const closeAlert = () => {
    setAlertContent(null);
  }

  if (!users.isInRole(user, Constants.ROLES.SEN_ADMINISTRATOR)) {
    return <Unauthorized />;
  }

  return (
    <>
      <ActionBar>
        <MultiSchoolUserView>
          <SchoolDropdown
            initialValue={selectedSchool}
            placeholder="School"
            onChange={(value) => setSelectedSchool(value)}
            fluid
          />
        </MultiSchoolUserView>
      </ActionBar>

      { overviewError && <Message text={overviewError} color={Swatches.Danger} /> }
      { passportActionError && <Message text={passportActionError} color={Swatches.Danger} /> }

      { selectedSchool > 0 && (
        overviewLoading ? (
          <Loader size={Size.Medium} fluid />
        ) : (
          <>
          <Card title={"Passports"}>
      
            <HeadlineStatistic>
              <HeadlineStatistic.Item
                icon="hand-holding-seedling"
                label={`Total`}
                value={schoolSenOverview?.passportsOverview?.totalCount}
              />
              <HeadlineStatistic.Item
                icon="hand-holding-seedling"
                label={`Published`}
                value={schoolSenOverview?.passportsOverview?.publishedCount}
              />
              <HeadlineStatistic.Item
                icon="hand-holding-seedling"
                label={`Unpublished`}
                value={schoolSenOverview?.passportsOverview?.unpublishedCount}
              />
            </HeadlineStatistic>
            
            <Card.Body>
              <StructuredList>
                <StructuredList.Item
                  name="Publish All"
                  description="This will publish all pupil passports for the current academic year."
                >
                  <Button
                    text="Publish All"
                    onClick={openPublishAlert}
                    color={Swatches.Primary}
                    size={Size.Small}
                    working={passportActionLoading}
                  />
                </StructuredList.Item>
                <StructuredList.Item
                  name="Unpublish All"
                  description="This will unpublish all pupil passports for the current academic year."
                >
                  <Button
                    text="Unpublish All"
                    onClick={openUnpublishAlert}
                    color={Swatches.Primary}
                    size={Size.Small}
                    working={passportActionLoading}
                  />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
          </>
        )
      )}

      <OkayModal 
        open={alertContent != null}
        body={alertContent?.body}
        onCancel={() => closeAlert()}
        onConfirm={() => alertContent?.action()}
      />
      
    </>
  )
}

export default SendAdmin;