import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import FileDownload from "js-file-download";
import { BeehiveReport, callbackType } from "types/common/action.types";
import { GenerateAwardReportCommand } from "../types/awardAnalyticsRequests.types";
import { GenerateAwardReportView } from "../types/awardAnalyticsShared.types";

const builder = new actionTypeBuilder("awardAnalytics");

const types = {
  GETAWARDREPORT: builder.build("getawardreport"),
  EXPORTAWARDREPORT: builder.build("exportawardreport"),
};


const getAwardReport = (payload: GenerateAwardReportCommand, onSuccess?: callbackType<GenerateAwardReportView[]>) => {
  return action(
    () => client.post(`planner/reporting/awards`, payload),
    types.GETAWARDREPORT,
    onSuccess
  );
};

const exportAwardReport = (payload: GenerateAwardReportCommand, onSuccess?: callbackType<BeehiveReport>) => {
  return action<BeehiveReport>(
    () =>
      client.post(`planner/reporting/awards/export`, payload, {
        responseType: "blob",
      }),
    types.EXPORTAWARDREPORT,
    response => {
      onSuccess && onSuccess(response);
      FileDownload(response, "Awards Report.csv");
    }
  );
};


const awardAnalyticsActions = { 
  types, 
  getAwardReport, 
  exportAwardReport 
};

export default awardAnalyticsActions;
