import React from "react";
import { Subtitle, TitleSize, Checkbox } from "ui-kit";
import styled from "styled-components";
import BehaviourCategoryCheckbox from "./behaviourCategoryCheckbox";
import { BehaviourIncludeType } from "areas/analytics/types/behaviourAnalyticsShared.types";
import { BehaviourCategoryGroupByListView, SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";


const CheckboxListsWrapper = styled.div`
  display: flex;

  .behaviours-list,
  .rewards-list {
    width: 50%;

    .title {
      width: 100%;
    }
  }
`;


interface ICheckboxesForCategoriesProps {
  categories: BehaviourCategoryGroupByListView[];
  selectedCategories: SchoolBehaviourCategoryListView[];
  handleCategoryClick: (category: SchoolBehaviourCategoryListView, isChecked: boolean) => void;
}

const CheckboxesForCategories: React.FC<ICheckboxesForCategoriesProps> = ({
  categories,
  selectedCategories,
  handleCategoryClick,
}) => {
  return categories.map(categoryGroup => (
    <>
      <Subtitle
        text={categoryGroup.behaviourCodeName}
      />
      {categoryGroup.behaviourCategories.map(category => ( //SchoolBehaviourCategoryListView
        <BehaviourCategoryCheckbox
          category={category}
          selectedBehaviourCategories={selectedCategories}
          handleCategoryClick={handleCategoryClick}
        />
      ))}
      <br />
    </>
  ));
};


interface IBehaviourCategoryFiltersProps {
  behaviourTypesToInclude: BehaviourIncludeType;
  behaviourTypesToIncludeName: string;
  filterByPointCategory: boolean;
  handleFilterbyPointCategoryClick: () => void;
  rewardCategoryGroups: BehaviourCategoryGroupByListView[];
  selectedCategories: SchoolBehaviourCategoryListView[];
  handleCategoryClick: (category: SchoolBehaviourCategoryListView, isChecked: boolean) => void;
  behaviourCategoryGroups: BehaviourCategoryGroupByListView[];
}

const BehaviourCategoryFilters: React.FC<IBehaviourCategoryFiltersProps> = ({
  behaviourTypesToInclude,
  behaviourTypesToIncludeName,
  filterByPointCategory,
  handleFilterbyPointCategoryClick,
  rewardCategoryGroups,
  selectedCategories,
  handleCategoryClick,
  behaviourCategoryGroups,
}) => {
  return (
    <>
      <Checkbox
        text={`Filter by ${behaviourTypesToIncludeName} Categories`}
        checked={filterByPointCategory}
        onChange={handleFilterbyPointCategoryClick}
      />
      {filterByPointCategory && (
        <>
          <br />
          {behaviourTypesToInclude === BehaviourIncludeType.Reward ? (
            <CheckboxesForCategories
              categories={rewardCategoryGroups}
              selectedCategories={selectedCategories}
              handleCategoryClick={handleCategoryClick}
            />
          ) : behaviourTypesToInclude === BehaviourIncludeType.Behaviour ? (
            <CheckboxesForCategories
              categories={behaviourCategoryGroups}
              selectedCategories={selectedCategories}
              handleCategoryClick={handleCategoryClick}
            />
          ) : (
            <CheckboxListsWrapper>
              <div className="rewards-list">
                <CheckboxesForCategories
                  categories={rewardCategoryGroups}
                  selectedCategories={selectedCategories}
                  handleCategoryClick={handleCategoryClick}
                />
              </div>
              <div className="behaviours-list">
                <CheckboxesForCategories
                  categories={behaviourCategoryGroups}
                  selectedCategories={selectedCategories}
                  handleCategoryClick={handleCategoryClick}
                />
              </div>
            </CheckboxListsWrapper>
          )}
        </>
      )}
    </>
  );
};

export default BehaviourCategoryFilters;
