import React from "react";
import styled from "styled-components";
import { Icon, Size, Spacing, neutral, BorderRadius } from "ui-kit";
import { honeygold } from "../../../ui-kit/common/colors";
import {
  displayFont,
  fontStyle,
  typescale,
  DetailLabel,
} from "../../../ui-kit";

const Wrapper = styled.div`
  margin: ${Spacing.Medium}px 0;
`;

const ItemWrapper = styled.div`
  background: ${neutral[100]};
  padding: ${Spacing.Medium}px;
  border-radius: ${BorderRadius.Default}px;
  margin-bottom: ${Spacing.Medium}px;

  .header {
    display: flex;
    align-items: center;

    .title-wrapper {
      flex-grow: 1;
      ${fontStyle(displayFont.medium, typescale.header4, neutral[700])}

      .title:hover {
        cursor: pointer;
        .detail-label {
          .label,
          .sub {
            color: ${honeygold};
          }
        }
      }
    }

    .expand-arrow {
      padding: ${Spacing.Medium}px;
      cursor: pointer;
      &:hover {
        .icon {
          color: ${honeygold};
        }
      }
    }
  }

  .accordion-item-content {
    margin-top: ${Spacing.Medium}px;
  }
`;

interface IAccordionProps {
  children:
    | React.ReactElement<IAccordionItemProps>
    | React.ReactElement<IAccordionItemProps>[];
}

const Accordion: React.FC<IAccordionProps> & {
  Item: typeof AccordionItem;
} = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

interface IAccordionItemProps {
  accordionItemKey: number;
  title: string;
  sub?: any;
  expandedItemKey: number;
  children: any;
  onClickItem: (accordionItemKey: number) => void;
  onClickAccordionItemArrow: (accordionItemKey: number) => void;
}

const AccordionItem: React.FC<IAccordionItemProps> = ({
  accordionItemKey,
  title,
  sub,
  expandedItemKey,
  children,
  onClickItem,
  onClickAccordionItemArrow,
}) => {
  return (
    <ItemWrapper>
      <div className="header">
        <div className="title-wrapper">
          <div className="title" onClick={() => onClickItem(accordionItemKey)}>
            <DetailLabel label={title} sub={sub} bold />
          </div>
        </div>
        <div
          className="expand-arrow"
          onClick={() => onClickAccordionItemArrow(accordionItemKey)}
        >
          <Icon
            value={`${
              accordionItemKey === expandedItemKey ? "angle-up" : "angle-down"
            }`}
            size={Size.Medium}
          />
        </div>
      </div>

      {accordionItemKey === expandedItemKey && (
        <div className="accordion-item-content">{children}</div>
      )}
    </ItemWrapper>
  );
};

Accordion.Item = AccordionItem;

export default Accordion;
