import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { Dropdown } from "ui-kit";
import { arrays } from "utils";


interface IProvisionMapDropdownProps {
  onChange?: (value: number) => void;
  value?: number
}


const ProvisionMapDropdown: React.FC<IProvisionMapDropdownProps> = ({ onChange, value }) => {

  const { loading, error, provisionMaps } = useSelector(
    (state: RootState) => state.provisionMaps
  );
  const [_value, _setValue] = useState<number | null>(null);

  useEffect(() => {
    _setValue(value);
  }, [value]);

  const handleValueChanged = (value: number) => {
    _setValue(value);
    onChange?.(value);
  };

  return arrays.isEmpty(provisionMaps) ? null : (
    <Dropdown
      onChange={handleValueChanged}
      value={_value}
      placeholder="Choose a Provision Map..."
    >
      {provisionMaps.map((map, index) => (
        <Dropdown.Item
          key={index}
          value={map.id}
          label={map.academicYearName}
        />
      ))}
    </Dropdown>
  );
};

export default ProvisionMapDropdown;
