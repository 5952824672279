import { useState } from "react";
import { formatDate, getDurationInHoursAndMins } from "utils/dateTime";
import {
  ActionBar,
  Button,
  Right,
  Size,
  Subtitle,
  Swatches,
  Table,
} from "ui-kit";
import ConfirmModal from "sharedComponents/common/confirmModal";
import AddSanctionDayButton from "../behaviours/pendingAttentionBehaviours/addSanctionDayButton";
import { IDetentionDetails } from "areas/behaviour/utils/detentions";


interface IMultipleSanctionsTableProps {
  sanctions: IDetentionDetails[];
  onClickAddAnotherDay: () => void;
  onClickEditSanction: (id: number) => void;
  onClickRemoveSanction: (id: number) => void;
  editable?: boolean;
}


const MultipleSanctionsTable: React.FC<IMultipleSanctionsTableProps> = ({
  sanctions,
  onClickAddAnotherDay,
  onClickEditSanction,
  onClickRemoveSanction,
  editable,
}) => {

  const [openConfirmRemove, setOpenConfirmRemove] = useState<boolean>(false);
  const [removeSanctionId, setRemoveSanctionId] = useState(null);

  const handleRemoveSanction = (id: number) => {
    setRemoveSanctionId(id);
    setOpenConfirmRemove(true);
  };

  return (
    <>
      <Subtitle text="Sanctions" />
      <Table>
        <Table.Header>
          <Table.HeaderCell width={0.5}>Date</Table.HeaderCell>
          <Table.HeaderCell width={0.5}>Time</Table.HeaderCell>
          <Table.HeaderCell width={1}>Duration</Table.HeaderCell>
          <Table.HeaderCell width={1}>Sanction</Table.HeaderCell>
          <Table.HeaderCell width={1}>Location</Table.HeaderCell>
          <Table.HeaderCell right width={2} hide={!editable}></Table.HeaderCell>
        </Table.Header>

        <Table.Body>
          {sanctions.map(sanction => (
            <Table.Row key={sanction.id}>
              <Table.Cell>
                <b>{formatDate(sanction.date)}</b>
              </Table.Cell>
              <Table.Cell>{sanction.time}</Table.Cell>
              <Table.Cell>
                {getDurationInHoursAndMins(sanction.duration)}
              </Table.Cell>
              <Table.Cell>{sanction.detentionTypeName}</Table.Cell>
              <Table.Cell>{sanction.detentionLocation}</Table.Cell>
              <Table.Cell right hide={!editable}>
                <Button
                  text="Edit"
                  size={Size.Small}
                  onClick={() => onClickEditSanction(sanction.id)}
                />{" "}
                <Button
                  text="Remove"
                  size={Size.Small}
                  onClick={() => handleRemoveSanction(sanction.id)}
                  color={Swatches.Danger}
                />
                {/* </ButtonBar> */}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        {onClickAddAnotherDay && (
          <Table.Footer>
            <Table.Cell colspan={editable ? 6 : 5}>
              <ActionBar low>
                <Right>
                  <AddSanctionDayButton onClick={onClickAddAnotherDay} />
                </Right>
              </ActionBar>
            </Table.Cell>
          </Table.Footer>
        )}
      </Table>

      <ConfirmModal
        openModal={openConfirmRemove}
        onClose={() => setOpenConfirmRemove(false)}
        onConfirm={() => {
          onClickRemoveSanction(removeSanctionId);
          setOpenConfirmRemove(false);
        }}
        confirmMsg="Are you sure you want to remove this sanction?"
      />
    </>
  );
};

export default MultipleSanctionsTable;
