import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  EmptyMessage,
  Swatches,
  Loader,
  Size,
  Left,
  Right,
  List,
  Spacing,
} from "ui-kit";
import { arrays } from "utils";
import styled from "styled-components";
import CourseContent from "./courseContent";
import { Constants } from "configuration";
import GroupListItem from "../groups/groupListItem";
import studentActions from "areas/planner/actions/student/studentActions";
import courseActions from "areas/planner/actions/courses/courseActions";

const Wrapper = styled.div`
  display: flex;

  > .left {
    min-width: 200px;
    max-width: 200px;
    margin-right: ${Spacing.Large}px;
    .list .left {
      flex-grow: 0;
    }
  }

  > .right {
    flex-grow: 1;
  }
`;

const Classes = () => {
  const { user } = useSelector(state => state.currentUser);
  const { studentClasses, loading, error } = useSelector(
    state => state.studentClasses
  );
  const {
    courses,
    loading: loadingCourses,
    error: coursesError,
  } = useSelector(state => state.classGroupCourses);
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  useEffect(() => {
    // Load classes
    studentActions.getStudentClasses(user.id, () => {});
  }, []);

  // useEffect(() => {
  //   selectedGroupId && getClassGroupCoursesAction(selectedGroupId);
  // }, [selectedGroupId]);

  const handleGroupClicked = group => {
    setSelectedGroupId(group.id);
    group.id &&
      group.type === Constants.GROUP_TYPES.CLASS &&
      courseActions.getClassGroupCourses(group.id);
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem while loading your classes"
        icon="times-circle"
        color={Swatches.Danger}
        cover
      />
    );
  }

  if (arrays.isEmpty(studentClasses)) {
    <EmptyMessage
      icon="users-class"
      title="No Classes"
      summary="You currently have no classes"
      cover
    />;
  }

  return (
    <Wrapper>
      <Left>
        <List>
          {studentClasses?.map((group, index) => (
            <GroupListItem
              group={group}
              selectedGroupId={selectedGroupId}
              handleGroupClicked={handleGroupClicked}
            />
          ))}
        </List>
      </Left>
      <Right>
        <CourseContent
          course={courses?.[0]}
          loading={loadingCourses}
          showTitle={true}
          error={coursesError}
        />
      </Right>
    </Wrapper>
  );
};

export default Classes;
