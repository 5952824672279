import timetableActions from "areas/planner/actions/timetableActions";
import { TermListView } from "areas/send/types/passportResponse.types";
import { ProvisionTimescale } from "areas/send/types/passportShared.types";
import Config from "configuration";
import config from "configuration/config";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { DateRange } from "types/common/data.types";
import { AcademicYearListView } from "types/schools/academicYears.types";
import { Dropdown } from "ui-kit";
import { arrays, dateTime, termsHelper } from "utils";


interface ITimescaleDropdownProps {
  value?: {timescale: ProvisionTimescale, dates: DateRange};
  academicYear: AcademicYearListView;
  onChange?: (value: ProvisionTimescale, data?: DateRange) => void;
  fluid?: boolean;
}

interface timeScaleOption {
  label: string;
  value: ProvisionTimescale;
  data?: DateRange;
}


const TimescaleDropdown: React.FC<ITimescaleDropdownProps> = ({
  value,
  academicYear,
  onChange,
  fluid,
}) => {

  const { terms } = useSelector((state: RootState) => state.terms);
  const [_options, _setOptions] = useState<timeScaleOption[]>([]);

  useEffect(() => {
    arrays.isEmpty(terms) 
      && timetableActions.getTerms(config.academicYear.current);
  }, []);

  useEffect(() => {
    if (!arrays.isEmpty(terms)) {
      var currentTerm = termsHelper.getCurrentTerm(terms);
      var previousTerm: TermListView;
      if (value && value.timescale === ProvisionTimescale.Term) {
        previousTerm = termsHelper.getByDateRange(terms, value.dates);
      }
      _setOptions(getOptions(currentTerm, previousTerm));
    }
  }, [terms, value]);


  const checkDatespanIsValid = (dateSpan: DateRange) : DateRange => {
    var endOfYear = dateTime.stringToDate(academicYear?.endDate);
    if (dateSpan.endDate > endOfYear) {
      return { startDate: dateSpan.startDate, endDate: endOfYear };
    }
    return dateSpan;
  }

  const getOptions = (currentTerm: TermListView, previousTerm?: TermListView): timeScaleOption[] => {
    return [
      { 
        label: "Month", 
        value: ProvisionTimescale.Month, 
        data: checkDatespanIsValid({ startDate: new Date(), endDate: moment().add(1, "month").toDate() })
      },
      (previousTerm 
        ? {
          label: `${previousTerm?.name} Term`, 
          value: ProvisionTimescale.Term, 
          data: checkDatespanIsValid({ startDate: moment(previousTerm?.startDate).toDate(), endDate: moment(previousTerm?.endDate).toDate() })
        }
        : {
          label: `${currentTerm?.name} Term`, 
          value: ProvisionTimescale.Term, 
          data: checkDatespanIsValid({ startDate: new Date(), endDate: moment(currentTerm?.endDate).toDate() })
        }
      ),
      { 
        label: "6 Months", 
        value: ProvisionTimescale.HalfYear,
        data: checkDatespanIsValid({ startDate: new Date(), endDate: moment().add(6, "month").toDate() })
      },
      { 
        label: "Year", 
        value: ProvisionTimescale.Year,
        data: checkDatespanIsValid({ startDate: new Date(), endDate: moment().add(1, "year").toDate() })
      },
      { 
        label: "Custom", 
        value: ProvisionTimescale.Custom 
      }
    ];
  }

  const handleChange = (value: ProvisionTimescale, label: string, data?: DateRange) => {
    onChange?.(value, data);
  }

  return (
    <Dropdown
      placeholder={"Please choose a timescale..."}
      fluid={fluid}
      value={value?.timescale}
      items={_options}
      onChange={handleChange}
    />
  );
};

export default TimescaleDropdown;
