import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ClassLayoutCreatorForm from "./classLayoutCreatorForm";
import ClassLayoutCreatorRoomDropdown from "./classLayoutCreatorRoomDropdown";
import {
  Size,
  Card,
  StructuredList,
  Loader,
  Button,
  Swatches,
  Dropdown,
  Message,
} from "ui-kit";
import styled from "styled-components";
import classRoomActions from "areas/planner/actions/classViewer/classRoomActions";
import RoomLayoutWizard from "../roomLayoutWizard/roomLayoutWizard";
import RoomLayoutOptions from "../roomLayoutWizard/roomLayoutOptions";
import { ApiExceptionMessage } from "../../../../../../sharedComponents/common";

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .button {
    width: fit-content;
  }
`;

const ClassLayoutCreator = ({
  group,
  schoolRooms,
  closeLayoutCreator,
  children,
  handleCreateClassLayout,
}) => {
  const { roomLayouts, loading, error, refresh } = useSelector(
    state => state.classViewerRoomLayouts
  );
  const { actions } = useSelector(state => state.classViewerRoomLayout);

  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [roomLayoutOption, setRoomLayoutOption] = useState(null);
  const [roomLayoutTemplates, setRoomLayoutTemplates] = useState([]);

  useEffect(() => {
    if (group.rooms && group.rooms.length > 0 && group.rooms[0] !== null) {
      setSelectedRoomId(group.rooms[0].roomId);
    }
  }, [group]);

  useEffect(() => {
    if (selectedRoomId) {
      classRoomActions.getRoomLayouts(group.school.id, selectedRoomId);
      setRoomLayoutOption(null);
    }
  }, [selectedRoomId]);

  useEffect(() => {
    if (refresh && selectedRoomId) {
      classRoomActions.getRoomLayouts(group.school.id, selectedRoomId);
    }
  }, [refresh]);

  useEffect(() => {
    const array = roomLayouts.map(layout => ({
      key: layout.id,
      id: layout.id,
      name: layout.name,
      rows: layout.length,
      cols: layout.width,
      slots: layout.roomSlots.map(slot => ({
        x: slot.coordinates.x,
        y: slot.coordinates.y,
        isASeat: slot.isAllowed,
      })),
    }));

    setRoomLayoutTemplates(array);
  }, [roomLayouts]);

  const handleRoomChange = value => {
    setSelectedRoomId(value);
  };

  const handleRoomLayoutChange = layout => {
    setRoomLayoutOption(layout);
  };

  const onCreateRoomLayout = roomLayoutId => {
    setRoomLayoutOption({ id: roomLayoutId });
  };

  return (
    <>
      {children}
      <Card>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item
              name="Room"
              description="Select a room for your layout"
              required
            >
              <ClassLayoutCreatorRoomDropdown
                classGroupRooms={group.rooms}
                selectedRoom={selectedRoomId}
                schoolRooms={schoolRooms}
                handleRoomChange={handleRoomChange}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Room Layout"
              description="Select a room layout from the list. You can choose Create New Room Layout to create a new room layout for your room."
              required
            >
              <>
                <ApiExceptionMessage error={error} />
                {loading ? (
                  <Loader size={Size.Medium} fluid />
                ) : selectedRoomId ? (
                  <RoomLayoutOptions
                    roomlayouts={roomLayoutTemplates}
                    selectedRoomTemplate={roomLayoutOption}
                    onSelectTemplate={handleRoomLayoutChange}
                    showCreateNewLayout
                  />
                ) : (
                  <Message
                    text="Choose a room from the dropdown above to view room layouts available for the room."
                    color={Swatches.Warning}
                  />
                )}
              </>
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      {(selectedRoomId === null || roomLayoutOption === null) &&
        group.layouts &&
        group.layouts.length > 0 && (
          <ButtonWrapper>
            <Button
              text="Cancel"
              color={Swatches.Low}
              onClick={closeLayoutCreator}
            />
          </ButtonWrapper>
        )}

      {!loading &&
        (actions.getRoomLayout.isLoading ? (
          <Loader size={Size.Medium} fluid />
        ) : roomLayoutOption === null ? null : roomLayoutOption.id === -1 ? (
          <>
            <ApiExceptionMessage error={actions.createRoomLayout.error} />

            <RoomLayoutWizard
              group={group}
              selectedRoom={selectedRoomId}
              selectedRoomId={selectedRoomId}
              closeLayoutCreator={closeLayoutCreator}
              numberOfRoomLayouts={roomLayouts.length}
              onCreateRoomLayout={onCreateRoomLayout}
            />
          </>
        ) : (
          <ClassLayoutCreatorForm
            group={group}
            selectedRoom={selectedRoomId}
            selectedRoomLayout={roomLayoutOption}
            handleCreateClassLayout={handleCreateClassLayout}
            closeLayoutCreator={closeLayoutCreator}
            working={loading}
          />
        ))}
    </>
  );
};

ClassLayoutCreator.propTypes = {
  classGroup: PropTypes.object,
  schoolRooms: PropTypes.array,
  closeLayoutCreator: PropTypes.func,
  children: PropTypes.node,
};

export default ClassLayoutCreator;
