import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import { Table, DetailLabel } from "ui-kit";
import moment from "moment";
import styled from "styled-components";
import DetentionStatusChip from "../detentionStatusChip";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import { useAppSelector } from "reducers/hooks";


const ChipWrapper = styled.div`
  .chip {
    min-width: 100px;
  }
`;


interface IRescheduledDetentionsProps {
  studentId: string;
  behaviourId: number;
  detentionId: number;
}


const RescheduledDetentions: React.FC<IRescheduledDetentionsProps> = ({ studentId, behaviourId, detentionId }) => {

  const { rescheduledDetentions } = useAppSelector(
    state => state.rescheduledDetentions
  );

  useEffect(() => {
    detentionActions.getRescheduledDetentions(
      studentId,
      behaviourId,
      detentionId
    );
  }, []);

  return (
    !arrays.isEmpty(rescheduledDetentions) && (
      <Table caption="Sanction History">
        <Table.Header>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Detention</Table.HeaderCell>
          <Table.HeaderCell>Updated by</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
        </Table.Header>

        <Table.Body>
          {rescheduledDetentions.map((detention, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                <DetailLabel
                  label={moment(detention.startDate).format("ddd Do MMM YYYY")}
                  sub={`${moment(detention.startDate).format(
                    "HH:mm"
                  )} - ${moment(detention.endDate).format("HH:mm")}`}
                  bold
                />
              </Table.Cell>
              <Table.Cell>
                <DetailLabel label={detention.detentionType} />
                {/* <DetailLabel label= bold /> */}
              </Table.Cell>
              <Table.Cell>
                <DetailLabel
                  label={detention.updatedBy}
                  sub={moment(detention.updatedDate).format("DD/MM/YYYY HH:mm")}
                  bold
                />
              </Table.Cell>
              <Table.Cell>
                <ChipWrapper>
                  <DetentionStatusChip detentionStatus={detention.statusId} />
                </ChipWrapper>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    )
  );
};

export default RescheduledDetentions;
