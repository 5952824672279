import React, {  } from "react";
import { Table, Loader, Size, EmptyMessage } from "ui-kit";
import { arrays, dateTime } from "utils";
import { ApiExceptionMessage, Avatar, UserList } from "sharedComponents/common";
import { StudentAssignedStaffReportView } from "areas/analytics/types/assignedStaffAnalyticResponse.types";
import { IBeehiveError } from "types/common/errors.types";


interface IAssignedStaffReportTableProps {
  reportData: StudentAssignedStaffReportView[];
  loading: boolean;
  error: IBeehiveError;
}


const AssignedStaffReportTable: React.FC<IAssignedStaffReportTableProps> = ({ reportData, loading, error }) => {

  if (loading) {
    return <Loader size={Size.Large} fluid />;
  }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  if (!loading && arrays.isEmpty(reportData)) {
    return (
      <EmptyMessage
        title="No students with assigned staff matching these parameters"
        icon="list"
      />
    );
  }

  return (
    <Table>
      <Table.Header>
        <Table.HeaderCell>Student</Table.HeaderCell>
        <Table.HeaderCell>Assigned Staff</Table.HeaderCell>
      </Table.Header>

      <Table.Body>
        <>
        { reportData.map((student, index) => (
          <Table.Row key={index}>
            <Table.Cell>
              <Avatar user={student} />
            </Table.Cell>
            <Table.Cell>
              <UserList 
                users={student.assignedStaff} 
                style={{border: 0}}
                transparrent
              />
            </Table.Cell>
          </Table.Row>
        ))}
        </>
      </Table.Body>
      
    </Table>
  );
};

export default AssignedStaffReportTable;
