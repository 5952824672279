import bulkRescheduleDetentionsReducer from "./bulkRescheduleDetentionsReducer";
import detentionReducer from "./detentionReducer";
import detentionRegisterReducer from "./detentionRegisterReducer";
import detentionRoomsReducer from "./detentionRoomsReducer";
import detentionSummaryReducer from "./detentionSummaryReducer";
import detentionTypesReducer from "./detentionTypesReducer";
import missedDetentionsReducer from "./missedDetentionsReducer";
import preflightDetentionDateCheckReducer from "./preflightDetentionDateCheckReducer";
import rescheduledDetentionsReducer from "./rescheduledDetentionsReducer";

const detentionsReducers = {
  detention: detentionReducer,
  detentionRegister: detentionRegisterReducer,
  detentionRooms: detentionRoomsReducer,
  detentionSummary: detentionSummaryReducer,
  detentionTypes: detentionTypesReducer,
  missedDetentions: missedDetentionsReducer,
  preflightDetentionDateCheck: preflightDetentionDateCheckReducer,
  rescheduledDetentions: rescheduledDetentionsReducer,
  bulkRescheduleDetentions: bulkRescheduleDetentionsReducer
};

export default detentionsReducers;
