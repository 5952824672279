import assignmentEditorReducer from "./assignmentEditorReducer";
import assignmentPublishReducer from "./assignmentPublishReducer";
import assignmentReducer from "./assignmentReducer";
import assignmentsReducer from "./assignmentsReducer";
import assignmentSubmissionsFilterReducer from "./assignmentSubmissionsFilterReducer";

const assignmentReducers = {
  assignmentEditor: assignmentEditorReducer,
  assignmentPublish: assignmentPublishReducer,
  assignment: assignmentReducer,
  assignments: assignmentsReducer,
  assignmentSubmissionsFilter: assignmentSubmissionsFilterReducer,
};

export default assignmentReducers;
