import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { BasketDetailView, BasketItemView, CashlessOrderDetailView, DuplicateBasketListView, GroupedTransactionListView, IncomingReportListView, IncomingTransactionListView, NoteView, OrderDetailView, OrderLineCollectedResult, OrderListView, PaymentsSummaryReport, PrintCreditBalanceView, ProductCategoryDetailView, ProductCategoryListView, ProductDetailView, ProductListView, ReconciliationDetailView, ReconciliationListView, SmartcardDetailView, SmartcardSyncResultListView, SmartcardTransactionListView, UserProductDetailLegacyView, UserProductOrderView } from "../types/shopkeeper/shopkeeperResponses.types";
import store from "reducers/store";
import { PagedCashlessQueryView, PagedQueryView, SearchResultView } from "types/common/views.types";
import { InventoryFilterType } from "../reducers/payments/inventoryFilterReducer";
import { AddLunchCreditToBasketCommand, AddOrderNoteCommand, AddPrintCreditToBasketCommand, AddProductToBasketCommand, AddSmartcardTopupToBasketCommand, BasketWithDuplicateOrdersQuery, ChangeOrderCommand, CreateReconciliationCommand, GetIncomingTransactionsByDateQuery, IssueRefundCommand, ReconciliationFilter, RunIncomingReportCommand, SaveProductCategoryCommand, SaveProductCommand, SaveReconciliationCommand, SmartcardCreditQueryFilter, SmartcardUserCreditQueryFilter, TransactionFilter, VoidOrderCommand } from "../types/shopkeeper/shopkeeperRequests.types";
import { TransactionMethod } from "../types/shopkeeper/shopkeeperShared.types";
import { ManagedUserDetailView, UserListView } from "types/users/userListViews.types";
import { LunchCreditAdjustmentView, LunchCreditBalanceView, LunchTransactionListView } from "../types/catering/cateringResponses.types";
import { AddManualLunchCreditCommand } from "../types/catering/cateringRequests.types";
import { urls } from "utils";

const builder = new actionTypeBuilder("payments");

const paymentsTypes = {
  PAYMENTS_HOMESWITCHTAB: "PAYMENTS_HOMESWITCHTAB",
  PAYMENTS_GETUSER: builder.build("PAYMENTS_GETUSER"),
  PAYMENTS_QUERYUSER: builder.build("PAYMENTS_QUERYUSER"),
  PAYMENTS_SEARCHUSERS: builder.build("PAYMENTS_SEARCHUSERS"),
  PAYMENTS_SEARCHPRODUCTS: builder.build("PAYMENTS_SEARCHPRODUCTS"),
  PAYMENTS_GETORDER: builder.build("PAYMENTS_GETORDER"),
  PAYMENTS_GETORDERSFORUSER: builder.build("PAYMENTS_GETORDERSFORUSER"),
  PAYMENTS_GETPURCHASEDITEMSFORUSER: builder.build(
    "PAYMENTS_GETPURCHASEDITEMSFORUSER"
  ),
  PAYMENTS_GETMANAGEDPRODUCTS: builder.build("PAYMENTS_GETMANAGEDPRODUCTS"),
  PAYMENTS_GETMANAGEDPRODUCT: builder.build("PAYMENTS_GETMANAGEDPRODUCT"),
  PAYMENTS_GETMANAGEDPRODUCTORDERS: builder.build(
    "PAYMENTS_GETMANAGEDPRODUCTORDERS"
  ),
  PAYMENTS_GETCATEGORIESFORSCHOOL: builder.build(
    "PAYMENTS_GETCATEGORIESFORSCHOOL"
  ),
  PAYMENTS_GETPRODUCTSFORADMIN: builder.build("PAYMENTS_GETPRODUCTSFORADMIN"),
  PAYMENTS_GETCATEGORY: builder.build("PAYMENTS_GETCATEGORY"),
  PAYMENTS_CREATECATEGORY: builder.build("PAYMENTS_CREATECATEGORY"),
  PAYMENTS_SAVECATEGORY: builder.build("PAYMENTS_SAVECATEGORY"),
  PAYMENTS_CREATEPRODUCT: builder.build("PAYMENTS_CREATEPRODUCT"),
  PAYMENTS_SAVEPRODUCT: builder.build("PAYMENTS_SAVEPRODUCT"),
  PAYMENTS_GETPRODUCT: builder.build("PAYMENTS_GETPRODUCT"),
  PAYMENTS_GETPRODUCTORDERS: builder.build("PAYMENTS_GETPRODUCTORDERS"),
  PAYMENTS_CHANGEORDER: builder.build("PAYMENTS_CHANGEORDER"),

  PAYMENTS_GETSMARTCARDCREDITS: builder.build("PAYMENTS_GETSMARTCARDCREDITS"),
  PAYMENTS_GETPRINTCREDITS: builder.build("PAYMENTS_GETPRINTCREDITS"),
  PAYMENTS_CANCELPRINTCREDIT: builder.build("PAYMENTS_CANCELPRINTCREDIT"),
  PAYMENTS_CANCELSMARTCARDCREDIT: builder.build(
    "PAYMENTS_CANCELSMARTCARDCREDIT"
  ),
  PAYMENTS_REQUEUEPRINTCREDIT: builder.build("PAYMENTS_REQUEUEPRINTCREDIT"),
  PAYMENTS_REQUEUESMARTCARDCREDIT: builder.build(
    "PAYMENTS_REQUEUESMARTCARDCREDIT"
  ),
  PAYMENTS_MARKORDERLINECOLLECTED: builder.build(
    "PAYMENTS_MARKORDERLINECOLLECTED"
  ),

  PAYMENTS_GETPRODUCTSFORUSER: builder.build("PAYMENTS_GETPRODUCTSFORUSER"),
  PAYMENTS_GETPRODUCTFORUSER: builder.build("PAYMENTS_GETPRODUCTFORUSER"),
  PAYMENTS_GETUSERSMARTCARDS: builder.build("PAYMENTS_GETUSERSMARTCARDS"),
  PAYMENTS_GETUSERSMARTCARDTRANSACTIONS: builder.build(
    "PAYMENTS_GETUSERSMARTCARDTRANSACTIONS"
  ),
  PAYMENTS_GETUSERPRINTCREDITS: builder.build("PAYMENTS_GETUSERPRINTCREDITS"),

  PAYMENTS_GETUSERPRINTCREDITSYNCRECORDS: builder.build(
    "PAYMENTS_GETUSERPRINTCREDITSYNCRECORDS"
  ),
  PAYMENTS_GETUSERSMARTCARDSYNCRECORDS: builder.build(
    "PAYMENTS_GETUSERSMARTCARDSYNCRECORDS"
  ),
  PAYMENTS_BASKET_GET: builder.build("BASKET_GET"),
  PAYMENTS_BASKET_REMOVEITEM: builder.build("BASKET_REMOVEITEM"),
  PAYMENTS_BASKET_ADDPRODUCT: builder.build("BASKET_ADDPRODUCT"),
  PAYMENTS_BASKET_ADDTOPUP: builder.build("BASKET_ADDTOPUP"),
  PAYMENTS_BASKET_ADDPRINTCREDIT: builder.build("BASKET_ADDPRINTCREDIT"),
  PAYMENTS_BASKET_ADDLUNCHCREDIT: builder.build("BASKET_ADDLUNCHCREDIT"),

  PAYMENTS_PLACEORDER: builder.build("PLACEORDER"),
  PAYMENTS_ISSUEREFUND: builder.build("ISSUEREFUND"),
  PAYMENTS_SENDRECEIPT: builder.build("SENDRECEIPT"),
  PAYMENTS_CANCELORDER: builder.build("CANCELORDER"),
  PAYMENTS_VOIDORDER: builder.build("VOIDORDER"),
  PAYMENTS_ADDORDERNOTE: builder.build("ADDORDERNOTE"),

  PAYMENTS_GETSUMMARY: builder.build("PAYMENTS_GETSUMMARY"),
  PAYMENTS_GETTRANSACTIONS: builder.build("PAYMENTS_GETTRANSACTIONS"),
  PAYMENTS_GETRECONCILIATIONS: builder.build("PAYMENTS_GETRECONCILIATIONS"),
  PAYMENTS_GETRECONCILIATION: builder.build("PAYMENTS_GETRECONCILIATION"),
  PAYMENTS_CREATERECONCILIATION: builder.build("PAYMENTS_CREATERECONCILIATION"),
  PAYMENTS_SAVERECONCILIATION: builder.build("PAYMENTS_SAVERECONCILIATION"),
  PAYMENTS_RECONCILIATIONS_FILTERBYDATE:
    "PAYMENTS_RECONCILIATIONS_FILTERBYDATE",

  PAYMENTS_GETUSERLUNCHTRANSACTIONS: builder.build(
    "PAYMENTS_GETUSERLUNCHTRANSACTIONS"
  ),
  PAYMENTS_GETUSERLUNCHCREDITBALANCE: builder.build(
    "PAYMENTS_GETUSERLUNCHCREDITBALANCE"
  ),
  PAYMENTS_ADDMANUALLUNCHCREDIT: builder.build("PAYMENTS_ADDMANUALLUNCHCREDIT"),
  PAYMENTS_RUNINCOMINGREPORT: builder.build("PAYMENTS_RUNINCOMINGREPORT"),
  PAYMENTS_GETINCOMINGREPORTS: builder.build("PAYMENTS_GETINCOMINGREPORTS"),
  PAYMENTS_PROCESSINCOMINGREPORT: builder.build(
    "PAYMENTS_PROCESSINCOMINGREPORT"
  ),
  PAYMENTS_GETINCOMINGREPORT: builder.build("PAYMENTS_GETINCOMINGREPORT"),
  PAYMENTS_GETINCOMINGTRANSACTIONS: builder.build(
    "PAYMENTS_GETINCOMINGTRANSACTIONS"
  ),
  PAYMENTS_GETINCOMINGTRANSACTIONSBYDATE: builder.build(
    "PAYMENTS_GETINCOMINGTRANSACTIONSBYDATE"
  ),
  PAYMENTS_GETBASKETSWITHDUPLICATEORDERS: builder.build(
    "PAYMENTS_GETBASKETSWITHDUPLICATEORDERS"
  ),
  PAYMENTS_USERSYNC_UPDATEFILTER: "PAYMENTS_USERSYNC_UPDATEFILTER",
  PAYMENTS_GETCASHLESSORDER: builder.build("PAYMENTS_GETCASHLESSORDER"),
};


const switchHomeTab = (index: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: paymentsTypes.PAYMENTS_HOMESWITCHTAB,
    payload: index,
  });
}


const getSummary = (startDate: Date, endDate: Date, callback?: callbackType<PaymentsSummaryReport>) => {

  var start = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;
  var end = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`; 

  action<PaymentsSummaryReport>(
    () => client.get(`payments/reporting/summary?startDate=${start}&endDate=${end}`),
    paymentsTypes.PAYMENTS_GETSUMMARY,
    callback
  );
}


const getProductsForAdmin = (filter: InventoryFilterType, pageIndex: number, callback?: callbackType) => {

  var qs = `pageIndex=${pageIndex}
  ${filter.schoolId > -1 ? "&schoolId=" + filter.schoolId : ""}
  ${filter.category?.id > -1 ? "&categoryId=" + filter.category.id : ""}
  ${filter.active > -1 ? "&active=" + (filter.active ? "true" : "false") : ""}`;

  return action<PagedQueryView<ProductListView>>(
    () => client.get(`payments/inventory/products?${qs}`),
    paymentsTypes.PAYMENTS_GETPRODUCTSFORADMIN,
    callback
  );
}


const getCategoriesForSchool = (schoolId: number, callback?: callbackType<ProductCategoryListView[]>) =>
  action<ProductCategoryListView[]>(
    () => client.get(`payments/inventory/categories/school/${schoolId}`),
    paymentsTypes.PAYMENTS_GETCATEGORIESFORSCHOOL,
    callback
  );


const createCategory = (category: SaveProductCategoryCommand, callback?: callbackType<ProductCategoryListView>) =>
  action<ProductCategoryListView>(
    () => client.post(`payments/inventory/categories`, category),
    paymentsTypes.PAYMENTS_CREATECATEGORY,
    callback
  );


const saveCategory = (category: SaveProductCategoryCommand, callback?: callbackType<ProductCategoryListView>) =>
  action<ProductCategoryListView>(
    () => client.post(`payments/inventory/categories/${category.id}`, category),
    paymentsTypes.PAYMENTS_SAVECATEGORY,
    callback
  );


const getCategory = (categoryId: number, callback?: callbackType<ProductCategoryDetailView>) =>
  action<ProductCategoryDetailView>(
    () => client.get(`payments/inventory/categories/${categoryId}`),
    paymentsTypes.PAYMENTS_GETCATEGORY,
    callback
  );


const getProduct = (categoryId: number, productId: number, callback?: callbackType<ProductDetailView>) =>
  action<ProductDetailView>(
    () => client.get(`payments/inventory/categories/${categoryId}/products/${productId}`),
    paymentsTypes.PAYMENTS_GETPRODUCT,
    callback
  );


const saveProduct = (product: SaveProductCommand, callback?: callbackType) =>
  action<null>(
    () => client.post(`payments/inventory/categories/${product.categoryId}/products/${product.id}`, product),
    paymentsTypes.PAYMENTS_SAVEPRODUCT,
    callback
  );


const createProduct = (product: SaveProductCommand, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`payments/inventory/categories/${product.categoryId}/products`, product),
    paymentsTypes.PAYMENTS_CREATEPRODUCT,
    callback
  );


const getProductOrders = (categoryId: number, productId: number, callback?: callbackType<UserProductOrderView[]>) =>
  action<UserProductOrderView[]>(
    () => client.get(`payments/inventory/categories/${categoryId}/products/${productId}/orders`),
    paymentsTypes.PAYMENTS_GETPRODUCTORDERS,
    callback
  );


const getPurchasedItemsForUser = (userId: string, callback?: callbackType<UserProductOrderView[]>) =>
  action<UserProductOrderView[]>(
    () => client.get(`payments/users/${userId}/products/ordered`),
    paymentsTypes.PAYMENTS_GETPURCHASEDITEMSFORUSER,
    callback
  );


const markOrderLineCollected = (userId: string, orderId: number, orderLineId: number, callback?: callbackType<OrderLineCollectedResult>) =>
  action<OrderLineCollectedResult>(
    () => client.post(`payments/users/${userId}/orders/${orderId}/lines/${orderLineId}/collect`, { userId, orderId, orderLineId }),
    paymentsTypes.PAYMENTS_MARKORDERLINECOLLECTED,
    callback
  );


const getProductForUser = (userId: string, productId: number, excludeDiscount: boolean = false, callback?: callbackType<UserProductDetailLegacyView>) =>
  action<UserProductDetailLegacyView>(
    () => client.get(`payments/users/${userId}/products/${productId}?excludeDiscount=${excludeDiscount}`),
    paymentsTypes.PAYMENTS_GETPRODUCTFORUSER,
    callback
  );


const getProductsForUser = (userId: string, callback?: callbackType<ProductListView[]>) =>
  action<ProductListView[]>(
    () => client.get(`payments/users/${userId}/products`),
    paymentsTypes.PAYMENTS_GETPRODUCTSFORUSER,
    callback
  );


const getBasket = (userId: string, callback?: callbackType<BasketDetailView>) =>
  action<BasketDetailView>(
    () => client.get(`payments/users/${userId}/basket`),
    paymentsTypes.PAYMENTS_BASKET_GET,
    callback
  );


const removeItemFromBasket = (userId: string, basketItem: BasketItemView, callback?: callbackType<null>) =>
  action<null>(
    () => client.delete(`payments/users/${userId}/basket/items/${basketItem.id}`),
    paymentsTypes.PAYMENTS_BASKET_REMOVEITEM,
    callback
  );


const addProductToBasket = (userId: string, data: AddProductToBasketCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`payments/users/${userId}/basket/products`, data),
    paymentsTypes.PAYMENTS_BASKET_ADDPRODUCT,
    callback
  );


const addPrintCreditToBasket = (userId: string, data: AddPrintCreditToBasketCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`payments/users/${userId}/basket/printcredits`, data),
    paymentsTypes.PAYMENTS_BASKET_ADDPRINTCREDIT,
    callback
  );


const addTopupToBasket = (userId: string, data: AddSmartcardTopupToBasketCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`payments/users/${userId}/basket/smartcards`, data),
    paymentsTypes.PAYMENTS_BASKET_ADDTOPUP,
    callback
  );


const addLunchCreditToBasket = (userId: string, data: AddLunchCreditToBasketCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`payments/users/${userId}/basket/lunchcredits`, data),
    paymentsTypes.PAYMENTS_BASKET_ADDLUNCHCREDIT,
    callback
  );


const placeOrder = (userId: string, method: TransactionMethod, reference: string, providerTransactionId: string, date: Date, notes: string, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`payments/users/${userId}/orders`, { userId, method, reference, providerTransactionId, date, notes }),
    paymentsTypes.PAYMENTS_PLACEORDER,
    callback
  );


const getOrder = (userId: string, orderId: number, callback?: callbackType<OrderDetailView>) =>
  action<OrderDetailView>(
    () => client.get(`payments/users/${userId}/orders/${orderId}`),
    paymentsTypes.PAYMENTS_GETORDER,
    callback
  );


const issueRefund = (userId: string, data: IssueRefundCommand, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`payments/users/${userId}/refunds`, data),
    paymentsTypes.PAYMENTS_ISSUEREFUND,
    callback
  );


const sendReceipt = (userId: string, orderId: number, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`payments/users/${userId}/orders/${orderId}/receipt`),
    paymentsTypes.PAYMENTS_SENDRECEIPT,
    callback
  );


const cancelOrder = (userId: string, orderId: number, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`payments/users/${userId}/orders/${orderId}/cancel`, { userId, orderId }),
    paymentsTypes.PAYMENTS_CANCELORDER,
    callback
  );


const changeOrder = (data: ChangeOrderCommand, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`payments/users/${data.userId}/orders/${data.id}/change`, data),
    paymentsTypes.PAYMENTS_CHANGEORDER,
    callback
  );


const voidOrder = (data: VoidOrderCommand, callback?: callbackType<OrderListView>) =>
  action<OrderListView>(
    () => client.post(`payments/users/${data.userId}/orders/${data.orderId}/void`, data),
    paymentsTypes.PAYMENTS_VOIDORDER,
    callback
  );


const addOrderNote = (data: AddOrderNoteCommand, callback?: callbackType<NoteView>) =>
  action<NoteView>(
    () =>  client.post(`payments/users/${data.userId}/orders/${data.id}/notes`, data),
    paymentsTypes.PAYMENTS_ADDORDERNOTE,
    callback
  );


const cancelPrintCredit = (userId: string, creditId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`payments/users/${userId}/printcredits/${creditId}/cancel`),
    paymentsTypes.PAYMENTS_CANCELPRINTCREDIT,
    callback
  );


const requeuePrintCredit = (userId: string, creditId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`payments/users/${userId}/printcredits/${creditId}/requeue`),
    paymentsTypes.PAYMENTS_REQUEUEPRINTCREDIT,
    callback
  );


const cancelSmartcardCredit = (userId: string, creditId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`payments/users/${userId}/smartcards/${creditId}/cancel`),
    paymentsTypes.PAYMENTS_CANCELSMARTCARDCREDIT,
    callback
  );


const requeueSmartcardCredit = (userId: string, creditId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`payments/users/${userId}/smartcards/${creditId}/requeue`),
    paymentsTypes.PAYMENTS_REQUEUESMARTCARDCREDIT,
    callback
  );


const getSmartcardCredits = (filter: SmartcardCreditQueryFilter, pageIndex: number, callback?: callbackType<PagedQueryView<SmartcardSyncResultListView>>) => {
  var url = `payments/users/smartcards/sync?pageIndex=${pageIndex}&${
    filter.schoolId ? "schoolId=" + filter.schoolId + "&" : ""
  }${filter.status > -1 ? "status=" + filter.status + "&" : ""}${
    filter.searchTerm ? "&searchTerm=" + filter.searchTerm : ""
  }`;

  return action<PagedQueryView<SmartcardSyncResultListView>>(
    () => client.get(url),
    paymentsTypes.PAYMENTS_GETSMARTCARDCREDITS,
    callback
  );
}


const getPrintCredits = (filter: SmartcardCreditQueryFilter, pageIndex: number, callback?: callbackType<PagedQueryView<SmartcardSyncResultListView>>) => {
  var url = `payments/users/printcredits/sync?pageIndex=${pageIndex}&${
    filter.schoolId ? "schoolId=" + filter.schoolId + "&" : ""
  }${filter.status > -1 ? "status=" + filter.status + "&" : ""}${
    filter.searchTerm ? "&searchTerm=" + filter.searchTerm : ""
  }`;

  return action<PagedQueryView<SmartcardSyncResultListView>>(
    () => client.get(url),
    paymentsTypes.PAYMENTS_GETPRINTCREDITS,
    callback
  );
}


const getReconciliations = (filter: ReconciliationFilter, pageIndex: number, callback?: callbackType<PagedQueryView<ReconciliationListView>>) => {
  var date = filter.date
  ? `${filter.date.getFullYear()}-${filter.date.getMonth() + 1}-${filter.date.getDate()}`
  : null;

  return action<PagedQueryView<ReconciliationListView>>(
    () => client.get(`payments/reconciliations?pageIndex=${pageIndex}&date=${date}`),
    paymentsTypes.PAYMENTS_GETRECONCILIATIONS,
    callback
  );
}


const filterReconciliationsByDate = (date: Date) => {
  store.dispatch<IBeehiveAction<Date>>({
    type: paymentsTypes.PAYMENTS_RECONCILIATIONS_FILTERBYDATE,
    payload: date,
  });
}


const createReconciliation = (data: CreateReconciliationCommand, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`payments/reconciliations`, data),
    paymentsTypes.PAYMENTS_CREATERECONCILIATION,
    callback
  );


const getReconciliation = (id: number, callback?: callbackType<ReconciliationDetailView>) =>
  action<ReconciliationDetailView>(
    () => client.get(`payments/reconciliations/${id}`),
    paymentsTypes.PAYMENTS_GETRECONCILIATION,
    callback
  );


const getTransactions = (filter: TransactionFilter, callback?: callbackType<GroupedTransactionListView>) => {
  var start = `${filter.startDate.getFullYear()}-${filter.startDate.getMonth() + 1}-${filter.startDate.getDate()}`;
  var end = `${filter.endDate.getFullYear()}-${filter.endDate.getMonth() + 1}-${filter.endDate.getDate()}`;

  return action<GroupedTransactionListView>(
    () => client.get(`payments/transactions/grouped?startDate=${start}&endDate=${end}&method=${filter.method}`),
    paymentsTypes.PAYMENTS_GETTRANSACTIONS,
    callback
  );
}

const saveReconciliation = (data: SaveReconciliationCommand, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`payments/reconciliations/${data.id}`, data),
    paymentsTypes.PAYMENTS_SAVERECONCILIATION,
    callback
  );


const getSmartcardSyncRecordsForUser = (userId: string, filter?: SmartcardUserCreditQueryFilter, callback?: callbackType<SmartcardSyncResultListView[]>) => {
  
  var qs = `${filter.schoolId > -1 ? "&schoolId=" + filter.schoolId : ""}${filter.providerId > -1 ? "&providerId=" + filter.providerId : ""}${filter.statusId > -1 ? "&statusId=" + filter.statusId : ""}`;

  return action<SmartcardSyncResultListView[]>(
    () => client.get(`payments/users/${userId}/smartcards/sync?${qs}`),
    paymentsTypes.PAYMENTS_GETUSERSMARTCARDSYNCRECORDS,
    callback
  );
}


const getPrintCreditSyncRecordsForUser = (userId: string, callback?: callbackType<SmartcardSyncResultListView[]>) =>
  action<SmartcardSyncResultListView[]>(
    () => client.get(`payments/users/${userId}/printcredits/sync`),
    paymentsTypes.PAYMENTS_GETUSERPRINTCREDITSYNCRECORDS,
    callback
  );


const searchUsers = (searchTerm: string, pageIndex: number, callback?: callbackType<PagedQueryView<SearchResultView<UserListView>>>) =>
  action<PagedQueryView<SearchResultView<UserListView>>>(
    () => client.get(`payments/users/search?searchTerm=${searchTerm}&pageIndex=${pageIndex}&pageSize=25`),
    paymentsTypes.PAYMENTS_SEARCHUSERS,
    callback
  );


const searchProducts = (searchTerm: string, pageIndex: number, callback?: callbackType<PagedQueryView<ProductListView>>) =>
  action<PagedQueryView<ProductListView>>(
    () => client.get(`payments/inventory/search?searchTerm=${searchTerm}&pageIndex=${pageIndex}&pageSize=25`),
    paymentsTypes.PAYMENTS_SEARCHPRODUCTS,
    callback
  );


const getUser = (userId: string, callback?: callbackType<ManagedUserDetailView>) =>
  action<ManagedUserDetailView>(
    () => client.get(`users/${userId}`),
    paymentsTypes.PAYMENTS_GETUSER,
    callback
  );


const queryUser = (userId: string, callback?: callbackType<ManagedUserDetailView>) =>
  action<ManagedUserDetailView>(
    () => client.get(`users/${userId}`),
    paymentsTypes.PAYMENTS_QUERYUSER,
    callback
  );


const getOrdersForUser = (userId: string, callback?: callbackType<OrderListView[]>) =>
  action<OrderListView[]>(
    () => client.get(`payments/users/${userId}/orders`),
    paymentsTypes.PAYMENTS_GETORDERSFORUSER,
    callback
  );


const getManagedProducts = (userId: string, callback?: callbackType<ProductListView[]>) =>
  action<ProductListView[]>(
    () => client.get(`payments/users/${userId}/associatedproducts`),
    paymentsTypes.PAYMENTS_GETMANAGEDPRODUCTS,
    callback
  );


const getManagedProduct = (userId: string, categoryId: number, productId: number, callback?: callbackType<ProductDetailView>) =>
  action<ProductDetailView>(
    () => client.get(`payments/inventory/categories/${categoryId}/products/${productId}`),
    paymentsTypes.PAYMENTS_GETMANAGEDPRODUCT,
    callback
  );


const getManagedProductOrders = (categoryId: number, productId: number, callback?: callbackType<UserProductOrderView[]>) =>
  action<UserProductOrderView[]>(
    () => client.get(`payments/inventory/categories/${categoryId}/products/${productId}/orders`),
    paymentsTypes.PAYMENTS_GETMANAGEDPRODUCTORDERS,
    callback
  );


const getSmartcardsForUser = (userId: string, callback?: callbackType<SmartcardDetailView[]>) =>
  action<SmartcardDetailView[]>(
    () => client.get(`payments/users/${userId}/smartcards/all`),
    paymentsTypes.PAYMENTS_GETUSERSMARTCARDS,
    callback
  );


const getSmartcardTransactionsForUser = (userId: string, pageIndex?: number, callback?: callbackType<PagedCashlessQueryView<SmartcardTransactionListView>>) =>
  action<PagedCashlessQueryView<SmartcardTransactionListView>>(
    () => client.get(`payments/users/${userId}/smartcards/transactions?pageIndex=${pageIndex}&pageSize=25`),
    paymentsTypes.PAYMENTS_GETUSERSMARTCARDTRANSACTIONS,
    callback
  );


const getPrintCreditsForUser = (userId: string, callback?: callbackType<PrintCreditBalanceView>) =>
  action<PrintCreditBalanceView>(
    () => client.get(`payments/users/${userId}/smartcards/printcredits`),
    paymentsTypes.PAYMENTS_GETUSERPRINTCREDITS,
    callback
  );


const getUserLunchCreditBalance = (userId: string, callback?: callbackType<LunchCreditBalanceView[]>) =>
  action<LunchCreditBalanceView[]>(
    () => client.get(`payments/users/${userId}/lunchcredits/balance`),
    paymentsTypes.PAYMENTS_GETUSERLUNCHCREDITBALANCE,
    callback
  );


const getUserLunchTransactions = (userId: string, callback?: callbackType<LunchTransactionListView[]>) =>
  action<LunchTransactionListView[]>(
    () => client.get(`payments/users/${userId}/lunchcredits/transactions`),
    paymentsTypes.PAYMENTS_GETUSERLUNCHTRANSACTIONS,
    callback
  );


const addManualLunchCredit = (userId: string, data: AddManualLunchCreditCommand, callback?: callbackType<LunchCreditAdjustmentView>) =>
  action<LunchCreditAdjustmentView>(
    () => client.post(`payments/users/${userId}/lunchcredits`, data),
    paymentsTypes.PAYMENTS_ADDMANUALLUNCHCREDIT,
    callback
  );


const runIncomingReport = (data: RunIncomingReportCommand, callback?: callbackType<IncomingReportListView>) =>
  action<IncomingReportListView>(
    () => client.post(`payments/incomingreports`, data),
    paymentsTypes.PAYMENTS_RUNINCOMINGREPORT,
    callback
  );


const getIncomingReports = (callback?: callbackType<IncomingReportListView[]>) =>
  action<IncomingReportListView[]>(
    () => client.get(`payments/incomingreports`),
    paymentsTypes.PAYMENTS_GETINCOMINGREPORTS,
    callback
  );


const getIncomingReport = (id: number, callback?: callbackType<IncomingReportListView>) =>
  action<IncomingReportListView>(
    () => client.get(`payments/incomingreports/${id}`),
    paymentsTypes.PAYMENTS_GETINCOMINGREPORT,
    callback
  );


const processIncomingReport = (id: number, callback?: callbackType<IncomingReportListView>) =>
  action<IncomingReportListView>(
    () => client.post(`payments/incomingreports/${id}/process`),
    paymentsTypes.PAYMENTS_PROCESSINCOMINGREPORT,
    callback
  );


const getIncomingTransactions = (id: number, callback?: callbackType<IncomingTransactionListView[]>) =>
  action<IncomingTransactionListView[]>(
    () => client.get(`payments/incomingreports/${id}/transactions`),
    paymentsTypes.PAYMENTS_GETINCOMINGTRANSACTIONS,
    callback
  );


const getIncomingTransactionsByDate = (dateRange: GetIncomingTransactionsByDateQuery, callback?: callbackType<IncomingTransactionListView[]>) => {
  var url = new urls.QueryString("payments/incomingtransactions");
  url.addParams({ ...dateRange });

  return action<IncomingTransactionListView[]>(
    () => client.get(url.toUrl()),
    paymentsTypes.PAYMENTS_GETINCOMINGTRANSACTIONSBYDATE,
    callback
  );
}


const getBasketsWithDuplicateOrders = (data: BasketWithDuplicateOrdersQuery, pageIndex: number, callback?: callbackType<PagedQueryView<DuplicateBasketListView>>) => {
  var url = new urls.QueryString("payments/baskets/duplicates");
  url.addParams({ ...data, pageIndex });

  return action<PagedQueryView<DuplicateBasketListView>>(
    () => client.get(url.toUrl()),
    paymentsTypes.PAYMENTS_GETBASKETSWITHDUPLICATEORDERS,
    callback
  );
}


const getCashlessOrderDetails = (userId: string, orderId: string, callback?: callbackType<CashlessOrderDetailView>) =>
  action<CashlessOrderDetailView>(
    () => client.get(`payments/users/${userId}/orders/cashless/${orderId}`),
    paymentsTypes.PAYMENTS_GETCASHLESSORDER,
    callback
  );


const updateUserSyncRecordsFilter = (filter: SmartcardUserCreditQueryFilter) => {
  store.dispatch<IBeehiveAction<SmartcardUserCreditQueryFilter>>({
    type: paymentsTypes.PAYMENTS_USERSYNC_UPDATEFILTER,
    payload: filter,
  });
};


const paymentActions = {
  paymentsTypes,
  switchHomeTab,
  getSummary,
  getProductsForAdmin,
  getCategoriesForSchool,
  createCategory,
  saveCategory,
  getCategory,
  getProduct,
  saveProduct,
  createProduct,
  getProductOrders,
  getPurchasedItemsForUser,
  markOrderLineCollected,
  getProductForUser,
  getProductsForUser,
  getBasket,
  removeItemFromBasket,
  addProductToBasket,
  addPrintCreditToBasket,
  addTopupToBasket,
  addLunchCreditToBasket,
  placeOrder,
  getOrder,
  issueRefund,
  sendReceipt,
  cancelOrder,
  changeOrder,
  voidOrder,
  addOrderNote,
  cancelPrintCredit,
  requeuePrintCredit,
  cancelSmartcardCredit,
  requeueSmartcardCredit,
  getSmartcardCredits,
  getPrintCredits,
  getReconciliations,
  filterReconciliationsByDate,
  createReconciliation,
  getReconciliation,
  getTransactions,
  saveReconciliation,
  getSmartcardSyncRecordsForUser,
  getPrintCreditSyncRecordsForUser,
  searchUsers,
  searchProducts,
  getUser,
  getOrdersForUser,
  getManagedProducts,
  getManagedProduct,
  getManagedProductOrders,
  getSmartcardsForUser,
  getSmartcardTransactionsForUser,
  getPrintCreditsForUser,
  getUserLunchCreditBalance,
  getUserLunchTransactions,
  addManualLunchCredit,
  runIncomingReport,
  getIncomingReports,
  getIncomingReport,
  processIncomingReport,
  getIncomingTransactions,
  getIncomingTransactionsByDate,
  getBasketsWithDuplicateOrders,
  updateUserSyncRecordsFilter,
  getCashlessOrderDetails,
  queryUser
};

export default paymentActions;