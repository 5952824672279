import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


interface IPreflightDetentionDateCheckState {
  loading: boolean;
  error: IBeehiveError;
}


const INITIAL_STATE: IPreflightDetentionDateCheckState = {
  loading: false,
  error: null,
};

const { VALIDATEDETENTIONDATE: PREFLIGHTDETENTIONDATE, RESCHEDULEDETENTION } =
  detentionActions.types;
const {
  GETBEHAVIOUR,
  GETDEFAULTDETENTION,
  ADDBEHAVIOUR,
  ADDBEHAVIOURFORMULTIPLE,
  UPDATEBEHAVIOUR,
  UPSCALEBEHAVIOUR,
  UPSCALEBEHAVIOURDETENTION,
} = behaviourActions.types;


const preflightDetentionDateCheckReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IPreflightDetentionDateCheckState => {

  switch (action.type) {
    case PREFLIGHTDETENTIONDATE.START:
      return { ...state, loading: true, error: null };

    case PREFLIGHTDETENTIONDATE.SUCCESS:
      return { ...state, loading: false };

    case PREFLIGHTDETENTIONDATE.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GETBEHAVIOUR.START:
    case GETDEFAULTDETENTION.START:
    case ADDBEHAVIOUR.START:
    case ADDBEHAVIOURFORMULTIPLE.START:
    case UPDATEBEHAVIOUR.START:
    case UPSCALEBEHAVIOUR.START:
    case UPSCALEBEHAVIOURDETENTION.START:
    case RESCHEDULEDETENTION.START:
      return { ...state, error: null };

    default:
      return state;
  }
};

export default preflightDetentionDateCheckReducer;
