import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Table, DetailLabel } from "ui-kit";
import styled from "styled-components";
import { DETENTION_STATUS } from "areas/behaviour/constants/detentions";
import { DetentionStudentSummaryView } from "areas/behaviour/types/behaviourResponses.types";


const Wrapper = styled.div`
  .table .table-body {
    min-height: unset;
  }
`;


interface IDetentionsOnDayProps {
  detentionSummary: DetentionStudentSummaryView[]
}


const DetentionsOnDay: React.FC<IDetentionsOnDayProps> = ({ detentionSummary }) => {

  return (
    <Wrapper>
      <Table caption="Existing Sanction(s) on Selected Date">
        <Table.Header>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell width={0.75}>Start</Table.HeaderCell>
          <Table.HeaderCell width={0.75}>End</Table.HeaderCell>
          <Table.HeaderCell width={0.75}>Duration</Table.HeaderCell>
          <Table.HeaderCell>Behaviour</Table.HeaderCell>
          <Table.HeaderCell right>Status</Table.HeaderCell>
        </Table.Header>

        <Table.Body>
          {detentionSummary.map((detention, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                {moment(detention.startDate).format("ddd Do MMM YYYY")}
              </Table.Cell>
              <Table.Cell>
                {moment(detention.startDate).format("HH:mm")}
              </Table.Cell>
              <Table.Cell>
                {moment(detention.endDate).format("HH:mm")}
              </Table.Cell>
              <Table.Cell>
                {moment(detention.endDate).diff(
                  moment(detention.startDate),
                  "minutes"
                )}{" "}
                mins
              </Table.Cell>
              <Table.Cell>
                <DetailLabel
                  bold
                  label={detention.behaviourCode}
                  sub={detention.categoryName}
                />
              </Table.Cell>
              <Table.Cell right>
                {DETENTION_STATUS[detention.statusId].status}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Wrapper>
  );
};


export default DetentionsOnDay;
