import { IBeehiveAction } from "types/common/action.types";
import adminSubmissionActions from "../actions/adminSubmissionActions";
import submissionActions from "../actions/submissionActions";
import { SubmissionDetailView } from "../types/formResponse.types";


interface IAdminSubmissionState {
  submission: SubmissionDetailView | null;
  loading: boolean;
  error: string | null;
  assigning: boolean;
  assignmentError: string | null;
  saving: boolean;
  settingAuthoriser: boolean;
  authoriserError: string | null;
  creating: {
    categoryId: number, 
    formId: number
  };
  savingNote: boolean;
  noteError: string | null;
  updatingStatus: boolean;
  statusError: string | null;
}

const INITIAL_STATE: IAdminSubmissionState = {
  submission: null,
  loading: false,
  error: null,
  assigning: false,
  assignmentError: null,
  saving: false,
  settingAuthoriser: false,
  authoriserError: null,
  creating: {
    categoryId: -1, 
    formId: -1
  },
  savingNote: false,
  noteError: null,
  updatingStatus: false,
  statusError: null,
};


const adminSubmissionReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAdminSubmissionState => {

  const { GETSUBMISSION, ASSIGN, ADDNOTE, SETPREAUTHORISER, NEWSUBMISSION } = adminSubmissionActions.types;
  const { AUTHORISE, REJECT, CANCEL } = submissionActions.types;

  switch (action.type) {
    case NEWSUBMISSION:
      return { ...state, submission: null, creating: action.payload };

    case GETSUBMISSION.START:
      return { ...state, creating: null, loading: true };

    case GETSUBMISSION.SUCCESS:
      return {
        ...state,
        submission: action.payload,
        loading: false,
      };

    case GETSUBMISSION.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case AUTHORISE.START:
    case REJECT.START:
    case CANCEL.START:
      return { ...state, updatingStatus: true, statusError: "" };

    case AUTHORISE.SUCCESS:
    case REJECT.SUCCESS:
    case CANCEL.SUCCESS:
      var submission =
        state.submission && state.submission.id === action.payload.submissionId
          ? {
              ...state.submission,
              latestHistory: action.payload,
              history: [action.payload, ...state.submission.history],
            }
          : state.submission;

      return {
        ...state,
        updatingStatus: false,
        submission: submission,
      };

    case AUTHORISE.FAILED:
    case REJECT.FAILED:
    case CANCEL.FAILED:
      return {
        ...state,
        updatingStatus: false,
        statusError: action.payload,
      };

    case ASSIGN.START:
      return { ...state, assigning: true, assignmentError: "" };

    case ASSIGN.SUCCESS:
      return {
        ...state,
        submission: { ...state.submission, assignedTo: action.payload.user },
        assigning: false,
      };

    case ASSIGN.FAILED:
      return { ...state, assigning: false, assignmentError: action.payload };

    case ADDNOTE.START:
      return { ...state, savingNote: true, noteError: "" };

    case ADDNOTE.SUCCESS:
      return {
        ...state,
        savingNote: false,
        submission:
          state.submission.id === action.payload.submissionId
            ? {
                ...state.submission,
                notes: [action.payload, ...state.submission.notes],
              }
            : state.submission,
      };

    case ADDNOTE.FAILED:
      return { ...state, savingNote: false, noteError: action.payload };

    case SETPREAUTHORISER.START:
      return { ...state, settingAuthoriser: true, authoriserError: "" };

    case SETPREAUTHORISER.SUCCESS:
      return {
        ...state,
        submission: {
          ...state.submission,
          authoriser: action.payload.authoriser,
        },
        settingAuthoriser: false,
      };

    case SETPREAUTHORISER.FAILED:
      return {
        ...state,
        settingAuthoriser: false,
        authoriserError: action.payload,
      };

    default:
      return state;
  }
};

export default adminSubmissionReducer;
