import React from "react";
import { Swatches } from "ui-kit/common/colors";
import { Message } from "ui-kit";


const ParentNotificationNote: React.FC = () => {

  return (
    <>
      <br />
      <Message
        text="Please note: The behaviour point and all starred information in this form
          will be sent to parents."
        color={Swatches.Warning}
      />
    </>
  );
};


export default ParentNotificationNote;
