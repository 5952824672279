

/**
 * @param {number} max The max value
 * @returns {number} A random number below the max value
 */
const getRandomNumber = (max : number) : number => {
  return Math.floor(Math.random() * Math.floor(max));
};


/**
 * Returns a random student from the given student array
 * @param {studentObjType[]} classGroupStudents Array of students
 * @returns {studentObjType} A random student from the array
 */
const pickRandomStudent = <studentObjType>(classGroupStudents: studentObjType[]) : studentObjType => {
  const index = getRandomNumber(classGroupStudents.length - 1);
  return classGroupStudents[index];
};


const studentQuiz = { 
  pickRandomStudent 
};

export default studentQuiz;