import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Loader,
  Size,
  StructuredList,
  Dropdown,
  TitleSize,
  Title,
  Card,
} from "ui-kit";
import { getDateTime, formatDate } from "utils/dateTime";
import { Constants } from "configuration";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import { upscaleBehaviourValidation } from "../../../utils/behaviours";
import { DETENTION_OPTIONS_KEYS } from "areas/behaviour/constants/detentions";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import ReadOnlyDetails from "areas/behaviour/components/readOnly/readOnlyDetails";
import BehaviourPointForm from "../behaviourPointForm";
import RelatedBehaviourDetails from "../relatedBehaviourDetails";
import { StudentFeedListView } from "types/planner/feedResponse.types";
import { useAppSelector } from "reducers/hooks";
import { BehaviourView, SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { UpscaleBehaviourCommand, UpscaleBehaviourDetention } from "areas/behaviour/types/behaviourRequest.types";
import { DetentionOption } from "areas/behaviour/types/behaviourShared.types";
import { IBehaviourDetails, IDetentionDetails, IIncidentDetails } from "areas/behaviour/utils/detentions";
import { GroupType } from "types/users/userGroups.types";


interface IUpscaleBehaviourFormWrapperProps {
  activity: StudentFeedListView;
  handleCloseModal: () => void;
}


const UpscaleBehaviourFormWrapper: React.FC<IUpscaleBehaviourFormWrapperProps> = ({ activity, handleCloseModal }) => {

  const { behaviour, refresh, loading, getBehaviourError } = useAppSelector(
    state => state.behaviour
  );
  const { loading: loadingUpscale, error: upscaleError } = useAppSelector(
    state => state.upscaleBehaviour
  );
  const { behaviourUpscaleCategoryGroups } = useAppSelector(
    state => state.behaviourUpscaleCategories
  );
  const { defaultDetention, loading: loadingDefaultDetention } = useAppSelector(
    state => state.defaultDetention
  );
  const { schoolInformation } = useAppSelector(state => state.school);
  const { detentionSummary } = useAppSelector(state => state.detentionSummary);

  const [selectedBehaviour, setSelectedBehaviour] = useState<BehaviourView>(null);
  const [upscaleBehaviourCategory, setUpscaleBehaviourCategory] = useState<SchoolBehaviourCategoryListView>(null);
  const [upscaleBehaviourCategoriesArray, setUpscaleBehaviourCategoriesArray] = useState<IDropdownDataItemProps<string, number, null>[]>([]);

  useEffect(() => {
    behaviourActions.getBehaviour(
      activity.students[0].id,
      activity.itemId,
      null
    );
  }, [activity]);

  useEffect(() => {
    if (behaviourUpscaleCategoryGroups.length > 0) {
      let categoriesArray: IDropdownDataItemProps<string, number, null>[] = [];

      for (let i = 0; i < behaviourUpscaleCategoryGroups.length; i++) {
        let behaviours = behaviourUpscaleCategoryGroups[
          i
        ].behaviourCategories.map(category => ({
          key: category.id,
          label: `${category.codeDisplayName} - ${category.behaviourCategoryName}`,
          value: category.id,
        }));
        categoriesArray = categoriesArray.concat(behaviours);
      }

      setUpscaleBehaviourCategoriesArray(categoriesArray);
    }
  }, [behaviourUpscaleCategoryGroups]);

  useEffect(() => {
    if (behaviour) {
      setUpscaleBehaviourCategory(null);
      setSelectedBehaviour(behaviour.behaviour);
      behaviourActions.getUpscaleBehaviourCategories(
        schoolInformation.id,
        {
          isLegacy: null,
          canBeAwarded: null,
          behaviourCodeId: null,
          parentCategoryId: behaviour.behaviour.categoryId
        }
      );
    }
  }, [behaviour]);

  useEffect(() => {
    if (refresh) {
      behaviourActions.getBehaviour(
        activity.students[0].id,
        activity.itemId,
        null
      );
    }
  }, [refresh]);

  const checkValidations = (incidentDetails: IIncidentDetails, detentionDetails: IDetentionDetails) => {
    const validationErrors = upscaleBehaviourValidation(
      incidentDetails,
      detentionDetails,
      selectedBehaviour.detentions[0]
        ? selectedBehaviour.detentions[0].detentionId
        : null,
      detentionSummary,
      schoolInformation
    );

    return validationErrors;
  };

  const handleUpscaleBehaviourChange = (behaviourCategoryId: number) => {
    let behaviourCategory: SchoolBehaviourCategoryListView;
    for (let i = 0; i < behaviourUpscaleCategoryGroups.length; i++) {
      behaviourCategory = behaviourUpscaleCategoryGroups[
        i
      ].behaviourCategories.find(
        category => category.id === behaviourCategoryId
      );

      if (behaviourCategory !== undefined) {
        setUpscaleBehaviourCategory(behaviourCategory);
        behaviourActions.getDefaultDetention(
          schoolInformation.id,
          activity.students[0].id,
          behaviourCategoryId
        );
        break;
      }
    }
  };

  const handleUpscaleBehaviour = (detentionOptionId: DetentionOption, behaviourDetails: IBehaviourDetails) => {
    let detention: UpscaleBehaviourDetention = null;
    const detentionDetails = behaviourDetails.detentionDetails;

    if (detentionOptionId === DetentionOption.Yes) {
      detention = {
        detentionTypeId: detentionDetails.detentionTypeId,
        roomId: detentionDetails.detentionLocationId,
        duration: detentionDetails.duration,
        detentionDate: getDateTime(
          formatDate(detentionDetails.date),
          detentionDetails.time
        ),
        detentionNote: null
      };
    }

    const incidentDetails = behaviourDetails.incidentDetails;

    const newBehaviour: UpscaleBehaviourCommand = {
      studentId: activity.students[0].id,
      behaviourCodeId: upscaleBehaviourCategory.behaviourCodeId, 
      investigationNote: null,
      description: behaviourDetails.internalNotes,
      publicNotes: behaviourDetails.publicNotes,
      categoryId: upscaleBehaviourCategory.id,
      points: behaviourDetails.points,
      outcomeId: behaviourDetails.outcome ? behaviourDetails.outcome : null,
      initialActionId: behaviourDetails.initialAction.initialActionId,
      classGroupId: activity.classGroup ? activity.classGroup.id : null,
      tutorGroupId: activity.tutorGroup ? activity.tutorGroup.id : null,
      customGroupId: activity.customGroup ? activity.customGroup.id : null,
      provisionGroupId: activity.provisionGroup
        ? activity.provisionGroup.id
        : null,
      incidentDate: getDateTime(
        formatDate(incidentDetails.incidentDate),
        incidentDetails.incidentTime
      ),
      roomId: incidentDetails.incidentLocation,
      otherLocation: incidentDetails.otherIncidentLocation,
      parentBehaviourId: activity.itemId,
      periodId: incidentDetails && incidentDetails.period,
      upscaleBehaviourDetention: detention,
    };

    const callback = () => {
      handleCloseModal();
    };

    behaviourActions.upscaleBehaviour(
      activity.students[0].id,
      activity.itemId,
      newBehaviour,
      callback
    );
  };

  const getGroup = () => {
    return activity.classGroup
      ? { id: activity.classGroup.id, type: GroupType.ClassGroup }
      : activity.tutorGroup
      ? { id: activity.tutorGroup.id, type: GroupType.TutorGroup }
      : activity.customGroup
      ? { id: activity.customGroup.id, type: GroupType.Custom }
      : activity.provisionGroup
      ? {
          id: activity.provisionGroup.id,
          type: GroupType.Provision,
        }
      : null;
  };

  if (loading || loadingUpscale) {
    return <Loader size={Size.Medium} fluid />;
  }

  if (getBehaviourError) {
    return <ApiExceptionMessage error={getBehaviourError} />;
  }

  return (
    selectedBehaviour && (
      <div className="behaviour-modal">
        <ApiExceptionMessage error={upscaleError} />

        <RelatedBehaviourDetails behaviour={behaviour} />

        <ReadOnlyDetails behaviour={selectedBehaviour} />

        <Title text="Upscale Behaviour" size={TitleSize.H3} />

        <StructuredList>
          <Card>
            <Card.Body>
              <StructuredList.Item name="Upscale Behaviour Category">
                <Dropdown
                  fluid
                  items={upscaleBehaviourCategoriesArray}
                  value={
                    upscaleBehaviourCategory
                      ? upscaleBehaviourCategory.id
                      : null
                  }
                  onChange={value => handleUpscaleBehaviourChange(value)}
                />
              </StructuredList.Item>
            </Card.Body>
          </Card>

          <br />
          {loadingDefaultDetention ? (
            <>
              <Loader size={Size.Medium} fluid />
              <br />
            </>
          ) : (
            upscaleBehaviourCategory && (
              <BehaviourPointForm
                studentId={activity.students[0].id}
                selectedCategory={upscaleBehaviourCategory}
                defaultDetention={defaultDetention}
                handleAddBehaviour={handleUpscaleBehaviour}
                handleCloseModal={handleCloseModal}
                group={getGroup()}
                schoolId={schoolInformation.id}
                schoolInformation={schoolInformation}
                checkValidations={checkValidations}
                upscale
              />
            )
          )}
        </StructuredList>
      </div>
    )
  );
};


export default UpscaleBehaviourFormWrapper;
