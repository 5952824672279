import action from "actions/action";
import { ToastService } from "ui-kit";
import actionTypeBuilder from "actions/actionTypeBuilder";
import store from "reducers/store";
import moment from "moment";
import client from "services/client";
import { urls } from "utils";
import { YearGroupFilter } from "areas/behaviour/types/detentionRegisterRequest.types";
import { callbackType } from "types/common/action.types";
import { AuthorisedPendingBehaviourCommand, BehaviourSummaryListView } from "areas/behaviour/types/behaviourResponses.types";


const builder = new actionTypeBuilder("pendingattention");

const types = {
  SELECTBEHAVIOUR: "selectbehaviour",
  GETPENDINGATTENTIONBEHAVIOURS: builder.build("getpendingattentionbehaviours"),
  CLAIMPENDINGATTENTIONBEHAVIOUR: builder.build(
    "clainpendingattentionbehaviour"
  ),
  AUTHORISEPENDINGBEHAVIOUR: builder.build("authorisependingbehaviour"),
};


const selectBehaviour = (behaviour: BehaviourSummaryListView) =>
  store.dispatch({
    type: types.SELECTBEHAVIOUR,
    payload: behaviour,
  });


const getPendingAttentionBehaviours = (
  schoolId: number,
  {
    academicYearId,
    yearGroupId,
    date,
    behaviourCodeId,
    schoolBehaviourCategoryId,
    searchTerm
  }: YearGroupFilter,
  onSuccess?: callbackType<BehaviourSummaryListView[]>
) => {
  let url = new urls.QueryString(
    `planner/schools/${schoolId}/pendingAttentionBehaviour`
  );
  url.addParam("academicYearId", academicYearId);
  url.addParam("yearGroupId", yearGroupId);
  url.addParam( "date", date 
    ? moment(date).format("YYYY-MM-DD") 
    : null
  );
  url.addParam("behaviourCodeId", behaviourCodeId);
  url.addParam("schoolBehaviourCategoryId", schoolBehaviourCategoryId);
  url.addParam("searchTerm", searchTerm);

  return action<BehaviourSummaryListView[]>(
    () => client.get(url.toUrl()),
    types.GETPENDINGATTENTIONBEHAVIOURS,
    onSuccess
  );
};


const claimPendingAttentionBehaviour = (studentId: string, behaviourId: number, onSuccess?: callbackType<number>) => {
  return action<number>(
    () =>
      client.post(
        `planner/students/${studentId}/pendingBehaviours/${behaviourId}/claim`
      ),
    types.CLAIMPENDINGATTENTIONBEHAVIOUR,
    (response) => {
      ToastService.pop("Behaviour successfully claimed", null, "check-circle");
      if (onSuccess) {
        onSuccess(response);
      }
    }
  );
};


const authorisePendingBehaviour = (
  studentId: string,
  behaviourId: number,
  behaviourPayload: AuthorisedPendingBehaviourCommand,
  onSuccess?: callbackType<number>
) => {
  return action<number>(
    () =>
      client.post(
        `planner/students/${studentId}/pendingBehaviours/${behaviourId}/authorise`,
        behaviourPayload
      ),
    types.AUTHORISEPENDINGBEHAVIOUR,
    response => {
      ToastService.pop("Successfully updated", null, "check-circle");
      if (onSuccess) {
        onSuccess(response);
      }
    }
  );
};


const pendingAttentionBehaviourActions = {
  types,
  selectBehaviour,
  getPendingAttentionBehaviours,
  claimPendingAttentionBehaviour,
  authorisePendingBehaviour,
};

export default pendingAttentionBehaviourActions;
