import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCcVisa,
  faCcAmex,
  faCcMastercard,
  faCcPaypal,
  faCcJcb,
  faCcDinersClub
} from "@fortawesome/free-brands-svg-icons";
import { faCreditCardBlank } from "@fortawesome/pro-solid-svg-icons";


interface ICardLogoProps {
  type: string;
  className?: string;
}

const CardLogo: React.FC<ICardLogoProps> = ({ type, className }) => {
  switch (type) {
    case "Visa":
      return <FontAwesomeIcon className={className} icon={faCcVisa} />;
    case "MasterCard":
      return <FontAwesomeIcon className={className} icon={faCcMastercard} />;
    case "VisaDebit":
      return <FontAwesomeIcon className={className} icon={faCcVisa} />;
    case "MasterCardDebit":
      return <FontAwesomeIcon className={className} icon={faCcMastercard} />;
    case "Maestro":
      return <FontAwesomeIcon className={className} icon={faCcMastercard} />;
    case "VisaElectron":
      return <FontAwesomeIcon className={className} icon={faCcVisa} />;
    case "AmericanExpress":
      return <FontAwesomeIcon className={className} icon={faCcAmex} />;
    case "DinersClub":
      return <FontAwesomeIcon className={className} icon={faCcDinersClub} />;
    case "Jcb":
      return <FontAwesomeIcon className={className} icon={faCcJcb} />;
    case "Paypal":
      return <FontAwesomeIcon className={className} icon={faCcPaypal} />;
    default:
      return <FontAwesomeIcon className={className} icon={faCreditCardBlank} />;
  }
};

export default CardLogo;
