import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAction } from "hooks";
import storeActions from "areas/payments/actions/storeActions";
import { 
  List, 
  Size, 
  Loader, 
  EmptyMessage, 
  Label, 
  Currency 
} from "ui-kit";
import { arrays } from "utils";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { RootState } from "reducers/store";
import { OrderListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


const Orders = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { orders, error, loading } = useSelector((state: RootState) => state.storeOrders);
  const navigate = useNavigate();

  useEffect(() => {
    user && storeActions.getOrdersForUser(user.id);
  }, []);

  const handleOrderClicked = (order: OrderListView) => {
    navigate(`/main/store/orders/${order.id}`);
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem loading your orders"
        cover
      />
    );
  }

  if (arrays.isEmpty(orders)) {
    return (
      <EmptyMessage
        icon="shopping-bag"
        title="No orders found"
        summary="You have not placed any orders yet"
        cover
      />
    );
  }

  return (
    <List title="Your Orders">
      {orders.map((order: OrderListView, index: number) => (
        <List.Item
          onClick={() => handleOrderClicked(order)}
          key={index}
          text={`Order ${order.id}`}
          sub={<Moment date={order.date} format="dddd, MMMM Do YYYY" />}
          right={
            <Label bold>
              <Currency value={order.total} />
            </Label>
          }
        />
      ))}
    </List>
  );
};

export default Orders;
