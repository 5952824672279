import { IBeehiveAction } from "types/common/action.types";
import newsActions from "../../actions/newsActions";
import { ArticleFilter } from "areas/news/types/newsRequest.types";
import { PublishStatus } from "types/common/status.types";

interface IArticleFilterState {
  loading: boolean;
  error: string | null;
  filter: ArticleFilter;
}

const INITIAL_STATE: IArticleFilterState = {
  loading: false,
  error: null,
  filter: {
    categoryId: -1,
    schoolId: -1,
    publishStatus: PublishStatus.Live,
  },
};

const articleFilterReducer = (
  state = INITIAL_STATE,
  action: IBeehiveAction
): IArticleFilterState => {
  const {
    ARTICLES_FILTERBYCATEGORY,
    ARTICLES_FILTERBYSCHOOL,
    ARTICLES_FILTERBYPUBLISHSTATUS,
  } = newsActions.types;

  switch (action.type) {
    case ARTICLES_FILTERBYCATEGORY:
      return {
        ...state,
        filter: { ...state.filter, categoryId: action.payload },
      };

    case ARTICLES_FILTERBYSCHOOL:
      return {
        ...state,
        filter: {
          ...state.filter,
          schoolId: action.payload,
        },
      };

    case ARTICLES_FILTERBYPUBLISHSTATUS:
      return {
        ...state,
        filter: { ...state.filter, publishStatus: action.payload },
      };

    default:
      return state;
  }
};

export default articleFilterReducer;
