import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Loader,
  Size,
  ToastContainer,
  fontStyle,
  typescale,
  neutral,
  displayFont,
  Spacing,
} from "ui-kit";
import quotes, { Quote } from "configuration/quotes";
import Config from "configuration";

const Wrapper = styled.div`
  background: ${neutral[700]};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  padding: ${Spacing.ExtraLarge}px;
  justify-content: center;
`;

const QuoteWrapper = styled.div`
  text-align: center;
  margin: 0 auto ${Spacing.Jumbo * 2}px auto;
  p {
    ${fontStyle(displayFont.medium, typescale.header2, neutral[400])}
    max-width: 500px;
    margin-bottom: ${Spacing.Large}px;
    padding: 0 ${Spacing.Large}px;
  }

  span {
    ${fontStyle(displayFont.medium, typescale.paragraph, neutral[500])}
  }
`;

const LoadingScreen = () => {
  const [quote, setQuote] = useState<Quote | null>(null);

  const getQuote = () => {
    return Math.floor(Math.random() * quotes.length);
  };

  useEffect(() => {
    console.info(`Beehive for Web | Client Version ${Config.clientVersion}`);
    setQuote(quotes[getQuote()]);
  }, []);

  return (
    <Wrapper>
      {quote && (
        <QuoteWrapper>
          <p>{quote?.quote}</p>
          <span>{quote.credit}</span>
        </QuoteWrapper>
      )}
      <Loader size={Size.Large} />
    </Wrapper>
  );
};

export default LoadingScreen;
