import { BulletinFilter } from "areas/news/types/newsRequest.types";
import bulletinActions from "../../actions/bulletinActions";
import { BulletinFullView, BulletinSendHistoryStatisticsDetail } from "areas/news/types/newsResponse.types";
import { IBeehiveAction } from "types/common/action.types";
import { StaffListView } from "types/users/userListViews.types";

interface IBulletinFullState {
  bulletin: BulletinFullView | null;
  filter: BulletinFilter;
  sendHistory: BulletinSendHistoryStatisticsDetail | null;
  testRecipients: StaffListView[];
  preview: string | null;
  actions: {
    load: { working: boolean; error: string | null; };
    loadSendHistory: { working: boolean; error: string | null; };
    send: { working: boolean; error: string | null; };
    testSend: { working: boolean; error: string | null; };
    approve: { working: boolean; error: string | null; };
    preview: { working: boolean; error: string | null; };
  }
}

const INITIAL_STATE: IBulletinFullState = {
  bulletin: null,
  filter: {
    schoolId: -1,
    userType: null,
    staffType: null,
  },
  sendHistory: null,
  testRecipients: [],
  preview: null,
  actions: {
    load: { working: false, error: null },
    loadSendHistory: { working: false, error: null },
    send: { working: false, error: null },
    testSend: { working: false, error: null },
    approve: { working: false, error: null },
    preview: { working: false, error: null },
  },
};

const bulletinFullReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IBulletinFullState => {
  const {
    BULLETIN_GETFULL,
    BULLETIN_GETSENDHISTORYSTATISTICS,
    BULLETIN_SEND,
    BULLETIN_TESTSEND,
    BULLETIN_PREVIEW,
    BULLETIN_UPDATETESTRECIPIENTS,
    BULLETIN_SETFILTER,
  } = bulletinActions.types;

  switch (action.type) {
    case BULLETIN_SETFILTER:
      return { ...state, filter: action.payload };
    case BULLETIN_GETFULL.START:
      return {
        ...INITIAL_STATE,
        actions: { ...state.actions, load: { working: true, error: null } },
      };

    case BULLETIN_GETFULL.SUCCESS:
      return {
        ...state,
        bulletin: action.payload,
        actions: { ...state.actions, load: { working: false, error: null } },
      };

    case BULLETIN_GETFULL.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          load: {
            working: false,
            error: "There was a problem loading the bulletin.",
          },
        },
      };

    case BULLETIN_SEND.START:
      return {
        ...state,
        actions: { ...state.actions, send: { working: true, error: null } },
      };

    case BULLETIN_SEND.SUCCESS:
      if (!state.bulletin) {
        return state;
      }
      return {
        ...state,
        bulletin: {
          ...state.bulletin,
          sendCount: state.bulletin?.sendCount + 1,
          sendHistory: [action.payload, ...state.bulletin?.sendHistory],
        },
        actions: { ...state.actions, send: { working: false, error: null } },
      };

    case BULLETIN_SEND.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          send: {
            working: false,
            error: "There was a problem sending the bulletin.",
          },
        },
      };

    case BULLETIN_UPDATETESTRECIPIENTS:
      return {
        ...state,
        testRecipients: action.payload,
      };

    case BULLETIN_TESTSEND.START:
      return {
        ...state,
        actions: { ...state.actions, testSend: { working: true, error: null } },
      };

    case BULLETIN_TESTSEND.SUCCESS:
      if (!state.bulletin) {
        return state;
      }
      return {
        ...state,
        bulletin: {
          ...state.bulletin,
          sendCount: state.bulletin?.sendCount + 1,
          sendHistory: [action.payload, ...state.bulletin?.sendHistory],
        },
        actions: {
          ...state.actions,
          testSend: { working: false, error: null },
        },
      };

    case BULLETIN_TESTSEND.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          testSend: {
            working: false,
            error: "There was a problem sending the test bulletin.",
          },
        },
      };

    case BULLETIN_PREVIEW.START:
      return {
        ...state,
        actions: { ...state.actions, preview: { working: true, error: null } },
      };

    case BULLETIN_PREVIEW.SUCCESS:
      return {
        ...state,
        preview: action.payload,
        actions: {
          ...state.actions,
          preview: { working: false, error: null },
        },
      };

    case BULLETIN_PREVIEW.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          preview: {
            working: false,
            error: "There was a problem previewing the bulletin.",
          },
        },
      };

    case BULLETIN_GETSENDHISTORYSTATISTICS.START:
      return {
        ...state,
        actions: {
          ...state.actions,
          loadSendHistory: {
            working: true,
            error: null,
          },
        }
      };

    case BULLETIN_GETSENDHISTORYSTATISTICS.SUCCESS:
      return {
        ...state,
        sendHistory: action.payload,
        actions: {
          ...state.actions,
          loadSendHistory: {
            working: false,
            error: null,
          },
        }
      };

    case BULLETIN_GETSENDHISTORYSTATISTICS.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          loadSendHistory: {
            working: false,
            error: "There was a problem loading the send history",
          },
        }
      };

    default:
      return state;
  }
};

export default bulletinFullReducer;
