import React from "react";
import PropTypes from "prop-types";
import { StructuredList } from "ui-kit";
import styled from "styled-components";
import Address from "sharedComponents/common/formatting/address";

const Wrapper = styled.div`
  width: 50%;
`;

const ParentDetails = ({ parent }) => {
  const parentDetails = [
    {
      data: "Name",
      value: `${parent.firstName} ${parent.lastName}`,
    },
    {
      data: "Email",
      value: <a href={`mailto:${parent.email}`}>{parent.email}</a>,
    },
  ];

  return (
    <Wrapper>
      <StructuredList>
        {parentDetails.map((dataItem, index) => (
          <StructuredList.Item key={index} name={dataItem.data}>
            {dataItem.value}
          </StructuredList.Item>
        ))}

        {parent.telephoneNumbers &&
          parent.telephoneNumbers.map((telephone, index) => (
            <StructuredList.Item key={index} name={telephone.location}>
              {telephone.number}
            </StructuredList.Item>
          ))}

        {parent.address && (
          <StructuredList.Item name="Address">
            <Address address={parent.address} />
          </StructuredList.Item>
        )}
      </StructuredList>
    </Wrapper>
  );
};

ParentDetails.propTypes = {
  parent: PropTypes.object,
};

export default ParentDetails;
