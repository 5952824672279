import defaults from "configuration/defaults";
import feedActions from "../actions/feedActions";
import { PageInfo } from "types/common/paging.types";
import { IBeehiveAction } from "types/common/action.types";
import { IFeedItem } from "./feedReducer";


interface IFavouritesState {
  favourites: IFeedItem[],
  loading: boolean;
  error: string | null;
  paging: PageInfo;
  refresh: boolean;
}

const INITIAL_STATE: IFavouritesState = {
  favourites: [],
  loading: false,
  error: null,
  paging: defaults.DEFAULT_PAGING,
  refresh: false,
};


const favouritesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IFavouritesState => {
  const {
    GETFAVOURITES,
    FEED_ARTICLE_FAVOURITE,
    FEED_ARTICLE_UNFAVOURITE,
    FEED_ARTICLE_LIKE,
    FEED_ARTICLE_UNLIKE,
    FEED_ARTICLE_DISMISS,
    FEED_ARTICLE_UNDISMISS,
  } = feedActions.types;

  switch (action.type) {
    case GETFAVOURITES.START:
      return { 
        ...state, 
        loading: true, 
        error: null, 
        refresh: false 
      };

    case GETFAVOURITES.SUCCESS:
      var newState = {
        ...state,
        favourites:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.favourites, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };
      return newState;

    case GETFAVOURITES.FAILED:
      return { 
        ...state, 
        loading: false, 
        error: action.payload 
      };

    case FEED_ARTICLE_FAVOURITE.SUCCESS:
      return {
        ...state,
        favourites: state.favourites.map(x =>
          x.id == action.payload && x.type == 0 ? { ...x, favourited: true } : x
        ),
        refresh: true,
      };

    case FEED_ARTICLE_UNFAVOURITE.SUCCESS:
      return {
        ...state,
        favourites: state.favourites.map(x =>
          x.id == action.payload && x.type == 0
            ? { ...x, favourited: false }
            : x
        ),
        refresh: true,
      };

    case FEED_ARTICLE_LIKE.SUCCESS:
      return {
        ...state,
        favourites: state.favourites.map(x =>
          x.id == action.payload && x.type == 0 ? { ...x, liked: true } : x
        ),
        refresh: true,
      };

    case FEED_ARTICLE_UNLIKE.SUCCESS:
      return {
        ...state,
        favourites: state.favourites.map(x =>
          x.id == action.payload && x.type == 0 ? { ...x, liked: false } : x
        ),
        refresh: true,
      };

    case FEED_ARTICLE_DISMISS.SUCCESS:
      return {
        ...state,
        favourites: state.favourites.map(x =>
          x.id == action.payload && x.type == 0 ? { ...x, dismissed: true } : x
        ),
        refresh: true,
      };

    case FEED_ARTICLE_UNDISMISS.SUCCESS:
      return {
        ...state,
        favourites: state.favourites.map(x =>
          x.id == action.payload && x.type == 0 ? { ...x, dismissed: false } : x
        ),
        refresh: true,
      };

    default:
      return state;
  }
};

export default favouritesReducer;
