import React, { useEffect, useState } from "react";
import { EmptyMessage, Loader, Size } from "ui-kit";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import DismissedItem from "./dismissedItem";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Constants } from "configuration";
import FeedNotice from "./feedNotice";
import FeedCalendarEvent from "./feedCalendarEvent";
import FeedFeature from "./feedFeature";
import feedActions from "../actions/feedActions";
import EventModal from "areas/calendar/components/events/eventModal";
import { RootState } from "reducers/store";
import { IFeedItem } from "../reducers/feedReducer";


const FeedList = () => {

  var { feed, paging, loading, error } = useSelector((state: RootState) => state.feed);
  var { user } = useSelector((state: RootState) => state.currentUser);

  const [pageIndex, setPageIndex] = useState<number>(0);
  const [_selectedEvent, _setSelectedEvent] = useState<IFeedItem | null>(null);

  const loadFeed = (pageIndex: number) => {
    feedActions.getFeed(user.id, pageIndex);
  };

  useEffect(() => {
    // NB - This will run on first load as the filter will change initially.
    // Only load the data if the articles are not already populated.
    if (user && (!feed || arrays.isEmpty(feed))) {
      loadFeed(0);
    }
  }, []);

  useEffect(() => {
    if (user && pageIndex > 0) {
      loadFeed(pageIndex);
    }
  }, [pageIndex]);

  const onPage = () => {
    setPageIndex(paging.pageIndex + 1);
  };

  const handleMultiEventSignup = (event: IFeedItem) => {
    _setSelectedEvent(event);
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: paging?.pageNumber < paging?.totalPages,
    onLoadMore: onPage,
  });

  const handleOnUnsignedUp = (event: IFeedItem) => {
    //_setSelectedEvent(null)
  };

  const handleOnSignedUp = (event: IFeedItem) => {
    //_setSelectedEvent(null)
  };


  const FeedItemComponent = ({ item } : { item: IFeedItem }) => {

    switch (item.type) {
      case Constants.FEED_ITEM_TYPES.NOTICE:
        return <FeedNotice item={item} />;
      case Constants.FEED_ITEM_TYPES.FEATURE:
        return <FeedFeature item={item} />; //<FeedArticle item={item}/>
      case Constants.FEED_ITEM_TYPES.CALENDAR_EVENT:
        return (
          <FeedCalendarEvent
            item={item}
            onMultiEventSignup={handleMultiEventSignup}
          />
        );
      default:
        return null;
    }
  };

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem loading your feed"
        cover
      />
    );
  }

  if (!feed || arrays.isEmpty(feed)) {
    return loading ? (
      <Loader size={Size.Large} fluid />
    ) : (
      <EmptyMessage
        icon="paper-plane"
        title="Nothing to see here..."
        summary="There is nothing in your feed"
        cover
      />
    );
  }

  return (
    <>
      {feed.map((item: IFeedItem, index: number) =>
        item.dismissed ? (
          <DismissedItem item={item} />
        ) : (
          <FeedItemComponent key={index} item={item} />
        )
      )}
      {(loading || paging.pageNumber < paging.totalPages) && (
        <div ref={sentryRef}>
          <Loader size={Size.Medium} />
        </div>
      )}

      <EventModal
        userId={user?.id}
        event={_selectedEvent}
        open={_selectedEvent != null}
        onClose={() => _setSelectedEvent(null)}
        onSave={null}
        onCancel={null}
      />
    </>
  );
};

export default FeedList;
