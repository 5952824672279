import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import styled from "styled-components";
import { AbilityStatus } from "types/users/userSen.types";
import { ActionBar, Button, Checkbox, CheckboxList, Modal, Orientation, RadioList, Right, Size, Spacing, Subtitle, Swatches } from "ui-kit";
import { StudentQueryFilter } from "./types/searchRequest.types";
import { RoleView } from "sharedComponents/common";
import { Constants } from "configuration";


const FiltersWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  margin: 0 ${Spacing.Default}px 0 ${Spacing.Default}px;
`

const DividerWrapper = styled.div`
  margin-bottom: ${Spacing.Default}px;
  margin-top: 0px;

  hr {
    border-top: 1px solid #cccccc;
    color: #ccc;
  }
`;


interface IStudentSearchFiltersModalProps {
  open: boolean;
  loading?: boolean;
  onClear?: () => void;
  onClose?: () => void;
  onApply?: (filter: StudentQueryFilter) => void;
}

const StudentSearchFiltersModal: React.FC<IStudentSearchFiltersModalProps> = ({open, loading, onClear, onClose, onApply}) => {

  const filterDefaults: StudentQueryFilter = {
    schools: [],
    eal: false,
    pupilPremium: false,
    bursary: false,
    freeSchoolMeals: false,
    sen: false,
    abilityStatus: null,
    leaver: false
  }
  const { user } = useSelector((state: RootState) => state.currentUser);
  const [_open, _setOpen] = useState<boolean>(open);
  const [filter, setFilter] = useState<StudentQueryFilter>(filterDefaults);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const handleClear = () => {
    setFilter(filterDefaults);
    onClear?.();
  }

  const handleClose = () => {
    onClose?.();
  }

  const handleApply = () => {
    onApply?.(filter);
  }
  
  return (
    <Modal
      onClose={handleClose}
      open={_open}
      title={"Student Search Filter"}
      width="70%"
      height="75%"
    >
      <Modal.Body>
        <FiltersWrapper>

          <FilterColumn title="Schools">
            <CheckboxList
              orientation={Orientation.Portrait}
              onChange={selectedSchoolIds => setFilter({...filter, schools: selectedSchoolIds})}
            >
              {[...user.schools, user.baseSchool]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((school, index) => (
                <CheckboxList.Item
                  key={index}
                  text={school?.name}
                  value={school?.id}
                />
              ))}
            </CheckboxList>
          </FilterColumn>

          <FilterColumn title="Language">
            <Checkbox
              text={"EAL"}
              checked={filter.eal}
              onChange={eal => setFilter({...filter, eal: eal.checked})}
            />
          </FilterColumn>

          <FilterColumn title="Finance">
            <Checkbox
              text={"Pupil Premium"}
              checked={filter.pupilPremium}
              onChange={pp => setFilter({...filter, pupilPremium: pp.checked})}
            />
            <Checkbox
              text={"Bursary"}
              checked={filter.bursary}
              onChange={bur => setFilter({...filter, bursary: bur.checked})}
            />
            <Checkbox
              text={"Free School Meal"}
              checked={filter.freeSchoolMeals}
              onChange={fsm => setFilter({...filter, freeSchoolMeals: fsm.checked})}
            />
          </FilterColumn>

          <FilterColumn title="SEND">
            <Checkbox
              text={"SEN"}
              checked={filter.sen}
              onChange={sen => setFilter({...filter, sen: sen.checked})}
            />
          </FilterColumn>

          <RoleView roles={[
            Constants.ROLES.SEN_ADMINISTRATOR,
            Constants.ROLES.SEN_COORDINATOR,
            Constants.ROLES.LEADERSHIP,
            Constants.ROLES.TRUST_LEADERSHIP,
            Constants.ROLES.IT_ADMINISTRATOR,
            Constants.ROLES.DEVELOPMENT,
          ]}>
            <FilterColumn title="Admission">
              <Checkbox
                text={"Leaver"}
                checked={filter.leaver}
                onChange={leaver => setFilter({...filter, leaver: leaver.checked})}
              />
            </FilterColumn>
          </RoleView>

          {/* <FilterColumn title="Ability Status">
            <CheckboxList
              orientation={Orientation.Portrait}
              onChange={ability => setFilter({...filter, abilityStatus: ability[0]})}
              radio
            >
              <CheckboxList.Item
                text={"LAP"}
                value={AbilityStatus.Lap}
              />
              <CheckboxList.Item
                text={"MAP"}
                value={AbilityStatus.Map}
              />
              <CheckboxList.Item
                text={"HAP"}
                value={AbilityStatus.Hap}
              />
            </CheckboxList>
          </FilterColumn> */}

        </FiltersWrapper>
      </Modal.Body>

      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              text="Clear"
              color={Swatches.Low}
              size={Size.Small}
              onClick={handleClear}
              working={loading}
            />
            <Button
              text="Apply"
              color={Swatches.Success}
              size={Size.Small}
              onClick={handleApply}
              working={loading}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  )
}


interface IFilterColumnProps {
  title: string;
  children: React.ReactNode;
}

const FilterColumn: React.FC<IFilterColumnProps> = ({title, children}) => {

    return (
      <ColumnWrapper>
        <Subtitle text={title} />
        <DividerWrapper>
          <hr />
        </DividerWrapper>
        
        {children}
      </ColumnWrapper>
    )
}

export default StudentSearchFiltersModal;