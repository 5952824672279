import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { CloneDeclarationOfInterestVersionCommand, DeclarationsOfInterestAdminFilter, SaveDeclarationOfInterestCommand, SaveDeclarationOfInterestVersionCommand, SubmitDeclarationOfInterestVersionCommand } from "areas/forms/types/declarations/declarationsOfInterestRequest.types";
import { DeclarationOfInterestAdminView, DeclarationOfInterestDetailView, DeclarationOfInterestListView, DeclarationOfInterestVersionDetailView } from "areas/forms/types/declarations/declarationsOfInterestResponse.types";
import store from "reducers/store";
import client from "services/client";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { PagedQueryView } from "types/common/views.types";
import { StaffListView } from "types/users/userListViews.types";
import { urls } from "utils";


const builder = new actionTypeBuilder("adminsubmision");

const types = {
  GETFORSTAFF: builder.build("GETFORSTAFF"),
  GET_DECLARATION: builder.build("GETDECLARATION"),
  GET_DECLARATION_VERSION: builder.build("GETDECLARATIONVERSION"),
  CREATE_DECLARATION: builder.build("CREATEDECLARATION"),
  UPDATE_DECLARATION: builder.build("UPDATEDECLARATION"),
  DELETE_DECLARATION: builder.build("DELETEDECLARATION"),
  CREATE_DECLARATION_VERSION: builder.build("CREATEDECLARATIONVERSION"),
  UPDATE_DECLARATION_VERSION: builder.build("UPDATEDECLARATIONVERSION"),
  CLONE_DECLARATION_VERSION: builder.build("CLONEDECLARATIONVERSION"),
  SUBMIT_DECLARATION_VERSION: builder.build("SUBMITDECLARATIONVERSION"),
  DELETE_DECLARATION_VERSION: builder.build("DELETEDECLARATIONVERSION"),
  ADMIN_GET_DECLARATIONS: builder.build("ADMINGETDECLARATIONS"),
  ADMIN_GET_DECLARATION: builder.build("ADMINGETDECLARATION"),
  FILTERBYSUBMITTER: "FILTERBYSUBMITTER",
  FILTERBYSCHOOL: "FILTERBYSCHOOL",
  FILTERBYSEARCHTERM: "FILTERBYSEARCHTERM",
  FILTERBYACADEMICYEAR: "FILTERBYACADEMICYEAR"
};


const getDeclarationsOfInterestForStaff = (
  staffId: string,
  callback?: callbackType<DeclarationOfInterestListView[]>
) =>
  action<DeclarationOfInterestListView[]>(
    () => client.get(`declarations/${staffId}/declarationsOfInterest`),
    types.GETFORSTAFF,
    callback
  );


const getDeclarationOfInterest = (
  staffId: string,
  declarationId: number,
  callback?: callbackType<DeclarationOfInterestDetailView>
) =>
  action<DeclarationOfInterestDetailView>(
    () => client.get(`declarations/${staffId}/declarationsOfInterest/${declarationId}`),
    types.GET_DECLARATION,
    callback
  );


const getDeclarationOfInterestVersion = (
  staffId: string,
  declarationId: number,
  versionId: number,
  callback?: callbackType<DeclarationOfInterestVersionDetailView>
) =>
  action<DeclarationOfInterestVersionDetailView>(
    () => client.get(`declarations/${staffId}/declarationsOfInterest/${declarationId}/versions/${versionId}`),
    types.GET_DECLARATION_VERSION,
    callback
  );


const createDeclarationOfInterest = (
    staffId: string,
    command: SaveDeclarationOfInterestCommand,
    callback?: callbackType<DeclarationOfInterestDetailView>
) =>
  action<DeclarationOfInterestDetailView>(
    () => client.post(`declarations/${staffId}/declarationsOfInterest`, command),
    types.CREATE_DECLARATION,
    callback
  );


const updateDeclarationOfInterest = (
  staffId: string,
  declarationId: number,
  command: SaveDeclarationOfInterestCommand,
  callback?: callbackType<DeclarationOfInterestDetailView>
) =>
  action<DeclarationOfInterestDetailView>(
    () => client.post(`declarations/${staffId}/declarationsOfInterest/${declarationId}`, command),
    types.UPDATE_DECLARATION,
    callback
  );


const deleteDeclarationOfInterest = (
  staffId: string,
  declarationId: number,
  callback?: callbackType<number>
) =>
  action<number>(
    () => client.delete(`declarations/${staffId}/declarationsOfInterest/${declarationId}`),
    types.DELETE_DECLARATION,
    callback
  );


const createDeclarationOfInterestVersion = (
  staffId: string,
  declarationId: number,
  command: SaveDeclarationOfInterestVersionCommand,
  callback?: callbackType<DeclarationOfInterestDetailView>
) =>
  action<DeclarationOfInterestDetailView>(
    () => client.post(`declarations/${staffId}/declarationsOfInterest/${declarationId}/versions`, command),
    types.CREATE_DECLARATION_VERSION,
    callback
  );


const updateDeclarationOfInterestVersion = (
  staffId: string,
  declarationId: number,
  versionId: number,
  command: SaveDeclarationOfInterestVersionCommand,
  callback?: callbackType<DeclarationOfInterestVersionDetailView>
) =>
  action<DeclarationOfInterestVersionDetailView>(
    () => client.post(`declarations/${staffId}/declarationsOfInterest/${declarationId}/versions/${versionId}`, command),
    types.UPDATE_DECLARATION_VERSION,
    callback
  );


const cloneDeclarationOfInterestVersion = (
  command: CloneDeclarationOfInterestVersionCommand,
  callback?: callbackType<DeclarationOfInterestVersionDetailView>
) =>
  action<DeclarationOfInterestVersionDetailView>(
    () => client.post(`declarations/${command.staffId}/declarationsOfInterest/${command.declarationOfInterestId}/versions/${command.versionId}/clone`, command),
    types.CLONE_DECLARATION_VERSION,
    callback
  );


const submitDeclarationOfInterestVersion = (
  command: SubmitDeclarationOfInterestVersionCommand,
  callback?: callbackType<number>
) =>
  action<number>(
    () => client.post(`declarations/${command.staffId}/declarationsOfInterest/${command.id}/versions/${command.declarationOfInterestVersionId}/submit`, command),
    types.SUBMIT_DECLARATION_VERSION,
    callback
  );


const deleteDeclarationOfInterestVersion = (
  staffId: string,
  declarationId: number,
  versionId: number,
  callback?: callbackType<number>
) =>
  action<number>(
    () => client.delete(`declarations/${staffId}/declarationsOfInterest/${declarationId}/versions/${versionId}`),
    types.DELETE_DECLARATION_VERSION,
    callback
  );


//ADMIN


const getDeclarationsForAdmin = (
  filter: DeclarationsOfInterestAdminFilter,
  pageIndex: number,
  callback?: callbackType<PagedQueryView<DeclarationOfInterestListView>>,
) => {
  var url = new urls.QueryString(`declarations/administration`);
  url.addParams(filter);
  url.addPaging(pageIndex);

  return action<PagedQueryView<DeclarationOfInterestListView>>(
    () => client.get(url.toUrl()),
    types.ADMIN_GET_DECLARATIONS,
    callback,
  );
};


const getDeclarationOfInterestForAdmin = (
  staffId: string,
  declarationId: number,
  callback?: callbackType<DeclarationOfInterestAdminView>
) =>
  action<DeclarationOfInterestAdminView>(
    () => client.get(`declarations/administration/${staffId}/${declarationId}`),
    types.ADMIN_GET_DECLARATION,
    callback
  );


const filterBySchool = (schoolId: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.FILTERBYSCHOOL,
    payload: schoolId,
  });
};


const filterBySubmitter = (user: StaffListView) => {
  store.dispatch<IBeehiveAction<StaffListView>>({
    type: types.FILTERBYSUBMITTER,
    payload: user,
  });
}


const filterBySearchTerm = (searchTerm: string) => {
  store.dispatch<IBeehiveAction<string>>({
    type: types.FILTERBYSEARCHTERM,
    payload: searchTerm,
  });
};


const filterByAcadmicYear = (academicYearId: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.FILTERBYACADEMICYEAR,
    payload: academicYearId,
  });
};


const declarationsOfInterestActions = {
  types,
  getDeclarationsOfInterestForStaff,
  getDeclarationOfInterest,
  getDeclarationOfInterestVersion,
  createDeclarationOfInterest,
  updateDeclarationOfInterest,
  deleteDeclarationOfInterest,
  createDeclarationOfInterestVersion,
  updateDeclarationOfInterestVersion,
  cloneDeclarationOfInterestVersion,
  submitDeclarationOfInterestVersion,
  deleteDeclarationOfInterestVersion,
  getDeclarationsForAdmin,
  getDeclarationOfInterestForAdmin,
  filterBySubmitter,
  filterBySchool,
  filterBySearchTerm,
  filterByAcadmicYear
}

export default declarationsOfInterestActions;