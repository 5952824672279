import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";
import syncActions from "areas/administration/actions/syncAction";
import { PageInfo } from "types/common/paging.types";
import { UserWarningItem } from "areas/administration/types/syncResponses.types";
import defaults from "reducers/defaults";

interface IWarningsForUserState {
  warnings: UserWarningItem[] | null;
  paging: PageInfo | null;
  loading: boolean;
  loadingExportReport: boolean;
  error: IBeehiveError | null;
}

const INITIAL_STATE: IWarningsForUserState = {
  warnings: null,
  paging: defaults.DEFAULT_PAGING,
  loading: false,
  loadingExportReport: false,
  error: null,
};

const syncUserWarningReducer = (
  state = INITIAL_STATE,
  action: IBeehiveAction
): IWarningsForUserState => {
  const { GETWARNINGSFORUSER, EXPORTWARNINGSFORUSER } = syncActions.types;

  switch (action.type) {
    case GETWARNINGSFORUSER.START:
      return { ...state, loading: true, error: null };

    case GETWARNINGSFORUSER.SUCCESS:
      return {
        ...state,
        warnings:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.warnings, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };

    case GETWARNINGSFORUSER.FAILED:
      return { ...state, loading: false, error: action.payload };

    case EXPORTWARNINGSFORUSER.START:
      return { ...state, loadingExportReport: true, error: null };

    case EXPORTWARNINGSFORUSER.SUCCESS:
      return { ...state, loadingExportReport: false };

    case EXPORTWARNINGSFORUSER.FAILED:
      return { ...state, loadingExportReport: false, error: action.payload };

    default:
      return state;
  }
};

export default syncUserWarningReducer;
