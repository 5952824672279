import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { urls } from "utils";
import client from "services/client";
import { BeehiveReport, callbackType } from "types/common/action.types";
import { HolidayCardReportFilter, LeaveRequestReportFilter } from "../types/leaveRequest.types";

const prefix = "hrreports";
const builder = new actionTypeBuilder(prefix);

const types = {
  HOLIDAYCARDS_REPORT: builder.build("HOLIDAYCARDS_REPORT"),
  LEAVEREQUESTS_REPORT: builder.build("LEAVEREQUESTS_REPORT"),
  STAFFLEAVEREQUESTS_REPORT: builder.build("STAFFLEAVEREQUESTS_REPORT"),
};


const getHolidayCardReport = (filter: HolidayCardReportFilter, callback?: callbackType<BeehiveReport>) => {
  let url = new urls.QueryString(`pm/leave/reporting/holidayCards`);
  url.addParams(filter);

  return action<BeehiveReport>(
    () => client.get(url.toUrl(), { responseType: "text" }),
    types.HOLIDAYCARDS_REPORT,
    callback
  );
};

const getLeaveRequestReport = (filter: LeaveRequestReportFilter, callback?: callbackType<BeehiveReport>) => {
  let url = new urls.QueryString(`pm/leave/reporting/leave`);
  url.addParams(filter);

  return action<BeehiveReport>(
    () => client.get(url.toUrl(), { responseType: "text" }),
    types.LEAVEREQUESTS_REPORT,
    callback
  );
};


const reportingActions = {
  types,
  getHolidayCardReport,
  getLeaveRequestReport
};

export default reportingActions;
