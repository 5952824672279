import React from "react";
import { Routes, Route } from "react-router-dom";
import Events from "./events/events";
import EventViewer from "./events/eventViewer";
import EventEditor from "./events/eventEditor";
import { Constants } from "configuration";
import WallPlannerEditor from "./wallPlanner/wallPlannerEditor";
import { eventRoutes, wallplannerRoutes } from "../calendarRoutes";
import CustomRoute from "sharedComponents/layout/routes/customRoute";
import UserEventViewer from "./events/userEventViewer";

const Index = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <CustomRoute>
            <Events />
          </CustomRoute>
        }
      />

      <Route
        path={eventRoutes.new}
        element={
          <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
            <EventEditor />
          </CustomRoute>
        }
      />

      <Route
        path={eventRoutes.event}
        element={
          <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
            <EventViewer />
          </CustomRoute>
        }
      />

      <Route
        path={eventRoutes.edit}
        element={
          <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
            <EventEditor />
          </CustomRoute>
        }
      />

      <Route
        path={eventRoutes.signup}
        element={
          <CustomRoute>
            <UserEventViewer />
          </CustomRoute>
        }
      />

      <Route
        path={wallplannerRoutes.edit}
        element={
          <CustomRoute
            users={[Constants.USER_TYPES.STAFF]}
            roles={[
              Constants.ROLES.WALLPLANNER_USER,
              Constants.ROLES.WALLPLANNER_ADMINISTRATOR,
            ]}
          >
            <WallPlannerEditor />
          </CustomRoute>
        }
      />
    </Routes>
  );
};

export default Index;
