import React from "react";
import styled from "styled-components";
import {
  Icon,
  Swatches,
  Tooltip
} from "../index";
import Tippy from "@tippyjs/react";

export interface IHelpPopupProps {
  title: string | JSX.Element;
  text?: string | React.ReactElement;
}

const Wrapper = styled.span`
  i,
  .icon {
    color: ${Swatches.Blue.swatch};
    transition: all ease 150ms;
  }

  &:hover {
    cursor: help;
    i,
    .icon {
      color: ${Swatches.Cancel.swatch};
    }
  }
`;

const HelpPopup: React.FC<IHelpPopupProps> = ({ title, text }) => {
  return (
    <Tippy content={<Tooltip title={title} sub={text} />} arrow="">
      <Wrapper className={`help-popup`}>
        <Icon value="question-circle" />
      </Wrapper>
    </Tippy>
  );
};

export default HelpPopup;
