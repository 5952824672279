import React from "react";
import { Constants } from "configuration";
import { useAppSelector } from "../../../reducers/hooks";

export interface IUserViewProps {
  children?: React.ReactElement<IUserTypeViewProps> | React.ReactElement<IUserTypeViewProps>[];
}

const UserTypeView: React.FC<IUserViewProps> & {
  Item: typeof UserTypeViewItem;
} = ({ children }) => {

  const { user } = useAppSelector(state => state.currentUser);

  if (!user) {
    return null;
  }

  const canShowChild = (child: React.ReactElement<IUserTypeViewProps>) => {
    return (
      (!child.props.inverse && child.props.userType === user.userType) ||
      (child.props.inverse && child.props.userType !== user.userType)
    );
  };

  return (
    <>
      { children && (
        Array.isArray(children) && children.length >= 1
          ? children
              .filter(child => canShowChild(child))
              .map((child, index) => {
                return React.cloneElement(child, {
                  key: index,
                });
              })
          : !Array.isArray(children) && canShowChild(children)
            ? children
            : null
      )}
    </>
  );
};

// enum userType {
//   STAFF = "Staff",
//   STUDENT = "Student",
//   PARENT = "Parent",
// }
export interface IUserTypeViewProps {
  children?: React.ReactNode | React.ReactNode[];
  inverse?: boolean;
  userType?: string;
}

const UserTypeViewItem: React.FC<IUserTypeViewProps> = ({ children }) => {
  if (children) {
    return (
      <>{children}</>
    )
  } else {
    return (
      <></>
    )
  };
};

UserTypeView.Item = UserTypeViewItem;

interface IViewProps {
  children?: React.ReactNode | React.ReactNode[];
}

export const ParentView: React.FC<IViewProps> = ({ children }) => {
  return (
    <UserTypeView>
      <UserTypeView.Item userType={Constants.USER_TYPES.PARENT}>
        <>{children}</>
      </UserTypeView.Item>
    </UserTypeView>
  );
};
export const StaffView: React.FC<IViewProps> = ({ children }) => {
  return (
    <UserTypeView>
      <UserTypeView.Item userType={Constants.USER_TYPES.STAFF}>
        {children}
      </UserTypeView.Item>
    </UserTypeView>
  );
};
export const StudentView: React.FC<IViewProps> = ({ children }) => {
  return (
    <UserTypeView>
      <UserTypeView.Item userType={Constants.USER_TYPES.STUDENT}>
        {children}
      </UserTypeView.Item>
    </UserTypeView>
  );
};

export const NonStaffView: React.FC<IViewProps> = ({ children }) => {
  return (
    <UserTypeView>
      <UserTypeView.Item userType={Constants.USER_TYPES.STAFF} inverse>
        {children}
      </UserTypeView.Item>
    </UserTypeView>
  );
};

export const NonStudentView: React.FC<IViewProps> = ({ children }) => {
  return (
    <UserTypeView>
      <UserTypeView.Item userType={Constants.USER_TYPES.STUDENT} inverse>
        {children}
      </UserTypeView.Item>
    </UserTypeView>
  );
};

export const NonParentView: React.FC<IViewProps> = ({ children }) => {
  return (
    <UserTypeView>
      <UserTypeView.Item userType={Constants.USER_TYPES.PARENT} inverse>
        {children}
      </UserTypeView.Item>
    </UserTypeView>
  );
};

export default UserTypeView;
