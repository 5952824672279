import React from "react";
import { Subtitle, Checkbox } from "ui-kit";
import styled from "styled-components";
import BehaviourCodeCheckbox from "./behaviourCodeCheckbox";
import { BehaviourIncludeType } from "areas/analytics/types/behaviourAnalyticsShared.types";
import { SchoolBehaviourCodeListView } from "areas/behaviour/types/behaviourResponses.types";
import { CategoryType } from "areas/analytics/types/analytics.shared";


const CheckboxListsWrapper = styled.div`
  display: flex;

  .behaviour-codes,
  .reward-codes {
    width: 50%;
  }
`;


interface IBehaviourCodeFiltersProps {
  behaviourTypesToInclude: BehaviourIncludeType;
  behaviourTypesToIncludeName: string;
  filterByPointCode: boolean;
  handleFilterbyPointCodeClick: () => void;
  rewardCodes: SchoolBehaviourCodeListView[];
  behaviourCodes: SchoolBehaviourCodeListView[];
  selectedCodes: SchoolBehaviourCodeListView[];
  handleCodeClick: (code: SchoolBehaviourCodeListView, isChecked: boolean) => void;
}

const BehaviourCodeFilters: React.FC<IBehaviourCodeFiltersProps> = ({
  behaviourTypesToInclude,
  behaviourTypesToIncludeName,
  filterByPointCode,
  handleFilterbyPointCodeClick,
  rewardCodes,
  behaviourCodes,
  selectedCodes,
  handleCodeClick,
}) => {

  return (
    <>
      <Checkbox
        text={`Filter by ${behaviourTypesToIncludeName} Codes`}
        checked={filterByPointCode}
        onChange={handleFilterbyPointCodeClick}
      />
      {filterByPointCode && (
        <>
          {behaviourTypesToInclude === BehaviourIncludeType.Reward ? (
            rewardCodes.map(rewardCode => (
              <BehaviourCodeCheckbox
                code={rewardCode}
                selectedBehaviourCodes={selectedCodes}
                handleCodeClick={handleCodeClick}
              />
            ))
          ) : behaviourTypesToInclude === BehaviourIncludeType.Behaviour ? (
            behaviourCodes.map(behaviourCode => (
              <BehaviourCodeCheckbox
                code={behaviourCode}
                selectedBehaviourCodes={selectedCodes}
                handleCodeClick={handleCodeClick}
              />
            ))
          ) : (
            <CheckboxListsWrapper>
              <div className="reward-codes">
                <Subtitle text="Reward Codes" />
                {rewardCodes.map(rewardCode => (
                  <BehaviourCodeCheckbox
                    code={rewardCode}
                    selectedBehaviourCodes={selectedCodes}
                    handleCodeClick={handleCodeClick}
                  />
                ))}
              </div>
              <div className="behaviour-codes">
                <Subtitle text="Behaviour Codes" />
                {behaviourCodes.map(behaviourCode => (
                  <BehaviourCodeCheckbox
                    code={behaviourCode}
                    selectedBehaviourCodes={selectedCodes}
                    handleCodeClick={handleCodeClick}
                  />
                ))}
              </div>
            </CheckboxListsWrapper>
          )}
        </>
      )}
    </>
  );
};

export default BehaviourCodeFilters;
