import React, { useEffect, useState, useRef } from "react";
import BehaviourAnalyticsForm, { BehaviourFilter } from "./behaviourAnalyticsForm";
import ReportTable from "./reportTable";
import { arrays } from "utils";
import ReportDescription from "../reportDescription";
import { Title, TitleSize } from "ui-kit";
import schoolsActions from "areas/planner/actions/schools/schoolsActions";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import detentionTypesActions from "areas/behaviour/actions/detentions/detentionTypesActions";
import behaviourAnalyticsActions from "areas/analytics/actions/behaviourAnalyticsActions";
import { useAppSelector } from "reducers/hooks";
import { GenerateBehaviourReportCommand } from "areas/analytics/types/behaviourAnalyticsRequest.types";
import moment from "moment";
import { BehaviourIncludeType, GroupBy, OrderBy, OrderByOption } from "areas/analytics/types/behaviourAnalyticsShared.types";
import { ReportFilterBy } from "areas/analytics/types/lessonExitAnalyticRequest.types";
import { FilterBy } from "areas/analytics/types/lessonExitAnalyticShared.types";


const BehaviourAnalyticsWrapper: React.FC = () => {

  const reportRef = useRef<HTMLDivElement>();
  const { user } = useAppSelector(state => state.currentUser);
  const { schoolId } = useAppSelector(state => state.schools);
  const { report, loading, loadingExportReport } = useAppSelector(state => state.behaviourAnalytics);

  const [reportParameters, setReportParameters] = useState<GenerateBehaviourReportCommand>(null);

  useEffect(() => {
    schoolsActions.selectSchool(user.baseSchool.id);
  }, []);

  useEffect(() => {
    if (schoolId) {
      behaviourActions.getAllBehaviourCodes(schoolId);
      rewardActions.getAllRewardCodes(schoolId);
      behaviourActions.getAllBehaviourCategories(schoolId);
      rewardActions.getAllRewardCategories(schoolId);
      detentionTypesActions.getDetentionTypes(schoolId);
    }
  }, [schoolId]);

  useEffect(() => {
    if (loading || report) {
      reportRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading, report]);

  const onSelectSchool = (schoolId: number) => {
    schoolsActions.selectSchool(schoolId);
  };

  const getReportFilters = (filters: BehaviourFilter) : ReportFilterBy[] => {
    const reportFilters = [];

    if (!arrays.isEmpty(filters.subjects)) {
      const subjectIds = filters.subjects.map(subject => subject.id);
      reportFilters.push({
        filterById: FilterBy.Subject,
        names: subjectIds,
      });
    }

    if (!arrays.isEmpty(filters.teachers)) {
      const teacherIds = filters.teachers.map(teacher => {
        return teacher.id;
      });
      reportFilters.push({
        filterById: FilterBy.Teacher,
        names: teacherIds,
      });
    }

    if (!arrays.isEmpty(filters.students)) {
      const studentIds = filters.students.map(student => {
        return student.id;
      });
      reportFilters.push({
        filterById: FilterBy.Pupil,
        names: studentIds,
      });
    }

    if (!arrays.isEmpty(filters.yearGroups)) {
      const yearGroupIds = filters.yearGroups.map(yearGroup => {
        return yearGroup.id;
      });
      reportFilters.push({
        filterById: FilterBy.YearGroup,
        names: yearGroupIds,
      });
    }

    if (!arrays.isEmpty(filters.tutorGroups)) {
      const tutorGroupIds = filters.tutorGroups.map(tutorGroup => {
        return tutorGroup.id;
      });
      reportFilters.push({
        filterById: FilterBy.TutorGroup,
        names: tutorGroupIds,
      });
    }

    if (!arrays.isEmpty(filters.categories)) {
      const categoryIds = filters.categories.map(category => {
        return category.id.toString();
      });
      reportFilters.push({
        filterById: FilterBy.BehaviourCategories,
        names: categoryIds,
      });
    }

    if (!arrays.isEmpty(filters.codes)) {
      const codeIds = filters.codes.map(code => {
        return code.id.toString();
      });
      reportFilters.push({
        filterById: FilterBy.BehaviourCode,
        names: codeIds,
      });
    }

    if (!arrays.isEmpty(filters.outcomes)) {
      const outcomeIds = filters.outcomes.map(outcome => {
        return outcome.id.toString();
      });
      reportFilters.push({
        filterById: FilterBy.Outcome,
        names: outcomeIds,
      });
    }

    if (!arrays.isEmpty(filters.sen)) {
      const senTypesIds = filters.sen.map(type => {
        return type.id.toString();
      });
      reportFilters.push({
        filterById: FilterBy.SEN,
        names: senTypesIds,
      });
    }

    if (!arrays.isEmpty(filters.detentionTypes)) {
      const detentionTypesIds = filters.detentionTypes.map(type => {
        return type.detentionTypeId.toString();
      });
      reportFilters.push({
        filterById: FilterBy.DetentionTypes,
        names: detentionTypesIds,
      });
    }

    return reportFilters;
  };

  const handleGenerateReport = (
    startDate: Date,
    endDate: Date,
    groupBy1: GroupBy,
    groupBy2: GroupBy,
    behaviourType: BehaviourIncludeType,
    sortBy: OrderBy,
    orderBy: OrderByOption,
    academicYearId: number,
    filters: BehaviourFilter,
    exportReport?: boolean
  ) => {
    const reportFilters = getReportFilters(filters);
    const payload: GenerateBehaviourReportCommand = {
      schoolId: schoolId,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      groupBy1: groupBy1,
      groupBy2: groupBy2,
      behaviourIncludeTypeId: behaviourType,
      orderBy: sortBy,
      orderByOption: orderBy,
      reportFilters: reportFilters,
      academicYearId: academicYearId,
    };
    if (exportReport) {
      behaviourAnalyticsActions.exportBehaviourReport(payload);
    } else {
      setReportParameters(payload);
      behaviourAnalyticsActions.getBehaviourReport(payload);
    }
  };

  return (
    <>
      {/* <HelpMessage
        icon="info-circle"
        title="Need help with generating a report?"
        sub="Watch our VLE training video to find out more"
        link="https://vle.lionhearttrust.org.uk/mod/page/view.php?id=70703"
      /> */}
      <Title text="Behaviour and Reward Points Report" size={TitleSize.H3} />
      <ReportDescription text="Use this report to view the number of behaviour and/or reward points broken down by different categories." />
      <BehaviourAnalyticsForm
        onSelectSchool={onSelectSchool}
        handleGenerateReport={handleGenerateReport}
        loadingExportReport={loadingExportReport}
      />
      <div ref={reportRef}>
        <ReportTable reportParameters={reportParameters} />
      </div>
    </>
  );
};

export default BehaviourAnalyticsWrapper;
