import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import {
  Title,
  TitleSize,
  Loader,
  EmptyMessage,
  Size,
  Swatches,
  ActionPanel,
} from "ui-kit";
import documentActions from "areas/administration/actions/documentActions";
import Subheader from "sharedComponents/layout/header/subheader";
import { RootState } from "reducers/store";
import { DocumentListView } from "areas/administration/types/documentResponse.types";


const Documents = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { documents, loading, error } = useSelector((state: RootState) => state.userDocuments);

  useEffect(() => {
    user && documentActions.getDocumentsForUser(user.id);
  }, []);

  const downloadDocument = (document: DocumentListView) => {
    documentActions.downloadDocument(document.user.id, document.id);
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="An error occurred"
        summary="There was an error while retrieving the documents."
        cover
      />
    );
  }

  return (
    <>
      <Subheader>
        <Title size={TitleSize.H2} text="Documents" />
      </Subheader>
      {arrays.isEmpty(documents) ? (
        <EmptyMessage
          icon="file-certificate"
          title="No Documents"
          summary="There are no documents for you to download"
          cover
        />
      ) : (
        documents.map((document: DocumentListView, index: number) => (
          <ActionPanel
            key={index}
            label={document.group.name}
            sub={document.group.summary}
            buttonText="Download"
            buttonColor={Swatches.Primary}
            onClick={() => downloadDocument(document)}
          />
        ))
      )}
    </>
  );
};

export default Documents;
