import studentActivityFeedReducer from "./studentActivityFeedReducer";
import studentAwardReducer from "./studentAwardReducer";
import studentClassesReducer from "./studentClassesReducer";
import studentConsentFormsReducer from "./studentConsentFormsReducer";
import studentDetentionHistoryReducer from "./studentDetentionHistoryReducer";
import studentInformationTabsReducer from "./studentInformationTabsReducer";
import studentLeavingLessonReducer from "./studentLeavingLessonReducer";
import studentNoteCategoriesReducer from "./studentNoteCategoriesReducer";
import studentNoteReducer from "./studentNoteReducer";
import studentNotesReducer from "./studentNotesReducer";
import studentProfileReducer from "./studentProfileReducer";
import studentQuizReducer from "./studentQuizReducer";
import studentReducer from "./studentReducer";

const studentReducers = {
  studentActivityFeed: studentActivityFeedReducer,
  studentAward: studentAwardReducer,
  studentClasses: studentClassesReducer,
  studentDetentionHistory: studentDetentionHistoryReducer,
  studentInformationTabs: studentInformationTabsReducer,
  studentLeavingLesson: studentLeavingLessonReducer,
  studentNote: studentNoteReducer,
  studentNotes: studentNotesReducer,
  studentProfile: studentProfileReducer,
  studentQuiz: studentQuizReducer,
  student: studentReducer,
  studentConsentForms: studentConsentFormsReducer,
  studentNoteCategories: studentNoteCategoriesReducer,
};

export default studentReducers;
