import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { SaveProviderCommand, SaveProviderContactCommand } from "areas/send/types/passportRequest.types";
import { ProviderContactDetailView, ProviderDetailView, ProviderListView } from "areas/send/types/passportResponse.types";
import client from "services/client";
import { callbackType } from "types/common/action.types";

const builder = new actionTypeBuilder("send");

const types = {
  PROVIDERS_GET: builder.build("providers_get"),
  PROVIDER_GET: builder.build("provider_get"),
  PROVIDERCONTACT_GET: builder.build("providercontact_get"),
  PROVIDER_CREATE: builder.build("provider_create"),
  PROVIDER_SAVE: builder.build("provider_save"),
  PROVIDER_DELETE: builder.build("provider_delete"),
  PROVIDERCONTACT_CREATE: builder.build("providercontact_create"),
  PROVIDERCONTACT_SAVE: builder.build("providercontact_save"),
  PROVIDERCONTACT_DELETE: builder.build("providercontact_delete"),
};


const getProviders = (callback?: callbackType<ProviderListView[]>) =>
  action<ProviderListView[]>(
    () => client.get(`planner/send/providers`),
    types.PROVIDERS_GET,
    callback
  );

const getProvider = (id: number, callback?: callbackType<ProviderDetailView>) =>
  action<ProviderDetailView>(
    () => client.get(`planner/send/providers/${id}`),
    types.PROVIDER_GET,
    callback
  );

const getProviderContact = (providerId: number, contactId: number, callback?: callbackType<ProviderContactDetailView>) =>
  action<ProviderContactDetailView>(
    () => client.get(`planner/send/providers/${providerId}/contact/${contactId}`),
    types.PROVIDERCONTACT_GET,
    callback
  );

const createProvider = (data: SaveProviderCommand, callback?: callbackType<ProviderListView>) =>
  action<ProviderListView>(
    () => client.post(`planner/send/providers`, data),
    types.PROVIDER_CREATE,
    callback
  );

const saveProvider = (data: SaveProviderCommand, callback?: callbackType<ProviderListView>) =>
  action<ProviderListView>(
    () => client.post(`planner/send/providers/${data.id}`, data),
    types.PROVIDER_SAVE,
    callback
  );

const deleteProvider = (id: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.delete(`planner/send/providers/${id}`),
    types.PROVIDER_DELETE,
    callback
  );

const createProviderContact = (data: SaveProviderContactCommand, callback?: callbackType<ProviderContactDetailView>) =>
  action<ProviderContactDetailView>(
    () => client.post(`planner/send/providers/${data.providerId}/contacts`, data),
    types.PROVIDERCONTACT_CREATE,
    callback
  );

const saveProviderContact = (data: SaveProviderContactCommand, callback?: callbackType<ProviderContactDetailView>) =>
  action<ProviderContactDetailView>(
    () => client.post(`planner/send/providers/${data.providerId}/contacts/${data.id}`, data),
    types.PROVIDERCONTACT_SAVE,
    callback
  );

const deleteProviderContact = (providerId: number, contactId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.delete(`planner/send/providers/${providerId}/contacts/${contactId}`),
    types.PROVIDERCONTACT_DELETE,
    callback
  );


const providerActions = {
  types,
  getProviders,
  getProvider,
  getProviderContact,
  createProvider,
  saveProvider,
  deleteProvider,
  createProviderContact,
  saveProviderContact,
  deleteProviderContact,
};

export default providerActions;
