import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { UserListView } from "types/users/userListViews.types";
import { RoleListView } from "types/users/userRoles.types";

const builder = new actionTypeBuilder("roles");

const types = {
  ROLES_GETALL: builder.build("ROLES_GETALL"),
  ROLES_GETSTAFFBYROLE: builder.build("ROLES_GETSTAFFBYROLE"),
  ROLES_ADDSTAFFTOROLE: builder.build("ROLES_ADDSTAFFTOROLE"),
  ROLES_REMOVESTAFFFROMROLE: builder.build("ROLES_REMOVESTAFFFROMROLE"),
};


const getRoles = (callback?: callbackType<RoleListView[]>) =>
  action<RoleListView[]>(
    () => client.get(`users/roles`), 
    types.ROLES_GETALL, 
    callback
  );

const getStaffByRole = (roleId: number, callback?: callbackType<UserListView[]>) =>
  action<UserListView[]>(
    () => client.get(`users/roles/${roleId}/staff`),
    types.ROLES_GETSTAFFBYROLE,
    callback
  );

const addStaffToRole = (roleId: number, userId: string, callback?: callbackType<UserListView>) =>
  action<UserListView>(
    () => client.post(`users/roles/${roleId}/staff/add`, { roleId, userId }),
    types.ROLES_ADDSTAFFTOROLE,
    callback
  );

const removeStaffFromRole = (roleId: number, userId: string, callback?: callbackType<UserListView>) =>
  action<UserListView>(
    () => client.post(`users/roles/${roleId}/staff/remove`, { roleId, userId }),
    types.ROLES_REMOVESTAFFFROMROLE,
    callback
  );


const rolesActions = {
  types,
  getRoles,
  getStaffByRole,
  addStaffToRole,
  removeStaffFromRole,
};

export default rolesActions;
