import adminSubmissionReducer from "./adminSubmissionReducer";
import adminSubmissionsReducer from "./adminSubmissionsReducer";
import formCategoriesReducer from "./formCategoriesReducer";
import formReducer from "./formReducer";
import formsReducer from "./formsReducer";
import formsReportingReducer from "./formsReporting";
import formStaffReducer from "./formStaffReducer";
import formSubmissionReducer from "./formSubmissionReducer";
import submissionApprovalFilterReducer from "./submissionApprovalFilterReducer";
import submissionApprovalReducer from "./submissionApprovalReducer";
import submissionFilterReducer from "./submissionFilterReducer";
import submissionReducer from "./submissionReducer";
import userSubmissionsFilterReducer from "./userSubmissionsFilterReducer";
import userSubmissionsReducer from "./userSubmissionsReducer";

const forms = {
  adminSubmission: adminSubmissionReducer,
  adminSubmissions: adminSubmissionsReducer,
  formCategories: formCategoriesReducer,
  form: formReducer,
  forms: formsReducer,
  formsReporting: formsReportingReducer,
  formStaff: formStaffReducer,
  formSubmission: formSubmissionReducer,
  submissionApprovalFilter: submissionApprovalFilterReducer,
  submissionApproval: submissionApprovalReducer,
  submissionFilter: submissionFilterReducer,
  submission: submissionReducer,
  userSubmissionsFilter: userSubmissionsFilterReducer,
  userSubmissions: userSubmissionsReducer,
};

export default forms;
