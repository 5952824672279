
export enum PassportVersionStatus {
  Draft = 0,
  Published = 1
}

export enum AreaOfNeed {
  CognitionAndLearning = 0,
  CommunicationAndInteraction = 1,
  Sensory = 2,
  SEMH = 3
}

export enum PassportTileType {
  KeyInformation = 0,
  StudentInformation = 1,
  SupportStrategies = 2,
  LearningStrategies = 3,
  AdditionalInformation = 4,
  Custom = 5
}

export enum ProvisionType {
  Standard = 0,
  Custom = 1
}

export enum ProvisionMapStatus {
  Open = 0,
  Closed = 1
}

export enum HalfTermType {
  One = 0,
  Two = 1
}

export enum ProvisionGroupStatus {
  Draft = 0,
  Live = 1,
  Complete = 2
}

export enum ProvisionFrequency {
  Single = 0,
  Day = 1,
  Week = 2,
  Month = 3,
  HalfTerm = 4
}

export enum ProvisionProgressRating {
  Expected = 0,
  Above = 1,
  Below = 2
}

export enum ProvisionStatus {
  Live = 0,
  Archived = 1
}

export enum ProvisionTimescale {
  Month = 0,
  Term = 1,
  HalfYear = 2,
  Year = 3,
  Custom = 4
}