import routes from "configuration/routes";

export const adminRoutes = {
  default: "/administration",
  search: "/search",
  user: "users/:userId",
  getUserPath: (userId: string) => `${routes.home}/administration/users/${userId}`,
};

export const consentRoutes = {
  default: "/consent/forms",
  form: "/:formId",
  getConsentFormPath: (formId: number) =>
    `${routes.home}/consent/forms/${formId}`,
  consentFormAdmin: "/admin/form/:formId",
  getConsentFormAdminPath: (formId: number) =>
    `${routes.home}/consent/forms/admin/form/${formId}`,
  response: "/admin/form/:formId/responses/:responseId",
  newConsentForm: "/admin/form/new",
};

export const documentRoutes = {
  default: "/documents",
  admin: "/documents/admin",
  upload: "/documents/admin/groups/:id/upload",
  viewer: "/documents/admin/groups/:id/viewer",
  getUploadPath: (groupId: number) =>
    `${routes.home}/documents/admin/groups/${groupId}/upload`,
  getViewerPath: (groupId: number) =>
    `${routes.home}/documents/admin/groups/${groupId}/viewer`,
};
