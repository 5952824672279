import action from "actions/action";
import { ToastService } from "ui-kit";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import store from "reducers/store";
import { urls } from "../../../../utils";
import { PreflightDetentionDateFilter, RescheduleDetentionCommand, UpdateDetentionStatusCommand } from "areas/behaviour/types/detentionRegisterRequest.types";
import { callbackType } from "types/common/action.types";
import { DetentionStudentSummaryView } from "areas/behaviour/types/behaviourResponses.types";
import { RoomListView } from "areas/planner/types/classLayoutResponse.types";
import { DetentionStudentView, PreflightDetentionDateView, RescheduledDetentionListView } from "areas/behaviour/types/detentionRegisterResponse.types";

const builder = new actionTypeBuilder("detention");

const types = {
  ATTENDEDDETENTION: builder.build("attendeddetention"),
  MISSEDDETENTION: builder.build("misseddetention"),
  CANCELDETENTION: builder.build("canceldetention"),
  RESCHEDULEDETENTION: builder.build("rescheduledetention"),
  GETDETENTIONSUMMARY: builder.build("getdetentionsummary"),
  SELECTDETENTION: "selectdetention",
  GETDETENTIONROOMS: builder.build("getdetentionrooms"),
  VALIDATEDETENTIONDATE: builder.build("validatedetentiondate"),
  GETRESCHEDULEDDETENTIONS: builder.build("getrescheduleddetentions"),
};


const attendedDetention = (
  studentId: string,
  behaviourId: number,
  detentionId: number,
  updateNote: UpdateDetentionStatusCommand,
  onSuccess?: callbackType<number>
) => {
  return action<number>(
    () =>
      client.put(
        `planner/students/${studentId}/behaviours/${behaviourId}/detentions/${detentionId}/attend`,
        updateNote
      ),
    types.ATTENDEDDETENTION,
    onSuccess
  );
};


const missedDetention = (
  studentId: string,
  behaviourId: number,
  detentionId: number,
  updateNote: UpdateDetentionStatusCommand,
  onSuccess?: callbackType<number>
) => {
  return action<number>(
    () =>
      client.put(
        `planner/students/${studentId}/behaviours/${behaviourId}/detentions/${detentionId}/miss`,
        updateNote
      ),
    types.MISSEDDETENTION,
    onSuccess
  );
};


const cancelDetention = (
  studentId: string,
  behaviourId: number,
  detentionId: number,
  updateNote: UpdateDetentionStatusCommand,
  onSuccess?: callbackType<number>
) => {
  return action<number>(
    () =>
      client.put(
        `planner/students/${studentId}/behaviours/${behaviourId}/detentions/${detentionId}/cancel`,
        updateNote
      ),
    types.CANCELDETENTION,
    (id) => {
      ToastService.pop(
        "Detention successfully marked as Cancelled",
        null,
        "check-circle"
      );
      onSuccess?.(id);
    }
  );
};


const rescheduleDetention = (
  studentId: string,
  behaviourId: number,
  rescheduleDetails: RescheduleDetentionCommand,
  onSuccess?: callbackType<number>
) => {
  return action<number>(
    () =>
      client.post(
        `planner/students/${studentId}/behaviours/${behaviourId}/rescheduleDetention`,
        rescheduleDetails
      ),
    types.RESCHEDULEDETENTION,
    (id) => {
      ToastService.pop(
        "Detention successfully rescheduled",
        null,
        "check-circle"
      );
      onSuccess?.(id);
    }
  );
};


const getDetentionSummary = (studentId: string, year: number, month: number, day: number, onSuccess?: callbackType<DetentionStudentSummaryView[]>) => {
  return action<DetentionStudentSummaryView[]>(
    () =>
      client.get(
        `/planner/students/${studentId}/detentions/${year}/${month}/${day}`
      ),
    types.GETDETENTIONSUMMARY,
    onSuccess
  );
};


const selectDetention = (detention: DetentionStudentView) =>
  store.dispatch({
    type: types.SELECTDETENTION,
    payload: detention,
  });


const getDetentionRooms = (schoolId: number, onSuccess?: callbackType<RoomListView[]>) => {
  return action<RoomListView[]>(
    () => client.get(`planner/schools/${schoolId}/detentionRooms`),
    types.GETDETENTIONROOMS,
    onSuccess
  );
};


const validateDetentionDate = (
  studentId: string,
  {
    detentionTypeId,
    detentionDate,
    duration,
    detentionId
  }: PreflightDetentionDateFilter,
  onSuccess?: callbackType<PreflightDetentionDateView>
) => {
  const url = new urls.QueryString(
    `planner/students/${studentId}/preflightDetentionDate`
  );
  url.addParam("detentionTypeId", detentionTypeId);
  url.addParam("detentionDate", detentionDate);
  url.addParam("duration", duration);
  url.addParam("detentionId", detentionId);

  return action<PreflightDetentionDateView>(
    () => client.get(url.toUrl()),
    types.VALIDATEDETENTIONDATE,
    onSuccess
  );
};


const getRescheduledDetentions = (
  studentId: string,
  behaviourId: number,
  detentionId: number,
  onSuccess?: callbackType<RescheduledDetentionListView[]>
) => {
  return action<RescheduledDetentionListView[]>(
    () =>
      client.get(
        `planner/students/${studentId}/behaviours/${behaviourId}/detentions/${detentionId}/rescheduledDetentions`
      ),
    types.GETRESCHEDULEDDETENTIONS,
    onSuccess
  );
};


const detentionActions = {
  types,
  attendedDetention,
  missedDetention,
  cancelDetention,
  rescheduleDetention,
  getDetentionSummary,
  selectDetention,
  getDetentionRooms,
  validateDetentionDate,
  getRescheduledDetentions,
};

export default detentionActions;
