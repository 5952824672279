import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import { arrays } from "utils";
import {
  Table,
  SplitButton,
  Loader,
  Size,
  EmptyMessage,
  Button,
  Swatches,
  ToastService,
  Message,
  ActionBar,
  Right,
  DetailLabel,
  HtmlText,
} from "ui-kit";
import { RootState } from "reducers/store";
import { InterventionRecordView } from "areas/planner/types/interventions/interventionResponses";
import { Avatar } from "sharedComponents/common";
import InterventionProgress from "./interventionProgress";
import { studentRoutes } from "areas/planner/plannerRoutes";
import { useNavigate } from "react-router";
import interventionActions from "areas/planner/actions/interventions/interventionActions";


interface IInterventionsTableProps {
  interventions: InterventionRecordView[];
  handleCreate?: () => void;
  handleEdit?: (intervention: InterventionRecordView) => void;
  handleExport?: () => void;
  handleProgressReport?: () => void;
  showAttendance?: boolean;
  loading?: boolean;
  error?: string;
  loadingReport?: boolean,
  readonly?: boolean;
  studentMode?: boolean;
}


const InterventionsTable: React.FC<IInterventionsTableProps> = ({ 
  interventions, 
  handleCreate, 
  handleEdit, 
  handleExport,
  handleProgressReport,
  showAttendance,
  loading, 
  error, 
  loadingReport,
  readonly, 
  studentMode 
}) => {

  const navigate = useNavigate();
  const { filter } = useSelector((state: RootState) => state.groupInterventionsFilter);
  const { saving, saveError, deleting, deleteError } = useSelector((state: RootState) => state.intervention);
  const [_selectedIntervention, _setSelectedIntervention] = useState<InterventionRecordView | null>(null);

  const cellStyle: React.CSSProperties = {
    verticalAlign: 'top',
  }

  const getCellStyle = () => {
    return (studentMode === true)
      ? cellStyle
      : {}
  }

  const goToStudentProfile = (intervention: InterventionRecordView) => {
    navigate(studentRoutes.getStudentPath(intervention.student.id));
  };

  const handleDelete = (intervention: InterventionRecordView) => {
    if (
      window.confirm("This will delete the selected intervention record. This cannot be undone. Are you sure?")
    ) {
      interventionActions.deleteInterventionRecord(
        intervention.student.id,
        intervention.id,
        () => ToastService.pop("Intervention Record Deleted", null, "hand-holding-seedling")
      );
    }
  }

  const createNew = () => {
    !readonly && handleCreate?.();
  }

  const editRecord = (intervention: InterventionRecordView) => {
    !readonly && handleEdit?.(intervention);
  }

  const handleStudentProgressReport = () => {

  }

  const handleGroupProgressReport = () => {
    
  }

  if (loading) {
    return <Loader cover size={Size.Medium} />;
  }

  if (error) {
    return <EmptyMessage icon="times-circle" title="An error occurred" cover />;
  }

  return (
    <>
      { arrays.isEmpty(interventions) ? (
        <EmptyMessage
          icon={"hand-holding-seedling"}
          title={"No Interventions"}
          summary={`No intervention records for this ${studentMode ? "student" : "group"} have been created`}
          cover
        >
          {!readonly && (
            <Button
              size={Size.Small}
              text="Add New Intervention Record"
              onClick={createNew}
              color={Swatches.Primary}
            />
          )}
        </EmptyMessage>
      ) : (
        <>
          <Message text={deleteError} color={Swatches.Danger} />
          <Message text={saveError} color={Swatches.Danger} />

          <Table>
            <Table.Header>
              <Table.HeaderCell width={studentMode === true ? 0.65 : 1}>Rating</Table.HeaderCell>
              <Table.HeaderCell width={1} show={studentMode === true}>Group</Table.HeaderCell>
              <Table.HeaderCell width={2} show={studentMode === false || !studentMode}>Student</Table.HeaderCell>
              <Table.HeaderCell width={studentMode === true ? 2 : 4}>Target</Table.HeaderCell>
              <Table.HeaderCell width={0.5} show={showAttendance === true}>Attendance (%)</Table.HeaderCell>
              <Table.HeaderCell width={2} show={readonly === true}>Notes</Table.HeaderCell>
              <Table.HeaderCell width={1.5} show={readonly === false || !readonly} right></Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              { interventions
                .filter(x => filter.progress !== -1 ? x.rating == filter.progress : x)
                .map((intervention: InterventionRecordView, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell width={studentMode === true ? 0.65 : 1} style={getCellStyle()}>
                    <InterventionProgress
                      rating={intervention.rating}
                      fluid
                    />
                  </Table.Cell>
                  <Table.Cell width={2.5} show={studentMode === false || !studentMode} style={getCellStyle()}>
                    <Avatar 
                      user={intervention.student} 
                      sub={`${intervention.student.simsId} | ${intervention.student.tutorGroupName}`}
                    />
                  </Table.Cell>
                  <Table.Cell width={1} show={studentMode === true} style={getCellStyle()}>
                    <DetailLabel
                      label={intervention.group?.name}
                      sub={intervention.group?.school.name}
                      bold
                    />
                  </Table.Cell>
                  <Table.Cell width={studentMode === true ? 2 : 4} style={getCellStyle()}>
                    {intervention.target}
                  </Table.Cell>
                  <Table.Cell width={0.5} show={showAttendance === true} style={getCellStyle()}>
                    {intervention.attendances?.attendedDatesPercentage?.toFixed(2)}
                  </Table.Cell>
                  <Table.Cell width={1.5} show={readonly === true} style={getCellStyle()}>
                    <HtmlText html={intervention.notes} />
                  </Table.Cell>
                  <Table.Cell width={1.5} show={readonly === false || !readonly} style={getCellStyle()} right>
                    <SplitButton
                      color={Swatches.Low}
                      text="Edit"
                      onDefaultClick={() => editRecord(intervention)}
                      working={deleting || saving}
                    >
                      <SplitButton.Option
                        text="Edit"
                        onClick={() => editRecord(intervention)}
                        show
                      />
                      <SplitButton.Option
                        text="Student Profile"
                        onClick={() => goToStudentProfile(intervention)}
                        show
                      />
                      <SplitButton.Option
                        text="Delete"
                        onClick={() => handleDelete(intervention)}
                        show
                        color={Swatches.Danger}
                      />
                    </SplitButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            { handleExport && (
              <Table.Footer>
                <Table.Cell colspan={ studentMode ? 5 : 5 } right>
                  <ActionBar low>
                    <Right>
                      <Button
                        icon="file-download"
                        text="Export Intervention Records"
                        onClick={handleExport}
                        size={Size.Small}
                        working={loading || loadingReport}
                      />
                      <Button
                        icon="file-download"
                        text="Intervention Progress Report"
                        onClick={handleProgressReport}
                        size={Size.Small}
                        working={loading || loadingReport}
                      />
                    </Right>
                  </ActionBar>
                </Table.Cell>
              </Table.Footer>
            )}
          </Table>
        </>
      )}
    </>
  );
};

export default InterventionsTable;
