import defaults from "configuration/defaults";
import { arrays } from "utils";
import mailoutActions from "../actions/mailoutActions";
import { PageInfo } from "types/common/paging.types";
import { QueueMessageUserListView } from "../types/mailoutResponse.types";
import { IBeehiveAction } from "types/common/action.types";


interface IUserEmailsState {
  emails: QueueMessageUserListView[];
  error: string | null;
  loading: boolean;
  paging: PageInfo;
}

const INITIAL_STATE: IUserEmailsState = {
  emails: [],
  error: null,
  loading: false,
  paging: defaults.DEFAULT_PAGING,
};


const userEmailsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUserEmailsState => {

  const { MAILOUT_USEREMAILS_GET } = mailoutActions.types;

  switch (action.type) {
    case MAILOUT_USEREMAILS_GET.START:
      return {
        ...state,
        loading: arrays.isEmpty(state.emails),
        error: null,
      };

    case MAILOUT_USEREMAILS_GET.SUCCESS:
      return {
        ...state,
        emails:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.emails, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };

    case MAILOUT_USEREMAILS_GET.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default userEmailsReducer;
