
export enum InstallmentType {
  Default = 0,
  PupilPremium = 1,
  Bursary = 2
}

export interface InstalmentRuleView {
  id: number | null;
  dueDate: Date;
  total: number;
  optionalForPupilPremium: boolean;
  installmentType: InstallmentType;
}

export enum OrderLineStatus {
  Ordered = 0,
  Cancelled = 1
}

export enum TransactionMethod {
  SagePay = 0,
  Epos = 1,
  Cheque = 2,
  Cash = 3,
  Subsidy = 4,
  Bacs = 5,
  Terminal = 6,
  Cardnet = 7,
  Stripe = 8,
  PupilPremium = 9
}

export enum TransactionStatus {
  Ok = 0,
  NotAuthorised = 1,
  Pending = 2,
  Aborted = 3,
  Rejected = 4,
  Error = 6,
  Unknown = 7
}

export enum SmartcardCreditType {
  Credit = 0,
  Reverse = 1
}

export enum SmartcardProvider {
  Midas = 0,
  Ami = 1,
  LionheartCashless = 2
}