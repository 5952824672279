import React, { useRef } from "react";
import Avatar from "sharedComponents/common/users/avatar";
import { HelpPopup, Icon, List, Tooltip } from "ui-kit";
import Tippy from "@tippyjs/react";
import { UserTinyView } from "types/users/userListViews.types";
import { CustomGroupStudentView } from "types/users/userGroups.types";


interface ICustomGroupStudentListProps {
  students: CustomGroupStudentView[];
  listTitle?: string;
  handleRemoveStudent?: (studentId: string) => void;
  existing?: boolean;
}


const CustomGroupStudentList: React.FC<ICustomGroupStudentListProps> = ({
  students,
  listTitle,
  handleRemoveStudent,
  existing
}) => {

  const ref = useRef();

  const handleRemove = (studentId: string) => {
    handleRemoveStudent?.(studentId);
  }

  return (
    <List title={listTitle}>
      {students.map((student, index) => (
        <List.Item
          key={index}
          left={<Avatar user={student} />}
          right={ (!existing || student.canBeDeleted) ? (
            <>
              <button
                ref={ref}
                className="remove-student-button"
                onClick={event => {
                  event.stopPropagation();
                  handleRemove(student.id);
                }}
              >
                &#10799;
              </button>
              <Tippy
                reference={ref}
                content={<Tooltip title="Remove Student" />}
              />
            </>
          ) :
        (
          <>
            <HelpPopup 
              title={"Student cannot be removed"} 
              text={"Student cannot be removed as they have an active modified provision map"} 
            />
          </>
        )}
        />
      ))}
    </List>
  );
};

export default CustomGroupStudentList;
