import { CalendarEvent } from "areas/calendar/reducers/calendarEventReducer";
import { CalendarEventDateListView } from "areas/calendar/types/calendarResponses.types";
import React, { useState, useEffect } from "react";
import { Button, Label, Size, Swatches } from "ui-kit";
import { SignupDetails } from ".";



interface IDateSignupButtonProps {
  calendarEvent: CalendarEvent;
  signup: SignupDetails;
  date: CalendarEventDateListView;
  onDateSelect: (date: CalendarEventDateListView) => void;
  onDateDeselect: (date: CalendarEventDateListView) => void;
}


const DateSignupButton: React.FC<IDateSignupButtonProps> = ({ calendarEvent, signup, date, onDateSelect, onDateDeselect }) => {

  if (calendarEvent.mandatoryAttendance) {
    return <Label>Attendance is mandatory</Label>;
  }

  if (!calendarEvent?.requireSignup) {
    return <Label>No Signup Needed</Label>;
  }

  return signup?.dates?.some(x => x === date.id) ? (
    <Button
      size={Size.Small}
      text="Deselect this Date"
      color={Swatches.Danger}
      onClick={() => onDateDeselect(date)}
      // working={signingUp}
      fluid
    />
  ) : calendarEvent?.requireSignup &&
    calendarEvent?.isSignupOpen &&
    (date.signupLimit == null ||
      date.signupLimit === 0 ||
      (date.signupLimit > 0 && date.signups < date.signupLimit)) ? (
    <Button
      size={Size.Small}
      text="Select this Date"
      color={Swatches.Primary}
      onClick={() => onDateSelect(date)}
      // working={signingUp}
      fluid
    />
  ) : null;
}


export default DateSignupButton;