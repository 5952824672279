
export enum CalendarEventMenuItemType {
  Canape = 0,
  Starter = 1,
  Main = 2,
  Dessert = 3
}

export enum CalendarEventSignupStatus {
  Attending = 0,
  ReserveList = 1,
  Cancelled = 2
}

export enum CalendarEventDateAttendance {
  Pending = 0,
  Attended = 1,
  Missed = 2
}