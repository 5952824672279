import React from "react";
import RoomLayoutOptions from "./roomLayoutOptions";
import { Message, StructuredList, Card, TextInput } from "ui-kit";
import { Swatches } from "ui-kit/common/colors";
import { ROOM_LAYOUT_TEMPLATES } from "areas/planner/constants/classViewer/roomLayout";

const RoomLayoutOptionsForm = ({
  selectedRoomTemplate,
  setSelectedRoomTemplate,
  roomLayoutName,
  setRoomLayoutName,
}) => {
  return (
    <StructuredList>
      <Card title="New Room Layout">
        <Card.Body>
          <StructuredList.Item
            name="Room Layout Name"
            description="Give the room layout a name"
            required
          >
            <TextInput
              fluid
              value={roomLayoutName}
              onChange={value => setRoomLayoutName(value)}
            />
          </StructuredList.Item>

          <StructuredList.Item
            name="Room Layout Templates"
            description="Choose a room layout template or create a custom room layout. You will be able to change the chosen template in the next step if you need to."
            required
          >
            <Message
              text={`Choose a room layout template or create a custom room layout.`}
              color={Swatches.Warning}
            />

            <RoomLayoutOptions
              roomlayouts={ROOM_LAYOUT_TEMPLATES}
              selectedRoomTemplate={selectedRoomTemplate}
              onSelectTemplate={setSelectedRoomTemplate}
            />
          </StructuredList.Item>
        </Card.Body>
      </Card>
    </StructuredList>
  );
};

export default RoomLayoutOptionsForm;
