import store from "reducers/store";
import { ProductCategoryListView } from "../types/shopkeeper/shopkeeperResponses.types";
import { SaveProductCategoryCommand } from "../types/shopkeeper/shopkeeperRequests.types";
import { IBeehiveAction } from "types/common/action.types";

export const inventoryFilterTypes = {
  INVENTORY_FILTERBYACTIVE: "INVENTORY_FILTERBYACTIVE",
  INVENTORY_FILTERBYCATEGORY: "INVENTORY_FILTERBYCATEGORY",
  INVENTORY_FILTERBYSCHOOL: "INVENTORY_FILTERBYSCHOOL",
};

export const filterBySchool = (schoolId: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: inventoryFilterTypes.INVENTORY_FILTERBYSCHOOL,
    payload: schoolId,
  });
};

export const filterByCategory = (category: ProductCategoryListView | SaveProductCategoryCommand) => {
  store.dispatch<IBeehiveAction<ProductCategoryListView | SaveProductCategoryCommand>>({
    type: inventoryFilterTypes.INVENTORY_FILTERBYCATEGORY,
    payload: category,
  });
};

export const filterByActive = (active: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: inventoryFilterTypes.INVENTORY_FILTERBYACTIVE,
    payload: active,
  });
};

const inventoryFilterActions = {
  inventoryFilterTypes,
  filterBySchool,
  filterByCategory,
  filterByActive,
};

export default inventoryFilterActions;
