import React, { useState, useEffect } from "react";
import GroupSearch from "./groupSearch";
import { useSelector } from "react-redux";
import GroupList from "./groupList";
import styled from "styled-components";
import { Spacing, Swatches } from "ui-kit";
import { arrays } from "utils";
import { RootState } from "reducers/store";
import { GroupListView, GroupType } from "types/users/userGroups.types";
import { SchoolListView } from "types/schools/schools.types";
import { SearchResultView } from "types/common/views.types";


const Wrapper = styled.div`
  .chip-list {
    margin-top: ${Spacing.Default}px;
  }
`;


interface IGroupSelectorProps {
  onChange?: (newGroups: GroupListView[]) => void;
  onChipClick?: (group: GroupListView) => void;
  groups: GroupListView[];
  schools?: SchoolListView[];
  showSchoolIcon?: boolean;
  groupType?: GroupType;
  placeholder?: string;
}

const GroupSelector: React.FC<IGroupSelectorProps> = ({ onChange, onChipClick, groups, schools, showSchoolIcon, groupType, placeholder }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const [selectedGroups, setSelectedGroups] = useState<GroupListView[]>([]);
  const [_schools, _setSchools] = useState<SchoolListView[]>([...user.schools, user.baseSchool]);

  useEffect(() => {
    groups && setSelectedGroups(groups);
  }, [groups]);

  useEffect(() => {
    schools && _setSchools(schools);
  }, [schools]);

  const handleGroupSelected = (group: SearchResultView<GroupListView>) => {
    if (
      selectedGroups.some(x => x.id == parseInt(group.id) && Number(x.type) == Number(group.subCategory))
    ) {
      return;
    }

    var newGroups: GroupListView[] = [
      ...selectedGroups,
      group.data
    ];

    setSelectedGroups(newGroups);
    onChange?.(newGroups);
  };

  const handleGroupClick = (group: GroupListView, index: number) => {
    if (onChipClick != null) {
      onChipClick?.(group);
    }
    else {
      var newGroups = [...arrays.remove(selectedGroups, index)];
      setSelectedGroups(newGroups);
      onChange?.(newGroups);
    }
  };

  return (
    <Wrapper>
      <GroupSearch 
        onGroupSelected={handleGroupSelected} 
        schools={_schools} 
        groupType={groupType}
        placeholder={placeholder}
      />
      <GroupList
        groups={selectedGroups}
        onGroupClick={handleGroupClick}
        chipHoverBackground={Swatches.Danger.swatch}
        chipIcon="times"
        showSchoolIcon={showSchoolIcon}
      />
    </Wrapper>
  );
};

export default GroupSelector;
