import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "../../../services/client";
import { callbackType } from "types/common/action.types";
import { AuthoriseSubmissionStatusCommand, CancelSubmissionCommand, RejectSubmissionStatusCommand, SubmissionQueryFilter, SubmissionReadCommand } from "../types/formRequest.types";
import { SubmissionDetailView, SubmissionHistoryView, SubmissionListView } from "../types/formResponse.types";
import { PagedQueryView } from "types/common/views.types";
import { ISubmissionFilter } from "../reducers/submissionFilterReducer";

const builder = new actionTypeBuilder("submissions");

const types = {
  GETFORAPPROVAL: builder.build("getforapproval"),
  GETFORUSER: builder.build("getforuser"),
  GETSUBMISSION: builder.build("getsubmission"),
  AUTHORISE: builder.build("approve"),
  REJECT: builder.build("reject"),
  CANCEL: builder.build("cancel"),
  READ: builder.build("read"),
  DELETE: builder.build("delete"),
};


const buildSubmissionFilterQueryString = (filter: ISubmissionFilter) =>  {
  var url = `${
    filter.category && filter.category.id
      ? "&categoryId=" + filter.category.id
      : ""
  }${
    filter.formType && filter.formType.id ? "&formId=" + filter.formType.id : ""
  }${filter.formStatus > -1 ? "&status=" + filter.formStatus : ""}${
    filter.userId !== "-1" ? "&assignedToId=" + filter.userId : ""
  }${filter.schoolId !== -1 ? "&schoolId=" + filter.schoolId : ""}
  ${filter.searchTerm ? "&searchTerm=" + filter.searchTerm : ""}`;

  return url;
};

const getSubmissionsForUser = (userId: string, filter: ISubmissionFilter, pageIndex: number, callback?: callbackType<PagedQueryView<SubmissionListView>>) =>
  action<PagedQueryView<SubmissionListView>>(
    () => client.get(`forms/users/${userId}/submissions?${buildSubmissionFilterQueryString(filter)}&pageIndex=${pageIndex}`),
    types.GETFORUSER,
    callback
  );

const getSubmissionsForApproval = (userId: string, filter: ISubmissionFilter, pageIndex: number, callback?: callbackType<PagedQueryView<SubmissionListView>>) =>
  action<PagedQueryView<SubmissionListView>>(
    () => client.get(`forms/users/${userId}/submissions/approval?${buildSubmissionFilterQueryString(filter)}&pageIndex=${pageIndex}`),
    types.GETFORAPPROVAL,
    callback
  );

const getSubmission = (submissionId: number, callback?: callbackType<SubmissionDetailView>) =>
  action<SubmissionDetailView>(
    () => client.get(`forms/submissions/${submissionId}`),
    types.GETSUBMISSION,
    callback
  );

const authoriseSubmission = (data: AuthoriseSubmissionStatusCommand, callback?: callbackType<SubmissionHistoryView>) =>
  action<SubmissionHistoryView>(
    () => client.post(`forms/submissions/${data.submissionId}/authorise`, data),
    types.AUTHORISE,
    callback
  );

const cancelSubmission = (data: CancelSubmissionCommand, callback?: callbackType<SubmissionHistoryView>) =>
  action<SubmissionHistoryView>(
    () => client.post(`forms/submissions/${data.submissionId}/cancel`, data),
    types.CANCEL,
    callback
  );

const rejectSubmission = (data: RejectSubmissionStatusCommand, callback?: callbackType<SubmissionHistoryView>) =>
  action<SubmissionHistoryView>(
    () => client.post(`forms/submissions/${data.submissionId}/reject`, data),
    types.REJECT,
    callback
  );

const markSubmissionRead = (data: SubmissionReadCommand, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`forms/submissions/${data.submissionId}/read`, data),
    types.READ,
    callback
  );

const deleteDraft = (submissionId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.delete(`forms/submissions/${submissionId}/deleteDraft`),
    types.DELETE,
    callback
  );


const submissionActions = {
  types,
  getSubmissionsForUser,
  getSubmissionsForApproval,
  getSubmission,
  authoriseSubmission,
  cancelSubmission,
  rejectSubmission,
  markSubmissionRead,
  deleteDraft,
};

export default submissionActions;
