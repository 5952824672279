import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { honeygold, neutral } from "ui-kit/common/colors";
import {
  DetailLabel,
  fontStyle,
  displayFont,
  typescale,
  Spacing,
  Size,
} from "ui-kit";
import { DISPERSE_LEVELS } from "areas/planner/constants/classViewer/classViewerLayout";

const Wrapper = styled.div`
  padding-top: 10px;

  .disperse-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${Spacing.Large}px 0 ${Spacing.Medium}px 0;

    .slider-levels {
      display: flex;
      width: 50%;
      justify-content: space-between;
      margin-bottom: ${Spacing.Small}px;
      ${fontStyle(displayFont.medium, typescale.paragraph, neutral[700])}
    }

    .slider {
      appearance: none;
      outline: none;
      border-radius: ${Spacing.Medium}rem;
      width: 51%;
      height: ${Spacing.Small}px;
      background: lightgrey;

      &::-webkit-slider-thumb {
        appearance: none;
        width: ${Size.Small}rem;
        height: ${Size.Small}rem;
        border-radius: 50%;
        cursor: pointer;
        background: ${honeygold};
      }
    }

    .slider-labels {
      display: flex;
      width: 75%;
      justify-content: space-between;
      margin-top: ${Spacing.Small}px;
      ${fontStyle(displayFont.medium, typescale.paragraph, neutral[700])}

      .slider-label {
        width: ${Spacing.Medium}rem;

        .detail-label .label {
          ${fontStyle(displayFont.medium, typescale.paragraph)}
        }

        &.dispersed {
          text-align: right;
        }
      }
    }
  }
`;

const LayoutGeneratorDisperseSlider = ({ value, handleDisperseChange }) => {
  return (
    <Wrapper className="auto-layout-slider">
      <div className="disperse-slider">
        <div className="slider-levels">
          {DISPERSE_LEVELS.map(level => (
            <div key={level}>{level}</div>
          ))}
        </div>

        <input
          className="slider"
          type="range"
          min="0"
          max="4"
          value={value}
          onChange={event => handleDisperseChange(event)}
        />

        <div className="slider-labels">
          <div className="slider-label clustered">
            <DetailLabel
              label="Clustered"
              sub="All students with selected criteria will be
            seated together"
            />
          </div>
          <div className="slider-label dispersed">
            <DetailLabel
              label="Dispersed"
              sub="Students with selected criteria will be seated
              separately and evenly around the class"
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

LayoutGeneratorDisperseSlider.propTypes = {
  value: PropTypes.number,
  setDisperseValue: PropTypes.func,
  visible: PropTypes.bool,
  children: PropTypes.node,
};

export default LayoutGeneratorDisperseSlider;
