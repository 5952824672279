import { OutcomeType } from "areas/behaviour/constants/behaviours";
import React, { useEffect, useState } from "react";
import { Checkbox } from "ui-kit";


interface IBehaviourOutcomeCheckboxProps {
  outcome: OutcomeType;
  selectedBehaviourOutcomes: OutcomeType[];
  handleOutcomeClick: (outcome: OutcomeType, isChecked: boolean) => void;
}

const BehaviourOutcomeCheckbox: React.FC<IBehaviourOutcomeCheckboxProps> = ({
  outcome,
  selectedBehaviourOutcomes,
  handleOutcomeClick
}) => {

  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    const tempOutcome = selectedBehaviourOutcomes.find(
      selectedOutcome => selectedOutcome.id === outcome.id
    );

    if (tempOutcome !== undefined) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [selectedBehaviourOutcomes]);

  const handleCheckboxChange = () => {
    const newIsChecked = !isChecked;
    handleOutcomeClick(outcome, newIsChecked);
    setIsChecked(newIsChecked);
  };

  return (
    <Checkbox
      text={outcome.description}
      checked={isChecked}
      onChange={handleCheckboxChange}
    />
  );
};

export default BehaviourOutcomeCheckbox;
