import React from "react";
import { 
  Message, 
  Swatches 
} from "../index";
import { arrays } from "../../utils";

interface IValidationMessageProps {
  errors: string[];
}

const ValidationMessage: React.FC<IValidationMessageProps> = ({ errors }) => {
  if (arrays.isEmpty(errors)) {
    return null;
  }

  return (
    <Message
      text={
        <ul>
          {errors.map((err, index) => (
            <li key={index}>{err}</li>
          ))}
        </ul>
      }
      color={Swatches.Danger}
    />
  );
};

export default ValidationMessage;
