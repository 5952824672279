import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  Button,
  CircleIcon,
  neutral,
  honeygold,
  Flyout,
  Size,
  Speed,
  Swatches,
  Spacing,
  BorderRadius,
  Label,
  boxShadow,
  fontStyle,
  displayFont,
  typescale,
} from "ui-kit";
import flyoutActions from "actions/ui/flyouts";
import { useNavigate } from "react-router-dom";
import { Constants } from "configuration";
import authenticationActions from "actions/authentication/authenticationActions";
import { Routes } from "configuration";
import Configuration from "configuration";
import Avatar from "sharedComponents/common/users/avatar";
import { RootState } from "reducers/store";


const AvatarWrapper = styled.div`
  margin-bottom: ${Spacing.ExtraLarge}px;
`;

const LinkWrapper = styled.div`
  border-top: 1px solid ${neutral[200]};
`;

const LinkItemWrapper = styled.div<{selected?: boolean}>`
  display: flex;
  align-items: center;
  margin: ${Spacing.Small}px 0;
  padding: ${Spacing.Default}px;
  border-radius: ${BorderRadius.Default}px;
  background: ${neutral[100]};
  box-shadow: ${boxShadow};
  transition: all ease 150ms;

  &:hover {
    cursor: pointer;
    background: ${neutral[600]};
    .label {
      color: ${neutral[100]};
    }

    .circle-icon {
      background: ${neutral[100]};
      i {
        color: ${neutral[600]};
      }
    }
  }

  .circle-icon {
    margin-right: ${Spacing.Default}px;
  }
  a {
    display: flex;
    align-items: center;

    &:hover {
      .circle-icon {
        transition: all ${Speed.Short}ms ease;
        background: ${neutral[700]};
        color: ${neutral[100]};
      }
    }

    ${({ selected }) =>
      selected &&
      `
  .circle-icon {
    transition: all ${Speed.Short}ms ease;
    background: ${honeygold};

    color: ${neutral[100]};
  }
`}
  }
`;

const Version = styled.div`
  ${fontStyle(displayFont.roman, typescale.sub, neutral[500])}
  margin-bottom:${Spacing.Medium}px;
  text-align: right;
`;


const ProfileFlyout = () => {

  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const [_user, _setUser] = useState(null);

  useEffect(() => {
    if (user) {
      _setUser({
        ...user,
        firstName: user.details?.firstName,
        lastName: user.details?.lastName,
      });
    }
  }, [user]);

  const handleLogout = () => {
    authenticationActions.logout(() => {
      navigate(Routes.authentication.login);
    });
  };

  return (
    <Flyout title="Account" name={Constants.FLYOUTS.ACCOUNT}>
      <Flyout.Body>
        <AvatarWrapper>
          <Avatar
            user={_user}
            sub={user?.email}
            size={Size.Jumbo}
            portrait
            self
          />
        </AvatarWrapper>
        <LinkWrapper
          onClick={() => {
            navigate("/main/profile/details");
            flyoutActions.closeFlyout();
          }}
        >
          <LinkItemWrapper>
            <CircleIcon value="user" />
            <Label bold>Account Details</Label>
          </LinkItemWrapper>
        </LinkWrapper>
        <LinkWrapper
          onClick={() => {
            navigate("/main/profile/security");
            flyoutActions.closeFlyout();
          }}
        >
          <LinkItemWrapper>
            <CircleIcon value="shield-alt" />
            <Label bold>Password &amp; Security</Label>
          </LinkItemWrapper>
        </LinkWrapper>
        <LinkWrapper
          onClick={() => {
            navigate("/main/profile/notifications");
            flyoutActions.closeFlyout();
          }}
        >
          <LinkItemWrapper>
            <CircleIcon value="paper-plane" />
            <Label bold>Notification Settings</Label>
          </LinkItemWrapper>
        </LinkWrapper>
      </Flyout.Body>
      <Flyout.Footer>
        <Version className="version-number">
          {Configuration.clientVersion}
        </Version>
        <Button
          icon="sign-out"
          text="Sign Out"
          onClick={handleLogout}
          color={Swatches.Danger}
          fluid
        />
      </Flyout.Footer>
    </Flyout>
  );
};

export default ProfileFlyout;
