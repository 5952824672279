import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Right,
  ActionBar,
  Swatches,
  Size,
  StructuredList,
  Card,
  EmptyMessage,
  Checkbox,
  ToastService,
} from "ui-kit";
import userManagementActions from "areas/administration/actions/userManagementActions";
import StaffPicker from "sharedComponents/common/users/staffPicker";
import { managedStudent } from "areas/administration/reducers/userManagement/managedUserReducer";
import { UpdateSscStaffForStudentCommand, UpdateStudentDetailsCommand } from "areas/administration/types/userManagementRequest.types";
import { StaffListView } from "types/users/userListViews.types";


interface IStudentDetailsModalProps {
  student: managedStudent;
  open: boolean;
  onCancel?: () => void;
  onSaved?: (data: studentDetailsData) => void;
}

export interface studentDetailsData extends UpdateStudentDetailsCommand {
  assignedStaff: StaffListView[];
}


const StudentDetailsModal: React.FC<IStudentDetailsModalProps> = ({ student, open, onCancel, onSaved }) => {

  const [_open, _setOpen] = useState<boolean>(false);
  const [_student, _setStudent] = useState<managedStudent | null>(null);
  const [_data, _setData] = useState<studentDetailsData>({
    userId: null,
    bursary: false,
    childProtection: false,
    lookedAfter: false,
    assignedStaff: [],
  });

  useEffect(() => {
    student && _setStudent(student);
    student &&
      _setData({
        userId: student.id,
        bursary: student.bursary,
        childProtection: student.childProtection,
        lookedAfter: student.lookedAfter,
        assignedStaff: student.assignedStaff,
      });
  }, [student]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const handleSave = () => {
    const assignedStaffIds = _data.assignedStaff.map(a => a.id);
    var staffForStudentData: UpdateSscStaffForStudentCommand = {
      studentId: _student.id,
      assignedStaff: assignedStaffIds
    }
    userManagementActions.updateAssignedStaff(_student.id, staffForStudentData);
    userManagementActions.updateStudentDetails(
      _student.id,
      _data as UpdateStudentDetailsCommand,
      () => {
        onSaved?.(_data);
        ToastService.pop("Student Details Updated", null, "user");
      }
    );
  };

  return (
    <Modal
      width="80%"
      height="80%"
      title="Update Student Details"
      open={_open}
      onClose={onCancel}
    >
      <Modal.Body>
        {_student ? (
          <Card>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item
                  name="Child Protection"
                  description="Student is subject to child protection measures"
                >
                  <Checkbox
                    checked={_data.childProtection}
                    onChange={val => {
                      _setData({ ..._data, childProtection: val.checked });
                    }}
                    text="Child Protection"
                  />
                </StructuredList.Item>
                <StructuredList.Item
                  name="Looked After Child"
                  description="Student is a looked after child"
                >
                  <Checkbox
                    checked={_data.lookedAfter}
                    onChange={val => {
                      _setData({ ..._data, lookedAfter: val.checked });
                    }}
                    text="Looked After Child"
                  />
                </StructuredList.Item>
                <StructuredList.Item
                  name="Bursary"
                  description="This student has been awarded a bursary"
                >
                  <Checkbox
                    checked={_data.bursary}
                    onChange={val => {
                      _setData({ ..._data, bursary: val.checked });
                    }}
                    text="Bursary"
                  />
                </StructuredList.Item>
                <StructuredList.Item
                  name="Assigned Staff"
                  description="Staff assigned to student"
                >
                  <StaffPicker
                    users={_data.assignedStaff}
                    onChange={users =>
                      _setData({ ..._data, assignedStaff: users })
                    }
                  />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
        ) : (
          <EmptyMessage
            icon="user"
            title="Error"
            summary="There was a problem loading the student"
            cover
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              onClick={onCancel}
              size={Size.Small}
              color={Swatches.Low}
              text="Cancel"
            />
            <Button
              onClick={handleSave}
              size={Size.Small}
              color={Swatches.Success}
              text="Save"
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default StudentDetailsModal;
