import { IBeehiveAction } from "types/common/action.types";
import types from "./uiTypes";
import store from "reducers/store";

const openFlyout = (name: string) : void => {
  store.dispatch<IBeehiveAction<string>>({
    type: types.FLYOUT_OPEN,
    payload: name,
  });
};

const closeFlyout = () : void => {
  store.dispatch<IBeehiveAction<null>>({
    type: types.FLYOUT_OPEN,
    payload: null,
  });
};

const flyoutActions = {
  openFlyout,
  closeFlyout,
};

export default flyoutActions;
