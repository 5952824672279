import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { LeaderBoardBehaviourView } from "areas/planner/types/whiteboard/whiteboardResponse.types";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { LeaderBoardBehaviourFilter } from "../types/leaderboardAnalyticsRequests.types";

const builder = new actionTypeBuilder("leaderboard");

const types = {
  GETGROUPLEADERBOARD: builder.build("getgroupleaderboard"),
};


const getGroupLeaderboardReport = ( //LeaderBoardBehaviourView[]
  groupId: number,
  {
    groupTypeId,
    categoryTypeId,
    startDate,
    endDate,
  }: LeaderBoardBehaviourFilter,
  onSuccess?: callbackType<LeaderBoardBehaviourView[]>
) => {
  return action<LeaderBoardBehaviourView[]>(
    () =>
      client.get(
        `planner/reporting/groups/${groupId}/leaderBoardBehaviourReport?StartDate=${startDate}&EndDate=${endDate}&groupTypeId=${groupTypeId}&categoryTypeId=${categoryTypeId}`
      ),
    types.GETGROUPLEADERBOARD,
    onSuccess
  );
};


const leaderboardReportActions = { 
  types, 
  getGroupLeaderboardReport 
};

export default leaderboardReportActions;
