import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ActionBar,
  Button,
  Loader,
  Right,
  Size,
  Swatches,
  Modal,
} from "ui-kit";
import OnCallForm from "./onCallForm";
import { useSelector } from "react-redux";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { arrays, objects } from "utils";
import ConfirmModal from "sharedComponents/common/confirmModal";
import onCallActions from "areas/behaviour/actions/onCall/onCallActions";
import schoolActions from "areas/planner/actions/school/schoolActions";
import { useAppSelector } from "reducers/hooks";
import { OnCallRequestDetailView, OnCallRequestListView } from "areas/analytics/reducers/onCallAnalyticsResponse.types";
import { IBeehiveError } from "types/common/errors.types";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { UserTinyView } from "types/users/userListViews.types";


interface IOnCallModalProps {
  onCallRequest: OnCallRequestListView;
  schoolId: number;
  handleSchoolChange?: (value: number) => void;
  openModal: boolean;
  handleCloseModal: () => void;
  handleSaveOnCall: (onCallRequest: OnCallRequestListView, closeConfirmModal?: () => void) => void;
  handleCancelOnCall?: () => void;
  loading?: boolean;
  loadingAction?: boolean;
  error?: IBeehiveError;
}


const OnCallModal: React.FC<IOnCallModalProps> = ({
  onCallRequest,
  schoolId,
  handleSchoolChange,
  openModal,
  handleCloseModal,
  handleSaveOnCall,
  handleCancelOnCall,
  loading,
  loadingAction,
  error
}) => {

  const { rooms, loadingRooms } = useAppSelector(state => state.schoolRooms);
  const { onCallCategories, loading: loadingCategories } = useAppSelector(
    state => state.onCallCategories
  );

  const [roomOptions, setRoomOptions] = useState<IDropdownDataItemProps<string, number, null>[]>([]);
  const [onCallCategoriesArray, setOnCallCategoriesArray] = useState<IDropdownDataItemProps<string, number, null>[]>([]);
  const [notesMandatory, setNotesMandatory] = useState<boolean>(false);
  const [_onCallRequest, _setOnCallRequest] = useState<OnCallRequestListView>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  useEffect(() => {
    if (onCallRequest) {
      _setOnCallRequest(objects.deepClone(onCallRequest));
    }
    if (arrays.isEmpty(onCallCategories) && !loadingCategories) {
      onCallActions.getOnCallCategories();
    }
  }, [onCallRequest]);

  useEffect(() => {
    if (schoolId) {
      schoolActions.getSchoolRooms(schoolId);
    }
  }, [schoolId]);

  useEffect(() => {
    let roomsArray: IDropdownDataItemProps<string, number, null>[] = [];
    if (rooms.length > 0) {
      roomsArray = rooms.map(room => ({
        key: room.roomId,
        label: room.roomName,
        value: room.roomId,
      }));
    }
    setRoomOptions(roomsArray);
  }, [rooms]);

  useEffect(() => {
    const reasonsForOnCall: IDropdownDataItemProps<string, number, null>[] = onCallCategories.map(category => ({
      key: category.categoryDetail.id,
      label: category.categoryDetail.name,
      value: category.categoryDetail.id,
    }));

    setOnCallCategoriesArray(reasonsForOnCall);
  }, [onCallCategories]);

  const onChangeRoom = (value: number) => {
    _setOnCallRequest({ ..._onCallRequest, roomId: value });
  };

  const onChangeNotes = (value: string) => {
    _setOnCallRequest({ ..._onCallRequest, notes: value });
  };

  const onChangeCategory = (value: number) => {
    let tempNotesMandatory = false;

    const onCallCategory = onCallCategories.find(
      category => category.categoryDetail.id === value
    );
    if (onCallCategory !== undefined) {
      _setOnCallRequest({ ..._onCallRequest, category: onCallCategory });
      if (onCallCategory.isNotesMandatory) {
        tempNotesMandatory = true;
      }
    }

    setNotesMandatory(tempNotesMandatory);
  };

  const onClickClose = () => {
    handleCloseModal();
    onChangeCategory(null);
    onChangeRoom(null);
    onChangeNotes("");
  };

  const handleStudentListChange = (students: UserTinyView[]) => {
    _setOnCallRequest({ ..._onCallRequest, students: students });
  };

  return (
    <>
      <Modal
        onClose={handleCloseModal}
        open={openModal}
        title="Request Assistance"
        width="70%"
        height="75%"
      >
        {loading ? (
          <Loader size={Size.Large} cover />
        ) : (
          <>
            <Modal.Body>
              <ApiExceptionMessage error={error} />
              {_onCallRequest && (
                <OnCallForm
                  rooms={roomOptions}
                  onCallCategories={onCallCategoriesArray}
                  onCallRequest={_onCallRequest}
                  areNotesMandatory={notesMandatory}
                  handleRoomChange={onChangeRoom}
                  handleCategoryChange={onChangeCategory}
                  handleNotesChange={onChangeNotes}
                  handleStudentListChange={handleStudentListChange}
                  schoolId={schoolId}
                  handleSchoolChange={handleSchoolChange}
                />
              )}
            </Modal.Body>

            <Modal.Footer>
              <ActionBar low>
                <Right>
                  <Button
                    text="Save"
                    color={Swatches.Success}
                    size={Size.Small}
                    disabled={
                      _onCallRequest?.category === null ||
                      (notesMandatory && _onCallRequest?.notes.trim() === "") ||
                      (!_onCallRequest?.roomId &&
                        _onCallRequest?.notes.trim() === "") ||
                      !schoolId
                    }
                    onClick={() => setOpenConfirmModal(true)}
                    working={loadingAction}
                  />
                  {onCallRequest?.id && (
                    <Button
                      text="Cancel Request"
                      color={Swatches.Default}
                      size={Size.Small}
                      onClick={handleCancelOnCall}
                      working={loadingAction}
                    />
                  )}
                  <Button
                    text="Close"
                    color={Swatches.Low}
                    size={Size.Small}
                    onClick={onClickClose}
                    working={loadingAction}
                  />
                </Right>
              </ActionBar>
            </Modal.Footer>
          </>
        )}
      </Modal>

      <ConfirmModal
        openModal={openConfirmModal}
        confirmMsg={`Are you sure you want to save this request?`}
        onClose={() => setOpenConfirmModal(false)}
        onConfirm={() =>
          handleSaveOnCall(_onCallRequest, () => setOpenConfirmModal(false))
        }
      />
    </>
  );
};


export default OnCallModal;
