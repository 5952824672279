import moment from "moment";
import client from "./client";
import { DateListView } from "areas/planner/types/termDatesResponse.types";
import { DetentionPeriod } from "areas/behaviour/types/behaviourShared.types";

const checkTermDates = (
  startDate: Date,
  endDate: Date | null,
  schoolId: number,
  sendNextAvailableDate: boolean,
  isForDetention: boolean,
  detentionPeriodId?: DetentionPeriod
): Promise<DateListView> => {
  let url = `planner/checkDates?&startDate=${moment(startDate).format(
    "YYYY-MM-DD"
  )}&schoolId=${schoolId}`;

  if (endDate) {
    url = url + `&endDate=${moment(endDate).format("YYYY-MM-DD")}`;
  }
  if (detentionPeriodId !== null && detentionPeriodId !== undefined) {
    url = url + `&detentionPeriodId=${detentionPeriodId}`;
  }
  if (sendNextAvailableDate) {
    url = url + `&SendNextAvailableDate=${sendNextAvailableDate}`;
  }
  if (isForDetention) {
    url = url + `&isForDetention=${isForDetention}`;
  }

  return new Promise((resolve, reject) => {
    client
      .get(url)
      .then(({ data }: { data: DateListView }) => resolve(data))
      .catch((error: any) => {
        reject(error);
      });
  });
};

const termDateService = {
  checkTermDates,
};

export default termDateService;
