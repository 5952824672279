import React from "react";
import styled from "styled-components";
import { typescale, textFont, neutral, fontStyle } from "../index";

export interface ISubProps {
  children: string | JSX.Element | (string | JSX.Element)[];
  className?: string;
  style?: any;
}

const Wrapper = styled.span`
  ${fontStyle(textFont.roman, typescale.sub, neutral[500])}
`;

const Sub: React.FC<ISubProps> = ({ children, className, style }) => {
  return (
    <Wrapper style={style} className={`sub ${className ? className : ""}`}>
      {children}
    </Wrapper>
  );
};

export default Sub;
