import { useSelector } from "react-redux";
import { ActionBar, TextInput } from "ui-kit";
import { SchoolDropdown } from "sharedComponents/common";
import smartcardFilterActions from "areas/payments/actions/smartcardFilterActions";
import CreditStatusDropDown from "./creditStatusDropdown";
import { useEffect } from "react";
import { RootState } from "reducers/store";


const SmartcardCreditFilter = () => {

  const { filter } = useSelector((state: RootState) => state.smartcardCreditFilter);
  const { user } = useSelector((state: RootState) => state.currentUser);

  useEffect(() => {
    if (filter.schoolId == -1) {
      smartcardFilterActions.filterBySchool(user?.baseSchool.id);
    }
  }, []);

  const handleSchoolChange = (schoolId: number) => {
    smartcardFilterActions.filterBySchool(schoolId);
  };

  const handleStatusChange = (status: number) => {
    smartcardFilterActions.filterByStatus(status);
  };

  const handleSearchTermChange = (searchTerm: string) => {
    smartcardFilterActions.filterBySearchTerm(searchTerm);
  };

  return (
    <ActionBar>
      <SchoolDropdown
        onChange={handleSchoolChange}
        includeAllOption={false}
        includeTrust={false}
        initialValue={filter.schoolId}
      />
      <CreditStatusDropDown
        onChange={handleStatusChange}
        initialValue={filter.status}
      />
      <TextInput
        icon="search"
        onChange={handleSearchTermChange}
        placeholder="Search Sync Records..."
        value={filter.searchTerm}
        debounce
        fluid
      />
    </ActionBar>
  );
};

export default SmartcardCreditFilter;
