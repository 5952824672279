import { useEffect } from "react";
import PasswordChanger from "sharedComponents/common/passwords/passwordChanger";
import { useSelector } from "react-redux";
import PublicTemplate from "sharedComponents/layout/public/publicTemplate";
import { useParams, useNavigate } from "react-router-dom";
import { Loader, Size, Card, Button } from "ui-kit";
import passwordActions from "areas/administration/actions/passwordActions";
import { RootState } from "reducers/store";


const ChangePassword = () => {

  const { authenticationCode } = useParams();
  const navigate = useNavigate();
  const { verifying, error } = useSelector((state: RootState) => state.password);

  useEffect(() => {
    passwordActions.verify(authenticationCode);
  }, []);

  const changePassword = (password: string) => {
    return passwordActions.change({
      password,
      confirmPassword: password,
      passwordResetCode: authenticationCode,
    });
  };

  return (
    <>
      <PublicTemplate title="Reset Your Password">
        {verifying ? (
          <Loader cover size={Size.Large} />
        ) : !error ? (
          <PasswordChanger onPasswordChange={changePassword} />
        ) : (
          <Card>
            <Card.Body>
              <p>
                Unfortunately, there was a problem with loading the password
                reset form. This may be because your link is invalid or has
                expired.
              </p>
              <p>
                Please check the link supplied in your email, or alternatively
                contact your child's school for further assistance.
              </p>
            </Card.Body>
            <Card.Footer>
              <Button text="Return to Login" onClick={() => navigate("/")} />
            </Card.Footer>
          </Card>
        )}
      </PublicTemplate>
    </>
  );
};

export default ChangePassword;
