import { DatePicker, StructuredList } from "ui-kit";
import { ICateringReportProps } from "."
import { SchoolDropdown } from "sharedComponents/common";


const DinnerNumbersReport: React.FC<ICateringReportProps> = ({ filter, setFilter }) => {

  return (
    <>
      <StructuredList.Item name="School">
        <SchoolDropdown
          initialValue={filter?.schoolId}
          onChange={value => setFilter({ ...filter, schoolId: value })}
          filter={x => x.enableLunchCredits}
          fluid
        />
      </StructuredList.Item>
      <StructuredList.Item name="Start Date">
        <DatePicker
          closeOnSelect
          selectedDate={filter?.startDate}
          onChange={value => setFilter({ ...filter, startDate: value })}
        />
      </StructuredList.Item>
      <StructuredList.Item name="End Date">
        <DatePicker
          closeOnSelect
          selectedDate={filter?.endDate}
          onChange={value => setFilter({ ...filter, endDate: value })}
        />
      </StructuredList.Item>
    </>
  )
}


export default DinnerNumbersReport;