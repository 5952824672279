import moment from "moment";
import Moment from "react-moment";
import { DetailLabel } from "ui-kit";


export const formatDate = (starts: Date, ends: Date, simple: boolean) => {
  
  const startMoment = moment(starts);
  const endMoment = moment(ends);

  if (startMoment.isSame(endMoment)) {
    return simple ? (
      <>
        Starts <Moment date={starts} format="DD/MM/YYYY" />
      </>
    ) : (
      <DetailLabel
        bold
        label={<Moment date={starts} format="dddd, Do MMMM YYYY" />}
        sub={<Moment date={starts} format="[Starts] HH:mma" />}
      />
    );
  }

  if (startMoment.isSame(endMoment, "day")) {
    if (simple) {
      return <Moment date={starts} format="DD/MM/YYYY" />;
    } else {
      return (
        <DetailLabel
          bold
          label={<Moment date={starts} format="dddd, Do MMMM YYYY" />}
          sub={
            <>
              <Moment date={starts} format="HH:mma" /> to{" "}
              <Moment date={ends} format="HH:mma" />
            </>
          }
        />
      );
    }
  }

  return simple ? (
    <span>
      <Moment date={starts} format="DD/MM/YYYY" /> - <br />
      <Moment date={ends} format="DD/MM/YYYY" />
    </span>
  ) : (
    <span>
      <b>
        <Moment date={starts} format="dddd, Do MMMM YYYY HH:mma" />
      </b>{" "}
      to{" "}
      <b>
        <Moment date={ends} format="dddd, Do MMMM YYYY, HH:mma" />
      </b>
    </span>
  );
};
