import React, { useEffect, useState } from "react";
import {
  Modal,
  ActionBar,
  Button,
  Size,
  Swatches,
  Card,
  StructuredList,
  Left,
  Right,
  ValidationMessage,
  DatePicker,
  TimePicker,
} from "ui-kit";
import moment from "moment";
import { arrays } from "utils";
import { CalendarEventDateListView } from "areas/calendar/types/calendarResponses.types";


interface IEventDateEditorModalProps {
  eventDate: CalendarEventDateListView;
  open: boolean;
  onClose?: () => void;
  onSave?: (eventDate: CalendarEventDateListView) => void;
  onDelete?: (eventDate: CalendarEventDateListView) => void;
}


const EventDateEditorModal: React.FC<IEventDateEditorModalProps> = ({
  eventDate,
  open,
  onClose,
  onSave,
  onDelete,
}) => {

  const [_open, _setOpen] = useState<boolean>(open);
  const [_eventDate, _setEventDate] = useState(eventDate);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_startTime, _setStartTime] = useState<string>("08:00");
  const [_endTime, _setEndTime] = useState<string>("09:00");

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    _setEventDate(eventDate);
    _setStartTime(moment(eventDate?.starts).format("HH:mm"));
    _setEndTime(moment(eventDate?.ends).format("HH:mm"));

    setValidationErrors([]);
  }, [eventDate]);

  const handleDelete = () => {
    onDelete?.(_eventDate);
  };

  const handleSave = () => {
    var errors = [];

    if (!_eventDate.starts) {
      errors.push(`Please enter a start date.`);
    }
    if (!_eventDate.ends) {
      errors.push(`Please enter a end date.`);
    }

    // if (_eventDate.starts && _eventDate.ends) {
    //   if (moment(_eventDate.starts).isBefore(moment())) {
    //     errors.push(`The event start date is in the past.`);
    //   }

    //   if (moment(_eventDate.ends).isBefore(_eventDate.starts)) {
    //     errors.push(`The event end date must be after the start date.`);
    //   }
    // }

    if (moment(_eventDate.ends).isBefore(_eventDate.starts)) {
      errors.push(`The event end date must be after the start date.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    _setEventDate({
      ..._eventDate,
      starts: moment(_eventDate.starts).utc().toDate(),
      ends: moment(_eventDate.ends).utc().toDate(),
    });

    onSave?.(_eventDate);
  };

  const handleStartDateChanged = (value: Date) => {
    _setEventDate({
      ..._eventDate,
      starts: moment(_eventDate.starts)
        .date(value.getDate())
        .month(value.getMonth())
        .year(value.getFullYear())
        .toDate(),
    });
  };

  const handleEndDateChanged = (value: Date) => {
    _setEventDate({
      ..._eventDate,
      ends: moment(_eventDate.ends)
        .date(value.getDate())
        .month(value.getMonth())
        .year(value.getFullYear())
        .toDate(),
    });
  };

  const handleStartTimeChanged = (value: string) => {
    var time = value.split(":");

    _setEventDate({
      ..._eventDate,
      starts: moment(_eventDate.starts).hour(parseInt(time[0])).minutes(parseInt(time[1])).toDate(),
    });
  };

  const handleEndTimeChanged = (value: string) => {
    var time = value.split(":");

    _setEventDate({
      ..._eventDate,
      ends: moment(_eventDate.ends).hour(parseInt(time[0])).minutes(parseInt(time[1])).toDate(),
    });
  };

  return _eventDate ? (
    <Modal
      title="Edit Date"
      open={_open}
      onClose={onClose}
      width="80%"
      height="60%"
    >
      <Modal.Body scrollOnErrors={validationErrors}>
        <ValidationMessage errors={validationErrors} />
        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item
                name="Starts"
                description="Please choose when the event date starts."
                helpPopup
                required
              >
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  closeOnSelect
                  placeholder="Pick a start date..."
                  selectedDate={_eventDate.starts}
                  onChange={handleStartDateChanged}
                />
                <TimePicker
                  initialValue={_startTime}
                  onChange={time => {
                    handleStartTimeChanged(time);
                  }}
                />
              </StructuredList.Item>

              <StructuredList.Item
                name="Ends"
                description="Please choose when the event ends."
                helpPopup
                required
              >
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  closeOnSelect
                  placeholder="Pick an end date..."
                  selectedDate={_eventDate.ends}
                  onChange={handleEndDateChanged}
                />
                <TimePicker
                  initialValue={_endTime}
                  onChange={time => {
                    handleEndTimeChanged(time);
                  }}
                />
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Left>
            <Button
              text="Close"
              onClick={onClose}
              color={Swatches.Low}
              size={Size.Small}
            />
            {_eventDate?.id && (
              <Button
                text="Delete"
                onClick={handleDelete}
                color={Swatches.Low}
                size={Size.Small}
              />
            )}
          </Left>
          <Right>
            <Button
              text="Save"
              onClick={handleSave}
              color={Swatches.Success}
              size={Size.Small}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  ) : null;
};

export default EventDateEditorModal;
