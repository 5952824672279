import React from "react";
import { useReview } from "./reviewContext";
import ReviewSummary from "./reviewSummary";

const ReviewViewer = () => {
  const { state, dispatch } = useReview();

  return <ReviewSummary />;
};

export default ReviewViewer;
