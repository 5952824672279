import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import { RescheduledDetentionListView } from "areas/behaviour/types/detentionRegisterResponse.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


interface IRescheduledDetentionsState {
  rescheduledDetentions: RescheduledDetentionListView[];
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IRescheduledDetentionsState = {
  rescheduledDetentions: [],
  loading: false,
  error: null,
};

const { GETRESCHEDULEDDETENTIONS } = detentionActions.types;

const rescheduledDetentionsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IRescheduledDetentionsState => {

  switch (action.type) {
    case GETRESCHEDULEDDETENTIONS.START:
      return { ...state, loading: true, error: null };

    case GETRESCHEDULEDDETENTIONS.SUCCESS:
      return {
        ...state,
        loading: false,
        rescheduledDetentions: action.payload,
      };

    case GETRESCHEDULEDDETENTIONS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default rescheduledDetentionsReducer;
