import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { ManagedUserDetailView } from "types/users/userListViews.types";
import { DataUpdateRequestCommand, UpdateUserNotificationPreferencesCommand } from "../types/profileRequest.types";
import { UserNotificationPreferences } from "../types/profileResponses.types";

const builder = new actionTypeBuilder("profile");

const types = {
  PROFILE_GETPROFILE: builder.build("PROFILE_GETPROFILE"),
  PROFILE_SUBMITDATAUPDATEREQUEST: builder.build(
    "PROFILE_SUBMITDATAUPDATEREQUEST"
  ),
  PROFILE_SETNOTIFICATIONPREFERENCES: builder.build(
    "USERMANAGEMENT_SETNOTIFICATIONPREFERENCES"
  ),
};


const getProfile = (userId: string, callback?: callbackType<ManagedUserDetailView>) =>
  action<ManagedUserDetailView>(
    () => client.get(`users/${userId}`),
    types.PROFILE_GETPROFILE,
    callback
  );

const submitDataUpdateRequest = (userId: string, data: DataUpdateRequestCommand, callback?: callbackType<null>) =>
  action<null>(
    () => client.post(`users/${userId}/dataupdaterequests`, data),
    types.PROFILE_SUBMITDATAUPDATEREQUEST,
    callback
  );

const setNotificationPreferences = (userId: string, preferences: UpdateUserNotificationPreferencesCommand, callback?: callbackType<UserNotificationPreferences>) =>
  action<UserNotificationPreferences>(
    () => client.post(`users/${userId}/notifications/preferences`, preferences),
    types.PROFILE_SETNOTIFICATIONPREFERENCES,
    callback
  );


const profileActions = {
  types,
  getProfile,
  submitDataUpdateRequest,
  setNotificationPreferences,
};

export default profileActions;
