import actionTypeBuilder from "actions/actionTypeBuilder";
import store from "reducers/store";
import { IBeehiveAction } from "types/common/action.types";

const builder = new actionTypeBuilder("administration");

const types = {
  ADMINISTRATION_HOMESWITCHTAB: "ADMINISTRATION_HOMESWITCHTAB",
  ADMINISTRATION_GETUSER: builder.build("ADMINISTRATION_GETUSER"),
};


const switchAdministrationTab = (index: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.ADMINISTRATION_HOMESWITCHTAB,
    payload: index,
  });
};


const administrationActions = {
  types,
  switchAdministrationTab,
};

export default administrationActions;
