import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./dashboard";
import ReviewWrapper from "./reviews/reviewWrapper";
import CustomRoute from "sharedComponents/layout/routes/customRoute";
import constants from "configuration/constants";
import { pmRoutes } from "areas/humanResources/hrRoutes";


const Index = () => {

  return (
    <Routes>
      <Route
        index
        element={
          <CustomRoute users={[constants.USER_TYPES.STAFF]}>
            <Dashboard />
          </CustomRoute>
        }
      />
      <Route
        path={pmRoutes.staffReview}
        element={
          <CustomRoute users={[constants.USER_TYPES.STAFF]}>
            <ReviewWrapper />
          </CustomRoute>
        }
      />
    </Routes>
  );
};

export default Index;
