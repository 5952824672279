import declarationsOfInterestActions from "areas/forms/actions/declarations/declarationsOfInterestActions";
import { DeclarationOfInterestListView } from "areas/forms/types/declarations/declarationsOfInterestResponse.types";
import defaults from "configuration/defaults";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";
import { PageInfo } from "types/common/paging.types";

interface IAdminDeclarationsState {
  declarations: DeclarationOfInterestListView[];
  loading: boolean;
  error: IBeehiveError;
  paging: PageInfo;
}

const INITIAL_STATE: IAdminDeclarationsState = {
  declarations: [],
  loading: false,
  error: null,
  paging: defaults.DEFAULT_PAGING,
};


const adminDeclarationsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAdminDeclarationsState => {

  const { ADMIN_GET_DECLARATIONS } = declarationsOfInterestActions.types;

  switch (action.type) {

    case ADMIN_GET_DECLARATIONS.START:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case ADMIN_GET_DECLARATIONS.SUCCESS:
      return {
        ...state,
        loading: false,
        declarations: action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.declarations, ...action.payload.items],
        paging: action.payload.paging,
      }
    case ADMIN_GET_DECLARATIONS.START:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state;
  }

}


export default adminDeclarationsReducer;