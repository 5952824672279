import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  StructuredList,
  DatePicker,
  Dropdown,
  ActionBar,
  Button,
  Size,
  Table,
  Message,
  Swatches,
  ValidationMessage,
  Title,
  TitleSize,
} from "ui-kit";
import { arrays } from "utils";
import moment from "moment";
import ReportDescription from "../reportDescription";
import managedMovesActions from "areas/planner/actions/managedMoves/managedMovesActions";
import SchoolAvatar from "sharedComponents/common/schools/schoolAvatar";
import { useAppSelector } from "reducers/hooks";
import { ApiExceptionMessage } from "sharedComponents/common";
import { ManagedMoveStatus } from "areas/planner/types/managedMoves/managedMovesShared.types";
import { ManagedMoveSummaryFilter } from "areas/planner/types/managedMoves/managedMovesRequest.types";


interface ManagedMoveSummaryFilterExt extends Omit<ManagedMoveSummaryFilter, "status" | "startDate" | "endDate"> {
  status?: ManagedMoveStatus | null;
  startDate?: Date;
  endDate?: Date;
}


const ManagedMovesReport = () => {

  const [options, setOptions] = useState<ManagedMoveSummaryFilterExt>({
    startDate: null,
    endDate: null,
    status: null,
  });
  const [validationErrors, setValidationErrors] = useState([]);

  const { summary, loading, error } = useAppSelector(
    state => state.managedMovesSummary
  );

  const handleRunReport = () => {
    var errors = [];

    if (moment(options.startDate).isAfter(options.endDate)) {
      errors.push("The end date must be after the start date.");
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    managedMovesActions.getManagedMovesSummary(
      {
        ...options,
        startDate: moment(options.startDate).format("YYYY-MM-DD"),
        endDate: moment(options.startDate).format("YYYY-MM-DD")
      }, 
      () => {});
  };

  return (
    <>
      <ApiExceptionMessage error={error} />
      <ValidationMessage errors={validationErrors} />

      <Title text="Managed Moves Report" size={TitleSize.H3} />
      <ReportDescription text="Use this report to view managed moves." />
      <Card title="Report Options">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Start Date">
              <DatePicker
                selectedDate={options.startDate}
                onChange={value => setOptions({ ...options, startDate: value })}
                closeOnSelect
              />
            </StructuredList.Item>
            <StructuredList.Item name="End Date">
              <DatePicker
                selectedDate={options.endDate}
                closeOnSelect
                onChange={value => setOptions({ ...options, endDate: value })}
              />
            </StructuredList.Item>
            <StructuredList.Item name="Status">
              <Dropdown
                fluid
                value={options.status}
                onChange={value => setOptions({ ...options, status: value })}
              >
                <Dropdown.Item value={null} label="All Outcomes" />
                <Dropdown.Item value={ManagedMoveStatus.Open} label="Open" />
                <Dropdown.Item value={ManagedMoveStatus.Hosting} label="Hosted" />
                <Dropdown.Item value={ManagedMoveStatus.ManagedMove} label="Managed Move" />
                <Dropdown.Item value={ManagedMoveStatus.PermanentMove} label="Permanent Move" />
              </Dropdown>
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
      <ActionBar>
        <Button
          text="Run Report"
          color={Swatches.Success}
          size={Size.Small}
          onClick={handleRunReport}
          working={loading}
        />
      </ActionBar>

      {summary != null && (
        <Card title="Managed Moves Summary">
          <Card.Body noPad>
            <Table>
              <Table.Header>
                <Table.HeaderCell width={9}>School</Table.HeaderCell>
                <Table.HeaderCell right width={2}>
                  Hosted Moves
                </Table.HeaderCell>
                <Table.HeaderCell right width={1}>
                  Points
                </Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {summary?.schools.map((school, index) => (
                  <Table.Row key={index}>
                    <Table.Cell width={9}>
                      <SchoolAvatar school={school.school} />
                    </Table.Cell>
                    <Table.Cell right width={2}>
                      {school.movesReceived}
                    </Table.Cell>
                    <Table.Cell right width={1}>
                      {school.points}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default ManagedMovesReport;
