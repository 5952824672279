import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import FileDownload from "js-file-download";
import { callbackType } from "types/common/action.types";
import { AxiosProgressEvent } from "axios";
import { UploadStudentCsvCommand } from "../types/fileRequest.types";
import { ProcessStudentCsvResult } from "../types/fileResponse.types";

const builder = new actionTypeBuilder("files");

const types = {
  UPLOAD_FILES: builder.build("UPLOAD_FILES"),
  DOWNLOAD_FILE: builder.build("DOWNLOAD_FILE"),
  IMPORT_STUDENTCSV: builder.build("IMPORT_STUDENTCSV"),
};


const uploadFiles = (data: FormData, progress: (progressEvent: AxiosProgressEvent) => void, callback?: callbackType<number[]>) =>
  action<number[]>(
    () => client.post(`files/upload`, data, { headers: { "content-type": "multipart/form-data" }, onUploadProgress: progress }),
    types.UPLOAD_FILES,
    callback
  );

const downloadFile = (fileId: number, callback?: callbackType<Blob>) =>
  action<Blob>(
    () => client.get(`files/download/${fileId}`, { responseType: "blob" }),
    types.DOWNLOAD_FILE,
    (response, headers) => {
      callback && callback(response);
      const contentDisposition = headers["content-disposition"];
      var filename = contentDisposition.split("=");
      FileDownload(response, filename[1]);
    }
  );

const importStudentCsv = (data: UploadStudentCsvCommand, progress: (progressEvent: AxiosProgressEvent) => void, callback?: callbackType<ProcessStudentCsvResult>) =>
  action<ProcessStudentCsvResult>(
    () => client.post(`import/students/csv`, data, { headers: { "content-type": "multipart/form-data" }, onUploadProgress: progress }),
    types.IMPORT_STUDENTCSV,
    callback
  );

  
const fileActions = {
  types,
  uploadFiles,
  downloadFile,
  importStudentCsv,
};

export default fileActions;
