import PublicTemplate from "sharedComponents/layout/public/publicTemplate";
import PasswordStrengthChecker from "sharedComponents/common/passwords/passwordStrengthChecker";


const CheckPassword = () => {

  return (
    <PublicTemplate title="Check a Password">
      <PasswordStrengthChecker />
    </PublicTemplate>
  );
};

export default CheckPassword;
