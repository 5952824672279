export interface Quote {
  quote: string
  credit: string
}

var quotes : Quote[] = [
  {
    quote:
      "Education is the most powerful weapon which you can use to change the world.",
    credit: "Nelson Mandela",
  },
  {
    quote:
      "The function of education is to teach one to think intensively and to think critically. Intelligence plus character - that is the goal of true education.",
    credit: "Martin Luther King, Jr.",
  },
  {
    quote:
      "It is the mark of an educated mind to be able to entertain a thought without accepting it.",
    credit: "Aristotle",
  },
  {
    quote:
      "Education is the best friend. An educated person is respected everywhere. Education beats the beauty and the youth.",
    credit: "Chanakya",
  },
  {
    quote: "An investment in knowledge pays the best interest.",
    credit: "Benjamin Franklin",
  },
  {
    quote:
      "Develop a passion for learning. If you do, you will never cease to grow.",
    credit: "Anthony J. D'Angelo",
  },
  {
    quote: "Education is not preparation for life; education is life itself.",
    credit: "John Dewey",
  },
  {
    quote: "I learned the value of hard work by working hard.",
    credit: "Margaret Mead",
  },
  {
    quote:
      "A wise man can learn more from a foolish question than a fool can learn from a wise answer.",
    credit: "Bruce Lee",
  },
  {
    quote: "Learning never exhausts the mind.",
    credit: "Leonardo da Vinci",
  },
  {
    quote: "Every champion was once a contender who refused to give up.",
    credit: "Rocky Balboa",
  },
  {
    quote: "Be the change that you wish to see in the world.",
    credit: "Mahatma Gandhi",
  },
  {
    quote: "Everything you can imagine is real.",
    credit: "Pablo Picasso",
  },
  {
    quote:
      "Life isn't about finding yourself. Life is about creating yourself.",
    credit: "George Bernard Shaw",
  },
  {
    quote: "Do what you can, with what you have, where you are.",
    credit: "Theodore Roosevelt",
  },
  {
    quote:
      "And, when you want something, all the universe conspires in helping you to achieve it.",
    credit: "Paulo Coelho",
  },
  {
    quote: "Beauty comes first. Victory is secondary. What matters is joy.",
    credit: "Socrates Brasileiro",
  },
  {
    quote:
      "Success is no accident. It is hard work, perseverance, learning, studying, sacrifice and most of all, love of what you are doing or learning to do.",
    credit: "Pele",
  },
  {
    quote:
      "The more difficult the victory, the greater the happiness in winning.",
    credit: "Pele",
  },

  {
    quote:
      "You are braver than you believe, stronger than you seem, and smarter than you think.",
    credit: "Christopher Robin",
  },
  {
    quote:
      "A lot of people give up just before they're about to make it. You know, you never know when that next obstacle is going to be the last one.",
    credit: "Chuck Norris",
  },
  {
    quote:
      "Whatever you do, do it well. Do it so well that when people see you do it, they will want to come back and see you do it again...",
    credit: "Walt Disney",
  },
  {
    quote: "It is better to change an opinion than to persist in a wrong one.",
    credit: "Socrates",
  },
  {
    quote:
      "With your mind power, your determination, your instinct, and the experience as well, you can fly very high.",
    credit: "Ayrton Senna",
  },
  {
    quote:
      "The mind is the limit. As long as the mind can envision the fact that you can do something, you can do it, as long as you really believe 100 percent.",
    credit: "Arnold Schwarzenegger",
  },
  {
    quote: "Ambition without knowledge is like a boat on dry land.",
    credit: "Mr Miyagi",
  },
  {
    quote:
      "The most beautiful thing in the world is to live each second as if it were your last.",
    credit: "Vinicius de Moraes",
  },
  {
    quote:
      "Learn as if you will live forever, live like you will die tomorrow.",
    credit: "Mahatma Gandhi",
  },
  {
    quote:
      "Success is not final; failure is not fatal: It is the courage to continue that counts.",
    credit: "Winston Churchill",
  },
  {
    quote:
      "If you are working on something that you really care about, you don't have to be pushed. The vision pulls you.",
    credit: "Steve Jobs",
  },
  {
    quote: "He who conquers himself is the mightiest warrior.",
    credit: "Confucius",
  },
  {
    quote: "Inspiration does exist, but it must find you working.",
    credit: "Pablo Picasso",
  },
  {
    quote: "Somewhere, something incredible is waiting to be known.",
    credit: "Carl Sagan",
  },
  {
    quote:
      "It's fine to celebrate success but it is more important to heed the lessons of failure.",
    credit: "Bill Gates",
  },
  {
    quote:
      "Perfection is not attainable. But if we chase perfection we can catch excellence.",
    credit: "Vince Lombardi",
  },
];

export default quotes;
