import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Title,
  TitleSize,
  Wizard,
  StructuredList,
  EmptyMessage,
  Loader,
  TextInput,
  DatePicker,
  Size,
  Dropdown,
  Spacing,
  Card,
  Button,
  Swatches,
  neutral,
  Message,
} from "ui-kit";
import { arrays } from "utils";
import Subheader from "sharedComponents/layout/header/subheader";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import profileActions from "areas/administration/actions/profileActions";
import { RootState } from "reducers/store";
import { ManagedUserDetailView, ManagedParentDetailView, ManagedStudentDetailView } from "types/users/userListViews.types";
import { TelephoneNumberListView } from "types/users/userContact.types";
import { DataUpdateRequestCommand } from "areas/administration/types/profileRequest.types";
import { useScroll } from "hooks/useScroll";


const TelephoneNumbersWrapper = styled.div`
  border-bottom: 1px solid ${neutral[300]};
  margin-bottom: ${Spacing.Default}px;
`;
const TelephoneWrapper = styled.div`
  margin-bottom: ${Spacing.Default}px;

  .form-control {
    margin-right: ${Spacing.Default}px;
  }
`;


const DEFAULT_TELEPHONENUMBER: TelephoneNumberListView = {
  number: "", 
  location: "Home",
  type: null,
  isMain: false,
  isPrimary: false
};


const ProfileDataUpdate = () => {

  const { scrollToTop } = useScroll();
  const { userId } = useParams();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { profile, loading, error, submitting } = useSelector((state: RootState) => state.profile);
  const [_profile, _setProfile] = useState<ManagedUserDetailView | ManagedParentDetailView | ManagedStudentDetailView | null>(null);
  const [isSelf, setIsSelf] = useState(true);
  const [_newNumber, _setNewNumber] = useState<TelephoneNumberListView>(DEFAULT_TELEPHONENUMBER);
  const [_newNumberValidationErrors, _setNewNumberValidationErrors] = useState(
    []
  );
  const [_validationErrors, _setValidationErrors] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {
    if (userId) {
      profileActions.getProfile(userId);
    } else {
      user && profileActions.getProfile(user.id);
    }
  }, [user, userId]);

  useEffect(() => {
    profile &&
      _setProfile({
        ...profile,
        dateOfBirth: profile.dateOfBirth,
      });
  }, [profile]);

  const handleFinish = () => {
    return new Promise((resolve, reject) => {
      var errors = [];

      if (!_profile.firstName) {
        errors.push("Please enter a first name");
      }
      if (!_profile.lastName) {
        errors.push("Please enter a last name");
      }
      if (!_profile.email) {
        errors.push("Please enter an email");
      }
      if (!_profile.address.address1) {
        errors.push("Please enter your house or flat number");
      }

      if (!_profile.address.address2) {
        errors.push("Please enter a street name");
      }

      if (!_profile.address.town) {
        errors.push("Please enter a town");
      }

      if (!_profile.address.postcode) {
        errors.push("Please enter a postcode");
      }

      _setValidationErrors(errors);

      if (errors.length) {
        scrollToTop();
        return;
      }

      var command: DataUpdateRequestCommand = {
        userId: _profile.id,
        firstName: _profile.firstName,
        lastName: _profile.lastName,
        email: _profile.email,
        ..._profile.address,
        telephoneNumbers: _profile.telephoneNumbers,
      };

      profileActions.submitDataUpdateRequest(_profile.id, command);
    });
  };

  const handleComplete = () => {
    navigate(-1);
  };
  const handleCancel = () => {
    navigate(-1);
  };

  const handleAddNumber = () => {
    var errors = [];

    if (!_newNumber.number || _newNumber.number == "") {
      errors.push("Please enter a number");
    }

    _setNewNumberValidationErrors(errors);

    if (errors.length) {
      return;
    }

    _profile.telephoneNumbers.push(_newNumber);
    _setNewNumber(DEFAULT_TELEPHONENUMBER);
  };

  const handleRemoveNumber = (number: TelephoneNumberListView, index: number) => {
    console.log(number, index);
    _setProfile({
      ..._profile,
      telephoneNumbers: arrays.remove(_profile.telephoneNumbers, index),
    });
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }
  if (error) {
    return (
      <EmptyMessage
        cover
        icon="user"
        title="An error occurred"
        summary="Unfortunately, there was a problem while loading the profile information"
      />
    );
  }

  if (!profile) {
    return (
      <EmptyMessage
        icon="user"
        title="Profile Not Found"
        summary="Unfortunately, the profile information could not be loaded"
        cover
      />
    );
  }

  return (
    <>
      <Subheader>
        <Title
          size={TitleSize.H2}
          text="Your Account"
          sub="Update Your Details"
        />
      </Subheader>
      <Wizard
        error={error}
        showHeaderSteps={false}
        onFinish={handleFinish}
        working={submitting}
        onCancel={handleCancel}
        finishButtonText="Submit Update"
        onComplete={handleComplete}
        completionButtonText="Finish and Return"
      >
        <Wizard.Step>
          <Card title="Introduction">
            <Card.Body>
              <p>
                On this page, you can request an update to the data we hold for
                you. Once we receive your request, our systems will be updated
                within 48 hours, and you will be notified of when the change has
                taken place. If you do not receive a confirmation of the change
                after 3 days, please contact your school office.{" "}
              </p>

              <p>
                For more information on how the we process and store your data,
                please review our Data Protection Policy and Privacy Notices
                which can be found on the{" "}
                <a
                  href="https://lionhearttrust.org.uk/about-us/trust-policies/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Lionheart Educational Trust Policies Page
                </a>
                .
              </p>

              <p>
                To request an update, simply edit your details below and click
                on <b>'Submit Update'</b> below.
              </p>
            </Card.Body>
          </Card>
          {!arrays.isEmpty(_validationErrors) && (
            <Message
              text={_validationErrors.map((err, index) => (
                <React.Fragment key={index}>
                  {err}
                  <br />
                </React.Fragment>
              ))}
              color={Swatches.Danger}
            />
          )}
          <Card title="Your Personal Details">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="First Name" required>
                  <TextInput
                    maxLength={30}
                    value={_profile?.firstName}
                    onChange={value =>
                      _setProfile({ ..._profile, firstName: value })
                    }
                    fluid
                  />
                </StructuredList.Item>
                <StructuredList.Item name="Last Name" required>
                  <TextInput
                    maxLength={30}
                    value={_profile?.lastName}
                    onChange={value =>
                      _setProfile({ ..._profile, lastName: value })
                    }
                    fluid
                  />
                </StructuredList.Item>
                {profile?.dateOfBirth && (
                  <StructuredList.Item name="Date of Birth">
                    <DatePicker
                      selectedDate={_profile?.dateOfBirth}
                      closeOnSelect
                      onChange={value =>
                        _setProfile({ ..._profile, dateOfBirth: moment(value).format("YYYY-MM-DD") })
                      }
                    />
                  </StructuredList.Item>
                )}
                <StructuredList.Item
                  name="Email"
                  description="Please note that if you change your email, your Beehive login email will also change."
                  required
                >
                  <TextInput
                    maxLength={256}
                    value={_profile?.email}
                    onChange={value =>
                      _setProfile({ ..._profile, email: value })
                    }
                    fluid
                  />
                </StructuredList.Item>

                {profile?.address && (
                  <StructuredList.Item name="Address" required>
                    <TextInput
                      maxLength={30}
                      value={_profile?.address?.address1}
                      onChange={value =>
                        _setProfile({
                          ..._profile,
                          address: {
                            ..._profile?.address,
                            address1: value,
                          },
                        })
                      }
                      fluid
                      placeholder="House/Flat No."
                    />
                    <br />
                    <TextInput
                      value={_profile?.address?.address2}
                      maxLength={30}
                      onChange={value =>
                        _setProfile({
                          ..._profile,
                          address: {
                            ..._profile?.address,
                            address2: value,
                          },
                        })
                      }
                      placeholder="Street"
                      fluid
                    />
                    <br />
                    <TextInput
                      value={_profile?.address?.address3}
                      maxLength={30}
                      onChange={value =>
                        _setProfile({
                          ..._profile,
                          address: {
                            ..._profile?.address,
                            address3: value,
                          },
                        })
                      }
                      fluid
                      placeholder="Address Line 3"
                    />
                    <br />
                    <TextInput
                      value={_profile?.address?.town}
                      maxLength={30}
                      onChange={value =>
                        _setProfile({
                          ..._profile,
                          address: {
                            ..._profile?.address,
                            town: value,
                          },
                        })
                      }
                      placeholder="Town"
                      fluid
                    />
                    <br />
                    <TextInput
                      value={_profile?.address?.county}
                      maxLength={30}
                      onChange={value =>
                        _setProfile({
                          ..._profile,
                          address: {
                            ..._profile?.address,
                            county: value,
                          },
                        })
                      }
                      placeholder="County"
                      fluid
                    />
                    <br />
                    <TextInput
                      value={_profile?.address?.postcode}
                      maxLength={8}
                      onChange={value =>
                        _setProfile({
                          ..._profile,
                          address: {
                            ..._profile?.address,
                            postcode: value,
                          },
                        })
                      }
                      placeholder="Postcode"
                    />
                  </StructuredList.Item>
                )}
                <StructuredList.Item name="Telephone Numbers">
                  {!arrays.isEmpty(_profile?.telephoneNumbers) && (
                    <TelephoneNumbersWrapper>
                      {_profile.telephoneNumbers.map((number, index) => (
                        <TelephoneWrapper key={index}>
                          {" "}
                          <Dropdown
                            value={number.location}
                            onChange={value =>
                              _setProfile({
                                ..._profile,
                                telephoneNumbers: _profile.telephoneNumbers.map(
                                  (n, i) =>
                                    i == index ? { ...n, location: value } : n
                                ),
                              })
                            }
                          >
                            <Dropdown.Item value="Home" label="Home" />
                            <Dropdown.Item value="Work" label="Work" />
                            <Dropdown.Item value="Mobile" label="Mobile" />
                          </Dropdown>
                          <TextInput
                            value={number.number}
                            onChange={value =>
                              _setProfile({
                                ..._profile,
                                telephoneNumbers: _profile.telephoneNumbers.map(
                                  (n, i) =>
                                    i == index ? { ...n, number: value } : n
                                ),
                              })
                            }
                            placeholder="Number"
                            maxLength={15}
                          />
                          <Button
                            text="Remove"
                            onClick={() => handleRemoveNumber(number, index)}
                            color={Swatches.Danger}
                            size={Size.Small}
                          />
                        </TelephoneWrapper>
                      ))}
                    </TelephoneNumbersWrapper>
                  )}
                  <TelephoneWrapper>
                    {!arrays.isEmpty(_newNumberValidationErrors) && (
                      <Message
                        text={_newNumberValidationErrors.map((err, index) => (
                          <React.Fragment key={index}>
                            {err}
                            <br />
                          </React.Fragment>
                        ))}
                        color={Swatches.Danger}
                      />
                    )}
                    <Dropdown
                      value={_newNumber?.location}
                      onChange={value =>
                        _setNewNumber({ ..._newNumber, location: value })
                      }
                    >
                      <Dropdown.Item value="Home" label="Home" />
                      <Dropdown.Item value="Work" label="Work" />
                      <Dropdown.Item value="Mobile" label="Mobile" />
                    </Dropdown>
                    <TextInput
                      value={_newNumber?.number}
                      onChange={value =>
                        _setNewNumber({ ..._newNumber, number: value })
                      }
                      placeholder="Add a new number..."
                      maxLength={15}
                    />
                    <Button
                      text="Add"
                      onClick={handleAddNumber}
                      color={Swatches.Primary}
                      size={Size.Small}
                    />
                  </TelephoneWrapper>
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
        </Wizard.Step>
        <Wizard.Step completion>
          <Card title="Data Update Request Submitted">
            <Card.Body>
              <p>
                Thank you for submitting your updated data.Your request has been
                received and once processed, our systems will be updated within
                48 hours and confirmation will be sent when completed. Please
                note that your updated data will not be visible straight away.
              </p>
            </Card.Body>
          </Card>
        </Wizard.Step>
      </Wizard>
    </>
  );
};

export default ProfileDataUpdate;