import { IBeehiveAction } from "types/common/action.types";
import client from "../client";
import store from "reducers/store";
import { CachedUserImage } from "reducers/fileCache/profileImageCacheReducer";


const types = {
  CACHEIMAGE: "CACHEIMAGE",
};


const getProfileImage = (userId: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    client
      .get(`images/users/${userId}`, { responseType: "arraybuffer" })
      .then(response => {
        resolve(Buffer.from(response.data, "base64").toString());
      })
      .catch(error => {
        reject(error);
      });
  });
};


const cacheImage = (imageUrl: CachedUserImage) => {
  store.dispatch<IBeehiveAction<CachedUserImage>>({
    type: types.CACHEIMAGE,
    payload: imageUrl,
  });
};


const fileService = {
  types,
  getProfileImage,
};

export default fileService;
