import React, { useEffect, useState } from "react";
import {
  Modal,
  ActionBar,
  Button,
  Size,
  Swatches,
  Card,
  StructuredList,
  Left,
  Dropdown,
  Right,
  Message,
  TextInput,
  TextInputType,
  ValidationMessage,
  ToastService,
} from "ui-kit";
import { arrays } from "utils";
import { useSelector } from "react-redux";
import calendarActions from "areas/calendar/actions/calendarActions";
import { Avatar, UserSelector } from "sharedComponents/common";
import { CalendarEventDateListView, CalendarEventDateSignupListView, CalendarEventMenuItemListView, GroupedMenuView } from "areas/calendar/types/calendarResponses.types";
import { RootState } from "reducers/store";
import { CalendarEventSignupCommand } from "areas/calendar/types/calendarRequests.types";
import { CalendarEventDateAttendance, CalendarEventSignupStatus } from "areas/calendar/types/calendarShared.types";
import { useScroll } from "hooks/useScroll";
import { MenuChoice } from "./eventModal/eventMenu";


interface IAddSignupModalProps {
  eventDate: CalendarEventDateListView;
  menu: GroupedMenuView[];
  signup: CalendarEventDateSignupListView;
  open: boolean;
  onClose?: () => void;
  onSave?: (signup: CalendarEventDateSignupListView) => void;
}

//export type EventSignup = Partial<Omit<CalendarEventDateSignupListView, "menu">> & { menu: MenuChoice[] }; // I don't want to change fixed payload key requirements, so I'll make a new type and override menu

export type EventSignup = Omit<CalendarEventDateSignupListView, "menu"> & { menu: MenuChoice[] };


const AddSignupModal: React.FC<IAddSignupModalProps> = ({ eventDate, menu, signup, open, onClose, onSave }) => {
  
  const { calendarEvent, signingUp, signupError } = useSelector((state: RootState) => state.calendarEvent);
  const [_open, _setOpen] = useState<boolean>(open);
  const [_eventDate, _setEventDate] = useState<CalendarEventDateListView>(eventDate);
  const [_menu, _setMenu] = useState<GroupedMenuView[]>(menu);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const [_signup, _setSignup] = useState<EventSignup>({
    id: null,
    status: CalendarEventSignupStatus.Attending,
    attendance: CalendarEventDateAttendance.Pending,
    statusId: 0,
    user: null,
    createdDate: new Date(),
    createdBy: null,
    consentGiven: false,
    consentGivenBy: null,
    rewardName: "",
    accessRequirements: "",
    dietaryRequirements: "",
    menu: []
  });

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    _setEventDate(eventDate);

    setValidationErrors([]);
  }, [eventDate]);

  useEffect(() => {
    _setMenu(menu);

    if (menu && !_signup?.menu && menu?.length > 0) {
      var defaultMenu = [];
      for (var i = 0; i < menu.length; i++) {
        defaultMenu.push({ id: menu[i].menu[0].id, type: menu[i].type });
      }

      _setSignup({ ..._signup, menu: defaultMenu });
    }
  }, [menu]);

  useEffect(() => {
    if (signup) {
      _setSignup({
        ...signup,
        menu: signup?.menu?.map(x => ({ id: x.id, type: x.type })),
      });
    } else {
      _setSignup({
        id: null,
        status: CalendarEventSignupStatus.Attending,
        attendance: CalendarEventDateAttendance.Pending,
        statusId: 0,
        user: null,
        createdDate: new Date(),
        createdBy: null,
        consentGiven: false,
        consentGivenBy: null,
        rewardName: "",
        accessRequirements: "",
        dietaryRequirements: "",
        menu: []
      });
    }
  }, [signup]);

  const setMenuChoice = (menuItem: MenuChoice) => {
    const index = _signup.menu?.map(e => e.type).indexOf(menuItem.type);
    var newChoices = _signup.menu;

    if (index != null) {
      newChoices = arrays.remove(_signup.menu, index);
    }

    newChoices.push(menuItem);

    _setSignup({ ..._signup, menu: newChoices });
  };

  const handleSave = () => {
    var errors = [];

    if (!_signup.user) {
      errors.push(`Please choose an attendee.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    if (
      window.confirm(
        `This will ${_signup.id ? "update the details for" : "sign up"} ${
          _signup.user.firstName
        } ${_signup.user.lastName} for this event date. Are you sure?`
      )
    ) {
      const data: CalendarEventSignupCommand = {
        userId: _signup.user.id,
        eventId: calendarEvent.id,
        eventDates: [_eventDate.id],
        accessRequirements: _signup.accessRequirements,
        dietaryRequirements: _signup.dietaryRequirements,
        menu: _signup.menu?.map(x => x.id),
      };
      calendarActions.calendarEventSignup(data, id => {
        ToastService.pop("Signed up successfully", null, "calendar");
        onSave?.(_signup as CalendarEventDateSignupListView);
      });
    }
  };

  return _eventDate ? (
    <Modal
      title={_signup?.id ? "Update Details" : "New Attendee"}
      open={_open}
      onClose={onClose}
      width="60%"
      height="50%"
    >
      <Modal.Body scrollOnErrors={validationErrors}>
        <ValidationMessage errors={validationErrors} />
        <Message text={signupError} color={Swatches.Danger} />
        <Card title="Attendee">
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="Attendee" helpPopup required>
                {_signup?.id ? (
                  <Avatar user={_signup?.user} />
                ) : (
                  <UserSelector
                    user={_signup?.user}
                    onChange={user => _setSignup({ ..._signup, user: user })}
                  />
                )}
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
        {_menu != null && _menu.length > 0 && (
          <>
            <Card title="Menu Selection">
              <Card.Body>
                <StructuredList>
                  {_menu.map((course: GroupedMenuView, index: number) => (
                    <StructuredList.Item
                      key={index}
                      name={course.typeName}
                      required={course.menu.length > 1}
                    >
                      {course.menu.length === 1 ? (
                        <>{course.menu[0].name}</>
                      ) : (
                        <>
                          <Dropdown
                            fluid
                            onChange={(val: MenuChoice) => setMenuChoice(val)}
                            value={_signup?.menu?.find(
                              x => x.type === course.type
                            )}
                          >
                            {course.menu.map((dish: CalendarEventMenuItemListView, index: number) => (
                              <Dropdown.Item
                                key={index}
                                label={dish.name}
                                value={{ id: dish.id, type: dish.type }}
                              />
                            ))}
                          </Dropdown>
                        </>
                      )}
                    </StructuredList.Item>
                  ))}
                </StructuredList>
              </Card.Body>
            </Card>
            <Card title="Dietary Information">
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item
                    name="Diet Requirements"
                    description="Please add any special requirements including any allergies"
                  >
                    <TextInput
                      type={TextInputType.Textarea}
                      rows={3}
                      value={_signup?.dietaryRequirements}
                      onChange={value =>
                        _setSignup({ ..._signup, dietaryRequirements: value })
                      }
                      fluid
                    />
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
          </>
        )}

        <Card title="Accessibility">
          <Card.Body>
            <StructuredList>
              <StructuredList.Item
                name="Access Requirements"
                description="Please add any accessibility requirements relevant to this event"
              >
                <TextInput
                  rows={3}
                  type={TextInputType.Textarea}
                  value={_signup?.accessRequirements}
                  onChange={value =>
                    _setSignup({ ..._signup, accessRequirements: value })
                  }
                  fluid
                />
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Left>
            <Button
              text="Close"
              onClick={onClose}
              color={Swatches.Low}
              size={Size.Small}
              working={signingUp}
            />
          </Left>
          <Right>
            <Button
              text="Save"
              onClick={handleSave}
              color={Swatches.Success}
              size={Size.Small}
              working={signingUp}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  ) : null;
};

export default AddSignupModal;
