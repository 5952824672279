import { faClipboardUser } from "@fortawesome/pro-solid-svg-icons";
import { DeclarationOfInterestDetailView, DeclarationOfInterestVersionDetailView } from "areas/forms/types/declarations/declarationsOfInterestResponse.types";
import config, { Constants } from "configuration";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Card, Chip, EmptyMessage, Flyout, HtmlText, Label, Left, Right, Size, Spacing, SplitButton, StructuredList, Swatches, Table, Title, TitleSize } from "ui-kit";
import FileList from "sharedComponents/common/files/fileList";
import { DeclarationType, DeclarationVersionStatus } from "../../types/declarations/declarationsOfInterestShared.types";
import moment from "moment";
import declarationsOfInterestActions from "../../actions/declarations/declarationsOfInterestActions";
import DeclarationEditorModal from "./declarationEditorModal";
import { useAppSelector } from "reducers/hooks";
import { arrays } from "utils";


const Header = styled.div`
  margin-bottom: ${Spacing.Large}px;
  display: flex;
  align-items: top;
  justify-content: space-between;
`;


interface IUserDeclarationProps {
  declaration: DeclarationOfInterestDetailView;
}


const UserDeclaration: React.FC<IUserDeclarationProps> = ({ declaration }) => {

  const { saving, saveError, deleting, deleteError, submitting, submitError, cloning, cloneError } = useAppSelector(state => state.declarationOfInterest);

  const { user } = useAppSelector(state => state.currentUser);
  const [_declaration, _setdeclaration] = useState<DeclarationOfInterestDetailView>(null);
  const [_declarationVersion, _setDeclarationVersion] = useState<DeclarationOfInterestVersionDetailView>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const [submitErrors, setSubmitErrors] = useState<string[]>([]);

  useEffect(() => {
    _setdeclaration(declaration);
  }, [declaration]);

  useEffect(() => {
    _declaration && _setDeclarationVersion(_declaration.latestVersion)
  }, [_declaration])

  if (_declaration === null) {
    return (
      <EmptyMessage
        cover
        title="No declaration found"
        icon={faClipboardUser}
        summary="No declaration of interest found"
      />
    )
  }

  const getYearName = (yearId: number) => {
    if (!yearId) {
      return;
    }
    const year = Constants.ACADEMIC_YEARS.find(year => year.id == yearId);
    return year?.name;
  };

  const getDeclarationTypeFriendlyName = (declarationType: DeclarationType) => {
    switch (declarationType) {
      case DeclarationType.Employment:
        return "Employment";
      case DeclarationType.AppointmentsAndDirectorships:
        return "Appointments and Directorships";
      case DeclarationType.NonprofitMembership:
        return "Nonprofit Membership";
      case DeclarationType.ShareholdingAndInvestment:
        return "Shareholding and Investment";
      case DeclarationType.TrustConnection:
        return "Trust Connection";
      case DeclarationType.Contractual:
        return "Contractual";
      case DeclarationType.Other:
        return "Other";
    }
  }

  const handleUpdate = () => {
    if (_declaration.latestVersion.status === DeclarationVersionStatus.Draft) {
      _setDeclarationVersion(_declaration.latestVersion)
      setEditModalOpen(true);
    }
    else {
      declarationsOfInterestActions.cloneDeclarationOfInterestVersion(
        {
          staffId: user.id,
          declarationOfInterestId: _declaration.id,
          versionId: _declaration.latestVersion.id,
        },
        (response) => {
          _setDeclarationVersion(response)
          setEditModalOpen(true);
        }
      )
    }
  }

  const handleDelete = () => {
    if (window.confirm(`This will delete this Declaration draft. This cannot be undone. Are you sure?`)) {
      if (
        _declaration.versions.some(x => x.status == DeclarationVersionStatus.Submitted) && 
        _declaration.latestVersion.status == DeclarationVersionStatus.Draft
      ) {
        declarationsOfInterestActions.deleteDeclarationOfInterestVersion(_declaration.staffMember.id, _declaration.id, _declaration.latestVersion.id);
      }
      else {
        declarationsOfInterestActions.deleteDeclarationOfInterest(_declaration.staffMember.id, _declaration.id);
      }
    }
  }

  const handleSubmit = () => {

    setSubmitErrors([]);
    var errors: string[] = [];

    if (_declarationVersion.acceptance === false) {
      errors.push("Cannot submit a declaration that has not been accepted");
    }
    setSubmitErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    declarationsOfInterestActions.submitDeclarationOfInterestVersion({
      id: _declaration.id,
      declarationOfInterestVersionId: _declarationVersion.id,
      staffId: user.id
    });
  }

  const handleModalCancel = () => {
    setEditModalOpen(false);
  };

  const handleModalSaved = (declarationVersion: DeclarationOfInterestVersionDetailView, saveAsDraft: boolean) => {
    declarationsOfInterestActions.updateDeclarationOfInterestVersion(
      user.id,
      _declaration.id,
      declarationVersion.id,
      {
        id: declarationVersion.id,
        staffId: user.id,
        declarationId: declarationVersion.declarationId,
        status: saveAsDraft ? DeclarationVersionStatus.Draft : DeclarationVersionStatus.Submitted,
        reference: declarationVersion.reference,
        notes: declarationVersion.notes,
        acceptance: declarationVersion.acceptance,
        files: declarationVersion.files,
        interests: declarationVersion.interests
      }
    )
    setEditModalOpen(false);
  };

  return (
    <>
    <Header>
      <Left>
        <Title
          size={TitleSize.H3}
          text={`Declaration of Interest ${getYearName(_declaration.academicYearId)}`}
        />
        <Chip
          text={_declaration.latestVersion.statusName}
          colorSwatch={
            _declaration.latestVersion.status === DeclarationVersionStatus.Draft
              ? Swatches.Low
              : Swatches.Success
          }
        />
      </Left>
      {_declaration.academicYearId === config.academicYear.current && (
        <Right>
          <SplitButton
            text="Update"
            onDefaultClick={handleUpdate}
            size={Size.Small}
            color={Swatches.Primary}
            working={saving || cloning || deleting || submitting}
          >
            <SplitButton.Option
              onClick={handleUpdate}
              text="Update"
              show
            />
            <SplitButton.Option
              onClick={handleSubmit}
              text="Submit"
              show={_declaration.latestVersion.status === DeclarationVersionStatus.Draft}
            />
            <SplitButton.Option
              onClick={handleDelete}
              text="Delete Draft"
              show={_declaration.latestVersion.status === DeclarationVersionStatus.Draft}
            />
          </SplitButton>
        </Right>
      )}
    </Header>

    <Card title="Dates">
      <Card.Body>
        <StructuredList>
          <StructuredList.Item name="Created">
            <Label>{moment(_declaration.createdDate).format("DD/MM/YYYY")}</Label>
          </StructuredList.Item>
          <StructuredList.Item name="Last Updated ">
            <Label>{moment(_declaration.latestVersion.updatedDate).format("DD/MM/YYYY")}</Label>
          </StructuredList.Item>
        </StructuredList>
      </Card.Body>
    </Card>

    <Card title="Details">
      <Card.Body>
        <StructuredList>
          <StructuredList.Item name="Reference">
            {_declaration.latestVersion.reference}
          </StructuredList.Item>
          <StructuredList.Item name="Notes">
            <HtmlText html={_declaration.latestVersion.notes} />
          </StructuredList.Item>
          <StructuredList.Item name="Accepted">
            {_declaration.latestVersion.acceptance ? "Yes" : "No"}
          </StructuredList.Item>
        </StructuredList>
      </Card.Body>
    </Card>

    <Card title="Interests">
      { _declaration.latestVersion.interests.length > 0 ? (
        <Card.Body noPad>
          <Table>
            <Table.Header>
              <Table.HeaderCell>Declaration Type</Table.HeaderCell>
              <Table.HeaderCell>Organisation Name</Table.HeaderCell>
              <Table.HeaderCell>Organisation Nature</Table.HeaderCell>
              <Table.HeaderCell>Nature of Interest</Table.HeaderCell>
              <Table.HeaderCell>Start Date</Table.HeaderCell>
              <Table.HeaderCell>End Date</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {_declaration.latestVersion.interests.map((interest, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{getDeclarationTypeFriendlyName(interest.declarationType)}</Table.Cell>
                  <Table.Cell>{interest.organisationName}</Table.Cell>
                  <Table.Cell>{interest.organisationNature}</Table.Cell>
                  <Table.Cell>{interest.natureOfInterest}</Table.Cell>
                  <Table.Cell>{interest.startDate && moment(interest.startDate).format("DD/MM/YYYY")}</Table.Cell>
                  <Table.Cell>{interest.endDate && moment(interest.endDate).format("DD/MM/YYYY")}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card.Body>
      )
      : (
        <Card.Body>
          <EmptyMessage
            icon="paste"
            title="No Interests Added"
            summary={"You have added no interests on this declaration"}
            cover
          />
        </Card.Body>
      )}
    </Card>

    <Card>
      <Card.Body>
        <StructuredList>
          <StructuredList.Item name="Attachments">
            <FileList>
              {_declaration.latestVersion.files?.map((file, index) => (
                <FileList.Item
                  key={index}
                  file={{ ...file, id: file.id }}
                  clickToDownload
                />
              ))}
            </FileList>
          </StructuredList.Item>
        </StructuredList>
      </Card.Body>
    </Card>

    <DeclarationEditorModal
      version={_declarationVersion}
      open={editModalOpen}
      onSave={handleModalSaved}
      onCancel={handleModalCancel}
    />

    </>
  )
}


export default UserDeclaration;