import React, { useEffect, useState } from "react";
import {
  Modal,
  ActionBar,
  Size,
  Swatches,
  Button,
  Right,
  Spacing,
  Card,
  Wizard,
  Message,
  StructuredList,
  ValidationMessage,
  TextInput,
  RichTextEditor,
  ToastService,
} from "ui-kit";
import styled from "styled-components";
import { arrays } from "utils";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { ProvisionRecordListView } from "areas/send/types/passportResponse.types";
import { InterventionRecordView } from "areas/planner/types/interventions/interventionResponses";
import { StudentProfileHeader } from "sharedComponents/common";
import StudentListPicker from "./studentListPicker";
import { StudentListView, StudentTinyView } from "types/users/userListViews.types";
import InterventionProgressDropdown from "./interventionProgressDropdown";
import interventionActions from "areas/planner/actions/interventions/interventionActions";
import { SaveInterventionRecordCommand } from "areas/planner/types/interventions/interventionRequests";


const Wrapper = styled.div`
  display: flex;

  .content-wrapper {
    flex-grow: 1;
  }
`;
const StudentDetailsWrapper = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${Spacing.Default}px;
`;


interface IInterventionRecordModalProps {
  record: InterventionRecordView;
  interventionGroup: any;
  availableNewStudents?: StudentListView[];
  open: boolean;
  onSave?: (record: InterventionRecordView) => void;
  onCancel?: () => void;
}

const InterventionRecordModal: React.FC<IInterventionRecordModalProps> = ({
  record,
  interventionGroup,
  open,
  availableNewStudents,
  onSave,
  onCancel,
}) => {

  const { saving, saveError } = useSelector((state: RootState) => state.intervention);
  const [_open, _setOpen] = useState<boolean>(open);
  const [_record, _setRecord] = useState<InterventionRecordView>(record);
  const [_interventionGroup, _setInterventionGroup] = useState<any>(interventionGroup);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    setValidationErrors([]);
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    _setRecord(record);
  }, [record]);

  useEffect(() => {
    _setInterventionGroup(interventionGroup);
  }, [interventionGroup]);

  const cleanUp = () => {
    _setRecord(null);
    //_setInterventionGroup(null);
  }

  const handleCancel = () => {
    cleanUp();
    onCancel?.();
  };

  const handleSave = () => {

    var errors: string[] = [];
    if (_record.rating == null) {
      errors.push(`Please select a progress rating.`);
    }
    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    var data: SaveInterventionRecordCommand = {
      id: _record.id,
      studentId: _record.student.id,
      groupId: _interventionGroup.id,
      target: _record.target,
      notes: _record.notes,
      rating: _record.rating
    };

    if (!_record.id) {
      interventionActions.createInterventionRecord(data, () => {
        ToastService.pop("Provision Record Created", null, "hand-holding-seedling");
         onSave?.(_record);
         cleanUp();
      });
    } else {
      interventionActions.saveInterventionRecord(data, () => {
        ToastService.pop("Provision Record Saved", null, "hand-holding-seedling");
        onSave?.(_record);
        cleanUp();
      });
    }
  };


  return (
    <Modal
      title={ record?.id
        ? record?.student.firstName + " " + record?.student.lastName
        : "New Intervention Record"
      }
      open={_open}
      onClose={handleCancel}
      width="80%"
      height="80%"
    >

      <Modal.Body>
        { _record?.student ? (
          <Wrapper>
            <StudentDetailsWrapper>
              <StudentProfileHeader student={_record?.student} />
            </StudentDetailsWrapper>

            <div className="content-wrapper">
              <ValidationMessage errors={validationErrors} />
              <Message text={saveError} color={Swatches.Danger} />
              <Card title="Target & Progress">
                <Card.Body>
                  <StructuredList>
                    <StructuredList.Item name="Target">
                      <TextInput
                        value={_record?.target}
                        onChange={value => _setRecord({..._record, target: value})}
                        fluid
                      />
                    </StructuredList.Item>
                    <StructuredList.Item name="Progress Rating" required>
                      <InterventionProgressDropdown
                        value={_record?.rating}
                        onChange={(value) => _setRecord({..._record, rating: value})}
                      />
                    </StructuredList.Item>
                    <StructuredList.Item name="Notes">
                      <RichTextEditor
                        noPad
                        initialValue={_record?.notes}
                        onChange={value => _setRecord({..._record, notes: value})}
                      />
                    </StructuredList.Item>
                  </StructuredList>
                </Card.Body>
              </Card>
            </div>
          </Wrapper>
        ) : (
          <StudentListPicker
            students={availableNewStudents}
            listTitle={`Group Students`}
            onSelect={(student: StudentListView) => _setRecord({..._record, student: student})}
          />
        )}
      </Modal.Body>

      {_record?.student && (
        <Modal.Footer>
          <ActionBar low>
            <Right>
              <Button
                onClick={handleCancel}
                size={Size.Small}
                color={Swatches.Low}
                text="Cancel"
                working={saving}
              />
              <Button
                onClick={handleSave}
                size={Size.Small}
                color={Swatches.Success}
                text="Save"
                working={saving}
              />
            </Right>
          </ActionBar>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default InterventionRecordModal;
