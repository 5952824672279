import React, { useEffect, useRef, useState } from "react";
import YearGroupDetentionReportForm from "./yearGroupDetentionReportForm";
import YearGroupDetentionReportTable from "./yearGroupDetentionReportTable";
import ReportDescription from "../reportDescription";
import { Title, TitleSize } from "ui-kit";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import detentionReportActions from "areas/analytics/actions/detentionReportActions";
import { Constants } from "configuration";
import { useAppSelector } from "reducers/hooks";
import { DetentionReportFilter } from "areas/analytics/types/detentionAnalyticsRequest.types";
import { SearchResultViewBase } from "types/common/views.types";
import moment from "moment";


export interface DetentionReportFilterExt extends Omit<DetentionReportFilter, "yearGroupId" | "startDate" | "endDate"> {
  yearGroup: SearchResultViewBase;
  startDate?: Date;
  endDate?: Date;
}


const YearGroupDetentionReportWrapper = () => {

  const reportRef = useRef<HTMLDivElement>();

  const { user } = useAppSelector(state => state.currentUser);
  const { allBehaviourCodes, loading: loadingBehaviourCodes } = useAppSelector(
    state => state.allBehaviourCodes
  );
  const { report, loading, loadingExportReport } = useAppSelector(
    state => state.yearGroupDetentionReport
  );

  const [schoolId, setSchoolId] = useState<number>(null);
  const [reportCriteria, setReportCriteria] = useState<DetentionReportFilterExt>({
    schoolId: null,
    startDate: null,
    endDate: null,
    yearGroup: null,
    statusId: null,
    codeId: null,
    academicYearId: Constants.ACADEMIC_YEAR_FILTER_ARRAY[0].value,
  });

  useEffect(() => {
    if (user?.baseSchool) {
      setSchoolId(user.baseSchool.id);
    }
  }, []);

  useEffect(() => {
    if (schoolId !== null) {
      behaviourActions.getAllBehaviourCodes(schoolId);
    }

    setReportCriteria({
      ...reportCriteria,
      codeId: null,
      yearGroup: null,
    });
  }, [schoolId]);

  useEffect(() => {
    if (loading || report) {
      reportRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading, report]);

  const handleReportCriteriaChange = (criteria: DetentionReportFilterExt) => {
    setReportCriteria(criteria);
  };

  const handleGenerateReport = (exportReport: boolean) => {
    detentionReportActions.getYearGroupDetentionReport(
      {
        schoolId: schoolId,
        startDate: moment(reportCriteria.startDate).format("YYYY-MM-DD"),
        endDate: moment(reportCriteria.endDate).format("YYYY-MM-DD"),
        yearGroupId: parseInt(reportCriteria.yearGroup?.id),
        statusId: reportCriteria.statusId,
        codeId: reportCriteria.codeId,
        academicYearId: reportCriteria.academicYearId
      },
      exportReport
    );
  };

  return (
    <>
      <Title text="Year Group Detentions Report" size={TitleSize.H3} />
      <ReportDescription text="Use this report to view number of detentions for individual students in a year group." />
      <YearGroupDetentionReportForm
        schoolId={schoolId}
        onSelectSchool={value => setSchoolId(value)}
        behaviourCodes={allBehaviourCodes}
        loadingBehaviourCodes={loadingBehaviourCodes}
        reportCriteria={reportCriteria}
        onChangeReportCriteria={handleReportCriteriaChange}
        handleGenerateReport={handleGenerateReport}
        loadingExportReport={loadingExportReport}
      />
      <div ref={reportRef}>
        <YearGroupDetentionReportTable />
      </div>
    </>
  );
};

export default YearGroupDetentionReportWrapper;
