import store from "reducers/store";
import { IBeehiveAction } from "types/common/action.types";
import { StaffType } from "types/users/userStaff.types";

const types = {
  FILTERBYSCHOOL: "filterbyschool",
  FILTERBYSTAFFTYPE: "filterbystafftype",
};

export const filterBySchool = (schoolId: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.FILTERBYSCHOOL,
    payload: schoolId,
  });
};

export const filterByStaffType = (staffType: StaffType) => {
  store.dispatch<IBeehiveAction<StaffType>>({
    type: types.FILTERBYSTAFFTYPE,
    payload: staffType,
  });
};


const staffActions = {
  types,
  filterBySchool,
  filterByStaffType,
};

export default staffActions;
