import React from "react";
import {
  TextInput,
  TextInputType,
  DatePicker,
  RichTextEditor,
  TimePicker,
  Dropdown,
  Checkbox,
} from "ui-kit";
import moment from "moment";
import { FieldListView } from "../types/formResponse.types";

interface IDynamicFieldProps {
  field: FieldListView;
  onChange?: (value: number | string | Date) => void;
  disabled?: boolean;
  value?: string;
}

const DynamicField: React.FC<IDynamicFieldProps> = ({
  field,
  onChange,
  disabled,
  value,
}) => {
  const handleChange = (value: number | string | Date) => {
    onChange?.(value);
  };

  switch (field.fieldType) {
    case 0: // String
      return (
        <TextInput
          type={TextInputType.String}
          name={field.alias}
          value={value}
          fluid
          placeholder={field.name}
          maxLength={field.maxValue && Number(field.maxValue)}
          required={field.isRequired}
          disabled={disabled}
          suffix={field.suffix}
          onChange={handleChange}
        />
      );
    case 1: // Integer
      return (
        <TextInput
          type={TextInputType.Number}
          name={field.alias}
          value={value}
          min={field.minValue ? parseInt(field.minValue) : 0}
          required={field.isRequired}
          max={field.maxValue ? parseInt(field.maxValue) : null}
          fluid
          placeholder={field.placeholder}
          disabled={disabled}
          suffix={field.suffix}
          onChange={handleChange}
        />
      );
    case 2: // Currency
      return (
        <TextInput
          type={TextInputType.Currency}
          name={field.alias}
          min={field.minValue ? parseInt(field.minValue) : 0}
          required={field.isRequired}
          max={field.maxValue ? parseInt(field.maxValue) : null}
          fluid
          value={value}
          placeholder={field.placeholder}
          disabled={disabled}
          onChange={handleChange}
        />
      );
    case 3:
      return (
        <DatePicker
          className={field.alias}
          value={new Date(value)}
          disabled={disabled}
          closeOnSelect={true}
          onChange={handleChange}
        />
      );
    case 4: // Textarea
      return (
        <RichTextEditor
          value={value}
          className={field.alias}
          initialValue={field.placeholder}
          onChange={handleChange}
        />
      );
    case 5: // Decimal
      return (
        <TextInput
          type={TextInputType.Number}
          min={field.minValue ? parseInt(field.minValue) : 0}
          required={field.isRequired}
          max={field.maxValue ? parseInt(field.maxValue) : null}
          fluid
          value={value}
          placeholder={field.placeholder}
          disabled={disabled}
          suffix={field.suffix}
          onChange={handleChange}
        />
      );

    case 6: // Time
      return <TimePicker initialValue={value} onChange={handleChange} />;

    case 7: // Hours
      return (
        <TextInput
          type={TextInputType.Number}
          min={field.minValue ? parseInt(field.minValue) : 0}
          required={field.isRequired}
          max={field.maxValue ? parseInt(field.maxValue) : null}
          fluid
          value={value}
          placeholder={field.placeholder}
          disabled={disabled}
          suffix={field.suffix}
          onChange={handleChange}
        />
      );

    case 8: // Minutes
      return (
        <TextInput
          type={TextInputType.Number}
          min={field.minValue ? parseInt(field.minValue) : 0}
          required={field.isRequired}
          max={field.maxValue ? parseInt(field.maxValue) : null}
          fluid
          value={value}
          placeholder={field.placeholder}
          disabled={disabled}
          suffix={field.suffix}
          onChange={handleChange}
        />
      );

    case 9: // Month
      var months = [];

      for (var i = 1; i <= 12; i++) {
        months.push({ value: i, label: moment(i, "MM").format("MMMM") });
      }

      return (
        <>
          <Dropdown
            placeholder={field.placeholder}
            onChange={handleChange}
            fluid
            value={value}
            items={months}
          />
        </>
      );

    case 12: // Checkbox
      return (
        <Checkbox
          value={value}
          disabled={disabled}
          onChange={(val) => handleChange(val.checked.toString())}
          text={field.name}
        />
      );

    case 13: // Options
      return (
        <Dropdown
          fluid
          value={value?.toString()}
          disabled={disabled}
          onChange={(val) => handleChange(val.toString())}
        >
          {field.options?.map((f, index) => (
            <Dropdown.Item label={f.displayName} value={f.id?.toString()} />
          ))}
        </Dropdown>
      );
    default:
      return null;
  }
};

export default DynamicField;
