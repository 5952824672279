import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TabBar from "./tabBar";

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
`;

const PanesWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const TabPaneWrapper = styled.div<{ selected: boolean }>`
  flex-grow: 1;
  flex-direction: column;

  ${({ selected }) =>
    selected
      ? `
      position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    `
      : `display: none;`}
`;

export interface ITabsProps {
  children: React.ReactElement<ITabPaneProps> | React.ReactElement<ITabPaneProps>[];
  className?: string;
  selectedIndex?: number;
  initialTabIndex?: number;
  onTabSelected?: (name: string, index: number, data?: any) => void;
}

export interface ITabPaneProps {
  label: string;
  name?: string;
  children: React.ReactNode;
  selected?: boolean;
  tooltip?: string;
  tooltipSub?: string;
  hide?: boolean;
  data?: any;
  badge?: number;
}

const Tabs: React.FC<ITabsProps> & {
  Pane: typeof TabPane;
} = ({
  children,
  className,
  onTabSelected,
  selectedIndex: initialSelectedIndex,
  initialTabIndex,
}) => {

  const [selectedIndex, setSelectedIndex] = useState<number>(initialTabIndex ?? 0);
  const [_children, _setChildren] = useState<React.ReactElement<ITabPaneProps>[]>([]);
  

  useEffect(() => {
    Array.isArray(children)
      ? _setChildren(children)
      : _setChildren([ children ])
  }, [children])

  useEffect(() => {
    initialSelectedIndex && setSelectedIndex(initialSelectedIndex);
  }, [initialSelectedIndex]);

  const handleTabItemClick = (name: string, index: number, data: any) => {
    if (index !== selectedIndex) {
      onTabSelected?.(name, index, data);
      setSelectedIndex(index);
    }
  };

  return (
    <Wrapper className={`tabs ${className ? className : ""}`}>
      {_children.filter(x => !x.props.hide).length > 1 && (
        <TabBar
          onItemClicked={handleTabItemClick}
          selectedIndex={selectedIndex}
        >
          {_children
            .filter(x => !x.props.hide)
            .map((child, index) => (
              <TabBar.Item
                text={child.props?.label}
                name={index.toString()}
                key={index}
                data={child.props?.data}
                tooltip={child.props?.tooltip}
                tooltipSub={child.props?.tooltipSub}
                badge={child.props?.badge}
              />
            ))}
        </TabBar>
      )}
      <PanesWrapper className="tabs-panes">
        {_children
          .filter(x => !x.props.hide)
          .map((child, index) => {
            return React.cloneElement(child, {
              selected: index === selectedIndex,
              key: index,
            });
          })}
      </PanesWrapper>
    </Wrapper>
  );
};

const TabPane: React.FC<ITabPaneProps> = ({ children, selected }) => {
  return (
    <TabPaneWrapper className={`tabs-pane`} selected={selected}>
      {children}
    </TabPaneWrapper>
  );
};

Tabs.Pane = TabPane;

export default Tabs;
