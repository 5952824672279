import interventionActions from "areas/planner/actions/interventions/interventionActions";
import { InterventionRecordView } from "areas/planner/types/interventions/interventionResponses";
import { IBeehiveAction } from "types/common/action.types";

interface IGroupInterventionsState {
  interventions: InterventionRecordView[];
  loading: boolean;
  saving: boolean;
  error: string | null;
}

const INITIAL_STATE: IGroupInterventionsState = {
  interventions: [],
  loading: false,
  saving: false,
  error: null,
};


const groupInterventionsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IGroupInterventionsState => {

  const {
    GROUPINTERVENTIONS_GET,
    GROUPINTERVENTIONS_CREATE,
    GROUPINTERVENTIONS_SAVE,
    GROUPINTERVENTIONS_DELETE
  } = interventionActions.types;

  switch (action.type) {

    case GROUPINTERVENTIONS_GET.START:
      return { 
        ...state, 
        loading: true
      };
    case GROUPINTERVENTIONS_GET.SUCCESS:
      return { 
        ...state, 
        loading: false, 
        interventions: action.payload 
      };
    case GROUPINTERVENTIONS_GET.FAILED:
      return { 
        ...state, 
        loading: false, 
        error: action.payload 
      };

    case GROUPINTERVENTIONS_CREATE.SUCCESS:
      return {
        ...state,
        interventions: [...state.interventions, action.payload],
      };

    case GROUPINTERVENTIONS_SAVE.SUCCESS:
      return {
        ...state,
        interventions: state.interventions.map(intervention =>
          intervention.id === action.payload.id 
            ? action.payload 
            : intervention
        ),
      };

    case GROUPINTERVENTIONS_DELETE.SUCCESS:
      return {
        ...state,
        interventions: state.interventions.filter(intervention => intervention.id !== action.payload),
      };
    default:
      return state;
  }
};

export default groupInterventionsReducer;
