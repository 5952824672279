import React from "react";
import styled from "styled-components";
import { BorderRadius } from "ui-kit";
import PrimaryRocket from "sharedComponents/common/primary/rockets/primaryRocket";
import { PrimaryAvatar } from "types/users/userStudent.types";


const Wrapper = styled.div`
  border-radius: ${BorderRadius.Default}px;
  background: url("/images/primary/streak.svg") no-repeat;
  justify-content: flex-end;
  display: flex;
  min-width: 100px;
  min-height: 66px;
  transition: all ease 550ms;
  background-size: auto 80px;

  svg {
    position: absolute;
    right: -22px;
    top: -3px;
    width: 90px;
  }
`;


interface IPrimaryLeaderboardPointsBarProps {
  highestPoints: number;
  currentPointsValue: number;
  primaryAvatarId: PrimaryAvatar;
}


const PrimaryLeaderboardPointsBar: React.FC<IPrimaryLeaderboardPointsBarProps> = ({
  highestPoints,
  currentPointsValue,
  primaryAvatarId,
}) => {

  return (
    <Wrapper
      style={{
        width: `${100 + currentPointsValue * 30}px`,
        maxWidth: "100%",
      }}
    >
      <PrimaryRocket code={primaryAvatarId} />
    </Wrapper>
  );
};

export default PrimaryLeaderboardPointsBar;
