import React, { useEffect, useCallback } from "react";
import DetentionRegisterMenu from "./detentionRegisterMenu";
import lodashDebounce from "lodash.debounce";
import moment from "moment";
import schoolsActions from "areas/planner/actions/schools/schoolsActions";
import schoolActions from "areas/planner/actions/school/schoolActions";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import detentionTypesActions from "areas/behaviour/actions/detentions/detentionTypesActions";
import detentionRegisterActions from "areas/behaviour/actions/detentions/detentionRegisterActions";
import { DETENTION_GROUPBY_KEYS } from "areas/behaviour/constants/tableConstants";
import { useAppSelector } from "reducers/hooks";


const DetentionRegisterMenuWrapper: React.FC = () => {

  const {
    detentions,
    detentionDate,
    detentionFilters,
    groupBy,
    loading: loadingDetentions,
    searchTerm,
  } = useAppSelector(state => state.detentionRegister);
  const { schoolId } = useAppSelector(state => state.schools);
  const { schoolInformation } = useAppSelector(state => state.school);
  const { user } = useAppSelector(state => state.currentUser);

  useEffect(() => {
    if (user) {
      schoolsActions.selectSchool(user.baseSchool.id);
    }
  }, []);

  useEffect(() => {
    if (schoolId && (!schoolInformation || schoolInformation.id !== schoolId)) {
      schoolActions.getSchoolInformation(schoolId);
      detentionActions.getDetentionRooms(schoolId);
      detentionTypesActions.getDetentionTypes(schoolId);
    }
  }, [schoolId]);

  const onSelectSchool = (schoolId: number) => {
    schoolsActions.selectSchool(schoolId);
  };

  const onSelectDetentionStatusFilter = (filters: number[]) => {
    detentionRegisterActions.setDetentionStatusFilter(filters);
  };

  const setSelectedDate = (date: Date) => {
    detentionRegisterActions.setDetentionRegisterDate(date);
  };

  const onSelectGroupBy = (keys: number[]) => {
    let tempDetentionRegisterGroupBy = groupBy.slice();
    tempDetentionRegisterGroupBy = [];
    keys.forEach(element => {
      tempDetentionRegisterGroupBy.push(element);
    });
    detentionRegisterActions.setDetentionRegisterGroupBy(
      tempDetentionRegisterGroupBy
    );
  };

  const setYearGroup = (yearGroup: number) => {
    detentionRegisterActions.setRegisterYearGroupFilter(yearGroup);
  };

  const onChangeBehaviourCode = (behaviourCode: number) => {
    if (
      behaviourCode !== null &&
      detentionFilters.behaviourCodeFilter !== behaviourCode
    ) {
      detentionRegisterActions.setBehaviourCodeFilter(behaviourCode);
    } else {
      detentionRegisterActions.setBehaviourCodeFilter(null);
    }
  };

  const onChangeBehaviourCategory = (behaviourCategory: number) => {
    if (
      behaviourCategory !== null &&
      detentionFilters.behaviourCategoryFilter !== behaviourCategory
    ) {
      detentionRegisterActions.setBehaviourCategoryFilter(behaviourCategory);
    } else {
      detentionRegisterActions.setBehaviourCategoryFilter(null);
    }
  };

  const debouncedSearchChange = useCallback(
    lodashDebounce(value => {
      detentionRegisterActions.setSearchTerm(value);
    }, 500),
    []
  );

  const handleSearchTermChange = (searchTerm: string) => {
    if (searchTerm.length >= 2 || searchTerm.length === 0) {
      debouncedSearchChange(searchTerm);
    }
  };

  const handleGetDetentions = () => {
    const viewUnmarkedDetentions = detentionFilters.viewUnmarkedDetentions;
    if (schoolId) {
      detentionRegisterActions.getDetentions(
        schoolId,
        {
          year: viewUnmarkedDetentions ? null : +moment(detentionDate).format("YYYY"),
          month: viewUnmarkedDetentions ? null : +moment(detentionDate).format("M"),
          day: viewUnmarkedDetentions ? null : +moment(detentionDate).format("D"),
          statusIds: detentionFilters.detentionStatusFilters,
          yearGroupId: detentionFilters.yearGroupFilter,
          groupByDetentionType: groupBy.includes(DETENTION_GROUPBY_KEYS.DETENTION_TYPE),
          groupByRoom: groupBy.includes(DETENTION_GROUPBY_KEYS.ROOM),
          groupByStartDate: groupBy.includes(DETENTION_GROUPBY_KEYS.START_TIME),
          groupByEndDate: groupBy.includes(DETENTION_GROUPBY_KEYS.END_TIME),
          unMarkedOldDetentions: viewUnmarkedDetentions,
          behaviourCodeId: detentionFilters.behaviourCodeFilter,
          schoolBehaviourCategoryId: detentionFilters.behaviourCategoryFilter,
          searchTerm: searchTerm
        }
      );
    }
  };

  return (
    <DetentionRegisterMenu
      date={detentionDate}
      setDate={setSelectedDate}
      onSelectDetentionStatusFilter={onSelectDetentionStatusFilter}
      yearGroups={schoolInformation && schoolInformation.yearGroups}
      onSelectYearGroup={setYearGroup}
      onSelectGroupBy={onSelectGroupBy}
      groupByProperties={groupBy}
      detentionFilters={detentionFilters}
      behaviourCodeFilter={detentionFilters.behaviourCodeFilter}
      onChangeBehaviourCode={onChangeBehaviourCode}
      onChangeBehaviourCategory={onChangeBehaviourCategory}
      handleSearchTermChange={handleSearchTermChange}
      loadingDetentions={loadingDetentions}
      onSelectSchool={onSelectSchool}
      schoolId={schoolId}
      handleGetDetentions={handleGetDetentions}
      areDetentionsNull={detentions === null}
    />
  );
};

export default DetentionRegisterMenuWrapper;
