import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { OnCallStatus } from "areas/analytics/reducers/onCallAnalyticsShared.types";


export const ON_CALL_FILTERS: IDropdownDataItemProps<string, OnCallStatus, null>[] = [
  {
    label: "Open",
    value: OnCallStatus.Open,
  },
  {
    label: "Claimed",
    value: OnCallStatus.Claimed,
  },
  {
    label: "Resolved",
    value: OnCallStatus.Resolved,
  },
  {
    label: "Cancelled",
    value: OnCallStatus.Cancelled,
  },
];
