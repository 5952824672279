import React, { useState, useEffect } from "react";
import RoomLayoutOptionsForm from "./roomLayoutOptionsForm";
import RoomLayoutGridWrapper from "./roomLayoutGridWrapper";
import styled from "styled-components";
import { Spacing, Wizard, StructuredList, Card } from "ui-kit";
import {
  GRID_PROP,
  changeNumberOfCols,
  changeNumberOfRows,
} from "areas/planner/utils/layouts";
import classRoomActions from "areas/planner/actions/classViewer/classRoomActions";
import ConfirmModal from "sharedComponents/common/confirmModal";

const Wrapper = styled.div`
    .wizard {
      margin-top: ${Spacing.Medium}px;
    }
  }
`;

const RoomLayoutWizard = ({
  group,
  selectedRoom,
  selectedRoomId,
  closeLayoutCreator,
  numberOfRoomLayouts,
  onCreateRoomLayout,
}) => {
  const [rows, setRows] = useState(10);
  const [cols, setCols] = useState(10);
  const [roomSlots, setRoomSlots] = useState([]);
  const [selectedRoomTemplate, setSelectedRoomTemplate] = useState(null);
  const [roomLayoutName, setRoomLayoutName] = useState(
    `Room Layout ${numberOfRoomLayouts + 1}`
  );
  const [openConfirm, setOpenConfirm] = useState(false);

  useEffect(() => {
    if (selectedRoomTemplate) {
      setRows(selectedRoomTemplate.rows);
      setCols(selectedRoomTemplate.cols);
      setRoomSlots(JSON.parse(JSON.stringify(selectedRoomTemplate.slots)));
    }
  }, [selectedRoomTemplate]);

  const changeRoomRowsCols = (gridProperty, change) => {
    let tempRoomSlots;

    if (gridProperty === GRID_PROP.COLS) {
      tempRoomSlots = changeNumberOfCols(
        change,
        cols,
        roomSlots,
        setCols,
        rows
      );
    } else if (gridProperty === GRID_PROP.ROWS) {
      tempRoomSlots = changeNumberOfRows(
        change,
        cols,
        roomSlots,
        setRows,
        rows
      );
    }
    setRoomSlots(tempRoomSlots);
  };

  const handleCreateRoomSlots = () => {
    let newRoomSlots = [];
    roomSlots.forEach(slot => {
      if (slot.isASeat) {
        newRoomSlots.push({
          id: null,
          coordinates: {
            x: slot.x,
            y: slot.y,
          },
          isAllowed: true,
        });
      }
    });

    const callBack = () => {
      closeLayoutCreator();
    };

    const payload = {
      schoolId: group.school.id,
      roomId: selectedRoomId,
      name: roomLayoutName,
      maxOccupancy: null,
      width: cols,
      length: rows,
      wallOptionId: 0,
      saveSlots: newRoomSlots,
    };
    classRoomActions
      .createRoomLayout(group.school.id, payload)
      .then(roomLayoutId => {
        onCreateRoomLayout?.(roomLayoutId);
      });
  };

  const validateTemplate = () => {
    return new Promise((resolve, reject) => {
      let valid = selectedRoom !== null && selectedRoomTemplate !== null;

      if (valid) {
        resolve(true);
      } else {
        reject(
          "Please choose a room and a room template. If you want to create a custom layout select the custom template option."
        );
      }
    });
  };

  const validateRoom = () => {
    return new Promise((resolve, reject) => {
      let seats = countSeats();
      let valid = true;
      let message = null;
      if (selectedRoom.roomId === null) {
        valid = false;
        message = "Please select a room";
      } else if (group.students && seats < group.students.length) {
        valid = false;
        message =
          "Number of seats are less than the number of students in the class.";
      }

      if (valid) {
        resolve(true);
      } else {
        reject(message);
      }
    });
  };

  const countSeats = () => {
    let seats = 0;
    roomSlots.forEach(slot => {
      if (slot.isASeat) {
        seats++;
      }
    });
    return seats;
  };

  return (
    <Wrapper>
      <Wizard
        showHeaderSteps={false}
        onCancel={group.layouts?.length > 0 ? closeLayoutCreator : null}
        onFinish={() => setOpenConfirm(true)}
      >
        <Wizard.Step onValidate={validateTemplate}>
          <RoomLayoutOptionsForm
            selectedRoomTemplate={selectedRoomTemplate}
            setSelectedRoomTemplate={template =>
              setSelectedRoomTemplate(template)
            }
            roomLayoutName={roomLayoutName}
            setRoomLayoutName={name => setRoomLayoutName(name)}
          />
        </Wizard.Step>
        <Wizard.Step onValidate={validateRoom}>
          <Card>
            <Card.Body>
              <StructuredList.Item
                name="Customise Room Layout"
                description="You can now customise your layout by adding extra rows, 
                columns or adding seats by clicking on the empty squares and removing 
                seats by clicking on the dark grey squares on the layout grid. 
                Once you have finished editing, you can save the room layout."
              >
                <RoomLayoutGridWrapper
                  rows={rows}
                  cols={cols}
                  changeRoomRowsCols={changeRoomRowsCols}
                  roomSlots={roomSlots}
                  setRoomSlots={setRoomSlots}
                />
              </StructuredList.Item>
            </Card.Body>
          </Card>
        </Wizard.Step>
      </Wizard>

      <ConfirmModal
        openModal={openConfirm}
        confirmMsg="A room layout cannot be amended or deleted after it has been created. Are you sure you want to save this room layout?"
        onCancel={() => setOpenConfirm(false)}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          handleCreateRoomSlots();
          setOpenConfirm(false);
        }}
      />
    </Wrapper>
  );
};

export default RoomLayoutWizard;
