import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  Modal,
  Button,
  Right,
  ActionBar,
  Swatches,
  Size,
  EmptyMessage,
  Message,
  ToastService,
  PickList,
} from "ui-kit";
import userManagementActions from "areas/administration/actions/userManagementActions";
import rolesActions from "areas/administration/actions/rolesActions";
import { RootState } from "reducers/store";


const Wrapper = styled.div`
  display: flex;
  height: 100%;

  .picklist {
    flex-grow: 1;
  }
`;


interface IStaffRolesModalProps {
  open: boolean;
  onCancel?: () => void;
  onSaved?: (roles: number[]) => void;
}

interface roleOption {
  label: string;
  sub?: string;
  value: {
    id: number;
  };
}


const StaffRolesModal: React.FC<IStaffRolesModalProps> = ({ open, onCancel, onSaved }) => {

  const { actions, user } = useSelector((state: RootState) => state.managedUser);
  const {
    roles,
    loading: loadingRoles,
    error: rolesError,
  } = useSelector((state: RootState) => state.roles);

  const [_open, _setOpen] = useState<boolean>(false);
  const [_roleOptions, _setRoleOptions] = useState<roleOption[]>([]);
  const [_selectedRoles, _setSelectedRoles] = useState<roleOption[]>([]);

  useEffect(() => {
    rolesActions.getRoles(data => {
      _setRoleOptions(
        data.map(role => ({
          label: role.name,
          sub: role.description,
          value: { id: role.id },
        }))
      );
    });
    _setSelectedRoles(
      user.roles.map(role => ({
        label: role.name,
        sub: role.description,
        value: { id: role.id },
      }))
    );
  }, []);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const handleSave = () => {
    var _roles = _selectedRoles.map(role => role.value.id);
    const data = {
      userId: user.id,
      roles: _roles,
    };

    userManagementActions.setStaffRoles(user.id, data, () => {
      onSaved?.(_roles);
      ToastService.pop("Roles Updated", null, "user");
    });
  };

  const handlePicked = (value: roleOption[]) => {
    _setSelectedRoles(value);
  };

  return (
    <Modal
      width="80%"
      height="60%"
      title="Roles"
      open={_open}
      onClose={onCancel}
    >
      <Modal.Body>
        <Message text={actions.updateRoles?.error} color={Swatches.Danger} />
        {user ? (
          <Wrapper>
            <PickList
              options={_roleOptions}
              optionsTitle="Available Roles"
              optionsEmptyContent={
                <EmptyMessage
                  title="No roles"
                  summary="No roles are available"
                  cover
                />
              }
              selected={_selectedRoles}
              selectedTitle="Assigned Roles"
              selectedEmptyContent={
                <EmptyMessage
                  title="No roles"
                  summary="No roles have been assigned"
                  cover
                />
              }
              onChange={handlePicked}
              loadingOptions={loadingRoles}
              sortExpression={(a, b) => a.label > b.label}
            />
          </Wrapper>
        ) : (
          <EmptyMessage
            icon="user"
            title="No Staff Member"
            summary="No staff member was selected"
            cover
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              onClick={onCancel}
              size={Size.Small}
              color={Swatches.Low}
              text="Cancel"
            />
            <Button
              onClick={handleSave}
              size={Size.Small}
              color={Swatches.Success}
              text="Save"
              working={actions.updateRoles?.working}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default StaffRolesModal;
