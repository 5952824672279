import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAction } from "hooks";
import {
  Size,
  Swatches,
  Currency,
  Table,
  DetailLabel,
  ToastService,
  Icon,
  SplitButton,
} from "ui-kit";
import { UserName, RoleView, SchoolIcon } from "sharedComponents/common";
import { Constants } from "configuration";
import OrderLineStatus from "./orderLineStatus";
import { RootState } from "reducers/store";
import paymentsActions from "areas/payments/actions/paymentsActions";
import { OrderDetailView, OrderLineView, ProductListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { UserTinyView } from "types/users/userListViews.types";


interface IOrderLinesTableProps {
  order: OrderDetailView;
  onPayClicked?: (order: OrderLineView) => void;
}


const OrderLinesTable: React.FC<IOrderLinesTableProps> = ({ order, onPayClicked }) => {

  const { collecting, collectError } = useSelector((state: RootState) => state.userPurchasedItems);
  const navigate = useNavigate();

  const handleUserClicked = (user: UserTinyView) => {
    navigate(`/main/shopkeeper/users/${user.id}`);
  };

  const handleProductClicked = (product: ProductListView) => {
    navigate(`/main/shopkeeper/category/${product.category.id}/products/${product.id}`);
  };

  const handleCollectClicked = (line: OrderLineView) => {
    if (window.confirm(`This will mark this order item as having been collected. Are you sure?`)) {
      paymentsActions.markOrderLineCollected(order.user.id, order.id, line.id, () => {
        ToastService.pop("Item marked as collected", null, "shopping-bag");
      });
    }
  };

  const handlePayClicked = (line: OrderLineView) => {
    onPayClicked?.(line);
  };

  return (
    <>
      <Table zebra>
        <Table.Header>
          <Table.HeaderCell width={0.5}></Table.HeaderCell>
          <Table.HeaderCell width={0.25}></Table.HeaderCell>
          <Table.HeaderCell width={1.5}>Description</Table.HeaderCell>
          <Table.HeaderCell width={1}>Ordered For</Table.HeaderCell>
          <Table.HeaderCell width={0.5} right>
            Qty
          </Table.HeaderCell>
          <Table.HeaderCell width={0.5} right>
            Total
          </Table.HeaderCell>
          <Table.HeaderCell width={0.5} right>
            Paid
          </Table.HeaderCell>
          <Table.HeaderCell width={0.5} right>
            O/S
          </Table.HeaderCell>
          <Table.HeaderCell width={0.5} right>
            Refunded
          </Table.HeaderCell>
          <Table.HeaderCell width={0.5} center>
            Collected
          </Table.HeaderCell>
          <Table.HeaderCell right width={1}></Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {order?.lines?.map((line: OrderLineView, index: number) => (
            <Table.Row key={index}>
              <Table.Cell width={0.5}>
                <OrderLineStatus status={line.status} />
              </Table.Cell>
              <Table.Cell center width={0.25}>
                <SchoolIcon
                  code={line?.product?.category?.school.code}
                  size={Size.Small}
                />
              </Table.Cell>
              <Table.Cell width={1.5}>
                <RoleView inverse roles={[Constants.ROLES.FINANCE_USER]}>
                  <DetailLabel
                    label={line.description}
                    sub={line.product?.category?.name}
                    bold
                  />
                </RoleView>
                <RoleView roles={[Constants.ROLES.FINANCE_USER]}>
                  <DetailLabel
                    label={line.description}
                    sub={line.product?.category?.name}
                    onClick={
                      line.type === "Product"
                        ? () => handleProductClicked(line.product)
                        : null
                    }
                    bold
                  />
                </RoleView>
              </Table.Cell>
              <Table.Cell width={1}>
                <RoleView roles={[Constants.ROLES.FINANCE_USER]}>
                  <UserName
                    user={line.purchasedFor}
                    onClick={() => handleUserClicked(line.purchasedFor)}
                  />
                </RoleView>
                <RoleView roles={[Constants.ROLES.FINANCE_USER]} inverse>
                  <UserName user={line.purchasedFor} />
                </RoleView>
              </Table.Cell>
              <Table.Cell right width={0.5}>
                <p>{line.quantity}</p>
              </Table.Cell>
              <Table.Cell right>
                <Currency value={line.total} />
              </Table.Cell>
              <Table.Cell right width={0.5}>
                <Currency value={line.paid} />
              </Table.Cell>
              <Table.Cell right width={0.5}>
                <Currency value={line.outstanding} />
              </Table.Cell>
              <Table.Cell right width={0.5}>
                <Currency value={line.refunded} />
              </Table.Cell>
              <Table.Cell center width={0.5}>
                {line.product?.requiresCollection
                  ? line.collectedDate != null && (
                      <Icon
                        value="check-circle"
                        color={Swatches.Success.swatch}
                      />
                    )
                  : "N/A"}
              </Table.Cell>
              <Table.Cell right width={1}>
                <RoleView roles={[Constants.ROLES.FINANCE_USER]}>
                  {line.status === 0 && (
                    <SplitButton
                      size={Size.Small}
                      text="Actions"
                      color={Swatches.Default}
                      working={collecting}
                    >
                      <SplitButton.Option
                        onClick={() => handlePayClicked(line)}
                        text="Pay"
                        show={line.outstanding > 0}
                      />
                      <SplitButton.Option
                        onClick={() => handleCollectClicked(line)}
                        text="Mark Collected"
                        show={line.isCollectable}
                      />
                    </SplitButton>
                  )}
                </RoleView>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default OrderLinesTable;
