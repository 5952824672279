import { CalendarEvent } from "areas/calendar/reducers/calendarEventReducer";
import { CalendarEventDateListView } from "areas/calendar/types/calendarResponses.types";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Card, Chip, Spacing, Sub, Swatches, Table } from "ui-kit";
import DateSignupButton from "./dateSignupButton";
import FormatDate from "./formatDate";
import RemainingPlaces from "./remainingPlaces";
import { SignupDetails } from ".";
import { arrays } from "utils";


const Wrapper = styled.div`
  .signup-all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button {
      margin-bottom: ${Spacing.Large}px;
    }
    > span {
    }
  }

  .cancel-all {
    margin-bottom: ${Spacing.Large}px;
  }
`;


interface IEventDatesProps {
  calendarEvent: CalendarEvent;
  signup: SignupDetails;
  setSignup: React.Dispatch<React.SetStateAction<SignupDetails>>;
  readonly?: boolean;
}


const EventDates: React.FC<IEventDatesProps> = ({ calendarEvent, signup, setSignup, readonly }) => {

  const handleDateSelect = (date: CalendarEventDateListView) => {
    setSignup({ ...signup, dates: [...signup.dates, date.id] });
  };

  const handleDateDeselect = (date: CalendarEventDateListView) => {
    setSignup({
      ...signup,
      dates: arrays.removeItem(signup.dates, date.id),
    });
  };

  if (!calendarEvent || calendarEvent.dates.length <= 1) {
    return (
      <></>
    )
  }

  return (
    <>
     <Wrapper>
        <Card title="Dates">
          <Card.Body noPad>
            <Table zebra>
              <Table.Body>
                {calendarEvent?.dates?.map((date, index) => (
                  <Table.Row key={index}>
                    <Table.Cell width={5}>
                      <FormatDate starts={date.starts} ends={date.ends} />
                    </Table.Cell>
                    <Table.Cell width={2.5} right>
                      <RemainingPlaces date={date} />
                    </Table.Cell>
                    <Table.Cell right width={2.5}>
                      <>
                        {calendarEvent?.signupLimit <= date.signups &&
                        !date.signedUp ? (
                          <>This date is fully booked </>
                        ) : (
                          <>
                            { !readonly ? (
                              <DateSignupButton 
                                calendarEvent={calendarEvent}
                                signup={signup}
                                date={date} 
                                onDateSelect={handleDateSelect}
                                onDateDeselect={handleDateDeselect}
                              />
                            ) : (
                              <>
                              { signup?.dates?.some(x => x === date.id) && (
                                <Chip text={"Signed Up"} colorSwatch={Swatches.Success} />
                              )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Card.Body>
        </Card>
        { !calendarEvent?.mandatoryAttendance && (
          <Sub>
            To sign up for this event, please choose one or more dates above
            and then click on the{" "}
            <b>
              {calendarEvent?.signedUp
                ? "'Save Changes'"
                : "'Signup for this Event'"}
            </b>{" "}
            button below.
          </Sub>
        )}
      </Wrapper>
    </>
  );
}


export default EventDates;