import searchClassGroupsReducer from "./searchClassGroupsReducer";
import searchCustomGroupsReducer from "./searchCustomGroupsReducer";
import searchDepartmentsReducer from "./searchDepartmentsReducer";
import searchGroupsReducer from "./searchGroupsReducer";
import searchPhonebookReducer from "./searchPhonebookReducer";
import searchStaffReducer from "./searchStaffReducer";
import searchStudentsReducer from "./searchStudentsReducer";
import searchTutorGroupsReducer from "./searchTutorGroupsReducer";
import searchUsersReducer from "./searchUsersReducer";

const search = {
  searchClassGroups: searchClassGroupsReducer,
  searchCustomGroups: searchCustomGroupsReducer,
  searchDepartments: searchDepartmentsReducer,
  searchGroups: searchGroupsReducer,
  searchPhonebook: searchPhonebookReducer,
  searchStaff: searchStaffReducer,
  searchStudents: searchStudentsReducer,
  searchTutorGroups: searchTutorGroupsReducer,
  searchUsers: searchUsersReducer,
};

export default search;
