import React from "react";
import Bear from "./bear";
import Cat from "./cat";
import Dog from "./dog";
import Fox from "./fox";
import Frog from "./frog";
import Lion from "./lion";
import Panda from "./panda";
import Sheep from "./sheep";

import styled from "styled-components";
import { Size } from "ui-kit";

export interface IPrimaryRocketCharacterProps {
  className?: string;
  alt?: string;
}

export interface IPrimaryRocketProps {
  code: number;
  size?: number;
  className?: string;
}

const getSize = (size: Size) => {
  switch (size) {
    case Size.ExtraSmall:
      return 18;
    case Size.Small:
      return 24;
    case Size.Medium:
      return 42;
    case Size.Large:
      return 56;
    case Size.ExtraLarge:
      return 72;
    case Size.Jumbo:
      return 100;
    default:
      return 100;
  }
};

const Wrapper = styled.span<{size: number}>`
  display: inline-block;
  svg {
    width: 100px;
  }
`;

const PrimaryRocket: React.FC<IPrimaryRocketProps> = ({code, size, className}) => {
  let _size = !size ? Size.Small : size;

  switch (code) {
    case 0:
      return null;
    case 1:
      return (
        <Wrapper className="primary-rocket" size={getSize(_size)}>
          <Fox className={className} />
        </Wrapper>
      );
    case 2:
      return (
        <Wrapper className="primary-rocket" size={getSize(_size)}>
          <Lion className={className}/>
        </Wrapper>
      );
    case 3:
      return (
        <Wrapper className="primary-rocket" size={getSize(_size)}>
          <Frog className={className} />
        </Wrapper>
      );
    case 4:
      return (
        <Wrapper className="primary-rocket" size={getSize(_size)}>
          <Panda className={className} />
        </Wrapper>
      );
    case 5:
      return (
        <Wrapper className="primary-rocket" size={getSize(_size)}>
          <Dog className={className} />
        </Wrapper>
      );
    case 6:
      return (
        <Wrapper className="primary-rocket" size={getSize(_size)}>
          <Cat className={className} />
        </Wrapper>
      );
    case 7:
      return (
        <Wrapper className="primary-rocket" size={getSize(_size)}>
          <Bear className={className} />
        </Wrapper>
      );
    case 8:
      return (
        <Wrapper className="primary-rocket" size={getSize(_size)}>
          <Sheep className={className} />
        </Wrapper>
      );
    default:
      return null;
  }
};

export default PrimaryRocket;
