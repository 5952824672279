import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  StructuredList,
  Button,
  TextInput,
  Dropdown,
  Size,
  List,
  Spacing,
  EmptyMessage,
  Swatches,
  Loader,
  neutral,
  ToastService,
  Message,
  Title,
  TitleSize,
  DetailLabel,
  HtmlText,
  ValidationMessage,
  Left,
  Right,
  Tabs,
} from "ui-kit";
import { arrays } from "utils";
import { SchoolPicker, FilePicker } from "sharedComponents/common";
import Subheader from "sharedComponents/layout/header/subheader";
import styled from "styled-components";
import BulletinSectionModal from "./bulletinSectionModal";
import BulletinPreviewModal from "./bulletinPreviewModal";
import BulletinArticlesModal from "./bulletinArticlesModal";
import BulletinTestSendModal from "./bulletinTestSendModal";
import bulletinActions from "areas/news/actions/bulletinActions";
import { RootState } from "reducers/store";
import { SchoolStartupView } from "types/schools/schools.types";
import {
  ArticleListView,
  BulletinDetailView,
  BulletinSectionView,
} from "areas/news/types/newsResponse.types";
import { ApprovalStatus } from "areas/humanResources/types/leaveShared.types";
import { SaveBulletinCommand } from "areas/news/types/newsRequest.types";
import { FileListView } from "types/common/files.types";
import { useScroll } from "hooks/useScroll";

const MainWrapper = styled.div`
  display: flex;
  flex-grow: 1;

  > .left {
    flex-grow: 1;
    margin-right: ${Spacing.Large}px;
  }

  > .right {
    width: 300px;

    .button {
      margin-bottom: ${Spacing.Medium}px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const SectionWrapper = styled.div`
  .card .card-body {
    .detail-label {
      margin-bottom: ${Spacing.Default}px;
      padding-bottom: ${Spacing.Default}px;
      border-bottom: 1px solid ${neutral[300]};
    }
    &:hover {
      cursor: move;
    }
    display: flex;
    flex-direction: row;

    > .left {
      flex-grow: 1;
      margin-right: ${Spacing.Large}px;
    }

    > .right {
      display: flex;
      flex-direction: column;

      .button {
        margin-bottom: ${Spacing.Small}px;
      }
    }
  }

  .bulletin-sections-add {
    display: flex;
    justify-content: center;
    margin-bottom: ${Spacing.Large}px;
  }
`;

const ArticlesWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: ${Spacing.Default}px;

  .list {
    margin-bottom: ${Spacing.Default}px;
  }
  .articles-wrapper-select-articles {
    flex-grow: 0;
    display: inline-flex;
  }
`;

const BulletinEditor = () => {

  const { scrollToTop } = useScroll();
  const { id: bulletinId } = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { bulletin, actions } = useSelector(
    (state: RootState) => state.bulletin
  );
  const {
    templates,
    loading: loadingBulletinTemplates,
    error: bulletinTemplatesError,
  } = useSelector((state: RootState) => state.bulletinTemplates);

  const [_schools, _setSchools] = useState<SchoolStartupView[]>([
    ...user.schools,
    user.baseSchool,
  ]);
  const [_bulletin, _setBulletin] = useState<BulletinDetailView | null>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_selectedSection, _setSelectedSection] =
    useState<BulletinSectionView | null>(null);
  const [_previewOpen, _setPreviewOpen] = useState<boolean>(false);
  const [_testSendOpen, _setTestSendOpen] = useState<boolean>(false);
  const [_articlesOpen, _setArticlesOpen] = useState<boolean>(false);

  useEffect(() => {
    actions.save.error && scrollToTop();
  }, [actions.save.error]);

  useEffect(() => {
    bulletinActions.getBulletinTemplates();
  }, []);

  useEffect(() => {
    if (bulletinId != null) {
      bulletinActions.getBulletin(
        parseInt(bulletinId),
        (data: BulletinDetailView) => {
          _setBulletin(data);
        }
      );
    } else {
      // Default bulletin options
      _setBulletin({
        senderId: 99,
        schools: [],
        articles: [],
        sections: [],
        darkSwatch: "#111111",
        lightSwatch: "#ffffff",
        primarySwatch: "#006592",
        secondarySwatch: "#4e96bc",
        name: "",
        subject: "",
        template: "",
        bulletinTemplateId: 0,
        files: [],
        sender: undefined,
        bulletinTemplateName: "",
        status: ApprovalStatus.Draft,
        sendCount: 0,
      });
    }
  }, [bulletinId]);

  const handleSave = () => {
    // Validation
    var errors = [];

    if (!_bulletin.senderId || _bulletin.senderId == -1) {
      errors.push(`Please choose a school account to send from.`);
    }

    if (!_bulletin.name) {
      errors.push(`Please enter a name for this bulletin.`);
    }

    if (!_bulletin.subject) {
      errors.push(`Please enter a subject.`);
    }

    if (!_bulletin.bulletinTemplateId) {
      errors.push(`Please select a template.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    var data: SaveBulletinCommand = {
      ..._bulletin,
      schools: _bulletin.schools.map(x => x.id),
      articles: _bulletin.articles.map(x => x.id),
      sections: _bulletin.sections.map(x =>
        x.id < 0 ? { ...x, id: null } : x
      ),
      approverId: null,
    };
    // Send

    if (_bulletin.id) {
      bulletinActions.saveBulletin(data, () => {
        ToastService.pop("Bulletin saved", null, "megaphone");
        navigate(-1);
      });
    } else {
      bulletinActions.createBulletin(data, () => {
        ToastService.pop("Bulletin saved", null, "megaphone");
        navigate(-1);
      });
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const [dragId, setDragId] = useState<number | null>(null);

  const handleDrag = (ev: React.DragEvent) => {
    setDragId(parseInt(ev.currentTarget.id));
  };

  const handleDrop = (ev: React.DragEvent) => {
    const dragSection = _bulletin.sections.find(
      section => section.id == dragId
    );
    const dropSection = _bulletin.sections.find(
      section => section.id == parseInt(ev.currentTarget.id)
    );
    const dragSectionOrder = dragSection.displayIndex;
    const dropSectionOrder = dropSection.displayIndex;

    _setBulletin({
      ..._bulletin,
      sections: _bulletin.sections.map(section => {
        if (section.id == dragId) {
          section.displayIndex = dropSectionOrder;
        }

        if (section.id == parseInt(ev.currentTarget.id)) {
          section.displayIndex = dragSectionOrder;
        }

        return section;
      }),
    });
  };

  const handleAddSection = () => {
    var length = _bulletin.sections.length;

    var newSection: BulletinSectionView = {
      id: length * -1,
      displayIndex: length,
      title: "",
      content: "",
      color: 0,
      colorName: "Primary",
      layout: 1,
      layoutName: "Center",
      file: null,
      linkText: "",
      linkUrl: "",
    };
    _setBulletin({
      ..._bulletin,
      sections: [..._bulletin.sections, newSection],
    });
    _setSelectedSection(newSection);
  };

  const handleDeleteSection = (section: BulletinSectionView) => {
    if (window.confirm("This will delete this section. Are you sure?")) {
      _setBulletin({
        ..._bulletin,
        sections: arrays.removeItem(_bulletin.sections, section),
      });
    }
  };

  const handleSaveSection = (section: BulletinSectionView) => {
    _setBulletin({
      ..._bulletin,
      sections: _bulletin.sections.map(s => (s.id == section.id ? section : s)),
    });
    _setSelectedSection(null);
  };

  const handleArticlesSaved = (articles: ArticleListView[]) => {
    _setBulletin({
      ..._bulletin,
      articles: articles,
    });
    _setArticlesOpen(false);
  };

  const handleRemoveArticle = (article: ArticleListView) => {
    _setBulletin({
      ..._bulletin,
      articles: arrays.removeItem(_bulletin.articles, article),
    });
  };

  const handlePreview = () => {};

  if (actions.load.working || !_bulletin) {
    return <Loader cover size={Size.Medium} />;
  }

  if (actions.load.error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem loading the bulletin"
        cover
      />
    );
  }

  return (
    <>
      <Subheader>
        <Title
          size={TitleSize.H2}
          text="Bulletins"
          sub={_bulletin.id ? bulletin?.name : `New Bulletin`}
        />
      </Subheader>
      <Message text={actions.save.error} color={Swatches.Danger} />
      <ValidationMessage errors={validationErrors} />

      <MainWrapper>
        <Left>
          <Tabs>
            <Tabs.Pane label="General" name="General">
              <Card>
                <Card.Body>
                  <StructuredList>
                    <StructuredList.Item
                      name="Bulletin Name"
                      required
                      description="This is an internal name for this bulletin."
                    >
                      <TextInput
                        value={_bulletin.name}
                        onChange={value =>
                          _setBulletin({ ..._bulletin, name: value })
                        }
                        characterCount={true}
                        maxLength={50}
                        fluid
                        placeholder="e.g. Heartbeat No.1"
                      />
                    </StructuredList.Item>
                    <StructuredList.Item
                      name="Sender"
                      description="This is the school account that the bulletin will be sent from."
                      required
                    >
                      <p>Lionheart Educational Trust</p>
                      {/* <SchoolDropdown
                        includeTrust
                        fluid
                        initialValue={_bulletin.senderId}
                        onChange={val => {
                          console.log(val);
                          _setBulletin({ ..._bulletin, senderId: val })
                        }
                        }
                      /> */}
                    </StructuredList.Item>
                    <StructuredList.Item
                      name="Bulletin Template"
                      required
                      description="The master layout template that the bulletin will use."
                    >
                      <Dropdown
                        loading={loadingBulletinTemplates}
                        error={bulletinTemplatesError}
                        value={_bulletin.bulletinTemplateId}
                        placeholder="Please select a template"
                        onChange={value =>
                          _setBulletin({
                            ..._bulletin,
                            bulletinTemplateId: value,
                          })
                        }
                        fluid
                      >
                        {templates?.map((template, index) => (
                          <Dropdown.Item
                            key={index}
                            value={template.id}
                            label={template.name}
                          />
                        ))}
                      </Dropdown>
                    </StructuredList.Item>
                    <StructuredList.Item
                      name="Subject"
                      required
                      description="This is the subject line that will appear on the bulletin email."
                    >
                      <TextInput
                        value={_bulletin.subject}
                        onChange={value =>
                          _setBulletin({ ..._bulletin, subject: value })
                        }
                        characterCount={true}
                        maxLength={150}
                        fluid
                        placeholder="e.g. Autumn Half-Term Update"
                      />
                    </StructuredList.Item>

                    <StructuredList.Item
                      name="Recipient Schools"
                      description="These are the schools that will receive the bulletin email."
                      required
                    >
                      <SchoolPicker
                        onChange={value => {
                          _setBulletin({
                            ..._bulletin,
                            schools: value.filter(x => x.selected),
                          });
                        }}
                        value={_bulletin?.schools}
                        selectedTooltip={school =>
                          `This bulletin will be sent to ${school.name} `
                        }
                        deselectedTooltip={school =>
                          `This bulletin will no be sent to ${school.name} `
                        }
                        readOnlyTooltip={school =>
                          `You do not have permission to set or unset bulletins for ${school.name} `
                        }
                      />
                    </StructuredList.Item>
                  </StructuredList>
                </Card.Body>
              </Card>

              <Card title="Attachments">
                <Card.Body>
                  <StructuredList>
                    <StructuredList.Item
                      name="Upload Files"
                      description="You can upload .pdf, .docx, .xlsx, .csv, .jpg, .gif, .png or .pptx files."
                    >
                      <FilePicker
                        files={_bulletin.files}
                        onFilesChanged={files =>
                          _setBulletin({
                            ..._bulletin,
                            files: files as FileListView[],
                          })
                        }
                      />
                    </StructuredList.Item>
                  </StructuredList>
                </Card.Body>
              </Card>
            </Tabs.Pane>
            <Tabs.Pane label="Sections" name="Sections">
              <SectionWrapper className="bulletin-sections">
                {_bulletin?.sections
                  ?.sort(function (a, b) {
                    return a.displayIndex - b.displayIndex;
                  })
                  .map((section: BulletinSectionView, index: number) => (
                    <Card
                      key={index}
                      id={`${section.id}`}
                      draggable={true}
                      onDragOver={ev => ev.preventDefault()}
                      onDragStart={handleDrag}
                      onDrop={handleDrop}
                    >
                      <Card.Body>
                        <Left>
                          <DetailLabel
                            bold
                            label={section.title}
                            sub={`${section.layoutName} | ${section.colorName}`}
                          />

                          <HtmlText html={section.content} />
                        </Left>
                        <Right>
                          <Button
                            text="Edit"
                            onClick={() => _setSelectedSection(section)}
                            color={Swatches.Low}
                            size={Size.Small}
                          />
                          <Button
                            text="Delete"
                            onClick={() => handleDeleteSection(section)}
                            color={Swatches.Danger}
                            size={Size.Small}
                          />
                        </Right>
                      </Card.Body>
                    </Card>
                  ))}
                <div className="bulletin-sections-add">
                  <Button
                    text="Add New Section"
                    onClick={handleAddSection}
                    color={Swatches.Low}
                    size={Size.Small}
                  />
                </div>
              </SectionWrapper>
            </Tabs.Pane>
            <Tabs.Pane label="Linked Articles" name="Articles">
              <ArticlesWrapper>
                {arrays.isEmpty(_bulletin.articles) ? (
                  <EmptyMessage
                    icon="megaphone"
                    title="No Articles"
                    summary="No articles have been selected yet"
                    cover
                  >
                    <Button
                      text="Select Articles"
                      onClick={() => _setArticlesOpen(true)}
                      color={Swatches.Primary}
                      size={Size.Small}
                    />
                  </EmptyMessage>
                ) : (
                  <>
                    <List>
                      {_bulletin.articles.map(
                        (article: ArticleListView, index: number) => (
                          <List.Item
                            key={index}
                            text={article.title}
                            sub={article.category.name}
                            right={
                              <Button
                                onClick={() => handleRemoveArticle(article)}
                                text="Remove"
                                color={Swatches.Danger}
                                size={Size.Small}
                              />
                            }
                          />
                        )
                      )}
                    </List>
                    <Button
                      className="articles-wrapper-select-articles"
                      text="Select Articles"
                      onClick={() => _setArticlesOpen(true)}
                      color={Swatches.Primary}
                      size={Size.Small}
                    />
                  </>
                )}
              </ArticlesWrapper>
            </Tabs.Pane>
          </Tabs>
        </Left>
        <Right>
          <Card>
            <Card.Body>
              <Button
                text="Save Bulletin"
                onClick={() => handleSave()}
                color={Swatches.Success}
                working={actions.save.working}
                size={Size.Small}
              />
              <Button
                text="Preview"
                onClick={() => _setPreviewOpen(true)}
                color={Swatches.Low}
                working={actions.save.working || actions.preview.working}
                size={Size.Small}
                disabled={!_bulletin?.bulletinTemplateId}
              />
              {_bulletin.status !== null && _bulletin.status !== undefined && (
                <Button
                  text="Test Send"
                  onClick={() => _setTestSendOpen(true)}
                  color={Swatches.Low}
                  working={actions.save.working || actions.testSend.working}
                  size={Size.Small}
                />
              )}

              <Button
                text="Cancel"
                onClick={handleCancel}
                color={Swatches.Low}
                working={actions.save.working}
                size={Size.Small}
              />
            </Card.Body>
          </Card>
        </Right>
      </MainWrapper>

      <BulletinSectionModal
        section={_selectedSection}
        open={_selectedSection != null}
        onClose={() => _setSelectedSection(null)}
        onSave={handleSaveSection}
        primarySwatch={_bulletin.primarySwatch}
        secondarySwatch={_bulletin.secondarySwatch}
        lightSwatch={_bulletin.lightSwatch}
        darkSwatch={_bulletin.darkSwatch}
      />
      <BulletinPreviewModal
        bulletin={_bulletin}
        open={_previewOpen}
        onClose={() => _setPreviewOpen(false)}
      />
      <BulletinTestSendModal
        bulletin={_bulletin}
        open={_testSendOpen}
        onClose={() => _setTestSendOpen(false)}
        onSent={() => _setTestSendOpen(false)}
      />
      <BulletinArticlesModal
        bulletin={_bulletin}
        open={_articlesOpen}
        onClose={() => _setArticlesOpen(false)}
        onSave={handleArticlesSaved}
      />
    </>
  );
};

export default BulletinEditor;
