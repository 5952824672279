import moment, { duration } from "moment";
import { PlannerSchoolView } from "areas/planner/types/plannerSchoolResponse.types";
import { objects } from "utils";
import { DetentionTypeListView } from "../types/behaviourResponses.types";
import { DetentionStudentViewExt } from "../components/detentions/bulkRescheduleModal";


const detentionDateTimeFormat = "YYYY-MM-DD HH:mm";
const timeFormat = "HH:mm";

export type ValidatedDatetime = {
  startDate: string;
  endDate: string;
}

/**
 * @param {moment.MomentInput} startDate - The start date
 * @param {moment.MomentInput} startDate - The end date
 * @returns The minute difference between two dates
 */
export const getDetentionDuration = (startDate: moment.MomentInput, endDate: moment.MomentInput) : number => {
  return moment(endDate).diff(moment(startDate), "minutes");
}


/**
 * @param {string} time - A time string in `HH:mm` format
 * @returns An object containing the numeric hour and minute of the input
 */
export const getTime = (time: string) : { hour: number; minute: number; } => {
  return {
    hour: parseInt(time.split(":")[0]),
    minute: parseInt(time.split(":")[1])
  }
}


/**
 * @param {DetentionStudentView[]} detentions - A list of all detentions being rescheduled
 * @param {string} studentId - The Id of student whose detentions we are getting
 * @param {string} startDate - The date to check for detentions on
 * @returns A filtered array of detentions for the given student on the given day
 */
export const getStudentDetentionsForDate = (detentions: DetentionStudentViewExt[], studentId: string, startDate: moment.MomentInput) : DetentionStudentViewExt[] => {
  return detentions
    .filter(d => 
      (d.student.id === studentId) &&
      (moment(d.startDate).isSame(startDate, "date"))
    )
    .sort((a, b) => moment(a.startDate).unix() - moment(b.startDate).unix());
}


/**
 * @param {string} originalStart - The original start date of the detention
 * @param {number} duration - The detention duration
 * @param {string} studentId - The Id of the student who the detention is for
 * @param {DetentionStudentView[]} detentions - An array of all detentions being rescheduled
 * @param {PlannerSchoolView} schoolInformation - The school information including detention information
 * @param {number} [detentionId] - Optional detention Id to ignore when searching for student detentions for date
 * @returns A validated datetime for the next available detention slot on the next day
 */
export const getNextDayDetentionSlot = (originalStart: string, duration: number, studentId: string, detentions: DetentionStudentViewExt[], schoolInformation: PlannerSchoolView, detentionId?: number) : ValidatedDatetime => {
  var { hour, minute } = getTime(schoolInformation.detentionAfterSchoolStarts);
  var nextDayDtStart = moment(originalStart)
    .add(1, "day")
    .hour(hour)
    .minutes(minute);
  var studentDetentionsForDate = getStudentDetentionsForDate(detentions, studentId, nextDayDtStart.format(detentionDateTimeFormat));
  studentDetentionsForDate = studentDetentionsForDate.filter(x => x.detentionId !== detentionId);
  if (studentDetentionsForDate.length === 0) {
    return {
      startDate: nextDayDtStart.format(detentionDateTimeFormat),
      endDate: nextDayDtStart.clone().add(duration, "minutes").format(detentionDateTimeFormat),
    }
  }
  else {
    // Get last detention of next day. If adding another detention goes past cutoff, move to next day. Else, use next slot.
    var lastDt = studentDetentionsForDate[studentDetentionsForDate.length - 1];
    if (moment(lastDt.endDate).add(duration).isSameOrAfter(schoolInformation.detentionAfterSchoolEnds)) {
      return getNextDayDetentionSlot(lastDt.endDate, duration, studentId, detentions, schoolInformation);
    }
    else {
      return {
        startDate: moment(lastDt.endDate).format(detentionDateTimeFormat),
        endDate: moment(lastDt.endDate).add(duration, "minutes").format(detentionDateTimeFormat),
      }
    }
  }
}


/**
 * @param {DetentionStudentView[]} detentions - An array of all detentions being rescheduled
 * @param {DetentionStudentView} detention - The detention which is having the time modified
 * @param {string} newTime - The updated time of the detention
 * @returns An array of all existing detentions that the new detention overlaps with
 */
export const getOverlappingTimes = (detentions: DetentionStudentViewExt[], detention: DetentionStudentViewExt, newTime: string) : DetentionStudentViewExt[] => {
  var { hour, minute} = getTime(newTime);
  var duration = getDetentionDuration(detention.startDate, detention.endDate);

  var newStartTime = moment(detention.startDate)
    .hour(hour)
    .minutes(minute);
  var newEndTime = newStartTime
    .clone()
    .add(duration, "minutes");

  return detentions.filter(d => 
    ( newStartTime.isBetween(d.startDate, d.endDate) || 
      newEndTime.isBetween(d.startDate, d.endDate) || 
      newStartTime.isSame(d.startDate)) && 
    (d.student.id === detention.student.id) &&
    (d.detentionId !== detention.detentionId)
  )
  .sort((a, b) => moment(a.startDate).unix() - moment(b.startDate).unix());
}


/**
 * @param {DetentionStudentView} detention - The detention to check if it elapses past the cutoff time
 * @param {string} newTime - The updated start time of the detention in `HH:mm` format
 * @param {string} cutoffTime - The cutoff time for detention attendance in `HH:mm` format
 * @returns True if the rescheduled detention elapses past the cutoff time
 */
export const detentionElapsesPastCutoff = (detention: DetentionStudentViewExt, newTime: string, cutoffTime: string) : boolean => {
  var { hour, minute} = getTime(newTime);
  var duration = getDetentionDuration(detention.startDate, detention.endDate);
  var newEndTime = moment(detention.startDate)
    .hour(hour)
    .minutes(minute)
    .add(duration, "minutes");
  var dayDtCutoff = moment(`${moment(detention.startDate).format("YYYY-MM-DD")} ${cutoffTime}`);

  return newEndTime.isAfter(dayDtCutoff);
}


/**
 * @param {DetentionStudentView[]} detentions - An array of all detentions being rescheduled
 * @param {DetentionStudentView} detention - The detention which is having the time modified
 * @param {string} newTime - The updated time of the detention
 * @param {PlannerSchoolView} schoolInformation - The school information including detention information
 * @returns A validated datetime for the next available detention slot
 */
export const validateNewDetentionTime = (detentions: DetentionStudentViewExt[], detention: DetentionStudentViewExt, newTime: string, schoolInformation: PlannerSchoolView) : ValidatedDatetime => {

  var duration = getDetentionDuration(detention.startDate, detention.endDate);
  var { hour, minute} = getTime(newTime);

  var newStartTime = moment(detention.startDate)
    .hour(hour)
    .minutes(minute);
  var newEndTime = newStartTime
    .clone()
    .add(duration, "minutes");

    if (duration > 120)  { //If it's reflection, allow whatever given time because it doesn't have to conform to after school times
    return {
      startDate: newStartTime.format(detentionDateTimeFormat),
      endDate: newEndTime.format(detentionDateTimeFormat)
    }
  }

  const overlappingTimes = getOverlappingTimes(detentions, detention, newTime);

  if (overlappingTimes.length > 0 && !schoolInformation.allowOverlapDetentions) {
    var endOfExisting = moment(overlappingTimes[0].endDate);
    return validateNewDetentionTime(detentions, detention, endOfExisting.format(timeFormat), schoolInformation);
  }

  if (detentionElapsesPastCutoff(detention, newTime, schoolInformation.detentionAfterSchoolEnds)) {
    var nextDaySlot = getNextDayDetentionSlot(detention.startDate, duration, detention.student.id, detentions, schoolInformation);
    return {
      startDate: nextDaySlot.startDate,
      endDate: nextDaySlot.endDate,
    }
  }
  else {
    return {
      startDate: newStartTime.format(detentionDateTimeFormat),
      endDate: newEndTime.format(detentionDateTimeFormat)
    }
  }
}


/**
 * @param {DetentionStudentView[]} detentions - An array of all detentions being rescheduled
 * @param {DetentionStudentView} detention - The detention which is having the date modified
 * @param {Date} newDate - The updated date of the detention
 * @returns A validated datetime with the new date checked
 */
export const validateNewDetentionDate = (detentions: DetentionStudentViewExt[], detention: DetentionStudentViewExt, newDate: Date, schoolInformation: PlannerSchoolView) : ValidatedDatetime => {
  
  var newStartDate = (moment(newDate).day() > 5) 
    ? moment(newDate).weekday(8)
    : moment(newDate)
  var duration = getDetentionDuration(detention.startDate, detention.endDate);

  if (newStartDate.isBefore(moment(), "days")) {
    return {
      startDate: detention.startDate,
      endDate: detention.endDate
    }
  }

  if (duration > 120) { //If it's reflection, allow whatever given date because it doesn't have to conform to after school times
    var { hour, minute } = getTime(moment(detention.startDate).format(timeFormat));
    newStartDate.hour(hour);
    newStartDate.minute(minute);
    return {
      startDate: newStartDate.format(detentionDateTimeFormat),
      endDate: newStartDate.add(duration, "minutes").format(detentionDateTimeFormat)
    }
  }

  var existingDetentions = getStudentDetentionsForDate(detentions, detention.student.id, newDate);

  if (existingDetentions.length === 0) {
    var { hour, minute} = getTime(schoolInformation.detentionAfterSchoolStarts);
    newStartDate.hour(hour);
    newStartDate.minute(minute);
    return {
      startDate: newStartDate.format(detentionDateTimeFormat),
      endDate: newStartDate.add(duration, "minutes").format(detentionDateTimeFormat)
    }
  }
  else if (existingDetentions.length > 0) {
    var { hour, minute } = getTime(moment(detention.startDate).format(timeFormat));

    newStartDate.hour(hour);
    newStartDate.minute(minute);

    var _detention = objects.deepClone(detention);
    _detention.startDate = newStartDate.format(detentionDateTimeFormat);
    _detention.endDate = newStartDate.clone().add(duration, "minutes").format(detentionDateTimeFormat);

    const overlappingTimes = getOverlappingTimes(detentions, _detention, newStartDate.format(timeFormat));
    if (overlappingTimes.length > 0 && !schoolInformation.allowOverlapDetentions) {
      var previousDtEnd = overlappingTimes[0].endDate;
      var _detention = objects.deepClone(detention);
      _detention.startDate = previousDtEnd;
      _detention.endDate = moment(previousDtEnd).add(duration, "minutes").format(detentionDateTimeFormat);
      return validateNewDetentionDate(detentions, _detention, moment(previousDtEnd).toDate(), schoolInformation);
    }

    if (detentionElapsesPastCutoff(_detention, moment(_detention.startDate).format(timeFormat), schoolInformation.detentionAfterSchoolEnds)) {
      var nextDaySlot = getNextDayDetentionSlot(_detention.startDate, duration, _detention.student.id, detentions, schoolInformation, _detention.detentionId);
      return {
        startDate: nextDaySlot.startDate,
        endDate: nextDaySlot.endDate,
      }
    }

    return {
      startDate: newStartDate.format(detentionDateTimeFormat),
      endDate: newStartDate.clone().add(duration, "minutes").format(detentionDateTimeFormat)
    }
  }
}


/**
 * @param {DetentionStudentView[]} detentions - An array of all detentions being rescheduled
 * @param {DetentionStudentView} detention - The detention to get a validated datetime for
 * @param {Date} newDate - The updated date of the detention
 * @param {string} newTime - The updated time of the detention
 * @param {PlannerSchoolView} schoolInformation - The school information including detention information
 * @returns A validated datetime for the given detention
 */
export const initialDetentionValidation = (detentions: DetentionStudentViewExt[], detention: DetentionStudentViewExt, newDate: Date, newTime: string, schoolInformation: PlannerSchoolView) : ValidatedDatetime => {
  var newStartDate = (moment(newDate).day() > 5) 
  ? moment(newDate).weekday(8)
  : moment(newDate)
  var duration = getDetentionDuration(detention.startDate, detention.endDate);

  if (newStartDate.isBefore(moment(), "days")) {
    return {
      startDate: detention.startDate,
      endDate: detention.endDate
    }
  }

  var { hour, minute } = getTime(moment(detention.startDate).format(timeFormat));
  newStartDate.hour(hour);
  newStartDate.minute(minute);

  return {
    startDate: newStartDate.format(detentionDateTimeFormat),
    endDate: newStartDate.add(duration, "minutes").format(detentionDateTimeFormat)
  }
}


/**
 * @param {DetentionStudentView[]} detentions - An array of all detentions being rescheduled
 * @param {DetentionStudentView} detention - The detention that is having its detention type changed
 * @param {DetentionTypeListView} detentionType - The updated detention type
 * @param {PlannerSchoolView} schoolInformation - The school information including detention information
 * @returns Detentions Array with new detention type on the given detention
 */
export const validateDetentionTypeChange = (detentions: DetentionStudentViewExt[], detention: DetentionStudentViewExt, detentionType: DetentionTypeListView, schoolInformation: PlannerSchoolView) : DetentionStudentViewExt[] => {
  var _detention = objects.deepClone(detention);

  //Update the end date here for detentionElapsesPastCutoff time difference
  _detention.endDate = moment(detention.startDate)
    .add(detentionType.detentionInformation.duration, "minutes")
    .format(detentionDateTimeFormat);
  
  var studentDetentionsForDate = getStudentDetentionsForDate(detentions, _detention.student.id, _detention.startDate);
  if (studentDetentionsForDate.length <= 1) {

    // Only one detention on the day that somehow goes past the cutoff, then move start time to earliest possible time
    if (detentionElapsesPastCutoff(_detention, _detention.startDate, schoolInformation.detentionAfterSchoolEnds)) {
      var { hour, minute} = getTime(schoolInformation.detentionAfterSchoolStarts);
      var earliestStartDate = moment(_detention.startDate)
        .hour(hour)
        .minutes(minute);
      return detentions.map(dt => dt.detentionId === _detention.detentionId
        ? {
          ...dt, 
          startDate: earliestStartDate.format(detentionDateTimeFormat),
          endDate: earliestStartDate.clone().add(detentionType.detentionInformation.duration, "minutes").format(detentionDateTimeFormat),
          detentionTypeId: detentionType.detentionTypeId,
          detentionTypeName: detentionType.detentionTypeName
        }
        : dt
      );
    }
    return detentions.map(dt => dt.detentionId === _detention.detentionId
      ? {
          ...dt,
          startDate: _detention.startDate,
          endDate: _detention.endDate,
          detentionTypeId: detentionType.detentionTypeId,
          detentionTypeName: detentionType.detentionTypeName
      }
      : dt
    );
  }
  else if (studentDetentionsForDate.length > 1) {

    var dtIndex = studentDetentionsForDate.findIndex(x => x.detentionId === detention.detentionId);
    if (dtIndex === -1) {
      throw new Error('Detention not found in day detentions');
    }
    else if (dtIndex + 1 < studentDetentionsForDate.length) {
      var _detentions = [...detentions];
      for (var i = dtIndex + 1; i < studentDetentionsForDate.length; i++) {
        var subsequentDetention = studentDetentionsForDate[i];
        var previousDtEnd = studentDetentionsForDate[i - 1].endDate;
        var duration = getDetentionDuration(subsequentDetention.startDate, subsequentDetention.endDate);
        if (detentionElapsesPastCutoff(subsequentDetention, previousDtEnd, schoolInformation.detentionAfterSchoolEnds)) {
          var nextDaySlot = getNextDayDetentionSlot(subsequentDetention.startDate, duration, subsequentDetention.student.id, detentions, schoolInformation);
          _detentions = detentions.map(dt => dt.detentionId === subsequentDetention.detentionId
            ? {
              ...dt,
              startDate: nextDaySlot.startDate,
              endDate: nextDaySlot.endDate,
            }
            : dt
          )
        }
        else {
          _detentions = detentions.map(dt => dt.detentionId === subsequentDetention.detentionId
            ? {
              ...dt,
              startDate: previousDtEnd,
              endDate: moment(previousDtEnd).add(duration, "minutes").format(detentionDateTimeFormat),
            }
            : dt
          )
        }
      }
      return _detentions.map(dt => dt.detentionId === _detention.detentionId
        ? {
          ...dt,
          startDate: _detention.startDate,
          endDate: _detention.endDate,
          detentionTypeId: detentionType.detentionTypeId,
          detentionTypeName: detentionType.detentionTypeName
        }
        : dt
      );
    }
    else if ((dtIndex + 1 === studentDetentionsForDate.length) && detentionElapsesPastCutoff(detention, detention.startDate, schoolInformation.detentionAfterSchoolEnds)) {
      var nextDaySlot = getNextDayDetentionSlot(detention.startDate, detentionType.detentionInformation.duration, detention.student.id, detentions, schoolInformation);    
      return detentions.map(dt => dt.detentionId === _detention.detentionId
        ? {
          ...dt, 
          startDate: nextDaySlot.startDate,
          endDate: nextDaySlot.endDate,
          detentionTypeId: detentionType.detentionTypeId,
          detentionTypeName: detentionType.detentionTypeName
        }
        : dt
      );
    }

  }
}