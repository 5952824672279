

/**
 * Pluralises words depending on the count
 * @param {string} singular The singular noun
 * @param {string} plural The plural of the noun
 * @param {number} count The count to check the length of
 * @returns {string} the singular or plural version of a noun depending on the count
 */
const pluralize = (singular: string, plural: string, count: number) : string => {
  if (!count || count > 1 || count < -1) {
    return plural;
  }
  return singular;
};


const methods = { pluralize };

export default methods;
