import React from "react";
import styled from "styled-components";
import { neutral, Label, Button, BorderRadius, Spacing } from "ui-kit";
import { Constants } from "configuration";
import { useSelector } from "react-redux";
import feedActions from "../actions/feedActions";
import { RootState } from "reducers/store";
import { IFeedItem } from "../reducers/feedReducer";


const Wrapper = styled.div`
  background: ${neutral[300]};
  border-radius: ${BorderRadius}px;
  padding: ${Spacing.Default}px;
  margin-bottom: ${Spacing.Large}px;
  text-align: center;
  color: ${neutral[500]};

  .icon {
    color: ${neutral[500]};
  }

  .dismissed-item-restore {
    color: ${neutral[500]};
  }
`;


interface IDismissedItemProps {
  item: IFeedItem;
}


const DismissedItem: React.FC<IDismissedItemProps> = ({ item }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);

  const handleRestore = () => {
    switch (item.type) {
      case Constants.FEED_ITEM_TYPES.NOTICE:
      case Constants.FEED_ITEM_TYPES.FEATURE:
        feedActions.undismissFeedArticle(user.id, item.id);
        break;
      case Constants.FEED_ITEM_TYPES.ASSIGNMENT:
        feedActions.undismissFeedAssignment(user.id, item.id);
        break;
      case Constants.FEED_ITEM_TYPES.CALENDAR_EVENT:
        feedActions.undismissFeedCalendarEvent(user.id, item.id);
        break;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <Label>This item has been dismissed</Label>
      <Button
        className="dismissed-item-restore"
        icon="undo-alt"
        fluid
        transparent
        onClick={handleRestore}
      />
    </Wrapper>
  );
};

export default DismissedItem;
