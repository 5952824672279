import React, { useState } from "react";
import PropTypes from "prop-types";
import EditBehaviourStatus from "./editBehaviourStatus";
import {
  StructuredList,
  TextInput,
  TextInputType,
  Message,
  Swatches,
} from "ui-kit";
import { CHANGE_STATUS_KEYS } from "areas/behaviour/constants/detentions";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import ConfirmModal from "sharedComponents/common/confirmModal";
import { StudentFeedListView } from "types/planner/feedResponse.types";
import { IDetentionDetails } from "areas/behaviour/utils/detentions";


interface IEditBehaviourStatusWrapperProps {
  activity: StudentFeedListView;
  detentionDetails: IDetentionDetails;
  handleCloseModal: () => void;
}


const EditBehaviourStatusWrapper: React.FC<IEditBehaviourStatusWrapperProps> = ({
  activity,
  detentionDetails,
  handleCloseModal,
}) => {

  const [note, setNote] = useState<string>("");
  const [openConfirmCancel, setOpenConfirmCancel] = useState<boolean>(false);
  const [openConfirmRevoke, setOpenConfirmRevoke] = useState<boolean>(false);
  const [emptyNoteError, setEmptyNoteError] = useState<boolean>(false);

  const changeStatus = (futureStatus: number, setOpenConfirm?: (value: boolean) => void) => {
    if (
      (futureStatus === CHANGE_STATUS_KEYS.MARKCANCELLED ||
        futureStatus === CHANGE_STATUS_KEYS.REVOKEBEHAVIOUR) &&
      note.trim() === ""
    ) {
      setEmptyNoteError(true);
    } else {
      const callback = () => {
        handleCloseModal();
      };

      switch (futureStatus) {
        case CHANGE_STATUS_KEYS.MARKATTENDED:
          detentionActions.attendedDetention(
            activity.students[0].id,
            activity.itemId,
            detentionDetails.id,
            {
              studentId: activity.students[0].id,
              behaviourId: activity.itemId,
              detentionId: detentionDetails.id,
              cancelNote: null,
              updateNote: null,
            }
          );
          break;

        case CHANGE_STATUS_KEYS.MARKMISSED:
          detentionActions.missedDetention(
            activity.students[0].id,
            activity.itemId,
            detentionDetails.id,
            {
              studentId: activity.students[0].id,
              behaviourId: activity.itemId,
              detentionId: detentionDetails.id,
              cancelNote: null,
              updateNote: null,
            }
          );
          break;

        case CHANGE_STATUS_KEYS.MARKCANCELLED:
          detentionActions.cancelDetention(
            activity.students[0].id,
            activity.itemId,
            detentionDetails.id,
            {
              studentId: activity.students[0].id,
              behaviourId: activity.itemId,
              detentionId: detentionDetails.id,
              cancelNote: null,
              updateNote: null,
            },
            callback
          );

          setOpenConfirm(false);
          break;

        case CHANGE_STATUS_KEYS.REVOKEBEHAVIOUR: {
          behaviourActions.revokeBehaviour(
            {
              studentId: activity.students[0].id,
              behaviourId: activity.itemId,
              updateNote: note,
            },
            callback
          );

          setOpenConfirm(false);
          break;
        }

        default:
          break;
      }
      setEmptyNoteError(false);
    }
  };

  const confirmContent = (noteFieldName: string) => {
    return (
      <>
        <StructuredList>
          <StructuredList.Item required name={noteFieldName}>
            <TextInput
              fluid
              type={TextInputType.Textarea}
              placeholder="Details"
              value={note}
              onChange={value => {
                setNote(value);
                setEmptyNoteError(false);
              }}
            />
          </StructuredList.Item>
        </StructuredList>

        {emptyNoteError && (
          <Message
            text={`${noteFieldName} must be entered.`}
            color={Swatches.Danger}
          />
        )}
      </>
    );
  };

  return (
    <>
      <EditBehaviourStatus
        activity={activity}
        detentionDetails={detentionDetails}
        changeStatus={changeStatus}
        setOpenConfirmStatusCancelled={setOpenConfirmCancel}
        setOpenConfirmRevoke={setOpenConfirmRevoke}
      />

      <ConfirmModal
        openModal={openConfirmCancel}
        confirmMsg="A message will be sent to parents to inform them of the sanction cancellation. Are you sure you want to continue?"
        onClose={() => setOpenConfirmCancel(false)}
        onConfirm={() =>
          changeStatus(CHANGE_STATUS_KEYS.MARKCANCELLED, setOpenConfirmCancel)
        }
      >
        {confirmContent("Reason for Cancelling")}
      </ConfirmModal>

      <ConfirmModal
        openModal={openConfirmRevoke}
        confirmMsg={
          detentionDetails
            ? "A message will be sent to parents to inform them of the sanction cancellation. Are you sure you want to continue?"
            : "Are you sure you want to revoke this behaviour?"
        }
        onClose={() => setOpenConfirmRevoke(false)}
        onConfirm={() =>
          changeStatus(CHANGE_STATUS_KEYS.REVOKEBEHAVIOUR, setOpenConfirmRevoke)
        }
      >
        {confirmContent("Reason for Revoking")}
      </ConfirmModal>
    </>
  );
};


export default EditBehaviourStatusWrapper;
