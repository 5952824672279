import React, { useEffect, useState } from "react";
import { Dropdown } from "ui-kit";
import { OUTCOMES } from "areas/behaviour/constants/behaviours";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";


interface IBehaviourOutcomeDropdownProps {
  outcome: number;
  handleChangeOutcome: (value: number) => void;
}


const BehaviourOutcomeDropdown: React.FC<IBehaviourOutcomeDropdownProps> = ({ outcome, handleChangeOutcome }) => {

  const [outcomesArray, setOutcomesArray] = useState<IDropdownDataItemProps<string, number, null>[]>([]);

  useEffect(() => {
    const outcomes = OUTCOMES.map(outcome => ({
      key: outcome.id,
      label: outcome.description,
      value: outcome.id,
    }));

    setOutcomesArray(outcomes);
  }, []);

  return (
    <Dropdown
      placeholder="Outcome"
      fluid
      items={outcomesArray}
      onChange={value => handleChangeOutcome(value)}
      value={outcome}
    />
  );
};

export default BehaviourOutcomeDropdown;
