import { HALF_TERM } from "./constants";
import { users } from "utils";
import { Constants } from "configuration";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";
import { PassportTileView } from "./types/passportResponse.types";


export const getHalfTerm = (halfTermId: number) => {
  const term = HALF_TERM.find(term => term.id === halfTermId);

  return term ? term.value : null;
};


export const getTileName = (tile: PassportTileView, student: PlannerStudentDetailView) => {
  
  const isPrimary = users.isPrimary(student);
  const {
    KEY_INFORMATION,
    STUDENT_INFORMATION,
    SUPPORT_STRATEGIES,
    LEARNING_STRATEGIES,
    ADDITIONAL_INFORMATION,
    CUSTOM,
  } = Constants.SEND_TILETYPE;

  if (tile.type === CUSTOM.value) {
    return tile.customTitle;
  }

  switch (tile.type) {
    case KEY_INFORMATION.value:
      return isPrimary
        ? KEY_INFORMATION.primaryName
        : KEY_INFORMATION.secondaryName;

    case STUDENT_INFORMATION.value:
      return isPrimary
        ? STUDENT_INFORMATION.primaryName
        : STUDENT_INFORMATION.secondaryName;

    case SUPPORT_STRATEGIES.value:
      return isPrimary
        ? SUPPORT_STRATEGIES.primaryName
        : SUPPORT_STRATEGIES.secondaryName;

    case LEARNING_STRATEGIES.value:
      return isPrimary
        ? LEARNING_STRATEGIES.primaryName
        : LEARNING_STRATEGIES.secondaryName;

    case ADDITIONAL_INFORMATION.value:
      return isPrimary
        ? ADDITIONAL_INFORMATION.primaryName
        : ADDITIONAL_INFORMATION.secondaryName;
  }
};
