import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Flyout,
  ActionBar,
  Button,
  Swatches,
  Message,
  Card,
  StructuredList,
  TextInput,
  Size,
  TextInputType,
  Dropdown,
  ValidationMessage,
} from "ui-kit";
import { Constants } from "configuration";
import flyoutActions from "actions/ui/flyouts";
import { useAction } from "hooks";
import { arrays } from "utils";
import paymentActions from "areas/payments/actions/paymentsActions";
import { useSelector } from "react-redux";
import { SchoolAvatar } from "sharedComponents/common";
import { RootState } from "reducers/store";
import { PaymentUser } from "areas/payments/reducers/payments/paymentsUserReducer";
import { SchoolListView } from "types/schools/schools.types";
import paymentsActions from "areas/payments/actions/paymentsActions";
import { AddManualLunchCreditCommand } from "areas/payments/types/catering/cateringRequests.types";


const Wrapper = styled.div`
  .adjustmentValue {
    min-width: auto;
  }
  .adjustmentValue input {
    min-width: 4rem;
    width: 4rem;
    max-width: 4rem;
  }
`;


interface ILunchCreditAdjustmentFlyoutProps {
  user: PaymentUser;
  onAdjusted?: (adjustmentValue: number) => void;
  school: SchoolListView;
}


const LunchCreditAdjustmentFlyout: React.FC<ILunchCreditAdjustmentFlyoutProps> = ({ user, onAdjusted, school }) => {

  const { actions } = useSelector((state: RootState) => state.paymentsUser);
  const [adjustmentValue, setAdjustmentValue] = useState<number>(0);
  const [reason, setReason] = useState<number>(1);
  const [notes, setNotes] = useState<string>("");
  const [_school, _setSchool] = useState<SchoolListView>(school);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    _setSchool(school);
  }, [school]);

  const handleAdd = () => {
    // Validation
    var errors = [];

    if (adjustmentValue === 0) {
      errors.push(`Please enter a non-zero adjustment amount.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    const data: AddManualLunchCreditCommand = {
      schoolId: _school.id,
      userId: user.id,
      total: adjustmentValue,
      reason: reason,
      notes: notes,
    };

    paymentsActions.addManualLunchCredit(user.id, data, () => {
      onAdjusted?.(adjustmentValue);
      flyoutActions.closeFlyout();
    });
  };

  const handleClose = () => {
    flyoutActions.closeFlyout();
  };

  return (
    <Flyout
      title="Lunch Credit Adjustment"
      name={Constants.FLYOUTS.LUNCHCREDITADJUSTMENT}
      wide
    >
      <Flyout.Body scrollOnErrors={validationErrors}>
        <Message text={actions.addLunchCredit.error} color={Swatches.Danger} />
        <ValidationMessage errors={validationErrors} />
        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="School">
                <SchoolAvatar school={_school} />
              </StructuredList.Item>
              <StructuredList.Item
                name="Credit Amount"
                required
                description="Please enter an amount to adjust the balance by."
              >
                <Wrapper>
                  <TextInput
                    className="adjustmentValue"
                    type={TextInputType.Number}
                    value={adjustmentValue}
                    onChange={(value) => setAdjustmentValue(value)}
                    fluid
                  />
                </Wrapper>
              </StructuredList.Item>
              <StructuredList.Item
                name="Adjustment Reason"
                required
                description="Please enter a reason for the adjustment. 'Topup' should only be used in instances where a customer manual payment has been made and a topup should have been recorded."
              >
                <Wrapper>
                  <Dropdown
                    value={reason}
                    onChange={(value) => setReason(value)}
                    fluid
                  >
                    <Dropdown.Item value={0} label="Topup" />
                    <Dropdown.Item value={1} label="Opening Balance" />
                    <Dropdown.Item value={2} label="Correction" />
                    <Dropdown.Item value={3} label="Complimentary Lunch" />
                    <Dropdown.Item value={4} label="Other" />
                  </Dropdown>
                </Wrapper>
              </StructuredList.Item>
              <StructuredList.Item
                name="Notes"
                description="Please enter any additional notes relevant to the adjustment."
              >
                <Wrapper>
                  <TextInput
                    type={TextInputType.Textarea}
                    value={notes}
                    onChange={(value) => setNotes(value)}
                    fluid
                  />
                </Wrapper>
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      </Flyout.Body>
      <Flyout.Footer>
        <ActionBar low>
          <Button
            text="Adjust Balance"
            onClick={handleAdd}
            color={Swatches.Success}
            fluid
            working={actions.addLunchCredit.working}
            size={Size.Small}
          />
          <Button
            text="Close"
            onClick={handleClose}
            color={Swatches.Low}
            fluid
            working={actions.addLunchCredit.working}
            size={Size.Small}
          />
        </ActionBar>
      </Flyout.Footer>
    </Flyout>
  );
};

export default LunchCreditAdjustmentFlyout;
