import React from "react";
import { Tabs } from "ui-kit";
import Articles from "./articles";
import { users } from "utils";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import Bulletins from "./bulletins/bulletins";
import Feedback from "./feedback/feedback";
import newsActions from "areas/news/actions/newsActions";
import { RootState } from "reducers/store";


const NewsdeskHome = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { tabIndex } = useSelector((state: RootState) => state.newsUi);

  const handleTabClicked = (name: string, index: number) => {
    newsActions.setTab(index);
  };

  return (
    <Tabs
      selectedIndex={tabIndex}
      initialTabIndex={tabIndex}
      onTabSelected={handleTabClicked}
    >
      <Tabs.Pane label="Articles" name="Articles">
        <Articles />
      </Tabs.Pane>
      <Tabs.Pane
        label="Bulletins"
        name="Bulletins"
        hide={
          !users.isInAnyRoles(user, [
            Constants.ROLES.MARKETING_ADMINISTRATOR,
            Constants.ROLES.DEVELOPMENT,
          ])
        }
      >
        <Bulletins />
      </Tabs.Pane>
      <Tabs.Pane
        label="Feedback"
        name="Feedback"
        hide={
          !users.isInAnyRoles(user, [
            Constants.ROLES.MARKETING_ADMINISTRATOR,
            Constants.ROLES.DEVELOPMENT,
          ])
        }
      >
        <Feedback />
      </Tabs.Pane>
    </Tabs>
  );
};

export default NewsdeskHome;
