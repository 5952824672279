import { CashlessPageInfo, PageInfo } from "types/common/paging.types";

export interface IActionState {
  isLoading?: boolean;
  error?: null | string;
  working?: boolean;
}

const DEFAULT_PAGING : PageInfo = {
  endIndex: 0,
  pageIndex: 0,
  pageNumber: 0,
  pageSize: 25,
  startIndex: 0,
  totalPages: 0,
  totalRecords: 0,
};

const DEFAULT_ALT_PAGING : CashlessPageInfo = {
  pageIndex: 0,
  pageSize: 25,
  hasNextPage: false,
};

const INITIAL_ACTION_STATE: IActionState = {
  isLoading: false,
  error: null,
};

const LOADING_ACTION_STATE: IActionState = {
  isLoading: true,
  error: null,
};

const ERROR_ACTION_STATE = (error: string) : IActionState => {
  return {
    isLoading: false,
    error: error,
  };
};

const defaults = {
  DEFAULT_PAGING,
  DEFAULT_ALT_PAGING,
  INITIAL_ACTION_STATE,
  LOADING_ACTION_STATE,
  ERROR_ACTION_STATE,
};

export default defaults;