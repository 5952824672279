import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DetentionsOnDay from "./detentionsOnDay";
import { useSelector } from "react-redux";
import { Loader, Size } from "ui-kit";
import { arrays } from "utils";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { useAppSelector } from "reducers/hooks";


interface IDetentionsOnDayWrapperProps {
  studentId: string;
  date: string;
}


const DetentionsOnDayWrapper: React.FC<IDetentionsOnDayWrapperProps> = ({ studentId, date }) => {

  const { detentionSummary, loading, error } = useAppSelector(
    state => state.detentionSummary
  );

  useEffect(() => {
    const year = +moment(date).format("YYYY");
    const month = +moment(date).format("M");
    const day = +moment(date).format("D");

    detentionActions.getDetentionSummary(studentId, year, month, day);
  }, [date, studentId]);

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  if (loading) {
    return <Loader size={Size.Medium} fluid />;
  }

  return (
    !arrays.isEmpty(detentionSummary) && (
      <DetentionsOnDay detentionSummary={detentionSummary} />
    )
  );
};


export default DetentionsOnDayWrapper;
