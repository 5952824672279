import React from "react";
import styled from "styled-components";
import { EmptyMessage, Loader, Size, Table } from "ui-kit";
import { TableHeader } from "ui-kit/composite/table";

const Wrapper = styled.div`
  width: 100%;

  div {
    white-space: pre-wrap;
    font-family: monospace;
    font-size: 14px;
    line-height: 1.5;
  }
`;

// const demo = [
//   "2024-08-09 11:43:57.417 +01:00 [INF] ==================================================",
//   "2024-08-09 11:43:57.679 +01:00 [INF] Application Starting...",
//   "2024-08-09 11:43:58.083 +01:00 [INF] ==================================================",
//   "2024-08-09 11:44:02.663 +01:00 [INF] EmployeeDataHandler: Employee transient data sync started for school LHT 99",
//   "2024-08-09 11:44:03.675 +01:00 [INF] Sending HTTP request GET https://api.wonde.com/v1.0/schools/A1156235330/employees?cursor=true&per_page=200&include=employment_details,extended_details,contact_details,photo,identifiers",
//   "2024-08-09 11:44:09.863 +01:00 [INF] Received HTTP response headers after 6186.3706ms - 200",
//   "2024-08-09 11:44:09.865 +01:00 [INF] End processing HTTP request after 6196.6837ms - 200",
//   "2024-08-09 11:44:21.601 +01:00 [INF] Received HTTP response headers after 3501.095ms - 200"
// ]

interface ISyncLogViewProps {
  Data: string[];
  loading: boolean;
}

const SyncLogView: React.FC<ISyncLogViewProps> = ({ Data, loading }) => {
  if (loading) {
    return <Loader size={Size.Large} fluid />;
  }

  const parseDataObject = (data: any) => {
    try {
      if (typeof data === "object") {
        return JSON.stringify(data, null, 2);
      }
      if (typeof data === "string") {
        return JSON.stringify(JSON.parse(data), null, 2);
      }
    } catch (error) {
      return data;
    }
  };

  return !Data ? null : Data.length > 0 ? (
    <Wrapper>
      <Table grow loading={loading}>
        <TableHeader>
          <Table.HeaderCell>Logs</Table.HeaderCell>
          <Table.HeaderCell />
        </TableHeader>
        <Table.Body>
          {Data &&
            parseDataObject(Data).map((warning: any, index: number) => (
              <Table.Row key={index}>
                <Table.Cell>{warning}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </Wrapper>
  ) : (
    <EmptyMessage title="No Sync Log Found" icon="list" />
  );
};

export default SyncLogView;
