import action from "actions/action";
import { ToastService } from "ui-kit";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "../../../../services/client";

const builder = new actionTypeBuilder("studentAwards");

const types = {
  GETSTUDENTAWARDCATEGORIES: builder.build("getawardcategories"),
  GETSTUDENTAWARDS: builder.build("getawards"),
  ADDSTUDENTAWARD: builder.build("addaward"),
  DELETESTUDENTAWARD: builder.build("deleteaward"),
};

const getStudentAwardCategories = onSuccess => { //CategoryListView[]
  return action(
    () => client.get(`planner/awardCategories`),
    types.GETSTUDENTAWARDCATEGORIES,
    onSuccess
  );
};

const getStudentAwards = (studentId, onSuccess) => {
  return action(
    () => client.get(`planner/students/${studentId}/awards`),
    types.GETSTUDENTAWARDS,
    onSuccess
  );
};

const addStudentAward = (newAward, onSuccess) => {
  return action(
    () => client.post(`planner/award`, newAward),
    types.ADDSTUDENTAWARD,
    response => {
      ToastService.pop("Award successfully added", null, "check-circle");
      onSuccess?.(response);
    }
  );
};

const deleteStudentAward = (studentId, awardId, onSuccess) => {
  return action(
    () => client.delete(`planner/students/${studentId}/awards/${awardId}`),
    types.DELETESTUDENTAWARD,
    response => {
      ToastService.pop("Award successfully deleted", null, "check-circle");
      onSuccess?.(response);
    }
  );
};

const studentAwardActions = {
  types,
  getStudentAwardCategories,
  getStudentAwards,
  addStudentAward,
  deleteStudentAward,
};

export default studentAwardActions;
