import React, { useState, useEffect } from "react";
import { 
  HeadlineStatistic, 
  Currency, 
  ActionBar, 
  TextInput, 
  Button, 
  Size, 
  Swatches, 
  Spacing,
  Dropdown
} from "ui-kit";
import { useNavigate } from "react-router-dom";
import SearchForm from "../../../search/searchForm";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import paymentActions from "areas/payments/actions/paymentsActions";
import styled from "styled-components";


const SearchFormWrapper = styled.div`
  display: flex;
  flex-grow: 1;

  .textInput {
    margin-right: ${Spacing.Default}px;
  }
`;


interface IDashboardProps {
  buyerOnlyRole?: boolean;
}


const Dashboard: React.FC<IDashboardProps> = ({ buyerOnlyRole }) => {

  const { summary, loading, error } = useSelector((state: RootState) => state.adminSummary);

  useEffect(() => {
    const now = new Date();
    if (!summary) {
      //paymentActions.getSummary(now, now);
    }
  }, []);

  return (
    <>
      {/* <SearchForm onSearch={handleSearch} initialValue={""}/> */}
      <ShopkeeperSearch buyerOnlyRole={buyerOnlyRole} />

      {summary && (
        <HeadlineStatistic>
          <HeadlineStatistic.Item
            icon="receipt"
            label="Income"
            value={<Currency value={summary.headline.headline.income} />}
          />
          <HeadlineStatistic.Item
            icon="receipt"
            label="Refunds"
            value={<Currency value={summary.headline.headline.refunds} />}
          />
          <HeadlineStatistic.Item
            icon="receipt"
            label="Orders"
            value={summary.headline.orders.toString()}
          />
          <HeadlineStatistic.Item
            icon="id-card-alt"
            label="Smartcard Failures"
            value={summary.headline.smartcardSyncFailures.toString()}
          />
          <HeadlineStatistic.Item
            icon="receipt"
            label="Print Credit Failures"
            value={summary.headline.printCreditSyncFailures.toString()}
          />
        </HeadlineStatistic>
      )}
    </>
  );
};


interface IShopkeeperSearchProps {
  buyerOnlyRole?: boolean;
}
enum SearchCategoryType {
  User = 0,
  Products = 1
}
interface searchCategoryDropdownItem {
  label: string;
  value: SearchCategoryType;
}

const ShopkeeperSearch: React.FC<IShopkeeperSearchProps> = ({ buyerOnlyRole }) => {

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchCategories, setSearchCategories] = useState<searchCategoryDropdownItem[]>(() => {
    var _categories = [
      { label: "Users", value: SearchCategoryType.User }
    ]
    if (!buyerOnlyRole) {
      _categories.push({ label: "Products", value: SearchCategoryType.Products });
    }
    return _categories;
});
  const [selectedCategory, setSelectedCategory] = useState<searchCategoryDropdownItem>(searchCategories[0]);
 
  const handleSearch = () => {
    if (searchTerm?.length > 0) {
      switch (selectedCategory.value) {
        case SearchCategoryType.User:
          navigate(`/main/shopkeeper/search`, { state: { searchTerm: searchTerm } });
          break;
        case SearchCategoryType.Products:
          navigate(`/main/shopkeeper/productSearch`, { state: { searchTerm: searchTerm } });
          break;
      }
    }
  };

  const handleSearchCategoryChange = (value: number, label: string) => {
    setSelectedCategory({ label: label, value: value });
  };

  return (
    <ActionBar>
      <SearchFormWrapper>
        <Dropdown
          placeholder="Search Category"
          items={searchCategories}
          onChange={handleSearchCategoryChange}
          value={selectedCategory.value}
        />
        <TextInput
          value={searchTerm}
          onChange={value => setSearchTerm(value)}
          placeholder={`Search ${selectedCategory.label}...`}
          fluid
          onKeyPress={event => (event.key === 'Enter') && handleSearch()}
        />
        <Button
          text="Search"
          color={Swatches.Primary}
          type="submit"
          size={Size.Medium}
          onClick={handleSearch}
        />
      </SearchFormWrapper>
    </ActionBar>
  );
};


export default Dashboard;
