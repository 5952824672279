import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import FileDownload from "js-file-download";
import moment from "moment";
import { BeehiveReport, callbackType } from "types/common/action.types";
import { SmartTaskFilter } from "../types/smartTaskAnalyticsRequests.types";
import { SmartTaskReportView } from "../types/smartTaskAnalyticsResponse.types";

const builder = new actionTypeBuilder("analytics");

const types = {
  GETSMARTTASKREPORT: builder.build("getsmarttaskreport"),
  EXPORTSMARTTASKREPORT: builder.build("exportsmarttaskreport"),
};


const getSmartTaskReport = <T extends boolean = false>(
  {
    startDate,
    endDate,
    schoolId,
    smartTaskId
  }: SmartTaskFilter,
  exportReport: T,
  onSuccess?: callbackType<T extends true ? BeehiveReport : SmartTaskReportView[]>
) => {

  var queryEndPoint: string;

  if (exportReport) {
    queryEndPoint = `planner/reporting/smartTaskReportExport?schoolId=${schoolId}&startDate=${moment(
      startDate
    ).format("YYYY-MM-DD")}`;
  } else {
    queryEndPoint = `planner/reporting/smartTaskReport?schoolId=${schoolId}&startDate=${moment(
      startDate
    ).format("YYYY-MM-DD")}`;
  }

  if (endDate) {
    queryEndPoint =
      queryEndPoint + `&endDate=${moment(endDate).format("YYYY-MM-DD")}`;
  }

  if (smartTaskId !== null) {
    queryEndPoint = queryEndPoint + `&smartTaskId=${smartTaskId}`;
  }

  return exportReport
    ? action<BeehiveReport>(
        () => client.get(queryEndPoint, { responseType: "blob" }),
        types.EXPORTSMARTTASKREPORT,
        response => {
          onSuccess?.(response as any);
          FileDownload(response, "Smart Task Report.csv");
        }
      )
    : action<SmartTaskReportView[]>(
        () => client.get(queryEndPoint),
        types.GETSMARTTASKREPORT,
        response => {
          onSuccess?.(response as any);
        }
      );
};


const smartTaskReportActions = { 
  types, 
  getSmartTaskReport 
};

export default smartTaskReportActions;
