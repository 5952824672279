import { ProvisionCostBandListView } from "areas/send/types/passportResponse.types";
import provisionCostBandsActions from "../../actions/provisions/provisionCostBandsActions";
import { IBeehiveAction } from "types/common/action.types";

interface IProvisionCostBandState {
  band: ProvisionCostBandListView[];
  loading: boolean;
  error: string | null;
  saving: boolean;
  saveError: string | null;
  deleting: boolean;
  deleteError: string | null;
  newId: number | null;
}

const INITIAL_STATE: IProvisionCostBandState = {
  band: [],
  loading: false,
  error: null,
  saving: false,
  saveError: null,
  deleting: false,
  deleteError: null,
  newId: null
};


const provisionCostBandReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IProvisionCostBandState => {

  const {
    PROVISIONCOSTBANDS_GET,
    PROVISIONCOSTBANDS_CREATE,
    PROVISIONCOSTBANDS_SAVE,
    PROVISIONCOSTBANDS_DELETE,
  } = provisionCostBandsActions.types;

  switch (action.type) {
    case PROVISIONCOSTBANDS_GET.START:
      return { ...state, loading: true };
    case PROVISIONCOSTBANDS_GET.SUCCESS:
      return { ...state, loading: false, band: action.payload };
    case PROVISIONCOSTBANDS_GET.FAILED:
      return { ...state, error: action.payload };

    case PROVISIONCOSTBANDS_CREATE.START:
      return { ...state, saving: true };
    case PROVISIONCOSTBANDS_CREATE.SUCCESS:
      return { ...state, saving: false, newId: action.payload };
    case PROVISIONCOSTBANDS_CREATE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem creating the band",
      };

    case PROVISIONCOSTBANDS_SAVE.START:
      return { ...state, saveError: null, deleteError: null, saving: true };
    case PROVISIONCOSTBANDS_SAVE.SUCCESS:
      return { ...state, saving: false };
    case PROVISIONCOSTBANDS_SAVE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the band",
      };

    case PROVISIONCOSTBANDS_DELETE.START:
      return { ...state, deleteError: null, deleting: true };
    case PROVISIONCOSTBANDS_DELETE.SUCCESS:
      return { ...state, deleting: false };
    case PROVISIONCOSTBANDS_DELETE.FAILED:
      return {
        ...state,
        deleting: false,
        deleteError:
          "There was a problem deleting the band. This may be because this band is in use.",
      };

    default:
      return state;
  }
};

export default provisionCostBandReducer;
