import { configureStore } from '@reduxjs/toolkit'
import thunk from "redux-thunk";
// import signalRMiddleware from "signalr/signalr";
import rootReducer from ".";


const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  devTools: process.env.REACT_APP_ENVIRONMENT_NAME === "Local" ||
            process.env.REACT_APP_ENVIRONMENT_NAME === "Refactor Local"
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
