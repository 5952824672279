import React from "react";
import { Tabs, HeadlineStatistic, honeygold } from "ui-kit";
import {
  XYPlot,
  XAxis,
  HorizontalGridLines,
  YAxis,
  VerticalBarSeries,
  VerticalBarSeriesProps,
  VerticalBarSeriesPoint,
} from "react-vis";


const Houses: React.FC = () => {
  
  const tickFormatFunc = (value: number) => {
    return value % 1 !== 0 ? null : Math.round(value).toString();
  };

  const data: VerticalBarSeriesPoint[] = [
    { x: "Wyvern", y: 221, color: "#333333" },
    { x: "Phoenix", y: 193 },
    { x: "Titan", y: 341 },
    { x: "Pegasus", y: 207 },
    { x: "Drakon", y: 43 },
  ];
  return (
    <Tabs>
      <Tabs.Pane name="Leaderboard" label="Leaderboard">
        <XYPlot
          height={400}
          width={800}
          xType="ordinal"
          yType="linear"
          style={{ font: "600 12px neue-haas-grotesk-display" }}
        >
          <HorizontalGridLines />

          <XAxis />

          <YAxis tickFormat={tickFormatFunc} />

          <VerticalBarSeries data={data} color={honeygold} barWidth={5} />
        </XYPlot>
      </Tabs.Pane>
      <Tabs.Pane name="My House" label="My House">
        <HeadlineStatistic>
          <HeadlineStatistic.Item
            icon="shield-alt"
            label="Points"
            value="231"
          />
        </HeadlineStatistic>
      </Tabs.Pane>
    </Tabs>
  );
};

export default Houses;
