import { IBeehiveError } from "types/common/errors.types";
import classBehaviourReportActions from "../actions/classBehaviourReportActions";
import { IBeehiveAction } from "types/common/action.types";
import { BehaviourReportView } from "../types/classGroupBehaviourAnalyticsResponse.types";


interface IClassBehaviourReportState {
  report: BehaviourReportView | null;
  refresh: boolean;
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IClassBehaviourReportState = {
  report: null,
  refresh: false,
  loading: false,
  error: null,
};


const classBehaviourReportReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IClassBehaviourReportState => {

  const { GETCLASSBEHAVIOURS } = classBehaviourReportActions.types;
  
  switch (action.type) {
    case GETCLASSBEHAVIOURS.START:
      return { ...state, loading: true, error: null };

    case GETCLASSBEHAVIOURS.SUCCESS:
      return { ...state, report: action.payload, loading: false };

    case GETCLASSBEHAVIOURS.FAILED:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default classBehaviourReportReducer;
