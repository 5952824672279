import Proptypes from "prop-types";
import ClassLayoutStudentWrapper from "./classLayoutStudentWrapper";
import styled from "styled-components";
import { neutral } from "ui-kit/common/colors";
import { Spacing, BorderRadius } from "ui-kit";
import ClassLayoutStudentSlot from "./classLayoutStudentSlot";
import { useSelector } from "react-redux";
import { findEmptySlot } from "areas/planner/utils/layouts";
import { checkIsDropzone } from "areas/planner/utils/layouts";

const Wrapper = styled.div`
  box-sizing: border-box;
  .col {
    width: 6.5rem;
    height: 7.9rem;
    border-radius: ${BorderRadius.Default}px;
    margin: ${Spacing.Small - 2}px;
    box-sizing: border-box;
    // border: 1px solid ${neutral[600]};

    .empty-slot-wrapper {
      visibility: hidden;
    }

    &.dropzone {
      // width: 6.5rem;
      // height: 7.9rem;
      z-index: 1000;

      .empty-slot-wrapper {
        visibility: visible;
      }
    }
  }

  ${({ flags }) =>
    flags === 0 &&
    `
    .col {
      width: 5.8rem;
    }`}

  ${({ flags, isReadingAge }) =>
    flags > 5 && isReadingAge === true
      ? `
      .col {
        height: 7.9rem;
      }`
      : (flags > 5 && isReadingAge === false) ||
        (flags > 0 && isReadingAge === true)
      ? `
      .col {
        height: 7rem;
      }`
      : (flags === 0 || (flags > 0 && isReadingAge === false)) &&
        `
      .col {
        height: 6rem;
      }`}

  ${({ hideSensitive }) =>
    hideSensitive &&
    `
      .col {
        width: 5.8rem;
        height: 6rem;
      }
    `}
`;

const ClassLayoutGridCell = ({
  row,
  col,
  onDragStart,
  onDropGrid,
  studentSlots,
  removeStudent,
  selectedStudents,
  handleSelectStudent,
  maxFlagsAndReadingAge,
}) => {
  const { isPrivate } = useSelector(state => state.privacy);

  const displayStudentOnGrid = (row, col) => {
    for (let i = 0; i < studentSlots.length; i++) {
      let studentSlot = studentSlots[i];
      if (
        studentSlot.student != null &&
        row === studentSlot.coordinates.y &&
        col === studentSlot.coordinates.x
      ) {
        return (
          <ClassLayoutStudentWrapper
            student={studentSlot.student}
            onGrid
            onDragStart={onDragStart}
            removeStudent={removeStudent}
            selectedStudents={selectedStudents}
            handleSelectStudent={handleSelectStudent}
            maxFlagsAndReadingAge={maxFlagsAndReadingAge}
          />
        );
      }
    }
    return (
      <ClassLayoutStudentSlot maxFlagsAndReadingAge={maxFlagsAndReadingAge} />
    );
  };

  const onDragEnter = event => {
    event.preventDefault();
    const target = event.target;
    findEmptySlot(target, true);
  };

  const onDragLeave = event => {
    event.preventDefault();
    const target = event.target;
    findEmptySlot(target);
  };

  return (
    <Wrapper
      hideSensitive={isPrivate.sensitiveInfo}
      flags={maxFlagsAndReadingAge?.maxFlags}
      isReadingAge={maxFlagsAndReadingAge?.isReadingAge}
    >
      <div
        className={`col ${
          checkIsDropzone(row, col, studentSlots) ? "dropzone" : "" // CHANGING THE CLASS NAME WILL BREAK CLASS LAYOUT
        }`}
        onDragOver={event => event.preventDefault()}
        onDrop={event => onDropGrid(event, row, col)}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
      >
        {displayStudentOnGrid(row, col)}
      </div>
    </Wrapper>
  );
};

ClassLayoutGridCell.propTypes = {
  row: Proptypes.number,
  col: Proptypes.number,
  onDragStart: Proptypes.func,
  onDropGrid: Proptypes.func,
  studentSlots: Proptypes.array,
  removeStudent: Proptypes.func,
};

export default ClassLayoutGridCell;
