import React, { useState, useEffect } from "react";
import {
  StructuredList,
  Card,
  RadioList,
  ActionBar,
  Button,
  Swatches,
  Dropdown,
  Size,
} from "ui-kit";
import BehaviourCategoryFilters from "./behaviourCategoryFilters";
import BehaviourCodeFilters from "./behaviourCodeFilters";
import BehaviourOutcomeFilters from "./behaviourOutcomeFilters";
import SenFilters from "./senFilters";
import SortByRadioList from "./sortByRadioList";
import DateRange from "../dateRange";
import DetentionTypeFilters from "./detentionTypeFilters";
import Search, {
  SEARCH_GROUP_TYPE_KEYS,
} from "sharedComponents/common/search/search";
import MultiSchoolUserView from "sharedComponents/common/users/multiSchoolUserView";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import {
  GROUP_BY_ARRAY,
  SenType,
} from "areas/analytics/constants/behaviourReportConstants";
import AcademicYearFilter from "sharedComponents/common/academicYearFilter";
import { Constants } from "configuration";
import { useAppSelector } from "reducers/hooks";
import { BehaviourIncludeType, GroupBy, OrderBy, OrderByOption } from "areas/analytics/types/behaviourAnalyticsShared.types";
import { FilterBy } from "areas/analytics/types/lessonExitAnalyticShared.types";
import { DetentionTypeListView, SchoolBehaviourCategoryListView, SchoolBehaviourCodeListView } from "areas/behaviour/types/behaviourResponses.types";
import { OutcomeType } from "areas/behaviour/constants/behaviours";
import { SearchResultViewBase } from "types/common/views.types";


export interface BehaviourFilter {
  subjects: SearchResultViewBase[];
  teachers: SearchResultViewBase[];
  students: SearchResultViewBase[];
  yearGroups: SearchResultViewBase[];
  tutorGroups: SearchResultViewBase[];
  categories: SchoolBehaviourCategoryListView[];
  codes: SchoolBehaviourCodeListView[];
  outcomes: OutcomeType[];
  sen: SenType[];
  detentionTypes: DetentionType[];
}

type filterType = 
  SchoolBehaviourCategoryListView | 
  SchoolBehaviourCodeListView | 
  OutcomeType | 
  SenType | 
  DetentionType | 
  SearchResultViewBase;

const initialFilters: BehaviourFilter = {
  subjects: [],
  teachers: [],
  students: [],
  yearGroups: [],
  tutorGroups: [],
  categories: [],
  codes: [],
  outcomes: [],
  sen: [],
  detentionTypes: [],
};


interface IBehaviourAnalyticsFormProps {
  onSelectSchool: (schoolId: number) => void;
  handleGenerateReport: (
    startDate: Date,
    endDate: Date,
    groupBy1: GroupBy,
    groupBy2: GroupBy,
    behaviourType: BehaviourIncludeType,
    sortBy: OrderBy,
    orderBy: OrderByOption,
    academicYearId: number,
    filters: BehaviourFilter,
    exportReport?: boolean
  ) => void;
  loadingExportReport: boolean;
}

export interface DetentionType extends DetentionTypeListView {
  id: number;
}


const BehaviourAnalyticsForm: React.FC<IBehaviourAnalyticsFormProps> = ({
  onSelectSchool,
  handleGenerateReport,
  loadingExportReport,
}) => {

  const { schoolId } = useAppSelector(state => state.schools);
  const { allBehaviourCodes } = useAppSelector(state => state.allBehaviourCodes);
  const { allRewardCodes } = useAppSelector(state => state.allRewardCodes);
  const { allBehaviourCategoryGroups } = useAppSelector(
    state => state.allBehaviourCategories
  );
  const { allRewardCategoryGroups } = useAppSelector(
    state => state.allRewardCategories
  );//BehaviourCategoryGroupByListView[]
  const { detentionTypes } = useAppSelector(state => state.detentionTypes);

  // Report Options
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [breakdownCategoryOne, setBreakdownCategoryOne] = useState<GroupBy>(null);
  const [breakdownCategoryTwo, setBreakdownCategoryTwo] = useState<GroupBy>(null);
  const [behaviourTypesToInclude, setBehaviourTypesToInclude] = useState<BehaviourIncludeType>(BehaviourIncludeType.BehaviourReward);
  const [behaviourTypesToIncludeName, setBehaviourTypesToIncludeName] = useState<string>(null);
  const [_detentionTypes, _setDetentionTypes] = useState<DetentionType[]>([]);
  const [ filters, setFilters] = useState<BehaviourFilter>({ ...initialFilters });
  const [academicYearId, setAcademicYearId] = useState<number>(Constants.ACADEMIC_YEAR_FILTER_ARRAY[0].value);

  // Check boxes to display filters
  const [filterByCategoryChecked, setFilterByCategoryChecked] = useState<boolean>(false);
  const [filterByCodeChecked, setFilterByCodeChecked] = useState<boolean>(false);
  const [filterByOutcomeChecked, setFilterByOutcomeChecked] = useState<boolean>(false);
  const [filterBySenChecked, setFilterBySenChecked] = useState<boolean>(false);
  // const [filterByDetentionTypeChecked, setFilterByDetentionTypeChecked] = useState<boolean>(false);

  const [sortByValue, setSortByValue] = useState<OrderBy>(OrderBy.Name);
  const [orderByValue, setOrderByValue] = useState<OrderByOption>(OrderByOption.Ascending);

  useEffect(() => {
    setFilters({ ...initialFilters });
  }, [schoolId]);

  useEffect(() => {
    var behaviourType: string;
    switch (behaviourTypesToInclude) {
      case BehaviourIncludeType.Reward:
        behaviourType = "Reward";
        break;
      case BehaviourIncludeType.Behaviour:
        behaviourType = "Behaviour";
        break;
      case BehaviourIncludeType.BehaviourReward:
        behaviourType = "Reward & Behaviour";
        break;
      default:
        behaviourType = "";
    }
    setBehaviourTypesToIncludeName(behaviourType);
  }, [behaviourTypesToInclude]);

  useEffect(() => {
    ////DetentionTypeListView[]
    if (detentionTypes) {
      let detentionTypesCopy = JSON.parse(JSON.stringify(detentionTypes));

      const tempDetentionTypes: DetentionType[] = detentionTypesCopy.map((type: DetentionTypeListView) => ({
        ...type,
        id: type.detentionTypeId,
      }));

      _setDetentionTypes(tempDetentionTypes);
    }
  }, [detentionTypes]);

  const handleCategoryChange = (value: GroupBy) => {
    setBreakdownCategoryOne(value);
    if (value === null) {
      setBreakdownCategoryTwo(null);
    }
  };

  const handleBehaviourTypeChange = (value: BehaviourIncludeType) => {
    setBehaviourTypesToInclude(value);
    value === BehaviourIncludeType.Reward &&
      setFilters({ ...filters, detentionTypes: [] });
  };

  const selectFilter = <T extends filterType>(
    filterArray: T[], 
    filter: T
  ) => {
    const tempSelectedFilters = filterArray.slice();
    tempSelectedFilters.push(filter);
    return tempSelectedFilters;
  };

  const removeFilter = <T extends filterType>(
    filterArray: T[], 
    filter: T
  ) => {
    const tempSelectedFilters = filterArray.slice();
    const index = tempSelectedFilters.findIndex(
      selectedFilter => selectedFilter.id === filter.id
    );
    tempSelectedFilters.splice(index, 1);

    return tempSelectedFilters;
  };

  const handleFilterChange = (
    filter: filterType, 
    filterType: FilterBy, 
    isSelected: boolean
  ) => {
    switch (filterType) {
      case FilterBy.Teacher:
        setFilters({
          ...filters,
          teachers: isSelected
            ? selectFilter(filters.teachers, filter as SearchResultViewBase)
            : removeFilter(filters.teachers, filter as SearchResultViewBase),
        });
        break;

      case FilterBy.Pupil:
        setFilters({
          ...filters,
          students: isSelected
            ? selectFilter(filters.students, filter as SearchResultViewBase)
            : removeFilter(filters.students, filter as SearchResultViewBase),
        });
        break;

      case FilterBy.Subject:
        setFilters({
          ...filters,
          subjects: isSelected
            ? selectFilter(filters.subjects, filter as SearchResultViewBase)
            : removeFilter(filters.subjects, filter as SearchResultViewBase),
        });
        break;

      case FilterBy.YearGroup:
        setFilters({
          ...filters,
          yearGroups: isSelected
            ? selectFilter(filters.yearGroups, filter as SearchResultViewBase)
            : removeFilter(filters.yearGroups, filter as SearchResultViewBase),
        });
        break;

      case FilterBy.TutorGroup:
        setFilters({
          ...filters,
          tutorGroups: isSelected
            ? selectFilter(filters.tutorGroups, filter as SearchResultViewBase)
            : removeFilter(filters.tutorGroups, filter as SearchResultViewBase),
        });
        break;

      case FilterBy.BehaviourCategories:
        setFilters({
          ...filters,
          categories: isSelected
            ? selectFilter(filters.categories, filter as SchoolBehaviourCategoryListView)
            : removeFilter(filters.categories, filter as SchoolBehaviourCategoryListView),
        });
        break;

      case FilterBy.BehaviourCode:
        setFilters({
          ...filters,
          codes: isSelected
            ? selectFilter(filters.codes, filter as SchoolBehaviourCodeListView)
            : removeFilter(filters.codes, filter as SchoolBehaviourCodeListView),
        });
        break;

      case FilterBy.Outcome:
        setFilters({
          ...filters,
          outcomes: isSelected
            ? selectFilter(filters.outcomes, filter as OutcomeType)
            : removeFilter(filters.outcomes, filter as OutcomeType),
        });
        break;

      case FilterBy.SEN:
        setFilters({
          ...filters,
          sen: isSelected
            ? selectFilter(filters.sen, filter as SenType)
            : removeFilter(filters.sen, filter as SenType),
        });
        break;

      case FilterBy.DetentionTypes:
        setFilters({
          ...filters,
          detentionTypes: isSelected
            ? selectFilter(filters.detentionTypes, filter as DetentionType)
            : removeFilter(filters.detentionTypes, filter as DetentionType),
        });
        break;

      default:
        break;
    }
  };

  const handleCategoryClick = (category: SchoolBehaviourCategoryListView, isChecked: boolean) => {
    handleFilterChange(category, FilterBy.BehaviourCategories, isChecked);
  };

  const handleCodeClick = (code: SchoolBehaviourCodeListView, isChecked: boolean) => {
    handleFilterChange(code, FilterBy.BehaviourCode, isChecked);
  };

  const handleOutcomeClick = (outcome: OutcomeType, isChecked: boolean) => {
    handleFilterChange(outcome, FilterBy.Outcome, isChecked);
  };

  const handleSenTypeClick = (senType: SenType, isChecked: boolean) => {
    handleFilterChange(senType, FilterBy.SEN, isChecked);
  };

  const handleDetentionTypeClick = (detentionType: DetentionType, isChecked: boolean) => {
    handleFilterChange(detentionType, FilterBy.DetentionTypes, isChecked);
  };

  const handleFilterByCodeClick = () => {
    if (!filterByCodeChecked === false) {
      setFilters({
        ...filters,
        codes: [],
      });
    }
    setFilterByCodeChecked(!filterByCodeChecked);
  };

  const handleFilterbyPointCategoryClick = () => {
    if (!filterByCategoryChecked === false) {
      setFilters({
        ...filters,
        categories: [],
      });
    }
    setFilterByCategoryChecked(!filterByCategoryChecked);
  };

  const handleFilterbyOutcomeClick = () => {
    if (!filterByOutcomeChecked === false) {
      setFilters({
        ...filters,
        outcomes: [],
      });
    }
    setFilterByOutcomeChecked(!filterByOutcomeChecked);
  };

  const handleFilterbySenClick = () => {
    if (!filterBySenChecked === false) {
      setFilters({
        ...filters,
        sen: [],
      });
    }
    setFilterBySenChecked(!filterBySenChecked);
  };

  const handleSortByChange = (value: OrderBy) => {
    setSortByValue(value);
  };

  return (
    <>
      {/* <Title text="Behaviour Report" size={TitleSize.H2} /> */}
      <StructuredList>
        <MultiSchoolUserView>
          <Card title="School">
            <Card.Body>
              <SchoolDropdown
                onChange={onSelectSchool}
                initialValue={schoolId}
                fluid
              />
            </Card.Body>
          </Card>
        </MultiSchoolUserView>
        <Card title="Date Range">
          <Card.Body>
            <StructuredList.Item name="Academic Year" required>
              <AcademicYearFilter
                value={academicYearId}
                onChange={value => setAcademicYearId(value)}
              />
            </StructuredList.Item>

            <DateRange
              startDate={startDate}
              handleStartDateChange={value => setStartDate(value)}
              endDate={endDate}
              handleEndDateChange={value => setEndDate(value)}
              required
            />
          </Card.Body>
        </Card>

        <Card title="Report Breakdown">
          <Card.Body>
            <StructuredList.Item
              name="Breakdown Category One"
              description="Choose a category that you want your report to be broken down by"
              required
              helpPopup
            >
              <Dropdown
                placeholder="Category"
                fluid
                items={GROUP_BY_ARRAY}
                onChange={value => handleCategoryChange(value)}
                value={breakdownCategoryOne}
                clearable
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Breakdown Category Two"
              description="Choose a second category that you want your report to be broken down by"
              helpPopup
            >
              <Dropdown
                placeholder="Category"
                fluid
                items={GROUP_BY_ARRAY}
                onChange={value => setBreakdownCategoryTwo(value)}
                value={breakdownCategoryTwo}
                clearable
                disabled={breakdownCategoryOne === null}
              />
            </StructuredList.Item>
          </Card.Body>
        </Card>

        <Card title="Behaviour">
          <Card.Body>
            <StructuredList.Item
              name="Behaviours to Include in Report"
              description="Choose the behaviours that you want to include in your report"
              required
              helpPopup
            >
              <RadioList
                value={behaviourTypesToInclude}
                onChange={value => handleBehaviourTypeChange(value)}
              >
                <RadioList.Item
                  label="Rewards &amp; Behaviours"
                  value={BehaviourIncludeType.BehaviourReward}
                />
                <RadioList.Item
                  label="Rewards"
                  value={BehaviourIncludeType.Reward}
                />
                <RadioList.Item
                  label="Behaviours"
                  value={BehaviourIncludeType.Behaviour}
                />
              </RadioList>
            </StructuredList.Item>
          </Card.Body>
        </Card>

        <Card title="Filters">
          <Card.Body>
            <StructuredList.Item
              name="Teacher"
              description="Filter by selected teachers"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.TEACHER]}
                allowMultiple
                selected={filters.teachers}
                handleSelectItem={filter =>
                  handleFilterChange(filter, FilterBy.Teacher, true)
                }
                handleDeleteItem={filter =>
                  handleFilterChange(filter, FilterBy.Teacher, false)
                }
                schoolId={schoolId}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Student"
              description="Filter by selected students"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.STUDENT]}
                allowMultiple
                selected={filters.students}
                handleSelectItem={filter =>
                  handleFilterChange(filter, FilterBy.Pupil, true)
                }
                handleDeleteItem={filter =>
                  handleFilterChange(filter, FilterBy.Pupil, false)
                }
                schoolId={schoolId}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Year Group"
              description="Filter by selected year groups"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.YEAR_GROUP]}
                allowMultiple
                selected={filters.yearGroups}
                handleSelectItem={filter =>
                  handleFilterChange(filter, FilterBy.YearGroup, true)
                }
                handleDeleteItem={filter =>
                  handleFilterChange(filter, FilterBy.YearGroup, false)
                }
                schoolId={schoolId}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Tutor Group"
              description="Filter by selected tutor groups"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.TUTOR_GROUP]}
                allowMultiple
                selected={filters.tutorGroups}
                handleSelectItem={filter =>
                  handleFilterChange(filter, FilterBy.TutorGroup, true)
                }
                handleDeleteItem={filter =>
                  handleFilterChange(filter, FilterBy.TutorGroup, false)
                }
                schoolId={schoolId}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Subject"
              description="Filter by selected subjects"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.SUBJECT]}
                allowMultiple
                selected={filters.subjects}
                handleSelectItem={filter =>
                  handleFilterChange(filter, FilterBy.Subject, true)
                }
                handleDeleteItem={filter =>
                  handleFilterChange(filter, FilterBy.Subject, false)
                }
                schoolId={schoolId}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name={`${behaviourTypesToIncludeName} Categories`}
              description="Filter by behaviour or reward categories"
              helpPopup
            >
              <BehaviourCategoryFilters
                behaviourTypesToInclude={behaviourTypesToInclude}
                behaviourTypesToIncludeName={behaviourTypesToIncludeName}
                filterByPointCategory={filterByCategoryChecked}
                handleFilterbyPointCategoryClick={
                  handleFilterbyPointCategoryClick
                }
                rewardCategoryGroups={allRewardCategoryGroups}
                selectedCategories={filters.categories}
                handleCategoryClick={handleCategoryClick}
                behaviourCategoryGroups={allBehaviourCategoryGroups}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name={`${behaviourTypesToIncludeName} Codes`}
              description="Filter by behaviour or reward codes"
              helpPopup
            >
              <BehaviourCodeFilters
                behaviourTypesToInclude={behaviourTypesToInclude}
                behaviourTypesToIncludeName={behaviourTypesToIncludeName}
                filterByPointCode={filterByCodeChecked}
                handleFilterbyPointCodeClick={handleFilterByCodeClick}
                rewardCodes={allRewardCodes}
                behaviourCodes={allBehaviourCodes}
                selectedCodes={filters.codes}
                handleCodeClick={handleCodeClick}
              />
            </StructuredList.Item>

            {behaviourTypesToInclude !== BehaviourIncludeType.Reward && (
              <StructuredList.Item
                name="Sanction Type"
                description="Filter by sanction types"
                helpPopup
              >
                <DetentionTypeFilters
                  detentionTypes={_detentionTypes}
                  selectedDetentionTypes={filters.detentionTypes}
                  handleDetentionTypeClick={handleDetentionTypeClick}
                />
              </StructuredList.Item>
            )}

            <StructuredList.Item
              name="Outcome"
              description="Filter by behaviour or reward outcomes"
              helpPopup
            >
              <BehaviourOutcomeFilters
                filterByOutcome={filterByOutcomeChecked}
                handleFilterbyOutcomeClick={handleFilterbyOutcomeClick}
                selectedOutcomes={filters.outcomes}
                handleOutcomeClick={handleOutcomeClick}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="SEN"
              description="Filter by SEN types"
              helpPopup
            >
              <SenFilters
                filterBySen={filterBySenChecked}
                handleFilterbySenClick={handleFilterbySenClick}
                selectedSenTypes={filters.sen}
                handleSenTypeClick={handleSenTypeClick}
              />
            </StructuredList.Item>
          </Card.Body>
        </Card>

        <Card title="Sort Report">
          <Card.Body>
            <StructuredList.Item
              name="Sort By"
              description="Sort the report by category name or points"
              helpPopup
            >
              <SortByRadioList
                behaviourTypesToInclude={behaviourTypesToInclude}
                sortByValue={sortByValue}
                handleSortByChange={handleSortByChange}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Order"
              description="Order the report by ascending or descending values"
              helpPopup
            >
              <RadioList
                value={orderByValue}
                onChange={value => setOrderByValue(value)}
              >
                <RadioList.Item
                  label="Ascending"
                  value={OrderByOption.Ascending}
                />
                <RadioList.Item
                  label="Descending"
                  value={OrderByOption.Descending}
                />
              </RadioList>
            </StructuredList.Item>
          </Card.Body>
        </Card>

        <ActionBar>
          <Button
            text="Generate Report"
            color={Swatches.Success}
            size={Size.Small}
            disabled={
              startDate === null ||
              endDate === null ||
              breakdownCategoryOne === null ||
              behaviourTypesToInclude === null
            }
            onClick={() =>
              handleGenerateReport(
                startDate,
                endDate,
                breakdownCategoryOne,
                breakdownCategoryTwo,
                behaviourTypesToInclude,
                sortByValue,
                orderByValue,
                academicYearId,
                filters
              )
            }
          />
          <Button
            color={Swatches.Success}
            text="Export Report as CSV"
            icon="file-download"
            size={Size.Small}
            disabled={
              startDate === null ||
              endDate === null ||
              breakdownCategoryOne === null ||
              behaviourTypesToInclude === null
            }
            onClick={() =>
              handleGenerateReport(
                startDate,
                endDate,
                breakdownCategoryOne,
                breakdownCategoryTwo,
                behaviourTypesToInclude,
                sortByValue,
                orderByValue,
                academicYearId,
                filters,
                true
              )
            }
            working={loadingExportReport}
          />
        </ActionBar>
      </StructuredList>
    </>
  );
};

export default BehaviourAnalyticsForm;
