const actionTypes = {
  START: "START",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};


export type BuildLabels = {
  START: string,
  SUCCESS: string,
  FAILED: string,
}


const build = (prefix : string, name : string, type : string) => {
  return `${prefix.toUpperCase()}_${name.toUpperCase()}_${type.toUpperCase()}`;
};


/**
 * Helper class to generate action types
 */
class actionTypeBuilder {
  prefix : string = "";

  constructor(prefix: string) {
    this.prefix = prefix;
  }

  buildActionName(prefix: string, name: string, type: string) {
    return `${prefix}_${name}_${type}`;
  }

  /**
   * Function to return a set of actions (start, success, failed and finished)
   * with the prefix specified in the constructor and supplied name
   * @param {string} name The name of the action
   * @returns {BuildLabels} The actions in format <prefix_name_actiontype>
   */
  build(name: string) : BuildLabels {
    return {
      START: build(this.prefix, name, actionTypes.START),
      SUCCESS: build(this.prefix, name, actionTypes.SUCCESS),
      FAILED: build(this.prefix, name, actionTypes.FAILED),
    };
  }

  buildFromArray(actionNameArray: string[]) : {[key: string]: BuildLabels} {
    var types: {[key: string]: BuildLabels} = {};

    for (var i = 0; i < actionNameArray.length; i++) {
      types[this.prefix + "_" + actionNameArray[i]] = this.build(
        actionNameArray[i]
      );
    }

    return types;
  }
}

export default actionTypeBuilder;
