//@ts-nocheck
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";

export interface ISchoolViewProps {
  children?: React.ReactElement | React.ReactElement[];
}

const SingleSchoolUserView: React.FC<ISchoolViewProps> = ({ children }) => {
  const { user } = useSelector((state: RootState) => state.currentUser);
  return user && user.schools?.length == 0 ? children : null;
};

export default SingleSchoolUserView;
