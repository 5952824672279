export enum UserType {
  STAFF = 'Staff',
  STUDENT = 'Student',
  PARENT = 'Parent',
}

export enum SpecialDays {
  HALF_TERM = 0,
  BANK_HOLIDAY = 1,
  RELIGIOUS = 2,
  OTHER = 3,
  SCHOOL_CLOSURE = 4,
  BLOCK_DETENTION_SLOTS = 5
}

export enum GroupTypes {
  CLASS = 0,
  SUBJECT = 1,
  TUTOR = 2,
  YEAR = 3,
  HOUSE = 4,
  CUSTOM = 5,
  PROVISION = 6,
  GLOBAL = 7
}

export enum CovidTestresult {
  POSITIVE = 0,
  NEGATIVE = 1,
  INVALID = 2,
}

export enum FeedItemTypes {
  NOTICE = 0,
  FEATURE = 1,
  CALENDAR_EVENT = 2,
  ASSIGNMENT = 3,
}

export enum NavigationModes {
  FULL = 0,
  ICONS = 1,
  HAMBURGER = 2,
}

export enum Ordering {
  DESCENDING = 0,
  ASCENDING = 1,
}

export enum PrimaryAvatarTypes {
  Fox = 1,
  Lion = 2,
  Frog = 3,
  Panda = 4,
  Dog = 5,
  Cat = 6,
  Bear = 7,
  Sheep = 8,
}

export enum ReviewPeriods {
  YEARSTART = 0,
  MIDYEAR = 1,
  YEAREND = 2,
}

export enum Flyouts {
  BASKET = "BASKET",
  SEARCH = "SEARCH",
  NOTIFICATIONS = "NOTIFICATIONS",
  ACCOUNT = "ACCOUNT",
  REARRANGE_LAYOUT = "REARRANGE LAYOUT",
  STUDENT = "STUDENT",
  REQUESTLEAVE = "REQUESTLEAVE",
  APPROVELEAVE = "APPROVELEAVE",
  VIEWLEAVE = "VIEWLEAVE",
  DETENTIONDETAILS = "DETENTIONDETAILS",
  PENDINGATTENTIONBEHAVIOUR = "PENDINGATTENTIONBEHAVIOUR",
  CONSENT_CLAUSE = "CONSENTCLAUSE",
  MULTIPLE_REWARDS = "MULTIPLEREWARDS",
  MULTIPLE_BEHAVIOURS = "MULTIPLEBEHAVIOURS",
  CATEGORY_EDITOR = "CATEGORY_EDITOR",
  CREDIT = "CREDIT",
  ORDER = "ORDER",
  PAYMENT = "PAYMENT",
  REFUND = "REFUND",
  CREATEREFUND = "CREATEREFUND",
  USERPRODUCT = "USERPRODUCT",
  BASKETORDER = "BASKETORDER",
  INSTALMENT = "INSTALMENT",
  PUPILPREMIUMINSTALMENT = "PUPILPREMIUMINSTALMENT",
  BURSARYINSTALMENT = "BURSARYINSTALMENT",
  ITEMPAYMENT = "ITEMPAYMENT",
  CREATE_RECONCILIATION = "CREATE_RECONCILIATION",
  CUSTOM_TOPUP = "CUSTOM_TOPUP",
  LINK_EDITOR = "LINK_EDITOR",
  DOCUMENTGROUP = "DOCUMENTGROUP",
  ADDOVERTIME = "ADDOVERTIME",
  VIEWOVERTIME = "VIEWOVERTIME",
  LUNCHCREDITADJUSTMENT = "LUNCHCREDITADJUSTMENT",
  PROVISIONCATEGORY = "PROVISIONCATEGORY",
  PROVISIONORGANIZATION = "PROVISIONORGANIZATION",
  PROVISION = "PROVISION",
  SENCATEGORY = "SENCATEGORY",
  TILECATEGORY = "TILECATEGORY",
  CREATEPROVISIONRECORD = "CREATEPROVISIONRECORD",
  PROVISIONCONTACT = "PROVISIONCONTACT",
  FUNDINGSTREAM = "FUNDINGSTREAM",
  DETENTIONREGISTER = "DETENTIONREGISTER",
  SEND_PROVIDERCONTACT = "SEND_PROVIDERCONTACT",
  SEND_TAG = "SEND_TAG",
  SEND_ACCESSARRANGEMENT = "SEND_ACCESSARRANGEMENT",
  SEND_PROVIDER = "SEND_PROVIDER",
  SEND_PROVISION = "SEND_PROVISION",
  SEND_PROVISIONCOSTBAND = "SEND_PROVISIONCOSTBAND",
  FORM_FILTERING = "FORM_FILTERING",
  EVENT_FILTERING = "EVENT_FILTERING",
  DECLARATION_INTERESTS = "DECLARATION_INTERESTS",
  MULTI_STUDENT_LEAVING_LESSON = "MULTI_STUDENT_LEAVING_LESSON",
}