import React from "react";
import { useLocation, useNavigate } from "react-router";
import { Swatches, Button, Size } from "ui-kit";
import { assignmentRoutes } from "../../plannerRoutes";
import routes from "configuration/routes";


const NewAssignmentButton: React.FC = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(
      `${routes.home}${assignmentRoutes.default}${assignmentRoutes.newAssignment}`
    );
  };

  return (
    <Button
      text="New Assignment"
      size={Size.Small}
      color={Swatches.Primary}
      onClick={handleClick}
      tooltip="Create a New Assignment"
    />
  );
};

export default NewAssignmentButton;
