import React from "react";
import ReadOnlyDetentionDetails from "./readOnlyDetentionDetails";
import ReadOnlyIncidentDetails from "./readOnlyIncidentDetails";
import ReadOnlyInvestigationNote from "./readOnlyInvestigationNote";
import ReadOnlyBehaviourDetails from "./readOnlyBehaviourDetails";
import { BehaviourView } from "areas/behaviour/types/behaviourResponses.types";
import ReadOnlyCancelledDetentionDetails from "./readOnlyCancelledDetentionDetails";


interface IReadOnlyDetailsProps {
  behaviour: BehaviourView;
}


const ReadOnlyDetails: React.FC<IReadOnlyDetailsProps> = ({ behaviour }) => {
  
  return (
    <>
      {behaviour.detentions?.length > 0 && (
        <ReadOnlyDetentionDetails detentions={behaviour.detentions} />
      )}

      <ReadOnlyBehaviourDetails behaviour={behaviour} />

      <ReadOnlyIncidentDetails behaviour={behaviour} />

      {behaviour.cancelledDetentions?.length > 0 && (
        <ReadOnlyCancelledDetentionDetails detentions={behaviour.cancelledDetentions} />
      )}

      {behaviour.investigationNote && (
        <ReadOnlyInvestigationNote
          investigationNote={behaviour.investigationNote}
        />
      )}
    </>
  );
};


export default ReadOnlyDetails;
