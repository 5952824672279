import { IBeehiveAction } from "types/common/action.types";
import leaveActions from "../actions/leaveActions";
import { OvertimeDetailView } from "../types/leaveResponse.types";

interface IOvertimeState {
  overtime: OvertimeDetailView | null,
  working: boolean;
  loading: boolean;
  error: string | null;
}

const INITIAL_STATE: IOvertimeState = {
  overtime: null,
  working: false,
  loading: false,
  error: null,
};

const overtimeReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IOvertimeState => {
  const {
    SUBMIT_OVERTIME,
    CANCEL_OVERTIME,
    APPROVE_OVERTIME,
    REJECT_OVERTIME,
    GET_OVERTIME,
  } = leaveActions.types;

  switch (action.type) {
    case GET_OVERTIME.START:
      return { ...state, loading: true };

    case GET_OVERTIME.SUCCESS:
      return { ...state, loading: false, overtime: action.payload };

    case GET_OVERTIME.FAILED:
      return { ...state, loading: false };

    case SUBMIT_OVERTIME.START:
    case CANCEL_OVERTIME.START:
    case APPROVE_OVERTIME.START:
    case REJECT_OVERTIME.START:
      return { ...state, working: true };

    case SUBMIT_OVERTIME.SUCCESS:
    case CANCEL_OVERTIME.SUCCESS:
    case APPROVE_OVERTIME.SUCCESS:
    case REJECT_OVERTIME.SUCCESS:
      return {
        ...state,
        working: false,
      };

    case SUBMIT_OVERTIME.FAILED:
    case CANCEL_OVERTIME.FAILED:
    case APPROVE_OVERTIME.FAILED:
    case REJECT_OVERTIME.FAILED:
      return {
        ...state,
        error: "There was a problem processing the overtime request.",
      };

    default:
      return state;
  }
};

export default overtimeReducer;
