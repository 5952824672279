import React, { useState } from "react";
import {
  DetailLabel,
  EmptyMessage,
  Loader,
  Size,
  Table,
  SplitButton,
  Swatches,
  Chip,
} from "ui-kit";
import { formatDate } from "utils/dateTime";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { useAppSelector } from "reducers/hooks";
import DetailedDataViewModal from "./DetailedDataViewModal";
import ViewWarningModal from "./ViewWarningModal";
import {
  ObjectType,
  objectTypeDescriptions,
  SyncFailureReportItems,
} from "areas/administration/types/syncResponses.types";
import { SchoolIcon } from "sharedComponents/common";

interface SyncFailureReportTableProps {
  handlePage: () => void;
  loading?: boolean;
}

const SyncFailureReportTable: React.FC<SyncFailureReportTableProps> = ({
  handlePage,
  loading,
}) => {
  const { report, paging, error } = useAppSelector(
    (state) => state.syncFailureReport
  );
  const [selectedItem, setSelectedItem] =
    useState<SyncFailureReportItems | null>(null);
  const [warningItem, setWarningItem] = useState<SyncFailureReportItems | null>(
    null
  );

  if (loading) {
    return <Loader size={Size.Large} fluid />;
  }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  const parseWarnings = (data: string) => {
    if (typeof data !== "string" || data.trim() === "") {
      console.error("Invalid input: Data should be a non-empty string.");
      return [];
    }
    try {
      const warningsArray = data.split("~").map((warning) => warning.trim());
      const warnings = warningsArray.filter((warning) => warning.length > 0);
      return warnings;
    } catch (error) {
      console.error("An error occurred while parsing warnings:", error);
      return [];
    }
  };

  return !report ? null : report.length > 0 ? (
    <>
      <br />
      <Table grow loading={loading}>
        <Table.Header>
          <Table.HeaderCell width={0.5} />
          <Table.HeaderCell width={1.5}>Name</Table.HeaderCell>
          <Table.HeaderCell width={1}>Type</Table.HeaderCell>
          <Table.HeaderCell width={0.8}>Sims ID</Table.HeaderCell>
          <Table.HeaderCell width={1}>Wonde ID</Table.HeaderCell>
          <Table.HeaderCell width={1}>Created Date</Table.HeaderCell>
          <Table.HeaderCell width={1}>Updated Date</Table.HeaderCell>
          <Table.HeaderCell width={1}>Total Erros</Table.HeaderCell>
          <Table.HeaderCell width={1.8} right />
        </Table.Header>

        <Table.Body onPage={handlePage} paging={paging}>
          {report.map((item: SyncFailureReportItems, index) => (
            <Table.Row key={index}>
              <Table.Cell width={0.5}>
              <SchoolIcon size={Size.Small} code={item.schoolCode} />
              </Table.Cell>
              <Table.Cell width={1.5}>
                <DetailLabel label={item.name || "Unknown"} bold />
              </Table.Cell>
              <Table.Cell width={1}>
                {
                  <Chip
                    text={
                      objectTypeDescriptions[
                        ObjectType[item.objectName as keyof typeof ObjectType]
                      ]
                    }
                  />
                }
              </Table.Cell>
              <Table.Cell width={0.8}>{item.simsId}</Table.Cell>
              <Table.Cell width={1}>{item.providerId}</Table.Cell>
              <Table.Cell width={1}>{formatDate(item.createdDate)}</Table.Cell>
              <Table.Cell width={1}>{formatDate(item.updatedDate)}</Table.Cell>
              <Table.Cell width={1}>
                {parseWarnings(item.notes).length}
              </Table.Cell>
              <Table.Cell width={1.8} right>
                <SplitButton
                  size={Size.Small}
                  text="Actions"
                  color={Swatches.Default}
                >
                  <SplitButton.Option
                    onClick={() => setWarningItem(item)}
                    text="View Errors"
                    show
                  />
                  <SplitButton.Option
                    onClick={() => setSelectedItem(item)}
                    text="View Data"
                    show
                  />
                </SplitButton>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <DetailedDataViewModal
        open={!!selectedItem}
        Data={selectedItem}
        onCancel={() => setSelectedItem(null)}
      ></DetailedDataViewModal>

      <ViewWarningModal
        open={!!warningItem}
        notes={warningItem && parseWarnings(warningItem.notes)}
        onCancel={() => setWarningItem(null)}
        failureErrorsModal
      ></ViewWarningModal>
    </>
  ) : (
    <EmptyMessage title="No Sync Failures Found" icon="list" />
  );
};

export default SyncFailureReportTable;
