import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { DetailLabel, EmptyMessage, Label, Loader, Size, Table } from "ui-kit";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import OnCallStatusChip from "areas/behaviour/components/onCall/onCallStatusChip";
import { useAppSelector } from "reducers/hooks";


const OnCallReporttable = () => {

  const { report, loading, error } = useAppSelector(state => state.onCallReport);

  if (loading) {
    return <Loader size={Size.Large} fluid />;
  }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return !report ? null : report.items?.length > 0 ? (
    <Table>
      <Table.Header>
        <Table.HeaderCell>Requested By</Table.HeaderCell>
        <Table.HeaderCell width={0.9}>Class / Group</Table.HeaderCell>
        <Table.HeaderCell>On Call Category</Table.HeaderCell>
        <Table.HeaderCell>{"Student(s)"}</Table.HeaderCell>
        <Table.HeaderCell>Claimed By</Table.HeaderCell>
        <Table.HeaderCell>Resolved By</Table.HeaderCell>
        <Table.HeaderCell>Cancelled By</Table.HeaderCell>
        <Table.HeaderCell width={0.9}>Status</Table.HeaderCell>
      </Table.Header>

      <Table.Body>
        {report.items.map((onCall, index) => (
          <Table.Row>
            <Table.Cell>
              <DetailLabel
                label={`${onCall.requestedBy.firstName} ${onCall.requestedBy.lastName}`}
                sub={
                  <>
                    {moment(onCall.createdDate).format("DD/MM/YYYY")}
                    {/* {onCall.classGroup ? (
                      <div>Class: {onCall.classGroup.name}</div>
                    ) : onCall.tutorGroup ? (
                      <div>Tutor Group: {onCall.tutorGroup.name}</div>
                    ) : (
                      ""
                    )} */}
                    {onCall.roomName !== null && (
                      <div>Room: {onCall.roomName}</div>
                    )}
                  </>
                }
                bold
              />
            </Table.Cell>
            <Table.Cell>
              {onCall.classGroup
                ? onCall.classGroup.name
                : onCall.tutorGroup
                ? onCall.tutorGroup.name
                : onCall.provisionGroup
                ? onCall.provisionGroup.name
                : ""}
            </Table.Cell>
            <Table.Cell>{onCall.category.categoryDetail.name}</Table.Cell>
            <Table.Cell>
              {onCall.students.map(student => (
                <Label bold>{`${student.firstName} ${student.lastName}`}</Label>
              ))}
            </Table.Cell>
            <Table.Cell>
              {onCall.claimedBy && (
                <DetailLabel
                  label={`${onCall.claimedBy.firstName} ${onCall.claimedBy.lastName}`}
                  sub={moment(onCall.claimedDate).format("DD/MM/YYYY")}
                  bold
                />
              )}
            </Table.Cell>
            <Table.Cell>
              {onCall.resolvedBy && (
                <DetailLabel
                  label={`${onCall.resolvedBy.firstName} ${onCall.resolvedBy.lastName}`}
                  sub={moment(onCall.resolvedDate).format("DD/MM/YYYY")}
                  bold
                />
              )}
            </Table.Cell>
            <Table.Cell>
              {onCall.cancelledBy && (
                <DetailLabel
                  label={`${onCall.cancelledBy.firstName} ${onCall.cancelledBy.lastName}`}
                  sub={moment(onCall.cancelledDate).format("DD/MM/YYYY")}
                  bold
                />
              )}
            </Table.Cell>
            <Table.Cell>
              <OnCallStatusChip onCallStatus={onCall.status} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <EmptyMessage title="No On Call Requests Found" icon="list" />
  );
};

export default OnCallReporttable;
