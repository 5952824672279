import React, { useRef } from "react";
import styled from "styled-components";
import {
  Size,
  neutral,
  Spacing,
  DetailLabel,
  Icon,
} from "ui-kit";
import { ICircleIconProps } from "ui-kit/basic/circleIcon"
import { IChipProps } from "ui-kit/basic/chip"
import UserName from "./userName";
import ProfileImage from "./profileImage";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import { users } from "utils";
import { RootState } from "reducers/store";

const Wrapper = styled.div<{isPortrait?: boolean, hasOnClick?: boolean}>`
  display: flex;
  align-items: center;

  .label {
    color: ${neutral[700]};
  }

  ${({ isPortrait }) =>
    isPortrait
      ? `
    flex-direction: column;
    justify-content: center;

    .inner {
      text-align: center;
      margin-top: ${Spacing.Default}px;
    }
  `
      : `
  .inner {
    margin-left: ${Spacing.Default}px;
   
  }
  `}
  .inner {
    display: flex;
    flex-direction: column;
  }

  ${({ hasOnClick }) =>
    hasOnClick &&
    `
    &:hover {
      cursor: pointer;
    }
  `}

  .future-icon {
    margin-left: ${Spacing.Default}px;
    color: #1dcaff;
    display: inline-block;
    margin-bottom: -2px;
  }
`;

type userObj = {
  id: string,
  admissionStatus: number
}

export interface IAvatarProps extends ICircleIconProps {
  user?: any;
  portrait?: boolean;
  sub?: any;
  children?: React.ReactNode | React.ReactNode[];
  highlight?: boolean;
  size?: Size;
  self?: boolean;
  hideLabel?: boolean;
  splitName?: boolean;
  hidePhoto?: boolean;
  chip?: React.ReactNode;
}

const Avatar: React.FC<IAvatarProps> = ({
  portrait,
  user,
  sub,
  size = Size.Medium,
  onClick,
  self,
  hideLabel,
  hidePhoto,
  splitName,
  chip,
  style
}) => {
  const { user: currentUser } = useSelector((state: RootState) => state.currentUser);

  const ref = useRef() as React.RefObject<HTMLDivElement>;

  const handleClick = () => {
    onClick?.();
  };

  if (!user || !currentUser) {
    return null;
  }

  // Should show the profile image if the user is a staff member or the avatar user is the current user

  return (
    <>
      <Wrapper
        ref={ref}
        className="avatar"
        isPortrait={portrait}
        hasOnClick={onClick != null}
        onClick={handleClick}
        style={style}
      >
        {!hidePhoto && <ProfileImage user={user} size={size} className={""}/>}

        {!hideLabel && (
          <div className="inner">
            <DetailLabel
              label={
                <>
                  <UserName
                    user={user}
                    self={user?.id == currentUser?.id}
                    splitName={splitName}
                    bold={false}
                  />

                  {user.admissionStatus ===
                    Constants.ADMISSION_STATUS.FUTURE.value && (
                    <Icon value="forward" className="future-icon" />
                  )}
                </>
              }
              sub={users.isFuture(user) ? "Future Student" : sub}
              chip={chip}
              bold
            />
          </div>
        )}
      </Wrapper>
    </>
  );
};

Avatar.defaultProps = {
  portrait: false,
};

export default Avatar;
