import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Loader, Size, Dropdown, Card, Message, Swatches } from "ui-kit";
import { getDateTime, formatDate } from "utils/dateTime";
import styled from "styled-components";
import BehaviourPointForm from "./behaviourPointForm";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import schoolActions from "areas/planner/actions/school/schoolActions";
import { behaviourValidation } from "../../utils/behaviours";
import { addGroupIdToPayload } from "areas/planner/utils/group";
import { DETENTION_OPTIONS_KEYS } from "areas/behaviour/constants/detentions";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { StudentListView } from "types/users/userListViews.types";
import { CreateDetention, SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";
import { GroupType } from "types/users/userGroups.types";
import { useAppSelector } from "reducers/hooks";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { DetentionOption } from "areas/behaviour/types/behaviourShared.types";
import { CreateBehaviourCommand } from "areas/behaviour/types/behaviourRequest.types";
import { IBehaviourDetails, IDetentionDetails, IIncidentDetails } from "areas/behaviour/utils/detentions";


const Wrapper = styled.div`
  &.loading {
    display: none;
  }
`;


interface IBehaviourPointFormWrapperProps {
  student: StudentListView;
  selectedCategory: SchoolBehaviourCategoryListView;
  handleBehaviourCategoryChange: (behaviourCategoryId: number) => void;
  selectedBehaviourGroupKey: number;
  behaviourCategories: SchoolBehaviourCategoryListView[];
  handleCloseModal: () => void;
  groupId?: number;
  groupType?: GroupType;
}


const BehaviourPointFormWrapper: React.FC<IBehaviourPointFormWrapperProps> = ({
  student,
  selectedCategory,
  handleBehaviourCategoryChange,
  selectedBehaviourGroupKey,
  behaviourCategories,
  handleCloseModal,
  groupId,
  groupType,
}) => {

  const {
    defaultDetention,
    error,
    loading: loadingDefaultDetention,
  } = useAppSelector(state => state.defaultDetention);
  const { detentionSummary } = useAppSelector(state => state.detentionSummary);
  const { schoolInformation } = useAppSelector(state => state.school);
  const { addBehaviourError, loading } = useAppSelector(state => state.behaviour);
  const { loading: loadingDetentionDateCheck, error: dateCheckError } =
  useAppSelector(state => state.preflightDetentionDateCheck);

  // const [dateCheckResponse, setDateCheckResponse] = useState(null);
  const [behaviourCategoriesArray, setBehaviourCategoriesArray] = useState<IDropdownDataItemProps<string, number, null>[]>([]);

  useEffect(() => {
    // setDateCheckResponse(null);

    if (selectedBehaviourGroupKey !== null && behaviourCategories) {
      const categories = behaviourCategories.map(category => ({
        key: category.id,
        label: `${category.codeDisplayName} - ${category.behaviourCategoryName}`,
        value: category.id,
      }));
      setBehaviourCategoriesArray(categories);
    } else {
      setBehaviourCategoriesArray([]);
    }
  }, [selectedBehaviourGroupKey, behaviourCategories]);

  useEffect(() => {
    if (selectedCategory) {
      behaviourActions.getDefaultDetention(
        student.school.id,
        student.id,
        selectedCategory.id
      );
    }
    // setDateCheckResponse(null);
  }, [selectedCategory]);

  useEffect(() => {
    if (!schoolInformation || schoolInformation.id !== student.school.id) {
      schoolActions.getSchoolInformation(student.school.id);
    }
  }, [student]);

  const checkValidations = (incidentDetails: IIncidentDetails, detentionDetails: IDetentionDetails) => {
    const validationErrors = behaviourValidation(
      incidentDetails,
      detentionDetails,
      detentionSummary,
      schoolInformation,
      true
    );

    return validationErrors;
  };

  const handleAddBehaviour = (detentionOptionId: DetentionOption, behaviourDetails: IBehaviourDetails)  => {
    let classId: number = null;
    let classType: GroupType = null;

    if (behaviourDetails.class.id !== -1) {
      classId = behaviourDetails.class.id;
      classType = behaviourDetails.class.type;
    }

    const addBehaviour = (detention: CreateDetention) => {
      const incidentDetails = behaviourDetails.incidentDetails;
      let newPoint: CreateBehaviourCommand = {
        studentId: student.id,
        description: behaviourDetails.internalNotes,
        publicNotes: behaviourDetails.publicNotes,
        categoryId: selectedCategory.id,
        behaviourCodeId: selectedCategory.behaviourCodeId,
        points: behaviourDetails.points,
        outcomeId: behaviourDetails.outcome,
        initialActionId: behaviourDetails.initialAction.initialActionId,
        incidentDate: getDateTime(
          formatDate(incidentDetails.incidentDate),
          incidentDetails.incidentTime
        ),
        roomId: incidentDetails.incidentLocation,
        otherLocation: incidentDetails.otherIncidentLocation,
        periodId: incidentDetails.period,
        createDetention: detention,
      };

      if (classId) {
        newPoint = addGroupIdToPayload(newPoint, classId, classType);
      }

      behaviourActions.addBehaviour(student.id, newPoint, handleCloseModal);
    };

    let detention: CreateDetention = null;
    if (detentionOptionId === DetentionOption.Yes) {
      const detentionDetails = behaviourDetails.detentionDetails;

      const detentionDateTime = getDateTime(
        formatDate(detentionDetails.date),
        detentionDetails.time
      );
      detention = {
        detentionTypeId: detentionDetails.detentionTypeId,
        roomId: detentionDetails.detentionLocationId,
        duration: detentionDetails.duration,
        detentionDate: detentionDateTime,
        detentionNote: null
      };
    }

    addBehaviour(detention);
  };

  if (loading) {
    return <Loader size={Size.Medium} fluid />;
  }

  return (
    <>
      {!selectedCategory && (
        <Message
          text="Please select a behaviour category from the dropdown below."
          color={Swatches.Cancel}
        />
      )}

      <ApiExceptionMessage error={error} />
      <ApiExceptionMessage error={addBehaviourError} />
      <ApiExceptionMessage error={dateCheckError} />

      <Card title="Behaviour Category">
        <Card.Body>
          <Dropdown
            items={behaviourCategoriesArray}
            value={selectedCategory && selectedCategory.id}
            onChange={value => handleBehaviourCategoryChange(value)}
            placeholder="Behaviour Category"
            fluid
          />
        </Card.Body>
      </Card>

      {loadingDefaultDetention ? (
        <Loader size={Size.Medium} fluid />
      ) : (
        <Wrapper className={`${loading ? "loading" : ""}`}>
          {selectedCategory && (
            <BehaviourPointForm
              studentId={student.id}
              selectedCategory={selectedCategory}
              defaultDetention={defaultDetention}
              handleAddBehaviour={handleAddBehaviour}
              handleCloseModal={handleCloseModal}
              group={groupId ? { id: groupId, type: groupType } : null}
              schoolId={student.school.id}
              schoolInformation={schoolInformation}
              checkValidations={checkValidations}
              loading={loading || loadingDetentionDateCheck}
            />
          )}
        </Wrapper>
      )}
    </>
  );
};


export default BehaviourPointFormWrapper;
