import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Size,
  ActionBar,
  Chip,
  StructuredList,
  ValidationMessage,
  Message,
  Button,
  Card,
  TextInput,
  TextInputType,
  Swatches,
  Left,
  Right,
  Modal,
  Dropdown,
  ToastService,
} from "ui-kit";
import lunchRegisterActions from "areas/payments/actions/lunchRegisterActions";
import { arrays } from "utils";
import styled from "styled-components";
import { AddManualLunchCreditCommand } from "../../types/catering/cateringRequests.types";
import { RootState } from "reducers/store";
import { LunchBalanceListView } from "areas/payments/types/catering/cateringResponses.types";


const Wrapper = styled.div`
  .action-bar .dropdown {
    min-width: 100px;
    max-width: 100px;
  }
  .chip {
    width: 100px;
  }
`;


interface IAdjustBalanceModalProps {
  account: LunchBalanceListView;
  schoolId: number;
  open?: boolean;
  onClose?: () => void;
  onSave?: (newBalance: number) => void;
}


const AdjustBalanceModal: React.FC<IAdjustBalanceModalProps> = ({ account, schoolId, open, onClose, onSave }) => {

  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_open, _setOpen] = useState<boolean>(false);
  const [_account, _setAccount] = useState<LunchBalanceListView | null>(null);
  const [data, setData] = useState<AddManualLunchCreditCommand | null>();
  const [operation, setOperation] = useState<number>(0);

  const { actions } = useSelector((state: RootState) => state.lunchHistory);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    _setAccount(account);
    if (account) {
      setData({
        schoolId: schoolId,
        userId: account.user.id,
        total: 0,
        reason: 2,
        notes: null,
      });
    }
  }, [account]);

  const handleClose = () => {
    onClose?.();
  };

  const getNewBalance = () => {
    return operation === 0
      ? Number(_account.balance) + Number(data.total)
      : Number(_account.balance) - Number(data.total);
  };

  const handleSaveAdjustment = () => {
    // Validation
    var errors = [];

    if (data.total === 0 || data.total === null) {
      errors.push(
        "Please enter a number of credits by which the balance should be adjusted."
      );
    }
    if (data.reason === null) {
      errors.push("Please enter a reason for this adjustment.");
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    if (
      window.confirm(
        `This will update the account balance from ${
          _account.balance
        } to ${getNewBalance()}. This cannot be undone. Are you sure?`
      )
    ) {
      lunchRegisterActions.addManualLunchCredit(
        account.user.id,
        {
          ...data,
          schoolId: schoolId,
          total: !operation ? data.total : data.total * -1,
        },
        () => {
          onSave?.(getNewBalance());
          ToastService.pop("Lunch Credits Adjusted", null, "utensils");
        }
      );
    }
  };

  return (
    <Modal
      title="Adjust Lunch Credit Balance"
      open={_open}
      onClose={handleClose}
      width="60%"
      height="60%"
    >
      <Modal.Body>
        {_account ? (
          <>
            <Message text={actions.adjust.error} color={Swatches.Danger} />
            <ValidationMessage errors={validationErrors} />
            <Wrapper>
              <Card>
                <Card.Body>
                  <StructuredList>
                    <StructuredList.Item name="Adjustment" required>
                      <ActionBar low>
                        <Dropdown
                          value={operation}
                          onChange={(value) => setOperation(value)}
                        >
                          <Dropdown.Item value={0} label="Add" />
                          <Dropdown.Item value={1} label="Remove" />
                        </Dropdown>
                        <TextInput
                          fluid
                          type={TextInputType.Number}
                          onChange={(value: number) => {
                            setData({ ...data, total: value })
                          }}
                          suffix="Credits"
                          value={data.total.toString()}
                        />
                      </ActionBar>
                    </StructuredList.Item>
                    <StructuredList.Item name="Current Balance">
                      <Chip
                        colorSwatch={
                          _account.balance > 0
                            ? Swatches.Success
                            : Swatches.Danger
                        }
                        text={_account.balance.toString()}
                      />
                    </StructuredList.Item>
                    <StructuredList.Item name="New Balance">
                      <Chip
                        colorSwatch={
                          getNewBalance() > 0
                            ? Swatches.Success
                            : Swatches.Danger
                        }
                        text={getNewBalance().toString()}
                      />
                    </StructuredList.Item>
                    <StructuredList.Item name="Adjustment Reason" required>
                      <Dropdown
                        fluid
                        value={data.reason}
                        onChange={(value) =>
                          setData({ ...data, reason: value })
                        }
                      >
                        <Dropdown.Item label="Topup" value={0} />
                        <Dropdown.Item label="Opening Balance" value={1} />
                        <Dropdown.Item label="Correction" value={2} />
                        <Dropdown.Item label="Complimentary Lunch" value={3} />
                        <Dropdown.Item label="Other" value={4} />
                      </Dropdown>
                    </StructuredList.Item>
                    <StructuredList.Item name="Notes">
                      <TextInput
                        fluid
                        rows={4}
                        type={TextInputType.Textarea}
                        onChange={(value: string) => setData({ ...data, notes: value })}
                        suffix="Notes"
                        value={data.notes}
                      />
                    </StructuredList.Item>
                  </StructuredList>
                </Card.Body>
              </Card>
            </Wrapper>
          </>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Left>
            <Button
              text="Save Adjustment"
              color={Swatches.Success}
              onClick={handleSaveAdjustment}
              size={Size.Small}
              working={actions.adjust.working}
            />
          </Left>
          <Right>
            <Button
              text="Cancel"
              color={Swatches.Low}
              onClick={handleClose}
              size={Size.Small}
              working={actions.adjust.working}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default AdjustBalanceModal;
