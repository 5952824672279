import React from "react";
import { 
  BorderRadius, 
  neutral 
} from "../index";
import styled, { AnyStyledComponent } from "styled-components";
import { 
  ToastContainer, 
  toast 
} from "react-toastify";

const StyledToastContainer = styled(ToastContainer as AnyStyledComponent).attrs({})`
  .Toastify__toast {
  }
  .Toastify__toast--rtl {
  }
  .Toastify__toast--dark {
  }
  .Toastify__toast--default {
    border-radius: ${BorderRadius.Default}px;
    background: rgba(255, 255, 255, 0.95);
  }
  .Toastify__toast--info {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--error {
  }
  .Toastify__toast-body {
  }

  .Toastify__close-button {
    color: ${neutral[600]};
  }
  .Toastify__close-button--default {
  }
  .Toastify__close-button > svg {
  }
  .Toastify__close-button:hover,
  .Toastify__close-button:focus {
  }
`;

const WrappedToastContainer = () => {
  return <StyledToastContainer position={toast.POSITION.BOTTOM_LEFT} />;
};

export default WrappedToastContainer;
