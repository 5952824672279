import { useState } from "react";
import { Table, EmptyMessage, Chip, Size, Swatches, SplitButton } from "ui-kit";
import { arrays } from "utils";
import { Avatar } from "sharedComponents/common";
import AccountModal from "./accountModal";
import AdjustBalanceModal from "./adjustBalanceModal";
import { PageInfo } from "types/common/paging.types";
import { LunchBalanceListView } from "areas/payments/types/catering/cateringResponses.types";


interface ILunchBalancesTableProps {
  schoolId: number;
  loading?: boolean;
  accounts: LunchBalanceListView[]; 
  error?: boolean;
  onPage?: () => void;
  paging?: PageInfo;
}


const LunchBalancesTable: React.FC<ILunchBalancesTableProps> = ({ schoolId, loading, accounts, error, onPage, paging }) => {

  const [selectedRecord, setSelectedRecord] = useState<LunchBalanceListView | null>(null);
  const [adjustmentModalOpen, setAdjustmentModalOpen] = useState<boolean>(false);
  const [accountModalOpen, setAccountModalOpen] = useState<boolean>(false);

  const handleAccountModalOpen = (record: LunchBalanceListView) => {
    setAccountModalOpen(true);
    setSelectedRecord(record);
  };

  const handleAdjustBalanceModalOpen = (record: LunchBalanceListView) => {
    setAdjustmentModalOpen(true);
    setSelectedRecord(record);
  };

  const handleAccountModalClose = () => {
    setAccountModalOpen(false);
    setSelectedRecord(null);
  };

  const handleAdjustmentModalClose = () => {
    setAdjustmentModalOpen(false);
    setSelectedRecord(null);
  };

  const handleAdjustmentModalSaved = (newBalance: number) => {
    setAdjustmentModalOpen(false);
    setSelectedRecord(null);
  };

  return (
    <>
      <Table
        zebra
        loading={loading}
        empty={arrays.isEmpty(accounts)}
        emptyMessage={
          <EmptyMessage
            icon="users"
            title="No Pupils"
            summary="No pupils were found"
            cover
          />
        }
        error={error}
        grow
      >
        <Table.Header>
          <Table.HeaderCell width={7}>Pupil</Table.HeaderCell>
          <Table.HeaderCell width={1}>Balance</Table.HeaderCell>
          <Table.HeaderCell width={2} />
        </Table.Header>
        <Table.Body onPage={onPage} paging={paging}>
          {accounts?.map((record: LunchBalanceListView, index: number) => (
            <Table.Row key={index}>
              <Table.Cell width={7}>
                <Avatar user={record.user} />
              </Table.Cell>
              <Table.Cell width={1}>
                <Chip
                  fluid
                  colorSwatch={
                    record.balance > 0 ? Swatches.Success : Swatches.Danger
                  }
                  text={record.balance.toString()}
                />
              </Table.Cell>
              <Table.Cell right width={2}>
                <SplitButton
                  size={Size.Small}
                  text="View"
                  color={Swatches.Default}
                  onDefaultClick={() => {
                    handleAccountModalOpen(record);
                  }}
                >
                  <SplitButton.Option
                    onClick={() => {
                      handleAccountModalOpen(record);
                    }}
                    text="View"
                    show={true}
                  />
                  <SplitButton.Option
                    onClick={() => handleAdjustBalanceModalOpen(record)}
                    text="Adjust Balance"
                    show={true}
                  />
                </SplitButton>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <AdjustBalanceModal
        account={selectedRecord}
        schoolId={schoolId}
        open={adjustmentModalOpen && selectedRecord != null}
        onClose={handleAdjustmentModalClose}
        onSave={handleAdjustmentModalSaved}
      />
      <AccountModal
        account={selectedRecord}
        schoolId={schoolId}
        open={accountModalOpen && selectedRecord != null}
        onClose={handleAccountModalClose}
      />
    </>
  );
};

export default LunchBalancesTable;
