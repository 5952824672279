import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import {
  Flyout,
  ActionBar,
  Button,
  Swatches,
  Card,
  EmptyMessage,
  TextInput,
  Message,
  ValidationMessage,
  StructuredList,
} from "ui-kit";
import { arrays } from "utils";
import flyoutActions from "actions/ui/flyouts";
import providerActions from "areas/send/actions/providers/providerActions";
import { RootState } from "reducers/store";
import { ProviderContactDetailView } from "areas/send/types/passportResponse.types";


interface IProviderContactFlyoutProps {
  contact: ProviderContactDetailView;
  onClose?: (contact: ProviderContactDetailView) => void; 
  onSave?: (contact: ProviderContactDetailView) => void; 
}


const ProviderContactFlyout: React.FC<IProviderContactFlyoutProps> = ({ contact, onClose, onSave }) => {

  const { saveError, saving } = useSelector((state: RootState) => state.providerContact);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_contact, _setContact] = useState<ProviderContactDetailView>(contact);

  useEffect(() => {
    _setContact(contact);
    setValidationErrors([]);
  }, [contact]);

  const handleClose = () => {
    flyoutActions.closeFlyout();
    onClose?.(contact);
  };

  const handlePostSave = () => {
    flyoutActions.closeFlyout();
    onSave?.(_contact);
  };

  const handleSave = () => {
    // Validation
    var errors = [];

    if (!_contact.firstName.length) {
      errors.push(`Please enter a first name.`);
    }

    if (!_contact.lastName.length) {
      errors.push(`Please enter a last name.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    if (_contact.id) {
      providerActions.saveProviderContact(_contact, () => {
        handlePostSave();
      });
    } else {
      providerActions.createProviderContact(_contact, () => {
        handlePostSave();
      });
    }
  };

  return (
    <Flyout
      title={`${contact?.id ? "Edit" : "New"} Provider Contact`}
      name={Constants.FLYOUTS.SEND_PROVIDERCONTACT}
      wide
    >
      <Flyout.Body>
        {_contact ? (
          <>
            <Message text={saveError} color={Swatches.Danger} />
            <ValidationMessage errors={validationErrors} />
            <Card>
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="First Name" required>
                    <TextInput
                      value={_contact.firstName}
                      onChange={value =>
                        _setContact({ ..._contact, firstName: value })
                      }
                      maxLength={50}
                      fluid
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="Last Name" required>
                    <TextInput
                      value={_contact.lastName}
                      onChange={value =>
                        _setContact({ ..._contact, lastName: value })
                      }
                      maxLength={50}
                      fluid
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="Email">
                    <TextInput
                      value={_contact.email}
                      onChange={value =>
                        _setContact({ ..._contact, email: value })
                      }
                      maxLength={256}
                      fluid
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="Mobile">
                    <TextInput
                      value={_contact.mobile}
                      onChange={value =>
                        _setContact({ ..._contact, mobile: value })
                      }
                      maxLength={15}
                      fluid
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="Telephone">
                    <TextInput
                      value={_contact.telephone}
                      onChange={value =>
                        _setContact({ ..._contact, telephone: value })
                      }
                      maxLength={15}
                      fluid
                    />
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
          </>
        ) : (
          <EmptyMessage
            icon="hand-holding-seedling"
            title="No Contact"
            summary="No contact has been selected"
            cover
          />
        )}
      </Flyout.Body>
      <Flyout.Footer>
        <ActionBar low>
          <Button
            text="Save"
            onClick={handleSave}
            color={Swatches.Success}
            fluid
            working={saving}
          />
        </ActionBar>
      </Flyout.Footer>
    </Flyout>
  );
};

export default ProviderContactFlyout;
