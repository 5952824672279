import { TermListView } from "areas/send/types/passportResponse.types";
import dateTime from "./dateTime";
import moment from "moment";
import Config from "configuration";
import { DateRange } from "types/common/data.types";


const getCurrentTerm = (terms: TermListView[]): TermListView => {
  return terms.find((term: TermListView) => 
    dateTime.dateRangeIncludes(
      { 
        startDate: dateTime.stringToDate(term.startDate), 
        endDate: dateTime.stringToDate(term.endDate)
      }, 
      new Date()
    )
  );
}


const getAcademicYearTerms = (terms: TermListView[]): TermListView[] => {
  return terms.filter((term: TermListView) => 
    moment(term.startDate).isSameOrAfter(moment(Config.academicYear.currentStartDate)) &&
    moment(term.endDate).isSameOrBefore(moment(Config.academicYear.currentEndDate))
  );
}


const getByDateRange = (terms: TermListView[], dates: DateRange): TermListView => {
  return terms.find((term: TermListView) => 
    moment(term.startDate).isSameOrAfter(moment(dates.startDate)) &&
    moment(term.endDate).isSameOrBefore(moment(dates.endDate))
  );
}


const termsHelper = {
  getCurrentTerm,
  getAcademicYearTerms,
  getByDateRange
}

export default termsHelper;