import accessArrangementReducer from "./accessArrangements/accessArrangementReducer";
import accessArrangementsReducer from "./accessArrangements/accessArrangementsReducer";
import passportReducer from "./passports/passportReducer";
import passportsReducer from "./passports/passportsReducer";
import passportVersionReducer from "./passports/passportVersionReducer";
import providerContactReducer from "./providers/providerContactReducer";
import providerReducer from "./providers/providerReducer";
import providersReducer from "./providers/providersReducer";
import provisionMapCommentReducer from "./provisionMaps/provisionMapCommentReducer";
import provisionMapReducer from "./provisionMaps/provisionMapReducer";
import provisionMapsReducer from "./provisionMaps/provisionMapsReducer";
import customProvisionReducer from "./provisions/customProvisionReducer";
import provisionCostBandReducer from "./provisions/provisionCostBandReducer";
import provisionCostBandsReducer from "./provisions/provisionCostBandsReducer";
import provisionGroupReducer from "./provisions/provisionGroupReducer";
import provisionRecordReducer from "./provisions/provisionRecordReducer";
import provisionReducer from "./provisions/provisionReducer";
import provisionsReducer from "./provisions/provisionsReducer";
import standardProvisionReducer from "./provisions/standardProvisionReducer";
import provisionReportingReducer from "./reporting/reportingReducer";
import sendStudentReducer from "./students/sendStudentReducer";
import sendStudentsReducer from "./students/sendStudentsReducer";
import tagReducer from "./tags/tagReducer";
import tagsReducer from "./tags/tagsReducer";
import sendAdminReducer from "./sendAdmin/sendAdminReducer";

const send = {
  accessArrangement: accessArrangementReducer,
  accessArrangements: accessArrangementsReducer,
  passport: passportReducer,
  passports: passportsReducer,
  passportVersion: passportVersionReducer,
  providerContact: providerContactReducer,
  provider: providerReducer,
  providers: providersReducer,
  provisionMapComment: provisionMapCommentReducer,
  provisionMap: provisionMapReducer,
  provisionMaps: provisionMapsReducer,
  customProvision: customProvisionReducer,
  provisionCostBand: provisionCostBandReducer,
  provisionCostBands: provisionCostBandsReducer,
  provisionGroup: provisionGroupReducer,
  provisionRecord: provisionRecordReducer,
  provision: provisionReducer,
  provisions: provisionsReducer,
  standardProvision: standardProvisionReducer,
  provisionReporting: provisionReportingReducer,
  sendStudent: sendStudentReducer,
  sendStudents: sendStudentsReducer,
  tag: tagReducer,
  tags: tagsReducer,
  sendAdmin: sendAdminReducer,
};

export default send;
