import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import lodashDebounce from "lodash.debounce";
import { useSelector } from "react-redux";
import { Dropdown, Spacing, BorderRadius, neutral } from "ui-kit";
import matriculationActions from "../actions/matriculationActions";
import { RootState } from "reducers/store";
import { PreadmissionListView } from "../types/matriculationResponses.types";


const SearchWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: ${neutral[100]};
  border: 1px solid ${neutral[300]};
  border-radius: ${BorderRadius.Default}px;
  padding: ${Spacing.Default}px;
  margin-bottom: ${Spacing.Small}px;
`;


interface IPreadmissionSearchProps {
  schoolId: number;
  sessionId: number;
  onChange?: (preadmission: PreadmissionListView) => void;
}


const PreadmissionSearch: React.FC<IPreadmissionSearchProps> = ({ schoolId, sessionId, onChange }) => {

  const { results, loading, error } = useSelector((state: RootState) => state.matriculationSessions.searchResults);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    if (searchTerm.length >= 2) {
      debouncedSearchChange(searchTerm);
    }
  }, [searchTerm]);

  const debouncedSearchChange = useCallback(
    lodashDebounce(
      searchTerm =>
        matriculationActions.searchPreadmissionStudents(
          schoolId,
          sessionId,
          searchTerm
        ),
      500
    ),
    []
  );

  return (
    <SearchWrapper>
      <Dropdown
        className="staff-search"
        items={results?.map((preadmission: PreadmissionListView, index: number) => {
          return {
            key: index,
            label: `${preadmission.firstName} ${preadmission.lastName}`,
            value: preadmission,
            sub: `Photographed on ${new Date(
              preadmission.photographedDate
            ).toLocaleDateString("en-UK")}`,
          };
        })}
        autocomplete
        placeholder={"Search Preadmissions"}
        searchPlaceholder={"Search Preadmissions"}
        onSearchChange={val => setSearchTerm(val)}
        onChange={onChange}
        loading={loading}
        error={error}
        fluid
      />
    </SearchWrapper>
  );
};

export default PreadmissionSearch;
