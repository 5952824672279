import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Spacing } from "ui-kit/common/size";
import { neutral } from "ui-kit/common/colors";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${neutral[300]};
  padding: ${Spacing.Medium}px;
  min-width: 180px;
  max-width: 180px;
  .button {
    margin-bottom: ${Spacing.Medium}px;
  }
`;


interface IButtonPanelProps {
  children: React.ReactNode;
}


const ButtonPanel: React.FC<IButtonPanelProps> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};


export default ButtonPanel;
