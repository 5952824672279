import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import {
  fontStyle,
  honeygold,
  neutral,
  Spacing,
  typescale,
  displayFont,
} from "ui-kit";
import { magazineRoutes } from "../../newsRoutes";
import { ArticleCategoryListView } from "areas/news/types/newsResponse.types";


const Wrapper = styled.span`
  background: ${honeygold};
  display: inline-block;
  padding: ${Spacing.ExtraSmall}px ${Spacing.Default}px;
  margin-bottom: 4px;
  ${fontStyle(displayFont.medium, typescale.paragraph, neutral[100])}
  transition: all ease 150ms;

  &:hover {
    background: ${neutral[600]};
    cursor: pointer;
  }
`;

interface IMagazineCategoryChipProps {
  category: ArticleCategoryListView;
}

const MagazineCategoryChip: React.FC<IMagazineCategoryChipProps> = ({ category }) => {

  const navigate = useNavigate();

  const goToCategory = () => {
    navigate(magazineRoutes.getCategoryPath(category.id));
  };

  return category ? (
    // <Wrapper className="article-category-chip" onClick={goToCategory}></Wrapper>
    <Wrapper className="article-category-chip">{category.name}</Wrapper>
  ) : null;
};

export default MagazineCategoryChip;
