import { useState, useEffect } from "react";
import {
  ActionBar,
  DatePicker,
  Message,
  Swatches,
} from "ui-kit";
import { SchoolDropdown } from "sharedComponents/common";
import LunchTypeDropdown from "./lunchTypeDropdown";
import { useSelector } from "react-redux";
import LunchRecordsTable from "./lunchRecordsTable";
import LunchRegisterStatistics from "./lunchRegisterStatistics";
import lunchRegisterActions from "areas/payments/actions/lunchRegisterActions";
import RegisterModal from "./registerModal";
import { RootState } from "reducers/store";
import { files } from "utils";
import TutorGroupDropdown from "./tutorGroupDropdown";
import { GroupListView } from "types/users/userGroups.types";
import { LunchRecordQueryFilter } from "areas/payments/types/catering/cateringRequests.types";
import moment from "moment";


const Registers: React.FC = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);

  const { records, error, loading, filter } = useSelector((state: RootState) => state.lunchRecords);
  const { loading: downloading, error: downloadError } = useSelector((state: RootState) => state.cateringReport);

  const [registerModalOpen, setRegisterModalOpen] = useState<boolean>(false);

  const handleDownload = () => {
    const data: LunchRecordQueryFilter = {
      tutorGroupId: filter.tutorGroupId,
      lunchType: filter.lunchType === -1 
        ? null 
        : filter.lunchType,
      freeSchoolMeal: null,
      pupilPremium: null,
      date: filter.date 
        ? moment(filter.date).format("YYYY-MM-DD") 
        : null,
      schoolId: filter.schoolId,
      universalFreeSchoolMeal: filter.universalFreeSchoolMeal
    };

    lunchRegisterActions.getDailyLunchReport(data, (reportData) => files.openCsv(reportData));
  };

  const getRegisters = () => {

    const data: LunchRecordQueryFilter = {
      tutorGroupId: filter.tutorGroupId,
      lunchType: filter.lunchType === -1 
        ? null 
        : filter.lunchType,
      freeSchoolMeal: null,
      pupilPremium: null,
      date: filter.date 
        ? moment(filter.date).format("YYYY-MM-DD") 
        : null,
      schoolId: filter.schoolId,
      universalFreeSchoolMeal: filter.universalFreeSchoolMeal
    };

    lunchRegisterActions.getLunchRecords(data);
  };

  useEffect(() => {
    if (user && !filter?.schoolId) {
      var schoolId = [user.baseSchool, ...user.schools].filter(
        (x) => x.enableLunchCredits
      )[0]?.id;

      lunchRegisterActions.setRecordsFilter({ ...filter, schoolId: schoolId });
    }
  }, [user]);

  useEffect(() => {
    filter && getRegisters();
  }, [filter]);

  const handleSchoolChange = (value: number) => {
    lunchRegisterActions.setRecordsFilter({ ...filter, schoolId: value });
  };

  const handleTutorGroupChange = (tutorGroup: GroupListView) => {
    lunchRegisterActions.setRecordsFilter({ ...filter, tutorGroupId: tutorGroup?.id });
  };

  const handleDateChange = (value: Date) => {
    lunchRegisterActions.setRecordsFilter({ ...filter, date: value });
  };

  const handleLunchTypeChange = (value: number) => {
    lunchRegisterActions.setRecordsFilter({ ...filter, lunchType: value });
  };

  const handleTakeRegister = () => {
    if (filter.tutorGroupId) {
      setRegisterModalOpen(true);
    }
  };

  return (
    <>
      <ActionBar>
        <SchoolDropdown
          initialValue={filter.schoolId}
          onChange={handleSchoolChange}
          filter={(x) => x.enableLunchCredits}
        />
        <TutorGroupDropdown
          schoolId={filter.schoolId}
          onChange={handleTutorGroupChange}
          showYear
        />
        <LunchTypeDropdown
          initialValue={filter.lunchType}
          onChange={handleLunchTypeChange}
        />
        <DatePicker
          closeOnSelect
          selectedDate={filter.date}
          onChange={handleDateChange}
        />
      </ActionBar>
      <Message text={downloadError} color={Swatches.Danger} />
      <LunchRegisterStatistics records={records} />
      <LunchRecordsTable
        records={records}
        schoolId={filter.schoolId}
        loading={loading}
        error={error != null}
        tutorGroupId={filter?.tutorGroupId}
        downloading={downloading}
        onDownload={handleDownload}
        onTakeRegister={handleTakeRegister}
        showTakeRegisterButton={filter.tutorGroupId != null}
      />
      <RegisterModal
        tutorGroupId={filter?.tutorGroupId}
        date={filter?.date}
        open={registerModalOpen}
        onClose={() => setRegisterModalOpen(false)}
      />
    </>
  );
};

export default Registers;
