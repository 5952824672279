import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import flyoutActions from "actions/ui/flyouts";
import BasketItem from "../../store/basketItem";
import arrays from "utils/arrays";
import {
  Size,
  Swatches,
  Button,
  Loader,
  typescale,
  displayFont,
  EmptyMessage,
  Message,
  Currency,
  Spacing,
  fontStyle,
  ActionBar,
} from "ui-kit";
import UserOrderFlyout from "./userOrderFlyout";
import { Constants } from "configuration";
import { RootState } from "reducers/store";
import paymentsActions from "areas/payments/actions/paymentsActions";
import { BasketItemView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { PaymentUser } from "areas/payments/reducers/payments/paymentsUserReducer";


const Wrapper = styled.div`
  .basket-error {
    margin-bottom: ${Spacing.Default}px;
  }
`;
const Total = styled.div`
  ${fontStyle(displayFont.roman, typescale.header2)}
  text-align: right;
  padding: ${Spacing.Large}px;
`;


interface IUserBasketProps {
  user: PaymentUser;
  hideErrors?: boolean;
  onOrderPlaced?: () => void;
}


const UserBasket: React.FC<IUserBasketProps> = ({ user, hideErrors, onOrderPlaced }) => {

  const { basket, working, errors, refresh } = useSelector((state: RootState) => state.userBasket);

  useEffect(() => {
    user && paymentsActions.getBasket(user.id);
  }, [user]);

  useEffect(() => {
    refresh && paymentsActions.getBasket(user.id);
  }, [refresh]);

  const handleItemDelete = (item: BasketItemView) => {
    paymentsActions.getBasket(user.id);
  };

  const openPlaceOrder = () => {
    flyoutActions.openFlyout(Constants.FLYOUTS.BASKETORDER);
  };

  const handleOrderPlaced = () => {
    onOrderPlaced?.();
  };

  if (errors.load) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem loading the basket"
        cover
      />
    );
  }

  if (!basket || arrays.isEmpty(basket.items)) {
    return (
      <EmptyMessage
        icon="shopping-bag"
        title="Basket Empty"
        summary="There are no items in the basket"
        cover
      />
    );
  }

  return (
    <>
      {working && <Loader cover={true} size={Size.Medium} />}
      <Wrapper>
        {!hideErrors && errors.item && (
          <Message
            className="basket-error"
            text={errors.item}
            icon="times-circle"
            color={Swatches.Danger}
          />
        )}

        {basket.items.map((item, index) => {
          return (
            <BasketItem key={index} userId={user.id} item={item} onDelete={handleItemDelete} />
          );
        })}

        <Total>
          <Currency value={basket.total} />
        </Total>
      </Wrapper>

      <ActionBar low>
        <Button
          text="Place Order"
          onClick={openPlaceOrder}
          color={Swatches.Success}
          fluid
        />
      </ActionBar>

      <UserOrderFlyout
        basket={basket}
        user={user}
        onOrderPlaced={handleOrderPlaced}
      />
    </>
  );
};

export default UserBasket;
