import React, { useRef } from "react";
import styled from "styled-components";
import {
  IUiKitBaseProps,
  IconStyle,
  Size,
  getFontSize,
  neutral,
} from "../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import Badge from "./badge";
import Tooltip from "./tooltip";
import Tippy from "@tippyjs/react";

export interface IIconProps extends IUiKitBaseProps {
  value?: string | IconDefinition;
  iconStyle?: IconStyle;
  size?: Size;
  badge?: number;
  spin?: boolean;
  color?: string;
  hoverColor?: string;
  onClick?(): void;
  light?: boolean;
  regular?: boolean;
  tooltip?: string;
  tooltipSub?: string | React.ReactNode;
  stopPropagation?: boolean;
}

const Wrapper = styled.i<{ size?: Size, hoverColour?: string }>`
  display: inline-block;
  font-style: normal;
  font-size: ${({ size }) => getFontSize(size)}rem;
  position: relative;

  color: ${({ color }) => color};

  ${({ onClick }) =>
    onClick &&
    `
    &:hover {
      cursor: pointer;
    }
  `}

  ${({ hoverColour }) => hoverColour && `
    &:hover {
      color: ${hoverColour};
    }
  `}

  svg {
    margin: 0;
    padding: 0;
    display: block;
  }

  .badge {
    position: absolute;
    right: -35%;
    top: -25%;
    font-size: 40%;
    border: 0.2rem solid white;
  }
`;

const Icon: React.FC<IIconProps> = ({
  value,
  iconStyle,
  size,
  badge,
  spin,
  color = neutral[500],
  hoverColor,
  className,
  style,
  light,
  regular,
  tooltip,
  tooltipSub,
  onClick,
  stopPropagation,
}) => {
  const ref = useRef() as React.RefObject<HTMLElement>;

  let prefix = light ? "fal" : regular ? "far" : "fas";

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    stopPropagation && event.stopPropagation();
    onClick?.();
  };

  return (
    <>
      <Wrapper
        style={style}
        ref={ref}
        className={`icon ${className ? className : ""}`}
        size={size}
        color={color}
        hoverColour={hoverColor}
        onClick={onClick ? handleOnClick : null}
      >
        {value && (
          typeof value === "string"
          ? (
            <FontAwesomeIcon
              icon={[prefix as IconPrefix, value as IconName]}
              spin={spin}
            />
          )
          : (
            <FontAwesomeIcon
              icon={value}
              spin={spin}
            />
          )
        )}
        {badge != null && badge > 0 && <Badge value={badge} />}
      </Wrapper>
      {tooltip != null && (
        <Tippy
          reference={ref}
          content={<Tooltip title={tooltip} sub={tooltipSub} />}
        />
      )}
    </>
  );
};

export default Icon;
