import React, { useEffect, useState } from "react";
import Assignments from "./assignments";
import { users } from "utils";
import StaffAssignments from "./staffAssignments";
import {
  ParentView,
  StaffView,
  StudentView,
} from "sharedComponents/common/users/userTypeView";
import ChildTabs from "sharedComponents/common/users/childTabs";
import { useAppSelector } from "reducers/hooks";
import { Constants } from "configuration";


const AssignmentsWrapper: React.FC = () => {

  const { user } = useAppSelector(state => state.currentUser);
  const [selectedUser, setSelectedUser] = useState<{ id: string, userType: string }>(null);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (users.isParent(user)) {
      setSelectedUser({
        id: user.children[0].id,
        userType: Constants.USER_TYPES.STUDENT
      });
    } else {
      setSelectedUser(user);
    }
  }, [user]);

  const handleChildClicked = (name: string, index: number) => {
    setSelectedUser({
      id: user.children[index].id,
      userType: Constants.USER_TYPES.STUDENT
    });
  };

  const getOffset = () => {
    return user && users.isParent(user) && user.children?.length > 0 ? 40 : 0;
  };

  return (
    <>
      <ParentView>
        <ChildTabs onChildClicked={handleChildClicked} />
        <Assignments user={selectedUser} offset={getOffset()} />
      </ParentView>
      <StudentView>
        <Assignments user={selectedUser} offset={getOffset()} />
      </StudentView>
      <StaffView>
        <StaffAssignments />
      </StaffView>
    </>
  );
};

export default AssignmentsWrapper;
