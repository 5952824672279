import { IBeehiveAction } from "types/common/action.types";
import consentActions from "../../actions/consentActions";
import { ConsentResponseDetailView } from "areas/administration/types/consentResponses.types";


interface IAdminConsentResponseState {
  loading: boolean;
  response: ConsentResponseDetailView | null;
  error: string | null;
}

const CONSENT_STATE: IAdminConsentResponseState = {
  loading: false,
  response: null,
  error: null,
};


const adminConsentResponseReducer = (state = CONSENT_STATE, action: IBeehiveAction) : IAdminConsentResponseState => {

  const { CONSENT_GETRESPONSEFORADMIN } = consentActions.types;

  switch (action.type) {
    case CONSENT_GETRESPONSEFORADMIN.START:
      return { ...state, loading: true, error: null };

    case CONSENT_GETRESPONSEFORADMIN.SUCCESS:
      return {
        ...state,
        response: action.payload,
        loading: false,
      };

    case CONSENT_GETRESPONSEFORADMIN.FAILED:
      return {
        ...state,
        loading: false,
        response: null,
        error: "There was a problem loading the consent response",
      };

    default:
      return state;
  }
};

export default adminConsentResponseReducer;
