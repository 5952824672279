import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  TextInput,
  TextInputType,
  Button,
  Dropdown,
  StructuredList,
  Swatches,
  Card,
  ActionBar,
  Right,
  Size,
} from "ui-kit";

const StudentAwardForm = ({ awardCategories, handleAddAward }) => {
  const [awardContent, setAwardContent] = useState("");
  const [awardNotes, setAwardNotes] = useState("");
  const [awardType, setAwardType] = useState(null);

  const handleButtonOnClick = () => {
    handleAddAward(awardType, awardContent, awardNotes);
    setAwardType(awardType);
    setAwardContent("");
  };

  return (
    <>
      <Card title="Add New Award">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Award Type" required>
              <Dropdown
                placeholder="Award Type"
                fluid
                items={awardCategories}
                onChange={value => setAwardType(value)}
              />
            </StructuredList.Item>
            <StructuredList.Item
              name="Award Details"
              description="Give more details of the award given. Please note, the award details will be included in the email sent to parents."
              required
            >
              <TextInput
                type={TextInputType.Textarea}
                fluid
                placeholder="Award Details"
                value={awardContent}
                onChange={value => setAwardContent(value)}
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      {/* <StructuredList.Item name="Award Other Notes">
            <TextInput
              type={TextInputType.Textarea}
              fluid
              placeholder="Award Notes"
              value={awardNotes}
              onChange={value => setAwardNotes(value)}
            />
          </StructuredList.Item> */}

      <ActionBar low>
        <Right>
          <Button
            text="Add Award"
            color={Swatches.Success}
            disabled={awardType === null || awardContent.trim() === ""}
            onClick={handleButtonOnClick}
            size={Size.Small}
          />
        </Right>
      </ActionBar>
    </>
  );
};

StudentAwardForm.propTypes = {
  awardCategories: PropTypes.array,
  handleAddAward: PropTypes.func,
};

export default StudentAwardForm;
