import React, { useState } from "react";
import flyoutActions from "actions/ui/flyouts";
import {
  Flyout,
  ActionBar,
  Button,
  Swatches,
  Message,
  StructuredList,
  TextInput,
  TextInputType,
  DatePicker,
  ValidationMessage
} from "ui-kit";
import { Constants } from "configuration";
import { arrays } from "utils";
import paymentActions from "areas/payments/actions/paymentsActions";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { CreateReconciliationCommand } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";


interface IReconciliationFlyoutProps {
  onReconciliationSaved?: (reconciliationId: number) => void;
}


const ReconciliationFlyout: React.FC<IReconciliationFlyoutProps> = ({ onReconciliationSaved }) => {

  const INITIAL_VALUE: CreateReconciliationCommand = {
    startDate: new Date(),
    endDate: new Date(),
    total: 0
  };

  const { saving, error: saveError } = useSelector((state: RootState) => state.reconciliation);
  const [_reconciliation, _setReconciliation] = useState<CreateReconciliationCommand>(INITIAL_VALUE);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const handleSaveReconciliation = () => {
    // Validate
    var errors = [];

    if (!_reconciliation?.total || _reconciliation?.total < 0) {
      errors.push(`An valid reconciliation total is required.`);
    }
    if (!_reconciliation?.startDate) {
      errors.push(`An reconciliation start date is required.`);
    }
    if (!_reconciliation?.endDate) {
      errors.push(`An reconciliation end date is required.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    // Save
    paymentActions.createReconciliation(_reconciliation, (returnValue: number) => {
      flyoutActions.closeFlyout();
      onReconciliationSaved?.(returnValue);
      _setReconciliation(INITIAL_VALUE);
    });
  };

  const handleClose = () => {
    flyoutActions.closeFlyout();
    _setReconciliation(INITIAL_VALUE);
  };

  return (
    <Flyout
      title="New Reconciliation"
      name={Constants.FLYOUTS.CREATE_RECONCILIATION}
      wide
    >
      <Flyout.Body>
        <ValidationMessage errors={validationErrors} />
        <Message text={saveError} color={Swatches.Danger} />
        <StructuredList>
          <StructuredList.Item
            name="Start Date"
            required
            description="The start date for the reconciliation period."
          >
            <DatePicker
              dateFormat="DD/MM/YYYY"
              closeOnSelect
              selectedDate={_reconciliation?.startDate}
              onChange={value =>
                _setReconciliation({ ..._reconciliation, startDate: value })
              }
            />
          </StructuredList.Item>
          <StructuredList.Item
            name="End Date"
            description="The end date for the reconciliation period."
            required
          >
            <DatePicker
              dateFormat="DD/MM/YYYY"
              closeOnSelect
              selectedDate={_reconciliation?.endDate}
              onChange={value =>
                _setReconciliation({ ..._reconciliation, endDate: value })
              }
            />
          </StructuredList.Item>
          <StructuredList.Item
            name="Closing Balance"
            description="The closing balance for the reconciliation period."
            required
          >
            <TextInput
              type={TextInputType.Currency}
              value={_reconciliation?.total}
              onChange={value =>
                _setReconciliation({ ..._reconciliation, total: value })
              }
              min={0}
              fluid
            />
          </StructuredList.Item>
        </StructuredList>
      </Flyout.Body>
      <Flyout.Footer>
        <ActionBar low>
          <Button
            text="Save Reconciliation"
            onClick={handleSaveReconciliation}
            color={Swatches.Success}
            fluid
            working={saving}
          />
        </ActionBar>
      </Flyout.Footer>
    </Flyout>
  );
};

export default ReconciliationFlyout;
