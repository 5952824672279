import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  Swatches,
  Button,
  ActionBar,
  Table,
  DetailLabel,
  TextInput,
  Message,
  Checkbox,
  TextInputType,
  ToastService
} from "ui-kit";
import { UserName } from "sharedComponents/common";
import styled from "styled-components";
import { useEffect } from "react";
import paymentActions from "areas/payments/actions/paymentsActions";
import { RootState } from "reducers/store";
import { OrderLineView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import paymentsActions from "areas/payments/actions/paymentsActions";
import { ChangeOrderCommand } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";


const Wrapper = styled.div`
  .change-orderline-amount {
    width: auto !important;
    min-width: auto !important;
    input {
      max-width: 3rem !important;
      width: 3rem !important;
    }
  }

  .change-orderline-cancel {
    justify-content: flex-end;
  }
`;


interface IChangeQuantityModalProps {
  onCancel?: () => void;
  onUpdate?: () => void;
  open: boolean;
}


const ChangeQuantityModal: React.FC<IChangeQuantityModalProps> = ({ onCancel, onUpdate, open }) => {

  const { order, changeError, changing } = useSelector((state: RootState) => state.userOrder);
  const [_lines, _setLines] = useState<OrderLineView[]>([]);

  useEffect(() => {
    _setLines(order.lines);
  }, [order]);

  const handleCancel = () => {
    onCancel?.();
  };

  const handleUpdate = () => {
    var data: ChangeOrderCommand = {
      id: order.id,
      userId: order.user.id,
      lines: _lines.map(line => {
        return {
          id: line.id,
          price: line.unitPrice,
          quantity: line.quantity,
          cancel: line.status ? true : false
        };
      })
    };

    if (
      window.confirm(
        "This will update the order with the adjusted quantities and prices. If all items have been marked for cancellation then the whole order will be cancelled. Are you sure?"
      )
    ) {
      paymentsActions.changeOrder(data, () => {
        ToastService.pop("Order changed successfully", null, "receipt");
        onUpdate?.();
      });
    }
  };

  return (
    <Modal open={open} title="Change Order Quantities">
      <Modal.Body>
        <Wrapper>
          <Message text={changeError} color={Swatches.Danger} />
          <Table>
            <Table.Header>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell width={0.75} right>
                Quantity
              </Table.HeaderCell>
              <Table.HeaderCell width={0.75} right>
                Unit Price
              </Table.HeaderCell>
              <Table.HeaderCell width={0.5} right>
                Cancel
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {_lines.map((line, index) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <DetailLabel
                      bold
                      label={line.description}
                      sub={<UserName user={line.purchasedFor} />}
                    />
                  </Table.Cell>
                  <Table.Cell right>
                    {line.type == "Product" ? (
                      <TextInput
                        className="change-orderline-amount"
                        type={TextInputType.Number}
                        value={line.quantity}
                        min={1}
                        onChange={value => {
                          _setLines(
                            _lines.map(x =>
                              x.id != line.id
                                ? x
                                : {
                                    ...line,
                                    quantity: value
                                  }
                            )
                          );
                        }}
                      />
                    ) : (
                      line.quantity
                    )}
                  </Table.Cell>
                  <Table.Cell width={1} right>
                    <TextInput
                      className="change-orderline-amount"
                      type={TextInputType.Currency}
                      value={line.unitPrice}
                      min={0}
                      onChange={value => {
                        _setLines(
                          _lines.map(x =>
                            x.id != line.id
                              ? x
                              : {
                                  ...line,
                                  unitPrice: value
                                }
                          )
                        );
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell right>
                    <Checkbox
                      className="change-orderline-cancel"
                      checked={line.status === 1}
                      onChange={value => {
                        _setLines(
                          _lines.map(x => x.id != line.id
                            ? x
                            : { ...line, status: (value.checked) ? 1 : 0 }
                          )
                        );
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Wrapper>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Button
            working={changing}
            text="Update"
            color={Swatches.Success}
            onClick={handleUpdate}
          />
          <Button
            working={changing}
            text="Cancel"
            color={Swatches.Default}
            onClick={handleCancel}
          />
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeQuantityModal;
