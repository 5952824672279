import React, { useState } from "react";
import { useSelector } from "react-redux";
import { EmptyMessage, ActionBar, Right } from "ui-kit";
import { Constants } from "configuration";
import GroupStudentsTable from "./groupStudentsTable";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import flyoutActions from "actions/ui/flyouts";
import studentActions from "areas/planner/actions/student/studentActions";
import GroupActionButton from "../groupActionButton";
import CreateOnCallRequestWrapper from "areas/behaviour/components/onCall/createOnCallRequestWrapper";
import CustomGroupEditorModal from "../customGroups/customGroupEditorModal";
import MultipleRewardFlyout from "areas/behaviour/components/rewards/multipleRewardFlyout";
import MultipleBehaviourFlyout from "areas/behaviour/components/behaviours/multipleBehaviours/multipleBehaviourFlyout";
import { useAppSelector } from "reducers/hooks";
import { StudentListView } from "types/users/userListViews.types";
import { GroupType } from "types/planner/groups.types";
import { ICheckboxClickEvent } from "ui-kit/forms/checkbox";
import { studentQuiz } from "utils";
import QuizModal from "../quiz/quizModal";


const GroupStudentsWrapper: React.FC = () => {

  const { group } = useAppSelector(state => state.group);
  const { selected: selectedStudent } = useAppSelector(state => state.student);
  const { errorDeletingGroup } = useAppSelector(state => state.customGroup);

  const [selectedStudents, setSelectedStudents] = useState<StudentListView[]>([]);
  const [openRequestOnCall, setOpenRequestOnCall] = useState<boolean>(false);
  const [openCustomGroupModal, setOpenCustomGroupModal] = useState<boolean>(false);
  const [editGroup, setEditGroup] = useState<boolean>(false);
  const [openQuizModal, setOpenQuizModal] = useState(false);
  const [randomStudent, setRandomStudent] = useState(null);

  const handleStudentClick = (studentId: string) => {
    if (selectedStudent !== studentId) {
      flyoutActions.openFlyout(Constants.FLYOUTS.STUDENT);
      studentActions.selectStudent(studentId);
    }
  };

  const handleStudentCheckBoxChange = (checked: ICheckboxClickEvent<null>, student: StudentListView) => {
    // create a deep copy
    const tempSelectedStudents: StudentListView[] = JSON.parse(JSON.stringify(selectedStudents));
    const index = tempSelectedStudents.findIndex(
      selectedStudent => selectedStudent.id === student.id
    );

    if (index >= 0) {
      tempSelectedStudents.splice(index, 1);
    } else {
      tempSelectedStudents.push(student);
    }

    setSelectedStudents(tempSelectedStudents);
  };

  const handleSelectAllStudents = () => {
    const tempSelectedStudents: StudentListView[] = [];
    group.students.forEach(student => {
      tempSelectedStudents.push(student);
    });
    setSelectedStudents(tempSelectedStudents);
  };

  const handleUnselectAllStudents = () => {
    setSelectedStudents([]);
  };

  const pickRandomStudentFromClass = () => {
    const student = studentQuiz.pickRandomStudent(group.students);
    setRandomStudent(student);
    setOpenQuizModal(true);
  };

  return group?.students?.length > 0 ? (
    <>
      <ApiExceptionMessage error={errorDeletingGroup} />

      <ActionBar>
        <Right>
          <GroupActionButton
            selectedStudents={selectedStudents}
            handleSelectAllStudents={handleSelectAllStudents}
            handleUnselectAllStudents={handleUnselectAllStudents}
            handleAddToCustomGroupClick={() => setOpenCustomGroupModal(true)}
            showAddToCustomGroup={group.type !== GroupType.Custom}
            handleRequestOnCall={() => setOpenRequestOnCall(true)}
            group={group}
            showPickAStudent
            handlePickAStudent={pickRandomStudentFromClass}
          />
        </Right>
      </ActionBar>

      <GroupStudentsTable
        handleStudentClick={handleStudentClick}
        selectedStudents={selectedStudents}
        handleStudentCheckBoxChange={handleStudentCheckBoxChange}
        groupIsPrimary={group.school?.isPrimary}
        students={group.students}
      />

      {openRequestOnCall && (
        <CreateOnCallRequestWrapper
          students={selectedStudents}
          handleUnselectAllStudents={handleUnselectAllStudents}
          groupType={group?.type}
          groupId={group?.id}
          schoolId={group?.school?.id}
          openModal={openRequestOnCall}
          handleCloseModal={() => setOpenRequestOnCall(false)}
        />
      )}

      {openCustomGroupModal && (
        <CustomGroupEditorModal
          open={openCustomGroupModal}
          onClose={() => {
            setOpenCustomGroupModal(false);
            setEditGroup(false);
          }}
          students={selectedStudents}
          handleUnselectAllStudents={handleUnselectAllStudents}
          schoolId={group?.school?.id}
          groupId={editGroup ? group.id : null}
        />
      )}

      <MultipleRewardFlyout
        students={selectedStudents}
        group={group}
        resetSelectedStudents={handleUnselectAllStudents}
      />

      <MultipleBehaviourFlyout
        students={selectedStudents}
        group={group}
        resetSelectedStudents={handleUnselectAllStudents}
      />

      {openQuizModal && (
        <QuizModal
          openModal={openQuizModal}
          setOpenModal={(open) => setOpenQuizModal(open)}
          student={randomStudent}
          setStudent={setRandomStudent}
          group={group}
        />
      )}
    </>
  ) : (
    <EmptyMessage icon="list" title="No students found in selected group" />
  );
};

export default GroupStudentsWrapper;
