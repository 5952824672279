import { useEffect } from "react";
import ReconciliationsFilter from "./reconciliationsFilter";
import paymentActions from "areas/payments/actions/paymentsActions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EmptyMessage, Size, Table, Currency, Label } from "ui-kit";
import Moment from "react-moment";
import { arrays } from "utils";
import { RootState } from "reducers/store";
import { ReconciliationListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


const Reconciliations = () => {

  const { reconciliations, filter, loading, error, paging } = useSelector((state: RootState) => state.reconciliations);
  const navigate = useNavigate();

  useEffect(() => {
    paymentActions.getReconciliations(filter, 0);
  }, []);

  useEffect(() => {
    paymentActions.getReconciliations(filter, 0);
  }, [filter]);

  const goToReconciliation = (reconciliationId: number) => {
    navigate(`/main/shopkeeper/reconciliations/${reconciliationId}`);
  };

  const handleReconciliationSaved = (reconciliationId: number) => {
    goToReconciliation(reconciliationId);
  };

  const handleRowClicked = (reconciliation: ReconciliationListView) => {
    goToReconciliation(reconciliation.id);
  };

  return (
    <>
      <ReconciliationsFilter
        onReconciliationSaved={handleReconciliationSaved}
      />
      <Table
        emptyMessage={
          <EmptyMessage
            icon="receipt"
            title="Not Found"
            summary="No reconciliations were found"
            cover
          />
        }
        empty={arrays.isEmpty(reconciliations)}
        loading={loading}
        error={error != null}
        grow
        zebra
      >
        <Table.Header>
          <Table.HeaderCell width={1}>Start Date</Table.HeaderCell>
          <Table.HeaderCell width={1}>End Date</Table.HeaderCell>
          <Table.HeaderCell right width={8}>
            Closing Balance
          </Table.HeaderCell>
        </Table.Header>
        <Table.Body paging={paging}>
          {reconciliations.map((reconciliation: ReconciliationListView, index: number) => (
            <Table.Row
              onClick={() => handleRowClicked(reconciliation)}
              key={index}
            >
              <Table.Cell width={1}>
                <Moment date={reconciliation.startDate} format="DD/MM/YYYY" />
              </Table.Cell>
              <Table.Cell width={1}>
                <Moment date={reconciliation.endDate} format="DD/MM/YYYY" />
              </Table.Cell>
              <Table.Cell right width={8}>
                <Label bold>
                  <Currency value={reconciliation.total} />
                </Label>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default Reconciliations;
