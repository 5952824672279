import React, { useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import {
  Size,
  TitleSize,
  Loader,
  EmptyMessage,
  Title,
  Chip,
  Card,
  Table,
  neutral,
  Icon,
  Currency,
  Swatches,
  Spacing,
  HeadlineStatistic,
  Subtitle
} from "ui-kit";
import { UserName, CardLogo } from "sharedComponents/common";
import storeActions from "areas/payments/actions/storeActions";
import Moment from "react-moment";
import { RootState } from "reducers/store";
import { OrderLineView, PaymentView, RefundView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


const Wrapper = styled.div`
  .chip {
    margin-bottom: ${Spacing.Large}px;
  }
  .card-logo {
    color: ${neutral[500]};
    font-size: 24px;
  }
`;


const Order = () => {

  let { id } = useParams();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { order, error, loading } = useSelector((state: RootState) => state.storeOrder);

  useEffect(() => {
    user && storeActions.getOrderForUser(user.id, parseInt(id));
  }, []);

  const getLineIcon = (type: string) => {
    switch (type) {
      case "Product":
        return (
          <Icon
            color={Swatches.Default.swatch}
            value="tags"
            size={Size.Medium}
          />
        );
      case "Smartcard":
        return (
          <Icon
            color={Swatches.Default.swatch}
            value="id-card-alt"
            size={Size.Medium}
          />
        );
      case "PrintCredit":
        return (
          <Icon
            color={Swatches.Default.swatch}
            value="print"
            size={Size.Medium}
          />
        );
      default:
        return null;
    }
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem loading your order"
        cover
      />
    );
  }

  if (!order) {
    return (
      <EmptyMessage
        icon="shopping-bag"
        title="Order not found"
        summary="The requested order was not found"
        cover
      />
    );
  }

  return (
    <Wrapper>
      <Title
        size={TitleSize.H2}
        text={`Order ${order.id}`}
        sub={<Moment date={order.date} format="dddd, MMMM Do YYYY" />}
      />

      <Chip text={order.statusName} />

      <HeadlineStatistic>
        <HeadlineStatistic.Item
          icon="receipt"
          label="Total"
          value={<Currency value={order.total} />}
        />
        {order.outstanding > 0 && (
          <>
            <HeadlineStatistic.Item
              icon="receipt"
              label="Paid"
              value={<Currency value={order.paid} />}
            />
            <HeadlineStatistic.Item
              icon="receipt"
              label="Remaning"
              value={<Currency value={order.outstanding} />}
            />
          </>
        )}

        {order.refunded > 0 && (
          <HeadlineStatistic.Item
            value={<Currency value={order.refunded} />}
            label="Refunded"
          />
        )}
      </HeadlineStatistic>

      <Subtitle text="Items Ordered" />
      <Card>
        <Card.Body noPad>
          <Table>
            <Table.Header>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Ordered For</Table.HeaderCell>
              <Table.HeaderCell right>Total</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {order.lines.map((line: OrderLineView, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell>{line.description}</Table.Cell>
                  <Table.Cell>
                    <UserName user={line.purchasedFor} />
                  </Table.Cell>
                  <Table.Cell right>
                    <Currency value={line.total} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card.Body>
      </Card>

      {!arrays.isEmpty(order.payments) ? (
        <>
          <Subtitle text="Payments Made" />
          <Card>
            <Card.Body noPad>
              <Table>
                <Table.Header>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>By</Table.HeaderCell>
                  <Table.HeaderCell>Method</Table.HeaderCell>
                  <Table.HeaderCell>Reference</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell right>Total</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                  {order.payments.map((payment: PaymentView, index: number) => (
                    <Table.Row key={index}>
                      <Table.Cell>
                        <Moment
                          date={payment.createdDate}
                          format="DD/MM/YYYY"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <UserName user={payment.createdBy} />
                      </Table.Cell>
                      <Table.Cell>{payment.transaction.method}</Table.Cell>
                      <Table.Cell>{payment.transaction.reference}</Table.Cell>
                      <Table.Cell>
                        <CardLogo
                          type={payment.transaction.cardType}
                          className="card-logo"
                        />
                      </Table.Cell>
                      <Table.Cell right>
                        <Currency value={payment.total} />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card.Body>
          </Card>
        </>
      ) : (
        <EmptyMessage
          icon="shopping-bag"
          title="No payments made"
          summary="No payments have been made for this order yet"
          cover
        />
      )}

      {!arrays.isEmpty(order.refunds) && (
        <Table caption="Refunds">
          <Table.Header>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>By</Table.HeaderCell>
            <Table.HeaderCell right>Total</Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {order.refunds.map((refund: RefundView, index: number) => (
              <Table.Row key={index}>
                <Table.Cell>
                  <Moment date={refund.createdDate} format="DD/MM/YYYY" />
                </Table.Cell>
                <Table.Cell>
                  <UserName user={refund.refundedBy} />
                </Table.Cell>
                <Table.Cell right>
                  <Currency value={refund.total} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </Wrapper>
  );
};

export default Order;
