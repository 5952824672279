import React, { useState } from "react";
import { arrays } from "utils";
import { EmptyMessage, Table, Currency, Size, Label, honeygold } from "ui-kit";
import { Constants } from "configuration";
import flyoutActions from "actions/ui/flyouts";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { Avatar } from "sharedComponents/common";
import PaymentFlyout from "./paymentFlyout";
import { PaymentView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { UserTinyView } from "types/users/userListViews.types";


interface IPaymentsTableProps {
  payments: PaymentView[];
}


const PaymentsTable: React.FC<IPaymentsTableProps> = ({ payments }) => {

  const navigate = useNavigate();
  const [selectedPayment, setSelectedPayment] = useState<PaymentView | null>(null);


  const handleUserClicked = (user: UserTinyView) => {
    navigate(`/main/shopkeeper/users/${user.id}`);
  };

  const handleRowClicked = (payment: PaymentView) => {
    setSelectedPayment(payment);
    flyoutActions.openFlyout(Constants.FLYOUTS.PAYMENT);
  };

  if (arrays.isEmpty(payments)) {
    return (
      <EmptyMessage
        icon="receipt"
        title="No Payments Found"
        summary="There are no payments for this order"
        cover
      />
    );
  }

  return (
    <>
      <Table>
        <Table.Header>
          <Table.HeaderCell width={1}>ID</Table.HeaderCell>
          <Table.HeaderCell width={1}>Date</Table.HeaderCell>
          <Table.HeaderCell width={2}>By</Table.HeaderCell>
          <Table.HeaderCell width={1}>Method</Table.HeaderCell>
          <Table.HeaderCell width={3}>Reference</Table.HeaderCell>
          <Table.HeaderCell width={1}>Basket</Table.HeaderCell>
          <Table.HeaderCell right width={1}>
            Total
          </Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {payments.map((payment: PaymentView, index: number) => (
            <Table.Row key={index} onClick={() => handleRowClicked(payment)}>
              <Table.Cell>{payment.id}</Table.Cell>
              <Table.Cell width={1}>
                <Moment date={payment.createdDate} format="DD/MM/YYYY HH:mm" />
              </Table.Cell>

              <Table.Cell>
                <Avatar
                  user={payment.createdBy}
                  size={Size.Small}
                  onClick={() => handleUserClicked(payment.createdBy)}
                />
              </Table.Cell>
              <Table.Cell>{payment.transaction.method}</Table.Cell>
              <Table.Cell>
                {(payment.transaction?.method === "Stripe") ? (
                  <a
                    style={{ color: honeygold }}
                    href={`https://dashboard.stripe.com/payments/${
                      payment.transaction.providerTransactionId?.length
                        ? payment.transaction.providerTransactionId
                        : payment.transaction.reference
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {payment.transaction.providerTransactionId?.length
                      ? payment.transaction.providerTransactionId
                      : payment.transaction.reference}
                  </a>
                ) : (
                  payment.transaction.reference
                )}
              </Table.Cell>
              <Table.Cell>{payment.transaction.basketId}</Table.Cell>

              <Table.Cell right width={1}>
                <Label bold>
                  <Currency value={payment.total} />
                </Label>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <PaymentFlyout payment={selectedPayment} />
    </>
  );
};

export default PaymentsTable;
