import React, { useState } from "react";
import PropTypes from "prop-types";
import LayoutGeneratorOptions from "./layoutGeneratorOptions";
import styled from "styled-components";
import { neutral, Swatches } from "ui-kit/common/colors";
import { Constants } from "configuration";
import {
  Flyout,
  Button,
  Spacing,
  fontStyle,
  displayFont,
  typescale,
} from "ui-kit";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import ConfirmModal from "sharedComponents/common/confirmModal";
import flyoutActions from "actions/ui/flyouts";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding-bottom: 20px;
  height: 100%;

  .rearrange-description {
    ${fontStyle(displayFont.medium, typescale.header4, neutral[700])}
    margin-bottom: ${Spacing.Medium}px;
  }
`;

const ButtonBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const LayoutGenerator = ({ generateLayout, error }) => {
  const [selectedLayoutType, setSelectedLayoutType] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [orderValue, setOrderValue] = useState(1);
  const [disperseValue, setDisperseValue] = useState(0);

  const onClickGenerate = () => {
    generateLayout(selectedLayoutType, orderValue, disperseValue);
    setSelectedLayoutType(null);
    setOpenConfirm(false);
    flyoutActions.closeFlyout(Constants.FLYOUTS.REARRANGE_LAYOUT);
  };

  const handleOrderChange = value => {
    setOrderValue(value);
  };

  const handleDisperseChange = event => {
    setDisperseValue(Number(event.target.value));
  };

  return (
    <Wrapper className="layout-generator">
      <h4 className="rearrange-description">
        To re-arrange the seating plan, choose a plan from the options below
      </h4>
      <ApiExceptionMessage error={error} />

      <LayoutGeneratorOptions
        selectedLayoutType={selectedLayoutType}
        onSelectLayoutType={setSelectedLayoutType}
        orderValue={orderValue}
        handleOrderChange={handleOrderChange}
        disperseValue={disperseValue}
        handleDisperseChange={handleDisperseChange}
      />

      <Flyout.Footer>
        <ButtonBarWrapper>
          <Button
            color={Swatches.Success}
            text="Rearrange seating plan"
            disabled={selectedLayoutType === null}
            onClick={() => setOpenConfirm(true)}
          />
        </ButtonBarWrapper>
      </Flyout.Footer>

      <ConfirmModal
        openModal={openConfirm}
        onOpen={() => setOpenConfirm(true)}
        onClose={() => setOpenConfirm(false)}
        onConfirm={onClickGenerate}
        confirmMsg="This will change your seating plan. Are you sure you want to do
          this? (You need to click save on the next screen to save the new
          seating plan)"
      />
    </Wrapper>
  );
};

LayoutGenerator.propTypes = {
  generateLayout: PropTypes.func,
  openGenerateLayout: PropTypes.bool,
  error: PropTypes.string,
};

export default LayoutGenerator;
