import React, { CSSProperties, useEffect, useState } from "react";
import { RadialChart } from "react-vis";
import styled from "styled-components";
import { EmptyMessage, Spacing } from "ui-kit";
import BarChart, { BarChartPoint } from "../barChart";
import { BehaviourReportView } from "areas/analytics/types/classGroupBehaviourAnalyticsResponse.types";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .pie-chart {
    align-self: center;
  }

  .bar-charts {
    padding: ${Spacing.ExtraLarge + 40}px 0;
    // display: flex;
    // justify-content: space-between;
    align-self: center;
  }
`;


interface IClassBehaviourChartsProps {
  classBehaviourData: BehaviourReportView;
}


const ClassBehaviourCharts: React.FC<IClassBehaviourChartsProps> = ({ classBehaviourData }) => {

  const [rewardData, setRewardData] = useState<BarChartPoint[]>([]);
  const [behaviourData, setBehaviourData] = useState<BarChartPoint[]>([]);
  const [pieChartData, setPieChartData] = useState<Array<{
      angle?: number | undefined;
      className?: string | undefined;
      label?: string | undefined;
      radius?: number | undefined;
      style?: CSSProperties | undefined;
  }>>([]);

  useEffect(() => {
    if (classBehaviourData) {
      const tempRewardsData = classBehaviourData.totalRewardsByType.map(
        reward => ({
          x: reward.codeName,
          y: reward.total,
          label: `${reward.codeName} - ${reward.categoryName}`,
        })
      );

      const tempBehavioursData = classBehaviourData.totalBehavioursByType.map(
        behaviour => ({
          x: behaviour.codeName,
          y: behaviour.total,
          label: `${behaviour.codeName} - ${behaviour.categoryName}`,
        })
      );

      setRewardData(tempRewardsData);
      setBehaviourData(tempBehavioursData);

      const totalPoints =
        classBehaviourData.totalBehaviourPoints +
        classBehaviourData.totalRewardPoints;

      const rewardRatio =
        classBehaviourData.totalRewardPoints > 0 &&
        Math.round((classBehaviourData.totalRewardPoints / totalPoints) * 100);

      const behaviourRatio =
        classBehaviourData.totalBehaviourPoints > 0 &&
        Math.round(
          (classBehaviourData.totalBehaviourPoints / totalPoints) * 100
        );

      const tempPieChartData = [];

      const totalBehaviours = classBehaviourData.totalBehaviourPoints;
      const totalRewards = classBehaviourData.totalRewardPoints;

      if (behaviourRatio) {
        tempPieChartData.push({
          angle: totalBehaviours,
          label: `${
            behaviourRatio
              ? `${totalBehaviours} Behaviour${
                  totalBehaviours > 1 ? "s" : ""
                } (${behaviourRatio}%)`
              : ""
          }`,
          color: "#990000",
        });
      }

      if (rewardRatio) {
        tempPieChartData.push({
          angle: totalRewards,
          label: `${
            rewardRatio
              ? `${totalRewards} Reward${
                  totalRewards > 1 ? "s" : ""
                } (${rewardRatio}%)`
              : ""
          }`,
          color: "#339900",
        });
      }

      setPieChartData(tempPieChartData);
    }
  }, [classBehaviourData]);

  return (
    <Wrapper>
      {classBehaviourData.totalBehaviourPoints +
        classBehaviourData.totalRewardPoints ===
      0 ? (
        <EmptyMessage
          title="No rewards or behaviours in the selected time period"
          icon="list"
        />
      ) : (
        <>
          {/* <Title text="Rewards & Behaviours" size={TitleSize.H2} /> */}
          <div className="pie-chart">
            <RadialChart
              colorType="literal"
              data={pieChartData}
              width={475}
              height={475}
              showLabels
              labelsRadiusMultiplier={0.8}
              labelsStyle={{
                fill: "#ffffff",
                font: "600 14px neue-haas-grotesk-display",
              }}
            />
          </div>

          <div className="bar-charts">
            {classBehaviourData.totalRewardPoints > 0 && (
              <BarChart
                chartTitle="Rewards"
                data={rewardData}
                colour="#339900"
                horizontal
              />
            )}

            {classBehaviourData.totalBehaviourPoints > 0 && (
              <BarChart
                chartTitle="Behaviours"
                data={behaviourData}
                colour="#990000"
                horizontal
              />
            )}
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default ClassBehaviourCharts;
