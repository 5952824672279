import { IBeehiveAction } from "types/common/action.types";
import onCallReportActions from "../actions/onCallReportActions";
import { IBeehiveError } from "types/common/errors.types";
import { PagedQueryView } from "types/common/views.types";
import { OnCallRequestDetailView } from "./onCallAnalyticsResponse.types";


interface IOnCallReportState {
  report: PagedQueryView<OnCallRequestDetailView>;
  refresh: boolean;
  loading: boolean;
  loadingExportReport: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IOnCallReportState = {
  report: null,
  refresh: false,
  loading: false,
  loadingExportReport: false,
  error: null,
};


const onCallReportReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IOnCallReportState => {

  const { 
    GETONCALLREPORT, 
    EXPORTONCALLREPORT 
  } = onCallReportActions.types;

  switch (action.type) {
    case GETONCALLREPORT.START:
      return { ...state, loading: true, error: null };

    case GETONCALLREPORT.SUCCESS:
      return { ...state, report: action.payload, loading: false };

    case GETONCALLREPORT.FAILED:
      return { ...state, loading: false, error: action.payload };

    case EXPORTONCALLREPORT.START:
      return { ...state, loadingExportReport: true, error: null };

    case EXPORTONCALLREPORT.SUCCESS:
      return { ...state, loadingExportReport: false };

    case EXPORTONCALLREPORT.FAILED:
      return { ...state, loadingExportReport: false, error: action.payload };

    default:
      return state;
  }
};

export default onCallReportReducer;
