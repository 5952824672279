import types from "actions/ui/uiTypes";
import { IBeehiveAction } from "types/common/action.types";


interface IUiState {
  activeFlyout: string | null;
  upgradeUi: boolean;
}

const INITIAL_STATE: IUiState = {
  activeFlyout: null,
  upgradeUi: false,
};


const uiReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUiState => {

  switch (action.type) {
    case types.FLYOUT_OPEN:
      return { ...state, activeFlyout: action.payload };
    case types.FLYOUT_CLOSE:
      return { ...state, activeFlyout: null };
    case types.UPGRADE_UI:
      return { ...state, upgradeUi: true };
    default:
      return state;
  }
};


export default uiReducer;
