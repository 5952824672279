import moment from "moment";
import React from "react";
import { Table, Loader, Size, EmptyMessage } from "ui-kit";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { useAppSelector } from "reducers/hooks";


const SmartTaskReportTable = () => {

  const { report, loading, error } = useAppSelector(
    state => state.smartTaskReport
  );

  if (loading) {
    return <Loader size={Size.Large} fluid />;
  }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return !report ? null : report?.length > 0 ? (
    <Table>
      <Table.Header>
        <Table.HeaderCell>Date</Table.HeaderCell>
        <Table.HeaderCell>Smart Task</Table.HeaderCell>
        <Table.HeaderCell>Student</Table.HeaderCell>
        <Table.HeaderCell>Student SIMS ID</Table.HeaderCell>
        <Table.HeaderCell>Tutor Group</Table.HeaderCell>
      </Table.Header>

      <Table.Body>
        {report.map((smartTask, index) => (
          <Table.Row>
            <Table.Cell>
              {moment(smartTask.awardDate).format("DD/MM/YYYY")}
            </Table.Cell>
            <Table.Cell>{smartTask.smartTaskName}</Table.Cell>
            <Table.Cell>
              <b>{`${smartTask.student.firstName} ${smartTask.student.lastName}`}</b>
            </Table.Cell>
            <Table.Cell>{smartTask.student.simsId}</Table.Cell>
            <Table.Cell>{smartTask.tutorGroup}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <EmptyMessage title="No Activities Found" icon="list" />
  );
};

export default SmartTaskReportTable;
