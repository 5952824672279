import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { DetentionTypeListView } from "areas/behaviour/types/behaviourResponses.types";
import client from "services/client";
import { callbackType } from "types/common/action.types";

const builder = new actionTypeBuilder("detentionTypes");

const types = {
  GETDETENTIONTYPES: builder.build("getdetentiontypes"),
};

const getDetentionTypes = (schoolId: number, onSuccess?: callbackType<DetentionTypeListView[]>) => {
  return action<DetentionTypeListView[]>(
    () => client.get(`planner/schools/${schoolId}/detentionTypes`),
    types.GETDETENTIONTYPES,
    onSuccess
  );
};

const detentionTypesActions = {
  types,
  getDetentionTypes,
};

export default detentionTypesActions;
