import React from "react";
import { Tabs } from "ui-kit";
import Dashboard from "./dashboard";
import Roles from "./roles";
import Reporting from "./reporting";
import administrationActions from "../actions/administrationActions";
import { Constants } from "configuration";
import { useAppSelector } from "reducers/hooks";
import { users } from "utils";
import Sync from "./sync";


const Administration = () => {

  const { selectedTab } = useAppSelector(state => state.administrationHome);
  const { user } = useAppSelector(state => state.currentUser);

  const handleTabSelected = (name: string, index: number) => {
    administrationActions.switchAdministrationTab(index);
  };

  const userIsIt = () => {
    return (
      users.isInRole(user, Constants.ROLES.IT_USER) ||
      users.isInRole(user, Constants.ROLES.IT_ADMINISTRATOR)
    )
  }

  const userIsHr = () => {
    return (
      users.isInRole(user, Constants.ROLES.HR_ADMINISTRATOR)
    )
  }

  const userIsProfileAdmin = () => {
    return (
      users.isInRole(user, Constants.ROLES.STUDENTPROFILE_ADMINISTRATOR)
    );
  }


  const userIsSyncAdmin = () => {
    return (
      users.isInRole(user, Constants.ROLES.DEVELOPMENT) ||
      users.isInRole(user, Constants.ROLES.SYNCDATA_ADMINISTRATOR)
    )
  }

  return (
    <>
      <Tabs onTabSelected={handleTabSelected} initialTabIndex={selectedTab}>
        <Tabs.Pane 
          name="Dashboard" 
          label="Dashboard" 
          hide={!userIsIt() && !userIsHr() && !userIsProfileAdmin()}
        >
          <Dashboard />
        </Tabs.Pane>
        <Tabs.Pane 
          name="Reporting" 
          label="Reporting" 
          hide={!userIsIt()}
        >
          <Reporting />
        </Tabs.Pane>
        <Tabs.Pane 
          name="Roles" 
          label="Roles" 
          hide={!userIsIt()}
        >
          <Roles />
        </Tabs.Pane>
        <Tabs.Pane 
          name="Sync" 
          label="Sync" 
          hide={!userIsSyncAdmin() && !userIsIt() && !userIsHr()}
        >
          <Sync/>
        </Tabs.Pane>
      </Tabs>
    </>
  );
};

export default Administration;
