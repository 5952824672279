import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  TimePicker,
  StructuredList,
  Dropdown,
  Checkbox,
  TextInput,
} from "ui-kit";
import { Constants } from "configuration";
import ValidDatePicker from "sharedComponents/common/validDatePicker";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { IIncidentDetails } from "areas/behaviour/utils/detentions";
import moment from "moment";
import { SpecialDayType } from "areas/planner/types/termDatesShared.types";


interface IIncidentFormProps {
  periodDropdownArray: IDropdownDataItemProps<string, number, null>[];
  roomsDropdownArray: IDropdownDataItemProps<string, number, null>[];
  incidentDetails: IIncidentDetails;
  setIncidentDetails: (details: IIncidentDetails) => void;
  schoolId: number;
}


const IncidentForm: React.FC<IIncidentFormProps> = ({
  periodDropdownArray,
  roomsDropdownArray,
  incidentDetails,
  setIncidentDetails,
  schoolId,
}) => {

  const [otherIncidentLocationChecked, setOtherIncidentLocationChecked] =
    useState<boolean>(false);

  useEffect(() => {
    if (incidentDetails.otherIncidentLocation) {
      setOtherIncidentLocationChecked(true);
    } else {
      setOtherIncidentLocationChecked(false);
    }
  }, [incidentDetails.otherIncidentLocation]);

  useEffect(() => {
    if (otherIncidentLocationChecked) {
      setIncidentDetails({
        ...incidentDetails,
        incidentLocation: null,
      });
    } else {
      setIncidentDetails({
        ...incidentDetails,
        otherIncidentLocation: null,
      });
    }
  }, [otherIncidentLocationChecked]);

  return (
    <>
      <StructuredList.Item name="Period">
        <Dropdown
          placeholder="Choose Period"
          fluid
          items={periodDropdownArray}
          value={incidentDetails.period}
          onChange={value =>
            setIncidentDetails({
              ...incidentDetails,
              period: value,
            })
          }
          // clearable
        />
      </StructuredList.Item>

      <StructuredList.Item name="Incident Location">
        <Dropdown
          placeholder="Choose Location"
          fluid
          items={roomsDropdownArray}
          value={incidentDetails.incidentLocation}
          onChange={value =>
            setIncidentDetails({
              ...incidentDetails,
              incidentLocation: value,
            })
          }
          disabled={otherIncidentLocationChecked}
          clearable
        />
      </StructuredList.Item>

      <StructuredList.Item name="Other Location">
        <Checkbox
          checked={otherIncidentLocationChecked}
          onChange={() =>
            setOtherIncidentLocationChecked(!otherIncidentLocationChecked)
          }
        />
        {otherIncidentLocationChecked && (
          <TextInput
            placeholder="Please enter details"
            value={incidentDetails.otherIncidentLocation}
            onChange={value =>
              setIncidentDetails({
                ...incidentDetails,
                otherIncidentLocation: value,
              })
            }
          />
        )}
      </StructuredList.Item>

      <StructuredList.Item name="Incident Date">
        {/* <DatePicker
          dateFormat="DD/MM/YYYY"
          selectedDate={incidentDetails.incidentDate}
          onChange={date =>
            setIncidentDetails({
              ...incidentDetails,
              incidentDate: date
            })
          }
          closeOnSelect
        /> */}

        <ValidDatePicker
          dateFormat="DD/MM/YYYY"
          selectedDate={moment(incidentDetails.incidentDate).toDate()}
          onChange={date =>
            setIncidentDetails({
              ...incidentDetails,
              incidentDate: moment(date).format(),
            })
          }
          closeOnSelect
          // validate
          allowSpecialDays={[
            SpecialDayType.Religious,
            SpecialDayType.Other,
          ]}
          schoolId={schoolId}
        />
      </StructuredList.Item>

      <StructuredList.Item name="Incident Time">
        <TimePicker
          initialValue={incidentDetails.incidentTime}
          onChange={time =>
            setIncidentDetails({
              ...incidentDetails,
              incidentTime: time,
            })
          }
        />
      </StructuredList.Item>
    </>
  );
};


export default IncidentForm;
