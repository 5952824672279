import React from "react";
import styled from "styled-components";
import { Chip, Swatches, displayFont, Spacing } from "ui-kit";

const Wrapper = styled.div<{small?: boolean, compact?: boolean}>`
  ${({ small }) =>
    small &&
    `.chip {
    height: fit-content;
    width: fit-content;
    padding: 0 ${Spacing.Small}px;
    margin: ${Spacing.Small}px;
    font-size: 11px;
    font-family: ${displayFont.roman.name};
    letter-spacing: 1px;
    font-weight: unset;
  }`}

  ${({ compact }) =>
    compact &&
    `
  .chip { 
    margin-left: 0;
    padding-left: ${Spacing.Small}px;
    padding-right: ${Spacing.Small}px;
 }
  `}
`;


interface IActiveOnCallChipProps {
  small?: boolean;
  compact?: boolean;
}


const ActiveOnCallChip: React.FC<IActiveOnCallChipProps> = ({ small, compact }) => {

  return (
    <Wrapper small={small} compact={compact}>
      <Chip
        className="active-oncall-chip"
        text="On Call"
        colorSwatch={Swatches.Danger}
        tooltip="Active On Call"
        tooltipSub="The student has an active on call request"
      />
    </Wrapper>
  );
};

export default ActiveOnCallChip;
