import React from "react";
import styled from "styled-components";
import { 
  Label, 
  Icon, 
  Swatches, 
  Chip, 
  Spacing, 
  Size, 
  Tooltip 
} from "ui-kit";
import Tippy from "@tippyjs/react";
import { TelephoneNumberListView } from "types/users/userContact.types";

const Wrapper = styled.span`
  i {
    margin-right: ${Spacing.Default}px;
    width: 15px;
  }

  .chip {
    margin-left: ${Spacing.Small}px;
  }
`;


interface ITelephoneNumberProps {
  number: TelephoneNumberListView;
}

const TelephoneNumber: React.FC<ITelephoneNumberProps> = ({ number }) => {

  const getIcon = () => {
    switch (number.location) {
      case "Home":
        return "home";
      case "Work":
        return "industry";
      case "Mobile":
        return "mobile-alt";
      default:
        return "phone";
    }
  };

  const format = (n: string) => {
    return n.replace(/\s+/g, "").replace(/(.)(\d{4})(\d)/, "$1$2 $3");
  };

  return (
    <Tippy
      content={
        <Tooltip
          title={`${number.location} Phone Number`}
          sub={`This is a ${number.location} Phone Number${
            number.isPrimary ? " and also the primary phone number." : "."
          }`}
        />
      }
    >
      <Wrapper>
        <Icon value={getIcon()} />
        <Label>{format(number.number)}</Label>
        {number.isPrimary && (
          <Chip text="Primary" colorSwatch={Swatches.Low} size={Size.Small} />
        )}
      </Wrapper>
    </Tippy>
  );
};

export default TelephoneNumber;
