import React from "react";
import { ReactComponent as StripeBlackSvg } from "assets/logos/stripe-black.svg";
import { ReactComponent as StripeWhiteSvg } from "assets/logos/stripe-white.svg";


interface IStripeLogoProps {
  width?: number;
  height?: number;
  style?: object;
}


export const StripeBlackLogo: React.FC<IStripeLogoProps> = ({ width, height, style }) => {
  return (
    <StripeBlackSvg
      //alt="Powered by Stripe"
      className="stripe-logo"
      style={{
        width: width,
        height: height,
        ...style,
        opacity: 0.5
      }}
    />
  );
};


export const StripeWhiteLogo: React.FC<IStripeLogoProps> = ({ width, height, style }) => {
  return (
    <StripeWhiteSvg
      //alt="Powered by Stripe"
      className="stripe-logo"
      style={{ 
        width: width, 
        height: height, 
        ...style 
      }}
    />
  );
};
