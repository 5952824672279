import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  ActionBar,
  Button,
  Size,
  Swatches,
  Modal,
  Card,
  StructuredList,
  Checkbox,
  Dropdown,
  Message,
  ToastService,
} from "ui-kit";
import { SchoolDropdown } from "sharedComponents/common";
import { users } from "utils";
import { Constants } from "configuration";
import matriculationActions from "../actions/matriculationActions";
import { RootState } from "reducers/store";
import { CreateMatriculationSessionStatusCommand } from "../types/matriculationRequests.types";
import config from "configuration/config";


const SessionsFilter = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { filter } = useSelector((state: RootState) => state.matriculationFilter);
  const { sessions } = useSelector((state: RootState) => state.matriculationSessions);
  const [newSession, setNewSession] = useState<CreateMatriculationSessionStatusCommand | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    matriculationActions.updateMatriculationFilter({
      ...filter,
      schoolId: user.baseSchool.id,
    });
  }, []);

  const handleSchoolChange = (schoolId: number) => {
    matriculationActions.updateMatriculationFilter({
      ...filter,
      schoolId: schoolId,
    });
  };

  const handleCreateNewSession = () => {
    setNewSession({
      academicYearId: -1,
      schoolId: -1,
      isActive: false,
    });
  };

  const submitNewSession = () => {
    setError(null);
    if (!filter?.schoolId || newSession.academicYearId === -1) {
      return;
    }
    if (sessions != null) {
      if (
        sessions.findIndex(
          session => session.academicYear.id === newSession.academicYearId
        ) !== -1
      ) {
        //alert("A session for this academic year already exists");
        setError("A session for this academic year already exists");
        return;
      }
    }
    matriculationActions.createNewSession(
      filter.schoolId,
      newSession,
      () => ToastService.pop("New Session Created", null, "check-circle")
    );
    setNewSession(null);
    setError(null);
  };

  const closeModal = () => {
    setNewSession(null);
    setError(null);
  };

  return (
    <>
      <ActionBar>
        <SchoolDropdown
          fluid
          schools={[...user.schools]}
          includeAllOption={false}
          includeTrust={false}
          initialValue={filter?.schoolId}
          onChange={handleSchoolChange}
        />
        {(users.isInRole(user, Constants.ROLES.MATRICULATION_ADMINISTRATOR) ||
          users.isInRole(user, Constants.ROLES.IT_ADMINISTRATOR)) &&
          !sessions?.some(x => x.academicYear.id == config.academicYear.current) && (
          <Button
            size={Size.Small}
            text="Create New Session"
            color={Swatches.Primary}
            onClick={handleCreateNewSession}
            tooltip="Create a new matriculation session"
          />
        )}
      </ActionBar>
      <Modal
        title="Create New Matriculation Session"
        open={newSession != null}
        onClose={closeModal}
      >
        <Modal.Body>
          {error && <Message text={error} color={Swatches.Danger} />}
          <Card>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item
                  name="Session Intake Year"
                  description="Please choose the intake year for the matriculation session"
                  required
                >
                  <Dropdown
                    value={newSession?.academicYearId}
                    onChange={yearId =>
                      setNewSession({ ...newSession, academicYearId: yearId })
                    }
                    fluid
                  >
                    <Dropdown.Item label="2023/24" value={10} />
                    <Dropdown.Item label="2024/25" value={11} />
                  </Dropdown>
                </StructuredList.Item>
                <StructuredList.Item
                  name="Session is Active"
                  description="Please select if the new submission is to immediately be marked as active"
                  required
                >
                  <Checkbox
                    checked={newSession?.isActive}
                    onChange={status =>
                      setNewSession({ ...newSession, isActive: status.checked })
                    }
                  />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <ActionBar low>
            <Button
              text="Cancel"
              onClick={closeModal}
              color={Swatches.Low}
              size={Size.Small}
            />
            <Button
              text="Create Session"
              onClick={submitNewSession}
              color={Swatches.Success}
              size={Size.Small}
            />
          </ActionBar>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SessionsFilter;
