import { IBeehiveAction } from "types/common/action.types";
import provisionGroupsActions from "../../actions/provisions/provisionGroupsActions";
import provisionsActions from "../../actions/provisions/provisionsActions";
import { StandardProvisionDetailView } from "areas/send/types/passportResponse.types";

interface IStandardProvisionState {
  provision: StandardProvisionDetailView | null;
  loading: boolean;
  error: string | null;
  saving: boolean;
  saveError: string | null;
  deleting: boolean;
  deleteError: string | null;
  newId: number | null;
}

const INITIAL_STATE: IStandardProvisionState = {
  provision: null,
  loading: false,
  saving: false,
  error: null,
  saveError: null,
  deleting: false,
  deleteError: null,
  newId: null,
};


const standardProvisionReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IStandardProvisionState => {
  const {
    PROVISIONS_GETSTANDARD,
    PROVISIONS_CREATESTANDARD,
    PROVISIONS_SAVESTANDARD,
  } = provisionsActions.types;
  const {
    PROVISIONGROUPS_CREATE,
    PROVISIONGROUPS_SAVE,
    PROVISIONGROUPS_DELETE,
    PROVISIONGROUPS_STATUSCHANGE,
  } = provisionGroupsActions.types;

  switch (action.type) {
    case PROVISIONS_GETSTANDARD.START:
      return { ...state, loading: true };
    case PROVISIONS_GETSTANDARD.SUCCESS:
      return { ...state, loading: false, provision: action.payload };
    case PROVISIONS_GETSTANDARD.FAILED:
      return { ...state, loading: false, error: action.payload };

    case PROVISIONS_CREATESTANDARD.START:
      return { ...state, saving: true, saveError: null };
    case PROVISIONS_CREATESTANDARD.SUCCESS:
      return { ...state, saving: false, newId: action.payload.id };
    case PROVISIONS_CREATESTANDARD.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem creating the provision",
      };

    case PROVISIONS_SAVESTANDARD.START:
      return { ...state, saveError: null, deleteError: null, saving: true };

    case PROVISIONS_SAVESTANDARD.SUCCESS:
      return {
        ...state,
        saving: false,
        provision:
          action.payload.id === state.provision?.id
            ? { ...state.provision, ...action.payload }
            : state.provision,
      };
    case PROVISIONS_SAVESTANDARD.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the provision",
      };

    case PROVISIONGROUPS_CREATE.SUCCESS:
      return {
        ...state,
        provision: {
          ...state.provision,
          groups: [action.payload, ...state.provision?.groups],
        },
      };

    case PROVISIONGROUPS_SAVE.SUCCESS:
      return {
        ...state,
        provision: {
          ...state.provision,
          groups: state.provision?.groups?.map(a =>
            a.id === action.payload.id ? action.payload : a
          ),
        },
      };

    case PROVISIONGROUPS_DELETE.SUCCESS:
      return {
        ...state,
        provision: {
          ...state.provision,
          groups: state.provision?.groups?.filter(a => a.id !== action.payload),
        },
      };

    case PROVISIONGROUPS_STATUSCHANGE.START:
      return { ...state, saving: true };
    case PROVISIONGROUPS_STATUSCHANGE.SUCCESS:
      return {
        ...state,
        provision: {
          ...state.provision,
          groups: state.provision?.groups?.map(a =>
            a.id === action.payload.id ? action.payload : a
          ),
        },
      };
    case PROVISIONGROUPS_STATUSCHANGE.FAILED:
      return { ...state, saving: false, error: action.payload };

    default:
      return state;
  }
};

export default standardProvisionReducer;
