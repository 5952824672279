import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  EmptyMessage,
  Button,
  Size,
  Loader,
  DateTime,
  Swatches,
} from "ui-kit";
import { Avatar } from "sharedComponents/common";
import { arrays } from "utils";
import FeedbackViewModal from "./feedbackViewModal";
import feedbackActions from "areas/feed/actions/feedbackActions";
import { RootState } from "reducers/store";
import { FeedbackMessageListView } from "areas/feed/types/feedbackResponses.types";


const Feedback = () => {

  const [_selectedMessage, _setSelectedMessage] = useState<FeedbackMessageListView | null>(null);
  const { messages, loading, error, paging } = useSelector(
    (state: RootState) => state.feedbackMessages
  );

  const getMessages = (pageIndex: number) => {
    feedbackActions.getFeedbackMessages(pageIndex);
  };

  useEffect(() => {
    getMessages(0);
  }, []);

  const handleViewDetails = (message: FeedbackMessageListView) => {
    _setSelectedMessage(message);
  };

  const handleModalClosed = () => {
    _setSelectedMessage(null);
  };

  if (loading) {
    return <Loader size={Size.Medium} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occcured"
        cover
        summary="There was a problem retrieving the feedback messages"
        icon="times-circle"
      />
    );
  }

  if (arrays.isEmpty(messages)) {
    return (
      <EmptyMessage
        cover
        title="No Messages"
        summary="No feedback messages have been submitted"
        icon="comment"
      />
    );
  }

  return (
    <>
      <Table>
        <Table.Header>
          <Table.HeaderCell>Sender</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell right></Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {messages?.map((message: FeedbackMessageListView, index: number) => (
            <Table.Row key={index}>
              <Table.Cell>
                <Avatar
                  user={message.sender}
                  size={Size.Small}
                  sub={message.sender.school.name}
                />
              </Table.Cell>
              <Table.Cell>
                <DateTime date={message.createdDate} bold />
              </Table.Cell>
              <Table.Cell right>
                <Button
                  text="Details"
                  onClick={() => handleViewDetails(message)}
                  color={Swatches.Low}
                  size={Size.Small}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <FeedbackViewModal
        open={_selectedMessage != null}
        message={_selectedMessage}
        onClose={handleModalClosed}
      />
    </>
  );
};

export default Feedback;
