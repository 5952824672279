import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Loader, Size, TitleSize, Title, ValidationMessage } from "ui-kit";
import { getDateTime, formatDate } from "utils/dateTime";
import UpscaleDetentionForm from "./upscaleDetentionForm";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import { upscaleBehaviourValidation } from "../../../utils/behaviours";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import ReadOnlyDetails from "areas/behaviour/components/readOnly/readOnlyDetails";
import RelatedBehaviourDetails from "../../behaviours/relatedBehaviourDetails";
import { StudentFeedListView } from "types/planner/feedResponse.types";
import { useAppSelector } from "reducers/hooks";
import { BehaviourView } from "areas/behaviour/types/behaviourResponses.types";
import { IDetentionDetails, IIncidentDetails } from "areas/behaviour/utils/detentions";
import { UpscaleC2BehaviourCommand } from "areas/behaviour/types/behaviourRequest.types";


interface IUpscaleDetentionFormWrapperProps {
  activity: StudentFeedListView;
  handleCloseModal: () => void;
}


const UpscaleDetentionFormWrapper: React.FC<IUpscaleDetentionFormWrapperProps> = ({ activity, handleCloseModal }) => {

  const { behaviour, refresh, loading, getBehaviourError } = useAppSelector(
    state => state.behaviour
  );
  const { loading: loadingUpscale, error: upscaleError } = useAppSelector(
    state => state.upscaleBehaviour
  );
  const { schoolInformation } = useAppSelector(state => state.school);
  const { detentionSummary } = useAppSelector(state => state.detentionSummary);

  const [selectedBehaviour, setSelectedBehaviour] = useState<BehaviourView>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    behaviourActions.getBehaviour(
      activity.students[0].id,
      activity.itemId,
      null
    );
  }, [activity]);

  useEffect(() => {
    if (behaviour) {
      setSelectedBehaviour(behaviour.behaviour);
    }
  }, [behaviour]);

  useEffect(() => {
    if (refresh) {
      behaviourActions.getBehaviour(
        activity.students[0].id,
        activity.itemId,
        null
      );
    }
  }, [refresh]);

  const checkValidations = (incidentDetails: IIncidentDetails, detentionDetails: IDetentionDetails, openConfirmReshedule: () => void) => {
    
    const validationErrors = upscaleBehaviourValidation(
      incidentDetails,
      detentionDetails,
      selectedBehaviour.detentions[0]
        ? selectedBehaviour.detentions[0].detentionId
        : null,
      detentionSummary,
      schoolInformation
    );

    if (
      selectedBehaviour.detentions[0].detentionInformation.duration >=
      detentionDetails.duration
    ) {
      validationErrors.push(
        "The new detention duration is less than or equal to the original detention duration. If you do not want to upscale the detention, you can use the edit option to make changes."
      );
    }

    if (validationErrors.length > 0) {
      setValidationErrors(validationErrors);
    } else {
      setValidationErrors([]);
      openConfirmReshedule();
    }
  };

  const handleUpscaleBehaviourDetention = (detentionDetails: IDetentionDetails, internalNotes: string, publicNotes: string) => {

    const detention = {
      detentionTypeId: detentionDetails.detentionTypeId,
      roomId: detentionDetails.detentionLocationId,
      duration: detentionDetails.duration,
      detentionDate: getDateTime(
        formatDate(detentionDetails.date),
        detentionDetails.time
      ),
    };

    const newBehaviourDetention: UpscaleC2BehaviourCommand = {
      studentId: activity.students[0].id,
      description: internalNotes,
      publicNotes: publicNotes,
      parentBehaviourId: activity.itemId,
      upscaleBehaviourDetention: {
        ...detention,
        detentionNote: internalNotes
      }
    };

    const callback = () => {
      handleCloseModal();
    };

    behaviourActions.upscaleBehaviourDetention(
      activity.students[0].id,
      activity.itemId,
      newBehaviourDetention,
      callback
    );
  };

  if (loading || loadingUpscale) {
    return <Loader size={Size.Medium} fluid />;
  }

  if (getBehaviourError) {
    return <ApiExceptionMessage error={getBehaviourError} />;
  }

  return (
    selectedBehaviour && (
      <div className="behaviour-modal">
        <>
          <RelatedBehaviourDetails behaviour={behaviour} />

          <ReadOnlyDetails behaviour={selectedBehaviour} />

          <Title text="Upscale Sanction" size={TitleSize.H3} />

          <br />
          <UpscaleDetentionForm
            behaviour={selectedBehaviour}
            studentId={activity.students[0].id}
            handleCancel={handleCloseModal}
            handleUpscaleDetention={handleUpscaleBehaviourDetention}
            schoolId={schoolInformation.id}
            checkValidations={checkValidations}
          />

          <ApiExceptionMessage error={upscaleError} />
          <ValidationMessage errors={validationErrors} />
        </>
      </div>
    )
  );
};


export default UpscaleDetentionFormWrapper;
