import paymentActions from "areas/payments/actions/paymentsActions";
import { DuplicateBasketListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import defaults from "configuration/defaults";
import { IBeehiveAction } from "types/common/action.types";
import { PageInfo } from "types/common/paging.types";


export interface IBasketsWithDuplicateOrdersState {
  loading: boolean;
  error: string | null;
  baskets: DuplicateBasketListView[] | null,
  paging: PageInfo;
  working: boolean;
  processError: string | null;
  voidError: string | null;
}

const INITIAL_STATE: IBasketsWithDuplicateOrdersState = {
  loading: false,
  error: null,
  baskets: null,
  paging: defaults.DEFAULT_PAGING,
  working: false,
  processError: null,
  voidError: null
};

const basketsWithDuplicateOrdersReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IBasketsWithDuplicateOrdersState => {

  const { 
    PAYMENTS_GETBASKETSWITHDUPLICATEORDERS, 
    PAYMENTS_VOIDORDER 
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETBASKETSWITHDUPLICATEORDERS.START:
      return { 
        ...state, 
        loading: true, 
        error: null, 
        processError: null 
      };

    case PAYMENTS_GETBASKETSWITHDUPLICATEORDERS.SUCCESS:
      return {
        ...state,
        baskets: action.payload.items,
        paging: action.payload?.paging,
        loading: false,
      };

    case PAYMENTS_GETBASKETSWITHDUPLICATEORDERS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PAYMENTS_VOIDORDER.START:
      return {
        ...state,
        working: true,
        voidError: null,
      };

    case PAYMENTS_VOIDORDER.SUCCESS:
      return {
        ...state,
        working: false,
        voidError: null,
        baskets: [
          ...state.baskets.map((item) =>
            item.id === action.payload.initialBasketId
              ? {
                  ...item,
                  orders: item.orders.map((x) =>
                    x.id === action.payload.id ? action.payload : x
                  ),
                }
              : item
          ),
        ],
      };

    case PAYMENTS_VOIDORDER.FAILED:
      return {
        ...state,
        working: false,
        voidError: "There was a problem voiding the order.",
      };

    default:
      return state;
  }
};

export default basketsWithDuplicateOrdersReducer;
