import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "ui-kit";
import { arrays } from "utils";
import paymentActions from "areas/payments/actions/paymentsActions";
import inventoryFilterActions from "areas/payments/actions/inventoryFilterActions";
import { RootState } from "reducers/store";
import { ProductCategoryListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


interface ICategoryOption {
  key: number;
  label: string;
  value: ProductCategoryListView;
}

const ALL_OPTION: ICategoryOption = {
  key: -1,
  label: "All Categories",
  value: {
    isActive: false,
    icon: "",
    school: undefined,
    id: -1,
    name: "",
  }
};

interface IProductCategoryDropDownProps {
  onChange?: (category: ProductCategoryListView) => void;
  initialValue?: number;
  schoolId?: number;
}


const ProductCategoryDropDown: React.FC<IProductCategoryDropDownProps> = ({ onChange, initialValue, schoolId }) => {

  const [selectedValue, setSelectedValue] = useState<ProductCategoryListView>(ALL_OPTION.value);
  const [options, setOptions] = useState<ICategoryOption[]>([ALL_OPTION]);
  const { categories, loading: loadingCategories } = useSelector((state: RootState) => state.paymentCategories);
  const { loading, filter } = useSelector((state: RootState) => state.inventoryFilter);

  const handleChange = (category: ProductCategoryListView) => {
    setSelectedValue(category);
    if (onChange) {
      const cat = options.find(x => x.value === category);
      onChange(cat.value ?? ALL_OPTION.value);
    }
  };

  useEffect(() => {
    paymentActions.getCategoriesForSchool(schoolId);
  }, [filter.schoolId]);

  useEffect(() => {
    //The internal selected state is needed as the selected cateogry
    //from the reducer is not in the correct object format, so needs
    //to be mapped to a format the dropdown accepts.
    setSelectedValue(filter.category ? filter.category : ALL_OPTION.value);
  }, [filter.category]);

  useEffect(() => {
    var opts = categories.map((cat: ProductCategoryListView, index: number) => ({
      key: index,
      label: cat.name,
      value: cat
    }));

    setOptions([ALL_OPTION, ...opts]);
  }, [categories]);

  useEffect(() => {
    if (
      filter.category &&
      filter.category.id > 0 &&
      options.find(x => x.value.id === filter.category.id)
    ) {
      setSelectedValue(filter.category);
    } else {
      options && setSelectedValue(options[0].value);
    }
  }, [options]);

  return !loading && !arrays.isEmpty(categories) ? (
    <>
      <Dropdown
        placeholder="Category"
        items={options}
        onChange={handleChange}
        value={selectedValue}
        loading={loadingCategories}
      />
    </>
  ) : null;
};

export default ProductCategoryDropDown;
