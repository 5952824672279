import { IBeehiveAction } from "types/common/action.types";
import bulletinActions from "../../actions/bulletinActions";
import { BulletinTemplateListView } from "areas/news/types/newsResponse.types";

interface IBulletinTemplatesState {
  loading: boolean;
  error: string | null;
  templates: BulletinTemplateListView[];
}

const INITIAL_STATE: IBulletinTemplatesState = {
  loading: false,
  error: null,
  templates: [],
};


const bulletinTemplatesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IBulletinTemplatesState => {
  
  const { BULLETINTEMPLATES_GET } = bulletinActions.types;

  switch (action.type) {
    case BULLETINTEMPLATES_GET.START:
      return { ...INITIAL_STATE, loading: true, error: null };

    case BULLETINTEMPLATES_GET.SUCCESS:
      var newState = { ...state, templates: action.payload, loading: false };
      return newState;

    case BULLETINTEMPLATES_GET.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default bulletinTemplatesReducer;
