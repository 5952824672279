import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GroupFeedFilters from "./groupFeedFilters";
import { Size } from "ui-kit/common/size";
import { Loader, EmptyMessage } from "ui-kit";
import groupActions from "areas/planner/actions/group/groupActions";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import ActivityFeed from "areas/planner/components/activityFeed/activityFeed";
import { useAppSelector } from "reducers/hooks";


const GroupActivityWrapper: React.FC = () => {

  const {
    groupActivity,
    paging,
    refresh,
    loading,
    error,
    feedTypeFilter,
    createdDateFilter,
  } = useAppSelector(state => state.groupActivity);
  const { group } = useAppSelector(state => state.group);

  const [pageIndex, setPageIndex] = useState<number>(paging.pageIndex);

  const handleLoadMore = () => {
    setPageIndex(paging.pageIndex + 1);
  };

  const getFeed = () => {
    groupActions.getGroupActivityFeed(
      group.id,
      group.type,
      0,
      {
        feedType: feedTypeFilter,
        createdDate: createdDateFilter
      }
    );
  };

  useEffect(() => {
    setPageIndex(paging.pageIndex);
  }, [paging.pageIndex]);

  useEffect(() => {
    if (group) {
      getFeed();
    }
  }, [group, feedTypeFilter, createdDateFilter]);

  useEffect(() => {
    if (refresh) {
      getFeed();
    }
  }, [refresh]);

  useEffect(() => {
    if (pageIndex > 0) {
      groupActions.getGroupActivityFeed(
        group.id,
        group.type,
        pageIndex,
        {
          feedType: feedTypeFilter,
          createdDate: createdDateFilter
        }
      );
    }
  }, [pageIndex]);

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return (
    <>
      <GroupFeedFilters />
      {groupActivity.length === 0 && loading ? (
        <Loader size={Size.Large} fluid />
      ) : groupActivity && groupActivity.length > 0 ? (
        <ActivityFeed
          activities={groupActivity}
          displayStudentName
          loading={loading}
          pagesAvailable={paging.pageNumber < paging.totalPages}
          handleLoadMore={handleLoadMore}
          schoolId={group.school.id}
        />
      ) : (
        <EmptyMessage icon="list" title="No Activity" />
      )}
    </>
  );
};

export default GroupActivityWrapper;
