import { Constants } from "configuration";
import groupActions from "../../actions/group/groupActions";
import classViewerActions from "../../actions/classViewer/classViewerActions";
import onCallActions from "areas/behaviour/actions/onCall/onCallActions";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import customGroupActions from "../../actions/group/customGroupActions";
import { IBeehiveAction } from "types/common/action.types";
import { ClassGroupStaffView, GroupType } from "types/users/userGroups.types";
import { IBeehiveError } from "types/common/errors.types";
import { LayoutListView } from "areas/planner/types/classLayoutResponse.types";


interface IGroupState {
  selectedLayoutId: number;
  selectedGroupId: number;
  group: ClassGroupStaffView;
  refresh: boolean;
  load: boolean;
  loading: boolean;
  getGroupError: IBeehiveError;
  setDefaultClassLayoutError: IBeehiveError; 
}

const INITIAL_STATE: IGroupState = {
  selectedLayoutId: null,
  selectedGroupId: null,
  group: null,
  refresh: false,
  load: false, // load seating plan by calling get class layout
  loading: false,
  getGroupError: null,
  setDefaultClassLayoutError: null,
};


const groupReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IGroupState => {

  const {
    SELECTGROUP,
    GETGROUP,
    GETDEFAULTLAYOUT,
    SETDEFAULTLAYOUT,
    FAVOURITEGROUP,
    UNFAVOURITEGROUP,
  } = groupActions.types;
  const {
    CREATECUSTOMGROUP,
    DELETECUSTOMGROUP,
    SAVECUSTOMGROUP,
    CHANGECUSTOMGROUPSTATUS,
  } = customGroupActions.types;
  const { CREATECLASSLAYOUT, DELETECLASSLAYOUT, SELECTLAYOUT, GETCLASSLAYOUT } = classViewerActions.types;
  const { CREATEONCALLREQUEST, UPDATEONCALLREQUEST } = onCallActions.types;
  const { ADDBEHAVIOUR } = behaviourActions.types;
  const { ADDREWARDS } = rewardActions.types;

  switch (action.type) {
    case SELECTLAYOUT:
      return { ...state, selectedLayoutId: action.payload, load: true };

    case SELECTGROUP:
    case CREATECUSTOMGROUP.SUCCESS:
      return { ...state, selectedGroupId: action.payload };

    case GETGROUP.START:
      return {
        ...state,
        loading: true,
        refresh: false,
        getGroupError: null,
      };

    // if the selectedLayoutId is a layout in the for the group then leave it otherwise set the selectedLayoutId to null (if a new group is loaded)
    case GETGROUP.SUCCESS:
      return {
        ...state,
        group: action.payload,
        refresh: false,
        loading: false,
        selectedLayoutId: action.payload.layouts?.some(
          (layout: LayoutListView) => layout.id === state.selectedLayoutId
        )
          ? state.selectedLayoutId
          : null,
      };

    case GETGROUP.FAILED:
      return {
        ...state,
        loading: false,
        refresh: false,
        getGroupError: action.payload,
      };

    case GETDEFAULTLAYOUT:
      return { ...state, selectedLayoutId: action.payload.classLayoutId };

    case GETCLASSLAYOUT.SUCCESS:
      return { ...state, load: false };

    case CREATECLASSLAYOUT.SUCCESS:
      return {
        ...state,
        selectedLayoutId: action.payload,
        load: true,
        refresh: true,
      };

    case DELETECLASSLAYOUT.SUCCESS:
      return { ...state, selectedLayoutId: null, refresh: true };

    case SETDEFAULTLAYOUT.START:
      return { ...state, setDefaultClassLayoutError: null };

    case SETDEFAULTLAYOUT.SUCCESS:
      return {
        ...state,
        selectedLayoutId: action.payload,
        load: true,
        refresh: true,
      };

    case SETDEFAULTLAYOUT.FAILED:
      return { ...state, setDefaultClassLayoutError: action.payload };

    case CREATEONCALLREQUEST.SUCCESS:
    case UPDATEONCALLREQUEST.SUCCESS:
    case SAVECUSTOMGROUP.SUCCESS:
      return { ...state, refresh: true };

    case CHANGECUSTOMGROUPSTATUS.SUCCESS:
      return {
        ...state,
        group: { ...state.group, isActive: !state.group.isActive },
      };

    case ADDBEHAVIOUR.SUCCESS:
    case ADDREWARDS.SUCCESS:
      if (
        state.group?.type === GroupType.Custom &&
        state.group?.totalBehaviourPoints === 0 &&
        state.group?.totalRewardPoints === 0
      ) {
        return { ...state, refresh: true };
      } else {
        return state;
      }

    case FAVOURITEGROUP.SUCCESS:
      return { ...state, group: { ...state.group, isFavourite: true } };

    case UNFAVOURITEGROUP.SUCCESS:
      return { ...state, group: { ...state.group, isFavourite: false } };

    case DELETECUSTOMGROUP.START:
      return { ...state, loading: true };

    case DELETECUSTOMGROUP.SUCCESS:
      return { ...state, loading: false, group: null };

    case DELETECUSTOMGROUP.FAILED:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default groupReducer;
