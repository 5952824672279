import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Chip,
  DateTime,
  Icon,
  Label,
  Loader,
  Size,
  Spacing,
  StructuredList,
  Table,
} from "ui-kit";
import { danger, success } from "ui-kit/common/colors";
import styled from "styled-components";
import calendarActions from "areas/calendar/actions/calendarActions";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import GroupList from "areas/planner/components/groups/groupList";
import { EVENT_ATTENDANCE } from "areas/calendar/constants/events";
import { formatDate } from "areas/calendar/utils/events";
import PublishStatus from "sharedComponents/common/status/publishStatus";
import SchoolAvatar from "sharedComponents/common/schools/schoolAvatar";

const Wrapper = styled.div`
  display: flex;

  .structured-list,
  .table-wrapper {
    width: 50%;
  }

  .structured-list {
    margin-right: ${Spacing.ExtraLarge}px;
  }
`;

const EventAttendance = ({ studentId, event }) => {
  // const { studentId, eventId } = useParams();

  const { calendarEvent, loading, error } = useSelector(
    state => state.calendarEvent
  );

  const [loadingAttendance, setLoadingAttendance] = useState(false);
  const [attendanceError, setAttendanceError] = useState(null);
  const [eventAttendance, setEventAttendance] = useState([]);

  useEffect(() => {
    const callback = data => {
      setEventAttendance(data);
      setLoadingAttendance(false);
    };
    const errCallback = error => {
      setAttendanceError(error);
      setLoadingAttendance(false);
    };
    setLoadingAttendance(true);
    calendarActions.getUserEventAttendance(
      event.id,
      studentId,
      callback,
      errCallback
    );
    calendarActions.getCalendarEvent(studentId, event.id);
  }, []);

  const getAttendance = date => {
    const attendance = eventAttendance?.find(
      attendance => attendance.eventDateId === date.id
    );

    if (attendance) {
      switch (attendance.attendanceId) {
        case EVENT_ATTENDANCE.PENDING:
          return <Icon value="clock" size={Size.Medium} tooltip="Signed Up" />;

        case EVENT_ATTENDANCE.ATTENDED:
          return (
            <Icon
              value="check"
              color={success}
              size={Size.Medium}
              tooltip={attendance.attendanceName}
            />
          );

        case EVENT_ATTENDANCE.MISSED:
          return (
            <Icon
              value="times"
              color={danger}
              size={Size.Medium}
              tooltip={attendance.attendanceName}
            />
          );

        default:
          break;
      }
    } else {
      return calendarEvent?.requireSignup
        ? "Not Signed Up"
        : "Signup Not Required";
    }
  };

  if (loading || loadingAttendance) {
    return <Loader size={Size.Medium} fluid />;
  }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  if (attendanceError) {
    return <ApiExceptionMessage error={attendanceError} />;
  }

  return (
    <Wrapper>
      <StructuredList>
        <StructuredList.Item name="Event Title">
          <Label>{calendarEvent?.title}</Label>
        </StructuredList.Item>
        <StructuredList.Item name="Category">
          <Label>{calendarEvent?.category.name}</Label>
        </StructuredList.Item>
        <StructuredList.Item name="Status">
          <PublishStatus status={calendarEvent?.status} />
        </StructuredList.Item>
        <StructuredList.Item name="Ends">
          <DateTime bold date={calendarEvent?.endDate} />
        </StructuredList.Item>
        <StructuredList.Item name="Schools">
          {calendarEvent?.schools?.map((school, index) => (
            <SchoolAvatar key={index} school={school} />
          ))}
        </StructuredList.Item>
        <StructuredList.Item name="Open to Groups">
          <GroupList groups={calendarEvent?.groups} />
        </StructuredList.Item>
        {calendarEvent?.isSticky && (
          <StructuredList.Item name="Is Sticky">
            <Chip text="Sticky" />
          </StructuredList.Item>
        )}
      </StructuredList>

      <div className="table-wrapper">
        <Table>
          <Table.Header>
            <Table.HeaderCell width={2}>Date</Table.HeaderCell>
            <Table.HeaderCell width={0.5} right>
              Attendance
            </Table.HeaderCell>
          </Table.Header>

          <Table.Body>
            {event.dates.map(date => (
              <Table.Row>
                <Table.Cell>{formatDate(date.starts, date.ends)}</Table.Cell>
                <Table.Cell right>{getAttendance(date)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </Wrapper>
  );
};

export default EventAttendance;
