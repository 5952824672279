import lunchRecordsReducer from "./lunchRecordsReducer";
import lunchRegisterReducer from "./lunchRegisterReducer";
import lunchHistoryReducer from "./lunchHistoryReducer";
import lunchBalancesReducer from "./lunchBalancesReducer";
import cateringReportReducer from "./cateringReportReducer";
import cateringPricingReducer from "./cateringPricingReducer";

const catering = {
  lunchRecords: lunchRecordsReducer,
  lunchRegister: lunchRegisterReducer,
  lunchHistory: lunchHistoryReducer,
  lunchBalances: lunchBalancesReducer,
  cateringReport: cateringReportReducer,
  cateringPricing: cateringPricingReducer
};

export default catering;
