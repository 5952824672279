import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Swatches, danger } from "ui-kit/common/colors";
import { Message, Dropdown } from "ui-kit";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import { useAppSelector } from "reducers/hooks";
import { IBehaviourCategory } from "./managePendingAttentionFormWrapper";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";


interface IBehaviourCategoryFormWrapperProps {
  schoolId: number;
  behaviourCategory: IBehaviourCategory;
  setBehaviourCategory: (category: IBehaviourCategory) => void;
}


const BehaviourCategoryFormWrapper: React.FC<IBehaviourCategoryFormWrapperProps> = ({
  schoolId,
  behaviourCategory,
  setBehaviourCategory,
}) => {

  const {
    behaviourCategoryGroups,
    getBehavioursError,
    schoolId: categoriesSchoolId,
  } = useAppSelector(state => state.behaviourCategories);

  const [behaviourCategoriesArray, setBehaviourCategoriesArray] = useState<IDropdownDataItemProps<string, number, IBehaviourCategory>[]>([]);

  useEffect(() => {
    if (
      categoriesSchoolId !== schoolId ||
      !behaviourCategoryGroups ||
      behaviourCategoryGroups.length === 0
    ) {
      behaviourActions.getBehaviourCategories(
        schoolId, 
        {
          isLegacy: null, 
          canBeAwarded: true,
          behaviourCodeId: null
        }
      );
    }
  }, [schoolId]);

  useEffect(() => {
    let allBehaviours: IDropdownDataItemProps<string, number, IBehaviourCategory>[] = [];

    for (let i = 0; i < behaviourCategoryGroups.length; i++) {
      let behaviours = behaviourCategoryGroups[i].behaviourCategories.map(
        category => ({
          key: category.id,
          label: `${category.codeDisplayName} - ${category.behaviourCategoryName}`,
          value: category.id,
          data: {
            behaviourCodeId: category.behaviourCodeId,
            behaviourCategoryId: category.id,
            points: category.points,
            severityIndex: behaviourCategoryGroups[i].severityIndex,
          }
        })
      );
      allBehaviours = allBehaviours.concat(behaviours);
    }

    setBehaviourCategoriesArray(allBehaviours);
  }, [behaviourCategoryGroups]);

  const onSelectBehaviourCategory = (value: number, label: string, data: IBehaviourCategory) => {
    // const category = behaviourCategoryGroups.find(
    //   (behaviour) => behaviour.behaviourCategoryId === data.value
    // );
    let category;
    category = behaviourCategoriesArray.find(
      behaviour => behaviour.value === value
    );

    //console.log(category);
    if (category !== undefined) {
      setBehaviourCategory(category.data);
    }
  };

  if (getBehavioursError) {
    return (
      <Message
        text="There was a problem loading the categories"
        color={Swatches.Danger}
      />
    );
  }

  return (
    behaviourCategoriesArray.length > 0 && (
      <Dropdown
        placeholder="Behaviour Category"
        fluid
        items={behaviourCategoriesArray}
        onChange={onSelectBehaviourCategory}
        value={behaviourCategory.behaviourCategoryId}
      />
    )
  );
};


export default BehaviourCategoryFormWrapper;
