import React from "react";
import PropTypes from "prop-types";
import { formatTime, formatDate } from "utils/dateTime";
import {
  Table,
  Icon,
  Size,
  Button,
  DetailLabel,
  Spacing,
  HelpPopup,
  Text,
  Checkbox
} from "ui-kit";
import styled from "styled-components";
import Avatar from "sharedComponents/common/users/avatar";
import moment from "moment";
import { getBehaviourGivenGroup } from "areas/behaviour/utils/detentions";
import { DetentionStudentView } from "areas/behaviour/types/detentionRegisterResponse.types";
import { ICheckboxClickEvent } from "ui-kit/forms/checkbox";
import { YearGroupFilterExt } from "./missedDetentionsWrapper";
import config from "configuration/config";


const ButtonWrapper = styled.div`
  .button {
    padding: ${Spacing.Small}px ${Spacing.Medium}px;
  }
`;

export const BehaviourWrapper = styled.div`
  display: flex;

  .detail-label {
    margin-right: ${Spacing.ExtraSmall + 2}px;
  }
`;


interface IMissedDetentionsTableRow {
  filters: YearGroupFilterExt;
  detention: DetentionStudentView;
  onClickDetention: (detention: DetentionStudentView) => void;
  rescheduleDetention: (detention: DetentionStudentView) => void;
  selected: boolean;
  handleDetentionCheckBoxChange: (checked: boolean, detention: DetentionStudentView) => void;
}


const MissedDetentionsTableRow: React.FC<IMissedDetentionsTableRow> = ({
  filters,
  detention,
  onClickDetention,
  rescheduleDetention,
  selected,
  handleDetentionCheckBoxChange
}) => {

  const student = detention.student;

  return (
    <Table.Row onClick={() => onClickDetention(detention)}>
      <Table.Cell width={0.25} center>
        <Text>
          <Checkbox
            checked={selected}
            onChange={checked =>
              handleDetentionCheckBoxChange(checked.checked, detention)
            }
          />
        </Text>
      </Table.Cell>
      <Table.Cell width={2}>
        <Avatar
          user={student}
          sub={
            detention.tutorGroup?.friendlyName
              ? `${student.simsId} | ${detention.tutorGroup.friendlyName}`
              : student.simsId
          }
        />
      </Table.Cell>

      <Table.Cell width={1}>{`${moment(detention.startDate).format(
        "ddd Do MMM YYYY"
      )}`}</Table.Cell>

      <Table.Cell width={1} >{`${formatTime(detention.startDate)}`}</Table.Cell>

      <Table.Cell width={1} >{detention.detentionTypeName}</Table.Cell>

      <Table.Cell width={1} >
        <BehaviourWrapper>
          <DetailLabel bold label={detention.codeDisplayName} />
          <HelpPopup title={detention.categoryName} />
        </BehaviourWrapper>
      </Table.Cell>

      <Table.Cell width={2}>
        <DetailLabel
          bold
          label={detention.awardedBy}
          sub={
            <>
              {getBehaviourGivenGroup(detention)}
              <p>
                {`${formatDate(detention.awardedDate)} ${formatTime(
                  detention.awardedDate
                )}`}
              </p>
            </>
          }
        />
      </Table.Cell>

      <Table.Cell width={1} show={filters.academicYearId === config.academicYear.current} right>
        <ButtonWrapper>
          <Button
            size={Size.Small}
            text="Reschedule"
            onClick={e => {
              rescheduleDetention(detention);
              e.stopPropagation();
            }}
          />
        </ButtonWrapper>
      </Table.Cell>
    </Table.Row>
  );
};


export default MissedDetentionsTableRow;
