import paymentActions from "areas/payments/actions/paymentsActions";
import { SmartcardSyncResultListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import defaults from "configuration/defaults";
import { IBeehiveAction } from "types/common/action.types";
import { PageInfo } from "types/common/paging.types";
import { arrays } from "utils";


export interface ISmartcardCreditsState {
  credits: SmartcardSyncResultListView[],
  error: string | null;
  loading: boolean;
  paging: PageInfo;
  working: boolean;
  workingError: string | null;
}


const INITIAL_STATE: ISmartcardCreditsState = {
  credits: [],
  error: null,
  loading: false,
  paging: defaults.DEFAULT_PAGING,
  working: false,
  workingError: null
};

const smartcardCreditsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISmartcardCreditsState => {

  const {
    PAYMENTS_GETSMARTCARDCREDITS,
    PAYMENTS_REQUEUESMARTCARDCREDIT,
    PAYMENTS_CANCELSMARTCARDCREDIT
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETSMARTCARDCREDITS.START:
      return { 
        ...state, 
        loading: arrays.isEmpty(state.credits), 
        error: null 
      };

    case PAYMENTS_GETSMARTCARDCREDITS.SUCCESS:
      return {
        ...state,
        credits:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.credits, ...action.payload.items],
        paging: action.payload.paging,
        loading: false
      };

    case PAYMENTS_GETSMARTCARDCREDITS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PAYMENTS_CANCELSMARTCARDCREDIT.START:
      return {
        ...state,
        working: true,
        workingError: null
      };

    case PAYMENTS_CANCELSMARTCARDCREDIT.SUCCESS:
      return {
        ...state,
        working: false,
        workingError: null,
        credits: state.credits.map(credit =>
          credit.id === action.payload ? { ...credit, status: 3 } : credit
        )
      };

    case PAYMENTS_CANCELSMARTCARDCREDIT.FAILED:
      return {
        ...state,
        working: false,
        workingError:
          "There was a problem cancelling the smartcard credit sync record"
      };

    case PAYMENTS_REQUEUESMARTCARDCREDIT.START:
      return {
        ...state,
        working: true,
        workingError: null
      };

    case PAYMENTS_REQUEUESMARTCARDCREDIT.SUCCESS:
      return {
        ...state,
        working: false,
        workingError: null,
        credits: state.credits.map(credit =>
          credit.id === action.payload ? { ...credit, status: 0 } : credit
        )
      };

    case PAYMENTS_REQUEUESMARTCARDCREDIT.FAILED:
      return {
        ...state,
        working: false,
        workingError:
          "There was a problem cancelling the smartcard credit sync record"
      };

    default:
      return state;
  }
};

export default smartcardCreditsReducer;
