import React from "react";
import {
  CircleIcon,
  Chip,
  Swatches,
  Size,
  Spacing,
  fontStyle,
  displayFont,
  typescale,
} from "ui-kit";
import styled from "styled-components";
import { FEED_TYPE_KEY } from "../../constants/activityFeed";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${Spacing.Small}px ${Spacing.Large}px 0 ${Spacing.Small}px;

  .chip {
    height: fit-content;
    width: fit-content;
    ${fontStyle(displayFont.roman, typescale.sub)}
    margin-top: ${Spacing.Small}px;
  }
`;

const getIconObj = (name, color, tooltip) => {
  return {
    name: name,
    color: color,
    tooltip: tooltip,
  };
};

const ActivityIcon = ({ activityTypeId, codeDisplayName }) => {
  let icon;

  switch (activityTypeId) {
    case FEED_TYPE_KEY.NOTES:
      icon = getIconObj("sticky-note", Swatches.Blue, "Note");
      break;
    case FEED_TYPE_KEY.QNA:
      icon = getIconObj(
        "question",
        Swatches.Orange,
        "Answer for a question asked in class"
      );
      break;
    case FEED_TYPE_KEY.AWARDS:
      icon = getIconObj("medal", Swatches.Purple, "Award");
      break;
    case FEED_TYPE_KEY.BEHAVIOURS:
      icon = getIconObj("thumbs-down", Swatches.Danger, "Behaviour Point");
      break;
    case FEED_TYPE_KEY.REWARDS:
      icon = getIconObj("thumbs-up", Swatches.Success, "Reward Point");
      break;
    case FEED_TYPE_KEY.SMART_TASK:
      icon = getIconObj("trophy-alt", Swatches.Primary, "Smart Task Triggered");
      break;
    case FEED_TYPE_KEY.LEFT_LESSON:
      icon = getIconObj("door-open", Swatches.Default, "Student Left Lesson");
      break;
    case FEED_TYPE_KEY.ON_CALL:
      icon = getIconObj("bullhorn", Swatches.Black, "On Call Requested");
      break;
    default:
      icon = getIconObj(null, Swatches.Default, "");
  }

  return (
    <Wrapper>
      <CircleIcon
        value={icon.name}
        color={icon.color.swatch}
        size={Size.Medium}
        outline
        tooltip={icon.tooltip}
      />

      {codeDisplayName && (
        <Chip text={codeDisplayName} colorSwatch={icon.color} />
      )}
    </Wrapper>
  );
};

export default ActivityIcon;
