import { ProvisionMapListView } from "areas/send/types/passportResponse.types";
import provisionMapActions from "../../actions/provisionMaps/provisionMapActions";
import { IBeehiveAction } from "types/common/action.types";
import sendParentsActions from "areas/send/actions/parents/senParentsActions";

interface IProvisionMapsState {
  provisionMaps: ProvisionMapListView[];
  activeStudent: string | null;
  loading: boolean;
  saving: boolean;
  error: string | null;
}

const INITIAL_STATE: IProvisionMapsState = {
  provisionMaps: [],
  activeStudent: null,
  loading: false,
  saving: false,
  error: null,
};


const provisionMapsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IProvisionMapsState => {

  const {
    PROVISIONMAPS_GETFORSTUDENT,
    PROVISIONMAP_CREATE,
    PROVISIONMAP_SAVE,
    PROVISIONMAP_DELETE,
  } = provisionMapActions.types;
  const { SET_ACTIVE_CHILD } = sendParentsActions.types;

  switch (action.type) {
    case PROVISIONMAPS_GETFORSTUDENT.START:
      return { ...state, loading: true };
    case PROVISIONMAPS_GETFORSTUDENT.SUCCESS:
      return { ...state, loading: false, provisionMaps: action.payload };
    case PROVISIONMAPS_GETFORSTUDENT.FAILED:
      return { ...state, loading: false, error: action.payload };

    case PROVISIONMAP_CREATE.SUCCESS:
      return {
        ...state,
        provisionMaps: [action.payload, ...state.provisionMaps],
      };

    case PROVISIONMAP_SAVE.SUCCESS:
      return {
        ...state,
        provisionMaps: state.provisionMaps.map(x =>
          x.id === action.payload.id ? action.payload : x
        ),
      };

    case PROVISIONMAP_DELETE.SUCCESS:
      return {
        ...state,
        provisionMaps: state.provisionMaps.filter(a => a.id !== action.payload),
      };

    case SET_ACTIVE_CHILD:
      return {
        ...state,
        activeStudent: action.payload
      }

    default:
      return state;
  }
};

export default provisionMapsReducer;
