import reportingActions from "areas/payments/actions/reportingActions";
import { BeehiveReport, IBeehiveAction } from "types/common/action.types";


export interface IPaymentsReportingState {
  loading: boolean;
  error: string | null;
  reportData: BeehiveReport | null;
}

const INITIAL_STATE: IPaymentsReportingState = {
  loading: false,
  error: null,
  reportData: null,
};

const paymentsReportingReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IPaymentsReportingState => {

  const {
    PAYMENTS_PURCHASESREPORT,
    PAYMENTS_INCOMEREPORT,
    PAYMENTS_PRODUCTSREPORT,
    PAYMENTS_TRANSACTIONSREPORT,
    PAYMENTS_INCOMINGTRANSACTIONSREPORT,
  } = reportingActions.paymentReportingTypes;

  switch (action.type) {
    case PAYMENTS_PURCHASESREPORT.START:
    case PAYMENTS_PRODUCTSREPORT.START:
    case PAYMENTS_INCOMEREPORT.START:
    case PAYMENTS_TRANSACTIONSREPORT.START:
    case PAYMENTS_INCOMINGTRANSACTIONSREPORT.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_PURCHASESREPORT.SUCCESS:
    case PAYMENTS_PRODUCTSREPORT.SUCCESS:
    case PAYMENTS_INCOMEREPORT.SUCCESS:
    case PAYMENTS_TRANSACTIONSREPORT.SUCCESS:
    case PAYMENTS_INCOMINGTRANSACTIONSREPORT.SUCCESS:
      return { 
        ...state, 
        reportData: action.payload, 
        loading: false 
      };

    case PAYMENTS_PURCHASESREPORT.FAILED:
    case PAYMENTS_PRODUCTSREPORT.FAILED:
    case PAYMENTS_INCOMEREPORT.FAILED:
    case PAYMENTS_TRANSACTIONSREPORT.FAILED:
    case PAYMENTS_INCOMINGTRANSACTIONSREPORT.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default paymentsReportingReducer;
