import React, { useEffect, useRef, useState } from "react";
import {
  ActionBar,
  Button,
  Card,
  Flyout,
  List,
  Loader,
  LoadingWrapper,
  Size,
  Spacing,
  StructuredList,
  Subtitle,
  Swatches,
  Tabs,
  TextInput,
  TextInputType,
  Tooltip,
} from "ui-kit";
import { Constants } from "configuration";
import styled from "styled-components";
import studentActions from "areas/planner/actions/student/studentActions";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { useAppSelector } from "reducers/hooks";
import {
  Avatar,
  SelectionButton,
  SelectionButtons,
} from "sharedComponents/common";
import flyoutActions from "actions/ui/flyouts";
import { useSelector } from "react-redux";
import { blue, danger, honeygold, neutral } from "ui-kit/common/colors";
import Tippy from "@tippyjs/react";
import studentLeavingLessonActions from "areas/planner/actions/student/studentLeavingLessonActions";

const Wrapper = styled.div`
  .student-card {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    max-height: 230px;
    overflow: auto;
  }

  .card {
    justify-content: space-evenly;
  }

  .content-wrapper {
    margin-left: 0px;
  }

  .subtitle-wrapper {
    margin-left: 4px;

    .subtitle {
      font-size: medium;
    }
  }

  .card {
    padding: 2px;
  }
`;

const RemoveButton = styled.button`
  font-size: 1.5rem;
  background-color: unset;
  border: medium none;
  margin: 0px;
  padding: 0px;
  color: rgb(153, 153, 153);
  cursor: pointer;
  line-height: 1rem;
  margin-bottom: ${Spacing.Small}px;
  &:hover {
    color: ${danger};
  }
`;

const LeavingLessonFlyout: React.FC = () => {
  const { selection, error } = useAppSelector((state) => state.student);
  const [SelectedStudents, setSelectedStudents] = useState([]);
  const { group } = useAppSelector<any>((state) => state.group);
  const [selectedReason, setSelectedReason] = useState(null);
  const { leavingLessonCategories, getCategoriesError, loadingCategories } =
    useSelector((state: any) => state.studentLeavingLesson);
  const tippyRef = useRef();
  const [leavingNote, setLeavingNote] = useState("");

  const { loadingCreateRecord, createRecordError } = useSelector(
    (state:any) => state.studentLeavingLesson
  );

  useEffect(() => {
    setLeavingNote("");
  }, [selectedReason]);

  useEffect(() => {
    if (selection < 1) {
      flyoutActions.closeFlyout();
      setSelectedReason(null);
    } else {
      setSelectedStudents(selection);
    }
  }, [selection]);

  const handleChange = (data: any) => {
    setLeavingNote(data);
  };

  const handleReasonClick = (reason: any) => {
    setSelectedReason(reason);
  };

  const handleAddLeavingLesson = () => {
    const callback = () => {
      flyoutActions.closeFlyout();
      setSelectedReason(null);
    };

    const payload = {
      studentIds: selection.map((student: any) => student.id),
      classGroupId:
        group.type === Constants.GROUP_TYPES.CLASS ? group.id : null,
      tutorGroupId:
        group.type === Constants.GROUP_TYPES.TUTOR ? group.id : null,
      provisionGroupId:
        group.type === Constants.GROUP_TYPES.PROVISION ? group.id : null,
      CategoryId: selectedReason.id,
      Notes: leavingNote,
    };

    studentLeavingLessonActions.createMultiStudentLeavingLessonRecord(
      payload,
      callback
    );
  };

  return (
    <Wrapper>
      <Flyout
        title={"Students Leaving Lesson"}
        name={Constants.FLYOUTS.MULTI_STUDENT_LEAVING_LESSON}
        wide
        onClose={() => studentActions.selectStudent(null)}
      >
        {loadingCategories ? (
          <LoadingWrapper loading={loadingCategories}>
            <Loader size={Size.Small} cover />
          </LoadingWrapper>
        ) : (
          <>
            {SelectedStudents ? (
              <div className="student-card">
                {SelectedStudents.map((student: any, index: number) => (
                  <div className="card">
                    <List.Item
                      key={index}
                      left={<Avatar size={Size.Medium} user={student} />}
                      right={
                        <>
                          <RemoveButton
                            ref={tippyRef}
                            onClick={() =>
                              studentActions.selectMultiStudent(
                                SelectedStudents.filter(
                                  (x: any) => x.id !== student.id
                                )
                              )
                            }
                          >
                            &#10799;
                          </RemoveButton>
                          <Tippy
                            reference={tippyRef}
                            content={<Tooltip title="Remove Student" />}
                          />
                        </>
                      }
                    />
                  </div>
                ))}
              </div>
            ) : null}
            {<ApiExceptionMessage error={error} />}

            {selection && (
              <Flyout.Body>
                <div className="reasonBody">
                  <Subtitle text={"Reason"} />
                  <Tabs>
                    <Tabs.Pane label="Leaving Lesson">
                      <SelectionButtons>
                        {leavingLessonCategories.map(
                          (category: any, index: number) => (
                            <SelectionButton
                              itemLabel={""}
                              key={index}
                              item={category}
                              itemSub={category.name}
                              icon="door-open"
                              iconColour={
                                selectedReason === category
                                  ? neutral[100]
                                  : blue
                              }
                              colour={
                                selectedReason === category ? honeygold : null
                              }
                              handleItemClick={handleReasonClick}
                            />
                          )
                        )}
                      </SelectionButtons>
                    </Tabs.Pane>
                  </Tabs>
                </div>

                <Card>
                  <Card.Body>
                    <StructuredList>
                      <StructuredList.Item
                      description={"This is a public note and will be visible to staff, students, and parents"} 
                      name="Public Notes">
                        <TextInput
                          type={TextInputType.Textarea}
                          value={leavingNote}
                          onChange={(value) => handleChange(value)}
                          fluid
                        />
                      </StructuredList.Item>
                    </StructuredList>
                  </Card.Body>
                </Card>
              </Flyout.Body>
            )}

            <Flyout.Footer>
              <ActionBar low>
                <Button
                  disabled={!selectedReason ? true : false}
                  text="Record Leaving Lessson"
                  working={loadingCreateRecord? true : false}
                  color={Swatches.Success}
                  onClick={handleAddLeavingLesson}
                  size={Size.Small}
                />
                <Button
                  text="Cancel"
                  color={Swatches.Low}
                  onClick={() => {
                    flyoutActions.closeFlyout();
                    setSelectedReason(null);
                  }}
                  size={Size.Small}
                />
              </ActionBar>
            </Flyout.Footer>
          </>
        )}
      </Flyout>
    </Wrapper>
  );
};

export default LeavingLessonFlyout;
