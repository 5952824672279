import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  Size,
  Swatches,
  Card,
  StructuredList,
  Message,
  Spacing,
  formatScrollbars,
  neutral,
  Left,
  Right,
  List,
  Button,
  ActionBar,
  Dropdown,
} from "ui-kit";
import consentActions from "areas/administration/actions/consentActions";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import { files } from "utils";
import { RootState } from "reducers/store";
import { ConsentFormDetailView } from "areas/administration/types/consentResponses.types";
import { ConsentReportFilter } from "areas/administration/types/consentRequest.types";
import { BeehiveReport } from "types/common/action.types";


const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;

  .left {
    padding-right: ${Spacing.Default}px;
    flex-direction: column;
    width: 0px;
    flex-grow: 1;
    left: 0;
    overflow-y: auto !important;
    width: 13rem;
    max-width: 13rem;
    margin-right: ${Spacing.Default}px;
  }

  .right {
    flex-grow: 1;
  }

  ${formatScrollbars(neutral[200])}
`;


const REPORTS: consentReport[] = [
  {
    name: "Consent Report",
    value: 0,
  },
];


interface IConsentReportsProps {
  form: ConsentFormDetailView;
}

interface consentReport {
  name: string;
  value: number;
}


const ConsentReports: React.FC<IConsentReportsProps> = ({ form }) => {

  const { loading, error } = useSelector((state: RootState) => state.consentReports);
  const [selectedReport, setSelectedReport] = useState<consentReport>(REPORTS[0]);
  const [filter, setFilter] = useState<ConsentReportFilter>({
    schoolId: -1,
    consentGiven: null,
  });

  const handleRunReport = () => {
    consentActions.consentFormResponseReport(form.id, filter, (reportData: BeehiveReport) => {
      files.openCsv(reportData);
    });
  };

  return (
    <Wrapper>
      <Left>
        <List title="Available Reports">
          {REPORTS.map((report, index) => (
            <List.Item
              text={report.name}
              key={index}
              selected={selectedReport.value === report.value}
              onClick={() => setSelectedReport(report)}
            />
          ))}
        </List>
      </Left>
      <Right>
        <Message text={error} color={Swatches.Danger} />
        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="School">
                <SchoolDropdown
                  initialValue={filter.schoolId}
                  fluid
                  onChange={val => setFilter({ ...filter, schoolId: val })}
                />
              </StructuredList.Item>
              <StructuredList.Item name="Consent Type">
                <Dropdown
                  value={filter.consentGiven}
                  onChange={value =>
                    setFilter({ ...filter, consentGiven: value })
                  }
                >
                  <Dropdown.Item value={null} label="All Consent Types">
                    All Consent
                  </Dropdown.Item>
                  <Dropdown.Item value={1} label="Consent Given">
                    Consent Given
                  </Dropdown.Item>
                  <Dropdown.Item value={2} label="Partial Consent">
                    Partial Consent
                  </Dropdown.Item>
                  <Dropdown.Item value={0} label="No Consent">
                    No Consent
                  </Dropdown.Item>
                </Dropdown>
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
          <Card.Footer>
            <ActionBar low>
              <Button
                text="Run Report"
                color={Swatches.Success}
                size={Size.Small}
                onClick={handleRunReport}
                working={loading}
              />
            </ActionBar>
          </Card.Footer>
        </Card>
      </Right>
    </Wrapper>
  );
};

export default ConsentReports;
