import React from "react";
import { ReactComponent as LandscapeDarkSvg } from "assets/logos/landscape-color-dark.svg";
import { ReactComponent as LandscapeWhiteSvg } from "assets/logos/landscape-color-white.svg";
import { ReactComponent as DevIconColorSvg } from "assets/logos/development/logo.svg";
import { ReactComponent as QaIconColorSvg } from "assets/logos/qa/logo.svg";
import { ReactComponent as SandboxIconColorSvg } from "assets/logos/sandbox/logo.svg";
import { ReactComponent as ProductionIconColorSvg } from "assets/logos/production/logo.svg";
import config from "configuration";
import PropTypes from "prop-types";


export interface ILogoProps {
  className?: string;
  width?: string | number,
  height?: string | number,
  alt?: string;
}

export const LandscapeDarkLogo: React.FC<ILogoProps> = ({className, width, height, alt}) => {
  return (
    <LandscapeDarkSvg
      //alt={alt ?? config.name}
      className={className}
      style={{ width: width, height: height }}
    />
  );
};

export const LandscapeWhiteLogo: React.FC<ILogoProps> = ({className, width, height, alt}) => {
  return (
    <LandscapeWhiteSvg
      //alt={alt ?? config.name}
      className={className}
      style={{ width: width, height: height }}
    />
  );
};

export const IconColorLogo: React.FC<ILogoProps> = ({className, width, height, alt}) => {

  switch (process.env.REACT_APP_ENVIRONMENT_NAME) {
    case "Local":
      return (
        <DevIconColorSvg
          //alt={alt ?? config.name}
          className={className}
          style={{
            width: width,
            height: height
          }}
        />
      );
    case "Development":
      return (
        <DevIconColorSvg
          //alt={alt ?? config.name}
          className={className}
          style={{
            width: width,
            height: height
          }}
        />
      );
    case "QA":
      return (
        <QaIconColorSvg
          //alt={alt ?? config.name}
          className={className}
          style={{
            width: width,
            height: height
          }}
        />
      );
    case "Sandbox":
      return (
        <SandboxIconColorSvg
          //alt={alt ?? config.name}
          className={className}
          style={{
            width: width,
            height: height
          }}
        />
      );
    case "Production":
      return (
        <ProductionIconColorSvg
          //alt={alt ?? config.name}
          className={className}
          style={{
            width: width,
            height: height
          }}
        />
      );
    default:
      return null;
  }
};