
/**
 * Data presence check
 * @param {any} data Object to check
 * @returns {boolean} True if the given data has a value
 */
const notNullOrUndefined = (data: any) : boolean => {
  return (data !== null && data !== undefined);
};

const methods = {
  notNullOrUndefined,
};

export default methods;
