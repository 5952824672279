import React from "react";
import { ReactComponent as FrogSvg } from "assets/primary/rockets/frog.svg";
import { IPrimaryRocketCharacterProps } from "./primaryRocket";


export const Frog: React.FC<IPrimaryRocketCharacterProps> = ({ className }) => {

  return <FrogSvg className={className} />;
};

export default Frog;
