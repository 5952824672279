import React from "react";
import styled from "styled-components";
import { Spacing } from "ui-kit";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${Spacing.Medium}px ${Spacing.Medium}px ${Spacing.Small}px
    ${Spacing.Medium}px;
`;

interface IPrimaryContentProps {
  children: any | any[];
}

const PrimaryContent : React.FC<IPrimaryContentProps> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default PrimaryContent;
