import { HolidayCardView } from "areas/humanResources/types/leaveResponse.types";
import React, { useState, useEffect } from "react";
import { Dropdown } from "ui-kit";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { strings } from "utils";



interface IGoldenTimeDropdownProps {
  holidayCard: HolidayCardView;
  value?: number;
  onChange: (value: number, label: string) => void;
  fluid?: boolean;
}


const GoldenTimeDropdown: React.FC<IGoldenTimeDropdownProps> = ({ holidayCard, value, onChange, fluid }) => {

  const [goldenHours, setGoldenHours] = useState<IDropdownDataItemProps<string, number, null>[]>([]);

  useEffect(() => {
    var hours: IDropdownDataItemProps<string, number, null>[] = [];
    for (var i = 1; i <= holidayCard.goldenTimeRemaining; i++) {
      hours.push({ label: `${i} ${strings.pluralize("Hour", "Hours", i)}`, value: i });
    }
    hours.push({ label: "Half Day - Morning", value: -1 });
    hours.push({ label: "Half Day - Afternoon", value: -2 });
    if (holidayCard.goldenTimeRemaining === 5) {
      hours.push({ label: "Full Day", value: -3 });
    }
    setGoldenHours(hours);
  }, [holidayCard])

  return (
    <>
      <Dropdown
        value={value}
        onChange={onChange}
        items={goldenHours}
        fluid={fluid}
      />
    </>
  );
}


export default GoldenTimeDropdown;