import { ArticleListView } from "areas/news/types/newsResponse.types";
import { CategoryListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { PagedQueryView } from "types/common/views.types";
import magazineActions from "../../actions/magazineActions";
import { IBeehiveAction } from "types/common/action.types";


interface IMagazineCategoryLatestState {
  loading: boolean;
  error: string | null;
  category: CategoryListView | null;
  features: PagedQueryView<ArticleListView> | null;
}

const INITIAL_STATE: IMagazineCategoryLatestState = {
  loading: false,
  error: null,
  category: null,
  features: null,
};


const magazineCategoryLatestReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IMagazineCategoryLatestState => {

  const { MAGAZINE_GETCATEGORYLATEST } = magazineActions.types;

  switch (action.type) {
    case MAGAZINE_GETCATEGORYLATEST.START:
      return { ...INITIAL_STATE, loading: true, error: null };

    case MAGAZINE_GETCATEGORYLATEST.SUCCESS:
      var newState = {
        ...state,
        features: action.payload.articles,
        category: action.payload.category,
        loading: false,
      };
      return newState;

    case MAGAZINE_GETCATEGORYLATEST.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default magazineCategoryLatestReducer;
