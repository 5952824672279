import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Left,
  Right,
  List,
  Spacing,
  Card,
  Message,
  StructuredList,
  Size,
  Button,
  Swatches,
  ActionBar,
  ValidationMessage,
} from "ui-kit";
import styled from "styled-components";
import { arrays, files } from "utils";
import lunchRegisterActions from "areas/payments/actions/lunchRegisterActions";
import { RootState } from "reducers/store";
import { SchoolStartupView } from "types/schools/schools.types";
import { CateringReportFilter } from "areas/payments/reducers/catering/lunchRecordsReducer";
import { useScroll } from "hooks/useScroll";
import PupilDailyReport from "./pupilDailyReport";
import PupilBalanceReport from "./pupilBalanceReport";
import GroupDailyReport from "./groupDailyReport";
import SinglePupilChoicesReport from "./singlePupilChoicesReport";
import DinnerNumbersReport from "./dinnerNumbersReport";
import { useAppSelector } from "reducers/hooks";
import { LunchNumbersFilter, LunchRecordQueryFilter, StudentChoicesQueryFilter } from "areas/payments/types/catering/cateringRequests.types";
import { LunchType } from "areas/payments/types/catering/cateringShared.types";
import moment from "moment";


const Wrapper = styled.div`
  display: flex;

  > .left {
    width: 250px;
  }

  > .right {
    flex-grow: 1;
    margin-left: ${Spacing.Large}px;
  }
`;


export interface ICateringReportProps {
  filter: CateringReportFilter;
  setFilter: (value: CateringReportFilter) => void
}

interface CateringReport {
  name: string;
  value: number;
}

const REPORTS: CateringReport[] = [
  {
    name: "Pupil Daily Report",
    value: 0,
  },
  {
    name: "Pupil Balance Report",
    value: 1,
  },
  {
    name: "Group Daily Report",
    value: 2,
  },
  {
    name: "Single Pupil Choices Report",
    value: 3,
  },
  {
    name: "Dinner Numbers Report",
    value: 4,
  },
];

const FILTER_DEFAULT: CateringReportFilter = {
  schoolId: null,
  date: new Date(),
  tutorGroupId: -1,
  lunchType: -1,
  freeSchoolMeal: false,
  universalFreeSchoolMeal: false,
};


const Reporting = () => {

  const { scrollToTop } = useScroll();
  const { user } = useAppSelector(state => state.currentUser);
  const { data, loading, error } = useAppSelector(state => state.cateringReport);

  const [selectedReport, setSelectedReport] = useState<CateringReport>(REPORTS[0]);
  const [schools, setSchools] = useState<SchoolStartupView[]>([]);
  const [filter, setFilter] = useState<CateringReportFilter | null>();
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    if (selectedReport) {
      if (selectedReport.value === 0 || selectedReport.value === 1 || selectedReport.value === 2) {
        setFilter(FILTER_DEFAULT);
      } else if (selectedReport.value === 3) {
        setFilter({
          startDate: null,
          endDate: null,
          studentId: null
        });
      }
    }
  }, [selectedReport]);

  useEffect(() => {
    user &&
      setSchools(
        [user.baseSchool, ...user.schools].filter((x) => x.enableLunchCredits)
      );
  }, [user]);

  useEffect(() => {
    !arrays.isEmpty(schools) &&
      setFilter({ ...filter, schoolId: schools[0]?.id });
  }, [schools]);


  const handleRunReport = () => {
    var lunchRecordQueryFilter: LunchRecordQueryFilter;
    var studentChoicesQueryFilter: StudentChoicesQueryFilter;
    var lunchNumbersFilter: LunchNumbersFilter;
    var errors: string[] = [];

    switch(selectedReport.value) {

      case 0:
      case 1:
      case 2: {
        if (filter.schoolId == null) {
          errors.push(`Please select a school.`);
        }
        lunchRecordQueryFilter = {
          date: filter.date 
            ? moment(filter.date).format("YYYY-MM-DD") 
            : null,
          schoolId: filter.schoolId,
          universalFreeSchoolMeal: filter.universalFreeSchoolMeal,
          tutorGroupId: filter.tutorGroupId === -1 
            ? null 
            : filter.tutorGroupId,
          lunchType: filter.lunchType === -1 
            ? null 
            : filter.lunchType,
          freeSchoolMeal: filter.freeSchoolMeal 
            ? true 
            : null,
          pupilPremium: filter.pupilPremium 
            ? true 
            : null,
        };
        break;
      }
      case 3: {
        if (filter.startDate == null) {
          errors.push(`Please select a start date.`);
        }
        if (filter.endDate == null) {
          errors.push(`Please select a end date.`);
        }
        if (filter.studentId == null) {
          errors.push(`Please select a student.`);
        }
        studentChoicesQueryFilter = {
          startDate: filter.startDate 
            ? moment(filter.startDate).format("YYYY-MM-DD") 
            : null,
          endDate: filter.endDate 
            ? moment(filter.endDate).format("YYYY-MM-DD") 
            : null,
          studentId: filter.studentId,
        }
        break;
      }
      case 4: {
        if (filter.schoolId == null) {
          errors.push(`Please select a school.`);
        }
        if (filter.startDate == null) {
          errors.push(`Please select a start date.`);
        }
        if (filter.endDate == null) {
          errors.push(`Please select a end date.`);
        }
        lunchNumbersFilter = {
          schoolId: filter.schoolId,
          startDate: filter.startDate 
          ? moment(filter.startDate).format("YYYY-MM-DD") 
          : null,
          endDate: filter.endDate 
            ? moment(filter.endDate).format("YYYY-MM-DD") 
            : null,
        }
        break;
      }
      default:
        break;
    }

    setValidationErrors(errors);
    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    switch (selectedReport.value) {
      case 0: // Pupil Daily Report
        lunchRegisterActions.getDailyLunchReport(lunchRecordQueryFilter, (reportData) => { files.openCsv(reportData); });
        break;
      case 1: // Student Balance Report
        lunchRegisterActions.getStudentBalanceReport(lunchRecordQueryFilter, (reportData) => { files.openCsv(reportData); });
        break;
      case 2: // Group Daily Report
        lunchRegisterActions.getDailyTutorGroupReport(lunchRecordQueryFilter, (reportData) => { files.openCsv(reportData); });
        break;
      case 3: //Single Pupil Choices Report
        lunchRegisterActions.getSingleStudentLunchChoices(studentChoicesQueryFilter, (reportData) => { files.openCsv(reportData); });
        break;
      case 4: //Dinner Numbers Report
        lunchRegisterActions.getLunchNumbers(lunchNumbersFilter, (reportData) => { files.openCsv(reportData); });
        break;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <Left>
        <List>
          {REPORTS.map((report, index) => (
            <List.Item
              key={index}
              text={report.name}
              onClick={() => setSelectedReport(report)}
              selected={report.value === selectedReport.value}
            />
          ))}
        </List>
      </Left>
      <Right>
        <Message text={error} color={Swatches.Danger} />
        <ValidationMessage errors={validationErrors} />
        <Card title={selectedReport.name}>
          <Card.Body>
            <StructuredList>
              {selectedReport.value === 0 && (
                <PupilDailyReport filter={filter} setFilter={setFilter} />
              )}
              {selectedReport.value === 1 && (
                <PupilBalanceReport filter={filter} setFilter={setFilter} />
              )}
              {selectedReport.value === 2 && (
                <GroupDailyReport filter={filter} setFilter={setFilter} />
              )}
              {selectedReport.value === 3 && (
                <SinglePupilChoicesReport filter={filter} setFilter={setFilter} />
              )}
              {selectedReport.value === 4 && (
                <DinnerNumbersReport filter={filter} setFilter={setFilter} />
              )}
            </StructuredList>
          </Card.Body>
        </Card>
        <ActionBar low>
          <Button
            text="Run Report"
            onClick={handleRunReport}
            color={Swatches.Success}
            working={loading}
            size={Size.Medium}
          />
        </ActionBar>
      </Right>
    </Wrapper>
  );
};

export default Reporting;
