import defaults from "reducers/defaults";
import classRoomActions from "../../actions/classViewer/classRoomActions";
import groupActions from "../../actions/group/groupActions";

const INITIAL_STATE = {
  roomLayout: null,
  refresh: false,
  actions: {
    getRoomLayout: defaults.INITIAL_ACTION_STATE,
    createRoomLayout: defaults.INITIAL_ACTION_STATE,
  },
};

const { GETROOMLAYOUT, CREATEROOMLAYOUT, GETROOM } = classRoomActions.types;
const { GETGROUP } = groupActions.types;

const classViewerRoomLayoutReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GETROOMLAYOUT.START:
      return {
        ...state,
        actions: {
          ...state.actions,
          getRoomLayout: defaults.LOADING_ACTION_STATE,
        },
      };

    case GETROOMLAYOUT.SUCCESS:
      return {
        ...state,
        roomLayout: action.payload?.[0],
        refresh: false,
        actions: {
          ...state.actions,
          getRoomLayout: defaults.INITIAL_ACTION_STATE,
        },
      };

    case GETROOMLAYOUT.FAILED:
      return {
        ...state,
        refresh: false,
        actions: {
          ...state.actions,
          getRoomLayout: defaults.ERROR_ACTION_STATE(action.payload),
        },
      };

    case CREATEROOMLAYOUT.START:
      return {
        ...state,
        actions: {
          ...state.actions,
          createRoomLayout: defaults.INITIAL_ACTION_STATE,
        },
      };

    case CREATEROOMLAYOUT.SUCCESS:
      return {
        ...state,
        refresh: true,
        actions: {
          ...state.actions,
          createRoomLayout: defaults.INITIAL_ACTION_STATE,
        },
      };

    case CREATEROOMLAYOUT.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          createRoomLayout: defaults.ERROR_ACTION_STATE(action.payload),
        },
      };

    case GETGROUP.START:
      return { ...state, roomLayout: null };

    case GETROOM.START:
      return {
        ...state,
        actions: {
          ...state.actions,
          createRoomLayout: defaults.INITIAL_ACTION_STATE,
        },
      };

    default:
      return state;
  }
};

export default classViewerRoomLayoutReducer;
