import React from "react";
import moment from "moment";
import styled from "styled-components";
import {
  displayFont,
  textFont,
  neutral,
  typescale,
  honeygold,
  BorderRadius,
  IUiKitBaseProps,
} from "../index";

const Wrapper = styled.span`
  display: block;
  text-align: center;
  max-width: 32px;
  padding: 0;

  .pretty-date-sub {
    display: block;
    text-transform: uppercase;
    font-family: ${textFont.medium.name};
    font-size: ${typescale.helper.size}rem;
    color: ${neutral[600]};
    letter-spacing: 1px;
  }

  .pretty-date-day {
    margin-bottom: 2px;
  }

  .pretty-date-date {
    display: flex;
    background: ${neutral[600]};
    background: ${honeygold};
    border-radius: ${BorderRadius.Default}px;
    margin: 0;
    align-items: center;
    justify-content: center;
    width: 28px;
    padding: 8px 0;
    margin-bottom: 4px;

    .pretty-date-inner {
      font-family: ${displayFont.medium.name};
      font-size: ${typescale.header4.size}rem;
      color: ${neutral[100]};
      margin: 0;
      padding: 0;
    }
  }
`;

interface IPrettyDateProps extends IUiKitBaseProps {
  date: Date;
}

const PrettyDate: React.FC<IPrettyDateProps> = ({ date, className }) => {
  var _date = moment(date);

  if (!date || !_date.isValid()) {
    return null;
  }

  return (
    <Wrapper className={`pretty-date ${className ? className : ""}`}>
      <span className="pretty-date-day pretty-date-sub">
        {_date.format("ddd")}
      </span>
      <span className="pretty-date-date">
        <span className="pretty-date-inner">{_date.format("DD")}</span>
      </span>
      <span className="pretty-date-month pretty-date-sub">
        {_date.format("MMM")}
      </span>
    </Wrapper>
  );
};

export default PrettyDate;
