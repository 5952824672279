import React from "react";
import { arrays } from "utils";
import styled from "styled-components";
import { CarRegistrationListView } from "types/users/userCars.types";

const Wrapper = styled.ul`
  margin-left: 0;
  padding-left: 0;

  li {
    list-style: none;
    padding-left: 0;
    margin-left: 0;
  }
`;


interface ICarRegistrationListProps {
  registrations: CarRegistrationListView[]
}

const CarRegistrationList: React.FC<ICarRegistrationListProps> = ({ registrations }) => {

  return !arrays.isEmpty(registrations) ? (
    <Wrapper>
      {registrations.map((registration, index) => (
        <li key={index}>{registration.registrationNumber}</li>
      ))}
    </Wrapper>
  ) : null;
};

export default CarRegistrationList;
