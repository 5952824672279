import interventionActions from "areas/planner/actions/interventions/interventionActions";
import { InterventionProgressRating } from "areas/planner/types/interventions/interventionShared";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import {
  ActionBar,
  Button,
  Dropdown,
  Left,
  Right,
  Size,
  Swatches,
} from "ui-kit";


interface InterventionProgressOption {
  key: number;
  label: string;
  value: number;
}

interface IInterventionProgressDropdownProps {
  value?: InterventionProgressRating | -1;
  showAllOption?: boolean;
  onChange?: (value: number) => void;
}


const InterventionProgressDropdown: React.FC<IInterventionProgressDropdownProps> = ({ value, showAllOption, onChange }) => {
  
  const [progressOptions, setProgressOptions] = useState<InterventionProgressOption[]>(() => {
    var opts: InterventionProgressOption[] = [];

    if (showAllOption) {
      opts.push({
        key: -1,
        label: "All",
        value: -1,
      });
    }
    
    for (var ivProg in InterventionProgressRating) {
      if (!isNaN(Number(ivProg))) {
        opts.push({
          key: Number(ivProg),
          label: InterventionProgressRating[ivProg],
          value: Number(ivProg),
        });
      }
    }

    return opts;
  });

  const handleChange = (value: number) => {
    onChange?.(value);
  };

  return (
    <Dropdown
      placeholder={"Please choose a progress rating"}
      value={value}
      onChange={handleChange}
      items={progressOptions}
      fluid
    />
  )
}

export default InterventionProgressDropdown;