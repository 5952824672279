import basketReducer from "./basketReducer";
import lunchCreditsReducer from "./lunchCreditsReducer";
import smartcardsReducer from "./smartcardsReducer";
import printCreditsReducer from "./printCreditsReducer";
import smartcardTransactionsReducer from "./smartcardTransactionsReducer";
import storeOrderReducer from "./storeOrderReducer";
import storeOrdersReducer from "./storeOrdersReducer";
import storeProductReducer from "./storeProductReducer";
import storeProductsReducer from "./storeProductsReducer";


const store = {
  basket: basketReducer,
  lunchCredits: lunchCreditsReducer,
  smartcards: smartcardsReducer,
  smartcardTransactions: smartcardTransactionsReducer,
  //printCredits: printCreditsReducer,
  storeOrder: storeOrderReducer,
  storeOrders: storeOrdersReducer,
  storeProduct: storeProductReducer,
  storeProducts: storeProductsReducer
};

export default store;
