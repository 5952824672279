import React, { useState, useEffect } from "react";
import styled from "styled-components";
import configuration from "../../configuration";
import { Editor } from "@tinymce/tinymce-react";
import {
  neutral,
  typescale,
  textFont,
  BorderRadius,
  fontStyle,
  honeygold,
  Speed,
  Spacing,
  formatScrollbars,
} from "../index";

const Wrapper = styled.div<{ noPad?: boolean }>`
  flex-grow: 1;
  flex-direction: column;
  display: flex;

  ${({ noPad }) => !noPad && `margin-bottom: ${Spacing.Medium}px;`}

  .tox-tinymce {
    flex-grow: 1;
    border: 0;
    height: 100%;
  }
  .tox .tox-toolbar__primary {
    background: ${neutral[300]};
    border-top-right-radius: ${BorderRadius.Default}px;
    border-top-left-radius: ${BorderRadius.Default}px;
  }

  .tox .tox-toolbar__group {
    padding: 0 !important;
    border: 0 !important;

    &:first-child {
      .tox-tbtn:first-child {
        border-top-left-radius: ${BorderRadius.Default}px;
      }
    }
  }

  .tox .tox-tbtn {
    margin: 0;
    background: ${neutral[300]};
    border-radius: 0;
    border-right: 1px solid ${neutral[100]};
    span svg {
      fill: ${neutral[700]};
    }

    &:hover {
      transition: all ease ${Speed.Default}ms;
      border-right: 1px solid ${neutral[100]};
      background: ${honeygold};
    }
  }

  .tox .tox-edit-area__iframe {
    background: ${neutral[100]};
    border-bottom-right-radius: ${BorderRadius.Default}px;
    border-bottom-left-radius: ${BorderRadius.Default}px;
    border: 1px solid ${neutral[300]};
  }

  .mce-content-body {
    ${fontStyle(textFont.roman, typescale.paragraph, neutral[600])}
  }

  .iframe {
    ${formatScrollbars(neutral[100])}
  }
`;

export interface IRichTextEditorProps {
  value?: string;
  initialValue?: string;
  onChange?(value: string | null): void;
  onBlur?(): void;
  onFocus?(): void;
  className?: string;
  noPad?: boolean;
}

const RichTextEditor: React.FC<IRichTextEditorProps> = ({
  value,
  initialValue,
  onChange,
  onBlur,
  onFocus,
  className,
  noPad,
}) => {
  const [editorValue, setEditorValue] = useState<undefined | string>("");

  useEffect(() => {
    setEditorValue(initialValue || "");
  }, [initialValue]);

  const handleEditorChange = (details: string) => {
    setEditorValue(details);
    onChange?.(details);
  };

  const handleFocus = () => {
    onFocus?.();
  };
  const handleBlur = () => {
    onBlur?.();
  };

  return (
    <Wrapper
      className={`rich-text-editor ${className ? className : ""}`}
      noPad={noPad}
    >
      <Editor
        tinymceScriptSrc='/tinymce/tinymce.min.js'
        apiKey={configuration.ui.editorApiKey}
        value={editorValue}
        init={{
          browser_spellcheck: true,
          contextmenu: false,
          allow_html_in_named_anchor: false,
          invalid_elements: "span,br,div,u",
          valid_elements:
            "a[href|target=_blank],-p,-ul,-ol,-li,-h1,-h2,-h3,-strong,-em",
          branding: false,
          menubar: false,
          statusbar: false,
          plugins: [
            "autolink",
            "lists",
            "link",
            "image",
            "preview",
            "anchor",
            "searchreplace",
            "insertdatetime",
            "media",
            "table",
            "code"
          ],
          toolbar: "bold italic | bullist numlist | link",
          content_css: "/overrides.css",
        }}
        onEditorChange={handleEditorChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </Wrapper>
  );
};

export default RichTextEditor;
