import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProvisionsTable from "./provisionsTable";
import ProvisionFlyout from "./provisionFlyout";
import { Constants } from "configuration";
import { ActionBar, Button, Left, Right, Size, Swatches } from "ui-kit";
import { MultiSchoolUserView, SchoolDropdown } from "sharedComponents/common";
import ProviderDropdown from "../providerDropdown";
import AreaOfNeedDropdown from "./areaOfNeedDropdown";
import { arrays } from "utils";
import ProvisionTypeDropdown from "./provisionTypeDropdown";
import flyoutActions from "actions/ui/flyouts";
import provisionsActions from "areas/send/actions/provisions/provisionsActions";
import { RootState } from "reducers/store";
import { ProvisionQueryFilter } from "areas/send/types/passportRequest.types";
import { ProvisionListView } from "areas/send/types/passportResponse.types";
import ProvisionStatusDropdown from "./ProvisionStatusDropdown";
import { ProvisionStatus } from "areas/send/types/passportShared.types";


const Provisions = () => {

  const { provisions, loading, error, filter } = useSelector((state: RootState) => state.provisions);
  const [_selectedProvision, _setSelectedProvision] = useState<Partial<ProvisionListView | null>>(null);
  const [_filter, _setFilter] = useState<ProvisionQueryFilter | null>(null);

  const handleEditProvision = (provision: ProvisionListView) => {
    _setSelectedProvision(provision);
    flyoutActions.openFlyout(Constants.FLYOUTS.SEND_PROVISION);
  };

  const handleNewProvision = () => {
    _setSelectedProvision({
      id: null
    });
    flyoutActions.openFlyout(Constants.FLYOUTS.SEND_PROVISION);
  };

  useEffect(() => {
    arrays.isEmpty(provisions) && provisionsActions.getProvisions(null, 0);
  }, []);

  useEffect(() => {
    if (_filter) {
      provisionsActions.filterProvisions(_filter);
      provisionsActions.getProvisions(_filter, 0);
    }
  }, [_filter]);

  const handleSchoolFilterChange = (value: number) => {
    _setFilter({ ..._filter, schoolId: value });
  };

  const handleProviderFilterChange = (value: number) => {
    _setFilter({ ..._filter, providerId: value });
  };

  const handleAreaOfNeedFilterChange = (value: number) => {
    _setFilter({ ..._filter, areaOfNeed: value });
  };

  const handleProvisionTypeFilterChange = (value: number) => {
    _setFilter({ ..._filter, provisionType: value });
  };

  const handleProvisionStatusFilterChange = (value: ProvisionStatus) => {
    _setFilter({ ..._filter, provisionStatus: value });
  }

  return (
    <>
      <ActionBar>
        <Left>
          <ActionBar.Filter>
            <MultiSchoolUserView>
              <ActionBar.FilterItem>
                <SchoolDropdown
                  initialValue={filter.schoolId}
                  fluid
                  includeAllOption
                  placeholder="School"
                  onChange={handleSchoolFilterChange}
                  showAllSchools
                />
              </ActionBar.FilterItem>
            </MultiSchoolUserView>
            <ActionBar.FilterItem>
              <ProviderDropdown
                placeholder="Provider"
                onChange={handleProviderFilterChange}
                includeAllOption
                value={filter.providerId}
              />
            </ActionBar.FilterItem>
            <ActionBar.FilterItem>
              <AreaOfNeedDropdown
                placeholder="Area of Need"
                onChange={handleAreaOfNeedFilterChange}
                includeAllOption
                value={filter.areaOfNeed}
              />
            </ActionBar.FilterItem>
            <ActionBar.FilterItem>
              <ProvisionTypeDropdown
                placeholder="Provision Type"
                onChange={handleProvisionTypeFilterChange}
                includeAllOption
                value={filter.provisionType}
              />
            </ActionBar.FilterItem>
            <ActionBar.FilterItem>
              <ProvisionStatusDropdown
                placeholder="Provision Status"
                onChange={handleProvisionStatusFilterChange}
                includeAllOption
                value={filter.provisionStatus}
              />
            </ActionBar.FilterItem>
          </ActionBar.Filter>
        </Left>
        <Right>
          <Button
            size={Size.Small}
            text="New Provision"
            onClick={handleNewProvision}
            color={Swatches.Primary}
          />
        </Right>
      </ActionBar>
      <ProvisionsTable
        provisions={provisions}
        loading={loading}
        error={error}
        onNew={handleNewProvision}
        onEdit={handleEditProvision}
        showProvider
      />
      <ProvisionFlyout provision={_selectedProvision} />
    </>
  );
};

export default Provisions;
