import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  Modal,
  Button,
  Right,
  ActionBar,
  Swatches,
  Size,
  EmptyMessage,
  List,
  Message,
  ToastService,
  PickList,
} from "ui-kit";
import searchEngineActions from "areas/search/actions/searchEngineActions";
import userManagementActions from "areas/administration/actions/userManagementActions";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import SchoolIcon from "sharedComponents/common/schools/icons/schoolIcon";
import { RootState } from "reducers/store";
import { managedUser, managedParent, managedStudent, managedStaff, managedUserAccount } from "areas/administration/reducers/userManagement/managedUserReducer";
import { PagedQueryView, SearchResultView, SearchResultViewBase } from "types/common/views.types";
import { SchoolListView } from "types/schools/schools.types";


const Wrapper = styled.div`
  display: flex;
  height: 100%;

  .picklist {
    flex-grow: 1;
  }
`;


interface IStaffDepartmentsModalProps {
  user: managedStaff;
  open: boolean;
  onCancel?: () => void;
  onSaved?: (departments: number[]) => void;
}

interface StaffDepartmentOption {
  label: string;
  sub?: string;
  value: {
    id: string;
    school: SchoolListView;
  }
}


const StaffDepartmentsModal: React.FC<IStaffDepartmentsModalProps> = ({ user, open, onCancel, onSaved }) => {

  const { actions } = useSelector((state: RootState) => state.managedUser);
  const {
    departments,
    loading: loadingDepartments,
    error: departmentsError,
  } = useSelector((state: RootState) => state.searchDepartments);

  const [_user, _setUser] = useState<managedUserAccount | null>(null);
  const [_open, _setOpen] = useState<boolean>(false);
  const [_departmentOptions, _setDepartmentOptions] = useState<StaffDepartmentOption[]>([]);
  const [selectedSchool, setSelectedSchool] = useState<number>(user.school.id);
  const [_selectedDepartments, _setSelectedDepartments] = useState<StaffDepartmentOption[]>([]);

  const mapDepartments = (depts: SearchResultViewBase[]) : StaffDepartmentOption[] => {
    // Method to return picklist options from mapped departments
    var opts = depts.map(dept => ({
      label: dept.title,
      sub: dept.description,
      value: { id: dept.id, school: dept.school },
    }));

    return opts;
  };

  const getDepartments = (schoolId: number) => {
    searchEngineActions.searchDepartments(null, [schoolId], (data: PagedQueryView<SearchResultViewBase>) => {
      _setDepartmentOptions(mapDepartments(data.items));
    });
  };

  useEffect(() => {
    _setUser(user);
    _setSelectedDepartments(
      user.departments.map(dept => ({
        label: dept.name,
        sub: dept.school.name,
        value: { id: `${dept.id}`, school: dept.school },
      }))
    );
    getDepartments(user.school.id);
  }, [user]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const handleSave = () => {
    var _depts = _selectedDepartments.map(dept => parseInt(dept.value.id));
    const data = {
      userId: _user.id,
      departments: _depts,
    };

    userManagementActions.setStaffDepartments(_user.id, data, () => {
      onSaved?.(_depts);
      ToastService.pop("Departments Updated", null, "user");
    });
  };

  const handlePicked = (value: StaffDepartmentOption[]) => {
    _setSelectedDepartments(value);
  };

  const handleSchoolChange = (value: number) => {
    setSelectedSchool(value);
    getDepartments(value);
  };

  return (
    <Modal
      width="80%"
      height="60%"
      title="Departments"
      open={_open}
      onClose={onCancel}
    >
      <Modal.Body>
        <Message text={actions.updateJob?.error} color={Swatches.Danger} />
        {_user ? (
          <Wrapper>
            <PickList
              options={_departmentOptions}
              optionsTitle="Available Departments"
              optionsEmptyContent={
                <EmptyMessage
                  title="No departments"
                  summary="No departments are available"
                  cover
                />
              }
              optionsHeader={
                <SchoolDropdown
                  fluid
                  schools={[...user.schools, user.school]}
                  includeTrust={user.isTrust}
                  initialValue={selectedSchool}
                  onChange={handleSchoolChange}
                />
              }
              selected={_selectedDepartments}
              selectedTitle="Selected Departments"
              selectedEmptyContent={
                <EmptyMessage
                  title="No departments"
                  summary="No departments have been assigned"
                  cover
                />
              }
              template={opt => (
                <List.Item
                  text={opt.label}
                  sub={opt.sub}
                  value={opt.value}
                  left={
                    <SchoolIcon
                      code={opt.value?.school?.code}
                      size={Size.Small}
                    />
                  }
                ></List.Item>
              )}
              onChange={handlePicked}
              loadingOptions={loadingDepartments}
              sortExpression={(a, b) => a.label > b.label}
            />
          </Wrapper>
        ) : (
          <EmptyMessage
            icon="user"
            title="No Staff Member"
            summary="No staff member was selected"
            cover
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              onClick={onCancel}
              size={Size.Small}
              color={Swatches.Low}
              text="Cancel"
            />
            <Button
              onClick={handleSave}
              size={Size.Small}
              color={Swatches.Success}
              text="Save"
              working={actions.updateDepartments?.working}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default StaffDepartmentsModal;
