import React, { useEffect, useState } from "react";
import { ActionBar, Dropdown } from "ui-kit";
import { SchoolDropdown } from "sharedComponents/common";
import FormCategoryDropDown from "areas/forms/components/filters/formCategoryDropdown";
import FormTypeDropdown from "areas/forms/components/filters/formTypeDropdown";
import FormStatusDropdown from "areas/forms/components/filters/formStatusDropdown";
import { TrayListItemType } from "../types/fileRequest.types";
import { ApprovalStatus, LeaveRequestType } from "areas/humanResources/types/leaveShared.types";
import { FormCategoryListView, FormListView } from "areas/forms/types/formResponse.types";
import { IInTrayFilter } from "../reducers/inTrayReducer";
import { IOutTrayFilter } from "../reducers/outTrayReducer";
import { ISubmissionFilter } from "areas/forms/reducers/submissionFilterReducer";


interface IFilingFilterProps {
  filter: IInTrayFilter | IOutTrayFilter;
  onSchoolChange: (schoolId: number) => void;
  onFormCategoryChange: (cat: FormCategoryListView) => void;
  onFormTypeChange: (formType: FormListView) => void;
  onTypeChange: (type: TrayListItemType) => void;
  onLeaveRequestTypeChange: (type: LeaveRequestType) => void;
  onStatusChange: (status: ApprovalStatus) => void;
}

const FilingFilter: React.FC<IFilingFilterProps> = ({
  filter,
  onSchoolChange,
  onFormCategoryChange,
  onFormTypeChange,
  onTypeChange,
  onLeaveRequestTypeChange,
  onStatusChange,
}) => {

  const [_filter, _setFilter] = useState<IInTrayFilter | IOutTrayFilter>(filter);

  useEffect(() => {
    _setFilter(filter);
  }, [filter]);

  return _filter ? (
    <>
      <ActionBar>
        <SchoolDropdown
          placeholder="School"
          onChange={onSchoolChange}
          initialValue={_filter.schoolId}
          includeTrust
          includeAllOption
        />
        <Dropdown value={_filter.type} onChange={onTypeChange}>
          <Dropdown.Item value={null} label="All Types" />
          <Dropdown.Item value={0} label="Forms" />
          <Dropdown.Item value={1} label="Leave Requests" />
        </Dropdown>

        {_filter.type === 1 && (
          <Dropdown
            value={_filter.leaveRequestType}
            onChange={onLeaveRequestTypeChange}
          >
            <Dropdown.Item value={null} label="All Leave Requests" />
            <Dropdown.Item value={0} label="Holiday" />
            <Dropdown.Item value={1} label="Time off in Lieu" />
            <Dropdown.Item value={2} label="Authorised Absence" />
            <Dropdown.Item value={3} label="Compassionate Leave" />
            <Dropdown.Item value={5} label="Golden Time" />
          </Dropdown>
        )}

        {_filter.type === 0 && (
          <>
            <FormCategoryDropDown
              onChange={onFormCategoryChange}
              initialValue={_filter.category}
              filter={_filter as ISubmissionFilter}
            />
            {_filter.category?.id !== -1 && (
              <FormTypeDropdown
                onChange={onFormTypeChange}
                initialValue={_filter.formType}
                selectedCategory={_filter.category}
              />
            )}
          </>
        )}
        <FormStatusDropdown
          onChange={onStatusChange}
          initialValue={_filter.status}
        />
      </ActionBar>
    </>
  ) : null;
};

export default FilingFilter;
