import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Swatches, danger, neutral } from "ui-kit/common/colors";
import { Message, EmptyMessage, Loader, Size } from "ui-kit";
import AddBehaviourPointModal from "./addBehaviourPointModal";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import Accordion from "sharedComponents/common/accordion/accordion";
import SelectionButtons from "sharedComponents/common/largeSelectionButtons/selectionButtons";
import SelectionButton from "sharedComponents/common/largeSelectionButtons/selectionButton";
import { useAppSelector } from "reducers/hooks";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";
import { ClassGroupStaffView } from "types/users/userGroups.types";
import { SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";


interface IBehaviourPointsAccordionProps {
  student: PlannerStudentDetailView;
  group?: ClassGroupStaffView;
  tabIndex?: number;
  currentTabIndex?: number;
}


const BehaviourPointsAccordion: React.FC<IBehaviourPointsAccordionProps> = ({
  student,
  group,
  tabIndex,
  currentTabIndex,
}) => {

  const { schoolId, behaviourCategoryGroups, getBehavioursError, loading } =
    useAppSelector(state => state.behaviourCategories);

  const [selectedCategory, setSelectedCategory] = useState<SchoolBehaviourCategoryListView>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedArrowBehaviourGroupKey, setSelectedArrowBehaviourGroupKey] =
    useState<number>(null);
  const [selectedBehaviourGroupKey, setSelectedBehaviourGroupKey] =
    useState<number>(null);

  useEffect(() => {
    if (
      (tabIndex === undefined || tabIndex === currentTabIndex) &&
      (!behaviourCategoryGroups ||
        behaviourCategoryGroups.length === 0 ||
        schoolId !== student.school.id)
    ) {
      behaviourActions.getBehaviourCategories(
        student.school.id,
        {
          isLegacy: null,
          canBeAwarded: true,
          behaviourCodeId: null
        },
        () => behaviourActions.setBehaviourSchoolId(student.school.id)
      );
    }
  }, [student, currentTabIndex]);

  const handlePointClick = (selectedCategory: SchoolBehaviourCategoryListView) => {
    setSelectedBehaviourGroupKey(selectedCategory.behaviourCodeId);
    setSelectedCategory(selectedCategory);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedCategory(null);
  };

  const onClickBehaviourGroupArrow = (accordionItemKey: number) => {
    if (selectedArrowBehaviourGroupKey === accordionItemKey) {
      setSelectedArrowBehaviourGroupKey(null);
    } else {
      setSelectedArrowBehaviourGroupKey(accordionItemKey);
    }
  };

  const onClickBehaviourGroup = (accordionItemKey: number, behaviourCodeId: number) => {
    setSelectedBehaviourGroupKey(behaviourCodeId);
    setOpen(true);
  };

  if (getBehavioursError) {
    return (
      <Message
        text="There was a problem loading the categories"
        color={Swatches.Danger}
      />
    );
  }

  if (loading) {
    return <Loader size={Size.Medium} fluid />;
  }

  return behaviourCategoryGroups && behaviourCategoryGroups.length > 0 ? (
    <>
      <Accordion>
        {behaviourCategoryGroups.map((categoryGroup, index) => (
          <Accordion.Item
            key={index}
            accordionItemKey={index}
            title={categoryGroup.behaviourCodeName}
            sub={categoryGroup.description}
            expandedItemKey={selectedArrowBehaviourGroupKey}
            onClickAccordionItemArrow={onClickBehaviourGroupArrow}
            onClickItem={onClickBehaviourGroupArrow}
            // onClickItem={key =>
            //   onClickBehaviourGroup(key, categoryGroup.behaviourCodeId)
            // }
          >
            <SelectionButtons listView={true}>
              {categoryGroup.behaviourCategories.map(category => (
                <SelectionButton
                  key={category.id}
                  listView
                  item={{ ...category, behaviourGroupId: index }}
                  itemLabel={category.codeDisplayName}
                  itemSub={category.behaviourCategoryName}
                  icon="thumbs-down"
                  iconColour={danger}
                  colour={neutral[200]}
                  handleItemClick={handlePointClick}
                />
              ))}
            </SelectionButtons>
          </Accordion.Item>
        ))}
      </Accordion>

      <AddBehaviourPointModal
        open={open}
        handleCloseModal={handleCloseModal}
        selectedCategory={selectedCategory}
        onSelectedCategoryChange={category => setSelectedCategory(category)}
        selectedBehaviourGroupKey={selectedBehaviourGroupKey}
        student={student}
        behaviourCategoryGroups={behaviourCategoryGroups}
        groupId={group?.id}
        groupType={group?.type}
      />

      {/* <StudentModal
          title={
            selectedCategory
              ? `${selectedCategory.codeDisplayName}: ${selectedCategory.behaviourCategoryName}`
              : "Behaviour"
          }
          onClose={handleCloseModal}
          open={open}
          height="90%"
          width="75%"
          student={student}
        >
          <BehaviourPointFormWrapper
            student={student}
            selectedCategory={selectedCategory}
            handleBehaviourCategoryChange={handleBehaviourCategoryChange}
            selectedBehaviourGroupKey={selectedBehaviourGroupKey}
            behaviourCategories={
              selectedBehaviourGroupKey !== null
                ? behaviourCategoryGroups[selectedBehaviourGroupKey]
                    .behaviourCategories
                : []
            }
            handleCloseModal={handleCloseModal}
            groupId={group ? group.id : null}
            groupType={group ? group.type : null}
          />
        </StudentModal> */}
    </>
  ) : (
    <EmptyMessage icon="list" title="No Categories" />
  );
};

export default BehaviourPointsAccordion;
