import { CalendarEventMenuItemListView } from "areas/calendar/types/calendarResponses.types";
import React, { useEffect, useState } from "react";
import {
  Modal,
  ActionBar,
  Button,
  Size,
  Swatches,
  Card,
  StructuredList,
  Left,
  Right,
  Dropdown,
  TextInput,
  TextInputType,
  Checkbox,
  ValidationMessage,
} from "ui-kit";
import { arrays } from "utils";


interface IEventMenuItemEditorModalProps {
  menuItem: CalendarEventMenuItemListView;
  open: boolean;
  onClose?: () => void;
  onSave?: (menuItem: CalendarEventMenuItemListView) => void;
  onDelete?: (menuItem: CalendarEventMenuItemListView) => void;
}


const EventMenuItemEditorModal: React.FC<IEventMenuItemEditorModalProps> = ({
  menuItem,
  open,
  onClose,
  onSave,
  onDelete,
}) => {

  const [_open, _setOpen] = useState<boolean>(open);
  const [_menuItem, _setMenuItem] = useState<CalendarEventMenuItemListView>(menuItem);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    _setMenuItem(menuItem);

    setValidationErrors([]);
  }, [menuItem]);

  const handleDelete = () => {
    onDelete?.(_menuItem);
  };

  const handleSave = () => {
    var errors = [];

    if (!_menuItem.name.length) {
      errors.push(`Please enter a name.`);
    }

    if (!_menuItem.code.length) {
      errors.push(`Please enter a code.`);
    }

    if (_menuItem.type == null || _menuItem.typeName == null) {
      errors.push(`Please select the course.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }
    onSave?.(_menuItem);
  };

  return _menuItem ? (
    <Modal
      title="Edit Menu Item"
      open={_open}
      onClose={onClose}
      width="80%"
      height="60%"
    >
      <Modal.Body scrollOnErrors={validationErrors}>
        <ValidationMessage errors={validationErrors} />
        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item
                name="Course"
                description="Please choose the course."
                required
              >
                <Dropdown
                  onChange={val => {
                    _setMenuItem({
                      ..._menuItem,
                      type: val.value,
                      typeName: val.name,
                    });
                  }}
                  value={{ value: _menuItem.type, name: _menuItem.typeName }}
                  fluid
                >
                  <Dropdown.Item
                    value={{ name: "Canape", value: 0 }}
                    label="Canape"
                  />
                  <Dropdown.Item
                    value={{ name: "Starter", value: 1 }}
                    label="Starter"
                  />
                  <Dropdown.Item
                    value={{ name: "Main", value: 2 }}
                    label="Main"
                  />
                  <Dropdown.Item
                    value={{ name: "Dessert", value: 3 }}
                    label="Dessert"
                  />
                </Dropdown>
              </StructuredList.Item>
              <StructuredList.Item
                name="Name"
                description="Please enter the name of the dish."
                required
              >
                <TextInput
                  value={_menuItem.name}
                  onChange={value =>
                    _setMenuItem({ ..._menuItem, name: value })
                  }
                  fluid
                />
              </StructuredList.Item>
              <StructuredList.Item
                name="Code"
                description="Please enter a code for this dish. The code will be displayed on reports."
                required
              >
                <TextInput
                  value={_menuItem.code}
                  maxLength={5}
                  onChange={value =>
                    _setMenuItem({ ..._menuItem, code: value })
                  }
                  fluid
                />
              </StructuredList.Item>
              <StructuredList.Item
                name="Description"
                description="You can optionally add an extra description about the dish."
              >
                <TextInput
                  type={TextInputType.Textarea}
                  value={_menuItem.description}
                  onChange={value =>
                    _setMenuItem({ ..._menuItem, description: value })
                  }
                  rows={2}
                  fluid
                />
              </StructuredList.Item>
              <StructuredList.Item
                name="Vegetarian"
                description="This dish is suitable for vegetarians. It does not contain meat or fish but may contain dairy products."
              >
                <Checkbox
                  checked={_menuItem.vegetarian}
                  text={`Vegetarian`}
                  onChange={value => {
                    _setMenuItem({
                      ..._menuItem,
                      vegetarian: value.checked,
                    });
                  }}
                />
              </StructuredList.Item>
              <StructuredList.Item
                name="Vegan"
                description="This dish is suitable for vegans and does not contain meat, fish or dairy products."
              >
                <Checkbox
                  checked={_menuItem.vegan}
                  text={`Vegan`}
                  onChange={value => {
                    _setMenuItem({
                      ..._menuItem,
                      vegan: value.checked,
                    });
                  }}
                />
              </StructuredList.Item>
              <StructuredList.Item
                name="Halal"
                description="This dish contains Halal meat."
              >
                <Checkbox
                  checked={_menuItem.halal}
                  text={`Halal`}
                  onChange={value => {
                    _setMenuItem({
                      ..._menuItem,
                      halal: value.checked,
                    });
                  }}
                />
              </StructuredList.Item>
              <StructuredList.Item
                name="Allergy"
                description="This dish is intended for people with allergies. Please also indicate in the description which allergens are included and/or excluded."
              >
                <Checkbox
                  checked={_menuItem.allergy}
                  text={`Allergy`}
                  onChange={value => {
                    _setMenuItem({
                      ..._menuItem,
                      allergy: value.checked,
                    });
                  }}
                />
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Left>
            <Button
              text="Close"
              onClick={onClose}
              color={Swatches.Low}
              size={Size.Small}
            />
            {_menuItem?.id && (
              <Button
                text="Delete"
                onClick={handleDelete}
                color={Swatches.Low}
                size={Size.Small}
              />
            )}
          </Left>
          <Right>
            <Button
              text="Save"
              onClick={handleSave}
              color={Swatches.Success}
              size={Size.Small}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  ) : null;
};

export default EventMenuItemEditorModal;
