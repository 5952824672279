import React, { useState } from "react";
import PublicTemplate from "sharedComponents/layout/public/publicTemplate";
import { 
  Wizard, 
  TextInput, 
  Card, 
  StructuredList 
} from "ui-kit";
import { useNavigate } from "react-router";
import passwordActions from "areas/administration/actions/passwordActions";


const ForgotPassword = () => {

  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [working, setWorking] = useState<boolean>(false);

  const handleFinish = () => {
    return new Promise((resolve, reject) => resolve(true));
  };

  const handleComplete = () => {
    navigate("/");
  };

  const handleReset = () => {
    setWorking(true);

    return new Promise((resolve, reject) => {
      if (!email) {
        setWorking(false);
        reject("Please enter your email.");
      } else {
        passwordActions
          .forgot({ email })
          .then(response => {
            resolve(true);
          })
          .catch(error => {
            reject(
              "Your password could not be reset at this time. Please check your email address is correct and try again."
            );
          })
          .finally(() => {
            setWorking(false);
          });
      }
    });
  };

  return (
    <PublicTemplate title="Reset Your Password">
      <Wizard
        finishButtonText="Send Password Reset Link"
        showHeaderSteps={false}
        onFinish={handleFinish}
        onComplete={handleComplete}
        completionButtonText="Return to Login"
        working={working}
      >
        <Wizard.Step onValidate={handleReset}>
          <Card>
            <Card.Body>
              <p>
                If you have forgotten your password, you can reset it by
                entering your registered email address below, and an email with
                a unique password reset link will be sent to you.
              </p>
              <p>
                Please note that staff and students cannot use this form and
                should contact IT Support to reset their password.
              </p>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Your Email" required>
                  <TextInput
                    fluid
                    value={email}
                    onChange={value => setEmail(value)}
                    maxLength={256}
                  />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
        </Wizard.Step>
        <Wizard.Step completion>
          <Card>
            <Card.Body>
              <p>
                Your password reset request was received and instructions on how
                to set a new password have been sent to your email. If you do
                not receive this email within five minutes please check your
                Junk Mail folder.
              </p>
              <p>
                If you have still not received it, please contact IT Support at
                your child's school.
              </p>
            </Card.Body>
          </Card>
        </Wizard.Step>
      </Wizard>
    </PublicTemplate>
  );
};

export default ForgotPassword;
