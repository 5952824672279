import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAction } from "hooks";
import { Constants } from "configuration";
import paymentActions from "areas/payments/actions/paymentsActions";
import styled from "styled-components";
import {
  neutral,
  typescale,
  Swatches,
  Size,
  Message,
  displayFont,
  Button,
  HeadlineStatistic,
  Subtitle,
  CircleIcon,
  ToastService,
  Spacing,
  BorderRadius,
  ActionPanel,
  Tabs,
  fontStyle,
  Table,
  TabBar,
  EmptyMessage,
  Loader,
  DateTime,
} from "ui-kit";
import flyoutActions from "actions/ui/flyouts";
import LunchCreditAdjustmentFlyout from "../lunch/lunchCreditAdjustmentFlyout";
import { arrays, types, users } from "utils";
import { RootState } from "reducers/store";
import { LunchCreditBalanceView } from "areas/payments/types/catering/cateringResponses.types";
import { AddLunchCreditToBasketCommand } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";
import { PaymentUser, PaymentsParent, PaymentsStudent } from "areas/payments/reducers/payments/paymentsUserReducer";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .freeSchoolMeals {
    margin: ${Spacing.ExtraLarge}px 0;
  }
  .topups {
    display: flex;

    .topup {
      margin-right: ${Spacing.Medium}px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const TopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
  background: ${neutral[100]};
  margin-right: ${Spacing.Large}px;
  margin-bottom: ${Spacing.Large}px;
  padding: ${Spacing.Large}px;
  border-radius: ${BorderRadius.Default}px;

  &:last-child {
    margin-right: 0;
  }

  .circle-icon {
    margin-bottom: 4px;
    color: ${neutral[300]};
  }
  .total {
    ${fontStyle(displayFont.extraLight, typescale.header2, neutral[700])}
  }
  .caption {
    ${fontStyle(displayFont.bold, typescale.paragraph, neutral[700])}
  }
`;


interface IUserPrintCreditsProps {
  user: PaymentUser | PaymentsParent | PaymentsStudent;
}


const UserLunchCredits: React.FC<IUserPrintCreditsProps> = ({ user }) => {

  const { working: basketWorking, errors: basketError } = useSelector((state: RootState) => state.userBasket);
  const { transactions, loading: loadingTransactions, error: transactionsError } = useSelector((state: RootState) => state.userLunchTransactions);
  const { accounts, loading: loadingAccounts, error: accountsError } = useSelector((state: RootState) => state.userLunchCredits);

  const [activeAccount, setActiveAccount] = useState<LunchCreditBalanceView | null>(null);

  var balance = 0;

  const loadDetails = () => {
    user && paymentActions.getUserLunchCreditBalance(user.id);
    user && paymentActions.getUserLunchTransactions(user.id);
  };

  useEffect(() => {
    loadDetails();
  }, []);

  useEffect(() => {
    if (accounts) {
      if (activeAccount != null) {
        setActiveAccount(accounts.filter((x) => x.school.id === activeAccount.school.id)[0]);
      } else {
        setActiveAccount(accounts[0]);
      }
    }
  }, [accounts]);

  const handleAddToBasket = (value: number) => {
    const data: AddLunchCreditToBasketCommand = {
      userId: user.id,
      recipientId: user.id,
      quantity: value,
      schoolId: activeAccount.school.id,
    };
    paymentActions.addLunchCreditToBasket(user.id, data, () => {
      ToastService.pop("Lunch Credit added to basket", null, "shopping-bag");
    });
  };

  const onBalanceAdjusted = (adjustmentValue: number) => {};

  const handleAdjustBalance = () => {
    flyoutActions.openFlyout(Constants.FLYOUTS.LUNCHCREDITADJUSTMENT);
  };

  const handleTabClicked = (name: string, index: number) => {
    setActiveAccount(accounts[index]);
  };

  return (
    <>
      {accounts.length > 1 && (
        <TabBar onItemClicked={handleTabClicked}>
          {accounts.map((card, index) => (
            <TabBar.Item
              key={index}
              name={`${card.school.code}`}
              text={card.school.code}
            />
          ))}
        </TabBar>
      )}
      {activeAccount && (
        <Wrapper>
          { types.isType<PaymentsStudent>(user, "freeSchoolMeal") && user.freeSchoolMeal ? (
            <EmptyMessage
              className="freeSchoolMeals"
              icon="utensils"
              title={`${user.firstName} ${user.lastName} gets free school meals`}
              summary={`As ${user.firstName} ${user.lastName} is automatically entitled to free school meals, lunch credits cannot be purchased.`}
              cover
            />
          ) : (
            <>
              <HeadlineStatistic>
                <HeadlineStatistic.Item
                  icon="utensils"
                  value={activeAccount.balance}
                  label="Lunch Credit Balance"
                />
              </HeadlineStatistic>
              <Subtitle text="Lunch Credit Topups" />
              {basketError?.item && (
                <Message
                  text="There was a problem adding the topup to the basket"
                  color={Swatches.Danger}
                />
              )}
              <div className="topups">
                {Constants.LUNCH_AMOUNTS.map((value) => (
                  <TopupWrapper className="topup">
                    <CircleIcon value="utensils" outline color={Swatches.Low.swatch} />
                    <span className="total">{value}</span>
                    <span className="caption">School Lunches</span>
                    <Button
                      text={`Add to Basket`}
                      size={Size.Small}
                      onClick={() => handleAddToBasket(value)}
                      color={Swatches.Primary}
                      working={basketWorking}
                      tooltip={`Add ${value} School Lunches to Basket`}
                      tooltipSub={`Add ${value} school meal credits to your basket`}
                    />
                  </TopupWrapper>
                ))}
              </div>
            </>
          )}

          <Tabs>
            <Tabs.Pane label="History">
              {loadingTransactions && <Loader cover size={Size.Medium} />}
              {!loadingTransactions && arrays.isEmpty(transactions) && (
                <EmptyMessage
                  icon="utensils"
                  title="No History"
                  summary="There is no lunch history"
                  cover
                />
              )}

              {!loadingTransactions && !arrays.isEmpty(transactions) && (
                <Table grow>
                  <Table.Header>
                    <Table.HeaderCell width={2}>Date</Table.HeaderCell>
                    <Table.HeaderCell width={7}>Details</Table.HeaderCell>
                    <Table.HeaderCell width={1} right>
                      Credits
                    </Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    {transactions.map((item, index) => (
                      <Table.Row key={index}>
                        <Table.Cell width={2}>
                          <DateTime date={item.date} bold />
                        </Table.Cell>
                        <Table.Cell width={7}>
                          {item.recordDetails &&
                            item.recordDetails.lunchTypeName}
                          {item.adjustmentDetails &&
                            item.adjustmentDetails.adjustmentReasonName}
                        </Table.Cell>
                        <Table.Cell width={1} right>
                          {item.total}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )}
            </Tabs.Pane>
            <Tabs.Pane label="Actions">
              <ActionPanel
                label="Adjust Lunch Credit Balance"
                sub="This allows manual adjustments to be made to the lunch credit balance"
                buttonText="Adjust Balance"
                buttonColor={Swatches.Primary}
                onClick={handleAdjustBalance}
              />
              <LunchCreditAdjustmentFlyout
                user={user}
                onAdjusted={onBalanceAdjusted}
                school={activeAccount.school}
              />
            </Tabs.Pane>
          </Tabs>
        </Wrapper>
      )}
    </>
  );
};

export default UserLunchCredits;
