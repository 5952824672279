import React from "react";
import FeedItem from "./feedItem";
import { Swatches, Button, Size } from "ui-kit";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import feedActions from "../actions/feedActions";
import { magazineRoutes } from "../../news/newsRoutes";
import { IFeedItem } from "../reducers/feedReducer";
import { RootState } from "reducers/store";


interface IFeedNoticeProps {
  item: IFeedItem;
}


const FeedNotice: React.FC<IFeedNoticeProps> = ({ item }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);

  const navigate = useNavigate();
  const handleReadFullArticle = () => {
    navigate(magazineRoutes.getArticlePath(item.id));
  };

  const HandleFavourite = (article: IFeedItem) => {
    article &&
      (!article.favourited
        ? feedActions.favouriteFeedArticle(user.id, article.id)
        : feedActions.unfavouriteFeedArticle(user.id, article.id));
  };

  const HandleLike = (article: IFeedItem) => {
    article &&
      (!article.liked
        ? feedActions.likeFeedArticle(user.id, article.id)
        : feedActions.unlikeFeedArticle(user.id, article.id));
  };

  const HandleDismiss = (article: IFeedItem) => {
    article && feedActions.dismissFeedArticle(user.id, article.id);
  };

  return (
    <FeedItem
      item={item}
      headerIcon="newspaper"
      headerPostMessage={
        <>
          posted a feature in{" "}
          <b>
            {item.category.name}
            {/* <Link to={`/main/magazine/categories/${item.category.id}`}>
              {item.category.name}
            </Link> */}
          </b>
        </>
      }
      subheaderRight={
        <Button
          size={Size.Small}
          text="Read More"
          color={Swatches.Low}
          onClick={handleReadFullArticle}
        />
      }
      titleSub={item.content}
      onFavourite={HandleFavourite}
      onLike={HandleLike}
      onDismiss={HandleDismiss}
    ></FeedItem>
  );
};

export default FeedNotice;
