import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  Button,
  Right,
  ActionBar,
  Swatches,
  Size,
  StructuredList,
  Card,
  EmptyMessage,
  DateTime,
  Message,
  Loader,
} from "ui-kit";
import paymentActions from "areas/payments/actions/paymentsActions";
import reportingActions from "areas/payments/actions/reportingActions";
import IncomingTransactionsTable from "./incomingTransactionsTable";
import { RootState } from "reducers/store";
import { DateRange } from "types/common/data.types";
import { files } from "utils";
import { BeehiveReport } from "types/common/action.types";


interface IImportedTransactionsModalProps {
  dateRange: DateRange;
  open: boolean;
  onClose?: () => void;
}


const ImportedTransactionsModal: React.FC<IImportedTransactionsModalProps> = ({ dateRange, open, onClose }) => {

  const { loading, error, transactions } = useSelector((state: RootState) => state.incomingTransactions);
  const { loading: reporting, error: reportError, reportData } = useSelector((state: RootState) => state.paymentsReporting);

  const [_dateRange, _setDateRange] = useState<DateRange | null>(null);
  const [_open, _setOpen] = useState<boolean>(false);

  useEffect(() => {
    _setDateRange(dateRange);

    if (dateRange) {
      paymentActions.getIncomingTransactionsByDate(dateRange);
    }
  }, [dateRange]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const handleExport = () => {
    reportingActions.runIncomingTransactionsReport(
      { 
        startDate: _dateRange.startDate, 
        endDate: _dateRange.endDate 
      },
      (reportData: BeehiveReport) => {
        files.openCsv(reportData);
      }
    );
  };

  const handleClose = () => {
    onClose?.();
  };

  return (
    <Modal
      title="Report Transactions"
      open={_open}
      onClose={handleClose}
      width="95%"
      height="95%"
    >
      <Modal.Body>
        {_dateRange ? (
          <>
            <Message text={error} color={Swatches.Danger} />
            <Message text={reportError} color={Swatches.Danger} />
            <Card>
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="Start Date">
                    <DateTime bold date={_dateRange.startDate} />
                  </StructuredList.Item>
                  <StructuredList.Item name="End Date">
                    <DateTime bold date={_dateRange.endDate} />
                  </StructuredList.Item>
                  <StructuredList.Item name="Imported">
                    <b>{transactions.length}</b>
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>

            {loading ? (
              <Loader cover size={Size.Medium} />
            ) : (
              <Card title="Imported Transactions">
                <Card.Body noPad>
                  <IncomingTransactionsTable transactions={transactions} />
                </Card.Body>
              </Card>
            )}
          </>
        ) : (
          <EmptyMessage
            icon="file-spreadsheet"
            title="No Report"
            summary="No report was selected"
            cover
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              onClick={handleExport}
              size={Size.Small}
              color={Swatches.Primary}
              text="Export Transactions"
              working={reporting}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportedTransactionsModal;
