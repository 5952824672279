import routes from "configuration/routes";

export const calendarRoutes = {
  default: "/calendar",

};

export const eventRoutes = {
  event: `/events/:eventId`,
  new: "/events/new",
  edit: `/events/:eventId/edit`,
  signup: `/events/:eventId/signup`,
  getEventPath: (eventId: number) => `${routes.home}/calendar/events/${eventId}`,
  getEditPath: (eventId: number) => `${routes.home}/calendar/events/${eventId}/edit`,
  getSignupPath: (eventId: number) => `${routes.home}/calendar/events/${eventId}/signup`,
};

export const wallplannerRoutes = {
  edit: "/wallplanner/schools/:schoolId/academicyear/:yearId/edit",
  getEditPath: (schoolId: number, academicYear: number) =>
    `${routes.home}/calendar/wallplanner/schools/${schoolId}/academicyear/${academicYear}/edit`,
};
