import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import groupActions from "../../actions/group/groupActions";
import onCallActions from "areas/behaviour/actions/onCall/onCallActions";
import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import studentActions from "../../actions/student/studentActions";
import studentNoteActions from "../../actions/student/studentNoteActions";
import studentProfileActions from "../../actions/student/studentProfileActions";
import { StudentProfileTabs } from "areas/planner/utils/studentRecordAreas";


const INITIAL_STATE = {
  selected: null,
  selection: null,
  student: null, //PlannerStudentDetailView
  refresh: false,
  loading: false,
  error: null,
  profileTab: StudentProfileTabs.Details,
};

const { SELECTSTUDENT, GETSTUDENT, SELECTMULTISTUDENT } = studentActions.types;
const { GETGROUP } = groupActions.types;
const { ADDBEHAVIOUR } = behaviourActions.types;
const { ADDREWARDS } = rewardActions.types;
const { CREATEONCALLREQUEST } = onCallActions.types;
const { UPDATEAVATAR } = studentProfileActions.types;
const { CREATESTUDENTNOTE, DELETESTUDENTNOTE } = studentNoteActions.types;

const studentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECTSTUDENT:
      return { ...state, selected: action.payload };

    case SELECTMULTISTUDENT:
      return { ...state, selection: action.payload };

    case GETSTUDENT.START:
      return { ...state, loading: true, error: null };

    case GETSTUDENT.SUCCESS:
      return {
        ...state,
        student: action.payload,
        refresh: false,
        loading: false,
      };

    case GETSTUDENT.FAILED:
      return {
        ...state,
        refresh: false,
        loading: false,
        error: action.payload,
      };

    case GETGROUP.SUCCESS:
      return { ...state, selected: null, student: null };

    // case ADDREWARDS.SUCCESS:
    case ADDBEHAVIOUR.SUCCESS:
    case CREATEONCALLREQUEST.SUCCESS:
    case UPDATEAVATAR.SUCCESS:
      return { ...state, refresh: true };

    case CREATESTUDENTNOTE.SUCCESS:
      return {
        ...state,
        student: { ...state.student, totalNotes: state.student.totalNotes + 1 },
      };

    case DELETESTUDENTNOTE.SUCCESS:
      return {
        ...state,
        student: { ...state.student, totalNotes: state.student.totalNotes - 1 },
      };

    default:
      return state;
  }
};

export default studentReducer;
