import React from "react";
import styled from "styled-components";
import { Size, neutral, Spacing, DetailLabel, CircleIcon } from "ui-kit";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";

const Wrapper = styled.div<{isPortrait?: boolean, hasOnClick?: boolean}>`
  display: flex;
  align-items: center;

  .label {
    color: ${neutral[700]};
  }

  ${({ isPortrait }) =>
    isPortrait
      ? `
    flex-direction: column;
    justify-content: center;

    .circle-icon, .profile-image {
      margin-bottom: ${Spacing.Default}px;
    }

    .inner {
      text-align: center;
    }
  `
      : `
  .circle-icon, .profile-image {
    margin-right: ${Spacing.Default}px;
  }
  `}
  .inner {
    display: flex;
    flex-direction: column;
  }

  ${({ hasOnClick }) =>
    hasOnClick &&
    `
    &:hover {
      cursor: pointer;
    }
  `}
`;

interface IPrimaryAvatarProps {
  portrait?: boolean;
  icon?: string;
  user?: any;
  sub?: string;
  size?: Size;
  onClick?: () => void
}

const PrimaryAvatar: React.FC<IPrimaryAvatarProps> = ({
  portrait = false,
  icon = "user",
  user,
  sub,
  size = Size.Medium,
  onClick
}) => {
  const { user: currentUser } = useSelector((state: RootState) => state.currentUser);

  if (!user || !currentUser) {
    return null;
  }

  return (
    <>
      <Wrapper
        className="primary-avatar"
        isPortrait={portrait}
        hasOnClick={onClick != null}
        onClick={onClick}
      >
        <CircleIcon value={icon} size={size} />

        <div className="inner">
          <DetailLabel label={user.firstName} sub={sub} bold />
        </div>
      </Wrapper>
    </>
  );
};

export default PrimaryAvatar;
