import { IBeehiveAction } from "types/common/action.types";
import formCategoriesActions from "../actions/formCategoriesActions";
import { FormCategoryListView, FormListView } from "../types/formResponse.types";


interface IFormCategoriesState {
  categories: FormCategoryListView[];
  formTypes: FormListView[];
  loading: boolean;
  error: string | null;
}

const INITIAL_STATE: IFormCategoriesState = {
  categories: [],
  formTypes: [],
  loading: false,
  error: null,
};


const formCategoriesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IFormCategoriesState => {

  const { GETCATEGORIES } = formCategoriesActions.types;

  const getAllFormsFromCategories = (categories: FormCategoryListView[]) => {
    var formTypes: FormListView[] = [];
    if (categories) {
      categories.forEach((cat: FormCategoryListView) => {
        cat.forms.forEach((form: FormListView) => {
          formTypes.push(form);
        });
      });
    }
    return formTypes;
  };

  switch (action.type) {
    case GETCATEGORIES.START:
      return { ...state, loading: true };

    case GETCATEGORIES.SUCCESS:
      return {
        ...state,
        categories: action.payload,
        formTypes: getAllFormsFromCategories(action.payload),
        loading: false,
      };

    case GETCATEGORIES.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default formCategoriesReducer;
