import defaults from "configuration/defaults";
import { arrays } from "utils";
import calendarActions from "../actions/calendarActions";
import { IBeehiveAction } from "types/common/action.types";
import { PageInfo } from "types/common/paging.types";
import { CalendarEventAppListView, CalendarEventListView } from "../types/calendarResponses.types";


interface IManagedCalendarEventsState {
  events: CalendarEventListView[],
  error: string | null;
  loading: boolean;
  paging: PageInfo;
}

const INITIAL_STATE: IManagedCalendarEventsState = {
  events: [],
  error: null,
  loading: false,
  paging: defaults.DEFAULT_PAGING,
};

const managedCalendarEventsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IManagedCalendarEventsState => {
  const { CALENDAR_GETMANAGEDCALENDAREVENTS, CALENDAR_DELETECALENDAREVENT } = calendarActions.types;

  switch (action.type) {
    case CALENDAR_GETMANAGEDCALENDAREVENTS.START:
      return {
        ...state,
        loading: arrays.isEmpty(state.events),
        error: null,
      };

    case CALENDAR_GETMANAGEDCALENDAREVENTS.SUCCESS:
      return {
        ...state,
        events:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.events, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };

    case CALENDAR_GETMANAGEDCALENDAREVENTS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CALENDAR_DELETECALENDAREVENT.START:
      return {
        ...state,
        loading: true
      };
    case CALENDAR_DELETECALENDAREVENT.SUCCESS:
      return {
        ...state,
        loading: false,
        events: state.events.filter(x => x.id !== action.payload)
      };
    case CALENDAR_DELETECALENDAREVENT.FAILED:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};

export default managedCalendarEventsReducer;
