import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { GroupListView } from "types/users/userGroups.types";

const builder = new actionTypeBuilder("subjects");

const types = {
  SUBJECTS_GETBYSCHOOL: builder.build("SUBJECTS_GETBYSCHOOL"),
  SUBJECTS_AVAILABLETOSTAFF: builder.build("SUBJECTS_AVAILABLETOSTAFF"),
};

const getSubjectsBySchool = (schoolId: number, callback?: callbackType<GroupListView[]>) =>
  action<GroupListView[]>(
    () => client.get(`planner/schools/${schoolId}/subjects`),
    types.SUBJECTS_GETBYSCHOOL,
    callback
  );

const getSubjectsAvailableToStaff = (staffId: string, callback?: callbackType<GroupListView[]>) =>
  action<GroupListView[]>(
    () => client.get(`planner/staff/${staffId}/subjects`),
    types.SUBJECTS_AVAILABLETOSTAFF,
    callback
  );

const subjectActions = {
  types,
  getSubjectsBySchool,
  getSubjectsAvailableToStaff
};

export default subjectActions;
