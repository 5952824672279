import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Title, TitleSize } from "ui-kit";
import SessionsFilter from "./sessionsFilter";
import SessionsTable from "./sessionsTable";
import matriculationActions from "../actions/matriculationActions";
import Subheader from "sharedComponents/layout/header/subheader";
import { RootState } from "reducers/store";


const MatriculationAdmin = () => {

  const { sessions, loading, error, sessionUpdated } = useSelector((state: RootState) => state.matriculationSessions);
  const { filter } = useSelector((state: RootState) => state.matriculationFilter);

  useEffect(() => {
    if (filter && filter.schoolId != -1) {
      matriculationActions.getMatriculationSessions(filter.schoolId);
    }
  }, [filter]);

  useEffect(() => {
    if (filter && filter.schoolId != -1) {
      matriculationActions.getMatriculationSessions(filter.schoolId);
    }
  }, [sessionUpdated]);

  return (
    <>
      <Subheader>
        <Title size={TitleSize.H2} text="Matriculation" sub="Administration" />
      </Subheader>
      <SessionsFilter />
      <SessionsTable sessions={sessions} loading={loading} error={error} />
    </>
  );
};

export default MatriculationAdmin;
