import { CalendarEvent } from "areas/calendar/reducers/calendarEventReducer";
import { CalendarEventAppListView } from "areas/calendar/types/calendarResponses.types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { Button, Size, Swatches } from "ui-kit";


interface IEventSignupButtonProps {
  event: CalendarEventAppListView | CalendarEvent;
  onClick?: () => void;
  onSignedUp?: () => void;
  onUnsignedUp?: () => void;
  onError?: () => void;
}


const EventSignupButton: React.FC<IEventSignupButtonProps> = ({
  event,
  onClick,
  onSignedUp,
  onUnsignedUp,
  onError,
}) => {

  const { user } = useSelector((state: RootState) => state.currentUser);

  // const signupAction = useAction(calendarEventSignup);
  // const unsignupAction = useAction(calendarEventUnsignup);
  // const [working, setWorking] = useState(false);

  // const handleSignup = () => {
  //   // Need to handle signup or reserve list.

  //   setWorking(true);

  //   signupAction(
  //     {
  //       userId: user.id,
  //       categoryId: event.category.id,
  //       eventId: event.id,
  //     },
  //     () => {
  //       setWorking(false);
  //     },
  //     () => {
  //       setWorking(false);
  //     }
  //   );
  // };

  const handleUnsignup = () => {};

  // if (!event || event.status !== 2 || users.isStaff(user)) {
  //   return null;
  // }

  // if (event.isSignupOpen && !event.signedUp) {
  return event.dates.some(x => x.signedUp) ? (
    <Button
      text={"Signed Up"}
      size={Size.Small}
      onClick={onClick}
      color={Swatches.Low}
      fluid
    />
  ) : (
    <Button
      text={"Sign Up"}
      size={Size.Small}
      onClick={onClick}
      color={Swatches.Primary}
      fluid
    />
  );
};

export default EventSignupButton;
