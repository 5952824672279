import { ArticleListView } from "areas/news/types/newsResponse.types";
import { PagedQueryView } from "types/common/views.types";
import magazineActions from "../../actions/magazineActions";
import { IBeehiveAction } from "types/common/action.types";


interface IMagazineLatestState {
  loading: boolean;
  error: string | null;
  features: PagedQueryView<ArticleListView> | null;
}

const INITIAL_STATE: IMagazineLatestState = {
  loading: false,
  error: null,
  features: null,
};


const magazineLatestReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IMagazineLatestState => {

  const { MAGAZINE_GETLATEST } = magazineActions.types;

  switch (action.type) {
    case MAGAZINE_GETLATEST.START:
      return { ...state, loading: true, error: null };

    case MAGAZINE_GETLATEST.SUCCESS:
      var newState = {
        ...state,
        features: action.payload.articles,
        loading: false,
      };
      return newState;

    case MAGAZINE_GETLATEST.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default magazineLatestReducer;
