import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "ui-kit";
import newsActions from "areas/news/actions/newsActions";
import { RootState } from "reducers/store";

interface IArticleCategoryDropdownProps {
  onChange?: (value: number) => void;
  includeAllOption?: boolean;
  value: number;
}

interface articleCategoryOption {
  key: number;
  label: string;
  value: number;
}

const ALL_OPTION: articleCategoryOption = {
  key: -1,
  label: "All Categories",
  value: -1,
};

const ArticleCategoryDropdown: React.FC<IArticleCategoryDropdownProps> = ({
  onChange,
  includeAllOption,
  value,
}) => {
  const { categories, loading } = useSelector(
    (state: RootState) => state.articleCategories
  );

  const [selectedValue, setSelectedValue] = useState<number | null>(
    includeAllOption ? ALL_OPTION.value : null
  );
  const [options, setOptions] = useState<articleCategoryOption[]>(
    includeAllOption ? [ALL_OPTION] : []
  );

  useEffect(() => {
    newsActions.getCategories();
  }, []);

  const setFromOptions = (value: number) => {
    const option = options.find(x => x?.value == value);
    setSelectedValue(option?.value);
  };

  useEffect(() => {
    var newOptions = includeAllOption ? [ALL_OPTION] : [];

    setOptions([
      ...newOptions,
      ...categories.map(cat => ({
        key: cat.id,
        label: cat.name,
        value: cat.id,
      })),
    ]);
  }, [categories]);

  useEffect(() => {
    if (value) {
      setFromOptions(value);
    } else {
      setSelectedValue(options[0]?.value);
      onChange?.(options[0]?.value);
    }
  }, [options]);

  useEffect(() => {
    const option = options.find(x => x?.value == value);
    setSelectedValue(option?.value);
  }, [value]);

  const handleChange = (value: number) => {
    setSelectedValue(value);
    onChange?.(value);
  };

  return (
    <>
      <Dropdown
        placeholder="Chose a Category..."
        items={options}
        onChange={handleChange}
        value={selectedValue}
        fluid
        loading={loading}
      />
    </>
  );
};

export default ArticleCategoryDropdown;
