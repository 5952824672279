import React, { useState, useEffect } from "react";
import IncidentFormWrapper from "../incidentFormWrapper";
import BehaviourOutcomeDropdown from "../behaviourOutcomeDropdown";
import InternalNoteFormField from "../../behaviourCommon/internalNoteFormField";
import InitialBehaviourActionWrapper from "../initialBehaviourActionWrapper";
import { StructuredList, Checkbox, Swatches, Card } from "ui-kit";
import CategoryChip from "areas/behaviour/components/behaviourCommon/categoryChip";
import PublicNotesFormField from "../../behaviourCommon/publicNotesFormField";
import MultipleDetentionFormWrapper from "./multipleDetentionFormWrapper";
import {
  DETENTION_OPTIONS_KEYS,
  DETENTION_STATUS_KEYS,
} from "areas/behaviour/constants/detentions";
import PointsDropdown from "areas/behaviour/components/behaviourCommon/pointsDropdown";
import ParentNotificationNote from "areas/behaviour/components/behaviourCommon/parentNotificationNote";
import { SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";
import { PlannerSchoolView } from "areas/planner/types/plannerSchoolResponse.types";
import { IBehaviourDetails, IDetentionDetails, IIncidentDetails } from "areas/behaviour/utils/detentions";
import { IInitialAction } from "../../rewards/initialRewardActionWrapper";


interface IMultipleBehaviourPointFormProps {
  selectedCategory: SchoolBehaviourCategoryListView;
  groupId: number;
  schoolId: number;
  schoolInformation: PlannerSchoolView;
  behaviourDetails: IBehaviourDetails;
  setBehaviourDetails: (details: IBehaviourDetails) => void;
}


const MultipleBehaviourPointForm: React.FC<IMultipleBehaviourPointFormProps> = ({
  selectedCategory,
  groupId,
  schoolId,
  schoolInformation,
  behaviourDetails,
  setBehaviourDetails,
}) => {

  const [addIncidentDetails, setAddIncidentDetails] = useState<boolean>(false);

  useEffect(() => {
    if (!addIncidentDetails) {
      setIncidentDetails({
        ...behaviourDetails.incidentDetails,
        period: null,
        incidentLocation: null,
        otherIncidentLocation: null,
      });
    }
  }, [addIncidentDetails]);

  const setDetentionDetails = (detentionDetails: IDetentionDetails) => {
    setBehaviourDetails({
      ...behaviourDetails,
      detentionDetails: detentionDetails,
    });
  };

  const setIncidentDetails = (incidentDetails: IIncidentDetails) => {
    setBehaviourDetails({
      ...behaviourDetails,
      incidentDetails: incidentDetails,
    });
  };

  const setPoints = (points: number) => {
    setBehaviourDetails({
      ...behaviourDetails,
      points: points,
    });
  };

  const setOutcome = (outcomeId: number) => {
    setBehaviourDetails({
      ...behaviourDetails,
      outcome: outcomeId,
    });
  };

  const setInternalNotes = (notes: string) => {
    setBehaviourDetails({
      ...behaviourDetails,
      internalNotes: notes,
    });
  };

  const setPublicNotes = (notes: string) => {
    setBehaviourDetails({
      ...behaviourDetails,
      publicNotes: notes,
    });
  };

  const setInitialAction = (initialAction: IInitialAction) => {
    setBehaviourDetails({
      ...behaviourDetails,
      initialAction: initialAction,
    });
  };

  return (
    <StructuredList>
      <Card>
        <Card.Body>
          <StructuredList.Item name="Behaviour Category">
            <CategoryChip
              className="behaviour"
              categoryCode={selectedCategory.codeDisplayName}
              categoryName={selectedCategory.behaviourCategoryName}
              colorSwatch={Swatches.Danger}
            />
          </StructuredList.Item>
        </Card.Body>
      </Card>

      {selectedCategory.detentionOptionId ===
        DETENTION_OPTIONS_KEYS.MANDATORY &&
        !selectedCategory.leadershipInterventionRequired &&
        behaviourDetails.detentionDetails !== null && (
          <MultipleDetentionFormWrapper
            schoolId={schoolId}
            detentionDetails={behaviourDetails.detentionDetails}
            setDetentionDetails={setDetentionDetails}
          />
        )}

      <Card title="Incident Details">
        <Card.Body>
          <StructuredList.Item name="Add Details of the Incident">
            <Checkbox
              text="Enter incident details"
              checked={addIncidentDetails}
              onChange={() => setAddIncidentDetails(!addIncidentDetails)}
            />
          </StructuredList.Item>

          {addIncidentDetails && (
            <IncidentFormWrapper
              incidentDetails={behaviourDetails.incidentDetails}
              setIncidentDetails={setIncidentDetails}
              detentionDetails={behaviourDetails.detentionDetails}
              groupId={groupId}
              schoolId={schoolId}
            />
          )}
        </Card.Body>
      </Card>

      <Card title="Behaviour Details">
        <Card.Body>
          {schoolInformation.isPrimary &&
            selectedCategory.allowOverridePointsForPrimary && (
              <StructuredList.Item name="Points" required>
                <PointsDropdown
                  points={behaviourDetails.points}
                  setPoints={setPoints}
                  maxPoints={selectedCategory.maxPoints}
                />
              </StructuredList.Item>
            )}

          <StructuredList.Item name="Outcome" required>
            <BehaviourOutcomeDropdown
              outcome={behaviourDetails.outcome}
              handleChangeOutcome={selectedOutcome =>
                setOutcome(selectedOutcome)
              }
            />
          </StructuredList.Item>

          <InitialBehaviourActionWrapper
            selectedInitialAction={behaviourDetails.initialAction}
            handleChangeInitialAction={selectedInitialAction =>
              setInitialAction(selectedInitialAction)
            }
          />

          <StructuredList.Item
            name="Public Notes"
            description="Maximum 250 characters. (These notes will be sent/displayed to parents)"
            helpPopup
          >
            <PublicNotesFormField
              notes={behaviourDetails.publicNotes}
              handleNotesChange={value => setPublicNotes(value)}
            />
          </StructuredList.Item>

          <StructuredList.Item
            name="Internal Notes"
            description="(These notes will not be sent/displayed to parents)"
            helpPopup
          >
            <InternalNoteFormField
              notes={behaviourDetails.internalNotes}
              handleNoteChange={value => setInternalNotes(value)}
            />
          </StructuredList.Item>
        </Card.Body>
      </Card>

      {behaviourDetails.detentionDetails && <ParentNotificationNote />}
    </StructuredList>
  );
};

export default MultipleBehaviourPointForm;
