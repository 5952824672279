import action from "actions/action";
import { ToastService } from "ui-kit";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { Constants } from "configuration";
import store from "reducers/store";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { BehaviourCategoriesFilter, BehaviourRewardFilter, CreateBehaviourCommand, CreateBehavioursCommand, RevokeBehaviourCommand, UpdateBehaviourCommand, UpscaleBehaviourCommand, UpscaleC2BehaviourCommand } from "areas/behaviour/types/behaviourRequest.types";
import { BehaviourCategoryGroupByListView, BehaviourIntialActionView, BehaviourUpscaleView, DetentionTypeView, MultipleBehavioursSaveResultsView, SchoolBehaviourCodeListView } from "areas/behaviour/types/behaviourResponses.types";
import { GroupType } from "types/planner/groups.types";
import { IMultipleBehaviourResponse } from "areas/behaviour/utils/detentions";


const builder = new actionTypeBuilder("behaviours");

const types = {
  GETBEHAVIOURCATEGORIES: builder.build("getbehaviourcategories"),
  GETALLBEHAVIOURCATEGORIES: builder.build("getallbehaviourcategories"),
  GETUPSCALEBEHAVIOURCATEGORIES: builder.build("getupscalebehaviourcategories"),
  GETBEHAVIOURCODES: builder.build("getbehaviourcodes"),
  GETALLBEHAVIOURCODES: builder.build("getallbehaviourcodes"),
  GETDEFAULTDETENTION: builder.build("getdefaultdetention"),
  ADDBEHAVIOUR: builder.build("addbehaviour"),
  GETBEHAVIOUR: builder.build("getbehaviour"),
  UPDATEBEHAVIOUR: builder.build("updatebehaviourreward"),
  REVOKEBEHAVIOUR: builder.build("revokebehaviour"),
  GETINITIALBEHAVIOURACTIONS: builder.build("getinitialbehaviouractions"),
  UPSCALEBEHAVIOUR: builder.build("upscalebehaviour"),
  UPSCALEBEHAVIOURDETENTION: builder.build("upscalebehaviourdetention"),
  ADDBEHAVIOURFORMULTIPLE: builder.build("addbehaviourformultiple"),
  CREATEMULTIPLEBEHAVIOURRESPONSE: "createmultiplebehaviourresponse",
  SETBEHAVIOURSCHOOLID: "setbehaviourschoolid",
};


const getBehaviourCategories = (
  schoolId: number,
  {
    isLegacy,
    canBeAwarded,
    behaviourCodeId
  }: BehaviourCategoriesFilter,
  onSuccess?: callbackType<BehaviourCategoryGroupByListView[]>
) => {
  const url = `planner/schools/${schoolId}/behaviourCategories?isLegacy=${isLegacy}&canBeAwarded=${canBeAwarded}&behaviourCodeId=${behaviourCodeId}`;

  return action<BehaviourCategoryGroupByListView[]>(
    () => client.get(url), 
    types.GETBEHAVIOURCATEGORIES, 
    onSuccess
  );
};


// For reporting to get all categories including legacy categories
const getAllBehaviourCategories = (schoolId: number, onSuccess?: callbackType<BehaviourCategoryGroupByListView[]>) => {
  return action<BehaviourCategoryGroupByListView[]>(
    () => client.get(`planner/schools/${schoolId}/behaviourCategories`),
    types.GETALLBEHAVIOURCATEGORIES,
    onSuccess
  );
};


const getUpscaleBehaviourCategories = (
  schoolId: number,
  {
    isLegacy,
    canBeAwarded,
    behaviourCodeId,
    parentCategoryId
  }: BehaviourCategoriesFilter,
  onSuccess?: callbackType<BehaviourCategoryGroupByListView[]>
) => {
  const url = `planner/schools/${schoolId}/behaviourCategories?isLegacy=${isLegacy}&canBeAwarded=${canBeAwarded}&behaviourCodeId=${behaviourCodeId}&parentCategoryId=${parentCategoryId}`;

  return action<BehaviourCategoryGroupByListView[]>(
    () => client.get(url),
    types.GETUPSCALEBEHAVIOURCATEGORIES,
    onSuccess
  );
};


const getBehaviourCodes = (
  schoolId: number, 
  {
    isLegacy, 
    canBeAwarded,
  }: BehaviourRewardFilter,
  onSuccess?: callbackType<SchoolBehaviourCodeListView[]>
) => {
  return action<SchoolBehaviourCodeListView[]>(
    () =>
      client.get(
        `planner/schools/${schoolId}/behaviourRewardCodes?CategoryTypeId=0&isLegacy=${isLegacy}&canBeAwarded=${canBeAwarded}`
      ),
    types.GETBEHAVIOURCODES,
    onSuccess
  );
};


// For reporting to get all codes including legacy codes
// Saved in a seperate reducer so it doesn't affect planner
const getAllBehaviourCodes = (schoolId: number, onSuccess?: callbackType<SchoolBehaviourCodeListView[]>) => {
  return action<SchoolBehaviourCodeListView[]>(
    () =>
      client.get(
        `planner/schools/${schoolId}/behaviourRewardCodes?CategoryTypeId=0`
      ),
    types.GETALLBEHAVIOURCODES,
    onSuccess
  );
};


const getDefaultDetention = (
  schoolId: number,
  studentId: string,
  schoolBehaviourCategory: number,
  onSuccess?: callbackType<DetentionTypeView>
) => {
  return action<DetentionTypeView>(
    () =>
      client.get(
        `planner/schools/${schoolId}/students/${studentId}/schoolcategories/${schoolBehaviourCategory}/detentionType`
      ),
    types.GETDEFAULTDETENTION,
    onSuccess
  );
};


const addBehaviour = (studentId: string, newBehaviour: CreateBehaviourCommand, onSuccess?: callbackType<number>) => {
  return action<number>(
    () => client.post(`planner/students/${studentId}/behaviour`, newBehaviour),
    types.ADDBEHAVIOUR,
    response => {
      ToastService.pop("Successfully added", null, "check-circle");
      if (onSuccess) {
        onSuccess(response);
      }
    }
  );
};


const upscaleBehaviour = (
  studentId: string, 
  behaviourId: number, 
  newBehaviour: UpscaleBehaviourCommand, 
  onSuccess?: callbackType<number>
) => {
  return action<number>(
    () =>
      client.post(
        `planner/students/${studentId}/behaviours/${behaviourId}/upscale`,
        newBehaviour
      ),
    types.UPSCALEBEHAVIOUR,
    response => {
      ToastService.pop("Successfully upscaled", null, "check-circle");
      if (onSuccess) {
        onSuccess(response);
      }
    }
  );
};


const upscaleBehaviourDetention = (
  studentId: string,
  behaviourId: number,
  newBehaviourDetention: UpscaleC2BehaviourCommand,
  onSuccess?: callbackType<number>
) => {
  return action<number>(
    () =>
      client.post(
        `planner/students/${studentId}/behaviours/${behaviourId}/upscaleC2`,
        newBehaviourDetention
      ),
    types.UPSCALEBEHAVIOURDETENTION,
    response => {
      ToastService.pop("Successfully upscaled", null, "check-circle");
      if (onSuccess) {
        onSuccess(response);
      }
    }
  );
};


const getBehaviour = (
  studentId: string, 
  behaviourId: number, 
  detentionId?: number, 
  onSuccess?: callbackType<BehaviourUpscaleView>
) => {
  // Get an error from API if detentionId param isn't there in URL
  let url = `planner/students/${studentId}/behaviours/${behaviourId}?detentionId=${
    detentionId ? detentionId : null
  }`;

  return action<BehaviourUpscaleView>(
    () => client.get(url), 
    types.GETBEHAVIOUR, 
    onSuccess
  );
};


const updateBehaviour = (
  studentId: string, 
  behaviourId: number, 
  updateDetails: UpdateBehaviourCommand, 
  onSuccess?: callbackType<number>
) => {
  return action<number>(
    () =>
      client.post(
        `planner/students/${studentId}/behaviours/${behaviourId}/update`,
        updateDetails
      ),
    types.UPDATEBEHAVIOUR,
    response => {
      ToastService.pop("Successfully updated", null, "check-circle");
      if (onSuccess) {
        onSuccess(response);
      }
    }
  );
};


const revokeBehaviour = (
  command: RevokeBehaviourCommand, 
  onSuccess?: callbackType<number>
) => {
  return action<number>(
    () =>
      client.put(
        `planner/students/${command.studentId}/behaviours/${command.behaviourId}/revoke`,
        command
      ),
    types.REVOKEBEHAVIOUR,
    response => {
      ToastService.pop("Successfully revoked", null, "check-circle");
      if (onSuccess) {
        onSuccess(response);
      }
    }
  );
};


const getInitialBehaviourActions = (onSuccess?: callbackType<BehaviourIntialActionView[]>) => {
  return action(
    () => client.get(`planner/behaviourRewardInitialActions?CategoryTypeId=0`),
    types.GETINITIALBEHAVIOURACTIONS,
    onSuccess
  );
};


const addBehaviourForMultipleStudentsInGroup = (
  groupId: number,
  groupType: GroupType,
  behaviours: CreateBehavioursCommand,
  onSuccess?: callbackType<MultipleBehavioursSaveResultsView[]>
) => {
  let url: string;

  switch (groupType) {
    case GroupType.ClassGroup:
      url = `planner/classGroups/${groupId}/behaviours`;
      break;

    case GroupType.Custom:
      url = `planner/customGroups/${groupId}/behaviours`;
      break;

    case GroupType.TutorGroup:
      url = `planner/tutorGroups/${groupId}/behaviours`;
      break;

    case GroupType.Provision:
      url = `planner/provisiongroups/${groupId}/behaviours`;
      break;

    default:
      break;
  }

  return action<MultipleBehavioursSaveResultsView[]>(
    () => client.post(url, behaviours),
    types.ADDBEHAVIOURFORMULTIPLE,
    onSuccess
  );
};


const createMultipleBehaviourResponse = (payload: IMultipleBehaviourResponse) => {
  store.dispatch({
    type: types.CREATEMULTIPLEBEHAVIOURRESPONSE,
    payload: payload,
  });
};


const setBehaviourSchoolId = (schoolId: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.SETBEHAVIOURSCHOOLID,
    payload: schoolId,
  });
};


const behaviourActions = {
  types,
  getBehaviourCategories,
  getAllBehaviourCategories,
  getUpscaleBehaviourCategories,
  getBehaviourCodes,
  getAllBehaviourCodes,
  getDefaultDetention,
  addBehaviour,
  upscaleBehaviour,
  upscaleBehaviourDetention,
  getBehaviour,
  updateBehaviour,
  revokeBehaviour,
  getInitialBehaviourActions,
  addBehaviourForMultipleStudentsInGroup,
  createMultipleBehaviourResponse,
  setBehaviourSchoolId,
};

export default behaviourActions;
