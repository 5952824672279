import defaults from "configuration/defaults";
import config from "configuration";
import leaveActions from "../actions/leaveActions";
import { PageInfo } from "types/common/paging.types";
import { LeaveRequestListView } from "../types/leaveResponse.types";
import { LeaveRequestQueryFilter } from "../types/leaveRequest.types";
import { IBeehiveAction } from "types/common/action.types";
import { Ordering } from "configuration/constants.enums";


interface ILeaveRequestsState {
  leaveRequests: LeaveRequestListView[],
  loading: boolean;
  error: string | null;
  filter: LeaveRequestQueryFilter,
  paging: PageInfo;
}

const INITIAL_STATE: ILeaveRequestsState = {
  leaveRequests: [],
  loading: false,
  error: null,
  filter: {
    leaveRequestType: null,
    processed: null,
    status: null,
    startDate: null,
    endDate: null,
    schoolId: -1,
    academicYearId: config.academicYear.current,
    searchTerm: null,
    order: Ordering.DESCENDING
  },
  paging: defaults.DEFAULT_PAGING,
};

const leaveRequestsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ILeaveRequestsState => {
  const {
    LEAVEREQUESTS_GETALL,
    LEAVEREQUESTS_UPDATEFILTER,
    APPROVE_LEAVEREQUEST,
    REJECT_LEAVEREQUEST,
    CANCEL_LEAVEREQUEST,
    CONFIRM_LEAVEREQUEST,
    RESUBMIT_LEAVEREQUEST,
  } = leaveActions.types;

  switch (action.type) {
    case LEAVEREQUESTS_UPDATEFILTER:
      return { ...state, filter: action.payload };

    case LEAVEREQUESTS_GETALL.START:
      return { ...state, loading: true };

    case LEAVEREQUESTS_GETALL.SUCCESS:
      return {
        ...state,
        leaveRequests:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.leaveRequests, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };

    case LEAVEREQUESTS_GETALL.FAILED:
      return {
        ...state,
        loading: false,
        error: "There was a problem getting the leave requests.",
      };

    case APPROVE_LEAVEREQUEST.SUCCESS:
    case REJECT_LEAVEREQUEST.SUCCESS:
    case CANCEL_LEAVEREQUEST.SUCCESS:
    case CONFIRM_LEAVEREQUEST.SUCCESS:
    case RESUBMIT_LEAVEREQUEST.SUCCESS:
      return {
        ...state,
        leaveRequests: 
          state.leaveRequests?.map(req => 
            action.payload.leaveRequests.filter((x: LeaveRequestListView) => x.id === req.id).length
              ? action.payload.leaveRequests.filter((x: LeaveRequestListView) => x.id === req.id)[0]
              : req
        ),
      };
 
    default:
      return state;
  }
};

export default leaveRequestsReducer;
