import React from "react";
import styled from "styled-components";
import { LandscapeDarkLogo } from "sharedComponents/common/logos/beehive";
import config from "configuration";
import {
  neutral,
  Spacing,
  displayFont,
  typescale,
  fontStyle,
  textFont,
  honeygold,
  formatScrollbars,
} from "ui-kit";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: stretch;
  align-items: stretch;
  overflow-y: scroll;
  height: 100vh;
  ${formatScrollbars(neutral[200])}
  background-color: #efefef;
`;
const HeaderWrapper = styled.div`
  box-sizing: box-content;
  svg {
    width: 200px;
    display: block;
    margin: 0 auto 0 auto;
  }
  padding: ${Spacing.ExtraLarge}px;
`;
const BodyWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  background: ${neutral[200]};
  padding: 0 ${Spacing.ExtraLarge}px ${Spacing.ExtraLarge}px
    ${Spacing.ExtraLarge}px;
  box-sizing: border-box;
  @media only screen and (max-width: 600px) {
    margin-bottom: 120px;
  }

  .inner {
    width: 100%;
    max-width: 800px;
    margin: 0 auto 0 auto;
    flex-grow: 1;
  }
  h1.title {
    ${fontStyle(displayFont.extraLight, typescale.header1, neutral[600])}
    text-align: center;
    margin: ${Spacing.Jumbo}px 0;
  }

  p,
  ol,
  ul {
    ${fontStyle(textFont.roman, typescale.paragraph, neutral[600])}
    margin-top: 0 !important;
    margin-bottom: ${typescale.paragraph.lineHeight}rem !important;

    &:last-child {
      margin-bottom: 0 !important;
    }

    a {
      color: ${honeygold};
      font-weight: bold;

      &:hover {
        color: ${neutral[700]};
      }
    }
  }
`;
const FooterWrapper = styled.div``;

interface IPublicTemplateProps {
  title?: string;
  children: any | any[];
}

const PublicTemplate: React.FC<IPublicTemplateProps> = ({title, children}) => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <LandscapeDarkLogo alt={config.name} />
      </HeaderWrapper>
      <BodyWrapper>
        <div className="inner">
          <h1 className="title">{title}</h1>
          {children}
        </div>
      </BodyWrapper>
      <FooterWrapper></FooterWrapper>
    </Wrapper>
  );
};

export default PublicTemplate;
