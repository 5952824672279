import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Message, Swatches, Spacing } from "ui-kit";
import moment from "moment";
import reviewPeriodActions from "areas/humanResources/actions/reviewPeriodActions";
import { RootState } from "reducers/store";
import { StaffType } from "types/users/userStaff.types";


const Wrapper = styled.div`
  margin-bottom: ${Spacing.ExtraLarge}px;
`;


const ReviewPeriodMessage = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { currentReviewPeriod } = useSelector((state: RootState) => state.currentReviewPeriod);

  useEffect(() => {
    if (user && !currentReviewPeriod) {
      var st = StaffType[user.staffType as keyof typeof StaffType];
      reviewPeriodActions.getCurrentReviewPeriod(st);
    }
  }, []);

  return currentReviewPeriod && user ? (
    <Wrapper>
      <Message
        color={Swatches.Low}
        text={`${currentReviewPeriod.staffType} Review Period Open`}
        subtext={`The ${
          currentReviewPeriod.staffType
        } Staff Review Period is now open and will close on ${moment(
          currentReviewPeriod.ends
        ).format("dddd, MMMM Do YYYY")}`}
        icon="rocket"
      />
    </Wrapper>
  ) : null;
};

export default ReviewPeriodMessage;
