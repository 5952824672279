import React, { useEffect, useState } from "react";
import {
  Modal,
  ActionBar,
  Button,
  Size,
  Swatches,
  Card,
  Table,
  Title,
  Label,
  TitleSize,
  Left,
  Right,
  Message,
  ValidationMessage,
  HeadlineStatistic,
  SplitButton,
  Spacing,
  ToastService,
  EmptyMessage,
  Icon,
} from "ui-kit";
import { Avatar } from "sharedComponents/common";
import moment from "moment";
import { arrays, users } from "utils";
import { useSelector } from "react-redux";
import styled from "styled-components";
import AddSignupModal from "./addSignupModal";
import IssueRewardsModal from "./issueRewardsModal";
import calendarActions from "../../actions/calendarActions";
import { CalendarEventDateListView, CalendarEventDateSignupListView } from "areas/calendar/types/calendarResponses.types";
import { RootState } from "reducers/store";
import { CancelCalendarEventSignupCommand, SaveCalendarEventDateRegisterCommand } from "areas/calendar/types/calendarRequests.types";
import { PageInfo } from "types/common/paging.types";
import { SchoolBehaviourCategoryListView } from "areas/behaviour/types/behaviourResponses.types";


const CheckWrapper = styled.div`
  .icon:first-child {
    margin-right: ${Spacing.Default}px;
  }
`;


interface IEventDateRegisterModalProps {
  eventDate: CalendarEventDateListView;
  open: boolean;
  mandatoryAttendance?: boolean;
  onClose?: () => void;
  onSave?: (eventDate: CalendarEventDateListView) => void;
  onDelete?: () => void
}

export interface EventRegister {
  signupLimit: number | null;
  placesRemaining: number | null;
  hasMenu: boolean;
  attendees: CalendarEventDateSignupListView[];
  paging: PageInfo | null;
}


const EventDateRegisterModal: React.FC<IEventDateRegisterModalProps> = ({
  eventDate,
  open,
  mandatoryAttendance,
  onClose,
  onSave,
  onDelete,
}) => {

  const [_open, _setOpen] = useState<boolean>(open);
  const [_eventDate, _setEventDate] = useState<CalendarEventDateListView>(eventDate);
  const [_register, _setRegister] = useState<EventRegister>({
    signupLimit: null,
    placesRemaining: null,
    hasMenu: false,
    attendees: [],
    paging: null,
  });
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_addSignupModalOpen, _setAddSignupModalOpen] = useState<boolean>(false);
  const [_issueRewardsModalOpen, _setIssueRewardsModalOpen] = useState<boolean>(false);
  //const [_student, _setStudent] = useState(null);
  const [_exportLoading, _setExportLoading] = useState<boolean>(false);
  const [_exportMenu, _setExportMenu] = useState<boolean>(false);
  const [_exportMenuTotals, _setExportMenuTotals] = useState<boolean>(false);
  const [_selectedRecord, _setSelectedRecord] = useState<CalendarEventDateSignupListView | null>(null);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);

  const {
    calendarEvent,
    registerError,
    loadingRegister,
    savingRegister,
    signingUp,
    signupError,
  } = useSelector((state: RootState) => state.calendarEvent);

  useEffect(() => {
    if (calendarEvent?.register) {
      //console.log(calendarEvent?.register);
      _setRegister({
        signupLimit: calendarEvent?.register.signupLimit,
        placesRemaining: calendarEvent?.register.placesRemaining,
        hasMenu: calendarEvent?.register.hasMenu,
        attendees: calendarEvent?.register.attendees.items,
        paging: calendarEvent?.register.attendees.paging,
      });
    }
  }, [calendarEvent?.register])

  const loadAttendees = (pageIndex: number) => {
    calendarActions.getCalendarEventDateRegister(
      calendarEvent.id,
      eventDate.id,
      pageIndex,
      () => {
        (pageIndex === 0) && setInitialLoad(false);
      }
    );
  };

  // const loadAttendees = (pageIndex: number) => {
  //   return new Promise((resolve, reject) => {
  //     calendarActions.getCalendarEventDateRegister(
  //       calendarEvent.id,
  //       eventDate.id,
  //       pageIndex
  //     )
  //     .then(() => resolve(""))
  //     .catch(() => reject(new Error("Error!")));
  //   });
  // };


  const handlePage = () => {
    loadAttendees(_register.paging.pageIndex + 1);
  };

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    _setEventDate(eventDate);
    setValidationErrors([]);

    if (calendarEvent && eventDate) {
      setInitialLoad(true);
      loadAttendees(0);
    }
  }, [eventDate]);

  const handleCloseModal = () => {
    onClose?.();
    //_setStudent(null);
  };

  const handleSave = () => {
    var errors: string[] = [];

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    const data: SaveCalendarEventDateRegisterCommand = {
      eventId: calendarEvent.id,
      eventDateId: _eventDate.id,
      attendees: _register.attendees.map(x => ({
        userId: x.user.id,
        attendance: x.attendance,
      })),
    };

    calendarActions.saveCalendarEventDateRegister(data, () => {
      ToastService.pop("Register Saved Successfully", null, "calendar");
      onSave?.(_eventDate);
    });
  };

  const setAttendance = (index: number, value: number) => {
    _setRegister({
      ..._register,
      attendees: _register.attendees.map((r, i) =>
        index === i
          ? { ...r, attendance: r.attendance === value ? 0 : value }
          : r
      ),
    });
  };

  const handleExport = () => {
    // console.log(eventDate.starts);
    if (
      window.confirm(
        "Make sure you have saved the register first to see your changes in the exported file."
      )
    ) {
      _setExportLoading(true);
      const callback = () => {
        _setExportLoading(false);
      };
      calendarActions.exportEventDateAttendanceReport(
        calendarEvent.id,
        eventDate.starts,
        calendarEvent.title,
        callback,
        callback
      );
    }
  };

  const handleExportMenu = () => {
    // console.log(eventDate.starts);
    if (
      window.confirm(
        "Make sure you have saved the register first to see your changes in the exported file."
      )
    ) {
      _setExportMenu(true);
      const callback = () => {
        _setExportMenu(false);
      };

      calendarActions.exportEventDateMenuReport(
        calendarEvent.id,
        eventDate.id,
        callback,
        callback
      );
    }
  };

  const handleExportMenuTotals = () => {
    // console.log(eventDate.starts);
    if (
      window.confirm(
        "Make sure you have saved the register first to see your changes in the exported file."
      )
    ) {
      _setExportMenuTotals(true);
      const callback = () => {
        _setExportMenuTotals(false);
      };

      calendarActions.exportEventDateMenuTotalsReport(
        calendarEvent.id,
        eventDate.id,
        callback,
        callback
      );
    }
  };

  const getAttendanceColor = (record: CalendarEventDateSignupListView, matchValue: number) => {
    if (record.attendance !== matchValue) {
      return Swatches.Low.swatch;
    }

    switch (record.attendance) {
      case 1:
        return Swatches.Success.swatch;
      case 2:
        return Swatches.Danger.swatch;
      default:
        return Swatches.Low.swatch;
    }
  };

  // const handleAddSignup = () => {
  //   // _setAddSignupModalOpen(true);
  //   var errors = [];

  //   if (!_student) {
  //     errors.push(`Please choose a user.`);
  //   }

  //   setValidationErrors(errors);

  //   if (!arrays.isEmpty(errors)) {
  //     return;
  //   }

  //   if (
  //     window.confirm(
  //       `This will sign up ${_student.firstName} ${_student.lastName} for this event date. Are you sure?`
  //     )
  //   ) {
  //     const onSave = () => {
  //       // getCalendarEventDateRegisterAction(
  //       //   calendarEvent.id,
  //       //   _eventDate.id,
  //       //   data => {
  //       //     _setRegister(data);
  //       //   }
  //       // );

  //       const tempRegister = JSON.parse(JSON.stringify(_register));
  //       tempRegister.attendees.push({
  //         user: _student,
  //         attendance: 0,
  //         rewardId: null,
  //         rewardName: null,
  //       });

  //       _setRegister(tempRegister);
  // _setSelectedRecord(record);
  //       _setStudent(null);
  //     };

  //     const data = {
  //       userId: _student.id,
  //       eventId: calendarEvent.id,
  //       eventDates: [_eventDate.id],
  //     };

  //     calendarEventSignupAction(data, (id) => {
  //       ToastService.pop("Signed up successfully", null, "calendar");
  //       onSave();
  //     });
  //   }
  // };

  const handleCancelSignup = (record: CalendarEventDateSignupListView, index: number) => {
    if (
      window.confirm(
        `This will cancel the signup for ${record.user.firstName} ${record.user.lastName}. Are you sure?`
      )
    ) {
      const data: CancelCalendarEventSignupCommand = {
        userId: record.user.id,
        eventId: calendarEvent.id,
        eventDates: [_eventDate.id],
      };

      calendarActions.calendarEventUnsignup(data, () => {
        ToastService.pop("Signup Cancelled", null, "calendar");
        _setRegister({
          ..._register,
          attendees: arrays.remove(_register.attendees, index),
        });
      });
    }
  };

  const handleIssueRewardsSaved = (students: string[], reward: SchoolBehaviourCategoryListView) => {
    // console.log(students, reward);

    var attendees = [..._register.attendees];

    // console.log(students, attendees, reward);

    // Update the register with the reward/id etc
    for (var i = 0; i < students.length; i++) {
      for (var j = 0; j < attendees.length; j++) {
        if (attendees[j].user?.id === students[i]) {
          attendees[j] = {
            ...attendees[j],
            rewardId: reward?.id,
            rewardName: `${reward.codeDisplayName} - ${reward.behaviourCategoryName}`,
          };
        }
      }
    }

    _setRegister({ ..._register, attendees: attendees });

    _setIssueRewardsModalOpen(false);
  };

  const handleIssueRewards = () => {
    _setIssueRewardsModalOpen(true);
  };

  const handleIssueRewardsModalClosed = () => {
    _setIssueRewardsModalOpen(false);
  };

  const handleNewAttendee = () => {
    _setAddSignupModalOpen(true);
    _setSelectedRecord(null);
  };

  const handleAddSignupSaved = (signup: CalendarEventDateSignupListView) => {
    _setAddSignupModalOpen(false);
    _setSelectedRecord(null);
    _setRegister({
      ..._register,
      attendees:
        signup.id != null
          ? _register.attendees.map(attendee =>
              attendee.id === signup.id ? signup : attendee
            )
          : [..._register.attendees, signup],
    });
  };

  const handleSignupModalClosed = () => {
    _setAddSignupModalOpen(false);
    _setSelectedRecord(null);
  };

  const editMenuChoices = (record: CalendarEventDateSignupListView)  => {
    _setSelectedRecord(record);
    _setAddSignupModalOpen(true);
  };

  return (
    <Modal
      title="Event Register"
      open={_open}
      onClose={handleCloseModal}
      width="95%"
      height="80%"
      //loading={loadingRegister}
    >
      <Modal.Body scrollOnErrors={validationErrors}>
        <Title
          size={TitleSize.H2}
          text={calendarEvent?.title}
          sub={moment(_eventDate?.starts).format("dddd, Do MMMM YYYY")}
        />
        {_register != null ? (
          <>
            <Message
              text={registerError || signupError}
              color={Swatches.Danger}
            />
            <ValidationMessage errors={validationErrors} />

            <HeadlineStatistic>
              <HeadlineStatistic.Item
                icon="users"
                label="Attendees"
                value={_register.paging?.totalRecords}
              />
              <HeadlineStatistic.Item
                icon="users"
                label="Total Places"
                value={_register.signupLimit}
              />
              <HeadlineStatistic.Item
                icon="users"
                label="Places Remaining"
                value={
                  _register.signupLimit - _register.attendees?.length < 0
                    ? 0
                    : _register.signupLimit - _register.attendees?.length
                }
              />
            </HeadlineStatistic>

            <ActionBar>
              <Left>
                {!mandatoryAttendance && (
                  <Button
                    text="Add New Attendee"
                    onClick={() => handleNewAttendee()}
                    color={Swatches.Primary}
                    size={Size.Small}
                  />
                )}
              </Left>
              <Right>
                <SplitButton
                  size={Size.Small}
                  text="Export Register"
                  color={Swatches.Default}
                  onDefaultClick={handleExport}
                  working={
                    savingRegister ||
                    _exportLoading ||
                    _exportMenu ||
                    _exportMenuTotals
                  }
                >
                  <SplitButton.Option
                    onClick={handleExportMenu}
                    text="Export Menu Selections"
                    show={_register?.hasMenu}
                  />
                  <SplitButton.Option
                    onClick={handleExportMenuTotals}
                    text="Export Menu Totals"
                    show={_register?.hasMenu}
                  />
                </SplitButton>
              </Right>
            </ActionBar>

            <AddSignupModal
              open={_addSignupModalOpen}
              eventDate={_eventDate}
              onClose={() => handleSignupModalClosed()}
              menu={calendarEvent?.menu}
              signup={_selectedRecord}
              onSave={handleAddSignupSaved}
            />

            <Card grow>
              <Card.Body noPad>
                <AttendeeTable
                  attendees={_register?.attendees}
                  mandatoryAttendance={mandatoryAttendance}
                  onPage={handlePage}
                  paging={_register?.paging}
                  error={registerError}
                  getAttendanceColor={getAttendanceColor}
                  setAttendance={setAttendance}
                  signingUp={signingUp}
                  handleCancelSignup={handleCancelSignup}
                  loading={initialLoad}
                />
              </Card.Body>
            </Card>
            <IssueRewardsModal
              open={_issueRewardsModalOpen}
              register={_register}
              eventDate={_eventDate}
              onClose={handleIssueRewardsModalClosed}
              onSave={handleIssueRewardsSaved}
            />
          </>
        ) : (
          <EmptyMessage
            cover
            title="No register was found."
            summary="No register was found for this date"
            icon="calendar"
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Left>
            <Button
              text="Close"
              onClick={onClose}
              color={Swatches.Low}
              size={Size.Small}
              working={
                savingRegister ||
                _exportLoading ||
                _exportMenu ||
                _exportMenuTotals
              }
            />
          </Left>
          <Right>
            {_register?.attendees?.some(
              x => x.attendance === 1 && !x.rewardId
            ) && (
                <Button
                  text="Issue Rewards"
                  onClick={handleIssueRewards}
                  color={Swatches.Primary}
                  size={Size.Small}
                />
              )}
            <Button
              text="Save Register"
              onClick={handleSave}
              color={Swatches.Success}
              size={Size.Small}
              working={savingRegister || _exportLoading}
              disabled={
                _register?.paging?.pageNumber < _register?.paging?.totalPages
              }
              disabledTooltip={"All records must be loaded in order to save"}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};


interface IAttendeeTableProps {
  attendees: CalendarEventDateSignupListView[];
  mandatoryAttendance?: boolean;
  onPage?: () => void;
  paging?: PageInfo;
  loading?: boolean;
  error?: string;
  getAttendanceColor?: (record: CalendarEventDateSignupListView, matchValue: number) => string;
  setAttendance?: (index: number, value: number) => void;
  signingUp?: boolean;
  handleCancelSignup?: (record: CalendarEventDateSignupListView, index: number) => void;
  editMenuChoices?: (record: CalendarEventDateSignupListView) => void;
  hasMenu?: boolean;
}


const AttendeeTable: React.FC<IAttendeeTableProps> = ({
  attendees,
  mandatoryAttendance,
  onPage,
  paging,
  loading,
  error,
  getAttendanceColor,
  setAttendance,
  signingUp,
  handleCancelSignup,
  editMenuChoices,
  hasMenu,
}) => {
  return (
    <Table
      grow
      zebra
      error={error != null}
      loading={loading}
      empty={arrays.isEmpty(attendees)}
      emptyMessage={
        <EmptyMessage
          icon="envelope"
          title="No Attendees"
          summary="No attendees for this event have been found."
          cover
        />
      }
    >
      <Table.Header>
        <Table.HeaderCell width={1}>Attendance</Table.HeaderCell>
        <Table.HeaderCell width={3.5}>Attendee</Table.HeaderCell>
        <Table.HeaderCell width={4}>Reward</Table.HeaderCell>
        <Table.HeaderCell width={1.5}></Table.HeaderCell>
      </Table.Header>
      <Table.Body onPage={onPage} paging={paging}>
        {attendees?.map((record: CalendarEventDateSignupListView, index: number) => (
          <Table.Row key={index}>
            <Table.Cell width={1}>
              <CheckWrapper>
                <Icon
                  value="check-circle"
                  size={Size.ExtraLarge}
                  color={getAttendanceColor(record, 1)}
                  onClick={() => setAttendance(index, 1)}
                />
                <Icon
                  value="times-circle"
                  size={Size.ExtraLarge}
                  color={getAttendanceColor(record, 2)}
                  onClick={() => setAttendance(index, 2)}
                />
              </CheckWrapper>
            </Table.Cell>
            <Table.Cell width={3.5}>
              <Avatar user={record.user} />
            </Table.Cell>
            <Table.Cell width={4}>
              <Label>{record.rewardName}</Label>
            </Table.Cell>
            <Table.Cell right width={1.5}>
              {!record.attendance && (
                <SplitButton
                  size={Size.Small}
                  text="Actions"
                  color={Swatches.Default}
                  working={signingUp}
                >
                  <SplitButton.Option
                    onClick={() => handleCancelSignup(record, index)}
                    text="Cancel Signup"
                    color={Swatches.Danger}
                    show={!mandatoryAttendance}
                  />
                  <SplitButton.Option
                    onClick={() => editMenuChoices(record)}
                    text="Update Menu Choices"
                    show={hasMenu}
                  />
                </SplitButton>
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default EventDateRegisterModal;
