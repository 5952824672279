import React from "react";
import { Chip, Swatches } from "ui-kit";
import { Constants } from "configuration";

interface IConsentFormStatusProps {
  status: number;
}

const ConsentFormStatus: React.FC<IConsentFormStatusProps> = ({ status }) => {
  
  var swatch = null;
  var name = "";

  switch (status) {
    case Constants.CONSENTFORM_STATUS.DRAFT.value:
      swatch = Swatches.Low;
      name = Constants.CONSENTFORM_STATUS.DRAFT.name;
      break;
    case Constants.CONSENTFORM_STATUS.SUBMITTED.value:
      swatch = Swatches.Low;
      name = Constants.CONSENTFORM_STATUS.SUBMITTED.name;
      break;
    case Constants.CONSENTFORM_STATUS.APPROVED.value:
      swatch = Swatches.Warning;
      name = Constants.CONSENTFORM_STATUS.APPROVED.name;
      break;
    case Constants.CONSENTFORM_STATUS.CONFIRMED.value:
      swatch = Swatches.Success;
      name = Constants.CONSENTFORM_STATUS.CONFIRMED.name;
      break;
    case Constants.CONSENTFORM_STATUS.COMPLETE.value:
      swatch = Swatches.Complete;
      name = Constants.CONSENTFORM_STATUS.COMPLETE.name;
      break;
    case Constants.CONSENTFORM_STATUS.CANCELLED.value:
      swatch = Swatches.Cancel;
      name = Constants.CONSENTFORM_STATUS.CANCELLED.name;
      break;
    default:
      return null;
  }

  return <Chip text={name} colorSwatch={swatch} />;
};

export default ConsentFormStatus;
