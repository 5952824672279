import React from "react";
import { ReactComponent as BlackSvg } from "assets/schools/mer/black.svg";
import { ReactComponent as WhiteSvg } from "assets/schools/mer/white.svg";
import { ReactComponent as ColorSvg } from "assets/schools/mer/color.svg";
import { ISchoolIconProps } from "./schoolIcon";

export const BlackLogo: React.FC<ISchoolIconProps> = ({ code, className, style, width, height }) => {
  return (
    <BlackSvg
      title={code}
      className={className}
      style={{ ...style, width: width ?? 24, height: height  ?? 24 }}
    />
  );
};

export const WhiteLogo: React.FC<ISchoolIconProps> = ({ code, className, style, width, height }) => {
  return (
    <WhiteSvg
      title={code}
      className={className}
      style={{ ...style, width: width ?? 24, height: height  ?? 24 }}
    />
  );
};

const ColorLogo: React.FC<ISchoolIconProps> = ({ code, className, style, width, height }) => {
  return (
    <ColorSvg
      title={code}
      className={className}
      style={{ ...style, width: width ?? 24, height: height  ?? 24 }}
    />
  );
};

export default ColorLogo;
