import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import urls from "utils/urls";
import { BeehiveReport, callbackType } from "types/common/action.types";
import fileDownload from "js-file-download";
import {
  SyncReportsFilter,
  SyncUserWarningFilter,
} from "../types/syncRequest.types";
import {
  SyncFailureReportResponse,
  SyncLogReportResponse,
  SyncUserWarningsResponse,
} from "../types/syncResponses.types";

const builder = new actionTypeBuilder("syncReports");

const types = {
  GETSYNCFAILUREREPORT: builder.build("getsyncfailurereport"),
  GETSYNCLOG: builder.build("getsynclog"),
  GETWARNINGSFORUSER: builder.build("getwarningsforuser"),

  EXPORTSYNCFAILUREREPORT: builder.build("exportsyncfailurereport"),
  EXPORTWARNINGSFORUSER: builder.build("exportwarningsforuser"),
};

const getSyncFailureReport = <T extends boolean = false>(
  filter: SyncReportsFilter,
  pageIndex?: number,
  exportReport?: T,
  onSuccess?: callbackType<
    T extends true ? BeehiveReport : SyncFailureReportResponse
  >
) => {
  let url = new urls.QueryString("/sync/failures");

  url.addParams({
    SchoolId: filter.schoolId,
    ObjectTypeId: filter.type ?? undefined,
    SimsId: filter.simsId || undefined,
    PageIndex: pageIndex,
    PageSize: 50,
  });

  return exportReport
    ? action<BeehiveReport>(
        () =>
          client.get("/sync/failuresExport", {
            params: {
              SchoolId: filter.schoolId,
              ObjectTypeId: filter.type ?? undefined,
              SimsId: filter.simsId,
            },
            responseType: "blob",
          }),
        types.EXPORTSYNCFAILUREREPORT,
        (response) => {
          onSuccess?.(response as any);
          fileDownload(response, "SyncFailureReport.csv");
        }
      )
    : action<SyncFailureReportResponse>(
        () => client.get(url.toUrl()),
        types.GETSYNCFAILUREREPORT,
        (response) => onSuccess?.(response as any)
      );
};

const getSyncLog = (
  fileDate?: Date | null,
  onSuccess?: callbackType<SyncLogReportResponse>
) => {
  let url = new urls.QueryString("/sync/log");

  url.addParams({ fileDate: fileDate || "null" });

  return action<SyncLogReportResponse>(
    () => client.get(url.toUrl()),
    types.GETSYNCLOG,
    onSuccess
  );
};

const getWarningsForUser = <T extends boolean = false>(
  schoolId: number,
  filter: SyncUserWarningFilter,
  pageIndex?: number,
  exportReport?: T,
  onSuccess?: callbackType<
    T extends true ? BeehiveReport : SyncUserWarningsResponse
  >
) => {
  let url = new urls.QueryString("/sync/WarningsForUser");

  url.addParams({
    SchoolId: schoolId,
    SearchTerm: filter.searchTerm || undefined,
    userType: filter.userType ?? undefined,
    PageIndex: pageIndex,
    PageSize: 50,
  });

  return exportReport
    ? action<BeehiveReport>(
        () =>
          client.get("/sync/WarningsForUserExport", {
            params: {
              SchoolId: schoolId,
              SearchTerm: filter.searchTerm || undefined,
              userType:
                filter.userType !== undefined && filter.userType !== null
                  ? filter.userType
                  : undefined,
            },
            responseType: "blob",
          }),
        types.EXPORTWARNINGSFORUSER,
        (response) => {
          onSuccess?.(response as any);
          fileDownload(response, "UserWarningsReport.csv");
        }
      )
    : action<SyncUserWarningsResponse>(
        () => client.get(url.toUrl()),
        types.GETWARNINGSFORUSER,
        (response) => onSuccess?.(response as any)
      );
};

const syncActions = {
  types,
  getSyncFailureReport,
  getSyncLog,
  getWarningsForUser,
};

export default syncActions;
