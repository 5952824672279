import Tippy from "@tippyjs/react";
import React, { useRef } from "react";
import styled from "styled-components";
import {
  Card,
  formatScrollbars,
  getFontSize,
  List,
  neutral,
  Size,
  Spacing,
  Sub,
  Tooltip,
} from "ui-kit";
import { danger } from "ui-kit/common/colors";
import { StudentPicker } from "sharedComponents/common";
import Avatar from "sharedComponents/common/users/avatar";
import { objects } from "utils";
import { StudentListView, UserTinyView } from "types/users/userListViews.types";


const Wrapper = styled.div<{ leftMargin: boolean }>`
  display: flex;
  height: 100%;

  .on-call-content {
    flex-grow: 1;
    height: 100%;
    margin-left: 280px;
  }

  ${({ leftMargin }) =>
    leftMargin &&
    `.on-call-content {
         margin-left: 0;
      }
  `}
`;

const StudentListWrapper = styled.div`
  margin-bottom: ${Spacing.Medium}px;
  overflow-y: auto;
  ${formatScrollbars()}
  position: absolute;
  min-height: 100%;
  max-height: 100%;
  width: 268px;
  min-width: 268px;

  .card {
    margin-bottom: ${Spacing.Small + 4}px;
  }

  .remove-student-button {
    align-self: flex-start;
    font-size: ${getFontSize(Size.ExtraLarge)}rem;
    background-color: unset;
    border: none;
    margin: 0;
    padding: 0;
    color: ${neutral[500]};
    cursor: pointer;
    line-height: 1rem;

    &:hover {
      //   font-weight: bolder;
      color: ${danger};
    }
  }
`;


interface IMultipleStudentWrapperProps {
  students: UserTinyView[];
  handleStudentListChange: (students: UserTinyView[]) => void;
  children: React.ReactNode;
  schoolId: number;
  allowStudentListChange?: boolean;
}


const MultipleStudentWrapper: React.FC<IMultipleStudentWrapperProps> = ({
  students,
  handleStudentListChange,
  children,
  schoolId,
  allowStudentListChange,
}) => {

  const ref = useRef<HTMLButtonElement>();

  const onClickRemoveStudent = (studentId: string) => {
    const studentsCopy = objects.deepClone(students);
    const studentIndex = studentsCopy.findIndex(
      student => student.id === studentId
    );

    if (studentIndex >= 0) {
      studentsCopy.splice(studentIndex, 1);
      handleStudentListChange(studentsCopy);
    }
  };

  const onClickAddStudent = (value: StudentListView) => {
    const studentsCopy = objects.deepClone(students);
    studentsCopy.push(value as UserTinyView);
    handleStudentListChange(studentsCopy);
  };

  return (
    <Wrapper leftMargin={!(students?.length > 0 || allowStudentListChange)}>
      <StudentListWrapper>
        {allowStudentListChange && (
          <Card>
            <Card.Body>
              <StudentPicker
                fluid
                onChange={onClickAddStudent}
                schools={[schoolId]}
              />
            </Card.Body>
          </Card>
        )}

        {students?.length > 0 ? (
          <List title="Students">
            {students.map((student, index) => (
              <List.Item
                left={<Avatar user={student} />}
                right={
                  allowStudentListChange && (
                    <>
                      <button
                        ref={ref}
                        className="remove-student-button"
                        onClick={event => {
                          event.stopPropagation();
                          onClickRemoveStudent(student.id);
                        }}
                      >
                        &#10799;
                      </button>
                      <Tippy
                        reference={ref}
                        content={<Tooltip title="Remove Student" />}
                      />
                    </>
                  )
                }
              />
            ))}
          </List>
        ) : (
          allowStudentListChange && (
            <Sub>
              You can create a general on call request or you can optionally
              choose specific students using the search above.
            </Sub>
          )
        )}
      </StudentListWrapper>

      <div className="on-call-content">{children}</div>
    </Wrapper>
  );
};


export default MultipleStudentWrapper;
