import { SenType } from "areas/analytics/constants/behaviourReportConstants";
import React, { useEffect, useState } from "react";
import { Checkbox } from "ui-kit";


interface ISenTypeCheckboxProps {
  senType: SenType;
  selectedSenTypes: SenType[];
  handleSenTypeClick: (senType: SenType, isChecked: boolean) => void;
}

const SenTypeCheckbox: React.FC<ISenTypeCheckboxProps> = ({ senType, selectedSenTypes, handleSenTypeClick }) => {

  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    const foundSenType = selectedSenTypes.find(type => type.id === senType.id);

    if (foundSenType !== undefined) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [selectedSenTypes]);

  const handleCheckboxChange = () => {
    const newIsChecked = !isChecked;
    handleSenTypeClick(senType, newIsChecked);
    setIsChecked(newIsChecked);
  };

  return (
    <Checkbox
      text={senType.text}
      checked={isChecked}
      onChange={handleCheckboxChange}
    />
  );
};

export default SenTypeCheckbox;
