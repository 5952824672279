import React from "react";
import { useNavigate } from "react-router";
import { adminRoutes } from "../adminRoutes";
import routes from "configuration/routes";
import SearchForm from "areas/search/searchForm";


const Dashboard = () => {

  const navigate = useNavigate();

  const handleSearch = (searchTerm: string) => {
    navigate(`${routes.home}/administration${adminRoutes.search}`, {
      state: { searchTerm: searchTerm },
    });
  };
  return <SearchForm onSearch={handleSearch} initialValue={null} />;
};

export default Dashboard;
