import paymentActions from "areas/payments/actions/paymentsActions";
import { SmartcardTransactionListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import defaults from "configuration/defaults";
import { IBeehiveAction } from "types/common/action.types";
import { CashlessPageInfo } from "types/common/paging.types";


export interface IUserSmartcardTransactionsState {
  transactions: SmartcardTransactionListView[];
  paging: CashlessPageInfo;
  error: string | null;
  loading: boolean;
}

const INITIAL_STATE: IUserSmartcardTransactionsState = {
  transactions: [],
  paging: defaults.DEFAULT_ALT_PAGING,
  error: null,
  loading: false
};

const userSmartcardTransactionsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUserSmartcardTransactionsState => {

  const { 
    PAYMENTS_GETUSERSMARTCARDTRANSACTIONS 
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETUSERSMARTCARDTRANSACTIONS.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETUSERSMARTCARDTRANSACTIONS.SUCCESS:
      return { 
        ...state, 
        transactions: action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.transactions, ...action.payload.items],
        paging: action.payload.paging,
        loading: false 
      };

    case PAYMENTS_GETUSERSMARTCARDTRANSACTIONS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default userSmartcardTransactionsReducer;
