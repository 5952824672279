import { BULK_BEHAVIOUR_STATUS } from "areas/behaviour/constants/behaviours";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";
import { MultipleBehavioursSaveResultsView } from "areas/behaviour/types/behaviourResponses.types";
import { objects } from "utils";
import { IMultipleBehaviourResponse } from "areas/behaviour/utils/detentions";


interface IBehaviourForMultipleStudentsState {
  multipleBehaviourResponse: IMultipleBehaviourResponse;
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IBehaviourForMultipleStudentsState = {
  multipleBehaviourResponse: null,
  loading: false,
  error: null,
};

const { 
  CREATEMULTIPLEBEHAVIOURRESPONSE, 
  ADDBEHAVIOURFORMULTIPLE 
} = behaviourActions.types;


const behaviourForMultipleStudentsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IBehaviourForMultipleStudentsState => {
  
  switch (action.type) {
    case CREATEMULTIPLEBEHAVIOURRESPONSE:
      return { ...state, multipleBehaviourResponse: action.payload };

    case ADDBEHAVIOURFORMULTIPLE.START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ADDBEHAVIOURFORMULTIPLE.SUCCESS:
      const multipleBehaviourResponseCopy = objects.deepClone(state.multipleBehaviourResponse);
      const apiAddBehavioursResponse: MultipleBehavioursSaveResultsView[] = action.payload;

      const newMultipleBehaviourResponse =
        multipleBehaviourResponseCopy.behaviours.map(behaviour => {
          const studentBehaviour = apiAddBehavioursResponse.find(
            response => response.student.id === behaviour.student.id
          );
          return {
            ...behaviour,
            errorMessage: studentBehaviour.errorMessage,
            isSuccessful: studentBehaviour.isSuccessful,
            detentionSummary: studentBehaviour.detentions,
          };
        });

      return {
        ...state,
        multipleBehaviourResponse: {
          ...state.multipleBehaviourResponse,
          bulkBehaviourStatus: BULK_BEHAVIOUR_STATUS.SENT,
          behaviours: newMultipleBehaviourResponse,
        },
        loading: false,
      };

    case ADDBEHAVIOURFORMULTIPLE.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default behaviourForMultipleStudentsReducer;
