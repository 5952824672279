import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import { BehaviourIntialActionView } from "areas/behaviour/types/behaviourResponses.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";

interface IInitialBehaviourActionsState {
  initialBehaviourActions: BehaviourIntialActionView[];
  loadingBehaviourActions: boolean;
  error: IBeehiveError;
}
const INITIAL_STATE: IInitialBehaviourActionsState = {
  initialBehaviourActions: [],
  loadingBehaviourActions: false,
  error: null,
};

const { GETINITIALBEHAVIOURACTIONS } = behaviourActions.types;

const initialBehaviourActionsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IInitialBehaviourActionsState => {

  switch (action.type) {
    case GETINITIALBEHAVIOURACTIONS.START:
      return {
        ...state,
        loadingBehaviourActions: true,
        error: null,
      };

    case GETINITIALBEHAVIOURACTIONS.SUCCESS:
      return {
        ...state,
        loadingBehaviourActions: false,
        initialBehaviourActions: action.payload,
      };

    case GETINITIALBEHAVIOURACTIONS.FAILED:
      return {
        ...state,
        loadingBehaviourActions: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default initialBehaviourActionsReducer;
