import React from "react";
import { Interface } from "readline";
import styled from "styled-components";
import { BorderRadius, fontStyle, textFont, typescale } from "ui-kit";
import { success, neutral, danger } from "ui-kit/common/colors";

const Wrapper = styled.div`
  border-radius: ${BorderRadius.Default}px;
  display: flex;
  justify-content: center;

  .ratio {
    border-radius: ${BorderRadius.Default}px;
    overflow: hidden;
    display: flex;
    width: 75%;
    height: 24px;

    .reward-ratio,
    .behaviour-ratio {
      ${fontStyle(textFont.medium, typescale.paragraph, neutral[100])};
      line-height: 1.35rem !important;
    }

    .reward-ratio {
      //   border-radius: ${BorderRadius.Default}px 0 0 ${BorderRadius.Default}px;
      background: ${success};
    }

    .behaviour-ratio {
      //   border-radius: 0 ${BorderRadius.Default}px ${BorderRadius.Default}px 0;
      background: ${danger};
    }
  }
`;


interface IBehaviourRatioProps {
  rewardRatio: number;
}

const BehaviourRatio: React.FC<IBehaviourRatioProps> = ({ rewardRatio }) => {
  return (
    <Wrapper>
      <div className="ratio">
        <div className="reward-ratio" style={{ width: `${rewardRatio}%` }}>
          {`${rewardRatio}%`}
        </div>
        <div
          className="behaviour-ratio"
          style={{ width: `${100 - rewardRatio}%` }}
        >
          {`${100 - rewardRatio}%`}
        </div>
      </div>
    </Wrapper>
  );
};

export default BehaviourRatio;
