import moment from "moment";
import {
  getDateTime,
  todayDateTime,
  todayDate,
  formatDate,
  todayTime,
  formatTime,
} from "utils/dateTime";
import {
  DETENTION_STATUS_KEYS,
  DETENTION_CATEGORIES,
} from "../constants/detentions";
import { IDetentionDetails, IIncidentDetails, TinyGroup } from "./detentions";
import { BehaviourView, DetentionStudentSummaryView } from "../types/behaviourResponses.types";
import { PlannerSchoolView } from "areas/planner/types/plannerSchoolResponse.types";
import { DetentionPeriod } from "../types/behaviourShared.types";


export const ERRORS: Record<string, string> = {
  DETENTION_TIME_ERROR:
    "Sanction cannot be earlier than the current date and time",
  INCIDENT_DETENTION_TIME_ERROR:
    "Incident date/time cannot be after than the sanction date/time",
  OVERLAPPING_DETENTION_ERROR:
    "The sanction is overlapping with another sanction. Please look at the pupil's detention summary for the day above and select a date and time where there are no other sanctions.",
  INCIDENT_TIME_ERROR: "Incident date/time cannot be in the future",
  DETENTION_DATE_ERROR:
    "The sanction date cannot be set for today as it is past the cut off time. Please select the next school day for the sanction.",
  WEEKEND_ERROR:
    "Sanction date falls on a weekend, please select a school day.",
  DETENTION_END_TIME_ERROR:
    "The sanction cannot start after or go past the end time limit of ",
  DETENTION_START_TIME_ERROR: "The sanction cannot start before ",
};


//internal
export const overlappingDetentionFound = (
  detentionDateTime: string,
  detentionDetails: IDetentionDetails,
  detentionSummary: DetentionStudentSummaryView[],
  oldDetentionId: number
) : boolean => {
  const endDateTime = moment(detentionDateTime)
    .add(detentionDetails.duration, "minutes")
    .format();

  let overlappingDetention = false;

  detentionSummary.forEach(detention => {
    if (!oldDetentionId || oldDetentionId !== detention.detentionId) {
      if (
        detentionDetails.id !== detention.detentionId &&
        detention.statusId !== DETENTION_STATUS_KEYS.CANCELLED &&
        detention.statusId !== DETENTION_STATUS_KEYS.RESCHEDULED
      ) {
        if (
          (detentionDateTime >= moment(detention.startDate).format() &&
            detentionDateTime < moment(detention.endDate).format()) ||
          (endDateTime > moment(detention.startDate).format() &&
            endDateTime < moment(detention.endDate).format()) ||
          (detentionDateTime < moment(detention.startDate).format() &&
            endDateTime > moment(detention.startDate).format())
        ) {
          overlappingDetention = true;
        }
      }
    }
  });
  return overlappingDetention;
};


//internal
const getValidDetentionEarliestTime = (
  detentionPeriodId: DetentionPeriod,
  schoolInformation: PlannerSchoolView
) : string => {
  let earliestDetentionStartTime;

  if (detentionPeriodId === DETENTION_CATEGORIES.AFTER_SCHOOL) {
    earliestDetentionStartTime = schoolInformation.detentionAfterSchoolStarts
      ? moment(schoolInformation.detentionAfterSchoolStarts, [
          "HH:mm:ss",
        ]).format("HH:mm")
      : null;
  } else {
    earliestDetentionStartTime = schoolInformation.detentionStartTime
      ? moment(schoolInformation.detentionStartTime, ["HH:mm:ss"]).format(
          "HH:mm"
        )
      : null;
  }
  return earliestDetentionStartTime;
};


//internal
const getValidDetentionLatestEndTime = (
  detentionPeriodId: DetentionPeriod,
  schoolInformation: PlannerSchoolView
) : string => {
  let latestDetentionEndTime;

  if (detentionPeriodId === DETENTION_CATEGORIES.AFTER_SCHOOL) {
    latestDetentionEndTime = schoolInformation.detentionAfterSchoolEnds
      ? moment(schoolInformation.detentionAfterSchoolEnds, ["HH:mm:ss"]).format(
          "HH:mm"
        )
      : null;
  } else {
    latestDetentionEndTime = schoolInformation.detentionClosingTime
      ? moment(schoolInformation.detentionClosingTime, ["HH:mm:ss"]).format(
          "HH:mm"
        )
      : null;
  }
  return latestDetentionEndTime;
};


//external
export const detentionValidation = (
  detentionDetails: IDetentionDetails,
  incidentDetails: IIncidentDetails,
  detentionSummary: DetentionStudentSummaryView[],
  schoolInformation: PlannerSchoolView,
  newDetention?: boolean,
  validationErrors?: string[],
  oldDetentionId?: number
) => {

  let latestDetentionEndTime = getValidDetentionLatestEndTime(
    detentionDetails.detentionPeriodId,
    schoolInformation
  );
  let earliestDetentionStartTime = getValidDetentionEarliestTime(
    detentionDetails.detentionPeriodId,
    schoolInformation
  );

  // Format detention and incident date and time
  const detentionDateTime = getDateTime(
    formatDate(detentionDetails.date),
    detentionDetails.time
  );

  const incidentDateTime = getDateTime(
    formatDate(incidentDetails.incidentDate),
    incidentDetails.incidentTime
  );

  console.log(incidentDateTime);

  // Detention date/time in the past
  if (detentionDateTime < todayDateTime()) {
    validationErrors.push(ERRORS.DETENTION_TIME_ERROR);
  }

  // Incident date/time earlier than detention date/time
  if (incidentDateTime > detentionDateTime) {
    validationErrors.push(ERRORS.INCIDENT_DETENTION_TIME_ERROR);
  }

  incidentValidation(incidentDetails, validationErrors);

  // Overlapping detentions
  if (
    !schoolInformation.allowOverlapDetentions &&
    overlappingDetentionFound(
      detentionDateTime,
      detentionDetails,
      detentionSummary,
      oldDetentionId
    )
  ) {
    validationErrors.push(ERRORS.OVERLAPPING_DETENTION_ERROR);
  }

  // Only check if new detention being added, not if rescheduling or updating
  if (
    newDetention &&
    formatDate(detentionDetails.date) === todayDate() &&
    todayTime() >
      moment(schoolInformation.detentionCutOffTime, ["HH:mm:ss"]).format(
        "HH:mm"
      )
  ) {
    validationErrors.push(ERRORS.DETENTION_DATE_ERROR);
  }

  // Detention date falling on weekend
  if (
    moment(detentionDateTime).day() === 0 ||
    moment(detentionDateTime).day() === 6
  ) {
    validationErrors.push(ERRORS.WEEKEND_ERROR);
  }

  // Detention end time is past school detention end time
  if (
    latestDetentionEndTime &&
      moment(detentionDateTime)
      .add(detentionDetails.duration, "minutes")
      .isAfter(latestDetentionEndTime)
  ) {
    validationErrors.push(
      ERRORS.DETENTION_END_TIME_ERROR + latestDetentionEndTime
    );
  }

  // Detention start time is before school detention start time
  if (
    earliestDetentionStartTime &&
    moment(detentionDateTime).isBefore(earliestDetentionStartTime)
  ) {
    validationErrors.push(
      ERRORS.DETENTION_START_TIME_ERROR + earliestDetentionStartTime
    );
  }
};


//internal
export const incidentValidation = (incidentDetails: IIncidentDetails, validationErrors: string[]) => {
  const incidentDateTime = getDateTime(
    formatDate(incidentDetails.incidentDate),
    incidentDetails.incidentTime
  );

  if (incidentDateTime > todayDateTime()) {
    validationErrors.push(ERRORS.INCIDENT_TIME_ERROR);
  }
};


//external
export const behaviourValidation = (
  incidentDetails: IIncidentDetails,
  detentionDetails: IDetentionDetails,
  detentionSummary: DetentionStudentSummaryView[],
  schoolInformation: PlannerSchoolView,
  newDetention?: boolean
) => {
  let errors: string[] = [];
  if (incidentDetails) {
    if (detentionDetails) {
      detentionValidation(
        detentionDetails,
        incidentDetails,
        detentionSummary,
        schoolInformation,
        newDetention,
        errors
      );
    } else {
      incidentValidation(incidentDetails, errors);
    }
  }
  return errors;
};


//external
export const bulkBehaviourValidation = (
  incidentDetails: IIncidentDetails,
  detentionDetails: IDetentionDetails,
  detentionCutOffTime: string
) => {

  let errors: string[] = [];
  if (incidentDetails) {
    const incidentDateTime = getDateTime(
      formatDate(incidentDetails.incidentDate),
      incidentDetails.incidentTime
    );

    if (detentionDetails) {
      const detentionDateTime = getDateTime(
        formatDate(detentionDetails.date),
        detentionDetails.time
      );

      if (detentionDateTime < todayDateTime()) {
        errors.push(ERRORS.DETENTION_TIME_ERROR);
      }

      if (incidentDateTime > detentionDateTime) {
        errors.push(ERRORS.INCIDENT_DETENTION_TIME_ERROR);
      }

      if (
        formatDate(detentionDetails.date) === todayDate() &&
        todayTime() > moment(detentionCutOffTime, ["HH:mm:ss"]).format("HH:mm")
      ) {
        errors.push(ERRORS.DETENTION_DATE_ERROR);
      }
    }
    incidentValidation(incidentDetails, errors);
  }
  return errors;
};


//external
export const upscaleBehaviourValidation = (
  incidentDetails: IIncidentDetails,
  detentionDetails: IDetentionDetails,
  oldDetentionId: number | null,
  detentionSummary: DetentionStudentSummaryView[],
  schoolInformation: PlannerSchoolView,
) => {
  let errors: string[] = [];
  if (incidentDetails) {
    if (detentionDetails) {
      detentionValidation(
        detentionDetails,
        incidentDetails,
        detentionSummary,
        schoolInformation,
        null,
        errors,
        oldDetentionId
      );
    } else {
      incidentValidation(incidentDetails, errors);
    }
  }
  return errors;
};


//external
export const getIncidentDetails = (behaviour: BehaviourView) : IIncidentDetails => {
  return {
    period: behaviour.periodId,
    incidentLocation: behaviour.roomId,
    otherIncidentLocation: behaviour.otherLocation,
    incidentDate: moment(behaviour.incidentDate).format(),
    incidentTime: formatTime(behaviour.incidentDate),
    initialActionId: behaviour.initialActionId,
    initialActionName: behaviour.initialActionName,
  };
};


//external
export const readyToSubmit = (
  initialActionId: number,
  detentionDetails: IDetentionDetails,
  selectedClass?: TinyGroup
) : boolean => {
  // if (selectedClass === null) {
  //   return false;
  // }

  if (initialActionId === null) {
    return false;
  }

  if (!detentionDetails) {
    return true;
  } else if (
    detentionDetails.detentionLocationId === null ||
    detentionDetails.duration < 15
  ) {
    return false;
  } else {
    return true;
  }
};
