import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { Avatar, HelpMessage, SchoolAvatar } from "sharedComponents/common";
import { Card, CircleIcon, DetailLabel, List, Size, Spacing, StructuredList } from "ui-kit";
import { CustomGroupCategory } from "./customGroups/customGroupCategoryDropdown";
import { UserTinyView } from "types/users/userListViews.types";
import styled from "styled-components";
import Moment from "react-moment";
import { CalendarEventListView } from "areas/calendar/types/calendarResponses.types";
import { group } from "console";
import { useNavigate } from "react-router";
import { eventRoutes } from "areas/calendar/calendarRoutes";


const StaffWrapper = styled.div`
  margin-bottom: ${Spacing.Large}px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const GroupDetails = () => {

  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { group } = useSelector((state: RootState) => state.group); //ClassGroupStaffView

  if (!group) {
    return (
      <></>
    )
  }

  return (
    <>
      { group.school?.id === 99 && (
        <HelpMessage
          icon="info-circle"
          sub="This group cannot have a seating plan or batch issuing of behaviours as it is a Trust group"
          marginTop={Spacing.ExtraSmall}
          marginBottom={Spacing.Default}
          padding={Spacing.Medium}
        />
      )}
      <Card title={"Group Details"}>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="School">
              <SchoolAvatar school={group?.school} />
            </StructuredList.Item>
            <StructuredList.Item name="Name">
              {group?.name}
            </StructuredList.Item>
            <StructuredList.Item name="Summary" hide={!group?.summary}>
              {group?.summary}
            </StructuredList.Item>
            <StructuredList.Item name="Category">
              {CustomGroupCategory[group?.category]}
            </StructuredList.Item>
            <StructuredList.Item name="Status">
              {group?.isActive ? "Active" : "Inactive" }
            </StructuredList.Item>
            <StructuredList.Item name="Private">
              {group?.isPrivate ? "Yes" : "No" }
            </StructuredList.Item>
            <StructuredList.Item name="Created Date">
              <Moment date={group.createdDate} format="DD/MM/YYYY" />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      { group?.linkedEvents?.length > 0 && (
        <Card title={"Group Events"}>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item
                name="Events"
                description="Events that this group are linked to"
              >
                <List>
                  { group?.linkedEvents?.map((linkedEvent: CalendarEventListView, index: number) => (
                    <List.Item
                      key={index}
                      value={linkedEvent}
                      left={
                        <DetailLabel
                          bold
                          label={linkedEvent.title}
                          sub={linkedEvent.mandatoryAttendance
                            ? "Mandatory Attendance"
                            : "Optional Attendance"
                          }
                        />
                      }
                      onClick={() => navigate(eventRoutes.getEventPath(linkedEvent.id))}
                    />
                  ))}
                </List>
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      )}

      <Card title={"Group Students"}>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Student Count">
              {group?.students?.length}
            </StructuredList.Item>
            <StructuredList.Item name="Behaviour Points">
              {group?.totalIssuedBehaviourPoints}
            </StructuredList.Item>
            <StructuredList.Item name="Reward Points">
              {group?.totalIssuedRewardPoints}
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      <Card title={"Group Staff"}>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Responsible Staff">
              { group?.responsibleStaff?.filter((staff: UserTinyView) => staff.id != group?.createdBy.id)
              .map((staff: UserTinyView, index: number) => (
                <StaffWrapper key={index}>
                  <Avatar user={staff} />
                </StaffWrapper>
              ))}
            </StructuredList.Item>
            <StructuredList.Item name="Created By">
              <Avatar user={group?.createdBy} />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
    </>
  );
};

export default GroupDetails;
