import React, { useEffect, useState } from "react";
import { RadialChart } from "react-vis";
import styled from "styled-components";
import { EmptyMessage, Spacing } from "ui-kit";
import BarChart, { BarChartPoint } from "../barChart";
import { StudentBehaviourReportView } from "areas/planner/types/plannerReportResponse.types";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .pie-chart {
    align-self: center;
  }

  .bar-charts {
    padding: ${Spacing.ExtraLarge + 40}px 0;
    /* display: flex;
    justify-content: space-between; */
    align-self: center;
  }
`;


interface IStudentBehaviourChartsProps {
  studentBehaviourData: StudentBehaviourReportView;
}

type PieChartData = {
  angle: number;
  label: string;
  color: string;
}

const StudentBehaviourCharts: React.FC<IStudentBehaviourChartsProps> = ({ studentBehaviourData }) => {
  
  const [rewardData, setRewardData] = useState<BarChartPoint[]>([]);
  const [behaviourData, setBehaviourData] = useState<BarChartPoint[]>([]);
  const [pieChartData, setPieChartData] = useState<PieChartData[]>([]);

  useEffect(() => {
    if (studentBehaviourData) {
      const tempRewardsData = studentBehaviourData.rewards.map(reward => ({
        y: Number(reward.codeName),
        x: reward.total,
        //label: `${reward.codeName} - ${reward.categoryName}`,
      }));

      const tempBehavioursData = studentBehaviourData.behaviours.map(
        behaviour => ({
          y: Number(behaviour.codeName),
          x: behaviour.total,
          //label: `${behaviour.codeName} - ${behaviour.categoryName}`,
        })
      );

      setRewardData(tempRewardsData);
      setBehaviourData(tempBehavioursData);

      const totalPoints =
        studentBehaviourData.totalBehaviourPoints +
        studentBehaviourData.totalRewardPoints;

      const rewardRatio =
        studentBehaviourData.totalRewardPoints > 0 &&
        Math.round(
          (studentBehaviourData.totalRewardPoints / totalPoints) * 100
        );

      const behaviourRatio =
        studentBehaviourData.totalBehaviourPoints > 0 &&
        Math.round(
          (studentBehaviourData.totalBehaviourPoints / totalPoints) * 100
        );

      const tempPieChartData = [];

      const totalBehaviours = studentBehaviourData.totalBehaviourPoints;
      const totalRewards = studentBehaviourData.totalRewardPoints;

      if (behaviourRatio) {
        tempPieChartData.push({
          angle: totalBehaviours,
          label: `${
            behaviourRatio
              ? `${totalBehaviours} Behaviour${
                  totalBehaviours > 1 ? "s" : ""
                } (${behaviourRatio}%)`
              : ""
          }`,
          color: "#990000",
        });
      }

      if (rewardRatio) {
        tempPieChartData.push({
          angle: totalRewards,
          label: `${
            rewardRatio
              ? `${totalRewards} Reward${
                  totalRewards > 1 ? "s" : ""
                } (${rewardRatio}%)`
              : ""
          }`,
          color: "#339900",
        });
      }

      setPieChartData(tempPieChartData);
    }
  }, [studentBehaviourData]);

  return (
    <Wrapper>
      {studentBehaviourData.totalBehaviourPoints +
        studentBehaviourData.totalRewardPoints ===
      0 ? (
        <EmptyMessage
          title="No rewards or behaviours in the selected time period"
          icon="list"
        />
      ) : (
        <>
          {/* <Title text="Rewards & Behaviours" size={TitleSize.H2} /> */}
          <div className="pie-chart">
            <RadialChart
              colorType="literal"
              data={pieChartData}
              width={500}
              height={500}
              showLabels
              labelsRadiusMultiplier={0.8}
              labelsStyle={{
                fill: "#ffffff",
                font: "600 16px neue-haas-grotesk-display",
              }}
            />
          </div>

          <div className="bar-charts">
            {studentBehaviourData.totalRewardPoints > 0 && (
              <BarChart
                chartTitle="Rewards"
                data={rewardData}
                colour="#339900"
                horizontal
              />
            )}

            {studentBehaviourData.totalBehaviourPoints > 0 && (
              <BarChart
                chartTitle="Behaviours"
                data={behaviourData}
                colour="#990000"
                horizontal
              />
            )}
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default StudentBehaviourCharts;
