import React, { useState, useEffect } from "react";
import {
  Table,
  Size,
  EmptyMessage,
  DateTime,
  Button,
  Swatches,
  Chip,
  SplitButton,
} from "ui-kit";
import { arrays, users } from "utils";
import ManagedMoveCloseModal from "./managedMoveCloseModal";
import ManagedMoveEditorModal from "./managedMoveEditorModal";
import { Constants } from "configuration";
import managedMovesActions from "areas/planner/actions/managedMoves/managedMovesActions";
import Avatar from "sharedComponents/common/users/avatar";
import SchoolAvatar from "sharedComponents/common/schools/schoolAvatar";
import { useNavigate } from "react-router";
import { studentRoutes } from "../../plannerRoutes";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";
import { ManagedMoveListView } from "areas/planner/types/managedMoves/managedMovedResponse.types";
import { IBeehiveError } from "types/common/errors.types";
import { useAppSelector } from "reducers/hooks";
import { UserTinyView } from "types/users/userListViews.types";


interface IManagedMovesTableProps {
  student?: PlannerStudentDetailView;
  moves: ManagedMoveListView[];
  loading: boolean;
  error: IBeehiveError;
  hideFooter?: boolean;
  onChange?: () => void;
  showStudent?: boolean;
}


const ManagedMovesTable: React.FC<IManagedMovesTableProps> = ({
  student,
  moves,
  loading,
  error,
  hideFooter,
  onChange,
  showStudent,
}) => {

  const navigate = useNavigate();
  const { user } = useAppSelector(state => state.currentUser);

  const handleRowClick = (move: ManagedMoveListView) => {};

  const handleNewManagedMove = () => {
    _setEditingMove({
      id: null,
      student: student,
      newStudent: null,
      startDate: "",
      hostSchool: null,
      endDate: null,
      points: null,
      status: null,
      statusName: null,
    });
  };

  const [_editingMove, _setEditingMove] = useState<ManagedMoveListView>(null);
  const [_closingMove, _setClosingMove] = useState<ManagedMoveListView>(null);
  const [_student, _setStudent] = useState<PlannerStudentDetailView>(student);

  useEffect(() => {
    _setStudent(student);
  }, [student]);

  const handleEditClick = (move: ManagedMoveListView) => {
    _setEditingMove(move);
  };

  const handleCloseClick = (move: ManagedMoveListView) => {
    _setClosingMove(move);
  };

  const handleModalClose = (move: ManagedMoveListView) => {
    _setEditingMove(null);
    _setClosingMove(null);
    if (move) {
      managedMovesActions.refreshManagedMoves(move);
      onChange?.();
    }
  };

  const handleSuccess = (move: ManagedMoveListView) => {
    if (move) {
      managedMovesActions.refreshManagedMoves(move);
      _setClosingMove(null);
      onChange?.();
    }
  };

  const goToStudent = (student: UserTinyView) => {
    navigate(studentRoutes.getStudentPath(student.id));
  };

  return (
    <>
      <Table
        zebra
        loading={loading}
        empty={arrays.isEmpty(moves)}
        emptyMessage={
          <EmptyMessage
            icon="walking"
            title="No Managed Moves"
            summary="No managed moves were found."
            cover
          >
            {student && (
              <Button
                text="New Managed Move"
                onClick={handleNewManagedMove}
                color={Swatches.Primary}
                size={Size.Small}
              />
            )}
          </EmptyMessage>
        }
        error={error != null}
        grow
      >
        <Table.Header>
          <Table.HeaderCell width={1.25}>Outcome</Table.HeaderCell>
          <Table.HeaderCell width={1.5} show={showStudent}>
            Student
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>From</Table.HeaderCell>
          <Table.HeaderCell width={1}>Host</Table.HeaderCell>
          <Table.HeaderCell width={1}>Start Date</Table.HeaderCell>
          <Table.HeaderCell width={1}>End Date</Table.HeaderCell>
          <Table.HeaderCell width={0.5} right>
            Points
          </Table.HeaderCell>
          <Table.HeaderCell
            width={1.75}
            right
            show={users.isInAnyRoles(user, [
              Constants.ROLES.LEADERSHIP,
              Constants.ROLES.TRUST_LEADERSHIP,
              Constants.ROLES.MOVES_ADMINISTRATOR,
            ])}
          />
        </Table.Header>
        <Table.Body>
          {moves?.map((move, index) => (
            <Table.Row key={index} onClick={() => handleRowClick(move)}>
              <Table.Cell width={1.25}>
                <Chip text={move.statusName} />
              </Table.Cell>
              <Table.Cell width={1.5} show={showStudent}>
                <Avatar
                  user={move.student}
                  onClick={() => goToStudent(move.student)}
                />
              </Table.Cell>
              <Table.Cell width={1}>
                <SchoolAvatar school={move.student.school} short />
              </Table.Cell>
              <Table.Cell width={1}>
                <SchoolAvatar school={move.hostSchool} short />
              </Table.Cell>
              <Table.Cell width={1}>
                <DateTime date={move.startDate} hideTime />
              </Table.Cell>
              <Table.Cell width={1}>
                <DateTime date={move.endDate} hideTime />
              </Table.Cell>
              <Table.Cell width={0.5} right>
                {move.status === 0 ? "" : move.points}
              </Table.Cell>
              <Table.Cell
                width={1.75}
                right
                show={users.isInAnyRoles(user, [
                  Constants.ROLES.LEADERSHIP,
                  Constants.ROLES.TRUST_LEADERSHIP,
                  Constants.ROLES.MOVES_ADMINISTRATOR,
                ])}
              >
                {move.status === 0 && (
                  <SplitButton
                    size={Size.Small}
                    text="Actions"
                    color={Swatches.Default}
                  >
                    <SplitButton.Option
                      onClick={() => handleEditClick(move)}
                      text="Edit"
                      show
                    />
                    <SplitButton.Option
                      onClick={() => handleCloseClick(move)}
                      text="End Move"
                      show
                    />
                    <SplitButton.Option
                      onClick={() => goToStudent(move.student)}
                      text="View Student"
                      show={showStudent}
                    />
                  </SplitButton>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        {!hideFooter && (
          <Table.Footer>
            <Table.Row>
              <Table.Cell colspan={7} right>
                <Button
                  text="New Managed Move"
                  onClick={handleNewManagedMove}
                  color={Swatches.Primary}
                  size={Size.Small}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>

      {_editingMove && (
        <ManagedMoveEditorModal
          open={_editingMove != null}
          move={_editingMove}
          student={_editingMove.student}
          onClose={handleModalClose}
        />
      )}
      <ManagedMoveCloseModal
        open={_closingMove != null}
        move={_closingMove}
        onSuccess={handleSuccess}
        onClose={handleModalClose}
      />
    </>
  );
};

export default ManagedMovesTable;
