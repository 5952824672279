import { LunchBalanceListView } from "areas/payments/types/catering/cateringResponses.types";
import lunchRegisterActions from "../../actions/lunchRegisterActions"
import paymentActions from "areas/payments/actions/paymentsActions";
import defaults from "configuration/defaults";
import { IBeehiveAction } from "types/common/action.types";
import { PageInfo } from "types/common/paging.types";
import { PagedQueryView } from "types/common/views.types";


export interface ILunchBalancesFilter {
  schoolId: number | null;
  tutorGroupId: number | null;
  pupilPremium: boolean;
  freeSchoolMeal: boolean;
}

export interface ILunchBalancesState {
  students: LunchBalanceListView[] | null;
  staff: PagedQueryView<LunchBalanceListView> | null;
  loading: boolean;
  error: null | string;
  paging: PageInfo;
  filter: ILunchBalancesFilter;
}


const INITIAL_STATE: ILunchBalancesState = {
  students: null,
  staff: null,
  filter: {
    schoolId: null,
    tutorGroupId: null,
    pupilPremium: null,
    freeSchoolMeal: null,
  },
  loading: false,
  error: null,
  paging: defaults.DEFAULT_PAGING,
};


const lunchBalancesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ILunchBalancesState => {
  const { 
    CATERING_GETSTUDENTBALANCES, 
    CATERING_GETSTAFFBALANCES, 
    PAYMENTS_SETBALANCESFILTER,
  } = lunchRegisterActions.lunchRegisterTypes;
  const { PAYMENTS_ADDMANUALLUNCHCREDIT } = paymentActions.paymentsTypes;

  switch (action.type) {

    case PAYMENTS_SETBALANCESFILTER:
      return { 
        ...state, 
        filter: action.payload 
      };

    case CATERING_GETSTAFFBALANCES.START:
    case CATERING_GETSTUDENTBALANCES.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case CATERING_GETSTUDENTBALANCES.SUCCESS:
      var newState = {
        ...state,
        students:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.students, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };
      return newState;

    case CATERING_GETSTAFFBALANCES.SUCCESS:
      return {
        ...state,
        staff: action.payload.paging.pageIndex === 0
          ? action.payload
          : {
            ...state.staff,
            paging: action.payload.paging,
            items: [...state.staff.items, ...action.payload.items]
          },
        loading: false,
      }

    case CATERING_GETSTAFFBALANCES.FAILED:
    case CATERING_GETSTUDENTBALANCES.FAILED:
      return { 
        ...state, 
        loading: false, 
        error: action.payload 
      };

    case PAYMENTS_ADDMANUALLUNCHCREDIT.SUCCESS:
      return {
        ...state,
        students: state.students?.map(s =>
          s.user.id === action.payload.user.id
            ? { ...s, balance: action.payload.balance }
            : s
        ),
        staff: {
          ...state.staff,
          items: state.staff?.items?.map(s =>
            s.user.id === action.payload.user.id
              ? { ...s, balance: action.payload.balance }
              : s
          )
        }
      };

    default:
      return state;
  }
};

export default lunchBalancesReducer;
