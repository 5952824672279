import React, { useState } from "react";
import { Tabs } from "ui-kit";
import AllEvents from "./allEvents";
import SignedUpEvents from "./signedUpEvents";

const EventsWrapper = ({ student }) => {
  const [innerTabIndex, setInnerTabIndex] = useState(0);

  return (
    <Tabs
      selectedIndex={innerTabIndex}
      onTabSelected={(name, index) => setInnerTabIndex(index)}
    >
      <Tabs.Pane label="All Events">
        <AllEvents student={student} />
      </Tabs.Pane>

      <Tabs.Pane label="Signed Up">
        <SignedUpEvents student={student} />
      </Tabs.Pane>
    </Tabs>
  );
};

export default EventsWrapper;
