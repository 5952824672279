import { IBeehiveAction } from "types/common/action.types";
import rolesActions from "../../actions/rolesActions";
import { UserListView } from "types/users/userListViews.types";

interface IRoleStaffState {
  staff: UserListView[],
  error: string | null;
  loading: boolean;
  working: boolean;
  staffError: string | null;
};

const INITIAL_STATE: IRoleStaffState = {
  staff: [],
  error: null,
  loading: false,
  working: false,
  staffError: null,
};

const roleStaffReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IRoleStaffState => {
  const {
    ROLES_GETSTAFFBYROLE,
    ROLES_ADDSTAFFTOROLE,
    ROLES_REMOVESTAFFFROMROLE,
  } = rolesActions.types;

  switch (action.type) {
    case ROLES_GETSTAFFBYROLE.START:
      return {
        ...state,
        loading: true,
        working: false,
        staffError: null,
        error: null,
      };

    case ROLES_GETSTAFFBYROLE.SUCCESS:
      return { ...state, staff: action.payload, loading: false };

    case ROLES_GETSTAFFBYROLE.FAILED:
      return {
        ...state,
        loading: false,
        error: "There was a problem loading the staff.",
      };

    case ROLES_ADDSTAFFTOROLE.START:
      return { ...state, working: true, staffError: null, error: null };

    case ROLES_ADDSTAFFTOROLE.SUCCESS:
      return {
        ...state,
        working: false,
        staff: [...state.staff, action.payload],
      };

    case ROLES_ADDSTAFFTOROLE.FAILED:
      return {
        ...state,
        working: false,
        staffError: "There was a problem adding the staff member to the role.",
      };

    case ROLES_REMOVESTAFFFROMROLE.START:
      return { ...state, working: true, staffError: null, error: null };

    case ROLES_REMOVESTAFFFROMROLE.SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        working: false,
        staff: [...state.staff.filter(x => x.id !== action.payload.id)],
      };

    case ROLES_REMOVESTAFFFROMROLE.FAILED:
      return {
        ...state,
        working: false,
        staffError:
          "There was a problem removing the staff member from the role.",
      };

    default:
      return state;
  }
};

export default roleStaffReducer;
