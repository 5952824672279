import { IBeehiveError } from "types/common/errors.types";
import subjectActions from "../actions/subjectActions";
import { IBeehiveAction } from "types/common/action.types";
import { GroupListView } from "types/users/userGroups.types";


interface ISubjectsState {
  subjects: GroupListView[];
  staffSubjects: GroupListView[];
  schoolId: number;
  error: IBeehiveError;
  loading: boolean;
}

const INITIAL_STATE: ISubjectsState = {
  subjects: [],
  staffSubjects: [],
  schoolId: null,
  error: null,
  loading: false,
};

const subjectsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISubjectsState => {

  const { SUBJECTS_GETBYSCHOOL, SUBJECTS_AVAILABLETOSTAFF } = subjectActions.types;

  switch (action.type) {
    case SUBJECTS_GETBYSCHOOL.START:
      return {
        ...state,
        subjects: [],
        schoolId: action.payload,
        loading: true,
        error: null,
      };

    case SUBJECTS_GETBYSCHOOL.SUCCESS:
      return { 
        ...state, 
        subjects: action.payload, 
        loading: false 
      };

    case SUBJECTS_GETBYSCHOOL.FAILED:
      return {
        ...state,
        schoolId: null,
        loading: false,
        error: action.payload,
      };

    case SUBJECTS_AVAILABLETOSTAFF.START:
      return {
        ...state,
        subjects: [],
        loading: true,
        error: null,
      };

    case SUBJECTS_AVAILABLETOSTAFF.SUCCESS:
      return {
        ...state,
        staffSubjects: action.payload,
        loading: false
      };

    case SUBJECTS_AVAILABLETOSTAFF.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default subjectsReducer;
