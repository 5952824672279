import { useState } from "react";
import {
  Flyout,
  ActionBar,
  Button,
  Swatches,
  StructuredList,
  HeadlineStatistic,
  TextInput,
  TextInputType,
  Card,
  ValidationMessage,
  DatePicker} from "ui-kit";
import { Constants } from "configuration";
import { arrays } from "utils";
import { useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import { NewInstalment } from "./instalmentsTable";
import { ProductDetails } from "./productEditor";
import flyoutActions from "actions/ui/flyouts";


const INSTALMENT_DEFAULT: NewInstalment = {
  id: null,
  total: 0,
  dueDate: new Date(),
  installmentType: 0,
  optionalForPupilPremium: false
};

interface InstalmentFlyoutProps {
  instalment: NewInstalment; 
  onInstalmentSaved?: (instalment: NewInstalment) => void;
  product: ProductDetails;
  installmentType: number;
}


const InstalmentFlyout: React.FC<InstalmentFlyoutProps> = ({ instalment, onInstalmentSaved, product, installmentType }) => {

  const [_instalment, _setInstalment] = useState<NewInstalment>(INSTALMENT_DEFAULT);
  const [_product, _setProduct] = useState<ProductDetails>(null);
  const [_standardUnallocated, _setStandardUnallocated] = useState<number>(0);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    if (instalment) {
      _setInstalment(instalment);
    } else {
      _setInstalment(INSTALMENT_DEFAULT);
    }
  }, [instalment]);

  useEffect(() => {
    _setProduct(product);
  }, [product]);

  useEffect(() => {
    _setInstalment({ ..._instalment, installmentType: installmentType });
  }, [installmentType]);

  useEffect(() => {
    if (_product) {

      if (!_product?.instalmentRules.some(x => x.installmentType === _instalment?.installmentType)) {
        _setStandardUnallocated(0);
      }

      var standardAllocated = _product?.instalmentRules.reduce((totalOfType, instalment) => {
        return Number(totalOfType) + (instalment.installmentType == _instalment?.installmentType ? Number(instalment.total) : 0);
      }, 0);

      var standardUnallocated;
      switch (_instalment?.installmentType) {
        case (0):
          standardUnallocated = _product.unitPrice - standardAllocated;
          break;
        case (1):
          standardUnallocated = (_product.unitPrice - _product.pupilPremiumDiscount) - standardAllocated;
          break;
        case (2):
          standardUnallocated = (_product.unitPrice - _product.bursaryDiscount) - standardAllocated;
          break;
        default:
          standardUnallocated = _product.unitPrice - standardAllocated;
          break         
    }

      _setStandardUnallocated(standardUnallocated);
      _setInstalment({ ..._instalment, total: standardUnallocated });
    }
  }, [_product, _instalment.installmentType]);


  const handleSaveInstalment = () => {
    // Validate
    var errors = [];

    if (!_instalment?.total || _instalment?.total < 0) {
      errors.push(`An valid instalment total is required.`);
    }
    if (!_instalment?.dueDate) {
      errors.push(`An instalment due date is required.`);
    }
    // if (_instalment?.installmentType == null) {
    //   errors.push(`An instalment type is required.`);
    // }

    if (_instalment.total > _standardUnallocated) {
      errors.push(`The instalment total is greater than the unallocated amount.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    flyoutActions.closeFlyout();
    onInstalmentSaved?.(_instalment);
    _setInstalment(INSTALMENT_DEFAULT);
  };

  const handleClose = () => {
    flyoutActions.closeFlyout();
    _setInstalment(INSTALMENT_DEFAULT);
  };

  // const getItems = () => {
  //   return [
  //     { label: "Standard", name: "standard", value: 0 },
  //     _product?.pupilPremium && { label: "Pupil Premium", name: "pupilPremium", value: 1 },
  //     _product?.bursary && { label: "Bursary", name: "bursary", value: 2 },
  //   ].filter(Boolean)
  // }

  const getFlyoutDetails = (installmentType: number) => {
    switch (installmentType) {
      case (0):
        return {
          name: Constants.FLYOUTS.INSTALMENT,
          title: "Instalments"
        }
      case (1):
        return {
          name: Constants.FLYOUTS.PUPILPREMIUMINSTALMENT,
          title: "Pupil Premium Instalments"
        }
      case (2):
        return {
          name: Constants.FLYOUTS.BURSARYINSTALMENT,
          title: "Bursary Instalments"
        }
      default:
        return {
          name: Constants.FLYOUTS.INSTALMENT,
          title: "Instalments"
        }
    }
  }

  var flyoutDetails = getFlyoutDetails(installmentType);
  return (
    <Flyout
      title={flyoutDetails.title}
      name={flyoutDetails.name}
      onClose={handleClose}
      wide
    >
      <Flyout.Body>
        <ValidationMessage errors={validationErrors} />
        <HeadlineStatistic>
          <HeadlineStatistic.Item
            icon="shopping-bag"
            label="Unallocated"
            value={
              <CurrencyFormat
                prefix={"£"}
                decimalScale={2}
                thousandSeparator=","
                fixedDecimalScale={true}
                displayType={"text"}
                value={_standardUnallocated}
              />
            }
          />
        </HeadlineStatistic>
        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item
                name="Instalment Deadline"
                description="The date the instalment needs to be paid by."
              >
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  closeOnSelect
                  selectedDate={_instalment?.dueDate}
                  onChange={(value) =>
                    _setInstalment({ ..._instalment, dueDate: value })
                  }
                />
              </StructuredList.Item>
              <StructuredList.Item
                name="Instalment Amount"
                description="The total the customer should pay by the instalment deadline."
              >
                <TextInput
                  type={TextInputType.Currency}
                  value={_instalment?.total}
                  onChange={(value) =>
                    _setInstalment({ ..._instalment, total: value })
                  }
                  min={0}
                  fluid
                />
              </StructuredList.Item>
              {/* <StructuredList.Item
                name="Instalment Type"
                description="The total the customer should pay by the instalment deadline."
              >
                <RadioList
                  value={_instalment?.installmentType}
                  onChange={(value) => 
                    _setInstalment({ ..._instalment, installmentType: value })
                  }
                  items={getItems()}
                >
                </RadioList>
              </StructuredList.Item> */}
            </StructuredList>
          </Card.Body>
        </Card>
      </Flyout.Body>
      <Flyout.Footer>
        <ActionBar low>
          <Button
            text="Save Instalment"
            onClick={handleSaveInstalment}
            color={Swatches.Success}
            fluid
          />
        </ActionBar>
      </Flyout.Footer>
    </Flyout>
  );
};

export default InstalmentFlyout;
