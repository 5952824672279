import { ProvisionListView } from "areas/send/types/passportResponse.types";
import providerActions from "../../actions/providers/providerActions";
import provisionsActions from "../../actions/provisions/provisionsActions";
import { IBeehiveAction } from "types/common/action.types";
import { ProvisionQueryFilter } from "areas/send/types/passportRequest.types";
import { ProvisionStatus, ProvisionType } from "areas/send/types/passportShared.types";

interface IProvisionsState {
  provisions: ProvisionListView[];
  loading: boolean;
  error: string | null;
  filter: ProvisionQueryFilter;
}

const INITIAL_STATE: IProvisionsState = {
  provisions: [],
  loading: false,
  error: null,
  filter: {
    schoolId: -1,
    areaOfNeed: null,
    providerId: -1,
    provisionType: ProvisionType.Standard,
    provisionStatus: ProvisionStatus.Live
  },
};


const provisionsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IProvisionsState => {
  const {
    PROVISIONS_GET,
    PROVISIONS_CREATESTANDARD,
    PROVISIONS_SAVESTANDARD,
    PROVISIONS_DELETE,
    PROVISIONS_FILTER,
  } = provisionsActions.types;

  const { PROVIDER_SAVE } = providerActions.types;

  switch (action.type) {
    case PROVISIONS_GET.START:
      return { ...state, loading: true };
    case PROVISIONS_GET.SUCCESS:
      return { ...state, loading: false, provisions: action.payload.items };
    case PROVISIONS_GET.FAILED:
      return { ...state, loading: false, error: action.payload };

    case PROVISIONS_FILTER:
      return { ...state, filter: action.payload };

    case PROVISIONS_CREATESTANDARD.SUCCESS:
      return {
        ...state,
        provisions:
          state.filter == null ||
          ((state.filter.schoolId === -1 || state.filter.schoolId === action.payload.school?.id) &&
            (state.filter.areaOfNeed === null || state.filter.areaOfNeed === action.payload.areaOfNeed))
            ? [action.payload, ...state.provisions]
            : state.provisions,
      };

    case PROVISIONS_SAVESTANDARD.SUCCESS:
      return {
        ...state,
        provisions: state.provisions?.map(a =>
          a.id === action.payload.id ? action.payload : a
        ),
      };

    case PROVISIONS_DELETE.SUCCESS:
      return {
        ...state,
        provisions: state.provisions?.filter(a => a.id !== action.payload),
      };

    case PROVIDER_SAVE.SUCCESS:
      return {
        ...state,
        provisions: state.provisions?.map(p =>
          p.provider?.id === action.payload.id
            ? { ...p, provider: action.payload }
            : p
        ),
      };

    default:
      return state;
  }
};

export default provisionsReducer;
