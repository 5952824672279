import customGroupActions from "../../actions/group/customGroupActions";
import studentActions from "../../actions/student/studentActions";

const INITIAL_STATE = {
  studentClasses: null,
  loading: false,
  refresh: false,
  error: null,
};

const { GETSTUDENTCLASSES } = studentActions.types;
const { REMOVESTUDENTFROMCUSTOMGROUP, UPDATECUSTOMGROUPSTUDENTS } =
  customGroupActions.types;

const studentClassesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GETSTUDENTCLASSES.START:
      return { ...state, loading: true, refresh: false, error: null };

    case GETSTUDENTCLASSES.SUCCESS:
      return {
        ...state,
        studentClasses: action.payload,
        loading: false,
      };

    case GETSTUDENTCLASSES.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATECUSTOMGROUPSTUDENTS.SUCCESS:
    case REMOVESTUDENTFROMCUSTOMGROUP.SUCCESS:
      return { ...state, refresh: true };

    default:
      return state;
  }
};

export default studentClassesReducer;
