import React, { useEffect, useState } from "react";
import DetentionRegisterTable from "./detentionRegisterTable";
import moment from "moment";
import DetentionRegisterTableHeaders from "./detentionRegisterTableHeaders";
import {
  Loader,
  Size,
  Table,
  EmptyMessage,
  Button,
  Swatches,
  Message,
  Spacing,
  ToastService,
} from "ui-kit";
import { Constants } from "configuration";
import detentionRegisterActions from "areas/behaviour/actions/detentions/detentionRegisterActions";
import { DETENTION_GROUPBY_KEYS } from "areas/behaviour/constants/tableConstants";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import flyoutActions from "actions/ui/flyouts";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import RescheduleDetentionModal from "../reschedule/rescheduleDetentionModal";
import { useAppSelector } from "reducers/hooks";
import { DetentionStudentView, SchoolStudentDetentionListView } from "areas/behaviour/types/detentionRegisterResponse.types";
import BulkRescheduleModal from "../bulkRescheduleModal";
import { DETENTION_LOCAL_MARKING_KEYS, DETENTION_STATUS_KEYS } from "areas/behaviour/constants/detentions";


const DetentionRegisterTableWrapper: React.FC = () => {

  const { schoolId } = useAppSelector(state => state.schools);
  const {
    detentions,
    detentionDate,
    detentionFilters,
    groupBy,
    searchTerm,
    loading,
    loadingExportReport,
    refresh,
    error,
  } = useAppSelector(state => state.detentionRegister);
  const { attendedDetentionError, missedDetentionError } = useAppSelector(
    state => state.detention
  );
  const { schoolInformation } = useAppSelector(state => state.school);

  const [openRescheduleModal, setOpenRescheduleModal] = useState<boolean>(false);
  const [detention, setDetention] = useState<DetentionStudentView>(null);

  const [selectedDetentions, setSelectedDetentions] = useState<DetentionStudentView[]>([]);
  const [localMarkedMissed, setLocalMarkedMissed] = useState<DetentionStudentView[]>([]);
  const [localMarkedAttended, setLocalMarkedAttended] = useState<DetentionStudentView[]>([]);
  const [bulkrescheduleModalOpen, setBulkRescheduleModalOpen] = useState<boolean>(false);

  const handleGetDetentions = () => {
    const viewUnmarkedDetentions = detentionFilters.viewUnmarkedDetentions;
    detentionRegisterActions.getDetentions(
      schoolId,
      {
        year: +moment(viewUnmarkedDetentions ? null : detentionDate).format("YYYY"),
        month: +moment(viewUnmarkedDetentions ? null : detentionDate).format("M"),
        day: +moment(viewUnmarkedDetentions ? null : detentionDate).format("D"),
        statusIds: detentionFilters.detentionStatusFilters,
        yearGroupId: detentionFilters.yearGroupFilter,
        groupByDetentionType: groupBy.includes(DETENTION_GROUPBY_KEYS.DETENTION_TYPE),
        groupByRoom: groupBy.includes(DETENTION_GROUPBY_KEYS.ROOM),
        groupByStartDate: groupBy.includes(DETENTION_GROUPBY_KEYS.START_TIME),
        groupByEndDate: groupBy.includes(DETENTION_GROUPBY_KEYS.END_TIME),
        unMarkedOldDetentions: viewUnmarkedDetentions,
        behaviourCodeId: detentionFilters.behaviourCodeFilter,
        schoolBehaviourCategoryId: detentionFilters.behaviourCategoryFilter,
        searchTerm: searchTerm
      }
    );
  };

  useEffect(() => {
    if (schoolId && refresh) {
      handleGetDetentions();
    }
  }, [refresh]);

  useEffect(() => {
    setSelectedDetentions([])
    setLocalMarkedMissed([])
    setLocalMarkedAttended([])
  }, [schoolId,detentions]);

  const markPresent = (studentId: string, behaviourRewardId: number, detentionId: number) => {
    detentionActions.attendedDetention(
      studentId,
      behaviourRewardId,
      detentionId,
      {
        studentId: studentId,
        behaviourId: behaviourRewardId,
        detentionId: detentionId,
        updateNote: null,
        cancelNote: null
      }
    );
  };

  const markNotPresent = (studentId: string, behaviourRewardId: number, detentionId: number) => {
    detentionActions.missedDetention(
      studentId,
      behaviourRewardId,
      detentionId,
      {
        studentId: studentId,
        behaviourId: behaviourRewardId,
        detentionId: detentionId,
        updateNote: null,
        cancelNote: null
      }
    );
  };

  const onClickDetention = (detention: DetentionStudentView) => {
    flyoutActions.openFlyout(Constants.FLYOUTS.DETENTIONDETAILS);
    detentionActions.selectDetention(detention);
  };

  const handleRescheduleDetention = (detention: DetentionStudentView) => {
    setDetention(detention);
    setOpenRescheduleModal(true);
  };

  const handleExportDetentions = () => {
    const viewUnmarkedDetentions = detentionFilters.viewUnmarkedDetentions;
    detentionRegisterActions.exportDetentions(
      schoolId,
      {
        year: +moment(viewUnmarkedDetentions ? null : detentionDate).format("YYYY"),
        month: +moment(viewUnmarkedDetentions ? null : detentionDate).format("M"),
        day: +moment(viewUnmarkedDetentions ? null : detentionDate).format("D"),
        statusIds: detentionFilters.detentionStatusFilters,
        yearGroupId: detentionFilters.yearGroupFilter,
        groupByDetentionType: groupBy.includes(DETENTION_GROUPBY_KEYS.DETENTION_TYPE),
        groupByRoom: groupBy.includes(DETENTION_GROUPBY_KEYS.ROOM),
        groupByStartDate: groupBy.includes(DETENTION_GROUPBY_KEYS.START_TIME),
        groupByEndDate: groupBy.includes(DETENTION_GROUPBY_KEYS.END_TIME),
        unMarkedOldDetentions: viewUnmarkedDetentions,
        behaviourCodeId: detentionFilters.behaviourCodeFilter,
        schoolBehaviourCategoryId: detentionFilters.behaviourCategoryFilter,
        searchTerm: searchTerm
      }
    );
  };

  const handleBulkUpdate = (refresh: boolean) => {
    refresh && handleGetDetentions();
  }

  const CloseBulkModal = (refresh: boolean) => {
    setBulkRescheduleModalOpen(false)
    handleBulkUpdate(refresh);
  }

  const handleBulkReschedule = (refresh: boolean) => {
    setSelectedDetentions([]);
    setLocalMarkedMissed([])
    setLocalMarkedAttended([])
    setBulkRescheduleModalOpen(false);
    ToastService.pop("All detentions successfully rescheduled", null, "check-circle");
    handleBulkUpdate(refresh);
  }

  const handleBulkSelection = (checked: boolean, detention: DetentionStudentView) => {
    if (checked) {
      if (!selectedDetentions.some(x => x.detentionId === detention.detentionId)) {
        setSelectedDetentions([...selectedDetentions, detention]);
      }
    } else {
      var temp = selectedDetentions.filter(x => x.detentionId !== detention.detentionId);
      setSelectedDetentions(temp);
    }
  };

  const handleLocalMarking = ( detention: DetentionStudentView, markingType: Number) => {
  if (markingType === DETENTION_LOCAL_MARKING_KEYS.MISSED) {
      if (!localMarkedMissed.some(x => x.detentionId === detention.detentionId)) {
        setLocalMarkedMissed([...localMarkedMissed, detention]);
      }
    } else {
      var missedTemp = localMarkedMissed.filter(x => x.detentionId !== detention.detentionId);
      setLocalMarkedMissed(missedTemp);
    }

    if (markingType  === DETENTION_LOCAL_MARKING_KEYS.ATTENDED) {
      if (!localMarkedAttended.some(x => x.detentionId === detention.detentionId)) {
        setLocalMarkedAttended([...localMarkedAttended, detention]);
      }
    } else {
      var attendedTemp = localMarkedAttended.filter(x => x.detentionId !== detention.detentionId);
      setLocalMarkedAttended(attendedTemp);
    }
  }

  useEffect(() => {
    var temp = selectedDetentions.filter(selectedDetention => {
      return !localMarkedAttended.some(localDetention => localDetention.detentionId === selectedDetention.detentionId);
    });
    setSelectedDetentions(temp);
  }, [localMarkedAttended]);
  
  const selectAll = () => {
    if (selectedDetentions.length < 1) {
        const filteredStudents = detentions[0].students.filter((student) => 
            (student.statusId === DETENTION_STATUS_KEYS.ISSUED || student.statusId === DETENTION_STATUS_KEYS.MISSED) && 
            !localMarkedAttended.some((x) => x.detentionId === student.detentionId) &&
            !localMarkedMissed.some((x) => x.detentionId === student.detentionId)
        );
        setSelectedDetentions([...filteredStudents, ...localMarkedMissed]);
    } else {
        setSelectedDetentions([]);
      }
}

  const getGroupBys = (session: SchoolStudentDetentionListView) => {
    const groupsBys = [];

    if (session.name) {
      groupsBys.push(DETENTION_GROUPBY_KEYS.DETENTION_TYPE);
    }
    if (session.startDate) {
      groupsBys.push(DETENTION_GROUPBY_KEYS.START_TIME);
    }
    if (session.endDate) {
      groupsBys.push(DETENTION_GROUPBY_KEYS.END_TIME);
    }
    if (session.location) {
      groupsBys.push(DETENTION_GROUPBY_KEYS.ROOM);
    }

    return groupsBys;
  };

  if (loading) {
    return <Loader size={Size.Medium} cover />;
  }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  if (detentions === null) {
    return (
      <EmptyMessage
        icon="list"
        title="Click Get Detentions to load detentions"
        cover
      >
        <Button
          text="Get detentions"
          onClick={handleGetDetentions}
          size={Size.Small}
          color={Swatches.Primary}
        />
      </EmptyMessage>
    );
  }

  return detentions.length > 0 ? (
    <>
      <ApiExceptionMessage error={attendedDetentionError} />
      <ApiExceptionMessage error={missedDetentionError} />

      <Table grow loading={loading}>
        <DetentionRegisterTableHeaders
          selectedGroupByOptions={getGroupBys(detentions?.[0])}
          selectAll={selectAll}
          selectedDetentions={selectedDetentions}
        />

        <>
        {detentions.map((session, index) => (
          <DetentionRegisterTable
            key={index}
            session={session}
            markPresent={markPresent}
            markNotPresent={markNotPresent}
            onClickDetention={onClickDetention}
            selectedGroupByOptions={getGroupBys(session)}
            detentionRegisterStarts={
              schoolInformation && schoolInformation.detentionRegisterStarts
            }
            handleRescheduleDetention={handleRescheduleDetention}
            handleBulkSelection={handleBulkSelection}
            handleLocalMarking={handleLocalMarking}
            selectedDetentions={selectedDetentions}
          />
        ))}
        </>

        <Table.Footer>
          <Table.Row>
            <Table.Cell
              colspan={6 + (4 - getGroupBys(detentions?.[0])?.length)}
              right
            >
              <Button
                color={Swatches.Primary}
                text="Bulk Reschedule"
                icon="calendar"
                onClick={() => setBulkRescheduleModalOpen(true)}
                working={false}
                size={Size.Small}
                style={{ marginRight: Spacing.Default }}
                disabled={selectedDetentions.length < 1}
               />
               
              <Button
                color={Swatches.Success}
                text="Export Detention Register"
                icon="file-download"
                onClick={handleExportDetentions}
                working={loadingExportReport}
                size={Size.Small}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>

      <Message
        text="Email and SMS notifications will be sent to the parents if attendance is recorded as Not Present."
        color={Swatches.Primary}
      />

      <RescheduleDetentionModal
        open={openRescheduleModal}
        setOpen={setOpenRescheduleModal}
        detention={detention}
      />

      <BulkRescheduleModal
      schoolId={schoolId}
      detentions={selectedDetentions}
      open={bulkrescheduleModalOpen}
      onCancel={CloseBulkModal}
      onSaved={handleBulkReschedule}
      />

    </>
  ) : (
    <EmptyMessage icon="list" title="No detentions on selected date" cover />
  );
};
export default DetentionRegisterTableWrapper;
