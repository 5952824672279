import paymentActions from "areas/payments/actions/paymentsActions";
import { UserProductDetailLegacyView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface IUserProductsState {
  product: UserProductDetailLegacyView | null,
  error: string | null;
  loading: boolean;
}

const INITIAL_STATE: IUserProductsState = {
  product: null,
  error: null,
  loading: false
};

const userProductReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUserProductsState => {

  const { 
    PAYMENTS_GETPRODUCTFORUSER 
  } = paymentActions.paymentsTypes;

  switch (action.type) {

    case PAYMENTS_GETPRODUCTFORUSER.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETPRODUCTFORUSER.SUCCESS:
      return { 
        ...state, 
        product: action.payload, 
        loading: false 
      };

    case PAYMENTS_GETPRODUCTFORUSER.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default userProductReducer;
