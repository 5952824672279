import React from "react";
import {
  StructuredList,
  Card,
  ActionBar,
  Button,
  Swatches,
  Size,
  RadioList,
} from "ui-kit";
import DateRange from "../../dateRange";
import MultiSchoolUserView from "sharedComponents/common/users/multiSchoolUserView";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import AcademicYearFilter from "sharedComponents/common/academicYearFilter";
import { CategoryType } from "areas/analytics/types/analytics.shared";
import moment from "moment";
import { BehaviourRewardReportFilterExt } from "./behaviourDetailedReportWrapper";


interface IBehaviourDetailedReportFormProps {
  schoolId: number;
  onSelectSchool: (schoolId: number) => void;
  reportCriteria: BehaviourRewardReportFilterExt;
  onChangeReportCriteria: (criteria: BehaviourRewardReportFilterExt) => void;
  handleGenerateReport: (exportReport: boolean) => void;
  loadingExportReport: boolean;
}

const BehaviourDetailedReportForm: React.FC<IBehaviourDetailedReportFormProps> = ({
  schoolId,
  onSelectSchool,
  reportCriteria,
  onChangeReportCriteria,
  handleGenerateReport,
  loadingExportReport,
}) => {
  return (
    <>
      <MultiSchoolUserView>
        <Card title="School">
          <Card.Body>
            <SchoolDropdown
              onChange={onSelectSchool}
              initialValue={schoolId}
              fluid
            />
          </Card.Body>
        </Card>
      </MultiSchoolUserView>

      <Card title="Date Range">
        <Card.Body>
          <StructuredList.Item name="Academic Year" required>
            <AcademicYearFilter
              value={reportCriteria.academicYearId}
              onChange={value =>
                onChangeReportCriteria({
                  ...reportCriteria,
                  academicYearId: value,
                })
              }
            />
          </StructuredList.Item>

          <DateRange
            startDate={reportCriteria.startDate}
            handleStartDateChange={value =>
              onChangeReportCriteria({
                ...reportCriteria,
                startDate: value,
              })
            }
            endDate={reportCriteria.endDate}
            handleEndDateChange={value =>
              onChangeReportCriteria({ 
                ...reportCriteria, 
                endDate: value
              })
            }
          />
        </Card.Body>
      </Card>

      <Card title="Filters">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item
              name="Behaviours to Include in Report"
              description="Choose the points that you want to include in your report"
              required
              helpPopup
            >
              <RadioList
                value={reportCriteria.categoryTypeId}
                onChange={value =>
                  onChangeReportCriteria({
                    ...reportCriteria,
                    categoryTypeId: value,
                  })
                }
              >
                <RadioList.Item
                  label="Behaviours"
                  value={CategoryType.Behaviour}
                />
                <RadioList.Item
                  label="Rewards"
                  value={CategoryType.Reward}
                />
              </RadioList>
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      <ActionBar>
        <Button
          text="Generate Report"
          color={Swatches.Success}
          size={Size.Small}
          onClick={() => handleGenerateReport(false)}
        />
        <Button
          color={Swatches.Success}
          text="Export Report as CSV"
          icon="file-download"
          size={Size.Small}
          onClick={() => handleGenerateReport(true)}
          working={loadingExportReport}
        />
      </ActionBar>
    </>
  );
};

export default BehaviourDetailedReportForm;
