import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { neutral } from "ui-kit/common/colors";
import {
  Card,
  Loader,
  Size,
  fontStyle,
  displayFont,
  typescale,
  Spacing,
} from "ui-kit";
import ClassLayoutGrid from "./classLayoutGrid";
import classRoomActions from "areas/planner/actions/classViewer/classRoomActions";
import { LAYOUT_DIRECTION } from "areas/planner/constants/classViewer/classViewerLayout";
import classViewerActions from "areas/planner/actions/classViewer/classViewerActions";

const Wrapper = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  margin-right: 232px;
  position: relative;
  transition: all ease 300ms;

  .card {
    transition: all ease 300ms;
  }

  ${({ noUnseatedStudents }) =>
    noUnseatedStudents &&
    `
  margin-right: 0;
  `}

  .layout-grid {
    align-self: center;
  }

  .class-direction-label {
    align-self: stretch;
    ${fontStyle(displayFont.medium, typescale.paragraph, neutral[600])}
    text-align: center;
    line-height: 1rem;

    &.front {
      margin-top: ${Spacing.Large}px;
    }
    &.back {
      margin-bottom: ${Spacing.Large}px;
    }
  }
`;

const ClassLayoutGridWrapper = ({
  classLayout,
  onDragStart,
  onDropGrid,
  removeStudent,
  selectedStudents,
  handleSelectStudent,
  schoolId,
  loading,
}) => {
  const { roomLayout, actions } = useSelector(
    state => state.classViewerRoomLayout
  );
  const { layoutDirection } = useSelector(state => state.classLayout);

  const [roomDimensions, setRoomDimensions] = useState({
    length: null,
    width: null,
  });
  const [numberOfRows, setNumberOfRows] = useState([]);
  const [numberOfCells, setNumberOfCells] = useState([]);
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const roomLayoutId = classLayout.roomLayoutId;
    if (roomLayoutId && roomLayoutId !== roomLayout?.id) {
      classRoomActions.getRoomLayout(
        schoolId,
        classLayout.roomId,
        roomLayoutId
      );
    }
  }, [classLayout]);

  useEffect(() => {
    if (roomLayout) {
      setRoomDimensions({
        ...roomDimensions,
        length: roomLayout.length,
        width: roomLayout.width,
      });
    }
  }, [roomLayout]);

  useEffect(() => {
    if (roomDimensions.length) {
      const rows = [];
      if (layoutDirection === LAYOUT_DIRECTION.BACKTOFRONT) {
        for (let i = roomDimensions.length; i > 0; i--) {
          rows.push(i);
        }
      } else {
        for (let i = 1; i <= roomDimensions.length; i++) {
          rows.push(i);
        }
      }
      setNumberOfRows(rows);
    }

    if (roomDimensions.width) {
      const cells = [];
      if (layoutDirection === LAYOUT_DIRECTION.BACKTOFRONT) {
        for (let i = 1; i <= roomDimensions.width; i++) {
          cells.push(i);
        }
      } else {
        for (let i = roomDimensions.width; i > 0; i--) {
          cells.push(i);
        }
      }
      setNumberOfCells(cells);
    }
  }, [layoutDirection, roomDimensions]);

  const onClickflipLayoutDirection = () => {
    layoutDirection === LAYOUT_DIRECTION.BACKTOFRONT
      ? classViewerActions.changeLayoutDirection(LAYOUT_DIRECTION.FRONTTOBACK)
      : classViewerActions.changeLayoutDirection(LAYOUT_DIRECTION.BACKTOFRONT);
  };

  if (loading || actions.getRoomLayout.isLoading) {
    return <Loader size={Size.Medium} />;
  }

  return (
    <Wrapper noUnseatedStudents={classLayout?.unseatedStudents.length === 0}>
      <Card>
        <Card.Body>
          <ClassLayoutGrid
            numberOfRows={numberOfRows}
            numberOfCells={numberOfCells}
            scale={scale}
            setScale={setScale}
            handleFlipLayoutDirection={onClickflipLayoutDirection}
            onDragStart={onDragStart}
            onDropGrid={onDropGrid}
            studentSlots={classLayout.studentSlots}
            removeStudent={removeStudent}
            selectedStudents={selectedStudents}
            handleSelectStudent={handleSelectStudent}
          />
        </Card.Body>
      </Card>
    </Wrapper>
  );
};

ClassLayoutGridWrapper.propTypes = {
  onDragStart: PropTypes.func,
  onDropGrid: PropTypes.func,
  studentSlots: PropTypes.array,
  removeStudent: PropTypes.func,
  showGenerateLayout: PropTypes.bool,
  handleCLoseGenerateLayout: PropTypes.func,
  generateLayout: PropTypes.func,
};

export default ClassLayoutGridWrapper;
