import paymentActions from "areas/payments/actions/paymentsActions";
import { BasketDetailView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface IUserErrorsState {
  load: string | null;
  item: string | null;
  placeOrder: string | null;
}

export interface IUserBasketState {
  basket: BasketDetailView | null,
  working: boolean;
  errors: IUserErrorsState,
  refresh: boolean;
}


const INITIAL_ERRORS: IUserErrorsState = {
  load: null,
  item: null,
  placeOrder: null,
};

const INITIAL_STATE: IUserBasketState = {
  basket: null,
  working: false,
  errors: INITIAL_ERRORS,
  refresh: false,
};


const userBasketReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUserBasketState => {

  const {
    PAYMENTS_BASKET_GET,
    PAYMENTS_BASKET_REMOVEITEM,
    PAYMENTS_BASKET_ADDPRODUCT,
    PAYMENTS_BASKET_ADDTOPUP,
    PAYMENTS_BASKET_ADDPRINTCREDIT,
    PAYMENTS_BASKET_ADDLUNCHCREDIT,
    PAYMENTS_PLACEORDER,
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_BASKET_GET.START:
      return {
        ...state,
        working: true,
        refresh: false,
        errors: INITIAL_ERRORS,
      };

    case PAYMENTS_BASKET_GET.SUCCESS:
      return { 
        ...state, 
        basket: action.payload, 
        working: false 
      };

    case PAYMENTS_BASKET_GET.FAILED:
      return {
        ...state,
        working: false,
        errors: { 
          ...state.errors, 
          load: action.payload 
        },
      };

    case PAYMENTS_BASKET_REMOVEITEM.START:
      return { 
        ...state, 
        working: true, 
        errors: INITIAL_ERRORS 
      };

    case PAYMENTS_BASKET_REMOVEITEM.SUCCESS:
      return { 
        ...state, 
        working: false, 
        refresh: true 
      };

    case PAYMENTS_BASKET_REMOVEITEM.FAILED:
      return {
        ...state,
        working: false,
        errors: {
          ...state.errors,
          item: "There was a problem removing the item",
        },
      };

    case PAYMENTS_BASKET_ADDPRODUCT.START:
      return { 
        ...state, 
        working: true, 
        errors: INITIAL_ERRORS 
      };

    case PAYMENTS_BASKET_ADDPRODUCT.SUCCESS:
      return { 
        ...state, 
        working: false, 
        refresh: true 
      };

    case PAYMENTS_BASKET_ADDPRODUCT.FAILED:
      return {
        ...state,
        working: false,
        errors: {
          ...state.errors,
          item: "There was a problem adding the product",
        },
      };

    case PAYMENTS_BASKET_ADDPRINTCREDIT.START:
      return { 
        ...state, 
        working: true, 
        errors: INITIAL_ERRORS 
      };

    case PAYMENTS_BASKET_ADDPRINTCREDIT.SUCCESS:
      return { 
        ...state, 
        working: false, 
        refresh: true 
      };

    case PAYMENTS_BASKET_ADDPRINTCREDIT.FAILED:
      return {
        ...state,
        working: false,
        errors: {
          ...state.errors,
          item: "There was a problem adding the print credit",
        },
      };

    case PAYMENTS_BASKET_ADDTOPUP.START:
      return { 
        ...state, 
        working: true, 
        errors: INITIAL_ERRORS 
      };

    case PAYMENTS_BASKET_ADDTOPUP.SUCCESS:
      return { 
        ...state, 
        working: false, 
        refresh: true
      };

    case PAYMENTS_BASKET_ADDTOPUP.FAILED:
      return {
        ...state,
        working: false,
        errors: {
          ...state.errors,
          item: "There was a problem adding the topup",
        },
      };

    case PAYMENTS_BASKET_ADDLUNCHCREDIT.START:
      return { 
        ...state, 
        working: true, 
        errors: INITIAL_ERRORS 
      };

    case PAYMENTS_BASKET_ADDLUNCHCREDIT.SUCCESS:
      return { 
        ...state, 
        working: false, 
        refresh: true 
      };

    case PAYMENTS_BASKET_ADDLUNCHCREDIT.FAILED:
      return {
        ...state,
        working: false,
        errors: {
          ...state.errors,
          item: "There was a problem adding the lunch credit",
        },
      };

    case PAYMENTS_PLACEORDER.START:
      return { 
        ...state, 
        working: true, 
        errors: INITIAL_ERRORS 
      };

    case PAYMENTS_PLACEORDER.SUCCESS:
      return { 
        ...state, 
        working: false, 
        refresh: true 
      };

    case PAYMENTS_PLACEORDER.FAILED:
      return {
        ...state,
        working: false,
        errors: {
          ...state.errors,
          placeOrder: "There was a problem placing the order",
        },
      };

    default:
      return state;
  }
};

export default userBasketReducer;
