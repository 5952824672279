import defaults from "configuration/defaults";
import { arrays } from "utils";
import calendarActions from "../actions/calendarActions";
import { PageInfo } from "types/common/paging.types";
import { IBeehiveAction } from "types/common/action.types";
import { CalendarEventAppListView } from "../types/calendarResponses.types";


interface ISignedUpCalendarEventsState {
  events: CalendarEventAppListView[];
  error: string | null;
  loading: boolean;
  paging: PageInfo;
  signingUp: boolean;
  signupError: string | null;
};

const INITIAL_STATE: ISignedUpCalendarEventsState = {
  events: [],
  error: null,
  loading: false,
  paging: defaults.DEFAULT_PAGING,
  signingUp: false,
  signupError: null
};

const signedUpCalendarEventsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISignedUpCalendarEventsState => {
  const {
    CALENDAR_GETSIGNEDUPCALENDAREVENTS,
    CALENDAR_EVENTSIGNUP,
    CALENDAR_EVENTUNSIGNUP,
    CALENDAR_ADDEVENTTOSIGNEDUPEVENTS,
    CALENDAR_REMOVEEVENTFROMSIGNEDUPEVENTS,
    CALENDAR_SIGNEDUPTOALLDATES,
    CALENDAR_UNSIGNEDUPTOALLDATES,
  } = calendarActions.types;

  switch (action.type) {
    case CALENDAR_GETSIGNEDUPCALENDAREVENTS.START:
      return {
        ...state,
        loading: arrays.isEmpty(state.events),
        error: null,
      };

    case CALENDAR_GETSIGNEDUPCALENDAREVENTS.SUCCESS:
      return {
        ...state,
        events:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.events, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };

    case CALENDAR_GETSIGNEDUPCALENDAREVENTS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CALENDAR_EVENTSIGNUP.START:
      return { ...state, signingUp: true, signupError: null };

    case CALENDAR_EVENTSIGNUP.SUCCESS:
      return {
        ...state,
        events: state.events.map(x => ({
          ...x,
          signedUp: true,
          dates: x.dates.map(d =>
            action.payload.some((a: number) => a === d.id)
              ? { ...d, signedUp: true, signups: d.signups + 1 }
              : d
          ),
        })),
      };

    case CALENDAR_EVENTSIGNUP.FAILED:
      return {
        ...state,
        signingUp: false,
        signupError:
          "An error occured while unregistering from the calendar event",
      };

    case CALENDAR_EVENTUNSIGNUP.START:
      return { ...state, signingUp: true, signupError: null };

    case CALENDAR_EVENTUNSIGNUP.SUCCESS:
      return {
        ...state,
        events: state.events.map(x => ({
          ...x,
          dates: x.dates.map(d =>
            action.payload.some((a: number) => a === d.id)
              ? { ...d, signedUp: true, signups: d.signups + 1 }
              : d
          ),
        })),
      };

    case CALENDAR_EVENTUNSIGNUP.FAILED:
      return {
        ...state,
        signingUp: false,
        signupError:
          "An error occured while unregistering from the calendar event",
      };

    case CALENDAR_ADDEVENTTOSIGNEDUPEVENTS:
      return {
        ...state,
        events: state.events.some(x => x.id === action.payload.id)
          ? state.events
          : [action.payload, ...state.events],
      };

    case CALENDAR_REMOVEEVENTFROMSIGNEDUPEVENTS:
      return {
        ...state,
        events: state.events.filter(x => x.id !== action.payload.id),
      };

    case CALENDAR_SIGNEDUPTOALLDATES:
      var filtered = state.events.filter(x =>
        x.id === action.payload.id ? { ...x, signedUp: true } : x
      );
      return {
        ...state,
        events: filtered,
      };

    case CALENDAR_UNSIGNEDUPTOALLDATES:
      var filtered = state.events.filter(x =>
        x.id == action.payload.id ? { ...x, signedUp: false } : x
      );
      return {
        ...state,
        events: filtered,
      };

    default:
      return state;
  }
};

export default signedUpCalendarEventsReducer;
