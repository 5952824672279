import { IBeehiveAction } from "types/common/action.types";
import reviewActions from "../../actions/reviewActions";
import staffMemberActions from "../../actions/staffMemberActions";
import { PmStaffDetailView } from "areas/humanResources/types/managedStaffResponse.types";

interface IManagedStaffMemberState {
  staffMember: PmStaffDetailView | null;
  loading: boolean;
  error: string | null;
  selectedTabIndex: number;
  refresh: boolean;
}

const INITIAL_STATE: IManagedStaffMemberState = {
  staffMember: null,
  loading: false,
  error: null,
  selectedTabIndex: 0,
  refresh: false,
};

const managedStaffMemberReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IManagedStaffMemberState => {

  const { 
    GETMANAGEDSTAFFMEMBER, 
    SELECTSTAFFMEMBERTAB 
  } = staffMemberActions.types;
  const { 
    STARTREVIEW, 
    SAVEREVIEW 
  } = reviewActions.types;

  switch (action.type) {
    case SELECTSTAFFMEMBERTAB:
      return { ...state, selectedTabIndex: action.payload };
    case GETMANAGEDSTAFFMEMBER.START:
      return { ...INITIAL_STATE, loading: true };
    case GETMANAGEDSTAFFMEMBER.SUCCESS:
      return { ...INITIAL_STATE, staffMember: action.payload };
    case GETMANAGEDSTAFFMEMBER.FAILED:
      return { ...INITIAL_STATE, error: action.payload };
    case SAVEREVIEW.SUCCESS:
    case STARTREVIEW.SUCCESS:
      return { ...state, refresh: true };
    default:
      return state;
  }
};

export default managedStaffMemberReducer;
