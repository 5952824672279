import passportActions from "areas/send/actions/passports/passportActions";
import sendParentsActions from "areas/send/actions/parents/senParentsActions";
import { PassportListView } from "areas/send/types/passportResponse.types";
import { IBeehiveAction } from "types/common/action.types";

interface IPassportsState {
  passports: PassportListView[];
  activeStudent: string | null;
  loading: boolean;
  saving: boolean;
  error: string | null;
}

const INITIAL_STATE: IPassportsState = {
  passports: [],
  activeStudent: null,
  loading: false,
  saving: false,
  error: null,
};


const passportsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IPassportsState => {

  const { PASSPORTS_GET, PASSPORT_CREATE, PASSPORT_SAVE } = passportActions.types;
  const { SET_ACTIVE_CHILD } = sendParentsActions.types;

  switch (action.type) {
    case PASSPORTS_GET.START:
      return { ...state, loading: true };
    case PASSPORTS_GET.SUCCESS:
      return { ...state, loading: false, passports: action.payload };
    case PASSPORTS_GET.FAILED:
      return { ...state, loading: false, error: action.payload };

    case PASSPORT_CREATE.SUCCESS:
      return {
        ...state,
        passports: [action.payload, ...state.passports],
      };

    case PASSPORT_SAVE.SUCCESS:
      return {
        ...state,
        passports: state.passports?.map(x =>
          x.id === action.payload.id ? action.payload : x
        ),
      };

    case SET_ACTIVE_CHILD:
      return {
        ...state,
        activeStudent: action.payload
      }

    default:
      return state;
  }
};

export default passportsReducer;
