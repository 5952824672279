import paymentActions from "areas/payments/actions/paymentsActions";
import { OrderListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface IUserOrdersState {
  orders: OrderListView[],
  error: string | null;
  loading: boolean;
}

const INITIAL_STATE: IUserOrdersState = {
  orders: [],
  error: null,
  loading: false
};

const userOrdersReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUserOrdersState => {
  
  const { 
    PAYMENTS_GETORDERSFORUSER 
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETORDERSFORUSER.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETORDERSFORUSER.SUCCESS:
      return { 
        ...state, 
        orders: action.payload, 
        loading: false 
      };

    case PAYMENTS_GETORDERSFORUSER.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default userOrdersReducer;
