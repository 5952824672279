import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import StudentAwardForm from "./studentAwardForm";
import { Loader, Size } from "ui-kit";
import studentAwardActions from "areas/planner/actions/student/studentAwardActions";
import { addGroupIdToPayload } from "areas/planner/utils/group";

const StudentAwardWrapper = ({
  studentId,
  groupId,
  groupType,
  tabIndex,
  currentTabIndex,
}) => {
  const { studentAwardCategories, loading } = useSelector(
    state => state.studentAward
  );

  useEffect(() => {
    if (
      (tabIndex === undefined || tabIndex === currentTabIndex) &&
      (!studentAwardCategories || studentAwardCategories.length === 0)
    ) {
      studentAwardActions.getStudentAwardCategories();
    }
  }, [currentTabIndex]);

  const awardCategories = studentAwardCategories.map(awardCategory => ({
    key: awardCategory.id,
    label: awardCategory.name,
    value: awardCategory.id,
  }));

  const handleAddAward = (awardCategoryId, awardContent, awardNotes) => {
    let newAward = {
      id: null,
      studentId: studentId,
      awardCategoryId: awardCategoryId,
      awardedFor: awardContent,
      awardNotes: awardNotes,
    };
    if (groupId) {
      newAward = addGroupIdToPayload(newAward, groupId, groupType);
    }
    studentAwardActions.addStudentAward(newAward);
  };

  return loading ? (
    <Loader size={Size.Medium} fluid />
  ) : (
    <StudentAwardForm
      awardCategories={awardCategories}
      handleAddAward={handleAddAward}
    />
  );
};

StudentAwardWrapper.propTypes = {
  studentId: PropTypes.string,
};

export default StudentAwardWrapper;
