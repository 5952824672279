/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  TitleSize,
  Title,
  Button,
  Message,
  Swatches,
  typescale,
  displayFont,
  neutral,
  honeygold,
  Icon,
  Spacing,
  BorderRadius,
  Speed,
  fontStyle,
  Text,
  Left,
  Right,
  ActionBar,
} from "../index";
import { useScroll } from "../../hooks/useScroll";

const Wrapper = styled.div<{fluid?: boolean}>`
  display: flex;
  flex-direction: column;

  ${({ fluid }) => fluid && `
    flex-grow: 1;
  `}
`;

const WizardHeader = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${Spacing.Large}px;
  background: ${neutral[100]};
`;

const WizardHeaderStep = styled.div<{active: boolean, completed: boolean, allowJump: boolean}>`
  ${fontStyle(displayFont.medium, typescale.sub, neutral[600])}
  display: flex;
  align-items: top;
  flex-grow: 1;
  flex-basis: 0;
  padding: ${Spacing.Large}px;
  border-right: ${Spacing.ExtraSmall}px solid ${neutral[200]};

  &:first-child {
    border-top-left-radius: ${BorderRadius.Default}px;
    border-bottom-left-radius: ${BorderRadius.Default}px;
  }
  &:last-child {
    border-top-right-radius: ${BorderRadius.Default}px;
    border-bottom-right-radius: ${BorderRadius.Default}px;
    margin-right: 0;
  }

  i {
    color: ${neutral[600]};
    margin-right: ${Spacing.Default}px;
  }

  ${({ active }) =>
    active &&
    `
  transition: all ease ${Speed.Default}ms;
  background: ${honeygold};
  color: ${neutral[100]};
  i {
    color: ${neutral[100]};
  }
`}
  ${({ completed }) =>
    completed &&
    `
    color: ${neutral[400]};
    i {
      color: ${neutral[400]};
    }
  `}

  ${({ allowJump }) =>
    allowJump &&
    `
    &:hover { cursor: pointer; color: ${neutral[100]}; background: ${neutral[600]}; i { color: ${neutral[100]};} }`}
`;

const WizardFooter = styled.div`
  display: flex;
  padding: 0 0 ${Spacing.Large}px 0;
`;

const WizardStepWrapper = styled.div<{active?: boolean}>`
  display: none;
  flex-grow: 1;
  overflow-y: visible;

  .wizard-step-description {
    display: block;
    margin-bottom: ${Spacing.Large}px;
  }
  ${({ active }) =>
    active &&
    `
    display: block;
  `}
`;

export interface IWizardProps {
  children: React.ReactElement<IWizardStepProps>[];
  onNext?(index: number): void;
  onBack?(index: number): void;
  onFinish?(): Promise<any>;
  onComplete?(): void;
  onCancel?(): void;
  onActiveIndexChanged?(index: number): void;
  allowJumpBack?: boolean;
  allowJumpForward?: boolean;
  nextButtonText?: string;
  finishButtonText?: string;
  backButtonText?: string;
  completionButtonText?: string;
  showBackButtonOnCompletion?: boolean;
  error?: string;
  working?: boolean;
  showHeaderSteps?: boolean;
  fluid?: boolean;
}

const Wizard: React.FC<IWizardProps> & {
  Step: typeof WizardStep;
} = ({
  children,
  onNext,
  onBack,
  onFinish,
  onComplete,
  onActiveIndexChanged,
  nextButtonText = "Next",
  backButtonText = "Back",
  finishButtonText = "Finish",
  completionButtonText = "Complete",
  showBackButtonOnCompletion,
  allowJumpBack = true,
  allowJumpForward = false,
  error,
  working,
  showHeaderSteps = true,
  onCancel,
  fluid
}) => {
  
  const scroll = useScroll(); 
  //cannot destructure scroll to top hook because mainroutes context only happens after login and wizard is used on public templates so have to null check
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isComplete, setComplete] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [_children, _setChildren] = useState<React.ReactElement<IWizardStepProps>[]>([]);
  const [_completionStep, _setCompletionStep] = useState<React.ReactElement<IWizardStepProps> | null>(null);

  const steps = _children.length;

  const canMoveBack = activeIndex > 0;
  const canMoveNext = activeIndex >= 0 && activeIndex < steps - 1;
  const canFinish = activeIndex === steps - 1;


  useEffect(() => {
    _setChildren(
      children.filter((x) => !x.props?.completion && !x.props?.hide?.())
    );

    _setCompletionStep(
      children.filter((x) => x.props?.completion && !x.props?.hide?.())?.[0]
    );
  }, [children]);

  useEffect(() => {
    scroll?.scrollToTop();
    onActiveIndexChanged?.(activeIndex);
  }, [activeIndex]);

  useEffect(() => {
    error && scroll?.scrollToTop();
  }, [error]);

  useEffect(() => {
    validationError && scroll?.scrollToTop();
  }, [validationError]);

  const validateStep = () => {
    setValidationError(null);

    return new Promise((resolve, reject) => {
      if (_children[activeIndex].props?.onValidate) {
        return _children[activeIndex].props
          .onValidate?.()
          .then((result: any) => {
            result ? resolve(true) : reject();
          })
          .catch((err: any) => {
            console.log(err);
            setValidationError(err);
            reject(false);
          });
      } else {
        resolve(true);
      }
    });
  };

  const moveBack = () => {
    if (canMoveBack) {
      var newIndex = activeIndex - 1;
      setActiveIndex(newIndex);
      onBack?.(newIndex);
    }
  };

  const moveNext = () => {
    validateStep()
      ?.then(() => {
        if (canMoveNext) {
          var newIndex = activeIndex + 1;
          setActiveIndex(newIndex);
          onNext?.(newIndex);
        }
      })
      .catch((x) => {
        console.log(x);
        // Do nothing
      });
  };

  const jump = (index: number) => {
    setActiveIndex(index);
    onNext?.(index);
  };

  const handleBack = () => {
    moveBack();
  };

  const handleNext = () => {
    moveNext();
  };

  const handleFinish = () => {
    validateStep()
      ?.then(() => {
        onFinish?.()
          .then(() => {
            setComplete(true);
            setActiveIndex(-1);
          })
          .catch((err) => {
            console.log(err);
            setValidationError(err);
          });
      })
      .catch((err) => {
        console.log(err);
        // Do nothing
      });
  };

  const handleHeaderStepClick = (index: number) => {
    index < activeIndex && allowJumpBack && jump(index);
    index > activeIndex && allowJumpForward && jump(index);
  };

  return (
    <Wrapper className="wizard" fluid={fluid}>
      {!isComplete && showHeaderSteps && (
        <WizardHeader>
          <>
            {_children
              .filter((x: React.ReactElement<IWizardStepProps>) => !x.props?.completion)
              .map((step: React.ReactElement<IWizardStepProps>, index: number) => (
                <WizardHeaderStep
                  key={index}
                  active={activeIndex === index}
                  completed={activeIndex > index}
                  allowJump={
                    (index < activeIndex && allowJumpBack) ||
                    (index > activeIndex && allowJumpForward)
                  }
                  onClick={() => handleHeaderStepClick(index)}
                >
                  {step.props?.icon && <Icon value={step.props?.icon} />}
                  <span>
                    {step.props?.title} {step.props?.completion && "*"}
                  </span>
                </WizardHeaderStep>
              ))}
          </>
        </WizardHeader>
      )}

      {error && <Message text={error} color={Swatches.Danger} />}
      {validationError && (
        <Message text={validationError} color={Swatches.Danger} />
      )}

      { _children
        .filter((x: React.ReactElement<IWizardStepProps>) => !x.props?.completion)
        .map((child: React.ReactElement<IWizardStepProps>, index: number) => (
          <Wizard.Step
            key={index}
            {...child.props}
            active={activeIndex === index}
          />
        ))}

      { (isComplete && _completionStep) &&
        React.cloneElement(_completionStep, {
          active: true,
        })}

      <WizardFooter className="wizard-footer">
        <ActionBar fluid low>
          <Left>
            {(!isComplete || (isComplete && showBackButtonOnCompletion)) &&
              canMoveBack && (
                <Button
                  onClick={handleBack}
                  text={backButtonText}
                  working={working}
                />
              )}
          </Left>
          <Right>
            {onCancel && !isComplete && (
              <Button
                onClick={onCancel}
                text="Cancel"
                working={working}
                color={Swatches.Low}
              />
            )}
            {canMoveNext && (
              <Button
                onClick={handleNext}
                text={nextButtonText}
                working={working}
                color={Swatches.Primary}
              />
            )}
            {canFinish && (
              <Button
                onClick={handleFinish}
                text={finishButtonText}
                working={working}
                color={Swatches.Success}
              />
            )}
            {isComplete && onComplete && (
              <Button onClick={onComplete} text={completionButtonText} />
            )}
          </Right>
        </ActionBar>
      </WizardFooter>
    </Wrapper>
  );
};

export interface IWizardStepProps {
  children: React.ReactElement | React.ReactElement[];
  active?: boolean;
  description?: string;
  title?: string;
  allowJump?: boolean;
  icon?: string;
  hide?(): boolean;
  completion?: boolean;
  onValidate?(): Promise<any>;
  showTitle?: boolean;
}

const WizardStep: React.FC<IWizardStepProps> = ({
  children,
  active,
  description,
  title,
  icon,
  allowJump = false,
  completion,
  showTitle = true,
  onValidate,
}) => {
  return (
    <WizardStepWrapper active={active}>
      {title && showTitle && <Title text={title} size={TitleSize.H3} />}
      <div className="wizard-step-header">
        {description && (
          <Text className="wizard-step-description">{description}</Text>
        )}
      </div>
      {children}
    </WizardStepWrapper>
  );
};

Wizard.Step = WizardStep;

export default Wizard;
