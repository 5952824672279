import React from "react";
import { arrays } from "utils";
import { Table, EmptyMessage, Swatches, Button, Size, DateTime } from "ui-kit";
import { ApprovalStatusChip, OvertimeStatusChip } from "sharedComponents/common";
import { OvertimeListView } from "areas/humanResources/types/leaveResponse.types";


interface IOvertimeTableProps {
  overtime: OvertimeListView[];
  onRowSelected?: (overtime: OvertimeListView, index: number) => void;
  onRecordClicked?: () => void;
}


const OvertimeTable: React.FC<IOvertimeTableProps> = ({ overtime, onRowSelected, onRecordClicked }) => {

  const handleAddOvertime = () => {
    onRecordClicked?.();
  };

  const handleRowClick = (overtime: OvertimeListView, index: number) => {
    onRowSelected?.(overtime, index);
  };

  if (arrays.isEmpty(overtime)) {
    return (
      <EmptyMessage
        icon="house-night"
        title="No Additional Hours"
        summary="No additional hours have been worked yet"
        cover
      >
        <Button
          text="Record Additional Hours"
          onClick={handleAddOvertime}
          color={Swatches.Primary}
          size={Size.Small}
        />
      </EmptyMessage>
    );
  }

  return (
    <Table zebra>
      <Table.Header>
        <Table.HeaderCell width={2}>Date</Table.HeaderCell>
        <Table.HeaderCell width={1} right>
          Hours
        </Table.HeaderCell>
        <Table.HeaderCell width={1} right>
          Reclaimed
        </Table.HeaderCell>
        <Table.HeaderCell right width={6} />
      </Table.Header>
      <Table.Body>
        {overtime.map((overtime: OvertimeListView, index: number) => (
          <Table.Row
            key={index}
            onClick={() => handleRowClick(overtime, index)}
          >
            <Table.Cell>
              <DateTime
                date={overtime.startDate}
                bold
                hideTime
              />
            </Table.Cell>
            <Table.Cell right>{overtime.hours}</Table.Cell>
            <Table.Cell right>{overtime.hoursUsed}</Table.Cell>
            <Table.Cell right>
              <OvertimeStatusChip status={overtime.currentStatus.status} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.Cell colspan={4} right>
            <Button
              text="Record Additional Hours"
              onClick={handleAddOvertime}
              color={Swatches.Primary}
              size={Size.Small}
            />
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default OvertimeTable;
