import React, { useEffect, useState } from "react";
import flyoutActions from "actions/ui/flyouts";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  Flyout,
  Button,
  Swatches,
  Message,
  ToastService,
  TextInput,
  TextInputType,
  Chip,
  Currency,
  Card,
  Label,
  Table,
  ActionBar,
  StructuredList,
  HeadlineStatistic,
  Size,
  Spacing,
  Sub
} from "ui-kit";
import { Constants } from "configuration";
import paymentActions from "areas/payments/actions/paymentsActions";
import Moment from "react-moment";
import { arrays, types } from "utils";
import { Avatar } from "sharedComponents/common";
import { useNavigate } from "react-router-dom";
import { RootState } from "reducers/store";
import { BasketItemView, OrderLineView, ProductAvailibilityView, ProductInstalmentView, UserProductOrderView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { ManagedUserDetailView, UserTinyView } from "types/users/userListViews.types";
import { AddProductToBasketCommand } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";
import { PaymentUser } from "areas/payments/reducers/payments/paymentsUserReducer";
import SingleAddButton from "./singleAddButton";
import RelevantInstallments from "./relevantInstallments";
import paymentsActions from "areas/payments/actions/paymentsActions";


const SinglePurchaseWrapper = styled.div`
  display: flex;
  .textInput {
    margin-right: 8px
  }
  .button {
    width: 150px;
  }
`


interface IItemPaymentFlyoutProps {
  item: UserProductOrderView | OrderLineView;
  user: UserTinyView;
}

const ItemPaymentFlyout: React.FC<IItemPaymentFlyoutProps> = ({ item, user }) => {

  const navigate = useNavigate();
  const { basket, errors: basketErrors } = useSelector((state: RootState) => state.userBasket);
  const { product, error } = useSelector((state: RootState) => state.userProduct);
  const [total, setTotal] = useState<number>(0);
  const [productAvailibility, setProductAvailibility] = useState<ProductAvailibilityView>(null);
  const [_recipient, _setRecipient] = useState<ManagedUserDetailView>(null);

  useEffect(() => {
    if (!basket && user) {
      paymentActions.getBasket(user.id);
    }
  }, []);

  useEffect(() => {
    if (product) {
      setProductAvailibility(product.availableFor[0]);
    }
  }, [product]);

  useEffect(() => {
    if (productAvailibility && (productAvailibility.student.id !== _recipient?.id || !_recipient)) {
      paymentsActions.queryUser(
        productAvailibility.student.id, 
        userResult => _setRecipient(userResult)
      );
    }
  }, [productAvailibility]);

  useEffect(() => {
    if (item) {
      paymentActions.getProductForUser(item.purchasedFor.id, item.product.id);
      setTotal(item.outstanding);
    }
  }, [item]);

  useEffect(() => {
    if (item?.outstanding && total > item.outstanding) {
      setTotal(item.outstanding);
    }
  }, [total]);

  const goToBasket = () => {
    flyoutActions.closeFlyout();
    navigate(`/main/shopkeeper/users/${user.id}?tab=1&item=${item.product.id}`);
  };

  const handleOrderClicked = (orderId: number) => {
    flyoutActions.closeFlyout();
    navigate(`/main/shopkeeper/users/${user.id}/orders/${orderId}`);
  };

  const handleUserClicked = (user: UserTinyView) => {
    flyoutActions.closeFlyout();
    navigate(`/main/shopkeeper/users/${user.id}`);
  };

  return (
    <Flyout title={"Item Payment"} name={Constants.FLYOUTS.ITEMPAYMENT} wide>
      <Flyout.Body>
        <Message text={error} color={Swatches.Danger} />

        {product && item && basket && (
          <>
            <HeadlineStatistic>
              <HeadlineStatistic.Item
                icon="shopping-bag"
                value={<Currency value={item.total} />}
                label="Price"
              />
              <HeadlineStatistic.Item
                icon="gift"
                value={<Currency value={item.paid} />}
                label="Paid"
              />
              <HeadlineStatistic.Item
                icon="gift"
                value={<Currency value={item.outstanding} />}
                label="Outstanding"
              />
            </HeadlineStatistic>

            {basketErrors?.item && (
              <Message
                className="basket-error"
                text={basketErrors?.item}
                icon="times-circle"
                color={Swatches.Danger}
              />
            )}

            <Card>
              <Card.Body>
                <StructuredList>

                  <StructuredList.Item name="Item">
                    <p>{item.product.name}</p>
                  </StructuredList.Item>

                  <StructuredList.Item name="Ordered By">
                    <Avatar
                      user={user}
                      size={Size.Small}
                      onClick={() => handleUserClicked(user)}
                    />
                  </StructuredList.Item>

                  <StructuredList.Item name="Recipient">
                    <Avatar
                      user={item.purchasedFor}
                      size={Size.Small}
                      onClick={() => handleUserClicked(item.purchasedFor)}
                    />
                  </StructuredList.Item>

                  <StructuredList.Item name="Order ID">
                    <Label onClick={() => handleOrderClicked(item.orderId)}>
                      {item.orderId}
                    </Label>
                  </StructuredList.Item>

                  { item.product.allowPartPayment && (
                    <StructuredList.Item
                      name="Total"
                      description="If part payment is required, please enter the value to pay here."
                    >
                      <Table>
                        <Table.Body noHover>
                          <Table.Row>
                            <Table.Cell width={8}>
                              <TextInput
                                type={TextInputType.Currency}
                                value={total}
                                onChange={(value) => setTotal(value)}
                                min={0}
                                max={item?.outstanding ? item.outstanding : 0}
                                fluid
                              />
                            </Table.Cell>
                            <Table.Cell width={2}>
                              <SingleAddButton 
                                user={user} 
                                product={product}
                                item={item}
                                total={total}
                              />
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </StructuredList.Item>
                  )}

                  {( productAvailibility?.instalments.length === 0) && (
                    <StructuredList.Item
                      name="Remaining"
                      description="Remaining payment for items with no instalments"
                    >
                      <SinglePurchaseWrapper>
                        <TextInput
                          type={TextInputType.Currency}
                          value={total}
                          onChange={(value) => setTotal(value)}
                          min={0}
                          max={item?.outstanding ? item.outstanding : 0}
                          fluid
                        />
                        <SingleAddButton 
                          user={user}
                          product={product}
                          item={item}
                          total={total}
                        />
                      </SinglePurchaseWrapper>
                    </StructuredList.Item>
                  )}

                  { _recipient &&
                    ( productAvailibility?.instalments.some(x => x.installmentType === 0) 
                      || productAvailibility?.instalments.some(x => x.installmentType === 1) && _recipient.pupilPremium && !_recipient.bursary
                      || productAvailibility?.instalments.some(x => x.installmentType === 2) && _recipient.bursary ) && (
                      <StructuredList.Item
                        name="Instalments"
                        description="These are the available instalments for the selected recipient. This will add the instalments to the basket of the user that originally ordered the item."
                      >
                        { ( product.ppDiscountedPrice != null && _recipient.pupilPremium || product.bursaryDiscountedPrice != null && _recipient.bursary) && (
                          <div style={{ marginBottom: Spacing.Default + "px" }} >
                            <Sub>
                              { productAvailibility?.bursary ? (
                                <>
                                  <b>Important: </b>Bursary installments will automatically be shown if the student is bursary eligible{" "}
                                </>
                              ) : productAvailibility?.pupilPremium ? (
                                <>
                                  <b>Important: </b>Pupil premium instalments will automatically be shown if the student is pupil premium{" "}
                                </>
                              ) : null }
                            </Sub>
                          </div>
                        )}
                        <RelevantInstallments 
                          user={user}
                          item={item}
                          recipient={productAvailibility}
                        />
                      </StructuredList.Item>
                    )
                  }
                  
                </StructuredList>
              </Card.Body>
            </Card>
          </>
        )}
      </Flyout.Body>
      <Flyout.Footer>
        <ActionBar low>
          <Button
            fluid
            color={Swatches.Success}
            text="Go to Basket"
            onClick={goToBasket}
          />
        </ActionBar>
      </Flyout.Footer>
    </Flyout>
  );
};

export default ItemPaymentFlyout;