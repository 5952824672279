import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { EmptyMessage, Loader, Size, Swatches } from "ui-kit";
import { arrays } from "utils";
import ConsentFormTable from "../consentFormTable";
import ConsentFormFilter from "./consentFormFilter";
import { useNavigate } from "react-router-dom";
import consentActions from "areas/administration/actions/consentActions";
import { consentRoutes } from "areas/administration/adminRoutes";
import { RootState } from "reducers/store";
import { ConsentFormListView } from "areas/administration/types/consentResponses.types";


const ConsentForms = () => {

  return (
    <>
      <ConsentFormFilter />
      <ConsentFormWrapper />
    </>
  );
};


const ConsentFormWrapper = () => {

  const navigate = useNavigate();

  const { forms, loading, error, paging, filter } = useSelector((state: RootState) => state.adminConsentForms);

  const loadConsentForms = (pageIndex: number) => {
    filter && consentActions.getConsentForms(filter, pageIndex);
  };

  useEffect(() => {
    loadConsentForms(0);
  }, [filter]);

  const onPage = () => {
    loadConsentForms(paging.pageIndex + 1);
  };

  const handleClick = (form: ConsentFormListView) => {
    navigate(consentRoutes.getConsentFormAdminPath(form.id));
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem while loading the consent forms"
        icon="times-circle"
        iconColor={Swatches.Danger.swatch}
        cover
      />
    );
  }

  if (arrays.isEmpty(forms)) {
    return (
      <>
        <EmptyMessage
          title="No forms available"
          summary="No consent forms have been created yet"
          icon="clipboard-check"
          cover
        ></EmptyMessage>
      </>
    );
  }
  return (
    <>
      <ConsentFormTable
        forms={forms}
        onClick={handleClick}
        handlePage={onPage}
        paging={paging}
      />
    </>
  );
};

export default ConsentForms;
