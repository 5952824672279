import React, { useEffect, useState } from "react";
import moment from "moment";
import PrimaryLeaderboardTable from "./primaryLeaderboardTable";
import PrimaryLeaderboardActionBar from "./primaryLeaderboardActionBar";
import { Loader, Size } from "ui-kit";
import { TIME_SCALE } from "areas/planner/constants/classViewer/primarySchool";
import leaderboardReportActions from "areas/analytics/actions/leaderboardReportActions";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { useAppSelector } from "reducers/hooks";
import { GroupType } from "types/planner/groups.types";
import { UserTinyView } from "types/users/userListViews.types";
import { CategoryType } from "areas/analytics/types/analytics.shared";


interface IPrimaryLeaderboardWrapperProps {
  groupId: number;
  groupType: GroupType;
  handleOpenBehaviourModal: (students: UserTinyView[]) => void;
}


const PrimaryLeaderboardWrapper: React.FC<IPrimaryLeaderboardWrapperProps> = ({
  groupId,
  groupType,
  handleOpenBehaviourModal,
}) => {

  const { leaderboardReport, loading, error, refresh } = useAppSelector(
    state => state.groupLeaderboard
  );

  const [startDate, setStartDate] = useState<moment.Moment>(moment());
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const [timeScale, setTimeScale] = useState<number>(TIME_SCALE.DAY);

  useEffect(() => {
    if (timeScale === TIME_SCALE.DAY) {
      setStartDate(moment());
      setEndDate(moment());
    } else if (timeScale === TIME_SCALE.WEEK) {
      setStartDate(moment().day(1));
      setEndDate(moment().day(5));
    }
  }, [timeScale]);

  useEffect(() => {
    leaderboardReportActions.getGroupLeaderboardReport(
      groupId,
      {
        groupTypeId: groupType,
        categoryTypeId: CategoryType.Reward,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD")
      }
    );
  }, [endDate, groupId, groupType, startDate]);

  useEffect(() => {
    if (refresh) {
      leaderboardReportActions.getGroupLeaderboardReport(
        groupId,
        {
          groupTypeId: groupType,
          categoryTypeId: CategoryType.Reward,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD")
        }
      );
    }
  }, [refresh]);

  return (
    <>
      <PrimaryLeaderboardActionBar
        // categoryTypeId={categoryTypeId}
        // handleSelectCategoryType={categoryType =>
        //   setCategoryTypeId(categoryType)
        // }
        timeScale={timeScale}
        handleTimeScaleClick={value => setTimeScale(value)}
      />
      {loading ? (
        <Loader size={Size.Large} cover />
      ) : error ? (
        <ApiExceptionMessage error={error} />
      ) : (
        <PrimaryLeaderboardTable
          leaderboardReport={leaderboardReport}
          categoryTypeId={CategoryType.Reward}
          handleOpenBehaviourModal={handleOpenBehaviourModal}
        />
      )}
    </>
  );
};

export default PrimaryLeaderboardWrapper;
