import React from "react";
import { useSelector } from "react-redux";
import { ManagedStaffList } from "sharedComponents/common";
import { Message, Swatches } from "ui-kit";
import ManagedStaffMember from "./managedStaffMember";
import { arrays } from "utils";
import staffMemberActions from "areas/humanResources/actions/staffMemberActions";
import { RootState } from "reducers/store";
import { StaffListView } from "types/users/userListViews.types";


const ManagedStaff = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { loading, error, staffMember } = useSelector((state: RootState) => state.managedStaffMember);

  const handleStaffMemberSelected = (staffMember: StaffListView, index: number) => {
    staffMemberActions.getManagedStaffMember(staffMember.id);
  };

  const handleOnStaffLoaded = (managedStaff: StaffListView[]) => {
    !arrays.isEmpty(managedStaff) && staffMemberActions.getManagedStaffMember(managedStaff[0].id);
  };

  return (
    <>
      <ManagedStaffList
        manager={user}
        onLoaded={handleOnStaffLoaded}
        onStaffMemberSelected={handleStaffMemberSelected}
      />
      <ManagedStaffMember
        staffMember={staffMember}
        error={error}
        loading={loading}
      />
      <Message
        color={Swatches.Blue}
        text={`Incorrect Managed Staff?`}
        subtext={`If your managed staff list is incorrect, please contact your school's HR Administrator who will be able to update these details.`}
        icon="users"
      />
    </>
  );
};

export default ManagedStaff;
