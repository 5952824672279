import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Loader, Size } from "ui-kit";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import InitialActionDropdown from "../behaviourCommon/initialActionDropdown";
import { useAppSelector } from "reducers/hooks";
import { IInitialAction } from "../rewards/initialRewardActionWrapper";


interface IInitialBehaviourActionWrapperProps {
  selectedInitialAction: IInitialAction;
  handleChangeInitialAction: (action: IInitialAction) => void;
}


const InitialBehaviourActionWrapper: React.FC<IInitialBehaviourActionWrapperProps> = ({
  selectedInitialAction,
  handleChangeInitialAction,
}) => {

  const { initialBehaviourActions, loadingBehaviourActions } = useAppSelector(
    state => state.initialBehaviourActions
  );

  useEffect(() => {
    if (!initialBehaviourActions || initialBehaviourActions.length === 0) {
      behaviourActions.getInitialBehaviourActions();
    }
  }, []);

  if (loadingBehaviourActions) {
    return <Loader size={Size.Large} cover />;
  }

  return (
    <InitialActionDropdown
      selectedInitialAction={selectedInitialAction}
      handleChangeInitialAction={handleChangeInitialAction}
      initialActions={initialBehaviourActions}
    />
  );
};


export default InitialBehaviourActionWrapper;
