import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SmartTasksHeader from "./smartTasksHeader";
import styled from "styled-components";
import { EmptyMessage, Loader, Size, Spacing } from "ui-kit";
import SmartTaskList from "./smartTaskList";
import smartTaskActions from "areas/behaviour/actions/smartTasks/smartTaskActions";
import schoolsActions from "areas/planner/actions/schools/schoolsActions";
import SmartTaskFormWrapper from "./smartTaskFormWrapper";
import { useScroll } from "hooks/useScroll";
import { useAppSelector } from "reducers/hooks";


const Wrapper = styled.div`
  display: flex;
`;

const ListWrapper = styled.div`
  max-width: 450px;
  flex-direction: auto;
  margin-right: ${Spacing.ExtraLarge + 16}px;
`;


const SmartTasksWrapper: React.FC = () => {

  const { scrollToTop } = useScroll();
  const { schoolId } = useAppSelector(state => state.schools);
  const { smartTasks, loading, refresh } = useAppSelector(state => state.smartTasks);
  const { selectedSmartTaskId } = useAppSelector(state => state.smartTask);
  const { user } = useAppSelector(state => state.currentUser);

  useEffect(() => {
    if (schoolId) {
      smartTaskActions.getSmartTasks(schoolId);
    } else {
      schoolsActions.selectSchool(user.baseSchool.id);
    }
  }, [schoolId]);

  useEffect(() => {
    if (schoolId && refresh) {
      smartTaskActions.getSmartTasks(schoolId);
    }
  }, [schoolId, refresh]);

  const handleOnClickSmartTask = (smartTaskId: number) => {
    smartTaskActions.selectSmartTask(smartTaskId);
  };

  const onClickNewSmartTask = () => {
    smartTaskActions.selectSmartTask(null);
    smartTaskActions.newSmartTask();
    scrollToTop();
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  return (
    <>
      {/* <HelpMessage
        icon="info-circle"
        title="Need help creating and managing smart tasks?"
        sub="Watch our VLE training video to find out more"
        link="https://vle.lionhearttrust.org.uk/mod/page/view.php?id=70704"
      /> */}
      <SmartTasksHeader />
      {schoolId !== null ? (
        <Wrapper>
          <ListWrapper>
            <SmartTaskList
              smartTasks={smartTasks}
              selectSmartTask={handleOnClickSmartTask}
              selectedSmartTaskId={selectedSmartTaskId}
              onAddSmartTaskClicked={onClickNewSmartTask}
            />
          </ListWrapper>
          <SmartTaskFormWrapper />
        </Wrapper>
      ) : (
        <EmptyMessage
          title="Please select a school to view Smart Tasks"
          icon="tasks"
          cover
        />
      )}
    </>
  );
};

SmartTasksWrapper.propTypes = {
  classgroupId: PropTypes.string,
};

export default SmartTasksWrapper;
