import React, { useEffect, useState } from "react";
import PendingAttentionTable from "./pendingAttentionTable";
import { useSelector } from "react-redux";
import {
  Loader,
  Size,
  EmptyMessage,
  StructuredList,
  TextInput,
  TextInputType,
  Message,
  Swatches,
} from "ui-kit";
import ManagePendingAttentionModal from "./managePendingAttentionModal";
import { Constants } from "configuration";
import PendingAttentionBehavioursActionBar from "./pendingAttentionBehavioursActionBar";
import pendingAttentionBehaviourActions from "areas/behaviour/actions/behaviour/pendingAttentionActions";
import schoolActions from "areas/planner/actions/school/schoolActions";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import flyoutActions from "actions/ui/flyouts";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import ConfirmModal from "sharedComponents/common/confirmModal";
import { YearGroupFilter } from "areas/behaviour/types/detentionRegisterRequest.types";
import { useAppSelector } from "reducers/hooks";
import { BehaviourSummaryListView } from "areas/behaviour/types/behaviourResponses.types";

const PendingAttentionWrapper = () => {

  const { pendingAttentionBehaviours, loading, error, refresh } = useAppSelector(
    state => state.pendingAttentionBehaviours
  );
  const { user } = useAppSelector(state => state.currentUser);
  const { schoolInformation } = useAppSelector(state => state.school);

  const [openManageBehaviourModal, setOpenManageBehaviourModal] = useState<boolean>(false);
  const [behaviour, setBehaviour] = useState<BehaviourSummaryListView>(null);
  const [openRevokeConfirm, setOpenRevokeConfirm] = useState<boolean>(false);
  const [emptyNoteError, setEmptyNoteError] = useState<boolean>(false);
  const [revokeNote, setRevokeNote] = useState<string>("");
  const [behaviourToRevoke, setBehaviourToRevoke] = useState<BehaviourSummaryListView>(null);
  const [filters, setFilters] = useState<YearGroupFilter>({
    academicYearId: Constants.ACADEMIC_YEAR_FILTER_ARRAY[0].value,
    yearGroupId: null,
    date: null,
    behaviourCodeId: null,
    schoolBehaviourCategoryId: null,
    searchTerm: null
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [schoolId, setSchoolId] = useState<number>(user?.baseSchool?.id);

  const callGetPendingAttentionBehaviours = () => {
    if (!loading) {
      pendingAttentionBehaviourActions.getPendingAttentionBehaviours(
        schoolId,
        {
          ...filters,
          searchTerm: searchTerm
        }
      );
    }
  };

  useEffect(() => {
    if (schoolId) {
      callGetPendingAttentionBehaviours();

      if (schoolId !== schoolInformation?.id) {
        schoolActions.getSchoolInformation(schoolId);
      }
    }
  }, [schoolId, filters]);

  useEffect(() => {
    if (schoolId && refresh) {
      callGetPendingAttentionBehaviours();
    }
  }, [refresh]);

  useEffect(() => {
    if (searchTerm.length === 0) {
      callGetPendingAttentionBehaviours();
    }
    const timeoutId = setTimeout(() => {
      if (searchTerm.length >= 2) {
        callGetPendingAttentionBehaviours();
      }
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  const handleSearch = () => {
    callGetPendingAttentionBehaviours();
  };

  const onClickRevoke = () => {
    if (behaviourToRevoke) {
      if (revokeNote.trim() === "") {
        setEmptyNoteError(true);
      } else {
        setOpenRevokeConfirm(false);

        const callback = () => {
          setOpenManageBehaviourModal(false);
          setRevokeNote("");
        };

        behaviourActions.revokeBehaviour(
          {
            studentId: behaviourToRevoke.student.id,
            behaviourId: behaviourToRevoke.behaviourId,
            updateNote: revokeNote,
          },
          callback
        );
      }
    }
  };

  const onClickBehaviour = (behaviour: BehaviourSummaryListView) => {
    flyoutActions.openFlyout(Constants.FLYOUTS.PENDINGATTENTIONBEHAVIOUR);
    pendingAttentionBehaviourActions.selectBehaviour(behaviour);
  };

  const onClaim = (behaviour: BehaviourSummaryListView) => {
    pendingAttentionBehaviourActions.claimPendingAttentionBehaviour(
      behaviour.student.id,
      behaviour.behaviourId
    );
  };

  const handleManageBehaviour = (behaviour: BehaviourSummaryListView) => {
    setBehaviour(behaviour);
    setOpenManageBehaviourModal(true);
  };

  const handleRevokeBehaviour = (behaviour: BehaviourSummaryListView) => {
    setBehaviourToRevoke(behaviour);
    setOpenRevokeConfirm(true);
  };

  const onChangeSchool = (value: number) => {
    setSchoolId(value);
    setFilters({
      ...filters,
      yearGroupId: null,
      schoolBehaviourCategoryId: null,
      behaviourCodeId: null,
    });
    setSearchTerm("");
  };

  return (
    <>
      {/* <HelpMessage
        icon="info-circle"
        title="Need help managing pending attention behaviours?"
        sub="Watch our VLE training video to find out more"
        link="https://vle.lionhearttrust.org.uk/mod/page/view.php?id=70120"
      /> */}

      <PendingAttentionBehavioursActionBar
        filters={filters}
        onChangeFilters={(newfilters: YearGroupFilter) => setFilters(newfilters)}
        schoolId={schoolId}
        onChangeSchool={onChangeSchool}
        yearGroups={
          schoolInformation && schoolInformation.yearGroups
            ? schoolInformation.yearGroups
            : []
        }
        searchTerm={searchTerm}
        onChangeSearchTerm={(value: string) => setSearchTerm(value)}
        onClickSearch={handleSearch}
        loading={loading}
      />

      {loading ? (
        <Loader size={Size.Medium} cover />
      ) : error ? (
        <ApiExceptionMessage error={error} />
      ) : pendingAttentionBehaviours.length > 0 ? (
        <>
          <PendingAttentionTable
            pendingAttentionBehaviours={pendingAttentionBehaviours}
            onClickBehaviour={onClickBehaviour}
            onClaim={onClaim}
            handleManageBehaviour={handleManageBehaviour}
            handleRevokeBehaviour={handleRevokeBehaviour}
          />

          <ManagePendingAttentionModal
            open={openManageBehaviourModal}
            closeModal={() => setOpenManageBehaviourModal(false)}
            behaviourDetails={behaviour}
            handleRevokeBehaviour={handleRevokeBehaviour}
            schoolId={schoolId}
          />

          <ConfirmModal
            openModal={openRevokeConfirm}
            confirmMsg="Are you sure you want to revoke this behaviour point?"
            onClose={() => {
              setOpenRevokeConfirm(false);
              setEmptyNoteError(false);
            }}
            onConfirm={onClickRevoke}
          >
            <StructuredList>
              <StructuredList.Item
                name="Reason"
                description="Please enter the reason for revoking the behaviour point"
                required
                helpPopup
              >
                <TextInput
                  type={TextInputType.Textarea}
                  placeholder="Revoke Reason"
                  fluid
                  value={revokeNote}
                  onChange={value => setRevokeNote(value)}
                />
              </StructuredList.Item>
            </StructuredList>

            {emptyNoteError && (
              <Message
                text="Please enter the reason to revoke this behaviour"
                color={Swatches.Danger}
              />
            )}
          </ConfirmModal>
        </>
      ) : (
        <EmptyMessage
          icon="list"
          title="No Pending Attention Behaviours"
          cover
        />
      )}
    </>
  );
};

export default PendingAttentionWrapper;
