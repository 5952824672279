import { SchoolListView } from "types/schools/schools.types";
import { CashlessPageInfo, PageInfo } from "./paging.types";

export interface PagedQueryView<T> {
    items: T[];
    paging: PageInfo;
    unread?: number;
}

export interface PagedCashlessQueryView<T> {
    items: T[];
    paging: CashlessPageInfo;
}

export interface SearchResultView<T> extends SearchResultViewBase {
    data?: T;
}

export interface SearchResultViewBase {
    id: string;
    title: string;
    description: string;
    category: SearchCategory;
    subCategory: SearchSubCategory;
    school: SchoolListView;
}

export enum SearchCategory {
    User = 0,
    Group = 1,
    Department = 2
}

export enum SearchSubCategory {
    ClassGroup = 0,
    Subject = 1,
    TutorGroup = 2,
    YearGroup = 3,
    House = 4,
    CustomGroup = 5,
    Group = 6,
    User = 7,
    Student = 8,
    Staff = 9,
    Parent = 10,
    Department = 11
}

export interface UserTypePermissionsView {
    allowStaff: boolean;
    allowStudents: boolean;
    allowParents: boolean;
}

export interface SearchQuery<T> extends PagedQueryView<SearchResultView<T>> {
    searchTerm: string;
}