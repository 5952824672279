import React, { useEffect, useState } from "react";
import { Modal } from "ui-kit";
import { useSelector } from "react-redux";
import { Loader, Size } from "ui-kit";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { ActionBar, Button, ButtonBar, Right, Swatches } from "ui-kit";
import ConfirmModal from "sharedComponents/common/confirmModal";
import ManageOnCallForm from "./manageOnCallForm";
import onCallActions from "../../actions/onCall/onCallActions";
import { useAppSelector } from "reducers/hooks";
import { OnCallRequestDetailView } from "areas/analytics/reducers/onCallAnalyticsResponse.types";
import { objects } from "utils";
import { ResolveOnCallRequestCommand } from "areas/behaviour/types/onCall/onCallRequest.types";
import { UserTinyView } from "types/users/userListViews.types";
import { OnCallStatus } from "areas/analytics/reducers/onCallAnalyticsShared.types";


interface IManageOnCallModalProps {
  open: boolean;
  handleCloseModal: () => void;
  onCallRequestDetails: OnCallRequestDetailView;
  schoolId: number;
  handleClaimRequest: (onCallRequest: OnCallRequestDetailView) => void;
  handleCancelRequest: (internalNotes: string, onSuccess?: () => void) => void;
  claimAndResolve?:boolean
}


const ManageOnCallModal: React.FC<IManageOnCallModalProps> = ({
  open,
  handleCloseModal,
  onCallRequestDetails,
  schoolId,
  handleClaimRequest,
  handleCancelRequest,
  claimAndResolve,
}) => {

  const { onCallRequest, refresh, actions } = useAppSelector(
    state => state.onCallRequest
  );

  const [openConfirmResolved, setOpenConfirmResolved] = useState<boolean>(false);
  const [openConfirmCancel, setOpenConfirmCancel] = useState<boolean>(false);
  const [internalNotes, setInternalNotes] = useState<string>("");
  const [_onCallRequest, _setOnCallRequest] = useState<OnCallRequestDetailView>(null);

  const onSuccess = () => {
    handleCloseModal();
    setInternalNotes("");
    _setOnCallRequest(null);
  };

  useEffect(() => {
    if (onCallRequestDetails && open) {
      onCallActions.getOnCallRequest(schoolId, onCallRequestDetails.id);
    }
  }, [onCallRequestDetails, open]);

  useEffect(() => {
    if (onCallRequest) {
      const tempOnCallRequest = objects.deepClone(onCallRequest);
      _setOnCallRequest(tempOnCallRequest);
    }
  }, [onCallRequest]);

  useEffect(() => {
    if (refresh) {
      onCallActions.getOnCallRequest(schoolId, onCallRequestDetails.id);
    }
  }, [refresh]);

  // const handleClaimRequest = () => {
  //   const payload = {
  //     id: _onCallRequest.id,
  //     schoolId: schoolId,
  //   };

  //   claimOnCallRequestAction(schoolId, _onCallRequest.id, payload);
  // };

  const handleResolveRequest = (internalNotes: string) => {
    const payload: ResolveOnCallRequestCommand = {
      id: _onCallRequest.id,
      schoolId: schoolId,
      internalNotes: internalNotes,
    };

    onCallActions.resolveOnCallRequest(
      schoolId,
      _onCallRequest.id,
      payload,
      onSuccess
    );
  };

  // const handleCancelRequest = internalNotes => {
  //   const payload = {
  //     id: _onCallRequest.id,
  //     schoolId: schoolId,
  //     internalNotes: internalNotes,
  //   };

  //   cancelOnCallRequestAction(schoolId, _onCallRequest.id, payload, onSuccess);
  // };

  const handleStudentListChange = (students: UserTinyView[]) => {
    _setOnCallRequest({ ..._onCallRequest, students: students });
  };

  return (
    <>
      <Modal
        title="Manage On Call Request"
        onClose={handleCloseModal}
        open={open}
        height="90%"
        width="70%"
      >
        {actions.getOnCallRequest.isLoading ? (
          <Loader size={Size.Large} cover />
        ) : (
          <>
            <Modal.Body>
              <ApiExceptionMessage error={actions.getOnCallRequest.error} />
              <ApiExceptionMessage error={actions.resolveOnCallRequest.error} />
              <ApiExceptionMessage error={actions.cancelOnCallRequest.error} />

              {_onCallRequest && (
                <ManageOnCallForm
                  onCallRequest={_onCallRequest}
                  handleStudentListChange={handleStudentListChange}
                  internalNotes={internalNotes}
                  onChangeInternalNotes={notes => setInternalNotes(notes)}
                  claimAndResolve={claimAndResolve}
                />
              )}
            </Modal.Body>

            <Modal.Footer>
              <ActionBar low>
                <Right>
                  <ButtonBar>
                    {_onCallRequest?.status === OnCallStatus.Open && !claimAndResolve ? (
                      <Button
                        color={Swatches.Success}
                        text="Claim"
                        size={Size.Small}
                        onClick={() => handleClaimRequest(_onCallRequest)}
                        working={
                          actions.claimOnCallRequest.isLoading ||
                          actions.cancelOnCallRequest.isLoading ||
                          actions.resolveOnCallRequest.isLoading
                        }
                      />
                    ) : (
                      (_onCallRequest?.status === OnCallStatus.Claimed || claimAndResolve)  && (
                        <>
                          <Button
                            color={Swatches.Success}
                            text={claimAndResolve? "Claim & Resolve" : "Mark Resolved"}
                            size={Size.Small}
                            onClick={() => setOpenConfirmResolved(true)}
                            working={
                              actions.claimOnCallRequest.isLoading ||
                              actions.cancelOnCallRequest.isLoading ||
                              actions.resolveOnCallRequest.isLoading
                            }
                          />
                          <Button
                            text="Cancel Request"
                            size={Size.Small}
                            onClick={() => setOpenConfirmCancel(true)}
                            disabled={
                              actions.claimOnCallRequest.isLoading ||
                              actions.cancelOnCallRequest.isLoading ||
                              actions.resolveOnCallRequest.isLoading
                            }
                          />
                        </>
                      )
                    )}

                    <Button
                      color={Swatches.Low}
                      text="Close"
                      size={Size.Small}
                      onClick={handleCloseModal}
                      disabled={
                        actions.claimOnCallRequest.isLoading ||
                        actions.cancelOnCallRequest.isLoading ||
                        actions.resolveOnCallRequest.isLoading
                      }
                    />
                  </ButtonBar>
                </Right>
              </ActionBar>
            </Modal.Footer>
          </>
        )}
      </Modal>

      <ConfirmModal
        openModal={openConfirmResolved}
        confirmMsg="Are you sure you want to resolve this on call request?"
        onClose={() => setOpenConfirmResolved(false)}
        onConfirm={() => {
          handleResolveRequest(internalNotes);
          setOpenConfirmResolved(false);
        }}
      />

      <ConfirmModal
        openModal={openConfirmCancel}
        confirmMsg="Are you sure you want to cancel this on call request?"
        onClose={() => setOpenConfirmCancel(false)}
        onConfirm={() => {
          handleCancelRequest(internalNotes, onSuccess);
          setOpenConfirmCancel(false);
        }}
      />
    </>
  );
};


export default ManageOnCallModal;
