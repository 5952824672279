import { LAYOUT_DIRECTION } from "areas/planner/constants/classViewer/classViewerLayout";
import classViewerActions from "../../actions/classViewer/classViewerActions";
import groupActions from "../../actions/group/groupActions";
import classRoomActions from "../../actions/classViewer/classRoomActions";

const INITIAL_STATE = {
  openGenerateLayout: false,
  classLayout: null, //ClassLayoutView
  layoutDirection: LAYOUT_DIRECTION.BACKTOFRONT,
  loadingClassLayout: false,
  error: null,
  generateLayoutError: null,
  refresh: false,
};

const {
  GETCLASSLAYOUT,
  CREATECLASSLAYOUT,
  DELETECLASSLAYOUT,
  UPDATECLASSLAYOUT,
  OPENGENERATELAYOUT,
  GENERATELAYOUT,
  CHANGELAYOUTDIRECTION,
} = classViewerActions.types;

const { GETGROUP, GETDEFAULTLAYOUT } = groupActions.types;

const { DELETEROOMLAYOUT, CREATEROOMLAYOUT } = classRoomActions.types;

const classViewerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GETDEFAULTLAYOUT:
      return { ...state, classLayout: action.payload };

    case GETCLASSLAYOUT.START:
      return { ...state, loadingClassLayout: true, error: null };

    case GETCLASSLAYOUT.SUCCESS:
      return {
        ...state,
        loadingClassLayout: false,
        classLayout: action.payload,
        refresh: false,
      };

    case GETCLASSLAYOUT.FAILED:
      return { ...state, loadingClassLayout: false, error: action.payload };

    case GETGROUP.START:
      return { ...state, classLayout: null };

    case CREATECLASSLAYOUT.START:
      return { ...state, loadingClassLayout: true, error: null };

    case CREATECLASSLAYOUT.SUCCESS: {
      return {
        ...state,
        // selected: action.payload,
        loadingClassLayout: false,
      };
    }

    case CREATECLASSLAYOUT.FAILED:
      return { ...state, loadingClassLayout: false, error: action.payload };

    case UPDATECLASSLAYOUT.START:
      return { ...state, loadingClassLayout: true, error: null };

    case UPDATECLASSLAYOUT.SUCCESS:
      return { ...state, loadingClassLayout: false };

    case UPDATECLASSLAYOUT.FAILED:
      return { ...state, loadingClassLayout: false, error: action.payload };

    case DELETECLASSLAYOUT.START:
      return { ...state, loadingClassLayout: true, error: null };

    case DELETECLASSLAYOUT.SUCCESS:
      return {
        ...state,
        // selected: null,
        classLayout: null,
        loadingClassLayout: false,
      };

    case DELETECLASSLAYOUT.FAILED:
      return { ...state, loadingClassLayout: false, error: action.payload };

    // case GETROOMLAYOUT.START:
    //   return { ...state, loadingRoomLayout: true, error: null };

    // case GETROOMLAYOUT.SUCCESS:
    //   return { ...state, roomLayout: action.payload, loadingRoomLayout: false };

    // case GETROOMLAYOUT.FAILED:
    //   return { ...state, loadingRoomLayout: false, error: action.payload };

    case OPENGENERATELAYOUT:
      return { ...state, openGenerateLayout: action.payload };

    case GENERATELAYOUT.START:
      return { ...state, loadingClassLayout: true, generateLayoutError: null };

    case GENERATELAYOUT.SUCCESS:
      return {
        ...state,
        classLayout: action.payload,
        loadingClassLayout: false,
      };

    case GENERATELAYOUT.FAILED:
      return {
        ...state,
        loadingClassLayout: false,
        generateLayoutError: action.payload,
      };

    case CHANGELAYOUTDIRECTION:
      return { ...state, layoutDirection: action.payload };

    case DELETEROOMLAYOUT.SUCCESS:
    case CREATEROOMLAYOUT.SUCCESS:
      return { ...state, refresh: true };

    default:
      return state;
  }
};

export default classViewerReducer;
