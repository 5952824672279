import { ProvisionMapDetailView } from "areas/send/types/passportResponse.types";
import provisionMapActions from "../../actions/provisionMaps/provisionMapActions";
import provisionGroupsActions from "../../actions/provisions/provisionGroupsActions";
import provisionsActions from "../../actions/provisions/provisionsActions";
import { IBeehiveAction } from "types/common/action.types";
import { arrays } from "utils";

interface IProvisionMapState {
  provisionMap: ProvisionMapDetailView | null;
  loading: boolean;
  error: string | null;
  saving: boolean;
  saveError: string | null;
  deleting: boolean;
  deleteError: string | null;
  downloading: boolean;
  downloadError: string | null;
  newId: number | null;
}

const INITIAL_STATE: IProvisionMapState = {
  provisionMap: null,
  loading: false,
  error: null,
  saving: false,
  saveError: null,
  deleting: false,
  deleteError: null,
  downloading: false,
  downloadError: null,
  newId: null
};


const provisionMapReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IProvisionMapState => {

  const {
    PROVISIONMAP_GET,
    PROVISIONMAP_CREATE,
    PROVISIONMAP_SAVE,
    PROVISIONMAP_DELETE,
    PROVISIONMAP_DOWNLOAD,
    PROVISIONMAPCOMMENT_CREATE,
    PROVISIONMAPCOMMENT_DELETE,
    PROVISIONMAPCOMMENT_SAVE,
  } = provisionMapActions.types;

  const {
    PROVISIONRECORDS_CREATE,
    PROVISIONRECORDS_SAVE,
    PROVISIONRECORDS_DELETE,
    PROVISIONRECORDS_UPDATESTATUS,
  } = provisionGroupsActions.types;

  const { PROVISIONS_CREATECUSTOM, PROVISIONS_SAVECUSTOM, PROVISIONS_DELETE } =
    provisionsActions.types;

  switch (action.type) {
    case PROVISIONMAP_GET.START:
      return { ...state, loading: true };
    case PROVISIONMAP_GET.SUCCESS:
      return { ...state, loading: false, provisionMap: action.payload };
    case PROVISIONMAP_GET.FAILED:
      return { ...state, loading: false, error: action.payload };

    case PROVISIONMAP_DOWNLOAD.START:
      return { ...state, downloading: true };
    case PROVISIONMAP_DOWNLOAD.SUCCESS:
      return { ...state, downloading: false };
    case PROVISIONMAP_DOWNLOAD.FAILED:
      return {
        ...state,
        downloading: false,
        downloadError: "There was a problem downloading the provision map",
      };

    case PROVISIONMAP_CREATE.START:
      return { ...state, saving: true, saveError: null };
    case PROVISIONMAP_CREATE.SUCCESS:
      return { ...state, saving: false, newId: action.payload.id };
    case PROVISIONMAP_CREATE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem creating the provision map",
      };

    case PROVISIONMAP_SAVE.START:
      return { ...state, saving: true, saveError: null, deleteError: null };
    case PROVISIONMAP_SAVE.SUCCESS:
      return { ...state, saving: false };
    case PROVISIONMAP_SAVE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the provision map",
      };

    case PROVISIONMAP_DELETE.START:
      return { ...state, deleteError: null, saveError: null, saving: true };
    case PROVISIONMAP_DELETE.SUCCESS:
      return { ...state, deleting: false };
    case PROVISIONMAP_DELETE.FAILED:
      return {
        ...state,
        deleting: false,
        deleteError: "There was a problem deleting the provision map.",
      };

    // Standard Provisions
    case PROVISIONRECORDS_CREATE.SUCCESS:
      return {
        ...state,
        provisionMap: state.provisionMap
          ? {
              ...state.provisionMap,
              records: [action.payload, ...state.provisionMap?.records],
            }
          : null,
      };

    case PROVISIONRECORDS_SAVE.SUCCESS:
    case PROVISIONRECORDS_UPDATESTATUS.SUCCESS:
      return {
        ...state,
        provisionMap: state.provisionMap
          ? {
              ...state.provisionMap,
              records: state.provisionMap?.records.map(a =>
                a.id === action.payload.id ? action.payload : a
              ),
            }
          : null,
      };

    case PROVISIONRECORDS_DELETE.SUCCESS:
      return {
        ...state,
        provisionMap: state.provisionMap
          ? {
              ...state.provisionMap,
              records: state.provisionMap?.records.filter(
                a => a.id !== action.payload
              ),
            }
          : null,
      };

    // Custom Provisions
    case PROVISIONS_CREATECUSTOM.SUCCESS:
      return {
        ...state,
        provisionMap: state.provisionMap
          ? {
              ...state.provisionMap,
              customProvisions: [
                action.payload,
                ...state.provisionMap.customProvisions,
              ],
            }
          : null,
      };

    case PROVISIONS_SAVECUSTOM.SUCCESS:
      return {
        ...state,
        provisionMap: state.provisionMap
          ? {
              ...state.provisionMap,
              customProvisions: state.provisionMap.customProvisions.map(a =>
                a.id === action.payload.id ? action.payload : a
              ),
            }
          : null,
      };

    case PROVISIONS_DELETE.SUCCESS:
      return {
        ...state,
        provisionMap: state.provisionMap
          ? {
              ...state.provisionMap,
              customProvisions: state.provisionMap.customProvisions.filter(
                a => a.id !== action.payload
              ),
            }
          : null,
      };

    // Comments
    case PROVISIONMAPCOMMENT_CREATE.SUCCESS:
      return {
        ...state,
        provisionMap: state.provisionMap
          ? {
              ...state.provisionMap,
              reviewComments: arrays.isEmpty(state.provisionMap.reviewComments)
              ? [{ term: action.payload.term, comments: [action.payload] }]
              : state.provisionMap.reviewComments.map(review =>
                  review.term.id === action.payload.term.id
                    ? {...review, comments: [...review.comments, action.payload]}
                    : review
                )
            }
          : null,
      };

    case PROVISIONMAPCOMMENT_SAVE.SUCCESS:
      return {
        ...state,
        provisionMap: state.provisionMap
          ? {
              ...state.provisionMap,
              reviewComments: state.provisionMap.reviewComments.map(review =>
                review.term.id === action.payload.term.id 
                  ? {...review, comments: review.comments.map(comment => 
                    comment.id == action.payload.id
                      ? action.payload
                      : comment
                    )}
                  : review
              ),
            }
          : null,
      };

    case PROVISIONMAPCOMMENT_DELETE.SUCCESS:
      return {
        ...state,
        provisionMap: state.provisionMap
          ? {
              ...state.provisionMap,
              reviewComments: state.provisionMap.reviewComments.map(review => 
                review.comments.some(comment => comment.id == action.payload)
                ? {...review, comments: review.comments.filter(c => c.id !== action.payload)}
                : review
              ),
            }
          : null,
      };

    default:
      return state;
  }
};

export default provisionMapReducer;
