import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import EventsFeed from "./eventsFeed";
import { Constants } from "configuration";
import calendarActions from "../../actions/calendarActions";
import { RootState } from "reducers/store";


const UserEvents = () => {

  const { events, error, loading, paging } = useSelector((state: RootState) => state.userCalendarEvents);
  const { user } = useSelector((state: RootState) => state.currentUser);

  const getEvents = (page: number) => {
    const filter =
      user.userType === Constants.USER_TYPES.STUDENT
        ? { startDate: new Date() }
        : null;
    user && calendarActions.getUserCalendarEvents(user.id, filter, page);
  };

  useEffect(() => {
    getEvents(0);
  }, []);

  const handleOnPage = () => {
    if (!loading) {
      getEvents(paging.pageIndex + 1);
    }
  };

  return (
    <EventsFeed
      events={events}
      error={error}
      loading={loading}
      paging={paging}
      onPage={handleOnPage}
    />
  );
};

export default UserEvents;
