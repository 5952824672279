import React, { useState } from "react";
import styled from "styled-components";
import ReportList from "areas/analytics/components/reportList";
import ClassBehaviourWrapper from "areas/analytics/components/classGroupBehaviour/classBehaviourWrapper";


const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const ReportWrapper = styled.div`
  flex-grow: 2;
  margin-left: 250px;
`;


interface StudentReport {
  key: number;
  text: string;
  name: string;
}

const availableReports: StudentReport[] = [{ key: 0, text: "Behaviour", name: "behaviour" }];


const ReportingWrapper: React.FC = () => {

  const [selectedReport, setSelectedReport] = useState<StudentReport>(availableReports[0]);

  //   const { path } = useRouteMatch();
  //   const history = useHistory();
  //   const { groupId, groupType } = useParams();

  //   const handleSelectReport = report => {
  //     history.push(
  //       `/main/groups/${groupId}/grouptype/${groupType}/reporting/${report.name}`
  //     );
  //     setSelectedReport(report);
  //   };

  const handleSelectReport = (report: StudentReport) => {
    setSelectedReport(report);
  };

  return (
    <Wrapper className="reporting-wrapper">
      <ReportList
        reports={availableReports}
        handleSelectReport={handleSelectReport}
        selectedReport={selectedReport}
      />

      <ReportWrapper className="report-wrapper">
        {/* <Switch>
          <Route exact path={`${path}`}>
            <Redirect
              to={`/main/groups/${groupId}/grouptype/${groupType}/reporting/${selectedReport?.name}`}
            />
          </Route>
          <CustomRoute
            exact
            path={`${path}/behaviour`}
            component={ClassBehaviourWrapper}
          />
        </Switch> */}
        {selectedReport.key === 0 && <ClassBehaviourWrapper />}
      </ReportWrapper>
    </Wrapper>
  );
};

export default ReportingWrapper;
