import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import paymentActions from "areas/payments/actions/paymentsActions";
import {
  Size,
  Title,
  Table,
  TextInput,
  TextInputType,
  TitleSize,
  Tabs,
  Swatches,
  EmptyMessage,
  StructuredList,
  Card,
  Currency,
  LoadingWrapper,
  HeadlineStatistic,
  Right,
  DateTime,
  ToastService,
  Button,
  Message,
  ActionBar,
  ValidationMessage,
  ActionPanel,
} from "ui-kit";
import BasketView from "./basketView";
import Subheader from "sharedComponents/layout/header/subheader";
import OrderStatus from "./orderStatus";
import Moment from "react-moment";
import { Avatar, RoleView, UserName } from "sharedComponents/common";
import { Constants } from "configuration";
import PaymentsTable from "./paymentsTable";
import RefundsTable from "./refundsTable";
import CreditsTable from "../smartcards/creditsTable";
import flyoutActions from "actions/ui/flyouts";
import SmartcardCreditFlyout from "../smartcards/smartcardCreditFlyout";
import CreateRefundFlyout from "./createRefundFlyout";
import OrderLinesTable from "./orderLinesTable";
import ItemPaymentFlyout from "./itemPaymentFlyout";
import ChangeQuantityModal from "./changeQuantityModal";
import { arrays } from "utils";
import { RootState } from "reducers/store";
import paymentsActions from "areas/payments/actions/paymentsActions";
import { UserListView } from "types/users/userListViews.types";
import { OrderLineView, SmartcardSyncResultListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


const Order = () => {

  const {
    loading,
    error,
    order,
    sendingReceipt,
    cancelling,
    voiding,
    voidError,
    cancelError,
    receiptError,
    addingNote,
    noteError,
  } = useSelector((state: RootState) => state.userOrder);
  const { error: smartcardCreditWorkingError, working: smartcardCreditWorking } = useSelector((state: RootState) => state.smartcardCredits);
  const { error: printCreditWorkingError, loading: printCreditWorking } = useSelector((state: RootState) => state.printCredits);

  const { orderId, userId } = useParams();
  const navigate = useNavigate();

  const [changeQuantityModalOpen, setChangeQuantityModalOpen] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<OrderLineView>(null);
  const [_note, _setNote] = useState<string | null>(null);
  const [selectedSmartcardCredit, setSelectedSmartcardCredit] = useState<SmartcardSyncResultListView | null>(null);
  const [selectedPrintCredit, setSelectedPrintCredit] = useState<SmartcardSyncResultListView | null>(null);

  useEffect(() => {
    orderId && userId && paymentsActions.getOrder(userId, parseInt(orderId));
  }, [orderId, userId]);

  const handleUserClicked = (user: UserListView) => {
    navigate(`/main/shopkeeper/users/${user.id}`);
  };

  const handleSmartcardCreditRowClicked = (credit: SmartcardSyncResultListView) => {
    setSelectedSmartcardCredit(credit);
    flyoutActions.openFlyout(Constants.FLYOUTS.CREDIT);
  };

  const handlePrintCreditRowClicked = (credit: SmartcardSyncResultListView) => {
    setSelectedPrintCredit(credit);
    flyoutActions.openFlyout(Constants.FLYOUTS.CREDIT);
  };

  const handleCancelOrder = () => {
    if (window.confirm("This will cancel this order and automatically refund any refundable items. IMPORTANT: if the order has smartcard credits it will reverse the credit and reduce the card balance.This cannot be undone. Are you sure?")) {
      paymentActions.cancelOrder(order.user.id, order.id, () => {
        ToastService.pop("Order cancelled successfully", null, "receipt");
        paymentActions.getOrder(userId, parseInt(orderId));
      });
    }
  };

  const handleVoidOrder = (issueRefund: boolean) => {
    if ( window.confirm(`This will void this order ${issueRefund ? "and automatically refund any refundable items" : ""}. 
          IMPORTANT:  If the order has smartcard credits it will reverse the credit and reduce the card balance. This cannot be undone. Are you sure?`)) {
      paymentsActions.voidOrder(
        { 
          userId: order.user.id, 
          orderId: order.id, 
          issueRefund: issueRefund 
        },
        () => {
          ToastService.pop("Order voided successfully", null, "receipt");
          paymentActions.getOrder(userId, parseInt(orderId));
        }
      );
    }
  };

  const handleAddOrderNote = () => {
    var errors = [];

    if (!_note || _note === "") {
      errors.push(`Please enter a note.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    paymentsActions.addOrderNote(
      { 
        userId: order.user.id, 
        id: order.id, 
        notes: _note 
      },
      () => {
        ToastService.pop("Note added successfully", null, "receipt");
        _setNote("");
      }
    );
  };

  const handleResendInvoice = () => {
    if (window.confirm("This will resend this invoice by email. Are you sure?")) {
      paymentsActions.sendReceipt(order.user.id, order.id, () => {
        ToastService.pop("Receipt sent successfully", null, "receipt");
      });
    }
  };

  const handleRefundCreated = () => {
    paymentsActions.getOrder(userId, parseInt(orderId));
  };

  const handlePayClicked = (order: OrderLineView) => {
    setSelectedItem(order);
    flyoutActions.openFlyout(Constants.FLYOUTS.ITEMPAYMENT);
  };

  const handleChangeOrderClicked = () => {
    setChangeQuantityModalOpen(true);
  };

  const handleOrderUpdated = () => {
    setChangeQuantityModalOpen(false);
    paymentsActions.getOrder(userId, parseInt(orderId));
  };

  return (
    <LoadingWrapper
      loading={loading}
      error={error != null}
      data={order}
      errorMessage="There was a problem loading the order"
      emptyMessage="The requested order was not found"
      emptyIcon="shopping-bag"
    >
      {order && (
        <>
          <Subheader>
            <Title
              size={TitleSize.H2}
              text={`Order ${order.id}`}
              sub={<UserName user={order.user} />}
            />
            <OrderStatus status={order.status} />
          </Subheader>
          <HeadlineStatistic>
            <HeadlineStatistic.Item
              icon="receipt"
              label="Total"
              value={<Currency value={order.total} />}
            />
            <HeadlineStatistic.Item
              icon="receipt"
              label="Paid"
              value={<Currency value={order.paid} />}
            />
            {order.outstanding > 0 && (
              <HeadlineStatistic.Item
                icon="receipt"
                label="Remaning"
                value={<Currency value={order.outstanding} />}
              />
            )}

            {order.refunded > 0 && (
              <HeadlineStatistic.Item
                icon="receipt"
                value={<Currency value={order.refunded} />}
                label="Refunded"
              />
            )}

            <HeadlineStatistic.Item
              icon="receipt"
              label="Refundable"
              value={<Currency value={order.refundable} />}
            />
          </HeadlineStatistic>
          <Card>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Placed By">
                  <Avatar
                    user={order.user}
                    size={Size.Medium}
                    onClick={() => handleUserClicked(order.user)}
                    sub={
                      <Moment date={order.date} format="DD/MM/YYYY HH:mma" />
                    }
                  />
                </StructuredList.Item>

                <StructuredList.Item name="Order Status">
                  <OrderStatus status={order.status} />
                </StructuredList.Item>

                {order.cancelledBy && (
                  <StructuredList.Item name="Cancelled By">
                    <Avatar
                      user={order.cancelledBy}
                      sub={
                        <Moment
                          date={order.cancelledDate}
                          format="DD/MM/YYYY HH:mma"
                        />
                      }
                    />
                  </StructuredList.Item>
                )}
                {order.voidedBy && (
                  <StructuredList.Item name="Voided By">
                    <Avatar
                      user={order.voidedBy}
                      sub={
                        <Moment
                          date={order.voidedDate}
                          format="DD/MM/YYYY HH:mma"
                        />
                      }
                    />
                  </StructuredList.Item>
                )}
              </StructuredList>
            </Card.Body>
          </Card>

          <Tabs>
            <Tabs.Pane name="Items" label="Items Ordered">
              <Card>
                <Card.Body noPad>
                  <OrderLinesTable
                    order={order}
                    onPayClicked={handlePayClicked}
                  />
                </Card.Body>
                <Card.Footer>
                  <RoleView roles={[Constants.ROLES.SHOPKEEPER_ADMINISTRATOR]}>
                    <ActionBar low>
                      <Right>
                        <Button
                          color={Swatches.Primary}
                          size={Size.Small}
                          text="Change Order"
                          onClick={handleChangeOrderClicked}
                        />
                      </Right>
                    </ActionBar>
                  </RoleView>
                </Card.Footer>
              </Card>

              <ChangeQuantityModal
                open={changeQuantityModalOpen}
                onUpdate={handleOrderUpdated}
                onCancel={() => setChangeQuantityModalOpen(false)}
              />
              <ItemPaymentFlyout item={selectedItem} user={order.user} />
            </Tabs.Pane>
            <Tabs.Pane name="Notes" label="Notes">
              <Message text={noteError} color={Swatches.Danger} />
              <ValidationMessage errors={validationErrors} />
              <Card title="Add Note">
                <Card.Body>
                  <StructuredList>
                    <StructuredList.Item name="Add Note">
                      <TextInput
                        type={TextInputType.Textarea}
                        value={_note}
                        onChange={(value) => _setNote(value)}
                        fluid
                        rows={3}
                      />
                    </StructuredList.Item>
                    <StructuredList.Item>
                      <Button
                        size={Size.Small}
                        color={Swatches.Primary}
                        text="Add Note"
                        working={addingNote}
                        onClick={handleAddOrderNote}
                      />
                    </StructuredList.Item>
                  </StructuredList>
                </Card.Body>
              </Card>
              <Card title="Notes">
                {arrays.isEmpty(order.notes) ? (
                  <Card.Body>
                    <EmptyMessage title="No notes added" icon="file" />
                  </Card.Body>
                ) : (
                  <Card.Body noPad>
                    <Table>
                      {order.notes.map((note, index) => (
                        <Table.Row key={index}>
                          <Table.Cell width={2.5}>
                            <Avatar
                              user={note.createdBy}
                              size={Size.Small}
                              sub={
                                <Moment
                                  date={note.createdDate}
                                  format="DD/MM/YYYY HH:mma"
                                />
                              }
                            />
                          </Table.Cell>
                          <Table.Cell>{note.notes}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table>
                  </Card.Body>
                )}
              </Card>
            </Tabs.Pane>
            <Tabs.Pane name="Actions" label="Actions">
              {order.status === Constants.ORDER_STATUS.OPEN.value ||
              order.status === Constants.ORDER_STATUS.COMPLETE.value ? (
                <>
                  {cancelError && (
                    <Message text={cancelError} color={Swatches.Danger} />
                  )}
                  {voidError && (
                    <Message text={voidError} color={Swatches.Danger} />
                  )}
                  {receiptError && (
                    <Message text={receiptError} color={Swatches.Danger} />
                  )}
                  <RoleView roles={[Constants.ROLES.SHOPKEEPER_ADMINISTRATOR]}>
                    <ActionPanel
                      label="Cancel Order"
                      sub="This will cancel this order, issue a refund and reverse any smartcard credits."
                      buttonText="Cancel Order"
                      buttonColor={Swatches.Danger}
                      onClick={handleCancelOrder}
                      working={cancelling}
                    />
                    <ActionPanel
                      label="Void Order"
                      sub="This will void this order and reverse any smartcard credits."
                      buttonText="Void Order"
                      buttonColor={Swatches.Danger}
                      onClick={() => handleVoidOrder(false)}
                      working={voiding}
                    />
                    <ActionPanel
                      label="Void Order and Refund"
                      sub="This will void this order, issue a refund and reverse any smartcard credits."
                      buttonText="Void Order & Refund"
                      buttonColor={Swatches.Danger}
                      onClick={() => handleVoidOrder(true)}
                      working={voiding}
                    />
                  </RoleView>
                  <ActionPanel
                    label="Resend Invoice"
                    sub="This will resend the invoice for this order by email"
                    buttonText="Resend Invoice"
                    buttonColor={Swatches.Primary}
                    onClick={handleResendInvoice}
                    working={sendingReceipt}
                  />
                </>
              ) : (
                <Card>
                  <Card.Body>
                    <Message
                      text="This order does not have any available actions."
                      icon="info-circle"
                    />
                  </Card.Body>
                </Card>
              )}
            </Tabs.Pane>
            <Tabs.Pane name="Payments" label="Payments">
              <PaymentsTable payments={order.payments} />
            </Tabs.Pane>
            <Tabs.Pane name="Refunds" label="Refunds">
              <RefundsTable order={order} />

              <CreateRefundFlyout
                order={order}
                onRefundCreated={handleRefundCreated}
              />
            </Tabs.Pane>
            <Tabs.Pane name="Basket" label="Original Basket">
              <BasketView basket={order.initialBasket} />
            </Tabs.Pane>
            <Tabs.Pane name="Smartcard Sync" label="Smartcard Sync">
              <SmartcardCreditFlyout
                credit={selectedSmartcardCredit}
                working={smartcardCreditWorking}
                error={smartcardCreditWorkingError}
              />
              <CreditsTable
                credits={order.smartcardCredits}
                onRowClicked={handleSmartcardCreditRowClicked}
              />
            </Tabs.Pane>
            <Tabs.Pane name="Print Credit Sync" label="Print Credit Sync">
              <SmartcardCreditFlyout
                credit={selectedPrintCredit}
                working={printCreditWorking}
                error={printCreditWorkingError}
              />
              <CreditsTable
                credits={order.printCredits}
                onRowClicked={handlePrintCreditRowClicked}
              />
            </Tabs.Pane>
          </Tabs>
        </>
      )}
    </LoadingWrapper>
  );
};

export default Order;
