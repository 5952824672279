import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import StaffList from "./staffList";
import { Loader, EmptyMessage, Size } from "ui-kit";
import managedStaffActions from "areas/humanResources/actions/managedStaffActions";
import { RootState } from "reducers/store";
import { StaffListView } from "types/users/userListViews.types";

const Wrapper = styled.div``;

interface IManagedStaffListProps {
  manager: any;
  onStaffMemberSelected?: (staffMember: StaffListView, index: number) => void;
  onLoaded?: (data: StaffListView[]) => void;
}

const ManagedStaffList: React.FC<IManagedStaffListProps> = ({
  manager,
  onStaffMemberSelected,
  onLoaded,
}) => {
  const { managedStaff, error, loading } = useSelector(
    (state: RootState) => state.managedStaff
  );

  useEffect(() => {
    manager &&
      managedStaffActions.getManagedStaff(manager.id, (data: StaffListView[]) =>
        onLoaded?.(data)
      );
  }, []);

  const handleStaffMemberSelected = (staffMember: StaffListView, index: number) => {
    onStaffMemberSelected?.(staffMember, index);
  };

  if (loading) {
    return (
      <Wrapper>
        <Loader size={Size.Large} cover />
      </Wrapper>
    );
  }

  if (error) {
    return (
      <Wrapper>
        <EmptyMessage
          icon="times-circle"
          title="A problem occurred"
          summary="There was a problem loading the reviews"
          cover
        />
      </Wrapper>
    );
  }

  return (
    <StaffList
      title="My Managed Staff"
      staff={managedStaff}
      onStaffMemberSelected={handleStaffMemberSelected}
      wrap
    />
  );
};

export default ManagedStaffList;
