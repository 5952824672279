import React, { useEffect, useState } from "react";
import {
  Modal,
  ActionBar,
  Message,
  ValidationMessage,
  Size,
  Swatches,
  Button,
  TextInput,
  TextInputType,
  RichTextEditor,
  Dropdown,
  ToastService,
  StructuredList,
  Right,
  Card,
  Spacing,
} from "ui-kit";
import {
  StudentPicker,
  StudentProfileHeader,
  Avatar,
} from "sharedComponents/common";
import styled from "styled-components";
import { arrays } from "utils";
import { useSelector } from "react-redux";
import provisionGroupsActions from "areas/send/actions/provisions/provisionGroupsActions";
import { RootState } from "reducers/store";
import { ProvisionGroupDetailView, ProvisionGroupListView, ProvisionRecordListView } from "areas/send/types/passportResponse.types";
import { SaveProvisionRecordCommand } from "areas/send/types/passportRequest.types";
import { StudentListView } from "types/users/userListViews.types";

const Wrapper = styled.div`
  display: flex;

  .content-wrapper {
    flex-grow: 1;
  }
`;

const StudentDetailsWrapper = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${Spacing.Default}px;
`;



interface IProvisionRecordModalProps {
  provisionGroup: ProvisionGroupListView;
  record: ProvisionRecordListView;
  onSave?: (record: ProvisionRecordListView) => void;
  onCancel?: () => void;
  open: boolean;
}


const ProvisionRecordModal: React.FC<IProvisionRecordModalProps> = ({
  provisionGroup,
  record,
  onSave,
  onCancel,
  open,
}) => {

  const { saveError, saving } = useSelector((state: RootState) => state.provisionRecord);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_open, _setOpen] = useState<boolean>(open);
  const [_studentError, _setStudentError] = useState<string | null>(null);
  const [_record, _setRecord] = useState<ProvisionRecordListView>(record);
  const [_provisionGroup, _setProvisionGroup] = useState<ProvisionGroupListView>(provisionGroup);
  const [_notes, _setNotes] = useState<string>("");
  const [_student, _setStudent] = useState<StudentListView | null>(null);

  useEffect(() => {
    setValidationErrors([]);
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    _setRecord(record);
    _setNotes(record ? record?.progress.notes : "");
  }, [record]);

  useEffect(() => {
    _setProvisionGroup(provisionGroup);
  }, [provisionGroup]);

  const handleCancel = () => {
    console.log("CANCELLING :DDD");
    _setNotes("");
    _setStudent(null);
    _setRecord(null);
    onCancel?.();
  };

  const handleStudentChange = (student: StudentListView) => {
    _setStudent(student);
  };

  const handleSave = () => {
    //Validate
    var errors: string[] = [];

    if (!_record.progress.target) {
      errors.push(`Please enter a target.`);
    }

    if (_record.progress.rating == null) {
      errors.push(`Please select a progress rating.`);
    }

    if (!_student && !_record.id) {
      errors.push(`Please choose a student.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    var data: SaveProvisionRecordCommand = {
      id: _record.id,
      groupId: _provisionGroup.id,
      provisionId: _provisionGroup.provision.id,
      providerId: _provisionGroup.provision.provider.id,
      notes: _notes,
      studentId: _student ? _student.id : _record.student.id,
      target: _record.progress.target,
      entryData: _record.progress.entryData,
      exitData: _record.progress.exitData,
      rating: _record.progress.rating,
    };

    if (!_record.id) {
      provisionGroupsActions.createProvisionRecord(data, () => {
        ToastService.pop(
          "Provision Record Created",
          null,
          "hand-holding-seedling"
        );
        onSave?.(_record);
        _setNotes("");
        _setStudent(null);
      });
    } else {
      provisionGroupsActions.saveProvisionRecord(data, () => {
        ToastService.pop(
          "Provision Record Saved",
          null,
          "hand-holding-seedling"
        );
        onSave?.(_record);
        _setNotes("");
        _setStudent(null);
      });
    }
  };

  // need this to destroy modal on close to clear rtf
  if (_record == null) {
    return <></>;
  }

  return (
    <Modal
      title={
        <>
          {record?.id
            ? record?.student.firstName + " " + record?.student.lastName
            : "New Student Record"}
        </>
      }
      open={_open}
      onClose={handleCancel}
      width="90%"
      height="90%"
    >
      <Modal.Body>
        <Wrapper>
          {_record?.student && (
            <StudentDetailsWrapper>
              <StudentProfileHeader student={_record.student} />
            </StudentDetailsWrapper>
          )}
          <div className="content-wrapper">
            <ValidationMessage errors={validationErrors} />
            <Message text={saveError} color={Swatches.Danger} />
            {!_record?.student && (
              <Card title="Student">
                <Card.Body>
                  <StructuredList>
                    <StructuredList.Item
                      name="Student"
                      rightContent={
                        _student && (
                          <Button
                            text="Change"
                            color={Swatches.Low}
                            size={Size.Small}
                            onClick={() => _setStudent(null)}
                          />
                        )
                      }
                    >
                      <Message text={_studentError} color={Swatches.Danger} />

                      {!_student ? (
                        <StudentPicker
                          onChange={(value: StudentListView) => handleStudentChange(value)}
                          fluid
                        />
                      ) : (
                        <Avatar user={_student} sub={_student.simsId} />
                      )}
                    </StructuredList.Item>
                  </StructuredList>
                </Card.Body>
              </Card>
            )}

            <Card title="Target & Progress">
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="Target" required>
                    <TextInput
                      onChange={value =>
                        _setRecord({
                          ..._record,
                          progress: { ..._record.progress, target: value },
                        })
                      }
                      value={_record?.progress.target}
                      fluid
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="Progress Rating" required>
                    <Dropdown
                      onChange={value =>
                        _setRecord({
                          ..._record,
                          progress: { ..._record.progress, rating: value },
                        })
                      }
                      value={_record?.progress.rating}
                      fluid
                    >
                      <Dropdown.Item value={0} label="0 : Expected Progress" />
                      <Dropdown.Item
                        value={1}
                        label="+1 : Above Expected Progress"
                      />
                      <Dropdown.Item
                        value={2}
                        label="-1 : Below Expected Progress"
                      />
                    </Dropdown>
                  </StructuredList.Item>
                  <StructuredList.Item name="Entry Data">
                    <TextInput
                      type={TextInputType.Textarea}
                      onChange={value =>
                        _setRecord({
                          ..._record,
                          progress: { ..._record.progress, entryData: value },
                        })
                      }
                      value={_record?.progress.entryData}
                      maxLength={100}
                      characterCount
                      rows={3}
                      fluid
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="Exit Data">
                    <TextInput
                      type={TextInputType.Textarea}
                      onChange={value =>
                        _setRecord({
                          ..._record,
                          progress: { ..._record.progress, exitData: value },
                        })
                      }
                      value={_record?.progress.exitData}
                      maxLength={100}
                      characterCount
                      rows={3}
                      fluid
                    />
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
            <Card title="Notes">
              <Card.Body noPad>
                <RichTextEditor
                  noPad
                  initialValue={_notes}
                  onChange={value => _setNotes(value)}
                />
              </Card.Body>
            </Card>
          </div>
        </Wrapper>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              onClick={handleCancel}
              size={Size.Small}
              color={Swatches.Low}
              text="Cancel"
              working={saving}
            />
            <Button
              onClick={handleSave}
              size={Size.Small}
              color={Swatches.Success}
              text="Save"
              working={saving}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default ProvisionRecordModal;
