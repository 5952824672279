import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Dropdown,
  StructuredList,
  ValidationMessage,
  Button,
  Swatches,
  List,
  formatScrollbars,
  Spacing,
  ActionBar,
  Size,
  Left,
  Right,
  TextInput,
  DatePicker,
  neutral,
} from "ui-kit";
import { Constants } from "configuration";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { users } from "utils";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import { RootState } from "reducers/store";
import SyncFailureReportTable from "./SyncFailureReportTable";
import syncActions from "areas/administration/actions/syncAction";
import SyncLogView from "./SyncLogView";
import SyncUserWarningTable from "./SyncUserWarningTable";
import {
  ObjectType,
  objectTypeDescriptions,
} from "areas/administration/types/syncResponses.types";
import {
  SyncReportsFilter,
  SyncUserWarningFilter,
  UserType,
} from "areas/administration/types/syncRequest.types";
import { IRole } from "configuration/constants.types";

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;

  .left {
    padding-right: ${Spacing.Default}px;
    flex-direction: column;
    width: 13rem;
    max-width: 13rem;
    margin-right: ${Spacing.Default}px;
    overflow-y: auto !important;
  }

  .right {
    flex-grow: 1;
  }

  ${formatScrollbars(neutral[200])}
`;

const UserTypeDescriptions: Record<UserType, string> = {
  [UserType.student]: "Student",
  [UserType.parent]: "Parent",
  [UserType.staff]: "Staff",
};

interface SyncReport {
  name: string;
  value: number;
  roles: IRole[];
}

const REPORTS: SyncReport[] = [
  {
    name: "Failure Report",
    value: 0,
    roles: [
      Constants.ROLES.DEVELOPMENT,
      Constants.ROLES.IT_ADMINISTRATOR,
      Constants.ROLES.IT_USER,
      Constants.ROLES.SYNCDATA_ADMINISTRATOR,
    ],
  },
  // {
  //   name: "Sync Log",
  //   value: 1,
  //   roles: [
  //     Constants.ROLES.DEVELOPMENT,
  //     Constants.ROLES.IT_ADMINISTRATOR,
  //     Constants.ROLES.IT_USER,
  //     Constants.ROLES.SYNCDATA_ADMINISTRATOR,
  //   ],
  // },
  {
    name: "User Warnings",
    value: 2,
    roles: [
      Constants.ROLES.DEVELOPMENT,
      Constants.ROLES.IT_ADMINISTRATOR,
      Constants.ROLES.IT_USER,
      Constants.ROLES.SYNCDATA_ADMINISTRATOR,
    ],
  },
];

const Sync = () => {
  const { user } = useSelector((state: RootState) => state.currentUser);
  const reportRef = useRef<HTMLDivElement>(null);
  const logRef = useRef<HTMLDivElement>(null);
  const userWarningRef = useRef<HTMLDivElement>(null);

  const {report, loading: loadingFailures, paging} = useSelector((state: RootState) => state.syncFailureReport);
  const { loading: loadingLog, logData } = useSelector((state: RootState) => state.syncLog);
  const {loading: loadingWarnings,warnings,paging: userWarningsPaging} = useSelector((state: RootState) => state.userWarning);

  const [filter, setFilter] = useState<SyncReportsFilter>({
    schoolId: user?.baseSchool.id,
    type: null,
    simsId: null,
  });

  const [userWarningFilter, setUserWarningFilter] = useState<SyncUserWarningFilter>({
      schoolId: user?.baseSchool.id,
      searchTerm: null,
      userType: null,
    });

  const [logDate, setLogDate] = useState<Date>();
  const [selectedReport, setSelectedReport] = useState<SyncReport>(REPORTS[0]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_loading, _setLoading] = useState<boolean>(false);

  const handleRunReport = (exportReport: boolean) => {
    const pageIndex = 0;
    switch (selectedReport.value) {
      case 0:
        _setLoading(true);
        syncActions.getSyncFailureReport(filter, pageIndex, exportReport, () =>
          _setLoading(false)
        );
        break;
      case 1:
        _setLoading(true);
        if (!exportReport)
          syncActions.getSyncLog(logDate, () => _setLoading(false));
        break;
      case 2:
        _setLoading(true);
        syncActions.getWarningsForUser(
          userWarningFilter.schoolId,
          userWarningFilter,
          pageIndex,
          exportReport,
          () => _setLoading(false)
        );
        break;
    }
  };

  const handlePage = () => {
    if (selectedReport.value === 0) {
      syncActions.getSyncFailureReport(filter, paging.pageIndex + 1);
    } else if (selectedReport.value === 2) {
      syncActions.getWarningsForUser(
        userWarningFilter.schoolId,
        userWarningFilter,
        userWarningsPaging.pageIndex + 1
      );
    }
  };

  useEffect(() => {
    if (loadingFailures && reportRef.current) {
      reportRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loadingFailures, report]);

  useEffect(() => {
    if (loadingLog && logRef.current) {
      logRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loadingLog, logData]);

  useEffect(() => {
    if (loadingWarnings && userWarningRef.current) {
      userWarningRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loadingWarnings, warnings]);

  return (
    <Wrapper>
      <Left>
        <List title="Available Reports">
          {REPORTS.filter((x) => users.isInAnyRoles(user, x.roles)).map(
            (report, index) => (
              <List.Item
                text={report.name}
                key={index}
                selected={selectedReport.value === report.value}
                onClick={() => setSelectedReport(report)}
              />
            )
          )}
        </List>
      </Left>
      <Right>
        <Card title={selectedReport.name}>
          <Card.Body>
            <ValidationMessage errors={validationErrors} />
            <StructuredList>
              {selectedReport.value === 0 && (
                <>
                  <StructuredList.Item name="School">
                    <SchoolDropdown
                      includeTrust={false}
                      initialValue={filter.schoolId}
                      includeAllOption
                      fluid
                      schools={user.schools}
                      onChange={(val) =>
                        setFilter({ ...filter, schoolId: val })
                      }
                    />
                  </StructuredList.Item>
                  <StructuredList.Item
                    description={"Enter Student Admission Number or Sims ID"}
                    name="Sims Id"
                  >
                    <TextInput
                      value={filter.simsId}
                      onChange={(e) => setFilter({ ...filter, simsId: e })}
                      placeholder="Enter Sims Id"
                    />
                  </StructuredList.Item>
                  <StructuredList.Item name="Type">
                    <Dropdown
                      value={filter.type}
                      fluid
                      onChange={(e, data) => setFilter({ ...filter, type: e })}
                    >
                      {[
                        <Dropdown.Item
                          key="all"
                          label="All Types"
                          value={null}
                        />,
                        ...Object.keys(ObjectType)
                          .filter((key) => isNaN(Number(key)))
                          .map((key) => {
                            const enumValue =
                              ObjectType[key as keyof typeof ObjectType];
                            return (
                              <Dropdown.Item
                                key={enumValue}
                                label={objectTypeDescriptions[enumValue]}
                                value={enumValue}
                              />
                            );
                          }),
                      ]}
                    </Dropdown>
                  </StructuredList.Item>
                </>
              )}
              {selectedReport.value === 1 && (
                <StructuredList.Item name="Log File Date">
                  <DatePicker
                    dateFormat="DD/MM/YYYY"
                    closeOnSelect
                    selectedDate={logDate}
                    onChange={(value) => setLogDate(value)}
                  />
                </StructuredList.Item>
              )}
              {selectedReport.value === 2 && (
                <>
                  <StructuredList.Item required name="School">
                    <SchoolDropdown
                      includeTrust={false}
                      initialValue={userWarningFilter.schoolId}
                      includeAllOption
                      fluid
                      schools={user.schools}
                      onChange={(val) =>
                        setUserWarningFilter({
                          ...userWarningFilter,
                          schoolId: val,
                        })
                      }
                    />
                  </StructuredList.Item>
                  <StructuredList.Item
                    description={
                      "Search by First Name, Last Name, Student Admission Number (Sims ID), User Id or Wonde ID. Only one term at a time"
                    }
                    name="Search Term"
                  >
                    <TextInput
                      value={userWarningFilter.searchTerm}
                      onChange={(e) =>
                        setUserWarningFilter({
                          ...userWarningFilter,
                          searchTerm: e.trim(),
                        })
                      }
                      placeholder="Search Term"
                    />
                  </StructuredList.Item>

                  <StructuredList.Item name="User Type">
                    <Dropdown
                      value={userWarningFilter.userType}
                      fluid
                      onChange={(e, data) =>
                        setUserWarningFilter({
                          ...userWarningFilter,
                          userType: e,
                        })
                      }
                    >
                      {[
                        <Dropdown.Item
                          key="all"
                          label="All Types"
                          value={null}
                        />,
                        ...Object.keys(UserType)
                          .filter((key) => isNaN(Number(key)))
                          .map((key) => {
                            const enumValue =
                              UserType[key as keyof typeof UserType];
                            return (
                              <Dropdown.Item
                                key={enumValue}
                                label={UserTypeDescriptions[enumValue]}
                                value={enumValue}
                              />
                            );
                          }),
                      ]}
                    </Dropdown>
                  </StructuredList.Item>
                </>
              )}
            </StructuredList>
          </Card.Body>
          <Card.Footer>
            <ActionBar low>
              <Button
                text="Generate Report"
                color={Swatches.Success}
                size={Size.Small}
                onClick={() => handleRunReport(false)}
                working={_loading}
              />

              <Right>
                {selectedReport.value !== 1 && (
                  <Button
                    icon="file-download"
                    text="Export Report as CSV"
                    color={Swatches.Primary}
                    size={Size.Small}
                    onClick={() => handleRunReport(true)}
                    working={_loading}
                  />
                )}
              </Right>
            </ActionBar>
          </Card.Footer>
        </Card>

        {selectedReport.value === 0 && (
          <div ref={reportRef}>
            <SyncFailureReportTable
              handlePage={handlePage}
              loading={_loading}
            />
          </div>
        )}

        {selectedReport.value === 1 && (
          <SyncLogView Data={logData?.lines} loading={_loading} />
        )}

        {selectedReport.value === 2 && (
          <div ref={userWarningRef}>
            <SyncUserWarningTable handlePage={handlePage} loading={_loading} />
          </div>
        )}
      </Right>
    </Wrapper>
  );
};

export default Sync;
