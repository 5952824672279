import React from "react";
import { Table, Swatches } from "ui-kit";
import BehaviourCountChip from "../behaviour/behaviourCountChip";
import { Link } from "react-router-dom";
import { Routes } from "configuration";
import { arrays } from "utils";
import Avatar from "sharedComponents/common/users/avatar";
import { studentRoutes } from "../../../planner/plannerRoutes";
import { BehaviourReportView } from "areas/analytics/types/classGroupBehaviourAnalyticsResponse.types";


interface IClassBehaviourTableProps {
  report: BehaviourReportView;
}


const ClassBehaviourTable: React.FC<IClassBehaviourTableProps> = ({ report }) => {
  return (
    <>
      {!arrays.isEmpty(report.studentBehaviours) && (
        <Table>
          <Table.Header>
            <Table.HeaderCell>Student</Table.HeaderCell>
            <Table.HeaderCell center>Rewards</Table.HeaderCell>
            <Table.HeaderCell center>Behaviours</Table.HeaderCell>
            <Table.HeaderCell center>Behaviour Count</Table.HeaderCell>
          </Table.Header>

          <>
          {report.studentBehaviours.map(studentBehaviour => (
            <Table.Row>
              <Table.Cell>
                <Link
                  to={`${Routes.home}${studentRoutes.getStudentPath(
                    studentBehaviour.student.id
                  )}`}
                >
                  <Avatar
                    user={studentBehaviour.student}
                    sub={studentBehaviour.student.simsId}
                  />
                  {/* <b>{`${studentBehaviour.student.firstName} ${studentBehaviour.student.lastName}`}</b> */}
                </Link>
              </Table.Cell>
              <Table.Cell center>
                <BehaviourCountChip
                  count={studentBehaviour.totalRewardPoints}
                  color={Swatches.Success}
                />
              </Table.Cell>
              <Table.Cell center>
                <BehaviourCountChip
                  count={studentBehaviour.totalBehaviourPoints}
                  color={Swatches.Danger}
                />
              </Table.Cell>
              <Table.Cell center>
                <BehaviourCountChip
                  count={studentBehaviour.totalBehaviourCount}
                  color={Swatches.Danger}
                />
              </Table.Cell>
            </Table.Row>
          ))}
          </>
        </Table>
      )}

      <br />

      {report.totalRewardPoints > 0 && (
        <Table>
          <Table.Header>
            <Table.HeaderCell>Rewards</Table.HeaderCell>
            <Table.HeaderCell center>
              <BehaviourCountChip
                count={report.totalRewardPoints}
                color={Swatches.Success}
              />
            </Table.HeaderCell>
          </Table.Header>

          <>
          {report.totalRewardsByType.map(reward => (
            <Table.Row>
              <Table.Cell>
                <b>{`${reward.codeName} - ${reward.categoryName}`}</b>
              </Table.Cell>
              <Table.Cell center>
                <BehaviourCountChip
                  count={reward.total}
                  color={Swatches.Success}
                />
              </Table.Cell>
            </Table.Row>
          ))}
          </>
        </Table>
      )}
      <br />
      {report.totalBehaviourPoints > 0 && (
        <Table>
          <Table.Header>
            
            <Table.HeaderCell>Behaviours</Table.HeaderCell>
            <Table.HeaderCell center>Points</Table.HeaderCell>
            <Table.HeaderCell center>Counts</Table.HeaderCell>
            </Table.Header>
            <Table.Header>
            <Table.HeaderCell>Total</Table.HeaderCell>
            <Table.HeaderCell center>
              <BehaviourCountChip
                count={report.totalBehaviourPoints}
                color={Swatches.Danger}
              />
            </Table.HeaderCell>
            <Table.HeaderCell center>
              <BehaviourCountChip
                count={report.totalBehaviourCount}
                color={Swatches.Danger}
              />
            </Table.HeaderCell>
          </Table.Header>

          <>
          {report.totalBehavioursByType.map(behaviour => (
            <Table.Row>
              <Table.Cell>
                <b>{`${behaviour.codeName} - ${behaviour.categoryName}`}</b>
              </Table.Cell>
              <Table.Cell center>
                <BehaviourCountChip
                  count={behaviour.total}
                  color={Swatches.Danger}
                />
              </Table.Cell>
              <Table.Cell center>
                <BehaviourCountChip
                  count={behaviour.totalCount}
                  color={Swatches.Danger}
                />
              </Table.Cell>
            </Table.Row>
          ))}
          </>
        </Table>
      )}
    </>
  );
};

export default ClassBehaviourTable;
