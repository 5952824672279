import { IBeehiveError } from "types/common/errors.types";
import studentBehaviourReportActions from "../../planner/actions/reports/studentBehaviourReportActions";
import { StudentBehaviourReportView } from "areas/planner/types/plannerReportResponse.types";
import { IBeehiveAction } from "types/common/action.types";

interface IStudentBehaviourReportState {
  report: StudentBehaviourReportView | null;
  refresh: boolean;
  loading: boolean;
  exporting: boolean;
  error: IBeehiveError | null;
}

const INITIAL_STATE: IStudentBehaviourReportState = {
  report: null,
  refresh: false,
  loading: false,
  exporting: false,
  error: null,
};

const studentBehaviourReportReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IStudentBehaviourReportState => {

  const { 
    GETSTUDENTBEHAVIOURS, 
    GETSTUDENTBEHAVIOURSBYTIME,
    DOWNLOADSTUDENTBEHAVIOURS
  } = studentBehaviourReportActions.types;

  switch (action.type) {

    case GETSTUDENTBEHAVIOURS.START:
      return { ...state, loading: true, error: null };
    case GETSTUDENTBEHAVIOURS.SUCCESS:
      return { ...state, report: action.payload, loading: false };
    case GETSTUDENTBEHAVIOURS.FAILED:
      return { ...state, loading: false, error: action.payload };

    case GETSTUDENTBEHAVIOURSBYTIME.START:
      return { ...state, loading: true, error: null };
    case GETSTUDENTBEHAVIOURSBYTIME.SUCCESS:
      return { ...state, loading: false };
    case GETSTUDENTBEHAVIOURSBYTIME.FAILED:
      return { ...state, loading: false, error: action.payload };

    case DOWNLOADSTUDENTBEHAVIOURS.START:
      return { ...state, exporting: true, error: null };
    case DOWNLOADSTUDENTBEHAVIOURS.SUCCESS:
      return { ...state, exporting: false };
    case DOWNLOADSTUDENTBEHAVIOURS.FAILED:
      return { ...state, exporting: false, error: action.payload };

    default:
      return state;
  }
};

export default studentBehaviourReportReducer;
