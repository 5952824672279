import config from "configuration";
import { IBeehiveAction } from "types/common/action.types";


interface IAlertsState {
  message: string | null;
}

const INITIAL_STATE = {
  message: config.alerts.showDefaultAlert ? config.alerts.defaultAlert : null
};


const alertReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAlertsState => {

  switch (action.type) {
    default:
      return state;
  }
};

export default alertReducer;
