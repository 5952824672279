import routes from "configuration/routes";

export const leaveRoutes = {
  default: "/leave",
  staffLeave: "/leave/administration/staff/:staffId",
  getStaffLeavePath: (userId: string) => `${routes.home}/leave/administration/staff/${userId}`,
};

export const pmRoutes = {
  default: "/pm",
  staffReview: "staff/:revieweeId/reviews/:reviewId",
  getStaffReviewPath: (revieweeId: string, reviewId: number) => `${routes.home}/pm/staff/${revieweeId}/reviews/${reviewId}`,
};
