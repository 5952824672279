import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import store from "reducers/store";
import client from "services/client";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { urls } from "utils";
import { ApproveBulletinCommand, ArticleFilter, BulletinFilter, SaveBulletinCommand, SaveBulletinTemplateCommand, SendBulletinCommand, TestSendBulletinCommand } from "../types/newsRequest.types";
import { ArticleListView, BulletinDetailView, BulletinFullView, BulletinListView, BulletinNotificationView, BulletinSendHistoryStatisticsDetail, BulletinSendHistoryStatisticsSummary, BulletinTemplateDetailView, BulletinTemplateListView } from "../types/newsResponse.types";
import { PagedQueryView } from "types/common/views.types";
import { StaffListView } from "types/users/userListViews.types";

const prefix = "bulletins";
const builder = new actionTypeBuilder(prefix);

const types = {
  BULLETIN_SETFILTER: "BULLETIN_SETFILTER",
  BULLETINS_GET: builder.build("BULLETINS_GET"),
  BULLETIN_GET: builder.build("BULLETIN_GET"),
  BULLETIN_GETFULL: builder.build("BULLETIN_GETFULL"),
  BULLETIN_GETSENDHISTORYSTATISTICS: builder.build(
    "BULLETIN_GETSENDHISTORYSTATISTICS"
  ),
  BULLETIN_CREATE: builder.build("BULLETIN_CREATE"),
  BULLETIN_SAVE: builder.build("BULLETIN_SAVE"),
  BULLETIN_APPROVE: builder.build("BULLETIN_APPROVE"),
  BULLETIN_SEND: builder.build("BULLETIN_SEND"),
  BULLETIN_TESTSEND: builder.build("BULLETIN_TESTSEND"),
  BULLETIN_GETARTICLES: builder.build("BULLETIN_GETARTICLES"),
  BULLETIN_PREVIEW: builder.build("BULLETIN_PREVIEW"),
  BULLETIN_UPDATETESTRECIPIENTS: "BULLETIN_UPDATETESTRECIPIENTS",

  BULLETINTEMPLATES_GET: builder.build("BULLETINTEMPLATES_GET"),
  BULLETINTEMPLATE_GET: builder.build("BULLETINTEMPLATE_GET"),
  BULLETINTEMPLATE_CREATE: builder.build("BULLETINTEMPLATE_CREATE"),
  BULLETINTEMPLATE_SAVE: builder.build("BULLETINTEMPLATE_SAVE"),
};


const setBulletinFilter = (filter: BulletinFilter) => {
  store.dispatch<IBeehiveAction<BulletinFilter>>({
    type: types.BULLETIN_SETFILTER,
    payload: filter,
  });
};

const getBulletinTemplates = (callback?: callbackType<BulletinTemplateListView[]>) =>
  action<BulletinTemplateListView[]>(
    () => client.get(`news/bulletins/templates`),
    types.BULLETINTEMPLATES_GET,
    callback
  );

const getBulletinTemplate = (id: number, callback?: callbackType<BulletinTemplateDetailView>) =>
  action<BulletinTemplateDetailView>(
    () => client.get(`news/bulletins/templates/${id}`),
    types.BULLETINTEMPLATE_GET,
    callback
  );

const getBulletins = (callback?: callbackType<BulletinListView[]>) =>
  action<BulletinListView[]>(
    () => client.get(`news/bulletins`), 
    types.BULLETINS_GET, 
    callback
  );

const getBulletin = (id: number, callback?: callbackType<BulletinDetailView>) =>
  action<BulletinDetailView>(
    () => client.get(`news/bulletins/${id}`),
    types.BULLETIN_GET,
    callback
  );

const getBulletinFull = (id: number, callback?: callbackType<BulletinFullView>) =>
  action<BulletinFullView>(
    () => client.get(`news/bulletins/${id}/full`),
    types.BULLETIN_GETFULL,
    callback
  );

const getBulletinSendHistoryStatistics = (id: number, sendId: number, filter: BulletinFilter, callback?: callbackType<BulletinSendHistoryStatisticsDetail>) => {
  let _filter = {
    ...filter,
    schoolId: filter.schoolId == -1 ? null : filter.schoolId,
  };

  let url = new urls.QueryString(`news/bulletins/${id}/send/${sendId}`);
  url.addParams(_filter);

  return action<BulletinSendHistoryStatisticsDetail>(
    () => client.get(url.toUrl()),
    types.BULLETIN_GETSENDHISTORYSTATISTICS,
    callback
  );
};

const createBulletin = (command: SaveBulletinCommand, callback?: callbackType<BulletinDetailView>) =>
  action<BulletinDetailView>(
    () => client.post(`news/bulletins`, command),
    types.BULLETIN_CREATE,
    callback
  );

const saveBulletin = (command: SaveBulletinCommand, callback?: callbackType<BulletinDetailView>) =>
  action<BulletinDetailView>(
    () => client.post(`news/bulletins/${command.id}`, command),
    types.BULLETIN_SAVE,
    callback
  );

const sendBulletin = (command: SendBulletinCommand, callback?: callbackType<BulletinSendHistoryStatisticsSummary>) =>
  action<BulletinSendHistoryStatisticsSummary>(
    () => client.post(`news/bulletins/${command.id}/send`, command),
    types.BULLETIN_SEND,
    callback
  );

const testSendBulletin = (command: TestSendBulletinCommand, callback?: callbackType<BulletinSendHistoryStatisticsSummary>) =>
  action<BulletinSendHistoryStatisticsSummary>(
    () => client.post(`news/bulletins/test`, command),
    types.BULLETIN_TESTSEND,
    callback
  );

const previewBulletin = (view: BulletinNotificationView, callback?: callbackType<string>) =>
  action<string>(
    () => client.post(`news/bulletins/preview`, view),
    types.BULLETIN_PREVIEW,
    callback
  );

const approveBulletin = (command: ApproveBulletinCommand, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`news/bulletins/${command.id}/approve`, command),
    types.BULLETIN_APPROVE,
    callback
  );

const createBulletinTemplate = (command: SaveBulletinTemplateCommand, callback?: callbackType<BulletinTemplateDetailView>) =>
  action<BulletinTemplateDetailView>(
    () => client.post(`news/bulletins/templates`, command),
    types.BULLETINTEMPLATE_CREATE,
    callback
  );

const saveBulletinTemplate = (command: SaveBulletinTemplateCommand, callback?: callbackType<BulletinTemplateDetailView>) =>
  action<BulletinTemplateDetailView>(
    () => client.post(`news/bulletins/templates/${command.id}`, command),
    types.BULLETINTEMPLATE_SAVE,
    callback
  );

const getArticlesForBulletin = (filter: ArticleFilter, pageIndex: number, callback?: callbackType<PagedQueryView<ArticleListView>>) => {
  console.log(filter);
  let url = `news/articles?pageIndex=${pageIndex}`;

  url =
    filter?.categoryId && filter?.categoryId > 0
      ? url + `&categoryId=${filter.categoryId}`
      : url;
  url =
    filter?.publishStatus >= 0 && filter?.publishStatus > -1
      ? url + `&publishStatus=${filter.publishStatus}`
      : url;
  url =
    filter?.schoolId && filter?.schoolId > -1
      ? url + `&schoolId=${filter.schoolId}`
      : url;

  return action<PagedQueryView<ArticleListView>>(
    () => client.get(url), 
    types.BULLETIN_GETARTICLES, 
    callback
  );
};

const updateTestRecipients = (recipients: StaffListView[]) => {
  store.dispatch<IBeehiveAction<StaffListView[]>>({
    type: types.BULLETIN_UPDATETESTRECIPIENTS,
    payload: recipients,
  });
};


const bulletinActions = {
  types,
  setBulletinFilter,
  getBulletinTemplates,
  getBulletinTemplate,
  getBulletins,
  getBulletin,
  getBulletinFull,
  getBulletinSendHistoryStatistics,
  createBulletin,
  saveBulletin,
  sendBulletin,
  testSendBulletin,
  previewBulletin,
  approveBulletin,
  createBulletinTemplate,
  saveBulletinTemplate,
  getArticlesForBulletin,
  updateTestRecipients,
};

export default bulletinActions;
