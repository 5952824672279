import inventoryFilterActions from "areas/payments/actions/inventoryFilterActions";
import { ProductCategoryListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface InventoryFilterType {
  category: ProductCategoryListView,
  active: number;
  onSale: number;
  schoolId: number;
}

export interface IInventoryFilterState {
  loading: boolean;
  error: string | null;
  filter: InventoryFilterType;
}

const DEFAULT_CATEGORY: ProductCategoryListView = {
  id: -1,
  isActive: false,
  icon: "",
  school: undefined,
  name: ""
};

const INITIAL_STATE: IInventoryFilterState = {
  loading: false,
  error: null,
  filter: {
    category: DEFAULT_CATEGORY,
    active: 1,
    onSale: -1,
    schoolId: -1
  }
};

const inventoryFilterReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IInventoryFilterState => {
  const {
    INVENTORY_FILTERBYCATEGORY,
    INVENTORY_FILTERBYSCHOOL,
    INVENTORY_FILTERBYACTIVE
  } = inventoryFilterActions.inventoryFilterTypes;

  switch (action.type) {
    case INVENTORY_FILTERBYCATEGORY:
      return {
        ...state,
        filter: { ...state.filter, category: action.payload }
      };

    case INVENTORY_FILTERBYSCHOOL:
      return {
        ...state,
        filter: {
          ...state.filter,
          schoolId: action.payload,
          category: DEFAULT_CATEGORY
        }
      };

    case INVENTORY_FILTERBYACTIVE:
      return {
        ...state,
        filter: { ...state.filter, active: action.payload }
      };

    default:
      return state;
  }
};

export default inventoryFilterReducer;
