import React, { useState } from "react";
import {
  Table,
  Currency,
  Label,
  Button,
  Size,
  Swatches,
  DateTime,
  EmptyMessage
} from "ui-kit";
import { useNavigate } from "react-router-dom";
import OrderStatus from "./orderStatus";
import OrderFlyout from "./orderFlyout";
import { Constants } from "configuration";
import flyoutActions from "actions/ui/flyouts";
import { arrays } from "utils";
import { OrderListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


interface IOrdersTableProps {
  orders: OrderListView[];
  error?: boolean;
  loading?: boolean;
}


const OrdersTable: React.FC<IOrdersTableProps> = ({ orders, error, loading }) => {

  const [selectedOrder, setSelectedOrder] = useState<OrderListView>(null);
  const navigate = useNavigate();

  const handleRowClicked = (order: OrderListView) => {
    setSelectedOrder(order);
    flyoutActions.openFlyout(Constants.FLYOUTS.ORDER);
  };

  const handleOrderDetailsClicked = (e: React.MouseEvent, order: OrderListView) => {
    e.stopPropagation();
    navigate(`/main/shopkeeper/users/${order.user.id}/orders/${order.id}`);
  };

  return (
    <>
      <Table
        zebra
        empty={arrays.isEmpty(orders)}
        error={error}
        loading={loading}
        emptyMessage={
          <EmptyMessage
            icon="receipt"
            title="No Orders Found"
            summary="No orders have been placed yet"
            cover
          />
        }
        grow
      >
        <Table.Header>
          <Table.HeaderCell width={0.75}>Status</Table.HeaderCell>
          <Table.HeaderCell width={0.5}>Order No.</Table.HeaderCell>
          <Table.HeaderCell width={1}>Date</Table.HeaderCell>
          <Table.HeaderCell right width={1}>
            Total
          </Table.HeaderCell>
          <Table.HeaderCell right width={1}>
            Paid
          </Table.HeaderCell>
          <Table.HeaderCell width={1} right>
            Outstanding
          </Table.HeaderCell>
          <Table.HeaderCell width={1} right>
            Refunded
          </Table.HeaderCell>
          <Table.HeaderCell width={1} right>
            Basket ID
          </Table.HeaderCell>
          <Table.HeaderCell width={1} />
        </Table.Header>
        <Table.Body>
          {orders.map((order, index) => (
            <Table.Row key={index} onClick={() => handleRowClicked(order)}>
              <Table.Cell width={0.75} chip>
                <OrderStatus status={order.status} />
              </Table.Cell>
              <Table.Cell width={0.5}>{order.id}</Table.Cell>
              <Table.Cell width={1}>
                <DateTime bold date={order.date} />
              </Table.Cell>
              <Table.Cell right width={1}>
                <Label bold>
                  <Currency value={order.total} />
                </Label>
              </Table.Cell>
              <Table.Cell right width={1}>
                <Currency value={order.paid} />
              </Table.Cell>
              <Table.Cell right width={1}>
                <Currency value={order.outstanding} />
              </Table.Cell>
              <Table.Cell right width={1}>
                <Currency value={order.refunded} />
              </Table.Cell>
              <Table.Cell right width={1}>
                {order.initialBasketId}
              </Table.Cell>
              <Table.Cell right width={1}>
                <Button
                  color={Swatches.Low}
                  text="Details"
                  size={Size.Small}
                  onClick={e => handleOrderDetailsClicked(e, order)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <OrderFlyout order={selectedOrder} />
    </>
  );
};

export default OrdersTable;
