import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import moment from "moment";
import { validations } from "utils";
import client from "services/client";
import FileDownload from "js-file-download";
import { BeehiveReport, callbackType } from "types/common/action.types";
import { DetentionReportFilter } from "../types/detentionAnalyticsRequest.types";
import { DetentionReportView, DetentionsDetailReportListView } from "../types/detentionAnalyticsResponse.types";
import { PagedQueryView } from "types/common/views.types";

const builder = new actionTypeBuilder("analytics");

const types = {
  GETYEARGROUPDETENTIONREPORT: builder.build("getyeargroupdetentionreport"),
  EXPORTYEARGROUPDETENTIONREPORT: builder.build(
    "exportyeargroupdetentionreport"
  ),
  GETDETENTIONSDETAILEDREPORT: builder.build("getdetentiondetailedreport"),
  EXPORTDETENTIONSDETAILEDREPORT: builder.build(
    "exportdetentiondetailedreport"
  ),
};

export const getYearGroupDetentionReport = <T extends boolean = false>(
  {
    schoolId,
    startDate,
    endDate,
    yearGroupId,
    statusId,
    codeId,
    academicYearId
  }: DetentionReportFilter,
  exportReport?: T,
  onSuccess?: callbackType<T extends true ? BeehiveReport : DetentionReportView>
) => {

  var url: string;

  if (exportReport) {
    url = `planner/reporting/detentionReport/export?SchoolId=${schoolId}&yearGroupId=${yearGroupId}&academicYearId=${academicYearId}`;
  } else {
    url = `planner/reporting/detentionReport?SchoolId=${schoolId}&yearGroupId=${yearGroupId}&academicYearId=${academicYearId}`;
  }

  if (startDate) {
    url = url + `&startDate=${moment(startDate).format("YYYY-MM-DD")}`;
  }

  if (endDate) {
    url = url + `&endDate=${moment(endDate).format("YYYY-MM-DD")}`;
  }

  if (validations.notNullOrUndefined(statusId)) {
    url = url + `&statusId=${statusId}`;
  }

  if (validations.notNullOrUndefined(codeId)) {
    url = url + `&codeId=${codeId}`;
  }

  return exportReport
    ? action<BeehiveReport>(
        () => client.get(url, { responseType: "blob" }),
        types.EXPORTYEARGROUPDETENTIONREPORT,
        response => {
          onSuccess?.(response as any);
          FileDownload(response, "Year Group Detention Report.csv");
        }
      )
    : action<DetentionReportView>(
        () => client.get(url),
        types.GETYEARGROUPDETENTIONREPORT,
        response => onSuccess?.(response as any)
      );
};


export const getDetentionDetailedReport = <T extends boolean = false>(
  {
    schoolId,
    startDate,
    endDate,
    statusId,
    academicYearId,
  }: DetentionReportFilter,
  pageIndex: number,
  exportReport: T,
  onSuccess?: callbackType<T extends true ? BeehiveReport : PagedQueryView<DetentionsDetailReportListView>>
) => {

  var queryEndPoint: string;

  if (exportReport) {
    queryEndPoint = `planner/reporting/detentionsDetailReportExport?SchoolId=${schoolId}&academicYearId=${academicYearId}`;
  } else {
    queryEndPoint = `planner/reporting/detentionsDetailReport?SchoolId=${schoolId}&academicYearId=${academicYearId}&pageIndex=${pageIndex}&pageSize=50`;
  }

  if (startDate) {
    queryEndPoint =
      queryEndPoint + `&startDate=${moment(startDate).format("YYYY-MM-DD")}`;
  }

  if (endDate) {
    queryEndPoint =
      queryEndPoint + `&endDate=${moment(endDate).format("YYYY-MM-DD")}`;
  }

  if (validations.notNullOrUndefined(statusId)) {
    queryEndPoint = queryEndPoint + `&statusId=${statusId}`;
  }

  return exportReport
    ? action<BeehiveReport>(
        () => client.get(queryEndPoint, { responseType: "blob" }),
        types.EXPORTDETENTIONSDETAILEDREPORT,
        response => {
          onSuccess?.(response as any);
          FileDownload(response, "Detentions Report.csv");
        }
      )
    : action<PagedQueryView<DetentionsDetailReportListView>>(
        () => client.get(queryEndPoint),
        types.GETDETENTIONSDETAILEDREPORT,
        response => onSuccess?.(response as any)
      );
};


const detentionReportActions = {
  types,
  getYearGroupDetentionReport,
  getDetentionDetailedReport,
};

export default detentionReportActions;
