/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment, useCallback } from "react";
import lodashDebounce from "lodash.debounce";
import styled from "styled-components";
import {
  Icon,
  displayFont,
  textFont,
  Swatches,
  Spacing,
  BorderRadius,
  typescale,
  neutral,
  MinFieldWidth,
  ClassNames,
  fontStyle,
  Speed,
  Spinner,
} from "../index";

export interface ITextInputProps<T extends TextInputType> {
  placeholder?: string;
  onChange?: (value: TextInputTypeReturnType[T]) => void;
  fluid?: boolean;
  icon?: string;
  value?: string | number;
  prefix?: string;
  suffix?: string;
  rows?: number;
  maxLength?: number;
  characterCount?: boolean;
  type?: T;
  min?: number;
  max?: number;
  step?: number;
  required?: boolean;
  invalid?: false;
  name?: string;
  className?: string;
  debounce?: boolean;
  disabled?: boolean;
  autocomplete?: string;
  noSpinner?: boolean;
  noMouseWheel?: boolean;
  readonly?: boolean;
  loading?: boolean;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  color?: string;
}

export enum TextInputType {
  String,
  Number,
  Currency,
  Email,
  Password,
  Telephone,
  Url,
  Textarea,
}

type TextInputTypeReturnType = {
  [TextInputType.String]: string;
  [TextInputType.Number]: number;
  [TextInputType.Currency]: number;
  [TextInputType.Email]: string;
  [TextInputType.Password]: string;
  [TextInputType.Telephone]: string;
  [TextInputType.Url]: string;
  [TextInputType.Textarea]: string;
};

const Wrapper = styled.div<{
  fluid?: boolean;
  loadingText: boolean;
  type?: TextInputType;
  hasPrefix?: boolean;
  suffix?: string;
  icon?: string;
  bgColour?: string;
  readonlyStyles?: boolean;
}>`
  position: relative;
  display: ${props => (props.fluid ? "flex" : "inline-flex")};
  flex-direction: column;
  ${fontStyle(displayFont.medium, typescale.paragraph)}
  width: ${props => (props.fluid ? "100% " : "auto")};
  min-width: ${props => (props.fluid ? "auto" : `${MinFieldWidth}rem`)};

  .no-spinner {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    color: red;
  }

  .textInput-inner {
    display: flex;
    flex-grow: 1;
    align-items: stretch;
    background: ${props => (props.bgColour ? props.bgColour : neutral[200])};
    color: ${neutral[500]};
    border-radius: ${BorderRadius.Default}px;
    transition: all ${Speed.Default}ms ease;

    &.${ClassNames.invalid} {
      background: ${Swatches.Danger.swatch};
      color: ${Swatches.Danger.foreground};

      input,
      input::placeholder,
      textarea,
      textarea::placeholder {
        color: ${Swatches.Danger.foreground};
      }
    }

    :focus-within {
      i {
        color: ${neutral[100]};
      }
      ${props => (!props.readonlyStyles && `
        background: ${neutral[600]};
      `)};
    }

    input {
      flex-grow: 1;
      padding: ${Spacing.Default}px;
      margin: 0;
      border: 0;
      background: none;
      ${fontStyle(displayFont.medium, typescale.paragraph, neutral[600])}

      ${props => (props.readonlyStyles && `
        cursor: default;
        user-select: none;
      `)};

      &::placeholder {
        ${fontStyle(displayFont.medium, typescale.paragraph, neutral[400])}
      }

      &:focus {
        transition: all ${Speed.Default}ms ease;
        border-radius: ${BorderRadius.Default}px;
        ${props => (!props.readonlyStyles && `
          color: ${neutral[100]};
          background: ${neutral[600]};
        `)};
        ${fontStyle(displayFont.medium, typescale.paragraph)}
      }
    }

    textarea {
      flex-grow: 1;
      background: none;
      resize: none;
      border: 0;
      border-radius: ${BorderRadius.Default + "px"};
      padding: ${Spacing.Default}px;
      ${fontStyle(displayFont.medium, typescale.paragraph)}
    }
  }

  .text-input-loading {
    align-self: center;
    height: 1rem;
    padding: 0;
    display: inline-flex;
    align-items: center;
    margin-right: ${Spacing.Default}px;
    .spinner {
      margin-top: 2px;
      visibility: hidden;
    }
  }

  ${({ loadingText }) =>
    loadingText &&
    `
    .text-input-loading .spinner {
      visibility: visible;
    }`}

  .textInput-prefix,
  .textInput-suffix {
    padding: 0 ${Spacing.Default}px 0 ${Spacing.Default}px;
    display: flex;
    align-items: center;
    font-weight: ${displayFont.medium.weight};
  }

  .textInput-prefix {
    border-right: 1px solid ${neutral};
  }

  .textInput-suffix {
    border-left: 1px solid ${neutral};
  }

  .textInput-counter {
    display: flex;
    justify-content: flex-end;
    ${fontStyle(textFont.roman, typescale.sub, neutral[400])}
    margin: ${Spacing.Default}px ${BorderRadius.Default +
    "px"} 0 ${BorderRadius.Default + "px"};
    align-items: ${props =>
      props.type === TextInputType.Textarea ? "flex-end" : "center"};

    &.${ClassNames.invalid} {
      color: ${Swatches.Danger.swatch};
    }
  }
`;

const Input = styled.input<{ readonlyStyles: boolean }>`
  ${props => (props.readonlyStyles && `
    cursor: default;
    user-select: none;
  `)};
`

const prefixClassName = "textInput-prefix";
const suffixClassName = "textInput-suffix";

const TextInput = <T extends TextInputType = TextInputType.String>({
  placeholder,
  onChange,
  fluid,
  icon,
  prefix,
  suffix,
  value,
  rows,
  type,
  maxLength,
  step,
  characterCount,
  min,
  max,
  required,
  invalid,
  name,
  className,
  debounce,
  disabled,
  loading,
  autocomplete,
  noSpinner,
  noMouseWheel,
  readonly,
  onKeyPress,
  color,
}: ITextInputProps<T>) => {
  const [textValue, setTextValue] = useState<string>("");

  if (noSpinner) {
    className = `${className ? className : ""} no-spinner`;
  }

  useEffect(() => {
    setTextValue(value != null ? value.toString() : "");
  }, [value]);

  const debouncedChange = useCallback(
    lodashDebounce(value => {
      onChange?.(value as TextInputTypeReturnType[T]);
    }, 500),
    []
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    var newValue: (string | number) = e.target.value;
    setTextValue(newValue);

    if (type === TextInputType.Currency || type === TextInputType.Number) {
      newValue = Number(newValue);
    }

    if (debounce) {
      debouncedChange(newValue);
    } else {
      onChange?.(newValue as TextInputTypeReturnType[T]);
    }
  };

  const renderPrefix = () => {
    if (type === TextInputType.Currency) {
      return <span className={prefixClassName}>£</span>;
    }

    if (prefix) {
      return <span className={prefixClassName}>{prefix}</span>;
    }

    if (icon) {
      return (
        <span className={prefixClassName}>
          <Icon value={icon} />
        </span>
      );
    }
  };

  const renderSuffix = () => {
    return <>{suffix && <span className={suffixClassName}>{suffix}</span>}</>;
  };

  const renderCounter = () => {
    const css = [`textInput-counter`];

    if (textValue && maxLength && textValue.length > maxLength) {
      css.push(ClassNames.invalid);
    }

    return (
      maxLength && characterCount && (
        <span className={css.join(" ")}>
          {textValue ? textValue.length : 0}/{maxLength}
        </span>
      )
    );
  };

  const setInputTypeProperties = () => {
    var typeValue = "text";

    switch (type) {
      case TextInputType.Number:
        return { type: "number", min: min, max: max };
      case TextInputType.Currency:
        return { type: "number", min: 0, max: max, step: 0.01 };
      case TextInputType.Email:
        typeValue = "email";
        break;
      case TextInputType.Password:
        typeValue = "password";
        break;
      case TextInputType.Telephone:
        typeValue = "tel";
        break;
      case TextInputType.Url:
        typeValue = "url";
        break;
      default:
        typeValue = "text";
        break;
    }

    return { type: typeValue };
  };

  const getProps = () => {
    var props = {};

    props = { ...props, ...(name ? { name } : {}) };
    props = { ...props, ...{ value: textValue } };
    props = { ...props, ...(required ? { required: true } : {}) };
    props = { ...props, ...(placeholder ? { placeholder } : {}) };
    props = { ...props, ...(type ? { type } : {}) };
    props = { ...props, ...(disabled ? { disabled: true } : {}) };
    props = { ...props, ...(maxLength ? { maxLength: maxLength } : {}) };
    props = {
      ...props,
      ...(autocomplete ? { autoComplete: autocomplete } : {}),
    };

    return props;
  };

  const renderTextarea = () => {
    return <textarea {...getProps()} onChange={handleChange} rows={rows} />;
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    !disabled && onKeyPress?.(e);
  };

  const renderInput = () => {
    return (
      <Fragment>
        {renderPrefix()}
        <Input
          {...(className ? { className: ClassNames.invalid } : {})}
          {...getProps()}
          {...setInputTypeProperties()}
          onChange={handleChange}
          onWheel={
            noMouseWheel ? e => (e.target as HTMLElement).blur() : undefined
          }
          onKeyDown={handleKeyPress}
          readOnly={readonly}
          readonlyStyles={readonly}
        />
        {/* {loading && ( */}
        <span className="text-input-loading">
          <Spinner />
        </span>
        {/* )} */}
        {renderSuffix()}
      </Fragment>
    );
  };

  return (
    <Wrapper
      className={`textInput form-control ${className ? className : ""}`}
      fluid={fluid}
      hasPrefix={prefix != null || type === TextInputType.Currency}
      suffix={suffix}
      icon={icon}
      type={type}
      loadingText={loading}
      bgColour={color}
      readonlyStyles={readonly}
    >
      <div
        className={[
          `textInput-inner`,
          noSpinner ? "no-spinner" : "",
          invalid ? ClassNames.invalid : "",
        ].join(" ")}
      >
        {type && type === TextInputType.Textarea
          ? renderTextarea()
          : renderInput()}
      </div>
      {renderCounter()}
    </Wrapper>
  );
};

TextInput.defaultProps = {
  fluid: false,
  characterCount: false,
  type: TextInputType.String,
  rows: 8,
};

export default TextInput;
