import {
  DateAndStudentName,
  FeedItemContent,
  FeedItemDetails,
} from "../activityFeedItemComponents";

const SmartTaskActivity = ({ activity, displayStudentName }) => {
  return (
    <FeedItemContent>
      <DateAndStudentName
        displayStudentName={displayStudentName}
        activity={activity}
      />

      <FeedItemDetails>
        <b>{activity.categoryName}</b>
      </FeedItemDetails>
    </FeedItemContent>
  );
};

export default SmartTaskActivity;
