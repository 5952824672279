import { IBeehiveAction } from "types/common/action.types";
import consentActions from "../../actions/consentActions";
import { ConsentFormUserView } from "areas/administration/types/consentResponses.types";

interface ICompletedConsentFormsState {
  loading: boolean;
  forms: ConsentFormUserView[];
  error: string | null;
}

const CONSENT_STATE: ICompletedConsentFormsState = {
  loading: false,
  forms: [],
  error: null,
};


const completedConsentFormsReducer = (state = CONSENT_STATE, action: IBeehiveAction) : ICompletedConsentFormsState=> {

  const {
    CONSENT_GETCOMPLETEDFORMSFORUSER,
    CONSENT_GETCOMPLETEDFORMSFORPARENT,
  } = consentActions.types;

  switch (action.type) {
    case CONSENT_GETCOMPLETEDFORMSFORUSER.START:
    case CONSENT_GETCOMPLETEDFORMSFORPARENT.START:
      return { ...state, loading: true, error: null };
    case CONSENT_GETCOMPLETEDFORMSFORUSER.SUCCESS:
    case CONSENT_GETCOMPLETEDFORMSFORPARENT.SUCCESS:
      return { ...state, loading: false, forms: action.payload, error: null };
    case CONSENT_GETCOMPLETEDFORMSFORUSER.FAILED:
    case CONSENT_GETCOMPLETEDFORMSFORPARENT.FAILED:
      return {
        ...state,
        loading: false,
        forms: [],
        error: "There was a problem loading the consent forms",
      };

    default:
      return state;
  }
};

export default completedConsentFormsReducer;
