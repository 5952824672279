import { IBeehiveAction } from "types/common/action.types";
import yearGroupActions from "../actions/yearGroupActions";
import { IBeehiveError } from "types/common/errors.types";
import { GroupListView } from "types/users/userGroups.types";


interface IYearGroupsState {
  yearGroups: GroupListView[];
  schoolId: number;
  error: IBeehiveError;
  loading: boolean;
}

const INITIAL_STATE : IYearGroupsState = {
  yearGroups: [],
  schoolId: null,
  error: null,
  loading: false,
};


const yearGroupsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IYearGroupsState => {

  const { YEARGROUPS_GETBYSCHOOL } = yearGroupActions.types;

  switch (action.type) {
    case YEARGROUPS_GETBYSCHOOL.START:
      return {
        ...state,
        yearGroups: [],
        schoolId: action.payload,
        loading: true,
        error: null,
      };

    case YEARGROUPS_GETBYSCHOOL.SUCCESS:
      return { ...state, yearGroups: action.payload, loading: false };

    case YEARGROUPS_GETBYSCHOOL.FAILED:
      return {
        ...state,
        loading: false,
        schoolId: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default yearGroupsReducer;
