import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import FileDownload from "js-file-download";
import { BeehiveReport, callbackType } from "types/common/action.types";
import { CoCurricularReportFilter } from "../types/coCurricularAnalyticsRequest.types";


const builder = new actionTypeBuilder("coCurricularAnalytics");

const types = {
  EXPORTCOCURRICULARREPORT: builder.build("exportCoCurricularreport"),
};


const exportCoCurricularReport = (
  filter: CoCurricularReportFilter,
  onSuccess?: callbackType<BeehiveReport>
) => {
  return action<BeehiveReport>(
    () =>
      client.get(`planner/reporting/cocurricular/export?schoolid=${filter.schoolId}&AcademicYearId=${filter.academicYearId}`, {
        responseType: "blob",
      }),
    types.EXPORTCOCURRICULARREPORT,
    response => {
      onSuccess && onSuccess(response);
      FileDownload(response, "CoCurricularReport.xlsx");
    }
  );
};


const coCurricularAnalyticsActions = {
  types,
  exportCoCurricularReport,
};

export default coCurricularAnalyticsActions;
