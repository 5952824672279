import React from "react";


const Magazine = () => {

  return (
    <p>Magazine</p>
  );
};

export default Magazine;
