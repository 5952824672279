import defaults from "configuration/defaults";
import magazineActions from "../../actions/magazineActions";
import { PageInfo } from "types/common/paging.types";
import { ArticleListView } from "areas/news/types/newsResponse.types";
import { IBeehiveAction } from "types/common/action.types";


interface IMagazineHomeState {
  loading: boolean;
  error: string | null;
  features: ArticleListView[];
  paging: PageInfo;
}

const INITIAL_STATE: IMagazineHomeState = {
  loading: false,
  error: null,
  features: [],
  paging: defaults.DEFAULT_PAGING,
};


const magazineHomeReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IMagazineHomeState => {

  const { MAGAZINE_GETHOME } = magazineActions.types;

  switch (action.type) {
    case MAGAZINE_GETHOME.START:
      return { ...INITIAL_STATE, loading: true, error: null };

    case MAGAZINE_GETHOME.SUCCESS:
      var newState = {
        ...state,
        features:
          action.payload.articles.paging.pageIndex === 0
            ? action.payload.articles.items
            : [...state.features, ...action.payload.articles.items],

        paging: action.payload.articles.paging,

        loading: false,
      };
      return newState;

    case MAGAZINE_GETHOME.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default magazineHomeReducer;
