import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import TagsTable from "./tagsTable";
import tagsActions from "areas/send/actions/tags/tagsActions";
import { RootState } from "reducers/store";


const Tags = () => {

  const { error, loading, tags } = useSelector((state: RootState) => state.tags);

  useEffect(() => {
    tagsActions.getTags();
  }, []);

  return <TagsTable tags={tags} loading={loading} error={error} />;
};

export default Tags;
