import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  EmptyMessage,
  TabBar,
  Spacing,
  displayFont,
  typescale,
  neutral,
  Speed,
  Icon,
  fontStyle,
  Chip,
  Swatches,
} from "ui-kit";
import styled from "styled-components";
import { arrays } from "utils";
import { Constants } from "configuration";
import { useNavigate } from "react-router";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const CycleWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

const DayColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-basis: 0;
`;

const DayHeader = styled.div`
  padding: ${Spacing.Default}px;
  ${fontStyle(displayFont.medium, typescale.paragraph, neutral[700])}
  background: ${neutral[300]};
`;

const PeriodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  padding: ${Spacing.Default}px;
  background: ${neutral[100]};
  border-right: 1px solid ${neutral[200]};
  border-bottom: 1px solid ${neutral[200]};
  ${fontStyle(displayFont.medium, typescale.header4, neutral[600])}
  min-height: 72px;

  .period-title {
    flex-grow: 1;

    span {
      display: block;
    }
    .period-subject {
      margin-bottom: ${Spacing.Small}px;
    }
    .period-code {
      display: inline-block;
      margin-right: ${Spacing.Small}px;
      margin-bottom: ${Spacing.Default}px;

      ${fontStyle(displayFont.roman, typescale.paragraph)}
    }

    .period-subject-chip {
      display: inline-block;
      margin-right: ${Spacing.Small}px;
      margin-bottom: ${Spacing.ExtraSmall}px;
      ${fontStyle(displayFont.roman, typescale.paragraph)}
    }
  }

  .period-details {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .period-room {
    i {
      margin-right: 4px;
    }
    ${fontStyle(displayFont.roman, typescale.paragraph)}
  }

  .period-teacher {
    ${fontStyle(displayFont.medium, typescale.paragraph)}
  }

  ${({ hasOnClick }) =>
    hasOnClick &&
    `
    &:hover {
      transition: all ease ${Speed.Default}ms;
      cursor: pointer;
      color: ${neutral[100]};
      background: ${neutral[600]};

      i {
        color: ${neutral[100]};
      }

      .chip {
        background: ${neutral[100]};
        color: ${neutral[600]};
      }
    }
    `}
`;

const PeriodColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const PeriodListCellWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  padding: ${Spacing.Default}px;
  background: ${neutral[300]};
  ${fontStyle(displayFont.bold, typescale.header2, neutral[100])}
  border-bottom: 1px solid ${neutral[200]};
`;

const PeriodColumnHeader = styled.div`
  padding: ${Spacing.Default}px;
  background: ${neutral[300]};
  border-bottom: 1px solid ${neutral[200]};
  border-right: 1px solid ${neutral[200]};

  ${fontStyle(displayFont.bold, typescale.paragraph, neutral[500])}
`;

const TimetableGrid = ({ timetable, onPeriodClick }) => {
  const [activeCycle, setActiveCycle] = useState(
    timetable ? timetable.cycles[0] : null
  );

  useEffect(() => {
    setActiveCycle(timetable ? timetable.cycles[0] : null);
  }, [timetable]);

  useEffect(() => {}, [activeCycle]);

  const handleCycleClicked = (name, index) => {
    setActiveCycle(timetable.cycles[index]);
  };

  const getPeriods = periods => {
    var cells = [<PeriodColumnHeader>Period</PeriodColumnHeader>];

    for (var i = 1; i <= periods.length; i++) {
      cells.push(<PeriodListCell key={i} index={i} />);
    }
    return cells;
  };

  const getWeekName = index => {
    switch (index) {
      case 0:
        return "A";
      case 1:
        return "B";
      case 2:
        return "C";
      default:
        return "X";
    }
  };

  const showEmptyMessage = () => {
    return (
      <EmptyMessage
        icon="clock"
        title="No timetables"
        summary="No timetables were found"
        cover
      />
    );
  };

  if (arrays.isEmpty(timetable?.cycles)) {
    return <Wrapper>{showEmptyMessage()}</Wrapper>;
  }

  return (
    <>
      {timetable.cycles.length > 1 && (
        <TabBar
          selectedIndex={activeCycle?.index}
          onItemClicked={handleCycleClicked}
        >
          {timetable.cycles.map((cycle, index) => (
            <TabBar.Item
              key={index}
              name={`Week ${getWeekName(index)}`}
              text={`Week ${getWeekName(index)}`}
            />
          ))}
        </TabBar>
      )}
      {activeCycle?.days.length > 0 ? (
        <CycleWrapper>
          {/* getPeriods(timetable.dailyPeriods) */}
          <PeriodColumn>
            {getPeriods(activeCycle?.days?.[0]?.lessons)}
          </PeriodColumn>
          {activeCycle?.days.map((day, index) => (
            <Day key={index} day={day} onPeriodClick={onPeriodClick} />
          ))}
        </CycleWrapper>
      ) : (
        <>
          {/* <p>hi</p> */}
          {showEmptyMessage()}
        </>
      )}
    </>
  );
};

const Day = ({ day, onPeriodClick }) => {
  return (
    <DayColumn>
      <DayHeader>{day.day}</DayHeader>
      {day.lessons.map((lesson, index) => (
        <Period key={index} period={lesson} onClick={onPeriodClick} />
      ))}
    </DayColumn>
  );
};

const Period = ({ period, onClick }) => {
  const { user } = useSelector(state => state.currentUser);

  const handleClick = () => {
    if (period.groupId > 0) {
      onClick?.(period);
    }
  };
  return (
    <PeriodWrapper
      onClick={handleClick}
      hasOnClick={onClick && period.groupId > 0}
    >
      <div className="period-title">
        {period.subject.length > 0 && (
          <span className="period-subject">
            {user.userType === Constants.USER_TYPES.STAFF && period.groupName != null ? (
              <>
               {period.subject !== "Self Study" && <Chip colorSwatch={Swatches.Miami} text={period.subject} className="period-subject-chip" />} {period.groupName}
              </>
            ) : (
              period.subject
            )}
          </span>
        )}

        <div className="period-details">
          { user.userType === Constants.USER_TYPES.STAFF && (
            <>
              { period.code && (
                <Chip text={period.code} className="period-code" />
              )}
              <p className="period-teacher">{period.teacher}</p>
            </>
          )}
          {period.room && (
            <span className="period-room">
              <Icon value="map-marker-alt" />
              {period.room}
            </span>
          )}
        </div>
      </div>
    </PeriodWrapper>
  );
};

const PeriodListCell = ({ index }) => {
  return <PeriodListCellWrapper>{index ? index : null}</PeriodListCellWrapper>;
};

export default TimetableGrid;
