import React from "react";
import { ReactComponent as PandaSvg } from "assets/primary/rockets/panda.svg";
import { IPrimaryRocketCharacterProps } from "./primaryRocket";


export const Panda: React.FC<IPrimaryRocketCharacterProps> = ({ className }) => {

  return <PandaSvg className={className} />;
};

export default Panda;
