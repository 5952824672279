import onCallCategoriesReducer from "./onCallCategoriesReducer";
import onCallNotificationReducer from "./onCallNotificationReducer";
import onCallRequestReducer from "./onCallRequestReducer";
import onCallRequestsReducer from "./onCallRequestsReducer";

const onCallReducers = {
  onCallCategories: onCallCategoriesReducer,
  onCallNotification: onCallNotificationReducer,
  onCallRequest: onCallRequestReducer,
  onCallRequests: onCallRequestsReducer,
};

export default onCallReducers;
