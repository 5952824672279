import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ActionBar,
  Button,
  Loader,
  Modal,
  Right,
  Size,
  Swatches,
} from "ui-kit";
import { FEED_TYPE_KEY } from "../../constants/activityFeed";
import StudentModal from "sharedComponents/common/users/studentModal";
import ReadOnlyDetails from "areas/behaviour/components/readOnly/readOnlyDetails";
import ReadOnlyRewardDetails from "../../../behaviour/components/readOnly/readOnlyRewardDetails";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import onCallActions from "areas/behaviour/actions/onCall/onCallActions";
import ManageOnCallForm from "areas/behaviour/components/onCall/manageOnCallForm";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";

const FeedItemDetailsModal = ({ open, handleCloseModal, activity }) => {
  const {
    behaviour,
    loading: loadingBehaviour,
    getBehaviourError,
  } = useSelector(state => state.behaviour);
  const {
    reward,
    loading: loadingReward,
    getRewardError,
  } = useSelector(state => state.reward);
  const {
    loading: loadingOnCallRequest,
    getRequestError,
    onCallRequest,
  } = useSelector(state => state.onCallRequest);

  useEffect(() => {
    if (activity) {
      switch (activity.feedTypeId) {
        case FEED_TYPE_KEY.BEHAVIOURS:
          behaviourActions.getBehaviour(
            activity.students[0].id,
            activity.itemId,
            null
          );
          break;

        case FEED_TYPE_KEY.REWARDS:
          rewardActions.getReward(
            activity.students[0].id,
            activity.itemId,
            null
          );
          break;

        case FEED_TYPE_KEY.ON_CALL:
          onCallActions.getOnCallRequest(activity.schoolId, activity.itemId);
          break;

        default:
          break;
      }
    }
  }, [activity]);

  const modalFooter = (
    <ActionBar low>
      <Right>
        <Button
          text="Close"
          size={Size.Small}
          color={Swatches.Low}
          onClick={handleCloseModal}
        />
      </Right>
    </ActionBar>
  );

  const ActivityDetailModal = () => {
    switch (activity?.feedTypeId) {
      case FEED_TYPE_KEY.BEHAVIOURS:
        return (
          <StudentModal
            title="Behaviour"
            onClose={handleCloseModal}
            open={open}
            height="80%"
            width="75%"
            student={activity?.students?.[0]}
            footer={modalFooter}
          >
            <ApiExceptionMessage error={getBehaviourError} />
            {loadingBehaviour ? (
              <Loader size={Size.Medium} fluid />
            ) : (
              behaviour && <ReadOnlyDetails behaviour={behaviour.behaviour} />
            )}
          </StudentModal>
        );

      case FEED_TYPE_KEY.REWARDS:
        return (
          <StudentModal
            title="Reward"
            onClose={handleCloseModal}
            open={open}
            height="70%"
            width="75%"
            student={activity?.students?.[0]}
            footer={modalFooter}
          >
            <ApiExceptionMessage error={getRewardError} />
            {loadingReward ? (
              <Loader size={Size.Medium} fluid />
            ) : (
              reward && <ReadOnlyRewardDetails reward={reward} />
            )}
          </StudentModal>
        );

      case FEED_TYPE_KEY.ON_CALL:
        return (
          <Modal
            title="On Call Request"
            onClose={handleCloseModal}
            open={open}
            height="70%"
            width="80%"
          >
            <Modal.Body>
              <ApiExceptionMessage error={getRequestError} />
              {loadingOnCallRequest ? (
                <Loader size={Size.Medium} fluid />
              ) : (
                onCallRequest && (
                  <ManageOnCallForm onCallRequest={onCallRequest} readOnly />
                )
              )}
            </Modal.Body>
            <Modal.Footer>{modalFooter}</Modal.Footer>
          </Modal>
        );

      default:
        return null;
    }
  };

  return <ActivityDetailModal />;
};

export default FeedItemDetailsModal;
