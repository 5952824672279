import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Size } from "ui-kit/common/size";
import { Message, Loader } from "ui-kit";
import { Swatches, danger } from "ui-kit/common/colors";
import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import EditReward, { IRewardDetails } from "./editReward";
import { useAppSelector } from "reducers/hooks";
import { StudentFeedListView } from "types/planner/feedResponse.types";
import { UpdateRewardCommand } from "areas/behaviour/types/behaviourRequest.types";
import moment from "moment";


interface IEditRewardWrapperProps {
  activity: StudentFeedListView;
  handleCloseModal: () => void;
}


const EditRewardWrapper: React.FC<IEditRewardWrapperProps> = ({ activity, handleCloseModal }) => {

  const { reward, loading, getRewardError, updateRewardError } = useAppSelector(
    state => state.reward
  );
  const { schoolInformation } = useAppSelector(state => state.school);

  useEffect(() => {
    rewardActions.getReward(activity.students[0].id, activity.itemId, null);
  }, [activity]);

  const handleRevokeReward = (revokeNote: string) => {
    const callback = () => {
      handleCloseModal();
    };

    rewardActions.revokeReward({
        studentId: activity.students[0].id,
        rewardId: activity.itemId,
        updateNote: revokeNote,
      },
      callback
    );
  };

  const handleUpdateReward = ({
    internalNotes,
    publicNotes,
    points,
    initialAction,
    housePoints,
    incidentDate
  }: IRewardDetails) => {

    const rewardUpdateDetails: UpdateRewardCommand = {
      id: activity.itemId,
      description: internalNotes,
      publicNotes: publicNotes,
      points: points,
      initialActionId: initialAction.initialActionId,
      housePoints: housePoints,
      incidentDate: moment(incidentDate).format("YYYY-MM-DD"),
      studentId: activity.students[0].id,
    };

    const callback = () => {
      handleCloseModal();
    };

    rewardActions.updateReward(
      rewardUpdateDetails,
      callback
    );
  };

  if (loading) {
    return <Loader size={Size.Medium} fluid />;
  }

  if (getRewardError) {
    return (
      <Message
        text="There was a problem loading the reward details."
        color={Swatches.Danger}
      />
    );
  }

  return (
    reward && (
      <EditReward
        activity={activity}
        reward={reward}
        handleCloseModal={handleCloseModal}
        updateReward={handleUpdateReward}
        revokeReward={handleRevokeReward}
        updateError={updateRewardError}
        isPrimary={schoolInformation.isPrimary}
      />
    )
  );
};


export default EditRewardWrapper;
