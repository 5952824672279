import store from "reducers/store";
import { FormCategoryListView, FormListView } from "../types/formResponse.types";
import { IBeehiveAction } from "types/common/action.types";

const types = {
  USERSUBMISSIONSFILTERBYCATEGORY: "usersubmissionsfilterbycategory",
  USERSUBMISSIONSFILTERBYFORMTYPE: "usersubmissionsfilterbyformtype",
  USERSUBMISSIONSFILTERBYFORMSTATUS: "usersubmissionsfilterbyformstatus",
  USERSUBMISSIONSFILTERBYFORMUSER: "usersubmissionsfilterbyuser",
  USERSUBMISSIONSFILTERBYSCHOOL: "usersubmissionsfilterbyschool",
};


const filterByCategory = (category: FormCategoryListView) => {
  store.dispatch<IBeehiveAction<FormCategoryListView>>({
    type: types.USERSUBMISSIONSFILTERBYCATEGORY,
    payload: category,
  });
};

const filterByFormType = (formType: FormListView) => {
  store.dispatch<IBeehiveAction<FormListView>>({
    type: types.USERSUBMISSIONSFILTERBYFORMTYPE,
    payload: formType,
  });
};

const filterByFormStatus = (formStatus: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.USERSUBMISSIONSFILTERBYFORMSTATUS,
    payload: formStatus,
  });
};

const filterByFormUser = (userId: string) => {
  store.dispatch<IBeehiveAction<string>>({
    type: types.USERSUBMISSIONSFILTERBYFORMUSER,
    payload: userId,
  });
};

const filterBySchool = (schoolId: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.USERSUBMISSIONSFILTERBYSCHOOL,
    payload: schoolId,
  });
};


const userSubmissionsFilterActions = {
  types,
  filterByCategory,
  filterByFormType,
  filterByFormStatus,
  filterByFormUser,
  filterBySchool,
};

export default userSubmissionsFilterActions;
