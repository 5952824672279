import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";

const builder = new actionTypeBuilder("houses");

const types = {
  HOUSES_GETSUMMARY: builder.build("HOUSES_GETSUMMARY"),
  HOUSES_GETLEADERBOARD: builder.build("HOUSES_GETLEADERBOARD"),
};

const getHouseLeaderboard = (schoolId, houseId, callback) =>
  action(
    () => client.get(`planner/schools/${schoolId}/houses/leaderboard`),
    types.HOUSES_GETLEADERBOARD,
    callback
  );

const getHouseSummary = (schoolId, houseId, callback) =>
  action(
    () => client.get(`planner/schools/${schoolId}/houses/${houseId}/summary`),
    types.HOUSES_GETSUMMARY,
    callback
  );

const houseActions = {
  types,
  getHouseLeaderboard,
  getHouseSummary,
};

export default houseActions;
