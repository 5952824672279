import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RoleStaff from "./roleStaff";
import { formatScrollbars, neutral, Spacing, List } from "ui-kit";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import rolesActions from "areas/administration/actions/rolesActions";
import { RootState } from "reducers/store";
import { RoleListView } from "types/users/userRoles.types";


const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const RoleListWrapper = styled.div`
  display: flex;
  padding-right: ${Spacing.Default}px;
  flex-direction: column;
  position: absolute;
  min-height: 100%;
  max-height: 100%;
  left: 0;
  overflow-y: scroll;
  width: 15rem;
  min-width: 15rem;

  ${formatScrollbars(neutral[200])}
`;

const RoleStaffWrapper = styled.div`
  flex-grow: 1;
  margin-left: 16rem;
  height: 100%;
`;


const Roles = () => {

  const { roles, loading, error } = useSelector((state: RootState) => state.roles);
  const [_selectedRole, _setSelectedRole] = useState<RoleListView | null>(null);

  useEffect(() => {
    if (arrays.isEmpty(roles)) {
      rolesActions.getRoles();
    }
  }, []);

  const handleSelectRole = (role: RoleListView) => {
    _setSelectedRole(role);
  };

  return (
    <Wrapper>
      <RoleListWrapper>
        <List title="Roles">
          {roles.map((role: RoleListView, index: number) => (
            <List.Item
              text={role.name}
              sub={role.description}
              key={index}
              selected={_selectedRole?.id === role.id}
              onClick={() => handleSelectRole(role)}
            />
          ))}
        </List>
      </RoleListWrapper>
      <RoleStaffWrapper>
        <RoleStaff role={_selectedRole} />
      </RoleStaffWrapper>
    </Wrapper>
  );
};

export default Roles;
