import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import store from "reducers/store";
import client from "services/client";
import { Constants } from "configuration";
import { ToastService } from "../../../../ui-kit";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { PublishStatus } from "types/common/status.types";

const prefix = "assignmentpublish";
const builder = new actionTypeBuilder(prefix);

const types = {
  PUBLISH: builder.build("publish"),
  UNPUBLISH: builder.build("unpublish"),
  PUBLISHED: `${prefix}_published`,
  UNPUBLISHED: `${prefix}_unpublished`,
};


const publishAssignment = (assignmentId: number, callback?: callbackType<null>) =>
  action<null>(
    () =>
      client.post(`planner/assignments/${assignmentId}/publish`, {
        id: assignmentId,
        status: PublishStatus.Live,
      }),
    types.PUBLISH,
    response => {
      ToastService.pop("Assignment published", null, "check-circle");
      callback?.(response);
    }
  );

const unpublishAssignment = (assignmentId: number, callback?: callbackType<null>) =>
  action<null>(
    () =>
      client.post(`planner/assignments/${assignmentId}/publish`, {
        id: assignmentId,
        status: PublishStatus.Draft,
      }),
    types.UNPUBLISH,
    response => {
      ToastService.pop("Assignment unpublished", null, "check-circle");
      callback?.(response);
    }
  );

const assignmentPublished = (assignmentId: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.PUBLISHED,
    payload: assignmentId,
  });
};

const assignmentUnpublished = (assignmentId: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.UNPUBLISHED,
    payload: assignmentId,
  });
};

const assignmentPublishActions = {
  types,
  publishAssignment,
  unpublishAssignment,
  assignmentPublished,
  assignmentUnpublished,
};

export default assignmentPublishActions;
