import { IRole } from "configuration/constants.types";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { users } from "utils";


interface IRoleViewProps {
  roles: IRole[]
  children?: any | any[];
  inverse?: boolean;
}

const RoleView: React.FC<IRoleViewProps> = ({ roles, children, inverse }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);

  const inRoles = user && users.isInAnyRoles(user, roles);

  return (inRoles && !inverse) || (!inRoles && inverse) ? children : null;
};

export default RoleView;
