import {
  ActionBar,
  Button,
  DatePicker,
  Size,
  Spacing,
  TextInput,
} from "ui-kit";
import styled from "styled-components";
import MultiSchoolUserView from "sharedComponents/common/users/multiSchoolUserView";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import BehaviourCodeDropdown from "areas/behaviour/components/behaviours/behaviourCodeDropdown";
import BehaviourCategoriesDropdown from "areas/behaviour/components/behaviours/behaviorCategoriesDropdown";
import YearGroupDropdown from "areas/behaviour/components/behaviourCommon/yearGroupDropdown";
import AcademicYearFilter from "sharedComponents/common/academicYearFilter";
import SearchFilterItemAndButton from "../../searchButton";
import { YearGroupFilter } from "areas/behaviour/types/detentionRegisterRequest.types";
import { GroupListView } from "types/users/userGroups.types";
import moment from "moment";


const ActionBarItemWrapper = styled.div`
  margin-bottom: ${Spacing.Medium}px;
  .dropdown {
    width: 100%;
  }
`;


interface IPendingAttentionBehavioursActionBarProps {
  filters: YearGroupFilter;
  onChangeFilters: (newfilters: YearGroupFilter) => void;
  schoolId: number;
  onChangeSchool: (value: number) => void;
  searchTerm: string;
  onChangeSearchTerm: (value: string) => void;
  onClickSearch: () => void;
  yearGroups: GroupListView[];
  loading?: boolean;
}


const PendingAttentionBehavioursActionBar: React.FC<IPendingAttentionBehavioursActionBarProps> = ({
  filters,
  onChangeFilters,
  schoolId,
  onChangeSchool,
  searchTerm,
  onChangeSearchTerm,
  onClickSearch,
  yearGroups,
  loading,
}) => {

  const onChangeDate = (date: Date) => {
    if (date) {
      onChangeFilters({ ...filters, date: moment(date).toString() });
    }
    else {
      onChangeFilters({ ...filters, date: null });
    }
  };

  const onChangeBehaviourCode = (value: number) => {
    onChangeFilters({ ...filters, behaviourCodeId: value });
  };

  const onChangeBehaviourCategory = (value: number) => {
    onChangeFilters({ ...filters, schoolBehaviourCategoryId: value });
  };

  const onChangeAcademicYear = (value: number, label: string) => {
    onChangeFilters({ ...filters, academicYearId: value });
  };

  return (
    <ActionBar>
      <ActionBar.Filter>
        <MultiSchoolUserView>
          <ActionBar.FilterItem title="School">
            <SchoolDropdown
              onChange={onChangeSchool}
              initialValue={schoolId}
              fluid
            />
          </ActionBar.FilterItem>
        </MultiSchoolUserView>

        <ActionBar.FilterItem title="Academic Year">
          <ActionBarItemWrapper>
            <AcademicYearFilter
              value={filters.academicYearId}
              onChange={onChangeAcademicYear}
            />
          </ActionBarItemWrapper>
        </ActionBar.FilterItem>

        <ActionBar.FilterItem title="Behaviour">
          <ActionBarItemWrapper>
            <BehaviourCodeDropdown
              schoolId={schoolId}
              selected={filters.behaviourCodeId}
              onChange={onChangeBehaviourCode}
            />
          </ActionBarItemWrapper>

          <ActionBarItemWrapper>
            <BehaviourCategoriesDropdown
              schoolId={schoolId}
              behaviourCodeFilter={filters.behaviourCodeId}
              selected={filters.schoolBehaviourCategoryId}
              onChange={onChangeBehaviourCategory}
            />
          </ActionBarItemWrapper>
        </ActionBar.FilterItem>

        <ActionBar.FilterItem title="Date Given">
          <DatePicker
            dateFormat="DD/MM/YYYY"
            selectedDate={filters.date}
            onChange={onChangeDate}
            closeOnSelect
            fluid
            clearable
          />
        </ActionBar.FilterItem>

        <ActionBar.FilterItem title="Year Group">
          <YearGroupDropdown
            yearGroups={yearGroups}
            selectedYearGroup={filters.yearGroupId}
            handleSelectYearGroup={value =>
              onChangeFilters({ ...filters, yearGroupId: value })
            }
          />
        </ActionBar.FilterItem>

        <ActionBar.FilterItem title="Search">
          {/* <SearchFilterItemAndButton onClickSearch={onClickSearch}> */}
          <TextInput
            value={searchTerm}
            fluid
            placeholder="Search Behaviours... "
            onChange={value => onChangeSearchTerm(value)}
            loading={loading}
            icon="search"
          />
          {/* </SearchFilterItemAndButton> */}
        </ActionBar.FilterItem>
      </ActionBar.Filter>
    </ActionBar>
  );
};

export default PendingAttentionBehavioursActionBar;
