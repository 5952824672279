import { IBeehiveAction } from "types/common/action.types";
import calendarActions from "../actions/calendarActions";
import { CalendarCategoryListView } from "../types/calendarResponses.types";


interface ICalendarCategoriesState {
  categories: CalendarCategoryListView[],
  loading: boolean;
  error: string | null;
}

const INITIAL_STATE: ICalendarCategoriesState = {
  categories: [],
  loading: false,
  error: null,
};

const calendarCategoriesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ICalendarCategoriesState => {
  const { CALENDAR_GETCALENDARCATEGORIES } = calendarActions.types;

  switch (action.type) {
    case CALENDAR_GETCALENDARCATEGORIES.START:
      return { 
        ...state, 
        loading: true 
      };

    case CALENDAR_GETCALENDARCATEGORIES.SUCCESS:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };

    case CALENDAR_GETCALENDARCATEGORIES.FAILED:
      return { 
        ...state, 
        loading: false, 
        error: action.payload 
      };

    default:
      return state;
  }
};

export default calendarCategoriesReducer;
