import React, { useRef } from "react";
import styled, { FlattenSimpleInterpolation } from "styled-components";
import {
  displayFont,
  Size,
  Swatches,
  neutral,
  Spacing,
  typescale,
  BorderRadius,
  Icon,
  Spinner,
  fontStyle,
  fontSize,
  Speed,
  Tooltip,
} from "../index";
import { ColorSwatch } from "../common/colors";
import Tippy from "@tippyjs/react";

export interface IButtonProps {
  type?: "button" | "submit" | "reset";
  text?: string;
  color?: ColorSwatch;
  badge?: number | null;
  icon?: string;
  working?: boolean;
  disabled?: boolean;
  fluid?: boolean;
  transparent?: boolean;
  className?: string;
  size?: Size;
  tooltip?: string;
  tooltipSub?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  style?: React.CSSProperties;
  css?: FlattenSimpleInterpolation;
  disabledTooltip?: string;
  disabledTooltipSub?: string;
}

const Wrapper = styled.button<{
  isFluid?: boolean;
  swatch?: ColorSwatch;
  size?: Size;
  isTransparent?: boolean;
  working?: boolean;
  css?: FlattenSimpleInterpolation;
}>`
  display: ${props => (props.isFluid ? "flex" : "inline-flex")};
  width: ${props => (props.isFluid ? "100%" : "auto")};
  box-sizing: border-box;
  align-items: center;
  position: relative;
  background: ${props => (props.swatch ? props.swatch.swatch : null)};
  color: ${props => (props.swatch ? props.swatch.foreground : null)};

  ${({ swatch }) =>
    fontStyle(displayFont.medium, typescale.control1, swatch?.foreground)}

  ${({ size }) => {
    switch (size) {
      case Size.Small:
        return fontSize(typescale.control3);
      case Size.Medium:
        return fontSize(typescale.control2);
      case Size.Large:
        return fontSize(typescale.control1);
      default:
        return fontSize(typescale.control2);
    }
  }}

  ${({ isTransparent }) =>
    isTransparent &&
    `

    background: none;
    color: ${neutral[400]};
    
    &:hover {
      transition: all ${Speed.Default}ms ease;
      color: ${neutral[500]};
      background: ${neutral[200]};
    }
  `}

  border-radius: ${BorderRadius.Default}px;
  text-align: center;
  justify-content: center;
  padding: ${Spacing.Default}px ${Spacing.Jumbo}px;
  margin: 0;
  border: 0;
  cursor: ${props => (props.working ? "wait" : "pointer")};

  &:active {
    .button-content {
      transform: scale(0.8);
    }
  }

  .spinner {
    position: absolute;
    right: 0;
    left: 0;
    color: ${props => (props.swatch ? props.swatch.foreground : null)};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  ${props => props.css}
`;

const TextWrapper = styled.span<{ working?: boolean; swatch?: ColorSwatch }>`
  display: flex;
  position: relative;
  width: auto;
  align-items: center;
  visibility: ${props => (props.working ? "hidden" : "visible")};
  .icon {
    margin-right: ${Spacing.Default}px;
    color: ${props => (props.swatch ? props.swatch.foreground : null)};
  }
`;

const Button: React.FC<IButtonProps> = ({
  type = "button",
  text,
  color,
  icon,
  working,
  disabled,
  fluid,
  size,
  transparent,
  className,
  tooltip,
  tooltipSub,
  style,
  css,
  onClick,
  disabledTooltip,
  disabledTooltipSub,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    !disabled && !working && onClick?.(e);
  };

  const ref = useRef() as React.RefObject<HTMLButtonElement>;
  const disabledRef = useRef() as React.RefObject<HTMLDivElement>;

  return (
    <>
      <Wrapper
        ref={ref}
        type={type}
        className={`button form-control ${className ? className : ""}`}
        swatch={color}
        disabled={disabled}
        working={working}
        isFluid={fluid}
        isTransparent={transparent}
        onClick={handleClick}
        size={size}
        css={css}
        style={style}
      >
        <div ref={disabledRef}>
          {working && <Spinner size={Size.Medium} color={color?.foreground} />}

          <TextWrapper
            working={working}
            className="button-content"
            swatch={color}
          >
            {icon && <Icon value={icon} size={Size.Medium} />}
            <span>{text}</span>
          </TextWrapper>
        </div>
      </Wrapper>
      {tooltip != null && (
        <Tippy
          reference={ref}
          content={<Tooltip title={tooltip} sub={tooltipSub} />}
        />
      )}
      {disabled && disabledTooltip != null && (
        <Tippy
          reference={disabledRef}
          disabled={false}
          content={<Tooltip title={disabledTooltip} sub={disabledTooltipSub} />}
        />
      )}
    </>
  );
};

Button.defaultProps = {
  color: Swatches.Default,
  size: Size.Large,
  working: false,
  disabled: false,
  fluid: false,
};

export default Button;
