import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Subtitle, ActionBar } from "ui-kit";
import LeaveRequestTable from "../leaveRequestTable";
import LeaveRequestsFilter from "./leaveRequestsFilter";
import { StaffSearch } from "sharedComponents/common";
import leaveActions from "areas/humanResources/actions/leaveActions";
import { leaveRoutes } from "areas/humanResources/hrRoutes";
import { RootState } from "reducers/store";
import { StaffListView } from "types/users/userListViews.types";


const LeaveAdministration = () => {

  const navigate = useNavigate();
  const { leaveRequests, loading, error, filter, paging } = useSelector((state: RootState) => state.leaveRequests);

  useEffect(() => {
    leaveActions.getAllLeaveRequests(
      { ...filter, schoolId: filter.schoolId === -1 ? null : filter.schoolId },
      0
    );
  }, [filter]);

  const onPage = () => {
    leaveActions.getAllLeaveRequests(
      { ...filter, schoolId: filter.schoolId === -1 ? null : filter.schoolId },
      paging.pageIndex + 1
    );
  };

  const handleSearch = (staffMember: StaffListView) => {
    navigate(leaveRoutes.getStaffLeavePath(staffMember.id));
  };

  return (
    <>
      <Subtitle>Staff Search</Subtitle>
      <ActionBar>
        <StaffSearch onChange={val => handleSearch(val)} />
      </ActionBar>

      <Subtitle>All Leave Requests</Subtitle>
      <LeaveRequestsFilter />

      <LeaveRequestTable
        requests={leaveRequests}
        error={error}
        paging={paging}
        handlePage={onPage}
        showStaff
        // onRowSelected={handleLeaveRequestSelected}
      />
    </>
  );
};

export default LeaveAdministration;
