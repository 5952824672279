import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Icon,
  neutral,
  honeygold,
  Orientation,
  typescale,
  displayFont,
  Spacing,
  fontStyle,
  Speed,
  BorderRadius
} from "../index";

const RadioListWrapper = styled.div<{disabled?: boolean, fluid?: boolean}>`
  display: inline-flex;
  box-sizing: box-content;

  ${fontStyle(displayFont.medium, typescale.paragraph)}

  ${({ disabled }) =>
    disabled
      ? `
    opacity: 0.75;
    cursor: not-allowed;
`
      : `
    .radio-list-item{
        &:hover {
        cursor: pointer;
        transistion: all ease ${Speed.Default}ms;
        background: ${neutral[600]};
        color: ${neutral[100]};
      }
    }

  `}


  ${({ fluid }) =>
    fluid &&
    `
    display: flex;
    width: 100%;
  `}
`;

const RadioListItemWrapper = styled.div<{orientation?: Orientation, selected: boolean}>`
  flex-basis: 0;
  display: flex;
  align-items: center;
  margin-left: ${Spacing.ExtraSmall}px;
  flex-grow: 1;

  background: ${neutral[200]};
  padding: ${Spacing.Default}px;
  color: ${neutral[700]};
  white-space: nowrap;
  i {
    color: ${neutral[700]};
  }

  ${({ orientation }) =>
    orientation === Orientation.Landscape
      ? `
    flex-direction: row;
    i {
      margin-right: ${Spacing.Default}px;
    }
  `
      : `
    flex-direction: column;
    justify-content: center;
    text-align: center;
    i {
      margin-bottom: ${Spacing.Default}px;
    }
  `}

  ${({ selected }) =>
    selected &&
    `
    background: ${honeygold};
    color: ${neutral[100]};
    i {
      color: ${neutral[100]};
    }
  `}

  &:first-child {
    border-top-left-radius: ${BorderRadius.Default}px;
    border-bottom-left-radius: ${BorderRadius.Default}px;
  }
  &:last-child {
    border-top-right-radius: ${BorderRadius.Default}px;
    border-bottom-right-radius: ${BorderRadius.Default}px;
    border-right: 0;
  }
`;

export interface IRadioListProps<TValue> {
  value?: string | boolean | number | {};
  children?: React.ReactElement<IRadioListItemProps>[];
  items?: IRadioListItemProps<TValue | null>[];
  onChange: (value: TValue | null) => void;
  orientation?: Orientation;
  fluid?: boolean;
  disabled?: boolean;
}

type valueTypes = string | boolean | number | object | JSX.Element;


const RadioList = <
  TValue extends valueTypes = any
>({
  children,
  items,
  value,
  onChange,
  orientation = Orientation.Landscape,
  fluid,
  disabled
}: IRadioListProps<TValue>) => {
  const [selectedValue, setSelectedValue] = useState(children?.[0].props.value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleSelectedItemChanged = (value: TValue) => {
    if (!disabled) {
      setSelectedValue(value);
      onChange?.(value);
    }
  };

  return (
    <RadioListWrapper className="radio-list" fluid={fluid} disabled={disabled}>
      {children?.map((child, index) => {
        return React.cloneElement(
          child as React.ReactElement<IRadioListItemProps>,
          {
            key: index,
            selected: child.props.value === selectedValue,
            onClick: handleSelectedItemChanged,
            orientation: orientation
          }
        );
      })}
      {items?.map((item, index) => (
        <RadioListItem
          key={children?.length ? index + children.length : index}
          value={item.value}
          icon={item.icon}
          label={item.label}
          selected={item.value === selectedValue}
          onClick={handleSelectedItemChanged}
          orientation={orientation}
        />
      ))}
    </RadioListWrapper>
  );
};


export interface IRadioListItemProps<TValue = any> {
  value: TValue;
  label: string;
  icon?: string;
  onClick?: (value: TValue) => void;
  selected?: boolean;
  orientation?: Orientation;
}

const RadioListItem: React.FC<IRadioListItemProps> = ({
  value,
  label,
  icon,
  onClick,
  selected = false,
  orientation = Orientation.Landscape
}) => {
  const handleClick = () => {
    onClick?.(value);
  };
  return (
    <RadioListItemWrapper
      className={`radio-list-item  ${
        selected ? "radio-list-item-selected" : ""
      }`}
      onClick={handleClick}
      selected={selected}
      orientation={orientation}
    >
      {icon && <Icon value={icon} />}
      <span>{label}</span>
    </RadioListItemWrapper>
  );
};

RadioList.Item = RadioListItem;

export default RadioList;
