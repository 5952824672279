import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import styled from "styled-components";
import {
  Spacing,
  BorderRadius,
  neutral,
  Speed,
  displayFont,
  typescale,
  fontStyle,
  honeygold,
  EmptyMessage,
  Swatches,
  Message,
} from "ui-kit";

const DropzoneWrapper = styled.div<{dragging?: boolean}>`
  position: relative;
  padding: ${Spacing.Large}px;
  border: 1px solid ${neutral[300]};
  border-radius: ${BorderRadius.Default}px;
  background: ${neutral[100]};
  transition: all ease ${Speed.Default}px;
  margin-bottom: ${Spacing.Large}px;

  .file-input {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }

  ${({ dragging }) =>
    dragging &&
    `
    cursor: grabbing;
    background: ${neutral[600]};
    color: ${neutral[100]} !important;
    i, h3, .label { color: ${neutral[100]} !important;;}

    h3,
    .emptymessage-body h3,
    .emptymessage-body span {
      color: white !important;
    }
  `}
`;

const UploadInfoWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  padding: ${Spacing.Large}px;
  border-radius: ${BorderRadius.Default}px;
  background: ${neutral[100]};

  .upload-info-inner {
    flex-grow: 1;
  }
  .upload-info-title {
  }

  .upload-info-progress {
    ${fontStyle(displayFont.medium, typescale.header3, neutral[100])}
  }
  .upload-info-progress-bar-track {
    background: ${neutral[300]};
    width: 100%;
    border-radius: ${BorderRadius.Large}px;
    text-align: right;
    margin-bottom: ${Spacing.Large}px;

    .upload-info-progress-bar-highlight {
      transition: all ease ${Speed.Default};
      background: ${honeygold};
      border-radius: ${BorderRadius.Large}px;
      padding: ${Spacing.Small}px ${Spacing.Large}px;
    }
  }
`;

interface IFileDropAreaProps {
  onDrop: (files: FileList) => void;
  uploadPercentage?: number | string;
  fileExtensions?: string | string[]
}

const FileDropArea: React.FC<IFileDropAreaProps> = ({ onDrop, uploadPercentage, fileExtensions }) => {

    const [dragging, setDragging] = useState(false);

    const { uploading, results, error } = useSelector((state: RootState) => state.upload);

    const fileInputRef = useRef() as React.RefObject<HTMLInputElement>;
    const uploadRef = useRef() as React.RefObject<HTMLSpanElement>;
    const progressRef = useRef() as React.RefObject<HTMLDivElement>;

    useEffect(() => {
        if (uploadRef.current && progressRef.current) {
            uploadRef.current.innerHTML = `${uploadPercentage}%`;
            progressRef.current.style.width = `${uploadPercentage}%`;
        }
    }, [uploadPercentage])


    const dragOver = (e: React.DragEvent) => {
      e.preventDefault();
      setDragging(true);
    };

    const dragEnter = (e: React.DragEvent) => {
      e.preventDefault();
      setDragging(true);
    };

    const dragLeave = (e: React.DragEvent) => {
      e.preventDefault();
      setDragging(false);
    };

    const handleFileInputClick = () => {
      fileInputRef.current && fileInputRef.current.click();
    };

    const fileDrop = (e: React.DragEvent) => {
      e.preventDefault();
      setDragging(false);

      const files = e.dataTransfer.files;

      if (files.length) {
        onDrop(files);
      }
    };

    const filesSelected = () => {
      if (fileInputRef.current && fileInputRef.current.files) {
        if (fileInputRef.current.files.length) {
          onDrop(fileInputRef.current.files);
        }
      }
    };

    // const removeFile = (file) => {
    //   const index = validFiles.findIndex((e) => e.name === file.name);
    //   const index2 = selectedFiles.findIndex((e) => e.name === file.name);
    //   const index3 = unsupportedFiles.findIndex((e) => e.name === file.name);
    //   validFiles.splice(index, 1);
    //   selectedFiles.splice(index2, 1);
    //   setValidFiles([...validFiles]);
    //   setSelectedFiles([...selectedFiles]);
    //   if (index3 !== -1) {
    //     unsupportedFiles.splice(index3, 1);
    //     setUnsupportedFiles([...unsupportedFiles]);
    //   }
    // };

    return (
      <>
        {uploading && (
          <UploadInfoWrapper className="upload-info">
            <div className="upload-info-inner">
              <EmptyMessage icon="cloud-upload" title="Uploading..." cover />
              <div className="upload-info-progress-bar-track">
                <div
                  className="upload-info-progress-bar-highlight"
                  ref={progressRef}
                >
                  <span className="upload-info-progress" ref={uploadRef}>
                    25%
                  </span>
                </div>
              </div>
            </div>
          </UploadInfoWrapper>
        )}
        <DropzoneWrapper
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
          dragging={dragging}
          onClick={handleFileInputClick}
        >
          <EmptyMessage
            icon="cloud-upload"
            title="Upload Files"
            summary="Drag and drop files here or click to choose"
            cover
          />
          <input
            ref={fileInputRef}
            className="file-input"
            type="file"
            multiple
            onChange={filesSelected}
            accept={fileExtensions ? fileExtensions.toString() : ""}
          />
        </DropzoneWrapper>
        {error && (
          <Message
            text="There was a problem uploading the files"
            color={Swatches.Danger}
          />
        )}
      </>
    );
};

export default FileDropArea;