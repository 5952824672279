import React from "react";
import { Route, Routes } from "react-router-dom";
import AssignmentEditor from "./assignmentEditor";
import AssignmentsWrapper from "./assignmentsWrapper";
import StaffAssignment from "./staffAssignment";
import { Constants } from "configuration";
import CustomRoute from "sharedComponents/layout/routes/customRoute";
import { assignmentRoutes } from "areas/planner/plannerRoutes";


const Index: React.FC = () => {

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <CustomRoute>
              <AssignmentsWrapper />
            </CustomRoute>
          }
        />

        <Route
          path={assignmentRoutes.editAssignment}
          element={
            <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
              <AssignmentEditor />
            </CustomRoute>
          }
        />

        <Route
          path={assignmentRoutes.newAssignment}
          element={
            <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
              <AssignmentEditor />
            </CustomRoute>
          }
        />

        <Route
          path={assignmentRoutes.assignment}
          element={
            <CustomRoute users={[Constants.USER_TYPES.STAFF]}>
              <StaffAssignment />
            </CustomRoute>
          }
        />
      </Routes>
    </>
  );
};

export default Index;
