import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Wizard,
  TextInput,
  Size,
  StructuredList,
  DateSelector,
  Card,
  Loader,
} from "ui-kit";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import PasswordEditor, {
  PasswordObj,
} from "sharedComponents/common/passwords/passwordEditor";
import registrationActions from "../actions/registrationActions";
import passwordActions from "areas/administration/actions/passwordActions";
import routes from "configuration/routes";
import { RootState } from "reducers/store";
import {
  StudentMediaConsentDetails,
  StudentRegistrationDetails,
  VerifyRegistrationCommand,
} from "../types/registrationRequest.types";

const RegistrationWizard = () => {
  const { authenticationCode } = useParams();
  const navigate = useNavigate();
  const { preflighting, preflightData, preflightError, error } = useSelector(
    (state: RootState) => state.registration
  );

  const [verificationDetails, setVerificationDetails] =
    useState<VerifyRegistrationCommand | null>(null);
  const [children, setChildren] = useState<StudentRegistrationDetails[]>([]);
  const [working, setWorking] = useState<boolean>(false);
  const [mediaConsent, setMediaConsent] = useState<
    StudentMediaConsentDetails[] | null
  >(null);
  const [password, setPassword] = useState<PasswordObj | null>(null);

  useEffect(() => {
    if (authenticationCode) {
      registrationActions.preflight(authenticationCode);
    } else {
      navigate(
        `${routes.registration.default}${routes.registration.preflightError}`
      );
    }
  }, []);

  useEffect(() => {
    if (preflightData) {
      preflightData.isRegistered &&
        navigate(
          `${routes.registration.default}${routes.registration.preflightRegistered}`
        );
      preflightData.isUnavailable &&
        navigate(
          `${routes.registration.default}${routes.registration.preflightUnavailable}`
        );
    }
  }, [preflightData]);

  const setMediaConsentForChild = (userId: string, consentGiven: boolean) => {
    setMediaConsent(
      mediaConsent.map(m =>
        m.studentId == userId ? { ...m, consentGiven: consentGiven } : m
      )
    );
  };

  const handleVerification = () => {
    setWorking(true);

    return new Promise((resolve, reject) => {
      const errors = [];

      if (!verificationDetails.firstName) {
        errors.push("Please enter your child's preferred first name.");
      }

      if (!verificationDetails.lastName) {
        errors.push("Please enter your child's preferred last name.");
      }

      if (!verificationDetails.dateOfBirth) {
        errors.push("Please enter your child's date of birth.");
      }

      if (errors.length > 0) {
        reject(errors.join(" "));
        setWorking(false);
      } else {
        registrationActions
          .verify(authenticationCode, {
            ...verificationDetails,
            firstName: verificationDetails.firstName.trim(),
            lastName: verificationDetails.lastName.trim(),
            dateOfBirth: moment
              .utc(verificationDetails.dateOfBirth, "DD/MM/YYYY")
              .toISOString(),
            authorisationCode: authenticationCode,
          })
          .then(response => {
            setChildren(response);
            setMediaConsent([
              ...response.map(child => ({
                studentId: child.studentId,
                consentGiven: false,
              })),
            ]);
            resolve(response);
          })
          .catch(error => {
            reject(
              "Your child's details could not be verified. Please check them and try again."
            );
          })
          .finally(() => setWorking(false));
      }
    });
  };

  const handlePassword = () => {
    setWorking(true);

    return new Promise((resolve, reject) => {
      if (!password.password) {
        setWorking(false);
        reject("Please enter a password.");
        return;
      }
      if (!password.check) {
        setWorking(false);
        reject("Please re-enter your password.");
        return;
      }
      if (password.check !== password.password) {
        setWorking(false);
        reject("Your passwords do not match.");
        return;
      }
      if (!password.result.has8) {
        setWorking(false);
        reject("Please enter a password with at least 8 characters");
        return;
      }
      if (!password.result.hasDigit) {
        setWorking(false);
        reject("Please enter a password with at least one number");
        return;
      }
      if (!password.result.hasLower) {
        setWorking(false);
        reject("Please enter a password with at least one lower case letter");
        return;
      }
      if (!password.result.hasUpper) {
        setWorking(false);
        reject("Please enter a password with at least one upper case letter");
        return;
      }
      if (!password.result.hasSpecial) {
        setWorking(false);
        reject(
          "Please enter a password with at least one punctuation/special character"
        );
        return;
      }

      passwordActions
        .check(password.password)
        .then(response => {
          if (response.isCompromised) {
            reject(
              "This password appears on a list of compromised passwords. Please choose another password."
            );
            return;
          } else {
            resolve(true);
          }
        })
        .catch(error => {
          reject(
            "Your password could not be analysed at this time. Please check try again later."
          );
          return;
        })
        .finally(() => {
          setWorking(false);
        });
    });
  };

  const handleFinish = () => {
    setWorking(true);

    return new Promise((resolve, reject) => {
      registrationActions
        .activate(authenticationCode, {
          userId: preflightData?.id,
          authorisationCode: authenticationCode,
          password: password.password,
          confirmPassword: password.check,
          mediaConsent: mediaConsent,
        })
        .then(() => {
          resolve(true);
        })
        .catch(err => {
          reject(
            "There was a problem activating your account. Please try again later."
          );
          return;
        })
        .finally(() => {
          setWorking(false);
        });
    });
  };

  if (preflighting) {
    return <Loader cover size={Size.Large} />;
  }

  if (preflightError) {
    navigate(
      `${routes.registration.default}${routes.registration.preflightError}`
    );
  }

  return (
    <Wizard
      showHeaderSteps={false}
      onFinish={handleFinish}
      error={error}
      working={working}
      finishButtonText="Activate My Account"
      completionButtonText="Login"
      onComplete={() => navigate("/")}
    >
      <Wizard.Step onValidate={handleVerification}>
        <p>
          To verify your identity, please enter the details of any one of your
          children that attend a Lionheart Educational Trust school and then
          click on the 'Next' button. Please use your child's preferred name
          that you supplied to the school at the time of registering their
          place.
        </p>
        <p>
          This step is just to confirm your identity. Your children have already
          been automatically added to our system and linked to you. You do not
          need to repeat the registration process for each of them.
        </p>

        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="Child's Preferred First Name" required>
                <TextInput
                  fluid
                  value={verificationDetails?.firstName}
                  placeholder="e.g. John"
                  onChange={value =>
                    setVerificationDetails({
                      ...verificationDetails,
                      firstName: value,
                    })
                  }
                  maxLength={30}
                />
              </StructuredList.Item>
              <StructuredList.Item name="Child's Preferred Last Name" required>
                <TextInput
                  fluid
                  value={verificationDetails?.lastName}
                  placeholder="e.g. Smith"
                  onChange={value =>
                    setVerificationDetails({
                      ...verificationDetails,
                      lastName: value,
                    })
                  }
                  maxLength={30}
                />
              </StructuredList.Item>
              <StructuredList.Item name="Child's Date of Birth" required>
                <DateSelector
                  fluid
                  dateFormat="DD/MM/YYYY"
                  onChange={value =>
                    setVerificationDetails({
                      ...verificationDetails,
                      dateOfBirth: value,
                    })
                  }
                />
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      </Wizard.Step>
      <Wizard.Step onValidate={handlePassword}>
        <Card title="Choose a Password">
          <Card.Body>
            <PasswordEditor onChange={password => setPassword(password)} />
          </Card.Body>
        </Card>
      </Wizard.Step>
      {/* <Wizard.Step>
        <Card title="Media Consent">
          <Card.Body>
            <p>
              Lionheart Educational Trust takes the protection of personal data
              seriously and wishes to uphold yours and your children's privacy
              rights under the latest Data Protection legislation.
            </p>
            <p>
              In order to ensure that we are adhering to your wishes we are
              seeking your consent for the use of images/pictures and video
              clips of your children (biometric data).
            </p>
            <p>
              We would like to give you this opportunity to 'opt-in' if you are
              happy to have your children's photograph or image captured to
              celebrate their life at school.
            </p>

            <p>
              "Celebrating life at school" would include using images for
              in-school display boards, social media celebration features (e.g.
              Twitter), news articles that will appear in newsletters and on our
              website and photographs of trips and sports fixtures.
            </p>

            <p>
              Please be aware that biometric data (photographs) are used for
              Student ID cards and in our Student Management Database (SIMS). As
              this is part of our legal safeguarding obligations use of this
              data falls outside of this request for consent.
            </p>
            <p>
              More details about how The Lionheart Educational Trust, processes
              student data can be found in our{" "}
              <a
                href="https://www.lionhearttrust.org.uk/policies"
                target="_blank"
              >
                Data Protection Policy
              </a>
              .
            </p>

            <p>
              <b>
                Please let us know whether you consent for us to use images or
                videos of your{" "}
                {strings.pluralize("child", "children", children)} by ticking
                the opt-in {strings.pluralize("box", "boxes", children)} below
                {strings.pluralize("", "for each of your children", children)},
                or leave {strings.pluralize("it", "them", children)} unticked if
                you do not consent.
              </b>
            </p>

            {children.map((child, index) => (
              <Checkbox
                key={index}
                value={false}
                text={`I am happy to have ${users.getPossessive(
                  child
                )} image captured to celebrate their life at school`}
                onChange={value => {
                  setMediaConsentForChild(child.studentId, value.checked);
                }}
              />
            ))}
          </Card.Body>
        </Card>
      </Wizard.Step> */}
      <Wizard.Step>
        <Card>
          <Card.Body>
            <p>
              To finish the registration process and activate your account,
              please click on <b>Activate My Account</b>.
            </p>
          </Card.Body>
        </Card>
      </Wizard.Step>
      <Wizard.Step completion>
        <Card>
          <Card.Body>
            <p>
              You have successfully registered to use Beehive and can now login
              in with your email and chosen password.
            </p>
            <p>
              If you have any questions about using Beehive, please contact your
              school's IT department.
            </p>
          </Card.Body>
        </Card>
      </Wizard.Step>
    </Wizard>
  );
};

export default RegistrationWizard;
