import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Tabs } from "ui-kit";
import { Constants } from "configuration";
import styled from "styled-components";
import studentInformationTabsActions from "areas/planner/actions/student/studentInformationTabsActions";
import StudentActivityWrapper from "../studentActivity/studentActivityWrapper";
import BehaviourPointsAccordion from "areas/behaviour/components/behaviours/behaviourPointsAccordion";
import RewardPointsAccordion from "areas/behaviour/components/rewards/rewardPointsAccordion";
import StudentAwardWrapper from "../award/studentAwardWrapper";
import QuizFormWrapper from "../../groups/quiz/quizFormWrapper";
import StudentNotesListWrapper from "../studentNote/studentNotesListWrapper";
import LeavingLessonReasons from "../leavingLesson/leavingLessonReasons";
import { useAppSelector } from "reducers/hooks";
import { ClassGroupStaffView } from "types/users/userGroups.types";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";
import { GroupType } from "types/planner/groups.types";


const Wrapper = styled.div`
  .tabs {
    height: unset;
  }
`;


interface IStudentInfoTabsProps {
  student: PlannerStudentDetailView;
  group: ClassGroupStaffView;
}


const StudentInfoTabs: React.FC<IStudentInfoTabsProps> = ({ student, group }) => {

  const { activeTab } = useAppSelector(state => state.studentInformationTabs);
  // const { group } = useSelector(state => state.group);
  const [_totalNotes, _setTotalNotes] = useState<number>(0);

  useEffect(() => {
    studentInformationTabsActions.setActiveTab(0);
    if (student) {
      _setTotalNotes(student.totalNotes);
    }
  }, [student]);

  const handleTabChange = (name: string, index: number) => {
    studentInformationTabsActions.setActiveTab(index);
  };

  return (
    <Wrapper>
      {group.type === GroupType.ClassGroup ||
      group.type === GroupType.Provision ? (
        <Tabs selectedIndex={activeTab} onTabSelected={handleTabChange}>
          <Tabs.Pane label="Activity">
            <StudentActivityWrapper
              studentId={student.id}
              schoolId={student.school?.id}
              tabIndex={0}
              currentTabIndex={activeTab}
            />
          </Tabs.Pane>

          <Tabs.Pane label="Rewards">
            <RewardPointsAccordion
              student={student}
              group={group}
              tabIndex={1}
              currentTabIndex={activeTab}
            />
          </Tabs.Pane>

          <Tabs.Pane label="Behaviours">
            <BehaviourPointsAccordion
              student={student}
              group={group}
              tabIndex={2}
              currentTabIndex={activeTab}
            />
          </Tabs.Pane>

          <Tabs.Pane label="Awards">
            <StudentAwardWrapper
              studentId={student.id}
              groupId={group.id}
              groupType={group.type}
              tabIndex={3}
              currentTabIndex={activeTab}
            />
          </Tabs.Pane>

          <Tabs.Pane label="Quiz">
            <QuizFormWrapper
              studentId={student.id}
              group={group}
              tabIndex={4}
              currentTabIndex={activeTab}
            />
          </Tabs.Pane>

          <Tabs.Pane label="Notes" badge={_totalNotes}>
            <StudentNotesListWrapper
              student={student}
              group={group}
              // tabIndex={5}
              // currentTabIndex={activeTab}
            />
          </Tabs.Pane>

          <Tabs.Pane label="Leaving Lesson">
            <LeavingLessonReasons
              student={student}
              group={group}
              tabIndex={6}
              currentTabIndex={activeTab}
            />
          </Tabs.Pane>
        </Tabs>
      ) : group.type === GroupType.TutorGroup ? (
        <Tabs selectedIndex={activeTab} onTabSelected={handleTabChange}>
          <Tabs.Pane label="Activity">
            <StudentActivityWrapper
              studentId={student.id}
              schoolId={student.school?.id}
              tabIndex={0}
              currentTabIndex={activeTab}
            />
          </Tabs.Pane>

          <Tabs.Pane label="Rewards">
            <RewardPointsAccordion
              student={student}
              group={group}
              tabIndex={1}
              currentTabIndex={activeTab}
            />
          </Tabs.Pane>

          <Tabs.Pane label="Behaviours">
            <BehaviourPointsAccordion
              student={student}
              group={group}
              tabIndex={2}
              currentTabIndex={activeTab}
            />
          </Tabs.Pane>

          <Tabs.Pane label="Notes" badge={_totalNotes}>
            <StudentNotesListWrapper
              student={student}
              group={group}
              // tabIndex={3}
              // currentTabIndex={activeTab}
            />
          </Tabs.Pane>

          <Tabs.Pane label="Leaving Lesson">
            <LeavingLessonReasons
              student={student}
              group={group}
              tabIndex={4}
              currentTabIndex={activeTab}
            />
          </Tabs.Pane>
        </Tabs>
      ) : group.type === GroupType.Custom ? (
        <Tabs selectedIndex={activeTab} onTabSelected={handleTabChange}>
          <Tabs.Pane label="Activity">
            <StudentActivityWrapper
              studentId={student.id}
              schoolId={student.school?.id}
              tabIndex={0}
              currentTabIndex={activeTab}
            />
          </Tabs.Pane>

          <Tabs.Pane label="Rewards">
            <RewardPointsAccordion
              student={student}
              group={group}
              tabIndex={1}
              currentTabIndex={activeTab}
            />
          </Tabs.Pane>

          <Tabs.Pane label="Behaviours">
            <BehaviourPointsAccordion
              student={student}
              group={group}
              tabIndex={2}
              currentTabIndex={activeTab}
            />
          </Tabs.Pane>

          <Tabs.Pane label="Notes" badge={_totalNotes}>
            <StudentNotesListWrapper
              student={student}
              group={group}
              // tabIndex={3}
              // currentTabIndex={activeTab}
            />
          </Tabs.Pane>
        </Tabs>
      ) : (
        <Tabs selectedIndex={activeTab} onTabSelected={handleTabChange}>
          <Tabs.Pane label="Activity">
            <StudentActivityWrapper
              studentId={student.id}
              schoolId={student.school?.id}
              tabIndex={0}
              currentTabIndex={activeTab}
            />
          </Tabs.Pane>

          <Tabs.Pane label="Notes" badge={_totalNotes}>
            <StudentNotesListWrapper
              student={student}
              group={group}
              // tabIndex={1}
              // currentTabIndex={activeTab}
            />
          </Tabs.Pane>
        </Tabs>
      )}
    </Wrapper>
  );
};

export default StudentInfoTabs;
