import { BeehiveReport, IBeehiveAction } from "types/common/action.types";
import provisionReportingActions from "../../actions/reporting/provisionReportingActions";

interface IProvisionReportingState {
  data: BeehiveReport;
  loading: boolean;
  error: string | null;
}

const INITIAL_STATE: IProvisionReportingState = {
  data: null,
  loading: false,
  error: null,
};


const provisionReportingReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IProvisionReportingState => {
  const {
    REPORTING_PROVISIONGROUPSTUDENT,
    REPORTING_PROVISIONGROUPTARGET,
    REPORTING_STUDENTPROVISIONMAPS,
    REPORTING_STUDENTPROVISION,
    REPORTING_STUDENTPASSPORTS,
  } = provisionReportingActions.types;

  switch (action.type) {
    case REPORTING_PROVISIONGROUPSTUDENT.START:
    case REPORTING_PROVISIONGROUPTARGET.START:
    case REPORTING_STUDENTPROVISION.START:
    case REPORTING_STUDENTPROVISIONMAPS.START:
    case REPORTING_STUDENTPASSPORTS.START:
      return { ...state, loading: true };
    case REPORTING_PROVISIONGROUPSTUDENT.SUCCESS:
    case REPORTING_PROVISIONGROUPTARGET.SUCCESS:
    case REPORTING_STUDENTPROVISION.SUCCESS:
    case REPORTING_STUDENTPROVISIONMAPS.SUCCESS:
    case REPORTING_STUDENTPASSPORTS.SUCCESS:
      return { ...state, data: action.payload };
    case REPORTING_PROVISIONGROUPSTUDENT.FAILED:
    case REPORTING_PROVISIONGROUPTARGET.FAILED:
    case REPORTING_STUDENTPROVISION.FAILED:
    case REPORTING_STUDENTPROVISIONMAPS.FAILED:
    case REPORTING_STUDENTPASSPORTS.FAILED:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

export default provisionReportingReducer;
