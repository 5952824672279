import defaults from "configuration/defaults";
import { arrays } from "utils";
import feedbackActions from "../actions/feedbackActions";
import { IBeehiveAction } from "types/common/action.types";
import { PageInfo } from "types/common/paging.types";
import { FeedbackMessageListView } from "../types/feedbackResponses.types";


export interface IFeedbackMessagesState {
  messages: FeedbackMessageListView[],
  error: string | null;
  loading: boolean;
  paging: PageInfo;
}


const INITIAL_STATE: IFeedbackMessagesState = {
  messages: [],
  error: null,
  loading: false,
  paging: defaults.DEFAULT_PAGING,
};


const feedbackMessagesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IFeedbackMessagesState => {
  const { 
    FEEDBACKMESSAGES_GET 
  } = feedbackActions.types;

  switch (action.type) {
    case FEEDBACKMESSAGES_GET.START:
      return {
        ...state,
        loading: arrays.isEmpty(state.messages),
        error: null,
      };

    case FEEDBACKMESSAGES_GET.SUCCESS:
      return {
        ...state,
        messages:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.messages, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };

    case FEEDBACKMESSAGES_GET.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default feedbackMessagesReducer;
