import { useEffect, useState } from "react";
import Subheader from "sharedComponents/layout/header/subheader";
import {
  EmptyMessage,
  Loader,
  Size,
  Title,
  TitleSize,
  Tabs,
  Swatches,
  Button,
  StructuredList,
  Card,
  Label,
  Currency,
  Chip,
  HeadlineStatistic,
  Message,
  ActionPanel,
  Checkbox,
} from "ui-kit";
import { SchoolAvatar, GroupList, UserList } from "sharedComponents/common";
import Moment from "react-moment";
import CurrencyFormat from "react-currency-format";
import { useSelector } from "react-redux";
import paymentActions from "areas/payments/actions/paymentsActions";
import reportingActions from "areas/payments/actions/reportingActions";
import { useParams } from "react-router-dom";
import { arrays, files, users } from "utils";
import ProductOrdersTable from "./productOrdersTable";
import { useNavigate } from "react-router";
import InstalmentsTable from "./instalmentsTable";
import { RootState } from "reducers/store";
import { BeehiveReport } from "types/common/action.types";
import { ProductDetails } from "./productEditor";
import { InstallmentType } from "areas/payments/types/shopkeeper/shopkeeperShared.types";
import { ProductCategoryDetailView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { useAppSelector } from "reducers/hooks";
import { GroupListView } from "types/users/userGroups.types";
import { groupRoutes } from "areas/planner/plannerRoutes";
import { Constants } from "configuration";


const ProductViewer = () => {

  const { productId, categoryId } = useParams();
  const { product, loading, error } = useSelector((state: RootState) => state.adminProduct);
  const { orders, loading: loadingOrders, error: ordersError } = useSelector((state: RootState) => state.adminProductOrders);
  const { loading: reporting, error: reportError, reportData } = useSelector((state: RootState) => state.paymentsProductReports);
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { category } = useAppSelector(state => state.adminCategory);
  const navigate = useNavigate()
  const [_category, _setCategory] = useState<ProductCategoryDetailView | null>(null);

  useEffect(() => {
    user && paymentActions.getProduct(parseInt(categoryId), parseInt(productId), () => paymentActions.getProductOrders(parseInt(categoryId), parseInt(productId)));
  }, []);

  useEffect(() => {
    if (categoryId && (!category || category?.id != parseInt(categoryId))) {
      paymentActions.getCategory(parseInt(categoryId), response => {
        _setCategory(response);
      });
    } else {
      _setCategory(category);
    }
  }, [category, categoryId])

  const openHtml = (report: BeehiveReport) => {
    var newWindow = window.open();
    newWindow.document.write(report);
  };

  const handlePurchaseReportClicked = () => {
    reportingActions.runSingleProductOrdersReport(parseInt(categoryId), parseInt(productId), (reportData: BeehiveReport) => openHtml(reportData));
  };

  const handleProductReportClicked = () => {
    reportingActions.runSingleProductRecipientsReport(parseInt(categoryId), parseInt(productId), (reportData: BeehiveReport) => openHtml(reportData));
  };

  const handleExportClicked = () => {
    reportingActions.runSingleProductExport(parseInt(categoryId), parseInt(productId), (reportData: BeehiveReport) => files.openCsv(reportData));
  };

  const handleEdit = () => {
    navigate(`/main/shopkeeper/category/${product.category.id}/products/${product.id}/edit`);
  };

  const handleGroupClick = (group: GroupListView) => {
    if (!group.isPrivate || (group.isMyGroup && group.isPrivate) || users.isInAnyRoles(user, [Constants.ROLES.ADMINISTRATOR, Constants.ROLES.DEVELOPMENT])) {
      navigate(groupRoutes.getGroupPath(group.id, group.type))
    }
  }

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem loading the product"
        cover
      />
    );
  }

  if (!product) {
    return (
      <EmptyMessage
        icon="shopping-bag"
        title="Not found"
        summary="The requested product was not found"
        cover
      />
    );
  }

  return (
    <>
      <Subheader>
        <Title
          size={TitleSize.H2}
          text="Inventory"
          sub={product.name}
          right={
            <Button
              color={Swatches.Primary}
              text="Edit Product"
              onClick={handleEdit}
            />
          }
        >
          {product.isVisibleInStore && (
            <Chip colorSwatch={Swatches.Success} text="Available in Store" />
          )}
        </Title>
      </Subheader>

      <HeadlineStatistic>
        <HeadlineStatistic.Item
          icon="tag"
          label="Price"
          value={
            <CurrencyFormat
              prefix={"£"}
              decimalScale={2}
              thousandSeparator=","
              fixedDecimalScale={true}
              displayType={"text"}
              value={product.unitPrice}
            />
          }
        />
        <HeadlineStatistic.Item icon="gift" label="Sold" value={product.sold} />

        {product.enforceStockControl && (
          <>
            <HeadlineStatistic.Item
              icon="boxes"
              label="Stock Limit"
              value={product.stock}
            />
            <HeadlineStatistic.Item
              icon="box-open"
              label="Remaining"
              value={product.stockRemaining}
            />
          </>
        )}
      </HeadlineStatistic>
      <Tabs>
        <Tabs.Pane name="Details" label="Details">
          <Card title="Details">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Name">
                  <Label bold>{product.name}</Label>
                </StructuredList.Item>
                <StructuredList.Item name="School">
                  <SchoolAvatar school={product.category.school} />
                </StructuredList.Item>
                <StructuredList.Item name="Category">
                  {product.category.name}
                </StructuredList.Item>
                <StructuredList.Item name="Price">
                  <Currency value={product.unitPrice} />
                </StructuredList.Item>
                <StructuredList.Item 
                  name="User Permissions"
                  description={
                    <p>
                      Choose which user types can buy this product             
                      <b> for themselves</b>. If students are selected, 
                      then their parents can also purchase for their children.
                    </p>
                  }
                >
                  <>
                  {_category?.allowParents && (<p>Allow parents to purchase for themselves: <b>{product.userRestrictions?.allowParents?.toString()}</b></p>)}
                  {_category?.allowStudents && ( <p>Allow students to purchase for themselves: <b>{product.userRestrictions?.allowStudents?.toString()}</b></p>)}
                  {_category?.allowStaff && (<p>Allow staff to purchase for themselves: <b>{product.userRestrictions?.allowStaff?.toString()}</b></p>)}
                  </>
                </StructuredList.Item>
                <StructuredList.Item 
                  name="Pupil Premium Discount"
                  hide={!product.userRestrictions?.allowStudents}
                >
                  {product.pupilPremiumDiscount ? (
                    <Currency value={product.pupilPremiumDiscount} />
                  ) : (
                    <p>No Pupil Premium Discount</p>
                  )}
                </StructuredList.Item>
                <StructuredList.Item 
                  name="Bursary Discount"
                  hide={!product.userRestrictions?.allowStudents}
                >
                  {product.bursaryDiscount ? (
                    <Currency value={product.bursaryDiscount} />
                  ) : (
                    <p>No Bursary Discount</p>
                  )}
                </StructuredList.Item>
                <StructuredList.Item 
                  name="Available For"
                  hide={!product.userRestrictions?.allowStudents}
                >
                  {arrays.isEmpty(product.groups) ? (
                    <span>
                      No groups set - item is available to all students
                    </span>
                  ) : (
                    <GroupList 
                      groups={product.groups} 
                      onGroupClick={handleGroupClick}
                    />
                  )}
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
          <Card title="Visibility">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item
                  name="Active"
                  description="Whether the item is active. If inactive the item will not be available for purchase at all."
                >
                  {product.isActive ? (
                    <Chip colorSwatch={Swatches.Success} text="Active" />
                  ) : (
                    <Chip colorSwatch={Swatches.Low} text="Inactive" />
                  )}
                </StructuredList.Item>
                <StructuredList.Item
                  name="Enabled in Store"
                  description="Whether the item is enabled in the public store. Note that an item can be active but not visible in the store."
                >
                  {product.isActive && product.isVisibleInStore ? (
                    <Chip colorSwatch={Swatches.Success} text="Enabled" />
                  ) : (
                    <Chip colorSwatch={Swatches.Low} text="Not Enabled" />
                  )}
                </StructuredList.Item>
                <StructuredList.Item
                  name="Expiry Date"
                  description="The date by which enabled items can be purchased in the public store."
                >
                  {product.isActive && product.isVisibleInStore ? (
                    product.hasExpiryDate ? (
                      <Moment date={product.expiryDate} format="DD/MM/YYYY" />
                    ) : (
                      <p>No Expiry Date</p>
                    )
                  ) : (
                    <p>
                      Item is inactive or disabled in store so is automatically
                      unavailable
                    </p>
                  )}
                </StructuredList.Item>
                <StructuredList.Item
                  name="Future Purchases"
                  description="Whether the product can be purchased by future parents or students."
                >
                  {product.allowFuture ? (
                    <Chip
                      colorSwatch={Swatches.Success}
                      text="Future Purchases Allowed"
                    />
                  ) : (
                    <Chip colorSwatch={Swatches.Low} text="Current Only" />
                  )}
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
          <Card title="Purchasing & Stock">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Allow Multiple Purchases">
                  {product.allowMultiple ? (
                    <Chip colorSwatch={Swatches.Success} text="Yes" />
                  ) : (
                    <Chip colorSwatch={Swatches.Low} text="No" />
                  )}
                </StructuredList.Item>
                <StructuredList.Item name="Allow Part Payment">
                  {product.allowPartPayment ? (
                    <Chip colorSwatch={Swatches.Success} text="Yes" />
                  ) : (
                    <Chip colorSwatch={Swatches.Low} text="No" />
                  )}
                </StructuredList.Item>
                <StructuredList.Item name="Requires Collection">
                  {product.requiresCollection ? (
                    <Chip colorSwatch={Swatches.Success} text="Yes" />
                  ) : (
                    <Chip colorSwatch={Swatches.Low} text="No" />
                  )}
                </StructuredList.Item>
                <StructuredList.Item name="Stock Control">
                  {product.enforceStockControl ? (
                    <Chip
                      text="Stock Control On"
                      colorSwatch={Swatches.Success}
                    />
                  ) : (
                    <Chip text="No Stock Control" colorSwatch={Swatches.Low} />
                  )}
                </StructuredList.Item>
                {product.enforceStockControl && (
                  <>
                    <StructuredList.Item name="Stock">
                      {product.stock != null ? product.stock.toString() : "N/A"}
                    </StructuredList.Item>
                    <StructuredList.Item name="Sold">
                      {product.sold}
                    </StructuredList.Item>
                    <StructuredList.Item name="Remaining">
                      {product.stockRemaining.toString()}
                    </StructuredList.Item>
                  </>
                )}
              </StructuredList>
            </Card.Body>
          </Card>
          <Card title="Instalments">
            <Card.Body>
              <StructuredList>
                <StructuredList.Item 
                  name={product?.instalmentRules.some(x => x.installmentType === InstallmentType.Default) && "Instalments"}
                >
                  <InstalmentsTable
                    product={product as ProductDetails}
                    editable={false}
                    installmentType={0}
                  />
                </StructuredList.Item>
                <StructuredList.Item 
                  name={product?.instalmentRules.some(x => x.installmentType === InstallmentType.PupilPremium) && "Pupil Premium Instalments"}
                  hide={!product.userRestrictions?.allowStudents}
                >
                  <InstalmentsTable
                    product={product as ProductDetails}
                    editable={false}
                    installmentType={1}
                    emptyMessage={"No Pupil Premium Instalments"}
                  />
                </StructuredList.Item>
                <StructuredList.Item 
                  name={product?.instalmentRules.some(x => x.installmentType === InstallmentType.Bursary) && "Bursary Instalments"}
                  hide={!product.userRestrictions?.allowStudents}
                >
                  <InstalmentsTable
                    product={product as ProductDetails}
                    editable={false}
                    installmentType={2}
                    emptyMessage={"No Bursary Instalments"}
                  />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
          <Card title="Interested Staff">
            <Card.Body>
              <UserList users={product.interestedStaff} />
            </Card.Body>
          </Card>
        </Tabs.Pane>
        <Tabs.Pane name="Orders" label="Orders">
          <ProductOrdersTable orders={orders} showCollection showPurchasedFor />
        </Tabs.Pane>
        <Tabs.Pane name="Reporting" label="Reporting">
          <Message
            icon="info-circle"
            text={
              <span>
                Reports open in a new tab, so your pop-up blocker must be
                disabled. For more info, please see{" "}
                <a
                  href="https://support.google.com/chrome/answer/95472?hl=en-GB&co=GENIE.Platform%3DDesktop"
                  target="_blank"
                >
                  How to allow pop-ups in Google Chrome
                </a>
              </span>
            }
          />
          {reportError && (
            <Message text={reportError} color={Swatches.Danger} />
          )}

          <ActionPanel
            label="Order Report"
            sub="This report shows a list of all orders for a product"
            buttonText="Run Order Report"
            buttonColor={Swatches.Primary}
            onClick={handlePurchaseReportClicked}
            working={reporting}
          />
          <ActionPanel
            label="Recipient Report"
            sub="This report shows all recipients of a product and their
            payment history"
            buttonText="Run Recipient Report"
            buttonColor={Swatches.Primary}
            onClick={handleProductReportClicked}
            working={reporting}
          />
          <ActionPanel
            label="Export Orders to CSV"
            sub="This exports order data to a CSV file"
            buttonText="Export Data to CSV"
            buttonColor={Swatches.Primary}
            onClick={handleExportClicked}
            working={reporting}
          />
        </Tabs.Pane>
      </Tabs>
    </>
  );
};

export default ProductViewer;
