import React from "react";
import {
  Route,
  useNavigate,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import Products from "./products";
import Product from "./product";
import Orders from "./orders";
import Order from "./order";
import ManagedProducts from "./managedProducts";
import ManagedProduct from "./managedProduct";
import DinnerMoney from "./dinnerMoney";
import { TabBar } from "ui-kit";
import { users } from "utils";
import { useSelector } from "react-redux";
import StaffLunchCredits from "./staffLunchCredits";
import { RootState } from "reducers/store";


const Store = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleTabClicked = (name: string, index: number) => {
    navigate("/main/store/" + name);
  };

  return (
    <>
      <TabBar onItemClicked={handleTabClicked}>
        <TabBar.Item
          key={0}
          name="products"
          text="Products"
          tooltip="Products"
          tooltipSub="View products that are available for you to purchase"
        />
        <TabBar.Item
          key={1}
          name="orders"
          text="Orders"
          tooltip="Orders"
          tooltipSub="View your past orders"
        />
        <TabBar.Item
          hide={!users.isStaff(user)}
          key={2}
          name="managedProducts"
          text="Managed Products"
          tooltip="Managed Products"
          tooltipSub="View products that you are managing"
        />
        <TabBar.Item
          hide={!user.children.some((x) => x.school.enableLunchCredits)}
          key={3}
          name="dinnermoney"
          text="Dinner Money"
          tooltip="Dinner Money"
          tooltipSub="Purchase dinner money for your children"
        />
        {/* <TabBar.Item
          hide={
            !users.isStaff(user) ||
            (!user.schools?.some((x) => x.enableLunchCredits) &&
              !user.baseSchool.enableLunchCredits)
          }
          key={4}
          name="lunchcredits"
          text="Lunch Credits"
          tooltip="Lunch Credits"
          tooltipSub="Purchase lunch credits"
        /> */}
      </TabBar>

      <Routes>

        <Route
          index
          element={ <Navigate to="/main/store/products" replace /> }
        />

        <Route
          path={`/products`}
          element={ <Products /> }
        />

        <Route
          path={`/products/:id`}
          element={ <Product /> }
        />

        <Route
          path={`/orders`}
          element={ <Orders /> }
        />

        <Route
          path={`/orders/:id`}
          element={ <Order /> }
        />

        <Route
          path={`/managedProducts`}
          element={ <ManagedProducts /> }
        />

        <Route
          path={`/managedProducts/categories/:categoryId/products/:productId`}
          element={ <ManagedProduct /> }
        />

        <Route
          path={`/dinnermoney`}
          element={ <DinnerMoney /> }
        />

        {/* <Route
          path={`/lunchcredits`}
          element={ <StaffLunchCredits /> }
        /> */}

      </Routes>
    </>
  );
};

export default Store;
