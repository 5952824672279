import startupActions from "actions/startup/startupActions";
import groupActions from "../../actions/group/groupActions";
import groupsActions from "../../actions/groups/groupsActions";
import customGroupActions from "../../actions/group/customGroupActions";
import { IBeehiveAction } from "types/common/action.types";
import { GroupListView } from "types/users/userGroups.types";
import { IBeehiveError } from "types/common/errors.types";


interface IGroupsState {
  groups: GroupListView[];
  favouriteGroups: GroupListView[];
  refresh: boolean;
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IGroupsState = {
  groups: [],
  favouriteGroups: [],
  refresh: false,
  loading: false,
  error: null,
};


const groupsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IGroupsState => {

  const { GETGROUPS } = groupsActions.types;
  const { GETUSER } = startupActions.types;
  const { FAVOURITEGROUP, UNFAVOURITEGROUP } = groupActions.types;
  const {
    CREATECUSTOMGROUP,
    DELETECUSTOMGROUP,
    SAVECUSTOMGROUP,
    CHANGECUSTOMGROUPSTATUS,
  } = customGroupActions.types;

  switch (action.type) {
    case GETUSER.SUCCESS:
      return {
        ...state,
        groups: [],
        favouriteGroups: [],
      };

    case GETGROUPS.START:
      return { ...state, loading: true, refresh: false, error: null };

    case GETGROUPS.SUCCESS:
      return {
        ...state,
        groups: action.payload,
        favouriteGroups: action.payload.filter((group: GroupListView) => group.isFavourite),
        loading: false,
      };

    case GETGROUPS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATECUSTOMGROUP.SUCCESS:
    case SAVECUSTOMGROUP.SUCCESS:
    case DELETECUSTOMGROUP.SUCCESS:
    case CHANGECUSTOMGROUPSTATUS.SUCCESS:
      return { ...state, refresh: true };

    case FAVOURITEGROUP.SUCCESS:
      return {
        ...state,
        groups: [...state.groups, { ...action.payload, isFavourite: true }],
        favouriteGroups: [
          ...state.favouriteGroups,
          { ...action.payload, isFavourite: true },
        ],
      };

    case UNFAVOURITEGROUP.SUCCESS:
      // remove group if the user is not a responsible staff for the group
      const groupIndex = state.groups.findIndex(
        group => group.id === action.payload.id
      );
      const tempGroups = state.groups.slice();
      if (groupIndex >= 0 && !state.groups[groupIndex].isMyGroup) {
        tempGroups.splice(groupIndex, 1);
      }

      // remove group from favourite groups list
      const favGroupIndex = state.favouriteGroups.findIndex(
        group => group.id === action.payload.id
      );
      const tempFavGroups = state.favouriteGroups.slice();
      if (favGroupIndex >= 0) {
        tempFavGroups.splice(favGroupIndex, 1);
      }
      return {
        ...state,
        groups: tempGroups,
        favouriteGroups: tempFavGroups,
      };

    default:
      return state;
  }
};

export default groupsReducer;
