import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  Loader,
  Size,
  EmptyMessage,
  Card,
  Sub,
  Spacing,
  fontStyle,
  typescale,
  displayFont,
  honeygold,
  neutral,
  Subtitle,
} from "ui-kit";
import { arrays } from "utils";
import linksActions from "./actions/linksActions";
import Subheader from "sharedComponents/layout/header/subheader";
import { RootState } from "reducers/store";
import { LinkListView } from "types/common/links.types";


const Wrapper = styled.section`
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: ${Spacing.Large}px;
  grid-row-gap: ${Spacing.Large}px;
  margin-bottom ${Spacing.ExtraLarge}px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const LinkWrapper = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  .card {
    width: 100%;
    margin-bottom: 0;
  }

  .link-library-title {
    ${fontStyle(displayFont.medium, typescale.header4, honeygold)}
    margin-bottom: ${Spacing.Small}px;
  }
  .sub {
    display: block;
    margin-bottom: ${Spacing.Large}px;
  }
  .link-library-description {
  }

  &:hover {
    cursor: pointer;
    .link-library-title {
      color: ${neutral[700]};
      transition: all ease 250ms;
    }
  }
`;


const Links = () => {

  const { links, loading, error } = useSelector((state: RootState) => state.links);

  useEffect(() => {
    linksActions.getLinksForUser();
  }, []);

  const openLink = (link: LinkListView) => {
    var win = window.open(link.url, "_blank");
    win.focus();
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem while loading the links"
        cover
      />
    );
  }

  if (arrays.isEmpty(links)) {
    return (
      <EmptyMessage
        icon="paste"
        title="No Links"
        summary="There are no links available"
        cover
      />
    );
  }

  return (
    <>
      <Subheader title="Links" />

      {links?.map((linkCategory, index) => (
        <React.Fragment key={index}>
          <Subtitle text={linkCategory.name} />

          <Wrapper>
            {linkCategory?.links?.map((link: LinkListView, index: number) => (
              <LinkWrapper key={index}>
                <Card
                  key={index}
                  className="link-library-item"
                  onClick={() => openLink(link)}
                >
                  <Card.Body>
                    <span className="link-library-title">{link.title}</span>
                    <Sub>{link.url}</Sub>
                    <p className="link-library-description">
                      {link.description}
                    </p>
                  </Card.Body>
                </Card>
              </LinkWrapper>
            ))}
          </Wrapper>
        </React.Fragment>
      ))}
    </>
  );
};

export default Links;
