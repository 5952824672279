import React from "react";
import styled from "styled-components";
import { IUiKitBaseProps } from "../index";

export interface IRightProps extends IUiKitBaseProps {
  children: any;
}

const Wrapper = styled.div``;

const Right: React.FC<IRightProps> = ({ children }) => {
  return <Wrapper className="right">{children}</Wrapper>;
};

export default Right;
