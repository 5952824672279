import React from "react";
import styled from "styled-components";
import {
  Swatches,
  neutral,
  textFont,
  displayFont,
  typescale,
  Button,
  Spacing,
  fontStyle,
} from "ui-kit";
import { useNavigate } from "react-router-dom";
import { Routes } from "configuration";


const Intro = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Heading = styled.div`
  ${fontStyle(displayFont.extraLight, typescale.header1, neutral[300])}
  margin-bottom: ${Spacing.ExtraLarge}px;
`;

const Text = styled.p`
  ${fontStyle(textFont.roman, typescale.paragraph, neutral[300])}
  max-width: 30rem;
  margin-bottom: ${Spacing.ExtraLarge}px;
`;


const LoginIntro = () => {

  const navigate = useNavigate();
  
  const handleClick = () => {
    navigate(Routes.registration.default);
  };

  return (
    <Intro>
      <Heading>Welcome to Beehive</Heading>
      <Text>
        Beehive is the location for everything you need for a successful school
        day. From news and timetables to assignments and smartcard topups,
        Beehive provides all your important information in one place.
      </Text>
      <Text>If you don't have a login yet, click below to activate your account today.</Text>
      <Button text="Activate" color={Swatches.Primary} onClick={handleClick} />
    </Intro>
  );
};

export default LoginIntro;
