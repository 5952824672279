import studentNoteActions from "../../actions/student/studentNoteActions";

const INITIAL_STATE = {
  studentNoteCategories: [],
  loading: false,
  error: null,
};

const { GETSTUDENTNOTECATEGORIES } = studentNoteActions.types;

const studentNoteCategoriesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GETSTUDENTNOTECATEGORIES.START:
      return { ...state, loading: true, error: null };

    case GETSTUDENTNOTECATEGORIES.SUCCESS:
      return {
        ...state,
        studentNoteCategories: action.payload,
        loading: false,
      };

    case GETSTUDENTNOTECATEGORIES.FAILED:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default studentNoteCategoriesReducer;
