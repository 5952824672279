import { Button, Size, Swatches } from "ui-kit";


interface IAddSanctionDayButtonProps {
  onClick: () => void;
}


const AddSanctionDayButton: React.FC<IAddSanctionDayButtonProps> = ({ onClick }) => {

  return (
    <Button
      text="Add Another Day/Session"
      icon="plus"
      onClick={onClick}
      size={Size.Small}
      color={Swatches.Primary}
    />
  );
};

export default AddSanctionDayButton;
