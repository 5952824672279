import { LessonExitReportView } from "areas/analytics/types/lessonExitAnalyticsResponse.types";
import React from "react";
import { Table } from "ui-kit";
import { formatTime, formatDate } from "utils/dateTime";


interface ILessonExitReportViewProps {
  leavingLessonRecord: LessonExitReportView;
}


const LeavingLessonRow: React.FC<ILessonExitReportViewProps> = ({ leavingLessonRecord }) => {

  return (
    <Table.Row>
      <Table.Cell>{`${leavingLessonRecord.studentFirstName} ${leavingLessonRecord.studentLastName}`}</Table.Cell>
      <Table.Cell>{`${
        leavingLessonRecord.classGroupName
          ? leavingLessonRecord.classGroupName
          : leavingLessonRecord.tutorGroupName
          ? leavingLessonRecord.tutorGroupName
          : leavingLessonRecord.provisionGroupName
          ? leavingLessonRecord.provisionGroupName
          : ""
      }`}</Table.Cell>
      <Table.Cell>{leavingLessonRecord.categoryName}</Table.Cell>
      <Table.Cell>{leavingLessonRecord.notes}</Table.Cell>
      <Table.Cell>
        {`${formatDate(leavingLessonRecord.createdDate)} ${formatTime(
          leavingLessonRecord.createdDate
        )}`}
      </Table.Cell>
      <Table.Cell>{leavingLessonRecord.createdBy}</Table.Cell>
    </Table.Row>
  );
};

export default LeavingLessonRow;
