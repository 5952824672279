import React from "react";
import styled from "styled-components";

export interface IBodyProps {
  children?: any;
}

const Wrapper = styled.div``;

const Body: React.FC<IBodyProps> = ({ children }) => {
  return <Wrapper className="body">{children}</Wrapper>;
};

export default Body;
