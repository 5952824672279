import { IBeehiveAction } from "types/common/action.types";
import leaveActions from "../actions/leaveActions";
import { LeaveRequestListView } from "../types/leaveResponse.types";

interface ILeaveRequestState {
  request: LeaveRequestListView | null;
  working: boolean;
  error: string | null;
  loading: boolean;
}

const INITIAL_STATE: ILeaveRequestState = {
  request: null,
  working: false,
  error: null,
  loading: true,
};

const leaveRequestReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ILeaveRequestState => {

  const {
    SUBMIT_LEAVEREQUEST,
    CANCEL_LEAVEREQUEST,
    APPROVE_LEAVEREQUEST,
    REJECT_LEAVEREQUEST,
    CONFIRM_LEAVEREQUEST,
    RESUBMIT_LEAVEREQUEST,
    LEAVEREQUEST_GET,
    UPDATEAPPROVER_LEAVEREQUEST,
  } = leaveActions.types;

  switch (action.type) {
    case LEAVEREQUEST_GET.START:
      return { ...INITIAL_STATE, loading: true };

    case LEAVEREQUEST_GET.SUCCESS:
      return { ...state, loading: false, request: action.payload };

    case LEAVEREQUEST_GET.FAILED:
      return {
        ...state,
        error: "There was a problem loading the leave request.",
        loading: false,
      };

    case SUBMIT_LEAVEREQUEST.START:
    case CANCEL_LEAVEREQUEST.START:
    case APPROVE_LEAVEREQUEST.START:
    case REJECT_LEAVEREQUEST.START:
    case CONFIRM_LEAVEREQUEST.START:
    case RESUBMIT_LEAVEREQUEST.START:
      return { ...state, working: true };

    case SUBMIT_LEAVEREQUEST.SUCCESS:
    case CANCEL_LEAVEREQUEST.SUCCESS:
    case APPROVE_LEAVEREQUEST.SUCCESS:
    case REJECT_LEAVEREQUEST.SUCCESS:
    case CONFIRM_LEAVEREQUEST.SUCCESS:
    case RESUBMIT_LEAVEREQUEST.SUCCESS:
      return {
        ...state,
        working: false,
      };

    case SUBMIT_LEAVEREQUEST.FAILED:
    case CANCEL_LEAVEREQUEST.FAILED:
    case APPROVE_LEAVEREQUEST.FAILED:
    case REJECT_LEAVEREQUEST.FAILED:
    case CONFIRM_LEAVEREQUEST.FAILED:
    case RESUBMIT_LEAVEREQUEST.FAILED:
      return {
        ...state,
        error: "There was a problem processing the leave request.",
        working: false,
      };

    case UPDATEAPPROVER_LEAVEREQUEST.START:
      return { ...state, working: true };

    case UPDATEAPPROVER_LEAVEREQUEST.SUCCESS:
      return { ...state, working: false };

    case UPDATEAPPROVER_LEAVEREQUEST.FAILED:
      return {
        ...state,
        error: "There was a problem updating the authoriser.",
        working: false,
      };

    default:
      return state;
  }
};

export default leaveRequestReducer;
