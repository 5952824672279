import { IBeehiveAction } from "types/common/action.types";
import detentionReportActions from "../actions/detentionReportActions";
import { IBeehiveError } from "types/common/errors.types";
import { DetentionReportView } from "../types/detentionAnalyticsResponse.types";


interface IYearGroupDetentionReportState {
  report: DetentionReportView | null;
  refresh: boolean;
  loading: boolean;
  loadingExportReport: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IYearGroupDetentionReportState = {
  report: null,
  refresh: false,
  loading: false,
  loadingExportReport: false,
  error: null,
};


const yearGroupDetentionReportReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IYearGroupDetentionReportState => {

  const { 
    GETYEARGROUPDETENTIONREPORT, 
    EXPORTYEARGROUPDETENTIONREPORT 
  } = detentionReportActions.types;

  switch (action.type) {
    case GETYEARGROUPDETENTIONREPORT.START:
      return { ...state, loading: true, error: null };

    case GETYEARGROUPDETENTIONREPORT.SUCCESS:
      return { ...state, report: action.payload, loading: false };

    case GETYEARGROUPDETENTIONREPORT.FAILED:
      return { ...state, loading: false, error: action.payload };

    case EXPORTYEARGROUPDETENTIONREPORT.START:
      return { ...state, loadingExportReport: true, error: null };

    case EXPORTYEARGROUPDETENTIONREPORT.SUCCESS:
      return { ...state, loadingExportReport: false };

    case EXPORTYEARGROUPDETENTIONREPORT.FAILED:
      return { ...state, loadingExportReport: false, error: action.payload };

    default:
      return state;
  }
};

export default yearGroupDetentionReportReducer;
