import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100%;

  &:hover {
    cursor: grab;
  }
`;


interface IDraggableProps {
  id?: string | number;
  className?: string;
  onDragStart?: (e: React.DragEvent) => void;
  onDragEnd?: (e: React.DragEvent) => void;
  handleDoubleClick?: (e: React.MouseEvent) => void;
  handleClick?: (e: React.MouseEvent) => void;
  children?: any | any[];
}

const Draggable: React.FC<IDraggableProps> = ({
  id,
  className,
  onDragStart,
  onDragEnd,
  handleDoubleClick,
  handleClick,
  children
}) => {
  const [timer, setTimer] = useState<number | NodeJS.Timeout>(0);
  const [prevent, setPrevent] = useState(false);
  const delay = 200;

  const onClick = () => {
    setTimer(
      setTimeout(() => {
        if (!prevent) {
          console.log("on click");
        }
      }, 200)
    );
  };

  const onDoubleClick = (e: React.MouseEvent) => {
    clearTimeout(timer);
    setPrevent(true);

    setTimeout(() => {
      console.log("on double click");
      setPrevent(false);
    }, 200);
  };

  // useEffect(() => {
  //   if (prevent) {
  //     setTimeout(() => {
  //       console.log("on double click");
  //       setPrevent(false);
  //     }, delay);
  //   }
  // }, [prevent]);

  return (
    <Wrapper
      className={`draggable ${className ? className : ""}`}
      data-id={id ? id : ""}
      draggable="true"
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDoubleClick={handleDoubleClick}
      onClick={handleClick}
    >
      {children}
    </Wrapper>
  );
};

Draggable.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  onDragStart: PropTypes.func,
  handleDoubleClick: PropTypes.func
};

export default Draggable;
