import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FeedArticle from "./_feedArticle";
import FeedItemFooter from "./feedItemFooter";
import FeedCalendarEvent from "./feedCalendarEvent";
import { Constants } from "configuration";
import styled from "styled-components";
import {
  typescale,
  DetailLabel,
  neutral,
  Spacing,
  fontStyle,
  Body,
  Left,
  Right,
  displayFont,
  Card,
} from "ui-kit";
import { SchoolIcon, FileList, LinkList } from "sharedComponents/common";
import { files, arrays } from "utils";
import FeedItemHeader from "./feedItemHeader";
import feedActions from "../actions/feedActions";
import { RootState } from "reducers/store";
import { FileListView } from "types/common/files.types";
import { IFeedItem } from "../reducers/feedReducer";


const FeedItemSchools = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  span {
    margin-right: ${Spacing.Small}px;
    &:last-child {
      margin-right: 0;
    }
  }

  .feed-items-schools-overflow {
    ${fontStyle(displayFont.medium, typescale.sub, neutral[600])}
  }
`;

const FeedItemSubheader = styled.div`
  display: flex;
  align-items: center;
  padding: ${Spacing.Medium}px;
  background: ${neutral[200]};

  .left {
    margin-right: ${Spacing.Default}px;
  }

  .body {
    flex-grow: 1;
  }

  .right {
  }

  .detail-label {
    ${fontStyle(displayFont.medium, typescale.paragraph, neutral[600])}
  }

  .links-list {
    margin-bottom: 0;
  }
`;

const FeedItemContent = styled.div`
  padding: ${Spacing.Medium}px;
  .file-list {
    padding-top: ${Spacing.Large}px;
  }
`;

const FeedLinks = styled.div`
  padding: 0 ${Spacing.Medium}px;
`;

const FeedFiles = styled.div`
  padding: 0 ${Spacing.Medium}px;
`;


const getFeedItemSubType = (item: IFeedItem) => {
  switch (item.type) {
    case Constants.FEED_ITEM_TYPES.NOTICE:
      return <FeedArticle item={item} />;
    case Constants.FEED_ITEM_TYPES.CALENDAR_EVENT:
      return <FeedCalendarEvent item={item} />;
    case Constants.FEED_ITEM_TYPES.FEATURE:
      return <FeedArticle item={item} />;
    default:
      return null;
  }
};


interface IFeedItemProps {
  item: IFeedItem;
  headerIcon?: string;
  headerPostMessage?: JSX.Element;
  children?: JSX.Element | JSX.Element[];
  onFavourite?: (article: IFeedItem) => void;
  onLike?: (article: IFeedItem) => void;
  onDismiss?: (article: IFeedItem) => void;
  titleSub?: JSX.Element | string;
  subheaderLeft?: JSX.Element;
  subheaderRight?: JSX.Element;
}


const FeedItem: React.FC<IFeedItemProps> = ({
  item,
  headerIcon,
  headerPostMessage,
  children,
  onFavourite,
  onLike,
  onDismiss,
  titleSub,
  subheaderLeft,
  subheaderRight,
}) => {

  const isMedia = (file: FileListView) => {
    return files.isMediaMimeType(file.mimeType);
  };

  const { user } = useSelector((state: RootState) => state.currentUser);

  const [_item, _setItem] = useState<IFeedItem>(item);
  const [media, setMedia] = useState<FileListView[]>(item?.files?.filter(x => isMedia(x)));

  useEffect(() => {
    item && _setItem(item);
  }, [item]);

  const handleMediaClick = (index: number) => {
    feedActions.openGallery(media, index);
  };

  const FeedSchools = () => {
    return (
      user?.schools.length > 0 && (
        <FeedItemSchools>
          {_item.schools.map(
            (school, index) =>
              index < 3 && <SchoolIcon key={index} code={school.code} />
          )}
          {_item.schools.length >= 3 && (
            <span className="feed-items-schools-overflow">
              +{_item.schools.length - 3}
            </span>
          )}
        </FeedItemSchools>
      )
    );
  };

  const handleDismiss = (article: IFeedItem) => {
    onDismiss?.(article);
  };

  const handleLike = (article: IFeedItem) => {
    onLike?.(article);
  };

  const handleFavourite = (article: IFeedItem) => {
    onFavourite?.(article);
  };

  return (
    <Card className="feed-item">
      <Card.Header className="feed-item-header">
        <Left>
          <FeedItemHeader
            user={_item.createdBy}
            date={_item.publishedDate}
            icon={headerIcon}
            category={_item.category}
            postMessage={headerPostMessage}
            groups={_item.groups}
            pinned={_item.isSticky}
          />
        </Left>
        <Right>
          <FeedSchools />
        </Right>
      </Card.Header>
      {media?.length > 0 && (
        <Card.Media
          className="feed-item-media"
          noPad
          media={media}
          onClick={handleMediaClick}
        />
      )}
      <Card.Body className="feed-item-body" noPad>
        <FeedItemSubheader className="feed-item-subheader">
          <>
            {subheaderLeft != null && <Left>{subheaderLeft}</Left>}
            <Body>
              <DetailLabel bold label={<>{item.title} </>} sub={titleSub} />
            </Body>
            {subheaderRight != null && <Right>{subheaderRight}</Right>}
          </>
        </FeedItemSubheader>

        {children != null && (
          <FeedItemContent className="feed-item-body-content">
            {children}
          </FeedItemContent>
        )}

        {!arrays.isEmpty(_item.files) && (
          <FeedFiles className="feed-item-files">
            <FileList title="Attachments">
              {_item.files
                ?.filter(file => !isMedia(file))
                .map((file, index) => (
                  <FileList.Item key={index} file={file} clickToDownload />
                ))}
            </FileList>
          </FeedFiles>
        )}

        {!arrays.isEmpty(_item.links) && (
          <FeedLinks className="feed-item-links">
            <LinkList title="Links">
              {_item.links?.map((link, index) => (
                <LinkList.Item key={index} link={link} />
              ))}
            </LinkList>
          </FeedLinks>
        )}
      </Card.Body>
      <Card.Footer className="feed-item-footer" noPad>
        <FeedItemFooter
          item={_item}
          onFavourite={handleFavourite}
          onLike={handleLike}
          onDismiss={handleDismiss}
        />
      </Card.Footer>
    </Card>
  );
};

export default FeedItem;
