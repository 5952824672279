import { IBeehiveAction } from "types/common/action.types";
import administrationActions from "../actions/administrationActions";

interface IAdministrationHomeState {
  selectedTab: number;
}

const INITIAL_STATE: IAdministrationHomeState = {
  selectedTab: 0,
};


const administrationHomeReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAdministrationHomeState => {

  const { ADMINISTRATION_HOMESWITCHTAB } = administrationActions.types;

  switch (action.type) {
    case ADMINISTRATION_HOMESWITCHTAB:
      return { ...state, selectedTab: action.payload };

    default:
      return state;
  }
};

export default administrationHomeReducer;
