import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tippy from "@tippyjs/react";
import { Tooltip } from "ui-kit";

const RoomLayoutGridCell = ({ row, col, roomSlots, setRoomSlots, preview }) => {
  const ref = useRef();
  const [slot, setSlot] = useState(null);

  useEffect(() => {
    const slot = roomSlots.find(
      roomSlot => roomSlot.y === row && roomSlot.x === col
    );
    setSlot(slot);
  }, [roomSlots, col, row]);

  const handleOnClickCell = () => {
    const tempRoomSlots = roomSlots.slice();
    const slotIndex = tempRoomSlots.findIndex(
      roomSlot => roomSlot.y === row && roomSlot.x === col
    );
    const isASeat = tempRoomSlots[slotIndex].isASeat;
    tempRoomSlots[slotIndex].isASeat = !isASeat;

    setRoomSlots(tempRoomSlots);
  };

  return (
    <>
      <div
        ref={ref}
        className={`room-col-${col} ${slot && slot.isASeat ? "seat" : ""} ${
          preview ? "preview" : ""
        }`}
        onClick={preview ? null : handleOnClickCell}
      >
        {/* x: {col}, y: {row} */}
      </div>
      <Tippy
        reference={ref}
        content={
          <Tooltip
            title={
              slot && slot.isASeat
                ? "Click to remove seat"
                : "Click to add seat"
            }
          />
        }
      />
    </>
  );
};

RoomLayoutGridCell.propTypes = {
  row: PropTypes.number,
  col: PropTypes.number,
  isASeat: PropTypes.bool,
  roomSlots: PropTypes.array,
  setRoomSlots: PropTypes.func,
  preview: PropTypes.bool
};

export default RoomLayoutGridCell;
