/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  neutral,
  typescale,
  textFont,
  fontStyle,
  BorderRadius,
  Subtitle,
  Spacing,
  Speed,
  Loader,
  formatScrollbars,
  Size,
  EmptyMessage,
  Swatches,
  Icon,
} from "../index";
import { IEmptyMessageProps } from "./emptyMessage";
import { useOnScreen } from "../../hooks";
import { honeygold } from "../common/colors";
import { CashlessPageInfo, PageInfo } from "types/common/paging.types";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .loader {
    z-index: 9999;
  }
  .empty-message {
    margin: ${Spacing.Large}px;
  }
`;

const TableWrapper = styled.table<{
  zebra?: boolean;
  grow?: boolean;
  col?: number;
}>`
  position: relative;
  width: 100%;
  table-layout: fixed;
  box-sizing: box-content;
  margin-bottom: ${Spacing.Large}px;
  border: 1px solid ${neutral[300]};
  border-radius: ${BorderRadius.Default}px;
  background: ${neutral[100]};

  ${fontStyle(textFont.roman, typescale.paragraph, neutral[700])}

  ${({ zebra }) =>
    zebra &&
    `
    tbody tr:nth-child(even) td {
      background: rgba(0,0,0,0.04)};
    }
  `}

    ${({ grow, col }) =>
    grow
      ? // Growing
        `
      display: flex;
      flex-flow: column;
      flex-grow: 1;
      justify-content: stretch;

      thead {
        display: flex;
        flex-flow: row;
        justify-content: stretch;
        padding-right: 16px; /* Width of a scrolbar */
        border-bottom: 1px solid ${neutral[300]};

        th {
          flex: 1;
          ${col && `flex-basis: ${col * 10}%;`};
        }
      }

      tbody {
        display: block;
        flex-grow: 1;
        flex-basis: 0;
        flex-shrink: 1;
        min-height: 350px;
        overflow-y: auto;
        width: 100%;
        ${formatScrollbars(neutral[100])}
      }

      tfoot {
        flex-shrink: 0;
        padding-right: 16px; /* Width of a scrolbar */
        // box-shadow: 0px -4px 5px rgba(50, 50, 50, 0.1);
        border-top: 1px solid ${neutral[300]};
      }

      tr {
        display: flex;
        flex-flow: row;
        flex: 1;
      }

      td {
        display: flex;
        flex-flow: row;
        flex: 1;
        align-items: center;
        flex-shrink: 0;
      }
    `
      : // Not growing
        `      
          th {
            border-bottom: 1px solid ${neutral[300]};
          }
    `}
`;

const HeaderWrapper = styled.thead`  
  color: ${neutral[600]};  
}
`;

const HeaderCellWrapper = styled.th<{
  right?: boolean;
  center?: boolean;
  hasSort: boolean;
  isSorting: boolean;
}>`
  position: relative;
  padding: ${Spacing.Default}px;
  vertical-align: middle;
  box-sizing: border-box;
  transition: all ease 350ms;

  ${fontStyle(textFont.medium, typescale.sub, neutral[500])}

  ${({ right }) => right && `text-align: right; justify-content: flex-end;`}
  ${({ center }) => center && `text-align: center;  justify-content: center;`}

  ${({ hasSort }) =>
    hasSort
      ? `
    cursor: pointer;
  `
      : `cursor: default;`}
  ${({ isSorting }) =>
    isSorting &&
    `
    background: rgba(0,0,0,0.05);  
    .icon, i { 
      position: absolute;
      right: ${Spacing.Medium}px;
      top: ${Spacing.Medium}px;
    }
  `}
`;

const BodyWrapper = styled.tbody<{ noHover?: boolean; onClick?: boolean }>`
  width: 100%;
  ${({ noHover, onClick }) =>
    !noHover &&
    `
      .table > tr:hover td,
      .table-row:hover > td {
        ${onClick && `cursor: pointer;`}
        transition: all ease ${Speed.Default};
        background: ${neutral[200]};
      }
    `}

  tr:last-child {
    td {
      border-bottom: 0 !important;
    }
  }

  tr.table-loader {
    td div {
      text-align: center;
      width: 100%;
      padding: ${Spacing.Default}px 0 ${Spacing.Small}px 0;
    }
  }
`;

const FooterWrapper = styled.tfoot`
  td {
    // border-top: 1px solid ${neutral[300]};
  }
`;

const CellWrapper = styled.td<{
  right?: boolean;
  center?: boolean;
  chip?: boolean;
  icon?: boolean;
}>`
  overflow-wrap: break-word;
  border-bottom: 1px solid ${neutral[200]};
  padding: ${Spacing.Default}px;
  ${({ right }) => right && `text-align: right; justify-content: flex-end;`}
  ${({ center }) => center && `text-align: center;  justify-content: center;`}
  vertical-align: middle;
  word-wrap: break-word;
  box-sizing: border-box;

  ${fontStyle(textFont.roman, typescale.paragraph, neutral[600])}

  ${({ chip }) =>
    chip &&
    `padding: 0 ${Spacing.Default}px 0 ${Spacing.Default}px !important;
    
    .chip {
      flex-grow: 1;
    }
    `}

    a {
    color: ${honeygold};
    transition: all ease 150ms;

    &:hover {
      color: ${neutral[700]};
    }
  }
`;

const RowWrapper = styled.tr<{ highlight?: boolean }>`
  width: 100%;

  &:last-child td {
    border-bottom: 0 !important;
  }

  ${({ highlight }) =>
    highlight &&
    `td {
    background: ${Swatches.Blue.swatch} !important;
    .label, .detail-label, .sub { color: white; }
    }
    
    &:hover td {
      background: ${neutral[700]} !important;
    }`}

  td.zebra:nth-child(even) {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const GroupWrapper = styled.tr`
  td {
    background: ${neutral[300]};
    padding: ${Spacing.Default}px;
    vertical-align: middle;

    ${fontStyle(textFont.medium, typescale.paragraph, neutral[600])}
  }
`;

export interface ITableProps {
  className?: string;
  children:
    | (
        | React.ReactElement<ITableHeaderProps>
        | React.ReactElement<ITableFooterProps>
        | React.ReactElement<ITableBodyProps>
      )[]
    | React.ReactElement<ITableBodyProps>;
  caption?: string;
  zebra?: boolean;
  loading?: boolean | number;
  empty?: boolean;
  emptyMessage?: React.ReactElement<IEmptyMessageProps>;
  error?: boolean;
  grow?: boolean;
  style?: object;
}

export interface ITableSortProps {
  name?: string;
  direction: string;
}

export interface ITableHeaderProps {
  children: React.ReactElement<ITableHeaderCellProps>[];
  onSort?: (args: ITableSortProps) => void;
}

export interface ITableHeaderCellProps extends ITableCellProps {
  sort?: string;
  onSort?: (name: string) => void;
  direction?: string;
  hide?: boolean;
}

export interface ITableFooterProps {
  children:
    | React.ReactElement<ITableCellProps>
    | React.ReactElement<ITableCellProps>[];
}

export interface ITableBodyProps {
  children:
    | React.ReactElement<ITableRowProps>
    | React.ReactElement<ITableRowProps>[];
  noHover?: boolean;
  onPage?: () => void;
  style?: React.CSSProperties;
}

interface ITableBodyPropsPaging extends ITableBodyProps {
  paging?: PageInfo;
  altPaging?: never;
}
interface ITableBodyPropsAltPaging extends ITableBodyProps {
  paging?: never;
  altPaging?: CashlessPageInfo;
}
type ITableBodyPropsWithPage = ITableBodyPropsPaging | ITableBodyPropsAltPaging;

export interface ITableRowProps {
  children:
    | React.ReactElement<ITableCellProps>
    | React.ReactElement<ITableCellProps>[];
  onClick?(): void;
  expandedContent?: React.ReactElement;
  highlight?: boolean;
  style?: React.CSSProperties;
}

export interface ITableGroupHeaderProps {
  label: any;
  colspan: number;
  style?: object;
}

export interface ITableCellProps {
  children?: React.ReactNode;
  right?: boolean;
  center?: boolean;
  colspan?: number;
  rowspan?: number;
  width?: number;
  chip?: boolean;
  icon?: boolean;
  show?: boolean;
  zebra?: boolean;
  hide?: boolean;
  style?: React.CSSProperties;
}

const getColumnWidth = (width: number | undefined) => {
  const columnWidth = width ? width * 10 + "%" : "";

  return {
    width: columnWidth,
    minWidth: columnWidth,
    flexBasis: columnWidth,
  };
};

const Table: React.FC<ITableProps> & {
  Header: typeof TableHeader;
  HeaderCell: typeof TableHeaderCell;
  Footer: typeof TableFooter;
  Body: typeof TableBody;
  Row: typeof TableRow;
  Cell: typeof TableCell;
  GroupHeader: typeof TableGroupHeader;
} = ({
  className,
  children,
  caption,
  zebra,
  loading,
  empty,
  emptyMessage,
  error,
  grow,
  style
}) => {
  if (error) {
    return (
      <Wrapper>
        <EmptyMessage icon="times-circle" title="A problem occurred" cover />
      </Wrapper>
    );
  }

  if (loading) {
    return (
      <Wrapper>
        <Loader size={Size.Medium} cover />
      </Wrapper>
    );
  }

  if (!loading && empty) {
    return <Wrapper>{emptyMessage}</Wrapper>;
  }

  return (
    <Wrapper>
      {caption && <Subtitle text={caption} />}
      <TableWrapper
        className={`table ${className ? className : ""}`}
        zebra={zebra}
        grow={grow}
        style={style}
      >
        {children}
      </TableWrapper>
    </Wrapper>
  );
};

export const TableHeader: React.FC<ITableHeaderProps> = ({
  children,
  onSort,
}) => {
  // const [_sortParameters, _setSortParameters] = useState<ITableSortProps>({
  //   name: "",
  //   direction: "ASC",
  // });

  // const handleSort = (name: string) => {
  //   var newParams: ITableSortProps = _sortParameters;

  //   if (_sortParameters.name === name) {
  //     // We are already sorting, so reverse direction.
  //     newParams = {
  //       ..._sortParameters,
  //       direction: _sortParameters.direction === "ASC" ? "DESC" : "ASC",
  //     };
  //   } else {
  //     newParams = { name, direction: "ASC" };
  //   }

  //   _setSortParameters(newParams);
  //   onSort?.(newParams);
  // };

  return (
    <HeaderWrapper className="table-header">
      <TableRow>
        {children?.filter(x => x.props?.show == null || x.props?.show)}
        {/* && // THIS THROWS AN ERROR WHEN DEALING WITH DYNAMIC TABLES
          children
            .filter(x => x.props?.show == null || x.props?.show)
            .map((child, index) => {
              return React.cloneElement(
                child as React.ReactElement<ITableHeaderCellProps>,
                {
                  onSort: handleSort,
                  key: index,
                  direction:
                    child?.props?.sort === _sortParameters.name
                      ? _sortParameters.direction
                      : undefined
                }
              );
            })} */}
      </TableRow>
    </HeaderWrapper>
  );
};

export const TableHeaderCell: React.FC<ITableHeaderCellProps> = ({
  children,
  right,
  colspan,
  width,
  center,
  sort,
  onSort,
  direction,
  hide,
  style
}) => {
  const handleClick = () => {
    onSort?.(sort);
  };

  const isSorting = direction != null;

  if (hide) {
    return <></>
  }

  return (
    <HeaderCellWrapper
      className="table-header-cell"
      onClick={handleClick}
      {...(colspan && { colSpan: colspan })}
      right={right}
      center={center}
      style={{...getColumnWidth(width), ...style}}
      hasSort={sort != null}
      isSorting={isSorting}
    >
      {children}
      {isSorting ? (
        direction === "ASC" ? (
          <Icon value="caret-up" />
        ) : (
          <Icon value="caret-down" />
        )
      ) : (
        ""
      )}
    </HeaderCellWrapper>
  );
};

export const TableFooter: React.FC<ITableFooterProps> = ({ children }) => {
  return <FooterWrapper className="table-footer">{children}</FooterWrapper>;
};

export const TableBody: React.FC<ITableBodyPropsWithPage> = ({
  children,
  noHover,
  paging,
  altPaging,
  onPage,
  style
}) => {

  const loader = useRef<HTMLTableRowElement>(null);
  const body = useRef<HTMLTableSectionElement>(null);

  const [_paging, _setPaging] = useState<PageInfo>(paging);
  const [_altPaging, _setAltPaging] = useState<CashlessPageInfo>(altPaging);

  const [_loading, _setLoading] = useState<boolean>(false);
  const isOnScreen = useOnScreen(loader, body);

  useEffect(() => {
    if (
      isOnScreen &&
      !_loading &&
      _paging &&
      _paging.pageNumber < _paging.totalPages
    ) {
      _setLoading(true);
      onPage?.();
    }
  }, [isOnScreen]);

  useEffect(() => {
    if (paging) {
      _setLoading(false);
      _setPaging(paging);
    }
  }, [paging]);

  useEffect(() => {
    if (
      isOnScreen &&
      !_loading &&
      _altPaging &&
      _altPaging.hasNextPage
    ) {
      _setLoading(true);
      onPage?.();
    }
  }, [isOnScreen]);

  useEffect(() => {
    if (altPaging) {
      _setLoading(false);
      _setAltPaging(altPaging);
    }
  }, [altPaging]);

  return (
    <BodyWrapper noHover={noHover} className="table-body" ref={body} style={style}>
      {children}

      <tr ref={loader} className="table-loader">
        <td>
          { ((_paging && _paging.pageNumber < _paging.totalPages) ||
            (_altPaging && _altPaging.hasNextPage)) && (
            <div>
              <Loader size={Size.Medium} />
            </div>
          )}
        </td>
      </tr>
    </BodyWrapper>
  );
};

export const TableRow: React.FC<ITableRowProps> = ({
  children,
  onClick,
  expandedContent,
  highlight,
  style
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    expandedContent ? setExpanded(!expanded) : onClick?.();
  };

  return (
    <>
      <RowWrapper
        className="table-row"
        onClick={handleClick}
        highlight={highlight}
        style={style}
      >
        {Array.isArray(children)
          ? children
              .map(x => x)
              .filter(x => x.props?.show == null || x.props?.show) // This approach is needed to avoid problems when there is just one row (i.e. not an array) and children.filter is not a function.
          : children}
      </RowWrapper>
      {expandedContent && expanded ? (
        <RowWrapper>{expandedContent}</RowWrapper>
      ) : null}
    </>
  );
};

export const TableGroupHeader: React.FC<ITableGroupHeaderProps> = ({
  label,
  colspan,
  style,
}) => {
  return (
    <GroupWrapper className="table-group" style={style}>
      <td colSpan={colspan}>{label}</td>
    </GroupWrapper>
  );
};

export const TableCell: React.FC<ITableCellProps> = ({
  children,
  colspan,
  rowspan,
  width,
  right,
  icon,
  center,
  chip,
  zebra,
  hide,
  style
}) => {

  if (hide) {
    return <></>
  }

  return (
    <CellWrapper
      className={`table-cell ${zebra ? "zebra" : null}`}
      {...(colspan && { colSpan: colspan })}
      {...(rowspan && { rowSpan: rowspan })}
      right={right}
      center={center}
      chip={chip}
      style={{...getColumnWidth(width), ...style}}
      icon={icon}
    >
      {children}
    </CellWrapper>
  );
};

Table.Header = TableHeader;
Table.HeaderCell = TableHeaderCell;
Table.Footer = TableFooter;
Table.Body = TableBody;
Table.Cell = TableCell;
Table.Row = TableRow;
Table.GroupHeader = TableGroupHeader;

export default Table;
