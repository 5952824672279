import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  ActionBar,
  Swatches,
  Size,
  StructuredList,
  DatePicker,
  Checkbox,
  Loader,
  TextInput,
  TextInputType,
  Card,
  Message,
  ValidationMessage,
} from "ui-kit";
import { arrays } from "utils";
import { useSelector } from "react-redux";
import managedMovesActions from "areas/planner/actions/managedMoves/managedMovesActions";
import { ManagedMoveDetailView, ManagedMoveListView } from "areas/planner/types/managedMoves/managedMovedResponse.types";
import { useAppSelector } from "reducers/hooks";
import { ManagedMoveStatus } from "areas/planner/types/managedMoves/managedMovesShared.types";
import { ApiExceptionMessage } from "sharedComponents/common";


interface IEndManagedMoveModalProps {
  open: boolean;
  move: ManagedMoveListView;
  onSuccess: (move: ManagedMoveListView) => void;
  onClose: (move: ManagedMoveListView) => void;
}


const EndManagedMoveModal: React.FC<IEndManagedMoveModalProps> = ({
  open,
  move: initialMove,
  onSuccess,
  onClose
}) => {

  const { move, loading, error, closing, closeError } = useAppSelector(
    state => state.managedMove
  );

  const [_move, _setMove] = useState<ManagedMoveDetailView>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    if (initialMove?.id) {
      managedMovesActions.getManagedMove(
        initialMove.student.id,
        initialMove.id,
        data => _setMove(data)
      );
    } else {
      _setMove({
        ...initialMove,
        notes: null,
        createdBy: null,
        updatedBy: null
      });
    }
  }, [initialMove]);

  const handleClose = () => {
    onClose?.(_move);
  };

  const handleEndManagedMove = () => {
    if (window.confirm("This will end the managed move. Are you sure?")) {
      // Validation
      var errors = [];

      if (_move.endDate == null) {
        errors.push("Please choose an end date.");
      }

      if (moment(_move.startDate).isAfter(_move.endDate)) {
        errors.push("The end date must be after the start date.");
      }

      setValidationErrors(errors);

      if (!arrays.isEmpty(errors)) {
        return;
      }

      var data = {
        id: _move.id,
        studentId: _move.student.id,
        endDate: _move.endDate,
        isPermanent: _move.status === ManagedMoveStatus.PermanentMove,
        notes: _move.notes,
      };

      managedMovesActions.closeManagedMove(data, newMove => {
        onSuccess?.(newMove);
      });
    }
  };

  return (
    <Modal open={open} onClose={handleClose} title="End Managed Move">
      <Modal.Body>
        <ApiExceptionMessage error={error} />
        <ApiExceptionMessage error={closeError} />
        <ValidationMessage errors={validationErrors} />
        {loading ? (
          <Loader cover size={Size.Large} fluid />
        ) : (
          <Card>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item required name="End Date">
                  <DatePicker
                    fluid
                    selectedDate={_move?.endDate}
                    onChange={value => _setMove({ ..._move, endDate: moment(value).format("YYYY-MM-DD") })}
                    closeOnSelect
                  />
                </StructuredList.Item>
                <StructuredList.Item
                  name="Permanence"
                  description="Has this resulted in a permanent move?"
                >
                  <Checkbox
                    text="This move is now permanent"
                    checked={_move?.status === ManagedMoveStatus.PermanentMove}
                    onChange={value =>
                      _setMove({ ..._move, status: value.checked ? ManagedMoveStatus.PermanentMove : null })
                    }
                  />
                </StructuredList.Item>
                <StructuredList.Item name="Notes">
                  <TextInput
                    fluid
                    type={TextInputType.Textarea}
                    value={_move?.notes}
                    onChange={value => _setMove({ ..._move, notes: value })}
                  />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Button
            text="End Managed Move"
            onClick={handleEndManagedMove}
            color={Swatches.Danger}
            size={Size.Small}
            working={closing}
          />
          <Button
            text="Cancel"
            onClick={handleClose}
            color={Swatches.Low}
            size={Size.Small}
            working={closing}
          />
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default EndManagedMoveModal;
