import React, { useState } from "react";
import styled from "styled-components";
import { useLocation, Link, useNavigate, Location } from "react-router-dom";
import qs from "query-string";
import { Routes } from "configuration";
import loginService from "services/authentication/loginService";
import * as yup from "yup";
import {
  Button,
  TextInput,
  TextInputType,
  Message,
  Swatches,
  neutral,
  Speed,
  textFont,
  displayFont,
  typescale,
  fontStyle,
  Spacing,
  Respond,
  Breakpoints,
} from "ui-kit";


const Wrapper = styled.form`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: ${Breakpoints.xs}px) {
    align-items: center;

    .separator {
      display: block;
      margin-bottom: 1rem;
    }

    button {
      width: 100%;
    }

    .register-button {
      width: auto;
      background: rgba(255, 255, 255, 0.7);
      margin-bottom: 3rem;
    }

    .textInput input {
      text-align: center;

      &:focus {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  button {
    margin-bottom: 1rem;
  }

  p {
    ${fontStyle(textFont.roman, typescale.sub, neutral[300])}

    a {
      color: ${Swatches.Primary.swatch};
      transition: all ${Speed.Default}ms ease;
      &:hover {
        color: white;
      }
    }
  }

  .message {
    margin-bottom: 1rem;
  }
  .textInput-inner {
    background: rgba(255, 255, 255, 0.15);
    margin-bottom: 1rem;

    input {
      color: white;
    }

    input::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }
  }
`;

const Heading = styled.div`
  ${fontStyle(displayFont.extraLight, typescale.header1, neutral[300])}
  margin-bottom: ${Spacing.ExtraLarge}px;
`;


const validationSchema = yup.object({
  username: yup
    .string()
    .max(50)
    .required("Please enter your email or username"),
  password: yup.string().max(50).required("Please enter your password"),
});


interface loginCredentials {
  username: string;
  password: string;
}


const LoginForm = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const handleRegisterClick = () => {
    navigate(Routes.registration.default);
  };

  const startupError = qs.parse(location.search).startupError;

  const [error, setError] = useState(null);
  const [loggingIn, setLoggingIn] = useState(false);
  const [credentials, setCredentials] = useState<loginCredentials>({
    username: "",
    password: "",
  });

  const setUsername = (username: string) => {
    setCredentials({ ...credentials, username });
  };

  const setPassword = (password: string) => {
    setCredentials({ ...credentials, password });
  };

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    validationSchema
      .validate(credentials, { abortEarly: false })
      .then(() => {
        setError(null);
        setLoggingIn(true);

        loginService
          .login(credentials.username, credentials.password)
          .then(() => {
            const preAuthLocation: Location = location.state?.preAuthLocation;
            if (preAuthLocation?.pathname) {
              navigate(preAuthLocation?.pathname);
            } else {
              navigate(Routes.home);
            }
          })
          .catch(error => {
            setError(error.error_description ? error.error_description : error);
          })
          .finally(() => {
            setLoggingIn(false);
          });
      })
      .catch((error: yup.ValidationError) => {
        setError(error.inner.map(x => x.message).join(". "));
      });
  };

  return (
    <Wrapper onSubmit={handleLogin}>
      <Heading>Sign In</Heading>

      <Message color={Swatches.Danger} text={error} fluid />
      {startupError && (
        <Message
          color={Swatches.Danger}
          text={"There was a problem loading your user account."}
          fluid
        />
      )}

      <TextInput
        placeholder="Email or username"
        value={credentials.username}
        onChange={value => setUsername(value)}
        required
        fluid
      />
      <TextInput
        placeholder="Password"
        value={credentials.password}
        type={TextInputType.Password}
        required
        fluid
        onChange={value => setPassword(value)}
      />
      <Button
        type="submit"
        text="Login"
        color={Swatches.Primary}
        working={loggingIn}
      />
      <Respond upper={Breakpoints.xs}>
        <p className="separator">or</p>
        <Button
          className="register-button"
          type="button"
          text="Register"
          color={Swatches.Primary}
          onClick={handleRegisterClick}
        />
      </Respond>
      <p>
        Forgotten your password?{" "}
        <Link to="/password/forgot">Reset it here.</Link>
      </p>
    </Wrapper>
  );
};

export default LoginForm;
