const VERSION: number = 3.5;
const SAGEPAY_NOTIFICATION_PREFIX: string = "/sagepay/notifications/";
const CARDNET_NOTIFICATION_PREFIX: string = "/cardnet/notifications/";

const config: IConfig = {
  name: "Beehive",
  version: VERSION,
  clientVersion: "4.0.0",
  clientId: "web",
  apiTimeout: process.env.REACT_APP_API_TIMEOUT,
  alerts: {
    showDefaultAlert: false,
    defaultAlert:
      "This is the Sandbox version of Beehive for training and demonstration. Data is from the 2020/21 Academic Year",
  },
  sentry_dsn: process.env.SENTRY_DSN,
  academicYear: {
    current: 11,
    currentName: "2024/25",
    currentStartDate: "2024-08-23T00:00:00",
    currentEndDate: "2025-08-26t00:00:00"
  },
  tokens: {
    endpoint: "token",
    accessTokenKey: "access_token",
    refreshTokenKey: "refresh_token",
  },
  pushNotifications: {
    key: "signalr",
    reconnectTimeout: 3000,
  },
  ui: {
    defaultMasterDetailWidth: 1920 / 2,
    editorApiKey: "k9s4r4mdxeukqc7mrign93b26zofvznzyw98lj16y5rlb73z",
  },
  baseApiUrl: (includeVersion) => {
    var url = `https://${process.env.REACT_APP_API_SUBDOMAIN}.lionhearttrust.org.uk/`;
    return includeVersion ? url + `v${VERSION}/` : url;
  },
  support: {
    telephone: "0116 2720 3100",
    email: "beehivesupport@lionhearttrust.org.uk",
  },
  supportedBrowsers: [
    // https://en.wikipedia.org/wiki/Safari_version_history
    {
      name: "safari",
      version: 13,
    },
    // https://en.wikipedia.org/wiki/Microsoft_Edge#Release_history
    {
      name: "edge",
      version: 79,
    },
    // https://en.wikipedia.org/wiki/Google_Chrome_release_history
    {
      name: "chrome",
      version: 81,
    },
    // https://en.wikipedia.org/wiki/Firefox_version_history
    {
      name: "firefox",
      version: 74,
    },
    // https://en.wikipedia.org/wiki/History_of_the_Opera_web_browser
    {
      name: "opera",
      version: 64,
    },
  ],
  enableHolidays: false,
  payments: {
    instalmentThresholdPrice: 50,
    SAGEPAY_NOTIFICATION_SUCCESS_URL: SAGEPAY_NOTIFICATION_PREFIX + "success",
    SAGEPAY_NOTIFICATION_NOTAUTHORISED_URL:
      SAGEPAY_NOTIFICATION_PREFIX + "notauthorised",
    SAGEPAY_NOTIFICATION_ERROR_URL: SAGEPAY_NOTIFICATION_PREFIX + "error",
    SAGEPAY_NOTIFICATION_CANCELLED_URL:
      SAGEPAY_NOTIFICATION_PREFIX + "cancelled",

    CARDNET_NOTIFICATION_SUCCESS_URL: CARDNET_NOTIFICATION_PREFIX + "success",
    CARDNET_NOTIFICATION_FAILURE_URL: CARDNET_NOTIFICATION_PREFIX + "failure",
  },
};

export default config;

export interface IConfig {
  name: string;
  version: number;
  clientVersion: string;
  clientId: string;
  apiTimeout: string | undefined;
  alerts: {
    showDefaultAlert: boolean;
    defaultAlert: string;
  };
  sentry_dsn: string | undefined;
  academicYear: {
    current: number;
    currentName: string;
    currentStartDate: string;
    currentEndDate: string;
  };
  tokens: {
    endpoint: string;
    accessTokenKey: string;
    refreshTokenKey: string;
  };
  pushNotifications: {
    key: string;
    reconnectTimeout: number;
  };
  ui: {
    defaultMasterDetailWidth: number;
    editorApiKey: string;
  };
  baseApiUrl: (includeVersion: any) => string;
  support: {
    telephone: string;
    email: string;
  };
  supportedBrowsers: {
    name: string;
    version: number;
  }[];
  enableHolidays: boolean;
  payments: {
    instalmentThresholdPrice: number;
    SAGEPAY_NOTIFICATION_SUCCESS_URL: string;
    SAGEPAY_NOTIFICATION_NOTAUTHORISED_URL: string;
    SAGEPAY_NOTIFICATION_ERROR_URL: string;
    SAGEPAY_NOTIFICATION_CANCELLED_URL: string;
    CARDNET_NOTIFICATION_SUCCESS_URL: string;
    CARDNET_NOTIFICATION_FAILURE_URL: string;
  };
}
