import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { urls } from "utils";
import client from "services/client";
import store from "reducers/store";
import { FilingFilter } from "../types/fileRequest.types";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { PagedQueryView } from "types/common/views.types";
import { TrayListView } from "../types/fileResponse.types";
import { IInTrayFilter } from "../reducers/inTrayReducer";
import { IOutTrayFilter } from "../reducers/outTrayReducer";

const builder = new actionTypeBuilder("filing");

const types = {
  INTRAY_GET: builder.build("intray_get"),
  INTRAY_FILTER: "intray_filter",
  OUTTRAY_GET: builder.build("outtray_get"),
  OUTTRAY_FILTER: "outtray_filter",
};


const getInTray = (userId: string, filter: FilingFilter, pageIndex?: number, callback?: callbackType<PagedQueryView<TrayListView>>) => {
  let _filter = {
    ...filter,
    schoolId: filter.schoolId < 0 ? null : filter.schoolId,
    status: filter.status < 0 ? null : filter.status,
  };

  let url = new urls.QueryString(`filing/users/${userId}/intray`);
  url.addParams({ ..._filter, pageIndex });

  return action<PagedQueryView<TrayListView>>(
    () => client.get(url.toUrl()), 
    types.INTRAY_GET, 
    callback
  );
};

const getOutTray = (userId: string, filter: FilingFilter, pageIndex?: number, callback?: callbackType<PagedQueryView<TrayListView>>) => {
  let _filter = {
    ...filter,
    schoolId: filter.schoolId < 0 ? null : filter.schoolId,
    status: filter.status < 0 ? null : filter.status,
  };

  let url = new urls.QueryString(`filing/users/${userId}/outtray`);
  url.addParams({ ..._filter, pageIndex });

  return action<PagedQueryView<TrayListView>>(
    () => client.get(url.toUrl()), 
    types.OUTTRAY_GET, 
    callback
  );
};

const filterInTray = (filter: IInTrayFilter) => {
  store.dispatch<IBeehiveAction<IInTrayFilter>>({
    type: types.INTRAY_FILTER,
    payload: filter,
  });
};

const filterOutTray = (filter: IOutTrayFilter) => {
  store.dispatch<IBeehiveAction<IOutTrayFilter>>({
    type: types.OUTTRAY_FILTER,
    payload: filter,
  });
};


const filingActions = {
  types,
  getInTray,
  getOutTray,
  filterInTray,
  filterOutTray,
};

export default filingActions;
