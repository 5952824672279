import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import StudentFeedFilters from "./studentFeedFilters";
import { Size } from "ui-kit/common/size";
import { Loader, EmptyMessage } from "ui-kit";
import studentActions from "areas/planner/actions/student/studentActions";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import ActivityFeed from "../../activityFeed/activityFeed";

const StudentActivityWrapper = ({
  studentId,
  schoolId,
  tabIndex,
  currentTabIndex,
}) => {
  const {
    studentActivityFeed,
    paging,
    refresh,
    loading,
    error,
    feedTypeFilter,
    createdDateFilter,
  } = useSelector(state => state.studentActivityFeed);

  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    if (tabIndex === undefined || tabIndex === currentTabIndex) {
      studentActions.getStudentActivityFeed(
        studentId,
        0,
        feedTypeFilter,
        createdDateFilter
      );
    }
  }, [feedTypeFilter, createdDateFilter, studentId, currentTabIndex]);

  useEffect(() => {
    if (pageIndex > 0) {
      studentActions.getStudentActivityFeed(
        studentId,
        pageIndex,
        feedTypeFilter,
        createdDateFilter
      );
    }
  }, [pageIndex]);

  useEffect(() => {
    setPageIndex(paging.pageIndex);
  }, [paging.pageIndex]);

  useEffect(() => {
    if (refresh) {
      studentActions.getStudentActivityFeed(
        studentId,
        0,
        feedTypeFilter,
        createdDateFilter
      );
    }
  }, [refresh]);

  const handleLoadMore = () => {
    setPageIndex(paging.pageIndex + 1);
  };

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return (
    <>
      <StudentFeedFilters />

      {pageIndex === 0 && loading ? (
        <Loader size={Size.Medium} fluid />
      ) : studentActivityFeed && studentActivityFeed.length > 0 ? (
        <ActivityFeed
          activities={studentActivityFeed}
          loading={loading}
          pagesAvailable={paging.pageNumber < paging.totalPages}
          handleLoadMore={handleLoadMore}
          schoolId={schoolId}
        />
      ) : (
        <EmptyMessage icon="list" title="No activity" />
      )}
    </>
  );
};

StudentActivityWrapper.propTypes = {
  studentId: PropTypes.string,
};

export default StudentActivityWrapper;
