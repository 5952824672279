import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DetentionForm from "./detentionForm";
import { useSelector } from "react-redux";
import { getDateTime, todayDate, formatDate } from "utils/dateTime";
import DetentionsOnDayWrapper from "./detentionsOnDayWrapper";
import { Size } from "ui-kit/common/size";
import moment from "moment";
import { formatTime } from "utils/dateTime";
import {
  IDetentionDetails,
  getDefaultDetentionInfo,
  getDetentionDate,
} from "../../utils/detentions";
import schoolActions from "areas/planner/actions/school/schoolActions";
import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import detentionTypesActions from "areas/behaviour/actions/detentions/detentionTypesActions";
import { Loader } from "ui-kit";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { useAppSelector } from "reducers/hooks";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { DetentionTypeListView } from "areas/behaviour/types/behaviourResponses.types";
import { PreflightDetentionDateView } from "areas/behaviour/types/detentionRegisterResponse.types";
import { getTime } from "areas/behaviour/utils/bulkDetentionReschedule";


interface IDetentionFormWrapperProps {
  studentId: string;
  schoolId: number;
  detentionDetails: IDetentionDetails;
  setDetentionDetails: (details: IDetentionDetails) => void;
  allowDetentionTypeChange?: boolean;
  allowDetentionDurationChange?: boolean;
}


const DetentionFormWrapper: React.FC<IDetentionFormWrapperProps> = ({
  studentId,
  schoolId,
  detentionDetails,
  setDetentionDetails,
  allowDetentionTypeChange,
  allowDetentionDurationChange,
}) => {

  const {
    detentionTypes,
    loading,
    error: detentionTypesError,
  } = useAppSelector(state => state.detentionTypes);
  const { schoolInformation } = useAppSelector(state => state.school);
  const { detentionRooms, error: detentionRoomsError } = useAppSelector(
    state => state.detentionRooms
  );
  const { loading: loadingDateCheck, error: dateCheckError } = useAppSelector(
    state => state.preflightDetentionDateCheck
  );

  const [detentionTypesArray, setDetentionTypesArray] = useState<IDropdownDataItemProps<string, number, null>[]>([]);
  const [roomsArray, setRoomsArray] = useState<IDropdownDataItemProps<string, number, null>[]>([]);
  const [dateCheckResponse, setDateCheckResponse] = useState<PreflightDetentionDateView>(null);

  useEffect(() => {
    if (!schoolInformation || schoolInformation.id !== schoolId) {
      schoolActions.getSchoolInformation(schoolId);
      detentionActions.getDetentionRooms(schoolId);
      detentionTypesActions.getDetentionTypes(schoolId);
    } else {
      if (!detentionTypes || detentionTypes.length === 0) {
        detentionTypesActions.getDetentionTypes(schoolId);
      }
      if (!detentionRooms || detentionRooms.length === 0) {
        detentionActions.getDetentionRooms(schoolId);
      }
    }
  }, []);

  useEffect(() => {
    if (detentionRooms.length > 0) {
      const roomsArray = detentionRooms.map(room => ({
        key: room.roomId,
        label: room.roomName,
        value: room.roomId,
      }));

      setRoomsArray(roomsArray);
    }
  }, [detentionRooms]);

  useEffect(() => {
    const detentions: IDropdownDataItemProps<string, number, null>[] = detentionTypes.map(detentionType => ({
      key: detentionType.detentionTypeId,
      label: detentionType.detentionTypeName,
      value: detentionType.detentionTypeId,
    }));

    if (detentionDetails && detentionDetails.detentionTypeId) {
      const selectedDetentionTypeId = detentionTypes.find(
        detentionType =>
          detentionType.detentionTypeId === detentionDetails.detentionTypeId
      );

      // legacy detention type
      if (selectedDetentionTypeId === undefined) {
        detentions.push({
          label: detentionDetails.detentionTypeName,
          value: detentionDetails.detentionTypeId,
        });
      }
    }

    setDetentionTypesArray(detentions);
  }, [detentionTypes, detentionDetails]);

  const handleDetentionTypeChange = (value: number) => {
    const selectedDetentionType = detentionTypes.find(
      detentionType => detentionType.detentionTypeId === value
    );

    setDetentionTypeDetails(selectedDetentionType);
  };

  const setDetentionTypeDetails = (selectedDetentionType: DetentionTypeListView) => {
    let tempDetentionDetails = getDefaultDetentionInfo(selectedDetentionType);

    if (
      roomsArray.length > 0 &&
      tempDetentionDetails.detentionLocationId === null
    ) {
      tempDetentionDetails = {
        ...tempDetentionDetails,
        detentionLocationId: roomsArray[0].value,
      };
    }

    const detentionDateTime = detentionDetails
      ? moment(
          formatDate(detentionDetails.date) + " " + tempDetentionDetails.time,
          "DD/MM/YYYY HH:mm"
        ).format()
      : moment(
          todayDate() + " " + tempDetentionDetails.time,
          "DD/MM/YYYY HH:mm"
        ).format();

    setDetentionDetails({
      ...tempDetentionDetails,
      date: moment(detentionDateTime),
      id: detentionDetails?.id,
      detentionNotes: null
    });

    const onDateCheckSuccess = (response: PreflightDetentionDateView) => {
      if (response.isDateValid === false && response.validDetentionDate) {
        const validDate = moment(response.validDetentionDate).format();

        setDetentionDetails({
          ...tempDetentionDetails,
          id: detentionDetails?.id,
          date: moment(validDate),
          time: formatTime(validDate),
          detentionNotes: null
        });
      } 
      else if (response.isDateValid === false && !response.validDetentionDate)
      {
        setDetentionDetails({
          ...tempDetentionDetails,
          date: getDetentionDate(
            schoolInformation.detentionCutOffTime,
            formatTime(selectedDetentionType.detentionInformation.startDate)
          ),
          detentionNotes: null,
          id: detentionDetails?.id
        });
      }
    };

    detentionActions.validateDetentionDate(
      studentId,
      {
        detentionTypeId: tempDetentionDetails.detentionTypeId,
        detentionDate: moment(detentionDateTime).format("YYYY-MM-DD HH:mm:ss"),
        duration: tempDetentionDetails.duration,
        detentionId: detentionDetails && detentionDetails.id ? detentionDetails.id : null
      },
      onDateCheckSuccess
    );
  };

  const validateDetentionDate = (detentionTypeId: number, date: Date, time: string, duration: number) => {
    setDateCheckResponse(null);
    const { hour, minute } = getTime(time);
    const onDateCheckSuccess = (response: PreflightDetentionDateView) => {
      if (!response.isDateValid && response.validDetentionDate) {
        setDateCheckResponse(response);

        let validDate = moment(response.validDetentionDate);

        setDetentionDetails({
          ...detentionDetails,
          date: validDate,
          time: formatTime(validDate),
          duration: duration
        });
      }
    };

    detentionActions.validateDetentionDate(
      studentId,
      {
        detentionTypeId: detentionTypeId,
        detentionDate: moment(date).hour(hour).minute(minute).format("YYYY-MM-DD HH:mm:ss"),
        duration: duration,
        detentionId : detentionDetails.id
      },
      onDateCheckSuccess
    );
  };

  if (loading) {
    return <Loader size={Size.Medium} fluid />;
  }

  return detentionDetails ? (
    <>
      {studentId && (
        <DetentionsOnDayWrapper
          studentId={studentId}
          date={getDateTime(formatDate(detentionDetails.date), detentionDetails.time)}
        />
      )}

      {allowDetentionTypeChange && detentionTypesError && (
        <ApiExceptionMessage error={detentionTypesError} />
      )}

      {detentionRoomsError && (
        <ApiExceptionMessage error={detentionRoomsError} />
      )}

      <DetentionForm
        detentionDetails={detentionDetails}
        onChangeDetentionDetails={setDetentionDetails}
        detentionTypesDropdownArray={detentionTypesArray}
        onChangeDetentionType={handleDetentionTypeChange}
        allowDetentionTypeChange={allowDetentionTypeChange}
        allowDetentionDurationChange={allowDetentionDurationChange}
        detentionRooms={roomsArray}
        validateDetentionDate={validateDetentionDate}
        dateCheckResponse={dateCheckResponse}
        dateCheckError={dateCheckError}
        loadingDateCheck={loadingDateCheck}
        schoolId={schoolId}
      />
    </>
  ) : null;
};


export default DetentionFormWrapper;
