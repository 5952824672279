import React from "react";
import PublicTemplate from "sharedComponents/layout/public/publicTemplate";
import { Route, Routes } from "react-router-dom";
import RegistrationRequest from "./registrationRequest";
import RegistrationWizard from "./registrationWizard";
import CustomRoute from "sharedComponents/layout/routes/customRoute";
import routes from "configuration/routes";
// import routes from "configuration/routes";
import PreflightError from "./preflight/error";
import PreflightUnavailable from "./preflight/unavailable";
import PreflightRegistered from "./preflight/registered";

const Register = () => {
  return (
    <PublicTemplate title="Activate your Beehive account">
      <Routes>
        <Route
          index
          element={
            <CustomRoute noAuth redirectIfAuthenticated>
              <RegistrationRequest />
            </CustomRoute>
          }
        />
        <Route
          path={routes.registration.preflightError}
          element={
            <CustomRoute noAuth redirectIfAuthenticated>
              <PreflightError />
            </CustomRoute>
          }
        />

        <Route
          path={routes.registration.preflightUnavailable}
          element={
            <CustomRoute noAuth redirectIfAuthenticated>
              <PreflightUnavailable />
            </CustomRoute>
          }
        />

        <Route
          path={routes.registration.preflightRegistered}
          element={
            <CustomRoute noAuth redirectIfAuthenticated>
              <PreflightRegistered />
            </CustomRoute>
          }
        />
        <Route
          path={routes.registration.registrationWizard}
          element={
            <CustomRoute noAuth redirectIfAuthenticated>
              <RegistrationWizard />
            </CustomRoute>
          }
        />
      </Routes>
    </PublicTemplate>
  );
};

export default Register;
