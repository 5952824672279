import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Size, boxShadow, Icon, neutral } from "ui-kit";
import { useSelector } from "react-redux";
import fileService from "services/files/fileService";
import { users } from "utils";
import { RootState } from "reducers/store";
import { UserStartupView } from "types/users/userStartup.types";
import { useAppSelector } from "reducers/hooks";

const Wrapper = styled.div<{
  size: number;
  allowImage?: boolean;
  url?: string;
  hide?: boolean;
}>`
  background-size: cover;
  background-position-x: center;
  background-position-y: 25%;
  background: ${neutral[200]};
  border-radius: 50%;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  box-shadow: ${boxShadow};
  border: 2px solid white;

  ${({ url }) =>
    url &&
    `
    background-size: cover;
    background-position-x: center;
    background-position-y: 25%;
    `}

  ${({ hide }) =>
    hide &&
    `
    display: none;
  `}

  ${({ allowImage }) => (allowImage ? `opacity: 100%;` : `opacity: 50%;`)}
`;

const IconWrapper = styled.div<{ size: number }>`
  .icon,
  .school-icon {
    border-radius: 50%;
    border: 2px solid white;
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    min-width: ${({ size }) => size}px;
    min-height: ${({ size }) => size}px;
  }

  .icon {
    background: ${neutral[300]};
    // box-sizing: border-box;

    svg {
      width: fit-content;
      height: fit-content;
      transform: translate(0px, -10%) scale(50%);
    }
  }
`;

const getSize = (size: Size): number => {
  switch (size) {
    case Size.ExtraSmall:
      return 18;
    case Size.Small:
      return 24;
    case Size.Medium:
      return 42;
    case Size.Large:
      return 56;
    case Size.ExtraLarge:
      return 72;
    case Size.Jumbo:
      return 100;
    default:
      return 100;
  }
};

interface IProfileImageProps {
  user: any;
  size?: Size;
  className?: string;
}

const ProfileImage: React.FC<IProfileImageProps> = ({
  user,
  size = Size.Large,
  className,
}) => {

  const { user: currentUser } = useAppSelector(state => state.currentUser);
  const { images: cachedImages } = useAppSelector(state => state.profileImageCache);
  const { isPrivate } = useAppSelector(state => state.privacy);

  const [imgData, setImgData] = useState<string | null>(null);
  const [allow, setAllow] = useState<boolean>(false);
  const [_user, _setUser] = useState<UserStartupView>(user);

  useEffect(() => {
    if (user) {
      _setUser(user);

      setAllow(getAllowStatus(user));

      if (users.isParent(user)) {
        setImgData(null);
      } 
      else if (cachedImages.some(cachedImage => cachedImage.userId === user.id)) {
        var cachedImage = cachedImages.find(cachedImage => cachedImage.userId === user.id);
        fetch(cachedImage.imageUrl)
          .then(response => response.arrayBuffer())
          .then(buffer => {
            var data = Buffer.from(buffer);
            setImgData(`data:image/jpeg;base64,${data.toString("base64")}`)
          })
      }
      else {
        fileService
          .getProfileImage(user.id)
          .then(data =>
            data
              ? setImgData(`data:image/jpeg;base64,${data}`)
              : setImgData(null)
          )
          .catch(error => setImgData(null));
      }
    }
  }, [user]);

  const getAllowStatus = (u: any): boolean => {
    if (u.id === currentUser.id || users.isStaff(currentUser)) {
      return true;
    }

    if (
      users.isParent(currentUser) &&
      currentUser.children.some((x: any) => x.id === u.id)
    ) {
      return true;
    }

    return false;
  };

  const ProfileIcon = () => {
    // if (!users.isParent(_user) && _user.school) {
    //   return <SchoolIcon code={_user.school?.code} size={size} />;
    // }

    return (
      <Icon
        className={`profile-image ${className ? className : ""}`}
        size={size}
        value="user"
      />
    );
  };

  return allow && imgData && !isPrivate.profileImage ? (
    <Wrapper
      style={{
        backgroundImage: `url(${imgData})`,
        backgroundSize: "cover",
        backgroundPositionX: "center",
        backgroundPositionY: "25%",
      }}
      className={`profile-image ${className ? className : ""}`}
      size={getSize(size)}
      allowImage={allow}
    ></Wrapper>
  ) : (
    <IconWrapper
      className={`profile-image ${className ? className : ""}`}
      size={getSize(size)}
    >
      {ProfileIcon()}
    </IconWrapper>
  );
};

export default ProfileImage;
