import React from "react";
import styled from "styled-components";
import {
  StructuredList,
  Dropdown,
  TextInput,
  TextInputType,
  Card,
  BorderRadius,
  fontStyle,
  honeygold,
  neutral,
  Spacing,
  textFont,
  typescale,
} from "ui-kit";
import OnCallModalContentWrapper from "./onCallModalContentWrapper";
import { MultiSchoolUserView, SchoolDropdown } from "sharedComponents/common";
import MultipleStudentWrapper from "./multipleStudentWrapper";
import SearchableDropdown from "sharedComponents/common/searchableDropdown";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";
import { OnCallRequestDetailView, OnCallRequestListView } from "areas/analytics/reducers/onCallAnalyticsResponse.types";
import { UserTinyView } from "types/users/userListViews.types";


const EditOnCallMessage = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-radius: ${BorderRadius.Default}px;
  padding: ${Spacing.Default}px ${Spacing.Large}px;
  margin-bottom: ${Spacing.Large}px;
  ${fontStyle(textFont.bold, typescale.paragraph, neutral[800])};
  background: ${honeygold};

  .training-video-link {
    color: ${neutral[100]};
    text-decoration-line: underline;
  }
`;


interface IOnCallFormProps {
  rooms: IDropdownDataItemProps<string, number, null>[];
  onCallCategories: IDropdownDataItemProps<string, number, null>[];
  onCallRequest: OnCallRequestListView;
  areNotesMandatory: boolean;
  handleRoomChange: (value: number) => void;
  handleCategoryChange: (value: number) => void;
  handleNotesChange: (value: string) => void;
  handleStudentListChange: (students: UserTinyView[]) => void;
  schoolId: number;
  handleSchoolChange: (value: number) => void;
}


const OnCallForm: React.FC<IOnCallFormProps> = ({
  rooms,
  onCallCategories,
  onCallRequest,
  areNotesMandatory,
  handleRoomChange,
  handleCategoryChange,
  handleNotesChange,
  handleStudentListChange,
  schoolId,
  handleSchoolChange,
}) => {

  return (
    <MultipleStudentWrapper
      students={onCallRequest.students}
      handleStudentListChange={handleStudentListChange}
      schoolId={schoolId}
      allowStudentListChange
    >
      <>
        <Card>
          <Card.Body>
            <StructuredList>
              <MultiSchoolUserView>
                <StructuredList.Item name="School" required>
                  <SchoolDropdown
                    onChange={handleSchoolChange}
                    initialValue={schoolId}
                    fluid
                    filter={school => school.enableOnCall}
                  />
                </StructuredList.Item>
              </MultiSchoolUserView>

              <StructuredList.Item
                name="Room"
                description="Choose a room or make a note of the location below."
              >
                <SearchableDropdown
                  items={rooms}
                  value={onCallRequest.roomId}
                  onChange={handleRoomChange}
                  fluid
                  clearable
                />
              </StructuredList.Item>

              <StructuredList.Item name="Reason" required>
                <Dropdown
                  fluid
                  items={onCallCategories}
                  onChange={value => handleCategoryChange(value)}
                  value={onCallRequest.category?.categoryDetail?.id}
                />
              </StructuredList.Item>

              <StructuredList.Item name="Notes" required={areNotesMandatory}>
                <TextInput
                  fluid
                  type={TextInputType.Textarea}
                  placeholder="Notes"
                  value={onCallRequest.notes}
                  onChange={value => handleNotesChange(value)}
                />
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>

        <EditOnCallMessage>
          You can edit or cancel this on call request after it has been created
          so please do not create a new on call request if you want to make
          changes to this request. For more information,{" "}
          <a
            className="training-video-link"
            href="https://vle.lionhearttrust.org.uk/mod/page/view.php?id=78185"
            target="_blank"
            rel="noreferrer"
          >
            you can watch our training video.
          </a>
        </EditOnCallMessage>
      </>
    </MultipleStudentWrapper>
  );
};

export default OnCallForm;
