import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";

const builder = new actionTypeBuilder("courses");

const types = {
  COURSES_GETCLASSGROUPCOURSES: builder.build("getcourse"),
};

const getClassGroupCourses = (classGroupId, onSuccess) => {
  return action(
    () => client.get(`planner/classgroups/${classGroupId}/courses`),
    types.COURSES_GETCLASSGROUPCOURSES,
    onSuccess
  );
};

const courseActions = {
  types,
  getClassGroupCourses,
};

export default courseActions;
