import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Table,
  EmptyMessage,
  Size,
  Button,
  Flyout,
  Swatches,
  StructuredList,
  StructuredListOrientation,
  Spacing,
  Label,
  Card,
  Message,
  Right,
  ActionBar,
} from "ui-kit";
import { arrays } from "utils";
import flyoutActions from "actions/ui/flyouts";
import DynamicField from "./dynamicField";
import ReadOnlyField from "./readOnlyField";
import { FieldGroupListView, FieldListView } from "../types/formResponse.types";

const EmptyWrapper = styled.div`
  padding: ${Spacing.Large}px;
`;

interface ITableEditorProps {
  group: FieldGroupListView;
  onSave?: (
    selectedItem: selectedItemFields,
    group: FieldGroupListView,
    index: number
  ) => void;
  onDelete?: (group: FieldGroupListView, index: number) => void;
  disabled?: boolean;
  error?: string;
}

export interface selectedItemFields {
  [key: string]: string;
}

const TableEditor: React.FC<ITableEditorProps> = ({
  group,
  onSave,
  onDelete,
  disabled,
  error,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [selectedItem, setSelectedItem] = useState<selectedItemFields>({});
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    !arrays.isEmpty(validationErrors.length) && window.scrollTo(0, 0);
  }, [validationErrors]);

  const handleAdd = () => {
    setSelectedIndex(null);
    setSelectedItem(null);
    flyoutActions.openFlyout(group.name);
  };

  const handleEdit = (index: number) => {
    let editable: selectedItemFields = {};

    setSelectedIndex(index);

    group.fields.forEach((field: FieldListView) => {
      editable[field.alias] = field.submittedValues[index]?.value;
    });

    setSelectedItem(editable);
    flyoutActions.openFlyout(group.name);
  };

  const handleDelete = (index: number) => {
    if (
      window.confirm(
        "Are you sure you want to delete this item? This cannot be undone."
      )
    ) {
      onDelete?.(group, index);
    }
  };

  const handleSave = () => {
    var errors: string[] = [];

    group.fields.forEach((field) => {
      if (field.isRequired && selectedItem[field.alias] == null) {
        console.log(selectedItem[field.alias]);
        errors.push(`${field.name} is required.`);
      }
    });

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    onSave?.(selectedItem, group, selectedIndex);
    setSelectedIndex(null);
    setSelectedItem({});
    flyoutActions.closeFlyout();
  };

  const handleCancel = () => {
    setSelectedIndex(null);
    setSelectedItem({});
    flyoutActions.closeFlyout();
  };

  const EmptyRow = ({ description }: { description: string }) => {
    return (
      <EmptyMessage
        icon="paste"
        title="Nothing added"
        summary={description ? description : "Please enter at least one item"}
        cover
      >
        <Button
          text="Click to Add"
          onClick={handleAdd}
          color={Swatches.Primary}
          size={Size.Small}
        />
      </EmptyMessage>
    );
  };

  return (
    <>
      {group.fields?.some((x) => x.submittedValues?.length > 0) ? (
        <Table zebra>
          <Table.Header>
            <>
              {group.fields
                ?.filter((x) => x.isActive)
                .map((field, index) => (
                  <Table.HeaderCell
                    key={index}
                    right={
                      field.fieldType === 1 ||
                      field.fieldType === 2 ||
                      field.fieldType === 5
                    }
                  >
                    {field.name}
                  </Table.HeaderCell>
                ))}
            </>
            <Table.HeaderCell />
          </Table.Header>

          <Table.Body>
            {[...Array(group.submittedRows)].map((a, b: number) => (
              <Table.Row key={b}>
                <>
                  {group.fields
                    .filter((x) => x.isActive)
                    .map((field, index) => (
                      <Table.Cell
                        key={index}
                        right={
                          field.fieldType === 1 ||
                          field.fieldType === 2 ||
                          field.fieldType === 5
                        }
                      >
                        {field.submittedValues[b] && (
                          <ReadOnlyField
                            field={field}
                            value={field.submittedValues[b]?.value}
                          />
                        )}
                      </Table.Cell>
                    ))}
                </>
                <Table.Cell right>
                  <ActionBar low>
                    <Right>
                      <Button
                        icon="pencil"
                        onClick={() => handleEdit(b)}
                        color={Swatches.Low}
                        size={Size.Small}
                      />
                      <Button
                        icon="trash"
                        color={Swatches.Low}
                        onClick={() => handleDelete(b)}
                        size={Size.Small}
                      />
                    </Right>
                  </ActionBar>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            {group.fields.some((field) => field.isSummable) && (
              <Table.Row>
                <>
                  {group.fields
                    .filter((x) => x.isActive)
                    .map((field, index) => (
                      <Table.Cell
                        key={index}
                        right={
                          field.fieldType == 1 ||
                          field.fieldType == 2 ||
                          field.fieldType === 5
                        }
                      >
                        {field.isSummable ? (
                          <Label bold>
                            <ReadOnlyField
                              field={field}
                              value={arrays.sum(field.submittedValues, "value")}
                            />
                          </Label>
                        ) : null}
                      </Table.Cell>
                    ))}
                </>
                <Table.Cell />
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell
                colspan={group.fields.filter((x) => x.isActive).length + 1}
                right
              >
                <Button
                  text="Add"
                  size={Size.Small}
                  onClick={handleAdd}
                  color={Swatches.Primary}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>
      ) : (
        <EmptyWrapper>
          <EmptyRow description={group.description} />
        </EmptyWrapper>
      )}

      <Flyout title={group.name} name={group.name} wide>
        <Flyout.Body>
          {!arrays.isEmpty(validationErrors) && (
            <Message
              text={validationErrors.map((err) => (
                <>
                  {err}
                  <br />
                </>
              ))}
              color={Swatches.Danger}
            />
          )}
          <Card>
            <Card.Body>
              <StructuredList orientation={StructuredListOrientation.Rows}>
                {group.fields
                  .filter((x) => x.isActive)
                  .map((field, index) => (
                    <StructuredList.Item
                      key={index}
                      name={field.name}
                      required={field.isRequired}
                      description={field.description}
                    >
                      <DynamicField
                        field={field}
                        value={selectedItem && selectedItem[field.alias]}
                        onChange={(value) =>
                          setSelectedItem({
                            ...selectedItem,
                            [field.alias]: value as string,
                          })
                        }
                      />
                    </StructuredList.Item>
                  ))}
              </StructuredList>
            </Card.Body>
          </Card>
        </Flyout.Body>
        <Flyout.Footer>
          <ActionBar low>
            <Button
              text="Save"
              onClick={handleSave}
              color={Swatches.Success}
              fluid
            />
            <Button
              text="Cancel"
              onClick={handleCancel}
              color={Swatches.Low}
              fluid
            />
          </ActionBar>
        </Flyout.Footer>
      </Flyout>
    </>
  );
};

export default TableEditor;
