import assignedStaffAnalyticsReducer from "./assignedStaffAnalyticsReducer";
import assignmentAnalyticsReducer from "./assignmentAnalyticsReducer";
import awardAnalyticsReducer from "./awardAnalyticsReducer";
import behaviourAnalyticsReducer from "./behaviourAnalyticsReducer";
import behaviourDetailedReportReducer from "./behaviourDetailedReportReducer";
import classBehaviourReportReducer from "./classBehavourReportReducer";
import coCurricularAnalyticsReducer from "./coCurricularAnalyticsReducer";
import detentionsDetailedReportReducer from "./detentionsDetailedReportReducer";
import groupLeaderboardReducer from "./groupLeaderboardReducer";
import leavingLessonAnalyticsReducer from "./leavingLessonAnalyticsReducer";
import onCallReportReducer from "./onCallReportReducer";
import smartTaskReportReducer from "./smartTaskReportReducer";
import studentBehaviourReportReducer from "./studentBehaviourReportReducer";
import yearGroupDetentionReportReducer from "./yearGroupDetentionReportReducer";
import yearGroupReportReducer from "./yearGroupReportReducer";

const analytics = {
  assignmentAnalytics: assignmentAnalyticsReducer,
  assignedStaffAnalytics: assignedStaffAnalyticsReducer,
  awardAnalytics: awardAnalyticsReducer,
  behaviourAnalytics: behaviourAnalyticsReducer,
  behaviourDetailedReport: behaviourDetailedReportReducer,
  classBehaviourReport: classBehaviourReportReducer,
  coCurricularAnalytics: coCurricularAnalyticsReducer,
  detentionsDetailedReport: detentionsDetailedReportReducer,
  groupLeaderboard: groupLeaderboardReducer,
  leavingLessonAnalytics: leavingLessonAnalyticsReducer,
  onCallReport: onCallReportReducer,
  smartTaskReport: smartTaskReportReducer,
  studentBehaviourReport: studentBehaviourReportReducer,
  yearGroupDetentionReport: yearGroupDetentionReportReducer,
  yearGroupReport: yearGroupReportReducer,
};

export default analytics;
