import React, { useState } from "react";
import InitialRewardActionWrapper from "./initialRewardActionWrapper";
import { StructuredList, Card, Spacing } from "ui-kit";
import PublicNotesFormField from "areas/behaviour/components/behaviourCommon/publicNotesFormField";
import styled from "styled-components";
import { ActionBar, Button, DatePicker, Right, Size, Swatches } from "ui-kit";
import StudentClassesDropdown from "areas/planner/components/studentRecord/classList/studentClassesDropdown";
import InternalNoteFormField from "areas/behaviour/components/behaviourCommon/internalNoteFormField";
import { RewardDetails, RewardDetailsClass, RewardDetailsInitialAction } from "./addRewardModal";
import { GroupListView, GroupType } from "types/users/userGroups.types";
import moment from "moment";


const HousePointsWrapper = styled.div`
  display: flex;

  .dropdown {
    margin-left: ${Spacing.Medium}px;
    flex-grow: 1;
  }
`;


interface IAddRewardFormProps {
  rewardDetails: RewardDetails;
  handleSetRewardDetails: (reward: RewardDetails) => void;
  isPrimary?: boolean;
  belongsToHouse?: GroupListView;
  group?: { id: number, type: GroupType };
  studentId: string;
  rewardsToAdd: RewardDetails[];
  handleIssueReward: () => void;
  handleSaveReward: () => void;
  updatingReward: boolean;
  handleUpdateReward: () => void;
  handleCancelUpdate: () => void;
  handleCloseModal: () => void;
}


const AddRewardForm: React.FC<IAddRewardFormProps> = ({
  rewardDetails,
  handleSetRewardDetails,
  isPrimary,
  belongsToHouse,
  group,
  studentId,
  rewardsToAdd,
  handleIssueReward,
  handleSaveReward,
  updatingReward,
  handleUpdateReward,
  handleCancelUpdate,
  handleCloseModal,
}) => {

  const [housePointsChecked, setHousePointsChecked] = useState<boolean>(false);

  const handleHousePointCheckbox = () => {
    if (housePointsChecked) {
      handleSetRewardDetails({ ...rewardDetails, housePoints: null });
    }
    setHousePointsChecked(!housePointsChecked);
  };

  return (
    <>
      <Card title="Reward Details">
        <Card.Body>
          <StructuredList>
            {/* {!groupId && ( */}
            <StructuredList.Item
              name="Class or Group"
              description="You can optionally choose a class group, tutor group or custom group for this reward."
              required
            >
              <StudentClassesDropdown
                studentId={studentId}
                selectedClass={rewardDetails.class}
                onClassChange={(newClass: RewardDetailsClass) =>
                  handleSetRewardDetails({ ...rewardDetails, class: newClass })
                }
                showNoGroupMessage={!group}
              />
            </StructuredList.Item>
            {/* )} */}
            {/* {isPrimary && selectedCategory.allowOverridePointsForPrimary && (
              <StructuredList.Item name="Number of Points" required>
                <PointsDropdown
                  points={points}
                  setPoints={setPoints}
                  maxPoints={selectedCategory.maxPoints}
                />
              </StructuredList.Item>
            )} */}

            <StructuredList.Item
              name="Date"
              description="The date that the reward is being issued for."
              required
            >
              <DatePicker
                dateFormat="DD/MM/YYYY"
                selectedDate={moment(rewardDetails.incidentDate).toDate()}
                onChange={date =>
                  handleSetRewardDetails({
                    ...rewardDetails,
                    incidentDate: moment(date).format(),
                  })
                }
                closeOnSelect
              />
            </StructuredList.Item>

            <InitialRewardActionWrapper
              selectedInitialAction={rewardDetails.initialAction}
              handleInitialActionChange={(initialAction: RewardDetailsInitialAction) =>
                handleSetRewardDetails({
                  ...rewardDetails,
                  initialAction: initialAction,
                })
              }
            />

            {/* {belongsToHouse && (
              <StructuredList.Item
                name="House Points"
                description="Select the number of house points to give with this reward"
                helpPopup
              >
                <HousePointsDropdown
                  housePointsChecked={housePointsChecked}
                  onChangeHousePointsCheckbox={handleHousePointCheckbox}
                  housePoints={housePoints}
                  onChangeHousePoints={value => setHousePoints(value)}
                />
              </StructuredList.Item>
            )} */}

            <StructuredList.Item
              name="Public Notes"
              description="Maximum 250 characters. (These notes will be sent/displayed to parents)"
              helpPopup
            >
              <PublicNotesFormField
                notes={rewardDetails.publicNotes}
                handleNotesChange={value =>
                  handleSetRewardDetails({
                    ...rewardDetails,
                    publicNotes: value,
                  })
                }
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Internal Notes"
              description="These notes will not be sent/displayed to parents"
              helpPopup
            >
              <InternalNoteFormField
                notes={rewardDetails.internalNotes}
                handleNoteChange={value =>
                  handleSetRewardDetails({
                    ...rewardDetails,
                    internalNotes: value,
                  })
                }
              />
            </StructuredList.Item>
          </StructuredList>

          <ActionBar low>
            <Right>
              {rewardsToAdd.length === 0 && (
                <Button
                  text="Issue Reward"
                  color={Swatches.Success}
                  size={Size.Small}
                  disabled={
                    rewardDetails.category === null ||
                    rewardDetails.initialAction.initialActionId === null ||
                    rewardDetails.class === null
                  }
                  onClick={handleIssueReward}
                />
              )}

              {updatingReward ? (
                <>
                  <Button
                    text="Update"
                    color={Swatches.Success}
                    size={Size.Small}
                    disabled={
                      rewardDetails.initialAction.initialActionId === null ||
                      rewardDetails.class === null
                    }
                    onClick={handleUpdateReward}
                  />
                  <Button
                    text="Cancel"
                    color={Swatches.Low}
                    size={Size.Small}
                    onClick={handleCancelUpdate}
                  />
                </>
              ) : (
                <>
                  <Button
                    text={
                      rewardsToAdd.length > 0 ? "Add" : "Add and Create Another"
                    }
                    color={Swatches.Success}
                    size={Size.Small}
                    disabled={
                      rewardDetails.category === null ||
                      rewardDetails.initialAction.initialActionId === null ||
                      rewardDetails.class === null
                    }
                    onClick={handleSaveReward}
                  />

                  {rewardsToAdd.length === 0 && (
                    <Button
                      text="Cancel"
                      color={Swatches.Low}
                      size={Size.Small}
                      onClick={handleCloseModal}
                    />
                  )}
                </>
              )}
            </Right>
          </ActionBar>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddRewardForm;
