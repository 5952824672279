import { SchoolSmartTaskView } from "../types/smartTasks/smartTaskResponse.types";
import { EventName, TimePeriodName } from "../types/smartTasks/smartTaskShared.types";

export const NEW_SMART_TASK: SchoolSmartTaskView = {
  schoolId: null,
  name: "",
  eventFrequency: 1,
  eventNameId: EventName.RewardPoints,
  timePeriodFrequency: 1,
  timePeriodNameId: TimePeriodName.CalendarDays,
  sendNotificationToParents: false,
  sendNotificationToPastoral: false,
  sendNotificationToLeadership: false,
  actionNote: "",
  behaviourCodeId: null,
  behaviourCodeName: null,
  classGroupId: null,
  classGroupName: null,
  yearGroupId: null,
  yearGroupName: null,
  subjectId: null,
  subjectName: null,
  schoolBehaviourCategoryId: null,
  schoolBehaviourCategoryName: null,
  calendarCategoryId: null,
  calendarCategoryName: null,
  isActive: true,
  allowMultiple: true,
  staffEmailRecipients: [],
  isTriggered: false,
};

export const EVENT_TYPE_KEYS = {
  BEHAVIOUR: 0,
  REWARD: 1,
};

export const EVENT_NAMES = [
  { key: EVENT_TYPE_KEYS.BEHAVIOUR, text: "Behaviour Points", value: 0 },
  { key: EVENT_TYPE_KEYS.REWARD, text: "Reward Points", value: 1 },
];

export const ACTION_TYPE_KEYS = {
  SHARE_WITH_PARENTS: 0,
};

export const ACTION_TYPES = [
  {
    key: ACTION_TYPE_KEYS.SHARE_WITH_PARENTS,
    text: "Share with Parents",
    isSelectedAction: false,
  },
];

export const TIME_PERIOD_KEYS = {
  DAYS: 0,
  WEEKS: 1,
  MONTHS: 2,
  ACADEMIC_YEAR: 3,
};

export const TIME_PERIOD_NAME = [
  { key: TIME_PERIOD_KEYS.DAYS, label: "Day(s)", value: 0 },
  { key: TIME_PERIOD_KEYS.WEEKS, label: "Week(s)", value: 1 },
  { key: TIME_PERIOD_KEYS.MONTHS, label: "Month(s)", value: 2 },
  {
    key: TIME_PERIOD_KEYS.ACADEMIC_YEAR,
    label: "Current Academic Year",
    value: 3,
  },
];

export const SEARCH_OPTION_KEYS = {
  SUBJECTS: 0,
  CLASS_GROUPS: 1,
};

export const SEARCH_OPTIONS = [
  { key: SEARCH_OPTION_KEYS.SUBJECTS, text: "Subjects", value: 0 },
  { key: SEARCH_OPTION_KEYS.CLASS_GROUPS, text: "Class Groups", value: 1 },
];
