import React, { useState, useEffect } from "react";
import PropTypes, { object } from "prop-types";
import {
  StructuredList,
  Card,
  ActionBar,
  Button,
  Swatches,
  Right,
  Size,
} from "ui-kit";
import { IDetentionDetails, IIncidentDetails, getDetentionDetails } from "../../../utils/detentions";
import { getIncidentDetails, readyToSubmit } from "../../../utils/behaviours";
import DetentionFormWrapper from "../detentionFormWrapper";
import PublicNotesFormField from "areas/behaviour/components/behaviourCommon/publicNotesFormField";
import InternalNoteFormField from "areas/behaviour/components/behaviourCommon/internalNoteFormField";
import { DETENTION_STATUS_KEYS } from "areas/behaviour/constants/detentions";
import ConfirmModal from "sharedComponents/common/confirmModal";
import { BehaviourView } from "areas/behaviour/types/behaviourResponses.types";


interface IUpscaleDetentionFormProps {
  behaviour: BehaviourView;
  studentId: string;
  handleCancel: () => void;
  handleUpscaleDetention: (detentionDetails: IDetentionDetails, internalNotes: string, publicNotes: string) => void;
  schoolId: number;
  checkValidations: (incidentDetails: IIncidentDetails, detentionDetails: IDetentionDetails, openConfirmReshedule: () => void) => void;
}


const UpscaleDetentionForm: React.FC<IUpscaleDetentionFormProps> = ({
  behaviour,
  studentId,
  handleCancel,
  handleUpscaleDetention,
  schoolId,
  checkValidations,
}) => {

  const [incidentDetails, setIncidentDetails] = useState<IIncidentDetails>(null);
  const [detentionDetails, setDetentionDetails] = useState<IDetentionDetails>(null);
  const [publicNotes, setPublicNotes] = useState<string>("");
  const [internalNotes, setInternalNotes] = useState<string>("");
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  useEffect(() => {
    if (behaviour.detentions[0]) {
      setDetentionDetails(getDetentionDetails(behaviour.detentions[0]));
    }
    setIncidentDetails(getIncidentDetails(behaviour));
  }, [behaviour]);

  return (
    <>
      <StructuredList>
        <DetentionFormWrapper
          studentId={studentId}
          schoolId={schoolId}
          detentionDetails={detentionDetails}
          setDetentionDetails={setDetentionDetails}
          allowDetentionTypeChange
          allowDetentionDurationChange
        />

        <Card title="Notes">
          <Card.Body>
            <StructuredList.Item
              name="Public Notes"
              description="Maximum 250 characters. (These notes will be sent/displayed to parents)"
              helpPopup
            >
              <PublicNotesFormField
                notes={publicNotes}
                handleNotesChange={value => setPublicNotes(value)}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Internal Notes"
              description="(These notes will not be sent/displayed to parents)"
              helpPopup
            >
              <InternalNoteFormField
                notes={internalNotes}
                handleNoteChange={value => setInternalNotes(value)}
              />
            </StructuredList.Item>
          </Card.Body>
        </Card>

        <ActionBar low>
          <Right>
            <Button
              text="Save"
              color={Swatches.Success}
              size={Size.Small}
              disabled={
                readyToSubmit(behaviour.initialActionId, detentionDetails) ===
                false
              }
              onClick={() =>
                checkValidations(incidentDetails, detentionDetails, () =>
                  setOpenConfirm(true)
                )
              }
            />

            <Button text="Cancel" size={Size.Small} onClick={handleCancel} />
          </Right>
        </ActionBar>
      </StructuredList>

      <ConfirmModal
        openModal={openConfirm}
        confirmMsg={
          "A message will be sent to parents with these changes. Are you sure you want to continue?"
        }
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          handleUpscaleDetention(detentionDetails, internalNotes, publicNotes);
          setOpenConfirm(false);
        }}
      />
    </>
  );
};


export default UpscaleDetentionForm;
