import React from "react";
import styled from "styled-components";
import {
  DetailLabel,
  CircleIcon,
  neutral,
  Spacing} from "../index";
import { honeygold } from "../common/colors";

export interface IToastItemProps {
  label: string;
  sub?: string;
  icon?: string;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .circle-icon {
    margin-right: ${Spacing.Default}px;
    border-color: ${honeygold} important;
  }
  .toast-item-details {
    .label {
      color: ${neutral[600]};
      display: block;
    }
    .sub {
      display: block;
      color: ${neutral[300]};
    }
  }
`;

const ToastItem: React.FC<IToastItemProps> = ({ label, sub, icon }) => {
  return (
    <Wrapper>
      {icon && (
        <CircleIcon value={icon} color={neutral[100]} background={honeygold} />
      )}
      <div className="toast-item-details">
        <DetailLabel bold label={label} sub={sub ? sub : ""} />
      </div>
    </Wrapper>
  );
};

export default ToastItem;
