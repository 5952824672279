import React from "react";
import { BorderRadius, Spacing } from "ui-kit/common/size";
import styled from "styled-components";
import { neutral } from "ui-kit/common/colors";
import {
  DetailLabel,
  Size,
  Icon,
  fontStyle,
  displayFont,
  typescale
} from "../../../ui-kit";

const Wrapper = styled.div<{listView?: boolean}>`
  padding: ${Spacing.Small + 4}px;
  margin: 0 ${Spacing.Small}px ${Spacing.Medium}px ${Spacing.Small}px;
  border-radius: ${BorderRadius.Default}px;
  background: ${({ color }) => (color ? color : neutral[100])};
  height: inherit;

  // &:last-child {
  //   margin-bottom: 0;
  // }

  .item-label {
    ${fontStyle(displayFont.bold, typescale.header4, neutral[700])};
  }

  .item-sub {
    vertical-align: middle;
    text-align: center;
    ${fontStyle(displayFont.medium, typescale.paragraph, neutral[600])};
    color :${({ color }) => (color ?  neutral[100] : neutral[600])}; // Used for multiple student leaving lesson selectionButtons.
  }

  &:hover {
    cursor: pointer;
    background: ${neutral[600]};

    .item-label,
    .item-sub {
      color: ${neutral[100]};
    }

    .detail-label {
      .label,
      .sub {
        color: ${neutral[100]};
      }
    }

    .icon {
      color: ${neutral[100]};
    }
  }
`;


interface ISelectionButtonProps<TValue> {
  className?: string;
  listView?: boolean;
  item: TValue;
  itemLabel: string;
  itemSub?: React.ReactNode;
  icon?: string;
  iconColour?: string;
  colour?: string;
  handleItemClick: (item: TValue) => void;
}

const SelectionButton = <
  TValue = null,
>({
  className,
  listView,
  item,
  itemLabel,
  itemSub,
  icon,
  iconColour,
  colour,
  handleItemClick
}: ISelectionButtonProps<TValue>) => {
  return (
    <Wrapper
      className={`selection-button ${className ? className : ""}`}
      listView={listView}
      onClick={() => handleItemClick(item)}
      color={colour}
    >
      {/* {children} */}
      {listView ? (
        <>
          <Icon value={icon} size={Size.Large} color={iconColour} />
          <DetailLabel label={itemLabel} sub={itemSub} bold />
        </>
      ) : (
        <>
          <div className="item-label">{itemLabel}</div>
          <Icon value={icon} size={Size.Large} color={iconColour} />
          <div className="item-sub">{itemSub}</div>
        </>
      )}
    </Wrapper>
  );
};

export default SelectionButton;
