import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  List,
  Left,
  Right,
  Spacing,
  Subtitle,
  Size,
  Loader,
  formatScrollbars,
  neutral,
} from "../index";
import { arrays } from "../../utils";
import _ from "lodash";

const Wrapper = styled.div`
  display: flex;
  margin-right: ${Spacing.Medium}px;
  > .left,
  > .right {
    width: 50%;
    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-direction: column;
  }

  > .left {
    margin-right: ${Spacing.Default}px;
  }
  > .right {
    margin-left: ${Spacing.Default}px;
  }
`;

const PicklistHeader = styled.div`
  margin-bottom: ${Spacing.Default}px;
`;

const PicklistBody = styled.div`
  margin-bottom: ${Spacing.Default}px;
  flex-grow: 1;
  height: 0px;
  overflow-y: auto !important;
  ${formatScrollbars(neutral[200])};
`;

type option = {
  label: string;
  sub?: string;
  value: any;
}

interface IPicklistProps {
  options: option[];
  optionsEmptyContent?: React.ReactNode;
  optionsTitle: string;
  optionsHeader?: React.ReactNode;
  selected?: option[];
  selectedEmptyContent?: React.ReactNode;
  selectedTitle: string;
  selectedHeader?: React.ReactNode;
  loadingOptions?: boolean;
  template?: (opt: option, index?: number) => React.ReactElement;
  onChange: (newSelected: option[]) => void;
  sortExpression: (a: option, b: option) => boolean;
}

const PickList: React.FC<IPicklistProps> = ({
  options,
  optionsEmptyContent,
  optionsTitle,
  optionsHeader,
  selected,
  selectedEmptyContent,
  selectedTitle,
  selectedHeader,
  loadingOptions,
  template,
  onChange,
  sortExpression,
}) => {
  const [_options, _setOptions] = useState<option[]>([]);
  const [_selected, _setSelected] = useState<option[]>([]);

  useEffect(() => {
    if (!arrays.isEmpty(options)) {
      _setOptions(options);
    }
  }, [options]);

  useEffect(() => {
    if (!arrays.isEmpty(selected)) {
      _setSelected(selected!.map((s) => ({ ...s, hide: false })));
    }
  }, [selected]);

  const handleOptionPicked = (value: option) => {
    if (!_selected.some((x: option) => x.value === value.value)) {
      const newSelected = [..._selected, value];
      _setSelected(newSelected);
      onChange?.(newSelected);
    }
  };

  const handleSelectionRemoved = (value: option) => {
    var newSelected = [..._selected]; // This is necessary so _selected is not mutated by arrays.removeItem
    newSelected = arrays.removeItem(newSelected, value);

    _setSelected(newSelected);

    onChange?.(newSelected);
  };

  return (
    <Wrapper className="picklist">
      <Left className="picklist-selected">
        {arrays.isEmpty(_selected) ? (
          selectedEmptyContent
        ) : (
          <>
            <PicklistHeader className="picklist-header picklist-selected-header">
              <Subtitle text={selectedTitle} />
              {selectedHeader != null && (
                <div className="picklist-header-content">{selectedHeader}</div>
              )}
            </PicklistHeader>
            <PicklistBody className="picklist-body">
              <List>
                {_selected
                  ?.sort((a, b) => (sortExpression(a, b) ? 1 : -1))
                  .map((option, index) =>
                    template ? (
                      React.cloneElement(template(option, index), {
                        onClick: () => handleSelectionRemoved(option),
                        key: index,
                      })
                    ) : (
                      <List.Item
                        key={index}
                        text={option.label}
                        sub={option.sub}
                        value={option.value}
                        onClick={() => handleSelectionRemoved(option)}
                      />
                    )
                  )}
              </List>
            </PicklistBody>
          </>
        )}
      </Left>
      <Right className="picklist-options">
        <>
          <PicklistHeader className="picklist-header picklist-options-header">
            <Subtitle text={optionsTitle} />
            {/* <pre>{JSON.stringify(_options, null, 2)}</pre> */}
            {optionsHeader != null && (
              <div className="picklist-header-content">{optionsHeader}</div>
            )}
          </PicklistHeader>
          <PicklistBody className="picklist-body">
            {loadingOptions ? (
              <Loader size={Size.Medium} />
            ) : arrays.isEmpty(
                _options.filter(
                  (opt) => !_selected.some((s) => _.isEqual(s.value, opt.value))
                )
              ) ? (
              optionsEmptyContent
            ) : (
              <List>
                {_options
                  ?.filter(
                    (opt) =>
                      !_selected.some((s) => _.isEqual(s.value, opt.value))
                  )
                  .sort((a, b) => (sortExpression(a, b) ? 1 : -1))
                  .map((option, index) =>
                    template ? (
                      React.cloneElement(template(option, index), {
                        onClick: () => handleOptionPicked(option),
                        key: index,
                      })
                    ) : (
                      <List.Item
                        key={index}
                        text={option.label}
                        sub={option.sub}
                        value={option.value}
                        onClick={() => handleOptionPicked(option)}
                      />
                    )
                  )}
              </List>
            )}
          </PicklistBody>
        </>
      </Right>
    </Wrapper>
  );
};

export default PickList;
