import smartcardFilterActions from "areas/payments/actions/smartcardFilterActions";
import { IBeehiveAction } from "types/common/action.types";


interface ISmartcardFilterState {
  loading: boolean;
  error: string | null,
  filter: {
    status: number;
    schoolId: number;
    searchTerm: string;
  }
}

const DEFAULT_STATUS: number = 2;

const INITIAL_STATE: ISmartcardFilterState = {
  loading: false,
  error: null,
  filter: {
    status: DEFAULT_STATUS,
    schoolId: -1,
    searchTerm: ""
  }
};

const smartcardFilterReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISmartcardFilterState => {

  const {
    SMARTCARDS_FILTERBYSTATUS,
    SMARTCARDS_FILTERBYSCHOOL,
    SMARTCARDS_FILTERBYSEARCHTERM
  } = smartcardFilterActions.types;

  switch (action.type) {
    case SMARTCARDS_FILTERBYSTATUS:
      return {
        ...state,
        filter: { 
          ...state.filter, 
          status: action.payload 
        }
      };

    case SMARTCARDS_FILTERBYSCHOOL:
      return {
        ...state,
        filter: {
          ...state.filter,
          schoolId: action.payload,
          status: DEFAULT_STATUS
        }
      };

    case SMARTCARDS_FILTERBYSEARCHTERM:
      return {
        ...state,
        filter: { 
          ...state.filter, 
          searchTerm: action.payload 
        }
      };
    default:
      return state;
  }
};

export default smartcardFilterReducer;
