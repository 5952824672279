import React, { useState } from "react";
import { useNavigate } from "react-router-dom";



const ConsentResponse = () => {
  
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/main/consent");
  };

  return <p>Form</p>;
};

export default ConsentResponse;
