import React from "react";
import {
  Card,
  StructuredList,
  Size,
  Label,
  Title,
  TitleSize,
  Button,
  Swatches,
  Chip,
} from "ui-kit";
import {
  SchoolAvatar,
  Address,
  Avatar,
  UserName,
  Email,
  TelephoneNumberList,
} from "sharedComponents/common";
import Moment from "react-moment";
import { arrays, types, users } from "utils";
import { useNavigate } from "react-router-dom";
import { PaymentUser, PaymentsParent, PaymentsStudent } from "areas/payments/reducers/payments/paymentsUserReducer";


interface IUserDetailsProps {
  user: PaymentUser | PaymentsParent | PaymentsStudent;
}


const UserDetails: React.FC<IUserDetailsProps> = ({ user }) => {

  const navigate = useNavigate();

  const handleAccountClick = (user: PaymentUser) => {
    navigate(`/main/shopkeeper/users/${user.id}`);
  };

  return (
    <>
      <Card title="Personal Details">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Name">
              <Avatar user={user} size={Size.Small} />
            </StructuredList.Item>
            <StructuredList.Item name="School">
              <SchoolAvatar school={user.school} />
            </StructuredList.Item>
            <StructuredList.Item name="SIMS ID">
              <Label>{user.simsId}</Label>
            </StructuredList.Item>
            <StructuredList.Item name="Cashless ID">
                  {user.externalId}
                </StructuredList.Item>
            {users.isStudent(user) && (
              <>
              <StructuredList.Item name="Date of Birth">
                <Label>
                  <Moment date={user.dateOfBirth} format="DD/MM/YYYY" />
                </Label>
              </StructuredList.Item>
              <StructuredList.Item name="Pupil Premium">
                {user.pupilPremium ? (
                  <Chip text="Pupil Premium" colorSwatch={Swatches.Purple} />
                ) : (
                  <p>No</p>
                )}
              </StructuredList.Item>
              <StructuredList.Item name="Bursary">
                {user.bursary ? (
                  <Chip text="Bursary" colorSwatch={Swatches.DeepPink} />
                ) : (
                  <p>No</p>
                )}
              </StructuredList.Item>
              </>
            )}
          </StructuredList>
        </Card.Body>
      </Card>
      <Card title="Contact Details">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Email">
              <Email email={user.email} />
            </StructuredList.Item>
            <StructuredList.Item name="Telephone">
              <TelephoneNumberList numbers={user.telephoneNumbers} />
            </StructuredList.Item>
            <StructuredList.Item name="Address">
              <Address address={user.address} />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      {users.isStudent(user) && (
        <>
          <Title text="Parents" size={TitleSize.H3} />

          {types.isType<PaymentsStudent>(user, "parents") && !arrays.isEmpty(user.parents) &&
            user.parents.map((parent, index) => (
              <Card key={index} title={<UserName user={parent} />}>
                <Card.Body>
                  <StructuredList>
                    <StructuredList.Item name="SIMS ID">
                      <Label>{parent.simsId}</Label>
                    </StructuredList.Item>
                    <StructuredList.Item name="Email">
                      <Email email={parent.email} />
                    </StructuredList.Item>
                    <StructuredList.Item name="Telephone">
                      <TelephoneNumberList numbers={parent.telephoneNumbers} />
                    </StructuredList.Item>
                    <StructuredList.Item name="Address">
                      <Address address={parent.address} />
                    </StructuredList.Item>
                    <StructuredList.Item>
                      <Button
                        size={Size.Small}
                        text="View Account"
                        color={Swatches.Primary}
                        onClick={() => handleAccountClick(parent)}
                      />
                    </StructuredList.Item>
                  </StructuredList>
                </Card.Body>
              </Card>
            ))}
        </>
      )}

      {users.isParent(user) && (
        <>
          <Title text="Children" size={TitleSize.H3} />
          {types.isType<PaymentsParent>(user, "children") && !arrays.isEmpty(user.children) &&
            user.children.map((child, index) => (
              <Card key={index} title={<UserName user={child} />}>
                <Card.Body>
                  <StructuredList>
                    <StructuredList.Item name="Name">
                      <Avatar
                        user={child}
                        size={Size.Small}
                        onClick={() => handleAccountClick(child)}
                      />
                    </StructuredList.Item>
                    <StructuredList.Item name="School">
                      <SchoolAvatar school={child.school} />
                    </StructuredList.Item>
                    <StructuredList.Item name="SIMS ID">
                      <Label>{child.simsId}</Label>
                    </StructuredList.Item>
                    <StructuredList.Item name="Email">
                      <Email email={child.email} />
                    </StructuredList.Item>
                    <StructuredList.Item>
                      <Button
                        size={Size.Small}
                        text="View Account"
                        color={Swatches.Primary}
                        onClick={() => handleAccountClick(child)}
                      />
                    </StructuredList.Item>
                  </StructuredList>
                </Card.Body>
              </Card>
            ))}
        </>
      )}
    </>
  );
};

export default UserDetails;
