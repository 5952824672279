import React, { useState, useEffect } from "react";
import { DatePicker } from "ui-kit";
import { useSelector } from "react-redux";
import { Sub, neutral } from "ui-kit";
import { warning, danger } from "ui-kit/common/colors";
import styled from "styled-components";
import moment from "moment";
import { arrays } from "utils";
import termDateActions from "../../areas/calendar/actions/termDatesActions";
import { RootState } from "reducers/store";
import { IBeehiveError } from "types/common/errors.types";
import { DateObject } from "ui-kit/composite/calendar";
import { SpecialDays } from "configuration/constants.enums";
import { DateListView, DateView } from "areas/planner/types/termDatesResponse.types";
import termDatesService from "services/termDatesService";
import { SpecialDayType } from "areas/planner/types/termDatesShared.types";
import { DetentionPeriod } from "areas/behaviour/types/behaviourShared.types";

const Wrapper = styled.div`
  ${({ color }) =>
    color &&
    `
    .date-picker-sub {
      color: ${color};
    } 
  `}
  .error-sub {
    color: ${danger};
  }
`;

interface IValidDatePickerProps {
  selectedDate?: Date | moment.Moment;
  dateFormat?: string;
  inputDateFormat?: string;
  onChange: (date: Date | moment.Moment) => void;
  disabled?: boolean;
  fluid?: boolean;
  placeholder?: string;
  className?: string;
  closeOnSelect?: boolean;
  validate?: boolean;
  autoSelectNextAvailableDate?: boolean;
  allowPastDates?: boolean;
  allowWeekend?: boolean;
  allowSpecialDays?: SpecialDayType[];
  isForDetention?: boolean;
  detentionPeriodId?: DetentionPeriod;
  schoolId: number;
}

const ValidDatePicker: React.FC<IValidDatePickerProps> = ({
  selectedDate,
  dateFormat,
  inputDateFormat,
  onChange,
  disabled,
  fluid,
  placeholder,
  className,
  closeOnSelect,
  validate,
  autoSelectNextAvailableDate = true,
  allowPastDates = false,
  allowWeekend,
  allowSpecialDays,
  isForDetention = false,
  detentionPeriodId,
  schoolId,
}) => {
  const { allCheckedDates } = useSelector(
    (state: RootState) => state.termDates
  );
  const [checkedDates, setCheckedDates] = useState<DateListView | null>(null);
  const [checkedDatesError, setCheckedDatesError] =
    useState<IBeehiveError | null>(null);
  const [disallowedDates, setDisallowedDates] = useState<Date[]>([]);
  const [datePickerSubText, setDatePickerSubText] = useState({
    text: "",
    color: neutral[500],
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!arrays.isEmpty(allCheckedDates)) {
      addDisallowedDates(allCheckedDates);
    }
  }, []);

  useEffect(() => {
    setCheckedDates(null);
    setDisallowedDates([]);
    termDateActions.saveCheckedDates([]);
    var start = moment(selectedDate).startOf('month');
    var end = moment(selectedDate).endOf('month');
    checkTermDates(start.toDate(), end.toDate(), autoSelectNextAvailableDate);
  }, [detentionPeriodId])

  useEffect(() => {
    if (checkedDates) {
      const dates = checkedDates.dates;
      if (dates.length === 1 && isDisallowedDate(dates[0])) {
        if (autoSelectNextAvailableDate && checkedDates.nextAvailableDate) {
          onChange(moment(checkedDates.nextAvailableDate).toDate());
          setDatePickerSubText({
            text: "The date was not valid. A valid date has automatically been selected.",
            color: warning,
          });
        } else {
          onChange(new Date());
          setDatePickerSubText({
            text: "The date is not valid. Please select a valid date during term time.",
            color: danger,
          });
        }
      }

      if (!arrays.isEmpty(dates)) {
        addDisallowedDates(dates);
      }

      const tempAllCheckedDates = allCheckedDates.slice();
      dates.forEach(checkedDate => {
        tempAllCheckedDates.push(checkedDate);
      });
      termDateActions.saveCheckedDates(tempAllCheckedDates);
    }
  }, [checkedDates]);

  const isDisallowedDate = (date: DateView): boolean => {
    const isAnAllowedSpecialDay = (checkedDate: DateView) => {
      const isAllowed = (allowedSpecialDay: SpecialDayType): boolean => {
        return checkedDate.specialDays.some(
          specialDay => specialDay.specialDayTypeId === allowedSpecialDay
        );
      };

      if (
        allowSpecialDays &&
        checkedDate.specialDays?.length > 0 &&
        allowSpecialDays.some(allowedSpecialDay => isAllowed(allowedSpecialDay))
      ) {
        return true;
      } else if (!checkedDate.isSpecialDayClosure) {
        return true;
      } else {
        return false;
      }
    };

    if (
      (!allowPastDates && moment(date.date).isBefore(moment(), "day")) ||
      !date.isTermTime ||
      (!allowWeekend && date.isWeekend) ||
      !isAnAllowedSpecialDay(date) ||
      (isForDetention && date.isDetentionSlotsBlocked)
    ) {
      // console.log(
      //   `${date.date}: ${isForDetention && date.isDetentionSlotsBlocked}`
      // );
      return true;
    } else {
      return false;
    }
  };

  const addDisallowedDates = (dates: DateView[]) => {
    const tempDisallowedDates = disallowedDates.slice();
    dates.forEach(checkedDate => {
      if (isDisallowedDate(checkedDate)) {
        tempDisallowedDates.push(moment(checkedDate.date).toDate());
      }
    });
    setDisallowedDates(tempDisallowedDates);
  };

  const checkTermDates = async (
    startDate: Date,
    endDate: Date | null,
    selectNextAvailableDate: boolean = false
  ) => {
    setIsLoading(true);
    termDatesService
      .checkTermDates(
        startDate,
        endDate,
        schoolId,
        selectNextAvailableDate,
        isForDetention,
        detentionPeriodId
      )
      .then(response => {
        setCheckedDates(response);
        setCheckedDatesError(null);
        setIsLoading(false);
      })
      .catch((error: IBeehiveError) => {
        setCheckedDatesError(error);
        setIsLoading(false);
      });
  };

  const onDateRangeChange = (
    startDate: DateObject,
    endDate: DateObject
  ): void => {
    const findDate = (date: Date, dateObject: DateObject): boolean => {
      return (
        date.getDate() === dateObject.day &&
        date.getMonth() === dateObject.month &&
        date.getFullYear() === dateObject.year
      );
    };

    const momentStartDate = moment(
      new Date(startDate.year, startDate.month, startDate.day)
    );
    const momentEndDate = moment(
      new Date(endDate.year, endDate.month, endDate.day)
    );
    const numberOfDays = momentEndDate.diff(momentStartDate, "days");

    let foundStartDate;
    for (let i = 0; i < numberOfDays; i++) {
      let startDateTemp = moment(
        new Date(startDate.year, startDate.month, startDate.day)
      )
        .add(i, "d")
        .format();

      foundStartDate = allCheckedDates.find((date: any) =>
        findDate(new Date(date.date), {
          day: moment(startDateTemp).date(),
          month: moment(startDateTemp).month(),
          year: moment(startDateTemp).year(),
        })
      );

      if (!foundStartDate) {
        startDate = {
          day: moment(startDateTemp).date(),
          month: moment(startDateTemp).month(),
          year: moment(startDateTemp).year(),
        };
        break;
      }
    }

    const foundEndDate = allCheckedDates.find((date: any) =>
      findDate(new Date(date.date), endDate)
    );

    if (!foundStartDate || !foundEndDate) {
      checkTermDates(
        new Date(startDate.year, startDate.month, startDate.day),
        new Date(endDate.year, endDate.month, endDate.day)
      );
    }
  };

  const handleOnTextChangeDate = (date: moment.Moment) => {
    if (!allowPastDates && moment(date).isBefore(moment(), "day")) {
      autoSelectNextAvailableDate && onChange(moment());

      setDatePickerSubText({
        text: "Dates in the past are not allowed.",
        color: danger,
      });
    } else {
      checkTermDates(
        date.toDate(),
        null,
        autoSelectNextAvailableDate ? true : false
      );
      onChange(date);
      setDatePickerSubText({
        text: "",
        color: neutral[500],
      });
    }
  };

  const handleDateChange = (newDate: Date | null) => {
    newDate && onChange(newDate);
    setDatePickerSubText({
      text: "",
      color: neutral[500],
    });
  };

  return (
    <>
      <DatePicker
        selectedDate={selectedDate}
        dateFormat={dateFormat}
        inputDateFormat={inputDateFormat}
        onChange={handleDateChange}
        disabled={disabled}
        fluid={fluid}
        placeholder={placeholder}
        className={className}
        closeOnSelect={closeOnSelect}
        disallowed={validate ? disallowedDates : undefined}
        onDateRangeChange={onDateRangeChange}
        loading={isLoading}
        handleOnTextChangeDate={handleOnTextChangeDate}
      />
      <Wrapper color={datePickerSubText.color}>
        {checkedDatesError && (
          <>
            <Sub className="error-sub">
              {checkedDatesError.response?.data?.exceptionMessage
                ? checkedDatesError.response.data.exceptionMessage
                : checkedDatesError?.exceptionMessage
                ? checkedDatesError.exceptionMessage
                : "An error occured when getting term dates."}
            </Sub>
            <br />
          </>
        )}
        <Sub className="date-picker-sub">{datePickerSubText.text}</Sub>
      </Wrapper>
    </>
  );
};

export default ValidDatePicker;
