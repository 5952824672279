import React, { useCallback, useEffect, useState } from "react"
import { ActionBar, Button, Loader, Modal, Right, Size, Swatches, ValidationMessage } from "ui-kit";
import { DetentionStudentView } from "../../types/detentionRegisterResponse.types";
import detentionRegisterActions from "../../actions/detentions/detentionRegisterActions";
import { PreflightDetentionDatesFilter, RescheduleDetentionsCommand } from "../../types/detentionRegisterRequest.types";
import { useAppSelector } from "reducers/hooks";
import moment from "moment";
import { ApiExceptionMessage } from "sharedComponents/common";
import detentionTypesActions from "../../actions/detentions/detentionTypesActions";
import schoolActions from "areas/planner/actions/school/schoolActions";
import { getDetentionDuration, getStudentDetentionsForDate, getTime, initialDetentionValidation, validateDetentionTypeChange, validateNewDetentionDate, validateNewDetentionTime } from "../../utils/bulkDetentionReschedule";
import { objects } from "utils";
import lodashDebounce from "lodash.debounce";
import BulkRescheduleTable, { BulkRescheduleTableMode } from "./bulkRescheduleTable";
import BulkRescheduleSuccessTable from "./bulkRescheduleSuccessTable";


interface IBulkRescheduleModalProps {
  schoolId: number;
  detentions: DetentionStudentView[];
  open: boolean;
  onCancel?: (refresh: boolean) => void;
  onSaved?: (refresh: boolean) => void;
}

export enum RescheduleStatus {
  Draft = 0,
  Rescheduled = 1,
  Failed = 2,
}

export interface DetentionStudentViewExt extends DetentionStudentView {
  originalDate: string;
  rescheduleStatus: RescheduleStatus;
  errorMessage?: string;
}


const BulkRescheduleModal: React.FC<IBulkRescheduleModalProps> = ({ schoolId, detentions, open, onCancel, onSaved }) => {

  const {
    loading,
    preflightError, 
    bulkRescheduleDetentionError, 
    checkingDetentions 
  } = useAppSelector(state => state.bulkRescheduleDetentions);
  const {
    detentionTypes,
    loading: loadingTypes,
    error: detentionTypesError,
  } = useAppSelector(state => state.detentionTypes);
  const { schoolInformation } = useAppSelector(state => state.school);

  const [_open, _setOpen] = useState<boolean>(false);
  const [_detentions, _setDetentions] = useState<DetentionStudentViewExt[]>([]);
  const [newDate, setNewDate] = useState<Date>();
  const [initialCheck, setInitialCheck] = useState<boolean>(false);
  const [rescheduleErrorMessage, setRescheduleErrorMessage] = useState<string[]>([]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    if (schoolId && (!detentionTypes || detentionTypes.length === 0)) {
      detentionTypesActions.getDetentionTypes(schoolId);
    }
  }, [detentionTypes, schoolId]);

  useEffect(() => {
    if (schoolId) {
      if (!schoolInformation || schoolInformation?.id !== schoolId) {
        schoolActions.getSchoolInformation(schoolId);
        detentionTypesActions.getDetentionTypes(schoolId);
      }
      else {
        var now = moment();
        setNewDate(now.isAfter(schoolInformation.detentionCutOffTime)
          ? now.day() >= 5
            ? now.weekday(8).toDate() 
            : now.add(1, "day").toDate()
          : now.toDate()
        );
      }
    }
  }, [schoolInformation, schoolId])

  useEffect(() => {
    if (detentions && newDate && open) {
      setInitialCheck(true);
      setRescheduleErrorMessage([]);
      var tempDetentions: DetentionStudentViewExt[] = [];
      detentions.forEach(detention => {
        var validated = initialDetentionValidation(
          tempDetentions, 
          {
            ...detention, 
            originalDate: detention.startDate, 
            rescheduleStatus: RescheduleStatus.Draft
          }, 
          newDate, 
          moment(detention.startDate).format("HH:mm"), 
          schoolInformation
        );
        tempDetentions.push({
          ...detention,
          originalDate: detention.startDate,
          startDate: validated.startDate,
          endDate: validated.endDate,
          rescheduleStatus: RescheduleStatus.Draft
        });
      });
      _setDetentions(tempDetentions);
      validateDetentions(
        tempDetentions, 
        tempDetentions, 
        () => setInitialCheck(false),
        () => setInitialCheck(false),
      );
    }
  }, [detentions, newDate, open]);


  const debouncedValidation = useCallback(
    lodashDebounce((
      allDetentions: DetentionStudentViewExt[], 
      detentionsToCheck: DetentionStudentViewExt[], 
      callback?: () => void,
      errorCallback?: () => void
    ) => {
      validateDetentions(allDetentions, detentionsToCheck, callback, errorCallback);
    }, 1000),
    []
  );

  const validateDetentions = (
    allDetentions: DetentionStudentViewExt[], 
    detentionsToCheck: DetentionStudentViewExt[], 
    callback?: () => void,
    errorCallback?: () => void
  ) => {
    const filter: PreflightDetentionDatesFilter = {
      detentionInfo: detentionsToCheck.map(x => ({
        studentId: x.student.id,
        detentionId: x.detentionId,
        detentionTypeId: x.detentionTypeId,
        detentionDate: moment(x.originalDate).startOf('day').isBefore(moment().startOf('day')) // Check if original date is before current date
          ? `${moment(x.startDate).format("YYYY-MM-DD HH:mm:ss")}`  // Use current date
          : moment(x.originalDate).add(1, 'days').format("YYYY-MM-DD HH:mm:ss"), // Otherwise, use original date + 1 Day
        duration: moment(x.endDate).diff(moment(x.startDate), "minutes")
      }))
    };
    detentionRegisterActions.getPreflightDetentionDates(
      filter, 
      preflightResults => {
        var invalidResults = preflightResults.filter(pfr => pfr.isDateValid === false);
        var validResults = preflightResults.filter(pfr => pfr.isDateValid === true );
        if (invalidResults.length > 0 || validResults.length > 0 ) {

          var detentionsToCheckAndAllCombined = allDetentions.map(detention => {
            var invalidDetentionDate = invalidResults?.find(invalidResult => invalidResult.detentionId === detention.detentionId);
            var ajustedDetentionDate = validResults?.find(validResult => {
                return validResult.detentionId === detention.detentionId &&
                      moment(validResult.validDetentionDate).isAfter(moment(detention.originalDate));
            });
            var invalidRecord = {...invalidDetentionDate, ...ajustedDetentionDate};
            var duration = getDetentionDuration(detention.startDate, detention.endDate);
            return (invalidRecord !== undefined)
              ? {
                ...detention,
                startDate: invalidRecord.validDetentionDate,
                endDate: moment(invalidRecord.validDetentionDate).add(duration, "minutes").format("YYYY-MM-DD HH:mm")
              }
              : detention
          });

          _setDetentions(detentionsToCheckAndAllCombined);
        }
        callback?.();
      },
      error => {
        errorCallback?.();
      }
    );
  }

  const handleCancel = () => {
    _setDetentions([]);
    onCancel?.(_detentions.some(x => x.rescheduleStatus === RescheduleStatus.Rescheduled));
  };

  const handleSave = () => {
    setRescheduleErrorMessage([]);
    const command: RescheduleDetentionsCommand = {
      rescheduleDetentionCommands: _detentions
        // .filter(x => x.rescheduleStatus !== RescheduleStatus.Rescheduled)
        .map(x => ({
          behaviourId: x.behaviourRewardId,
          studentId: x.student.id,
          createDetention: {
            detentionTypeId: x.detentionTypeId,
            detentionDate: x.startDate,
            roomId: x.roomId,
            duration: getDetentionDuration(x.startDate, x.endDate),
            detentionNote: "",
            parentDetentionId: x.detentionId
          }
        }))
    }

    detentionRegisterActions.rescheduleDetentions(command, (response) => {
      if (response.some(x => !x.isSuccessful)) {
        var tempDetentions = _detentions.map(x => {
          var failRecord = response.find(y => !y.isSuccessful && y.detentionDatePreflightCheck.detentionId === x.detentionId);
          return (failRecord !== undefined)
            ? {
              ...x,
              rescheduleStatus: RescheduleStatus.Failed,
              errorMessage: failRecord.errorMessage
            }
            : {
              ...x,
              rescheduleStatus: RescheduleStatus.Rescheduled
            }
          });
        _setDetentions(tempDetentions);
        setRescheduleErrorMessage([`${response.filter(x => !x.isSuccessful).length} Invalid detentions found. You can change or remove the invalid detention(s) and try again.`])
      }
      else {
        _setDetentions([]);
        onSaved?.(true);
      }
    })
  };


  const handleDetentionTypeChange = (detention: DetentionStudentViewExt, detentionTypeId: number) => {
    const selectedDetentionType = detentionTypes.find(
      detentionType => detentionType.detentionTypeId === detentionTypeId
    );
    if (!selectedDetentionType) {
      return;
    }
    var updatedDetentions = validateDetentionTypeChange(_detentions, detention, selectedDetentionType, schoolInformation);
    _setDetentions(updatedDetentions);
    //debouncedValidation(updatedDetentions, getStudentDetentionsForDate(updatedDetentions, detention.student.id, detention.startDate));
  }


  const handleDetentionDateChange = (detention: DetentionStudentViewExt, newDate: Date) => {
    //var validatedDate = validateNewDetentionDate(_detentions, detention, newDate, schoolInformation);
    var newStartDate = (moment(newDate).day() > 5) 
      ? moment(newDate).weekday(8)
      : moment(newDate);
    var duration = getDetentionDuration(detention.startDate, detention.endDate);
    var { hour, minute } = getTime(moment(detention.startDate).format("HH:mm"));
    newStartDate.hour(hour);
    newStartDate.minute(minute);

    var tempDetentions = _detentions.map(dt => 
      dt.detentionId === detention.detentionId
        ? {
          ...dt,
          startDate: newStartDate.format("YYYY-MM-DD HH:mm"),
          endDate: newStartDate.clone().add(duration, "minutes").format("YYYY-MM-DD HH:mm")
        }
        : dt
    );
    _setDetentions(tempDetentions);
    //debouncedValidation(tempDetentions, getStudentDetentionsForDate(tempDetentions, detention.student.id, newStartDate));
  }


  const handleDetentionTimeChange = (detention: DetentionStudentViewExt, newTime: string) => {
    //var validatedTime = validateNewDetentionTime(_detentions, detention, newTime, schoolInformation);
    var duration = getDetentionDuration(detention.startDate, detention.endDate);
    var { hour, minute} = getTime(newTime);
    var newStartTime = moment(detention.startDate).hour(hour).minutes(minute);
    var newEndTime = newStartTime.clone().add(duration, "minutes");

    var tempDetentions = _detentions.map(dt => 
      dt.detentionId === detention.detentionId
        ? {
            ...dt,
            startDate: newStartTime.format("YYYY-MM-DD HH:mm"),
            endDate: newEndTime.format("YYYY-MM-DD HH:mm"),
          }
        : dt
    );
    _setDetentions(tempDetentions);
    //debouncedValidation(tempDetentions, getStudentDetentionsForDate(tempDetentions, detention.student.id, newStartTime));
  }


  const handleRemove = (detention: DetentionStudentViewExt) => {
    const temp = [..._detentions].filter(x => x.detentionId !== detention.detentionId);
    _setDetentions(temp);
  }


  return (
    <>
    <Modal
      width="80%"
      height="80%"
      title="Bulk Reschedule Detentions"
      open={_open}
      onClose={handleCancel}
    >
      <Modal.Body>
       { initialCheck ? (
          <Loader loadingMessage={"Performing inital check"} size={Size.Large} cover />
        ) : (
          <>
            <ApiExceptionMessage error={preflightError} />
            <ApiExceptionMessage error={bulkRescheduleDetentionError} />
            <ApiExceptionMessage error={detentionTypesError} />
            <ValidationMessage errors={rescheduleErrorMessage} />
            <BulkRescheduleTable 
              schoolId={schoolId}
              detentions={_detentions.filter(detention => detention.rescheduleStatus === RescheduleStatus.Draft)}
              mode={BulkRescheduleTableMode.Draft}
              handleDetentionDateChange={handleDetentionDateChange}
              handleDetentionTimeChange={handleDetentionTimeChange}
              handleDetentionTypeChange={handleDetentionTypeChange}
              handleRemove={handleRemove}
              loading={loading}
            />
            <BulkRescheduleTable 
              schoolId={schoolId}
              detentions={_detentions.filter(detention => detention.rescheduleStatus === RescheduleStatus.Failed)}
              mode={BulkRescheduleTableMode.Error}
              rowStyle={{ backgroundColor: "#feedec" }}
              handleDetentionDateChange={handleDetentionDateChange}
              handleDetentionTimeChange={handleDetentionTimeChange}
              handleDetentionTypeChange={handleDetentionTypeChange}
              handleRemove={handleRemove}
              loading={loading}
            />
            <BulkRescheduleSuccessTable
              detentions={_detentions.filter(detention => detention.rescheduleStatus === RescheduleStatus.Rescheduled)}
              loading={loading}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              onClick={handleCancel}
              size={Size.Small}
              color={Swatches.Low}
              text="Cancel"
              working={loading || loadingTypes || checkingDetentions}
              disabled={initialCheck}
            />
            <Button
              onClick={handleSave}
              size={Size.Small}
              color={Swatches.Success}
              text="Save"
              working={loading || loadingTypes || checkingDetentions}
              disabled={initialCheck || _detentions.length < 1}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
    </>
  )
}

export default BulkRescheduleModal;