import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import store from "reducers/store";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { PmStaffDetailView } from "../types/managedStaffResponse.types";

const prefix = "staffmember";
const builder = new actionTypeBuilder(prefix);

const types = {
  GETMANAGEDSTAFFMEMBER: builder.build("getmanagedstaffmember"),
  GETCURRENTPMUSER: builder.build("getcurrentpmuser"),
  SELECTSTAFFMEMBERTAB: "selectstaffmembertab",
  SELECTCURRENTPMUSERTAB: "selectcurrentpmusertab",
};

const getManagedStaffMember = (staffMemberId: string, callback?: callbackType<PmStaffDetailView>) =>
  action<PmStaffDetailView>(
    () => client.get(`pm/staff/${staffMemberId}`),
    types.GETMANAGEDSTAFFMEMBER,
    callback
  );

const getCurrentPmUser = (staffMemberId: string, callback?: callbackType<PmStaffDetailView>) =>
  action<PmStaffDetailView>(
    () => client.get(`pm/staff/${staffMemberId}`),
    types.GETCURRENTPMUSER,
    callback
  );

const selectStaffMemberTab = (tabIndex: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.SELECTSTAFFMEMBERTAB,
    payload: tabIndex,
  });
};

const selectCurrentPmUserTab = (tabIndex: number) => {
  store.dispatch<IBeehiveAction<number>>({
    type: types.SELECTCURRENTPMUSERTAB,
    payload: tabIndex,
  });
};


const staffMemberActions = {
  types,
  getManagedStaffMember,
  getCurrentPmUser,
  selectStaffMemberTab,
  selectCurrentPmUserTab,
};

export default staffMemberActions;
