import React, { useEffect, useState } from "react";
import flyoutActions from "actions/ui/flyouts";
import paymentActions from "areas/payments/actions/paymentsActions";
import { useSelector } from "react-redux";
import {
  Flyout,
  ActionBar,
  Button,
  Swatches,
  Message,
  ToastService,
  TextInput,
  Left,
  Right,
  TextInputType,
  Currency,
  Spacing,
  Card,
  Sub,
  Label,
  Table,
  StructuredList,
  HeadlineStatistic,
  LoadingWrapper,
  Size,
  Chip,
  Dropdown,
  ValidationMessage,
} from "ui-kit";
import { Constants } from "configuration";
import Moment from "react-moment";
import { arrays, users } from "utils";
import { useNavigate } from "react-router-dom";
import { RootState } from "reducers/store";
import { PaymentUser } from "areas/payments/reducers/payments/paymentsUserReducer";
import { BasketItemView, ProductAvailibilityView, ProductInstalmentView, ProductListView, UserProductDetailLegacyView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { AddProductToBasketCommand } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";
import { UserTinyView } from "types/users/userListViews.types";


interface IUserProductFlyoutProps {
  user: PaymentUser;
  selectedProduct: ProductListView;
}


const UserProductFlyout: React.FC<IUserProductFlyoutProps> = ({ user, selectedProduct }) => {

  const { basket, working, errors: basketErrors } = useSelector((state: RootState) => state.userBasket);
  const { product, loading, error } = useSelector((state: RootState) => state.userProduct);
  const navigate = useNavigate();
  const [excludeDiscount, setExcludeDiscount] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(1);
  const [recipient, setRecipient] = useState<ProductAvailibilityView | null>(null);
  const [total, setTotal] = useState<number>(selectedProduct?.unitPrice);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const goToBasket = () => {
    flyoutActions.closeFlyout();
    navigate(`/main/shopkeeper/users/${user.id}?tab=1&product=${selectedProduct?.id}`);
  };

  const updateTotal = () => {
    if (users.isStudent(user)) {
      setTotal(
        selectedProduct.ppDiscountedPrice && user.pupilPremium
          ? selectedProduct.ppDiscountedPrice
          : selectedProduct.bursaryDiscountedPrice && user.bursary
          ? selectedProduct.bursaryDiscountedPrice
          : selectedProduct.unitPrice
      );
    } else if (recipient) {
      setTotal(
        selectedProduct.ppDiscountedPrice && recipient.pupilPremium
          ? selectedProduct.ppDiscountedPrice
          : selectedProduct.bursaryDiscountedPrice && recipient.bursary
          ? selectedProduct.bursaryDiscountedPrice
          : selectedProduct.unitPrice
      );
    }
  }

  useEffect(() => {
    recipient && updateTotal();
  }, [recipient]);

  useEffect(() => {
    if (selectedProduct && user) {
      paymentActions.getProductForUser(
        user.id,
        selectedProduct.id,
        excludeDiscount,
        (userProductDetails: UserProductDetailLegacyView) => {
          setRecipient(userProductDetails.availableFor[0]);
          setQuantity(1);
        }
      );

      updateTotal();

      // setDiscount(
      //   selectedProduct?.ppDiscountedPrice
      //     ? selectedProduct?.ppDiscountedPrice
      //     : 0
      // );
      // setDiscountType(
      //   selectedProduct?.ppDiscountedPrice
      //     ? Constants.PAYMENT_METHODS.PUPILPREMIUM.value
      //     : null
      // );
    }
  }, [selectedProduct]);

  useEffect(() => {
    selectedProduct && setTotal(selectedProduct.unitPrice);
  }, [quantity]);

  useEffect(() => {
    if (total > selectedProduct?.unitPrice * quantity) {
      setTotal(selectedProduct?.unitPrice * quantity);
    }
  }, [total]);

  const addInstalmentToBasket = (student: UserTinyView, instalment: ProductInstalmentView) => {
    var data: AddProductToBasketCommand = {
      userId: user.id,
      recipientId: student.id,
      productId: product.id,
      quantity: 1,
      total: instalment.amount,
      virtualInstalmentId: instalment.virtualInstalmentId,
      orderType: instalment.installmentType
    };
    paymentActions.addProductToBasket(user.id, data, () => {
      ToastService.pop("Item Added to Basket", null, "shopping-bag");
    });
  };

  const getOrderType = (recipient: ProductAvailibilityView) => {
    if (!recipient.pupilPremium && !recipient.bursary) {
      return 0;
    }
    if (recipient.pupilPremium && (product.ppDiscountedPrice != null) && (product.ppDiscountedPrice != product.unitPrice)) {
      return 1;
    }
    if (recipient.bursary && (product.bursaryDiscountedPrice != null) && (product.bursaryDiscountedPrice != product.unitPrice)) {
      return 2;
    }
  }

  const addToBasket = (recipient: ProductAvailibilityView) => {
    var student = recipient.student;
    if (quantity === 1) {    
      var errors = [];
      if (total > product.unitPrice) {
        errors.push(`Added total cannot be more than the product unit price of £${product.unitPrice}`);
      }
      if (recipient.pupilPremium && product.ppDiscountedPrice != null && total > product.ppDiscountedPrice) {
        errors.push(`Added total for Pupil Premium students cannot be more than the product pupil premium discounted price of £${product.ppDiscountedPrice}`);
      }
      if (recipient.bursary && product.bursaryDiscountedPrice != null && total > product.bursaryDiscountedPrice) {
        errors.push(`Added total for Bursary students cannot be more than the product bursary discounted price of £${product.bursaryDiscountedPrice}`);
      }
      if (!arrays.isEmpty(errors)) {
        setValidationErrors(errors);
        return;
      }
    }
    setValidationErrors([]);

    var data: AddProductToBasketCommand = {
      userId: user.id,
      recipientId: student.id,
      productId: product.id,
      quantity: quantity,
      total: total,
      virtualInstalmentId: null,
      orderType: getOrderType(recipient)
    };

    paymentActions.addProductToBasket(user.id, data, () => {
      ToastService.pop("Item Added to Basket", null, "shopping-bag");
    });
  };

  const removeFromBasket = (basketItem: BasketItemView) => {
    paymentActions.removeItemFromBasket(user.id, basketItem);
  };

  const renderSingleAddButton = () => {
    var basketItem = basket?.items?.find(
      (item: BasketItemView) =>
        item?.productId === selectedProduct?.id &&
        recipient?.student?.id === item?.recipient?.id
    );
    return !basketItem ? (
      <Button
        size={Size.Medium}
        color={Swatches.Success}
        text="Add"
        working={working}
        fluid
        onClick={() => addToBasket(recipient)}
      />
    ) : (
      <Button
        size={Size.Medium}
        color={Swatches.Danger}
        text="Remove"
        working={working}
        fluid
        onClick={() => removeFromBasket(basketItem)}
      />
    );
  };

  const renderInstalmentButton = (student: UserTinyView, instalment: ProductInstalmentView) => {
    var basketItem = basket?.items?.find(
      (item) => item.virtualInstalmentId === instalment.virtualInstalmentId
    );

    return !basketItem ? (
      <Button
        size={Size.Medium}
        color={Swatches.Success}
        text="Add"
        working={working}
        onClick={() => addInstalmentToBasket(student, instalment)}
      />
    ) : (
      <Button
        size={Size.Medium}
        color={Swatches.Danger}
        text="Remove"
        working={working}
        fluid
        onClick={() => removeFromBasket(basketItem)}
      />
    );
  };

  const getQuantityOptions = (maxOrderQuantity: number) => {
    const options = [];
    for (var i = 1; i <= maxOrderQuantity; i++) {
      options.push(<Dropdown.Item value={i} label={i} />);
    }
    return options;
  };

  // const handleDiscountTypeChanged = (value) => {
  //   if (
  //     value !== Constants.PAYMENT_METHODS.PUPILPREMIUM.value &&
  //     value !== Constants.PAYMENT_METHODS.SUBSIDY.value
  //   ) {
  //     setDiscount(0);
  //   }
  //   setDiscountType(value);
  // };
  // const handleDiscountChanged = (value) => {
  //   if (value === 0) {
  //     setDiscountType(null);
  //   }
  //   setDiscount(value);
  // };

  const InstalmentsList = ({ title, filter, recipient } : { title: string, filter: number, recipient: ProductAvailibilityView}) => {
    return (
      <Table>
        <Table.Body noHover>
          { recipient?.instalments
            .filter((x) => x.installmentType == filter)
            .map((instalment, index) => (
            <Table.Row key={index}>
              <Table.Cell width={6}>
                <Moment
                  date={instalment.dueDate}
                  format="dddd, MMMM Do YYYY"
                />{" "}
                {instalment.optionalForPupilPremium && (
                  <Chip text="PP Optional" />
                )}
              </Table.Cell>
              <Table.Cell right width={2}>
                <b>
                  <Currency value={instalment.amount} />
                </b>
              </Table.Cell>
              <Table.Cell right width={2}>
                {renderInstalmentButton(
                  recipient.student,
                  instalment
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    )
  }

  const RelevantInstallments = ({ recipient } : { recipient: ProductAvailibilityView }) => {
    if (recipient.bursary && recipient.instalments.some(x => x.installmentType === 2)) {
      return <InstalmentsList title={"Bursary Instalments"} filter={2} recipient={recipient}/>
    }
    if (recipient.pupilPremium && recipient.instalments.some(x => x.installmentType === 1)) {
      return <InstalmentsList title={"Pupil Premium Instalments"} filter={1} recipient={recipient}/>
    }
    return <InstalmentsList title={"Instalments"} filter={0} recipient={recipient}/>
  }

  const getMaxPrice = (recipient: ProductAvailibilityView) => {
    if (recipient?.bursary) {
      return selectedProduct?.bursaryDiscountedPrice - recipient?.paid
    }
    if (recipient?.pupilPremium) {
      return selectedProduct?.ppDiscountedPrice - recipient?.paid
    }
    return selectedProduct?.unitPrice - recipient?.paid
  }

  return (
    <Flyout
      title={loading ? null : product?.name}
      name={Constants.FLYOUTS.USERPRODUCT}
      wide
    >
      <Flyout.Body>
        <ValidationMessage errors={validationErrors} />
        <LoadingWrapper
          loading={loading}
          error={error != null}
          data={product}
          errorMessage="There was a problem loading the product"
        >
          <Message text={error} color={Swatches.Danger} />

          {product && (
            <>
              <HeadlineStatistic>
                <HeadlineStatistic.Item
                  icon="shopping-bag"
                  value={<Currency value={product.unitPrice} />}
                  label={"Price"}
                />
                {/* {product.ppDiscountedPrice && user.pupilPremium && (
                  <HeadlineStatistic.Item
                    icon="shopping-bag"
                    value={
                      <Currency
                        value={product.unitPrice - product.ppDiscountedPrice}
                      />
                    }
                    label={
                      <Chip
                        text="Pupil Premium Price"
                        colorSwatch={Swatches.Purple}
                      />
                    }
                  />
                )} */}
                <HeadlineStatistic.Item
                  icon="gift"
                  value={product.sold}
                  label="Sold"
                />
                {product.enforceStockControl && (
                  <>
                    <HeadlineStatistic.Item
                      icon="boxes"
                      value={product.stock}
                      label="Stock"
                    />
                    <HeadlineStatistic.Item
                      icon="boxes"
                      value={product.stockRemaining}
                      label="Stock Remaining"
                    />
                  </>
                )}
              </HeadlineStatistic>

              {!product.soldOut && product.enforceStockControl && (
                <Label className="availability" bold>
                  {product.stockRemaining} Available{" "}
                  {product.expiryDate && `until` && (
                    <Moment
                      date={product.expiryDate}
                      format="[until] dddd, MMMM Do YYYY"
                    />
                  )}
                </Label>
              )}

              {basketErrors?.item && (
                <Message
                  className="basket-error"
                  text={basketErrors?.item}
                  icon="times-circle"
                  color={Swatches.Danger}
                />
              )}

              <Card>
                <Card.Body>
                  <StructuredList>
                    <StructuredList.Item
                      name="Recipient"
                      description="This is the recipient of the item. If the user is a parent, please choose the appropriate child."
                    >
                      <Dropdown
                        value={recipient}
                        onChange={(value) => setRecipient(value)}
                        fluid
                      >
                        {product?.availableFor.map((x, index) => (
                          <Dropdown.Item
                            key={index}
                            value={recipient}
                            label={
                              x.student.firstName + " " + x.student.lastName
                            }
                          />
                        ))}
                      </Dropdown>
                    </StructuredList.Item>
                  </StructuredList>
                </Card.Body>
              </Card>
              <Card title="Pricing">
                <Card.Body>
                  <StructuredList>
                    {product.allowMultiple == true && (
                      <StructuredList.Item name="Quantity">
                        <Dropdown
                          value={quantity}
                          onChange={(value) => setQuantity(value)}
                          fluid
                        >
                          {getQuantityOptions(product.maximumOrderQuantity)}
                        </Dropdown>
                      </StructuredList.Item>
                    )}

                    <StructuredList.Item name="Unit Price" required>
                      <TextInput
                        fluid
                        type={TextInputType.Currency}
                        value={total}
                        onChange={(value) => setTotal(value)}
                        min={0}
                        max={getMaxPrice(recipient)}
                      />

                      {/* <Sub>
                        This is the full unit price before any subsidy or Pupil
                        Premium discounts are applied
                      </Sub> */}
                    </StructuredList.Item>
                    {/* <StructuredList.Item name="Discount" required>
                      <TextInput
                        fluid
                        type={TextInputType.Currency}
                        value={discount}
                        onChange={(value) => handleDiscountChanged(value)}
                        min={0}
                        max={selectedProduct?.unitPrice - recipient?.paid}
                      />
                      <Sub>This is the unit discount to apply</Sub>
                    </StructuredList.Item>
                    <StructuredList.Item name="Discount Type" required>
                      <Dropdown
                        fluid
                        value={discountType}
                        onChange={(val) => handleDiscountTypeChanged(val)}
                      >
                        <Dropdown.Item
                          label="No Discount/Subsidy"
                          value={null}
                        />
                        <Dropdown.Item
                          label="Pupil Premium"
                          value={Constants.PAYMENT_METHODS.PUPILPREMIUM.value}
                        />
                        <Dropdown.Item
                          label="Subsidy"
                          value={Constants.PAYMENT_METHODS.SUBSIDY.value}
                        />
                      </Dropdown>
                    </StructuredList.Item> */}
                    <StructuredList.Item name="Total">
                      <Currency value={total * quantity} />
                    </StructuredList.Item>
                    <StructuredList.Item>
                      {renderSingleAddButton()}
                    </StructuredList.Item>
                  </StructuredList>
                </Card.Body>
              </Card>
              { recipient?.instalments.length > 0 && (
                <Card title="Instalments">
                  <Card.Body>
                    <StructuredList>
                      {( recipient?.instalments.some(x => x.installmentType === 0) 
                      || recipient?.instalments.some(x => x.installmentType === 1) && user.pupilPremium && !user.bursary
                      || recipient?.instalments.some(x => x.installmentType === 2) && user.bursary ) && (
                        <StructuredList.Item
                          name="Instalments"
                          description="These are the available instalments for the selected recipient"
                        >
                          { ( product.ppDiscountedPrice != null && !excludeDiscount && user.pupilPremium
                            || product.bursaryDiscountedPrice != null && !excludeDiscount && user.bursary) && (
                              <div style={{ marginBottom: Spacing.Default + "px" }} >
                                <Sub>
                                  { recipient.bursary ? (
                                    <>
                                      <b>Important: </b>Bursary installments will automatically be shown if the student is bursary eligible{" "}
                                    </>
                                  ) : recipient.pupilPremium ? (
                                    <>
                                      <b>Important: </b>Pupil premium instalments will automatically be shown if the student is pupil premium{" "}
                                    </>
                                  ) : null }
                                </Sub>
                              </div>
                            )}
                          <RelevantInstallments recipient={recipient}/>
                        </StructuredList.Item>
                      )}
                    </StructuredList>
                  </Card.Body>
                </Card>
              )}
            </>
          )}
        </LoadingWrapper>
      </Flyout.Body>
      <Flyout.Footer>
        <ActionBar low>
          <Button
            fluid
            color={Swatches.Success}
            text="Go to Basket"
            onClick={goToBasket}
          />
        </ActionBar>
      </Flyout.Footer>
    </Flyout>
  );
};

export default UserProductFlyout;
