import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import WallPlannerFilter from "./wallPlannerFilter";
import WallPlannerTable from "./wallPlannerTable";
import calendarActions from "../../actions/calendarActions";
import { RootState } from "reducers/store";


const WallPlanner: React.FC = () => {
  
  const { sessions, loading, error, filter } = useSelector((state: RootState) => state.wallPlannerSessions);

  useEffect(() => {
    if (filter && filter.academicYear != null) {
      calendarActions.getWallplannerSessions(filter);
    }
  }, [filter]);

  return (
    <>
      <WallPlannerFilter />
      <WallPlannerTable
        sessions={sessions}
        academicYear={filter?.academicYear}
        loading={loading}
        error={error}
      />
    </>
  );
};

export default WallPlanner;
