import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  StructuredList,
  Checkbox,
  TextInput,
  TextInputType,
  Text,
  Card,
} from "ui-kit";
import { arrays, users } from "utils";
import { Message, Swatches } from "ui-kit";
import Search, {
  SEARCH_GROUP_TYPE_KEYS,
} from "sharedComponents/common/search/search";
import { smartTaskData } from "./newSmartTaskFormWrapper";
import { UserTinyView } from "types/users/userListViews.types";


interface ISmartTaskActionsProps {
  smartTask: smartTaskData,
  setSmartTask: (data: smartTaskData) => void;
  staffEmailRecipients?: UserTinyView[];
  schoolId: number;
}


const SmartTaskActions: React.FC<ISmartTaskActionsProps> = ({
  smartTask,
  setSmartTask,
  staffEmailRecipients,
  schoolId,
}) => {

  const [emailParentsActionChecked, setEmailParentsActionChecked] = useState(
    smartTask.sendNotificationToParents
  );
  const [emailTeachersActionChecked, setEmailTeachersActionChecked] = useState(
    !arrays.isEmpty(smartTask.staffEmailRecipients)
  );
  const [emailPastoralActionChecked, setEmailPastoralActionChecked] = useState(
    smartTask.sendNotificationToPastoral
  );
  const [emailLeadershipActionChecked, setEmailLeadershipActionChecked] =
    useState(smartTask.sendNotificationToLeadership);

  const [selectedTeachers, setSelectedTeachers] = useState([]);

  useEffect(() => {
    if (!arrays.isEmpty(staffEmailRecipients)) {
      const tempSelectedTeachers = selectedTeachers.slice();

      staffEmailRecipients.forEach(teacher => {
        tempSelectedTeachers.push({
          id: teacher.id,
          title: users.getFullName(teacher),
        });
      });

      setSelectedTeachers(tempSelectedTeachers);
    }
  }, []);

  useEffect(() => {
    if (!emailTeachersActionChecked) {
      setSmartTask({ ...smartTask, staffEmailRecipients: [] });
      setSelectedTeachers([]);
    }
  }, [emailTeachersActionChecked]);

  useEffect(() => {
    if (!emailParentsActionChecked) {
      setSmartTask({
        ...smartTask,
        sendNotificationToParents: false,
        actionNote: "",
      });
    } else {
      setSmartTask({
        ...smartTask,
        sendNotificationToParents: emailParentsActionChecked,
      });
    }
  }, [emailParentsActionChecked]);

  useEffect(() => {
    setSmartTask({
      ...smartTask,
      sendNotificationToPastoral: emailPastoralActionChecked,
    });
  }, [emailPastoralActionChecked]);

  useEffect(() => {
    setSmartTask({
      ...smartTask,
      sendNotificationToLeadership: emailLeadershipActionChecked,
    });
  }, [emailLeadershipActionChecked]);

  useEffect(() => {
    setSmartTask({
      ...smartTask
    });
  }, [emailTeachersActionChecked]);

  const handleSelectTeacher = (teacher: UserTinyView) => {
    const tempTeachers = smartTask.staffEmailRecipients.slice();
    tempTeachers.push(teacher);
    setSmartTask({
      ...smartTask,
      staffEmailRecipients: tempTeachers,
    });
  };

  const handleDeleteTeacher = (teacher: UserTinyView) => {
    const tempSelectedTeachers = smartTask.staffEmailRecipients.slice();
    const index = tempSelectedTeachers.findIndex(
      selectedTeacherId => selectedTeacherId === teacher
    );

    tempSelectedTeachers.splice(index, 1);

    setSmartTask({
      ...smartTask,
      staffEmailRecipients: tempSelectedTeachers,
    });
  };

  return (
    <Card title="Smart Task Actions">
      <Card.Body>
        <StructuredList.Item
          name="Actions"
          description="The actions taken when the smart task is triggered. The student will be sent an email by default."
        >
          <Checkbox
            text="Email Parents"
            checked={emailParentsActionChecked}
            onChange={() =>
              setEmailParentsActionChecked(!emailParentsActionChecked)
            }
          />

          {emailParentsActionChecked && (
            <>
              <Message
                text="Please note: The text below will be sent to parents in the notification email."
                color={Swatches.Warning}
              />

              <StructuredList.Item
                name="Parent Email Message"
                description="Please do not add 'Dear Parent/Carer' as it will be added automatically to the email."
                required
              >
                <>
                  <Text>Dear Parent/Carer, </Text>
                  <TextInput
                    type={TextInputType.Textarea}
                    fluid
                    rows={4}
                    placeholder="Parent Email Message"
                    value={smartTask.actionNote}
                    onChange={value =>
                      setSmartTask({ ...smartTask, actionNote: value })
                    }
                  />
                </>
              </StructuredList.Item>
            </>
          )}

          <Checkbox
            text="Email Teachers"
            checked={emailTeachersActionChecked}
            onChange={() =>
              setEmailTeachersActionChecked(!emailTeachersActionChecked)
            }
          />
          {emailTeachersActionChecked && (
            <>
              <StructuredList.Item name="Teachers" required>
                <Search
                  groupTypes={[SEARCH_GROUP_TYPE_KEYS.TEACHER]}
                  allowMultiple
                  selected={selectedTeachers}
                  handleSelectItem={teacher => handleSelectTeacher(teacher)}
                  handleDeleteItem={teacher => handleDeleteTeacher(teacher)}
                  schoolId={schoolId}
                />
              </StructuredList.Item>
            </>
          )}

          <Checkbox
            text="Email SSC"
            checked={emailPastoralActionChecked}
            onChange={() =>
              setEmailPastoralActionChecked(!emailPastoralActionChecked)
            }
          />

          <Checkbox
            text="Email Leadership"
            checked={emailLeadershipActionChecked}
            onChange={() =>
              setEmailLeadershipActionChecked(!emailLeadershipActionChecked)
            }
          />
        </StructuredList.Item>
      </Card.Body>
    </Card>
  );
};

export default SmartTaskActions;
