import React, { useEffect } from "react";
import { Loader, Size, Title, TitleSize, HeadlineStatistic } from "ui-kit";
import Subheader from "sharedComponents/layout/header/subheader";
import { useSelector } from "react-redux";
import studentActions from "areas/planner/actions/student/studentActions";
import StudentActivityWrapper from "../../studentRecord/studentActivity/studentActivityWrapper";
import StudentHeadlineStats from "./studentHeadlineStats";

const StudentActivityView = () => {
  const { user } = useSelector(state => state.currentUser);
  const { student, loading } = useSelector(state => state.student);

  useEffect(() => {
    if (user) {
      studentActions.getStudent(user.id);
    }
  }, []);

  return (
    <>
      <Subheader>
        <Title text="Activity" size={TitleSize.H2} />
      </Subheader>

      {loading ? (
        <Loader size={Size.Large} fluid />
      ) : (
        student && (
          <>
            <StudentHeadlineStats student={student} />

            <StudentActivityWrapper
              studentId={user.id}
              schoolId={student.school?.id}
            />
          </>
        )
      )}
    </>
  );
};

export default StudentActivityView;
