import { IBeehiveAction } from "types/common/action.types";
import reviewPeriodActions from "../../actions/reviewPeriodActions";
import { ReviewPeriodView } from "areas/humanResources/types/managedStaffResponse.types";

interface ICurrentReviewPeriodState {
  currentReviewPeriod: ReviewPeriodView | null;
  loading: boolean;
  error: string | null;
}

const INITIAL_STATE: ICurrentReviewPeriodState = {
  currentReviewPeriod: null,
  loading: false,
  error: null,
};

const currentReviewPeriodReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ICurrentReviewPeriodState => {
  const { GETCURRENT } = reviewPeriodActions.types;

  switch (action.type) {
    case GETCURRENT.START:
      return { ...INITIAL_STATE, loading: true };
    case GETCURRENT.SUCCESS:
      return { ...INITIAL_STATE, currentReviewPeriod: action.payload };
    case GETCURRENT.FAILED:
      return { ...INITIAL_STATE, error: action.payload };
    default:
      return state;
  }
};

export default currentReviewPeriodReducer;
