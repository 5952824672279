import React from "react";
import styled from "styled-components";
import { Table, EmptyMessage, Spacing, Label } from "ui-kit";
import { arrays } from "utils";
import ReadOnlyField from "../readOnlyField";
import { FieldGroupListView, FieldListView } from "areas/forms/types/formResponse.types";


const EmptyWrapper = styled.div`
  padding: ${Spacing.Large}px;
`;


interface ISubmissionTableProps {
  group: FieldGroupListView;
  rows: FieldListView[];
}


const SubmissionTable: React.FC<ISubmissionTableProps> = ({ group, rows }) => {
  
  const EmptyRow = ({ description } : { description: string }) => {
    return (
      <EmptyMessage icon="paste" title="No Items Added" cover></EmptyMessage>
    );
  };

  return (
    <>
      {group.submittedRows === 0 ? (
        <EmptyWrapper>
          <EmptyRow description={group.description} />
        </EmptyWrapper>
      ) : (
        <Table>
          <Table.Header>
            {group.fields.map((field: FieldListView, index: number) => (
              <Table.HeaderCell
                key={index}
                right={field.fieldType === 1 || field.fieldType === 2}
              >
                {field.name}
              </Table.HeaderCell>
            ))}
          </Table.Header>
          <Table.Body>
            {[...Array(group.submittedRows)].map((a, b: number) => (
              <Table.Row key={b}>
                {group.fields.map((field: FieldListView, index: number) => (
                  <Table.Cell
                    key={index}
                    right={field.fieldType === 1 || field.fieldType === 2}
                  >
                    <p>
                      <ReadOnlyField
                        field={field}
                        value={field.submittedValues[b]?.value}
                      />
                    </p>
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            {group.fields.some(field => field.isSummable) &&
              !arrays.isEmpty(rows) && (
                <Table.Row>
                  {group.fields.map((field: FieldListView, index: number) => (
                    <Table.Cell
                      key={index}
                      right={field.fieldType == 1 || field.fieldType == 2}
                    >
                      {field.isSummable ? (
                        <Label bold>
                          <ReadOnlyField
                            field={field}
                            value={arrays.sum(field.submittedValues, "value")}
                          />
                        </Label>
                      ) : null}
                    </Table.Cell>
                  ))}
                </Table.Row>
              )}
          </Table.Footer>
        </Table>
      )}
    </>
  );
};

export default SubmissionTable;
