import { CalendarEvent } from "areas/calendar/reducers/calendarEventReducer";
import { CalendarEventDateListView, GroupedMenuView, CalendarEventMenuItemListView } from "areas/calendar/types/calendarResponses.types";
import React, { useState, useEffect } from "react";
import { GroupList } from "sharedComponents/common";
import { Card, Chip, Dropdown, HtmlText, Message, Spacing, StructuredList, Sub, Swatches, Table, TextInput, TextInputType, ValidationMessage } from "ui-kit";
import { SignupDetails } from ".";
import DateSignupButton from "./dateSignupButton";
import FormatDate from "./formatDate";
import RemainingPlaces from "./remainingPlaces";
import { useAppSelector } from "reducers/hooks";
import styled from "styled-components";
import moment from "moment";


const Wrapper = styled.div`
  .signup-all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button {
      margin-bottom: ${Spacing.Large}px;
    }
    > span {
    }
  }

  .cancel-all {
    margin-bottom: ${Spacing.Large}px;
  }
`;


interface IEventDetailsProps {
  calendarEvent: CalendarEvent;
  validationErrors: string[];
  readonly?: boolean;
}


const EventDetails: React.FC<IEventDetailsProps> = ({ calendarEvent, validationErrors, readonly }) => {

  const { signupError } = useAppSelector(state => state.calendarEvent);

  const signupStillOpen = (cc: CalendarEvent) => {
    return (
      cc?.allowSignupTo == null ||
      moment(cc?.allowSignupTo).isAfter(moment())
    )
  }

  if (!calendarEvent) {
    return (
      <></>
    )
  }

  return (
    <>
      <GroupList
        groups={calendarEvent?.groups}
        style={{ marginBottom: Spacing.Small }}
      />
      {calendarEvent?.signedUp && !calendarEvent.mandatoryAttendance && (
        <Message
          text={`${readonly ? "This student is" : "You are"} signed up for dates in this event.`}
          color={Swatches.Success}
        />
      )}
      {calendarEvent?.signedUp && calendarEvent.mandatoryAttendance && (
        <Message
          text="Attendance for this event is mandatory, and as a result you have been automatically signed up"
          color={Swatches.Low}
        />
      )}
      {!calendarEvent?.signedUp && !calendarEvent.mandatoryAttendance && !signupStillOpen(calendarEvent) && (
        <Message
          text={`Signups are now closed for this event.`}
          color={Swatches.Low}
        />
      )}
      <ValidationMessage errors={validationErrors} />
      <Message text={signupError} color={Swatches.Danger} />
      <Card title="About this Event">
        <Card.Body>
          <HtmlText html={calendarEvent?.description} />
        </Card.Body>
      </Card>
    </>
  );
}


export default EventDetails;