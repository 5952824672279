import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Right,
  ActionBar,
  Swatches,
  Size,
  Card,
  StructuredList,
} from "ui-kit";
import Table, { TableHeaderCell } from "ui-kit/composite/table";

interface IViewWarningModalProps {
  notes?: string[];
  open?: boolean;
  onCancel?: () => void;
  userSyncView?: boolean;
  userNotes?: string;
  failureErrorsModal?: boolean;
}

const ViewWarningModal: React.FC<IViewWarningModalProps> = ({
  notes,
  open,
  onCancel,
  userSyncView,
  userNotes,
  failureErrorsModal,
}) => {
  const [_open, _setOpen] = useState<boolean>(false);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const parseWarnings = (data: string) => {
    console.log(data);
    if (typeof data !== "string" || data.trim() === "") {
      console.error("Invalid input: Data should be a non-empty string.");
      return [];
    }
    try {
      const warningsArray = data.split("~").map((warning) => warning.trim());
      const warnings = warningsArray.filter((warning) => warning.length > 0);
      return warnings;
    } catch (error) {
      console.error("An error occurred while parsing warnings:", error);
      return [];
    }
  };

  if (!open && !oncancel && userSyncView)
    return (
      <>
        <Card title="Sync Details">
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="Total Warnings">
                {parseWarnings(userNotes).length}
              </StructuredList.Item>
              <StructuredList.Item name="Last Synced">
                {"26/3/2024"}
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>

        <Table>
          <TableHeaderCell>Warnings</TableHeaderCell>
          <Table.Body>
            {userNotes &&
              parseWarnings(userNotes).map((warning: any, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell>{warning}</Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </>
    );

  return (
    <Modal
      width="60%"
      height="60%"
      title={failureErrorsModal ? "User Errors" : "User Warnings"}
      open={_open}
      onClose={onCancel}
    >
      <Modal.Body>
        <Table>
          <TableHeaderCell>
            {failureErrorsModal ? "Errors" : "Warnings"}
          </TableHeaderCell>
          <Table.Body>
            {notes &&
              notes.map((warning: any, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell>{warning}</Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              onClick={onCancel}
              size={Size.Small}
              color={Swatches.Low}
              text="Close"
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewWarningModal;
