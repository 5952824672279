import React, { useEffect, useState } from "react";
import SmartTaskName from "./smartTaskDetails";
import SmartTaskConditionsWrapper from "./smartTaskConditionsWrapper";
import SmartTaskActions from "./smartTaskActions";
import SmartTaskOptions from "./smartTaskOptions";
import {
  StructuredList,
  Button,
  Swatches,
  ActionBar,
  Right,
  Left,
  Message,
  ValidationMessage,
} from "ui-kit";
import smartTaskActions from "areas/behaviour/actions/smartTasks/smartTaskActions";
import { useAppSelector } from "reducers/hooks";
import { SaveSmartTaskCommand } from "areas/behaviour/types/smartTasks/smartTaskRequest.types";
import { smartTaskData } from "./newSmartTaskFormWrapper";
import { EventName } from "areas/behaviour/types/smartTasks/smartTaskShared.types";
import { arrays } from "utils";
import { useScroll } from "hooks/useScroll";


interface IEditSmartTaskFormWrapperProps {
  selectedSmartTaskId: number;
  schoolId: number;
  closeEditMode: () => void;
  openConfirmDelete: () => void;
}


const EditSmartTaskFormWrapper: React.FC<IEditSmartTaskFormWrapperProps> = ({
  selectedSmartTaskId,
  schoolId,
  closeEditMode,
  openConfirmDelete,
}) => {
  
  const { scrollToTop } = useScroll();
  const { smartTask, updateSmartTaskError } = useAppSelector(
    state => state.smartTask
  );

  const [selectedSmartTask, setSelectedSmartTask] = useState<smartTaskData | null>();
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    if (smartTask) {
      setSelectedSmartTask({
        ...smartTask,
        sendNotificationToParents: smartTask.sendNotificationToParents || false,
        sendNotificationToPastoral: smartTask.sendNotificationToPastoral || false,
        sendNotificationToLeadership: smartTask.sendNotificationToLeadership || false,
        behaviourCodeId: smartTask.behaviourCodeId,
        yearGroupId: smartTask.yearGroupId,
        classGroupId: smartTask.classGroupId,
        subjectId: smartTask.subjectId,
        schoolBehaviourCategoryId: smartTask.schoolBehaviourCategoryId,
        calendarCategoryId: smartTask.calendarCategoryId
      });
    }
  }, [smartTask]);

  const handleUpdateSmartTask = () => {
    const callback = () => {
      closeEditMode();
    };

    setValidationErrors([]);
    var errors: string[] = [];
    if (selectedSmartTask.eventNameId === EventName.Event && selectedSmartTask.calendarCategoryId == null) {
      errors.push("Event smarttasks must have an event category");
    }
    setValidationErrors(errors);
    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    smartTaskActions.updateSmartTask(
      schoolId,
      selectedSmartTaskId,
      {
        ...selectedSmartTask, 
        staffEmailRecipients: selectedSmartTask.staffEmailRecipients.map(x => x.id)
      } as SaveSmartTaskCommand,
      callback
    );
  };

  return (
    selectedSmartTask ? (
      <>
        <StructuredList>
          {updateSmartTaskError && (
            <Message
              text={updateSmartTaskError.message}
              color={Swatches.Danger}
            />
          )}
          <ValidationMessage errors={validationErrors} />

          <SmartTaskName
            smartTask={selectedSmartTask}
            setSmartTask={smartTask => setSelectedSmartTask(smartTask)}
            schoolId={schoolId}
            disabled={selectedSmartTask.isTriggered}
          />

          <SmartTaskConditionsWrapper
            smartTask={selectedSmartTask}
            setSmartTask={smartTask => setSelectedSmartTask(smartTask)}
            schoolId={schoolId}
            disabled={selectedSmartTask.isTriggered}
          />

          <SmartTaskActions
            smartTask={selectedSmartTask}
            setSmartTask={smartTask => setSelectedSmartTask(smartTask)}
            staffEmailRecipients={smartTask.staffEmailRecipients}
            schoolId={schoolId}
          />

          <SmartTaskOptions
            smartTask={selectedSmartTask}
            setSmartTask={smartTask => setSelectedSmartTask(smartTask)}
          />
        </StructuredList>

        <ActionBar>
          <Left>
            <Button
              color={Swatches.Success}
              disabled={
                (selectedSmartTask.sendNotificationToParents && selectedSmartTask.actionNote.trim() === "") ||
                //(selectedSmartTask.sendNotificationToStaff && arrays.isEmpty(selectedSmartTask.staffEmailRecipients)) ||
                selectedSmartTask.name.trim() === ""
              }
              text="Save"
              onClick={handleUpdateSmartTask}
            />
            <Button
              color={Swatches.Low}
              text="Cancel"
              onClick={closeEditMode}
            />
          </Left>
          <Right>
            {" "}
            <Button
              color={Swatches.Danger}
              text="Delete"
              onClick={openConfirmDelete}
              disabled={smartTask.isTriggered}
              disabledTooltip={"Cannot delete a triggered smarttask"}
            />
          </Right>
        </ActionBar>
      </>
    ) : (
      <></>
    )
  );
};

export default EditSmartTaskFormWrapper;
