import React from "react";
import { Chip, Swatches } from "ui-kit";
import { DETENTION_STATUS_KEYS } from "areas/behaviour/constants/detentions";


interface IDetentionStatusChipProps {
  detentionStatus: number;
  issuedText?: string;
  cancelledText?: string;
}


const DetentionStatusChip: React.FC<IDetentionStatusChipProps>  = ({
  detentionStatus,
  issuedText,
  cancelledText,
}) => {

  switch (detentionStatus) {
    case DETENTION_STATUS_KEYS.ISSUED:
      return (
        <Chip
          text={issuedText ? issuedText : "Detention"}
          colorSwatch={Swatches.Primary}
          tooltip="Issued Detention"
          tooltipSub="The detention is issued"
        />
      );
    case DETENTION_STATUS_KEYS.ATTENDED:
      return (
        <Chip
          text="Attended"
          colorSwatch={Swatches.Success}
          tooltip="Detention Attended"
          tooltipSub="The detention has been attended"
        />
      );
    case DETENTION_STATUS_KEYS.MISSED:
      return (
        <Chip
          text="Missed"
          colorSwatch={Swatches.Danger}
          tooltip="Detention Missed"
          tooltipSub="The detention has been missed"
        />
      );
    case DETENTION_STATUS_KEYS.CANCELLED:
      return (
        <Chip
          text={"Cancelled"}
          colorSwatch={Swatches.Default}
          tooltip="Detention Cancelled"
          tooltipSub="The detention has been cancelled"
        />
      );
    case DETENTION_STATUS_KEYS.RESCHEDULED:
      return (
        <Chip
          text={"Rescheduled"}
          colorSwatch={Swatches.Default}
          tooltip="Detention Rescheduled"
          tooltipSub="The detention has been rescheduled"
        />
      );
    default:
      return null;
  }
};

export default DetentionStatusChip;
