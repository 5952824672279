import { IBeehiveAction } from "types/common/action.types";
import feedActions from "../actions/feedActions";
import { FileListView } from "types/common/files.types";


interface IFeedGalleryProps {
  images: FileListView[] | null;
  selectedIndex: number | null;
  open: boolean;
}

const INITIAL_STATE: IFeedGalleryProps = {
  images: null,
  selectedIndex: null,
  open: false,
};

const feedGalleryReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IFeedGalleryProps => {

  const { 
    GALLERY_OPEN, 
    GALLERY_CLOSE 
  } = feedActions.types;

  switch (action.type) {
    case GALLERY_OPEN:
      return {
        ...state,
        open: true,
        images: action.payload.images,
        selectedIndex: action.payload.selectedIndex,
      };

    case GALLERY_CLOSE:
      return { 
        ...INITIAL_STATE, 
        open: false 
      };

    default:
      return state;
  }
};

export default feedGalleryReducer;
