import React from "react";
import { Tabs } from "ui-kit";
import { useSelector } from "react-redux";
import Inventory from "./inventory";
import Smartcards from "./smartcards/smartcards";
import Dashboard from "./dashboard";
import PrintCredits from "./smartcards/printCredits";
import Reporting from "./reporting/index";
import Reconciliations from "./reconciliations/index";
import IncomingReports from "./incoming/index";
import paymentActions from "areas/payments/actions/paymentsActions";
import { users } from "utils";
import { Constants } from "configuration";
import BasketTools from "./basketTools";
import { RootState } from "reducers/store";


const Shopkeeper = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { selectedTab } = useSelector((state: RootState) => state.paymentsHome);

  const handleTabSelected = (name: string, index: number) => {
    paymentActions.switchHomeTab(index);
  };

  const userIsBuyerOnly = () => {
    return (
      users.isInAnyRoles(user, [Constants.ROLES.FINANCE_BUYER]) &&
      userIsNotFinance()
    );
  };

  const userIsNotFinance = () => {
    return (
      !users.isInRole(user, Constants.ROLES.FINANCE_ADMIN) &&
      !users.isInRole(user, Constants.ROLES.FINANCE_TEAMLEADER) &&
      !users.isInRole(user, Constants.ROLES.FINANCE_USER)
    );
  };

  return (
    <>
      <Tabs onTabSelected={handleTabSelected} initialTabIndex={selectedTab}>
        <Tabs.Pane 
          name="Dashboard"
          label="Dashboard"
        >
          <Dashboard buyerOnlyRole={userIsBuyerOnly()} />
        </Tabs.Pane>
        <Tabs.Pane 
          name="Inventory" 
          label="Inventory"
          hide={userIsBuyerOnly() || userIsNotFinance()}
        >
          <Inventory />
        </Tabs.Pane>
        <Tabs.Pane
          name="Smartcards"
          label="Smartcards"
          hide={userIsBuyerOnly() || userIsNotFinance()}
        >
          <Smartcards />
        </Tabs.Pane>
        <Tabs.Pane
          name="Print Credits"
          label="Print Credits"
          hide={userIsBuyerOnly() || userIsNotFinance()}
        >
          <PrintCredits />
        </Tabs.Pane>
        <Tabs.Pane name="Reconciliations" label="Reconciliations" hide={true}>
          <Reconciliations />
        </Tabs.Pane>
        <Tabs.Pane name="Reporting" label="Reporting" hide={userIsBuyerOnly() || userIsNotFinance()}>
          <Reporting />
        </Tabs.Pane>
        <Tabs.Pane
          name="Incoming"
          label="Incoming"
          hide={
            !users.isInAnyRoles(
              user,
              [Constants.ROLES.DEVELOPMENT,
              Constants.ROLES.FINANCE_USER,
              Constants.ROLES.FINANCE_ADMIN,
              Constants.ROLES.FINANCE_TEAMLEADER]
            )
          }
        >
          <IncomingReports />
        </Tabs.Pane>
        <Tabs.Pane
          name="Basket Tools"
          label="Basket Tools"
          hide={
            !users.isInAnyRoles(
              user,
              [Constants.ROLES.DEVELOPMENT,
              Constants.ROLES.FINANCE_USER,
              Constants.ROLES.FINANCE_ADMIN,
              Constants.ROLES.FINANCE_TEAMLEADER]
            )
          }
        >
          <BasketTools />
        </Tabs.Pane>
      </Tabs>
    </>
  );
};

export default Shopkeeper;
