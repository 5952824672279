import authenticationActions from "actions/authentication/authenticationActions";
import startupActions from "actions/startup/startupActions";
import defaults, { IActionState } from "./defaults"
import { UserStartupView } from "types/users/userStartup.types";

interface ICurrentUserState {
  user: UserStartupView | null;
  forceLogout: boolean;
  database: null,
  actions: {
    getUser: IActionState,
    submitAcceptableUse: IActionState,
    load: IActionState
  },
}

interface ICurrentUserAction {
  type: string,
  payload: any,
}

const INITIAL_STATE : ICurrentUserState = {
  user: null,
  forceLogout: false,
  database: null,
  actions: {
    getUser: defaults.INITIAL_ACTION_STATE,
    submitAcceptableUse: defaults.INITIAL_ACTION_STATE,
    load: defaults.INITIAL_ACTION_STATE,
  },
};

const currentUserReducer = (state: ICurrentUserState = INITIAL_STATE, action: ICurrentUserAction) : ICurrentUserState => {

  const {
    GETUSER,
    STAFF_ROLESCHANGED,
    STAFF_SCHOOLSCHANGED,
    STAFF_BASESCHOOLCHANGED,
    SETUSERFORNEWWINDOW,
    ACCEPTABLEUSE_SUBMIT,
  } = startupActions.types;

  const { FORCE_LOGOUT } = authenticationActions.types;

  switch (action.type) {
    case GETUSER.START:
      return {
        ...INITIAL_STATE,
        actions: {
          ...state.actions,
          getUser: { isLoading: true, error: null },
        },
      };

    case GETUSER.SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        database: action.payload.database,
        actions: { ...state.actions, getUser: defaults.INITIAL_ACTION_STATE },
      };

    case GETUSER.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          load: { isLoading: false, error: action.payload },
        },
      };

    case STAFF_ROLESCHANGED:
      return { ...state, user: { ...state.user!, roles: action.payload } };

    case STAFF_SCHOOLSCHANGED:
      return { ...state, user: { ...state.user!, schools: action.payload } };

    case STAFF_BASESCHOOLCHANGED:
      return { ...state, user: { ...state.user!, baseSchool: action.payload } };

    case SETUSERFORNEWWINDOW:
      return { ...state, user: action.payload };

    case ACCEPTABLEUSE_SUBMIT.START:
      return {
        ...state,
        actions: {
          ...state.actions,
          submitAcceptableUse: { isLoading: true, error: null },
        },
      };

    case ACCEPTABLEUSE_SUBMIT.SUCCESS:
      return {
        ...state,
        actions: {
          ...state.actions,
          //submitAcceptableUse: defaults.DEFAULT_ACTION,
        },
      };

    case ACCEPTABLEUSE_SUBMIT.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          submitAcceptableUse: { isLoading: true, error: action.payload },
        },
      };

    case FORCE_LOGOUT:
      return { ...state, forceLogout: true };
    default:
      return state;
  }
};

export default currentUserReducer;
