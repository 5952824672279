import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { neutral, Swatches } from "ui-kit/common/colors";
import {
  Text,
  Button,
  fontStyle,
  displayFont,
  typescale,
  Spacing,
} from "ui-kit";
import { GRID_CHANGE, GRID_PROP } from "areas/planner/utils/layouts";

const Wrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;

  .change-rows,
  .change-cols {
    display: flex;
    align-items: center;
    margin: 0 ${Spacing.Large}px ${Spacing.ExtraLarge}px;

    .change-row-col-button {
      padding: ${Spacing.Small}px ${Spacing.Small + 4}px;
      // padding-left: ${Spacing.Medium}px;

      .button-content .icon {
        margin: 0;
        font-size: 0.6rem;
      }
    }

    .text {
      margin: 0 ${Spacing.Small}px;
      ${fontStyle(displayFont.medium, typescale.paragraph, neutral[700])}
    }
  }
`;

const RoomLayoutSizeFormField = ({ changeRoomRowsCols, rows, cols }) => {
  const button = (icon, handleOnClick, disabled) => {
    return (
      <Button
        className="change-row-col-button"
        icon={icon}
        color={Swatches.Cancel}
        onClick={handleOnClick}
        disabled={disabled}
      />
    );
  };

  return (
    <Wrapper>
      <div className="change-rows">
        {button(
          "minus",
          () => changeRoomRowsCols(GRID_PROP.ROWS, GRID_CHANGE.MINUS),
          rows === 1
        )}
        <Text>Rows</Text>
        {button("plus", () =>
          changeRoomRowsCols(GRID_PROP.ROWS, GRID_CHANGE.ADD)
        )}
      </div>

      <div className="change-cols">
        {button(
          "minus",
          () => changeRoomRowsCols(GRID_PROP.COLS, GRID_CHANGE.MINUS),
          cols === 1
        )}
        <Text>Columns</Text>
        {button("plus", () =>
          changeRoomRowsCols(GRID_PROP.COLS, GRID_CHANGE.ADD)
        )}
      </div>
    </Wrapper>
  );
};

RoomLayoutSizeFormField.propTypes = {
  changeRoomRowsCols: PropTypes.func,
};

export default RoomLayoutSizeFormField;
