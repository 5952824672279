import React from "react";
import { Checkbox } from "ui-kit";
import BehaviourOutcomeCheckbox from "./behaviourOutcomeCheckbox";
import { OUTCOMES, OutcomeType } from "areas/behaviour/constants/behaviours";


interface IBehaviourOutcomeFiltersProps {
  filterByOutcome: boolean;
  handleFilterbyOutcomeClick: () => void;
  selectedOutcomes: OutcomeType[];
  handleOutcomeClick: (outcome: OutcomeType, isChecked: boolean) => void;
}

const BehaviourOutcomeFilters: React.FC<IBehaviourOutcomeFiltersProps> = ({
  filterByOutcome,
  handleFilterbyOutcomeClick,
  selectedOutcomes,
  handleOutcomeClick,
}) => {
  
  return (
    <>
      <Checkbox
        text={`Filter by Outcome`}
        checked={filterByOutcome}
        onChange={handleFilterbyOutcomeClick}
      />
      {filterByOutcome && (
        <>
          <br />
          {OUTCOMES.map(outcome => (
            <BehaviourOutcomeCheckbox
              outcome={outcome}
              selectedBehaviourOutcomes={selectedOutcomes}
              handleOutcomeClick={handleOutcomeClick}
            />
          ))}
        </>
      )}
    </>
  );
};

export default BehaviourOutcomeFilters;
