import React, { useEffect, useState } from "react";
import { MultiSchoolUserView, SchoolDropdown } from "sharedComponents/common";
import { SearchResultViewBase } from "types/common/views.types";
import { ActionBar, Button, Card, Size, StructuredList, Swatches } from "ui-kit";
import Search, {
  SEARCH_GROUP_TYPE_KEYS,
} from "sharedComponents/common/search/search";
import { FilterBy } from "areas/analytics/types/lessonExitAnalyticShared.types";
import { GetStudentAssignedStaffReportCommand } from "areas/analytics/types/assignedStaffAnalyticRequests.types";
import { useAppSelector } from "reducers/hooks";


export interface GetStudentAssignedStaffReportCommandExt extends Omit<GetStudentAssignedStaffReportCommand, "reportFilters"> {
  filters: {
    filterById: FilterBy, 
    names: SearchResultViewBase[]
  }[];
}

interface IAssignedStaffAnalyticsFormProps {
  onGenerateReport: (command: GetStudentAssignedStaffReportCommand, exportReport?: Boolean) => void;
  loading: boolean;
}

enum filterAction {
  add,
  remove
}


const AssignedStaffAnalyticsForm: React.FC<IAssignedStaffAnalyticsFormProps> = ({
  onGenerateReport, 
  loading
}) => {

  const { user } = useAppSelector(state => state.currentUser);
  const [parameters, setParameters] = useState<GetStudentAssignedStaffReportCommandExt>({
    schoolId: null,
    filters: [
      { filterById: FilterBy.Teacher, names: [] },
      { filterById: FilterBy.Pupil, names: [] },
      { filterById: FilterBy.YearGroup, names: [] },
      { filterById: FilterBy.TutorGroup, names: [] },
    ]
  });

  useEffect(() => {
    user && setParameters({...parameters, schoolId: user.baseSchool.id});
  }, [user]);

  const handleFilterChange = (item: SearchResultViewBase, filterType: FilterBy, action: filterAction) => {
    var _filters = parameters.filters.map(filter => 
      filter.filterById === filterType
      ? {
        ...filter,
        names: action === filterAction.add
          ? [...filter.names, item]
          : filter.names.filter(x => x.id !== item.id)
      }
      : filter
    )
    setParameters({
      ...parameters,
      filters: _filters
    });
  }

  const handleGenerateReport = (exportReport: boolean) => {
    onGenerateReport(
      {
        ...parameters, 
        reportFilters: parameters.filters
          .filter(filter => filter.names.length > 0)
          .map(filter => ({...filter, names: filter.names.map(name => name.id)}))
      },
      exportReport
    )
  }

  return (
    <>
      <MultiSchoolUserView>
        <Card title="School">
          <Card.Body>
            <SchoolDropdown
              onChange={value => setParameters({...parameters, schoolId: value})}
              initialValue={parameters.schoolId}
              fluid
            />
          </Card.Body>
        </Card>
      </MultiSchoolUserView>

      <Card title="Filters">
          <Card.Body>
            <StructuredList.Item
              name="Teacher"
              description="Filter by selected teachers"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.STAFF]}
                allowMultiple
                selected={parameters.filters.find(x => x.filterById === FilterBy.Teacher)?.names}
                handleSelectItem={filter =>
                  handleFilterChange(filter, FilterBy.Teacher, filterAction.add)
                }
                handleDeleteItem={filter =>
                  handleFilterChange(filter, FilterBy.Teacher, filterAction.remove)
                }
                schoolId={parameters.schoolId}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Student"
              description="Filter by selected students"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.STUDENT]}
                allowMultiple
                selected={parameters.filters.find(x => x.filterById === FilterBy.Pupil)?.names}
                handleSelectItem={filter =>
                  handleFilterChange(filter, FilterBy.Pupil, filterAction.add)
                }
                handleDeleteItem={filter =>
                  handleFilterChange(filter, FilterBy.Pupil, filterAction.remove)
                }
                schoolId={parameters.schoolId}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Year Group"
              description="Filter by selected year groups"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.YEAR_GROUP]}
                allowMultiple
                selected={parameters.filters.find(x => x.filterById === FilterBy.YearGroup)?.names}
                handleSelectItem={filter =>
                  handleFilterChange(filter, FilterBy.YearGroup, filterAction.add)
                }
                handleDeleteItem={filter =>
                  handleFilterChange(filter, FilterBy.YearGroup, filterAction.remove)
                }
                schoolId={parameters.schoolId}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Tutor Group"
              description="Filter by selected tutor groups"
              helpPopup
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.TUTOR_GROUP]}
                allowMultiple
                selected={parameters.filters.find(x => x.filterById === FilterBy.TutorGroup)?.names}
                handleSelectItem={filter =>
                  handleFilterChange(filter, FilterBy.TutorGroup, filterAction.add)
                }
                handleDeleteItem={filter =>
                  handleFilterChange(filter, FilterBy.TutorGroup, filterAction.remove)
                }
                schoolId={parameters.schoolId}
              />
            </StructuredList.Item>

          </Card.Body>
        </Card>

      <ActionBar>
        <Button
          text="Generate Report"
          color={Swatches.Success}
          size={Size.Small}
          onClick={() => handleGenerateReport(false)}
          working={loading}
        />
        <Button
          color={Swatches.Success}
          text="Export Report as CSV"
          icon="file-download"
          size={Size.Small}
          onClick={() => handleGenerateReport(true)}
          working={loading}
        />
      </ActionBar>
    </>
  )
}

export default AssignedStaffAnalyticsForm;