import { Swatches } from "ui-kit";
import { purple, deepPink, teal } from "ui-kit/common/colors";
import { neutral } from "../../../../ui-kit";
import sensitiveData from "./sensitiveData";

export const STUDENT_FILTERS = [
  { ...sensitiveData.PP, setFilter: null },
  { ...sensitiveData.EAL, setFilter: null },
  { ...sensitiveData.SEN, setFilter: null },
  { ...sensitiveData.FSM, setFilter: null },
];

export const STUDENT_ABILITY_FILTERS = [
  { key: sensitiveData.STUDENT_ABILITY_KEYS.LAP, text: "LAP", setFilter: null },
  { key: sensitiveData.STUDENT_ABILITY_KEYS.MAP, text: "MAP", setFilter: null },
  { key: sensitiveData.STUDENT_ABILITY_KEYS.HAP, text: "HAP", setFilter: null },
];
