import { IBeehiveAction } from "types/common/action.types";
import observationActions from "../../actions/observationActions";
import { ObservationListView } from "areas/humanResources/types/managedStaffResponse.types";

interface IObservationsState {
  observations: ObservationListView[];
  loading: boolean;
  error: string | null;
}

const INITIAL_STATE: IObservationsState = {
  observations: [],
  loading: false,
  error: null,
};

const observationsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IObservationsState => {
  const { GETOBSERVATIONS } = observationActions.types;

  switch (action.type) {
    case GETOBSERVATIONS.START:
      return { ...INITIAL_STATE, loading: true };
    case GETOBSERVATIONS.SUCCESS:
      return { ...INITIAL_STATE, observations: action.payload };
    case GETOBSERVATIONS.FAILED:
      return { ...INITIAL_STATE, error: action.payload };
    default:
      return state;
  }
};

export default observationsReducer;
