import { IBeehiveError } from "types/common/errors.types";
import managedMovesActions from "../../actions/managedMoves/managedMovesActions";
import { IBeehiveAction } from "types/common/action.types";
import { ManagedMoveSummaryView } from "areas/planner/types/managedMoves/managedMovedResponse.types";


interface IManagedMovesSummaryState {
  summary: ManagedMoveSummaryView;
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IManagedMovesSummaryState = {
  summary: null,
  loading: false,
  error: null,
};

const managedMovesSummaryReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IManagedMovesSummaryState => {

  const { MANAGEDMOVES_GETSUMMARY } = managedMovesActions.types;

  switch (action.type) {
    case MANAGEDMOVES_GETSUMMARY.START:
      return { ...INITIAL_STATE, loading: true };
    case MANAGEDMOVES_GETSUMMARY.SUCCESS:
      return { ...INITIAL_STATE, summary: action.payload };
    case MANAGEDMOVES_GETSUMMARY.FAILED:
      return { ...INITIAL_STATE, error: action.payload };
    default:
      return state;
  }
};

export default managedMovesSummaryReducer;
