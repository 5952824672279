import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import FileDownload from "js-file-download";
import moment from "moment";
import { urls } from "../../../utils";
import { BeehiveReport, callbackType } from "types/common/action.types";
import { PagedQueryView } from "types/common/views.types";
import { OnCallRequestDetailView } from "../reducers/onCallAnalyticsResponse.types";
import { OnCallRequestsFilter } from "../reducers/onCallAnalyticsRequest.types";

const builder = new actionTypeBuilder("analytics");

const types = {
  GETONCALLREPORT: builder.build("getoncallreport"),
  EXPORTONCALLREPORT: builder.build("exportoncallreport"),
};


const getOnCallReport = <T extends boolean = false>(
  schoolId: number,
  {
    startDate,
    endDate,
    statusId,
    studentId,
    categoryId,
    roomId,
    pupilPremium,
    sen,
    gender,
    classGroupId,
    tutorGroupId,
    provisionGroupId,
    academicYearId
  }: OnCallRequestsFilter,
  exportReport: T,
  onSuccess?: callbackType<T extends true ? BeehiveReport : PagedQueryView<OnCallRequestDetailView>>
) => {

  var url: any;

  if (exportReport) {
    url = new urls.QueryString(
      `planner/schools/${schoolId}/oncallRequestsExport`
    );
  } else {
    url = new urls.QueryString(`planner/schools/${schoolId}/oncallRequests`);
  }

  url.addParam("pageIndex", 0);
  url.addParam("pageSize", 10000);
  url.addParam("startDate", moment(startDate).format("YYYY-MM-DD"));
  url.addParam("endDate", moment(endDate).format("YYYY-MM-DD"));
  url.addParams({
    statusId,
    studentId,
    categoryId,
    roomId,
    pupilPremium,
    sen,
    gender,
    classGroupId,
    tutorGroupId,
    provisionGroupId,
    academicYearId
  });

  return exportReport
    ? action<BeehiveReport>(
        () => client.get(url.toUrl(), { responseType: "blob" }),
        types.EXPORTONCALLREPORT,
        response => {
          onSuccess?.(response as any);
          FileDownload(response, "On Call Report.csv");
        }
      )
    : action<PagedQueryView<OnCallRequestDetailView>>(
        () => client.get(url.toUrl()), 
        types.GETONCALLREPORT, 
        response => onSuccess?.(response as any)
      );
};


const onCallReportActions = { 
  types, 
  getOnCallReport 
};

export default onCallReportActions;
