import { BeehiveReport, IBeehiveAction } from "types/common/action.types";
import reportingActions from "../actions/reportingActions";

interface IHolidayCardReportingState {
  report: BeehiveReport;
  working: boolean;
  error: string | null;
}

const INITIAL_STATE: IHolidayCardReportingState = {
  report: null,
  working: false,
  error: null,
};

const holidayCardReportingReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IHolidayCardReportingState => {
  
  const { 
    HOLIDAYCARDS_REPORT, 
    LEAVEREQUESTS_REPORT, 
    STAFFLEAVEREQUESTS_REPORT
  } = reportingActions.types;

  switch (action.type) {
    case HOLIDAYCARDS_REPORT.START:
    case LEAVEREQUESTS_REPORT.START:
    case STAFFLEAVEREQUESTS_REPORT.START:
      return {
        ...INITIAL_STATE,
        working: true,
        error: null,
      };

    case HOLIDAYCARDS_REPORT.SUCCESS:
    case LEAVEREQUESTS_REPORT.SUCCESS:
    case STAFFLEAVEREQUESTS_REPORT.SUCCESS:
      return {
        ...state,
        report: action.payload,
        working: false,
        error: null,
      };

    case HOLIDAYCARDS_REPORT.FAILED:
    case LEAVEREQUESTS_REPORT.FAILED:
    case STAFFLEAVEREQUESTS_REPORT.FAILED:
      return {
        ...state,
        working: false,
        error: "There was a problem running the report.",
      };

    default:
      return state;
  }
};

export default holidayCardReportingReducer;
