import paymentActions from "areas/payments/actions/paymentsActions";
import { IBeehiveAction } from "types/common/action.types";
import { ManagedParentDetailView, ManagedStudentDetailView, ManagedUserDetailView } from "types/users/userListViews.types";

export interface PaymentUser extends ManagedUserDetailView {
}

export interface PaymentsParent extends ManagedParentDetailView, PaymentUser {
}

export interface PaymentsStudent extends ManagedStudentDetailView, PaymentUser {
  lunchCreditBalance?: number;
  freeSchoolMeal?: boolean;
}

export interface IPaymentsUserState {
  loading: boolean;
  error: string | null;
  user: PaymentUser | PaymentsParent | PaymentsStudent | null,
  actions: {
    addLunchCredit: { 
      working: boolean;
      error: string | null;
    },
    getBalance: { 
      working: boolean;
      error: string | null;
    },
  },
}

const INITIAL_STATE: IPaymentsUserState = {
  loading: false,
  error: null,
  user: null,
  actions: {
    addLunchCredit: { 
      working: false, 
      error: null 
    },
    getBalance: { 
      working: false, 
      error: null 
    },
  },
};

const paymentsUserReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IPaymentsUserState => {
  
  const {
    PAYMENTS_GETUSER,
    PAYMENTS_ADDMANUALLUNCHCREDIT,
    PAYMENTS_GETUSERLUNCHCREDITBALANCE,
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_GETUSER.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETUSER.SUCCESS:
      return { 
        ...state, 
        user: action.payload, 
        loading: false 
      };

    case PAYMENTS_GETUSER.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PAYMENTS_GETUSERLUNCHCREDITBALANCE.START:
      return {
        ...state,
        actions: {
          ...state.actions,
          getBalance: { 
            working: true, 
            error: null 
          },
        },
      };

    case PAYMENTS_GETUSERLUNCHCREDITBALANCE.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          lunchCreditBalance: action.payload.balance,
          freeSchoolMeal: action.payload.freeSchoolMeal,
        },
        actions: {
          ...state.actions,
          getBalance: { 
            working: false, 
            error: null 
          },
        },
      };

    case PAYMENTS_GETUSERLUNCHCREDITBALANCE.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          getBalance: {
            working: false,
            error: "There was a problem loading the lunch credit balance.",
          },
        },
      };

    case PAYMENTS_ADDMANUALLUNCHCREDIT.START:
      return {
        ...state,
        actions: {
          ...state.actions,
          addLunchCredit: { 
            working: true, 
            error: null 
          },
        },
      };

    case PAYMENTS_ADDMANUALLUNCHCREDIT.SUCCESS:
      return {
        ...state,
        actions: {
          ...state.actions,
          addLunchCredit: {
            working: false, 
            error: null 
          },
        },
      };

    case PAYMENTS_ADDMANUALLUNCHCREDIT.FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          addLunchCredit: {
            working: false,
            error: "There was a problem adjusting the lunch balance.",
          },
        },
      };

    default:
      return state;
  }
};

export default paymentsUserReducer;
