import React, { useState } from "react";
import {
  Size,
  Currency,
  Table,
  Chip,
  DetailLabel,
  Swatches,
  EmptyMessage
} from "ui-kit";
import Moment from "react-moment";
import UserProductFlyout from "./userProductFlyout";
import flyoutActions from "actions/ui/flyouts";
import { Constants } from "configuration";
import { SchoolIcon } from "sharedComponents/common";
import { arrays } from "utils";
import { ProductListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { PaymentUser } from "areas/payments/reducers/payments/paymentsUserReducer";


interface IUserProductsTableProps {
  products: ProductListView[];
  user: PaymentUser;
  loading?: boolean;
  error?: boolean;
}


const UserProductsTable: React.FC<IUserProductsTableProps> = ({ products, user, loading, error }) => {
  
  const [selectedProduct, setSelectedProduct] = useState<ProductListView | null>(null);

  const handleProductClick = (product: ProductListView) => {
    setSelectedProduct(product);
    flyoutActions.openFlyout(Constants.FLYOUTS.USERPRODUCT);
  };

  return (
    <>
      <Table
        zebra
        loading={loading}
        error={error}
        empty={arrays.isEmpty(products)}
        emptyMessage={
          <EmptyMessage
            icon="gift"
            title="No Products"
            summary="No products were found"
            cover
          />
        }
        grow
      >
        <Table.Header>
          <Table.HeaderCell width={0.75} />
          <Table.HeaderCell width={0.25} />
          <Table.HeaderCell width={5}>Product</Table.HeaderCell>
          <Table.HeaderCell width={0.5} right>
            Price
          </Table.HeaderCell>
          <Table.HeaderCell width={1} right>
            Expiry Date
          </Table.HeaderCell>
          <Table.HeaderCell width={1} right>
            Stock
          </Table.HeaderCell>
          <Table.HeaderCell width={0.5} right>
            Sold
          </Table.HeaderCell>
          <Table.HeaderCell width={0.75} right>
            Remaining
          </Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {products.map((product: ProductListView, index: number) => (
            <Table.Row key={index} onClick={() => handleProductClick(product)}>
              <Table.Cell width={0.75} chip>
                {product.isVisibleInStore ? (
                  <Chip colorSwatch={Swatches.Success} text="On Sale" />
                ) : (
                  <Chip colorSwatch={Swatches.Low} text="Inactive" />
                )}
              </Table.Cell>
              <Table.Cell width={0.25}>
                <SchoolIcon
                  code={product?.category?.school.code}
                  size={Size.Small}
                />
              </Table.Cell>
              <Table.Cell width={5}>
                <DetailLabel
                  label={product.name}
                  sub={product.category.name}
                  bold
                />
              </Table.Cell>
              <Table.Cell right width={0.5}>
                <Currency value={product.unitPrice} />
              </Table.Cell>
              <Table.Cell right width={1}>
                {product.expiryDate && (
                  <Moment date={product.expiryDate} format="DD/MM/YYYY" />
                )}
              </Table.Cell>
              <Table.Cell right width={1}>
                {product.enforceStockControl ? product.stock : "Unlimited"}
              </Table.Cell>
              <Table.Cell right width={0.5}>
                {product.sold}
              </Table.Cell>
              <Table.Cell right width={0.75}>
                {product.enforceStockControl ? (
                  product.soldOut ? (
                    <Chip text="Sold Out" colorSwatch={Swatches.Danger} />
                  ) : (
                    product.stockRemaining
                  )
                ) : (
                  "N/A"
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <UserProductFlyout user={user} selectedProduct={selectedProduct} />
    </>
  );
};

export default UserProductsTable;
