import React, { useEffect, useState, useRef } from "react";
import LeavingLessonAnalyticsForm from "./leavingLessonAnalyticsForm";
import LeavingLessonTableWrapper from "./leavingLessonTableWrapper";
import { Title, TitleSize } from "ui-kit";
import ReportDescription from "../reportDescription";
import schoolsActions from "areas/planner/actions/schools/schoolsActions";
import leavingLessionReportActions from "areas/analytics/actions/leavingLessonReportActions";
import { useAppSelector } from "reducers/hooks";
import moment from "moment";
import { GenerateLessonExitReportCommand, ReportFilterBy } from "areas/analytics/types/lessonExitAnalyticRequest.types";
import { LessonExitGroupBy } from "areas/analytics/types/lessonExitAnalyticShared.types";


const LeavingLessonAnalyticsWrapper: React.FC = () => {

  const reportRef = useRef<HTMLDivElement>();
  const { schoolId } = useAppSelector(state => state.schools);
  const { user } = useAppSelector(state => state.currentUser);
  const { report, loading, loadingExportReport } = useAppSelector(state => state.leavingLessonAnalytics);
  const [reportParameters, setReportParameters] = useState<GenerateLessonExitReportCommand | null>(null);

  useEffect(() => {
    schoolsActions.selectSchool(user.baseSchool.id);
  }, []);

  useEffect(() => {
    if (loading || report) {
      reportRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading, report]);

  const onSelectSchool = (value: number) => {
    schoolsActions.selectSchool(value);
  };

  const handleGenerateReport = (
    startDate: Date,
    endDate: Date,
    groupBy1: LessonExitGroupBy,
    groupBy2: LessonExitGroupBy | null,
    academicYearId: number,
    filters: ReportFilterBy[],
    exportReport?: boolean
  ) => {
    
    const payload: GenerateLessonExitReportCommand = {
      schoolId: schoolId,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      groupBy1: groupBy1,
      groupBy2: groupBy2,
      academicYearId: academicYearId,
      reportFilters: filters
    };

    if (exportReport) {
      leavingLessionReportActions.exportLeavingLessonReport(payload);
    } else {
      setReportParameters(payload);
      leavingLessionReportActions.getLeavingLessonReport(payload);
    }
  };

  return (
    <>
      <Title text="Leaving Lesson Report" size={TitleSize.H3} />
      <ReportDescription text="Use this report to view leaving lesson records between a selected date range." />
      <LeavingLessonAnalyticsForm
        onSelectSchool={onSelectSchool}
        handleGenerateReport={handleGenerateReport}
        loadingExportReport={loadingExportReport}
      />
      <br />
      <div ref={reportRef}>
        <LeavingLessonTableWrapper reportParameters={reportParameters} />
      </div>
    </>
  );
};

export default LeavingLessonAnalyticsWrapper;
