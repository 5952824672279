import React from "react";
import { DetailLabel, EmptyMessage, Loader, Size, Table } from "ui-kit";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import { useAppSelector } from "reducers/hooks";


const YearGroupDetentionReportTable = () => {
  
  const { report, loading, error } = useAppSelector(
    state => state.yearGroupDetentionReport
  );

  if (loading) {
    return <Loader size={Size.Large} fluid />;
  }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return !report ? null : report.detentions?.length > 0 ? (
    <Table>
      <Table.Header>
        <Table.Cell>
          <b>Total Number of Detentions</b>
        </Table.Cell>
        <Table.Cell colspan={3} right>
          <b>{report.totalDetentions}</b>
        </Table.Cell>
      </Table.Header>

      <Table.Header>
        <Table.HeaderCell>Student</Table.HeaderCell>
        <Table.HeaderCell right>Detention Status</Table.HeaderCell>
        <Table.HeaderCell right>Number of Detentions</Table.HeaderCell>
        <Table.HeaderCell right>Behaviour Code</Table.HeaderCell>
      </Table.Header>

      <Table.Body>
        {report.detentions.map(detention => (
          <Table.Row>
            <Table.Cell>
              <DetailLabel
                label={`${detention.student.firstName} ${detention.student.lastName}`}
                sub={detention.student.simsId}
                bold
              />
            </Table.Cell>
            <Table.Cell right>{detention.detentionStatus}</Table.Cell>
            <Table.Cell right>{detention.detentionCount}</Table.Cell>
            <Table.Cell right>{detention.behaviourCode}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <EmptyMessage title="No Detentions Found" icon="list" />
  );
};

export default YearGroupDetentionReportTable;
