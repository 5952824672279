import React from "react";
import PropTypes from "prop-types";
import { formatTime, formatDate } from "utils/dateTime";
import { Table, Size, DetailLabel, SplitButton, Swatches } from "ui-kit";
import Avatar from "sharedComponents/common/users/avatar";
import { getBehaviourGivenGroup } from "areas/behaviour/utils/detentions";
import { BehaviourSummaryListView } from "areas/behaviour/types/behaviourResponses.types";


interface IPendingAttentionTableRowProps {
  behaviour: BehaviourSummaryListView;
  onClickBehaviour: (behaviour: BehaviourSummaryListView) => void;
  onClaim: (behaviour: BehaviourSummaryListView) => void;
  handleManageBehaviour: (behaviour: BehaviourSummaryListView) => void;
  handleRevokeBehaviour: (behaviour: BehaviourSummaryListView) => void;
}


const PendingAttentionTableRow: React.FC<IPendingAttentionTableRowProps> = ({
  behaviour,
  onClickBehaviour,
  onClaim,
  handleManageBehaviour,
  handleRevokeBehaviour,
}) => {

  const student = behaviour.student;

  return (
    <Table.Row onClick={() => onClickBehaviour(behaviour)}>
      <Table.Cell width={1}>
        <Avatar user={student} sub={student.simsId} />
      </Table.Cell>
      <Table.Cell width={0.5}>
        {behaviour.studentTutorGroup &&
          behaviour.studentTutorGroup.friendlyName}
      </Table.Cell>
      <Table.Cell width={1.4}>
        <DetailLabel
          bold
          label={behaviour.behaviourCodeName}
          sub={behaviour.categoryName}
        />
      </Table.Cell>
      <Table.Cell width={1}>
        <DetailLabel
          bold
          label={behaviour.awardedBy}
          sub={`${formatDate(behaviour.awardedDate)} ${formatTime(
            behaviour.awardedDate
          )}`}
        />
      </Table.Cell>
      <Table.Cell width={1}>{getBehaviourGivenGroup(behaviour)}</Table.Cell>
      <Table.Cell width={1}>
        {behaviour.claimedBy ? (
          <DetailLabel
            bold
            label={behaviour.claimedBy}
            sub={`${formatDate(behaviour.claimedDate)} ${formatTime(
              behaviour.claimedDate
            )}`}
          />
        ) : (
          ""
        )}
      </Table.Cell>

      <Table.Cell width={1} right>
        <SplitButton size={Size.Small} text="Actions" color={Swatches.Low}>
          <SplitButton.Option
            text="Manage"
            onClick={() => {
              handleManageBehaviour(behaviour);
            }}
            show={behaviour.claimedBy !== null}
          />
          <SplitButton.Option
            text="Revoke"
            onClick={() => {
              handleRevokeBehaviour(behaviour);
            }}
            show={behaviour.claimedBy !== null}
          />
          <SplitButton.Option
            text="Claim"
            onClick={() => {
              onClaim(behaviour);
            }}
            show={behaviour.claimedBy === null}
          />
        </SplitButton>
      </Table.Cell>
    </Table.Row>
  );
};


export default PendingAttentionTableRow;
