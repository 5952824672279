import { ProvisionStatus, ProvisionType } from "areas/send/types/passportShared.types";
import React, { useState, useEffect } from "react";
import { Dropdown } from "ui-kit";


interface IProvisionStatusDropdownProps {
  value?: ProvisionStatus;
  onChange?: (value: number) => void;
  fluid?: boolean;
  placeholder?: string;
  includeAllOption?: boolean;
}

interface provisionStatusOption {
  label: string;
  value: ProvisionStatus;
}


const ALL_OPTION = {
  key: -1,
  label: "All Provision Status",
  value: -1,
  sub: "ALL",
};

const OPTIONS: provisionStatusOption[] = [
  { label: "Live", value: ProvisionStatus.Live },
  { label: "Archived", value: ProvisionStatus.Archived },
];


const ProvisionStatusDropdown: React.FC<IProvisionStatusDropdownProps> = ({
  value,
  onChange,
  fluid,
  placeholder,
  includeAllOption,
}) => {

  const [_options, _setOptions] = useState<provisionStatusOption[]>(OPTIONS);

  useEffect(() => {
    includeAllOption && _setOptions([ALL_OPTION, ...OPTIONS]);
  }, [includeAllOption]);

  return (
    <Dropdown
      placeholder={
        placeholder ? placeholder : "Please choose a provision type..."
      }
      fluid={fluid}
      value={value}
      onChange={onChange}
    >
      {_options.map((opt: provisionStatusOption, index: number) => (
        <Dropdown.Item key={index} label={opt.label} value={opt.value} />
      ))}
    </Dropdown>
  );
};

export default ProvisionStatusDropdown;
