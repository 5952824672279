import React, { useState, useEffect } from "react";
import ManagedMovesTable from "./managedMovesTable";
import ManagedMovesFilter from "./managedMovesFilter";
import managedMovesActions from "../../actions/managedMoves/managedMovesActions";
import { useAppSelector } from "reducers/hooks";
import { ManagedMoveQueryFilter } from "areas/planner/types/managedMoves/managedMovesRequest.types";


const ManagedMoves : React.FC = () => {
  
  const { moves, loading, error } = useAppSelector(state => state.managedMoves);
  const { user } = useAppSelector(state => state.currentUser);

  const handleFilterChange = (filter: ManagedMoveQueryFilter) => {
    managedMovesActions.getManagedMoves(filter);
  };

  return (
    <>
      <ManagedMovesFilter
        onChange={handleFilterChange}
        userBaseSchool={user.baseSchool?.id}
      />
      <ManagedMovesTable
        moves={moves}
        loading={loading}
        error={error}
        hideFooter
        showStudent={true}
      />
    </>
  );
};

export default ManagedMoves;
