import { CalendarEventMenuItemListView, GroupedMenuView } from "areas/calendar/types/calendarResponses.types";
import React from "react";
import styled from "styled-components";
import {
  Size,
  Icon,
  Table,
  DetailLabel,
  Button,
  Swatches,
  SplitButton,
  EmptyMessage
} from "ui-kit";
import { arrays } from "utils";
import ItemDietaryIcons from "./itemDietaryIcons";


interface IMenuListProps {
  menu: GroupedMenuView[];
  editable?: boolean;
  onCreate?: () => void;
  onEdit?: (menuItem: CalendarEventMenuItemListView, index: number) => void;
  onDelete?: (menuItem: CalendarEventMenuItemListView, index: number) => void;
}


const MenuList: React.FC<IMenuListProps> = ({ menu, editable, onCreate, onEdit, onDelete }) => {

  const handleCreate = () => {
    onCreate?.();
  }

  const handleEdit = (menuItem: CalendarEventMenuItemListView, index: number) => {
    onEdit?.(menuItem, index);
  }
  
  const handleDelete = (menuItem: CalendarEventMenuItemListView, index: number) => {
    onDelete?.(menuItem, index);
  }

  if (arrays.isEmpty(menu)) {

    return (
      <EmptyMessage
        title="No Menu Items Added"
        icon="calendar"
        summary="No menu items have been added for this event yet"
      >
        { editable && (
          <Button
            size={Size.Small}
            onClick={handleCreate}
            color={Swatches.Primary}
            text="Add New Menu Item"
          />
        )}
      </EmptyMessage>
    )
  }

  return (
    <Table zebra>
        <Table.Header>
          <Table.HeaderCell width={1}>Dish/Course</Table.HeaderCell>
          <Table.HeaderCell width={2}></Table.HeaderCell>
          <Table.HeaderCell width={1}>Code</Table.HeaderCell>
          <Table.HeaderCell width={6} right></Table.HeaderCell>
        </Table.Header>
        <Table.Body>
        { menu && menu.flatMap(course => course.menu).map((menuItem: CalendarEventMenuItemListView, index: number) => (
          <Table.Row key={index}>
            <Table.Cell width={1}>
                <DetailLabel label={menuItem.name} sub={menuItem.typeName} bold />
            </Table.Cell>
            <Table.Cell width={2}>
              <ItemDietaryIcons menuItem={menuItem} />
            </Table.Cell>
            <Table.Cell width={1}>
                {menuItem.code}
            </Table.Cell>
            <Table.Cell width={6} right>
                { editable && (
                  <SplitButton
                    size={Size.Small}
                    text="Edit"
                    color={Swatches.Default}
                    onDefaultClick={() => handleEdit(menuItem, index)}
                  >
                    <SplitButton.Option
                      onClick={() => handleEdit(menuItem, index)}
                      text="Edit"
                      show={true}
                    />
                    <SplitButton.Option
                      onClick={() => handleDelete(menuItem, index)}
                      text="Delete"
                      color={Swatches.Danger}
                      show={true}
                    />
                  </SplitButton>
                )}
            </Table.Cell>
          </Table.Row>
        ))}
        </Table.Body>
        { editable && (
          <Table.Footer>
            <Table.Row>
              <Table.Cell colspan={3} />
              <Table.Cell right>
                <Button
                  size={Size.Small}
                  onClick={handleCreate}
                  color={Swatches.Primary}
                  text="Add New Menu Item"
                />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        )}
    </Table>
  )
}

export default MenuList;