import React from "react";
import PropTypes from "prop-types";
import PendingAttentionTableRow from "./pendingAttentionTableRow";
import { Table } from "ui-kit";
import { BehaviourSummaryListView } from "areas/behaviour/types/behaviourResponses.types";


interface IPendingAttentionTableProps {
  pendingAttentionBehaviours: BehaviourSummaryListView[];
  onClickBehaviour: (behaviour: BehaviourSummaryListView) => void;
  onClaim: (behaviour: BehaviourSummaryListView) => void;
  handleManageBehaviour: (behaviour: BehaviourSummaryListView) => void;
  handleRevokeBehaviour: (behaviour: BehaviourSummaryListView) => void;
}


const PendingAttentionTable: React.FC<IPendingAttentionTableProps> = ({
  pendingAttentionBehaviours,
  onClickBehaviour,
  onClaim,
  handleManageBehaviour,
  handleRevokeBehaviour,
}) => {

  return (
    <Table grow>
      <Table.Header>
        <Table.HeaderCell width={1}>Student</Table.HeaderCell>
        <Table.HeaderCell width={0.5}>Tutor Group</Table.HeaderCell>
        <Table.HeaderCell width={1.4}>Behaviour</Table.HeaderCell>
        <Table.HeaderCell width={1}>Created By</Table.HeaderCell>
        <Table.HeaderCell width={1}>Class/Location</Table.HeaderCell>
        <Table.HeaderCell width={1}>Claimed By</Table.HeaderCell>
        <Table.HeaderCell width={1}></Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {pendingAttentionBehaviours.map(behaviour => (
          <PendingAttentionTableRow
            key={behaviour.behaviourId}
            behaviour={behaviour}
            onClickBehaviour={onClickBehaviour}
            onClaim={onClaim}
            handleManageBehaviour={handleManageBehaviour}
            handleRevokeBehaviour={handleRevokeBehaviour}
          />
        ))}
      </Table.Body>
    </Table>
  );
};


export default PendingAttentionTable;
