import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import store from "reducers/store";
import { IBeehiveAction, callbackType } from "types/common/action.types";
import { PagedQueryView } from "types/common/views.types";
import { FeedItemListView } from "../types/feedResponses.types";
import { FileListView } from "types/common/files.types";

const builder = new actionTypeBuilder("feed");

const types = {
  GALLERY_OPEN: "gallery_open",
  GALLERY_CLOSE: "gallery_close",
  GETFEED: builder.build("getfeed"),
  GETFAVOURITES: builder.build("getfavourites"),

  FEED_ARTICLE_FAVOURITE: builder.build("feed_article_favourite"),
  FEED_ARTICLE_UNFAVOURITE: builder.build("feed_article_unfavourite"),
  FEED_ARTICLE_LIKE: builder.build("feed_article_like"),
  FEED_ARTICLE_UNLIKE: builder.build("feed_article_unlike"),
  FEED_ARTICLE_DISMISS: builder.build("feed_article_dismiss"),
  FEED_ARTICLE_UNDISMISS: builder.build("feed_article_undismiss"),

  FEED_CALENDAREVENT_FAVOURITE: builder.build("feed_calendarevent_favourite"),
  FEED_CALENDAREVENT_UNFAVOURITE: builder.build("feed_calendarevent_unfavourite"),
  FEED_CALENDAREVENT_LIKE: builder.build("feed_calendarevent_like"),
  FEED_CALENDAREVENT_UNLIKE: builder.build("feed_calendarevent_unlike"),
  FEED_CALENDAREVENT_DISMISS: builder.build("feed_calendarevent_dismiss"),
  FEED_CALENDAREVENT_UNDISMISS: builder.build("feed_calendarevent_undismiss"),

  FEED_ASSIGNMENT_FAVOURITE: builder.build("feed_assignment_favourite"),
  FEED_ASSIGNMENT_UNFAVOURITE: builder.build("feed_assignment_unfavourite"),
  FEED_ASSIGNMENT_LIKE: builder.build("feed_assignment_like"),
  FEED_ASSIGNMENT_UNLIKE: builder.build("feed_assignment_unlike"),
  FEED_ASSIGNMENT_DISMISS: builder.build("feed_assignment_dismiss"),
  FEED_ASSIGNMENT_UNDISMISS: builder.build("feed_assignment_undismiss"),
};

//In the old UI we would resolve the axios promise with the provided ID parameter
//Now API has been changed as all resolves come from API payload

const getFeed = (userId: string, pageIndex: number, callback?: callbackType<PagedQueryView<FeedItemListView>>) =>
  action<PagedQueryView<FeedItemListView>>(
    () => client.get(`feed/users/${userId}?feedItemType=1&pageIndex=${pageIndex}&pageSize=5`),
    types.GETFEED,
    callback
  );

const getFavourites = (userId: string, pageIndex: number, callback?: callbackType<PagedQueryView<FeedItemListView>>) =>
  action<PagedQueryView<FeedItemListView>>(
    () => client.get(`feed/users/${userId}/favourites?feedItemType=1&pageIndex=${pageIndex}`),
    types.GETFAVOURITES,
    callback
  );

const favouriteFeedArticle = (userId: string, articleId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`news/users/${userId}/articles/${articleId}/favourite`),
    types.FEED_ARTICLE_FAVOURITE,
    callback
  );

const unfavouriteFeedArticle = (userId: string, articleId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`news/users/${userId}/articles/${articleId}/unfavourite`),
    types.FEED_ARTICLE_UNFAVOURITE,
    callback
  );

const favouriteFeedCalendarEvent = (userId: string, calendarEventId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`planner/users/${userId}/calendarEvents/${calendarEventId}/favourite`),
    types.FEED_CALENDAREVENT_FAVOURITE,
    callback
  );

const unfavouriteFeedCalendarEvent = (userId: string, calendarEventId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`planner/users/${userId}/calendarEvents/${calendarEventId}/unfavourite`),
    types.FEED_CALENDAREVENT_UNFAVOURITE,
    callback
  );

const favouriteFeedAssignment = (userId: string, assignmentId: number, callback?: callbackType<number>) =>
  action<number>(
    () =>
      client.post(`planner/users/${userId}/assignments/${assignmentId}/favourite`),
    types.FEED_ASSIGNMENT_FAVOURITE,
    callback
  );

const unfavouriteFeedAssignment = (userId: string, assignmentId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`planner/users/${userId}/assignments/${assignmentId}/unfavourite`),
    types.FEED_ASSIGNMENT_UNFAVOURITE,
    callback
  );


// LIKE METHODS
const likeFeedArticle = (userId: string, articleId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`news/users/${userId}/articles/${articleId}/like`),
    types.FEED_ARTICLE_LIKE,
    callback
  );

const unlikeFeedArticle = (userId: string, articleId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`news/users/${userId}/articles/${articleId}/unlike`),
    types.FEED_ARTICLE_UNLIKE,
    callback
  );

const likeFeedCalendarEvent = (userId: string, calendarEventId: number, callback?: callbackType<number>) =>
  action<number>(
    () =>
      client.post(`planner/users/${userId}/calendarEvents/${calendarEventId}/like`),
    types.FEED_CALENDAREVENT_LIKE,
    callback
  );

const unlikeFeedCalendarEvent = (userId: string, calendarEventId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`planner/users/${userId}/calendarEvents/${calendarEventId}/unlike`),
    types.FEED_CALENDAREVENT_UNLIKE,
    callback
  );

const likeFeedAssignment = (userId: string, assignmentId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`planner/users/${userId}/assignments/${assignmentId}/like`),
    types.FEED_ASSIGNMENT_LIKE,
    callback
  );

const unlikeFeedAssignment = (userId: string, assignmentId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`planner/users/${userId}/assignments/${assignmentId}/unlike`),
    types.FEED_ASSIGNMENT_UNLIKE,
    callback
  );


// DISMISS METHODS
const dismissFeedArticle = (userId: string, articleId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`news/users/${userId}/articles/${articleId}/dismiss`),
    types.FEED_ARTICLE_DISMISS,
    callback
  );

const undismissFeedArticle = (userId: string, articleId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`news/users/${userId}/articles/${articleId}/undismiss`),
    types.FEED_ARTICLE_UNDISMISS,
    callback
  );

const dismissFeedCalendarEvent = (userId: string, calendarEventId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`planner/users/${userId}/calendarEvents/${calendarEventId}/dismiss`),
    types.FEED_CALENDAREVENT_DISMISS,
    callback
  );

const undismissFeedCalendarEvent = (userId: string, calendarEventId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`planner/users/${userId}/calendarEvents/${calendarEventId}/undismiss`),
    types.FEED_CALENDAREVENT_UNDISMISS,
    callback
  );

const dismissFeedAssignment = (userId: string, assignmentId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`planner/users/${userId}/assignments/${assignmentId}/dismiss`),
    types.FEED_ASSIGNMENT_DISMISS,
    callback
  );

const undismissFeedAssignment = (userId: string, assignmentId: number, callback?: callbackType<number>) =>
  action<number>(
    () => client.post(`planner/users/${userId}/assignments/${assignmentId}/undismiss`),
    types.FEED_ASSIGNMENT_UNDISMISS,
    callback
  );


const openGallery = (images: FileListView[], selectedIndex: number) => {
  store.dispatch<IBeehiveAction<{images: FileListView[], selectedIndex: number}>>({
    type: types.GALLERY_OPEN,
    payload: {
      images,
      selectedIndex,
    },
  });
};

const closeGallery = () => {
  store.dispatch({
    type: types.GALLERY_CLOSE,
  });
};


const feedActions = {
  types,
  getFeed,
  getFavourites,
  favouriteFeedArticle,
  unfavouriteFeedArticle,
  favouriteFeedCalendarEvent,
  unfavouriteFeedCalendarEvent,
  favouriteFeedAssignment,
  unfavouriteFeedAssignment,
  likeFeedArticle,
  unlikeFeedArticle,
  likeFeedCalendarEvent,
  unlikeFeedCalendarEvent,
  likeFeedAssignment,
  unlikeFeedAssignment,
  dismissFeedArticle,
  undismissFeedArticle,
  dismissFeedCalendarEvent,
  undismissFeedCalendarEvent,
  dismissFeedAssignment,
  undismissFeedAssignment,
  openGallery,
  closeGallery,
};

export default feedActions;
