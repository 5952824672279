import React, { useState } from "react";
import {
  XYPlot,
  XAxis,
  HorizontalGridLines,
  YAxis,
  VerticalBarSeries,
  HorizontalBarSeries,
  Hint
} from "react-vis";
import {
  Title,
  TitleSize,
  Spacing,
  neutral,
  typescale,
  displayFont,
  fontStyle,
  BorderRadius
} from "ui-kit";
import styled from "styled-components";


const Wrapper = styled.div`
  padding: ${Spacing.ExtraLarge}px 0;

  .rv-xy-plot .rv-xy-plot__inner .rv-xy-plot__axis.rv-xy-plot__axis--vertical {
    g {
      text {
        transform: translate(-5px, 0);
      }
    }
  }
`;

const ToolTipWrapper = styled.div`
  background-color: ${neutral[700]};
  padding: ${Spacing.Small + 4}px ${Spacing.Large}px;
  border-radius: ${BorderRadius.Default}px;

  .category {
    ${fontStyle(displayFont.medium, typescale.header4, neutral[100])}
  }

  .points {
    ${fontStyle(displayFont.roman, typescale.paragraph, neutral[400])}
  }
`;


export interface BarChartPoint {
  x: string | number;
  y: number;
  color?: string | number | undefined;
  opacity?: string | number | undefined;
  stroke?: string | number | undefined;
  fill?: string | number | undefined;
}

interface IBarChartProps {
  chartTitle: string,
  data: BarChartPoint[],
  colour?: string,
  horizontal?: boolean
}


const BarChart: React.FC<IBarChartProps> = ({ chartTitle, data, colour, horizontal }) => {

  const [value, setValue] = useState(null);

  const tickFormatFunc = (value: number) => {
    return value % 1 !== 0 ? null : Math.round(value).toString();
  };

  return (
    <Wrapper className="bar-chart">
      <Title text={chartTitle} size={TitleSize.H3} />
      { horizontal ? (
        <XYPlot
          height={500}
          width={800}
          xType="linear"
          yType="ordinal"
          style={{ font: "600 13px neue-haas-grotesk-display" }}
        >
          <HorizontalGridLines />

          <XAxis tickFormat={tickFormatFunc} />

          <YAxis />

          {/* <LabelSeries animation allowOffsetToBeReversed data={data} /> */}

          <HorizontalBarSeries
            data={data}
            color={colour}
            onValueMouseOver={datapoint => setValue(datapoint)}
            onValueMouseOut={datapoint => setValue(null)}
            barWidth={2}
          />
          {value && (
            <Hint value={value}>
              <ToolTipWrapper>
                <div className="category">{value.label}</div>
                <div className="points">{`${value.x} Points`}</div>
              </ToolTipWrapper>
            </Hint>
          )}
        </XYPlot>
      ) : (
        <XYPlot
          height={400}
          width={600}
          xType="ordinal"
          yType="linear"
          style={{ font: "600 16px neue-haas-grotesk-display" }}
        >
          <HorizontalGridLines />

          <XAxis />

          <YAxis tickFormat={tickFormatFunc} />

          <VerticalBarSeries
            data={data} 
            color={colour} 
            barWidth={2}
          />
        </XYPlot>
      )}
      {/* <div>{dataPoint && dataPoint.label}</div> */}
    </Wrapper>
  );
};

export default BarChart;
