import React, { useEffect, useState } from "react";
import {
  Modal,
  ActionBar,
  Button,
  Size,
  Swatches,
  Card,
  StructuredList,
  Left,
  Right,
  ValidationMessage,
  DatePicker,
  TimePicker,
  Dropdown
} from "ui-kit";
import moment from "moment";
import { arrays } from "utils";
import { CalendarEventDateListView } from "areas/calendar/types/calendarResponses.types";
import { PublishStatus } from "types/common/status.types";


interface IRepeatingDateModalProps {
  open: boolean;
  onClose?: () => void;
  onSave?: (eventDates: CalendarEventDateListView[]) => void
}

interface EventDateRange {
  startDate: Date;
  startTime: string;
  endTime: string;
  endDate: Date;
}


const RepeatingDateModal: React.FC<IRepeatingDateModalProps> = ({ open, onClose, onSave }) => {

  const [_open, _setOpen] = useState<boolean>(open);
  const [validationErrors, setValidationErrors] = useState([]);
  const [dateParams, setDateParams] = useState<EventDateRange>({ startDate: null, startTime: "12:00", endTime: "12:00", endDate: null });

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const handleClose = () => {
    _setOpen(false);
    setValidationErrors([]);
    onClose?.();
  }

  const handleSave = () => {
    setValidationErrors([]);
    var errors = [];

    if (dateParams.startDate == null) {
      errors.push("Please select the date of the first occurrence of the event");
    }
    if (dateParams.startTime == null) {
      errors.push("Please select a time of day for the event start");
    }
    if (dateParams.endTime == null) {
      errors.push("Please select a time of day for the event end");
    }
    if (dateParams.endDate == null) {
      errors.push("Please select the date of the last occurrence of the event");
    }
    if (dateParams.endDate != null && dateParams.endDate < new Date()) {
      errors.push("Please enter a date in the future for the last event date");
    }
    if (dateParams.endDate < dateParams.startDate) {
      errors.push("The end date cannot be before the start date");
    }
    if (dateParams.endTime < dateParams.startTime) {
      errors.push("The end time cannot be before the start time");
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    var dates: CalendarEventDateListView[] = [];

    //console.log(dateParams);
    var startTime = dateParams.startTime.split(":");
    var endTime = dateParams.endTime.split(":");
    
    for (var d = moment(dateParams.startDate); d <= moment(dateParams.endDate); d = d.add(7, 'days')) {
      dates.push({
        starts: d.hour(parseInt(startTime[0])).minutes(parseInt(startTime[1])).toDate(),
        ends: d.hour(parseInt(endTime[0])).minutes(parseInt(endTime[1])).toDate(),
        id: null,
        status: PublishStatus.Draft,
        signedUp: false,
        requireSignup: false
      });
    }
    //console.log(dates);

    onSave?.(dates);
  };


  return (
    <Modal
      title="Edit Date"
      open={_open}
      onClose={handleClose}
      width="80%"
      height="75%"
    >
      <Modal.Body>
        <ValidationMessage errors={validationErrors} />
        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item
                name="From"
                description="The date of the first occurrence of this event"
                required
              >
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  closeOnSelect
                  placeholder="Pick a start date..."
                  onChange={date => setDateParams({...dateParams, startDate: date})}
                />
              </StructuredList.Item>
              <StructuredList.Item
                name="At"
                description="What time the event is held at"
                required
              >
                <TimePicker
                  initialValue={dateParams.startTime}
                  onChange={time => setDateParams({...dateParams, startTime: time})}
                />
              </StructuredList.Item>
              <StructuredList.Item
                name="To"
                description="What time the event finishes"
                required
              >
                <TimePicker
                  initialValue={dateParams.endTime}
                  onChange={time => setDateParams({...dateParams, endTime: time})}
                />
              </StructuredList.Item>
              <StructuredList.Item
                name="Until"
                description="The date of the last occurrence of this event"
                required
              >
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  closeOnSelect
                  placeholder="Pick an end date..."
                  onChange={date => setDateParams({...dateParams, endDate: date})}
                />
              </StructuredList.Item>
            </StructuredList>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              text="Close"
              onClick={onClose}
              color={Swatches.Low}
              size={Size.Small}
            />
            <Button
              text="Save"
              onClick={handleSave}
              color={Swatches.Success}
              size={Size.Small}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};


export default RepeatingDateModal;