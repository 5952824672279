import React from "react";


const Error = () => {
  
  return (
    <>
      <p>
        Unfortunately, there was a problem with loading the registration form.
        This may be because your registration link is invalid or has expired.
      </p>
      <p>
        Please check the link supplied in your registration email, or
        alternatively contact your child's school for further assistance.
      </p>
    </>
  );
};

export default Error;
