import { IBeehiveAction } from "types/common/action.types";
import newsActions from "../../actions/newsActions";
import { ArticleDetailView } from "areas/news/types/newsResponse.types";

interface IArticleState {
  article: ArticleDetailView | null;
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  error: string | null;
  saveError: string | null;
  newId: number | null;
}

const INITIAL_STATE: IArticleState = {
  article: null,
  loading: false,
  saving: false,
  deleting: false,
  error: null,
  saveError: null,
  newId: null
};

const articleReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IArticleState => {
  
  const { GET_ARTICLE, CREATE_ARTICLE, SAVE_ARTICLE, DELETE_ARTICLE } = newsActions.types;

  switch (action.type) {
    case GET_ARTICLE.START:
      return { ...INITIAL_STATE, loading: true };
    case GET_ARTICLE.SUCCESS:
      return { ...INITIAL_STATE, article: action.payload };
    case GET_ARTICLE.FAILED:
      return { ...INITIAL_STATE, error: action.payload };

    case CREATE_ARTICLE.START:
      return { ...INITIAL_STATE, saving: true };
    case CREATE_ARTICLE.SUCCESS:
      return { ...INITIAL_STATE, newId: action.payload };
    case CREATE_ARTICLE.FAILED:
      return {
        ...INITIAL_STATE,
        saving: false,
        saveError: "There was a problem creating the article",
      };

    case SAVE_ARTICLE.START:
      return { ...state, saveError: null, saving: true };
    case SAVE_ARTICLE.SUCCESS:
      return { ...state, saving: false };
    case SAVE_ARTICLE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the article",
      };

    case DELETE_ARTICLE.START:
      return { ...state, saveError: null, deleting: true };
    case DELETE_ARTICLE.SUCCESS:
      return { ...state, deleting: false };
    case DELETE_ARTICLE.FAILED:
      return {
        ...state,
        deleting: false,
        saveError: "There was a problem deleting the article",
      };

    default:
      return state;
  }
};

export default articleReducer;
