import React from "react";
import { Flyout, Button, Swatches } from "ui-kit";
import { Constants } from "configuration";
import { useNavigate } from "react-router-dom";
import Basket from "./basket";
import flyoutActions from "actions/ui/flyouts";
import { StripeBlackLogo } from "./checkout/stripeLogo";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import { RootState } from "reducers/store";


const BasketFlyout = () => {

  var { basket, working, redirecting } = useSelector((state: RootState) => state.basket);
  const navigate = useNavigate();

  const goToCheckout = () => {
    navigate("/main/checkout");
    flyoutActions.closeFlyout();
  };

  return (
    <Flyout title="Basket" name={Constants.FLYOUTS.BASKET}>
      <Flyout.Body>
        <Basket />
      </Flyout.Body>
      <Flyout.Footer>
        {!working && !redirecting && !arrays.isEmpty(basket?.items) && (
          <>
            <Button
              icon="lock-alt"
              text="Go to Secure Checkout"
              onClick={() => goToCheckout()}
              color={Swatches.Success}
              fluid
              tooltip="Click to go to the secure checkout"
            />
            <StripeBlackLogo height={30} style={{ marginTop: 24 }} />
          </>
        )}
      </Flyout.Footer>
    </Flyout>
  );
};

export default BasketFlyout;
