import React from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "ui-kit";
import searchEngineActions from "areas/search/actions/searchEngineActions";
import { RootState } from "reducers/store";

interface IStudentSearchProps {
  onChange?: (value: any, label: any) => void;
  fluid?: boolean;
  schools?: number[];
}

const StudentSearch: React.FC<IStudentSearchProps> = ({
  onChange,
  fluid,
  schools,
}) => {
  // @ts-ignore TODO: Remove this when search reducer is added
  const { students, loading, error } = useSelector((state: RootState) => state.searchStudents);

  const handleStudentSearch = (value: any) => {
    if (value && value.length > 2) {
      searchEngineActions.searchStudents(value, { schools: schools });
    }
  };

  return (
    <>
      <Dropdown
        className="student-search"
        items={students?.map((student: any, index: number) => ({
          key: index,
          label: `${student.data.firstName} ${student.data.lastName}`,
          value: student.data,
          sub: student.description,
        }))}
        autocomplete
        placeholder="Search students..."
        searchPlaceholder="Search students..."
        onSearchChange={handleStudentSearch}
        onChange={onChange}
        loading={loading}
        error={error && "An error occurred"}
        fluid={fluid}
      />
    </>
  );
};

export default StudentSearch;
