import React, { useState, useEffect } from "react";
import {
  Modal,
  StructuredList,
  ActionBar,
  Button,
  Swatches,
  Message,
  HtmlText,
  ToastService,
  Label,
  Card,
  Loader,
  Size,
  ValidationMessage,
  DateTime,
  RichTextEditor,
} from "ui-kit";
import { arrays } from "utils";
import { Constants } from "configuration";
import { useSelector } from "react-redux";
import {
  UserView,
  StatusView,
  Avatar,
  ApprovalStatusChip,
  StaffSearch,
  OvertimeStatusChip,
} from "sharedComponents/common";
import leaveActions from "areas/humanResources/actions/leaveActions";
import { OvertimeListView, HolidayCardView, OvertimeDetailView } from "areas/humanResources/types/leaveResponse.types";
import { RootState } from "reducers/store";
import { ApproveOvertimeCommand, CancelOvertimeCommand, RejectOvertimeCommand } from "areas/humanResources/types/leaveRequest.types";
import { OvertimeStatus } from "areas/humanResources/types/leaveShared.types";


interface IViewAdditionalHoursModalProps {
  overtime: OvertimeListView;
  holidayCard: HolidayCardView;
  onOvertimeUpdated?: (overtime: OvertimeDetailView) => void;
  onClose?: () => void;
  open?: boolean;
}


const ViewAdditionalHoursModal: React.FC<IViewAdditionalHoursModalProps> = ({
  overtime: initialOvertime,
  holidayCard,
  onOvertimeUpdated,
  onClose,
  open,
}) => {

  const { overtime, loading, working, error } = useSelector((state: RootState) => state.overtime);

  const [_open, _setOpen] = useState<boolean>(false);
  const [changingApprover, setChangingApprover] = useState<boolean>(false);
  const [_overtime, _setOvertime] = useState<OvertimeDetailView | null>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  useEffect(() => {
    initialOvertime &&
      holidayCard &&
      leaveActions.getOvertime(
        holidayCard.staffMember.id,
        initialOvertime.id,
        (data) => {
          _setOvertime(data);
        }
      );
  }, [initialOvertime, holidayCard]);

  const handleApprove = () => {
    if (window.confirm("This will approve this overtime. Are you sure?")) {
      var data: ApproveOvertimeCommand = {
        overtimeId: _overtime.id,
        staffMemberId: holidayCard.staffMember.id,
        holidayCardId: holidayCard.id
      };
      leaveActions.approveOvertime(holidayCard.staffMember.id, data, () => {
        onClose?.();
        ToastService.pop("Overtime Approved", null, "house-night");
        onOvertimeUpdated?.(_overtime);
      });
    }
  };

  const handleReject = () => {
    var errors = [];

    if (!_overtime.notes?.length) {
      errors.push("You must supply covering notes when rejecting a overtime.");
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    if (
      window.confirm(
        "This will reject this overtime. This cannot be undone. Are you sure?"
      )
    ) {
      var data: RejectOvertimeCommand = {
        overtimeId: _overtime.id,
        staffMemberId: holidayCard.staffMember.id,
        holidayCardId: holidayCard.id,
        notes: _overtime.notes
      };
      leaveActions.rejectOvertime(holidayCard.staffMember.id, data, () => {
        onClose?.();
        ToastService.pop("Overtime Rejected", null, "plane-arrival");
        onOvertimeUpdated?.(_overtime);
      });
    }
  };

  const handleCancel = () => {
    if (
      window.confirm(
        "This will cancel this overtime. This cannot be undone. Are you sure?"
      )
    ) {
      var data: CancelOvertimeCommand = {
        overtimeId: _overtime.id,
        staffMemberId: holidayCard.staffMember.id,
        holidayCardId: holidayCard.id,
        notes: _overtime.notes
      };
      leaveActions.cancelOvertime(holidayCard.staffMember.id, data, () => {
        onClose?.();
        ToastService.pop("Overtime Cancelled", null, "plane-arrival");
        onOvertimeUpdated?.(_overtime);
      });
    }
  };

  const handleSetApprover = () => {
    // _setSubmission({
    //   ..._submission,
    //   authoriser: value,
    //   authoriserId: value.id
    // });

    setChangingApprover(false);
  };

  return overtime ? (
    <Modal title="Overtime">
      <Modal.Body scrollOnErrors={validationErrors}>
        <ValidationMessage errors={validationErrors} />
        <Message text={error} color={Swatches.Danger} />

        {loading && <Loader size={Size.Medium} cover />}

        {_overtime ? (
          <Card>
            <Card.Body>
              <StructuredList>
                <StructuredList.Item name="Start Date">
                  <DateTime date={_overtime?.startDate} bold />
                </StructuredList.Item>

                <StructuredList.Item name="Duration">
                  <Label>
                    {_overtime?.hours} hour{_overtime?.hours !== 1 && "s"}
                  </Label>
                </StructuredList.Item>

                <StructuredList.Item name="Hours Claimed Back">
                  <Label>
                    {_overtime?.hoursUsed} hour
                    {_overtime?.hoursUsed !== 1 && "s"}
                  </Label>
                </StructuredList.Item>

                <StructuredList.Item name="Status">
                  <OvertimeStatusChip status={_overtime.currentStatus.status} />
                </StructuredList.Item>

                <StructuredList.Item>
                  <Avatar user={_overtime.approver} />
                </StructuredList.Item>

                <StructuredList.Item
                  name="Line Manager / Approver"
                  description="This is the staff member that the overtime has been sent to to approve."
                  rightContent={
                    <>
                      {!changingApprover && _overtime?.approver ? (
                        <Button
                          text="Change Approver"
                          color={Swatches.Primary}
                          onClick={() => setChangingApprover(true)}
                        />
                      ) : (
                        (_overtime?.approver) && (
                          <Button
                            text="Cancel"
                            color={Swatches.Low}
                            onClick={() => setChangingApprover(false)}
                          />
                        )
                      )}
                    </>
                  }
                >
                  {_overtime?.approver && !changingApprover ? (
                    <>
                      <Avatar user={_overtime?.approver} />
                    </>
                  ) : (
                    <StaffSearch fluid onChange={handleSetApprover} />
                  )}

                  <Avatar user={_overtime?.approver} />
                </StructuredList.Item>

                <StructuredList.Item name="Notes">
                  <HtmlText html={_overtime?.notes} />
                </StructuredList.Item>

                <StatusView status={_overtime?.currentStatus?.status}>
                  <StatusView.Item
                    status={OvertimeStatus.Cancelled}
                    inverse
                  >
                    <UserView user={_overtime?.approver}>
                      <StructuredList.Item
                        name="Line Manager Notes"
                        description="These notes are for use when approving or rejecting overtime.  They must be used to give a reason when rejecting overtime."
                      >
                        <RichTextEditor
                          onChange={value =>
                            _setOvertime({ ..._overtime, notes: value })
                          }
                        />
                      </StructuredList.Item>
                    </UserView>
                  </StatusView.Item>
                  <StatusView.Item></StatusView.Item>
                </StatusView>
              </StructuredList>
            </Card.Body>
          </Card>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <StatusView status={_overtime?.currentStatus?.status}>
          <StatusView.Item
            status={OvertimeStatus.Cancelled}
            inverse
          >
            <UserView user={_overtime?.createdBy}>
              <ActionBar low>
                <Button
                  text="Cancel Overtime"
                  onClick={handleCancel}
                  color={Swatches.Danger}
                  working={working}
                  fluid
                />
              </ActionBar>
            </UserView>
          </StatusView.Item>
          <StatusView.Item></StatusView.Item>
        </StatusView>

        <StatusView status={_overtime?.currentStatus?.status}>
          <StatusView.Item status={null}>
            <UserView user={_overtime?.approver}>
              <ActionBar low>
                <Button
                  text="Approve Overtime"
                  onClick={handleApprove}
                  color={Swatches.Success}
                  working={working}
                  fluid
                />
                <Button
                  text="Reject Overtime"
                  onClick={handleReject}
                  color={Swatches.Danger}
                  working={working}
                  fluid
                />
              </ActionBar>
            </UserView>
          </StatusView.Item>
          <StatusView.Item></StatusView.Item>
        </StatusView>
      </Modal.Footer>
    </Modal>
  ) : null;
};

export default ViewAdditionalHoursModal;
