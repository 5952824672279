import { CalendarEvent } from "areas/calendar/reducers/calendarEventReducer";
import React, { useState, useEffect } from "react";
import { SignupDetails } from ".";
import { Card, StructuredList, TextInput, TextInputType } from "ui-kit";
import moment from "moment";



interface IEventRequirementsProps {
  calendarEvent: CalendarEvent;
  signup: SignupDetails;
  setSignup: React.Dispatch<React.SetStateAction<SignupDetails>>;
  readonly?: boolean;
}


const EventRequirements: React.FC<IEventRequirementsProps> = ({ calendarEvent, signup, setSignup, readonly }) => {

  const signupStillOpen = (cc: CalendarEvent) => {
    return (
      cc?.allowSignupTo == null ||
      moment(cc?.allowSignupTo).isAfter(moment())
    )
  }
  
  if (!calendarEvent || calendarEvent?.dates?.length !== 1) {
    return (
      <></>
    )
  }

  if (!signupStillOpen(calendarEvent) || readonly) {
    return (
      <ReadonlyEventRequirements 
        calendarEvent={calendarEvent} 
        signup={signup}      
      />
    )
  }

  return (
    <>
      <Card title="Your Requirements">
        <Card.Body>
          <StructuredList>
            {calendarEvent?.menu?.length > 1 && (
              <StructuredList.Item
                name="Dietary Requirements"
                description="Please add any important dietary requirements such as allergies"
              >
                <TextInput
                  rows={3}
                  type={TextInputType.Textarea}
                  value={signup?.dietaryRequirements}
                  onChange={value =>
                    setSignup({
                      ...signup,
                      dietaryRequirements: value,
                    })
                  }
                  fluid
                />
              </StructuredList.Item>
            )}
            <StructuredList.Item
              name="Access Requirements"
              description="Please add any accessibility requirements relevant to this event"
            >
              <TextInput
                rows={3}
                type={TextInputType.Textarea}
                value={signup?.accessRequirements}
                onChange={value =>
                  setSignup({ ...signup, accessRequirements: value })
                }
                fluid
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
    </>
  );
}


interface IReadonlyEventRequirementsProps {
  calendarEvent: CalendarEvent;
  signup: SignupDetails;
}

const ReadonlyEventRequirements: React.FC<IReadonlyEventRequirementsProps> = ({ calendarEvent, signup }) => {

  if (!calendarEvent || !calendarEvent.signedUp || calendarEvent?.dates?.length !== 1) {
    return (
      <></>
    )
  }

  return (
    <>
      <Card title="Your Requirements">
        <Card.Body>
          <StructuredList>
            {calendarEvent?.menu?.length > 1 && (
              <StructuredList.Item
                name="Dietary Requirements"
                description="Please add any important dietary requirements such as allergies"
              >
                {signup?.dietaryRequirements}
              </StructuredList.Item>
            )}
            <StructuredList.Item
              name="Access Requirements"
              description="Please add any accessibility requirements relevant to this event"
            >
              {signup?.accessRequirements}
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
    </>
  )
}


export default EventRequirements;