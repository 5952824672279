import { privacySettings } from "reducers/ui/privacyReducer";
import types from "./uiTypes";
import store from "reducers/store";
import { IBeehiveAction } from "types/common/action.types";

const setPrivacy = (isPrivate: privacySettings) : void => {
  store.dispatch<IBeehiveAction<privacySettings>>({
    type: types.SET_PRIVACY,
    payload: isPrivate,
  });
};

const privacyActions = {
  setPrivacy,
};

export default privacyActions;
