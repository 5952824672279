import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useAction } from "hooks";
import paymentActions from "areas/payments/actions/paymentsActions";
import flyoutActions from "actions/ui/flyouts";
import Transactions from "../../smartcard/transactions";
import {
  Subtitle,
  Tabs,
  HeadlineStatistic,
  Spacing,
  Currency,
  Message,
  ToastService,
  Swatches
} from "ui-kit";
import CreditsTable from "../smartcards/creditsTable";
import PrintCreditFlyout from "../smartcards/printCreditFlyout";
import { Constants } from "configuration";
import TopupButton from "../smartcards/topupButton";
import CustomTopupFlyout from "./customTopupFlyout";
import { users } from "utils";
import { RootState } from "reducers/store";
import { SmartcardDetailView, SmartcardSyncResultListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { AddSmartcardTopupToBasketCommand } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";
import SmartcardSyncFilter from "../smartcards/smartcardSyncFilter";


const TopupWrapper = styled.div`
  display: flex;
  .card {
    margin-right: ${Spacing.Large}px;
    &:last-child {
      margin-right: 0;
    }
  }
`;


interface IUserSmartcardProps {
  smartcard: SmartcardDetailView;
}


const UserSmartcard: React.FC<IUserSmartcardProps> = ({ smartcard }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { transactions, loading: loadingTransactions, error: transactionsError, paging: transactionPaging } = useSelector((state: RootState) => state.userSmartcardTransactions);
  const { syncRecords, filter, loading: loadingSyncRecords, error: syncRecordsError } = useSelector((state: RootState) => state.userSmartcardSyncRecords);
  const { working: basketWorking, errors: basketError } = useSelector((state: RootState) => state.userBasket);

  const [selectedCredit, setSelectedCredit] = useState<SmartcardSyncResultListView | null>(null);

  const loadTransactions = (pageIndex: number) => {
    if (smartcard && smartcard.user && smartcard.school) {
      paymentActions.getSmartcardTransactionsForUser(smartcard.user.id, pageIndex);
    }
  };

  useEffect(() => {
    loadTransactions(0);
    if (smartcard && smartcard.user && smartcard.school) {
      paymentActions.getSmartcardSyncRecordsForUser(smartcard.user.id, filter);
    }
  }, [smartcard]);

  useEffect(() => {
    smartcard && paymentActions.getSmartcardSyncRecordsForUser(smartcard.user.id, filter);
  }, [filter]);

  const handlePage = () => {
    loadTransactions(transactionPaging.pageIndex + 1);
  };

  const handleCreditClick = (credit: SmartcardSyncResultListView) => {
    setSelectedCredit(credit);
    flyoutActions.openFlyout(Constants.FLYOUTS.CREDIT);
  };

  const handleTopupAdded = (value: number) => {
    const data: AddSmartcardTopupToBasketCommand = {
      userId: smartcard.user.id,
      recipientId: smartcard.user.id,
      schoolId: smartcard.school.id,
      amount: value
    };
    paymentActions.addTopupToBasket(smartcard.user.id, data, () => {
      ToastService.pop("Topup added to basket", null, "shopping-bag");
    });
  };

  const handleCustomTopup = () => {
    flyoutActions.openFlyout(Constants.FLYOUTS.CUSTOM_TOPUP);
  };

  const userIsBuyerOnly = () => {
    return !users.isInRole(user, Constants.ROLES.SHOPKEEPER_ADMINISTRATOR) && !users.isInRole(user, Constants.ROLES.SHOPKEEPER_USER);
  }

  return (
    <>
      <HeadlineStatistic>
        <HeadlineStatistic.Item
          icon="id-card-alt"
          value={<Currency value={smartcard?.balance} />}
          label="Smartcard Balance"
        />
      </HeadlineStatistic>

      <Subtitle text="Smartcard Topups" />
      <TopupWrapper>
        {basketError?.item && (
          <Message
            text="There was a problem adding the topup to the basket"
            color={Swatches.Danger}
          />
        )}
        {Constants.TOPUP_AMOUNTS.map(value => (
          <TopupButton
            key={value}
            value={value}
            buttonText="Add Topup"
            onAddToBasket={handleTopupAdded}
            working={basketWorking}
            tooltip="Add Topup to Basket"
            tooltipSub={`Add a £${value} smartcard topup to the basket`}
          />
        ))}
        <TopupButton
          custom
          buttonText="Choose Amount"
          onAddCustom={handleCustomTopup}
          working={basketWorking}
          tooltip="Add Custom Topup to Basket"
          tooltipSub={`Add a custom smartcard topup to the basket`}
        />
      </TopupWrapper>

      <Tabs>
        <Tabs.Pane key={0} name="Transactions" label="Smartcard Transactions" hide={userIsBuyerOnly()} >
          <Transactions
            user={smartcard?.user}
            onPage={handlePage}
            paging={transactionPaging}
            loading={loadingTransactions}
            transactions={transactions}
            error={transactionsError}
          />
        </Tabs.Pane>
        <Tabs.Pane key={1} name="Sync" label="Smartcard Sync">
          <SmartcardSyncFilter />
          <CreditsTable
            credits={syncRecords}
            loading={loadingSyncRecords}
            error={syncRecordsError != null}
            onRowClicked={handleCreditClick}
          />
        </Tabs.Pane>
      </Tabs>
      <PrintCreditFlyout credit={selectedCredit} />
      <CustomTopupFlyout onTopupAdded={value => handleTopupAdded(value)} />
    </>
  );
};

export default UserSmartcard;
