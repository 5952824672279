import React from "react";
import styled from "styled-components";
import { IUiKitBaseProps } from "../index";

export interface ILeftProps extends IUiKitBaseProps {
  children: any;
}

const Wrapper = styled.div``;

const Left: React.FC<ILeftProps> = ({ children }) => {
  return <Wrapper className="left">{children}</Wrapper>;
};

export default Left;
