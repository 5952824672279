import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Loader,
  Size,
  EmptyMessage,
  Title,
  TitleSize,
  Card,
  StructuredList,
  TextInput,
  TextInputType,
  DatePicker,
  Sub,
  RichTextEditor,
  Checkbox,
  Swatches,
  ToastService,
  Button,
  Message,
  ValidationMessage,
  ActionBar,
} from "ui-kit";
import { StaffPicker, GroupSelector, ApiExceptionMessage } from "sharedComponents/common";
import InstalmentsTable, { NewInstalment } from "./instalmentsTable";
import { arrays } from "utils";
import { useNavigate } from "react-router-dom";
import config from "configuration";
import { RootState } from "reducers/store";
import paymentActions from "areas/payments/actions/paymentsActions";
import { ProductCategoryDetailView, ProductDetailView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { SaveProductCommand } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";
import { GroupListView } from "types/users/userGroups.types";
import { InstallmentType, InstalmentRuleView } from "areas/payments/types/shopkeeper/shopkeeperShared.types";
import { UserRestrictions } from "types/common/command.typed";
import { useScroll } from "hooks/useScroll";
import { useAppSelector } from "reducers/hooks";
import moment from "moment";


export interface ProductDetails extends ProductDetailView {
  pupilPremium: boolean;
  bursary: boolean;
  schools?: number[];
  assignedTo?: string[];
}


const ProductEditor = () => {

  const { scrollToTop } = useScroll();
  const { categoryId, productId } = useParams();
  const { product, loading, error, saving, saveError } = useSelector((state: RootState) => state.adminProduct);
  const { category } = useAppSelector(state => state.adminCategory);
  const navigate = useNavigate();

  const [_product, _setProduct] = useState<ProductDetails>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_description, _setDescription] = useState<string>("");
  const [_category, _setCategory] = useState<ProductCategoryDetailView | null>(null);

  useEffect(() => {
    saveError && scrollToTop();
  }, [saveError]);

  useEffect(() => {
    if (categoryId && (!category || category?.id != parseInt(categoryId))) {
      paymentActions.getCategory(parseInt(categoryId), response => {
        _setCategory(response);
      });
    } else {
      _setCategory(category);
    }
  }, [category, categoryId])

  useEffect(() => {
    if (productId && categoryId) {
      paymentActions.getProduct(parseInt(categoryId), parseInt(productId), (result: ProductDetailView) => {
        _setProduct({
          ...result,
          pupilPremium:
            result.pupilPremiumDiscount !== null &&
            result.pupilPremiumDiscount !== undefined &&
            result.pupilPremiumDiscount != 0,
          bursary:
            result.bursaryDiscount !== null &&
            result.bursaryDiscount !== undefined &&
            result.bursaryDiscount != 0,
        });
        _setDescription(result.description);
      });
    } else {
      _setProduct({
        categoryId: parseInt(categoryId),
        name: "",
        description: "",
        isVisibleInStore: true,
        isActive: true,
        stock: 1,
        expiryDate: null,
        unitPrice: 1.0,
        pupilPremium: false,
        pupilPremiumDiscount: null,
        enforceStockControl: true,
        allowMultiple: false,
        allowPartPayment: false,
        requiresCollection: false,
        maximumOrderQuantity: 15,
        instalmentRules: [],
        groups: [],
        schools: [ _category?.school?.id ],
        interestedStaff: [],
        allowFuture: false,
        bursary: false,
        inStock: true, 
        bursaryDiscount: 0, 
        hasExpiryDate: false, 
        canBePurchasedInStore: true,
        soldOut: false, 
        sold: 0, 
        stockRemaining: 0, 
        hasInstalments: false,
        ppDiscountedPrice: undefined, 
        bursaryDiscountedPrice: undefined, 
        id: undefined, 
        category: undefined,
        userRestrictions: {
          allowStudents: _category?.allowStudents,
          allowParents: _category?.allowParents,
          allowStaff: _category?.allowStaff
        }
      });
    }
  }, [productId, categoryId, _category]);

  const handleGroupChange = (groups: GroupListView[]) => {
    _setProduct({ ..._product, groups });
  };

  const calculateInstalmentTotal = (filter: number) => {
    return _product.instalmentRules
      .filter((x) => x.installmentType == filter)
      .reduce(function (prev, cur) {
        return Number(prev) + Number(cur.total);
      }, 0);
  };

  const calculatePupilPremiumInstalmentTotal = () => {
    return _product.instalmentRules
      .filter((x) => x.optionalForPupilPremium)
      .reduce(function (prev, cur) {
        return prev + cur.total;
      }, 0);
  };

  const handleDeleteInstalment = (instalment: InstalmentRuleView, index: number) => {
    _setProduct({
      ..._product,
      instalmentRules: arrays.remove(_product.instalmentRules, index),
      allowPartPayment: _product.instalmentRules.length === 0
        ? false
        : _product.allowPartPayment
    });
    ToastService.pop("Instalment Removed", null, "shopping-bag");
  };

  const handleInstalmentSaved = (instalment: NewInstalment) => {
    if (instalment.index >= 0) {
      _setProduct({
        ..._product,
        instalmentRules: _product.instalmentRules.map((x, i) =>
          i === instalment.index
            ? {
                ...x,
                dueDate: instalment.dueDate,
                total: instalment.total,
                optionalForPupilPremium: instalment.optionalForPupilPremium,
              }
            : x
        ),
        allowMultiple: _product.instalmentRules.length > 0 
          ? false 
          : _product.allowMultiple
      });
    } else {
      _setProduct({
        ..._product,
        instalmentRules: [..._product.instalmentRules, instalment],
        allowMultiple: _product.instalmentRules.length > 0 
          ? false 
          : _product.allowMultiple
      });
    }

    ToastService.pop("Instalment Saved", null, "shopping-bag");
  };

  const postSave = () => {
    ToastService.pop("Product Saved", null, "shopping-bag");
    navigate(-1);
  };

  const handleSave = () => {
    //Validate
    var errors = [];

    if (!_product.name || _product.name == "") {
      errors.push(`Please enter a name.`);
    }
    if (_product.unitPrice <= 0) {
      errors.push(`Please enter a valid unit price.`);
    }
    if (_product.pupilPremium == true && 
      _product.pupilPremiumDiscount != null &&
      (Number(_product.pupilPremiumDiscount) <= 0 || Number(_product.pupilPremiumDiscount) >= Number(_product.unitPrice))
    ) {
      errors.push(`Please enter a valid pupil premium unit price. It must be less than the unit price.`);
    }
    if (_product.bursary == true && 
      _product.bursaryDiscount != null &&
      (Number(_product.bursaryDiscount) <= 0 || Number(_product.bursaryDiscount) >= Number(_product.unitPrice))
    ) {
      errors.push(`Please enter a valid bursary unit price. It must be less than the unit price.`);
    }

    if (_product.allowPartPayment && _product.unitPrice <= 25) {
      errors.push("Cannot enable part payments on products equal to or below £25");
    }

    if (_product.allowPartPayment && _product.instalmentRules.length === 0) {
      errors.push("Part payments are only available for products with instalments");
    }

    if (_product.unitPrice > config.payments.instalmentThresholdPrice && !arrays.isEmpty(_product.instalmentRules)) {
      if (_product?.instalmentRules.some(x => x.installmentType === 0) && calculateInstalmentTotal(0) != _product.unitPrice) {
        errors.push(`The item price is not the same as the instalments total.`);
      }
      if (_product?.instalmentRules.some(x => x.installmentType === 1) && calculateInstalmentTotal(1) != (_product.unitPrice - _product.pupilPremiumDiscount)) {
        errors.push(`The item price is not the same as the pupil premium total.`);
      }
      if (_product?.instalmentRules.some(x => x.installmentType === 2) && calculateInstalmentTotal(2) != (_product.unitPrice - _product.bursaryDiscount)) {
        errors.push(`The item price is not the same as the bursary total.`);
      }
    } else {
      _setProduct({ ..._product, instalmentRules: [] }); // Forcibly wipe instalment rules that if price is less than Constants..
    }

    if (!_product.userRestrictions.allowParents && !_product.userRestrictions.allowStaff && !_product.userRestrictions.allowStudents) {
      errors.push(`You must select at least one user type to be the recipient for this product.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    const data: SaveProductCommand = {
      id: _product.id,
      categoryId: _product.categoryId,
      name: _product.name,
      description: "",
      isVisibleInStore: _product.isVisibleInStore,
      isActive: _product.isActive,
      stock: _product.stock,
      unitPrice: _product.unitPrice,
      enforceStockControl: _product.enforceStockControl,
      hasExpiryDate: _product.hasExpiryDate,
      expiryDate: moment(_product.expiryDate).format("YYYY-MM-DD"),
      allowMultiple: _product.allowMultiple,
      allowPartPayment: _product.allowPartPayment,
      requiresCollection: _product.requiresCollection,
      allowFuture: _product.allowFuture,
      pupilPremiumDiscount: _product.pupilPremiumDiscount,
      bursaryDiscount: _product.bursaryDiscount,
      interestedStaff: _product.interestedStaff,
      instalmentRules: _product.instalmentRules,
      schools: [],  //Let the Api handle schools
      groups: _product.groups,
      assignedTo: _product.assignedTo,
      userRestrictions: _product.userRestrictions
    }
    // Save
    if (_product.id) {
      paymentActions.saveProduct(data, postSave);
    } else {
      paymentActions.createProduct(data, postSave);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (error) {
    return (
      <EmptyMessage
        icon="times-circle"
        title="A problem occurred"
        summary="There was a problem loading the product"
        cover
      />
    );
  }

  if (!_product) {
    return (
      <EmptyMessage
        icon="shopping-bag"
        title="Not found"
        summary="The requested product was not found"
        cover
      />
    );
  }

  return (
    <>
      <Title
        size={TitleSize.H2}
        text="Inventory"
        sub={_product.id ? `Edit ${product.name}` : "New Item"}
      />
      <ApiExceptionMessage error={saveError} />
      <ValidationMessage errors={validationErrors} />

      <Card title="General Details">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item
              required
              name="Name"
              description="The name of the item. This should be clear and concise. DO NOT include year groups, subjects or similar in this description as they are automatically added by the 'Available For' group settings."
            >
              <TextInput
                value={_product.name}
                onChange={(value) => _setProduct({ ..._product, name: value })}
                characterCount={true}
                maxLength={150}
                fluid
              />
            </StructuredList.Item>
            <StructuredList.Item
              name="User Permissions"
              required
              description={
                <p>
                  Choose which user types can buy this product             
                  <b> for themselves</b>. If students are selected, 
                  then their parents can also purchase for their children.
                </p>
              }
            >
              {_category?.allowParents && (
                <Checkbox
                  checked={_product.userRestrictions?.allowParents}
                  onChange={checkbox => _setProduct({ 
                    ..._product, 
                    userRestrictions: {
                      ..._product.userRestrictions,
                      allowParents: checkbox.checked 
                    }
                  })}
                  text={`Allow parents to purchase for themselves`}
                />
              )}
              {_category?.allowStudents && (
                <Checkbox
                  checked={_product.userRestrictions?.allowStudents}
                  onChange={checkbox => _setProduct({ 
                    ..._product, 
                    userRestrictions: {
                      ..._product.userRestrictions,
                      allowStudents: checkbox.checked 
                    },
                    groups: [],
                    pupilPremium: false,
                    pupilPremiumDiscount: null,
                    bursary: false,
                    bursaryDiscount: null,
                    instalmentRules: _product.instalmentRules?.filter(i => i.installmentType === InstallmentType.Default)
                  })}
                  text={`Allow students to purchase for themselves`}
                />
              )}
              {_category?.allowStaff && (
                <Checkbox
                  checked={_product.userRestrictions?.allowStaff}
                  onChange={checkbox => _setProduct({ 
                    ..._product, 
                    userRestrictions: {
                      ..._product.userRestrictions,
                      allowStaff: checkbox.checked 
                    }
                  })}
                  text={`Allow staff to purchase for themselves`}
                />
              )}
            </StructuredList.Item>
            <StructuredList.Item
              name="Available For"
              description="Please enter the groups this item is available for."
              hide={!_product.userRestrictions?.allowStudents}
            >
              <GroupSelector
                onChange={handleGroupChange}
                groups={_product?.groups}
              />
            </StructuredList.Item>
            <StructuredList.Item
              name="Description"
              description="Optionally include further details of the item. If parental communications have been sent, then the description should contain the same information as these."
            >
              <RichTextEditor
                initialValue={_description}
                onChange={(value) =>
                  _setProduct({ ..._product, description: value })
                }
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
      <Card title="Purchasing &amp; Stock">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item
              required
              name="Unit Price"
              description="The cost of a single item."
            >
              <TextInput
                type={TextInputType.Currency}
                value={_product.unitPrice}
                onChange={(value) =>
                  _setProduct({ 
                    ..._product, 
                    unitPrice: value,
                    allowPartPayment: (value < 25)
                      ? false
                      : _product.allowPartPayment
                  })
                }
                min={0}
              />
            </StructuredList.Item>
            <StructuredList.Item
              name="Pupil Premium Discount"
              description="The discount for Pupil Premium students."
              hide={!_product.userRestrictions?.allowStudents}
            >
              <Checkbox
                checked={_product.pupilPremium}
                onChange={(val) =>
                  _setProduct({
                    ..._product,
                    pupilPremium: val.checked,
                    pupilPremiumDiscount: val.checked
                      ? 0
                      : null,
                  })
                }
                text="Set Pupil Premium Discount"
              />
              {_product.pupilPremium && (
                <>
                  <TextInput
                    type={TextInputType.Currency}
                    value={_product.pupilPremiumDiscount}
                    onChange={(value) =>
                      _setProduct({ ..._product, pupilPremiumDiscount: value })
                    }
                    min={0}
                    max={_product.unitPrice - 0.01}
                  />
                  <br />
                  <Sub>
                    The Pupil Premium discount is applied automatically when the
                    item is ordered for Pupil Premium students.
                  </Sub>
                </>
              )}
            </StructuredList.Item>

            <StructuredList.Item
              name="Bursary Discount"
              description="The discount for Bursary students."
              hide={!_product.userRestrictions?.allowStudents}
            >
              <Checkbox
                checked={_product.bursary}
                onChange={(val) =>
                  _setProduct({
                    ..._product,
                    bursary: val.checked,
                    bursaryDiscount: val.checked
                      ? 0
                      : null,
                  })
                }
                text="Set Bursary Discount"
              />
              {_product.bursary && (
                <>
                  <TextInput
                    type={TextInputType.Currency}
                    value={_product.bursaryDiscount}
                    onChange={(value) =>
                      _setProduct({ ..._product, bursaryDiscount: value })
                    }
                    min={0}
                    max={_product.unitPrice - 0.01}
                  />
                  <br />
                  <Sub>
                    The bursary discount is applied automatically when the
                    item is ordered for bursary students.
                  </Sub>
                </>
              )}
            </StructuredList.Item>

            <StructuredList.Item
              name="Allow Multiple Purchases"
              description="If enabled, this item can be bought more than once for a student."
              hide={_product.instalmentRules.length > 0}
            >
              <Checkbox
                checked={_product.allowMultiple}
                text={`Allow Multiple Purchases`}
                onChange={(value) => {
                  _setProduct({ ..._product, allowMultiple: value.checked });
                }}
              />
            </StructuredList.Item>
            <StructuredList.Item
              name="Maximum Order Limit"
              description="Indicates the maximum number of items that can be purchased in one transaction. Allow Multiple Purchases must be enabled for this option."
            >
              {_product.allowMultiple ? (
                <TextInput
                  type={TextInputType.Number}
                  value={_product.maximumOrderQuantity}
                  onChange={(value) => {
                    _setProduct({ ..._product, maximumOrderQuantity: value });
                  }}
                  min={1}
                />
              ) : (
                <p>Allow multple purchases must be enabled for this option</p>
              )}
            </StructuredList.Item>
            {_product.unitPrice > 25 && _product.instalmentRules.length > 0 && (
              <StructuredList.Item
                name="Allow Part Payment"
                description="If enabled, parents can pay a minimum amount of £25 towards the product and pay the rest at a later date."
              >
                <Checkbox
                  checked={_product.allowPartPayment}
                  text={`Allow Part Payment`}
                  onChange={(value) => {
                    _setProduct({ ..._product, allowPartPayment: value.checked });
                  }}
                />
              </StructuredList.Item>
            )}
            <StructuredList.Item
              name="Stock"
              description="The total number of items available."
            >
              <TextInput
                type={TextInputType.Number}
                value={_product.stock}
                onChange={(value) => _setProduct({ ..._product, stock: value })}
                min={1}
              />
            </StructuredList.Item>
            <StructuredList.Item
              name="Enforce Stock Limit"
              description="If enabled, once the number of sales is equal to or exceeds the stock level, the item is no longer available in the public store."
            >
              <Checkbox
                checked={_product.enforceStockControl}
                text={`Enforce Stock Limit`}
                onChange={(value) => {
                  _setProduct({
                    ..._product,
                    enforceStockControl: value.checked,
                  });
                }}
              />
            </StructuredList.Item>
            <StructuredList.Item
              name="Requires Collection"
              description="Specify whether physical products require collection."
            >
              <Checkbox
                checked={_product.requiresCollection}
                text={`This item requires collection`}
                onChange={(value) => {
                  _setProduct({
                    ..._product,
                    requiresCollection: value.checked,
                  });
                }}
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
      <Card title="Installments">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item
              name="Instalments"
              description="You can give customers the option to pay for this item in dated instalments."
            >
              {_product.unitPrice < config.payments.instalmentThresholdPrice ? (
                <p>
                  Unit price must be £{config.payments.instalmentThresholdPrice}{" "}
                  or greater to set instalments.
                </p>
              ) : (
                <InstalmentsTable
                  product={_product}
                  editable
                  onInstalmentSaved={handleInstalmentSaved}
                  onInstalmentDeleted={handleDeleteInstalment}
                  installmentType={0}
                />
              )}
            </StructuredList.Item>
            <StructuredList.Item
              name="Pupil Premium Instalments"
              description="You can give pupil premium students the option to pay for this item in dated instalments."
              hide={!_product.userRestrictions?.allowStudents}
            >
               { (_product.unitPrice < config.payments.instalmentThresholdPrice) || !_product.pupilPremium ? (
                <p>
                  Unit price must be £{`${config.payments.instalmentThresholdPrice} `} or greater and have a pupil premium discount set to set instalments.
                </p>
              ) : (
                <InstalmentsTable
                  product={_product}
                  editable
                  onInstalmentSaved={handleInstalmentSaved}
                  onInstalmentDeleted={handleDeleteInstalment}
                  installmentType={1}
                  emptyMessage={"No Pupil Premium Instalments"}
                />
              )}
            </StructuredList.Item>
            <StructuredList.Item
              name="Bursary Instalments"
              description="You can give bursary students the option to pay for this item in dated instalments."
              hide={!_product.userRestrictions?.allowStudents}
            >
              { (_product.unitPrice < config.payments.instalmentThresholdPrice) || !_product.bursary ? (
                <p>
                  Unit price must be £{`${config.payments.instalmentThresholdPrice} `} or greater and have a bursary discount set to set instalments.
                </p>
              ) : (
                <InstalmentsTable
                  product={_product}
                  editable
                  onInstalmentSaved={handleInstalmentSaved}
                  onInstalmentDeleted={handleDeleteInstalment}
                  installmentType={2}
                  emptyMessage={"No Bursary Instalments"}
                />
              )}
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
      <Card title="Visibility">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item
              name="Is Active"
              description="Whether the item is active. If inactive the item will not be available for purchase at all."
            >
              <Checkbox
                checked={_product.isActive}
                text={`This item is active`}
                onChange={(value) => {
                  _setProduct({
                    ..._product,
                    isActive: value.checked,
                    isVisibleInStore: !value.checked
                      ? false
                      : _product.isVisibleInStore,
                  });
                }}
              />
            </StructuredList.Item>
            <StructuredList.Item
              name="Enabled in Store"
              description="Whether the item is visible in the public store. Note that an item can be active but not visible in the store."
            >
              {_product.isActive ? (
                <Checkbox
                  checked={_product.isVisibleInStore}
                  text={`This item is enabled in the store`}
                  onChange={(value) => {
                    _setProduct({
                      ..._product,
                      isVisibleInStore: value.checked,
                    });
                  }}
                />
              ) : (
                <p>
                  Item is inactive so is automatically unavailable in the store
                </p>
              )}
            </StructuredList.Item>
            <StructuredList.Item
              name="Expiry Date"
              description="The date by which purchases can be made in the public store."
            >
              {_product.isActive && _product.isVisibleInStore ? (
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  closeOnSelect
                  selectedDate={_product.expiryDate}
                  onChange={value =>
                    _setProduct({ ..._product, expiryDate: moment(value).format("YYYY-MM-DD") })
                  }
                />
              ) : (
                <p>
                  Item is inactive or disabled in store so is automatically
                  unavailable in the store
                </p>
              )}
            </StructuredList.Item>
            <StructuredList.Item
              name="Future Purchases"
              description="Whether the product can be purchased by future parents or students."
            >
              <Checkbox
                checked={_product.allowFuture}
                text={`Allow Future Purchases`}
                onChange={(value) => {
                  _setProduct({
                    ..._product,
                    allowFuture: value.checked,
                  });
                }}
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
      <Card title="Interested Staff">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item
              name="Interested Staff"
              description="You can provide a list of staff members responsible for managing this product. Once added, they will receive email notifications when items are sold and have access to limited product management via their store."
            >
              <StaffPicker
                users={_product?.interestedStaff}
                onChange={(users) =>
                  _setProduct({ ..._product, interestedStaff: users })
                }
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
      <ActionBar>
        <Button
          text="Save Item"
          onClick={handleSave}
          color={Swatches.Success}
          working={saving}
        />
        <Button
          text="Cancel"
          onClick={handleCancel}
          color={Swatches.Low}
          working={saving}
        />
      </ActionBar>
    </>
  );
};

export default ProductEditor;
