import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ArticlesTable from "./articlesTable";
import { useNavigate } from "react-router";
import ArticlesFilter from "./articlesFilter";
import newsActions from "../../actions/newsActions";
import { articleRoutes } from "areas/news/newsRoutes";
import { RootState } from "reducers/store";


const Articles = () => {

  const navigate = useNavigate();

  const { articles, paging, loading, error } = useSelector(
    (state: RootState) => state.articles
  );
  const { filter } = useSelector((state: RootState) => state.articleFilter);

  const loadArticles = (pageIndex: number) => {
    filter && newsActions.getArticles(filter, pageIndex);
  };

  useEffect(() => {
    loadArticles(0);
  }, [filter]);

  const handlePage = () => {
    console.log("XCA", paging);
    loadArticles(paging.pageIndex + 1);
  };

  const handleNewArticleClick = (categoryId: number) => {
    navigate(articleRoutes.getNewPath(categoryId));
  };

  return (
    <>
      <ArticlesFilter onNewArticleClick={handleNewArticleClick} />
      <ArticlesTable
        loading={loading}
        error={error}
        articles={articles}
        onPage={handlePage}
        paging={paging}
      />
    </>
  );
};

export default Articles;
