import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Constants } from "configuration";
import {
  Flyout,
  ActionBar,
  Button,
  Swatches,
  ToastService,
  Card,
  EmptyMessage,
  TextInput,
  TextInputType,
  Message,
  ValidationMessage,
  StructuredList,
} from "ui-kit";
import { arrays } from "utils";
import flyoutActions from "actions/ui/flyouts";
import providerActions from "areas/send/actions/providers/providerActions";
import { RootState } from "reducers/store";
import { ProviderListView } from "areas/send/types/passportResponse.types";


interface IProviderFlyoutProps {
  provider: ProviderListView;
  onClose?: (provider: ProviderListView) => void;
  onSave?: (provider: ProviderListView) => void;
}


const ProviderFlyout: React.FC<IProviderFlyoutProps> = ({ provider, onClose, onSave }) => {

  const { saveError, saving } = useSelector((state: RootState) => state.provider);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_provider, _setArrangement] = useState<ProviderListView>(provider);

  useEffect(() => {
    _setArrangement(provider);
    setValidationErrors([]);
  }, [provider]);

  const handleClose = () => {
    flyoutActions.closeFlyout();
    onClose?.(provider);
  };

  const handlePostSave = () => {
    flyoutActions.closeFlyout();
    ToastService.pop("Provider Saved", null, "hand-holding-seedling");
    onSave?.(_provider);
  };

  const handleSave = () => {
    // Validation
    var errors = [];

    if (!_provider.name.length) {
      errors.push(`Please enter a name.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    if (_provider.id) {
      providerActions.saveProvider(_provider, () => {
        handlePostSave();
      });
    } else {
      providerActions.createProvider(_provider, () => {
        handlePostSave();
      });
    }
  };

  return (
    <Flyout
      title={`${provider?.id ? "Edit" : "New"} Provider`}
      name={Constants.FLYOUTS.SEND_PROVIDER}
      wide
    >
      <Flyout.Body>
        {_provider ? (
          <>
            <Message text={saveError} color={Swatches.Danger} />
            <ValidationMessage errors={validationErrors} />
            <Card>
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item
                    name="Name"
                    description="The name of the provider"
                    required
                  >
                    <TextInput
                      value={_provider.name}
                      onChange={value =>
                        _setArrangement({ ..._provider, name: value })
                      }
                      maxLength={100}
                      fluid
                    />
                  </StructuredList.Item>
                  <StructuredList.Item
                    name="Description"
                    description="An optional description with more details about this provider"
                  >
                    <TextInput
                      type={TextInputType.Textarea}
                      value={_provider.description}
                      onChange={value =>
                        _setArrangement({ ..._provider, description: value })
                      }
                      fluid
                      rows={5}
                    />
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
          </>
        ) : (
          <EmptyMessage
            icon="hand-holding-seedling"
            title="No Provider"
            summary="No providers has been selected"
            cover
          />
        )}
      </Flyout.Body>
      <Flyout.Footer>
        <ActionBar low>
          <Button
            text="Save"
            onClick={handleSave}
            color={Swatches.Success}
            fluid
            working={saving}
          />
        </ActionBar>
      </Flyout.Footer>
    </Flyout>
  );
};

export default ProviderFlyout;
