import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import { SchoolBehaviourCodeListView } from "areas/behaviour/types/behaviourResponses.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";

// Does not include legacy behaviour codes

interface IBehaviourCodesState {
  behaviourCodes: SchoolBehaviourCodeListView[];
  loading: boolean;
  getBehaviourCodesError: IBeehiveError;
}

const INITIAL_STATE: IBehaviourCodesState = {
  behaviourCodes: [],
  loading: false,
  getBehaviourCodesError: null,
};

const { GETBEHAVIOURCODES } = behaviourActions.types;

const behaviourCodesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IBehaviourCodesState => {

  switch (action.type) {
    case GETBEHAVIOURCODES.START:
      return { ...state, loading: true, getBehaviourCodesError: null };

    case GETBEHAVIOURCODES.SUCCESS:
      return { ...state, loading: false, behaviourCodes: action.payload };

    case GETBEHAVIOURCODES.FAILED:
      return {
        ...state,
        loading: false,
        getBehaviourCodesError: action.payload,
      };

    default:
      return state;
  }
};

export default behaviourCodesReducer;
