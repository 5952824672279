/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import {
  honeygold,
  neutral,
  typescale,
  displayFont,
  Icon,
  BorderRadius,
  Spacing,
  fontStyle,
  Speed,
} from "../index";
import { Constants } from "../../configuration";
import { Size } from "..";
import Spinner from "../basic/spinner";

const Wrapper = styled.div`
  ${fontStyle(displayFont.medium, typescale.paragraph, neutral[600])}

  .calendar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: ${BorderRadius.Default}px;
    border-top-right-radius: ${BorderRadius.Default}px;
    background: ${neutral[600]};
    color: ${neutral[100]};

    span {
      flex-grow: 1;
      text-align: center;
    }

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: 0;
      padding: ${Spacing.Default}px;

      &:first-child {
        // border-top-left-radius: ${BorderRadius.Default}px;
        border-right: 1px solid ${neutral[100]};
      }

      &:last-child {
        // border-top-right-radius: ${BorderRadius.Default}px;
        border-left: 1px solid ${neutral[100]};
      }

      i {
        color: ${neutral[100]};
      }

      &:hover {
        transition: all ease ${Speed.Default}ms;
        cursor: pointer;
        background: ${honeygold};
        i {
          color: ${neutral[700]};
        }
      }
    }
  }

  .calendar-body {
    table {
      position: relative;
      width: 100%;
      box-sizing: box-content;

      thead {
        padding-right: unset;
      }

      tbody {
        min-height: auto;
      }

      tr {
        display: flex;
      }
      &&&tr:hover td {
        transition: all ease ${Speed.Default};
      }

      th {
        background: ${neutral[300]};
        font-weight: ${displayFont.medium.weight};
        border-bottom: 1px solid ${neutral[100]};
        border-right: 1px solid ${neutral[100]};

        &:last-child {
          border-right: 0;
        }
      }

      th,
      td {
        flex-grow: 1;
        flex-basis: 0;
        padding: 12px;
        text-align: center;
      }
    }

    .calender-loading-spinner {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  .calendar-footer {
    border-bottom-left-radius: ${BorderRadius.Default}px;
    border-bottom-right-radius: ${BorderRadius.Default}px;
    background: ${neutral[300]};
    display: flex;

    button {
      border: 0;
      background: none;
      ${fontStyle(displayFont.medium, typescale.paragraph, neutral[600])}
      border-right: 1px solid ${neutral[100]};
      padding: ${Spacing.Default}px;
      flex-grow: 1;
      flex-basis: 0;

      &:first-child {
        border-bottom-left-radius: ${BorderRadius.Default}px;
      }

      &:last-child {
        border-right: 0;
      }

      &:hover {
        transition: all ease ${Speed.Default}ms;
        background: ${neutral[600]};
        color: ${neutral[100]};
        cursor: pointer;
      }
    }
  }
`;

const Day = styled.td<{
  selected: boolean;
  isToday: boolean;
  disallowedDay: boolean;
}>`
  font-weight: ${displayFont.medium.weight};
  background: ${neutral[200]};
  border-bottom: 1px solid ${neutral[100]};
  border-right: 1px solid ${neutral[100]};

  &:last-child {
    border-right: 0;
  }

  &&&:hover {
    transition: all ease ${Speed.Short}ms;
    cursor: pointer;
    background: ${neutral[300]};
  }

  ${({ selected }) =>
    selected &&
    `background: ${honeygold};
    font-weight: ${displayFont.medium.weight};
    color: ${neutral[700]};
    &&&:hover{
      cursor: pointer;
      background: ${honeygold};
    }
  `}

  ${({ isToday, selected }) =>
    isToday && !selected && `background: ${neutral[300]};`}

  ${({ disallowedDay }) =>
    disallowedDay &&
    `background: #e8c3c3;
    color: ${neutral[200]};
    
      &:hover {
        background: #e8c3c3;
        cursor: not-allowed;
      }
  `}
`;

export interface ICalendarProps {
  selectedDate: string;
  dateFormat?: string;
  onChange?: (date: Date) => void;
  className?: string;
  disallowed?: Date[];
  onDateRangeChange?(startDate: DateObject, endDate: DateObject): void;
  loading?: Boolean;
}

export type DateObject = {
  year: number;
  month: number;
  day: number;
};
type Week = DateObject[];

const Calendar: React.FC<ICalendarProps> = ({
  selectedDate: initialDate,
  dateFormat,
  onChange,
  className,
  disallowed,
  onDateRangeChange,
  loading,
}) => {
  const getMonthFromDate = (date: Date) => {
    return { month: date.getMonth(), year: date.getFullYear() };
  };

  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [currentMonth, setCurrentMonth] = useState<any>(null);
  const [monthView, setMonthView] = useState<any>([]);
  const [disallowedDates, setDisallowedDates] = useState<any>([]);

  useEffect(() => {
    // Initial date has changed.
    // If null, then use todays date otherwise convert the initial date.
    if (initialDate) {
      var parsed = moment
        .utc(
          initialDate,
          dateFormat ? dateFormat : Constants.DEFAULT_DATEFORMAT
        )
        .toDate();
      setSelectedDate(parsed);
    } else {
      setSelectedDate(new Date());
    }
  }, [initialDate]);

  useEffect(() => {
    // Selected Date has changed, so update the current month view
    selectedDate &&
      (selectedDate.getMonth() !== currentMonth?.month ||
        selectedDate.getFullYear() !== currentMonth?.year) &&
      setCurrentMonth(getMonthFromDate(selectedDate));
  }, [selectedDate]);

  const addDays = (date: Date, days: number) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    result.setSeconds(0)
    return result;
    
  };

  const createDateObject = (date: Date): DateObject => {
    return {
      day: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
    };
  };

  const dateEqualsObject = (date: Date, object: DateObject) => {
    return (
      date.getDate() === object.day &&
      date.getMonth() === object.month &&
      date.getFullYear() === object.year
    );
  };

  useEffect(() => {
    if (currentMonth) {
      const weeks: Week[] = [],
        firstDate = new Date(currentMonth.year, currentMonth.month, 1),
        lastDate = new Date(currentMonth.year, currentMonth.month + 1, 0),
        numDays = lastDate.getDate();

      let dayOfWeekCounter = firstDate.getDay() - 1; // The -1 accounts for the first day of the week to be Monday, not Sunday

      for (let i = 1; i <= numDays; i++) {
        if (dayOfWeekCounter === 0 || weeks.length === 0) {
          weeks.push([]);
        }

        var date = addDays(firstDate, i - 1);
        weeks[weeks.length - 1].push(createDateObject(date));
        dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
      }

      // Prepend last month to the first week.
      if (weeks[0]?.length < 7) {
        var numberOfDaysToPrepend = 7 - weeks[0].length;

        for (var i = 1; i <= numberOfDaysToPrepend; i++) {
          var dateToPrepend = addDays(firstDate, -i);
          weeks[0].unshift(createDateObject(dateToPrepend));
        }
      }

      // Append next month to the last week
      var lastWeekIndex = weeks.length - 1;

      if (weeks[lastWeekIndex]?.length < 7) {
        var numberOfDaysToAppend = 7 - weeks[lastWeekIndex].length;

        for (var j = 1; j <= numberOfDaysToAppend; j++) {
          var dateToAdd = addDays(lastDate, j);
          weeks[lastWeekIndex].push(createDateObject(dateToAdd));
        }
      }
      setMonthView(weeks);

      let startDate = weeks?.[0]?.[0];
      let endDate = weeks?.[lastWeekIndex]?.[6];
      if (startDate && endDate) {
        onDateRangeChange?.(startDate, endDate);
      }
    }
  }, [currentMonth]);

  const setAndPropagateDate = (newDate: Date) => {
    setSelectedDate(newDate);
    onChange?.(newDate);
  };

  const moveNextMonth = () => {
    setCurrentMonth(
      currentMonth.month === 11
        ? { month: 0, year: (currentMonth.year += 1) }
        : {
            month: (currentMonth.month += 1),
            year: currentMonth.year,
          }
    );
  };

  const movePreviousMonth = () => {
    setCurrentMonth(
      currentMonth.month === 0
        ? { month: 11, year: (currentMonth.year -= 1) }
        : {
            month: (currentMonth.month -= 1),
            year: currentMonth.year,
          }
    );
  };

  const moveToday = () => {
    const newDate = new Date()
    newDate.setSeconds(0)
    setAndPropagateDate(newDate);
  };

  const moveYesterday = () => {
    setAndPropagateDate(addDays(new Date(), -1));
  };

  const moveTomorrow = () => {
    setAndPropagateDate(addDays(new Date(), 1));
  };

  const handleDaySelected = (date: DateObject) => {
    var newDate = new Date(Date.UTC(date.year, date.month, date.day));
    setAndPropagateDate(newDate);
  };

  const isDisallowed = (date: DateObject) => {
    const compareDates = (dateToCompare: any) => {
      return dateEqualsObject(new Date(dateToCompare), date);
    };

    const foundDissallowed = disallowedDates?.find((disallowedDate: any) =>
      compareDates(disallowedDate)
    );

    if (foundDissallowed === undefined) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    setDisallowedDates(disallowed);
  }, [disallowed]);

  return (
    <Wrapper className={`calendar ${className ? className : ""}`}>
      <div className="calendar-header">
        <button onClick={movePreviousMonth}>
          <Icon value="angle-left" />
        </button>
        <span>
          {moment(currentMonth?.month + 1, "MM").format("MMMM")}{" "}
          {currentMonth?.year}
        </span>
        <button onClick={moveNextMonth}>
          <Icon value="angle-right" />
        </button>
      </div>
      <div className="calendar-body">
        <table>
          <thead>
            <tr>
              <th>Mon</th>
              <th>Tue</th>
              <th>Wed</th>
              <th>Thu</th>
              <th>Fri</th>
              <th>Sat</th>
              <th>Sun</th>
            </tr>
          </thead>
          <tbody>
            {monthView.map((week: Week, index: number) => (
              <tr key={index}>
                {week.map((day: DateObject, index: number) => (
                  <Day
                    key={index}
                    onClick={() => {
                      !isDisallowed(day) && handleDaySelected(day);
                    }}
                    selected={dateEqualsObject(selectedDate, day)}
                    isToday={dateEqualsObject(new Date(), day)}
                    disallowedDay={isDisallowed(day)}
                  >
                    {day.day}
                  </Day>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        {loading && (
          <div className="calender-loading-spinner">
            <Spinner size={Size.Medium} />
          </div>
        )}
      </div>

      <div className="calendar-footer">
        <button onClick={moveYesterday}>Yesterday</button>
        <button onClick={moveToday}>Today</button>
        <button onClick={moveTomorrow}>Tomorrow</button>
      </div>
    </Wrapper>
  );
};

export default Calendar;
