import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Size,
  Swatches,
  ActionBar,
  Loader,
  Message,
  formatScrollbars,
  neutral,
} from "ui-kit";
import { useSelector } from "react-redux";
import styled from "styled-components";
import BulletinTestSendModal from "./bulletinTestSendModal";
import bulletinActions from "areas/news/actions/bulletinActions";
import { RootState } from "reducers/store";
import { BulletinDetailView, BulletinFullView, BulletinNotificationView } from "areas/news/types/newsResponse.types";


const PreviewWrapper = styled.div`
  flex-grow: 1;

  iframe {
    width: 99%;
    height: 99%;
    ${formatScrollbars(neutral[100])}
  }
`;

interface IBulletinPreviewModalProps {
  bulletin: BulletinDetailView | BulletinFullView, 
  open: boolean;
  onClose?: () => void;
}

const BulletinPreviewModal: React.FC<IBulletinPreviewModalProps> = ({ bulletin, open, onClose }) => {

  const { preview, actions } = useSelector((state: RootState) => state.bulletin);

  const [_open, _setOpen] = useState<boolean>(false);
  const [_testSendOpen, _setTestSendOpen] = useState<boolean>(false);

  useEffect(() => {
    _setOpen(open);

    if (open && bulletin) {
      bulletinActions.previewBulletin(bulletin as BulletinNotificationView);
    }
  }, [open, bulletin]);

  return (
    <Modal
      open={_open}
      onClose={onClose}
      title="Preview Bulletin"
      width="90%"
      height="90%"
    >
      <Modal.Body>
        {actions.preview.working ? (
          <Loader cover size={Size.Medium} />
        ) : actions.preview.error ? (
          <Message text={actions.preview.error} color={Swatches.Danger} />
        ) : (
          <PreviewWrapper>
            <iframe srcDoc={preview} title="Preview" />
          </PreviewWrapper>
        )}
        <BulletinTestSendModal
          bulletin={bulletin}
          open={_testSendOpen}
          onClose={() => _setTestSendOpen(false)}
          onSent={() => _setTestSendOpen(false)}
        />
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          {bulletin.status !== null && bulletin.status !== undefined && (
            <Button
              text="Test Send"
              onClick={() => _setTestSendOpen(true)}
              color={Swatches.Low}
              size={Size.Small}
            />
          )}
          <Button
            text="Close"
            onClick={onClose}
            color={Swatches.Low}
            size={Size.Small}
          />
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default BulletinPreviewModal;
