import constants from "configuration/constants";

const getSchoolById = (schoolId: number) => {  
  return constants.SCHOOLS.find(school => school.id == schoolId);
}

const methods = {
  getSchoolById
};

export default methods;
