import { IBeehiveError } from "types/common/errors.types";
import assignmentActions from "../../actions/assignments/assignmentActions";
import { IBeehiveAction } from "types/common/action.types";
import { GroupType } from "types/users/userGroups.types";


type SubmissionFilterType = {
  group: {
    id: number;
    type: GroupType | -1;
  },
  status: number
}

const SUBMISSION_FILTER: SubmissionFilterType = {
  group: { 
    id: -1,
    type: -1 
  },
  status: -1,
};

interface IAssignmentSubmissionsFilterState {
  loading: boolean;
  error: IBeehiveError;
  filter: SubmissionFilterType,
}

const INITIAL_STATE: IAssignmentSubmissionsFilterState = {
  loading: false,
  error: null,
  filter: SUBMISSION_FILTER,
};

const assignmentSubmissionsFilterReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAssignmentSubmissionsFilterState => {

  const {
    SUBMISSIONS_FILTERBYGROUP,
    SUBMISSIONS_FILTERBYSTATUS,
    GETASSIGNMENT,
  } = assignmentActions.types;

  switch (action.type) {
    case GETASSIGNMENT.SUCCESS:
      return INITIAL_STATE;

    case SUBMISSIONS_FILTERBYGROUP:
      return {
        ...state,
        filter: { ...state.filter, group: action.payload },
      };

    case SUBMISSIONS_FILTERBYSTATUS:
      return {
        ...state,
        filter: { ...state.filter, status: action.payload },
      };

    default:
      return state;
  }
};

export default assignmentSubmissionsFilterReducer;
