import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Orientation,
  typescale,
  displayFont,
  fontStyle,
  Checkbox,
  RadioButton,
  Spacing,
} from "../index";
import { ICheckboxClickEvent } from "./checkbox";
import { arrays } from "../../utils";

const CheckboxListWrapper = styled.div<{disabled?: boolean, fluid?: boolean, orientation?: Orientation}>`
  display: inline-flex;
  box-sizing: box-content;

  ${fontStyle(displayFont.medium, typescale.paragraph)}

  ${({ disabled }) =>
    disabled &&
    `opacity: 0.75;
    cursor: not-allowed;
`}

  ${({ fluid }) =>
    fluid &&
    `
    display: flex;
    width: 100%;
  `}

  ${({ orientation }) => {
    switch (orientation) {
      case Orientation.Landscape:
        return `
        flex-direction: row;`;
      case Orientation.Portrait:
        return `flex-direction: column;`;
    }
  }}
`;

const CheckboxListItemWrapper = styled.div<{checked: boolean}>`
  margin-right: ${Spacing.Large}px;
`;

export interface ICheckboxListProps<TValue> {
  children?: React.ReactElement<ICheckboxListItemProps<TValue>>[];
  items?: ICheckboxListItemProps<TValue>[];
  onChange?: (value: TValue[]) => void;
  orientation?: Orientation;
  fluid?: boolean;
  disabled?: boolean;
  radio?: boolean;
  group?: string;
  value?: TValue[];
}

const CheckboxList = <TValue = any>({
  children,
  items,
  value,
  onChange,
  orientation,
  fluid,
  disabled,
  radio,
  group,
}: ICheckboxListProps<TValue>) => {
  const [checkedValues, setCheckedValues] = useState<TValue[]>([]);

  useEffect(() => {
    value && setCheckedValues(value);
  }, [value]);

  const handleCheckboxItemChanged = (item: ICheckboxClickEvent<TValue>) => {
    if (!disabled) {
      var _checkedValues = checkedValues;

      if (!radio) {
        if (_checkedValues.some((x) => x === item.value)) {
          arrays.removeItem(_checkedValues, item.value);
        } else {
          _checkedValues.push(item.value);
        }
        setCheckedValues(_checkedValues);
      } else {
        _checkedValues = [];
        if (item.checked) {
          _checkedValues.push(item.value);
        }
        setCheckedValues(_checkedValues);
      }

      onChange?.(_checkedValues);
    }
  };

  return (
    <CheckboxListWrapper
      className="checkbox-list"
      fluid={fluid}
      disabled={disabled}
      orientation={orientation}
    >
      {children?.map((child, index) => {
        return React.cloneElement(
          child as React.ReactElement<ICheckboxListItemProps<typeof child.props.value>>,
          {
            key: index,
            checked: checkedValues.some((x) => x === child.props.value),
            onChange: handleCheckboxItemChanged,
            group: group,
            radio: radio,
          },
        );
      })}
    </CheckboxListWrapper>
  );
};

export interface ICheckboxListItemProps<TValue> {
  name?: string;
  value?: TValue;
  text: string;
  onChange?: (value: ICheckboxClickEvent<TValue>) => void;
  checked?: boolean;
  radio?: boolean;
  group?: string;
}

const CheckboxListItem = <TValue = null>({
  value,
  text,
  name,
  onChange,
  checked = false,
  group,
  radio,
}: ICheckboxListItemProps<TValue>) => {

  const handleChange = (e: ICheckboxClickEvent<TValue>) => {
    onChange?.(e);
  };

  return (
    <CheckboxListItemWrapper
      className={`checkbox-list-item  ${
        checked ? "checkbox-list-item-checked" : ""
      }`}
      checked={checked}
    >
      {!radio ? (
        <Checkbox
          name={name}
          text={text}
          value={value}
          checked={checked}
          onChange={handleChange}
        />
      ) : (
        <RadioButton
          name={name}
          text={text}
          value={value}
          checked={checked}
          onChange={handleChange}
          group={group}
        />
      )}
    </CheckboxListItemWrapper>
  );
};

CheckboxList.Item = CheckboxListItem;

export default CheckboxList;
