import React from "react";
import { Size, Loader, EmptyMessage, Swatches } from "ui-kit";
import { useNavigate } from "react-router-dom";
import { arrays } from "utils";
import ConsentFormTable from "./consentFormTable";
import { consentRoutes } from "../../adminRoutes";
import { ConsentFormListView } from "areas/administration/types/consentResponses.types";


interface ICreatedConsentFormsProps {
  forms: ConsentFormListView[];
  error?: string;
  loading?: boolean;
}


const CreatedConsentForms: React.FC<ICreatedConsentFormsProps> = ({ forms, error, loading }) => {

  const navigate = useNavigate();

  const createNewForm = () => {
    navigate(consentRoutes.newConsentForm);
  };

  const goToForm = (form: ConsentFormListView) => {
    navigate(consentRoutes.getConsentFormAdminPath(form.id));
  };

  if (loading) {
    return <Loader size={Size.Large} cover />;
  }

  if (arrays.isEmpty(forms)) {
    return (
      <EmptyMessage
        title="No forms available"
        summary="You have not created any consent forms yet"
        icon="clipboard-check"
        cover
      >
        {/* <Button
          text="Create a Consent Form"
          onClick={createNewForm}
          color={Swatches.Primary}
        /> */}
      </EmptyMessage>
    );
  }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem while loading the consent forms"
        icon="times-circle"
        iconColor={Swatches.Danger.swatch}
        cover
      />
    );
  }

  return <ConsentFormTable forms={forms} onClick={goToForm} />;
};

export default CreatedConsentForms;
