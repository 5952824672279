import React, { useRef, useState } from "react";
import { Modal, Button, ActionBar } from "ui-kit";
import { Swatches } from "ui-kit/common/colors";
import { Size } from "ui-kit";
import AssignmentEditor, { IAssignmentEditorRef } from "../assignments/assignmentEditor";
import { StudentListView } from "types/users/userListViews.types";


interface IConfirmModalProps {
  open?: boolean;
  students: StudentListView[];
  onClose: () => void;
}

//TODO: Move to assignmentEditor when that is converted to TS



const AssignmentModal: React.FC<IConfirmModalProps> = ({
  open,
  students,
  onClose,
}) => {

  const assignmentEditorRef = useRef<IAssignmentEditorRef>();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Modal
      onClose={onClose}
      open={open}
      title="New Assignment"
      height="95%"
      width="75%"
    >
      <Modal.Body>
        <AssignmentEditor
          contentOnly={true} 
          students={students}
          ref={assignmentEditorRef}
        />
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Button
            color={Swatches.Primary}
            text="Save as Draft"
            size={Size.Small}
            onClick={e => {
              assignmentEditorRef.current.saveAsDraft();
              e.stopPropagation();
            }}
            working={loading}
          />
          <Button
            color={Swatches.Success}
            text="Save and Publish"
            size={Size.Small}
            onClick={e => {
              assignmentEditorRef.current.saveAndPublish();
              e.stopPropagation();
            }}
            working={loading}
          />
          <Button
            color={Swatches.Low}
            text="Cancel"
            size={Size.Small}
            onClick={e => {
              onClose();
              e.stopPropagation();
            }}
            working={loading}
          />
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignmentModal;
