import React, { useEffect, useState } from "react";
import { Checkbox } from "ui-kit";
import { DetentionType } from "./behaviourAnalyticsForm";


interface IDetentionTypeCheckboxProps {
  detentionType: DetentionType;
  selectedDetentionTypes: DetentionType[];
  handleDetentionTypeClick: (detentionType: DetentionType, isChecked: boolean) => void;
}

const DetentionTypeCheckbox: React.FC<IDetentionTypeCheckboxProps> = ({
  detentionType,
  selectedDetentionTypes,
  handleDetentionTypeClick,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    const foundSenType = selectedDetentionTypes.find(
      type => type.detentionTypeId === detentionType.detentionTypeId
    );

    if (foundSenType !== undefined) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [selectedDetentionTypes]);

  const handleCheckboxChange = () => {
    const newIsChecked = !isChecked;
    handleDetentionTypeClick(detentionType, newIsChecked);
    setIsChecked(newIsChecked);
  };

  return (
    <Checkbox
      text={detentionType.detentionTypeName}
      checked={isChecked}
      onChange={handleCheckboxChange}
    />
  );
};


interface IDetentionTypeFiltersProps {
  detentionTypes: DetentionType[];
  selectedDetentionTypes: DetentionType[]
  handleDetentionTypeClick: (detentionType: DetentionType, isChecked: boolean) => void;
}

const DetentionTypeFilters: React.FC<IDetentionTypeFiltersProps> = ({
  detentionTypes,
  selectedDetentionTypes,
  handleDetentionTypeClick,
}) => {
  return (
    <>
      {detentionTypes.map(type => (
        <DetentionTypeCheckbox
          detentionType={type}
          selectedDetentionTypes={selectedDetentionTypes}
          handleDetentionTypeClick={handleDetentionTypeClick}
        />
      ))}
    </>
  );
};

export default DetentionTypeFilters;
