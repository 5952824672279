import { Routes, Route } from "react-router-dom";
import { Constants } from "configuration";
import CustomRoute from "sharedComponents/layout/routes/customRoute";
import Shopkeeper from "./shopkeeper";
import ProductEditor from "./products/productEditor";
import ProductViewer from "./products/productViewer";
import UserViewer from "./users/userViewer";
import Order from "./orders/order";
import Reconciliation from "./reconciliations/reconciliation";
import Search from "./search/search";
import ProductSearch from "./search/productSearch";


const Index = () => {

  return (
    <>
      <Routes>

        <Route
          index
          element={
            <CustomRoute
              roles={[
                Constants.ROLES.SHOPKEEPER_USER,
                Constants.ROLES.SHOPKEEPER_ADMINISTRATOR,
                Constants.ROLES.FINANCE_BUYER
              ]}>
                <Shopkeeper />
            </CustomRoute>
          }/>

        <Route
          path="/category/:categoryId/products/new"
          element={
            <CustomRoute
              roles={[
                Constants.ROLES.SHOPKEEPER_USER,
                Constants.ROLES.SHOPKEEPER_ADMINISTRATOR,
              ]}>
                <ProductEditor />
            </CustomRoute>
          }/>

        <Route
          path="/category/:categoryId/products/:productId"
          element={
            <CustomRoute
              roles={[
                Constants.ROLES.SHOPKEEPER_USER,
                Constants.ROLES.SHOPKEEPER_ADMINISTRATOR,
              ]}>
                <ProductViewer />
            </CustomRoute>
          }/>

        <Route
          path="/category/:categoryId/products/:productId/edit"
          element={
            <CustomRoute
              roles={[
                Constants.ROLES.SHOPKEEPER_USER,
                Constants.ROLES.SHOPKEEPER_ADMINISTRATOR,
              ]}>
                <ProductEditor />
            </CustomRoute>
          }/>

        <Route
          path="/users/:userId"
          element={
            <CustomRoute
              roles={[
                Constants.ROLES.SHOPKEEPER_USER,
                Constants.ROLES.SHOPKEEPER_ADMINISTRATOR,
                Constants.ROLES.FINANCE_BUYER
              ]}>
                <UserViewer />
            </CustomRoute>
          }/>

        <Route
          path="/users/:userId/orders/:orderId"
          element={
            <CustomRoute
              roles={[
                Constants.ROLES.SHOPKEEPER_USER,
                Constants.ROLES.SHOPKEEPER_ADMINISTRATOR,
              ]}>
                <Order />
            </CustomRoute>
          }/>

        <Route
          path="/reconciliations/:reconciliationId"
          element={
            <CustomRoute
              roles={[
                Constants.ROLES.SHOPKEEPER_USER,
                Constants.ROLES.SHOPKEEPER_ADMINISTRATOR,
              ]}>
                <Reconciliation />
            </CustomRoute>
          }/>

        <Route
          path="/search"
          element={
            <CustomRoute
              roles={[
                Constants.ROLES.SHOPKEEPER_USER,
                Constants.ROLES.SHOPKEEPER_ADMINISTRATOR,
                Constants.ROLES.FINANCE_BUYER
              ]}>
                <Search />
            </CustomRoute>
          }/>

        <Route
          path="/productSearch"
          element={
            <CustomRoute
              roles={[
                Constants.ROLES.SHOPKEEPER_USER,
                Constants.ROLES.SHOPKEEPER_ADMINISTRATOR,
                Constants.ROLES.FINANCE_BUYER
              ]}>
                <ProductSearch />
            </CustomRoute>
          }/>

      </Routes>
    </>
  );
};

export default Index;
