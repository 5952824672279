import adminDeclarationReducer from "./adminDeclarationReducer";
import adminDeclarationsFilterReducer from "./adminDeclarationsFilterReducer";
import adminDeclarationsReducer from "./adminDeclarationsReducer";
import declarationOfInterestReducer from "./declarationOfInterestReducer";
import declarationsOfInterestReducer from "./declarationsOfInterestReducer";


const declarations = {
  declarationsOfInterest: declarationsOfInterestReducer,
  declarationOfInterest: declarationOfInterestReducer,
  adminDeclarationsFilter: adminDeclarationsFilterReducer,
  adminDeclarations: adminDeclarationsReducer,
  adminDeclaration: adminDeclarationReducer
};

export default declarations;
