import React from "react";
import PropTypes from "prop-types";
import LayoutGeneratorOption from "./layoutGeneratorOption";
import { LAYOUT_TYPES } from "areas/planner/constants/classViewer/classViewerLayout";

const LayoutGeneratorOptions = ({
  selectedLayoutType,
  onSelectLayoutType,
  orderValue,
  handleOrderChange,
  disperseValue,
  handleDisperseChange,
  children,
}) => {
  return (
    <>
      {LAYOUT_TYPES.map(layoutType => (
        <LayoutGeneratorOption
          key={layoutType.key}
          layout={layoutType}
          selectedLayoutId={selectedLayoutType}
          onSelectLayoutType={onSelectLayoutType}
          orderValue={orderValue}
          handleOrderChange={handleOrderChange}
          disperseValue={disperseValue}
          handleDisperseChange={handleDisperseChange}
        />
      ))}
      {children}
    </>
  );
};

LayoutGeneratorOptions.propTypes = {
  selectedLayoutType: PropTypes.number,
  onSelectLayoutType: PropTypes.func,
  orderValue: PropTypes.number,
  disperseValue: PropTypes.number,
  setOrderValue: PropTypes.func,
  setDisperseValue: PropTypes.func,
  children: PropTypes.node,
};

export default LayoutGeneratorOptions;
