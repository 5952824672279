import React, { useRef } from "react";
import styled from "styled-components";
import {
  IUiKitBaseProps,
  displayFont,
  Size,
  Swatches,
  Spacing,
  typescale,
  BorderRadius,
  Badge,
  Icon,
  fontStyle,
  neutral,
  Speed,
  Tooltip,
} from "../index";
import Tippy from "@tippyjs/react";
import { ColorSwatch } from "../common/colors";

export interface IChipProps extends IUiKitBaseProps {
  text: string | number | JSX.Element;
  colorSwatch?: ColorSwatch;
  badge?: number | null;
  icon?: string;
  hoverBackground?: string;
  schoolIcon?: React.ReactElement;
  rightIcon?: string;
  tooltip?: string | React.ReactNode;
  tooltipSub?: string;
  fluid?: boolean;
  size?: Size;
  style?: any;
}

const Wrapper = styled.span<{
  fluid?: boolean;
  swatch?: ColorSwatch;
  hasOnClick?: boolean;
  hoverBackground?: string;
  badge?: number | null;
}>`
  box-sizing: border-box;
  display: inline-flex;
  width: ${props => (props.fluid ? "100%" : "auto")};

  align-items: flex-start;
  text-align: center;
  justify-content: center;

  background: ${props => props.swatch?.swatch};
  ${({ swatch }) =>
    fontStyle(displayFont.medium, typescale.paragraph, swatch?.foreground)}
  border-radius: ${BorderRadius.Default}px;
  padding: ${Spacing.ExtraSmall}px ${Spacing.Default}px;

  .badge {
    margin-left: ${Spacing.Small}px;
  }

  .left-icon,
  .right-icon {
    ${({ swatch }) =>
      swatch ? `color: ${swatch?.foreground}` : `color: ${neutral[100]}`}
  }

  .left-icon {
    margin-right: ${Spacing.Large}px;
  }

  .right-icon {
    margin-left: ${Spacing.Large}px;
  }

  ${({ hasOnClick, hoverBackground }) =>
    hasOnClick &&
    `
    &:hover{
      background: ${
        hoverBackground ? hoverBackground : Swatches.Primary.swatch
      };
      color: ${neutral[100]};
      cursor: pointer;
      transition: all ${Speed.Default}ms ease;
    }
  `}
`;

const SchoolIcon = styled.div`
  line-height: 0;
  margin-right: 4px;
`;

const Chip: React.FC<IChipProps> = ({
  text,
  colorSwatch,
  badge,
  onClick,
  icon,
  schoolIcon,
  rightIcon,
  hoverBackground,
  className,
  style,
  tooltip,
  tooltipSub,
  fluid,
  size,
}) => {
  const ref = useRef() as React.RefObject<HTMLSpanElement>;

  const handleClick = () => {
    onClick?.();
  };

  return text != null ? (
    <>
      <Wrapper
        style={style}
        ref={ref}
        className={`chip ${className ? className : ""}`}
        swatch={colorSwatch}
        badge={badge}
        hasOnClick={onClick != null}
        onClick={handleClick}
        hoverBackground={hoverBackground}
        fluid={fluid}
      >
        {icon && (
          <Icon
            className="left-icon"
            value={icon}
            size={size ? size : Size.Small}
          />
        )}
        {schoolIcon && <SchoolIcon>{schoolIcon}</SchoolIcon>}
        <span>{text}</span>
        {rightIcon && <Icon className="right-icon" value={rightIcon} />}
        {badge && <Badge value={badge} />}
      </Wrapper>
      {tooltip != null && (
        <Tippy
          reference={ref}
          content={<Tooltip title={tooltip} sub={tooltipSub} />}
        />
      )}
    </>
  ) : null;
};

Chip.defaultProps = {
  colorSwatch: Swatches.Default,
};

export default Chip;
