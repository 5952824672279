import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "../../../services/client";
import { FormDetailView } from "../types/formResponse.types";
import { callbackType } from "types/common/action.types";
import { AddUserNoteCommand } from "../types/formRequest.types";

const builder = new actionTypeBuilder("form");

const types = {
  GETFORM: builder.build("getform"),
  UPDATENOTES: builder.build("updateNotes"),
};


const getForm = (categoryId: number, formId: number, callback?: callbackType<FormDetailView>) =>
  action<FormDetailView>(
    () => client.get(`forms/categories/${categoryId}/forms/${formId}`),
    types.GETFORM,
    callback
  );

const updateNotes = (submissionId: number, data: AddUserNoteCommand, callback?: callbackType<string>) => {
  return action<string>(
    () => client.post(`forms/submissions/${submissionId}/usernotes`, data),
    types.UPDATENOTES,
    callback
  );
};


const formActions = {
  types,
  getForm,
  updateNotes,
};

export default formActions;
