import { IBeehiveAction } from "types/common/action.types";
import consentActions from "../../actions/consentActions";
import { ConsentFormDetailView } from "areas/administration/types/consentResponses.types";


interface IAdminConsentFormState {
  loading: boolean;
  form: ConsentFormDetailView | null;
  error: string | null;
}

const CONSENT_STATE: IAdminConsentFormState = {
  loading: false,
  form: null,
  error: null,
};


const adminConsentFormReducer = (state = CONSENT_STATE, action: IBeehiveAction) : IAdminConsentFormState => {

  const { CONSENT_GETCONSENTFORMFORADMIN } = consentActions.types;

  switch (action.type) {
    case CONSENT_GETCONSENTFORMFORADMIN.START:
      return { ...state, loading: true, error: null };
    case CONSENT_GETCONSENTFORMFORADMIN.SUCCESS:
      return { ...state, loading: false, form: action.payload, error: null };
    case CONSENT_GETCONSENTFORMFORADMIN.FAILED:
      return {
        ...state,
        loading: false,
        form: null,
        error: "There was a problem loading the consent form",
      };

    default:
      return state;
  }
};

export default adminConsentFormReducer;
