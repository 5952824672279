import studentAwardActions from "../../actions/student/studentAwardActions";

const INITIAL_STATE = {
  studentAwardCategories: [],
  studentAwards: [],
  loading: false,
  error: null,
};

const {
  GETSTUDENTAWARDCATEGORIES,
  GETSTUDENTAWARDS,
  ADDSTUDENTAWARD,
  DELETESTUDENTAWARD,
} = studentAwardActions.types;

const studentAwardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GETSTUDENTAWARDCATEGORIES.START:
      return { ...state, loading: true, error: null };

    case GETSTUDENTAWARDCATEGORIES.SUCCESS:
      return {
        ...state,
        loading: false,
        studentAwardCategories: action.payload,
      };

    case GETSTUDENTAWARDCATEGORIES.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GETSTUDENTAWARDS.START:
      return { ...state, loading: true, error: null };

    case GETSTUDENTAWARDS.SUCCESS:
      return {
        ...state,
        loading: false,
        studentAwards: action.payload,
      };

    case GETSTUDENTAWARDS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADDSTUDENTAWARD.START:
      return { ...state, loading: true, error: null };

    case ADDSTUDENTAWARD.SUCCESS:
      return { ...state, loading: false };

    case ADDSTUDENTAWARD.FAILED:
      return { ...state, loading: false, error: action.payload };

    case DELETESTUDENTAWARD.START:
      return { ...state, loading: true, error: null };

    case DELETESTUDENTAWARD.SUCCESS:
      return { ...state, loading: false };

    case DELETESTUDENTAWARD.FAILED:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default studentAwardReducer;
