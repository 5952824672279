import { IBeehiveAction } from "types/common/action.types";
import yearGroupReportActions from "../actions/yearGroupReportActions";
import defaults from "reducers/defaults";
import { PageInfo } from "types/common/paging.types";
import { StudentFeedListView } from "types/planner/feedResponse.types";
import { IBeehiveError } from "types/common/errors.types";


interface IYearGroupReportState {
  report: StudentFeedListView[] | null;
  paging: PageInfo;
  refresh: boolean;
  loading: boolean;
  loadingExportReport: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IYearGroupReportState = {
  report: null,
  paging: defaults.DEFAULT_PAGING,
  refresh: false,
  loading: false,
  loadingExportReport: false,
  error: null,
};


const yearGroupReportReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IYearGroupReportState => {

  const { 
    GETYEARGROUPREPORT, 
    EXPORTYEARGROUPREPORT 
  } = yearGroupReportActions.types;

  switch (action.type) {
    case GETYEARGROUPREPORT.START:
      return { ...state, loading: true, error: null };

    case GETYEARGROUPREPORT.SUCCESS:
      return {
        ...state,
        report:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.report, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };

    case GETYEARGROUPREPORT.FAILED:
      return { ...state, loading: false, error: action.payload };

    case EXPORTYEARGROUPREPORT.START:
      return { ...state, loadingExportReport: true, error: null };

    case EXPORTYEARGROUPREPORT.SUCCESS:
      return { ...state, loadingExportReport: false };

    case EXPORTYEARGROUPREPORT.FAILED:
      return { ...state, loadingExportReport: false, error: action.payload };

    default:
      return state;
  }
};

export default yearGroupReportReducer;
