import { StructuredList } from "ui-kit";
import { IFormsReportProps } from ".";
import { SchoolDropdown } from "sharedComponents/common";
import AcademicYearFilter from "sharedComponents/common/academicYearFilter";

const DeclarationsOfInterestReport: React.FC<IFormsReportProps> = ({ filter, setFilter }) => {

  return (
    <>
      <StructuredList.Item name="School">
        <SchoolDropdown
          initialValue={filter.schoolId}
          onChange={value =>
            setFilter({ ...filter, schoolId: value })
          }
          includeTrust
          fluid
        />
      </StructuredList.Item>
      <StructuredList.Item name="Academic Year">
        <AcademicYearFilter
          value={filter?.academicYearId}
          onChange={value => setFilter({...filter, academicYearId: value})}
        />
      </StructuredList.Item>
    </>
  )
}

export default DeclarationsOfInterestReport;