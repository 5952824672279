import detentionActions from "areas/behaviour/actions/detentions/detentionActions";
import { DetentionStudentSummaryView } from "areas/behaviour/types/behaviourResponses.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


interface IDetentionSummaryState {
  detentionSummary: DetentionStudentSummaryView[];
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IDetentionSummaryState = {
  detentionSummary: [],
  loading: false,
  error: null,
};

const { GETDETENTIONSUMMARY } = detentionActions.types;


const detentionSummaryReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IDetentionSummaryState => {

  switch (action.type) {
    case GETDETENTIONSUMMARY.START:
      return { ...state, loading: true, error: null };

    case GETDETENTIONSUMMARY.SUCCESS:
      return {
        ...state,
        loading: false,
        detentionSummary: action.payload,
      };

    case GETDETENTIONSUMMARY.FAILED:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default detentionSummaryReducer;
