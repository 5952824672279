import React from "react";
import { Label } from "ui-kit";


interface IEmailProps {
  email: string;
}

const Email: React.FC<IEmailProps> = ({ email }) => {
  return (
    <Label>
      <a href={`mailto:${email.toLowerCase()}`}>{email.toLowerCase()}</a>
    </Label>
  );
};

export default Email;
