import action from "actions/action";
import { ToastService } from "ui-kit";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { Constants } from "configuration";
import client from "services/client";

const builder = new actionTypeBuilder("studentQuiz");

const types = {
  GETSTUDENTANSWERS: builder.build("getstudentanswers"),
  SAVESTUDENTANSWER: builder.build("addstudentanswer"),
  DELETESTUDENTANSWER: builder.build("deletestudentanswer"),
};

const getGroupQueryString = (groupId, groupType) => {
  switch (groupType) {
    case Constants.GROUP_TYPES.CLASS:
      return `classGroupId=${groupId}`;

    case Constants.GROUP_TYPES.PROVISION:
      return `provisionGroupId=${groupId}`;

    default:
      return;
  }
};

const getStudentAnswers = ( //StudentAnswerListView[]
  studentId,
  groupId,
  groupType,
  teacherId,
  onSuccess
) => {
  let url = `planner/students/${studentId}/answers?${getGroupQueryString(
    groupId,
    groupType
  )}`;
  if (teacherId) {
    url += `&teacherId=${teacherId}`;
  }

  return action(() => client.get(url), types.GETSTUDENTANSWERS, onSuccess);
};

const saveStudentAnswer = (newAnswer, onSuccess) => { //SaveStudentAnswerCommand
  return action(
    () => client.post(`planner/studentAnswer`, newAnswer),
    types.SAVESTUDENTANSWER,
    response => {
      ToastService.pop("Answer successfully saved", null, "check-circle");
      onSuccess?.(response);
    }
  );
};

const deleteStudentAnswer = (studentId, answerId, onSuccess) => {
  return action(
    () => client.delete(`planner/students/${studentId}/answers/${answerId}`),
    types.DELETESTUDENTANSWER,
    response => {
      ToastService.pop("Answer successfully deleted", null, "check-circle");
      onSuccess?.(response);
    }
  );
};

const studentQuizActions = {
  types,
  getStudentAnswers,
  saveStudentAnswer,
  deleteStudentAnswer,
};

export default studentQuizActions;
