import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import {
  EmptyMessage,
  Button,
  Swatches,
  Size,
  Loader,
  DateTime,
  DetailLabel,
  Table,
  SplitButton,
} from "ui-kit";
import { arrays } from "utils";
import { PublishStatus, StaffView } from "sharedComponents/common";
import routes from "configuration/routes";
import { eventRoutes } from "../../calendarRoutes";
import { PageInfo } from "types/common/paging.types";
import { CalendarEventAppListView, CalendarEventListView } from "areas/calendar/types/calendarResponses.types";


interface IEventsTableProps {
  loading?: boolean;
  events: CalendarEventListView[];
  paging?: PageInfo;
  handlePage?: () => void;
  error?: string;
  onCreateClicked?: () => void;
  onEventClicked?: () => void;
}

const EventsTable: React.FC<IEventsTableProps> = ({
  loading,
  events,
  paging,
  handlePage,
  error,
  onCreateClicked,
  onEventClicked,
}) => {

  const navigate = useNavigate();

  const handleCreateEventClicked = () => {
    navigate(`${routes.home}/calendar${eventRoutes.new}`);
  };
  const handleEditEventClicked = (event: CalendarEventListView) => {
    navigate(eventRoutes.getEditPath(event.id));
  };

  const handleEventClicked = (event: CalendarEventListView) => {
    navigate(eventRoutes.getEventPath(event.id));
  };

  if (loading && arrays.isEmpty(events)) {
    return <Loader size={Size.Large} cover />;
  }

  // if (arrays.isEmpty(events)) {
  //   return (
  //     <EmptyMessage
  //       icon="calendar"
  //       title="No Events Found"
  //       description="hi"
  //       cover
  //     >
  //       <StaffView>
  //         <Button
  //           color={Swatches.Primary}
  //           text="Create New Event"
  //           size={Size.Small}
  //           onClick={handleCreateEventClicked}
  //         />
  //       </StaffView>
  //     </EmptyMessage>
  //   );
  // }

  if (error) {
    return (
      <EmptyMessage
        title="An error occured"
        summary="There was a problem while loading your events"
        icon="times-circle"
        cover
      />
    );
  }

  return (
    <Table
      zebra
      grow
      loading={loading}
      error={error != null}
      empty={arrays.isEmpty(events)}
      emptyMessage={
        <EmptyMessage
          icon="calendar"
          title="No Events Found"
          // description="hi"
          cover
        >
          <StaffView>
            <Button
              color={Swatches.Primary}
              text="Create New Event"
              size={Size.Small}
              onClick={handleCreateEventClicked}
            />
          </StaffView>
        </EmptyMessage>
      }
    >
      <Table.Header>
        <Table.HeaderCell width={1}>Status</Table.HeaderCell>
        <Table.HeaderCell width={3}>Event</Table.HeaderCell>
        <Table.HeaderCell width={1}>Dates</Table.HeaderCell>
        <Table.HeaderCell width={2}>Next Date</Table.HeaderCell>
        <Table.HeaderCell width={2} right />
      </Table.Header>
      <Table.Body onPage={handlePage} paging={paging}>
        {events.map((event: CalendarEventListView, index: number) => (
          <Table.Row key={index}>
            <Table.Cell width={1}>
              <PublishStatus status={event.status} />
            </Table.Cell>
            <Table.Cell width={3}>
              <DetailLabel label={event.title} sub={event.category.name} bold />
            </Table.Cell>
            <Table.Cell width={1}>{event.dateCount}</Table.Cell>
            <Table.Cell width={2}>
              {event.nextDate ? (
                <DateTime date={event.nextDate.starts} bold />
              ) : null}
            </Table.Cell>
            <Table.Cell width={2} right>
              <SplitButton
                size={Size.Small}
                text="View"
                color={Swatches.Default}
                onDefaultClick={() => {
                  handleEventClicked(event);
                }}
              >
                <SplitButton.Option
                  onClick={() => {
                    handleEventClicked(event);
                  }}
                  text="View"
                  show={true}
                />
                <SplitButton.Option
                  onClick={() => handleEditEventClicked(event)}
                  text="Edit"
                  show={true}
                />
              </SplitButton>
            </Table.Cell>
          </Table.Row>
        ))}
        {/* {loading && !arrays.isEmpty(events) && (
          <Table.Row>
            <Table.Cell width={10}>
              <Loader size={Size.Medium} />
            </Table.Cell>
          </Table.Row>
        )} */}
      </Table.Body>
    </Table>

    // <List>
    //   {events.map((event, index) => (
    //     <List.Item
    //       key={index}
    //       onClick={() => handleEventClick(event)}
    //       text={event.title}
    //       sub={<DateRange start={event.startDate} end={event.endDate} />}
    //       left={<PrettyDate date={event.startDate} />}
    //       right={
    //         event.statusId != Constants.PUBLISH_STATUS.LIVE.value ? (
    //           <PublishStatus status={event.publishStatus} />
    //         ) : event.requireSignup ? (
    //           event.attendeeCount
    //         ) : null
    //       }
    //     />
    //   ))}
    // </List>
  );
};

export default EventsTable;
