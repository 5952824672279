// import Browser from "./components/layout/browser";
import { Provider } from "react-redux";
import store from "./reducers/store";
import "react-toastify/dist/ReactToastify.min.css";
import {
  HashRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import CustomRoute from "./sharedComponents/layout/routes/customRoute";
import Main from "./sharedComponents/layout/main";
import Login from "./areas/login/login";
import Unauthorized from "./sharedComponents/layout/fallback/unauthorized";
import CheckoutSuccess from "areas/payments/components/store/checkout/success";
import CheckoutNotAuthorised from "areas/payments/components/store/notifications/notAuthorised";
import CheckoutError from "areas/payments/components/store/notifications/error";
import CheckoutCancelled from "areas/payments/components/store/notifications/cancelled";
import Register from "./areas/login/registration";
// import PaymentScreen from "./components/areas/store/paymentScreen";
import CheckPasswordPublic from "./areas/administration/components/passwords/checkPassword";
import ChangePasswordPublic from "./areas/administration/components/passwords/changePassword";
import ForgotPasswordPublic from "./areas/administration/components/passwords/forgotPassword";
// import ErrorFallback from "./components/layout/fallback/errorFallback";
//import * as Sentry from "@sentry/react";
import BuildIcons from "./configuration/icons";
import routes from "./configuration/routes";
import ClassLayoutPrintView from "./areas/planner/components/groups/classViewer/printView/classLayoutPrintView";
import WhiteboardViewWrapper from "./areas/planner/components/groups/primarySchool/whiteboardViewWrapper";
import { groupRoutes } from "./areas/planner/plannerRoutes";
import config from "configuration/config";

function App() {
  BuildIcons();

  return (
    <>
      {/* <Sentry.ErrorBoundary fallback={ErrorFallback}> */}
      {/* <Browser> */}
      <Provider store={store}>
        <Router>
          <MainSwitch />
        </Router>
      </Provider>
      {/* </Browser> */}
      {/* </Sentry.ErrorBoundary> */}
    </>
  );
}

const MainSwitch = () => {
  const location = useLocation();

  return (
    <>
      <Routes location={location}>
        <Route path="/" element={<CustomRoute redirectTo={routes.home} />} />
        <Route
          path={`${routes.home}/*`}
          element={
            <CustomRoute>
              <Main />
            </CustomRoute>
          }
        />

        <Route
          path={routes.authentication.login}
          element={
            <CustomRoute noAuth redirectIfAuthenticated>
              <Login />
            </CustomRoute>
          }
        />

        <Route
          path={routes.fallback.unauthorized}
          element={
            <CustomRoute>
              <Unauthorized />
            </CustomRoute>
          }
        />

        <Route
          path={routes.passwords.public.forgotPassword}
          element={
            <CustomRoute noAuth redirectIfAuthenticated>
              <ForgotPasswordPublic />
            </CustomRoute>
          }
        />

        <Route
          path={routes.passwords.public.resetPassword}
          element={
            <CustomRoute noAuth redirectIfAuthenticated>
              <ChangePasswordPublic />
            </CustomRoute>
          }
        />
        <Route
          path={routes.passwords.public.checkPassword}
          element={
            <CustomRoute noAuth>
              <CheckPasswordPublic />
            </CustomRoute>
          }
        />
        <Route
          path={`${routes.registration.default}/*`}
          element={
            <CustomRoute noAuth redirectIfAuthenticated>
              <Register />
            </CustomRoute>
          }
        />

        <Route
          path={groupRoutes.printClassLayout}
          element={
            <CustomRoute>
              <ClassLayoutPrintView />
            </CustomRoute>
          }
        />

        <Route
          path={groupRoutes.whiteboardView}
          element={
            <CustomRoute>
              <WhiteboardViewWrapper />
            </CustomRoute>
          }
        />

        {/* <Route
          path={routes.payment.cardnet}
          element={
            <CustomRoute noAuth>
              <PaymentScreen />
            </CustomRoute>
          }
        /> */}

        <Route
          path={`${config.payments.SAGEPAY_NOTIFICATION_SUCCESS_URL}`}
          element={<CheckoutSuccess />}
          // title="Store"
        />

        <Route
          path={`${config.payments.SAGEPAY_NOTIFICATION_NOTAUTHORISED_URL}`}
          element={<CheckoutNotAuthorised />}
          // title="Store"
        />

        <Route
          path={`${config.payments.SAGEPAY_NOTIFICATION_ERROR_URL}`}
          element={<CheckoutError />}
          // title="Store"
        />

        <Route
          path={`${config.payments.SAGEPAY_NOTIFICATION_CANCELLED_URL}`}
          element={<CheckoutCancelled />}
          // title="Store"
        />
      </Routes>
    </>
  );
};

export default App;
