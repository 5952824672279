import React, { useState, useEffect } from "react";
import {
  DetailLabel,
  fontStyle,
  displayFont,
  typescale,
  Spacing,
  Size,
  List,
  SplitButton,
  Icon,
  Swatches,
  neutral,
  Chip,
} from "ui-kit";
import styled from "styled-components";
import { honeygold } from "ui-kit/common/colors";
import { todayDateTime } from "utils/dateTime";
import moment from "moment";
import { DETENTION_STATUS_KEYS } from "areas/behaviour/constants/detentions";
import ActivityDate from "areas/planner/components/activityFeed/activityDate";
import { DetentionDetailView } from "areas/behaviour/types/detentionRegisterResponse.types";


const DetentionItem = styled.div`
  display: flex;
  align-items: center;

  .detail-label {
    margin-bottom: ${Spacing.Small}px;
  }

  .detention-details {
    ${fontStyle(displayFont.roman, typescale.paragraph, neutral[700])}
    margin-left: ${Spacing.Medium}px;
  }
`;

const SanctionActions = styled.div<{ expanded?: boolean }>`
  .split-button {
    ${({ expanded }) =>
      expanded &&
      `
    background-color: ${honeygold} !important;

    `}
  }
`;

const DetentionStatus = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 64px;

  .icon {
    margin: ${Spacing.Small}px;
    color: ${({ color }) => color};
  }

  .chip {
    font-size: 0.6rem;
    padding: 0 4px;
  }
`;


interface IDetentionListItemProps {
  detention: DetentionDetailView;
  markPresent: (behaviourRewardId: number, detentionId: number) => void;
  markNotPresent: (behaviourRewardId: number, detentionId: number) => void;
  handleRescheduleDetention: (detention: DetentionDetailView) => void;
  handleClickDetention: (detention: DetentionDetailView) => void;
}


const DetentionListItem: React.FC<IDetentionListItemProps> = ({
  detention,
  markPresent,
  markNotPresent,
  handleRescheduleDetention,
  handleClickDetention,
}) => {

  const [isPresent, setIsPresent] = useState(null);
  const [icon, setIcon] = useState({
    value: "clock",
    color: Swatches.Default,
    tooltip: "Issued",
    tooltipSub: "The detention has been issued",
  });

  useEffect(() => {
    switch (detention.statusId) {
      case DETENTION_STATUS_KEYS.ISSUED:
        setIsPresent(null);
        break;

      case DETENTION_STATUS_KEYS.ATTENDED:
        setIsPresent(true);
        break;

      case DETENTION_STATUS_KEYS.MISSED:
        setIsPresent(false);
        break;

      default:
        setIsPresent(false);
    }
  }, []);

  useEffect(() => {
    if (detention.statusId === DETENTION_STATUS_KEYS.CANCELLED) {
      setIcon({
        value: "ban",
        color: Swatches.Default,
        tooltip: "Cancelled",
        tooltipSub: "The detention has been cancelled",
      });
    } else if (detention.statusId === DETENTION_STATUS_KEYS.RESCHEDULED) {
      setIcon({
        value: "history",
        color: Swatches.Default,
        tooltip: "Rescheduled",
        tooltipSub: "The detention has been rescheduled",
      });
    } else {
      switch (isPresent) {
        case true:
          setIcon({
            value: "check-circle",
            color: Swatches.Success,
            tooltip: "Attended",
            tooltipSub: "The detention was attended",
          });
          break;

        case false:
          setIcon({
            value: "times-circle",
            color: Swatches.Danger,
            tooltip: "Missed",
            tooltipSub: "The detention was missed",
          });
          break;

        default:
          setIcon({
            value: "clock",
            color: Swatches.Blue,
            tooltip: "Issued",
            tooltipSub: "The detention attendance has not been recorded yet",
          });
      }
    }
  }, [isPresent, detention.statusId]);

  const onMarkPresent = () => {
    markPresent(detention.behaviourRewardId, detention.detentionId);
    setIsPresent(true);
  };

  const onMarkNotPresent = () => {
    markNotPresent(detention.behaviourRewardId, detention.detentionId);
    setIsPresent(false);
  };

  const changeAttendance = () => {
    if (
      window.confirm(
        `Are you sure you want to change the attendance to ${
          isPresent ? "Not Present" : "Present"
        }? A notification will be sent to the student's parents.`
      )
    ) {
      isPresent ? onMarkNotPresent() : onMarkPresent();
    }
  };

  const getGroupName = () => {
    return detention.classGroupName ? (
      <>
        in <b>{detention.classGroupName}</b>
      </>
    ) : detention.tutorGroupName ? (
      <>
        in <b>{detention.tutorGroupName}</b>
      </>
    ) : detention.customGroupName ? (
      <>
        in <b>{detention.customGroupName}</b>
      </>
    ) : detention.provisionGroupName ? (
      <>
        in <b>{detention.provisionGroupName}</b>
      </>
    ) : null;
  };

  return (
    <List.Item
      className={`${isPresent ? "attended" : "missed"}`}
      left={
        <DetentionItem>
          <DetentionStatus color={icon.color.swatch}>
            <Icon
              value={icon.value}
              color={icon.color.swatch}
              size={Size.ExtraLarge}
              tooltip={icon.tooltip}
              tooltipSub={icon.tooltipSub}
            />
            <Chip
              text={icon.tooltip}
              colorSwatch={icon.color}
              tooltip={icon.tooltip}
              tooltipSub={icon.tooltipSub}
            />
          </DetentionStatus>

          <div className="detention-details">
            <DetailLabel
              label={detention.detentionTypeName}
              sub={<ActivityDate date={detention.startDate} />}
              bold
            />
            <DetailLabel
              label={
                <>
                  <b>{`${detention.codeDisplayName} - ${detention.categoryName}`}</b>{" "}
                  <span className="given-by-text">given by</span>{" "}
                  <b>{`${detention.awardedBy}`}</b> {getGroupName()}
                </>
              }
            />
          </div>
        </DetentionItem>
      }
      right={
        <SanctionActions>
          {detention.statusId !== DETENTION_STATUS_KEYS.CANCELLED &&
            detention.statusId !== DETENTION_STATUS_KEYS.RESCHEDULED && (
              <SplitButton
                size={Size.Small}
                text="Actions"
                color={Swatches.Low}
              >
                <SplitButton.Option
                  onClick={onMarkPresent}
                  text="Present"
                  show={
                    isPresent === null &&
                    todayDateTime() >= moment(detention.startDate).format()
                  }
                  color={Swatches.Success}
                />
                <SplitButton.Option
                  onClick={onMarkNotPresent}
                  text="Not Present"
                  show={
                    isPresent === null &&
                    todayDateTime() >= moment(detention.startDate).format()
                  }
                  color={Swatches.Danger}
                />
                <SplitButton.Option
                  onClick={() => handleRescheduleDetention(detention)}
                  text="Reschedule"
                  show={isPresent === null}
                />
                <SplitButton.Option
                  onClick={changeAttendance}
                  text="Change Attendance"
                  show={isPresent !== null}
                />
              </SplitButton>
            )}
        </SanctionActions>
      }
      onClick={() => handleClickDetention(detention)}
    />
  );
};

export default DetentionListItem;
