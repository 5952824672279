import React, { useEffect, useState } from "react";
import fileService from "services/files/fileService";

export interface IImageProps {
  src: string;
  alt?: string;
  onClick?(): void;
  className?: string;
}

const Image: React.FC<IImageProps> = ({ src, alt, onClick, className }) => {
  const [imgData, setImgData] = useState<string>("");

  useEffect(() => {
    if (src) {
      fileService
        .getProfileImage("59e3f49c-e625-e911-80e7-005056a23846")
        .then((data: string) => setImgData(`data:image/jpeg;base64,${data}`));
    }
  }, [src]);

  const handleClick = () => {
    onClick?.();
  };

  return (
    <img
      src={imgData}
      alt={alt}
      onClick={handleClick}
      className={`image ${className ? className : ""}`}
    />
  );
};

export default Image;
