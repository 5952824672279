import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import NewSmartTaskFormWrapper from "./newSmartTaskFormWrapper";
import EditSmartTaskFormWrapper from "./editSmartTaskFormWrapper";
import ReadOnlySmartTask from "./readOnlySmartTask";
import { Loader, Size, Message, Spacing } from "ui-kit";
import { Swatches } from "ui-kit/common/colors";
import styled from "styled-components";
import smartTaskActions from "areas/behaviour/actions/smartTasks/smartTaskActions";
import ConfirmModal from "sharedComponents/common/confirmModal";
import { useAppSelector } from "reducers/hooks";

const Wrapper = styled.div`
  flex-grow: 2;
  margin-top: ${Spacing.Small}px;
`;

const LoaderWrapper = styled.div`
  margin-top: ${Spacing.Medium}px;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SmartTaskFormWrapper: React.FC = () => {

  const {
    selectedSmartTaskId,
    smartTask,
    loading,
    refresh,
    deleteSmartTaskError,
  } = useAppSelector(state => state.smartTask);
  const { schoolId } = useAppSelector(state => state.schools);

  const [editSmartTask, setEditSmartTask] = useState<boolean>(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);

  useEffect(() => {
    setEditSmartTask(false);
    if (selectedSmartTaskId) {
      smartTaskActions.getSmartTask(schoolId, selectedSmartTaskId);
    }
  }, [selectedSmartTaskId]);

  useEffect(() => {
    if (refresh) {
      smartTaskActions.getSmartTask(schoolId, selectedSmartTaskId);
    }
  }, [refresh]);

  const handleDeleteSmartTask = (smartTaskId: number) => {
    const callback = () => {
      if (!deleteSmartTaskError) {
        smartTaskActions.resetSelectedSmartTask();
      }
    };

    smartTaskActions.deleteSmartTask(schoolId, smartTaskId, callback);
    setOpenConfirmDelete(false);
  };

  if (loading) {
    return (
      <LoaderWrapper>
        <Loader size={Size.Medium} fluid />
      </LoaderWrapper>
    );
  }

  return (
    <Wrapper>
      {deleteSmartTaskError && (
        <Message text={deleteSmartTaskError.message} color={Swatches.Danger} />
      )}

      {selectedSmartTaskId ? (
        editSmartTask ? (
          <EditSmartTaskFormWrapper
            selectedSmartTaskId={selectedSmartTaskId}
            schoolId={schoolId}
            closeEditMode={() => setEditSmartTask(false)}
            openConfirmDelete={() => setOpenConfirmDelete(true)}
          />
        ) : (
          smartTask && (
            <ReadOnlySmartTask
              smartTask={smartTask}
              openEditMode={() => setEditSmartTask(true)}
              openConfirmDelete={() => setOpenConfirmDelete(true)}
            />
          )
        )
      ) : (
        <NewSmartTaskFormWrapper schoolId={schoolId} />
      )}

      <ConfirmModal
        openModal={openConfirmDelete}
        confirmMsg="Are you sure you want to delete this smart task?"
        onConfirm={() => handleDeleteSmartTask(selectedSmartTaskId)}
        onClose={() => setOpenConfirmDelete(false)}
      />
    </Wrapper>
  );
};

SmartTaskFormWrapper.propTypes = {
  smartTask: PropTypes.object,
};

export default SmartTaskFormWrapper;
