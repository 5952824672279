import React, { useState, useEffect } from "react";
import {
  Card,
  StructuredList,
  Message,
  ValidationMessage,
  Button,
  Swatches,
  List,
  formatScrollbars,
  Spacing,
  ActionBar,
  Size,
  neutral,
  Left,
  Right,
} from "ui-kit";
import { arrays, files } from "utils";
import moment from "moment";
import { Constants } from "configuration";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { users } from "utils";
import reportingActions from "areas/humanResources/actions/reportingActions";
import { RootState } from "reducers/store";
import { IRole } from "configuration/constants.types";
import { HolidayCardReportFilter, LeaveRequestReportFilter } from "areas/humanResources/types/leaveRequest.types";
import { useScroll } from "hooks/useScroll";
import HolidayCardReport from "./holidayCardReport";
import LeaveRequestsReport from "./leaveRequestsReport";
import { StaffListView } from "types/users/userListViews.types";


const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;

  .left {
    padding-right: ${Spacing.Default}px;
    flex-direction: column;
    width: 0px;
    flex-grow: 1;
    left: 0;
    overflow-y: auto !important;
    width: 13rem;
    max-width: 13rem;
    margin-right: ${Spacing.Default}px;
  }

  .right {
    flex-grow: 1;
  }

  ${formatScrollbars(neutral[200])}
`;


interface leaveReport {
  name: string;
  value: number;
  roles: IRole[];
}

interface leaveReportFilters extends HolidayCardReportFilter, Omit<LeaveRequestReportFilter, "staffId"> {
  staffId: StaffListView;
}

export interface IHolidayAndLeaveReportProps {
  filter: leaveReportFilters;
  setFilter: React.Dispatch<React.SetStateAction<leaveReportFilters>>;
}


const REPORTS: leaveReport[] = [
  {
    name: "Holiday Card Report",
    value: 0,
    roles: [
      Constants.ROLES.DEVELOPMENT,
      Constants.ROLES.IT_ADMINISTRATOR,
      Constants.ROLES.IT_USER,
    ],
  },
  {
    name: "Leave Requests Report",
    value: 1,
    roles: [
      Constants.ROLES.DEVELOPMENT,
      Constants.ROLES.HR_ADMINISTRATOR,
      Constants.ROLES.IT_ADMINISTRATOR,
      Constants.ROLES.IT_USER,
    ],
  },
];


const Reporting = () => {

  const { scrollToTop } = useScroll();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { working, error, report } = useSelector((state: RootState) => state.holidayCardReporting);

  const firstValidReport = REPORTS.find(report => {
    return users.isInAnyRoles(user, report.roles);
  });

  const [selectedReport, setSelectedReport] = useState<leaveReport>(firstValidReport);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [filter, setFilter] = useState<leaveReportFilters>({
    academicYearId: 10,
    schoolId: -1,
    staffType: null,
    leaveRequestType: null,
    status: null,
    startDate: null,
    endDate: null,
    coverRequirement: null,
    staffId: null
  });

  const userHasMultipleSchools = () => user.schools.length > 0;

  useEffect(() => {
    if (user && !userHasMultipleSchools()) {
      setFilter({ ...filter, schoolId: user.baseSchool.id });
    }
  }, [user]);

  const handleRunReport = () => {
    var errors = [];

    if (filter.startDate != null && filter.endDate != null) {
      const start = moment(filter.startDate);
      const end = moment(filter.endDate);

      if (start.isAfter(end)) {
        errors.push(
          `The start date for the report must be before the end date.`
        );
      }
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    var reportCommand = {
      ...filter,
      schoolId: filter.schoolId === -1 ? null : filter.schoolId,
      staffId: filter.staffId == null ? null : filter.staffId.id
    };

    switch (selectedReport.value) {
      case 0: // Holiday Card
        reportingActions.getHolidayCardReport(reportCommand, data => {
          files.openCsv(data);
        });
        break;
      case 1: // Leave Request
        reportingActions.getLeaveRequestReport(reportCommand, data => {
          files.openCsv(data);
        });
        break;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <Left>
        <List title="Available Reports">
          {REPORTS.filter(x => users.isInAnyRoles(user, x.roles)).map(
            (report, index) => (
              <List.Item
                text={report.name}
                key={index}
                selected={selectedReport.value === report.value}
                onClick={() => setSelectedReport(report)}
              />
            )
          )}
        </List>
      </Left>
      <Right>
        <Message text={error} color={Swatches.Danger} />
        <ValidationMessage errors={validationErrors} />
        <Card title={selectedReport.name}>
          <Card.Body>
            <StructuredList>
              {selectedReport.value === 0 && (
                <HolidayCardReport filter={filter} setFilter={setFilter} />
              )}
              {selectedReport.value === 1 && (
                <LeaveRequestsReport filter={filter} setFilter={setFilter} />
              )}
            </StructuredList>
          </Card.Body>
          <Card.Footer>
            <ActionBar low>
              <Button
                text="Run Report"
                color={Swatches.Success}
                size={Size.Small}
                onClick={handleRunReport}
                working={working}
              />
            </ActionBar>
          </Card.Footer>
        </Card>
      </Right>
    </Wrapper>
  );
};

export default Reporting;
