import defaults from "configuration/defaults";
import { arrays } from "utils";
import submissionActions from "../actions/submissionActions";
import { PageInfo } from "types/common/paging.types";
import { SubmissionListView } from "../types/formResponse.types";
import { IBeehiveAction } from "types/common/action.types";


interface ISubmissionApprovalState {
  submissions: SubmissionListView[];
  loading: boolean;
  error: string | null;
  paging: PageInfo;
}

const INITIAL_STATE: ISubmissionApprovalState = {
  submissions: [],
  loading: false,
  error: null,
  paging: defaults.DEFAULT_PAGING,
};


const submissionApprovalReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISubmissionApprovalState => {

  const { GETFORAPPROVAL, AUTHORISE, REJECT, CANCEL } = submissionActions.types;

  switch (action.type) {
    case GETFORAPPROVAL.START:
      return { ...state, loading: arrays.isEmpty(state.submissions) };

    case GETFORAPPROVAL.SUCCESS:
      var newState = {
        ...state,
        submissions:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.submissions, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };
      return newState;

    case GETFORAPPROVAL.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case AUTHORISE.SUCCESS:
    case REJECT.SUCCESS:
    case CANCEL.SUCCESS:
      return {
        ...state,
        submissions: state.submissions.map(submission =>
          submission.id === action.payload.submissionId
            ? { ...submission, currentStatus: action.payload }
            : submission
        ),
      };
    default:
      return state;
  }
};

export default submissionApprovalReducer;
