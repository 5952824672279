import paymentActions from "areas/payments/actions/paymentsActions";
import { IBeehiveAction } from "types/common/action.types";


export interface IPaymentsHomeState {
  selectedTab: number
}

const INITIAL_STATE: IPaymentsHomeState = {
  selectedTab: 0
};

const paymentsHomeReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IPaymentsHomeState => {
  const { 
    PAYMENTS_HOMESWITCHTAB 
  } = paymentActions.paymentsTypes;

  switch (action.type) {
    case PAYMENTS_HOMESWITCHTAB:
      return { 
        ...state, 
        selectedTab: action.payload 
      };

    default:
      return state;
  }
};

export default paymentsHomeReducer;
