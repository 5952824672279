import React from "react";
import styled from "styled-components";
import { Icon, Size } from "ui-kit";

interface IFileIconProps {
  type: string;
  className?: string;
}

const FileIcon: React.FC<IFileIconProps> = ({ type, className }) => {
  const getIconName = (type: string): string => {
    if (!type) {
      return "file";
    }

    var extension = type.toLowerCase();

    switch (extension) {
      case "doc":
      case "docx":
        return "file-word";
      case "xls":
      case "xlsx":
        return "file-excel";
      case "ppt":
      case "pptx":
        return "file-powerpoint";
      case "jpg":
      case "jpeg":
      case "gif":
      case "png":
        return "file-image";
      case "csv":
        return "file-csv";
      case "pdf":
        return "file-pdf";
      default:
        return "file";
    }
  };

  return (
    <Icon
      className={`file-icon  ${className ? className : ""}`}
      size={Size.Medium}
      value={getIconName(type)}
    />
  );
};

export default FileIcon;
