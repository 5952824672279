export const DETENTION_OPTIONS_KEYS = {
  NO: 0,
  MANDATORY: 1,
  OPTIONAL: 2,
};

export const DETENTION_STATUS_KEYS = {
  ISSUED: 0,
  ATTENDED: 1,
  MISSED: 2,
  CANCELLED: 3,
  RESCHEDULED: 4,
};

export const DETENTION_LOCAL_MARKING_KEYS = {
  MISSED: 0,
  ATTENDED: 1,
};

export const DETENTION_STATUS = [
  {
    key: DETENTION_STATUS_KEYS.ISSUED,
    status: "Issued",
  },
  {
    key: DETENTION_STATUS_KEYS.ATTENDED,
    status: "Attended",
  },
  {
    key: DETENTION_STATUS_KEYS.MISSED,
    status: "Missed",
  },
  {
    key: DETENTION_STATUS_KEYS.CANCELLED,
    status: "Cancelled",
  },
  {
    key: DETENTION_STATUS_KEYS.RESCHEDULED,
    status: "Rescheduled",
  },
];

export const CHANGE_STATUS_KEYS = {
  MARKATTENDED: 0,
  MARKMISSED: 1,
  MARKCANCELLED: 2,
  REVOKEBEHAVIOUR: 3,
};

export const DETENTION_CATEGORIES = {
  MORNING_DETENTION: 0,
  LUNCH_DETENTION: 1,
  AFTER_SCHOOL: 2,
  INTERNAL_EXCLUSION: 3,
};
