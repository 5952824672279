import { ProviderDetailView } from "areas/send/types/passportResponse.types";
import providerActions from "../../actions/providers/providerActions";
import provisionsActions from "../../actions/provisions/provisionsActions";
import { IBeehiveAction } from "types/common/action.types";

interface IProviderState {
  provider: ProviderDetailView | null;
  loading: boolean;
  saving: boolean;
  saveError: string | null;
  error: string | null;
  deleting: boolean;
  deleteError: string | null;
  newId: number | null;
}

const INITIAL_STATE: IProviderState = {
  provider: null,
  loading: false,
  saving: false,
  saveError: null,
  error: null,
  deleting: false,
  deleteError: null,
  newId: null
};

const providerReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IProviderState => {

  const {
    PROVIDER_GET,
    PROVIDER_CREATE,
    PROVIDER_SAVE,
    PROVIDER_DELETE,
    PROVIDERCONTACT_CREATE,
    PROVIDERCONTACT_SAVE,
    PROVIDERCONTACT_DELETE,
  } = providerActions.types;
  const {
    PROVISIONS_CREATESTANDARD,
    PROVISIONS_SAVESTANDARD,
    PROVISIONS_DELETE,
  } = provisionsActions.types;

  switch (action.type) {
    case PROVIDER_GET.START:
      return { ...state, loading: true };
    case PROVIDER_GET.SUCCESS:
      return { ...state, loading: false, provider: action.payload };
    case PROVIDER_GET.FAILED:
      return { ...state, loading: false, error: action.payload };

    case PROVIDER_CREATE.START:
      return { ...state, saving: true };
    case PROVIDER_CREATE.SUCCESS:
      return { ...state, saving: false, newId: action.payload.id };
    case PROVIDER_CREATE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem creating the provider.",
      };

    case PROVIDER_SAVE.START:
      return { ...state, saveError: null, deleteError: null, saving: true };
    case PROVIDER_SAVE.SUCCESS:
      return {
        ...state,
        saving: false,
        provider:
          action.payload.id === state.provider?.id
            ? { ...state.provider, ...action.payload }
            : state.provider,
      };
    case PROVIDER_SAVE.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "There was a problem saving the provider.",
      };

    case PROVIDER_DELETE.START:
      return { ...state, deleteError: null, deleting: true };
    case PROVIDER_DELETE.SUCCESS:
      return { ...state, deleting: false };
    case PROVIDER_DELETE.FAILED:
      return {
        ...state,
        deleting: false,
        deleteError:
          "There was a problem deleting the provider. This may be because the provider is in use.",
      };

    case PROVIDERCONTACT_CREATE.SUCCESS:
      return {
        ...state,
        provider: {
          ...state.provider,
          contacts: [action.payload, ...state.provider.contacts],
        },
      };

    case PROVIDERCONTACT_SAVE.SUCCESS:
      return {
        ...state,
        provider: {
          ...state.provider,
          contacts: state.provider.contacts.map(a =>
            a.id === action.payload.id ? action.payload : a
          ),
        },
      };

    case PROVIDERCONTACT_DELETE.SUCCESS:
      return {
        ...state,
        provider: {
          ...state.provider,
          contacts: state.provider.contacts.filter(
            a => a.id !== action.payload
          ),
        },
      };

    case PROVISIONS_CREATESTANDARD.SUCCESS:
      return {
        ...state,
        provider:
          state.provider?.id === action.payload.provider.id
            ? {
                ...state.provider,
                provisions: [action.payload, ...state.provider?.provisions],
              }
            : state.provider,
      };

    case PROVISIONS_SAVESTANDARD.SUCCESS:
      return {
        ...state,
        provider:
          state.provider?.id === action.payload.provider.id
            ? {
                ...state.provider,
                provisions: state.provider?.provisions?.map(a =>
                  a.id === action.payload.id ? action.payload : a
                ),
              }
            : state.provider,
      };

    case PROVISIONS_DELETE.SUCCESS:
      return {
        ...state,
        provider: {
          ...state.provider,
          provisions: state.provider?.provisions?.filter(
            a => a.id !== action.payload
          ),
        },
      };

    default:
      return state;
  }
};

export default providerReducer;
