import React from "react";
import { Chip, Swatches } from "ui-kit";
import { Constants } from "configuration";


interface ICreditStatusProps {
  status: number;
}


const CreditStatus: React.FC<ICreditStatusProps> = ({ status }) => {

  var swatch = null;
  var name = "";

  switch (status) {
    case Constants.CREDIT_STATUS.PENDING.value:
      swatch = Swatches.Low;
      name = Constants.CREDIT_STATUS.PENDING.name;
      break;
    case Constants.CREDIT_STATUS.CREDITED.value:
      swatch = Swatches.Success;
      name = Constants.CREDIT_STATUS.CREDITED.name;
      break;
    case Constants.CREDIT_STATUS.FAILED.value:
      swatch = Swatches.Danger;
      name = Constants.CREDIT_STATUS.FAILED.name;
      break;
    case Constants.CREDIT_STATUS.CANCELLED.value:
      swatch = Swatches.Cancel;
      name = Constants.CREDIT_STATUS.CANCELLED.name;
      break;
    default:
      return null;
  }

  return <Chip text={name} colorSwatch={swatch} />;
};

export default CreditStatus;
