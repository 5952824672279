import { UserTinyView } from "types/users/userListViews.types";
import sendStudentsActions from "../../actions/students/sendStudentsActions";
import { IBeehiveAction } from "types/common/action.types";

interface ISendStudentsState {
  students: UserTinyView[];
  loading: boolean;
  saving: boolean;
  error: string | null;
}

const INITIAL_STATE: ISendStudentsState = {
  students: [],
  loading: false,
  saving: false,
  error: null,
};


const sendStudentsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ISendStudentsState => {

  const { STUDENTS_GET } = sendStudentsActions.types;

  switch (action.type) {
    case STUDENTS_GET.START:
      return { ...state, loading: true };
    case STUDENTS_GET.SUCCESS:
      return { ...state, students: action.payload };
    case STUDENTS_GET.FAILED:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

export default sendStudentsReducer;
