import React from "react";
import Moment from "react-moment";


interface IActivityDateProps {
  date: string | Date;
  children?: React.ReactNode
}


const ActivityDate: React.FC<IActivityDateProps> = ({ date, children }) => {
  return (
    <div className="activity-date">
      <Moment format="dddd Do MMMM YYYY" date={date} /> at{" "}
      <Moment format="h:mm A" date={date} />
      {children}
    </div>
  );
};

export default ActivityDate;
