import React from "react";
import styled from "styled-components";
import { BorderRadius, neutral } from "ui-kit";
import { Swatch } from "ui-kit/common/colors";


const Wrapper = styled.div`
  height: 6px;
  max-height: 6px;
  border-radius: ${BorderRadius.Default}px;
  width: 100%;
  background-color: ${neutral[300]};

  .points {
    height: 6px;
    max-height: 6px;
  }
`;


interface ILeaderboardPointsBarProps {
  highestPoints: number;
  currentPointsValue: number;
  color: Swatch;
}


const LeaderboardPointsBar: React.FC<ILeaderboardPointsBarProps> = ({ highestPoints, currentPointsValue, color }) => {
  const width =
    currentPointsValue === 0
      ? 0
      : Math.round((currentPointsValue / highestPoints) * 100);

  const rightBorderRadius = width < 100 ? 0 : 4;

  return (
    <Wrapper>
      <div
        className="points"
        style={{
          width: `${width}%`,
          backgroundColor: color.swatch,
          borderRadius: `4px ${rightBorderRadius}px ${rightBorderRadius}px 4px`,
          boxShadow: `0px 0px 2px -1px ${color.swatch}`
        }}
      ></div>
    </Wrapper>
  );
};

export default LeaderboardPointsBar;
