import React from "react";
import {
  StructuredList,
  Card,
  Dropdown,
  ActionBar,
  Button,
  Swatches,
  Size,
} from "ui-kit";
import DateRange from "../dateRange";
import Search, {
  SEARCH_GROUP_TYPE_KEYS,
} from "sharedComponents/common/search/search";
import moment from "moment";
import MultiSchoolUserView from "sharedComponents/common/users/multiSchoolUserView";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import { ACTIVITY_FILTERS } from "areas/planner/constants/activityFeed";
import { FeedFilterExt } from "./yearGroupReportWrapper";


interface IYearGroupReportFormProps {
  schoolId: number;
  onSelectSchool: (value: number) => void;
  reportCriteria: FeedFilterExt;
  onChangeReportCriteria: (criteria: FeedFilterExt) => void;
  handleGenerateReport: (exportReport: boolean) => void;
  loadingExportReport: boolean;
}


const YearGroupReportForm: React.FC<IYearGroupReportFormProps> = ({
  schoolId,
  onSelectSchool,
  reportCriteria,
  onChangeReportCriteria,
  handleGenerateReport,
  loadingExportReport,
}) => {

  const handleExportReport = () => {
    const days = moment(reportCriteria.endDate).diff(
      moment(reportCriteria.startDate),
      "days"
    );

    if (
      days <= 5 ||
      (days > 5 &&
        window.confirm(
          "Warning - The selected date range will contain a large amount of data. Only 10,000 records will be exported."
        ))
    ) {
      handleGenerateReport(true);
    }
  };

  return (
    <>
      <MultiSchoolUserView>
        <Card title="School">
          <Card.Body>
            <SchoolDropdown
              onChange={onSelectSchool}
              initialValue={schoolId}
              fluid
            />
          </Card.Body>
        </Card>
      </MultiSchoolUserView>

      <Card title="Date Range">
        <Card.Body>
          <DateRange
            startDate={reportCriteria.startDate}
            handleStartDateChange={value =>
              onChangeReportCriteria({
                ...reportCriteria,
                startDate: value,
              })
            }
            endDate={reportCriteria.endDate}
            handleEndDateChange={value =>
              onChangeReportCriteria({ ...reportCriteria, endDate: value })
            }
            required
          />
        </Card.Body>
      </Card>

      <Card title="Filters">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item
              name="Year Group"
              description="Filter to generate a report for only selected year groups"
              helpPopup
              required
            >
              <Search
                groupTypes={[SEARCH_GROUP_TYPE_KEYS.YEAR_GROUP]}
                selected={
                  reportCriteria.yearGroup ? [reportCriteria.yearGroup] : []
                }
                handleSelectItem={yearGroup =>
                  onChangeReportCriteria({
                    ...reportCriteria,
                    yearGroup: yearGroup,
                  })
                }
                handleDeleteItem={() =>
                  onChangeReportCriteria({
                    ...reportCriteria,
                    yearGroup: null,
                  })
                }
                schoolId={schoolId}
              />
            </StructuredList.Item>

            <StructuredList.Item name="Activity Type">
              <Dropdown
                items={ACTIVITY_FILTERS.map((activityType, index) => ({
                  key: activityType.key,
                  label: activityType.text,
                  value: activityType.key,
                }))}
                onChange={value =>
                  onChangeReportCriteria({
                    ...reportCriteria,
                    feedType: value,
                  })
                }
                value={reportCriteria.feedType}
                fluid
                clearable
              />
            </StructuredList.Item>

            {/* <StructuredList.Item name="Timescale">
              <Dropdown
                items={DATE_FILTERS.map((date, index) => ({
                  key: date.key,
                  label: date.text,
                  value: date.key,
                }))}
                onChange={value =>
                  onChangeReportCriteria({
                    ...reportCriteria,
                    timescale: value,
                  })
                }
                value={reportCriteria.timescale}
                fluid
                clearable
              />
            </StructuredList.Item> */}
          </StructuredList>
        </Card.Body>
      </Card>

      <ActionBar>
        <Button
          text="Generate Report"
          color={Swatches.Success}
          size={Size.Small}
          disabled={
            reportCriteria.startDate === null ||
            reportCriteria.endDate === null ||
            reportCriteria.yearGroup === null
          }
          onClick={() => handleGenerateReport(false)}
        />
        <Button
          color={Swatches.Success}
          text="Export Report as CSV"
          icon="file-download"
          size={Size.Small}
          disabled={
            reportCriteria.startDate === null ||
            reportCriteria.endDate === null ||
            reportCriteria.yearGroup === null
          }
          onClick={handleExportReport}
          working={loadingExportReport}
        />
      </ActionBar>
    </>
  );
};

export default YearGroupReportForm;
