import React, { useEffect } from "react";
import ManagedMovesTable from "./managedMovesTable";
import managedMovesActions from "../../actions/managedMoves/managedMovesActions";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";
import { useAppSelector } from "reducers/hooks";


interface IStudentManagedMovesProps {
  student: PlannerStudentDetailView;
}


const StudentManagedMoves: React.FC<IStudentManagedMovesProps> = ({ student }) => {

  const { moves, loading, error } = useAppSelector(
    state => state.studentManagedMoves
  );

  const loadMoves = () => {
    managedMovesActions.getManagedMovesForStudent(student.id);
  };

  useEffect(() => {
    student && loadMoves();
  }, [student]);

  return (
    <ManagedMovesTable
      student={student}
      moves={moves}
      loading={loading}
      error={error}
      onChange={loadMoves}
      showStudent={false}
    />
  );
};

export default StudentManagedMoves;
