import moment from "moment";
import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { DetailLabel } from "ui-kit";



interface IFormatDateProps {
  starts: Date;
  ends: Date;
}


const FormatDate: React.FC<IFormatDateProps> = ({ starts, ends }) => {

  const startMoment = moment(starts);
  const endMoment = moment(ends);
  
  if (startMoment.isSame(endMoment)) {
    return (
      <DetailLabel
        bold
        label={<Moment date={starts} format="dddd, Do MMMM YYYY" />}
        sub={<Moment date={starts} format="[Starts] HH:mma" />}
      />
    );
  }

  if (startMoment.isSame(endMoment, "day")) {
    return (
      <DetailLabel
        bold
        label={<Moment date={starts} format="dddd, Do MMMM YYYY" />}
        sub={
          <>
            <Moment date={starts} format="HH:mma" /> to{" "}
            <Moment date={ends} format="HH:mma" />
          </>
        }
      />
    );
  }

  return (
    <span>
      <b>
        <Moment date={starts} format="dddd, Do MMMM YYYY HH:mma" />
      </b>{" "}
      to{" "}
      <b>
        <Moment date={ends} format="dddd, Do MMMM YYYY, HH:mma" />
      </b>
    </span>
  );
}


export default FormatDate;