import React from "react";
import { useSelector } from "react-redux";
import storeActions from "areas/payments/actions/storeActions";
import styled from "styled-components";
import {
  neutral,
  typescale,
  Swatches,
  Size,
  displayFont,
  Button,
  CircleIcon,
  Currency,
  Spacing,
  BorderRadius,
  fontStyle
} from "ui-kit";
import { RootState } from "reducers/store";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
  background: ${neutral[100]};
  margin-right: ${Spacing.Large}px;
  margin-bottom: ${Spacing.Large}px;
  padding: ${Spacing.Large}px;
  border-radius: ${BorderRadius.Default}px;

  &:last-child {
    margin-right: 0;
  }

  .circle-icon {
    margin-bottom: 4px;
    color: ${neutral[300]};
  }
  .total {
    ${fontStyle(displayFont.extraLight, typescale.header2, neutral[700])}
  }
  .caption {
    ${fontStyle(displayFont.bold, typescale.paragraph, neutral[700])}
  }
`;


export interface IPrintCreditProps {
  onAddToBasket?: (value: number) => void;
  value: number;
  recipientId: string;
  schoolId: number;
}


const PrintCredit: React.FC<IPrintCreditProps> = ({ onAddToBasket, value, recipientId, schoolId }) => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { working } = useSelector((state: RootState) => state.basket);

  const handleAddToBasket = () => {
    const data = { userId: user.id, recipientId, schoolId, amount: value };
    storeActions.addPrintCreditToBasket(user.id, data, () => {
      onAddToBasket?.(value);
    });
  };
  return (
    <Wrapper className="topup">
      <CircleIcon value="id-card-alt" outline color={Swatches.Low.swatch} />
      <Currency className="total" value={value} />
      <span className="caption">Print Credit</span>
      <Button
        text="Buy Print Credit"
        size={Size.Medium}
        onClick={handleAddToBasket}
        color={Swatches.Primary}
        working={working}
        tooltip="Add Print Credit to Basket"
        tooltipSub={`Add £${value} of print credits to your basket`}
      />
    </Wrapper>
  );
};
export default PrintCredit;
