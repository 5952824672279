import moment from "moment";
import {
  formatDate,
  formatTime,
  getDateTime,
  todayTime,
  todayDate,
} from "utils/dateTime";
import { Constants } from "configuration";
import { BehaviourSummaryListView, DetentionTypeListView, DetentionView, MultipleBehavioursSaveResultsView } from "../types/behaviourResponses.types";
import { BehaviourOutcome, DetentionPeriod } from "../types/behaviourShared.types";
import { DetentionDetailView } from "../types/detentionRegisterResponse.types";
import { IInitialAction } from "../components/rewards/initialRewardActionWrapper";
import { GroupType } from "types/users/userGroups.types";
import { types } from "utils";


export const getDetentionDate = (cutOffTime: string, detentionTime: string) => {
  const currentTime = todayTime();
  const detentionDateTime = getDateTime(todayDate(), detentionTime);
  return currentTime > moment(cutOffTime, ["HH:mm:ss"]).format("HH:mm")
    ? moment(detentionDateTime).day() === 5
      ? moment().add(3, "days")
      : moment().add(1, "days")
    : moment();
};


export const getDefaultDetentionInfo = (
  detentionTypeInformation: DetentionTypeListView,
  detentionCutOffTime?: string,
  multipleBehaviours?: boolean,
  schoolId?: number
) : IDetentionDetails => {

  const detentionTime = formatTime(
    detentionTypeInformation.detentionInformation.startDate
  );

  let detentionDate;

  if (multipleBehaviours) {
    detentionDate = getDetentionDate(detentionCutOffTime, detentionTime);
  } else {
    detentionDate = moment(
      detentionTypeInformation.detentionInformation.startDate
    );
  }

  return {
    detentionTypeId: detentionTypeInformation.detentionTypeId,
    detentionTypeName: detentionTypeInformation.detentionTypeName,
    detentionLocationId: detentionTypeInformation.roomId || null,
    detentionLocation: detentionTypeInformation.roomName || null,
    duration: detentionTypeInformation.detentionInformation.duration,
    date: detentionDate,
    time: detentionTime,
    detentionPeriodId: detentionTypeInformation.detentionPeriodId,
    detentionNotes: null
  };
};


export interface IDetentionDetails {
  id?: number;
  detentionTypeId: number;
  detentionTypeName: string;
  date: moment.Moment;
  detentionLocationId: number;
  detentionLocation: string;
  duration: number;
  time: string;
  detentionNotes: string;
  detentionPeriodId: DetentionPeriod;
}

export const getDetentionDetails = (detention: DetentionView) : IDetentionDetails => {
  if (detention) {
    return {
      id: detention.detentionId,
      detentionTypeId: detention.detentionTypeId,
      detentionTypeName: detention.detentionTypeName,
      date: moment(detention.detentionInformation.startDate),
      detentionLocationId: detention.roomId,
      detentionLocation: detention.roomName,
      duration: detention.detentionInformation.duration,
      time: formatTime(detention.detentionInformation.startDate),
      detentionNotes: detention.detentionNote,
      detentionPeriodId: detention.detentionPeriodId,
    }; 
  }
};


export const getBehaviourGivenGroup = (detention: DetentionDetailView | BehaviourSummaryListView) : string => {
  return detention.classGroupDisplayName
    ? detention.classGroupDisplayName
    : detention.customGroupDisplayName
    ? detention.customGroupDisplayName
    : detention.tutorGroupName
    ? detention.tutorGroupName
    : detention.provisionGroupName
    ? detention.provisionGroupName
    : types.isType<DetentionDetailView>(detention, "location")
    ? detention.location
    : null;
};


export interface IIncidentDetails {
  period: number;
  incidentLocation: number;
  otherIncidentLocation: string;
  incidentDate: string;
  incidentTime: string;
  initialActionId: number;
  initialActionName: string;
}

export interface IBehaviourDetails {
  points: number;
  outcome: number;
  initialAction: IInitialAction;
  publicNotes: string;
  internalNotes: string;
  incidentDetails: IIncidentDetails;
  detentionDetails: IDetentionDetails;
  class: TinyGroup;
}

export type TinyGroup = { 
  id: number, 
  type: GroupType,
  label?: string,
  value?: number
};

export interface IMultipleIncidentDetails {
  description: string;
  publicNotes: string;
  categoryId: number;
  behaviourCodeId: number;
  behaviourCodeName: string;
  behaviourCategoryName: string;
  leadershipInterventionRequired: boolean;
  points: number;
  initialActionId: number;
  outcome?: BehaviourOutcome;
  incidentDateTime?: string;
  incidentLocation?: number;
  otherIncidentLocation?: string;
  period?: number;
}

export interface IMultipleBehaviourResponse {
  behaviours: MultipleBehavioursSaveResultsViewExt[];
  bulkBehaviourStatus: number;
  detentionDetails: IDetentionDetails;
  incidentDetails: IMultipleIncidentDetails;
}

export interface MultipleBehavioursSaveResultsViewExt extends MultipleBehavioursSaveResultsView {
  detentionDetails: IDetentionDetails;
  hasBeenSaved?: boolean;
}