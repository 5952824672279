import React, { useEffect, useState } from "react";
import {
  DetailLabel,
  EmptyMessage,
  fontStyle,
  List,
  Loader,
  neutral,
  Size,
  Sub,
  textFont,
  typescale,
} from "ui-kit";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import { Constants } from "configuration";
import StudentConsentResponseModal from "./studentConsentResponseModal";
import styled from "styled-components";
import moment from "moment";
import consentActions from "areas/administration/actions/consentActions";
import { ApiExceptionMessage } from "sharedComponents/common";
import ConsentStatus from "areas/administration/components/consent/consentStatus";
import studentActions from "areas/planner/actions/student/studentActions";

const ListWrapper = styled.div`
  .list .list-item {
    .left .form-name {
      ${fontStyle(textFont.roman, typescale.paragraph, neutral[600])};
    }

    &:hover {
      .left .form-name {
        color: ${neutral[100]};
      }
    }
  }
`;

const StudentConsentForms = ({ student }) => {
  const { consentForms, error, loading } = useSelector(
    state => state.studentConsentForms
  );

  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(null);

  useEffect(() => {
    if (student) {
      studentActions.getStudentCompletedConsentForms(student.id);
    }
  }, []);

  const handleConsentFormClick = form => {
    setForm(form);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setForm(null);
  };

  if (loading) {
    return <Loader size={Size.Medium} fluid />;
  }

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return arrays.isEmpty(consentForms) ? (
    <EmptyMessage title="No Completed Consent Forms" icon="list" />
  ) : (
    <>
      <ListWrapper>
        <List>
          {consentForms.map(form => (
            <List.Item
              key={form.formId}
              className="list-item"
              left={
                <DetailLabel
                  label={form.name}
                  sub={
                    "Last updated: " +
                    moment(form.createdDate).format("DD/MM/YYYY hh:mmA")
                  }
                  bold
                />
              }
              right={<ConsentStatus status={form.consentGiven} />}
              onClick={() => handleConsentFormClick(form)}
            />
          ))}
        </List>

        <Sub style={{ color: neutral[600] }}>
          This is a list of consent forms that have been completed on behalf of
          this student. Non-completed forms are not shown in this list. Please
          note that if a consent form has not been completed this means that
          there is no parental consent given for that form.
        </Sub>
      </ListWrapper>

      {form && open && (
        <StudentConsentResponseModal
          open={open}
          onClose={handleCloseModal}
          form={form}
          student={student}
        />
      )}
    </>
  );
};

export default StudentConsentForms;
