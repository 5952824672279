import classLayoutReducer from "./classLayoutReducer";
import classLayoutStudentFilterReducer from "./classLayoutStudentFilterReducer";
import classViewerRoomLayoutReducer from "./classViewerRoomLayoutReducer";
import classViewerRoomLayoutsReducer from "./classViewerRoomLayoutsReducer";

const classViewerReducers = {
  classLayout: classLayoutReducer,
  classLayoutStudentFilter: classLayoutStudentFilterReducer,
  classViewerRoomLayout: classViewerRoomLayoutReducer,
  classViewerRoomLayouts: classViewerRoomLayoutsReducer,
};

export default classViewerReducers;
