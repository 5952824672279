import React from "react";
import PropTypes from "prop-types";
import RoomLayoutGrid from "./roomLayoutGrid";
import styled from "styled-components";
import {
  displayFont,
  fontStyle,
  typescale,
  neutral,
  List,
  Spacing,
  BorderRadius,
  Label,
} from "ui-kit";
import { honeygold } from "ui-kit/common/colors";
import { getArrayOfCols, getArrayOfRows } from "areas/planner/utils/layouts";
import Grid from "ui-kit/basic/grid";

const Wrapper = styled.span`
  &:hover .room-layout-option-preview {
    align-self: center;
    background-color: ${neutral[100]};
    padding: ${Spacing.ExtraSmall}px;
    border-radius: ${Spacing.Small}px;
  }
`;

const RoomLayoutOption = ({
  template,
  selectedRoomTemplate,
  onSelectTemplate,
}) => {
  const rowsArray = getArrayOfRows(template.rows);
  const colsArray = getArrayOfCols(template.cols);

  return (
    // <Wrapper>
    <Grid.Item
      className={`room-layout-option ${
        selectedRoomTemplate && selectedRoomTemplate.id === template.id
          ? "selected"
          : ""
      }`}
      onClick={() => onSelectTemplate(template)}
      text={template.name}
      // sub={template.description}
      body={
        <div className="room-layout-option-preview">
          <RoomLayoutGrid
            rows={template.rows}
            cols={template.cols}
            rowsArray={rowsArray}
            colsArray={colsArray}
            roomSlots={template.slots}
            preview
          />
        </div>
      }
      selected={selectedRoomTemplate && selectedRoomTemplate.id === template.id}
    />
    // </Wrapper>
  );
};

RoomLayoutOption.propTypes = {
  template: PropTypes.object,
  selectedRoomTemplate: PropTypes.object,
  onSelectTemplate: PropTypes.func,
  buttonText: PropTypes.string,
};

export default RoomLayoutOption;
