import React from "react";
import styled from "styled-components";
import { Card, Checkbox, StructuredList, TextInput, TextInputType } from "ui-kit";
import { formatDate, formatTime } from "utils/dateTime";
import Search, {
  SEARCH_GROUP_TYPE_KEYS,
} from "sharedComponents/common/search/search";
import Avatar from "sharedComponents/common/users/avatar";
import StaffPicker from "sharedComponents/common/users/staffPicker";
import CustomGroupCategoryDropdown from "./customGroupCategoryDropdown";
import { CustomGroupCategory } from "./customGroupCategoryDropdown";
import CalendarEventPicker from "sharedComponents/common/calendarEventPicker";
import { ICustomGroupEditData } from "./customGroupEditorModal";

const Wrapper = styled.div``;


interface ICustomGroupFormProps {
  group: ICustomGroupEditData;
  handleGroupChange: (group: ICustomGroupEditData) => void;
  schoolId: number;
}


const CustomGroupForm: React.FC<ICustomGroupFormProps> = ({ group, handleGroupChange, schoolId }) => {

  return (
    <Wrapper>
      <Card>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item
              name="Name"
              required
              description="Please choose a name for this group."
              helpPopup
            >
              <TextInput
                value={group.name}
                onChange={value => handleGroupChange({ ...group, name: value })}
                fluid
                characterCount
                maxLength={30}
              />
            </StructuredList.Item>
            <StructuredList.Item
              name="Category"
              description="You can choose a category for this group. This cannot be changed once created."
              helpPopup
            >
              { group.id ? (
                <>{CustomGroupCategory[group.category]}</>
              ) : (
                <CustomGroupCategoryDropdown 
                  initialValue={group.category}
                  onChange={value => handleGroupChange({ ...group, category: value })}
                />
              )}
            </StructuredList.Item>
            { schoolId !== 99 && (
              <>
              <StructuredList.Item
                name="Year Group"
                description="You can choose a year group for this group."
                helpPopup
              >
                <Search
                  groupTypes={[SEARCH_GROUP_TYPE_KEYS.YEAR_GROUP]}
                  selected={group.yearGroup ? [group.yearGroup] : []}
                  handleSelectItem={value =>
                    handleGroupChange({ ...group, yearGroup: value })
                  }
                  handleDeleteItem={value =>
                    handleGroupChange({ ...group, yearGroup: null })
                  }
                  schoolId={schoolId}
                />
              </StructuredList.Item>
              <StructuredList.Item
                name="Subject"
                description="You can choose a subject for this group."
                helpPopup
              >
                <Search
                  groupTypes={[SEARCH_GROUP_TYPE_KEYS.SUBJECT]}
                  selected={group.subject ? [group.subject] : []}
                  handleSelectItem={value =>
                    handleGroupChange({ ...group, subject: value })
                  }
                  handleDeleteItem={value =>
                    handleGroupChange({ ...group, subject: null })
                  }
                  schoolId={schoolId}
                />
              </StructuredList.Item>
              </>
            )}
            <StructuredList.Item
              name="Is Private"
              description="You can choose whether to set this group as private. 
              A private group is only visible to you and staff members assigned to this group.
              A private group is not visible to other staff, students and parents."
            >
              <Checkbox
                checked={group.isPrivate}
                onChange={value =>
                  handleGroupChange({ ...group, isPrivate: value.checked })
                }
                text={`This group is private`}
              />
            </StructuredList.Item>

            <StructuredList.Item
              name="Description"
              description="An optional description for what this group is about"
            >
              <TextInput
                type={TextInputType.Textarea}
                value={group.description}
                onChange={value =>
                  handleGroupChange({ ...group, description: value })
                }
                fluid
                rows={5}
              />
            </StructuredList.Item>

            {group.id && (
              <StructuredList.Item
                name="Is Active"
                description="You can choose whether to set this group as active.
              A group that is not active is only visible to you and you cannot make any other changes to the group."
              >
                <Checkbox
                  checked={group.isActive}
                  onChange={value =>
                    handleGroupChange({ ...group, isActive: value.checked })
                  }
                  text={`This group is active`}
                />
              </StructuredList.Item>
            )}

            { (group.category === CustomGroupCategory.Intervention) && (
              <StructuredList.Item
                name="Intervention Event"
                description="Attach an existing intervention event for this group"
              >
                <CalendarEventPicker 
                  eventCategoryName={"Interventions"}
                  buttonText={"Add Intervention Event"}
                  selectedEvents={group.linkedEvents}
                  onSelect={value => handleGroupChange({ ...group, linkedEvents: value })}
                  editable
                />
              </StructuredList.Item>
            )}

            <StructuredList.Item
              name="Responsible Staff"
              required
              description="Please select the staff that will be responsible for this group. You must choose at least one staff member."
            >
              <StaffPicker
                users={group?.responsibleStaff}
                onChange={users =>
                  handleGroupChange({ ...group, responsibleStaff: users })
                }
              />
            </StructuredList.Item>
            {group?.createdBy && (
              <StructuredList.Item
                name="Created By"
                required
                description="Details of who this custom group was created by and when."
              >
                <Avatar
                  user={group.createdBy}
                  sub={`${formatDate(group.createdDate)} ${formatTime(
                    group.createdDate
                  )}`}
                />
              </StructuredList.Item>
            )}
          </StructuredList>
        </Card.Body>
      </Card>
    </Wrapper>
  );
};

export default CustomGroupForm;
