import React from "react";
import styled from "styled-components";
import { Title, TitleSize, Table, Text, Spacing } from "ui-kit";
import { formatDate, formatTime } from "utils/dateTime";
import ReadOnlyDetails from "areas/behaviour/components/readOnly/readOnlyDetails";
import { BehaviourUpscaleView } from "areas/behaviour/types/behaviourResponses.types";

const BehaviourTableWrapper = styled.div`
  padding: 0 ${Spacing.Medium}px;
`;


interface IRelatedBehaviourDetailsProps {
  behaviour: BehaviourUpscaleView;
}


const RelatedBehaviourDetails: React.FC<IRelatedBehaviourDetailsProps> = ({ behaviour }) => {
  return (
    <div>
      {behaviour.parentBehaviourRecords.length > 0 && (
        <>
          <BehaviourTableWrapper>
            <Title text="Behaviour History" size={TitleSize.H3} />
            <Text>
              This behaviour has been upscaled from another behaviour. The table
              below shows the information of the previous behaviour record(s).
            </Text>
            <Table zebra>
              <Table.Header>
                <Table.HeaderCell>Behaviour Code</Table.HeaderCell>
                <Table.HeaderCell>Behaviour</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Time</Table.HeaderCell>
                <Table.HeaderCell>Given By</Table.HeaderCell>
              </Table.Header>

              <Table.Body>
                {behaviour.parentBehaviourRecords.map((record, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{record.behaviourCodeName}</Table.Cell>
                    <Table.Cell>{record.categoryName}</Table.Cell>
                    <Table.Cell>
                      {`${formatDate(record.incidentDate)}`}
                    </Table.Cell>
                    <Table.Cell>
                      {`${formatTime(record.incidentDate)}`}
                    </Table.Cell>
                    <Table.Cell>{record.claimedBy}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </BehaviourTableWrapper>

          <hr />
        </>
      )}

      {behaviour.childBehaviourRecords.map(record => (
        <ReadOnlyDetails behaviour={record} />
      ))}
    </div>
  );
};

export default RelatedBehaviourDetails;
