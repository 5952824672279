import React from "react";
import styled from "styled-components";
import { Size, Spacing } from "ui-kit";
import SchoolIcon from "../schools/icons/schoolIcon";
import ActiveOnCallChip from "areas/behaviour/components/onCall/activeOnCallChip";
import DetentionTodayChip from "areas/behaviour/components/detentions/detentionTodayChip";
import StudentSensitiveData from "areas/planner/components/studentRecord/studentSensitiveData/studentSensitiveData";
import Avatar from "sharedComponents/common/users/avatar";
import { useNavigate } from "react-router";
import { studentRoutes } from "areas/planner/plannerRoutes";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${Spacing.Default}px ${Spacing.Large}px;
  margin-bottom: ${Spacing.Medium}px;
  // flex-grow: 1;

  .school-icon {
    margin: ${Spacing.Small}px 0;
  }
`;

interface IStudentProfileHeaderProps {
  student: any;
  schoolLogoSize?: Size;
  allowNavigation?: boolean;
  onClick?: () => void;
  children?: any | any[];
}

const StudentProfileHeader: React.FC<IStudentProfileHeaderProps> = ({
  student,
  schoolLogoSize,
  allowNavigation,
  onClick,
  children,
}) => {
  const navigate = useNavigate();

  const getSubText = () => {
    let tutorGroupAndHouse = null;
    const tutorGroupName = student.tutorGroup?.friendlyName;
    const houseName = student.house?.friendlyName;

    if (tutorGroupName && houseName) {
      tutorGroupAndHouse = <b>{`${tutorGroupName} | ${houseName}`}</b>;
    } else if (student.tutorGroup) {
      tutorGroupAndHouse = <b>{tutorGroupName}</b>;
    } else if (student.house) {
      tutorGroupAndHouse = <b>{houseName}</b>;
    }
    return (
      <>
        {student.simsId}
        <div>{tutorGroupAndHouse}</div>
      </>
    );
  };

  const handleClick = () => {
    if (allowNavigation) {
      navigate(studentRoutes.getStudentPath(student.id));
    } else {
      onClick?.();
    }
  };

  return student ? (
    <Wrapper className="student-profile-header">
      <Avatar
        portrait
        user={student}
        sub={getSubText()}
        size={Size.ExtraLarge}
        onClick={handleClick}
      />

      <StudentSensitiveData student={student} compact={false} fluid={false} />

      {student.school && (
        <SchoolIcon
          code={student.school.code}
          size={schoolLogoSize ? schoolLogoSize : Size.Small}
        />
      )}

      {student.pastoral?.todayDetention && <DetentionTodayChip />}

      {student.activeOnCall && (
        <ActiveOnCallChip small={false} compact={false} />
      )}

      {children}
    </Wrapper>
  ) : null;
};

export default StudentProfileHeader;
