import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { 
  Title, 
  TitleSize, 
  Tabs, 
  LoadingWrapper
} from "ui-kit";
import { UserName } from "sharedComponents/common";
import Subheader from "sharedComponents/layout/header/subheader";
import paymentsActions from "areas/payments/actions/paymentsActions";
import UserDetails from "./userDetails";
import UserOrders from "./userOrders";
import UserStore from "./userStore";
import UserPrintCredits from "./userPrintCredits";
import UserLunchCredits from "./userLunchCredits";
import UserSmartcards from "./userSmartcards";
import UserPurchasedItems from "./userPurchasedItems";
import UserBasket from "./userBasket";
import { users } from "utils";
import { Constants } from "configuration";
import { RootState } from "reducers/store";
import qs from "query-string";
import { useAppSelector } from "reducers/hooks";


const UserViewer = () => {

  const { userId } = useParams();
  const location = useLocation();

  const { loading, error, user } = useAppSelector(state => state.paymentsUser);
  const { user: currentUser } = useAppSelector(state => state.currentUser);

  const [activeTab, setActiveTab] = useState<number>(parseInt(qs.parse(location.search).tab as string));

  useEffect(() => {
    userId && paymentsActions.getUser(userId);
  }, [userId]);

  const handleOrderPlaced = () => {
    paymentsActions.getUser(userId);
  };

  useEffect(() => {
    // Watches for QS changes in order to check for tab switching
    setActiveTab(parseInt(qs.parse(location.search).tab as string));
  }, [location.search]);

  const userIsBuyerOnly = () => {
    return !users.isInRole(currentUser, Constants.ROLES.SHOPKEEPER_ADMINISTRATOR) && !users.isInRole(currentUser, Constants.ROLES.SHOPKEEPER_USER);
  }


  return (
    <LoadingWrapper
      loading={loading}
      error={error != null}
      data={user}
      errorMessage="There was a problem loading the user"
      emptyMessage="The requested user was not found"
      emptyIcon="users"
    >
      {user && (
        <>
          <Subheader>
            <Title
              size={TitleSize.H2}
              text={<UserName user={user} />}
              sub={user.school.name}
            />
          </Subheader>
          <Tabs
            selectedIndex={activeTab}
            onTabSelected={(name, index) => setActiveTab(index)}
          >
            <Tabs.Pane name="Details" label="Details">
              <UserDetails user={user} />
            </Tabs.Pane>
            <Tabs.Pane name="Basket" label="Basket">
              <UserBasket user={user} onOrderPlaced={handleOrderPlaced} />
            </Tabs.Pane>
            <Tabs.Pane name="Store" label="Store">
              <UserStore user={user} />
            </Tabs.Pane>
            <Tabs.Pane name="Orders" label="Orders" hide={userIsBuyerOnly()}>
              <UserOrders user={user} />
            </Tabs.Pane>
            <Tabs.Pane
              name="Purchased Items"
              label="Purchased Items"
              hide={users.isParent(user) || userIsBuyerOnly()}
            >
              <UserPurchasedItems user={user} />
            </Tabs.Pane>
            <Tabs.Pane
              name="Smartcard"
              label="Smartcard"
              hide={users.isParent(user) || (users.isStudent(user) && users.isPrimary(user))}
            >
              <UserSmartcards user={user} />
            </Tabs.Pane>
            <Tabs.Pane
              name="Print Credits"
              label="Print Credits"
              hide={
                users.isParent(user) ||
                users.isFuture(user) ||
                users.isPrimary(user)
              }
            >
              <UserPrintCredits user={user} />
            </Tabs.Pane>
            <Tabs.Pane
              name="Lunch Credits"
              label="Lunch Credits"
              hide={!users.isStudent(user) || !user.school.isPrimary}
            >
              <UserLunchCredits user={user} />
            </Tabs.Pane>
          </Tabs>
        </>
      )}
    </LoadingWrapper>
  );
};

export default UserViewer;
