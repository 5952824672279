import { SchoolDropdown } from "sharedComponents/common";
import AcademicYearFilter from "sharedComponents/common/academicYearFilter";
import { StructuredList } from "ui-kit";
import { ICalendarReportProps } from ".";


const AttendanceSummaryReport: React.FC<ICalendarReportProps> = ({ filter, setFilter }) => {
  return (
  <>
    <StructuredList.Item name="School">
      <SchoolDropdown
        initialValue={filter.schoolId}
        onChange={value =>
          setFilter({ ...filter, schoolId: value })
        }
        fluid
      />
    </StructuredList.Item>
    <StructuredList.Item name="Academic Year">
      <AcademicYearFilter
        value={filter?.academicYearId}
        onChange={value => setFilter({...filter, academicYearId: value})}
      />
    </StructuredList.Item>
  </>
  )
}


export default AttendanceSummaryReport;