import { IBeehiveAction } from "types/common/action.types";
import groupActions from "../../actions/group/groupActions";
import { IBeehiveError } from "types/common/errors.types";
import { SchoolPeriodListView } from "types/planner/timetable.types";


interface IGroupPeriodsState {
  groupPeriods: SchoolPeriodListView[];
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IGroupPeriodsState = {
  groupPeriods: [],
  loading: false,
  error: null,
};


const groupPeriodsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IGroupPeriodsState => {

  const { GETGROUPPERIODS } = groupActions.types;

  switch (action.type) {
    case GETGROUPPERIODS.START:
      return { ...state, loading: true, error: null };

    case GETGROUPPERIODS.SUCCESS:
      return {
        ...state,
        groupPeriods: action.payload,
        loading: false,
      };

    case GETGROUPPERIODS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default groupPeriodsReducer;
