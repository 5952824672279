import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Subheader from "sharedComponents/layout/header/subheader";
import {
  Title,
  TitleSize,
  Card,
  ActionBar,
  Message,
  Swatches,
  TextInput,
  StructuredList,
  Size,
  Button,
  ValidationMessage
} from "ui-kit";
import Basket from "../basket";
import { arrays } from "utils";
import storeActions from "areas/payments/actions/storeActions";
import config from "configuration";
import { useNavigate } from "react-router-dom";
import { StripeBlackLogo } from "./stripeLogo";
import { RootState } from "reducers/store";
import { TransferToPaymentProviderCommand } from "areas/payments/types/shopkeeper/shopkeeperRequests.types";
import { useScroll } from "hooks/useScroll";


const CHECKOUT_IFRAME_ID = "checkout-frame";


const Checkout = () => {

  const { scrollToTop } = useScroll();
  const { user } = useSelector((state: RootState) => state.currentUser);
  var { basket, redirecting, checkoutUrl, checkoutActive, errors: basketError } = useSelector((state: RootState) => state.basket);

  const navigate = useNavigate();

  const [checkoutData, setCheckoutData] = useState<TransferToPaymentProviderCommand | null>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [basketErrors, setBasketErrors] = useState<string[]>([]);

  useEffect(() => {
    user &&
      setCheckoutData({
        userId: user.id,
        userDetails: {
          firstName: user.details.firstName,
          lastName: user.details.lastName,
          ...user.details.address
        }
      });
  }, [user]);

  useEffect(() => {
    basketError && scrollToTop();
  }, [basketError]);

  useEffect(() => {
    if (basketError?.item == "Invalid items found" && !basket?.validity?.isValid) {
      var errors: string[] = [];
      basket.validity.expiredItems?.forEach(invalidItem => {
        errors.push(`${basket.validity.message}: "${invalidItem.name}". Please remove this item.`)
      });
      setBasketErrors(errors);
    }
  }, [basketError])

  const closePaymentModal = () => {
    storeActions.closeCheckout();
  };

  const handleCheckout = () => {
    var errors = [];

    if (!basket || basket.items.length == 0) {
      errors.push("Your basket is empty.");
    }

    if (!checkoutData.userDetails.firstName) {
      errors.push("Please enter the cardholder's first name");
    }
    if (!checkoutData.userDetails.lastName) {
      errors.push("Please enter the cardholder's last name");
    }
    if (!checkoutData.userDetails.address1) {
      errors.push(
        "Please enter the house or flat number of the cardholder's address"
      );
    }
    if (!checkoutData.userDetails.address2) {
      errors.push("Please enter the street of the cardholder's address");
    }
    if (!checkoutData.userDetails.town) {
      errors.push("Please enter the town of the cardholder's address");
    }
    if (!checkoutData.userDetails.postcode) {
      errors.push("Please enter the postcode of the cardholder's address");
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    storeActions.startCheckout(user.id, checkoutData, response =>
      window.location.replace(response)
    );
  };

  const frameLoaded = () => {
    var url = (document.getElementById(CHECKOUT_IFRAME_ID) as HTMLIFrameElement).src;

    if (url === config.payments.SAGEPAY_NOTIFICATION_SUCCESS_URL) {
      storeActions.resetCheckout();
    } else {
      // console.log("SAGEURL", url);
    }
  };

  return (
    <>
      <Subheader>
        <Title size={TitleSize.H2} text="Store" sub="Checkout" />
      </Subheader>

      {!arrays.isEmpty(validationErrors) && (
        <Message
          text={validationErrors.map((err, index) => (
            <React.Fragment key={index}>
              {err}
              <br />
            </React.Fragment>
          ))}
          color={Swatches.Danger}
        />
      )}
      {/* {basketError.item && (
        <Message text={basketError.item} color={Swatches.Danger} />
      )} */}
      <ValidationMessage errors={basketErrors} />

      {basket && process.env.REACT_APP_ENVIRONMENT_NAME != "Production" && (
        <Message
          text={`The checkout is currently using the ${basket?.paymentProcessorMode} payment mode`}
          color={
            basket?.paymentProcessorMode.toLowerCase() == "live"
              ? Swatches.Success
              : Swatches.Warning
          }
        />
      )}

      <Basket hideErrors title={"Your Basket"} />

      {basket && !arrays.isEmpty(basket.items) && (
        <>
          <Card title={"Cardholder Details"}>
            <Card.Body>
              <p>
                The card holder details above have been filled automatically
                with the details we hold on your account.
                {/* If your details need
                to be updated, please{" "}
                <Link to="/main/profile/update">request an update</Link>. */}
              </p>
              <p>
                If you want to make payment with a card that is not registered
                to you, please update the cardholder details below. Please note
                that we do not retain additional cardholder details or store any
                credit card details.
              </p>
              <StructuredList>
                <StructuredList.Item name="First Name" required>
                  <TextInput
                    maxLength={30}
                    value={checkoutData?.userDetails.firstName}
                    placeholder="Cardholder's First Name"
                    onChange={value =>
                      setCheckoutData({
                        ...checkoutData,
                        userDetails: {
                          ...checkoutData.userDetails,
                          firstName: value
                        }
                      })
                    }
                    fluid
                  />
                </StructuredList.Item>
                <StructuredList.Item name="Last Name" required>
                  <TextInput
                    maxLength={30}
                    value={checkoutData?.userDetails.lastName}
                    placeholder="Cardholder's Last Name"
                    onChange={value =>
                      setCheckoutData({
                        ...checkoutData,
                        userDetails: {
                          ...checkoutData.userDetails,
                          lastName: value
                        }
                      })
                    }
                    fluid
                  />
                </StructuredList.Item>

                <StructuredList.Item
                  name="Address"
                  description="This should be the address that the payment card is registered to."
                  required
                >
                  <TextInput
                    value={checkoutData?.userDetails.address1}
                    maxLength={30}
                    onChange={value =>
                      setCheckoutData({
                        ...checkoutData,
                        userDetails: {
                          ...checkoutData.userDetails,
                          address1: value
                        }
                      })
                    }
                    fluid
                    placeholder="House/Flat No."
                  />
                  <br />
                  <TextInput
                    value={checkoutData?.userDetails.address2}
                    maxLength={30}
                    onChange={value =>
                      setCheckoutData({
                        ...checkoutData,
                        userDetails: {
                          ...checkoutData.userDetails,
                          address2: value
                        }
                      })
                    }
                    placeholder="Address Line 2"
                    fluid
                  />
                  <br />
                  <TextInput
                    value={checkoutData?.userDetails.address3}
                    maxLength={30}
                    onChange={value =>
                      setCheckoutData({
                        ...checkoutData,
                        userDetails: {
                          ...checkoutData.userDetails,
                          address3: value
                        }
                      })
                    }
                    fluid
                    placeholder="Address Line 3"
                  />
                  <br />
                  <TextInput
                    value={checkoutData?.userDetails.town}
                    maxLength={30}
                    onChange={value =>
                      setCheckoutData({
                        ...checkoutData,
                        userDetails: {
                          ...checkoutData.userDetails,
                          town: value
                        }
                      })
                    }
                    placeholder="Town"
                    fluid
                  />
                  <br />
                  {/* <TextInput
                    value={checkoutData?.userDetails.county}
                    maxLength={30}
                    onChange={value =>
                      setCheckoutData({
                        ...checkoutData,
                        userDetails: {
                          ...checkoutData.userDetails,
                          county: value
                        }
                      })
                    }
                    placeholder="County"
                    fluid
                  />
                  <br /> */}
                  <TextInput
                    value={checkoutData?.userDetails.postcode}
                    maxLength={30}
                    onChange={value =>
                      setCheckoutData({
                        ...checkoutData,
                        userDetails: {
                          ...checkoutData.userDetails,
                          postcode: value
                        }
                      })
                    }
                    placeholder="Postcode"
                  />
                  <br />
                </StructuredList.Item>
              </StructuredList>
            </Card.Body>
          </Card>

          <ActionBar fluid low>
            <Button
              fluid
              text="Pay Securely"
              icon="lock"
              color={Swatches.Success}
              size={Size.Large}
              onClick={handleCheckout}
              working={redirecting}
            />
          </ActionBar>

          <StripeBlackLogo
            height={30}
            style={{ marginTop: 20, marginBottom: 24 }}
          />

          {/* <Modal
            title="Secure Checkout"
            open={checkoutActive}
            onClose={closePaymentModal}
            width="75%"
            height="65%"
          >
            <Modal.Body noPad>
              <iframe
                title="Checkout"
                id={CHECKOUT_IFRAME_ID}
                src={checkoutUrl}
                width="100%"
                height="100%"
                onLoad={frameLoaded}
              />
            </Modal.Body>
          </Modal> */}
        </>
      )}
    </>
  );
};
export default Checkout;
