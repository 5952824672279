import { BeehiveReport, IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";
import formReportingActions from "../actions/formReportingActions";


interface IFormsReportingState {
  data: BeehiveReport | null;
  declarationsOfInterest: null;
  loading: boolean;
  error: IBeehiveError | null;
}

const INITIAL_STATE: IFormsReportingState = {
  data: null,
  declarationsOfInterest: null,
  loading: false,
  error: null
};


const formsReportingReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IFormsReportingState => {
  
  const { 
    EXPORT_DECLARATIONSOFINTEREST
  } = formReportingActions.types;

  switch (action.type) {

    case EXPORT_DECLARATIONSOFINTEREST.START:
      return {
        ...state,
        loading: true,
        error: null
      }

    case EXPORT_DECLARATIONSOFINTEREST.SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      } 

    case EXPORT_DECLARATIONSOFINTEREST.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      } 

    default:
      return state;
  }
};

export default formsReportingReducer;
