import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAction } from "hooks";
import {
  Modal,
  Button,
  Right,
  ActionBar,
  Swatches,
  Size,
  StructuredList,
  Card,
  EmptyMessage,
  TextInput,
  TextInputType,
  Message,
  ToastService,
} from "ui-kit";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import userManagementActions from "areas/administration/actions/userManagementActions";
import { managedStaff } from "areas/administration/reducers/userManagement/managedUserReducer";
import { SchoolListView } from "types/schools/schools.types";
import { RootState } from "reducers/store";
import { ManagedStaffSchoolView } from "types/users/userSchools.types";
import constants from "configuration/constants";


interface IStaffSchoolsModalProps {
  user: managedStaff; 
  school: ManagedStaffSchoolView; 
  open: boolean;
  onCancel?: () => void;
  onSaved?: (staff: managedStaff) => void;
}

export interface schoolOption {
  id: number;
  name: string;
  code: string;
}

interface staffSchoolData {
  id: number;
  code: string;
  simsId: string;
  personId: number;
}


const StaffSchoolsModal: React.FC<IStaffSchoolsModalProps> = ({ user, school, open, onCancel, onSaved }) => {

  const [_user, _setUser] = useState<managedStaff | null>(null);
  const [_schoolData, _setSchoolData] = useState<staffSchoolData | null>(null);
  const [_open, _setOpen] = useState<boolean>(false);

  const { actions } = useSelector((state: RootState) => state.managedUser);

  useEffect(() => {
    _setUser(user);
  }, [user]);

  useEffect(() => {
    _setSchoolData({
      ..._schoolData,
      id: school?.id,
      code: school?.code,
      simsId: school?.simsId,
      personId: school?.personId,
    });
  }, [school]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const handleSchoolChanged = (val: number) => {
    var selectedSchool = constants.SCHOOLS.find(x => x.id === val);
    _setSchoolData({ ..._schoolData, id: val, code: selectedSchool?.code });
  };

  const handleSave = () => {
    const data = {
      userId: _user.id,
      schoolId: _schoolData.id,
      simsId: _schoolData.simsId,
      personId: _schoolData.personId,
    };

    userManagementActions.setStaffSchool(_user.id, data, () => {
      onSaved?.(_user);
      ToastService.pop("School Updated", null, "user");
    });
  };

  return (
    <Modal title="School" open={_open} onClose={onCancel}>
      <Modal.Body>
        <Message text={actions.updateSchool?.error} color={Swatches.Danger} />
        {_user ? (
          <Card>
            <Card.Body>
              {/* <pre>{JSON.stringify(_schoolData, null, 2)}</pre> */}
              <StructuredList>
                <StructuredList.Item name="School" required>
                  <SchoolDropdown
                    initialValue={_schoolData.id}
                    fluid
                    onChange={handleSchoolChanged}
                    schools={constants.SCHOOLS}
                    showAllSchools
                  />
                </StructuredList.Item>
                <StructuredList.Item
                  name="Staff Number"
                  required
                  description={
                    <span>
                      This is the staff number specific to the school selected{" "}
                      <b>without</b> the code prefix.
                    </span>
                  }
                >
                  <TextInput
                    type={TextInputType.Number}
                    value={_schoolData.simsId}
                    onChange={val =>
                      _setSchoolData({ ..._schoolData, simsId: val.toString() })
                    }
                    fluid
                    placeholder="e.g. 01234"
                    prefix={
                      _schoolData.code != null ? `${_schoolData.code}S` : ``
                    }
                  />
                </StructuredList.Item>
                {/* <StructuredList.Item
                  name="SIMS Person ID"
                  required
                  description={
                    <span>
                      This is SIMS Person ID specific to the school selected.
                    </span>
                  }
                >
                  <TextInput
                    value={_schoolData.personId}
                    number
                    onChange={(val) =>
                      _setSchoolData({ ..._schoolData, personId: val })
                    }
                    fluid
                    placeholder="e.g. 12345"
                  />
                </StructuredList.Item> */}
              </StructuredList>
            </Card.Body>
          </Card>
        ) : (
          <EmptyMessage
            icon="user"
            title="No Staff Member"
            summary="No staff member was selected"
            cover
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              onClick={onCancel}
              size={Size.Small}
              color={Swatches.Low}
              text="Cancel"
              working={actions.updateSchool?.working}
            />
            <Button
              onClick={handleSave}
              size={Size.Small}
              color={Swatches.Success}
              text="Save"
              working={actions.updateSchool?.working}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default StaffSchoolsModal;
