import { WallPlannerEventView } from "areas/calendar/types/calendarResponses.types";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAppSelector } from "reducers/hooks";
import { ActionBar, Button, Card, DatePicker, Left, Message, Modal, Right, Size, StructuredList, Swatches, TextInput, ValidationMessage } from "ui-kit";
import { arrays } from "utils";
import WpCategoryDropdown from "./wallplannerCategoryDropdown";
import { SchoolPicker } from "sharedComponents/common";
import { SchoolWithRead } from "sharedComponents/common/schools/schoolPicker";
import { SaveWallplannerEventCommand } from "areas/calendar/types/calendarRequests.types";

interface IWpEditModalProps {
  event: WallPlannerEventView;
  open: boolean;
  setRememberedDates: React.Dispatch<React.SetStateAction<{
    startDate: Date;
    endDate: Date;
  }>>;
  onClose?: () => void;
  onSave?: (event: SaveWallplannerEventCommand) => void;
}

const WpEditModal: React.FC<IWpEditModalProps> = ({ event, open, setRememberedDates, onClose, onSave }) => {

  const { working, error } = useAppSelector(state => state.wallPlannerSessions.selectedEvent);
  const [_event, _setEvent] = useState<SaveWallplannerEventCommand | null>();
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    event && _setEvent({
      id: event.id,
      name: event.name,
      categoryId: event.categoryId,
      newCategoryId: null,
      schools: (event.schools.length < 1) ? [99] : event.schools, //If there are no schools the set it to a trust event
      groups: [],
      assignedTo: [],
      userRestrictions: {
        allowParents: true,
        allowStudents: true,
        allowStaff: true,
      },
      startDate: event.startDate,
      endDate: event.endDate
    });
  }, [event]);

  const handleClose = () => {
    setValidationErrors([]);
    onClose?.();
  };

  const handleSave = () => {
    setValidationErrors([]);
    var errors = [];

    if (_event.name == null) {
      errors.push("Event must have a name.");
    }
    if (_event.categoryId == null) {
      errors.push("Event must have a category.");
    }
    if (_event.startDate == null) {
      errors.push("Event must have a start date.");
    }
    if (_event.endDate == null) {
      errors.push("Event must have an end date. Even if it is the same day.");
    }
    if (moment(_event.startDate).isAfter(_event.endDate)) {
      errors.push("The end date must be after the start date.");
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      return;
    }

    onSave?.(_event);

    _setEvent(null);
  };

  const handleSchoolsChange = (schools: SchoolWithRead[]) => {
    var newSchools = schools?.filter(s => s.selected).map(s => s.id);

    _setEvent({
      ..._event,
      schools: [99, ...newSchools],
    });
  };

  const hasGranularSchoolsSet = () =>
    _event.schools.filter(school => school != 99).length > 0;

  const handleCategoryChange = (catId: number) => {
    if (_event.id != null) {
      _setEvent({ ..._event, newCategoryId: catId })
    }
    else {
      _setEvent({ ..._event, categoryId: catId })
    }
  }

  return _event ? (
    <Modal
      title={_event?.id ? `Edit Event` : "New Event"}
      open={open}
      onClose={handleClose}
      width="60%"
      height="60%"
    >
      <Modal.Body>
        <ValidationMessage errors={validationErrors} />
        <Message text={error} color={Swatches.Danger} />
        <Card>
          <Card.Body>
            <StructuredList>
              <StructuredList.Item name="Title" required>
                <TextInput
                  value={_event?.name}
                  onChange={value => _setEvent({ ..._event, name: value })}
                  characterCount={true}
                  maxLength={75}
                  fluid
                />
              </StructuredList.Item>
              <StructuredList.Item name="Category" required>
                <WpCategoryDropdown
                  value={_event?.categoryId}
                  onChange={handleCategoryChange}
                />
              </StructuredList.Item>
              <StructuredList.Item name="Start Date" required>
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  placeholder="Pick a start date..."
                  closeOnSelect
                  selectedDate={_event.startDate}
                  onChange={value => {
                    _setEvent({ ..._event, startDate: value });
                    setRememberedDates(prev => ({ ...prev, startDate: value }));
                  }}
                />
              </StructuredList.Item>
              <StructuredList.Item name="End Date" required>
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  placeholder="Pick an end date..."
                  closeOnSelect
                  selectedDate={_event.endDate}
                  onChange={value => {
                    _setEvent({ ..._event, endDate: value });
                    setRememberedDates(prev => ({ ...prev, endDate: value }));
                  }}
                />
              </StructuredList.Item>
              {_event.schools.some(school => school == 99) && (
                <StructuredList.Item
                  name="Schools"
                  description="The schools that the event applies to. If left blank, event will appear for all schools."
                >
                  <SchoolPicker
                    onChange={handleSchoolsChange}
                    value={_event.schools.filter(school => school != 99).map(s => ({ id: s}))}
                    selectedTooltip={school =>
                      `This event is set for ${school.name} `
                    }
                    deselectedTooltip={school =>
                      hasGranularSchoolsSet()
                        ? `This event is not set for ${school.name} `
                        : `No indiviudal schools selected, therefore event applies to ${school.name} `
                    }
                    readOnlyTooltip={school =>
                      `You do not have permission to set or unset events for ${school.name} `
                    }
                  />
                </StructuredList.Item>
              )}
            </StructuredList>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <ActionBar low>
          <Left>
            <Button
              text="Close"
              onClick={handleClose}
              color={Swatches.Low}
              size={Size.Small}
              working={working}
            />
          </Left>
          <Right>
            <Button
              text="Save"
              onClick={handleSave}
              color={Swatches.Success}
              size={Size.Small}
              working={working}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  ) : null;
};


export default WpEditModal;