import defaults from "reducers/defaults";
import behaviourAnalyticsActions from "../actions/behaviourAnalyticsActions";
import { PageInfo } from "types/common/paging.types";
import { IBeehiveError } from "types/common/errors.types";
import { IBeehiveAction } from "types/common/action.types";
import { BehaviourRewardsDetailReportListView } from "../types/behaviourAnalyticsResponse.types";


interface IBehaviourDetailedReportState {
  report: BehaviourRewardsDetailReportListView[] | null;
  paging: PageInfo;
  refresh: boolean;
  loading: boolean;
  loadingExportReport: boolean;
  error: IBeehiveError | null;
}

const INITIAL_STATE: IBehaviourDetailedReportState = {
  report: null,
  paging: defaults.DEFAULT_PAGING,
  refresh: false,
  loading: false,
  loadingExportReport: false,
  error: null,
};


const behaviourDetailedReportReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IBehaviourDetailedReportState => {

  const { 
    GETBEHAVIOURDETAILEDREPORT, 
    EXPORTBEHAVIOURDETAILEDREPORT 
  } = behaviourAnalyticsActions.types;

  switch (action.type) {
    case GETBEHAVIOURDETAILEDREPORT.START:
      return { ...state, loading: true, error: null };

    case GETBEHAVIOURDETAILEDREPORT.SUCCESS:
      return {
        ...state,
        report:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.report, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };

    case GETBEHAVIOURDETAILEDREPORT.FAILED:
      return { ...state, loading: false, error: action.payload };

    case EXPORTBEHAVIOURDETAILEDREPORT.START:
      return { ...state, loadingExportReport: true, error: null };

    case EXPORTBEHAVIOURDETAILEDREPORT.SUCCESS:
      return { ...state, loadingExportReport: false };

    case EXPORTBEHAVIOURDETAILEDREPORT.FAILED:
      return { ...state, loadingExportReport: false, error: action.payload };

    default:
      return state;
  }
};

export default behaviourDetailedReportReducer;
