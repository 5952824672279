import React from "react";
import { ReactComponent as LionSvg } from "assets/primary/characters/lion.svg";
import { IAnimalIconProps } from "./primaryCharacter";


export const Lion: React.FC<IAnimalIconProps> = ({ className }) => {

  return (
    <LionSvg 
      //alt={alt} 
      className={className} 
    />
  )
};

export default Lion;
