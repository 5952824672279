import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ClassLayoutMenuWrapper from "./classLayoutMenuWrapper";
import ClassLayoutUnseatedStudentList from "./classLayoutUnseatedStudentList";
import styled from "styled-components";
import { Message, Swatches, EmptyMessage, Loader, Button, Size } from "ui-kit";
import ClassLayoutCreatorWrapper from "./classLayoutCreatorWrapper";
import ClassLayoutGridWrapper from "./classLayoutGridWrapper";
import classViewerActions from "areas/planner/actions/classViewer/classViewerActions";
import groupActions from "areas/planner/actions/group/groupActions";
import {
  handleOnDropGrid,
  handleOnDropStudentList,
  removeAllStudentsFromPositions,
  removeStudentFromGrid,
} from "areas/planner/utils/layouts";
import classRoomActions from "areas/planner/actions/classViewer/classRoomActions";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import QuizModal from "areas/planner/components/groups/quiz/quizModal";
import MultipleRewardFlyout from "areas/behaviour/components/rewards/multipleRewardFlyout";
import MultipleBehaviourFlyout from "areas/behaviour/components/behaviours/multipleBehaviours/multipleBehaviourFlyout";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
`;

const ButtonWrapper = styled.div`
  width: fit-content;
  align-self: center;
`;

const ClassLayoutWrapper = ({ openLayoutCreator }) => {
  const { selectedLayoutId, group, load } = useSelector(state => state.group);
  const { classLayout, loadingClassLayout, refresh, error } = useSelector(
    state => state.classLayout
  );

  const [classLayouts, setClassLayouts] = useState([]);
  const [selectedClassLayout, setSelectedClassLayout] = useState(null);
  const [openQuizModal, setOpenQuizModal] = useState(false);
  const [randomStudent, setRandomStudent] = useState(null);
  const [openRoomLayoutWizard, setOpenRoomLayoutWizard] = useState(false);

  const [multipleStudents, setMultipleStudents] = useState([]);

  useEffect(() => {
    if (group) {
      setClassLayouts(group.layouts);
      if (
        group.layouts?.length > 0 &&
        group.defaultLayout &&
        !selectedLayoutId
      ) {
        groupActions.getDefaultLayout(group.defaultLayout);
      }
    }
  }, [group]);

  useEffect(() => {
    if (classLayout) {
      setSelectedClassLayout(JSON.parse(JSON.stringify(classLayout)));
    }
  }, [classLayout]);

  // Stopping class layout from crashing, maybe by creating a delay so gives time to register moving students around
  useEffect(() => {}, [selectedClassLayout]);

  useEffect(() => {
    if (selectedLayoutId) {
      classViewerActions.getClassLayout(selectedLayoutId);
    }
  }, [selectedLayoutId]);

  useEffect(() => {
    if (refresh) {
      classViewerActions.getClassLayout(selectedLayoutId);
    }
  }, [refresh]);

  const handleSelectLayout = chosenLayoutId => {
    const layout = classLayouts.find(layout => layout.id === chosenLayoutId);
    if (layout && selectedLayoutId !== chosenLayoutId) {
      classViewerActions.selectLayout(chosenLayoutId);
    }
  };

  const resetLayoutToEmpty = () => {
    const newClassLayout = removeAllStudentsFromPositions(selectedClassLayout);
    setSelectedClassLayout(newClassLayout);
  };

  let studentBeingDraggedId;
  const onDragStart = event => {
    studentBeingDraggedId = event.target.getAttribute("data-id");
    event.target.firstChild.classList.add("hide-cross");
  };

  const onDropGrid = (event, row, col) => {
    let dropzone = event.target;

    const newClassLayout = handleOnDropGrid(
      dropzone,
      studentBeingDraggedId,
      col,
      row,
      selectedClassLayout
    );
    setSelectedClassLayout(newClassLayout);

    dropzone.style = {};
  };

  const removeStudentFromGridLayout = id => {
    const newClassLayout = removeStudentFromGrid(id, selectedClassLayout);
    setSelectedClassLayout(newClassLayout);
  };

  const onDropStudentList = event => {
    let dropzone = event.target;

    handleOnDropStudentList(
      dropzone,
      studentBeingDraggedId,
      selectedClassLayout,
      removeStudentFromGridLayout
    );

    dropzone.style = {};
  };

  const onClickPickAStudent = student => {
    setRandomStudent(student);
    setOpenQuizModal(true);
  };

  // const handleDeleteRoomLayout = () => {
  //   classRoomActions.deleteRoomLayout(group.school.id, classLayout.roomId);
  //   setOpenRoomLayoutWizard(true);
  // };

  const handleSelectStudent = student => {
    const tempMultipleStudents = multipleStudents.slice();
    const index = tempMultipleStudents.findIndex(
      selectedStudent => selectedStudent.id === student.id
    );

    if (index === -1) {
      tempMultipleStudents.push(student);
    } else if (index > -1) {
      tempMultipleStudents.splice(index, 1);
    }
    setMultipleStudents(tempMultipleStudents);
  };

  const handleSelectAllStudents = () => {
    const tempSelectedStudents = [];
    group.students.forEach(student => {
      tempSelectedStudents.push(student);
    });
    setMultipleStudents(tempSelectedStudents);
  };

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return openRoomLayoutWizard ? (
    <ClassLayoutCreatorWrapper
      group={group}
      closeLayoutCreator={() => setOpenRoomLayoutWizard(false)}
    />
  ) : // : loadingClassLayout ? (
  //   <Loader size={Size.Medium} fluid />
  // )
  selectedLayoutId && selectedClassLayout ? (
    <>
      <ClassLayoutMenuWrapper
        isDefault={selectedClassLayout.isDefault}
        handleSelectLayout={handleSelectLayout}
        openLayoutCreator={openLayoutCreator}
        selectedClassLayout={selectedClassLayout}
        // handleDeleteRoomLayout={handleDeleteRoomLayout}
        studentSlots={selectedClassLayout.studentSlots}
        handleResetLayout={resetLayoutToEmpty}
        selectedStudents={multipleStudents}
        handleSelectAllStudents={handleSelectAllStudents}
        handleUnselectAllStudents={() => setMultipleStudents([])}
        handlePickAStudent={onClickPickAStudent}
        groupIsActive={group.isActive}
        loading={loadingClassLayout}
      />

      {selectedClassLayout.studentSlots.length === 0 ? (
        <>
          <Message
            text="The tables from this room have been deleted. You can create the room layout by clicking on the 'Create Room Layout' button below."
            color={Swatches.Warning}
          />
          <EmptyMessage
            title="No Room Layout Created"
            icon="users-class"
            summary="You can create the room layout by clicking on the 'Create Room Layout' button below."
          />
          <ButtonWrapper>
            <Button
              text="Create Room Layout"
              color={Swatches.Primary}
              onClick={openLayoutCreator}
            />
          </ButtonWrapper>
        </>
      ) : (
        <Wrapper data-testid="component-ClassViewer">
          <ClassLayoutGridWrapper
            classLayout={selectedClassLayout}
            onDragStart={onDragStart}
            onDropGrid={onDropGrid}
            removeStudent={removeStudentFromGridLayout}
            selectedStudents={multipleStudents}
            handleSelectStudent={handleSelectStudent}
            schoolId={group?.school?.id}
            loading={loadingClassLayout}
          />
          <ClassLayoutUnseatedStudentList
            unseatedStudents={selectedClassLayout.unseatedStudents}
            onDragStart={onDragStart}
            onDropStudentList={onDropStudentList}
            selectedStudents={multipleStudents}
            handleSelectStudent={handleSelectStudent}
          />
        </Wrapper>
      )}

      {openQuizModal && (
        <QuizModal
          openModal={openQuizModal}
          setOpenModal={(open) => setOpenQuizModal(open)}
          student={randomStudent}
          setStudent={setRandomStudent}
          group={group}
        />
      )}

      <MultipleRewardFlyout
        students={multipleStudents}
        group={group}
        resetSelectedStudents={() => setMultipleStudents([])}
      />

      <MultipleBehaviourFlyout
        students={multipleStudents}
        group={group}
        resetSelectedStudents={() => setMultipleStudents([])}
      />
    </>
  ) : null;
};

ClassLayoutWrapper.propTypes = {
  setDisplayLayoutCreator: PropTypes.func,
};

export default ClassLayoutWrapper;
