import { useEffect, useState } from "react";
import {
  Modal,
  StructuredList,
  Card,
  Currency,
  EmptyMessage,
  DateTime,
  Table,
  CopyToClipboard,
  Sub,
} from "ui-kit";
import { Avatar } from "sharedComponents/common";
import { arrays } from "utils";
import { IncomingTransactionListView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";


interface ILinkedTransactionsModalProps {
  transaction: IncomingTransactionListView;
  open: boolean;
  onClose?: () => void;
}


const LinkedTransactionsModal: React.FC<ILinkedTransactionsModalProps> = ({ transaction, open, onClose }) => {

  const [_transaction, _setTransaction] = useState<IncomingTransactionListView | null>(null);
  const [_open, _setOpen] = useState<boolean>(false);

  useEffect(() => {
    _setTransaction(transaction);
  }, [transaction]);

  useEffect(() => {
    _setOpen(open);
  }, [open]);

  const handleClose = () => {
    onClose?.();
  };

  const copyToClipboard = (item: string) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(item);
    }
  };

  return (
    <Modal
      title="Linked Transactions"
      open={_open}
      onClose={handleClose}
      width="80%"
      height="70%"
    >
      <Modal.Body>
        {_transaction ? (
          <>
            <Card>
              <Card.Body>
                <StructuredList>
                  <StructuredList.Item name="Date">
                    <DateTime bold date={_transaction.date} />
                  </StructuredList.Item>
                  <StructuredList.Item name="Stripe Transaction ID">
                    {_transaction.transactionId}
                    <CopyToClipboard text={_transaction.transactionId} />
                  </StructuredList.Item>
                  <StructuredList.Item name="Stripe Payment Intent">
                    {_transaction.paymentIntent}
                    <CopyToClipboard text={_transaction.paymentIntent} />
                  </StructuredList.Item>
                  <StructuredList.Item name="Description">
                    <Sub>{_transaction.description}</Sub>
                  </StructuredList.Item>
                </StructuredList>
              </Card.Body>
            </Card>
            <Card title="Linked Transactions">
              <Card.Body noPad>
                {!arrays.isEmpty(_transaction.linkedTransactions) ? (
                  <Table zebra>
                    <Table.Header>
                      <Table.HeaderCell width={0.5}>ID</Table.HeaderCell>
                      <Table.HeaderCell width={1}>Basket ID</Table.HeaderCell>
                      <Table.HeaderCell width={2}>User</Table.HeaderCell>
                      <Table.HeaderCell width={1} right>
                        Total
                      </Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                      {_transaction.linkedTransactions.map((linked, index: number) => (
                        <Table.Row key={index}>
                          <Table.Cell>{linked.id}</Table.Cell>
                          <Table.Cell>{linked.basketId}</Table.Cell>
                          <Table.Cell>
                            <Avatar user={linked.user} />
                          </Table.Cell>
                          <Table.Cell right>
                            <b>
                              <Currency value={linked.total} />
                            </b>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                ) : (
                  <EmptyMessage
                    icon="clipboard"
                    title="No Linked Transactions"
                    summary="No linked transactions were found"
                    cover
                  />
                )}
              </Card.Body>
            </Card>
          </>
        ) : (
          <EmptyMessage
            icon="transaction"
            title="No Transaction"
            summary="No transaction was selected"
            cover
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default LinkedTransactionsModal;
