import React from "react";
import styled from "styled-components";
import { Chip, textFont, fontStyle, typescale, neutral } from "ui-kit";
import { ColorSwatch } from "ui-kit/common/colors";

const Wrapper = styled.div`
  .chip {
    ${fontStyle(textFont.medium, typescale.paragraph, neutral[100])};
    width: 50px;
  }
`;


interface IBehaviourCountChipProps {
  count: number;
  color: ColorSwatch;
}

const BehaviourCountChip: React.FC<IBehaviourCountChipProps> = ({ count, color }) => {
  return (
    <Wrapper>
      <Chip text={count} colorSwatch={color} />
    </Wrapper>
  );
};

export default BehaviourCountChip;
