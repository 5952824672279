import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import BehaviourCategoryFormWrapper from "./behaviourCategoryFormWrapper";
import {
  StructuredList,
  Button,
  Card,
  TextInput,
  TextInputType,
  Swatches,
  ActionBar,
  ValidationMessage,
  Checkbox,
  Loader,
  Size,
  Message,
  Right,
  Spacing,
  Modal,
} from "ui-kit";
import styled from "styled-components";
import {
  detentionValidation,
  getIncidentDetails,
} from "areas/behaviour/utils/behaviours";
import { IDetentionDetails, IIncidentDetails, getDefaultDetentionInfo } from "areas/behaviour/utils/detentions";
import {
  DETENTION_OPTIONS_KEYS,
  DETENTION_CATEGORIES,
} from "areas/behaviour/constants/detentions";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import ReadOnlyBehaviourDetails from "areas/behaviour/components/readOnly/readOnlyBehaviourDetails";
import ReadOnlyIncidentDetails from "areas/behaviour/components/readOnly/readOnlyIncidentDetails";
import TriggeredDetentionMessage from "../../detentions/triggeredDetentionMessage";
import ConfirmModal from "sharedComponents/common/confirmModal";
import { CODE_SEVERITY_INDEX } from "areas/behaviour/constants/behaviours";
import DetentionFormWrapper from "../../detentions/detentionFormWrapper";
import MultipleSanctionsTable from "../../detentions/multipleSanctionsTable";
import moment from "moment";
import AddSanctionDayButton from "./addSanctionDayButton";
import { useAppSelector } from "reducers/hooks";
import { BehaviourView, DetentionStudentSummaryView, DetentionTypeView } from "areas/behaviour/types/behaviourResponses.types";
import { PlannerSchoolView } from "areas/planner/types/plannerSchoolResponse.types";
import { IBehaviourCategory } from "./managePendingAttentionFormWrapper";
import { DetentionOption } from "areas/behaviour/types/behaviourShared.types";


const LoaderWrapper = styled.div`
  padding: ${Spacing.Medium}px 0 ${Spacing.Small}px 0;
`;

const AddSantionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${Spacing.Medium}px;

  .circle-icon {
    margin-right: ${Spacing.Medium}px;
  }
`;


interface IManagePendingAttentionFormProps {
  studentId: string;
  behaviour: BehaviourView;
  defaultDetention: DetentionTypeView;
  schoolInformation: PlannerSchoolView;
  detentionSummary: DetentionStudentSummaryView[];
  behaviourCategory: IBehaviourCategory;
  setBehaviourCategory: (category: IBehaviourCategory) => void;
  onClickAuthoriseBehaviour: (detentions: IDetentionDetails[], investigationNotes: string) => void;
  closeModal: () => void;
  handleRevokeBehaviour: () => void;
  loadingDefaultDetention: boolean;
}


const ManagePendingAttentionForm: React.FC<IManagePendingAttentionFormProps> = ({
  studentId,
  behaviour,
  defaultDetention,
  schoolInformation,
  detentionSummary,
  behaviourCategory,
  setBehaviourCategory,
  onClickAuthoriseBehaviour,
  closeModal,
  handleRevokeBehaviour,
  loadingDefaultDetention,
}) => {

  const { updateBehaviourError } = useAppSelector(state => state.behaviour);
  const { loading, error: authoriseError } = useAppSelector(
    state => state.pendingAttentionBehaviour
  );
  const { loadingRevokeBehaviour } = useAppSelector(state => state.behaviour);

  const [issueDetention, setIssueDetention] = useState<boolean>(false);
  const [detentionTypeInfo, setDetentionTypeInfo] = useState<IDetentionDetails>(null);

  const [selectedDetention, setSelectedDetention] = useState<IDetentionDetails>(null);
  const [openSanctionModal, setOpenSanctionModal] = useState<boolean>(false);

  const [detentions, setDetentions] = useState<IDetentionDetails[]>([]);
  const [incidentDetails, setIncidentDetails] = useState<IIncidentDetails>(null);
  const [investigationNotes, setInvestigationNotes] = useState<string>("");
  const [detentionOptionId, setDetentionOptionId] = useState<DetentionOption>(null);

  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>(null);
  const [textLengthError, setTextLengthError] = useState<string>(null);

  useEffect(() => {
    setIncidentDetails(getIncidentDetails(behaviour));
    setValidationErrors(null);
    setTextLengthError(null);
  }, [behaviour]);

  useEffect(() => {
    if (defaultDetention) {
      setDetentionOptionId(defaultDetention.detentionOptionId);
      setIssueDetention(false);

      const detentionInfo = defaultDetention.detentionTypeInformation
        ? getDefaultDetentionInfo(
            defaultDetention.detentionTypeInformation,
            null,
            null,
            schoolInformation?.id
          )
        : null;

        console.log(`DETENTION INFO:`);
        console.log(detentionInfo);

      setDetentionTypeInfo(detentionInfo);
    }
  }, [defaultDetention]);

  useEffect(() => {
    if (detentionOptionId === DETENTION_OPTIONS_KEYS.MANDATORY) {
      //console.log("Mandatory DT");
      setDetentions([{ id: detentions.length, ...detentionTypeInfo }]);
    } 
    else if (detentionOptionId === DETENTION_OPTIONS_KEYS.OPTIONAL && issueDetention) {
      setDetentions([
        ...detentions,
        { id: detentions.length, ...detentionTypeInfo },
      ]);
    } else {
      //console.log("NOT Mandatory DT");
      setDetentions([]);
    }
  }, [detentionTypeInfo, issueDetention]);

  useEffect(() => {
    setValidationErrors(null);
  }, [selectedDetention]);

  const isReadyToSubmit = () => {
    if (defaultDetention) {
      if (
        defaultDetention.detentionOptionId ===
          DETENTION_OPTIONS_KEYS.MANDATORY ||
        (defaultDetention.detentionOptionId ===
          DETENTION_OPTIONS_KEYS.OPTIONAL &&
          issueDetention) ||
        defaultDetention.isTriggeredDetention
      ) {
        if (detentions.length > 0) {
          for (let i = 0; i < detentions.length; i++) {
            const detention = detentions[i];
            if (
              detention.detentionTypeId === null ||
              detention.detentionLocationId === null ||
              detention.date === null ||
              detention.time === null ||
              detention.duration < 15
            ) {
              return false;
            }
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const openSaveConfirm = () => {
    let errors: string[] = [];

    if (detentions.length > 0) {
      detentions.forEach(detention => {
        detentionValidation(
          detention,
          incidentDetails,
          detentionSummary,
          schoolInformation,
          true,
          errors
        );
      });
    }

    if (errors.length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors(null);
      setOpenConfirm(true);
    }
  };

  const handleInvestigationNotesChange = (value: string) => {
    if (value.length > 256) {
      setTextLengthError(
        "Investigation Notes cannot be longer than 256 characters."
      );
    } else {
      setTextLengthError(null);
    }
    setInvestigationNotes(value);
  };

  const onClickAddSanctionDay = () => {
    // to avoid duplicate IDs
    const detentionsLength = detentions.length;
    const nextId = detentions[detentionsLength - 1].id + 1;

    const tempDetentionDetails = {
      ...detentions[0],
      id: nextId,
    };

    setOpenSanctionModal(true);
    setSelectedDetention(tempDetentionDetails);
  };

  const onClickSaveSanction = () => {
    let errors: string[] = [];

    detentionValidation(
      selectedDetention,
      incidentDetails,
      detentionSummary,
      schoolInformation,
      true,
      errors
    );

    const foundSameDate = detentions.some(det =>
      moment(det.date).isSame(selectedDetention.date)
    );

    if (foundSameDate) {
      errors.push("A sanction has already been added for this date.");
    }

    if (errors.length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors(null);

      // save if already in array, otherwise add it
      const index = detentions.findIndex(
        det => det.id === selectedDetention.id
      );

      if (index > -1) {
        const tempDetentionDetails = [...detentions];
        tempDetentionDetails.splice(index, 1, selectedDetention);
        setDetentions(tempDetentionDetails);
      } else {
        setDetentions([...detentions, selectedDetention]);
      }

      setSelectedDetention(null);
      setOpenSanctionModal(false);
    }
  };

  const handleCancel = () => {
    setOpenSanctionModal(false);
  }

  const onClickEditSanction = (id: number) => {
    const tempDetentionDetails = [...detentions];

    const index = tempDetentionDetails.findIndex(det => det.id === id);
    if (index !== -1) {
      setSelectedDetention(tempDetentionDetails[index]);
      setOpenSanctionModal(true);
    }
  };

  const onClickRemoveSanction = (detentionId: number) => {
    const tempDetentionDetails = [...detentions];

    const index = tempDetentionDetails.findIndex(
      detention => detention.id === detentionId
    );

    if (index !== -1) {
      tempDetentionDetails.splice(index, 1);
      setDetentions(tempDetentionDetails);
    }
  };

  const handleBehaviourCategoryChange = (category: IBehaviourCategory) => {
    if (behaviourCategory.severityIndex < category.severityIndex) {
      //setDetentions([]);
      setIssueDetention(false)
    }
    setBehaviourCategory?.(category);
  }

  if (loading || loadingRevokeBehaviour) {
    return <Loader size={Size.Medium} fluid />;
  }

  return (
    <>
      <ApiExceptionMessage error={updateBehaviourError} />
      <ApiExceptionMessage error={authoriseError} />

      {validationErrors !== null && (
        <ValidationMessage errors={validationErrors} />
      )}

      <ReadOnlyBehaviourDetails behaviour={behaviour} />
      <ReadOnlyIncidentDetails behaviour={behaviour} />

      <Card title="Manage Behaviour">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Behaviour Category" required>
              <BehaviourCategoryFormWrapper
                schoolId={schoolInformation ? schoolInformation.id : null}
                behaviourCategory={behaviourCategory}
                setBehaviourCategory={handleBehaviourCategoryChange}
              />
            </StructuredList.Item>

            {loadingDefaultDetention ? (
              <LoaderWrapper>
                <Loader size={Size.Medium} fluid />
              </LoaderWrapper>
            ) : (
              detentionOptionId === DETENTION_OPTIONS_KEYS.OPTIONAL &&
              behaviourCategory.severityIndex === CODE_SEVERITY_INDEX.C3 && (
                <StructuredList.Item name="Issue Sanction with Behaviour Point">
                  <Checkbox
                    text="Issue Sanction"
                    checked={issueDetention}
                    onChange={() => setIssueDetention(!issueDetention)}
                  />
                </StructuredList.Item>
              )
            )}
          </StructuredList>
        </Card.Body>
      </Card>

      {defaultDetention &&
        defaultDetention.detentionRule &&
        defaultDetention.detentionRule.ruleName && (
          <TriggeredDetentionMessage
            ruleName={defaultDetention.detentionRule.ruleName}
          />
        )}
        
      {(issueDetention ||
        detentionOptionId === DETENTION_OPTIONS_KEYS.MANDATORY) && (
        <>
          {detentions.length === 1 ? (
            <>
              <DetentionFormWrapper
                studentId={studentId}
                schoolId={schoolInformation.id}
                detentionDetails={detentions[0]}
                setDetentionDetails={detention => setDetentions([detention])}
                allowDetentionTypeChange
                allowDetentionDurationChange
              />

              {detentions[0].detentionPeriodId ===
                DETENTION_CATEGORIES.INTERNAL_EXCLUSION && (
                <AddSantionWrapper>
                  <AddSanctionDayButton onClick={onClickAddSanctionDay} />
                </AddSantionWrapper>
              )}
            </>
          ) : detentions.length > 1 && (
            <>
            <MultipleSanctionsTable
              sanctions={detentions}
              onClickAddAnotherDay={onClickAddSanctionDay}
              onClickEditSanction={onClickEditSanction}
              onClickRemoveSanction={onClickRemoveSanction}
              editable
            />
            </>
          )}
        </>
      )}

      <Card>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Investigation Notes">
              <TextInput
                type={TextInputType.Textarea}
                fluid
                placeholder="Notes"
                value={investigationNotes}
                onChange={value => handleInvestigationNotesChange(value)}
                maxLength={256}
              />
              {textLengthError ? (
                <Message text={textLengthError} color={Swatches.Danger} />
              ) : null}
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      <ActionBar low>
        <Right>
          <Button
            color={Swatches.Blue}
            size={Size.Small}
            disabled={isReadyToSubmit() === false}
            onClick={openSaveConfirm}
            text="Authorise &amp; Issue"
          />
          <Button
            color={Swatches.Danger}
            size={Size.Small}
            text="Revoke"
            onClick={handleRevokeBehaviour}
          />
          <Button size={Size.Small} onClick={closeModal} text="Cancel" />
        </Right>
      </ActionBar>

      <br />

      <ConfirmModal
        openModal={openConfirm}
        confirmMsg={"Are you sure you want to authorise this behaviour?"}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          onClickAuthoriseBehaviour(detentions, investigationNotes);
          setOpenConfirm(false);
        }}
      />

      <Modal
        open={openSanctionModal}
        onClose={handleCancel}
        title="Sanction"
        height="65%"
        width="60%"
      >
        <Modal.Body>
          {validationErrors !== null && (
            <ValidationMessage errors={validationErrors} />
          )}
          <DetentionFormWrapper
            studentId={studentId}
            schoolId={schoolInformation.id}
            detentionDetails={selectedDetention}
            setDetentionDetails={detention => setSelectedDetention(detention)}
            allowDetentionDurationChange
          />
        </Modal.Body>
        <Modal.Footer>
          <ActionBar low>
            <Right>
              <Button
                text="Save"
                size={Size.Small}
                onClick={onClickSaveSanction}
                color={Swatches.Success}
              />
              <Button
                text="Cancel"
                size={Size.Small}
                onClick={handleCancel}
              />
            </Right>
          </ActionBar>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManagePendingAttentionForm;
