import React from "react";
import styled from "styled-components";
import { fontStyle, neutral, Spacing, textFont, typescale } from "ui-kit";


const Wrapper = styled.div`
  padding-bottom: ${Spacing.Medium}px;
  margin-bottom: ${Spacing.Medium}px;
  border-bottom: 1px solid ${neutral[300]};

  .description {
    ${fontStyle(textFont.roman, typescale.paragraph, neutral[500])}
  }

  .required-fields-wrapper {
    margin: ${Spacing.Medium}px 0;

    .fields-header {
      ${fontStyle(textFont.medium, typescale.paragraph, neutral[600])};
      margin-bottom: ${Spacing.Small}px;
    }

    .required-field-list {
      ${fontStyle(textFont.roman, typescale.paragraph, neutral[500])};
      margin-left: ${Spacing.Large}px;
      list-style: unset;
      line-height: 1rem !important;
    }
  }
`;


interface IReportDescriptionProps {
  text: string,
  requiredFields?: string[]
}


const ReportDescription: React.FC<IReportDescriptionProps> = ({ text, requiredFields }) => {
  
  return (
    <Wrapper>
      <div className="description">{text}</div>
      {requiredFields && (
        <div className="required-fields-wrapper">
          <div className="fields-header">Required Fields</div>
          <ul className="required-field-list">
            {requiredFields.map((fieldName, index) => (
              <li key={index}>{fieldName}</li>
            ))}
          </ul>
        </div>
      )}
    </Wrapper>
  );
};

export default ReportDescription;
