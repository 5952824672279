import { useEffect, useState } from "react";
import { Dropdown } from "ui-kit"


export enum CustomGroupCategory {
  Club = 0,
  Workshop = 1,
  Programme = 2,
  Intervention = 3,
  Standard = 4
}

interface CustomGroupCategoryOption {
  key: number | string;
  label: string;
  value: number;
}

interface ICustomGroupCategoryDropdownProps {
  initialValue?: CustomGroupCategory;
  onChange?: (value: number) => void;
}


const CustomGroupCategoryDropdown: React.FC<ICustomGroupCategoryDropdownProps> = ({ initialValue, onChange }) => {

  const [selectedValue, setSelectedValue] = useState<number>(-1);
  const [options, setOptions] = useState<CustomGroupCategoryOption[]>(() => {
    var opts: CustomGroupCategoryOption[] = [];

    for (var cgCat in CustomGroupCategory) {
      if (!isNaN(Number(cgCat))) {
        opts.push({
          key: Number(cgCat),
          label: CustomGroupCategory[cgCat],
          value: Number(cgCat),
        });
      }
    }

    return opts;
  });

  useEffect(() => {
    const option = options.find(x => x.value === initialValue);
    setSelectedValue(option ? option.value : -1);
  }, [initialValue]);

  const handleChange = (value: number) => {
    setSelectedValue(value);
    onChange?.(value);
  };
  
  return (
    <Dropdown
      placeholder={"Please choose a category"}
      value={selectedValue}
      onChange={handleChange}
      items={options}
      fluid
    />
  )
}

export default CustomGroupCategoryDropdown;