import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import { BehaviourCategoryGroupByListView } from "areas/behaviour/types/behaviourResponses.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";

// Includes legacy categories for reporting - seperate state as we dont want to display legacy categories when giving new behaviours.
interface IAllBehaviourCategoriesState {
  allBehaviourCategoryGroups: BehaviourCategoryGroupByListView[];
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IAllBehaviourCategoriesState = {
  allBehaviourCategoryGroups: [],
  loading: false,
  error: null,
};

const { GETALLBEHAVIOURCATEGORIES } = behaviourActions.types;

const allBehaviourCategoriesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAllBehaviourCategoriesState => {

  switch (action.type) {
    case GETALLBEHAVIOURCATEGORIES.START:
      return { ...state, loading: true, error: null };

    case GETALLBEHAVIOURCATEGORIES.SUCCESS:
      return {
        ...state,
        loading: false,
        allBehaviourCategoryGroups: action.payload,
      };

    case GETALLBEHAVIOURCATEGORIES.FAILED:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default allBehaviourCategoriesReducer;
