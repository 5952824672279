import React, { useEffect } from "react";
import {
  Title,
  TitleSize,
  EmptyMessage,
  Loader,
  Swatches,
  Text,
  Chip,
  Button,
  Size,
  StructuredList,
  ChipList,
  Card,
} from "ui-kit";
import Subheader from "sharedComponents/layout/header/subheader";
import { Constants } from "configuration";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ProvisionFlyout from "./provisionFlyout";
import { SchoolAvatar } from "sharedComponents/common";
import { arrays } from "utils";
import { Link } from "react-router-dom";
import ProvisionGroupsTable from "./provisionGroups/provisionGroupsTable";
import provisionsActions from "areas/send/actions/provisions/provisionsActions";
import flyoutActions from "actions/ui/flyouts";
import { RootState } from "reducers/store";


const Provision = () => {

  const { loading, error, provision } = useSelector(
    (state: RootState) => state.standardProvision
  );

  const { providerId, provisionId } = useParams();

  useEffect(() => {
    provisionsActions.getStandardProvision(parseInt(providerId), parseInt(provisionId));
  }, []);

  const editProvider = () => {
    flyoutActions.openFlyout(Constants.FLYOUTS.SEND_PROVISION);
  };

  if (loading) {
    return <Loader cover size={Size.Medium} />;
  }

  if (error) {
    return <EmptyMessage icon="times-circle" title="An error occurred" cover />;
  }

  if (!loading && !error && !provision) {
    <EmptyMessage
      icon="hand-holding-seedling"
      title="Provision Not Found"
      cover
    />;
  }

  return (
    <>
      <Subheader>
        <Title
          size={TitleSize.H2}
          text="SEND"
          sub={<>{provision?.name}</>}
          right={
            <Button
              size={Size.Small}
              onClick={editProvider}
              text="Edit Provision"
              color={Swatches.Primary}
            />
          }
        />
        <Chip text={provision?.areaOfNeedName} colorSwatch={Swatches.Blue} />
      </Subheader>

      <Card title="Details">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Provider">
              <Link to={`/main/send/providers/${provision?.provider?.id}`}>
                {provision?.provider?.name}
              </Link>
            </StructuredList.Item>
            <StructuredList.Item name="School">
              <SchoolAvatar school={provision?.school} />
            </StructuredList.Item>

            <StructuredList.Item name="Description">
              <Text>{provision?.description}</Text>
            </StructuredList.Item>
            {!arrays.isEmpty(provision?.tags) && (
              <StructuredList.Item name="Tags">
                <ChipList>
                  {provision?.tags?.map((tag, index) => (
                    <Chip key={index} text={tag.name} />
                  ))}
                </ChipList>
              </StructuredList.Item>
            )}
          </StructuredList>
        </Card.Body>
      </Card>

      <Card title="Provision Groups">
        <Card.Body noPad>
          <ProvisionGroupsTable provision={provision} loading={loading} error={error} />
        </Card.Body>
      </Card>
      <ProvisionFlyout provision={provision} />
    </>
  );
};

export default Provision;
