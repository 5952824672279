import { BeehiveReport, IBeehiveAction } from "types/common/action.types";
import reviewActions from "../../actions/reviewActions";

interface IReviewReportState {
  report: BeehiveReport;
  loading: boolean;
  error: string | null;
}

const INITIAL_STATE: IReviewReportState = {
  report: "",
  loading: false,
  error: null,
};

const reviewReportReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IReviewReportState => {
  const { GETREVIEWREPORT } = reviewActions.types;

  switch (action.type) {
    case GETREVIEWREPORT.START:
      return { ...INITIAL_STATE, loading: true };
    case GETREVIEWREPORT.SUCCESS:
      return { ...INITIAL_STATE, report: action.payload };
    case GETREVIEWREPORT.FAILED:
      return {
        ...INITIAL_STATE,
        error: "There was a problem running the report.",
      };

    default:
      return state;
  }
};

export default reviewReportReducer;
