import action from "actions/action";
import { ToastService } from "ui-kit";
import actionTypeBuilder from "actions/actionTypeBuilder";
import store from "reducers/store";
import client from "services/client";
import { callbackType } from "types/common/action.types";
import { OnCallRequestCategoryView, OnCallRequestDetailView } from "areas/analytics/reducers/onCallAnalyticsResponse.types";
import { PagedQueryView } from "types/common/views.types";
import { OnCallRequestsFilter } from "areas/analytics/reducers/onCallAnalyticsRequest.types";
import { CancelOnCallRequestCommand, ClaimOnCallRequestCommand, ResolveOnCallRequestCommand, SaveOnCallRequestCommand } from "areas/behaviour/types/onCall/onCallRequest.types";


const builder = new actionTypeBuilder("oncall");

export const types = {
  GETONCALLCATEGORIES: builder.build("getoncallcategories"),
  GETONCALLREQUEST: builder.build("getoncallrequest"),
  GETONCALLREQUESTS: builder.build("getoncallrequests"),
  CREATEONCALLREQUEST: builder.build("createoncallrequest"),
  CLAIMONCALLREQUEST: builder.build("claimoncallrequest"),
  RESOLVEONCALLREQUEST: builder.build("resolveoncallrequest"),
  CANCELONCALLREQUEST: builder.build("canceloncallrequest"),
  UPDATEONCALLREQUEST: builder.build("updateoncallrequest"),
  NEWONCALLNOTIFICATION: "newoncallnotification",
  CLEARNEWONCALLNOTIFICATION: "clearnewoncallnotification",
};



const getOnCallCategories = (onSuccess?: callbackType<OnCallRequestCategoryView[]>) => {
  return action<OnCallRequestCategoryView[]>(
    () => client.get(`planner/onCallRequestCategories`),
    types.GETONCALLCATEGORIES,
    onSuccess
  );
};


const getOnCallRequest = (schoolId: number, onCallRequestId: number, onSuccess?: callbackType<OnCallRequestDetailView>) => {
  return action<OnCallRequestDetailView>(
    () => client.get(`planner/schools/${schoolId}/oncalls/${onCallRequestId}`),
    types.GETONCALLREQUEST,
    onSuccess
  );
};


const getOnCallRequests = (
  schoolId: number,
  {
    academicYearId,
    statusId
  }: OnCallRequestsFilter,
  pageIndex: number,
  onSuccess?: callbackType<PagedQueryView<OnCallRequestDetailView>>
) => {
  return action<PagedQueryView<OnCallRequestDetailView>>(
    () =>
      client.get(
        `planner/schools/${schoolId}/oncallRequests?academicYearId=${academicYearId}&StatusId=${statusId}&pageIndex=${pageIndex}&pageSize=10`
      ),
    types.GETONCALLREQUESTS,
    onSuccess
  );
};


const createOnCallRequest = (schoolId: number, onCallPayload: SaveOnCallRequestCommand, onSuccess?: callbackType<number>) => {
  return action<number>(
    () => client.post(`planner/schools/${schoolId}/oncall`, onCallPayload),
    types.CREATEONCALLREQUEST,
    response => {
      ToastService.pop(
        "On call request successfully created",
        null,
        "check-circle"
      );
      onSuccess?.(response);
    }
  );
};


const claimOnCallRequest = (
  schoolId: number, 
  onCallId: number, 
  onCallPayload: ClaimOnCallRequestCommand, 
  onSuccess?: callbackType<null>
) => {
  return action<null>(
    () =>
      client.post(
        `planner/schools/${schoolId}/oncalls/${onCallId}/claim`,
        onCallPayload
      ),
    types.CLAIMONCALLREQUEST,
    response => {
      ToastService.pop(
        "On call request successfully claimed",
        null,
        "check-circle"
      );
      onSuccess?.(response);
    }
  );
};


const resolveOnCallRequest = (
  schoolId: number, 
  onCallId: number, 
  onCallPayload: ResolveOnCallRequestCommand, 
  onSuccess?: callbackType<null>
) => {
  return action<null>(
    () =>
      client.post(
        `planner/schools/${schoolId}/oncalls/${onCallId}/resolve`,
        onCallPayload
      ),
    types.RESOLVEONCALLREQUEST,
    response => {
      ToastService.pop(
        "On call request successfully resolved",
        null,
        "check-circle"
      );
      onSuccess?.(null);
    }
  );
};


const cancelOnCallRequest = (
  schoolId: number, 
  onCallId: number, 
  onCallPayload: CancelOnCallRequestCommand, 
  onSuccess?: callbackType<null>
) => {
  return action<null>(
    () =>
      client.post(
        `planner/schools/${schoolId}/oncalls/${onCallId}/cancel`,
        onCallPayload
      ),
    types.CANCELONCALLREQUEST,
    response => {
      ToastService.pop(
        "On call request successfully cancelled",
        null,
        "check-circle"
      );
      onSuccess?.(response);
    }
  );
};


const updateOnCallRequest = (
  schoolId: number, 
  onCallId: number, 
  onCallPayload: SaveOnCallRequestCommand, 
  onSuccess?: callbackType<null>
) => {
  return action<null>(
    () =>
      client.post(
        `planner/schools/${schoolId}/oncalls/${onCallId}/update`,
        onCallPayload
      ),
    types.UPDATEONCALLREQUEST,
    response => {
      ToastService.pop(
        "On call request successfully updated",
        null,
        "check-circle"
      );
      onSuccess?.(response);
    }
  );
};


const clearNewOnCallNotification = () =>
  store.dispatch({
    type: types.CLEARNEWONCALLNOTIFICATION,
  });


const onCallActions = {
  types,
  getOnCallCategories,
  getOnCallRequest,
  getOnCallRequests,
  createOnCallRequest,
  claimOnCallRequest,
  resolveOnCallRequest,
  cancelOnCallRequest,
  updateOnCallRequest,
  clearNewOnCallNotification,
};

export default onCallActions;
