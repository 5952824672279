import { SchoolDropdown, YearGroupDropdown } from "sharedComponents/common";
import AcademicYearFilter from "sharedComponents/common/academicYearFilter";
import { Gender } from "types/users/userDetail.types";
import { StructuredList, TextInput, TextInputType, RadioList, DatePicker } from "ui-kit";
import EventsCategoryDropdown from "../eventsCategoryDropdown";
import { ICalendarReportProps } from ".";


const TotalSignupsReport: React.FC<ICalendarReportProps> = ({ filter, setFilter }) => {
  return (
    <>
      <StructuredList.Item name="School">
        <SchoolDropdown
          initialValue={filter.schoolId}
          onChange={value =>
            setFilter({ 
              ...filter, 
              schoolId: value, 
              yearGroupId: value === -1
                ? null
                : filter.yearGroupId
            })
          }
          includeAllOption
          fluid
        />
      </StructuredList.Item>
      <StructuredList.Item name="Event Category" required>
        <EventsCategoryDropdown
          onChange={cat => setFilter({ ...filter, eventCategoryId: cat.id })}
          initialValue={filter.eventCategoryId}
        />
      </StructuredList.Item>
      <StructuredList.Item name="Top Students Count">
        <TextInput
          type={TextInputType.Number}
          value={filter.topStudentsCount}
          onChange={val => setFilter({ ...filter, topStudentsCount: val })}
          fluid
        />
      </StructuredList.Item>
      <StructuredList.Item name="Academic Year">
        <AcademicYearFilter
          value={filter?.academicYearId}
          onChange={value => setFilter({...filter, academicYearId: value})}
        />
      </StructuredList.Item>
      <StructuredList.Item name="Pupil Premium">
        <RadioList
          value={filter?.isPp}
          onChange={value => setFilter({ ...filter, isPp: value })}
        >
          <RadioList.Item label="Both" value={null} />
          <RadioList.Item label="Is PP" value={true} />
          <RadioList.Item label="Not PP" value={false} />
        </RadioList>
      </StructuredList.Item>
      <StructuredList.Item name="SEN">
        <RadioList
          value={filter?.isSen}
          onChange={value => setFilter({ ...filter, isSen: value })}
        >
          <RadioList.Item label="Both" value={null} />
          <RadioList.Item label="Is SEN" value={true} />
          <RadioList.Item label="Not SEN" value={false} />
        </RadioList>
      </StructuredList.Item>
      <StructuredList.Item name="EAL">
        <RadioList
          value={filter?.isEal}
          onChange={value => setFilter({ ...filter, isEal: value })}
        >
          <RadioList.Item label="Both" value={null} />
          <RadioList.Item label="Is EAL" value={true} />
          <RadioList.Item label="Not EAL" value={false} />
        </RadioList>
      </StructuredList.Item>
      <StructuredList.Item name="Gender">
        <RadioList
          value={filter?.gender}
          onChange={value => setFilter({ ...filter, gender: value })}
        >
          <RadioList.Item label="Both" value={null} />
          <RadioList.Item label="Male" value={Gender.Male} />
          <RadioList.Item label="Female" value={Gender.Female} />
        </RadioList>
      </StructuredList.Item>
      <StructuredList.Item name="Start Date">
        <DatePicker
          dateFormat="DD/MM/YYYY"
          placeholder="Pick a start date..."
          closeOnSelect
          selectedDate={filter?.starts}
          onChange={value => setFilter({ ...filter, starts: value })}
          clearable
        />
      </StructuredList.Item>
      <StructuredList.Item name="End Date">
        <DatePicker
          dateFormat="DD/MM/YYYY"
          placeholder="Pick an end date..."
          closeOnSelect
          selectedDate={filter?.ends}
          onChange={value => setFilter({ ...filter, ends: value })}
          clearable
        />
      </StructuredList.Item>
      <StructuredList.Item name="Year Group" hide={filter.schoolId === -1}>
        <YearGroupDropdown
          school={filter?.schoolId}
          value={filter?.yearGroupId}
          onChange={value => setFilter({ ...filter, yearGroupId: value })}
          clearable
        />
      </StructuredList.Item>
    </>
  )
}


export default TotalSignupsReport;
