import defaults from "configuration/defaults";
import { arrays } from "utils";
import adminSubmissionActions from "../actions/adminSubmissionActions";
import submissionActions from "../actions/submissionActions";
import { PageInfo } from "types/common/paging.types";
import { SubmissionDetailView, SubmissionListView } from "../types/formResponse.types";
import { IBeehiveAction } from "types/common/action.types";


interface IAdminSubmissionsState {
  submissions: SubmissionListView[];
  selected: SubmissionDetailView | null;
  loading: boolean;
  error: string | null;
  saving: boolean;
  assigning: boolean;
  paging: PageInfo;
  masterWidth: number;
}

const INITIAL_STATE: IAdminSubmissionsState = {
  submissions: [],
  selected: null,
  loading: false,
  error: null,
  saving: false,
  assigning: false,
  paging: defaults.DEFAULT_PAGING,
  masterWidth: 400,
};


const adminSubmissionsReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAdminSubmissionsState => {

  const { GETFORADMIN, SELECTSUBMISSION, ASSIGN, SETWIDTH } = adminSubmissionActions.types;
  const { AUTHORISE, REJECT, CANCEL } = submissionActions.types;

  switch (action.type) {
    case SETWIDTH:
      return { ...state, masterWidth: action.payload };

    case GETFORADMIN.START:
      return { ...state, loading: arrays.isEmpty(state.submissions) };

    case GETFORADMIN.SUCCESS:
      return {
        ...state,
        submissions:
          action.payload.paging.pageIndex === 0
            ? action.payload.items
            : [...state.submissions, ...action.payload.items],
        paging: action.payload.paging,
        loading: false,
      };

    case GETFORADMIN.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SELECTSUBMISSION:
      return {
        ...state,
        selected: action.payload,
      };

    case AUTHORISE.START:
    case REJECT.START:
    case CANCEL.START:
      return { ...state, saving: true, error: null };

    case AUTHORISE.SUCCESS:
    case REJECT.SUCCESS:
    case CANCEL.SUCCESS:
      return {
        ...state,
        submissions: state.submissions.map(submission =>
          submission.id === action.payload.submissionId
            ? { ...submission, latestHistory: action.payload }
            : submission
        ),
      };

    case AUTHORISE.FAILED:
    case REJECT.FAILED:
    case CANCEL.FAILED:
      return {
        ...state,
        saving: false,
        error: action.payload,
      };

    case ASSIGN.START:
      return { ...state, assigning: true, error: null };

    case ASSIGN.SUCCESS:
      return {
        ...state,
        submissions: state.submissions.map(submission =>
          submission.id === action.payload.submission.id
            ? {
                ...submission,
                assignedTo: action.payload.user,
              }
            : submission
        ),
      };

    case ASSIGN.FAILED:
      return {
        ...state,
        assigning: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default adminSubmissionsReducer;
