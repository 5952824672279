import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";
import syncActions from "areas/administration/actions/syncAction";
import { SyncLogReportResponse } from "areas/administration/types/syncResponses.types";

interface ISyncLogState {
  logData: SyncLogReportResponse | null;
  loading: boolean;
  error: IBeehiveError | null;
}

const INITIAL_STATE: ISyncLogState = {
  logData: null,
  loading: false,
  error: null,
};

const syncLogReducer = (
  state = INITIAL_STATE,
  action: IBeehiveAction
): ISyncLogState => {
  const { GETSYNCLOG } = syncActions.types;

  switch (action.type) {
    case GETSYNCLOG.START:
      return { ...state, loading: true, error: null };

    case GETSYNCLOG.SUCCESS:
      return { ...state, logData: action.payload, loading: false };

    case GETSYNCLOG.FAILED:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default syncLogReducer;
