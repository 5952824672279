import paymentActions from "areas/payments/actions/paymentsActions";
import { CashlessOrderDetailView } from "areas/payments/types/shopkeeper/shopkeeperResponses.types";
import { IBeehiveAction } from "types/common/action.types";


export interface IUserCashlessSaleState {
  order: CashlessOrderDetailView | null;
  error: string | null;
  loading: boolean;
}

const INITIAL_STATE: IUserCashlessSaleState = {
  order: null,
  error: null,
  loading: false,
};

const userCashlessSaleReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IUserCashlessSaleState => {

  const { PAYMENTS_GETCASHLESSORDER } = paymentActions.paymentsTypes;

  switch (action.type) {

    case PAYMENTS_GETCASHLESSORDER.START:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case PAYMENTS_GETCASHLESSORDER.SUCCESS:
      return { 
        ...state, 
        order: action.payload, 
        loading: false 
      };

    case PAYMENTS_GETCASHLESSORDER.FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default userCashlessSaleReducer;
