import React, { useEffect, useState } from "react";
import { Dropdown } from "ui-kit";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import behaviourActions from "areas/behaviour/actions/behaviour/behaviourActions";
import { useAppSelector } from "reducers/hooks";
import { IDropdownDataItemProps } from "ui-kit/forms/dropdown";


interface IBehaviourCategoriesDropdownProps {
  schoolId: number;
  behaviourCodeFilter?: number;
  selected?: number;
  onChange: (value: number) => void;
}


const BehaviourCategoriesDropdown: React.FC<IBehaviourCategoriesDropdownProps> = ({
  schoolId,
  behaviourCodeFilter,
  selected,
  onChange,
}) => {

  const { behaviourCategoryGroups, loading } = useAppSelector(
    state => state.behaviourCategories
  );

  const [behaviourCategories, setBehaviourCategories] = useState<IDropdownDataItemProps<string, number, null>[]>([]);

  useEffect(() => {
    if (schoolId) {
      behaviourActions.getBehaviourCategories(
        schoolId,
        {
          isLegacy: false,
          canBeAwarded: null,
          behaviourCodeId: behaviourCodeFilter
        }
      );
    }
  }, [schoolId, behaviourCodeFilter]);

  useEffect(() => {
    if (!arrays.isEmpty(behaviourCategoryGroups)) {
      const tempBehaviourCategories: IDropdownDataItemProps<string, number, null>[] = [];
      behaviourCategoryGroups.map(categoryGroup =>
        categoryGroup.behaviourCategories.map(behaviourCategory =>
          tempBehaviourCategories.push({
            label: `${behaviourCategory.codeDisplayName}: ${behaviourCategory.behaviourCategoryName}`,
            value: behaviourCategory.id,
          })
        )
      );
      setBehaviourCategories(tempBehaviourCategories);
    }
  }, [behaviourCategoryGroups]);

  return (
    <Dropdown
      items={behaviourCategories}
      placeholder="Behaviour Category"
      value={selected}
      onChange={onChange}
      clearable
      fluid
      loading={loading}
    />
  );
};

export default BehaviourCategoriesDropdown;
