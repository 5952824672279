import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { StudentStartupView } from "types/users/userListViews.types";
import { SenStatus } from "types/users/userSen.types";
import { Tabs, Loader, Size } from "ui-kit";
import PupilPassport from "../passports/pupilPassport";
import React, { useEffect, useState } from "react";
import { PlannerStudentDetailView } from "areas/planner/types/plannerStudentResponse.types";
import studentActions from "areas/planner/actions/student/studentActions";
import ProvisionMaps from "../provisionMaps/provisionMaps";
import sendParentsActions from "areas/send/actions/parents/senParentsActions";
import ParentPassportView from "./parentPassportView";
import ParentProvisionMapView from "./parentProvisionMapView";


const SenParentView = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);

  const handleChildSelect = (name: string, index: number, childId: string) => {
    sendParentsActions.setActiveChild(childId);
  }
  
  return user ? (
    <>
      <Tabs onTabSelected={handleChildSelect}>
        { user.children
          .filter(c => c.senStatus != SenStatus.N)
          .map((child: StudentStartupView, index: number) => (
            <Tabs.Pane 
              key={index}
              label={`${child.details.firstName} ${child.details.lastName}`}
              name={child.id}
              data={child.id}
            >
              <ParentViewTabs child={child}/>
            </Tabs.Pane>
          ))
        }   
      </Tabs>
    </>
  ) : null;
}


interface IParentViewTabsProps {
  child: StudentStartupView
}
const ParentViewTabs: React.FC<IParentViewTabsProps> = ({ child }) => {

  const [student, setStudent] = useState<PlannerStudentDetailView | null>(null);

  useEffect(() => {
    child && studentActions.getStudent(
      child.id, 
      (plannerStudent: PlannerStudentDetailView) => setStudent(plannerStudent)
    )
  }, [child])

  return student ? (
    <>
    <Tabs>
      <Tabs.Pane label={`${student.firstName} Pupil Passport`} name={`${student.id}_Passport`}>
        <ParentPassportView student={student} />
      </Tabs.Pane>
      <Tabs.Pane label={`${student.firstName} Pupil Provisions`} name={`${student.id}_Provisions`}>
        <ParentProvisionMapView student={student} />
      </Tabs.Pane>
    </Tabs>
    </>
  ) : (
    <Loader cover size={Size.Medium} loadingMessage={"Loading Student"} />
  )

}


export default SenParentView;