import { IBeehiveAction } from "types/common/action.types";
import newsActions from "../../actions/newsActions";

interface NewsUiState {
  tabIndex: number;
}

const INITIAL_STATE: NewsUiState = {
  tabIndex: 0,
};

const newsUiReducer = (state = INITIAL_STATE, action:IBeehiveAction) : NewsUiState => {

  switch (action.type) {
    case newsActions.types.SET_TAB:
      return { ...state, tabIndex: action.payload };
    default:
      return state;
  }
};

export default newsUiReducer;
