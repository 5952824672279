import React, { useEffect, useState } from "react";
import GroupList from "../groupList";
import config from "configuration";
import {
  ActionBar,
  Button,
  Card,
  fontStyle,
  formatScrollbars,
  getFontSize,
  List,
  Loader,
  Message,
  Modal,
  neutral,
  Right,
  Size,
  Spacing,
  Subtitle,
  Swatches,
  textFont,
  ToastService,
  typescale,
} from "ui-kit";
import styled from "styled-components";
import { danger } from "ui-kit/common/colors";
import CustomGroupForm from "./customGroupForm";
import { arrays, objects } from "utils";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import CustomGroupStudentList from "./customGroupStudentList";
import MultiSchoolUserView from "sharedComponents/common/users/multiSchoolUserView";
import customGroupActions from "areas/planner/actions/group/customGroupActions";
import { CustomGroupCategory } from "./customGroupCategoryDropdown";
import { ImportStudentsModal } from "sharedComponents/common";
import UserAndGroupsSearch from "sharedComponents/common/userAndGroupsSearch";
import { UserType } from "configuration/constants.enums";
import { ClassGroupStaffView, CustomGroupDetailView, CustomGroupStudentView, GroupListView } from "types/users/userGroups.types";
import { SearchCategory, SearchResultView, SearchResultViewBase } from "types/common/views.types";
import groupActions from "areas/planner/actions/group/groupActions";
import { useAppSelector } from "reducers/hooks";
import { StudentListView, UserListView, UserTinyView } from "types/users/userListViews.types";
import { GroupType } from "types/planner/groups.types";
import { IBeehiveError } from "types/common/errors.types";
import { SaveCustomGroupCommand } from "areas/planner/types/groups/customGroupRequest.types";


const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Wrapper = styled.div<{multiSchool: boolean, hideGroups: boolean}>`
  display: flex;
  height: 100%;

  ${({ multiSchool }) =>
    !multiSchool &&
    `
  // height: 100%;
  `}

  .group-list-wrapper {
    margin-bottom: ${Spacing.Medium}px;
    overflow-y: auto;
    ${formatScrollbars()}
    position: absolute;
    min-height: 100%;
    max-height: 100%;
    width: 260px;
    min-width: 260px;

    .list .new-custom-group-button {
      margin-right: ${Spacing.Default}px;
      .left .icon {
        margin-top: 0;
      }
      .body .detail-label .label {
        margin-bottom: 0;
      }
    }

    .group-list {
      padding: 0 ${Spacing.Default}px ${Spacing.Medium}px 0;
      width: unset;
      margin-top: ${Spacing.Default}px;
    }
  }

  .custom-group-editor {
    display: flex;
    flex-grow: 1;
    height: 100%;
    margin-left: 260px;
    justify-content: center;

    // .loader {
    //   align-self: center;
    // }

    .student-list-wrapper {
      width: 40%;
      margin: 0 ${Spacing.Default}px;
      flex-grow: 1;
      min-width: 315px;
      min-height: 100%;
      max-height: 100%;
      overflow-y: auto;
      ${formatScrollbars()}
      transition: all ease 200ms;
      padding-right: ${Spacing.Default}px;

      .student-picker-wrapper {
        display: flex;
        align-items: center;
        ${fontStyle(textFont.medium, typescale.paragraph, neutral[700])}

        .student-picker {
          flex-grow: 1;
          margin-right: ${Spacing.Default}px;
        }

        .button {
          margin-left: ${Spacing.Default}px;
        }
      }

      .card,
      .list {
        transition: all ease 200ms;
      }

      .card {
        margin-bottom: ${Spacing.Default}px;
      }

      .existing-students-list {
        margin-bottom: ${Spacing.Default}px;
      }

      .remove-student-button {
        font-size: ${getFontSize(Size.ExtraLarge)}rem;
        background-color: unset;
        border: none;
        margin: 0;
        padding: 0;
        color: ${neutral[500]};
        cursor: pointer;
        line-height: 1rem;

        &:hover {
          color: ${danger};
        }
      }
    }
  }

  ${({ hideGroups }) =>
    hideGroups &&
    `
    .custom-group-editor {
      margin-left: 0px;
    }
  `}
`;

const CustomGroupFormWrapper = styled.div<{hide: boolean}>`
  width: 60%;
  min-height: 100%;
  max-height: 100%;
  overflow-y: auto;
  ${formatScrollbars()}
  padding: 0 ${Spacing.Default}px;

  ${({ hide }) =>
    hide &&
    `
    display: none;
  `}
`;

const HelpText = styled.div`
  ${fontStyle(textFont.roman, typescale.paragraph, neutral[700])};
  margin-bottom: ${Spacing.Default}px;
`;


export interface ICustomGroupEditData extends CustomGroupDetailView {
  yearGroup: GroupListView | null;
  subject: GroupListView | null;
}

interface ICustomGroupEditorModalProps {
  open: boolean;
  onClose: () => void;
  students?: StudentListView[];
  handleUnselectAllStudents?: () => void;
  schoolId: number;
  groupId?: number;
  newGroup?: boolean;
}


const CustomGroupEditorModal: React.FC<ICustomGroupEditorModalProps> = ({
  open,
  onClose,
  students,
  handleUnselectAllStudents,
  schoolId,
  groupId,
  newGroup,
}) => {

  const { groups, loading: loadingGroups } = useAppSelector(
    state => state.customGroups
  );
  const {
    loading: loadingGroup,
    error,
    loadingUpdateStudents,
    errorUpdatingStudents,
  } = useAppSelector(state => state.customGroup);
  const { user } = useAppSelector(state => state.currentUser);

  const [_schoolId, _setSchoolId] = useState<number | null>(null);
  const [_students, _setStudents] = useState<CustomGroupStudentView[]>([]);
  const [_group, _setGroup] = useState<ICustomGroupEditData | null>(null);
  const [_importOpen, _setImportOpen] = useState<boolean>(false);
  const [existingStudents, setExistingStudents] = useState<CustomGroupStudentView[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const [showCustomGroupForm, setShowCustomGroupForm] = useState<boolean>(false);

  const [loadingSaveGroup, setLoadingSaveGroup] = useState<boolean>(false);
  const [saveGroupError, setSaveGroupError] = useState<IBeehiveError | null>(null);

  useEffect(() => {
    if (students && !groupId) {
      const studentsCopy = students.map(student => ({ ...student, canBeDeleted: true } as CustomGroupStudentView));
      _setStudents(studentsCopy);
    }
  }, []);

  useEffect(() => {
    if (newGroup) {
      handleCreateNewGroupClick();
    }
  }, [newGroup]);

  useEffect(() => {
    if (groupId) {
      setSelectedGroupId(groupId);
      setShowCustomGroupForm(true);
      getGroup(groupId);
    }
  }, [groupId]);

  useEffect(() => {
    if (schoolId && schoolId !== _schoolId) {
      _setSchoolId(schoolId);
    }
  }, [schoolId]);

  useEffect(() => {
    if (_schoolId) {
      resetGroup();
      // if (!newGroup && !groupId) {
      customGroupActions.getCustomGroupsForUser(
        user.id,
        config.academicYear.current,
        _schoolId
      );
      // }
    }
  }, [_schoolId]);

  useEffect(() => {
    // console.log(_group);
    if (_group?.id) {
      setExistingStudents(_group.students);
      if (groupId) {
        setShowCustomGroupForm(true);
      } else {
        setShowCustomGroupForm(false);
      }
    } else {
      setExistingStudents([]);
    }
  }, [_group]);

  const resetGroup = () => {
    _setGroup({
      id: null,
      academicYearId: null, 
      isActive: false,
      students: [],
      schoolId: _schoolId,
      name: "",
      isPrivate: false,
      allowSignup: false,
      createdDate: (new Date).toString(), 
      createdBy: null, 
      linkedEvents: [], 
      description: "",
      responsibleStaff: [
        {
          id: user.id,
          title: null,
          firstName: user.details.firstName,
          initials: null,
          lastName: user.details.lastName,
          admissionStatus: null
        },
      ],
      yearGroup: null,
      subject: null,
    });
  };

  const getGroup = (groupId: number) => {
    setSelectedGroupId(groupId);

    const onSuccess = (data: CustomGroupDetailView) => {
      const yearGroup = data.yearGroup
        ? { ...data.yearGroup, title: data.yearGroup.name }
        : null;
      const subject = data.subject
        ? { ...data.subject, title: data.subject.name }
        : null;

      const group = {
        ...data,
        yearGroup: yearGroup,
        subject: subject,
      };
      _setGroup(group);
    };

    customGroupActions.getCustomGroup(groupId, onSuccess);
  };

  const handleCreateNewGroupClick = () => {
    setSelectedGroupId(null);
    resetGroup();
    setShowCustomGroupForm(true);
  };

  const handleSave = () => {
    const onSuccess = () => {
      handleUnselectAllStudents?.();
      ToastService.pop("Group Saved", null, "users");
      setLoadingSaveGroup(false);
      onClose?.();
    };

    const allStudents = _students.concat(existingStudents);
    var data: SaveCustomGroupCommand = {
      id: _group.id,
      schoolId: _group.schoolId,
      yearGroupId: _group.yearGroup?.id,
      subjectId: _group.subject?.id,
      name: _group.name,
      category: _group.category,
      isPrivate: _group.isPrivate,
      allowSignup: _group.allowSignup,
      responsibleStaff: _group.responsibleStaff?.map(x => x.id),
      students: allStudents?.map(x => x.id),
      linkedEvents: _group.linkedEvents?.map(x => x.id),
      isActive: _group.isActive,
      description: _group.description
    }

    if (groupId && _group.id) {
      setLoadingSaveGroup(true);
      customGroupActions.saveCustomGroup(data, onSuccess, (error: IBeehiveError) => {
        setSaveGroupError(error);
        setLoadingSaveGroup(false);
      });
    } else if (_group.id) {
      const allStudents = _students.concat(existingStudents);
      customGroupActions.updateCustomGroupStudents(
        _group.id,
        { id: _group.id, studentIds: allStudents.map(student => student.id) },
        onSuccess
      );
    } else {
      setLoadingSaveGroup(true);
      customGroupActions.createCustomGroup(data, onSuccess, (error: IBeehiveError) => {
        setSaveGroupError(error);
        setLoadingSaveGroup(false);
      });
    }
  };

  const handleAddStudent = (newStudent: UserListView) => {
    if (_students.some(s => s.id === newStudent.id)) {
      return;
    }
    _setStudents([..._students, { ...newStudent, canBeDeleted: true } as CustomGroupStudentView]);
  };


  const handleAddGroup = (newGroup: SearchResultViewBase) => {
    if (newGroup?.subCategory == null) {
      return;
    }

    if (!window.confirm(`This will import all students from the ${newGroup.title} group to the current group. Are you sure? `)) {
      return;
    }

    groupActions.getGroup(
      user.id, 
      parseInt(newGroup.id), 
      newGroup.subCategory as number as GroupType,
      (groupDetails) => {
        const groupStudentsToAdd = groupDetails?.students?.filter(groupStudent => {
          return !_students.find(_student => groupStudent.id == _student.id);
        })
        .map(groupStudent => ({ ...groupStudent, canBeDeleted: true } as CustomGroupStudentView));
        _setStudents([..._students, ...groupStudentsToAdd]);
      }
    );
  };


  const handleAddStudentOrGroup = (searchResult: SearchResultView<UserListView | null>, label: string) => {
    if (!searchResult) {
      return;
    }
    if (searchResult.category === SearchCategory.User) {
      handleAddStudent(searchResult.data);
    }
    else if (searchResult.category === SearchCategory.Group) {
      handleAddGroup(searchResult);
    }
  };

  const removeStudentFromList = (studentId: string, studentArray: CustomGroupStudentView[]) => {
    const studentsCopy = studentArray.slice();
    const studentIndex = studentsCopy.findIndex(
      student => student.id === studentId
    );

    if (studentIndex >= 0) {
      studentsCopy.splice(studentIndex, 1);
    }

    return studentsCopy;
  };

  const handleRemoveSelectedStudent = (studentId: string) => {
    _setStudents(removeStudentFromList(studentId, _students));
  };

  const handleRemoveExistingStudent = (studentId: string) => {
    setExistingStudents(removeStudentFromList(studentId, existingStudents));
  };

  const handleSchoolChange = (value: number) => {
    if (
      window.confirm(
        "Changing the school will deselect any students that you have selected. Are you sure you want to do this?"
      )
    ) {
      _setSchoolId(value);
      _setStudents([]);
      setExistingStudents([]);
    } else {
      _setSchoolId(_schoolId);
    }
  };

  const handleImportSuccess = (importedStudents: UserTinyView[]) => {
    _setImportOpen(false);
    _setStudents(arrays.removeDuplicates([
      ..._students, 
      ...importedStudents.map(importedStudent => ({ ...importedStudent, canBeDeleted: true } as CustomGroupStudentView))
    ]));
  };

  return (
    <Modal
      title="Manage Custom Group"
      open={open}
      onClose={onClose}
      width="95%"
      height="90%"
    >
      <Modal.Body>
        <ApiExceptionMessage error={errorUpdatingStudents} />
        <ApiExceptionMessage error={saveGroupError} />

        <MainWrapper>
          {loadingGroups ? (
            <Loader size={Size.Medium} fluid />
          ) : (
            <Wrapper
              multiSchool={user.schools?.length > 0}
              hideGroups={newGroup || groupId != null}
            >
              {!newGroup && !groupId && (
                <>
                  <div className="group-list-wrapper">
                    <List>
                      <List.Item
                        className="new-custom-group-button"
                        icon="plus"
                        text="Create New Custom Group"
                        transparent
                        onClick={handleCreateNewGroupClick}
                        selected={showCustomGroupForm}
                      />
                    </List>

                    <GroupList
                      groups={groups}
                      selectedGroupId={selectedGroupId}
                      handleGroupClick={(groupId, groupType) =>
                        getGroup(groupId)
                      }
                      title="Choose from an existing custom group"
                      isExpanded
                    />
                  </div>
                </>
              )}

              <div className="custom-group-editor">
                {error && (
                  <Message
                    text="There was a problem while loading the group"
                    color={Swatches.Danger}
                  />
                )}

                {loadingGroup ? (
                  <Loader size={Size.Medium} fluid />
                ) : (
                  <>
                    <CustomGroupFormWrapper
                      className="custom-group-form"
                      hide={!showCustomGroupForm}
                    >
                      {(newGroup || groupId) && (
                        <MultiSchoolUserView>
                          <Card title="School">
                            <Card.Body>
                              <SchoolDropdown
                                onChange={handleSchoolChange}
                                initialValue={_schoolId}
                                fluid
                              />
                            </Card.Body>
                          </Card>
                        </MultiSchoolUserView>
                      )}
                      <Subtitle
                        text={_group?.id ? _group.name : "New Custom Group"}
                      />
                      {!groupId ||
                        (!_group?.id && (
                          <HelpText>
                            You can create a new custom group by entering
                            details below. You can give it a name and optionally
                            a year group and subject and you can make it
                            private.
                          </HelpText>
                        ))}

                      {/* {loadingGroup && <Loader size={Size.Medium} fluid />} */}

                      {_group && (
                        <CustomGroupForm
                          group={_group}
                          handleGroupChange={group => _setGroup(group)}
                          schoolId={_schoolId}
                        />
                      )}
                    </CustomGroupFormWrapper>

                    <div className="student-list-wrapper">
                      <Subtitle text="Custom Group Students" />

                      <HelpText>
                        { newGroup
                          ? `Add students to the custom group by using the search box below${ _group?.category === CustomGroupCategory.Intervention 
                              ? `, or by importing them from a CSV using the "Import" button`
                              : `.`
                            }`
                          : "You can add or remove students from the custom group. You can search for students to add below."}
                      </HelpText>

                      <Card>
                        <Card.Body>
                          <div className="student-picker-wrapper">
                            {/* <StudentPicker
                              fluid
                              onChange={value => handleAddStudent(value)}
                              schools={[_schoolId]}
                            /> */}
                            <UserAndGroupsSearch
                              userTypes={[ UserType.STUDENT ]}
                              groupTypes={[ GroupType.ClassGroup, GroupType.TutorGroup, GroupType.Custom ]}
                              onChange={handleAddStudentOrGroup}
                              schools={[_schoolId]}
                              fluid
                            />
                            { _group?.category === CustomGroupCategory.Intervention && (
                              <Button
                                text="Import Students"
                                size={Size.Small}
                                color={Swatches.Primary}
                                onClick={() => _setImportOpen(true)}
                              />
                            )}
                          </div>
                        </Card.Body>
                      </Card>

                      {_students?.length > 0 && (
                        <CustomGroupStudentList
                          students={_students}
                          listTitle="Students to Add"
                          handleRemoveStudent={handleRemoveSelectedStudent}
                        />
                      )}

                      <br />

                      {existingStudents?.length > 0 && (
                        <CustomGroupStudentList
                          students={existingStudents}
                          listTitle={`Students already in ${_group?.name}`}
                          handleRemoveStudent={handleRemoveExistingStudent}
                          existing
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </Wrapper>
          )}
        </MainWrapper>
        {/* <LoadingOverlay>
          <Loader size={Size.Medium} fluid />
        </LoadingOverlay> */}

        <ImportStudentsModal
          open={_importOpen}
          schoolId={_group?.schoolId}
          onCancel={() => _setImportOpen(false)}
          onImported={handleImportSuccess}
        />
      </Modal.Body>

      <Modal.Footer>
        <ActionBar low>
          <Right>
            <Button
              text="Save"
              color={Swatches.Success}
              onClick={handleSave}
              size={Size.Small}
              disabled={
                !_group ||
                _group?.name.trim() === "" ||
                arrays.isEmpty(_group?.responsibleStaff)
              }
              working={loadingUpdateStudents || loadingSaveGroup}
            />
            <Button
              text="Cancel"
              color={Swatches.Low}
              onClick={onClose}
              size={Size.Small}
              working={loadingUpdateStudents || loadingSaveGroup}
            />
          </Right>
        </ActionBar>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomGroupEditorModal;
