import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Constants } from "configuration";
import { arrays, users } from "utils";
import moment from "moment";
import {
  EmptyMessage,
  Left,
  DateTime,
  Loader,
  Size,
  Title,
  TitleSize,
  Table,
  SplitButton,
  Message,
  Swatches,
  StructuredList,
  TextInput,
  DatePicker,
  RichTextEditor,
  Card,
  ActionBar,
  Button,
  Right,
  TextInputType,
  Dropdown,
  Checkbox,
  ToastService,
  ValidationMessage,
} from "ui-kit";
import EventDateEditorModal from "./eventDateEditorModal";
import EventMenuItemEditorModal from "./eventMenuItemEditorModal";
import MenuList from "./menuList";
import Unauthorized from "sharedComponents/layout/messages/unauthorized";
import Subheader from "sharedComponents/layout/header/subheader";
import {
  GroupSelector,
  SchoolPicker,
  StaffPicker,
  StatusView,
} from "sharedComponents/common";
import calendarActions from "../../actions/calendarActions";
import { RootState } from "reducers/store";
import { SchoolStartupView } from "types/schools/schools.types";
import {
  CalendarCategoryListView,
  CalendarEventDateListView,
  CalendarEventDetailView,
  CalendarEventMenuItemListView,
} from "areas/calendar/types/calendarResponses.types";
import { SchoolWithRead } from "sharedComponents/common/schools/schoolPicker";
import { GroupListView } from "types/users/userGroups.types";
import { SaveCalendarEventCommand } from "areas/calendar/types/calendarRequests.types";
import { PublishStatus } from "types/common/status.types";
import RepeatingDateModal from "./repeatingDateModal";
import { useScroll } from "hooks/useScroll";
import { UserType } from "configuration/constants.enums";
import { GroupType } from "types/planner/groups.types";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

//This is an enum to represent the known category names returned from the api. Stops me from making typos.
enum CategoryNames {
  ExtraCurricularPE = "Extra Curricular (PE)",
  ExtraCurricular = "Extra Curricular",
  StaffEvents = "Staff Events",
  Interventions = "Interventions",
}

interface CalendarCategoryOption {
  key: number;
  label: string;
  value: CalendarCategoryListView;
}

interface ICategorySpecificDetails {
  hasMenu: boolean;
  isSingleGroupType: boolean;
  targetGroupType?: GroupType;
  targetUserType?: UserType;
}


const EventEditor = () => {

  const { scrollToTop } = useScroll();
  let { eventId } = useParams();
  const navigate = useNavigate();

  const { calendarEvent, loading, error, saveError, saving } = useSelector(
    (state: RootState) => state.calendarEvent
  );
  const { loading: loadingCategories, error: categoriesError } = useSelector(
    (state: RootState) => state.calendarCategories
  );
  const { user } = useSelector((state: RootState) => state.currentUser);

  const calendarEventDefault: CalendarEventDetailView = {
    description: "",
    requireParentalConsent: false,
    visibleFrom: null,
    userRestrictions: null,
    createdDate: new Date(),
    createdById: user.id,
    createdBy: null,
    updatedDate: new Date(),
    updatedById: user.id,
    updatedBy: null,
    assignedTo: [],
    schools: [user.baseSchool],
    groups: [],
    classGroups: [],
    tutorGroups: [],
    yearGroups: [],
    subjects: [],
    customGroups: [],
    links: [],
    files: [],
    responsibleStaff: [],
    dates: [],
    menu: [],
    accessRequirements: "",
    dietaryRequirements: "",
    menuChoices: [],
    id: null,
    category: null,
    status: PublishStatus.Draft,
    title: "",
    signupLimit: 0,
    requireSignup: false,
    mandatoryAttendance: false,
    isSticky: false,
    allowSignupFrom: null,
    allowSignupTo: null,
    signedUp: false,
    isSignupOpen: false,
    enableReserveList: false,
    nextDate: null,
    dateCount: 0,
  };

  const [_calendarEvent, _setCalendarEvent] =
    useState<CalendarEventDetailView>(calendarEventDefault);
  const [_description, _setDescription] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [_categories, _setCategories] = useState<CalendarCategoryOption[]>([]);
  const [_selectedEventDate, _setSelectedEventDate] =
    useState<CalendarEventDateListView | null>(null);
  const [_selectedEventDateIndex, _setSelectedEventDateIndex] = useState<
    number | null
  >(null);
  const [_selectedEventMenuItem, _setSelectedEventMenuItem] =
    useState<CalendarEventMenuItemListView | null>(null);
  const [_schools, _setSchools] = useState<SchoolStartupView[]>([
    ...user.schools,
    user.baseSchool,
  ]);
  const [_eventRangeModalOpen, _setEventRangeModalOpen] = useState<boolean>(false);
  const [_eventCategoryDetails, _setEventCategoryDetails] = useState<ICategorySpecificDetails>({
    hasMenu: false,
    isSingleGroupType: false,
    targetGroupType: undefined
  });

  const userCanEdit = () => {
    return (
      calendarEvent?.createdBy?.id == user.id ||
      calendarEvent?.responsibleStaff?.some((staff) => staff.id == user.id) ||
      users.isInAnyRoles(user, [Constants.ROLES.DEVELOPMENT])
    );
  };

  const getEvent = () => {
    if (eventId) {
      user &&
        calendarActions.getCalendarEvent(
          user.id,
          parseInt(eventId),
          true,
          (data: CalendarEventDetailView) => {
            _setDescription(data?.description);
            _setCalendarEvent(data);
          }
        );
    } else {
      _setCalendarEvent(calendarEventDefault);
    }
  };

  useEffect(() => {
    saveError && scrollToTop();
  }, [saveError]);

  useEffect(() => {
    calendarActions.getCalendarCategories(
      (data: CalendarCategoryListView[]) => {
        var options: CalendarCategoryOption[] = data?.map((cat) => ({
          key: cat.id,
          label: cat.name,
          value: cat,
        }));
        _setCategories(options);
        getEvent();
      }
    );
  }, []);

  useEffect(() => {
    if (_calendarEvent?.category) {
      switch(_calendarEvent?.category?.name) {
        case CategoryNames.ExtraCurricularPE:
          _setEventCategoryDetails({
            hasMenu: false,
            isSingleGroupType: false,
            targetUserType: UserType.STUDENT
          });
          break;
        case CategoryNames.ExtraCurricular:
          _setEventCategoryDetails({
            hasMenu: false,
            isSingleGroupType: false,
            targetUserType: UserType.STUDENT
          });
          break;
        case CategoryNames.Interventions:
          _setEventCategoryDetails({
            hasMenu: false,
            isSingleGroupType: true,
            targetGroupType: GroupType.Custom,
            targetUserType: UserType.STUDENT
          });
        break;
        case CategoryNames.StaffEvents:
          _setEventCategoryDetails({
            hasMenu: true,
            isSingleGroupType: false,
            targetUserType: UserType.STAFF
          });
        break;
        default:
          break;
      }
    }
  }, [_calendarEvent?.category])

  const handleSchoolsChange = (schools: SchoolWithRead[]) => {
    var newSchools = schools?.filter((s) => s.selected);
    var newGroups = _calendarEvent?.groups;

    // if there are any groups that don't have a selected school, remove them

    _calendarEvent?.groups?.forEach((group) => {
      if (newSchools?.every((school) => school.id != group.school?.id)) {
        arrays.removeItem(newGroups, group);
      }
    });

    _setCalendarEvent({
      ..._calendarEvent,
      schools: newSchools,
      groups: newGroups,
    });
  };

  //moved to groupchange handler to avoid usestate race conditions
  // const setGroups = (groups: GroupListView[]) => {
  //   // if there are any groups that don't have the school selected, then select it
  //   var newSchools = _calendarEvent?.schools;

  //   groups?.forEach((group) => {
  //     if (newSchools?.every((s) => s.id != group.school?.id)) {
  //       // console.log(_calendarEvent.schools);
  //       // console.log(group.school);

  //       newSchools = [...newSchools, group.school];
  //       // console.log(group);
  //     }
  //   });

  //   _setCalendarEvent(ev => ({
  //     ...ev,
  //     groups: groups,
  //     schools: newSchools,
  //   }));
  // };

  const handleSave = (saveAsDraft: boolean) => {
    var errors = [];

    if (!_calendarEvent.title) {
      errors.push(`A title is required.`);
    }

    if (!_calendarEvent.description) {
      errors.push(`Please enter a description for the event.`);
    }

    if (!_calendarEvent.dates.length) {
      errors.push(`Please enter at least one date for the event.`);
    }

    // if (_calendarEvent.visibleFrom) {
    //   var visibleFrom = moment(_calendarEvent.visibleFrom, "DD/MM/YYYY");
    //   if (visibleFrom.isAfter(startsTime)) {
    //     errors.push(`The visible from date is after the event start date.`);
    //   }
    // }

    // if (_calendarEvent.allowSignupFrom) {
    //   var allowSignupFrom = moment(
    //     _calendarEvent.allowSignupFrom,
    //     "DD/MM/YYYY"
    //   );
    //   if (allowSignupFrom.isAfter(startsTime)) {
    //     errors.push(`The visible from date is after the event start date.`);
    //   }
    // }
    // }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    var data: SaveCalendarEventCommand = {
      ..._calendarEvent,
      categoryId: _calendarEvent.category.id,
      schools: _calendarEvent.schools.map((school) => school.id),
      responsibleStaff: _calendarEvent.responsibleStaff?.map((x) => x.id),
      menu: _calendarEvent.menu?.flatMap((course) => course.menu),
      status: saveAsDraft ? 0 : 2,
      dates: _calendarEvent.dates.map((date) => ({
        ...date,
        starts: moment(date.starts).utc().toDate(),
        ends: moment(date.ends).utc().toDate(),
      })),
      assignedTo: _calendarEvent.assignedTo.map((s) => s.id),
    };

    if (_calendarEvent.id) {
      calendarActions.updateCalendarEvent(data, () => {
        ToastService.pop("Event Saved", null, "calendar");
        //history.goBack(); //if you load the edit modal from a url in a new tab like I do, back just takes you to a newtab page
        navigate("../");
      });
    } else {
      calendarActions.createCalendarEvent(data, () => {
        //history.goBack(); //instead, go up one directory
        navigate("../");
        ToastService.pop("Event Created", null, "calendar");
      });
    }
  };

  const handleNewEventClick = () => {
    _setSelectedEventDate({
      starts: new Date(),
      ends: new Date(),
      id: null,
      status: 0,
      signedUp: false,
      requireSignup: false,
    });
    _setSelectedEventDateIndex(null);
  };

  const deleteEventDate = (event: CalendarEventDateListView, index: number) => {
    _setCalendarEvent({
      ..._calendarEvent,
      dates: arrays.remove(_calendarEvent.dates, index),
    });
  };

  const handleEditEventDate = (
    eventDate: CalendarEventDateListView,
    index: number
  ) => {
    _setSelectedEventDate(eventDate);
    _setSelectedEventDateIndex(index);
  };

  const handleDeleteEventDate = (
    eventDate: CalendarEventDateListView,
    index: number
  ) => {
    deleteEventDate(eventDate, index);
  };

  const handleEventDateEditorModalClosed = () => {
    _setSelectedEventDate(null);
    _setSelectedEventDateIndex(null);
  };

  const handleDateRangeAdd = (eventDates: CalendarEventDateListView[]) => {
    _setEventRangeModalOpen(false);
    _setCalendarEvent({
      ..._calendarEvent,
      dates: [..._calendarEvent.dates, ...eventDates],
    });
  };

  const handleEventDateSaved = (eventDate: CalendarEventDateListView) => {
    if (_selectedEventDateIndex !== null) {
      _setCalendarEvent({
        ..._calendarEvent,
        dates: _calendarEvent.dates.map((date, index) =>
          index === _selectedEventDateIndex ? eventDate : date
        ),
      });
    } else {
      _setCalendarEvent({
        ..._calendarEvent,
        dates: [..._calendarEvent.dates, eventDate],
      });
    }

    _setSelectedEventDate(null);
    _setSelectedEventDateIndex(null);
  };

  const handleEventDateDeleted = (eventDate: CalendarEventDateListView) => {
    deleteEventDate(eventDate, _selectedEventDateIndex);
    _setSelectedEventDate(null);
    _setSelectedEventDateIndex(null);
  };

  const handleNewEventMenuItemClick = () => {
    _setSelectedEventMenuItem({
      name: "",
      code: "",
      description: "",
      vegetarian: false,
      vegan: false,
      allergy: false,
      halal: false,
      id: null,
      type: null,
      typeName: null,
    });
  };

  const deleteEventMenuItem = (
    eventMenuItem: CalendarEventMenuItemListView
  ) => {
    const courseSection = _calendarEvent.menu.filter(
      (course) => course.type === eventMenuItem.type
    );

    if (courseSection.length > 0) {
      _setCalendarEvent({
        ..._calendarEvent,
        menu: _calendarEvent.menu.map((course, index) =>
          course.type === eventMenuItem.type
            ? {
                ...course,
                menu: course.menu.filter(
                  (dish) => dish.id !== eventMenuItem.id
                ),
              }
            : course
        ),
      });
    }
  };

  const handleEditEventMenuItem = (
    eventMenuItem: CalendarEventMenuItemListView,
    index: number
  ) => {
    _setSelectedEventMenuItem(eventMenuItem);
  };

  const handleDeleteEventMenuItem = (
    eventMenuItem: CalendarEventMenuItemListView,
    index: number
  ) => {
    deleteEventMenuItem(eventMenuItem);
  };

  const handleEventMenuItemEditorModalClosed = () => {
    _setSelectedEventMenuItem(null);
  };

  const handleEventMenuItemSaved = (
    eventMenuItem: CalendarEventMenuItemListView
  ) => {
    const menuDishlist = _calendarEvent.menu?.flatMap((course) => course.menu);
    const existingItem =
      eventMenuItem.id != null
        ? menuDishlist.find((x) => x.id === eventMenuItem.id)
        : null;
    const sameCourse = eventMenuItem.type === existingItem?.type;
    const courseSection = _calendarEvent.menu.filter(
      (course) => course.type === eventMenuItem.type
    );

    if (courseSection.length > 0) {
      //if there is a course section of the menu where the course type matches the menuItem type use it (use .empty rather than null because array filter will return an empty array if none are found)
      _setCalendarEvent({
        ..._calendarEvent,
        menu: _calendarEvent.menu.map((course, index) =>
          course.type === eventMenuItem.type
            ? {
                ...course,
                menu:
                  eventMenuItem.id != null &&
                  course.menu.find((x) => x.id === eventMenuItem.id) != null //check if new item or editing item in this course
                    ? course.menu.map((dish) =>
                        dish.id === eventMenuItem.id ? eventMenuItem : dish
                      ) //item is already under this course, so update
                    : [...course.menu, eventMenuItem], //item was not here so append it to the course
              }
            : existingItem && !sameCourse && course.type === existingItem.type
            ? {
                ...course,
                menu: course.menu.filter((dish) => dish.id !== existingItem.id),
              } //the item already existed under another course, so we remove it from there
            : course
        ),
      });
    } else {
      //else we create the course section first using the menuItem details and then add the menuItem to the course
      _setCalendarEvent({
        ..._calendarEvent,
        menu: [
          ..._calendarEvent.menu.map((course, index) =>
            existingItem && !sameCourse && course.type === existingItem.type
              ? {
                  ...course,
                  menu: course.menu.filter(
                    (dish) => dish.id !== existingItem.id
                  ),
                }
              : course
          ),
          {
            type: eventMenuItem.type,
            typeName: eventMenuItem.typeName,
            menu: [eventMenuItem],
          },
        ],
      });
    }

    _setSelectedEventMenuItem(null);
  };

  const handleEventMenuItemDeleted = (
    eventMenuItem: CalendarEventMenuItemListView
  ) => {
    deleteEventMenuItem(eventMenuItem);
    _setSelectedEventMenuItem(null);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleGroupsChange = (groups: GroupListView[]) => {
    var newSchools = _calendarEvent?.schools;
    groups?.forEach((group) => {
      if (newSchools?.every((s) => s.id != group.school?.id)) {
        newSchools = [...newSchools, group.school];
      }
    });
    if ((_eventCategoryDetails.isSingleGroupType === true) && (_eventCategoryDetails.targetGroupType != null)) {
      var _newGroups = groups?.filter((group) => group.type === _eventCategoryDetails.targetGroupType);
      _setCalendarEvent({
        ..._calendarEvent,
        groups: _newGroups,
        schools: newSchools,
        userRestrictions: _eventCategoryDetails.targetUserType
          ? {
            allowStudents: _eventCategoryDetails.targetUserType === UserType.STUDENT,
            allowParents: _eventCategoryDetails.targetUserType === UserType.PARENT,
            allowStaff: _eventCategoryDetails.targetUserType === UserType.STAFF,
          }
          : _calendarEvent.userRestrictions,
        mandatoryAttendance: (_newGroups.length === 0)
          ? false
          : _calendarEvent.mandatoryAttendance
      });
    }
    else {
      _setCalendarEvent({
        ..._calendarEvent,
        groups: groups,
        schools: newSchools,
      });
    }
  }

  if (_calendarEvent?.id && !userCanEdit()) {
    return <Unauthorized />;
  }

  if (loading || loadingCategories) {
    return <Loader size={Size.Large} cover />;
  }

  if (error || categoriesError) {
    return (
      <Wrapper>
        <EmptyMessage
          icon="times-circle"
          title="A problem occurred"
          summary="There was a problem while loading the calendar event"
          cover
        />
      </Wrapper>
    );
  }

  return (
    <>
      <Subheader>
        <Title
          size={TitleSize.H2}
          text="Events"
          sub={eventId ? "Edit Event" : "New Event"}
        />
      </Subheader>
      {saveError && <Message text={saveError} color={Swatches.Danger} />}
      <ValidationMessage errors={validationErrors} />

      <Card title="General Details">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Category" required>
              <Dropdown
                placeholder="Chose a Category..."
                items={_categories}
                onChange={(val) =>
                  _setCalendarEvent({ ..._calendarEvent, category: val })
                }
                value={_calendarEvent.category}
                fluid
              />
            </StructuredList.Item>
            <StructuredList.Item
              name="Event Title"
              required
              description="Please set a title that describes the event being created. This will be visible to parents and students."
              helpPopup
            >
              <TextInput
                value={_calendarEvent.title}
                onChange={(value) =>
                  _setCalendarEvent({ ..._calendarEvent, title: value })
                }
                fluid
              />
            </StructuredList.Item>

            <StructuredList.Item
              required
              name="Details"
              description="Please note that this will be seen by students and parents."
              helpPopup
            >
              <RichTextEditor
                initialValue={_description}
                onChange={(value) =>
                  _setCalendarEvent({ ..._calendarEvent, description: value })
                }
              />
            </StructuredList.Item>
            <StructuredList.Item
              name="Sticky"
              description="Events that are set as sticky always appear at the top of users' lists. Sticky events are intended for urgent or important events."
              helpPopup
            >
              <Checkbox
                checked={_calendarEvent.isSticky}
                text={`Event is sticky`}
                onChange={(value) => {
                  _setCalendarEvent({
                    ..._calendarEvent,
                    isSticky: value.checked,
                  });
                }}
              />
            </StructuredList.Item>
            <StructuredList.Item
              name="Visible From"
              description="If selected, the event will only be visible after this date."
              helpPopup
            >
              <DatePicker
                dateFormat="DD/MM/YYYY"
                placeholder="Pick a visible from date..."
                closeOnSelect
                selectedDate={_calendarEvent.visibleFrom}
                onChange={(value) => {
                  _setCalendarEvent({ ..._calendarEvent, visibleFrom: value });
                }}
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>
      <Card title="Available For">
        <Card.Body>
          <StructuredList>
            {(_calendarEvent?.schools?.length > 1 || _schools?.length > 1) && (
              <StructuredList.Item
                name="School"
                description="You can restrict the event to one or more schools."
                helpPopup
              >
                {/* {_calendarEvent?.schools?.length > 1 && (
                  <pre>{JSON.stringify(_calendarEvent?.schools, null, 2)}</pre>
                )} */}
                <SchoolPicker
                  onChange={handleSchoolsChange}
                  value={_calendarEvent?.schools}
                  selectedTooltip={(school) =>
                    `This event is set for ${school.name} `
                  }
                  deselectedTooltip={(school) =>
                    `This event is not set for ${school.name} `
                  }
                  readOnlyTooltip={(school) =>
                    `You do not have permission to set or unset events for ${school.name} `
                  }
                />
              </StructuredList.Item>
            )}
            <StructuredList.Item
              name="For Users"
              description="You can choose which user types can sign up for this event"
              hide={_eventCategoryDetails.isSingleGroupType}
              helpPopup
            >
              <Checkbox
                checked={_calendarEvent?.userRestrictions?.allowStudents}
                text={`Students`}
                onChange={(value) => {
                  _setCalendarEvent({
                    ..._calendarEvent,
                    userRestrictions: {
                      ..._calendarEvent.userRestrictions,
                      allowStudents: value.checked,
                    },
                  });
                }}
              />
              <Checkbox
                checked={_calendarEvent?.userRestrictions?.allowStaff}
                text={`Staff`}
                onChange={(value) => {
                  _setCalendarEvent({
                    ..._calendarEvent,
                    userRestrictions: {
                      ..._calendarEvent.userRestrictions,
                      allowStaff: value.checked,
                    },
                  });
                }}
              />
              <Checkbox
                checked={_calendarEvent?.userRestrictions?.allowParents}
                text={`Parents`}
                onChange={(value) => {
                  _setCalendarEvent({
                    ..._calendarEvent,
                    userRestrictions: {
                      ..._calendarEvent.userRestrictions,
                      allowParents: value.checked,
                    },
                  });
                }}
              />
            </StructuredList.Item>
            <StructuredList.Item
              name="Available For"
              description="Please enter the groups this event is available for."
              helpPopup
            >
              <GroupSelector
                groupType={_eventCategoryDetails.targetGroupType}
                onChange={handleGroupsChange}
                schools={_calendarEvent.schools}
                groups={_calendarEvent?.groups}
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      <Card title="Dates">
        <Card.Body noPad>
          {arrays.isEmpty(_calendarEvent.dates) ? (
            <EmptyMessage
              title="No Dates Set"
              icon="calendar"
              summary="No dates have been added for this event yet"
            >
              <ButtonWrapper>
                <Button
                  size={Size.Small}
                  onClick={handleNewEventClick}
                  color={Swatches.Primary}
                  text="Add New Date"
                />
                <Button
                  size={Size.Small}
                  onClick={() => _setEventRangeModalOpen(true)}
                  color={Swatches.Primary}
                  text="Add Date Range"
                />
              </ButtonWrapper>
            </EmptyMessage>
          ) : (
            <>
              <Table zebra>
                <Table.Header>
                  <Table.HeaderCell width={2}>Starts</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Ends</Table.HeaderCell>
                  <Table.HeaderCell width={6} right></Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                  {_calendarEvent.dates.map(
                    (date: CalendarEventDateListView, index: number) => (
                      <Table.Row key={index}>
                        <Table.Cell>
                          <DateTime date={date.starts} bold />
                        </Table.Cell>
                        <Table.Cell>
                          <DateTime date={date.ends} bold />
                        </Table.Cell>
                        <Table.Cell right>
                          <SplitButton
                            size={Size.Small}
                            text="Edit"
                            color={Swatches.Default}
                            onDefaultClick={() => {
                              handleEditEventDate(date, index);
                            }}
                          >
                            <SplitButton.Option
                              onClick={() => {
                                handleEditEventDate(date, index);
                              }}
                              text="Edit"
                              show={true}
                            />
                            <SplitButton.Option
                              onClick={() => handleDeleteEventDate(date, index)}
                              text="Delete"
                              color={Swatches.Danger}
                              show={true}
                            />
                          </SplitButton>
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.Cell colspan={2} />
                    <Table.Cell right>
                      <Button
                        size={Size.Small}
                        onClick={handleNewEventClick}
                        color={Swatches.Primary}
                        text="Add New Date"
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </>
          )}
        </Card.Body>
      </Card>
      <EventDateEditorModal
        eventDate={_selectedEventDate}
        open={_selectedEventDate != null}
        onClose={handleEventDateEditorModalClosed}
        onSave={handleEventDateSaved}
        onDelete={handleEventDateDeleted}
      />
      <RepeatingDateModal
        open={_eventRangeModalOpen}
        onClose={() => _setEventRangeModalOpen(false)}
        onSave={handleDateRangeAdd}
      />

      { _eventCategoryDetails.hasMenu && (
        <>
          <Card title="Menu">
            <Card.Body noPad>
              <MenuList
                menu={_calendarEvent.menu}
                editable
                onCreate={handleNewEventMenuItemClick}
                onEdit={handleEditEventMenuItem}
                onDelete={handleDeleteEventMenuItem}
              />
            </Card.Body>
          </Card>
          <EventMenuItemEditorModal
            menuItem={_selectedEventMenuItem}
            open={_selectedEventMenuItem != null}
            onClose={handleEventMenuItemEditorModalClosed}
            onSave={handleEventMenuItemSaved}
            onDelete={handleEventMenuItemDeleted}
          />
        </>
      )}

      <Card title="Signup">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item
              name="Mandatory Attendance"
              description="If enabled, students will not be able to unenrol from this event"
              helpPopup
              hide={_calendarEvent?.groups.length === 0}
            >
              <Checkbox
                checked={_calendarEvent.mandatoryAttendance}
                text={`Attendance for this event is mandatory`}
                onChange={(value) => {
                  _setCalendarEvent({
                    ..._calendarEvent,
                    mandatoryAttendance: value.checked,
                    requireSignup: value.checked
                      ? false
                      : _calendarEvent.requireSignup
                  });
                }}
              />
            </StructuredList.Item>
            <StructuredList.Item
              name="Require Signup"
              description="If enabled, this event will require students to sign up before attending."
              hide={_calendarEvent.mandatoryAttendance}
              helpPopup
            >
              <Checkbox
                checked={_calendarEvent.requireSignup}
                text={`This event requires signup.`}
                onChange={(value) => {
                  _setCalendarEvent({
                    ..._calendarEvent,
                    requireSignup: value.checked,
                  });
                }}
              />
            </StructuredList.Item>
            {_calendarEvent.requireSignup && (
              <>
                <StructuredList.Item
                  name="Attendees Limit"
                  description="You can set a limit for the number of attendees."
                  helpPopup
                >
                  <TextInput
                    type={TextInputType.Number}
                    value={_calendarEvent.signupLimit}
                    onChange={(value) =>
                      _setCalendarEvent({
                        ..._calendarEvent,
                        signupLimit: value,
                      })
                    }
                  />
                </StructuredList.Item>
                <StructuredList.Item
                  name="Allow Signup From"
                  description="If set, attendees can only signup after the selected date."
                  helpPopup
                >
                  <DatePicker
                    dateFormat="DD/MM/YYYY"
                    placeholder="Pick a signup from date..."
                    closeOnSelect
                    selectedDate={_calendarEvent.allowSignupFrom}
                    onChange={(value) => {
                      _setCalendarEvent({
                        ..._calendarEvent,
                        allowSignupFrom: value,
                      });
                    }}
                  />
                </StructuredList.Item>
                <StructuredList.Item
                  name="Allow Signup To"
                  description="If set, attendees can only signup before the selected date."
                  helpPopup
                >
                  <DatePicker
                    dateFormat="DD/MM/YYYY"
                    placeholder="Pick a signup to date..."
                    closeOnSelect
                    selectedDate={_calendarEvent.allowSignupTo}
                    onChange={(value) => {
                      _setCalendarEvent({
                        ..._calendarEvent,
                        allowSignupTo: value,
                      });
                    }}
                  />
                </StructuredList.Item>
                {/* <StructuredList.Item
                  name="Enable Reserve List"
                  description="If enabled, if the event is full then any additional signups will go onto a reserve list."
                  helpPopup
                >
                  <Checkbox
                    checked={_calendarEvent.enableReserveList}
                    text={`This event has a reserve list`}
                    onChange={(value) => {
                      _setCalendarEvent({
                        ..._calendarEvent,
                        enableReserveList: value.checked,
                      });
                    }}
                  />
                </StructuredList.Item> */}
              </>
            )}
            {/* <StructuredList.Item
              name="Require Consent"
              description="If enabled, this event will require parental consent from students signing up."
              helpPopup
            >
              <Checkbox
                checked={_calendarEvent.requireParentalConsent}
                text={`This event requires parental consent`}
                onChange={(value) => {
                  _setCalendarEvent({
                    ..._calendarEvent,
                    requireParentalConsent: value.checked,
                  });
                }}
              />
            </StructuredList.Item> */}
          </StructuredList>
        </Card.Body>
      </Card>

      {/* <Card title="Attachments &amp; Links">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item
              name="Upload Files"
              description="You can upload .pdf, .docx, .xlsx, .csv, .jpg, .gif, .png or .pptx files.  After uploading, you still need to save the calendarEvent to save the documents. Please note that due to technical limitations with some mobile phones, attachments are only currently available on the web version of Beehive."
              helpPopup
            >
              <FilePicker
                files={_calendarEvent.files}
                onFilesChanged={(files) =>
                  _setCalendarEvent({ ..._calendarEvent, files: files })
                }
              />
            </StructuredList.Item>
            <StructuredList.Item name="Links">
              <LinkPicker
                links={_calendarEvent.links}
                onLinksChanged={(links) =>
                  _setCalendarEvent({ ..._calendarEvent, links: links })
                }
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card> */}

      <Card title="Responsible Staff">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item
              name="Responsible Staff"
              description="You can provide a list of staff members responsible for managing this event."
            >
              <StaffPicker
                users={_calendarEvent?.responsibleStaff}
                onChange={(users) =>
                  _setCalendarEvent({
                    ..._calendarEvent,
                    responsibleStaff: users,
                  })
                }
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      <ActionBar low>
        <Left>
          <Button
            text="Cancel"
            color={Swatches.Low}
            onClick={handleCancel}
            working={saving}
            size={Size.Small}
          />
        </Left>

        <Right>
          <StatusView status={_calendarEvent.status}>
            <StatusView.Item status={Constants.PUBLISH_STATUS.DRAFT.value}>
              <Button
                text="Save as Draft"
                color={Swatches.Primary}
                onClick={() => handleSave(true)}
                working={saving}
                size={Size.Small}
              />
            </StatusView.Item>
            <StatusView.Item></StatusView.Item>
          </StatusView>
          <Button
            text={
              _calendarEvent.status == Constants.PUBLISH_STATUS.DRAFT.value
                ? `Save and Publish`
                : `Save`
            }
            color={Swatches.Success}
            onClick={() => handleSave(false)}
            working={saving}
            size={Size.Small}
          />
        </Right>
      </ActionBar>
    </>
  );
};

export default EventEditor;
