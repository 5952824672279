import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AssignmentAnalyticsForm from "./assignmentAnalyticsForm";
import { arrays } from "utils";
import moment from "moment";
import AssignmentReportTable from "./assignmentReportTable";
import { Title, TitleSize } from "ui-kit";
import ReportDescription from "../reportDescription";
import assignmentAnalyticsActions from "areas/analytics/actions/assignmentAnalyticsActions";
import { useAppSelector } from "reducers/hooks";
import { SearchResultViewBase } from "types/common/views.types";
import { DateRange } from "types/common/data.types";
import { AssignmentGroupBy } from "areas/analytics/types/assignmentAnalyticsShared.types";


export interface AssignmentAnalyticsReportParameters {
  groupById: AssignmentGroupBy,
}


const AssignmentAnalyticsWrapper: React.FC = () => {

  const reportRef = useRef<HTMLDivElement>();
  const { report, loading, loadingExportReport } = useAppSelector(
    state => state.assignmentAnalytics
  );
  const [reportParameters, setReportParameters] = useState<AssignmentAnalyticsReportParameters>(null);

  useEffect(() => {
    if (loading || report) {
      reportRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading, report]);

  const handleGenerateReport = (
    setDateRange: DateRange,
    deadlineDateRange: DateRange,
    filterStaff: SearchResultViewBase[],
    filterClassGroups: SearchResultViewBase[],
    filterTutorGroups: SearchResultViewBase[],
    filterCustomGroups: SearchResultViewBase[],
    filterYearGroups: SearchResultViewBase[],
    filterSubjects: SearchResultViewBase[],
    groupById: AssignmentGroupBy,
    exportReport?: boolean
  ) => {
    const setStartDate = moment(setDateRange.startDate, [
      "DD/MM/YYYY",
    ]).format("YYYY-MM-DD");
    const setEndDate = moment(setDateRange.endDate, ["DD/MM/YYYY"]).format(
      "YYYY-MM-DD"
    );
    const deadlineStartDate = deadlineDateRange.startDate
      ? moment(deadlineDateRange.startDate, ["DD/MM/YYYY"]).format(
          "YYYY-MM-DD"
        )
      : null;
    const deadlineEndDate = deadlineDateRange.endDate
      ? moment(deadlineDateRange.endDate, ["DD/MM/YYYY"]).format(
          "YYYY-MM-DD"
        )
      : null;

    const staffIds = filterStaff.map(staff => staff.id);
    const classGroupIds = filterClassGroups.map(staff => staff.id);
    const tutorGroupIds = filterTutorGroups.map(staff => staff.id);
    const customGroupIds = filterCustomGroups.map(staff => staff.id);
    const yearGroupIds = filterYearGroups.map(staff => staff.id);
    const subjectIds = filterSubjects.map(staff => staff.id);

    if (exportReport) {
      assignmentAnalyticsActions.exportAssignmentReport(
        {
          deadlineStartDate: deadlineStartDate,
          deadlineEndDate: deadlineEndDate,
          setStartDate: setStartDate,
          setEndDate: setEndDate,
          staffIds: arrays.isEmpty(staffIds) ? null : staffIds.join(),
          classGroupIds: arrays.isEmpty(classGroupIds) ? null : classGroupIds.join(),
          tutorGroupIds: arrays.isEmpty(tutorGroupIds) ? null : tutorGroupIds.join(),
          customGroupIds: arrays.isEmpty(customGroupIds) ? null : customGroupIds.join(),
          yearGroupIds: arrays.isEmpty(yearGroupIds) ? null : yearGroupIds.join(),
          subjectIds: arrays.isEmpty(subjectIds) ? null : subjectIds.join(),
          assignmentGroupById: groupById,
        }
      );
    } else {
      const onSuccess = () => {
        setReportParameters({
          groupById: groupById,
        });
      };

      assignmentAnalyticsActions.getAssignmentReport(
        {
          deadlineStartDate: deadlineStartDate,
          deadlineEndDate: deadlineEndDate,
          setStartDate: setStartDate,
          setEndDate: setEndDate,
          staffIds: arrays.isEmpty(staffIds) ? null : staffIds.join(),
          classGroupIds: arrays.isEmpty(classGroupIds) ? null : classGroupIds.join(),
          tutorGroupIds: arrays.isEmpty(tutorGroupIds) ? null : tutorGroupIds.join(),
          customGroupIds: arrays.isEmpty(customGroupIds) ? null : customGroupIds.join(),
          yearGroupIds: arrays.isEmpty(yearGroupIds) ? null : yearGroupIds.join(),
          subjectIds: arrays.isEmpty(subjectIds) ? null : subjectIds.join(),
          assignmentGroupById: groupById,
        },
        onSuccess
      );
    }
  };

  return (
    <>
      <Title text="Assignments Report" size={TitleSize.H3} />
      <ReportDescription text="Use this report to view assignments set between a selected date range. The assignments can be filtered and can be grouped by a chosen breakdown category." />
      <AssignmentAnalyticsForm
        handleGenerateReport={handleGenerateReport}
        loadingExportReport={loadingExportReport}
      />
      <div ref={reportRef}>
        <AssignmentReportTable reportParameters={reportParameters} />
      </div>
    </>
  );
};

export default AssignmentAnalyticsWrapper;
