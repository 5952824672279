import { CalendarEventMenuItemListView } from "areas/calendar/types/calendarResponses.types";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Icon, Size } from "ui-kit";


const Wrapper = styled.div<{ dropdown?: boolean }>`
  .menu-icon {
    ${({ dropdown }) => dropdown ? `
      margin: 0 4px !important;
      svg {
        margin: 0 !important;
      }
    `
    :`
      margin: 0 4px;
    `}
  }
`


interface IItemDietaryIconsProps {
  menuItem: CalendarEventMenuItemListView;
  dropdown?: boolean;
}


const ItemDietaryIcons: React.FC<IItemDietaryIconsProps> = ({ menuItem, dropdown }) => {

  return (
    <Wrapper dropdown={dropdown}>
      { menuItem.vegetarian && (
        <Icon
          className="menu-icon"
          value="leaf"
          size={Size.Small}
          tooltip="Vegetarian"
          tooltipSub="This dish is suitable for vegetarians. It does not contain meat or fish but may contain dairy products."
        />
      )}
      { menuItem.vegan && (
        <Icon
          className="menu-icon"
          value="seedling"
          size={Size.Small}
          tooltip="Vegan"
          tooltipSub="This dish is suitable for vegans and does not contain meat, fish or dairy products."
        />
      )}
      { menuItem.halal && (
        <Icon
          className="menu-icon"
          value="star-and-crescent"
          size={Size.Small}
          tooltip="Halal"
          tooltipSub="This dish contains Halal meat."
        />
      )}
      { menuItem.allergy && (
        <Icon
          className="menu-icon"
          value="exclamation-triangle"
          size={Size.Small}
          tooltip="Allergy"
          tooltipSub="This dish is intended for people with allergies."
        />
      )}            
    </Wrapper>
  );
}


export default ItemDietaryIcons;