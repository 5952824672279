import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import client from "services/client";
import { BeehiveReport, callbackType } from "types/common/action.types";
import { AcceptableUseReportFilter, StaffReportFilter, StaffRoleReportQuery, UsersReportFilter } from "../types/reportingRequest.types";

const builder = new actionTypeBuilder("usermanagment");

const types = {
  USERMANAGEMENT_USERSREPORT: builder.build("USERMANAGEMENT_USERSREPORT"),
  USERMANAGEMENT_STAFFREPORT: builder.build("USERMANAGEMENT_STAFFREPORT"),
  USERMANAGEMENT_STAFFROLESREPORT: builder.build(
    "USERMANAGEMENT_STAFFROLESREPORT"
  ),
  USERMANAGEMENT_STUDENTSWITHUNREGISTEREDPARENTREPORT: builder.build(
    "USERMANAGEMENT_STUDENTSWITHUNREGISTEREDPARENTREPORT"
  ),
  USERMANAGEMENT_INCOMPLETEACCEPTABLEUSEREPORT: builder.build(
    "USERMANAGEMENT_INCOMPLETEACCEPTABLEUSEREPORT"
  ),
};


const usersReport = (filter: UsersReportFilter, callback?: callbackType<BeehiveReport>) => {
  var url = `users/reporting/users?`;

  url = filter?.userType != null ? `${url}userType=${filter.userType}&` : url;
  url =
    filter?.schoolId != null && filter.schoolId > -1
      ? `${url}schoolId=${filter.schoolId}&`
      : url;
  url =
    filter?.registered != null
      ? `${url}registered=${filter.registered ? "true" : "false"}&`
      : url;
  url =
    filter?.locked != null
      ? `${url}locked=${filter.locked ? "true" : "false"}&`
      : url;
  url =
    filter?.securityFlag != null
      ? `${url}securityFlag=${filter.securityFlag}&`
      : url;

  return action<BeehiveReport>(
    () => client.get(url, { responseType: "text" }),
    types.USERMANAGEMENT_USERSREPORT,
    callback
  );
};

const staffReport = (filter: StaffReportFilter, callback?: callbackType<BeehiveReport>) => {
  var url = `users/reporting/staff?`;

  url =
    filter?.staffType != null ? `${url}staffType=${filter.staffType}&` : url;
  url =
    filter?.schoolId != null && filter.schoolId > -1
      ? `${url}schoolId=${filter.schoolId}&`
      : url;

  return action<BeehiveReport>(
    () => client.get(url, { responseType: "text" }),
    types.USERMANAGEMENT_STAFFREPORT,
    callback
  );
};

const staffRolesReport = (filter: StaffRoleReportQuery, callback?: callbackType<BeehiveReport>) => {
  var url = `users/reporting/staff/roles?`;

  url = filter?.role != null ? `${url}role=${filter.role}&` : url;
  url =
    filter?.schoolId != null && filter.schoolId > -1
      ? `${url}schoolId=${filter.schoolId}&`
      : url;

  return action<BeehiveReport>(
    () => client.get(url, { responseType: "text" }),
    types.USERMANAGEMENT_STAFFROLESREPORT,
    callback
  );
};

const studentsWithUnregisteredParentReport = (schoolId: number, callback?: callbackType<BeehiveReport>) => {
  var url = `users/reporting/students/noregisteredparent?`;

  url = schoolId != null && schoolId > -1
      ? `${url}schoolId=${schoolId}&`
      : url;

  return action<BeehiveReport>(
    () => client.get(url, { responseType: "text" }),
    types.USERMANAGEMENT_STUDENTSWITHUNREGISTEREDPARENTREPORT,
    callback
  );
};

const incompleteAcceptableUseReport = (filter: AcceptableUseReportFilter, callback?: callbackType<BeehiveReport>) => {
  var url = `users/reporting/acceptableuse?`;

  url = filter?.userType != null ? `${url}userType=${filter.userType}&` : url;
  url =
    filter?.schoolId != null && filter.schoolId > -1
      ? `${url}schoolId=${filter.schoolId}&`
      : url;

  return action<BeehiveReport>(
    () => client.get(url, { responseType: "text" }),
    types.USERMANAGEMENT_INCOMPLETEACCEPTABLEUSEREPORT,
    callback
  );
};

const reportingActions = {
  types,
  usersReport,
  staffReport,
  staffRolesReport,
  studentsWithUnregisteredParentReport,
  incompleteAcceptableUseReport,
};

export default reportingActions;
