import React from "react";
import { Table } from "ui-kit";
import ConsentFormStatus from "./consentFormStatus";
import { PageInfo } from "types/common/paging.types";
import { ConsentFormListView } from "areas/administration/types/consentResponses.types";


interface IConsentFormTableProps {
  forms: ConsentFormListView[];
  onClick?: (form: ConsentFormListView) => void;
  handlePage?: () => void;
  paging?: PageInfo;
}


const ConsentFormTable: React.FC<IConsentFormTableProps> = ({ forms, onClick, handlePage, paging }) => {

  const handleClick = (form: ConsentFormListView) => {
    onClick?.(form);
  };

  return (
    <Table>
      <Table.Header>
        <Table.HeaderCell>Form</Table.HeaderCell>
        <Table.HeaderCell right>Status</Table.HeaderCell>
      </Table.Header>
      <Table.Body onPage={handlePage} paging={paging}>
        {forms.map((form: ConsentFormListView, index: number) => (
          <Table.Row key={index} onClick={() => handleClick(form)}>
            <Table.Cell>{form.name}</Table.Cell>
            <Table.Cell right>
              <ConsentFormStatus status={form.statusId} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default ConsentFormTable;
