import React, { useState, useEffect } from "react";
import {
  Card,
  Dropdown,
  StructuredList,
  DatePicker,
  Message,
  ValidationMessage,
  Button,
  Swatches,
} from "ui-kit";
import { arrays, files } from "utils";
import moment from "moment";
import { useSelector } from "react-redux";
import { SchoolDropdown } from "sharedComponents/common";
import reportingActions from "areas/payments/actions/reportingActions";
import { RootState } from "reducers/store";
import { BeehiveReport } from "types/common/action.types";
import { useScroll } from "hooks/useScroll";


const Reporting = () => {

  const { scrollToTop } = useScroll();
  const { user } = useSelector((state: RootState) => state.currentUser);
  const { loading, error, reportData } = useSelector((state: RootState) => state.paymentsReporting);

  const [reportType, setReportType] = useState<number>(0);
  const [transactionStatus, setTransactionStatus] = useState<number>(-1);
  const [transactionMethod, setTransactionMethod] = useState<number>(-1);
  const [school, setSchool] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [validationErrors, setValidationErrors] = useState<string[]>([]);


  useEffect(() => {
    user && setSchool(user.baseSchool.id);
  }, [user]);

  const openHtml = (report: BeehiveReport) => {
    var newWindow = window.open();
    newWindow.document.write(report);
  };

  const handleRunClicked = () => {
    var errors = [];

    const start = moment(startDate);
    const end = moment(endDate);

    if (start.isAfter(end)) {
      errors.push(`The start date for the report must be before the end date.`);
    }

    setValidationErrors(errors);

    if (!arrays.isEmpty(errors)) {
      scrollToTop();
      return;
    }

    const data = {
      startDate: start.toDate(),
      endDate: end.toDate(),
    };

    switch (reportType) {
      case 0:
        reportingActions.runProductReport({ ...data, schoolId: school }, 
          (reportData: BeehiveReport) => {
            openHtml(reportData);
          }
        );
        break;
      case 1:
        reportingActions.runPurchaseReport({ ...data, schoolId: school }, 
          (reportData: BeehiveReport) => {
            openHtml(reportData);
          }
        );
        break;
      case 2:
        reportingActions.runIncomeReport(data, 
          (reportData: BeehiveReport) => {
            openHtml(reportData);
          }
        );
        break;
      case 3:
        reportingActions.runTransactionsReport(
          {
            ...data,
            method: transactionMethod > -1 ? transactionMethod : null,
            status: transactionStatus > -1 ? transactionStatus : null,
          },
          (reportData: BeehiveReport) => {
            files.openCsv(reportData);
          }
        );
        break;
      case 4:
        reportingActions.runIncomingTransactionsReport(data, 
          (reportData: BeehiveReport) => {
            files.openCsv(reportData);
          }
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ValidationMessage errors={validationErrors} />
      {error && <Message text={error} color={Swatches.Danger} />}
      <Card>
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Report Type">
              <Dropdown
                value={reportType}
                onChange={(value) => setReportType(value)}
                fluid
              >
                <Dropdown.Item value={0} label="Products" />
                <Dropdown.Item value={1} label="Order Lines" />
                <Dropdown.Item value={2} label="School Income" />
                <Dropdown.Item value={3} label="Export Transactions" />
                <Dropdown.Item value={4} label="Export Incoming Transactions" />
              </Dropdown>
            </StructuredList.Item>
            {reportType < 2 && (
              <StructuredList.Item name="School">
                <SchoolDropdown
                  initialValue={school}
                  onChange={(value) => setSchool(value)}
                  fluid
                />
              </StructuredList.Item>
            )}
            {reportType === 3 && (
              <>
                <StructuredList.Item name="Transaction Status">
                  <Dropdown
                    value={transactionStatus}
                    onChange={(value) => setTransactionStatus(value)}
                    fluid
                  >
                    <Dropdown.Item value={-1} label="All Statuses" />
                    <Dropdown.Item value={0} label="OK" />
                    <Dropdown.Item value={1} label="Not Authorised" />
                    <Dropdown.Item value={2} label="Pending" />
                    <Dropdown.Item value={3} label="Aborted" />
                    <Dropdown.Item value={4} label="Rejected" />
                    <Dropdown.Item value={5} label="N/A" />
                    <Dropdown.Item value={6} label="Error" />
                    <Dropdown.Item value={7} label="Unknown" />
                  </Dropdown>
                </StructuredList.Item>
                <StructuredList.Item name="Transaction Method">
                  <Dropdown
                    value={transactionMethod}
                    onChange={(value) => setTransactionMethod(value)}
                    fluid
                  >
                    <Dropdown.Item value={-1} label="All Methods" />
                    <Dropdown.Item value={8} label="Stripe" />
                    <Dropdown.Item value={0} label="Opayo" />
                    <Dropdown.Item value={1} label="EPOS" />
                    <Dropdown.Item value={2} label="Cheque" />
                    <Dropdown.Item value={3} label="Cash" />
                    <Dropdown.Item value={4} label="Subsidy" />
                    <Dropdown.Item value={5} label="BACS" />
                    <Dropdown.Item value={6} label="Terminal" />
                    <Dropdown.Item value={7} label="Cardnet" />
                  </Dropdown>
                </StructuredList.Item>
              </>
            )}
            <StructuredList.Item
              name="Start Date"
              description="This is the start of the date range the report runs over."
            >
              <DatePicker
                dateFormat="DD/MM/YYYY"
                closeOnSelect
                selectedDate={startDate}
                onChange={(value) => setStartDate(value)}
              />
            </StructuredList.Item>
            <StructuredList.Item
              name="End Date"
              description="This is the end of the date range the report runs over."
            >
              <DatePicker
                dateFormat="DD/MM/YYYY"
                closeOnSelect
                selectedDate={endDate}
                onChange={(value) => setEndDate(value)}
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
        <Card.Footer>
          <Button
            text="Run Report"
            onClick={handleRunClicked}
            color={Swatches.Success}
            working={loading}
          />
        </Card.Footer>
      </Card>
    </>
  );
};

export default Reporting;
