import { PageInfo } from "types/common/paging.types";
import { SchoolListView } from "types/schools/schools.types";
import { GroupListView } from "types/users/userGroups.types";
import { UserListView, UserTinyView } from "types/users/userListViews.types";
import { InstallmentType, InstalmentRuleView, OrderLineStatus, SmartcardCreditType, SmartcardProvider, TransactionMethod } from "./shopkeeperShared.types";
import { UserTypePermissionsView } from "types/common/views.types";

export interface PaymentsSummaryReport {
  headline: Summary;
  schools: SchoolSummary[];
}

export interface SchoolSummary extends Summary {
  school: string;
}

export interface Summary {
  headline: RevenueSummary;
  revenue: MethodRevenueSummary[];
  smartcardSyncFailures: number;
  printCreditSyncFailures: number;
  orders: number;
}

export interface RevenueSummary {
  income: number | null;
  refunds: number | null;
}

export interface MethodRevenueSummary {
  method: string;
  total: number | null;
}

export interface ProductListView extends ProductTinyView {
  isActive: boolean;
  isVisibleInStore: boolean;
  hasExpiryDate: boolean;
  canBePurchasedInStore: boolean;
  expiryDate: string | null;
  soldOut: boolean;
  enforceStockControl: boolean;
  stock: number | null;
  sold: number;
  stockRemaining: number;
  allowMultiple: boolean;
  allowPartPayment: boolean;
  hasInstalments: boolean;
  maximumOrderQuantity: number;
  requiresCollection: boolean;
  allowFuture: boolean;
  ppDiscountedPrice: number | null;
  bursaryDiscountedPrice: number | null;
}

export interface ProductTinyView {
  id: number;
  name: string;
  unitPrice: number;
  category: ProductCategoryListView;
  categoryId: number;
}

export interface ProductCategoryListView extends CategoryListView {
  isActive: boolean;
  icon: string;
  school: SchoolListView;
}

export interface CategoryListView {
  id: number;
  name: string;
}

export interface ProductCategoryDetailView extends ProductCategoryListView {
  description: string;
  allowParents: boolean;
  allowStudents: boolean;
  allowStaff: boolean;
}

export interface ProductDetailView extends ProductListView {
  description: string;
  userRestrictions: UserTypePermissionsView;
  interestedStaff: UserListView[];
  groups: GroupListView[];
  instalmentRules: InstalmentRuleView[];
  inStock: boolean;
  pupilPremiumDiscount: number;
  bursaryDiscount: number;
}

export interface UserProductOrderView extends ProductOrderView {
  product: ProductListView;
  collectedDate: string | null;
  collectedFrom: UserTinyView;
  isCollectable: boolean;
}

export interface ProductOrderView {
  id: number;
  orderId: number;
  order: OrderListView;
  orderedBy: UserTinyView;
  purchasedFor: UserTinyView;
  orderDate: Date;
  total: number;
  paid: number;
  outstanding: number;
  refunded: number;
  quantity: number;
  status: OrderLineStatus;
  orderType: InstallmentType;
}

export interface OrderListView {
  id: number;
  initialBasketId: number;
  date: Date;
  status: number;
  statusName: string;
  total: number;
  refunded: number;
  paid: number;
  outstanding: number;
  user: UserListView;
  school: SchoolListView;
}

export interface OrderLineCollectedResult {
  id: number;
  collectedFrom: UserTinyView;
  collectedDate: string;
}

export interface UserProductDetailLegacyView extends ProductListView {
  description: string;
  groups: GroupListView[];
  availableFor: ProductAvailibilityView[];
}

export interface ProductAvailibilityView {
  student: UserTinyView;
  pupilPremium: boolean;
  bursary: boolean;
  instalments: ProductInstalmentView[];
  orders: ProductOrderLineView[];
  paid: number;
  outstanding: number;
}

export interface ProductInstalmentView {
  dueDate: string;
  amount: number;
  installmentType: InstallmentType;
  virtualInstalmentId: string;
  optionalForPupilPremium: boolean;
}

export interface ProductOrderLineView {
  id: number;
  orderId: number;
  orderDate: string;
  paid: number;
  placedBy: UserTinyView;
}

export interface BasketDetailView {
  id: number;
  total: number;
  enablePayments: boolean;
  paymentProviderCheckoutId: string;
  items: BasketItemView[];
  transactions: TransactionDetailView[];
  createdDate: string | null;
  paymentProcessorMode: string;
  existingProducts: BasketItemView[];
  newProducts: BasketItemView[];
  instalments: BasketItemView[];
  hasSmartcardTopups: boolean;
  hasPrintCredits: boolean;
  hasItemsAlreadyOrdered: boolean;
  hasNewProducts: boolean;
  requiresNewOrder: boolean;
  school: SchoolListView;
  validity: BasketValidityView;
}

export interface BasketItemView {
  id: number;
  name: string;
  recipient: UserTinyView;
  productId: number | null;
  unitPrice: number;
  quantity: number;
  total: number;
  virtualInstalmentId: string;
  createdBy: UserTinyView;
  isOpenOrderLine: boolean;
  alreadyOrdered: boolean;
  isNewProduct: boolean;
  isInstalment: boolean;
}

export interface BasketValidityView {
  isValid: boolean;
  message: string;
  expiredItems: BasketItemView[];
}

export interface TransactionDetailView {
  id: number;
  basketId: number;
  status: string;
  method: string;
  type: string;
  bankAuthorisationCode: string;
  bankDeclineCode: string;
  statusDetail: string;
  cardType: string;
  cardTypeName: string;
  expiryDate: string;
  reference: string;
  vendorCode: string;
  vendorName: string;
  providerTransactionId: string;
  vpsSignature: string;
  avsCv2: string;
  addressResult: string;
  postcodeResult: string;
  cv2Result: string;
  threeDSecureStatus: string;
  cavv: string;
  addressStatus: string;
  payerStatus: string;
  fraudResponse: string;
}

export interface OrderDetailView extends OrderListView {
  orderType: InstallmentType;
  lines: OrderLineView[];
  payments: PaymentView[];
  refunds: RefundView[];
  notes: NoteView[];
  initialBasket: BasketView;
  smartcardCredits: SyncView[];
  printCredits: SyncView[];
  refundable: number;
  createdBy: UserTinyView;
  cancelledBy: UserTinyView;
  cancelledDate: string | null;
  voidedBy: UserTinyView;
  voidedDate: string | null;
}

export interface OrderLineView {
  id: number;
  orderId: number;
  productId: number | null;
  status: number;
  statusName: string;
  product: ProductListView;
  productCategoryId: number | null;
  productName: string;
  pricing: QuantityPricing;
  purchasedFor: UserTinyView;
  type: string;
  outstanding: number;
  refunded: number;
  paid: number;
  description: string;
  refundable: number;
  lineType: string;
  quantity: number;
  total: number;
  unitPrice: number;
  allocations: AllocationView[];
  requiresCollection: boolean;
  collectedDate: string | null;
  collectedFrom: UserTinyView;
  isCollectable: boolean;
}

export interface AllocationTinyView {
  total: number;
  allocatedFromId: number | null;
}

export interface AllocationView extends AllocationTinyView {
  paymentId: number | null;
  refundId: number | null;
  createdDate: string | null;
  orderLine: OrderLineView;
}

export interface NoteView {
  id: number;
  notes: string;
  createdDate: string | null;
  createdBy: UserTinyView;
}

export interface PaymentView {
  id: number;
  createdDate: string;
  total: number;
  transaction: TransactionDetailView;
  createdBy: UserTinyView;
  allocations: AllocationView[];
}

export interface RefundView {
  id: number;
  createdDate: string;
  total: number;
  transaction: TransactionDetailView;
  refundedBy: UserTinyView;
  allocations: AllocationView[];
}

export interface TransactionListView {
  id: number;
  method: string;
  date: Date;
  total: number;
  user: UserTinyView;
  basketId: number | null;
  cardType: string;
  expiryDate: string;
  reference: string;
  internalTransactionId: string;
  status: string;
}

export interface SyncView {
  id: number;
  user: UserListView;
  total: number;
  orderLine: OrderLineView;
  school: SchoolListView;
  status: SmartcardSyncStatus;
  creditType: SmartcardCreditType;
  creditTypeName: string;
  syncProcessedDate: Date | null;
  details: string;
  smartcardProvider: SmartcardProvider;
  smartcardProviderName: string;
}

export interface BasketView {
  id: number;
  total: number;
  createdDate: Date | null;
  paymentProviderCheckoutId: string;
  items: BasketItemView[];
}

export interface QuantityPricing {
  price: number;
  quantity: number;
  total: number;
}

export enum SmartcardSyncStatus {
  Pending = 0,
  Credited = 1,
  Failed = 2,
  Cancelled = 3
}

export interface SmartcardSyncResultListView {
  id: number;
  user: UserListView;
  total: number;
  status: SmartcardSyncStatus;
  orderLine: OrderLineView;
  school: SchoolListView;
  syncProcessedDate: Date | null;
  details: string;
  smartcardProvider: SmartcardProvider;
  smartcardProviderName: string;
}

export interface ReconciliationListView {
  id: number;
  startDate: string;
  endDate: string;
  total: number;
  notes: string;
  createdBy: UserTinyView;
  createdDate: string;
}

export interface ReconciliationDetailView extends ReconciliationListView {
  transactions: TransactionListView[];
}

export interface GroupedTransactionListView {
  transactionMethods: TransactionGroupView[];
}

export interface TransactionGroupView {
  transactions: TransactionListView[];
  methodName: string;
  method: TransactionMethod;
}

export interface SmartcardDetailView {
  smartcardAccountName: string;
  user: UserTinyView;
  school: SchoolListView;
  balance: number;
  printCreditBalance?: number | null;
  lunchCreditBalance?: number | null;
  canTopup: boolean;
  canPrint: boolean;
  canLunchCredit: boolean;
}

export interface SmartcardTransactionListView {
  transactionId: string;
  orderId?: string;
  total?: number | null;
  description: string;
  date: Date;
}

export interface SmartcardBalanceView {
  balance: number;
}

export interface PrintCreditBalanceView {
  balance: number;
}

export interface IncomingReportListView {
  id: number;
  status: IncomingReportStatus;
  statusName: string;
  incomingId: string;
  requestDate: string;
  requestedById: string;
  requestedBy: UserListView;
  statusDetail: string;
  incomingFileId: string;
  startDate: Date;
  endDate: Date;
  transactionCount: number;
  duplicateCount: number;
  importedCount: number;
}

export enum IncomingReportStatus {
  Requested = 0,
  Succeeded = 1,
  Failed = 2,
  Processed = 3
}

export interface IncomingTransactionListView {
  id: number;
  transactionId: string;
  paymentIntent: string;
  sourceId: string;
  description: string;
  date: Date;
  total: number;
  email: string;
  linkedTransactions: TransactionListView[];
}

export interface DuplicateBasketListView {
  id: number;
  total: number;
  transactions: TransactionDetailView[];
  createdDate: string | null;
  user: UserTinyView;
  orders: OrderListView[];
}

export interface CashlessOrderDetailView {
  userId: string;
  total: number;
  nettTotal: number;
  vatTotal: number;
  createdAt: string;
  orderItems: OrderItemsView[];
}

export interface OrderItemsView {
  menuItemId: string;
  price: number;
  vatPerItem: number;
  quantity: number;
  menuItem: MenuItemView;
}

export interface MenuItemView {
  name: string;
  price: number;
}