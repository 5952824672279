import React, { useState } from "react";
import styled from "styled-components";
import { IUiKitBaseProps, Chip, Swatches, Spacing } from "../index";

import { IChipProps } from "./chip";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -${Spacing.Default}px;

  .chip {
    margin-right: ${Spacing.Default}px;
    margin-bottom: ${Spacing.Default}px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export interface IChipListProps extends IUiKitBaseProps {
  children: React.ReactElement<IChipProps>[];
  limit?: number;
  expandable?: boolean;
  chipHoverBackground?: string;
  chipIcon?: string;
  style?: object;
}

const ChipList: React.FC<IChipListProps> = ({
  children,
  className,
  style,
  limit,
  expandable = false,
  chipHoverBackground,
  chipIcon,
  onClick,
}) => {
  const [truncate, setTruncate] = useState(limit ? limit > 0 : false);

  const handleShowAll = () => {
    setTruncate(false);
  };

  return (
    <Wrapper
      className={`chip-list ${className ? className : ""}`}
      style={style}
    >
      {children?.map((child, index) =>
        !expandable || !truncate || !limit || index < limit - 1 ? child : null
      )}

      {expandable && truncate && limit && limit < children?.length && (
        <Chip
          text={`+${children && limit ? children.length - limit : 0} more`}
          colorSwatch={Swatches.Low}
          onClick={() => expandable && handleShowAll()}
        />
      )}
    </Wrapper>
  );
};

export default ChipList;
