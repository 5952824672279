import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ActionBar } from "ui-kit";
import { SchoolDropdown } from "sharedComponents/common";
import lunchRegisterActions from "areas/payments/actions/lunchRegisterActions";
import TutorGroupDropdown, { TutorGroupOption } from "./tutorGroupDropdown";
import LunchBalancesTable from "./lunchBalancesTable";
import { RootState } from "reducers/store";
import { types } from "utils";
import { SchoolStartupView } from "types/schools/schools.types";
import { GroupListView } from "types/users/userGroups.types";


const Students: React.FC = () => {

  const { user } = useSelector((state: RootState) => state.currentUser);
  const { filter, students, loading, error, paging } = useSelector((state: RootState) => state.lunchBalances);

  useEffect(() => {
    if (user && !filter?.schoolId) {
      var schoolId = [user.baseSchool, ...user.schools].filter(
        (x) => x.enableLunchCredits
      )[0]?.id;

      lunchRegisterActions.setBalancesFilter({ ...filter, schoolId });
    }
  }, [user]);

  useEffect(() => {
    filter && lunchRegisterActions.getStudentBalances(filter, 0);
  }, [filter]);

  const handleSchoolChange = (value: number) => {
    lunchRegisterActions.setBalancesFilter({ ...filter, schoolId: value });
  };

  const handleTutorGroupChange = (tutorgroup: GroupListView) => {
    lunchRegisterActions.setBalancesFilter({ ...filter, tutorGroupId: tutorgroup?.id });
  };

  const handlePage = () => {
    lunchRegisterActions.getStudentBalances(filter, paging.pageIndex + 1);
  };

  return (
    <>
      <ActionBar>
        <SchoolDropdown
          initialValue={filter.schoolId}
          onChange={handleSchoolChange}
          filter={(x) => x.enableLunchCredits}
        />
        <TutorGroupDropdown
          schoolId={filter.schoolId}
          onChange={handleTutorGroupChange}
        />
      </ActionBar>

      <LunchBalancesTable
        accounts={students}
        schoolId={filter.schoolId}
        paging={paging}
        onPage={handlePage}
        error={error != null}
        loading={loading}
      />
    </>
  );
};

export default Students;
