import React, { useState, useEffect } from "react";
import { Checkbox } from "ui-kit";
import Search from "sharedComponents/common/search/search";
import { GroupType } from "types/planner/groups.types";
import { SearchResultViewBase } from "types/common/views.types";


interface ICheckboxSearchConditionProps {
  id: number;
  name: string;
  setId: (data: number) => void;
  checkboxText?: string;
  searchGroupType: GroupType;
  disabled?: boolean;
}


const CheckboxSearchCondition: React.FC<ICheckboxSearchConditionProps> = ({
  id,
  name,
  setId,
  checkboxText,
  searchGroupType,
  disabled,
}) => {

  const [checked, setChecked] = useState<boolean>(id && id !== null);
  const [selected, setSelected] = useState<{ id: number; title: string; }[]>([]);

  useEffect(() => {
    if (id && id !== null) {
      setSelected([
        {
          id: id,
          title: name,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (!checked) {
      setId(null);
      setSelected([]);
    }
  }, [checked]);

  const handleSelectItem = (result: SearchResultViewBase) => {
    setId(parseInt(result.id));
  };

  const handleDeleteItem = () => {
    setId(null);
    setSelected([]);
  };

  return (
    <>
      <Checkbox
        text={checkboxText}
        checked={checked}
        onChange={() => setChecked(!checked)}
        disabled={disabled}
      />

      {checked && (
        <Search
          groupTypes={[searchGroupType as number]}
          selected={selected}
          handleSelectItem={selectedItem => handleSelectItem(selectedItem)}
          handleDeleteItem={selectedItem => handleDeleteItem()}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default CheckboxSearchCondition;
