import React from "react";
import PendingAttentionBehaviourDetailsWrapper from "./pendingAttentionBehaviourDetailsWrapper";
import { Flyout } from "ui-kit";
import { Constants } from "configuration";
import pendingAttentionBehaviourActions from "areas/behaviour/actions/behaviour/pendingAttentionActions";


const PendingAttentionBehaviourFlyout: React.FC = () => {

  const handleCloseDetentionDetails = () => {
    pendingAttentionBehaviourActions.selectBehaviour(null);
  };

  return (
    <Flyout
      title="Behaviour Details"
      name={Constants.FLYOUTS.PENDINGATTENTIONBEHAVIOUR}
      onClose={handleCloseDetentionDetails}
      wide
    >
      <PendingAttentionBehaviourDetailsWrapper />
    </Flyout>
  );
};

export default PendingAttentionBehaviourFlyout;
