import React from "react";
import moment from "moment";
import Moment from "react-moment";


interface IDateRangeProps {
  starts: Date;
  ends: Date;
}


const DateRange: React.FC<IDateRangeProps> = ({ starts, ends }) => {

  const startMoment = moment(starts);
  const endMoment = moment(ends);

  if (startMoment.isSame(endMoment)) {
    return (
      <span>
        Starts <Moment date={starts} format="dddd, Do MMMM YYYY [at] HH:mma" />
      </span>
    );
  }

  if (startMoment.isSame(endMoment, "day")) {
    return (
      <span>
        <Moment date={starts} format="dddd, Do MMMM YYYY [from] HH:mma" /> to{" "}
        <Moment date={ends} format="HH:mma" />
      </span>
    );
  }

  return (
    <span>
      <Moment date={starts} format="dddd, Do MMMM YYYY HH:mma" /> to{" "}
      <Moment date={ends} format="dddd, Do MMMM YYYY, HH:mma" />
    </span>
  );
};

export default DateRange;
