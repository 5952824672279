import React from "react";
import {
  StructuredList,
  Card,
  Dropdown,
  ActionBar,
  Button,
  Swatches,
  Size,
  Icon,
} from "ui-kit";
import DateRange from "../dateRange";
import MultiSchoolUserView from "sharedComponents/common/users/multiSchoolUserView";
import SchoolDropdown from "sharedComponents/common/schools/schoolDropdown";
import { DETENTION_STATUS_KEYS } from "areas/behaviour/constants/detentions";
import AcademicYearFilter from "sharedComponents/common/academicYearFilter";
import moment from "moment";
import { DetentionReportFilterExt } from "./detentionDetailedReportWrapper";


interface IDetentionDetailedReportFormProps {
  schoolId: number;
  onSelectSchool: (value: number) => void;
  reportCriteria: DetentionReportFilterExt;
  onChangeReportCriteria: (criteria: DetentionReportFilterExt) => void;
  handleGenerateReport: (exportReport: boolean) => void;
  loadingExportReport: boolean;
}


const DetentionDetailedReportForm: React.FC<IDetentionDetailedReportFormProps> = ({
  schoolId,
  onSelectSchool,
  reportCriteria,
  onChangeReportCriteria,
  handleGenerateReport,
  loadingExportReport,
}) => {
  return (
    <>
      <MultiSchoolUserView>
        <Card title="School">
          <Card.Body>
            <SchoolDropdown
              onChange={onSelectSchool}
              initialValue={schoolId}
              fluid
            />
          </Card.Body>
        </Card>
      </MultiSchoolUserView>

      <Card title="Date Range">
        <Card.Body>
          <StructuredList.Item name="Academic Year" required>
            <AcademicYearFilter
              value={reportCriteria.academicYearId}
              onChange={value =>
                onChangeReportCriteria({
                  ...reportCriteria,
                  academicYearId: value,
                })
              }
            />
          </StructuredList.Item>
          <DateRange
            startDate={reportCriteria.startDate}
            handleStartDateChange={value =>
              onChangeReportCriteria({
                ...reportCriteria,
                startDate: value,
              })
            }
            endDate={reportCriteria.endDate}
            handleEndDateChange={value =>
              onChangeReportCriteria({ ...reportCriteria, endDate: value })
            }
          />
        </Card.Body>
      </Card>

      <Card title="Filters">
        <Card.Body>
          <StructuredList>
            <StructuredList.Item name="Detention Status" required>
              <Dropdown
                items={[
                  {
                    label: "Issued",
                    value: DETENTION_STATUS_KEYS.ISSUED,
                  },
                  {
                    label: "Attended",
                    value: DETENTION_STATUS_KEYS.ATTENDED,
                  },
                  {
                    label: "Missed",
                    value: DETENTION_STATUS_KEYS.MISSED,
                  },
                  {
                    label: "Cancelled",
                    value: DETENTION_STATUS_KEYS.CANCELLED,
                  },
                ]}
                onChange={value =>
                  onChangeReportCriteria({
                    ...reportCriteria,
                    statusId: value,
                  })
                }
                value={reportCriteria.statusId}
                fluid
                clearable
              />
            </StructuredList.Item>
          </StructuredList>
        </Card.Body>
      </Card>

      <ActionBar>
        <Button
          text="Generate Report"
          color={Swatches.Success}
          size={Size.Small}
          disabled={reportCriteria.statusId === null}
          onClick={() => handleGenerateReport(false)}
          working={loadingExportReport}
        />
        <Button
          color={Swatches.Success}
          text="Export Report as CSV"
          icon="file-download"
          size={Size.Small}
          disabled={reportCriteria.statusId === null}
          onClick={() => handleGenerateReport(true)}
          working={loadingExportReport}
        />
      </ActionBar>
    </>
  );
};

export default DetentionDetailedReportForm;
