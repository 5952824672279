import rewardActions from "areas/behaviour/actions/reward/rewardActions";
import { SchoolBehaviourCodeListView } from "areas/behaviour/types/behaviourResponses.types";
import { IBeehiveAction } from "types/common/action.types";
import { IBeehiveError } from "types/common/errors.types";


interface IAllRewardCodesState {
  allRewardCodes: SchoolBehaviourCodeListView[];
  loading: boolean;
  error: IBeehiveError;
}

const INITIAL_STATE: IAllRewardCodesState = {
  allRewardCodes: [],
  loading: false,
  error: null,
};

const { GETALLREWARDCODES } = rewardActions.types;


const allRewardCodesReducer = (state = INITIAL_STATE, action: IBeehiveAction) : IAllRewardCodesState => {

  switch (action.type) {
    case GETALLREWARDCODES.START:
      return { ...state, loading: true, error: null };

    case GETALLREWARDCODES.SUCCESS:
      return { ...state, loading: false, allRewardCodes: action.payload };

    case GETALLREWARDCODES.FAILED:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default allRewardCodesReducer;
