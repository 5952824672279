import React, { useEffect, useRef, useState } from "react";
import YearGroupReportForm from "./yearGroupReportForm";
import YearGroupReportTable from "./yearGroupReportTable";
import { Title, TitleSize } from "ui-kit";
import ReportDescription from "../reportDescription";
import yearGroupReportActions from "areas/analytics/actions/yearGroupReportActions";
import { useAppSelector } from "reducers/hooks";
import { FeedFilter } from "types/planner/feedRequest.types";
import { SearchResultViewBase } from "types/common/views.types";
import moment from "moment";


export interface FeedFilterExt extends Omit<FeedFilter, "startDate" | "endDate"> {
  yearGroup: SearchResultViewBase;
  startDate?: Date;
  endDate?: Date;
}


const YearGroupReportWrapper = () => {

  const reportRef = useRef<HTMLDivElement>();

  const { user } = useAppSelector(state => state.currentUser);
  const { report, paging, loading, loadingExportReport } = useAppSelector(
    state => state.yearGroupReport
  );

  const [schoolId, setSchoolId] = useState<number>(null);
  const [reportCriteria, setReportCriteria] = useState<FeedFilterExt>({
    startDate: null,
    endDate: null,
    yearGroup: null,
    createdDate: null,
    feedType: null,
  });
  const [_loading, _setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (user?.baseSchool) {
      setSchoolId(user.baseSchool.id);
    }
  }, []);

  useEffect(() => {
    setReportCriteria({
      ...reportCriteria,
      yearGroup: null,
      createdDate: null,
      feedType: null,
    });
  }, [schoolId]);

  useEffect(() => {
    if (loading || report) {
      reportRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading, report]);

  const handleReportCriteriaChange = (criteria: FeedFilterExt) => {
    setReportCriteria(criteria);
  };

  const handleGenerateReport = (exportReport: boolean) => {
    if (!exportReport) {
      _setLoading(true);
    }
    yearGroupReportActions
      .getYearGroupReport(
        parseInt(reportCriteria.yearGroup?.id),
        {
          startDate: moment(reportCriteria.startDate).format("YYYY-MM-DD"),
          endDate: moment(reportCriteria.endDate).format("YYYY-MM-DD"),
          createdDate: reportCriteria.createdDate,
          feedType: reportCriteria.feedType
        },
        0,
        exportReport,
        () => _setLoading(false)
      )
      .catch(() => _setLoading(false));
  };

  const handlePage = () => {
    yearGroupReportActions.getYearGroupReport(
      parseInt(reportCriteria.yearGroup?.id),
      {
        startDate: moment(reportCriteria.startDate).format("YYYY-MM-DD"),
        endDate: moment(reportCriteria.endDate).format("YYYY-MM-DD"),
        createdDate: reportCriteria.createdDate,
        feedType: reportCriteria.feedType
      },
      paging.pageIndex + 1
    );
  };

  return (
    <>
      <Title text="Year Group Activity Report" size={TitleSize.H3} />
      <ReportDescription text="Use this report to view all activity (behaviours, rewards, awards, etc) for a year group between a selected date range." />
      <YearGroupReportForm
        schoolId={schoolId}
        onSelectSchool={id => setSchoolId(id)}
        reportCriteria={reportCriteria}
        onChangeReportCriteria={handleReportCriteriaChange}
        handleGenerateReport={handleGenerateReport}
        loadingExportReport={loadingExportReport}
      />
      <div ref={reportRef}>
        <YearGroupReportTable handlePage={handlePage} loading={_loading} />
      </div>
    </>
  );
};

export default YearGroupReportWrapper;
