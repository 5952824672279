import { Dropdown } from "ui-kit";
import React, { useState, useEffect } from "react";
import { DropdownLabelTypes, DropdownValueTypes, IDropdownDataItemProps } from "ui-kit/forms/dropdown";


type returnLabel<T> = T extends null ? any : T;
type returnValue<T> = T extends null ? any : T;
type returnData<T> = T extends null ? any : T;

interface ISearchableDropdownProps<TLabel, TValue, TData> {
  items?: IDropdownDataItemProps<TLabel, TValue, TData>[];
  value?: number | string | boolean | null | {};
  onChange?: (
    value: returnValue<TValue>,
    label: returnLabel<TLabel>,
    data?: returnData<TData>
  ) => void;
  placeholder?: string;
  clearable?: boolean;
  fluid?: boolean; 
}

type LabelTypes = string | number | JSX.Element;


const SearchableDropdown = <
TLabel extends LabelTypes = null,
TValue = null,
TData = null
>({
  items,
  value,
  onChange,
  placeholder,
  clearable,
  fluid,
}: ISearchableDropdownProps<TLabel, TValue, TData>) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredItems, setFilteredItems] = useState<IDropdownDataItemProps[]>([]);

  useEffect(() => {
    if (items) {
      setFilteredItems(items);
    }
  }, [items]);

  useEffect(() => {
    if (items) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      const filteredItems =
        searchTerm.length > 1
          ? items.filter(item =>
              (item.label as string)?.toLowerCase().includes(lowerCaseSearchTerm)
            )
          : items;
      setFilteredItems(filteredItems);
    }
  }, [searchTerm]);

  return (
    <Dropdown
      items={filteredItems}
      autocomplete
      placeholder={placeholder}
      searchPlaceholder={placeholder}
      onSearchChange={val => setSearchTerm(val)}
      onChange={onChange}
      value={value}
      fluid={fluid}
      clearable={clearable}
    />
  );
};

export default SearchableDropdown;
