import React from "react";
import { useNavigate } from "react-router-dom";
import { Size, Button, EmptyMessage } from "ui-kit";

const Unauthorized = () => {
  const navigate = useNavigate();

  return (
    <EmptyMessage
      icon="ban"
      title="Not Authorised"
      summary="Sorry, you are not authorized to access this content"
      cover
    >
      <Button text="Go Back" onClick={() => navigate(-1)} size={Size.Small} />
    </EmptyMessage>
  );
};

export default Unauthorized;
