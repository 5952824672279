import calendarCategoriesReducer from "./calendarCategoriesReducer";
import calendarEventsFilterReducer from "./calendarEventsFilterReducer";
import calendarEventReducer from "./calendarEventReducer";
import eventReportingReducer from "./eventReportingReducer";
import managedCalendarEventsReducer from "./managedCalendarEventsReducer";
import signedUpCalendarEventsReducer from "./signedUpCalendarEventsReducer";
import termDatesReducer from "./termDatesReducer";
import userCalendarEventsReducer from "./userCalendarEventsReducer";
import wallPlannerSessionsReducer from "./wallPlannerSessionsReducer";
import studentCalendarEventsReducer from "./studentCalendarEventsReducer";

const calendar = {
  calendarCategories: calendarCategoriesReducer,
  eventsFilter: calendarEventsFilterReducer,
  calendarEvent: calendarEventReducer,
  eventReporting: eventReportingReducer,
  managedCalendarEvents: managedCalendarEventsReducer,
  signedUpCalendarEvents: signedUpCalendarEventsReducer,
  termDates: termDatesReducer,
  studentCalendarEvents: studentCalendarEventsReducer,
  userCalendarEvents: userCalendarEventsReducer,
  wallPlannerSessions: wallPlannerSessionsReducer
};

export default calendar;
