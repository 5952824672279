import { IBeehiveAction } from "types/common/action.types";
import types from "./uiTypes";
import store from "reducers/store";

const setTitle = (name: string) : void => {
  store.dispatch<IBeehiveAction<string>>({
    type: types.SET_TITLE,
    payload: name,
  });
};

const toggleMenu = () : void => {
  store.dispatch({
    type: types.TOGGLE_MENU,
  });
};

const closeMenu = () : void => {
  store.dispatch({
    type: types.CLOSE_MENU,
  });
};

const openMenu = () : void => {
  store.dispatch({
    type: types.OPEN_MENU,
  });
};

const toggleHoverMenu = () : void => {
  store.dispatch({
    type: types.TOGGLE_HOVER_MENU,
  });
};

const setMenuMode = (mode: number): void => {
  store.dispatch({
    type: types.MENU_SET_MODE,
    payload: mode,
  });
};

const navigationActions = {
  setTitle,
  toggleMenu,
  closeMenu,
  openMenu,
  toggleHoverMenu,
  setMenuMode,
};

export default navigationActions;
