import React from "react";
import { useSelector } from "react-redux";
import { arrays } from "utils";
import {
  Table,
  SplitButton,
  Loader,
  Size,
  DetailLabel,
  EmptyMessage,
  Button,
  Swatches,
  ToastService,
  Message,
} from "ui-kit";
import { SchoolIcon } from "sharedComponents/common";
import { useNavigate } from "react-router";
import { PROVISION_TYPE } from "areas/send/constants";
import { sendRoutes } from "areas/send/sendRoutes";
import provisionsActions from "areas/send/actions/provisions/provisionsActions";
import { RootState } from "reducers/store";
import { ProvisionListView } from "areas/send/types/passportResponse.types";

interface IProvisionsTableProps {
  provisions: ProvisionListView[];
  loading?: boolean;
  error?: string;
  onNew?: () => void;
  onEdit?: (provision: ProvisionListView) => void;
  showProvider?: boolean;
}

const ProvisionsTable: React.FC<IProvisionsTableProps> = ({
  provisions,
  loading,
  error,
  onNew,
  onEdit,
  showProvider,
}) => {

  const { deleteError, deleting } = useSelector((state: RootState) => state.provision);

  const navigate = useNavigate();

  const handleNew = () => {
    onNew?.();
  };

  const handleEdit = (provision: ProvisionListView) => {
    onEdit?.(provision);
  };

  const handleDelete = (provision: ProvisionListView) => {
    if (
      window.confirm(
        "This will delete the selected provision. This cannot be undone. Are you sure?"
      )
    ) {
      provisionsActions.deleteProvision(
        provision.provider.id,
        provision.id,
        () => {
          ToastService.pop("Provision Deleted", null, "hand-holding-seedling");
        }
      );
    }
  };

  const handleViewDetails = (provision: ProvisionListView) => {
    switch (provision.provisionType) {
      case PROVISION_TYPE.CUSTOM:
        navigate(
          sendRoutes.getStudentCustomProvisionPath(
            provision.studentId,
            provision.id
          )
        );
        break;
      case PROVISION_TYPE.STANDARD:
        navigate(
          sendRoutes.getProvisionPath(provision.provider.id, provision.id)
        );
        break;
      default:
        break;
    }
  };

  if (loading) {
    return <Loader cover size={Size.Medium} />;
  }

  if (error) {
    return <EmptyMessage icon="times-circle" title="An error occurred" cover />;
  }

  return (
    <>
      {arrays.isEmpty(provisions) ? (
        <EmptyMessage
          icon="hand-holding-seedling"
          title="No Provisions"
          summary="No provisions have been set up yet"
          cover
        >
          <Button
            size={Size.Small}
            text="Add New Provision"
            onClick={handleNew}
            color={Swatches.Primary}
          />
        </EmptyMessage>
      ) : (
        <>
          <Message text={deleteError} color={Swatches.Danger} />

          <Table>
            <Table.Header>
              <Table.HeaderCell width={0.5} />
              <Table.HeaderCell width={7.5}>Provision</Table.HeaderCell>
              <Table.HeaderCell width={2} />
            </Table.Header>
            <Table.Body>
              {provisions.map((provision: ProvisionListView, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell width={0.5}>
                    <SchoolIcon code={provision.school.code} />
                  </Table.Cell>
                  <Table.Cell width={7.5}>
                    <DetailLabel
                      label={provision.name}
                      sub={`${provision.areaOfNeedName}${
                        showProvider === true
                          ? ` | ${provision.provider.name}`
                          : ""
                      }`}
                      bold
                    />
                  </Table.Cell>
                  <Table.Cell width={2} right>
                    <SplitButton
                      color={Swatches.Low}
                      text="Details"
                      onDefaultClick={() => handleViewDetails(provision)}
                      working={deleting}
                    >
                      <SplitButton.Option
                        text="Details"
                        onClick={() => handleViewDetails(provision)}
                        show
                      />
                      <SplitButton.Option
                        text="Edit"
                        onClick={() => handleEdit(provision)}
                        show
                      />
                      <SplitButton.Option
                        text="Delete"
                        onClick={() => handleDelete(provision)}
                        show
                        color={Swatches.Danger}
                      />
                    </SplitButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.Cell colspan={2} />
                <Table.Cell width={2} right>
                  <Button
                    size={Size.Small}
                    text="New Provision"
                    onClick={handleNew}
                    color={Swatches.Primary}
                    working={deleting}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </>
      )}
    </>
  );
};

export default ProvisionsTable;
