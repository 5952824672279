import React, { useEffect } from "react";
import AssignmentsFilter from "./assignmentFilter";
import { arrays } from "utils";
import AssignmentsTable from "./assignmentsTable";
import assignmentsActions from "areas/planner/actions/assignments/assignmentsActions";
import { useNavigate } from "react-router";
import { assignmentRoutes } from "areas/planner/plannerRoutes";
import { useAppSelector } from "reducers/hooks";
import { callbackType } from "types/common/action.types";
import { PagedQueryView } from "types/common/views.types";
import { AssignmentTinyView } from "areas/planner/types/assignments/assignmentResponse.types";
import { AssignmentGrouping } from "areas/planner/reducers/assignments/assignmentsReducer";
import { AssignmentCreator } from "areas/planner/types/assignments/assignmentShared.types";


const StaffAssignments: React.FC = () => {

  const navigate = useNavigate();
  const { user } = useAppSelector(state => state.currentUser);
  const { assignments, paging, loading, filter, grouping, error, refresh } = useAppSelector(
    state => state.assignments
  );

  const loadAssignments = (pageIndex: number) => {
    switch (grouping.value) {
      case AssignmentGrouping.Owned:
        user && assignmentsActions.getAssignmentsForStaff(
          user.id,
          { assignmentStatus: filter.value, setBy: AssignmentCreator.Self }, 
          pageIndex
        );
        break;
      case AssignmentGrouping.Shared:
        user && assignmentsActions.getAssignmentsForStaff(
          user.id,
          { assignmentStatus: filter.value, setBy: AssignmentCreator.Others }, 
          pageIndex
        );
        break;
      case AssignmentGrouping.Department:
        grouping.subjectId && assignmentsActions.getAssignmentsForSubject(
          grouping.subjectId, 
          { assignmentStatus: filter.value, setBy: AssignmentCreator.Others }, 
          pageIndex
        );
        break;
      case AssignmentGrouping.ManagedStaff:
        grouping.staffId && assignmentsActions.getAssignmentsForStaff(
          grouping.staffId, 
          { assignmentStatus: filter.value, setBy: AssignmentCreator.Self }, 
          pageIndex
        );
        break;
      default:
        user && assignmentsActions.getAssignmentsForStaff(
          user.id, 
          { assignmentStatus: filter.value, setBy: AssignmentCreator.All },  
          pageIndex
        );
        break;
    }
  };

  useEffect(() => {
    user && loadAssignments(0);
  }, [user]);

  useEffect(() => {
    if (user && arrays.isEmpty(assignments)) {
      loadAssignments(0);
    }
  }, [filter]);

  useEffect(() => {
    if (user && refresh) {
      loadAssignments(0);
    }
  }, [refresh]);

  const onPage = () => {
    loadAssignments(paging.pageIndex + 1);
  };

  const handleAssignmentClick = (assignment: AssignmentTinyView) => {
    navigate(assignmentRoutes.getAssignmentPath(assignment.id));
  };

  return (
    <>
      <AssignmentsFilter hideNewAssignmentButton={false} />

      <AssignmentsTable
        paging={paging}
        error={error}
        loading={loading}
        onPage={onPage}
        assignments={assignments}
        onRowClick={handleAssignmentClick}
      />
    </>
  );
};

export default StaffAssignments;
