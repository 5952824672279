import React, { useEffect, useState } from "react";
import OnCallRequestsTable from "./onCallRequestsTable";
import { useSelector } from "react-redux";
import {
  Message,
  ActionBar,
  ChipList,
  Right,
  Chip,
  Spacing,
  honeygold,
  StructuredList,
  TextInput,
  TextInputType,
} from "ui-kit";
import ManageOnCallModal from "./manageOnCallModal";
import { MultiSchoolUserView, SchoolDropdown } from "sharedComponents/common";
import styled from "styled-components";
import ApiExceptionMessage from "sharedComponents/common/apiExceptionMessage";
import ConfirmModal from "sharedComponents/common/confirmModal";
import EditOnCallRequestWrapper from "./editOnCallRequestWrapper";
import onCallActions from "../../actions/onCall/onCallActions";
import schoolsActions from "areas/planner/actions/schools/schoolsActions";
import { ON_CALL_FILTERS } from "../../constants/onCall";
import AcademicYearFilter from "sharedComponents/common/academicYearFilter";
import { Constants } from "configuration";
import { useAppSelector } from "reducers/hooks";
import { OnCallRequestDetailView } from "areas/analytics/reducers/onCallAnalyticsResponse.types";
import { CancelOnCallRequestCommand, ClaimOnCallRequestCommand } from "areas/behaviour/types/onCall/onCallRequest.types";
import { OnCallStatus } from "areas/analytics/reducers/onCallAnalyticsShared.types";


const ActionBarWrapper = styled.div`
  margin-bottom: ${Spacing.Medium}px;

  .dropdown {
    max-width: 300px;
  }

  .action-bar .chip-list .chip.selected {
    background: ${honeygold};
  }
`;


const OnCallRequestsWrapper: React.FC = () => {

  const { onCallRequests, paging, refresh, error } = useAppSelector(
    state => state.onCallRequests
  );
  const { actions } = useAppSelector(state => state.onCallRequest);
  const { user } = useAppSelector(state => state.currentUser);
  const { schoolId } = useAppSelector(state => state.schools);

  const [selectedOnCallRequest, setSelectedOnCallRequest] = useState<OnCallRequestDetailView>(null);
  const [openManageOnCallModal, setOpenManageOnCallModal] = useState<boolean>(false);
  const [openUpdateOnCallModal, setOpenUpdateOnCallModal] = useState<boolean>(false);
  const [openConfirmCancel, setOpenConfirmCancel] = useState<boolean>(false);
  const [claimAndResolve, setClaimAndResolve] = useState<boolean>(false);

  const [filterId, setFilterId] = useState<OnCallStatus>(null);
  const [internalNotes, setInternalNotes] = useState<string>("");
  const [academicYearId, setAcademicYearId] = useState<number>(
    Constants.ACADEMIC_YEAR_FILTER_ARRAY[0].value
  );
  const [_loading, _setLoading] = useState<boolean>(false);

  const handleCancelRequestClick = (onCallRequest: OnCallRequestDetailView) => {
    setSelectedOnCallRequest(onCallRequest);
    setOpenConfirmCancel(true);
  };

  const getOnCallRequestsFirstPage = () => {
    _setLoading(true);
    onCallActions.getOnCallRequests(
      schoolId, 
      {
        academicYearId: academicYearId, 
        statusId: filterId, 
      },
      0, 
      () => _setLoading(false)
    );
  };

  useEffect(() => {
    if (user) {
      schoolsActions.selectSchool(user.baseSchool.id);
    }
  }, []);

  useEffect(() => {
    if (schoolId) {
      getOnCallRequestsFirstPage();
    }
  }, [schoolId, filterId, academicYearId]);

  useEffect(() => {
    if (refresh && schoolId) {
      getOnCallRequestsFirstPage();
    }
  }, [refresh]);

  useEffect(() => {
    setInternalNotes("");
  }, [selectedOnCallRequest]);

  const handlePage = () => {
    onCallActions.getOnCallRequests(
      schoolId,
      {
        academicYearId: academicYearId,
        statusId: filterId
      },
      paging.pageIndex + 1
    );
  };

  const onSelectSchool = (value: number) => {
    schoolsActions.selectSchool(value);
  };

  const handleSelectFilter = (filterKey: OnCallStatus) => {
    if (filterKey !== null && filterId !== filterKey) {
      setFilterId(filterKey);
    } else {
      setFilterId(null);
    }
  };

  const handleViewOnCallRequest = (onCallRequest: OnCallRequestDetailView, claimAndResolve?:boolean) => {
    setSelectedOnCallRequest(onCallRequest);
    setClaimAndResolve(claimAndResolve)
    setOpenManageOnCallModal(true);
  };

  const handleUpdateOnCallClick = (onCallRequest: OnCallRequestDetailView) => {
    setSelectedOnCallRequest(onCallRequest);
    setOpenUpdateOnCallModal(true);
  };

  const handleClaimOnCallRequest = (onCallRequest: OnCallRequestDetailView) => {
    const payload: ClaimOnCallRequestCommand = {
      id: onCallRequest.id,
      schoolId: schoolId,
    };

    onCallActions.claimOnCallRequest(schoolId, onCallRequest.id, payload);
  };

  const handleCancelonCallRequest = (internalNotes: string, onSuccess?: () => void) => {
    const payload: CancelOnCallRequestCommand = {
      id: selectedOnCallRequest.id,
      schoolId: schoolId,
      internalNotes: internalNotes,
    };

    onCallActions.cancelOnCallRequest(
      schoolId,
      selectedOnCallRequest.id,
      payload,
      onSuccess
    );
  };

  if (error) {
    return <ApiExceptionMessage error={error} />;
  }

  return (
    <>
      <ActionBarWrapper>
        <ActionBar>
          <MultiSchoolUserView>
            <SchoolDropdown
              onChange={onSelectSchool}
              initialValue={schoolId}
              fluid
            />
          </MultiSchoolUserView>
          <AcademicYearFilter
            value={academicYearId}
            onChange={value => setAcademicYearId(value)}
          />
          <Right>
            <ChipList>
              {ON_CALL_FILTERS.map((filter, index) => (
                <Chip
                  key={index}
                  className={filter.value === filterId ? "selected" : ""}
                  text={filter.label}
                  onClick={() => handleSelectFilter(filter.value)}
                />
              ))}
            </ChipList>
          </Right>
        </ActionBar>
      </ActionBarWrapper>

      <ApiExceptionMessage error={actions.claimOnCallRequest.error} />

      <OnCallRequestsTable
        onCallRequests={onCallRequests}
        handleViewOnCallRequest={handleViewOnCallRequest}
        handleClaimOnCallRequest={handleClaimOnCallRequest}
        handleCancelRequestClick={handleCancelRequestClick}
        handleUpdateOnCallClick={handleUpdateOnCallClick}
        handlePage={handlePage}
        paging={paging}
        loading={_loading}
        loadingAction={
          actions.claimOnCallRequest.isLoading ||
          actions.cancelOnCallRequest.isLoading
        }
      />

      {openUpdateOnCallModal && selectedOnCallRequest && (
        <EditOnCallRequestWrapper
          onCallId={selectedOnCallRequest.id}
          schoolId={schoolId}
          openModal={openUpdateOnCallModal}
          handleCloseModal={() => setOpenUpdateOnCallModal(false)}
        />
      )}

      {openManageOnCallModal && (
        <ManageOnCallModal
          open={openManageOnCallModal}
          handleCloseModal={() => setOpenManageOnCallModal(false)}
          onCallRequestDetails={selectedOnCallRequest}
          schoolId={schoolId}
          handleClaimRequest={handleClaimOnCallRequest}
          handleCancelRequest={handleCancelonCallRequest}
          claimAndResolve={claimAndResolve}

        />
      )}

      <ConfirmModal
        openModal={openConfirmCancel}
        confirmMsg="Are you sure you want to cancel this on call request?"
        onClose={() => setOpenConfirmCancel(false)}
        onConfirm={() => {
          handleCancelonCallRequest(internalNotes, () =>
            setOpenConfirmCancel(false)
          );
        }}
        loading={actions.cancelOnCallRequest.isLoading}
      >
        <StructuredList>
          <StructuredList.Item name="Internal Notes">
            <TextInput
              fluid
              type={TextInputType.Textarea}
              placeholder="Internal Notes"
              value={internalNotes}
              onChange={value => setInternalNotes(value)}
            />
          </StructuredList.Item>
        </StructuredList>
      </ConfirmModal>
    </>
  );
};

export default OnCallRequestsWrapper;
