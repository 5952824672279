import action from "actions/action";
import actionTypeBuilder from "actions/actionTypeBuilder";
import { StudentSendReportFilter } from "areas/send/types/passportRequest.types";
import client from "services/client";
import { BeehiveReport, callbackType } from "types/common/action.types";
import { urls } from "utils";

const builder = new actionTypeBuilder("send");

const types = {
  REPORTING_PROVISIONGROUPSTUDENT: builder.build(
    "reporting_provisionGroupStudentReport"
  ),
  REPORTING_PROVISIONGROUPTARGET: builder.build(
    "reporting_provisionGroupTargetReport"
  ),
  REPORTING_STUDENTPROVISION: builder.build("reporting_studentProvisionReport"),
  REPORTING_STUDENTPROVISIONMAPS: builder.build(
    "reporting_studentProvisionMapsReport"
  ),
  REPORTING_STUDENTPASSPORTS: builder.build("reporting_studentPassportsReport"),
  REPORTING_STUDENTSCOST: builder.build("reporting_studentsCostReport"),
  REPORTING_GROUPCOST: builder.build("reporting_groupCostReport"),
  REPORTING_STUDENTCOST: builder.build("reporting_studentCostReport"),
};


const provisionGroupStudentReport = (
  providerId: number,
  provisionId: number,
  provisionGroupId: number,
  callback?: callbackType<BeehiveReport>
) =>
  action<BeehiveReport>(
    () => 
      client.post(
        `planner/send/providers/${providerId}/provisions/${provisionId}/groups/${provisionGroupId}/students`,
        {},
        { responseType: "text" }
      ),
    types.REPORTING_PROVISIONGROUPSTUDENT,
    callback
  );

const provisionGroupTargetReport = (
  providerId: number,
  provisionId: number,
  provisionGroupId: number,
  callback?: callbackType<BeehiveReport>
) =>
  action<BeehiveReport>(
    () =>
      client.post(
        `planner/send/providers/${providerId}/provisions/${provisionId}/groups/${provisionGroupId}/targets`,
        {},
        { responseType: "text" }
      ),
    types.REPORTING_PROVISIONGROUPTARGET,
    callback
  );

const studentProvisionReport = (
  studentId: string,
  academicYearId: number,
  termId: number,
  halfTerm: number,
  pageIndex: number,
  callback?: callbackType<BeehiveReport>
) => {
  var url = new urls.QueryString(
    `planner/send/students/${studentId}/provisions`
  );
  url.addParam("academicYearId", academicYearId);
  url.addParam("termId", termId);
  url.addParam("halfTerm", halfTerm);
  url.addParam("pageIndex", pageIndex);
  url.addParam("pageSize", 50);

  return action<BeehiveReport>(
    () => client.post(url.toUrl(), {}, { responseType: "text" }),
    types.REPORTING_STUDENTPROVISION,
    callback
  );
};

const studentProvisionMapsReport = (studentId: string, filter: StudentSendReportFilter, callback?: callbackType<BeehiveReport>) => {
  var url = new urls.QueryString(
    `planner/send/students/${studentId}/reports/provisionMaps`
  );
  url.addParams(filter);

  action<BeehiveReport>(
    () => client.post(url.toUrl(), {}, { responseType: "text" }),
    types.REPORTING_STUDENTPROVISIONMAPS,
    callback
  );
};

const studentPassportsReport = (studentId: string, filter: StudentSendReportFilter, callback?: callbackType<BeehiveReport>) => {
  var url = new urls.QueryString(
    `planner/send/students/${studentId}/reports/passports`
  );
  url.addParams(filter);

  return action<BeehiveReport>(
    () => client.post(url.toUrl(), {}, { responseType: "text" }),
    types.REPORTING_STUDENTPASSPORTS,
    callback
  );
};

const provisionStudentsCostReport = (academicYearId: number, callback?: callbackType<BeehiveReport>) => {
  var url = new urls.QueryString(`planner/send/studentCosts`);

  if (academicYearId == -1 || academicYearId == null) {
    url.addParam("academicYearId", "null");
  } else {
    url.addParam("academicYearId", academicYearId);
  }

  return action<BeehiveReport>(
    () => client.get(url.toUrl(), { responseType: "text" }),
    types.REPORTING_STUDENTSCOST,
    callback
  );
};

const provisionGroupCostReport = (academicYearId: number, callback?: callbackType<BeehiveReport>) => {
  var url = `planner/send/groupCosts`;
  url =
  academicYearId == -1 || academicYearId == null
      ? `${url}?academicYearId=null`
      : `${url}?academicYearId=${academicYearId}`;

  return action<BeehiveReport>(
    () => client.get(url, { responseType: "text" }),
    types.REPORTING_GROUPCOST,
    callback
  );
};

export const provisionStudentCostReport = (studentId: string, academicYearId: number, callback?: callbackType<BeehiveReport>) => {
  var url = `planner/send/singleStudentCost?studentId=${studentId}`;
  url = academicYearId == -1 || academicYearId == null
    ? `${url}&academicYearId=null`
    : `${url}&academicYearId=${academicYearId}`;

  return action<BeehiveReport>(
    () => client.get(url, { responseType: "text" }),
    types.REPORTING_STUDENTCOST,
    callback
  );
};


const provisionReportingActions = {
  types,
  provisionGroupStudentReport,
  provisionGroupTargetReport,
  studentProvisionReport,
  studentProvisionMapsReport,
  studentPassportsReport,
  provisionStudentsCostReport,
  provisionGroupCostReport,
  provisionStudentCostReport,
};

export default provisionReportingActions;
