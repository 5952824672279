import { IBeehiveAction } from "types/common/action.types";
import calendarActions from "../actions/calendarActions";
import { CalendarEventDateRegisterView, CalendarEventDetailView } from "../types/calendarResponses.types";
import { IBeehiveError } from "types/common/errors.types";

export interface CalendarEvent extends CalendarEventDetailView {
  register: CalendarEventDateRegisterView
}

interface ICalendarEventState {
  calendarEvent: CalendarEvent | null;
  loading: boolean;
  error: string | null;
  saving: boolean;
  saveError: string | null;
  signingUp: boolean;
  signupError: string | null;
  //register: CalendarEventDateRegisterView | null;
  registerError: string | null;
  loadingRegister: boolean;
  savingRegister: boolean;
  rewarding: boolean;
  rewardError: string | null;
  deleting: boolean;
  deleteError: IBeehiveError;
}

const INITIAL_STATE: ICalendarEventState = {
  calendarEvent: null,
  loading: false,
  error: null,
  saving: false,
  saveError: null,
  signingUp: false,
  signupError: null,
  //register: null,
  registerError: null,
  loadingRegister: false,
  savingRegister: false,
  rewarding: false,
  rewardError: null,
  deleting: false,
  deleteError: null
};

const calendarEventReducer = (state = INITIAL_STATE, action: IBeehiveAction) : ICalendarEventState => {
  const {
    CALENDAR_GETCALENDAREVENT,
    CALENDAR_CREATECALENDAREVENT,
    CALENDAR_UPDATECALENDAREVENT,
    CALENDAR_EVENTSIGNUP,
    CALENDAR_EVENTUNSIGNUP,
    CALENDAR_EVENTDATE_REGISTER,
    CALENDAR_EVENTDATE_REGISTER_SAVE,
    CALENDAR_EVENTDATE_REGISTER_ISSUEREWARDS,
    CALENDAR_DELETECALENDAREVENT
  } = calendarActions.types;

  switch (action.type) {
    case CALENDAR_GETCALENDAREVENT.START:
      return { ...state, loading: true };

    case CALENDAR_GETCALENDAREVENT.SUCCESS:
      return {
        ...state,
        calendarEvent: action.payload,
        loading: false,
      };

    case CALENDAR_GETCALENDAREVENT.FAILED:
      return { ...state, loading: false, error: action.payload };

    case CALENDAR_CREATECALENDAREVENT.START:
      return { ...state, saving: true, saveError: null };

    case CALENDAR_CREATECALENDAREVENT.SUCCESS:
      return {
        ...state,
        saving: false,
        calendarEvent: null,
      };

    case CALENDAR_CREATECALENDAREVENT.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "An error occurred while creating the calendar event.",
      };

    case CALENDAR_UPDATECALENDAREVENT.START:
      return { ...state, saving: true, saveError: null };

    case CALENDAR_UPDATECALENDAREVENT.SUCCESS:
      return { ...state, saving: false, calendarEvent: null };

    case CALENDAR_UPDATECALENDAREVENT.FAILED:
      return {
        ...state,
        saving: false,
        saveError: "An error occured while saving the calendar event.",
      };

    case CALENDAR_EVENTSIGNUP.START:
      return { ...state, signingUp: true, signupError: null };

    case CALENDAR_EVENTSIGNUP.SUCCESS:
      return {
        ...state,
        signingUp: false,
        calendarEvent: {
          ...state.calendarEvent,
          signedUp: true,
          dates: state.calendarEvent.dates.map(d =>
            action.payload.some((a: number) => a === d.id)
              ? { ...d, signedUp: true, signups: d.signups - 1 }
              : d
          ),
        },
      };

    case CALENDAR_EVENTSIGNUP.FAILED:
      return {
        ...state,
        signingUp: false,
        signupError: "An error occured while signing up to the calendar event.",
      };

    case CALENDAR_EVENTUNSIGNUP.START:
      return { ...state, signingUp: true, signupError: null };

    case CALENDAR_EVENTUNSIGNUP.SUCCESS:
      return {
        ...state,
        signingUp: false,
        calendarEvent: {
          ...state.calendarEvent,
          signedUp: false,
          dates: state.calendarEvent.dates.map(d =>
            action.payload.some((a: number) => a === d.id)
              ? { ...d, signedUp: false, signups: d.signups + 1 }
              : d
          ),
        },
      };

    case CALENDAR_EVENTUNSIGNUP.FAILED:
      return {
        ...state,
        signingUp: false,
        signupError: "An error occured while signing up to the calendar event.",
      };

    case CALENDAR_EVENTDATE_REGISTER.START:
      return { 
        ...state, 
        loadingRegister: true, 
        calendarEvent: { 
          ...state.calendarEvent
        }
      };

    case CALENDAR_EVENTDATE_REGISTER.SUCCESS:
      //console.log(action.payload);
      return {
        ...state,
        loadingRegister: false,
        calendarEvent: { 
          ...state.calendarEvent, 
          register: {
            signupLimit: action.payload.signupLimit,
            placesRemaining: action.payload.placesRemaining,
            hasMenu: action.payload.hasMenu,
            attendees: {
              items: action.payload.attendees.paging.pageIndex === 0
                ? action.payload.attendees.items
                : [...state.calendarEvent.register.attendees.items, ...action.payload.attendees.items],
              paging: action.payload.attendees.paging
            },
          }
        },
      };

    case CALENDAR_EVENTDATE_REGISTER.FAILED:
      return {
        ...state,
        loadingRegister: false,
        registerError: "An error occured while loading the register.",
      };

    case CALENDAR_EVENTDATE_REGISTER_SAVE.START:
      return { ...state, savingRegister: true };

    case CALENDAR_EVENTDATE_REGISTER_SAVE.SUCCESS:
      return {
        ...state,
        savingRegister: false,
      };

    case CALENDAR_EVENTDATE_REGISTER_SAVE.FAILED:
      return {
        ...state,
        savingRegister: false,
        registerError: "An error occured while saving the register.",
      };

    case CALENDAR_EVENTDATE_REGISTER_ISSUEREWARDS.START:
      return { ...state, rewarding: true };

    case CALENDAR_EVENTDATE_REGISTER_ISSUEREWARDS.SUCCESS:
      return {
        ...state,
        rewarding: false,
      };

    case CALENDAR_EVENTDATE_REGISTER_ISSUEREWARDS.FAILED:
      return {
        ...state,
        rewarding: false,
        rewardError: "An error occured while issuing the rewards.",
      };

    case CALENDAR_DELETECALENDAREVENT.START:
      return {
        ...state,
        deleting: true
      }
    case CALENDAR_DELETECALENDAREVENT.SUCCESS:
      return {
        ...state,
        deleting: false
      }
    case CALENDAR_DELETECALENDAREVENT.FAILED:
      return {
        ...state,
        deleting: false,
        deleteError: action.payload
      }

    default:
      return state;
  }
};

export default calendarEventReducer;
